"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358766;
const slug = exports.slug = 'guides/api/app-management/oauth-tokens';
const title = exports.title = 'OAuth | Managing tokens';
const name = exports.name = 'vNext Docs DP - OAuth Tokens';
const metaDescription = exports.metaDescription = 'Get OAuth access and refresh tokens ensuring your ability to securely perform CRUD actions with HubSpot APIs.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "initiating-oauth-access",
  "label": "Initiating OAuth access",
  "parent": null
}, {
  "depth": 0,
  "id": "generate-initial-access-and-refresh-tokens",
  "label": "Generate initial access and refresh tokens",
  "parent": null
}, {
  "depth": 0,
  "id": "refresh-an-access-token",
  "label": "Refresh an access token",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-access-token-metadata",
  "label": "Retrieve access token metadata",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-a-refresh-token",
  "label": "Delete a refresh token",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 10,
        children: [(0, _jsxRuntime.jsx)(_components.h1, {
          children: "Manage OAuth tokens"
        }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the OAuth tokens API to generate and manage tokens needed for authorizing your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public app"
      }), " and the requests it makes. For example, you'll need to use this API to fetch the initial access and refresh tokens during the app installation process. You'll then use it to continue generating new tokens when the old ones expire. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "working with OAuth"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before you can use these endpoints, you'll have to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "create a public app"
      }), ". A user will then need to install it into their account to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#initiating-an-integration-with-oauth-2-0",
        children: "initiate OAuth access"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initiating OAuth access"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After creating your app, a user can install it into their HubSpot account using they'll use the install URL located in the app's settings, which will include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "client_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirect_uri"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scopes"
      }), " as query parameters. You may also include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scopes"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), ", if needed. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#initiating-an-integration-with-oauth-2-0",
        children: "initiating OAuth for your app"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After a user authorizes your app and installs it into their account, the redirect URL will be appended with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), " value, which you can use to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#generate-initial-access-and-refresh-tokens",
        children: "generate an access token and a refresh token"
      }), ". The access token will be used to authenticate requests that your app makes, while the refresh token will be used to get a new access token when the current one expires."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Generate initial access and refresh tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get OAuth access and refresh tokens, make a URL-form encoded ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v1/token"
      }), ". In the request body, you'll specify various auth parameters, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "client_id"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "client_secret"
      }), ", along with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), " passed back through the redirect URL."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After a user authorizes your app, the redirect URL will be appended with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), " value. Using this code, you'll generate the initial access token and refresh token. Access tokens are short-lived, and you can check the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " parameter when generating an access token to determine its lifetime (in seconds)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url https://api.hubapi.com/oauth/v1/token \\\n  --header 'content-type: application/x-www-form-urlencoded' \\\n  --data 'grant_type=authorization_code&code=bcf33c57-dd7a-c7eb-4179-9241-e01bd&redirect_uri=https://www.domain.com/redirect&client_id=7933b042-0952-4e7d-a327dab-3dc&client_secret=7a572d8a-69bf-44c6-9a34-416aad3ad5'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Must be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            }), " for the request to generate initial access and refresh tokens."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            }), " returned in the redirect URL after the user installs the app."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The app's set redirect URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The app's client ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The app's client secret."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the response, you'll receive the access token along with the refresh token, which you can use to refresh the access token. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " field specifies how long the access token will last (in seconds)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"token_type\": \"bearer\",\n  \"refresh_token\": \"1e8fbfb1-8e96-4826-8b8d-c8af73715\",\n  \"access_token\": \"CIrToaiiMhIHAAEAQAAAARiO1ooBIOP0sgEokuLtAEaOaTFnToZ3VjUbtl46MAAAAEAAAAAgAAAAAAAAAAAACAAAAAAAOABAAAAAAAAAAAAAAAQAkIUVrptEzQ4hQHP89Eoahkq-p7dVIAWgBgAA\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Refresh an access token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using a refresh token, you can generate a new access token by making a URL-form encoded ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v1/token"
      }), ". In the request body, you'll specify the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "grant_type"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "client_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "client_secret"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "refresh_token"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url https://api.hubapi.com/oauth/v1/token \\\n  --header 'content-type: application/x-www-form-urlencoded' \\\n  --data 'grant_type=refresh_token&refresh_token=1e8fbfb1-8e96-4826-8b8d-c8af73715&client_id=7933b042-0952-4e7d-a327dab-3dc&client_secret=7a572d8a-69bf-44c6-9a34-416aad3ad5'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Must be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            }), " for the request to generate new access tokens from the refresh token."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The refresh token value."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The app's client ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The app's client secret."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve access token metadata"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get information about an OAuth access token, including the user that the token was created for and their corresponding Hub ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v1/access-tokens/{token}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You'll receive a response containing information about the user their HubSpot account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"token\": \"CNaKSIHAAEAQAAAARiO1ooBIOP0sgEokuLtATIU5m7Kzmjj0ihJJuKFq1TcIiHCqwE6MAAAAEEAAAAAAAAAAgAIUfmerBenQwc07ZHXy6atYNNW8XCVKA25hMVIAWgBgAA\",\n  \"user\": \"user@domain.com\",\n  \"hub_domain\": \"meowmix.com\",\n  \"scopes\": [\n    \"oauth\",\n    \"crm.objects.contacts.read\",\n    \"crm.objects.contacts.write\"\n  ],\n  \"signed_access_token\": {\n    \"expiresAt\": 1727190403926,\n    \"scopes\": \"AAEAAAAQ==\",\n    \"hubId\": 1234567,\n    \"userId\": 293199,\n    \"appId\": 111111,\n    \"signature\": \"5m7ihJJuKFq1TcIiHCqwE=\",\n    \"scopeToScopeGroupPks\": \"AAAAQAAAAAAAAAACAAAAAAAAAAAAAIAAAAAAA4AEAAAAAAAAAAAAAABAC\",\n    \"newSignature\": \"fme07ZHXy6atYNNW8XCU=\",\n    \"hublet\": \"na1\",\n    \"trialScopes\": \"\",\n    \"trialScopeToScopeGroupPks\": \"\",\n    \"isUserLevel\": false\n  },\n  \"hub_id\": 1234567,\n  \"app_id\": 111111,\n  \"expires_in\": 1754,\n  \"user_id\": 293199,\n  \"token_type\": \"access\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": HubSpot access tokens are expected to fluctuate in size over time, as updates will be made to the information that is encoded in them. It's recommended to allow for tokens to be up to 512 characters to account for any changes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a refresh token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a user uninstalls your app, you can delete the refresh token by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v1/refresh-tokens/{token}"
      }), ". This will only delete the refresh token. Access tokens generated with the refresh token will not be deleted. Additionally, this will not uninstall the application from HubSpot accounts or inhibit data syncing between the app and account."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}