"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529645;
const slug = exports.slug = 'guides/cms/improve-older-sites/add-theme-features-to-existing-sites';
const title = exports.title = 'Cómo agregar características de temas a un sitio web existente de HubSpot CMS';
const name = exports.name = 'LATAM (ES) Add theme capabilities to an existing HubSpot CMS website';
const metaDescription = exports.metaDescription = 'Las características de temas pueden agregarse de forma adicional a los sitios más antiguos. Piensa en el futuro del sitio web y en cómo estas funciones pueden mejorarlo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tema",
  "label": "Tema",
  "parent": null
}, {
  "depth": 1,
  "id": "1-1.-coloca-los-archivos-existentes-en-una-carpeta-contenedora",
  "label": "1 1. Coloca los archivos existentes en una carpeta contenedora",
  "parent": "tema"
}, {
  "depth": 1,
  "id": "2.-haz-que-la-carpeta-contenedora-sea-un-tema",
  "label": "2. Haz que la carpeta contenedora sea un tema",
  "parent": "tema"
}, {
  "depth": 1,
  "id": "3.-agregar-campos-de-tema",
  "label": "3. Agregar campos de tema",
  "parent": "tema"
}, {
  "depth": 0,
  "id": "clonar-arrastrar-y-soltar-del-administrador-de-dise%C3%B1o-como-html",
  "label": "Clonar arrastrar y soltar del administrador de diseño como HTML",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A1reas-de-arrastrar-y-soltar",
  "label": "Áreas de arrastrar y soltar",
  "parent": null
}, {
  "depth": 2,
  "id": "%C2%BFtiene-sentido-convertir-todas-las-p%C3%A1ginas-existentes-para-que-utilicen-%C3%A1reas-de-arrastrar-y-soltar%3F",
  "label": "¿Tiene sentido convertir todas las páginas existentes para que utilicen áreas de arrastrar y soltar?",
  "parent": "%C3%A1reas-de-arrastrar-y-soltar"
}, {
  "depth": 1,
  "id": "conversi%C3%B3n-de-plantillas-existentes",
  "label": "Conversión de plantillas existentes",
  "parent": "%C3%A1reas-de-arrastrar-y-soltar"
}, {
  "depth": 1,
  "id": "%C2%BFc%C3%B3mo-se-migran-las-p%C3%A1ginas-existentes%3F",
  "label": "¿Cómo se migran las páginas existentes?",
  "parent": "%C3%A1reas-de-arrastrar-y-soltar"
}, {
  "depth": 0,
  "id": "parciales-globales",
  "label": "Parciales globales",
  "parent": null
}, {
  "depth": 0,
  "id": "alertas-de-c%C3%B3digo",
  "label": "Alertas de código",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      code: "code",
      img: "img",
      h4: "h4",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Cómo agregar características de temas a un sitio web existente de HubSpot CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una de las ventajas de HubSpot es que no es necesario actualizarlo constantemente. CMS Hub utiliza siempre la última versión. Las nuevas funciones introducidas con CMS Hub son adicionales. Por lo tanto, las plantillas, módulos, etc., de los sitios web existentes de HubSpot CMS funcionan de la misma manera en que los construiste. Incluso son mejores, ya que entre bastidores, el CMS es cada vez mejor, más rápido y más fácil de editar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay algunas características específicas de CMS Hub que un desarrollador necesita agregar. Puedes implementar algunas de estas características de forma diferente a como lo harías en un sitio web nuevo. Este tutorial te guiará en la adición de estas características en tus sitios web actuales."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de continuar:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Repasa los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "conceptos clave de CMS Hub"
        }), " aunque lleves mucho tiempo creando en HubSpot. Comprenderás mejor cómo encajan todas las piezas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["También necesitarás usar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CLI del CMS"
        }), ", así que si no la tienes instalada, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "configúrala"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes agregar y utilizar estas funciones de forma independiente. Empieza por el encabezado para conocer las características específicas que necesitas. No es necesario seguir este tutorial de forma completamente lineal."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " de CMS Hub son un paquete de plantillas, módulos y archivos CSS, JSON y JS. El hecho de que los temas sean un paquete tiene significado en toda la experiencia del editor de contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al crear un nuevo sitio web o una página de destino, al creador de contenido se le presenta una cuadrícula con los temas de su cuenta. Este selecciona el tema que está utilizando y luego se le muestra una cuadrícula con las plantillas de ese tema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando se edita una página utilizando una plantilla de un tema, los módulos de ese tema reciben un tratamiento especial que los hace destacar en el panel de agregar módulos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1 1. Coloca los archivos existentes en una carpeta contenedora"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Haces que tus plantillas, módulos, archivos CSS y JS sean un tema si están dentro de la misma carpeta contenedora. No importa si están organizados en subcarpetas; solo importa que esos materiales estén contenidos en una carpeta. Si ya están almacenados de esa manera, genial, si no, crea esta carpeta y mueve tus materiales a ella. Dale a esa carpeta el nombre que quieras darle al tema. El nombre depende totalmente de ti, pero tal vez podrías ponerle el nombre de la marca de la empresa que refleja el sitio, y el año del rediseño o la actualización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Haz que la carpeta contenedora sea un tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los temas requieren dos archivos JSON, y solo uno de ellos necesita contenido real al principio."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Theme.json"
        }), ": contiene la configuración del tema."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fields.json"
        }), ": contiene campos que pueden utilizarse para realizar cambios de diseño en todo el sitio."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los archivos JSON son especiales y actualmente no se pueden crear en el administrador de diseño. Estos archivos PUEDEN cargarse a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI del CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Recoge en tu equipo la carpeta contenedora que creaste en el paso «Coloca los archivos existentes en una carpeta contenedora»."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dentro de la carpeta, en tu equipo, crea un nuevo archivo con el nombre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copia el ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/cms/content/themes/overview#theme-json",
          children: ["archivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.json"
          }), " de ejemplo en el documento de temas"]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pega el código de ejemplo en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cambia el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"label\""
        }), " para que sea el nombre del tema, ya que quieres que los creadores de contenido lo vean."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cambia ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"preview_path\""
        }), " por la ruta de la plantilla de página de inicio o por la de la plantilla que más has utilizado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz una captura de pantalla de la página de inicio del sitio web. Dale a la imagen el nombre de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.jpg"
        }), ". Coloca la imagen dentro de la misma carpeta que tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cambia ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"screenshot_path\""
        }), " en theme.json por la ruta de la imagen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.png"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Guarda el archivo."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dentro de la misma carpeta que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), " crea un nuevo archivo y llámalo fields.json. Dentro de este archivo introduce solo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[]"
        }), " y guarda el archivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver los cambios en el administrador de diseño, ejecuta el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "comando hs upload"
      }), ". Ahora tienes un tema básico. Los módulos y plantillas que hay en ese tema se mostrarán asociados al tema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Agregar campos de tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos de tema son controles que se pueden proporcionar a un creador de contenido para que pueda realizar cambios de estilo en todo el tema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Durante la creación inicial de un sitio web, estos controles de tema permiten a los creadores de contenido participar en la definición de la marca del sitio. Los equipos pueden descubrir que esto libera al desarrollador para que se centre en los aspectos más técnicos de desarrollo del sitio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En los sitios web existentes, los campos de tema pueden ser innecesarios. Después de todo, si el sitio web ya fue construido a la medida, probablemente no tiene sentido agregar controles de color y tipografía en todo el sitio. Si la marca de un sitio web está cambiando significativamente, puede ser más apropiado hacer un rediseño que agregar campos retroactivamente. En última instancia, sin embargo, esta es una decisión que debe tomarse de forma conjunta con las demás partes interesadas en el sitio web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agregar campos al tema, agrega tu JSON al archivo fields.json. Los campos tienen la misma estructura que los campos de módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Asegúrate de que los usuarios que deben tener acceso para cambiar los valores de los campos de tema, tienen el permiso «Editar contenido global y temas». Debes asegurarte de que los usuarios que no quieras que puedan editar estos valores de configuración NO los tengan activados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "Captura de pantalla de la configuración del contenido global y temas en los permisos de usuario"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clonar arrastrar y soltar del administrador de diseño como HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastrar y soltar"
      }), " y los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "parciales globales"
      }), " requieren el uso de archivos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL codificados"
      }), ". Si es posible que tu sitio web actual esté construido con el antiguo sistema de arrastrar y soltar del administrador de diseño y quieres utilizar esas nuevas características, quizá tengas que clonar esas plantillas como HTML."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para clonar estas plantillas como plantillas HTML + HubL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abre el administrador de diseño y busca la plantilla en el buscador."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Haz clic con el botón derecho del ratón en la plantilla."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En el menú contextual que aparece, elige «Clonar como HTML»"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ahora tienes lo que necesitas para trabajar en la adición de áreas de arrastrar y soltar y los parciales globales."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Áreas de arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastrar y soltar"
      }), " pueden agregarse a las plantillas para proporcionar a los creadores de contenido una forma de colocar los módulos tanto horizontal como verticalmente en una página. Las áreas de arrastrar y soltar también proporcionan un control adicional del estilo en el creador de contenido. Puesto que una plantilla con un área de arrastrar y soltar puede utilizarse para crear muchos diseños diferentes, esto libera al desarrollador para que se centre en los aspectos técnicos de la creación y actualización del sitio."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Las áreas de arrastrar y soltar son una nueva característica y no son lo mismo que las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "plantillas de arrastrar y soltar del administrador de diseño"
        }), ". Tampoco son compatibles con las plantillas de arrastrar y soltar del administrador de diseño. Para agregarlas a las plantillas construidas previamente con el creador de plantillas de arrastrar y soltar , consulta Clonar arrastrar y soltar del administrador de diseño como HTML."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "¿Tiene sentido convertir todas las páginas existentes para que utilicen áreas de arrastrar y soltar?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eso depende completamente de si la página cumple con los objetivos de tu empresa. En otras palabras, se aplica el viejo adagio de «Si no está descompuesto, no lo arregles». Si es necesario cambiar el diseño de la página, entonces probablemente sí conviene convertir la página para usar áreas de arrastrar y soltar. Si la página cumple su función y no necesita cambios de diseño, probablemente esté bien como está."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conversión de plantillas existentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es relativamente fácil crear un clon de una plantilla antigua y cambiar la columna flexible por un área de arrastrar y soltar. Es una buena idea, ya que abre muchas posibilidades para los creadores de contenido. Eso les da a los creadores de contenido un gran control creativo en las nuevas páginas. Si la plantilla es una plantilla de arrastrar y soltar del administrador de diseño, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#clone-design-manager-drag-and-drop-as-html",
        children: "Clonar arrastrar y soltar del administrador de diseño como HTML"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La solución más fácil es encontrar las instancias de columnas flexibles que utilizan la etiqueta de HubL ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% widget_container \"my_unique_column_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Reemplaza cada instancia según sea necesario con", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% dnd_area \"my_unique_area_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si no te interesa establecer los módulos predeterminados, deja ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " vacío. Si quieres llevar los módulos predeterminados para la región o establecer nuevos valores predeterminados útiles, dentro de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " agrega ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), ", y dentro de este, agrega ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para cada etiqueta de módulo dentro del antiguo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_container"
        }), ", es conveniente que crees un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fila dnd_row"
        }), " que contenga un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " con una ruta coincidente con los módulos que quieras mostrar de forma predeterminada. Esto creará la misma disposición vertical que tenía la columna flexible."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "¿Cómo se migran las páginas existentes?"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si estás cambiando las plantillas construidas con columnas flexibles para utilizar ahora áreas de arrastrar y soltar, hay algunas cosas que debe saber. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Las columnas flexibles no son lo mismo que las áreas de arrastrar y soltar. No puedes pasar de una plantilla que solo tiene una columna flexible a otra que solo tiene un área de arrastrar y soltar"
        }), ". No lo permitimos como medida de seguridad. El contenido no se mapeará desde la columna flexible al área de arrastrar y soltar. Para ilustrar el porqué de esto, supón que construiste tu nueva plantilla de manera que tiene una barra lateral y un área de contenido principal. Tu barra lateral es una columna flexible, tu contenido principal es un área de arrastrar y soltar. La herramienta de intercambio asignaría la columna flexible a la columna flexible."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si deseas convertir una página para que use áreas de arrastrar y soltar, la forma más segura de hacerlo es utilizar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "preparación de contenido"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/content/staging/domain",
          children: "Abre la herramienta de preparación de contenido"
        }), ", busca la página actual y prepárala. Elige «Preparar página en blanco»"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Selecciona la nueva plantilla que utiliza el área de arrastrar y soltar."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copia y pega la información de la versión original de la página y crea el diseño que desees."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cuando hayas terminado, publica para preparar."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En la herramienta de preparación de contenido, ve a la pestaña «Publicar». Selecciona la página y publícala desde la preparación."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parciales globales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "parciales globales"
      }), " son contenido HTML & HubL que pueden reutilizarse en todo el sitio web. Los tipos de parciales globales más comunes son los encabezados, las barras laterales y los pies de página del sitio web. Para los creadores de contenido, los parciales globales se manifiestan en el editor de contenido como regiones que estos pueden seleccionar para editarlas en el editor de contenido global. El editor de contenido global tiene el mismo aspecto que el editor de la página, pero se utiliza para editar el contenido global."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ilustrarlo, podrías implementar el encabezado como un parcial global con módulos para la navegación y otro contenido. Para el creador de contenido, si este necesita agregar un enlace a la navegación, podría hacer clic en el encabezado en el editor de la página y luego hacer clic en el módulo de menú y actualizar el menú."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Asegúrate de que los usuarios que ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "deben"
      }), " tener acceso a la edición de contenido global tengan el permiso «Editar contenido global y temas»."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "configuración de contenido global y temas en los permisos de usuario"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alertas de código"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En las cuentas de CMS Hub Enterprise, las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "alertas de código"
      }), " proporcionan una ubicación centralizada para que los desarrolladores y los administradores de TI tengan una visión general de los problemas que afectan el rendimiento y la representación de las páginas y los materiales en la cuenta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ya que estás optimizando activamente el sitio para aprovechar las nuevas características. Es una buena idea revisar las alertas de código de tu cuenta y resolver las que existan."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}