'use es6';

// Match EITHER
//   - ``` at the beginning of a line
//   - <br /> followed by one newline but not more than one
const LINEBREAK_REGEX = /(^`{3}|<br ?\/?>([\S\t ]*)\n(?=[\S\t ]))/m;
export function preprocessMarkdownBlock(md) {
  let insideCodeBlock = false;
  let output = '';
  let index = 0;
  let match = md.match(LINEBREAK_REGEX);
  while (match) {
    if (match[1].slice(0, 3) === '<br') {
      if (!insideCodeBlock) {
        // Append from index to the beginning of the match
        output += md.slice(index, index + match.index);
        // Append modified match
        output += `\n${match[2]}${match[2] ? '\n' : ''}`;
      } else {
        // If we're in a code block, we don't want to modify anything.
        output += md.slice(index, index + match.index + match[0].length);
      }
    } else if (match[1] === '```') {
      // Toggle flag to indicate that we've just entered or exited a code block
      insideCodeBlock = !insideCodeBlock;
      // Append from index to the end of the full match, without modification
      output += md.slice(index, index + match.index + match[0].length);
    } else {
      throw new Error('Unrecognized match in markdown preprocessing.');
    }
    // Advance index pointer to begin at the end of the last match in the source string
    index += match.index + match[0].length;
    // Re-run regex on remainder of string starting from the end of the last match
    match = md.slice(index).match(LINEBREAK_REGEX);
  }
  // Tack on whatever's left.
  output += md.slice(index);
  return output;
}