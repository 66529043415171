"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492284291;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/overview';
const title = exports.title = 'Überblick über serverlose Funktionen';
const name = exports.name = 'EMEA DACH (DE) Serverless Overview';
const metaDescription = exports.metaDescription = 'Serverlose Funktionen sind in JavaScript geschrieben und verwenden die NodeJS-Laufzeitumgebung. Nutzen Sie sie, um CMS Hub-Websites neue und erweiterte Funktionen hinzuzufügen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "beispiele",
  "label": "Beispiele",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen",
  "label": "Beschränkungen",
  "parent": null
}, {
  "depth": 1,
  "id": "ausf%C3%BChrungslimits",
  "label": "Ausführungslimits",
  "parent": "beschr%C3%A4nkungen"
}, {
  "depth": 1,
  "id": "abh%C3%A4ngigkeiten-aufteilen",
  "label": "Abhängigkeiten aufteilen",
  "parent": "beschr%C3%A4nkungen"
}, {
  "depth": 0,
  "id": "zugriff-auf-serverlose-funktionen",
  "label": "Zugriff auf serverlose Funktionen",
  "parent": null
}, {
  "depth": 1,
  "id": "ordner-f%C3%BCr-serverlose-funktionen",
  "label": "Ordner für serverlose Funktionen",
  "parent": "zugriff-auf-serverlose-funktionen"
}, {
  "depth": 1,
  "id": "serverless.json",
  "label": "serverless.json",
  "parent": "zugriff-auf-serverlose-funktionen"
}, {
  "depth": 1,
  "id": "function.js",
  "label": "Function.js",
  "parent": "zugriff-auf-serverlose-funktionen"
}, {
  "depth": 0,
  "id": "geheimnisse",
  "label": "Geheimnisse",
  "parent": null
}, {
  "depth": 0,
  "id": "anzeigen-von-protokollen-f%C3%BCr-serverlose-funktionen",
  "label": "Anzeigen von Protokollen für serverlose Funktionen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie eine serverlose Funktion als Teil eines ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "Entwicklerprojekts"
        }), " erstellen, besuchen Sie stattdessen die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "Dokumentation der serverlosen Funktion für Entwicklerprojekte"
        }), ". Die folgende Dokumentation dient zum Erstellen serverloser Funktionen außerhalb der Entwicklerprojektplattform."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit serverlosen Funktionen können Sie serverseitigen Code schreiben, der über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "APIs"
      }), " mit HubSpot und Drittanbieterdiensten interagiert. APIs, für die eine Authentifizierung erforderlich ist, können aus Sicherheitsgründen nicht für das Front-End einer Website genutzt werden – Ihre Anmeldedaten wären zugänglich und die Website damit Angriffen ausgesetzt. Serverlose Funktionen können eine Mittlerfunktion übernehmen, sodass Ihre Anmeldedaten geschützt bleiben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit serverlosen Funktionen müssen Sie keine neuen Server aufsetzen und verwalten. Der Aufwand ist viel geringer und serverlose Funktionen lassen sich einfach anpassen und skalieren, wenn ein Unternehmen wächst."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können mit serverlosen Funktionen experimentieren, indem Sie einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "CMS-Entwickler-Account"
      }), " verwenden. Um Ihre erste serverlose Funktion zu erstellen, lesen Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
        children: "Leitfaden für den Einstieg in serverlose Funktionen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beispiele"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Die Liste der Dinge, für die Sie serverlose Funktionen von HubSpot verwenden können, ist Ihrer Fantasie überlassen. Hier sind einige Beispiele:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Sammeln von Daten und Speichern in HubDB oder im CRM von HubSpot"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Komplexe Datenberechnungen"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Dynamische Anzeige von Daten aus anderen Systemen"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/cms/overview",
                children: "Registrierungssysteme für Events"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Formulareinsendungen, die Daten an andere Systeme übermitteln"
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172853952400"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei dem Beispiel des Event-Registrierungssystems könnten Sie serverlose Funktionen nutzen, um die Registrierung zu verarbeiten und zu aktualisieren, wie viele freie Plätze es für ein Event gibt. Der Ablauf wäre folgendermaßen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Website-Besucher navigiert zu Ihrer Event-Registrierungsseite, die anzeigt, dass noch 15 weitere Personen teilnehmen können. Der Besucher füllt ein benutzerdefiniertes Formular aus, um sich für die Veranstaltung anzumelden, und sendet es ab."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Für diese Einsendung haben wir festgelegt, dass eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ihrewebsite.com/_hcms/api/event/teilnehmer"
        }), " gesendet wird. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event/teilnehmer"
        }), " ist Ihre serverlose Funktion."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ihre serverlose Funktion empfängt die vom Benutzer übermittelten Daten und führt ein paar Aktionen aus, bevor sie eine Antwort an den Browser zurückgibt:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Übermittelt die Formularfelddaten an die HubSpot-API für die Formulareinsendung, um diese Formulareinsendungsinformationen zum CRM von HubSpot hinzuzufügen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verwendet die HubDB-API, um 1 von der in HubDB gespeicherten Teilnehmerzahl für dieses Event abzuziehen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sendet eine Antwort an den Webbrowser zurück."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das JavaScript auf der Seite empfängt die Antwort von der serverlosen Funktion und zeigt dem Endbenutzer eine Bestätigungsmeldung an und passt die Anzahl der verbleibenden Teilnehmerplätze an."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die serverlosen Funktionen von HubSpot sind in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        children: "JavaScript"
      }), " geschrieben und verwenden die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/about/",
        children: "NodeJS-Laufzeitumgebung"
      }), ". Die serverlosen Funktionen von HubSpot sind dazu gedacht, Ihrer HubSpot-Website Funktionen hinzuzufügen, z. B. die Unterstützung erweiterter Formulareinsendungen und das Abrufen von Daten aus anderen APIs. Es ist nicht als allgemeine Computerplattform gedacht, auf der Sie Code ausführen können, der nichts mit HubSpot zu tun hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serverlose Funktionen sollen schnell sein und einen engen Fokus haben. Diese Geschwindigkeit macht sie zu perfekten Begleitern für das Front-End von Websites und Anwendungen, die schnelle Aufrufe und Reaktionen ermöglichen. Um die Leistung aufrechtzuerhalten, sind die serverlosen Funktionen von HubSpot auf Folgendes beschränkt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 Geheimnisse pro Account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 MB Speicher."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "nicht mehr als 100 Endpunkte pro HubSpot-Account."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["den contentType ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " beim Aufruf einer Funktion."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6 MB pro Aufruf-Payload (diese Beschränkung ist oft beim Hochladen einer Datei mit einer serverlosen Funktion relevant)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "4 KB für die Menge der Daten, die protokolliert werden können. Wenn Sie diese Obergrenze erreichen, wird empfohlen, nach einzelnen Aktionen zu protokollieren und nicht nach der endgültigen Ausgabe."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ausführungslimits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Jede Funktion hat eine maximale Ausführungszeit von 10 Sekunden."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Jeder Account ist auf insgesamt 600 Ausführungssekunden pro Minute beschränkt."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das bedeutet, dass jedes dieser Szenarien innerhalb einer Minute eintreten kann:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bis zu 60 Funktionsausführungen, die jeweils 10 Sekunden in Anspruch nehmen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bis zu 6.000 Funktionsausführungen, die 100 Millisekunden dauern."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Funktionen, die diese Limits überschreiten, lösen einen Fehler aus. Ausführungsanzahl und Zeitlimits geben eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "-Antwort zurück. Die Ausführungszeit jeder Funktion wird in den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#viewing-serverless-function-logs",
        children: "Protokollen der serverlosen Funktionen"
      }), " berücksichtigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abhängigkeiten aufteilen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverlose Funktionen unterstützen nicht das Aufteilen von JavaScript auf mehrere Dateien, wenn sie bereitgestellt werden. Stattdessen muss Ihre serverlose Funktion eine JavaScript-Datei enthalten, um die Funktion auszuführen. Wenn Sie eine serverlose Funktion mit mehreren JavaScript-Dateien erstellen, sollten Sie stattdessen den freigegebenen Code in die eine JavaScript-Datei kopieren oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "Webpack"
      }), " verwenden, um Ihren Code zu bündeln. Erfahren Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/Import-packages-in-serverless-functions/m-p/346620",
        children: "HubSpot-Community"
      }), " mehr über das Verwenden von Webpack als Lösung."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zugriff auf serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot werden serverlose Funktionen im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "Dateisystem für Entwickler"
      }), " gespeichert, das im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-general/a-quick-tour-of-the-design-manager",
        children: "Design-Manager"
      }), "sichtbar ist. Sie können Ihre serverlosen Funktionen lokal über das CLI aufrufen und bearbeiten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Informationen zum Einbinden von serverlosen Funktionen in ein Entwicklerprojekt finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "Dokumentation zu grundlegenden JavaScript-Komponenten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ordner für serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Serverlose Funktionen von HubSpot befinden sich in einem functions-Ordner. Dieser Ordner kann beliebig benannt werden, muss aber die Endung ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".functions"
            }), " enthalten. Die in diesem Ordner gespeicherten Dateien sind nicht öffentlich zugänglich."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Berücksichtigen Sie im functions-Ordner Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), "-Datei mit einer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#function-js",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: ".js"
              })
            }), "-Datei, die Ihre Funktionen enthält. Sie sollten es in Betracht ziehen, eine ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#readme-md",
              children: "README"
            }), "-Markdown-Datei hinzuzufügen, um mitzuteilen, wozu die Funktionen dienen, wie sie funktionieren und ob Sie einen Build-Prozess haben, um sie zu erstellen."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-03%20at%208.24.31%20AM.png",
            alt: "Serverloser .functions-Ordner"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um versehentliche Änderungen innerhalb des Design-Managers zu verhindern, können Sie Ihren Ordner sperren. Um einen Ordner zu sperren, navigieren Sie zum Design-Manager, klicken Sie mit der rechten Maustaste auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ordner"
        }), " und wählen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ordner sperren"
        }), " aus."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "serverless.json"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Serverlose Funktionen, die in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "Entwicklerprojekten"
        }), " enthalten sind, wurden ab Plattformversion ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.2"
        }), " aktualisiert, einschließlich eines neuen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "-Schemas. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/platform-versioning",
          children: "Versionierung der Projektplattform"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })
      }), " ist die Konfigurationsdatei der serverlosen Funktion, die die Laufzeitumgebung und alle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "Umgebungsvariablen"
      }), " angibt, die Sie in Ihren Funktionen verwenden möchten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Datei regelt auch die Weiterleitung Ihrer Endpunkte. Sie geben die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#endpoints",
        children: "Endpunktpfade"
      }), " an, die Sie Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#function-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "function.js"
        })
      }), "-Dateien zuordnen möchten. Ein Beispiel dafür, wie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Datei aussehen sollte, finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: "Referenzleitfaden für serverlose Funktionen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Function.js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ihre eigentliche serverlose Funktion kann beliebig benannt werden, solange es sich um eine ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".js"
        }), "-Datei"]
      }), " handelt. Damit Ihre serverlose Funktion funktioniert, muss sie einem Endpunkt zugeordnet werden, der in der ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "-Datei definiert ist"]
      }), ". Zur Fehlerbehebung empfiehlt es sich, die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".js"
      }), "-Datei ähnlich wie den Endpunktnamen in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Konfigurationsdatei zu benennen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Geheimnisse"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie einen von einer serverlosen Funktion vorgenommenen Aufruf authentifizieren, sollten Sie zur Sicherheit Geheimnisse verwenden, um API-Schlüssel, Zugriffstoken von privaten Apps und andere Authentifizierungsinformationen speichern. Dies ermöglicht eine Authentifizierung, ohne Ihren Schlüssel oder Zugriffstoken offenzulegen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Geheimnisse zu erstellen und zu verwalten, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#serverless-function-commands",
        children: "HubSpot CLI-Befehle"
      }), " verwenden, z. B.:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add"
        }), ", um ein neues Geheimnis zu erstellen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets list"
        }), ", um Ihre aktuell verfügbaren Geheimnisse nach Namen anzuzeigen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add"
        }), ", um ein vorhandenes Geheimnis zu aktualisieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald sie über das CLI hinzugefügt wurden, können sie Funktionen zur Verfügung gestellt werden, indem ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), "-Array mit dem Namen des Geheimnisses hinzugefügt wird. Auf diese Weise können Sie Ihren Funktionscode in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "Versionskontrolle speichern"
      }), " und Geheimnisse verwenden, ohne sie preiszugeben. Sie sollten jedoch ", (0, _jsxRuntime.jsx)("u", {
        children: "niemals"
      }), " den Wert Ihres Geheimnisses durch Konsolenprotokollierung oder als Antwort zurückgeben, da dies das Geheimnis in Protokollen oder auf Frontend-Seiten preisgibt, die Ihre serverlose Funktion aufrufen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Aufgrund der Zwischenspeicherung kann es etwa eine Minute dauern, bis aktualisierte geheime Werte angezeigt werden. Wenn Sie gerade ein Geheimnis aktualisiert haben, aber immer noch den alten Wert sehen, überprüfen Sie es nach etwa einer Minute erneut."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anzeigen von Protokollen für serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zur Unterstützung bei der Fehlersuche in Ihren serverlosen Funktionen verfügt das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " über den ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        }), "-Befehl"]
      }), ", mit dem Sie die Protokolle Ihrer Funktion anzeigen können. Neben den Antworten auf die einzelnen Funktionsaufrufe, dem Zeitpunkt der Ausführung und der Ausführungszeit wird auch jede ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), "-Anweisung in den Funktionsprotokollen angezeigt. Führen Sie bei Geheimnissen nicht eine Konsolenprotokollierung (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), ") wie bei API-Schlüsseln durch."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}