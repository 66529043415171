"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866608851;
const slug = exports.slug = 'reference/api/overview';
const title = exports.title = 'HubSpot API | はじめに';
const name = exports.name = 'vNext Docs DP - はじめに';
const metaDescription = exports.metaDescription = 'HubSpot APIを使うと、カスタムアプリを開発したり、カスタム連携を行ったりすることができます。まずはAPIの概要と開発者アカウントの作成について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E8%A8%AD%E5%AE%9A",
  "label": "設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%AF%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%83%BC",
  "label": "クライアントライブラリー",
  "parent": "%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 0,
  "id": "api%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "APIドキュメント",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%83%95%E3%82%A1%E3%83%AC%E3%83%B3%E3%82%B9%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "リファレンスドキュメント",
  "parent": "api%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88"
}, {
  "depth": 1,
  "id": "%E9%80%A3%E6%90%BA%E3%82%AC%E3%82%A4%E3%83%89",
  "label": "連携ガイド",
  "parent": "api%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88"
}, {
  "depth": 0,
  "id": "%E6%8E%B2%E8%BC%89",
  "label": "掲載",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%A8%E3%82%B3%E3%83%9F%E3%83%A5%E3%83%8B%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E3%83%AA%E3%82%BD%E3%83%BC%E3%82%B9",
  "label": "サポートとコミュニティーのリソース",
  "parent": null
}, {
  "depth": 0,
  "id": "cms%E3%81%A7%E3%81%AE%E6%A7%8B%E7%AF%89",
  "label": "CMSでの構築",
  "parent": null
}, {
  "depth": 2,
  "id": "%E9%96%A2%E9%80%A3%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "関連ドキュメント",
  "parent": "cms%E3%81%A7%E3%81%AE%E6%A7%8B%E7%AF%89"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Table
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot APIリファレンスドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの開発者プラットフォームは、さまざまな組織の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/grow-better",
        children: "スマートな成長"
      }), "（英語）を支援するという、HubSpotのミッションの中核を成しているとも言えます。HubSpot APIは、あらゆる形態やあらゆる規模のチームがカスタマイズを行ってHubSpotを最大限に活用できる、確かな連携の開発を可能にすることを目的として設計されています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのHubSpot APIは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/Representational_state_transfer",
        children: "RESTを使って構築"
      }), "され、予測可能なURL構造で記述できるように設計されています。さまざまなメソッド（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "）やエラー レスポンス コードを含め、標準的なHTTP機能が採用されています。HubSpot APIに対する全ての呼び出しは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com"
      }), "で行われ、全てのレスポンスとして標準的なJSONが返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotとの連携を開発するには、いくつかの方法があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["例えば組織の他の部分との共有／統合用にアカウントの承認された部分のみを表示／編集できるアプリを開発する場合など、個別のHubSpotアカウント用の内部連携を開発するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "非公開アプリ"
        }), "を作成します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["複数のHubSpotアカウントにインストールできる公開アプリを作成する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
          children: "開発者アカウントを作成する"
        }), "必要があります。なぜなら、それぞれOAuthで認証され、設定可能な一連の機能と権限を備えたHubSpotアプリを作成する際には、開発者アカウントを使用するからです。また開発者アカウントでは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "テストアカウントの作成"
        }), "や、アプリのステータスとパフォーマンスのモニタリング、HubSpotアプリマーケットプレイスへのアプリ公開も可能です。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["さまざまな種類のアプリとアカウントの種類の詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#account-relationships",
        children: "こちらの記事"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "クライアントライブラリー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["クライアントライブラリーは、HubSpot APIのスムーズな利用を目的に開発されています。さまざまな言語で記述され、アプリとHubSpot APIの間の仲介コードとして機能します。各APIコールのURLとHTTPメソッドを正確に覚えておく必要がなくなるため、アプリの作成作業に集中して取り組むことが可能になります。クライアントライブラリーの詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/overview",
        children: "こちら"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Table, {
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("th", {
            children: "言語"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "パッケージリンク"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "ソースコード"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_nodejs-512_339733.png",
              alt: "Node.Js"
            }), "Node.Js"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://www.npmjs.com/package/@hubspot/api-client",
              children: "npm install @hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "hubspot-api-nodejs"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/new-php-logo.png",
              alt: "PHP"
            }), "PHP"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://packagist.org/packages/hubspot/api-client",
              children: "composer require hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-php",
              children: "hubspot-api-php"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/ruby.png",
              alt: "Ruby"
            }), "Ruby"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://rubygems.org/gems/hubspot-api-client",
              children: "gem install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-ruby"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_267_Python_logo_4375050.png",
              alt: "Python"
            }), "Python"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://pypi.org/project/hubspot-api-client/",
              children: "pip install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-python"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのAPIドキュメントは、リファレンスドキュメントと連携ガイドの2種類に分かれています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リファレンスドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "全てのAPIリファレンスドキュメントは、概要とエンドポイントの項で構成されています。APIの概要には、機能の要約、使用例、連携に関する考慮事項が含まれています。エンドポイントの項には、各エンドポイント、パラメーター、複数言語によるリクエストの例が示されています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者アカウントでアプリの認証設定を完了したら、エンドポイント リファレンス ページから直接Postmanを使用したり、テスト呼び出しを行ったりできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "連携ガイド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのプラットフォームの基礎を学習したい場合、または初めてAPI呼び出しを行う前に例を見る場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "連携ガイド"
      }), "の項でアプリやチュートリアルを検索したり、開発者アカウント、OAuthの利用、APIレート制限などの詳細を参照したりできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "関連："
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "認証方式"
      }), "や", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "の詳細をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "掲載"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリを世界に配信するアプリパートナーになる準備ができたら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "掲載"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "認定"
      }), "に必要な条件を確認してください。その後、開発者アカウントで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "アプリマーケットプレイス"
      }), "への掲載情報の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "作成"
      }), "と管理ができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サポートとコミュニティーのリソース"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの活発な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "開発者コミュニティーフォーラム"
      }), "（英語）と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/developer-slack",
        children: "Slackコミュニティー"
      }), "（英語）に参加して、質問に答えてもらったり、交流したり、情報を共有したりしてみてください。当社は、こうしたフォーラムやコミュニティーに寄せられる貴重なご意見を、開発者エクスペリエンス向上の取り組みに役立てていきたいと考えています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "変更ログ"
      }), "（英語）の受信をお申込みいただくと、新機能、お知らせ、重要な変更などの最新情報をお届けします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMSでの構築"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイトの構築や、ブログ、ランディングページ、簡単なアプリ、Eメールの作成をお考えの場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "CMS開発者ドキュメント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "HubSpot APIの使い方"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}