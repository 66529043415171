"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358754;
const slug = exports.slug = 'guides/api/crm/objects/contacts';
const title = exports.title = 'API du CRM | Contacts';
const name = exports.name = 'vNext Docs DP - Contacts';
const metaDescription = exports.metaDescription = `Les fiches d'informations de contact stockent des informations sur des personnes. Les points de terminaison des contacts vous permettent de gérer ces données et de les synchroniser entre HubSpot et d'autres systèmes.`;
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-des-contacts",
  "label": "Créer des contacts",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": "cr%C3%A9er-des-contacts"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "cr%C3%A9er-des-contacts"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rez-des-contacts-par-id-de-fiche-d%E2%80%99informations%2C-e-mail-ou-propri%C3%A9t%C3%A9-de-valeur-unique-personnalis%C3%A9e",
  "label": "Récupérez des contacts par ID de fiche d’informations, e-mail ou propriété de valeur unique personnalisée",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-des-contacts",
  "label": "Mettre à jour des contacts",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-contacts-existants-%C3%A0-des-fiches-d-informations-ou-activit%C3%A9s",
  "label": "Associer des contacts existants à des fiches d'informations ou activités",
  "parent": "mettre-%C3%A0-jour-des-contacts"
}, {
  "depth": 1,
  "id": "supprimer-une-association",
  "label": "Supprimer une association",
  "parent": "mettre-%C3%A0-jour-des-contacts"
}, {
  "depth": 0,
  "id": "%C3%A9pingler-une-activit%C3%A9-%C3%A0-une-fiche-d-informations-d-un-contact",
  "label": "Épingler une activité à une fiche d'informations d'un contact",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-les-contacts",
  "label": "Supprimer les contacts",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-d-utilisation",
  "label": "Limites d'utilisation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Contacts"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans HubSpot, les contacts stockent des informations sur les personnes qui interagissent avec votre entreprise. Les points de terminaison des contacts vous permettent de créer et de gérer des fiches d'informations de contact ainsi que de synchroniser des données de contact entre HubSpot et d'autres systèmes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les API d'objets, de fiches d'informations, de propriétés et d'associations dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "guide Comprendre le CRM"
      }), ". Pour plus d'informations générales sur les objets et les fiches d'informations dans HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://knowledge.hubspot.com/contacts-user-guide",
        children: " découvrez comment gérer votre base de données CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer de nouveaux contacts, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans votre demande, incluez les données de votre contact dans un objet de propriétés. Vous pouvez également ajouter un objet d'association pour associer votre nouveau contact à des fiches d'informations existantes (par exemple : des entreprises ou des transactions) ou à des activités (par exemple : des réunions ou des notes)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les détails du contact sont stockés dans des propriétés de contact. Il existe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/hubspots-default-contact-properties",
        children: "propriétés de contact HubSpot par défaut"
      }), ", mais vous pouvez également créer des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "propriétés personnalisées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez un nouveau contact, vous devez inclure ", (0, _jsxRuntime.jsx)("u", {
        children: "au moins une"
      }), " des propriétés suivantes dans votre demande : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), ". Il est recommandé de toujours inclure ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", car les adresses e-mail constituent le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "principal identifiant unique"
      }), " pour éviter les contacts en double dans HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher toutes les propriétés disponibles, vous pouvez récupérer une liste des contacts de votre compte en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/contacts"
      }), ". Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API des propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous avez inclus ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " dans votre demande, les valeurs doivent faire référence au nom interne de la phase du cycle de vie. Les noms internes des phases par défaut sont des valeurs ", (0, _jsxRuntime.jsx)("u", {
          children: "textes"
        }), " et ne changent pas, même si vous modifiez le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "libellé"
        }), " de la phase (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "). Les noms internes des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "phases personnalisées"
        }), " sont des valeurs ", (0, _jsxRuntime.jsx)("u", {
          children: "numériques"
        }), ". Vous pouvez trouver l'ID interne d'une phase dans vos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "paramètres de phase du cycle de vie "
        }), "ou en récupérant la propriété de phase du cycle de vie via l'API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour créer un nouveau contact, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez un nouveau contact, vous pouvez également associer le contact à des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "fiches d'informations"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "activités"
      }), " existantes en incluant un objet d'associations. Par exemple, pour associer un nouveau contact à une entreprise et une adresse e-mail existantes, votre demande ressemblerait à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 279\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 197\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet d'association, vous devez ajouter les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fiche d'informations ou l'activité à laquelle vous souhaitez associer le contact, en fonction de sa valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " unique."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association entre le contact et la fiche d'informations/l'activité. Inclure la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "et le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Les ID de types d'association par défaut sont répertoriés ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "ici"
            }), ", ou vous pouvez récupérer la valeur des types d'associations personnalisés (c'est-à-dire les libellés) via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérez des contacts par ID de fiche d’informations, e-mail ou propriété de valeur unique personnalisée"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez récupérer des contacts individuellement ou par lots."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer un contact individuel, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId} or"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour demander une liste de tous les contacts, effectuez une demande", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ces points de terminaison, vous pouvez inclure les paramètres suivants dans l'URL de la requête :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer dans la réponse. Si le contact demandé n'a pas de valeur pour une propriété, il n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés actuelles et historiques à renvoyer dans la réponse. Si le contact demandé n'a pas de valeur pour une propriété, il n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules des objets pour lesquels récupérer les ID associés. Les associations spécifiées qui n'existent pas ne seront pas renvoyées dans la réponse. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API des associations."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer un lot de contacts spécifiques par ID de fiche d'informations, adresse e-mail ou selon une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propriété d'identifiant unique personnalisée"
        }), ", effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/contacts/batch/read"
        }), ". Le point de terminaison du lot ", (0, _jsxRuntime.jsx)("u", {
          children: "ne peut pas"
        }), " récupérer les associations. Découvrez comment lire par lots les associations avec l'API ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "des associations"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour le point de terminaison de lecture par lot, vous pouvez utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " facultatif pour récupérer les contacts par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " ou en fonction d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriété d'identifiant unique"
      }), " personnalisée. Par défaut, les valeurs d'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " dans la demande font référence à l'ID de fiche d'informations (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), de sorte que le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " n'est pas requis lors de la récupération par ID de fiche d'informations. Si vous utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " ou une propriété de valeur unique personnalisée pour récupérer des contacts, vous devez inclure le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour récupérer un lot de contacts en fonction de valeurs d'ID de fiche d'informations, votre demande peut ressembler à ce qui suit (valeurs actuelles uniquement, ou valeurs actuelles et historiques) :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current values)\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"lifecyclestage\", \"hs_lead_status\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour récupérer des contacts en fonction d'une adresse e-mail ou d'un identifiant unique personnalisé (par exemple : un numéro d'identification client unique pour votre entreprise), votre demande ressemblerait à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"email\",\n  \"inputs\": [\n    {\n      \"id\": \"lgilmore@thedragonfly.com\"\n    },\n    {\n      \"id\": \"sstjames@thedragonfly.com\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with a unique value property\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"internalcustomerid\",\n  \"inputs\": [\n    {\n      \"id\": \"12345\"\n    },\n    {\n      \"id\": \"67891\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour des contacts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez mettre à jour des contacts individuellement ou par lots."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour des contacts ", (0, _jsxRuntime.jsx)("u", {
        children: "individuels"
      }), ", vous pouvez utiliser l’ID de fiche d’informations (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") ou l’adresse e-mail du contact (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre à jour un contact individuel avec son ID de fiche d'informations, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId}"
        }), " et incluez les données que vous souhaitez mettre à jour."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre à jour un contact individuel avec son e-mail, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), " et ajoutez les données que vous souhaitez mettre à jour."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with record ID\n// PATCH /crm/v3/objects/contacts/123456789\n{\n  \"properties\": {\n    \"favorite_food\": \"burger\",\n    \"jobtitle\": \"Manager\",\n    \"lifecyclestage\": \"Customer\"\n  }\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n// PATCH /crm/v3/objects/contacts/lgilmore@thedragonfly.com?idProperty=email\n{\n  \"properties\": {\n    \"favorite_food\": \"Donut\",\n    \"jobtitle\": \"Owner\",\n    \"phone\": \"5555555555\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour des contacts par ", (0, _jsxRuntime.jsx)("u", {
        children: "lots"
      }), ", vous pouvez utiliser les valeurs d’ID de fiche d’informations des contacts (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "). Pour mettre à jour plusieurs contacts, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      }), ". Dans le corps de votre demande, incluez l’ID de fiche d’informations de chaque contact comme ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " et incluez les propriétés que vous souhaitez mettre à jour."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n// POST /crm/v3/objects/contacts/batch/update\n{\n  \"inputs\": [\n    {\n      \"id\": \"123456789\",\n      \"properties\": {\n        \"favorite_food\": \"burger\"\n      }\n    },\n    {\n      \"id\": \"56789123\",\n      \"properties\": {\n        \"favorite_food\": \"Donut\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des contacts existants à des fiches d'informations ou activités"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer un contact à d'autres fiches d'informations de CRM ou à une activité, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour récupérer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", reportez-vous à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "cette liste"
        }), " de valeurs par défaut ou effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "l'API des associations."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une association entre un contact et une fiche d'informations ou une activité, effectuez une demande", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à l'URL suivante : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactID}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Épingler une activité à une fiche d'informations d'un contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "épingler une activité"
      }), " à une fiche d'informations de contact en incluant le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " dans votre demande. Dans le champ, incluez l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de l'activité à épingler, qui peut être récupéré via les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API d'engagement"
      }), ". Vous pouvez épingler une activité par fiche d'informations, et l'activité doit déjà être associée au contact avant l'épinglage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour définir ou mettre à jour l'activité épinglée d'un contact, votre demande peut ressembler à :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body PATCH /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez également créer un contact, l'associer à une activité existante et épingler l'activité dans la même demande. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body POST /crm/v3/objects/contacts\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 201\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer les contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez supprimer des contacts individuellement ou par lots, ce qui placera le contact dans la corbeille dans HubSpot. Vous pourrez ensuite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restaurer la transaction dans HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer un contact individuel avec son ID, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la suppression de contacts par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'utilisation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les opérations par lot sont limitées à 100 fiches d’informations à la fois. Par exemple, vous ne pouvez pas mettre à jour par lots plus de 100 contacts dans une demande. Il existe également des limites pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/api/faq#limits_contacts",
        children: "les contacts et les soumissions de formulaires"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}