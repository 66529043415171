import I18n from 'I18n';
import { Paths } from '../../components/RoutesPublic';
import referenceSecondaryNavConfigs from './reference';
import getStartedSecondaryNavConfigs from './getStarted';
import guidesSecondaryNavConfigs from './guides';
import memoize from 'react-utils/memoize';

/**
 * Returns an array of primary nav items.
 */
export const getPrimaryNavItems = memoize(() => [{
  baseDirectory: Paths.GETTING_STARTED,
  label: I18n.text('developer-docs-ui.navigation.getStarted.primaryNavLinkLabel'),
  getSecondaryNavConfigs: getStartedSecondaryNavConfigs
}, {
  baseDirectory: Paths.GUIDES,
  label: I18n.text('developer-docs-ui.navigation.guides.primaryNavLinkLabel'),
  getSecondaryNavConfigs: guidesSecondaryNavConfigs
}, {
  baseDirectory: Paths.REFERENCE,
  label: I18n.text('developer-docs-ui.navigation.reference.primaryNavLinkLabel'),
  getSecondaryNavConfigs: referenceSecondaryNavConfigs
}]);