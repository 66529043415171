"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044612;
const slug = exports.slug = 'guides/cms/features';
const title = exports.title = 'Fonctionnalités de CMS Hub';
const name = exports.name = 'EMEA FR CMS Features';
const metaDescription = exports.metaDescription = 'Les développeurs et responsables marketing peuvent travailler avec un certain nombre de puissantes fonctionnalités de CMS Hub pour concevoir leur site et le sauvegarder avec le CRM de premier ordre de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "environnement-de-test-d%C3%A9veloppeur-cms",
  "label": "Environnement de test développeur CMS",
  "parent": null
}, {
  "depth": 0,
  "id": "recherche-de-contenu",
  "label": "Recherche de contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "multilingue",
  "label": "Multilingue",
  "parent": null
}, {
  "depth": 0,
  "id": "gestionnaire-de-fichiers",
  "label": "Gestionnaire de fichiers",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%A9paration-de-contenu",
  "label": "Préparation de contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb",
  "label": "HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "adh%C3%A9sion",
  "label": "Adhésion",
  "parent": null
}, {
  "depth": 0,
  "id": "fonctions-sans-serveur",
  "label": "Fonctions sans serveur",
  "parent": null
}, {
  "depth": 0,
  "id": "api-de-hubspot",
  "label": "API de HubSpot",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      strong: "strong",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Fonctionnalités de CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les développeurs et responsables marketing peuvent travailler avec un certain nombre de puissantes fonctionnalités de CMS Hub pour concevoir leur site et le sauvegarder avec le CRM de premier ordre de HubSpot. Préparez votre site en toute sécurité tout en utilisant HubDB, en fournissant un contenu spécifique selon l'utilisateur et en veillant à ce que votre contenu soit présenté dans une langue comprise par le lecteur. Tout est dans CMS Hub."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Environnement de test développeur CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Créez un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/fr/free-cms-developer-sandbox",
        children: "environnement de test développeur CMS gratuit"
      }), " pour accéder à tous les outils et fonctionnalités de développeur HubSpot. Utilisez votre environnement de test pour découvrir comment créer d'excellents sites sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/fr/cms",
        children: "CMS Hub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recherche de contenu"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il n'y a pas besoin d'un outil de recherche tiers. En utilisant le moteur de recherche natif de HubSpot, la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "fonctionnalité de recherche dans le site intégrée de CMS Hub"
      }), " permettra à vos visiteurs de trouver le contenu qu'ils recherchent."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multilingue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Avec CMS Hub, les utilisateurs peuvent créer des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "variantes multilingues"
      }), " de leur contenu. Cela permettra aux utilisateurs finaux de voir le contenu dans une langue qu'ils comprennent. En outre, HubSpot fournit des outils pour aider les développeurs à s'assurer que la langue appropriée est disponible pour l'utilisateur final."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gestionnaire de fichiers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/file-manager",
        children: "gestionnaire de fichiers HubSpot"
      }), " peut être utilisé pour stocker et restituer des fichiers pour votre site web. Les fichiers peuvent être stockés dans le gestionnaire de fichiers via l'interface utilisateur intégrée à l'application ou via l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ILC du CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Préparation de contenu"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "préparation de contenu"
      }), " permet aux créateurs de sites web de mettre à jour le design et le contenu des pages en préparant les modifications. Ces modifications préparées peuvent ensuite être publiées sur votre site de production. La ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "préparation de contenu est une fonctionnalité de CMS Hub Pro."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), " est une banque de données relationnelles, un peu comme une feuille de calcul. Vous pouvez créer des tables et manipuler des données dans HubDB via une interface utilisateur, ou utiliser l'API HubDB. Vous pouvez utiliser les balises HubL de HubSpot pour extraire les données directement dans le CMS et générer dynamiquement des pages basées sur les lignes HubDB. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubDB est une fonctionnalité de CMS Hub Pro."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adhésion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'aide de la fonctionnalité ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "Adhésion"
      }), " de CMS Hub Entreprise, vous pouvez exiger que vos contacts de CRM soient connectés pour afficher un contenu spécifique de votre site. Le contenu derrière les pages d'adhésion peut être hautement personnalisé pour le contact connecté et peut même restituer des données de contacts, d'entreprises, de transactions et de produits à partir du CRM.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "La fonctionnalité Adhésion est une fonctionnalité de CMS Hub Entreprise."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "fonctions sans serveur"
      }), " permettent d'écrire un code côté serveur qui interagit avec HubSpot et des services tiers via des API. Utilisez des fonctions sans serveur pour ajouter des fonctionnalités avancées à votre site web ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.fr/products/cms",
        children: "CMS Hub"
      }), ". Les ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "fonctions sans serveur sont une fonctionnalité de CMS Hub Entreprise."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les API de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "HubSpot"
      }), " offrent un accès authentifié et sécurisé à vos données CMS et CRM. Les API sont conçues pour permettre la création rapide et facile d'applications ou d'intégrations fonctionnelles. Toutes les API de HubSpot sont organisées autour de REST. Si vous avez déjà interagi avec une API RESTful, beaucoup de concepts vous seront familiers."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}