import { labels } from './translations/labels';
import { breadcrumbs } from './translations/breadcrumbs';
export function assertIsBreadcrumbKeyArray(array) {
  for (const key of array) {
    if (!isBreadcrumb(key)) {
      throw new Error(`Invalid breadcrumb key: ${key}`);
    }
  }
}
export function isBreadcrumb(key) {
  const keyExists = (key in breadcrumbs);
  if (!keyExists) {
    console.error(`Invalid breadcrumb key: ${key}`);
  }
  return keyExists;
}
export function isLabel(label) {
  const labelExists = (label in labels);
  if (!labelExists) {
    console.error(`Invalid label: ${label}`);
  }
  return labelExists;
}