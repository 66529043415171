"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937497;
const slug = exports.slug = 'guides/api/crm/objects/products';
const title = exports.title = 'CRM API | Products';
const name = exports.name = 'vNext Docs DP - Products';
const metaDescription = exports.metaDescription = 'In HubSpot, products represent the goods or services you sell. The products endpoints allow you to manage and sync this data.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-product",
  "label": "Create a product",
  "parent": null
}, {
  "depth": 0,
  "id": "associate-products",
  "label": "Associate products",
  "parent": null
}, {
  "depth": 1,
  "id": "create-and-associate-a-line-item-multiple-calls",
  "label": "Create and associate a line item (multiple calls)",
  "parent": "associate-products"
}, {
  "depth": 1,
  "id": "create-and-associate-a-line-item-single-call",
  "label": "Create and associate a line item (single call)",
  "parent": "associate-products"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Products"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, products represent the goods or services you sell. Building a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
        children: "product library"
      }), " allows you to quickly add products to deals, generate quotes, and report on product performance. The products endpoints allow you to manage this data and sync it between HubSpot and other systems."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Products, along with companies, contacts, deals, tickets, line items, and quotes, are objects in the HubSpot CRM. Learn more about object properties, associations, relationships, and more in our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM Objects"
      }), " guide."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " so that sales reps can easily add goods and services to deals, quotes, and more, use the products API to import your product catalog into HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a product"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a product make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/products"
      }), ". In the request body, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object containing any product properties that you'd like to set on create. You can later update a product's properties through a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to the same endpoint."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see all available product properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), ". To retrieve product properties, the request URL will be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/products"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to crm/v3/objects/products\n{\n  \"properties\": {\n    \"name\": \"Implementation Service\",\n    \"price\": \"6000.00\",\n    \"hs_sku\": \"123456\",\n    \"description\": \"Onboarding service for data product\",\n    \"hs_cost_of_goods_sold\": \"600.00\",\n    \"hs_recurring_billing_period\": \"P12M\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Note that the value for ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_recurring_billing_period"
        }), " is formatted as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "P#M"
        }), ", where # is the number of months."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associate products"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Products themselves can't be associated with other CRM objects. However, to associate a product's information with a deal or a quote, you can create a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "line item"
      }), " based on that product. Line items are individual instances of products, and are a separate object from products so that you can tailor the goods and services on a deal or quote as needed without needing to update the product itself."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you're putting together a deal where one of your products is being sold, you'd first create a line item from the product, then associate it with the deal. You can either do this with two separate calls, or with one call that creates and associates the line item. Both options are shown below."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " line items belong to one single parent object. If associating objects, line items should be individual to each object. For example, if you're creating a deal and a quote, you should create one set of line items for the deal, and another set for the quote. This will help streamline CRM data across objects and prevent unexpected data loss when needing to modify line items. For example, deleting a quote will also delete the quote's line items. If those line items are also assocatied with a deal, the deal's line items will also be deleted."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create and associate a line item (multiple calls)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["First, you'll create a line item based on a product with the ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567"
      }), ". For a full list of available line item properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), ". The URL for line items would be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/line_items"
      }), ". Because you're create the line item from an existing product, it will inherit property values from the product, such as price."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_object_id\": \"1234567\", //the object ID of the product\n    \"name\": \"New line item (product-based)\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response will return a line item ID which you can use to associate it with a deal using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API"
      }), ". For this example, assume that the returned line item ID is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "7791176460"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate the line item with an existing deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "14795354663"
      }), "), you'll make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663"
      }), ". This request uses the default association type."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " response will return information similar to the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663\n\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"14795354663\"\n      },\n      \"to\": {\n        \"id\": \"7791176460\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 19\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"7791176460\"\n      },\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 20\n      }\n    }\n  ],\n  \"startedAt\": \"2023-12-21T20:06:52.083Z\",\n  \"completedAt\": \"2023-12-21T20:06:52.192Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, the deal record will display the line item in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Line items"
      }), " card."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create and associate a line item (single call)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a line item from an existing product and associate it with a deal using a single call, you can include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " array in the line item create request."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create the line item, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_item"
      }), ". Your request body will look similar to the following. Note that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " for the line item-deal association is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "20"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
        children: "association types between different types of CRM records"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_object_id\": \"1234567\", //the object ID of the product\n    \"name\": \"New line item (product-based)\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " response will return details about the new line item. In HubSpot, the deal record will display the line item in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Line items"
      }), " card."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}