"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044599;
const slug = exports.slug = 'guides/cms/storage/file-manager';
const title = exports.title = 'Administrador de archivos';
const name = exports.name = 'EMEA ES (es) File Manager';
const metaDescription = exports.metaDescription = 'Usar el Administrador de archivos de HubSpot para alojar imágenes en la red de distribución de contenido de CMS de HubSpot y permitir que los creadores de contenido agreguen fácilmente archivos al contenido. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cuando-usar-el-administrador-de-archivos",
  "label": "Cuando usar el Administrador de archivos",
  "parent": null
}, {
  "depth": 0,
  "id": "carga-de-archivos-en-el-administrador-de-archivos",
  "label": "Carga de archivos en el Administrador de archivos",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-archivos-del-administrador-de-archivos",
  "label": "Usar archivos del Administrador de archivos",
  "parent": null
}, {
  "depth": 0,
  "id": "optimizaciones",
  "label": "Optimizaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "servir-archivos-html-y-js-desde-el-administrador-de-archivos",
  "label": "Servir archivos HTML y JS desde el administrador de archivos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Administrador de archivos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además del sistema de archivos de desarrollador, el administrador de archivos de HubSpot se puede usar para almacenar y servir archivos. Los archivos en el administrador de archivos se sirven a través de la red de distribución de contenido (CDN) de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por opción predeterminada, todos los archivos cargados en el administrador de archivos son de acceso público y pueden ser indexados en los motores de búsqueda. Después de cargar tus archivos, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/files/organize-edit-and-delete-files#edit-your-file-s-visibility-settings",
        children: "administrar la configuración de visibilidad de tu archivo"
      }), " para evitar que los archivos se indexen o se acceda a ellos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El Administrador de archivos se puede encontrar en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/files/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Archivo y plantillas > Archivos"
        })
      }), " en el menú de navegación superior."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/image-png-May-24-2023-05-49-31-9738-AM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuando usar el Administrador de archivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por lo general, el administrador de archivos se debe usar para archivos que están destinados a ser utilizados en selectores de archivos en todo el software HubSpot. Por ejemplo, para seleccionar una imagen en una imagen o módulo de texto enriquecido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de cargar archivos para usar con el administrador de archivos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se aplicarán ciertos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/files/supported-file-types#files-tool",
          children: "límites de tamaño y tipo de archivo"
        }), ". Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/files/upload-files-to-use-in-your-hubspot-content#before-you-get-started",
          children: "qué considerar antes de cargar archivos"
        }), " al administrador de archivos."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los archivos cargados en el administrador de archivos no se pueden editar dentro de la aplicación HubSpot, aparte de pequeñas ediciones de archivos de imagen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si tienes la intención de editar archivos basados en texto, deben estar almacenados en el administrador de diseño."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los archivos basados en texto cargados en el Administrador de archivos no se minimizarán ni modificarán de ninguna manera. Si deseas aprovechar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/developer-reference/cdn#javascript-minification",
          children: "minificación de JavaScript"
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "minificación y combinación de CSS"
        }), " de HubSpot, almacena estos archivos en el administrador de diseño."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Carga de archivos en el Administrador de archivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los archivos se pueden cargar en el administrador de archivos a través de las siguientes opciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para cargar archivos directamente en HubSpot, descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/files/upload-files-to-use-in-your-hubspot-content",
          children: "cargar archivos en el administrador de archivos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para cargar archivos a través de la CLI de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS,"
        }), " usa el comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload filemanager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para cargar archivos usando una API, obtén más información sobre la ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/api/files/v3#post-%2Ffiles%2Fv3%2Ffiles",
          children: ["API ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Subir un nuevo archivo"
          }), " de HubSpot"]
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de cargar tus archivos al administrador de archivos, descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/files/organize-edit-and-delete-files",
        children: "organizar y administrar tus archivos y detalles de archivo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar archivos del Administrador de archivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se puede acceder a los archivos cargados al administrador de archivos a través de las siguientes opciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Todos los archivos cargados en el Administrador de archivos son accesibles en los diferentes selectores de archivos en todo HubSpot y el CMS de HubSpot, como en los módulos de texto enriquecido o de imagen en las páginas."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se puede acceder a los archivos cargados al administrador de archivos a través de un enlace de descarga directa. Descubre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/files/provide-a-direct-download-link-to-a-file-hosted-on-the-files-tool",
          children: "recuperar el enlace de descarga directa de un archivo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Optimizaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los archivos del Administrador de archivos se ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/cdn#browser-and-server-caching",
        children: "almacenan en caché"
      }), ", se ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/cdn#image-compression-optimization-and-automatic-image-resizing",
        children: "comprimen y se redimensionan automáticamente para que se puedan servir de manera eficiente"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/cdn#domain-rewriting",
        children: "accesible en todos los dominios alojados para reducir las solicitudes de origen cruzado"
      }), ". Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "CDN, la seguridad y el rendimiento de HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Servir archivos HTML y JS desde el administrador de archivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los archivos HTML y JavaScript cargados en el Administrador de archivos y servidos usando un dominio de HubSpot predeterminado (p. ej., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "f.hubspotusercontentXX.net"
      }), ") usan Tipo de contenido: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text/plain"
      }), " . Esto significa que los navegadores web no mostrarán ni evaluarán el código."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si un usuario va directamente a un archivo HTML, se mostrará el código HTML al usuario. Para evitar esto, debes servir estos archivos desde uno de tus dominios conectados en lugar de un dominio predeterminado de HubSpot."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}