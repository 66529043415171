"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42366481032;
const slug = exports.slug = 'guides/api/conversations/working-with-chatbots/code-snippets';
const title = exports.title = 'コミュニケーション｜ボットでコードスニペットを実行する';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Running Code Snippets in bots | 202104';
const metaDescription = exports.metaDescription = 'ボットアクションとして独自のコードスニペットを実行する手順';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%88%B6%E9%99%90%E4%BA%8B%E9%A0%85",
  "label": "制限事項",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%BD%BF%E7%94%A8%E5%8F%AF%E8%83%BD%E3%81%AA%E3%83%A9%E3%82%A4%E3%83%96%E3%83%A9%E3%83%AA%E3%83%BC",
  "label": "使用可能なライブラリー",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h2: "h2"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ボットでコードスニペットを実行する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/chatflows/create-a-live-chat",
        children: "ボットを作成または編集する"
      }), "際には、通常の［+］をクリックして", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/articles/kcs_article/conversations/a-guide-to-bot-actions",
        children: "アクションを追加"
      }), "することで、コードスニペットを追加できます。アクション選択パネルで、［コードスニペットを実行］をクリックします。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/run-a-code-snippet-2.png?width=322&name=run-a-code-snippet-2.png",
        alt: "run-a-code-snippet-2"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次に、アクションにニックネームを付けます。コード編集ペイン内に、Node.js 10.xの既定のテンプレートが表示されます。「イベント」オブジェクト、および利用可能なレスポンスオブジェクトの形式については、以下で詳しく説明します。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/run-a-code-snippet-editor.png?width=388&name=run-a-code-snippet-editor.png",
        alt: "run-a-code-snippet-editor"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コミュニケーション内の保存済みアクションに到達すると、このコードが起動されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードスニペットの使用については、主に次の3つの点に留意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コードスニペットのアクションが実行されると、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main()"
        }), "関数が呼び出されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "event"
        }), "引数は、訪問者とチャットセッションの詳細を格納するオブジェクトです。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "callback()"
        }), "関数は、ボットとユーザーにデータを返すために使用します。この関数は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main"
        }), "関数内で呼び出す必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "event"
      }), "オブジェクトには、以下のデータが格納されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example payload\n{\n  \"userMessage\": {\n  // Details for the last message sent to your bot\n    \"message\": \"100-500\",\n    // The last message received by your bot, sent by the visitor\n    \"quickReply\": {\n    // If the visitor selected any quick reply options, this will be a list of the selected options.\n    // Will be 'null' if no options were selected.\n      \"quickReplies\":[\n      // A list of quick reply options selected by the visitor\n         {\n            \"value\":\"100-500\",\n            \"label\":\"100-500\"\n         }\n      ],\n  },\n  \"session\": {\n    \"vid\": 12345,\n    // The contact VID of the visitor, if known.\n    \"properties\": {\n    // A list of properties collected by the bot in the current session.\n      \"CONTACT\": {\n        \"firstname\": {\n          \"value\": \"John\",\n          \"syncedAt\": 1534362540592\n        },\n        \"email\": {\n          \"value\": \"testing@domain.com\",\n          \"syncedAt\": 1534362541764\n        },\n        \"lastname\": {\n          \"value\": \"Smith\",\n          \"syncedAt\": 1534362540592\n        }\n      }\n    },\n  \"customState\":{myCustomCounter: 1, myCustomString:\"someString\"}\n  // Only present if it customState was passed in from a previous callback payload\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["データをボットに返送するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback()"
      }), "関数を使用します。引数としては、次のデータを格納するオブジェクトが必要です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//sample payload\n{\n  \"botMessage\": \"Thanks for checking out our website!\",\n  // This is the message your bot will display to the visitor.\n  \"quickReplies\": [{ value:'option',\n  // Passed to the bot as the response on click\n   label:'Option' // Gets displayed as the button label\n    }],\n  // the quickReplies object is optional\n  \"nextModuleNickname\": \"SuggestAwesomeProduct\",\n  // The nickname of the next module the bot should execute. If undefined, the bot will follow the default configured behavior\n  \"responseExpected\": false\n  // If true, the bot will display the returned botMessage, wait for a response, then execute this code snippet again with that new response.\n  \"customState\":{myCustomCounter: 1, myCustomString:\"someString\"}\n  // Optional field to pass along to the next step.\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "制限事項"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ボット内のコードスニペットは20秒以内に実行を完了する必要があり、使用するメモリーは128 MBのみです。これらの制限に違反すると、エラーが発生します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "使用可能なライブラリー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードスニペット内では、広く利用されている次のNode.jsライブラリーを使用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/async",
          children: "async"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/lodash",
          children: "lodash"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/mongoose",
          children: "mongoose"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/mysql",
          children: "mysql"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/redis",
          children: "redis"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/aws-sdk",
          children: "aws-sdk"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ライブラリーは、コードの最上部で通常の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require()"
      }), "関数を使用することでを読み込むことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const request = require('request');\n\nexports.main = (event, callback) => {\n  request('http://time.jsontest.com/', function (error, response, body) {\n    const responseJson = {\n      botMessage: 'The current time in GMT is ' + JSON.parse(body).time,\n      responseExpected: false,\n    };\n\n    callback(responseJson);\n  });\n};\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}