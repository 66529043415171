"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742917;
const slug = exports.slug = 'guides/api/cms/content-audit';
const title = exports.title = 'API de auditoría de contenido de CMS';
const name = exports.name = 'EMEA ES vNext Docs DP - CMS Content Audit API';
const metaDescription = exports.metaDescription = 'La API de auditoría de contenido de CMS te permite filtrar y ordenar cambios en el objeto de contenido por tipo, período de tiempo o ID de usuario de HubSpot, por lo que nunca te quedarás preguntándote qué sucedió.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "lista-de-tipos-de-eventos-de-cms",
  "label": "Lista de tipos de eventos de CMS",
  "parent": null
}, {
  "depth": 0,
  "id": "lista-de-tipos-de-objetos-de-cms",
  "label": "Lista de tipos de objetos de CMS",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Auditoría de contenido de CMS"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La API de auditoría de contenido del CMS te permite filtrar y ordenar cambios en el objeto contenido por tipo, período de tiempo o ID de usuario de HubSpot. Este punto de terminación solo está disponible en cuentas con una suscripción de ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub"
        }), " Enterprise"]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de uso:"
      }), " averigua qué usuario realizó cambios más recientemente en una lista de páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lista de tipos de eventos de CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de evento"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CREADO"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se creó un objeto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ELIMINADO"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se eliminó o se desconectó un objeto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLICADO"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se publicó un objeto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLICACIÓN CANCELADA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se canceló la publicación de un objeto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ACTUALIZADO"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se actualizó un objeto."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lista de tipos de objetos de CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de objeto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a la configuración de tu blog en la configuración de tu cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Publicaciones de blog asociadas a tus blogs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTENT_SETTINGS"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos a la configuración de tu sitio web en la configuración de tu cuenta. Los valores que cambiaron aparecerán en la matriz ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta"
            }), " JSON."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CTA"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos a tus ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/cta/get-started-with-calls-to-action-ctas",
              children: "llamadas a la acción (CTA)."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOMINIO"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a los dominios conectados en tu configuración de Dominios y URL en la configuración de tu cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CORREO ELECTRÓNICO"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos en correos electrónicos en el editor de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ARCHIVO"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos en archivos en la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/cos-general/organize-edit-and-delete-files",
              children: "herramienta Archivos"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GLOBAL_MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos en los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/cos-general/can-i-make-changes-to-a-global-module-in-only-one-template",
              children: "módulos globales."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBDB_TABLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a tablas HubDB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a la configuración de tu base de conocimientos en la configuración de tu cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a artículos de la base de conocimientos en el editor de contenido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a páginas de destino en el editor de contenido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MÓDULO"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview",
              children: "módulos"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SERVERLESS_FUNCTION"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
              children: "funciones sin servidor"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PLANTILLA"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "plantillas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TEMA"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cambios hechos a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/website-pages/edit-your-global-theme-settings",
              children: "Configuración de tema"
            }), " y cuándo se crearon los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/website-pages/edit-your-global-theme-settings",
              children: "Temas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "URL_MAPPING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a tus redireccionamientos de URL en la configuración de Redireccionamientos de URL en la configuración de tu cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBSITE_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cambios hechos a páginas del sitio web en el editor de contenido."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}