"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358755;
const slug = exports.slug = 'guides/api/crm/objects/tickets';
const title = exports.title = 'CRM-API | Tickets';
const name = exports.name = 'vNext Docs DP - Tickets';
const metaDescription = exports.metaDescription = 'Ein Ticket stellt eine Kundenanfrage für Support dar. Tickets-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zwischen HubSpot und anderen Systemen zu synchronisieren. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tickets-erstellen",
  "label": "Tickets erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "tickets-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "tickets-erstellen"
}, {
  "depth": 0,
  "id": "tickets-abrufen",
  "label": "Tickets abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "tickets-aktualisieren",
  "label": "Tickets aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "vorhandene-tickets-mit-datens%C3%A4tzen-oder-aktivit%C3%A4ten-verkn%C3%BCpfen",
  "label": "Vorhandene Tickets mit Datensätzen oder Aktivitäten verknüpfen",
  "parent": "tickets-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "tickets-aktualisieren"
}, {
  "depth": 0,
  "id": "eine-aktivit%C3%A4t-an-einen-ticket-datensatz-anpinnen",
  "label": "Eine Aktivität an einen Ticket-Datensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "tickets-l%C3%B6schen",
  "label": "Tickets löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot stellen Tickets Kundenanfragen für Hilfe oder Support dar. Tickets werden während Ihres Support-Prozesses über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
        children: "Pipeline-Status"
      }), " nachverfolgt, bis sie geschlossen werden. Die Tickets-Endpunkte ermöglichen es Ihnen, Tickets zu erstellen und zu verwalten sowie Ticket-Daten zwischen HubSpot und anderen Systemen zu synchronisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über Objekte, Datensätze, Eigenschaften und Zuordnungen-APIs im Leitfaden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Grundlegendes zum CRM"
      }), ". Weitere allgemeine Informationen zu Objekten und Datensätzen in HubSpot finden Sie in diesem Artikel über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "Verwalten Ihrer CRM-Datenbank"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tickets erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um neue Tickets zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schließen Sie in Ihrer Anfrage Ihre Ticket-Daten in ein properties-Objekt ein. Sie können auch ein associations-Objekt hinzufügen, um Ihrem neuen Ticket vorhandene Datensätze (z. B. Kontakte, Unternehmen) oder Aktivitäten (z. B. Meetings, Notizen) zuzuordnen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ticket-Details werden in Ticket-Eigenschaften gespeichert. Es gibt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
        children: "Standard-HubSpot-Ticket-Eigenschaften"
      }), ", Sie können jedoch auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "benutzerdefinierte Eigenschaften erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen eines neuen Tickets sollten Sie die folgenden Eigenschaften in Ihrer Anfrage berücksichtigen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subject"
      }), " (Name des Tickets), ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pipeline_stage"
      }), " (Status des Tickets) und, wenn Sie mehrere Pipelines haben, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pipeline"
      }), ". Wenn keine Pipeline angegeben ist, wird die Standard-Pipeline verwendet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle verfügbaren Eigenschaften anzuzeigen, können Sie eine Liste der Ticket-Eigenschaften Ihres Accounts abrufen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/tickets"
      }), " durchführen. Erfahren Sie mehr über die", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: " Eigenschaften-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Sie müssen die interne ID eines Ticket-Status oder einer Ticket-Pipeline verwenden, wenn Sie ein Ticket über die API erstellen. Die interne ID ist eine Nummer, die auch zurückgegeben wird, wenn Sie Tickets über die API abrufen. Sie können die interne ID eines Ticket-Status oder einer Ticket-Pipeline in Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
          children: "Ticket-Pipeline-Einstellungen"
        }), " finden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise ein neues Ticket zu erstellen, kann Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen eines neuen Tickets können Sie das Ticket auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "bestehenden Datensätzen"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "Aktivitäten"
      }), " zuordnen, indem Sie ein associations-Objekt einschließen. Um beispielsweise ein neues Ticket mit einem bestehenden Kontakt und Unternehmen zu verknüpfen, müsste Ihre Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 16\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 26\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im associations-Objekt sollten Sie Folgendes einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz oder die Aktivität, den bzw. die Sie dem Ticket zuordnen möchten, angegeben durch seinen eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen Ticket und Datensatz/Aktivität. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tickets abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Tickets einzeln oder mehrere gleichzeitig (batchweise) abrufen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ein einzelnes Ticket abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/{ticketId}"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine Liste aller Tickets anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können für diese Endpunkte die folgenden Abfrageparameter in die Anfrage-URL einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Ticket keinen Wert für eine Eigenschaft hat, wird es nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der aktuellen und historischen Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Ticket keinen Wert für eine Eigenschaft hat, wird es nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekten, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen Batch von bestimmten Tickets nach Datensatz-ID oder einer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "benutzerdefinierten „Eindeutige ID“-Eigenschaft"
        }), " abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/tickets/batch/read"
        }), " durch. Der Batch-Endpunkt kann Zuordnungen ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " abrufen. Erfahren Sie, wie Sie Zuordnungen mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "Zuordnungen-API"
        }), " batchweise lesen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für den Batch-Leseendpunkt können Sie auch den optionalen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter verwenden, um Tickets anhand einer benutzerdefinierten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "„Eindeutige ID“-Eigenschaft"
      }), " abzurufen. Standardmäßig beziehen sich die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Werte in der Anfrage auf die Datensatz-ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), sodass der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter beim Abrufen nach Datensatz-ID nicht erforderlich ist. Um eine benutzerdefinierte „Eindeutiger Wert“-Eigenschaft zum Abrufen von Tickets zu verwenden, müssen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter einschließen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise einen Batch von Tickets abzurufen, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"subject\", \"hs_pipeline_stage\", \"hs_pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"4444888856\"\n    },\n    {\n      \"id\": \"666699988\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"subject\", \"hs_pipeline_stage\", \"hs_pipeline\"],\n  \"idProperty\": \"uniquepropertyexample\",\n  \"inputs\": [\n    {\n      \"id\": \"abc\"\n    },\n    {\n      \"id\": \"def\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um Tickets mit aktuellen und historischen Werten für eine Eigenschaft abzurufen, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"hs_pipeline_stage\"],\n  \"inputs\": [\n    {\n      \"id\": \"4444888856\"\n    },\n    {\n      \"id\": \"666699988\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tickets aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Tickets einzeln oder mehrere gleichzeitig (batchweise) aktualisieren. Bei vorhandenen Tickets ist die Datensatz-ID ein eindeutiger Standardwert, mit dem Sie das Ticket über die API aktualisieren können. Sie können Tickets jedoch auch mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "benutzerdefinierter „Eindeutige ID“-Eigenschaften identifizieren und aktualisieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ein einzelnes Ticket anhand seiner Datensatz-ID zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/{ticketId}"
        }), " durch und schließen Sie die Daten ein, die Sie aktualisieren möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um mehrere Tickets zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/batch/update"
        }), " durch. Fügen Sie im Anfragetext ein Array mit den IDs für die Tickets und die Eigenschaften hinzu, die Sie aktualisieren möchten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorhandene Tickets mit Datensätzen oder Aktivitäten verknüpfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Ticket anderen CRM-Datensätzen oder einer Aktivität zuzuordnen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "-Wert abzurufen, verweisen Sie auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "diese Liste"
        }), " der Standardwerte oder führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), " durch."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Zuordnung zwischen einem Ticket und einem Datensatz oder einer Aktivität zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an die folgende URL durch: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Aktivität an einen Ticket-Datensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine Aktivität über die API an eine Ticket-Datensatz anpinnen, indem Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld in Ihre Anfrage aufnehmen. Geben Sie im Feld die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " der anzupinnenden Aktivität an, die über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen-APIs"
      }), " abgerufen werden kann. Sie können eine Aktivität pro Datensatz anpinnen, und die Aktivität muss bereits vor dem Anheften mit dem Ticket verknüpft sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um die angepinnten Aktivitäten eines Tickets festzulegen oder zu aktualisieren, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/tickets/{ticketId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können auch ein Ticket erstellen, es mit einer vorhandenen Aktivität verknüpfen und die Aktivität in derselben Anfrage anpinnen. Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/tickets\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 227\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tickets löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Tickets einzeln oder mehrere gleichzeitig (batchweise) löschen, wodurch der Ticket in den Papierkorb in HubSpot verschoben wird. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "das Ticket später in HubSpot wiederherstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein einzelnes Ticket anhand seiner ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über das batchweise Löschen von Tickets, indem Sie oben in diesem Artikel auf die Registerkarte* Endpunkte* klicken."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}