"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601074021;
const slug = exports.slug = 'getting-started/overview';
const title = exports.title = 'Resumen de Primeros pasos';
const name = exports.name = 'Resumen de Primeros pasos (documentos para desarrolladores 2024)';
const metaDescription = exports.metaDescription = 'Aprende los primeros pasos para desarrollar en HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "las-ventajas-de-una-plataforma-conectada",
  "label": "Las ventajas de una plataforma conectada",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-instala-herramientas-que-te-ayuden-a-construir",
  "label": "1. Instala herramientas que te ayuden a construir",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-decide-qu%C3%A9-construir",
  "label": "2. Decide qué construir",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%C3%BAnete-a-tus-pares",
  "label": "3. Únete a tus pares",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-obt%C3%A9n-m%C3%A1s-informaci%C3%B3n%2C-a-tu-manera",
  "label": "4. Obtén más información, a tu manera",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de Primeros pasos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tanto si eres un nuevo desarrollador de HubSpot como si quieres ampliar tus conocimientos, esta sección te guiará a través del proceso de puesta en marcha. Explorarás las distintas vías de desarrollo disponibles, así como las herramientas y cuentas específicas que necesitarás para alcanzar tus metas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Las ventajas de una plataforma conectada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Primero, es útil considerar qué hace de HubSpot una potente plataforma para desarrolladores."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La base de toda cuenta de HubSpot es la plataforma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRM"
      }), " (Customer Relationship Management), una base de datos de relaciones y procesos de negocios. HubSpot ofrece diferentes vías para que desarrolladores y partners externos trabajen con el CRM y puedan crear valor agregado para los usuarios finales de HubSpot. Esto incluye:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crear integraciones y/o extensiones con las API de HubSpot para sincronizar datos entre HubSpot y plataformas externas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Construir sitios web personalizados utilizando el CMS (Sistema de gestión de contenido) de HubSpot. Además de un conjunto completo de herramientas de gestión de contenido, el CMS se integra totalmente con el CRM. Esto facilita el lanzamiento de campañas, la captación de leads y las comunicaciones continuas con los clientes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Personalización del CRM con extensiones de IU. Los desarrolladores pueden utilizar React para crear tarjetas personalizadas flexibles que se integran perfectamente con HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la siguiente sección, obtendrás un resumen de las herramientas que están disponibles para los desarrolladores de HubSpot. A continuación, elegirás una ruta para continuar tu viaje como desarrollador de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Instala herramientas que te ayuden a construir"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay una amplia gama de herramientas disponibles para los desarrolladores de HubSpot. La combinación de herramientas que necesitarás dependerá de tus metas específicas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CLI de HubSpot:"
        }), " Instalar la CLI de HubSpot para el desarrollo local es un gran punto de partida para muchas tareas de desarrollo. La CLI se utiliza al crear extensiones de IU y recursos de CMS, y puede utilizarse para crear y gestionar secretos, tablas de HubDB y datos de objetos personalizados de tu cuenta. Necesitarás tener instalado ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: (0, _jsxRuntime.jsx)("u", {
            children: "Node.js"
          })
        }), " (se requiere la versión 18 o superior, y se recomienda la versión LTS). Para instalar el CLI globalmente, ejecuta el siguiente comando:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Visual Studio Code:"
          }), " Instalar ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://code.visualstudio.com/",
            children: "Visual Studio Code"
          }), " significa que puedes aprovechar la extensión ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
            children: "Visual Studio Code de HubSpot"
          }), ", lo cual te permite centralizar tu workflow de desarrollo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Bibliotecas de clientes"
          }), ": HubSpot proporciona bibliotecas de clientes para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-nodejs",
            children: "Node"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-php",
            children: "PHP"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-ruby",
            children: "Ruby"
          }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-python",
            children: "Python"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Postman:"
          }), " utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/",
            children: "Postman"
          }), " para acceder a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/hubspot",
            children: "Colecciones de Postman de HubSpot"
          }), ". A lo largo de la documentación de la API de HubSpot, encontrarás botones para ejecutar llamadas utilizando la colección."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/run-in-postman-current-docs-site.png",
            alt: "run-in-postman-current-docs-site"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/tools-to-help-you-build",
        children: "herramientas para desarrolladores de de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Decide qué construir"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No hay un camino único para desarrollar con HubSpot. Es una plataforma flexible que puede satisfacer una amplia gama de necesidades."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una de las cuatro amplias categorías que se enumeran a continuación debería cubrir la mayoría de estas necesidades. Puedes elegir la que sea más relevante para ti en este momento para una breve introducción, junto con algunos pasos inmediatos para empezar. También puedes hacerte una idea de cuáles son las mejores herramientas para el trabajo, y si se requiere un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "tipo específico de cuenta de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#use-apis-and-build-integrations",
          children: "Utilizar API y crear integraciones"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-for-the-app-marketplace",
          children: "Crear aplicaciones para el mercado"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#customize-the-hubspot-ui",
          children: "Personalizar la IU de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-custom-websites",
          children: "Crear sitios web personalizados"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Únete a tus pares"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Conecta con tus pares que ya están construyendo en HubSpot. Haz preguntas, comparte conocimientos, construye una comunidad e incluso puede que hagas amigos por el camino."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "Slack para desarrolladores"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
          children: "Foros de desarrolladores"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hubspotdev",
          children: "Developer Advocacy GitHub"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Obtén más información, a tu manera"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que estés en marcha, hay muchos recursos disponibles para ayudarte a profundizar. Puedes acceder a guías detalladas, documentos de referencia detallados o a los cursos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses",
        children: "HubSpot Academy"
      }), ", según tu estilo de aprendizaje preferido."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}