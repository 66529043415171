"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694136;
const slug = exports.slug = 'guides/cms/content/forms';
const title = exports.title = 'Formulários do HubSpot';
const name = exports.name = 'Formulários do HubSpot';
const metaDescription = exports.metaDescription = 'Saiba como adicionar formulários ao seu site da HubSpot ou páginas externas usando módulos, HubL ou o código de incorporação. Em seguida, saiba como estilizá-los.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "o-m%C3%B3dulo-de-formul%C3%A1rio-padr%C3%A3o",
  "label": "O módulo de formulário padrão",
  "parent": null
}, {
  "depth": 1,
  "id": "clone-do-m%C3%B3dulo-padr%C3%A3o",
  "label": "Clone do módulo padrão",
  "parent": "o-m%C3%B3dulo-de-formul%C3%A1rio-padr%C3%A3o"
}, {
  "depth": 0,
  "id": "campos-de-formul%C3%A1rio-em-m%C3%B3dulos-personalizados",
  "label": "Campos de formulário em módulos personalizados",
  "parent": null
}, {
  "depth": 1,
  "id": "limitar-op%C3%A7%C3%B5es-de-formul%C3%A1rio-no-editor",
  "label": "Limitar opções de formulário no editor",
  "parent": "campos-de-formul%C3%A1rio-em-m%C3%B3dulos-personalizados"
}, {
  "depth": 0,
  "id": "usar-o-c%C3%B3digo-de-incorpora%C3%A7%C3%A3o-de-formul%C3%A1rio",
  "label": "Usar o código de incorporação de formulário",
  "parent": null
}, {
  "depth": 1,
  "id": "adicionar-internacionaliza%C3%A7%C3%A3o",
  "label": "Adicionar internacionalização",
  "parent": "usar-o-c%C3%B3digo-de-incorpora%C3%A7%C3%A3o-de-formul%C3%A1rio"
}, {
  "depth": 0,
  "id": "eventos-de-formul%C3%A1rio",
  "label": "Eventos de formulário",
  "parent": null
}, {
  "depth": 0,
  "id": "estilos-do-formul%C3%A1rio",
  "label": "Estilos do formulário",
  "parent": null
}, {
  "depth": 1,
  "id": "estilizar-os-formul%C3%A1rios-por-meio-do-m%C3%B3dulo-de-formul%C3%A1rio-padr%C3%A3o-ou-da-tag-hubl",
  "label": "Estilizar os formulários por meio do módulo de formulário padrão ou da tag HubL",
  "parent": "estilos-do-formul%C3%A1rio"
}, {
  "depth": 1,
  "id": "estilizar-os-formul%C3%A1rios-por-meio-de-um-m%C3%B3dulo-personalizado",
  "label": "Estilizar os formulários por meio de um módulo personalizado",
  "parent": "estilos-do-formul%C3%A1rio"
}, {
  "depth": 1,
  "id": "estilizar-os-formul%C3%A1rios-adicionados-por-meio-do-c%C3%B3digo-de-incorpora%C3%A7%C3%A3o-de-formul%C3%A1rios",
  "label": "Estilizar os formulários adicionados por meio do código de incorporação de formulários",
  "parent": "estilos-do-formul%C3%A1rio"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Formulários do HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use os formulários da HubSpot para capturar informações de usuários do site, que você pode acessar em todo o HubSpot. Você pode compartilhar links para formulários diretamente com os usuários, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "enviar dados de formulário por meio da API"
      }), " e incorporá-los às páginas do site usando o CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os formulários são uma parte essencial da HubSpot e podem ser criados em contas da HubSpot de qualquer nível de assinatura. Os formulários não são importantes apenas para a conversão do cliente, mas também porque os dados do formulário podem ser usados em outras ferramentas e ativos da HubSpot, como conteúdo inteligente, listas, fluxos de trabalho, personalização de conteúdo e muito mais."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms",
        children: "criar um formulário da HubSpot"
      }), ", você pode adicioná-lo aos seus modelos e páginas. Existem algumas maneiras de adicionar um formulário a um modelo, dependendo do seu caso de uso:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-default-form-module",
          children: "Uso do módulo de formulário padrão"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#form-fields-in-custom-modules",
          children: "Adição de um campo de formulário a um módulo personalizado"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-hubl-tag"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-embed-code",
          children: "Incorporação usando o código de incorporação de formulários"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "O módulo de formulário padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se o seu modelo tiver ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "áreas de arrastar e soltar"
      }), ", os criadores de conteúdo poderão adicionar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "módulo de formulário padrão"
      }), " a uma página usando o editor de páginas e, em seguida, configurar as opções de formulário na barra lateral esquerda."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para codificar um módulo de formulário diretamente para um modelo com áreas de arrastar e soltar, faça referência a ele como um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"dnd_area\" class='body-container body-container__landing', label='Main section' %}\n {% dnd_section vertical_alignment='MIDDLE' %}\n  {% dnd_column width=6, offset=6 %}\n   {% dnd_row %}\n\n    <!-- Form module tag for use in templates -->\n    {% dnd_module path='@hubspot/form' %}\n    {% end_dnd_module %}\n\n   {% end_dnd_row %}\n  {% end_dnd_column %}\n {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para adicionar um módulo de formulário a um modelo fora de uma área de arrastar e soltar, faça referência a ele como um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), " padrão."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com qualquer implementação, você pode adicionar parâmetros à tag do módulo para especificar configurações, como o formulário a ser usado e as opções de redirecionamento, conforme mostrado no exemplo de código acima. Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "documentação dos módulos padrão"
      }), " para obter mais informações sobre os parâmetros disponíveis."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Clone do módulo padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além de usar o módulo padrão como está, você pode cloná-lo para torná-lo editável, permitindo personalizá-lo conforme necessário. Por exemplo, você poderia clonar o módulo de formulário padrão, adicionar um campo de cor e agrupar o HTML do módulo em uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), " com estilo para adicionar a cor como plano de fundo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No gerenciador de design da barra lateral esquerda, acesse a pasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "@hubspot"
        }), ", clique com o botão direito em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "form.module"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Clonar módulo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/clone-form-module.png",
        alt: "clone-form-module"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral direita, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar campo"
        }), " e, em seguida, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cor"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Adicione uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<section>"
        }), " em torno do conteúdo HTML e, em seguida, inclua o estilo para fazer referência ao campo de cor, como:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section style=\"background:{{ module.color_field.color }}\">"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/default-form-module-clone-section.png",
        alt: "default-form-module-clone-section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos de formulário em módulos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar um módulo personalizado, você pode incluir um formulário nele adicionando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#form",
        children: "campo de formulário"
      }), ", além de adicionar o snippet de código do campo ao HTML do módulo. Por exemplo, você pode adicionar um formulário de solicitação de consulta a um módulo que contenha uma imagem de um produto e uma proposta de valor descritiva."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para adicionar um campo de formulário a um módulo personalizado com o gerenciador de design:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral direita do editor de módulos, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar campo"
        }), ", em seguida, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Formulário"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-select-form-field.png",
        alt: "design-manager-select-form-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depois de adicionar o campo, passe o cursor do mouse sobre o campo na barra lateral direita, em seguida, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ações"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar snippet"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-copy-snippet.png",
        alt: "module-field-copy-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cole o snippet no campo de HTML do módulo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-paste-snippet.png",
        alt: "module-field-paste-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limitar opções de formulário no editor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de adicionado a uma página, os criadores de conteúdo normalmente têm controle sobre muitos aspectos do formulário, incluindo qual formulário usar e os próprios campos do formulário. No entanto, você pode limitar o controle fornecido no editor de páginas, modificando o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " do módulo de formulário ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "localmente"
      }), " para incluir os seguintes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Defina a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para ocultar todos os controles de edição de formulário integrados no módulo de formulário. Isso inclui os campos do formulário, o texto do botão de envio, as opções de privacidade e consentimento de dados e o CAPTCHA."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required_property_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma matriz que especifica quais formulários podem ser selecionados com base nos tipos de propriedade dos campos do formulário. Os valores incluem: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CONTACT\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"COMPANY\""
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TICKET\""
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, caso tenha criado um módulo que deve ser usado apenas para formulários que permitem aos visitantes entrar em contato com os vários departamentos de serviços da sua empresa, você pode permitir que os criadores de conteúdo selecionem apenas os formulários que usam propriedades de tickets."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Form field\n{\n  \"id\": \"843b4f0f-0ed7-5b10-e86a-5cc8a0f11a0c\",\n  \"name\": \"form_field_1\",\n  \"display_width\": null,\n  \"label\": \"Form\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"form\",\n  \"disable_inline_form_editing\": true,\n  \"required_property_types\": [\"TICKET\"],\n  \"default\": {\n    \"response_type\": \"inline\",\n    \"message\": \"Thanks for submitting the form.\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar o código de incorporação de formulário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando uma tag do módulo ou do HubL não é ideal, ou se você deseja adicionar um formulário a uma página externa, você pode incorporá-lo usando o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code",
        children: "Código de incorporação do formulário"
      }), ". Você também pode personalizar o código de incorporação do formulário para estender a funcionalidade do formulário. Veja todas as opções de personalização do código de incorporação de formulário e muito mais no guia de referência de formulários."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["As opções de personalização de formulário estão disponíveis somente para formulários criados no HubSpot que foram ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#with-css-in-your-external-stylesheet-marketing-hub-professional-enterprise-or-legacy-marketing-hub-basic-only",
            children: "definidos como HTML bruto"
          }), ". A conta da HubSpot deve ter uma assinatura do ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Content Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Formulários da HubSpot somente devem ser carregados usando o arquivo JavaScript hospedado no HubSpot. ", (0, _jsxRuntime.jsx)("u", {
            children: "Não"
          }), " é possível copiar o código de incorporação do formulário e hospedá-lo por conta própria. Quaisquer melhorias que o HubSpot fizer para melhorar a segurança, a detecção de spam, a acessibilidade e o desempenho não serão propagadas para o seu formulário se você decidir hospedar por conta própria o código de incorporação do formulário do HubSpot."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja a seguir um exemplo de um formulário de código de incorporação sem nenhuma personalização."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    region: 'na1',\n    portalId: '123456',\n    formId: 'df93f0c1-2919-44ef-9446-6a29f9a7f',\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número ou string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da conta da HubSpot na qual o formulário foi criado. Isso é usado para recuperar a definição do formulário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do formulário, que é usado para recuperar a definição do formulário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "region"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
              children: "região"
            }), " da conta em que o formulário foi criado. Isso é usado para recuperar a definição do formulário. Estes são os possíveis valores: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "na1"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eu1"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar internacionalização"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por padrão, o HubSpot fornece um número de mensagens de validação em vários idiomas diferentes, que você pode acessar usando o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), ". Você também pode adicionar idiomas personalizados ou substituir as mensagens de erro e os meses/dias do seletor de data exibidos no formulário usando o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre internacionalização e mensagens de erro de validação de formulários na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "documentação de referência de formulários"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      fieldLabels: {\n        email: 'Electronic mail',\n      },\n      required: \"Hey! That's required!\",\n      submitText: 'Custom Submit Button Text',\n      submissionErrors: {\n        MISSING_REQUIRED_FIELDS: 'Please fill in all required fields!',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eventos de formulário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os formulários permitem duas maneiras de vincular a funcionalidade aos eventos: retornos de chamada no código de incorporação de formulário do HubSpot e eventos de formulário globais. Saiba mais sobre os retornos de chamada de código de incorporação de formulários e os eventos de formulário globais disponíveis na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "documentação de referência de formulários"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example form embed code callback\nhbspt.forms.create({\n  portalId: '',\n  formId: '',\n  onBeforeFormSubmit: function ($form) {\n    // YOUR SCRIPT HERE\n  },\n});\n\n// Example listener\nwindow.addEventListener('message', (event) => {\n  if (\n    event.data.type === 'hsFormCallback' &&\n    event.data.eventName === 'onFormSubmitted'\n  ) {\n    someAnalyticsLib('formSubmitted');\n    console.log('Form Submitted! Event data: ${event.data}');\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estilos do formulário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Embora o HubSpot ofereça ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "estilos de formulário de uma configuração global"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "nível de configuração específico de formulário"
      }), ", você também pode definir estilos a um formulário dependendo de como ele é adicionado às suas páginas do CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " todos os formulários gerados no HubSpot CMS (excluindo o uso do código de incorporação de formulário) ignorarão qualquer estilo definido por meio das configurações globais ou de nível individual do formulário."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilizar os formulários por meio do módulo de formulário padrão ou da tag HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os formulários da HubSpot adicionados às páginas da HubSpot podem ser estilizados usando o CSS do seu site. A HubSpot inclui várias classes e atributos diferentes em formulários gerados nos quais você pode aplicar estilo. Como ponto de partida, consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/elements/_forms.css",
        children: "CSS de formulário no Boilerplate da HubSpot"
      }), ", que representa as práticas recomendadas para definir estilos aos formulários."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilizar os formulários por meio de um módulo personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os formulários dentro dos módulos personalizados podem ser estilizados por CSS no painel de CSS do módulo no gerenciador de design. Ao manter o escopo do CSS no módulo, você pode garantir que, sempre que o módulo for adicionado a uma página, o estilo virá com ele. É recomendável adicionar um wrapper ao formulário e usá-lo como seletor de nível superior para o CSS. Assim, o estilo de formulário do módulo personalizado não será substituído por estilos adicionais na folha de estilo principal do site. Veja a seguir uma captura de tela do módulo personalizado mencionado acima com o estilo de formulário adicionado ao Painel CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/form-module-css-pane-4.jpg",
        alt: "Personalizar o módulo com CSS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilizar os formulários adicionados por meio do código de incorporação de formulários"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao usar o código de incorporação de formulários, você pode estilizar o formulário usando as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "configurações globais de estilo de formulário"
      }), " ou usando o CSS do seu site."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O uso das configurações globais de estilo de formulário permite definir configurações padrão para cada formulário na conta. Você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "substituir esses estilos em um formulário individual dentro do editor de formulários"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/global-form-styling-options-4.png",
        alt: "Estilos de formulários globais"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você tiver uma assinatura do ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ou do ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", poderá selecionar a opção ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Definir como formulário HTML bruto"
      }), " ao criar um formulário. Essa configuração renderiza o formulário como HTML em vez de iframe, o que facilita estilizar o formulário incorporado com CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site",
        children: "estilizar formulários incorporados na Central de Conhecimento"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/set-as-raw-html-form-4.jpg",
        alt: "Definir como formulário HTML bruto"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}