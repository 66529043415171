"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093033;
const slug = exports.slug = 'guides/cms/setup/github-integration';
const title = exports.title = 'Eine kontinuierliche Integration mit einem GitHub-Repository mithilfe von GitHub-Aktionen einrichten';
const name = exports.name = 'EMEA DACH (DE) Setting up continuous integration with a GitHub repository using GitHub Actions';
const metaDescription = exports.metaDescription = 'Richten Sie eine kontinuierliche Integration mit einem GitHub-Repository mithilfe von GitHub-Aktionen ein.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "lokale-dateien-an-github-senden",
  "label": "Lokale Dateien an GitHub senden",
  "parent": null
}, {
  "depth": 0,
  "id": "verwenden-sie-die-cms-hub-bereitstellungsaktion-in-github-empfohlen",
  "label": "Verwenden Sie die CMS Hub-Bereitstellungsaktion in GitHub (empfohlen)",
  "parent": null
}, {
  "depth": 0,
  "id": "einen-pull-request-erstellen-und-in-der-hauptverzweigung-zusammenf%C3%BChren",
  "label": "Einen Pull Request erstellen und in der Hauptverzweigung zusammenführen",
  "parent": null
}, {
  "depth": 0,
  "id": "ihr-element-im-design-manager-sperren",
  "label": "Ihr Element im Design-Manager sperren",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Eine kontinuierliche Integration mit einem GitHub-Repository mithilfe von GitHub-Aktionen einrichten"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Als Teil Ihres ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Entwicklungsworkflows"
      }), " ziehen Sie es möglicherweise vor, die Datenquelle Ihrer Produktionscodebasis in der Versionskontrolle zu behalten. Dies wäre besonders hilfreich, wenn Sie als Teil eines Entwicklungsteams arbeiten, damit Sie Änderungen nachverfolgen und bei Bedarf schnell zurücksetzen können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/features/actions",
        children: "GitHub-Aktionen"
      }), " können Sie eine kontinuierliche Integration mit einem GitHub-Repository einrichten. Dieser Leitfaden geht durch den Integrationsprozess und geht davon aus, dass Sie mit Folgendem vertraut sind:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.github.com/en/get-started/using-git",
          children: "Verwenden von Git"
        }), " und GitHub"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen von Websites mithilfe des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie, wie Sie die Integration mit der CMS Hub-Bereitstellungsaktion in GitHub (empfohlen) oder manuell einrichten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lokale Dateien an GitHub senden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie mit GitHub integrieren können, müssen Sie zunächst Ihre Dateien lokal erfassen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie ein vorhandenes CMS-Element haben, das sich in HubSpot befindet, z. B. ein Design oder eine Gruppe von Vorlagen, können Sie es abrufen, indem Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#fetch",
          children: "fetch"
        }), "-Befehl wie folgt ausführen: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch <HubSpot_src> <local_dest>"
        }), ". Alternativ können Sie alle Dateien im ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "Entwickler-Dateisystem"
        }), " des Accounts herunterladen, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch /"
        }), " ausführen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ein neues lokales Projekt zu erstellen, wird empfohlen, mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "CMS Theme Boilerplate"
        }), " zu beginnen. Wenn Sie noch nicht mit der CMS Theme Boilerplate gearbeitet haben, lesen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Schnellanleitung"
        }), ". Wenn Sie das HubSpot CLI bereits installiert und Ihre lokale Umgebung konfiguriert haben, können Sie ein neues lokales Design über die Boilerplate erstellen, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs create website-theme <new-theme-name>"
        }), " ausführen. Sie müssen dann Ihre Dateien mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#upload",
          children: "hs upload"
        }), "-Befehl nach HubSpot hochladen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ihr Code ist nun lokal verfügbar und Sie können ihn dann ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.github.com/en/get-started/importing-your-projects-to-github/importing-source-code-to-github/adding-locally-hosted-code-to-github",
        children: "einem GitHub-Repository hinzufügen"
      }), ". Nachdem Sie Ihre Dateien zu GitHub hinzugefügt haben, fahren Sie mit dem nächsten Schritt fort, um entweder die vorgefertigte GitHub-Aktion von HubSpot zu installieren (empfohlen) oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manually-configure-the-action",
        children: "die Aktion manuell zu konfigurieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwenden Sie die CMS Hub-Bereitstellungsaktion in GitHub (empfohlen)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um den Prozess zu optimieren, hat HubSpot eine GitHub-Aktion erstellt, die Sie in Ihrem GitHub-Projekt installieren können, um die automatische Bereitstellung von Änderungen aus einer Verzweigung in Ihrem HubSpot-Produktions-Account zu verarbeiten."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "e0132707-395d-4617-bd9d-0b21c8b129d5",
      external: true,
      label: "Install automatic CMS deploy GitHub action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Pull Request erstellen und in der Hauptverzweigung zusammenführen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erstellen Sie mit Ihren Geheimnissen, Workflows und Skripts in Ihrem GitHub-Repository einen Pull Request und führen Sie ihn in der Hauptverzweigung zusammen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie nach dem Zusammenführen des Pull Requests zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Aktionen“"
        }), ". Sie sollten sehen, dass Ihre Bereitstellungsaktion ausgeführt, die dann Ihren Code in Ihrem HubSpot-Account bereitstellt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihr Element im Design-Manager sperren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jetzt, da sich Ihre Datenquelle in GitHub befindet, sollten Sie Ihr Element in HubSpot sperren, um zu verhindern, dass dort Änderungen vorgenommen werden. Dadurch wird sichergestellt, dass Änderungen nur durch die Bereitstellungsaktion erfolgen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So sperren Sie Elemente im Design-Manager:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Marketing“"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Dateien und Vorlagen“"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Design-Tools“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Suchen Sie den Ordner Ihres Elements in der linken Seitenleiste, ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "klicken Sie mit der rechten Maustaste"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Ordner sperren“"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-lock-folder.png",
        alt: "design-manager-lock-folder"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}