"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 47364466212;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/sections';
const title = exports.title = 'Sections';
const name = exports.name = 'Sections';
const metaDescription = exports.metaDescription = 'Sections are the outermost child element of a dnd_area. Sections can be made reusable through section templates or saving them within the page editor.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "overview",
  "label": "Overview",
  "parent": null
}, {
  "depth": 0,
  "id": "create-reusable-sections",
  "label": "Create reusable sections",
  "parent": null
}, {
  "depth": 1,
  "id": "section-template-files",
  "label": "Section template files",
  "parent": "create-reusable-sections"
}, {
  "depth": 1,
  "id": "add-a-section-partial-to-a-template",
  "label": "Add a section partial to a template",
  "parent": "create-reusable-sections"
}, {
  "depth": 1,
  "id": "section-context",
  "label": "Section context",
  "parent": "create-reusable-sections"
}, {
  "depth": 1,
  "id": "section-classes",
  "label": "Section classes",
  "parent": "create-reusable-sections"
}, {
  "depth": 1,
  "id": "previewing-your-section",
  "label": "Previewing your section",
  "parent": "create-reusable-sections"
}, {
  "depth": 0,
  "id": "copy-section-hubl",
  "label": "Copy section HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Sections"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The outermost container in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "drag and drop area"
      }), " is called a section. Sections can't be nested within any other ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#drag-and-drop-areas-and-their-elements",
        children: "dnd element"
      }), ", but can contain modules, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#row",
        children: "rows"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: "columns"
      }), ". In the page editor, content creators can add sections to the page, then modify and style them as needed. Content creators can also create and save sections to use on other pages within the same theme, making content creation more efficient."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this article, learn more about sections and how to use them in the page editor. If you're developing a theme, check out the guide on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/hide-modules-and-sections",
        children: "hiding modules and sections"
      }), " from the page editor to create a more streamlined content creation experience."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/page%20editor%20add%20reusable%20section%20UI.gif",
        alt: "page editor add reusable section UI"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sections can be created either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/edit-page-content-in-a-drag-and-drop-area#create-a-section",
        children: "in the content editor by a content creator"
      }), " or built by a developer into a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), ", with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The styling options available in the editor are available when coding a template as well. For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<main class=\"body-container-wrapper\">\n  {% dnd_area 'dnd_area'\n    label='Main section',\n  %}\n    {% dnd_section\n      background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://example.com/path/to/image.jpg'\n      },\n      margin={\n        'top': 32,\n        'bottom': 32\n      },\n      padding={\n        'top': '1em',\n        'bottom': '1em',\n        'left': '1em',\n        'right': '1em'\n      },\n      max_width=1200,\n      vertical_alignment='MIDDLE'\n    %}\n\n    {% end_dnd_section %}\n\n  {% end_dnd_area %}\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For full documentation of all available drag and drop element parameters and usage examples, learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area tags"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use sections to quickly scaffold out templates that are easy to read. Since you are only specifying in context where the template specific instances are different, you can still go back and modify that section template."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " modifying a section will update it across all instances of that section, ", (0, _jsxRuntime.jsx)("u", {
          children: "except"
        }), " for existing pages that use a template that references the section. Pages previously created with a template that had an included section in it will instead need to be manually updated to use the new version of the section. This prevents accidentally making breaking changes. To update a section to the latest version, a content creator can navigate to the page editor, add the new section to the page, then delete the old version."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create reusable sections"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Within a theme, you can include preconfigured sections that content creators can add to pages using that theme within the page editor. These reusable sections are built as section template files and are coded within the same syntax that you would use inside a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " to make a section available for multiple themes, you'll need to add the section template file to each theme. Similarly, sections created by content creators in the content editor will only be available within that theme."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to create section template files and then reference them in other template files."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Section template files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Section templates are denoted with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: section"
      }), " in their ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "template annotation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: section\n label: Banner\n description: \"A banner typically used at the top of a page highlighting a product or main topic.\"\n isAvailableForNewContent: true\n screenshotPath: ../images/section-previews/banner.png\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Value"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sets the template type used to determine where the template can be used and what data is available to it."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "section"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used in the page editor to provide a human readable name of the section."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Further description of the section beyond what you can do with a label. Displays in the page editor. 255 characters maximum."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String/path"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Path to a screenshot of the section. This is used to give content creators an easy visual reference for what the section looks like."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A section template must begin and end with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " tag. Only one ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " can exist within a section template. Inside of that section, you can place modules, rows and columns, following the same ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " rules that apply when ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#dnd-area",
        children: "adding a dnd_area to a page template"
      }), ". The exception is that you are defining the content for just a section and its child drag and drop elements."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!--\n templateType: section\n label: Banner\n description: \"A banner typically used at the top of a page highlighting a product or main topic.\"\n isAvailableForNewContent: true\n screenshotPath: ../images/section-previews/banner.png\n-->\n{% dnd_section\n padding={\n   'top': 200,\n   'right': 20,\n   'bottom': 200,\n   'left': 20\n },\n background_image={\n   'backgroundPosition': 'MIDDLE_CENTER',\n   'backgroundSize': 'cover',\n   'imageUrl': context.backgroundImage || get_asset_url('../images/blank-page-banner.png')\n },\n max_width=778,\n vertical_alignment='MIDDLE'\n%}\n {% dnd_column %}\n   {% dnd_row %}\n     {% dnd_module path='@hubspot/rich_text' %}\n     {% module_attribute 'html' %}\n       <div style=\"text-align: center\">\n         {{ context.content || '<h1 style=\"color: #fff;\">Communicate <span style=\"font-weight: 400;\">Your Way</span></h1><p style=\"color: #fff;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus posuere mi, in pretium ante posuere a. Aliquam a risus at eros molestie pretium.</p>' }}\n       </div>\n     {% end_module_attribute %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n   {% dnd_row %}\n     {% dnd_module\n       path='../modules/button',\n       button_text={{ context.buttonText || 'Subscribe' }}\n       horizontal_alignment='CENTER'\n     %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add a section partial to a template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After creating a section, you can reference it within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " by using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "include_dnd_partial"
      }), " tag. This tag provides the path pointing to the section file, as shown below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area 'dnd_area' class='body-container body-container--home-page', label='Main section' %}\n   {# Banner Section #}\n   {% include_dnd_partial path='../sections/banner.html' context={} %}\n   {# End Banner Section #}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["In the above example, note the context argument in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "include_dnd_partial"
        }), " tag. This allows you to pass instance specific variables from the page template to the section, overriding the default values in the section file. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#section-context",
          children: "See section context"
        }), " for more information."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Section context"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use section context variables to override section level and module level default values. Section context variables are defined by you and are not associated directly with the modules and their fields."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your page template you can set these context variables through the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context"
      }), " parameter in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "include_dnd_partial"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area 'dnd_area' class='body-container body-container--home-page', label='Main section' %}\n\n   {# Banner Section #}\n   {% include_dnd_partial path='../sections/banner.html'\n     context={\n       'content': '<h1 style=\"color: #fff;\">Home Page</h1><p style=\"color: #fff;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus posuere mi, in pretium ante posuere a. Aliquam a risus at eros molestie pretium.</p>',\n       'buttonText': 'Buy Now'\n     }\n   %}\n   {# End Banner Section #}\n\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any variables you add to your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context"
      }), " parameter will become available to reference within your section template. The following example shows how to set the image URL and rich text area and button content set in context if it exists."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!--\n templateType: section\n label: Banner\n description: \"A banner typically used at the top of a page highlighting a product or main topic.\"\n isAvailableForNewContent: true\n screenshotPath: ../images/section-previews/banner.png\n-->\n{% dnd_section\n  background_image={\n   'backgroundPosition': 'MIDDLE_CENTER',\n   'backgroundSize': 'cover',\n   'imageUrl': context.backgroundImage || get_asset_url('../images/blank-page-banner.png')\n },\n max_width=778\n%}\n {% dnd_column %}\n   {% dnd_row %}\n     {% dnd_module path='@hubspot/rich_text' %}\n     {% module_attribute 'html' %}\n       <div style=\"text-align: center\">\n         {{ context.content || '<h1 style=\"color: #fff;\">Communicate <span style=\"font-weight: 400;\">Your Way</span></h1><p style=\"color: #fff;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus posuere mi, in pretium ante posuere a. Aliquam a risus at eros molestie pretium.</p>' }}\n       </div>\n     {% end_module_attribute %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n   {% dnd_row %}\n     {% dnd_module\n       path='../modules/button',\n       button_text={{ context.buttonText || 'Subscribe' }}\n       horizontal_alignment='CENTER'\n     %}\n     {% end_dnd_module %}\n   {% end_dnd_row %}\n {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Notice everywhere context variables are used, there is an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "||"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "OR"
        }), " filter to provide fallback default content if none is provided. For example, in the button module, if ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "context.buttonText"
        }), " has a value, the page will use it. Otherwise, the text is set to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Subscribe"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Section classes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In section templates, you can add classes to the section wrapper using the class parameter. This will add the class you specify to the class field of the dnd section's html element. It's recommended wherever possible that you use styling controls built into sections to enable content creators to be able to modify them."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " section classes are only supported in section templates."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n class='my-hero-section'\n padding={\n   'top': 200,\n   'right': 20,\n   'bottom': 200,\n   'left': 20\n },\n background_image={\n   'backgroundPosition': 'MIDDLE_CENTER',\n   'backgroundSize': 'cover',\n   'imageUrl': context.backgroundImage || get_asset_url('../images/blank-page-banner.png')\n },\n max_width=778,\n vertical_alignment='MIDDLE'\n%}\n...\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Content creators can't edit, add, or remove classes. They can only be \"removed\" by recreating a section manually in the editor."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Additionally you should avoid changing the layout of section children using CSS or JavaScript. Doing so can create an unpleasant page editor experience for the content creator."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Previewing your section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The easiest way to preview your section while developing, is to use the Design Manager. Open a template containing a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " which calls your section template using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "include_dnd_partial"
      }), " tag. In the top right corner click preview. This way you can keep updating your section and see your changes reflected right away. This is much more efficient than having to create a new page for each change you make."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Copy section HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the page editor, you can copy the HubL markup for a section to reuse the code as needed. This can be helpful when wanting to recreate drag and drop sections in a coded file."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To copy a section's HubL markup:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to your content:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Website Pages"
            }), ": In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Website"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Website Pages"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Landing Pages"
            }), ": In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Landing Pages"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over a page and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the page editor, add the following parameter to the URL, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?developerMode=true"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With the page reloaded, you'll now be in developer mode. You can exit developer mode any time by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exit developer mode"
        }), " in the upper right."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/exit-developer-mode0.png",
        alt: "exit-developer-mode0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over the section you want to copy, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "down arrow icon"
        }), ". Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy as HubL"
        }), ". The HubL markup will then be copied to your clipboard."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-section-hubl-menu.png",
        alt: "copy-section-hubl-menu"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag and Drop Areas overview"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "Drag and Drop Areas HubL tags"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Using modules in templates"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Getting started with drag and drop areas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "How to enable Developer Mode in CMS Hub's page editor - YouTube"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}