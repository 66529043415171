"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42366481034;
const slug = exports.slug = 'guides/api/analytics-and-events/event-analytics';
const title = exports.title = 'イベントAPI | イベントアナリティクス';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | vNext Docs DP - Web Analytics | 202211';
const metaDescription = exports.metaDescription = 'イベント アナリティクス エンドポイントを使用することで、開発者はCRMオブジェクト（タイプにかかわらず）に関連付けられているイベントを検索し、絞り込むことができます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%80%8B%E5%88%A5%E3%81%AE%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%AE%8C%E4%BA%86%E3%82%92%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%82%8B",
  "label": "個別のイベント完了を問い合わせる",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88id%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8F%E9%81%B8%E6%8A%9E",
  "label": "オブジェクトIDに基づく選択",
  "parent": "%E5%80%8B%E5%88%A5%E3%81%AE%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%AE%8C%E4%BA%86%E3%82%92%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%8F%E9%81%B8%E6%8A%9E",
  "label": "オブジェクトプロパティーに基づく選択",
  "parent": "%E5%80%8B%E5%88%A5%E3%81%AE%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%AE%8C%E4%BA%86%E3%82%92%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97%E3%81%AE%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%81%A8%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%AA%E3%83%B3%E3%82%B0",
  "label": "イベントタイプのクエリーとフィルタリング",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "イベントアナリティクス"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントアナリティクスAPIは、CRMレコード（タイプにかかわらず）に関連付けられているイベントの取得に使用します（Marketing Hub Enterprise、Sales Hub Enterprise、Service Hub Enterprise、またはCMS Hub Enterpriseのみ）。例として、ウェブサイトページの閲覧やEメールの開封などの標準イベント、さらには", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "カスタムイベント"
      }), "があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えばこのAPIは、コンタクトがコンテンツに行ったやり取りのタイムライン表示に活用できます。このタイムラインは、カスタム分析のデータセット作成や、外部アプリへのコンタクトタイムライン表示にも使用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "個別のイベント完了を問い合わせる"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このAPIでは1回につき、1件のCRMレコードに関するイベントが返されます。レコードの選択には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), "を指定し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), "クエリーパラメーターのいずれかを含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "オブジェクトIDに基づく選択"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["IDを基準にしてレコードを指定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "クエリーパラメーターを含めます。例えば、IDが「2832」のコンタクトレコードを指定するには、次の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectId=224834"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "オブジェクトプロパティーに基づく選択"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトID以外の重複のないプロパティーに基づいてレコードを特定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), "パラメーターを含めます。このプロパティーの参照は、プロパティー名と値を含む次の書式を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty.{propname}={propvalue}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、Eメールアドレスに基づいてコンタクトを指定するには、次の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectProperty.email=user12@dev.com"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントタイプのクエリーとフィルタリング"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "特定のCRMオブジェクトに関連付けられているイベントについて問い合わせると、レスポンスにはカスタム行動イベントを含む全てのイベントタイプが含まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のイベントタイプのイベント完了のみを返すには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), "パラメーターを使用し、その後にイベント名を指定できます。利用可能なすべてのイベントタイプのリストを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/event-types"
      }), "に送信できます。応答では、アカウント内のすべてのイベントタイプが返されます。次に、これらのイベント名のいずれかをクエリーパラメーターとして使用し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events"
      }), "エンドポイントに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例として、以下のような場合が挙げられます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId=224834"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}