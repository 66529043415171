"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400708;
const slug = exports.slug = 'guides/cms/content/content-search';
const title = exports.title = 'Recherche de contenu';
const name = exports.name = 'EMEA (FR) Content Search';
const metaDescription = exports.metaDescription = "Utilisation de la fonctionnalité de recherche native du site dans HubSpot CMS, Content Search. HubSpot CMS dispose d'une fonctionnalité intégrée de recherche sur le site pour permettre à vos visiteurs de trouver facilement le contenu qu'ils recherchent. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "recherche-de-contenu-en-cours",
  "label": "Recherche de contenu en cours",
  "parent": null
}, {
  "depth": 0,
  "id": "appliquer-la-recherche-sur-votre-site-web",
  "label": "Appliquer la recherche sur votre site Web",
  "parent": null
}, {
  "depth": 1,
  "id": "barre-de-recherche-du-site",
  "label": "Barre de recherche du site",
  "parent": "appliquer-la-recherche-sur-votre-site-web"
}, {
  "depth": 1,
  "id": "r%C3%A9sultats-de-la-recherche-sur-le-site",
  "label": "Résultats de la recherche sur le site",
  "parent": "appliquer-la-recherche-sur-votre-site-web"
}, {
  "depth": 0,
  "id": "mod%C3%A8le-de-r%C3%A9sultats-de-recherche",
  "label": "Modèle de résultats de recherche",
  "parent": null
}, {
  "depth": 0,
  "id": "comment-la-priorit%C3%A9-de-la-recherche-est-elle-d%C3%A9termin%C3%A9e-%3F",
  "label": "Comment la priorité de la recherche est-elle déterminée ?",
  "parent": null
}, {
  "depth": 0,
  "id": "contr%C3%B4le-de-l-indexation-pendant-le-d%C3%A9veloppement",
  "label": "Contrôle de l'indexation pendant le développement",
  "parent": null
}, {
  "depth": 0,
  "id": "comportement-d-indexation-par-d%C3%A9faut",
  "label": "Comportement d'indexation par défaut",
  "parent": null
}, {
  "depth": 0,
  "id": "comment-puis-je-exclure-des-pages-des-r%C3%A9sultats-de-recherche-%3F",
  "label": "Comment puis-je exclure des pages des résultats de recherche ?",
  "parent": null
}, {
  "depth": 0,
  "id": "comment-exclure-des-sections-d-une-page-de-l-indexation-dans-les-recherches-%3F",
  "label": "Comment exclure des sections d'une page de l'indexation dans les recherches ?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      img: "img",
      strong: "strong",
      ol: "ol",
      li: "li",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Recherche de contenu"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot CMS dispose d'une fonctionnalité intégrée de recherche sur le site pour permettre à vos visiteurs de trouver facilement le contenu qu'ils recherchent. Tout votre contenu HubSpot est automatiquement indexé dans le moteur de recherche natif de HubSpot, ce qui vous évite de devoir configurer un outil de recherche tiers pour votre site Web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recherche de contenu en cours"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le moteur de recherche est accessible via l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "API de recherche de site"
      }), ". Cette API prend en charge de nombreuses options de filtrage pour vous permettre de créer une expérience de recherche puissante et hautement personnalisée sur votre site Web. Par exemple, si vous souhaitez effectuer une recherche sur votre blog, vous pouvez utiliser l'option ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type=BLOG_POST"
      }), " pour obtenir uniquement les articles de blog. Ou, si vous voulez intégrer la recherche dans la version espagnole de votre site Web, vous pouvez interroger ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "langue=es"
      }), " pour ne renvoyer que les pages en espagnol."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'API les renvoie au format JSON qui peut être analysé avec JavaScript pour afficher les résultats sur votre site Web. Tous les types de contenu renverront le domaine, le titre, l'URL et la langue de la page. La description renvoyée est un échantillon de texte du contenu qui correspond le mieux au terme recherché. Un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span class=\"hs-search-highlight hs-highlight-html\">"
      }), " encadrera le texte parfaitement adapté, ce qui vous permettra de mettre en évidence le texte correspondant avec la CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Selon le type de contenu recherché, les résultats renvoient des informations légèrement différentes. Vous pouvez ainsi afficher différemment les résultats pour des types de contenu spécifiques. Par exemple, les articles de blog renverront des informations sur les balises de l'article, l'auteur et la date de publication."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// json\n{\n  \"total\": 850,\n  \"offset\": 0,\n  \"limit\": 10,\n  \"results\": [\n    {\n      \"id\": 3761238962,\n      \"score\": 0.30858153,\n      \"type\": \"SITE_PAGE\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://developers.hubspot.com/docs/cms/search\",\n      \"language\": \"en\",\n      \"title\": \"Content Search\",\n      \"description\": \"Using HubSpot’s native search engine, content search, to implement <span class=\"hs-search-highlight hs-highlight-html\">search on your website</span>\"\n    },\n    {\n      \"id\": 3254581958,\n      \"score\": 0.30858153,\n      \"type\": \"BLOG_POST\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://designers.hubspot.com/blog/getting-started-with-local-development\",\n      \"featuredImageUrl\":\"https://designers.hubspot.com/hubfs/local-development.jpg\"\n      \"title\": \"Getting Started with Local Development\",\n      \"description\":\"The beta Local Development Tooling connects your local workflow to HubSpot, allowing you to utilize <span class=\"hs-search-highlight hs-highlight-html\">version control</span>, your favorite text editor and various web development technologies when developing on the HubSpot CMS.\"\n      \"authorFullName\": \"William Spiro\",\n      \"tags\": [\n        \"Website Development\",\n        \"Local Development\"\n      ],\n      \"publishedDate\": 1447938000000\n    }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Appliquer la recherche sur votre site Web"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe deux modules par défaut à utiliser pour appliquer facilement la recherche sur votre site Web, qui utilisent l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "API de recherche de site"
      }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_input"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_results"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Barre de recherche du site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une barre de saisie dans laquelle les visiteurs peuvent saisir des termes à rechercher. Ce module peut se trouver n'importe où sur votre site Web. Ce module peut être inclus dans un modèle avec ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Résultats de la recherche sur le site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renvoie une liste de contenu basée sur le terme recherché. Ce module peut se trouver n'importe où sur votre site Web. Ce module peut être inclus dans un modèle avec"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si la fonctionnalité de ces modules ne correspond pas à la manière dont vous souhaitez que la recherche fonctionne sur votre site Web, vous pouvez créer vos propres modules ou fonctionnalités de recherche. Les modules de recherche par défaut sont conçus pour être étendus en fonction de vos besoins de recherche. Vous pouvez visualiser et cloner ces modules dans le Gestionnaire de conception en recherchant leurs noms dans le Finder, ou vous pouvez les récupérer dans votre environnement local à l'aide de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI CMS"
      }), " en exécutant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_input.module"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_results.module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/default%20search%20module.png",
        alt: "module de recherche par défaut"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modèle de résultats de recherche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque domaine a sa propre page de résultats de recherche par défaut. Le modèle et le chemin d'accès de cette page sont définis dans ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Paramètres > Site Web > Pages"
      }), " sous l' ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "onglet Pages système"
      }), " pour des domaines spécifiques. Par exemple, consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "modèle de boilerplate de résultats de recherche du thème CMS"
      }), ". Le domaine défini pour la page de résultats de recherche est automatiquement connecté aux modules de recherche par défaut. Cependant, vous pouvez utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "API de recherche de site web"
      }), " pour créer vos résultats de recherche comme vous le souhaitez sur n'importe quelle page de votre site web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment la priorité de la recherche est-elle déterminée ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'ordre des résultats de recherche est déterminé par une série de comparaisons pondérées du contenu de la page par rapport au terme recherché par le visiteur. Le contenu de la page est séparé en champs de comparaison avec une pondération différente en fonction de l'endroit où se situe le contenu dans le code HTML de vos pages. Les champs de comparaison sont regroupés par ordre d'importance :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Titre HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Métadescription"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Éléments HTML H1"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Éléments HTML H2"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Éléments HTML H3"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Autres éléments HTML"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remarque : Cette liste est susceptible d'être modifiée."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez indiquer à notre indexeur de booster spécifiquement certains éléments d'une page, vous pouvez encadrer le contenu dans une classe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-keyword"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contrôle de l'indexation pendant le développement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors du développement d'un nouveau site, il est utile de pouvoir tester la recherche sur le site sans se soucier de son indexation par les moteurs de recherche tels que Google."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " vous pouvez demander à HubSpot de tout explorer, tout en bloquant les autres robots."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /\n\n# Allow HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nAllow: /\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si l'une de vos pages comporte une balise méta indiquant « no index », la page ne sera toujours pas indexée, même si elle est autorisée dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["N'oubliez pas de vérifier votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " avant le lancement pour vous assurer que tout est indexé comme vous le souhaitez."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportement d'indexation par défaut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comme le CMS connaît l'intention de certains types de pages, la recherche de contenu est en mesure de limiter l'indexation en toute sécurité pour permettre l'indexation des pages de type contenu. Exemples de pages de type contenu :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pages du site"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pages de destination"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Articles de la base de connaissances"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Articles de blog"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Les pages système et les pages protégées par un mot de passe ne sont pas indexées."
      }), " Les pages réservées au contrôle de l'accès au CMS ne s'afficheront dans la recherche de contenu de HubSpot que pour les utilisateurs qui sont connectés et ont accès aux pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment puis-je exclure des pages des résultats de recherche ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous empêchez une page d'être indexée par les moteurs de recherche via le fichier de votre site Web ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " ou via les balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta"
      }), ", elle ne sera pas indexée pour la recherche sur le site."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans votre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fichier robots.txt"
      }), ", ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "disallow"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /path-to-page\n\n# Block just HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nDisallow: /path-to-page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également ajouter une métabalise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "NOINDEX, NOFOLLOW"
      }), " dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " au niveau de la page ou du modèle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<meta name=\"ROBOTS\" content=\"NOINDEX, NOFOLLOW\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Il n'est pas nécessaire de bloquer les robots en utilisant à la fois ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " et la balise meta. Cela peut prêter à confusion si vous décidez d'autoriser l'indexation d'une page."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment exclure des sections d'une page de l'indexation dans les recherches ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Parfois, certaines zones d'une page ne sont pas utiles à faire apparaître dans les résultats de recherche. Il peut s'agir du contenu d'un en-tête, d'un pied de page, d'une barre latérale, etc. Lorsque c'est le cas, vous pouvez ajouter la classe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-hidden"
      }), " à votre HTML pour que la recherche ignore le contenu de ces zones."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}