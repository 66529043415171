"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 113077495721;
const slug = exports.slug = 'guides/cms/content/performance/reverse-proxy-support';
const title = exports.title = 'HubSpotでリバースプロキシーを使用する';
const name = exports.name = 'APAC JAPAN (ja) | Dev. Page | Use a reverse proxy with HubSpot';
const metaDescription = exports.metaDescription = 'HubSpotのCMS上に構築されたウェブサイトは世界各地に広がるCDNを自動的に使用しますが、CMS Hub Enterpriseはリバースプロキシーもサポートしています。HubSpotのCDNを使用して維持することができない複雑なルーティングルールまたは既存のCDNをご利用の場合は、HubSpotでホスティングされているコンテンツのリバースプロキシーをセットアップする方法を確認してください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%A6%82%E8%A6%81",
  "label": "概要",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "プロキシーの設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E6%89%8B%E9%A0%86",
  "label": "一般的な手順",
  "parent": "%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "amazon-cloudfront%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E3%83%AA%E3%83%90%E3%83%BC%E3%82%B9%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AE%E3%82%BB%E3%83%83%E3%83%88%E3%82%A2%E3%83%83%E3%83%97",
  "label": "Amazon CloudFrontにおけるリバースプロキシーのセットアップ",
  "parent": "%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "nginx%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%83%AA%E3%83%90%E3%83%BC%E3%82%B9%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AE%E3%82%BB%E3%83%83%E3%83%88%E3%82%A2%E3%83%83%E3%83%97",
  "label": "nginxを使用したリバースプロキシーのセットアップ",
  "parent": "%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 0,
  "id": "hubspot%E3%81%A7%E3%81%AE%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E8%A8%AD%E5%AE%9A",
  "label": "HubSpotでのドメイン設定",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A8%AD%E5%AE%9A%E3%81%8C%E6%AD%A3%E3%81%97%E3%81%84%E3%81%93%E3%81%A8%E3%81%AE%E7%A2%BA%E8%AA%8D",
  "label": "設定が正しいことの確認",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%88%E3%83%A9%E3%83%96%E3%83%AB%E3%82%B7%E3%83%A5%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0",
  "label": "トラブルシューティング",
  "parent": "%E8%A8%AD%E5%AE%9A%E3%81%8C%E6%AD%A3%E3%81%97%E3%81%84%E3%81%93%E3%81%A8%E3%81%AE%E7%A2%BA%E8%AA%8D"
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E5%85%AC%E9%96%8B",
  "label": "コンテンツの公開",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AA%E3%83%90%E3%83%BC%E3%82%B9%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A%E3%82%92%E6%94%AF%E6%8F%B4%E3%81%99%E3%82%8B%E6%9C%89%E6%96%99%E3%82%B3%E3%83%B3%E3%82%B5%E3%83%AB%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AE%E3%81%94%E5%88%A9%E7%94%A8%E3%82%92%E6%A4%9C%E8%A8%8E%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8A%E5%AE%A2%E3%81%95%E3%81%BE%E3%81%AF%E3%80%81%E4%BB%A5%E4%B8%8B%E3%81%AE%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AB%E3%81%94%E5%85%A5%E5%8A%9B%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82",
  "label": "リバースプロキシーの設定を支援する有料コンサルティングサービスのご利用を検討されているお客さまは、以下のフォームにご入力ください。",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      ol: "ol",
      pre: "pre",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      Video,
      Form
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Form) _missingMdxReference("Form", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Video) _missingMdxReference("Video", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotでリバースプロキシーを使用する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMS上に構築されたウェブサイトは自動的に、数百のローカルPOP（ポイント オブ プレゼンス）を持つHubSpotのグローバルなCDN（コンテンツ配信ネットワーク）を使用します。ただしお客さまの環境によっては、既存のCDNや複雑なルーティングルールをお持ちで、HubSpotの標準搭載CDNでは維持できない場合があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpotのサポートチームは、このサービスに関するサポートを提供していません。お客さまはHubSpotへのリバースプロキシーの実装について、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#interested-in-purchasing-consulting-to-help-config",
            children: "テクニカルコンサルタントによる有料サポート"
          }), "を利用するか、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
            children: "コミュニティーフォーラムで他のユーザーからサポート"
          }), "を受けることができます。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "このガイドでは、既存のウェブサイトまたはアプリが、リバースプロキシーに対応したCDNまたはウェブサービスを使用することを前提としています。"
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "概要"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リバースプロキシーはプロキシーサーバーの一種で、1つまたは複数のサーバーからリソースを取得し、そのリソースをプロキシーサーバー自体から来たかのようにクライアントに返します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、HubSpot CMSでホスティング", (0, _jsxRuntime.jsx)("u", {
        children: "されていない"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "www.website.com"
      }), "のような既存のウェブサイトがあり、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "www.website.com/blog"
      }), "でHubSpotが管理するブログをホスティングしているとします。このブログは実際にはHubSpotのサーバーから配信されますが、リバースプロキシーを使用すると、ウェブサイトと同じサーバーからホスティングされているように見えます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**HubSpotの標準搭載CDNとその他の全てのサービスには、自動フェールオーバーとリカバリー機能を備えた複数のインスタンスがあります。リバースプロキシーを実装する場合は、負荷分散機能を備えた複数のインスタンスを使用することを強くお勧めします。全ての要求が単一プロキシーノードを介してルーティングされている場合、要求がレート制限プロトコルをトリップし、ブラウザー内のJavaScriptチャレンジが完了するまでは要求に対して", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), "レスポンスが返される可能性があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "独自のCDNまたはリバースプロキシーを使用すると、より多くの設定オプションを利用できる可能性がありますが、これにはかなりの運用知識とメンテナンスが必要となります。以下は、この方法を採用する前に検討すべき事項のリストです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "機能"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "HubSpotのCDN"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "カスタムソリューション"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "帯域幅"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "追加費用あり"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SSL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる：自動セットアップ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "追加費用あり：手動セットアップ"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "グローバルCDN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "?"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "自動キャッシュ管理"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいえ"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "不正使用防止保護"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客所有"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "24時間体制の監視"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客所有"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "サポート"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "顧客所有"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "IPv6"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "?"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "HTTP/2"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "含まれる"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "?"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "レイテンシー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "最適"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "追加のネットワークホップが必要"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "さらに、サイトのサブパスをHubSpotにプロキシーする場合、手動で追加しない限り、メインのsitemap.xmlにはHubSpotのページが含まれません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロキシーの設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタム リバース プロキシーを追加した場合、ウェブサイトのユーザーがサービスに対して要求を行うと、HubSpotのCDNにプロキシーされ、新たなネットワークホップが導入されることになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロキシーの設定作業を開始するには、まず", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-in-amazon-cloudfront",
        children: "Amazon CloudFront"
      }), "のようなCDNや", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-using-nginx",
        children: "nginx"
      }), "サーバーなど外部環境でプロキシーを設定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**現在のところ、Cloudflareはリバースプロキシーの設定に使用できません。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "複数のIPアドレスからHubSpot CNAMEオリジンに送られる要求が、プロキシーからのトラフィックによってローテーションされるようにするために、常にプロキシーを負荷分散された環境に実装する必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロキシーに必要なCNAMEの形式は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<HubID>.<suffix>"
      }), "です。ここでのサフィックスはHubIDの末尾2桁によって決まります。以下の表で、HubIDの末尾2桁とサフィックスの対応をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "HubIDの末尾"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "サフィックス"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "00-10"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn00.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "11-19"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn10.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "20-29"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn20.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "30-39"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn30.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "40-49"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn40.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "50-59"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn00.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "60-69"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn10.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "70-79"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn20.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "80-89"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn30.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "90-99"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn40.net"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えばHubIDが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), "の場合、正しいオリジンCNAMEは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456.sites-proxy.hscoscdn00.net"
      }), "になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下は、リバースプロキシーの一般的な設定手順と、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-in-amazon-cloudfront",
        children: "Amazon CloudFront"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-using-nginx",
        children: "nginx"
      }), "向けの具体的な手順です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "一般的な手順"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "一般的には、オリジンCNAMEを使用して要求を転送するようにプロキシーを設定し、以下の設定を追加することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotからのパスに対してキャッシュを実行しないようにプロキシーを設定します。HubSpotがCDNのキャッシュの内容を自動的に管理するため、キャッシュの内容が公開されるとページが自動的に更新されます。サービスが応答をキャッシュする場合、ページは数時間または数日更新されない可能性があることにご注意ください。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["元の要求者のクライアントIPアドレスを、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-Forwarded-For"
        }), "ヘッダーに追加するか、このヘッダーの前に付加します。これは、クライアントをそれぞれ区別するために必要です。CloudFrontなどの多くのサービスでは、これらのヘッダーが自動的に維持されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["所在地に基づいてパーソナライズされたコンテンツが機能するようにするため、静的ヘッダー", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Trust-Forwarded-For: true"
        }), "を渡します。これにより", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-Forwarded-For"
        }), "ヘッダーを信頼しますが、このヘッダーは全てのアップストリームプロキシーで更新されていない可能性があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["宛先ドメインの値が指定された", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HS-Public-Host"
        }), "ヘッダーを渡します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "全てのHTTPメソッドを許可します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロキシードメインにSSL証明書が発行され、インストールされていることを確認します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "全てのクエリー文字列を転送します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["その他", (0, _jsxRuntime.jsx)("u", {
          children: "全ての"
        }), "リクエストヘッダーとレスポンスヘッダーをそのまま転送します（Cookieを含む）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ドメイン下の全てのパスをHubSpotにプロキシーすることが理想的です。そうでない場合は、ドメインからアセットを適切に読み込むために、パス", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/_hcms/*"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/hs/*"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/hubfs/*"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/hs-fs/*"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cs/c/*"
        }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/e3t/*"
        }), "をプロキシーする必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Amazon CloudFrontにおけるリバースプロキシーのセットアップ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Amazon CloudFrontでリバースプロキシーを設定するには、新しい代替ドメイン名を使用して新しいディストリビューションを作成し、新しいオリジンを作成してから、HubSpotコンテンツがホスティングされているページパスのキャッシュ動作を作成する必要があります。ディストリビューションの利用方法について詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html",
        children: "AWS側の資料"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Amazon CloudFrontアカウントにログインします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "左側のサイドバーで［Distributions（ディストリビューション）］に移動します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["新規にセットアップする場合は、最初に［Create Distribution（ディストリビューションの作成）］をクリックして新しいディストリビューションを作成する必要があります。または、既存のディストリビューションを編集するか、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#origin",
            children: "オリジンと動作のセットアップ手順"
          }), "に進むことができます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［General（一般）］タブで［Edit（編集）］をクリックします。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［代替ドメイン名(CNAMEs)］フィールドに、サブドメインを含むドメインを追加します。これは、HubSpotに追加したドメインと一致している必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［Yes, Edit（はい、編集します）］をクリックして変更を確定します。［General（一般）］タブが再び表示され、［代替ドメイン名 (CNAMEs)］の横にドメインがリストされます。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["また、［Domain Name（ドメイン名）］フィールドの値を使用してDNSプロバイダーに新しいCNAMEレコードを作成する必要があります。この値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<value>.cloudfront.net"
            }), "のようになります。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "作成したディストリビューションで、リバースプロキシーをセットアップしているドメインを代替ドメイン名として追加します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "次に、新しいオリジンを設定します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["［Origin domain（オリジンドメイン）］フィールドに上の表の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<HubID>.<suffix>"
            }), "CNAME値を入力します。この値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "123.sites-proxy.hscoscdn20.net"
            }), "のようになります。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［Add custom header（カスタムヘッダーの追加）］の下の［Add header（ヘッダーの追加）］をクリックします。次に、以下のヘッダー詳細を追加します。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["所在地に基づいてパーソナライズされたコンテンツが機能するようにするため、エンドユーザーのIPの値を指定した", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-Client-IP"
            }), "を渡すか（推奨される方法）、または静的ヘッダー", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-Trust-Forwarded-For: true"
            }), "を渡します。後者の方法ではX-Forwarded-Forヘッダーが信頼されますが、このヘッダーは全てのアップストリームプロキシーで更新されていない可能性があります。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["宛先ドメインの値が指定された", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HS-Public-Host"
            }), "ヘッダーを渡します。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［Origins and Origin Groups（オリジンとオリジングループ）］タブをクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［Create Origin（オリジンの作成）］をクリックして、オリジンをセットアップします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［Create（作成）］をクリックして変更を保存します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["次に、HubSpotコンテンツをホスティングするページパスの", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-specify.html#DownloadDistValuesCacheBehavior",
            children: "キャッシュ動作"
          }), "をセットアップします。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["［Path pattern（パスパターン）］フィールドに、HubSpotコンテンツがホスティングされるページのURLパスを入力します。これは、特定のページのパス、またはワイルドカードなどの柔軟なURLにすることができます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-specify.html#DownloadDistValuesPathPattern",
              children: "パスパターン"
            }), "をご確認ください。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［Origin and Origin Groups（オリジンとオリジングループ）］フィールドをクリックし、先ほど作成したオリジンを選択します。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［Behaviors（動作）］タブをクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［Create Behavior（動作の作成）］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "キャッシュの動作をセットアップします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［Save changes（変更を保存）］をクリックします。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ディストリビューション、オリジン、動作を設定すると、指定されたパスで作成するHubSpotページでリバースプロキシーが使用できるようになります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-your-domain-in-hubspot",
        children: "HubSpotでドメインを設定する"
      }), "手順に進みます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "nginxを使用したリバースプロキシーのセットアップ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "nginxを使用してリバースプロキシーを設定するには、SSL情報とロケーションパス情報を含むロケーション設定ファイルを作成する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**nginxを使用したリバースプロキシーのセットアップについて詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.nginx.com/nginx/admin-guide/web-server/reverse-proxy/",
          children: "nginx側の資料"
        }), "（英語）をご確認ください。特に、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.nginx.com/nginx/admin-guide/security-controls/securing-http-traffic-upstream/",
          children: "アップストリームトラフィックの保護"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://nginx.org/en/docs/http/ngx_http_proxy_module.html#proxy_ssl_server_name",
          children: "proxy_ssl"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.nginx.com/nginx/admin-guide/load-balancer/using-proxy-protocol/",
          children: "$proxy_protocol_addr"
        }), "などの設定についてのドキュメントをご参照いただけます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "nginxを使用する場合、トラフィックをルーティングするために必要なヘッダーと設定がいくつかあります。以下は、始点として使用できるnginxロケーション設定ファイルのサンプルスニペットです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "location ~ ^(/|/some-other-path) {\n    proxy_set_header Host $http_host;\n    proxy_set_header X-HS-Public-Host www.example.com\n    proxy_pass_request_headers on;\n    proxy_set_header X-HubSpot-Trust-Forwarded-For true;\n    proxy_pass https://2XX93.sites-proxy.hscoscdnXX.net;\n    proxy_ssl_name www.example.com;\n    proxy_ssl_server_name on;\n    proxy_set_header   X-Real-IP $proxy_protocol_addr;\n    proxy_set_header   X-Forwarded-Proto  $scheme;\n    proxy_set_header   X-Forwarded-For $proxy_add_x_forwarded_for;\n    proxy_set_header   X-HubSpot-Client-IP $proxy_protocol_addr;\n  }\n\nlocation ~ ^(/hs|/_hcms|/hubfs|/hs-fs|/cs/c) {\n    proxy_set_header Host $http_host;\n    proxy_set_header X-HS-Public-Host www.example.com\n    proxy_pass_request_headers on;\n    proxy_set_header X-HubSpot-Trust-Forwarded-For true;\n    proxy_pass https://2XX93.sites-proxy.hscoscdnXX.net;\n    proxy_ssl_name www.example.com;\n    proxy_ssl_server_name on;\n    proxy_set_header   X-Real-IP  $proxy_protocol_addr;\n    proxy_set_header   X-Forwarded-Proto  $scheme;\n    proxy_set_header   X-Forwarded-For    $proxy_add_x_forwarded_for;\n    proxy_set_header   X-HubSpot-Client-IP $proxy_protocol_addr;\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記のコードでは、プロキシー接続とコンテンツ ホスト ドメインが異なることにご注意ください。一方はHubSpotが提供するCNAMEですが、もう片方はコンテンツの提供元であり、HubSpotに追加するドメインと一致するドメインです。この違いは、プロキシーとHubSpotのサーバー間の安全な接続を確立するSNI（サーバー名表示）接続プロセスによるものです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "nginxプロキシーでこれを有効にするには、上記のコードにもある、以下の設定を含めてください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "proxy_ssl_name www.example.com;\nproxy_ssl_server_name on;\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらの設定は、オリジンドメインがホストとは異なるため、SNI SSL接続ハンドシェイクでサーバードメイン名を送信するようnginxに指示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotでのドメイン設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロキシーを設定したら、次にドメインをHubSpotに追加します。標準的なドメイン接続プロセスと違い、HubSpotにドメインを完全に接続", (0, _jsxRuntime.jsx)("u", {
        children: "しない"
      }), "点にご注意ください。接続プロセスを開始してドメインをHubSpotコンテンツ公開用にしますが、DNSプロバイダーでCNAMEレコードを作成しません。このプロセスの終了時には、プロキシーはドメインに対する全ての要求を受信し、HubSpotへの特定のパスとその他のコンテンツホストへの他のパスをプロキシーすることを選択できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.website.com%E3%80%8D%E4%B8%8A%E3%81%AE%E3%83%91%E3%82%B9%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6%E3%83%AA%E3%83%90%E3%83%BC%E3%82%B9%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%82%92%E3%82%BB%E3%83%83%E3%83%88%E3%82%A2%E3%83%83%E3%83%97%E3%81%99%E3%82%8B%E5%A0%B4%E5%90%88%E3%81%AB%E3%81%AF%E3%80%81HubSpot%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%81%AB%E3%80%8Cwww.website.com%E3%80%8D%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B%E5%BF%85%E8%A6%81%E3%81%8C%E3%81%82%E3%82%8A%E3%81%BE%E3%81%99%E3%80%82%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%AF%E3%80%8Cwww.website.com%E3%80%8D%E3%81%AB%E5%AF%BE%E3%81%99%E3%82%8B%E5%85%A8%E3%81%A6%E3%81%AE%E8%A6%81%E6%B1%82%E3%82%92%E5%8F%97%E4%BF%A1%E3%81%97%E3%80%81HubSpot%E3%81%B8%E3%81%AE%E7%89%B9%E5%AE%9A%E3%81%AE%E3%83%91%E3%82%B9%E3%81%A8%E4%BB%96%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%83%9B%E3%82%B9%E3%83%88%E3%81%B8%E3%81%AE%E4%BB%96%E3%81%AE%E3%83%91%E3%82%B9%E3%82%92%E3%83%97%E3%83%AD%E3%82%AD%E3%82%B7%E3%83%BC%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%82%92%E9%81%B8%E6%8A%9E%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82%E3%81%93%E3%82%8C%E3%81%AB%E3%82%88%E3%82%8A%E3%80%81%E3%80%8Cwww.website.com/pricing%E3%80%8D%E3%81%AA%E3%81%A9%E3%80%81%E3%81%9D%E3%81%AE%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E4%B8%8A%E3%81%AE%E3%83%91%E3%82%B9%E3%81%ABHubSpot%E3%82%92%E4%BB%8B%E3%81%97%E3%81%A6%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%82%92%E5%85%AC%E9%96%8B%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82",
        children: "www.website.com」上のパスに対してリバースプロキシーをセットアップする場合には、HubSpotドメインに「www.website.com」を追加する必要があります。プロキシーは「www.website.com」に対する全ての要求を受信し、HubSpotへの特定のパスと他のコンテンツホストへの他のパスをプロキシーすることを選択できます。これにより、「www.website.com/pricing」など、そのドメイン上のパスにHubSpotを介してコンテンツを公開できます。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ドメインをHubSpotに追加するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotのアカウントで、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/domains",
          children: "ドメイン設定"
        }), "に移動します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［ドメインを接続］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［プライマリー］または［セカンダリー］を選択します。この機能では、リダイレクトドメインおよびEメール送信ドメインはサポートされていません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［接続］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ドメインでホスティングする", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "コンテンツタイプ"
        }), "を選択し、［次へ］をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ブランドドメイン"
        }), "を入力します。例えば「", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://www.website.com%E3%80%8D%E3%81%AE%E5%A0%B4%E5%90%88%E3%80%81",
          children: "www.website.com」の場合、"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: "website.com"
        }), "を入力します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**このドメインは、リバースプロキシーを介して要求されているドメインと一致している必要があります。"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コンテンツをホスティングする", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "サブドメイン"
        }), "を入力します。サブドメインは、外部でホスティングされているドメインのサブドメインと一致している必要があります。続いて、［次へ］をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "入力したドメインを確認し、［次へ］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ドメインがGoDaddyでホスティングされている場合は、GoDaddy接続モーダルが表示されたら、［いいえ、手動でセットアップします］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次に、HubSpotがドメインの所有権の確認とコンテンツ公開の許可ができるようにするため、ドメインを確認します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ドメインが現在SSL証明書を使用して外部でホスティングされていない場合は、HubSpotサポートに連絡して、CNAMEレコードとTXTレコードを作成するために必要な値を取得します。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["現在、有効なSSL証明書を使用してドメインが外部でホスティングされている場合は、次の手順に従います。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "「このドメインにはすでに別のプロバイダーからのSSL認証があるようです。」というアラートがHubSpotに表示されます。"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "このアラートで［事前プロビジョニング］をクリックして、ホスト名の認証に必要なCNAMEの値とTXTの値を取得します。"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "DNSプロバイダーで、指定された値を使用してレコードを作成します。"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "HubSpotで［確認］をクリックします。HubSpotがDNSプロバイダーに加えられた変更を認識し、ホスト名を確認するまでに最大4時間かかることがあります。"
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Video, {
      src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/pre-provision-ssl-for-proxy.mp4",
      height: "auto",
      width: "600",
      controls: true,
      autoplay: true,
      loop: true
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ドメインを確認したら、左下の［ドメインに戻る］をクリックして接続ウィザードを終了します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ドメインのリストで、新しいドメインにカーソルを合わせ、［編集］をクリックして［公開の準備完了として設定］を選択します。ドメインマネージャーで、ドメインのステータスが［接続されていません］から［公開の準備完了］に更新されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これで、HubSpotでドメインがプロキシーできるように適切に設定されました。ドメインへの要求が正常に行われると、ドメインのステータスが［公開の準備完了］から［接続済み］に更新されます。これには数週間かかる場合があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "設定が正しいことの確認"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["設定を確認するには、次のURLにアクセスします。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://[yourFullDomain]/_hcms/diagnostics"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の内容を確認します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "現在の時刻値が読み込みごとに変化する。これにより、ページがキャッシュされていないことを確認できます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "User-Agent"
        }), "がブラウザーと一致している。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Accept-Language"
        }), "の値がブラウザーと一致している。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Accept-Encoding"
        }), "ヘッダーが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "*"
        }), "である。これにより、応答が確実に圧縮されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Cookie"
        }), "の値が空白ではない。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Protocol"
        }), "が", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https"
        }), "である。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "X-Forwarded-For"
        }), "の一番左のIPアドレスが、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.whatismyip.com/",
          children: "https://www.whatismyip.com"
        }), "などのサービスから報告されるIPアドレスと一致している。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "IP-Determined Location"
        }), "の値が、ロケーションに対して正確である。これらは、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-Forwarded-For"
        }), "のIP関連ヘッダーに基づいています。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "トラブルシューティング"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["診断URLにアクセスすると", (0, _jsxRuntime.jsx)(_components.code, {
        children: "404"
      }), "が表示される場合は、設定に問題がある可能性があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://[yourFullDomain]/_hcms/_worker/headers"
      }), "にアクセスして、HubSpotがリバースプロキシーを介して要求から受信している全てのヘッダーを確認します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロキシーの最も重要なヘッダーは以下のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-Forwarded-For"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Trust-Forwarded-For"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HS-Public-Host"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "追加のヘッダー／不要なヘッダー、または重複する値を送信していないことを確認してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツの公開"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSを使用したコンテンツの公開は、ドメインがHubSpotによって直接ホスティングされている場合と同様に機能します。Cookieを使用するA/Bテストやコンテンツメンバーシップなどの機能は通常通り機能します。ページを作成するときには、URLで宛先ドメイン（利用可能な場合）を必ず選択してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "リバースプロキシーの設定を支援する有料コンサルティングサービスのご利用を検討されているお客さまは、以下のフォームにご入力ください。"
    }), "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "359f5dab-cbc9-4a3e-bb71-3436c208b59c",
      responseType: "inline",
      message: `フォームをご送信いただき、ありがとうございます。`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}