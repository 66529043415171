"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125361;
const slug = exports.slug = 'reference/cms/hubl/if-statements';
const title = exports.title = 'if-Anweisungen';
const name = exports.name = 'EMEA DACH (DE) if statements';
const metaDescription = exports.metaDescription = 'Ein praktischer Leitfaden zu HubSpots HubL if-Anweisungen für CMS-Entwickler.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "grundlegende-syntax-der-if-anweisung",
  "label": "Grundlegende Syntax der if-Anweisung",
  "parent": null
}, {
  "depth": 0,
  "id": "verwendung-von-elif-und-else",
  "label": "Verwendung von elif und else",
  "parent": null
}, {
  "depth": 0,
  "id": "unless-anweisungen",
  "label": "unless-Anweisungen",
  "parent": null
}, {
  "depth": 0,
  "id": "ifchanged",
  "label": "ifchanged",
  "parent": null
}, {
  "depth": 0,
  "id": "inline-if-anweisungen",
  "label": "Inline-if-Anweisungen",
  "parent": null
}, {
  "depth": 0,
  "id": "tern%C3%A4re-operatoren",
  "label": "Ternäre Operatoren",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      code: "code",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "if-Anweisungen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können bedingte Logik in Ihre Module und Vorlagen aufnehmen, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#basic-if-statement-syntax",
        children: "if-Anweisungen"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#unless-statements",
        children: "unless-Anweisungen"
      }), " von HubL verwenden. if-Anweisungen enthalten oft von HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "unterstützte Operatoren"
      }), " und können zur Ausführung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "Ausdruckstests"
      }), " verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#personalization-token",
          children: "Personalisierungstoken"
        }), " innerhalb einer bedingten Anweisung Ihres E-Mail-Moduls verwenden, müssen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
          children: "programmierbare E-Mails für das Modul aktivieren"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Informationen, die über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/transactional-emails#single-send-api",
          children: "v3"
        }), "- oder ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/single-send-api",
          children: "v4"
        }), "-Single-Send-APIs übermittelt werden, funktionieren nicht innerhalb von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if"
        }), "-Anweisungen, da die Vorlagen kompiliert werden, bevor die Informationen ausgefüllt werden."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Grundlegende Syntax der if-Anweisung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL verwendet if-Anweisungen, um die Logik einer Vorlage zu definieren. Die Syntax von HubL if-Anweisungen ist der bedingten Logik in Python sehr ähnlich. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisungen sind in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "Anweisungstrennzeichen"
      }), " eingeschlossen, beginnend mit einer öffnenden ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung und mit einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "-Anweisung am Ende."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das folgende Beispiel zeigt die grundlegende Syntax einer if-Anweisung, wobei „Bedingung“ durch die boolesche Regel ersetzt wird, die Sie als wahr oder falsch auswerten wollen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if condition %}\n\tIf the condition is true print this to template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie nun die grundlegende Syntax kennengelernt haben, wollen wir uns nun ein paar konkrete Beispiele für einfache if-Anweisungen ansehen. Die folgenden Beispiele zeigen if-Anweisungen, die prüfen, ob ein HubL-Modul mit dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " und eine Variable mit dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " in einer Vorlage vorhanden sind oder nicht. Beachten Sie, dass ohne jegliche Operatoren die if-Anweisung auswertet, ob das Modul im Kontext der Vorlage definiert ist oder nicht."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_module\" path=\"@hubspot/rich_text\", label=\"My rich text module\", html=\"Default module text\" export_to_template_context=true %}\n\n{% if widget_data.my_module %}\n\tA module named \"my_module\" is defined in this template.\n{% endif %}\n\n\n{% set my_variable = \"A string value for my variable\" %}\n{% if my_variable %}\n\tThe variable named my_variable is defined in this template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beachten Sie, dass bei der Auswertung des HubL-Moduls der Modulname innerhalb der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung in Anführungszeichen gesetzt wird und beim Testen der Variablen keine Anführungszeichen um den Variablennamen herum verwendet werden. In beiden obigen Beispielen sind das Modul und die Variable in der Vorlage vorhanden, sodass die Anweisungen so ausgewertet werden, dass das Markup gedruckt wird. Bitte beachten Sie, dass in diesen Beispielen nur getestet wird, ob das Modul und die Variable definiert sind, und nicht, ob sie einen Wert haben oder nicht."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Betrachten wir nun eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if-Anweisung"
      }), ", die auswertet, ob ein Modul einen Wert hat, anstatt auszuwerten, ob es in der Vorlage vorhanden ist. Hierfür müssen wir den Parameter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "export_to_template_context"
      }), " verwenden. Wenn im folgenden Beispiel der Textbaustein im Content-Editor einen Wert erhält, wird das Markup gedruckt. Wäre das Textfeld des Moduls gelöscht worden, würde kein Markup gerendert. Wenn Sie mit benutzerdefinierten Modulen arbeiten, gibt es eine vereinfachte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.widget_name"
      }), "-Syntax, die in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "diesem Beispiel"
      }), " beschrieben wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"product_names\" path=\"@hubspot/text\", label=\"Enter the product names that you would like to render the coupon ad for\", value=\"all of our products\", export_to_template_context=True %}\n\n{% if widget_data.product_names.value %}\n<div class=\"coupon-ad\">\n<h3>For a limited time, get 50% off {{ widget_data.product_names.value}}! </h3>\n</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"coupon-ad\">\n  <h3>For a limited time get 50% off all of our products!</h3>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung von elif und else"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "if-"
      }), "-Anweisungen können mit zusätzlichen bedingten Anweisungen oder mit einer Regel, die ausgeführt wird, wenn die Bedingung oder die Bedingungen falsch sind, weiter verfeinert werden. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "-Anweisungen ermöglichen es Ihnen, zusätzliche Bedingungen zu Ihrer Logik hinzuzufügen, die nach der vorherigen Bedingung ausgewertet werden ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "else"
        })
      }), "-Anweisungen definieren eine Regel, die ausgeführt wird, wenn alle anderen Bedingungen falsch sind. Sie können eine unbegrenzte Anzahl von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "-Anweisungen innerhalb einer einzigen if-Anweisung haben, aber nur eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "-Anweisung."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden finden Sie ein Beispiel für die grundlegende Syntax einer if-Anweisung, die den ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: ["Operator ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<="
        })]
      }), " verwendet, um den Wert einer Variablen zu überprüfen. In diesem Beispiel würde die Vorlage Folgendes ausgeben: „Die angegebene Zahl der Variablen ist kleiner als oder gleich 6.“"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set number = 5 %}\n\n{% if number <= 2 %}\n\tVariable named number is less than or equal to 2.\n{% elif number <= 4 %}\n\tVariable named number is less than or equal to 4.\n{% elif number <= 6 %}\n\tVariable named number is less than or equal to 6.\n{% else %}\n\tVariable named number is greater than 6.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend ein weiteres Beispiel, bei dem ein Auswahlmodul verwendet wird, um verschiedene Überschriften für eine Karriereseite auf der Grundlage der vom Benutzer gewählten Abteilung darzustellen. Das Beispiel verwendet den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "Operator =="
      }), ", um auf bestimmte vordefinierte Werte im Auswahlmodul zu überprüfen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unless-Anweisungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), "-Anweisungen sind genau wie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisungen bedingte Anweisungen, funktionieren aber nach der umgekehrten Logik. Der Code zwischen dem öffnenden und dem schließenden Tag wird gerendert und kompiliert, es sei denn, die einzelne boolesche Bedingung wird zu true ausgewertet. unless-Anweisungen beginnen mit einem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "unless"
        })
      }), " und enden mit einem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "endunless"
        })
      }), " .", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), "-Anweisungen unterstützen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), ", aber nicht ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend ein Beispiel, bei dem die Überschrift „Wartungsarbeiten“ ausgegeben wird, sofern das Rich-Text-Feld keinen Wert erhält. Wenn das Rich-Text-Feld über Inhalt verfügt, wird dieser Inhalt angezeigt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_page_content\" path=\"@hubspot/rich_text\", label=\"Enter your page content\", html=\"\" export_to_template_context=true %}\n\n{{ widget_data.my_page_content.html }}\n\n{% unless widget_data.my_page_content.html %}\n<h1>This page is under construction.</h1>\n<h3>Come back soon!</h3>\n{% endunless %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ifchanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zusätzlich zu if- und unless-Anweisungen unterstützt HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ifchanged"
      }), "-Anweisungen. Diese Anweisungen können verwendet werden, um Markup nur dann zu rendern, wenn sich eine Variable seit einem früheren Aufruf dieses Tags geändert hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inline-if-Anweisungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL unterstützt inline ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if-"
      }), "-Anweisungen. Diese können verwendet werden, um bedingte Logik knapp und gut verständlich mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "Operatoren und Ausdruckstests"
      }), " zu schreiben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set color = \"Blue\" if is_blue is truthy else \"Red\" %}     // color == \"blue\"\n\n{{ \"Blue\" if is_blue is truthy else \"Red\" }}     // \"Blue\"\n\n{% set dl = true %}\n<a href=\"http://example.com/some.pdf\" {{\"download\" if dl }} >Download PDF</a>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ternäre Operatoren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es ist auch möglich, ternäre Operatoren zu verwenden, um schnell bedingte Logik mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#logical",
        children: "Operatoren und Ausdruckstests"
      }), " zu schreiben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// If the variable is_blue is true, output \"blue\", otherwise output\"red\"\n{{ is_blue is truthy ? \"blue\" : \"red\" }}\n\n// Set the variable is_red to false if is_blue is true, otherwise set to true\n{% set is_red = is_blue is truthy ? false : true %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}