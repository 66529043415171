"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 115790575649;
const slug = exports.slug = 'guides/crm/ui-extensions/create';
const title = exports.title = 'Create UI extensions with React (BETA)';
const name = exports.name = 'Create UI extensions with React (BETA)';
const metaDescription = exports.metaDescription = "Learn how to create UI extensions for customizing HubSpot's UI.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "set-up-extension-files",
  "label": "Set up extension files",
  "parent": null
}, {
  "depth": 1,
  "id": "example-card.json",
  "label": "example-card.json",
  "parent": "set-up-extension-files"
}, {
  "depth": 2,
  "id": "extension-location",
  "label": "Extension location",
  "parent": "set-up-extension-files"
}, {
  "depth": 2,
  "id": "supported-objects",
  "label": "Supported objects",
  "parent": "set-up-extension-files"
}, {
  "depth": 1,
  "id": "example.jsx",
  "label": "Example.jsx",
  "parent": "set-up-extension-files"
}, {
  "depth": 1,
  "id": "package.json",
  "label": "package.json",
  "parent": "set-up-extension-files"
}, {
  "depth": 0,
  "id": "start-local-development",
  "label": "Start local development",
  "parent": null
}, {
  "depth": 0,
  "id": "adding-cards-to-the-ui",
  "label": "Adding cards to the UI",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h4: "h4",
      img: "img",
      strong: "strong",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create UI extensions with React (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After creating a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/create-a-project",
        children: "project"
      }), " and a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps",
        children: "public app"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps",
        children: "private app"
      }), " within it, you can create a UI extension to customize and extend HubSpot's CRM record and help desk UI. In this guide, you'll learn how UI extensions work and how to build them. To learn more about configuring UI extensions, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk",
        children: "UI extensions SDK reference"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart",
        children: "follow the quickstart guide"
      }), " to build and deploy an example UI extension to your account. Or, check out HubSpot's other ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/ui-extensions-examples",
        children: "sample projects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this guide:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#prerequisites",
          children: "Prerequisites"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#set-up-extension-files",
          children: "Set up extension files"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#start-local-development",
          children: "Start local development"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before getting started, ensure you've also set up the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/setup",
          children: "Set up your local environment"
        }), " for project development."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Created a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/create-a-project",
          children: "project"
        }), " with a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/creating-private-apps",
          children: "private app"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/platform/create-a-public-app-using-projects",
          children: "public app"
        }), " in it."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you haven't created a UI extension before, you may want to start with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart",
        children: "private app"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/quickstart",
        children: "public app"
      }), " quickstart guide or view ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview",
        children: "HubSpot's example UI extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up extension files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Within your project's app directory, create an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/extensions"
      }), " directory with the following files:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "example-card.json"
        }), ": the card configuration."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Example.jsx"
        }), ": the React file that serves as the front-end."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "package.json:"
        }), " metadata about the extension's front-end. This file is required and can be used to include dependencies for your React front-end."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " directory, you'll also need to install the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.npmjs.com/package/@hubspot/ui-extensions",
        children: "HubSpot UI extensions npm package"
      }), " by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "npm i"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "example-card.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The configuration file for the UI extension's app card."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example extension config file\n\n{\n  \"type\": \"crm-card\",\n  \"data\": {\n    \"title\": \"Example Card\",\n    \"location\": \"crm.record.tab\",\n    \"uid\": \"unique-extension-name\",\n    \"module\": {\n      \"file\": \"Example.jsx\"\n    },\n    \"objectTypes\": [{ \"name\": \"contacts\" }]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of extension. Must be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm-card"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the card."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "location"
            }), " (string): where the card appears in HubSpot's UI. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#extension-location",
              children: "extension location"
            }), ".", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "crm.record.tab"
                }), ": places the card on a tab of the middle pane of CRM records."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "crm.record.sidebar"
                }), ": places the card in the right sidebar of CRM records."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "crm.preview"
                }), ": places the card in the right side preview panel that you can access from record pages, index pages, board views, and lists pages."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "helpdesk.sidebar"
                }), ": places the card in the ticket sidebars within help desk. This includes the ticket preview panel on the help desk home page and the right sidebar of the ticket view in help desk. This location is separate from the ticket CRM record page."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "uid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The extension's unique identifier. This can be any string, but should meaningfully identify the extension. HubSpot will identify the extension by this ID so that you can change the extension's title without removing historical or stateful data, such as the card's position on the CRM record."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), " field, which contains the the location of the app card's font end React code."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Defines which types of CRM object records the extension will appear on. This will also enable you to pull data from those records when using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fetchCrmObjectProperties"
            }), " method. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#compatible-objects",
              children: "compatible objects"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Extension location"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can configure which part of the HubSpot UI to customize using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "location"
      }), " property in the extension's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#example-card-json",
        children: "JSON config file"
      }), ". The follow locations are available:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.record.tab"
          }), ": places the extension in the middle column of CRM record pages, either in one of HubSpot's default tabs or in a custom tab. If you've customized the middle column previously, you'll need to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/object-settings/customize-the-middle-column-of-records",
            children: "customize the middle column view"
          }), " to make any newly created extensions visible."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/middle-column-example-card.png",
            alt: "middle-column-example-card"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.record.sidebar"
          }), ": places the extension in the right sidebar of CRM record pages. Extensions in the sidebar cannot use ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/crm-data-components/overview",
            children: "CRM data components"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/right-sidebar-example-card.png",
        alt: "right-sidebar-example-card"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.preview"
          }), ": places the app card in the preview panel that you can access throughout the CRM. When using this location, the extension will be available when previewing the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectTypes"
          }), " specified in the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#example-card-json",
            children: "JSON config file"
          }), ". This includes previewing records from within CRM record pages, index pages, board views, and the lists tool. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/object-settings/customize-record-previews",
            children: "customizing previews"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/preview-example-card.png",
            alt: "preview-example-card"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "helpdesk.sidebar"
          }), ": places the card in the ticket sidebars within help desk. This includes both the ticket preview panel on the help desk home page and the right sidebar of the ticket view in help desk. To add a card to this location, you'll need to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/help-desk/customize-the-right-sidebar-of-help-desk",
            children: "configure your help desk settings"
          }), " to include the card."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when creating an extension for this location, you'll also need to ensure that the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/crm/intro/create-private-apps-with-projects",
          children: "app's JSON configuration file"
        }), " includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "tickets"
        }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scopes"
        }), " array, and that the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/crm/ui-extensions/create#set-up-extension-files",
          children: "card's JSON configuration file"
        }), " includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "tickets"
        }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypes"
        }), " field."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Help desk home page:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/help-desk-uie-example-home-page-preview.png",
        alt: "help-desk-uie-example-home-page-preview"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Help desk ticket view:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/help-desk-uie-example-ticket-page.png",
        alt: "help-desk-uie-example-ticket-page"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Supported objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can create extensions for both ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "standard object"
      }), " and custom object records. In the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#example-card-json",
        children: "app card's JSON configuration file"
      }), ", you'll define this within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypes"
      }), " array."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When building an extension for custom objects, you'll reference the object as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "p_objectName"
      }), " (case sensitive). To get this value, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom object schema API"
      }), ", then look for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), " in the response. Take the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), ", then remove the HubID number, and use the resulting value for the configuration file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example card.json\n\n\"objectTypes\": [\n {\n  \"name\": \"p_Cats\"\n }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, for a custom object with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "p123456_Cats"
      }), ", the correct value to use for the configuration file would be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "p_Cats"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example.jsx"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The React front-end file. Note that this example code is for a UI extension powered by a private app, as it includes a serverless function. For a public app, you would not include a serverless function. Instead, you would provide your own custom back-end."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example React front-end\n\nimport React, { useState } from 'react';\nimport {\n  Button,\n  Text,\n  Input,\n  Stack,\n  hubspot,\n} from '@hubspot/ui-extensions';\n\nhubspot.extend(({ context, runServerlessFunction, actions }) => (\n  <Extension\n    context={context}\n// runServerlessFunction is for private apps only\n    runServerless={runServerlessFunction}\n    sendAlert={actions.addAlert}\n  />\n));\n\nconst Extension = ({ context, runServerless, sendAlert }) => {\n  const [text, setText] = useState('');\n\n  const run = () => {\n    runServerless({ name: 'myFunc', parameters: { text: text } }).then((resp) =>\n      sendAlert({ message: resp.response })\n    );\n  };\n\n  return (\n    <>\n      <Text>\n        <Text format={{ fontWeight: 'bold' }}>\n          Your first UI Extension is ready!\n        </Text>\n        Congratulations {context.user.firstName}! You just deployed your first\n        HubSpot UI extension. This example demonstrates how you would send\n        parameters from your React front-end to the serverless function and get\n        response back.\n      </Text>\n      <Stack>\n        <Input\n          name=\"text\"\n          label=\"Send to serverless\"\n          onInput={(t) => setText(t)}\n        />\n        <Button type=\"submit\" onClick={run}>\n          Click me\n        </Button>\n      </Stack>\n    &lt;/>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "package.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Metadata about the extension's front-end. This file is required and can be used to include dependencies for your React front-end."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example package.json\n\n{\n  \"name\": \"example-extension\",\n  \"version\": \"0.1.0\",\n  \"description\": \"\",\n  \"license\": \"MIT\",\n  \"main\": \"Example.jsx\",\n  \"scripts\": {\n    \"dev\": \"hs-ui-extensions-dev-server dev\",\n    \"build\": \"hs-ui-extensions-dev-server build\"\n  },\n  \"repository\": {\n    \"type\": \"git\",\n    \"url\": \"https://github.com/HubSpot/ui-extensions-react-examples\"\n  },\n  \"dependencies\": {\n    \"@hubspot/ui-extensions\": \"latest\",\n    \"react\": \"^18.2.0\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Start local development"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your project files created locally, you can now run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project dev"
      }), " to upload the files to HubSpot, then run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project dev"
      }), " to start a local development server. start a local development server to view the extension in HubSpot. The local development server will pick up changes saved to your React files without needing to refresh the page or re-upload the project."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/local-development",
        children: "local development for UI extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adding cards to the UI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view an app card in HubSpot, you'll need to add it to the UI in its specified location. For example, to add a card to the contact record view:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Log in to your HubSpot account."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contacts"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contacts"
          }), ". Then, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of a contact to view its record."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["At the top of the contact record, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Customize tabs"
          }), ". A new tab will open showing the record editor sidebar."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/crm-recrd-customize-tabs.png",
            alt: "crm-recrd-customize-tabs"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Default view"
          }), " to edit the default contact record view."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For the purposes of this tutorial, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "+ plus icon tab"
          }), " at the top of the editor to add a new tab."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/crm-record-page-editor-add-tab.png",
            alt: "crm-record-page-editor-add-tab"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " for your new tab, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Done"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["With the new tab added, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Add cards"
          }), " dropdown menu."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right panel, under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Card types"
          }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Apps"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/add-app-card-to-middle-column-hello-public-ap.png?width=600&height=544&name=add-app-card-to-middle-column-hello-public-ap.png",
            alt: "add-app-card-to-middle-column-hello-public-ap"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "checkbox"
          }), " next to the example extension that's part of your public app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "X"
          }), " at the top of the right panel to close it."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Save and exit"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Navigate back to the contact record, then refresh the page. You should now see your new tab, which will contain your new card. With the local development server running, you'll see a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Developing locally"
          }), " tag displayed at the top of the card."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-ext-card-quickstart-result.png",
        alt: "ui-ext-card-quickstart-result"
      }), "Learn more about configuring UI extensions using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk",
        children: "UI extensions SDK"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}