"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 41185684471;
const slug = exports.slug = 'guides/cms/debugging/troubleshooting';
const title = exports.title = 'デバッグとエラー';
const name = exports.name = 'APAC JAPAN (ja) | [new] Debugging and Errors_JA | 202210';
const metaDescription = exports.metaDescription = 'HubSpotのCMS上でウェブサイトを開発する際のエラーのデバッグ方法および調査方法について説明します。 ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/fatal%20error%20-%20CMS%20CLI.png';
const featuredImageAltText = exports.featuredImageAltText = 'Error publishing template';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A8%E3%83%A9%E3%83%BC",
  "label": "エラー",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%87%B4%E5%91%BD%E7%9A%84%E3%81%AA%E3%82%A8%E3%83%A9%E3%83%BC",
  "label": "致命的なエラー",
  "parent": "%E3%82%A8%E3%83%A9%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E8%AD%A6%E5%91%8A",
  "label": "警告",
  "parent": "%E3%82%A8%E3%83%A9%E3%83%BC"
}, {
  "depth": 0,
  "id": "%E3%83%A9%E3%82%A4%E3%83%96%E3%83%9A%E3%83%BC%E3%82%B8%E4%B8%8A%E3%81%AE%E3%83%87%E3%83%90%E3%83%83%E3%82%B0%E3%83%A2%E3%83%BC%E3%83%89",
  "label": "ライブページ上のデバッグモード",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%BC%E3%81%AE%E9%96%8B%E7%99%BA%E8%80%85%E3%83%A2%E3%83%BC%E3%83%89",
  "label": "ページエディターの開発者モード",
  "parent": null
}, {
  "depth": 0,
  "id": "hubl%E5%87%BA%E5%8A%9B%E3%81%AE%E8%A1%A8%E7%A4%BA",
  "label": "HubL出力の表示",
  "parent": null
}, {
  "depth": 0,
  "id": "%7Cpprint",
  "label": "|pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85%E6%83%85%E5%A0%B1",
  "label": "開発者情報",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%A8%E5%A3%8A%E3%82%8C%E3%81%9F%E3%83%AA%E3%83%B3%E3%82%AF%E3%81%AE%E7%A2%BA%E8%AA%8D",
  "label": "ウェブサイトのパフォーマンスと壊れたリンクの確認",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E9%80%9F%E5%BA%A6%E3%81%AE%E6%94%B9%E5%96%84",
  "label": "ウェブサイトの速度の改善",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "デバッグとエラー"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードをデバッグすることと、エラーをどこでどのようにして表示するかを理解することは、HubSpot CMSでの開発において重要です。開発とデバッグの効率を高め、開発を進めながらウェブサイトの最適化を確認するために使用できる多数のツールがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "エラー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSで利用できる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/key-concepts#developer-file-system",
        children: "デベロッパー ファイル システム"
      }), "には、テンプレートとモジュールをページ上に正常にレンダリングするための数多くの検証が用意されています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "致命的なエラー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["致命的なエラーとは、ページが正常にレンダリングされないエラーです。公開コンテンツが常に正しくレンダリングされるように、致命的なエラーを含むテンプレートはCMS Hubで公開されません。致命的なエラーとして、例えば", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "などの必須のHubL変数が欠落しているケースがあります。このような場合、デザインマネージャーでの開発時や、CMS CLIでのファイルアップロード時にエラーが発生します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "VS Code Extension"
      }), "（英語）はHubL Lintをサポートしているため、ファイルのアップロード前に致命的なエラーを具体的に表示できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20design%20manager.png",
        alt: "致命的なエラーのスクリーンショット - デザインマネージャー"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20CMS%20CLI.png",
        alt: "致命的なエラーのスクリーンショット - CMS CLI"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルを公開するには、致命的なエラーを解決する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "警告"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["警告とは、ファイルの公開を妨げないエラーまたは問題です。警告の多くは、構文に関する提案、あるいは開発時に見落とされている潜在的な問題を提示するものです。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "VS Code Extension"
      }), "（英語）はHubL Lintをサポートしているため、ファイルのアップロード前に警告を具体的に表示できます。例えば、存在しないファイルをインクルードしようとすると、開発者に警告が表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/warning%20-design%20manager.png",
        alt: "警告 - デザインマネージャー"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "警告によってファイルの公開が防止されることはありませんが、警告の内容を調査することをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ライブページ上のデバッグモード"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["URLで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), "というクエリー文字列を指定してページを読み込むと、ライブページ上でデバッグモードを有効にすることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**デバッグモードは、404ページやパスワードページなどの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#system-pages",
          children: "システムページ"
        }), "ではサポートされていません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このクエリー文字列を指定してライブページを読み込むと、ページが次のようにレンダリングされます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "非縮小ファイルを使用して。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "非結合CSSファイルを使用して（個別のCSSファイルが提供される）。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "キャッシュされたファイルを使用せずに。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), "を指定してページを読み込むと、ページのソースコードの下に、以下のようなデバッグ情報が追加されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ページを", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/developer-reference/cdn/prerendering",
          children: "プリレンダリング"
        }), "できるかどうかと、プリレンダリングができない場合の理由。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "レンダリングリクエストのタイミングの内訳。どのページコンポーネントのレンダリングに時間がかかるかを知るのに役立ちます。この内訳は、［ドキュメント］リクエストの下のブラウザーの開発者コンソール内の［タイミング］タブにも追加されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/timing-tab-request-details.png",
        alt: "timing-tab-request-details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubL関数の制限や不明なファイルなどのエラーや警告。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/debug-page-source-elements-tab.png",
        alt: "debug-page-source-elements-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページエディターの開発者モード"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、クエリー文字列を指定してHubSpotでページエディターを読み込むと、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#copy-section-hubl",
        children: "HubLとしてのセクションのコピー"
      }), "などの開発者機能と、ページエディターからデザインマネージャー内の特定のモジュールを開く機能を有効にすることもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ページエディターで、URLに以下のパラメーターを追加してから、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?developerMode=true"
        }), "を押します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページが再読み込みされ、開発者モードになります。右上の［開発者モードを終了］をクリックすれば、いつでも開発者モードを終了できます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-top-navigation-bar.png",
        alt: "developer-mode-top-navigation-bar"
      }), "開発者モードでは、関連するモジュールをクリックしてから、サイドバーエディターの［デザインマネージャーで開く］をクリックすることで、特定のモジュールのコードに移動することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-open-design-manager-for-module.png",
        alt: "developer-mode-open-design-manager-for-module"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "また、未公開の変更をリセットして、テンプレートの既定の内容に戻すこともできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［コンテンツ］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テンプレート名の右側で、［コンテンツをリセット］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-reset-contents.png",
        alt: "developer-mode-reset-contents"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ダイアログボックスで、［はい、リセットします］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubL出力の表示"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャー内では、コードファイルについて「出力の表示」トグルを使用できます。これによりもう1つのコードエディターのパネルが開いて、閲覧中のファイルのトランスパイル（ソース変換）されたコードが表示されます。ファイルが含まれている公開ページを再読み込みすることなく、HubLコードがどのようにCSS、HTML、またはJavaScriptへとトランスパイルされるかを確認する際に役立ちます。また、HubLの入力がどのように出力されるかを簡単に確認できるので、HubLの新機能を試すときや、HubLの基礎を学習するときにも役立つツールです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/View%20HubL%20output.png",
        alt: "HubL出力の表示"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h2, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " HubLフィルター"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL変数で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " HubLフィルターを使用すると、有用なデバッグ情報を出力できます。HubL変数の型が出力されるので、このHubL変数を使用できる式、フィルター、演算子、関数を把握する際に役立ちます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ local_dt }}"
      }), "は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2020-02-21 12:52:20"
      }), "を出力します。この変数をpprintした場合、出力値は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "(PyishDate: 2020-02-21 12:52:20)"
      }), "という日付になります。つまり、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|datetimeformat"
      }), "のようなHubLフィルターを使用して、日付オブジェクトを操作したり書式設定したりできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|pprint }}\n{{ local_dt|datetimeformat('%B %e, %Y') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2020-02-21 12:55:13 (PyishDate: 2020-02-21 12:55:13) February 21, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "開発者情報"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "開発者情報に含まれるデータの大半は当社の内部利用向けで、別途文書化されている場合を除き、変更される可能性があります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページの開発者情報は、ページがレンダリングされる際の全てのデータのコンテキスト出力です。このレンダリングコンテキストは、全てHubLによりアクセスできます。ページの開発者情報にアクセスするには、公開ページの右上隅にあるHubSpotスプロケットアイコンをクリックして、［開発者情報（Developer Info）］を選択します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20sprocket%20menu.png",
        alt: "開発者情報スプロケットメニュー"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これにより、新しいタブが開き、ページのレンダリングコンテキストがJSON形式で表示されます。開発者情報を読みやすくするために、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa",
        children: "Chrome拡張機能のJSON Formatter"
      }), "（英語）などのJSON整形ツールをブラウザーにインストールすることをお勧めします。ページのコンテキストに含まれる情報の多くは社内利用向けですが、テンプレートの作成時にHubLで利用できるデータを確認する場合に役立ちます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、次の画像は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "https://desigers.hubspot.com/docs/developer-reference/cdn"
      }), "の開発者情報を示しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20example.png",
        alt: "開発者情報の例"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このデータの値は、コンテンツエディターの［設定］タブで設定されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/content%20editor%20-%20settings.png",
        alt: "コンテンツエディター - 設定"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この値は、HubLでページをレンダリングするために利用できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base template"
      }), "（英語）のタイトルとメタディスクリプションを出力するには、次のHubLを使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<title>{{ page_meta.html_title }}</title>\n<meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レンダリングコンテキストのデータはHubLによりアクセスできます。また、JSONツリーはドット表記を使用して走査できます。開発者が頻繁に出力する開発者情報データとしては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/modules/export-to-template-context",
        children: "テンプレートコンテキストにエクスポートされた"
      }), "モジュールのフィールド値とタグがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイトのパフォーマンスと壊れたリンクの確認"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["自分のサイトの訪問者が壊れたリンクに移動しないように確認することが重要です。サイト訪問者が正しい場所にたどり着くことを保証するために使用可能な2つのツールがあります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/overview",
        children: "ウェブサイトパフォーマンスAPI"
      }), "（英語）を使用して、404などのHTTPステータスを取得し、サイトの稼働時間を確認することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "404エラーが表示された場合は、訪問者を関連するURLにリダイレクトすることをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/seo/view-seo-recommendations-in-hubspot",
        children: "SEO対策ツール"
      }), "を使用して、ページコンテンツ内の壊れたリンクを特定し、素早く修正することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイトの速度の改善"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブサイト速度の最適化とテストでは、さまざまな要因を確認する必要があります。サイトの速度を最適化するためのツールやヒントについては、当社のガイドをご覧ください。"
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "841bbe22-70a6-4222-a6f0-85dd019711ae",
      label: "Optimize your CMS Hub site speed"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}