"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831420;
const slug = exports.slug = 'guides/cms/content/content-staging';
const title = exports.title = 'Préparation de contenu';
const name = exports.name = '[new] EMEA FR Préparation de contenu';
const metaDescription = exports.metaDescription = "La préparation de contenu est un environnement intégré à l'application qui vous permet de créer des pages préparées avant de les publier sur votre site de production.";
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Préparation de contenu"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La préparation de contenu est un environnement de contenu distinct intégré à l'application qui vous permet de mettre à jour ou de créer des pages préparées avant de les publier sur votre site de production."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La préparation de contenu peut être utilisée lors d'un projet de refonte de site web pour préparer des pages existantes à l'aide d'un nouveau modèle. Les analyses liées à la page seront transférées vers la nouvelle page publiée à partir de la préparation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comme les refontes impliquent généralement la mise à jour de plusieurs pages, vous pouvez préparer toutes les pages dont vous avez besoin et les publier sur le site en une seule fois."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Reportez-vous à la section ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "Utilisation de la préparation de contenu pour une refonte de site web"
      }), " pour obtenir des instructions détaillées."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour obtenir un tutoriel complet sur l'utilisation de la préparation de contenu, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "consultez cet article du Guide d'utilisation des sites web"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour concevoir et tester des modifications d'éléments pour des thèmes, des modèles ou encore des modules, nous vous recommandons d'utiliser un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "compte d'environnement de test développeur"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Découvrez-en davantage sur la création d'un workflow de développement efficace."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}