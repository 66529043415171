"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 30872325019;
const slug = exports.slug = 'guides/cms/content/performance/reverse-proxy-support';
const title = exports.title = 'Use a reverse proxy with HubSpot';
const name = exports.name = 'Use a reverse proxy with HubSpot';
const metaDescription = exports.metaDescription = "While websites built on HubSpot CMS automatically use a global CDN, CMS Hub Enterprise also supports reverse proxies. If you have an existing CDN or complex routing rules that aren't possible to maintain using HubSpot's CDN, learn how to set up a reverse proxy for your HubSpot-hosted content.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "considerations",
  "label": "Considerations",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-configure-the-proxy",
  "label": "1. Configure the proxy",
  "parent": null
}, {
  "depth": 1,
  "id": "eu-centered-accounts",
  "label": "EU-centered accounts",
  "parent": "1.-configure-the-proxy"
}, {
  "depth": 1,
  "id": "us-centered-accounts",
  "label": "US-centered accounts",
  "parent": "1.-configure-the-proxy"
}, {
  "depth": 1,
  "id": "general-instructions",
  "label": "General instructions",
  "parent": "1.-configure-the-proxy"
}, {
  "depth": 0,
  "id": "2.-confirm-your-configuration-is-correct",
  "label": "2. Confirm your configuration is correct",
  "parent": null
}, {
  "depth": 1,
  "id": "troubleshooting",
  "label": "Troubleshooting",
  "parent": "2.-confirm-your-configuration-is-correct"
}, {
  "depth": 2,
  "id": "clicks-registered-as-bot-events",
  "label": "Clicks registered as bot events",
  "parent": "2.-confirm-your-configuration-is-correct"
}, {
  "depth": 0,
  "id": "3.-add-the-domain-to-hubspot",
  "label": "3. Add the domain to HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "provider-specific-instructions",
  "label": "Provider-specific instructions",
  "parent": null
}, {
  "depth": 1,
  "id": "set-up-a-reverse-proxy-in-amazon-cloudfront",
  "label": "Set up a reverse proxy in Amazon CloudFront",
  "parent": "provider-specific-instructions"
}, {
  "depth": 1,
  "id": "set-up-a-reverse-proxy-using-nginx",
  "label": "Set up a reverse proxy using nginx",
  "parent": "provider-specific-instructions"
}, {
  "depth": 0,
  "id": "request-a-consultation",
  "label": "Request a consultation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      img: "img",
      h3: "h3",
      ol: "ol",
      h4: "h4",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      Video,
      Form
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Form) _missingMdxReference("Form", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Video) _missingMdxReference("Video", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Use a reverse proxy with HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Websites built on HubSpot's CMS automatically use HubSpot's global CDN with hundreds of local points of presence. However, you may have an existing CDN or complex routing rules that are not possible to maintain using HubSpot's built-in CDN. In that case, you may want to set up a reverse proxy with HubSpot instead."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A reverse proxy is a type of proxy server that takes resources from one or more servers and then returns them to the client with the appearance of it coming from the proxy server itself. For example, you have an existing website at ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "www.website.com"
      }), " which is ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " hosted on the HubSpot CMS, while also hosting a blog on HubSpot at ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "www.website.com/blog"
      }), ". Using a reverse proxy, the blog would appear to be hosted from the same server as the website when it's actually coming from HubSpot's servers."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about how to set up a reverse proxy with HubSpot. By the end of the guide, you will have:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Reviewed the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#considerations",
          children: "considerations"
        }), " for whether to set up a reverse proxy."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-the-proxy",
          children: "Configured the proxy"
        }), " in your external environment. The guide includes general instructions as well as provider-specific instructions for ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#set-up-a-reverse-proxy-in-amazon-cloudfront",
          children: "Amazon CloudFront"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#set-up-a-reverse-proxy-using-nginx",
          children: "nginx"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#confirm-that-the-configuration-is-correct",
          children: "Confirmed that the configuration is correct"
        }), ". This guide also includes troubleshooting steps if you're seeing a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "404"
        }), " error during that process."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-the-domain-to-hubspot",
          children: "Added the domain to HubSpot"
        }), " to enable content creation in HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Note that this guide assumes that you have an existing website or app that uses a CDN or web service which supports reverse proxies."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Reverse proxy setup is ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " provided by HubSpot's support team. ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#interested-in-purchasing-consulting-to-help-configure-reverse-proxy-fill-out-the-form-below-",
            children: "You may purchase time with a HubSpot Technical Consultant"
          }), " for support with implementing a reverse proxy on HubSpot, or use our ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
            children: "community forums for peer-to-peer support"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot’s built-in CDN and all other services have multiple instances with automatic failover and recovery. If you implement a reverse proxy, it's highly recommend that you use multiple instances with load balancing. If all requests are instead routed through a single proxy node, it’s possible that requests will trip rate limiting protocols. This would result in requests being served ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "429"
          }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "403"
          }), " responses until an in-browser JavaScript challenge is completed."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Considerations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using your own CDN or reverse proxy may open up more configuration options, but it also requires significant operational knowledge and maintenance. Additionally, if you proxy a subpath of your site to HubSpot, your main ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sitemap.xml"
      }), " file won't include HubSpot pages unless you manually add them."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before proceeding with a reverse proxy setup, review the list of feature considerations below."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feature"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "HubSpot's CDN"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Custom Solution"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Bandwith"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Additional cost"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SSL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included; automatic setup"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Additional cost; manual setup"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Global CDN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "?"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Automatic cache management"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Anti-abuse protection"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Customer-owned"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "24x7 monitoring"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Customer-owned"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Support"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Customer-owned"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "IPv6"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "?"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "HTTP/2"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Included"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "?"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Latency"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Optimal"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Additional network hop required"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Configure the proxy"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adding a custom reverse proxy means that users of your website will make a request to your service and then be proxied through to HubSpot's CDN, introducing another network hop."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To start the proxy setup process, first configure the proxy in your external environment, such as a CDN like ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-in-amazon-cloudfront",
        children: "Amazon CloudFront"
      }), " or an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-using-nginx",
        children: "nginx"
      }), " server."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You should always implement the proxy in a load balanced environment so that traffic from your proxy rotates requests to the HubSpot CNAME origin from multiple IP addresses. The CNAME to use will depend on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account-management/migrate-your-account-data-to-hubspots-eu-data-center",
        children: "data center"
      }), " that your account is assigned to."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To check which data center your account is assigned to, navigate to your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/account-defaults/data-hosting",
        children: "Account Defaults settings page"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the top navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Account Defaults"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Data Hosting"
        }), " tab."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/account-defaults-data-hosting-tab.png",
        alt: "account-defaults-data-hosting-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You'll then find your assigned data location listed under ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Your data hosting location"
      }), ". Proceed to the next sections depending on that location:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#eu-centered-accounts",
          children: "EU-centered accounts"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#us-centered-accounts",
          children: "US-centered accounts"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "EU-centered accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For accounts assigned to the EU data center, the CNAME needed will be in the following format: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<HubID>.sites-proxy.hscsocdn-eu1.net"
      }), ". Unlike accounts US-centered accounts, the suffix doesn't change based on your Hub ID."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if your HubID is 123456, the correct origin CNAME would be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456.sites-proxy.hscsocdn-eu1.net"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "US-centered accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For accounts assigned to the US data center, the CNAME needed will be in the following format: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<HubID>.<suffix>"
      }), ", where suffix is determined by the last two digits of your HubID. Use the table below to match the last two digits of your HubID with a suffix."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "HubIDs ending with"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Suffix"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "00-10"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn00.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "11-19"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn10.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "20-29"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn20.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "30-39"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn30.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "40-49"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn40.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "50-59"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn00.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "60-69"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn10.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "70-79"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn20.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "80-89"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn30.net"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "90-99"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "sites-proxy.hscoscdn40.net"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if your HubID is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), ", your correct origin CNAME would be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456.sites-proxy.hscoscdn00.net"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are general instructions for configuring a reverse proxy, as well as specific guidance for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-in-amazon-cloudfront",
        children: "Amazon CloudFront"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-using-nginx",
        children: "nginx"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you have both ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          }), " Enterprise"]
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Cloudflare Enterprise"
        }), ", learn more about setting up an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.cloudflare.com/cloudflare-for-platforms/cloudflare-for-saas/saas-customers/provider-guides/hubspot/",
          children: "Orange-to-Orange (O2O)"
        }), " reverse proxy configuration in Cloudflare."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "General instructions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In general, you can configure your proxy to forward requests using your origin CNAME and add the following configurations:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Set your proxy to perform no caching for paths originating from HubSpot. HubSpot automatically manages the content of our CDN’s cache so pages are updated automatically when content is published. Note that if the service caches responses, ​pages may not update for hours or days​."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add or prepend to a ​", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-Forwarded-For"
        }), "​ header with the client IP address of the original requestor. This is required to differentiate clients from each other. Many services such as CloudFront maintain these headers automatically."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To ensure personalized content based on location works, pass a static header of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Trust-Forwarded-For: true"
        }), "​. This will trust the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "​X-Forwarded-For​"
        }), " header, which may not have been updated by all upstream proxies."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pass a ​", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HS-Public-Host"
        }), "​ header with a value of your destination domain."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Allow all HTTP methods."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ensure an SSL certificate is provisioned and installed for your proxy domain."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Forward all query strings."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Forward ​", (0, _jsxRuntime.jsx)("u", {
          children: "all"
        }), "​ other request and response headers as-is, including cookies."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ideally, all paths under your domain should proxy to HubSpot. If that’s not the case, then the following paths must proxy so assets load properly from your domain: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/_hcms/*"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/hs/*"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/hubfs/*"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/hs-fs/*​"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cs/c/*"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/e3t/*"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Confirm your configuration is correct"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In accounts with ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          }), " Enterprise"]
        }), ", you can identify issues with your configuration in your HubSpot domain settings:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "settings icon"
            }), " in the main navigation bar."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Website"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Domains & URLs"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Next to the domain you are using for your reverse proxy, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Edit"
            }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Verify Reverse Proxy Connection"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click any ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "category"
            }), " that is labeled as ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Failed"
            }), " to view recommendations for fixing the issue."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Once you've implemented your fixes, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Refresh test"
            }), " to check your configuration again."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you see an error in your domain settings stating that “Reverse proxy domains need your action to avoid website disruption,” learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/domains-and-urls/ssl-and-domain-security-in-hubspot#set-up-a-domain-control-validation-dcv-record",
          children: "set up a Domain Control Validation (DCV) record"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For all other accounts, to can confirm your configuration, visit: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://[yourFullDomain]/_hcms/diagnostics"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verify the following information:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The current time value changes on every load. This confirms that the page is not cached."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "User-Agent"
            }), " is consistent with your browser."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Accept-Language"
            }), " value is consistent with your browser."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Accept-Encoding"
            }), " header is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            }), ". This ensures that responses are compressed."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Cookie"
            }), " value is not blank."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Protocol"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The leftmost IP address in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-Forwarded-For"
            }), " matches your IP address as reported by a service like ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.whatismyip.com/",
              children: "https://www.whatismyip.com"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IP-Determined Location"
            }), " values are accurate to your location. These are based on the IP-related headers in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-Forwarded-For"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Troubleshooting"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're seeing a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "404"
      }), " when going to the diagnostics URL, that likely means you have an issue with your configuration."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Visit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://[yourFullDomain]/_hcms/_worker/headers"
      }), " to view all the headers that HubSpot is receiving from a request through your reverse proxy."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The most important headers for proxies are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-Forwarded-For"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Trust-Forwarded-For"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HS-Public-Host"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verify you are not sending additional/unnecessary headers, or duplicate values."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Clicks registered as bot events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're using Amazon CloudFront and are seeing clicks registered as bot events, the User-Agent is likely being set as Amazon CloudFront instead of the visitor's User-Agent. To fix this, update your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/using-managed-origin-request-policies.html",
        children: "managed origin request policies"
      }), " to forward the visitor's User-Agent instead."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Add the domain to HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your proxy configured, you’ll then add your domain to HubSpot. You will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " be fully connecting the domain to HubSpot in the way that you would in the standard domain connection process. Rather, you’ll start the connection process to make the domain available for publishing HubSpot content, but you will not create CNAME records in your DNS provider. By the end of this process, your proxy will receive all requests to the domain and can choose to proxy certain paths to HubSpot and other paths to other content hosts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add your domain to HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/domains",
          children: "domain settings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connect a domain"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Primary"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Secondary"
        }), ". Redirect and email sending domains are not supported for this feature."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click to toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connect with HubSpot's built-in content delivery network (CDN)"
        }), " switch off, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connect"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "content type"
        }), " you’ll be hosting on the domain, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "brand domain"
        }), ". For example, for ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "http://www.website.com",
            children: "www.website.com"
          })
        }), ", you would enter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "website.com"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this domain must match the domain being requested through your reverse proxy."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "subdomain"
        }), " that you’ll be hosting content on. The subdomain needs to match the subdomain for the externally hosted domain. Then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the domain you’ve entered, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Next, verify your domain so that HubSpot can confirm your domain ownership and allow content publishing:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "In your DNS provider, create the records using the provided values."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In HubSpot, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Verify"
            }), ". It can take up to 4 hours for HubSpot to recognize changes made to your DNS provider and verify your hostname."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Video, {
      src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/pre-provision-ssl-for-proxy.mp4",
      height: "auto",
      width: "600",
      controls: true,
      autoplay: true,
      loop: true
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Provider-specific instructions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#general-instructions",
        children: "general instructions above"
      }), " to set up your proxy, below are steps for setting up a reverse proxy with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#setup-up-a-reverse-proxy-in-amazon-cloudfront",
        children: "Amazon CloudFront"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-up-a-reverse-proxy-using-nginx",
        children: "nginx"
      }), " specifically."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you have both ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          }), " Enterprise"]
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Cloudflare Enterprise"
        }), ", learn more about setting up an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.cloudflare.com/cloudflare-for-platforms/cloudflare-for-saas/saas-customers/provider-guides/hubspot/",
          children: "Orange-to-Orange (O2O)"
        }), " reverse proxy configuration in Cloudflare."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set up a reverse proxy in Amazon CloudFront"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To set up a reverse proxy in Amazon CloudFront, you’ll need to create a new distribution with a new alternate domain name, create a new origin, then create cache behaviors for the page paths where your HubSpot content is hosted. You can learn more about working with distributions in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html",
        children: "AWS documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Log in to your Amazon CloudFront account."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Distributions"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you’re starting from scratch, you’ll first need to create a new distribution by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create Distribution"
          }), ". Alternatively, you can edit an existing distribution or skip to the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#origin",
            children: "origin and behaviors setup steps"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "General"
            }), " tab, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Edit"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Alternate Domain Names (CNAMEs)"
            }), " field, add the domain, including the subdomain. This must match the domain you added to HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Confirm your changes by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Yes, Edit"
            }), ". You’ll then be directed back to the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "General"
            }), " tab where your domain should now be listed next to ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Alternate Domain Names (CNAMEs)"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["You’ll also need to create a new CNAME record in your DNS provider using the value from the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Domain Name"
            }), " field. This value should look something like ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<value>.cloudfront.net"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Next, set up a new origin:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Origins and Origin Groups"
            }), " tab."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create Origin"
            }), ", then set up your origin:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Origin domain"
                }), " field, enter the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "<HubID>.<suffix>"
                }), " CNAME value from the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#configure-the-proxy",
                  children: "table above"
                }), ". This value should look something like ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "123.sites-proxy.hscoscdn20.net"
                }), "."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
                  children: "Add custom header"
                }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Add header"
                }), ". Then, add the following header details:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
                  children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                    children: ["To ensure personalized content based on location works, either pass a ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "X-Client-IP"
                    }), " header with a value of the end user’s IP (preferred) or pass a static header of ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "X-HubSpot-Trust-Forwarded-For: true"
                    }), ". The latter will trust the ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "X-Forwarded-For"
                    }), " header, which may not have been updated by all upstream proxies."]
                  }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                    children: ["Pass a ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "X-HS-Public-Host"
                    }), " header with a value of your destination domain."]
                  }), "\n"]
                }), "\n"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create"
            }), " to save your changes."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Then, set up ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-specify.html#DownloadDistValuesCacheBehavior",
            children: "cache behaviors"
          }), " for the page paths you’ll be hosting HubSpot content on:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Behaviors"
            }), " tab."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create Behavior"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Path pattern"
            }), " field, enter the URL path of the page that your HubSpot content is hosted on. This can be a path to a specific page, or a flexible URL such as a wildcard. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-specify.html#DownloadDistValuesPathPattern",
              children: "path patterns"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Origin and Origin Groups"
            }), " field, then select the origin you created earlier."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Save changes"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your distribution, origin, and behaviors configured, the reverse proxy will now be available for HubSpot pages that you create at the specified paths. Proceed to the steps for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-the-domain-to-hubspot",
        children: "configuring your domain in HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " by default, if no Origin Request Policy configuration is specified, Amazon CloudFront ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://aws.amazon.com/developer/application-security-performance/articles/api-dynamic-acceleration/#:~:text=By%20default%2C%20If%20you%20do%20not%20explicitly%20configure",
          children: "strips cookies, query parameters and most of headers"
        }), " before forwarding the request to your origin. This includes setting the User-Agent to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Amazon CloudFront"
        }), ", which will result in clicks to be marked as bot events. To resolve this, you should ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/using-managed-origin-request-policies.html#managed-origin-request-policy-user-agent-referer",
          children: "update origin request policies"
        }), " to forward the visitor's User-Agent instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set up a reverse proxy using nginx"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To configure a reverse proxy with nginx, you’ll need to create a location configuration file that includes SSL information and the location path information."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " full documentation for setting up reverse proxies with nginx can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.nginx.com/nginx/admin-guide/web-server/reverse-proxy/",
          children: "nginx documentation"
        }), ". In particular, you may want to review documentation for settings such as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.nginx.com/nginx/admin-guide/security-controls/securing-http-traffic-upstream/",
          children: "securing upstream traffic"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://nginx.org/en/docs/http/ngx_http_proxy_module.html#proxy_ssl_server_name",
          children: "proxy_ssl"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.nginx.com/nginx/admin-guide/load-balancer/using-proxy-protocol/",
          children: "$proxy_protocol_addr"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When working with nginx, there are several headers and settings required to route traffic. Below are snippets of a sample nginx location configuration file to use as a starting point."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "location ~ ^(/|/some-other-path) {\n    proxy_set_header Host $http_host;\n    proxy_set_header X-HS-Public-Host www.example.com;\n    proxy_pass_request_headers on;\n    proxy_set_header X-HubSpot-Trust-Forwarded-For true;\n    proxy_pass https://2XX93.sites-proxy.hscoscdnXX.net;\n    proxy_ssl_name www.example.com;\n    proxy_ssl_server_name on;\n    proxy_set_header   X-Real-IP $proxy_protocol_addr;\n    proxy_set_header   X-Forwarded-Proto  $scheme;\n    proxy_set_header   X-Forwarded-For $proxy_add_x_forwarded_for;\n    proxy_set_header   X-HubSpot-Client-IP $proxy_protocol_addr;\n  }\n\nlocation ~ ^(/hs|/_hcms|/hubfs|/hs-fs|/cs/c) {\n    proxy_set_header Host $http_host;\n    proxy_set_header X-HS-Public-Host www.example.com;\n    proxy_pass_request_headers on;\n    proxy_set_header X-HubSpot-Trust-Forwarded-For true;\n    proxy_pass https://2XX93.sites-proxy.hscoscdnXX.net;\n    proxy_ssl_name www.example.com;\n    proxy_ssl_server_name on;\n    proxy_set_header   X-Real-IP  $proxy_protocol_addr;\n    proxy_set_header   X-Forwarded-Proto  $scheme;\n    proxy_set_header   X-Forwarded-For    $proxy_add_x_forwarded_for;\n    proxy_set_header   X-HubSpot-Client-IP $proxy_protocol_addr;\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the above code, note that the proxy connection and content host domain are different: one is a HubSpot provided CNAME (", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#configure-the-proxy",
        children: "see configuration table"
      }), "), and the other is the domain that the content should be served from, matching the domain you’ll add to HubSpot. This is due to the SNI (Server Name Indication) connection process that establishes a secure connection between your proxy and HubSpot’s servers."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To enable this in your nginx proxy, ensure that you include the settings below, which are also in the above code:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "proxy_ssl_name www.example.com;\nproxy_ssl_server_name on;\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These settings instruct nginx to send the server domain name with the SNI SSL connection handshake since the origin domain is different from the Host."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Request a consultation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fill out the form below to request a consultation from a HubSpot Technical Consultant."
    }), "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "a6a44927-c1c2-4769-bb39-ab248ad618b4",
      responseType: "inline",
      message: `Thanks for submitting the form. We'll be in touch shortly!`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}