"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77654816928;
const slug = exports.slug = 'guides/cms/setup/js-frameworks';
const title = exports.title = 'Cómo usar los marcos de JavaScript en HubSpot';
const name = exports.name = 'LATAM (ES) How to use JavaScript frameworks on HubSpot';
const metaDescription = exports.metaDescription = 'Usando el CMS de HubSpot, puedes crear aplicaciones web avanzadas basadas en JS. Hay plantillas disponibles para que sea más fácil ponerse en marcha. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C2%BFqu%C3%A9-nivel-de-cms-de-hubspot-se-necesita%3F",
  "label": "¿Qué nivel de CMS de HubSpot se necesita?",
  "parent": null
}, {
  "depth": 0,
  "id": "marcos-y-bibliotecas",
  "label": "Marcos y bibliotecas",
  "parent": null
}, {
  "depth": 0,
  "id": "lo-que-debes-saber",
  "label": "Lo que debes saber",
  "parent": null
}, {
  "depth": 1,
  "id": "cosas-que-debes-considerar-al-desarrollar",
  "label": "Cosas que debes considerar al desarrollar",
  "parent": "lo-que-debes-saber"
}, {
  "depth": 2,
  "id": "representaci%C3%B3n-del-lado-del-servidor-y-del-lado-del-cliente",
  "label": "Representación del lado del servidor y del lado del cliente",
  "parent": "lo-que-debes-saber"
}, {
  "depth": 2,
  "id": "anal%C3%ADticas-de-aplicaciones-de-una-sola-p%C3%A1gina-spa",
  "label": "Analíticas de aplicaciones de una sola página (SPA)",
  "parent": "lo-que-debes-saber"
}, {
  "depth": 2,
  "id": "crear-tu-aplicaci%C3%B3n-utilizando-los-m%C3%B3dulos-de-hubspot",
  "label": "Crear tu aplicación utilizando los módulos de HubSpot",
  "parent": "lo-que-debes-saber"
}, {
  "depth": 2,
  "id": "crear-una-plantilla-completa-en-su-lugar",
  "label": "Crear una plantilla completa en su lugar",
  "parent": "lo-que-debes-saber"
}, {
  "depth": 2,
  "id": "delimitadores",
  "label": "Delimitadores",
  "parent": "lo-que-debes-saber"
}, {
  "depth": 0,
  "id": "vuejs",
  "label": "VueJS",
  "parent": null
}, {
  "depth": 1,
  "id": "sin-un-paso-de-compilaci%C3%B3n",
  "label": "Sin un paso de compilación",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "agrega-la-biblioteca-de-vue-a-tu-m%C3%B3dulo",
  "label": "Agrega la biblioteca de Vue a tu módulo",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "agrega-el-c%C3%B3digo-html",
  "label": "Agrega el código HTML",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "agrega-el-c%C3%B3digo-javascript",
  "label": "Agrega el código JavaScript",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "con-un-paso-de-compilaci%C3%B3n",
  "label": "Con un paso de compilación",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "trabajar-con-formularios-y-cta-de-hubspot-dentro-de-los-componentes-de-vue",
  "label": "Trabajar con formularios y CTA de HubSpot dentro de los componentes de Vue",
  "parent": "vuejs"
}, {
  "depth": 0,
  "id": "reactjs",
  "label": "ReactJS",
  "parent": null
}, {
  "depth": 0,
  "id": "otras-bibliotecas-de-javascript",
  "label": "Otras bibliotecas de JavaScript",
  "parent": null
}, {
  "depth": 1,
  "id": "utilizar-require_js-en-lugar-de-etiquetas-de-script",
  "label": "Utilizar require_js en lugar de etiquetas de script",
  "parent": "otras-bibliotecas-de-javascript"
}, {
  "depth": 0,
  "id": "tutoriales-y-gu%C3%ADas-recomendados",
  "label": "Tutoriales y guías recomendados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      em: "em",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Cómo usar marcos y bibliotecas de JavaScript en HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando el CMS de HubSpot, puedes crear aplicaciones web basadas en JavaScript."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Qué nivel de CMS de HubSpot se necesita?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tu sitio web requiere un código del lado del servidor o un mecanismo de suscripción de contenido, puedes aprovechar las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "funciones sin servidor"
      }), " de HubSpot y la característica de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "gestión de miembros de contenido"
      }), " si tienes una suscripción a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ". Sin embargo, también puedes crear tu propio sistema utilizando proveedores de terceros, como AWS Lambda, combinado con una puerta de enlace API para ejecutar código del lado del servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás creando una aplicación web que necesita alcanzar los punto de terminación de la API que requieren autentificación, como una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "ficha de acceso privado a la aplicación"
      }), ", no deberías ejecutar ese código en el navegador. Estarías exponiendo tus credenciales ante cualquiera que vea la página. El método correcto es crear una capa de abstracción entre el navegador y la API autentificada: un punto de terminación de API personalizada que no requiere exponer tus credenciales y se sirve desde el mismo dominio que el sitio web que lo llama."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al pulsar el punto de terminación de la API personalizada, se ejecutará el código del lado del servidor que puede realizar la solicitud autentificada. A continuación, puedes hacer cualquier formato de los datos o la lógica de negocios que desees mantener en secreto y enviar el resultado al navegador."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comúnmente, se utilizan funciones sin servidor para hacer esto porque tienen una escalabilidad increíble y no requieren administrar y mantener tu propio servidor. Puedes usar proveedores como AWS Lambda combinados con una puerta de enlace de API o puedes usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "las funciones sin servidor"
      }), " de HubSpot. La ventaja de las funciones sin servidor de HubSpot es que no necesitas administrar múltiples servicios por separado. La experiencia se simplifica y se integra directamente con el mismo sistema de archivos de desarrollador en el que existen temas, plantillas y módulos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si no necesitas realizar llamadas de API autentificadas, entonces no necesitas Enterprise para tu aplicación. React y Vue son marcos front-end que no necesitan funciones sin servidor para funcionar; es lo que haces con ellos lo que importa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Marcos y bibliotecas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para las aplicaciones web, los desarrolladores suelen utilizar marcos de JavaScript que ayudan a administrar el estado y la interfaz de usuario (IU)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub no fue diseñado específicamente para trabajar con un marco específico en mente, pero muchos marcos de JavaScript comunes funcionan en el CMS de HubSpot. Al basarte en HubSpot, es posible que debas pensar en cómo trabajas con esos marcos de manera diferente. Pero las cosas básicas necesarias para trabajar con estos marcos están disponibles: la capacidad de escribir ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "plantillas personalizadas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " y JavaScript. También te permitimos hacer tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "codificación localmente"
      }), ", para que puedas usar un paso de compilación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lo que debes saber"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Estamos colaborando con nuestra comunidad de desarrolladores para establecer buenas prácticas para construir con marcos de JavaScript comunes en HubSpot. Si bien es posible hacerlo, hay aspectos de cómo funciona el CMS de HubSpot que pueden requerir que configures conscientemente tu proyecto de manera diferente a como lo harías en una página en HTML sencilla."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puede haber algunas partes de tu proceso de desarrollo a las que no te has acostumbrado. Te pedimos que nos des tus comentarios para que podamos mejorar la experiencia de todos los desarrolladores. Actualmente el mejor lugar para hacerlo es nuestro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "foro para desarrolladores"
      }), ". A medida que experimentamos y aprendemos, continuaremos actualizando nuestra documentación en consecuencia."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cosas que debes considerar al desarrollar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El CMS de HubSpot tiene un potente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "sistema de módulos"
      }), ", lo que te permite crear fragmentos reutilizables de CSS, JavaScript y HTML con acceso a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL, el lenguaje de plantillas de HubSpot"
      }), ". Los módulos de HubSpot te proporcionan una forma de dar mucho control y poder a los creadores de contenido. Los marcos de JavaScript modernos a menudo tienen sus propios sistemas de módulos. Todos estos sistemas se construyen independientes entre sí y, como resultado, a menudo tienen diferentes soluciones para los problemas que puedas encontrar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Representación del lado del servidor y del lado del cliente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La representación del lado del servidor es cuando el HTML de una página se genera a partir de la lógica de plantilla en el servidor antes de enviar cualquier HTML a un navegador."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La representación del lado del cliente es cuando se envía una versión más ligera o «incompleta» del HTML desde el servidor y se utiliza JavaScript para generar el HTML. Esto transfiere el procesamiento de la lógica desde el servidor al navegador web (el cliente)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La hidratación es el acto de combinar ambas técnicas. Primero, en el servidor, se genera tanto HTML como sea posible. A continuación, JavaScript evalúa el HTML proporcionado y realiza cambios más pequeños según sea necesario cuando el usuario interactúa con la página o se reciben datos. Esto reduce la carga en el cliente y potencialmente reduce el tiempo que tarda el usuario en ver el contenido cargado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En HubSpot CMS, HubL se procesa en el servidor y luego se almacena en caché a nivel de la CDN. Puedes usar JavaScript para hidratar o representar del lado del cliente el HTML que el navegador sirve al visitante del sitio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Analíticas de aplicaciones de una sola página (SPA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las analíticas son importantes para la capacidad de tu empresa de crecer y adaptarse para ofrecer soluciones a tus clientes y prospectos. Al crear una aplicación de una sola página que contiene varias vistas, es posible que desees rastrear al visitante que accede a diferentes vistas, como páginas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La mayoría de las plataformas de análisis proporcionan una manera de hacer esto con JavaScript. HubSpot no es diferente. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/api/events/tracking-code#tracking-in-single-page-apps",
        children: "Pulsa la vista de página"
      }), " cuando la aplicación cambie de vista."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Crear tu aplicación utilizando los módulos de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El sistema de módulos de HubSpot es un sistema de módulos del lado del servidor, que genera un documento HTML a partir de parciales de HubL + HTML y que genera CSS y JavaScript minimizados para cada módulo dentro de una página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si creas usando módulos de HubSpot, hay varios beneficios que vienen con él:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los creadores de contenido pueden agregar tu módulo a páginas que tengan áreas de arrastrar y soltar o columnas flexibles. También pueden mover y quitar el módulo ellos mismos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes proporcionarle campos al creador de contenido que le permitan configurar tu aplicación."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El código solo se representa en la página si el módulo se utiliza realmente."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Module.css"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.js"
        }), " se minimizan automáticamente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El costo de usar el sistema de módulos de HubSpot es que requiere que los módulos se compongan de archivos específicos y en lugares diferentes de los que normalmente colocarías tu código."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Crear una plantilla completa en su lugar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También puedes crear tu aplicación como una plantilla en lugar de dentro del marco del módulo. Esto te da más flexibilidad con tu estructura de archivos. Pero no obtienes los beneficios que brindan los módulos; los creadores de contenido no podrán agregar esta aplicación a páginas dentro de áreas de arrastrar y soltar y columnas flexibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Delimitadores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Algunos marcos de JavaScript utilizan llaves ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ }"
      }), " para delimitar su código. El lenguaje HubL utiliza estas llaves también. Hay tres estrategias que puedes usar para asegurarte de no tener conflictos entre tu marco y HubL: puedes usar la etiqueta HubL sin procesar para envolver tu JSX, establecer el marco para usar un delimitador diferente o usar un paso de compilación que compile el JavaScript de antemano."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "VueJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El popular marco ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/",
        children: "Vue.js"
      }), " se puede usar con y sin un paso de compilación. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html",
        children: "documentación de Vue"
      }), " para obtener un desglose más detallado de los pros y los contras de cada método. En HubSpot hay pros y contras específicos que también debes tener en cuenta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sin un paso de compilación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Integrar Vue.js sin un paso de compilación en un módulo es fácil."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrega la biblioteca de Vue a tu módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ", usa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " para agregar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html#Direct-lt-script-gt-Include",
        children: "biblioteca de Vue"
      }), " asegurándote de que solo se cargará una vez cuando se agregue el módulo a una página."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Mientras desarrollas, usa la compilación dev para obtener información útil para la depuración. Una vez en producción, se recomienda usar la URL de la CDN para la versión de Vue específica o descargar ese archivo y alojarlo como un archivo JavaScript en el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "sistema de archivos de desarrollador"
        }), " de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrega el código HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copia el código HTML de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "introducción de Vue.js"
      }), " y pégalo en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ". Envuelve este código en una etiqueta de HubL sin procesar para evitar que sea evaluado como HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# raw prevents code within it from being evaluated as HubL #}\n\n<div id=\"app\">\n  {{ message }}\n</div>\n\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrega el código JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copia el JavaScript de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "introducción de Vue.js"
      }), " y pégalo en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Envuelve este código en un listener de eventos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/API/Window/DOMContentLoaded_event",
        children: "para asegurarte de que se ejecute una vez que el contenido DOM haya terminado de cargarse"
      }), ". Publica el módulo y previsualízalo. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ahora deberías ver que tu aplicación básica de Vue funciona."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var app = new Vue({\n  el: '#app',\n  data: {\n    message: 'Hello Vue!',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Con un paso de compilación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Creamos una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate",
        children: "plantilla"
      }), " [versión beta] para ayudarte a poner en marcha el método del módulo HubSpot para crear una aplicación VueJS. La manera más fácil de aprovecharlo es ejecutar el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create vue-app"
      }), " desde la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI del CMS"
      }), ". Las instrucciones se pueden encontrar en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/",
        children: "repositorio"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["¡Esta plantilla es nueva y nos encantaría recibir tus comentarios! Cuéntanos qué se podría mejorar y cualquier problema que encuentres. La mejor manera de proporcionar comentarios es ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/issues",
        children: "enviando incidencias al repositorio de GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Trabajar con formularios y CTA de HubSpot dentro de los componentes de Vue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las CTA y los formularios de HubSpot tienen sus propias etiquetas de script y administran su propio HTML. Para asegurarte de que tu componente de Vue no modifique el formulario o la CTA, crea un elemento HTML alrededor del código de incrustación del CTA o formulario. ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://vuejs.org/v2/api/#v-once",
        children: ["Aplica ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v-once"
        }), " a ese elemento."]
      }), " Esto garantiza que el código se representará una vez y luego su componente Vue lo ignorará."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ReactJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En lugar de usar HubL para crear módulos y parciales, puedes usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
        children: "JavaScript y React"
      }), ". Además de unir los componentes de React renderizados por el servidor en el HTML generado por HubL, los módulos y parciales de JavaScript admiten la interactividad tanto del lado del servidor como del lado del cliente. Más información en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "Introducción a JS Building Blocks"
      }), " de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes darle un vistazo a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "plantilla React"
      }), " que puedes usar para ponerte en marcha rápidamente con una aplicación ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://reactjs.org/",
        children: "React"
      }), " dentro de un módulo de HubSpot. La manera más fácil de aprovecharlo es ejecutar el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create react-app"
      }), " desde la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI del CMS"
      }), ". Desde allí, sigue las instrucciones que hay en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "repositorio"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["¡Esta plantilla es nueva y nos encantaría recibir tus comentarios! Cuéntanos qué se podría mejorar y cualquier problema que encuentres. La mejor manera de proporcionar comentarios es ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate/issues",
        children: "enviando incidencias al repositorio de GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Otras bibliotecas de JavaScript"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay muchas bibliotecas de JavaScript por ahí y es imposible para nosotros documentarlas todas individualmente. Hay algunas buenas prácticas básicas que debes conocer y comprender al usar las bibliotecas de JavaScript en HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utilizar require_js en lugar de etiquetas de script"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes tener decenas de módulos y plantillas que usan bibliotecas de JavaScript compartidas y no preocuparte por cargar esas bibliotecas varias veces. Para hacer esto, necesitas usar la función ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " de HubL. Los scripts que se carguen con esta función solo se cargarán una vez por página, independientemente del número de módulos, parciales y la plantilla que se requieran."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{{ require_js(get_asset_url('/js/jquery-latest.js')) }}\n\n{{ require_js(\"https://cdnjs.cloudflare.com/ajax/libs/d3/6.2.0/d3.min.js\") }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Usa ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get_asset_url()"
        }), " para solicitar archivos almacenados dentro del sistema de archivos de desarrollador. La ventaja aparte de simplemente compartir la ubicación de tus archivos de desarrollo y consolidar la seguridad de estos archivos, es que generará menos búsquedas de DNS."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El uso de require puede ser increíble para el rendimiento, ya que no solo cargarás el archivo una sola vez. Si los materiales de una página no necesitan esa biblioteca, no se cargarán en absoluto. Incluso puedes usar require con la lógica de HubL para cargar recursos solo cuando realmente los necesites."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriales y guías recomendados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimización para el rendimiento"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "La accesibilidad no es una característica"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Cómo usar componentes web en HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Primeros pasos con módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Primeros pasos con funciones sin servidor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Creación de un proceso de desarrollo eficiente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Desarrollo de páginas dinámicas con HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
          children: "Construir módulos y parciales con JavaScript"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}