"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694134;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/tutorial';
const title = exports.title = 'ドラッグ＆ドロップエリアを作成する';
const name = exports.name = '[新規]ドラッグ＆ドロップエリアを使ってみる';
const metaDescription = exports.metaDescription = 'ドラッグ＆ドロップエリアを使用して、簡単にレイアウト、スタイル、コンテンツを変更できるようにコンテンツ制作担当者をサポートします。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.%E6%96%B0%E8%A6%8Fhtml%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "1.新規HTMLテンプレートを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "2. ドラッグ＆ドロップエリアを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%A7%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "3. モジュールでセクションを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-%E8%A4%87%E6%95%B0%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E5%90%AB%E3%82%81%E3%82%8B",
  "label": "4. 複数のモジュールを含める",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-%E5%88%97%E3%81%A8%E8%A1%8C%E3%82%92%E7%B5%84%E3%81%BF%E8%BE%BC%E3%82%80",
  "label": "5. 列と行を組み込む",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%B3%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%8D%E3%83%B3%E3%83%88%E3%81%AE%E6%B1%8E%E7%94%A8%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "6. ドラッグ＆ドロップコンポーネントの汎用スタイルを設定する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB",
  "label": "関連チュートリアル",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ドラッグ＆ドロップエリアを使ってみる"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "を使用することで、開発者はコンテンツエディター内でレイアウト、スタイル、コンテンツを直接変更できる、ページ内のセクションを作成できます。これにより、開発者はグローバル構造を使用していくつかのテンプレートを作成して制作担当者をサポートできます。制作担当者は、小さなレイアウトを変更するためにコードに手を加えたり新しいテンプレートを必要としたりすることなく、さまざまな目的とレイアウトの多数のページを作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/a%20new%20approach.gif",
        alt: "モジュールをページにドラッグして列と行を調整する様子を示すアニメーション"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者は、ドラッグ＆ドロップエリアに空のドロップ領域を設置できます。この場所に制作担当者は自分のページコンテンツとレイアウトを作成できます。また、開発者はドラッグ＆ドロップエリアにさまざまなモジュール、レイアウト、コンテンツを事前に配置して、制作担当者が作業を開始する際に利用してもらうことができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このチュートリアルでは、簡単なドラッグ＆ドロップエリアの設定について説明します。ドラッグ＆ドロップエリアの開発者向けリソースについては、実装のベストプラクティスとしての", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
        children: "ボイラープレート"
      }), "と、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "ドラッグ＆ドロップエリアHubLタグのリファレンスドキュメント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**コンテンツ制作担当者は、テンプレートで「", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "」タグが使用されているかどうかに応じて、ページのテンプレートを同じタイプの別のテンプレートと入れ替えることができます。"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["ビジュアル レイアウト エディター上で作成したドラッグ＆ドロップテンプレートは、「", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "」タグの有無に関係なく、他のドラッグ＆ドロップテンプレートまたはコードテンプレートと入れ替えることができます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["「", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "」タグが使用されているコードテンプレートは、「", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "」タグが使用されている他のコードテンプレートとのみ入れ替えることができます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["「", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "」タグが使用されていないコードテンプレートは、「", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "」タグが使用されていない他のコードテンプレートとのみ入れ替えることができます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1.新規HTMLテンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "新しいHTMLテンプレートを作成して、ドラッグ＆ドロップセクションのHubLおよびHTMLを格納します。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ドラッグ＆ドロップエリアは、12列のレスポンシブグリッドを基盤としています。ドラッグ＆ドロップタグでは、列と行を指定するクラス名を指定して、マークアップをレンダリングします。これらのクラス名をターゲットとするスタイルシートは、開発者が追加する必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
          children: "CMS-Theme-Boilerplate"
        }), "で、実装可能なレイアウトスタイルの例をご確認ください。スタイルシートをテンプレートに追加するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-css",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ require_css() }}"
          })
        }), "を使用します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. ドラッグ＆ドロップエリアを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "は、ウェブページの一部の構造、デザイン、およびコンテンツを編集可能にするためのコンテナーです。ドラッグ＆ドロップエリアの既定のコンテンツは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "タグの本文で指定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このタグ自体によって、制作担当者がモジュールをコンテンツ制作ツール内にドラッグするためのドロップ領域が生成されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n\t<!-- generates an empty drag and drop area drop-section -->\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. モジュールでセクションを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "は最上位の行であり、常に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "の直接の子でなければなりません。セクションでは、制作担当者がコンテンツ制作ツール内のセクションに指定するスタイルの既定値を制御するさまざまなパラメーターがサポートされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この例では、バックグラウンド画像を設定し、子コンテンツの垂直方向の位置合わせを中央にし、最大幅を1000pxに設定しています。ドラッグ＆ドロップHubLタグでサポートされる全てのパラメーターについては、ドラッグ＆ドロップエリアHubLタグのリファレンスドキュメントを参照してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["セクションにあらかじめコンテンツを取り込むには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "タグを使用して、取り込むモジュールのパスを参照します。この例では既定のHubSpotモジュールを参照していますが、デザインマネージャーのファイルツリー内のパスを指定して、自分で作成した追加モジュールを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "の既定値を指定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_attribute"
      }), "タグを使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image = {\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n          },\n          max_width=1000,\n          vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text' %}\n            {% module_attribute \"html\"%}\n                This is your main headline.\n                Use this space to tell everyone about what you have to offer.\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これで、ドラッグ＆ドロップエリアに、制作担当者がコンテンツエディター内で編集できるモジュールが格納され、表示されるようになりました。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "での", (0, _jsxRuntime.jsx)(_components.code, {
        children: "max_width"
      }), "の設定がコンテンツにどのように影響するかを確認することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_text.png",
        alt: "モジュールツールバーが表示されたページエディターのスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. 複数のモジュールを含める"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["複数のモジュールを含めるには、複数の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "タグを使用します。12列グリッドに基づく", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), "パラメーターを設定することで、リッチ テキスト モジュールの横に画像モジュールを配置できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image={\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n        },\n        max_width=1000,\n        vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text', width=8, offset=0, label=\"Rich Text\" %}\n            {% module_attribute \"html\"%}\n            \t<h1>This is your main headline.</h1>\n            \t<p>Use this space to tell everyone about what you have to offer.</p>\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n        {% dnd_module path='@hubspot/linked_image',\n          width=4,\n          offset=8,\n          img={\n            \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n            \"alt\": \"Stock placeholder image\"\n          }\n        %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、編集可能な画像モジュールも、ドラッグハンドルも用意されているため、制作担当者がモジュールの幅とオフセットを変更できます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "での", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vertical_alignment"
      }), "の設定で、コンテンツの垂直方向の位置合わせを中央にするとどうなるかを確認することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_image.png",
        alt: "セクションに追加された画像モジュールを表示するページエディターのスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. 列と行を組み込む"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリアをさらに手の込んだものにするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "タグと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), "タグを使用して行と列を組み込むことができます。行と列はコンテンツエディターのセクションと同様に機能します。制作担当者は行と列をドラッグして操作し、複製、削除、スタイル設定を行うこともできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n  {% dnd_section\n    background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n    },\n    max_width=1000,\n    vertical_alignment='MIDDLE'\n  %}\n    {% dnd_module path='@hubspot/linked_image',\n      width=6,\n      img={\n        \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n        \"alt\": \"Stock placeholder image\"\n      }\n    %}\n    {% end_dnd_module %}\n    {% dnd_column width=6, offset=6 %}\n      {% dnd_row\n        padding={\n            'bottom': 15\n        }\n      %}\n        {% dnd_module path='@hubspot/rich_text' %}\n          {% module_attribute \"html\"%}\n              <h1>This is your main headline.</h1>\n              <p>Use this space to tell everyone about what you have to offer.</p>\n          {% end_module_attribute %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n      {% dnd_row %}\n        {% dnd_module path='@hubspot/form' %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n    {% end_dnd_column %}\n  {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これで、制作担当者は、モジュールやセクションに加えて、特定の行や列に対するスタイル設定とレイアウト制御ができるようになりました。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_area_full.png",
        alt: "2列の行を表示するページエディターのスクリーンショット。左側に画像モジュール、右側にリッチ テキスト モジュールとフォームモジュールがあります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. ドラッグ＆ドロップコンポーネントの汎用スタイルを設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリア、セクション、列、行、モジュールのさまざまなコンポーネント全てには、CSSを使用してスタイル設定できるクラスがあります。これらのコンポーネントの編集可能なスタイルとオプションは、HubLではなくCSSを使用して設定できます。例えば、既定のパディングはCSSで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_sections"
      }), "に設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".dnd-section {\n  padding: 80px 20px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップ エリア コンポーネントの汎用CSSセレクターは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-section"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-column"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-row"
      }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-module"
      }), "です。これらのdndプレフィックスが付いたクラス以外、マークアップの実際のグリッドクラス名はブートストラップ2の名前に基づいています。これは、ドラッグ＆ドロップでブートストラップ2を使用する必要があることを意味するわけではありません。ページに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "を追加する場合、開発者には、グリッドが機能するようなスタイルを指定する責任があります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/css/objects",
        children: "CMS-Theme-Boilerplate"
      }), "で、実装可能なレイアウトスタイルの例をご確認ください。スタイルシートをテンプレートに追加するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリアの開発者向けリソースについては、実装のベストプラクティスとしての", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "ボイラープレート"
      }), "と、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "ドラッグ＆ドロップエリアHubLタグのリファレンスドキュメント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連チュートリアル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "テーマを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "カスタムモジュールを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "テーマ機能を既存のサイトに追加する方法"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}