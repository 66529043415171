"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492355336;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/overview';
const title = exports.title = 'Áreas de arrastrar y soltar';
const name = exports.name = 'EMEA ES Drag and Drop Areas';
const metaDescription = exports.metaDescription = 'Las áreas de arrastrar y soltar permiten a los creadores de contenido colocar módulos, cambiar el diseño y algunos estilos dentro de los editores de contenido. Los desarrolladores crean diseños predefinidos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "la-experiencia-del-creador-de-contenido",
  "label": "La experiencia del creador de contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "experiencia-del-desarrollador",
  "label": "Experiencia del desarrollador",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A1reas-arrastrar-y-soltar-y-sus-elementos",
  "label": "Áreas arrastrar y soltar y sus elementos",
  "parent": null
}, {
  "depth": 1,
  "id": "secci%C3%B3n",
  "label": "Sección",
  "parent": "%C3%A1reas-arrastrar-y-soltar-y-sus-elementos"
}, {
  "depth": 1,
  "id": "columna",
  "label": "Columna",
  "parent": "%C3%A1reas-arrastrar-y-soltar-y-sus-elementos"
}, {
  "depth": 1,
  "id": "fila",
  "label": "Fila",
  "parent": "%C3%A1reas-arrastrar-y-soltar-y-sus-elementos"
}, {
  "depth": 1,
  "id": "m%C3%B3dulo",
  "label": "Módulo",
  "parent": "%C3%A1reas-arrastrar-y-soltar-y-sus-elementos"
}, {
  "depth": 0,
  "id": "estructura-y-estilo-html",
  "label": "Estructura y estilo HTML",
  "parent": null
}, {
  "depth": 1,
  "id": "estilo-y-atributos-del-editor",
  "label": "Estilo y atributos del editor",
  "parent": "estructura-y-estilo-html"
}, {
  "depth": 0,
  "id": "migraci%C3%B3n",
  "label": "Migración",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriales-y-gu%C3%ADas-relacionadas",
  "label": "Tutoriales y guías relacionadas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Áreas de arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta página ilustra y explica la experiencia y los conceptos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". Debido a que esta experiencia es bastante amplia, es una buena idea familiarizarse con ella antes de sumergirse en el código."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que estés listo para construir, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "cómo empezar con dnd_area"
      }), ", y la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "referencia de dnd_area"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las áreas de arrastrar y soltar permiten a los desarrolladores crear áreas de páginas y parciales globales que empoderan a los creadores de contenido. Las áreas de arrastrar y soltar permiten a los creadores de contenido colocar módulos, cambiar el diseño y algunos estilos dentro de los editores de contenido. Esto permite crear menos plantillas, que los creadores de contenido pueden utilizar para hacer una multitud de páginas con distintos propósitos y diseños por su cuenta. Los creadores de contenido pueden hacer esto sin tener que lidiar con el código o requerir nuevas plantillas para pequeños cambios de diseño. Debido a que son tan flexibles y útiles es una buena idea tener al menos 1 plantilla en tu tema que incluya áreas de arrastrar y soltar."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " las áreas de arrastrar y soltar no se pueden utilizar en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "publicaciones del blog"
        }), " ni en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates#email",
          children: "plantillas de correo electrónico"
        }), " en este momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "La experiencia del creador de contenido"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando un creador de contenido crea una página utilizando una plantilla que tiene áreas de arrastrar y soltar, primero ve la página con módulos predefinidos colocados en un diseño por el desarrollador. Esto ayuda al creador de contenido a comprender cuán visualmente deberían o tienden a verse la mayoría de las páginas que usan esta plantilla. Este es un punto de partida, pero no una apariencia de bloqueo. El creador de contenido puede arrastrar y soltar módulos, secciones, filas y columnas para reorganizarlos. Pueden cambiar el tamaño, editar su contenido y ajustar distintas opciones de estilo visual que permiten alinear vertical y horizontalmente el contenido y agregar fondos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para los creadores de contenido esto es mucho poder. Esto les da suficiente flexibilidad para hacer cambios simples en la página sin tener que molestar a un desarrollador por pequeños ajustes."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " un creador de contenido puede cambiar la plantilla de una página por otra del mismo tipo, dependiendo de si tiene etiquetas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas construidas con el editor de diseño visual de arrastrar y soltar pueden cambiarse por otras plantillas de arrastrar y soltar o plantillas codificadas con o sin etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " sólo pueden cambiarse por otras plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas ", (0, _jsxRuntime.jsx)("u", {
            children: "sin"
          }), " etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " sólo pueden cambiarse por otras plantillas codificadas sin etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/drag-and-drop.gif",
        alt: "La experiencia del editor de páginas para dnd_areas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Experiencia del desarrollador"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para los desarrolladores, trabajar con dnd_area es semi-similar a trabajar con los frameworks CSS más comunes y sus cuadriculas. Los desarrolladores diseñan la página utilizando contenedores llamados secciones, que contienen filas. Dentro de esas filas hay módulos y columnas. Casi todo lo que los creadores de contenido pueden hacer en el editor de páginas con etiquetas de área de arrastrar y soltar lo pueden hacer los desarrolladores en código en las propias plantillas. Lo que los desarrolladores están construyendo es el contenido predeterminado para las páginas que utilizan esa plantilla."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los beneficios de utilizar áreas de arrastrar y soltar frente a las etiquetas de módulos de codificación dura en la plantilla, es que los creadores de contenido pueden cambiar el contenido y el diseño dentro de ellos. Debido a esta flexibilidad, una plantilla con áreas de arrastrar y soltar puede resolver una multitud de diseños de páginas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ve las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "plantillas CMS Theme Boilerplate"
      }), " para ver las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " en uso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd%20coded.png",
        alt: "dnd_area codificado en VS Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Áreas arrastrar y soltar y sus elementos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las áreas de arrastrar y soltar se componen de un puñado de bloques de construcción que definen el diseño y apoyan la flexibilidad de la alineación del estilo. La etiqueta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " crea una región en la página que el editor de páginas reconoce, permitiendo agregar, eliminar y reorganizar los elementos de arrastrar y soltar. Estos se definen utilizando HubL dentro de las plantillas HTML+HubL. Todos los elementos arrastrar y soltar viven dentro de un área de arrastrar y soltar. No se pueden anidar las áreas de arrastrar y soltar, y los elementos de arrastrar y soltar no pueden contener áreas de arrastrar y soltar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una cosa importante que debemos entender como desarrolladores es que el contenido del área de arrastrar y soltar que se define en la plantilla, es un punto de partida para las páginas que utilizan esa plantilla. Los creadores de contenido pueden cambiar completamente el contenido dentro de un área de arrastrar y soltar. Tu trabajo consiste en proporcionarles un punto de partida sensato para que lo modifiquen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Example%20structure.png",
        alt: "Ejemplo de estructura"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este diagrama es un desglose de cómo se pueden anidar los distintos elementos de arrastrar y soltar. Un concepto clave que hay que entender es que tanto las secciones como las filas pueden contener columnas y módulos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Drag%20and%20Drop%20relationships.png",
        alt: "Relaciones de los elementos de arrastrar y soltar"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sección"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las secciones son un tipo especial de fila. Las secciones se crean en las plantillas utilizando la etiqueta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), ". Son el único elemento de arrastrar y soltar que puede ser descendiente directo de un área de arrastrar y soltar. Puedes pensar en las secciones como un contenedor de envoltura exterior. Pueden permitir que el contenido sea de ancho completo o que tenga un ancho máximo confinado en el centro. Debido a que las secciones envuelven las columnas y los módulos, facilita la reorganización e implementación de grandes porciones de contenido. La etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " no muestra un elemento HTML ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Section%20in%20page%20editor.png",
        alt: "Sección en el editor de páginas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La apariencia de una sección en el editor de páginas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los desarrolladores pueden proporcionar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#section-templates",
        children: "plantillas de sección"
      }), ", que crean secciones reutilizables en el editor de páginas. Se trata de secciones preconfiguradas definidas por el desarrollador, que los creadores de contenidos pueden utilizar como punto de partida. Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#reusable-sections",
        children: "secciones reutilizables"
      }), " tienen algunas capacidades únicas, incluyendo la posibilidad de utilizarlas de forma similar a una plantilla parcial estándar de hubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Columna"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las columnas son envoltorios para las filas y los módulos. Se colocan columnas dentro de una fila, o sección que es una fila especializada. Las columnas se crean en las plantillas utilizando la etiqueta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags/dnd-areas#drag-and-drop-column-code-dnd-column-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utiliza varias columnas dentro de una fila para colocar las filas y los módulos que contienen de forma horizontal."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las columnas son regiones verticales que pueden contener filas. Puedes hacer columnas de diferentes tamaños cambiando su anchura. El tamaño de una fila es de 12 \"columnas\" de ancho, esto se refiere a la cuadrícula CSS. Las columnas dentro de una fila pueden ser de cualquier tamaño menor que 12 pero no pueden sumar más de 12."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando se colocan varias filas dentro de una columna, los módulos dentro de esas filas aparecerán apilados verticalmente. Dado que los módulos son columnas en sí mismos, un módulo no puede ser descendiente directo de una columna, sino que debe estar contenido en una fila."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20column%20in%20page%20editor.png",
        alt: "columna dnd_area en el editor de páginas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La apariencia de una columna en el editor de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fila"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las filas son envoltorios de las columnas. Las filas son creadas en las plantillas utilizando la etiqueta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags/dnd-areas#drag-and-drop-row-code-dnd-row-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        })
      }), ". Como los módulos son columnas, puedes colocarlos directamente dentro de una fila. Esto causará que los módulos aparezcan horizontalmente adyacentes entre sí."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los módulos se pueden organizar verticalmente colocándolos dentro de las filas. Si quieres colocar un módulo por encima de otro, debes colocar ese módulo dentro de una fila. A continuación, se agregaría otro módulo en una fila por encima o por debajo de esa primera fila."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20row%20in%20page%20editor.png",
        alt: "fila dnd_area en el editor de páginas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La aparición de una fila en el editor de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " son una parte fundamental del CMS de HubSpot, actuando como bloques de construcción reutilizables que se utilizan para armar un sitio, y mostrar el contenido. Cuando se construye una plantilla se colocan módulos dentro de las filas y secciones de arrastrar y soltar utilizando la etiqueta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags/dnd-areas#drag-and-drop-module-code-dnd-module-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), ". Los módulos también son columnas. Esto significa que si colocas dos etiquetas de módulo, o un módulo y una columna directamente al lado del otro, aparecerán uno al lado del otro horizontalmente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No se pueden colocar elementos de arrastrar y soltar dentro de un módulo. Los módulos no pueden ser hijos directos de un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estructura y estilo HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las áreas de arrastrar y soltar y sus elementos cuando se renderizan tienen nombres de clase para una cuadrícula de 12 columnas basada en bootstrap 2. Para facilitar su puesta en marcha, puedes utilizar el archivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "_layout.css"
      }), " del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/",
        children: "Plantilla de temas de CMS"
      }), ". Esto proporciona estilos predeterminados para esos nombres de clase."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No requieres utilizar esta hoja de estilos y puedes proporcionar tus propios estilos en su lugar. Si estás construyendo tu sitio basado en la plantilla de tema CMS y deseas utilizar tu propio CSS, querrás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/5abaf2a4c45a95dbed1d459f7f0f6407350752ac/src/templates/layouts/base.html#L8",
        children: "eliminar layout.css de ser llamado en base.html"
      }), ". Para tu propia cuadrícula CSS tendrás que apuntar a esos mismos nombres de clase de cuadrícula, pero el estilo depende de ti."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las áreas de arrastrar y soltar cuando se renderizan crean divs con clases que son usadas por el editor de la página. Algunos ejemplos serían ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-span"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-type-cell"
      }), ". No deberías apuntar directamente a estas clases ya que son utilizadas por el editor de páginas y podrían cambiar en el futuro."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En su lugar en tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " hubL agrega un parámetro de clase con el nombre de la clase que deseas utilizar"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"container-fluid my-custom-class\">\n  <div class=\"row-fluid-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n      <!--end widget-span -->\n    </div>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilo y atributos del editor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con las áreas de arrastrar y soltar los creadores de contenido pueden tener algún efecto en el estilo de la página. Por ejemplo, pueden establecer que una sección tenga un fondo. Los desarrolladores pueden pasar valores predeterminados para esas configuraciones a través de atributos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando la página se renderiza realmente, los estilos que se generan en base a esas configuraciones se agregan a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En el lanzamiento de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " esos estilos fueron cargados desde ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        }), ". Esto ha ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/drag-and-drop-area-styles-moved-from-footer-to-header",
          children: "cambiado recientemente"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        }), " y se está implementando a todas las cuentas de HubSpot con el CMS de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migración"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás cambiando las plantillas construidas con columnas flexibles para utilizar ahora áreas de arrastrar y soltar, hay algunas cosas que debe entender. Las ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "columnas flexibles no son lo mismo que las áreas de arrastrar y soltar, no puedes pasar de una plantilla que sólo tiene una columna flexible a otra que sólo tiene un área de arrastrar y soltar"
      }), ". No lo permitimos como medida de seguridad. El contenido no se mapeará desde la columna flexible al área de arrastrar y soltar. Para ilustrar el porqué de esto, supón que construiste tu nueva plantilla de manera que tiene una barra lateral y un área de contenido principal. Tu barra lateral es una columna flexible, tu contenido principal es un área de arrastrar y soltar. La herramienta de intercambio mapearía la columna flexible a la columna flexible."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriales y guías relacionadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/guides/add-theme-features-to-existing-sites#drag-and-drop-areas",
          children: "Agregar áreas de arrastrar y soltar a los sitios web existentes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Crear un área de soltar"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}