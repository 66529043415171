"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742949;
const slug = exports.slug = 'reference/api/conversations/chat-widget-sdk';
const title = exports.title = 'Conversas | SDK de widget de chat';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Chat Widget SDK';
const metaDescription = exports.metaDescription = 'O widget de chat ao vivo do HubSpot permite conversar com clientes no seu site. Com o SDK widget de chat, você pode personalizar o comportamento do widget de chat.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "inicializando",
  "label": "Inicializando",
  "parent": null
}, {
  "depth": 0,
  "id": "configurar-as-defini%C3%A7%C3%B5es-de-conversas",
  "label": "Configurar as definições de conversas",
  "parent": null
}, {
  "depth": 1,
  "id": "estilos-integrados-em-linha",
  "label": "Estilos integrados em linha",
  "parent": "configurar-as-defini%C3%A7%C3%B5es-de-conversas"
}, {
  "depth": 0,
  "id": "widget-behavior",
  "label": "Widget behavior",
  "parent": null
}, {
  "depth": 1,
  "id": "widget.load",
  "label": "widget.load",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.refresh",
  "label": "widget.refresh",
  "parent": "widget-behavior"
}, {
  "depth": 2,
  "id": "exemplo",
  "label": "Exemplo",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.open",
  "label": "widget.open",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.close",
  "label": "widget.close",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.remove",
  "label": "widget.remove",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.status",
  "label": "widget.status",
  "parent": "widget-behavior"
}, {
  "depth": 0,
  "id": "limpar-cookies-de-chat",
  "label": "Limpar cookies de chat",
  "parent": null
}, {
  "depth": 0,
  "id": "eventos-de-chat",
  "label": "Eventos de chat",
  "parent": null
}, {
  "depth": 1,
  "id": "conversationstarted",
  "label": "conversationStarted",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "conversationclosed",
  "label": "conversationClosed",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "userselectedthread",
  "label": "userSelectedThread",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "unreadconversationcountchanged",
  "label": "unreadConversationCountChanged",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "contactassociated",
  "label": "contactAssociated",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "userinteractedwithwidget",
  "label": "userInteractedWithWidget",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "widgetloaded",
  "label": "widgetLoaded",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "quickreplybuttonclick",
  "label": "quickReplyButtonClick",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "widgetclosed",
  "label": "widgetClosed",
  "parent": "eventos-de-chat"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      img: "img",
      h4: "h4",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "SDK de conversas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para conversar com clientes e leads no seu site usando a caixa de entrada de conversas do HubSpot, você pode configurar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/chat-with-your-website-visitors",
        children: "widget de chat ao vivo"
      }), ". Com o SDK de conversas, você pode fornecer uma experiência mais adaptada aos visitantes com a personalização do comportamento do widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em um nível alto, o SDK de conversas permite que você faça o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-conversations-settings",
          children: "Configurar as definições do widget de chat"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-behavior",
          children: "Controlar o comportamento do widget"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#clear-chat-cookies",
          children: "Limpar cookies de chat"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#chat-events",
          children: "Ouvir e responder a eventos de widget"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inicializando"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A API está hospedada no objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), ", que fornece acesso a todos os métodos disponíveis. O objeto é criado pelo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
        children: "código de acompanhamento do HubSpot"
      }), ", mas pode não estar disponível imediatamente no carregamento da página. Para adiar o acesso à API até que ela seja inicializada, você pode usar o assistente ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " é um campo opcional que você pode definir no objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), " , que permite especificar o código a ser executado assim que o widget se tornar disponível. Este campo usa funções de matriz para serem executadas assim que a API for inicializada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar as definições de conversas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este objeto opcional permite fornecer algumas opções de configuração ao widget antes que ele seja inicializado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  inlineEmbedSelector: '#some-id',\n  enableWidgetCookieBanner: true,\n  disableAttachment: true,\n};\nwindow.hsConversationsOnReady = [\n  () => {\n    window.HubSpotConversations.widget.load();\n  },\n];\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the widget should implicitly load or wait until the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " method is called."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inlineEmbedSelector"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specify a selector (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "#some-id"
            }), ") to embed the chat widget in a specific location on the page. Widget will be embedded inline within that DOM node and will remain open until it is removed with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.remove"
            }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#inline-embed-styling",
              children: "styling embedded chat widgets"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableWidgetCookieBanner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Control behavior of the cookie banner for all chat widgets on the page. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " (default): uses the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#4-options",
                  children: "chat widget's settings"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": presents cookie banners when the widget is loaded."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_WIDGET_LOAD"
                }), ": same as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_EXIT_INTENT"
                }), ": enable cookie banners when the user exhibits an exit intent."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableAttachment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to hide the upload attachment button in the chat widget."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableInitialInputFocus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to automatically prevent focusing on the widget's input field after an inline embedded widget is initially loaded."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "avoidInlineStyles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", injects a link tag with externally hosted CSS instead of a direct dynamic insertion of a style tag."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilos integrados em linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando o widget está incorporado em um local específico usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inlineEmbedSelector"
      }), ", vários elementos DOM são adicionados e podem ser estilizados (por exemplo, altura, largura, borda)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, se você incorporar o widget de chat usando o seletor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#some-id"
      }), ", ele será carregado com os seguintes contêineres e IDs:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div id=\"some-id\">\n  <div id=\"hubspot-conversations-inline-parent\">\n    <iframe id=\"hubspot-conversations-inline-iframe\">\n      <!-- rest of iframe content -->\n    </iframe>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "You can then customize the chat widget using those selectors, such as:"
          })
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hubspot-conversations-inline-iframe {\n  width: 300px;\n  height: 500px;\n  border: none;\n}\n"
          })
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://developers.hubspot.com/hs-fs/hubfs/livechat_after.png?width=600&name=livechat_after.png",
              alt: "livechat_after"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Widget behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotConversations.widget"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The widget object contains a number of methods that allow you to manipulate the chat widget on your page, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-load",
          children: "widget.load"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-refresh",
          children: "widget.refresh"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-open",
          children: "widget.open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-close",
          children: "widget.close"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-remove",
          children: "widget.remove"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status",
          children: "widget.status"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about each method."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.load"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " method handles the initial load on the page. This method is only necessary if you set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loadImmediately"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". Otherwise, the widget will load itself automatically."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This method is throttled to one call per second."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n\n/* ... */\n\n// Force the widget to load in an open state\nwindow.HubSpotConversations.widget.load({ widgetOpen: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "widgetOpen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se o widget deve carregar em um estado aberto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.refresh"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " trata da atualização e da nova renderização das informações do widget, de acordo com o URL da página atual. Esse método pode ser útil para widgets de chat incorporados em aplicativos de página única, quando você precisa atualizar o widget nas alterações de rota. Esse método também permite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#example",
        children: "especificar diferentes widgets de chat em diferentes rotas da página"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você acionar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " em uma rota onde não haja widget de chat e o usuário não esteja envolvido em um chat, o widget será removido. Isso ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " removerá o widget quando houver um chat ativo no momento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este método é limitado a uma chamada por segundo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.refresh();\n\n/* ... */\n\n// Force the widget to open to a specific chat flow\nwindow.HubSpotConversations.widget.refresh({ openToNewThread: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "openToNewThread"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se a criação de um thread será forçada."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com este método, você pode criar botões e links para abrir fluxos de chat específicos em uma página, adicionando parâmetros de consulta ao URL da página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/conversations-chat-widget-open-crop.gif",
        alt: "conversations-chat-widget-open-crop"
      }), "Por exemplo, você pode adicionar o seguinte código às suas páginas para gerar os botões:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"chat-buttons\">\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_sales', '?sales_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to sales\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_customer_support', '?cs_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to customer support\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_the_ceo', '?ceo_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to the CEO\n  </button>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#2-target---decide-where-the-live-chat-should-appear",
        children: "configurações de destino"
      }), " de cada chat, defina o chat para ser exibido quando o parâmetro de consulta corresponder ao que foi definido no código do botão.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/conversations-target-rule.png",
        alt: "conversations-target-rule"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.open"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.open"
      }), " abre o widget se ele ainda não estiver aberto ou carregado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.open();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.close"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.close"
      }), " fecha o widget se ele ainda não estiver fechado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.close();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.remove"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.remove"
      }), " remove o widget da página. Se o widget não estiver presente na página, este método não fará nada. O widget será exibido novamente na atualização da página ou se ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " for acionado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.remove();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.status"
      }), " devolve um objeto que contém propriedades relacionadas ao status atual do widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const status = window.HubSpotConversations.widget.status();\n\nif (status.loaded) {\n  window.HubSpotConversations.widget.refresh();\n} else {\n  window.HubSpotConversations.widget.load();\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loaded"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se o iframe do widget foi carregado."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limpar cookies de chat"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear"
      }), " exclui os cookies relacionados ao widget de chat e o retorna ao seu estado padrão no carregamento subsequente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O widget de chat cria vários cookies para preservar seu estado em visitas do site e atualizar a página. O escopo desses cookies é criado no domínio da página que hospeda o widget e eles são usados para permitir os seguintes recursos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Referência a conversas históricas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Persistência do estado aberto do widget de chat em carregamentos de página."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Persistência do estado aberto da mensagem de boas-vindas em carregamentos de página."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os cookies a seguir são apagados com este método:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-is-open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-hide-welcome-message"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter mais informações sobre esses cookies, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser",
        children: "Central de Conhecimento da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, você pode passar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{resetWidget:true}"
      }), " para a função ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear()"
      }), " para apagar todos os cookies relacionados a chat, remover o widget da página e criar uma instância do widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear({ resetWidget: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eventos de chat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O widget de chat emitirá vários eventos que você pode ouvir e responder durante todo o seu ciclo de vida. Esses eventos incluem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationstarted",
          children: "conversationStarted"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationclosed",
          children: "conversationClosed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userselectedthread",
          children: "userSelectedThread"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unreadconversationcountchanged",
          children: "unreadConversationCountChanged"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#contactassociated",
          children: "contactAssociated"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userinteractedwithwidget",
          children: "userInteractedWithWidget"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetloaded",
          children: "widgetLoaded"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#quickreplybuttonclick",
          children: "quickReplyButtonClick"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetclosed",
          children: "widgetClosed"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para registar e remover ouvintes de eventos, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "on"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "off"
      }), ", conforme mostrado abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const handleEvent = (eventPayload) => console.log(eventPayload);\n\nwindow.HubSpotConversations.on('conversationStarted', handleEvent);\n\n/* ... */\n\nwindow.HubSpotConversations.off('conversationStarted', handleEvent);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre cada evento abaixo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationStarted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationStarted"
      }), " é acionado quando uma nova conversa é iniciada com sucesso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationStarted', (payload) => {\n  console.log(\n    `Started conversation with id ${payload.conversation.conversationId}`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do thread da conversa que foi iniciada. Você pode usar esse ID ao fazer chamadas para a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "API de conversas"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationClosed"
      }), " é acionado quando uma nova conversa é ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/use-the-conversations-inbox#reply-and-comment:~:text=To%20mark%20the%20conversation%20as%20closed",
        children: "marcada como fechada"
      }), " na caixa de entrada de conversas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os visitantes do site que minimizam ou fecham o widget de chat não acionam este evento. Para esse evento, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#widgetclosed",
        children: "widgetClosed"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationClosed', (payload) => {\n  console.log(\n    `Conversation with id ${payload.conversation.conversationId} has been closed!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do thread da conversa que foi iniciada. Você pode usar esse ID ao fazer chamadas para a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "API de conversas"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userSelectedThread"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userSelectedThread"
      }), " é disparado ao criar um thread ou selecionar um thread existente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('userSelectedThread', (payload) => {\n  console.log(\n    `User selected thread with ID ${payload.conversation.conversationId}!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do thread da conversa que foi iniciada. Você pode usar esse ID ao fazer chamadas para a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "API de conversas"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "unreadConversationCountChanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unreadConversationCountChanged"
      }), " é acionado quando o número de conversas com mensagens não lidas aumenta ou diminui."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {\n  console.log(`New unread count is ${payload.unreadCount}!`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unreadCount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número total de conversas com pelo menos uma mensagem não lida."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "contactAssociated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactAssociated"
      }), " é acionado quando o visitante é associado a um contato no CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('contactAssociated', (payload) => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma mensagem de confirmação de que o visitante foi associado a um contato."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userInteractedWithWidget"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userInteractedWithWidget"
      }), " é acionado quando o visitante interage com o widget, como clicar para abrir o widget ou fechar a mensagem de boas-vindas inicial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘userInteractedWithWidget’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma mensagem de confirmação de que o visitante interagiu com o widget."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetLoaded"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetLoaded"
      }), " é acionado quando o iframe do widget é carregado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘widgetLoaded’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma mensagem de confirmação de que o iframe do widget foi carregado."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "quickReplyButtonClick"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "quickReplyButtonClick"
      }), " é acionado quando o visitante clica em uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/a-guide-to-bot-actions#ask-a-question",
        children: "resposta rápida"
      }), " de uma conversa de bot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma matriz com o texto da opção de resposta rápida que foi clicada."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('quickReplyButtonClick', (event) => {\n  console.log(`The text content of the clicked button is ${payload.value[0]}`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/quick-reply-options-in-bot-conversation.png",
        alt: "quick-reply-options-in-bot-conversation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na captura de tela do exemplo acima, o fluxo de chat do bot contém três opções de resposta rápida. Se o usuário selecionar ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Saiba mais"
      }), ", a carga útil resultante do evento será:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example event payload when a quick reply option is selected\n{\n  \"name\": \"QUICK_REPLIES\",\n  \"multiSelect\": false,\n  \"value\": [\"Learn more\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetClosed"
      }), " é acionado quando o visitante fecha o widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('widgetClosed', (event) => {\n  console.log(event);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma mensagem de confirmação de que o visitante fechou o widget de chat."
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}