"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76685480979;
const slug = exports.slug = 'guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app';
const title = exports.title = 'Migrar uma integração de chave de API para um aplicativo privado';
const name = exports.name = 'LATAM BR (PT) Migrate an API key integration to a private app';
const metaDescription = exports.metaDescription = 'Saiba como migrar uma integração de chave de API para um aplicativo privado.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "neste-guia",
  "label": "Neste guia",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-um-novo-aplicativo-privado",
  "label": "Criar um novo aplicativo privado",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-o-m%C3%A9todo-de-autoriza%C3%A7%C3%A3o-das-solicita%C3%A7%C3%B5es-de-api-da-sua-integra%C3%A7%C3%A3o",
  "label": "Atualizar o método de autorização das solicitações de API da sua integração",
  "parent": null
}, {
  "depth": 0,
  "id": "verificar-solicita%C3%A7%C3%B5es-e-monitorar-registros",
  "label": "Verificar solicitações e monitorar registros",
  "parent": null
}, {
  "depth": 0,
  "id": "exemplos-de-implementa%C3%A7%C3%A3o",
  "label": "Exemplos de implementação",
  "parent": null
}, {
  "depth": 1,
  "id": "fun%C3%A7%C3%B5es-sem-servidor",
  "label": "Funções sem servidor",
  "parent": "exemplos-de-implementa%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "trabalhos-%C3%BAnicos",
  "label": "Trabalhos únicos",
  "parent": "exemplos-de-implementa%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "criar-objetos-personalizados",
  "label": "Criar objetos personalizados",
  "parent": "exemplos-de-implementa%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "a%C3%A7%C3%B5es-de-fluxo-de-trabalho-de-c%C3%B3digo-personalizado",
  "label": "Ações de fluxo de trabalho de código personalizado",
  "parent": "exemplos-de-implementa%C3%A7%C3%A3o"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      em: "em",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an API key integration to a private app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Caso veja um banner na sua conta sobre como desativar a chave de API:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Verifique se você migrou todas as integrações afetadas e, em seguida, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/pt/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
              children: "desative a chave de API"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Para verificar se a chave de API da conta foi usada nos últimos sete dias, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/pt/integrations/how-do-i-get-my-hubspot-api-key#view-your-api-key-call-log",
              children: "visualizar seu histórico de registro de chamadas da chave de API"
            }), ". O registro de chamadas não mostrará solicitações feitas com a chave há mais de sete dias."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Os aplicativos listados na página ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "https://knowledge.hubspot.com/pt/integrations/connect-apps-to-hubspot#:~:text=You%20can%20view%20all%20your,Connected%20Apps%20page.",
              children: [(0, _jsxRuntime.jsx)(_components.em, {
                children: "Aplicativos conectados"
              }), " nas configurações da sua conta"]
            }), " não precisam de ser migrados, pois são autenticados com OAuth."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["As chaves de API de desenvolvedor são separadas das chaves de API padrão da HubSpot e ", (0, _jsxRuntime.jsx)("u", {
              children: "não"
            }), " serão descontinuadas. As chaves de API de desenvolvedor são utilizadas para gerenciar as configurações relacionadas aos seus aplicativos HubSpot, incluindo assinaturas da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/webhooks",
              children: "API de webhooks"
            }), " e tipos de eventos da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/crm/timeline",
              children: "API de eventos de linha do tempo"
            }), "."]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você criou uma integração interna que usa uma", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/integrations/how-do-i-get-my-hubspot-api-key",
        children: "chave de API da HubSpot,"
      }), " sua chave de API fornecerá acesso de leitura e gravação a todos os seus dados de CRM da HubSpot, o que poderá representar um risco de segurança se sua chave de API for comprometida. Ao migrar para um aplicativo privado, você pode autorizar os escopos específicos exigidos pela sua integração, o que gerará um token de acesso para limitar os dados que a integração pode solicitar ou alterar em sua conta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Siga as etapas abaixo para migrar uma integração de chave de API existente para um aplicativo privado. É recomendado usar um ambiente de testes primeiro, como uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/creating-test-accounts",
        children: "conta de teste de desenvolvedor"
      }), " ou uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/account/set-up-a-hubspot-sandbox-account",
        children: "conta de sandbox"
      }), ", antes de colocar as alterações em produção. Se você tiver dúvidas durante a migração do aplicativo, acesse a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "Comunidade de desenvolvedores"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para obter um vídeo passo a passo da migração de uma integração de chave de API para um aplicativo privado, confira o vídeo para Desenvolvedores da HubSpot abaixo:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/F3Qd9Hp4giM?start=29&feature=oembed",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Neste guia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-new-private-app",
          children: "Criar um novo aplicativo privado"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#update-the-authorization-method-of-your-integration-s-api-requests",
          children: "Atualizar o método de autorização da solicitação de API da sua integração"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#verify-requests-and-monitor-logs",
          children: "Verificar solicitações e monitorar registros"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#implementation-examples",
          children: "Exemplos de implementação"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os aplicativos privados não são compatíveis com webhooks e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/api/crm/extensions/overview",
          children: "alguns tipos de extensões"
        }), ". Se sua integração existente usar um desses recursos, você deverá criar um aplicativo público usando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/api/working-with-oauth",
          children: "OAuth"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um novo aplicativo privado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta da HubSpot, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ícone de configurações"
          }), " na barra de navegação principal."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No menu lateral esquerdo, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Integrações"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Aplicativos privados"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar aplicativo privado"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informações básicas"
          }), ", configure os detalhes do aplicativo:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "nome"
            }), " do aplicativo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Passe o cursor do mouse sobre o logotipo de espaço reservado e clique ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "no ícone de upload"
            }), " para carregar uma imagem quadrada que servirá como logotipo do seu aplicativo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Insira uma ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descrição"
            }), " para o aplicativo."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Escopos"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Em seguida, selecione os escopos para autorizar com base nas APIs usadas pela integração. Para descobrir quais escopos o aplicativo precisará:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Crie uma lista das APIs da HubSpot usadas pela sua integração existente."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para cada solicitação de API, acesse a documentação do desenvolvedor associada (por exemplo, a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/crm/contacts",
              children: "API de contatos"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Endpoints"
            }), " e role até o endpoint usado pela integração."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Requisitos"
            }), ", localize os escopos necessários para usar o endpoint. Sempre que possível, opte pelos escopos listados em ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Escopos granulares"
            }), " em vez de usar os ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Escopos padrão"
            }), ". Se nenhum escopo granular estiver listado, use os escopos padrão."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/locate-scope-in-endpoints-tab-for-private-app-migration.png",
            alt: "locate-scope-in-endpoints-tab-for-private-app-migration"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Nas configurações do aplicativo privado, marque as caixas de seleção ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Leitura"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Gravação"
            }), " ao lado dos escopos correspondentes. Você também pode pesquisar um escopo usando a barra de pesquisa ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Localizar um escopo"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-matching-scope-for-private-app.png",
            alt: "select-matching-scope-for-private-app"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Depois de selecionar seus escopos, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar aplicativo"
          }), " no canto superior direito. Você pode fazer alterações no seu aplicativo depois de criá-lo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na caixa de diálogo, revise as informações sobre o token de acesso do aplicativo e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Continuar criando"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com seu aplicativo privado criado, você pode começar a fazer solicitações de API usando seu token de acesso. Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Detalhes"
      }), " da página de configurações do seu aplicativo privado, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Mostrar token"
      }), " abaixo do token de acesso para revelá-lo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-migration-guide.png",
        alt: "show-private-app-access-token-migration-guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar o método de autorização das solicitações de API da sua integração"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em vez de usar um parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapiKey"
      }), " para fazer solicitações de API, os tokens de acesso para aplicativos privados são incluídos no cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " da solicitação. Ao fazer uma solicitação, defina o valor do cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Bearer YOUR_ACCESS_TOKEN"
      }), ". Salvo indicação em contrário, este método de autorização é compatível com todos os endpoints da API pública, incluindo as APIs antigas listadas na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "antiga documentação do desenvolvedor"
      }), " da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O seu pedido pode assemelhar-se ao seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$headers = [\n    'Content-Type: application/json',\n    'Authorization: Bearer ' . YOUR_ACCESS_TOKEN,\n];\n\n$curl = curl_init();\n\ncurl_setopt($curl, CURLOPT_HTTPHEADER, $headers);\ncurl_setopt($curl, CURLOPT_URL, 'https://api.hubapi.com/crm/v3/objects/contacts');\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n$contacts = curl_exec($curl);\ncurl_close($curl);\n\nvar_dump($contacts);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "require 'uri'\nrequire 'net/http'\nrequire 'openssl'\n\nurl = URI(\"https://api.hubapi.com/crm/v3/objects/contacts\")\n\nhttp = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nhttp.verify_mode = OpenSSL::SSL::VERIFY_NONE\n\nrequest = Net::HTTP::Get.new(url)\nrequest['content-type'] = 'application/json'\ntoken = 'YOUR_ACCESS_TOKEN'\nrequest['authorization'] = \"Bearer #{token}\"\n\nresponse = http.request(request)\nputs response.read_body\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "import requests\n\nurl = \"https://api.hubapi.com/crm/v3/objects/contacts\"\n\nheaders = {\n  'content-type': 'application/json',\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\nresponse = requests.request(\"GET\", url, headers=headers)\n\nprint(response.text)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os tokens de acesso para aplicativos privados são implementados na OAuth, para que você também possa fazer chamadas autenticadas usando uma das bibliotecas de cliente do HubSpot. Por exemplo, se você estiver usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "biblioteca de cliente Node.js"
      }), ", poderá iniciar um cliente OAuth enviando o token de acesso do seu aplicativo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$hubSpot = \\HubSpot\\Factory::createWithAccessToken('access-token');\n$response = $hubSpot->crm()->contacts()->basicApi()->getPage();\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# Load the gem\nrequire 'hubspot-api-client'\n\n# Setup client\nclient = Hubspot::Client.new(access_token: 'YOUR_ACCESS_TOKEN')\n\n# Get contacts\ncontacts = client.crm.contacts.basic_api.get_page\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "from hubspot import HubSpot\n\napi_client = HubSpot(access_token='YOUR_ACCESS_TOKEN')\napi_client.crm.contacts.get_page()\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para concluir a migração para o seu aplicativo privado, remova todas as referências à chave de API da HubSpot do seu código e, em vez disso, use a abordagem acima para usar o token de acesso do seu aplicativo privado. Dependendo da solicitação que está fazendo, você pode querer criar um segredo para armazenar seu token, em vez de codificá-lo em suas solicitações. O uso de um segredo impedirá que o token seja exposto, como ao usar um token em uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "função sem servidor"
      }), ". Para armazenar o token de acesso como um segredo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No terminal, execute ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add secretName"
        }), ". É recomendado dar um nome simples ao segredo para que você possa referenciá-lo com facilidade no futuro."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cole o token de acesso no terminal e pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode acessar seu segredo como uma variável de ambiente. Saiba mais no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "exemplo de funções sem servidor abaixo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para confirmar que todas as referências à sua chave de API foram removidas, você pode verificar o registro de chamadas na sua conta da HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícone de configurações"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrações > Chave de API"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revise as solicitações mais recentes na guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Registro de chamadas"
        }), " para confirmar que nenhuma solicitação recente foi feita desde a remoção de todas as referências anteriores para usar o token de acesso do seu aplicativo privado."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/check-api-key-call-log-after-migration.png",
        alt: "check-api-key-call-log-after-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você tiver uma conta paga do ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), " com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/contacts/marketing-contacts",
        children: "contatos de marketing"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/integrations/manage-marketing-contacts-settings-for-your-integrations#set-contacts-created-by-api-key-apps-as-marketing-contacts",
        children: "definir contatos criados por integrações usando sua chave de API como contatos de marketing"
      }), ", também deverá fazer o mesmo com seu aplicativo privado:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícone de configurações"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrações > Contatos de marketing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Em ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Seus aplicativos conectados"
        }), ", utilize a barra de pesquisa para localizar o aplicativo privado e, em seguida, clique para ativar a opção ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ative para sincronizar como contatos de marketing"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/set-private-app-contacts-as-marketing-contacts.png",
        alt: "set-private-app-contacts-as-marketing-contacts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando terminar de configurar seu aplicativo privado e confirmar que todas as referências à sua chave de API foram removidas do código, você poderá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
        children: "desativá-la"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verificar solicitações e monitorar registros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de remover todas as referências à chave de API da HubSpot em seu código e substituí-las por referências ao token de acesso do seu aplicativo privado, nenhuma outra alteração de código será necessária."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você seguiu as etapas acima em uma conta de teste ou sandbox de desenvolvedor, repita o mesmo processo na sua conta de produção. Em seguida, monitore os registros das chamadas de API do aplicativo privado e confirme se nenhuma das solicitações do aplicativo retorna erros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícone de configurações"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu lateral esquerdo, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrações"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos privados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do aplicativo privado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Registros"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Qualquer solicitação de API com falha devido a um escopo ausente aparecerá como um erro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), ". Se você acessar os registros de tempo de execução da sua integração, a resposta da solicitação de API correspondente deverá incluir uma mensagem de erro com detalhes sobre quaisquer escopos ausentes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/403-error-after-private-app-migration.png",
        alt: "403-error-after-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você precisar incluir um novo escopo para seu aplicativo privado:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Detalhes"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Editar detalhes"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["No canto superior da página, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Escopos"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Marque a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "caixa de seleção"
            }), " ao lado dos escopos ausentes e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Confirmar alterações"
            }), " no canto superior direito quando terminar."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-missing-scopes-private-app-migration.png",
        alt: "select-missing-scopes-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como criar e gerenciar aplicativos privados, bem como seus limites, no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/private-apps",
        children: "guia de aplicativos privados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemplos de implementação"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre os usos comuns das chaves de API e como migrar para tokens de acesso para aplicativos privados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver usando uma chave de API dentro de uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/data/serverless-functions",
        children: "função sem servidor"
      }), ", poderá usar o token de acesso para aplicativo privado na autenticação. Será necessário garantir que o aplicativo privado tenha os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/working-with-oauth#scopes",
        children: "escopos"
      }), " que a função precisa executar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para autenticar uma função sem servidor com um token de acesso para aplicativo privado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No cartão ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Token de"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "acesso"
          }), ", clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mostrar token"
          }), " para revelar seu token de acesso. Em seguida, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar"
          }), " para copiar o token para a área de transferência."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
            alt: "show-private-app-access-token-1"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Com o token de acesso copiado, crie um novo segredo para armazenar o token:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["No terminal, execute ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs secrets add secretName"
            }), ". É recomendado dar um nome simples ao segredo para que você possa referenciá-lo com facilidade no futuro."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Cole o token de acesso no terminal e pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enter"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "serverless.json"
          }), " da sua função sem servidor, adicione o nome secreto à matriz ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "secrets"
          }), ":"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example serverless.json file\n\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"getPrompts\": {\n      \"method\": \"GET\",\n      \"file\": \"serverlessFunction.js\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No arquivo JavaScript da sua função sem servidor, defina o valor do cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Authorization"
        }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bearer secretName"
        }), ". Por exemplo, se você estiver fazendo uma chamada para a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "API de contatos"
        }), " usando Node.js e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", a solicitação terá a seguinte aparência:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example serverless function\nconst axios = require('axios');\n\nexports.main = (context, sendResponse) => {\n  axios.get(`https://api.hubapi.com/crm/v3/objects/contacts`, {\n    headers: {\n      Authorization: `Bearer ${process.env.secretName}`,\n      'Content-Type': 'application/json',\n    },\n  });\n  sendResponse({ statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/private-apps#make-api-calls-with-your-app-s-access-token",
        children: "fazer chamadas de API com o token do seu aplicativo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Trabalhos únicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver usando uma chave de API para executar trabalhos únicos, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/crm/properties",
        children: "criar uma propriedade"
      }), ", poderá criar um aplicativo privado e usar seu token de acesso para autenticar a chamada. Assim que um aplicativo privado for criado, você poderá reutilizar seu token de acesso para qualquer trabalho único, desde que o aplicativo privado tenha os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "escopos"
      }), " adequados. Você pode atualizar os escopos de um aplicativo privado a qualquer momento nas configurações do aplicativo privado no HubSpot. Ou, você pode excluir o aplicativo privado e criar um novo específico para o trabalho que precisa executar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/private-app-edit-scopes.png",
        alt: "private-app-edit-scopes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar objetos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em vez de usar uma chave de API para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/crm/custom-objects",
        children: "criar um objeto personalizado"
      }), ", você pode criar um aplicativo privado e usar seu token de acesso para autenticar a chamada, desde que o aplicativo tenha os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/working-with-oauth#scopes",
        children: "escopos"
      }), " necessários. Por exemplo, ao usar Postman para criar um objeto personalizado, defina o tipo de autorização como ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Token do portador"
      }), " e insira o token no campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Token"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-private-app-access-token-field.png",
        alt: "postman-private-app-access-token-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como criar um objeto personalizado usando um aplicativo privado no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/blog/how-to-build-a-custom-object-using-private-apps",
        children: "blog do desenvolvedor da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ações de fluxo de trabalho de código personalizado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver usando uma chave de API em uma ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/workflows/custom-code-actions#create-a-custom-code-action",
        children: ["ação de fluxo de trabalho de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "código personalizado"
        })]
      }), ", poderá usar o token de acesso do aplicativo privado, desde que o aplicativo tenha os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/working-with-oauth#scopes",
        children: "escopos"
      }), " necessários. Para fazer a atualização, abra a ação personalizada no editor de fluxo de trabalho e faça as seguintes atualizações:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Primeiro, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/pt/docs/api/conversations/code-snippets-in-bots",
            children: "adicione um novo segredo"
          }), " que contenha o token de acesso para aplicativo privado."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/workflow-action-add-secret.png",
            alt: "workflow-action-add-secret"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Em seguida, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/pt/docs/api/workflows/custom-code-actions#secret",
            children: "atualize o código de ação"
          }), " com o novo segredo."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({\n  accessToken: process.env.secretName,\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}