"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611090;
const slug = exports.slug = 'guides/cms/overview';
const title = exports.title = 'HubSpotのCMSの概要';
const name = exports.name = 'HubSpotのCMSの概要';
const metaDescription = exports.metaDescription = '開発者向けにHubSpot Content Hubの概要を紹介します。テーマ、テンプレート、モジュールなどの主な概念と、それらを組み合わせて使用する方法についてご覧ください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E5%88%B6%E4%BD%9C%E3%81%AE%E6%94%AF%E6%8F%B4",
  "label": "コンテンツ制作の支援",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "コンテンツのタイプ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A8%E3%83%A9%E3%83%B3%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "ウェブサイトページとランディングページ",
  "parent": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0",
  "label": "ブログ",
  "parent": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB",
  "label": "Eメール",
  "parent": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 0,
  "id": "%E6%A7%8B%E9%80%A0%E5%8C%96%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "構造化コンテンツ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%83%99%E3%83%AD%E3%83%83%E3%83%91%E3%83%BC-%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB-%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0",
  "label": "デベロッパー ファイル システム",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%80%81%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%80%81%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%80%81%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "テーマ、テンプレート、モジュール、フィールド",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "テーマ",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%80%81%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%80%81%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%80%81%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "テンプレート",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%80%81%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%80%81%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%80%81%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89"
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "モジュール",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%80%81%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%80%81%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%80%81%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89"
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "フィールド",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%80%81%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%80%81%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%80%81%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89"
}, {
  "depth": 0,
  "id": "hubl%E8%A8%80%E8%AA%9E",
  "label": "HubL言語",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      li: "li",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotのCMSの概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このセクションは、HubSpotの", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS"
        })
      }), "に伴う主な側面を理解していただき、優れたウェブサイトを構築できるよう支援することを目的としています。CMS Hubを最大限に活用するには、HTML、JavaScript、CSSなどのウェブ開発の基本に関する専門知識が求められます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["初めてCMSで構築しようとしている場合は、まず", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "クイックスタートのチュートリアル"
      }), "を確認した後、必要に応じてこの概要を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツ制作の支援"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSは、企業のウェブプレゼンスの向上を支援する目的で設計されており、特にコンテンツを制作／管理するマーケティング担当者向けに機能を提供します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/crm",
        children: "HubSpotのCRM"
      }), "には、ウェブサイトのコンテンツ、リード（見込み客）の情報収集機能、アナリティクスが統合されており、サイトの訪問者に応じたパーソナライズされた体験を簡単に創出してビジネス全体に活用できるように構築されています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMS開発者はテーマ、テンプレート、モジュールなどのアセットを構築しますが、その際、コンテンツ制作担当者が必要に応じてコンテンツをカスタマイズできるような方法でそれらを構築します。したがって、開発者は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "システムや、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "を使用して、自身のニーズと制作担当者のニーズとのバランスを取る", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "必要があります"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/code-no-code-graphic.png",
        alt: "開発者とマーケティング担当者の間を結ぶテンプレートとモジュール"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["優れたウェブサイトを開発するには、コンテンツ制作担当者のニーズを理解しながら制作者との間で緊密なコラボレーションを行う必要があります。こうすれば、その後、開発者の継続的なサポートとメンテナンスの必要性が軽減されます。そのためには、構築中に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow#testing",
        children: "コンテンツ制作担当者の立場に立ってページ作成エクスペリエンスの外観や操作性をプレビューする"
      }), "ことをお勧めします。こうした取り組みが、可能な限りコンテンツ作成者が自力で作業できるサイトの実現につながります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/making%20a%20page.gif",
        alt: "ユーザーがドラッグ＆ドロップページを作成する様子を示すアニメーション"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページのホスティングとメンテナンスはHubSpotによって行われます。つまり、開発者がプラグインの管理、更新、ホスティング、スケーリング、セキュリティーについて心配する必要はありません。ただし、開発者が自身でCMSをホスティングする場合に比べて、制約が少し多くなります。例えば、システムの基本要素を手作業で、またはプラグインを使用して変更したり拡張したりすることはできません。また、レンダリングの詳細を操作すること、データベースのコンテンツに直接アクセスして変更することもできません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者が構築するコンテンツ（", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "テンプレート"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "、JavaScript、CSSなど）は、デベロッパー ファイル システム内に作成されます。一方、ページコンテンツ（ページ、ブログ記事）は強力なブロックベースのWYSIWYGエディターで設計および作成され、メディアファイル（制作担当者が作成した画像、PDFなど）はウェブアプリベースのファイルマネージャーに保存されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページのレンダリング時に、HubSpotはドメインに応じて多数のHubSpotサーバーのうちの1つにリクエストをルーティングし、そのサーバー上でページをレンダリングして、可能な場合はそのページをコンテンツ配信ネットワーク（CDN）にキャッシュします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツのタイプ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSを使用して作成されるコンテンツには多くの種類があります。コンテンツのタイプによっては、制作担当者に表示されるユーザーインターフェイスにわずかな違いがあります。開発者はこれらの違いを認識しておかなければなりません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ウェブサイトページとランディングページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイトページとランディングページは互いに独立して作成されますが、全てのページはテンプレートに基づきます。制作担当者にとって、ランディングページを構築するプロセスとウェブサイトページを構築するプロセスはほぼ同じです。ただし、ウェブサイトページは組織のウェブサイトの一部として情報を提供し、オーガニック検索で見つけやすく設計される一方、ランディングページは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/marketing/landing-page-best-practices",
        children: "一般に特定のマーケティングオファーまたはキャンペーンに関連付けられる"
      }), "（例：特定のコンタクトリストに送信されるマーケティングEメールからリンクされる）点が異なります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "マーケティング担当者向けのUIとしては、これらのタイプのページのアナリティクスと編成も独立して体系化されています。これは、ランディングページには多くの場合、特定のコンバージョン目標があるためです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブログ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのブログには2つのビューがあります。1つはリストページのビュー、もう1つは個々のブログ記事のビューです。各ブログ記事はそれぞれのビューに取り込まれます。ブログ記事とリスティングページで同じテンプレートを共有するようにブログを設定することも、リスティングページとブログ記事で別々のテンプレートを使用するように設定することもできます。ただし、ブログ記事の間では同じテンプレートを共有する必要があります。詳細は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "ブログ テンプレート マークアップ"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/manage-your-blog-template-and-settings",
        children: "HubSpotでブログを作成して管理する方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotでは、いくつかの方法でEメールを作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**従来型Eメール：**ウェブサイトやランディングページと同じようにEメールテンプレートとモジュールを作成します。マークアップを完全に制御する", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/email-template-markup",
          children: "Eメールテンプレート"
        }), "を作成することもできます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**ドラッグ＆ドロップEメール：**カスタマイズ可能な", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
          children: "ドラッグ＆ドロップ"
        }), "Eメールテンプレートを作成します。これにより、コンテンツ制作担当者はHubSpotのドラッグ＆ドロップインターフェイスを使用してEメールのレイアウトとコンテンツを作成できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "構造化コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリ内エディターを使用して、またはテンプレートにハードコーディングしてページのコンテンツを作成できるだけでなく、構造化データソースを使用して、HubLで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "動的ページのコンテンツ"
      }), "を取り込むこともできます。ページにコンテンツを取り込むには、次のデータソースを使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
          children: "HubDB"
        }), "：HubDBテーブルのセルにデータを保存します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
          children: "CRMレコード"
        }), "：コンタクト、会社、カスタムオブジェクトなどのデータをCRMレコードに保存します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "構造化コンテンツを使用して動的ページを作成するということは、データソースを更新することによって直接ウェブサイトページとページのコンテンツを作成、編集、削除できることを意味します。HubSpotブログと同様に、一連の動的ページには、データソースのインスタンスを表示する単一のリスティングページと、個々のインスタンスごとの別個のページを含めます。HubLを使用することで、ページに表示されるデータを完全に構成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、営業チームのそれぞれのメンバーに対応する各行に情報を格納するHubDBテーブルを作成できます。HubSpotはそのHubDBテーブルを使用して、各テーブル行に格納された主な詳細（各営業担当者の名前や画像など）を表示するリスティングページと、営業担当者ごとの詳細（プロフィール、電話番号など）を表示する個別のページを生成します。営業担当者が後で別のチームに異動した場合は、HubDBテーブルからその営業担当者の行を削除すれば、HubSpotが自動的にその営業担当者の詳細ページを削除し、リスティングページからもその営業担当者を除去します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デベロッパー ファイル システム"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コアアセット（テンプレート、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "、およびこれらをサポートするJavaScript、CSSファイル、画像）は、デベロッパー ファイル システム内に作成されます。このファイルシステムは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "デザインマネージャー"
      }), "の左側のパネル、またはローカル開発ツールを使用してローカルで同期されたフォルダー内で確認できます。ファイルシステム内では、アセットを相互に絶対パスまたは相対パスで参照可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["内部では、これらのファイルはデータベース内のエントリーにマッピングされます。このことから、デベロッパー ファイル システムにアクセスするには、SSHやFTPではなくHubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), "ツールを使用します。特定のファイルシステムに見られるような機能（アクセス権限、シンボリックリンクなど）は、デベロッパー ファイル システムには備わっていません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは従来のCMSのアプローチとは異なりますが、ファイルのリンク切れや構文エラーが本番ではなく公開する時点で検出されるため、実際のトラフィックがウェブサイトに到達した際の予想外のエラーを防ぐための対策にもなります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンテンツ制作担当者が新しいページを作成するとき、ファイルシステム内のテンプレートが自動的に検出されて制作担当者に提示されます。したがって、ファイルシステムの構造は開発者の裁量に任されます。モジュールを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/modules/"
      }), "フォルダー内に保管したり、JavaScriptを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/js/"
      }), "フォルダー内に保管したりする必要はありません。ただし、CMSの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "ボイラープレートのサンプルコード"
      }), "と同じようにアセットを整理することをお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**既定では、HubSpotはデザインマネージャーに含まれるJavaScriptとCSSのミニフィケーション（軽量化）を自動的に行って、不要なスペース、改行、コメントを削除します。この既定の動作は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLIを使用してデザインマネージャーにアップロード"
        }), "されたJavaScriptとCSSにも適用されます。そのため、あらかじめ軽量化されたコードをデザインマネージャーに直接追加することは避けてください。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-and-css-minification",
          children: "JavaScriptとCSSのミニフィケーション"
        }), "の詳細をご確認ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマ、テンプレート、モジュール、フィールド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "テンプレート"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "フィールド"
      }), "は、開発者が特に頻繁に扱うオブジェクトです。このさまざまなオブジェクトを効果的に使用することで、開発者が設定したスタイルとレイアウトのガイドラインに従って、制作担当者が自力で自由にウェブサイトを編集し、改良を重ねられるようになります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマとモジュールに含まれるフィールドには、特定のデータタイプ（数値、文字列、リッチテキスト、画像）が設定されます。オブジェクトをレンダリングする際にこれらのフィールドを使用する方法と、", (0, _jsxRuntime.jsx)("abbr", {
        title: "What You See Is What You Get",
        children: "WYSIWYG"
      }), "エディター内でのフィールドの配置と表示は、開発者が制御できます。制作担当者がWYSIWYGエディターでフィールドの値を設定すると、レンダリング時にそれらの値がテーマまたはモジュールに適用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーマ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "は、ウェブサイトの外観と操作感を定義して、快適なコンテンツ編集環境を創出するための最上位のオブジェクトです。テーマには、テンプレート、モジュール、CSSファイル、JavaScriptファイル、画像などを組み込むことができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマを使用することで、一連のフィールドを作成して、制作担当者がCSSを編集しなくてもウェブサイト全体のスタイルを制御できるようにすることができます。CSSファイル内で、制御の適用対象を指定し、他の制御を継承するように設定したり、マーケティング担当者に対してテーマエディターで表示、配置する方法を管理したりできます。制作担当者は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-fields",
        children: "テーマエディター"
      }), "を使用して、テーマフィールドの値を変更し、テーマに含まれる既存のテンプレートに対する変更をプレビューしてから、その変更を公開します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのテーマフィールドは、サイト全体でグローバルに設定することも、ページレベルでオーバーライドすることもできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Theme%20Editor.png",
        alt: "テーマエディター"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テンプレート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "テンプレート"
      }), "は、ページで使用する、基礎となるマークアップとスタイルを定義します。テンプレートを使用するページには、そのテンプレートで定義されたマークアップとスタイルが適用されます。テンプレートにはHubL、HTML、JavaScriptファイルとCSSファイルへのリンクを含めることができます。また、テンプレートが特定のタイプのコンテンツで使用されるように制限することもできます。テンプレートに含めるマークアップとスタイルは完全に開発者の自由裁量に委ねられますが、マーケティング担当者が簡単にページを編集できるよう、いくつかのベストプラクティスに従って", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), "の主要な機能を使用することをお勧めします。以下に、いくつかのベストプラクティスをご紹介します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマの一部として", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview",
          children: "テンプレート"
        }), "を作成し、テーマレベルのCSS（テーマフィールドを含む）を使用して、スタイル設定の大部分をテンプレート内で行えるようにします。こうすると、制作担当者がCSSを編集しなくても、グローバルおよびローカルのスタイルの一貫性を確保しながら変更できるようになります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ページ上のコンポーネントの大半に", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "モジュール"
        }), "を使用して、ウェブサイト全体でモジュールを再編成および再利用できるようにします。この後、モジュールの詳細について説明します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サイト内のページをはじめ、主要なページコンテンツにはできるだけ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "ドラッグ＆ドロップエリア"
        }), "を使用するようにします。ドラッグ＆ドロップエリアを使用すると、ページを構成するモジュールの既定のレイアウトを設定する一方で、マーケティング担当者が柔軟に独自のレイアウトとスタイルを編集できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ウェブサイト全体に一貫して表示するヘッダーやフッターなどの共有コンテンツを含めるには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
          children: "グローバルパーシャル"
        }), "を使用します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートを作成するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML+HubL"
      }), "を使用することも、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "デザインマネージャー"
      }), "内の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "ドラッグ＆ドロップ操作"
      }), "のインターフェイスを使用することもできます。ただし、コード化されたテンプレートのほうが、ドラッグ＆ドロップ操作のインターフェイスよりも細かく制御でき、機能性にも優れているためにお勧めです。例えば、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "をサポートなどがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Homepage%20Template%20html.png",
        alt: "HTML＋HubLテンプレートが開いているVS Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "は、テンプレートやページに配置できる再利用可能なコンポーネントです。モジュールにはマーケティング担当者用のコントロールを組み込み、ウェブサイトの再利用可能かつ編集可能なコンポーネントを作成するために連動するHubL/HTMLマークアップ、CSS、JavaScriptを追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールのコントロールはフィールドで定義されます。したがって、優れたモジュールを作成するには、ページ上での表示とコンテンツ編集者の作業性の両方を考慮する必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotに用意されている一連の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "既定のモジュール"
      }), "（ヘッダー、リッチテキスト、画像、ボタン、CTAなど）を基本的なコンポーネントとして使用できます。要素をゼロから作成して、テーマとテンプレートに合った、より特徴的なレイアウトを設定することもできます。例えば、アコーディオン、スライダー、タブなどのモジュールを作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Module%20form%20in%20editor.png",
        alt: "エディターでのモジュールフォーム"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールはオブジェクトであり、ページ上のモジュールはそのオブジェクトのインスタンスであると見なせます。つまり、モジュールに含まれる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), "、CSS、またはJavaScriptに加えた変更は、所定のポータル内のページまたはテンプレートに含まれる、そのモジュールの全てのインスタンスに適用されます。モジュールはポータル間で移植することもできます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "デザインマネージャー"
      }), "に組み込まれているコピーツールを使用する、マーケットプレイスで販売する、ローカル開発ツールで直接コードを共有するといった場合も、移植可能です。つまり、デザインソリューションを一度実装すれば、複数のページやポータルでそのソリューションを使用できます。さらに、ソリューションの更新が必要になったら、複数のページや複数のテンプレートを編集しなくても、ソリューションの変更を全てのページに適用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマにもモジュールを組み込むことができます。この場合、テーマフィールドを使用してモジュールの外観を操作し、制作担当者が優れた外観のモジュールに簡単にアクセスできるよう、ページエディター内で目立たせることもできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュールの概要ガイド"
      }), "で詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "フィールド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "フィールド"
      }), "は、制作担当者がテーマやモジュールに渡されるパラメーターを調整するために使用するコントロールです。フィールドには型があります。型にはブール、テキスト、URL、選択肢、ファイルなどのシンプルなものもあれば、スタイル設定されたフォントといった複雑なフィールドもあります。また、コンテンツの別の部分へのリンクやHubSpotシステム内のフォームへのリンクなど、HubSpot固有のフィールドも使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールに配列を渡すリピーター内にフィールドを配置することもできます。例えば、画像カルーセルに`alt`テキストが関連付けられた一連の画像を渡す必要があるとします。この場合、多数の画像フィールドとテキストフィールドを作成する代わりに、画像フィールドとテキストフィールドをそれぞれ1つ作成して、繰り返しグループ内に配置できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールのフィールドは、デザインマネージャー内で指定するか、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#the-user-interface-for-editing",
        children: "fields.jsonファイル内でこの構文を使用"
      }), "して指定します。テーマのフィールドは、テーマのルートにある", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイル内で指定する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubL言語"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSでウェブサイトアセットの構築に使用する主な言語は、HubSpotマークアップ言語つまり", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), "（「ハッブル」と発音）です。HubLは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), "という", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://palletsprojects.com/p/jinja/",
        children: "Jinja"
      }), "ベースのテンプレートエンジンをHubSpotが拡張した言語です。HubLは数多くのHubSpot固有のマークアップを採用しています。また、Jinjaの機能のうちサポートされない機能もあります。ページのレンダリング時に、HubLは一貫してサーバーサイドで実行されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLでは、シンプルなテンプレート作成言語で使用する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "変数"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "forループ"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if文"
      }), "などの機能を使用できるだけでなく、複雑な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "マクロ"
      }), "のレンダリング、データフェッチ、および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "タグ"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "関数"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "フィルター"
      }), "のマッピングもサポートしています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLで可能なことの限界に達したときは、HubSpotに備わっているAPIでよりカスタマイズされたソリューションを作ることもできます。", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " アカウントでは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "サーバーレス関数"
      }), "を使用して、高度なサーバー サイド プログラミングを行うことができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定の言語機能の詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL言語リファレンス"
      }), "をご覧ください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}