"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044568;
const slug = exports.slug = 'guides/cms/content/themes/default-themes';
const title = exports.title = 'Standarddesigns';
const name = exports.name = 'EMEA DACH Default Themes_DE';
const metaDescription = exports.metaDescription = 'HubSpot bietet eine Vielzahl von Designs, die Sie als Referenz für Ihr eigenes Website-Design verwenden, ändern oder verwenden können. Sie können diese Designs auch über unsere CLI-Tools in Ihre lokale Umgebung herunterladen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C3%A4ndern-der-standarddesigns",
  "label": "Ändern der Standarddesigns",
  "parent": null
}, {
  "depth": 0,
  "id": "standarddesign-listing",
  "label": "Standarddesign-Listing",
  "parent": null
}, {
  "depth": 1,
  "id": "growth-design",
  "label": "Growth-Design",
  "parent": "standarddesign-listing"
}, {
  "depth": 0,
  "id": "standarddesigns-im-wartungsmodus",
  "label": "Standarddesigns im Wartungsmodus",
  "parent": null
}, {
  "depth": 1,
  "id": "barricade-design",
  "label": "Barricade-Design",
  "parent": "standarddesigns-im-wartungsmodus"
}, {
  "depth": 1,
  "id": "education-design",
  "label": "Education-Design",
  "parent": "standarddesigns-im-wartungsmodus"
}, {
  "depth": 1,
  "id": "martech-design",
  "label": "Martech-Design",
  "parent": "standarddesigns-im-wartungsmodus"
}, {
  "depth": 1,
  "id": "rally-design",
  "label": "Rally-Design",
  "parent": "standarddesigns-im-wartungsmodus"
}, {
  "depth": 1,
  "id": "session-design",
  "label": "Session-Design",
  "parent": "standarddesigns-im-wartungsmodus"
}, {
  "depth": 1,
  "id": "sprout-design",
  "label": "Sprout-Design",
  "parent": "standarddesigns-im-wartungsmodus"
}, {
  "depth": 1,
  "id": "vitality-design",
  "label": "Vitality-Design",
  "parent": "standarddesigns-im-wartungsmodus"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      em: "em",
      h2: "h2",
      h3: "h3",
      img: "img",
      h4: "h4",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Standarddesigns"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot bietet eine Reihe von Standarddesigns, mit denen Content-Autoren Webseiten erstellen können, ohne das Basisdesign ändern zu müssen. Diese Designs eignen sich am besten für Content-Autoren mit eingeschränktem Zugriff auf Entwicklerressourcen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Ihr eigenes Design entwickeln möchten, empfiehlt es sich, mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "CMS Hub Boilerplate"
      }), " zu beginnen. Sie können jedoch Standarddesigns über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot-CLI"
      }), " herunterladen und ändern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend erfahren Sie mehr über die derzeit verfügbaren Standarddesigns."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Starter"
        }), "-Accounts haben nur Zugriff auf das Growth-Design."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ändern der Standarddesigns"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Standarddesigns von HubSpot befinden sich in einem Ordner mit der Bezeichnung @hubspot im Entwickler-Dateisystem. Dieser Ordner ist schreibgeschützt und der Inhalt kann nur von HubSpot geändert werden. Wenn Sie ein Design ändern möchten, müssen Sie das gewünschte Design ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "abrufen"
      }), " und es dann mit Ihren Änderungen in einen anderen Ordner als @hubspot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "hochladen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standarddesign-Listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können mit den aufgeführten CLI-Befehlen unsere Standarddesigns herunterladen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "Erfahren Sie mehr über den fetch-Befehl"
      }), " im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Growth-Design"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/5Cdocs/default-themes/growth-homepage.jpg",
            alt: "Startseite des Growth-Designs"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/growth/home",
              children: "View Growth theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Any"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "12 Sections"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "16 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/growth <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standarddesigns im Wartungsmodus"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Designs befinden sich momentan im Wartungsmodus. Sie werden nicht mehr mit neuen Funktionen aktualisiert und erhalten nur Updates in Bezug auf Sicherheit, Barrierefreiheit und Fehlerbehebungen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Barricade-Design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Design, das sich an Bauunternehmen richtet."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-barricade-theme-thumb-4.jpg",
            alt: "Screenshot des Barricade-Designs"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/barricade/home",
              children: "View Barricade theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Construction"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "10 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/barricade <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Education-Design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Design, das für Schulen, Hochschulen oder andere Bildungseinrichtungen entwickelt wurde."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-education-theme-thumb-4.jpg",
            alt: "Screenshot des Education-Designs"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/education/home",
              children: "View Education theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Education"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "13 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/education <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Martech-Design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Website-Design, das sich an Marketing-Technologie-Unternehmen richtet."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-martech-theme-thumb-4.jpg",
            alt: "Screenshot des Martech-Designs"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/martech/home",
              children: "View Martech theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/martech <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rally-Design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Design für Unternehmen und Agenturen."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-rally-theme-thumbnail-1-4.png",
            alt: "Screenshot des Rally-Designs"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/rally/home",
              children: "View Rally theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "14 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/cms-rally <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Session-Design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Design, das für Unternehmen entwickelt wurde."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-session-theme-thumb-4.jpg",
            alt: "Screenshot des Session-Design"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/session/home",
              children: "View Session theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/session <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sprout-Design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Design für Unternehmen und Agenturen."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-sprout-theme-thumb-4.jpg",
            alt: "Screenshot des Sprout-Designs"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/sprout/home",
              children: "View Sprout theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "15 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "21 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/sprout <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vitality-Design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Design für Unternehmen und Agenturen."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-vitality-theme-thumb-4.jpg",
            alt: "Screenshot des Vitality-Designa"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/vitality/home",
              children: "View Vitality theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Healthcare, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "8 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/vitality <destination-folder>\n"
          })
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}