"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358694;
const slug = exports.slug = 'guides/apps/marketplace/listing-your-app';
const title = exports.title = 'App Marketplace | Listing your app';
const name = exports.name = 'vNext Docs DP - Listing your App';
const metaDescription = exports.metaDescription = "Follow these steps to submit an app for listing on HubSpot's App Marketplace.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-and-submit-an-app-listing",
  "label": "Create and submit an app listing",
  "parent": null
}, {
  "depth": 0,
  "id": "listing-info",
  "label": "Listing info",
  "parent": null
}, {
  "depth": 0,
  "id": "app-details",
  "label": "App details",
  "parent": null
}, {
  "depth": 0,
  "id": "pricing",
  "label": "Pricing",
  "parent": null
}, {
  "depth": 0,
  "id": "app-features",
  "label": "App features",
  "parent": null
}, {
  "depth": 0,
  "id": "support-info",
  "label": "Support info",
  "parent": null
}, {
  "depth": 0,
  "id": "review-info-and-submit-your-listing",
  "label": "Review info and submit your listing",
  "parent": null
}, {
  "depth": 0,
  "id": "create-and-update-a-localized-listing-for-an-existing-app-listing",
  "label": "Create and update a localized listing for an existing app listing",
  "parent": null
}, {
  "depth": 0,
  "id": "edit-a-live-app-listing",
  "label": "Edit a live app listing",
  "parent": null
}, {
  "depth": 0,
  "id": "unpublish-a-live-app-listing",
  "label": "Unpublish a live app listing",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Listing your app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you’ve created an app in your developer account that meets the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App Marketplace listing requirements"
      }), ", you can submit a listing to add it to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "HubSpot App Marketplace"
      }), ". The HubSpot Ecosystem Quality team will review your submission and follow up via email if the app has been approved or rejected."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you must be a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "super admin"
        }), " to update and submit an app listing."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this article:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Create and submit an app listing"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-update-a-localized-listing-for-an-existing-app-listing",
          children: "Create and update a localized listing for an existing app listing"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#edit-a-live-app-listing",
          children: "Edit a live app listing"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unpublish-a-live-app-listing",
          children: "Unpublish a live app listing"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create and submit an app listing"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " before submitting an app listing, review the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "App listing requirements page"
        }), " to understand how to fill your listing."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "app developer account"
        }), ", navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create listing"
        }), ". If this button is grayed out, listings have already been created for all your existing apps."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app"
        }), " you want to create a listing for and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), ". Apps that are already listed on the App Marketplace will not appear here."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the next screen, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select the languages your app is available in"
        }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "languages"
        }), " your app software is offered in. The App Marketplace is available in 14 languages: German, English, Spanish, French, Italian, Japanese, Dutch, Portuguese, Chinese, Finnish, Danish, Norwegian, Polish, and Swedish."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select the primary listing language for [app name]"
        }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "default language"
        }), " users will see when browsing the App Marketplace."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The app listing wizard has five tabs of information to fill out."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listing info"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Listing info"
      }), " tab:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "App information"
          }), " section, add your ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Public app name"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Company name"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Tagline"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Install Button URL"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "App icon"
          }), " section, upload an 800px by 800px icon for your app. This will appear in the App Marketplace and connected users’ accounts. When creating your icon:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Do"
            }), ": use a JPG, JPEG, or PNG file, fill the entire space (800px by 800px) - your image should touch at least two edges, and use a high-resolution, unpixellated image."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Do not"
            }), ": include text in your icon, use a wordmark, or leave extra whitespace around your icon."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/dev-doc-logo.png",
        alt: "dev-doc-logo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Categorize your app"
        }), " section, you can select up to two ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "categories"
        }), " for your app. You can also add any ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "search terms"
        }), " that can be used to find your app in the App Marketplace. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/apps/marketplace/understand-app-categories",
          children: "different categories available"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App details"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "App details"
      }), " tab:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Demo video"
        }), " section, upload a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "video"
        }), " to show how your app works. Refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
          children: "How to Make a Great App Demo Video"
        }), " page for best practices and examples of how to create a demo video."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Screenshots"
        }), " section, add ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "images"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "alt text"
        }), " showing how your app works. You can add up to eight images.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click Add ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "shared data"
            }), ".In the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Shared data"
            }), " section, let users know how data will flow between your app and HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the right panel, select which app object syncs with which HubSpot object, and the direction of the sync. In the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "App Overview"
            }), " section, give a brief ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), " of how your app can help users carry out their goals."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To add another object sync, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Add another object"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/shared-data-app-listing.png",
        alt: "shared-data-app-listing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "HubSpot features your app works with"
        }), " section, add up to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "10 HubSpot tools and features"
        }), " your app works with."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Other tools your software integrates with"
        }), " section, select up to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "six other tools or apps"
        }), " that your software integrates with."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Languages your app is available in"
        }), " section, select all ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "languages"
        }), " available for your app. You can only create additional App Marketplace listings in these languages."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pricing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pricing"
      }), " tab:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the top section, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "currency"
          }), " you want to list the app in. You can select from over 100 currencies."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can also set up ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "pricing plans"
          }), " for your app by adding the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "pricing model"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "plan name"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "tagline"
          }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "pricing detail"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "features list"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Depending on the pricing model selected, you may need to add more information, such as the frequency of payment, one-time fees, or monthly prices. Hover over the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "information icon"
              }), " to learn more about each pricing model."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/pricing-model-app-listing.png",
                alt: "pricing-model-app-listing"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["You can add up to five pricing plans. To add additional pricing plans, click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Add another plan"
              })]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Link to your software’s pricing plan"
          }), " section, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " where users can find more information on your pricing plans."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Agency pricing plans"
          }), " section, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " where users can learn more about pricing for partner or consulting services."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App features"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "App features"
      }), " tab, add features and guide customers on how to use them. There is no limit on the number of app features that can be created for your app."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add a feature"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Feature details"
        }), " page:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter your ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Feature name"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select all ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "scope groups"
            }), " a customer needs to have this feature. Scope groups are used to determine whether the customer's HubSpot account is compatible with the app features."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select an ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "image"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select where you want to display the feature: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "On your app's marketplace listing"
            }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "As a feature discovery card"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can show a maximum of five app features in the app marketplace listing and a maximum of 10 app features as discovery cards."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you select to display the feature as a feature discovery card:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select a primary button:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Link to a feature:"
            }), " select which HubSpot feature the button should link to."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create custom"
            }), ": enter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Button text"
            }), " and the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Button URL"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "No primary button"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select a how-to guide to onboard your customers:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create a guide from scratch:"
            }), " enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "image or video"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "External link to guide:"
            }), " enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Guide URL"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Video only guide:"
            }), " upload a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "video"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To add another guide, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add another section"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/feature-discovery-card.png",
        alt: "feature-discovery-card"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Support info"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Support info"
      }), " tab:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contact info"
        }), " section, add a support contact method for users who have questions while using your app. Add your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "support email"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "company website,"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "languages"
        }), " that customer support is offered."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Support resources"
        }), " section, include ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "links"
        }), " to your app’s setup documentation."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Terms of Service and Privacy Policy"
        }), " section, add ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "links"
        }), " to your privacy documentation."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Review info and submit your listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Review info"
      }), " tab:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App review instructions"
        }), " section, enter any ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app review instructions"
        }), " that the HubSpot Ecosystem Quality team requires to test and review your app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App Partner Program points of contact"
        }), " section, include the contact information of any people who need to be communicated with regarding the app listing, and the developer of the app. You must include the person's ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "role"
        }), ", f", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "irst and last name"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "email"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "country"
        }), " where they are based in."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To add another point of contact, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add another point of contact"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To submit the listing for review, click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Submit for review"
      }), " in the upper right corner. If the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Submit for review"
      }), " button is grayed out, check that you’ve filled out all the required fields and have ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Super admin"
      }), " permissions. If you’ve missed any required fields, you will see a number in the tab heading indicating the number of missed fields. Click each tab to enter the missing information, then return to the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Review info"
      }), " tab and click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Submit for review"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features.png",
        alt: "app-features"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create and update a localized listing for an existing app listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You will need to set a primary language on your existing app listing and have your primary listing already published in the HubSpot Marketplace in order to create listings in other languages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot app developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App Marketplace"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you already have an app listed in the Marketplace, you’ll see a yellow banner above the listed app asking you to set your primary listing language. Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Set it now"
          }), ". You will need to set the primary listing language for the app listing before you're able to create new language listings."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Select the languages your app is available in"
          }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "languages"
          }), " your app software is available in."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Select the primary listing language for [app name]"
          }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "default language"
          }), " users will see when browsing the App Marketplace."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Save."
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you have set a primary language, you will be able to add a new localized listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app listing"
        }), " and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create listing in another language"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Language for this listing"
        }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "language"
        }), " you want to create this listing in."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Follow the steps to create and submit a listing in the selected language."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "All parts of your localized listing should be in the same language, including product screenshots and demo videos."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Updating your primary listing does not automatically update your localized listings. Each must be updated independently."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Edit a live app listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once an app listing is live on the App Marketplace, to edit the listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace > Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over the listing you’d like to edit and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Make your changes then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Submit for review"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unpublish a live app listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over the listing you want to unpublish and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Unpublish live listing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, enter the reason for unpublishing the app, the click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Submit unpublish request"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Unpublish requests are processed by the HubSpot Marketplace team within 10 business days of submission."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}