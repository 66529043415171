"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937497;
const slug = exports.slug = 'guides/api/crm/objects/products';
const title = exports.title = 'CRM-API | Produkte';
const name = exports.name = 'vNext Docs DP - Produkte';
const metaDescription = exports.metaDescription = 'In HubSpot stellen Produkte die Waren oder Dienstleistungen dar, die Sie verkaufen. Die Produkte-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zu synchronisieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "produkt-erstellen",
  "label": "Produkt erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "produkte-zuordnen",
  "label": "Produkte zuordnen",
  "parent": null
}, {
  "depth": 1,
  "id": "einen-artikel-erstellen-und-zuordnen-mehrere-aufrufe",
  "label": "Einen Artikel erstellen und zuordnen (mehrere Aufrufe)",
  "parent": "produkte-zuordnen"
}, {
  "depth": 1,
  "id": "einen-artikel-erstellen-und-zuordnen-einzelner-aufruf",
  "label": "Einen Artikel erstellen und zuordnen (einzelner Aufruf)",
  "parent": "produkte-zuordnen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Produkte"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot stellen Produkte die Waren oder Dienstleistungen dar, die Sie verkaufen. Durch das Erstellen einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
        children: "Produktbibliothek"
      }), " können Sie schnell Produkte zu Deals hinzufügen, Angebote erstellen und Berichte zur Produkt-Performance erstellen. Produkt-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zwischen HubSpot und anderen Systemen zu synchronisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Produkte stellen zusammen mit Unternehmen, Kontakten, Deals, Tickets, Artikeln und Angeboten Objekte im HubSpot-CRM dar. In unserem Leitfaden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Grundlegendes zum CRM"
      }), " finden Sie Informationen zu Objekteigenschaften, -zuordnungen, -beziehungen und mehr."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiel für einen Anwendungsfall:"
      }), " Damit Vertriebsmitarbeiter problemlos Waren oder Dienstleistungen zu Deals, Angeboten und mehr hinzufügen können, verwenden Sie die Produkte-API, um Ihren Produktkatalog in HubSpot zu importieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Produkt erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Produkt zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/products"
      }), " durch. Berücksichtigen Sie im Anfragetext ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "-Objekt, das alle Produkteigenschaften enthält, die Sie beim Erstellen festlegen möchten. Sie können die Eigenschaften eines Produkts später durch eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an denselben Endpunkt aktualisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle verfügbaren Produkteigenschaften anzuzeigen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Eigenschaften-API"
      }), " durch. Um Produkteigenschaften abzurufen, lautet die Anfrage-URL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/products"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to crm/v3/objects/products\n{\n  \"properties\": {\n    \"name\": \"Implementation Service\",\n    \"price\": \"6000.00\",\n    \"hs_sku\": \"123456\",\n    \"description\": \"Onboarding service for data product\",\n    \"hs_cost_of_goods_sold\": \"600.00\",\n    \"hs_recurring_billing_period\": \"P12M\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Beachten Sie, dass der Wert für ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_recurring_billing_period"
        }), " als ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "P#M"
        }), " formatiert ist, wobei # die Anzahl der Monate ist."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Produkte zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Produkte selbst können nicht mit anderen CRM-Objekten verknüpft werden. Um jedoch die Informationen eines Produkts mit einem Deal oder einem Angebot zu verknüpfen, können Sie einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "Artikel"
      }), " basierend auf diesem Produkt erstellen. Artikel sind einzelne Instanzen von Produkten und stellen ein separates Objekt von Produkten dar, sodass Sie die Waren und Dienstleistungen in einem Deal oder Angebot nach Bedarf anpassen können, ohne das Produkt selbst aktualisieren zu müssen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie beispielsweise einen Deal zusammenstellen, bei dem eines Ihrer Produkte verkauft wird, erstellen Sie in der Regel zunächst einen Artikel aus dem Produkt und ordnen ihn dann dem Deal zu. Sie können dies entweder mit zwei separaten Aufrufen oder mit einem Aufruf tun, der den Artikel erstellt und zuordnet. Beide Optionen sind unten dargestellt."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Artikel gehören zu einem einzelnen übergeordneten Objekt. Wenn Objekte zugeordnet werden, sollten Artikel für jedes Objekt individuell sein. Wenn Sie beispielsweise einen Deal und ein Angebot erstellen, sollten Sie einen Satz von Artikeln für den Deal und einen anderen Satz für das Angebot erstellen. Dies trägt dazu bei, CRM-Daten über Objekte hinweg zu optimieren und unerwarteten Datenverlust zu verhindern, wenn Artikel geändert werden müssen. Wenn Sie beispielsweise ein Angebot löschen, werden auch die Artikel des Angebots gelöscht. Wenn diese Artikel auch mit einem Deal verknüpft sind, werden auch die Artikel des Deals gelöscht."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einen Artikel erstellen und zuordnen (mehrere Aufrufe)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zuerst erstellen Sie einen Artikel basierend auf einem Produkt mit der ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567"
      }), ". Um eine vollständige Liste der verfügbaren Artikeleigenschaften zu erhalten, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Eigenschaften-API"
      }), " durch. Die URL für Artikel wäre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/line_items"
      }), ". Da Sie den Artikel aus einem vorhandenen Produkt erstellen, übernimmt er Eigenschaftswerte vom Produkt, z. B. den Preis."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Antwort gibt eine Antwort-ID zurück, die Sie verwenden können, um sie über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), " einem Deal zuzuordnen. Nehmen Sie für dieses Beispiel an, dass die zurückgegebene Artikel-ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "7791176460"
      }), " ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um den Artikel einem bestehenden Deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "14795354663"
      }), ") zuzuordnen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663"
      }), " durch. Diese Anfrage verwendet den Standardzuordnungstyp."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "-Antwort gibt Informationen zurück, die den folgenden ähneln:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663\n\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"14795354663\"\n      },\n      \"to\": {\n        \"id\": \"7791176460\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 19\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"7791176460\"\n      },\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 20\n      }\n    }\n  ],\n  \"startedAt\": \"2023-12-21T20:06:52.083Z\",\n  \"completedAt\": \"2023-12-21T20:06:52.192Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot zeigt der Deal-Datensatz den Artikel in der ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Artikel"
      }), "-Karte an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einen Artikel erstellen und zuordnen (einzelner Aufruf)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Artikel aus einem vorhandenen Produkt zu erstellen und ihn einem Deal mit einem einzigen Anruf zuzuordnen, können Sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), "-Array in die Anfrage zum Erstellen eines Artikels einfügen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um den Artikel zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_item"
      }), " durch. Ihr Anfragetext sieht wie folgt aus. Beachten Sie, dass die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " für die Artikel-Deal-Zuordnung ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "20"
      }), " ist. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#association-type-id-values",
        children: "Zuordnungstypen zwischen verschiedenen Typen von CRM-Datensätzen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "-Antwort gibt Details zum neuen Artikel zurück. In HubSpot zeigt der Deal-Datensatz den Artikel in der ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Artikel"
      }), "-Karte an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}