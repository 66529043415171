"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358695;
const slug = exports.slug = 'guides/api/crm/understanding-the-crm';
const title = exports.title = 'CRM-API | Grundlegendes zum CRM';
const name = exports.name = 'vNext Docs DP - Grundlegendes zum CRM';
const metaDescription = exports.metaDescription = 'Das CRM von HubSpot ist ein System zur Verwaltung von Kundenbeziehungen und -daten. Erfahren Sie mehr über CRM-Objekttypen, Eigenschaften, Zuordnungen, zuständige Mitarbeiter und Interaktionen. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "objekte",
  "label": "Objekte",
  "parent": null
}, {
  "depth": 1,
  "id": "objektbeziehungen",
  "label": "Objektbeziehungen",
  "parent": "objekte"
}, {
  "depth": 1,
  "id": "aktivit%C3%A4ten-und-anh%C3%A4nge",
  "label": "Aktivitäten und Anhänge",
  "parent": "objekte"
}, {
  "depth": 1,
  "id": "datensynchronisierung",
  "label": "Datensynchronisierung",
  "parent": "objekte"
}, {
  "depth": 1,
  "id": "objekttyp-ids",
  "label": "Objekttyp-IDs",
  "parent": "objekte"
}, {
  "depth": 0,
  "id": "batch-aktionen",
  "label": "Batch-Aktionen",
  "parent": null
}, {
  "depth": 0,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": null
}, {
  "depth": 1,
  "id": "standardeigenschaften",
  "label": "Standardeigenschaften",
  "parent": "eigenschaften"
}, {
  "depth": 1,
  "id": "benutzerdefinierte-eigenschaften",
  "label": "Benutzerdefinierte Eigenschaften",
  "parent": "eigenschaften"
}, {
  "depth": 1,
  "id": "f%C3%BCr-datens%C3%A4tze-zust%C3%A4ndige-mitarbeiter",
  "label": "Für Datensätze zuständige Mitarbeiter",
  "parent": "eigenschaften"
}, {
  "depth": 0,
  "id": "eindeutige-ids-und-datensatz-ids",
  "label": "Eindeutige IDs und Datensatz-IDs",
  "parent": null
}, {
  "depth": 1,
  "id": "standardm%C3%A4%C3%9Fige-eindeutige-ids",
  "label": "Standardmäßige eindeutige IDs",
  "parent": "eindeutige-ids-und-datensatz-ids"
}, {
  "depth": 1,
  "id": "erstellen-ihrer-eigenen-eindeutigen-ids",
  "label": "Erstellen Ihrer eigenen eindeutigen IDs",
  "parent": "eindeutige-ids-und-datensatz-ids"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Grundlegendes zum CRM"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Grundlage Ihres HubSpot-Accounts ist eine Datenbank mit Ihren Geschäftsbeziehungen\nund -prozessen, auch CRM (Customer Relationship Management) genannt. Um diese Daten\nzu verwalten, beinhalten HubSpot-Accounts Objekte, die Typen von Beziehungen oder\nProzessen darstellen. Einzelne Instanzen von Objekten, sogenannte Datensätze, stellen\ndie einzelnen Entitäten unter jedem Objekttyp dar (z. B. ist John Smith ein Kontakt).\nUm Daten in jedem Datensatz zu speichern, verwenden Sie Eigenschaften (z. B. die\nE-Mail-Eigenschaft), und um die Beziehungen zwischen einzelnen Entitäten darzustellen,\nkönnen Sie Datensätze miteinander verknüpfen (z. B. John Smith einer Firma Smith\n& Co. zuordnen). Darüber hinaus können CRM-Datensätze auch Informationen zu Interaktionen\ndurch zugeordnete Interaktionen/Aktivitäten wie E-Mails, Anrufe und Meetings speichern.\nNachfolgend erfahren Sie mehr über CRM-Objekte, Datensätze, Eigenschaften und zusätzliche\nFunktionen. Weitere Informationen zum Verwalten Ihrer CRM-Datenbank in HubSpot finden\nSie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/get-started/manage-your-crm-database",
        children: "Wissensdatenbank von HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objekte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die CRM-API bietet Zugriff auf Objekte, Datensätze und Aktivitäten. In der folgenden Liste werden die in HubSpot verfügbaren Objekte erläutert. Die folgenden Objekte haben jeweils eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "Indexseite"
      }), " in HubSpot und können alle miteinander verknüpft werden:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Kontakte"
        }), ": speichern Informationen zu einer einzelnen Person. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/contacts",
          children: "Kontakte-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Unternehmen"
        }), ": speichern Informationen zu einem einzelnen Unternehmen oder einer einzelnen Organisation. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/companies",
          children: "Unternehmen-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Deals"
        }), ": stellen Umsatzchancen bei einem Kontakt oder Unternehmen dar. Sie werden über Pipeline-Phasen hinweg nachverfolgt, an dessen Ende dann ein erfolgreich abgeschlossener oder ein verlorener Deal steht. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/deals",
          children: "Deals-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Tickets"
        }), ": stellen Kundenanfragen für Hilfe oder Support dar. Sie werden über Pipeline-Status nachverfolgt, an dessen Ende das Schließen des Tickets steht. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/tickets",
          children: "Ticket-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
          children: "Anrufe"
        }), ": speichern Informationen zu Anrufen mit Kontakten, können aber auch anderen Objekten als Interaktion zugeordnet werden. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/calls",
          children: "Anrufe-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-quotes",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Angebote"
          })
        }), ": stellen Preisinformationen dar, die mit potenziellen Käufern geteilt werden. Angebote können mit Kontakten, Unternehmen, Deals und Artikeln verknüpft werden. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/commerce/quotes",
          children: "Angebote-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "Benutzerdefinierte Objekte"
        }), " (nur ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "): Erstellen Sie ein benutzerdefiniertes Objekt, um jeden Datentyp in HubSpot zu speichern – insbesondere Daten, die nicht in die oben aufgeführten Standardobjekttypen passen. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "Endpunkte für benutzerdefiniertes Objekt anzeigen"
        }), "Die folgenden Objekte haben keine Indexseiten innerhalb von HubSpot, können aber mit bestimmten anderen Objekten verknüpft werden:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
          children: "Aktivitäten/Interaktionen"
        }), " (Anrufe, E-Mails, Meetings, Notizen, Aufgaben, SMS, LinkedIn, WhatsApp, Briefpost): stellen Interaktionen dar, die mit Ihren Datensätzen verknüpft sind. Sie können Aktivitäten mit Kontakten, Unternehmen, Deals, Tickets und benutzerdefinierten Objekten verknüpfen. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/engagement-details",
          children: "Interaktionen-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
          children: "Produkte"
        }), ": stellen Waren oder Services dar, die Sie verkaufen. Produkte können nicht mit anderen CRM-Objekten verknüpft werden, aber Sie können Artikel basierend auf Produkten erstellen und diese mit Deals und Angeboten verknüpfen. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/products",
          children: "Produkte-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
          children: "Artikel"
        }), ": stellen individuelle Produkte und Services dar, die in einem Deal verkauft werden. Produkte/Artikel können aus vorhandenen Produkten in Ihrer Produktbibliothek oder als eigenständige Artikel erstellt werden. Eigenständige Artikel werden nicht zu Ihrer Produktbibliothek hinzugefügt. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/line-items",
          children: "Artikel-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Feedback-Einsendungen"
          })
        }), ": Speichern Informationen, die zu einer Feedbackumfrage eingereicht wurden. Feedback-Einsendungen sind mit Kontaktdatensätzen verknüpft. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/feedback-submissions",
          children: "Feedback-Einsendungen-Endpunkte"
        }), " anzeigen"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events",
          children: "Marketingevents"
        }), ": stellen Events dar, die mit Ihren Marketingbemühungen zusammenhängen, insbesondere auch Events aus verknüpften Integrationen. Sie können angeben, ob ein Kontakt an einem Marketingevent teilgenommen, sich dafür registriert oder seine Teilnahme abgesagt hat. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/marketing/marketing-events",
          children: "Marketingevents-Endpunkte anzeigen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "Rechnungen"
        }), ": stellen die Rechnungen dar, die Sie für getätigte Verkäufe senden. Rechnungen können ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Kontakten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "Unternehmen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "Deals"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "Artikeln"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "Rabatten"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " Gebühren"
          })
        }), " und ", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/taxes",
            children: "Steuern"
          }), " zugeordnet werden."]
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "Zahlungen"
        }), ": die Zahlungen, die von Käufern über Rechnungen, Zahlungslinks und Angebote getätigt werden. Zahlungen können ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Kontakten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "Unternehmen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "Deals"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "Rechnungen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "Angeboten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "Artikeln"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "Abonnements"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "Rabatten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: "Gebühren"
          })
        }), " und ", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/taxes",
            children: "Steuern"
          }), " zugeordnet werden."]
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "Abonnements"
        }), ": wiederkehrende Zahlungen, die über Zahlungslinks und Angebote geplant sind. Rechnungen können ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Kontakten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "Unternehmen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "Deals"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "Angeboten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "Artikeln"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "Zahlungen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "Rabatten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: "Gebühren"
          })
        }), " und ", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/taxes",
            children: "Steuern"
          }), " zugeordnet werden."]
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-details",
          children: "Benutzer"
        }), ": stellen die Benutzer in Ihrem HubSpot-Account dar. Benutzer können nicht anderen CRM-Objekten zugeordnet werden, sondern können über die Benutzer-API abgerufen und aktualisiert werden. Sie können auch Benutzer mithilfe der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "Benutzerbereitstellungs-API"
        }), " zu einem Account hinzufügen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objektbeziehungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot können Sie anzeigen, wie Objekte durch zugehörige Datensätze miteinander verknüpft sind. Beispielsweise können Sie mehrere Kontakte mit einem Unternehmen verknüpfen und dann das Unternehmen und relevante Kontakte mit einem Deal verknüpfen. Alle HubSpot-Accounts verfügen über Kontakte, Unternehmen, Deals, Tickets und Aktivitäten, die wie im folgenden Modell miteinander verknüpft werden können. Wenn Sie Zugriff auf einen HubSpot-Account haben, können Sie die eindeutigen Objektbeziehungen Ihres Accounts überprüfen, indem Sie zum ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-model-of-your-crm-object-and-activity-relationships",
        children: "Datenmodell-Tool"
      }), " navigieren. ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/data-model-overview-updated.gif",
        alt: "data-model-overview-updated"
      }), "Je nach Abonnement können Sie die spezifische Beziehung zwischen Datensätzen mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "Zuordnungslabeln"
      }), " beschreiben, und Ihr Account kann zusätzliche benutzerdefinierte Objekte enthalten, die den anderen Standardobjekten zugeordnet werden können. Andere Objekte wie Produkte und Artikel können nur bestimmten Objekten zugeordnet werden. Erfahren Sie mehr über diese ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-objects",
        children: "zusätzlichen Objekte und ihre Zuordnungen"
      }), ". Erfahren Sie mehr über Objektbeziehungen und das Verwalten von Zuordnungen mithilfe der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-Endpunkte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aktivitäten und Anhänge"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen"
      }), ", auch Aktivitäten genannt, speichern Daten aus dem Wechselspiel mit Datensätzen. Wenn Sie beispielsweise einen potenziellen Kunden anrufen, können Sie einen Anruf im Kontaktdatensatz protokollieren und den Anruf auch mit dem zugehörigen Unternehmen verknüpfen. Mögliche Aktivitäten sind Notizen, Aufgaben, Meetings, E-Mails, Anrufe, Briefpostsendungen sowie SMS-, LinkedIn- und WhatsApp-Nachrichten. Sie können auch Anhänge in Datensätzen speichern, um relevante Dateien nachzuverfolgen. Diese stehen oft im Zusammenhang mit Interaktionen. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen-APIs."
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datensynchronisierung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine Synchronisierung von Interaktionsdaten ist nicht erforderlich, um Objektdaten zu synchronisieren. Da ein Objekt zahlreichen Interaktionen zugeordnet werden kann, ist es auch wichtig, vor der Synchronisierung die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "API-Beschränkungen"
      }), " zu beachten. Sie sollten jedoch eher Interaktionen als Eigenschaften synchronisieren, wenn eine Integration einer vollständigen Migration vorausgeht. In diesem Fall stellt die Synchronisierung von Interaktionen über beide Systemen hinweg sicher, dass alle Benutzer während des Übergangs über die erforderlichen Daten verfügen. Wenn beispielsweise ein für die Unternehmensentwicklung zuständiges Team, das in HubSpot arbeitet, Deals an einen internen Vertriebsmitarbeiter übergibt, der in einem anderen CRM arbeitet, sollten Sie Interaktionen synchronisieren, damit beide Teams über den Kontext verfügen, den sie für einen Geschäftsabschluss benötigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objekttyp-IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie bestimmte APIs verwenden, müssen Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), "-Feld verwenden. Nachfolgend finden Sie die ID-Werte für jedes Objekt oder jede Aktivität:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontakte"
        }), ":", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Unternehmen"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-2"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Deals"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-3"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tickets"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-5"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Benutzerdefinierte Objekte"
        }), ": Um den ID-Wert für Ihr benutzerdefiniertes Objekt zu finden, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/schemas"
        }), " durch. Der Wert sieht ungefähr so aus: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2-3453932"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anrufe"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-48"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-Mails"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-49"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Meetings"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-47"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hinweise"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-46"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aufgaben"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-27"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Produkte"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-7"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rechnungen"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-52"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Artikel"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-8"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahlungen"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-101"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Angebote"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-14"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Abonnements"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-69"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kommunikationen"
        }), " (SMS, LinkedIn, WhatsApp-Nachrichten): ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-18"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Briefpost"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-116"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketingevents"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-54"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Feedback-Einsendungen"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-19"
        }), "Obwohl Sie immer den numerischen ID-Wert verwenden können, können Sie in einigen Fällen auch den Namen des Objekts für Kontakte, Unternehmen, Deals, Tickets oder Notizen verwenden. Zum Beispiel:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie einen Import mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/imports",
          children: "Importe-API"
        }), " starten, gibt die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " an, zu welchem Objekt die Daten in Ihrer Datei gehören. Um Daten für zu importieren, könnte Ihr Wert für ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " entweder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        }), " sein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bei Verwendung der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "Zuordnungen-API"
        }), " geben die Werte ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), " die Objekte und die Richtung der Zuordnung an. Um Zuordnungstypen für Kontakte zu Unternehmen anzuzeigen, könnte Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage-URL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/contact/company/labels"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/0-1/0-2/labels"
        }), " sein."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Batch-Aktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jedes Objekt stellt Batch-Endpunkte bereit, mit denen Sie mehrere Objektdatensätze in einer einzigen Anfrage erstellen, lesen, aktualisieren und archivieren können. Batch-Endpunkte sind auf 100 Datensätze pro Aufruf beschränkt. Beispielsweise würde die folgende Anfrage zwei Kontaktdatensätze, die durch ihre ID identifiziert wurden, im Rahmen eines Batch-Updates aktualisieren.", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST``/crm/v3/objects/contacts/batch/update"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"inputs\": [\n    {\n      \"id\": \"609051\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    },\n    {\n      \"id\": \"569751\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für Batch-Erstellungsaktionen können Sie Multi-Status-Fehler aktivieren, die Ihnen mitteilen, welche Datensätze erfolgreich erstellt wurden und welche nicht. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/other-resources/error-handling#multi-status-errors",
        children: "Einrichten der Behandlung von Multi-Status-Fehlern"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Informationen zu Datensätzen werden in Felder, Eigenschaften genannt, gespeichert, die dann in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "Gruppen"
      }), " organisiert werden. HubSpot bietet eine Reihe von Standardeigenschaften für jedes Objekt. Neben den Standardeigenschaften der einzelnen Objekte können Sie benutzerdefinierte Daten speichern, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "benutzerdefinierte Eigenschaften erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standardeigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM-Objekte werden durch einen primären ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Typ"
      }), " und einen Satz von ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Eigenschaften"
      }), " definiert. Jeder Typ verfügt über einen eindeutigen Satz an Standardeinstellungen, die von einer Zuordnung von Name/Wert-Paaren dargestellt werden. Erfahren Sie mehr über Standardeigenschaften für verschiedene Objekte:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Kontakte"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Unternehmen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Deals"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: "Feedback-Einsendungen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties",
          children: "Rechnungen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Zahlungen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Abonnements"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benutzerdefinierte Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erstellen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "benutzerdefinierte Eigenschaften"
      }), ", um spezifische Informationen für ein Objekt zu speichern. Benutzerdefinierte Eigenschaften können über die Endpunkte für CRM-Objekteigenschaften verwaltet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Für Datensätze zuständige Mitarbeiter"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können HubSpot-Benutzer als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "zuständige Mitarbeiter"
      }), " von Datensätzen zuweisen. Jeder HubSpot-Benutzer mit Zugriff auf ein Objekt kann als ein zuständiger Mitarbeiter zugewiesen werden und mehrere zuständige Mitarbeiter können einem Objekt zugewiesen werden, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/how-can-i-create-a-custom-owner-property",
        children: "benutzerdefinierte Eigenschaft"
      }), " für diesen Zweck erstellen. Zuständige Mitarbeiter können nur in HubSpot erstellt werden. Sie können jedoch den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "Mitarbeiter-Endpunkt"
      }), " verwenden, um ihre personenbezogenen Angaben, einschließlich IDs und E-Mail-Adressen, zu identifizieren. Diese Daten können dann CRM-Datensätzen in HubSpot oder über eine Änderung der Eigenschaft API-Aufrufen zugewiesen werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eindeutige IDs und Datensatz-IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine eindeutige ID ist ein Wert, der einen Datensatz in einer Datenbank von einem anderen Datensatz unterscheidet, selbst wenn sie ansonsten identische Informationen enthalten. Eine Datenbank für eine Bank kann beispielsweise Datensätze für zwei Personen enthalten, die beide John Smith heißen. Um zu vermeiden, dass versehentlich Geld an den falschen John Smith überwiesen wird, wird jedem Datensatz eine Zahl als eindeutige ID zugewiesen. In HubSpot verwenden Sie diese eindeutigen IDs, um Ihre Daten an die richtigen Datensätze zu senden und die Deduplizierung zu verwalten. Erfahren Sie mehr darüber, wie HubSpot die Deduplizierung in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/deduplication-of-contacts-companies-deals-tickets",
        children: "Wissensdatenbank"
      }), " handhabt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standardmäßige eindeutige IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ein Datensatz in HubSpot erstellt wird, wird automatisch eine eindeutige ID generiert, die als Zeichenfolge behandelt werden sollte. Diese IDs sind nur für den jeweiligen Objekttyp eindeutig. Daher kann es sowohl einen Kontakt als auch ein Unternehmen mit derselben ID geben. Für Kontakte und Unternehmen gibt es zusätzliche eindeutige IDs, einschließlich der E-Mail-Adresse (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ") eines Kontakts und des Domain-Namens (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ") eines Unternehmens."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Erstellen Ihrer eigenen eindeutigen IDs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In vielen Fällen können Sie die von HubSpot generierte Datensatz-ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") verwenden, um die Logik Ihrer Integration zu unterstützen. Ihre Daten erfordern jedoch möglicherweise andere Eigenschaften mit eindeutigen Werten, oder es kann vorkommen, dass die Datensatz-ID entweder nicht verwendet werden kann oder die Integrationslogik Ihrer App komplizierter macht. In diesen Fällen können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "eine benutzerdefinierte „Eindeutige ID“-Eigenschaft über die Eigenschaften-API erstellen."
      }), " Sobald Sie eine benutzerdefinierte „Eindeutige ID“-Eigenschaft erstellt haben, können Sie sie in API-Aufrufen verwenden, um bestimmte Datensätze auf die gleiche Weise zu identifizieren und zu aktualisieren, wie Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " für Kontakte oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " für Unternehmen verwenden können. Um beispielsweise einen Deal basierend auf seinem Wert für eine benutzerdefinierte „Eindeutige ID“-Eigenschaft abzurufen, könnte Ihre Anfrage-URL wie folgt aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}