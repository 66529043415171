import I18n from 'I18n';
export const breadcrumbs = {
  marketplace: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.marketplace'),
  debugging: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.debugging'),
  memberships: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.memberships'),
  storage: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.storage'),
  content: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.contentAndStructure'),
  themes: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.themes'),
  templates: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.templates'),
  types: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.templateTypes'),
  dragAndDropAreas: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.dragAndDropAreas'),
  'data-driven-content': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.dataDrivenContent'),
  'dynamic-pages': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.dynamicPages'),
  'mobile-chat-sdk': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.mobileChatSdk'),
  graphql: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.graphql'),
  modules: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.modules'),
  fields: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.fields'),
  'serverless-functions': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.serverlessFunctions'),
  'building-blocks': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.buildingBlocks'),
  cms: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cms'),
  'drag-and-drop': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.dragAndDrop'),
  'analytics-and-events': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.analyticsAndEvents'),
  'custom-events': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.customEvents'),
  automation: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.automation'),
  blogs: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.blogs'),
  conversations: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.conversations'),
  'working-with-chatbots': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.workingWithChatbots'),
  crm: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.crm'),
  commerce: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.commerce'),
  engagements: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.engagements'),
  extensions: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.extensions'),
  lists: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.lists'),
  objects: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.objects'),
  files: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.files'),
  marketing: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.marketing'),
  oauth: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.oauth'),
  settings: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.settings'),
  webhooks: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.webhooks'),
  associations: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.associations'),
  users: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.users'),
  'improve-older-sites': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.improveOlderSites'),
  hubl: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsReferenceBreadcrumbs.templatingLangDescription', {
    languageName: 'HubL'
  }),
  tags: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsReferenceBreadcrumbs.tags'),
  'standard-components': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.uiComponentsBreadcrumbs.standardComponents'),
  'crm-data-components': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.uiComponentsBreadcrumbs.crmDataComponents'),
  'crm-action-components': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.uiComponentsBreadcrumbs.crmActionComponents'),
  // do not localize, this is a proprietary word that should not be translated
  slack: () => 'Slack',
  emails: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.emails'),
  tools: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.tools'),
  setup: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.setup'),
  performance: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.performance'),
  intro: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.intro'),
  'ui-extensions': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.uiExtensions'),
  'sample-projects': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.sampleProjects'),
  authentication: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.authentication'),
  // eslint-disable-next-line hubspot-dev/no-private-classes
  'private-apps': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.privateApps'),
  'public-apps': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.publicApps'),
  'calling-extensions': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.callingExtensions'),
  'api-usage': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.apiUsage'),
  deprecated: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.deprecated'),
  otherResources: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.otherResources'),
  legal: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.legal'),
  apiIntegrationGuides: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.apiIntegrationGuides'),
  cmsDeveloperDocs: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsDeveloperDocs'),
  developerCommunityForums: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.developerCommunityForums'),
  faq: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.faq'),
  developerPolicy: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.developerPolicy'),
  developerTerms: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.developerTerms'),
  acceptableUsePolicy: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.acceptableUsePolicy'),
  cdnSecurityAndPerformance: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cmsBreadcrumbs.cdnSecurityPerformance'),
  'cookie-banner': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.cookieBanner'),
  hubdb: () => 'HubDB',
  'other-resources': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.otherResources'),
  'getting-started': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.gettingStarted'),
  'developer-projects': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.developerProjects'),
  'sample-extensions': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.sampleExtensions'),
  library: () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.library'),
  'app-management': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.appManagement'),
  charts: () => I18n.text('developer-docs-shared-components.navigation.labels.charts'),
  // external URLs
  'https://legal.hubspot.com/hubspot-developer-policy': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.developerPolicy'),
  'https://legal.hubspot.com/developer-terms': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.developerTerms'),
  'https://legal.hubspot.com/acceptable-use': () => I18n.text('developer-docs-shared-components.navigation.breadcrumbs.acceptableUsePolicy')
};