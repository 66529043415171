"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49334172328;
const slug = exports.slug = 'guides/cms/content/fields/best-practices';
const title = exports.title = 'Prácticas recomendadas en cuanto a campos de módulos y temas';
const name = exports.name = 'Prácticas recomendadas en cuanto a campos de módulos y temas';
const metaDescription = exports.metaDescription = 'La habilidad de crear y agrupar campos plantea un problema de experiencia de usuario (UX). Te recomendamos que utilices esta página como guía para proporcionar una experiencia intuitiva a los creadores de contenido.';
const featuredImage = exports.featuredImage = 'https://www.hubspot.com/hubfs/options%20and%20style%20tabs.png';
const featuredImageAltText = exports.featuredImageAltText = 'Pestaña de campos de estilo de módulo en el editor de páginas de CMS Hub';
const toc = exports.toc = [{
  "depth": 0,
  "id": "campos-de-estilo-vs.-campos-de-contenido",
  "label": "Campos de estilo vs. campos de contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "organizaci%C3%B3n-de-los-campos",
  "label": "Organización de los campos",
  "parent": null
}, {
  "depth": 1,
  "id": "ejemplo",
  "label": "Ejemplo",
  "parent": "organizaci%C3%B3n-de-los-campos"
}, {
  "depth": 0,
  "id": "campos-obligatorios",
  "label": "Campos obligatorios",
  "parent": null
}, {
  "depth": 0,
  "id": "tipograf%C3%ADa",
  "label": "Tipografía",
  "parent": null
}, {
  "depth": 0,
  "id": "alternar-vs-casilla-de-verificaci%C3%B3n-en-campos-booleanos",
  "label": "Alternar vs casilla de verificación en campos booleanos",
  "parent": null
}, {
  "depth": 0,
  "id": "art%C3%ADculos-relacionados",
  "label": "Artículos relacionados",
  "parent": null
}, {
  "depth": 2,
  "id": "tutoriales",
  "label": "Tutoriales",
  "parent": "art%C3%ADculos-relacionados"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      h2: "h2",
      a: "a",
      em: "em",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Prácticas recomendadas en cuanto a campos de módulos y temas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al incorporar campos a tu módulo o tema, hay algunas prácticas recomendadas que debes tener en cuenta, entre ellas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Agrupar campos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Organización lógica de los campos en los módulos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Proporcionar una experiencia de estilo consistente con campos de estilo"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En este artículo, aprende algunas de las mejores prácticas recomendadas para crear una experiencia eficiente y consistente en la edición de módulos y campos de temas para los creadores de contenido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos de estilo vs. campos de contenido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes agregar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "campos de estilo"
      }), " tanto a los módulos como a los temas, y permiten al creador de contenido ajustar la apariencia del módulo, como los bordes o las imágenes de fondo detrás del texto. Los campos de estilo no deben comunicar significado ni ser necesarios para comprender el contenido de la página. Por ejemplo, si tienes un módulo de texto e imagen y la imagen es necesaria para comprender el contenido del texto, la imagen debe ser un campo de contenido en lugar de un campo de estilo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se agregan a un módulo, las opciones de estilo aparecerán en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estilos"
      }), " del editor de páginas. Cuando se agregan a un tema, las opciones de estilo aparecerán en la barra lateral izquierda del editor de temas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/style-field-module-editor0.png",
        alt: "style-field-module-editor0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Ten en cuenta la accesibilidad a la hora de decidir entre utilizar una imagen o un campo de imagen de fondo. Si el creador de contenido pudiera agregar texto alternativo, utiliza un campo de imagen en lugar de un campo de imagen de fondo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Organización de los campos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La forma en que organizas los campos juega un papel importante en la capacidad del creador de contenido para realizar cambios rápidamente. A continuación, se presentan las mejores prácticas recomendadas para organizar los campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Agrupa los campos de estilo en función del componente que controlan en lugar del elemento estilístico."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Deja solo los campos de más alto nivel e importantes fuera de los grupos."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Favorece la creación de un grupo de componentes en lugar de grupos no anidados. Si alguna vez necesitas agregar funcionalidad a tu módulo más adelante, no puedes mover tus módulos a un grupo luego sin actualizar manualmente todas las instancias del módulo en las páginas."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ordena los grupos de campos en el orden en que aparecen los componentes basados en el idioma principal de la mayoría de los creadores de contenido que mantendrán el sitio. Ejemplo: El inglés se lee de izquierda a derecha, de arriba a abajo. Si los usuarios que mantienen el sitio suelen leer de derecha a izquierda en su idioma, deberías proporcionarlo en ese orden. En caso de duda, basa esto en el idioma principal del contenido de tu sitio."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#field-groups",
        children: "grupos de campos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ejemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación un ejemplo de módulo de tarjeta."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/card%20and%20style%20settings.png",
        alt: "Un diseño típico de tarjeta que contiene un ícono, texto y un botón. Las opciones de estilo se muestran agrupadas a la derecha en las categorías Icono, Botón y Tarjeta."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El panel de estilos agrupa los campos del módulo en 3 grupos basado en componentes de la tarjeta a los que se les puede aplicar estilo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Icono"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Botón"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tarjeta"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al ver la tarjeta, primero se ve el ícono, luego el texto y el botón. El grupo Íconos y sus opciones de estilo aparecen antes del grupo de botones y sus opciones de estilo. Por lo tanto, el campo de color para el ícono se encontraría en el grupo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ícono"
      }), ", mientras que el campo de color para el color de fondo del botón se encontraría dentro del grupo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Botón"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos obligatorios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos obligatorios son campos que el creador de contenido debe establecer para mostrar el módulo y publicar la página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Solo se requiere que los campos tengan un valor si se rompe el módulo para no tener un valor."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si necesitas tener un campo obligatorio, proporciona un valor predeterminado si es posible."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, estás construyendo un módulo de carrusel de imágenes que te permite configurar cuántas diapositivas mostrar al mismo tiempo. Se necesita un valor mínimo de 1 y, sin un valor, no sabes cómo mostrar el carrusel de imágenes. Esta es una situación en la que requerir un valor, pero ajustar un valor predeterminado de uno o dos, podría tener sentido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipografía"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dado que los campos de texto enriquecido proporcionan más control tipográfico, se recomienda utilizar campos de texto enriquecidos sobre una combinación de campo de texto y campo de fuente siempre que sea posible."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puede haber casos en los que necesites proporcionar estilos tipográficos que se apliquen a varias piezas de contenido dentro del mismo módulo, como un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#text",
        children: "campo de texto"
      }), " destinado a los encabezados. En ese caso, es posible que puedas hacer que el trabajo del creador de contenido sea más eficiente proporcionando campos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#font",
        children: "estilo de fuente"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#color",
        children: "color"
      }), " en el campo de texto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al incluir campos de texto enriquecido, se recomienda permitir que los controles tipográficos del campo anulen los campos de estilo agregados manualmente. Si un campo de estilo controla un campo de texto enriquecido, puede valer la pena ajustar el texto de ayuda, para asegurarse de que el creador de contenido lo sepa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alternar vs casilla de verificación en campos booleanos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al incluir un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#boolean",
        children: "campo booleano"
      }), ", puedes configurarlo para que se muestre como interruptor o una casilla de verificación."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Configurar el booleano como un alternador cuando el campo controla un diseño principal o una opción de diseño. Por ejemplo, convertir el diseño de una tarjeta de orientación vertical a horizontal."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Establece el booleano como una casilla de verificación cuando el cambio sea menos drástico. Por ejemplo, ocultar o mostrar una fecha de publicación para una publicación de blog en un módulo de publicaciones destacadas."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Artículos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields",
          children: "Tipos de campo de módulo y tema"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Descripción de los campos de módulos y temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Resumen de módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview",
          children: "Resumen de temas"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tutoriales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Primeros pasos con módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Primeros pasos para usar temas"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}