"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const slug = exports.slug = 'reference/cms/overview';
const title = exports.title = 'CMS-Referenzübersicht';
const metaDescription = exports.metaDescription = 'Referenzdokumente für Entwickler für das HubSpot Content Management System (CMS).';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cms-entwicklungsanleitungen",
  "label": "CMS-Entwicklungsanleitungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS-Referenzübersicht"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Abschnitt enthält Referenzdokumente für Entwickler für das Contentmanagement-System (CMS) von HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Er bietet schnellen Zugang zu Referenzdokumenten zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "Modulen"
      }), ", zur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), " Templating-Sprache, zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Feldern"
      }), " und zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "serverlosen Funktionen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS-Entwicklungsanleitungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einen Überblick über das Entwickeln im Contentmanagement-System (CMS) von HubSpot finden Sie im Abschnitt mit den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "CMS-Entwicklungsanleitungen"
      }), ". Diese enthalten einen Überblick über die CMS-Funktionen und -Tools sowie eine Schnellstartanleitung."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;