"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 135388732420;
const slug = exports.slug = 'guides/api/crm/lists/lists-filters';
const title = exports.title = 'List filters overview';
const name = exports.name = 'List filters overview';
const metaDescription = exports.metaDescription = 'Learn how to structure filters when using the Lists API.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "filters",
  "label": "Filters",
  "parent": null
}, {
  "depth": 0,
  "id": "filter-evaluation-steps",
  "label": "Filter evaluation steps",
  "parent": null
}, {
  "depth": 0,
  "id": "filter-branches",
  "label": "Filter branches",
  "parent": null
}, {
  "depth": 0,
  "id": "structure",
  "label": "Structure",
  "parent": null
}, {
  "depth": 0,
  "id": "filtering-on-events-and-associated-objects",
  "label": "Filtering on events and associated objects",
  "parent": null
}, {
  "depth": 0,
  "id": "filter-branch-types",
  "label": "Filter branch types",
  "parent": null
}, {
  "depth": 0,
  "id": "or-filter-branch",
  "label": "OR filter branch",
  "parent": null
}, {
  "depth": 0,
  "id": "and-filter-branch",
  "label": "AND filter branch",
  "parent": null
}, {
  "depth": 0,
  "id": "unified_events-filter-branch",
  "label": "UNIFIED_EVENTS filter branch",
  "parent": null
}, {
  "depth": 0,
  "id": "association-filter-branch",
  "label": "ASSOCIATION filter branch",
  "parent": null
}, {
  "depth": 0,
  "id": "filter-types",
  "label": "Filter types",
  "parent": null
}, {
  "depth": 0,
  "id": "property-filter-operations",
  "label": "Property filter operations",
  "parent": null
}, {
  "depth": 0,
  "id": "time_point-and-time_ranged-examples",
  "label": "TIME_POINT and TIME_RANGED examples",
  "parent": null
}, {
  "depth": 0,
  "id": "is-equal-to-date",
  "label": "Is equal to date",
  "parent": null
}, {
  "depth": 0,
  "id": "in-last-x-number-of-days",
  "label": "In Last X Number of Days",
  "parent": null
}, {
  "depth": 0,
  "id": "in-next-x-number-of-days",
  "label": "In Next X Number of Days",
  "parent": null
}, {
  "depth": 0,
  "id": "updated-or-not-updated-in-the-last-x-days",
  "label": "Updated or Not Updated in the Last X Days",
  "parent": null
}, {
  "depth": 0,
  "id": "is-after-date",
  "label": "Is After Date",
  "parent": null
}, {
  "depth": 0,
  "id": "is-relative-to-today",
  "label": "Is Relative to Today",
  "parent": null
}, {
  "depth": 0,
  "id": "is-before-or-after-another-property-value-or-last-updated",
  "label": "Is Before or After another property (value or last updated)",
  "parent": null
}, {
  "depth": 0,
  "id": "refine-by-operation",
  "label": "Refine by operation",
  "parent": null
}, {
  "depth": 0,
  "id": "pruning-refine-by-operations",
  "label": "Pruning Refine By operations",
  "parent": null
}, {
  "depth": 0,
  "id": "absolute-comparative-timestamp",
  "label": "Absolute Comparative timestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "absolute-ranged-timestamp",
  "label": "Absolute Ranged timestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "relative-comparative-timestamp",
  "label": "Relative Comparative timestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "relative-ranged-timestamp",
  "label": "Relative Ranged timestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "coalescing-refine-by-operations",
  "label": "Coalescing Refine By operations",
  "parent": null
}, {
  "depth": 0,
  "id": "v1-list-filters-legacy",
  "label": "v1 list filters (legacy)",
  "parent": null
}, {
  "depth": 1,
  "id": "all-property-types-operations",
  "label": "All property types operations",
  "parent": "v1-list-filters-legacy"
}, {
  "depth": 1,
  "id": "string-operations",
  "label": "String operations",
  "parent": "v1-list-filters-legacy"
}, {
  "depth": 1,
  "id": "multi-string-operations",
  "label": "Multi-string operations",
  "parent": "v1-list-filters-legacy"
}, {
  "depth": 1,
  "id": "number-operations",
  "label": "Number operations",
  "parent": "v1-list-filters-legacy"
}, {
  "depth": 1,
  "id": "boolean-operations",
  "label": "Boolean operations",
  "parent": "v1-list-filters-legacy"
}, {
  "depth": 1,
  "id": "enumeration-operations",
  "label": "Enumeration operations",
  "parent": "v1-list-filters-legacy"
}, {
  "depth": 1,
  "id": "datetime-operations",
  "label": "Datetime operations",
  "parent": "v1-list-filters-legacy"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      ol: "ol",
      a: "a",
      pre: "pre",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "List filters"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you select a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SNAPSHOT"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DYNAMIC"
      }), " list processing type when creating a list, use filters to determine which records are members of the list."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["List filters have conditional logic that is defined by filter branches which have ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "AND"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OR"
        }), " operation types. This is defined by the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterBranchType"
        }), " parameter."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Within these branches, there are groups of individual filters that contain logic to assess records to determine if they should be included in the list. These are defined by the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterType"
        }), " parameter."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Filter branches can also have nested filter branches."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are a variety of different filter types that can be used to build out filters in a list's filter definition. These filters types can be used together in different combinations to construct the logic that is needed for a particular list definition structure."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot uses ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "PASS"
      }), "/", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "FAIL"
      }), " logic with filters to determine if a record should be in a list. If a record passes all filters, it will be a member of the list."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filter evaluation steps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To determine which records pass a list's filters, the following steps occur in order:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select or fetch the relevant records based on the filter selected. For example, the property values for all records being evaluated for a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#property-filter-options",
          children: "property filter."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If applicable, use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pruningRefineBy"
        }), " parameter to refine the data to a specific time range (see Refine By Operation section)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Apply the filtering rules against the refined data to determine if the records ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "PASS"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "FAIL"
        }), ". For example, if the filter \"First Name is equal to \"John\"\" is selected, ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "PASS"
        }), " all records that have \"John\" for their First Name contact property."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If applicable, use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "coalescingRefineBy"
        }), " parameter to further refine the data to a specific number of occurrences. For example, the filter \"contact has filled out a form at least 2 times\".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "coalescingRefineBy"
            }), " parameter is present, then ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "PASS"
            }), " records that meet the number of occurrences selected."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If no ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "coalescingRefineBy"
            }), " parameter is present, then records ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "PASS"
            }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "FAIL"
            }), " based on the criteria set in step 3."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filter branches"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A filter branch is a data structure used to build out the conditional logic of a list's definition. Filter branches are defined by a specific type, an operator, a list of filters, and a list of sub-branches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The filter branch's operator ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " dictate how the filter branch will be evaluated relative to the rest of the branches. The list of filters and sub-filter branches determine which records will be members of a list."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If the filter branch has an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "AND"
        }), " operator, then the record is accepted by the branch if it passes ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "all"
        }), " the branch's filters and is accepted by ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "all"
        }), " the sub-filter branches."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If the filter branch has an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OR"
        }), " operator, then the record is accepted by the branch if it passes ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "any"
        }), " of the branch's filters or is accepted by ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "any"
        }), " of the branch's sub-filter branches."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A record is a member of a list if it is accepted by the root filter branch in the list definition."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Structure"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All filter definitions must start with a root-level ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " (see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#filter-branch-types",
        children: "filter branch types"
      }), " for more details). This root level ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " filter branch must then have one or more ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " sub-filter branches."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The root-level ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "OR"
        })
      }), " filter branch can be thought of as the parent filter branch while the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " sub-filter branches can be thought of as child filter groups. Together, these branches make up the base filter branch structure."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filterBranchType\": \"AND\",\n        \"filters\": [\n          // can have filters here\n        ]\n      }\n      // can have more filterBranches here\n    ],\n    \"filterBranchType\": \"OR\",\n    \"filters\": []\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to create a list of contacts who have the first name \"John\" OR ", (0, _jsxRuntime.jsx)("u", {
        children: "do not"
      }), " have the last name \"Smith\", the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " body would be:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranch\": {\n    \"filterBranchType\": \"OR\",\n    \"filters\": [],\n    \"filterBranches\": [\n      {\n        \"filterBranchType\": \"AND\",\n        \"filters\": [\n          {\n            \"filterType\": \"PROPERTY\",\n            \"property\": \"firstname\",\n            \"operation\": {\n              \"operationType\": \"MULTISTRING\",\n              \"operator\": \"IS_EQUAL_TO\",\n              \"values\": [\"John\"]\n            }\n          }\n        ],\n        \"filterBranches\": []\n      },\n      {\n        \"filterBranchType\": \"AND\",\n        \"filters\": [\n          {\n            \"filterType\": \"PROPERTY\",\n            \"property\": \"lastname\",\n            \"operation\": {\n              \"operationType\": \"MULTISTRING\",\n              \"operator\": \"IS_NOT_EQUAL_TO\",\n              \"values\": [\"Smith\"]\n            }\n          }\n        ],\n        \"filterBranches\": []\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the above example, there is one parent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " parameter with two nested ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " parameters. The nested filter branches each have one ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterType"
      }), " that sets the criteria for the list."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The two ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterType"
        }), " parameters are nested within ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "AND"
        }), " filter branches, rather than directly within an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OR"
        }), " filter branch. This structure is enforced by HubSpot's API so that the list's filters can be properly rendered in the HubSpot user interface."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the HubSpot user interface, the above code would look like the image below. Any contacts that meet ", (0, _jsxRuntime.jsx)("u", {
        children: "either"
      }), " of the criteria will be a member of the list."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/and-or-list.png",
        alt: "and-or-list"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a list of contacts who have the first name \"John\" ", (0, _jsxRuntime.jsx)("u", {
        children: "and"
      }), " the last name \"Smith\":"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranch\": {\n    \"filterBranchType\": \"OR\",\n    \"filters\": [],\n    \"filterBranches\": [\n      {\n        \"filterBranchType\": \"AND\",\n        \"filters\": [\n          {\n            \"filterType\": \"PROPERTY\",\n            \"property\": \"firstname\",\n            \"operation\": {\n              \"operationType\": \"MULTISTRING\",\n              \"operator\": \"IS_EQUAL_TO\",\n              \"values\": [\"John\"]\n            }\n          },\n          {\n            \"filterType\": \"PROPERTY\",\n            \"property\": \"lastname\",\n            \"operation\": {\n              \"operationType\": \"MULTISTRING\",\n              \"operator\": \"IS_EQUAL_TO\",\n              \"values\": [\"Smith\"]\n            }\n          }\n        ],\n        \"filterBranches\": []\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the above example, there is one parent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " with one nested ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " has two ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterType"
      }), " parameters, one for each criteria."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the HubSpot user interface, the above code would look like the image below. Any contacts that meet the criteria will be a member of the list."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/and-list.png",
        alt: "and-list"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtering on events and associated objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are two special versions of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "UNIFIED_EVENTS"
        }), ": used to filter on events."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ASSOCIATION"
        }), ": used to filter on records that are associated with the primary record being evaluated."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These branches should be used within an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " filter branch. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranches\": [\n    {\n      \"filterBranches\": [\n        {\n          \"filterBranchType\": \"UNIFIED_EVENTS\",\n          \"filterBranchOperator\": \"AND\",\n          \"filters\": [\n            // must have one or more filters here\n          ]\n          // additional UNIFIED_EVENTS filter branch details omitted\n        },\n        {\n          \"filterBranchType\": \"ASSOCIATION\",\n          \"filterBranchOperator\": \"AND\",\n          \"filters\": [\n            // may have one or more filters here\n          ],\n          \"filterBranches\": [\n            // may have another ASSOCIATION branch here if filtering based on line item properties from a contact list\n          ]\n          // additional ASSOCIATION filter branch details omitted\n        }\n      ],\n      \"filterBranchOperator\": \"AND\",\n      \"filterBranchType\": \"AND\",\n      \"filters\": [\n        // can have filters here\n      ]\n    }\n    // can have more filterBranches here\n  ],\n  \"filterBranchOperator\": \"OR\",\n  \"filterBranchType\": \"OR\",\n  \"filters\": []\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filter branch types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, review the different ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " parameters that can be used to construct your list's filter definition structure."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OR filter branch"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Begin your filter definition structure with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), ". It is used to apply ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "OR"
      }), " conditional logic against records that are accepted by the nested ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " filter branches."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " filter branches:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Must have one or more ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "AND"
        }), " type sub-filter branches."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)("u", {
          children: (0, _jsxRuntime.jsx)("u", {
            children: "C"
          })
        }), "\n", (0, _jsxRuntime.jsx)("u", {
          children: "annot"
        }), " have any filters."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a record is accepted by ", (0, _jsxRuntime.jsx)("u", {
        children: "any"
      }), " of its nested filter branches, an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " filter branch will accept the record as well."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranchType\": \"OR\",\n  \"filterBranches\": [], // One or more nested AND filter branches\n  \"filters\": [] // Zero filters\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "AND filter branch"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " is used as a nested filter branch within the parent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OR"
      }), " filter branch. All filter definitions must have at least one ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " filter branch for it to be valid. It is used to apply ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AND"
      }), " conditional logic against the records that pass evaluation as defined by its filters and have also been accepted by nested filter branches."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "AND"
      }), " filter branches:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Can have zero or more filters."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Can have zero or more nested ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "UNIFIED_EVENTS"
        }), " and/or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ASSOCIATION"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterBranchType"
        }), " parameters."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["An ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " filter branch accepts a record if the record is accepted by ", (0, _jsxRuntime.jsx)("u", {
        children: "all"
      }), " of its nested filter branches and the record passes ", (0, _jsxRuntime.jsx)("u", {
        children: "all"
      }), " filters in the filter branch."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranchType\": \"AND\",\n  \"filterBranches\": [], // Zero or more nested UNIFIED_EVENTS or ASSOCIATION filter branches\n  \"filters\": [] // Zero or more filters\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "UNIFIED_EVENTS filter branch"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNIFIED_EVENTS"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " can only be used as a nested filter branch within an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), ". It is used to determine which records have or have not completed a given unified event."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "UNIFIED_EVENTS"
      }), " type filter branches:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Can have one or more ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROPERTY"
        }), " type filters."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cannot have any additional filter branches."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNIFIED_EVENTS"
      }), " filter branch accepts a record if the record passes ", (0, _jsxRuntime.jsx)("u", {
        children: "all"
      }), " filters on the filter branch and the criteria defined by the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNIFIED_EVENTS"
      }), " filter branch."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranchType\": \"UNIFIED_EVENTS\",\n  \"filterBranches\": [], // Zero filter branches\n  \"filters\": [], // Zero or more filters\n  \"eventTypeId\": \"0-1\",\n  \"operator\": \"HAS_COMPLETED\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_COMPLETED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NOT_COMPLETED"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ASSOCIATION filter branch"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATION"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), " can only be used as a nested filter branch within an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "AND"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranchType"
      }), ". It is used to filter on records which are associated with the primary record being evaluated."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATION"
      }), " filter branches:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Must have one or more filters."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cannot have any additional nested filter branches."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["An ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATION"
      }), " filter branch accepts a record if it is accepted by ", (0, _jsxRuntime.jsx)("u", {
        children: "all"
      }), " of its nested filter branches and if the record ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "PASSES"
      }), " all filters of the filter branch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can only have additional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranches"
      }), " in the case of a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CONTACT"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LINE_ITEM"
      }), " association."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterBranchType\": \"ASSOCIATION\",\n  \"filterBranches\": [], // Zero or one nested ASSOCIATION filter branches\n  \"filters\": [], // Zero or more filters\n  \"objectTypeId\": \"0-1\",\n  \"operator\": \"IN_LIST\",\n  \"associationTypeId\": 280,\n  \"associationCategory\": \"HUBSPOT_DEFINED\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_LIST"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_DEFINED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "USER_DEFINED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATOR_DEFINED"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filter types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Review the table below for the different types of filters that can be used. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterType"
      }), " parameter is used to define the filter within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranch"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For more information, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/lists/list-definitions#filtertype-options",
        children: "list filters reference page"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ADS_TIME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Evaluates whether a contact has seen any ads in the timeframe defined by the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pruningRefineBy"
            }), " parameter."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ADS_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has performed the ad interactions as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CTA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or has not interacted with a specific call-to-action as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_EVENT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluate the opt-in status of a contact for specific email subscriptions as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EVENT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or does not have a specific event as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FORM_SUBMISSION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or has not filled out a specific form or any form as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FORM_SUBMISSION_ON_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or has not filled out a specific or any form on a specific page as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_LIST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a record is or is not a member of a specific list, import, or workflow as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAGE_VIEW"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or has not viewed a specific page as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVACY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact does or does not have privacy consent for a specific privacy type as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PROPERTY"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Evaluates whether a record’s property value satisfies the property filter operation as defined by the filter. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#property-filter-operations",
              children: "property filter operations"
            }), " section for more details."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SURVEY_MONKEY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or has not responded to a specific Survey Monkey survey as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SURVEY_MONKEY_VALUE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or has not responded to a specific Survey Monkey survey’s question with a specified value as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBINAR"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evaluates whether a contact has or has not registered or attended any webinars or a specific webinar as defined by the filter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATION_EVENT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integration event filters can be used to filter specific contacts based on whether or not they have interacted with integration events that have properties as specified by the filter lines."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Property filter operations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When filtering for records with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROPERTY"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "INTEGRATION_EVENT"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SURVEY_MONKEY_VALUE"
      }), " filter type, you'll include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "operation"
      }), " object to define the parameters of the filter. This object can contain the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "operationType"
        }), ": the type of operator that you're filtering by (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "NUMBER"
        }), "). Each type of property supports a set of operation types, and each operation type supports a set of operators, which you'll define with the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "operator"
        }), " field. (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_NOT"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "operator"
        }), ": the operator that will be applied to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "operationType"
        }), " (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_NOT"
        }), "). Each property type supports a set of operators. Learn more about property types and their supporter operators in the table below."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "value"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), ": the value or values to filter by. Some operators can accept one value, while others can accept multiple values in an array."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "includeObjectsWithNoValueSet"
        }), ": defines how the operation should treat records that do not have a value set for the defined property.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", a record without a value for the evaluated property will be ", (0, _jsxRuntime.jsx)("u", {
              children: "accepted"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " (default), a record without a value for the evaluated property will be ", (0, _jsxRuntime.jsx)("u", {
              children: "rejected"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the filter below would filter for contacts with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " property value of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "John"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"firstname\",\n      \"operation\": {\n        \"operationType\": \"MULTISTRING\",\n        \"operator\": \"IS_EQUAL_TO\",\n        \"values\": [\"John\"]\n      }\n    }\n  ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The table below gives an overview of the available operation types. For more information, along with example code, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/lists/list-definitions#property-operation-definitions",
        children: "property operation definitions reference"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "OperationType"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Supported operators"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ALL_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine whether a property value is known or is unknown as defined by the property operation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BOOL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine whether a current (or historical) boolean property value is or is not (or has or has not) equaled a specific value."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ENUMERATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine whether an enumeration/multi-select property value is any of, is none of, is exactly, is not exactly, contains all of, does not contain all of, has ever been any of, has never been any of, has ever been exactly, has never been exactly, has ever contained all, or has never contained all of a given set of values as defined by the property operation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MULTISTRING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine whether a string property value is equal to, is not equal to, contains, does not contain, starts with, or ends with any of a given set of values as defined by the property operation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NUMBER"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine whether a current (or historical) number property value is or is not (or has or has not) equaled a specific value as defined by the property operation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "STRING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine whether a current (or historical) string property value is or is not (or has or has not) equaled a specific value as defined by the property operation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TIME_POINT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine if a property has been updated before or after a specific time. That time can be specified as a specific date or relative to the current day. This operation also allows the comparison of two properties or their last updated time."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TIME_RANGED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine if a property has been updated between or outside of two specific times. These times can be specified as a specific date or relative to the current day."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "TIME_POINT and TIME_RANGED examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, review some examples when using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TIME_POINT"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TIME_RANGED"
      }), " parameter. These parameters can be used in both time-referenced and property-referenced requests. For more information, see the"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Is equal to date"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The request below filters for ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Last activity date is equal to 03/11/2024 (EDT)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Sample Date Equal\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"DYNAMIC\",\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filters\": [\n          {\n            \"property\": \"notes_last_updated\",\n            \"operation\": {\n              \"operator\": \"IS_BETWEEN\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"lowerBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"upperBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"propertyParser\": \"VALUE\",\n              \"lowerBoundTimePoint\": {\n                \"timeType\": \"DATE\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"year\": 2024,\n                \"month\": 3,\n                \"day\": 11,\n                \"hour\": 0,\n                \"minute\": 0,\n                \"second\": 0,\n                \"millisecond\": 0\n              },\n              \"upperBoundTimePoint\": {\n                \"timeType\": \"DATE\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"US/Eastern\",\n                \"year\": 2024,\n                \"month\": 3,\n                \"day\": 11,\n                \"hour\": 23,\n                \"minute\": 59,\n                \"second\": 59,\n                \"millisecond\": 999\n              },\n              \"type\": \"TIME_RANGED\",\n              \"operationType\": \"TIME_RANGED\"\n            },\n            \"filterType\": \"PROPERTY\"\n          }\n        ],\n        \"filterBranchType\": \"AND\",\n        \"filterBranchOperator\": \"AND\"\n      }\n    ],\n    \"filters\": [],\n    \"filterBranchOperator\": \"OR\",\n    \"filterBranchType\": \"OR\"\n  }\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In Last X Number of Days"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below filters for ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Last activity date is less than 3 days ago"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Sample Date Last 3 days\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"DYNAMIC\",\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filters\": [\n          {\n            \"property\": \"notes_last_updated\",\n            \"operation\": {\n              \"operator\": \"IS_BETWEEN\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"lowerBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"upperBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"propertyParser\": \"VALUE\",\n              \"lowerBoundTimePoint\": {\n                \"timeType\": \"INDEXED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"US/Eastern\",\n                \"indexReference\": {\n                  \"referenceType\": \"TODAY\"\n                },\n                \"offset\": {\n                  \"days\": -3\n                }\n              },\n              \"upperBoundTimePoint\": {\n                \"timeType\": \"INDEXED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"indexReference\": {\n                  \"referenceType\": \"NOW\"\n                }\n              },\n              \"type\": \"TIME_RANGED\",\n              \"operationType\": \"TIME_RANGED\"\n            },\n            \"filterType\": \"PROPERTY\"\n          }\n        ],\n        \"filterBranchType\": \"AND\",\n        \"filterBranchOperator\": \"AND\"\n      }\n    ],\n    \"filters\": [],\n    \"filterBranchOperator\": \"OR\",\n    \"filterBranchType\": \"OR\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In Next X Number of Days"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below filters for ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Last activity date is less than 5 days from now"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Sample Date Next 5 Days\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"DYNAMIC\",\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filters\": [\n          {\n            \"property\": \"notes_last_updated\",\n            \"operation\": {\n              \"operator\": \"IS_BETWEEN\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"lowerBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"upperBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"propertyParser\": \"VALUE\",\n              \"lowerBoundTimePoint\": {\n                \"timeType\": \"INDEXED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"US/Eastern\",\n                \"indexReference\": {\n                  \"referenceType\": \"NOW\"\n                }\n              },\n              \"upperBoundTimePoint\": {\n                \"timeType\": \"INDEXED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"US/Eastern\",\n                \"indexReference\": {\n                  \"referenceType\": \"TODAY\"\n                },\n                \"offset\": {\n                  \"days\": 5\n                }\n              },\n              \"type\": \"TIME_RANGED\",\n              \"operationType\": \"TIME_RANGED\"\n            },\n            \"filterType\": \"PROPERTY\"\n          }\n        ],\n        \"filterBranchType\": \"AND\",\n        \"filterBranchOperator\": \"AND\"\n      }\n    ],\n    \"filters\": [],\n    \"filterBranchOperator\": \"OR\",\n    \"filterBranchType\": \"OR\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Updated or Not Updated in the Last X Days"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below filters for ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Last activity date updated in last 7 days"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter for ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Last activity date ", (0, _jsxRuntime.jsx)("u", {
          children: "not updated in last"
        }), " 7 days"]
      }), ", change the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "operator"
      }), " parameter to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_BETWEEN"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Sample Date Updated Last 7 Days\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"DYNAMIC\",\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filters\": [\n          {\n            \"property\": \"notes_last_updated\",\n            \"operation\": {\n              \"operator\": \"IS_BETWEEN\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"lowerBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"upperBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"propertyParser\": \"UPDATED_AT\",\n              \"lowerBoundTimePoint\": {\n                \"timeType\": \"INDEXED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"indexReference\": {\n                  \"referenceType\": \"TODAY\"\n                },\n                \"offset\": {\n                  \"days\": -7\n                }\n              },\n              \"upperBoundTimePoint\": {\n                \"timeType\": \"INDEXED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"indexReference\": {\n                  \"referenceType\": \"NOW\"\n                }\n              },\n              \"type\": \"TIME_RANGED\",\n              \"operationType\": \"TIME_RANGED\"\n            },\n            \"filterType\": \"PROPERTY\"\n          }\n        ],\n        \"filterBranchType\": \"AND\",\n        \"filterBranchOperator\": \"AND\"\n      }\n    ],\n    \"filters\": [],\n    \"filterBranchOperator\": \"OR\",\n    \"filterBranchType\": \"OR\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Is After Date"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below filters for ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Last activity date is ", (0, _jsxRuntime.jsx)("u", {
          children: "after"
        }), " 03/04/2024 (EST)"]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Sample Date After\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"DYNAMIC\",\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filters\": [\n          {\n            \"property\": \"notes_last_updated\",\n            \"operation\": {\n              \"operator\": \"IS_AFTER\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"timePoint\": {\n                \"timeType\": \"DATE\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"year\": 2024,\n                \"month\": 3,\n                \"day\": 4,\n                \"hour\": 23,\n                \"minute\": 59,\n                \"second\": 59,\n                \"millisecond\": 999\n              },\n              \"endpointBehavior\": \"EXCLUSIVE\",\n              \"propertyParser\": \"VALUE\",\n              \"type\": \"TIME_POINT\",\n              \"operationType\": \"TIME_POINT\"\n            },\n            \"filterType\": \"PROPERTY\"\n          }\n        ],\n        \"filterBranchType\": \"AND\",\n        \"filterBranchOperator\": \"AND\"\n      }\n    ],\n    \"filters\": [],\n    \"filterBranchOperator\": \"OR\",\n    \"filterBranchType\": \"OR\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Is Relative to Today"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below can either represent ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Last activity date is more than x days from ", (0, _jsxRuntime.jsx)("u", {
          children: "now"
        })]
      }), " or ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Last activity date is more than x days ", (0, _jsxRuntime.jsx)("u", {
          children: "ago"
        })]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter for the latter, set the value for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " parameter to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<=0"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Sample Days From Now\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"DYNAMIC\",\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filters\": [\n          {\n            \"property\": \"notes_last_updated\",\n            \"operation\": {\n              \"operator\": \"IS_AFTER\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"timePoint\": {\n                \"timeType\": \"INDEXED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"indexReference\": {\n                  \"referenceType\": \"TODAY\"\n                },\n                \"offset\": {\n                  \"days\": 2\n                }\n              },\n              \"endpointBehavior\": \"EXCLUSIVE\",\n              \"propertyParser\": \"VALUE\",\n              \"type\": \"TIME_POINT\",\n              \"operationType\": \"TIME_POINT\"\n            },\n            \"filterType\": \"PROPERTY\"\n          }\n        ],\n        \"filterBranchType\": \"AND\",\n        \"filterBranchOperator\": \"AND\"\n      }\n    ],\n    \"filters\": [],\n    \"filterBranchOperator\": \"OR\",\n    \"filterBranchType\": \"OR\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Is Before or After another property (value or last updated)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below compares the values where ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Last activity date is ", (0, _jsxRuntime.jsx)("u", {
          children: "before"
        }), " Latest Open Lead Date"]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter for ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Last activity date is ", (0, _jsxRuntime.jsx)("u", {
          children: "after"
        }), " Latest Open Lead Date"]
      }), ": set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "operator"
      }), " value to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_AFTER"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter for when the Latest Open Lead Date was updated: set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "referenceType"
      }), " value to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UPDATED_AT"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Sample Property Before\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"DYNAMIC\",\n  \"filterBranch\": {\n    \"filterBranches\": [\n      {\n        \"filterBranches\": [],\n        \"filters\": [\n          {\n            \"property\": \"notes_last_updated\",\n            \"operation\": {\n              \"operator\": \"IS_BEFORE\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"timePoint\": {\n                \"timeType\": \"PROPERTY_REFERENCED\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"US/Eastern\",\n                \"property\": \"hs_latest_open_lead_date\",\n                \"referenceType\": \"VALUE\"\n              },\n              \"endpointBehavior\": \"EXCLUSIVE\",\n              \"propertyParser\": \"VALUE\",\n              \"type\": \"TIME_POINT\",\n              \"operationType\": \"TIME_POINT\"\n            },\n            \"filterType\": \"PROPERTY\"\n          }\n        ],\n        \"filterBranchType\": \"AND\",\n        \"filterBranchOperator\": \"AND\"\n      }\n    ],\n    \"filters\": [],\n    \"filterBranchType\": \"OR\",\n    \"filterBranchOperator\": \"OR\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Refine by operation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are two types of refine by operations that can be used in certain filters:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "pruningRefineBy"
        }), ": refine the data set to a particular timeframe."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "coalescingRefineBy"
        }), ": determines whether the record ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "PASSES"
        }), " the filter the number of times defined."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Filters only support up to one refine by operation at a time, even if some filters allow for both a coalescing and pruning refine by operation to be passed in. This is enforced by HubSpot's API."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pruning Refine By operations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pruning refine by operations are used to narrow down the dataset that will be used for filter evaluation by refining the dataset to a particular timeframe. Pruning refine by operations are classified into two types: relative and absolute."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Relative"
        }), ": narrow the dataset down based on a time offset of a number of days or weeks in the past or in the future."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Absolute"
        }), ": narrow the dataset down based on the data being before or after a specific timestamp"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For both relative and absolute refine by operations, there are ranged and comparative derivatives."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Absolute Comparative timestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Used to narrow the relevant dataset down based on whether the timestamp of the data being evaluated is before or after a specific timestamp as defined by the refine by operation."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"type\": \"ABSOLUTE_COMPARATIVE\",\n  \"comparison\": \"BEFORE\",\n  \"timestamp\": 1698915170126\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "comparison"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the data's timestamp is before or after the defined ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timestamp"
            }), ". Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BEFORE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "AFTER"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Absolute Ranged timestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Used to narrow the relevant dataset down based on whether the timestamp of the data being evaluated is between or is not between an upper and lower bound timestamp as defined by the refine by operation."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"type\": \"ABSOLUTE_RANGED\",\n  \"rangeType\": \"BETWEEN\",\n  \"lowerTimestamp\": 1698915170126,\n  \"upperTimestamp\": 1682406908449\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rangeType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the data's timestamp is between or not between the defined ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lowerTimestamp"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "upperTimestamp"
            }), ". Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_BETWEEN"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Relative Comparative timestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Used to narrow the relevant dataset down based on whether the timestamp of the data being evaluated is before or after a certain number of days or weeks in the past or future relative to the current timestamp as defined by the refine by operation."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n    \"type\": \"RELATIVE_COMPARATIVE\",\n    \"comparison\": \"BEFORE\",\n    \"timeOffset\": [\n \"offsetDirection\": \"PAST\",\n    \"timeUnit\": \"DAYS\",\n    \"amount\": 4\n],\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "comparison"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the data's timestamp is before or after the defined ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeOffset"
            }), " values. Values include: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BEFORE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "AFTER"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offsetDirection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAST"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FUTURE"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeUnit"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DAYS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEEKS"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A number value."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Relative Ranged timestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Used to narrow the relevant dataset down based on whether the timestamp of the data being evaluated is between or is not between an upper and lower bound time offset relative to the current timestamp. The time offsets are a certain number of days or weeks in the past or the future as defined by the refine by operation."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n    \"type\": \"RELATIVE_RANGED\",\n    \"rangeType\": \"BETWEEN\",\n    \"timeOffset\": [\n \"offsetDirection\": \"PAST\",\n    \"timeUnit\": \"DAYS\",\n    \"amount\": 4\n],\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rangeType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the data's timestamp is between or not between the defined ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeOffset"
            }), " values. Values include: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_BETWEEN"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offsetDirection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAST"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FUTURE"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeUnit"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DAYS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEEKS"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A number value."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Coalescing Refine By operations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Coalescing refine by operations are used once the filter criteria has been applied to the relevant dataset. The only coalescing refine by operation supported by the Lists API is a “number of occurrences” operation that determines whether an object in the dataset ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "PASSED"
      }), " the filter evaluation at least a minimum number of times and less than a maximum number of times."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"type\": \"NUM_OCCURRENCES\",\n  \"minOccurrences\": 1, // Optional\n  \"maxOccurrences\": 4 // Optional\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NUM_OCCURRENCES"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used to determine whether an object in the relevant dataset PASSED the filter evaluation at least a minimum number of times (or zero times if a minimum is not provided) and at most a maximum number of times (or any number of times if a maximum is not provided) as defined by the refine by operation."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v1 list filters (legacy)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you're using the v1 lists API, the way that you filter lists is similar to the v3 API. However, there are minor differences in syntax and options available. Learn more about v1 list filters below."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Starting May 30, 2025, the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/lists/overview",
          children: "legacy v1 Lists API"
        }), " will be sunset. If you were previously using the v1 Lists API, review the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#migrate-from-v1-to-v3-api-endpoints",
          children: "guide below"
        }), " to transition to the v3 API."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Filter branches are AND / OR clauses that contain sets of filters and other filter branches. The only supported ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterType"
      }), " for v1 lists is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROPERTY"
      }), ". Below,"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"filterBranch\": {\n    \"filterBranchType\": \"AND\",\n    \"filters\": [\n      {\n        \"property\": \"amount\",\n        \"operation\": {\n          \"value\": 50,\n          \"operator\": \"IS_GREATER_THAN\",\n          \"propertyType\": \"number\"\n        },\n        \"filterType\": \"PROPERTY\"\n      }\n    ],\n    \"filterBranches\": []\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "All property types operations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For any property, filters for whether the property value is known or unknown. Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_KNOWN"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_KNOWN"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// alltypes filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"any_property\",\n  \"operation\": {\n    \"propertyType\": \"alltypes\",\n    \"operator\": \"IS_KNOWN\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "String operations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operations for string type properties."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CONTAINS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DOES_NOT_CONTAIN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STARTS_WITH"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ENDS_WITH"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_EVER_BEEN_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_NEVER_BEEN_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_EVER_CONTAINED"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_NEVER_CONTAINED"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// string property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"string_property_name\",\n  \"operation\": {\n    \"propertyType\": \"string\",\n    \"operator\": \"CONTAINS\",\n    \"value\": \"def\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Multi-string operations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operations for multiple string values."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CONTAINS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DOES_NOT_CONTAIN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STARTS_WITH"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ENDS_WITH"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// multi-string property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"string_property_name\",\n  \"operation\": {\n    \"propertyType\": \"multistring\",\n    \"operator\": \"CONTAINS\",\n    \"values\": [\"def\", \"abc\", \"123\"]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Number operations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operations for number type properties."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CONTAINS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DOES_NOT_CONTAIN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STARTS_WITH"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ENDS_WITH"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_EVER_BEEN_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_NEVER_BEEN_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_BETWEEN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_BETWEEN"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// number property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"number_property_name\",\n  \"operation\": {\n    \"propertyType\": \"number\",\n    \"operator\": \"IS_GREATER_THAN\",\n    \"value\": 100\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter for ranges, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lowerBound"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upperBound"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// number property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"datetime_property_name\",\n  \"operation\": {\n    \"propertyType\": \"number-ranged\",\n    \"operator\": \"IS_BETWEEN\",\n    \"lowerBound\": 0,\n    \"upperBound\": 10\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Boolean operations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Operations for boolean type properties. Can only filter for a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_EVER_BEEN_EQUAL_TO, HAS_NEVER_BEEN_EQUAL_TO"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// boolean property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"boolean_property_name\",\n  \"operation\": {\n    \"propertyType\": \"bool\",\n    \"operator\": \"IS_EQUAL_TO\",\n    \"value\": true\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Enumeration operations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operations for enumeration type properties."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IS_NOT_EQUAL_TO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HAS_EVER_BEEN_ANY_OF, HAS_NEVER_BEEN_ANY_OF"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// enumeration property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"enumeration_property_name\",\n  \"operation\": {\n    \"propertyType\": \"enumeration\",\n    \"operator\": \"IS_ANY_OF\",\n    \"values\": [\"abc\", \"def\", \"xyz\"]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datetime operations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are five different operation types for datetime properties, as set by the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyType"
      }), " field:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime"
        }), ": compares the property value to a specific datetime stamp. Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_EQUAL_TO"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_BEFORE_DATE"
        }), " (millisecond of day's start), ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_AFTER_DATE"
        }), " (last millisecond of day's end)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// datetime property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"datetime_property_name\",\n  \"operation\": {\n    \"propertyType\": \"datetime\",\n    \"operator\": \"IS_EQUAL_TO\",\n    \"timestamp\": 1504703360618\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime-comparative"
        }), ": compares the property value to another other datetime property on the contact record. Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_BEFORE"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_AFTER"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// datetime comparative property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"datetime_property_name\",\n  \"operation\": {\n    \"propertyType\": \"datetime-comparative\",\n    \"operator\": \"IS_AFTER\",\n    \"comparisonPropertyName\": \"other_datetime_property\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime-ranged"
        }), ": compares the property value to a specific timestamp range. Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_BETWEEN"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_NOT_BETWEEN"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// datetime range property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"datetime_property_name\",\n  \"operation\": {\n    \"propertyType\": \"datetime-ranged\",\n    \"operator\": \"IS_BETWEEN\",\n    \"lowerBoundTimestamp\": 1,\n    \"upperBoundTimestamp\": 2\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime-rolling"
        }), ": compares the property value to a rolling number of days. Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_LESS_THAN_X_DAYS_AGO"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_MORE_THAN_X_DAYS_AGO, IS_LESS_THAN_X_DAYS_FROM_NOW"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_MORE_THAN_X_DAYS_FROM_NOW"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// datetime rolling property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"datetime_property_name\",\n  \"operation\": {\n    \"propertyType\": \"datetime-rolling\",\n    \"operator\": \"IS_LESS_THAN_X_DAYS_AGO\"\n    \"numberOfDays\": 5,\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "rolling-property-updated"
        }), ": compares the last time the property was updated to a rolling number of days. Available operators are: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "UPDATED_IN_LAST_X_DAYS"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "NOT_UPDATED_IN_LAST_X_DAYS"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// datetime rolling property filter\n{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"datetime_property_name\",\n  \"operation\": {\n    \"propertyType\": \"rolling-property-updated\",\n    \"operator\": \"UPDATED_IN_LAST_X_DAYS\",\n    \"numberOfDays\": 3\n  }\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}