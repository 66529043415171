'use es6';

import namespacedKeyMirror from '../../lib/utils/namespacedKeyMirror';
import * as DocClient from './api';
import { LANGUAGE } from './constants/LocalStorageKeys';
import * as ProtectedLocalStorage from 'developer-experience-shared-components/utils/protectedLocalStorage';
import { mockPropertiesOasSpec, mockCompaniesOasSpec } from '../../mockOasSpecs';
export const SpecificationActionTypes = namespacedKeyMirror({
  SPECIFICATION_FETCH_STARTED: null,
  SPECIFICATION_FETCH_SUCCEEDED: null,
  SPECIFICATION_FETCH_FAILED: null,
  FORM_DATA_UPDATED: null
}, 'specification');
export const setLanguage = language => ProtectedLocalStorage.setItem(LANGUAGE, language);
export function callFetchFunctionIfNotAMock(dispatch, fetchFunction, apiPath) {
  if (apiPath === 'mockPropertiesOasSpec') {
    dispatch({
      type: SpecificationActionTypes.SPECIFICATION_FETCH_SUCCEEDED,
      payload: {
        data: mockPropertiesOasSpec
      }
    });
    return Promise.resolve();
  } else if (apiPath === 'mockCompaniesOasSpec') {
    dispatch({
      type: SpecificationActionTypes.SPECIFICATION_FETCH_SUCCEEDED,
      payload: {
        data: mockCompaniesOasSpec
      }
    });
    return Promise.resolve();
  } else {
    return fetchFunction();
  }
}
function abstractFetchSpecification(fetch, apiPath, ...rest) {
  return dispatch => {
    dispatch({
      type: SpecificationActionTypes.SPECIFICATION_FETCH_STARTED
    });
    const fetchFunction = () => {
      return fetch(apiPath, ...rest).then(resp => {
        dispatch({
          type: SpecificationActionTypes.SPECIFICATION_FETCH_SUCCEEDED,
          payload: {
            data: resp
          }
        });
      }).catch(error => {
        dispatch({
          type: SpecificationActionTypes.SPECIFICATION_FETCH_FAILED,
          payload: {
            error
          }
        });
      }).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
    };
    callFetchFunctionIfNotAMock(dispatch, fetchFunction, apiPath);
  };
}
export const fetchSpecification = endpoint => abstractFetchSpecification(DocClient.fetchSpecification, endpoint);
export const fetchCustomSpecification = (url, isInternal) => abstractFetchSpecification(DocClient.fetchCustomSpecification, url, isInternal);
export function updateFormData(formData, docId) {
  return dispatch => {
    dispatch({
      type: SpecificationActionTypes.FORM_DATA_UPDATED,
      payload: {
        formData,
        docId
      }
    });
  };
}