"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160619359497;
const slug = exports.slug = 'guides/api/settings/users/user-details';
const title = exports.title = 'Account | Users API';
const name = exports.name = 'Users API';
const metaDescription = exports.metaDescription = "A user object stores information such as a user's working hours, timezone, additional phone number, and job title. The user endpoints allow you to manage this data and sync it between HubSpot and other systems. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "retrieve-users",
  "label": "Retrieve users",
  "parent": null
}, {
  "depth": 0,
  "id": "update-users",
  "label": "Update users",
  "parent": null
}, {
  "depth": 0,
  "id": "user-properties",
  "label": "User properties",
  "parent": null
}, {
  "depth": 1,
  "id": "working-hours",
  "label": "Working hours",
  "parent": "user-properties"
}, {
  "depth": 1,
  "id": "out-of-office-hours",
  "label": "Out of office hours",
  "parent": "user-properties"
}, {
  "depth": 1,
  "id": "language-skills",
  "label": "Language skills",
  "parent": "user-properties"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Users"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use this API to fetch information about users in the account, along with updating their working hours, timezone, additional phone number, and job title properties. This API can be especially useful for syncing HubSpot user data with external workforce management tools."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, use these endpoints keep a user's working hours in sync with an external scheduling system."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about objects, records, properties, and associations APIs in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM"
      }), " guide. For more general information about objects and records in HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "learn how to manage your CRM database"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve users"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the information you need, there are a few ways to retrieve HubSpot users:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all users, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/users/"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a specific user, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the above URL and specify a user ID. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/users/207838823235"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a batch of users by ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/users/batch/read"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve users that meet a specific set of criteria, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/users/search"
        }), " and include search filters in the request body. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "searching the CRM"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": in a response for the users API, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_object_id"
        }), " are the same and represent a user ", (0, _jsxRuntime.jsx)("u", {
          children: "only"
        }), " in the HubSpot account from which the data was requested. This is different than the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " values in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "user provisioning API"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_internal_user_id"
        }), ") which refers to a user across all accounts, and in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/owners",
          children: "owners API"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot_owner_id"
        }), ") which refers to a user as an owner of records."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the following response returns users, their unique identifiers within the selected HubSpot account, and information about when they were created or modified:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"207838823235\",\n      \"properties\": {\n        \"hs_createdate\": \"2021-01-10T20:36:06.761Z\",\n        \"hs_lastmodifieddate\": \"2023-08-29T18:17:55.697Z\",\n        \"hs_object_id\": \"207838823235\"\n      },\n      \"createdAt\": \"2021-01-10T20:36:06.761Z\",\n      \"updatedAt\": \"2023-08-29T18:17:55.697Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"207840253600\",\n      \"properties\": {\n        \"hs_createdate\": \"2017-12-22T12:22:12.212Z\",\n        \"hs_lastmodifieddate\": \"2023-08-29T18:17:55.697Z\",\n        \"hs_object_id\": \"207840253600\"\n      },\n      \"createdAt\": \"2017-12-22T12:22:12.212Z\",\n      \"updatedAt\": \"2023-08-29T18:17:55.697Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To return specific properties, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter in the request URL along with comma-separated property names. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#user-properties",
        children: "user properties below"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL would result in the response below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/users?properties=hs_job_title,hs_additional_phone"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"207838823235\",\n      \"properties\": {\n        \"hs_additional_phone\": \"+1123456780\",\n        \"hs_createdate\": \"2021-01-10T20:36:06.761Z\",\n        \"hs_job_title\": \"CEO\",\n        \"hs_lastmodifieddate\": \"2023-08-29T18:17:55.697Z\",\n        \"hs_object_id\": \"207838823235\"\n      },\n      \"createdAt\": \"2021-01-10T20:36:06.761Z\",\n      \"updatedAt\": \"2023-08-29T18:17:55.697Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"207840253600\",\n      \"properties\": {\n        \"hs_additional_phone\": \"+1238675309\",\n        \"hs_createdate\": \"2021-01-10T20:36:06.761Z\",\n        \"hs_job_title\": \"Vice President\",\n        \"hs_lastmodifieddate\": \"2023-08-29T18:17:55.697Z\",\n        \"hs_object_id\": \"207838823235\"\n      },\n      \"createdAt\": \"2017-12-22T12:22:12.212Z\",\n      \"updatedAt\": \"2023-08-29T18:17:55.697Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For the batch read endpoint, you can either retrieve users by their ID or by another ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "unique identifier property"
      }), " by including an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to read a batch of users, your request could look like either of the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with internal ID\n{\n  \"properties\": [\"hs_job_title\", \"hs_additional_phone\"],\n  \"inputs\": [\n    {\n      \"id\": \"207838823235\"\n    },\n    {\n      \"id\": \"207840253600\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"hs_job_title\", \"hs_additional_phone\"],\n  \"idProperty\": \"externalIdProperty\",\n  \"inputs\": [\n    {\n      \"id\": \"0001111\"\n    },\n    {\n      \"id\": \"0001112\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update users"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can update users by ID individually or in batches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update an individual user, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/users/{userId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update a batch of users, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/users/batch/update"
        }), ", including the user IDs or unique ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "idProperty"
        }), " in the request body as shown in the section above."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For each endpoint, you'll need to include a request body that contains the properties you want to update."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the request body below would update a user's timezone and working hours:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body\n{\n  \"properties\": {\n    \"hs_standard_time_zone\": \"America/Detroit\",\n    \"hs_working_hours\": \"[{\\\"days\\\":\\\"SATURDAY\\\",\\\"startMinute\\\":540,\\\"endMinute\\\":1020},{\\\"days\\\":\\\"WEDNESDAY\\\",\\\"startMinute\\\":540,\\\"endMinute\\\":1020}]\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Only some properties can be set through this API. See the properties section below for a list of the available propeties."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "User properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a list of all available user properties, you can use the properties API by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/user"
      }), ". Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are the user properties that can be set through this API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_additional_phone"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The user's additional phone number. Users can set this in their ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/user-management/manage-user-properties-and-preferences#set-user-preferences",
              children: "user preferences"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_availability_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The user's availability status. The value must be either ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"available\""
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"away\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_job_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The user's job title. Users can set this in their ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/user-management/manage-user-properties-and-preferences#set-user-preferences",
              children: "user preferences"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_main_user_language_skill"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user's main language skill. The value must match an existing language skill. Learn more about formatting language skills below."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_out_of_office_hours"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user's out of office hours. Out of office hours must not overlap. Each out of office hours' start time must be later than the previous start time."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_secondary_user_language_skill"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user's secondary language skill. The value must match an existing language skill. Learn more about formatting language skills below."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_standard_time_zone"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The user's timezone. Timezone values must use standard ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/List_of_tz_database_time_zones",
              children: "TZ identifiers"
            }), ", such as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"America/New_York\""
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Europe/Dublin\""
            }), ". This property must be set before you can set the user's working hours."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_uncategorized_skills"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user's custom uncategorized skill. This property value must match an existing custom uncatgorized skill in the portal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_working_hours"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user's working hours. This property value is formatted as stringified JSON. Learn more about formatting for working hours below."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Working hours"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_working_hours"
      }), " accepts a stringified JSON value. It consists of an array with an object for each set of working hours."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"[{\\\"days\\\":\\\"VALUE\\\",\\\"startMinute\\\":number,\\\"endMinute\\\":number}]\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Stringified JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The days included in a set of working hours. Values include: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MONDAY_TO_FRIDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "SATURDAY_SUNDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "EVERY_DAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MONDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TUESDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "WEDNESDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "THURSDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FRIDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "SATURDAY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "SUNDAY"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startMinute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Working hours start time in minutes. Must be within the range of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " - ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1440"
            }), ", where ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " represents 12:00AM midnight. For example, a 9:00AM start time would be represented as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "540"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endMinute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Working hours end time in minutes. Follows the same rules as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "startMinute"
            }), ".For example, 5:00PM is represented as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1020"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_standard_time_zone"
          }), " property must be set before you can set working hours."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Working hours cannot overlap."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if a user works Monday through Friday, 9:00AM to 5:00PM, you would format that as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"[{\\\"days\\\":\\\"MONDAY_TO_FRIDAY\\\",\\\"startMinute\\\":540,\\\"endMinute\\\":1020}]\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If a user works Monday 9:00AM to 5:00PM and Saturday 11:00AM to 2:00PM, the array would contain an object to represent each set of working hours:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"[{\\\"days\\\":\\\"MONDAY\\\",\\\"startMinute\\\":540,\\\"endMinute\\\":1020},{\\\"days\\\":\\\"SATURDAY\\\",\\\"startMinute\\\":660,\\\"endMinute\\\":840}]\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Out of office hours"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a user will be unavailable due to scheduled time off, you can set any periods during which they'll be out of office using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_out_of_office_hours"
      }), " property:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The property accepts an array of date ranges, each specified by a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "startTimestamp"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "endTimestamp"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The date ranges cannot overlap with one another, and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "startTimestamp"
        }), " of each date range must be later than the previous ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "startTimestamp"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you wanted to specify out-of-office hours during October 31st 2024 9:00 AM to 5:00 PM and November 28 2024 9:00 AM to 5:00 PM, you'd specify the following value for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_out_of_office_hours"
      }), " property for a user:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"[{\\\"startTimestamp\\\": 17303796000,\\\"endTimestamp\\\": 17304084000},{\\\"startTimestamp\\\": 17328024000,\\\"endTimestamp\\\": 17328312000}]\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Language skills"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_main_user_language_skill"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_secondary_user_language_skill"
      }), " must match an existing language skill. The following JSON array lists all valid options for language skill categories:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Full list of valid options for language skill categories:\n[\n  {\n    \"label\": \"Dansk\",\n    \"value\": \"da\"\n  },\n  {\n    \"label\": \"Deutsch\",\n    \"value\": \"de\"\n  },\n  {\n    \"label\": \"English\",\n    \"value\": \"en\"\n  },\n  {\n    \"label\": \"Español\",\n    \"value\": \"es\"\n  },\n  {\n    \"label\": \"Français\",\n    \"value\": \"fr\"\n  },\n  {\n    \"label\": \"Italiano\",\n    \"value\": \"it\"\n  },\n  {\n    \"label\": \"Nederlands\",\n    \"value\": \"nl\"\n  },\n  {\n    \"label\": \"Norsk\",\n    \"value\": \"no\"\n  },\n  {\n    \"label\": \"Polski\",\n    \"value\": \"pl\"\n  },\n  {\n    \"label\": \"Português\",\n    \"value\": \"ptbr\"\n  },\n  {\n    \"label\": \"Suomi\",\n    \"value\": \"fi\"\n  },\n  {\n    \"label\": \"Svenska\",\n    \"value\": \"sv\"\n  },\n  {\n    \"label\": \"中文 - 繁體\",\n    \"value\": \"zhtw\"\n  },\n  {\n    \"label\": \"日本語\",\n    \"value\": \"ja\"\n  }\n]\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}