"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601073957;
const slug = exports.slug = 'getting-started/tools-to-help-you-build';
const title = exports.title = 'Tools zum Erstellen und Entwickeln';
const name = exports.name = 'Tools zum Erstellen und Entwickeln (Entwicklerdokumente 2024)';
const metaDescription = exports.metaDescription = 'Lernen Sie die verschiedenen verfügbaren Tools und Ressourcen kennen, die Ihnen beim Erstellen und Entwickeln in HubSpot helfen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubspot-cli",
  "label": "HubSpot-CLI",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-vs-code-erweiterung",
  "label": "HubSpot VS Code-Erweiterung",
  "parent": null
}, {
  "depth": 0,
  "id": "client-bibliotheken",
  "label": "Client-Bibliotheken",
  "parent": null
}, {
  "depth": 0,
  "id": "postman",
  "label": "Postman",
  "parent": null
}, {
  "depth": 0,
  "id": "projekte-beta",
  "label": "Projekte (BETA)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tools zum Erstellen und Entwickeln"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot stellt eine Reihe von Tools zur Verfügung, die Sie beim Erstellen auf seiner Entwickler-Plattform unterstützen. Die APIs von HubSpot sind flexibel, und Sie können immer Ihre bevorzugte Entwicklungsumgebung nutzen, um eine Integration zu erstellen. Die unten aufgeführten Tools sind jedoch für alle Entwickler verfügbar, um die Produktivität zu steigern und Ihren Entwicklungs-Workflow zu optimieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot-CLI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie auf Grundlage von HubSpots CMS Hub entwickeln, können Sie das HubSpot CLI installieren, um lokal in HubSpot zu entwickeln. Nach der Installation können Sie Ihre bevorzugten Tools in Ihrer bevorzugten Umgebung verwenden. Sie können auch eine kontinuierliche Integration mit einem GitHub-Konto einrichten, um Änderungen nachzuverfolgen und die Zusammenarbeit mit anderen Entwicklern in Ihrem Unternehmen zu erleichtern."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "das HubSpot CLI installieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot VS Code-Erweiterung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit der HubSpot Visual Studio Code-Erweiterung können Sie Ihren Entwicklungs-Workflow zentralisieren, ohne VS Code verlassen zu müssen. Nach der Installation können Sie das HubSpot CLI direkt zur Authentifizierung und Verwaltung Ihrer verknüpften Accounts verwenden und die HubL-Syntaxhervorhebung sowie die Unterstützung für die automatische Vervollständigung nutzen. Sie können Dateien auch mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "Design-Manager"
      }), " Ihres Standard-HubSpot-Accounts synchronisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
        children: "die HubSpot VS Code-Erweiterung installieren und verwenden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Client-Bibliotheken"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot stellt Client-Bibliotheken zur Verfügung, damit Sie bei der Verwendung von HubSpot-APIs mit Ihrer bevorzugten Programmiersprache arbeiten können. Client-Bibliotheken sind in JavaScript, Ruby, Python und PHP verfügbar. Jede Client-Bibliothek ermöglicht es Ihnen, Funktionsaufrufe zum Lesen, Bearbeiten, Erstellen und Löschen von Daten in Ihrem HubSpot-Account aufzurufen, anstatt API-Anfragen manuell erstellen zu müssen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das Einrichten und Verwenden von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-php",
        children: "PHP"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-ruby",
        children: "Ruby"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-python",
        children: "Python"
      }), "-Client-Bibliotheken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Postman"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Postman ist ein funktionsreicher API-Client, mit dem Sie einfach authentifizierte Anfragen an HubSpot durchführen können. Sie können auch kuratierte Sammlungen aller öffentlichen API-Endpunkte von HubSpot durchsuchen. Diese Sammlungen werden von HubSpot regelmäßig gepflegt, um die Spezifikationen für alle Felder für Anfragen und Antworten auf dem neuesten Stand zu halten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Laden Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/",
        children: "Postman-Client"
      }), " herunter, und durchsuchen Sie dann die offiziellen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/hubspot",
        children: "Postman-Sammlungen von HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Projekte (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Projekte sind ein High-Level-Container, den Sie zur Anpassung des CRM von HubSpot verwenden können. In Verbindung mit dem HubSpot CLI können Sie private Apps erstellen und bereitstellen, UI-Erweiterungen erstellen und serverlose Funktionen entwickeln, um Daten in benutzerdefinierten Komponenten im CRM dynamisch zu lesen oder zu schreiben. Nach der Bereitstellung können Sie Ihr Projekt in HubSpot verwalten, den Build-Verlauf überprüfen und API-Aufrufe überwachen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr darüber, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "ein komplett neues Projekt erstellen"
      }), ", oder folgen Sie der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "Schnellstartanleitung für UI-Erweiterungen"
      }), ". Wenn Sie nicht sicher sind, wo Sie anfangen sollen, können Sie sich auch die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "Beispielprojekte von HubSpot"
      }), " ansehen."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}