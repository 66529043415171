"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125376;
const slug = exports.slug = 'reference/cms/hubl/operators-and-expression-tests';
const title = exports.title = 'Operatoren und Ausdrucktests';
const name = exports.name = 'EMEA DACH (de) Operators & Expression Tests';
const metaDescription = exports.metaDescription = 'Um die Logik und Funktionalität Ihrer Vorlagen zu erweitern, unterstützt HubL mehrere wichtige Operatoren und Ausdruckstests. Operatoren ermöglichen es Ihnen, mathematische Funktionen auszuführen, Vergleiche durchzuführen, die Logik von Vorlagen komplexer zu machen und die Darstellung durch Markup zu ändern. Darüber hinaus enthält dieser Artikel eine umfassende Liste von Ausdruckstests, die in HubL verwendet werden können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "operatoren",
  "label": "Operatoren",
  "parent": null
}, {
  "depth": 1,
  "id": "mathematisch",
  "label": "Mathematisch",
  "parent": "operatoren"
}, {
  "depth": 1,
  "id": "vergleich",
  "label": "Vergleich",
  "parent": "operatoren"
}, {
  "depth": 1,
  "id": "logisch",
  "label": "Logisch",
  "parent": "operatoren"
}, {
  "depth": 1,
  "id": "andere-hubl-operatoren",
  "label": "Andere HubL-Operatoren",
  "parent": "operatoren"
}, {
  "depth": 0,
  "id": "ausdrucktests",
  "label": "Ausdrucktests",
  "parent": null
}, {
  "depth": 1,
  "id": "boolean",
  "label": "boolean",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "containing",
  "label": "containing",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "containingall",
  "label": "containingall",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "defined",
  "label": "defined",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "divisibleby",
  "label": "divisibleby",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "equalto",
  "label": "equalto",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "even",
  "label": "even",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "float",
  "label": "float",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "integer",
  "label": "integer",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "iterable",
  "label": "iterable",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "lower",
  "label": "lower",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "mapping",
  "label": "mapping",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "none",
  "label": "none",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "number",
  "label": "number",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "odd",
  "label": "odd",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "sameas",
  "label": "sameas",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "sequence",
  "label": "sequence",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "string",
  "label": "string",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "string_containing",
  "label": "string_containing",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "string_startingwith",
  "label": "string_startingwith",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "truthy",
  "label": "truthy",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "undefined",
  "label": "undefined",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "upper",
  "label": "upper",
  "parent": "ausdrucktests"
}, {
  "depth": 1,
  "id": "within",
  "label": "within",
  "parent": "ausdrucktests"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Operatoren und Ausdrucktests"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Logik und Funktionalität Ihrer Vorlagen zu erweitern, unterstützt HubL mehrere wichtige Operatoren und Ausdruckstests. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#operators",
        children: "Operatoren"
      }), " ermöglichen es Ihnen, mathematische Funktionen auszuführen, Vergleiche durchzuführen, die Logik von Vorlagen komplexer zu machen und die Darstellung durch Markup zu ändern. Darüber hinaus enthält dieser Artikel eine umfassende Liste von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "Ausdruckstests"
      }), ", die in HubL verwendet werden können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Operatoren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operatoren sind Symbole, die den HubL-Compiler anweisen, verschiedene Operationen auszuführen, die zur endgültigen Markup-Ausgabe führen. Im folgenden Abschnitt finden Sie eine Liste aller unterstützten HubL-Operatoren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mathematisch"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mathematische Standardoperatoren können zur Berechnung von Werten im Kontext einer Vorlage verwendet werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "+"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Fügt zwei Objekte zusammen. Sie verwenden dies im Allgemeinen für die Addition von Zahlen. Wenn Sie versuchen, Listenzeichenfolgen zu verketten, sollten Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "~"
            }), " verwenden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "-"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subtrahiert eine Zahl von einer anderen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "/"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dividiert Zahlen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "%"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt den Rest aus der Division von Zahlen zurück."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "//"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Teilt zwei Zahlen und gibt das gerundete ganzzahlige Ergebnis zurück. Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;%raw%&#125;&#123;&#123; 20 // 7 &#125;&#125;&#123;%endraw%&#125;"
            }), " ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "*"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multipliziert Zahlen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "**"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nimmt den linken Operanden hoch dem rechten Operanden."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num + my_number }}<br/>  <!-- 11 + 2 = 13 -->\n{{ my_num - my_number }}<br/>  <!-- 11 - 2 = 9 -->\n{{ my_num / my_number }}<br/>  <!-- 11 / 2 = 5.5 -->\n{{ my_num % my_number }}<br/>  <!-- 11 % 2 = 1 -->\n{{ my_num // my_number }}<br/> <!-- 11 // 2 = 5 -->\n{{ my_num * my_number }}<br/>  <!-- 11 * 2 = 22 -->\n{{ my_num ** my_number }}<br/> <!-- 11 ** 2 = 121 -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "13 9 5.5 1 5 22 121\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vergleich"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vergleichsoperatoren können verwendet werden, um Werte für Vorlagenlogik auszuwerten. Einige Beispiele für die Verwendung von Vergleichsoperatoren in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if-Anweisungen finden Sie hier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Kurzschreibweise"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "=="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gleich. Wird als true ausgewertet, wenn zwei Objekte gleich sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "!="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ungleich. Wird als true ausgewertet, wenn zwei Objekte nicht gleich sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Größer als. Wird als true ausgewertet, wenn die linke Seite größer als die rechte Seite ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Größer als oder gleich. Wird als true ausgewertet, wenn die linke Seite größer oder gleich der rechten Seite ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kleiner als. Wird als true ausgewertet, wenn die linke Seite niedriger als die rechte Seite ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kleiner als oder gleich. Wird als true ausgewertet, wenn die linke Seite kleiner oder gleich der rechten Seite ist."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Die Kurzschreibweise der Vergleichsoperatoren kann in Filtern verwendet werden, bei denen ein Ausdruck getestet wird, z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|selectattr()"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num == my_number }}<br/>  <!-- false -->\n{{ my_num != my_number }}<br/>  <!-- true -->\n{{ my_num > my_number }}<br/>   <!-- true -->\n{{ my_num >= my_number }}<br/>  <!-- true -->\n{{ my_num < my_number }}<br/>   <!-- false -->\n{{ my_num <= my_number }}<br/>  <!-- false -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "false true true true false false\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logisch"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit logischen Operatoren können Sie mehrere Ausdrücke in einer einzigen Anweisung kombinieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "and"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt true zurück, wenn der linke und der rechte Operand true sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "or"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt true zurück, wenn der linke oder der rechte Operand true ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "not"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Negiert eine Aussage und wird in Verbindung mit is verwendet. Siehe folgende Beispiele."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "(expr)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gruppieren Sie einen Ausdruck für die Reihenfolge der Operationen. Zum Beispiel: (10 - 2) * Variable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "?:"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements",
              children: "ternäre Operator"
            }), " akzeptiert 3 Argumente (Ausdruck, „true“-Bedingung, „false“-Bedingung). Wertet einen Ausdruck aus und gibt die entsprechende Bedingung zurück."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Andere HubL-Operatoren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend sind weitere wichtige HubL-Operatoren aufgeführt, die zur Durchführung verschiedener Aufgaben verwendet werden können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "in"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Überprüft, ob ein Wert in einer Sequenz enthalten ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "is"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Führt einen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
              children: "Ausdruckstest"
            }), " durch."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "|"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wendet einen Filter an."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "~"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verkettet Werte."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ausdrucktests"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ausdruckstests sind verschiedene boolesche Bedingungen, die mithilfe von logischen Operatoren ausgewertet werden können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "boolean"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "boolean"
      }), "-Ausdruckstest wird überprüft, ob das Objekt boolesch ist (im strengen Sinne, nicht in seiner Fähigkeit, zu einem wahrheitsgemäßen Ausdruck auszuwerten)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set isActive = false %}\n\n{% if isActive is boolean %}\nisActive is a boolean\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "isActive is a boolean\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containing"
      }), "-Ausdruckstest wird überprüft, ob eine Listenvariable einen Wert enthält."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containing 2 %}\n\tSet contains 2!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containingall"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containingall"
      }), "-Ausdruckstest wird überprüft, ob eine Listenvariable alle Werte einer anderen Liste enthält."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containingall [2, 3] %}\n\tSet contains 2 and 3!\n{% endif %}\n\n{% if numbers is containingall [2, 4] %}\n\tSet contains 2 and 4!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2 and 3!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "defined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "defined"
      }), "-Ausdruckstest wird überprüft, ob eine Variable im Kontext der Vorlage definiert ist. Sie können diesen Ausdruckstest zwar verwenden, aber beim Schreiben einer if-Anweisung ohne Operatoren wird standardmäßig geprüft, ob die Variable definiert ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In dem folgenden Beispiel wird der color-Parameter eines Farbmoduls getestet. Hätte der color-Parameter keinen Wert, würde die Vorlage standardmäßig eine schwarze Hintergrundfarbe darstellen. Wenn er angegeben ist, wird die vom Benutzer festgelegte Hintergrundfarbe dargestellt."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% color \"my_color\" color=\"#930101\", export_to_template_context=True %}\n<style>\n{% if widget_data.my_color.color is defined %}\nbody{\n    background: {{ widget_data.my_color.color }};\n}\n{% else %}\nbody{\n    background: #000;\n}\n{% endif %}\n</style>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<style>\n  body {\n    background: #930101;\n  }\n</style>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "divisibleby"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "divisibleby"
      }), "-Ausdruckstest kann getestet werden, ob ein Objekt durch eine andere Zahl teilbar ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden wird zum Beispiel eine for-Schleife erstellt, die eine Liste von Tierarten durchläuft. Jede Tierart wird in einem div gedruckt, und jedes 5. div hat ein anderes Inline-Styling (Breite:100 %). Dieses Konzept könnte auf einen Blog angewendet werden, in dem für ein bestimmtes Muster von Beiträgen ein anderes Markup gerendert wird. Weitere Informationen zu for-Schleifen und loop.index finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "in diesem Artikel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set animals = [\"lions\", \"tigers\", \"bears\", \"dogs\", \"sharks\"] %}\n{% for animal in animals %}\n    {% if loop.index is divisibleby 5 %}\n    <div style=\"width:100%\">{{animal}}</div>\n    {% else %}\n    <div style=\"width:25%\">{{animal}}</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%\">lions</div>\n<div style=\"width:25%\">tigers</div>\n<div style=\"width:25%\">bears</div>\n<div style=\"width:25%\">dogs</div>\n<div style=\"width:100%\">sharks</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "equalto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "equalto"
      }), "-Ausdruckstest wird überprüft, ob der Wert einer Variablen gleich einer Konstanten oder einer anderen Variablen ist. Sie können auch den Operator == verwenden, um denselben Test durchzuführen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel wird die Breite der Blog-Beiträge auf der Grundlage der Gesamtzahl der Beiträge in der Schleife angepasst. In der Beispielausgabe wird davon ausgegangen, dass der Blog 4 Beiträge enthält."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    {% if loop.length is equalto 2 %}\n        <div style=\"width:50%;\">Post content</div>\n    {% elif loop.length is equalto 3 %}\n        <div style=\"width:33.333332%;\">Post content</div>\n    {% elif loop.length is equalto 4 %}\n        <div style=\"width:25%;\">Post content</div>\n    {% else %}\n        <div style=\"width:100%;>Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "even"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "even"
      }), "-Ausdruckstest wird überprüft, ob eine numerische Variable eine gerade Zahl ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das folgende Beispiel zeigt eine vereinfachte Blog-Listing-Schleife. Wenn die aktuelle Iteration der Schleife gerade ist, wird dem post item-div die even-post-Klasse zugewiesen. Andernfalls wird die odd-post-Klasse zugewiesen."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is even %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "float"
      }), "-Ausdruckstest wird überprüft, ob eine numerische Variable eine Gleitkommazahl ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 1.20 %}\n{% if quantity is float %}\n  quantity is a floating point number\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is a floating-point number\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "integer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüft, ob eine Variable eine ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ganze Zahl"
      }), " ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 120 %}\n{% if quantity is integer %}\n  quantity is an integer\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is an integer\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "iterable"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüft, ob eine Variable ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterierbar"
      }), " ist und in einer Schleife durchlaufen werden kann."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Beispiel wird eine Variable namens „jobs“ daraufhin überprüft, ob sie durchlaufen werden kann. Da die Variable eine Liste von Jobs enthält, würde die if-Anweisung als true ausgewertet werden, und die Schleife würde ausgeführt werden. Hätte die Variable einen einzelnen Wert enthalten, würde die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if-Anweisung"
      }), " diesen Wert stattdessen mit anderem Markup ausgeben. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "Erfahren Sie mehr über für for-Schleifen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set jobs = [\"Accountant\", \"Developer\", \"Manager\", \"Marketing\", \"Support\"] %}\n\n{% if jobs is iterable %}\n<h3>Available positions</h3>\n<ul>\n{% for job in jobs %}\n    <li>{{ job }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Available position</h3>\n<div class=\"single-position\">{{ jobs }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Available positions</h3>\n<ul>\n  <li>Accountant</li>\n  <li>Developer</li>\n  <li>Manager</li>\n  <li>Marketing</li>\n  <li>Support</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Test ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "lower"
      }), "-Ausdruck wird als true ausgewertet, wenn eine Zeichenkette klein geschrieben ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im folgenden Beispiel wird mithilfe einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements#unless-statements",
        children: "unless-Anweisung"
      }), " und eines lower-Filter sichergestellt, dass eine in einen Textbaustein eingegebene Textzeichenfolge immer klein geschrieben wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Lowercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is lower %}\n{{ widget_data.my_text.value|lower }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text that should be lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "mapping"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mapping"
      }), "-Ausdruckstest wird überprüft, ob ein Objekt ein Dictionary (Wörterbuch) ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel wird überprüft, ob das Kontaktobjekt ein Dictionary ist, was hier auch der Fall ist."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if contact is mapping %}\nThis object is a dictionary.\n{% else %}\nThis object is not a dictionary.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This object is a dictionary.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "none"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "none"
      }), "-Ausdruckstest wird überprüft, ob eine Variable einen Nullwert hat."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"user_email\" path=\"@hubspot/text\" label=\"Enter user email\", value=\"example@hubspot.com\", export_to_template_context=True %}\n{% unless widget_data.user_email.value is none %}\n{{ widget_data.user_email.value }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "example@hubspot.com\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "number"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "number"
      }), "-Ausdruckstest wird überprüft, ob der Wert einer Variablen eine Zahl ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel wird überprüft, ob es sich bei einer Variablen um eine Variable handelt, und wenn ja, wird sie in Millionen umgewandelt."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = 40 %}\n{% if my_var is number %}\n{{ my_var * 1000000 }}\n{% else %}\nmy_var is not a number.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "40000000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "odd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "odd"
      }), "-Ausdruckstest wird überprüft, ob eine numerische Variable eine ungerade Zahl ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden wird dasselbe Beispiel wie der zuvor beschriebene inverse-even-Ausdruckstest verwendet."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is odd %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sameas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sameas"
      }), "-Ausdruckstest wird überprüft, ob zwei Variablen den gleichen Wert haben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel werden zwei Variablen festgelegt und dann wird überprüft, ob sie gleich sind."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set var_one = True %}\n{% set var_two = True %}\n{% if var_one is sameas var_two  %}\nThe variables values are the same.\n{% else %}\nThe variables values are different.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The variables values are the same.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sequence"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sequence"
      }), "-Ausdruckstest ähnelt dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), "-Ausdruckstest darin, dass überprüft wird, ob eine Variable eine Sequenz ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel wird überprüft, ob eine Variable eine Sequenz ist, und dann wird diese Sequenz von Musikgenres durchlaufen."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set genres = [\"Pop\", \"Rock\", \"Disco\", \"Funk\", \"Folk\", \"Metal\", \"Jazz\", \"Country\", \"Hip-Hop\", \"Classical\", \"Soul\", \"Electronica\" ] %}\n{% if genres is sequence %}\n<h3>Favorite genres</h3>\n<ul>\n{% for genre in genres %}\n    <li>{{ genre }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Favorite genre:</h3>\n    <div class=\"single-genre\">{{ genres }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Pop</li>\n  <li>Rock</li>\n  <li>Disco</li>\n  <li>Funk</li>\n  <li>Folk</li>\n  <li>Metal</li>\n  <li>Jazz</li>\n  <li>Country</li>\n  <li>Hip-Hop</li>\n  <li>Classical</li>\n  <li>Soul</li>\n  <li>Electronica</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "string"
      }), "-Ausdruckstest wird überprüft, ob der in einer Variablen gespeicherte Wert ein Text ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel wird überprüft, ob eine Variable eine Zeichenkette ist, und wenn ja, wird ein title-Filter angewendet, um die Großschreibung zu ändern."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = \"title of section\" %}\n{% if my_var is string %}\n{{ my_var|title }}\n{% else %}\nmy_var is not a string\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Title Of Section\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_containing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit diesem Test wird überprüft, ob eine Zeichenfolge in einer anderen Zeichenfolge enthalten ist. Dieser Ausdruckstest wird in Verbindung mit dem Operator „is“ verwendet."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.domain is string_containing \".es\" %}\nMarkup that will only render on content hosted on .es domains\n{% elif content.domain is string_containing \".jp\" %}\nMarkup that will only render on content hosted on .jp domains\n{% else %}\nMarkup that will render on all other domains\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all other domains\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_startingwith"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit diesem Ausdruckstest wird überprüft, ob eine Zeichenkette mit einer bestimmten Zeichenkette beginnt. Er wird in Verbindung mit dem Operator „is“ verwendet."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.slug is string_startingwith \"es/\" %}\nMarkup that will only render on content hosted in a /es/ subdirectory\n{% elif content.slug is string_startingwith \"jp/\" %}\nMarkup that will only render on content hosted in a /jp/ subdirectory\n{% else %}\nMarkup that will render on all subdirectories\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all subdirectories\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "truthy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "truthy"
      }), "-Ausdruckstest wird überprüft, ob ein Ausdruck als true ausgewertet wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel wird ein boolesches Kontrollkästchenmodul verwendet, um eine Warnmeldung anzuzeigen."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% boolean \"check_box\" label=\"Show alert\", value=True, export_to_template_context=True %}\n\n{% if widget_data.check_box.value is truthy %}\n<div class=\"alert\">Danger!</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"alert\">Danger!</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "undefined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "undefined"
      }), "-Ausdruckstest wird überprüft, ob eine Variable im Kontext der Vorlage undefiniert ist. Dieser Test unterscheidet sich von none dadurch, dass undefined true ist, wenn die Variable vorhanden ist, aber keinen Wert hat; none hingegen ist true, wenn die Variable einen Nullwert hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel wird eine Vorlage auf das Vorhandensein der Variablen „my_var“ überprüft."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if my_var is undefined %}\nA variable named \"my_var\" does not exist on this template.\n{% else %}\n{{ my_var }}\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A variable named \"my_var\" does not exist on this template.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "upper"
      }), "-Ausdruckstest wird als true ausgewertet, wenn eine Zeichenkette durchgehend in Großbuchstaben geschrieben ist. Nachfolgend finden Sie ein umgekehrtes Beispiel für den obigen Test des lower-Ausdrucks."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Uppercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is upper %}\n{{ widget_data.my_text.value|upper }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "SOME TEXT THAT SHOULD BE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "within"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "within"
      }), "-Ausdruckstest wird überprüft, ob eine Variable innerhalb einer Liste liegt."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if 2 is within numbers %}\n\t2 is in the list!\n{% endif %}\n\n{% if 4 is within numbers %}\n\t4 is in the list!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2 is in the list!\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}