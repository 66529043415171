"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107553;
const slug = exports.slug = 'guides/apps/marketplace/app-marketplace-listing-requirements';
const title = exports.title = 'App Marketplace | App listing requirements';
const name = exports.name = 'App Listing Requirements';
const metaDescription = exports.metaDescription = "App Partners can now see HubSpot's guidelines and requirements for getting an app listed on the App Marketplace in one place. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "minimum-requirements",
  "label": "Minimum requirements",
  "parent": null
}, {
  "depth": 1,
  "id": "brand-requirements",
  "label": "Brand requirements",
  "parent": "minimum-requirements"
}, {
  "depth": 1,
  "id": "listing-requirements",
  "label": "Listing requirements",
  "parent": "minimum-requirements"
}, {
  "depth": 0,
  "id": "review%2C-feedback%2C-and-approval",
  "label": "Review, feedback, and approval",
  "parent": null
}, {
  "depth": 0,
  "id": "rewards-for-listed-app-partners",
  "label": "Rewards for Listed App Partners",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "App Marketplace listing requirements"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["App listing submissions are manually reviewed by the HubSpot Ecosystem Quality team and will be rejected if they do not meet the criteria outlined below. Once your app meets these requirements, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "build your app listing"
      }), " from within your app developer account by navigating to ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "App Marketplace > Listings > Create listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Minimum requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Single HubSpot app ID:"
          }), " your app must authorize API requests with the public HubSpot app ID (and ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "OAuth client ID"
          }), ") associated with your app listing."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A listing must not redirect to a different public or private app."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Your listed public app must not require another public or private app to function."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Your listed public app must be unique. If you have already listed an app and you want to replace it, you should update the existing app instead of listing a new one."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Do not create multiple apps that solve for the same use case. Apps with similar functionality and use the same APIs should be consolidated into a single app."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "OAuth:"
          }), " your app must use OAuth as its sole authorization method. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "working with OAuth"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Installs:"
          }), " your app must have at least three ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/certification-requirements#:~:text=Active%20installs%20are%20the%20number%20of%20unique%20HubSpot%20production%20accounts%2C%20unaffiliated%20with%20your%20organization%2C%20showing%20successful%20app%20activity%20within%20the%20past%2030%20days.",
            children: "active, unique installs"
          }), ". You won’t be able to submit your app listing without this."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Scopes:"
          }), " you must only request scopes your app needs. ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth#scopes",
            children: "Review your scopes"
          }), " and make sure you’re not asking for unnecessary access. Apps that do this tend to have better conversion rates."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Your app must have ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "advanced scope settings"
            }), " turned on. All required, conditionally required, and optional scopes should be selected to prevent errors. These settings can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#app-developer-accounts",
              children: "developer account"
            }), " that manages your app."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Terms:"
          }), " you must review and agree to the terms in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/app-program-agreement",
            children: "HubSpot's App Partner Program Agreement"
          }), ". This protects you, HubSpot, and our shared customers. You won’t be able to submit your app listing without completing this step."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Restricted industries:"
          }), " your app must not fit or deliver functionality that would exclusively serve customers within any of HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/acceptable-use#Restricted-Industries",
            children: "restricted industries"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Brand requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your app and its associated collateral (documentation, landing pages, etc.) must meet ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/branding-guidelines",
          children: "HubSpot’s Branding Guidelines"
        }), ". For example, capitalize the “S” in “HubSpot” any time you’re referring to HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your app and its associated collateral (documentation, landing pages, etc.) must not infringe ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "HubSpot’s Trademark Usage Guidelines"
        }), ". For example, do not combine HubSpot's name (including “Hub” and “HubSpot”) with your app name or logo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Listing requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you’ve met the minimum requirements, you can submit your app listing. When submitting your app listing, you must completely and accurately fill out all information. These fields are particularly important and failure to meet these requirements will cause your listing to be set to Draft mode only:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The content of your listing should be specific to the integration as opposed to general product information. It should contain information about the value customers can expect specifically from downloading and using this integration. Good examples include: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/calling/aircall",
          children: "Aircall"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/sales-enablement/cloudfiles",
          children: "CloudFiles"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/partner-relationship-management/reveal-191193",
          children: "Reveal"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A link to a publicly available (no sign-in, no paywall) Setup Documentation specific to your HubSpot integration.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Your Setup Guide cannot simply be your homepage or a general knowledge base."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Instead, it must contain the steps to install and configure the integration."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For an example, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://orgcharthub.com/guides/setup",
              children: "OrgChartHub setup guide"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Include a relevant Install button URL that brings customers to a page where they can easily connect your app with HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "URLs for your app’s support resources (support website, HubSpot community forum, case study) must be live, up-to-date, and publicly available."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "URLs for your app’s Terms of Service and Privacy Policy must be live and up-to-date."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "All URL fields have a limit of 250 characters."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Shared data"
        }), ", which lets users know how information will flow between your app and HubSpot, must be accurate, up-to-date, and reflect the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements#:~:text=Webhooks%20API.-,Scopes,-%3A%20You%20must",
          children: "scopes"
        }), " your app requests.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["All objects selected in your OAuth scopes should be documented in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Shared data"
            }), " table."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If your app is requesting both read and write object scopes, the data sync should be advertised as bi-directional for these specific objects."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your App Marketplace listing must contain clear and accurate pricing information.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "At least one pricing plan relevant to your HubSpot integration, which needs to match the information published on your website."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Free pricing plans should only be used for Free forever or Freemium pricing models."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You must include at least one support contact method."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the guidelines listed ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/testing-credentials",
          children: "here"
        }), " for providing testing credentials for your app listing."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Review, feedback, and approval"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you submit your listing, the HubSpot Ecosystem Quality team will complete an initial review within 10 business days. If any of the information provided is incorrect, misleading, or incomplete, we’ll contact you with that feedback. The entire app review and feedback process should take no more than 6o days from the time feedback is shared. As stated in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/app-program-agreement",
        children: "App Marketplace Terms"
      }), ", HubSpot reserves the right to unpublish or refuse publication of your app listing at any time."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rewards for Listed App Partners"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dedicated HubSpot App Marketplace listing"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Priority access to developer support through a dedicated support alias"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Developer community resources, including webinars, forums, and more"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Curated marketing resources, including PR templates and launch guides"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Discounted INBOUND event sponsorship, booths, and tickets"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Discounted software through the HubSpot for Startups seed-stage program"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Monthly newsletter with marketing updates, product releases, and more"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/listing-your-app",
          children: "How to list your app"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/certification-requirements",
          children: "App certification requirements"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "API reference documentation"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/overview",
          children: "How to use the HubSpot APIs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
          children: "Developer community forum"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/contact-our-partnerships-team",
          children: "Contact the App Partner team"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}