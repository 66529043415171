"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 1;
const slug = exports.slug = 'reference/api/crm/associations/association-details/v1';
const title = exports.title = 'Associations API - V1';
const name = exports.name = 'Associations API - V1';
const metaDescription = exports.metaDescription = 'Associations API - V1';
const orderedTitles = exports.orderedTitles = ['Get associations for a CRM object', 'Associate CRM objects', 'Create multiple associations between CRM objects', 'Delete an association', 'Delete multiple associations between CRM objects'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET', 'PUT', 'PUT', 'PUT', 'PUT'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      a: "a",
      strong: "strong",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get associations for a CRM object"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/crm-associations/v1/associations/:objectId/HUBSPOT_DEFINED/:definitionId"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use this endpoint to get the IDs of CRM objects associated with a given object, based on the specified association type. Check out the table on the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview",
            children: "CRM Associations Overview"
          }), " for a full list of IDs."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Use cases for this endpoint:"
          }), " To get the ticket IDs for all tickets associated with a specific contact record, the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectId"
          }), " would be the contact ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "vid"
          }), ", and the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "definitionId"
          }), " would be ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "15"
          }), "."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["To get all companies associated with a specific ticket record, the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectId"
          }), " would be the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectId"
          }), " of the ticket, and the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "definitionId"
          }), " would be ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "26"
          }), "."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: ["crm.objects.companies.read", "crm.objects.contacts.read", "crm.objects.deals.read", "tickets", "e-commerce"]
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "objectId",
            required: true,
            type: "Path",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the object that you want to get the associations for."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "definitionId",
            required: true,
            type: "Path",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The ID of the type of associations you want. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview",
                children: "the table on the overview page"
              }), " for a list of these IDs."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "limit",
            required: false,
            type: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The number of associations to return in a single response. Defaults to 10 if the parameter is not included, supports a maximum value of 100."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "offset",
            required: false,
            type: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Used to page through the results. Each response will include an ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "offset"
              }), ", which can be used to get the next set of results in the next request."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTCwgcHVsbGluZyB0aGUgSURzIGZvciB0aWNrZXRzIGFzc29jaWF0ZWQgd2l0aCBhIGNvbnRhY3Qgd2l0aCB2aWQgMjU6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vY3JtLWFzc29jaWF0aW9ucy92MS9hc3NvY2lhdGlvbnMvMjUvSFVCU1BPVF9ERUZJTkVELzE1CgpFeGFtcGxlIHJlc3BvbnNlIHdpdGggdHdvIGFzc29jaWF0ZWQgdGlja2V0czoKewogICJyZXN1bHRzIjogWwogICAgMjU5Njc0LAogICAgMjU5NzI3CiAgXSwKICAiaGFzTW9yZSI6IGZhbHNlLAogICJvZmZzZXQiOiAyNTk3MjcKfQoKRXhhbXBsZSBHRVQgVVJMLCBwdWxsaW5nIHRoZSBJRHMgZm9yIGNvbXBhbmllcyBhc3NvY2lhdGVkIHdpdGggYSB0aWNrZXQgd2l0aCBpZCAyNTk2NzQ6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vY3JtLWFzc29jaWF0aW9ucy92MS9hc3NvY2lhdGlvbnMvNzEwNjUxOC9IVUJTUE9UX0RFRklORUQvMjY/aGFwaWtleT1kZW1vCgpFeGFtcGxlIHJlc3BvbnNlIHdpdGggb25lIGFzc29jaWF0ZWQgY29tcGFueToKewogICJyZXN1bHRzIjogWwogICAgMTg0ODk2NjcwCiAgXSwKICAiaGFzTW9yZSI6IGZhbHNlLAogICJvZmZzZXQiOiAxODQ4OTY2NzAKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Associate CRM objects"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/crm-associations/v1/associations"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "This endpoint is used to associate two CRM objects."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Use case for this endpoint:"
          }), " You can use this endpoint to associate a ticket with a contact or to associate a line item with a deal."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["See all possible associations on the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview",
            children: "CRM Associations Overview"
          }), "."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you need to make multiple associations (either to the same object or across multiple objects) you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#create-multiple-associations-between-crm-objects",
            children: "batch associations endpoint"
          }), "."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: ["crm.objects.companies.read", "crm.objects.contacts.read", "crm.objects.deals.read", "tickets", "e-commerce"]
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fromObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the object being associated."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "toObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the object the from object is being associated with."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "category",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The category of the association. Currently this must be \"HUBSPOT_DEFINED\""
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "definitionId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The ID of the association definition. See the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview",
                children: "table on the overview page"
              }), " for a list of these IDs."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9jcm0tYXNzb2NpYXRpb25zL3YxL2Fzc29jaWF0aW9ucwoKRXhhbXBsZSBQVVQgSlNPTjoKewogICJmcm9tT2JqZWN0SWQiOiA0OTYzNDYsCiAgInRvT2JqZWN0SWQiOiAxNzY2MDIsCiAgImNhdGVnb3J5IjogIkhVQlNQT1RfREVGSU5FRCIsCiAgImRlZmluaXRpb25JZCI6IDE1Cn0KClJldHVybnMgYSAyMDQgTm8gQ29udGVudCByZXNwb25zZSBvbiBzdWNjZXNzLg=="
          })
        }), (0, _jsxRuntime.jsx)(_components.h4, {
          children: "Response details"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "If the request is successful, returns a 204 No Content response."
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Create multiple associations between CRM objects"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/crm-associations/v1/associations/create-batch"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Create multiple associations between CRM objects. The JSON data in the PUT request would be a list of associations, with each one representing a single association between 2 objects (for example, a ticket to a contact). To associate one object with multiple other objects (i.e. to associate a ticket with 2 different contacts), you would need to include multiple association entries (one for associating the ticket with contact A, and another to associate the ticket with contact B)."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Up to 100 associations may be created in a single request."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: ["crm.objects.companies.read", "crm.objects.contacts.read", "crm.objects.deals.read", "tickets", "e-commerce"]
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fromObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the object being associated."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "toObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the object the from object is being associated with."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "category",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The category of the association. Currently this must be \"HUBSPOT_DEFINED\""
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "definitionId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The ID of the association definition. See the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview",
                children: "table on the overview page"
              }), " for a list of those IDs."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CgpFeGFtcGxlIFBVVCBVUkw6CgpodHRwczovL2FwaS5odWJhcGkuY29tL2NybS1hc3NvY2lhdGlvbnMvdjEvYXNzb2NpYXRpb25zL2NyZWF0ZS1iYXRjaAoKRXhhbXBsZSBQVVQgSlNPTiAoYXNzb2NpYXRpbmcgdHdvIGNvbnRhY3RzIHdpdGggdGhlIHNhbWUgdGlja2V0KToKClsKICB7CiAgICAiZnJvbU9iamVjdElkIjogNDk2MzQ2LAogICAgInRvT2JqZWN0SWQiOiAxNzY2MDIsCiAgICAiY2F0ZWdvcnkiOiAiSFVCU1BPVF9ERUZJTkVEIiwKICAgICJkZWZpbml0aW9uSWQiOiAxNQogIH0sCiAgewogICAgImZyb21PYmplY3RJZCI6IDQ5MTY0NiwKICAgICJ0b09iamVjdElkIjogMTc2NjAyLAogICAgImNhdGVnb3J5IjogIkhVQlNQT1RfREVGSU5FRCIsCiAgICAiZGVmaW5pdGlvbklkIjogMTUKICB9Cl0KClJldHVybnMgYSAyMDQgTm8gQ29udGVudCByZXNwb25zZSBvbiBzdWNjZXNzLgoKUmV0dXJucyBhIDQwMCByZXNwb25zZSBpZiB0aGVyZSBhcmUgYW55IGVycm9ycyB3aXRoIHRoZSBhc3NvY2lhdGlvbnMuClRoZSByZXNwb25zZSB3aWxsIGluY2x1ZGUgYSBsaXN0IG9mIGVycm9yIG1lc3NzYWdlcyBmb3IgZWFjaCBpc3N1ZS4KewogICJzdGF0dXMiOiAiZXJyb3IiLAogICJtZXNzYWdlIjogIk9uZSBvciBtb3JlIGFzc29jaWF0aW9ucyBhcmUgaW52YWxpZCIsCiAgImVycm9ycyI6IFsKICAgICJDT05UQUNUPTEgaXMgbm90IHZhbGlkIgogIF0KfQo="
          })
        }), (0, _jsxRuntime.jsx)(_components.h4, {
          children: "Response details"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Returns a 400 response if there are any issues with the association. If there are any errors, the entire request will fail and ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "none"
            })
          }), " of the associations will be created."]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete an association"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/crm-associations/v1/associations/delete"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Delete an association between 2 CRM objects."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you need to remove multiple associations, you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#delete-multiple-associations-between-crm-objects",
            children: "batch delete endpoint"
          }), "."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: ["crm.objects.companies.read", "crm.objects.contacts.read", "crm.objects.deals.read", "tickets", "e-commerce"]
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fromObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the object you want to remove the association from."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "toObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the currently associated object that you're removing the association from."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "category",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The category of the association. Currently this must be \"HUBSPOT_DEFINED\""
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "definitionId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The ID of the association definition. See the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview",
                children: "table on the overview page"
              }), " for a list of those IDs."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9jcm0tYXNzb2NpYXRpb25zL3YxL2Fzc29jaWF0aW9ucy9kZWxldGUKCkV4YW1wbGUgUFVUIEpTT04sIHJlbW92aW5nIHRoZSBhc3NvY2lhdGlvbiBmcm9tIGEgY29udGFjdCB3aXRoIHZpZCAyNSB0byBhIHRpY2tldCB3aXRoIElEIDEwMDoKewogICJmcm9tT2JqZWN0SWQiOiAyNSwKICAidG9PYmplY3RJZCI6IDEwMCwKICAiY2F0ZWdvcnkiOiAiSFVCU1BPVF9ERUZJTkVEIiwKICAiZGVmaW5pdGlvbklkIjogMTUKfQoKUmV0dXJucyBhIDIwNCBObyBDb250ZW50IHJlc3BvbnNlIG9uIHN1Y2Nlc3Mu"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete multiple associations between CRM objects"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/crm-associations/v1/associations/delete-batch"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Delete multiple associations between CRM objects."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "The JSON data in the PUT request will be a list of items each representing a single association that you want to delete. See the example for more details."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: ["crm.objects.companies.read", "crm.objects.contacts.read", "crm.objects.deals.read", "tickets", "e-commerce"]
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fromObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the object you want to remove the association from."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "toObjectId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the currently associated object that you're removing the association from."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "category",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The category of the association. Currently this must be \"HUBSPOT_DEFINED\""
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "definitionId",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The ID of the association definition. See the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview",
                children: "table on the overview page"
              }), " for a list of those IDs."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9jcm0tYXNzb2NpYXRpb25zL3YxL2Fzc29jaWF0aW9ucy9kZWxldGUtYmF0Y2gKCkV4YW1wbGUgUFVUIEpTT04sIHJlbW92aW5nIHRoZSBhc3NvY2lhdGlvbnMgb2YgdHdvIGNvbnRhY3RzIGZyb20gYSBzaW5nbGUgdGlja2V0OgpbCiAgewogICAgImZyb21PYmplY3RJZCI6IDQ5NjM0NiwKICAgICJ0b09iamVjdElkIjogMTc2NjAyLAogICAgImNhdGVnb3J5IjogIkhVQlNQT1RfREVGSU5FRCIsCiAgICAiZGVmaW5pdGlvbklkIjogMTUKICB9LAogIHsKICAgICJmcm9tT2JqZWN0SWQiOiA0OTE2NDYsCiAgICAidG9PYmplY3RJZCI6IDE3NjYwMiwKICAgICJjYXRlZ29yeSI6ICJIVUJTUE9UX0RFRklORUQiLAogICAgImRlZmluaXRpb25JZCI6IDE1CiAgfQpdCgpSZXR1cm5zIGEgMjA0IE5vIENvbnRlbnQgcmVzcG9uc2Ugb24gc3VjY2Vzcy4="
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}