"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280489377;
const slug = exports.slug = 'guides/api/app-management/webhooks/overview';
const title = exports.title = 'API dos Webhooks ';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Webhooks API';
const metaDescription = exports.metaDescription = 'Visão geral da API dos Webhooks, que permite a você assinar eventos que acontecem em uma conta da HubSpot com sua integração instalada.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "escopos",
  "label": "Escopos",
  "parent": null
}, {
  "depth": 0,
  "id": "configura%C3%A7%C3%B5es-de-webhooks",
  "label": "Configurações de webhooks",
  "parent": null
}, {
  "depth": 1,
  "id": "gerenciar-configura%C3%A7%C3%B5es-em-sua-conta-de-desenvolvedor",
  "label": "Gerenciar configurações em sua conta de desenvolvedor",
  "parent": "configura%C3%A7%C3%B5es-de-webhooks"
}, {
  "depth": 1,
  "id": "gerenciar-configura%C3%A7%C3%B5es-por-meio-da-api",
  "label": "Gerenciar configurações por meio da API",
  "parent": "configura%C3%A7%C3%B5es-de-webhooks"
}, {
  "depth": 0,
  "id": "assinaturas-de-webhook",
  "label": "Assinaturas de Webhook",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-assinaturas-em-sua-conta-de-desenvolvedor",
  "label": "Criar assinaturas em sua conta de desenvolvedor",
  "parent": "assinaturas-de-webhook"
}, {
  "depth": 1,
  "id": "criar-assinaturas-por-meio-da-api",
  "label": "Criar assinaturas por meio da API",
  "parent": "assinaturas-de-webhook"
}, {
  "depth": 1,
  "id": "obter-assinaturas",
  "label": "Obter assinaturas",
  "parent": "assinaturas-de-webhook"
}, {
  "depth": 1,
  "id": "criar-uma-nova-assinatura",
  "label": "Criar uma nova assinatura",
  "parent": "assinaturas-de-webhook"
}, {
  "depth": 1,
  "id": "atualizar-uma-assinatura",
  "label": "Atualizar uma assinatura",
  "parent": "assinaturas-de-webhook"
}, {
  "depth": 1,
  "id": "excluir-uma-assinatura",
  "label": "Excluir uma assinatura",
  "parent": "assinaturas-de-webhook"
}, {
  "depth": 0,
  "id": "payloads-de-webhooks",
  "label": "Payloads de webhooks",
  "parent": null
}, {
  "depth": 0,
  "id": "exclus%C3%B5es-de-contatos-em-conformidade-com-a-privacidade",
  "label": "Exclusões de contatos em conformidade com a privacidade",
  "parent": null
}, {
  "depth": 0,
  "id": "seguran%C3%A7a",
  "label": "Segurança",
  "parent": null
}, {
  "depth": 0,
  "id": "tentativas",
  "label": "Tentativas",
  "parent": null
}, {
  "depth": 0,
  "id": "limites",
  "label": "Limites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Webhooks"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A API de Webhooks permite assinar eventos que acontecem em uma conta da HubSpot com sua integração instalada. Em vez de fazer uma chamada de API quando um evento acontece em uma conta conectada, o HubSpot pode enviar uma solicitação HTTP para um ponto de extremidade que você configurar. Você pode configurar eventos assinados nas configurações de seu aplicativo ou usando os pontos de extremidade detalhados abaixo. Os Webhooks podem ser uma alternativa mais escalável do que verificar alterações com frequência, especialmente para aplicativos com uma base de instalação grande."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O uso da API dos Webhooks requer o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você deve configurar um aplicativo da HubSpot para usar webhooks, inscrevendo-se nos eventos sobre os quais deseja ser notificado e especificando um URL para envio dessas notificações. Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/integration-platform-api-requirements",
          children: "documentação dos pré-requisitos"
        }), " para obter mais informações sobre como criar um aplicativo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você deve implantar um ponto de extremidade seguro (HTTPS) e disponível publicamente para esse URL que seja capaz de lidar com as cargas do webhook especificadas nesta documentação."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os webhooks estão configurados para um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "aplicativo da HubSpot"
      }), ", e não para contas individuais. Todas as contas que instalarem seu aplicativo passando pelo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "fluxo de OAuth"
      }), " serão inscritas nas respectivas assinaturas do webhook."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode se inscrever em eventos de objeto do CRM, que incluem contatos, empresas, negócios, tickets, produtos e itens de linha, bem como em eventos de conversas."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
            children: "gerenciar webhooks em um aplicativo privado"
          }), ". Nos aplicativos privados, as configurações de webhook só podem ser editadas nas configurações do aplicativo privado; elas não podem ser editadas por meio da API."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Para se inscrever em webhooks de conversas, você precisa acessar ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/inbox-and-messages",
            children: "a caixa de entrada de conversas e as APIs de mensagens"
          }), ", que estão atualmente ", (0, _jsxRuntime.jsx)("u", {
            children: "em versão beta"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Escopos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para usar webhooks para assinar os eventos do CRM, o aplicativo precisará ser configurado para exigir o escopo associado que corresponde ao tipo de objeto do CRM no qual você deseja se inscrever. Por exemplo, se quiser se inscrever em eventos de contatos, você terá que solicitar o escopo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm.objects.contacts.read"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao criar assinaturas na interface de configurações do seu aplicativo público, você será solicitado a adicionar o escopo necessário no painel ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Criar novas assinaturas do webhook"
        }), " antes de terminar de criar a assinatura."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você estiver criando uma assinatura ao fazer uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para o ponto de extremidade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/webhooks/v3/{appId}/subscriptions"
        }), ", a resposta incluirá um erro contendo o nome do escopo que você precisará definir nas configurações da interface do usuário do seu aplicativo público."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se o seu aplicativo já estiver usando webhooks, você não poderá remover nenhum escopo exigido pelas assinaturas de webhooks ativas sem primeiro pausar e remover as assinaturas."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você pode revisar os escopos necessários para cada tipo de assinatura de webhook na ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#webhook-subscriptions",
          children: "tabela abaixo."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Revise a documentação de OAuth para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0#scopes",
        children: "obter mais detalhes sobre os escopos"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "configurar o URL de autorização"
      }), " para o seu aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurações de webhooks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de configurar suas assinaturas de Webhook, você precisa especificar um URL para o qual essas notificações serão enviadas. Siga as instruções nas secções abaixo para saber como configurar totalmente as assinaturas do seu aplicativo."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "As configurações de Webhooks podem ser armazenadas no cache por até cinco minutos. Quando você altera o URL do webhook, os limites de simultaneidade ou as configurações de assinatura, essas alterações podem levar até cinco minutos para entrarem em vigor."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "O HubSpot define um limite de simultaneidade de 10 solicitações ao enviar dados de eventos de assinatura associados a uma conta que instalou seu aplicativo. Esse limite de simultaneidade é o número máximo de solicitações em andamento que o HubSpot tentará por vez. Cada solicitação pode conter até 100 eventos."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gerenciar configurações em sua conta de desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode gerenciar a URL e o limite de acúmulo de eventos na página de configuração do aplicativo em sua conta de desenvolvedor:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta de desenvolvedor, acesse o painel ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Aplicativo"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), " do aplicativo para o qual deseja configurar os webhooks."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/app_id_list.png?width=600&name=app_id_list.png",
            alt: "app_id_list"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No menu da barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Webhooks"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No campo ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "URL de destino"
          }), ", insira a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " para a qual o HubSpot fará uma solicitação POST quando os eventos forem disparados."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use a configuração ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Acúmulo de eventos"
          }), " para ajustar o número máximo de eventos que o HubSpot tentará enviar."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_settings.png?width=600&name=webhook_settings.png",
        alt: "webhook_settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Salvar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gerenciar configurações por meio da API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode usar os seguintes pontos de extremidade e sua ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/developer-api-keys",
        children: "chave de API de desenvolvedor"
      }), " para configurar programaticamente as configurações do webhook para um aplicativo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para exibir as configurações de webhooks definidas para um aplicativo, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você precisará incluir o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/how-do-i-find-the-app-id",
        children: "ID do aplicativo"
      }), " na solicitação, que pode ser encontrado abaixo do nome do aplicativo no painel ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Aplicativos"
      }), " ou na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Autenticação"
      }), " nas configurações do aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O objeto de configuração contém os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhookUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL para a qual o HubSpot enviará notificações de webhook. A URL deve ser atendida por HTTPS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O limite de concorrência para a URL do webhook. Este valor deve ser um número maior que cinco."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para editar essas configurações, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), " e inclua os seguintes campos no corpo da solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL disponível publicamente para que o HubSpot indique onde as payloads do evento serão entregues."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "acúmulo"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configure os detalhes de acúmulo do webhook neste objeto. O objeto de acúmulo inclui os campos ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "período"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Escala de tempo para esta configuração. Pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SECONDLY"
            }), " (por segundo) ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ROLLING_MINUTE"
            }), " (por minuto)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O número máximo de solicitações HTTP que o HubSpot tentará fazer para o seu aplicativo em um período de tempo determinado por ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, o corpo da solicitação pode ser parecido com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to https://api.hubapi.com/webhooks/v3/{appId}/settings\n\n{\n  \"throttling\": {\n    \"period\": \"SECONDLY\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"targetUrl\": \"https://www.example.com/hubspot/target\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Assinaturas de Webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de definir a URL do Webhook e o limite de acúmulo de eventos, será necessário criar uma ou mais assinaturas. As assinaturas do Webhook informam ao HubSpot quais eventos seu aplicativo específico gostaria de receber."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As assinaturas se aplicam a todos os clientes que instalaram sua integração. Isso significa que basta você especificar uma vez as assinaturas de que precisa. Depois que uma assinatura for ativada para um aplicativo, ele começará a receber webhooks automaticamente de todos os clientes que instalaram seu aplicativo, e sua integração começará a receber gatilhos de webhook de qualquer novo cliente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para todas as assinaturas de webhook ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationChange"
      }), ", o webhook disparará dois eventos para ambos os lados da associação."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao associar dois contatos, uma assinatura de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " disparará dois eventos, representando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to contact 2"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 2 to contact 1"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao associar uma empresa, se você tiver duas assinaturas de webhook, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company.associationChange"
        }), ", receberá dois eventos. Estes representarão ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to company 1"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company 1 to contact 1"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os seguintes tipos de assinatura são suportados e podem ser usados como valor para o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " ao criar assinaturas por meio da API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de assinatura"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Escopo obrigatório"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um contato for criado na conta de um cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um contato for excluído na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um contato for mesclado com outro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um contato tiver uma associação adicionada ou removida entre ele e outro objeto de webhook suportado (contato, empresa, negócio, ticket, item de linha ou produto)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um contato for restaurado da exclusão."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Receba uma notificação se um contato for excluído por ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "motivos de conformidade com a privacidade"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma propriedade específica for alterada para um contato em uma conta."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma empresa for criada na conta de um cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma empresa for excluída na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma propriedade específica for alterada para uma empresa na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma empresa tiver uma associação adicionada ou removida entre ela e outro objeto de webhook suportado (contato, empresa, negócio, ticket, item de linha ou produto)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma empresa for restaurada da exclusão."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma empresa for mesclada com outra."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um negócio for criado na conta de um cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um negócio for excluído na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um negócio tiver uma associação adicionada ou removida entre ele e outro objeto de webhook suportado (contato, empresa, negócio, ticket, item de linha ou produto)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um negócio for restaurado da exclusão."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um negócio for mesclado com outro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma propriedade específica for alterada para um negócio na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um ticket for criado na conta de um cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um ticket for excluído na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma propriedade específica for alterada para um ticket na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um ticket tiver uma associação adicionada ou removida entre ele e outro objeto de webhook suportado (contato, empresa, negócio, ticket, item de linha ou produto)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um tíquete for restaurado da exclusão."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um ticket for mesclado com outro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um produto for criado na conta de um cliente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um produto for excluído na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um produto for restaurado da exclusão."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um produto for mesclado com outro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um produto específico for alterado para um produto na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um item de linha for criado na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um item de linha for excluído na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um item de linha tiver uma associação adicionada ou removida entre ele e outro objeto de webhook suportado (contato, empresa, negócio, ticket, item de linha ou produto)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um item de linha for restaurado da exclusão."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um item de linha for mesclado com outro."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma propriedade específica for alterada para um item de linha na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os seguintes tipos de assinatura de conversas estão disponíveis para você assinar se estiver usando a", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "API de mensagens e caixa de entrada de conversas"
      }), ", que está atualmente ", (0, _jsxRuntime.jsx)("u", {
        children: "em versão beta"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de assinatura"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Scope"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um novo thread for criado em uma conta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um thread for arquivado ou excluído de forma reversível em uma conta."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se um thread for excluído permanentemente em uma conta."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma propriedade em um thread for alterada."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Receba uma notificação se uma nova mensagem for recebida em um thread."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No caso de assinaturas de alteração de propriedade, você precisará especificar sobre quais propriedades deseja ser notificado. É possível especificar várias assinaturas de alteração de propriedade. Se a conta de um cliente não tiver a propriedade especificada em uma assinatura, você não receberá webhooks desse cliente para essa propriedade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algumas propriedades não estão disponíveis para assinaturas de alteração de propriedade do CRM. São elas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "num_unique_conversion_events"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_lastmodifieddate"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "API de caixa de entrada e mensagens de conversas"
      }), ", que está atualmente ", (0, _jsxRuntime.jsx)("u", {
        children: "em versão beta"
      }), ", as seguintes propriedades estarão disponíveis:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "assignedTo"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o thread da conversa foi reatribuído ou teve sua atribuição cancelada. Se o thread foi reatribuído, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " será um ID de ator na payload dos webhooks; se teve sua atribuição cancelada, a propriedade estará vazia."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o status do thread de conversas foi alterado. Na carga útil dos webhooks, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OPEN"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CLOSED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "isArchived"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o thread da conversa foi restaurado. O ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " na payload dos webhooks sempre será ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "FALSE"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar assinaturas em sua conta de desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode criar assinaturas de webhook em sua conta de desenvolvedor da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor da HubSpot, acesse o painel ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " de um aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu da barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar assinatura"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No painel direito, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quais tipos de objeto?"
        }), " e selecione os ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objetos"
        }), " para os quais você deseja criar uma assinatura."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitorar quais eventos?"
        }), " e selecione os ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tipos de eventos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-contact-create-subscription.png",
        alt: "create-contact-create-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Se você estiver criando uma assinatura para eventos de alteração de propriedade, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Quais propriedades?"
          }), " e selecione as ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "propriedades"
          }), " que devem ser monitoradas."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_select_properties.png?width=450&name=webhook_select_properties.png",
            alt: ""
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Assinar"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A assinatura aparecerá nas configurações de webhooks. Novas assinaturas são criadas em um estado pausado; você precisará ativar a assinatura para que os webhooks sejam enviados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Assinaturas de eventos"
        }), ", passe o mouse sobre o tipo de objeto e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exibir assinaturas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Marque a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "caixa de seleção"
        }), " ao lado do evento e, no cabeçalho da tabela, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ativar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/activate-subscription.png",
        alt: "activate-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar assinaturas por meio da API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode criar assinaturas de forma programática usando os pontos de extremidade a seguir. Você precisará usar sua chave de API de desenvolvedor ao fazer solicitações para esses pontos de extremidade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O objeto de assinatura pode incluir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um número que representa o ID exclusivo de uma assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A hora em milissegundos em que essa assinatura foi criada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID associado ao usuário que criou a assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inteligente"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica se a assinatura está ativada e disparando notificações de forma ativa. O valor pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de assinatura. A ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview#webhook-subscriptions",
              children: "tabela"
            }), " no início desta seção inclui os tipos de assinatura disponíveis."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da propriedade em que a assinatura monitorará as alterações. Isso é necessário apenas para tipos de assinatura de alteração de propriedade."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obter assinaturas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar a lista de assinaturas, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A resposta será um conjunto de objetos que representam suas assinaturas. Cada objeto conterá informações sobre a assinatura, como o ID, a data de criação, o tipo e se a assinatura está ou não ativa no momento. Veja a seguir o exemplo de uma resposta:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n[\n  {\n    \"id\": 25,\n    \"createdAt\": 1461704185000,\n    \"createdBy\": 529872,\n    \"eventType\": \"contact.propertyChange\",\n    \"propertyName\": \"lifecyclestage\",\n    \"active\": false\n  },\n  {\n    \"id\": 59,\n    \"createdAt\": 1462388498000,\n    \"createdBy\": 529872,\n    \"eventType\": \"company.creation\",\n    \"active\": false\n  },\n  {\n    \"id\": 108,\n    \"createdAt\": 1463423132000,\n    \"createdBy\": 529872,\n    \"eventType\": \"deal.creation\",\n    \"active\": true\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar uma nova assinatura"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar uma nova assinatura, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo da solicitação, você pode incluir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O tipo de assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da propriedade em que a assinatura monitorará as alterações. Isso é necessário apenas para tipos de assinatura de alteração de propriedade."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inteligente"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica se a assinatura está ativada e disparando notificações de forma ativa. O valor pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Não é necessário incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdAt"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdBy"
      }), ", pois esses campos são definidos automaticamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, o corpo da solicitação pode ser parecido com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n{\n  \"eventType\": \"company.propertyChange\",\n  \"propertyName\": \"companyname\",\n  \"active\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " deve ser um tipo de assinatura válido, conforme definido na seção acima, e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyName"
      }), " deve ser um nome de propriedade válido. Se um cliente não tiver uma propriedade definida que corresponda a esse valor, essa assinatura não resultará em notificação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atualizar uma assinatura"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ativar ou pausar uma assinatura, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo da solicitação, inclua o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inteligente"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica se a assinatura está ativada e disparando notificações de forma ativa. O valor pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Excluir uma assinatura"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir uma assinatura, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Payloads de webhooks"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ponto de extremidade na URL de destino especificada nas configurações de webhooks do aplicativo receberá do HubSpot solicitações ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " contendo dados em formato JSON."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para garantir que as solicitações que você está recebendo no ponto de extremidade do webhook sejam provenientes do HubSpot, o HubSpot preenche um cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " com um hash SHA-256 criado usando o segredo do cliente do seu aplicativo combinado com os detalhes da solicitação. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "como validar assinaturas de solicitação"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use as tabelas abaixo para visualizar detalhes sobre os campos que podem estar contidos no conteúdo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do objeto que foi criado, alterado ou excluído. Para contatos, é o ID do contato; para empresas, é o ID da empresa; para negócios, é o ID do negócio; e para conversas, é o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "ID do thread"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "É enviado apenas para assinaturas de alteração de propriedade e é o nome da propriedade que foi alterada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "É enviado apenas para assinaturas de alteração de propriedade e representa o novo valor definido para a propriedade que disparou a notificação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A origem da alteração. Pode ser qualquer uma das origens de alteração que aparecem nos históricos de propriedades de contato."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do evento que disparou essa notificação. Não há garantias de que esse valor seja exclusivo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da assinatura que disparou uma notificação sobre o evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "ID da conta da HubSpot"
            }), " do cliente onde o evento ocorreu."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do aplicativo. Isso é usado caso você tenha vários aplicativos apontando para a mesmo URL de Webhook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quando esse evento ocorreu, como uma marca de data/hora em milissegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O tipo de evento para o qual esta notificação se destina. Revise a lista de tipos de assinatura permitidos na seção de assinatura de webhooks acima."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Começando em 0, o número desta tentativa de notificação do seu serviço sobre esse evento. Se o serviço atingir o tempo limite ou lançar um erro conforme descrito na seção ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Tentativas"
            }), " abaixo, o HubSpot tentará enviar a notificação novamente."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Somente será enviado quando um webhook estiver monitorando novas mensagens para um thread. É o ID da nova mensagem."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Somente será enviado quando um webhook estiver monitorando novas mensagens para um thread. Representa o tipo de mensagem que você está enviando. Este valor pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do vencedor da mesclagem, que é o registro que permanece após a mesclagem. Na interface de mesclagem do HubSpot, é o registro à direita."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mergedObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma matriz de IDs que representam os registros mesclados no vencedor da mesclagem. Na interface de mesclagem do HubSpot, é o registro à esquerda."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "newObjectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do registro criado como resultado da mesclagem. Isso é separado do ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            }), " porque, em alguns casos, um novo registro é criado como resultado da mesclagem."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOfPropertiesMoved"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um número inteiro que representa quantas propriedades foram transferidas durante a mesclagem."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de associação, que será um dos seguintes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_CONTACT"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_COMPANY"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_LINE_ITEM"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_DEAL"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_TICKET"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINE_ITEM_TO_DEAL"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do registro do qual a alteração da associação foi feita."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do registro secundário no evento de associação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationRemoved"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um booleano que representa o seguinte:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": o webhook foi disparado pela remoção de uma associação."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": o webhook foi disparado pela criação de uma associação."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isPrimaryAssociation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um booleano que representa o seguinte:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": o registro secundário é a ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/crm-setup/associate-records#primary-company-information",
                  children: "associação principal"
                }), " do registro do qual a alteração da associação foi feita."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": o registro ", (0, _jsxRuntime.jsx)("u", {
                  children: "não é"
                }), " a associação principal do registro do qual a alteração da associação foi feita. "]
              })]
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Observação:"
            }), " a criação de uma instância de associação principal entre dois registros de objeto fará com que a associação correspondente, que não seja a principal, também seja criada. Isso pode resultar em duas mensagens de webhook."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n[\n  {\n    \"objectId\": 1246965,\n    \"propertyName\": \"lifecyclestage\",\n    \"propertyValue\": \"subscriber\",\n    \"changeSource\": \"ACADEMY\",\n    \"eventId\": 3816279340,\n    \"subscriptionId\": 25,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.propertyChange\",\n    \"attemptNumber\": 0\n  },\n  {\n    \"objectId\": 1246978,\n    \"changeSource\": \"IMPORT\",\n    \"eventId\": 3816279480,\n    \"subscriptionId\": 22,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.creation\",\n    \"attemptNumber\": 0\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como mostrado acima, você deve esperar receber um array de objetos em uma única solicitação. O tamanho do lote pode variar, mas será de no máximo 100 notificações. O HubSpot somente enviará várias notificações quando muitos eventos ocorrerem em um curto período de tempo. Por exemplo, se você tiver inscrito novos contatos e um cliente importar um grande número de contatos, o HubSpot enviará as notificações desses contatos importados em lotes, e não uma por solicitação."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot não garante que você receberá essas notificações na ordem em que ocorreram. Use a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "occurredAt"
      }), " para cada notificação a fim de determinar quando o evento que disparou a notificação ocorreu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot também não garante que você receberá somente uma notificação para cada evento. Pode acontecer de o HubSpot enviar a mesma notificação várias vezes, embora isso provavelmente não ocorrerá."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exclusões de contatos em conformidade com a privacidade"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usuários do HubSpot podem excluir permanentemente um registro de contato para cumprir as leis de privacidade. Saiba mais sobre como realizar uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-do-i-perform-a-gdpr-delete-in-hubspot",
        children: "exclusão em conformidade com o GDPR"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode se inscrever no tipo de assinatura ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.privacyDeletion"
      }), " para receber notificações de Webhook quando um usuário realizar a exclusão de um contato em conformidade com a privacidade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As notificações de exclusão de privacidade têm comportamentos especiais:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um evento de exclusão de privacidade também disparará o evento de exclusão de contato. Portanto, você receberá duas notificações se estiver inscrito nos dois eventos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Essas notificações não serão necessariamente enviadas em uma ordem específica ou no mesmo lote de mensagens. Você precisará usar o ID do objeto para corresponder mensagens separadas."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Segurança"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para garantir que as solicitações que você está recebendo no ponto de extremidade do webhook sejam provenientes do HubSpot, o HubSpot preenche um cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " com um hash SHA-256 da concatenação do segredo do aplicativo e do corpo da solicitação que estamos enviando."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para verificar essa assinatura, concatene o segredo do aplicativo e o corpo de solicitação não analisado da solicitação que você está gerenciando e obtenha um hash SHA-256 do resultado. Compare o hash resultante com o valor do cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), ". Se esses valores corresponderem, isso confirmará que essa solicitação veio da HubSpot. Ou a solicitação veio de outra pessoa que conhece o segredo do seu aplicativo. É importante manter esse valor em segredo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se esses valores forem diferentes, essa solicitação pode ter sido alterada em trânsito ou alguém pode ter falsificado as notificações de webhook no seu ponto de extremidade."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validar solicitações de assinatura"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tentativas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se, em algum momento, o serviço enfrentar problemas ao lidar com notificações, o HubSpot fará 10 tentativas de reenviar as notificações com falha."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot tentará novamente nos seguintes casos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Falha na conexão:"
        }), " se o HubSpot não conseguir estabelecer uma conexão http com a URL do webhook fornecida."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tempo limite:"
        }), " se o serviço demorar mais de cinco segundos para enviar uma resposta a um lote de notificações."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Códigos de erro:"
        }), " se o serviço responder com qualquer código de status HTTP (4xx ou 5xx)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As notificações serão repetidas até 10 vezes. Essas tentativas serão divulgadas nas próximas 24 horas, com atrasos variáveis entre solicitações. Será aplicada uma randomização às notificações individuais para evitar que um grande número de falhas simultâneas sejam repetidas exatamente mesmo horário."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As solicitações ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " que o HubSpot envia ao seu serviço por meio das assinaturas de webhook ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " serão contabilizadas nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "limites de taxa de API do aplicativo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode criar no máximo de 1.000 assinaturas por aplicativo. Se tentar criar mais de 1.000 assinaturas, você receberá uma solicitação 400 inválida com a seguinte mensagem:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"status\": \"error\",\n  \"message\": \"Couldn't create another subscription. You've reached the maximum number allowed per application (1000).\",\n  \"correlationId\": \"2c9beb86-387b-4ff6-96f7-dbb486c00a95\",\n  \"requestId\": \"919c4c84f66769e53b2c5713d192fca7\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}