"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400685;
const slug = exports.slug = 'guides/cms/content/performance/speed';
const title = exports.title = 'Optimiser la vitesse de votre site CMS Hub';
const name = exports.name = 'EMEA FR - Optimizing your CMS Hub site for Speed - original';
const metaDescription = exports.metaDescription = 'Conseils et stratégies pour améliorer la vitesse de chargement et de rendu de votre site web. Créez rapidement votre thème ou site CMS Hub et découvrez ce que HubSpot fait déjà pour vous.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "commencez-par-une-bonne-base",
  "label": "Commencez par une bonne base",
  "parent": null
}, {
  "depth": 0,
  "id": "obstacles-courants-des-performances-des-sites-web",
  "label": "Obstacles courants des performances des sites web",
  "parent": null
}, {
  "depth": 1,
  "id": "images",
  "label": "Images",
  "parent": "obstacles-courants-des-performances-des-sites-web"
}, {
  "depth": 2,
  "id": "ce-que-vous-pouvez-faire",
  "label": "Ce que vous pouvez faire",
  "parent": "obstacles-courants-des-performances-des-sites-web"
}, {
  "depth": 1,
  "id": "vid%C3%A9o-en-lecture-automatique",
  "label": "Vidéo en lecture automatique",
  "parent": "obstacles-courants-des-performances-des-sites-web"
}, {
  "depth": 2,
  "id": "ce-que-vous-pouvez-faire",
  "label": "Ce que vous pouvez faire",
  "parent": "obstacles-courants-des-performances-des-sites-web"
}, {
  "depth": 1,
  "id": "javascript",
  "label": "JavaScript",
  "parent": "obstacles-courants-des-performances-des-sites-web"
}, {
  "depth": 2,
  "id": "ce-que-vous-pouvez-faire",
  "label": "Ce que vous pouvez faire",
  "parent": "obstacles-courants-des-performances-des-sites-web"
}, {
  "depth": 0,
  "id": "outil-de-conseils",
  "label": "Outil de Conseils",
  "parent": null
}, {
  "depth": 0,
  "id": "alertes-de-code",
  "label": "Alertes de code",
  "parent": null
}, {
  "depth": 0,
  "id": "que-g%C3%A8re-hubspot-pour-vous-%3F",
  "label": "Que gère HubSpot pour vous ?",
  "parent": null
}, {
  "depth": 0,
  "id": "ressources-suppl%C3%A9mentaires-pour-am%C3%A9liorer-la-vitesse-de-votre-site",
  "label": "Ressources supplémentaires pour améliorer la vitesse de votre site",
  "parent": null
}, {
  "depth": 1,
  "id": "outils-de-performance",
  "label": "Outils de performance",
  "parent": "ressources-suppl%C3%A9mentaires-pour-am%C3%A9liorer-la-vitesse-de-votre-site"
}, {
  "depth": 2,
  "id": "optimisation-de-l-image",
  "label": "Optimisation de l'image",
  "parent": "ressources-suppl%C3%A9mentaires-pour-am%C3%A9liorer-la-vitesse-de-votre-site"
}, {
  "depth": 2,
  "id": "tests-de-performance",
  "label": "Tests de performance",
  "parent": "ressources-suppl%C3%A9mentaires-pour-am%C3%A9liorer-la-vitesse-de-votre-site"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      ol: "ol",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Optimiser les performances de votre site CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une bonne expérience utilisateur est un facteur de qualité de contenu, de rapidité, de sécurité et d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "accessibilité"
      }), ". L'optimisation de ces derniers améliore généralement aussi le SEO (Optimisation pour les moteurs de recherche)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'amélioration des performances consiste à offrir une meilleure expérience aux utilisateurs finaux. Pour obtenir de meilleures performances, il faut résoudre les problèmes de vos sites."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Commencez par une bonne base"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il est plus facile de créer à partir d'une base solide qui a été conçue dans un souci de performance, que d'essayer de résoudre les problèmes de performance par la suite. Une métaphore : il est plus facile de construire une voiture rapide à partir de rien que d'acheter une voiture lente et d'essayer de la rendre rapide."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "Boilerplate de HubSpot CMS"
      }), " a été conçu pour être rapide et encourager les meilleures pratiques. Consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "README GitHub"
      }), " pour voir les scores actuels dans lighthouse et website grader."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous créez à partir du boilerplate, vous savez que sans aucun code ajouté, il atteint ces scores. Cela signifie que vous pouvez concentrer votre attention sur le code que vous avez ajouté par-dessus le boilerplate standard."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "bdf71810-13e6-494f-8dbd-f733d5451ad5",
      label: "Build a site based on boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obstacles courants des performances des sites web"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La plupart des techniques et des meilleures pratiques d'optimisation des performances web ne sont pas spécifiques à HubSpot. En effet, la plupart des problèmes de performances des sites web ne sont pas spécifiques à HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La plupart des problèmes de performance se répartissent en deux catégories : le rendu et le chargement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Performance de chargement"
        }), " est l'efficacité du transfert de tous les fichiers nécessaires à votre page web vers le navigateur de l'utilisateur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Performances de rendu"
        }), " est l'efficacité du navigateur à prendre tout ce qu'il a téléchargé et à afficher le résultat final à l'utilisateur."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La performance de chargement est un peu plus facile à comprendre - la quantité de fichiers, la taille des fichiers et la vitesse de livraison de ces fichiers déterminent la performance de chargement. La performance du rendu est un thème compliqué. Les ", (0, _jsxRuntime.jsx)("abbr", {
        children: "CSS"
      }), " (Cascading Style Sheets, en anglais), JavaScript (", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), "), les images, les vidéos, l'appareil utilisé par le visiteur et le navigateur web utilisé sont tous des facteurs importants. Les CSS bloquent le rendu. Les CSS mal écrites peuvent provoquer un ", (0, _jsxRuntime.jsx)("abbr", {
        children: "CLS"
      }), " (Cumulative Layout Shift, en anglais) lors du rendu des pages. Les images peuvent provoquer un CLS et occuper la RAM. Les lecteurs vidéo peuvent être à l'origine de CLS, certains formats de fichiers nécessitent un travail de traitement plus important. JS peut manipuler le ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/DOM",
        children: [(0, _jsxRuntime.jsx)("abbr", {
          children: "DOM"
        }), " (Document Object Model, en anglais)"]
      }), " et le ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/CSSOM",
        children: [(0, _jsxRuntime.jsx)("abbr", {
          children: "CSSOM"
        }), " (Cascading Style Sheet Object Model, en anglais)"]
      }), " d'une page, provoquant l'un de ces problèmes. JS peut également consommer beaucoup de ressources. Tous ces facteurs doivent être équilibrés et les meilleures pratiques suivies pour garantir une expérience rapide à tous les visiteurs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Images"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les images sont omniprésentes sur presque toutes les pages du web. Les images sont généralement les fichiers les plus volumineux d'une page. Plus il y a d'images, et plus elles sont grandes, plus votre page sera longue à charger. Les images animées, telles que les gifs et les webp animés, occupent également plus d'espace que les images non animées de même taille. Certains formats d'image sont également plus performants que d'autres, et mieux adaptés à certains scénarios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ce que vous pouvez faire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La chose la plus importante que vous puissiez faire est d' ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/compress-image",
          children: "optimiser vos images"
        }), " pour le web. L'optimisation des images est une responsabilité partagée entre les créateurs de contenu et les développeurs."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilisez moins d'images par page."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/insiders/different-types-of-image-files",
          children: "Utilisez le bon format d'image pour le cas d'utilisation"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilisez des SVG (Scalable Vector Graphics, en anglais) dans la mesure du possible. Les SVG peuvent évoluer en taille à l'infini sans perdre en qualité. La mise en ligne de vos SVG est utile lorsque vous réalisez des animations. Dans les graphiques statiques, la création d'un sprite sheet SVG ou son traitement comme un élément img normal ou une image d'arrière-plan est généralement plus performant."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/lazy-loading",
          children: "Chargement différé des images"
        }), " judicieux."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Assurez-vous que les éléments ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), " contiennent les attributs HTML de hauteur et de largeur. Cela facilite la tâche des navigateurs au moment du rendu et permet à HubSpot d'ajouter intelligemment un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), " pour vous. Non seulement HubSpot aide à l'optimisation, mais les navigateurs web peuvent optimiser intelligemment le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/cls/",
          children: "CLS"
        }), " lorsque vous fournissez la largeur et la hauteur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez la propriété ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio",
          children: "rapport hauteur/largeur CSS"
        }), " pour réserver de l'espace lorsque les dimensions img peuvent changer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url"
          })
        }), " pour forcer les images à être redimensionnées à une certaine résolution."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les images d'arrière-plan, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/optimize-css-background-images-with-media-queries/",
          children: "utilisez les requêtes média"
        }), " en combinaison avec ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url"
        }), " pour fournir des images à des tailles adaptées à l'appareil."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les grandes images principales, vous pouvez les précharger en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"preload\" as=\"image\" href=\"http://example.com/img_url.jpg\">"
        }), " dans une ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: ["balise ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_head"
          })]
        }), ". Utilisez cette technique avec modération, car une utilisation excessive peut nuire aux performances."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vidéo en lecture automatique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les arrière-plans vidéo et les vidéos à lecture automatique peuvent faire ressortir un site web. Malheureusement, elles ont un coût. Les arrière-plans vidéo sont souvent utilisés pour les en-têtes de sites web. Lorsqu'une vidéo se lance automatiquement, cela signifie que le navigateur doit commencer à charger la vidéo immédiatement. Cela peut s'avérer particulièrement problématique pour les utilisateurs disposant de connexions plus lentes ou utilisant les données d'un téléphone portable."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ce que vous pouvez faire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Évitez d'utiliser des vidéos en lecture automatique. Si ce que vous montrez est une animation de fond, pensez à utiliser des animations CSS ou des animations JavaScript. Si vous devez afficher une vidéo en lecture automatique :"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Choisissez une résolution raisonnable pour la vidéo en fonction de votre cas d'utilisation, et appliquez un effet sur la vidéo pour rendre une résolution inférieure moins perceptible."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Assurez-vous que la qualité de la vidéo varie en fonction de l'appareil et de la connexion. Le meilleur moyen d'y parvenir est d'utiliser un service de partage et d'hébergement de vidéos comme YouTube, Vidyard ou Vimeo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Désactiver la lecture automatique sur mobiles, afficher une image de repli à la place."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript", (0, _jsxRuntime.jsx)("abbr", {
        children: "(JS"
      }), ") est utile pour ajouter de l'interactivité à votre site web. Le chargement d'une grande quantité de code JS augmente en général la taille des fichiers JS et le temps nécessaire au navigateur pour rendre les éléments interactifs. Le chargement de JS dans le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " peut aussi être un problème, car JavaScript est une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/render-blocking-resources/",
        children: "ressource bloquante pour le rendu"
      }), " par défaut. De plus, JS est exécuté sur l'appareil du visiteur, ce qui signifie qu'il est limité aux ressources de cet appareil."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ce que vous pouvez faire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lorsque le CMS de HubSpot est sorti, jQuery était chargé dans le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " par défaut. Vous pouvez le supprimer entièrement dans ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paramètres > Site web > Pages,"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/upgrade-jquery",
          children: "passer à la dernière version de jQuery"
        }), ". Faites attention lorsque vous modifiez ces paramètres sur des sites web plus anciens si vous ne les avez pas créés, ils peuvent avoir été conçus en s'appuyant sur jQuery ou sur le chargement de jQuery dans l'en-tête."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Assurez-vous que JavaScript est chargé avant le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "</body>"
        }), " pour éviter le blocage du rendu. Vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " pour charger js pour des modules ou des modèles uniquement lorsque cela est nécessaire et sans charger accidentellement JavaScript plusieurs fois pour plusieurs instances d'un module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Envisagez de remanier votre JS pour le rendre plus efficace. Utilisez moins de plug-ins JS, utilisez du HTML sémantique lorsque cela peut être utile. Par exemple, pour les listes déroulantes, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<details>"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<summary>"
        }), ". Pour les modaux, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<dialog>"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous utilisez une bibliothèque JS géante uniquement pour quelques petites fonctionnalités, envisagez d'utiliser Vanilla JS ou de charger un sous-ensemble de la bibliothèque si possible."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js"
        }), " pour charger JS uniquement lorsque cela est nécessaire et une seule fois par page. Lorsque vous utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", utilisez les attributs ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "async"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "defer"
        }), " pour améliorer les performances de la page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour contrôler où et quand le JavaScript d'un module se charge, utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "js_render_options"
        }), " dans le fichier meta.json du module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous chargez des ressources externes, utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/preconnect-and-dns-prefetch/",
          children: "préconnexion et prérécupération DNS"
        }), " de manière pertinente pour offrir une expérience plus rapide."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Limitez le nombre de scripts de suivi que vous utilisez. Les scripts de suivi essaient souvent de comprendre toutes les actions qu'un utilisateur effectue sur une page pour vous fournir des informations. C'est beaucoup de code qui analyse ce que fait l'utilisateur. Chaque script de suivi amplifie ce phénomène."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Outil de Conseils"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'outil de conseils HubSpot est un excellent moyen d'obtenir des informations sur les performances et le SEO de votre site web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/seo/view-seo-recommendations-in-hubspot",
        children: "En savoir plus sur l'outil de conseils"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alertes de code"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les alertes de code sont une fonctionnalité de CMS Hub Entreprise qui agit comme un aperçu centralisé des problèmes qui sont identifiés à l'intérieur de votre site web HubSpot CMS. La résolution des problèmes identifiés dans les Alertes de code peut contribuer à optimiser les performances de votre site web. Les problèmes identifiés comprennent plusieurs domaines différents, des limites du HubL aux problèmes des CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "En savoir plus sur les Alertes de code."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Que gère HubSpot pour vous ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La plupart des meilleures pratiques en matière de performance et de rapidité ne sont pas spécifiques à HubSpot. Le CMS de HubSpot gère automatiquement pour vous de nombreux problèmes de performance courants. Ces optimisations comprennent les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CDN avec optimisation des images et conversion automatique WebP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "HTTP2"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "JavaScript"
        }), " et minification CSS"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Mise en cache du navigateur et du serveur"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "Pré-rendu"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Réécriture de domaine"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#text-compression",
          children: "Compression Brotli (avec repli sur la compression GZIP)"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#accelerated-mobile-pages-amp-",
          children: "Les articles du blog HubSpot sont compatibles avec AMP"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous incluez un fichier CSS dans un module personnalisé, HubSpot charge intelligemment le fichier module.css uniquement lorsqu'un module est utilisé sur une page, et ne le charge qu'une seule fois, quel que soit le nombre d'instances du module sur la page. Par défaut, module.css ne se charge pas de manière asynchrone, mais vous pouvez changer cela en incluant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options"
      }), " dans le fichier meta.json du module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ressources supplémentaires pour améliorer la vitesse de votre site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il y a beaucoup de choses à faire pour optimiser la vitesse d'un site et beaucoup de ces thèmes méritent une analyse plus approfondie. Nous avons compilé d'excellentes ressources que nous vous encourageons à consulter lorsque vous travaillez à l'optimisation de votre site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/site-speed-and-performance-what-we-do-and-what-you-can-do",
          children: "Vitesse et performance des sites : ce que vous pouvez faire et comment HubSpot vous aide"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-we-improved-page-speed-on-hubspot.com",
          children: "Comment nous avons amélioré la vitesse des pages sur HubSpot.com"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/15-tips-to-speed-up-your-website",
          children: "15 conseils pour accélérer votre site web"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/how-to-reduce-your-websites-page-speed",
          children: "5 moyens simples de réduire le temps de chargement des pages de votre site web"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/google-page-speed",
          children: "guide en 8 étapes pour amener l'outil Google Page Speed à 100 %"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/website-optimization",
          children: "Optimisation du site web - HubSpot Academy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/learn/",
          children: "Web.dev"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Comment nous optimisons HubSpot CMS - Jeff Boulter"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.smashingmagazine.com/2021/04/humble-img-element-core-web-vitals/",
          children: "L'élément img modeste et Signaux web essentiels - Smashing Magazine"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Outils de performance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe de nombreux outils qui peuvent être utilisés pour améliorer les performances et les tester. Il est utile à la fois de comprendre ces outils et de les utiliser. Cette liste n'est en aucun cas exhaustive et provient de notre communauté de développeurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Optimisation de l'image"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'optimisation de vos images pour le web avant de les télécharger et de les publier vous permet de ne pas publier une image trop grande pour l'écran et le cas d'utilisation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Outils populaires d'optimisation des images :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imageoptim.com/mac",
          children: "ImageOptim"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/photoshop.html",
          children: "Adobe Photoshop"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/illustrator.html",
          children: "Adobe Illustrator"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imagecompressor.com/",
          children: "Optimizilla"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tests de performance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tester les performances et les optimiser devrait faire partie de la création de tout site web. Il existe une multitude d'outils permettant de tester la vitesse d'un site web. Il est important de comprendre ces outils et leur fonctionnement, afin de pouvoir prendre des décisions éclairées sur l'amélioration des performances."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Certains outils savent uniquement mesurer, d'autres évaluent. Il est important de comprendre comment cela fonctionne réellement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Outils de mesure"
      }), " testent généralement le temps de chargement, le temps d'exécution des scripts, le temps d'affichage du texte et des images (FCP), le temps de téléchargement des ressources sur le réseau, etc. Ces outils fournissent généralement des résultats qui indiquent des temps spécifiques pour chacun de ces paramètres. Si vous refaites le test, les mesures seront généralement légèrement modifiées, car le chargement de chaque page n'est pas exactement le même."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Outils d'évaluation"
      }), " ne se contentent pas de mesurer la vitesse, ils vous indiquent si votre performance est bonne ou mauvaise, souvent à l'aide d'un pourcentage ou d'une note avec une lettre. Ces notes sont destinées à être utilisées comme un outil pour motiver les développeurs et les équipes marketing à s'améliorer. Il y a tellement de paramètres et d'aspects différents de la performance qui doivent être pris en compte. Vous ne pouvez pas baser vos performances globales sur une seule mesure. De plus, certaines mesures ont des effets différents sur les performances perçues. Ces outils pondèrent différemment les paramètres pour calculer la performance globale. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Il n'existe pas de norme sectorielle sur la manière de pondérer les paramètres."
      }), " Au fil du temps, cette pondération peut changer, comme cela s'est produit avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://googlechrome.github.io/lighthouse/scorecalc/",
        children: "Google Page Speed"
      }), ". Il n'existe pas non plus de consensus dans le secteur sur ce qui est considéré comme une « bonne » valeur minimale ou maximale pour les différents paramètres. Certains outils se basent sur un percentile de sites web qui ont été testés. Cela signifie que vos résultats sont comparés à ceux d'autres sites qui ont été testés. En conséquence, la plage de vitesse « bonne/excellente » devient de plus en plus difficile à atteindre au fil du temps. Certains outils s'appuient plutôt sur l'expérience des utilisateurs, la fidélisation des visiteurs et les recherches basées sur le retour sur investissement (ROI) pour déterminer le seuil d'un bon score. Sachez également que tous les outils ne prennent pas en compte les performances de chargement ultérieur des pages. Par exemple, le système de modules de HubSpot sépare css et JS pour les modules individuels, et ne charge ces ressources que lorsque le module est effectivement placé sur la page. Il peut en résulter plusieurs fichiers css plus petits, qui pourraient être signalés par Google Page Speed Insights. En réalité, lors du prochain chargement de la page, vous n'aurez pas besoin de télécharger les css ou les js des modules qui se répètent sur la page suivante - ils seront mis en cache. Au lieu de cela, vous n'aurez à télécharger que les fichiers des modules que vous n'avez pas encore vus, et cela représentera des kilo-octets, au lieu d'un fichier monolithique."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'il s'agit d'outils de notation, vous devez utiliser plusieurs outils et vous efforcer d'obtenir le meilleur score possible dans chacun d'eux. Comprenez bien qu'ils pondéreront les choses différemment. Les efforts qui peuvent améliorer un score dans un outil peuvent ne pas l'améliorer dans d'autres."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Outils de test de performance populaires :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://website.grader.com/",
          children: "Website Grader"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://gtmetrix.com/",
          children: "GTMetrix"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/speed/pagespeed/insights/",
          children: "Google Page Speed Insights"
        }), " et d'autres ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/speed-tools",
          children: "Outils de performance de Google"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.pingdom.com/",
          children: "Pingdom"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.webpagetest.org/",
          children: "WebPageTest"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Associé :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/performance-scoring/",
          children: "Comment fonctionne le système de notation des performances Lighthouse"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Feuille de route pour l'optimisation des sites web (Core Web Vitals) | Mark Ryba"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}