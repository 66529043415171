"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77654816897;
const slug = exports.slug = 'guides/cms/setup/js-frameworks';
const title = exports.title = 'Verwenden von JavaScript-Frameworks in HubSpot';
const name = exports.name = 'EMEA DACH (DE) How to use JavaScript frameworks on HubSpot_DE';
const metaDescription = exports.metaDescription = 'Mithilfe von CMS Hub können Sie erweiterte JS-basierte Webanwendungen erstellen. Es stehen Boilervorlagen zur Verfügung, die Ihnen den Einstieg erleichtern. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "welche-ebene-von-cms-hub-wird-ben%C3%B6tigt%3F",
  "label": "Welche Ebene von CMS Hub wird benötigt?",
  "parent": null
}, {
  "depth": 0,
  "id": "frameworks-und-bibliotheken",
  "label": "Frameworks und Bibliotheken",
  "parent": null
}, {
  "depth": 0,
  "id": "was-sie-wissen-sollten",
  "label": "Was Sie wissen sollten",
  "parent": null
}, {
  "depth": 1,
  "id": "was-beim-erstellen-ber%C3%BCcksichtigt-werden-sollte",
  "label": "Was beim Erstellen berücksichtigt werden sollte",
  "parent": "was-sie-wissen-sollten"
}, {
  "depth": 2,
  "id": "serverseitiges-rendering-und-clientseitiges-rendering",
  "label": "Serverseitiges Rendering und clientseitiges Rendering",
  "parent": "was-sie-wissen-sollten"
}, {
  "depth": 2,
  "id": "single-page-app-spa-analysen",
  "label": "Single-Page-App (SPA)-Analysen",
  "parent": "was-sie-wissen-sollten"
}, {
  "depth": 2,
  "id": "erstellen-ihrer-app-mithilfe-von-hubspot-modulen",
  "label": "Erstellen Ihrer App mithilfe von HubSpot-Modulen",
  "parent": "was-sie-wissen-sollten"
}, {
  "depth": 2,
  "id": "erstellen-einer-vollst%C3%A4ndigen-vorlage-stattdessen",
  "label": "Erstellen einer vollständigen Vorlage stattdessen",
  "parent": "was-sie-wissen-sollten"
}, {
  "depth": 2,
  "id": "trennzeichen",
  "label": "Trennzeichen",
  "parent": "was-sie-wissen-sollten"
}, {
  "depth": 0,
  "id": "vuejs",
  "label": "VueJS",
  "parent": null
}, {
  "depth": 1,
  "id": "ohne-erstellungsschritt",
  "label": "Ohne Erstellungsschritt",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "die-vue-bibliothek-zu-ihrem-modul-hinzuf%C3%BCgen",
  "label": "Die Vue-Bibliothek zu Ihrem Modul hinzufügen",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "den-html-code-hinzuf%C3%BCgen",
  "label": "Den HTML-Code hinzufügen",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "ihren-javascript-code-hinzuf%C3%BCgen",
  "label": "Ihren JavaScript-Code hinzufügen",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "mit-einem-erstellungsschritt",
  "label": "Mit einem Erstellungsschritt",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "arbeiten-mit-hubspot-formularen-und-ctas-innerhalb-von-vue-komponenten",
  "label": "Arbeiten mit HubSpot-Formularen und CTAs innerhalb von Vue-Komponenten",
  "parent": "vuejs"
}, {
  "depth": 0,
  "id": "reactjs",
  "label": "ReactJS",
  "parent": null
}, {
  "depth": 0,
  "id": "andere-javascript-bibliotheken",
  "label": "Andere JavaScript-Bibliotheken",
  "parent": null
}, {
  "depth": 1,
  "id": "require_js-anstelle-von-skript-tags-verwenden",
  "label": "require_js anstelle von Skript-Tags verwenden",
  "parent": "andere-javascript-bibliotheken"
}, {
  "depth": 0,
  "id": "empfohlene-tutorials-und-leitf%C3%A4den",
  "label": "Empfohlene Tutorials und Leitfäden",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      em: "em",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Verwenden von JavaScript-Frameworks und -Bibliotheken in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe von CMS Hub können Sie JavaScript-basierte Webanwendungen erstellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Welche Ebene von CMS Hub wird benötigt?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre Website serverseitigen Code oder einen Mechanismus der Content-Mitgliedschaft erfordert, können Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverlosen Funktionen von HubSpot"
      }), " und die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "der Content-Mitgliedschaftsfunktion"
      }), " nutzen, wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "-Abonnement haben. Sie können jedoch alternativ Ihr eigenes System mit Drittanbietern wie AWS Lambda in Kombination mit einem API-Gateway erstellen, um serverseitigen Code auszuführen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine Webanwendung erstellen, die API-Endpunkte erreichen muss, die eine Authentifizierung für ein solches ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "Zugriffstoken von einer privaten App"
      }), " erfordern, sollten Sie diesen Code nicht im Browser ausführen. Sie würden Ihre Anmeldeinformationen jedem offenlegen, der die Seite aufruft. Der richtige Ansatz besteht darin, eine Abstraktionsebene zwischen dem Browser und der authentifizierten API zu erstellen: Ein benutzerdefinierter API-Endpunkt, der keine Offenlegung Ihrer Anmeldeinformationen erfordert und von der gleichen Domain wie die Website, die ihn aufruft, bereitgestellt wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn der benutzerdefinierte API-Endpunkt getroffen wird, wird serverseitiger Code ausgeführt, der die authentifizierte Anfrage durchführen kann. Dann können Sie jede Formatierung der Daten oder der Geschäftslogik, die Sie geheim halten möchten, vornehmen und das Ergebnis an den Browser senden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In der Regel werden dazu serverlose Funktionen verwendet, da sie unglaublich skalierbar sind und keine Verwaltung und Wartung Ihres eigenen Servers erfordern. Sie können Anbieter wie AWS Lambda in Kombination mit einem API-Gateway verwenden oder Sie können die proprietären", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverlosen Funktionen"
      }), " von HubSpot verwenden. Der Vorteil der serverlosen Funktionen von HubSpot besteht darin, dass Sie nicht mehrere separate Dienste verwalten müssen. Das Nutzererlebnis ist vereinfacht und direkt in dasselbe Entwickler-Dateisystem integriert, in dem Designs, Vorlagen und Module vorhanden sind."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie keine authentifizierten API-Aufrufe durchführen müssen, benötigen Sie für Ihre App kein Enterprise. React und Vue sind Frontend-Frameworks, die keine serverlosen Funktionen benötigen, um zu funktionieren. Was Sie mit ihnen tun, ist, worauf es ankommt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Frameworks und Bibliotheken"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für Webanwendungen verwenden Entwickler häufig JavaScript-Frameworks, die bei der Verwaltung des Status und der Benutzeroberfläche (UI) helfen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub wurde nicht speziell für die Arbeit mit einem bestimmten Framework entwickelt, aber viele gängige JavaScript-Frameworks funktionieren in CMS Hub. Wenn Sie auf Grundlage von HubSpot erstellen, müssen Sie möglicherweise darüber nachdenken, wie Sie mit diesen Frameworks anders arbeiten. Aber die wichtigsten Dinge, die für die Arbeit mit diesen Frameworks erforderlich sind, sind verfügbar: die Funktion, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "benutzerdefinierte Vorlagen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), " und JavaScript zu schreiben. Wir ermöglichen es Ihnen auch, Ihr ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "Programmieren lokal"
      }), " durchzuführen, damit Sie einen Erstellungsschritt verwenden können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Was Sie wissen sollten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wir arbeiten mit unserer Entwickler-Community zusammen, um die besten Methoden für das Erstellen von gemeinsamen JavaScript-Frameworks auf HubSpot zu schaffen. Obwohl es möglich ist, dies zu tun, müssen Sie Ihr Projekt aufgrund einiger Aspekte der Funktionsweise von CMS Hub möglicherweise bewusst anders als im Vergleich zu einer einfachen HTML-Seite einrichten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auch einige Teile Ihres Entwicklungsworkflows weichen unter Umständen von dem ab, was Sie gewöhnt sind. Wir bitten Sie, uns Ihr Feedback mitzuteilen, damit wir das Erlebnis für alle Entwickler verbessern können. Derzeit ist der beste Ort dafür in unserem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "Entwickler-Forum"
      }), ". Während wir experimentieren und lernen, wird unsere Dokumentation entsprechend aktualisiert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Was beim Erstellen berücksichtigt werden sollte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub verfügt über ein leistungsstarkes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modulsystem"
      }), ", mit dem Sie wiederverwendbare CSS-, JavaScript- und HTML-Blöcke mit Zugriff auf ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "die HubSpot-Vorlagensprache HubL"
      }), " erstellen können. Mit HubSpot-Modulen erhalten Content-Autoren viel Kontrolle und Leistungsfähigkeit. Moderne JavaScript-Frameworks verfügen oft über eigene Modulsysteme. Diese Systeme sind alle unabhängig voneinander entwickelt und haben daher oft unterschiedliche Lösungen für Probleme, auf die Sie stoßen könnten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Serverseitiges Rendering und clientseitiges Rendering"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serverseitiges Rendering ist, wenn das HTML für eine Seite aus der Vorlagenlogik auf dem Server generiert wird, bevor HTML an einen Browser gesendet wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Clientseitiges Rendern ist, wenn eine einfachere oder „unvollständige“ Version des HTML vom Server gesendet wird und JavaScript verwendet wird, um das HTML zu generieren. Dadurch wird die Verarbeitung der Logik vom Server an den Webbrowser (den Client) übertragen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hydratation ist der Vorgang des Kombinierens beider Techniken. Zuerst wird auf dem Server so viel HTML wie möglich generiert. Anschließend wertet JavaScript das bereitgestellte HTML aus und nimmt bei Bedarf kleinere Änderungen daran vor, wenn der Benutzer mit der Seite interagiert oder Daten empfängt. Dies reduziert die Belastung des Clients und reduziert möglicherweise die Zeit, die der Benutzer benötigt, um den geladenen Inhalt zu sehen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In CMS Hub wird HubL serverseitig verarbeitet und dann auf CDN-Ebene zwischengespeichert. Sie können dann JavaScript verwenden, um das HTML, das der Browser dem Website-Besucher anbietet, zu hydratisieren oder clientseitig zu rendern."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Single-Page-App (SPA)-Analysen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Analytics ist ein wichtiges Tool, damit Ihr Unternehmen wachsen und sich anpassen kann, um stets Lösungen für Ihre Kunden und potenziellen Kunden zu finden. Wenn Sie eine Single-Page-App erstellen, die mehrere Ansichten enthält, sollten Sie den Besucher, der verschiedene Ansichten sieht, als Seiten tracken."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die meisten Analytics-Plattformen, wie auch HubSpot, bieten eine Möglichkeit, dies mit JavaScript zu tun. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code#tracking-in-single-page-apps",
        children: "Verfolgen Sie die Seitenansicht nach,"
      }), " wenn Ihre App die Ansicht ändert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Erstellen Ihrer App mithilfe von HubSpot-Modulen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Modulsystem von HubSpot ist ein serverseitiges Modulsystem, das ein HTML-Dokument aus HubL + HTML-Partials generiert und minimiertes CSS und JavaScript für jedes Modul innerhalb einer Seite generiert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie mit HubSpot-Modulen erstellen, gibt es mehrere Vorteile, die damit einhergehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Content-Autoren können Ihr Modul zu Seiten mit Drag-&-Drop-Bereichen oder flexiblen Spalten hinzufügen. Sie können das Modul auch selbst verschieben und entfernen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können dem Content-Autor Felder bereitstellen, mit denen er Einstellungen für Ihre App konfigurieren kann."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ihr Code wird nur dann auf der Seite gerendert, wenn das Modul tatsächlich verwendet wird."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Module.css"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.js"
        }), " werden automatisch minimiert."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie das HubSpot-Modulsystem verwenden, müssen Sie jedoch auch beachten, dass Module aus bestimmten Dateien bestehen müssen und an anderen Orten erstellt werden müssen, als Sie Ihren Code normalerweise platzieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Erstellen einer vollständigen Vorlage stattdessen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Ihre Anwendung auch als Vorlage erstellen, anstatt im Modul-Framework. Dies gibt Ihnen mehr Flexibilität bei Ihrer Dateistruktur. Sie erhalten jedoch nicht die Vorteile, die Module bieten. Content-Autoren können diese Anwendung nicht zu Seiten innerhalb von Drag-&-Drop-Bereichen und flexiblen Spalten hinzufügen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Trennzeichen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einige JavaScript-Frameworks verwenden geschweifte Klammern ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ }"
      }), ", um ihren Code abzugrenzen. Die HubL-Sprache verwendet diese Klammern ebenfalls. Es gibt drei Strategien, mit denen Sie sicherstellen können, dass es keine Konflikte zwischen Ihrem Framework und HubL gibt: Sie können das RAW-HubL-Tag verwenden, um Ihren JSX zu umschließen, für das Framework festlegen, dass ein anderes Trennzeichen verwendet wird, oder einen Erstellungsschritt zu verwenden, mit dem das JavaScript zuvor kompiliert wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "VueJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das beliebte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/",
        children: "Vue.js"
      }), "-Framework kann mit und ohne Erstellungsschritt verwendet werden.- Eine detailliertere Aufschlüsselung der Vor- und Nachteile der einzelnen Methoden finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html",
        children: "Dokumentation von Vue"
      }), ". In HubSpot gibt es spezifische Vor- und Nachteile, die Sie ebenfalls beachten sollten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ohne Erstellungsschritt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Integration von Vue.js ohne Erstellungsschritt in ein Modul ist einfach."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Die Vue-Bibliothek zu Ihrem Modul hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie in Ihrer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "-Datei ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), ", um die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html#Direct-lt-script-gt-Include",
        children: "Vue-Bibliothek"
      }), " hinzuzufügen. Dadurch ist gewährleistet, dass sie nur einmal geladen wird, wenn Ihr Modul zu einer Seite hinzugefügt wird."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Verwenden Sie beim Entwickeln den Dev-Build, um nützliche Informationen zum Debuggen zu erhalten. In der Produktionsumgebung wird dann empfohlen, entweder die CDN-URL für die spezifische Vue-Version zu verwenden oder diese Datei herunterzuladen und als JavaScript-Datei im ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "Entwickler-Dateisystem"
        }), " von HubSpot zu hosten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Den HTML-Code hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Kopieren Sie den HTML-Code aus der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "Vue.js-Einführung"
      }), " und fügen Sie ihn in Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "-Datei ein. Umschließen Sie diesen Code mit einem HubL-raw-Tag, um zu verhindern, dass er als HubL ausgewertet wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# raw prevents code within it from being evaluated as HubL #}\n\n<div id=\"app\">\n  {{ message }}\n</div>\n\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ihren JavaScript-Code hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Kopieren Sie das JavaScript aus der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "Vue.js-Einführung"
      }), " und fügen Sie es in Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "-Datei ein. Umschließen Sie diesen Code mit einem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/API/Window/DOMContentLoaded_event",
        children: "Ereignis-Listener, um sicherzustellen, dass er ausgeführt wird, sobald der DOM-Inhalt geladen wurde"
      }), ". Veröffentlichen Sie Ihr Modul und sehen Sie es sich in der Vorschau an. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Sie sollten nun Ihre funktionierende grundlegende Vue-App sehen."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var app = new Vue({\n  el: '#app',\n  data: {\n    message: 'Hello Vue!',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mit einem Erstellungsschritt"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wir haben eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate",
        children: "Boilerplate"
      }), " [BETA] erstellt, um Ihnen zu helfen, mit dem HubSpot-Modul-Ansatz beim Erstellen einer VueJS-Anwendung zu arbeiten. Die einfachste Möglichkeit, von dieser zu profitieren, besteht darin, den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create vue-app"
      }), "-Befehl über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), " auszuführen. Anweisungen dazu finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/",
        children: "Repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Boilerplate ist neu und wir würden uns über Ihr Feedback freuen! Lassen Sie uns wissen, was verbessert werden könnte und welche Probleme Sie haben. Am besten geben Sie uns Ihr Feedback, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/issues",
        children: "Probleme im GitHub-Repository melden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Arbeiten mit HubSpot-Formularen und CTAs innerhalb von Vue-Komponenten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-CTAs und -Formulare verfügen über eigene Skript-Tags und verwalten ihr HTML selbst. Um sicherzustellen, dass Ihre Vue-Komponente das Formular oder den CTA nicht ändert, erstellen Sie ein HTML-Element um den CTA-/Formular-Einbettungscode herum. ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://vuejs.org/v2/api/#v-once",
        children: ["Wenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "V-once"
        }), " auf dieses Element an."]
      }), " Dadurch wird sichergestellt, dass der Code einmal gerendert und dann von Ihrer Vue-Komponente ignoriert wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ReactJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anstatt HubL zum Erstellen von Modulen und Partials zu verwenden, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
        children: "JavaScript und React"
      }), " verwenden. Neben dem Einfügen von server-gerenderten React-Komponenten in das von HubL generierte HTML unterstützen JavaScript-Module und -Partials sowohl serverseitige als auch clientseitige Interaktivitäten. Weitere Informationen finden Sie in HubSpots ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "Einführung zu grundlegenden JS-Komponenten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können sich auch die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "React-Boilerplate"
      }), " ansehen, um schnell mit einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://reactjs.org/",
        children: "React"
      }), "-App innerhalb eines HubSpot-Moduls loszulegen. Die einfachste Möglichkeit, von dieser zu profitieren, besteht darin, den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create react-app"
      }), "-Befehl über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), " auszuführen. Folgen Sie dann dort den Anweisungen im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "Repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Boilerplate ist neu und wir würden uns über Ihr Feedback freuen! Lassen Sie uns wissen, was verbessert werden könnte und welche Probleme auftreten. Am besten geben Sie uns Ihr Feedback, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate/issues",
        children: "Probleme im GitHub-Repository melden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Andere JavaScript-Bibliotheken"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt viele JavaScript-Bibliotheken und es ist für uns unmöglich, alle einzeln zu dokumentieren. Es gibt einige grundlegende bewährte Methoden, die Sie bei der Verwendung von JavaScript-Bibliotheken in HubSpot kennen und verstehen sollten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js anstelle von Skript-Tags verwenden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können über Dutzende von Modulen und Vorlagen verfügen, die gemeinsame JavaScript-Bibliotheken verwenden, und diese Bibliotheken ohne Bedenken mehrmals laden. Dazu müssen Sie die Funktion ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js HubL"
        })
      }), " verwenden. Skripts, die mit dieser Funktion geladen werden, werden nur einmal pro Seite geladen, unabhängig davon, wie viele Module, Partials und die Vorlage sie erfordern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{{ require_js(get_asset_url('/js/jquery-latest.js')) }}\n\n{{ require_js(\"https://cdnjs.cloudflare.com/ajax/libs/d3/6.2.0/d3.min.js\") }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get_assets_url()"
        }), ", um Dateien zu verlangen, die im Entwickler-Dateisystem gespeichert werden. Der Vorteil abgesehen davon, dass Sie Ihre Entwicklungsdateien gemeinsam lokalisieren und die Sicherheit dieser Dateien konsolidieren, besteht darin, dass dies zu weniger DNS-Suchvorgängen führt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Verwendung von require kann die Leistung erheblich steigern, da Sie nicht nur die Datei nur einmal laden. Wenn Inhaltselemente auf einer Seite diese Bibliothek nicht benötigen, wird sie überhaupt nicht geladen. Sie können sogar requires mit HubL-Logik verwenden, um Ressourcen nur dann zu laden, wenn Sie sie wirklich benötigen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Empfohlene Tutorials und Leitfäden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimieren für eine Leistungsverbesserung"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Barrierefreiheit ist kein Feature"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Verwenden von Webkomponenten in HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Erste Schritte mit Modulen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Erste Schritte mit serverlosen Funktionen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Erstellen eines effizienten Entwickler-Workflows"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Erstellen von dynamischen Seiten mit HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
          children: "Module und Partials mit JavaScript erstellen"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}