"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 48268518828;
const slug = exports.slug = 'guides/cms/content/performance/prerendering';
const title = exports.title = 'Prerendering';
const name = exports.name = '[Developers] Prerendering';
const metaDescription = exports.metaDescription = 'Prerendering is where the HubSpot CMS makes a static version of your website page, landing page, blog post or knowledge base article. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "overview",
  "label": "Overview",
  "parent": null
}, {
  "depth": 0,
  "id": "check-if-a-page-is-prerendered",
  "label": "Check if a page is prerendered",
  "parent": null
}, {
  "depth": 0,
  "id": "incompatible-features",
  "label": "Incompatible features",
  "parent": null
}, {
  "depth": 1,
  "id": "incompatible-hubl-variables",
  "label": "Incompatible HubL variables",
  "parent": "incompatible-features"
}, {
  "depth": 1,
  "id": "incompatible-hubl-functions",
  "label": "Incompatible HubL functions",
  "parent": "incompatible-features"
}, {
  "depth": 1,
  "id": "incompatible-functionalities",
  "label": "Incompatible functionalities",
  "parent": "incompatible-features"
}, {
  "depth": 0,
  "id": "how-can-i-make-my-pages-compatible-with-prerendering%3F",
  "label": "How can I make my pages compatible with prerendering?",
  "parent": null
}, {
  "depth": 0,
  "id": "what-is-partial-prerendering%3F",
  "label": "What is partial prerendering?",
  "parent": null
}, {
  "depth": 0,
  "id": "how-can-i-tell-if-my-page-uses-partial-prerendering%3F",
  "label": "How can I tell if my page uses partial prerendering?",
  "parent": null
}, {
  "depth": 1,
  "id": "hubl-variables",
  "label": "HubL variables",
  "parent": "how-can-i-tell-if-my-page-uses-partial-prerendering%3F"
}, {
  "depth": 1,
  "id": "hubl-filters",
  "label": "HubL filters",
  "parent": "how-can-i-tell-if-my-page-uses-partial-prerendering%3F"
}, {
  "depth": 0,
  "id": "how-does-prerendering-affect-css-combining%3F",
  "label": "How does prerendering affect CSS combining?",
  "parent": null
}, {
  "depth": 0,
  "id": "without-page-level-automatic-css-combining%2C-how-can-i-optimize-delivery-of-css%3F",
  "label": "Without page-level automatic CSS combining, how can I optimize delivery of CSS?",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      img: "img",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Prerendering"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To improve page load speed and security, HubSpot automatically creates static versions of pages, blog posts, and knowledge base articles when possible. This means that, rather than assembling the page's data and layout for each request, HubSpot will render the page ahead of time and push it to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "CDN"
      }), ". Local copies of prerendered pages are stored around the globe so that users across the world can access pages faster based on their location."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This is separate from the concept of caching. All prerendered pages are cacheable on HubSpot's CDN. Prerendering is the process by which the HubSpot CMS generates the static version of a page. Even if the CDN doesn't have a page cached, requests will be faster because the page is already in its final rendered form."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Not all pages are eligible for prerendering, depending on the type of content it serves. When a page is not eligible, HubSpot will continue to serve that page dynamically. Below, learn more about prerendering."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Updating data that affects your page will automatically trigger a render. For example, publishing an edit to a page's template will re-render it. Changes to other shared data, such as CMS settings, may cause pages pages to be re-rendered."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Generally, the prerendering process takes seconds to complete. If a large change is made, such as updating a module that’s included in many templates and pages, it may be minutes before all pages are updated."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CSS, JavaScript and images files have always been statically generated on HubSpot, so they have effectively always been prerendered."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubL in JS or CSS files is evaluated only once when the asset is published."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Check if a page is prerendered"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The easiest way to tell if a page is prerendered is by looking at the HTTP response headers of the request. If it includes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HS-Prerendered"
      }), " header, the page is prerendered. The value of the header is the last time the page was prerendered."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/chrome-network-tab-prerendered.png",
        alt: "chrome network tab showing prerendered header"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 7,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The other way to tell is to load the page with a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?hsDebugOnly=true"
            }), " query param. This will include an indication if the page can be prerendered. If it can't be prerendered, a list of issues that prevent prerendering will be listed. If the formatting of the debug information is hard to read you can use the parameter ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?hsDebug=true"
            }), " instead, then inspect your page the same debug information will appear in a formatted HTML comment near the bottom of your page."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/hsDebugTrue-prerender.png",
            alt: "Screenshot of HTML comment showing that page can be prerendered"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Incompatible features"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The use of the features below prevents the same response from being served to every user, so they prevent the serving of a static prerendered page. Pages using some of these features may be served using partial prerendering."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incompatible HubL variables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "account"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "local_dt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "owner"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.cookies"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.full_url"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "request.geoip*"
        }), " (deprecated)"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.headers"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.path_and_query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.query_dict"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.referrer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.remote_ip"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "year"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "The HubL request variables generally have JavaScript based alternatives you can use. Enabling you to avoid using those variables."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incompatible HubL functions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "personalization_token"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "unixtimestamp"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incompatible functionalities"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Password-protected pages"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pages that use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/create-an-adaptive-test-for-a-page",
          children: "adaptive tests"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pages that include ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-manage-smart-content-rules",
        children: "smart content"
      }), " are supported via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#what-is-partial-prerendering-",
        children: "partial pre-rendering"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How can I make my pages compatible with prerendering?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Load the page with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebugOnly=true"
      }), " query param, and there will be additional output that can help point to specific features that prevent pre-rendering. This additional output includes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A list of specific features that may be causing issues"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Specific files and line numbers of templates that use uncacheable variables"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When possible, move all dynamic rendering of content to JavaScript executed in the user’s browser. You may also use Serverless Functions to pull in dynamic data."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What is partial prerendering?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Partial prerendering allows HubSpot to serve “mostly” prerendered pages. For example, a page may be entirely static except for a contact’s name displayed on the page. The page can be prerendered except for that contact name. Just before returning the page to the user, HubSpot will perform a render of just those dynamic values."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pages that use partial prerendering can't be cached at the CDN or the browser. However, partially prerendered pages are faster to deliver than pages that can't be partially prerendered. Partially prerendered pages also have the ability to fall back to an unpersonalized state in case of an outage or an attack."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While partial prerendering may help the speed and reliability of your site, removing the HubL features that make pages non-prerenderable will have a much greater positive effect on your overall page performance."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How can I tell if my page uses partial prerendering?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Load the page with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsPrcDebug=true"
      }), " query param, and there will be additional output about the pre-rendered content for that page. If the page is prerendered, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HS-Prerendered"
      }), " header will be present and contain ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "partial"
      }), " before the time the page was partially prerendered."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["The features below ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "are currently supported"
        }), " with partial prerendering"]
      }), ". The page will be partially pre-rendered and expressions using these will be instead be evaluated at serve-time."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubL variables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "account"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "local_dt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "owner"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "year"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubL filters"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "|random"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "|shuffle"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How does prerendering affect CSS combining?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With page prerendering, HubSpot no longer automatically combines CSS at the page-level. There’s several reasons for this:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CSS combining may optimize a single page view, but it hurts subsequent page views because all the CSS has to be re-downloaded for each page."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HTTP/2 (all HubSpot sites served over SSL use HTTP/2) ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/http2#request_and_response_multiplexing",
          children: "solves a lot of the problems"
        }), " with many CSS files, so combining CSS doesn’t provide as much benefit as it used to."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "To verify that the combined CSS doesn’t break anything on a page, HubSpot runs an automated visual check on the page with the combined CSS. The visual checker isn’t 100% effective (consider elements that only appear or scroll, JavaScript animations, etc.) so sometimes it will miss problems."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Prerendering pages is much more important than CSS combining for site availability and speed. Since it’s required to verify combined CSS after every renderer, it’s not feasible to also combine and visually verify CSS on every change."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "This is referring to functionality that historically combined module and page styles into one monolithic file per page."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This has no effect on CSS files that are combined using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "include"
        }), " tag."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Without page-level automatic CSS combining, how can I optimize delivery of CSS?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Take advantage of modules. If your CSS is unique to the module, put it in the module's CSS or a CSS file you are tying to the module with require_css. This means the small css file specific to just this module will only load on pages where that module is used. For subsequent page loads, the CSS for modules already seen by the user will be cached and they only need to download the CSS for assets they haven't yet seen."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "If you share styles like layout or utility classes etc in multiple modules or modules are visually similar so they share a lot of CSS, consider creating a CSS file that you require_css in each of the modules that need it. This enables you to avoid repeating CSS in each module, while also getting the caching benefits."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Another way to consider this is also modules that are used everywhere on your site - for example if your header and footer modules don't change per page, consider using this approach for their CSS styles."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Optimizing CMS Hub site performance"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/js-frameworks",
          children: "Using JavaScript frameworks and libraries on HubSpot"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}