"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529760;
const slug = exports.slug = 'reference/cms/serverless-functions';
const title = exports.title = 'Referência das funções sem servidor';
const name = exports.name = 'LATAM BR (PT) Serverless Reference';
const metaDescription = exports.metaDescription = 'Informações de referência para seu serverless.json, arquivos de função, endpoints, comandos da CLI e gerenciamento de pacotes em um só lugar.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": null
}, {
  "depth": 1,
  "id": "endpoints",
  "label": "Endpoints",
  "parent": "serverless.json"
}, {
  "depth": 0,
  "id": "arquivo-de-fun%C3%A7%C3%B5es",
  "label": "Arquivo de funções",
  "parent": null
}, {
  "depth": 1,
  "id": "objeto-de-contexto",
  "label": "Objeto de contexto",
  "parent": "arquivo-de-fun%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "cabe%C3%A7alhos",
  "label": "Cabeçalhos",
  "parent": "arquivo-de-fun%C3%A7%C3%B5es"
}, {
  "depth": 2,
  "id": "redirecionar-enviando-um-cabe%C3%A7alho",
  "label": "Redirecionar enviando um cabeçalho",
  "parent": "arquivo-de-fun%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "definir-cookies-a-partir-do-seu-endpoint",
  "label": "Definir cookies a partir do seu endpoint",
  "parent": "arquivo-de-fun%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "definir-v%C3%A1rios-valores-para-um-%C3%BAnico-cabe%C3%A7alho",
  "label": "Definir vários valores para um único cabeçalho",
  "parent": "arquivo-de-fun%C3%A7%C3%B5es"
}, {
  "depth": 0,
  "id": "segredos",
  "label": "Segredos",
  "parent": null
}, {
  "depth": 0,
  "id": "usando-fun%C3%A7%C3%B5es-sem-servidor-com-o-elemento-de-formul%C3%A1rio",
  "label": "Usando funções sem servidor com o elemento de formulário",
  "parent": null
}, {
  "depth": 0,
  "id": "cors",
  "label": "CORS",
  "parent": null
}, {
  "depth": 1,
  "id": "solicita%C3%A7%C3%B5es-get",
  "label": "Solicitações GET",
  "parent": "cors"
}, {
  "depth": 0,
  "id": "pacotes-predefinidos",
  "label": "Pacotes predefinidos",
  "parent": null
}, {
  "depth": 0,
  "id": "limites",
  "label": "Limites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      h4: "h4",
      em: "em",
      ul: "ul",
      li: "li",
      br: "br",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Referência das funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se estiver criando uma função sem servidor como parte de um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "projeto de desenvolvedor"
        }), ", visite a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "documentação para desenvolvedores sobre a função sem servidor em projetos"
        }), ". A documentação abaixo é destinada a criação de funções sem servidor fora da plataforma do projeto do desenvolvedor."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neste artigo, saiba sobre os arquivos encontrados dentro de uma ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: ["pasta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), " sem servidor"]
      }), " e os comandos da CLI que você pode usar com funções sem servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para uma visão geral de alto nível das funções sem servidor, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "visão geral das funções sem servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter mais informações sobre como criar funções sem servidor com projetos para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "módulos renderizados e parciais em JavaScript"
      }), ", consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "documentação para desenvolvedores sobre projetos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na pasta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        })
      }), ", o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " armazena a configuração da função sem servidor. Este é um arquivo obrigatório e mapeia as funções aos", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#endpoints",
        children: "endpoints"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// serverless.json\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"environment\": {\n    \"globalConfigKey\": \"some-value\"\n  },\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"events\": {\n      \"file\": \"function1.js\",\n      \"method\": \"GET\"\n    },\n    \"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"CONFIG_KEY\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleKeyName\", \"otherkeyname\"]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "chave"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "runtime"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ambiente de tempo de execução. Suporta as seguintes versões do ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/en/about/",
              children: "Node.js"
            }), ":", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Node 20 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs20.x"
                }), ") (recomendado)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Node 18 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs18.x"
                }), ")"]
              })]
            }), "Observe que a HubSpot deixará de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/changelog/deprecation-of-node-v16-in-all-serverless-functions",
              children: "oferecer suporte ao Node 16"
            }), " a partir de 12 de julho de 2024."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Versão do esquema de função sem servidor do HubSpot. (Versão atual 1.0)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "environment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Variáveis de configuração passadas para a função em execução como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
              children: "variáveis de ambiente"
            }), " no tempo de execução. Você pode usar isso para adicionar uma lógica que permita usar uma versão de teste de uma API em vez da versão real, com base em uma variável de ambiente."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma matriz que contém os nomes dos segredos que sua função sem servidor usará para autenticação. Não armazene segredos diretamente neste arquivo, apenas faça referência aos nomes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoints"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os endpoints definem os caminhos que são expostos e seu mapeamento a arquivos JavaScript específicos, dentro da pasta de funções. Saiba mais sobre os endpoints abaixo."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " não atribua os mesmos nomes aos seus segredos e variáveis de ambiente. Se você fizer isso, poderá haver conflitos ao retornar os valores na função."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Endpoints"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada endpoint pode ter suas próprias ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "variáveis de ambiente"
      }), " e segredos. As variáveis especificadas fora dos endpoints devem ser usadas para definições de configuração que se aplicam a todas as funções e endpoints."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"configKey\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleAPIKeyName\",\"otherKeyName\"]\n    }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os endpoints têm algumas chaves exclusivas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "chave"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String ou array de strings"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
              children: "Método HTTP"
            }), " ou métodos compatíveis com o endpoint. Assume o padrão ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
              children: "GET"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Caminho para o arquivo de função JavaScript com a implementação do endpoint."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções sem servidor são expostas por meio de um caminho no domínio da sua conta do HubSpot CMS. Inclui os subdomínios padrão ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-sites.com"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode acessar essas funções no seguinte URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://{domainName}/_hcms/api/{endpoint-name/path}?portalid={hubId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre cada componente do URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "domainName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do seu domínio."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/_hcms/api/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O caminho reservado para funções sem servidor. Todos os pontos de extremidade existem dentro deste caminho."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoint-name/path"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome ou caminho do ponto de extremidade especificado no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Seu Hub ID. Fornecer essa informação na solicitação permitirá testar as funções nas visualizações de módulos e modelos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Arquivo de funções"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além do arquivo de configuração", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), ", a pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " também conterá um arquivo JavaScript ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " que define a função. Você também pode aproveitar a biblioteca de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/request/request#readme",
        children: "solicitações"
      }), " para fazer solicitações HTTP para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "APIs da HubSpot"
      }), ", entre outras."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Require axios library, to make API requests.\nconst axios = require('axios');\n// Environment variables from your serverless.json\n// process.env.globalConfigKey\n\nexports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n\n  // context.params\n  // context.body\n  // context.accountId\n  // context.limits\n\n  // secrets created using the CLI are available in the environment variables.\n  // process.env.secretName\n\n  //sendResponse is what you will send back to services hitting your serverless function.\n  sendResponse({ body: { message: 'my response' }, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objeto de contexto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O objeto de contexto contém informações contextuais sobre a execução da função, armazenadas nos parâmetros a seguir."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da conta da HubSpot que contém a função."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Preenchido se a solicitação for enviada como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " com um tipo de conteúdo de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "application/json"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se a solicitação for de um contato com cookie, o objeto de contato será preenchido com um conjunto de propriedades básicas, juntamento com as seguintes informações:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": o ID de visitante do contato."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "isLoggedIn"
                }), ": ao usar associações do CMS, isso será ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " se o contato estiver logado no domínio."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "listMemberships"
                }), ": uma matriz de IDs de listas de contatos das quais este contato é membro."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contém ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#headers",
              children: "cabeçalhos"
            }), " enviados do cliente que acessa seu endpoint."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "params"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Preenchido com valores de string de consulta, juntamente com quaisquer valores de formulário HTML via POST. São estruturados como um mapa, tendo strings como chaves e uma matriz de strings para cada valor.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "context.params.yourvalue"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limits"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna o quão perto você está de atingir os ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview#know-your-limits",
              children: "limites de funções sem servidor"
            }), ".", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "executionsRemaining"
                }), ": o número restante de execuções por minuto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "timeRemaining"
                }), ": o número restante de tempo de execução permitido."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cabeçalhos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se precisar saber os cabeçalhos do cliente que está atingindo o seu endpoint, você pode acessá-los por meio de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.headers"
      }), ", semelhante à forma como acessa as informações por meio de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.body"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Reveja alguns dos cabeçalhos comuns fornecidos pelo HubSpot. Para obter uma lista completa, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers",
        children: "documentação de cabeçalhos HTTP da MDN"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Cabeçalho"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica os tipos de conteúdo expressos como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types",
              children: "tipos MIME"
            }), " que o cliente entende. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-encoding"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica o conteúdo de codificação que o cliente entende. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-encoding",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica qual idioma e localização são preferidos pelo usuário. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-language",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cache-control"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Mantém diretivas para armazenamento em cache. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "connection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica se a conexão de rede permanece aberta. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/connection",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cookie"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contém os cookies enviados pelo cliente. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/cookie",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica o nome do domínio e o número da porta TCP de um servidor em modo de escuta. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/host",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true-client-ip"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O endereço IP do usuário final. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/206776727-What-is-True-Client-IP-",
              children: "Consulte Cloudflare true-client-ip"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upgrade-insecure-requests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica a preferência dos clientes por uma resposta criptografada e autenticada. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Upgrade-Insecure-Requests",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "user-agent"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["String definida pelo fornecedor que identifica o aplicativo, o sistema operacional, o fornecedor do aplicativo e a versão. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent",
              children: "Consulte a MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "x-forwarded-for"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifica o endereço IP de origem de um cliente por meio de um proxy ou balanceador de carga. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For",
              children: "Consulte a MDN"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Redirecionar enviando um cabeçalho"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode realizar um redirecionamento a partir de sua função sem servidor enviando uma resposta com um cabeçalho de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "localização"
      }), " e o statusCode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "301"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "sendResponse({\n  statusCode: 301,\n  headers: {\n    Location: 'https://www.example.com',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir cookies a partir do seu endpoint"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Na sua função sem servidor, você pode instruir o cliente (navegador da web) a definir um cookie."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n    sendResponse({\n      body: { ... },\n      'Set-Cookie': 'myCookie1=12345; expires=...; Max-Age=...',\n      statusCode: 200\n    });\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir vários valores para um único cabeçalho"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para cabeçalhos compatíveis com vários valores, você pode usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multiValueHeaders"
      }), " para passar os valores. Por exemplo: você pode instruir o navegador a definir vários cookies."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  sendResponse({\n    body: { ... },\n    multiValueHeaders: {\n      'Set-Cookie': [\n        'myCookie1=12345; expires=...; Max-Age=...',\n        'myCookie2=56789; expires=...; Max-Age=...'\n       ]\n    },\n    statusCode: 200\n  });\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Segredos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se precisar autenticar uma solicitação de função sem servidor, você usará segredos para armazenar valores como chaves de API ou tokens de acesso a aplicativos privados. Usando a CLI, você pode adicionar segredos à sua conta da HubSpot para armazenar esses valores, os quais poderá acessar posteriormente por meio de variáveis de ambiente (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "process.env.secretName"
      }), "). Os segredos são gerenciados por meio da CLI da HubSpot usando os seguintes comandos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#list-secrets",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets list"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#add-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets add"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#remove-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets delete"
          })
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma vez adicionados por meio da CLI, os segredos podem ser disponibilizados para as funções ao incluir uma matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), "contendo o nome do segredo. Isso permite armazenar seu código de função no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "controle de versão"
      }), " e usar segredos sem expô-los. No entanto, você ", (0, _jsxRuntime.jsx)("u", {
        children: "nunca"
      }), " deve retornar o valor do seu segredo por meio do registro do console ou como uma resposta, pois isso irá expor o segredo nos registos ou nas páginas de front-end que chamam a função sem servidor."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " devido ao armazenamento em cache, pode levar cerca de um minuto para ver os valores secretos atualizados. Se você acabou de atualizar um segredo, mas ainda está vendo o valor antigo, verifique novamente após cerca de um minuto."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usando funções sem servidor com o elemento de formulário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao enviar funções sem servidor, use javascript para lidar com o envio de formulário e inclua o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"contentType\" : \"application/json\""
      }), " em sua solicitação. Não use o atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "action"
      }), " em elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<form>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CORS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cross Origin Resource Sharing (CORS)"
        })
      }), " é um recurso de segurança do navegador. Por padrão, os navegadores restringem solicitações de origem cruzada iniciadas por JavaScript. Isso evita que códigos maliciosos em execução em um domínio diferente afetem seu site. Esse processo é chamado de política de mesma origem. Como o envio e a recuperação de dados de outros servidores às vezes são uma necessidade, o servidor externo pode fornecer cabeçalhos HTTP que indiquem quais origens têm permissão para ler as informações a partir de um navegador."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você não deve ter problemas de CORS ao chamar sua função sem servidor nas páginas hospedadas pela HubSpot. Se fizer isso, verifique se está usando o protocolo correto."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Está obtendo esse erro CORS?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"O acesso à busca em [URL da sua função] a partir da origem [página que fez a solicitação] foi bloqueado pela política de CORS: a resposta à solicitação de preflight não passou na verificação de controle de acesso: o cabeçalho 'Access-Control-Allow-Origin' não está presente no recurso solicitado. Se uma resposta opaca atender às suas necessidades, defina o modo da solicitação para 'no-cors' para buscar o recurso com o CORS desativado.\""
        })]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sua solicitação tem uma origem diferente da do site que a chama?"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Se o nome de domínio for diferente, sim."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Se estiver usando um protocolo diferente (http, https), sim."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Se estiver usando um protocolo diferente, basta alterar o protocolo para que ele corresponda e isso resolverá o problema."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Não é possível modificar o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Access-Control-Allow-Origin"
        }), " do HubSpot no momento."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors",
          children: "Consulte a MDN para obter mais informações detalhadas sobre a solução de problemas de erros CORS."
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Solicitações GET"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As solicitações GET podem ser capazes de fazer solicitações ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: "CORS"
      }), " dependendo do cliente. Não faça com que as solicitações ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
        children: "GET"
      }), " escrevam algo, apenas retornem dados."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pacotes predefinidos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Atualmente, as funções sem servidor da HubSpot vêm predefinidas com os seguintes pacotes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/@hubspot/api-client",
          children: "@hubspot/api-client"
        }), ": ^1.0.0-beta"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ": ^0.19.2"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        }), ": ^2.88.0"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/requests",
          children: "requests"
        }), ": ^0.2.2"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para usar a versão compatível mais recente de um pacote predefinido ou para usar um pacote recém-adicionado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Clone ou copie seu arquivo de função."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Altere o endpoint da sua função no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " para direcionar para o seu novo arquivo de função. Você pode excluir com segurança a versão antiga."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se quiser incluir pacotes que não fazem parte do conjunto de pacotes predefinidos, você pode usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " para combinar seus módulos Node e fazer com que seus arquivos empacotados sejam seus arquivos de função."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As funções sem servidor destinam-se a ser rápidas e a ter foco restrito. Para permitir chamadas e respostas rápidas, as funções sem servidor da HubSpot estão limitadas a:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 segredos por conta."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 MB de memória."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Não mais do que 100 endpoints por conta da HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você deve usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contentType"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " ao chamar uma função."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os registros de funções sem servidor são armazenados por 90 dias."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6 MB em uma carga útil de invocação do AWS Lambda"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Limites de execução"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada função tem no máximo 10 segundos de tempo de execução"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada conta é limitada a um total de 600 segundos de execução por minuto."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso significa que qualquer um destes cenários pode acontecer:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "60 execuções de funções que levam 10 segundos para serem concluídas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6.000 execuções de funções que levam 100 milissegundos para serem concluídas."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções que excedem esses limites geram um erro. A contagem de execução e os limites de tempo retornarão uma resposta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), ". O tempo de execução de cada função está incluído nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "logs das funções sem servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ajudar a evitar esses limites, os dados de limite são fornecidos automaticamente no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: "contexto da função"
      }), " durante a execução. Você pode usar esses dados para fazer com que o aplicativo permaneça dentro desses limites. Por exemplo, se seu aplicativo exige a consulta periódica de um endpoint, você pode retornar, junto com os dados, uma variável para influenciar a frequência dessa consulta. Dessa forma, quando o tráfego estiver alto, você pode diminuir a taxa de consulta, evitando atingir os limites e, em seguida, aumentá-lo novamente quando o tráfego estiver baixo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}