"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 139940706820;
const slug = exports.slug = 'guides/crm/developer-projects/platform-versioning';
const title = exports.title = 'Project platform versioning (BETA)';
const name = exports.name = 'Project platform versioning (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the sample projects that HubSpot provides to get started with developing UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "manage-versions",
  "label": "Manage versions",
  "parent": null
}, {
  "depth": 0,
  "id": "versions",
  "label": "Versions",
  "parent": null
}, {
  "depth": 0,
  "id": "changes-in-2023.2",
  "label": "Changes in 2023.2",
  "parent": null
}, {
  "depth": 1,
  "id": "serverless-function-configuration",
  "label": "Serverless function configuration",
  "parent": "changes-in-2023.2"
}, {
  "depth": 1,
  "id": "async-support",
  "label": "Async support",
  "parent": "changes-in-2023.2"
}, {
  "depth": 2,
  "id": "before",
  "label": "Before",
  "parent": "changes-in-2023.2"
}, {
  "depth": 2,
  "id": "after",
  "label": "After",
  "parent": "changes-in-2023.2"
}, {
  "depth": 1,
  "id": "private-app-access-token-authentication",
  "label": "Private app access token authentication",
  "parent": "changes-in-2023.2"
}, {
  "depth": 1,
  "id": "improved-logging",
  "label": "Improved logging",
  "parent": "changes-in-2023.2"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      h4: "h4",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Projects platform versioning (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot developer projects include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "platformVersion"
      }), " field that enables you to control which version of the projects platform you're developing on. This enables you to access updated functionalities, rollback to previous versions, and generally coordinate changes as needed."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn how to set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "platformVersion"
      }), " in a project, along with the available versions and their updates."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage versions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To set which version of the developer platform your projects are running on, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "platformVersion"
      }), " field in your project's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsproject.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After changing the version number, you'll need to upload the project to the HubSpot account to enable access to the version updates."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example project.json config file\n{\n  \"name\": \"my_project\",\n  \"srcDir\": \"src\",\n  \"platformVersion\": \"2023.2\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Versions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Latest version"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.2"
        }), " (November 21, 2023)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Initial release:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.1"
        }), " (sunset as of March 31, 2024)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "2023.2"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Available as of November 21, 2023.Changes primarily impact app functions, including:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Support for asynchronous functions (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "async"
                }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "await"
                }), ")"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Public URL support for app functions"
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "PRIVATE_APP_ACCESS_TOKEN"
                }), " moved from ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "context.secrets"
                }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "process.env"
                })]
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Increased log size from 4KB to 256KB with logs now in order of execution"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Support for Node 18"
              })]
            }), "Learn more about working with these changes below."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "2023.1"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Sunset"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Initial release of the developer platform.This version is no longer be available as of March 31, 2024. Attempts to upload projects at this version will fail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Changes in 2023.2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Version ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2023.2"
      }), " of the developer platform includes the changes below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless function configuration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following changes have been made for serverless function configuration (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Previously, serverless functions in projects supported two types of functions: app and API endpoint. App functions have been updated to support public URLs for making API requests, so you no longer need to build these as separate types."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With this update, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "runtime"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "version"
        }), " fields have also been removed."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "This version uses Node18, and lower versions cannot be specified."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Previous serverless function configuration"
            })
          })
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// serverless.json before update\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"appFunctions\": {\n    \"functionName\": {\n       \"file\": \"function1.js\"\n    }\n  },\n\"endpoints\": {\n  \"path/to/endpoint\": {\n  \"file\": \"githubUserFunction.js\",\n  \"method\": [\"GET\", \"POST\"]\n  }\n }\n}\n"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Updated serverless function configuration"
            })
          })
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// serverless.json after update\n{\n  \"appFunctions\": {\n    \"functionName\": {\n       \"file\": \"function1.js\",\n       \"endpoint\": {\n          \"path\": \"path/to/endpoint\",\n          \"method\": [\"GET\"],\n        }\n     }\n  }\n}\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Async support"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Projects now support asynchronous functions. Callbacks are no longer supported in this version."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To update your serverless functions to use async:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "async"
        }), " to the function definition."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Remove the callback (sometimes referred to as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendResponse"
        }), "), and use return statements to return response."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "await"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "try"
        }), "/", (0, _jsxRuntime.jsx)(_components.code, {
          children: "catch"
        }), " instead of promise chaining."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Return the desired response or throw an error."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Before"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspot = require('@hubspot/api-client');\n\nexports.main = myFunction = (context = {}, sendResponse) => {\n  const { hs_object_id } = context.propertiesToSend;\n  const userId = context.parameters.userId;\n\n  const hubspotClient = new hubspot.Client({\n    accessToken: process.env['PRIVATE_APP_ACCESS_TOKEN'],\n  });\n\n  const properties = {\n    name: 'newName',\n  };\n  const SimplePublicObjectInput = { properties };\n  const objectType = 'myObject';\n  const objectId = hs_object_id;\n  const idProperty = undefined;\n\n  hubspotClient.crm.objects.basicApi\n    .update(objectType, objectId, SimplePublicObjectInput, idProperty)\n    .then((res) => {\n      sendResponse(res);\n    })\n    .catch((err) => {\n      console.error(err);\n      sendResponse(err);\n    });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "After"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspot = require('@hubspot/api-client');\n\nexports.main = async (context = {}) => {\n  const { hs_object_id } = context.propertiesToSend;\n  const userId = context.parameters.userId;\n\n  const hubspotClient = new hubspot.Client({\n    accessToken: process.env['PRIVATE_APP_ACCESS_TOKEN'],\n  });\n\n  const properties = {\n    name: 'newName',\n  };\n  const SimplePublicObjectInput = { properties };\n  const objectType = 'myObject';\n  const objectId = hs_object_id;\n  const idProperty = undefined;\n\n  try {\n    const res = await hubspotClient.crm.objects.basicApi.update(\n      objectType,\n      objectId,\n      SimplePublicObjectInput,\n      idProperty\n    );\n    return res;\n  } catch (err) {\n    console.error(err);\n    return err;\n  }\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private app access token authentication"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Whereas previously you would refer to private app access tokens with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.secrets.PRIVATE_APP_ACCESS_TOKEN"
      }), ", you'll now use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "process.env"
      }), " rather than ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.secrets"
      }), ". For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Include HubSpot node API client\nconst hubspot = require('@hubspot/api-client');\n\nexports.main = async (context = {}) => {\n  // instantiate HubSpot node API client\n  const hubspotClient = new hubspot.Client({\n    accessToken: process.env['PRIVATE_APP_ACCESS_TOKEN'],\n  });\n\n  //your function\n\n  return response;\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Improved logging"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Version ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2023.2"
      }), " increases log size from 4KB to 256KB and guarantees logs to be in order of execution. You can also take advantage of improved in-app logging, such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps#log-traces",
        children: "log tracing"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}