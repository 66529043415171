"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694125;
const slug = exports.slug = 'guides/cms/content/themes/overview';
const title = exports.title = 'Theme overview';
const name = exports.name = 'Theme overview';
const metaDescription = exports.metaDescription = 'Building HubSpot CMS themes that allow developers to build design systems for content creators work within. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/themes.gif';
const featuredImageAltText = exports.featuredImageAltText = 'HubSpot Themes';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "themes-as-a-package",
  "label": "Themes as a package",
  "parent": null
}, {
  "depth": 1,
  "id": "page-creation",
  "label": "Page creation",
  "parent": "themes-as-a-package"
}, {
  "depth": 1,
  "id": "theme-fields",
  "label": "Theme fields",
  "parent": "themes-as-a-package"
}, {
  "depth": 1,
  "id": "theme-modules",
  "label": "Theme modules",
  "parent": "themes-as-a-package"
}, {
  "depth": 0,
  "id": "theme-file-structure",
  "label": "Theme file structure",
  "parent": null
}, {
  "depth": 1,
  "id": "theme.json",
  "label": "theme.json",
  "parent": "theme-file-structure"
}, {
  "depth": 1,
  "id": "fields.json",
  "label": "fields.json",
  "parent": "theme-file-structure"
}, {
  "depth": 2,
  "id": "using-theme-field-values",
  "label": "Using theme field values",
  "parent": "theme-file-structure"
}, {
  "depth": 0,
  "id": "previewing-themes",
  "label": "Previewing themes",
  "parent": null
}, {
  "depth": 1,
  "id": "test-mode",
  "label": "Test mode",
  "parent": "previewing-themes"
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h4: "h4",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Themes overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A theme is a portable and contained collection of developer assets designed to enable a flexible content editing experience. You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "build themes locally using the HubSpot CLI"
      }), " using the tools, technologies, and workflows that you prefer. Themes and all of their files are also portable between environments and accounts. For a video walkthrough of getting started developing themes, view the HubSpot Academy video below:"]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172844605010"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Themes as a package"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Themes being a package cascades throughout the HubSpot app in various places to enable an efficient content creation experience. Developers can use themes to build a design system for content creators to work within. Whatever amount of flexibility, or guardrails can be built into a theme to meet the needs of your business."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Page creation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When content creators start building new pages, they are prompted to start by selecting which theme they are building a page from, followed by selecting which template within the theme to use."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20selection.gif",
        alt: "Theme selection inside of HubSpot"
      }), "The theme preview image, as well as other configurations for a theme, are set in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#theme-json",
        children: "theme.json file"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Theme fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Themes allow developers to create a set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "Theme Fields"
      }), ", similar to Module Fields, which allow content creators to tweak various knobs and dials designed by a developer to allow global stylistic control over a website without having to edit CSS. Developers ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#using-theme-field-values",
        children: "use HubL to access the values of Theme Fields"
      }), " throughout their CSS. Content creators use the Theme Editor to modify Theme Fields, preview those changes against existing templates within a Theme, and publish their changes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/themes.gif",
        alt: "themes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Theme fields are dictated by the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "fields.json file of a theme"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When editing a theme in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#test-mode",
        children: "test mode"
      }), ", you can also copy the theme's settings JSON. This allows you to paste any updates into the theme's local ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode.png",
        alt: "copy-theme-settings-test-mode"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Theme modules"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The modules within a theme should be designed specifically for use within templates in that theme. The content editor will emphasize these theme modules, making it quick and easy for content creators to add modules to the pages they are building that are designed to work well in the context of that page. Default modules and the rest of the modules in your HubSpot account will still be available."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20modules.png",
        alt: "Theme modules inside of a theme"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing a theme, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/hide-modules-and-sections",
        children: "hide modules and sections from the page editor"
      }), " to create a more streamlined content creation experience."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Theme file structure"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A theme is a single directory of files. You can include HTML, CSS, and Javascript files, modules and additional files that can be organized in any manner within subdirectories of the parent theme folder. Two JSON files are necessary to build a theme: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ". These files should be included at the root theme folder."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To start from an example, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hs-fs/hubfs/theme%20file%20structure.png?width=599&height=452&name=theme%20file%20structure.png",
        alt: "theme file structure"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["At this time .json files can only be created and uploaded to a HubSpot account through the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "local development tools"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "theme.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file contains the meta-information for your theme directory, such as the themes readable label, its preview screenshot and various configurations for how the theme should behave. Your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file will look similar to the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The readable label of the theme, used in various places the theme is shown throughout the HubSpot app, like the template selection screen and the theme editor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "preview_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A relative path to a template file in the theme which should be the default template, used when previewing a theme in the theme editor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshot_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A relative path to an image file that is used to give a snapshot of what the theme looks like in various places theme selection occurs, such as in the template selection screen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enable_domain_stylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enabling or disabling stylesheets attached to domains in Website Settings getting included on templates within the theme. The default value is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Integer version number supporting ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "."
            }), " versions."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object to provide information about yourself as the theme provider.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "The provider's name. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "The provider's support email address. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "The provider's website. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "documentation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The theme documentation link."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "example_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The theme live example link."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "license"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A valid ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://spdx.org/licenses/",
              children: "SPDX Identifier"
            }), " or the relative path to the license within your theme.This license dictates what use and modification is permitted by the creator of the theme. Useful when submitting to the marketplace."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Boolean that determines if a theme shows up in the content creator page for selection. The default value is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file controls the available fields and field groups in the theme editor, including ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "style fields"
      }), ". The fields you include will depend on how much control you want content creators to have in the page editor. The number of fields available for themes is more limited than for modules, as theme fields are best for styling options, while ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "global content"
      }), " is better for theme content."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, rather than adding a text field to the theme's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field.json"
      }), " for your site's tagline, it should add it as a global module so that content creators can update the tagline from the page editor rather than the theme editor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The fields that are available for use in themes are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#boolean",
          children: "Boolean"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Border"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#choice",
          children: "Choice"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Color"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Font"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#image",
          children: "Image"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#number",
          children: "Number"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Spacing"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For comprehensive documentation on the possible options for theme fields, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "module and theme fields documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Using theme field values"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To access field values, use dot notation and prefix the path to the value in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " with theme. You can use a themes fields value in your stylesheets using syntax like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ theme.path.to.value }}"
      }), ". For example, the font field outlined below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"type\": \"group\",\n    \"name\": \"typography\",\n    \"label\": \"Typography\",\n    \"children\": [\n      {\n        \"type\": \"font\",\n        \"name\": \"h1_font\",\n        \"label\": \"Heading 1\",\n        \"load_external_fonts\": true,\n        \"default\": {\n          \"color\": \"#000\",\n          \"font\": \"Merriweather\",\n          \"font_set\": \"GOOGLE\",\n          \"variant\": \"700\",\n          \"size\": \"48\"\n        }\n      }\n    ]\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Would be referenced in CSS with:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "h1 {\n font-size: {{ theme.typography.h1_font.size }}px;\n font-family: {{ theme.typography.h1_font.font }};\n color: {{ theme.typography.h1_font.color }};\n text-decoration: {{ theme.typography.h1_font.styles.text-decoration }};\n font-style: {{ theme.typography.h1_font.styles.font-style }};\n font-weight: {{ theme.typography.h1_font.styles.font-weight }};\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Previewing themes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For developers sometimes you need to be able to test out that your theme fields are working properly but don't want to impact real pages. That's where theme test mode comes in."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Test mode"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Test mode gives you a safe environment to be able to play with your theme's fields and ensure they are working as you expect. The interface looks exactly like the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings#edit-theme-settings",
              children: "theme preview/editor that content creators can see"
            }), ", however you can rest assured that you are not changing your actual website's settings. To protect against accidental theme setting updates, publishing is blocked in test mode. You can tell if you are in test mode as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?testmode=true"
            }), " appears in your address bar, and a test mode icon will display in the header of the theme editor."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://f.hubspotusercontent00.net/hubfs/53/theme-test-mode.png",
            alt: "Theme Test/Preview Mode"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are two ways to enable test mode:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "To activate test mode from the design manager:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the design manager, select your ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "theme"
              }), " in the finder."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["At the top of the left sidebar, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Preview"
              }), " button."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode-design-manager%20(1).png",
                alt: "copy-theme-settings-test-mode-design-manager (1)"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "To activate test mode from the page editor:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the page editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design"
              }), " tab in the left sidebar, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Edit theme settings"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/page-editor-edit-theme.png",
                alt: "page-editor-edit-theme"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Add ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "?testmode=true"
              }), " to the URL, then hit ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "enter"
              }), ". You'll then be in test mode."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Another method is to open your theme settings from within the page editor. Then once inside add the query parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?testmode=true"
      }), " to the URL in your address bar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Getting started with themes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "How to add theme capabilities to existing sites"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "HubSpot theme boilerplate"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}