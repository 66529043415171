"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77681100131;
const slug = exports.slug = 'guides/api/marketing/marketing-events';
const title = exports.title = 'マーケティングAPI | マーケティングイベント';
const name = exports.name = 'APAC JAPAN (JA) | WEB | Marketing Events | 202207';
const metaDescription = exports.metaDescription = 'HubSpotのマーケティング イベント オブジェクトの概要。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-this-article",
  "label": "In this article",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%97%E3%81%AE%E8%A6%81%E4%BB%B6",
  "label": "スコープの要件",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E4%BD%9C%E6%88%90%E3%81%A8%E6%9B%B4%E6%96%B0",
  "label": "イベントの作成と更新",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%8F%82%E5%8A%A0%E3%82%A8%E3%83%B3%E3%83%89%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88",
  "label": "イベント参加エンドポイント",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E8%A8%AD%E5%AE%9A%E3%82%92%E6%A7%8B%E6%88%90%E3%81%99%E3%82%8B",
  "label": "アプリ設定を構成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%83%86%E3%83%83%E3%83%971%EF%BC%9A%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7api%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "ステップ1：アプリでAPIを作成する",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E8%A8%AD%E5%AE%9A%E3%82%92%E6%A7%8B%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%83%86%E3%83%83%E3%83%972%EF%BC%9Ahubspot%E3%81%ABapi%E3%81%B8%E3%81%AEurl%E3%83%91%E3%82%B9%E3%82%92%E6%8F%90%E4%BE%9B%E3%81%99%E3%82%8B",
  "label": "ステップ2：HubSpotにAPIへのURLパスを提供する",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E8%A8%AD%E5%AE%9A%E3%82%92%E6%A7%8B%E6%88%90%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      hr: "hr",
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "マーケティングイベント"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "マーケティングイベントは、コンタクトおよび会社と同様に、CRMオブジェクトです。ウェビナーなどのマーケティングイベントをトラッキングし、他のHubSpot CRMオブジェクトに関連付けることができます。この記事では、マーケティングイベントAPIを使用してマーケティングイベントをアプリと連携させる方法について詳しく説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In this article"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "スコープの要件"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "イベント参加エンドポイント"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-app-settings",
          children: "アプリ設定を構成する"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-1-create-an-api-in-your-app",
              children: "ステップ1：アプリでAPIを作成する"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-2-provide-hubspot-with-the-url-path-to-your-api",
              children: "ステップ2：HubSpotにAPIへのURLパスを提供する"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "スコープの要件"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["いずれかのマーケティング イベント エンドポイントにAPIリクエストを送信するには、次の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "スコープ"
      }), "が必要です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.read"
        }), "：マーケティングイベントおよび参加データを取得する権限を付与します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.write"
        }), "：マーケティングイベント情報を作成、削除、変更する権限を付与します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリによって行われた呼び出しを認証する際は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリのアクセストークン"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), "のいずれかを使用できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "認証方式"
      }), "の詳細をご確認ください。利用可能なエンドポイントの完全なリストについては、この記事の上部にある［エンドポイント］タブをクリックしてください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントの作成と更新"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/upsert"
      }), "エンドポイントに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信することで、マーケティングイベントを作成または更新できます。要求本文の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), "フィールドに、更新するイベントに対応する任意の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "と、イベントのその他の詳細（イベントの名前、開始時刻、説明など）を含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストで指定されたIDを持つマーケティングイベントが既に存在する場合、そのマーケティングイベントは更新されます。ない場合は、新しいイベントが作成されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、次の要求は、「Virtual cooking class」という名前で、IDが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "4"
      }), "のイベントを作成します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/marketing-events/events/upsert\n{\n  \"inputs\": [\n    {\n      \"customProperties\": [\n        {\n          \"name\": \"property1\",\n          \"value\": \"1234\"\n        }\n      ],\n      \"eventName\": \"Virtual cooking class\",\n      \"startDateTime\": \"2023-11-30T17:46:20.461Z\",\n      \"eventOrganizer\": \"Chef Joe\",\n      \"eventDescription\": \"Join us for a virtual cooking class! Yum.\"\n      \"eventCancelled\": false,\n      \"externalAccountId\": \"CookingCo\",\n      \"externalEventId\": \"4\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事の上部にある［エンドポイント］タブをクリックすると、リクエストに含めることができる全ての利用可能なフィールドの完全なリストを確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベント参加エンドポイント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベント参加状態エンドポイントを使用すると、HubSpotコンタクトによるマーケティングイベントの登録状態を記録できます。例えば、このエンドポイントを使用して、HubSpotのコンタクトがマーケティングイベントに登録したことを記録できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトの参加ステータスを更新するために使用できるエンドポイントは2つあります。以前、参加者のステータスの更新に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/upsert"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/email-upsert"
      }), "エンドポイントを使用していた場合は、代わりに以下のエンドポイントを使用して、同じ機能を利用しつつ、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/customize-activities-on-a-contact-company-deal-ticket-record-timeline",
        children: "コンタクトタイムライン"
      }), "にコンタクトの参加期間を入力することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["既存のコンタクトのコンタクトIDを使用する場合：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["外部アプリケーションのイベントのIDを", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), "として使用し、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/create"
            }), "に", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), "リクエストを送信します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["リクエスト本文で、次のフィールドを含む", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), "オブジェクトを指定します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), "：コンタクトがイベントに登録した日時。"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), "：既存のコンタクトのコンタクトID。"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["イベント参加者の1人のEメールアドレスを使用する場合：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/email-create"
            }), "に", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), "リクエストを送信します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["リクエスト本文で、次のフィールドを含む", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), "オブジェクトを指定します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), "：コンタクトがイベントに登録した日時。"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "email"
                }), "：inputs内のemailフィールドの値として指定する、参加者のEメールアドレス。"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "指定したEメールアドレスが既存のコンタクトのアドレスと一致しない場合は、新しいコンタクトが作成されます。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記のエンドポイントの両方について、対応するパスパラメーターに次の値を指定します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), "：", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events#view-edit-and-analyze-marketing-events",
          children: "マーケティングイベント"
        }), "のID。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriberState"
        }), "：コンタクトの新しい参加ステータスと一致する以下の列挙値。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "REGISTERED"
            }), "：HubSpotコンタクトがイベントに登録したことを示します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "ATTENDED"
            }), "：HubSpotコンタクトがイベントに参加したことを示します。コンタクトのステータスをATTENDEDに更新する場合は、リクエスト本文のパラメーターとして、ISO 8601インスタント形式で指定した", (0, _jsxRuntime.jsx)(_components.code, {
              children: "joinedAt"
            }), "および", (0, _jsxRuntime.jsx)(_components.code, {
              children: "leftAt"
            }), "タイムスタンプを含めることもできます。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "CANCELLED"
            }), "：以前イベントに登録したHubSpotコンタクトが登録をキャンセルしたことを示します。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントが完了したら、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/{externalEventId}/complete"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信することにより、イベントを完了とマークし、参加の測定指標（例：全ての参加者の参加期間）を計算できます。このアクションは", (0, _jsxRuntime.jsx)("u", {
        children: "元に戻せない"
      }), "ため、全ての参加状態変更イベントがすでに発生している場合にのみ、このエンドポイントを呼び出す必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**これらのAPIは、コンタクトのIDとイベントの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "interactionDateTime"
        }), "値が変更されていない限り、べき等性があります。これにより、重複したイベントがマーケティング イベント プロパティーにHubSpotによって作成されることなく、サブスクライバーの状態を複数回安全に設定できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリ設定を構成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "マーケティングイベントがHubSpotと適切に同期するためには、いくつかの設定が必要です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotに登録状態の変更（登録やキャンセルなど）を送信すると、HubSpotはまず、指定されたイベントIDに対応するマーケティングイベントが存在するかどうかを確認します。存在しない場合、HubSpotはアプリに設定されたエンドポイントを呼び出してマーケティングイベントの詳細を取得し、HubSpotでイベントを作成してから、サブスクライバー状態の変更を公開します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは利便性を目的に提供されている機能ですが、HubSpotでマーケティングイベントを作成するこの機能には依存せず、この記事の上部にある［エンドポイント］タブにあるCRUDメソッドを使用して、マーケティングイベントを自分で作成することをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ステップ1：アプリでAPIを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "マーケティングイベントをサポートするためにHubSpotでは、マーケティングイベントを使用する各アプリでAPIを定義し、特定のマーケティングイベントに関する情報を取得することを要件としています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "要件："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["許容値：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalAccountId"
            }), "：外部アプリの顧客のaccountIdを指定するクエリーパラメーター。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            }), "：イベントの詳細をリクエストしているHubSpotアプリケーションのIDを指定するクエリーパラメーター。これはアプリのIDです。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), "：HubSpotが詳細な情報をリクエストしている外部アプリのイベントのIDを指定する、リクエストのURL内のパスパラメーター。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["戻り値：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "マーケティングイベントの詳細を提供するJSONオブジェクト。以下の表で詳しく説明しているフィールドを含みます。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド名"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "必須"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールドの説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングイベントの名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventOrganizer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングイベントの主催者の名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このイベントのタイプを説明します。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBINAR"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONFERENCE"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKSHOP"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングイベントの開始日時。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングイベントの終了日時。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "マーケティングイベントの説明。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "外部イベントアプリケーションでマーケティングイベントが存在するURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventCancelled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "bool"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["マーケティングイベントがキャンセルされたかどうかを示します。既定では", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "です。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), "ヘッダーも送信します。これは、リクエストがHubSpotから送信されたことを確認するために使用できます。署名の詳細とその検証方法については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "リクエスト署名"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ステップ2：HubSpotにAPIへのURLパスを提供する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のマーケティングイベントの詳細を提供するオブジェクトを返すAPIをアプリで作成したので、次は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{appId}/settings"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信して、HubSpotにAPIへのURLパスを提供する必要があります。これにより、HubSpotはアプリにリクエストを送信してマーケティングイベントの詳細を取得する方法を決定できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストの本文で、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), "フィールドを使用してURLを指定します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), "は次の2つの要件を満たす必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "%s"
        }), "文字列を含んでいること。HubSpotはこの文字列をパスパラメーターとして使用し、イベントのID（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), "）に代入します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "https://"
        }), "プレフィックスとドメイン名（例：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my.event.app"
        }), "）を含む、APIリソースへのフルパスであること。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/%s"
      }), "の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), "を設定し、idが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234-event-XYZ"
      }), "のイベントの詳細を取得するリクエストを行う必要がある場合、HubSpotアプリのidが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app-101"
      }), "で、アカウントのidが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ABC-account-789"
      }), "だとすると、HubSpotは次のURLに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/1234-event-XYZ?appId=app-101&externalAccountId=ABC-account-789"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}