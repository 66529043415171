"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 174038358427;
const slug = exports.slug = 'guides/apps/public-apps/import-users-into-any-hubspot-account';
const title = exports.title = 'Import users into any HubSpot account from an external app';
const name = exports.name = 'vNext Docs DP - Import users into any HubSpot account from an external app (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to import users into any HubSpot account that has installed your app. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "set-up-the-user-import-source-feature",
  "label": "Set up the User import source feature",
  "parent": null
}, {
  "depth": 0,
  "id": "endpoint-to-return-account-data",
  "label": "Endpoint to return account data",
  "parent": null
}, {
  "depth": 0,
  "id": "endpoint-to-return-user-data",
  "label": "Endpoint to return user data",
  "parent": null
}, {
  "depth": 0,
  "id": "test-the-functionality",
  "label": "Test the functionality",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Import users into any HubSpot account from an external app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn how to set up the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "User import source"
      }), " feature to register your public app as a data source for bulk user imports. This means customers who have installed your app in their HubSpot account can use it to bulk import users. This feature is only available for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up the User import source feature"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before you configure your app to use the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "User import source"
      }), " feature, you'll need to set up and host two API endpoints that will accept ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests from HubSpot when a customer who's installed your app triggers a bulk user import."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "One endpoint you set up will be to return account data, while the other will return user data. The specifications for each endpoint are detailed in the sections below."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Requests are sent with the HubSpot Signature. To ensure that the requests that are received are from HubSpot, they can be validated using the signature: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "https://developers.hubspot.com/docs/api/webhooks/validating-requests"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Endpoint to return account data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The URL of the endpoint that returns account data should be structured like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://BASE_PATH_OF_YOUR_SERVICE/feature/USER_IMPORT/event/FETCH_ACCOUNTS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot wil provide the following fields in the body of the request:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body HubSpot will send to your hosted service\n{\n  \"portalId\": 10,\n  \"appId\": 20\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response you return when HubSpot makes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the endpoint should be a JSON-formatted object with the following fields:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"eventType\": \"FETCH_ACCOUNTS\",\n  \"payload\": {\n    \"accounts\": [\n      {\n        \"id\": \"testId1\",\n        \"name\": \"testAccount1\"\n      },\n      {\n        \"id\": \"testId2\",\n        \"name\": \"testAccount2\"\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Endpoint to return user data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The URL of the endpoint that returns account data should be structured like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://BASE_PATH_OF_YOUR_SERVICE/feature/USER_IMPORT/event/FETCH_USERS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will provide the following fields in the body of the request:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body that HubSpot will send to your hosted service\n{\n  \"portalId\": 10,\n  \"appId\": 20\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response you return when HubSpot makes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the endpoint should be a JSON-formatted object with the following fields:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 10,\n  \"appId\": 20,\n  \"eventType\": \"FETCH_USERS\",\n  \"payload\": {\n    \"users\": [\n      {\n        \"id\": \"testUser1\",\n        \"email\": \"testuser1-invalid@hubspot.com\",\n        \"firstName\": \"Test\",\n        \"lastName\": \"User-One\",\n        \"existingUserId\": null\n      },\n      {\n        \"id\": \"testUser2\",\n        \"email\": \"testuser2-invalid@hubspot.com\",\n        \"firstName\": \"Test\",\n        \"lastName\": \"User-Two\",\n        \"existingUserId\": null\n      },\n      {\n        \"id\": \"testUser3\",\n        \"email\": \"testuser3-invalid@hubspot.com\",\n        \"firstName\": \"Test\",\n        \"lastName\": \"User-Three\",\n        \"existingUserId\": null\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've set up your two endpoints, you can configure your app settings."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your app developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "apps"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More features"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Target URL"
        }), " section, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " that HubSpot will make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to when events trigger. Or, use this testing URL: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://api.hubspot.com/integration-components-example/v1/sample-app-webhooks/beta-app"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click to toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "User import source"
        }), " switch on."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save changes"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features-user-import.png",
        alt: "app-features-user-import"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Test the functionality"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After setting up your two endpoints and configuring your app settings, you can test your endpoints out:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "Install your app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the top navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Users & Teams"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create user"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Or create multiple users at once"
        }), " section, click your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app"
        }), " to begin the import process."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the steps in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account-management/add-and-remove-users#create-users",
          children: "this article"
        }), " to import and set up your users."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/import-users-1.png",
        alt: "import-users-1"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}