"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611090;
const slug = exports.slug = 'guides/cms/overview';
const title = exports.title = "Vue d'ensemble du CMS Hub";
const name = exports.name = "Vue d'ensemble du CMS Hub";
const metaDescription = exports.metaDescription = "Vue d'ensemble de haut niveau de Content Hub de HubSpot pour les développeurs, vous montrant tous les avantages clés comme les thèmes, les modèles et les modules, et comment ils s'assemblent.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9ation-de-contenu",
  "label": "Création de contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "types-de-contenu",
  "label": "Types de contenu",
  "parent": null
}, {
  "depth": 1,
  "id": "pages-de-site-web-et-pages-de-destination",
  "label": "Pages de site web et pages de destination",
  "parent": "types-de-contenu"
}, {
  "depth": 1,
  "id": "blogs",
  "label": "Blogs",
  "parent": "types-de-contenu"
}, {
  "depth": 1,
  "id": "e-mails",
  "label": "E-mails",
  "parent": "types-de-contenu"
}, {
  "depth": 0,
  "id": "contenu-structur%C3%A9",
  "label": "Contenu structuré",
  "parent": null
}, {
  "depth": 0,
  "id": "syst%C3%A8me-de-fichiers-d%C3%A9veloppeur",
  "label": "Système de fichiers développeur",
  "parent": null
}, {
  "depth": 0,
  "id": "th%C3%A8mes%2C-mod%C3%A8les%2C-modules-et-champs",
  "label": "Thèmes, modèles, modules et champs",
  "parent": null
}, {
  "depth": 1,
  "id": "th%C3%A8mes",
  "label": "Thèmes",
  "parent": "th%C3%A8mes%2C-mod%C3%A8les%2C-modules-et-champs"
}, {
  "depth": 1,
  "id": "mod%C3%A8les",
  "label": "Modèles",
  "parent": "th%C3%A8mes%2C-mod%C3%A8les%2C-modules-et-champs"
}, {
  "depth": 1,
  "id": "modules",
  "label": "Modules",
  "parent": "th%C3%A8mes%2C-mod%C3%A8les%2C-modules-et-champs"
}, {
  "depth": 1,
  "id": "champs",
  "label": "Champs",
  "parent": "th%C3%A8mes%2C-mod%C3%A8les%2C-modules-et-champs"
}, {
  "depth": 0,
  "id": "le-langage-hubl",
  "label": "Le langage HubL",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      li: "li",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Vue d'ensemble du CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette section est conçue pour vous aider à comprendre les principaux aspects du ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS"
        })
      }), " HubSpot et apprendre à y créer de superbes sites web. Pour en tirer le meilleur parti, une compréhension de niveau professionnel des bases du développement web, notamment HTML, JavaScript et CSS, est attendue."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous débutez dans la création de CMS, consultez d'abord le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "tutoriel de démarrage rapide"
      }), ", puis consultez cet aperçu au besoin."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Création de contenu"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le CMS de HubSpot est conçu pour aider les entreprises à développer leur présence web en mettant l'accent sur la création et la gestion de contenu pour les spécialistes du marketing. Le contenu du site web, la collecte de lead et les analytics sont intégrés au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/crm",
        children: "CRM de HubSpot"
      }), ", ce qui permet de créer facilement des expériences personnalisées pour les visiteurs et d'intégrer ces expériences au reste de l'activité."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tant que développeur du CMS Hub, vous ne créerez pas seulement des ressources telles que des thèmes, des modèles et des modules, mais vous les créerez de manière à permettre aux créateurs de contenu de les personnaliser avec du contenu au besoin. Ainsi, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "vous devrez"
        })
      }), " équilibrer les besoins des développeurs et ceux des créateurs de contenu grâce à son système de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thèmes"
      }), " et de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), " ainsi qu'à ses ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "zones de glisser-déposer"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/code-no-code-graphic.png",
        alt: "Les modèles et les modules sont à l'intersection entre les développeurs et les spécialistes marketing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un site web bien conçu doit être développé en étroite collaboration avec vos créateurs de contenu afin de comprendre leurs besoins, et nécessitera ensuite moins de support et de maintenance de la part d'un développeur. À cette fin, il est recommandé de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow#testing",
        children: "prévisualiser l'apparence de l'expérience de création de page pour les créateurs de contenu"
      }), " pendant la création. Cela leur permet de travailler de manière aussi indépendante que possible sur le site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/making%20a%20page.gif",
        alt: "Animation montrant un utilisateur créant une page glisser-déposer"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se charge de l'hébergement et de la maintenance de vos pages. Ainsi, vous n'avez pas à vous soucier de la gestion des plug-ins, des mises à jour, de l'hébergement, de la mise à l'échelle ou de la sécurité. Le compromis ici est que le système impose un peu plus de restrictions sur ce que vous pouvez faire d'autre que les CMS autohébergés. Par exemple, vous ne pouvez pas modifier ou étendre les principes fondamentaux du système manuellement ou via des plug-ins, ni manipuler le rendu de bas niveau, ni accéder et modifier directement le contenu des bases de données."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le contenu conçu par les développeurs (par exemple : les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thèmes"
      }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modèles"
      }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", JavaScript et les CSS) est créé dans un système de fichiers pour développeurs, tandis que le contenu des pages (pages, articles de blog) est conçu et construit dans un puissant éditeur WYSIWYG et les fichiers multimédias (images créées par le créateur de contenu, PDF, etc.) sont stockés dans un gestionnaire de fichiers basé sur une application web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'une page est rendue, HubSpot achemine la demande vers l'un des nombreux serveurs en fonction du domaine, rend la page sur nos serveurs et la met en cache dans un réseau de diffusion de contenu si possible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types de contenu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe de nombreux types de contenu que vous créez à l'aide du CMS de HubSpot. L'interface utilisateur pour les créateurs de contenu est légèrement différente en fonction du type de contenu, ce qui a des conséquences dont vous devez tenir compte en tant que développeur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pages de site web et pages de destination"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les pages de site web et de destination sont créées indépendamment les unes des autres, mais toutes reposent sur des modèles. Pour les créateurs de contenu, le processus de création d'une page de destination ou de site web est presque identique. La distinction entre ces deux types de pages est la suivante : les pages de site web sont conçues pour présenter des informations faisant partie de votre site web et destinées à être trouvées de manière organique, tandis qu'une page de destination est ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/marketing/landing-page-best-practices",
        children: "généralement associée à une offre ou à une campagne de marketing spécifique"
      }), " (par exemple : liée à un e-mail marketing envoyé à une liste spécifique de contacts)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'interface utilisateur destinée aux spécialistes du marketing, l'analytics et l'organisation de ces types de pages sont également organisées séparément, car les pages de destination ont souvent des objectifs de conversion spécifiques."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les blogs HubSpot ont deux vues : une pour la page de listing et une pour la page d'article individuel, puis chaque article de blog est alimenté dans chacune d'elles. Vous pouvez définir un blog pour qu'il partage le même modèle pour les articles de blog et les pages de listing ou utiliser des modèles distincts pour la page de listing et les articles de blog. Les articles de blog doivent partager le même modèle. Découvrez-en davantage sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "balisage des modèles de blog"
      }), " ainsi que sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/manage-your-blog-template-and-settings",
        children: "création et la gestion de blogs dans HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-mails"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les e-mails peuvent être créés de plusieurs façons dans HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-mail classique :"
        }), " créez des modèles et des modules d'e-mail de la même manière que le site web et les pages de destination. Vous pouvez également créer des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/email-template-markup",
          children: "modèles d'e-mail codés"
        }), " pour avoir un contrôle total sur le balisage."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-mails en glisser-déposer :"
        }), " créez des modèles d'e-mail en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
          children: "glisser-déposer"
        }), " personnalisables qui permettent aux créateurs de contenu de créer la mise en page et le contenu des e-mails à l'aide de l'interface de glisser-déposer de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenu structuré"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Outre la création de contenu de page via les éditeurs intégrés à l'application ou le codage en dur dans les modèles, vous pouvez utiliser des sources de données structurées pour alimenter du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "contenu de page dynamique"
      }), " avec HubL. Vous pouvez utiliser les sources de données suivantes pour alimenter vos pages :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
          children: "HubDB"
        }), " : stockez des données dans les cellules de tableaux HubDB."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
          children: "Fiches d'informations du CRM"
        }), " : stockez des données dans des fiches d'informations du CRM, telles que des contacts, des entreprises ou des objets personnalisés."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La création de pages dynamiques à l'aide de contenu structuré vous permet de créer, de modifier et de supprimer des pages web et du contenu de page en mettant à jour directement les sources de données. À l'image d'un blog HubSpot, un ensemble de pages dynamiques comprendra une page de listing unique pour afficher les instances de votre source de données, puis une page distincte pour chaque instance individuelle. À l'aide de HubL, vous pouvez configurer entièrement les données affichées par les pages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, vous pouvez créer un tableau HubDB qui stocke une ligne d'informations pour chaque membre d'une équipe de vente. En utilisant ce tableau HubDB, HubSpot peut ensuite générer une page de listing pour afficher les détails clés de chaque ligne de tableau (comme un nom et une image pour chaque représentant commercial) ainsi qu'une page distincte pour chaque représentant commercial afin d'afficher plus d'informations (comme leur biographie et leur numéro de téléphone). Si un représentant commercial est ensuite promu vers une autre équipe, vous pouvez supprimer sa ligne du tableau HubDB. HubSpot supprimera automatiquement sa page détaillée et le supprimera de la page de listing."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Système de fichiers développeur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les principales ressources (modèles, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thèmes"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", ainsi que les fichiers JavaScript, CSS et images qui les prennent en charge) sont créés dans un système de fichiers pour développeurs. Vous pouvez voir ce système de fichiers soit dans le panneau de gauche du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "gestionnaire de conception"
      }), ", soit dans les dossiers synchronisés localement à l'aide des outils de développement local. Dans le système de fichiers, les ressources peuvent se référer les unes aux autres avec des chemins absolus ou relatifs."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En arrière-plan, ces fichiers sont mappés aux entrées d'une base de données. C'est pourquoi l'accès au système de fichiers du développeur se fait par le biais des outils ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " de HubSpot plutôt que par un accès direct SSH ou FTP, et certaines fonctionnalités du système de fichiers auxquelles vous pouvez vous attendre, comme les permissions et les liens symboliques, ne sont pas offerts dans le système de fichiers du développeur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette approche diffère de celle des CMS traditionnels, mais signifie que les références cassées entre les fichiers ou les erreurs de syntaxe sont détectées au moment de la publication plutôt qu'au moment de l'exécution, ce qui vous offre une protection supplémentaire contre les défaillances accidentelles lorsque le site web est visité en direct."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles dans le système de fichiers seront automatiquement détectés et présentés aux créateurs de contenu lorsqu'ils créeront de nouvelles pages. La structure du système de fichiers dépend donc de vous. Il n'est pas nécessaire que les modules se trouvent dans un dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/modules/"
      }), " ou que JavaScript se trouve dans un dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/js/"
      }), ". Cependant, il est recommandé d'organiser vos ressources de la même manière que le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "code d'exemple du boilerplate du CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Par défaut, HubSpot minifie automatiquement le code JavaScript et CSS inclus dans le gestionnaire de conception afin de supprimer les espaces inutiles, les sauts de ligne et les commentaires. Cela s'applique également aux fichiers JavaScript et CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "chargés dans le gestionnaire de conception via l'interface d'ILC"
        }), ". Cela signifie que vous ne devez pas ajouter de code déjà minifié directement dans le gestionnaire de conception."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-and-css-minification",
          children: "JavaScript et la minification CSS"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Thèmes, modèles, modules et champs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thèmes"
      }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modèles"
      }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "champs"
      }), " sont les objets avec lesquels vous travaillerez le plus. L'utilisation efficace de ces différents objets vous permet de donner aux créateurs de contenu la liberté de travailler et d'itérer sur les sites web de manière indépendante, tout en respectant les règles de style et de mise en page que vous avez définies."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les thèmes et les modules contiennent des champs, qui sont des paramètres de types de données spécifiques, comme des nombres, des chaînes, du texte enrichi et des images. Vous pouvez contrôler la manière dont ils sont utilisés pour le rendu de ces objets, ainsi que la manière dont ils doivent être organisés et apparaître dans l'éditeur ", (0, _jsxRuntime.jsx)("abbr", {
        title: "What You See Is What You Get",
        children: "WYSIWYG"
      }), ". Les créateurs de contenu peuvent définir des valeurs pour les champs dans l'éditeur WYSIWYG, qui sont appliquées au thème ou au module au moment du rendu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Thèmes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thèmes"
      }), " sont les objets de premier niveau qui définissent l'aspect et la convivialité d'un site web et créent une expérience d'édition de contenu conviviale. Ces ressources peuvent comprendre des modèles, des modules, des fichiers CSS, des fichiers JavaScript, des images, etc."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Grâce aux thèmes, vous pouvez créer un ensemble de champs que les créateurs de contenu utilisent pour obtenir un contrôle stylistique global sur un site web sans avoir à modifier la CSS. Vous pouvez spécifier dans la CSS où ces contrôles sont appliqués, arranger les contrôles pour qu'ils héritent des autres, et gérer la façon dont ils sont présentés et organisés aux équipes marketing : dans l'éditeur de thème. Les créateurs de contenu utilisent l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-fields",
        children: "éditeur de thème"
      }), " pour modifier les champs du thème, prévisualiser ces modifications par rapport aux modèles existants dans un thème et publier leurs modifications."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ces champs du thème peuvent être définis de manière globale sur un site ou remplacés au niveau d'une page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Theme%20Editor.png",
        alt: "Éditeur de thèmes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modèles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: " modèles"
      }), " définissent le balisage et le style de base d'un ensemble de pages qui utilisent un modèle. Ils peuvent contenir un HubL, du HTML et des liens vers des fichiers JavaScript et CSS, et leur utilisation peut être limitée à des types de contenu spécifiques. Vous disposez d'une flexibilité totale en ce qui concerne le balisage et le style que vous pouvez inclure dans un modèle, mais nous vous encourageons à respecter quelques bonnes pratiques et à utiliser certaines fonctionnalités clés de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " afin de garantir que les responsables marketing puissent modifier les pages facilement. Voici quelques bonnes pratiques :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Construire des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview",
          children: "modèles"
        }), " dans le cadre d'un thème et utiliser la CSS du thème, y compris les champs du thème, pour réaliser la majorité du style dans un modèle. Les créateurs de contenu pourront ainsi facilement apporter des changements de style globaux et locaux de manière cohérente sans avoir à modifier les CSS."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliser des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "modules"
        }), " pour la majorité des composants de votre page permet de les réorganiser et de les réutiliser sur l'ensemble d'un site web. Découvrez-en davantage sur les modules ci-dessous."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliser des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "zones de glisser-déposer"
        }), " lorsque cela est possible pour le contenu des pages principales, en particulier sur les pages internes. Les zones de glisser-déposer vous permettent de définir une mise en page par défaut pour les modules qui composent une page, mais offrent aux commerçants la possibilité de modifier la mise en page et le style indépendamment."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliser des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
          children: "partials globaux"
        }), " pour contenir du contenu partagé, comme les en-têtes et les pieds de page, dont l'apparence doit être cohérente sur l'ensemble d'un site web."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles peuvent être créés soit avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), ", soit avec une interface de type ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "glisser-déposer"
      }), " dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "gestionnaire de conception"
      }), ". Cependant, il est recommandé de coder les modèles car ils permettent plus de contrôle et de fonctionnalités que les modèles en glisser-déposer, comme la prise en charge des zones de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "glisser-déposer"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Homepage%20Template%20html.png",
        alt: "Code VS avec HTML et modèle HubL ouvert"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), " sont des composants réutilisables que vous pouvez placer sur des modèles et des pages. Ils comprennent des contrôles pour les spécialistes du marketing et contiennent des balises HubL/HTML, CSS et JavaScript qui, ensemble, construisent des composants réutilisables et modifiables d'un site web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les contrôles d'un module sont définis dans des champs. Pour créer un module de qualité, il faut donc tenir compte à la fois de l'apparence de la page et de l'expérience des éditeurs de contenu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "HubSpot comprend un ensemble de"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "modules par défaut"
      }), " tels que des en-têtes, du texte enrichi, des images, des boutons et des CTA, que vous utiliserez comme composants fondamentaux. Vous souhaiterez sans doute aussi créer des éléments pouvant avoir des mises en page plus intéressantes qui s'intègrent dans votre thème et vos modèles. Les accordéons, les curseurs et les onglets sont des exemples courants de modules que vous pourriez vouloir construire."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Module%20form%20in%20editor.png",
        alt: "Format du module dans l'éditeur"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez considérer un module comme un objet et les modules sur les pages comme des instances de cet objet, ce qui signifie que les mises à jour du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), ", de la CSS ou du JavaScript d'un module sont appliquées à toutes les instances de ce module dans les pages ou les modèles d'un portail HubSpot donné. Les modules sont également portables d'un portail HubSpot à l'autre, que vous utilisiez l'outil de copie intégré au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "gestionnaire de conception"
      }), ", que vous les vendiez sur le marché ou que vous partagiez le code directement avec des outils de développement locaux. Cela signifie que vous pouvez appliquer une seule fois une solution de conception et l'utiliser sur toutes les pages et tous les portails, et lorsque vous devez la mettre à jour, ces modifications peuvent être appliquées sur toutes les pages sans avoir à modifier plusieurs pages ou plusieurs modèles."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modules peuvent également être inclus dans des thèmes, ce qui vous permet d'utiliser des champs de thème pour manipuler l'aspect des modules et faire en sorte qu'ils soient affichés de manière bien visible dans l'éditeur de page, afin que les créateurs de contenu puissent accéder facilement aux modules qui s'accordent parfaitement avec les conceptions que vous avez créées."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "guide de présentation des modules"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Champs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: " champs"
      }), " sont les contrôles que les créateurs de contenu utilisent pour ajuster les paramètres transmis à vos thèmes et modules. Les champs sont typés, comprenant des types simples comme booléen, texte, URL, choix et fichier, mais aussi des champs plus complexes comme la police avec style ainsi que des champs spécifiques à HubSpot comme des liens vers d'autres éléments de contenu ou formulaires dans le système HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs peuvent également être placés à l'intérieur de répéteurs qui transmettront un tableau au module, par exemple cela pourrait être un carrousel d'images où vous voudriez transmettre un ensemble d'images avec le texte associé `alt`. Plutôt que de créer un certain nombre de champs d'image et de texte, vous pouvez créer un champ de chaque type et les placer dans un groupe répétitif."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les champs d'un module sont spécifiés soit dans le gestionnaire de conception, soit avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#the-user-interface-for-editing",
        children: "cette syntaxe dans un fichier fields.json."
      }), " Les champs d'un thème doivent être spécifiés dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " situé à la racine du thème."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Le langage HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le langage principal que vous utiliserez pour créer des ressources de site web sur le CMS de HubSpot est le langage de balisage de HubSpot ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), " (prononcé « Hubble »). HubL est l'extension de HubSpot de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", un moteur de modèles basé sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://palletsprojects.com/p/jinja/",
        children: "Jinja"
      }), ". HubL utilise une quantité considérable de balises propres à HubSpot et ne prend pas en charge toutes les fonctionnalités de Jinja. Il est exécuté entièrement côté serveur lorsqu'une page est rendue."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL possède les fonctionnalités que l'on attend d'un langage de modélisation simple, comme les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "variables"
      }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "boucles"
      }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "fonctions SI"
      }), ", mais il prend également en charge des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macros"
      }), " de rendu plus complexes, la récupération de données et le mappage avec des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "balises"
      }), ", des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "fonctions"
      }), " et des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filtres"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous atteignez les limites de ce qui est possible avec HubL, HubSpot fournit des API pour créer des solutions plus personnalisées. Les comptes ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), " peuvent utiliser des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "fonctions sans serveur"
      }), " permettant une programmation côté serveur plus sophistiquée."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez vous référer à la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "référence du langage HubL"
      }), " pour plus de détails sur les caractéristiques spécifiques du langage."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}