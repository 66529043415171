export const introductionId = 'introduction';
export const useCasesId = 'use-cases';

/**
 * The max width of the API Reference page wrapper.
 */
export const MAX_WIDTH = 1993;

/**
 * The page id utilized for page load and page error nav markers and for the
 * scroll container id which is the `id` of the scrollable element. Used to
 * handle scroll interactivity on the right-hand Table of Contents.
 */
export const REFERENCE_PAGE_ID = 'REFERENCE';