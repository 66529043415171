"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329271;
const slug = exports.slug = 'guides/cms/marketplace/template-guidelines';
const title = exports.title = 'Richtlinien für den HubSpot-Marketplace für Vorlagen';
const name = exports.name = 'EMEA DACH (DE) Marketplace Requirements Overview';
const metaDescription = exports.metaDescription = 'Der HubSpot-Marketplace für Vorlagen hilft Tausenden von Kunden ohne direkten Zugang zu einem Entwickler, ihre Online-Präsenz auszubauen. Er wird von Entwicklern wie Ihnen betrieben, die schöne und benutzerfreundliche Designs, Vorlagen und Module erstellen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "warum-sollte-ich-vorlagen-im-marketplace-verkaufen%3F",
  "label": "Warum sollte ich Vorlagen im Marketplace verkaufen?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%BCberblick-und-anforderungen",
  "label": "Überblick und Anforderungen",
  "parent": null
}, {
  "depth": 0,
  "id": "designs-im-marketplace-f%C3%BCr-vorlagen-listen-und-aktualisieren",
  "label": "Designs im Marketplace für Vorlagen listen und aktualisieren",
  "parent": null
}, {
  "depth": 0,
  "id": "unterst%C3%BCtzte-zahlungsgateways",
  "label": "Unterstützte Zahlungsgateways",
  "parent": null
}, {
  "depth": 0,
  "id": "antr%C3%A4ge-auf-r%C3%BCckerstattung",
  "label": "Anträge auf Rückerstattung",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-transaktionen-verwalten",
  "label": "Ihre Transaktionen verwalten",
  "parent": null
}, {
  "depth": 0,
  "id": "entfernen-von-vorlagen-listings",
  "label": "Entfernen von Vorlagen-Listings",
  "parent": null
}, {
  "depth": 0,
  "id": "h%C3%A4ufig-gestellte-fragen",
  "label": "Häufig gestellte Fragen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      br: "br",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ContentCardsRow,
      ContentCard,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ContentCard) _missingMdxReference("ContentCard", true);
  if (!ContentCardsRow) _missingMdxReference("ContentCardsRow", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Richtlinien für den HubSpot-Marketplace für Vorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "HubSpot-Marketplace für Vorlagen"
      }), " hilft Tausenden von Kunden ohne direkten Zugang zu einem Entwickler, ihre Online-Präsenz auszubauen. Es wird von Entwicklern unterstützt, die einfach zu bedienende Vorlagen für Kunden erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im HubSpot-Marketplace für Vorlagen können Sie zwei Typen von Vorlagen verkaufen: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/website",
        children: "Designs"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/modules",
        children: "Module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Warum sollte ich Vorlagen im Marketplace verkaufen?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der HubSpot-Marketplace hat viel zu bieten für Entwickler oder Agenturen, die eine neue Einnahmequelle erschließen möchten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nicht exklusiv:"
        }), " Sie haben hart an der Entwicklung Ihrer Vorlagen gearbeitet. Sie sollten nicht daran gebunden sein, dass Ihre Vorlage nur in unserem Marketplace und nirgendwo sonst zu finden ist. Sie können Ihre Vorlagen auch außerhalb des Marketplaces für Ihre eigenen Projekte verwenden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Einmal erstellen, mehrmals verkaufen:"
        }), " Sobald Ihre Vorlage im Marketplace gelistet ist, wird sie zu einer 1:viele-Chance. Sie erstellen Ihre Vorlage einfach einmal und es kann an mehrere Kunden weiterverkauft werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fördern Sie das Wachstum Ihres Unternehmens:"
        }), " Bei vielen Entwicklern, die ihre Vorlagen (sowohl kostenlose als auch kostenpflichtige) in unserem Marketplace verkaufen, ist ihr Business sowohl aufgrund der Marketplace-Einnahmen als auch durch zusätzliche Dienstleistungen, die von Käufern nachgefragt wurden, gewachsen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Treten Sie einer Community von Kreativen bei und präsentieren Sie Ihre Kompetenzen:"
        }), " Vernetzen Sie sich mit einem wachsenden und unterstützenden Ökosystem, um die Zukunft des HubSpot-Marketplace für Vorlagen mitzugestalten und gleichzeitig mehr Sichtbarkeit für Ihre Angebote und Dienstleistungen zu schaffen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Überblick und Anforderungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um mit dem Verkauf im HubSpot-Marketplace für Vorlagen zu beginnen, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "erstellen Sie einen Anbieter-Account für den Vorlagen-Marketplace"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jede Vorlage, die eingereicht wird, muss die Compliance-, Design- und Codequalitätsanforderungen erfüllen. Jeder Vorlagentyp hat außerdem seine eigenen Anforderungen, die erfüllt sein müssen, um im Marketplace gelistet zu werden. Sie können diese Anforderungen unten anzeigen:"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bevor Sie mit dem Erstellen von Vorlagen für den Marketplace beginnen, empfiehlt HubSpot dringend, sich die Richtlinien für ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/template-providers",
          children: "das Erstellen für den HHubSpot-Marketplace für Vorlagen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/themes/branding-guidelines",
          children: "Branding"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "die Verwendung unserer Marke"
        }), " und", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/content-usage-guidelines",
          children: "die Verwendung unserer Inhalte"
        }), " anzusehen. Vorlagen, die gegen diese Richtlinien verstoßen, werden sofort vom Marketplace entfernt, wie in den zusätzlichen Bedingungen von HubSpot beschrieben."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(ContentCardsRow, {
      children: [(0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Designanforderungen"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Eine übertragbare und geschlossene Reihe von Vorlagen, Modulen, globalem Content und Stileinstellungen, die im Zusammenspiel eine Bearbeitung von Inhalten ermöglichen, die Marketingteams das Leben leichter machen."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/theme-requirements",
            children: "Designanforderungen anzeigen"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Modulanforderungen"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Eingereichte Designs enthalten Module. Dies sind wiederverwendbare Komponenten, die in Vorlagen verwendet oder über Drag-&-Drop-Bereiche und flexible Spalten zu Seiten hinzugefügt werden kEönnen. Sie können auch eigenständige Module einreichen. Hierbei handelt es sich um Module, die zu einem beliebigen Design im Content-Editor von HubSpot hinzugefügt werden können."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/module-requirements",
            children: "Modulanforderungen anzeigen"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Voraussetzungen für Listing-Seiten"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Anforderungen, die man einhalten muss, wenn man Vorlagen und deren Listing-Seiten beim Vorlagen-Marketplace einreicht. Diese Standards gelten für alle Vorlagen im Marketplace."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/general-requirements",
            children: "Anforderungen an Listing-Seiten anzeigen"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Richtlinien für den Vorlagen-Marketplace"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Anforderungen und Compliance-Details für Listing-Vorlagen im Vorlagen-Marketplace."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/template-policies",
            children: "Richtlinien für den HubSpot-Marketplace für Vorlagen anzeigen"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designs im Marketplace für Vorlagen listen und aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihre Vorlage erstellt haben, reichen Sie sie zur Genehmigung ein, um im HubSpot-Marketplace für Vorlagen gelistet zu werden. Nach der Einreichung werden die Vorlagen einem Überprüfungs- und Genehmigungsprozess durch das Team des HubSpot-Marketplace für Vorlagen unterzogen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "Erfahren Sie, wie Sie Ihre Vorlagen im Vorlagen-Marketplace listen und aktualisieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unterstützte Zahlungsgateways"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot bietet Marketplace-Anbietern derzeit die Möglichkeit, die folgenden Zahlungsgateways zu verknüpfen, um Zahlungen zu empfangen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["PayPal (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/webapps/mpp/country-worldwide",
          children: "unterstützte Länder"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/smarthelp/article/what-are-the-cross-border-fees-when-selling-international-faq2927",
          children: "grenzüberschreitende Zahlungen"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stripe (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "unterstützte Länder"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/docs/connect/cross-border-payouts",
          children: "grenzüberschreitende Zahlungen"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zahlungen von Kunden erhalten Sie entsprechend der in Ihrem Marketplace-Anbieterkonto verknüpften Zahlungsmethode:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Accounten zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Marketplace“"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Anbieterinformationen“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Zahlungsmethode"
        }), " können Sie Ihre Zahlungsinformationen ansehen und aktualisieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ist nicht für Probleme mit Zahlungsgateways verantwortlich, die möglicherweise während des Kaufprozesses auftreten. HubSpot erhält keinen Anteil an den Transaktionen, und alle Transaktionen werden unabhängig von der HubSpot-Plattform abgewickelt. Wenn Ihre Zahlungsmethode aus irgendeinem Grund nicht mehr funktioniert, können Ihre Elemente vorübergehend gelöscht werden, um ein negatives Kundenerlebnis zu vermeiden."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " HubSpot hat Kunden weltweit. Beim Einrichten Ihrer Zahlungsgateways empfehlen wir dringend, Zahlungen von internationalen Währungen (grenzüberschreitende Zahlungen) anzunehmen."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpot ermutigt Anbieter im Marketplace für Vorlagen, das Stripe-Zahlungsgateway zu verwenden, wenn es in ihrem Land verfügbar ist. Wenn Stripe nicht ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "in Ihrem Land verfügbar ist"
        }), ", empfehlen wir, das PayPal-Zahlungsgateway zu verwenden."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anträge auf Rückerstattung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Gelegentlich kann es vorkommen, dass ein Käufer eine Rückerstattung verlangt. HubSpot empfiehlt, Ihre Rückerstattungsrichtlinie in der Beschreibung eines Angebots deutlich anzugeben und einen Link zur Dokumentation Ihrer Rückerstattungsrichtlinie hinzuzufügen. HubSpot fordert Anbieter auf, gültige Rückerstattungsanträge zu berücksichtigen. Erfahren Sie mehr über allgemeine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/articles/kcs_article/resources/marketplace-refund-guidelines",
        children: "Richtlinien für Rückerstattungsanträge"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre Transaktionen verwalten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für jede Vorlage, die ein Kunde herunterlädt, wird ein Deal-Datensatz erstellt in Ihrem Anbieter-Account für den Vorlagen-Marketplace erstellt. Alle Deals werden in einer Deal-Pipeline namens ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "„Marketplace-Transaktionen“"
      }), " mit der Deal-Phase ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "„Transaktionen“"
      }), " ausgefüllt. Wenn eine Rückerstattung für eine Transaktion ausgestellt wird, wird die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "„Zurückerstattet um“"
      }), " im Deal-Datensatz festgelegt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anbieter können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "die Deal-Pipeline und Deal-Phasen anpassen"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/workflows/choose-your-workflow-actions#property-management",
        children: "die Deal-Eigenschaften in Workflows verwenden"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entfernen von Vorlagen-Listings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot behält sich das Recht vor, Marketplace-Listings aus folgenden Gründen zu entfernen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es sind Fehler in der Vorlage vorhanden."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Drittanbieter haben die Einreichung Ihrer Vorlagen pausiert."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die Vorlage ist nicht kompatibel mit ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/resources/which-browsers-are-supported-by-hubspot",
          children: "von HubSpot-unterstützten Browsern"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es wird urheberrechtlich geschütztes Material wie Bilder, Symbole, Schriftarten, Audio und Video verwendet, das nicht für den Vertrieb lizensiert ist"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es werden Vorlagen verwendet, die Benutzer ohne deren informierte, explizite Einwilligung (Opt-in) nachverfolgen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vorlagen (einschließlich der Verknüpfung mit Inhalten vom Vorlagen-Marketplace oder von Ihrer Vorlagen-Demo), die Folgendes enthalten:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Werbung für oder Teilnahme an rechtswidrige(n) Aktivitäten"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Abwertende, abfällige und/oder böswillige Inhalte"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Unehrliche oder falsche Behauptungen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Beleidigende Inhalte über Rasse, Religion, Alter, Geschlecht oder sexuelle Ausrichtung"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Module werden entfernt, wenn es andere Elemente auf der Seite/E-Mail beeinflusst, zu der es hinzugefügt wird."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Häufig gestellte Fragen"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Does the European Union P2B regulation (July 12,2020) apply to the App and Template Marketplaces?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No, the most recent European Union P2B regulation that took effect on July 12, 2020, does not apply to the HubSpot App and Template Marketplaces. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ec.europa.eu/digital-single-market/en/business-business-trading-practices",
          children: "Learn More"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Is there a specific HubSpot account type I need to submit to the Marketplace?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-marketplace-account",
          children: "Marketplace provider account"
        }), " is needed to submit to the Template Marketplace."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Some partners manage their Marketplace listings in their Partner account to use the templates in the same account. Other partners manage their listings in a separate Marketplace account to keep the templates separate from other templates in their design manager tool."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Do products published in the Marketplace live in my account or in the HubSpot Marketplace system?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Products submitted to or published in the Marketplace live in the HubSpot Marketplace system. This ensures that providers can continue to edit them in their own account without the changes affecting customers’ purchases. To update a Marketplace listing, a provider must resubmit the listing so it can be reviewed and republished."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Customers who have purchased previous versions of a product in a listing can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-customers-frequently-asked-questions#update",
          children: "update to the latest version"
        }), " free of charge."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I update my company name?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Once you have listed a template in the marketplace, you will need to contact ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://help.hubspot.com/",
          children: "HubSpot Support"
        }), "  to change your company name."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Why don't I see all of my products when trying to create a listing?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Possible reasons you may not see a product when trying to create a listing include:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is currently listed in the Marketplace or contains elements from a currently listed template."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template isn't supported in the Marketplace (transactional emails, ticket emails, etc.)"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is a clone of a template from the Marketplace."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "What should I use as a preview link?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When creating preview URLs you must use your own domain name if available. If you don't have a domain available to use, HubSpot provides each account with a system domain in the following URL structure:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": HubSpot does not support template previews that use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " subdomain."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Can a single template be used in multiple themes?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes, a single template can be used in multiple themes. A common example of when a template may be used in multiple themes is if a provider offers a Starter and Premium version of the same theme."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Do I have to resubmit a theme or module if I make changes to it?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes. All changes to a theme or module must be re-submitted to the Marketplace for pre-validation and approval by the quality team. Once the changes are approved, the listing and template will be updated in the Marketplace for customers."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I adjust the price of a theme or module?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "To adjust the price of a submitted product, you must resubmit it to the Marketplace with updated information. Prices cannot be changed from free to paid, or vice versa once a template is published."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How do I receive Marketplace payments?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Payments are received through the payment method that you've connected in your Marketplace Provider Profile. In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider Info"
        }), ". In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Method"
        }), " section, view or update your payment information."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot doesn’t take a cut of any transactions, and all transactions are handled independently of the HubSpot platform."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Where do I go to get peer-to-peer feedback or connect with other Marketplace providers? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Join the #marketplace channel in HubSpot's Developer Slack by signing up at ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "https://developers.hubspot.com/slack"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "When a customer requests a refund, do their downloaded files remain in their HubSpot account?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "All files associated with the template as well as any dependencies (e.g. pages created with the template, cloned templates, and modules) will be removed once the refund is initiated."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How can I stay informed on the latest developer requirements and changes? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To stay informed of the latest changes, visit and subscribe to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "HubSpot Developer Changelog"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}