"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 85488833916;
const slug = exports.slug = 'guides/api/crm/exports';
const title = exports.title = 'API del CRM | Exportación';
const name = exports.name = 'API del CRM | Exportación';
const metaDescription = exports.metaDescription = 'Aprende cómo utilizar la API de exportaciones para exportar registros en vistas o listas desde tu cuenta de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "iniciar-una-exportaci%C3%B3n",
  "label": "Iniciar una exportación",
  "parent": null
}, {
  "depth": 1,
  "id": "exportar-una-vista",
  "label": "Exportar una vista",
  "parent": "iniciar-una-exportaci%C3%B3n"
}, {
  "depth": 1,
  "id": "exportar-una-lista",
  "label": "Exportar una lista",
  "parent": "iniciar-una-exportaci%C3%B3n"
}, {
  "depth": 0,
  "id": "recuperar-exportaciones",
  "label": "Recuperar exportaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites",
  "label": "Límites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exportaciones"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la API de exportaciones para exportar registros y datos de propiedades desde tu cuenta de HubSpot, recupera una URL para descargar un archivo de exportación o ver el estado de una exportación. Dentro de HubSpot, también puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/export-contacts-companies-deals-or-tickets",
        children: "exportar registros"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-log-of-your-users-exports-in-your-account",
        children: "ver un registro de exportaciones pasadas en tu cuenta."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Iniciar una exportación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para iniciar una exportación, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async"
      }), ". El cuerpo de tu solicitud debe especificar información como el formato del archivo, el objeto y las propiedades que deseas exportar y el tipo de exportación que estás completando (por ejemplo, exportar una vista de objeto o una lista). También puedes filtrar los datos de propiedades que se exportarán en función de operadores específicos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tanto para las exportaciones de vista como de lista, puedes incluir los siguientes campos en tu solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de exportación, ya sea ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VIEW"
            }), " (exporta una vista de una página de índice de objetos) o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LIST"
            }), " (exporta una lista)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El formato de archivo. Las opciones incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLSX"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLS"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de la exportación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El idioma del archivo de exportación. Las opciones incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ES"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FI"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "JA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PT"
            }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SV"
            }), ". Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-language-offerings",
              children: "idiomas compatibles."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El nombre o ID del objeto que estás exportando. Para objetos estándar, puedes utilizar los nombres de los objetos (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            }), ",) pero para objetos personalizados, debes utilizar el valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), ". Puedes recuperar este valor haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associatedObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre o ID de un objeto asociado para incluir en la exportación. Si incluyes un objeto asociado, la exportación contendrá los ID de registro asociados de ese objeto y el valor de propiedad de muestra principal de los registros (por ejemplo, nombre). Solo puedes exportar un objeto asociado por solicitud."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista de las propiedades que desees incluir en tu exportación."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exportar una vista"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás exportando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "visualización de índice "
      }), ", tu valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " debe ser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "VIEW"
      }), ", y puedes incluir el siguiente campo para filtrar y ordenar los registros que estás exportando:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publicCrmSearchRequest"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica qué datos deben exportarse basado en determinados valores de propiedad y consultas de búsqueda. Puedes incluir lo siguiente dentro del objeto:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "filters"
            }), ": las propiedades y los valores de la propiedad por los que filtrar los registros.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "sorts"
            }), ": el orden de los valores de un propiedad, ya sea ascendente", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASC"
            }), ", o descendente, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DES"
            }), ".", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "query"
            }), ": una cadena para buscar los valores de los registros."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, para exportar una vista de contactos y registros de empresas asociadas, filtrados por la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", tu solicitud puede verse de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"VIEW\",\n  \"exportName\": \"All contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"DE\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email, firstname, lastname\"],\n  \"associatedObjectType\": \"COMPANY\",\n  \"publicCrmSearchRequest\": {\n    \"filters\": [\n      {\n        \"value\": \"hello@test.com\",\n        \"propertyName\": \"email\",\n        \"operator\": \"EQ\"\n      }\n    ],\n    \"query\": \"hello\",\n    \"sorts\": [\n      {\n        \"propertyName\": \"email\",\n        \"order\": \"ASC\"\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exportar una lista"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás exportando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists",
        children: "lista"
      }), ", tu valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " debe ser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LIST"
      }), ", pero también debes especificar la lista que estás exportando con el siguiente campo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/lists/lists-faq#ils-list",
              children: "ID de lista ILS"
            }), " de la lista de exportar. Puedes encontrar el valor Idel ID de lista ILS a través de los detalles de la lista en HubSpot. Navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contactos"
            }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Listas"
            }), ", coloca el cursor sobre la ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "lista"
            }), " en la tabla, luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Detalles"
            }), ". En el panel derecho, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Copiar"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "ID de lista"
            }), " junto a los valores del ID de lista ILS. Las listas de contactos tienen dos valores de ID diferentes, pero ", (0, _jsxRuntime.jsx)("u", {
              children: "debes"
            }), " usar el valor de ID de lista ILS en tu solicitud."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para exportar una lista con los correos electrónicos de contactos, tu solicitud puede verse de la siguiente manera:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"LIST\",\n  \"listId\": 1234567,\n  \"exportName\": \"Marketing email contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"EN\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar exportaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando completes correctamente una exportación, las exportaciones ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " se devolverán en la respuesta. Para recuperar una exportación de tu cuenta de HubSpot, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async/tasks/{exportId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al recuperar exportaciones, también se devolverá el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), " de la exportación. Los estados posibles incluyen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PENDING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSING"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CANCELED"
      }), ". Para las exportaciones con un estado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", se devuelve una URL que puedes utilizar para descargar el archivo exportado. La URL de descarga caducará cinco minutos después de que se complete la solicitud. Una vez vencida, puedes realizar otra solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para generar una nueva URL única."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": antes del vencimiento, se puede acceder a la URL de descarga de una exportación sin ninguna autorización adicional. Para proteger tus datos, procede con cautela al compartir una URL o realizar la integración con HubSpot a través de esta API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se aplican los siguientes límites:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al configurar los filtros para tu exportación, puedes incluir un máximo de tres ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " con hasta tres ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " en cada grupo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes realizar hasta treinta exportaciones en un plazo de 24 horas, y una exportación a la vez. Las exportaciones adicionales se pondrán en cola hasta que se complete la exportación anterior."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un archivo CSV exportado se comprimirá automáticamente si el archivo resultante tiene más de 2 MB."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}