"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107554;
const slug = exports.slug = 'guides/apps/authentication/validating-requests';
const title = exports.title = 'Webhooks | Validieren von Anfragen';
const name = exports.name = 'vNext Docs DP - Validieren von Anfragen von HubSpot';
const metaDescription = exports.metaDescription = 'Eine Übersicht über die Validierung von Anfragen aus HubSpot an eine Integration. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "anfragen-mit-der-v1-anfrageignatur-validieren",
  "label": "Anfragen mit der v1-Anfrageignatur validieren",
  "parent": null
}, {
  "depth": 0,
  "id": "beispiele-f%C3%BCr-die-v1-anfragesignatur",
  "label": "Beispiele für die v1-Anfragesignatur:",
  "parent": null
}, {
  "depth": 0,
  "id": "anfragen-mit-der-v2-anfragesignatur-validieren",
  "label": "Anfragen mit der v2-Anfragesignatur validieren",
  "parent": null
}, {
  "depth": 1,
  "id": "beispiel-f%C3%BCr-eine-get-anfrage",
  "label": "Beispiel für eine GET-Anfrage",
  "parent": "anfragen-mit-der-v2-anfragesignatur-validieren"
}, {
  "depth": 1,
  "id": "beispiel-f%C3%BCr-eine-post-anfrage",
  "label": "Beispiel für eine POST-Anfrage",
  "parent": "anfragen-mit-der-v2-anfragesignatur-validieren"
}, {
  "depth": 0,
  "id": "die-v3-anfragesignatur-validieren",
  "label": "Die v3-Anfragesignatur validieren",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      h2: "h2",
      strong: "strong",
      pre: "pre",
      br: "br",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Anfragen von HubSpot validieren"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um sicherzustellen, dass die Anfragen, die Ihre Integration von HubSpot erhält, tatsächlich von HubSpot stammen, werden mehrere Header in der Anfrage ausgefüllt. Sie können diese Header zusammen mit Feldern der eingehenden Anfrage verwenden, um die Signatur der Anfrage zu überprüfen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die zur Überprüfung der Signatur verwendete Methode hängt von der Version der Signatur ab."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine Anfrage mit der neuesten Version der HubSpot-Signatur zu validieren, verwenden Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-V3"
        }), "-Header und folgen Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests#validate-the-v3-request-signature",
          children: "zugehörigen Anweisungen zum Validieren der v3-Version der Signatur "
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aus Gründen der Abwärtskompatibilität enthalten Anfragen von HubSpot auch ältere Versionen der Signatur. Um eine ältere Version der Signatur zu validieren, überprüfen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-Version"
        }), "-Header und befolgen Sie dann die folgenden Anweisungen, je nachdem, ob die Version ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v1"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v2"
        }), " ist."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In den folgenden Anweisungen erfahren Sie, wie Sie einen Hash-Wert aus dem Client-Geheimnis Ihrer App und den Feldern einer eingehenden Anfrage ableiten. Nachdem Sie den Hash-Wert berechnet haben, vergleichen Sie ihn mit der Signatur. Wenn beide gleich sind, hat die Anfrage die Validierung bestanden. Andernfalls wurde die Anfrage möglicherweise während des Transfers manipuliert, oder jemand spooft Anfragen an Ihren Endpunkt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anfragen mit der v1-Anfrageignatur validieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre App ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/webhooks/overview",
        children: "CRM-Objekt-Events über die Webhooks-API abonniert hat"
      }), ", werden Anfragen von HubSpot mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), "-Header auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v1"
      }), " festgelegt gesendet. Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), "-Header ist ein SHA-256-Hash, der mithilfe des Client-Geheimnisses Ihrer App in Kombination mit den Details der Anfrage erstellt wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um diese Version der Signatur zu überprüfen, führen Sie die folgenden Schritte aus:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie eine Zeichenfolge, die Folgendes miteinander verkettet: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (falls vorhanden)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erstellen Sie einen SHA-256-Hash von der resultierenden Zeichenfolge."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vergleichen Sie den Hash-Wert mit dem Wert des ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature"
        }), "-Headers:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn sie gleich sind, hat diese Anfrage die Überprüfung bestanden."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn diese Werte nicht übereinstimmen, wurde diese Anfrage möglicherweise während des Transfers manipuliert, oder jemand spooft Anfragen an Ihren Endpunkt."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiel für eine Anfrage mit einem Text:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Client secret : yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\n// Request body: [\n{\"eventId\":1,\"subscriptionId\":12345,\"\nportalId\":62515\",\noccurredAt\":1564113600000\",\nsubscriptionType\":\"contact.creation\",\n\"attemptNumber\":0,\n\"objectId\":123,\n\"changeSource\":\"CRM\",\n\"changeFlag\":\"NEW\",\n\"appId\":54321}\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beispiele für die v1-Anfragesignatur:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n>>> import hashlib\n\n>>> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n>>> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> source_string = client_secret + request_body\n>>> source_string\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> hashlib.sha256(source_string).hexdigest()\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\nirb(main):003:0> require 'digest'\n=> true\nirb(main):004:0> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\"\nirb(main):005:0> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n=> \"[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):006:0> source_string = client_secret + request_body\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):007:0> Digest::SHA256.hexdigest source_string\n=> \"232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de\"\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n> const crypto = require('crypto')\nundefined\n> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n'[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> source_string = client_secret + request_body\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> hash = crypto.createHash('sha256').update(source_string).digest('hex')\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der resultierende Hash würde folgendermaßen aussehen:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anfragen mit der v2-Anfragesignatur validieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre App Daten aus einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "Webhook-Aktion in einem Workflow"
      }), " verarbeitet oder wenn Sie Daten für eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "benutzerdefinierte CRM-Karte"
      }), " zurückgeben, wird die Anfrage von HubSpot mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), "-Header auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v2"
      }), " festgelegt gesendet. Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), "-Header ist ein SHA-256-Hash, der mithilfe des Client-Geheimnisses Ihrer App in Kombination mit den Details der Anfrage erstellt wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um diese Signatur zu überprüfen, führen Sie die folgenden Schritte aus:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie eine Zeichenfolge, die Folgendes miteinander verkettet: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http method"
        }), "+ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "URI"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (falls vorhanden)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erstellen Sie einen SHA-256-Hash von der resultierenden Zeichenfolge."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vergleichen Sie den Hash-Wert mit der Signatur.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn sie gleich sind, hat diese Anfrage die Überprüfung bestanden."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn diese Werte nicht übereinstimmen, wurde diese Anfrage möglicherweise während des Transfers manipuliert, oder jemand spooft Anfragen an Ihren Endpunkt."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Hinweise:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der zur Erstellung der Quellzeichenfolge verwendete URI muss genau mit der ursprünglichen Anfrage übereinstimmen, einschließlich des Protokolls. Wenn Sie Schwierigkeiten bei der Validierung der Signatur haben, stellen Sie sicher, dass alle Abfrageparameter in genau der gleichen Reihenfolge sind wie in der ursprünglichen Anfrage aufgeführt."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die Quellzeichenfolge sollte vor der Berechnung des SHA-256-Hash-Werts UTF-8-codiert werden."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beispiel für eine GET-Anfrage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage benötigen Sie das Client-Geheimnis Ihrer App und bestimmte Felder aus den Metadaten Ihrer Anfrage. Diese Felder sind unten mit Platzhalterwerten aufgeführt:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client-Geheimnis:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTTP-Methode:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Anfragetext: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"\""
          })]
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die resultierende verkettete Zeichenfolge würde folgendermaßen aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyGEThttps://www.example.com/webhook_uri"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach der Berechnung eines SHA-256-Hashes der obigen verketteten Zeichenfolge würde die resultierende Signatur, die mit der im Header übereinstimmen sollte, wie folgt aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eee2dddcc73c94d699f5e395f4b9d454a069a6855fbfa152e91e88823087200e"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beispiel für eine POST-Anfrage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage benötigen Sie das Client-Geheimnis Ihrer App, bestimmte Felder aus den Metadaten Ihrer Anfrage und eine Zeichenfolgendarstellung des Anfragetextes (z. B. die Verwendung von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "JSON.stringify(request.body)"
      }), " für einen Node.js-Dienst). Diese Felder sind unten mit Platzhalterwerten aufgeführt:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client-Geheimnis:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTTP-Methode:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anfragetext:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{\"example_field\":\"example_value\"}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die resultierende verkettete Zeichenfolge würde folgendermaßen aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"example_value\"}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach der Berechnung eines SHA-256-Hashes der obigen verketteten Zeichenfolge würde die resultierende Signatur, die mit der im Header übereinstimmen sollte, wie folgt aussehen:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "9569219f8ba981ffa6f6f16aa0f48637d35d728c7e4d93d0d52efaa512af7900"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nach dem [SHA-ing] der Signatur können Sie die resultierende erwartete Signatur mit der im x-hubspot-signature-Header der Anfrage angegebenen Signatur vergleichen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im folgenden Node.js-Code-Snippet wird beschrieben, wie Sie eine Anfragevalidierung für eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage integrieren können, wenn Sie einen Express-Server ausführen, um eingehende Anfragen zu verarbeiten. Beachten Sie, dass der folgende Code-Block ein Beispiel ist und bestimmte Abhängigkeiten auslässt, die Sie möglicherweise benötigen, um einen voll funktionsfähigen Express-Dienst auszuführen. Vergewissern Sie sich, dass Sie die neuesten stabilen und sicheren Bibliotheken ausführen, wenn Sie die Anfragevalidierung für Ihren spezifischen Dienst implementieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Introduce any dependencies. Only several dependencies related to this example are included below:\nconst express = require('express');\nconst bodyParser = require('body-parser');\nconst crypto = require('crypto');\nconst app = express();\n\n// Add any custom handling or setup code for your Node.js service here.\napp.use(bodyParser.urlencoded({ extended: false }));\napp.use(bodyParser.json());\n\n// Example Node.js request validation code.\napp.get('/example-service', (request, response, next) => {\n  const { url, method, headers, hostname } = request;\n\n  const requestSignature = headers['x-hubspot-signature'];\n\n  // Compute expected signature\n  const uri = `https://${hostname}${url}`;\n  const encodedString = Buffer.from(\n    `${process.env.CLIENT_SECRET}${method}${uri}`,\n    'ascii'\n  ).toString('utf-8');\n  const expectedSignature = crypto\n    .createHash('sha256')\n    .update(encodedString)\n    .digest('hex');\n\n  console.log('Expected signature: %s', requestSignature);\n  console.log('Request signature: %s', expectedSignature);\n\n  // Add your custom handling to compare request signature to expected signature\n  if (requestSignature !== expectedSignature) {\n    console.log('Request of signature does NOT match!');\n    response.status(400).send('Bad request');\n  } else {\n    console.log('Request of signature matches!');\n    response.status(200).send();\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die v3-Anfragesignatur validieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), "-Header ist ein HMAC SHA-256-Hash, der mit dem Client-Geheimnis Ihrer App in Kombination mit Details der Anfrage erstellt wird. Sie enthält auch einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Request-Timestamp"
      }), "-Header."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie eine Anfrage mit dem X-HubSpot-Signatur-v3-Header validieren, müssen Sie:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die Anfrage ablehnen, wenn der Zeitstempel älter als 5 Minuten ist."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Im Anfrage-URI eines der URL-codierten Zeichen, die in der folgenden Tabelle aufgeführt sind. Sie müssen das Fragezeichen, das den Anfang der Abfragezeichenfolge angibt, nicht decodieren."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Codierter Wert"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Decodierter Wert"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ":"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "?"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%40"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "@"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%21"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%24"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "$"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%27"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%28"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "("
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%29"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ")"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2C"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ","
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3B"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ";"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie eine Zeichenfolge, die Folgendes miteinander verkettet: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestMethod"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestUri"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestBody"
        }), " + Zeitstempel (falls vorhanden). Der Zeitstempel wird vom ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Request-Timestamp"
        }), "-Header bereitgestellt."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erstellen Sie einen HMAC SHA-256-Hash der resultierenden Zeichenfolge, indem Sie das Anwendungsgeheimnis als das Geheimnis für die HMAC SHA-256-Funktion verwenden."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Base64-Codieren Sie das Ergebnis der HMAC-Funktion."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vergleichen Sie den Hash-Wert mit der Signatur. Wenn sie gleich sind, wurde diese Anfrage als von HubSpot stammend verifiziert. Es wird empfohlen, einen Zeichenfolgenvergleich mit Zeitkonstant zu verwenden, um sich vor Rechenzeitangriffen zu schützen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "#NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\n# Using Python 3\nimport hashlib\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nprint('source_string: {}'.format(source_string))\n\nhash_result = hashlib.sha256(source_string.encode('utf-8')).hexdigest()\nprint('hash_result: {}'.format(hash_result))\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\nrequire 'digest'\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nputs \"source_string: %s\" % [source_string]\n\nhash_result = Digest::SHA256.hexdigest source_string\nputs \"hash_result: %s\" % [hash_result]\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// NOTE: This is only an example for generating the expected hash.\n// You will need to compare this expected hash with the actual hash in the\n// X-HubSpot-Signature header.\n\nconst crypto = require('crypto');\n\nclientSecret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy';\nhttpMethod = 'POST';\nhttpURI = 'https://www.example.com/webhook_uri';\nrequestBody = '{\"example_field\":\"サンプルデータ\"}';\n\nsourceString = clientSecret + httpMethod + httpURI + requestBody;\nconsole.log('sourceString: ' + sourceString);\n\nhash = crypto.createHash('sha256').update(sourceString).digest('hex');\nconsole.log('hash: ' + hash);\n\n// output:\n// sourceString: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n// hash: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}