"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 142116065450;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-definitions';
const title = exports.title = 'Definir eventos personalizados';
const name = exports.name = 'Definir eventos personalizados';
const metaDescription = exports.metaDescription = 'Más información sobre cómo crear eventos personalizados utilizando la API de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-definici%C3%B3n-de-evento",
  "label": "Crear una definición de evento",
  "parent": null
}, {
  "depth": 0,
  "id": "propiedades-de-eventos",
  "label": "Propiedades de eventos",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades-del-evento-predeterminadas-de-hubspot",
  "label": "Propiedades del evento predeterminadas de HubSpot",
  "parent": "propiedades-de-eventos"
}, {
  "depth": 1,
  "id": "definir-nuevas-propiedades",
  "label": "Definir nuevas propiedades",
  "parent": "propiedades-de-eventos"
}, {
  "depth": 1,
  "id": "actualizar-propiedades-personalizadas-existentes",
  "label": "Actualizar propiedades personalizadas existentes",
  "parent": "propiedades-de-eventos"
}, {
  "depth": 1,
  "id": "eliminar-una-propiedad",
  "label": "Eliminar una propiedad",
  "parent": "propiedades-de-eventos"
}, {
  "depth": 0,
  "id": "actualizar-un-evento",
  "label": "Actualizar un evento",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-un-evento",
  "label": "Eliminar un evento",
  "parent": null
}, {
  "depth": 0,
  "id": "obtener-definiciones-de-eventos-existentes",
  "label": "Obtener definiciones de eventos existentes",
  "parent": null
}, {
  "depth": 0,
  "id": "ejemplo-de-evento-personalizado",
  "label": "Ejemplo de evento personalizado",
  "parent": null
}, {
  "depth": 1,
  "id": "crear-definici%C3%B3n-de-evento-personalizada",
  "label": "Crear definición de evento personalizada",
  "parent": "ejemplo-de-evento-personalizado"
}, {
  "depth": 1,
  "id": "crear-propiedad-en-la-definici%C3%B3n-personalizada-del-evento",
  "label": "Crear propiedad en la definición personalizada del evento",
  "parent": "ejemplo-de-evento-personalizado"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      ProductTier,
      Table,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Definir eventos personalizados"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los eventos personalizados te permiten definir y hacer un seguimiento de eventos exclusivos de tu empresa, como eventos en tu sitio web o en una aplicación. Puedes configurar eventos para almacenar información dentro de propiedades, que luego podrás utilizar en todas las herramientas de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "](/guides/api/analytics-and-events/custom-events/custom-event-completions) Para enviar los datos del evento personalizado [a HubSpot, primero tienes que definir el evento. Esto es similar a los objetos personalizados de CRM, donde primero tienes que definir el objeto personalizado antes de poder crear registros individuales para ese objeto. La definición de un evento incluye detalles como sus metadatos, asociaciones de objetos CRM y propiedades."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, obtén más información sobre cómo crear y gestionar definiciones de eventos utilizando la API. Para aprender a crear definiciones de eventos sin utilizar la API, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events-with-the-code-wizard",
        children: "Base de conocimientos de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una definición de evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear el esquema de eventos personalizado, solicita ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), ". En el cuerpo de la solicitud, incluye definiciones para tu esquema de eventos, incluyendo su etiqueta, nombre, asociaciones de objetos CRM y propiedades personalizadas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra un cuerpo de solicitud de ejemplo junto con las definiciones de los parámetros. Para ver un ejemplo completo, consulta el ejemplo de evento personalizado ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#custom-event-example",
        children: "a continuación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "cadena"
                    }), " (obligatorio)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "La etiqueta legible del evento, que se mostrará en HubSpot (hasta 100 caracteres). Las etiquetas largas pueden aparecer cortadas en algunas partes de la interfaz de usuario de HubSpot."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "name"
                    }), (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "cadena"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["El nombre interno y único del evento, que utilizarás para hacer referencia al evento a través de la API. Si no se proporciona ningún valor, HubSpot generará automáticamente uno basado en el ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), ". Esta propiedad ", (0, _jsxRuntime.jsx)("u", {
                      children: "no puede"
                    }), " modificarse después de crear la definición del evento."]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Ten en cuenta los siguientes requisitos de asignación de nombres:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "Esta propiedad sólo puede contener letras minúsculas, números, guiones bajos y guiones (hasta 50 caracteres)."
                    }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "El primer carácter debe ser una letra."
                    }), "\n"]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "description"
                    }), (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "cadena"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "La descripción del evento, que se mostrará en HubSpot."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "primaryObject"
                    }), (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "cadena"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["El objeto CRM con el que se asociará el evento. Los eventos completados se mostrarán en las líneas de tiempo de los registros de CRM del objeto especificado. Puede ser una de las siguientes: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CONTACT"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "COMPANY"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "DEAL"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "TICKET"
                    }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CUSTOM OBJECT NAME"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Si no se proporciona ningún valor, HubSpot asociará automáticamente el evento con los contactos. Este ", (0, _jsxRuntime.jsx)("u", {
                      children: "no puede"
                    }), " modificarse después de crear la definición del evento."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "propertyDefinitions"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "matriz"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Para cada definición de evento personalizada, HubSpot proporciona un conjunto de propiedades personalizadas predeterminadas que puedes utilizar al enviar los datos del evento. Incluye esta matriz para definir cualquiera de las propiedades personalizadas que tu evento necesite fuera de esas propiedades predeterminadas. Puedes incluir hasta 50 propiedades personalizadas por evento."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Para cada propiedad, incluye:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), ": la etiqueta dentro de la aplicación de la propiedad."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "name"
                      }), ": el nombre interno de la propiedad, que utilizarás cuando envíes datos de la propiedad a través de la API."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "type"
                      }), ": el tipo de propiedad ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "#custom-property-types"
                      }), ". Por opción predeterminada es ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "string"
                      }), "."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "options"
                      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "enumeration"
                      }), " propiedades, la matriz de valores predefinidos, incluyendo un ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "value"
                      }), " para cada uno."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "description"
                      }), ": texto opcional para describir la propiedad."]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#event-properties",
                      children: "propiedades personalizadas del evento"
                    }), " a continuación."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request body\n{\n  \"label\": \"My event label\",\n  \"name\": \"unique_event_name\",\n  \"description\": \"An event description that helps users understand what the event tracks.\",\n  \"primaryObject\": \"COMPANY\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"choice-property\",\n      \"label\": \"Choice property\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"Option 1\",\n          \"value\": \"1\"\n        },\n        {\n          \"label\": \"Option 2\",\n          \"value\": \"2\"\n        }\n      ]\n    },\n    {\n      \"name\": \"string-property\",\n      \"label\": \"String property\",\n      \"type\": \"string\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propiedades de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las propiedades personalizadas del evento se utilizan para almacenar información sobre la finalización de eventos personalizados individuales. Estas propiedades deben utilizarse cuando sea apropiado para enviar finalizaciones de eventos, pero no son necesarias para que una finalización de evento sea válida. Para cada definición de evento, HubSpot proporciona un conjunto predeterminado de 32 propiedades. Además, puedes crear hasta 50 propiedades personalizadas por definición de evento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las propiedades pueden ser de los siguientes tipos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), ": una propiedad que recibe cadenas de texto sin formato. Si el nombre de propiedad contiene las palabras ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "referrer"
        }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "link"
        }), ", el valor de propiedad puede tener hasta 1024 caracteres. De lo contrario, los valores de propiedad pueden tener hasta 256 caracteres."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        }), ": propiedad que recibe valores numéricos con hasta un decimal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), ": Una propiedad con opciones predefinidas. Al crear este tipo de propiedad, incluye una matriz ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), " para establecer los valores disponibles."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime"
        }), ": Propiedad que recibe valores en milisegundos de época o ISO8601 que representan una marca de tiempo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, obtén información sobre las propiedades del evento predeterminadas de HubSpot, sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#define-new-properties",
        children: "definir nuevas propiedades para eventos existentes"
      }), ", y sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#update-existing-custom-properties",
        children: "actualizar las propiedades personalizadas del evento existentes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades del evento predeterminadas de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_description"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_browser"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_campaign_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_city"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_country"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_class"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_link_href"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_system"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_version"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_content_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_title"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_url"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_parent_module_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_referrer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_region"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_height"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_width"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_touchpoint_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_tracking_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_user_agent"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_campaign"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_content"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_medium"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_term"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir nuevas propiedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir una nueva propiedad en un evento personalizado existente, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), ". En el cuerpo de la solicitud, incluye la definición de tu propiedad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"name\": \"property-name\",\n  \"label\": \"Property name\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"label\": \"label\",\n      \"value\": \"value\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando pongas nombre a tu propiedad, ten en cuenta lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Una vez que creas una propiedad, su nombre no se puede cambiar."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El nombre sólo puede contener letras minúsculas, números, guiones bajos y guiones."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El primer carácter del nombre de propiedad debe ser una letra."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El nombre de propiedad y la etiqueta pueden tener hasta 50 caracteres cada uno."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si no se proporciona un nombre de propiedad, se autogenerará uno utilizando la etiqueta de la propiedad."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las etiquetas largas pueden quedar cortadas en algunas partes de la interfaz de HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Actualizar propiedades personalizadas existentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para actualizar una propiedad existente en un evento personalizado, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), ". Los únicos campos de fórmula que se pueden actualizar en una propiedad son los ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), ", y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " para las propiedades de enumeración."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " para cambiar el tipo de propiedad, utiliza el interruptor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " para eliminar la propiedad y volver a crearla con el tipo correcto."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una propiedad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una propiedad existente en un evento personalizado, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property/{propertyName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando se elimina una propiedad, ya no estará disponible para su uso en futuras finalizaciones de eventos. Las finalizaciones anteriores seguirán teniendo los valores de propiedad."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " cuando elimines un evento:"]
      }), (0, _jsxRuntime.jsxs)(_components.ol, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Todos los eventos de esa definición de evento se eliminarán y serán irrecuperables."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["No se puede volver a utilizar ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "eventName"
          }), "' eliminados anteriormente."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar un evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los únicos campos de fórmula que pueden actualizarse en una definición de evento son ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para actualizar un esquema de eventos personalizado existente, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar un evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando eliminas un evento personalizado, las referencias a ese evento en otras herramientas de HubSpot, como workflows e informes, ya no utilizarán el evento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar un evento personalizado, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener definiciones de eventos existentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener una única definición de evento, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para buscar definiciones de eventos por criterios específicos, haz una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), ". Puedes proporcionar los siguientes parámetros de consulta para afinar tu búsqueda:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "searchString"
        }), ": busca eventos que contengan los caracteres especificados en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ". Buscar es ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " difuso, sino que es una búsqueda ingenua que ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "contiene"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        }), ": una cadena con hash proporcionada en las respuestas paginadas para visualizar la página siguiente de los resultados de la búsqueda."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), ": el número máximo de resultados a devolver."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "includeProperties"
        }), ": un valor booleano que especifica si se incluyen propiedades del evento en los resultados devueltos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ejemplo de evento personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, se explica cómo crear un evento personalizado de ejemplo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta explicación cubre:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "crear una definición de evento personalizada."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "crear una nueva propiedad en la definición del evento."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Meta:"
      }), " un concesionario de coches llamado CarSpot tiene un inventario online de todos los coches disponibles en su lote. CarSpot quiere hacer un seguimiento de cuándo los visitantes de su sitio ven un coche concreto disponible en su lote. Para ello, crearán un evento personalizado, que asociarán a los registros de contactos para saber quién ve el anuncio. Este evento también necesitará un conjunto de propiedades personalizadas para almacenar los detalles de la lista de coches que se ven durante cada evento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear definición de evento personalizada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayan decidido cómo llamar al evento, qué propiedades les gustaría incluir en cada evento desencadenante y el objeto de CRM al que quieren asociar los eventos, crearán el esquema del evento haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions"
      }), " con el siguiente cuerpo de solicitud:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for event definitions\n\n{\n  \"label\": \"Viewed Car\",\n  \"name\": \"viewed_car\",\n  \"description\": \"An event that fires when visitor views a car listing in the online inventory\",\n  \"primaryObject\": \"CONTACT\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"condition\",\n      \"label\": \"Condition\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"New\",\n          \"value\": \"new\"\n        },\n        {\n          \"label\": \"Used\",\n          \"value\": \"used\"\n        }\n      ]\n    },\n    {\n      \"name\": \"year\",\n      \"label\": \"Year\",\n      \"type\": \"number\"\n    },\n    {\n      \"name\": \"make\",\n      \"label\": \"Make\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"model\",\n      \"label\": \"Model\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"mileage\",\n      \"label\": \"Mileage\",\n      \"type\": \"number\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear propiedad en la definición personalizada del evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Justo después de implementar el evento en su sitio web, CarSpot decidió que quería ver si el precio de las listas influye en la tasa de clics. Para hacer seguimiento a esto, crearán una nueva propiedad que contenga el precio de la lista."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir una nueva propiedad, realizarán una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions/viewed_car/property"
      }), " con el siguiente cuerpo de solicitud:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for creating a property\n{\n  \"name\": \"price\",\n  \"label\": \"Price\",\n  \"type\": \"number\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con su evento personalizado definido, ahora pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "enviar datos del evento a HubSpot utilizando esta definición de evento personalizado"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}