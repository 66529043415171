"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107554;
const slug = exports.slug = 'guides/apps/authentication/validating-requests';
const title = exports.title = 'Webhook | リクエストの検証';
const name = exports.name = 'vNext Docs DP - HubSpotからのリクエストを検証する';
const metaDescription = exports.metaDescription = '連携においてHubSpotから送信されるリクエストの検証方法の概要。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "v1%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E7%BD%B2%E5%90%8D%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E6%A4%9C%E8%A8%BC",
  "label": "v1リクエスト署名を使用したリクエストの検証",
  "parent": null
}, {
  "depth": 0,
  "id": "v1%E3%81%AE%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E7%BD%B2%E5%90%8D%E3%81%AE%E4%BE%8B%EF%BC%9A",
  "label": "v1のリクエスト署名の例：",
  "parent": null
}, {
  "depth": 0,
  "id": "v2%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E7%BD%B2%E5%90%8D%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E6%A4%9C%E8%A8%BC",
  "label": "v2リクエスト署名を使用したリクエストの検証",
  "parent": null
}, {
  "depth": 1,
  "id": "get%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E4%BE%8B",
  "label": "GETリクエストの例",
  "parent": "v2%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E7%BD%B2%E5%90%8D%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E6%A4%9C%E8%A8%BC"
}, {
  "depth": 1,
  "id": "post%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E4%BE%8B",
  "label": "POSTリクエストの例",
  "parent": "v2%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E7%BD%B2%E5%90%8D%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E6%A4%9C%E8%A8%BC"
}, {
  "depth": 0,
  "id": "v3%E3%81%AE%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E7%BD%B2%E5%90%8D%E3%81%AE%E6%A4%9C%E8%A8%BC",
  "label": "v3のリクエスト署名の検証",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      h2: "h2",
      strong: "strong",
      pre: "pre",
      br: "br",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotからのリクエストを検証する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "連携においてHubSpotから受け取るリクエストが実際にHubSpotから送信されたことを確かめるために、いくつかのヘッダーがリクエストに入力されます。これらのヘッダーと一緒に受信リクエストのフィールドを使用して、リクエストの署名を確認することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "署名の確認方法は署名のバージョンによって異なります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot署名の最新バージョンを使用したリクエストを検証するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-V3"
        }), "ヘッダーを使用し、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests#validate-the-v3-request-signature",
          children: "v3バージョンの署名を検証するための関連手順"
        }), "に従います。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["後方互換性を維持するために、HubSpotからのリクエストには旧バージョンの署名も含まれています。旧バージョンの署名を検証するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-Version"
        }), "ヘッダーをチェックしてから、バージョンが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v1"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v2"
        }), "のどちらであるかに基づいて、以下の関連手順に従います。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の方法で、アプリのクライアントシークレットからのハッシュ値と受信リクエストのフィールドを抽出することができます。ハッシュ値を計算したら、その値を署名と比較します。両者が等しい場合は、リクエストは検証に合格したことになります。そうでない場合は、リクエストが転送中に改ざんされたか、あるいは他の誰かがエンドポイント宛ての偽装のリクエストを送信している可能性があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v1リクエスト署名を使用したリクエストの検証"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリが", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/webhooks/overview",
        children: "Webhooks API経由でCRMオブジェクトイベント"
      }), "に登録されている場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), "ヘッダーが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v1"
      }), "に設定されたリクエストがHubSpotから送信されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), "ヘッダーは、貴社のアプリのクライアントシークレットとリクエスト詳細の組み合わせに基づくSHA-256ハッシュになっています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この署名のバージョンを確認するには、以下の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次のように連結した文字列を作成します：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), "（存在する場合）。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "連結した文字列のSHA-256ハッシュを生成します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ハッシュ値と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature"
        }), "ヘッダーの値を比較します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "同一の場合、このリクエストが検証に合格したことになります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "これらの値が一致しない場合、転送中にリクエストが改ざんされたか、誰かがHubSpotになりすまして貴社のエンドポイントにリクエストを送信していると考えられます。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "リクエスト本文の例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Client secret : yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\n// Request body: [\n{\"eventId\":1,\"subscriptionId\":12345,\"\nportalId\":62515\",\noccurredAt\":1564113600000\",\nsubscriptionType\":\"contact.creation\",\n\"attemptNumber\":0,\n\"objectId\":123,\n\"changeSource\":\"CRM\",\n\"changeFlag\":\"NEW\",\n\"appId\":54321}\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v1のリクエスト署名の例："
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n>>> import hashlib\n\n>>> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n>>> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> source_string = client_secret + request_body\n>>> source_string\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> hashlib.sha256(source_string).hexdigest()\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\nirb(main):003:0> require 'digest'\n=> true\nirb(main):004:0> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\"\nirb(main):005:0> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n=> \"[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):006:0> source_string = client_secret + request_body\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):007:0> Digest::SHA256.hexdigest source_string\n=> \"232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de\"\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n> const crypto = require('crypto')\nundefined\n> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n'[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> source_string = client_secret + request_body\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> hash = crypto.createHash('sha256').update(source_string).digest('hex')\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この場合は以下のハッシュ値が得られます。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v2リクエスト署名を使用したリクエストの検証"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリが", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "ワークフロー内のWebhookアクション"
      }), "からのデータを処理している場合、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "カスタムCRMカード"
      }), "用のデータを返している場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), "ヘッダーが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v2"
      }), "に設定されたリクエストがHubSpotから送信されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), "ヘッダーは、貴社のアプリのクライアントシークレットとリクエスト詳細の組み合わせに基づくSHA-256ハッシュになっています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この署名を検証するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次のように連結した文字列を作成します：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http method"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "URI"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), "（存在する場合）。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "連結した文字列のSHA-256ハッシュを生成します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ハッシュ値を署名と比較します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "同一の場合、このリクエストが検証に合格したことになります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "これらの値が一致しない場合、転送中にリクエストが改ざんされたか、誰かがHubSpotになりすまして貴社のエンドポイントにリクエストを送信していると考えられます。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "メモ："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ソース文字列の作成に使用するURIは、（プロトコルを含めて）元のリクエストと完全に一致する必要があります。署名の検証に問題がある場合は、クエリーパラメーターが元のリクエストに記載した順序と同じになっていることを確かめてください。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ソース文字列は、SHA-256ハッシュ値の計算前にUTF-8にエンコードしておく必要があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "GETリクエストの例"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストでは、アプリのクライアントシークレットと、リクエストのメタデータの特定のフィールドが必要です。これらのフィールドとプレースホルダー値を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "クライアントシークレット："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTTPメソッド："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["リクエスト本文：", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"\""
          })]
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["連結された文字列は次のようになります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyGEThttps://www.example.com/webhook_uri"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記の連結文字列のSHA-256ハッシュを計算することで生成される、ヘッダーの署名と照合する署名は次のようになります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eee2dddcc73c94d699f5e395f4b9d454a069a6855fbfa152e91e88823087200e"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "POSTリクエストの例"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストでは、アプリのクライアントシークレット、リクエストのメタデータの特定のフィールド、リクエスト本文の文字列表現（例：Node.jsサービスでは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "JSON.stringify(request.body)"
      }), "を使用）が必要です。これらのフィールドとプレースホルダー値を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "クライアントシークレット："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTTPメソッド："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "リクエスト本文："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "{\"example_field\":\"example_value\"}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["連結された文字列は次のようになります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"example_value\"}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記の連結文字列のSHA-256ハッシュを計算することで生成される、ヘッダーの署名と照合する署名は次のようになります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "9569219f8ba981ffa6f6f16aa0f48637d35d728c7e4d93d0d52efaa512af7900"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "署名の[ハッシュ値を計算]したら、その署名をリクエストのx-hubspot-signatureヘッダーにある署名と比較できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のNode.jsコードスニペットは、Expressサーバーを実行して受信リクエストを処理していた場合に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストのリクエスト検証を組み込む方法の詳細を示しています。以下のコードブロックは一例であり、Expressサービスの全機能を実行するために必要な特定の依存関係が省略されていることに注意してください。特定のサービスにリクエストの検証を実装するときには、最新の安定した安全なライブラリーを実行していることを確認してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Introduce any dependencies. Only several dependencies related to this example are included below:\nconst express = require('express');\nconst bodyParser = require('body-parser');\nconst crypto = require('crypto');\nconst app = express();\n\n// Add any custom handling or setup code for your Node.js service here.\napp.use(bodyParser.urlencoded({ extended: false }));\napp.use(bodyParser.json());\n\n// Example Node.js request validation code.\napp.get('/example-service', (request, response, next) => {\n  const { url, method, headers, hostname } = request;\n\n  const requestSignature = headers['x-hubspot-signature'];\n\n  // Compute expected signature\n  const uri = `https://${hostname}${url}`;\n  const encodedString = Buffer.from(\n    `${process.env.CLIENT_SECRET}${method}${uri}`,\n    'ascii'\n  ).toString('utf-8');\n  const expectedSignature = crypto\n    .createHash('sha256')\n    .update(encodedString)\n    .digest('hex');\n\n  console.log('Expected signature: %s', requestSignature);\n  console.log('Request signature: %s', expectedSignature);\n\n  // Add your custom handling to compare request signature to expected signature\n  if (requestSignature !== expectedSignature) {\n    console.log('Request of signature does NOT match!');\n    response.status(400).send('Bad request');\n  } else {\n    console.log('Request of signature matches!');\n    response.status(200).send();\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v3のリクエスト署名の検証"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), "ヘッダーは、貴社のアプリのクライアントシークレットとリクエスト詳細の組み合わせに基づくHMAC SHA-256ハッシュになっています。また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Request-Timestamp"
      }), "ヘッダーも含まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の手順に従って、X-HubSpot-Signature-v3ヘッダーを使用してリクエストを検証します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "タイムスタンプが5分前よりも古い場合には、リクエストを拒否します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "リクエストURIで、下表に示すURLエンコード文字のいずれかをデコードします。クエリー文字列の先頭を示す疑問符をデコードする必要はありません。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "エンコード値"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "デコード値"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ":"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "?"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%40"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "@"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%21"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%24"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "$"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%27"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%28"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "("
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%29"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ")"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2C"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ","
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3B"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ";"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次のように連結したUTF-8でエンコードされた文字列を作成します：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestMethod"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestUri"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestBody"
        }), " +タイムスタンプ。タイムスタンプは、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Request-Timestamp"
        }), "ヘッダーで提供されます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリシークレットをHMAC SHA-256関数のシークレットとして使用し、連結した文字列のHMAC SHA-256ハッシュを作成します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HMAC関数の結果をBase64エンコードします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ハッシュ値を署名と比較します。同一の場合、このリクエストの発信元がHubSpotであることが検証されたことになります。タイミング攻撃を防ぐために、一定時間の文字列比較を使用することをお勧めします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "#NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\n# Using Python 3\nimport hashlib\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nprint('source_string: {}'.format(source_string))\n\nhash_result = hashlib.sha256(source_string.encode('utf-8')).hexdigest()\nprint('hash_result: {}'.format(hash_result))\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\nrequire 'digest'\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nputs \"source_string: %s\" % [source_string]\n\nhash_result = Digest::SHA256.hexdigest source_string\nputs \"hash_result: %s\" % [hash_result]\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// NOTE: This is only an example for generating the expected hash.\n// You will need to compare this expected hash with the actual hash in the\n// X-HubSpot-Signature header.\n\nconst crypto = require('crypto');\n\nclientSecret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy';\nhttpMethod = 'POST';\nhttpURI = 'https://www.example.com/webhook_uri';\nrequestBody = '{\"example_field\":\"サンプルデータ\"}';\n\nsourceString = clientSecret + httpMethod + httpURI + requestBody;\nconsole.log('sourceString: ' + sourceString);\n\nhash = crypto.createHash('sha256').update(sourceString).digest('hex');\nconsole.log('hash: ' + hash);\n\n// output:\n// sourceString: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n// hash: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}