"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501378058;
const slug = exports.slug = 'guides/api/marketing/subscriptions-preferences';
const title = exports.title = 'API de Marketing | Preferencias de suscripción';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Subscriptions Preferences';
const metaDescription = exports.metaDescription = ' Los puntos finales de preferencias de suscripción te permiten administrar los detalles de las suscripciones para contactos en un portal de HubSpot';
const toc = exports.toc = [{
  "depth": 0,
  "id": "obtener-estado-de-suscripci%C3%B3n-del-contacto",
  "label": "Obtener estado de suscripción del contacto",
  "parent": null
}, {
  "depth": 0,
  "id": "suscribir-al-contacto",
  "label": "Suscribir al contacto",
  "parent": null
}, {
  "depth": 0,
  "id": "cancelar-la-suscripci%C3%B3n-del-contacto",
  "label": "Cancelar la suscripción del contacto",
  "parent": null
}, {
  "depth": 0,
  "id": "obtener-tipos-de-suscripci%C3%B3n",
  "label": "Obtener tipos de suscripción",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Preferencias de suscripción"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los tipos de suscripción representan la base legal para comunicarte con tus contactos a través del correo electrónico.Los contactos pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/articles/kcs_article/email/how-to-send-contacts-an-email-with-an-opt-out-link-so-they-can-manage-their-preferences",
        children: "gestionar sus preferencias de correo"
      }), " para que solo se suscriban a los correos electrónicos que desean recibir."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " la API de preferencias de suscripción ", (0, _jsxRuntime.jsx)("u", {
          children: "no admite"
        }), " actualmente la recuperación o actualización de la información de suscripción de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/inbox/collect-consent-for-whatsapp-conversations",
          children: "WhatsApp"
        }), " para un contacto."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener estado de suscripción del contacto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El punto final de estado de suscripción del contacto les permite a los usuarios recuperar los estados de suscripción para una dirección de correo electrónico en una cuenta."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este punto final es ideal para cuando tienes un centro de preferencias externas o una integración y necesitas saber los estados de suscripción para cualquier dirección de correo electrónico en tu cuenta."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suscribir al contacto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El punto final de suscribir al contacto te permite suscribir a una dirección de correo electrónico a cualquier tipo de suscripción en una cuenta, pero ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "no te permitirá volver a suscribir contactos que cancelaron su suscripción."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de uso:"
      }), " este punto de terminación es ideal para cuando tienes una integración o un formulario externo que debe incluirse voluntariamente en un tipo de suscripción."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Nota"
      }), ": el punto de terminación de contacto de suscripción solo debe utilizarse para garantizar solicitudes de contactos que te han dado permiso para suscribirlos. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/contacts/how-do-subscription-preferences-and-types-work",
        children: "Revisa las leyes y regulaciones aplicables"
      }), " antes de suscribir a un contacto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancelar la suscripción del contacto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El punto final de cancelar la suscripción del contacto permite que te permita cancelar la suscripción de una dirección de correo electrónico en una cuenta de cualquier tipo de suscripción."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de uso:"
      }), " este punto de terminación es ideal para cuando tienes una integración o un formulario externo que debe excluirse voluntariamente de un tipo de suscripción."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener tipos de suscripción"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El punto final de información de suscripción les permite a los usuarios recuperar todos los tipos de suscripción en su cuenta."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de uso:"
      }), " este punto de terminación es ideal para cuando tienes un centro de preferencias externas, integración o formulario y necesitas saber qué tipos de suscripción existen en tu cuenta de manera que puedas actualizar los estados de suscripción para tus contactos según corresponda."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {})]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}