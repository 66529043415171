"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492270471;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/overview';
const title = exports.title = 'Drag-&-Drop-Bereiche';
const name = exports.name = 'EMEA DACH (de) Drag and Drop Areas';
const metaDescription = exports.metaDescription = 'Drag-&-Drop-Bereiche ermöglichen Content-Autoren das Platzieren von Modulen, das Ändern von Layout und Formatieren mit einigen Stilen innerhalb der Content-Editoren. Entwickler erstellen vordefinierte Layouts.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "die-sicht-des-content-autors",
  "label": "Die Sicht des Content-Autors",
  "parent": null
}, {
  "depth": 0,
  "id": "die-sicht-des-entwicklers",
  "label": "Die Sicht des Entwicklers",
  "parent": null
}, {
  "depth": 0,
  "id": "drag-%26-drop-bereiche-und-ihre-elemente",
  "label": "Drag-&-Drop-Bereiche und ihre Elemente",
  "parent": null
}, {
  "depth": 1,
  "id": "abschnitt",
  "label": "Abschnitt",
  "parent": "drag-%26-drop-bereiche-und-ihre-elemente"
}, {
  "depth": 1,
  "id": "s%C3%A4ule",
  "label": "Säule",
  "parent": "drag-%26-drop-bereiche-und-ihre-elemente"
}, {
  "depth": 1,
  "id": "zeile",
  "label": "Zeile",
  "parent": "drag-%26-drop-bereiche-und-ihre-elemente"
}, {
  "depth": 1,
  "id": "modul",
  "label": "Modul",
  "parent": "drag-%26-drop-bereiche-und-ihre-elemente"
}, {
  "depth": 0,
  "id": "html-struktur-und--styling",
  "label": "HTML-Struktur und -Styling",
  "parent": null
}, {
  "depth": 1,
  "id": "editor-und-attributstile",
  "label": "Editor und Attributstile",
  "parent": "html-struktur-und--styling"
}, {
  "depth": 0,
  "id": "migration",
  "label": "Migration",
  "parent": null
}, {
  "depth": 0,
  "id": "verwandte-tutorials-und-leitf%C3%A4den",
  "label": "Verwandte Tutorials und Leitfäden",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Drag-&-Drop-Bereiche"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Seite illustriert und erklärt die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "-Erfahrung und Konzepte. Da dies eine ziemlich umfassende Erfahrung ist, empfiehlt es sich, sich mit ihr vertraut zu machen, bevor man in den Code eintaucht."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie bereit zum Entwickeln sind, finden Sie unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "Erste Schritte mit Drag-&-Drop-Bereichen"
      }), " und in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Referenz für Drag-&-Drop-Bereichen"
      }), " weitere Informationen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe von Drag-&-Drop-Bereichen können Entwickler Seitenbereiche und globale Teilvorlagen erstellen, die das Erstellen von Inhalten erleichtern. Drag-&-Drop-Bereiche ermöglichen Content-Autoren das Platzieren von Modulen, das Ändern von Layout und Formatieren mit einigen Stilen innerhalb der Content-Editoren. Auf diese Weise sind weniger Vorlagen notwendig, mit denen Content-Autoren eine Vielzahl von Seiten mit unterschiedlichen Zwecken und Layouts selbst erstellen können. Content-Autoren können dies tun, ohne jemals mit Code herumfummeln zu müssen oder neue Vorlagen für kleine Layoutänderungen zu benötigen. Da sie so flexibel und nützlich sind, ist es eine gute Idee, mindestens eine Vorlage in Ihrem Design mit Drag-&-Drop-Bereichen zu versehen."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Drag-&-Drop-Bereiche können derzeit nicht in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "Blog-Beitragsvorlagen"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "E-Mail-Vorlagen"
        }), " verwendet werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Sicht des Content-Autors"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn ein Content-Autor eine Seite mit einer Vorlage erstellt, die über Drag-&-Drop-Bereiche verfügt, sieht er zunächst die Seite mit vordefinierten Modulen, die vom Entwickler in einem Layout platziert wurden. Dies hilft dem Content-Autor zu verstehen, wie die meisten Seiten, die diese Vorlage verwenden, optisch aussehen sollten oder wahrscheinlich aussehen. Dies ist ein Ausgangspunkt, jedoch kein feststehendes Erscheinungsbild. Der Content-Autor kann Module, Abschnitte, Zeilen und Spalten durch Ziehen und Ablegen neu anordnen. Er kann ihre Größe ändern, ihren Inhalt bearbeiten und verschiedene visuelle Styling-Einstellungen anpassen, mit denen er Inhalte vertikal und horizontal ausrichten und Hintergründe hinzufügen kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für Content-Autoren bedeutet dies eine Menge Kontrollmöglichkeiten. Dies gibt ihm genügend Flexibilität, um einfache Änderungen an der Seite vorzunehmen, ohne dass er einen Entwickler für kleine Anpassungen bemühen muss."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten Sie:"
        }), " Ein Content-Autor kann die Vorlage einer Seite gegen eine andere Vorlage desselben Typs austauschen, je nachdem, ob sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "-Tags enthält."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Mit dem visuellen Drag-&-Drop-Layout-Editor erstellte Vorlagen können gegen andere Drag-&-Drop-Vorlagen oder Code-Vorlagen mit oder ohne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags können nur gegen andere Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen ", (0, _jsxRuntime.jsx)("u", {
            children: "ohne"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area-"
          }), "-Tags können nur gegen andere Code-Vorlagen ohne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/drag-and-drop.gif",
        alt: "Das Seiten-Editor-Erlebnis für Drag-&-Drop-Bereiche"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Sicht des Entwicklers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für Entwickler ist die Arbeit mit Drag-&-Drop-Bereichen ein wenig ähnlich wie die Arbeit mit gängigen CSS-Frameworks und deren Rastern. Die Entwickler gestalten die Seite mit Containern, die Abschnitte genannt werden und Zeilen enthalten. Innerhalb dieser Zeilen befinden sich Module und Spalten. Nahezu alles, was Content-Autoren im Seiten-Editor mit Drag-&-Drop-Bereich-Tags tun können, können Entwickler im Code in den Vorlagen selbst tun. Was die Entwickler erstellen, ist der Standardinhalt für Seiten, die diese Vorlage verwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Vorteil der Verwendung von Drag-&-Drop-Bereichen gegenüber der festen Programmierung von Modul-Tags in die Vorlage besteht darin, dass Content-Autoren den Inhalt und das Layout innerhalb dieser Bereiche ändern können. Aufgrund dieser Flexibilität kann eine einzige Vorlage mit Drag-&-Drop-Bereichen für eine Vielzahl von Seitendesigns verwendet werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sehen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "CMS-Theme-Boilerplate-Vorlagen"
      }), " an, um Anwendungsbeispiele für ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "-Tags anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd%20coded.png",
        alt: "dnd_area in VS Code programmiert"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Drag-&-Drop-Bereiche und ihre Elemente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag-&-Drop-Bereiche bestehen aus einer Handvoll Grundbausteine, die das Layout definieren und die Flexibilität bei der Gestaltung der Ausrichtung unterstützen. Das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "-Tag erstellt einen Bereich in der Seite, der vom Seiten-Editor erkannt wird und das Hinzufügen, Entfernen und Neuanordnen von Drag-&-Drop-Elementen ermöglicht. Sie definieren diese mit HubL innerhalb von HTML+HubL-Vorlagen. Alle Drag-&-Drop-Elemente befinden sich in einem Drag-&-Drop-Bereich. Sie können keine Drag-&-Drop-Bereiche verschachteln, und Drag-&-Drop-Elemente können selbst keine Drag-&-Drop-Bereiche enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für Entwickler ist es wichtig zu verstehen, dass der Inhalt des Drag-&-Drop-Bereichs, den Sie in der Vorlage definieren, ein Ausgangspunkt für Seiten ist, die diese Vorlage verwenden. Content-Autoren können den Inhalt innerhalb eines Drag-&-Drop-Bereichs vollständig ändern. Ihre Aufgabe ist es, ihnen einen vernünftigen Ausgangspunkt zu bieten, von dem aus sie Änderungen vornehmen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Example%20structure.png",
        alt: "Beispielstruktur"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieses Diagramm zeigt, wie die verschiedenen Drag-&-Drop-Elemente verschachtelt werden können. Ein wichtiges Konzept ist, dass sowohl Abschnitte als auch Zeilen Spalten und Module enthalten können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Drag%20and%20Drop%20relationships.png",
        alt: "Beziehungen von Drag-&-Drop-Elementen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abschnitt"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abschnitte sind ein besonderer Typ von Zeile. Abschnitte werden in Vorlagen mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), "-Tag erstellt. Sie sind das einzige Drag-&-Drop-Element, das ein direkter Abkömmling eines Drag-&-Drop-Bereichs sein kann. Sie können sich die Abschnitte wie ein Container mit äußerer Umhüllung vorstellen. Sie können den Inhalt in voller Breite oder mit einer begrenzten maximalen Breite in der Mitte anzeigen. Da die Abschnitte Spalten und Module umschließen, ist es einfach, große Teile des Inhalts neu anzuordnen und zu implementieren. Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "-Tag rendert kein HTML-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), "-Element."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Section%20in%20page%20editor.png",
        alt: "Abschnitt im Seiten-Editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Erscheinungsbild eines Abschnitts im Seiten-Editor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entwickler können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "Abschnittsvorlagen"
      }), " bereitstellen, die wiederverwendbare Abschnitte im Seiten-Editor erstellen. Dabei handelt es sich um vorkonfigurierte, vom Entwickler definierte Abschnitte, die Content-Autoren als Ausgangspunkt verwenden können. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
        children: "Wiederverwendbare Abschnitte"
      }), " haben einige einzigartige Fähigkeiten, einschließlich der Möglichkeit, sie ähnlich wie eine Standard-HubL-Teilvorlage zu nutzen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Säule"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Spalten sind Wrapper für Zeilen und Module. Sie platzieren Spalten innerhalb einer Zeile oder eines Abschnitts, der eine spezielle Zeile ist. Spalten werden in Vorlagen mithilfe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-column-code-dnd-column-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_spalte"
        })
      }), "-Tags erstellt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie mehrere Spalten innerhalb einer Zeile, um Zeilen und die darin enthaltenen Module horizontal zu platzieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Spalten sind vertikale Bereiche, die Zeilen enthalten können. Sie können Spalten in verschiedenen Größen erstellen, indem Sie ihre Breite ändern. Die Größe einer Zeile ist 12 „Spalten“ breit, dies bezieht sich auf das CSS Grid. Die Spalten innerhalb einer Zeile können beliebig kleiner als 12 sein, dürfen aber in der Summe nicht mehr als 12 ergeben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn mehrere Zeilen innerhalb einer Spalte platziert werden, erscheinen die Module innerhalb dieser Zeilen vertikal gestapelt. Da Module selbst Spalten sind, kann ein Modul kein direkter Abkömmling einer Spalte sein, sondern muss sich innerhalb einer Zeile befinden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20column%20in%20page%20editor.png",
        alt: "Drag-&-Drop-Bereich-Spalte im Seiten-Editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Erscheinungsbild einer Spalte im Seiten-Editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zeile"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zeilen sind Wrapper für Spalten. Zeilen werden in Vorlagen mithilfe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-row-code-dnd-row-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        })
      }), "-Tags erstellt. Da es sich bei den Modulen um Spalten handelt, können Sie sie direkt innerhalb einer Zeile platzieren. Dadurch werden die Module horizontal nebeneinander angezeigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Module können vertikal organisiert werden, indem sie innerhalb von Zeilen platziert werden. Wenn Sie ein Modul über einem anderen platzieren möchten, müssen Sie dieses Modul in einer Zeile platzieren. Sie würden dann ein weiteres Modul in einer Zeile über oder unter dieser ersten Zeile hinzufügen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20row%20in%20page%20editor.png",
        alt: "Drag-&-Drop-Bereich-Zeile im Seiten-Editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Erscheinungsbild einer Zeile im Seiten-Editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modul"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), " sind ein grundlegender Bestandteil von CMS Hub und dienen als wiederverwendbare Grundbausteine, mit denen Sie eine Website zusammenstellen und Inhalte anzeigen können. Bei der Erstellung einer Vorlage platzieren Sie Module innerhalb von Drag-&-Drop-Zeilen und -Abschnitten mithilfe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-module-code-dnd-module-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "-Tags. Module sind auch Spalten. Das heißt, wenn Sie zwei Modul-Tags oder ein Modul und eine Spalte direkt nebeneinander platzieren, werden sie horizontal nebeneinander angezeigt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Innerhalb eines Moduls können keine Drag-&-Drop-Elemente platziert werden. Module können nicht direkte untergeordnete Elemente eines ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML-Struktur und -Styling"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag-&-Drop-Bereiche und ihre Elemente haben, wenn sie gerendert werden, Klassennamen für ein 12-Spalten-Raster auf der Grundlage von Bootstrap 2. Um den Einstieg zu erleichtern, können Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "_layout.css"
      }), "-Datei aus der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/",
        children: "CMS Theme Boilerplate"
      }), " verwenden. Dies bietet Standardstile für diese Klassennamen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie sind nicht verpflichtet, dieses Stylesheet zu verwenden und können stattdessen Ihre eigenen Styles bereitstellen. Wenn Sie Ihre Website auf der Grundlage der CMS Theme Boilerplate erstellen und Ihr eigenes CSS nutzen möchten, müssen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/5abaf2a4c45a95dbed1d459f7f0f6407350752ac/src/templates/layouts/base.html#L8",
        children: "layout.css vom Aufruf in base.html entfernen"
      }), ". Für Ihr eigenes CSS Grid müssen Sie dieselben Rasterklassennamen verwenden, aber das Styling ist Ihnen überlassen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag-&-Drop-Bereiche erstellen, wenn sie gerendert werden, Divs mit Klassen, die vom Seiten-Editor verwendet werden. Beispiele wären ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-span"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-type-cell"
      }), ". Sie sollten diese Klassen nicht direkt anvisieren, da sie vom Seiten-Editor verwendet werden und sich in Zukunft ändern könnten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie stattdessen in Ihrem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "-HubL einen class-Parameter mit einem Klassennamen hinzu, den Sie verwenden möchten"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"container-fluid my-custom-class\">\n  <div class=\"row-fluid-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n      <!--end widget-span -->\n    </div>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Editor und Attributstile"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit Drag-&-Drop-Bereichen können Content-Autoren das Styling der Seite beeinflussen. Sie können zum Beispiel einen Abschnitt mit einem Hintergrund versehen. Entwickler können Standardwerte für diese Einstellungen über Attribute übergeben."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn die Seite tatsächlich gerendert wird, werden die Stile, die auf der Grundlage dieser Einstellungen generiert werden, zu den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), " hinzugefügt."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Beim Start von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " wurden diese Stile von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        }), " geladen. Das wurde ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/drag-and-drop-area-styles-moved-from-footer-to-header",
          children: "vor kurzem geändert"
        }), " zu ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        }), " und wird für alle HubSpot-Accounts mit CMS Hub eingeführt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Vorlagen, die mit flexiblen Spalten erstellt wurden, auf die Verwendung von Drag-&-Drop-Bereichen umstellen, sollten Sie einige Dinge beachten. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Flexible Spalten sind nicht dasselbe wie Drag-&-Drop-Bereiche. Sie können nicht von einer Vorlage, die nur eine flexible Spalte hat, zu einer Vorlage wechseln, die nur einen Drag-&-Drop-Bereich hat"
      }), ". Aus Sicherheitsgründen erlauben wir dies nicht. Der Inhalt könnte in einem solchen Fall nicht von der flexiblen Spalte auf den Drag-&-Drop-Bereich übertragen werden. Zur Veranschaulichung, warum das so ist, nehmen wir an, Sie haben Ihre neue Vorlage so aufgebaut, dass Sie eine Seitenleiste und einen Hauptinhaltsbereich haben. Ihre Seitenleiste ist eine flexible Spalte, Ihr Hauptinhalt ist ein Drag-&-Drop-Bereich. Das Tool zum Austauschen würde die flexible Spalte auf die flexible Spalte übertragen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwandte Tutorials und Leitfäden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites#drag-and-drop-areas",
          children: "Hinzufügen von Drag-&-Drop-Bereichen zu bestehenden Websites"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Erstellen eines Drag-&-Drop-Bereichs"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}