"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620923;
const slug = exports.slug = 'guides/apps/overview';
const title = exports.title = "Présentation de la création d'applications";
const name = exports.name = 'EMEA (FR) vNext Docs DP - Developer tools and accounts';
const metaDescription = exports.metaDescription = 'Découvrez-en davantage sur les outils et les comptes de développeur de HubSpot. Utilisez-les pour créer une intégration, lister une application sur le marketplace ou créer un compte de test.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "types-d-applications",
  "label": "Types d'applications",
  "parent": null
}, {
  "depth": 1,
  "id": "applications-priv%C3%A9es",
  "label": "Applications privées",
  "parent": "types-d-applications"
}, {
  "depth": 2,
  "id": "applications-priv%C3%A9es-dans-des-projets-b%C3%AAta",
  "label": "Applications privées dans des projets (BÊTA)",
  "parent": "types-d-applications"
}, {
  "depth": 1,
  "id": "applications-publiques",
  "label": "Applications publiques",
  "parent": "types-d-applications"
}, {
  "depth": 1,
  "id": "extensions-prises-en-charge-par-type-d-application",
  "label": "Extensions prises en charge par type d'application",
  "parent": "types-d-applications"
}, {
  "depth": 0,
  "id": "types-de-comptes-pour-le-d%C3%A9veloppement-d-applications",
  "label": "Types de comptes pour le développement d'applications",
  "parent": null
}, {
  "depth": 0,
  "id": "authentification",
  "label": "Authentification",
  "parent": null
}, {
  "depth": 1,
  "id": "cl%C3%A9s-d-api-des-comptes-de-d%C3%A9veloppeur",
  "label": "Clés d'API des comptes de développeur",
  "parent": "authentification"
}, {
  "depth": 0,
  "id": "delete-a-developer-account",
  "label": "Delete a developer account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      ul: "ul",
      li: "li",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Présentation de la création d'applications"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les applications vous permettent d'authentifier les appels d'API vers votre compte HubSpot, de vous abonner aux événements avec des webhooks et d'étendre l'interface utilisateur HubSpot, par exemple avec des cartes personnalisées. HubSpot propose plusieurs types d'applications et d'extensions en fonction des besoins de votre intégration. Découvrez ci-dessous les types d'applications et d'extensions que vous pouvez créer avec HubSpot, comment commencer à les créer et comment les gérer dans HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types d'applications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Selon le type d'intégration que vous souhaitez créer, vous devrez choisir le bon type d'application. Découvrez ci-dessous les types d'applications que vous pouvez créer et les fonctionnalités prises en charge."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour la création d'extensions, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#supported-extensions-by-app-type",
        children: "consultez le tableau de référence ci-dessous"
      }), " pour un aperçu des extensions pouvant être créées selon les types d'applications."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Applications privées"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les applications privées peuvent être créées pour un seul compte HubSpot et sont mieux adaptées aux scripts uniques ou aux extensions à usage unique. Par exemple, vous pouvez créer une application privée pour votre compte HubSpot pour :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "créer un nouvel objet personnalisé via l'API ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "importer des fiches d'informations de CRM à partir d'un fichier CSV ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "authentifier les demandes d'API dans des actions d'automatisation personnalisées et des chatbots."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "Créez et modifiez des abonnements de webhook"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En général, les applications privées sont plus simples à mettre en œuvre que les applications publiques. Les applications privées s'authentifient avec des jetons d'accès et ne peuvent pas être répertoriées sur le marketplace des applications HubSpot. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "les situations dans lesquelles créer des applications privées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si ce type d'application correspond à vos besoins, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "commencez à créer une application privée dans votre compte HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Applications privées dans des projets (BÊTA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les applications privées créées avec des projets permettent de créer des extensions d'interface utilisateur pour les fiches d'informations de CRM. La différence lors de la création d'une application privée via cette méthode est que les projets ne prennent en charge que la création d'extensions d'interface utilisateur, que les applications privées en dehors des projets ne peuvent pas créer."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si ce type d'application répond à vos besoins, consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "guide de démarrage rapide des projets"
      }), " pour commencer à créer une application et un projet privés à l'aide de l'ILC."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Applications publiques"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les applications publiques peuvent être installées dans plusieurs comptes. Outre les types d'extensions que vous pouvez créer avec une application privée, les applications publiques prennent en charge des fonctionnalités avancées, telles que :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "l'abonnement à des événements à l'échelle du compte à l'aide de l'API des webhooks ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "la création d'événements de chronologie personnalisés sur des fiches d'informations de CRM à l'aide de l'API d'événements de chronologie ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "la création de pages de paramètres d'application personnalisées dans HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les applications publiques s'authentifient avec OAuth et peuvent être répertoriées sur le marketplace des applications HubSpot. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "les situations dans lesquelles créer des applications publiques"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si ce type d'application répond à vos besoins, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "commencez à créer une application publique dans votre compte de développeur d'applications"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensions prises en charge par type d'application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'application"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Extensions prises en charge"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Application privée"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/video-conferencing",
                  children: "Extension de vidéoconférence"
                })
              })
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Application publique"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/calling-sdk",
                  children: "Kit de développement logiciel pour les appels"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "Cartes CRM"
                }), "*"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/timeline",
                  children: "Événements de chronologie"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Extension de vidéoconférence"
              })]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Application privée créée avec des projets (BÊTA)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/crm/ui-extensions/create",
                  children: "Extensions d'interface utilisateur"
                })
              })
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["* Les cartes CRM que vous pouvez créer avec des applications publiques sont différentes des cartes personnalisées que vous pouvez créer en tant qu'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "extensions d'interface utilisateur avec des projets (BÊTA)"
        }), ". Les extensions d'interface utilisateur offrent des fonctionnalités plus avancées et des composants personnalisables."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types de comptes pour le développement d'applications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bien que les comptes de développeur et de test fonctionnent ensemble, leurs objectifs sont différents."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les comptes de développeurs d'applications servent à créer à répertorier des applications sur le marketplace des applications."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Testez les comptes destinés à tester les API et les applications que vous créez sans impacter les données réelles d'un compte HubSpot. Il s'agit d'un compte distinct d'un compte sandbox que vous pouvez créer dans les comptes HubSpot standard."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "types de compte HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentification"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez créer une intégration personnalisée avec un seul compte HubSpot, vous pouvez créer une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "application privée"
      }), " et utiliser son ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "jeton d'accès"
      }), " pour authentifier les appels d'API ou utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " avec une application publique. Toute application conçue pour une installation par plusieurs comptes HubSpot ou pour un listing sur le marketplace des applications doit utiliser OAuth."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " À compter du 30 novembre 2022, les clés d'API HubSpot sont obsolètes et ne sont plus prises en charge. L'utilisation continue des clés d'API HubSpot constitue un risque pour la sécurité de votre compte et de vos données. Pendant cette phase, HubSpot peut désactiver votre clé à tout moment."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Veillez plutôt à vous authentifier à l'aide d'un jeton d'accès d'application privée ou d'OAuth. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "cette modification"
        }), " et sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migration d'une intégration de clé d'API"
        }), " pour utiliser une application privée à la place."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez vous abonner à des webhooks ou configurer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth pour votre application"
      }), ", vous devez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "créer un compte de développeur"
      }), ". Avec les comptes de développeur, vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "lister vos applications"
      }), " sur le marketplace des applications ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "créer un compte de test"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Clés d'API des comptes de développeur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour gérer les paramètres de votre application via l'API, vous pouvez utiliser une clé d'API de développeur. Cette clé d'API se distingue des clés d'API standard, qui sont obsolètes. Les clés d'API de développeur peuvent être utilisées pour la gestion des abonnements pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "l'API des webhooks"
      }), " et la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "création ou la mise à jour des types d'événements pour la fonctionnalité des événements chronologiques"
      }), ". Tous les autres appels d'API doivent être effectués à l'aide d'un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "jeton d'accès d'application privée"
      }), " ou d'OAuth."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour accéder à votre clé d'API de compte de développeur d'applications :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur d'applications, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications"
        }), " dans la barre de navigation supérieure."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle supérieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Obtenir la clé d'API HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Afficher la clé"
        }), ". La clé sera révélée et vous pourrez ensuite cliquer sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copier"
        }), " à côté de la clé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous pouvez également désactiver la clé d'API précédente et en générer une nouvelle en cliquant sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Générer la clé"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/show_dev_api_key.webp",
        alt: "show_dev_api_key"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez supprimer des comptes de développeur s'ils ne contiennent plus d'applications avec des installations ou des listings de marketplace actifs. Si votre compte contient des applications ou des listings de marketplace actifs et que vous souhaitez le supprimer, veuillez contacter le support pour obtenir de l'aide."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois votre compte supprimé, vous ne pourrez plus y accéder. Si vous pouvez basculer entre plusieurs comptes HubSpot, le compte supprimé n'apparaîtra plus."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans votre compte de développeur HubSpot, cliquez sur le nom de votre compte figurant dans l'angle supérieur droit, puis sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Compte."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/account.jpeg",
        alt: "account"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Supprimer le compte"
      }), ". Si votre compte contient des applications ou des listings de marketplace actifs, ce bouton sera désactivé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Account2.jpg",
        alt: "Account2"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la boîte de dialogue, saisissez votre ID de compte, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Supprimer le compte de développeur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Confirm2.jpg",
        alt: "Confirm2"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}