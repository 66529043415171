"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400699;
const slug = exports.slug = 'reference/cms/hubl/overview';
const title = exports.title = 'Sintaxe do HubL';
const name = exports.name = 'LATAM BR (PT) HubL Syntax';
const metaDescription = exports.metaDescription = 'O HubL é a linguagem inspirada em jinja que é utilizada para criar modelos e módulos no HubSpot CMS. Instruções If, loops For, inclusões etc.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-delimitadores",
  "label": "Tipos de delimitadores",
  "parent": null
}, {
  "depth": 1,
  "id": "instru%C3%A7%C3%B5es",
  "label": "Instruções",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 1,
  "id": "express%C3%B5es",
  "label": "Expressões",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 2,
  "id": "tag-do",
  "label": "Tag do",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 1,
  "id": "coment%C3%A1rios",
  "label": "Comentários",
  "parent": "tipos-de-delimitadores"
}, {
  "depth": 0,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-e-macros",
  "label": "Variáveis e macros",
  "parent": null
}, {
  "depth": 0,
  "id": "filtros-e-fun%C3%A7%C3%B5es",
  "label": "Filtros e funções",
  "parent": null
}, {
  "depth": 0,
  "id": "instru%C3%A7%C3%B5es-if",
  "label": "Instruções If",
  "parent": null
}, {
  "depth": 0,
  "id": "loops-for",
  "label": "Loops For",
  "parent": null
}, {
  "depth": 0,
  "id": "outros-recursos-do-hubl",
  "label": "Outros recursos do HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "limpar-os-delimitadores-do-hubl",
  "label": "Limpar os delimitadores do HubL",
  "parent": "outros-recursos-do-hubl"
}, {
  "depth": 1,
  "id": "incluir-aquivos-em-arquivos",
  "label": "Incluir aquivos em arquivos",
  "parent": "outros-recursos-do-hubl"
}, {
  "depth": 1,
  "id": "blocos-e-extens%C3%B5es",
  "label": "Blocos e extensões",
  "parent": "outros-recursos-do-hubl"
}, {
  "depth": 1,
  "id": "copiar-se%C3%A7%C3%A3o-do-hubl",
  "label": "Copiar seção do HubL",
  "parent": "outros-recursos-do-hubl"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral da sintaxe do HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O CMS do HubSpot usa a HubSpot Markup Language, conhecida como HubL (pronuncia-se \"Hubble\"). O HubL é a extensão da HubSpot do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", um mecanismo de modelagem baseado em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jinja.palletsprojects.com/en/latest/",
        children: "Jinja"
      }), ". O HubL usa uma quantidade razoável de marcação que é exclusiva da HubSpot e não suporta todos os recursos do Jinja."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este artigo demonstra os conceitos básicos dos recursos e sintaxes do HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de delimitadores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Semelhante a outras linguagens de modelo comumente usadas, como o PHP, o HubL pode ser combinado ao HTML em arquivos de modelos codificados ou módulos de modelos do HubL. Para diferenciar onde o HubL começa e termina, você precisará aprender alguns símbolos importantes que atuam como delimitadores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% %} - statement delimiters\n{{ }} - expression delimiters\n{# #} - comment delimiters\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Esteja atento aos agrupar comentários em seu código, pois isso pode resultar na renderização da última tag de comentário como texto."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Instruções"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As instruções do HubL são usadas para criar módulos editáveis, definir lógica de modelo condicional, configurar loops, definir variáveis e muito mais. As instruções são delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%"
      }), ". Elas não imprimem nada na página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Expressões"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Expressões imprimem valores armazenados no contexto do modelo. As expressões são delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{"
      }), ". Por exemplo, uma variável deve ser definida como uma expressão, e a expressão do HubL é usada para imprimir a variável."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tag do"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A tag 'do” funciona exatamente como as instruções regulares ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% ... %}"
      }), "; Isso pode ser usado para modificar listas e dicionários."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Observação: ao adicionar a matrizes, você usa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/functions#append",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".append()"
        })
      }), " e ao adicionar a objetos, você usa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update()"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "# Arrays\n{% set navigation = [\"Home\", \"About\"] %}\n{% do navigation.append(\"Contact Us\") %}\n{{navigation}}\n\n# Objects\n{% set book = {\"name\" : \"Rocking HubL\", \"author\" : \"John Smith\" }%}\n{% do book.update({\"ebook\" : \"yes\"}) %}\n{{book}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "# Arrays [Home, About, Contact Us] # Objects {name=Rocking HubL, author=John\nSmith, ebook=yes}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comentários"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O último tipo de delimitador que você pode encontrar ou decidir utilizar ao desenvolver com o HubL é um comentário do HubL. Os comentários são definidos por um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{#"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/modules",
        children: "Os módulos"
      }), " são as áreas dinâmicas de um modelo que podem ser personalizadas pelo usuário final no editor de conteúdo. Por exemplo, ao codificar um arquivo de modelo do zero, você adiciona módulos aos modelos usando as tags do HubL para fornecer aos criadores de conteúdo a capacidade de editar as áreas da página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As tags dos módulos são constituídas pelos seguintes componentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tipo de módulo:"
        }), " especifica o módulo que deve ser renderizado. Consulte a página ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/hubl/tags",
          children: "Tags suportadas pelo HubL"
        }), " para ver uma lista dos diferentes tipos de módulos disponíveis."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Um nome exclusivo para esse módulo:"
        }), " dá ao módulo uma identidade exclusiva no contexto do modelo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Caminho:"
        }), " dependendo da tag, define a localização do módulo no gerenciador de design. O caminho dos módulos padrão do HubSpot deve sempre começar com @hubspot/ seguido do tipo de módulo. Veja o exemplo abaixo e a seção ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/modules/using-modules-in-templates",
          children: "usar módulos na página de modelos"
        }), " para obter mais informações."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Parâmetros:"
        }), " opcionalmente, especifique informações adicionais do módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"unique_module_name\",\n  path=\"@hubspot/text\",\n  label=\"Single Text Line\",\n  value=\"This is a single text line\"\n%}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text widget-type-text\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"module\"\n  id=\"hs_cos_wrapper_text\"\n  style=\"\"\n>\n  <span\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"text\"\n    id=\"hs_cos_wrapper_text_\"\n    style=\"\"\n    >This is a single text line</span\n  >\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo acima é um módulo de texto com os parâmetros de rótulo e valor definidos. O rótulo define o texto de ajuda no editor e o valor define o texto padrão para aquele módulo. Confira o gif de amostra abaixo para ver como seria isso dentro do editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/module-label-value-min.gif",
        alt: "module-label-value-min"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/modules/using-modules-in-templates",
        children: "saber mais sobre o uso de módulos em modelos aqui"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis e macros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubL inclui muitas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables",
        children: "variáveis HubSpot predefinidas"
      }), " que imprimem valores úteis a partir do aplicativo. Além disso, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables-macros-syntax",
        children: "definir suas próprias variáveis em um modelo"
      }), ". No exemplo a seguir, uma variável chamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryColor"
      }), " é definida em uma instrução e depois impressa com uma expressão do HubL. Este exemplo combina a variável do HubL com CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set primaryColor = '#F7761F' %} {# defines variable and assigns HEX color #}\n\na {\n  color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As macros do HubL permitem imprimir vários comandos com um valor dinâmico. Esta técnica revela-se útil quando você escreve os mesmos blocos de código básico repetidas vezes, mas só precisa alterar certos valores. No exemplo a seguir, uma macro é usada para imprimir uma propriedade de transição CSS3 que inclui todos os prefixos dos fornecedores. Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables-macros-syntax#macros",
        children: "saber mais sobre macros aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n  {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtros e funções"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os filtros podem ser adicionados ao HubL para transformar ou alterar o valor de uma variável de modelo. Um exemplo simples apresentado abaixo é a formatação de uma variável de data. Os filtros utilizam um | (símbolo de pipeline) e são aplicados sem espaços a uma variável."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No exemplo abaixo, suponha que um post no blog foi publicado no dia 29 de abril. A data de publicação do post é formatada com o filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetimeformat"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/filters",
        children: "Você pode ver a lista completa de filtros aqui."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date_local_time|datetimeformat(\"%B %e, %Y\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "April 29, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Enquanto os filtros afetam a forma como as variáveis são renderizadas, as funções processam o valor e as informações da conta e as renderizam. Por exemplo, uma função pode ser usada para obter o número total de posts de um determinado blog ou para clarear/escurecer uma variável de cor em uma determinada quantidade."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O exemplo imprime o número total de posts de blog do site designers.hubspot.com/blog. Ele usa o parâmetro de ID do blog (disponível na URL do painel do blog) para especificar o blog que deve ser direcionado. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/functions",
        children: "Você pode ver a lista completa de funções aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "// blog_total_post_count\n{{ blog_total_post_count(359485112) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instruções If"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/if-statements",
        children: "As instruções If"
      }), " permitem que você use lógica condicional para ditar como seu modelo renderiza a lógica condicional nas instruções do HubL para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), ". Uma instrução deve começar com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " e terminar com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O exemplo abaixo define um módulo de escolha que permite ao usuário final selecionar um departamento a partir de um menu suspenso. Dependendo do que o usuário seleciona no editor, o modelo gerará um cabeçalho dinâmico para uma página de carreiras. Este exemplo requer o uso do parâmetro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/modules/export-to-template-context",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "export_to_template_context"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Loops For"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/for-loops",
        children: "Loops For"
      }), " permitem iterar itens armazenados em uma sequência. Eles são mais comumente usados ao renderizar o conteúdo do blog em formato de lista. Loops For começam com uma instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), " e terminam com uma instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endfor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No exemplo abaixo, um conjunto de tipos de ursos é armazenado como uma variável chamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bears"
      }), ". Um loop for é então usado para iterar os diferentes tipos de ursos, imprimindo um item de lista para cada tipo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bears = [\"panda bear\", \"polar bear\", \"black bear\", \"grizzly bear\", \"koala bear\"] %}\n\n<h1>Types of bears</h1>\n<ul>\n  {% for bear in bears %}\n  <li>{{ bear }}</li>\n  {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Types of bears</h1>\n<ul>\n  <li>panda bear</li>\n  <li>polar bear</li>\n  <li>black bear</li>\n  <li>grizzly bear</li>\n  <li>koala bear</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Outros recursos do HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo estão algumas outras características de modelos diversos que podem ser úteis, à medida que você desenvolve com o HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limpar os delimitadores do HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Muitas outras linguagens compartilham os mesmos delimitadores do HubL, o que podem criar problemas ao trabalhar em arquivos codificados no CMS. Se você quiser usar um delimitador do HubL para uma linguagem diferente, precisará envolver o código com:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: " {{\"Code you want to escape\"}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{\"Code you want to escape\"}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incluir aquivos em arquivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode incluir vários arquivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".html"
      }), " em um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/html-hubl-templates",
        children: "modelo HubL"
      }), " usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/html-hubl-templates#standard-partials",
        children: "tag de inclusão"
      }), ". Para criar um arquivo HTML que não requer as variáveis de modelo padrão, você deve desmarcar a opção \"Make template available for new content.\" A sintaxe é mostrada abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"custom/page/web_page_basic/my_footer.html\" %}\n{% include \"hubspot/styles/patches/recommended.css\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você também pode compilar vários arquivos CSS em um único arquivo CSS com a mesma tag de inclusão. Quando você publicar o arquivo principal, o arquivo secundário será compilado em um único arquivo CSS minificado com o código do principal."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blocos e extensões"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao criar modelos complexos, você pode usar blocos compartimentados que estendem um modelo principal."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Primeiro, você cria um modelo principal que inclui as variáveis ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), ". Dentro desse modelo, você precisa definir um bloco exclusivo usando a seguinte sintaxe, onde ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_sidebar"
      }), " é um nome exclusivo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"custom/page/web_page_basic/my_template.html\" %}\n{% block my_sidebar %}\n<h3>Sidebar title</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, você pode criar um arquivo HTML secundário que irá preencher esse bloco. Primeiro, você deve declarar uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/html-hubl-templates#blocks-and-extends",
        children: "instrução de extensão"
      }), " que faça referência ao caminho para o principal. Esse bloco de código é renderizado no modelo principal, mas mantido em outro arquivo menor e mais administrável. Essa técnica não serve para todos, mas pode ser útil para se manter organizado na codificação de modelos de página ou e-mail complexos. Ao usar esta técnica, você deve escolher o modelo secundário para criar o conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Copiar seção do HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No editor de páginas, você pode copiar a marcação do HubL para uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/drag-and-drop-areas/sections",
        children: "seção de arrastar e soltar"
      }), " a fim de reutilizar o código conforme necessário. Isso pode ser útil quando se deseja recriar uma seção de arrastar e soltar em um arquivo codificado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-section-hubl-menu.png",
        alt: "copy-section-hubl-menu"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#copy-section-hubl",
        children: "copiar a seção do HubL."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}