"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611091;
const slug = exports.slug = 'reference/cms/modules/configuration';
const title = exports.title = 'Configuring a module';
const name = exports.name = 'Configuring a module';
const metaDescription = exports.metaDescription = 'Learn how to a configure a custom module.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "adding-an-icon",
  "label": "Adding an Icon",
  "parent": null
}, {
  "depth": 0,
  "id": "changing-the-label",
  "label": "Changing the label",
  "parent": null
}, {
  "depth": 0,
  "id": "making-a-module-global",
  "label": "Making a module global",
  "parent": null
}, {
  "depth": 0,
  "id": "controlling-where-a-module-is-available-for-use",
  "label": "Controlling where a module is available for use",
  "parent": null
}, {
  "depth": 0,
  "id": "adding-css-and-javascript-dependencies",
  "label": "Adding CSS and JavaScript dependencies",
  "parent": null
}, {
  "depth": 0,
  "id": "adding-categories-and-tags",
  "label": "Adding categories and tags",
  "parent": null
}, {
  "depth": 1,
  "id": "categories",
  "label": "Categories",
  "parent": "adding-categories-and-tags"
}, {
  "depth": 1,
  "id": "tags",
  "label": "Tags",
  "parent": "adding-categories-and-tags"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      em: "em",
      ul: "ul",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configuring a module"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "When creating a module, there are a number of options available that impact where a module is visible, how it's identified, and how it can be edited."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["When ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "developing locally"
            }), ", modules are stored as folders with a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".module"
            }), " suffix. These folders contain the files that make up the module's settings and the code used to render it. A module's configuration is kept in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#meta-json",
              children: "meta.json"
            }), " file, while fields are configured separately in a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview#fields-json",
              children: "fields.json"
            }), " file."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Most configuration can also be modified using the module editor in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/design-manager",
              children: "Design Manager"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/module%20structure.png",
            alt: "Module folder structure displayed locally"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["At a high level, you configure module options locally within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " file, which can include the following properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL to an image to use as the icon for a module."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Label used when modules are shown in the content editors"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unique id for the module that is independent from the path."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The value for the toggle in the top right corner of the module editor in HubSpot. Determines if the module can be used in content."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "global"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indicates whether the module is global or not"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " of ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "content types"
            }), " that the module can be used within. One or more of:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ANY"
                }), ": any of the types listed below."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LANDING_PAGE"
                }), ": landing pages."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SITE_PAGE"
                }), ": website pages and templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BLOG_POST"
                }), ": blog posts and templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BLOG_LISTING"
                }), ": blog listing templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EMAIL"
                }), ": emails and email templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "KNOWLEDGE_BASE"
                }), ": knowledge base pages and templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "QUOTE_TEMPLATE"
                }), ": quote templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CUSTOMER_PORTAL"
                }), ": customer portal templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "WEB_INTERACTIVE"
                }), ": web interactives."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SUBSCRIPTION"
                }), ": subscription templates."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MEMBERSHIP"
                }), ": membership templates."]
              })]
            }), "If a module is not to be used in any area within HubSpot, you would set the value to an empty array ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            }), " instead of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"NONE\"]"
            }), ".Note that this field was previously named ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "host_template_types"
            }), ". Modules using the previous field name will continue to function, but it's recommended to use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_types"
            }), " moving forward."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " of CSS files that the module depends on. Supports relative paths.e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"css_assets\": [{ \"path\": \"../path/to/file.css\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set whether the module CSS renders asynchronously with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "async"
            }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"async\": false}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " of JavaScript files that the module depends on. Supports relative paths.e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"js_assets\": [{ \"path\": \"../path/to/file.js\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modifies the module JavaScript tag added to the rendered page. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "position"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "head"
                }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "footer"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "async"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "defer"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "type"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "string"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"position\":\"footer\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inline_help_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Help text that will be shown at the top of the module in a blue info box (limit 400 characters).Provides information necessary to use the module. If you have field-specific help text information to convey, refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#properties-used-by-all-fields",
              children: "help text field documentation"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "master_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["With ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-translations-of-your-modules#:~:text=To%20create%20translations%20of%20a,the%20right%2C%20click%20Add%20translations.",
              children: "translations enabled"
            }), ", the language code of the language the module's fields were originally written in.e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "en"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
              children: "placeholder content"
            }), " for the module. Includes the following properties:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_module_icon"
                }), ": whether the module icon displays. ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": the title that appears on the module in the editor. String."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": the description that appears on the module in the editor. String."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "categories"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing up to three ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-categories",
              children: "module categories"
            }), ".For example: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"categories\":[\"FORMS_AND_BUTTONS\"]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array of ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#adding-categories-and-tags",
              children: "module tag objects"
            }), " containing the tag name and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "source"
            }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"USER\""
            }), ".For example: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"content_tags\": [{"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\" : \"BUTTONS\","
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"source\" : \"USER\"``}]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about individual module configuration options."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adding an Icon"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules can include an icon that appears in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Design Manager"
      }), " and the page and email editors to provide visual context for content creators. It's recommended to have different icons for the different types of modules in your theme. Icons are ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "required for marketplace providers"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are two ways to add an icon, through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-design-manager",
        children: "design manager"
      }), " or the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-cli",
        children: "CMS CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module icons must be an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".svg"
      }), " file and no larger in size than 10kb. For best results your icon should be simple and use only one color. Icons that use more than one color will be automatically converted for you. The default module icon that displays is a wrench and paint brush icon."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add an icon using the design manager:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Files and Templates"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design Tools"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Use the left sidebar to locate the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module"
              }), " you want to edit, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module name"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In the right sidebar of the module editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "icon"
              }), " next to the module title."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Upload/select your icon. After publishing your changes, the icon will appear within the page editors and design manager."
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-icon-crop.png",
            alt: "edit-module-icon-crop"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add an icon when developing locally, open the module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " file and add or edit the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "icon"
      }), " parameter's value to be an SVG from the file manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"global\": false,\n  \"content_types\": [\"PAGE\"],\n  \"icon\": \"http://example.com/hubfs/code.svg\",\n  \"label\": \"Code block\",\n  \"smart_type\": \"NOT_SMART\",\n  \"is_available_for_new_content\": true,\n  \"inline_help_text\": \"This module is for code snippets.\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Changing the label"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "The label used when modules are displayed in the editor can be adjusted separately from the name for the module. This is useful when developing locally as you can have a name that makes sense to you while having a different one content creators see."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Locally you change the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " parameter to set the label. In the design manager there's a field in the module editor below the module name."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-label.png",
            alt: "edit-module-label"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Making a module global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For normal modules, the content of each instance of a module in a page, email or template is independent. For some use cases, it is useful to be able to have all instances of a module share the same content. When developing locally, you can make a module global by setting ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/use-global-content-across-multiple-templates#create-new-global-content-in-the-design-manager:~:text=To%20convert%20an%20existing%20local%20module%20or%20group",
        children: "convert modules in a drag-and-drop template to global"
      }), " using the design manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Controlling where a module is available for use"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing locally, you can control which types of content a module can be used in through the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hostTemplateTypes"
      }), " property. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "available template types"
      }), ". Modules also can be hidden so that they can't be added directly to pages through setting ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_available_for_new_content"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". For example, this can be helpful for modules built for navigation menus and search."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update this in the design manager by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Template type"
      }), " option in the right sidebar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-template-type.png",
        alt: "edit-module-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adding CSS and JavaScript dependencies"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " to add CSS and JavaScript that will be added to all pages that include a module instance, dependencies that are shared between modules can be attached using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "js_assets"
      }), ". Paths can be absolute or relative to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"css_assets\": [{ \"path\": \"../path/to/file.css\" }],\n  \"js_assets\": [{ \"path\": \"../path/to/file.js\" }]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Warning:"
        }), " When using relative paths to reference dependencies, running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch --overwrite"
        }), " to update the module for local development will overwrite the relative paths with absolute paths."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the design manager, you can link CSS and JavaScript files to a module using the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Linked files"
      }), " section in the right sidebar of the module editor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-linked-files.png",
        alt: "edit-module-linked-files"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adding categories and tags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can assign categories and tags to modules to organize them within HubSpot tools:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#categories",
          children: "Categories"
        }), ": assign categories to a modules to organize them into groups within the content editor UI. This enables content creators to find modules more easily while building content in HubSpot. Note the following about categories:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A module can have up to three categories, which are pre-defined and cannot be customized."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Currently, categories are not surfaced in the content editor UI. However, you can assign categories for when categorization is available in editors."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Uncategorized modules will be available under an ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "All"
            }), " category."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#tags",
          children: "Tags"
        }), ": assign tags to organize modules within the design manager. This enables you to find modules more easily while building templates."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the design manager, you can assign categories and tags using the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Filter tags"
      }), " section of the right sidebar in the module editor. Learn more about the available categories and tags below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-filter-tags.png",
        alt: "edit-module-filter-tags"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Locally, you can add categories and tags to a module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " file as follows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"label\": \"Custom module\",\n  \"categories\": [\"image_and_video\", \"commerce\"],\n  \"content_tags\": [\n    {\n      \"name\": \"BUTTONS\",\n      \"source\": \"USER\"\n    },\n    {\n      \"name\": \"CTA\",\n      \"source\": \"USER\"\n    }\n  ],\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Categories"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "categories"
      }), " array can contain up to three of the following categories (case-insensitive):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Category"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog-specific modules, such as a recent post listing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules that are formatted to graphically showcase content, such as an image gallery."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Commerce-specific modules, such as pricing cards."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "design"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules that affect content structure and layout, such as accordions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "functionality"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules that include dynamic responses or behavior on the page, such as menus."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms_and_buttons"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules that allow site visitors to input and submit data."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules that contain elements such as images, icons, video, and banners."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Social media-specific modules, such as social sharing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules that contain only text."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content_tags"
      }), " array can contain any of the following module tag objects (case sensitive):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Content types:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ACCORDION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ANIMATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG_POST"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BUTTONS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CODE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CTA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FEED"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FORM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ICON"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "IMAGE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LISTS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LOGO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MENU"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "RICH_TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SLIDER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "VIDEO"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Functions:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BANNER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BRANDING"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CALCULATOR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CONVERSION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "EMAIL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "GALLERY"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "HEADERS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "INTERACTION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LAYOUT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MAP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MULTIMEDIA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "NAVIGATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROGRESS_BAR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SEARCH"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SETTINGS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SOCIAL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TRANSLATION"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}