"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310858;
const slug = exports.slug = 'guides/cms/tools/personal-access-key';
const title = exports.title = 'パーソナルCMSアクセスキー';
const name = exports.name = 'APAC JAPAN (ja) | [New] Personal CMS Access Keys_JA | 202108';
const metaDescription = exports.metaDescription = 'パーソナルCMSアクセスキーは、HubSpot上で開発作業を行う開発者による利用を目的とした認証形式です。これはアカウント内の特定のユーザーに結び付けられています。';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h3: "h3",
      h4: "h4",
      code: "code",
      img: "img",
      a: "a",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "パーソナル アクセス キー"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**パーソナル アクセス キーは、ローカル開発ツールでの認証方法として推奨されています。**パーソナル アクセス キーの働きはAPIキーと似ていますが、アカウント内の特定のユーザーに結び付けられています。パーソナル アクセス キーはローカル開発ツールでのみ動作します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "パーソナル アクセス キーと他の認証方式との比較"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "APIキーなどの実装と比較したパーソナル アクセス キーのメリットを考える場合、APIキーは実質的にスーパー管理者の権限が付与されています。パーソナル アクセス キーは、ポータル内の個々のユーザーの権限に限定されています。ユーザーにスーパー管理者の権限が付与されている場合は、機能的な違いは感じられません。しかし、個々の開発者をアカウントから削除することが必要になった場合、アカウント側でユーザーを無効にすると、該当するユーザーのローカル開発機能が無効になるという利点があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーソナル アクセス キーはアカウント内の個々のユーザーに結び付けられているため、分かりやすく情報を表示できます。例えば、パーソナル アクセス キーを使用していて、開発者がローカル開発ツール経由でファイルを変更またはアップロードした場合、アプリ内の変更をこのユーザーに結び付けることができます。これにより、チームで作業しやすくなり、誰が何をしたかを容易に把握できるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーソナル アクセス キーは、ユーザーと直接ではなく、特定のHubSpotアカウント内の個々のユーザーに結び付けられています。つまり、ローカル開発ツールを使用する際には、開発ツールを使用するアカウントごとに新しいパーソナル アクセス キーを生成する必要があります。これはアカウントのセキュリティーレイヤーとして機能し、仮に悪意のある人物がアクセスキーを入手しても、影響の範囲は特定のポータルの特定ユーザーに限定されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "OAuth2との類似点"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["背後でパーソナル アクセス キーは、OAuth2のように機能します。パーソナル アクセス キーを生成する際には、そのキーに付与する権限を選択します。1つのHubSpotアカウントあたり、1ユーザーについて1つのアクセスキーのみ保持できます。アクセスキーを生成したら、「HubSpot Local Development Tools」（HubSpotローカル開発ツール）という名前のHubSpotアカウントにアプリが接続されます。このファーストパーティーHubSpotアプリは、パーソナル アクセス キーを使用する際のローカル開発ツールの認証に役立ちます。このアプリを切断すると、作成済みの全てのアクセスキーが即座に削除され、ローカル開発ツールはこのアクセスキーを使用して接続することができなくなります。新しいキーを生成して、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), "ファイルを更新することが必要になります。"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/personalcmsaccesskey.png",
            alt: "personalcmsaccesskey"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "資格情報を保護する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーソナル アクセス キーは、アカウントパスワードと同じように保護してください。他の人とは共有しないでください。入手した人物が本来のユーザーに成りすまして、自由に操作できてしまいます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ローカル開発ツールでのパーソナル アクセス キーの使用"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーソナル アクセス キーは、ローカル開発ツールで使用することを意図して導入されました。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "ローカル開発ツールの利用を開始する"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://app.hubspot.com/l/personal-access-key",
        children: "パーソナルCMSアクセスキーを表示する。"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカル開発ツールの認証に使用する場合の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "ファイルの例を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: production\nportals:\n  - name: production\n    portalId: <portalId>\n    authType: personalaccesskey\n    personalAccessKey: >-\n      CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}