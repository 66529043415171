"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42761605798;
const slug = exports.slug = 'guides/apps/authentication/intro-to-auth';
const title = exports.title = 'HubSpot-APIs | Authentifizierungsmethoden in HubSpot';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Authentication methods on HubSpot';
const metaDescription = exports.metaDescription = 'HubSpot-APIs bieten zwei Wege der Authentifizierung: OAuth und API-Schlüssel. Obwohl die meisten Endpunkte beide Methoden unterstützen, wird in der Regel OAuth empfohlen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth",
  "label": "OAuth",
  "parent": null
}, {
  "depth": 0,
  "id": "zugriffstoken-von-privaten-apps",
  "label": "Zugriffstoken von privaten Apps",
  "parent": null
}, {
  "depth": 2,
  "id": "verwandte-dokumente",
  "label": "Verwandte Dokumente",
  "parent": "zugriffstoken-von-privaten-apps"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h1: "h1",
      h2: "h2",
      pre: "pre",
      code: "code",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " seit dem 30. November 2022 sind HubSpot-API-Schlüssel veraltet und werden nicht mehr unterstützt. Die fortgesetzte Verwendung von HubSpot API-Schlüsseln stellt ein Sicherheitsrisiko für Ihren Account und Ihre Daten dar. Während dieser Phase bis zur endgültigen Einstellung kann HubSpot Ihren Schlüssel jederzdeaktivieren."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Sie sollten sich stattdessen mit einem Zugriffstoken für private Apps oder über OAuth authentifizieren. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "diese Änderung"
        }), " und wie Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "API-Schlüssel-Integration migrieren"
        }), ", um stattdessen eine private App zu verwenden."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Authentifizierungsmethoden in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt zwei Möglichkeiten, Aufrufe der APIs von HubSpot zu authentifizieren: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " und Zugriffstoken für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private Apps"
      }), ". Im Folgenden erfahren Sie mehr über die einzelnen Methoden und wie Sie sie in Ihrem Code für die Autorisierung berücksichtigen können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie zuvor einen API-Schlüssel zur Authentifizierung verwendet haben, erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
        children: "hier"
      }), ", wie Sie stattdessen zur Verwendung von Zugriffstoken für private Apps migrieren."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Integrationen, die für die Verwendung von mehreren Kunden oder als Listing im App Marketplace gedacht sind, müssen als App erstellt werden, die das OAuth-Protokoll von HubSpot verwendet."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Anfrage mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), " durchzuführen, fügen Sie das OAuth-Zugriffstoken in den Autorisierungsheader ein:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer C4d***sVq\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zugriffstoken von privaten Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um eine Anfrage über das Zugriffstoken einer privaten App durchzuführen, fügen Sie ähnlich wie bei OAuth das Token in den Autorisierungsheader ein:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer ***-***-*********-****-****-****-************\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Arbeiten mit OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "OAuth-Schnellanleitung"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "Private Apps"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}