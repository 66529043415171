"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45757406737;
const slug = exports.slug = 'guides/cms/content/themes/quotes-themes';
const title = exports.title = 'Getting started from the CMS quotes theme';
const name = exports.name = '[developers] Getting started from the CMS quotes theme';
const metaDescription = exports.metaDescription = 'Create a custom quote Theme for your business based on the HubSpot provided CMS Quote Theme. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-fetching-the-theme-to-your-local-directory",
  "label": "1. Fetching the theme to your local directory",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-upload-and-watch-changes",
  "label": "2. Upload and watch changes",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-open-a-template-preview",
  "label": "3. Open a template preview",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-make-a-change-locally",
  "label": "4. Make a change locally",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-change-the-template-label",
  "label": "5. Change the template label",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-customize-the-quote-template-in-hubspot",
  "label": "6. Customize the quote template in HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-create-a-quote-using-your-new-template",
  "label": "7. Create a quote using your new template",
  "parent": null
}, {
  "depth": 0,
  "id": "next-steps",
  "label": "Next steps",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related Resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      code: "code",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Getting started from the CMS quotes theme"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With a CMS quotes theme, you can create a custom quote theme for sales reps to use during the buying process. This guide will walk you through cloning the default CMS quotes theme locally using the CLI, uploading the clone to your account, then making adjustments as needed. You'll then create a quote using the template to view your work."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You should feel confident writing HTML and CSS."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You should have the latest version of the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI installed and configured for your portal"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " while this tutorial uses the HubSpot CLI, you can do all of this in HubSpot using the design manager if preferred. To complete this process in HubSpot, you'll just need to clone the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cms-quotes-theme"
        }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot"
        }), " folder instead of running the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        }), " command. shown in step 1."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Fetching the theme to your local directory"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Open your terminal, navigate to the directory you want to download the files to. This will be your main working directory for the remainder of this tutorial."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To download the default quotes theme, run the following in your terminal:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch @hubspot/cms-quotes-theme \"my-quotes-theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You should now see a folder named ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " in your local file system. This folder contains all of the assets needed for the quote theme, including mock data and module defaults within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "imports"
      }), " folder."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Upload and watch changes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the folder downloaded, upload it to HubSpot. While you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "hs upload"
      }), " command to perform a single upload, you can instead use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " command to trigger automatic uploads on each file save:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch \"my-quotes-theme\" \"my-quotes-theme\" --initial-upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After upload, you can now view the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " folder in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: "design manager"
      }), ". To open the design manager from the terminal, open a new terminal tab or window and run the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs open dm"
      }), " command."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A new terminal tab or window is needed because you cannot run commands while the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), " process is running. You can also press ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), " to end the watch, run another command, then rerun ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Open a template preview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To preview the quote template:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the design manager, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right of the code editor, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Live preview with display options"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the template preview open, you'll then make a change locally, which will be automatically uploaded on save due to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), " running."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Make a change locally"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your local code editor, open ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > css > bold.css."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add the code below to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "bold.css"
        }), ", then save your changes:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".line-items__table tbody tr:nth-child(odd) {\n  background-color: #d6d6d6;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Refresh the template preview in your browser to view your CSS changes. You should now see that every odd row in the table body has a gray background."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As you build your custom quote theme, you can repeat this workflow to quickly confirm the changes that you're making locally."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " because of the complexity of the signature system, signatures will not display in previews."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Change the template label"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As you prepare a custom quotes theme for real life use, you should be mindful of the template label so that sales reps can easily find it among HubSpot's default quote options."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To change a quote template's label:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your code editor, open ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "At the top of the file, view the template annotation:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: quote\n isAvailableForNewContent: true\n label: Bold\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Update the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " parameter from ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bold"
        }), " to a name of your choosing, such as ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "My custom quote template"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Save the file to upload it to HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Customize the quote template in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before a sales rep can use your quote template, it must be customized in HubSpot. This would typically be done by a sales manager so that they can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/create-custom-quote-templates",
        children: "create ready-made quotes for their sales team"
      }), ". However, in this tutorial, you'll walk through this process yourself so that you can understand what the content creation experience is like."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To customize the quote template and make it available for sales reps:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the settings ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objects > Quotes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quote templates"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Customize quote template."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hover over your new ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "template"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Choose"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the left panel, you can edit the modules included in the template. For example, you can click a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "module"
        }), " to edit its properties or toggle visibility."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/quote-template-toggle-visibility.png",
        alt: "quote-template-toggle-visibility"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), " when you're done making changes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Create a quote using your new template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your changes saved, you can now create a quote with the template, simulating the sales rep experience."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales > Quotes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create quote"
        }), ". You'll then be redirected to a quote creation wizard."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the first screen, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Associate with a deal"
        }), " dropdown menu, then either select an existing deal or select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create a new deal"
        }), " if you want to use a test deal instead."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the bottom right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the next screen, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quote"
        }), " dropdown menu, then select your custom quote template."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Proceed through the rest of the quote wizard to create your quote."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After publishing the quote, a dialog box will appear with a link to view the quote. Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy"
        }), " to copy the URL, then paste it into your browser to view the completed quote."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-quote-url.png",
        alt: "copy-quote-url"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Next steps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your quote template created, uploaded, and customized, you should now have a better understanding of the quote template editing process and the content creator experience."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As you create quote templates to fit your business needs, you may want to try adding your own custom modules to the quote along with HubSpot's default modules."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " it's recommended to ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " edit the JavaScript of the payment, signature, and download modules, as this could lead to breaking the modules. If broken, the end-user might not be able to sign it, download it, or even make a payment."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related Resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Custom quote templates"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/quote-variables",
          children: "Custom quote variable reference"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/create-custom-quote-templates-beta",
          children: "Create and use custom quote templates (from the sales, sales ops/manager perspective)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}