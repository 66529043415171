"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501378077;
const slug = exports.slug = 'guides/api/conversations/working-with-chatbots/webhooks';
const title = exports.title = 'Conversations | Webhooks dans les bots';
const name = exports.name = 'vNext Docs DP - Working with webhooks from bots_FR';
const metaDescription = exports.metaDescription = "Profitez d'informations relatives aux demandes utilisées dans les étapes de webhook pour les bots.";
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h5: "h5",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Utilisation de webhooks depuis des bots"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/articles/kcs_article/conversations/create-a-bot",
        children: "création ou de la modification d'un bot"
      }), ", vous pouvez ajouter un webhook en cliquant sur le bouton + pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/articles/kcs_article/conversations/a-guide-to-bot-actions",
        children: "ajouter une action,"
      }), " comme vous le feriez habituellement. Dans le panneau de sélection de l'action, cliquez sur Déclencher un webhook.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/trigger-a-webhook.png?width=334&name=trigger-a-webhook.png",
        alt: "trigger-a-webhook"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensuite, donnez un nom interne à votre action et saisissez l'URL de point de terminaison pour le webhook. Si votre webhook enverra des données à HubSpot en réponse à la demande, sélectionnez la case à cocher Attendre le feedback sur le webhook (découvrez-en davantage ci-dessous). Enregistrez votre action. ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hub/2832391/hubfs/Settings/Website/Blog/trigger-a-webhook-editor.png?width=469&name=trigger-a-webhook-editor.png",
        alt: "trigger-a-webhook-editor"
      }), "Lorsque cette action est atteinte dans une conversation, HubSpot enverra une charge utile JSON à l'", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "URL du webhook"
      }), " que vous avez définie. La charge utile contient des informations pertinentes pour la session de chat, y compris les réponses des visiteurs pour toute question posée, leur ID de contact ainsi que des informations sur le bot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemple de charge utile de demande :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//sample payload\n{\n  \"userMessage\": {\n  // Details for the last message sent to your bot\n    \"message\": \"100-500\",\n    // The last message received by your bot, sent by the visitor\n    \"quickReply\": {\n    // If the visitor selected any quick reply options, this will be a list of the selected options.\n    // Will be 'null' if no options were selected.\n      \"quickReplies\":[\n      // A list of quick reply options selected by the visitor\n         {\n            \"value\":\"100-500\",\n            \"label\":\"100-500\"\n         }\n      ],\n  },\n  \"session\": {\n    \"vid\": 12345,\n    // The contact VID of the visitor, if known.\n    \"properties\": {\n    // A list of properties collected by the bot in the current session.\n      \"CONTACT\": {\n        \"firstname\": {\n          \"value\": \"John\",\n          \"syncedAt\": 1534362540592\n        },\n        \"email\": {\n          \"value\": \"testing@domain.com\",\n          \"syncedAt\": 1534362541764\n        },\n        \"lastname\": {\n          \"value\": \"Smith\",\n          \"syncedAt\": 1534362540592\n        }\n      }\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les utilisateurs avancés peuvent également inclure JSON dans la réponse de votre webhook. Vous pouvez ainsi affecter le flux de la conversation ou envoyer un message personnalisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemple de charge utile de réponse :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//sample payload\n{\n  \"botMessage\": null,\n  // This is the message your bot will display to the visitor.\n  \"nextModuleNickname\": \"PromptForCollectUserInput\",\n  // If defined, this will be the next module your bot will go to. If undefined, the default configured behavior will be observed.\n  \"responseExpected\": false\n  // If true, the webhook will be triggered again with the visitor's next reply. If false, the default configured behavior will be observed.\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}