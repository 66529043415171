"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52096466548;
const slug = exports.slug = 'guides/cms/content/performance/prerendering';
const title = exports.title = 'プリレンダリング';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] Prerendering_JA | 202109';
const metaDescription = exports.metaDescription = 'プリレンダリング（事前レンダリング）とは、HubSpotのCMS上でウェブサイトページ、ランディングページ、ブログ記事、ナレッジベース記事の静的バージョンを作成する処理です。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%A8%E3%81%AF%EF%BC%9F",
  "label": "プリレンダリングとは？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%A9%E3%81%AE%E3%82%88%E3%81%86%E3%81%AA%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88%E3%81%8C%E3%81%82%E3%82%8A%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "どのようなメリットがありますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AF%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E3%81%A8%E5%90%8C%E3%81%98%E5%87%A6%E7%90%86%E3%81%A7%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "プリレンダリングはキャッシュと同じ処理ですか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%8C%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B%E3%81%A9%E3%81%86%E3%81%8B%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%EF%BC%9F",
  "label": "ページがプリレンダリングされているかどうかを確認するには？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E5%86%8D%E5%BA%A6%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%EF%BC%9F",
  "label": "ページを再度プリレンダリングするには？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AB%E3%81%8B%E3%81%8B%E3%82%8B%E6%99%82%E9%96%93%E3%81%AF%EF%BC%9F",
  "label": "ページのプリレンダリングにかかる時間は？",
  "parent": null
}, {
  "depth": 0,
  "id": "css%E3%80%81javascript%E3%80%81%E7%94%BB%E5%83%8F%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E5%87%A6%E7%90%86%E3%81%AF%EF%BC%9F",
  "label": "CSS、JavaScript、画像ファイルの処理は？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%8F%BE%E6%99%82%E7%82%B9%E3%81%A7%E5%AE%8C%E5%85%A8%E3%81%AA%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AB%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%81%AA%E3%81%84%E6%A9%9F%E8%83%BD%E3%81%AF%EF%BC%9F",
  "label": "現時点で完全なプリレンダリングに対応していない機能は？",
  "parent": null
}, {
  "depth": 1,
  "id": "hubl%E5%A4%89%E6%95%B0",
  "label": "HubL変数",
  "parent": "%E7%8F%BE%E6%99%82%E7%82%B9%E3%81%A7%E5%AE%8C%E5%85%A8%E3%81%AA%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AB%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%81%AA%E3%81%84%E6%A9%9F%E8%83%BD%E3%81%AF%EF%BC%9F"
}, {
  "depth": 1,
  "id": "hubl%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC",
  "label": "HubLフィルター",
  "parent": "%E7%8F%BE%E6%99%82%E7%82%B9%E3%81%A7%E5%AE%8C%E5%85%A8%E3%81%AA%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AB%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%81%AA%E3%81%84%E6%A9%9F%E8%83%BD%E3%81%AF%EF%BC%9F"
}, {
  "depth": 1,
  "id": "hubl%E9%96%A2%E6%95%B0",
  "label": "HubL関数",
  "parent": "%E7%8F%BE%E6%99%82%E7%82%B9%E3%81%A7%E5%AE%8C%E5%85%A8%E3%81%AA%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AB%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%81%AA%E3%81%84%E6%A9%9F%E8%83%BD%E3%81%AF%EF%BC%9F"
}, {
  "depth": 1,
  "id": "%E6%A9%9F%E8%83%BD",
  "label": "機能",
  "parent": "%E7%8F%BE%E6%99%82%E7%82%B9%E3%81%A7%E5%AE%8C%E5%85%A8%E3%81%AA%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AB%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%81%AA%E3%81%84%E6%A9%9F%E8%83%BD%E3%81%AF%EF%BC%9F"
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E5%AF%BE%E5%BF%9C%E3%81%AE%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95%E3%81%AF%EF%BC%9F",
  "label": "プリレンダリング対応のページを作成する方法は？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%A8%E3%81%AF%EF%BC%9F",
  "label": "パーシャルプリレンダリングとは？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%8C%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B%E3%81%A9%E3%81%86%E3%81%8B%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%EF%BC%9F",
  "label": "ページがパーシャルプリレンダリングを使用しているかどうかを確認するには？",
  "parent": null
}, {
  "depth": 1,
  "id": "hubl%E5%A4%89%E6%95%B0",
  "label": "HubL変数",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%8C%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B%E3%81%A9%E3%81%86%E3%81%8B%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%EF%BC%9F"
}, {
  "depth": 1,
  "id": "hubl%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC",
  "label": "HubLフィルター",
  "parent": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%8C%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B%E3%81%A9%E3%81%86%E3%81%8B%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%EF%BC%9F"
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AA%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%AFcss%E3%81%AE%E7%B5%90%E5%90%88%E3%81%AB%E3%81%A9%E3%81%AE%E3%82%88%E3%81%86%E3%81%AB%E5%BD%B1%E9%9F%BF%E3%81%97%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "プリレンダリングはCSSの結合にどのように影響しますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A7%E3%81%AEcss%E8%87%AA%E5%8B%95%E7%B5%90%E5%90%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%9B%E3%81%9A%E3%81%ABcss%E3%81%AE%E9%85%8D%E4%BF%A1%E3%82%92%E6%9C%80%E9%81%A9%E5%8C%96%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%EF%BC%9F",
  "label": "ページでのCSS自動結合を使用せずにCSSの配信を最適化するには？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "プリレンダリング"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プリレンダリングとは？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プリレンダリング（事前レンダリング）とは、HubSpotのCMS上でウェブサイトページ、ランディングページ、ブログ記事、ナレッジベース記事の静的バージョンを作成する処理です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このコンセプトは、Gatsby、WP Super Cacheなどの静的サイトジェネレーターに似ています。HubSpotは、ページがプリレンダリングに対応できるかどうかを自動的に判定します。対応している場合は、静的バージョンが生成されます。そうでない場合には、ページが引き続き動的に提供されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "どのようなメリットがありますか？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエストごとにページのデータとレイアウトを動的に取り込む代わりに、CMSによって1つのコピーがレンダリングされ、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/cdn",
        children: "CDN"
      }), "にプッシュされます。これにより、世界中の訪問者に対してサイトの速度が向上します。HubSpotのCDNは、静的ファイルを処理する際に大量のトラフィックを処理できます。静的ページは、セキュリティーの面でも優れています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プリレンダリングはキャッシュと同じ処理ですか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "いいえ、異なる概念です。プリレンダリングされた全てのページは、HubSpotのCDN上にキャッシュ処理可能です。プリレンダリングは、HubSpotのCMSがページの静的バージョンを生成するプロセスです。CDNのキャッシュにページがない場合でも、ページがすでに最終的なレンダリングの形になっているため、リクエストの処理にかかる時間が短くなります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページがプリレンダリングされているかどうかを確認するには？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページがプリレンダリングされているかどうかを確認する最も簡単な方法は、リクエストのHTTPレスポンスヘッダーを確認することです。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HS-Prerendered"
      }), "ヘッダーが含まれている場合、ページはプリレンダリング済みです。このヘッダーの値は、ページが最後にプリレンダリングされた時刻です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/chrome-network-tab-prerendered.png",
        alt: "プリレンダリング済みのヘッダーを示すChromeの［Network］タブ"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 7,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["もう1つは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?hsDebugOnly=true"
            }), "クエリーパラメーターを使用してページを読み込む方法です。この場合、ページがプリレンダリング可能かどうかも示されます。プリレンダリングできない場合には、妨げとなっている問題のリストが表示されます。デバッグ情報の形式が読みづらい場合は、代わりにパラメーター", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?hsDebug=true"
            }), "を使用してページを調べることができます。同じデバッグ情報が、ページ下部に整形されたHTMLコメントとして表示されます。"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/hsDebugTrue-prerender.png",
            alt: "ページがプリレンダリング可能なことを示すHTMLコメントのスクリーンショット"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページを再度プリレンダリングするには？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページに影響するデータをHubSpot上で変更すると、レンダラーが自動的にトリガーされます。例えば、ページまたはそのテンプレートを編集すると、ページが再レンダリングされます。その他の共有データ（CMS設定など）を変更すると、多数のページが再レンダリングされることがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページのプリレンダリングにかかる時間は？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "通常は数秒程度です。多くのテンプレートに含まれていて多くのページで使用されているモジュールなどに大規模な変更を加えた場合は、全てのページが更新されるまでに数分間かかることがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSS、JavaScript、画像ファイルの処理は？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotではCSS、JavaScript、画像ファイルは常に静的に生成されるので、実質的には常にプリレンダリングされます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["つまり、JSまたはCSSファイルのHubLの評価は、「", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アセットが公開されるとき"
        }), "」に一度だけ行われます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "現時点で完全なプリレンダリングに対応していない機能は？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらの機能を使用すると、全てのユーザーに対して同一のレスポンスを提供できません。このため、静的にプリレンダリングされたページが提供されなくなります。これらの機能を使用しているページを提供する際には、パーシャルプリレンダリングが使用される場合があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubL変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "account"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "local_dt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "owner"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.cookies"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.full_url"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "request.geoip*"
        }), "（非推奨）"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.headers"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.path_and_query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.query_dict"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.referrer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.remote_ip"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "year"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubLのrequest変数には通常、対応するJavaScriptベースの代替手段があります。これらの変数を回避することも可能です。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubLフィルター"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "|random"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "|shuffle"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "ページごとに一連のデータをランダム化する必要がある場合は、代わりにJavaScriptを使用したランダム化を検討してください。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubL関数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "personalization_token"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "unixtimestamp"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "機能"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページのパスワード保護"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページのメンバーシップ制限"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スマートコンテンツを含むページ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "適応型テストを使用しているページ（A/Bテストページはサポートされます）"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ナレッジベースのページ"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プリレンダリング対応のページを作成する方法は？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebugOnly=true"
      }), "クエリーパラメーターを使用してページを読み込むと、プリレンダリングの妨げとなる特定の機能を確認する上で役立つ出力が追加されます。この追加の出力には次の情報が含まれています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "問題の原因となる特定の機能のリスト"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "特定のファイルとキャッシュ不可能な変数を使用しているテンプレートの行番号"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "可能な場合、コンテンツに関する全ての動的レンダリングを、ユーザーのブラウザー側で実行されるJavaScriptに移動します。動的データの取り込みには、サーバーレス関数も使用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "パーシャルプリレンダリングとは？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーシャルプリレンダリングでは、「ほぼ」プリレンダリングされたページを提供できます。例えば、ページに表示されるコンタクトの名前を除いて、ほぼ全体が静的なページがあるとします。このページの場合、コンタクト名以外の部分をプリレンダリングできます。ページをユーザーに戻す直前に、このような動的な値がHubSpotによって迅速にレンダリングされます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーシャルプリレンダリングを使用したページは、CDNまたはブラウザーにはキャッシュできませんが、パーシャルプリレンダリングできないページよりも迅速な配信が可能です。パーシャルプリレンダリングされたページは、停止や攻撃が発生した際にはパーソナライズなしの状態で提供することも可能です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーシャルプリレンダリングもサイトの速度と信頼性の向上に役立つ場合がありますが、ページのプリレンダリングの妨げになっているHubL機能を削除する方が、ページ全体のパフォーマンスが大きく向上します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページがパーシャルプリレンダリングを使用しているかどうかを確認するには？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsPrcDebug=true"
      }), "クエリーパラメーターを使用してページを読み込むと、ページ上のプリレンダリングされたコンテンツに関する出力が追加されます。ページがプリレンダリングされている場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HS-Prerendered"
      }), "ヘッダーが存在し、ページのパーシャルプリレンダリング時刻の前に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "partial"
      }), "が示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**パーシャルプリレンダリングで「現在サポートされている」機能を以下に示します。**ページはパーシャルプリレンダリングされ、提供時には以下の機能を使用する式が評価されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubL変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "account"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "local_dt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "owner"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "query"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "year"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubLフィルター"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "|random"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "|shuffle"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プリレンダリングはCSSの結合にどのように影響しますか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページプリレンダリングの導入に伴い、ページでのCSS自動結合は段階的に廃止される予定です。次のような理由があるためです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CSS結合では1つのページビューを最適化できますが、ページごとにCSSを全て再ダウンロードする必要があるため、後続のページビューへの影響が望ましくありません。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HTTP/2（SSL経由で配信される全てのHubSpotサイトではHTTP/2が使用されます）により、多数のCSSファイルに伴う", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/http2#request_and_response_multiplexing",
          children: "多くの問題が解決"
        }), "されるため、CSS結合には従来ほどのメリットがありません。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "結合されたCSSによってページが破損しないことを確認するために、HubSpotでは結合されたCSSがあるページに対して自動ビジュアルチェックを実行しています。ビジュアルチェッカーの効果は100％ではなく（スクロール時のみの表示要素やJavaScriptアニメーションなど）、問題を検出できない場合もあります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サイトの可用性と速度の観点から、ページのプリレンダリングはCSSの結合よりも重要です。レンダラーの後には結合されたCSSを常に検証する必要があるため、変更のたびにCSSを結合してビジュアルチェックを実行することは現実的ではありません。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "これは、ページごとにモジュールとページスタイルを1つのファイルとして結合していた従来の機能を指しています。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "これは、includeタグを使用して結合されたCSSファイルには影響しません。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページでのCSS自動結合を使用せずにCSSの配信を最適化するには？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "モジュールを活用しましょう。モジュールに固有のCSSの場合は、モジュールのCSS、またはrequire_cssでモジュールに関連付けるCSSファイルに配置します。つまり、このモジュールに固有の小さなcssファイルは、このモジュールが使用されるページでのみ読み込まれます。後続のページ読み込みでは、ユーザーに対してすでに表示されたモジュールのCSSはキャッシュ処理され、まだ表示されていないアセットのCSSのみがダウンロードされる必要があります。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "レイアウトクラスやユーティリティークラスなどのスタイルを複数のモジュールで共有する場合、またはモジュールの外観が類似していて多数のCSSを共有する場合には、CSSファイルを必要とする各モジュールについて、require_cssで関連付けるCSSファイルを作成することを検討してください。これにより、モジュールごとに同じCSSを繰り返すことを回避できるとともに、キャッシュ処理の面でもメリットがあります。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "サイト全体で使用するモジュールについてもこの点を考慮してください。例えばヘッダーモジュールとフッターモジュールがページごとに変化しない場合には、CSSスタイルにこの方法を採用することを検討してください。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/guides/speed",
          children: "CMS Hubで構築したウェブサイトのパフォーマンス最適化"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://developers.hubspot.jp/docs/cms/guides/js-frameworks",
          children: "HubSpotでのJavaScriptフレームワークとライブラリーの使用"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}