"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 99531901552;
const slug = exports.slug = 'guides/cms/marketplace/module-requirements';
const title = exports.title = 'HubSpot-Marketplace für Vorlagen | Modulanforderungen';
const name = exports.name = 'EMEA DACH (DE) Asset Marketplace | Module requirements';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über die Anforderungen, die Module eines Designs erfüllen müssen, wenn sie beim HubSpot-Marketplace für Vorlagen eingereicht werden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "modulbeschr%C3%A4nkungen",
  "label": "Modulbeschränkungen",
  "parent": null
}, {
  "depth": 0,
  "id": "modul-content",
  "label": "Modul-Content",
  "parent": null
}, {
  "depth": 1,
  "id": "modul-label-und-hilfetext",
  "label": "Modul-Label und Hilfetext",
  "parent": "modul-content"
}, {
  "depth": 1,
  "id": "standard-content",
  "label": "Standard-Content",
  "parent": "modul-content"
}, {
  "depth": 1,
  "id": "modulsymbole",
  "label": "Modulsymbole",
  "parent": "modul-content"
}, {
  "depth": 1,
  "id": "module%2C-die-drittanbieterkonten-erfordern",
  "label": "Module, die Drittanbieterkonten erfordern",
  "parent": "modul-content"
}, {
  "depth": 0,
  "id": "modulfelder",
  "label": "Modulfelder",
  "parent": null
}, {
  "depth": 1,
  "id": "fields.json--und-module.html-konfiguration",
  "label": "fields.json- und module.html-Konfiguration",
  "parent": "modulfelder"
}, {
  "depth": 0,
  "id": "modulcodequalit%C3%A4t",
  "label": "Modulcodequalität",
  "parent": null
}, {
  "depth": 1,
  "id": "module-m%C3%BCssen-eigenst%C3%A4ndig-sein.",
  "label": "Module müssen eigenständig sein.",
  "parent": "modulcodequalit%C3%A4t"
}, {
  "depth": 2,
  "id": "designmodule",
  "label": "Designmodule",
  "parent": "modulcodequalit%C3%A4t"
}, {
  "depth": 2,
  "id": "unabh%C3%A4ngige-module",
  "label": "Unabhängige Module",
  "parent": "modulcodequalit%C3%A4t"
}, {
  "depth": 1,
  "id": "code-einschr%C3%A4nkungen-f%C3%BCr-unabh%C3%A4ngige-module",
  "label": "Code-Einschränkungen für unabhängige Module",
  "parent": "modulcodequalit%C3%A4t"
}, {
  "depth": 1,
  "id": "kategorien",
  "label": "Kategorien",
  "parent": "modulcodequalit%C3%A4t"
}, {
  "depth": 1,
  "id": "selektoren-f%C3%BCr-klassennamen",
  "label": "Selektoren für Klassennamen",
  "parent": "modulcodequalit%C3%A4t"
}, {
  "depth": 1,
  "id": "stile-und-javascript",
  "label": "Stile und JavaScript",
  "parent": "modulcodequalit%C3%A4t"
}, {
  "depth": 0,
  "id": "feldstruktur",
  "label": "Feldstruktur",
  "parent": null
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Econtent%E2%80%9C",
  "label": "Registerkarte „Content“",
  "parent": "feldstruktur"
}, {
  "depth": 1,
  "id": "registerkarte-%E2%80%9Estile%E2%80%9C",
  "label": "Registerkarte „Stile“",
  "parent": "feldstruktur"
}, {
  "depth": 1,
  "id": "beispiele-f%C3%BCr-feldorganisation",
  "label": "Beispiele für Feldorganisation",
  "parent": "feldstruktur"
}, {
  "depth": 2,
  "id": "voreinstellungen",
  "label": "Voreinstellungen",
  "parent": "feldstruktur"
}, {
  "depth": 2,
  "id": "multi-level-gruppierung",
  "label": "Multi-Level-Gruppierung",
  "parent": "feldstruktur"
}, {
  "depth": 2,
  "id": "gruppieren-von-einzelnen-feldern",
  "label": "Gruppieren von einzelnen Feldern",
  "parent": "feldstruktur"
}, {
  "depth": 0,
  "id": "aliase",
  "label": "Aliase",
  "parent": null
}, {
  "depth": 1,
  "id": "einfache-umsetzung",
  "label": "Einfache Umsetzung",
  "parent": "aliase"
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}, {
  "depth": 0,
  "id": "complex-implementation",
  "label": "Complex implementation",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4",
      strong: "strong",
      pre: "pre",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      Alert,
      DndModule,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Modulanforderungen für den HubSpot-Marketplace für Vorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hier erfahren Sie mehr über die Anforderungen, die beim Einreichen eines Moduls für den HubSpot-Marketplace für Vorlagen erfüllt sein müssen. Diese Anforderungen gelten sowohl für Module in einem Design als auch für unabhängige Module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modulbeschränkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module dürfen Folgendes nicht enthalten: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), ", Aufrufe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverlosen Funktionen"
      }), " oder das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#crm-object",
        children: "CRM-Objektfeld"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Modultypen sollten nicht als eigenständige Module gebaut werden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Module in voller Breite"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formulare und Formulare mit mehreren Schritten"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abstandshalter-Module oder Module, die eine Gliederung Ihrer Seite ohne UI erstellen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Module, die die Standardmodulfunktionalität duplizieren"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Handelsspezifische Module"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "E-Mail-spezifische Module"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modul-Content"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über die Anforderungen für Modul-Label und Hilfetext, Felder und Standard-Content."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modul-Label und Hilfetext"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die Module müssen aussagekräftige Label haben, die den Zweck des Moduls vermitteln. Das Label ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero-Banner mit Parallax Scrolling"
        }), " ist beschreibend, die Label ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero-Banner"
        }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Galerie"
        }), " dagegen nicht."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modul-Label dürfen keine Zahlen enthalten, z. B. ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero-Banner 01"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modul-Label dürfen keine Unterstriche enthalten."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modul-Label dürfen keine Abkürzungen enthalten, z. B. ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sp"
        }), " anstelle von ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Spalte"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Module müssen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#meta-json",
          children: "Inline-Hilfetext"
        }), " enthalten (sofern zutreffend), um die Verwendung des Moduls weiterzuleiten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Module dürfen nicht nach einem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Standardmodul"
        }), " benannt werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bei unabhängigen Modulen sollte das Modul-Label mit dem Namen im Vorlagen-Listing übereinstimmen. Wenn Ihr Vorlagen-Listing beispielsweise ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "SuperAwesome Banner with Scrolling"
        }), " ist, sollte Ihr Modul-Label identisch sein."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-name.png",
          alt: "listing-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-label.png",
          alt: "module-label"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standard-Content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das Standardfeld darf keinen ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Lorem ipsum"
        }), "-Text enthalten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Der Standardinhalt für ein Feld sollte Auskunft über dessen Zweck geben:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Beim Einfügen von Menüfeldern müssen Module als Standardinhaltsoption ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Menü auswählen"
            }), " verwenden."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Beim Einfügen von Formularfeldern müssen Module als Standardinhaltsoption ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Formular auswählen"
            }), " verwenden."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Beim Einfügen von Blog-Auswahlfeldern müssen Module als Standardinhaltsoption ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Blog auswählen"
            }), " verwenden."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn das Hinzufügen von Standard-Content zu einem Modul keinen Sinn ergibt, verwenden Sie stattdessen einen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
          children: "Modul-Platzhalter"
        }), ", um dem Content-Autor zu helfen, den Bereich zu visualisieren, den er mit Inhalten füllt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modulsymbole"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module müssen ein benutzerdefiniertes Symbol enthalten, das dem Modul zugewiesen ist (und das Standardsymbol ersetzt). Verwenden Sie keine Unternehmenslogos als Symbole, z. B. Apple- oder Amazon-Logos. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-an-icon",
        children: "Modulsymbole"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Module, die Drittanbieterkonten erfordern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn für einzelne Module ein Drittanbieterkonto erforderlich ist, muss dies in der Vorlagenbeschreibung angegeben werden. Wenn Ihr Modul beispielsweise die Google Maps-Plattform verwendet, müssen Sie einen Hinweis hinzufügen: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "„Die Verwendung dieses Moduls erfordert ein Google Cloud-Konto (Google Maps Platform).“"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modulfelder"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Überprüfen Sie unten die spezifischen Anforderungen für Module in einem Design und unabhängige Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Module in einem Design:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Müssen Inline-Hilfetext und spezifische Standardinhalte für bestimmte Felder enthalten."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ein Teil der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#color",
              children: "Farb"
            }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#logo",
              children: "Logofelder"
            }), " des Designs muss von den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/fields/brand-and-settings-inheritance",
              children: "Markeneinstellungen"
            }), " des Accounts übernommen werden:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Mindestens drei Farbfelder müssen Farben aus den Markeneinstellungen des Accounts erben. Zusätzliche Farbfelder können standardmäßig auf andere Farben festgelegt werden, einschließlich Schwarz und Weiß."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Mindestens ein Logofeld muss von den Markeneinstellungen des Accounts erben. Wenn Sie ein Bildfeld zum Rendern eines Logos verwenden, muss das Bildfeld nicht von den Markeneinstellungen erben."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Module in einem Design und unabhängige Module:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Modulfeldnamen sollten den Zweck des Feldes beschreiben. Wenn beispielsweise ein Textfeld die Jobbezeichnung einer Person enthalten soll, wäre ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Jobbezeichnung"
            }), " eine passende Beschreibung, ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Bezeichnung"
            }), " jedoch nicht."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Alle Standardmodule von HubSpot müssen formatiert sein und in allen eingereichten Vorlagen korrekt angezeigt werden."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json- und module.html-Konfiguration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine kompatible Funktionalität zwischen Designs und unabhängigen Modulen zu gewährleisten, müssen Module die Farb- und Schriftfelder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#inherited-fields",
        children: "erben"
      }), ", entweder durch die Definition von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default_value_path"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), " oder durch die Definition beider Felder in der entsprechenden ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei sowie durch Hinzufügen einer Referenz auf die Designfelder in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "-Datei. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/general-requirements",
        children: "Erfahren Sie mehr über diese Anforderungen."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modulcodequalität"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Module müssen eigenständig sein."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Designmodule"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle für Ihr Designmodul erforderlichen Dateien, wie CSS oder JavaScript, müssen im Designordner und im Designverzeichnis enthalten sein. Sie können die Funktion „Verlinkte Dateien“ im Design-Manager verwenden. Oder Sie schließen die Dateien mit den Funktionen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "require_js()"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-css",
        children: "require_css()"
      }), " mit einem relativen Pfad zur Datei ein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für gängige Bibliotheken wie slick.js können Sie sie mit den Funktionen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " mit einer absoluten URL zum CDN hinzufügen, in dem sie gehostet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Verwenden Sie keine absoluten URLs für Assets, die in Ihrem Entwicklungsportal enthalten sind, da portalübergreifende Verweise nicht aufgelöst werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Unabhängige Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für unabhängige Module sollten alle CSS- und Javascript-Dateien entweder in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " oder in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " enthalten sein. Wahlweise können Sie die Dateien mit den Funktionen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " mit einer absoluten URL zum CDN hinzufügen, in dem sie gehostet werden. Die Funktion „Verlinkte Dateien“ im Design-Manager kann nicht verwendet werden, da diese nur für Designmodule verfügbar ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " im DOM vor allen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "-Dateien enthalten ist, sollte Javascript im Abschnitt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " mit der folgenden Anmerkung zurückgestellt werden:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "document.addEventListener('DOMContentLoaded', function () {\n  // Put Javascript here\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle Skripte und Dateien sollten im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "HTML"
      }), "-Head des Moduls gerendert werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Code-Einschränkungen für unabhängige Module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Einschränkungen gelten nur für unabhängige Module:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es wird empfohlen, nach Möglichkeit ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://vanilla-js.com/",
          children: "vanilla JS"
        }), " zu verwenden. Das Hinzufügen einer jQuery-Bibliothek zu einer Website, die nicht jQuery verwendet, kann möglicherweise zu Konflikten führen und die Website-Seite verlangsamen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie eine jQuery-Bibliothek verwenden, nehmen Sie die Bibliothek über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js()"
        }), "-Funktion auf, sofern jQuery mit dem Kontrollkästchen (Boolesch) in den Account-Einstellungen deaktiviert ist, um Konflikte aus mehreren jQuery-Bibliotheken zu vermeiden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "{% if not site_settings.include_jquery %}\n{{ require_js(\"https://code.jquery.com/jquery-3.7.0.min.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Kategorien"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Alle unabhängigen Module müssen mindestens eine Kategorie haben. Module, die als Teil eines Designs übermittelt werden, müssen keine Kategorien haben. Es wird jedoch empfohlen, mindestens eine aufzunehmen. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "das Hinzufügen von Kategorien zu Modulen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Selektoren für Klassennamen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Jedem Klassennamen-Selektor muss der Modulname vorangestellt werden, wobei Leerzeichen durch Bindestriche ersetzt werden. Im Folgenden finden Sie beispielsweise die Datei ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " für eine Schaltfläche ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "example-button"
        }), ", wobei jeder Klassenname und der CSS-Selektor seinen Namen widerspiegeln."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<style>\n{% scope_css %}\n{# Button wrapper #}\n {% if module.styles.group_alignment.alignment %}\n  .example-button-wrapper {\n   text-align: {{ module.styles.group_alignment.alignment.horizontal_align }};\n   }\n {% endif %}\n\n{# Button #}\n\n.example-button {\n {% if module.styles.group_background.color.color %}\n  background-color: rgba({{ module.styles.group_background.color.color|convert_rgb }}, {{ module.styles.group_background.color.opacity / 100 }});\n {% endif %}\n }\n {% end_scope_css %}\n</style>\n{% end_require_css %}\n\n{##### Module HTML #####}\n\n<div class=\"example-button-wrapper\">\n <a href=\"{{ href }}\" class=\"example-button\"\n {% if module.button_link.open_in_new_tab %}target=\"_blank\"{% endif %}\n {% if rel %}rel=\"{{ rel|join(\" \") }}\"{% endif %}\n >\n  {{ module.button_text }}\n </a>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Stile und JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stile:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Module dürfen keine leere Stilgruppe enthalten."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Das Hartcodieren von Inline-Stilen innerhalb von Modulen wird nicht empfohlen. Verwenden Sie stattdessen dynamische Inlineformatvorlagen, indem Sie Felder zur Steuerung von Stilen aktivieren."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["JavaScript", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScript muss immer mehrere Instanzen eines Moduls darstellen können. JavaScript im JS-Fenster wird nur einmal pro Seite geladen, unabhängig davon, wie häufig ein Modul vorkommt."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScript sollte DOM-Elemente anhand von modulspezifischen Klassennamen referenzieren, um sicherzustellen, dass Elemente außerhalb des Moduls nicht unbeabsichtigt betroffen sind."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen von Modulen können Sie die integrierte Variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{name}}"
      }), " verwenden. Diese Variable ruft die Instanz-ID des Moduls ab (kann nur im Fenster „HTML+HubL“ verwendet werden), um beim CSS- und JS-Markup für komplexe Module zu helfen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/files#require-css-block",
        children: "Erfahren Sie mehr darüber in der Entwicklerdokumentation."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feldstruktur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es müssen die folgenden Anforderungen an die Feldstruktur und -gruppierung erfüllt sein."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Content“"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn es innerhalb einer Feldgruppe mindestens ein Steuerelement gibt, müssen alle Steuerelemente in nach ihrer Funktion gekennzeichneten Kategorien gruppiert werden."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modulfelder, die der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content"
        }), " hinzugefügt wurden, müssen es ermöglichen, den Content eines Moduls anzupassen. Zum Beispiel Steuerelemente für Bilder, Symbole, ALT-Text und Links."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Registerkarte „Stile“"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Modulstil-Feldgruppen müssen konsistent sein und einem Muster folgen. Nachfolgend finden Sie eine empfohlene Reihenfolge für Ihre Stilfeldgruppen. Diese Gruppen können sich auf der obersten Ebene befinden oder dürfen nur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#multi-level-grouping",
        children: "mit einer direkt darunterliegenden Gruppe verschachtelt sein"
      }), ". Leere Gruppen können auch entfernt werden:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#presets",
          children: "Voreinstellungen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Text"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hintergrund"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rahmen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Zeigen auf"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ecke"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abstand"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ausrichtung"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Benutzerdefinierte Stilgruppen, die nicht zu den oben genannten passen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erweitert"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden Feldtypen müssen auf der ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Stile"
      }), "-Registerkarte enthalten sein, falls vorhanden:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#alignment",
          children: "Ausrichtung"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#background-image",
          children: "Hintergrundbild"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Rahmen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Farbe"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Schriftart"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#gradient",
          children: "Farbverlauf"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Abstand"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#text-alignment",
          children: "Textausrichtung"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn Sie Felder von der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content"
        }), " zur Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Stile"
        }), " verschieben, erfahren Sie, wie Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#aliases",
          children: "Aliaszuordnung verwenden"
        }), ", um die Formatierung für Module beibehalten, die bereits auf Live-Seiten verwendet werden."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Die Animationsoptionen sollten immer am unteren Rand der Feldgruppenliste positioniert werden."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Optionen, die es Content-Autoren ermöglichen, Code-Snippets oder CSS hinzuzufügen, sollten am Ende der Feldgruppenliste unter einem Feld ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Erweitert"
          }), " gruppiert werden."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Die Steuerelemente sollten über alle Module hinweg standardisiert werden. Zum Beispiel sollten alle Elemente, die ein Steuerelement für den Rahmenradius haben können, dieses Steuerelement anbieten. Es ist nicht ratsam, Steuerelemente für einige Module anzubieten und für andere nicht."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Modulfelder, die der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Stil"
          }), " hinzugefügt werden, müssen Möglichkeiten zum Bearbeiten des Modulstiels bieten. Zum Beispiel:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Stiloptionen wie Farbe, Textstil, Ausrichtung, Abstand, Rahmen und Eckenradius."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Animationen wie Hover- und Slide-In-Effekte."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Voreinstellungen wie dunkle und helle Designs, bei denen viele Stile gleichzeitig geändert werden."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beispiele für Feldorganisation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Voreinstellungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voreinstellungen können verwendet werden, um die Optionen für Content-Autoren zu begrenzen, da einige Optionen an die Designeinstellungen gebunden sind. Zum Beispiel enthält das im Growth-Design enthaltene Modul ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Symbol"
      }), " Voreinstellungen für ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Dunkel"
      }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Hell"
      }), ", um der Website ein konsistentes Erscheinungsbild zu geben, sofern es auf die gesamte Website angewendet wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Multi-Level-Gruppierung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie noch nicht wissen, ob Sie Stilfelder auf der obersten Ebene halten oder verschachteln möchten, sollten Sie sich das folgende Beispiel ansehen."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Das im Growth-Design enthaltene Modul ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Symbol"
            }), " führt alle Stile auf der obersten Ebene auf, da es sich um eine Komponente handelt. Daher wirken sich alle Stiloptionen auf diese eine Komponente aus."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-icon-module.png",
            alt: "growth-theme-icon-module"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Das im Growth-Design enthaltene Modul ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sprecherkarte"
            }), " enthält mehrere Komponenten: das Bild der Karte und den Textinhalt. Modulstile werden daher nach Komponente gruppiert, damit der Styling-Prozess jeder Komponente für den Content-Autor verständlicher ist."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-speaker-card.png",
            alt: "growth-theme-speaker-card"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Gruppieren von einzelnen Feldern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Button-Modul unten enthält Gruppierungen für ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Voreinstellungen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Text"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Hintergrund"
      }), " und mehr. Obwohl die Feldgruppe ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ecke"
      }), " nur das Steuerelement „Eckenradius“ enthält, ist sie dennoch gruppiert, um ein einheitliches Erlebnis bei der Content-Erstellung zu schaffen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-requirements-2_1.png",
        alt: "module-requirements-2_1"
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/button-styles.png",
        alt: "button-styles"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aliase"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit Aliaszuordnung können Sie Feldzuordnungen in einem Modul erstellen, sodass Sie seine Felder verschieben, umbenennen oder ersetzen können, ohne dass sich dies auf Seiten auswirkt, die das Modul verwenden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Modul wird beispielsweise auf einer Live-Seite verwendet. Sie möchten einige Felder zur ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: ["Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Stile"
        })]
      }), " verschieben, z. B. Farbe oder Schriftart, aber ein Content-Autor hat bereits Werte für diese Felder im Editor ausgewählt. Wenn Sie diese Felder verschieben, ohne eine Aliaszuordnung einzurichten, kann HubSpot diese Felder nicht verschieben und sie werden auf ihre Standardwerte zurückgesetzt, was die Formatierung auf der Live-Seite rückgängig macht."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Stattdessen können Sie einem Feld eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), "-Eigenschaft hinzufügen, um es einem anderen Feld zuzuordnen. Wenn dann kein Wert für das ursprüngliche Feld festgelegt wurde, prüft HubSpot, ob ein Wert im zugeordneten Feld vorhanden ist. Wenn auch im zugeordneten Feld kein Wert vorhanden ist, wird stattdessen der Standardwert verwendet. Diese Eigenschaft kann nur verwendet werden, um Feldwerte zwischen verschiedenen Versionen eines Moduls zuzuordnen, wenn der gespeicherte Datentyp des alten Feldes mit dem gespeicherten Datentyp des neuen Feldes übereinstimmt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine visuelle Übersicht über diese Funktion erhalten Sie unten im Video."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SvESTX8SLJQ",
      maxHeight: "367px",
      maxWidth: "700px",
      allowFullScreen: true
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So migrieren Sie vorhandene Felder zu Aliasen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie neue Felder und ordnen Sie sie mithilfe der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aliases_mapping"
        }), "-Eigenschaft alten Feldern zu."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Entfernen Sie die alte Felddefinition."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aktualisieren Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), "-Datei so, dass die neue Felddefinition verwendet wird."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Es ist nicht möglich, Felder eines anderen Datentyps einander zuzuordnen. Beispielsweise können Sie einem Bildfeld kein Hintergrundfarbverlauffeld zuordnen. Der gespeicherte Wert muss ein gültiger Wert für den Typ des neuen Feldes sein."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Beim Erstellen eines neuen Feldes mit einer Alias-Zuordnung zu einem alten Feld sollten die Standardwerte und die erforderlichen Eigenschaften beider Felder gleich sein."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie Beispiele für die Umsetzung sowohl einfacher als auch komplexer Änderungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#simple-implementation",
          children: "Einfache Umsetzung"
        }), ": Zuordnen eines Farbfeldes zu einem anderen neuen Farbfeld."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#complex-implementation",
          children: "Komplexe Umsetzung"
        }), ": Zuordnen eines Zahlenfeldes zum ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), "-Unterfeld eines Schriftartfeldes, um die Schriftartgröße zu steuern."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einfache Umsetzung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In einfachen Situationen sollten der Feldtyp des alten Feldes und der Feldtyp des neuen Feldes gleich sein. Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Altes Farbfeld zu neuem Farbfeld."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Altes Textfeld zu neuem Textfeld."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Altes Abstandfeld zu neuem Abstandfeld."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Unten sehen Sie ein Beispiel für die Verwendung von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " beim Verschieben eines Farbfeldes von der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Content"
      }), " des Moduls zur Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Stile"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Button Color\",\n    \"name\": \"old_button_color_field\",\n    \"type\": \"color\",\n    \"required\": true,\n    \"default\": {\n      \"color\": \"#FFFFFF\",\n      \"opacity\": 100\n    }\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Styles\",\n    \"name\": \"styles\",\n    \"type\": \"group\",\n    \"tab\": \"STYLE\",\n    \"children\": [\n      {\n        \"label\": \"Button Color\",\n        \"name\": \"new_button_color_field\",\n        \"type\": \"color\",\n        \"required\": true,\n        \"aliases_mapping\": {\n          \"property_aliases_paths\": {\n            \"new_button_color_field\": [\"old_button_color_field\"]\n          }\n        },\n        \"default\": {\n          \"color\": \"#FFFFFF\",\n          \"opacity\": 100\n        }\n      }\n    ]\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Complex implementation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In komplexeren Situationen können Sie Felder auch Unterfeldern oder anderen Modulfeldtypen zuordnen, sofern der Datentyp der gleiche ist und der Unterfeldtyp des neuen Feldes übereinstimmt. Unterfelder sind die Eigenschaften innerhalb des gespeicherten Wertobjekts des Feldes. Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Zuordnen eines ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Rich-Text"
        }), "-Feldes zu einem ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Text"
        }), "-Feld, da die Werte in beiden Feldern als Zeichenfolgen gespeichert werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das Konsolidieren von Typografiefeldern, z. B. Wechsel von einem Zahlenfeld für die Schriftgröße zu eine, Schriftartfeld (das ein Unterfeld für die Schriftgröße aufweist). Sie können einen Alias für das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), "-Unterfeld hinzufügen, um es mithilfe der Punktnotation dem alten Zahlenfeld zuzuordnen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie ein Beispiel für die Änderung der Schriftgrößenoption von einem Zahlenfeld in ein Schriftartfeld mit einem Unterfeld für die Schriftgröße."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_number_field\",\n    \"label\": \"Number field\",\n    \"required\": false,\n    \"locked\": false,\n    \"display\": \"text\",\n    \"step\": 1,\n    \"type\": \"number\",\n    \"min\": null,\n    \"max\": null,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"default\": null\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_font_field\",\n    \"label\": \"font_field\",\n    \"required\": false,\n    \"locked\": false,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"load_external_fonts\": true,\n    \"type\": \"font\",\n    \"aliases_mapping\": {\n      \"property_aliases_paths\": {\n        \"my_font_field.size\": [\"my_number_field\"]\n      }\n    },\n    \"default\": {\n      \"size\": 12,\n      \"font\": \"Merriweather\",\n      \"font_set\": \"GOOGLE\",\n      \"size_unit\": \"px\",\n      \"color\": \"#000\",\n      \"styles\": {}\n    }\n  }\n]\n"
          })
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}