"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890226;
const slug = exports.slug = 'guides/cms/content/templates/overview';
const title = exports.title = 'Visão geral dos modelos';
const name = exports.name = 'EMEA PT (pt-BR) PT-BR Templates';
const metaDescription = exports.metaDescription = 'Os modelos são páginas reutilizáveis ou wrappers de e-mail que geralmente colocam módulos e partials em um layout. Os criadores de conteúdo selecionam um modelo para usar ao criar uma página ou e-mail.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crie-um-modelo",
  "label": "Crie um modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "visualizar-um-modelo",
  "label": "Visualizar um modelo",
  "parent": null
}, {
  "depth": 1,
  "id": "visualizar-com-o-gerenciador-de-design",
  "label": "Visualizar com o gerenciador de design",
  "parent": "visualizar-um-modelo"
}, {
  "depth": 1,
  "id": "visualizar-com-uma-nova-p%C3%A1gina",
  "label": "Visualizar com uma nova página",
  "parent": "visualizar-um-modelo"
}, {
  "depth": 0,
  "id": "tipos-de-modelo",
  "label": "Tipos de modelo",
  "parent": null
}, {
  "depth": 1,
  "id": "p%C3%A1gina",
  "label": "Página",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "e-mail",
  "label": "E-mail",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "partial",
  "label": "Partial",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "partial-global",
  "label": "Partial global",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "blog",
  "label": "Blog",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "listagem-do-blog",
  "label": "Listagem do blog",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "post-de-blog",
  "label": "Post de blog",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "post-de-blog-e-modelo-de-listagem-combinados",
  "label": "Post de blog e modelo de listagem combinados",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "p%C3%A1ginas-do-sistema",
  "label": "Páginas do sistema",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "p%C3%A1ginas-de-erro",
  "label": "Páginas de erro",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "prefer%C3%AAncias-de-assinatura-de-e-mail",
  "label": "Preferências de assinatura de e-mail",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "cancelar-assinatura-de-backup-de-e-mail",
  "label": "Cancelar assinatura de backup de e-mail",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "confirma%C3%A7%C3%A3o-de-cancelamento-de-inscri%C3%A7%C3%A3o-de-e-mail",
  "label": "Confirmação de cancelamento de inscrição de e-mail",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "solicita%C3%A7%C3%A3o-de-senha",
  "label": "Solicitação de senha",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "p%C3%A1gina-de-resultados-de-pesquisa",
  "label": "Página de resultados de pesquisa",
  "parent": "tipos-de-modelo"
}, {
  "depth": 1,
  "id": "associa%C3%A7%C3%A3o",
  "label": "Associação",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "login-de-membro",
  "label": "Login de membro",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "registro-de-membro",
  "label": "Registro de membro",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "redefini%C3%A7%C3%A3o-de-senha-de-um-membro",
  "label": "Redefinição de senha de um membro",
  "parent": "tipos-de-modelo"
}, {
  "depth": 2,
  "id": "solicita%C3%A7%C3%A3o-de-redefini%C3%A7%C3%A3o-de-associa%C3%A7%C3%A3o",
  "label": "Solicitação de redefinição de associação",
  "parent": "tipos-de-modelo"
}, {
  "depth": 0,
  "id": "arquivos-cms-inclu%C3%ADdos",
  "label": "Arquivos CMS incluídos",
  "parent": null
}, {
  "depth": 1,
  "id": "jquery",
  "label": "jQuery",
  "parent": "arquivos-cms-inclu%C3%ADdos"
}, {
  "depth": 1,
  "id": "layout.css",
  "label": "layout.css",
  "parent": "arquivos-cms-inclu%C3%ADdos"
}, {
  "depth": 1,
  "id": "c%C3%B3digo-de-rastreamento-da-hubspot",
  "label": "Código de rastreamento da HubSpot",
  "parent": "arquivos-cms-inclu%C3%ADdos"
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral dos modelos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos definem o layout das páginas, e-mails e temas do HubSpot. Um modelo consiste em módulos e partials, e pode fazer referência a outros ativos, tais como folhas de estilo e arquivos JavaScript. Os modelos podem ser criados usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/guides/getting-started",
        children: "CLI da HubSpot"
      }), " ou no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "Gerenciador de design da HubSpot"
      }), ". Para os criadores de conteúdo, o modelo é a primeira coisa que será selecionada ao criar uma página ou e-mail."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, aprenda como criar um modelo, os diferentes tipos de modelos e o que está incluído com os seus modelos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crie um modelo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode criar um modelo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "no HubSpot"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/guides/getting-started",
        children: "usando a CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para criar um modelo usando a CLI, execute o seguinte comando:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <name> [dest]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do modelo"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O caminho do diretório local no qual você deseja criar o modelo. Se não estiver incluído, o modelo será criado no diretório atual."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usando as setas do teclado, navegue até o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#template-types",
          children: "tipo de modelo"
        }), " que você pretende criar e pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "enter"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O modelo será criado localmente. Quando você quiser disponibilizar o modelo para utilização na sua conta da HubSpot, faça ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cms-cli#upload",
        children: "upload"
      }), " do modelo na sua conta. Você também pode usar o comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cms-cli#watch",
        children: "watch"
      }), " para carregar automaticamente todos os novos arquivos e edições de arquivos existentes no diretório de trabalho atual e em diretórios secundários."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualizar um modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de atualizar um modelo, você pode visualizá-lo para garantir que ele se pareça e funcione conforme o esperado. No HubSpot, existem algumas maneiras de visualizar um modelo, como:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar um modelo no gerenciador de design:"
        }), " ideal para verificações visuais rápidas ou quando você precisa visualizar um modelo de post de blog/lista/combinado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar um novo recurso a partir de um modelo:"
        }), " ideal para testar o editor de arrastar e soltar e a experiência de criação de conteúdo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Visualizar com o gerenciador de design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Visualizar modelos usando o gerenciador de design pode ser especialmente útil para verificações visuais rápidas. O visualizador de modelos também permite configurar opções de exibição, como as dimensões da janela de visualização."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para visualizar um modelo no gerenciador de design:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivos e modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ferramentas de design"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usando o explorador de arquivos na barra lateral esquerda, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "modelo"
        }), " que deseja visualizar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Visualização ao vivo com opções de exibição"
            }), " para visualizar o modelo com opções para testar a capacidade de resposta e as configurações de domínio, como as folhas de estilo. Esta opção exibe a página dentro de um iframe. Isso também permite selecionar entre blogs e a exibição de posts ou listagens de blog."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Visualizar sem opções de exibição"
            }), " para visualizar o modelo sem opções adicionais."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/template-preview-options.png",
        alt: "template-preview-options"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Visualizar com uma nova página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao alterar áreas de arrastar e soltar, valores de parâmetros padrão nos módulos ou fazer outras alterações significativas, é melhor ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/website-pages/create-and-customize-pages",
        children: "criar uma página do site"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/blog/create-a-new-blog",
        children: "blog"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/blog/create-and-publish-blog-posts",
        children: "post de blog"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
        children: "e-mail"
      }), " usando o modelo. Você pode experimentar diferentes valores de campos de módulo, testar a aparência do seu modelo no mundo real e otimizá-lo com a melhor experiência de criação de conteúdo. Você pode publicar esses ativos ou deixá-los no modo de rascunho para fins de teste."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, você pode usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-general/redesign-and-relaunch-your-site-with-content-staging#new",
        children: "preparação de conteúdo"
      }), " ou uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "conta de sandbox de desenvolvedor"
      }), " para criar e visualizar ativos sem afetar uma conta de produção."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os modelos podem ser usados para diferentes tipos de conteúdo, tais como páginas de sites e posts de blog. Nos modelos codificados, você designa o tipo de modelo, adicionando uma anotação na parte superior do arquivo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, conheça os diferentes tipos de modelos e anotações que você pode usar para designar cada tipo."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " um criador de conteúdo pode trocar o modelo de uma página por outro do mesmo tipo, dependendo se tem tags ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos criados com o editor visual de layout de arrastar e soltar podem ser trocados por outros modelos de arrastar e soltar ou codificados com ou sem as tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados ", (0, _jsxRuntime.jsx)("u", {
            children: "sem"
          }), " tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados sem tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos de página são o tipo de modelo mais aberto. Eles servem para qualquer tipo de página da web ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/7177/what-is-a-landing-page-and-why-should-you-care.aspx",
        children: "landing page"
      }), ". Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#layout-css",
        children: "layouts"
      }), " de página não são preenchidos previamente com componentes. Os modelos de página codificados vêm preenchidos previamente com marcação esparsa, incluindo tags sugeridas do HubL para incluir as informações de metadescrição, título e cabeçalho/rodapé necessárias", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#included-cms-files"
      }), ". Exemplos de páginas que normalmente utilizariam um modelo de página incluem, entre outros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
          children: "Página inicial"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/about.html",
          children: "Sobre nós"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/contact.html",
          children: "Contato"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-mail"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos de e-mail são utilizados pela ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/marketing/email",
        children: "ferramenta de e-mail"
      }), ". Eles têm o mais rigoroso conjunto de requisitos, pois precisam ser exibidos em muitos clientes de e-mail diferentes e estão em conformidade com as práticas recomendadas para garantir a entregabilidade adequada. Os modelos de e-mail de arrastar e soltar do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/html-hubl-templates",
        children: "HTML + HubL"
      }), " e do Gerenciador de design incluem componentes de base no momento da criação. Os modelos com este ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " só são visíveis para seleção ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/email/create-and-send-marketing-emails",
        children: "criar um e-mail"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para permanecer em conformidade com o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), ", os modelos de e-mail têm um conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables#required-email-template-variables",
        children: "variáveis obrigatórias"
      }), " que devem ser incluídas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos de e-mail também têm uma funcionalidade integrada para o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/email-template-markup#hs-inline-css-and-data-hse-inline-css",
        children: "CSS inline"
      }), " adicionado aos elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " com um nome de classe especial ou atributo de dados. O CSS inline em e-mails é um método utilizado para obter maior suporte por meio de clientes de e-mail. Felizmente, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://litmus.com/blog/do-email-marketers-and-designers-still-need-to-inline-css",
        children: "a maioria dos clientes de e-mail populares agora oferece suporte a CSS incorporado"
      }), ", mas isso pode não ser representativo de seus destinatários específicos. Use o bom senso, fazendo o que é certo para seus destinatários."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/email-template-markup",
        children: "Saiba mais sobre como criar modelos de e-mail."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partial"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page isAvailableForNewContent: false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os partials são arquivos de modelo que podem ser incluídos em outros arquivos codificados. Ao contrário dos partials globais, os partials podem ser editados individualmente sem afetar outras instâncias do partial."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/html-hubl-templates#partials",
        children: "partials"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partial global"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: global_partial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Parciais globais são um tipo de modelo criado usando HTML e HubL que pode ser reutilizado em todo o site. Os tipos mais comuns de partials são cabeçalhos de sites, barras laterais e rodapés. A atualização de um partial global atualizará todas as instâncias do partial global."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/html-hubl-templates#global-partials",
        children: "partials globais"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/global-content",
        children: "conteúdo global"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-blog/cos-blog-how-do-i-create-a-cos-blog",
        children: "criar um blog"
      }), ", os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/blog",
        children: "modelos de blog"
      }), " têm uma estrutura semelhante à dos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#page-templates",
        children: "modelos de página"
      }), " padrão. A diferença essencial é que eles podem ser selecionados nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "configurações de conteúdo"
      }), " como modelos de blog, enquanto que os modelos de página não podem. Os modelos criados com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "não são exibidos"
      }), " quando um usuário está criando uma página web na tela de seleção de modelos. Os modelos de blog têm duas formas: as páginas de listagem de blog e as páginas de detalhes dos posts de blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Listagem do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_listing\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/blog/listing",
        children: "modelo de listagem do blog"
      }), " é o modelo que os usuários verão ao navegar para a URL do blog. Normalmente este modelo é usado para listar resumos, títulos e imagens em destaque de todos os posts do blog. Além disso, normalmente inclui paginação para navegar para os posts mais antigos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Post de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_post\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O modelo de post de blog é o modelo que os usuários verão ao exibir um post individual no blog. Estes modelos normalmente mostram o conteúdo completo do post."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Post de blog e modelo de listagem combinados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um único modelo de blog pode controlar o layout ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/blog-template-markup#if-is-listing-view-statement",
        children: "tanto das páginas de listagem como das de detalhes"
      }), ", mas estas costumam usar modelos separados. Modelos combinados serão mostrados nas configurações do blog como selecionáveis tanto para a listagem como para as opções de post de blog. Se você está criando um modelo que pretende ser usado apenas para posts, ou listagens, deve usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), ", ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para uma experiência mais simples de desenvolvimento e criação de conteúdo, é recomendável usar o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post"
        }), " separado, e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_listing"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateTypes"
        }), " em vez de modelos combinados."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Páginas do sistema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos de página do sistema são sinalizados internamente para o seu propósito específico. Nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "configurações de conteúdo"
      }), " da conta, você pode selecionar esses modelos para os propósitos especificados na aba do sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o único tipo de modelo de página do sistema que pode ser criado por meio da CLI é o modelo de página de resultados de pesquisa."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Páginas de erro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As páginas de erro podem ser definidas nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Configurações de conteúdo"
      }), " como páginas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/404.html",
        children: "404"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/500.html",
        children: "500"
      }), ". Ambos os modelos utilizam o mesmo templateType. Os modelos criados com este templateType não são exibidos quando um usuário está criando uma página web na tela de seleção de modelos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: error_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Preferências de assinatura de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A página de preferências da assinatura de e-mail. Lista todos os tipos de assinatura disponíveis que um usuário pode optar por assinar ou cancelar. É obrigatório conter a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_subscriptions \"email_subscriptions\"  %}"
      }), " do HubL. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscription-preferences.html",
        children: "Consulte o modelo de preferências de assinatura em cms-theme-boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscription_preferences_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Cancelar assinatura de backup de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um modelo do sistema para páginas de cancelamento de assinatura de e-mail. É obrigatório conter a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_simple_subscription \"email_simple_subscription\" %}"
      }), " do HubL. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/backup-unsubscribe.html",
        children: "Consulte o modelo de preferências de cancelamento de assinatura em cms-theme-boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_backup_unsubscribe_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Confirmação de cancelamento de inscrição de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um modelo do sistema para páginas de confirmação de cancelamento de assinatura de e-mail. Os usuários são encaminhados para cá quando acessam a URL gerada pela variável ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables#email-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ unsubscribe_link_all }}"
        })
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscriptions-confirmation.html",
        children: "Consulte o modelo de confirmação de assinatura em cms-theme-boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscriptions_confirmation_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Solicitação de senha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos de solicitação de senha fornecem uma página de conteúdo com marca que os criadores de conteúdo podem exibir para exigir uma senha para que um visitante possa ver o conteúdo real da página. Os modelos de solicitação de senha são definidos por meio das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Configurações de conteúdo"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "Como proteger uma página no HubSpot com senha"
      }), ". Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/password-prompt.html",
        children: "solicitação de página protegida por senha"
      }), " no boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: password_prompt_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Página de resultados de pesquisa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um modelo do sistema para a funcionalidade integrada de listagem de sites do CMS. Consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "modelo da página de resultados da pesquisa"
      }), " no boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: search_results_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As contas da HubSpot com a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/features/memberships",
        children: "funcionalidade de associação"
      }), " (somente ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "CMS Hub"
      }), " Enterprise) podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-pages-editor/control-audience-access-to-pages",
        children: "criar páginas em seus sites que apenas usuários que não constam em listas específicas no CRM podem acessar"
      }), ". Isso permite que os visitantes do site tenham contas com credenciais de login. Estes modelos permitem controlar a aparência dessas páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Apenas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/html-hubl-templates",
          children: "modelos HTML + HubL"
        }), " podem ser modelos de associação."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Login de membro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta é a página de login que é exibida quando um usuário tenta acessar um conteúdo com acesso controlado por meio da funcionalidade de associação. Normalmente contém o módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_login \"member_login\" %}"
      }), ". Consulte o exemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-login.html",
        children: "modelo de login de membro"
      }), " no boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_login_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Registro de membro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta é a página de inscrição que permite aos usuários criar uma conta para visualizar o conteúdo que somente as pessoas desta lista podem acessar. Normalmente contém a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_register \"member_register\" %}"
      }), " do HubL. Consulte o exemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-register.html",
        children: "modelo de inscrição de membro"
      }), " no boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_register_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Redefinição de senha de um membro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta é a página de redefinição de senha. Os usuários fornecem uma nova senha nesta página. Normalmente contém a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset \"password_reset\" %}"
      }), " do HubL. Consulte o exemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password.html",
        children: "modelo de redefinição de senha de um membro"
      }), " no boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Solicitação de redefinição de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta é a solicitação de uma página de redefinição de senha. Exibição de um formulário solicitando um e-mail para redefinir a senha. Normalmente contém a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset_request \"password_reset_request\" %}"
      }), " do HubL. Consulte o exemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password-request.html",
        children: "modelo de solicitação de redefinição de senha de um membro"
      }), " no boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_request_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Arquivos CMS incluídos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existem certos arquivos JavaScript e CSS que são anexados aos modelos do CMS. Alguns arquivos são incluídos automaticamente e não podem ser removidos, enquanto outros podem ser incluídos opcionalmente. Para saber mais sobre a ordem em que as folhas de estilo são anexadas ao conteúdo do CMS, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-general/create-edit-and-attach-css-files-to-style-your-site",
        children: "confira este artigo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jquery.com/",
        children: "jQuery"
      }), " está incluído opcionalmente na tag head dos modelos do HubSpot. Se incluído, é renderizado como parte da variável ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " do HubL."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Configurações > Site > Páginas"
      }), ", você pode alterar o jQuery para a versão 1.11.x ou 1.7.1,ou desabilitá-lo completamente. Você também pode optar por incluir um script de migração jQuery para permitir compatibilidade retroativa com navegadores mais antigos. Você pode mover o jQuery para o rodapé para melhorar o desempenho da página, mas isso pode quebrar o JavaScript que depende dele. É recomendável testar antes de movê-lo, adicionando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " ao final dos URLs de página do site."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Embora o jQuery tenha sido historicamente incluído por padrão, atualmente o CMS Hub não requer jQuery. A maior parte da funcionalidade do jQuery agora tem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://youmightnotneedjquery.com/",
        children: "equivalentes modernos do vanilla javascript"
      }), ", e é recomendável usá-los em vez disso. Se precisar usar jQuery, incentivamos você a desativar a versão padrão nas configurações e usar a última versão carregada acima da tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para testar se a remoção do jQuery no seu site prejudicará alguma coisa, adicione ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsNoJQuery=true"
      }), " ao final do URL ao visualizar seu site, especialmente páginas com bastante interatividade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "layout.css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anteriormente conhecido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "required_base.css"
      }), ", este arquivo é responsável por estilizar a grade responsiva do HubSpot. Este arquivo é incluído automaticamente em qualquer modelo de arrastar e soltar, mas ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " é incluído por padrão em modelos codificados personalizados. Ao usar tags ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), " em modelos HTML + HubL codificados, não é necessário carregar o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "layout.css"
      }), ", mas uma versão dele estará ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "incluída no boilerplate do CMS"
      }), " para facilitar a sua instalação e execução."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além do CSS com grid responsivo, o arquivo inclui algumas classes que podem ser usadas para exibir e ocultar elementos em diferentes viewports. Para saber mais, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/Designers_Docs_2015/layout.css",
        children: "veja o arquivo diretamente"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Código de rastreamento da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/account/how-does-hubspot-track-visitors",
        children: "código de rastreamento da HubSpot"
      }), " é sempre adicionado automaticamente a qualquer modelo HubSpot (com exceção dos modelos de e-mail), com a variável ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/hubl/variables",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "standard_footer_includes"
        })
      }), ". O código de rastreamento carrega um arquivo de análise JavaScript chamado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "your_HubID.js"
      }), " (exemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "158015.js"
      }), "). Este código de rastreamento está diretamente integrado à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/account/how-do-i-turn-on-gdpr-functionality-in-my-hubspot-account",
        children: "funcionalidade de GDPR da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/drag-and-drop-areas",
          children: "Áreas de arrastar e soltar"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/templates/drag-and-drop-areas/sections",
          children: "Modelos de seção"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/blog/use-web-components-in-hubspot-cms-development",
          children: "Como usar componentes da Web em modelos e módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/global-content",
          children: "Crie rodapés, cabeçalhos e muito mais usando conteúdo global"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}