"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694137;
const slug = exports.slug = 'guides/cms/content/modules/quickstart';
const title = exports.title = 'Introdução aos módulos';
const name = exports.name = 'Introdução aos módulos';
const metaDescription = exports.metaDescription = 'Crie um componente reutilizável em suas páginas e modelos chamado módulo personalizado. Você aprenderá sobre campos e os usará com o HubL.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Module%20Editor-1-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Introdução aos módulos';
const toc = exports.toc = [{
  "depth": 0,
  "id": "antes-de-come%C3%A7ar",
  "label": "Antes de começar",
  "parent": null
}, {
  "depth": 0,
  "id": "configurar-seu-ambiente-de-desenvolvimento-local",
  "label": "Configurar seu ambiente de desenvolvimento local",
  "parent": null
}, {
  "depth": 0,
  "id": "adicionar-o-cms-boilerplate-%C3%A0-sua-conta",
  "label": "Adicionar o CMS Boilerplate à sua conta",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-um-m%C3%B3dulo-no-hubspot",
  "label": "Criar um módulo no HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "adicionar-campos-ao-m%C3%B3dulo",
  "label": "Adicionar campos ao módulo",
  "parent": "criar-um-m%C3%B3dulo-no-hubspot"
}, {
  "depth": 2,
  "id": "adicionar-campo-de-texto-para-nome-do-cliente",
  "label": "Adicionar campo de texto para nome do cliente",
  "parent": "criar-um-m%C3%B3dulo-no-hubspot"
}, {
  "depth": 2,
  "id": "adicionar-o-campo-de-imagem-para-a-foto-de-perfil-do-cliente",
  "label": "Adicionar o campo de imagem para a foto de perfil do cliente",
  "parent": "criar-um-m%C3%B3dulo-no-hubspot"
}, {
  "depth": 2,
  "id": "adicionar-o-campo-de-rich-text-para-o-depoimento-de-sally",
  "label": "Adicionar o campo de rich text para o depoimento de Sally",
  "parent": "criar-um-m%C3%B3dulo-no-hubspot"
}, {
  "depth": 1,
  "id": "adicionar-o-hubl-para-exibir-os-dados-de-campo",
  "label": "Adicionar o HubL para exibir os dados de campo",
  "parent": "criar-um-m%C3%B3dulo-no-hubspot"
}, {
  "depth": 0,
  "id": "exibir-e-modificar-o-m%C3%B3dulo-localmente",
  "label": "Exibir e modificar o módulo localmente",
  "parent": null
}, {
  "depth": 1,
  "id": "abrir-a-pasta-do-m%C3%B3dulo-no-seu-ambiente-local",
  "label": "Abrir a pasta do módulo no seu ambiente local",
  "parent": "exibir-e-modificar-o-m%C3%B3dulo-localmente"
}, {
  "depth": 1,
  "id": "exibir-o-arquivo-fields.json-do-m%C3%B3dulo",
  "label": "Exibir o arquivo fields.json do módulo",
  "parent": "exibir-e-modificar-o-m%C3%B3dulo-localmente"
}, {
  "depth": 1,
  "id": "exibir-o-arquivo-module.html-do-m%C3%B3dulo",
  "label": "Exibir o arquivo module.html do módulo",
  "parent": "exibir-e-modificar-o-m%C3%B3dulo-localmente"
}, {
  "depth": 1,
  "id": "exibir-o-arquivo-module.css-do-m%C3%B3dulo",
  "label": "Exibir o arquivo module.css do módulo",
  "parent": "exibir-e-modificar-o-m%C3%B3dulo-localmente"
}, {
  "depth": 0,
  "id": "as-altera%C3%A7%C3%B5es-locais-para-a-sua-conta-da-hubspot",
  "label": "as alterações locais para a sua conta da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "visualizar-suas-altera%C3%A7%C3%B5es-locais-no-hubspot",
  "label": "Visualizar suas alterações locais no HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "adicionar-o-m%C3%B3dulo-a-um-modelo",
  "label": "Adicionar o módulo a um modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "visualizar-suas-altera%C3%A7%C3%B5es-no-hubspot",
  "label": "Visualizar suas alterações no HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "personalizar-o-m%C3%B3dulo-no-modelo-localmente",
  "label": "Personalizar o módulo no modelo localmente",
  "parent": null
}, {
  "depth": 0,
  "id": "adicionar-mais-dois-m%C3%B3dulos-de-depoimentos-ao-modelo",
  "label": "Adicionar mais dois módulos de depoimentos ao modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "modificar-o-nome-do-tema",
  "label": "Modificar o nome do tema",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%B3ximas-etapas",
  "label": "Próximas etapas",
  "parent": null
}, {
  "depth": 0,
  "id": "outros-tutoriais",
  "label": "Outros tutoriais",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      pre: "pre",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Introdução aos módulos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neste tutorial, você usará o tema do HubSpot CMS Boilerplate para aprender como criar um módulo e usá-lo em modelos e páginas como parte de um tema. No final do tutorial, você terá criado um módulo de depoimentos que inclui um campo de imagem, um campo de texto e um campo de rich text."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Se esta é a sua primeira experiência com o desenvolvimento do CMS Hub, recomendamos:"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Início rápido no desenvolvimento do CMS Hub"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": se você está procurando desenvolver um módulo para uso em um tema que deseja listar no Marketplace de ativos da HubSpot, confira os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements",
          children: "Requisitos do módulo para o Marketplace de ativos"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Antes de começar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de começar com este tutorial:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Configure um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-cms-developer-sandbox",
          children: "Ambiente de testes para desenvolvedores do HubSpot CMS"
        }), ". Você pode usar sua conta existente, mas o ambiente de testes permitirá o desenvolvimento sem afetar os ativos da sua conta principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instale o", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: " Node.js"
        }), ", que habilita as ferramentas de desenvolvimento local da HubSpot. As versões 10 ou superior são compatíveis."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se quiser avançar, você pode visualizar os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
        children: "arquivos do projeto"
      }), " concluídos. Como o código do tema do CMS Boilerplate pode mudar com o tempo, apenas os arquivos mais críticos que os desenvolvedores precisarão criar ou editar durante o tutorial são incluídos. Esses campos incluem:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial.module:"
        }), " a pasta que contém os arquivos que compõem o módulo personalizado que você criará como parte do tutorial."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "homepage.html:"
        }), " o modelo que você editará para incluir o módulo personalizado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "images:"
        }), " a pasta que contém as imagens de perfil que você usará no módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar seu ambiente de desenvolvimento local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Durante este tutorial, você fará o desenvolvimento do módulo localmente. Para interagir com o HubSpot na interface da linha de comando, você precisará instalar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "CLI do HubSpot"
      }), " e configurá-la para acessar sua conta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No terminal, execute o comando a seguir para instalar a CLI globalmente. Para instalar a CLI apenas no seu diretório atual, execute o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm install -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No diretório onde você armazenará os arquivos do tema, autentique a sua conta da HubSpot para que seja possível interagir com ela por meio da CLI."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs init\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), " para abrir a página da chave de acesso pessoal no navegador."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "conta"
        }), " para a qual deseja implantar e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continuar com esta conta"
        }), ". Você será redirecionado para a página da chave de acesso pessoal da conta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao lado de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Chave de acesso pessoal"
        }), ", clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mostrar"
        }), " para revelar sua chave. Em seguida, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " para copiá-la para a área de transferência."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cole a chave copiada no terminal e pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " exclusivo para a conta. Esse nome só será visto e usado por você ao executar comandos. Em seguida, pressione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". Para conectar as ferramentas de desenvolvimento local à sua conta:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você verá uma mensagem de sucesso confirmando que o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " foi criado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar o CMS Boilerplate à sua conta"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Execute o comando abaixo para criar um novo tema chamado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), ". Uma pasta de temas chamada ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), " será criada no seu diretório de trabalho contendo os ativos de boilerplate."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create website-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Carregue os arquivos na sua conta."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload <src> <destination>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O caminho para os arquivos locais, relacionados ao seu diretório de trabalho atual."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O caminho de destino na sua conta da HubSpot. Isso pode ser uma nova pasta."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme my-new-theme"
      }), " faria o upload da pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-theme"
      }), " do seu computador para uma pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-new-theme"
      }), " no HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Por padrão, o HubSpot carregará na conta padrão no seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config,yml"
        }), ". No entanto, você também pode especificar uma conta, incluindo o sinalizador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--account=<accountNameOrId>"
        }), " no comando. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload --portal=mainProd"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Saiba mais na ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "Referência de comando da CLI"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um módulo no HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o boilerplate de CMS em seu ambiente local, você criará um novo módulo para o tema."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para os fins deste tutorial, você criará o módulo no HubSpot e o aplicará ao tema usando a CLI. No entanto, você também pode criar módulos do zero localmente usando o comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#create-new-files",
          children: "hs create module"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Faça login na sua conta da HubSpot e acesse o gerenciador de design, navegando até ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing > Arquivos e modelos > Ferramentas de design"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral esquerda do gerenciador de design, abra a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "pasta de temas"
          }), " que você acabou de fazer o upload."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na pasta de temas, abra a pasta de ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "módulos"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "No canto superior esquerdo, clique em **Arquivo > Novo arquivo **para criar um novo módulo nesta pasta."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na caixa de diálogo, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "menu suspenso"
          }), ", selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Módulo"
          }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Próximo"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/new-module-dropdown-menu0.png",
            alt: "new-module-dropdown-menu0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Marque a caixa de seleção ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Páginas"
          }), " para disponibilizar o módulo para o site e as landing pages."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nomeie o módulo como ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Depoimentos"
          }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar campos ao módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em seguida, você adicionará três campos ao módulo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um campo de texto para armazenar o nome do cliente que está fornecendo o depoimento."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um campo de imagem que armazenará a imagem de perfil do cliente."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um campo de rich text que armazenará o depoimento do cliente."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Adicionar campo de texto para nome do cliente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral direita do editor de módulos, clique no menu suspenso **Adicionar campo **e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Texto"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-add-field.png",
            alt: "testimonial-module-add-field"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral direita, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ícone de lápis"
          }), " no canto superior direito para dar um nome para o campo. Para este tutorial, insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Nome do cliente"
          }), ". Você verá a mudança do nome da variável do HubL para ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "customer_name"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Defina o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Texto padrão"
          }), " para ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sally"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Text-Field-3.png",
        alt: "Exemplo de campo de texto"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral direita, clique no ícone de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "trilha de navegação"
        }), " para retornar ao menu do módulo principal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-breadcrumb-icon0.png",
        alt: "module-breadcrumb-icon0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Adicionar o campo de imagem para a foto de perfil do cliente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Adicione outro campo usando o mesmo método, desta vez, selecionando o tipo de campo de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagem"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Rotule o campo de imagem ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagem do perfil"
        }), ", em seguida, defina o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome da variável do HubL"
        }), " como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "profile_pic"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagem padrão"
        }), ", selecione a imagem do perfil fornecida para Sally na pasta de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "imagens"
        }), " dos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
          children: "arquivos do projeto"
        }), " concluídos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Defina o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texto alternativo"
        }), " como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Imagem de perfil de Sally"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/getting-started-with-modules-profile-pic.png",
        alt: "getting-started-with-modules-profile-pic"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Adicionar o campo de rich text para o depoimento de Sally"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Adicione outro campo usando o mesmo método, desta vez selecionando o tipo de campo de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rich text"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Rotule o campo de rich text ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Depoimentos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na caixa de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rich text padrão"
        }), " e digite \"Não tive nada além de experiências maravilhosas com esta empresa.\""]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Rich-Text-Field-5--.png",
        alt: "Exemplo de campo de rich text"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Até agora, você adicionou dados em vários campos de módulo. Neste momento, porém, o módulo não contém nenhum HTML para renderizar esses dados. No editor de módulos, isso é refletido pelo estado vazio da seção ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ".", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-html-empty0.png",
        alt: "module-html-empty0"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, você adicionará o HubL ao ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " para exibir os dados do campo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar o HubL para exibir os dados de campo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para exibir dados dos campos criados anteriormente, adicione o seguinte HubL ao painel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{module.customer_name}}\n<img src={{module.profile_pic.src}} alt=\"{{module.profile_pic.alt}}\">\n{{module.testimonial}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os tokens HubL acima usam notação de ponto para acessar os dados de cada campo. Nesse caso, como queremos extrair os dados dos campos do módulo, cada token começa com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", seguido pelo nome da variável HubL do campo. Você pode usar a notação de ponto para acessar ainda mais propriedades específicas de um campo, o que você pode ver nos tokens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "profile_pic"
      }), " na linha 3 acima."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Com o HubL adicionado ao módulo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar"
        }), " no canto superior direito para ver como o módulo está até agora."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-previewer.png",
        alt: "design-manager-previewer"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar alterações"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de criar e publicar o módulo no HubSpot, você usará a CLI para enviá-lo para o ambiente local, de modo a poder visualizar seu conteúdo e fazer quaisquer alterações necessárias."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exibir e modificar o módulo localmente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para exibir o módulo localmente, primeiro você precisará baixá-lo para o tema local:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No terminal, execute o seguinte comando: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs fetch <hs_src> <destination>"
          }), ":"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<hs_src>"
              }), " representa o caminho do arquivo do módulo no HubSpot. Para obter o caminho do arquivo, clique com o botão direito do mouse no módulo, na barra lateral esquerda do gerenciador de design, e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Copiar caminho"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-copy-path0.png",
                alt: "design-manager-copy-path0"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<destination>"
              }), " representa o caminho para o diretório local onde se encontra o tema. Se omitido, o comando será o padrão para o diretório de trabalho atual."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, se você já estiver no diretório de trabalho, o comando de busca pode ser semelhante ao seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch my-theme/modules/testimonial.module\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abrir a pasta do módulo no seu ambiente local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No seu editor de códigos preferido, acesse a pasta do módulo que você acabou de baixar da sua conta da HubSpot. Dentro da pasta do módulo, você verá cinco arquivos diferentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um objeto JSON que contém os campos do módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um objeto JSON que contém metainformações sobre seu módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.css"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O arquivo CSS que estiliza o módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O HTML e o HubL do seu módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.js"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O JavaScript do seu módulo."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode encontrar informações mais detalhadas em nossa documentação sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "estrutura de arquivos do módulo"
      }), ", principalmente as relacionadas aos arquivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), ". Neste tutorial, vamos nos concentrar nos arquivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " e como eles são gerados, baixados e enviados para o editor de módulos no Gerenciador de design."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exibir o arquivo fields.json do módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " do módulo. Além de alguns dos números de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", o atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " do campo de imagem e, potencialmente, a ordem dos campos, o arquivo deve conter um objeto JSON semelhante ao seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//fields.json\n\n[\n  {\n    \"label\": \"Customer Name\",\n    \"name\": \"customer_name\",\n    \"id\": \"2a025cd5-7357-007f-ae2f-25ace762588e\",\n    \"type\": \"text\",\n    \"required\": true,\n    \"locked\": false,\n    \"validation_regex\": \"\",\n    \"allow_new_line\": false,\n    \"show_emoji_picker\": false,\n    \"default\": \"Sally\"\n  },\n  {\n    \"label\": \"Profile Picture\",\n    \"name\": \"profile_pic\",\n    \"id\": \"7877fb84-eb8a-d2c7-d939-77e6e9557d8f\",\n    \"type\": \"image\",\n    \"required\": false,\n    \"locked\": false,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"default\": {\n      \"src\": \"https://cdn2.hubspotqa.net/hubfs/101140939/profile-pic-sally.svg\",\n      \"alt\": \"Sally Profile Picture\",\n      \"width\": 100,\n      \"height\": 100,\n      \"max_width\": 100,\n      \"max_height\": 100\n    }\n  },\n  {\n    \"label\": \"Testimonial\",\n    \"name\": \"testimonial\",\n    \"id\": \"c5524ece-1ab5-f92d-a5de-e2bf53199dfa\",\n    \"type\": \"richtext\",\n    \"required\": false,\n    \"locked\": false,\n    \"default\": \"<p>I’ve had nothing but wonderful experiences with this company.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os valores para os seguintes campos corresponderão aos valores adicionados na etapa 3:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": o nome do campo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": o rótulo do campo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "default"
        }), ": o valor padrão do campo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exibir o arquivo module.html do módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "deve conter o HubL e o HTML que você escreveu anteriormente no editor de módulos do HubL + HTML."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para tornar este código mais interessante e pronto para estilo CSS, copie e cole o seguinte código no arquivo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"testimonial\">\n  <h1 class=\"testimonial__header\"> {{ module.customer_name }} </h1>\n  <img class=\"testimonial__picture\" src={{ module.profile_pic.src }} alt={{ module.profile_pic.alt }}>\n  {{ module.testimonial }}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Escrever seu HTML, como mostrado acima, usa a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/bem-101/",
          children: "estrutura de classes BEM"
        }), " de acordo com o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/STYLEGUIDE.md#css-code-formatting",
          children: "guia de estilo"
        }), " do tema do HubSpot CMS Boilerplate."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exibir o arquivo module.css do módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " deve estar vazio neste momento. Para adicionar estilo, copie e cole o seguinte código no arquivo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial {\n  text-align: center;\n}\n\n.testimonial__header {\n  font-weight: bold;\n}\n\n.testimonial__picture {\n  display: block;\n  margin: auto;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Após adicionar o código, salve o arquivo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "as alterações locais para a sua conta da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de salvar as alterações locais, envie-as à sua conta da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Acesse o terminal e verifique se você está no diretório correto."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Execute o comando watch para enviar alterações para o HubSpot ao salvar: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <destination>"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch my-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualizar suas alterações locais no HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, vá em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivos e Modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ferramentas de design"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda, acesse o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tema"
        }), " que você criou, abra a pasta de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "módulos"
        }), " e selecione o módulo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Depoimentos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Com o módulo aberto, você deve ver suas alterações nos painéis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.css"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar"
        }), ". Uma nova guia será aberta para exibir a visualização do módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Module-Preview-7.png",
        alt: "Exemplo de visualização do módulo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Recapitulando este tutorial até agora, você:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "criou um módulo no HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Baixou esse módulo para o seu ambiente local."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fez alterações no HubL + HTML e CSS usando suas ferramentas de desenvolvimento local."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Na próxima parte deste tutorial, saiba como usar o módulo em um modelo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar o módulo a um modelo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para esta parte do tutorial, você trabalhará principalmente com as pastas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modules"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), " nos arquivos do tema local.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/theme-folder-structure-getting-started-with-modules0.png",
        alt: "theme-folder-structure-getting-started-with-modules0"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por sua definição mais básica, os módulos são áreas editáveis de modelos e páginas da HubSpot. Você pode inserir módulos em modelos no HubSpot usando o gerenciador de design, mas aqui usaremos o HubL para adicionar módulos a um modelo em seu ambiente local."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No editor de código, abra a pasta ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "templates"
          }), " e, em seguida, abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), ", acesse a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), " final, que começa na linha 28. Você adicionará o seu novo módulo a esta seção."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/home-html-add-testimonial-module-to-section0.png",
            alt: "home-html-add-testimonial-module-to-section0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Em ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), " e acima dos outros ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_modules"
          }), ", copie e cole a seguinte tag de módulo HubL:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path= “../modules/Testimonial.module”,\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essa tag HubL faz referência ao seu novo módulo pelo caminho relativo de arquivo. Para que o módulo se ajuste de forma uniforme aos outros dois módulos na ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), ", também atribui valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " ao módulo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O CMS da HubSpot usa um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview#columns",
          children: "sistema de grade de 12 colunas"
        }), "; portanto, para espaçar este módulo de forma uniforme com os outros dois, você precisará atualizar a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " de cada módulo para uma largura igual a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Em seguida, o primeiro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " no grupo (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), ") terá um deslocamento de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " para posicioná-lo primeiro em lugar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O segundo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linked_image"
        }), ") terá um deslocamento de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), " para posicioná-lo em segundo lugar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O terceiro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "rich_text"
        }), ") terá um deslocamento de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "8"
        }), " para posicioná-lo em terceiro lugar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de definir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " para cada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", seu código será semelhante ao seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n    background_color=\"#f8fafc\",\n    vertical_alignment=\"MIDDLE\"\n  %}\n\n    {% dnd_module\n      path= “../modules/Testimonial.module”,\n      offset=0,\n      width=4\n    %}\n    {% end_dnd_module %}\n\n    {% dnd_module\n      path=\"@hubspot/linked_image\",\n      img={\n        \"alt\": \"Stock placeholder image with grayscale geometrical mountain landscape\",\n        \"loading\": \"lazy\",\n        \"max_height\": 451,\n        \"max_width\": 605,\n        \"size_type\": \"auto_custom_max\",\n        \"src\": get_asset_url(\"../images/grayscale-mountain.png\")\n      },\n      offset=4,\n      width=4\n    %}\n    {% end_dnd_module %}\n    {% dnd_module\n      path=\"@hubspot/rich_text\",\n      html=\"<h2>Provide more details here.</h2><p>Use text and images to tell your company’s story. Explain what makes your product or service extraordinary.</p>\"\n      offset=8,\n      width=4\n    %}\n    {% end_dnd_module %}\n  {% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Ao adicionar um módulo a uma área de arrastar e soltar, a tag do módulo ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " exige um nome exclusivo. No entanto, ao adicionar um módulo a um arquivo HTML fora das áreas de arrastar e soltar, você deve atribuir ao módulo um nome exclusivo. Você também usaria uma sintaxe ligeiramente diferente, por exemplo:"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% module \"testimonial_one\" path=\"../modules/Testimonial.module\" %}"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "usar módulos em modelos"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualizar suas alterações no HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você não tiver mantido o comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " em execução para rastrear seu trabalho automaticamente, execute ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <dest>"
        }), ". Certifique-se de que esse comando continue em execução ao concluir as próximas etapas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, abra o gerenciador de design (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivos e modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ferramentas de design"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda do gerenciador de design, selecione o arquivo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "home.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualização em tempo real com opções de exibição"
        }), " para abrir a visualização do modelo em uma nova janela."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Live-Preview-10.png",
        alt: "Exemplo de visualização em tempo real"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Na nova guia, confira a visualização do modelo, que deve conter o módulo de depoimentos recém-adicionado."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-added-to-theme.png",
        alt: "testimonial-module-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Personalizar o módulo no modelo localmente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para tornar o modelo da página inicial mais interessante:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Retorne ao editor de códigos e abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "home.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adicione os seguintes parâmetros à tag do módulo de depoimentos:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n testimonial = \"Wow, what a product! I can't wait to recommend this to all my family and friends!\",\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os parâmetros acima substituem os valores padrão que você atribuiu originalmente aos três campos. Cada parâmetro usa o nome da variável do HubL que você atribuiu a cada campo anteriormente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "customer_name"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " este parâmetro passa o nome ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Mary"
        }), " para o campo de nome do cliente, substituindo o valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sally"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "profile_pic"
        }), "**: **este parâmetro é um objeto que contém duas propriedades.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["A propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "src"
            }), " usa a função do HubL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "get_asset_url"
            }), " para recuperar o URL da nova imagem de perfil. Observe que o caminho do arquivo de imagem é relativo ao seu diretório de trabalho. Primeiro, você precisará adicionar essa imagem à pasta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " nos arquivos do tema local. Você pode encontrar a imagem na pasta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " nos", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
              children: "arquivos do projeto concluído."
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["A propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " atribui o texto alternativo da imagem."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " este parâmetro passa um novo texto para o campo de depoimentos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alternativamente, para o campo de rich text, você pode usar a sintaxe de bloco do HubL para escrever um grande bloco de HTML ou texto:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n offset=0,\n width=4\n%}\n {% module_attribute \"testimonial\" %}\n   Wow, what a product! I can't wait to recommend this to all my family and friends!\n {% end_module_attribute %}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "sintaxe de bloco do módulo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você manteve o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " em execução no seu terminal, salve as alterações para enviá-las para o HubSpot. Você pode voltar ao gerenciador de design para visualizar o modelo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Mary-Module-in-Template-12.png",
        alt: "Módulo Mary em modelos"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar mais dois módulos de depoimentos ao modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nesta etapa, você adicionará mais dois módulos de depoimentos ao modelo usando as mesmas etapas anteriores:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Acesse o editor de códigos e, em seguida, abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "No módulo de depoimentos que você adicionou anteriormente, adicione outra instância do módulo, copiando e colando o código desse módulo. No novo módulo de depoimentos, especifique os seguintes detalhes usando as mesmas etapas acima:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["O nome do cliente é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["O depoimento de Ollie diz: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "I can't believe that I ever conducted business without the use of this product!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para a imagem de Ollie, adicione o caminho de arquivo relativo para o arquivo dentro da pasta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), ". Você pode obter a imagem nos ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
              children: "arquivos do projeto concluído"
            }), ". Em seguida, dê à imagem de Ollie o atributo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para manter o espaçamento, defina ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Abaixo do segundo módulo de depoimento, adicione um terceiro com os seguintes detalhes:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["O nome do cliente é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["O depoimento de Erin diz: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "My business has nearly tripled since I began to use this product! Don't wait, start now!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para a imagem de Erin, adicione o caminho de arquivo relativo para o arquivo dentro da pasta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), ". Em seguida, dê à imagem de Erin o atributo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para manter o espaçamento, defina ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "8"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Salve suas alterações."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você manteve o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " em execução no seu terminal, volte ao Gerenciador de design para visualizar as alterações no modelo. A visualização do modelo deve conter todos os três módulos de depoimentos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-modules-added-to-theme.png",
        alt: "testimonial-modules-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como etapa final, você preparará o tema para uso em uma página ativa, modificando o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modificar o nome do tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se quiser modificar o nome padrão do tema, você pode fazer isso configurando os campos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example theme.json\n\n{\n  \"name\": \"my_first_theme\",\n  \"label\": \"My first theme\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de adicionar esse código, salve suas alterações para que o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " envie suas alterações para o HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Próximas etapas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você criou um módulo personalizado, adicionou-o ao modelo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "home.html"
      }), " e, opcionalmente, personalizou o nome do tema, está pronto para criar uma página ativa a partir do tema."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se quiser seguir um tutorial semelhante criado especificamente para temas, confira o tutorial ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/getting-started",
        children: "Introdução aos temas"
      }), " a seguir. Caso contrário, você pode saber mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
        children: "criar e personalizar páginas"
      }), " na Central de conhecimento da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ou, se você quiser saber mais sobre os módulos, confira os seguintes guias:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurando um módulo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Usar módulos em modelos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Módulos padrão"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Outros tutoriais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Introdução aos temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Introdução às funções sem servidor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Criar um fluxo de trabalho de desenvolvedor eficaz"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Introdução à acessibilidade"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/js-frameworks",
          children: "Como usar estruturas JavaScript no CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Como usar componentes da Web em módulos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-build-a-code-block-web-component",
          children: "Como criar um módulo baseado em componentes da Web com bloco de código"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}