"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937503;
const slug = exports.slug = 'guides/api/crm/extensions/calling-sdk';
const title = exports.title = 'API du CRM | Extensions du kit de développement logiciel pour les appels';
const name = exports.name = `vNext Docs DP - Kit de développement logiciel pour les extensions d'appel`;
const metaDescription = exports.metaDescription = `Profitez d'une vue d'ensemble du kit de développement logiciel pour les extensions d'appel`;
const toc = exports.toc = [{
  "depth": 0,
  "id": "ex%C3%A9cuter-l-application-d-appel-de-d%C3%A9monstration",
  "label": "Exécuter l'application d'appel de démonstration",
  "parent": null
}, {
  "depth": 1,
  "id": "installer-l-application-d-appel-de-d%C3%A9monstration",
  "label": "Installer l'application d'appel de démonstration",
  "parent": "ex%C3%A9cuter-l-application-d-appel-de-d%C3%A9monstration"
}, {
  "depth": 1,
  "id": "lancer-l-application-d-appel-de-d%C3%A9monstration-depuis-hubspot",
  "label": "Lancer l'application d'appel de démonstration depuis HubSpot",
  "parent": "ex%C3%A9cuter-l-application-d-appel-de-d%C3%A9monstration"
}, {
  "depth": 0,
  "id": "installer-le-kit-de-d%C3%A9veloppement-logiciel-pour-les-extensions-d-appel-sur-votre-application-d-appel",
  "label": "Installer le kit de développement logiciel pour les extensions d'appel sur votre application d'appel",
  "parent": null
}, {
  "depth": 0,
  "id": "utilisation-du-kit-de-d%C3%A9veloppement-logiciel-pour-les-extensions-d-appel",
  "label": "Utilisation du kit de développement logiciel pour les extensions d'appel",
  "parent": null
}, {
  "depth": 0,
  "id": "tester-votre-application",
  "label": "Tester votre application",
  "parent": null
}, {
  "depth": 1,
  "id": "via-le-point-de-terminaison-des-param%C3%A8tres-d-appel",
  "label": "Via le point de terminaison des paramètres d'appel",
  "parent": "tester-votre-application"
}, {
  "depth": 1,
  "id": "remplacer-vos-param%C3%A8tres-d-extension-%C3%A0-l-aide-de-localstorage",
  "label": "Remplacer vos paramètres d'extension à l'aide de localStorage",
  "parent": "tester-votre-application"
}, {
  "depth": 0,
  "id": "pr%C3%A9parer-votre-application-pour-la-production",
  "label": "Préparer votre application pour la production",
  "parent": null
}, {
  "depth": 0,
  "id": "publier-votre-application-d-appel-dans-le-marketplace-de-hubspot",
  "label": "Publier votre application d'appel dans le marketplace de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9v%C3%A9nements-termin%C3%A9s",
  "label": "Événements terminés",
  "parent": null
}, {
  "depth": 1,
  "id": "envoi-de-messages-%C3%A0-hubspot",
  "label": "Envoi de messages à HubSpot",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "initialis%C3%A9",
  "label": "initialisé",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "userloggedin",
  "label": "userLoggedIn",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "userloggedout",
  "label": "userLoggedOut",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "outgoingcall",
  "label": "outgoingCall",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "callanswered",
  "label": "callAnswered",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "callended",
  "label": "callEnded",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "callcompleted",
  "label": "callCompleted",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "senderror",
  "label": "sendError",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "resizewidget",
  "label": "resizeWidget",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 1,
  "id": "recevoir-des-messages-de-hubspot",
  "label": "Recevoir des messages de HubSpot",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "onready",
  "label": "onReady",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "ondialnumber",
  "label": "onDialNumber",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "onengagementcreated",
  "label": "onEngagementCreated",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "oncreateengagementsucceeded",
  "label": "onCreateEngagementSucceeded",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "oncreateengagementfailed",
  "label": "onCreateEngagementFailed",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "onvisibilitychanged",
  "label": "onVisibilityChanged",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 2,
  "id": "defaulteventhandler",
  "label": "defaultEventHandler",
  "parent": "%C3%A9v%C3%A9nements-termin%C3%A9s"
}, {
  "depth": 0,
  "id": "kit-de-d%C3%A9veloppement-logiciel-pour-les-appels-%7C-questions-fr%C3%A9quentes",
  "label": "Kit de développement logiciel pour les appels | Questions fréquentes",
  "parent": null
}, {
  "depth": 0,
  "id": "comment-l%E2%80%99authentification-des-utilisateurs-est-elle-g%C3%A9r%C3%A9e%C2%A0%3F",
  "label": "Comment l’authentification des utilisateurs est-elle gérée ?",
  "parent": null
}, {
  "depth": 0,
  "id": "les-extensions-d%E2%80%99appels-sont-elles-h%C3%A9berg%C3%A9es-sur-un-r%C3%A9seau-de-diffusion-de-contenu%C2%A0%3F",
  "label": "Les extensions d’appels sont-elles hébergées sur un réseau de diffusion de contenu ?",
  "parent": null
}, {
  "depth": 0,
  "id": "quand-faut-il-cr%C3%A9er-ou-mettre-%C3%A0-jour-un-engagement%C2%A0%3F",
  "label": "Quand faut-il créer ou mettre à jour un engagement ?",
  "parent": null
}, {
  "depth": 0,
  "id": "quels-sont-les-p%C3%A9rim%C3%A8tres-d-acc%C3%A8s-requis-dans-le-cadre-de-l%E2%80%99int%C3%A9gration%C2%A0%3F",
  "label": "Quels sont les périmètres d'accès requis dans le cadre de l’intégration ?",
  "parent": null
}, {
  "depth": 0,
  "id": "cette-fonctionnalit%C3%A9-peut-elle-%C3%AAtre-ajout%C3%A9e-%C3%A0-une-application-d%C3%A9j%C3%A0-existante-sur-le-marketplace-ou-dois-je-cr%C3%A9er-une-nouvelle-application%C2%A0%3F",
  "label": "Cette fonctionnalité peut-elle être ajoutée à une application déjà existante sur le marketplace ou dois-je créer une nouvelle application ?",
  "parent": null
}, {
  "depth": 0,
  "id": "puis-je-int%C3%A9grer-mon-application-de-t%C3%A9l%C3%A9phone-logiciel-existante-dans-le-kit-de-d%C3%A9veloppement-logiciel%C2%A0%3F",
  "label": "Puis-je intégrer mon application de téléphone logiciel existante dans le kit de développement logiciel ?",
  "parent": null
}, {
  "depth": 0,
  "id": "les-utilisateurs-peuvent-ils-utiliser-plusieurs-int%C3%A9grations-en-m%C3%AAme-temps%C2%A0%3F",
  "label": "Les utilisateurs peuvent-ils utiliser plusieurs intégrations en même temps ?",
  "parent": null
}, {
  "depth": 0,
  "id": "les-utilisateurs-gratuits-peuvent-ils-installer-des-int%C3%A9grations-d%E2%80%99applications%C2%A0%3F",
  "label": "Les utilisateurs gratuits peuvent-ils installer des intégrations d’applications ?",
  "parent": null
}, {
  "depth": 0,
  "id": "si-un-utilisateur-a-d%C3%A9j%C3%A0-install%C3%A9-mon-application%2C-l%E2%80%99int%C3%A9gration-appara%C3%AEt-elle-automatiquement%C2%A0%3F",
  "label": "Si un utilisateur a déjà installé mon application, l’intégration apparaît-elle automatiquement ?",
  "parent": null
}, {
  "depth": 0,
  "id": "n-importe-quel-utilisateur-peut-il-installer-ou-d%C3%A9sinstaller-une-application%C2%A0%3F",
  "label": "N'importe quel utilisateur peut-il installer ou désinstaller une application ?",
  "parent": null
}, {
  "depth": 0,
  "id": "puis-je-cr%C3%A9er-une-propri%C3%A9t%C3%A9-d%E2%80%99appel-personnalis%C3%A9e%C2%A0%3F",
  "label": "Puis-je créer une propriété d’appel personnalisée ?",
  "parent": null
}, {
  "depth": 0,
  "id": "puis-je-passer-un-appel-%C3%A0-partir-d%E2%80%99un-objet-personnalis%C3%A9%C2%A0%3F",
  "label": "Puis-je passer un appel à partir d’un objet personnalisé ?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      ul: "ul",
      h3: "h3",
      code: "code",
      pre: "pre",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Kit de développement logiciel pour les extensions d'appel"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " nos partenaires d'application d'appel n'ont plus besoin de créer et de mettre à jour manuellement les engagements d'appel ; HubSpot s'en chargera pour eux. Découvrez-en davantage ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "ici"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/calling-extensions-sdk",
        children: "kit de développement logiciel pour les extensions d'appel"
      }), " permet aux applications de proposer une option d'appel personnalisée pour les utilisateurs HubSpot directement à partir d'une fiche d'informations dans le CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une extension d'appel comprend trois éléments principaux :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk",
          children: "kit de développement logiciel pour les extensions d'appel"
        }), ", un kit de développement logiciel pour JavaScript qui permet la communication entre votre application et HubSpot ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "points de terminaison des paramètres d'appel"
        }), ", qui sont utilisés pour définir les paramètres d'appel pour votre application. Chaque compte HubSpot qui connectera votre application utilisera ces paramètres."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["L'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "iFrame d'appel"
        }), ", qui est l'endroit où votre application apparaît pour les utilisateurs HubSpot et est configuré à l'aide des points de terminaison des paramètres d'appel."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour plus d'informations sur l'expérience d'appel dans l'application, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool#call-from-your-phone",
        children: "cet article de base de connaissances"
      }), ". Une fois que votre application compatible avec les extensions d'appel est connectée à HubSpot, elle apparaîtra comme option dans le sélecteur d'appel lorsque un utilisateur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
        children: "effectue un appel depuis HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous n'avez pas d'application, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#app-developer-accounts",
        children: "en créer une depuis votre compte de développeur HubSpot"
      }), ". Si vous n'avez pas encore de compte de développeur HubSpot, vous pouvez vous inscrire ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " seuls les appels sortants sont actuellement pris en charge."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exécuter l'application d'appel de démonstration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez tester le kit de développement logiciel pour les extensions d'appel sur deux applications de démonstration différentes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-minimal-js",
          children: "Demo-minimal-js"
        }), " propose une implémentation minimale du kit de développement logiciel en utilisant JavaScript, HTML et CSS. Affichez la façon dont le kit de développement logiciel est instancié dans ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/project-demo-v1/demos/demo-minimal-js/index.js",
          children: " index.js"
        }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-react-ts",
          children: "Demo-react-ts"
        }), " propose une implémentation réelle du kit de développement logiciel à l'aide de React, TypeScript et Styled Components pour servir de modèle à votre application. Affichez la façon dont le kit de développement logiciel est instancié dans ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/master/demos/demo-react-ts/src/hooks/useCti.ts",
          children: "useCti.ts"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " ces applications de démonstration ne sont pas entièrement fonctionnelles et utilisent des données factices pour offrir une expérience plus réaliste."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Installer l'application d'appel de démonstration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez exécuter les applications de démonstration avec ou sans installation. Pour installer la démo sur votre environnement local :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Installez ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://nodejs.org/en",
            children: " Node.js "
          }), " sur votre environnement."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clonez, bifurquez ou ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/calling-extensions-sdk/archive/refs/heads/master.zip",
            children: "téléchargez le ZIP"
          }), " de ce référentiel."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ouvrez votre terminal et accédez au répertoire racine du projet."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Exécutez l'une des commandes suivantes :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour les ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), " :"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-minimal-js && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), " :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-react-ts && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Celles-ci basculeront vers le répertoire de démonstration souhaité, installeront les dépendances ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " requises pour le projet à l'aide de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.npmjs.com/cli/v9",
        children: "ILC npm"
      }), " et démarreront l'application."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " La commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm start"
        }), " ouvrira automatiquement un nouvel onglet dans votre navigateur à l'adresse ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://localhost:9025/",
          children: "https://localhost:9025/"
        }), " et vous devrez peut-être contourner un avertissement « Votre connexion n'est pas sécurisée » pour accéder à l'application."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Lancer l'application d'appel de démonstration depuis HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Accédez à vos fiches d'informations :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contacts :"
            }), " dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contacts > Contacts"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["**Entreprise : **dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contacts > Entreprises"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ouvrez la console de développeur de votre navigateur et exécutez la commande suivante :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous avez terminé les étapes d'installation, pour ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-react-ts"
            }), " :"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'local');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous avez ignoré les étapes d'installation :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour les ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), " :"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app:js');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), " :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Actualisez la page et cliquez sur l'icône ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Appel"
          }), " dans la barre latérale de gauche. Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Appel de"
          }), " et sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom"
          }), " de l'application de démonstration à l'étape 2 (par exemple : Demo App Local, Demo App JS, Demo App React)."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/call-app-in-record.png",
            alt: "call-app-in-record"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Appel"
          }), " pour voir comment l'application de démonstration s'intègre à HubSpot via le kit de développement logiciel pour les extensions d'appel. Vous pouvez également voir les événements enregistrés sur la console de développeur de votre navigateur."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/calling-sdk-in-app.png",
        alt: "calling-sdk-in-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Installer le kit de développement logiciel pour les extensions d'appel sur votre application d'appel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour ajouter le kit de développement logiciel pour les extensions d'appel en tant que dépendance ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " à votre application d'appel :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pour npm, exécutez :"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i --save @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pour yarn, exécutez :"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "yarn add @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation du kit de développement logiciel pour les extensions d'appel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le kit de développement logiciel pour les extensions d'appel expose une API simple pour HubSpot et une application d'appel pour échanger des messages. Les messages sont envoyés via des méthodes exposées par le kit de développement logiciel et reçus via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), ". Vous pouvez trouver une liste complète des événements disponibles dans la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events",
        children: "section Événements"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici une description des événements :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Numérotation :"
        }), " HubSpot envoie l'événement de numérotation."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Appel sortant démarré :"
        }), " l'application informe HubSpot lorsque l'appel a démarré."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un engagement :"
        }), " HubSpot crée", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: " un engagement d'appel"
        }), " avec un minimum d'informations si l'application le demande."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Engagement créé :"
        }), " HubSpot a créé un engagement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "EngagementId envoyé à l'application :"
        }), " HubSpot envoie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), " à l'application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fin de l'appel :"
        }), " l'application informe lorsque l'appel est terminé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Appel terminé :"
        }), " l'application informe lorsque l'utilisateur a terminé l'expérience utilisateur de l'application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mettre à jour l'engagement :"
        }), " l'application récupère l'engagement avec ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), ", puis fusionne et met à jour l'engagement avec des détails d'appel supplémentaires. Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls#update-calls",
          children: "mise à jour d'un engagement d'appel via l'API"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "via le kit de développement logiciel"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour commencer, créez une instance de l’objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CallingExtensions"
      }), ". Vous pouvez définir le comportement de votre extension en fournissant l'objet d'une option lorsque vous créez votre instance d'extensions. L'objet de cette option fournit un champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), " dans lequel vous pouvez spécifier le comportement de votre extension. Le bloc de code suivant illustre les options disponibles et les gestionnaires d'événements que vous pouvez définir :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import CallingExtensions from \"@hubspot/calling-extensions-sdk\";\n\nconst options = {\n  /** @property {boolean} debugMode - Whether to log various inbound/outbound debug messages to the console. If false, console.debug will be used instead of console.log */\n  debugMode: boolean,\n  // eventHandlers handle inbound messages\n  eventHandlers: {\n    onReady: () => {\n      /* HubSpot is ready to receive messages. */\n    },\n    onDialNumber: event => {\n      /* HubSpot sends a dial number from the contact */\n    },\n    onCreateEngagementSucceeded: event => {\n      /* HubSpot has created an engagement for this call. */\n    }\n    onEngagementCreatedFailed: event => {\n      /* HubSpot has failed to create an engagement for this call. */\n    }\n    onUpdateEngagementSucceeded: event => {\n      /* HubSpot has updated an engagement for this call. */\n    },\n    onUpdateEngagementFailed: event => {\n      /* HubSpot has failed to update an engagement for this call. */\n    }\n    onVisibilityChanged: event => {\n      /* Call widget's visibility is changed. */\n    }\n  }\n};\n\nconst extensions = new CallingExtensions(options);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tester votre application"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Afin de lancer les extensions d'appel iFrame pour les utilisateurs finaux, HubSpot nécessite les paramètres iFrame suivants."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n   name: string /* The name of your calling app to display to users. */,\n   url: string  /* The URL of your calling app, built with the Calling Extensions SDK */,\n   width: number /* The iFrame's width */,\n   height: number /* The iFrame's height */,\n   isReady: boolean /* Whether the widget is ready for production (defaults to true) */,\n   supportsCustomObjects : true /* Whether calls can be placed from a custom object */\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Via le point de terminaison des paramètres d'appel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Via votre outil d'API (par exemple : Postman), envoyez la charge utile suivante à l'API de paramètres de HubSpot. Assurez-vous d'obtenir l'élément APP_ID de votre application d'appel et de votre application ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Remarque : **L'indicateur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isReady"
        }), " indique si l'application est prête pour la production. Il doit être défini sur false lors des tests."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":false}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remplacer vos paramètres d'extension à l'aide de localStorage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez remplacer n'importe lequel de vos paramètres d'extension à des fins de test. Ouvrez la console du développeur de votre navigateur à partir d'un onglet HubSpot, modifiez les paramètres ci-dessous et exécutez la commande :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const myExtensionSettings = {\n  isReady: true,\n  name: 'My app name',\n  url: 'My local/qa/prod URL',\n};\n\nlocalStorage.setItem(\n  'LocalSettings:Calling:CallingExtensions',\n  JSON.stringify(myExtensionSettings)\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Préparer votre application pour la production"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez déjà utilisé le point de terminaison POST lors du test de votre application, vous pouvez utiliser le point de terminaison PATCH pour définir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady"
      }), " sur true. Autrement, via votre outil d'API (par exemple : Postman), envoyez cette charge utile à notre API de paramètres. Assurez-vous d'obtenir l'élément APP_ID de votre application d'appel et de votre application ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":true}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Publier votre application d'appel dans le marketplace de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois votre application configurée, la dernière étape consiste à lister votre application d'appel dans le marketplace HubSpot. Vous trouverez plus de détails ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "ici"
      }), ". Vous pouvez également choisir de ne pas la lister sur le marketplace si cette application est à usage interne uniquement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Événements terminés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Événements d’appel disponibles :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Envoyer des messages à HubSpot", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#demo-minimal-js"
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "#yarn"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#initialized",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "initialized"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedIn",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedIn"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedOut"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut"
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#outgoingCall",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "outgoingCall"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callAnswered",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callAnswered"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callEnded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callEnded"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callCompleted"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#sendError",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "sendError"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#resizeWidget",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "resizeWidget"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Recevoir des messages de HubSpot :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onReady",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onReady"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onDial",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onDialNumber"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onEngagementCreated"
              })
            }), " (obsolète)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onCreateEngagementSucceeded"
              })
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onUpdateEngagementFailed"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onVisibilityChanged"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#defaultEventHandler",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "defaultEventHandler"
              })
            })]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Envoi de messages à HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " fournit les gestionnaires d'événements suivants que vous pouvez appeler pour envoyer des messages à HubSpot ou pour spécifier un autre comportement associé. Consultez l'exemple ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "initialisé"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoyez un message indiquant que le téléphone logiciel est prêt pour l’interaction."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const payload = {\n  isLoggedIn: boolean,\n};\n\nextensions.initialized(payload);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isLoggedIn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet de vérifier si un utilisateur est connecté ou non."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedIn"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoie un message indiquant que l'utilisateur s'est connecté."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// This message is only needed when user isn't logged in when initialized\nextensions.userLoggedIn();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedOut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoie un message indiquant que l'utilisateur s'est déconnecté."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.userLoggedOut();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "outgoingCall"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoie un message pour informer HubSpot qu'un appel sortant a commencé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string /** @deprecated Use toNumber instead **/,\n  callStartTime: number,\n  createEngagement: true,\n  toNumber: string,\n  fromNumber: string,\n};\nextensions.outgoingCall(callInfo);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callStartTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'heure de début de l’appel, en millisecondes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createEngagement"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : si HubSpot doit créer un engagement pour cet appel ou non. Si la valeur est définie sur vrai, HubSpot répondra avec ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: "onCreateEngagementSucceeded"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: "onCreateEngagementFailed"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le numéro de téléphone du destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le numéro de téléphone du correspondant. Paramètre obligatoire."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callAnswered"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoie un message pour informer HubSpot qu'un appel sortant est en cours de prise en charge."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.callAnswered();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callEnded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoie un message pour informer HubSpot que l'appel a pris fin."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call ended event, the user can navigate away, can close the call widget.\nextensions.callEnded({\n  engagementId: number,\n  callEndStatus: EndStatus,\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un ID d’engagement créé par HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callEndStatus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Énumération"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le statut de l'appel lorsqu'il se termine. Statuts disponibles :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_COMPLETED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_FAILED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_CANCELED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_BUSY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_NO_ANSWER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_REJECTED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_MISSED"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callCompleted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Envoie un message pour informer HubSpot que l'appel est terminé. Les propriétés d'engagement ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/calls",
        children: "appartiennent à HubSpot"
      }), " et n'ont plus besoin d'être créées ou mises à jour manuellement (voir les éléments en surbrillance)."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hideWidget"
        }), " sera ignorée lorsque l'utilisateur se trouvera dans une file d'attente de tâches avec le type de tâche ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Call"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call completed event, HubSpot will\n//   1) insert the engagement into the timeline\n//   2) set the default associations on the engagement\n//   3) closes the widget unless `hideWidget` is set to false.\n//   4) update the engagement with any engagement properties\nconst data = {\n  engagementId: number,\n  hideWidget: boolean,\n  engagementProperties: { [key: string]: string },\n  externalCallId: number,\n}\nextensions.callCompleted(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un ID d’engagement créé par HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hideWidget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'indiquer si le widget doit être masqué lorsque l’appel est terminé. Paramètre facultatif. La valeur par défaut est true."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de vous inscrire aux engagements détenus hs en ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/calls#properties",
              children: "ajoutant des propriétés"
            }), ". Entraîne une réponse de HubSpot avec ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementSucceeded",
              children: "onUpdateEngagementSucceeded"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementFailed",
              children: "onUpdateEngagementFailed"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalCallId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : une application d’appel a créé l’ID d’appel. Permet d’activer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/third-party-calling",
              children: "les appels dans le centre de support"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendError"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoie un message pour informer HubSpot que l'application d'appel a rencontré une erreur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the sendError event, HubSpot will display an alert popup to the user with the error message provided.\nconst data = {\n  message: string,\n};\nextensions.sendError(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un message d’erreur à afficher dans la fenêtre contextuelle d’alerte."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "resizeWidget"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envoie un message pour informer HubSpot que l'application d'appel doit être redimensionnée."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the resizeWidget event, HubSpot will use the provided height and width to resize the call widget.\nconst data = {\n  height: number,\n  width: number,\n};\nextensions.resizeWidget(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la hauteur souhaitée du widget d’appel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la largeur souhaitée du widget d’appel."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Recevoir des messages de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " fournit les gestionnaires d'événements suivants que vous pouvez appeler pour recevoir des messages dans HubSpot ou pour spécifier un autre comportement associé. Consultez l'exemple ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onReady"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Message indiquant que HubSpot est prêt à recevoir des messages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example snippet for handling onReady event\nonReady() {\n    extensions.initialized(payload);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onDialNumber"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cet événement se déclenche lorsqu’un utilisateur de HubSpot déclenche un appel sortant. L’événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onDialNumber"
      }), " fournira une charge utile qui comprendra tous les champs pertinents associés à l’appel. Ces champs sont détaillés dans le tableau ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onDialNumber(data) {\n  const {\n    phoneNumber: string,\n    ownerId: number,\n    subjectId: number,\n    objectId: number,\n    objectType: CONTACT | COMPANY,\n    portalId: number,\n    countryCode: string,\n    calleeInfo {\n        calleeId: number,\n        calleeObjectTypeId: string,\n    },\n    startTimestamp: number,\n    toPhoneNumberSrc: string,\n   } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Propriété | Type | Description |\n| --- | --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "phoneNumber"
      }), " | Chaîne |  : le numéro de téléphone de la personne que l’utilisateur HubSpot a appelée. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ownerId"
      }), " | Numéro | Chaîne |  : l’ID de l’utilisateur connecté (dans HubSpot). |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subjectId"
      }), " | Nombre |  : l’ID du sujet. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " | Nombre |  : le type d’objet du numéro de téléphone. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " | Chaîne |  : le type d’objet associé au numéro de téléphone composé (par exemple : le numéro de téléphone du contact ou de l’entreprise). Les valeurs possibles sont : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"CONTACT\""
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"COMPANY\""
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " | Nombre |  : l’ID du portail HubSpot. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "countryCode"
      }), " | Chaîne |  : le code du pays du numéro de téléphone. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calleeInfo"
      }), " | Tableau |  : les informations sur la personne appelée. Doit inclure les éléments suivants :", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeID: number"
          })
        }), (0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeObjectTypeId: string"
          })
        })]
      }), " |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "startTimestamp"
      }), " | Nombre |  : l'horodatage du début de l’appel. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toPhoneNumberSrc"
      }), " | Chaîne |  : le nom de la propriété des numéros de téléphone' ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/create-and-edit-properties",
        children: "dans HubSpot"
      }), ". La propriété peut être une valeur standard ou une propriété personnalisée. Par exemple, un contact peut avoir trois numéros de téléphone, l’un peut être libellé ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Bureau"
      }), ", un autre ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Personnel"
      }), " et le dernier ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Portable"
      }), ". |"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onEngagementCreated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obsolète. Utilisez-le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#onCreateEngagementSucceeded",
        children: "onCreateEngagementSucceeded"
      }), " à la place."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/** @deprecated Use onCreateEngagementSucceeded instead **/\nonEngagementCreated(data) {\n    const {\n      engagementId: number,\n    } = data;\n      ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un ID d’engagement créé par HubSpot."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementSucceeded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot envoie un message pour informer le partenaire d'application d'appel que la mise à jour de l'engagement a réussi."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementSucceeded: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementFailed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot envoie un message pour informer le partenaire d'application d'appel que la création de l'engagement a échoué."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementFailed: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onVisibilityChanged"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Message indiquant si l'utilisateur a minimisé ou masqué l'application d'appel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onVisibilityChanged(data) {\n    const { isMinimized, isHidden } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "defaultEventHandler"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gestionnaire par défaut pour les événements."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "defaultEventHandler(event) {\n   console.info(\"Event received. Do you need to handle it?\", event);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kit de développement logiciel pour les appels | Questions fréquentes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment l’authentification des utilisateurs est-elle gérée ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'application d'appel doit gérer l'authentification."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Les extensions d’appels sont-elles hébergées sur un réseau de diffusion de contenu ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Oui. Vous pouvez installer le kit de développement logiciel pour les extensions d'appel via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.jsdelivr.com/",
        children: "jsDeliver"
      }), ". Par exemple, pour installer call-extensions-sdk@0.2.2, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js",
        children: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quand faut-il créer ou mettre à jour un engagement ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un utilisateur peut lancer un appel à partir de l'interface utilisateur HubSpot, mais également en dehors (par exemple : application mobile, numéro redirigé, etc.). Si un appel est lancé à partir de l'interface utilisateur de HubSpot, HubSpot créera une interaction d'appel et enverra l'interaction à l'application d'appel. Une fois l'appel terminé, l'application d'appel peut mettre à jour cette interaction avec des détails d'appel supplémentaires. Si un appel est lancé en dehors de l'interface utilisateur HubSpot, l'application doit créer l'interaction d'appel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quels sont les périmètres d'accès requis dans le cadre de l’intégration ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'ajout des périmètres d'accès Contacts et Chronologie est requis. Ces périmètres d'accès garantissent que votre application a accès aux contacts, et peut créer et mettre à jour des interactions d'appel dans le CRM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cette fonctionnalité peut-elle être ajoutée à une application déjà existante sur le marketplace ou dois-je créer une nouvelle application ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous disposez déjà d'une application existante qui répond au cas d'utilisation d'appel, vous pouvez ajouter directement cette fonctionnalité à votre application existante. Tous les clients qui ont déjà votre application auront accès à cette nouvelle fonctionnalité sans avoir à installer l'application à nouveau."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Puis-je intégrer mon application de téléphone logiciel existante dans le kit de développement logiciel ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Oui, l'intégration de votre application de téléphone logiciel existante devrait être très facile. Il suffit de suivre les étapes décrites dans la documentation ci-dessus pour que votre application soit opérationnelle."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Les utilisateurs peuvent-ils utiliser plusieurs intégrations en même temps ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Oui, les utilisateurs peuvent utiliser plusieurs intégrations d'appel tierces en même temps. Ils peuvent utiliser le sélecteur de fournisseur qui s'affiche après avoir cliqué sur le bouton d'appel pour basculer facilement entre les fournisseurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Les utilisateurs gratuits peuvent-ils installer des intégrations d’applications ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Oui, tous les utilisateurs peuvent installer l'application."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Si un utilisateur a déjà installé mon application, l’intégration apparaît-elle automatiquement ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Oui, si un utilisateur a déjà installé votre application et que vous mettez à jour la même application avec les extensions d'appel, l'intégration s'affichera automatiquement. Actuellement, il n'est pas possible pour le développeur d'activer l'application d'appel uniquement pour un sous-ensemble de clients."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "N'importe quel utilisateur peut-il installer ou désinstaller une application ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Non, seuls les utilisateurs disposant des autorisations nécessaires peuvent installer et désinstaller une application. Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/edit-user-permissions",
        children: "consulter les autorisations d'un utilisateur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Puis-je créer une propriété d’appel personnalisée ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Oui, vous pouvez créer une propriété d'appel personnalisée à l'aide de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Puis-je passer un appel à partir d’un objet personnalisé ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Oui, les intégrations d'appel peuvent passer des appels à partir d'objets personnalisés tant qu'elles n'utilisent que le kit de développement logiciel pour créer l'appel. Chaque intégration devra vérifier qu'elle utilise uniquement le kit de développement logiciel d'appel pour créer des appels et notifier HubSpot lors de l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tout d'abord, vérifiez que l'intégration utilise le kit de développement logiciel pour les appels pour créer des engagements dans l'événement outgoingCall :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "outgoingCall({ createEngagement: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createEngagement"
      }), " est défini sur true, découvrez comment mettre à jour les informations de votre application ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-your-app-ready-for-production",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici l'exemple pour l'ensemble de l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string, // optional unless call is initiated by the widget\n  createEngagement: true // whether HubSpot should create an engagement for this call\n  callStartTime: number // optional unless call is initiated by the widget\n};\nextensions.outgoingCall(callInfo);\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}