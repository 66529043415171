"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890139;
const slug = exports.slug = 'guides/cms/debugging/troubleshooting';
const title = exports.title = 'Errores y depuración';
const name = exports.name = '[new] Debugging and Errors';
const metaDescription = exports.metaDescription = 'Entender cómo depurar y buscar errores al desarrollar sitios web en el CMS de HubSpot. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/fatal%20error%20-%20CMS%20CLI.png';
const featuredImageAltText = exports.featuredImageAltText = 'Error publishing template';
const toc = exports.toc = [{
  "depth": 0,
  "id": "errores",
  "label": "Errores",
  "parent": null
}, {
  "depth": 1,
  "id": "error-fatal",
  "label": "Error fatal",
  "parent": "errores"
}, {
  "depth": 1,
  "id": "anuncios",
  "label": "Anuncios",
  "parent": "errores"
}, {
  "depth": 0,
  "id": "hsdebug%3Dtrue",
  "label": "hsDebug=true",
  "parent": null
}, {
  "depth": 0,
  "id": "ver-la-salida-de-hubl",
  "label": "Ver la salida de HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "%7Cpprint",
  "label": "|pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "informaci%C3%B3n-del-desarrollador",
  "label": "Información del desarrollador",
  "parent": null
}, {
  "depth": 0,
  "id": "revisar-el-rendimiento-del-sitio-web-y-los-enlaces-rotos",
  "label": "Revisar el rendimiento del sitio web y los enlaces rotos",
  "parent": null
}, {
  "depth": 0,
  "id": "mejorar-la-velocidad-del-sitio-web",
  "label": "Mejorar la velocidad del sitio web",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      img: "img",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Errores y depuración"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depurar el código y entender dónde y cómo ver los errores es una parte importante del desarrollo en el CMS de HubSpot. Hay una serie de herramientas que puedes utilizar para aumentar la eficiencia en la construcción y depuración y para asegurarte de que tu sitio web está optimizado a medida que continúas construyéndolo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Errores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/key-concepts#developer-file-system",
        children: "sistema de archivos para desarrolladores"
      }), " del CMS de HubSpot tiene muchas formas de validación para asegurar que tus plantillas y módulos se muestren correctamente en las páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Error fatal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los errores fatales son errores que impedirían que una página se renderizara con éxito. Para asegurar que el contenido en vivo se represente correctamente, el CMS de HubSpot impide la publicación de plantillas que tengan errores fatales. Un ejemplo de error fatal sería la falta de variables HubL requeridas, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), ". Esto causará errores cuando se desarrolle en el Administrador de diseño o cuando se carguen archivos a través de la CLI del CMS. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "La extensión de VS code"
      }), " soporta HubL linting, y puede mostrar los errores fatales en contexto antes de cargar el archivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20design%20manager.png",
        alt: "captura de pantalla de un error fatal - administrador de diseño"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20CMS%20CLI.png",
        alt: "captura de pantalla de un error fatal - CMS CLI"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los errores fatales deben ser resueltos en orden para poder publicar los archivos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Anuncios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las advertencias son errores o problemas que no impiden la publicación de los archivos. Las advertencias suelen ser sugerencias de sintaxis o problemas potenciales que un desarrollador podría pasar por alto. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "La extensión de VS code"
      }), " soporta HubL linting, y puede mostrar las advertencias en contexto antes de cargar el archivo. Por ejemplo, si intentas incluir un archivo que no existe, se lanza una advertencia para alertar al desarrollador."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/warning%20-design%20manager.png",
        alt: "advertencia - administrador de diseño"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las advertencias nunca impedirán la publicación de archivos, sin embargo, se recomienda investigar las advertencias."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "hsDebug=true"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El CMS de HubSpot realiza una serie de optimizaciones en tu sitio web de forma automática. Para obtener más información sobre ellos, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "CDN, seguridad y rendimiento"
      }), ". Algunas veces, la depuración de problemas con estas optimizaciones en un sitio web en vivo puede ser difícil. Si cargas una página de HubSpot en vivo con la cadena de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), ", la página se renderizará:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Con archivos no minificados"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Con archivos CSS no combinados (archivos CSS individuales servidos)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sin servir archivos en caché"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ver la salida de HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dentro del Administrador de diseño, los archivos codificados tienen un botón \"Mostrar salida\" que abre un segundo panel del editor de código, con un código transpilado del archivo que se está viendo. Esto es útil para ver cómo su código HubL es transpilado en CSS, HTML o JavaScript, en lugar de recargar las páginas en vivo en las que se incluye el archivo. También es una herramienta útil para usar cuando se exploran nuevas características de HubL, o se aprenden los aspectos básicos de HubL, ya que puedes ver fácilmente lo que la entrada de HubL producirá."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/View%20HubL%20output.png",
        alt: "Ver la salida de HubL"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h2, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " HubL filter"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " HubL se puede utilizar en las variables HubL para imprimir información valiosa de depuración. Imprimirá el tipo de variable HubL, que puede ser útil para entender con qué expresiones, filtros, operadores o funciones se puede utilizar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ local_dt }}"
      }), " imprimirá ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "el 21 de febrero de 2020 a las 12:52:20"
      }), ". Si imprimimos bien esta variable, podemos ver que el valor es una fecha ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "(PyishDate: 2020-02-21 12:52:20)"
      }), ". Esto significa que podemos utilizar filtros HubL que operan o formatean objetos de fecha, como el filtro HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|datetimeformat"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|pprint }}\n{{ local_dt|datetimeformat('%B %e, %Y') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2020-02-21 12:55:13 (PyishDate: 2020-02-21 12:55:13) February 21, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Información del desarrollador"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Muchos de los datos que se encuentran en la información para desarrolladores se utilizan internamente y están sujetos a cambios si no están documentados."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La información para desarrolladores de una página es el contexto de todos los datos disponibles cuando se está renderizando una página. Todo este contexto de renderizado es accesible a través de HubL. Para acceder a la información del desarrollador de una página, selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "icono de la rueda dentada de HubSpot en la esquina superior derecha de las páginas en vivo > Información del desarrollador."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20sprocket%20menu.png",
        alt: "Menú de la rueda dentada de información para desarrolladores"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esto abrirá una nueva pestaña que devolverá el contexto de renderización de una página determinada en forma de JSON. Se recomienda tener un formateador JSON instalado en el navegador para facilitar la lectura de la información del desarrollador, como esta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa",
        children: "extensión de Chrome del formateador JSON"
      }), ". Aunque gran parte de la información contenida en el contexto de la página es para propósitos internos, esta herramienta puede ser valiosa para ver qué datos están disponibles a través de HubL cuando se planifica."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, la siguiente imagen es de la información del desarrollador para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "https://desigers.hubspot.com/docs/developer-reference/cdn."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20example.png",
        alt: "Ejemplo de información para desarrolladores"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los valores de estos datos se establecen a través de la pestaña Configuración del Editor de Contenidos:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/content%20editor%20-%20settings.png",
        alt: "editor de contenido - configuración"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los valores son entonces accesibles para renderizar en las páginas a través de HubL. Para imprimir el título y la metadescripción en una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "plantilla base"
      }), ", se utilizaría el siguiente HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<title>{{ page_meta.html_title }}</title>\n<meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los datos del contexto de renderización están disponibles a través de HubL, y el árbol JSON se puede recorrer utilizando la notación de puntos. Los datos de la información para desarrolladores que se imprimen con frecuencia incluyen los valores de los campos del módulo y las etiquetas que se han ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "exportado al contexto de la plantilla"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Revisar el rendimiento del sitio web y los enlaces rotos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es importante verificar que los visitantes de tu sitio no vayan a enlaces rotos. Hay dos herramientas que puedes utilizar para asegurarte de que los visitantes de tu sitio web llegan al lugar correcto. Puedes utilizar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/overview",
        children: "API de rendimiento del sitio web"
      }), " para obtener los estados HTTP como los 404 y ver el tiempo de actividad de tus sitios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si ves errores 404 es una buena idea redirigir al visitante a una URL relevante."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes utilizar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/seo/view-seo-recommendations-in-hubspot",
        children: "Herramienta de recomendaciones SEO"
      }), " para identificar los enlaces rotos dentro del contenido de tu página y arreglarlos rápidamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mejorar la velocidad del sitio web"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay muchos factores que intervienen en la optimización y prueba de la velocidad del sitio web. Para obtener herramientas y consejos para optimizar la velocidad de tu sitio web, consulta nuestra guía."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "841bbe22-70a6-4222-a6f0-85dd019711ae",
      label: "Optimize your CMS Hub site speed"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}