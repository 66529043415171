"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76685378321;
const slug = exports.slug = 'guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app';
const title = exports.title = 'APIキー連携を非公開アプリに移行する';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | Migrate an API key integration to a private app | 202304';
const metaDescription = exports.metaDescription = 'APIキー連携を非公開アプリに移行する方法についてご紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%81%93%E3%81%AE%E3%82%AC%E3%82%A4%E3%83%89%E3%81%AE%E5%86%85%E5%AE%B9",
  "label": "このガイドの内容",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%96%B0%E3%81%97%E3%81%84%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "新しい非公開アプリを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%80%A3%E6%90%BA%E3%81%AEapi%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E8%AA%8D%E8%A8%BC%E6%96%B9%E5%BC%8F%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "連携のAPIリクエストの認証方式を更新する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%82%92%E6%A4%9C%E8%A8%BC%E3%81%97%E3%80%81%E3%83%AD%E3%82%B0%E3%82%92%E7%9B%A3%E8%A6%96%E3%81%99%E3%82%8B",
  "label": "リクエストを検証し、ログを監視する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%AE%9F%E8%A3%85%E3%81%AE%E4%BE%8B",
  "label": "実装の例",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0",
  "label": "サーバーレス関数",
  "parent": "%E5%AE%9F%E8%A3%85%E3%81%AE%E4%BE%8B"
}, {
  "depth": 1,
  "id": "1%E5%9B%9E%E9%99%90%E3%82%8A%E3%81%AE%E3%82%B8%E3%83%A7%E3%83%96",
  "label": "1回限りのジョブ",
  "parent": "%E5%AE%9F%E8%A3%85%E3%81%AE%E4%BE%8B"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "カスタムオブジェクトの作成",
  "parent": "%E5%AE%9F%E8%A3%85%E3%81%AE%E4%BE%8B"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0-%E3%82%B3%E3%83%BC%E3%83%89-%E3%83%AF%E3%83%BC%E3%82%AF%E3%83%95%E3%83%AD%E3%83%BC-%E3%82%A2%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "カスタム コード ワークフロー アクション",
  "parent": "%E5%AE%9F%E8%A3%85%E3%81%AE%E4%BE%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an API key integration to a private app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "ご使用のアカウントで、APIキーの無効化に関するバナーが表示される場合は、次の内容をご確認ください。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["影響を受ける全ての連携が移行済みであることを確認してから、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
              children: "APIキーを無効化"
            }), "します。"]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["過去7日間にアカウントのAPIキーが使用されたかどうかを確認するには、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/ja/integrations/how-do-i-get-my-hubspot-api-key#view-your-api-key-call-log",
              children: "APIキーの呼び出しログ履歴を表示"
            }), "します。呼び出しログには、8日以上前にキーを使用して行われた要求は表示されません。"]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/ja/integrations/connect-apps-to-hubspot#:~:text=You%20can%20view%20all%20your,Connected%20Apps%20page.",
              children: "アカウント設定の［接続されたアプリ］ページ"
            }), "にリストされているアプリは、OAuthで認証されるため、移行する必要はありません。"]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["開発者APIキーは、標準のHubSpot APIキーとは別のものであり、非推奨になることは", (0, _jsxRuntime.jsx)("u", {
              children: "ありません"
            }), "。開発者APIキーは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "Webhook API"
            }), "サブスクリプションや", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/timeline",
              children: "タイムラインイベントAPI"
            }), "イベントタイプなど、HubSpotアプリに関連する設定を管理するために使用されます。"]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/integrations/how-do-i-get-my-hubspot-api-key",
        children: "HubSpot APIキー"
      }), "を使用する内部連携を構築した場合、APIキーは全てのHubSpot CRMデータに対する読み取りアクセス権と書き込みアクセス権の両方を提供しますが、APIキーが侵害された場合にセキュリティーリスクが発生する可能性があります。非公開アプリに移行することにより、連携で必要とされる特定のスコープを認証できます。スコープを認証すると、アクセストークンが生成されます。このトークンは、連携でアカウント内のリクエストや変更を行うことが可能なデータを制限します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既存のAPIキー連携を非公開アプリに移行するには、次の手順に従います。本番環境で変更する前に、まず", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "開発者テストアカウント"
      }), "や", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/account/set-up-a-hubspot-sandbox-account",
        children: "サンドボックスアカウント"
      }), "などのテスト環境を使用することをお勧めします。アプリの移行に関して質問がある場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "開発者コミュニティー"
      }), "（英語）をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "また、以下のHubSpot開発者向け動画で、APIキー連携を非公開アプリに移行する手順をご確認いただけます（英語）。"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/F3Qd9Hp4giM?start=29&feature=oembed",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "このガイドの内容"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-new-private-app",
          children: "新しい非公開アプリを作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#update-the-authorization-method-of-your-integration-s-api-requests",
          children: "連携のAPIリクエストの認証方法を更新する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#verify-requests-and-monitor-logs",
          children: "リクエストを検証し、ログを監視する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#implementation-examples",
          children: "実装の例"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**非公開アプリでは、Webhookおよび", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/overview",
          children: "特定の種類の拡張機能"
        }), "はサポートされません。既存の連携でこれらの機能のいずれかを使用している場合は、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/api/working-with-oauth",
          children: "OAuth"
        }), "を使用する公開アプリを作成する必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "新しい非公開アプリを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "HubSpotアカウントにて、メイン ナビゲーション バーにある設定アイコンをクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "左のサイドバーメニューで、［連携］＞［非公開アプリ］の順に進みます。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［非公開アプリを作成］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［基本情報］タブで、アプリの詳細を設定します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリの名前を入力します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "プレースホルダーロゴの上にカーソルを合わせると表示されるアップロードアイコンをクリックして、アプリのロゴとして使用する正方形の画像をアップロードします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            }), "を入力します。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［スコープ］タブをクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "次に、連携で使用しているAPIに基づいて、認証するスコープを選択します。アプリに必要なスコープを見つけるには、次の手順に従います。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "既存の連携で使用しているHubSpot APIのリストを作成します。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["APIリクエストごとに、関連する開発者ドキュメント（", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.jp/docs/api/crm/contacts",
              children: "コンタクトAPI"
            }), "など）に移動します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［エンドポイント］タブをクリックし、連携で使用しているエンドポイントまでスクロールします。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［必要な条件］セクションで、エンドポイントを使用するために必要なスコープを見つけます。可能な場合は常に、［標準スコープ］に表示されているスコープではなく、［詳細スコープ］に表示されているスコープを選択してください。きめ細かいスコープがない場合は、標準スコープを使用してください。"
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/locate-scope-in-endpoints-tab-for-private-app-migration.png",
            alt: "locate-scope-in-endpoints-tab-for-private-app-migration"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "非公開アプリの設定に戻り、一致するスコープの横にある［読み取り］または［書き込み］チェックボックスをオンにします。［スコープを検索］検索バーを使用してスコープを探すこともできます。"
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-matching-scope-for-private-app.png",
            alt: "select-matching-scope-for-private-app"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "スコープの選択が完了したら、右上の［アプリを作成］をクリックします。アプリの作成後は、いつでもアプリに変更を加えることができます。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ダイアログボックスで、アプリのアクセストークンの情報を確認してから、［作成を続行］をクリックします。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "非公開アプリを作成したら、そのアクセストークンを使用してAPIリクエストを開始できます。非公開アプリの設定ページの［詳細］タブで、アクセストークンの下の［トークンを表示］をクリックすると、トークンが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-migration-guide.png",
        alt: "show-private-app-access-token-migration-guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "連携のAPIリクエストの認証方式を更新する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hapiKey"
      }), "クエリーパラメーターを使用してAPIリクエストを行う代わりに、リクエストの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), "ヘッダーに非公開アプリのアクセストークンを含めます。リクエストを行うときは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), "ヘッダーの値を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Bearer YOUR_ACCESS_TOKEN"
      }), "に設定します。特に明記されている場合を除き、この認証方式は、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "レガシー開発者ドキュメント"
      }), "（英語）に記載されているレガシーAPIを含む、全てのパブリックAPIエンドポイントに対応しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$headers = [\n    'Content-Type: application/json',\n    'Authorization: Bearer ' . YOUR_ACCESS_TOKEN,\n];\n\n$curl = curl_init();\n\ncurl_setopt($curl, CURLOPT_HTTPHEADER, $headers);\ncurl_setopt($curl, CURLOPT_URL, 'https://api.hubapi.com/crm/v3/objects/contacts');\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n$contacts = curl_exec($curl);\ncurl_close($curl);\n\nvar_dump($contacts);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "require 'uri'\nrequire 'net/http'\nrequire 'openssl'\n\nurl = URI(\"https://api.hubapi.com/crm/v3/objects/contacts\")\n\nhttp = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nhttp.verify_mode = OpenSSL::SSL::VERIFY_NONE\n\nrequest = Net::HTTP::Get.new(url)\nrequest['content-type'] = 'application/json'\ntoken = 'YOUR_ACCESS_TOKEN'\nrequest['authorization'] = \"Bearer #{token}\"\n\nresponse = http.request(request)\nputs response.read_body\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "import requests\n\nurl = \"https://api.hubapi.com/crm/v3/objects/contacts\"\n\nheaders = {\n  'content-type': 'application/json',\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\nresponse = requests.request(\"GET\", url, headers=headers)\n\nprint(response.text)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["非公開アプリのアクセストークンはOAuthを基盤として実装されているため、HubSpotのクライアントライブラリーを使用して、アクセストークンによる認証済みの呼び出しを行うこともできます。例えば、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node.jsクライアントライブラリー"
      }), "（英語）を使用する場合は、アプリのアクセストークンを渡すことにより、OAuthクライアントをインスタンス化できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$hubSpot = \\HubSpot\\Factory::createWithAccessToken('access-token');\n$response = $hubSpot->crm()->contacts()->basicApi()->getPage();\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# Load the gem\nrequire 'hubspot-api-client'\n\n# Setup client\nclient = Hubspot::Client.new(access_token: 'YOUR_ACCESS_TOKEN')\n\n# Get contacts\ncontacts = client.crm.contacts.basic_api.get_page\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "from hubspot import HubSpot\n\napi_client = HubSpot(access_token='YOUR_ACCESS_TOKEN')\napi_client.crm.contacts.get_page()\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["非公開アプリへの移行を完了するには、コードからHubSpot APIキーへの参照を全て削除し、代わりに上記の方法に従って非公開アプリのアクセストークンを使用します。リクエストにトークンをハードコーディングするのではなく、実行するリクエストに応じて、トークンを格納するシークレットを作成します。シークレットを使用すると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "サーバーレス関数"
      }), "でトークンを使用するときなどに、トークンが公開されることを防止できます。アクセストークンをシークレットとして格納するには、次の手順に従います。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ターミナルで、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add secretName"
        }), "を実行します。シークレットの名前は、後で簡単に参照できるように、シンプルなものにすることをお勧めします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アクセストークンをターミナルに貼り付けて、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "キーを押します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この時点で、環境変数としてシークレットにアクセスできるようになります。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "後述のサーバーレス関数の例"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "APIキーへの参照が全て削除されたことを確認するには、HubSpotアカウントのコールログをチェックします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotアカウントで、メイン ナビゲーション バーにある", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "設定アイコン"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーで、［連携］>［APIキー］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［コールログ］タブで最近のリクエストをチェックし、非公開アプリのアクセストークンを使用するために以前の参照を全て削除してから、リクエストが実行されていないことを確認します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/check-api-key-call-log-after-migration.png",
        alt: "check-api-key-call-log-after-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/contacts/marketing-contacts",
        children: "マーケティングコンタクト"
      }), "を含む有料のMarketing Hubアカウントをご利用中で、以前に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/integrations/manage-marketing-contacts-settings-for-your-integrations#set-contacts-created-by-api-key-apps-as-marketing-contacts",
        children: "APIキーを使用して、連携によって作成されたコンタクトをマーケティングコンタクトとして設定"
      }), "している場合は、非公開アプリについても同じ操作を行う必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、メイン ナビゲーション バーにある設定アイコンをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーで、［連携］>［マーケティングコンタクト］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［接続されたアプリ］の下で、検索バーを使用して非公開アプリを見つけて、［コンタクトをマーケティングコンタクトとして同期するにはオンにします］スイッチをクリックしてオンにします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/set-private-app-contacts-as-marketing-contacts.png",
        alt: "set-private-app-contacts-as-marketing-contacts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["非公開アプリの設定が完了し、コードのAPIキーへの参照が全て削除されたことを確認したら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
        children: "キーを無効化"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "リクエストを検証し、ログを監視する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードからHubSpot APIキーへの参照を全て削除し、それらを非公開アプリのアクセストークンへの参照に置き換えたら、それ以上のコードの変更は不要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者テストアカウントまたはサンドボックスアカウントで上記の手順を実施したら、本番アカウントで同じプロセスを再現します。次に、非公開アプリのAPIコールログを監視し、アプリの全てのリクエストが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), "エラーを返していないことを確認します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、メイン ナビゲーション バーにある設定アイコンをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーメニューで、［連携］＞［非公開アプリ］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "非公開アプリの名前をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［ログ］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["スコープが不十分なために失敗したAPIリクエストについては、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), "エラーが表示されます。連携のランタイムログにアクセスする場合は、対応するAPIリクエストに対するレスポンスに、不足しているスコープの詳細を示すエラーメッセージが含まれている必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/403-error-after-private-app-migration.png",
        alt: "403-error-after-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["非公開アプリに新しいスコープを含める必要がある場合は、次の手順に従います。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［詳細］タブをクリックします。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［詳細を編集］をクリックします。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ページの最上部にある［スコープ］をクリックします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["不足しているスコープの横の", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "チェックボックス"
            }), "を全てオンにしたら、右上の［変更をコミット］をクリックします。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-missing-scopes-private-app-migration.png",
        alt: "select-missing-scopes-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["非公開アプリの作成と管理、および非公開アプリの制限については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/private-apps",
        children: "非公開アプリのガイド"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "実装の例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、一般的なAPIキーの使用方法と、APIキーを非公開アプリのアクセストークンに移行する方法について詳しく説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サーバーレス関数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "サーバーレス関数"
      }), "でAPIキーを使用している場合は、上記と同様に、非公開アプリのアクセストークンを認証に使用できます。関数が実行する必要のある", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/working-with-oauth#scopes",
        children: "スコープ"
      }), "が非公開アプリに存在することを確認してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "非公開アプリのアクセストークンを使用してサーバーレス関数を認証するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "「アクセストークン」カードで、［トークンを表示］をクリックしてアクセストークンを表示します。次に、［コピー］をクリックして、トークンをクリップボードにコピーします。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
            alt: "show-private-app-access-token-1"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アクセストークンをコピーした状態のまま、そのトークンを保存するための新しいシークレットを作成します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["ターミナルで、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs secrets add secretName"
            }), "を実行します。シークレットの名前は、後で簡単に参照できるように、シンプルなものにすることをお勧めします。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["アクセストークンをターミナルに貼り付けて、", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enter"
            }), "キーを押します。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["サーバーレス関数の", (0, _jsxRuntime.jsx)(_components.code, {
            children: "serverless.json"
          }), "ファイルで、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "secrets"
          }), "配列にシークレット名を追加します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example serverless.json file\n\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"getPrompts\": {\n      \"method\": \"GET\",\n      \"file\": \"serverlessFunction.js\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サーバーレス関数のJavaScriptファイルで、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Authorization"
        }), "ヘッダーの値を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bearer secretName"
        }), "に設定します。例えば、Node.jsと", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), "を使用して", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/api/crm/contacts",
          children: "コンタクトAPI"
        }), "を呼び出す場合、リクエストは次のようになります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example serverless function\nconst axios = require('axios');\n\nexports.main = (context, sendResponse) => {\n  axios.get(`https://api.hubapi.com/crm/v3/objects/contacts`, {\n    headers: {\n      Authorization: `Bearer ${process.env.secretName}`,\n      'Content-Type': 'application/json',\n    },\n  });\n  sendResponse({ statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/private-apps#make-api-calls-with-your-app-s-access-token",
        children: "アプリのトークンを使用してAPIを呼び出す方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1回限りのジョブ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/crm/properties",
        children: "プロパティーの作成"
      }), "のような1回限りのジョブを実行するためにAPIキーを使用している場合は、代わりに非公開アプリを作成して、そのアクセストークンでコールを認証できます。非公開アプリを作成すると、非公開アプリに適切な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/working-with-oauth#scopes",
        children: "スコープ"
      }), "が設定されている限り、そのアクセストークンを任意の1回限りのジョブで再利用できます。非公開アプリのスコープは、HubSpotの非公開アプリの設定から、いつでも更新できます。または、非公開アプリを削除して、実行する必要があるジョブ専用の新しい非公開アプリを作成することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/private-app-edit-scopes.png",
        alt: "private-app-edit-scopes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムオブジェクトの作成"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["APIキーを使用して", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "カスタムオブジェクトを作成"
      }), "する代わりに、非公開アプリを作成して、そのアクセストークンでコールを認証できます。ただし、必要な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/working-with-oauth#scopes",
        children: "スコープ"
      }), "が非公開アプリに含まれていることが条件となります。例えば、Postmanを使用してカスタムオブジェクトを作成する場合は、認証タイプを", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Bearerトークン"
      }), "に設定し、［トークン］フィールドにトークンを入力します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-private-app-access-token-field.png",
        alt: "postman-private-app-access-token-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["非公開アプリを使用したカスタムオブジェクトの作成に関する詳細は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-a-custom-object-using-private-appsprivate-apps",
        children: "HubSpotの開発者ブログ"
      }), "（英語）をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタム コード ワークフロー アクション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions#create-a-custom-code-action",
        children: "「カスタムコード」ワークフローアクション"
      }), "でAPIキーを使用している場合は、代わりに非公開アプリのアクセストークンを使用できます。ただし、必要な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/working-with-oauth#scopes",
        children: "スコープ"
      }), "が非公開アプリに存在することが条件となります。更新するには、ワークフローエディターでカスタムアクションを開き、次の変更を行います。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["最初に、非公開アプリのアクセストークンを格納する", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.jp/docs/api/conversations/code-snippets-in-bots",
            children: "新しいシークレットを追加"
          }), "します。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/workflow-action-add-secret.png",
            alt: "workflow-action-add-secret"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["次に、新しいシークレットで", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/automation/custom-code-actions#secret",
            children: "アクションコードを更新"
          }), "します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({\n  accessToken: process.env.secretName,\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}