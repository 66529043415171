"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358688;
const slug = exports.slug = 'guides/api/marketing/forms';
const title = exports.title = 'Marketing-API | Formulare ';
const name = exports.name = 'vNext Docs DP - Formulare';
const metaDescription = exports.metaDescription = 'Diese Endpunkte werden verwendet, um Formulare in Ihrem HubSpot-Account zu erstellen und zu verwalten.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Formulare"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie ein HubSpot-Formular, um Lead-Informationen über Ihre Besucher und Kontakte zu sammeln. Sie können die hier beschriebenen Endpunkte verwenden, um neue Formulare einzurichten oder Details zu Formularen abzurufen, die Sie zuvor erstellt haben. Wenn Sie Formulareinsendungsdaten senden möchten, können Sie stattdessen den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "Endpunkt für das Senden von Daten an ein Formular"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Typ des Formulars gibt seinen Zweck an und ist standardmäßig auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot"
      }), " festgelegt. Sie können die folgenden Werte für Ihren ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "formType"
      }), " verwenden:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot"
        }), ": Diese Formulare bieten eine Vielzahl von Feldtypen und Formatierungsoptionen und können entweder auf HubSpot-Seiten oder auf externen Seiten eingebettet verwendet werden. Diese Formulare können mithilfe der hier beschriebenen Endpunkte erstellt und bearbeitet werden. Sie können diese Formulare auch in Ihrem HubSpot-Account erstellen. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-forms",
          children: "Erstellen von HubSpot-Formularen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "captured"
        }), ": Diese Formulare entsprechen HTML-Formularen auf externen Websites. Wenn das Drittanbieter-Formulare-Tool aktiviert ist und die Einsendung des Formulars auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
          children: "einer nachverfolgten Seite"
        }), " erfolgt, wird das Formular automatisch in HubSpot erstellt. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/use-non-hubspot-forms",
          children: "Verwenden von Drittanbieter-Formularen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "flow"
        }), ": Dies sind Pop-up-Formulare, die entweder auf HubSpot-Seiten oder auf externen Seiten verwendet werden können. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-pop-up-forms",
          children: "Pop-up-Formulare-Tool von HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_comment"
        }), ": Diese Formulare werden automatisch für HubSpot-Blog-Seiten erstellt, um Kommentare zu Blog-Beiträgen zu sammeln. Erfahren Sie mehr darüber, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/blog/set-up-and-moderate-your-blog-comments",
          children: "Blog-Kommentare weiter einrichten und moderieren können"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}