"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492270475;
const slug = exports.slug = 'guides/cms/content/performance/speed';
const title = exports.title = 'Optimierung der Geschwindigkeit Ihrer CMS Hub-Website';
const name = exports.name = 'EMEA DACH (de) Optimizing your CMS Hub site for Speed - original';
const metaDescription = exports.metaDescription = 'Tipps und Strategien zur Verbesserung der Lade- und Renderinggeschwindigkeit Ihrer Website. Gestalten Sie Ihr CMS Hub-Design oder Ihre Website schnell, und lernen Sie, was HubSpot bereits für Sie tut.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "beginnen-sie-mit-einer-guten-grundlage",
  "label": "Beginnen Sie mit einer guten Grundlage",
  "parent": null
}, {
  "depth": 0,
  "id": "h%C3%A4ufige-engp%C3%A4sse-bei-der-website-performance",
  "label": "Häufige Engpässe bei der Website-Performance",
  "parent": null
}, {
  "depth": 1,
  "id": "bilder",
  "label": "Bilder",
  "parent": "h%C3%A4ufige-engp%C3%A4sse-bei-der-website-performance"
}, {
  "depth": 2,
  "id": "was-sie-tun-k%C3%B6nnen",
  "label": "Was Sie tun können",
  "parent": "h%C3%A4ufige-engp%C3%A4sse-bei-der-website-performance"
}, {
  "depth": 1,
  "id": "automatisch-abgespielte-videos",
  "label": "Automatisch abgespielte Videos",
  "parent": "h%C3%A4ufige-engp%C3%A4sse-bei-der-website-performance"
}, {
  "depth": 2,
  "id": "was-sie-tun-k%C3%B6nnen",
  "label": "Was Sie tun können",
  "parent": "h%C3%A4ufige-engp%C3%A4sse-bei-der-website-performance"
}, {
  "depth": 1,
  "id": "javascript",
  "label": "JavaScript",
  "parent": "h%C3%A4ufige-engp%C3%A4sse-bei-der-website-performance"
}, {
  "depth": 2,
  "id": "was-sie-tun-k%C3%B6nnen",
  "label": "Was Sie tun können",
  "parent": "h%C3%A4ufige-engp%C3%A4sse-bei-der-website-performance"
}, {
  "depth": 0,
  "id": "tool-f%C3%BCr-empfehlungen",
  "label": "Tool für Empfehlungen",
  "parent": null
}, {
  "depth": 0,
  "id": "code-warnungen",
  "label": "Code-Warnungen",
  "parent": null
}, {
  "depth": 0,
  "id": "was-%C3%BCbernimmt-hubspot-f%C3%BCr-sie%3F",
  "label": "Was übernimmt HubSpot für Sie?",
  "parent": null
}, {
  "depth": 0,
  "id": "zus%C3%A4tzliche-ressourcen-zur-verbesserung-der-geschwindigkeit-ihrer-website",
  "label": "Zusätzliche Ressourcen zur Verbesserung der Geschwindigkeit Ihrer Website",
  "parent": null
}, {
  "depth": 1,
  "id": "performance-tools",
  "label": "Performance-Tools",
  "parent": "zus%C3%A4tzliche-ressourcen-zur-verbesserung-der-geschwindigkeit-ihrer-website"
}, {
  "depth": 2,
  "id": "bildoptimierung",
  "label": "Bildoptimierung",
  "parent": "zus%C3%A4tzliche-ressourcen-zur-verbesserung-der-geschwindigkeit-ihrer-website"
}, {
  "depth": 2,
  "id": "performance-tests",
  "label": "Performance-Tests",
  "parent": "zus%C3%A4tzliche-ressourcen-zur-verbesserung-der-geschwindigkeit-ihrer-website"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      ol: "ol",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Optimieren der Performance Ihrer CMS Hub-Website"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wie gut das Nutzererlebnis für Website-Besucher ist, hängt von der Qualität des Content, der Ladegeschwindigkeit der Seiten, der Sicherheit und der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "Barrierefreiheit"
      }), " ab. Wenn Sie diese Faktoren verbessern, wirkt sich das positiv auf die Suchmaschinenoptimierung aus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine bessere Performance bietet Besuchern ein besseres Nutzererlebnis. Sie müssen also die Schwachpunkte Ihrer Website finden und beheben, um eine bessere Performance zu erzielen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beginnen Sie mit einer guten Grundlage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es ist einfacher, auf einem guten Fundament aufzubauen, das mit Blick auf die Performance entwickelt wurde, als zu versuchen, Performance-Probleme später zu beheben. Denken Sie an folgende Metapher: es ist einfacher, von Grund auf neu ein schnelles Auto zu konstruieren, als ein langsames Auto zu kaufen und zu versuchen, es schnell zu machen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), " wurde entwickelt, um schnell zu sein und bewährte Methoden zu fördern. In der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "GitHub README"
      }), " finden Sie die aktuellen Scores in Lighthouse und Website Grader."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie von der Boilerplate aus entwickeln, wissen Sie, dass diese Werte auch ohne jeglichen von Ihnen hinzugefügten Code erreicht werden. Das bedeutet, dass Sie Ihre Aufmerksamkeit auf den Code richten können, den Sie über die Boilerplate hinzugefügt haben."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "bdf71810-13e6-494f-8dbd-f733d5451ad5",
      label: "Build a site based on boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Häufige Engpässe bei der Website-Performance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die meisten Techniken und bewährte Methoden zur Optimierung der Webleistung sind nicht HubSpot-spezifisch. Der Grund dafür ist, dass auch die meisten Engpässe bei der Website-Performance nicht HubSpot-spezifisch sind."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die meisten Performance-Probleme lassen sich in zwei Kategorien einteilen: Rendering und Laden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lade-Performance"
        }), " ist die Effizienz der Übertragung aller für Ihre Webseite benötigten Dateien an den Browser des Benutzers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rendering-Performance"
        }), " bezeichnet die Effizienz des Browsers, alles, was er heruntergeladen hat, zu verarbeiten und dem Benutzer das Endergebnis anzuzeigen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Lade-Performance ist etwas einfacher zu verstehen – die Anzahl der Dateien, die Größe der Dateien und die Geschwindigkeit der Bereitstellung dieser Dateien bestimmen die Lade-Performance. Rendering-Performance ist ein kompliziertes Design. Cascading Style Sheets (", (0, _jsxRuntime.jsx)("abbr", {
        children: "CSS"
      }), "), JavaScript (", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), "), Bilder, Videos, das vom Besucher verwendete Gerät und der verwendete Webbrowser sind allesamt wichtige Faktoren. CSS ist eine rendering-blockierende Ressource. Schlecht geschriebenes CSS kann beim Rendern der Seite eine kumulative Layout-Verschiebung (Cumulative Layout Shift, ", (0, _jsxRuntime.jsx)("abbr", {
        children: "CLS"
      }), ") verursachen. Bilder können CLS auslösen und benötigen viel RAM. Videoplayer können CLS verursachen, einige Dateiformate erfordern mehr Verarbeitungsaufwand. JS kann das ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/DOM",
        children: ["Document Object Model (", (0, _jsxRuntime.jsx)("abbr", {
          children: "DOM"
        })]
      }), ") und das ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/CSSOM",
        children: ["Cascading Style Sheet Object Model (", (0, _jsxRuntime.jsx)("abbr", {
          children: "CSSOM"
        })]
      }), ") einer Seite manipulieren und damit eines der genannten Probleme verursachen. JS kann auch ressourcenintensiv sein. All diese Faktoren müssen ausgeglichen werden, und es müssen bewährte Methoden befolgt werden, um ein schnelles Erlebnis für alle Besucher zu gewährleisten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bilder"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bilder sind auf fast jeder Seite im Internet zu finden. Bilder sind in der Regel die größten Dateien auf einer Seite. Je mehr Bilder und je größer die Bilder sind, desto länger dauert es, bis die Seite geladen ist. Animierte Bilder, wie GIFs und animiertes WebP, benötigen ebenfalls mehr Platz als nicht animierte Bilder derselben Größe. Einige Bildformate sind ebenfalls besser in der Performance als andere und für bestimmte Szenarien besser geeignet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Was Sie tun können"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das Wichtigste, was Sie tun können, ist, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/compress-image",
          children: "Ihre Bilder für das Web zu optimieren"
        }), ". Bildoptimierung ist eine gemeinsame Aufgabe von Content-Autoren und Entwicklern."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verwenden Sie weniger Bilder pro Seite."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/insiders/different-types-of-image-files",
          children: "Verwenden Sie das richtige Bildformat für den jeweiligen Anwendungsfall"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verwenden Sie nach Möglichkeit skalierbare Vektorgrafiken (SVGs). SVGs können unendlich groß werden, ohne an Qualität zu verlieren. Das Einbinden von SVGs ist sinnvoll, wenn Sie Animationen erstellen. Bei statischen Grafiken ist die Erstellung eines SVG-Sprite-Sheets oder die einfache Behandlung als normales img-Element oder Hintergrundbild in der Regel besser für die Performance."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Laden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/lazy-loading",
          children: "Bilder per Lazy Loading"
        }), " auf intelligente Weise."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stellen Sie sicher, dass die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img-Elemente"
        }), " die HTML-Attribute „height“ und „width“ enthalten. Dies erleichtert den Browsern die Rendering-Zeit und ermöglicht es HubSpot, auf intelligente Weise ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), " für Sie hinzuzufügen. HubSpot hilft nicht nur beim Optimieren, Webbrowser können auch auf intelligente Weise für ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/cls/",
          children: "kumulative Layoutverschiebungen"
        }), " optimieren, wenn Sie Breite und Höhe angeben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie die CSS-Eigenschaft ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio",
          children: "aspect-ratio"
        }), ", um Platz zu reservieren, wenn sich die Maße des Bildes ändern können."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url"
          })
        }), ", um zu erzwingen, dass Bilder auf eine bestimmte Auflösung verkleinert werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie für Hintergrundbilder ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/optimize-css-background-images-with-media-queries/",
          children: "Medienabfragen"
        }), " in Kombination mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url"
        }), ", um Bilder in einer für das jeweilige Gerät sinnvollen Größe bereitzustellen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Große Hero-Bilder können mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"preload\" as=\"image\" href=\"http://example.com/img_url.jpg\">"
        }), " innerhalb eines ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "require_head"
          }), "-Tags"]
        }), " vorgeladen werden. Gehen Sie sparsam mit diesem Verfahren um, eine übermäßige Anwendung kann der Performance schaden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Automatisch abgespielte Videos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Videohintergründe und automatisch abgespielte Videos können eine Website durchaus von anderen abheben. Leider haben sie ihren Preis. Videohintergründe werden häufig für Website-Header verwendet. Wenn ein Video automatisch abgespielt wird, bedeutet dies, dass der Browser sofort mit dem Laden des Videos beginnen muss. Dies kann vor allem bei langsameren Verbindungen oder bei der Nutzung von Handydaten problematisch sein."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Was Sie tun können"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vermeiden Sie die Verwendung von automatisch abspielenden Videos. Wenn es sich um eine Hintergrundanimation handelt, sollten Sie CSS-Animationen oder JavaScript-Animationen verwenden. Wenn Sie ein automatisch abspielendes Video anzeigen müssen:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wählen Sie je nach Anwendungsfall eine angemessene Auflösung für das Video, und wenden Sie einen Effekt auf das Video an, damit eine niedrigere Auflösung weniger auffällt."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Stellen Sie sicher, dass die Qualität des Videos je nach Gerät und Verbindung skaliert wird. Am besten verwenden Sie dazu einen Video-Sharing-/Hosting-Dienst wie YouTube, Vidyard oder Vimeo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Deaktivieren Sie die automatische Wiedergabe auf mobilen Geräten und zeigen Sie stattdessen ein Ersatzbild an."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript (", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), ") ist nützlich, um Ihrer Website Interaktivität hinzuzufügen. Das Laden von viel JS-Code erhöht im Allgemeinen die Dateigröße der JS-Dateien und die Zeit, die der Browser zum Rendern interaktiver Elemente benötigt. Das Laden von JS im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " kann auch ein Problem darstellen, da JavaScript standardmäßig eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/render-blocking-resources/",
        children: "rendering-blockierende Ressource"
      }), " ist. Außerdem wird JS auf dem Gerät des Besuchers ausgeführt, d. h., es ist auf die Ressourcen dieses Geräts beschränkt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Was Sie tun können"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Als das CMS von HubSpot auf den Markt kam, wurde jQuery standardmäßig im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " geladen. Sie können es unter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Einstellungen“ > „Website“ > „Seiten“"
        }), " vollständig entfernen oder ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/upgrade-jquery",
          children: "auf die neueste Version von jQuery aktualisieren"
        }), ". Seien Sie vorsichtig, wenn Sie diese Einstellungen auf älteren Websites ändern, wenn Sie diese nicht selbst erstellt haben, da sie möglicherweise mit jQuery erstellt wurden oder auf dem Laden von jQuery im Header basieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stellen Sie sicher, dass JavaScript kurz vor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "</body>"
        }), " geladen wird, um eine Rendering-Blockierung zu vermeiden. Sie können ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " verwenden, um JavaScript für Module oder Vorlagen nur bei Bedarf zu laden, ohne dass das JavaScript versehentlich mehrfach für mehrere Instanzen eines Moduls geladen wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erwägen Sie ein Refactoring Ihres JS, um effizienter zu werden. Verwenden Sie weniger JS-Plug-ins, verwenden Sie semantisches HTML, wo es sinnvoll ist. Verwenden Sie zum Beispiel für Dropdowns ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<details>"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<summary>"
        }), ". Verwenden Sie für modale Dialogfelder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<dialog>"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie eine riesige JS-Bibliothek nur für ein paar kleine Funktionen verwenden, sollten Sie es in Betracht ziehen, Vanilla JS zu verwenden oder eine Teilmenge der Bibliothek zu laden, wenn möglich."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js"
        }), ", um JS nur bei Bedarf und nur einmal pro Seite zu laden. Verwenden Sie beim Einsatz von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " die Attribute ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "async"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "defer"
        }), ", um die Seiten-Performance zu verbessern."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um zu steuern, wo und wann das JavaScript eines Moduls geladen wird, verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "js_render_options"
        }), " in der meta.json-Datei des Moduls."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie externe Ressourcen laden, verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/preconnect-and-dns-prefetch/",
          children: "„preconnect“ und „DNS prefetch“"
        }), " in geeigneter Weise, um ein schnelleres Erlebnis zu bieten."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Begrenzen Sie die Anzahl der von Ihnen verwendeten Tracking-Skripts. Tracking-Skripts versuchen oft, alle Aktionen eines Benutzers auf einer Seite zu verstehen, um Ihnen Einblicke zu geben. Das ist eine Menge Code, der analysiert, was der Benutzer tut. Jedes Tracking-Skript verstärkt dies noch."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tool für Empfehlungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das HubSpot-Tool für Empfehlungen ist eine hervorragende Möglichkeit, Performance- und SEO-Feedback speziell für Ihre Website zu erhalten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/seo/view-seo-recommendations-in-hubspot",
        children: "Erfahren Sie mehr über das Tool für Empfehlungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Code-Warnungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Code-Warnungen ist eine Funktion von CMS Hub Enterprise, die als zentraler Überblick über Probleme dient, die innerhalb Ihrer CMS Hub-Website identifiziert werden. Das Beheben von Problemen, die in Code-Warnungen identifiziert werden, kann dazu beitragen, die Performance Ihrer Website zu optimieren. Die identifizierten Probleme umfassen verschiedene Bereiche von HubL-Limits bis hin zu CSS-Problemen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "Erfahren Sie mehr über Code-Warnungen."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Was übernimmt HubSpot für Sie?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die meisten bewährte Methoden für Performance/Geschwindigkeit sind nicht für HubSpot spezifisch. Das CMS von HubSpot kümmert sich automatisch um viele gängige Performance-Probleme für Sie. Zu diesen Optimierungen zählen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CDN mit Bildoptimierung und automatischer WebP-Konvertierung"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "HTTP2"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "JavaScript"
        }), "- und CSS-Minifizierung"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Browser- und Server-Caching"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "Prerendering"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Domain-Übertragung"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#text-compression",
          children: "Brotli-Kompression (mit Rückgriff auf GZIP-Kompression)"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#accelerated-mobile-pages-amp-",
          children: "HubSpot-Blog-Beiträge unterstützen AMP"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Einfügen von CSS in ein benutzerdefiniertes Modul lädt HubSpot die module.css-Datei auf intelligente Weise nur dann, wenn ein Modul auf einer Seite verwendet wird, und lädt sie nur einmal, unabhängig davon, wie viele Instanzen des Moduls sich auf der Seite befinden. Standardmäßig wird module.css nicht asynchron geladen, Sie können dies jedoch ändern, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options"
      }), " in die meta.json-Datei des Moduls aufnehmen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zusätzliche Ressourcen zur Verbesserung der Geschwindigkeit Ihrer Website"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt viele Möglichkeiten, eine Website auf Geschwindigkeit hin zu optimieren, und bei vielen Themen lohnt es sich, sich näher damit zu beschäftigen. Wir haben einige großartige Ressourcen zusammengestellt, die Sie sich ansehen sollten, wenn Sie an der Optimierung Ihrer Website arbeiten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/site-speed-and-performance-what-we-do-and-what-you-can-do",
          children: "Website-Geschwindigkeit und -Performance: Was Sie tun können und wie HubSpot Ihnen dabei hilft"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-we-improved-page-speed-on-hubspot.com",
          children: "Wie wir die Seitengeschwindigkeit auf HubSpot.com verbessert haben"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/15-tips-to-speed-up-your-website",
          children: "15 Tipps zur Beschleunigung Ihrer Website"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/how-to-reduce-your-websites-page-speed",
          children: "5 einfache Methoden, um die Ladezeit Ihrer Website zu verkürzen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/google-page-speed",
          children: "Anleitung in 8 Schritten zum Erreichen von 100% Google Page Speed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/website-optimization",
          children: "Website-Optimierung – HubSpot Academy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/learn/",
          children: "Web.dev"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Wie wir das CMS von HubSpot optimieren – Jeff Boulter"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.smashingmagazine.com/2021/04/humble-img-element-core-web-vitals/",
          children: "Das bescheidene img-Element und Core Web Vitals – Smashing Magazine"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Performance-Tools"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt eine Vielzahl von Tools, mit denen sich die Performance verbessern und testen lässt. Es ist hilfreich, diese Tools zu kennen und zu nutzen. Diese Liste erhebt keinen Anspruch auf Vollständigkeit und stammt von unserer Entwickler-Community."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bildoptimierung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie Ihre Bilder vor dem Hochladen und Bereitstellen für das Web optimieren, stellen Sie sicher, dass Sie kein für den Bildschirm und den Anwendungsfall zu großes Bild bereitstellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beliebte Tools für die Bildoptimierung:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imageoptim.com/mac",
          children: "ImageOptim"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/photoshop.html",
          children: "Adobe Photoshop"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/illustrator.html",
          children: "Adobe Illustrator"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imagecompressor.com/",
          children: "Optimizilla"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Performance-Tests"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Testen der Performance und die Optimierung dahingehend sollten Teil jeder Website-Erstellung sein. Es gibt eine Vielzahl von Tools zum Testen der Geschwindigkeit einer Website. Es ist wichtig, diese Tools und ihre Funktionsweise zu verstehen, damit Sie fundierte Entscheidungen über Performance-Verbesserungen treffen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit einigen Tool kann nur gemessen werden, andere bewerten auch. Es ist wichtig zu verstehen, wie das eigentlich funktioniert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Tools, die messen"
      }), ", testen in der Regel die Ladezeit, die Skriptausführungszeit, die Zeit bis zum ersten inhaltsreichen Bild, die Netzwerkzeiten für das Herunterladen von Elementen usw. Diese Tools liefern in der Regel Ergebnisse, die bestimmte Zeiten für jede dieser Kennzahlen angeben. Wenn Sie den Test wiederholen, verschieben sich die Messwerte in der Regel geringfügig, da nicht jeder Seitenaufbau exakt gleich ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Tools, die eine Bewertung vornehmen,"
      }), " messen nicht nur die Geschwindigkeit, sondern sagen Ihnen auch, ob Sie gut oder schlecht abschneiden, oft in Form von Prozent- oder Buchstabenwerten. Diese Bewertungen sollen Entwickler und Marketer dazu motivieren, sich zu verbessern. Es gibt so viele verschiedene Kennzahlen und Aspekte der Performance, die berücksichtigt werden müssen. Sie können Ihre Gesamt-Performance nicht nur auf eine einzige Kennzahl stützen, und darüber hinaus wirken sich einige Kennzahlen unterschiedlich stark auf die wahrgenommene Performance aus. Diese Tools gewichten die Kennzahlen also unterschiedlich, um die Gesamt-Performance zu berechnen. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Es gibt keinen branchenweiten Standard für die Gewichtung der Kennzahlen."
      }), " Im Laufe der Zeit kann sich diese Gewichtung ändern, wie es bei ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://googlechrome.github.io/lighthouse/scorecalc/",
        children: "Google Page Speed"
      }), " der Fall war. Es gibt auch keinen branchenweit akzeptierten Mindest- oder Maximalwert für die einzelnen Kennzahlen. Einige Tools stützen sich dabei auf ein Perzentil der getesteten Websites. Das bedeutet, dass Ihre Ergebnisse mit denen anderer getesteter Websites verglichen werden. Dies führt dazu, dass der Geschwindigkeitsbereich „gut/ausgezeichnet“ mit der Zeit immer schwieriger zu erreichen ist. Einige Tools setzen dagegen auch Benutzererfahrung, Besucherbindung und ROI-basierte Recherche, um den richten Schwellenwert für eine gute Bewertung zu bestimmen. Berücksichtigen Sie auch, dass nicht alle Tools die spätere Lade-Performance der Seite berücksichtigen. Das HubSpot-Modulsystem beispielsweise trennt CSS und JS für einzelne Module und lädt diese Elemente nur, wenn das Modul tatsächlich auf der Seite platziert wird. Dies kann zu mehreren kleineren CSS-Dateien führen, die von Google Page Speed Insights geflaggt werden könnten. Die Realität ist jedoch, dass Sie beim nächsten Laden der Seite kein CSS oder JS für Module herunterladen müssen, die sich auf der nächsten Seite wiederholen – sie werden im Cache gespeichert. Stattdessen müssen Sie nur die Dateien für Module herunterladen, die Sie noch nicht gesehen haben, und das sind dann Kilobytes anstelle einer monolithischen Datei."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei bewertenden Tools sollten Sie mehrere Tools verwenden und bei jedem den bestmöglichen Score anstreben. Ihnen sollte jedoch bewusst sein, dass sie unterschiedliche Gewichtungen vornehmen. Bemühungen, die einen Score in einem Tool verbessern können, haben in einem anderen möglicherweise nicht den gleichen Effekt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beliebte Tools für Performance-Tests:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://website.grader.com/",
          children: "Website Grader"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://gtmetrix.com/",
          children: "GTMetrix"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/speed/pagespeed/insights/",
          children: "Google Page Speed Insights"
        }), " und andere ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/speed-tools",
          children: "Google-Performance-Tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.pingdom.com/",
          children: "Pingdom"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.webpagetest.org/",
          children: "WebPageTest"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Verwandtes Thema:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/performance-scoring/",
          children: "Wie die Performance-Bewertung von Lighthouse funktioniert"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Roadmap für die Website-Optimierung (Core Web Vitals) | Mark Ryba"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}