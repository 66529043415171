"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937503;
const slug = exports.slug = 'guides/api/crm/extensions/calling-sdk';
const title = exports.title = 'API do CRM | SDK de extensões de chamada';
const name = exports.name = 'vNext DP de documentos - SDK das extensões de chamadas';
const metaDescription = exports.metaDescription = 'Visão geral do SDK de extensões de chamada';
const toc = exports.toc = [{
  "depth": 0,
  "id": "execute-a-demonstra%C3%A7%C3%A3o-do-aplicativo-de-chamadas",
  "label": "Execute a demonstração do aplicativo de chamadas",
  "parent": null
}, {
  "depth": 1,
  "id": "instalar-a-demonstra%C3%A7%C3%A3o-do-aplicativo-de-chamadas",
  "label": "Instalar a demonstração do aplicativo de chamadas",
  "parent": "execute-a-demonstra%C3%A7%C3%A3o-do-aplicativo-de-chamadas"
}, {
  "depth": 1,
  "id": "iniciar-o-aplicativo-de-chamadas-do-hubspot",
  "label": "Iniciar o aplicativo de chamadas do HubSpot",
  "parent": "execute-a-demonstra%C3%A7%C3%A3o-do-aplicativo-de-chamadas"
}, {
  "depth": 0,
  "id": "instalar-o-sdk-de-extens%C3%B5es-de-chamadas-no-seu-aplicativo-de-chamadas",
  "label": "Instalar o SDK de extensões de chamadas no seu aplicativo de chamadas",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-o-sdk-de-extens%C3%B5es-de-chamada",
  "label": "Usar o SDK de extensões de chamada",
  "parent": null
}, {
  "depth": 0,
  "id": "teste-seu-aplicativo",
  "label": "Teste seu aplicativo",
  "parent": null
}, {
  "depth": 1,
  "id": "usar-o-ponto-de-extremidade-das-configura%C3%A7%C3%B5es-de-chamada",
  "label": "Usar o ponto de extremidade das configurações de chamada",
  "parent": "teste-seu-aplicativo"
}, {
  "depth": 1,
  "id": "substitua-as-configura%C3%A7%C3%B5es-de-extens%C3%A3o-utilizando-localstorage",
  "label": "Substitua as configurações de extensão utilizando localStorage",
  "parent": "teste-seu-aplicativo"
}, {
  "depth": 0,
  "id": "preparar-o-aplicativo-para-produ%C3%A7%C3%A3o",
  "label": "Preparar o aplicativo para produção",
  "parent": null
}, {
  "depth": 0,
  "id": "publicar-o-aplicativo-de-chamadas-no-marketplace-da-hubspot",
  "label": "Publicar o aplicativo de chamadas no marketplace da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "eventos",
  "label": "Eventos",
  "parent": null
}, {
  "depth": 1,
  "id": "enviar-mensagens-para-o-hubspot",
  "label": "Enviar mensagens para o HubSpot",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "inicializado",
  "label": "inicializado",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "userloggedin",
  "label": "userLoggedIn",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "userloggedout",
  "label": "userLoggedOut",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "outgoingcall",
  "label": "outgoingCall",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "callanswered",
  "label": "callAnswered",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "callended",
  "label": "callEnded",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "callcompleted",
  "label": "callCompleted",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "senderror",
  "label": "sendError",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "resizewidget",
  "label": "resizeWidget",
  "parent": "eventos"
}, {
  "depth": 1,
  "id": "receber-mensagens-do-hubspot",
  "label": "Receber mensagens do HubSpot",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "onready",
  "label": "onReady",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "ondialnumber",
  "label": "onDialNumber",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "onengagementcreated",
  "label": "onEngagementCreated",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "oncreateengagementsucceeded",
  "label": "onCreateEngagementSucceeded",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "oncreateengagementfailed",
  "label": "onCreateEngagementFailed",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "onvisibilitychanged",
  "label": "onVisibilityChanged",
  "parent": "eventos"
}, {
  "depth": 2,
  "id": "defaulteventhandler",
  "label": "defaultEventHandler",
  "parent": "eventos"
}, {
  "depth": 0,
  "id": "sdk-de-chamadas-%7C-perguntas-frequentes",
  "label": "SDK de chamadas | Perguntas frequentes",
  "parent": null
}, {
  "depth": 0,
  "id": "como-a-autentica%C3%A7%C3%A3o-do-usu%C3%A1rio-%C3%A9-tratada%3F",
  "label": "Como a autenticação do usuário é tratada?",
  "parent": null
}, {
  "depth": 0,
  "id": "as-extens%C3%B5es-de-chamada-est%C3%A3o-hospedadas-em-uma-cdn%3F",
  "label": "As Extensões de chamada estão hospedadas em uma CDN?",
  "parent": null
}, {
  "depth": 0,
  "id": "quando-um-engajamento-deve-ser-criado-ou-atualizado%3F",
  "label": "Quando um engajamento deve ser criado ou atualizado?",
  "parent": null
}, {
  "depth": 0,
  "id": "quais-escopos-s%C3%A3o-necess%C3%A1rios-como-parte-da-integra%C3%A7%C3%A3o%3F",
  "label": "Quais escopos são necessários como parte da integração?",
  "parent": null
}, {
  "depth": 0,
  "id": "esta-funcionalidade-pode-ser-adicionada-a-um-aplicativo-existente-no-marketplace-ou-preciso-criar-um-novo-aplicativo%3F",
  "label": "Esta funcionalidade pode ser adicionada a um aplicativo existente no marketplace ou preciso criar um novo aplicativo?",
  "parent": null
}, {
  "depth": 0,
  "id": "posso-integrar-meu-aplicativo-de-soft-phone-existente-ao-sdk%3F",
  "label": "Posso integrar meu aplicativo de soft phone existente ao SDK?",
  "parent": null
}, {
  "depth": 0,
  "id": "os-usu%C3%A1rios-podem-usar-v%C3%A1rias-integra%C3%A7%C3%B5es-ao-mesmo-tempo%3F",
  "label": "Os usuários podem usar várias integrações ao mesmo tempo?",
  "parent": null
}, {
  "depth": 0,
  "id": "os-usu%C3%A1rios-gratuitos-podem-instalar-integra%C3%A7%C3%B5es-de-aplicativos%3F",
  "label": "Os usuários gratuitos podem instalar integrações de aplicativos?",
  "parent": null
}, {
  "depth": 0,
  "id": "se-um-usu%C3%A1rio-j%C3%A1-tiver-meu-aplicativo-instalado%2C-a-integra%C3%A7%C3%A3o-ser%C3%A1-exibida-automaticamente%3F",
  "label": "Se um usuário já tiver meu aplicativo instalado, a integração será exibida automaticamente?",
  "parent": null
}, {
  "depth": 0,
  "id": "qualquer-usu%C3%A1rio-pode-instalar-ou-desinstalar-um-aplicativo%3F",
  "label": "Qualquer usuário pode instalar ou desinstalar um aplicativo?",
  "parent": null
}, {
  "depth": 0,
  "id": "como-posso-criar-uma-propriedade-de-chamada-personalizada%3F",
  "label": "Como posso criar uma propriedade de chamada personalizada?",
  "parent": null
}, {
  "depth": 0,
  "id": "posso-fazer-uma-chamada-a-partir-de-um-objeto-personalizado%3F",
  "label": "Posso fazer uma chamada a partir de um objeto personalizado?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      ul: "ul",
      h3: "h3",
      code: "code",
      pre: "pre",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "SDK de extensões de chamada"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " nossos parceiros de aplicativos de chamadas não precisam mais criar e atualizar engajamentos de chamadas manualmente; o HubSpot fará isso por eles. Saiba mais ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "aqui"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/calling-extensions-sdk",
        children: "SDK de extensões de chamada"
      }), " permitem que os aplicativos forneçam uma opção de chamadas personalizadas para os usuários do HubSpot diretamente de um registro no CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma extensão de chamadas consiste em três componentes principais:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk",
          children: "SDK de extensões de chamada"
        }), ", um SDK JavaScript que permite a comunicação entre seu aplicativo e o HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "pontos de extremidade de configurações de chamada"
        }), " são usados para definir as configurações de chamadas para o seu aplicativo. Cada conta da HubSpot conectada ao seu aplicativo usará essas configurações."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "iframe de chamadas"
        }), ", que é onde seu aplicativo aparece para usuários do HubSpot e é configurado usando as configurações de chamada."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter mais informações sobre a experiência de chamadas no aplicativo, examine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool#call-from-your-phone",
        children: "este artigo da base de conhecimento"
      }), ". Depois que o aplicativo ativado de extensão de chamada estiver conectado ao HubSpot, ele aparecerá como uma opção no alternador de chamadas sempre que um usuário ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
        children: "fizer uma chamada do HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se não tiver um aplicativo, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#app-developer-accounts",
        children: "criar um da sua conta do desenvolvedor da HubSpot"
      }), ". Se você ainda não tiver uma conta de desenvolvedor da HubSpot, faça login ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " apenas chamadas de saída são suportadas no momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Execute a demonstração do aplicativo de chamadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você tem a opção de testar o SDK de extensões de chamadas em dois aplicativos de demonstração diferentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-minimal-js",
          children: " demo-minimal-js"
        }), " apresenta uma implementação mínima do SDK usando JavaScript, HTML e CSS. Veja como o SDK é instanciado em", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/project-demo-v1/demos/demo-minimal-js/index.js",
          children: " index.js"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-react-ts",
          children: " demo-react-ts"
        }), " apresenta uma implementação real do SDK usando React, TypeScript e componentes estilizados para atuar como um modelo para o aplicativo. Veja como o SDK é instanciado em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/master/demos/demo-react-ts/src/hooks/useCti.ts",
          children: "useCti.ts"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " esses aplicativos de demonstração não são aplicativos de chamadas totalmente funcionais e usam dados simulados para fornecer uma experiência mais realista."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Instalar a demonstração do aplicativo de chamadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode executar os aplicativos de demonstração com ou sem instalação. Para instalar a demonstração no seu ambiente local:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Instale o", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://nodejs.org/en",
            children: " Node.js "
          }), "em seu ambiente."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clone, bifurque ou ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/calling-extensions-sdk/archive/refs/heads/master.zip",
            children: "baixe o ZIP"
          }), " deste repositório."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Abra o terminal e navegue até o diretório raiz do projeto."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Execute um dos seguintes comandos:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-minimal-js && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), ":"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-react-ts && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Isso mudará para o diretório de demonstração desejado, instalará as dependências ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " necessárias para o projeto usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.npmjs.com/cli/v9",
        children: "CLI npm"
      }), " e iniciará o aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm start"
        }), " abrirá automaticamente uma nova guia no seu navegador em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://localhost:9025/",
          children: "https://localhost:9025/"
        }), ", e talvez seja necessário ignorar um aviso \"Sua conexão não é segura\" para acessar o aplicativo."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Iniciar o aplicativo de chamadas do HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Acesse os registros:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["**Contatos: **na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contatos > Contatos"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["**Empresa: **na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Contatos > Empresas"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Abra o console de desenvolvedor do navegador e execute o seguinte comando:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se você tiver concluído as etapas de instalação, para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-react-ts"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'local');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você ignorou as etapas de instalação:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app:js');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), ":"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Atualize a página e clique no ícone ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Chamar"
          }), " na barra lateral esquerda. Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Chamar de"
          }), " e selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), " do aplicativo de demonstração da etapa 2 (por exemplo, Aplicativo de Demonstração Local, Aplicativo de Demonstração JS, Aplicativo de Demonstração React)."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/call-app-in-record.png",
            alt: "call-app-in-record"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Chamar"
          }), " para ver como o aplicativo de demonstração se integra ao HubSpot por meio do SDK de extensões de chamadas. Você também pode ver os eventos registrados no console de desenvolvedor do navegador."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/calling-sdk-in-app.png",
        alt: "calling-sdk-in-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instalar o SDK de extensões de chamadas no seu aplicativo de chamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para adicionar o SDK de extensões de chamadas como uma dependência do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " no seu aplicativo de chamadas:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para npm, execute:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i --save @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para yarn, execute:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "yarn add @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar o SDK de extensões de chamada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O SDK de extensões de chamadas expõe uma API simples para a troca de mensagens entre o HubSpot e um aplicativo de chamadas. As mensagens são enviadas por meio de métodos expostos pelo SDK e recebidas por meio do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), ". Você pode encontrar uma lista completa dos eventos disponíveis na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events",
        children: "seção Eventos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A seguir, uma descrição dos eventos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Número de discagem:"
        }), " o HubSpot envia o evento de número de discagem."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Chamada de saída iniciada: **o aplicativo notifica o HubSpot quando a chamada é iniciada."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Criar engajamento: **o HubSpot cria", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: " um engajamento de chamada"
        }), " com informações mínimas, se solicitado pelo aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Engajamento criado:"
        }), " o HubSpot criou um engajamento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "EngagementId enviado para o aplicativo:"
        }), " o HubSpot envia o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), " para o aplicativo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Chamada encerrada: **o aplicativo notifica quando a chamada é encerrada."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Chamada concluída: **o aplicativo notifica quando o usuário termina a experiência no aplicativo."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Atualizar engajamento: **o aplicativo busca o engajamento pelo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), " e o mescla e atualiza com detalhes adicionais da chamada. Saiba mais sobre", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls#update-calls",
          children: " atualizar um engajamento de chamada através da API"
        }), "ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "através do SDK"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para começar, crie uma instância do objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CallingExtensions"
      }), ". Você pode definir o comportamento da sua extensão fornecendo o objeto de uma opção ao criar sua instância de extensões. O objeto desta opção fornece um campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), " onde você pode especificar o comportamento da sua extensão. O bloco de código a seguir ilustra as opções disponíveis e os manipuladores de eventos que você pode definir:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import CallingExtensions from \"@hubspot/calling-extensions-sdk\";\n\nconst options = {\n  /** @property {boolean} debugMode - Whether to log various inbound/outbound debug messages to the console. If false, console.debug will be used instead of console.log */\n  debugMode: boolean,\n  // eventHandlers handle inbound messages\n  eventHandlers: {\n    onReady: () => {\n      /* HubSpot is ready to receive messages. */\n    },\n    onDialNumber: event => {\n      /* HubSpot sends a dial number from the contact */\n    },\n    onCreateEngagementSucceeded: event => {\n      /* HubSpot has created an engagement for this call. */\n    }\n    onEngagementCreatedFailed: event => {\n      /* HubSpot has failed to create an engagement for this call. */\n    }\n    onUpdateEngagementSucceeded: event => {\n      /* HubSpot has updated an engagement for this call. */\n    },\n    onUpdateEngagementFailed: event => {\n      /* HubSpot has failed to update an engagement for this call. */\n    }\n    onVisibilityChanged: event => {\n      /* Call widget's visibility is changed. */\n    }\n  }\n};\n\nconst extensions = new CallingExtensions(options);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Teste seu aplicativo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para iniciar as extensões de chamadas do iFrame para usuários finais, o HubSpot exige os parâmetros do iFrame descritos abaixo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n   name: string /* The name of your calling app to display to users. */,\n   url: string  /* The URL of your calling app, built with the Calling Extensions SDK */,\n   width: number /* The iFrame's width */,\n   height: number /* The iFrame's height */,\n   isReady: boolean /* Whether the widget is ready for production (defaults to true) */,\n   supportsCustomObjects : true /* Whether calls can be placed from a custom object */\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usar o ponto de extremidade das configurações de chamada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando sua ferramenta de API (por exemplo, Postman), envie a carga útil a seguir para a API de configurações do HubSpot. Certifique-se de obter o APP_ID do seu aplicativo de chamadas e o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), " do aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Observação: **o sinalizador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isReady"
        }), " indica se o aplicativo está pronto para produção. Este sinalizador deve ser definido como falso durante o teste."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":false}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Substitua as configurações de extensão utilizando localStorage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode substituir qualquer uma das suas configurações de extensão para fins de teste. Abra o console de desenvolvedor do navegador a partir de uma guia do HubSpot, edite as configurações abaixo e execute o comando:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const myExtensionSettings = {\n  isReady: true,\n  name: 'My app name',\n  url: 'My local/qa/prod URL',\n};\n\nlocalStorage.setItem(\n  'LocalSettings:Calling:CallingExtensions',\n  JSON.stringify(myExtensionSettings)\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preparar o aplicativo para produção"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você já usou o ponto de extremidade POST ao testar seu aplicativo, poderá usar o ponto de extremidade PATCH para alterar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady"
      }), " para true. Caso contrário, usando sua ferramenta de API (por exemplo, Postman), envie esta carga útil para a API de configurações da HubSpot. Certifique-se de obter o APP_ID do seu aplicativo de chamadas e o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), " do aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":true}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Publicar o aplicativo de chamadas no marketplace da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de configurar o aplicativo, a etapa final é listar seu aplicativo de chamadas no marketplace da HubSpot. Você pode encontrar mais detalhes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "aqui"
      }), ". Você também pode optar por não o listar no marketplace se for apenas para uso interno."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eventos de chamadas disponíveis:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Enviar mensagens para o HubSpot", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#demo-minimal-js"
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "#yarn"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#initialized",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "initialized"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedIn",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedIn"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedOut"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut"
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#outgoingCall",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "outgoingCall"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callAnswered",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callAnswered"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callEnded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callEnded"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callCompleted"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#sendError",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "sendError"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#resizeWidget",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "resizeWidget"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Receber mensagens do HubSpot:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onReady",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onReady"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onDial",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onDialNumber"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onEngagementCreated"
              })
            }), " (descontinuado)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onCreateEngagementSucceeded"
              })
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onUpdateEngagementFailed"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onVisibilityChanged"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#defaultEventHandler",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "defaultEventHandler"
              })
            })]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Enviar mensagens para o HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " fornece os seguintes manipuladores de eventos que você pode invocar para enviar mensagens para o HubSpot ou para especificar outro comportamento associado. Veja os exemplos abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "inicializado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem indicando que o soft phone está pronto para interação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const payload = {\n  isLoggedIn: boolean,\n};\n\nextensions.initialized(payload);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isLoggedIn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Criar menus que se adaptam ao fato de o usuário estar conectado ou não."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedIn"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem indicando que o usuário fez login."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// This message is only needed when user isn't logged in when initialized\nextensions.userLoggedIn();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedOut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem indicando que o usuário fez logout."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.userLoggedOut();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "outgoingCall"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem para notificar o HubSpot de que uma chamada de saída foi iniciada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string /** @deprecated Use toNumber instead **/,\n  callStartTime: number,\n  createEngagement: true,\n  toNumber: string,\n  fromNumber: string,\n};\nextensions.outgoingCall(callInfo);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callStartTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hora de início da chamada, em milissegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createEngagement"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se o HubSpot deve criar um engajamento para esta chamada. Quando verdadeiro, o HubSpot responderá com ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: "onCreateEngagementSucceeded"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: "onCreateEngagementFailed"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de telefone do destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número do chamador. Parâmetro obrigatório."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callAnswered"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem para notificar o HubSpot de que uma chamada de saída está sendo atendida."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.callAnswered();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callEnded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem para notificar o HubSpot de que a chamada foi encerrada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call ended event, the user can navigate away, can close the call widget.\nextensions.callEnded({\n  engagementId: number,\n  callEndStatus: EndStatus,\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID de engajamento criado pelo HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callEndStatus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeração"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O status da chamada uma vez encerrada. Status disponíveis:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_COMPLETED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_FAILED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_CANCELED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_BUSY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_NO_ANSWER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_REJECTED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_MISSED"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callCompleted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Envia uma mensagem para notificar o HubSpot de que a chamada foi concluída. As propriedades de engajamento são de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/calls",
        children: "propriedade da HubSpot"
      }), " e não precisam mais ser criadas ou atualizadas manualmente (consulte o destaque)."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hideWidget"
        }), " será ignorada quando o usuário estiver em uma fila de tarefas com o tipo de tarefa ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Call"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call completed event, HubSpot will\n//   1) insert the engagement into the timeline\n//   2) set the default associations on the engagement\n//   3) closes the widget unless `hideWidget` is set to false.\n//   4) update the engagement with any engagement properties\nconst data = {\n  engagementId: number,\n  hideWidget: boolean,\n  engagementProperties: { [key: string]: string },\n  externalCallId: number,\n}\nextensions.callCompleted(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID de engajamento criado pelo HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hideWidget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se o widget deve ser ocultado quando a chamada é encerrada. Parâmetro opcional. Assume o padrão true"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Aceite os engajamentos hs ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/calls#properties",
              children: "adicionando propriedades"
            }), ". Faz com que o HubSpot responda com ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementSucceeded",
              children: "onUpdateEngagementSucceeded"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementFailed",
              children: "onUpdateEngagementFailed"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalCallId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um ID de chamada criado por um aplicativo de chamadas. Usado para habilitar as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/third-party-calling",
              children: "chamadas no help desk"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendError"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem para notificar o HubSpot de que o aplicativo de chamada encontrou um erro."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the sendError event, HubSpot will display an alert popup to the user with the error message provided.\nconst data = {\n  message: string,\n};\nextensions.sendError(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensagem de erro a ser exibida no pop-up de alerta."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "resizeWidget"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Envia uma mensagem para notificar o HubSpot de que o aplicativo de chamada precisa ser redimensionado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the resizeWidget event, HubSpot will use the provided height and width to resize the call widget.\nconst data = {\n  height: number,\n  width: number,\n};\nextensions.resizeWidget(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Altura desejada do widget da chamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Largura desejada do widget da chamada."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Receber mensagens do HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " fornece manipuladores de eventos que você pode invocar ao receber mensagens no HubSpot ou para especificar outro comportamento associado. Veja os exemplos abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onReady"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mensagem indicando que o HubSpot está pronto para receber mensagens."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example snippet for handling onReady event\nonReady() {\n    extensions.initialized(payload);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onDialNumber"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este evento será disparado quando um usuário no HubSpot fizer uma chamada. O evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onDialNumber"
      }), " fornecerá uma payload que inclui todos os campos pertinentes associados à chamada. Estes campos são detalhados na tabela abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onDialNumber(data) {\n  const {\n    phoneNumber: string,\n    ownerId: number,\n    subjectId: number,\n    objectId: number,\n    objectType: CONTACT | COMPANY,\n    portalId: number,\n    countryCode: string,\n    calleeInfo {\n        calleeId: number,\n        calleeObjectTypeId: string,\n    },\n    startTimestamp: number,\n    toPhoneNumberSrc: string,\n   } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Propriedade | Tipo | Descrição |\n| --- | --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "phoneNumber"
      }), " | String | O número de telefone da pessoa que foi chamada. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ownerId"
      }), " | String | Número | O ID do usuário conectado (no HubSpot). |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subjectId"
      }), " | Número | O ID do assunto. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " | Número | Tipo de objeto do número de telefone. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " | String | O tipo de objeto associado ao número de telefone discado (por exemplo, o número de telefone do contato ou da empresa). Os possíveis valores são ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"CONTACT\""
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"COMPANY\""
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " | Número | O ID do portal da HubSpot. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "countryCode"
      }), " | String | O código do país do número de telefone. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calleeInfo"
      }), " | Matriz | Mais informações sobre o autor da chamada. Deve incluir o seguinte:", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeID: number"
          })
        }), (0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeObjectTypeId: string"
          })
        })]
      }), " |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "startTimestamp"
      }), " | Número | Data/hora do início da chamada. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toPhoneNumberSrc"
      }), " | String | O nome da ' ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/create-and-edit-properties",
        children: "propriedade dos números de telefone no HubSpot."
      }), " A propriedade pode ser um valor de propriedade padrão ou uma propriedade personalizada. Por exemplo, um contato pode ter três números de telefone de contato, um pode ser rotulado ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Escritório"
      }), ", outro pode ser rotulado ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pessoal"
      }), " e o outro, ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Celular"
      }), ". |"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onEngagementCreated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Descontinuado. Em vez disso, use o filtro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#onCreateEngagementSucceeded",
        children: "onCreateEngagementSucceeded"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/** @deprecated Use onCreateEngagementSucceeded instead **/\nonEngagementCreated(data) {\n    const {\n      engagementId: number,\n    } = data;\n      ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID de engajamento criado pelo HubSpot."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementSucceeded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot envia uma mensagem para notificar o parceiro do aplicativo de chamada de que a atualização do engajamento foi bem-sucedida."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementSucceeded: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementFailed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot envia uma mensagem para notificar ao parceiro do aplicativo de chamada de que a criação do engajamento falhou."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementFailed: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onVisibilityChanged"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mensagem que indica se o usuário minimizou ou ocultou o aplicativo de chamada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onVisibilityChanged(data) {\n    const { isMinimized, isHidden } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "defaultEventHandler"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Manipulador padrão para eventos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "defaultEventHandler(event) {\n   console.info(\"Event received. Do you need to handle it?\", event);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SDK de chamadas | Perguntas frequentes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como a autenticação do usuário é tratada?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O aplicativo de chamadas deve lidar com a autenticação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "As Extensões de chamada estão hospedadas em uma CDN?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sim. Você pode instalar o SDK de Extensões de Chamada via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.jsdelivr.com/",
        children: "jsDeliver"
      }), ". Por exemplo, para instalar calling-extensions-sdk@0.2.2, você pode usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js",
        children: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quando um engajamento deve ser criado ou atualizado?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um usuário pode iniciar uma chamada diretamente da interface do HubSpot e fora dela (por exemplo, aplicativo móvel, número redirecionado etc.) Se uma chamada for iniciada a partir da interface do HubSpot, o HubSpot criará um engajamento de chamada e o enviará para o aplicativo de chamadas. Quando a chamada terminar, o aplicativo poderá atualizar esse engajamento com detalhes adicionais da chamada. Se uma chamada for iniciada fora da interface do usuário do HubSpot, o aplicativo deverá criar o engajamento de chamada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quais escopos são necessários como parte da integração?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adicionar contatos e escopos de linha do tempo são obrigatórios. Esses escopos garantem que o aplicativo tenha acesso aos contatos e à capacidade de criar e atualizar engajamentos de chamadas no CRM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Esta funcionalidade pode ser adicionada a um aplicativo existente no marketplace ou preciso criar um novo aplicativo?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você já tiver um aplicativo existente que atenda ao caso de uso de chamadas, poderá adicionar diretamente essa funcionalidade a ele. Todos os clientes que já têm o aplicativo instalado terão acesso a essa nova funcionalidade sem precisar instalá-lo novamente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Posso integrar meu aplicativo de soft phone existente ao SDK?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sim, é muito fácil integrar seu aplicativo de soft phone existente. Basta seguir as etapas na documentação acima para colocar seu aplicativo em funcionamento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Os usuários podem usar várias integrações ao mesmo tempo?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sim, os usuários podem usar várias integrações de chamadas de terceiros ao mesmo tempo. Eles podem usar a opção de troca de provedor mostrada depois de clicar no botão de chamada para alternar entre os provedores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Os usuários gratuitos podem instalar integrações de aplicativos?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sim, todos os usuários podem instalar o aplicativo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Se um usuário já tiver meu aplicativo instalado, a integração será exibida automaticamente?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sim, se um usuário já tiver instalado o aplicativo e você estiver atualizando esse mesmo aplicativo com as extensões de chamadas, a integração aparecerá automaticamente. Atualmente, não há maneira de o desenvolvedor habilitar o aplicativo de chamadas apenas para um subconjunto de clientes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Qualquer usuário pode instalar ou desinstalar um aplicativo?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Não, apenas os usuários com as permissões necessárias podem instalar e desinstalar um aplicativo. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/edit-user-permissions",
        children: "analisar as permissões de usuário"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como posso criar uma propriedade de chamada personalizada?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sim, é possível criar uma propriedade personalizada utilizando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Posso fazer uma chamada a partir de um objeto personalizado?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sim, as integrações podem iniciar chamadas de objetos personalizados, desde que somente usem o SDK para criar a chamada. Cada integração precisará confirmar que somente usa o SDK de chamadas para criar chamadas e notificar o HubSpot no evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Primeiro, verifique se a integração usa o SDK de chamadas para criar engajamentos no evento outgoingCall:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "outgoingCall({ createEngagement: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createEngagement"
      }), " for verdadeiro, saiba como atualizar as informações do seu aplicativo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-your-app-ready-for-production",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja o exemplo para todo o evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string, // optional unless call is initiated by the widget\n  createEngagement: true // whether HubSpot should create an engagement for this call\n  callStartTime: number // optional unless call is initiated by the widget\n};\nextensions.outgoingCall(callInfo);\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}