"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107556;
const slug = exports.slug = 'guides/api/crm/objects/line-items';
const title = exports.title = 'CRM API | Line items';
const name = exports.name = 'vNext Docs DP - Line Items';
const metaDescription = exports.metaDescription = 'When a product is attached to a deal, it becomes a line item. The line items endpoints allow you to manage and sync this data.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-line-item",
  "label": "Create a line item",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-a-line-item",
  "label": "Retrieve a line item",
  "parent": null
}, {
  "depth": 0,
  "id": "update-a-line-item",
  "label": "Update a line item",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-a-line-item",
  "label": "Delete a line item",
  "parent": null
}, {
  "depth": 0,
  "id": "line-item-properties",
  "label": "Line item properties",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Line Items"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, line items are individual instances of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "products"
      }), ". When a product is attached to a deal, it becomes a line item. You can create line items that are unique to an individual quote, but they will not be added to your product library. The line items endpoints allow you to manage this data and sync it between HubSpot and other systems."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " when creating a set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "quotes"
      }), " for sales reps to send to potential buyers, you can use this API to create standalone line items per quote, as well as line items that are attached to existing products."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a line item"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a line item, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items"
      }), ". In the post body, include the line item's details, such as name, quantity, and price."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a line item based on an existing product (created through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "products API"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "in HubSpot"
      }), "), include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_product_id"
      }), " in the post body."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also associate the line item with deals, quotes, invoices, payment links or subscriptions, by including an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " array in the post body. For example, the post body below would create a line item named \"New standalone line item\" that's associated with a deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "12345"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"price\": 10,\n    \"quantity\": 1,\n    \"name\": \"New standalone line item\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 12345\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Line items belong to one single parent object. If associating objects, line items should be individual to each object. For example, if you're creating a deal and a quote, you should create one set of line items for the deal, and another set for the quote. This will help streamline CRM data across objects and prevent unexpected data loss when needing to modify line items (e.g., deleting a quote will delete the quote's line items, and if those line items are associated with a deal, the deals line items will also be deleted)."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "price"
          }), " specified within the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "properties"
          }), " field ", (0, _jsxRuntime.jsx)("u", {
            children: "cannot"
          }), " be negative."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["The line items ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Term"
          }), " property (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_recurring_billing_period"
          }), ") accepts ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.oracle.com/javase/8/docs/api/java/time/Period.html#:~:text=exceeds%20an%20int-,parse,-public%20static%C2%A0",
            children: "ISO-8601 period formats"
          }), " of PnYnMnD and PnW."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve a line item"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve line items individually or in bulk."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a specific line item, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items/{lineItemId}"
        }), " where ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lineItemId"
        }), " is the ID of the line item."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all line items, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request URL, you can include the following parameters:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned in the response. If any of the specified properties are not present on the requested object(s), they will be ignored."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned along with their history of previous values. If any of the specified properties are not present on the requested object(s), they will be ignored."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update a line item"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update a line item, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), ", where ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " is the ID of the line item."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the post body, include the property values that you want to update. You ", (0, _jsxRuntime.jsx)("u", {
        children: "cannot"
      }), " update associations through this method. Instead you'll need to use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request body might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/line_item/{lineItemId}\n{\n  \"properties\": {\n    \"price\": 25,\n    \"quantity\": 3,\n    \"name\": \"Updated line item\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a line item"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a line item, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), ", where ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " is the ID of the line item."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Line item properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When managing your line item data, you may want to use some of the common properties in the table below. To get all line item properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/line_item"
      }), ". Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Label in UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the line item."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Full description of the product"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "SKU"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unique product identifier"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Billing start date"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Start date of a fixed billing period"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Billing end date"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "End date of a fixed billing period"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recurringbillingfrequency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Billing frequency"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "How often a line item with recurring billing is billed. It informs the pricing calculation for deals and quotes. Line items with one-time billing aren't included."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quantity"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "How many units of a product are included in this line item"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unit price"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The cost of the product"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Net price"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The total cost of the line item (i.e., the quantity times the unit price)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency code for the line item"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}