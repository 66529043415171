"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890255;
const slug = exports.slug = 'reference/cms/hubl/variables-macros-syntax';
const title = exports.title = 'Variáveis do HubL e sintaxe de macros';
const name = exports.name = '[new] HubL variables & macros syntax_PT';
const metaDescription = exports.metaDescription = 'Saiba como usar as variáveis e macros do HubL para criar páginas dinâmicas no HubSpots CMS e em e-mails. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vari%C3%A1veis",
  "label": "Variáveis",
  "parent": null
}, {
  "depth": 0,
  "id": "macros",
  "label": "Macros",
  "parent": null
}, {
  "depth": 0,
  "id": "call",
  "label": "Call",
  "parent": null
}, {
  "depth": 0,
  "id": "import",
  "label": "Import",
  "parent": null
}, {
  "depth": 0,
  "id": "from",
  "label": "From",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-dentro-de-loops",
  "label": "Variáveis dentro de loops",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      strong: "strong",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variáveis do HubL e sintaxe de macros"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubL utiliza variáveis para armazenar e produzir valores para o modelo. As variáveis podem ser usadas na lógica do modelo ou iteradas em loops. Além das variáveis, as macros são outra ferramenta útil para imprimir seções de código repetitivas, mas dinâmicas nos seus modelos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Variáveis são expressões delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}}"
      }), ". A sintaxe básica das variáveis é a seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// variables\n{{ variable }}\n{{ dict.attribute }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As variáveis são uma palavra em uma expressão ou um atributo de um dicionário. O HubL usa estruturas de dados baseadas em Python chamadas dicionários ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "dicts"
      }), " para armazenar vários conjuntos de variáveis. Por exemplo, o HubSpot usa um \"content\" de dicionário para abrigar muitos atributos que pertencem ao conteúdo criado com esse modelo. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.absolute_url"
      }), " imprime a URL da parte específica do conteúdo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot tem muitas variáveis predefinidas que podem ser usadas em toda a sua página, blog e modelos de e-mail. Temos uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "lista de referência de variáveis"
      }), ", você também pode ver as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "informações do desenvolvedor"
      }), " ao navegar em qualquer página da sua conta para ver as variáveis disponíveis dentro dessa página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além de imprimir os valores de variáveis e atributos de dicionário em um modelo, você também pode definir suas próprias variáveis. Você pode armazenar strings, booleanos, inteiros, sequências ou criar dicionários dentro de uma única variável. As variáveis são definidas dentro de delimitadores de instrução usando a palavra \"set\". Uma vez armazenadas, as variáveis podem então ser impressas declarando o nome da variável como uma expressão. Abaixo você pode ver vários tipos de informações armazenadas em variáveis e depois impressas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As variáveis devem ser palavras únicas ou usar sublinhados no lugar de espaços (ou seja, my_variable). ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "O HubL não suporta nomes de variáveis separadas por hifens."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_var = \"This is a string value stored in a variable\" %}\n{{ string_var}}\n\n{% set bool_var = True %}\n{{ bool_var}}\n\n{% set int_var = 53 %}\n{{ int_var}}\n\n{% set seq_var = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ seq_var}}\n\n{% set var_one = \"String 1\" %}\n{% set var_two = \"String 2\" %}\n{% set sequence = [var_one,  var_two] %}\n{{ sequence }}\n\n{% set dict_var = {\"name\": \"Item Name\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{{ dict_var.price }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This is a string value stored in a variable True 53 [Item1, Item2, Item3]\n[String 1, String 2] $20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada exemplo acima armazena um tipo diferente de variável, sendo que o último exemplo armazena duas variáveis diferentes em uma sequência."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além de imprimir valores, as variáveis podem ser usadas em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "instruções If"
      }), ", como em parâmetros de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filtro"
      }), " e de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "função"
      }), ", além de serem iteradas em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "loops For"
      }), " (somente variáveis de sequência)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É comum usar variáveis para definir valores CSS comuns em sua folha de estilo. Por exemplo, se você usa uma cor repetidas vezes ao longo do seu arquivo CSS. Dessa forma, se você precisar mudar essa cor, poderá alterar o valor da variável, e todas as referências a ela serão atualizadas na próxima vez que você publicar o arquivo."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- defines variable and assigns HEX color -->\n{% set primaryColor = \"#F7761F\" %}\n\na {\n    color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Macros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As macros do HubL permitem imprimir vários comandos com um valor dinâmico. Por exemplo, se houver um bloco de código que você sempre escreve, uma macro poderá ser uma boa solução, pois ela escreverá o bloco de código trocando alguns argumentos determinados por você."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A macro é definida, nomeada e recebe argumentos dentro de uma instrução do HubL. A macro é então chamada em uma instrução que passa seus valores dinâmicos, e o bloco de código final é impresso com os argumentos dinâmicos. A sintaxe básica de uma macro é a seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2)  %}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{% endmacro %}\n{{ name_of_macro(\"value to pass to argument 1\", \"value to pass to argument 2\")  }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se sua macro retornar espaços em branco na forma de novas linhas, você poderá remover os espaços em branco nos modelos manualmente. Se você adicionar um sinal de menos (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") no início ou no fim de um bloco, comentário ou expressão de variável, os espaços em branco antes ou depois desse bloco serão removidos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2) -%}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{%- endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo está uma aplicação prática de uma macro para imprimir uma propriedade CSS3 com os diferentes prefixos de fornecedor, com um valor dinâmico. Isso permite imprimir cinco linhas de código com uma única tag de macro."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n    {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As macros permitem ter um código recursivo. Para evitar problemas de confiabilidade e desempenho, você apenas pode aninhar macros com 20 níveis de profundidade. Se ultrapassar este limite, obterá o erro: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "max recursion limit of 20 reached for macro <your macro name>"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Call"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em alguns casos, você pode querer retornar informações dinâmicas adicionais para o bloco da macro. Por exemplo, você pode ter uma grande porção de código para retornar à macro, além dos argumentos. Você pode fazer isso usando o bloco de chamadas e chamador(). Um bloco de chamada funciona basicamente como uma macro, mas não recebe seu próprio nome. A expressão caller() especifica onde o conteúdo do bloco de chamada renderizará."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No exemplo abaixo, um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<p>"
      }), " é adicionado em uma macro, além dos dois argumentos."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro render_dialog(title, class) %}\n  <div class=\"{{ class }}\">\n      <h2>{{ title }}</h2>\n      <div class=\"contents\">\n          {{ caller() }}\n      </div>\n  </div>\n {% endmacro %}\n\n {% call render_dialog(\"Hello World\", \"greeting\") %}\n     <p>This is a paragraph tag that I want to render in my.</p>\n {% endcall %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"greeting\">\n  <h2>Hello World</h2>\n  <div class=\"contents\">\n    <p>This is a simple dialog rendered by using a macro and a call block.</p>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Outra característica útil das macros é que elas podem ser usadas nos modelos. Basta importar um arquivo de modelo em outro. Para fazer isso, você precisará usar a tag ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), ". A tag import permitirá especificar um caminho de arquivo do Gerenciador de design para o modelo que contém suas macros e dar um nome às macros no modelo em que você as está incluindo. Você pode passar valores para essas macros sem precisar redefini-los."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, suponha que você tenha um arquivo de modelo .html com as duas macros a seguir. Uma macro define uma tag header e a outra gera uma tag footer. Este arquivo é salvo no Gerenciador de design com o nome ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- my_macros.html file -->\n{% macro header(tag, title_text) %}\n    <header> <{{ tag }}>{{ title_text }} </{{tag}}> </header>\n{% endmacro %}\n\n{% macro footer(tag, footer_text) %}\n     <footer> <{{ tag }}>{{ footer_text }} </{{tag}}> </footer>\n{% endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No modelo que usará essas macros, é utilizada uma tag import que especifica o caminho do arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), ". Ela também nomeia o grupo de macros (neste exemplo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "header_footer"
      }), "). As macros podem então ser executadas anexando o nome da macro ao nome dado ao modelo importado. Veja o exemplo abaixo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% import \"custom/page/web_page_basic/my_macros.html\" as header_footer %}\n{{ header_footer.header(\"h1\", \"My page title\") }}\n<p>Some content</p>\n{{ header_footer.footer(\"h3:\", \"Company footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<header><h1>My page title</h1></header>\n<p>Some content</p>\n<footer><h3>Company footer info</h3></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "From"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você quiser importar apenas macros específicas, ao invés de todas as macros contidas em um arquivo .html separado, use a tag ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "from"
      }), ". Com a tag from, especifique apenas as macros que você quer importar. Geralmente, a utilização de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), " irá proporcionar maior flexibilidade, mas esta alternativa também é suportada."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O exemplo abaixo acessa o mesmo arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), " da seção anterior deste artigo. Porém, em vez de importar todas as macros, ele acessa apenas a macro footer."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% from \"custom/page/web_page_basic/my_macros.html\" import footer %}\n{{ footer(\"h2\", \"My footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<footer><h2>My footer info</h2></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis dentro de loops"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Quaisquer variáveis definidas dentro de loops são limitadas ao escopo desse loop e não podem ser chamadas de fora do loop."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode chamar variáveis que são definidas dentro ou fora de um loop, mas não no sentido contrário."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode usar funções para silenciar objetos para valores de configuração em dicionários ou operações de lista de execução. O seguinte exemplo usa a operação de lista ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update"
        })
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj = {val : 0} %}\n{% for i in range(0, 10) %}\n   {% do obj.update({val: obj.val + i }) %}\n{% endfor %}\n{{ obj.val }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "45\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}