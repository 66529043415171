"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31316289076;
const slug = exports.slug = 'guides/apps/authentication/intro-to-auth';
const title = exports.title = 'HubSpot API | HubSpotでの認証方式';
const name = exports.name = 'APAC JAPAN (JA) | WEB | Authentication methods on HubSpot | 202007';
const metaDescription = exports.metaDescription = 'HubSpotのAPIには、OAuthとAPIキーの2種類の認証方式があります。ほとんどのエンドポイントはどちらの方式にも対応していますが、全般としてはOAuthが推奨されます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth",
  "label": "OAuth",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3",
  "label": "非公開アプリのアクセストークン",
  "parent": null
}, {
  "depth": 2,
  "id": "%E9%96%A2%E9%80%A3%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "関連ドキュメント",
  "parent": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      a: "a",
      h1: "h1",
      h2: "h2",
      pre: "pre",
      code: "code",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**2022年11月30日をもってHubSpot APIキーは廃止され、サポートも終了します。HubSpot APIキーの使用を続けると、アカウントやデータに対するセキュリティー上のリスクが生じます。なお、この廃止段階中にHubSpotでお客様のキーが無効化されることがあります。"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["認証には、代わりに非公開アプリのアクセストークンまたはOAuthを使用する必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "この変更"
        }), "について、および", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/api/migrate-an-api-key-integration-to-a-private-app",
          children: "APIキー連携を移行"
        }), "して非公開アプリを代わりに使用する方法についてご確認ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotでの認証方式"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのAPIへの呼び出しを認証するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリ"
      }), " アクセス トークンの2つの方法があります。それぞれの方法と、認証用にそれをコードに組み込む方法について、以下で詳しく説明します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以前にAPIキーを使用して認証していた場合は、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
        children: "非公開アプリ アクセス トークンの使用に移行する"
      }), "方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**複数の顧客で使用するように意図された連携や、アプリマーケットプレイスに掲載する連携は、HubSpotのOAuthプロトコルを使用したアプリとして開発する必要があります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), "を使用してリクエストを行うには、認証ヘッダーにOAuthアクセストークンを含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer C4d***sVq\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非公開アプリのアクセストークン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuthと同様に、非公開アプリのアクセストークンを使用してリクエストを行うには、認証ヘッダーにトークンを含めます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer ***-***-*********-****-****-****-************\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/working-with-oauth",
        children: "OAuthの利用"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/api/oauth-quickstart-guide",
        children: "OAuthクイックスタートガイド"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリ"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}