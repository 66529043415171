"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694135;
const slug = exports.slug = 'reference/cms/hubl/variables';
const title = exports.title = 'Variables HubL';
const name = exports.name = 'Variables HubL';
const metaDescription = exports.metaDescription = 'Les modèles HubSpot peuvent utiliser une multitude de variables prédéfinies qui peuvent être utilisées pour restituer des éléments utiles du site web et des e-mails. Cette page est une liste de référence de ces variables.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variables-disponibles-dans-tous-les-mod%C3%A8les",
  "label": "Variables disponibles dans tous les modèles",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-d-e-mail",
  "label": "Variables d'e-mail",
  "parent": null
}, {
  "depth": 1,
  "id": "variables-de-mod%C3%A8le-d-e-mail-obligatoires",
  "label": "Variables de modèle d'e-mail obligatoires",
  "parent": "variables-d-e-mail"
}, {
  "depth": 1,
  "id": "param%C3%A8tres-de-couleur-et-de-police",
  "label": "Paramètres de couleur et de police",
  "parent": "variables-d-e-mail"
}, {
  "depth": 1,
  "id": "contenu-de-l-e-mail",
  "label": "Contenu de l'e-mail",
  "parent": "variables-d-e-mail"
}, {
  "depth": 1,
  "id": "variables-d%E2%80%99e-mail-de-contenu-priv%C3%A9",
  "label": "Variables d’e-mail de contenu privé",
  "parent": "variables-d-e-mail"
}, {
  "depth": 0,
  "id": "variables-de-page-de-site-web",
  "label": "Variables de page de site web",
  "parent": null
}, {
  "depth": 1,
  "id": "variables-de-mod%C3%A8le-de-page-obligatoires",
  "label": "Variables de modèle de page obligatoires",
  "parent": "variables-de-page-de-site-web"
}, {
  "depth": 1,
  "id": "variables-de-contenu-et-de-contact",
  "label": "Variables de contenu et de contact",
  "parent": "variables-de-page-de-site-web"
}, {
  "depth": 0,
  "id": "variables-de-requ%C3%AAte-http",
  "label": "Variables de requête HTTP",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-de-blog",
  "label": "Variables de blog",
  "parent": null
}, {
  "depth": 0,
  "id": "pages-d-objets-crm-dynamiques",
  "label": "Pages d'objets CRM dynamiques",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-hubdb",
  "label": "Variables HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-de-n%C5%93uds-de-menu",
  "label": "Variables de nœuds de menu",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9diteur-dans-l-application-et-variables-d-aper%C3%A7u",
  "label": "Éditeur dans l'application et variables d'aperçu",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variables HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles HubSpot peuvent utiliser une multitude de variables prédéfinies qui peuvent être utilisées pour restituer des éléments utiles du site web et des e-mails. Cette page liste ces variables. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "Découvrez-en davantage sur la création de vos propres variables"
      }), " dans un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "modèle HubL"
      }), " ou un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La plupart des variables énumérées sur cette page sont facultatives, mais certaines sont obligatoires afin de créer des e-mails et des pages à partir de vos modèles."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les variables énumérées ci-dessous peuvent être utilisées individuellement en les entourant du délimiteur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "}}"
        }), " comme indiqué sur notre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables-macros-syntax",
          children: "page Variables et macros"
        }), ". Vous pouvez éventuellement utiliser ces variables avec d'autres éléments du modèle de langue HubL, tels que les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/loops",
          children: "boucles"
        }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/filters",
          children: "filtres"
        }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions",
          children: "fonctions"
        }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "balises"
        }), ", etc."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables disponibles dans tous les modèles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les variables HubL ci-dessous peuvent être utilisées dans les modèles d’e-mails, de pages ou de blogs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher des informations supplémentaires sur ces résultats de variables, vous pouvez utiliser le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters#pprint",
        children: "filtre pprint"
      }), ", comme indiqué ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set variable = content %}\n{{variable|pprint}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Résultat",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(ContentMeta: { \"canonicalUrl\" : \"https://www.website.com/url-slug\",\n\"deferDynamicValues\" : false, \"featuredImageAltText\" : \"\", \"featuredImageHeight\"\n: 0, \"featuredImageUrl\" : \"\", \"featuredImageWidth\" : 0, \"htmlTitle\" : \"\",\n\"linkRelCanonicalUrl\" : \"https://www.website.com/url-slug\", \"metaDescription\" :\n\"\", \"metaKeywords\" : \"\", \"name\" : \"\", \"unescapedHtmlTitle\" : \"\",\n\"unescapedMetaDescription\" : null })\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : cette variable est un dictionnaire qui stocke les propriétés de personnalisation de l'entreprise pour un contact connu. Il est possible d'accéder aux propriétés à partir de ce dictionnaire, en ajoutant un point et le nom de la propriété. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "account.name"
            }), " imprime le nom de l’entreprise d’un contact.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer le domaine de l'entreprise à partir de ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Site web"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "> Pages > Branding > Lien du logo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : cette variable est un dictionnaire qui stocke les propriétés de personnalisation du contact pour un contact connu. Il est possible d'accéder aux propriétés à partir de ce dictionnaire, en ajoutant un point et le nom de la propriété. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.firstname"
            }), " imprime le prénom d’un contact.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : cette variable est un dictionnaire qui stocke diverses propriétés relatives à un élément de contenu spécifique tel qu'un e-mail, une page ou un article."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'imprimer l'URL complète d'une page, d'un article ou de la version web d'un e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.archived"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : cette variable est définie sur true si la page ou l'e-mail a été marqué(e) comme archivé(e) par l'utilisateur."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'adresse e-mail du créateur du contenu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le prénom et le nom de famille du créateur du contenu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_username"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom d'utilisateur HubSpot du créateur du contenu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le GUID de la campagne marketing à laquelle cette page ou cet e-mail est associé(e). Cet ID unique se trouve dans l'URL d'une campagne particulière dans l'outil de la campagne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom de la campagne marketing à laquelle cette page, cet article ou cet e-mail est associé(e)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.created"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date et heure"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un objet datetime indiquant la date de création initiale du contenu (UTC). Cette variable peut être formatée avec le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#datetimeformat",
              children: "filtre date et heure"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : pour extraire la méta-description d'une page, il est préférable d'utiliser la variable ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom d'un article, d'un e-mail ou d'une page. Pour les pages et les e-mails, le nom du contenu interne sera imprimé, tandis que pour les articles, le titre de l'article sera imprimé. Pour les articles de blog, il s'agit du titre de l'article qui s'affiche. Pour les autres types de contenu, il s'agit généralement d'un nom interne. Cette variable comprend un encadrement qui permet de la modifier via l'interface utilisateur lorsqu'elle est incluse dans des articles de blog. Si vous voulez imprimer le nom du contenu sans encadrement, utilisez page_meta.name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date et heure"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un objet datetime indiquant la date de publication du contenu (UTC). Cette variable peut être formatée avec le filtre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#format-datetime",
              children: "format_datetime"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : une chaîne représentant la date et heure à laquelle le contenu a été publié en utilisant le fuseau horaire défini dans les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/change-your-language-and-region-settings",
              children: "paramètres par défaut du compte"
            }), ". Cette variable est également soumise aux paramètres de langue et de format de date dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Site web > Blog > Formats de date"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le chemin d'accès du fichier du gestionnaire de conception vers votre modèle (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom/page/web_page_basic/my_template.html"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le slug d'URL d'une page, d'un article ou de la page de la version web d'un e-mail Il s’agit de la valeur qui suit le domaine. Par exemple, dans ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/about-us"
            }), ", le slug est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "about-us"
            }), ". Pour l’URL complète, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            }), " à la place."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.updated"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date et heure"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un objet datetime pour la dernière mise à jour du contenu par l'utilisateur (UTC). Cette variable peut être formatée avec le filtre dateTime. ", (0, _jsxRuntime.jsxs)(_components.em, {
              children: ["N'équivaut pas à ", (0, _jsxRuntime.jsx)(_components.em, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.publish_date"
                })
              }), " lors de la première publication. Utilisez"]
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#between-times",
              children: (0, _jsxRuntime.jsx)("code", {
                children: "|between_times"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "un filtre pour vérifier si un article a été mis à jour après sa publication."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'imprimer l'ID unique d'une page, d'un article ou d'un e-mail. Cet ID peut être trouvé dans l'URL de l'éditeur. Vous pouvez utiliser cette variable comme un alias pour content.id."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "favicon_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer l'URL source de la favicon. Cette image est définie dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Site web > Pages > Image de marque"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hub_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID du portail de votre compte HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_analytics_tracking_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'inclure le code de suivi analytique. Cette balise n'est pas nécessaire, car ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            }), " restitue déjà le code de suivi."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_dt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date et heure"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un objet datetime de l'heure actuelle dans le fuseau horaire défini dans les paramètres de rapport. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages afin de renvoyer l'heure actuelle. Cela peut nuire aux performances de la page. Utilisez plutôt JavaScript pour obtenir la date et l'heure actuelles sans perturber la mise en cache."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_time_zone"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le fuseau horaire, tel que configuré dans vos paramètres de rapport HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.canonical_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l’URL officielle à laquelle cette page doit être consultée. Ne comprend généralement pas de paramètres de chaîne de requête. Utilisez ceci pour la balise ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"canonical\""
            }), ". HubSpot canonicalise automatiquement les URL."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le titre de la page. Cette variable doit être utilisée dans la balise ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), " des modèles HTML."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la méta-description d'une page. Cette variable doit être utilisée dans la balise ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<meta>"
            }), " « description » des modèles HTML."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un alias pour ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portal_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un alias pour hub_id"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un dictionnaire contenant des données sur le contact demandé.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache de la page."
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Elle n'est pas disponible pour les modèles d'e-mails."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le dictionnaire site_settings contient divers paramètres tels que les couleurs et les polices (voir ci-dessous)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "year"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'imprimer l'année en cours."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables d'e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici les variables HubL utilisables dans les modèles d’e-mails. Certaines variables, telles que les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#color-and-font-settings",
        children: "variables des paramètres de couleur et de police"
      }), ", peuvent être utilisées dans d’autres types de contenu si vous souhaitez faire référence à vos paramètres de couleur et de police d’e-mail ailleurs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variables de modèle d'e-mail obligatoires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Conformément aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/can-i-customize-my-can-spam-email-footer",
        children: "directives européennes sur la protection de la vie privée"
      }), ", tous les e-mails envoyés par HubSpot doivent contenir certaines informations sur l'entreprise et les désabonnements. Ces informations sont définies par les variables suivantes, qui doivent être incluses."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["D'autres variables d'e-mail sont facultatives et sont énumérées ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#email-variables",
        children: "plus bas sur cette page"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer la ville de l'entreprise (définie dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Marketing > E-mail > Configuration > Pied de page"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer le nom de l'entreprise (défini dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Marketing > E-mail > Configuration > Pied de page"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer l'état de l'entreprise (défini dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Marketing > E-mail > Configuration > Pied de page"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer l'adresse de l'entreprise (définie dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Marketing > E-mail > Configuration > Pied de page"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'imprimer l'URL de la page qui permet aux destinataires de gérer leurs préférences d'abonnement ou de se désabonner des communications par e-mail. Cette variable doit être utilisée dans l'attribut href d'un élément <a>."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Paramètres de couleur et de police"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe plusieurs contrôles de base de couleur et de police dans vos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings",
        children: "paramètres d’e-mail marketing"
      }), " qui peuvent être imprimés pour les e-mails. Si indiquée, vous pouvez utiliser certaines de ces variables sur les modèles de page et de blog en ajoutant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings."
      }), " avant la variable."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de couleur d'arrière-plan sous forme de valeur hex. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.background_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de couleur de bordure du corps de l'e-mail sous forme de valeur hex. Cette option devient disponible lorsque vous sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Définir manuellement la couleur du corps de l'e-mail"
            }), " sous le menu déroulant ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Options de couleur de bordure"
            }), " dans les paramètres. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_border_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color_choice"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la valeur de cette variable est contrôlée par le menu déroulant ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Options de couleur de bordure"
            }), " dans les paramètres. Les valeurs possibles sont : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_AUTOMATIC"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_MANUAL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_NONE"
            }), ". Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez :", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de couleur de corps sous forme de valeur hex. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de couleur de thème principal sous forme de valeur hex. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_accent_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Énumération"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de police primaire sous forme de chaîne. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez :", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de couleur de police principale sous forme de valeur hex. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de taille de police principale sous forme de valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ". Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_size"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le numéro de la taille de la police principale, à l’exception de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de couleur de thème secondaire sous forme de valeur hex. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_accent_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Énumération"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de police secondaire sous forme de chaîne. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez :", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le paramètre de couleur de police secondaire sous forme de valeur hex. Pour imprimer cette valeur dans des modèles de page ou de blog, utilisez : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le numéro de la taille de la police secondaire, à l’exception de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Contenu de l'e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.create_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la valeur est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " s'il existe une version web de l'e-mail."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.email_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texte enrichi"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le corps principal de l'e-mail. Cette variable restitue un module de texte enrichi."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.emailbody_plaintext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le remplacement facultatif du corps de l'e-mail en texte brut"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.from_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom de l'expéditeur de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.reply_to"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'adresse de réponse de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'objet de l'e-mail"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "paramètre de remplissage du corps de l'e-mail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "paramètre de largeur du corps de l'e-mail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_2"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer la ligne d'adresse 2 à partir des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "paramètres de pied de page CAN-SPAM "
            }), " du compte."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.office_location_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'imprimer le nom de l'adresse des bureaux à partir des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "paramètres de pied de page CAN-SPAM "
            }), " du compte."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_confirmation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'imprimer l'URL de la page de confirmation de gestion des inscriptions. Cette URL est générée dynamiquement à l'envoi."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'imprimer le nom du type d'e-mail indiqué pour cet e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet de générer une balise d’ancrage avec le mot « unsubscribe » lié à votre page de désabonnement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link_all"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet de restituer un lien permettant de se désabonner de toutes les communications par e-mail, par opposition à un lien vers une page de gestion des inscriptions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet de restituer une section de désabonnement qui comprend un lien de désabonnement, ainsi qu'un texte d'aide."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet de générer un lien avec un texte d'aide qui mène à la version web d'un e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet de générer un lien qui mène à la version web d'un e-mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variables d’e-mail de contenu privé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La liste suivante de variables n'est disponible que dans les modèles d'e-mails utilisés pour les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages#customize-your-membership-registration-page-and-email-templates",
        children: "modèles d'e-mails à contenu privé"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le nom de l’entreprises, tel que défini dans les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "paramètres du contenu privé"
            }), " du compte."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le domaine du site web à contenu privé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_src"
            }), "Chaîne"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l’URL du logo à afficher, telle que définie dans les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "paramètres du kit de marque"
            }), " du compte."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_alt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l’attribut du ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " logo, tel que défini dans les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "paramètres du kit de marque"
            }), " du compte."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le lien vers la page d'inscription pour le site web à contenu privé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_saved_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le lien vers la page de sauvegarde du mot de passe. Le lien redirigera le visiteur vers une page restreinte aléatoire à laquelle il a accès."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_reset_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le lien vers la page de réinitialisation de mot de passe pour le site web à contenu privé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_passwordless_auth_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le lien vers la page de connexion des membres sans mot de passe."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_verify_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le lien de la page de vérification de l’enregistrement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_website_admin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le nom de l’administrateur du site web, tel que défini dans les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "paramètres du contenu privé"
            }), " du compte."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables de page de site web"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les variables suivantes sont disponibles pour les pages de site, les pages de destination, les pages système et les blogs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variables de modèle de page obligatoires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour publier un fichier codé en tant que modèle de page ou de blog modifiable, les variables suivantes doivent être incluses. Pour publier un fichier HTML sans ces variables, afin de l’utiliser dans un autre modèle, incluez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isAvailableForNewContent: false"
      }), " dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "annotations du modèle"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de restituer le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#hubspot-tracking-code",
              children: "code de suivi HubSpot"
            }), " et tout autre code inclus dans votre HTML de pied de page dans les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://app.hubspot.com/l/settings/website/pages/all-domains/page-templates",
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "paramètres du contenu"
              })
            }), " ou les options d'une page particulière. Cette balise doit être insérée directement avant la balise de fermeture du corps."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_header_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'ajouter ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#included-cms-files",
              children: "jQuery, layout.css"
            }), ", toute feuille de style jointe, une métabalise viewport, le code de suivi Google Analytics, d'autres métadonnées de la page et le code ajouté à la balise d'en-tête au niveau du domaine, du modèle ou de la page. Cette variable doit être ajoutée à l'élément <head> des modèles HTML."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variables de contenu et de contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "builtin_body_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : cette variable imprime dynamiquement des classes utiles qui permettent de différencier le balisage du contenu créé avec ce modèle (c.-à-d. le type de contenu, le nom du contenu, etc.). Cela permet de styliser plus facilement différents types de contenu ou des pages particulières. Cette variable doit être utilisée dans l'attribut class de la balise body des modèles codés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.is_logged_in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : cette variable définit si le contact demandeur est connecté ou non au contenu soumis à l'envoi d'un formulaire de site web (consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
              children: "documentation sur le contrôle de l'accès de l'audience"
            }), " pour plus d'informations). Cette variable renvoie la valeur vrai si le contact demandeur est connecté, et faux si le contact demandeur est déconnecté. Un contact peut être déconnecté en le dirigeant vers l'URL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.yourdomain.com/_hcms/mem/logout"
            }), ".", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.list_memberships"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : cette variable renvoie un dictionnaire d’ID qui représente les listes dont le contact est member.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : cette variable renvoie un dictionnaire d'informations sur les paramètres linguistiques d'une page. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.languageTag }}"
            }), " renvoie l'identifiant de la langue d'une page (ex : « en » ou « es »). ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.textDirection.value }}"
            }), " renvoie le sens du texte de la langue de la page (ex : « rtl » ou « ltr »)."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables de requête HTTP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les variables suivantes impriment des informations sur la requête de page HTTP."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.cookies"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un dictionnaire de noms de cookies associés à des valeurs de cookies._L'utilisation de cette variable désactivera la mise en cache des pages. _"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le domaine utilisé pour accéder à cette page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.full_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL utilisée pour accéder à cette page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le composant de chemin de l'URL"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path_and_query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le chemin et le composant de requête de l'URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le composant de la chaîne de requête de l'URL. request.query_dict divise automatiquement les chaînes de requête en paires (clé, valeur) et est recommandé par rapport à la requête brute pour la plupart des cas d'utilisation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query_dict"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la chaîne de requête convertie en un dictionnaire de noms et de valeurs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.referrer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le référent HTTP, l'URL de la page associée à la page actuelle.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.remote_ip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l'adresse IP du visiteur.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.scheme"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le protocole de la requête (HTTP ou HTTPS)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_engine"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le moteur de recherche utilisé pour trouver cette page, le cas échéant. Ex : Google, Aol, Live, Yahoo, images.google, etc."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_keyword"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le mot-clé (expression) utilisé pour trouver cette page, le cas échéant"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.headers"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un dictionnaire d'en-tête demandé disponible.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "L'utilisation de cette variable désactivera la mise en cache des pages afin d'interpréter individuellement les en-têtes pour chaque requête. Cela peut nuire aux performances de la page."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les variables suivantes sont disponibles pour les modèles de blog. Certaines variables ne sont disponibles que pour les listes d'articles, tandis que d'autres ne sont disponibles que pour les articles de blog."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : cette variable contient des informations relatives à l'auteur du blog pour les pages de listing des auteurs du blog. Elle peut être utilisée pour créer une logique conditionnelle pour ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-blog-author-statement",
              children: "restituer le balisage pour les listes d'auteurs de blog"
            }), ". Elle contient également les propriétés suivantes : ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.blog_post_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : cette variable contient des informations individuelles sur l'auteur d'un article de blog pour un article donné. Elle contient les propriétés suivantes : ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.slug"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un alias pour le groupe."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nombre de commentaires pour l'article de blog actuel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : une liste des commentaires pour l'article de blog actuel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "current_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'indice entier de la page actuelle des articles de blog dans la vue."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL source de l'image en vignette sélectionnée lors de la publication du blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le texte alternatif de l'image en vignette."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "last_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'indice entier de la dernière page des articles de blog dans la vue."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "next_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'indice entier de la page suivante des articles de blog dans la vue."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL de l'image en vignette du prochain article de blog, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le texte alternatif pour l'image en vignette du prochain article, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom du prochain article du blog, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le slug d'URL du prochain article du blog, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le corps de l'article de blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le contenu du corps de l'article de blog modifié pour la page de listing. Le résultat final est affecté par les paramètres de synthèse dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Site web > Blog"
            }), ". Si les images en vignette sont activées dans les paramètres, cette variable supprimera automatiquement toutes les images situées au-dessus du séparateur En savoir plus."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_summary_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l'image en vignette des résumés d'articles à utiliser dans les modèles de listes. Cette variable est affectée par les paramètres dans ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Site web > Blog"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le résumé de l'article de blog. Ce contenu est déterminé par le séparateur En savoir plus dans l'éditeur de blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL de l'image en vignette de l'article de blog précédent, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le texte alternatif pour l'image en vignette de l'article précédent, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom de l'article de blog précédent, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le slug d'URL de l'article de blog précédent, le cas échéant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : une chaîne représentant la date et l'heure de publication de l'article de blog, formatées en fonction de la langue du blog et des paramètres de formatage de date."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : un booléen indiquant si la page demandée est la page « Tous les articles » contenant des liens vers tous les articles du blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.topic_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : peut être utilisé pour restituer le balisage d'une liste de thèmes en le parcourant en boucle. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{% for topic in content.topic_list %}"
            }), " Les éléments qui s'y trouvent contiennent les propriétés suivantes : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "slug"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le contenu est une séquence de vos articles de blog qui sont itérés à l'aide d'une boucle, disponible sur ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-is-listing-view-statement",
              children: "les pages de liste de blogs (is_listing_view)"
            }), " "]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nombre total d'articles dans une liste (standard, thèmes, auteurs, etc.)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_page_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nombre total de pages d'articles en fonction du nombre d'articles par page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents_topics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'obtenir une liste de tous les thèmes du blog dans la séquence de contenu des articles."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le dictionnaire contenant les variables qui se rapportent à un blog entier."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL de base d'un blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.allow_comments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet de renvoyer une valeur true si les commentaires sont autorisés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la méta-description du blog à partir de ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Site web > Blog"
            }), ". Utilisée pour la méta-description sur certaines pages de listing."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'en-tête du blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le titre de ce blog tel qu'il doit apparaître dans la balise ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID unique d'un blog. Cet ID peut être trouvé dans l'URL du tableau de bord de blog pour un blog spécifique."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un dictionnaire contenant des informations sur la langue d'un blog. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ group.language.languageTag }}"
            }), " peut être utilisé dans des logiques conditionnelles pour restituer un contenu différent sur les différentes variations linguistiques d'un blog multilingue."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.public_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le titre de ce blog tel qu'il doit apparaître en haut des pages restituées."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.show_summary_in_listing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un booléen à partir de ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Site web > Blog"
            }), " indiquant s'il faut afficher les résumés dans les listes d'articles."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le chemin vers ce blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.use_featured_image_in_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un booléen à partir de ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètres > Site web > Blog"
            }), " indiquant s'il faut afficher les images en vignette dans les résumés d'articles."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "archive_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer une valeur true si la page est une page d'archive de blog. Ex : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/blog/archive/2020/02"
            }), " renverra une valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pages d'objets CRM dynamiques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les variables suivantes sont utilisées pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
        children: "créer des pages dynamiques avec des objets CRM"
      }), ". Ces variables ne sont disponibles que pour les pages d'objets CRM dynamiques."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l'objet CRM de la page dynamique qui correspond au chemin de la requête de la page. Si la requête concerne la page de listing, une valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), " sera renvoyée."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object_type_fqn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le nom entièrement qualifié (FQN) de l'objet CRM. Le FQN est un ID unique attribué à l'objet, comprenant l'identifiant du portail et le nom de l'objet. Le FQN peut être utilisé dans la ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "/reference/cms/hubl/functions#crm-objects",
              children: ["fonction ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "crm_objects"
              }), "."]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les variables suivantes sont utilisées pour créer des pages dynamiques avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), ". Ces variables ne sont disponibles que pour les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
        children: "pages dynamiques HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Long"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID du tableau sélectionné dans l'onglet « Paramètres avancés\\ » de l'éditeur de page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la ligne HubDB de la page dynamique qui correspond au chemin de la requête de la page. Si la requête concerne la page de listing, une valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), " sera renvoyée."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Long"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID interne d'une ligne HubDB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom de la ligne HubDB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le chemin de la ligne HubDB. Il est utilisé pour résoudre une requête d'une ligne dans le tableau spécifié par ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Long"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID de tableau enfant de la ligne HubDB. Il peut être utilisé pour créer des modèles imbriqués."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_parent_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la ligne parent de la ligne HubDB. Elle ne peut être utilisée que lorsque des tableaux enfants sont utilisés pour des modèles imbriqués."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_route_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la profondeur actuelle d'une page dans un modèle dynamique à plusieurs niveaux. La valeur commence à ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " et augmente avec chaque couche de tableau supplémentaire."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables de nœuds de menu"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les variables suivantes sont disponibles pour être utilisées sur l'objet renvoyé par la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "fonction de menu HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le libellé de menu de la page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'URL de la page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID de la page si elle se trouve dans HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.contentGroupId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID de blog de la page s'il s'agit d'un article de blog HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.parentNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le nœud parent du nœud actuel. Le nœud parent aura le nœud actuel dans sa propriété ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "children"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.children"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la liste des nœuds enfants pour le nœud actuel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeBranch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la valeur true est renvoyée si le nœud se trouve dans la branche de premier niveau dans laquelle se trouve la page actuelle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la valeur true est renvoyée si le nœud est la page actuelle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nombre de niveaux de profondeur du nœud actuel par rapport aux nœuds de premier niveau."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageTitle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom de la page de contenu si elle se trouve dans HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le slug de chemin d'accès de la page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.linkTarget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le lien cible de la page."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Éditeur dans l'application et variables d'aperçu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez utiliser les variables suivantes pour vérifier si le contenu est affiché dans l'éditeur de contenu ou l'aperçu. Par exemple, vous pouvez les utiliser pour empêcher l'exécution de code dans l'éditeur tout en exécutant le code sur les pages en direct."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_in_page_editor %}\nDisplay something different within the page editor.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_hs_app"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'application HubSpot."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans un éditeur de contenu."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_global_content_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'éditeur de contenu global."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'éditeur de thème."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'éditeur de page."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'éditeur d'article de blog."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'éditeur d'e-mail."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans un contexte de prévisualisation."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'aperçu du thème."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_template_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'aperçu du modèle."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'aperçu de la page."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'aperçu de l'article de blog."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'aperçu des e-mails."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_module_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de renvoyer la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " si le contenu est restitué dans l'aperçu du module."]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}