'use es6';

import { isNil } from '../../common/objutils';
import { formatVariableName } from './formatters';
export class Assignment {
  constructor() {
    this.imports = [];
    this.lines = [];
    this.name = null;
    this.variable = null;
  }
  generateVariable(modelName, variableIndexStorage) {
    if (!isNil(this.name)) {
      this.variable = formatVariableName(this.name + variableIndexStorage.getIndex(this.name));
    }
    if (isNil(this.name) && !isNil(modelName)) {
      this.variable = formatVariableName(modelName + variableIndexStorage.getIndex(modelName));
    }
    return this;
  }
  addImport(modelName) {
    this.imports.push(modelName);
    return this;
  }
  addLine(line) {
    this.lines.push(line);
    return this;
  }
  addLines(lines) {
    this.lines.push(...lines);
    return this;
  }
  setVariable(variable) {
    this.variable = variable;
    return this;
  }
  getVariable() {
    return this.variable;
  }
  setName(name) {
    this.name = name;
    return this;
  }
  getName() {
    return this.name;
  }
  merge(assignment) {
    this.imports.push(...assignment.imports);
    this.addLines(assignment.lines);
  }
}