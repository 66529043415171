"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59605144696;
const slug = exports.slug = 'guides/api/crm/engagements/notes';
const title = exports.title = 'Engajamentos | Observações';
const name = exports.name = 'Engajamentos | Observações';
const metaDescription = exports.metaDescription = 'Os registros de contato armazenam informações sobre pessoas. Os pontos de extremidade de contatos permitem que você gerencie e sincronize esses dados entre o HubSpot e outros sistemas.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-uma-observa%C3%A7%C3%A3o",
  "label": "Criar uma observação",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades",
  "label": "Propriedades",
  "parent": "criar-uma-observa%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "associa%C3%A7%C3%B5es",
  "label": "Associações",
  "parent": "criar-uma-observa%C3%A7%C3%A3o"
}, {
  "depth": 0,
  "id": "recuperar-observa%C3%A7%C3%B5es",
  "label": "Recuperar observações",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-observa%C3%A7%C3%B5es",
  "label": "Atualizar observações",
  "parent": null
}, {
  "depth": 1,
  "id": "associar-chamadas-existentes-a-registros",
  "label": "Associar chamadas existentes a registros",
  "parent": "atualizar-observa%C3%A7%C3%B5es"
}, {
  "depth": 1,
  "id": "remover-uma-associa%C3%A7%C3%A3o",
  "label": "Remover uma associação",
  "parent": "atualizar-observa%C3%A7%C3%B5es"
}, {
  "depth": 0,
  "id": "fixar-uma-observa%C3%A7%C3%A3o-em-um-registro",
  "label": "Fixar uma observação em um registro",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-observa%C3%A7%C3%B5es",
  "label": "Excluir observações",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      h2: "h2",
      code: "code",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Observações"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode registrar observações em registros do CRM para adicionar informações à linha do tempo do registro ou associar um anexo a um registro. Por exemplo, se você precisar acompanhar uma conversa offline que teve com um contato, poderá adicionar uma observação ao registro do contato com detalhes e documentos relacionados à conversa. Outros usuários na conta poderão visualizar e referenciar essa observação."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode gerenciar observações ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "no HubSpot"
      }), " ou por meio da API de observações. Abaixo, aprenda os métodos básicos de gerenciamento de observações por meio da API. Você pode revisar todos os pontos de extremidade disponíveis na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ponto de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar uma observação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar uma observação, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo da solicitação, adicione detalhes da observação em um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propriedades"
      }), ". Você também pode adicionar um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associações"
      }), " para associar sua nova observação a um registro existente (por exemplo, contatos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No objeto de propriedades, você pode incluir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obrigatório. Este campo marca a hora de criação da observação e determina onde ela se encontra na linha do tempo do registro. Você pode usar um carimbo de data e hora do Unix em milissegundos ou no formato UTC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O conteúdo de texto da observação, limitado a 65.536 caracteres."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID do proprietário"
            }), " associado à observação. Este campo determina o usuário listado como o criador da observação na linha do tempo do registro."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os IDs dos anexos da observação. Vários IDs de anexo são separados por ponto e vírgula."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar e associar uma observação a registros existentes, inclua um objeto de associações na solicitação. Por exemplo, para criar uma observação e associá-la a uma empresa e a um negócio, o corpo da solicitação pode ser semelhante ao seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubspot.com/crm/v3/objects/notes\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-11-12T15:48:22Z\",\n    \"hs_note_body\": \"Spoke with decision maker Carla. Attached the proposal and draft of contract.\",\n    \"hubspot_owner_id\": \"14240720\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 190\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 401\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 214\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No objeto de associações, você deve incluir o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O registro que deseja associar à observação, especificado por seu valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " exclusivo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de associação entre a observação e o registro. Inclua ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Os IDs de tipo de associação padrão são listados ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aqui"
            }), ", ou você pode recuperar o valor de tipos de associação personalizados (ou seja, rótulos) por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a criação em massa de empresas na guia* Pontos de extremidade* na parte superior deste artigo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar observações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode recuperar observações individualmente ou em lotes. Para recuperar uma observação individual, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar uma lista de todas as observações, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ambos os pontos de extremidade, você pode incluir os seguintes parâmetros de consulta no URL da solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas em resposta. Se a empresa solicitada não tiver um valor para uma propriedade, ela não será exibida na resposta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista separada por vírgulas de tipos de objeto para recuperar IDs associados. Todas as associações especificadas que não existem não serão retornadas na resposta. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de associações."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, para recuperar observações com o conteúdo do texto e qualquer ID de contato associado, o URL da solicitação pode ser semelhante ao seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/notes?limit=10&properties=hs_note_body&associations=contact&archived=false"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como recuperar um lote de observações pelo ID interno ou valor de propriedade exclusivo na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar observações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atualizar observações individualmente ou em massa. Para atualizar uma observação individual pelo seu ID, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo da solicitação, inclua as propriedades de observação que deseja atualizar:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example PATCH request to https://api.hubspot.com/crm/v3/objects/notes/{noteID}\n{\n  \"properties\": {\n    \"hs_note_body\": \"Spoke with decision maker Carla.\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot ignorará valores para propriedades somente leitura e inexistentes. Para limpar um valor de propriedade, passe uma string vazia para a propriedade no corpo da solicitação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a atualização em massa de empresas na guia* Pontos de extremidade* na parte superior deste artigo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar chamadas existentes a registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar uma observação a outros registros do CRM, como um contato, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". A URL da solicitação contém os campos a seguir:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "noteId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da observação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O tipo de objeto ao qual você deseja associar a observação (por exemplo, contato ou empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do registro ao qual você deseja associar a observação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um identificador exclusivo para indicar o tipo de associação entre a observação e o outro objeto. O ID pode ser representado numericamente ou em maiúsculas (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "note_to_contact"
            }), "). Você pode recuperar o valor por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, a URL da sua solicitação pode ser parecida com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/notes/17147287858/associations/contact/581751/202"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remover uma associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para remover uma associação entre uma chamada e um registro, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para o mesmo URL acima:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fixar uma observação em um registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "fixar uma observação"
      }), " em um registro para que permaneça no topo da linha do tempo do registro. A chamada já deve estar associada ao registro antes da fixação e você só pode fixar uma atividade por registo. Para fixar uma observação, inclua o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " da observação no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " ao criar ou atualizar um registro por meio das APIs de objeto. Saiba mais sobre como usar as APIs de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contatos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negócios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir observações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode excluir observações individualmente ou em massa, o que adicionará a observação à lixeira no HubSpot. Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurar posteriormente a observação da linha do tempo do registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir uma observação individual pelo seu ID, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a exclusão em massa de observações na guia* Pontos de extremidade* na parte superior deste artigo."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}