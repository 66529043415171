"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 1;
const slug = exports.slug = 'reference/api/settings/account-activity/v1';
const title = exports.title = 'Account Activity API';
const name = exports.name = 'Account Activity API';
const metaDescription = exports.metaDescription = 'Account Activity API';
const orderedTitles = exports.orderedTitles = ['Get details for a HubSpot account'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointRightColumn,
      EndpointExample
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(EndpointCard, {
    children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
      children: [(0, _jsxRuntime.jsx)(_components.h4, {
        children: "Get details for a HubSpot account"
      }), (0, _jsxRuntime.jsx)(Endpoint, {
        method: "GET",
        path: "/integrations/v1/me"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "This endpoint uses an API key or access token to get details for a HubSpot account."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Use case for this endpoint:"
        }), " This endpoint would be useful to discover which HubSpot account an OAuth access token is assigned to."]
      }), (0, _jsxRuntime.jsx)(_components.h3, {
        children: "Response details"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Returns the Portal ID (often called the Hub ID), time zone setting, and selected currency of the account."
      }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsx)(EndpointParameters, {})]
    }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
      children: (0, _jsxRuntime.jsx)(EndpointExample, {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: "CkV4YW1wbGUgR0VUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9pbnRlZ3JhdGlvbnMvdjEvbWUKCkV4YW1wbGUgcmVzcG9uc2U6Cgp7CiAgICBwb3J0YWxJZDogMjUxOTQ0NSwKICAgIHRpbWVab25lOiAiQW1lcmljYS9OZXdfWW9yayIsCiAgICBjdXJyZW5jeTogIlVTRCIsCiAgICB1dGNPZmZzZXRNaWxsaXNlY29uZHM6IC0xODAwMDAwMCwKICAgIHV0Y09mZnNldDogIi0wNTowMCIKfQ=="
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}