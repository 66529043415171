"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866608851;
const slug = exports.slug = 'reference/api/overview';
const title = exports.title = 'APIs da HubSpot | Introdução';
const name = exports.name = 'vNext Documentos DP - Introdução';
const metaDescription = exports.metaDescription = 'A API da HubSpot permite que os desenvolvedores criem aplicativos e integrações personalizadas. Comece lendo nossa visão geral da API e criando uma conta de desenvolvedor.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "configura%C3%A7%C3%A3o",
  "label": "Configuração",
  "parent": null
}, {
  "depth": 1,
  "id": "bibliotecas-de-clientes",
  "label": "Bibliotecas de clientes",
  "parent": "configura%C3%A7%C3%A3o"
}, {
  "depth": 0,
  "id": "documenta%C3%A7%C3%A3o-das-apis",
  "label": "Documentação das APIs",
  "parent": null
}, {
  "depth": 1,
  "id": "documentos-de-refer%C3%AAncia",
  "label": "Documentos de referência",
  "parent": "documenta%C3%A7%C3%A3o-das-apis"
}, {
  "depth": 1,
  "id": "guias-de-integra%C3%A7%C3%A3o",
  "label": "Guias de integração",
  "parent": "documenta%C3%A7%C3%A3o-das-apis"
}, {
  "depth": 0,
  "id": "criando-a-lista",
  "label": "Criando a lista",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-de-suporte-e-da-comunidade",
  "label": "Recursos de suporte e da comunidade",
  "parent": null
}, {
  "depth": 0,
  "id": "criando-no-cms",
  "label": "Criando no CMS",
  "parent": null
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "criando-no-cms"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Table
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Documentação de referência de API da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A plataforma de desenvolvedor da HubSpot é parte essencial da nossa missão de capacitar as empresas a alcançarem um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/grow-better",
        children: "crescimento melhor"
      }), ". Nossas APIs são desenvolvidas com o intuito de permitir que qualquer equipe seja capaz de criar integrações eficazes para personalizar e aproveitar ao máximo o HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todas as APIs da HubSpot são ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/Representational_state_transfer",
        children: "criadas usando convenções REST"
      }), " e projetadas para ter uma estrutura de URL previsível. Elas usam vários recursos HTTP padrão, inclusive métodos (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), ") e códigos de resposta de erro. Todas as chamadas de API da HubSpot são feitas em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com"
      }), " e todas as respostas retornam um JSON padrão."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuração"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há várias maneiras de criar integrações com o HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para criar uma integração interna para uma conta individual da HubSpot (por exemplo, você deseja criar um aplicativo que possa acessar e editar apenas partes autorizadas da sua conta para compartilhar ou integrar com outras partes da sua organização), crie um aplicativo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "privado"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você quiser criar um aplicativo público que possa ser instalado em várias contas da HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
          children: "crie uma conta de desenvolvedor"
        }), ". Há vários motivos para isso: é em uma conta de desenvolvedor que você cria aplicativos da HubSpot, cada um deles autenticado com OAuth e fornecido com um conjunto configurável de recursos e permissões. Você também pode usar sua conta de desenvolvedor para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "criar contas de teste"
        }), ", monitorar o status e o desempenho do aplicativo ou publicar aplicativos para o Marketplace de aplicativos da HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre os diferentes tipos de aplicativos e tipos de conta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#account-relationships",
        children: "neste artigo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bibliotecas de clientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As bibliotecas do cliente são projetadas para ajudar você a interagir facilmente com as APIs da HubSpot. Elas são criadas em várias linguagens diferentes e ajudam a relacionar seu aplicativo e às APIs da HubSpot. Por exemplo, com elas, você não precisa saber quais são o URL e o método HTTP exatos necessários para usar para cada chamada de API e, assim, tem mais tempo para focar na criação do aplicativo. Saiba mais sobre nossas bibliotecas de cliente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/overview",
        children: "aqui"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Table, {
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("th", {
            children: "Idioma"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "Link do pacote"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "Código-fonte"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_nodejs-512_339733.png",
              alt: "Node.Js"
            }), "Node.Js"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://www.npmjs.com/package/@hubspot/api-client",
              children: "npm install @hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "hubspot-api-nodejs"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/new-php-logo.png",
              alt: "PHP"
            }), "PHP"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://packagist.org/packages/hubspot/api-client",
              children: "composer require hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-php",
              children: "hubspot-api-php"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/ruby.png",
              alt: "Ruby"
            }), "Ruby"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://rubygems.org/gems/hubspot-api-client",
              children: "gem install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-ruby"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_267_Python_logo_4375050.png",
              alt: "Python"
            }), "Python"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://pypi.org/project/hubspot-api-client/",
              children: "pip install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-python"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Documentação das APIs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A documentação das APIs da HubSpot divide-se em duas seções: documentos de referência e guias de integração."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Documentos de referência"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos os documentos de referência de API contêm uma seção de visão geral e uma seção de ponto de extremidade. A visão geral da API inclui um breve resumo da funcionalidade, casos de uso e quaisquer considerações especiais necessárias à criação de uma integração. A seção de pontos de extremidade lista cada ponto de extremidade, os respectivos parâmetros e exemplos de solicitações em várias linguagens."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de definir as configurações de autenticação do aplicativo em sua conta do desenvolvedor, você poderá usar o Postman ou fazer chamadas de teste diretamente em uma página de referência de ponto de extremidade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guias de integração"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para aprender os fundamentos da plataforma da HubSpot ou ver um exemplo antes de fazer sua primeira chamada de API, consulte na nossa seção de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "Guias de integração"
      }), ". Nela, você encontra exemplos de aplicativos e tutoriais, bem como informações detalhadas sobre contas de desenvolvedores, como trabalhar com o OAuth, limites de taxa de API e muito mais."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Relacionado:"
      }), " saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "métodos de autenticação"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criando a lista"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando você estiver pronto para compartilhar seu aplicativo com o mundo e se tornar um parceiro de aplicativo, leia os requisitos para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "listagem"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "certificação"
      }), ". Depois disso, você poderá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "criar"
      }), " e gerenciar sua lista do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Marketplace de aplicativos"
      }), " diretamente em sua conta de desenvolvedor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos de suporte e da comunidade"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja as respostas às suas perguntas, faça conexões e compartilhe seus insights participando dos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "fóruns das comunidades de desenvolvedores"
      }), " e da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/developer-slack",
        children: "comunidade Slack"
      }), " da HubSpot em constante expansão. Esses fóruns são o local ideal para expressar suas opiniões e ideias. O feedback da comunidade é extremamente importante para nós e para nossos esforços contínuos de melhorar a experiência dos desenvolvedores do HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inscreva-se no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Log de alterações"
      }), " e esteja por dentro de novos recursos, anúncios e alterações importantes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criando no CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Está interessado em criar um site, um blog, uma página inicial, um aplicativo leve ou um e-mail? Confira nossos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "documentos de desenvolvedor do CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "Como usar as APIs da HubSpot"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}