"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 33029538121;
const slug = exports.slug = 'guides/cms/marketplace/module-requirements';
const title = exports.title = 'Template Marketplace | Module requirements';
const name = exports.name = 'Template Marketplace | Module requirements';
const metaDescription = exports.metaDescription = "Learn about the requirements that a theme's modules must meet when submitting to the HubSpot Template Marketplace.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "module-restrictions",
  "label": "Module restrictions",
  "parent": null
}, {
  "depth": 0,
  "id": "module-content",
  "label": "Module content",
  "parent": null
}, {
  "depth": 1,
  "id": "module-labels-%26-help-text",
  "label": "Module labels & help text",
  "parent": "module-content"
}, {
  "depth": 1,
  "id": "default-content",
  "label": "Default content",
  "parent": "module-content"
}, {
  "depth": 1,
  "id": "module-icons",
  "label": "Module icons",
  "parent": "module-content"
}, {
  "depth": 1,
  "id": "modules-that-require-3rd-party-accounts",
  "label": "Modules that require 3rd party accounts",
  "parent": "module-content"
}, {
  "depth": 0,
  "id": "module-fields",
  "label": "Module fields",
  "parent": null
}, {
  "depth": 1,
  "id": "fields.json-and-module.html-configuration",
  "label": "fields.json and module.html configuration",
  "parent": "module-fields"
}, {
  "depth": 0,
  "id": "module-code-quality",
  "label": "Module code quality",
  "parent": null
}, {
  "depth": 1,
  "id": "modules-must-be-self-contained",
  "label": "Modules must be self-contained",
  "parent": "module-code-quality"
}, {
  "depth": 2,
  "id": "theme-modules",
  "label": "Theme modules",
  "parent": "module-code-quality"
}, {
  "depth": 2,
  "id": "independent-modules",
  "label": "Independent modules",
  "parent": "module-code-quality"
}, {
  "depth": 1,
  "id": "code-restrictions-for-independent-modules",
  "label": "Code restrictions for independent modules",
  "parent": "module-code-quality"
}, {
  "depth": 1,
  "id": "categories",
  "label": "Categories",
  "parent": "module-code-quality"
}, {
  "depth": 1,
  "id": "class-name-selectors",
  "label": "Class name selectors",
  "parent": "module-code-quality"
}, {
  "depth": 1,
  "id": "styles-and-javascript",
  "label": "Styles and Javascript",
  "parent": "module-code-quality"
}, {
  "depth": 0,
  "id": "field-organization",
  "label": "Field organization",
  "parent": null
}, {
  "depth": 1,
  "id": "content-tab",
  "label": "Content tab",
  "parent": "field-organization"
}, {
  "depth": 1,
  "id": "styles-tab",
  "label": "Styles tab",
  "parent": "field-organization"
}, {
  "depth": 1,
  "id": "examples-of-field-organization",
  "label": "Examples of field organization",
  "parent": "field-organization"
}, {
  "depth": 2,
  "id": "presets",
  "label": "Presets",
  "parent": "field-organization"
}, {
  "depth": 2,
  "id": "multi-level-grouping",
  "label": "Multi-level grouping",
  "parent": "field-organization"
}, {
  "depth": 2,
  "id": "grouping-individual-fields",
  "label": "Grouping individual fields",
  "parent": "field-organization"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4",
      strong: "strong",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      Alert,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot Template Marketplace module requirements"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn about the requirements to submit a module to the Template Marketplace. These requirements apply to both modules in a theme and independent modules."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module restrictions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules must not contain ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), ", calls to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverless functions"
      }), ", or the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#crm-object",
        children: "CRM object field"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following module types should not be built as independent modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Full-width modules"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Forms and multi-step forms"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Spacer modules or modules that create non-UI page structure"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules that duplicate default module functionality"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Commerce-specific modules"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Email-specific modules"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module content"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn about the requirements for module labels and help text, fields, and default content."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Module labels & help text"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modules must have descriptive labels that convey the purpose of the module. The label ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero Banner with Parallax Scrolling"
        }), " is descriptive, whereas the labels ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero Banner"
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Gallery"
        }), " are not."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Module labels must not contain numbers, such as ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero Banner 01"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Module labels must not contain underscores."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Module labels must not contain abbreviations, such as ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Col"
        }), " instead of ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Column"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modules must contain ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#meta-json",
          children: "inline help text"
        }), " where applicable to further convey how to use the module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modules should not be named the same as a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "default module"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For independent modules, the module label should match the name on the template listing. For example, if your template listing is ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "SuperAwesome Banner with Scrolling"
        }), ", your module label should be the same."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-name.png",
          alt: "listing-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-label.png",
          alt: "module-label"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Default content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Default field cannot include ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Lorem ipsum"
        }), " text."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Default field content should represent the field’s purpose:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When including menu fields, modules must use ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Select a menu"
            }), " as the default content option."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When including form fields, modules must use ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Select a form"
            }), " as the default content option."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When including blog selector fields, modules must use ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Select a blog"
            }), " as the default content option."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If adding default content to a module doesn't make sense, use a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
          children: "module placeholder"
        }), " instead to help the content creator visualize the space that they'll fill with content."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Module icons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modules must include a custom icon assigned to the module (replacing the default icon). Do not use company logos as icons, such as Apple or Amazon logos. For modules included in a theme, each module should have a unique and relevant icon."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-an-icon",
        children: "module icons"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules that require 3rd party accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For individual modules, if the module requires a 3rd party account, it must be noted in the template description. For example, if your module makes use of the Google Maps Platform, you need to include a note, ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "\"The use of this module requires a Google Cloud (Google Maps Platform) account.\""
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module fields"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Review specific requirements for modules in a theme and independent modules below:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For modules in a theme:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Must contain inline help text and specific default content for certain fields."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["A part of the theme's ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#color",
              children: "color"
            }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#logo",
              children: "logo"
            }), " must inherit from the account's ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/fields/brand-and-settings-inheritance",
              children: "brand settings"
            }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "At a minimum, three color fields must inherit colors from the account's brand settings. Extra color fields can default to other colors, including black and white."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "At least one logo field must inherit from the account's brand settings. If using an image field to render a logo, the image field does not have to inherit from the brand settings."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For both modules in a theme and independent modules:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Module field names should describe the field’s intent. For example, if a text field is meant to include a person’s job title, ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Job Title"
            }), " would be a proper description whereas ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Title"
            }), " would not."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "All of HubSpot's default modules must be styled and must display properly on all templates submitted."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json and module.html configuration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ensure compatible functionality between themes and independent modules, modules must ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#inherited-fields",
        children: "inherit"
      }), " the color and font fields either by defining ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default_value_path"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), ", or both in their ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file and add a reference to the theme fields in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " file. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/template-policies#module-compatibility-with-themes",
        children: "Learn more about these requirements."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module code quality"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules must be self-contained"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Theme modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any files needed for your theme module, such as CSS or JavaScript, must be contained in the theme folder and included in the theme directory. You can use the Linked Files feature in the Design Manager. Or, include the files using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "require_js()"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-css",
        children: "require_css()"
      }), " functions with a relative path to the file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For common libraries, such as slick.js, you can include them using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " functions with an absolute URL to the CDN where it's hosted."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " do not use absolute URLs to assets contained within your development portal as cross-portal references will not resolve."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Independent modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For independent modules, all CSS and Javascript files should be contained in either the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Alternatively, include the files using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " functions with an absolute URL to the CDN where it’s hosted. It is not possible to use the Linked Files feature in the Design Manager as that is only available for theme modules."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Since ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " is included in the DOM before any ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " files, Javascript contained in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " section should be deferred using the annotation below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "document.addEventListener('DOMContentLoaded', function () {\n  // Put Javascript here\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All scripts and files should be rendered in the head of the module's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "HTML"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Code restrictions for independent modules"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following restrictions apply to only independent modules:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["It is recommended to use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://vanilla-js.com/",
          children: "vanilla JS"
        }), " where possible. Adding a jQuery library to a site that is not using jQuery can potentially cause conflicts and slow down the website page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If using a jQuery library, use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js()"
        }), " function to include the library in the event that jQuery is turned off with the checkbox (Boolean) in account settings to avoid conflicts from multiple jQuery libraries."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "{% if not site_settings.include_jquery %}\n{{ require_js(\"https://code.jquery.com/jquery-3.7.0.min.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Categories"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["All independent modules must have at least one category. Modules submitted as part of a theme are not required to have categories, but it's best practice to include at least one. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "adding categories to modules"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Class name selectors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Any class name selectors must be prefixed with the module name, replacing spaces with hyphens. For example, below is the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " file for a button named ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "example-button"
        }), ", with each class name and CSS selector reflecting its name."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<style>\n{% scope_css %}\n{# Button wrapper #}\n {% if module.styles.group_alignment.alignment %}\n  .example-button-wrapper {\n   text-align: {{ module.styles.group_alignment.alignment.horizontal_align }};\n   }\n {% endif %}\n\n{# Button #}\n\n.example-button {\n {% if module.styles.group_background.color.color %}\n  background-color: rgba({{ module.styles.group_background.color.color|convert_rgb }}, {{ module.styles.group_background.color.opacity / 100 }});\n {% endif %}\n }\n {% end_scope_css %}\n</style>\n{% end_require_css %}\n\n{##### Module HTML #####}\n\n<div class=\"example-button-wrapper\">\n <a href=\"{{ href }}\" class=\"example-button\"\n {% if module.button_link.open_in_new_tab %}target=\"_blank\"{% endif %}\n {% if rel %}rel=\"{{ rel|join(\" \") }}\"{% endif %}\n >\n  {{ module.button_text }}\n </a>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styles and Javascript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Styles:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Modules must have a non-empty style group."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Hardcoding inline styles within modules is not recommended. Instead, use dynamic inline styles by enabling fields to control styling."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["JavaScript:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScript must be able to represent multiple instances of a module. JavaScript in the JS Pane will only load once per page, regardless of the number of module occurrences."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScript should reference DOM elements by module-specific class names to ensure elements outside of the module are not unintentionally affected."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating modules, you can use a built-in variable called ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{name}}"
      }), ". This variable pulls in the module's instance ID (which can be used in the HTML+HubL panel only) to help in CSS and JS markup for complex modules. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/files#require-css-block",
        children: "Learn more about this in our developer documentation."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Field organization"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following field organization and grouping requirements must be met."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Content tab"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Where there is at least one control within a field group, all controls must be grouped into categories labeled by their function."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Module fields added to the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content"
        }), " tab must give ways to customize the content of a module. For example, controls for image, icon, alt text, and link controls."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styles tab"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module style field groups must be consistent and follow a pattern. Below is a recommended order for your style field groups. These groups can either be at the top level or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#multi-level-grouping",
        children: "nested one group deep"
      }), ". Empty groups may also be removed:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#presets",
          children: "Presets"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Text"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Background"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Border"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hover"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Corner"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Spacing"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alignment"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Custom style groups that don't fit the above"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Advanced"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following field types must be contained in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Styles"
      }), " tab if present:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#alignment",
          children: "Alignment"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#background-image",
          children: "Background image"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Border"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Color"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Font"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#gradient",
          children: "Gradient"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Spacing"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#text-alignment",
          children: "Text alignment"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When moving fields from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content"
        }), " tab to the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Styles"
        }), " tab, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/alias-mapping",
          children: "use alias mapping"
        }), " to preserve styling for modules that are already in use on live pages."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Animation options should always be positioned near the bottom of the field group list."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Options that allow content creators to add code snippets or CSS should be grouped at the end of the field group list under a field labeled ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Advanced"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Controls should be standardized across all modules. For example, all elements that can have a border radius control should offer that control. Avoid offering controls on some modules that are absent on others."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Module fields added to the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Style"
          }), " tab must provide ways to style the module. For example:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Style options such as color, text styling, alignment, spacing, border, and corner radius."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Animations such as hover and slide-in effects."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Presets such as dark and light themes that are meant to change many styles at the same time."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Examples of field organization"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Presets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Presets can be used when wanting to give content creators a limited set of options, often tying back to theme settings. For example, the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Icon"
      }), " module included in the Growth theme contains presents for ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Dark"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Light"
      }), " colors, which enables consistency when used across the website."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Multi-level grouping"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When deciding whether to keep style fields at the top level or nest them, consider the following example."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Icon"
            }), " module included in the Growth theme lists all its styles at the top level because it's one component, and therefore its style options all impact the one component."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-icon-module.png",
            alt: "growth-theme-icon-module"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Speaker card"
            }), " module included in the Growth theme contains multiple components: the card's image and its text contents. Module styles are therefore grouped by component so that the content creator has a more clear process for styling each component."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-speaker-card.png",
            alt: "growth-theme-speaker-card"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Grouping individual fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The button module below contains groupings for ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Presets"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Text"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Background"
      }), ", and more. Although the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Corner"
      }), " field group contains only the corner radius control, it’s still grouped to create a uniform content creation experience."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-requirements-2_1.png",
        alt: "module-requirements-2_1"
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/button-styles.png",
        alt: "button-styles"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}