"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164781431699;
const slug = exports.slug = 'reference/ui-components/crm-action-components/crm-action-link';
const title = exports.title = 'CrmActionLink';
const name = exports.name = 'CrmActionLink | UI components';
const metaDescription = exports.metaDescription = 'Learn about the CrmActionLink component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CrmActionLink | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CrmActionLink"
      }), " component renders a clickable link that can execute a built-in set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview#available-actions",
        children: "CRM actions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This type of component is useful for enabling your extension to interact with other CRM entities, such as records and engagements. To learn more about how CRM action components work together, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview",
        children: "CRM action components overview"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extensions-crm-action-link.png?width=299&height=39&name=ui-extensions-crm-action-link.png",
        alt: "ui-extensions-crm-action-link"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { CrmActionLink } from '@hubspot/ui-extensions/crm';\n\nconst dealContext = {\n objectTypeId: \"0-3\",\n objectId: 14795354663,\n};\n\nhubspot.extend(({ context, runServerlessFunction, actions }) => {\n  return (\n  <>\n   <CrmActionLink\n     actionType=\"ADD_NOTE\"\n     actionContext={dealContext}\n    >\n     Add a note about this deal to the record\n    </CrmActionLink>\n  &lt;/>\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "actionType"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of action to perform. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/overview#available-actions",
              children: "list of available actions"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing the CRM object and record context for performing the action. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/overview#available-actions",
              children: "list of available actions"
            }), " for required context values."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'primary'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'light'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dark'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'destructive'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The color variation of the link. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants section"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onError"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(errors: string[]) => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional callback that will pass any error messages that were generated. Common errors include missing required context values or the user not having sufficient permissions to perform an action."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop, you can control the color of the link."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "primary"
          }), ": the default blue (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#0091ae"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_4.png?width=206&height=37&name=design-guidelines-links_4.png",
            alt: "design-guidelines-links_4"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "light"
          }), ": a white link that turns to a lighter shade of blue on hover (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#7fd1de"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_3.png?width=206&height=34&name=design-guidelines-links_3.png",
            alt: "design-guidelines-links_3"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "dark"
          }), ": a darker shade of blue (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#33475b"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_2.png?width=206&height=38&name=design-guidelines-links_2.png",
            alt: "design-guidelines-links_2"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "destructive"
          }), ": a red link (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#f2545b"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_1.png?width=206&height=37&name=design-guidelines-links_1.png",
            alt: "design-guidelines-links_1"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-button",
          children: "CrmActionButtons"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-card-actions",
          children: "CrmCardActions"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}