"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611087;
const slug = exports.slug = 'guides/cms/content/templates/types/drag-and-drop-templates';
const title = exports.title = 'ドラッグ＆ドロップテンプレート｜HubSpot（ハブスポット）';
const name = exports.name = '[新規]テンプレート - ドラッグ＆ドロップ';
const metaDescription = exports.metaDescription = 'デザインマネージャーのドラッグ＆ドロップテンプレートは、ビジュアルビルダーを使用して作成されるテンプレートです。利便性の面でコードテンプレートが優れているため、新しく作成する場合にドラッグ＆ドロップテンプレートは推奨されません。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image2-3.png';
const featuredImageAltText = exports.featuredImageAltText = 'モジュールの選択を示すデザイン マネージャー インターフェイス';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88-%E3%83%93%E3%83%AB%E3%83%80%E3%83%BC",
  "label": "ドラッグ＆ドロップ テンプレート ビルダー",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "モジュール",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88-%E3%83%93%E3%83%AB%E3%83%80%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97",
  "label": "グループ",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88-%E3%83%93%E3%83%AB%E3%83%80%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97",
  "label": "グローバルグループ",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88-%E3%83%93%E3%83%AB%E3%83%80%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%83%AC%E3%82%AD%E3%82%B7%E3%83%96%E3%83%AB%E3%82%AB%E3%83%A9%E3%83%A0",
  "label": "フレキシブルカラム",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88-%E3%83%93%E3%83%AB%E3%83%80%E3%83%BC"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      h2: "h2",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ドラッグ＆ドロップテンプレート"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**ドラッグ＆ドロップテンプレートは、新しいテンプレートには推奨されません。**ドラッグ＆ドロップテンプレートはテーマの一部として使用できないため、テーマフィールドのようなテーマ機能と、テーマモジュールをサポートしません。また、CMS Hub Starterではサポートされないため、代わりに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), "を使用してください。ドラッグ＆ドロップテンプレートでは、CMSの一部の新しい機能（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "アクセス権設定（メンバーシップ）"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "ドラッグ＆ドロップエリア"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "再利用可能セクション"
        }), "、GraphQLなど）がサポートされません。拡張性が十分ではなく、制作担当者や開発者にとってコードテンプレートほどの利便性が得られません。代わりに、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "ドラッグ＆ドロップエリア"
        }), "を有する、コード化された", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "HTML＋HubLテンプレート"
        }), "の利用をお勧めします。開発者やマーケティング担当者にとっての利便性が向上します。CMSの利用を始めるには、コードテンプレートを使用して構築されている", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate",
          children: "HubSpot Theme Boilerplate"
        }), "（テーマボイラープレート）をぜひご参照ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'marketing_hub-professional', 'cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "テンプレート"
      }), "は、技術的な知識がなくてもHubSpotのCMS上で簡単にウェブサイトを構築できるように設計されています。ドラッグ＆ドロップテンプレートには視覚的なテンプレートビルダーが採用されていますが、内部ではHTML+HubLが生成されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ビジュアルビルダーの動作に必要なHTML出力はHubSpotによって制御されていて、開発者がその構造化部分を直接制御することはできません。さらに、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "layout.cssファイルがページに読み込まれて、ブートストラップ2に基づく基本的な12列グリッドが有効になります"
      }), "。これにより、ドラッグ＆ドロップテンプレートを使用したウェブサイトは既定で、コンテンツの行が縦に積み重なったレスポンシブ対応になります。レスポンシブデザインの複雑なカスタマイズを行う場合には、独自のレスポンシブスタイルを追加可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップテンプレートはデザインマネージャー内で構築し、APIまたは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "ローカル開発ツール"
      }), "を使用する場合にはJSONで表記します。ドラッグ＆ドロップテンプレートの編集方法としては、その性質上、デザイン マネージャー インターフェイスを使用する方法が推奨されます。**コーディングが必要な場合は、バージョン管理機能を使用するか、使い慣れたローカルツールを使用して編集します。HTML+HubLコードテンプレートにより、最適な開発体験が提供されます。**HTML+HubLテンプレートの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), "機能でも、ビジュアル コンテンツ エディター内で制作担当者が作業できるので、デザインマネージャーのドラッグ＆ドロップインターフェイスよりも優れた利便性を備えています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ドラッグ＆ドロップ テンプレート ビルダー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップテンプレートを作成するには、デザインマネージャーを開き、ファインダー内で新しいファイルを作成し、作成するテンプレートと", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "テンプレートのタイプ"
      }), "を選択します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ドラッグ＆ドロップテンプレートには、モジュール、グループ、グローバルグループ、フレキシブルカラムというさまざまな構成要素があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts",
        children: "ドラッグ＆ドロップ テンプレート ビルダーの使用方法"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールは、ページ上で編集できる再利用可能な要素です。モジュールは、HTML+HubLテンプレートのフラグメント、CSS、JS、およびフィールドで構成されます。制作担当者は、モジュールをフレキシブルカラムとdnd_areasの内部に配置できます。制作担当者によるウェブサイトのコンテンツ管理の基本機能です。モジュールを作成することで、数多くの機能、検索、画像ギャラリー、メニュー、複雑なマーケティングアニメーション、電卓、製品比較など、想像力次第でさまざまな処理が可能になり、制作担当者の利便性の向上につながります。フィールドは、制作担当者が出力を制御する手段になります。モジュールはドラッグ＆ドロップテンプレートに使用できるだけではなく、HubSpotのCMSにおける重要な構成要素です。ドラッグ＆ドロップテンプレートでは、モジュールフィールドの既定値を", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
        children: "インスペクター"
      }), "内で設定できます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュールについて"
      }), "をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image2-3.png",
        alt: "モジュールを示すデザイン マネージャー インターフェイス"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グループ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グループは他のグループやモジュールのためのラッパーであり、CSSクラスやラッピングHTMLを使用できます。グループの配置とサイズ設定を円滑化する、レイアウトクラスを伴うラッピングHTMLとして機能します。グループには内部専用名を付けることができます。このようにデザインマネージャー上で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#group-modules",
        children: "モジュールをグループ化"
      }), "することで、ページのさまざまな部分が識別しやすくなります。例として、サイドバーやバナーが挙げられます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["新しいサイトでは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML＋HubL"
        }), "ファイルが推奨されるため、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "ドラッグ＆ドロップエリア"
        }), "の列、セクション、行によってグループの目的の大半が置き換えられます。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["さらに開発者は、パーシャルおよび", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "グローバルパーシャル"
        }), "を作成し、ドラッグ＆ドロップエリアに加えて自由形式のコードも含められます。"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "ドラッグ＆ドロップテンプレートでは、ドラッグ＆ドロップエリア、パーシャル、およびグローバルパーシャルがサポートされません。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image4-1.png",
        alt: "デザインマネージャーでサイドバーグループが選択されているスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルグループ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "グローバルグループは、編集されると、特定のページだけでなくウェブサイト全体に影響するグループです。グローバルグループは、複数のテンプレートに配置可能で、通常はウェブサイトのヘッダーやフッターに使用されます。グローバルグループはパーシャルに似ていますが、ドラッグ＆ドロップテンプレートによって適用される構造に限定されます。必要に応じてHTML＋HubLファイルに埋め込むこともできますが、その場合はグローバルパーシャルを使用する方が理にかなっています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-3.png",
        alt: "ヘッダーとフッターのグローバルグループを示すデザイン マネージャー インターフェイス"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "フレキシブルカラム"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フレキシブルカラムは特別なタイプのグループです。モジュールの既定のセットを含めることができますが、制作担当者はその中のモジュールを追加、削除、移動することが可能です。フレキシブルカラムを利用すると、1次元的にモジュールを上から下に並べ替えることができます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "dnd_areaタグとは異なり"
      }), "、**フレキシブルカラムでは、モジュールを格納できるセクションもスタイル機能もサポートされません。**フレキシブルカラムは、ドラッグ＆ドロップテンプレートに限定されません。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML＋HubL"
      }), "テンプレートで使用できる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "HubLタグ"
      }), "があります。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#add-a-flexible-column",
        children: "ドラッグ＆ドロップテンプレートにフレキシブルカラムを追加する方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "一般的に、ドラッグ＆ドロップエリアの使用が推奨されます。多くの状況で使いやすく、フレキシブルカラムに相当する機能を全て利用できるためです。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "ただしサイドバーのように、フレキシブルカラムを使用する方が便利な場合もあります。メインのコンテンツ領域としては、ドラッグ＆ドロップエリアが非常に柔軟です。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image3-2.png",
        alt: "デザインマネージャーでのメイン コンテンツ グループの選択を示すスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "フレキシブルカラムを使用する理由を考えてみてください。ウェブサイトは変化を伴います。長期間にわたって構築および維持されます。例えば、企業のホームページで特定の製品が大きく扱われることがありますが、マーケティングニーズの変化につれて頻繁な変更が必要になる場合もあります。フレキシブルカラム内のコンテンツは制作担当者による追加、削除、変更が可能なので、マーケティング担当者にとって煩雑な作業ではありません。開発者は軽微なページ調整に対応する代わりに、本質的な開発作業に集中できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "同じように、1つのウェブサイトのページごとに異なる構造が必要な場合があるかもしれません。こうしたページもフレキシブルカラムというコントロールを利用すれば、マーケティング担当者がカスタムモジュールを使用して作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 8,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "ドラッグ＆ドロップテンプレートにカスタムコードを追加する"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "複数の方法が用意されています。最も基本的な方法はカスタムモジュールです。ただし、モジュールやグループのラッパーコードの追加が必要になる場合があります。そのためには、モジュールまたはグループをクリックして、それをインスペクターで表示し、ラッピングHTMLフィールドを検索します。そのフィールドにHTMLを追加します。"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["また、HTMLのheadへのコード追加や、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), "の直前へのコード追加が必要になる場合もあります。テンプレートを開いた状態で、何も選択していないことを確かめてください。インスペクターには、テンプレート自体のフィールドが表示されます。この状態で、スタイルシートやJavaScriptファイルへのリンクを追加したり、headまたは", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), "タグの直前にHTMLを追加したりできます。"]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["追加するには", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
            children: "インスペクター"
          }), "を使用します。インスペクターには、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template##attach-stylesheets-and-javascript-files",
            children: "スタイルシート、JavaScript"
          }), "、", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template#customize-your-template-s-head-and-body-options",
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "<head>"
            }), "マークアップ"]
          }), "などのための、テンプレート側のフィールドがあります。"]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://designers.hubspot.com/hubfs/image5.png",
            alt: "デザインマネージャーのカスタム コード インスペクター パネル"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}