"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 37833970855;
const slug = exports.slug = 'guides/api/cms/media-bridge';
const title = exports.title = 'API del CMS | Centro multimedia';
const name = exports.name = 'vNext Documentos DP - Centro multimedia';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "usar-la-api-del-centro-multimedia",
  "label": "Usar la API del centro multimedia",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-y-personalizar-tus-definiciones-de-objetos-multimedia",
  "label": "Crear y personalizar tus definiciones de objetos multimedia",
  "parent": null
}, {
  "depth": 0,
  "id": "conectar-la-aplicaci%C3%B3n-de-centro-multimedia-con-la-cuenta-de-un-usuario-de-hubspot",
  "label": "Conectar la aplicación de centro multimedia con la cuenta de un usuario de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-tus-objetos-multimedia",
  "label": "Crear tus objetos multimedia",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-m%C3%B3dulos-del-cms-para-incrustar-elementos-multimedia",
  "label": "Crear módulos del CMS para incrustar elementos multimedia",
  "parent": null
}, {
  "depth": 1,
  "id": "configurar-un-dominio-oembed",
  "label": "Configurar un dominio oEmbed",
  "parent": "crear-m%C3%B3dulos-del-cms-para-incrustar-elementos-multimedia"
}, {
  "depth": 0,
  "id": "crear-un-m%C3%B3dulo-personalizado",
  "label": "Crear un módulo personalizado",
  "parent": null
}, {
  "depth": 0,
  "id": "env%C3%ADa-tus-eventos-multimedia",
  "label": "Envía tus eventos multimedia",
  "parent": null
}, {
  "depth": 1,
  "id": "conectar-un-evento-con-un-registro-de-contacto",
  "label": "Conectar un evento con un registro de contacto",
  "parent": "env%C3%ADa-tus-eventos-multimedia"
}, {
  "depth": 1,
  "id": "conexi%C3%B3n-de-un-evento-con-un-elemento-multimedia",
  "label": "Conexión de un evento con un elemento multimedia",
  "parent": "env%C3%ADa-tus-eventos-multimedia"
}, {
  "depth": 1,
  "id": "conectar-un-evento-con-una-p%C3%A1gina",
  "label": "Conectar un evento con una página",
  "parent": "env%C3%ADa-tus-eventos-multimedia"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API del centro multimedia"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La API del centro multimedia permite a los integradores insertar objetos multimedia como archivos de video y audio, y datos de consumo de medios en HubSpot. También crea las siguientes características en la cuenta de HubSpot del usuario:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos para incrustar objetos multimedia en los editores de arrastrar y soltar de HubSpot para páginas y correos electrónicos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eventos de la cronología del CRM que se muestran cuando los prospectos o clientes han interactuado con videos, audio y otros tipos de medios."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Listas segmentadas para experiencias específicas y personalizadas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Workflows para automatizar interacciones basado en eventos de consumo de elementos multimedia."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Informes para medir el impacto de los materiales multimedia."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El centro multimedia utiliza tanto ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), " como eventos unificados, el sistema de seguimiento de eventos de HubSpot. Esto significa que puedes usar tanto la API del centro multimedia como la API de objetos personalizados para crear tu integración."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar la API del centro multimedia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Necesitas una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "cuenta de desarrollador de HubSpot"
      }), " para registrar tu aplicación de centro multimedia y configurar tus definiciones iniciales de objetos multimedia antes de conectar tu aplicación a la cuenta de un usuario de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear y personalizar tus definiciones de objetos multimedia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir un objeto multimedia, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/object-definitions"
      }), ". Utilizarás el parámetro ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaTypes"
      }), " para definir el objeto: ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "DOCUMENT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "OTHER"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de definir los objetos multimedia, crea y modifica las propiedades del objeto multimedia realizando una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/schemas/{objectType}"
      }), " y una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/properties/{objectType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier llamada a la API realizada incluirá su ID de cuenta de desarrollador como el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "portalID"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conectar la aplicación de centro multimedia con la cuenta de un usuario de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para conectar tu aplicación de centro multimedia con la cuenta de un usuario de HubSpot, debes crear una definición de aplicación en la cuenta de tu desarrollador de HubSpot para la aplicación. Las definiciones de la aplicación incluyen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Detalles como el logotipo y el texto que se mostrarán al usuario de HubSpot cuando tu integración intente realizar una conexión inicial a su cuenta."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Examina tus necesidades de integración en la cuenta de HubSpot del usuario."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para conectar tu aplicación de centro multimedia con la cuenta de un usuario de HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crea una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "definición de aplicación"
        }), " en tu cuenta de desarrollador para la aplicación de centro multimedia."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluye los siguientes alcances al definir tu aplicación:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliza la autenticación ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuth"
        }), " al autenticar las llamadas realizadas por tu aplicación. Aprende más sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/intro-to-auth",
          children: "métodos de autentificación"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para verificar que la aplicación está instalada correctamente en el portal de un cliente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Visita ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/media-bridge-demo/{HubID}"
        }), ", reemplazando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{HubID}"
        }), " por el ID de la cuenta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la esquina superior derecha, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicación"
        }), " y selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "aplicación de centro multimedia"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En la aplicación, puedes ver los tipos de medios compatibles de la aplicación y crear elementos multimedia de ejemplo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que la aplicación de centro multimedia se ha instalado en el portal de un cliente, puedes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-your-media-objects",
          children: "Crear objetos multimedia"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-cms-modules-to-embed-media",
          children: "Crear módulos del CMS para incrustar tus objetos de medios"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#send-your-media-events",
          children: "Enviar eventos multimedia"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear tus objetos multimedia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de crear tus definiciones de objetos multimedia e instalar tu aplicación de centro multimedia en la cuenta de un usuario, puedes usar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "token OAuth"
      }), " para crear y modificar objetos multimedia en la cuenta. Como los objetos multimedia son objetos personalizados, usa los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects#tab-2",
        children: "puntos de terminación de la API de objetos personalizados"
      }), " para crearlos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/media-bridge/v1/{appId}/settings/object-definitions/{mediaType}"
        }), " para encontrar el archivo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{objectType}"
        }), " para crear el objeto multimedia en la cuenta del usuario."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un objeto multimedia representa un fragmento de contenido incrustable en un sistema de tercero. Una vez que se agrega un objeto multimedia al centro multimedia, se puede incrustar en el contenido de CMS de HubSpot y asociarlo con eventos multimedia."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para los objetos multimedia de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), " y ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", las tablas a continuación indican todas las propiedades predeterminadas y requeridas (* denota requerido):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID utilizado para identificar el elemento específico de medios en el sistema del centro multimedia de HubSpot. Esto es autogenerado por HubSpot, y no pueden configurarlo los desarrolladores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La duración de los medios en milisegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una URL que debe devolver una respuesta válida oEmbed que sigue la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "especificación oEmbed"
            }), " Requiere un tipo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " con un iframe en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL del archivo multimedia sin procesar. Esto puede utilizarse en el futuro para admitir la incrustación en redes sociales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de una imagen utilizada como miniatura para incrustar los elementos multimedia en el contenido. El tamaño ideal para esta miniatura es de 640x480 píxeles."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de una imagen que representa los medios. Esta imagen debe tener las mismas dimensiones que los medios originales y se puede usar en lugares donde se necesita un parámetro de sustitución de imagen (por ejemplo, cuando se insertan los medios en un correo electrónico)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de los medios en el sistema del tercero. Esto les da a los integradores la capacidad de recuperar medios del centro multimedia según el mismo ID que usan en su propio sistema. (Este es el punto de terminación de API que aprovecha este mapeo)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una ruta suministrada por el proveedor al objeto, destinada a representar la ubicación del objeto en el sistema de carpetas del tercero (si existe). HubSpot intentará representar esta estructura de directorios al mostrar estos objetos al usuario, pero puede anidar los objetos y carpetas de cada proveedor dentro de una carpeta de nivel superior con el nombre del proveedor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de los medios. Esto se mostrará dentro de la interfaz de usuario de HubSpot en lugares como el selector de medios."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una URL que permite a un usuario ver los elementos multimedia o interactuar con ellos en el sistema del proveedor de elementos multimedia. Esto se utiliza en la interfaz de usuario de HubSpot para dar a los usuarios la capacidad de identificar los medios sin depender solo del título."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para los objetos multimedia de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), " las tablas a continuación indican todas las propiedades predeterminadas y requeridas (* denota requerido):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID utilizado para identificar el elemento específico de medios en el sistema del centro multimedia de HubSpot. Esto es autogenerado por HubSpot, y no pueden configurarlo los desarrolladores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una URL que debe devolver una respuesta válida oEmbed que sigue la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "especificación oEmbed"
            }), " Requiere un tipo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " con un iframe en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL del archivo de medios sin formato. Esto puede utilizarse en el futuro para admitir la incrustación en redes sociales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL a una imagen que se utilizará como la miniatura para incrustar los medios en el contenido en lugares como el selector de medios. El tamaño ideal para esta miniatura es de 640x480 píxeles."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de una imagen que representa los medios. Esta imagen debe tener las mismas dimensiones que los medios originales y se puede usar en lugares donde se necesita un parámetro de sustitución de imagen (por ejemplo, cuando se insertan los medios en un correo electrónico)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de los medios en el sistema del tercero. Esto les da a los integradores la capacidad de recuperar medios del centro multimedia según el mismo ID que usan en su propio sistema. (Este es el punto de terminación de API que aprovecha este mapeo)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una ruta suministrada por el proveedor al objeto, destinada a representar la ubicación del objeto en el sistema de carpetas del tercero (si existe). HubSpot intentará representar esta estructura de directorios al mostrar estos objetos al usuario, pero puede anidar los objetos y carpetas de cada proveedor dentro de una carpeta de nivel superior con el nombre del proveedor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de los medios. Esto se mostrará dentro de la interfaz de usuario de HubSpot en lugares como el selector de medios."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una URL que permite a un usuario ver los medios o interactuar con ellos en el sistema del proveedor de medios. Esto se utiliza en la interfaz de usuario de HubSpot para dar a los usuarios la capacidad de identificar los medios sin depender solo del título."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear módulos del CMS para incrustar elementos multimedia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada proveedor de aplicación de centro mutimedia es responsable de crear su propio ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulo"
      }), " para renderizar sus medios en el CMS de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se instala una aplicación de centro multimedia en una cuenta de HubSpot, el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#embed",
        children: "campo Insertar"
      }), " en el módulo tiene un tipo de fuente ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Integración de elementos multimedia"
      }), " adicional. Esto le permite al usuario seleccionar elementos multimedia de la aplicación instalada para incrustarlos en la página de su sitio web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de que el usuario selecciona un elemento multimedia específico que se va a incrustar, los medios ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_url"
      }), " y ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_response"
      }), " están disponibles en HubL para renderizar fácilmente los reproductores. Adicionalmente, el ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "id"
      }), " y ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "media_type"
      }), " de los medios seleccionados se almacenan para habilitar la consulta del objeto del CRM subyacente a través de la función ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crm_objects"
      }), " de HubL. Esto se puede usar para extraer cualquiera o todas las propiedades que son parte de un objeto multimedia."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ejemplo de uso de la función HubL crm_objects con un objeto multimedia donde los ID son ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "459"
      }), " y ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "922"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set objects = crm_objects(\"a123_Videos\", [459,922]) %} {{ objects }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para buscar una imagen específica con el mismo objeto: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set object = crm_object(\"a123_Images\", 459) %} {{ object }}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las aplicaciones pueden buscar el tipo de objeto (\"a123_Videos\" en el ejemplo) haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/{appId}/settings/object-definitions/{mediaType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los desarrolladores deben usar los puntos de terminación de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/source-code",
        children: "API del código fuente de CMS"
      }), " para insertar su código de módulo personalizado en las cuentas de los clientes una vez que los clientes se hayan conectado a través de oAuth. Una vez que el código del módulo se introduce en la cuenta del cliente, automáticamente podrán comenzar a usar el módulo del desarrollador en su contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar un dominio oEmbed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para utilizar la función HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#oembed",
        children: "oEmbed"
      }), ", el dominio que se utiliza para obtener la respuesta oEmbed debe registrarse haciendo una solicitud a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/oembed-domains"
      }), ". Se deben incluir los siguientes parámetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Esquema:"
          }), " el patrón de URL para las URL de los elementos multimedia. Este patrón de URL se usa para hacer coincidir la URL pasada a la función HubL oEmbed con tu API de oEmbed. Los valores comodín se admiten utilizando ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "*"
          }), " (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "www.domain.com/*"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL:"
          }), " la URL de la API de oEmbed. La URL multimedia se pasa a esta URL a través de un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "URL"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Descubrimiento (booleano):"
          }), " determina si tu API oEmbed admite o no la función ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://oembed.com/#section4",
            children: "Discovery"
          }), " de oEmbed."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por opción predeterminada, los dominios oEmbed registrados estarán disponibles para todos los clientes que hayan instalado tu aplicación. Si tienes dominios personalizados que son únicos para cada cliente, puedes especificar en qué cuenta se debe permitir el uso de un dominio oEmbed pasando un valor portalId a la solicitud de API al configurar el dominio oEmbed. Esto garantizará que solo la cuenta de HubSpot especificada pueda usar ese dominio oEmbed."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear un módulo personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear un módulo personalizado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivos y plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Herramientas de diseño"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior izquierda, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivo"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivo nuevo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "¿Qué te gustaría construir hoy?"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Módulo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Siguiente"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona la casilla de verificación junto a cada tipo de contenido en el que se utilizará el módulo: ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "páginas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "publicaciones de blog"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "anuncios de blogs"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "correos electrónicos"
        }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "cotizaciones"
        }), ". Los módulos utilizados en plantillas de correo electrónico no pueden incluir CSS o JavaScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona si este módulo será un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "módulo local"
        }), " o un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "módulo global"
        }), ". Si creas un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/design-manager-user-guide-v2/how-to-use-global-content-across-multiple-templates",
          children: "módulo global"
        }), ", editar el contenido de este módulo actualizará cada ubicación donde se utilizó el módulo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Introduce un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre de archivo"
        }), " para tu módulo, y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Campos"
        }), " a la derecha, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar campo"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Incrustar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/_cARgVZZLUD-nsJUIaG8XMUdSm1GRLr4Gfw96HeZ6SyXFG0t6yiiPKt5ToSe7nq8arSnG569_wg5X2XsD0XEtmEGiX9sbQxH7deUIJKGshXamME6vXrLN2oVSAt-cmrkTN2nggDsdkChDnEOxKrw5mo",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Tipos de fuentes compatibles"
          }), ", selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Integración multimedia"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Contenido predeterminado de inserción"
          }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seleccionar desde [aplicación de centro multimedia]"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el panel derecho, selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "multimedia"
          }), " que deseas incrustar en el módulo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Establece cualquiera de las opciones de editor, condiciones de presentación de campos y opciones de repetidor de campos."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Bajo ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Nombre de la variable HubL"
          }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar fragmento"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pega el fragmento en la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "module.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para obtener una vista preliminar de cómo se verá el módulo en tu página, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Vista preliminar"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección de la izquierda, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seleccionar desde [Aplicación de centro multimedia]"
          }), " y luego selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "elemento multimedia"
          }), " que deseas previsualizar."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Envía tus eventos multimedia"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un evento multimedia es un evento que ocurre en relación con un objeto multimedia, como un evento de reproducción. Una vez que se envía un evento multimedia a la aplicación de centro multimedia, se puede usar en informes y en tarjetas CRM de línea de tiempo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay tres tipos de eventos multimedia:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Evento de reproducción:"
          }), " representa cuándo un usuario comienza a reproducir un elemento multimedia."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Evento de cuartil:"
          }), " representa cuándo un usuario alcanzó hitos trimestrales (0%, 25%, 50%, 75%, 100%) en términos de cuántos elementos multimedia ha consumido."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Evento de período de atención"
          }), ": representa cuando un usuario haya consumido completamente un elemento multimedia o cuando el usuario haya completado su sesión."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los eventos se pueden enviar haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played-percent"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/attention-span respectively"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para que los eventos multimedia se muestren en la cronología de contacto del usuario en HubSpot, se debe enviar un evento de ", (0, _jsxRuntime.jsx)("u", {
        children: "reproducción"
      }), " a la aplicación de centro multimedia para cada sesión. Los eventos de una sola sesión se mostrarán en una tarjeta en la cronología de actividad de contacto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando los eventos se envían utilizando los puntos de terminación de eventos v2, se procesan de forma asíncrona, a diferencia de los enviados a través de los puntos de terminación v1. Como tal, recomendamos lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La versión v1 de los puntos de terminación debe usarse para cualquier prueba, ya que una solicitud errónea se eliminará inmediatamente."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La versión v2 de los puntos de terminación debe usarse en la producción, ya que su naturaleza asíncrona ayudará a evitar retrasos en el cliente mientras se escribe el evento en el centro multimedia. Los eventos también se retienen y se vuelven a intentar en caso de una falla temporal en el servidor del centro multimedia."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conectar un evento con un registro de contacto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para conectar un evento multimedia con un registro de contacto, los integradores deben proporcionar un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " o un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), ". Si solo se proporciona un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), ", se convertirá en un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), ". Si ambos se proporcionan en la solicitud, el ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " se utilizará como fuente de la verdad. Este parámetro permite a la aplicación de centro multimedia crear una asociación entre el registro de contacto y el evento."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que un evento multimedia se ha conectado a un registro de contacto, el evento se puede usar en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-reports-with-the-custom-report-builder",
        children: "informes de objetos cruzados"
      }), ". Esto permite a los clientes vincular sus eventos multimedia con los registros de contacto, así como con las empresas y negocios asociados."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conexión de un evento con un elemento multimedia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar un evento multimedia a un elemento multimedia específico, se deben incluir en la solicitud los parámetros ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "externalID"
      }), ". Si se proporcionan ambos, el ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " se utilizará como fuente de la verdad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conectar un evento con una página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para asociar un evento multimedia a una página de HubSpot, se deben incluir los siguientes parámetros en la solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si la página está alojada en el CMS de HubSpot, se debe proporcionar el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageId"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si la página ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " está alojada en el CMS de HubSpot, se debe incluir ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageName"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageUrl"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La siguiente tabla describe las propiedades compatibles para los tres eventos multimedia:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de evento"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de los medios con los que se relaciona este evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID de los medios en el sistema del tercero. Esto les da a los desarrolladores la capacidad de referirse a los medios en el centro multimedia basándose en el mismo identificador que usan en su propio sistema. Esto se puede utilizar en lugar del ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " en eventos. Si se proporcionan tanto un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), ", se utilizará el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " y el externalId se ignorará.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sessionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un identificador único para representar una sesión de visualización. Esto puede significar cosas diferentes para diferentes proveedores, y HubSpot permite que los proveedores decidan qué significa una sesión para ellos. Esto se utilizará para agrupar eventos que ocurrieron en la misma sesión. Se espera que esto lo genere el sistema del tercero."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del contacto en el sistema de HubSpot que consumió los medios. Esto se puede buscar utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/objects/contacts/v1#get-a-contact-by-its-user-token",
              children: "API obtener contacto mediante token de usuario (utk) de HubSpot"
            }), ". La API también admite el suministro de un usertoken, y se encargará de convertirlo en un ID de contacto automáticamente."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactUtk"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El usertoken (utk) que identifica qué contacto consumió los medios."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de contenido de la página en la que se produjo un evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre o el título de la página en la que se produjo un evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL de la página en la que se produjo un evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La marca de tiempo en la que ocurrió este evento, en milisegundos desde la época."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString / attentionSpanMap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Capacidad de atención"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Estos son los datos sin procesar que proporcionan los datos más granulares sobre los períodos de los medios y cuántas veces cada período fue consumido por el usuario. Ejemplo: considera un video de 10 segundos donde cada segundo es una capacidad. Si un visitante ve los primeros 5 segundos del video, luego reinicia el video y vuelve a ver los primeros 2 segundos, el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString"
            }), " resultante sería ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "“0=2;1=2;2=1;3=1;4=1;5=0;6=0;7=0;8=0;9=0;”"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Capacidad de atención"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El porcentaje de los medios que el usuario consumió. Los proveedores pueden calcular esto de manera diferente según la forma en que consideren las vistas repetidas de la misma parte de los medios. Por esta razón, la API no intentará validar totalPercentWatched con respecto a la capacidad de atención del evento. Si falta, HubSpot calculará a partir del mapa de capacidad de atención de la siguiente manera: (número de períodos con un valor de 1 o más)/(número total de capacidades)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalSecondsPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Capacidad de atención"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Los segundos que un usuario pasó consumiendo los medios. El centro multimedia calcula esto como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            }), "*", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaDuration"
            }), ". Si un proveedor desea que esto se calcule de manera diferente, puede proporcionar el valor precalculado cuando crea el evento"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "playedPercent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evento de cuartil"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de porcentaje del cuartil (0, 25, 50, 75, 100) para cuánto de los medios se ha consumido hasta ahora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "iframeUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evento de reproducción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una URL que se puede utilizar para mostrar datos de un sistema externo utilizando un iFrame. Cuando se incluya, el evento de la cronología del contacto mostrará un enlace que abrirá una ventana modal que muestra el contenido del iFrame cuando se hace clic."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo multimedia al que pertenece el evento (por ejemplo, VIDEO o AUDIO) Esto nos permite asignar adecuadamente el evento a los objetos correctos cuando un solo proveedor admite varios tipos de elementos multimedia.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}