"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694137;
const slug = exports.slug = 'guides/cms/content/modules/quickstart';
const title = exports.title = 'モジュールを使ってみる';
const name = exports.name = 'モジュールを使ってみる';
const metaDescription = exports.metaDescription = 'カスタムモジュールと呼ばれる、さまざまなページやテンプレートで再利用可能なコンポーネントを作成しましょう。フィールドの説明や、フィールドをHubLで使う方法をご紹介します。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Module%20Editor-1-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'モジュールを使ってみる';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%A7%8B%E3%82%81%E3%82%8B%E5%89%8D%E3%81%AB",
  "label": "始める前に",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E9%96%8B%E7%99%BA%E7%92%B0%E5%A2%83%E3%82%92%E3%82%BB%E3%83%83%E3%83%88%E3%82%A2%E3%83%83%E3%83%97%E3%81%99%E3%82%8B",
  "label": "ローカル開発環境をセットアップする",
  "parent": null
}, {
  "depth": 0,
  "id": "cms%E3%83%9C%E3%82%A4%E3%83%A9%E3%83%BC%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AB%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "CMSボイラープレートをアカウントに追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E5%86%85%E3%81%A7%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "HubSpot内でモジュールを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AB%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "モジュールにフィールドを追加する",
  "parent": "hubspot%E5%86%85%E3%81%A7%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E9%A1%A7%E5%AE%A2%E5%90%8D%E3%82%92%E5%85%A5%E5%8A%9B%E3%81%99%E3%82%8B%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "顧客名を入力するテキストフィールドを追加する",
  "parent": "hubspot%E5%86%85%E3%81%A7%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E9%A1%A7%E5%AE%A2%E3%81%AE%E3%83%97%E3%83%AD%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E5%86%99%E7%9C%9F%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B%E7%94%BB%E5%83%8F%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "顧客のプロフィール写真を設定する画像フィールドを追加する",
  "parent": "hubspot%E5%86%85%E3%81%A7%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "sally%E3%81%8B%E3%82%89%E3%81%AE%E8%A9%95%E4%BE%A1%E3%82%92%E5%85%A5%E5%8A%9B%E3%81%99%E3%82%8B%E3%83%AA%E3%83%83%E3%83%81-%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88-%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "Sallyからの評価を入力するリッチ テキスト フィールドを追加する",
  "parent": "hubspot%E5%86%85%E3%81%A7%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B%E3%81%9F%E3%82%81%E3%81%AEhubl%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "フィールドデータを表示するためのHubLを追加する",
  "parent": "hubspot%E5%86%85%E3%81%A7%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%A7%E8%A1%A8%E7%A4%BA%E3%81%97%E3%81%A6%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B",
  "label": "モジュールをローカル環境で表示して変更する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E5%86%85%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC%E3%82%92%E9%96%8B%E3%81%8F",
  "label": "ローカル環境内のモジュールフォルダーを開く",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%A7%E8%A1%A8%E7%A4%BA%E3%81%97%E3%81%A6%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AEfields.json%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B",
  "label": "モジュールのfields.jsonファイルを確認する",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%A7%E8%A1%A8%E7%A4%BA%E3%81%97%E3%81%A6%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AEmodule.html%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B",
  "label": "モジュールのmodule.htmlファイルを確認する",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%A7%E8%A1%A8%E7%A4%BA%E3%81%97%E3%81%A6%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AEmodule.css%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B",
  "label": "モジュールのmodule.cssファイルを確認する",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%A7%E8%A1%A8%E7%A4%BA%E3%81%97%E3%81%A6%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%A7%E8%A1%8C%E3%81%A3%E3%81%9F%E5%A4%89%E6%9B%B4%E3%82%92hubspot%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AB%E3%83%97%E3%83%83%E3%82%B7%E3%83%A5%E3%81%99%E3%82%8B",
  "label": "ローカル環境で行った変更をHubSpotアカウントにプッシュする",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E3%81%A7%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E3%81%AE%E5%A4%89%E6%9B%B4%E3%82%92%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B",
  "label": "HubSpotでローカルの変更をプレビューする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AB%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "モジュールをテンプレートに追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E3%81%A7%E5%A4%89%E6%9B%B4%E3%82%92%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B",
  "label": "HubSpotで変更をプレビューする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E5%86%85%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%82%A4%E3%82%BA%E3%81%99%E3%82%8B",
  "label": "テンプレート内のモジュールをカスタマイズする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AB%E3%81%95%E3%82%89%E3%81%AB2%E3%81%A4%E3%81%AEtestimonial%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "テンプレートにさらに2つのTestimonialモジュールを追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E5%90%8D%E3%82%92%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B",
  "label": "テーマ名を変更する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%AC%A1%E3%81%AE%E3%82%B9%E3%83%86%E3%83%83%E3%83%97",
  "label": "次のステップ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB",
  "label": "その他のチュートリアル",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      pre: "pre",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "モジュールを使ってみる"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このチュートリアルでは、HubSpot CMSボイラープレートテーマを使用してモジュールを作成し、このモジュールをテーマの一部としてテンプレートやページで使用する方法を説明します。このチュートリアルに従って作業を進めると、テキストフィールド、画像フィールド、リッチ テキスト フィールドを含むTestimonial（お客様の声）モジュールが完成します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "CMS Hubを使って初めて開発する場合には、こちらをお勧めします。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "CMS Hub開発のクイックスタート"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**テーマで使用するモジュールを開発し、HubSpotのアセットマーケットプレイスに掲載することを目指している場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements",
          children: "アセットマーケットプレイスのモジュールに必要な条件"
        }), "をご確認ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "始める前に"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このチュートリアルを開始する前に、次の作業を行ってください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-cms-developer-sandbox",
          children: "CMS Hub開発者用サンドボックス"
        }), "をセットアップします。既存のアカウントを使用することもできますが、サンドボックスを使用すると、メインアカウントのアセットに影響を与えることなく開発できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotのローカル開発ツールの利用に必要な", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), "をインストールします。バージョン10以降がサポートされています。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["今すぐ始めるには、完成した", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
        children: "プロジェクトファイル"
      }), "をプレビューできます。ボイラープレートテーマのコードは時間の経過とともに変化する可能性があるため、チュートリアル中に開発者が作成または編集する必要がある特に重要なファイルのみが含まれています。以下のようなファイルです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Testimonial.module：**チュートリアルで作成されるカスタムモジュールを構成するファイルを含むフォルダー。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**homepage.html：**編集してカスタムモジュールを含めるテンプレート。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**images：**モジュール内で使用するプロフィール写真が入ったフォルダー。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ローカル開発環境をセットアップする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このチュートリアルでは、ローカル環境でモジュールを開発します。コマンド ライン インターフェイスからHubSpotを利用するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "HubSpot CLI"
      }), "をインストールし、アカウントへのアクセスを設定する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ターミナルで次のコマンドを実行して、CLIをグローバルにインストールします。CLIを現在のディレクトリーにのみインストールする場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "を実行します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm install -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テーマファイルの保存先とするディレクトリーで、HubSpotアカウントを認証し、CLIを介してアカウントを操作できるようにします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs init\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "キーを押して、ブラウザーでパーソナル アクセス キーのページを開きます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["デプロイ先にする", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アカウント"
        }), "を選択してから、［このアカウントで続行］をクリックします。アカウントのパーソナル アクセス キーのページにリダイレクトされます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "「パーソナル アクセス キー__」の横にある［表示］をクリックしてキーを表示します。次に［コピー］をクリックして、キーをクリップボードにコピーします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コピーしたキーをターミナルに貼り付け、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "キーを押します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["他と重複しないアカウント", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名"
        }), "を入力します。この名前は自分の区別用で、コマンドを実行する際にも使用します。次に", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "キーを押して、ローカル開発ツールをアカウントに接続します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "ファイルが作成されたことの確認として、成功メッセージが表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMSボイラープレートをアカウントに追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次のコマンドを実行して、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), "という名前の新しいテーマを作成します。このコマンドを実行すると、ボイラープレートアセットを格納する", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), "という名前のフォルダーが作業ディレクトリー内に作成されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create website-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルをアカウントにアップロードします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload <src> <destination>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在の作業ディレクトリーを基準としたローカルファイルの相対パス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotアカウント内のアップロード先とするパス。新しいフォルダーにすることができます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme my-new-theme"
      }), "を実行すると、コンピューター内の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-theme"
      }), "フォルダーがHubSpot内の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-new-theme"
      }), "フォルダーにアップロードされます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["既定では、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config,yml"
        }), "ファイル内に指定した既定のアカウントにアップロードされます。ただし、コマンドに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--account=<accountNameOrId>"
        }), "フラグを追加して、アカウントを指定することもできます。例えば、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload --portal=mainProd"
        }), "です。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CLIコマンドリファレンス"
        }), "をご確認ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot内でモジュールを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ローカル環境内でCMSボイラープレートを使用して、テーマの新しいモジュールを作成します。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["このチュートリアルでは、HubSpot内でモジュールを作成してから、CLIを使用して、そのモジュールをテーマに取り込みます。ただし、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#create-new-files",
          children: "hs create module"
        }), "コマンドを使用して、ローカルでゼロからモジュールを作成することもできます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "HubSpotアカウントにログインし、［マーケティング］>［ファイルとテンプレート］>［デザインツール］に移動してデザインマネージャーを開きます。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["デザインマネージャーの左のサイドバーで、アップロードした", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "テーマフォルダー"
          }), "を開きます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["テーマフォルダー内にある", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "モジュールフォルダー"
          }), "を開きます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "このフォルダー内に新しいモジュールを作成するために、画面左上の［ファイル］>［新規ファイル］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["ダイアログボックスで、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ドロップダウンメニュー"
          }), "をクリックし、［モジュール］を選択してから［次へ］をクリックします。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/new-module-dropdown-menu0.png",
            alt: "new-module-dropdown-menu0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［ページ］チェックボックスをオンにして、モジュールをウェブサイトとランディングページで使用できるようにします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["モジュールの名前として「", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Testimonial"
          }), "」（お客様の声）と入力してから、［作成］をクリックします。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールにフィールドを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次は、以下の3つのフィールドをモジュールに追加します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "評価コメントを寄せてくれた顧客の名前を保存するテキストフィールド。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "顧客のプロフィール写真を保存する画像フィールド。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "顧客からの評価コメントを保存するリッチ テキスト フィールド。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "顧客名を入力するテキストフィールドを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "モジュールエディターの右のサイドバーで、［フィールドの追加］ドロップダウンメニューをクリックし、［テキスト］を選択します。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-add-field.png",
            alt: "testimonial-module-add-field"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["右のサイドバーで、右上にある", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "鉛筆アイコン"
          }), "をクリックし、フィールドに名前を付けます。このチュートリアルでは、「", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Customer Name"
          }), "」（顧客名）と入力します。これにより、HubL変数名が「", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "customer_name"
          }), "」に変わります。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["［既定のテキスト］を「", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sally"
          }), "」に設定します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Text-Field-3.png",
        alt: "テキストフィールドの例"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["右側のサイドバーの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "階層リンク"
        }), "アイコンをクリックして、メインモジュールのメニューに戻ります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-breadcrumb-icon0.png",
        alt: "module-breadcrumb-icon0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "顧客のプロフィール写真を設定する画像フィールドを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "同じ方法で他のフィールドを追加します。今回は、［画像］フィールドタイプを選択します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["画像フィールドに「", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Profile Picture"
        }), "」（プロフィール写真）というラベルを付け、［HubL変数名］を「", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "profile_pic"
        }), "」にします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［既定の画像］では、完成した", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
          children: "プロジェクトファイル"
        }), "が格納されている「**images**」フォルダー内にあるSallyのプロフィール写真を選択します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［代替テキスト］を「", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Customer Profile Picture"
        }), "」（サリーのプロフィール写真）に設定します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/getting-started-with-modules-profile-pic.png",
        alt: "getting-started-with-modules-profile-pic"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sallyからの評価を入力するリッチ テキスト フィールドを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "同じ方法で他のフィールドを追加します。今回は、［リッチテキスト］フィールドタイプを選択します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["リッチ テキスト フィールドに「", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial"
        }), "」（お客様の声）というラベルを付けます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［既定のリッチテキスト］ボックスをクリックし、\"I've had nothing but wonderful experiences with this company.\"（本当に素晴らしいサービスでした）と入力します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Rich-Text-Field-5--.png",
        alt: "リッチ テキスト フィールドの例"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールの複数のフィールドにデータを追加しました。ただし、現時点ではこのモジュールに、そのデータをレンダリングするためのHTMLが含まれていません。モジュールエディターでは、この状態を反映して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "セクションが空になっています。 ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-html-empty0.png",
        alt: "module-html-empty0"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次は、フィールドのデータを表示するためのHubLを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "に追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "フィールドデータを表示するためのHubLを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これまでに作成したフィールドのデータを表示するために、次のHubLを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ペインに追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{module.customer_name}}\n<img src={{module.profile_pic.src}} alt=\"{{module.profile_pic.alt}}\">\n{{module.testimonial}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記のHubLトークンでは、各フィールドのデータにアクセスするためにドット表記を使用しています。この例ではモジュールフィールドからデータを取得する必要があるため、各トークンを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "で始めて、その後にフィールドのHubL変数名を続けています。上記の3行目にある", (0, _jsxRuntime.jsx)(_components.code, {
        children: "profile_pic"
      }), "トークンに示されているように、ドット表記を使用すると、特定のフィールドにアクセスできるだけでなく、そのフィールドの特定のプロパティーにアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubLをモジュールに追加した後、画面右上の［プレビュー］をクリックして、現時点でモジュールがどのように表示されるかを確認します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-previewer.png",
        alt: "design-manager-previewer"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "確認し終わったら、右上にある［変更を公開］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot内でモジュールを作成して公開したので、次はCLIを使用してモジュールをローカル環境に取り込みます。こうすることで、モジュールの内容をローカル環境で表示し、さらに変更を加えることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールをローカル環境で表示して変更する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールをローカル環境で表示するには、まず、モジュールをローカルテーマに取り込む必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["ターミナルで、コマンド", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs fetch <hs_src> <destination>"
          }), " を実行します。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<hs_src>"
              }), "は、HubSpot内でのモジュールのファイルパスです。ファイルパスを取得するには、デザインマネージャーの左のサイドバーでモジュールを右クリックし、［パスをコピー］を選択します。"]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-copy-path0.png",
                alt: "design-manager-copy-path0"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<destination>"
              }), "は、テーマが置かれているローカルディレクトリーのパスです。これを省略した場合は既定で、コマンドによって現在の作業ディレクトリーが使用されます。"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、該当するディレクトリーが既にカレント作業ディレクトリーになっている場合、fetchコマンドは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch my-theme/modules/testimonial.module\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ローカル環境内のモジュールフォルダーを開く"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "使い慣れたコードエディター上で、HubSpotアカウントから取り込んだモジュールのフォルダーに進みます。モジュールフォルダーには、次の5つのファイルがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールのフィールドが含まれているJSONオブジェクト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールに関するメタ情報が含まれているJSONオブジェクト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.css"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールのスタイルを設定するCSSファイル。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュール用のHTMLおよびHubL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.js"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュール用のJavaScript。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュールのファイル構造"
      }), "、特に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "ファイルに関する詳細情報のドキュメントをご覧ください。このチュートリアルでは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "の3つのファイルに焦点を当て、それらがどのように生成され、デザインマネージャーとモジュールエディターの間でダウンロード／アップロードされるかを説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールのfields.jsonファイルを確認する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルを開きます。このファイルには次のようなJSONオブジェクトが含まれています（一部の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "番号、画像フィールドの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "属性、また場合によってはフィールドの順序などを除く）。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//fields.json\n\n[\n  {\n    \"label\": \"Customer Name\",\n    \"name\": \"customer_name\",\n    \"id\": \"2a025cd5-7357-007f-ae2f-25ace762588e\",\n    \"type\": \"text\",\n    \"required\": true,\n    \"locked\": false,\n    \"validation_regex\": \"\",\n    \"allow_new_line\": false,\n    \"show_emoji_picker\": false,\n    \"default\": \"Sally\"\n  },\n  {\n    \"label\": \"Profile Picture\",\n    \"name\": \"profile_pic\",\n    \"id\": \"7877fb84-eb8a-d2c7-d939-77e6e9557d8f\",\n    \"type\": \"image\",\n    \"required\": false,\n    \"locked\": false,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"default\": {\n      \"src\": \"https://cdn2.hubspotqa.net/hubfs/101140939/profile-pic-sally.svg\",\n      \"alt\": \"Sally Profile Picture\",\n      \"width\": 100,\n      \"height\": 100,\n      \"max_width\": 100,\n      \"max_height\": 100\n    }\n  },\n  {\n    \"label\": \"Testimonial\",\n    \"name\": \"testimonial\",\n    \"id\": \"c5524ece-1ab5-f92d-a5de-e2bf53199dfa\",\n    \"type\": \"richtext\",\n    \"required\": false,\n    \"locked\": false,\n    \"default\": \"<p>I’ve had nothing but wonderful experiences with this company.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のフィールドの値は、ステップ3で追加した値と一致します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "：フィールドの名前。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), "：フィールドのラベル。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "default"
        }), "：フィールドの既定値。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールのmodule.htmlファイルを確認する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイルには、先ほどHubL＋HTMLモジュールエディターで記述したHubLとHTMLが含まれています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このコードに工夫を加えて、CSSでスタイル設定できるよう、次のコードをコピーして、ファイルに貼り付けます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"testimonial\">\n  <h1 class=\"testimonial__header\"> {{ module.customer_name }} </h1>\n  <img class=\"testimonial__picture\" src={{ module.profile_pic.src }} alt={{ module.profile_pic.alt }}>\n  {{ module.testimonial }}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["上のHTMLの記述では、HubSpot CMSボイラープレートテーマの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/bem-101/",
          children: "スタイルガイド"
        }), "（英語）に従った", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/STYLEGUIDE.md#css-code-formatting",
          children: "BEMクラス構造"
        }), "（英語）を使用しています。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールのmodule.cssファイルを確認する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "ファイルは、現時点では空の状態になっています。スタイルを追加するには、次のコードをコピーし、ファイルに貼り付けます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial {\n  text-align: center;\n}\n\n.testimonial__header {\n  font-weight: bold;\n}\n\n.testimonial__picture {\n  display: block;\n  margin: auto;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードの追加後、ファイルを保存します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ローカル環境で行った変更をHubSpotアカウントにプッシュする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ローカル環境での変更を保存したら、その変更内容をHubSpotアカウントにプッシュします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ターミナルを開き、正しいディレクトリー上で作業していることを確かめます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["保存時に変更がHubSpotに適用されるように、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <destination>"
        }), "コマンドを実行します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch my-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotでローカルの変更をプレビューする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、［マーケティング］＞［ファイルとテンプレート］＞［デザインツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["左側のサイドバーで、作成した", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "テーマ"
        }), "に進み、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "module"
        }), "フォルダーを開いて", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial"
        }), "モジュールを選択します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["モジュールを開き、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), "ペインと", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.css"
        }), "ペインに変更が反映されていることを確認します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上にある［プレビュー］をクリックします。新しいタブが開き、モジュールのプレビューが表示されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Module-Preview-7.png",
        alt: "モジュールのプレビューの例"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここまでのチュートリアルでは、次のような作業を行いました。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotでモジュールを作成しました。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールをローカル環境に取り込みました。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ローカルの開発ツールを使用してHubL＋HTMLおよびCSSを変更しました。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このチュートリアルの次のパートでは、テンプレート内のモジュールの使い方を説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールをテンプレートに追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["チュートリアルのこの部分では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modules"
      }), "フォルダーと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), "フォルダーを主に扱います。 ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/theme-folder-structure-getting-started-with-modules0.png",
        alt: "theme-folder-structure-getting-started-with-modules0"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "簡単な定義で説明するなら、モジュールとはHubSpotのテンプレートとページの編集可能な領域です。HubSpotではデザインマネージャーを使ってモジュールをテンプレートに挿入できますが、ここではHubLを使用して、モジュールをローカル環境内のテンプレートに追加します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["コードエディターで、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "templates"
          }), "フォルダーを開き、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "ファイルを開きます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "ファイル内の28行目付近から始まる最後の", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), "に移動します。ここでは、このセクションに新しいモジュールを追加します。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/home-html-add-testimonial-module-to-section0.png",
            alt: "home-html-add-testimonial-module-to-section0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), "内の他の", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_modules"
          }), "の上に、次のHubLモジュールタグをコピーして貼り付けます。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path= “../modules/Testimonial.module”,\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このHubLタグでは、新しいモジュールが相対ファイルパスで参照されます。このモジュールを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "の他の2つのモジュールと均等な割合で収めるには、モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), "で、幅とオフセットをそれぞれ指定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotのCMSでは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview#columns",
          children: "12カラムのグリッドシステム"
        }), "を使用しているため、このモジュールを他の2つと均等な割合で配置するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), "の各モジュールを更新して幅を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), "に設定する必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次に、グループの最初の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), "（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), "）のオフセットを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), "に設定して、このモジュールが最初に配置されるようにします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["2つ目の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), "（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linked_image"
        }), "）のオフセットを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), "に設定して、2番目に配置されるようにします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["3つ目の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), "（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "rich_text"
        }), "）のオフセットを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "8"
        }), "に設定して、3番目に配置されるようにします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["各", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), "を設定した後のコードは、次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n    background_color=\"#f8fafc\",\n    vertical_alignment=\"MIDDLE\"\n  %}\n\n    {% dnd_module\n      path= “../modules/Testimonial.module”,\n      offset=0,\n      width=4\n    %}\n    {% end_dnd_module %}\n\n    {% dnd_module\n      path=\"@hubspot/linked_image\",\n      img={\n        \"alt\": \"Stock placeholder image with grayscale geometrical mountain landscape\",\n        \"loading\": \"lazy\",\n        \"max_height\": 451,\n        \"max_width\": 605,\n        \"size_type\": \"auto_custom_max\",\n        \"src\": get_asset_url(\"../images/grayscale-mountain.png\")\n      },\n      offset=4,\n      width=4\n    %}\n    {% end_dnd_module %}\n    {% dnd_module\n      path=\"@hubspot/rich_text\",\n      html=\"<h2>Provide more details here.</h2><p>Use text and images to tell your company’s story. Explain what makes your product or service extraordinary.</p>\"\n      offset=8,\n      width=4\n    %}\n    {% end_dnd_module %}\n  {% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["モジュールをドラッグ＆ドロップ領域に追加する場合、モジュールタグに固有名は", (0, _jsxRuntime.jsx)("u", {
          children: "不要"
        }), "です。ただし、モジュールをドラッグ＆ドロップ領域外のHTMLファイルに追加する場合は、モジュールに固有名を割り当てる必要があります。また、次のように少々異なる構文を使用します。"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% module \"testimonial_one\" path=\"../modules/Testimonial.module\" %}"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "テンプレートでモジュールを使用する方法"
        }), "について詳細をご確認ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotで変更をプレビューする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["保存された作業を自動的に追跡する", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), "コマンドを実行中の状態にしていない場合は", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <dest>"
        }), "を実行します。以降の一連のステップを進めるにあたって、必ずこのコマンドを実行してください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで、デザインマネージャーを開きます（［マーケティング］>［ファイルとテンプレート］>［デザインツール］）。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["デザインマネージャーの左のサイドバーで、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "home.html"
        }), "ファイルを選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［プレビュー］をクリックし、［表示オプションを使用したライブプレビュー］を選択して、新しいウィンドウでテンプレートのプレビューを開きます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Live-Preview-10.png",
        alt: "ライブプレビューの例"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "新しいタブでテンプレートプレビューを表示すると、新しく追加したTestimonialモジュールが含まれているはずです。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-added-to-theme.png",
        alt: "testimonial-module-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレート内のモジュールをカスタマイズする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ホームページテンプレートに工夫を加えるには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コードエディターに戻り、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "home.html"
        }), "ファイルを開きます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Testimonialモジュールタグに、以下のパラメーターを追加します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n testimonial = \"Wow, what a product! I can't wait to recommend this to all my family and friends!\",\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記のパラメーターは、3つのフィールドに当初割り当てた既定値よりも優先されます。各パラメーターでは、先ほど各フィールドに割り当てたHubL変数名が使用されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "customer_name"
        }), "**：**このパラメーターは、名前", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Mary"
        }), "を顧客名フィールドに渡し、元の値", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sally"
        }), "を上書きします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "profile_pic"
        }), "**：**このパラメーターは、2つのプロパティーを含むオブジェクトです。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "src"
            }), "プロパティーでは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "get_asset_url"
            }), " HubL関数を使用して、新しいプロフィール写真のURLを取得します。画像のファイルパスは、作業ディレクトリーを基準とした相対パスであることにご注意ください。まず、この画像を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "フォルダー内のローカル テーマ ファイルに追加する必要があります。この画像は、完成した", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
              children: "プロジェクトファイル"
            }), "を格納する", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "フォルダー内にあります。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), "プロパティーでは、画像の代替テキストを割り当てます。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), "**：**このパラメーターは、評価コメントのフィールドに新しいテキストを渡します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "あるいは、HubLブロック構文をリッチ テキスト フィールドで使用して、HTMLまたはテキストの大きなブロックを次のように記述することもできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n offset=0,\n width=4\n%}\n {% module_attribute \"testimonial\" %}\n   Wow, what a product! I can't wait to recommend this to all my family and friends!\n {% end_module_attribute %}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "モジュールブロック構文"
      }), "の詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ターミナルで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "コマンドが実行中であれば、保存した変更がHubSpotに送信されます。その後デザインマネージャーに戻り、テンプレートをプレビューできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Mary-Module-in-Template-12.png",
        alt: "テンプレート内のMaryモジュール"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートにさらに2つのTestimonialモジュールを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このステップでは、前と同じ手順に従って、さらに2つのTestimonialモジュールをテンプレートに追加します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["コードエディターを開き、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "ファイルを開きます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "前に追加したTestimonialモジュールをコピーして、その下に貼り付けることにより、このモジュールの別のインスタンスを追加します。新しいTestimonialモジュール内で、前と同じ手順に従って以下の詳細を指定します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["顧客の名前は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie"
            }), "です。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ollieのコメントは次のようにします。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "I can't believe that I ever conducted business without the use of this product!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ollieの写真として、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "フォルダー内のファイルの相対ファイルパスを追加します。この画像そのものは、完成した", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
              children: "プロジェクトファイル"
            }), "から取得できます。Ollieの画像に、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie Profile Picture"
            }), "（Ollieのプロフィール写真）と設定した", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), "属性を指定します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["間隔を均等に維持するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), "を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "に、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), "を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "に設定します。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "2つ目のTestimonialモジュールの下に、3つ目のTestimonialモジュールを追加して、以下の詳細を指定します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["顧客の名前は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin"
            }), "です。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Erinのコメントは次のようにします。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "My business has nearly tripled since I began to use this product! Don't wait, start now!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Erinの写真として、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "フォルダー内のファイルの相対ファイルパスを追加します。Erinの画像に、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin Profile Picture"
            }), "（Erinのプロフィール写真）と設定した", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), "属性を指定します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["間隔を均等に維持するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), "を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "8"
            }), "に、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), "を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "に設定します。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "変更内容を保存します。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ターミナルで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "コマンドを実行中の状態にしている場合、デザインマネージャーに戻ると、テンプレートの変更内容をプレビューできます。現時点で、テンプレートのプレビューに3つ全てのTestimonialモジュールが含まれています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-modules-added-to-theme.png",
        alt: "testimonial-modules-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["最後のステップとして、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "ファイルを変更して、テーマを公開ページで使用できるようにします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマ名を変更する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマの既定の名前を変更するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "ファイルで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), "フィールドを設定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example theme.json\n\n{\n  \"name\": \"my_first_theme\",\n  \"label\": \"My first theme\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記のコードを追加した後、変更を保存すると、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "コマンドによってHubSpotに変更が送信されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "次のステップ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このチュートリアルでは、カスタムモジュールを作成して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "home.html"
      }), "テンプレートに追加し、オプションでテーマの名前をカスタマイズしました。これで、このテンプレートからページを作成して公開できるようになりました。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマに焦点を当てた同様のチュートリアルにご興味がある場合、次のステップとして、こちらの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/getting-started",
        children: "テーマ入門チュートリアル"
      }), "（英語）をご覧ください。あるいは、HubSpotのナレッジベースで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
        children: "ページの作成とカスタマイズ"
      }), "について詳細を確認することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールの詳細については、次のモジュールガイドでご確認ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "モジュールの設定"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "テンプレートでモジュールを使用する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "既定のモジュール"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "その他のチュートリアル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "テーマを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "サーバーレス関数を使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "効率的な開発ワークフローの構築"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "アクセシビリティーについて"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/js-frameworks",
          children: "CMS HubでのJavaScriptフレームワークの使用方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "カスタムモジュールでウェブコンポーネントを使用する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-build-a-code-block-web-component",
          children: "モジュールをベースにコード ブロック ウェブ コンポーネントを作成する方法"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}