"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45279037457;
const slug = exports.slug = 'guides/api/crm/commerce/quotes';
const title = exports.title = 'CRM-API | Angebote';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Quotes';
const metaDescription = exports.metaDescription = 'Angebote werden verwendet, um Preisinformationen mit potenziellen Kunden zu teilen. Die Angebote-Endpunkte ermöglichen es Ihnen, diese Daten abzurufen und zwischen HubSpot und anderen Systemen zu synchronisieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C3%BCberblick",
  "label": "Überblick",
  "parent": null
}, {
  "depth": 0,
  "id": "ein-angebot-erstellen",
  "label": "Ein Angebot erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "elektronische-unterschriften-aktivieren",
  "label": "Elektronische Unterschriften aktivieren",
  "parent": "ein-angebot-erstellen"
}, {
  "depth": 1,
  "id": "zahlungen-aktivieren",
  "label": "Zahlungen aktivieren",
  "parent": "ein-angebot-erstellen"
}, {
  "depth": 0,
  "id": "hinzuf%C3%BCgen-von-zuordnungen",
  "label": "Hinzufügen von Zuordnungen",
  "parent": null
}, {
  "depth": 1,
  "id": "abrufen-von-ids-f%C3%BCr-zuordnungen",
  "label": "Abrufen von IDs für Zuordnungen",
  "parent": "hinzuf%C3%BCgen-von-zuordnungen"
}, {
  "depth": 1,
  "id": "erstellen-von-zuordnungen",
  "label": "Erstellen von Zuordnungen",
  "parent": "hinzuf%C3%BCgen-von-zuordnungen"
}, {
  "depth": 1,
  "id": "angebotsunterzeichner-verkn%C3%BCpfen",
  "label": "Angebotsunterzeichner verknüpfen",
  "parent": "hinzuf%C3%BCgen-von-zuordnungen"
}, {
  "depth": 0,
  "id": "ein-angebot-mit-zuordnungen-erstellen-einzelanfrage",
  "label": "Ein Angebot mit Zuordnungen erstellen (Einzelanfrage)",
  "parent": null
}, {
  "depth": 0,
  "id": "angebotsstatus-aktualisieren",
  "label": "Angebotsstatus aktualisieren",
  "parent": null
}, {
  "depth": 0,
  "id": "angebote-abrufen",
  "label": "Angebote abrufen",
  "parent": null
}, {
  "depth": 1,
  "id": "nach-angebotsstatus-festgelegte-eigenschaften",
  "label": "Nach Angebotsstatus festgelegte Eigenschaften",
  "parent": "angebote-abrufen"
}, {
  "depth": 0,
  "id": "bereiche",
  "label": "Bereiche",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h2: "h2",
      ol: "ol",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Table,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Table) _missingMdxReference("Table", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Angebote"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die Angebote-API, um Vertriebsangebote zu erstellen, zu verwalten und abzurufen, um Preisinformationen mit potenziellen Käufern zu teilen. Nach der Konfiguration kann ein Angebot entweder unter einer bestimmten URL oder über PDF mit einem Käufer geteilt werden. Benutzer können auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-quotes#manage-quotes",
        children: "Angebote in HubSpot verwalten"
      }), ", um Details hinzuzufügen, Zuordnungen zu aktualisieren und vieles mehr."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie entweder das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/set-up-payments",
        children: "Payments-Tool von HubSpot"
      }), " (HubSpot-Zahlungen) oder die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "Stripe-Zahlungsabwicklung"
      }), " eingerichtet haben, können Sie ein Angebot so konfigurieren, dass es über diese API zahlbar ist. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes#enable-payments",
        children: "zahlbare Angebote"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/example-quote-api.png",
        alt: "example-quote-api"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Anwendungsbeispiel:"
      }), " Sie müssen einen Vertragsvorschlag für einen Kunden erstellen, der am Kauf eines Ihrer Servicepakete für jährliches SEO-Auditing interessiert ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie, wie Sie ein Angebot über die API erstellen und die verschiedenen Eigenschaften, Zuordnungen, Status und mehr konfigurieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Überblick"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Angebotserstellungsprozess kann in die folgenden Schritte unterteilt werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ein Angebot erstellen:"
        }), " Erstellen Sie ein Angebot mit einigen Details wie Name und Ablaufdatum. Sie können das Angebot auch so konfigurieren, dass ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-e-signatures",
          children: "elektronische Unterschriften"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "Zahlungen"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes"
        }), " aktiviert werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zuordnungen einrichten"
        }), ": Ordnen Sie das Angebot anderen Arten von CRM-Objekten zu, z. B. Artikeln, einer Angebotsvorlage, einem Deal und mehr. Im nächsten Schritt erbt das Angebot Eigenschaftswerte von einigen dieser zugeordneten Datensätze sowie die Einstellungen des Accounts."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Den Angebotsstatus festlegen:"
        }), " Legen Sie den Status des Angebots fest, um seine Bereitschaft zur Weitergabe an Käufer widerzuspiegeln. Wenn Sie den Status des Angebots festlegen, z. B. indem Sie es zu einem bearbeitbaren Entwurf oder einem vollständig veröffentlichten und öffentlich zugänglichen Angebot machen, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#properties-set-by-quote-state",
          children: "erbt es bestimmte Eigenschaften"
        }), " von seinen zugeordneten CRM-Datensätzen und Account-Einstellungen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Das Angebot teilen:"
        }), " Sobald ein Angebot veröffentlicht wurde, können Sie es mit Ihren Käufern teilen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Angebot erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Angebot zu erstellen, konfigurieren Sie zunächst die grundlegenden Details, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes"
      }), " durchführen. Später führen Sie einen separaten Aufruf durch, um das Angebot anderen Objekten zuzuordnen, z. B. der Angebotsvorlage, Artikeln oder einem Deal."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Abhängig von Ihrem bevorzugten Workflow können Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-quote-with-associations-single-request-",
          children: "ein Angebot mit Zuordnungen über eine POST-Anfrage erstellen"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie im Text des Beitrags die folgenden erforderlichen Eigenschaften hinzu, um dessen grundlegende Details zu konfigurieren."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The above are just the minimum required properties to get a quote started but ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes#adding-associations",
              children: "other properties"
            }), " are required to publish a quote. To see all available quote properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm/v3/properties/quotes"
            }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/properties",
              children: "properties API"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Antwort enthält eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", mit der Sie das Angebot weiter konfigurieren können. Sie können Angebotseigenschaften jederzeit aktualisieren, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes/{quoteId}"
      }), " durchführen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Elektronische Unterschriften aktivieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um elektronische Unterschriften für das Angebot zu aktivieren, fügen Sie die boolesche Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_esign_enabled"
      }), " in Ihren Anfragetext mit dem Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " ein. Beachten Sie, dass Sie keine Gegenzeichner über die API hinzufügen können. Daher müssen diese vor der Veröffentlichung des Angebots in HubSpot hinzugefügt werden. Sie können auch kein Angebot mit aktivierter elektronischer Unterschrift veröffentlichen, wenn Sie Ihr ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
        children: "monatliches Limit für elektronische Unterschriften überschritten haben"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_esign_enabled\": \"true\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Später müssen Sie das Angebot den Unterzeichnern des Angebots zuordnen. Während die Kontakte, die das Angebot unterzeichnen, als Kontakte in HubSpot vorhanden sind, werden sie als separater Zuordnungstyp von Kontakten gespeichert. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associating-quote-signers",
        children: "Zuordnen von Angeboten zu Angebotsunterzeichnern"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zahlungen aktivieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Zahlungen für ein Angebot zu aktivieren, fügen Sie die boolesche Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_enabled"
      }), " mit dem Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " in Ihren Anfragetext ein. Abhängig von Ihrem Zahlungsabwickler und den akzeptierten Zahlungsmethoden müssen Sie außerdem auch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_type `und` hs_allowed_payment_methods"
      }), " festlegen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Im HubSpot-Account muss entweder das", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/set-up-payments",
          children: "Payments-Tool von HubSpot"
        }), " oder die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
          children: "Stripe-Zahlungsabwicklung"
        }), " eingerichtet sein, bevor Sie diese Funktion nutzen können."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn diese Einstellung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " gesetzt ist, kann das Angebot Zahlungen entweder über das Payments-Tool von HubSpot oder die Stripe-Zahlungsabwicklung einziehen. Standardmäßig ist dies auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " festgelegt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt fest, welcher Zahlungsabwickler verwendet werden soll. Der Wert kann entweder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BYO_STRIPE"
            }), " sein."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_allowed_payment_methods"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die zu verwendenden Zahlungsmethoden (z. B. Kreditkarte)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_billing_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn diese Option auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " festgelegt ist, kann der Käufer seine Rechnungsadresse während des Checkouts eingeben."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_shipping_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn diese Option auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " gesetzt ist, kann der Käufer seine Versandadresse während des Checkouts eingeben."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise ein Angebot zu erstellen und HubSpot-Zahlungen per Kredit-/Debitkarte oder ACH zu aktivieren, sieht Ihre Anfrage wie folgt aus:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_payment_enabled\": \"true\",\n    \"hs_payment_type\": \"HUBSPOT\",\n    \"hs_allowed_payment_methods\": \"CREDIT_OR_DEBIT_CARD;ACH\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Zahlung nachzuverfolgen, aktualisiert HubSpot automatisch die Eigenschaften ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_status"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_date"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie ein Angebot mit aktivierten Zahlungen veröffentlichen, setzt HubSpot die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " automatisch auf PENDING."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie ACH verwenden, legt HubSpot beim Abwickeln der Zahlung automatisch die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " auf PROCESSING fest."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn die Zahlung bestätigt wird, legt HubSpot die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " automatisch auf PAID fest."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sobald das Angebot bezahlt ist, legt HubSpot die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_date"
        }), " automatisch auf den Zeitpunkt ( Datum und Uhrzeit) fest, zu dem die Zahlung bestätigt wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sobald die Zahlung bestätigt ist, wird die Zahlung automatisch dem Angebot zugeordnet. Weitere Informationen zu der Zahlung finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "Zahlungen-API"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hinzufügen von Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein vollständiges Angebot zu erstellen, müssen Sie es mithilfe der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), " anderen CRM-Datensätzen, z. B. Artikeln, zuordnen. Die folgende Tabelle zeigt, welche CRM-Datensatzzuordnungen für ein vollständiges Angebot erforderlich sind und welche optional sind. Lesen Sie weiter, um mehr über das Abrufen von IDs und deren Verwendung zum Erstellen der erforderlichen Zuordnungen zu erfahren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Objekttyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Erforderlich"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "Artikel"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ja"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Waren und/oder Dienstleistungen, die über das Angebot verkauft werden. Sie können Artikel über ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "Produkte in Ihrer Produktbibliothek"
            }), " erstellen oder benutzerdefinierte eigenständige Artikel erstellen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
              children: "Angebotsvorlage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ja"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Vorlage, die das Angebot rendert, sowie einige Standardkonfigurationseinstellungen für das Angebot, z. B. die Sprache. Jedes Angebot kann einer Vorlage zugeordnet werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Deal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ja"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Deal-Datensatz zur Nachverfolgung von Umsatz und Vertriebs-Lifecycle. Ein Angebot übernimmt Werte vom zugeordneten Deal, einschließlich des zuständigen Mitarbeiters und der Währung. Jedes Angebot kann einem Deal zugeordnet werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Kontakt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nein"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bestimmte Käufer, die Sie im Angebot ansprechen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Unternehmen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nein"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein bestimmtes Unternehmen, das Sie im Angebot ansprechen. Jedes Angebot kann einem Unternehmen zugeordnet werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/discounts",
              children: "Rabatte"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/fees",
              children: "Gebühren"
            }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/taxes",
              children: "Steuern"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nein"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alle Rabatte, Gebühren und Steuern, die beim Checkout anfallen. Bei der Ermittlung des Gesamtangebotsbetrags wendet HubSpot zunächst Rabatte, dann Gebühren und dann Steuern an. Mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sort_order"
            }), "\\-Felds können Sie Objekte des gleichen Typs neu anordnen. Kann auf feste Werte oder Prozentsätze gesetzt werden, indem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_type"
            }), " entweder auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FIXED"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PERCENT"
            }), " festgelegt wird."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abrufen von IDs für Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die einzelnen Zuordnungen vorzunehmen, müssen Sie zunächst die ID der einzelnen Objekte abrufen, die Sie zuordnen möchten. Um die einzelnen IDs abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an den entsprechenden Objekt-Endpunkt, die dem gleichen Muster für die einzelnen CRM-Objekte folgt. Bei jeder Anfrage können Sie auch einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "-Abfrageparameter hinzufügen, um bei Bedarf bestimmte Eigenschaften zurückzugeben. Nachfolgend finden Sie Beispiel-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfragen für die einzelnen Objekttypen."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "GET request for line items\n/crm/v3/objects/line_items?properties=name\n\nGET request for quote templates\n/crm/v3/objects/quote_template?properties=hs_name\n\nGET request for deals\n/crm/v3/objects/deals?properties=dealname\n\nGET request for contacts\n/crm/v3/objects/contacts?properties=email\n\nGET request for companies\n/crm/v3/objects/companies?properties=name\n\nGET request for discounts\ncrm/v3/objects/discounts?properties=hs_type,hs_value\n\nGET request for fees\ncrm/v3/objects/fees?properties=hs_type,hs_value\n\nGET request for taxes\ncrm/v3/objects/taxes?properties=hs_type,hs_value\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#GET request for line items\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/line_items?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for quote templates\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/quote_templates?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for deals\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/deals?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for contacts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/contacts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for companies\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/companies?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for discounts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/discounts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for fees\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/fees?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for taxes\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/taxes?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jeder erfolgreiche Aufruf gibt eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "-Antwort mit Details für jeden abgerufenen Objekttyp zurück. Sie verwenden den Wert im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Feld, um im nächsten Schritt Zuordnungen festzulegen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example quote template GET response\n{\n  \"results\": [\n    {\n      \"id\": \"235425923863\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Basic\",\n        \"hs_object_id\": \"235425923863\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923864\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Modern\",\n        \"hs_object_id\": \"235425923864\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923865\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Original\",\n        \"hs_object_id\": \"235425923865\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Erstellen von Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Ihre IDs abgerufen wurden, können Sie jetzt die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), " aufrufen, um Zuordnungen zu erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für jeden Objekttyp, den Sie einem Angebot zuordnen möchten, müssen Sie einen separaten Aufruf durchführen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage mithilfe der folgende URL-Struktur durchführen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quotes/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Angebots."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ des Objekts, zu dem Sie eine Zuordnung erstellen. Zum Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_items"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deals"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote_template"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Objekts, dem Sie das Angebot zuordnen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie Beispiel-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfragen für die einzelnen Objekttypen."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "PUT request to associate a line item\n/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId}\n\nPUT request to associate a quote template\n/crm/v4/objects/quotes/{quoteId}/associations/default/quote_template/{quoteTemplateId}\n\nPUT request to associate a deal\n/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId}\n\nPUT request to associate contacts\n/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId}\n\nPUT request to associate companies\n/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId}\n\nPUT request to associate discounts\n/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId}\n\nPUT request to associate fees\n/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId}\n\nPUT request to associate taxes\n/crm/v4/objects/quotes/{quoteId}/associations/default/taxes/{taxId}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#PUT request to associate line items\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a quote template\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quote_ID}/associations/default/quote_template/{quoteTemplateId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a deal\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate contacts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a company\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate discounts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate fees\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate taxes\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{taxId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, if your quote has an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), ", the requests to associate the quote might include the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Line items (IDs: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "55555"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "66666"
          }), "):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/55555"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/66666"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quote template (ID:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "987654"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: "):"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/quote_template/987654"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "345345"
          }), "):"]
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/deals/345345"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jede erfolgreiche Zuordnung gibt eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "-Antwort mit Details über die Zuordnung zurück. Die obigen Aufrufe ordnen die Objekte in beide Richtungen zu, wobei jede Richtung ihre eigene ID hat. Wenn Sie beispielsweise das Angebot einer Angebotsvorlage zuordnen, beschreibt die Antwort die Zuordnung von beiden Enden. In der nachstehenden Beispielantwort ist ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "286"
      }), " die ID des Zuordnungstyps Angebot-zu-Angebotsvorlage und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "285"
      }), " die ID des Zuordnungstyps Angebotsvorlage-zu-Angebot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"115045534742\"\n      },\n      \"to\": {\n        \"id\": \"102848290\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 285\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"102848290\"\n      },\n      \"to\": {\n        \"id\": \"115045534742\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 286\n      }\n    }\n  ],\n  \"startedAt\": \"2023-10-12T16:48:40.624Z\",\n  \"completedAt\": \"2023-10-12T16:48:40.712Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie ein Angebot einer Angebotsvorlage zuordnen, beachten Sie die folgenden Einschränkungen:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Angebotsvorlagen müssen ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
            children: "erstellt werden"
          }), ", bevor sie mit einem Angebot verknüpft werden können."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Ein Angebot kann nur einer Angebotsvorlage zugeordnet werden."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Diese API unterstützt keine Legacy- oder Vorschlagsangebote. Es kann nur der Vorlagentyp ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
          }), " verwendet werden."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Angebotsunterzeichner verknüpfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#enable-e-signatures",
        children: "Angebot für elektronische Unterschriften aktivieren"
      }), ", müssen Sie auch eine Zuordnung zwischen dem Angebot und den Kontakten erstellen, die unterzeichnen, indem Sie ein bestimmtes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#associate-records-with-a-label",
        children: "Zuordnungslabel (von Angebot zu Kontakt) verwenden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anstatt die oben aufgeführten Standardzuordnungsendpunkte zu verwenden, müssen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an die folgende URL senden:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quote/{quoteId}/associations/contact/{contactId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Anfragetext müssen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " angeben, wie unten gezeigt:"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Angebot mit Zuordnungen erstellen (Einzelanfrage)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der folgende Anfragetext erstellt ein neues Angebot mit Zuordnungen zu einer Angebotsvorlage, einem Angebot, zwei Artikeln und einem Kontakt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%",
              "height": "590.032px"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "properties"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "object"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Quote details, which can be retrieved through the ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/properties",
                      children: "properties API"
                    }), ". Required properties are: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), "."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_status"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#update-quote-status",
                      children: "quote status"
                    }), ". Omitting this property on create will prevent users from being able to edit the quote in HubSpot."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "associations"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The quote's associated records. For a quote to be publishable, it must have an associated deal and quote template. The line items should be created separately from the line items on the associated deal."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "To set each association, include a separate object in the associations array with the following fields:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "to: the ID of the record to associate with the quote."
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationCategory: the ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "/guides/api/crm/associations#retrieve-association-types",
                        children: "type of association"
                      }), ". Can be HUBSPOT_DEFINED or USER_DEFINED."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationTypeId: the ID of the type of association being made:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
                        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "286: quote to quote template"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "64: quote to deal"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "67: quote to line item"
                        }), "\n"]
                      }), "\n"]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/associations#association-type-id-values",
                      children: "association type IDs"
                    }), "."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// POST request to https://api.hubapi.com/crm/v3/objects/quote\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-09-30\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 115045534742\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 286\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 14795354663\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 64\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 75895447\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 256143985\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Diese Artikel sollten sich von Artikeln in anderen Objekten unterscheiden, auch wenn sie verknüpft sind (z. B. Zuordnen eines Angebots zu einem Deal). Weitere Informationen finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "Dokumentation zur Artikel-API"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Angebotsstatus aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Status eines Angebots beschreibt, wie weit es im Erstellungsprozess ist, von der ersten Einrichtung bis zur Veröffentlichung und öffentlichen Zugänglichmachung. Der Angebotsstatus kann auch den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/approve-quotes",
        children: "Angebotsgenehmigungsprozess"
      }), " widerspiegeln, wenn Angebotsgenehmigungen für den Account aktiviert sind. Wenn Sie den Status eines Angebots festlegen, füllt HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#properties-set-by-quote-state",
        children: "automatisch bestimmte Eigenschaften aus"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können den Status eines Angebots aktualisieren, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes/{quoteId}"
      }), " durchführen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Status eines Angebots basiert auf dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_status"
      }), "-Feld. Bestimmte Angebotsstatus ermöglichen es Benutzern, das Angebot in Angebotsgenehmigungsworkflows zu bearbeiten, zu veröffentlichen und zu verwenden. Nachfolgend finden Sie die verfügbaren Angebotsstatus."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kein Status:"
        }), " Wenn für das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), "-Feld kein Wert angegeben wird, hat das Angebot den Status ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Minimal"
        }), ". Das Angebot wird auf der Indexseite des Angebote-Tools angezeigt, kann aber nicht direkt bearbeitet werden. Angebote mit diesem Status können weiterhin in der Automatisierung verwendet werden, z. B. im Sequenzen-Tool, und können auch im Reporting-Tool analysiert werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), ": ermöglicht die Bearbeitung des Angebots in HubSpot. Dieser Status kann nützlich sein, wenn das Angebot nicht vollständig konfiguriert ist oder wenn Sie es den Vertriebsmitarbeitern lieber ermöglichen möchten, den Angebotskonfigurationsprozess in HubSpot abzuschließen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVAL_NOT_NEEDED"
        }), ": veröffentlicht das Angebot unter einer öffentlich zugänglichen URL (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), "), ohne dass es ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "genehmigt"
        }), " werden muss."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), ": gibt an, dass das Angebot ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "auf die Genehmigung wartet"
        }), ", bevor es veröffentlicht werden kann."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVED"
        }), ": Das Angebot wurde ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "genehmigt"
        }), " und wird nun unter einer öffentlich zugänglichen URL (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ") veröffentlicht."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), ": gibt an, dass das Angebot eingerichtet, aber nicht für die Veröffentlichung ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "genehmigt"
        }), " wurde und bearbeitet werden muss, bevor es erneut zur Genehmigung eingereicht werden kann."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enabling-e-signatures",
          children: "elektronische Unterschriften"
        }), " für das Angebot aktivieren, können Sie das Angebot nicht veröffentlichen, wenn Sie Ihr ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
          children: "monatliches Limit für elektronische Unterschriften überschritten haben"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beispielsweise würde die folgende Anfrage das Angebot unter einer öffentlich zugänglichen URL veröffentlichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/quote/{QUOTE_ID}\n{\n  \"properties\": {\n    \"hs_status\": \"APPROVAL_NOT_NEEDED\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " HubSpot setzt standardmäßig die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_template_type"
        }), "-Eigenschaft des Angebots auf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
        }), ", nachdem Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#update-quote-state",
          children: "Status des Angebots aktualisiert haben"
        }), ". Dieser Vorlagentyp wird von der v3-API unterstützt, während die folgenden Vorlagentypen alte Vorlagen sind, die nicht mehr unterstützt werden:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "QUOTE"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROPOSAL"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Angebote abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Angebote einzeln oder in Batches aktualisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ein einzelnes Angebote abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/quotes/{quoteID}"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine Liste aller Angebote anzufragen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/quotes"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können für diese Endpunkte die folgenden Abfrageparameter in die Anfrage-URL einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Angebot keinen Wert für eine Eigenschaft hat, wird es nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der aktuellen und historischen Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Angebot keinen Wert für eine Eigenschaft hat, wird es nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekten, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Batch bestimmter Angebote anhand ihrer IDs abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/quotes/batch/read"
      }), " durch und fügen Sie die IDs in den Anfragetext ein. Sie können auch ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "-Array einfügen, um anzugeben, welche Eigenschaften zurückgegeben werden sollen. Der Batch-Endpunkt kann Zuordnungen nicht abrufen. Erfahren Sie, wie Sie Zuordnungen mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), " stapelweise lesen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n \"inputs\": [\n  {\"id\": \"342007287\"},\n  {\"id\":\"81204505203\"}\n ],\n \"properties\": [\n  \"hs_content\", \"hs_sentiment\",\n  \"hs_submission_timestamp\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nach Angebotsstatus festgelegte Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Durch die Aktualisierung des Angebotsstatus werden die folgenden Eigenschaften aktualisiert:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_amount"
        }), ": wird auf der Grundlage aller zugeordneten Artikel, Steuern, Rabatte und Gebühren berechnet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_domain"
        }), ": wird von der zugeordneten Angebotsvorlage festgelegt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_slug"
        }), ": wird zufällig generiert, wenn keiner explizit angegeben wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_total_preference"
        }), ": wird basierend auf Ihren Account-Einstellungen festgelegt. Wenn Sie diese Einstellung nicht konfiguriert haben, wird sie standardmäßig auf den Wert des total_first_payment-Felds festgelegt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_timezone"
        }), ": wird standardmäßig auf die Zeitzone Ihres HubSpot-Accounts festgelegt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locale"
        }), ": wird von der zugeordneten Angebotsvorlage festgelegt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_number"
        }), ": wird basierend auf dem aktuellen Datum und der aktuellen Uhrzeit festgelegt, es sei denn, es wird eine angegeben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        }), ": wird von der zugeordneten Angebotsvorlage festgelegt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_currency"
        }), ": wird basierend auf dem zugeordneten Deal festgelegt. Wenn Sie dem Angebot keinen Deal zugeordnet haben, wird die Standardwährung Ihres HubSpot-Accounts verwendet."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Darüber hinaus werden die folgenden Eigenschaften berechnet, wenn das Angebot auf einen veröffentlichten Status festgelegt wird:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_pdf_download_link"
        }), ": wird mit einer URL von einer PDF-Datei für das Angebot ausgefüllt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locked"
        }), ": wird auf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " festgelegt. Zum Ändern von Eigenschaften, nachdem Sie ein Angebot veröffentlicht haben, müssen Sie zunächst den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), " des Angebots zurück auf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), " aktualisieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ": die öffentlich zugängliche URL des Angebots. Dies ist eine schreibgeschützte Eigenschaft und kann nach der Veröffentlichung nicht über die API festgelegt werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_esign_num_signers_required"
        }), ": Wenn Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-e-signatures",
          children: "elektronische Unterschriften"
        }), " aktiviert haben, wird die Anzahl der erforderlichen Unterschriften angezeigt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), ": der Status des Zahlungseinzugs, wenn Sie Zahlungen aktiviert haben. Nach der Veröffentlichung mit aktivierten Zahlungen wird diese Eigenschaft auf PENDING festgelegt. Sobald der Käufer die Zahlung über das Angebot übermittelt, wird der Status automatisch entsprechend aktualisiert. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "Aktivieren von Zahlungen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bereiche"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Damit ein gültiges veröffentlichbares Angebot erstellt werden kann, sind für eine App die folgenden Bereiche erforderlich:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.owners.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.read"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.quote.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.companies.read"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}