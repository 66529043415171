"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694137;
const slug = exports.slug = 'guides/cms/content/modules/quickstart';
const title = exports.title = 'Getting started with Modules';
const name = exports.name = 'Getting started with modules';
const metaDescription = exports.metaDescription = 'Create a component that is reusable across your pages and templates called a custom module. You will learn about fields, and using them with HubL.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Module%20Editor-1-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Getting started with Modules';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "before-you-get-started",
  "label": "Before you get started",
  "parent": null
}, {
  "depth": 0,
  "id": "set-up-your-local-development-environment",
  "label": "Set up your local development environment",
  "parent": null
}, {
  "depth": 0,
  "id": "add-the-cms-boilerplate-to-your-account",
  "label": "Add the CMS boilerplate to your account",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-module-in-hubspot",
  "label": "Create a module in HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "add-fields-to-the-module",
  "label": "Add fields to the module",
  "parent": "create-a-module-in-hubspot"
}, {
  "depth": 2,
  "id": "add-the-text-field-for-customer-name",
  "label": "Add the text field for customer name",
  "parent": "create-a-module-in-hubspot"
}, {
  "depth": 2,
  "id": "add-the-image-field-for-customer-profile-picture",
  "label": "Add the image field for customer profile picture",
  "parent": "create-a-module-in-hubspot"
}, {
  "depth": 2,
  "id": "add-the-rich-text-field-for-sally-s-testimony",
  "label": "Add the rich text field for Sally's testimony",
  "parent": "create-a-module-in-hubspot"
}, {
  "depth": 1,
  "id": "add-hubl-to-display-field-data",
  "label": "Add HubL to display field data",
  "parent": "create-a-module-in-hubspot"
}, {
  "depth": 0,
  "id": "view-and-modify-the-module-locally",
  "label": "View and modify the module locally",
  "parent": null
}, {
  "depth": 1,
  "id": "open-the-module-folder-in-your-local-environment",
  "label": "Open the module folder in your local environment",
  "parent": "view-and-modify-the-module-locally"
}, {
  "depth": 1,
  "id": "view-the-module%E2%80%99s-fields.json-file",
  "label": "View the module’s fields.json file",
  "parent": "view-and-modify-the-module-locally"
}, {
  "depth": 1,
  "id": "view-the-module-s-module.html-file",
  "label": "View the module's module.html file",
  "parent": "view-and-modify-the-module-locally"
}, {
  "depth": 1,
  "id": "view-the-module%E2%80%99s-module.css-file",
  "label": "View the module’s module.css file",
  "parent": "view-and-modify-the-module-locally"
}, {
  "depth": 0,
  "id": "push-local-changes-to-your-hubspot-account",
  "label": "Push local changes to your HubSpot account",
  "parent": null
}, {
  "depth": 0,
  "id": "preview-your-local-changes-in-hubspot",
  "label": "Preview your local changes in HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "add-the-module-to-a-template",
  "label": "Add the module to a template",
  "parent": null
}, {
  "depth": 0,
  "id": "preview-your-changes-in-hubspot",
  "label": "Preview your changes in HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "customize-the-module-in-the-template-locally",
  "label": "Customize the module in the template locally",
  "parent": null
}, {
  "depth": 0,
  "id": "add-two-more-testimonial-modules-to-the-template",
  "label": "Add two more testimonial modules to the template",
  "parent": null
}, {
  "depth": 0,
  "id": "modify-the-theme-name",
  "label": "Modify the theme name",
  "parent": null
}, {
  "depth": 0,
  "id": "next-steps",
  "label": "Next steps",
  "parent": null
}, {
  "depth": 0,
  "id": "other-tutorials",
  "label": "Other tutorials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      pre: "pre",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Getting started with modules"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this tutorial, you'll use the HubSpot CMS boilerplate theme to learn how to create a module and walk through how you can use it in templates and pages as part of a theme. By the end of the tutorial, you'll have created a customer testimonial module that includes a text field, an image field, and a rich text field."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "If this is your first experience with CMS Hub development we recommend:"
      }), (0, _jsxRuntime.jsx)(CTA, {
        id: "28bfd0e9-ec05-48a5-b069-ce20015f54ac",
        label: "Quick start to CMS Hub development"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you're looking to develop a module for use in a theme that you want to list on the HubSpot Asset Marketplace, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements",
          children: "Asset Marketplace module requirements"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Before you get started"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before getting started with this tutorial:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Set up a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-cms-developer-sandbox",
          children: "HubSpot CMS Developer Sandbox"
        }), ". You can use your existing account instead, but the sandbox will allow you to develop without impacting assets in your main account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), ", which enables HubSpot's local development tools. Versions 10 or higher are supported."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to jump ahead, you can preview the finished ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
        children: "project files"
      }), ". Because the CMS boilerplate theme's code may change over time, only the most critical files that developers will need to create or edit during the tutorial are included. These files include:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial.module:"
        }), " the folder containing the files that make up the custom module you'll build as part of the tutorial."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "homepage.html:"
        }), " the template that you'll be editing to include the custom module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "images:"
        }), " the folder containing the profile pictures you'll use in the module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up your local development environment"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["During this tutorial, you'll be developing the module locally. To interact with HubSpot in your command line interface, you'll need to install the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "HubSpot CLI"
      }), " and configure it to access your account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the terminal, run the following command to install the CLI globally. To install the CLI only in your current directory instead, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm install -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the directory where you'll be storing your theme files, authenticate your HubSpot account so that you can interact with it via the CLI."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs init\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), " to open the personal access key page in your browser."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "account"
        }), " that you want to deploy to, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continue with this account"
        }), ". You’ll then be redirected to the personal access key page of the account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Next to ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Personal Access Key"
        }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Show"
        }), " to reveal your key. Then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy"
        }), " to copy it to your clipboard."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Paste the copied key into the terminal, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a unique ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for the account. This name will only be seen and used by you when running commands. Then, press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ".To connect the local development tools to your account:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You'll then see a success message confirming that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " file was created."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add the CMS boilerplate to your account"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run the command below to create a new theme named ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), ". A theme folder named ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), " will then be created in your working directory containing the boilerplate assets."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create website-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Upload the files to your account."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload <src> <destination>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The path to your local files, relative to your current working directory."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The destination path in your HubSpot account. This can be a new folder."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme my-new-theme"
      }), " would upload the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-theme"
      }), " folder from your machine to a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-new-theme"
      }), " folder in HubSpot"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["By default, HubSpot will upload to the default account in your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config,yml"
        }), " file. However, you can also specify an account by including a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--account=<accountNameOrId>"
        }), " flag in the command. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload --portal=mainProd"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Learn more in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CLI command reference"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a module in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the CMS boilerplate in your local environment, you'll now create a new module for the theme."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["For the purposes of this tutorial, you'll create the module in HubSpot, then pull it down into the theme using the CLI. However, you can also create modules from scratch locally using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#create-new-files",
          children: "hs create module"
        }), " command."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Log in to your HubSpot account, then navigate to the design manager by navigating to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing > Files and Templates > Design Tools"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar of the design manager, open the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "theme folder"
          }), " that you just uploaded."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the theme folder, open the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "modules folder"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the upper left, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "File > New file"
          }), " to create a new module in this folder."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dropdown menu"
          }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Module"
          }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Next"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/new-module-dropdown-menu0.png",
            alt: "new-module-dropdown-menu0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Pages"
          }), " checkbox to make the module available for website and landing pages."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Name the module ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Testimonial"
          }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add fields to the module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Next, you'll add three fields to the module:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A text field to store the name of the customer giving the testimonial."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "An image field that will store the customer's profile picture."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A rich text field that will store the customer's testimonial."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Add the text field for customer name"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right sidebar of the module editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Add field"
          }), " dropdown menu, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Text"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-add-field.png",
            alt: "testimonial-module-add-field"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right sidebar, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "pencil icon"
          }), " in the upper right to name the field. For this tutorial, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Customer Name"
          }), ". You'll then see the HubL variable name change to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "customer_name"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Set the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Default text"
          }), " to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sally"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Text-Field-3.png",
        alt: "Text Field Example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right sidebar, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "breadcrumb"
        }), " icon to return to the main module menu."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-breadcrumb-icon0.png",
        alt: "module-breadcrumb-icon0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Add the image field for customer profile picture"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add another field using the same method, this time selecting the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Image"
        }), " field type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Label the image field ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Profile Picture"
        }), ", then set the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubL variable name"
        }), " to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "profile_pic"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Default image"
        }), ", select the profile picture provided for Sally in the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "images"
        }), " folder of the completed ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
          children: "project files."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Set the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Alt text"
        }), " to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sally Profile Picture"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/getting-started-with-modules-profile-pic.png",
        alt: "getting-started-with-modules-profile-pic"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Add the rich text field for Sally's testimony"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add another field using the same method, this time selecting the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rich text"
        }), " field type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Label the rich text field ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Default rich text"
        }), " box, then enter \"I've had nothing but wonderful experiences with this company.\""]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Rich-Text-Field-5--.png",
        alt: "Rich Text Field Example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["So far, you've added data into several module fields. At this point, though, the module doesn't contain any HTML to render that data. In the module editor, this is reflected by the empty state of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " section. ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-html-empty0.png",
        alt: "module-html-empty0"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, you'll add HubL to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " to display the field data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add HubL to display field data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To display data from the previously created fields, add the following HubL to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " pane:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{module.customer_name}}\n<img src={{module.profile_pic.src}} alt=\"{{module.profile_pic.alt}}\">\n{{module.testimonial}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The above HubL tokens use dot notation to access data from each field. In this case, because we want to pull data from module fields, each token begins with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", followed by the field's HubL variable name. You can use dot notation to further access specific properties of a field, which you can see in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "profile_pic"
      }), " tokens on line 3 above."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With the HubL added to your module, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview"
        }), " in the upper right to see what the module looks like so far."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-previewer.png",
        alt: "design-manager-previewer"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Then, in the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish changes"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Having created and published the module in HubSpot, you'll now use the CLI to pull the module down into your local environment so that you can view its contents and make further changes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View and modify the module locally"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view the module locally, you'll first need to pull it down to your local theme:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the terminal, run the following command: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs fetch <hs_src> <destination>"
          }), ":"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<hs_src>"
              }), " represents the module's filepath in HubSpot. To get the filepath, you can right-click the module in the left sidebar of the design manager, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Copy path"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-copy-path0.png",
                alt: "design-manager-copy-path0"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<destination>"
              }), " represents the path to the local directory where your theme lives. If omitted, the command will default to the current working directory."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you're in the working directory already, your fetch command may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch my-theme/modules/testimonial.module\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Open the module folder in your local environment"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your preferred code editor, navigate to the module folder you’ve just pulled down from your HubSpot account. Within your module folder, you will see five different files:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A JSON object that contains your module’s fields."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A JSON object that contains meta information about your module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.css"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The CSS file that styles your module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The HTML and HubL for your module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.js"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The JavaScript for your module."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can find more detailed information in our documentation about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "module file structure"
      }), ", especially concerning the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " files. In this tutorial, we’ll focus on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " files and how they are generated by, downloaded from, and uploaded to the module editor in the Design Manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "View the module’s fields.json file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Open the module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file. Aside from some of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " numbers, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " attribute of the image field, and potentially the order of fields, the file should contain a JSON object similar to the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//fields.json\n\n[\n  {\n    \"label\": \"Customer Name\",\n    \"name\": \"customer_name\",\n    \"id\": \"2a025cd5-7357-007f-ae2f-25ace762588e\",\n    \"type\": \"text\",\n    \"required\": true,\n    \"locked\": false,\n    \"validation_regex\": \"\",\n    \"allow_new_line\": false,\n    \"show_emoji_picker\": false,\n    \"default\": \"Sally\"\n  },\n  {\n    \"label\": \"Profile Picture\",\n    \"name\": \"profile_pic\",\n    \"id\": \"7877fb84-eb8a-d2c7-d939-77e6e9557d8f\",\n    \"type\": \"image\",\n    \"required\": false,\n    \"locked\": false,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"default\": {\n      \"src\": \"https://cdn2.hubspotqa.net/hubfs/101140939/profile-pic-sally.svg\",\n      \"alt\": \"Sally Profile Picture\",\n      \"width\": 100,\n      \"height\": 100,\n      \"max_width\": 100,\n      \"max_height\": 100\n    }\n  },\n  {\n    \"label\": \"Testimonial\",\n    \"name\": \"testimonial\",\n    \"id\": \"c5524ece-1ab5-f92d-a5de-e2bf53199dfa\",\n    \"type\": \"richtext\",\n    \"required\": false,\n    \"locked\": false,\n    \"default\": \"<p>I’ve had nothing but wonderful experiences with this company.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The values for the following fields will match the values you added in step 3:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": the name of the field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": the field's label."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "default"
        }), ": the field's default value."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "View the module's module.html file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " file should contain the HubL and HTML that you wrote in the HubL + HTML module editor previously."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To make this code more interesting and ready for CSS styling, copy and paste the following code into the file:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"testimonial\">\n  <h1 class=\"testimonial__header\"> {{ module.customer_name }} </h1>\n  <img class=\"testimonial__picture\" src={{ module.profile_pic.src }} alt={{ module.profile_pic.alt }}>\n  {{ module.testimonial }}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Writing your HTML as above uses the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/bem-101/",
          children: "BEM class structure"
        }), " in accordance with the HubSpot CMS boilerplate theme's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/STYLEGUIDE.md#css-code-formatting",
          children: "style guide"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "View the module’s module.css file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " file should be empty at this point. To add styling, copy and paste the following code into the file:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial {\n  text-align: center;\n}\n\n.testimonial__header {\n  font-weight: bold;\n}\n\n.testimonial__picture {\n  display: block;\n  margin: auto;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After adding the code, save the file."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Push local changes to your HubSpot account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After saving your local changes, push them back to your HubSpot account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navigate to your terminal and ensure that you're in the correct directory."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run the watch command to push changes to HubSpot on save: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <destination>"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch my-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preview your local changes in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design Tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, navigate to the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme"
        }), " you've created, then open the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "module"
        }), " folder and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial"
        }), " module."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With the module open, you should now see your changes in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.css"
        }), " panes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview"
        }), ". A new tab will open to display the module preview."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Module-Preview-7.png",
        alt: "Module Preview Example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To recap this tutorial so far, you have:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "created a module in HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "pulled that module down to your local environment."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "made changes to the HubL + HTML and CSS using your local development tools."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the next part of this tutorial, learn how to use the module in a template."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add the module to a template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For this part of the tutorial, you'll be working mostly within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modules"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), " folders within your local theme files. ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/theme-folder-structure-getting-started-with-modules0.png",
        alt: "theme-folder-structure-getting-started-with-modules0"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By their most basic definition, modules are editable areas of HubSpot templates and pages. You can insert modules into templates in HubSpot by using the design manager, but here you'll be using HubL to add the module to the template in your local environment."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your code editor, open the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "templates"
          }), " folder, then open the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), " file."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), " file, navigate to the final ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), ", which starts around line 28. You'll be adding your new module to this section."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/home-html-add-testimonial-module-to-section0.png",
            alt: "home-html-add-testimonial-module-to-section0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Within this ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), " and above the other ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_modules"
          }), ", copy and paste the following HubL module tag:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path= “../modules/Testimonial.module”,\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This HubL tag references your new module by its relative file path. To get the module to fit evenly with the other two modules in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), ", it also assigns a module ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot's CMS uses a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview#columns",
          children: "12-column grid system"
        }), ", so to space this module evenly with the other two, you'll need to update each module in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " to have a width of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Then, the first ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " in the group (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), ") will have an offset of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " to position it first."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The second ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linked_image"
        }), ") will have an offset of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), " to position it second."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The third ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "rich_text"
        }), ") will have an offset of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "8"
        }), " to position it third."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After setting the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " of each ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", your code will look similar to the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n    background_color=\"#f8fafc\",\n    vertical_alignment=\"MIDDLE\"\n  %}\n\n    {% dnd_module\n      path= “../modules/Testimonial.module”,\n      offset=0,\n      width=4\n    %}\n    {% end_dnd_module %}\n\n    {% dnd_module\n      path=\"@hubspot/linked_image\",\n      img={\n        \"alt\": \"Stock placeholder image with grayscale geometrical mountain landscape\",\n        \"loading\": \"lazy\",\n        \"max_height\": 451,\n        \"max_width\": 605,\n        \"size_type\": \"auto_custom_max\",\n        \"src\": get_asset_url(\"../images/grayscale-mountain.png\")\n      },\n      offset=4,\n      width=4\n    %}\n    {% end_dnd_module %}\n    {% dnd_module\n      path=\"@hubspot/rich_text\",\n      html=\"<h2>Provide more details here.</h2><p>Use text and images to tell your company’s story. Explain what makes your product or service extraordinary.</p>\"\n      offset=8,\n      width=4\n    %}\n    {% end_dnd_module %}\n  {% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When adding a module to a Drag and Drop area, the module tag does ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " require a unique name. However, when adding a module to a HTML file outside of Drag and Drop areas, you must assign the module a unique name. You would also use slightly different syntax, such as:"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% module \"testimonial_one\" path=\"../modules/Testimonial.module\" %}"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "using modules in templates"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preview your changes in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you haven’t kept the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " command running to track your saved work automatically, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <dest>"
        }), ". Ensure that this command keeps running as you complete the next steps."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, open the design manager (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design Tools"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar of the design manager, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "home.html"
        }), " file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Live preview with display options"
        }), " to open the template preview in a new window."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Live-Preview-10.png",
        alt: "Live Preview Example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the new tab, view the template preview, which should contain your newly added testimonial module."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-added-to-theme.png",
        alt: "testimonial-module-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Customize the module in the template locally"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To make the homepage template more interesting:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate back to your code editor, then open the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "home.html"
        }), " file."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Add the following parameters to the testimonial module tag:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n testimonial = \"Wow, what a product! I can't wait to recommend this to all my family and friends!\",\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The above parameters override the default values that you originally assigned to the three fields. Each parameter uses the HubL variable name that you assigned to each field previously:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "customer_name"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " this parameter passes the name ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Mary"
        }), " to the customer name field, overriding the original value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sally"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "profile_pic"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " this parameter is an object that contains two properties:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "src"
            }), " property uses the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "get_asset_url"
            }), " HubL function to retrieve the URL for the new profile picture. Note that the image's file path is relative to your working directory. You'll first need to add this image to the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " folder in your local theme files. You can find the image in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " folder of the completed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
              children: "project files."
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " property assigns the image's alt text."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " this parameter passes new text into the testimonial field."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alternatively, for the rich text field you could use HubL block syntax to write a large block of HTML or text:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n offset=0,\n width=4\n%}\n {% module_attribute \"testimonial\" %}\n   Wow, what a product! I can't wait to recommend this to all my family and friends!\n {% end_module_attribute %}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "module block syntax"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you’ve kept the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " command running in your terminal, saving your changes will send them to HubSpot. You can then navigate back to the design manager to preview the template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Mary-Module-in-Template-12.png",
        alt: "Mary Module in Template"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add two more testimonial modules to the template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this step, you'll add two more testimonial modules to the template using the same steps as before:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Navigate to your code editor, then open the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), " file."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Under the testimonial module you previous added, add another instance of the module by copying and pasting that module's code. In the new testimonial module, specify the following details using the same steps as above:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The customer's name is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ollie's testimonial reads: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "I can't believe that I ever conducted business without the use of this product!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For Ollie's picture, add the relative file path for the file within the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " folder. You can get the image itself from the finished ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
              children: "project files"
            }), ". Then give Ollie's image the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " attribute of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To maintain spacing, set ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Underneath the second testimonial module, add a third with the following details:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The customer's name is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Erin's testimony reads: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "My business has nearly tripled since I began to use this product! Don't wait, start now!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For Erin's picture, add the relative file path for the file within the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), " folder. Then give Erin's image the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " attribute of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To maintain spacing, set ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "8"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Save your changes."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you’ve kept the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " command running in your terminal, you can navigate back to the Design Manager to preview your changes to the template. The template preview should now contain all three testimonial modules."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-modules-added-to-theme.png",
        alt: "testimonial-modules-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As a final step, you'll prepare the theme for use in a live page by modifying the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modify the theme name"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you'd like to modify the default name of the theme, you can do so by configuring the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " fields in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example theme.json\n\n{\n  \"name\": \"my_first_theme\",\n  \"label\": \"My first theme\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After adding that code, save your changes so that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " command sends your changes to HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Next steps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you've created a custom module, added it to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "home.html"
      }), " template, and optionally customized the theme name, you're ready to create a live page from the theme."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you'd like to follow a similar tutorial that focuses on themes, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/getting-started",
        children: "Getting started with themes tutorial"
      }), " next. Otherwise, you can learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
        children: "creating and customizing pages"
      }), " in HubSpot's Knowledge Base."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Or, if you'd like to learn more about modules, check out the following module guides:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configuring a module"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Using modules in templates"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Default modules"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Other tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Getting started with themes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Getting started with serverless functions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Creating an efficient developer workflow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Getting started with accessibility"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/js-frameworks",
          children: "How to use JavaScript frameworks on CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "How to use web components in custom modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-build-a-code-block-web-component",
          children: "How to build a code block web component based module"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}