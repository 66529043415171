"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164068156754;
const slug = exports.slug = 'reference/ui-components/standard-components/tag';
const title = exports.title = 'Tag';
const name = exports.name = 'Tag | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Tag component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "alignment",
  "label": "Alignment",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tag | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Tag"
      }), " component renders a tag to label or categorize information or other components. Tags can be static or clickable for invoking functions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-tag.png",
        alt: "design-guidelines-tag"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variant:"
        }), " the color of the tag."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tag text:"
        }), " the text that communicates the tag's purpose."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Tag } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Tag\n      variant=\"success\"\n      onClick={() => {\n        console.log('Tag clicked!');\n      }}\n      inline={true}\n    >\n      Success\n    </Tag>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'default'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'warning'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'success'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'error'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'info'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The color of the alert. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants section"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "() => void"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the tag is clicked. The function receives no arguments and its return value is ignored."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overlay"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Include a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), " component in this object to open it as an overlay on click. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/crm/ui-extensions/sdk#open-an-overlay",
              children: "using overlays"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop, you can choose from one of five tag colors:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-components-tag-inline-variants.png",
        alt: "ui-extension-components-tag-inline-variants"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "default"
        }), " (default): for general tagging and labeling."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "success"
        }), ": for indicating or confirming the success of an action."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "warning"
        }), ": for indicating something that might be time-sensitive or of importance."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "error"
        }), ": for indicating error or failure."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "info"
        }), ": for conveying general information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alignment"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inline"
      }), " prop, you can set a tag to align side-by-side with surrounding text. Below are examples of inline tags next to single-line text and multi-line text."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-components-tag-inline-examples_3.png",
        alt: "ui-extension-components-tag-inline-examples_3"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Text>\n  <Tag inline={true}>In Review</Tag>Some text over here\n</Text>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-components-tag-inline-examples_2.png",
        alt: "ui-extension-components-tag-inline-examples_2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Text>\n  <Tag inline={true}>In Review</Tag>Lorem ipsum dolor sit amet, consectetur\n  adipiscing elit. Nam ac rhoncus velit, non tincidunt tortor. Aliquam nec\n  ligula quis risus vehicula mattis id vitae orci. Suspendisse sed mattis metus,\n  id iaculis enim.\n</Text>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-components-tag-inline-examples_1.png",
        alt: "ui-extension-components-tag-inline-examples_1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Text>\n  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ac rhoncus velit,\n  non tincidunt tortor. Aliquam nec ligula quis risus vehicula mattis id vitae\n  orci. <Tag inline={true}>In Review</Tag> Suspendisse sed mattis metus, id\n  iaculis enim. Cras nisl erat, pulvinar sit amet nisl sit amet, feugiat\n  pharetra urna.\n</Text>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use a default tag to indicate that a customer is active."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use a success tag to indicate that an item in a to-do list has been completed."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use a warning tag to indicate that a deal is expiring soon."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use an error tag to indicate that an error happened when trying to sync a specific property in a table."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " make tag text concise and clear."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " ensure that tag variants are used consistently across the extension."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use tags in place of buttons or links."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " rely on color alone to communicate the tag's meaning. Ensure that tag text is clear and helpful."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/toggle",
          children: "Toggle"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/alert",
          children: "Alert"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/progress-bar",
          children: "ProgressBar"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}