"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093031;
const slug = exports.slug = 'guides/cms/tools/personal-access-key';
const title = exports.title = 'Persönliche CMS-Zugriffsschlüssel';
const name = exports.name = 'EMEA DACH Personal CMS Access Keys';
const metaDescription = exports.metaDescription = 'Persönliche CMS-Zugriffsschlüssel sind eine Form der Authentifizierung, die von Entwicklern verwendet werden soll, die auf HubSpot aufbauen. Sie sind an einen bestimmten Benutzer in einem Account gebunden.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      code: "code",
      img: "img",
      a: "a",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Persönlicher Zugriffsschlüssel"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Persönliche Zugriffsschlüssel sind die empfohlene Methode zur Authentifizierung bei Tools für lokale Entwicklung."
      }), " Persönliche Zugriffsschlüssel funktionieren ähnlich wie API-Schlüssel, sind jedoch an einen bestimmten Benutzer in einem Account gebunden. Persönliche Zugriffsschlüssel funktionieren nur bei Tools für lokale Entwicklung."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Persönliche Zugriffsschlüssel im Vergleich zu anderen Authentifizierungsmethoden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Vorteil von persönlichen Zugriffsschlüsseln gegenüber Implementierungen wie API-Schlüssel ist, dass API-Schlüssel praktisch Super-Admin-Berechtigungen haben. Persönliche Zugriffsschlüssel sind auf die Berechtigungen beschränkt, die der individuelle Benutzer im Portal hat. Wenn der Benutzer Super-Admin-Berechtigungen ist, sieht er keinen Unterschied in seiner Funktionalität, aber der Vorteil ist, dass, wenn z. B. ein einzelner Entwickler von einem Account entfernt werden muss, beim Vorgang des Deaktivierens seines Benutzers im Account dessen lokalen Entwicklungsfunktionen deaktiviert werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da persönliche Zugriffsschlüssel an den individuellen Benutzer in einem Account gebunden sind, können wir nützlichere Informationen anzeigen. Wenn ein Entwickler beispielsweise eine Datei mit den Tools für lokale Entwicklung ändert oder hochlädt, während er einen persönlichen Zugriffsschlüssel verwendet, können wir die Änderung in der App diesem Benutzer zuordnen. Dies erleichtert die Zusammenarbeit mit Teams und das Verständnis, wer was getan hat."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Persönliche Zugriffsschlüssel sind an individuellen Benutzer im jeweiligen HubSpot-Account gebunden und nicht direkt an den Benutzer. Dies bedeutet, dass Sie mithilfe der Tools für lokale Entwicklung einen neuen persönlichen Zugriffsschlüssel für jeden Account erstellen müssen, mit dem Sie die Entwicklungstools verwenden möchten. Dies bietet eine Sicherheitsebene für Accounts, da ein böswilliger Akteur, der an Ihren Zugriffsschlüssel gelangt, nur die einzelnen Portale beeinflussen kann, und dies nur als dieser einzelne Benutzer."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ähnlichkeit zu OAuth2"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Hinter den Kulissen agieren sich persönliche Zugriffsschlüssel eigentlich wie OAuth2. Wenn Sie einen persönlichen Zugriffsschlüssel generieren, wählen Sie die Berechtigungen aus, die dieser Schlüssel haben soll. Pro HubSpot-Account darf nur ein Zugriffsschlüssel pro Benutzer vorhanden sein. Nachdem Sie Ihren Zugriffsschlüssel generiert haben, wird eine App mit Ihrem HubSpot-Account namens „Tools für lokale Entwicklung von HubSpot“ verknüpft. Diese HubSpot-App eines Erstanbieters erleichtert die Authentifizierung für die Tools für lokale Entwicklung bei der Verwendung eines persönlichen Zugriffsschlüssels. Wenn Sie diese App trennen, werden alle zuvor generierten Zugriffsschlüssel gelöscht, sodass Ihre Tools für lokale Entwicklung nicht mehr über diese Zugriffsschlüssel verknüpft werden können. Sie müssen einen neuen Schlüssel generieren und Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), "-Datei aktualisieren."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/personalcmsaccesskey.png",
            alt: "personalcmsaccesskey"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schutz Ihrer Anmeldeinformationen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bewahren Sie Ihre persönlichen Zugriffsschlüssel auf, als wären sie Ihr Passwort. Teilen Sie sie mit niemandem. Sie ermöglichen es demjenigen, der sie hat, sich zu authentifizieren, als ob er Sie wäre, und alle Maßnahmen zu ergreifen, die Sie persönlich ergreifen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Verwendung von persönlichen Zugriffsschlüsseln mit den Tools für lokale Entwicklung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Persönliche Zugriffsschlüssel wurden für die Verwendung mit Tools für lokale Entwicklung konzipiert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Erste Schritte mit den Tools für lokale Entwicklung"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://app.hubspot.com/l/personal-access-key",
        children: "Zeigen Sie Ihren persönlichen CMS-Zugriffsschlüssel an."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn er für die Authentifizierung in den Tools für lokale Entwicklungs verwendet wird, sieht Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "-Datei ungefähr so aus:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: production\nportals:\n  - name: production\n    portalId: <portalId>\n    authType: personalaccesskey\n    personalAccessKey: >-\n      CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}