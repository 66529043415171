"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125397;
const slug = exports.slug = 'reference/cms/hubl/operators-and-expression-tests';
const title = exports.title = 'Operadores y pruebas de expresiones';
const name = exports.name = 'EMEA ES Operators & Expression Tests';
const metaDescription = exports.metaDescription = 'Para ampliar la lógica y la funcionalidad de tus plantillas, HubL admite varios operadores clave y pruebas de expresión. Los operadores permiten ejecutar funciones matemáticas, realizar comparaciones, complicar la lógica de las plantillas y alterar la representación del marcado. Además, este artículo contiene una lista completa de pruebas de expresión que pueden utilizarse en HubL.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "operadores",
  "label": "Operadores",
  "parent": null
}, {
  "depth": 1,
  "id": "matem%C3%A1ticas",
  "label": "Matemáticas",
  "parent": "operadores"
}, {
  "depth": 1,
  "id": "comparaci%C3%B3n",
  "label": "Comparación",
  "parent": "operadores"
}, {
  "depth": 1,
  "id": "l%C3%B3gica",
  "label": "Lógica",
  "parent": "operadores"
}, {
  "depth": 1,
  "id": "otros-operadores-de-hubl",
  "label": "Otros operadores de HubL",
  "parent": "operadores"
}, {
  "depth": 0,
  "id": "pruebas-de-expresi%C3%B3n",
  "label": "Pruebas de expresión",
  "parent": null
}, {
  "depth": 1,
  "id": "boolean",
  "label": "boolean",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "containing",
  "label": "containing",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "containingall",
  "label": "containingall",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "defined",
  "label": "defined",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "divisibleby",
  "label": "divisibleby",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "equalto",
  "label": "equalto",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "even",
  "label": "even",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "float",
  "label": "float",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "integer",
  "label": "integer",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "iterable",
  "label": "Iterable",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "lower",
  "label": "lower",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "mapping",
  "label": "mapping",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "none",
  "label": "none",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "number",
  "label": "number",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "odd",
  "label": "odd",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "sameas",
  "label": "sameas",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "sequence",
  "label": "sequence",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "string",
  "label": "string",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "string_containing",
  "label": "string_containing",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "string_startingwith",
  "label": "string_startingwith",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "truthy",
  "label": "truthy",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "undefined",
  "label": "undefined",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "upper",
  "label": "upper",
  "parent": "pruebas-de-expresi%C3%B3n"
}, {
  "depth": 1,
  "id": "within",
  "label": "within",
  "parent": "pruebas-de-expresi%C3%B3n"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Operadores y pruebas de expresiones"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ampliar la lógica y la funcionalidad de tus plantillas, HubL admite varios operadores clave y pruebas de expresión. Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/operators-and-expression-tests#operators",
        children: "operadores"
      }), " te permiten ejecutar funciones matemáticas, hacer comparaciones, complicar la lógica de la plantilla y alterar la representación del marcado. Además, este artículo contiene una lista completa de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "pruebas de expresión"
      }), " que se pueden utilizar en HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Operadores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los operadores son símbolos que indican al compilador HubL que ejecute diversas operaciones que dan lugar a la salida final del marcador. La siguiente sección incluye una lista de todos los operadores de HubL compatibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Matemáticas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los operadores matemáticos estándar pueden utilizarse para calcular valores en el contexto de una plantilla."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "+"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Suma dos objetos juntos. Generalmente se utiliza para la suma de números. Si estás tratando de concatenar cadenas de listas, debes utilizar ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "~"
            }), " en su lugar."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "-"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Resta un número de otro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "/"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dividir números"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "%"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Devuelve el resto de la división de números"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "\"\""
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Divide dos números y devuelve el resultado entero truncado. Ejemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;%raw%&#125;&#123;&#123; 20 // 7 &#125;&#125;&#123;%endraw%&#125;"
            }), " es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "*"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multiplicar números"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "**"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eleva el operando izquierdo a la potencia del operando derecho"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num + my_number }}<br/>  <!-- 11 + 2 = 13 -->\n{{ my_num - my_number }}<br/>  <!-- 11 - 2 = 9 -->\n{{ my_num / my_number }}<br/>  <!-- 11 / 2 = 5.5 -->\n{{ my_num % my_number }}<br/>  <!-- 11 % 2 = 1 -->\n{{ my_num // my_number }}<br/> <!-- 11 // 2 = 5 -->\n{{ my_num * my_number }}<br/>  <!-- 11 * 2 = 22 -->\n{{ my_num ** my_number }}<br/> <!-- 11 ** 2 = 121 -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "13 9 5.5 1 5 22 121\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comparación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los operadores de comparación se pueden utilizar para evaluar valores para la lógica de la plantilla. Puedes ver algunos ejemplos de uso de operadores de comparación en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "sentencias if aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "abreviatura"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "=="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Igual a Se evalúa como verdadero si tdos objetos son iguales"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "!="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No es igual a Se evalúa como verdadero si dos objetos no son iguales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mayor que Se evalúa como verdadero si el lado izquierdo es mayor que el lado derecho."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mayor que o igual a. Se evalúa como verdadero si el lado izquierdo es mayor que o igual al lado derecho."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que Se evalúa como verdadero si el lado izquierdo es menor que el lado derecho."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que o igual a Se evalúa como verdadero si el lado izquierdo es menor que o igual al lado derecho."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["La versión abreviada de los operadores de comparación se puede utilizar en filtros que implican la comprobación de una expresión como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|selectattr()"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num == my_number }}<br/>  <!-- false -->\n{{ my_num != my_number }}<br/>  <!-- true -->\n{{ my_num > my_number }}<br/>   <!-- true -->\n{{ my_num >= my_number }}<br/>  <!-- true -->\n{{ my_num < my_number }}<br/>   <!-- false -->\n{{ my_num <= my_number }}<br/>  <!-- false -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "false true true true false false\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Lógica"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los operadores lógicos permiten combinar varias expresiones en una sola sentencia."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "and"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Devuelve verdadero si el operando izquierdo y el derecho son verdaderos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "or"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Devuelve verdadero si el operando izquierdo o el derecho es verdadero."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "not"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Niega un enunciado y se utiliza junto con is. Consulta el ejemplo siguiente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "(expr)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Agrupa una expresión para el orden de las operaciones. Por ejemplo, (10 - 2) * variable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "?:"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements",
              children: "operador ternario"
            }), " acepta 3 argumentos (expresión, condición verdadera, condición falsa). Evalúa una expresión y devuelve la condición correspondiente."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Otros operadores de HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se presentan otros operadores importantes de HubL que pueden utilizarse para realizar diversas tareas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "in"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprueba si un valor está en una secuencia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "is"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Realiza una ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/es/docs/cms/hubl/operators-and-expression-tests#expression-tests",
              children: "prueba de expresión"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "|"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplica un filtro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "~"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concatena los valores."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pruebas de expresión"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las pruebas de expresión son varias condiciones booleanas que pueden evaluarse utilizando operadores lógicos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "boolean"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "booleana"
      }), " comprueba si el objeto es booleano (en sentido estricto, no en su capacidad de evaluarse a una expresión verdadera)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set isActive = false %}\n\n{% if isActive is boolean %}\nisActive is a boolean\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "isActive is a boolean\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containing"
      }), " comprueba si una variable de lista tiene un valor en ella."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containing 2 %}\n\tSet contains 2!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containingall"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containingall"
      }), " comprueba si una variable de lista contiene todos los valores de otra lista."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containingall [2, 3] %}\n\tSet contains 2 and 3!\n{% endif %}\n\n{% if numbers is containingall [2, 4] %}\n\tSet contains 2 and 4!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2 and 3!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "defined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "defined"
      }), " comprueba si una variable está definida en el contexto de la plantilla. Aunque puedes utilizar esta prueba de expresión, al escribir una sentencia if sin ningún operador se comprobará por opción predeterminada si la variable está definida o no."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el siguiente ejemplo, se comprueba el parámetro de color de un módulo de color. Si el parámetro de color no tuviera ningún valor, la plantilla, por opción predeterminada, mostraría un color de fondo negro. Si se define, muestra el color de fondo establecido por el usuario."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% color \"my_color\" color=\"#930101\", export_to_template_context=True %}\n<style>\n{% if widget_data.my_color.color is defined %}\nbody{\n    background: {{ widget_data.my_color.color }};\n}\n{% else %}\nbody{\n    background: #000;\n}\n{% endif %}\n</style>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<style>\n  body {\n    background: #930101;\n  }\n</style>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "divisibleby"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "divisibleby"
      }), " puede utilizarse para comprobar si un objeto es divisible por otro número."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, a continuación se crea un bucle for que itera a través de una lista de tipos de animales. Cada tipo de animal se imprime en un div, y cada 5 div tiene aplicado un estilo inline diferente (width:100%). Este concepto podría aplicarse a un blog en el que se renderiza un marcador diferente para un determinado patrón de entradas. Para saber más sobre los bucles for y loop.index, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "consulta este artículo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set animals = [\"lions\", \"tigers\", \"bears\", \"dogs\", \"sharks\"] %}\n{% for animal in animals %}\n    {% if loop.index is divisibleby 5 %}\n    <div style=\"width:100%\">{{animal}}</div>\n    {% else %}\n    <div style=\"width:25%\">{{animal}}</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%\">lions</div>\n<div style=\"width:25%\">tigers</div>\n<div style=\"width:25%\">bears</div>\n<div style=\"width:25%\">dogs</div>\n<div style=\"width:100%\">sharks</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "equalto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "equalto"
      }), " comprueba si el valor de una variable es igual a una constante o a otra variable. También puedes utilizar el operador == para realizar la misma prueba."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el ejemplo siguiente, el ancho de los posts del blog se ajusta en función del número total de entradas en el bucle. La salida del ejemplo supone que hay 4 posts en el blog."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    {% if loop.length is equalto 2 %}\n        <div style=\"width:50%;\">Post content</div>\n    {% elif loop.length is equalto 3 %}\n        <div style=\"width:33.333332%;\">Post content</div>\n    {% elif loop.length is equalto 4 %}\n        <div style=\"width:25%;\">Post content</div>\n    {% else %}\n        <div style=\"width:100%;>Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "even"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "even"
      }), " comprueba si una variable numérica es un número par."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El ejemplo siguiente muestra un bucle simplificado de listado de blogs, en el que si la iteración actual del bucle es par, se asigna una clase de even-post al div del elemento de la entrada. En caso contrario, se asigna una clase de odd-post."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is even %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "float"
      }), " comprueba si una variable numérica es un número de punto flotante."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 1.20 %}\n{% if quantity is float %}\n  quantity is a floating point number\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is a floating-point number\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "integer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comprueba si una variable es un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "entero."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 120 %}\n{% if quantity is integer %}\n  quantity is an integer\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is an integer\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Iterable"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comprueba si una variable es ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), " y puede ser recorrida en bucle."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este ejemplo comprueba una variable llamada \"jobs\" para ver si puede ser iterada. Como la variable contiene una lista de trabajos, la sentencia if se evaluaría como verdadera y el bucle se ejecutaría. Si la variable contuviera un solo valor, la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "sentencia if"
      }), " imprimiría ese valor con un marcador diferente. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "Más información sobre los bucles for aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set jobs = [\"Accountant\", \"Developer\", \"Manager\", \"Marketing\", \"Support\"] %}\n\n{% if jobs is iterable %}\n<h3>Available positions</h3>\n<ul>\n{% for job in jobs %}\n    <li>{{ job }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Available position</h3>\n<div class=\"single-position\">{{ jobs }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Available positions</h3>\n<ul>\n  <li>Accountant</li>\n  <li>Developer</li>\n  <li>Manager</li>\n  <li>Marketing</li>\n  <li>Support</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "lower"
      }), " evalúa como verdadero cuando una cadena está en minúsculas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente ejemplo utiliza una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/if-statements#unless-statements",
        children: "sentencia unless"
      }), " y un filtro lower para asegurar que una cadena de texto introducida en un módulo de texto esté siempre en minúsculas."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Lowercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is lower %}\n{{ widget_data.my_text.value|lower }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text that should be lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "mapping"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mapping"
      }), " comprueba si un objeto es o no un dict (diccionario)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El ejemplo siguiente comprueba si el objeto de contacto es un diccionario, en cuyo caso lo es."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if contact is mapping %}\nThis object is a dictionary.\n{% else %}\nThis object is not a dictionary.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This object is a dictionary.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "none"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "none"
      }), " comprueba si una variable tiene un valor nulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"user_email\" path=\"@hubspot/text\" label=\"Enter user email\", value=\"example@hubspot.com\", export_to_template_context=True %}\n{% unless widget_data.user_email.value is none %}\n{{ widget_data.user_email.value }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "example@hubspot.com\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "number"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "number"
      }), " comprueba si el valor de una variable es o no un número."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente ejemplo comprueba si una variable es o no una variable, y si es así la convierte en millones."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = 40 %}\n{% if my_var is number %}\n{{ my_var * 1000000 }}\n{% else %}\nmy_var is not a number.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "40000000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "odd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "odd"
      }), " comprueba si una variable numérica es un número impar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestra el mismo ejemplo que la prueba de expresión par inversa descrita anteriormente."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is odd %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sameas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sameas"
      }), " comprueba si dos variables tienen o no el mismo valor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente ejemplo establece dos variables y luego comprueba si son iguales o no."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set var_one = True %}\n{% set var_two = True %}\n{% if var_one is sameas var_two  %}\nThe variables values are the same.\n{% else %}\nThe variables values are different.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The variables values are the same.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sequence"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sequence"
      }), " es similar a la prueba ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), " , ya que comprueba si una variable es o no una secuencia."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente ejemplo comprueba si una variable es una secuencia y luego itera a través de esa secuencia de géneros musicales."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set genres = [\"Pop\", \"Rock\", \"Disco\", \"Funk\", \"Folk\", \"Metal\", \"Jazz\", \"Country\", \"Hip-Hop\", \"Classical\", \"Soul\", \"Electronica\" ] %}\n{% if genres is sequence %}\n<h3>Favorite genres</h3>\n<ul>\n{% for genre in genres %}\n    <li>{{ genre }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Favorite genre:</h3>\n    <div class=\"single-genre\">{{ genres }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Pop</li>\n  <li>Rock</li>\n  <li>Disco</li>\n  <li>Funk</li>\n  <li>Folk</li>\n  <li>Metal</li>\n  <li>Jazz</li>\n  <li>Country</li>\n  <li>Hip-Hop</li>\n  <li>Classical</li>\n  <li>Soul</li>\n  <li>Electronica</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "string"
      }), " comprueba si el valor almacenado en una variable es texto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente ejemplo comprueba si una variable es o no una cadena, y si es así, aplica un filtro de título para cambiar las mayúsculas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = \"title of section\" %}\n{% if my_var is string %}\n{{ my_var|title }}\n{% else %}\nmy_var is not a string\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Title Of Section\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_containing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta prueba comprueba si una cadena está contenida dentro de otra cadena. Esta prueba de expresión se utiliza junto con el operador \"is\"."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.domain is string_containing \".es\" %}\nMarkup that will only render on content hosted on .es domains\n{% elif content.domain is string_containing \".jp\" %}\nMarkup that will only render on content hosted on .jp domains\n{% else %}\nMarkup that will render on all other domains\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all other domains\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_startingwith"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta prueba de expresión comprueba si una cadena comienza con una cadena determinada. Se utiliza junto con el operador \"is\"."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.slug is string_startingwith \"es/\" %}\nMarkup that will only render on content hosted in a /es/ subdirectory\n{% elif content.slug is string_startingwith \"jp/\" %}\nMarkup that will only render on content hosted in a /jp/ subdirectory\n{% else %}\nMarkup that will render on all subdirectories\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all subdirectories\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "truthy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "truthy"
      }), " comprueba si una expresión es verdadera."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente ejemplo utiliza un módulo de casilla de comprobación booleana para mostrar un mensaje de alerta."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% boolean \"check_box\" label=\"Show alert\", value=True, export_to_template_context=True %}\n\n{% if widget_data.check_box.value is truthy %}\n<div class=\"alert\">Danger!</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"alert\">Danger!</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "undefined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "undefined"
      }), " comprueba si una variable está indefinida en el contexto de la plantilla. Esta prueba es diferente de la de none, ya que undefined será verdadera cuando la variable esté presente pero no tenga ningún valor; mientras que none será verdadera cuando la variable tenga un valor nulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente ejemplo comprueba la existencia de la variable \"my_var\" en una plantilla."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if my_var is undefined %}\nA variable named \"my_var\" does not exist on this template.\n{% else %}\n{{ my_var }}\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A variable named \"my_var\" does not exist on this template.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "upper"
      }), " evalúa como verdadero cuando una cadena está en mayúsculas. A continuación se muestra un ejemplo inverso de la prueba de expresión inferior anterior."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Uppercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is upper %}\n{{ widget_data.my_text.value|upper }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "SOME TEXT THAT SHOULD BE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "within"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La prueba de expresión ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "within"
      }), " comprueba si una variable está dentro de una lista."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if 2 is within numbers %}\n\t2 is in the list!\n{% endif %}\n\n{% if 4 is within numbers %}\n\t4 is in the list!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2 is in the list!\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}