"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313460;
const slug = exports.slug = 'reference/cms/hubl/quote-variables';
const title = exports.title = 'Angebotsvorlagenvariablen';
const name = exports.name = 'EMEA DACH (de) Quote template variables';
const metaDescription = exports.metaDescription = 'Verwenden Sie HubL, um in Ihrer benutzerdefinierten Angebotsvorlage auf CRM-Daten zuzugreifen und diese anzuzeigen. Das template_data-Objekt enthält die meisten Daten, die dem Angebot zugeordnet sind. Für alles, was dort nicht gefunden wird, können Sie entweder den Objektdatensatz dem Angebot zuordnen oder die HubL CRM_object-Funktionen verwenden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "template_data-objekt",
  "label": "Template_data-Objekt",
  "parent": null
}, {
  "depth": 0,
  "id": "angebotsvariablen",
  "label": "Angebotsvariablen",
  "parent": null
}, {
  "depth": 0,
  "id": "zugeordnete-objekte",
  "label": "Zugeordnete Objekte",
  "parent": null
}, {
  "depth": 0,
  "id": "benutzerdefinierte-objekte",
  "label": "Benutzerdefinierte Objekte",
  "parent": null
}, {
  "depth": 0,
  "id": "weiterf%C3%BChrende-ressourcen",
  "label": "Weiterführende Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      br: "br",
      a: "a",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Angebotsvorlagenvariablen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Benutzerdefinierte Angebotsvorlagen können direkt über die Vorlagen auf Angebotsdaten und einige zugeordnete Objekte zugreifen. Die verfügbaren Daten hängen von Daten ab, die Sie in Ihrem CRM haben, sowie von Daten, die dem Angebot selbst hinzugefügt wurden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Entwickeln einer Angebotsvorlage können Sie von HubSpot bereitgestellte Mock-Daten verwenden, um die Vorlage zu füllen, was beim Anzeigen einer Vorschau der Vorlage helfen kann. Gehen Sie im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), "-Ordner zum ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cms-quotes-theme"
      }), "-Ordner. Zeigen Sie im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), "-Ordner die Vorlagen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "basic.html"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modern.html"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "original.html"
      }), " an. Diese Vorlagen enthalten oben folgenden Code-Block:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% from \"../imports/mock_data.html\" import SAMPLE_TEMPLATE_DATA as mock_data %}\n{% from \"../imports/module_defaults.html\" import MODULE_DEFAULTS as module_defaults %}\n{% set QUOTE = template_data.quote || mock_data.quote %}\n{% set CURRENCY = QUOTE.hs_currency || \"USD\" %}\n{% set LOCALE = QUOTE.hs_locale || \"en-US\" %}\n{% set ASSOCIATED_OBJECTS = QUOTE.associated_objects %}\n{% set LINE_ITEMS = ASSOCIATED_OBJECTS.line_items %}\n{% set ADDITIONAL_FEES = ASSOCIATED_OBJECTS.additional_fees %}\n{% set TOTALS = ASSOCIATED_OBJECTS.totals || ASSOCIATED_OBJECTS.totals %}\n{% set QUOTE_TOTAL = TOTALS.total %}\n{% set SUB_TOTALS = TOTALS.subtotals %}\n{% set DEAL = ASSOCIATED_OBJECTS.deal %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Mock-Daten werden zuerst aus der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), "-Datei importiert und dann auf die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), "-Variable festgelegt, um die in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " gefundenen Daten zu verwenden, falls verfügbar. Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), "-Variable wird auch zum Ausfüllen der anderen Variablen in dieser Liste verwendet, z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATED_OBJECTS"
      }), ", um den Zugriff auf diese Daten zu vereinfachen. Sie können Ihre Daten jedoch je nach Ihren Vorlieben unterschiedlich strukturieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im obigen Code werden Sie feststellen, dass ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " auch verwendet wird, um die Haupt-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), "-Variable festzulegen. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " ist ein Objekt, das alle tatsächlichen Daten für das Angebot und den Deal auf der Seite enthält. Wenn dieses Objekt nicht in der Vorlage gefunden wird, lädt HubSpot stattdessen die Daten von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Template_data-Objekt"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der überwiegende Teil der Daten kann direkt über das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), "-Objekt abgerufen werden. Sie können ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ template_data|pprint }}"
      }), " in Ihrer Vorlage verwenden, um das vollständige bereitgestellte Objekt anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Dict, das das Angebot, quote.associated_objects und die Gesamtanzahl der Dicts enthält."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Angebotsvariablen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die spezifischen Informationen zu diesem individuellen Angebot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict, das alle Daten für das Angebot selbst enthält."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.associated_objects.deal.hs_object_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal-ID"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID des zuständigen Mitarbeiters für einen Deal"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_all_owner_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "integer or array of integers"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "IDs der zuständigen Mitarbeiters für einen Deal"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_created_by_user_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzer, der das Angebot erstellt hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_lastmodifieddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datum, an dem das Angebot zuletzt bearbeitet wurde. Im Epoche-Format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_assigneddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datum, an dem dem Angebot ein Mitarbeiter zugewiesen wurde. Im Epoche-Format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_createdate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeitpunkt (Datum und Uhrzeit), zu dem das Angebot erstellt wurde. Im Epoche-Format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_expiration_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ablaufdatum des Angebots. Im Epoche-Format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Angebotstitel"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_template_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"CUSTOMIZABLE_QUOTE_TEMPLATE\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL-Slug für die Angebots-Webseite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_proposal_template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entwickler-Dateisystempfad zur Vorlage. (umfasst Dateierweiterung)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Geldbetrag"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Währung, in der der Angebotsbetrag in einem 3-stelligen ISO 4217-Währungscode angegeben ist.„€“"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sprachcode„de“"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_locale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gebietsschema„de-de“"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_terms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text für die Bedingungen, der vom Ersteller des Angebots bereitgestellt wird"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vorname der Person, die das Angebot sendet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmensname der Person, die das Angebot sendet"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_image_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmenslogo für die Person, die das Angebot sendet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Status des Angebots.„APPROVAL_NOT_NEEDED“"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_primary_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string/hex color code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "„#425b76“"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eindeutige ID-Nummer des Angebots."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wird verwendet, um zu testen, ob Zahlungsfelder angezeigt werden müssen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_esign_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wird verwendet, um zu testen, ob eSgnature-Felder angezeigt werden müssen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sie können eine Variable, nach der Sie suchen, nicht finden?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Es gibt weitere Variablen, auf die Sie in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "template_data"
        }), " zugreifen können. Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|pprint"
        }), ", um sie anzuzeigen. Darüber hinaus können einige Variablen in Angebotszuordnungen nur basierend auf dem Angebot/Deal verfügbar sein."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Wir werden diese Dokumentation weiter ausbauen, um mehr Daten, auf die Sie Zugriff haben, zu präsentieren und zu erklären. Abgesehen vom Pretty Printing können Sie die Mock-Datendatei innerhalb des cms-quote-theme anzeigen, um zu sehen, was verfügbar ist und in welche Struktur es kommt."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zugeordnete Objekte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In einer Angebotsvorlage können Sie mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associated_objects"
      }), " auf Daten von den zugeordneten Datensätzen eines Angebots zugreifen, z. B. Deals oder Unternehmen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beispielsweise können Sie das Logo aus dem zugeordneten Unternehmensdatensatz des Angebotsempfängers zu einem Angebot hinzufügen, indem Sie den folgenden Code verwenden:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set company_avatar_url = template_data.quote.associated_objects.company.hs_avatar_filemanager_key %}\n{% if company_avatar_url %}\n  <img src=\"{{ template_data.quote.associated_objects.company.hs_avatar_filemanager_key }}\" width=\"400\" alt=\"{{ template_data.quote.associated_objects.company.name }}\">\n{% else %}\n  <!-- company does not have an assigned image-->\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Es werden nur manuell festgelegte Logos angezeigt. Automatisch erkannte Logos werden nicht angezeigt. Damit soll verhindert werden, dass unbeabsichtigte Logos auf der Angebotsvorlage erscheinen."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der obige Code legt zunächst eine Variable fest, die nach dem Logo des dem Angebot zugeordneten Unternehmens sucht. Mithilfe einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung zeigt die Vorlage dann dieses Logo an, falls verfügbar. Wurde für das Unternehmen kein Logo manuell festgelegt, wird kein Logo angezeigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Benutzerdefinierte Objekte"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Benutzerdefinierte Objektdaten können in einem Angebot auf verschiedene Arten angezeigt oder verwendet werden. Da die Struktur der einzelnen benutzerdefinierten Objekte variieren kann, müssen Sie bestimmte Eigenschaften basierend auf der Struktur Ihres benutzerdefinierten Objekts abrufen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), "-Angebot enthält standardmäßig benutzerdefinierte zugeordnete Objekte. Beispielsweise sind benutzerdefinierte Objekte, die Deals zugeordnet sind, enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um darauf zuzugreifen, können Sie den folgenden Code verwenden:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_custom_objects = template_data.quote.associated_objects.deal.associated_objects.custom_objects %}\n\n{{ quote_associated_custom_objects|pprint }}\n{# |pprint is useful for understanding the structure of the data, you can leave it off when outputting values for display. #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Da benutzerdefinierte Objekte für jeden Account eindeutig sind, enthalten die Mock-Daten kein benutzerdefiniertes Beispielobjekt. Dies bedeutet, dass Sie in der Vorlagenvorschau im Design-Manager möglicherweise einen Fehler sehen oder die benutzerdefinierten Objektdaten werden einfach nicht angezeigt. Sie müssen stattdessen eine Vorschau der Vorlage mit Ihren echten CRM-Daten anzeigen. Dies können Sie tun, indem Sie ein Angebot aus der Vorlage erstellen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können dann auf jeden benutzerdefinierten Objekttyp zugreifen, indem Sie seine benutzerdefinierte Objekttyp-ID mit Unterstrichen formatiert anhängen. Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data.quote.associated_objects.deal.associated_objects.custom_objects._2_2193031"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Außerdem können Sie ein benutzerdefiniertes Objekt mithilfe der Funktionen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations()"
        })
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), " suchen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie beispielsweise ein benutzerdefiniertes Objekt suchen möchten, das einem Deal zugeordnet ist, können Sie Daten von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_Data"
      }), " eingeben:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_object = crm_associations(template_data.quote.associated_objects.deal.hs_object_id, \"USER_DEFINED\", 152) %}\n{# 152 is an example of an association type id, you would need to use the appropriate id for your use-case. #}\n{{ quote_associated_object }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weiterführende Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Benutzerdefinierte Angebotsvorlagen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/getting-started-from-the-cms-quotes-theme-beta",
          children: "Erste Schritte mit dem CMS-Angebote-Design"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/deals/create-custom-quote-templates-beta",
          children: "Benutzerdefinierte Angebotsvorlagen erstellen und verwenden (aus der Perspektive des Vertriebsleiters, Sales Operations/Managers)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}