"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 93913308055;
const slug = exports.slug = 'guides/cms/content/modules/hide-modules-and-sections';
const title = exports.title = 'Hide modules and sections in the page editor';
const name = exports.name = 'Hide modules and sections in the page editor';
const metaDescription = exports.metaDescription = 'Learn how to hide modules and sections in the page editor for your custom theme.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hide-default-modules-in-a-theme",
  "label": "Hide default modules in a theme",
  "parent": null
}, {
  "depth": 0,
  "id": "hide-modules-and-sections-in-a-template",
  "label": "Hide modules and sections in a template",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Hide modules and sections"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing a theme, you can configure the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " to hide specific modules and sections from the editor. This enables you to curate the list of modules and sections available to content creators when building pages, blogs, and global content, rather than having all modules and sections appear for all content types. Using this feature, you can also hide ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "HubSpot default modules"
      }), " in favor of your own versions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can hide modules and sections in the following ways:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Within themes:"
        }), " you can hide default modules, but not custom modules. Similarly, you cannot hide custom sections in a theme. To exclude a custom module or section from a theme, you should instead delete the module or section from the theme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Within templates:"
        }), " you can hide default modules, custom modules, and sections."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When hiding modules and sections, keep the following in mind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When hiding HubSpot default modules in a ", (0, _jsxRuntime.jsx)("u", {
          children: "template"
        }), ", ensure that the module is wrapped in single quotes (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'"
        }), "). For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubSpot/follow_me'"
        }), ". This complies with YAML, which template annotations are based on."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You must use the relative path when specifying a hidden module. Using an absolute path will result in the module not being hidden."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hide default modules in a theme"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To hide all default modules, set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hide_all_default_modules\": true"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To hide specific default modules in a theme, you'll need to add a list of hidden modules to a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hidden_modules"
      }), " array in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to hide HubSpot's default button and form modules from a theme, your code would look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example theme.json\n{\n  \"label\": \"CMS Theme Boilerplate\",\n  \"preview_path\": \"./templates/home.html\",\n  \"screenshot_path\": \"./images/template-previews/home.png\",\n  \"enable_domain_stylesheets\": false,\n  \"license\": \"./license.txt\",\n  \"responsive_breakpoints\": [\n    {\n      \"name\": \"mobile\",\n      \"mediaQuery\": \"@media (max-width: 767px)\",\n      \"previewWidth\": { \"value\": 477 }\n    }\n  ],\n  \"hidden_modules\": [\"@hubspot/button\", \"@hubspot/form\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To hide all default modules, set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hide_all_default_modules\": true"
      }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hide modules and sections in a template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To hide modules and sections in a specific template, you'll need to add a list of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hiddenModules"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hiddenSections"
      }), " in the template annotations. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "// example template file\n<!--\ntemplateType: page\nisAvailableForNewContent: true\nlabel: Ticket listing\nscreenshotPath: ../images/template-previews/ticket-listing.png\nhiddenModules:\n- '@hubSpot/follow_me'\n- ../modules/form\nhiddenSections:\n- ../sections/card\n-->\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}