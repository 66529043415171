"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61303889553;
const slug = exports.slug = 'guides/api/crm/engagements/email';
const title = exports.title = 'Engagements | E-mail';
const name = exports.name = 'Engagements | E-mail';
const metaDescription = exports.metaDescription = "Utilisez l'API d'engagement des e-mails pour enregistrer et gérer e-mails dans les fiches d'informations CRM.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-un-e-mail",
  "label": "Créer un e-mail",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": "cr%C3%A9er-un-e-mail"
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s-en-lecture-seule",
  "label": "Propriétés en lecture seule",
  "parent": "cr%C3%A9er-un-e-mail"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-en-t%C3%AAtes-d-e-mail",
  "label": "Définir des en-têtes d'e-mail",
  "parent": "cr%C3%A9er-un-e-mail"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "cr%C3%A9er-un-e-mail"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-e-mails",
  "label": "Récupérer des e-mails",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-les-e-mails",
  "label": "Mettre à jour les e-mails",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-e-mails-existants-%C3%A0-des-fiches-d-informations",
  "label": "Associer des e-mails existants à des fiches d'informations",
  "parent": "mettre-%C3%A0-jour-les-e-mails"
}, {
  "depth": 1,
  "id": "supprimer-une-association",
  "label": "Supprimer une association",
  "parent": "mettre-%C3%A0-jour-les-e-mails"
}, {
  "depth": 0,
  "id": "%C3%A9pingler-un-e-mail-sur-une-fiche-d-informations",
  "label": "Épingler un e-mail sur une fiche d'informations",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-des-e-mails",
  "label": "Supprimer des e-mails",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "E-mail"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'API d'engagement des e-mails pour enregistrer et gérer e-mails dans les fiches d'informations CRM. Vous pouvez enregistrer des activités d'e-mails soit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "dans HubSpot"
      }), " soit via l'API des e-mails."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ci-dessous, découvrez les méthodes de base pour gérer les e-mails via l'API. Pour afficher tous les points de terminaison disponibles et leurs exigences, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un engagement d'e-mail, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le corps de la requête, ajoutez les détails d'e-mails dans un objet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), ". Vous pouvez également ajouter un objet d'", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "association"
      }), " pour associer votre nouvel e-mail à une fiche d'informations existante (par exemple, des contacts ou des entreprises)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet de propriété, vous pouvez inclure les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obligatoire. Ce champ indique l'heure de création de l'e-mail et détermine où se trouve l'e-mail sur la chronologie de la fiche d'informations. Vous pouvez utiliser soit un horodatage Unix en millisecondes, soit un format UTC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID du propriétaire"
            }), " associé à l'e-mail. Ce champ détermine l'utilisateur listé comme le créateur de l'e-mail sur la chronologie de la fiche d'informations."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La direction dans laquelle l'e-mail a été envoyé. Les valeurs possibles sont :", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            }), " : l'e-mail a été envoyé depuis le CRM ou envoyé et enregistré dans le CRM avec ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "l'adresse CCI."
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCOMING_EMAIL"
            }), " : l'e-mail était une réponse à un e-mail sortant enregistré. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FORWARDED_EMAIL"
            }), " : l'e-mail a été ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "transféré au CRM."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le corps d'un e-mail s'il est envoyé depuis un enregistrement CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le statut d'envoi de l'email. La valeur peut être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BOUNCED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SCHEDULED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENDING"
            }), ", ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La ligne d'objet de l'e-mail enregistré."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le corps de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les ID des pièces jointes de l'e-mail. Les ID de pièces jointes multiples sont séparés par un point-virgule."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les en-têtes de l'e-mail. La valeur de cette propriété renseignera automatiquement certaines propriétés d'e-mails en lecture seule. Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/email#set-email-headers",
              children: "configurer des en-têtes d'e-mails."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la création d'engagements par e-mails par lot, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés en lecture seule"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certaines propriétés d'e-mail sont également en lecture seule et sont automatiquement renseignées par HubSpot. Les propriétés du tableau ci-dessous sont toutes automatiquement renseignées à partir de la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'adresse e-mail de l'expéditeur de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le prénom de l'expéditeur de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de famille de l'expéditeur de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les adresses e-mail des destinataires de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les prénoms des destinataires de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les noms de famille des destinataires de l'e-mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Lors de la récupération d'un en-tête d'e-mail, vous pouvez remarquer qu'il y a des valeurs pour ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), ". Ce sont souvent les mêmes, mais comme ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " identifie ce qu'un e-mail a réellement soumis, il existe des scénarios où les valeurs peuvent différer. Par exemple, si un e-mail est envoyé à partir d'un alias d'e-mail, la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " fera référence à l'adresse e-mail réelle de l'utilisateur et la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " fera référence à l'alias d'e-mail."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des en-têtes d'e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Étant donné que les en-têtes remplissent automatiquement les propriétés en lecture seule, vous pouvez définir manuellement les en-têtes d'e-mail. Pour définir la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), ", vous pouvez utiliser une chaîne d'échappement JSON avec les données suivantes :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example data\n{\n  \"from\": {\n    \"email\": \"from@domain.com\",\n    \"firstName\": \"FromFirst\",\n    \"lastName\": \"FromLast\"\n  },\n  \"to\": [\n    {\n      \"email\": \"ToFirst ToLast<to@test.com>\",\n      \"firstName\": \"ToFirst\",\n      \"lastName\": \"ToLast\"\n    }\n  ],\n  \"cc\": [],\n  \"bcc\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, votre demande de création d'un e-mail peut ressembler à ceci :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"47550177\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for youremail\",\n    \"hs_email_headers\": \"{\\\"from\\\":{\\\"email\\\":\\\"from@domain.com\\\",\\\"firstName\\\":\\\"FromFirst\\\",\\\"lastName\\\":\\\"FromLast\\\"},\\\"sender\\\":{\\\"email\\\":\\\"sender@domain.com\\\",\\\"firstName\\\":\\\"SenderFirst\\\",\\\"lastName\\\":\\\"SenderLast\\\"},\\\"to\\\":[{\\\"email\\\":\\\"ToFirst+ToLast<to@test.com>\\\",\\\"firstName\\\":\\\"ToFirst\\\",\\\"lastName\\\":\\\"ToLast\\\"}],\\\"cc\\\":[],\\\"bcc\\\":[]}\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer et associer un e-mail à des fiches d'informations existantes, incluez un objet d'association dans votre demande. Par exemple, pour créer un e-mail et l'associer à une transaction et à un contact, votre corps de demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 601\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 210\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 602\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 198\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet d'association, vous devez ajouter les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fiche d'informations à laquelle vous souhaitez associer l'e-mail, en fonction de sa valeur unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association entre l'e-mail et la fiche d'informations. Inclure la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "et le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Les ID de types d'association par défaut sont répertoriés ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "ici"
            }), ", ou vous pouvez récupérer la valeur des types d'associations personnalisés (c'est-à-dire les libellés) via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez récupérer des e-mails individuellement ou en lot. Pour en savoir plus sur la récupération par lot, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer un e-mail individuel par son ID d'e-mail, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), ". Vous pouvez aussi inclure les paramètres suivants dans l'URL de la requête :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules des types d'objets pour lesquels récupérer les ID associés. Les associations spécifiées qui n'existent pas ne seront pas renvoyées dans la réponse. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API des associations."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour demander une liste de tous les e-mails, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/emails"
      }), ". Vous pouvez inclure les paramètres suivants dans l'URL de la requête :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre maximum de résultats à afficher par page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour les e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez mettre à jour des e-mails individuellement ou par lots. Pour mettre à jour un e-mail individuel avec son ID d'e-mail, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le corps de la requête, incluez les propriétés d'e-mail que vous souhaitez mettre à jour : Par exemple, votre corps de requête peut ressembler aux éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk tomorrow\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect!\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignorera les valeurs des propriétés inexistantes ou en lecture seule. Pour effacer une valeur de propriété, passez une chaîne vide comme valeur de propriété dans le corps de la requête."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la mise à jour par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des e-mails existants à des fiches d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer un e-mail à des fiches d'informations, comme un contact et ses entreprises associées, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". L'URL de la requête contient les champs suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emailId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le type d'objet auquel vous souhaitez associer l'e-mail (par exemple, contact ou entreprise)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de la fiche d'informations avec laquelle vous souhaitez associer l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un identifiant unique pour indiquer le type d'association entre l'e-mail et l'autre objet. L'ID peut être représenté numériquement ou en snake case (ex : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_to_contact"
            }), "). Vous pouvez récupérer la valeur via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, l'URL de votre requête peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/emails/17691787884/associations/contact/104901/198"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une association entre un e-mail et une fiche d'informations, faites une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à la même URL que ci-dessus :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Épingler un e-mail sur une fiche d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "épingler un e-mail"
      }), " sur une fiche d'informations afin qu'il reste en haut de la chronologie de la fiche d'informations. L'e-mail doit déjà être associé à la fiche d'informations avant d'être épinglé, et vous ne pouvez épingler qu'une seule activité par fiches d'informations. Pour épingler un e-mail, incluez l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de l'e-mail dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " lors de la création ou de la mise à jour d'une fiche d'informations via les API objet. Découvrez-en davantage sur l'utilisation des API d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "entreprises"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contacts"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "transactions"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " et d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objets personnalisés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer des e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous supprimez un e-mail, il est définitivement supprimé et ", (0, _jsxRuntime.jsx)("u", {
        children: "ne peut pas"
      }), " être restauré. Vous pouvez supprimer des e-mails individuellement ou par lots."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour un e-mail individuel avec son ID d'e-mail, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la suppression par lot, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}