"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620924;
const slug = exports.slug = 'guides/api/overview';
const title = exports.title = 'Comment utiliser les API de HubSpot';
const name = exports.name = "EMEA (FR) vNext Docs DP - How to use HubSpot's APIs";
const metaDescription = exports.metaDescription = "L'API de HubSpot permet aux développeurs de créer des applications et des intégrations personnalisées. Commencez par lire la vue d'ensemble des API et par créer un compte de développeur.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-des-applications",
  "label": "Créer des applications",
  "parent": null
}, {
  "depth": 1,
  "id": "applications-publiques",
  "label": "Applications publiques",
  "parent": "cr%C3%A9er-des-applications"
}, {
  "depth": 1,
  "id": "applications-priv%C3%A9es",
  "label": "Applications privées",
  "parent": "cr%C3%A9er-des-applications"
}, {
  "depth": 0,
  "id": "authentification",
  "label": "Authentification",
  "parent": null
}, {
  "depth": 0,
  "id": "utilisation-et-limites",
  "label": "Utilisation et limites",
  "parent": null
}, {
  "depth": 0,
  "id": "partenaires-d-application-et-marketplace-des-applications",
  "label": "Partenaires d'application et marketplace des applications",
  "parent": null
}, {
  "depth": 0,
  "id": "apprendre-%C3%A0-utiliser-des-api-et-%C3%A0-cr%C3%A9er-des-applications-sur-hubspot-academy",
  "label": "Apprendre à utiliser des API et à créer des applications sur HubSpot Academy",
  "parent": null
}, {
  "depth": 2,
  "id": "liens-rapides",
  "label": "Liens rapides",
  "parent": "apprendre-%C3%A0-utiliser-des-api-et-%C3%A0-cr%C3%A9er-des-applications-sur-hubspot-academy"
}, {
  "depth": 2,
  "id": "documents-associ%C3%A9s",
  "label": "Documents associés",
  "parent": "apprendre-%C3%A0-utiliser-des-api-et-%C3%A0-cr%C3%A9er-des-applications-sur-hubspot-academy"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Guides et ressources sur les API pour le développeur"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les API de HubSpot, dont la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "documentation de référence est disponible ici"
      }), ", vous permettent de créer une application fonctionnelle ou une intégration rapidement et facilement. Voici une vue d'ensemble de ce dont vous aurez besoin pour les utiliser."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des applications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de commencer, vous devez déterminer le type d'application que vous souhaitez créer."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Applications publiques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez créer une application que d'autres utilisateurs de HubSpot en dehors de votre organisation installeront, telle qu'une application répertoriée sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace des applications"
      }), ", vous devez créer une application publique."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous créez une application publique, vous devrez créer un compte de développeur d'applications :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans un premier temps, accédez à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/get-started",
          children: "cette page"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un compte de développeur d'applications"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Authentifiez-vous à l'aide de votre compte Google ou Microsoft, ou saisissez votre adresse e-mail."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Continuez à suivre les instructions de configuration pour créer votre compte de développeur."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pourrez alors créer une nouvelle application, configurer OAuth et créer un environnement de test. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "Commencez à créer maintenant."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Applications privées"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre objectif est de créer une intégration qui ne sera exploitée que par d'autres utilisateurs de votre compte HubSpot, comme une application interne qui peut accéder ou aux données de contact de votre compte ou les modifier, vous pouvez créer une application privée."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la création d'une application ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "privée"
      }), " dans cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentification"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La plupart des points de terminaison d'API de HubSpot sont compatibles avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "jetons d'accès d'application privée"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " À compter du 30 novembre 2022, les clés d'API HubSpot sont obsolètes et ne sont plus prises en charge. L'utilisation continue des clés d'API HubSpot constitue un risque pour la sécurité de votre compte et de vos données. Pendant cette phase, HubSpot peut désactiver votre clé à tout moment."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Veillez plutôt à vous authentifier à l'aide d'un jeton d'accès d'application privée ou d'OAuth. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "cette modification"
        }), " et sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migration d'une intégration de clé d'API"
        }), " pour utiliser une application privée à la place."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous souhaitez lister votre application dans le marketplace des applications de HubSpot ou si plusieurs utilisateurs l'installent, vous devez utiliser OAuth."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous créez une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "application privée"
        }), ", vous pouvez passer des appels à l'aide du jeton d'accès de votre application, qui implémente également OAuth."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation et limites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "règles d'utilisation"
      }), ", les limites et la façon dont vous pouvez vérifier l'utilisation des appels d'API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Partenaires d'application et marketplace des applications"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consultez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "conditions relatives aux listings d'applications"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "créez votre listing d'applications"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Apprendre à utiliser des API et à créer des applications sur HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les API de HubSpot, les comptes de développeur et la réalisation d'appels à l'aide d'OAuth et de clés d'API grâce à ces brèves ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses/integrating-with-hubspot-foundations",
        children: "vidéos HubSpot Academy"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Liens rapides"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
            children: "Créer un compte de développeur"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#developer-test-accounts",
            children: "Configurer un compte de test de développeur"
          }), " pour installer votre application et tester les appels d'API"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Rester au fait des nouveautés en vous abonnant au ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/changelog",
            children: "changelog"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Rejoindre la conversation ou poser des questions dans les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
            children: "forums communautaires des développeurs de HubSpot"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Devenir membre de la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/slack/developer-slack",
            children: "communauté Slack des développeurs"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "Documents de référence des API"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}