"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32079717803;
const slug = exports.slug = 'reference/cms/fields/rich-text-editor';
const title = exports.title = 'Configuring the Rich Text Editor';
const name = exports.name = '[Developers] Configuring the Rich Text Editor';
const metaDescription = exports.metaDescription = 'The Rich Text Editor inside of custom modules now provides the ability for developers to remove components from the configuration toolbar.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "how-to-use-enabled_features",
  "label": "How to use enabled_features",
  "parent": null
}, {
  "depth": 0,
  "id": "feature-listings",
  "label": "Feature listings",
  "parent": null
}, {
  "depth": 1,
  "id": "groups-of-controls",
  "label": "Groups of controls",
  "parent": "feature-listings"
}, {
  "depth": 1,
  "id": "text-formating",
  "label": "Text Formating",
  "parent": "feature-listings"
}, {
  "depth": 1,
  "id": "insert-buttons",
  "label": "Insert Buttons",
  "parent": "feature-listings"
}, {
  "depth": 1,
  "id": "advanced-options",
  "label": "Advanced Options",
  "parent": "feature-listings"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      pre: "pre",
      img: "img",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configuring the Rich Text Editor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As a developer, there are times when WYSIWYG editors provide functionality that, when used incorrectly, can hinder the goal of a unified brand and cause content design and flow issues. The Rich Text Editor inside of custom modules now provides the ability for developers to remove components from the configuration toolbar through the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), " property inside of the fields.json file."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Note: The following applies to custom modules utilizing the rich text field in local development only. Using this feature will not disable the functionality of options removed from the Rich Text Editor, just the display of the options. This is for backward compatibility reasons so existing content is not affected."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How to use enabled_features"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your fields.json file where you have your rich text field object, you can enable certain features by adding the valid toolbar options in an array to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), " property as illustrated below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Rich text field with only Bold, Link, and Image available in the Toolbar\n{\n  \"name\": \"description\",\n  \"label\": \"Description\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"richtext\",\n  \"default\": null,\n  \"enabled_features\": [\"bold\", \"link\", \"image\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The content editor would then see the rich text editor with only the included options enabled as illustrated in the image below:"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Note: Some features, such as the “Clear Styles” button which allows you to revert to the default styling for the editor, will always be enabled and cannot be removed. If the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "enabled_features"
        }), " property is omitted, all features will be shown."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/custom_rte_toolbar.jpg",
        alt: "An example of a RTE toolbar with enabled features."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feature listings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is a list of features which can be enabled individually when using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), " property."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Groups of controls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text color and background color controls."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fonts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Font family and font size controls."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Outdent and indent controls."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lists"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bulleted and numbered lists controls."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bold, italic, and underline controls."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "advanced_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Strikethrough, superscript, subscript, and code format controls."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "glyphs"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Emoji, special character, and icon controls. Not supported in email modules. To add the emoji picker to email modules, use ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#insert-buttons",
              children: "emoji"
            }), " instead."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Text Formating"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "block"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the style switcher dropdown menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_family"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the font switcher dropdown menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the font size dropdown menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bold"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the bold button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "italic"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the italics button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "underline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the underline button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the text color button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the background color button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the alignment button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bulleted_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the bulleted list button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numbered_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the numbered lists button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lineheight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the line-height button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "outdent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the outdent button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the indent button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "strikethrough"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the strikethrough button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "superscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the superscript button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the subscript button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the code format button."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Insert Buttons"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the link button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the image button. Not supported in email modules."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emoji"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the emoji button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "personalize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the personalize toolbar item."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the call-to-action menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the embed menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the video menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the table menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charmap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the special character menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the anchor menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hr"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the horizontal line menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nonbreaking_space"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the non-breaking space menu item under the insert menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the icon menu item under the insert menu."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Advanced Options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "source_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the source code menu item under the advanced menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "visual_blocks"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shows the show blocks menu item under the advanced menu."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}