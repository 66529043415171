"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529654;
const slug = exports.slug = 'guides/cms/storage/hubdb';
const title = exports.title = 'HubDB';
const name = exports.name = 'EMEA (FR) HubDB';
const metaDescription = exports.metaDescription = "Créez du contenu axé sur les données avec HubDB, l'outil de base de données natif de HubSpot. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "architecture-hubdb",
  "label": "Architecture HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-techniques-hubdb",
  "label": "Limites techniques HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-tableau-hubdb",
  "label": "Créer un tableau HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "joindre-plusieurs-tableaux-hubdb",
  "label": "Joindre plusieurs tableaux HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-ajouter-une-colonne-id-%C3%A9tranger-au-tableau-principal",
  "label": "1. Ajouter une colonne ID étranger au tableau principal",
  "parent": "joindre-plusieurs-tableaux-hubdb"
}, {
  "depth": 1,
  "id": "2.-ajouter-des-lignes-de-tableau-%C3%A9tranger-aux-lignes-de-votre-tableau",
  "label": "2. Ajouter des lignes de tableau étranger aux lignes de votre tableau",
  "parent": "joindre-plusieurs-tableaux-hubdb"
}, {
  "depth": 1,
  "id": "3.-restituer-vos-donn%C3%A9es-de-tableau-hubdb-joint",
  "label": "3. Restituer vos données de tableau HubDB joint",
  "parent": "joindre-plusieurs-tableaux-hubdb"
}, {
  "depth": 0,
  "id": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl",
  "label": "Accéder aux données HubDB via HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "obtenir-des-lignes",
  "label": "Obtenir des lignes",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 2,
  "id": "attributs-des-lignes",
  "label": "Attributs des lignes",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 1,
  "id": "obtention-des-m%C3%A9tadonn%C3%A9es-du-tableau",
  "label": "Obtention des métadonnées du tableau",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 1,
  "id": "attributs-de-tableau",
  "label": "Attributs de tableau",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 1,
  "id": "obtenir-des-m%C3%A9tadonn%C3%A9es-de-colonne",
  "label": "Obtenir des métadonnées de colonne",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 2,
  "id": "attributs-de-colonne",
  "label": "Attributs de colonne",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 2,
  "id": "m%C3%A9thodes-de-colonne",
  "label": "Méthodes de colonne",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 2,
  "id": "colonnes-de-texte-enrichi",
  "label": "Colonnes de texte enrichi",
  "parent": "acc%C3%A9der-aux-donn%C3%A9es-hubdb-via-hubl"
}, {
  "depth": 0,
  "id": "tutoriels-et-ressources-hubdb",
  "label": "Tutoriels et ressources HubDB",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      em: "em",
      img: "img",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h3: "h3",
      pre: "pre",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB est un outil qui vous permet de créer des tableaux pour stocker des données dans des lignes, des colonnes et des cellules, un peu comme dans une feuille de calcul. Vous pouvez personnaliser les colonnes, les lignes et les autres paramètres d'un tableau HubDB en fonction de vos besoins. Par exemple, vous pouvez utiliser un tableau HubDB pour :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "stocker les commentaires d'un mécanisme externe et les récupérer ultérieurement ;"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["stocker des données structurées que vous pouvez utiliser pour ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "créer des pages CMS dynamiques"
        }), " (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Pro"
        }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), " uniquement) ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["stocker les données à utiliser dans un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "e-mail programmable"
        }), " (", (0, _jsxRuntime.jsx)(_components.em, {
          children: "**_Marketing Hub"
        }), "**_ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), " uniquement)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-example0.png",
        alt: "hubdb-table-example0"
      }), "Les tableaux HubDB sont accessibles à la fois ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "dans HubSpot"
      }), " et via l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "API HubDB"
      }), ", et vous pouvez récupérer les données d'un tableau de plusieurs manières, en fonction de votre cas d'utilisation. Pour obtenir des données d'un tableau HubDB, vous pouvez :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["interroger les données en externe via l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/cms/hubdb",
          children: "API HubDB"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "utiliser les balises HubL de HubSpot pour transférer les données dans le CMS en tant que contenu structuré ;"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["utiliser l'API HubDB avec des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "fonctions sans serveur"
        }), " pour offrir une expérience d'application web interactive."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Pour ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
            children: "utiliser les données HubDB dans les pages"
          }), ", vous avez besoin de ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Pro"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Entreprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Pour utiliser les données HubDB dans des ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/email/create-programmable-emails",
            children: "e-mails programmables"
          }), ", vous avez besoin de ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Entreprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["La publication, la modification et la visualisation de tableaux existants nécessitent ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#website-tools",
            children: "des autorisations HubDB"
          }), ". La création, le clonage, la suppression et la modification des paramètres d'un tableau HubDB nécessitent les autorisations ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Paramètres du tableau HubDB"
            })
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Architecture HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tableau HubDB se compose de lignes, de colonnes et de cellules, comme une feuille de calcul."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tableaux :"
        }), " un tableau est un arrangement bidimensionnel de lignes et de colonnes. Lorsqu'un tableau est créé, il se voit attribuer un ID globalement unique que vous pouvez utiliser lorsque vous avez besoin de spécifier un tableau dans HubL ou via l'API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lignes :"
        }), " les lignes sont des tranches horizontales d'un tableau. Dans une application de feuille de calcul, les lignes sont représentées par des chiffres, en commençant par 1. Chaque ligne du tableau se voit attribuer un identifiant unique lors de sa création. Chaque ligne d'un tableau comprend des colonnes par défaut :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Colonne"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID numérique unique, attribué automatiquement pour cette ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un horodatage de la date de création de cette ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Avec des pages dynamiques, cette chaîne est le dernier segment du chemin d'accès de l'URL de la page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Avec des pages dynamiques, il s'agit du titre de la page."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les colonnes de la zone de texte enrichi dans HubDB sont limitées à 65 000 caractères. Pour en savoir plus, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/hubdb-rich-text-area-limited-to-65000-characters-and-a-new-meetings-module",
          children: "consultez l'annonce de changement"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Colonnes :"
        }), " Les colonnes sont des tranches verticales d'un tableau. Chaque colonne a un type, qui est utilisé pour stocker des types de données. Un tableau peut inclure autant de colonnes que vous le souhaitez, et chacune se voit attribuer un ID unique au niveau global lors de la création. L'ID de colonne commence à la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1"
        }), ", mais n'est pas nécessairement séquentiel et ne peut pas être réutilisé. Les types de colonnes comprennent :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Texte"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Texte enrichi"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "URL"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Image"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "select"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Sélection multiple"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Date"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Date et heure"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Nombre"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Devise"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "case à cocher"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Emplacement (longitude, latitude)"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ID étranger"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Vidéo"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cellules :"
        }), " Les cellules stockent les valeurs à l'intersection d'une ligne et d'une colonne. Les cellules peuvent être lues ou mises à jour individuellement ou en tant que partie d'une ligne. Définir la valeur d'une cellule sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " équivaut à supprimer la valeur de la cellule."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites techniques HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tenez compte des limites techniques suivantes de HubDB :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites de compte :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1 000 tableaux HubDB par compte"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1 million de lignes HubDB par compte"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites de tableau :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "250 colonnes par tableau"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10 000 lignes par tableau HubDB"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caractères par nom de tableau"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caractères par libellé de tableau"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites de colonne :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "65 000 caractères par colonne de texte enrichi"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10 000 caractères par colonne de texte"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caractères par nom de colonne"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caractères par libellé"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "300 caractères par description de colonne"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caractères par option sélectionnable dans une colonne"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites de page :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 appels à la fonction HubL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb_table_rows"
            }), " par page CMS"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "pages dynamiques"
            }), " utilisant le même tableau HubDB"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les HubDB avec des pages dynamiques activées doivent avoir des chemins en minuscules afin que les URL de ces pages puissent être insensibles à la casse."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un tableau HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez créer des tableaux HubDB ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "via l'interface utilisateur de HubSpot"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#create-a-new-table",
        children: "l'API HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les nouveaux tableaux créés ont le statut de brouillon. Ils ne peuvent pas être utilisés pour sortir des données via HubL ou l'API tant que vous n'avez pas publié le tableau. Lors de la création d'un tableau, vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "gérer ses paramètres"
      }), ", tels que l'autorisation d'accès à l'API publique et si ses données seront utilisées pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "créer des pages dynamiques"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Joindre plusieurs tableaux HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les tableaux HubDB peuvent être joints à l'aide du type de colonne ID étranger, qui vous permet de restituer les données combinées à partir de plusieurs tableaux. Cela peut être utile lorsque certaines données peuvent être partagées entre plusieurs magasins de données, ce qui permet un tableau de données centralisé de ces informations, qui peut ensuite être consulté dans plusieurs autres magasins de données de tableaux HubDB."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous comment joindre des tableaux HubDB."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Ajouter une colonne ID étranger au tableau principal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichiers et modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Localisez le tableau auquel vous souhaitez joindre un tableau, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modifier"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En haut à droite, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modifier"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter une colonne"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "libellé"
        }), " et un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " pour la nouvelle colonne."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Type de colonne"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ID étranger"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sélectionner un tableau"
        }), " et sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tableau"
        }), " que vous souhaitez joindre à votre tableau actuel."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sélectionner une colonne"
        }), ", puis sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "colonne"
        }), " dans le tableau à joindre que vous avez sélectionnée pour être visible dans le champ ID étranger."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter une colonne"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " La valeur que vous avez choisie pour ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sélectionner une colonne"
        }), " indique uniquement la valeur de colonne que vous voyez dans le champ ID étranger de l'interface utilisateur HubDB. Toutes les colonnes du tableau sont disponibles lors de la restitution des tableaux HubDB joints."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id.png",
        alt: "hubdb_foreign_id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Ajouter des lignes de tableau étranger aux lignes de votre tableau"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez une colonne ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ID étranger"
      }), ", vous aurez un champ de colonne à sélection multiple sur chaque ligne de votre tableau HubDB, ce qui vous permettra de sélectionner les lignes d'un tableau étranger."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le champ ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sélectionner une colonne"
      }), " que vous avez choisi sera utilisé dans ce champ à sélection multiple pour identifier la ligne que vous sélectionnez dans le tableau étranger. Dans l'exemple ci-dessous, les valeurs à sélection multiple pour le champ ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Expertise table join"
      }), " sont les valeurs disponibles dans la colonne ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Nom"
      }), " du tableau HubDB étranger."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id_ui.png",
        alt: "hubdb_foreign_id_ui"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Il est recommandé de modifier le champ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sélectionner une colonne"
        }), " de votre colonne ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID étranger"
        }), " et de simplement mettre à jour les valeurs de la colonne qui s'affichera dans l'interface utilisateur HubDB."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Restituer vos données de tableau HubDB joint"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Toutes les données de ligne d'un tableau étranger sont accessibles via HubL pour le rendu, pas seulement le champ ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sélectionner une colonne"
      }), ". Les données de ligne étrangère HubDB sont accessibles via une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "boucle imbriquée"
      }), ", bouclant toutes les lignes étrangères associées à une ligne individuelle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(tableId, filterQuery) %}\n  the name for row {{ row.hs_id }} is {{ row.name }}\n  {% for foreign_row in row.foreign_table %}\n  \tthe name for foreign row {{ foreign_row.hs_id }} is {{ foreign_row.name }}\n  {% endfor %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Accéder aux données HubDB via HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En utilisant HubL, vous pouvez extraire des données HubDB pour les utiliser en tant que contenu structuré sur les pages de site web. Découvrez comment récupérer des données de tableau, de ligne et de colonne à l'aide de HubL."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les données ébauchées du tableau HubDB apparaîtront dans l'éditeur de page et les aperçus en direct, mais seul le contenu HubDB publié apparaîtra sur la page en direct. Si vous voyez des données de tableau apparaître dans l'éditeur ou un aperçu qui n'apparaît pas sur la page en direct, vérifiez que le tableau a été publié depuis l'ajout de ces données de tableau."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtenir des lignes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour lister les lignes d'un tableau, utilisez la fonction HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: "hubdb_table_rows()"
      }), ". Vous pouvez accéder à un tableau via son ID ou son nom. Il est recommandé de référencer un tableau HubDB par son nom, car cela peut faciliter la portabilité du code entre les comptes HubSpot. Le nom de tableau immuable est défini lors de la création d'un nouveau tableau et peut être retrouvé à tout moment en sélectionnant ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Actions > Gérer les paramètres"
      }), " dans l'éditeur de tableau. L'ID d'un tableau peut être trouvé dans la barre d'adresse de l'éditeur de tableau ou dans le tableau de bord des tableaux HubDB sous la colonne ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Table%20Create.png",
        alt: "Capture d'écran de la fenêtre modale de création de tableau"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici un exemple d'utilisation de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows()"
      }), " pour récupérer des données."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(<tableId or name>, <filterQuery>) %}\n  the value for row {{ row.hs_id }} is {{ row.<column name> }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Par défaut, 1 000 lignes au maximum sont renvoyées. Pour récupérer plus de lignes, spécifiez une ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), " dans la demande de fonction. Par exemple :"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hudb_table_rows (12345, \"random()&limit=1500\")"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " utilise la même syntaxe que l'API HTTP. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows(123, \"employees__gt=10&orderBy=count\")"
      }), " renvoie une liste de lignes où la colonne « employees » est supérieure à 10, ordonnée selon la colonne « count ». Une liste complète des paramètres facultatifs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " est ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "disponible ici."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Au lieu d'utiliser plusieurs requêtes de lignes avec différents paramètres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), ", vous devez effectuer une seule requête et utiliser les filtres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "selectattr()"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rejectattr()"
      }), " pour filtrer vos lignes :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set all_cars = hubdb_table_rows(<tableId or name>) %}\n\n{% set cars_with_windows = all_cars|selectattr('windows') %}\n\n{% set teslas = all_cars|selectattr('make','equalto','tesla') %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour obtenir une seule ligne, utilisez la fonction HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_row()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set row = hubdb_table_row(<tableId or name>, <rowId>) %}\nthe value for {{ row.hs_id }} is {{ row.<column name> }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les noms des colonnes intégrées et personnalisées sont insensibles à la casse. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HS_ID"
      }), " fonctionnera de la même manière que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_id"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Attributs des lignes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'identifiant unique au niveau mondial pour cette ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lorsque vous utilisez des pages dynamiques, cette chaîne est la valeur de la colonne Chemin de page et le dernier segment du chemin d'accès de l'URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lorsque vous utilisez des pages dynamiques, cette chaîne est la valeur de la colonne Titre de la page pour la ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Horodatage Unix de la date de création de la ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lorsque vous utilisez des pages dynamiques, il s'agit de l'ID de l'autre tableau qui alimente les données de la ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.column_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtenez la valeur de la colonne personnalisée par le nom de la colonne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row[\"column name\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtenez la valeur de la colonne personnalisée par le nom de la colonne."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtention des métadonnées du tableau"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour obtenir les métadonnées d'un tableau, notamment son nom, ses colonnes, sa dernière mise à jour, etc., utilisez la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table(<tableId or name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Attributs de tableau"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les attributs listés ci-dessous sont en référence à la variable à laquelle la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table()"
        }), " a été attribuée dans le code ci-dessus. Votre variable peut être différente.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Remarque : Il est recommandé de l'attribuer à une variable pour une utilisation plus facile. Si vous ne voulez pas faire cela, vous pouvez utiliser", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table(<tableId>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID du tableau."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom du tableau."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.columns"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste d'informations sur les colonnes. Vous pouvez utiliser une ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/loops",
              children: "boucle for"
            }), " pour itérer à travers les informations disponibles dans cet attribut."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'horodatage de la date de création de la table."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.published_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'horodatage de la date de publication de ce tableau."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.updated_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'horodatage de la dernière mise à jour de ce tableau."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.row_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre de lignes dans le tableau."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtenir des métadonnées de colonne"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table_column(<tableId or name>, <columnId or column name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_column"
      }), " peut être utilisée pour obtenir des informations sur une colonne du tableau telles que son libellé, son type et ses options."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Attributs de colonne"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les attributs listés ci-dessous sont en référence à la variable à laquelle la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_column()"
        }), " a été attribuée dans le code ci-dessus. Votre variable peut être différente.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Remarque : Il est recommandé de l'attribuer à une variable pour une utilisation plus facile. Si vous ne voulez pas faire cela, vous pouvez utiliser", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table_column(<tableId>,<columnId or column name>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de la colonne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de la colonne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le libellé à utiliser pour la colonne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le type de cette colonne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pour les colonnes de type select, il s'agit d'un mappage de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "optionId"
            }), " vers les informations ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.foreignIds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pour les colonnes de type foreignId, une liste de foreignIds (avec les propriétés ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ")."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Méthodes de colonne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Méthode"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "getOptionByName(\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour certains types de colonnes, vous pouvez obtenir des informations sur les options par leur nom."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Colonnes de texte enrichi"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le type de colonne ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "richtext"
      }), " fonctionne de la même manière que le champ de texte enrichi pour les modules."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les données sont stockées en HTML et l'interface utilisateur HubDB fournit une interface d'édition de texte. Cependant, lors de la modification d'un tableau HubDB via l'interface utilisateur de HubSpot, vous ne pouvez pas modifier directement le code source. Cela permet d'éviter les situations où un utilisateur non expert pourrait saisir un code HTML non valide, ce qui élimine tout problème indésirable d'apparence ou de fonctionnalité de votre site. Pour les situations où vous avez besoin d'un code d'intégration ou d'un code HTML plus personnalisé, vous pouvez utiliser la fonction d'intégration de l'éditeur de texte enrichi pour placer votre code personnalisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriels et ressources HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "Application d'inscription aux événements"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Créer une page dynamique pour les membres de l'équipe avec HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Ajouter des vidéos aux pages dynamiques"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Créer des pages dynamiques multi-niveaux via HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}