"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102629641400;
const slug = exports.slug = 'guides/crm/overview';
const title = exports.title = 'CRM開発ツールの概要（ベータ版）';
const name = exports.name = 'CRM開発ツールの概要（ベータ版）';
const metaDescription = exports.metaDescription = 'HubSpot APIを使うと、カスタムアプリを開発したり、カスタム連携を行ったりすることができます。まずはAPIの概要と開発者アカウントの作成について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crm%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB",
  "label": "CRM開発ツール",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
  "label": "プロジェクト",
  "parent": "crm%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "非公開アプリ",
  "parent": "crm%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB"
}, {
  "depth": 1,
  "id": "ui%E6%8B%A1%E5%BC%B5%E6%A9%9F%E8%83%BD",
  "label": "UI拡張機能",
  "parent": "crm%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0",
  "label": "サーバーレス関数",
  "parent": "crm%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E7%94%A8%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9",
  "label": "開発用サンドボックス",
  "parent": "crm%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB"
}, {
  "depth": 1,
  "id": "github%E9%80%A3%E6%90%BA",
  "label": "GitHub連携",
  "parent": "crm%E9%96%8B%E7%99%BA%E3%83%84%E3%83%BC%E3%83%AB"
}, {
  "depth": 0,
  "id": "%E4%BD%BF%E3%81%84%E5%A7%8B%E3%82%81%E3%82%8B%E3%81%AB%E3%81%AF",
  "label": "使い始めるには",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E8%B3%87%E6%96%99",
  "label": "その他の資料",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      h2: "h2",
      h3: "h3",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CRM開発ツールの概要（ベータ版）"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCRM開発ツールを使用すると、開発用サンドボックス、バージョン管理、GitHub連携などの機能を備えたUI拡張機能を使ってCRMをカスタマイズし、開発プロセスを合理化することができます。まずローカル環境でこれらのツールを使用し、UI拡張機能を作成してさまざまなCRMレコードに展開することで、ユーザーエクスペリエンスをカスタマイズし、必要に応じてデータを送信および取得できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、外部ソースからデータを取得するアプリカードを作成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/custom-extension-example.png",
        alt: "custom-extension-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、CRM開発ツールとその基本的な使い方について詳しく説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM開発ツール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロジェクト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロジェクトは、CRM開発ツールを使用して作成される最上位レベルのコンテナーです。プロジェクトを使用すると、CLIを使って非公開アプリ、UI拡張機能、サーバーレス関数をローカルに作成して展開できます。アカウントに展開した後は、HubSpotでプロジェクトとそのアプリおよびUI拡張機能を表示し、管理することができます。これには、ビルド履歴の表示とAPI呼び出しの監視が含まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "プロジェクトの作成"
      }), "についてご覧ください。またHubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "サンプルプロジェクト"
      }), "のリストには、可能な操作を示す例が順次掲載されていきます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "非公開アプリ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "非公開アプリでは、認証用に非公開アプリのアクセストークンを使用することでデータを送信および取得できます。また非公開アプリは、UI拡張機能のバックエンドとして動作するサーバーレス関数も実行します。1つのプロジェクトに1つの非公開アプリを含めることができ、それぞれの非公開アプリには複数のUI拡張機能とサーバーレス関数を含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-private-apps-with-projects",
        children: "非公開アプリの作成"
      }), "ついてご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["プロジェクトを使って作成された非公開アプリでは、現在、UI拡張機能の作成のみが可能です。HubSpotの他の拡張機能と連携するための非公開アプリを作成したい場合には、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "さまざまなタイプのアプリおよびサポートされている機能"
        }), "で詳細をご確認ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "UI拡張機能"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI拡張機能は、HubSpotアカウントのユーザーが表示および操作することができるカスタムUI要素です。現時点で可能なUI拡張機能には、CRMレコード用およびヘルプデスク用のアプリカードが含まれます。例えば、ユーザーが任意のコンタクトレコードから外部データベースにフォームデータを送信できるようにするカードや、カスタマーサポート担当者がヘルプデスクUIでオープン状態のチケットに対してアクションを実行できるようにするカードを作成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI拡張機能はプロジェクトを使用して作成され、フロントエンドとバックエンドから成ります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**UI拡張機能フロントエンド：**ユーザーに表示される拡張機能の部分。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "HubSpot提供のコンポーネント"
        }), "で構成されるアプリカード形式です。ユーザーは情報を表示することに加えて、コンポーネントと対話してさまざまなアクションを実行できます。フロントエンドを作成するにはReactまたはTypeScriptを使用します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**UI拡張機能バックエンド：**UI拡張機能でデータを送信および取得してコンポーネントに表示できるようにする", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/serverless-functions",
          children: "サーバーレス関数"
        }), "です。プロジェクトの非公開アプリによって強化されたサーバーレス関数を、必要に応じて複数のコンポーネントで再利用できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロジェクトを使用して作成できるアプリカードは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/extensions/overview",
        children: "HubSpotの他のCRMカードAPI拡張機能"
      }), "とは別のものであり、両者を互換的に作成することはできません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "UI拡張機能の仕組み"
      }), "についてご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サーバーレス関数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数は、サーバーサイドJavaScriptを実行してUI拡張機能にバックエンド機能を提供します。サーバーレス関数は非公開アプリの中に格納されます。サーバーレス関数は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "main"
      }), "関数をエクスポートする1つ以上のJavaScriptファイルが入った1つのフォルダーと、関数を登録して設定する1つの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "ファイルから成ります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/serverless-functions",
        children: "プロジェクトを使用したサーバーレス関数の作成"
      }), "についてご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "プロジェクトで使用されるサーバーレス関数の概念と、CMS Hubで使用されるサーバーレス関数の概念は似ていますが、両者は異なります。相違点には次のようなものがあります。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "hs create function"
          }), "コマンドを使用してプロジェクト内にサーバーレス関数を作成することはできません。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "プロジェクトで作成されたサーバーレス関数をCMSページに使用することはできません。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "プロジェクトで作成されたサーバーレス関数は、デザインマネージャーには表示されません。"
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["CMSページ用のサーバーレス関数を作成するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "CMS開発者向けドキュメント"
        }), "を参照してください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発用サンドボックス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発用サンドボックスを使用すると、軽量のテスト環境でプロジェクトを開発し、プロジェクトのコンポーネントが期待どおりに機能することを確認した後で、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "標準サンドボックスまたは本番アカウント"
      }), "に展開することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発サンドボックスはCLIを介して作成され、本番HubSpotアカウント内でアクセス可能です。開発用サンドボックスの作成時には一部のアカウントアセット（全てではない）が同期され、標準サンドボックスと比べてより多くの制限があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/developer-projects-setup",
        children: "開発用サンドボックスのセットアップ"
      }), "についてご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "GitHub連携"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["バージョン管理にGitHubを使用するのが適切と考えられる場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/link-a-github-repository-to-a-project",
        children: "GitHubリポジトリーをプロジェクトに"
      }), "接続すると、変更を接続先リポジトリーにプッシュしたときにプロジェクトのビルドが自動的にトリガーされます。これにより、単独で作業する場合も、チームで作業する場合も、GitHubツールとワークフローを使用して開発を合理化できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "使い始めるには"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sales HubまたはService Hub ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enterprise"
      }), "アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/user-management/hubspot-user-permissions-guide#super-admin",
        children: "スーパー管理者"
      }), "は、ご使用のHubSpotアカウントの「製品の最新情報」ページから次のようにしてこのベータ版に参加できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HubSpotアカウントで、右上隅の", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "アカウント名"
          }), "をクリックし、［製品の最新情報］****をクリックします。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "左側のサイドバーで、［ベータ版］を選択します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["ベータ版のリストで、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ReactをフロントエンドとするUI拡張機能を作成するためのCRM開発ツール"
          }), "（ベータ版）を検索するかスクロールして見つけ、［ベータ版を使ってみる］をクリックします。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/crm-development-tools-join-beta.png",
            alt: "crm-development-tools-join-beta"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ベータ版に参加した後、次のいずれかの方法で使用を開始します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "クイックスタートガイド"
        }), "に従い、実際に機能するサンプル アプリ カードをすばやく作成して展開します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/sample-projects/overview",
          children: "HubSpotのサンプルプロジェクト"
        }), " を調べて、可能な操作の例を確認します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["まず", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "HubSpotプロジェクトガイド"
        }), "を読み、独自のプロジェクトを全く最初から作成します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "その他の資料"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/CRM-Development-Tools-Beta/gh-p/crm-development-tools-beta",
          children: "CRM開発ツール（HubSpotコミュニティー）"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/crm-development-tools-for-hubspot-developers-beta",
          children: "CRM開発ツール（HubSpot開発者ブログ）"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}