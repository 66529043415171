"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125402;
const slug = exports.slug = 'guides/cms/content/templates/types/blog';
const title = exports.title = 'Marcador de la plantilla del blog';
const name = exports.name = 'EMEA ES Blog template markup';
const metaDescription = exports.metaDescription = 'Las plantillas de listados y post de blog pueden utilizarse para personalizar la apariencia del blog de un sitio. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/Boilerplate%20Blog%20Templates.png';
const featuredImageAltText = exports.featuredImageAltText = 'Blog settings template selection';
const toc = exports.toc = [{
  "depth": 0,
  "id": "renderizar-las-p%C3%A1ginas-de-anuncios-y-los-posts-con-una-sola-plantilla",
  "label": "Renderizar las páginas de anuncios y los posts con una sola plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "lista-de-blogs-para-el-bucle",
  "label": "Lista de blogs para el bucle",
  "parent": null
}, {
  "depth": 0,
  "id": "marcador-de-plantillas-de-listados",
  "label": "Marcador de plantillas de listados",
  "parent": null
}, {
  "depth": 0,
  "id": "sentencia-if-blog_author",
  "label": "Sentencia If blog_author",
  "parent": null
}, {
  "depth": 0,
  "id": "sentencia-if-tag",
  "label": "Sentencia If tag",
  "parent": null
}, {
  "depth": 0,
  "id": "sentencia-if-not-simple_list_page",
  "label": "Sentencia If not simple_list_page",
  "parent": null
}, {
  "depth": 0,
  "id": "paginaci%C3%B3n-del-listado",
  "label": "Paginación del listado",
  "parent": null
}, {
  "depth": 0,
  "id": "marcado-del-post-del-blog",
  "label": "Marcado del post del blog",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      a: "a",
      pre: "pre",
      strong: "strong",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Marcador de la plantilla del blog"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los blogs de HubSpot consisten en páginas de listado de blogs y en los posts individuales del blog. Además de listar los posts individuales del blog, la plantilla de listado del blog también se utiliza para renderizar las páginas de listado de autores y etiquetas. Puedes crear una sola plantilla para representar todas las páginas de listados y post de blog, o puedes crear dos plantillas separadas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aprende sobre el marcador de la plantilla del blog, los componentes de la plantilla y las opciones de personalización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Renderizar las páginas de anuncios y los posts con una sola plantilla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una plantilla que muestre las páginas de listados y post, agrega la anotación ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: blog"
      }), " al principio de tu archivo de plantilla. Si se utiliza una plantilla para representar ambas cosas, se utilizará una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "sentencia if"
      }), " que evalúe si el usuario está viendo una página de anuncios o un post individual. Si estás utilizando el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "administrador de diseño de arrastrar y soltar"
      }), ", esta sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " está incorporada en la interfaz de usuario de los botones del módulo de contenido del blog."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro de la sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", escribirás tanto el código del post como el del listado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_listing_view %}\n    Markup for blog listing template\n{% else %}\n    Markup for blog post template\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alternativamente, puedes elegir tener una plantilla separada para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates#blog-post",
        children: "post de blog"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates#blog-listing",
        children: "páginas de anuncios"
      }), ". Esto permite que tu código sea más limpio y fácil de leer como desarrollador, y hace que las plantillas sean más fáciles de seleccionar para los creadores de contenido. La plantilla del CMS tiene una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-index.html",
        children: "plantilla de listado"
      }), " y una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-post.html",
        children: "plantilla de post"
      }), " separadas. Si construyes plantillas separadas para post y listados, esta comprobación de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_listing_view"
      }), " no es necesaria, solo tienes que asegurarte de seleccionar las plantillas separadas en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/templates",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configuración > Sitio web > Blog > Plantillas"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Boilerplate%20Blog%20Templates.png",
        alt: "Selección de la plantilla de configuración del blog"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lista de blogs para el bucle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El listado de posts se genera mediante un bucle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for"
      }), " que itera a través de los posts de tu blog. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Contents"
      }), " es una secuencia predefinida de contenidos que contiene todos los posts de ese blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    <div class=\"post-item\">\n        Post item markup that renders with each iteration.\n    </div>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Marcador de plantillas de listados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El contenido de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "página de listado del blog"
      }), " para el bucle se renderiza con el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-index.html",
        children: "siguiente marcador"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    {# On the blog homepage the first post will be featured above older posts #}\n    {% if (loop.first && current_page_num == 1 && !topic) %}\n    <div class=\"blog-index__post blog-index__post--large\">\n        <a class=\"blog-index__post-image blog-index__post-image--large\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--large\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content }}\n        </div>\n    </div>\n    {% else %}\n    <div class=\"blog-index__post blog-index__post--small\">\n        <a class=\"blog-index__post-image blog-index__post-image--small\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--small\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content|truncatehtml(100) }}\n        </div>\n    </div>\n    {% endif %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sentencia If blog_author"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro del marcador estándar del listado de blogs de HubSpot, hay una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if blog_author"
      }), " . Esta sentencia se evalúa como verdadera cuando se mira una página de listado de autores. Una página de listado de autores es una página de posts de un solo autor. La plantilla incluye el nombre del autor, su biografía y sus cuentas en las redes sociales."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if blog_author %}\n  <div class=\"blog-header\">\n    <div class=\"blog-header__inner\">\n      {% if blog_author.avatar %}\n      <div class=\"blog-header__author-avatar\" style=\"background-image: url('{{ blog_author.avatar }}');\"></div>\n      {% endif %}\n      <h1 class=\"blog-header__title\">{{ blog_author.display_name }}</h1>\n      <h4 class=\"blog-header__subtitle\">{{ blog_author.bio }}</h4>\n      {% if blog_author.has_social_profiles %}\n        <div class=\"blog-header__author-social-links\">\n          {% if blog_author.website %}\n            <a href=\"{{ blog_author.website }}\" target=\"_blank\">\n              {% icon name=\"link\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.facebook %}\n            <a href=\"{{ blog_author.facebook }}\" target=\"_blank\">\n              {% icon name=\"facebook-f\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.linkedin %}\n            <a href=\"{{ blog_author.linkedin }}\" target=\"_blank\">\n              {% icon name=\"linkedin-in\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.twitter %}\n            <a href=\"{{ blog_author.twitter }}\" target=\"_blank\">\n              {% icon name=\"twitter\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n        </div>\n      {% endif %}\n    </div>\n  </div>\n{% else %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sentencia If tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de la sentencia if ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_author"
      }), ", que define el marcador que solo debe aparecer en las páginas del listado de autores, existe una variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tag"
      }), " que puede utilizarse para que solo aparezca el código en una página del listado de temas del blog. El siguiente ejemplo es un fragmento que utiliza la variable de título de la página para imprimir automáticamente el nombre de la etiqueta en la parte superior de una página de listado de etiquetas. Este fragmento podría agregarse al código del listado de tu blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if tag %}\n    <h3>Posts about {{ page_meta.html_title|split(\" | \")|last }}</h3>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sentencia If not simple_list_page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro del bucle for, hay una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " que determina lo que hay que representar en un listado simple o regular."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un listado simple es un listado de todos tus posts y no admite la paginación. El listado simple no se ve afectado por la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/building-blocks/website-settings#number-of-posts-per-listing-page",
          children: "configuración del límite de post del blog"
        }), " y generalmente solo contiene enlaces a los 200 posts más recientes del blog"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El listado regular itera a través del número de posts especificado por la configuración del blog del listado de post y pagina en consecuencia."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La dirección de tu página de listado simple es la URL de tu blog con ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/all"
      }), " agregado al final de la ruta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente código es una versión simplificada de esta sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " que define lo que debe ser iterado en una página de listado simple. Observa que la sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " utiliza la lógica inversa; por lo tanto, el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " define la vista del listado simple. Opcionalmente se puede utilizar una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/if-statements#unless-statements",
        children: "sentencia unless"
      }), " en su lugar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if not simple_list_page %}\n    Iterated post markup for regular listing\n{% else %}\n    <h2 class=\"post-listing-simple\"><a href=\"{{content.absolute_url}}\">{{ content.name }}</a></h2>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paginación del listado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las páginas del listado del blog tienen una paginación autogenerada. Tu plantilla de listado puede incluir la lógica para permitir a los visitantes navegar fácilmente a través de tus posts de blog. El ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "blog boilerplate"
      }), " logra una paginación simple y numérica a través del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/modules/blog-pagination.module/module.html",
        children: "siguiente marcador"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if contents.total_page_count > 1 %}\n<div class=\"blog-pagination\">\n    {% set page_list = [-2, -1, 0, 1, 2] %}\n    {% if contents.total_page_count - current_page_num == 1 %}{% set offset = -1 %}\n    {% elif contents.total_page_count - current_page_num == 0 %}{% set offset = -2 %}\n    {% elif current_page_num == 2 %}{% set offset = 1 %}\n    {% elif current_page_num == 1 %}{% set offset = 2 %}\n    {% else %}{% set offset = 0 %}{% endif %}\n\n    <a class=\"blog-pagination__link blog-pagination__prev-link {{ \"blog-pagination__prev-link--disabled\" if !last_page_num }}\" href=\"{{ blog_page_link(last_page_num) }}\">\n    {% icon name=\"chevron-left\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    Prev\n    </a>\n    {% for page in page_list %}\n    {% set this_page = current_page_num + page + offset %}\n    {% if this_page > 0 and this_page <= contents.total_page_count %}\n        <a class=\"blog-pagination__link blog-pagination__number-link {{ \"blog-pagination__link--active\" if this_page == current_page_num }}\" href=\"{{ blog_page_link(this_page) }}\">{{ this_page }}</a>\n    {% endif %}\n    {% endfor %}\n    <a class=\"blog-pagination__link blog-pagination__next-link {{ \"blog-pagination__next-link--disabled\" if !next_page_num }}\" href=\"{{ blog_page_link(current_page_num + 1) }}\">\n    Next\n    {% icon name=\"chevron-right\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    </a>\n</div>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Marcado del post del blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos los posts de un blog son generados por una única plantilla de blog. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content"
      }), " es un objeto de datos predefinido que contiene información sobre el post del blog solicitado. Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "Boilerplate posts"
      }), " se presentan con los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-post.html",
        children: "siguientes marcadores"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"content-wrapper\">\n  <div class=\"blog-post\">\n    <h1>{{ content.name }}</h1>\n    <div class=\"blog-post__meta\">\n      <a href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">\n        {{ content.blog_post_author.display_name }}\n      </a>\n      <div class=\"blog-post__timestamp\">\n        {{ content.publish_date_localized }}\n      </div>\n    </div>\n    <div class=\"blog-post__body\">\n      {{ content.post_body }}\n    </div>\n    {% if content.tag_list %}\n    <div class=\"blog-post__tags\">\n      {% icon name=\"tag\" style=\"SOLID\" %}\n      {% for tag in content.tag_list %}\n        <a class=\"blog-post__tag-link\" href=\"{{ blog_tag_url(group.id, tag.slug) }}\">{{ tag.name }}</a>{% if not loop.last %},{% endif %}\n      {% endfor %}\n    </div>\n    {% endif %}\n  </div>\n  <div class=\"blog-comments\">\n    {% module \"blog_comments\" path=\"@hubspot/blog_comments\", label=\"Blog Comments\" %}\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La información del autor del post del blog también está disponible dentro de los datos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<img alt=\"{{ content.blog_post_author.display_name }}\" src=\"{{ content.blog_post_author.avatar }}\">\n<h3>Written by <a class=\"author-link\" href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">{{ content.blog_post_author.display_name }}</a></h3>\n<p>{{ content.blog_post_author.bio }}</p>\n{% if content.blog_post_author.has_social_profiles %}\n    <div class=\"hs-author-social-section\">\n        <div class=\"hs-author-social-links\">\n            {% if content.blog_post_author.facebook %}\n                <a href=\"{{ content.blog_post_author.facebook }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-facebook\">Facebook</a>\n            {% endif %}\n            {% if content.blog_post_author.linkedin %}\n                <a href=\"{{ content.blog_post_author.linkedin }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-linkedin\">LinkedIn</a>\n            {% endif %}\n            {% if content.blog_post_author.twitter %}\n                <a href=\"{{ content.blog_post_author.twitter }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-twitter\">Twitter</a>\n            {% endif %}\n            {% if content.blog_post_author.google_plus %}\n                <a href=\"{{ content.blog_post_author.google_plus }}?rel=author\" target=\"_blank\" class=\"hs-author-social-link hs-social-google-plus\">Google+</a>\n            {% endif %}\n        </div>\n    </div>\n{% endif %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}