"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31316286986;
const slug = exports.slug = 'guides/apps/marketplace/certification-requirements';
const title = exports.title = 'アプリマーケットプレイス｜アプリの認定に必要な条件｜HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (JA) | WEB | App Certification Requirements | 202007';
const metaDescription = exports.metaDescription = 'HubSpotのアプリマーケットプレイスでのアプリ認定の取得をお考えのアプリパートナーさまのために、必要な条件をご紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%A6%82%E8%A6%81",
  "label": "概要",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%AA%8D%E5%AE%9A%E5%8F%96%E5%BE%97%E3%81%AE%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88",
  "label": "認定取得のメリット",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96",
  "label": "審査基準",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%A8%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC",
  "label": "セキュリティーとプライバシー",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF%E3%81%AE%E4%BE%8B",
  "label": "フィードバックの例",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 1,
  "id": "%E4%BF%A1%E9%A0%BC%E6%80%A7%E3%81%A8%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9",
  "label": "信頼性とパフォーマンス",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF%E3%81%AE%E4%BE%8B",
  "label": "フィードバックの例",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 1,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%A8%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%EF%BD%9C%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "ユーザビリティーとアクセシビリティー｜アプリ",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF%E3%81%AE%E4%BE%8B",
  "label": "フィードバックの例",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 1,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%A8%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%EF%BD%9C%E3%82%A2%E3%83%97%E3%83%AA%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1",
  "label": "ユーザビリティーとアクセシビリティー｜アプリマーケットプレイス掲載情報",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF%E3%81%AE%E4%BE%8B",
  "label": "フィードバックの例",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 1,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%E3%81%A8%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E3%83%BC%EF%BD%9C%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "ユーザビリティーとアクセシビリティー｜サポートドキュメント",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF%E3%81%AE%E4%BE%8B",
  "label": "フィードバックの例",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 1,
  "id": "%E4%BE%A1%E5%80%A4",
  "label": "価値",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 2,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%89%E3%83%90%E3%83%83%E3%82%AF%E3%81%AE%E4%BE%8B",
  "label": "フィードバックの例",
  "parent": "%E5%AF%A9%E6%9F%BB%E5%9F%BA%E6%BA%96"
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E8%AA%8D%E5%AE%9A%E3%81%A8%E5%86%8D%E8%AA%8D%E5%AE%9A%E3%81%AE%E5%AF%A9%E6%9F%BB%E3%83%97%E3%83%AD%E3%82%BB%E3%82%B9",
  "label": "アプリの認定と再認定の審査プロセス",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F%EF%BC%88faq%EF%BC%89",
  "label": "よくあるご質問（FAQ）",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      h3: "h3",
      code: "code",
      h4: "h4",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Quote,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Quote) _missingMdxReference("Quote", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アプリマーケットプレイスで認定を取得する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリ認定では、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "アプリマーケットプレイスに掲載されるアプリ"
      }), "がセキュリティー、プライバシー、信頼性、パフォーマンス、ユーザビリティー、アクセシビリティー、価値について以下の必要な条件を満たしているかどうかをHubSpotエコシステム品質チームが審査して確認いたします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["認定アプリは、品質と信頼性が確認済みであることから", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "アプリマーケットプレイス"
      }), "の中でも際立ちます。またアプリ認定には", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#certification-benefits",
        children: "特別なメリット"
      }), "があり、認定審査中にHubSpotエコシステム品質チームから役立つフィードバックを得ることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "HubSpotのアプリマーケットプレイスとエコシステムが継続的に改善される過程で、認定に関する要件を変更する可能性があります。設定されている基準をアプリが満たしていない場合、HubSpotは独自の裁量でアプリ認証リクエストを拒否することがあります。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/applying-for-app-certification",
            children: "アプリ認定を申請"
          }), "する際には、手順に従ってデモ動画を提出していただく必要があります。提出がない場合、HubSpotはアプリを", (0, _jsxRuntime.jsx)("u", {
            children: "審査しません"
          }), "。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "一度に1つのアプリのみを認定申請に提出できます。同時に複数のアプリを認定申請すると、申請の順番に基づいて却下されます。"
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "概要"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下に、アプリの認定に必要な条件の概要を記載します。詳細については、この後の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#review-criteria",
        children: "審査基準"
      }), "のセクションをご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリに必要な条件："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "1つのHubSpotアプリIDと関連付けられている"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "掲載されている公開アプリは、独自のものである必要があります。すでに掲載しているアプリを置き換える必要がある場合には、新しいアプリを掲載するのではなく、既存のアプリを更新する必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "同じユースケースを解決する複数のアプリを作成しないでください。類似する機能を持ち、同じAPIを使用する複数のアプリは、1つのアプリに統合するのが適切です。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "OAuth認証"
          }), "と必要な全てのスコープを使用している"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "認証済みドメイン"
          }), "に関連付けられている"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アプリに関連付けられた公開アセットがセキュリティーに関するベストプラクティスに準拠している"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "#security-privacy",
        children: "セキュリティーとプライバシーの面で必要な条件の詳細"
      }), "をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["アプリのアクティビティーは、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "API"
        }), "へのOAuth認証済みリクエストと、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "Webhookサブスクリプション"
        }), "および拡張機能からの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests",
          children: "署名済みリクエスト"
        }), "（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRMカード"
        }), "のデータ フェッチ リクエストなど）と規定されています。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["アクティブインストール数は、過去30日以内に成功した", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリのアクティビティー"
        }), "を示す、貴社とは関係のない、HubSpotの本番アカウントのユニーク数です。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "認定取得のメリット"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners/app",
        children: "アプリが掲載されること"
      }), "に加えて、認定アプリには次のメリットがあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリマーケットプレイス掲載情報に「HubSpot認定アプリ」バッジが表示される。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotアプリマーケットプレイスでの存在感の向上：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "「HubSpot認定アプリ」検索フィルターへの追加"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリマーケットプレイスの特集コレクションで紹介される機会を得る"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "「HubSpot認定アプリ」バッジとソーシャルメディアの画像にアクセスして、アプリの認定実績を共有できる。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotのパートナーシップおよび強化イニシアチブで有利になるように考慮される。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "審査基準"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "認定を取得するには、セキュリティー、プライバシー、信頼性、パフォーマンス、ユーザビリティー、アクセシビリティー、価値に関する定量的な指標と定性的な条件を満たすことで、アプリの品質を証明する必要があります。以下にて各カテゴリー別における認定の条件と、申請者に提供されるフィードバックの例をご紹介します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "セキュリティーとプライバシー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリに必要な条件："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["1つのHubSpotアプリIDと関連付けられている。アプリの掲載情報に関連付けられたHubSpotの公開アプリのアプリID（および", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuthクライアントID"
        }), "）を使用して、APIリクエストを承認する必要があります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "掲載情報を別の公開アプリや非公開アプリにリダイレクトしないでください。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "掲載されている公開アプリが機能するのに、別の公開アプリや非公開アプリを使用する必要はありません。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth認証コードのフロー"
        }), "によって認証が行われている", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ユーザーにOAuth認証コードまたはトークンをコピーして貼り付けるよう依頼することは禁止されています。ユーザーに依頼できるのは、アクセス権の付与のみです。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリは、ユーザーによる関与を必要とすることなく、アクセストークンのリクエスト、管理、更新を行う必要があります。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["インストールのためにリクエストしている全ての", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "スコープ"
        }), "（つまり、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), "パラメーターと", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), "パラメーターの両方で必要とされるスコープ）を使用します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "高度なスコープ設定"
            }), "をオンにして、アプリのインストール時にリクエストされる必須スコープ、条件付き必須スコープ、およびオプションのスコープをすべて選択します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "不要なスコープは取り除く必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "特定のスコープが一部のアプリユーザーにのみ該当する場合は、それを条件付き必須スコープまたはオプションスコープとして含めてください。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "認証済みドメイン"
        }), "に関連付けられている"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "公開アセットは、古いソフトウェアおよびウェブサーバーの各種脆弱性と所見に関連するセキュリティー上のベストプラクティスに基づいて評価される"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "フィードバックの例"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app currently requires four scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "timeline"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". According to our logs, however, it only made requests to the CRM Contacts and Timeline Events APIs in the last 30 days. Since the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " scopes are not required for either of these functions, please remove them as required from the app’s settings to minimize the permissions users must accept."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "信頼性とパフォーマンス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリに必要な条件："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "次の条件に当てはまる："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "適用される全ての規約に従っている"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "過去6か月以内に認定が却下されていない"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "共通の顧客について未解決のサポートエスカレーションがない"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "HubSpotのAPIと拡張機能の公開済み安定版を使用している"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "最新の公開バージョンを使用することが推奨されます"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "文書化されていないAPI、ベータ版API、開発者プレビュー版APIは不安定と分類され、本番アプリでは使用できません"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "注：アプリで安定していない、または公開されていないAPIを使用する場合は、その旨を認定審査の際に明らかにするか、アプリパートナーのマネージャーチームに伝えて可能な対応について話し合ってください"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["貴社とは関係のないHubSpotの顧客アカウントによる適正な使用量の", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "アクティビティー"
          }), "がある"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["アプリが", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/api-usage/usage-details",
            children: "API利用ガイドライン"
          }), "とベストプラクティスに準拠している（以下を参照）"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "レート制限（接続済みHubSpotアカウント1件につき、受信するリクエストが10秒あたり100回）に従う"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "有効期限が切れる前にOAuthアクセストークンを更新する"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "繰り返し行われる呼び出しのデータを極力キャッシュする"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "リクエストの量を減らすため、可能な場合にはバッチAPIとWebhookサブスクリプションを使用する"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ユーザーの操作ではなくAPIを使用して、プロパティー、ワークフローの作成やカスタム ワークフロー アクションの設定を行う"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["全ての", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "アクティビティー"
          }), "にわたって、**95％**を上回る平均成功率を維持している"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "エラーレスポンスを生成したリクエストは、この成功率にマイナスの影響を及ぼします"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["全ての", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/marketplace/certification-requirements#activity",
              children: "アクティビティー"
            }), "における成功率を計算する際、一部の避けられないエラー、または想定されるエラーは除外される場合があります。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "お客様に補足的な機能と価値を提供するための次のようなブラウザー拡張機能をアプリに含めることができます："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubSpot UI専用として、あるいはHubSpot APIの回避策としてブラウザー拡張機能を作成してはなりません。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["ブラウザー拡張機能は、HubSpot UIに機能やコンポーネントを注入してはなりません。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["正式にサポートされているUI拡張機能（", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "CRMカード"
                }), "や", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/automation/custom-workflow-actions",
                  children: "カスタム ワークフロー アクション"
                }), "など）を利用することで、さらに一貫性のあるユーザーエクスペリエンスを顧客に提供できます"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリにブラウザー拡張機能が含まれている場合、アプリは追加のセキュリティー評価の対象となります。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "フィードバックの例"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s API success rate falls below the 95% threshold required for certification. Our logs show a 83% success rate in the last 30 days. The vast majority of these requests returned ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), " burst rate limit errors. To reduce this error rate, we recommend throttling requests to 100 requests per 10 seconds for each account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating errors around trying to update contacts using an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undefined"
        }), " email address, which will not work with this endpoint. Your app should skip these requests if a record does not have an email address."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is making requests with expired OAuth tokens and receiving ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors before refreshing the token. To minimize these errors, we recommend that your app keep track of when tokens expire or refresh tokens before making requests. If you start seeing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors for 100% of requests and are unable to refresh the access token, consider the app uninstalled and stop making requests for the account until a user re-authenticates your app."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors from trying to use the Contact Lists API with Marketing Hub Free accounts, which do not have access to contact lists. If your app repeatedly gets ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors for missing the proper scopes, it should stop making calls to retrieve lists from that account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s webhook subscriptions frequently fail with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "500"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "503"
        }), " errors. Make sure that your server can handle the volume of requests (currently limited to 150 per second) so that customer data is not lost."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is pulling many contacts one at a time instead of pulling them in batches. We recommend using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "batch endpoints"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザビリティーとアクセシビリティー｜アプリ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリは、少なくとも過去6か月間、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps",
          children: "アプリマーケットプレイス"
        }), "に", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "掲載"
        }), "されている必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/ten-usability-heuristics/",
          children: "使いやすさに関するベストプラクティス"
        }), "（英語）を実装している必要があります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "共通の顧客のユーザーエクスペリエンスに悪影響を及ぼしかねないので、ユーザーがアプリをインストールして利用する際に、混乱やストレスをもたらすことがないようにしましょう。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "フィードバックの例"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app currently requires users to manually configure workflow webhook actions to send text messages. Consider creating custom workflow actions via the app which are flexible enough to accommodate many use cases."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザビリティーとアクセシビリティー｜アプリマーケットプレイス掲載情報"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリマーケットプレイス掲載情報に必要な条件："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["現行のアプリ機能の正確な記述", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ユーザーが使用している製品や契約内容に基づいて利用できる機能が変化する場合は、その製品や契約内容がHubSpotのものであるか、貴社のものであるかに関わらず、違いを明示する必要があります"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["現行の明確な価格情報が記載された価格ページへのリンクが含まれている", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "アプリに対応するパッケージが複数ある場合は、少なくとも最低価格のものをアプリマーケットプレイス掲載情報に記載する必要があります"
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "個人情報（PII）が表示されないように、データにプレースホルダーを使用しているか、データを非表示にしている"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次のものが含まれている：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["有益な情報を提供する最新の視覚的資料（スクリーンショットや動画など）ベストプラクティスとデモ動画の作成方法の例については、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
              children: "アプリの優れたデモ動画の作成方法"
            }), "ページを参照してください。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "初期設定に関するドキュメントのURL（サブドメインを含み、アプリに関する資料ページにユーザーを直接導く最新のURL）。ホームページのトップ画面へのリンクはご利用いただけません。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次のものが含まれていない：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "データまたは統計（ケーススタディーがリソースとして提供されている場合を除く）"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "フィードバックの例"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your App Marketplace listing includes few specific details about your app’s functionality. Please enhance the listing with screenshots which depict app functionality and include more thorough descriptions of common use cases and in-app behavior."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot customers are used to a “try before you buy” experience when purchasing our products and services. For this reason, we recommend your app provide a free trial or freemium sign-up experience. Some app partners who do not have pricing pages or free trials have created “HubSpot plans,” offering mutual customers transparent pricing, touchless sign-up, and other benefits."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザビリティーとアクセシビリティー｜サポートドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリのサポートドキュメントに必要な条件："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "一般公開されている有効なURL（支払いやログインを必要とするページは不可）内にあり、アクセシビリティー、プライバシー、GDPRに関する現行の規定に準拠している"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリの現行バージョンと一致するように更新されている"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次の事項が明記されている：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリの基本機能"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリのインストール方法、およびHubSpotアカウントとの連携方法（スコープ承認画面を含む、各ステップのスクリーンショットが必要）"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "インストール後のアプリの設定方法"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリの使い方（手動と自動の両方を含む）"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリとHubSpotの接続を解除する方法"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubSpotアカウントからアプリをアンインストールする方法"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "接続解除とアンインストールがユーザーのHubSpotアカウントとデータに及ぼす影響"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["画像が含まれている。HubSpot UIのスクリーンショットを含む画像は、最新の状態に更新し、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://canvas.hubspot.com/",
          children: "デザインシステム"
        }), "（英語）との一貫性を確保してください。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "動画も推奨されますが、必須ではありません。動画は定期的に更新し、アプリの現行バージョンを反映する必要があります"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "フィードバックの例"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The setup guide for your app includes a screenshot depicting the scopes your app requires for installation. This screenshot does not show the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "business-intelligence"
        }), " scope, which is selected in your app’s settings. Please update the screenshot so that it reflects the current required scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "価値"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アプリのアクティブインストール数、顧客定着率、HubSpotアプリマーケットプレイスでのレビュー数は、貴社とHubSpotの共通の顧客がアプリに見出す価値の指標として評価されます"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["認定を取得および保持するには、アプリの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#activity",
              children: "アクティブ"
            }), "インストール数が（ユニーク数で）60以上必要です。インストールのアカウントは、貴社と無関係でなければなりません。テストアカウントも対象外となります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "アプリのアクティブインストール数が60未満の場合は、認証リクエストをキャンセルするよう求められます"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "アプリのアクティブインストール数が掲載の要件である3に満たない場合、アプリはアプリマーケットプレイスから削除される可能性があります"
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "否定的なレビューがある場合、それに対するチームの応答をアプリの掲載情報に含める必要があります。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "フィードバックの例"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app has not maintained at least 60 active installs over the trailing six month period. As such, its certified status will be removed. You may re-apply for certification in six months."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリの認定と再認定の審査プロセス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotエコシステム品質チームは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "アプリ認定の申請"
      }), "に対して", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "10営業日"
      }), "以内に返答いたします。アプリの審査およびフィードバックのプロセス全体にかかる期間は、フィードバックが共有されてから最大", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "60日間"
      }), "です。アプリのテスト用ユーザー資格情報を提供するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/testing-credentials",
        children: "こちら"
      }), "に記載されている条件を確認してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "全ての必要な条件を満たしたアプリには、認定ステータスが付与されます。「HubSpot認定アプリ」バッジがアプリマーケットプレイス上の顧客やプロスペクトに対して表示されます。認定済みのアプリは、ユーザーが「HubSpot認定アプリ」で絞り込んだ場合にも検索結果に表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "なお、アプリが審査において認定されなかった場合は、6か月後に再申請いただけます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリが厳格な認定条件を引き続き満たしているかどうかを確認する目的で、認定から1年が経過したアプリはHubSpotエコシステム品質チームによって再審査されることがあります。アプリが基準を満たさなくなった場合、同チームは最大60日間、貴社と協力して問題の解決に取り組みます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アプリがある時点で上記の認定条件に準拠しなくなった場合、認定から1年が経過していなくても、HubSpotによって直ちに再審査のプロセスを開始させていただく可能性がございます。また、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/app-program-agreement",
          children: "アプリ パートナー プログラム契約"
        }), "に記載のとおり、当社はいつでもアプリを非公開にする権利を有するものとします。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "よくあるご質問（FAQ）"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Are there any costs associated with app certification?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "No, we do not charge you a fee to list or certify your apps in the App Marketplace, nor a fee for installs generated through the App Marketplace. There is no revenue sharing. We are here to support you to make your app of higher quality."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Will I be notified when my app will be eligible again for certification if it was previously rejected?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No. At this time we do not have notifications enabled to notify you if and when you will be eligible to re-apply at this time. Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best resource to contact and ask if you are eligible before applying."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How should I promote my certified app to my customers?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Feel free to use the press release template on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/resources",
          children: "this page"
        }), " to share the news that your app has earned certification."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you plan to post on social media, be sure to tag HubSpot — we love to celebrate alongside our app partners!"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I enjoy experimenting with the newest beta APIs. Is app certification right for me?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We recommend you reach out to your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " to see if app certification is right for your app."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Our goal is to ensure your app is well built for our mutual customers and limits breaking changes, which requires your app uses the latest stable APIs. We also love seeing and supporting entrepreneurs, early adopters, and developers who are eager to experiment with the newest beta APIs."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I'd like my app to be featured on the App Marketplace. Is this what app certification is for?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The benefits of being featured in collections and for customers to easily filter for a certified app within the App Marketplace are continuing to evolve. We’d like to learn more about how you would find being featured the most helpful (e.g. App Marketplace, HubSpot community, HubSpot curated newsletters or other forms)."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best contact to discuss potential future benefits and start this conversation."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Why and how will my public assets be assessed for security best practices?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "With the average customer using more than five integrations, it’s imperative apps are monitored and held to privacy, security, and quality standards over time. Any public assets will be assessed using information already provided during a listing process and findings will be analyzed using a non-invasive method."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "My app was certified over a year ago. What do I have to do to keep my certification status and what does it mean to get recertified?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The HubSpot Ecosystem Quality team will reach out if your app is out of compliance or due for annual recertification with next steps."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We encourage you to monitor your app’s performance, certification requirements, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "Developer Changelog"
        }), ", and any additional HubSpot resources related to any changes in technology used and how your app could stay up to date."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["開発者アカウントにログインし、［アプリマーケットプレイス］>［掲載情報］の順に進み、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリ パートナー マネージャー"
        }), "とそのEメール情報を検索します。アプリの上で、［その他］>［掲載情報の詳細を表示］をクリックします。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "アプリ認定の申請方法"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "アプリを掲載する方法"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "アプリの掲載に必要な条件"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
        children: "開発者コミュニティーフォーラム"
      }), "（英語）"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/contact-our-partnerships-team",
        children: "アプリ パートナー チームに問い合わせる（英語）"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}