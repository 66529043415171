"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279931;
const slug = exports.slug = 'guides/cms/content/memberships/overview';
const title = exports.title = 'Associação';
const name = exports.name = 'PT BR Memberships';
const metaDescription = exports.metaDescription = 'Associações é um recurso que possibilita exigir que os visitantes tenham uma conta para acessar conteúdo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "fluxo-de-usu%C3%A1rios-associados",
  "label": "Fluxo de usuários associados",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-de-associa%C3%A7%C3%A3o-do-hubl",
  "label": "Variáveis de associação do HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "fun%C3%A7%C3%B5es-do-hubl-para-objetos-do-crm",
  "label": "Funções do HubL para objetos do CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "registrar-se%2C-entrar-e-sair",
  "label": "Registrar-se, entrar e sair",
  "parent": null
}, {
  "depth": 0,
  "id": "modelos-de-associa%C3%A7%C3%A3o",
  "label": "Modelos de associação",
  "parent": null
}, {
  "depth": 0,
  "id": "registro-de-auditoria-de-associa%C3%A7%C3%B5es",
  "label": "Registro de auditoria de associações",
  "parent": null
}, {
  "depth": 0,
  "id": "sso-para-associa%C3%A7%C3%B5es",
  "label": "SSO para associações",
  "parent": null
}, {
  "depth": 1,
  "id": "logins-sociais",
  "label": "Logins sociais",
  "parent": "sso-para-associa%C3%A7%C3%B5es"
}, {
  "depth": 0,
  "id": "artigos-e-recursos-relacionados-%C3%A0-associa%C3%A7%C3%A3o",
  "label": "Artigos e recursos relacionados à associação",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Associações"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Associações é um recurso que possibilita exigir que os visitantes tenham uma conta para acessar o conteúdo. O sistema de contas aproveita o HubSpot CRM e as listas de CRM, bem como a possibilidade de um visitante criar uma senha para sua conta. Os profissionais de marketing podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-pages-editor/control-audience-access-to-pages",
        children: "criar páginas nos sites que só interagem com listas específicas que o CRM possa acessar"
      }), ". Você também pode restringir o acesso a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-knowledge-base",
        children: "artigos da central de conhecimento"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-blog",
        children: "blogs"
      }), " usando as Associações."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fluxo de usuários associados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando os contatos recebem acesso ao conteúdo — o que pode ocorrer ao se juntarem a listas ou por meio de atribuição manual —, um e-mail é enviado para eles se registrarem no seu site, onde definem uma senha para acessar o conteúdo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Imagine uma academia, que deseja permitir que os visitantes se registrem nas aulas e visualizem as aulas nas quais se registraram. Quando um visitante se registra em uma aula, o envio do formulário cria um contato no HubSpot CRM e esse contato é adicionado a uma lista com base no envio do formulário, que é usado para conceder acesso à página \"Meus eventos\"."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/signup.gif",
        alt: "Um exemplo de registro em academia."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O visitante recebe um e-mail de registro de associação que lhe permite criar uma senha para sua conta de associação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/register%20account.png",
        alt: "Formulário de registro"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agora, quando os visitantes fizerem login em suas contas, poderão fazê-lo na página privada \"Meus Eventos\" usando o e-mail e a senha definidos. Como o visitante está conectado, o desenvolvedor que criou o conteúdo privado pode renderizar os dados do contato conectado usando os dados do CRM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/my_events.gif",
        alt: "Um visitante usa sua conta para fazer login e ver as aulas nas quais se registrou."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis de associação do HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para algumas empresas, pode fazer sentido mostrar conteúdo diferente com base em se um usuário está conectado ou não. Existem variáveis do HubL que os desenvolvedores podem usar para verificar se um contato está conectado a um site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A variável ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.is_logged_in"
        })
      }), " do HubL indica se o visitante atual está conectado ao site por meio de associações. Ele pode ser usado em uma instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " para renderizar determinado conteúdo de forma condicional, permitindo fornecer uma experiência individualizada ao visitante."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n    You're signed in!\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se quiser exibir conteúdo diferente na mesma página com base em uma associação de lista, você pode verificar as associações de lista de contatos conectados usando a variável ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.list_memberships"
        })
      }), " do HubL, que retorna um dicionário de IDs de lista dos quais o contato conectado é membro."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para personalizar o conteúdo sem usar associações, você poderá usar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables#general-variables",
          children: "variável de contato"
        }), " se um visitante tiver enviado um formulário no site."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Funções do HubL para objetos do CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além do conteúdo geral exibido de forma condicional em uma página, é possível extrair informações sobre objetos em sua conta da HubSpot, como Contatos/Empresas/Negócios/Produtos, usando as seguintes funções:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-associations",
          children: "Associações do CRM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-object",
          children: "Objeto do CRM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-objects",
          children: "Objetos do CRM"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para fins de segurança, apenas objetos de eventos de produto e marketing podem ser recuperados em uma página acessível ao público; para obter informações sobre outros tipos de objetos, uma página deve exigir associação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n  {% set membership_contact = crm_object('contact', request.contact.contact_vid, 'firstname,lastname') %}\n  Welcome back, {{ membership_contact.firstname }} {{ membership_contact.lastname }}\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Registrar-se, entrar e sair"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando um contato recebe acesso a qualquer conteúdo em seu site por meio de associações, um e-mail é enviado para ele se registrar no site, onde pode definir uma senha para visualizar o conteúdo. Caso precise reenviar um link de registro a um contato, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-pages-editor/control-audience-access-to-pages#resend-the-registration-email-to-a-specific-contact",
        children: "reenviar o e-mail de registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os caminhos de URL para entrada/saída do usuário são consistentes para todos os sites do HubSpot CMS com a funcionalidade de associação."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/login"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/logout"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando um visitante faz login em seu site, um cookie é enviado para seu navegador, permitindo que ele navegue pelo site e acesse as páginas às quais tem acesso por meio de associações sem precisar fazer login novamente. Se um visitante sair ou nunca tiver feito login em seu site no navegador, ele será solicitado a fazer login para poder visualizar o conteúdo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modelos de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sites com associações têm algumas páginas especiais que são necessárias para facilitar essa funcionalidade. Elas são determinadas por modelos de sistema especiais. Esses modelos de sistema são editáveis, permitindo controlar a aparência das várias etapas de associação. Para definir os modelos que deseja usar, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Configurações >"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conteúdo privado"
        })
      }), " e escolha a guia ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/templates",
        children: "\"Modelos\""
      }), ". Para criar um modelo para definir nessas configurações, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketing > Arquivos e modelos > Ferramentas de design"
      }), " e, no canto superior esquerdo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Arquivo > Novo arquivo > HTML e HUBL"
      }), " e selecione o Tipo de modelo apropriado no menu suspenso."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter uma lista abrangente dos modelos de associação, consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#membership",
        children: "seção de associações na documentação dos modelos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Apenas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "modelos HTML + HubL"
        }), " podem ser modelos de associação."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Registro de auditoria de associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: "Configurações > Conteúdo privado"
      }), ", você pode exibir um registro de auditoria dos visitantes que interagem com o conteúdo nas associações. Assim, é possível ver quais visitantes estão visualizando conteúdo privado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-02-22%20at%2012.24.54%20PM.png",
        alt: "Registro de auditoria da visualização de conteúdo privado"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SSO para associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode gerenciar todas as suas necessidades empresariais de permissão de acesso e autenticação em um único sistema com o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso",
        children: "Logon único (SSO) para associações."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logins sociais"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode fornecer aos usuários da sua lista uma maneira de fazer login usando o Google ou o Facebook em vez de inserir um endereço de e-mail e senha. O provedor de login social envia o endereço de e-mail associado à conta social conectada. Esse endereço de e-mail é usado para validar se o contato consta em uma lista de contatos com acesso ao conteúdo. Este recurso não requer que você tenha definido as configurações de SSO."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você precisa ter uma página definida como \"Registro privado obrigatório\" com uma lista de contatos. Além disso, o modelo de login precisa incluir o módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "membership_social_logins"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/social",
        children: "Adicionar login social às páginas de associação"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Artigos e recursos relacionados à associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-memberships",
          children: "Aspectos essenciais do HubSpot para desenvolvedores: introdução às associações"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/creating-a-header-nav-that-adapts-to-if-the-contact-is-logged-in",
          children: "Criar menus que se adaptam ao fato de o usuário estar conectado ou não"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}