"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32159895346;
const slug = exports.slug = 'guides/api/crm/imports';
const title = exports.title = 'CRM API | インポート';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Imports | 202007';
const metaDescription = exports.metaDescription = 'インポートは、営業、マーケティング、およびサービスのさまざまなツールで使用できるオブジェクトデータをHubSpotアカウントに取り込むために使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B",
  "label": "インポートを開始する",
  "parent": null
}, {
  "depth": 1,
  "id": "importrequest%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E5%BD%A2%E5%BC%8F%E3%82%92%E6%95%B4%E3%81%88%E3%82%8B",
  "label": "importRequestデータの形式を整える",
  "parent": "%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "hubspot%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E5%88%97%E3%82%92%E3%83%9E%E3%83%83%E3%83%94%E3%83%B3%E3%82%B0%E3%81%99%E3%82%8B",
  "label": "HubSpotプロパティーにファイルの列をマッピングする",
  "parent": "%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "1%E3%81%A4%E3%81%AE%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%92%E5%90%AB%E3%82%801%E3%81%A4%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%81%99%E3%82%8B",
  "label": "1つのオブジェクトを含む1つのファイルをインポートする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A4%87%E6%95%B0%E3%81%AE%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%92%E5%90%AB%E3%82%801%E3%81%A4%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88",
  "label": "複数のオブジェクトを含む1つのファイルをインポート",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A4%87%E6%95%B0%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%81%99%E3%82%8B",
  "label": "複数のファイルをインポートする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%BB%A5%E5%89%8D%E3%81%AE%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "以前のインポートを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "cancel-an-import",
  "label": "Cancel an import",
  "parent": null
}, {
  "depth": 0,
  "id": "view-and-troubleshoot-import-errors",
  "label": "View and troubleshoot import errors",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%88%B6%E9%99%90",
  "label": "制限",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      img: "img",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "インポート"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["インポートAPIは、コンタクト、会社、コメントなどのCRMレコードとアクティビティーをHubSpotアカウントにインポートする際に使用します。インポートされたレコードとアクティビティーに、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "コンタクトAPI"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "エンゲージメントAPI"
      }), "などのCRM APIエンドポイント経由でアクセスして更新することができます。また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/import-objects",
        children: "HubSpotのガイド付きインポートツールを使用してレコードとアクティビティーをインポートする"
      }), "こともできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["インポートを開始する前に、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file",
        children: "インポートできるオブジェクトとアクティビティー、およびファイルとプロパティーの要件"
      }), "について詳しくご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "インポートを開始する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["インポートを開始するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports"
      }), "に送信します。その際、インポートファイルの列をHubSpot上の関連するCRMプロパティーに割り当てる方法をリクエスト本文で指定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "APIインポートはform-dataタイプのリクエストとして送信され、リクエスト本文には次のフィールドが含まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-the-importrequest-data",
          children: "importRequest"
        }), "：リクエストのJSONを格納するテキストフィールド。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#HubSpot%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E5%88%97%E3%82%92%E3%83%9E%E3%83%83%E3%83%94%E3%83%B3%E3%82%B0%E3%81%99%E3%82%8B",
          children: "files"
        }), "：インポートファイルを格納するファイルフィールド。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエストヘッダーについては、値", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), "が含まれる", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content-Type"
      }), "ヘッダーを追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のスクリーンショットに、Postmanなどのアプリを使用したリクエストの例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-import-request-no-response0.png",
        alt: "postman-import-request-no-response0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "importRequestデータの形式を整える"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストのJSONでは、インポートファイルの詳細（スプレッドシートの列のHubSpotデータに対するマッピングなど）を定義します。リクエストのJSONには次のフィールドを含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), "：インポートの名前。これはHubSpot上で、リストなどの他のツールで参照できる名前で、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/view-and-analyze-previous-imports",
            children: "インポートツールにも表示される"
          }), "名前です。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "importOperations"
          }), "：このオプションフィールドを使用して、インポートで特定のオブジェクトやアクティビティーの", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/import-objects#choose-how-to-import",
            children: ["レコードの作成と更新、作成", (0, _jsxRuntime.jsx)("u", {
              children: "のみ"
            }), "、または更新", (0, _jsxRuntime.jsx)("u", {
              children: "のみ"
            })]
          }), "のどれを行うかを指示します。オブジェクト／アクティビティーの", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectTypeId"
          }), "を指定し、レコードの", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), "（作成と更新）、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CREATE"
          }), "、または", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPDATE"
          }), "を行うことを指定します。例えばリクエストのフィールドは次のようになります：", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"importOperations\": {\"0-1\": \"CREATE\"}"
          }), ".このフィールドを含めない場合、インポートに使用されるデフォルト値は", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), "です。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "dateFormat"
          }), "：ファイルに含まれる日付の形式。これは既定では", (0, _jsxRuntime.jsx)(_components.code, {
            children: "MONTH_DAY_YEAR"
          }), "に設定されていますが、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "DAY_MONTH_YEAR"
          }), "または", (0, _jsxRuntime.jsx)(_components.code, {
            children: "YEAR_MONTH_DAY"
          }), "も使用できます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "marketableContactImport"
          }), "：インポートファイルに含まれるコンタクトの", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/contacts/marketing-contacts",
            children: "マーケティングステータス"
          }), "を示す任意指定のフィールド。これは、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/contacts/marketing-contacts#check-your-access-to-marketing-contacts",
            children: "マーケティングコンタクトの利用"
          }), "が可能なアカウントにコンタクトをインポートする場合にのみ、使用します。ファイル内のコンタクトをマーケティング対象として設定するには、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), "の値を使用します。ファイル内のコンタクトをマーケティング対象外として設定するには、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), "の値を使用します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "createContactListFromImport"
          }), "：インポートから", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/crm-setup/import-objects#create-list-from-import",
            children: "コンタクトの静的リストを作成"
          }), "するための任意指定のフィールド。ファイルからリストを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), "の値を使用します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "files"
          }), "：インポートファイルの情報を格納する配列。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileName"
            }), "：インポートファイルの名前。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileFormat"
            }), "：インポートファイルの形式。CSVファイルの場合に使用する値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), "です。Excelファイルの場合に使用する値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SPREADSHEET"
            }), "です。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileImportPage"
            }), "：インポートファイルのデータをHubSpotデータにマッピングするために必要な", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), "配列を格納します。列マッピングの詳細については、以下をご覧ください。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotプロパティーにファイルの列をマッピングする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "columnMappings"
      }), "配列内には、スプレッドシート内の順序に従ってインポートファイル内の各列を格納します。各列には、次のフィールドを格納します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**columnObjectTypeId：**データが属するオブジェクトまたはアクティビティーの名前または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "値。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "値の一覧については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "こちらの記事"
        }), "をご覧ください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**columnName：**列ヘッダーの名前。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**propertyName：**データのマッピング先となるHubSpotプロパティーの内部名。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), "フィールドが使用されている場合、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "複数ファイルのインポート"
        }), "で共通する列では、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyName"
        }), "が", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), "である必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**columnType：**これを使用して、列に", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/crm-setup/set-up-your-import-file#:~:text=Name%2C%20Email%20Address",
          children: "一意のIDプロパティー"
        }), ".-,Unique%20identifier,-%3A%20a%20property)が含まれることを指定します。インポートのプロパティーと目標に応じて、次のいずれかの値を使用します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**HUBSPOT_OBJECT_ID：**レコードのID。例えば、コンタクトのインポートファイルに、コンタクトに関連付けられる会社のIDを格納する「レコードID」列を含めるケースが考えられます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**HUBSPOT_ALTERNATE_ID：**レコードID以外の固有ID。例えば、コンタクトのインポートファイルに、コンタクトのEメールアドレスを格納した「Eメール」列を含めるケースが考えられます。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "FLEXIBLE_ASSOCIATION_LABEL"
            }), "：関連付けラベルが列に含まれることを示すには、この列タイプを含めます。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ASSOCIATION_KEYS"
            }), "：同じオブジェクト関連付けインポートの場合にのみ、関連付けられる同じオブジェクトレコードの固有IDにこの列タイプを含めます。たとえば、コンタクト関連付けインポートのリクエストでは、［関連付けされたコンタクト［Eメール/レコードID］］列に", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnType"
            }), "として", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASSOCIATION_KEYS"
            }), "が必要です。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/crm-setup/set-up-your-import-file#same-object-association-imports",
              children: "同じオブジェクト関連付けインポート用にインポートファイルを設定する"
            }), "方法についてご確認ください。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "toColumnObjectTypeId"
        }), "：", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "複数のファイル"
        }), "または複数のオブジェクトをインポートする場合、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#sample-import-files:~:text=To%20import%20and%20associate%20multiple%20objects%20in%20one%20file%2C%20include%20information%20about%20associated%20records%20and/or%20activities%20in%20the%20same%20row.%20In%20two%20files%2C%20use%20a%20common%20column%20to%20connect%20the%20records%20in%20each%20file.%20You%20can%20refer%20to%20the%20example%20files%20for%20more%20help%20importing%20and%20associating%20records.",
          children: "共通する列"
        }), "プロパティーまたは関連付けラベルが属するオブジェクトの名前または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "。プロパティーが属して", (0, _jsxRuntime.jsx)("u", {
          children: "いない"
        }), "オブジェクトのファイルに共通する列プロパティー用にこのフィールドを含めます。例えば、共通する列として［Eメール］コンタクトプロパティーを持つ2つのファイルのコンタクトと会社を関連付ける場合、", (0, _jsxRuntime.jsx)("u", {
          children: "会社"
        }), "ファイルの「Eメール」列用に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), "を含めます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "foreignKeyType"
        }), "：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationCategory"
        }), "で指定された、共通の列が使用する関連付けのタイプ（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "複数ファイルのインポート"
        }), "の場合のみ）。プロパティーが属して", (0, _jsxRuntime.jsx)("u", {
          children: "いない"
        }), "オブジェクトのファイルに共通する列プロパティー用にこのフィールドを含めます。例えば、共通する列として［Eメール］コンタクトプロパティーを持つ2つのファイルのコンタクトと会社を関連付ける場合、", (0, _jsxRuntime.jsx)("u", {
          children: "会社"
        }), "ファイルの「Eメール」列用に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "foreignKeyType"
        }), "を含めます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "associationIdentifierColumn"
        }), "：レコードを関連付けるために共通の列で使用されるプロパティーを示します（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "複数ファイルのインポート"
        }), "の場合のみ）。プロパティーが属しているオブジェクトのファイルに共通する列プロパティー用にこのフィールドを含めます。例えば、共通する列として［Eメール］コンタクトプロパティーを持つ2つのファイルのコンタクトと会社を関連付ける場合、", (0, _jsxRuntime.jsx)("u", {
          children: "会社"
        }), "ファイルの「Eメール」列について", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationIdentifierColumn"
        }), "を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "に設定します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1つのオブジェクトを含む1つのファイルをインポートする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下は、1つのファイルをインポートしてコンタクトを作成するためのリクエスト本文の例です。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'test_import.csv'\n# This file contains a list of contact records to import.\n\nimport requests\nimport json\nimport os\n\nurl = \"https://api.hubapi.com/crm/v3/imports\"\n\nYOUR_ACCESS_TOKEN = 'xxxxxxx';\n\n# Content-Type header will be set automatically by the requests library\nheaders = {\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\ndata = {\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n\ndatastring = json.dumps(data)\n\npayload = {\"importRequest\": datastring}\n\ncurrent_dir = os.path.dirname(__file__)\nrelative_path = \"./test_import.csv\"\n\nabsolute_file_path = os.path.join(current_dir, relative_path)\n\nfiles = [\n    ('files', open(absolute_file_path, 'rb'))\n]\nprint(files)\n\nresponse = requests.request(\"POST\", url, data=payload, files=files, headers=headers)\n\nprint(response.text.encode('utf8'))\nprint(response.status_code)\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"import_file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\nYOUR_ACCESS_TOKEN=\"xxx-xxx-xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx\"\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  -H \"Authorization: Bearer $YOUR_ACCESS_TOKEN\" \\\n  https://api.hubapi.com/crm/v3/imports\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "複数のオブジェクトを含む1つのファイルをインポート"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下は、1つのファイル内のコンタクトと会社をインポートして関連付けラベルに関連付けるリクエスト本文の例です。"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Association Label Import\",\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"association label example.xlsx\",\n      \"fileFormat\": \"SPREADSHEET\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Domain\",\n            \"propertyName\": \"domain\"\n          },\n          {\n            \"columnName\": \"Association Label\",\n            \"columnType\": \"FLEXIBLE_ASSOCIATION_LABEL\",\n            \"columnObjectTypeId\": \"0-1\",\n            \"toColumnObjectTypeId\": \"0-2\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "複数のファイルをインポートする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下は、2つのファイル内のコンタクトと会社をインポートして関連付けるためのリクエスト本文の例です。コンタクトプロパティー［Eメール］がファイル内の共通の列です。"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Contact Company import\",\n  \"dateFormat\": \"YEAR_MONTH_DAY\",\n  \"files\": [\n    {\n      \"fileName\": \"contact-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"associationIdentifierColumn\": true\n          }\n        ]\n      }\n    },\n    {\n      \"fileName\": \"company-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company name\",\n            \"propertyName\": \"name\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company domain name\",\n            \"propertyName\": \"domain\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"toColumnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationTypeId\": 280,\n              \"associationCategory\": \"HUBSPOT_DEFINED\"\n            }\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエストが成功すると、インポートの取得またはキャンセルに使用できる", (0, _jsxRuntime.jsx)(_components.code, {
        children: "importId"
      }), "を含むレスポンスが返されます。完了すると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/import-and-export/view-and-analyze-previous-imports",
        children: "インポートをHubSpotで表示"
      }), "できますが、ビュー、リスト、レポート、またはワークフローでインポートを基準にレコードを絞り込む際には、API経由で完了したインポートはオプションとして使用可能にはなりません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "以前のインポートを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウントから全てのインポートを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。特定のインポートの情報を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["インポートを取得すると、インポートの名前、ソース、ファイル形式、言語、日付形式、列マッピングなどの情報が返されます。また、インポートの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), "も返されます。これは次のいずれかです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), "：HubSpotはインポートが存在することを認識していますが、インポートの処理はまだ開始されていません。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), "：インポートがアクティブに処理されています。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DONE"
        }), "：インポートは完了済みです。全てのオブジェクト、アクティビティー、または関連付けが更新または作成されました。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "FAILED"
        }), "：インポートの開始時に検出されなかったエラーがありました。インポートは完了しませんでした。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), "：エクスポートが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), "のいずれかの状態だったときに、ユーザーがエクスポートをキャンセルしました。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), "：最大数（3件）のインポートが同時に処理されています。他のインポートの1つの処理が終了すると、インポートが開始されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事の上部にある「エンドポイント」タブから、結果のページ処理と制限に関する詳細をご確認ください。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注"
        }), "：非公開アプリ アクセス トークンを使用してインポートを取得する場合、応答にはその非公開アプリによって実行されたインポートのみが含まれます。HubSpotまたは別の非公開アプリを介して完了したインポートは返されません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancel an import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To cancel an active import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/cancel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View and troubleshoot import errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view errors for a specific import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/errors"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/troubleshoot-import-errors",
        children: "common import errors and how to resolve them"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For errors such as ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Incorrect number of columns"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Unable to parse"
      }), " JSON or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "404 text/html is not accepted"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that there is a column header for each column in your file, and that the request body contains a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnMapping"
        }), " entry for each column. The following criteria should be met:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The column order in the request body and import file should match. If the column order doesn't match, the system will attempt to automatically reorder but may be unsuccessful, resulting in an error when the import is started."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Every column needs to be mapped. If a column is not mapped, the import request may still be successful, but would result in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Incorrect number of columns"
            }), " error when the import is started."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that the file's name and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field in your request JSON match, and that you've included the file extension in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field. For example, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "import_name.csv."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that your header includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), " with a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "multipart/form-data"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "Example POST URL:\nhttps://api.hubapi.com/crm/v3/imports?\n\nExample importRequest JSON data:\nThis example contains 3 columns:\n - First name, mapped to the firstname contact property\n - Email, mapped to the email contact property\n - Company ID, which contains a list of company record IDs\n   that the contact will be assocated with.\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'final_emails.csv'\n# This file contains a list of contact records to import.\n# Each row in the file contains a column named \"Company ID\" that contains the companyId\n# that the contact should be associated with.\n\nimport requests\nimport json\n\npost_url = 'http://api.hubapi.com/crm/v3/imports?hapikey=4324...5432'\n\nimportRequest = {\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"ignored\": False,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": None,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": None,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          }\n        ]\n      }\n    }\n  ]\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "<?php\n// This example imports a local file named 'import_file.csv'\n\n    $post_url = \"http://api.hubapi.com/crm/v3/imports?hapikey=1234...5342\";\n\n    $csv_file = new CURLFile('import_file.csv','text/csv');\n\n    $config_json = '{\"name\":\"test_import\",\"files\":[{\"fileName\":\"final_emails.csv\",\"fileImportPage\":{\"hasHeader\":true,\"columnMappings\":[{\"ignored\":false,\"columnName\":\"First Name\",\"idColumnType\":null,\"propertyName\":\"firstname\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Email\",\"idColumnType\":\"HUBSPOT_ALTERNATE_ID\",\"propertyName\":\"email\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Company ID\",\"idColumnType\":\"HUBSPOT_OBJECT_ID\",\"propertyName\":null,\"foreignKeyType\":{\"associationCategory\":\"HUBSPOT_DEFINED\",\"associationTypeId\":1},\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false}]}}]};type=application/json';\n\n    $post_data = array(\n        \"files\" => $csv_file,\n        \"importRequest\" => $config_json\n    );\n\n    $ch = curl_init();\n    @curl_setopt($ch, CURLOPT_POST, true);\n    @curl_setopt($ch, CURLOPT_URL, $post_url);\n    @curl_setopt($ch, CURLOPT_POSTFIELDS, $post_data);\n\n    $response    = @curl_exec($ch); //Log the response from HubSpot as needed.\n    $status_code = @curl_getinfo($ch, CURLINFO_HTTP_CODE); //Log the response status code\n    @curl_close($ch);\n    echo $status_code . \" \" . $response;\n?>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  http://api.hubapi.com/crm/v3/imports?hapikey=4123...4321\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**エラーが発生する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), "などの重複するヘッダーがあるかどうかを確認してください。これが発生する可能性があるのは、Postmanを使用している場合、またはPythonスクリプトのヘッダーにそれが含まれている場合です。リクエストを完了する前に重複を削除してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "インポートAPIを使用すると、1日あたり最大80,000,000行をインポートできます。ただし、個々のインポートファイルの上限として、1,048,576行または512MBのうち最初に到達した条件が適用されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストが行制限またはサイズ制限のいずれかを超えた場合、HubSpotから429 HTTPエラーが返されます。このような上限に近づいたら、インポートを複数のリクエストに分割することをお勧めします。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}