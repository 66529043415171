"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 181609845504;
const slug = exports.slug = 'reference/ui-components/figma-design-kit';
const title = exports.title = 'Figma Design Kit | UI extensions';
const name = exports.name = 'Figma Design Kit | UI extensions';
const metaDescription = exports.metaDescription = 'Learn about using the Figma Design Kit for designing UI extensions (BETA).';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "access-the-kit",
  "label": "Access the kit",
  "parent": null
}, {
  "depth": 0,
  "id": "using-the-kit",
  "label": "Using the kit",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Figma Design Kit (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Figma Design Kit is a visual representation of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "UI components"
      }), " you can include in React-based app cards in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "UI extensions"
      }), ". Using this kit, you can create more detailed designs to plan out UI extensions, and can be particularly helpful when needing to share demos with stakeholders or keep teams aligned during the development process."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Along with containing visual examples of UI components, the kit also contains high-level UI extension design information, such as a breakdown of app card anatomy and examples of available locations. Note that HubSpot will update this kit over time as new components are added, but the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "UI component documentation"
      }), " will be the most up to date source of truth for available components. Be sure to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#using-the-kit",
        children: "check the changelog"
      }), " for any recent or upcoming additions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/figma-design-kit-button-component.png",
        alt: "figma-design-kit-button-component"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about using UI kits in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.figma.com/hc/en-us/articles/24037724065943-Start-designing-with-UI-kits",
        children: "Figma's documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the Figma Design Kit is in beta and is therefore subject to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "HubSpot's developer beta terms"
        }), ". The contents of the kit may change during this period."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Access the kit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Click the button below to access the Figma Design Kit (password: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Extensibility0324"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "0a40bed3-e76c-49c4-b172-5c06cb306af3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After accessing the kit, it's recommended to open the kit in your Figma desktop app, then duplicate it to your drafts:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Extensibility Components"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Duplicate to your drafts"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/figma-design-kit-duplicate-to-drafts.png",
        alt: "figma-design-kit-duplicate-to-drafts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A copy will be created to your drafts, which you can then use as needed. As updates will be periodically released, you'll need to check the source kit and duplicate to your drafts again to access new components."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.figma.com/hc/en-us/articles/24037724065943-Start-designing-with-UI-kits#h_01J0Y2QYS4GKV112NWZHD6019J",
        children: "copying UI kits"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using the kit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pages"
      }), " section of the left sidebar, you'll find a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Documentation"
      }), " section which contains the following pages:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Introduction:"
        }), " a brief introduction to what the kit is and what it should be used for, along with links to documentation and a feedback form."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Card Anatomy & Location Wires:"
        }), " high-level breakdowns of app card anatomy, along with wireframes for the different locations you can build UI extensions for."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/figma-design-kit-card-anatomy-and-location-wires.png",
        alt: "figma-design-kit-card-anatomy-and-location-wires"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Changelog:"
          }), " recent additions and changes, along with upcoming additions. Each entry will include a link to relevant documentation when available, along with the release date."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/figma-design-kit-changelog-with-sidebar.png",
            alt: "figma-design-kit-changelog-with-sidebar"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Components"
      }), " section, you'll find the currently available design pages for UI components. Component pages will include a description of the component along with visual examples of different states and variants."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/figma-design-kit-checkbox-component-with-sidebar.png",
        alt: "figma-design-kit-checkbox-component-with-sidebar"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}