import styled, { css } from 'styled-components';
import { EERIE, SLINKY, OBSIDIAN } from 'HubStyleTokens/colors';
import { rgba } from 'UIComponents/core/Color';
import { mediaQueries, MOBILE_MENU_BREAKPOINT } from 'developer-docs-shared-components/utils/mediaQueries';

// INTERFACES
// Prevent width="" from being rendered to the DOM
// CONSTANTS
/** Secondary nav width at desktop, in pixels. */
export const SECONDARY_NAV_WIDTH = 298;

/**
 * Table of contents width, in pixels, on wider screens.
 */
export const TOC_WIDTH = 293;

/**
 * Table of contents width on narrower viewports, in pixels.
 */
export const TOC_WIDTH_NARROW = 200;

/**
 * The right offset of the TOC, in pixels.
 */
export const TOC_RIGHT_OFFSET = 36;

/**
 * The className applied to active router-aware links in this list.
 * @see https://tools.hubteamqa.com/ui-library/show/ui-addon-react-router-dom/UIRouterNavLink#uirouternavlink/activeClassName
 */
export const NESTED_NAV_ACTIVE_LINK_CLASSNAME = 'NestedNavLink-active';

// SHARED STYLES
/**
 * Styles for primary nav links that, when active, have a pill shape.
 */
export const getSharedPillLinkStyles = (accentColor = OBSIDIAN) => css(["border-radius:20px;padding:0 8px;display:inline-block;font-weight:500;font-size:14.5px;text-decoration:underline 1px ", ";transition:text-decoration-color 300ms;&:hover,&:focus{text-decoration-color:", ";}"], rgba(accentColor, 0), rgba(accentColor, 1));

/**
 * Handles the width for the main article on the page.
 */
export const articleWidthCss = css(["", "{width:calc(100% - ", "px);}", "{width:calc(100% - ", "px);}"], mediaQueries.aboveWidth(MOBILE_MENU_BREAKPOINT), TOC_WIDTH_NARROW + 16, mediaQueries.aboveLaptop, TOC_WIDTH + TOC_RIGHT_OFFSET + 32);

// STYLED COMPONENTS
/**
 * @internal
 */
export const StyledFooter = styled.footer.attrs(({
  inline
}) => ({
  as: inline ? 'section' : null
})).withConfig({
  displayName: "css__StyledFooter",
  componentId: "sc-1p6g1lf-0"
})(["margin:37px 5px;font-size:14px;color:", ";", ";", "{margin:64px 0;}", "{display:flex;flex-direction:column-reverse;padding:37px 10px 0 10px;border-top:1px solid ", ";}"], SLINKY, ({
  $width
}) => $width === 'article' && articleWidthCss, mediaQueries.aboveWidth(MOBILE_MENU_BREAKPOINT), mediaQueries.belowWidth(MOBILE_MENU_BREAKPOINT), EERIE);