"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77681100112;
const slug = exports.slug = 'guides/api/marketing/marketing-events';
const title = exports.title = 'API de marketing | Eventos de marketing';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Marketing Events';
const metaDescription = exports.metaDescription = 'Uma visão geral do objeto Eventos de marketing na HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-this-article",
  "label": "In this article",
  "parent": null
}, {
  "depth": 0,
  "id": "requisitos-do-escopo",
  "label": "Requisitos do escopo",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-e-atualizar-eventos",
  "label": "Criar e atualizar eventos",
  "parent": null
}, {
  "depth": 0,
  "id": "endpoints-de-participa%C3%A7%C3%A3o-em-eventos",
  "label": "Endpoints de participação em eventos",
  "parent": null
}, {
  "depth": 0,
  "id": "definir-configura%C3%A7%C3%B5es-de-aplicativo",
  "label": "Definir configurações de aplicativo",
  "parent": null
}, {
  "depth": 1,
  "id": "etapa-1-criar-uma-api-no-seu-app",
  "label": "Etapa 1: Criar uma API no seu app",
  "parent": "definir-configura%C3%A7%C3%B5es-de-aplicativo"
}, {
  "depth": 1,
  "id": "etapa-2-fornecer-%C3%A0-hubspot-o-caminho-da-url-para-sua-api",
  "label": "Etapa 2: Fornecer à HubSpot o caminho da URL para sua API",
  "parent": "definir-configura%C3%A7%C3%B5es-de-aplicativo"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      hr: "hr",
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Eventos de marketing"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um evento de marketing é um objeto de CRM, semelhante a contatos e empresas, que permite monitorar e associar eventos de marketing, como um webinar, a outros objetos CRM da HubSpot. Abaixo, saiba mais sobre como trabalhar com a API de eventos de marketing para integrar eventos de marketing a um app."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In this article"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Requisitos do escopo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Endpoints de participação em eventos"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-app-settings",
          children: "Definir configurações de aplicativo"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-1-create-an-api-in-your-app",
              children: "Etapa 1: Criar uma API no seu app"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-2-provide-hubspot-with-the-url-path-to-your-api",
              children: "Etapa 2: Fornecer à HubSpot o caminho da URL para sua API"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requisitos do escopo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para fazer uma solicitação de API para um dos endpoints de evento de marketing, são necessários os seguintes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "escopos"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.read"
        }), ": concede permissão para recuperar dados de eventos de marketing e de presença."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.write"
        }), ": concede permissão para criar, excluir ou fazer alterações nas informações do evento de marketing."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao autenticar chamadas feitas pelo seu app, você pode usar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "token de acesso de app privado"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), ". Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "métodos de autenticação"
      }), ". Para obter a lista completa de endpoints disponíveis, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar e atualizar eventos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode criar ou atualizar eventos de marketing enviando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para o ponto de extremidade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/upsert"
      }), ". Você pode incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CustomProperties"
      }), " que correspondam aos eventos que deseja atualizar no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " no corpo da solicitação, juntamente com outros detalhes do seu evento (incluindo nome, hora de início e descrição)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se já existir um evento de marketing com o ID especificado na solicitação, este será atualizado. Caso contrário, um novo evento será criado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, a seguinte solicitação criaria um evento com um ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "4"
      }), " chamado \"Virtual cooking class\":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/marketing-events/events/upsert\n{\n  \"inputs\": [\n    {\n      \"customProperties\": [\n        {\n          \"name\": \"property1\",\n          \"value\": \"1234\"\n        }\n      ],\n      \"eventName\": \"Virtual cooking class\",\n      \"startDateTime\": \"2023-11-30T17:46:20.461Z\",\n      \"eventOrganizer\": \"Chef Joe\",\n      \"eventDescription\": \"Join us for a virtual cooking class! Yum.\"\n      \"eventCancelled\": false,\n      \"externalAccountId\": \"CookingCo\",\n      \"externalEventId\": \"4\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode consultar uma lista completa de todos os campos disponíveis para incluir na sua solicitação clicando na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Endpoints de participação em eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os endpoints de status de participação no evento permitem que você registre um status de assinatura entre um contato do HubSpot e um evento de marketing. Por exemplo, você pode usar esse endpoint para registrar que um contato HubSpot se inscreveu em um evento de marketing."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existem dois endpoints que você pode usar para atualizar o status de participação de um contato. Se você estava usando os endpoints ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/upsert"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/email-upsert"
      }), " anteriormente para atualizar o status de um participante, você pode usar os endpoints listados abaixo para manter a mesma funcionalidade ao mesmo tempo em que preenche a duração da participação do contato na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/customize-activities-on-a-contact-company-deal-ticket-record-timeline",
        children: "linha do tempo do contato"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você quiser usar os IDs de contato de contatos existentes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/create"
            }), ", usando o ID do evento de seu aplicativo externo como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["No corpo da solicitação, forneça um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " que inclua os seguintes campos:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": a data e hora em que o contato se inscreveu para o evento."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": o ID de contato de um contato existente."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Caso queira usar o endereço de e-mail de um dos participantes do evento:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/email-create"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["No corpo da solicitação, forneça um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " que inclua os seguintes campos:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": a data e hora em que o contato se inscreveu para o evento."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "e-mail"
                }), ": o endereço de e-mail do participante como o valor do campo de e-mail em uma entrada"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se o endereço de e-mail que você incluir não corresponder ao endereço de um contato existente, um novo contato será criado."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ambos os endpoints acima, forneça os seguintes valores para os parâmetros de caminho correspondentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ": o ID do ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events#view-edit-and-analyze-marketing-events",
          children: "evento de marketing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriberState"
        }), ": uma enumeração que corresponda ao novo status de participação do contato:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INSCRITO"
        }), ": indica que o contato da HubSpot se inscreveu para o evento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "COMPARECEU"
        }), ": indica que o contato da HubSpot participou do evento. Se estiver atualizando o status de um contato para COMPARECEU, você também pode incluir os timestamps ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "joinedAt"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "leftAt"
        }), " como parâmetros no corpo da solicitação, especificados no formato ISO8601 Instant."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELADA"
        }), ": indica que o contato da HubSpot, que já havia se inscrito para o evento, cancelou sua inscrição."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma vez que seu evento esteja concluído, você poderá marcá-lo como concluído e calcular as métricas de participação (por exemplo, duração da presença de todos os participantes) fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/{externalEventId}/complete"
      }), ". Observe que essa ação é ", (0, _jsxRuntime.jsx)("u", {
        children: "irreversível"
      }), ", portanto, você só deve invocar esse endpoint depois que todos os eventos de alteração do status de participação já tiverem ocorrido."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " essas APIs são idempotentes, desde que o ID do contato e o valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "interactionDateTime"
        }), " no evento não sejam alterados. Isso permite que você defina com segurança o estado do assinante várias vezes sem que o HubSpot crie eventos duplicados nas propriedades de eventos de marketing."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Definir configurações de aplicativo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algumas configurações são necessárias para permitir que os eventos de marketing sejam sincronizados corretamente com a HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você enviar à HubSpot uma alteração no estado do assinante (por exemplo, uma inscrição ou cancelamento), a HubSpot primeiro verificará se existe um Evento de Marketing para o ID do evento especificado. Caso contrário, a HubSpot chamará o endpoint configurado do seu app para recuperar os detalhes do evento de marketing e, então, criar o evento na HubSpot e publicar a alteração do estado do assinante."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Isso é fornecido por conveniência. No entanto, ainda é recomendável que você mesmo crie os Eventos de Marketing por meio dos métodos CRUD fornecidos na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " na parte superior deste artigo, e não confie nessa funcionalidade para criar seus eventos de marketing na HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etapa 1: Criar uma API no seu app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para oferecer suporte a isso, o HubSpot exige que cada app que usa eventos de marketing defina uma API para obter informações sobre um evento de marketing específico."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Requisitos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aceita:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalAccountId"
            }), ": um parâmetro de consulta que especifica o accountId do cliente no aplicativo externo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            }), ": um parâmetro de consulta que especifica o ID do aplicativo HubSpot que está solicitando os detalhes do evento. Este será o ID do seu app."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), ": um parâmetro de caminho na URL da solicitação que especifica o ID do evento no aplicativo externo sobre o qual o HubSpot requer detalhes."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Retorna:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Um objeto JSON que fornece detalhes para o evento de marketing, que inclui os seguintes campos detalhados na tabela abaixo:"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome do campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obrigatório"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição do campo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do evento de marketing"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventOrganizer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do organizador do evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Descreve que tipo de evento é este. Por exemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBINAR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONFERÊNCIA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKSHOP"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A data e hora de início do evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A data e hora de término do evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A descrição do evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma URL no aplicativo de evento externo no evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventCancelled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "bool"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica se o evento de marketing foi cancelado. Assume o padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A HubSpot também enviará um cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " que você pode usar para verificar se a solicitação veio da HubSpot. Leia mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "request signatures"
      }), " para obter detalhes adicionais sobre a assinatura e como validá-la."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etapa 2: Fornecer à HubSpot o caminho da URL para sua API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você criou a API em seu app que retornará um objeto que fornece os detalhes de um evento de marketing específico, você vai precisar fornecer à HubSpot o caminho de URL para sua API fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{appId}/settings"
      }), ". Isso permitirá que a HubSpot determine como fazer solicitações ao seu app para obter os detalhes de um evento de marketing."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo de sua solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", especifique sua URL usando o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), ". A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " deve atender aos dois requisitos a seguir:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Conter uma sequência de caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "%s"
        }), " que a HubSpot usará para substituir no ID do evento (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ") como um parâmetro de caminho."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Deve ser o caminho completo para o recurso da API, incluindo o prefixo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://"
        }), " e o nome do domínio (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my.event.app"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, se você configurar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/%s"
      }), " e precisar fazer uma solicitação para obter detalhes de um evento com id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234-event-XYZ"
      }), " para o app da HubSpot com o id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app-101"
      }), " e conta com o id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ABC-account-789"
      }), ", a HubSpot fará uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/1234-event-XYZ?appId=app-101&externalAccountId=ABC-account-789"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}