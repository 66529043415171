"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937505;
const slug = exports.slug = 'guides/api/crm/properties';
const title = exports.title = 'API du CRM | Propriétés';
const name = exports.name = 'vNext Docs DP - Propriétés';
const metaDescription = exports.metaDescription = ' Les points de terminaison de propriétés de CRM permettent de gérer propriétés personnalisées ainsi que les détails par défaut des propriétés pour tout objet.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "propri%C3%A9t%C3%A9s-par-d%C3%A9faut",
  "label": "Propriétés par défaut",
  "parent": null
}, {
  "depth": 0,
  "id": "groupes-de-propri%C3%A9t%C3%A9s",
  "label": "Groupes de propriétés",
  "parent": null
}, {
  "depth": 0,
  "id": "type-de-propri%C3%A9t%C3%A9-et-valeurs-fieldtype",
  "label": "Type de propriété et valeurs fieldType",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-une-propri%C3%A9t%C3%A9",
  "label": "Créer une propriété",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-d-identifiant-unique",
  "label": "Créer des propriétés d'identifiant unique",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es",
  "label": "Créer des propriétés calculées",
  "parent": null
}, {
  "depth": 1,
  "id": "syntaxe-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es",
  "label": "Syntaxe des propriétés calculées",
  "parent": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es"
}, {
  "depth": 2,
  "id": "syntaxe-litt%C3%A9rale",
  "label": "Syntaxe littérale",
  "parent": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es"
}, {
  "depth": 2,
  "id": "syntaxe-de-propri%C3%A9t%C3%A9",
  "label": "Syntaxe de propriété",
  "parent": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es"
}, {
  "depth": 2,
  "id": "op%C3%A9rateurs",
  "label": "Opérateurs",
  "parent": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es"
}, {
  "depth": 2,
  "id": "fonctions",
  "label": "Fonctions",
  "parent": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es"
}, {
  "depth": 2,
  "id": "fonctions-conditionnelles",
  "label": "Fonctions conditionnelles",
  "parent": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es"
}, {
  "depth": 1,
  "id": "exemples-de-formules",
  "label": "Exemples de formules",
  "parent": "cr%C3%A9er-des-propri%C3%A9t%C3%A9s-calcul%C3%A9es"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-propri%C3%A9t%C3%A9s",
  "label": "Récupérer des propriétés",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-ou-effacer-les-valeurs-d-une-propri%C3%A9t%C3%A9",
  "label": "Mettre à jour ou effacer les valeurs d'une propriété",
  "parent": null
}, {
  "depth": 1,
  "id": "ajouter-des-valeurs-%C3%A0-des-propri%C3%A9t%C3%A9s-de-case-%C3%A0-cocher",
  "label": "Ajouter des valeurs à des propriétés de case à cocher",
  "parent": "mettre-%C3%A0-jour-ou-effacer-les-valeurs-d-une-propri%C3%A9t%C3%A9"
}, {
  "depth": 1,
  "id": "attribuer-des-propri%C3%A9taires-de-fiche-d-informations-%C3%A0-des-propri%C3%A9t%C3%A9s-d-utilisateur",
  "label": "Attribuer des propriétaires de fiche d'informations à des propriétés d'utilisateur",
  "parent": "mettre-%C3%A0-jour-ou-effacer-les-valeurs-d-une-propri%C3%A9t%C3%A9"
}, {
  "depth": 1,
  "id": "effacer-une-valeur-de-propri%C3%A9t%C3%A9",
  "label": "Effacer une valeur de propriété",
  "parent": "mettre-%C3%A0-jour-ou-effacer-les-valeurs-d-une-propri%C3%A9t%C3%A9"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Propriétés"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez les propriétés pour stocker des informations sur les fiches d'informations du CRM. HubSpot fournit un ensemble de propriétés par défaut pour chaque objet de CRM. Vous pouvez également créer et gérer vos propres propriétés personnalisées ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "dans HubSpot"
      }), " ou à l'aide de l'API de propriétés."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la création de propriétés, il est important de réfléchir à l'architecture de vos données. Dans de nombreux cas, la création de propriétés personnalisées pour les objets standard de HubSpot est la bonne marche à suivre. Cependant, vous devrez parfois créer un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objet personnalisé"
      }), " distinct avec son propre ensemble de propriétés."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriétés par défaut"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les objets de CRM sont définis par un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "type"
      }), " principal et un ensemble de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propriétés"
      }), ". Chaque type contient un ensemble de propriétés standards, représenté par une carte de noms et de valeurs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez-en davantage sur les propriétés par défaut pour différents objets :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contacts"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Entreprises"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Transactions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-activity-properties",
          children: "Activités"
        }), " (appels, e-mails, réunions, notes, tâches)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-lead-properties",
          children: "Leads"
        }), "(", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Pro"
        }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Groupes de propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties#create-and-edit-property-groups",
        children: "groupes de propriétés"
      }), " sont utilisés pour regrouper des propriétés associées. Toutes les propriétés regroupées apparaîtront les unes à côté des autres sur les fiches d'informations HubSpot. Si votre intégration crée des propriétés d'objets personnalisés, une propriété personnalisée facilitera l'identification de ces données."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Type de propriété et valeurs fieldType"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez ou mettez à jour des propriétés, les valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " sont obligatoires. La valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " détermine le type de propriété, c'est-à-dire une chaîne ou un nombre. La propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " détermine la façon dont la propriété apparaîtra dans HubSpot ou sur un formulaire, c'est-à-dire en tant que de texte brut, menu déroulant ou sélecteur de dates."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le tableau ci-dessous, découvrez les valeurs de propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " disponibles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsxs)(_components.th, {
            children: ["Valeurs valides ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            })]
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Champ contenant des options binaires (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Yes"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "No"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ")."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne représentant un ensemble d'options séparées par un point-virgule."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une valeur représentant un jour, un mois et une année spécifiques. Les valeurs doivent être représentées au format UTC et peuvent être formatées sous forme de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "chaînes ISO 8601 ou d'horodatages EPOCH en millisecondes (c.-à-d. minuit UTC)."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dateTime"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une valeur représentant un jour, un mois, une année et une heure spécifiques. Les valeurs doivent être représentées au format UTC et peuvent être formatées sous forme de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "chaînes ISO 8601 ou d'horodatages UNIX en millisecondes."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne de texte brut limitée à 65 536 caractères."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur numérique contenant des chiffres et au maximum une décimale."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object_coordinates"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur de texte utilisée pour référencer d'autres objets HubSpot, utilisée uniquement pour les propriétés internes. Les propriétés de ce type ne peuvent pas être créées ou modifiées et ne sont pas visibles dans HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur de texte stockée au format JSON, utilisée uniquement pour les propriétés internes. Les propriétés de ce type ne peuvent pas être créées ou modifiées et ne sont pas visibles dans HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Valeurs valides pour ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " incluent :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fieldtype"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une entrée qui permet aux utilisateurs de sélectionner Oui ou Non. Lorsque vous l'utilisez dans un formulaire, cette case sera affichée comme une case à cocher unique. Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "ajouter une valeur aux propriétés de case à cocher unique"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une équation personnalisée qui peut calculer des valeurs en fonction d'autres valeurs de propriété et/ou d'associations. Découvrez comment définir des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#create-calculation-properties",
              children: "propriétés calculées"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste de cases à cocher qui permet à un utilisateur de sélectionner plusieurs options à partir d'un ensemble d'options pour la propriété. Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "formater des valeurs lors de la mise à jour de propriétés avec plusieurs cases à cocher"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur de date, affichée comme un sélecteur de date."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet de télécharger un fichier sur une fiche d'informations ou via un formulaire. Stocke un ID de fichier."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne, restituée dans un format HTML nettoyé, qui permet l'utilisation d'un éditeur de texte enrichi pour la propriété."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne de chiffres ou de nombres sous forme décimale ou en notation scientifique."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne de texte brut affichée comme un numéro de téléphone formaté."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une entrée qui permet aux utilisateurs de sélectionner l'une des options autorisées pour la propriété. Lorsque vous l'utilisez dans un formulaire, elle sera affichée comme un ensemble de cases d'option."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une entrée déroulante qui permet aux utilisateurs de sélectionner l'une des options autorisées pour la propriété."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne de texte brut, affichée dans une entrée de texte sur une seule ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne de texte brut, affichée dans une entrée de texte sur plusieurs lignes."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer une propriété"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer une propriété, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/{objectType}"
      }), ". Dans le corps de votre demande, incluez les champs obligatoires suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "groupName"
        }), " : le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/organize-and-export-properties",
          children: "groupe de propriétés"
        }), " dans lequel la propriété se trouvera."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " : le nom interne de la propriété (par exemple : favorite_food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " : le nom de la propriété tel qu'il apparaît dans HubSpot (par exemple : plat préféré)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "type"
        }), " : le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#type",
          children: "type"
        }), " de propriété."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldType"
        }), " : le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-type",
          children: "type de champ"
        }), " de la propriété."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, pour créer une propriété de contact appelée ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Plat préféré"
      }), ", votre demande ressemblera à ceci :"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body POST crm/v3/properties/contacts\n{\n  \"groupName\": \"contactinformation\",\n  \"name\": \"favorite_food\",\n  \"label\": \"Favorite Food\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des propriétés d'identifiant unique"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une fiche d'informations est créée dans HubSpot, une ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "fiche d'informations ID"
      }), " (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") unique est générée automatiquement et doit être considérée comme une chaîne. Ces ID sont uniques pour chaque type d'objet seulement. Un contact et une entreprise peuvent donc avoir le même ID. Pour les contacts et les entreprises, il existe des identifiants uniques supplémentaires, y compris l'adresse e-mail d'un contact (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ") et le nom de domaine d'une entreprise (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans certains cas, vous pouvez créer votre propre propriété d'identifiant unique afin qu'il ne soit pas possible de saisir la même valeur pour plusieurs fiches d'informations. Vous pouvez avoir jusqu'à dix propriétés d'ID unique par objet. Pour créer une propriété nécessitant des valeurs uniques via l'API :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le corps de votre demande, pour le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hasUniqueValue"
        }), ", définissez la valeur sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body\n{\n  \"groupName\": \"dealinformation\",\n  \"name\": \"system_a_unique\",\n  \"label\": \"Unique ID for System A\",\n  \"hasUniqueValue\": true,\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois votre propriété d'ID unique créée, vous pouvez l'utiliser dans un appel d'API pour récupérer des fiches d'informations spécifiques. L'URL de la demande pourrait ressembler à ceci : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), ". Cela renverra la transaction avec la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "abc"
      }), " dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "system_a_unique"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ensuite utiliser cette valeur de propriété d'identifiant unique pour identifier et mettre à jour des fiches d'informations spécifiques de la même manière que vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " (contacts) ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " (entreprises)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer des propriétés calculées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les propriétés calculées définissent une valeur de propriété en fonction d'autres propriétés dans la même fiche d'informations d'objet. Elles sont définies à l'aide d'une formule, qui peut inclure des opérations telles que le minimum, le maximum, le compte, la somme ou la moyenne. Vous pouvez utiliser l'API de propriétés pour lire ou créer des propriétés calculées dans votre compte HubSpot, en utilisant un type de champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculation_equation"
      }), " et un type ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "number"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bool"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "string"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enumeration"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez définir la formule de calcul de la propriété avec le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : les propriétés calculées créées via l'API ", (0, _jsxRuntime.jsx)("u", {
          children: "ne peuvent pas"
        }), " être modifiées dans HubSpot. Vous pouvez modifier ces propriétés uniquement via l'API des propriétés."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Syntaxe des propriétés calculées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'aide de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), ", vous pouvez écrire votre formule avec des opérateurs arithmétiques, des opérateurs de comparaison, des opérateurs logiques, des instructions conditionnelles et d'autres fonctions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Syntaxe littérale"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Chaîne littérale"
        }), " : les chaînes constantes peuvent être représentées avec des guillemets simples (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'constant'"
        }), ") ou des guillemets doubles (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"constant\""
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nombre littéral"
        }), " : les nombres constants peuvent être n'importe quel nombre réel et peuvent inclure une notation ponctuelle. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1005"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.5589"
        }), " sont tous deux des nombres constants valides."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Booléen littéral"
        }), " : les booléens constants peuvent être ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Syntaxe de propriété"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variables de propriétés de chaîne :"
        }), " pour qu'une chaîne d'identificateurs soit interprétée comme une propriété de chaîne, elle doit être encadrée dans la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), ". Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string(var1)"
        }), " sera interprété comme la valeur de la propriété de chaîne var1."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variables de propriétés numériques"
        }), " : tous les identifiants seront interprétés comme des variables de propriétés numériques. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "var1"
        }), " sera interprété comme la valeur de la propriété numérique var1."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variables de propriétés booléennes"
        }), " : pour qu'un identifiant soit interprété comme une propriété booléenne, il doit être encadré dans la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool"
        }), ". Par exemple, l'identifiant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool(var1)"
        }), " sera interprété comme la valeur de la propriété booléenne var1."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " le texte utilisé est sensible à la casse pour tous les types, ", (0, _jsxRuntime.jsx)("u", {
          children: "à l'exception"
        }), " des chaînes. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "If A ThEn B"
        }), " est exactement identique à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if a then b"
        }), ", mais ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'a'"
        }), " est différent de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'A'"
        }), ". Les espaces, les onglets et les nouvelles lignes seront utilisés pour la tokenisation, mais seront ignorés."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Opérateurs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les opérateurs peuvent être utilisés avec des valeurs littérales et de propriété. Pour les opérateurs arithmétiques, vous pouvez utiliser la notation de préfixe pour multiplier et la parenthèse peut être utilisée pour spécifier l'ordre des opérations."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opérateur"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemples"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ajoutez des nombres ou des chaînes de caractères."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Soustrayez des nombres."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100 - property2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multipliez des nombres."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "10property1"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10 * property1"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divisez des nombres."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 * (100 - property2/(50 - property3))"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si une valeur est inférieure à une autre. Pris en charge par des propriétés ou des constantes de nombre."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a < 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si une valeur est supérieure à une autre. Pris en charge par des propriétés ou des constantes de nombre."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > 50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si une valeur est inférieure ou égale à une autre. Pris en charge par des propriétés ou des constantes de nombre."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a <= b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si une valeur est supérieure ou égale à une autre. Pris en charge par des propriétés ou des constantes de nombre."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b>= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si une valeur est égale à une autre. Pris en charge à la fois par des chiffres et des chaînes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "(a + b - 100c * 150.652) = 150-230b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "equals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si une valeur est égale à une autre. Pris en charge à la fois par des chiffres et des chaînes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a + b - 100.2c * 150 equals 150 - 230"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si une valeur est différente d'une autre. Pris en charge à la fois par des chiffres et des chaînes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string(property1) != 'test_string'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "or"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si l'une des deux valeurs est exacte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > b or b <= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "and"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si les deux valeurs sont vraies."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool(a) and bool(c)"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vérifiez si aucune des valeurs n'est vraie."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not (bool(a) and bool(c))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Fonctions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les fonctions suivantes sont prises en charge :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fonction"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemples"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aura entre 2 et 100 nombres d'entrées, et renverra le nombre maximum de toutes les entrées."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b, c, 100)"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "min"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aura entre 2 et 100 nombres d'entrées, et renverra le nombre minimum de toutes les entrées."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b, c, 100)"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Détermine si une expression peut être évaluée."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), "= true si la propriété est booléenne, mais ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), " = false si la propriété est vide ou non booléenne."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A deux chaînes comme entrées et renverra true si la première entrée contient la seconde."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('hello', 'ello')"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " tandis que ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('ello', 'hello')"
            }), " = false."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rejoint une liste de chaînes. La liste des entrées peut aller de 2 à 100."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate('a', 'b', string(a), string(b))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe également deux fonctions d'analyse :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number_to_string"
        }), " : tente de convertir l'expression numérique d'entrée en chaîne."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string_to_number"
        }), " : tente de convertir l'expression de chaîne d'entrée en nombre."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + num_cars"
      }), " n'est pas une propriété valide car vous ne pouvez pas ajouter une chaîne avec un nombre, mais ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + number_to_string(num_cars)"
      }), " est valide."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Fonctions conditionnelles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également saisir votre formule avec des fonctions conditionnelles en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, une fonction conditionnelle pourrait ressembler à : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if boolean_expression then statement [elseif expression then statement]* [else statement | endif]"
      }), " où les crochets ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[a]"
      }), " indiquent que a est facultatif, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a|b"
      }), " indique que a ou b fonctionnera, et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "*"
      }), " signifie 0 ou plus. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " peut être utilisé pour terminer une fonction conditionnelle prématurément, en veillant à ce que l'analyseur puisse identifier à quel élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " le prochain élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), " appartient."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemples de formules"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici des exemples que vous pouvez utiliser pour vous aider à définir vos propres formules de calcul :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"closed - started\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un exemple plus avancé avec des conditions :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"if is_present(hs_latest_sequence_enrolled_date) then\n  if is_present(hs_sequences_actively_enrolled_count) an hs_sequences_actively_enrolled_count >= 1 then\n    true\n  else\n    false\nelse\n  ''\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des propriétés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez récupérer des informations pour des propriétés individuelles ou toutes les propriétés d'un objet."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer une propriété individuelle, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{object}/{propertyName}"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), ". Par exemple, pour récupérer la propriété Plat préféré, l'URL de votre demande sera https://", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "api.hubspot.com/crm/v3/properties/contacts/favorite_food"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer toutes les propriétés d'un objet, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Lors de l'extraction de toutes les propriétés, seules les propriétés non sensibles sont renvoyées par défaut. Pour récupérer des propriétés de données sensibles, incluez le paramètre de demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), " avec la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sensitive"
        }), ". Pour en savoir plus sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/sensitive-data#manage-sensitive-data",
          children: "la gestion des données sensibles par API"
        }), " (BETA, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), " uniquement)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour ou effacer les valeurs d'une propriété"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour mettre à jour une valeur de propriété pour une fiche d'informations, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/{objectType}/{recordId}"
      }), ". Dans le corps de votre requête, incluez les propriétés et leurs valeurs dans un tableau. En savoir plus sur la mise à jour des fiches d'informations via les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "API objet"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter des valeurs à des propriétés de case à cocher"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de la mise à jour des valeurs pour les propriétés de case à cocher d'une fiche d'informations, formatez les valeurs selon les modalités suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propriété de case à cocher"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "booléenne"
        }), " : pour afficher comme ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Oui"
        }), " ou cochée dans HubSpot, votre valeur doit être ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". Pour afficher comme ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Non"
        }), " ou non coché dans HubSpot, votre valeur doit être ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propriété de case à cocher"
        }), " à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "sélection multiple"
        }), " : pour ajouter ou joindre des valeurs à une propriété à plusieurs cases à cocher, ajoutez un point-virgule avant la première valeur et séparez les valeurs avec des points-virgules sans espace au milieu. Si la propriété possède une valeur existante, le point-virgule principal ajoutera les valeurs au lieu de les remplacer. Par exemple, un contact a la valeur existante ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DECISION_MAKER"
        }), " pour la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_buying_role"
        }), ". Pour ajouter des valeurs supplémentaires sans remplacer la valeur existante, votre demande ressemblera à ceci :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example body for PATCH request to /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_buying_role\": \";BUDGET_HOLDER;END_USER\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Attribuer des propriétaires de fiche d'informations à des propriétés d'utilisateur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous attribuez des utilisateurs à des fiches d'informations de CRM via l'API, votre valeur doit être l'élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " du propriétaire de l'utilisateur, que vous pouvez trouver dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "paramètres de votre propriété"
      }), " ou via l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "API des propriétaires"
      }), ". Par exemple, pour affecter un utilisateur en tant que propriétaire d'un contact, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      }), ", avec le corps ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\":{ \"hubspot_owner_id\": \"41629779\"}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Effacer une valeur de propriété"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez effacer la valeur d'une propriété d'objet via l'API en définissant la valeur de la propriété sur une chaîne de caractères vide."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, pour effacer le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " d'un objet de contact, envoyez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/contacts/{contactId}"
      }), " avec le corps ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\": { \"firstname\": \"\"}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}