'use es6';

import * as extensions from './oas-extensions';
import getSchema from './get-schema';
import configureSecurity from './configure-security';
import removeUndefinedObjects from './remove-undefined-objects';
import findSchemaDefinition from './find-schema-definition';
import { parameterTypes } from './parameters-to-json-schema';
import { getRequestBodyExample } from '../../modules/Doc/utils/ExampleUtils';
import { stringify as hubstringify } from 'hub-http/helpers/params';
import formatStyle from './style-formatting';
const format = {
  value: v => v,
  example: v => v,
  key: v => v
};
function formatter(values, param, type, onlyIfExists) {
  if (param.style) {
    return formatStyle(values[type][param.name], param);
  }
  if (typeof values[type][param.name] !== 'undefined') {
    return format.value(values[type][param.name]);
  }
  if (onlyIfExists && !param.required) {
    return undefined;
  }
  if (param.required && param.example) {
    return format.example(param.example);
  }
  return format.key(param.name);
}
const defaultValues = Object.keys(parameterTypes).reduce((prev, curr) => {
  return Object.assign(prev, {
    [curr]: {}
  });
}, {});

// If you pass in types, it either uses a default, or favors
// anything JSON.
function getContentType(pathOperation) {
  const types = pathOperation && pathOperation.requestBody && pathOperation.requestBody.content && Object.keys(pathOperation.requestBody.content) || [];
  let type = 'application/json';
  if (types && types.length) {
    type = types[0];
  }

  // Favor JSON if it exists
  types.forEach(t => {
    if (t.match(/json/)) {
      type = t;
    }
  });
  return type;
}
function getResponseContentType(content) {
  const types = Object.keys(content) || [];
  let type = 'application/json';
  if (types && types.length) {
    type = types[0];
  }
  return type;
}
function isPrimitive(val) {
  return typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean';
}
function appendHarValue(harParam, name, value) {
  if (typeof value === 'undefined') return;
  if (Array.isArray(value)) {
    // If the formatter gives us an array, we're expected to add each array value as a new parameter item with the same parameter name
    value.forEach(singleValue => {
      appendHarValue(harParam, name, singleValue);
    });
  } else if (typeof value === 'object' && value !== null) {
    // If the formatter gives us an object, we're expected to add each property value as a new parameter item, each with the name of the property
    Object.keys(value).forEach(key => {
      appendHarValue(harParam, key, value[key]);
    });
  } else {
    // If the formatter gives us a non-array, non-object, we add it as is
    harParam.push({
      name,
      value: String(value)
    });
  }
}
export default (({
  oas,
  pathOperation = {
    path: '',
    method: ''
  },
  values = {},
  auth = {},
  opts = {
    proxyUrl: false
  }
}) => {
  const formData = Object.assign({}, defaultValues, values);
  const har = {
    headers: [],
    queryString: [],
    postData: {},
    method: pathOperation.method.toUpperCase(),
    url: `${oas.url()}${pathOperation.path}`.replace(/\s/g, '%20'),
    formData
  };

  // TODO look to move this to Oas class as well
  if (oas[extensions.PROXY_ENABLED] && opts.proxyUrl) {
    har.url = `https://try.readme.io/${har.url}`;
  }
  if (pathOperation.parameters) {
    pathOperation.parameters.forEach((param, i, params) => {
      if (param.$ref) {
        params[i] = findSchemaDefinition(param.$ref, oas);
      }
    });
  }
  har.url = har.url.replace(/{([-_a-zA-Z0-9[\]]+)}/g, (full, key) => {
    if (!pathOperation || !pathOperation.parameters) return key; // No path params at all
    // Find the path parameter or set a default value if it does not exist
    const parameter = pathOperation.parameters.find(param => param.name === key) || {
      name: key
    };
    return encodeURIComponent(formatter(formData, parameter, 'path'));
  });
  const queryStrings = pathOperation && pathOperation.parameters && pathOperation.parameters.filter(param => param.in === 'query');
  if (queryStrings && queryStrings.length) {
    queryStrings.forEach(queryString => {
      const value = formatter(formData, queryString, 'query', true);
      appendHarValue(har.queryString, queryString.name, value);
    });
  }
  const headers = pathOperation && pathOperation.parameters && pathOperation.parameters.filter(param => param.in === 'header');
  if (pathOperation.responses) {
    Object.keys(pathOperation.responses).some(response => {
      if (!pathOperation.responses[response].content) return false;

      // if there is an Accept header specified in the form, we'll use that instead.
      if (formData.header.Accept) return true;
      har.headers.push({
        name: 'Accept',
        value: getResponseContentType(pathOperation.responses[response].content)
      });
      return true;
    });
  }
  if (headers && headers.length) {
    headers.forEach(header => {
      const value = formatter(formData, header, 'header', true);
      if (typeof value === 'undefined') return;
      appendHarValue(har.headers, header.name, value);
    });
  }

  // x-headers static headers
  if (oas['x-headers']) {
    oas['x-headers'].forEach(header => {
      har.headers.push({
        name: header.key,
        value: String(header.value)
      });
    });
  }
  const schema = getSchema(pathOperation, oas) || {
    schema: {}
  };
  function stringify(json) {
    // Default to JSON.stringify
    return JSON.stringify(removeUndefinedObjects(typeof json.RAW_BODY !== 'undefined' ? json.RAW_BODY : json));
  }
  if (schema.schema && Object.keys(schema.schema).length) {
    // If there is formData, then the type is application/x-www-form-urlencoded
    if (Object.keys(formData.formData).length) {
      har.postData.text = formData.body = hubstringify(formData.formData);
    } else if (typeof formData.body !== 'undefined' && (isPrimitive(formData.body) || Object.keys(formData.body).length)) {
      try {
        // Find all `{ type: string, format: json }` properties in the schema
        // because we need to manually JSON.parse them before submit, otherwise
        // they'll be escaped instead of actual objects
        const jsonTypes = Object.keys(schema.schema.properties).filter(key => schema.schema.properties[key].format === 'json');
        if (jsonTypes.length) {
          // We have to clone the body object, otherwise the form
          // will attempt to re-render with an object, which will
          // cause it to error!
          let cloned = removeUndefinedObjects(JSON.parse(JSON.stringify(formData.body)));
          jsonTypes.forEach(prop => {
            // Attempt to JSON parse each of the json properties
            // if this errors, it'll just get caught and stringify it normally
            cloned[prop] = JSON.parse(cloned[prop]);
          });
          if (typeof cloned.RAW_BODY !== 'undefined') {
            cloned = cloned.RAW_BODY;
          }
          har.postData.text = JSON.stringify(cloned);
        } else {
          har.postData.text = stringify(formData.body);
        }
      } catch (e) {
        // If anything goes wrong in the above, assume that it's invalid JSON
        // and stringify it
        har.postData.text = stringify(formData.body);
      }
    }
    if (!har.postData.text) {
      const exampleRequest = getRequestBodyExample(pathOperation, oas, false);
      if (exampleRequest) {
        har.postData.text = exampleRequest.code;
      }
    }
  }

  // Add content-type header if there are any body values setup above ^^
  // or if there is a schema defined
  if (har.postData.text || Object.keys(schema.schema).length) {
    const type = getContentType(pathOperation);
    // set mimetype so json bodies will be pretty-printed and
    // url-encoded forms will be correctly rendered by httpsnippet
    if (type === 'application/json' || type === 'application/x-www-form-urlencoded') {
      har.postData.mimeType = type;
    }
    har.headers.push({
      name: 'Content-Type',
      value: type
    });
  }
  const securityRequirements = pathOperation.security || oas.security;
  if (securityRequirements && securityRequirements.length) {
    // TODO pass these values through the formatter?
    securityRequirements.forEach(schemes => {
      Object.keys(schemes).forEach(security => {
        const securityValue = configureSecurity(oas, auth, security);
        if (!securityValue) return;
        har[securityValue.type].push(securityValue.value);
      });
    });
  }
  return {
    log: {
      entries: [{
        request: har
      }]
    }
  };
});