"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164592946577;
const slug = exports.slug = 'reference/ui-components/overview';
const title = exports.title = 'UI拡張機能のコンポーネント';
const name = exports.name = 'UIコンポーネントの概要';
const metaDescription = exports.metaDescription = 'UI拡張機能を作成するために使用できるコンポーネントについて説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%A8%99%E6%BA%96%E3%82%B3%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%8D%E3%83%B3%E3%83%88",
  "label": "標準コンポーネント",
  "parent": null
}, {
  "depth": 0,
  "id": "crm%E3%83%87%E3%83%BC%E3%82%BF%E3%82%B3%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%8D%E3%83%B3%E3%83%88",
  "label": "CRMデータコンポーネント",
  "parent": null
}, {
  "depth": 0,
  "id": "crm%E3%82%A2%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%B3%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%8D%E3%83%B3%E3%83%88",
  "label": "CRMアクションコンポーネント",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "UIコンポーネントの概要（ベータ版）"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "UI拡張機能"
      }), "を作成する際は、HubSpotが提供する再利用可能なコンポーネントをいくつでも組み込むことができます。単純なテキストフィールドから、すぐに使えるCRMオブジェクトレポートに至るまでさまざまなコンポーネントがあり、各コンポーネントにはプロパティーを使用したカスタマイズオプションが備わっています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンポーネントは、拡張子が", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tsx"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jsx"
      }), "のファイルの先頭にインポートされます。コンポーネントの種類に応じて、2つのSDKディレクトリーのいずれかからインポートする必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#standard-components",
          children: "標準コンポーネント"
        }), "のインポート元：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions'"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-data-components",
          children: "CRMデータコンポーネント"
        }), "および", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-action-components",
          children: "CRMアクションコンポーネント"
        }), "のインポート元：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions/crm'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Alert, Text } from '@hubspot/ui-extensions';\nimport { CrmAssociationPivot, CrmActionLink } from '@hubspot/ui-extensions/crm';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**最新のコンポーネントにアクセスするには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extensions"
        }), "ディレクトリーで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i @hubspot/ui-extensions"
        }), "を実行して最新のnpmパッケージを既にインストールしていることを確認してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "標準コンポーネント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "標準コンポーネントは、内部データと外部データの両方に使用できるコンポーネントです。これらのコンポーネントは単独でデータを取得するわけではありませんが、より柔軟に実装できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのコンポーネントは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'@hubspot/ui-extensions'"
      }), "からインポートされます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "コンポーネント"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/accordion",
              children: "Accordion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "折りたたみ可能なアコーディオンセクション。ここに他のコンポーネントを含めることができます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/alert",
              children: "Alert"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "成功やエラーメッセージなど、ステータスやアクションの結果を示すためのアラートです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["レイアウト管理に使用されるコンポーネント。これを", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            }), "と併用できます。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "拡張機能のレイアウトの管理"
            }), "をご覧ください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがデータの送信や取得などのアクションを実行できるようになるボタン。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button-row",
              children: "Button row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "複数のボタンをレンダリングします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/checkbox",
              children: "Checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["単一のチェックボックス入力。複数のチェックボックスを表示するには、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/date-input",
              children: "DateInput"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーが日付を選択できるようになるフィールド。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/description-list",
              children: "DescriptionList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カスタムラベルと値のペアを表示します。これは、CRMレコードの左側サイドバーにHubSpotプロパティーが表示される方法と似ています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/divider",
              children: "Divider"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1つの拡張機能の中で複数のコンポーネントを分離するための水平線。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/dropdown",
              children: "Dropdown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンまたはハイパーリンクとしてスタイル設定された値を選択するためのドロップダウンメニュー。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/empty-state",
              children: "EmptyState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツのない状態のコンポーネントを示すラベル付きイラスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/error-state",
              children: "ErrorState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エラーを示すラベル付きイラスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display=flex"
            }), "に設定された空の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "div"
            }), "の、他のコンポーネントをラップします。このコンポーネントと", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            }), "を使用して、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "拡張機能のレイアウトを管理"
            }), "します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["データを送信するためのフォーム。ここに", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "などの他の関連コンポーネントを含めることができます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/heading",
              children: "Heading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "タイトルを示す大きなテキストをレンダリングします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/icon",
              children: "Icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "他のコンポーネントの中に視覚的なアイコンをレンダリングします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "主にロゴまたは他の視覚的なブランド アイデンティティ アセットを追加したり、他の拡張コンテンツを強調したりするために使用される画像。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テキスト入力フィールド。ユーザーはここでカスタムテキスト値を入力できます。主に", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "コンポーネント内で使用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "外部およびHubSpotアプリページに移動したり、関数をトリガーしたりするためのクリック可能なハイパーリンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/list",
              children: "List"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "特定の順序で、または順序なしで、アイテムのリストを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "新規"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "コンポーネントと同様ですが、読み込み状態のオプションが追加されています。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-spinner",
              children: "LoadingSpinner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カードが読み込み中または処理中であることを示す視覚的なインジケーター。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "新規"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["他のコンポーネントが入るポップアップオーバーレイ。短いメッセージやアクション確認ボックスに役立ちます。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), "、および", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "コンポーネントを使用して開くことができます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ユーザーが複数の値を選択できるドロップダウン選択フィールド。1つの値のみを選択できるようにするには、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), "を使用します。主に", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "コンポーネント内で使用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["数値入力フィールド。主に", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "コンポーネント内で使用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "新規"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページの右側に開き、他のコンポーネントが入るパネル。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), "、および", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "コンポーネントを使用して開くことができます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/progress-bar",
              children: "ProgressBar"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "正または負の目標に向かって動くデータを視覚的に表現したもの。数値とパーセンテージの両方を表示できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/radio-button",
              children: "RadioButton"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["1つのラジオ選択ボタン。複数のラジオボタンを含めたり、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            }), "を作成したりする場合には、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "を使用することをお勧めます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ユーザーが単一の値を選択できるドロップダウン選択フィールド。複数の値を選択できるようにするには、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/statistics",
              children: "Statistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1つ以上のデータポイントを表す視覚的なスポットライト。数値とトレンドインジケーター（増加／減少パーセンテージ）を含みます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/status-tag",
              children: "StatusTag"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "新規"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "項目の現在のステータスを表示する視覚的なインジケーター。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/step-indicator",
              children: "StepIndicator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "複数ステップからなるプロセスでの進捗状況を記述する視覚的なインジケーター。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/stepper-input",
              children: "StepperInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            }), "コンポーネントに似ていますが、このフィールドを使用すると、ユーザーは設定された量だけ値を増減できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/table",
              children: "Table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列と行でデータを表示します。テーブルをページ分割したり並べ替えたりすることができます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "情報やその他のコンポーネントを分類するための色付きラベル。静的にすることも、クリック可能にして関数をトリガーすることもできます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "書式設定オプションを使用してテキストをレンダリングします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tile",
              children: "Tile"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "関連するコンポーネントからなるグループを作成するための、長方形の境界線付きコンテナー。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text-area",
              children: "TextArea"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            }), "に似ていますが、より長いテキストセット用です。フィールドサイズ、最大文字数、サイズ可変性を設定するためのプロパティーが含まれています。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle",
              children: "Toggle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値トグルスイッチ。サイズとラベルオプションを設定できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択可能な複数のチェックボックスやラジオボタンからなるリスト。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRMデータコンポーネント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRMデータコンポーネントは、現在表示されているCRMレコードからデータを直接引き出すことができます（関連するレコードの情報や単一オブジェクトレポートを含む）。これらのコンポーネントを配置できる場所は、CRMレコードの中央列のみです。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-data-components/overview",
        children: "CRMデータコンポーネント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのコンポーネントは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "からインポートされます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "コンポーネント"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-pivot",
              children: "CrmAssociationPivot"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在表示されているレコードに関連するレコードからなるリスト。関連ラベルごとに整理されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-property-list",
              children: "CrmAssociationPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在表示されているレコードに関連するレコードに属するCRMプロパティーからなる編集可能なリスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-stage-tracker",
              children: "CrmAssociationStageTracker"
            }), "新規"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "関連付けられているレコードに含まれるデータを表示する、ライフサイクルまたはパイプラインのステージ進捗バー。インラインで編集できるプロパティーのリストを含めることができます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-table",
              children: "CrmAssociationTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在表示されているレコードに関連するレコードからなるテーブル。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-data-highlight",
              children: "CrmDataHighlight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在表示されているレコードまたは指定された別のレコードに属するCRMプロパティーからなるリスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-property-list",
              children: "CrmPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在表示されているレコードまたは指定された別のレコードに属するCRMプロパティーからなる編集可能なリスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-report",
              children: "CrmReport"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "既存の単一オブジェクトレポートを表示します。絞り込みオプションを含めたり、絞り込みなしで全てのレポートデータを表示したりできます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-stage-tracker",
              children: "CrmStageTracker"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロパティーのリストを含む、ライフサイクルまたはパイプラインのステージ進捗バー。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-statistics",
              children: "CrmStatistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在表示されているレコードの関連付けから計算されるデータの要約を表示します。例えば、あるコンタクトに関連付けられた複数の会社の平均収益です。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRMアクションコンポーネント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRMアクションコンポーネントは、CRMに関連する組み込みアクションセットを提供します。レコードにメモを追加する、1対1の電子メールの作成ウィンドウを開く、新しいレコードを作成するといった操作が可能です。各コンポーネントで同じアクションセットを実行できるので、どのコンポーネントを選択するかは実際のニーズと好みによります。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview",
        children: "CRMアクションコンポーネント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRMアクションコンポーネントは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "からインポートされます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "コンポーネント"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-button",
              children: "CrmActionButton"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "組み込みのCRMアクションセットを実行できるボタン。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-link",
              children: "CrmActionLink"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "組み込みのCRMアクションセットを実行できるクリック可能なリンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-card-actions",
              children: "CrmCardActions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "複数のCRMアクションを含めることができる、より小さなスタンドアロンまたはドロップダウン メニュー ボタン。"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}