"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61303889553;
const slug = exports.slug = 'guides/api/crm/engagements/email';
const title = exports.title = 'Engagements | Email';
const name = exports.name = 'Engagements | Email';
const metaDescription = exports.metaDescription = 'Use the email engagement API to log and manage emails on CRM records. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-an-email",
  "label": "Create an email",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-an-email"
}, {
  "depth": 1,
  "id": "read-only-properties",
  "label": "Read only properties",
  "parent": "create-an-email"
}, {
  "depth": 1,
  "id": "set-email-headers",
  "label": "Set email headers",
  "parent": "create-an-email"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-an-email"
}, {
  "depth": 0,
  "id": "retrieve-emails",
  "label": "Retrieve emails",
  "parent": null
}, {
  "depth": 0,
  "id": "update-emails",
  "label": "Update emails",
  "parent": null
}, {
  "depth": 1,
  "id": "associate-existing-emails-with-records",
  "label": "Associate existing emails with records",
  "parent": "update-emails"
}, {
  "depth": 1,
  "id": "remove-an-association",
  "label": "Remove an association",
  "parent": "update-emails"
}, {
  "depth": 0,
  "id": "pin-an-email-on-a-record",
  "label": "Pin an email on a record",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-emails",
  "label": "Delete emails",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Email"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the email engagement API to log and manage emails on CRM records. You can log email activities either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "in HubSpot"
      }), " or through the emails API."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn the basic methods of managing emails through the API. To view all available endpoints and their requirements, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/email",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an email"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create an email engagement, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, add email details in a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), " object. You can also add an ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), " object to associate your new email with an existing record (e.g., contacts, companies)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the properties object, you can include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Required. This field marks the email's time of creation and determines where the email sits on the record timeline. You can use either a Unix timestamp in milliseconds or UTC format."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID of the owner"
            }), " associated with the email. This field determines the user listed as the email creator on the record timeline."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The direction the email was sent in. Possible values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            }), ": the email was sent from the CRM or sent and logged to the CRM with the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "BCC address."
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCOMING_EMAIL"
            }), ": the email was a reply to a logged outgoing email. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FORWARDED_EMAIL"
            }), ": the email was ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "forwarded to the CRM."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The body of an email if it is sent from a CRM record."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The send status of the email. The value can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BOUNCED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SCHEDULED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENDING"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The subject line of the logged email."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The body of the email."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The IDs of the email's attachments. Multiple attachment IDs are separated by a semi-colon."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The email's headers. The value for this property will automatically populate certain read only email properties. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/email#set-email-headers",
              children: "set email headers."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch creating email engagements by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Read only properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are also some email properties that are read only, which are automatically populated by HubSpot. The properties in the table below are all automatically populated from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), " value."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address of the email's sender."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email sender's first name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email sender's last name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email addresses of the email's recipients."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The first names of the email's recipients."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The last names of the email recipient."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when retrieving an email header, you may notice there are values both for ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), ". These are often the same, but because ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " identifies what actually submitted an email, there are scenarios where the values may differ. For example, if an email is sent from an email alias, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " value will refer to the user's actual email address, and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " value will refer to the email alias."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set email headers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Since headers automatically populate the read only properties, you may want to manually set the email headers. To set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), " value, you can use a JSON escaped string with the following data:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example data\n{\n  \"from\": {\n    \"email\": \"from@domain.com\",\n    \"firstName\": \"FromFirst\",\n    \"lastName\": \"FromLast\"\n  },\n  \"to\": [\n    {\n      \"email\": \"ToFirst ToLast<to@test.com>\",\n      \"firstName\": \"ToFirst\",\n      \"lastName\": \"ToLast\"\n    }\n  ],\n  \"cc\": [],\n  \"bcc\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request to create an email may look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"47550177\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for youremail\",\n    \"hs_email_headers\": \"{\\\"from\\\":{\\\"email\\\":\\\"from@domain.com\\\",\\\"firstName\\\":\\\"FromFirst\\\",\\\"lastName\\\":\\\"FromLast\\\"},\\\"sender\\\":{\\\"email\\\":\\\"sender@domain.com\\\",\\\"firstName\\\":\\\"SenderFirst\\\",\\\"lastName\\\":\\\"SenderLast\\\"},\\\"to\\\":[{\\\"email\\\":\\\"ToFirst+ToLast<to@test.com>\\\",\\\"firstName\\\":\\\"ToFirst\\\",\\\"lastName\\\":\\\"ToLast\\\"}],\\\"cc\\\":[],\\\"bcc\\\":[]}\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create and associate an email with existing records, include an associations object in your request. For example, to create an email and associate it with a deal and a contact, your request body might look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 601\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 210\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 602\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 198\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the associations object, you should include the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The record you want to associate with the email, specified by its unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " value."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the association between the email and the record. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Default association type IDs are listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), ", or you can retrieve the value for custom association types (i.e. labels) via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#retrieve-association-types",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve emails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can retrieve emails individually or in bulk. Learn more about batch retrieval by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve an individual email by its email ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), ". You can also include the following parameters in the request URL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of object types to retrieve associated IDs for. Any specified associations that don't exist will not be returned in the response. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4",
              children: "associations API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To request a list of all of emails, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/emails"
      }), ". You can include the following parameters in the request URL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of results to display per page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update emails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update emails individually or in batches. To update an individual email by its email ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request body, include the email properties that you want to update. For example, your request body might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk tomorrow\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect!\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will ignore values for read-only and non-existent properties. To clear a property value, pass an empty string for the property in the request body."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch updating by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associate existing emails with records"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate an email with records, such as a contact and its associated companies, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". The request URL contains the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emailId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the email."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of object that you want to associate the email with (e.g., contact or company)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the record that you want to associate the email with."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A unique identifier to indicate the association type between the email and the other object. The ID can be represented numerically or in snake case (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_to_contact"
            }), "). You can retrieve the value through the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request URL might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/emails/17691787884/associations/contact/104901/198"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remove an association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association between an email and a record, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the same URL as above:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pin an email on a record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "pin an email"
      }), " on a record so it remains on the top of the record's timeline. The email must already be associated with the record prior to pinning, and you an only pin one activity per record. To pin an email, include the email's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " field when creating or updating a record via the object APIs. Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "companies,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contacts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "deals"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom objects"
      }), " APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete emails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you delete an email, it is permanently deleted and ", (0, _jsxRuntime.jsx)("u", {
        children: "cannot"
      }), " be restored. You can delete emails individually or in batches."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an individual email by its email ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch deleting by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpoints"
      }), " tab at the top of this article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}