/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

/*
 * Good reads
 * https://medium.com/reloading/a-link-rel-preload-analysis-from-the-chrome-data-saver-team-5edf54b08715
 * https://medium.com/reloading/preload-prefetch-and-priorities-in-chrome-776165961bbf
 * https://www.smashingmagazine.com/2016/02/preload-what-is-it-good-for/
 */
import codemirrorUrlBasis from 'bender-url!codemirror/codemirror.css';
import reactCodemirrorUrlBasis from 'bender-url!react-codemirror/theme/hubspot-canvas-dark.sass';
import Theme from 'react-codemirror/enum/Theme';
import { THEME_VALUES } from 'react-codemirror/util/Theme';
import { once } from './miniUnderscore.js';
const CSS_CLASSNAME = 'hs-codemirror-css';
const PRIMARY_CSS_ID = 'hs-codemirror-css-primary';
const privy = new WeakMap();
const styleSheetCache = {};

/**
 * @type {Promise} Determines of DOM has been loaded.
 */
const domLoaded = new Promise(resolve => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', resolve);
  } else {
    resolve();
  }
});

/**
 * Feature detects if <link rel="preload" /> is supported
 *
 * @returns {Boolean}
 */
const isLinkPreloadSupported = once(() => {
  const link = document.createElement('link');
  if (!(link.relList && link.relList.supports)) {
    return false;
  }
  return link.relList.supports('preload');
});

/*
 * Create static base URIs to static CSS files.
 */
const [CODEMIRROR_URL_PREFIX, REACT_CODEMIRROR_URL_PREFIX] = [codemirrorUrlBasis, reactCodemirrorUrlBasis].map(url => {
  const a = document.createElement('a');
  a.href = url;
  const {
    hostname,
    pathname
  } = a;
  const path = pathname.split('/').filter(Boolean).slice(0, 2).join('/');
  return `https://${hostname}/${path}`;
});

/**
 * Creates full url/href to CSS static file.
 *
 * @param {String} repo
 * @param {String} path
 * @returns {String}
 */
function createHref(repo, path) {
  let prefix;
  switch (repo) {
    case 'codemirror':
      prefix = CODEMIRROR_URL_PREFIX;
      break;
    case 'react-codemirror':
      prefix = REACT_CODEMIRROR_URL_PREFIX;
      break;
    default:
      throw new TypeError(`Unknown repo ${repo}`);
  }
  const separator = path && path[0] === '/' ? '' : '/';
  return `${prefix}${separator}${path}`;
}

/**
 * `codemirror.css` which has order priority over other CSS.
 */
const PRIMARY_CSS_HREF = createHref('codemirror', '/codemirror.css');

/**
 * Insert CSS <link> in <head>.
 * - Links will be inserted at the top of <head> to avoid clobbering
 *   overrides that users may have added.
 * - The primary CSS file (/codemirror.css) should be first.
 *   Other CodeMirror CSS should follow it.
 *
 * @param {HTMLElement} link
 * @returns {Promise}
 */
function insertCSSLink(link) {
  return new Promise((resolve, reject) => {
    domLoaded.then(() => {
      const isPrimaryCSS = link.id === PRIMARY_CSS_ID;
      // Primary CSS should appear in DOM before other CodeMirror CSS
      if (isPrimaryCSS) {
        const primaryEl = document.getElementById(PRIMARY_CSS_ID) || document.head.insertAdjacentElement('afterbegin', link);
        if (primaryEl) {
          resolve(primaryEl);
        } else {
          reject(new Error(`Failed to insert primary CodeMirror CSS: ${link.href}`));
        }
        return;
      }
      const links = document.head.getElementsByClassName(CSS_CLASSNAME);
      let linkEl;
      if (links.length) {
        linkEl = Array.prototype.find.call(links, ({
          href
        }) => href === link.href);
        if (!linkEl) {
          const lastLink = links[links.length - 1];
          linkEl = lastLink.insertAdjacentElement('afterend', link);
        }
      } else {
        linkEl = document.head.insertAdjacentElement('afterbegin', link);
      }
      if (linkEl) {
        resolve(linkEl);
      } else {
        reject(new Error(`Failed to insert CodeMirror CSS: ${link.href}`));
      }
    }, reject);
  });
}

/**
 * Inserts a <link> element in head for `href` if not already done.
 * Will use `rel="preload"` if supported.
 *
 * @type {Function}
 * @param {String} href
 * @param {Object} options
 * @returns {Promise}
 */
const loadCSS = (() => {
  function __loadCSS(href, options = {}) {
    const {
      preload = true
    } = options;
    // Already done and cached?
    const cachedPromise = styleSheetCache[href];
    if (cachedPromise) {
      return cachedPromise;
    }
    // Link already added?
    const existingSheet = Array.prototype.find.call(document.styleSheets, sheet => sheet.href === href);
    if (existingSheet) {
      return styleSheetCache[href] = Promise.resolve(existingSheet);
    }
    // Add link
    const isPrimaryCSS = href === PRIMARY_CSS_HREF;
    const link = document.createElement('link');
    const promise = new Promise((resolve, reject) => {
      link.href = href;
      link.as = 'style';
      link.type = 'text/css';
      link.rel = preload && isLinkPreloadSupported() ? 'preload' : 'stylesheet';
      link.className = CSS_CLASSNAME;
      if (isPrimaryCSS) {
        link.id = PRIMARY_CSS_ID;
      }
      link.onload = evt => {
        link.rel = 'stylesheet';
        link.onload = null;
        link.onerror = null;
        resolve(evt);
      };
      link.onerror = reject;
      insertCSSLink(link);
    });
    styleSheetCache[href] = promise;
    return promise;
  }
  // Ensure that primary CSS is always loaded.
  return function (...args) {
    return Promise.all([__loadCSS(PRIMARY_CSS_HREF), __loadCSS(...args)]);
  };
})();

/**
 * Loads CSS from specified repo.
 *
 * @param {String} repo     Name of repo with CSS
 * @param {String} path     Relative path of CSS file
 * @param {Object} options  Passed to loadCSS()
 */
function loadRepoCSS(repo, path, options) {
  const href = createHref(repo, path);
  return loadCSS(href, options);
}

/**
 * Wraps public load functions to allow their requests to be delayed.
 *
 * @param {Function} loadFunction
 */
function fetchWrapper(loadFunction) {
  return {
    fetch: loadFunction
  };
}

/**
 * Loads a CSS file from the `codemirror` repackage.
 *
 * @param {String} Path to CSS file (path after '/codemirror/static-x')
 * @returns {Promise}
 */
export function loadCodeMirrorCSS(path) {
  return fetchWrapper(() => loadRepoCSS('codemirror', path));
}

/**
 * Loads a CSS file from the `react-codemirror` repo.
 *
 * @param {String} Path to CSS file (path after '/react-codemirror/static-x')
 * @returns {Promise}
 */
export function loadReactCodeMirrorCSS(path) {
  return fetchWrapper(() => loadRepoCSS('react-codemirror', path));
}

/**
 * Loads a theme.
 *
 * @param {Theme} theme
 * @returns {Promise}
 */
export function loadTheme(theme) {
  return fetchWrapper(() => {
    if (!THEME_VALUES.has(theme)) {
      return Promise.reject(new TypeError(`Requested unknown theme '${theme}'`));
    }
    switch (theme) {
      case Theme.DEFAULT:
        return Promise.resolve(`Theme ${Theme.DEFAULT} is built into CodeMirror`);
      case Theme.HUBSPOT_CANVAS_DARK:
      case Theme.HUBSPOT_CANVAS_LIGHT:
        return loadReactCodeMirrorCSS(`/theme/${theme}.css`).fetch();
      case Theme.SOLARIZED_DARK:
      case Theme.SOLARIZED_LIGHT:
        return loadCodeMirrorCSS('/theme/solarized.css').fetch();
      default:
        return loadCodeMirrorCSS(`/theme/${theme}.css`).fetch();
    }
  });
}

/**
 * Class to encapsulate CSS load state and promises for components.
 */
export class ComponentCSSManager {
  constructor(loads = []) {
    privy.set(this, {
      loads,
      isFetching: false,
      isResolved: false,
      promise: null
    });
  }
  fetch() {
    const state = privy.get(this);
    if (state.promise && (state.isFetching || state.isResolved)) {
      return state.promise;
    }
    state.isFetching = true;
    const loads = state.loads || [];
    state.promise = Promise.all(loads.map(load => load.fetch()));
    state.promise.then(results => {
      state.isResolved = true;
      return results;
    }, err => {
      console.error('CodeMirror CSS failed to load: %o', err);
      state.promise = null;
      return err;
    });
    state.promise.finally(() => {
      state.isFetching = false;
    });
    return state.promise;
  }
  get isFetching() {
    return privy.get(this).isFetching;
  }
  get isResolved() {
    return privy.get(this).isResolved;
  }
  get promise() {
    return privy.get(this).promise;
  }
}