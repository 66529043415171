"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611090;
const slug = exports.slug = 'guides/cms/overview';
const title = exports.title = 'Resumen sobre CMS Hub';
const name = exports.name = 'Resumen sobre CMS Hub';
const metaDescription = exports.metaDescription = 'Resumen de alto nivel del Content Hub de HubSpot para desarrolladores, mostrando todos los conceptos clave como temas, plantillas y módulos, y cómo encajan entre sí.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "facilitaci%C3%B3n-de-contenido",
  "label": "Facilitación de contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "tipos-de-contenido",
  "label": "Tipos de contenido",
  "parent": null
}, {
  "depth": 1,
  "id": "p%C3%A1ginas-web-y-p%C3%A1ginas-de-destino",
  "label": "Páginas web y páginas de destino",
  "parent": "tipos-de-contenido"
}, {
  "depth": 1,
  "id": "blogs",
  "label": "Blogs",
  "parent": "tipos-de-contenido"
}, {
  "depth": 1,
  "id": "correos-electr%C3%B3nicos",
  "label": "Correos electrónicos",
  "parent": "tipos-de-contenido"
}, {
  "depth": 0,
  "id": "contenido-estructurado",
  "label": "Contenido estructurado",
  "parent": null
}, {
  "depth": 0,
  "id": "sistema-de-archivos-para-desarrolladores",
  "label": "Sistema de archivos para desarrolladores",
  "parent": null
}, {
  "depth": 0,
  "id": "temas%2C-plantillas%2C-m%C3%B3dulos-y-campos",
  "label": "Temas, plantillas, módulos y campos",
  "parent": null
}, {
  "depth": 1,
  "id": "temas",
  "label": "Temas",
  "parent": "temas%2C-plantillas%2C-m%C3%B3dulos-y-campos"
}, {
  "depth": 1,
  "id": "plantillas",
  "label": "Plantillas",
  "parent": "temas%2C-plantillas%2C-m%C3%B3dulos-y-campos"
}, {
  "depth": 1,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": "temas%2C-plantillas%2C-m%C3%B3dulos-y-campos"
}, {
  "depth": 1,
  "id": "campos",
  "label": "Campos",
  "parent": "temas%2C-plantillas%2C-m%C3%B3dulos-y-campos"
}, {
  "depth": 0,
  "id": "el-lenguaje-hubl",
  "label": "El lenguaje HubL",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      li: "li",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen sobre CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta sección está diseñada para ayudarte a entender los aspectos clave del ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS"
        })
      }), " de HubSpot y construir grandes sitios web en él. Para sacar el máximo provecho de esto, se espera una comprensión a nivel profesional de los fundamentos del desarrollo web, incluyendo HTML, JavaScript y CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si eres nuevo en la construcción del CMS, consulta primero el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "tutorial de inicio rápido"
      }), " y luego consulta este resumen según sea necesario."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Facilitación de contenido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub está diseñado para ayudar a las empresas a aumentar su presencia en la web con énfasis en permitir a los especialistas en marketing crear y administrar contenido. El contenido del sitio web, la recopilación de leads y los análisis se integran con el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/crm",
        children: "CRM de HubSpot"
      }), ", lo que facilita la creación de experiencias personalizadas para los visitantes y la integración de esas experiencias con el resto del negocio."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como desarrollador de CMS Hub, no solo crearás recursos como temas, plantillas y módulos, sino que lo harás de una manera que permita a los creadores de contenido personalizarlos con contenido según sea necesario. De esta forma, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "deberás"
        })
      }), " equilibrar las necesidades de los desarrolladores con las de los creadores de contenido a través de su sistema de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " y de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastrar y soltar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/code-no-code-graphic.png",
        alt: "Las plantillas y los módulos están en la intersección entre los desarrolladores y los profesionales de marketing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un sitio de CMS Hub bien elaborado debe desarrollarse en estrecha colaboración con los creadores de contenido para entender sus necesidades, y luego requerirá menos apoyo y mantenimiento continuo de un desarrollador. Con ese fin, te recomendamos que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow#testing",
        children: "anticipes cómo se ve y se siente la experiencia de creación de páginas para los creadores de contenido"
      }), " mientras creas. De este modo, se garantiza que puedan trabajar de forma independiente con el sitio en la medida de lo posible."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/making%20a%20page.gif",
        alt: "Animación que muestra al usuario creando una página de arrastrar y soltar"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se encarga de alojar y mantener tus páginas, lo que significa que no tienes que preocuparte por la gestión de los plugins, las actualizaciones, el alojamiento, la expansión o la seguridad. La compensación es que el sistema pone algunas restricciones más en lo que se puede hacer en comparación con los CMS autoalojados. Por ejemplo, no puedes alterar o ampliar los fundamentos del sistema de forma manual o a través de plugins, manipular el renderizado de bajo nivel o acceder y alterar el contenido de la base de datos directamente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El contenido creado por los desarrolladores (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "plantillas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), ", JavaScript y CSS) se crea en un sistema de archivos para desarrolladores, mientras que el contenido de las páginas (páginas, publicaciones de blog) se diseña y construye en un potente editor basado en bloques, \"lo que ves es lo que obtienes\" (WYSIWYG), y los archivos multimedia (imágenes creadas por los creadores de contenido, PDF, etc.) se almacenan en un gestor de archivos basado en la aplicación web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando se renderiza una página, HubSpot dirige la solicitud a uno de los muchos servidores basados en el dominio, renderiza la página en nuestros servidores y la almacena en caché en una red de distribución de contenido (CDN) si es posible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de contenido"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay muchos tipos de contenido que creas utilizando el CMS de HubSpot. La interfaz de usuario de los creadores de contenido es ligeramente diferente según el tipo de contenido, lo que tiene implicaciones que tú, como desarrollador, debes tener en cuenta."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Páginas web y páginas de destino"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El sitio web y las páginas de destino se construyen de forma independiente, pero todas las páginas se basan en plantillas. Para los creadores de contenido, el proceso de creación de una página de destino o una página de sitio web es casi idéntico. La distinción entre ellas es que las páginas web están hechas para presentar información que forma parte de tu sitio web y están diseñadas para ser encontradas orgánicamente, mientras que una página de destino está ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/marketing/landing-page-best-practices",
        children: "generalmente asociada a una oferta o campaña de marketing específica"
      }), " (por ejemplo, vinculada a un correo electrónico de marketing enviado a una lista específica de contactos)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la interfaz de usuario para los profesionales del marketing, la analítica y la organización de estos tipos de páginas también se organizan por separado, ya que las páginas de destino suelen tener objetivos de conversión específicos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los blogs de HubSpot tienen dos vistas: una para la página de catálogo y otra para la página de la publicación individual. Entonces, cada publicación del blog se rellena en cada una de ellas. Puedes establecer un blog para compartir la misma plantilla en publicaciones de blog y páginas de catálogo, o tener plantillas separadas para la página de catálogo y para publicaciones de blog. Las publicaciones de blog deben compartir la misma plantilla. Más información sobre el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "marcado de plantillas de blog"
      }), " y sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/manage-your-blog-template-and-settings",
        children: "cómo crear y administrar blogs en HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Correos electrónicos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los correos electrónicos se pueden crear de varias maneras en HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Correo electrónico clásico:"
        }), " crea plantillas y módulos de correo electrónico de manera similar al sitio web y las páginas de destino. También puedes crear ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/email-template-markup",
          children: "plantillas de correo electrónico codificadas"
        }), " para tener el control total del marcado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Correos electrónicos de arrastrar y soltar:"
        }), " crea plantillas de correo electrónico de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
          children: "arrastrar y soltar"
        }), " personalizables que permitan a los creadores de contenido producir diseños y contenido de correo electrónico utilizando la interfaz de arrastrar y soltar de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenido estructurado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de crear contenido para la página a través de los editores en la aplicación o la codificación en plantillas, también puedes usar fuentes de datos estructuradas para rellenar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "contenido de la página dinámica"
      }), " con HubL. Puedes usar las siguientes fuentes de datos para rellenar páginas:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
          children: "HubDB"
        }), ": almacena datos en celdas de tablas de HubDB."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
          children: "Registros del CRM"
        }), ": almacena datos en registros del CRM, como contactos, empresas u objetos personalizados"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La creación de páginas dinámicas con contenido estructurado significa que puedes crear, editar y eliminar páginas del sitio web y contenido de las páginas actualizando las fuentes de datos directamente. Al igual que un blog de HubSpot, un conjunto de páginas dinámicas incluirá una sola página de catálogo para mostrar las instancias de la fuente de datos, y luego una página aparte para cada instancia individual. Con HubL, puedes configurar completamente los datos que muestran las páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, puedes crear una tabla de HubDB que almacene una fila de información por cada miembro de un equipo de ventas. Usando esa tabla de HubDB, HubSpot puede generar una página de contenido para mostrar detalles clave de cada fila de la tabla (como un nombre e imagen para cada representante de ventas), junto con una página aparte por representante de ventas para mostrar más información (como su biografía y número de teléfono). Si un representante de ventas luego es promovido a un equipo diferente, puedes eliminar su fila de la tabla de HubDB y HubSpot eliminará automáticamente su página de detalles y lo eliminará de la página de contenido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sistema de archivos para desarrolladores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los materiales principales, que son las plantillas, los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), ", así como el JavaScript, los archivos CSS y las imágenes que los apoyan, se crean en un sistema de archivos para desarrolladores. Puedes ver este sistema de archivos en el panel izquierdo del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "administrador de diseño"
      }), " o en las carpetas sincronizadas localmente mediante las herramientas de desarrollo local. Dentro del sistema de archivos, los materiales pueden referirse entre sí con rutas absolutas o relativas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entre bastidores, estos archivos se asignan a entradas en una base de datos. Por ello, el acceso al sistema de archivos para desarrolladores se realiza a través de las herramientas de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " de HubSpot en lugar de acceder directamente por SSH o FTP, y algunas características del sistema de archivos que puedes esperar, como los permisos y los enlaces simbólicos, no se ofrecen en el sistema de archivos para desarrolladores."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto difiere del enfoque de los CMS tradicionales, pero significa que las referencias rotas entre archivos o los errores de sintaxis se detectan en el momento de la publicación y no en el tiempo de ejecución, lo que te proporciona un aislamiento adicional contra los fallos accidentales cuando el tráfico en directo llega a un sitio web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas en el sistema de archivos se detectarán automáticamente y se presentará a los creadores de contenido cuando estén haciendo nuevas páginas, así que la estructura del sistema de archivos depende de ti. No es necesario que los módulos estén en una carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/modules/"
      }), " o que JavaScript esté en una carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/js/"
      }), ". Sin embargo, se recomienda que organices tus materiales de manera similar al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "código de ejemplo de plantilla para el CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " de forma predeterminada, HubSpot minimiza automáticamente el JavaScript y el CSS incluidos en el administrador de diseño para eliminar espacios, saltos de línea y comentarios innecesarios. Esto también se aplica a JavaScript y CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "cargados en el administrador de diseño a través de la CLI"
        }), ". Esto significa que no debes añadir código ya minimizado directamente al administrador de diseño."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-and-css-minification",
          children: "minimización de JavaScript y CSS"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas, plantillas, módulos y campos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), ", las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "plantillas"
      }), ", los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " y los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " son los objetos con los que más trabajarás. El uso de estos diferentes objetos te permite dar a los creadores de contenido la libertad de trabajar e iterar en los sitios web de forma independiente mientras se mantienen dentro de los límites de estilo y diseño que tú estableces."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los temas y módulos contienen campos, que son configuraciones de tipos de datos específicos, como números, cadenas, texto enriquecido e imágenes. Puedes controlar cómo se utilizan en la representación de estos objetos, así como la forma en que deben organizarse y aparecer en el editor ", (0, _jsxRuntime.jsx)("abbr", {
        title: "What You See Is What You Get",
        children: "WYSIWYG"
      }), ". Los creadores de contenido pueden establecer valores para los campos en el editor WYSIWYG, que se aplican al tema o módulo en el momento de la renderización."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Temas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " son los objetos de nivel superior que definen el aspecto de un sitio web y crean una experiencia de edición de contenidos sencilla. Estos materiales pueden incluir plantillas, módulos, archivos CSS, archivos JavaScript, imágenes y más."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al usar temas, puedes crear un conjunto de campos que los creadores de contenido utilizan para obtener un control estilístico global sobre un sitio web sin tener que editar el CSS. Puedes especificar en CSS dónde se aplican estos controles, organizar los controles para que hereden de otros y gestionar cómo se presentan y organizan para los profesionales de marketing en el Editor de temas. Los creadores de contenido utilizan el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-fields",
        children: "editor de temas"
      }), " para modificar los campos de tema, previsualizar esos cambios con respecto a las plantillas existentes dentro de un tema y publicar sus cambios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Estos campos temáticos pueden establecerse globalmente en un sitio o anularse a nivel de página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Theme%20Editor.png",
        alt: "Editor de temas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Plantillas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "plantillas"
      }), " definen el marcador base y el estilo de un conjunto de páginas que utilizan una plantilla. Pueden contener HubL, HTML y enlaces a archivos de JavaScript y CSS, y pueden ser restringidas para usarse con tipos de contenido específicos. Tienes total flexibilidad en el marcador y el estilo que puedes incluir en una plantilla, pero te recomendamos que te adhieras a algunas prácticas recomendadas y que utilices algunas características clave de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " para asegurar que los profesionales de marketing puedan editar las páginas fácilmente. Algunas de las mejores prácticas incluyen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Construir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview",
          children: "plantillas"
        }), " como parte de un tema y utilizar CSS a nivel de tema, incluyendo campos de tema, para hacer la mayor parte del estilo dentro de una plantilla. Esto facilitará a los creadores de contenidos la realización de cambios de estilo globales y locales de forma coherente sin necesidad de entrar en la edición de CSS."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El uso de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "módulos"
        }), " para la mayoría de los componentes de tu página, lo que permite que sean reordenados y reutilizados a en un sitio web. Más información sobre los módulos a continuación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "áreas de arrastrar y soltar"
        }), " siempre que sea posible para el contenido de la página principal, especialmente en las páginas internas. Las áreas de arrastrar y soltar te permiten establecer un diseño predeterminado para los módulos que componen una página, pero ofrecen a los profesionales de marketing flexibilidad para editar el diseño y el estilo de forma independiente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
          children: "parciales globales"
        }), " para contener contenido compartido como encabezados y pies de página que se desea que tengan un aspecto coherente en todo el sitio web."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas pueden construirse con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), " o con una interfaz de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "arrastrar y soltar"
      }), " en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "administrador de diseño"
      }), ". Sin embargo, se recomienda codificar plantillas, ya que estas proporcionan más control y funcionalidad que las plantillas de arrastrar y soltar, como admitir áreas de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "arrastrar y soltar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Homepage%20Template%20html.png",
        alt: "VS Code con HTML y plantilla HubL abierta"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " son componentes reutilizables que puedes colocar en plantillas y páginas. Incluyen controles para los profesionales de marketing y contienen marcas HubL/HTML, CSS y JavaScript que, en conjunto, construyen componentes reutilizables y editables de un sitio web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los controles de un módulo se definen en campos, por lo que construir un gran módulo significa considerar tanto la apariencia resultante en una página, como la experiencia de edición para los editores de contenido."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "_HubSpot proporciona un conjunto de _"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "módulos predeterminados"
      }), ", como encabezados, texto enriquecido, imágenes, botones y CTA, que usarás como componentes fundamentales. También es probable que desees crear elementos que tengan diseños más interesantes que se ajusten a tu tema y tus plantillas. Algunos ejemplos comunes de módulos que puedes querer construir son acordeones, deslizadores y tabuladores."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Module%20form%20in%20editor.png",
        alt: "Formulario del módulo en el editor"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes pensar en un módulo como un objeto y en los módulos de las páginas como instancias de ese objeto, lo que significa que las actualizaciones de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), ", CSS o JavaScript de un módulo se aplican a todas las instancias de ese módulo dentro de las páginas o plantillas de un determinado portal. Los módulos también son transportables en los portales, tanto si se utiliza la herramienta de copia integrada en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "administrador de diseño"
      }), ", como si se venden en el mercado o se comparte el código directamente con las herramientas de desarrollo local. Esto significa que puedes implementar una solución de diseño una vez y usarla en todas las páginas y portales, y cuando necesites actualizarla, esos cambios pueden aplicarse en todas las páginas sin tener que editar varias páginas o varias plantillas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los módulos también pueden incluirse en los temas, lo que permite utilizar los campos de los temas para manipular el aspecto de los módulos y garantizar que se muestren de forma destacada en el editor de páginas, de modo que los creadores de contenidos puedan acceder fácilmente a los módulos que quedarán bien con los diseños que hayas creado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "guía de resumen de los módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Campos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " son los controles que los creadores de contenido utilizan para ajustar los parámetros pasados a sus temas y módulos. Los campos se escriben, incluyendo tipos simples como booleano, texto, URL, elección y archivo, pero también tienen campos más complejos como fuente con estilo, así como campos específicos de HubSpot como enlaces a otras piezas de contenido o formularios en el sistema HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos también se pueden colocar dentro de repetidores que pasarán una matriz al módulo; un ejemplo de esto podría ser un carrusel de imágenes en el que quieres un conjunto de imágenes con el texto `alt` asociado. En lugar de crear varios campos de imagen y de texto, puedes crear uno de cada uno y ponerlos en un grupo repetido."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los campos de un módulo se especifican dentro del administrador de diseño o con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#the-user-interface-for-editing",
        children: "esta sintaxis en un archivo fields.json."
      }), " Los campos de un tema deben especificarse en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " en la raíz del tema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "El lenguaje HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El lenguaje principal que utilizarás para crear recursos de sitios web en el CMS de HubSpot es el lenguaje de marcado de HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "o"
      }), " HubL (se pronuncia como \"Hubble\"). HubL es la extensión de HubSpot de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", un motor de plantillas basado en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://palletsprojects.com/p/jinja/",
        children: "Jinja"
      }), ". HubL utiliza una buena cantidad de marcadores que son exclusivos de HubSpot y no son compatibles con todas las características de Jinja. Se ejecuta completamente en el lado del servidor cuando se renderiza una página."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL tiene las características que esperarías de un lenguaje de plantillas simple como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "variables"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "bucles for"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "sentencias if"
      }), ", pero también admite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macros"
      }), " de renderizado más complejas, obtención de datos y mapeo con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "etiquetas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "funciones"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filtros"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si alcanzas los límites de las posibilidades que ofrecen HubL y el sistema de CMS, HubSpot te ofrece API para crear más soluciones personalizadas. Las cuentas de **", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Content Hub"
      }), " **", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " pueden usar las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "funciones sin servidor"
      }), ", que permiten una programación más sofisticada del lado del servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes consultar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "referencia del lenguaje HubL"
      }), " para obtener más detalles sobre las características específicas del lenguaje."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}