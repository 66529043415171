"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 41185540911;
const slug = exports.slug = 'guides/cms/content/content-search';
const title = exports.title = 'コンテンツ検索';
const name = exports.name = 'APAC JAPAN (JA) | [new] Content Search | 202103';
const metaDescription = exports.metaDescription = 'HubSpotのCMSに搭載されているサイト検索機能「コンテンツ検索」の使い方。HubSpotのCMSには、探しているコンテンツを訪問者が簡単に見つけられるように、サイト検索機能が組み込まれています。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E6%A4%9C%E7%B4%A2",
  "label": "コンテンツの検索",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%B8%E3%81%AE%E6%A4%9C%E7%B4%A2%E3%81%AE%E5%AE%9F%E8%A3%85",
  "label": "ウェブサイトへの検索の実装",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%82%A4%E3%83%88%E6%A4%9C%E7%B4%A2%E5%85%A5%E5%8A%9B",
  "label": "サイト検索入力",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%B8%E3%81%AE%E6%A4%9C%E7%B4%A2%E3%81%AE%E5%AE%9F%E8%A3%85"
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%82%A4%E3%83%88%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C",
  "label": "サイト検索結果",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%B8%E3%81%AE%E6%A4%9C%E7%B4%A2%E3%81%AE%E5%AE%9F%E8%A3%85"
}, {
  "depth": 0,
  "id": "%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "検索結果テンプレート",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%A4%9C%E7%B4%A2%E5%84%AA%E5%85%88%E9%A0%86%E4%BD%8D%E3%81%AE%E6%B1%BA%E5%AE%9A%E6%96%B9%E6%B3%95",
  "label": "検索優先順位の決定方法",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%8B%E7%99%BA%E4%B8%AD%E3%81%AE%E3%82%A4%E3%83%B3%E3%83%87%E3%83%83%E3%82%AF%E3%82%B9%E3%81%AE%E5%88%B6%E5%BE%A1",
  "label": "開発中のインデックスの制御",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%82%A4%E3%83%B3%E3%83%87%E3%83%83%E3%82%AF%E3%82%B9%E7%99%BB%E9%8C%B2%E5%8B%95%E4%BD%9C",
  "label": "既定のインデックス登録動作",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%8C%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C%E3%81%A7%E8%BF%94%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E3%82%88%E3%81%86%E3%81%AB%E9%99%A4%E5%A4%96%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%EF%BC%9F",
  "label": "ページが検索結果で返されないように除外するには？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E4%B8%80%E9%83%A8%E3%82%92%E6%A4%9C%E7%B4%A2%E3%81%AB%E3%82%88%E3%82%8B%E3%82%A4%E3%83%B3%E3%83%87%E3%83%83%E3%82%AF%E3%82%B9%E7%99%BB%E9%8C%B2%E3%81%8B%E3%82%89%E9%99%A4%E5%A4%96%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95",
  "label": "ページの一部を検索によるインデックス登録から除外する方法",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      img: "img",
      ol: "ol",
      li: "li",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "コンテンツ検索"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSには、探しているコンテンツを訪問者が簡単に見つけられるように、サイト検索機能が組み込まれています。貴社の全てのHubSpotコンテンツはHubSpotの検索エンジンによって自動的にインデックス登録されるため、ウェブサイト向けにサードパーティー検索ツールを設定する労力はなくなります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツの検索"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["検索エンジンには", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "サイト検索API"
      }), "からアクセスできます。このAPIは、高度にカスタマイズされた検索機能をウェブサイト上で提供するための多数のフィルタリングオプションをサポートしています。例えば、ブログに検索機能を組み込む場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type=BLOG_POST"
      }), "を問い合わせるとブログ記事のみが返されます。また、ウェブサイトのスペイン語バージョンに検索を組み込む場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "language=es"
      }), "を問い合わせるとスペイン語のページのみが返されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このAPIではJavaScriptで解析しやすいJSONが返されるので、ウェブサイトに結果を表示できます。全てのコンテンツタイプから、ページドメイン、タイトル、URL、言語が返されます。説明として返されるのは、検索語に最も一致するコンテンツのテキストのサンプルです。完全一致テキストを囲む", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span class=\"hs-search-highlight hs-highlight-html\">"
      }), "要素では、CSSを使用して一致テキストを強調表示できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "検索対象のコンテンツのタイプによって返される結果の情報は多少異なるため、コンテンツタイプに応じて結果の表示を変えることができます。例えばブログ記事では、記事に付けられているタグ、執筆者、公開日の情報が返されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// json\n{\n  \"total\": 850,\n  \"offset\": 0,\n  \"limit\": 10,\n  \"results\": [\n    {\n      \"id\": 3761238962,\n      \"score\": 0.30858153,\n      \"type\": \"SITE_PAGE\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://developers.hubspot.com/docs/cms/search\",\n      \"language\": \"en\",\n      \"title\": \"Content Search\",\n      \"description\": \"Using HubSpot’s native search engine, content search, to implement <span class=\"hs-search-highlight hs-highlight-html\">search on your website</span>\"\n    },\n    {\n      \"id\": 3254581958,\n      \"score\": 0.30858153,\n      \"type\": \"BLOG_POST\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://designers.hubspot.com/blog/getting-started-with-local-development\",\n      \"featuredImageUrl\":\"https://designers.hubspot.com/hubfs/local-development.jpg\"\n      \"title\": \"Getting Started with Local Development\",\n      \"description\":\"The beta Local Development Tooling connects your local workflow to HubSpot, allowing you to utilize <span class=\"hs-search-highlight hs-highlight-html\">version control</span>, your favorite text editor and various web development technologies when developing on the HubSpot CMS.\"\n      \"authorFullName\": \"William Spiro\",\n      \"tags\": [\n        \"Website Development\",\n        \"Local Development\"\n      ],\n      \"publishedDate\": 1447938000000\n    }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイトへの検索の実装"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "サイト検索API"
      }), "を使用する2つの既定のモジュール（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_input"
      }), "および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_results"
      }), "）を使用すると、簡単にウェブサイトに検索を実装できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サイト検索入力"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["訪問者が検索語を入力するフィールド。このモジュールは、ウェブサイト上の任意の場所に配置できます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}"
      }), "を使用してこのモジュールをテンプレートに含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サイト検索結果"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "検索語に基づいてコンテンツのリストを返します。このモジュールは、ウェブサイト上の任意の場所に配置できます。このモジュールは、次のようにテンプレートに含めることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このようなモジュールの機能が、自分のウェブサイトに必要な検索機能と合わない場合は、独自の検索モジュールまたは機能を開発できます。既定の検索モジュールは、検索ニーズに応じて拡張できるように設計されています。こうしたモジュールは、ファインダーで名前を検索してデザインマネージャー上で表示および複製したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "上で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_input.module"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_results.module"
      }), "を実行してローカル環境に取り込むことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/default%20search%20module.png",
        alt: "既定の検索モジュール"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "検索結果テンプレート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのドメインには、既定で固有の検索結果ページがあります。このページのテンプレートとパスは、特定のドメインの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "［システムページ］タブ"
      }), "にある［設定］>［ウェブサイト］>［ページ］で設定します。例として、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "CMSテーマボイラープレート検索結果テンプレート"
      }), "を参照してください。検索結果ページに設定されたドメインは、自動的に既定の検索モジュールに接続されます。ただし、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "サイト検索API"
      }), "を使用すると、ウェブサイトの任意のページ上で独自の検索結果を作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "検索優先順位の決定方法"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "検索結果の順位は、訪問者の検索語に対して重み付けられたページコンテンツの比較によって決まります。ページコンテンツは、ページのHTML内でのコンテンツの位置に基づいて、さまざまな重みの比較フィールドとして分類されます。比較フィールドは重みの順にグループ化されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTMLタイトル"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "メタディスクリプション"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H1 HTML要素"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H2 HTML要素"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "H3 HTML要素"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "その他のHTML要素"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このリストは変更される場合があります。ご注意ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページ上の特定の要素の重みを高めるようインデクサーに指示するには、該当するコンテンツを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-keyword"
      }), "クラスで囲みます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "開発中のインデックスの制御"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "新しいサイトを開発中は、Google などの検索エンジンによってサイトがインデックス登録されることを心配することなく、サイト検索をテストできると便利です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), "では、全てをクローリングするようHubSpotに指定しながら、他のボットをブロックすることが可能です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /\n\n# Allow HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nAllow: /\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["登録しないことを示すメタタグがあるページは、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), "で許可されていてもインデックス登録されません。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["また、サイトを公開する前に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), "を確認して、全てのインデックス登録が意図したとおりに設定されていることを確かめてください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既定のインデックス登録動作"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CMSでは特定のタイプのページの意図が認識されるため、コンテンツ検索によってコンテンツタイプのページが適切に登録されるように、インデックス登録が安全に制御されます。コンテンツタイプのページの例："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サイトページ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ランディングページ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ナレッジ記事"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ブログ記事"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**システムページとパスワード保護ページはインデックス登録されません。**CMSアクセス制限ページは、該当するページにサインインしてアクセスできるユーザーのHubSpotコンテンツ検索にのみ表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページが検索結果で返されないように除外するには？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイトの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), "ファイルまたは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta"
      }), "タグを使用して検索エンジンへの登録をブロックしたページは、サイト検索のためのインデックス登録が行われません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "robots.txt"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "disallow"
      }), "を追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /path-to-page\n\n# Block just HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nDisallow: /path-to-page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページまたはテンプレートの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "NOINDEX, NOFOLLOW"
      }), "メタタグを追加することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<meta name=\"ROBOTS\" content=\"NOINDEX, NOFOLLOW\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), "とメタタグの両方を使用してロボットをブロックする必要はありません。後でページのインデックス登録を許可することにした場合に混乱が生じる可能性があるからです。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページの一部を検索によるインデックス登録から除外する方法"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページによっては、検索結果として返される必要がない領域が含まれています。例えば、ヘッダー、フッター、サイドバーなどのコンテンツです。このような領域のHTMLには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-hidden"
      }), "クラスを追加すると、領域内のコンテンツが検索によって無視されます。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}