"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38575989237;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-completions';
const title = exports.title = 'Envoyez les événements personnalisés terminés';
const name = exports.name = 'Envoyez les événements personnalisés terminés';
const metaDescription = exports.metaDescription = `Les événements personnalisés permettent de suivre les activités avancées avec les API JavaScript ou HTTP. L'API d'événements peut être utilisée pour obtenir des détails sur vos événements.`;
const toc = exports.toc = [{
  "depth": 0,
  "id": "d%C3%A9finir-l-%C3%A9v%C3%A9nement",
  "label": "Définir l'événement",
  "parent": null
}, {
  "depth": 0,
  "id": "envoyer-des-donn%C3%A9es-d-%C3%A9v%C3%A9nements",
  "label": "Envoyer des données d'événements",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-donn%C3%A9es-d-%C3%A9v%C3%A9nements",
  "label": "Récupérer des données d'événements",
  "parent": null
}, {
  "depth": 0,
  "id": "propri%C3%A9t%C3%A9s-d-%C3%A9v%C3%A9nement",
  "label": "Propriétés d'événement",
  "parent": null
}, {
  "depth": 0,
  "id": "rapport-d-attribution",
  "label": "Rapport d'attribution",
  "parent": null
}, {
  "depth": 1,
  "id": "type-d-%C3%A9l%C3%A9ment",
  "label": "Type d'élément",
  "parent": "rapport-d-attribution"
}, {
  "depth": 1,
  "id": "titre-de-ressource",
  "label": "Titre de ressource",
  "parent": "rapport-d-attribution"
}, {
  "depth": 1,
  "id": "sources-d-interaction",
  "label": "Sources d'interaction",
  "parent": "rapport-d-attribution"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      em: "em",
      strong: "strong",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Envoyez les événements personnalisés terminés"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les événements personnalisés sont des événements définis par le compte qui stockent les détails de l'événement dans des propriétés d'événement. Il existe trois types d'événements personnalisés que vous pouvez créer dans HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les événements ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "d'élément cliqué"
        }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "d'URL visitée"
        }), " sont des événements personnalisés dont le code de suivi renseigne automatiquement avec des données. Vous pouvez personnaliser davantage ces événements en mettant à jour votre code de suivi avec la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code#events-js-api",
          children: "trackCustomEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
          children: "Les événements suivis manuellement"
        }), " sont des événements personnalisés propres à votre entreprise et qui ne sont pas capturés automatiquement par HubSpot ou par une intégration. Vous pouvez envoyer manuellement des données à ces événements via cette API."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous comment créer un événement personnalisé suivi manuellement, envoyer des données d'événement via l'API et comment utiliser ces données une fois capturées."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Définir l'événement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour envoyer des données d'événements terminés à HubSpot, vous devez d'abord ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "définir l'événement lui-même"
      }), ", y compris ses métadonnées, ses associations d'objets CRM et ses propriétés. Vous pouvez définir des événements en utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions",
        children: "l'API de définition d'événement personnalisée"
      }), ", ou si vous disposez d'un abonnement à ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ", vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
        children: "créer l'événement dans HubSpot"
      }), ". Lors de la création de l'événement, HubSpot inclura un ensemble de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "propriétés d'événement par défaut"
      }), " que vous pourrez utiliser pour stocker des données d'événement. Vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "créer des propriétés supplémentaires"
      }), " pour l'événement. Ces propriétés peuvent être créées ou modifiées à tout moment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez configuré votre événement, vous pouvez lui envoyer des données via l'API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Envoyer des données d'événements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour envoyer des données d'événement à HubSpot, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/events/v3/send"
      }), " avec vos données d'événement dans le corps de la requête. Avant d'envoyer des données d'événement, vérifiez les limites ci-dessous, car le dépassement de ces limites entraînera une erreur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST to https://api.hubspot.com/events/v3/send\n{\n  \"eventName\": \"pe1234567_login_event\",\n  \"objectId\": \"608051\",\n  \"occurredAt\": \"2024-06-28T12:09:31Z\",\n  \"properties\": {\n    \"hs_city\": \"Cambridge\",\n    \"hs_country\": \"United States\",\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\",\n    \"hs_touchpoint_source\": \"DIRECT_TRAFFIC\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le nom interne de l'événement. Vous pouvez le trouver en ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "interrogeant vos définitions d'événements existantes"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/reports/create-custom-behavioral-events-with-the-code-wizard#find-internal-name",
              children: "dans l'application HubSpot"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : l'ID de la fiche d'informations du CRM auquel l'événement sera associé. Pour les contacts, vous pouvez également utiliser le champ ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utk"
            }), " pour identifier le contact par adresse e-mail ou par un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code",
              children: "jeton d'utilisateur HubSpot"
            }), ". Tous les autres types d'objets nécessitent ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : par défaut, HubSpot définit l'horodatage d'événements terminés à l'heure d'envoi de la demande. Pour spécifier l'heure de fin de l'événement, incluez un horodatage dans un champ ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            }), " du corps de la demande ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " (", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_8601",
              children: "format ISO 8601"
            }), "). Cela peut être particulièrement utile pour antidater les données d'événements afin de refléter plus précisément les événements terminés réels."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : les propriétés d'événement auxquelles envoyer des données. Cela peut inclure les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
              children: "propriétés d'événement par défaut de HubSpot"
            }), " ou toute autre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
              children: "propriété personnalisée"
            }), " que vous avez définie pour l'événement. La plupart des propriétés d'événement par défaut sont des propriétés de chaîne, mais vous pouvez vérifier toutes les propriétés d'événements en ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "interrogeant la définition de l'événement"
            }), " ou en accédant à l'événement dans HubSpot. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#event-properties",
              children: "les propriétés d'événement"
            }), " ci-dessous."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Le dépassement de l'une des limites suivantes entraînera l'échec de la demande :"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Le libellé de la propriété et le nom interne sont limités à 50 caractères."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Les propriétés d'URL et de référent peuvent contenir jusqu'à 1 024 caractères, tandis que toutes les autres propriétés peuvent contenir jusqu'à 256 caractères."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Chaque événement peut contenir des données pour un maximum de 50 propriétés."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Les noms internes des propriétés doivent commencer par une lettre et ne contenir que des lettres minuscules de a à z, des chiffres de 0 à 9 et des tirets bas."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Les propriétés ayant le même nom interne après la mise en minuscule sont considérées comme des doublons, et une seule des propriétés sera utilisée à la fin du processus. HubSpot triera par ordre lexicographique croissant et conservera la dernière propriété vue parmi les 50 premières propriétés."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Le nombre de définitions d'événement unique est limité à 500 par compte."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Le nombre d'événements terminés est limité à 30 millions par mois."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des données d'événements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "récupérer les données d'événement d'un contact"
      }), ", effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId={CONTACT_ID}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La demande ci-dessus comprend :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "eventType :"
        }), " le nom interne de l'événement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType :"
        }), " le type d'objet de la fiche d'informations."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectId :"
        }), " l'ID du contact."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriétés d'événement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les données d'événement sont stockées dans des propriétés, soit dans l'ensemble des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "propriétés d'événement par défaut"
      }), ", soit dans des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "propriétés personnalisées définies"
      }), ". Lorsque vous envoyez des données d'événement, vous pouvez mettre à jour les propriétés en les incluant dans un objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"properties\": {\n    \"property1\": \"string\",\n    \"property2\": \"string\",\n    \"property3\": \"string\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les valeurs que vous enverrez dépendront du type de propriété d'événement. La plupart des propriétés d'événements par défaut sont des textes à une ligne (chaîne). Toutefois, vous pouvez créer des propriétés personnalisées de tout type pour chaque événement. Passez en revue le tableau ci-dessous lors du formatage de valeurs de propriétés."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type de propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne représentant un ensemble d'options. Lorsque vous envoyez plusieurs valeurs, séparez-les par un point-virgule. Dans HubSpot, ce type correspond aux propriétés de menu déroulant, de case d'option et de plusieurs cases à cocher."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un horodatage sous forme de millisecondes epoch ou conformément à la norme ISO8601. Dans HubSpot, ce type correspond aux propriétés de sélecteur de dates."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une chaîne de texte brut limitée à 65 536 caractères. Dans HubSpot, ce type correspond aux propriétés de texte à une ligne et à plusieurs lignes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur numérique contenant des chiffres et au maximum une décimale. Dans HubSpot, ce type correspond aux propriétés calculées et aux propriétés de nombre."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour afficher les propriétés disponibles d'un événement :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, naviguez vers ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reporting"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gestion des données"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Événements personnalisés"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de l'événement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propriétés"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans le tableau des propriétés, affichez le type de propriété sous le nom de la propriété."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-event-properties-table.png",
        alt: "custom-event-properties-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rapport d'attribution"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les événements JavaScript tels que les événements ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "d'élément cliqué"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "d'URL visitée"
      }), " sont automatiquement renseignés avec le type d'élément et les données d'interaction pour les rapports d'attribution. Pour inclure les mêmes données pour les événements suivis manuellement, vous devrez inclure manuellement les données dans le corps de la demande en utilisant les propriétés d'événement. En savoir plus sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/analyze-custom-behavioral-events",
        children: "l'analyse des événements personnalisés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous les valeurs disponibles pour les types de ressources et les sources d'interaction, ainsi que des exemples de demandes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Type d'élément"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour attribuer un type de ressource spécifique à une demande d'événement comportemental personnalisé, incluez la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_content_type"
      }), " dans le corps de la demande. Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Vous pouvez également utiliser la propriété ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), ". Si ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), " sont tous les deux inclus dans une seule demande, ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " remplacera la valeur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les types de contenu standard de HubSpot, tels que les pages de destination et les articles de blog, peuvent être représentés avec les valeurs suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valeur"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "STANDARD_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec une page de site web."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec une page de destination."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec un article de blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec un article de la base de connaissances."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour tous les autres types de ressources, utilisez les valeurs suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valeur"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec une publicité, telle qu'une publicité Facebook ou Google."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec un appel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT_IMPORT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction via un import de contact"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction liée à une conversation HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CUSTOM_BEHAVIORAL_EVENT_NAME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le nom interne d'un événement personnalisé, comme ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pe123456_manually_tracked_event"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec un e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec une page externe."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction via une intégration."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une interaction avec un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "événement marketing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEDIA_BRIDGE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une interaction via la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/cms/media-bridge",
              children: "passerelle multimédia"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec une réunion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec un e-mail de vente individuel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SEQUENCE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec une séquence."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec un post sur les réseaux sociaux."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une interaction avec une ressource n'appartenant à aucune des catégories ci-dessus."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Titre de ressource"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour attribuer un événement personnalisé à une ressource, incluez la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_asset_title"
      }), " dans votre demande, avec le nom de la ressource formaté sous forme de chaîne. Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_title\": \"Sweepstakes Sign Up\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sources d'interaction"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour attribuer un événement comportemental personnalisé à une source spécifique, incluez la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_touchpoint_source"
      }), " dans votre demande avec l'une des valeurs suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valeur"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est une conversation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DIRECT_TRAFFIC"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est du trafic direct."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_MARKETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est un e-mail marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_CRM"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est le CRM de HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est une intégration."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La source d'interaction est un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "événement marketing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OFFLINE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est hors ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ORGANIC_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est une recherche naturelle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER_CAMPAIGNS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction provient d'une campagne sans catégorie."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est une publicité payante sur les moteurs de recherche."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SOCIAL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est une publicité de réseaux sociaux payante."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "REFERRALS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est un renvoi."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est les ventes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_MEDIA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source d'interaction est les réseaux sociaux (pas une publicité de réseaux sociaux payante)."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}