"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611103;
const slug = exports.slug = 'guides/cms/content/global-content';
const title = exports.title = 'Contenu global';
const name = exports.name = 'Contenu global';
const metaDescription = exports.metaDescription = `Le contenu global est un contenu qui peut être réutilisé dans tous vos modèles pour créer des éléments d'information cohérents, tels que des en-têtes de site Web, des pieds de page, des barres latérales, des logos ou d'autres éléments.`;
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png';
const featuredImageAltText = exports.featuredImageAltText = 'Éditeur de contenu global';
const toc = exports.toc = [{
  "depth": 0,
  "id": "pr%C3%A9sentation",
  "label": "Présentation",
  "parent": null
}, {
  "depth": 1,
  "id": "partials-globaux-vs-modules-globaux",
  "label": "Partials globaux vs Modules globaux",
  "parent": "pr%C3%A9sentation"
}, {
  "depth": 0,
  "id": "partials-globaux",
  "label": "Partials globaux",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-un-partial-global",
  "label": "Créer un partial global",
  "parent": "partials-globaux"
}, {
  "depth": 1,
  "id": "inclure-un-partial-global-dans-votre-mod%C3%A8le-existant",
  "label": "Inclure un partial global dans votre modèle existant",
  "parent": "partials-globaux"
}, {
  "depth": 0,
  "id": "modules-globaux",
  "label": "Modules globaux",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Contenu global"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le contenu global est un contenu qui est partagé entre plusieurs rubriques d'un site Web. Les exemples courants sont les en-têtes, les pieds de page et les barres latérales de sites web. En tant que développeur, vous spécifierez quels composants doivent être globaux en utilisant des partials globaux ou en rendant les modules globaux. HubSpot propose une expérience d'édition différente pour les éditeurs de contenu qui leur permet de modifier facilement le contenu global et de prévisualiser les modifications sur les pages avant de les publier. Pour en savoir plus sur la modification de votre contenu global, veuillez consulter l'article ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "Utiliser du contenu global dans plusieurs modèles"
      }), " de la base de connaissances HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png",
        alt: "Éditeur de contenu global"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Présentation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le contenu global est idéal pour afficher les mêmes informations sur plusieurs pages. Par exemple, l'en-tête et le pied de page de votre site web, tout comme l'en-tête en haut de cette page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubspot-developers-header.png",
        alt: "hubspot-developers-header"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous d'autres exemples de domaines dans lesquels vous pouvez utiliser le contenu global :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navigation secondaire dans les différentes rubriques de votre site Web"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pieds de page (ou sous-pieds) avec les droits d'auteurs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Barres latérales des articles de blog (pour afficher les articles récents, une liste des auteurs, etc.)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Le contenu global étant utilisé à de multiples endroits sur un site Web, il est d'autant plus important de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "concevoir et d'élaborer vos partials et modules globaux dans un souci d'accessibilité"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partials globaux vs Modules globaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En tant que développeur, vous pouvez créer des partials globaux et des modules globaux, avec quelques différences clés entre les deux :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les partials globaux sont un type de modèle créé à l'aide de HTML et HubL qui peut être réutilisé sur l'ensemble de votre site web. Les types de partials les plus courants sont les en-têtes de sites Web, les barres latérales et les pieds de page."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les modules globaux sont des modules composés d'un ou plusieurs éléments de contenu qui peuvent être utilisés sur plusieurs pages de votre site. Parmi les types courants de modules globaux figurent des éléments tels que les formulaires d'inscription au blog, les éléments de navigation secondaire et les appels à l'action."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veuillez éviter d'inclure des modules globaux dans les partials globaux, car cela peut créer une expérience d'édition de contenu négative."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Tous les modules et champs de vos partials et modules globaux sont facilement modifiables dans l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
          children: "éditeur de contenu global"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Partials globaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer un partial global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un partial global est un type de modèle que vous pouvez créer localement via l'ILC de HubSpot à l'aide de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "commande create"
      }), ", comme indiqué ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <partial-file-name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous êtes invité à choisir un type de modèle, sélectionnez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global partial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela créera votre modèle dans le répertoire souhaité avec les annotations de modèle suivantes incluses dans le fichier HTML."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: global_partial\n  label: Page Header\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour voir un exemple de partial de contenu global, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/header.html",
        children: "veuillez consulter notre modèle sur GitHub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ajouter des zones de glisser-déposer à des partials globaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez activer les fonctionnalités de glisser-déposer de contenu dans vos partials globaux en ajoutant des balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " similaires à celles des modèles de page. Pour plus d'informations, consultez notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentation sur la zone de glisser-déposer"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Inclure un partial global dans votre modèle existant"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour ajouter un partial global dans l’un de vos modèles existants, utilisez la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), " tout en faisant référence au chemin d’accès à votre partial. Vous trouverez ci-dessous un exemple tiré du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L21",
        children: "boilerplate du CMS"
      }), " utilisant cette balise."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque des partials globaux sont générés, ils contiennent un cadre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " autour du partial global. Cet élément est utilisé par l'éditeur de page pour identifier que le contenu est un partial global."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div\n  data-global-resource-path=\"cms-theme-boilerplate/templates/partials/header.html\"\n>\n  <!-- Your header.html code is output here -->\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " n'utilisez pas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "global_partial"
        }), " dans le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " d’un modèle. Cela déboucherait sur un HTML non valide."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Dans la plupart des situations où vous souhaitez utiliser un partial global dans l’en-tête, il peut être plus logique d’utiliser un module global à la place, avec un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{%require_head%}"
          })
        }), " pour insérer du code personnalisé dans l’en-tête, tout en fournissant des champs de module."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules globaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez créer des modules globaux comme tout autre module via le ILC en exécutant la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create"
      }), ", comme indiqué ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create module <module_name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un module global est différencié par l’indicateur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " du module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json file\n{\n  \"css_assets\": [],\n  \"external_js\": [],\n  \"global\": true,\n  \"help_text\": \"\",\n  \"host_template_types\": [\"PAGE\"],\n  \"js_assets\": [],\n  \"other_assets\": [],\n  \"smart_type\": \"NOT_SMART\",\n  \"tags\": [],\n  \"is_available_for_new_content\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "créer des modules globaux dans HubSpot à l'aide du gestionnaire de conception"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour en savoir plus sur l'utilisation des modules, consultez les ressources connexes suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Vue d'ensemble des modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Modules par défaut de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configuration de modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Utilisation de modules dans les modèles"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}