"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742926;
const slug = exports.slug = 'reference/api/deprecated/overview';
const title = exports.title = 'API de HubSpot | API obsolètes';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Découvrez la liste des API obsolètes de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api-caduques",
  "label": "API caduques",
  "parent": null
}, {
  "depth": 0,
  "id": "api-obsol%C3%A8tes",
  "label": "API obsolètes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API caduques et obsolètes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les API répertoriées dans les sections ci-dessous ne recevront ", (0, _jsxRuntime.jsx)("u", {
        children: "pas"
      }), " de mises à jour futures des fonctionnalités et, dans certains cas, seront entièrement désactivées et indisponibles pour vos intégrations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il est fortement recommandé de vous abonner au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "journal des modifications des développeurs HubSpot"
      }), " pour suivre les dernières mises à jour, les modifications de dernière minute et d'autres modifications importantes apportées aux fonctionnalités."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API caduques"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les API suivantes ont été entièrement supprimées et renverront une erreur en cas d'appel à leurs points de terminaison associés :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Date de suppression"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Remarques"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Performance CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9 avril 2024"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pour les données d'analyse de site Web, utilisez l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/reporting",
              children: "API d'analyse"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ecommerce Bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28 février 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si vous avez déjà créé une intégration à l'aide de cette API, vous pouvez suivre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "ce guide de migration"
            }), " pour basculer vers votre intégration et utiliser des applications privées."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Extension comptable"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28 février 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si vous avez déjà créé une intégration à l'aide de cette API, vous pouvez suivre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "ce guide de migration"
            }), " pour basculer vers votre intégration et utiliser des applications privées."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Calendrier marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "31 août 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vous pouvez continuer à ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/use-your-marketing-calendar",
              children: "utiliser le calendrier marketing dans HubSpot"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API obsolètes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les points de terminaison hérités répertoriés ci-dessous ne recevront ", (0, _jsxRuntime.jsx)("u", {
        children: "pas"
      }), " de mise à jour de version. Ces points de terminaison sont fonctionnels et stables, mais ne seront pas mis à jour au-delà de leur version actuelle. HubSpot continuera de fournir une assistance à court terme et communiquera suffisamment à l'avance toute modification future dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "journal des modifications des développeurs"
      }), ".", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Remarques"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Réseaux sociaux"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cela comprend les points de terminaison suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
                  children: "Obtenir des canaux de publication"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
                  children: "Obtenir des messages de diffusion"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
                  children: "Obtenir un message de diffusion"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
                  children: "Créer un message de diffusion"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
                  children: "Annuler un message de diffusion"
                })
              })]
            })]
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}