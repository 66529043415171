"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937505;
const slug = exports.slug = 'guides/api/crm/properties';
const title = exports.title = 'CRM-API | Eigenschaften';
const name = exports.name = 'vNext Docs DP - Eigenschaften';
const metaDescription = exports.metaDescription = ' Die CRM-Eigenschaften-Endpunkte ermöglichen es Ihnen, benutzerdefinierte Eigenschaften zu verwalten und Details für Standardeigenschaften für jedes Objekt anzuzeigen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "standardeigenschaften",
  "label": "Standardeigenschaften",
  "parent": null
}, {
  "depth": 0,
  "id": "eigenschaftsgruppen",
  "label": "Eigenschaftsgruppen",
  "parent": null
}, {
  "depth": 0,
  "id": "eigenschaftstyp-und-fieldtype-werte",
  "label": "Eigenschaftstyp und fieldType-Werte",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-eigenschaft-erstellen",
  "label": "Eine Eigenschaft erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "%E2%80%9Eeindeutige-id%E2%80%9C-eigenschaften-erstellen",
  "label": "„Eindeutige ID“-Eigenschaften erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "berechnungseigenschaften-erstellen",
  "label": "Berechnungseigenschaften erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "berechnungseigenschaftensyntax",
  "label": "Berechnungseigenschaftensyntax",
  "parent": "berechnungseigenschaften-erstellen"
}, {
  "depth": 2,
  "id": "literalsyntax",
  "label": "Literalsyntax",
  "parent": "berechnungseigenschaften-erstellen"
}, {
  "depth": 2,
  "id": "eigenschaftssyntax",
  "label": "Eigenschaftssyntax",
  "parent": "berechnungseigenschaften-erstellen"
}, {
  "depth": 2,
  "id": "operatoren",
  "label": "Operatoren",
  "parent": "berechnungseigenschaften-erstellen"
}, {
  "depth": 2,
  "id": "funktionen",
  "label": "Funktionen",
  "parent": "berechnungseigenschaften-erstellen"
}, {
  "depth": 2,
  "id": "bedingte-anweisungen",
  "label": "Bedingte Anweisungen",
  "parent": "berechnungseigenschaften-erstellen"
}, {
  "depth": 1,
  "id": "beispielformeln",
  "label": "Beispielformeln",
  "parent": "berechnungseigenschaften-erstellen"
}, {
  "depth": 0,
  "id": "eigenschaften-abrufen",
  "label": "Eigenschaften abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "die-werte-einer-eigenschaft-aktualisieren-oder-l%C3%B6schen",
  "label": "Die Werte einer Eigenschaft aktualisieren oder löschen",
  "parent": null
}, {
  "depth": 1,
  "id": "werte-zu-den-eigenschaften-des-kontrollk%C3%A4stchentyps-hinzuf%C3%BCgen",
  "label": "Werte zu den Eigenschaften des Kontrollkästchentyps hinzufügen",
  "parent": "die-werte-einer-eigenschaft-aktualisieren-oder-l%C3%B6schen"
}, {
  "depth": 1,
  "id": "f%C3%BCr-datens%C3%A4tze-zust%C3%A4ndige-mitarbeiter-mit-benutzereigenschaften-zuweisen",
  "label": "Für Datensätze zuständige Mitarbeiter mit Benutzereigenschaften zuweisen",
  "parent": "die-werte-einer-eigenschaft-aktualisieren-oder-l%C3%B6schen"
}, {
  "depth": 1,
  "id": "einen-eigenschaftswert-l%C3%B6schen",
  "label": "Einen Eigenschaftswert löschen",
  "parent": "die-werte-einer-eigenschaft-aktualisieren-oder-l%C3%B6schen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Eigenschaften"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie Eigenschaften, um Informationen in CRM-Datensätzen zu speichern. HubSpot bietet eine Reihe von Standardeigenschaften für jedes CRM-Objekt. Außerdem können Sie Ihre eigenen benutzerdefinierten Eigenschaften entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "in HubSpot"
      }), " oder mit der Eigenschaften-API erstellen und verwalten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen von Eigenschaften sollte die Architektur Ihrer gut überlegt sein. In vielen Fällen ist das Erstellen benutzerdefinierter Eigenschaften für Standardobjekte von HubSpot die richtige Vorgehensweise. Es kann jedoch vorkommen, dass Sie ein separates ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "benutzerdefiniertes Objekt"
      }), " mit seinem eigenen Satz an Eigenschaften erstellen müssen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standardeigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM-Objekte werden durch einen primären ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Typ"
      }), " und einen Satz von ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Eigenschaften"
      }), " definiert. Jeder Typ verfügt über einen eindeutigen Satz an Standardeinstellungen, die von einer Zuordnung von Name/Wert-Paaren dargestellt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über Standardeigenschaften für verschiedene Objekte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Kontakte"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Unternehmen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Deals"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-activity-properties",
          children: "Aktivitäten"
        }), " (Anrufe, E-Mails, Meetings, Notizen, Aufgaben)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/hubspots-default-lead-properties",
          children: "Leads"
        }), " (", (0, _jsxRuntime.jsx)(_components.em, {
          children: "**Sales Hub **"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eigenschaftsgruppen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties#create-and-edit-property-groups",
        children: "Gruppen"
      }), " von Eigenschaften werden verwendet, um zugehörige Eigenschaften zu gruppieren. Gruppierte Eigenschaften werden nebeneinander in HubSpot-Datensätzen angezeigt. Wenn Ihre Integration beliebige benutzerdefinierte Objekteigenschaften erstellt, erleichtert eine benutzerdefinierte Eigenschaftsgruppe das Identifizieren dieser Daten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eigenschaftstyp und fieldType-Werte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen oder Aktualisieren von Eigenschaften sind sowohl der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), "-Wert als auch der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), "-Wert erforderlich. Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), "-Wert bestimmt den Typ der Eigenschaft, d. h. eine Zeichenfolge oder eine Zahl. Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), "-Eigenschaft bestimmt, wie die Eigenschaft in HubSpot oder in einem Formular angezeigt wird, also als Klartext, als ein Dropdown-Menü oder als eine Datumsauswahl."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie in der folgenden Tabelle mehr über die verfügbaren ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), "-Werte für Eigenschaften und die entsprechenden ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), "-Werte."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsxs)(_components.th, {
            children: ["Zulässige ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            }), "-Werte"]
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Feld mit binären Optionen (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Yes"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "No"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ")."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Zeichenfolge, die eine Reihe von Optionen enthält, die durch ein Semikolon getrennt sind."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Wert, der einen bestimmten Tag, einen bestimmten Monat und ein bestimmtes Jahr darstellt. Werte müssen in UTC-Zeit angegeben werden und können als ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "ISO 8601-Zeichenfolgen oder EPOCH-Zeitstempel in Millisekunden"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            }), " | (d. h. Mitternacht UTC) formatiert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dateTime"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Wert, der einen bestimmten Tag, einen bestimmten Monat, ein bestimmtes Jahr und eine bestimmte Uhrzeit darstellt. Werte müssen in UTC-Zeit angegeben werden und können als ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/docs/api/faq#how-should-i-format-timestamps-for-hubspot-s-apis:~:text=How%20should%20I%20format%20timestamps%20for%20HubSpot%27s%20APIs%3F",
              children: "ISO 8601-Zeichenfolgen oder UNIX-Zeitstempel in Millisekunden"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            }), " | formatiert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Klartext-Zeichenfolge, die auf 65.536 Zeichen begrenzt ist."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Zahlenwert mit numerischen Ziffern und höchstens einer Dezimalstelle."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "object_coordinates"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Textwert, der zum Verweisen auf andere HubSpot-Objekte verwendet wird und nur für interne Eigenschaften verwendet wird. Eigenschaften dieses Typs können nicht erstellt oder bearbeitet werden und sind in HubSpot nicht sichtbar."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Textwert, der als formatiertes json gespeichert ist und nur für interne Eigenschaften verwendet wird. Eigenschaften dieses Typs können nicht erstellt oder bearbeitet werden und sind in HubSpot nicht sichtbar."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zulässige Werte für ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fieldType"
      }), " sind:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feldtyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "booleancheckbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Eingabe, die es Benutzern ermöglicht, entweder „Ja“ oder „Nein“ auszuwählen. Bei Verwendung in einem Formular wird sie als einzelnes Kontrollkästchen angezeigt. Erfahren Sie, wie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "Sie einen Wert zu Eigenschaften mit einem einzelnen Kontrollkästchen hinzufügen"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "calculation_equation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine benutzerdefinierte Gleichung, die Werte basierend auf anderen Eigenschaftswerten und/oder Zuordnungen berechnen kann. Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#create-calculation-properties",
              children: "berechnete Eigenschaften definieren"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Liste der Kontrollkästchen, anhand derer ein Benutzer mehrere Optionen aus einer Reihe von Optionen auswählen kann, die für die Eigenschaft zulässig sind. Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-values-to-checkbox-type-properties",
              children: "Werte beim Aktualisieren von Eigenschaften mit mehreren Kontrollkästchen formatieren"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Datumswert, der als Datumsauswahl angezeigt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ermöglicht das Hochladen einer Datei in einen Datensatz oder über ein Formular. Speichert eine Datei-ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Zeichenfolge, die als bereinigter HTML-Code gerendert wird und die Verwendung eines Rich-Text-Editors für die Eigenschaft ermöglicht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Ziffernfolge oder Zahlen in Dezimalschreibweise oder in wissenschaftlicher Notation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "phonenumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Klartext-Zeichenfolge, die als eine formatierte Telefonnummer angezeigt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "radio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Eingabe, die es Benutzern ermöglicht, eine Option aus einer Reihe von Optionen auszuwählen, die für die Eigenschaft zulässig sind. Bei Verwendung in einem Formular wird dies als eine Reihe von Optionsfeldern angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Dropdown-Eingabe, die es Benutzern ermöglicht, eine Option aus einer Reihe von Optionen auszuwählen, die für die Eigenschaft zulässig sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Klartext-Zeichenfolge, die als eine einzeilige Texteingabe angezeigt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textarea"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Klartext-Zeichenfolge, die als eine mehrzeilige Texteingabe angezeigt wird."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Eigenschaft erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Eigenschaft zur erstellen, führen Sie eine", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/{objectType}"
      }), " durch. Geben Sie in Ihrem Anfragetext die folgenden Pflichtfelder an:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "groupName"
        }), ": die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/organize-and-export-properties",
          children: "Eigenschaftsgruppe"
        }), ", in der sich die Eigenschaft befinden wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": der interne Name der Eigenschaft (z. B. favorite_food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": der Name der Eigenschaft, wie er in HubSpot angezeigt wird (z. B. Favorite Food)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "type"
        }), ": der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#type",
          children: "Typ"
        }), " der Eigenschaft."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldType"
        }), ": der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-type",
          children: "Feldtyp"
        }), " der Eigenschaft."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um beispielsweise eine Kontakteigenschaft mit dem Namen ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Favorite Food"
      }), " zu erstellen, würde Ihre Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body POST crm/v3/properties/contacts\n{\n  \"groupName\": \"contactinformation\",\n  \"name\": \"favorite_food\",\n  \"label\": \"Favorite Food\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "„Eindeutige ID“-Eigenschaften erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ein Datensatz in HubSpot erstellt wird, wird automatisch eine eindeutige ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Datensatz-ID"
      }), " (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") generiert, die als Zeichenfolge behandelt werden sollte. Diese IDs sind nur innerhalb dieses Objekts eindeutig. Daher kann es sowohl einen Kontakt als auch ein Unternehmen mit derselben ID geben. Für Kontakte und Unternehmen gibt es zusätzliche eindeutige IDs, einschließlich der E-Mail-Adresse (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ") eines Kontakts und des Domain-Namens (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ") eines Unternehmens."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In einigen Fällen möchten Sie möglicherweise Ihre eigene „Eindeutige ID“-Eigenschaft erstellen, damit Sie nicht den gleichen Wert für mehrere Datensätze eingeben können. Sie können über bis zu zehn eindeutige ID-Eigenschaften pro Objekt verfügen. So erstellen Sie eine Eigenschaft, die eindeutige Werte über die API erfordert:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Legen Sie in Ihrem Anforderungstext für das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hasUniqueValue"
        }), "-Feld den Wert auf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " fest."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "/// Example request body\n{\n  \"groupName\": \"dealinformation\",\n  \"name\": \"system_a_unique\",\n  \"label\": \"Unique ID for System A\",\n  \"hasUniqueValue\": true,\n  \"type\": \"string\",\n  \"fieldType\": \"text\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihr Feld für die „Eindeutige ID“-Eigenschaft erstellt haben, können Sie sie in einem API-Aufruf verwenden, um spezifische Datensätze abzurufen. Die Anfrage-URL könnte wie folgt aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), ". Dadurch wird der Deal mit dem Wert von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "abc"
      }), " im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "system_a_unique"
      }), "-Feld zurückgegeben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können dann diesen „Eindeutige ID“-Eigenschaftswert verwenden, um bestimmte Datensätze auf die gleiche Weise zu identifizieren und zu aktualisieren, wie Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " (Kontakte) oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " (Unternehmen) verwenden könnten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Berechnungseigenschaften erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Berechnungseigenschaften definieren einen Eigenschaftswert basierend auf anderen Eigenschaften innerhalb desselben Objektdatensatzes. Sie werden mithilfe einer Formel definiert, die Operationen wie min, max, count, sum oder average umfassen kann. Sie können die Eigenschaften-API verwenden, um berechnete Eigenschaften in Ihrem HubSpot-Account zu lesen oder zu erstellen, indem Sie einen Feldtyp ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculation_equation"
      }), " und einen Typ von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "number"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bool"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "string"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enumeration"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können die Berechnungsformel der Eigenschaft mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), "-Feld definieren."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Berechnungseigenschafte, die über die API erstellt wurden, können in HubSpot ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " bearbeitet werden. Sie können diese Eigenschaften nur über die Eigenschaften-API bearbeiten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Berechnungseigenschaftensyntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calculationFormula"
      }), " können Sie Ihre Formel mit arithmetischen Operatoren, Vergleichsoperatoren, logischen Operatoren, bedingten Anweisungen und anderen Funktionen schreiben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Literalsyntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zeichenfolgenliteral"
        }), ": konstante Zeichenfolgen können entweder mit einfachen Anführungszeichen (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'constant'"
        }), ") oder doppelten Anführungszeichen (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"constant\""
        }), ") dargestellt werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahlliteral"
        }), ": konstante Zahlen können beliebige reelle Zahlen sein und Punktnotation enthalten. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1005"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1.5589"
        }), " sind beide gültige konstante Zahlen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Boolesches Literal"
        }), ": Konstante boolesche Werte können ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), " sein."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Eigenschaftssyntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zeichenfolgeneigenschaftsvariablen:"
        }), " damit eine Bezeichnerzeichenfolge als Zeichenfolgeneigenschaft interpretiert werden kann, muss sie in die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), "-Funktion gehüllt sein. Beispielsweise wird ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "string(var1)"
        }), " als Wert für die Zeichenfolgeneigenschaft var1 interpretiert."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahleneigenschaftsvariablen"
        }), ": alle Bezeichner werden als Zahleneigenschaftsvariablen interpretiert. Beispielsweise wird ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "var1"
        }), " als Wert für die Zahleneigenschaft var1 interpretiert."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Boolesche Eigenschaftsvariablen"
        }), ": damit ein Bezeichner als boolesche Eigenschaft interpretiert werden kann, muss er in die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool"
        }), "-Funktion gehüllt sein. Zum Beispiel wird der Bezeichner ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "bool(var1)"
        }), " als Wert für die boolesche Eigenschaft var1 interpretiert."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Die verwendete Sprache unterscheidet zwischen Groß- und Kleinschreibung für alle Typen ", (0, _jsxRuntime.jsx)("u", {
          children: "außer"
        }), " Zeichenfolgen. Zum Beispiel, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "If A ThEn B"
        }), " genau das gleiche ist wie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if a then b"
        }), ", aber ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'a'"
        }), " nicht das gleiche wie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'A'"
        }), " ist. Leerzeichen, Tabulatoren und neue Zeilen werden für die Tokenisierung verwendet, aber ignoriert."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Operatoren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Operatoren können mit Literal- und Eigenschaftswerten verwendet werden. Bei arithmetischen Operatoren können Sie die Präfixnotation zum Multiplizieren verwenden, und mithilfe von Klammern kann die Reihenfolge der Operationen festgelegt werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiele"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahlen oder Zeichenfolgen hinzufügen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahlen subtrahieren."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 + 100 - property2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahlen multiplizieren."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "10property1"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10 * property1"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahlen dividieren."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property1 * (100 - property2/(50 - property3))"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob ein Wert kleiner als ein anderer ist. Unterstützt durch Zahleneigenschaften oder Konstanten."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a < 100"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob ein Wert größer als ein anderer ist. Unterstützt durch Zahleneigenschaften oder Konstanten."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > 50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob ein Wert kleiner oder gleich einem anderen ist. Unterstützt durch Zahleneigenschaften oder Konstanten."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a <= b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ">="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob ein Wert größer oder gleich einem anderen ist. Unterstützt durch Zahleneigenschaften oder Konstanten."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b>= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob ein Wert gleich einem anderen ist. Unterstützt sowohl durch Zahlen als auch durch Zeichenfolgen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "(a + b - 100c * 150.652) = 150-230b"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "equals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob ein Wert gleich einem anderen ist. Unterstützt sowohl durch Zahlen als auch durch Zeichenfolgen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a + b - 100.2c * 150 equals 150 - 230"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!="
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob ein Wert ungleich einem anderen ist. Unterstützt sowohl durch Zahlen als auch durch Zeichenfolgen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string(property1) != 'test_string'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "or"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob einer oder zwei Werte wahr sind."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "a > b or b <= c"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "and"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob beide Werte wahr sind."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bool(a) and bool(c)"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prüft, ob keiner der Werte wahr ist."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not (bool(a) and bool(c))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Funktionen werden unterstützt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Funktion"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiele"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hat zwischen 2 und 100 Eingabenummern und gibt die Höchstanzahl aller Eingaben zurück."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b, c, 100)"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "min"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hat zwischen 2 und 100 Eingabenummern und gibt die Mindestanzahl aller Eingaben zurück."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b, c, 100)"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "min(a, b)"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wertet aus, ob ein Ausdruck ausgewertet werden kann."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), "= true, wenn die Eigenschaft boolesch ist, aber ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_present(bool(a))"
            }), " = false, wenn die Eigenschaft leer oder nicht boolesch ist."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hat zwei Zeichenfolgen als Eingaben und gibt true zurück, wenn die erste Eingabe die zweite enthält."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('hello', 'ello')"
            }), " = ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " während ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains('ello', 'hello')"
            }), " = false."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verbindet eine Liste von Zeichenfolgen. Die Liste der Eingaben kann von 2 bis zu 100 reichen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "concatenate('a', 'b', string(a), string(b))"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt auch zwei Parsing-Funktionen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number_to_string"
        }), ": versucht, den eingegebenen Zahlenausdruck in eine Zeichenfolge zu konvertieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string_to_number"
        }), ": versucht, den eingegebenen Zeichenfolgenausdruck in eine Zahl zu konvertieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beispielsweise ist ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + num_cars"
      }), " keine gültige Eigenschaft, da Sie keine Zeichenfolge mit einer Zahl hinzufügen können, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Number of cars: \" + number_to_string(num_cars)"
      }), " dagegen schon."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bedingte Anweisungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Ihre Formel auch mit bedingten Anweisungen schreiben, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beispielsweise könnte eine bedingte Anweisung wie folgt aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if boolean_expression then statement [elseif expression then statement]* [else statement | endif]"
      }), " wobei die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[a]"
      }), "-Klammern angeben, dass a optional ist, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a|b"
      }), " gibt an, dass entweder a oder b funktionieren wird, und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "*"
      }), " 0 oder mehr bedeutet. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), " kann verwendet werden, um eine bedingte Anweisung vorzeitig zu beenden, um sicherzustellen, dass der Parser identifizieren kann, zu welchem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " das nächste ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elseif"
      }), " gehört."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beispielformeln"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden finden Sie Beispiele, mit denen Sie Ihre eigenen Berechnungsformeln definieren können:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"closed - started\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein etwas komplexeres Beispiel mit Bedingungen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example formula\n\"calculationFormula\": \"if is_present(hs_latest_sequence_enrolled_date) then\n  if is_present(hs_sequences_actively_enrolled_count) an hs_sequences_actively_enrolled_count >= 1 then\n    true\n  else\n    false\nelse\n  ''\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eigenschaften abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Informationen für einzelne Eigenschaften oder alle Eigenschaften innerhalb eines Objekts abrufen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine einzelne Eigenschaft abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{object}/{propertyName}"
        }), " durch. Um beispielsweise die Eigenschaft „Favorite Food“ abzurufen, wäre Ihre Anfrage-URL https:// ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "api.hubspot.com/crm/v3/properties/contacts/favorite_food"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um alle Eigenschaften für ein Objekt abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Beim Abrufen aller Eigenschaften werden standardmäßig nur nicht vertrauliche Eigenschaften zurückgegeben. Um Eigenschaften vertraulicher Daten abzurufen, fügen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), "-Abfrageparameter mit dem Wert ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sensitive"
        }), " ein. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/sensitive-data#manage-sensitive-data",
          children: "Verwalten vertraulicher Daten über die API"
        }), " (nur BETA, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Werte einer Eigenschaft aktualisieren oder löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Eigenschaftswert für einen Datensatz zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/{objectType}/{recordId}"
      }), " durch. Fügen Sie in Ihrem Anfragetext die Eigenschaften und deren Werte in ein Array ein. Erfahren Sie mehr über das Aktualisieren von Datensätzen über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Objekt-APIs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Werte zu den Eigenschaften des Kontrollkästchentyps hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie Werte für die Eigenschaften des Kontrollkästchentyps eines Datensatzes aktualisieren, formatieren Sie die Werte wie folgt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Boolesche"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontrollkästcheneigenschaft"
        }), ": Um als ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Ja"
        }), " oder in HubSpot markiert angezeigt zu werden, muss Ihr Wert ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " sein. Um als ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Nein"
        }), " angezeigt zu werden oder in HubSpot nicht markiert zu werden, muss Ihr Wert ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "false"
        }), " sein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eigenschaft mit mehreren"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontrollkästchen"
        }), ": Um Werte zu einer Eigenschaft mit mehreren Kontrollkästchen hinzuzufügen oder anzuhängen, fügen Sie ein Semikolon vor dem ersten Wert hinzu und trennen Sie die Werte durch Semikolon ohne Leerzeichen dazwischen. Wenn die Eigenschaft einen vorhandenen Wert hat, hängt das führende Semikolon die Werte an, anstatt den Wert zu überschreiben. Ein Kontakt hat beispielsweise den vorhandenen Wert ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DECISION_MAKER"
        }), " für die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_buying_role"
        }), ". Um zusätzliche Werte hinzuzufügen, ohne den vorhandenen Wert zu ersetzen, würde Ihre Anfrage wie folgt aussehen:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example body for PATCH request to /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_buying_role\": \";BUDGET_HOLDER;END_USER\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Für Datensätze zuständige Mitarbeiter mit Benutzereigenschaften zuweisen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Zuweisen von Benutzern zu CRM-Datensätzen über die API muss Ihr Wert die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " des Benutzers für den zuständigen Mitarbeiter sein, die Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties",
        children: "Ihren Eigenschafteneinstellungen"
      }), " oder über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "Zuständige Mitarbeiter-API"
      }), " finden. Um beispielsweise einen Benutzer als zuständigen Mitarbeiter eines Kontakts zuzuweisen, senden Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " -Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      }), " mit dem Text ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\":{ \"hubspot_owner_id\": \"41629779\"}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einen Eigenschaftswert löschen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können einen Objekteigenschaftswert über die API löschen, indem Sie den Eigenschaftswert auf eine leere Zeichenfolge festlegen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um z. B. den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " aus einem Kontaktobjekt zu löschen, senden Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage mit dem Text ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ \"properties\": { \"firstname\": \"\"}}"
      }), " an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/contacts/{contactId}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}