"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59619794102;
const slug = exports.slug = 'guides/api/crm/engagements/calls';
const title = exports.title = 'Interaktionen | Anrufe';
const name = exports.name = 'Interaktionen | Anrufe';
const metaDescription = exports.metaDescription = 'Verwenden Sie die Anrufinteraktions-API, um Anrufe in CRM-Datensätzen zu protokollieren und zu verwalten. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-anrufinteraktion-erstellen",
  "label": "Eine Anrufinteraktion erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "eine-anrufinteraktion-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "eine-anrufinteraktion-erstellen"
}, {
  "depth": 0,
  "id": "anrufe-abrufen",
  "label": "Anrufe abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "voicemails-vs.-aufgezeichnete-anrufe-identifizieren",
  "label": "Voicemails vs. aufgezeichnete Anrufe identifizieren",
  "parent": null
}, {
  "depth": 0,
  "id": "anrufe-aktualisieren",
  "label": "Anrufe aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "vorhandene-anrufe-zu-datens%C3%A4tzen-zuordnen",
  "label": "Vorhandene Anrufe zu Datensätzen zuordnen",
  "parent": "anrufe-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "anrufe-aktualisieren"
}, {
  "depth": 0,
  "id": "einen-anruf-in-einem-datensatz-anpinnen",
  "label": "Einen Anruf in einem Datensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "anrufe-l%C3%B6schen",
  "label": "Anrufe löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Anrufe"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die Anrufinteraktion-API, um Anrufe in CRM-Datensätzen und auf der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "Indexseite für Anrufe"
      }), " zu protokollieren und zu verwalten. Sie können Anrufe entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "in HubSpot"
      }), " oder über die Anrufe-API protokollieren. Im Folgenden erfahren Sie, wie Sie Anrufe über die API verwalten können. Um alle verfügbaren Endpunkte und deren Anforderungen anzuzeigen, klicken Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Anrufinteraktion erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Anrufinteraktion zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie im Anfragetext Anrufdetails in einem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), "-Objekt hinzu. Sie können auch ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), "-Objekt hinzufügen, um Ihren neuen Anruf einem vorhandenen Datensatz (z. B. Kontakten, Unternehmen) zuzuordnen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie eine Liste der HubSpot-Standardanfrufeigenschaften, die Sie in das Eigenschaftsobjekt aufnehmen können. Sie können mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/properties",
        children: "Eigenschaften-API"
      }), " auch benutzerdefinierte Eigenschaften erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erforderlich. Dieses Feld markiert den Zeitpunkt der Erstellung des Anrufs und bestimmt, wo Sie den Anruf in der Datensatzchronik finden. Sie können entweder einen Unix-Zeitstempel im Millisekunden- oder UTC-Format verwenden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Beschreibung des Anrufs, einschließlich aller Anmerkungen, die Sie hinzufügen möchten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des HubSpot-Datensatzes, der dem Anruf zugeordnet ist. Dies ist der Empfänger des Anrufs für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), "-Anrufe oder der Wähler des Anrufs für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "-Anrufe."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_type_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des Objekts, zu dem der zugeordnete Datensatz des Anrufs gehört (gibt z. B. an, ob der Datensatz ein Kontakt oder ein Unternehmen ist). Dies ist das Objekt des Empfängers für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), "-Anrufe oder das Objekt des Wählers für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "-Anrufe."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Richtung des Anrufs aus der Perspektive des HubSpot-Benutzers. Wenn der Benutzer der Anrufempfänger ist, sollte die Richtung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), " festgelegt werden. Wenn der Benutzer den Anruf initiiert hat, sollte die Richtung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), " festgelegt werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_disposition"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das Ergebnis des Anrufs. Um die Anrufdisposition einzustellen, müssen Sie den internen GUID-Wert verwenden. Wenn für Ihren Account ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/calling/create-custom-call-and-meeting-outcomes",
              children: "benutzerdefinierte Anrufergebnisse"
            }), " eingerichtet sind, können Sie deren Dispositions-GUIDs mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/engagements/engagement-details#get-call-engagement-dispositions",
              children: "dieser API"
            }), " finden. Die standardmäßigen HubSpot-Ergebnislabels und ihre internen Werte sind:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Besetzt: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "9d9162e7-6cf3-4944-bf63-4dff82258764"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Verbunden: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "f240bbac-87c9-4f6e-bf70-924b57d47db7"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Live-Nachricht hinterlassen: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "a4c4c377-d246-4b32-a13b-75a56a4cd0ff"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Voicemail hinterlassen: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "b2cf5968-551e-4856-9783-52b3da59a7d0"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Keine Antwort: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "73a0d17f-1163-4015-bdd5-ec830791da20"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Falsche Nummer: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "17b47fee-58de-441e-a44c-c6300d46f273"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Dauer des Anrufs in Millisekunden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_from_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Telefonnummer, von der aus der Anruf getätigt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_recording_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL, die die Anrufaufzeichnung speichert. URLS zu .mp3- oder .wav-Dateien können in CRM-Datensätzen wiedergegeben werden. Nur HTTPS, sichere URLs werden akzeptiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Status des Anrufs. Die Status sind ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BUSY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALLING_CRM_USER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CANCELED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPLETED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONNECTING"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_PROGRESS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NO_ANSWER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUEUED"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RINGING"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Titel des Anrufs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_source"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Quelle des Anrufs. Diese ist nicht erforderlich, aber sie ist notwendig, um die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/extensions/calling-extensions/recordings-and-transcriptions#log-a-call-with-your-app-s-endpoint-using-the-engagements-api",
              children: "Aufnahme- und Transkriptionspipeline zu nutzen"
            }), ". Wenn die Eigenschaft festgelegt wird, muss sie auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS_PLATFORM"
            }), " festgelegt werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_to_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Telefonnummer, die den Anruf erhalten hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID des zuständigen Mitarbeiters"
            }), ", der dem Anruf zugeordnet ist. Dieses Feld bestimmt den Benutzer, der in der Datensatzchronik als Anrufersteller aufgeführt ist."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ von Anruf Die Optionen basieren auf den in Ihrem HubSpot-Account festgelegten ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "Anruftypen."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die IDs der Anhänge des Anrufs. Mehrere Anhang-IDs sind durch ein Semikolon getrennt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um einen Anruf zu erstellen und bestehenden Datensätzen zuzuordnen, schließen Sie ein associations-Objekt in Ihre Anfrage ein. Um beispielsweise einen Anruf zu erstellen und ihn einem Kontakt und einem Ticket zuzuordnen, könnte Ihr Anfragetext wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Support call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \"Resolved issue\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 500\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 194\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1234\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 220\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im associations-Objekt sollten Sie Folgendes einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz, den Sie dem Anruf zuordnen möchten, angegeben durch seinen eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen Anruf und Datensatz. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das Erstellen von Anrufen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anrufe abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Anrufe einzeln oder mehrere gleichzeitig abrufen. Erfahren Sie mehr über batchweises Abrufen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen einzelnen Anruf anhand seiner Anruf-ID abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), " durch. Sie können die folgenden Parameter in die Anfrage-URL einschließen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "Eigenschaften"
            }), ", die zurückgegeben werden sollen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekttypen, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Liste aller Anrufe anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), " durch. Sie können die folgenden Parameter in die Anfrage-URL einschließen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die maximale Anzahl an Ergebnissen, die pro Seite angezeigt werden können."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "Eigenschaften"
            }), ", die zurückgegeben werden sollen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine erfolgreiche Anfrage stellen, enthält die Antwort die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callId"
      }), ", mit der Sie den Anruf abrufen, aktualisieren und löschen können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Voicemails vs. aufgezeichnete Anrufe identifizieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für aufgezeichnete Anrufe und Voicemails wird eine Aufzeichnung in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_recording_url"
      }), "-Eigenschaft gespeichert. Wenn Ihr Account Zugriff auf ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/receive-calls-in-hubspot",
        children: "Inbound-Calling"
      }), " hat, schließen Sie, um zwischen abgeschlossenen bzw. aufgezeichneten Anrufen und eingehenden Anrufen mit einer Voicemail zu unterscheiden, die folgenden Eigenschaften in Ihre Anfrage ein: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ein Anruf eine Voicemail hat, ist der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), "-Wert ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "missed"
      }), " und der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), "-Wert ist ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), "-Wert ist ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " für einen eingehenden Anruf, bei dem keine Voicemail hinterlassen wurde, oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), ", wenn der Anruf einen anderen Status als „verpasst“ hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anrufe aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Anrufe einzeln oder mehrere gleichzeitig aktualisieren. Um einen einzelnen Anruf anhand seiner Anruf-ID zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie im Anfragetext die Anrufeigenschaften ein, die Sie aktualisieren möchten:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/calls/{callID}\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Discovery call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \" Decision maker out, will call back tomorrow\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignoriert Werte für schreibgeschützte und nicht vorhandene Eigenschaften. Um einen Eigenschaftswert zu löschen, übergeben Sie eine leere Zeichenfolge für die Eigenschaft im Anfragetext."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über batchweise Aktualisierungen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorhandene Anrufe zu Datensätzen zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Anruf Datensätzen zuzuordnen, z. B. einem Kontakt und seinen zugeordneten Unternehmen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch. Die Anfrage-URL enthält die folgenden Felder:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Anrufs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Typ des Objekts, dem Sie den Anruf zuordnen möchten (z. B. Kontakt oder Unternehmen)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Datensatzes, dem Sie den Anruf zuordnen möchten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine eindeutige ID, die den Zuordnungstyp zwischen dem Anruf und dem anderen Objekt angibt. Die ID kann numerisch oder in Snake-Case (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "call_to_contact"
            }), ") dargestellt werden. Sie können den Wert über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage-URL könnte beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/calls/17591596434/associations/contact/104901/194"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Zuordnung zwischen einem Anruf und einem Datensatz zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an dieselbe URL wie oben durch:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Anruf in einem Datensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "einen Anruf in einem Datensatz anpinnen"
      }), ", damit er oben in der Chronik des Datensatzes bleibt. Der Anruf muss bereits vor dem Anpinnen dem Datensatz zugeordnet sein, und Sie können nur eine Aktivität pro Datensatz anpinnen. Um einen Anruf anzupinnen, fügen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " des Anrufs in das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld ein, wenn Sie einen Datensatz über die Objekt-APIs erstellen oder aktualisieren. Erfahren Sie mehr über das Verwenden der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "Unternehmen"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "Kontakte"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "Deals"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "Tickets"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "Benutzerdefinierte Objekte"
      }), "-APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anrufe löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Anrufe einzeln oder mehrere gleichzeitig löschen, wodurch der Anruf dem Papierkorb in HubSpot hinzugefügt wird. Sie können später ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "den Anruf über die Datensatzchronik wiederherstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen einzelnen Anruf anhand seiner Anruf-ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über batchweise Löschungen, indem Sie oben in diesem Artikel auf die Registerkarte** Endpunkte** klicken."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}