"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50631386777;
const slug = exports.slug = 'guides/cms/content/content-staging';
const title = exports.title = 'コンテンツステージング';
const name = exports.name = 'APAC JAPAN (ja) | [new] Content Staging_JA | 202108';
const metaDescription = exports.metaDescription = 'コンテンツステージングは、本番サイトへのページ公開前にページの再設計や作成を行うためのアプリ内開発環境です。';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "コンテンツステージング"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンテンツステージングは、本番サイトへのページ公開前にページの更新や作成を行うために分離された、アプリ内コンテンツ環境です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンテンツステージングは、ウェブサイトの再設計プロジェクトにおいて、新しいテンプレートを使用して既存のページを試す際に使用できます。ページに関連付けられたアナリティクス（分析）は、ステージングからの公開時に新しいページに引き継がれます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "再設計では複数ページを更新することが一般的ですが、必要な全てのページをステージングに移管し、本番サイトに一斉公開することが可能です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳細な手順については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "ウェブサイト再設計のコンテンツステージングの使用"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンテンツステージングに関する包括的なチュートリアルについては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "ウェブサイト ユーザー ガイドのこちらの記事"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマ、テンプレート、モジュールなどのアセット（コンテンツ）を作成およびテストする場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "開発者用サンドボックスアカウント"
      }), "の使用をお勧めします。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "効率的な開発ワークフローの構築に関する詳細をご覧ください。"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}