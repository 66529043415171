"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694132;
const slug = exports.slug = 'guides/cms/content/modules/overview';
const title = exports.title = 'Visão geral dos módulos';
const name = exports.name = 'Visão geral dos módulos';
const metaDescription = exports.metaDescription = 'Compreender os módulos é a chave para compreender o CMS HubSpot e tudo o que ele permite fazer. Módulos são componentes reutilizáveis que podem ser usados em modelos ou adicionados a páginas por meio de áreas de arrastar e soltar e colunas flexíveis.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/team-member-editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'Editor do módulo de membros da equipe';
const toc = exports.toc = [{
  "depth": 0,
  "id": "um-exemplo",
  "label": "Um exemplo",
  "parent": null
}, {
  "depth": 1,
  "id": "a-interface-do-usu%C3%A1rio-para-edi%C3%A7%C3%A3o",
  "label": "A interface do usuário para edição",
  "parent": "um-exemplo"
}, {
  "depth": 0,
  "id": "m%C3%B3dulo-vs.-inst%C3%A2ncia-de-m%C3%B3dulo",
  "label": "Módulo vs. instância de módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "fields.json",
  "label": "Fields.json",
  "parent": null
}, {
  "depth": 1,
  "id": "usar-dados-de-campo-de-m%C3%B3dulo-para-renderizar-html",
  "label": "Usar dados de campo de módulo para renderizar HTML",
  "parent": "fields.json"
}, {
  "depth": 0,
  "id": "usar-m%C3%B3dulos-em-modelos",
  "label": "Usar módulos em modelos",
  "parent": null
}, {
  "depth": 0,
  "id": "os-m%C3%B3dulos-s%C3%A3o-uma-%C3%B3tima-ferramenta-na-caixa-de-ferramentas-de-acessibilidade",
  "label": "Os módulos são uma ótima ferramenta na caixa de ferramentas de acessibilidade",
  "parent": null
}, {
  "depth": 0,
  "id": "os-m%C3%B3dulos-podem-tornar-a-localiza%C3%A7%C3%A3o-mais-f%C3%A1cil",
  "label": "Os módulos podem tornar a localização mais fácil",
  "parent": null
}, {
  "depth": 0,
  "id": "introdu%C3%A7%C3%A3o",
  "label": "Introdução",
  "parent": null
}, {
  "depth": 0,
  "id": "aprofundando-se",
  "label": "Aprofundando-se",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      strong: "strong",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral dos módulos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Compreender os módulos é a chave para compreender o CMS HubSpot e tudo o que ele permite fazer. Módulos são componentes reutilizáveis que podem ser usados em modelos ou adicionados a páginas por meio de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "áreas de arrastar e soltar"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "colunas flexíveis"
      }), ". Além de usar os módulos fornecidos pela HubSpot, os desenvolvedores podem criar seus próprios módulos para tudo, desde testemunhos até galerias de fotos. Os módulos são criados ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "usando as ferramentas de desenvolvimento local"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "usando o Gerenciador de design"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um módulo tem duas partes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-user-interface-for-editing",
          children: "interface do usuário"
        }), " criada por meio de uma lista de campos que os usuários verão ao editar uma instância de módulo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um fragmento de modelo HTML+HubL com CSS e JS associados para definir como o HTML será gerado"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Um exemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para entender melhor o que é um módulo, vamos analisar o módulo simples \"Membro da equipe\". O módulo consiste em uma foto, o nome do membro da equipe, seu cargo e uma breve biografia, e quando faz parte de uma página web do CMS, é parecido com isso:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-module-instance.png",
        alt: "Instância do módulo de membro da equipe"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "A interface do usuário para edição"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O desenvolvedor cria a interface do usuário (UI) para os módulos usando campos. O desenvolvedor então escolhe quais campos usar com base no tipo de módulo a ser criado, nos dados necessários e na experiência de edição. Neste caso, o módulo inclui:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "um campo de imagem, para a foto do membro da equipe;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "dois campos de texto, para o nome e o cargo do membro da equipe;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "e um campo rich text, para uma breve biografia."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando um criador de conteúdo edita um módulo, a UI é criada com base nos campos que o desenvolvedor adicionou ao módulo e como cada campo foi configurado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-editor.png",
        alt: "Editor do módulo de membros da equipe"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulo vs. instância de módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há dois termos frequentemente utilizados em relação aos módulos. É importante entender a diferença entre eles."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Módulo"
        }), " - blocos de construção reutilizáveis que podem ser adicionados a modelos e páginas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Instância de módulo"
        }), " - os módulos individuais renderizados na página. Eles podem ter valores de campo separados e, como resultado, parecer diferente das outras instâncias de módulo que se destinam ao mesmo módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fields.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os campos de um módulo são definidos em JSON como uma matriz de objetos. Cada campo tem um nome, tipo e valor padrão. Outras propriedades também estão disponíveis, dependendo do tipo de campo que controla a experiência de edição."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"name\": \"team_member_photo\",\n    \"label\": \"Team Member Photo\",\n    \"required\": true,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"type\": \"image\",\n    \"default\": {\n      \"src\": \"\",\n      \"alt\": \"\"\n    }\n  },\n  {\n    \"name\": \"team_member_name\",\n    \"label\": \"Team member name\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"Joshua Beck\"\n  },\n  {\n    \"name\": \"team_member_position\",\n    \"label\": \"Team member position\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"CEO, Co-Founder\"\n  },\n  {\n    \"name\": \"team_member_bio\",\n    \"label\": \"Team member bio\",\n    \"required\": true,\n    \"type\": \"richtext\",\n    \"default\": \"<p>Joshua has over 20 years of experience in the tech industry. He helped start this company in 2015 with the mission of helping people grow. In his spare time he loves hanging out with his kids, going to the beach, and cooking.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para saber mais sobre todos os campos disponíveis, consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Campos de tema e módulo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usar dados de campo de módulo para renderizar HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os valores de cada campo são disponibilizados pelo fragmento HTML+HubL para um módulo por meio de uma variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ". Os dados de cada campo podem ser acessados por meio das propriedades da variável module. Usando o módulo de membro da equipe como exemplo, o nome do membro da equipe pode ser acessado via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ module.team_member_name }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<section class=\"team-member\">\n  <img class=\"team-member__image\" src=\"{{ module.team_member_image.src }}\" alt=\"{{ module.team_member_image.alt }}\">\n  <h3 class=\"team-member__name\">{{ module.team_member_name }}</h3>\n  <p class=\"team-member__position\">{{ module.team_member_position }}</p>\n  <div class=\"team-member__bio\">{{ module.team_member_bio }}</div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar módulos em modelos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos são adicionados aos modelos usando as tags ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#basic-module-syntax",
        children: "module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#block-syntax",
        children: "module_block"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_module"
      }), " e especificando o caminho para o módulo como um parâmetro. Os valores padrão dos campos em um módulo também podem ser substituídos no nível do modelo por meio da adição de parâmetros à tag que corresponde ao nome do campo, como mostrado na segunda parte do exemplo abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_identifier\" path=\"/modules/team-member.module\" %}\n\n\n{# override default values in a module instance #}\n\n{% module \"unique_identifier\"\n  path=\"/modules/team-member.module\",\n  team_member_name=\"Brian Halligan\",\n  team_member_position=\"CEO\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Os módulos não podem ser aninhados uns dentro dos outros. Na maioria das vezes, você gostaria de fazer isso, normalmente por motivos de layout. As seções nas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastar e soltar"
        }), " são muitas vezes o melhor curso de ação."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Os módulos são uma ótima ferramenta na caixa de ferramentas de acessibilidade"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos são utilizados em um site, algumas vezes em várias páginas, até mesmo várias vezes em uma página. Devido a esta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "estrutura, o HTML, o CSS e o JS do seu módulo, considerando a acessibilidade"
      }), ", pode influenciar profundamente a capacidade de utilização do seu site para pessoas sem e com deficiência."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Os módulos podem tornar a localização mais fácil"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em um sentido semelhante ao da acessibilidade, construir módulos para que todo o seu conteúdo seja baseado em campos, torna possível a localização posterior. Por exemplo, você pode ter um módulo \"Artigos em destaque\". Em vez de codificar o texto \"Artigos em destaque\", use um campo de texto ou um campo rich text. Em seguida, o texto pode ser alterado para outros idiomas. Para saber mais sobre localização no CMS, consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "vários idiomas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Introdução"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para começar, confira nosso tutorial ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "Introdução aos módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aprofundando-se"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurando um módulo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Usar módulos em modelos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Módulos padrão"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "O editor de módulos"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}