"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 163779113003;
const slug = exports.slug = 'reference/ui-components/standard-components/toggle-group';
const title = exports.title = 'ToggleGroup';
const name = exports.name = 'ToggleGroup | UI components';
const metaDescription = exports.metaDescription = 'Learn about the ToggleGroup component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ToggleGroup | UI extension components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ToggleGroup"
      }), " component renders a list of selectable options, either in radio button or checkbox form."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guide-toggle-group.png?width=400&height=293&name=design-guide-toggle-group.png",
        alt: "design-guide-toggle-group"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Group label:"
        }), " the text that displays above the group of checkboxes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tooltip:"
        }), " on hover, displays additional information about the field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Unchecked checkbox:"
        }), " an unselected checkbox."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Option label:"
        }), " the text that displays next to the checkbox."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Option description:"
        }), " the text that displays below the option label to describe the option."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { ToggleGroup } from '@hubspot/ui-extensions';\n\nconst options = [1, 2, 3, 4].map((n) => ({\n  label: `Option ${n}`,\n  value: `${n}`,\n  initialIsChecked: n === 2,\n  readonly: false,\n  description: `This is option ${n}`,\n}));\n\nconst Extension = () => {\n  return (\n    <ToggleGroup\n      name=\"toggle-checkboxes\"\n      label=\"Toggle these things\"\n      error={false}\n      options={options}\n      tooltip=\"Here's a secret tip.\"\n      validationMessage=\"Make sure you do the thing correctly.\"\n      required={false}\n      inline={false}\n      toggleType=\"checkboxList\"\n      variant=\"default\"\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The input's unique identifier."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text that displays above the toggles."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array of options to display in the group. Each object in the array contains:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), " (string)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), " (string)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "initialIsChecked"
                }), " (boolean)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "readonly"
                }), " (boolean)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), " (string)"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "toggleType"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'radioButtonList'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'checkboxList'"
            }), " (default)"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of toggle, whether checkboxes or radio buttons. Radio buttons only allow one option to be selected."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'default'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The size of the toggle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to true, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "validationMessage"
            }), " is displayed as an error message if provided. The input will also render its error state to let the user know there is an error. If left ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "validationMessage"
            }), " is displayed as a success message."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The value of the toggle group.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Accepts a string when ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "toggleType"
                }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "radioButtonList"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Accepts an array when ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "toggleType"
                }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "checkboxList"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", displays a required indicator next to the toggle group. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tooltip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that will appear in a tooltip next to the toggle group label."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "validationMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text to display if the input has an error."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", stacks the options horizontally. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "readonly"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", users will not be able to select the toggle. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(checked: boolean) => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that is invoked when the toggle is clicked."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, the toggle group will render as a vertical list of checkboxes. Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toggleType"
      }), " prop, you can set the options to display as checkboxes or radio buttons. You can also use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inline"
      }), " prop to stack options horizontally."]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "toggle-group-checklist-variant",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-togglegroup-styles_3.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)("code", {
                children: "toggleType='checkboxList'"
              }), " (default)"]
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "toggle-group-radio-buttons",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-togglegroup-styles_4.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "toggleType='radioButtonList'"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "toggle-group-inline",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-togglegroup-styles_1.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "inline={true}"
            })
          })
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A radio button list to enable salespeople to select one of four sales packages for a new customer."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A checkbox list to enable customer support reps to select several options of swag to send to a delightful customer."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " use this component when the user has a small selection of items to choose from. For longer lists of options, consider using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/select",
          children: "Select component"
        }), " instead."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " keep label options concise when possible."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use toggle groups to display long lists of options."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/checkbox",
          children: "Checkbox"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/radio-button",
          children: "RadioButton"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/toggle",
          children: "Toggle"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}