"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694130;
const slug = exports.slug = 'guides/cms/content/themes/getting-started';
const title = exports.title = 'Primeros pasos para usar temas';
const name = exports.name = '[nuevo] Primeros pasos para usar temas';
const metaDescription = exports.metaDescription = 'Primeros pasos con los temas en el CMS de HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-comenzar-un-proyecto-de-tema-de-plantilla",
  "label": "1. Comenzar un proyecto de tema de plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-sube-el-cms-boilerplate-a-tu-cuenta-de-hubspot",
  "label": "2. Sube el CMS Boilerplate a tu cuenta de HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-crea-una-p%C3%A1gina",
  "label": "3. Crea una página",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-editar-campos-del-tema",
  "label": "4. Editar campos del tema",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-prep%C3%A1rate-para-realizar-cambios-locales",
  "label": "5. Prepárate para realizar cambios locales",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-agregar-un-campo-de-tema",
  "label": "6. Agregar un campo de tema",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-haz-referencia-al-campo-en-tu-css",
  "label": "7. Haz referencia al campo en tu CSS",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-cambios-en-las-pruebas",
  "label": "8. Cambios en las pruebas",
  "parent": null
}, {
  "depth": 0,
  "id": "pasos-siguientes",
  "label": "Pasos siguientes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Primeros pasos para usar temas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema es una colección portátil y contenida de materiales para desarrolladores diseñada para permitir una experiencia de edición de contenido flexible. Estos materiales pueden incluir plantillas, módulos, archivos CSS, archivos JavaScript, imágenes y más. Los temas permiten a los desarrolladores crear un conjunto de campos temáticos, similares a los campos de los módulos, que permiten a los creadores de contenidos controlar los estilos globales del sitio web sin tener que editar el CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes utilizar HubL para acceder a los valores de los campos del tema a través del CSS del tema. Los creadores de contenido utilizan el editor de temas para modificar los campos del tema, previsualizar esos cambios con las plantillas existentes dentro de un tema y publicar sus cambios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/cms-themes-animation.gif",
        alt: "Animación de la UI de edición de la configuración del tema que muestra la selección de un color para los elementos de un tema."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este documento te guiará en la creación de tu primer tema basado en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "Boilerplate de CMS Hub"
      }), ". Para más información sobre los temas, consulta la", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: " documentación de referencia de los temas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Si esta es tu primera experiencia con el desarrollo del Hub CMS, se recomienda que sigas:"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Inicio rápido del desarrollo de CMS Hub"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " antes de empezar, necesitarás instalar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLI de HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Comenzar un proyecto de tema de plantilla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create website-theme my-website-theme"
      }), " para crear un directorio poblado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-website-theme"
      }), " con archivos de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "boilerplate del tema CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Sube el CMS Boilerplate a tu cuenta de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ejecutar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-website-theme my-website-theme"
      }), ". Esto subirá la plantilla al administrador de diseño de tu cuenta, en una carpeta titulada ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "my-website-theme"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Crea una página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear una página a partir del tema cargado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sitio web"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Páginas del sitio web."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Página del sitio web"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "dominio"
        }), " en el que se publicará la página y luego introduce un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre de página"
        }), ". Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear página"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-page-from-dashboard.gif",
        alt: "create-page-from-dashboard"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la pantalla de selección de plantillas, las plantillas de tu ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/use-themes#use-an-active-theme",
          children: "tema activo"
        }), " aparecerán en la parte superior de la página.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si no has seleccionado un tema activo, coloca el cursor sobre ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Boilerplate de tema CMS"
            }), " y haz clic en** Establecer como tema activo**."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si ya has establecido un tema activo, selecciona tu nuevo tema haciendo clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "menú desplegable"
            }), " del selector de temas y luego seleccionando ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Cambiar tema"
            }), ". Luego, coloca el cursor sobre ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Boilerplate de tema CMS"
            }), " y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Establecer como tema activo"
            }), ". En la página siguiente, selecciona una ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "plantilla"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/theme-selector.gif",
        alt: "theme-selector"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, se te dirigirá al editor de páginas donde puedes editar los campos del tema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Editar campos del tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda del editor de páginas, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Temas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Temas"
        }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar configuración del tema"
        }), ". Aquí es donde puedes modificar la configuración de tu tema existente. La publicación de los cambios en la configuración del tema actualizará los estilos en todas las páginas que utilicen este tema actualizado."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-theme-settings.gif",
        alt: "edit-theme-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Prepárate para realizar cambios locales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vuelve a tu terminal y ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch my-website-theme my-website-theme"
      }), ". Este ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "comando"
      }), " vigila tu directorio local y sube automáticamente los cambios a tu cuenta de HubSpot al guardar los archivos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Agregar un campo de tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ahora que estamos escuchando los cambios locales, agrega un nuevo campo de tema:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Abra el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " en su editor. Este archivo controla los campos disponibles en la barra lateral del editor de temas. Vamos a agregar un nuevo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview#fields-json",
          children: "campo"
        }), " para especificar la altura del pie de página."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cerca de la parte inferior del archivo, localiza el grupo de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "footer"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copia el código de abajo y pega el JSON en el archivo por encima del primer elemento de la matriz de elementos secundarios para el grupo de pie de página."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n      {\n      \"id\" : \"\",\n      \"name\" : \"height\",\n      \"label\" : \"Footer height\",\n      \"required\" : false,\n      \"locked\" : false,\n      \"display\" : \"text\",\n      \"step\" : 1,\n      \"type\" : \"number\",\n      \"min\" : 10,\n      \"max\" : 900,\n      \"help_text\":\"This footer will expand in height to accomodate any content added to the footer. You are setting the minimum height in px\",\n      \"default\" : 100\n      },\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Guarda ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " y actualiza la vista previa del tema en HubSpot. Tu nuevo campo debería aparecer en la barra lateral izquierda."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Haz referencia al campo en tu CSS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu editor de código, abre el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), ". Luego, localiza el selector css para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), ". Ahora vamos a agregar una ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "min-height"
        }), " a este selector."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para acceder a un valor en un tema, utiliza el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme"
        }), ". Por ejemplo, se utilizaría ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ theme.footer.height }}"
        }), " para acceder al valor de altura establecido en nuestro campo de altura."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Reemplaza la declaración ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), " en theme-overrides.css con lo siguiente:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".footer {\n  background-color: {{ footer_bg_color }};\n  min-height: {{theme.footer.height}}px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Guarda el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), " para subirlo a tu cuenta de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Cambios en las pruebas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vuelve al editor de temas, y actualiza la página para ver tu nuevo campo aparecer bajo el pie de página. Actualiza el valor de la altura para que se refleje inmediatamente en la vista previa. Podría ser útil establecer un color de fondo para el pie de página para que puedas ver el cambio más fácilmente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pasos siguientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que has creado y actualizado tu tema, puedes crear más campos temáticos y personalizarlos para tus proyectos. Para obtener más opciones de personalización, consulta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "resumen de temas"
      }), ". Mientras construyes tu tema, puede ser útil ver las mejores prácticas para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "optimizar sitios web en el CMS de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot tiene varios ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/default-themes",
        children: "temas predeterminados"
      }), " que vienen con CMS Hub. Estos temas están disponibles para que los veas, los clones y los actualices, para aprender cómo podrías usar un tema en un escenario del mundo real."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que tengas un control sobre los temas, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "aprende a construir tu primer módulo personalizado"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}