"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310862;
const slug = exports.slug = 'guides/cms/features';
const title = exports.title = 'HubSpotのCMS機能';
const name = exports.name = 'APAC JAPAN (ja) | [new] CMS Features_JA | 202108';
const metaDescription = exports.metaDescription = '開発者とマーケティング担当者は、HubSpotの豊富なCMS機能を駆使してサイトを構築したり、HubSpotの優れたCRM機能を基盤として活用したりできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cms%E9%96%8B%E7%99%BA%E8%80%85%E7%94%A8%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9",
  "label": "CMS開発者用サンドボックス",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E6%A4%9C%E7%B4%A2",
  "label": "コンテンツ検索",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%A4%9A%E8%A8%80%E8%AA%9E",
  "label": "多言語",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC",
  "label": "ファイルマネージャー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%82%B9%E3%83%86%E3%83%BC%E3%82%B8%E3%83%B3%E3%82%B0",
  "label": "コンテンツステージング",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb",
  "label": "HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9%E8%A8%AD%E5%AE%9A",
  "label": "アクセス権設定",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0",
  "label": "サーバーレス関数",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-api",
  "label": "HubSpot API",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      strong: "strong",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS機能"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者とマーケティング担当者は、HubSpotの豊富なCMS機能を駆使してサイトを構築したり、HubSpotの優れたCRM機能を基盤として活用したりできます。安全性が確保されたステージングから、HubDBを活用した構築、特定のユーザーをターゲットとしたコンテンツ提供、読者にとって最適な言語でのコンテンツ表示まで ―。全てをHubSpotのCMS上で実現できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS開発者用サンドボックス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["無料で利用できる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS開発用サンドボックス"
      }), "を作成すると、HubSpotの全ての開発ツールや機能を利用できます。自分のサンドボックスを使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/products/cms",
        children: "CMS Hub"
      }), "上に優れたサイトを構築する方法を学んでください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツ検索"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サードパーティーの検索ツールは必要ありません。HubSpotのネイティブ検索エンジンを使用することで、訪問者は必要なコンテンツをHubSpotのCMSに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "組み込みのサイト検索機能"
      }), "によって見つけることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "多言語"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSを使用すれば、コンテンツの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "多言語バリエーション"
      }), "を作成できます。これによりエンドユーザーは、最適な言語でコンテンツを表示できます。さらにHubSpotは、エンドユーザーに合った言語を開発者が提供するためのツールも備えています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルマネージャー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/file-manager",
        children: "HubSpotファイルマネージャー"
      }), "は、ウェブサイトのファイルの保存や配信に使用できます。ファイルは、アプリ内のUIを使用して、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "経由で、ファイルマネージャーに保存できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツステージング"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "コンテンツステージング"
      }), "機能では、ウェブサイトの作成者が、変更を試しながらページのデザインとコンテンツを更新できます。ステージングした変更は、本番サイトに公開できます。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "コンテンツのステージングはCMS Hub Professionalの機能です。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), "は、スプレッドシートのようなリレーショナル データ ストアです。HubDBでのテーブル作成やデータ操作は、ユーザーインターフェイスまたはHubDB APIを使用して行うことができます。HubSpotのHubLマークアップタグを使用して、データをCMSに直接取り込むことも、HubDBテーブル行に基づいてページを動的に生成することもできます。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubDBはCMS Hub Professionalの機能です。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセス権設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub Enterpriseの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "アクセス権設定（メンバーシップ）"
      }), "機能では、CRM上のコンタクトがサイトの特定のコンテンツを表示しようとした際に、ログインを求めることができます。メンバーシップページの後ろに配置されたコンテンツは、ログインしたコンタクトに合わせて高度にパーソナライズすることが可能で、CRMから取り出したコンタクト、会社、取引、製品のデータを提示することもできます。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "アクセス権設定（メンバーシップ）機能はCMS Hub Enterpriseの機能です。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サーバーレス関数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "サーバーレス関数"
      }), "を使用すると、HubSpotやサードパーティーのサービスをAPI経由で操作できるサーバーサイドコードを作成できます。貴社の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/products/cms",
        children: "CMS Hub"
      }), "ウェブサイトに高度な機能を追加するために、サーバーレス関数を活用してください。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "サーバーレス関数はCMS Hub Enterpriseの機能です。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "HubSpot API"
      }), "では、CMSとCRMデータへの認証済みの安全なアクセスが提供されます。APIを使用して、アプリの機能連携を素早く手軽に作成できます。HubSpotの全てのAPIはRESTによって構築されています。RESTful APIを利用した経験があれば、さまざまな概念になじみがあるはずです。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}