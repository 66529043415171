"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61303889553;
const slug = exports.slug = 'guides/api/crm/engagements/email';
const title = exports.title = 'Engajamentos | E-mail';
const name = exports.name = 'Engajamentos | E-mail';
const metaDescription = exports.metaDescription = 'Use a API de engajamento de e-mail para registrar e gerenciar e-mails nos registros do CRM. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-um-email",
  "label": "Criar um email",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades",
  "label": "Propriedades",
  "parent": "criar-um-email"
}, {
  "depth": 1,
  "id": "propriedades-somente-leitura",
  "label": "Propriedades somente leitura",
  "parent": "criar-um-email"
}, {
  "depth": 1,
  "id": "definir-cabe%C3%A7alhos-do-e-mail",
  "label": "Definir cabeçalhos do e-mail",
  "parent": "criar-um-email"
}, {
  "depth": 1,
  "id": "associa%C3%A7%C3%B5es",
  "label": "Associações",
  "parent": "criar-um-email"
}, {
  "depth": 0,
  "id": "recuperar-e-mails",
  "label": "Recuperar e-mails",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-e-mails",
  "label": "Atualizar e-mails",
  "parent": null
}, {
  "depth": 1,
  "id": "associar-chamadas-existentes-a-registros",
  "label": "Associar chamadas existentes a registros",
  "parent": "atualizar-e-mails"
}, {
  "depth": 1,
  "id": "remover-uma-associa%C3%A7%C3%A3o",
  "label": "Remover uma associação",
  "parent": "atualizar-e-mails"
}, {
  "depth": 0,
  "id": "fixar-um-e-mail-em-um-registro",
  "label": "Fixar um e-mail em um registro",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-e-mails",
  "label": "Excluir e-mails",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "E-mail"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a API de engajamento de e-mail para registrar e gerenciar e-mails nos registros do CRM. Você pode registrar atividades de e-mail ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "no HubSpot"
      }), " ou por meio da API de e-mails."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abaixo, aprenda os métodos básicos de gerenciamento de e-mails por meio da API. Para visualizar todos os pontos de extremidade disponíveis e seus requisitos, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um email"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um engajamento de e-mail, envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo da solicitação, adicione detalhes do e-mail em um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propriedades"
      }), ". Você também pode adicionar um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associações"
      }), " para associar seu novo e-mail a um registro existente (por exemplo, contatos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No objeto de propriedades, você pode incluir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Campo | Descrição |\n| --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_timestamp"
      }), " | Obrigatório. Este campo marca a hora de criação do e-mail e determina onde ele se encontra na linha do tempo do registro. Você pode usar um carimbo de data e hora do Unix em milissegundos ou no formato UTC. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot_owner_id"
      }), " | O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "ID do proprietário"
      }), " associado ao e-mail. Este campo determina o usuário listado como o criador do e-mail na linha do tempo do registro. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_direction"
      }), " | A direção em que o e-mail foi enviado Os valores possíveis incluem:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EMAIL"
      }), ": o e-mail foi enviado do CRM ou enviado e registrado no CRM com o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
        children: "endereço Cco."
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "INCOMING_EMAIL"
      }), ": o e-mail era uma resposta a um e-mail de saída registrado. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARDED_EMAIL"
      }), ": o e-mail foi ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
        children: "encaminhado para o CRM."
      }), " |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_html"
      }), " | O corpo de um e-mail se ele for enviado de um registro do CRM. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_status"
      }), " | O status de envio do e-mail O valor pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCED"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FAILED"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SCHEDULED"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENDING"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_subject"
      }), " | A linha de assunto do e-mail registrado. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_text"
      }), " | O corpo do e-mail. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_attachment_ids"
      }), " | Os IDs dos anexos do e-mail. Vários IDs de anexo são separados por ponto e vírgula. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), " | Os cabeçalhos do e-mail. O valor dessa propriedade preencherá automaticamente determinadas propriedades de e-mail somente leitura. Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/email#set-email-headers",
        children: "definir cabeçalhos de e-mail"
      }), " | . |"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre a criação de engajamentos de e-mail clicando na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades somente leitura"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Há também algumas propriedades de e-mail que são somente leitura e que são preenchidas automaticamente pelo HubSpot. As propriedades na tabela abaixo são preenchidas automaticamente a partir do valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Endereço de e-mail do remetente do e-mail"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nome do remetente do e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sobrenome do remetente do e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Endereços de e-mail dos destinatários"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Primeiros nomes dos destinatários do e-mail"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sobrenomes dos destinatários do e-mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " ao recuperar um cabeçalho de e-mail, observe que há valores para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), ". Geralmente são os mesmos, mas como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " identifica quem realmente enviou um e-mail, há situações em que os valores podem ser diferentes. Por exemplo, se um e-mail for enviado de um alias de e-mail, o valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " fará referência ao Endereço de e-mail real do usuário e o valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " fará referência ao alias de e-mail."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir cabeçalhos do e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como os cabeçalhos preenchem automaticamente as propriedades somente leitura, convém definir manualmente os cabeçalhos de e-mail. Para definir o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), ", você pode usar uma string com escape JSON com os seguintes dados:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example data\n{\n  \"from\": {\n    \"email\": \"from@domain.com\",\n    \"firstName\": \"FromFirst\",\n    \"lastName\": \"FromLast\"\n  },\n  \"to\": [\n    {\n      \"email\": \"ToFirst ToLast<to@test.com>\",\n      \"firstName\": \"ToFirst\",\n      \"lastName\": \"ToLast\"\n    }\n  ],\n  \"cc\": [],\n  \"bcc\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, sua solicitação para criar um e-mail pode ter a seguinte aparência:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"47550177\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for youremail\",\n    \"hs_email_headers\": \"{\\\"from\\\":{\\\"email\\\":\\\"from@domain.com\\\",\\\"firstName\\\":\\\"FromFirst\\\",\\\"lastName\\\":\\\"FromLast\\\"},\\\"sender\\\":{\\\"email\\\":\\\"sender@domain.com\\\",\\\"firstName\\\":\\\"SenderFirst\\\",\\\"lastName\\\":\\\"SenderLast\\\"},\\\"to\\\":[{\\\"email\\\":\\\"ToFirst+ToLast<to@test.com>\\\",\\\"firstName\\\":\\\"ToFirst\\\",\\\"lastName\\\":\\\"ToLast\\\"}],\\\"cc\\\":[],\\\"bcc\\\":[]}\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar e associar um e-mail a registros existentes, inclua um objeto de associações na solicitação. Por exemplo, para criar um e-mail e associá-lo a um negócio e a um contato, o corpo da solicitação pode ser semelhante ao seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 601\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 210\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 602\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 198\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No objeto de associações, você deve incluir o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O registro que deseja associar ao e-mail, especificado por seu valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " exclusivo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de associação entre o e-mail e o registro. Inclua ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Os IDs de tipo de associação padrão são listados ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aqui"
            }), ", ou você pode recuperar o valor de tipos de associação personalizados (ou seja, rótulos) por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode recuperar e-mails individualmente ou em massa. Saiba mais sobre a recuperação em massa clicando na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar um e-mail individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), ". Você também pode incluir os seguintes parâmetros no URL da solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista separada por vírgulas de tipos de objeto para recuperar IDs associados. Todas as associações especificadas que não existem não serão retornadas na resposta. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de associações."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar uma lista de todos os e-mails, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/emails"
      }), ". Você pode incluir os seguintes parâmetros na URL da solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número máximo de resultados a serem exibidos por página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atualizar e-mails individualmente ou em massa. Para atualizar um e-mail individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo da solicitação, inclua as propriedades de e-mail que deseja atualizar. Por exemplo, o corpo da sua solicitação pode ser parecido com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk tomorrow\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect!\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot ignorará valores para propriedades somente leitura e inexistentes. Para limpar um valor de propriedade, passe uma string vazia para a propriedade no corpo da solicitação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a atualização em massa clicando na guia** Pontos de extremidade** na parte superior deste artigo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar chamadas existentes a registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar um e-mail a registros, como um contato e suas empresas associadas, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". A URL da solicitação contém os campos a seguir:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emailId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O tipo de objeto ao qual você deseja associar o e-mail (por exemplo, contato ou empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do registro ao qual você deseja associar o e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um identificador exclusivo para indicar o tipo de associação entre o e-mail e o outro objeto. O ID pode ser representado numericamente ou em maiúsculas (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_to_contact"
            }), "). Você pode recuperar o valor por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, a URL da sua solicitação pode ser parecida com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/emails/17691787884/associations/contact/104901/198"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remover uma associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para remover uma associação entre um e-mail e um registro, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para o mesmo URL acima:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fixar um e-mail em um registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "fixar um e-mail"
      }), " em um registro para que permaneça no topo da linha do tempo do registro. A chamada já deve estar associada ao registro antes da fixação e você só pode fixar uma atividade por registo. Para fixar um e-mail, inclua o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " do e-mail no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " ao criar ou atualizar um registro por meio das APIs de objeto. Saiba mais sobre como usar as APIs de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contatos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negócios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando você exclui um e-mail, ele é excluído permanentemente e não ", (0, _jsxRuntime.jsx)("u", {
        children: "pode"
      }), " ser restaurado. Você pode recuperar e-mails individualmente ou em lotes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir um e-mail individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a exclusão em massa clicando na guia** Pontos de extremidade** na parte superior deste artigo."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}