"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021628;
const slug = exports.slug = 'guides/apps/authentication/working-with-oauth';
const title = exports.title = 'Working with OAuth';
const name = exports.name = 'vNext Docs DP - Working with OAuth';
const metaDescription = exports.metaDescription = 'OAuth is a secure means of authentication for your app. It uses authorization tokens rather than a password to connect your app to a user account. ';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "initiating-an-integration-with-oauth-2.0",
  "label": "Initiating an integration with OAuth 2.0",
  "parent": null
}, {
  "depth": 0,
  "id": "query-parameters",
  "label": "Query parameters",
  "parent": null
}, {
  "depth": 0,
  "id": "configure-scopes",
  "label": "Configure scopes",
  "parent": null
}, {
  "depth": 0,
  "id": "related-docs",
  "label": "Related docs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h5: "h5",
      h2: "h2",
      em: "em",
      img: "img",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Working with OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuth is a secure means of authentication that uses authorization tokens rather than a password to connect your app to a user account. Initiating OAuth access is the first step towards allowing users to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#install-an-app",
        children: "install your app"
      }), " in their HubSpot accounts."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "any app designed for installation by multiple HubSpot accounts or listing on the App Marketplace must use OAuth."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["users installing apps in their HubSpot account must either be a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
            children: "super admin"
          }), " or have ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: "App Marketplace Access"
          }), " permissions."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Recommended resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "OAuth Quickstart Guide"
        }), " will get you up and running with a working example app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["This ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/academy/tracks/71/593/2967",
          children: "HubSpot Academy tutorial"
        }), " provides a quick introduction on using OAuth with HubSpot, including a breakdown of the HubSpot-OAuth flow and how to refresh an access token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initiating an integration with OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To initiate an integration with OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["First, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "create an app"
        }), " in a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers/",
          children: "HubSpot developer account"
        }), ". After creating the app, you'll be able to find the app's client ID and client secret on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Auth"
        }), " page of your app settings."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/MyHubSpotApp.png",
        alt: "MyHubSpotApp"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use the client ID and client secret, along with the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-parameters",
            children: "query parameters"
          }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#scopes",
            children: "scopes"
          }), " outlined below, to build your authorization URL."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Send users installing your app to the authorization URL, where they'll be presented with a screen that allows them to select their account and grant access to your integration. You can set the authorization URL to be for a specific HubSpot account by adding the account ID between ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/oauth/"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/authorize"
          }), ", as shown below. After granting access, they'll be redirected back to your application via a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "redirect_url"
          }), ", which will have a code query parameter appended to it. You'll use that code and the client secret to get an ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/app-management/oauth-tokens",
            children: "access_token and refresh_token"
          }), " from HubSpot."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Example authorization URLs"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Any account:"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Specific account (ID 123456):"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/123456/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Example redirect URL:"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/?code=xxxx"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Example error:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/?error=error_code&error_description=Human%20readable%20description%20of%20the%20error"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " to authenticate any API calls made for that HubSpot account."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Once the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " expires, use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "refresh_token"
          }), " to generate a new ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["your app will not appear as a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Connected App"
          }), " in a user's account unless you generate the refresh token and initial access token."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["access tokens reflect the scopes requested from the app and ", (0, _jsxRuntime.jsx)("u", {
            children: "do not"
          }), " reflect the permissions or limitations of what a user can do in their HubSpot account. For example, if a user has permissions to view only owned contacts but authorizes a request for the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.objects.contacts.read"
          }), " scope, the resulting access token can view all contacts in the account and not only those owned by the authorizing user."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Query parameters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following parameters are required when building an authorization URL for your app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parameter"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "How to use"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Client ID"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id=x"
            }), "Used in the URL"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get this from your app's Auth settings page (as described above)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Redirect URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri=x"
            }), "The URL visitors will be redirected to after granting access to your app."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["You'll also designate this on your app's Auth settings page.", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Note:"
            }), " For security reasons, this URL ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "must"
            }), " use https in production. (When testing using localhost, http can be used.) You also ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "must"
            }), " use a domain, as IP addresses are not supported."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Scope"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A space-separated set of permissions that your app needs access to. Any scopes that you've checked off in your app's Auth settings will be treated as required, and you'll need to include them in this parameter or the authorization page will display an error.Additionally, users will get an error if they try to install your app on an account that doesn't have access to an included scope.See the Scopes table below for more details about which endpoints can be accessed by specific scopes."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following parameters are optional:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parameter"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "How to use"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Optional scopes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&optional_scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A space-separated set of optional permissions for your app. Optional scopes will be automatically dropped from the authorization request if the user selects a HubSpot account that does not have access to that tool (such as requesting the social scope on a CRM only portal). If you're using optional scopes, you will need to check the access token or refresh token to see which ones were granted. See the table below for more details about scopes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "State"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "&state=y"
            }), "If this parameter is included in the authorization URL, the value will be included in a state query parameter when the user is directed to the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_url"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A string value that can be used to maintain the user's state when they're redirected back to your app."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configure scopes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuth requires you to set scopes, or permissions, for your app. Each scope provides access to a set of HubSpot API endpoints and allows users to grant your app access to specific tools in their HubSpot account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/granular-scopes-selection.png",
        alt: "granular-scopes-selection"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Access to specific APIs or endpoints depends on HubSpot account tier. You can find a full list of available scopes and accessible endpoints in the table below. If your app can work with multiple types of HubSpot accounts, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " parameter to include any tiered scopes you work. This way, customers using CRM-only accounts can still authorize your app, even if they can't access all of its scopes. Your app must check for and handle any scopes that it doesn't get authorized for."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Provides access to"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Required account tier"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integrators can list CMS domains in a customer's account."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integrators can create, update, and delete CMS custom domains."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integrators can view all CMS serverless functions, any related secrets, and function execution results."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Enterprise"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integrators can write CMS serverless functions and secrets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to update knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.publish"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to update and publish knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View general and template knowledge base settings, such as the domain or root URL."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to update general and template knowledge base settings. This includes write access to knowledge articles."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.performance.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integrators can view CMS performance data for all your sites."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about contact lists."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "List endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to contact lists"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "List endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about companies."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and create, delete, or make changes to companies."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Companies endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and create, delete, and make changes to contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacts endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about custom objects in the HubSpot CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to custom objects in the HubSpot CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and create, delete, or make changes to deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.feedback_submission.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about submissions to any of your feedback surveys."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feedback survey endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.goals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View all goal types."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Goals endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about line items"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Line items endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to line items."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Line items endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about marketing events."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing events endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to marketing events."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing events endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.owners.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about users assigned to a CRM record."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Owners endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View properties and other details about quotes and quote templates."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quote endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to quotes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quote endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for companies"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to property settings for companies."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to property settings for contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about custom object definitions in the HubSpot CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Custom objects endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about property settings for deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, delete, or make changes to property settings for deals."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Properties endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about line items."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Line items endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about quotes and quotes templates."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quote endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.billing.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Make changes to your account's billing settings. This includes managing and assigning paid seats for users."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Settings endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reads existing exchange rates along with the current company currency associated with your portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account information endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, update and delete exchange rates along with updating the company currency associated with your portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account information endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about account users and their permissions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Manage users and user permissions on your HubSpot account. This includes creating new users, assigning permissions and roles, and deleting existing users."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.teams.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "See details about the account's teams."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.team.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Assign users to teams on your HubSpot account."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "User Provisioning endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account-info.security.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Includes access to account activity logs and other account security information."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Account activity API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accounting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allows HubSpot and the accounting integration to share invoice, product, and contact details."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Accounting Extension API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add forms to the contact's pages that do custom actions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM Extensions API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "analytics.behavioral_events.send"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Includes access to send custom behavioral events."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes workflows."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Automation API (Workflows endpoints)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "behavioral_events.event_definitions.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create, read, update, or delete behavioral events. This includes behavioral event properties."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business_units.view.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View business unit data, including logo information."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Business Units API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Business Units Add-on"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business-intelligence"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes endpoints that sit on top of sources and email."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Analytics API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_query.execute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Query data from your HubSpot account using the GraphQL API endpoint"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphQL API endpoint"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_schema.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Perform introspection queries via GraphQL application clients such as GraphiQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQL and other 3rd party GraphQL clients"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Professional"]
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details of your contacts' subscription preferences."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscription Preferences API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscribe/unsubscribe contacts to your subscription types. It won't subscribe contacts who have unsubscribed."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscription Preferences API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscribe/unsubscribe contacts to your subscription types. It won't subscribe contacts who have unsubscribed."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subscription Preferences API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes sites, landing pages, email, blog, and campaigns."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS API and Calendar, Email and Email Events endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise,"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details about threads in the conversations inbox."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Conversations inbox and messages API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.visitor_identification.tokens.create"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fetch identification tokens for authenticated website visitors interacting with the HubSpot chat widget."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Visitor Identification API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Any ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send messages in conversations. Create and update message threads."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Conversations inbox and messages API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.export"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Export records from your CRM for all CRM data types."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM Exports API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.import"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allows you to import records into your CRM. This includes creating new records or modifying any of your existing records for all CRM data types (contacts, companies, deals, tickets, etc). It doesn't include archiving or deleting any data."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM Imports API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ctas.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Allows read access for CTAs."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No publicAPI available"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })]
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter, Professional or Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to e-commerce features."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Products and line items endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "external_integrations.forms.access"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Includes the ability to rename, delete, and clone existing forms."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Forms endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to File Manager."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Files (File Manager) and file mapper (CMS templates, modules, and layout) endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "View details or download user files, attachments, and system files from all HubSpot tools."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Files (File Manager) and file mapper (CMS templates, modules, and layout) endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to the Forms endpoints."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Forms endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms-uploaded-files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Download files submitted through a form."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get a file uploaded via form submission endpoint"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to HubDB."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " with ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/website",
              children: "Website Add-on"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integration-sync"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This exposes the sync API, which allows syncing of most CRM objects."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ecommerce Bridge API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to events and objects from the media bridge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Media Bridge API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to create and update events and objects from the media bridge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Media Bridge API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oauth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Basic scope required for OAuth. This scope is added by default to all apps."
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sales-email-read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to read all details of one-to-one emails sent to contacts."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Engagements endpoints", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Note:"
            }), " This scope is required to get the content of email engagements. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/engagement-details#email-content",
              children: "Engagements overview"
            }), " for more details."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes Social Inbox."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Social Media API"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes access to tickets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any accounbt"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grants access to manage custom events on HubSpot CRM records. This includes creating or updating records."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Timeline Events endpoints"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transactional-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This includes transactional emails and the transactional emails endpoints."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Transactional email endpoints"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["**Marketing Hub** Professional or Enterprise with ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/email/transactional-email",
              children: "Transactional Email Add-on"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related docs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Auth methods on HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "OAuth Quickstart Guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "Managing tokens"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}