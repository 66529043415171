"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377942;
const slug = exports.slug = 'guides/api/cms/content-audit';
const title = exports.title = 'API-Content-Audit-API';
const name = exports.name = 'EMEA DACH (de) vNext Docs DP - CMS Content Audit API';
const metaDescription = exports.metaDescription = 'Die API-Content-Audit-API ermöglicht es Ihnen, Änderungen am Content-Objekt nach Typ, Zeitraum oder HubSpot-Benutzer-ID zu filtern und zu sortieren, damit Sie stets den Überblick behalten.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cms-event-typ-listing",
  "label": "CMS-Event-Typ-Listing",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-objekttypen-listing",
  "label": "CMS-Objekttypen-Listing",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "CMS-Content-Audit"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die CMS-Content-Audit-API ermöglicht es Ihnen, Änderungen am Content-Objekt nach Typ, Zeitraum oder HubSpot-Benutzer-ID zu filtern und zu sortieren,. Dieser Endpunkt ist nur für Accounts mit einem ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub"
        }), " Enterprise"]
      }), "-Abonnement verfügbar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Anwendungsfall als Beispiel:"
      }), " Finden Sie heraus, welcher Benutzer zuletzt Änderungen an einer Listen von Seiten vorgenommen hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS-Event-Typ-Listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Event-Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CREATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Objekt wurde erstellt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DELETED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Objekt wurde gelöscht oder getrennt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Objekt wurde veröffentlicht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UNPUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Objekt wurde nicht veröffentlicht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Objekt wurde aktualisiert."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS-Objekttypen-Listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Objekttyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die in Ihren Account-Einstellungen an Ihren Blog-Einstellungen vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog-Beiträge, die mit Ihrem oder Ihren Blogs verknüpft sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTENT_SETTINGS"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die in Ihren Account-Einstellungen an Ihren Website-Einstellungen vorgenommen werden. Die geänderten Werte werden im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta"
            }), "-JSON-Array angezeigt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CTA"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die an Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/cta/get-started-with-calls-to-action-ctas",
              children: "Calls-to-Action (CTAs)"
            }), " vorgenommen werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOMAIN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die in Ihren Account-Einstellungen an den in Ihren Einstellungen für „Domains & URLs“ verknüpften Domains vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die an E-Mails im E-Mail-Editor vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FILE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die an Dateien im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/cos-general/organize-edit-and-delete-files",
              children: "Datei-Tool"
            }), " vorgenommen werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GLOBAL_MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/cos-general/can-i-make-changes-to-a-global-module-in-only-one-template",
              children: "globalen Modulen"
            }), " vorgenommen werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBDB_TABLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die an HubDB-Tabellen vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die in Ihren Account-Einstellungen an Ihren Wissensdatenbank-Einstellungen vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die an Wissensdatenbank-Artikeln im Content-Editor vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die an Landingpages im Content-Editor vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview",
              children: "Modulen"
            }), " vorgenommen werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SERVERLESS_FUNCTION"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
              children: "serverlosen Funktionen"
            }), " vorgenommen werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TEMPLATE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "Vorlagen"
            }), " vorgenommen werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "THEME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Änderungen, die an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/website-pages/edit-your-global-theme-settings",
              children: "Designeinstellungen"
            }), " und beim Erstellen von ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/website-pages/edit-your-global-theme-settings",
              children: "Designs"
            }), " vorgenommen werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "URL_MAPPING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die in Ihren Account-Einstellungen an Ihren URL-Weiterleitungen in den Einstellungen für URL-Weiterleitungen vorgenommen werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBSITE_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Änderungen, die an Webseiten-Seiten im Content-Editor vorgenommen werden."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}