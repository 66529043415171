'use es6';

export const getRequestBody = har => {
  let requestBody;
  try {
    requestBody = har.log.entries[0].request.postData.text || null;
  } catch (e) {
    requestBody = null;
  }
  return requestBody;
};