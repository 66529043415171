"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 44206971922;
const slug = exports.slug = 'getting-started/slack/code-of-conduct';
const title = exports.title = 'The HubSpot Developer Slack Community Code of Conduct';
const name = exports.name = 'Developer Slack Code of Conduct';
const metaDescription = exports.metaDescription = "HubSpot's Developer Slack is a dedicated community for HubSpot's most engaged developers to gather in the name of developer-to-developer support and collaboration around the HubSpot platform.";
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Collaborate in the Developer Slack';
const toc = exports.toc = [{
  "depth": 0,
  "id": "preamble",
  "label": "Preamble",
  "parent": null
}, {
  "depth": 0,
  "id": "updates-to-this-code-of-conduct",
  "label": "Updates to this Code of Conduct",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-support-expectations",
  "label": "HubSpot Support Expectations",
  "parent": null
}, {
  "depth": 0,
  "id": "expected-behavior",
  "label": "Expected Behavior",
  "parent": null
}, {
  "depth": 1,
  "id": "be-humble",
  "label": "Be Humble",
  "parent": "expected-behavior"
}, {
  "depth": 1,
  "id": "be-empathetic",
  "label": "Be Empathetic",
  "parent": "expected-behavior"
}, {
  "depth": 1,
  "id": "be-adaptable",
  "label": "Be Adaptable",
  "parent": "expected-behavior"
}, {
  "depth": 1,
  "id": "be-remarkable",
  "label": "Be Remarkable",
  "parent": "expected-behavior"
}, {
  "depth": 1,
  "id": "be-transparent",
  "label": "Be Transparent",
  "parent": "expected-behavior"
}, {
  "depth": 0,
  "id": "unacceptable-behavior",
  "label": "Unacceptable Behavior",
  "parent": null
}, {
  "depth": 1,
  "id": "threats-of-violence",
  "label": "Threats of Violence",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "personal-attacks",
  "label": "Personal Attacks",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "impersonation-of-hubspot-employment",
  "label": "Impersonation of HubSpot Employment",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "derogatory%2Fharmful-language-and-harassment",
  "label": "Derogatory/Harmful Language and Harassment",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "unwanted-solicitation-of-services",
  "label": "Unwanted Solicitation of Services",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "abuse-of-voice%2Fvideo-calling",
  "label": "Abuse of Voice/Video Calling",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "undisclosed-use-of-ai-in-questions%2C-responses%2C-or-conversations",
  "label": "Undisclosed use of AI in Questions, Responses, or Conversations",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "channel-and-user-spamming",
  "label": "Channel and User Spamming",
  "parent": "unacceptable-behavior"
}, {
  "depth": 1,
  "id": "influencing-of-unacceptable-behavior",
  "label": "Influencing of Unacceptable Behavior",
  "parent": "unacceptable-behavior"
}, {
  "depth": 0,
  "id": "use-of-the-hubspot-developer-slack-for-private-team-conversations",
  "label": "Use of the HubSpot Developer Slack for Private Team Conversations",
  "parent": null
}, {
  "depth": 0,
  "id": "voice%2Fvideo-calls-%2B-screen-sharing",
  "label": "Voice/Video Calls + Screen Sharing",
  "parent": null
}, {
  "depth": 0,
  "id": "slack-%E2%80%9Capps%E2%80%9D-usage",
  "label": "Slack “Apps” Usage",
  "parent": null
}, {
  "depth": 0,
  "id": "keeping-topics-together-threaded-messages",
  "label": "Keeping Topics Together (Threaded Messages)",
  "parent": null
}, {
  "depth": 0,
  "id": "gifs-and-emojis",
  "label": "Gifs and Emojis",
  "parent": null
}, {
  "depth": 0,
  "id": "surveys-and-surveying-developer-slack-users",
  "label": "Surveys and Surveying Developer Slack Users",
  "parent": null
}, {
  "depth": 0,
  "id": "reporting-code-of-conduct-violations",
  "label": "Reporting Code of Conduct Violations",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Developer Slack Code of Conduct"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preamble"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot's Developer Slack is a dedicated community for HubSpot's developers to gather in the name of developer-to-developer support and collaboration around the HubSpot platform. By using our Developer Slack, you agree to abide by the following Developer Slack Code of Conduct (“Code of Conduct”), ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "HubSpots Acceptable Use Policy"
      }), " as applicable, and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://slack.com/acceptable-use-policy",
        children: "Slack’s Acceptable Use Policy"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Updates to this Code of Conduct"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot reserves the right to update this Code of Conduct at any time. When updates do occur, we are committed to communicating these changes via the #releases-and-updates channel on the Developer Slack, but because this Code of Conduct is written anticipating common scenarios, and we cannot anticipate every possible scenario that could occur, we may need to update it and take action on violations that may not be explicitly described here without prior notification of the community."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot reserves the right to downgrade or deactivate the Developer Slack at any time, but are committed to doing so with plenty of advanced notice to the community."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot Support Expectations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Developer Slack is not an official support channel of HubSpot. While this Slack may contain HubSpot Employees who are happy to assist when they can, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "please refrain from mentioning or messaging HubSpot employees with direct questions"
      }), ". If you have a question in which you require direct support from HubSpot, please go to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://help.hubspot.com/",
        children: "help.hubspot.com"
      }), " or the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/",
        children: "community forums"
      }), ". HubSpot reserves the right to enforce, interpret, and extend these guidelines for the betterment of the community."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Expected Behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "At HubSpot we don’t have pages of policies or procedures and instead have a three-word policy of Use Good Judgement and have HEART. We encourage our communities to also use good judgment and to share in the following core values of HubSpot:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Be Humble"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nothing is perfect. Be self-aware and always be respectful."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Be Empathetic"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Don’t just be understanding of someone's comments, also act with compassion and respect for all."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Be Adaptable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Be curious, and always be ready to change. Be a life-long learner."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Be Remarkable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the words of the wise Bill S. Preston, Esquire “Be excellent to each other”. Share your knowledge and don’t be afraid to be awesome."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Be Transparent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Be open and honest with others, most importantly, yourself."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To learn more about our values, please see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/34234/the-hubspot-culture-code-creating-a-company-we-love.aspx",
        children: "HubSpot’s Culture Code"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unacceptable Behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following behaviors are considered to be unacceptable and will not be tolerated by any users in the Slack workspace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Threats of Violence"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Threats of violence towards another user is not acceptable. This includes encouraging violence towards a user(s) or posting/threatening to post other peoples personally identifying information (known as “Doxxing”)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Personal Attacks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There will always be times when two parties will have a difference of opinion. While this can be frustrating, this should never turn into a personal attack on someone. Attacking people for their opinions, personal beliefs, ideas, or suggestions is not acceptable. Please be professional and respectful at all times."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Impersonation of HubSpot Employment"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Any users impersonating a HubSpot Employee (or employment by HubSpot) will be banned from the Slack Workspace. This is a zero tolerance rule."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Derogatory/Harmful Language and Harassment"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Harmful language towards a users:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Background"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Family"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Gender Identity or Expression"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sexual Orientation"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Race/Ethnicity"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Physical traits (body size, shape, scars, etc.)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Age"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Native Language"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abilities"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "National Origin"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Location"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Religion"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Political affiliation"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Other personal attributes"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "is not acceptable. Use Good Judgment at all times."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Unwanted Solicitation of Services"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It is unacceptable to message users directly offering your services unless the user has specifically requested this information. This also includes direct messages regarding employment opportunities or freelance projects. We have provided a #jobs channel inside of our Slack workspace where these kinds of messages can be posted to, and a #showcase channel to highlight any HubSpot work you are proud of with the community. These are the only two channels where any form of solicitation or self-promotion may be permitted."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abuse of Voice/Video Calling"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack allows the use of voice/video calling within its platform. It is unacceptable to abuse this feature by calling users directly without their permission. We understand sometimes mistakes happen and buttons are clicked on accident. If this happens, be professional and simply send an apology message to the user."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Undisclosed use of AI in Questions, Responses, or Conversations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you are going to use an AI-tool to generate content that will be used on the Developer Slack, you must clearly disclose the use of such tools in each and every instance of using them. You must also be mindful of the information that you provide an AI-tool and not share any HubSpot confidential or sensitive information."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Channel and User Spamming"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The Developer Slack has many channels tailored toward certain functionality of our platform and many users. When posting your message, please use the appropriate channel for posting your message. It is unacceptable to simply copy/paste your message across multiple channels for added visibility. It is unacceptable to post the same message or similar message frequently within the same channel and/or directly spam users."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Influencing of Unacceptable Behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It’s unacceptable to influence any user(s) in participating in any of the unacceptable behaviors listed above."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use of the HubSpot Developer Slack for Private Team Conversations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By joining the HubSpot Developer Slack, you are making a commitment to contribute to the broader HubSpot Developer Community. No conversation topics are discouraged (that abide by this Code of Conduct), but we ask that you do not use the Developer Slack to conduct private company business through Direct Messaging or other means. If such conduct is discovered, we reserve the right to remove you and your team from the Developer Slack."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Voice/Video Calls + Screen Sharing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack provides the ability for users to use Voice/Video Calling with the added ability to have screen sharing. When using these features, be mindful and respectful of others. Use good judgment when sharing your screen and be mindful of other windows that may be open on your computer containing sensitive or personal information."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Slack “Apps” Usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack has the ability to add Applications to workspace to help expand on the functionality of Slack. We do not allow users to install apps on their own to the Developer Slack Workspace. All app installs to the workspace will be done by HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Keeping Topics Together (Threaded Messages)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "One of the great features of Slack is the ability for threaded comments. To help conversations together and be easily searchable/scannable, we recommend using this feature. It helps keep the main channels clean and groups the topic of conversations into one place."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gifs and Emojis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using gifs inside of the channels or threaded comments, please be mindful of the content/context inside the gif. Likewise, Slack offers the ability for emojis to express emotions in regards to comments. Please be mindful of the emojis you are using when communicating with others."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Surveys and Surveying Developer Slack Users"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The Developer Slack Workspace is intended to be used for conversations around developing on the web and developing on HubSpot. Creating surveys are allowed and we have provided the Polly Slack Application in our workspace for use by anyone."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using Polly, we require the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The poll must be related to development on the web (such as CSS or JS Libraries) or development on HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "\"Show Responses\" must always remain checked."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following is not allowed when surveying individuals on the Developer Slack:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Solicitation of private information including demographic, medical, or other personal information of users.*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Linking to surveys hosted on 3rd party sites (such as google forms, survey monkey forms, or personal websites).*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Directly messagings users asking them to fill in surveys."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "* = HubSpot employees retain the right to survey the HubSpot Developer Slack in service of improvements to the HubSpot product. HubSpot Employees will make an effort to make it clear their survey is an official HubSpot survey if it is hosted elsewhere."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reporting Code of Conduct Violations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To report a violation of this Code of Conduct, or anything that may have made you or others uncomfortable in the HubSpot Developer Community, you may reach out directly to any of our Admins. All current Admins will be indicated in a pinned post in the #general channel."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you prefer to report your violation anonymously or would prefer not to DM an Admin, you ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://form.asana.com/",
        children: "may fill out this form"
      }), " and we will be immediately notified."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}