import I18n from 'I18n';
export const labels = {
  'understand-app-categories': () => I18n.text('developer-docs-shared-components.navigation.labels.understandAppCategories'),
  'create-update-delete-invoices': () => I18n.text('developer-docs-shared-components.navigation.labels.createUpdateDeleteInvoices'),
  'alias-mapping': () => I18n.text('developer-docs-shared-components.navigation.labels.aliasMapping'),
  ios: () => I18n.text('developer-docs-shared-components.navigation.labels.ios'),
  android: () => I18n.text('developer-docs-shared-components.navigation.labels.android'),
  'contacts-timeline-embed': () => I18n.text('developer-docs-shared-components.navigation.labels.contactsTimelineEmbed'),
  'location-based-pages': () => I18n.text('developer-docs-shared-components.navigation.labels.locationBasedPages'),
  overview: () => I18n.text('developer-docs-shared-components.navigation.labels.overview'),
  'hubspot-cms-boilerplate': () => I18n.text('developer-docs-shared-components.navigation.labels.cmsBoilerplate'),
  'default-themes': () => I18n.text('developer-docs-shared-components.navigation.labels.defaultThemes'),
  'child-themes': () => I18n.text('developer-docs-shared-components.navigation.labels.childThemes'),
  'quotes-themes': () => I18n.text('developer-docs-shared-components.navigation.labels.quotesThemes'),
  'responsive-breakpoints': () => I18n.text('developer-docs-shared-components.navigation.labels.responsiveBreakpoints'),
  'html-hubl-templates': () => I18n.text('developer-docs-shared-components.navigation.labels.htmlHublTemplates'),
  blog: () => I18n.text('developer-docs-shared-components.navigation.labels.blog'),
  'drag-and-drop-templates': () => I18n.text('developer-docs-shared-components.navigation.labels.dragAndDropTemplates'),
  'email-template-markup': () => I18n.text('developer-docs-shared-components.navigation.labels.emailTemplateMarkup'),
  quotes: () => I18n.text('developer-docs-shared-components.navigation.labels.quotes'),
  tutorial: () => I18n.text('developer-docs-shared-components.navigation.labels.tutorial'),
  modules: () => I18n.text('developer-docs-shared-components.navigation.labels.modules'),
  'hide-modules-and-sections': () => I18n.text('developer-docs-shared-components.navigation.labels.hideModulesAndSections'),
  'build-modules-and-partials-with-react': () => I18n.text('developer-docs-shared-components.navigation.labels.buildModulesAndPartialsWithReact'),
  // proprietary term, not translated
  hubdb: () => 'HubDB',
  'best-practices': () => I18n.text('developer-docs-shared-components.navigation.labels.bestPractices'),
  'brand-and-settings-inheritance': () => I18n.text('developer-docs-shared-components.navigation.labels.brandAndSettingsInheritance'),
  multilevel: () => I18n.text('developer-docs-shared-components.navigation.labels.multilevel'),
  video: () => I18n.text('developer-docs-shared-components.navigation.labels.video'),
  'dynamic-team-member-page': () => I18n.text('developer-docs-shared-components.navigation.labels.dynamicTeamMemberPage'),
  'query-hubspot-data-using-graphql': () => I18n.text('developer-docs-shared-components.navigation.labels.queryHubspotDataUsingGraphql'),
  'build-a-recruiting-agency-using-graphql': () => I18n.text('developer-docs-shared-components.navigation.labels.buildARecruitingAgencyUsingGraphql'),
  'use-graphql-data-in-your-website-pages': () => I18n.text('developer-docs-shared-components.navigation.labels.useGraphqlDataInPages'),
  'getting-started-with-serverless-functions': () => I18n.text('developer-docs-shared-components.navigation.labels.gettingStartedWithServerlessFunctions'),
  'custom-event-definitions': () => I18n.text('developer-docs-shared-components.navigation.labels.customEventDefinitions'),
  reporting: () => I18n.text('developer-docs-shared-components.navigation.labels.reporting'),
  'event-analytics': () => I18n.text('developer-docs-shared-components.navigation.labels.eventAnalytics'),
  'custom-event-completions': () => I18n.text('developer-docs-shared-components.navigation.labels.customEventCompletions'),
  'email-analytics': () => I18n.text('developer-docs-shared-components.navigation.labels.emailAnalytics'),
  'custom-workflow-actions': () => I18n.text('developer-docs-shared-components.navigation.labels.customWorkflowActions'),
  sequences: () => I18n.text('developer-docs-shared-components.navigation.labels.sequences'),
  'workflows-v4': () => I18n.text('developer-docs-shared-components.navigation.labels.workflowsV4'),
  'create-manage-workflows': () => I18n.text('developer-docs-shared-components.navigation.labels.createManageWorkflows'),
  'blog-authors': () => I18n.text('developer-docs-shared-components.navigation.labels.blogAuthors'),
  'blog-posts': () => I18n.text('developer-docs-shared-components.navigation.labels.blogPosts'),
  'blog-tags': () => I18n.text('developer-docs-shared-components.navigation.labels.blogTags'),
  'content-audit': () => I18n.text('developer-docs-shared-components.navigation.labels.contentAudit'),
  domains: () => I18n.text('developer-docs-shared-components.navigation.labels.domains'),
  'media-bridge': () => I18n.text('developer-docs-shared-components.navigation.labels.mediaBridge'),
  pages: () => I18n.text('developer-docs-shared-components.navigation.labels.pages'),
  performance: () => I18n.text('developer-docs-shared-components.navigation.labels.performance'),
  'site-search': () => I18n.text('developer-docs-shared-components.navigation.labels.siteSearch'),
  'source-code': () => I18n.text('developer-docs-shared-components.navigation.labels.sourceCode'),
  'url-redirects': () => I18n.text('developer-docs-shared-components.navigation.labels.urlRedirects'),
  'google-consent-mode': () => I18n.text('developer-docs-shared-components.navigation.labels.implementGoogleConsentMode'),
  'tracking-code': () => I18n.text('developer-docs-shared-components.navigation.labels.trackingCode'),
  'cookie-banner-api': () => I18n.text('developer-docs-shared-components.navigation.labels.cookieBannerApi'),
  'custom-code-actions': () => I18n.text('developer-docs-shared-components.navigation.labels.customCodeActions'),
  'blog-details': () => I18n.text('developer-docs-shared-components.navigation.labels.blogDetails'),
  'blog-comments': () => I18n.text('developer-docs-shared-components.navigation.labels.blogComments'),
  'blog-topics': () => I18n.text('developer-docs-shared-components.navigation.labels.blogTopics'),
  layouts: () => I18n.text('developer-docs-shared-components.navigation.labels.layouts'),
  templates: () => I18n.text('developer-docs-shared-components.navigation.labels.templates'),
  'chat-widget-sdk': () => I18n.text('developer-docs-shared-components.navigation.labels.chatWidgetSdk'),
  'inbox-and-messages': () => I18n.text('developer-docs-shared-components.navigation.labels.inboxAndMessages'),
  'create-custom-channels': () => I18n.text('developer-docs-shared-components.navigation.labels.createCustomChannels'),
  'visitor-identification': () => I18n.text('developer-docs-shared-components.navigation.labels.visitorIdentification'),
  'association-details': () => I18n.text('developer-docs-shared-components.navigation.labels.associationDetails'),
  'associations-schema': () => I18n.text('developer-docs-shared-components.navigation.labels.associationsSchema'),
  carts: () => I18n.text('developer-docs-shared-components.navigation.labels.carts'),
  discounts: () => I18n.text('developer-docs-shared-components.navigation.labels.discounts'),
  fees: () => I18n.text('developer-docs-shared-components.navigation.labels.fees'),
  invoices: () => I18n.text('developer-docs-shared-components.navigation.labels.invoices'),
  orders: () => I18n.text('developer-docs-shared-components.navigation.labels.orders'),
  payments: () => I18n.text('developer-docs-shared-components.navigation.labels.payments'),
  subscriptions: () => I18n.text('developer-docs-shared-components.navigation.labels.subscriptions'),
  taxes: () => I18n.text('developer-docs-shared-components.navigation.labels.taxes'),
  'engagement-details': () => I18n.text('developer-docs-shared-components.navigation.labels.engagementDetails'),
  calls: () => I18n.text('developer-docs-shared-components.navigation.labels.calls'),
  communications: () => I18n.text('developer-docs-shared-components.navigation.labels.communications'),
  email: () => I18n.text('developer-docs-shared-components.navigation.labels.email'),
  meetings: () => I18n.text('developer-docs-shared-components.navigation.labels.meetings'),
  notes: () => I18n.text('developer-docs-shared-components.navigation.labels.notes'),
  'postal-mail': () => I18n.text('developer-docs-shared-components.navigation.labels.postalMail'),
  tasks: () => I18n.text('developer-docs-shared-components.navigation.labels.tasks'),
  'calling-sdk': () => I18n.text('developer-docs-shared-components.navigation.labels.callingSdk'),
  'crm-cards': () => I18n.text('developer-docs-shared-components.navigation.labels.crmCards'),
  extensions: () => I18n.text('developer-docs-shared-components.navigation.labels.extensions'),
  'extension-details': () => I18n.text('developer-docs-shared-components.navigation.labels.extensionDetails'),
  timeline: () => I18n.text('developer-docs-shared-components.navigation.labels.timelineEvents'),
  'video-conferencing': () => I18n.text('developer-docs-shared-components.navigation.labels.videoConferencing'),
  exports: () => I18n.text('developer-docs-shared-components.navigation.labels.exportCrmData'),
  imports: () => I18n.text('developer-docs-shared-components.navigation.labels.importCrmData'),
  lists: () => I18n.text('developer-docs-shared-components.navigation.labels.lists'),
  'lists-filters': () => I18n.text('developer-docs-shared-components.navigation.labels.listFilters'),
  companies: () => I18n.text('developer-docs-shared-components.navigation.labels.companies'),
  contacts: () => I18n.text('developer-docs-shared-components.navigation.labels.contacts'),
  'custom-objects': () => I18n.text('developer-docs-shared-components.navigation.labels.customObjects'),
  deals: () => I18n.text('developer-docs-shared-components.navigation.labels.deals'),
  'deal-splits': () => I18n.text('developer-docs-shared-components.navigation.labels.dealSplits'),
  'feedback-submissions': () => I18n.text('developer-docs-shared-components.navigation.labels.feedbackSubmissions'),
  goals: () => I18n.text('developer-docs-shared-components.navigation.labels.goals'),
  'line-items': () => I18n.text('developer-docs-shared-components.navigation.labels.lineItems'),
  products: () => I18n.text('developer-docs-shared-components.navigation.labels.products'),
  tickets: () => I18n.text('developer-docs-shared-components.navigation.labels.tickets'),
  schemas: () => I18n.text('developer-docs-shared-components.navigation.labels.schemas'),
  owners: () => I18n.text('developer-docs-shared-components.navigation.labels.owners'),
  pipelines: () => I18n.text('developer-docs-shared-components.navigation.labels.pipelines'),
  embed: () => I18n.text('developer-docs-shared-components.navigation.labels.embed'),
  properties: () => I18n.text('developer-docs-shared-components.navigation.labels.properties'),
  files: () => I18n.text('developer-docs-shared-components.navigation.labels.files'),
  forms: () => I18n.text('developer-docs-shared-components.navigation.labels.forms'),
  'marketing-events': () => I18n.text('developer-docs-shared-components.navigation.labels.marketingEvents'),
  'subscriptions-preferences': () => I18n.text('developer-docs-shared-components.navigation.labels.subscriptionsPreferences'),
  'transactional-emails': () => I18n.text('developer-docs-shared-components.navigation.labels.transactionalEmails'),
  'marketing-emails': () => I18n.text('developer-docs-shared-components.navigation.labels.marketingEmails'),
  'single-send-api': () => I18n.text('developer-docs-shared-components.navigation.labels.singleSendApi'),
  oauth: () => I18n.text('developer-docs-shared-components.navigation.labels.oauth'),
  'oauth-tokens': () => I18n.text('developer-docs-shared-components.navigation.labels.oauthTokens'),
  'account-activity-api': () => I18n.text('developer-docs-shared-components.navigation.labels.accountActivityApi'),
  'account-information-api': () => I18n.text('developer-docs-shared-components.navigation.labels.accountInformationApi'),
  'business-units-api': () => I18n.text('developer-docs-shared-components.navigation.labels.businessUnitsApi'),
  currencies: () => I18n.text('developer-docs-shared-components.navigation.labels.currencies'),
  'user-provisioning': () => I18n.text('developer-docs-shared-components.navigation.labels.userProvisioning'),
  'user-details': () => I18n.text('developer-docs-shared-components.navigation.labels.userDetails'),
  webhooks: () => I18n.text('developer-docs-shared-components.navigation.labels.webhooks'),
  deprecated: () => I18n.text('developer-docs-shared-components.navigation.labels.deprecated'),
  'developer-slack': () => I18n.text('developer-docs-shared-components.navigation.labels.developerSlack'),
  'code-of-conduct': () => I18n.text('developer-docs-shared-components.navigation.labels.codeOfConduct'),
  getSetUp: () => I18n.text('developer-docs-shared-components.navigation.labels.getSetUp'),
  'what-to-build': () => I18n.text('developer-docs-shared-components.navigation.labels.whatToBuild'),
  'tools-to-help-you-build': () => I18n.text('developer-docs-shared-components.navigation.labels.toolsToHelpYouBuild'),
  'account-types': () => I18n.text('developer-docs-shared-components.navigation.labels.accountTypes'),
  features: () => I18n.text('developer-docs-shared-components.navigation.labels.features'),
  'local-development-cli': () => I18n.text('developer-docs-shared-components.navigation.labels.localDevelopmentCli'),
  'personal-access-key': () => I18n.text('developer-docs-shared-components.navigation.labels.personalAccessKey'),
  'design-manager': () => I18n.text('developer-docs-shared-components.navigation.labels.designManager'),
  quickstart: () => I18n.text('developer-docs-shared-components.navigation.labels.quickstart'),
  'getting-started-with-local-development': () => I18n.text('developer-docs-shared-components.navigation.labels.localDevelopmentWithHubspotCli'),
  'install-and-use-hubspot-code-extension': () => I18n.text('developer-docs-shared-components.navigation.labels.vsCodeExtension'),
  'github-integration': () => I18n.text('developer-docs-shared-components.navigation.labels.githubIntegration'),
  'js-frameworks': () => I18n.text('developer-docs-shared-components.navigation.labels.jsFrameworks'),
  'creating-an-efficient-development-workflow': () => I18n.text('developer-docs-shared-components.navigation.labels.creatingAnEfficientDevelopmentWorkflow'),
  'file-manager': () => I18n.text('developer-docs-shared-components.navigation.labels.fileManager'),
  'lazy-loading': () => I18n.text('developer-docs-shared-components.navigation.labels.lazyLoading'),
  prerendering: () => I18n.text('developer-docs-shared-components.navigation.labels.prerendering'),
  speed: () => I18n.text('developer-docs-shared-components.navigation.labels.speed'),
  'default-email-modules': () => I18n.text('developer-docs-shared-components.navigation.labels.defaultEmailModules'),
  sections: () => I18n.text('developer-docs-shared-components.navigation.labels.sections'),
  'crm-objects': () => I18n.text('developer-docs-shared-components.navigation.labels.crmObjects'),
  'emails-with-programmable-content': () => I18n.text('developer-docs-shared-components.navigation.labels.emailsWithProgrammableContent'),
  'global-content': () => I18n.text('developer-docs-shared-components.navigation.labels.globalContent'),
  'menus-and-navigation': () => I18n.text('developer-docs-shared-components.navigation.labels.menusAndNavigation'),
  'website-settings': () => I18n.text('developer-docs-shared-components.navigation.labels.websiteSettings'),
  'content-staging': () => I18n.text('developer-docs-shared-components.navigation.labels.contentStaging'),
  'content-search': () => I18n.text('developer-docs-shared-components.navigation.labels.contentSearch'),
  'multi-language-content': () => I18n.text('developer-docs-shared-components.navigation.labels.multiLanguageContent'),
  sso: () => I18n.text('developer-docs-shared-components.navigation.labels.membershipSso'),
  social: () => {
    return I18n.text('developer-docs-shared-components.navigation.labels.socialLoginForMembership');
  },
  accessibility: () => I18n.text('developer-docs-shared-components.navigation.labels.accessibility'),
  troubleshooting: () => I18n.text('developer-docs-shared-components.navigation.labels.troubleshooting'),
  'code-alerts': () => I18n.text('developer-docs-shared-components.navigation.labels.codeAlerts'),
  'template-guidelines': () => I18n.text('developer-docs-shared-components.navigation.labels.templateMarketplaceGuidelines'),
  'template-policies': () => I18n.text('developer-docs-shared-components.navigation.labels.templateMarketplacePolicies'),
  'general-requirements': () => I18n.text('developer-docs-shared-components.navigation.labels.generalListingRequirements'),
  'theme-requirements': () => I18n.text('developer-docs-shared-components.navigation.labels.themeRequirements'),
  'module-requirements': () => I18n.text('developer-docs-shared-components.navigation.labels.moduleRequirements'),
  'listing-templates': () => I18n.text('developer-docs-shared-components.navigation.labels.listingTemplatesInTemplateMarketplace'),
  'add-theme-features-to-existing-sites': () => I18n.text('developer-docs-shared-components.navigation.labels.addThemeFeaturesToExistingSites'),
  'upgrade-jquery': () => I18n.text('developer-docs-shared-components.navigation.labels.upgradeJquery'),
  'convert-wordpress-theme-to-hubspot': () => I18n.text('developer-docs-shared-components.navigation.labels.convertWordpressThemeToHubspot'),
  'manage-ui-extension-layout': () => I18n.text('developer-docs-shared-components.navigation.labels.manageLayout'),
  /** Hardcoded UIE component name */
  accordion: () => 'Accordion',
  /** Hardcoded UIE component name */
  alert: () => 'Alert',
  /** Hardcoded UIE component name */
  box: () => 'Box',
  /** Hardcoded UIE component name */
  button: () => 'Button',
  /** Hardcoded UIE component name */
  'button-row': () => 'ButtonRow',
  charts: () => I18n.text('developer-docs-shared-components.navigation.labels.charts'),
  /** Hardcoded UIE component name */
  'bar-chart': () => 'BarChart',
  /** Hardcoded UIE component name */
  'line-chart': () => 'LineChart',
  /** Hardcoded UIE component name */
  checkbox: () => 'Checkbox',
  /** Hardcoded UIE component name */
  'date-input': () => 'DateInput',
  /** Hardcoded UIE component name */
  'description-list': () => 'DescriptionList',
  /** Hardcoded UIE component name */
  divider: () => 'Divider',
  /** Hardcoded UIE component name */
  dropdown: () => 'Dropdown',
  /** Hardcoded UIE component name */
  'empty-state': () => 'EmptyState',
  /** Hardcoded UIE component name */
  'error-state': () => 'ErrorState',
  /** Hardcoded UIE component name */
  flex: () => 'Flex',
  /** Hardcoded UIE component name */
  form: () => 'Form',
  /** Hardcoded UIE component name */
  heading: () => 'Heading',
  /** Hardcoded UIE component name */
  icon: () => 'Icon',
  /** Hardcoded UIE component name */
  image: () => 'Image',
  /** Hardcoded UIE component name */
  input: () => 'Input',
  /** Hardcoded UIE component name */
  link: () => 'Link',
  /** Hardcoded UIE component name */
  list: () => 'List',
  /** Hardcoded UIE component name */
  'loading-button': () => 'LoadingButton',
  /** Hardcoded UIE component name */
  'loading-spinner': () => 'LoadingSpinner',
  /** Hardcoded UIE component name */
  'multi-select': () => 'MultiSelect',
  /** Hardcoded UIE component name */
  modal: () => 'Modal',
  /** Hardcoded UIE component name */
  'number-input': () => 'NumberInput',
  /** Hardcoded UIE component name */
  panel: () => 'Panel',
  /** Hardcoded UIE component name */
  'progress-bar': () => 'ProgressBar',
  /** Hardcoded UIE component name */
  'radio-button': () => 'RadioButton',
  /** Hardcoded UIE component name */
  select: () => 'Select',
  /** Hardcoded UIE component name */
  statistics: () => 'Statistics',
  /** Hardcoded UIE component name */
  'status-tag': () => 'StatusTag',
  /** Hardcoded UIE component name */
  'step-indicator': () => 'StepIndicator',
  /** Hardcoded UIE component name */
  'stepper-input': () => 'StepperInput',
  /** Hardcoded UIE component name */
  table: () => 'Table',
  /** Hardcoded UIE component name */
  tag: () => 'Tag',
  /** Hardcoded UIE component name */
  text: () => 'Text',
  /** Hardcoded UIE component name */
  'text-area': () => 'TextArea',
  /** Hardcoded UIE component name */
  tile: () => 'Tile',
  /** Hardcoded UIE component name */
  toggle: () => 'Toggle',
  /** Hardcoded UIE component name */
  'toggle-group': () => 'ToggleGroup',
  /** Hardcoded UIE component name */
  'crm-action-button': () => 'CrmActionButton',
  /** Hardcoded UIE component name */
  'crm-action-link': () => 'CrmActionLink',
  /** Hardcoded UIE component name */
  'crm-card-actions': () => 'CrmCardActions',
  /** Hardcoded UIE component name */
  'crm-association-pivot': () => 'CrmAssociationPivot',
  /** Hardcoded UIE component name */
  'crm-association-property-list': () => 'CrmAssociationPropertyList',
  /** Hardcoded UIE component name */
  'crm-association-stage-tracker': () => 'CrmAssociationStageTracker',
  /** Hardcoded UIE component name */
  'crm-association-table': () => 'CrmAssociationTable',
  /** Hardcoded UIE component name */
  'crm-data-highlight': () => 'CrmDataHighlight',
  /** Hardcoded UIE component name */
  'crm-property-list': () => 'CrmPropertyList',
  /** Hardcoded UIE component name */
  'crm-report': () => 'CrmReport',
  /** Hardcoded UIE component name */
  'crm-stage-tracker': () => 'CrmStageTracker',
  /** Hardcoded UIE component name */
  'crm-statistics': () => 'CrmStatistics',
  'developer-projects-setup': () => I18n.text('developer-docs-shared-components.navigation.labels.developerProjectsSetup'),
  setup: () => I18n.text('developer-docs-shared-components.navigation.labels.setup'),
  'link-a-github-repository-to-a-project': () => I18n.text('developer-docs-shared-components.navigation.labels.linkAGithubRepositoryToAProject'),
  'create-a-project': () => I18n.text('developer-docs-shared-components.navigation.labels.createAProject'),
  'create-private-apps-with-projects': () => I18n.text('developer-docs-shared-components.navigation.labels.createPrivateAppsWithProjects'),
  'creating-private-apps': () => I18n.text('developer-docs-shared-components.navigation.labels.creatingPrivateApps'),
  create: () => I18n.text('developer-docs-shared-components.navigation.labels.create'),
  sdk: () => I18n.text('developer-docs-shared-components.navigation.labels.sdk'),
  'deals-summary-sample-project': () => I18n.text('developer-docs-shared-components.navigation.labels.dealsSummarySampleProject'),
  'serverless-functions': () => I18n.text('developer-docs-shared-components.navigation.labels.serverlessFunctions'),
  'project-cli-commands': () => I18n.text('developer-docs-shared-components.navigation.labels.projectCliCommands'),
  'platform-versioning': () => I18n.text('developer-docs-shared-components.navigation.labels.platformVersioning'),
  'code-snippets': () => I18n.text('developer-docs-shared-components.navigation.labels.codeSnippets'),
  associations: () => I18n.text('developer-docs-shared-components.navigation.labels.associations'),
  search: () => I18n.text('developer-docs-shared-components.navigation.labels.search'),
  'understanding-the-crm': () => I18n.text('developer-docs-shared-components.navigation.labels.understandingTheCrm'),
  tokens: () => I18n.text('developer-docs-shared-components.navigation.labels.oauthTokens'),
  'intro-to-auth': () => I18n.text('developer-docs-shared-components.navigation.labels.introToAuth'),
  'validating-requests': () => I18n.text('developer-docs-shared-components.navigation.labels.validatingRequests'),
  'working-with-oauth': () => I18n.text('developer-docs-shared-components.navigation.labels.workingWithOauth'),
  'oauth-quickstart-guide': () => I18n.text('developer-docs-shared-components.navigation.labels.oauthQuickstartGuide'),
  'create-and-edit-webhook-subscriptions-in-private-apps': () => I18n.text('developer-docs-shared-components.navigation.labels.createWebhookSubscriptionsInPrivateApps'),
  'migrate-an-api-key-integration-to-a-private-app': () => I18n.text('developer-docs-shared-components.navigation.labels.migrateToPrivateAppFromApiKey'),
  'create-an-app-settings-page': () => I18n.text('developer-docs-shared-components.navigation.labels.createSettingsPageForPublicApp'),
  'create-generic-webhook-subscriptions': () => I18n.text('developer-docs-shared-components.navigation.labels.createGenericWebhookSubscriptions'),
  'import-users-into-any-hubspot-account': () => I18n.text('developer-docs-shared-components.navigation.labels.importUsersIntoHubSpotAccount'),
  'share-hubspot-reports-with-your-app': () => I18n.text('developer-docs-shared-components.navigation.labels.shareHubSpotReportsWithApp'),
  'recordings-and-transcriptions': () => I18n.text('developer-docs-shared-components.navigation.labels.recordingsAndTranscripts'),
  'receive-incoming-calls': () => I18n.text('developer-docs-shared-components.navigation.labels.receiveIncomingCalls'),
  'usage-details': () => I18n.text('developer-docs-shared-components.navigation.labels.usageGuidelinesAndRateLimits'),
  'breaking-change-definition': () => I18n.text('developer-docs-shared-components.navigation.labels.breakingChangeGuidelines'),
  'listing-your-app': () => I18n.text('developer-docs-shared-components.navigation.labels.listingYourApp'),
  'app-marketplace-listing-requirements': () => I18n.text('developer-docs-shared-components.navigation.labels.appListingRequirements'),
  'applying-for-app-certification': () => I18n.text('developer-docs-shared-components.navigation.labels.applyingForAppCertification'),
  'certification-requirements': () => I18n.text('developer-docs-shared-components.navigation.labels.appCertificationRequirements'),
  'measuring-app-performance': () => I18n.text('developer-docs-shared-components.navigation.labels.measuringAppPerformance'),
  'testing-credentials': () => I18n.text('developer-docs-shared-components.navigation.labels.provideTestingCredentialsForYourApp'),
  configuration: () => I18n.text('developer-docs-shared-components.navigation.labels.configuringAModule'),
  'using-modules-in-templates': () => I18n.text('developer-docs-shared-components.navigation.labels.usingModulesInTemplates'),
  'default-modules': () => I18n.text('developer-docs-shared-components.navigation.labels.defaultWebModules'),
  // HubL param that only exists in English, not translated
  'export-to-template-context': () => 'export_to_template_context',
  'default-module-versioning': () => I18n.text('developer-docs-shared-components.navigation.labels.defaultModuleVersioning'),
  'operators-and-expression-tests': () => I18n.text('developer-docs-shared-components.navigation.labels.operatorsAndExpressionTests'),
  filters: () => I18n.text('developer-docs-shared-components.navigation.labels.filters'),
  functions: () => I18n.text('developer-docs-shared-components.navigation.labels.functions'),
  loops: () => I18n.text('developer-docs-shared-components.navigation.labels.loops'),
  'standard-tags': () => I18n.text('developer-docs-shared-components.navigation.labels.standardTags'),
  'dnd-areas': () => I18n.text('developer-docs-shared-components.navigation.labels.dragAndDropAreas'),
  variables: () => I18n.text('developer-docs-shared-components.navigation.labels.variables'),
  'variables-macros-syntax': () => I18n.text('developer-docs-shared-components.navigation.labels.variablesAndMacrosSyntax'),
  'if-statements': () => I18n.text('developer-docs-shared-components.navigation.labels.ifStatements'),
  'quote-variables': () => I18n.text('developer-docs-shared-components.navigation.labels.quoteVariables'),
  'module-theme-fields': () => I18n.text('developer-docs-shared-components.navigation.labels.moduleThemeFields'),
  'rich-text-editor': () => I18n.text('developer-docs-shared-components.navigation.labels.richTextEditor'),
  hideModulesAndSectionsFromEditor: () => I18n.text('developer-docs-shared-components.navigation.labels.hideModulesAndSectionsFromEditor'),
  overviewOfCrmDevelopmentTools: () => I18n.text('developer-docs-shared-components.navigation.labels.overviewOfCrmDevelopmentTools'),
  apiDeveloperGuidesAndResources: () => I18n.text('developer-docs-shared-components.navigation.labels.apiDeveloperGuidesAndResources'),
  webhooksAPI: () => I18n.text('developer-docs-shared-components.navigation.labels.webhooksAPI'),
  moduleFiles: () => I18n.text('developer-docs-shared-components.navigation.labels.moduleFiles'),
  serverlessFunctionsReference: () => I18n.text('developer-docs-shared-components.navigation.labels.serverlessFunctionsReference'),
  'social-media': () => I18n.text('developer-docs-shared-components.navigation.labels.socialMedia'),
  'third-party-calling': () => I18n.text('developer-docs-shared-components.navigation.labels.thirdPartyCalling'),
  leads: () => I18n.text('developer-docs-shared-components.navigation.labels.leads'),
  'reverse-proxy-support': () => I18n.text('developer-docs-shared-components.navigation.labels.reverseProxySupport'),
  'provide-a-good-editor-experience': () => I18n.text('developer-docs-shared-components.navigation.labels.provideAGoodEditorExperience'),
  'custom-email-template': () => I18n.text('developer-docs-shared-components.navigation.labels.dragAndDropAreasInACustomEmailTemplate'),
  'custom-fonts': () => I18n.text('developer-docs-shared-components.navigation.labels.customFonts'),
  'write-fields-using-javascript': () => I18n.text('developer-docs-shared-components.navigation.labels.writeFieldsUsingJavascript'),
  'error-handling': () => I18n.text('developer-docs-shared-components.navigation.labels.errorHandling'),
  'sensitive-data': () => I18n.text('developer-docs-shared-components.navigation.labels.sensitiveData'),
  scopes: () => I18n.text('developer-docs-shared-components.navigation.labels.scopes'),
  'calls-to-action-sdk': () => I18n.text('developer-docs-shared-components.navigation.labels.callsToActionSdk'),
  'getting-started': () => I18n.text('developer-docs-shared-components.navigation.labels.gettingStarted'),
  'local-development': () => I18n.text('developer-docs-shared-components.navigation.labels.localDevelopment'),
  'creating-public-apps': () => I18n.text('developer-docs-shared-components.navigation.labels.creatingPublicApps'),
  'fetching-data': () => I18n.text('developer-docs-shared-components.navigation.labels.fetchingData'),
  'migrate-a-public-app-to-projects': () => I18n.text('developer-docs-shared-components.navigation.labels.migrateApublicAppToProjects'),
  'developer-projects': () => I18n.text('developer-docs-shared-components.navigation.labels.developerProjects'),
  'deals-summary-tutorial': () => I18n.text('developer-docs-shared-components.navigation.labels.dealsSummaryTutorial'),
  'feature-flags': () => I18n.text('developer-docs-shared-components.navigation.labels.featureFlags'),
  'google-sign-in': () => I18n.text('developer-docs-shared-components.navigation.labels.googleSignIn'),
  'microsoft-sign-in': () => I18n.text('developer-docs-shared-components.navigation.labels.microsoftSignIn'),
  'related-blog-posts': () => I18n.text('developer-docs-shared-components.navigation.labels.relatedBlogPosts'),
  campaigns: () => I18n.text('developer-docs-shared-components.navigation.labels.campaigns'),
  'figma-design-kit': () => I18n.text('developer-docs-shared-components.navigation.labels.figmaDesignKit'),
  'associations-v3': () => I18n.text('developer-docs-shared-components.navigation.labels.associationsV3'),
  'associations-v4': () => I18n.text('developer-docs-shared-components.navigation.labels.associationsV4')
};