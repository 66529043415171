"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107554;
const slug = exports.slug = 'guides/apps/authentication/validating-requests';
const title = exports.title = 'Webhooks | Validar solicitações';
const name = exports.name = 'vNext DP de documentos - Validar solicitações do HubSpot';
const metaDescription = exports.metaDescription = 'Uma visão geral das solicitações de validação com origem da HubSpot para uma integração. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "validar-solicita%C3%A7%C3%B5es-usando-a-assinatura-de-solicita%C3%A7%C3%A3o-v1",
  "label": "Validar solicitações usando a assinatura de solicitação v1",
  "parent": null
}, {
  "depth": 0,
  "id": "exemplos-de-assinatura-de-solicita%C3%A7%C3%A3o-v1",
  "label": "Exemplos de assinatura de solicitação v1:",
  "parent": null
}, {
  "depth": 0,
  "id": "validar-solicita%C3%A7%C3%B5es-usando-a-assinatura-de-solicita%C3%A7%C3%A3o-v2",
  "label": "Validar solicitações usando a assinatura de solicitação v2",
  "parent": null
}, {
  "depth": 1,
  "id": "exemplo-de-uma-solicita%C3%A7%C3%A3o-get",
  "label": "Exemplo de uma solicitação GET",
  "parent": "validar-solicita%C3%A7%C3%B5es-usando-a-assinatura-de-solicita%C3%A7%C3%A3o-v2"
}, {
  "depth": 1,
  "id": "exemplo-de-uma-solicita%C3%A7%C3%A3o-post",
  "label": "Exemplo de uma solicitação POST",
  "parent": "validar-solicita%C3%A7%C3%B5es-usando-a-assinatura-de-solicita%C3%A7%C3%A3o-v2"
}, {
  "depth": 0,
  "id": "validar-assinaturas-de-solicita%C3%A7%C3%A3o-v3",
  "label": "Validar assinaturas de solicitação v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      h2: "h2",
      strong: "strong",
      pre: "pre",
      br: "br",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Validar solicitações da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para garantir que as solicitações que sua integração esteja recebendo da HubSpot venham realmente da HubSpot, vários cabeçalhos são preenchidos na solicitação. Você pode usar esses cabeçalhos, juntamente com os campos da solicitação recebida, para verificar a assinatura da solicitação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O método usado para verificar a assinatura depende da versão da assinatura."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para validar uma solicitação usando a versão mais recente da assinatura do HubSpot, use o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-V3"
        }), " e siga as instruções", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests#validate-the-v3-request-signature",
          children: " associadas para validar a versão v3 da assinatura"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para compatibilidade com versões anteriores, as solicitações do HubSpot também incluem versões mais antigas da assinatura. Para validar uma versão mais antiga da assinatura, verifique o cabeçalho", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-Version"
        }), " e siga as instruções associadas abaixo com base em se a versão é ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v1"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v2"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nas instruções abaixo, saiba como derivar um valor de hash do segredo do cliente do seu aplicativo e dos campos de uma solicitação recebida. Depois de calcular o valor do hash, você o comparará à assinatura. Se os dois forem iguais, a solicitação passará na validação. Caso contrário, a solicitação pode ter sido alterada em trânsito ou alguém pode estar falsificando solicitações para seu ponto de extremidade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validar solicitações usando a assinatura de solicitação v1"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se seu aplicativo estiver inscrito em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/webhooks/overview",
        children: "eventos de objetos do CRM por meio da API do webhooks"
      }), ", as solicitações do HubSpot serão enviadas com o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " definido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v1"
      }), ". O cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " será um hash SHA-256 gerado usando o segredo do cliente do seu aplicativo combinado com detalhes da solicitação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para verificar a versão da assinatura, siga as seguintes etapas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crie uma string que concatene o seguinte: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (se presente)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crie um hash SHA-256 da string resultante."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Compare o valor de hash com o valor do cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se forem iguais, essa solicitação passou na validação."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se esses valores forem diferentes, essa solicitação pode ter sido alterada em trânsito ou alguém pode ter falsificado solicitações no seu ponto de extremidade."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de uma solicitação com um corpo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Client secret : yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\n// Request body: [\n{\"eventId\":1,\"subscriptionId\":12345,\"\nportalId\":62515\",\noccurredAt\":1564113600000\",\nsubscriptionType\":\"contact.creation\",\n\"attemptNumber\":0,\n\"objectId\":123,\n\"changeSource\":\"CRM\",\n\"changeFlag\":\"NEW\",\n\"appId\":54321}\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemplos de assinatura de solicitação v1:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n>>> import hashlib\n\n>>> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n>>> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> source_string = client_secret + request_body\n>>> source_string\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> hashlib.sha256(source_string).hexdigest()\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\nirb(main):003:0> require 'digest'\n=> true\nirb(main):004:0> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\"\nirb(main):005:0> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n=> \"[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):006:0> source_string = client_secret + request_body\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):007:0> Digest::SHA256.hexdigest source_string\n=> \"232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de\"\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n> const crypto = require('crypto')\nundefined\n> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n'[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> source_string = client_secret + request_body\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> hash = crypto.createHash('sha256').update(source_string).digest('hex')\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O hash resultante seria:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validar solicitações usando a assinatura de solicitação v2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se seu aplicativo gerencia dados de uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "ação de webhook em um fluxo de trabalho"
      }), " ou se você estiver retornando dados para um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "cartão de CRM personalizado"
      }), ", a solicitação do HubSpot será enviada com o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " definido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v2"
      }), ". O cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " será um hash SHA-256 gerado usando o segredo do cliente do seu aplicativo combinado com detalhes da solicitação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para verificar essa assinatura, execute as seguintes etapas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crie uma string que concatene o seguinte: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http method"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "URI"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (se presente)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crie um hash SHA-256 da string resultante."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Compare o valor do hash à assinatura.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se forem iguais, essa solicitação passou na validação."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se esses valores forem diferentes, essa solicitação pode ter sido alterada em trânsito ou alguém pode ter falsificado solicitações no seu ponto de extremidade."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observações:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A URI usada para construir a string de origem deve corresponder exatamente à solicitação original, incluindo o protocolo. Se você tiver problemas para validar a assinatura, verifique se todos os parâmetros de consulta estão na mesma ordem em que foram listados na solicitação original."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A string de origem deve ser de codificado em UTF-8 antes de calcular o hash SHA-256."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemplo de uma solicitação GET"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", você precisaria do segredo do cliente do seu aplicativo e de campos específicos dos metadados da sua solicitação. Esses campos estão listados abaixo com valores de espaço reservado incluídos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Segredo do cliente:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Método HTTP:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Corpo da solicitação:", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"\""
          })]
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A string concatenada resultante seria: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyGEThttps://www.example.com/webhook_uri"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de calcular um hash SHA-256 da string concatenada acima, a assinatura resultante que você espera corresponder à do cabeçalho seria: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eee2dddcc73c94d699f5e395f4b9d454a069a6855fbfa152e91e88823087200e"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemplo de uma solicitação POST"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", você precisaria do segredo do cliente do seu aplicativo, campos específicos dos metadados da sua solicitação e uma representação de string do corpo da solicitação (por exemplo, usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "JSON.stringify(request.body)"
      }), " para um serviço Node.js). Esses campos estão listados abaixo com valores de espaço reservado incluídos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Segredo do cliente:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Método HTTP:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Corpo da solicitação:"
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "{\"example_field\":\"example_value\"}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A string concatenada resultante seria: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"example_value\"}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de calcular um hash SHA-256 da string concatenada acima, a assinatura resultante que você espera corresponder à do cabeçalho seria:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "9569219f8ba981ffa6f6f16aa0f48637d35d728c7e4d93d0d52efaa512af7900"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Após [SHA-ing] a assinatura, você poderá comparar a assinatura esperada resultante com a fornecida no cabeçalho x-hubspot-signature da solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O snippet de código Node.js abaixo especifica como você pode incorporar a validação de uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " se você estava executando um servidor Express para lidar com solicitações de entrada. Lembre-se de que o bloco de código abaixo é um exemplo e omite certas dependências que talvez sejam necessárias para executar um serviço Express completo. Confirme se você está executando as bibliotecas estáveis e seguras mais recentes ao implementar a validação de solicitação para seu serviço específico."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Introduce any dependencies. Only several dependencies related to this example are included below:\nconst express = require('express');\nconst bodyParser = require('body-parser');\nconst crypto = require('crypto');\nconst app = express();\n\n// Add any custom handling or setup code for your Node.js service here.\napp.use(bodyParser.urlencoded({ extended: false }));\napp.use(bodyParser.json());\n\n// Example Node.js request validation code.\napp.get('/example-service', (request, response, next) => {\n  const { url, method, headers, hostname } = request;\n\n  const requestSignature = headers['x-hubspot-signature'];\n\n  // Compute expected signature\n  const uri = `https://${hostname}${url}`;\n  const encodedString = Buffer.from(\n    `${process.env.CLIENT_SECRET}${method}${uri}`,\n    'ascii'\n  ).toString('utf-8');\n  const expectedSignature = crypto\n    .createHash('sha256')\n    .update(encodedString)\n    .digest('hex');\n\n  console.log('Expected signature: %s', requestSignature);\n  console.log('Request signature: %s', expectedSignature);\n\n  // Add your custom handling to compare request signature to expected signature\n  if (requestSignature !== expectedSignature) {\n    console.log('Request of signature does NOT match!');\n    response.status(400).send('Bad request');\n  } else {\n    console.log('Request of signature matches!');\n    response.status(200).send();\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validar assinaturas de solicitação v3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " será um hash HMAC SHA-256 gerado usando o segredo do cliente do seu aplicativo combinado com detalhes da solicitação. Também conterá o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Request-Timestamp"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao validar uma solicitação usando o cabeçalho X-HubSpot-Signature-v3, você precisará"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rejeitar a solicitação se o registro de data/hora tiver mais de 5 minutos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Na URI da solicitação, decodificar todos os caracteres codificados por URL listados na tabela abaixo. Você não precisa decodificar o ponto de interrogação que indica o início da string de consulta."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Valor codificado"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Valor decodificado"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ":"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "?"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%40"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "@"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%21"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%24"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "$"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%27"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%28"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "("
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%29"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ")"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2C"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ","
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3B"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ";"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crie uma string com codificação utf-8 que concatene o seguinte: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestMethod"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestUri"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestBody"
        }), " + data/hora. O registro de data e hora é fornecido pelo cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Request-Timestamp"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crie um hash HMAC SHA-256 da string resultante usando o segredo do aplicativo como o segredo da função HMAC SHA-256."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A Base64 codifica o resultado da função do HMAC."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Compare o valor do hash à assinatura. Se forem iguais, a origem da solicitação foi confirmada como sendo da HubSpot. É recomendado usar uma comparação de string de tempo constante para proteger contra ataques de sincronização."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "#NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\n# Using Python 3\nimport hashlib\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nprint('source_string: {}'.format(source_string))\n\nhash_result = hashlib.sha256(source_string.encode('utf-8')).hexdigest()\nprint('hash_result: {}'.format(hash_result))\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\nrequire 'digest'\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nputs \"source_string: %s\" % [source_string]\n\nhash_result = Digest::SHA256.hexdigest source_string\nputs \"hash_result: %s\" % [hash_result]\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// NOTE: This is only an example for generating the expected hash.\n// You will need to compare this expected hash with the actual hash in the\n// X-HubSpot-Signature header.\n\nconst crypto = require('crypto');\n\nclientSecret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy';\nhttpMethod = 'POST';\nhttpURI = 'https://www.example.com/webhook_uri';\nrequestBody = '{\"example_field\":\"サンプルデータ\"}';\n\nsourceString = clientSecret + httpMethod + httpURI + requestBody;\nconsole.log('sourceString: ' + sourceString);\n\nhash = crypto.createHash('sha256').update(sourceString).digest('hex');\nconsole.log('hash: ' + hash);\n\n// output:\n// sourceString: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n// hash: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}