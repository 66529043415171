"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492284273;
const slug = exports.slug = 'reference/cms/hubl/variables-macros-syntax';
const title = exports.title = 'Syntax von HubL-Variablen & -Makros';
const name = exports.name = 'EMEA DACH (de) HubL variables & macros syntax_DE';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie HubL-Variablen & -Makros verwenden können, um dynamische Seiten in CMS Hub und E-Mails zu erstellen. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variablen",
  "label": "Variablen",
  "parent": null
}, {
  "depth": 0,
  "id": "makros",
  "label": "Makros",
  "parent": null
}, {
  "depth": 0,
  "id": "aufruf",
  "label": "Aufruf",
  "parent": null
}, {
  "depth": 0,
  "id": "import",
  "label": "Import",
  "parent": null
}, {
  "depth": 0,
  "id": "von-from",
  "label": "Von (from)",
  "parent": null
}, {
  "depth": 0,
  "id": "variablen-innerhalb-von-schleifen",
  "label": "Variablen innerhalb von Schleifen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      strong: "strong",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Syntax von HubL-Variablen & -Makros"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubL verwendet Variablen zum Speichern und Ausgeben von Werten in der Vorlage. Variablen können in Vorlagenlogik verwendet oder mit for-Schleifen durchlaufen werden. Neben Variablen sind Makros ein weiteres nützliches Tool, um sich wiederholende, aber dynamische Code-Abschnitte in Ihren Vorlagen zu drucken."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Variablen sind Ausdrücke, die durch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}}"
      }), "begrenzt sind. Die grundlegende Syntax von Variablen lautet wie folgt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// variables\n{{ variable }}\n{{ dict.attribute }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variablen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Variablen sind entweder ein einzelnes Wort in einem Ausdruck oder ein Attribut eines Dictionarys. HubL verwendet Python-basierte Datenstrukturen, Dictionaries oder ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Dicts"
      }), " genannt, um verschiedene Variablensätze zu speichern. HubSpot verwendet beispielsweise ein „content“-Dictionary, um viele Attribute zu speichern, die sich auf den mit dieser Vorlage erstellten Inhalt beziehen. Zum Beispiel druckt die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.absolute_url"
      }), " die URL eines bestimmten Inhalts."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot verfügt über viele vordefinierte Variablen, die in Ihren Seiten-, Blog- und E-Mail-Vorlagen verwendet werden können. Wir verfügen über eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "Referenzliste von Variablen"
      }), ". Sie können auch die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "Entwicklerinformationen"
      }), " anzeigen, wenn Sie eine beliebige Seite in Ihrem Account aufrufen, um die auf dieser Seite verfügbaren Variablen anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können nicht nur die Werte von Variablen und Dictionary-Attributen in einer Vorlage drucken, sondern auch eigene Variablen definieren. Sie können Zeichenfolgen, boolesche Werte, Ganzzahlen und Sequenzen speichern oder Dictionaries in einer einzigen Variablen erstellen. Variablen werden innerhalb von Anweisungstrennzeichen mit dem Wort „set“ definiert. Einmal gespeicherte Variablen können dann gedruckt werden, indem der Variablenname als Ausdruck angegeben wird. Unten sehen Sie verschiedene Arten von Informationen, die in Variablen gespeichert und dann gedruckt werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Variablen sollten entweder aus einzelnen Wörtern bestehen oder Unterstriche für Leerzeichen verwenden (z. B. my_variable). ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubL unterstützt keine Variablennamen mit Bindestrichen."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_var = \"This is a string value stored in a variable\" %}\n{{ string_var}}\n\n{% set bool_var = True %}\n{{ bool_var}}\n\n{% set int_var = 53 %}\n{{ int_var}}\n\n{% set seq_var = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ seq_var}}\n\n{% set var_one = \"String 1\" %}\n{% set var_two = \"String 2\" %}\n{% set sequence = [var_one,  var_two] %}\n{{ sequence }}\n\n{% set dict_var = {\"name\": \"Item Name\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{{ dict_var.price }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This is a string value stored in a variable True 53 [Item1, Item2, Item3]\n[String 1, String 2] $20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jedes der obigen Beispiele speichert einen anderen Variablentyp, wobei das letzte Beispiel zwei verschiedene Variablen in einer Sequenz speichert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neben der Ausgabe von Werten können Variablen in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if-Anweisungen"
      }), ", als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "Filterparameter"
      }), ", als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "Funktionsparameter"
      }), " sowie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for-Schleifen"
      }), " (nur Sequenzvariablen) zur Iteration verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine gängige Anwendung ist die Verwendung von Variablen zur Definition allgemeiner CSS-Werte in Ihrem Stylesheet. Zum Beispiel wenn Sie eine Farbe haben, die Sie in Ihrer CSS-Datei immer wieder verwenden. Auf diese Weise können Sie, wenn Sie die Farbe ändern müssen, den Variablenwert ändern, und alle Verweise auf diese Variable werden bei der nächsten Veröffentlichung der Datei aktualisiert."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- defines variable and assigns HEX color -->\n{% set primaryColor = \"#F7761F\" %}\n\na {\n    color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Makros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit HubL-Makros können Sie mehrere Anweisungen mit einem dynamischen Wert drucken. Wenn es beispielsweise einen Code-Block gibt, den Sie immer wieder schreiben müssen, kann ein Makro eine gute Lösung sein, da es den Code-Block ausgibt und dabei bestimmte Argumente, die Sie ihm übergeben, austauscht."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Makro wird in einer HubL-Anweisung definiert, benannt und mit Argumenten versehen. Das Makro wird dann in einer Anweisung aufgerufen, die seine dynamischen Werte übergibt und den endgültigen Code-Block mit den dynamischen Argumenten ausgibt. Die grundlegende Syntax eines Makros lautet wie folgt:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2)  %}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{% endmacro %}\n{{ name_of_macro(\"value to pass to argument 1\", \"value to pass to argument 2\")  }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihr Makro Leerzeichen in Form von neuen Zeilen zurückgibt, können Sie Leerzeichen in Vorlagen manuell entfernen. Wenn Sie ein Minuszeichen (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") an den Anfang oder das Ende eines Blocks, eines Kommentars oder eines Variablenausdrucks setzen, werden die Leerzeichen vor oder nach diesem Block entfernt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2) -%}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{%- endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Unten sehen Sie eine praktische Anwendung eines Makros, um eine CSS3-Eigenschaft mit den verschiedenen Anbieter-Präfixen und einem dynamischen Wert zu drucken. Damit können Sie 5 Codezeilen mit einem einzigen Makro-Tag drucken."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n    {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Mit Makros haben Sie die Möglichkeit, rekursiven Code zu erstellen. Um Probleme bei der Zuverlässigkeit und Performance zu vermeiden, können Sie Makros nur 20 Ebenen tief verschachteln. Wenn Sie diese Grenze überschreiten, erhalten Sie eine Fehlermeldung vom Typ: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "max. Rekursionslimit von 20 für <your macro name>-Makro erreicht"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aufruf"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In manchen Fällen möchten Sie vielleicht zusätzliche dynamische Informationen in den Makroblock zurückgeben. Sie können zum Beispiel ein großes Stück Code haben, das Sie zusätzlich zu den Argumenten in das Makro zurückführen wollen. Sie können dies mit Hilfe von call block und caller() tun. Ein Aufrufblock funktioniert im Wesentlichen wie ein Makro, erhält aber keinen eigenen Namen. Der caller()-Ausdruck gibt an, wo der Inhalt des Aufrufblocks wiedergegeben wird."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im folgenden Beispiel wird zusätzlich zu den beiden Argumenten ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<p>"
      }), " in ein Makro eingefügt."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro render_dialog(title, class) %}\n  <div class=\"{{ class }}\">\n      <h2>{{ title }}</h2>\n      <div class=\"contents\">\n          {{ caller() }}\n      </div>\n  </div>\n {% endmacro %}\n\n {% call render_dialog(\"Hello World\", \"greeting\") %}\n     <p>This is a paragraph tag that I want to render in my.</p>\n {% endcall %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"greeting\">\n  <h2>Hello World</h2>\n  <div class=\"contents\">\n    <p>This is a simple dialog rendered by using a macro and a call block.</p>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine weitere nützliche Funktion von Makros ist, dass sie vorlagenübergreifend verwendet werden können, indem eine Vorlagendatei in eine andere importiert wird. Hierfür müssen Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), " -Tag verwenden. Mit dem import-Tag können Sie einen Design-Manager-Dateipfad zur Vorlage angeben, die Ihre Makros enthält, und den Makros einen Namen in der Vorlage geben, in die Sie sie einfügen. Sie können dann Werte an diese Makros übergeben, ohne sie umdefinieren zu müssen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nehmen wir zum Beispiel an, Sie haben eine HTML-Vorlagendatei, die die folgenden 2 Makros enthält. Ein Makro wird definiert, um einen Header-Tag einzurichten, und eines, um einen Footer-Tag zu erzeugen. Diese Datei wird im Design-Manager unter dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), " gespeichert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- my_macros.html file -->\n{% macro header(tag, title_text) %}\n    <header> <{{ tag }}>{{ title_text }} </{{tag}}> </header>\n{% endmacro %}\n\n{% macro footer(tag, footer_text) %}\n     <footer> <{{ tag }}>{{ footer_text }} </{{tag}}> </footer>\n{% endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In der Vorlage, die diese Makros verwenden soll, wird ein import-Tag verwendet, das den Dateipfad zur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "-Datei angibt. Er benennt auch die Gruppe der Makros (in diesem Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "header_footer"
      }), "). Makros können dann ausgeführt werden, indem der Makroname an den Namen der importierten Vorlage angehängt wird. Siehe das folgende Beispiel."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% import \"custom/page/web_page_basic/my_macros.html\" as header_footer %}\n{{ header_footer.header(\"h1\", \"My page title\") }}\n<p>Some content</p>\n{{ header_footer.footer(\"h3:\", \"Company footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<header><h1>My page title</h1></header>\n<p>Some content</p>\n<footer><h3>Company footer info</h3></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Von (from)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie nur bestimmte Makros importieren möchten, anstatt alle Makros in einer separaten .html-Datei, können Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "from"
      }), "-Tag verwenden. Geben Sie mit dem from-Tag nur die Makros an, die Sie importieren möchten. Im Allgemeinen bietet die Verwendung von ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), " mehr Flexibilität, diese Alternative wird jedoch ebenfalls unterstützt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das folgende Beispiel greift auf dieselbe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "-Datei aus dem vorherigen Abschnitt dieses Artikels zu. Diesmal werden jedoch nicht alle Makros importiert, sondern nur das Makro für den Footer."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% from \"custom/page/web_page_basic/my_macros.html\" import footer %}\n{{ footer(\"h2\", \"My footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<footer><h2>My footer info</h2></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variablen innerhalb von Schleifen"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Alle innerhalb von Schleifen definierten Variablen sind auf den Bereich dieser Schleife beschränkt und können nicht von außerhalb der Schleife aufgerufen werden."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Variablen, die außerhalb einer Schleife definiert sind, aus einer Schleife heraus aufrufen, aber nicht umgekehrt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch Funktionen verwenden, um Objekte zu verändern, um Werte in Dictionaries festzulegen oder Listenoperationen durchzuführen. Im folgenden Beispiel wird die ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".update"
        }), " Listenoperation"]
      }), " verwendet:"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj = {val : 0} %}\n{% for i in range(0, 10) %}\n   {% do obj.update({val: obj.val + i }) %}\n{% endfor %}\n{{ obj.val }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "45\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}