"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890267;
const slug = exports.slug = 'reference/cms/modules/files';
const title = exports.title = 'Codificar módulos personalizados';
const name = exports.name = 'PT-BR module files';
const metaDescription = exports.metaDescription = 'Os módulos personalizados são componentes reutilizáveis que você pode usar em todo o site. Crie-os seguindo as práticas recomendadas de velocidade, experiência do usuário e acessibilidade.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "html-%2B-hubl-module.html",
  "label": "HTML + HubL (module.html)",
  "parent": null
}, {
  "depth": 0,
  "id": "css-module.css",
  "label": "CSS (module.css)",
  "parent": null
}, {
  "depth": 1,
  "id": "estilo-com-base-em-valores-de-campo-de-m%C3%B3dulo",
  "label": "Estilo com base em valores de campo de módulo",
  "parent": "css-module.css"
}, {
  "depth": 2,
  "id": "classes-de-css",
  "label": "Classes de CSS",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "bloco-require_css",
  "label": "Bloco require_css",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "adicionar-estilos-integrados",
  "label": "Adicionar estilos integrados",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "importar-arquivos-css-espec%C3%ADficos",
  "label": "Importar arquivos CSS específicos",
  "parent": "css-module.css"
}, {
  "depth": 0,
  "id": "javascript-module.js",
  "label": "JavaScript (module.js)",
  "parent": null
}, {
  "depth": 1,
  "id": "scripting-baseado-em-valores-de-campo",
  "label": "Scripting baseado em valores de campo",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "atributos-de-dados",
  "label": "Atributos de dados",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "bloco-require_js",
  "label": "Bloco require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "require_js",
  "label": "require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 0,
  "id": "informa%C3%A7%C3%B5es-relacionadas",
  "label": "Informações relacionadas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      h2: "h2",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Arquivos de módulos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao construir um módulo para páginas, blogs e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/quotes",
        children: "orçamentos"
      }), ", ele conterá três arquivos relacionados ao front-end que controlam o conteúdo, o estilo e a funcionalidade do módulo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.html"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.css"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.js"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Os módulos de e-mail não suportam module.css e module.js. Isso porque os clientes de e-mail não suportam JavaScript e o suporte a arquivos CSS vinculados é limitado."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esses arquivos serão sempre renderizados na página quando uma instância do módulo estiver na página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando uma página inclui várias instâncias de um mesmo módulo, o HubSpot carrega o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " e o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " do módulo uma única vez. Por padrão, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " não são carregados de forma assíncrona, mas você pode alterar isso incluindo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options e js_render_options"
      }), " no meta.json do módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos podem ser criados dentro do gerenciador de design ou localmente, usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI da HubSpot"
      }), ". No gerenciador de design, os arquivos dos módulos são exibidos em um editor multipainel."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module1.png",
        alt: "cms-dev-custom-module1"
      }), "Ao visualizar um módulo localmente, os arquivos estão contidos dentro das pastas module-name.module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module0.png",
        alt: "cms-dev-custom-module0"
      }), "Se você usa o gerenciador de design ou a CLI, a criação e o gerenciamento dos módulos depende das preferências da sua equipe. Veja ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "criar um fluxo de trabalho eficiente para desenvolvedores"
      }), " para obter recomendações."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML + HubL (module.html)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O arquivo module.html é destinado ao HTML e HubL. Em geral, o local em que um módulo é colocado no editor de páginas ou no arquivo de modelo determina onde o conteúdo do arquivo module.html será renderizado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esse arquivo atua como um elemento ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "include do HubL"
      }), " na página em que o módulo deve ser colocado. O arquivo module.html ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#using-module-field-data-to-render-html",
        children: "pode acessar os valores de campo do módulo por meio do HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSS (module.css)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " para adicionar CSS a um módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em geral, o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " suporta um subconjunto muito limitado do HubL. No entanto, você pode usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#module-asset-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_asset_url(\"my-image.png\")"
        })
      }), " para imagens adicionadas como ativos vinculados ao módulo. Isso permite vincular ativos como imagens, empacotadas no próprio módulo. Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial-module__wrapper {\n  background: url('{{ module_asset_url('bg-pattern.png') }}');\n  background-repeat: repeat;\n  min-height: 200px;\n  width: 100%;\n  display: block;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, aprenda como configurar o CSS de um módulo para mudar dinamicamente com base nos campos do módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilo com base em valores de campo de módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem algumas formas de influenciar o estilo do seu módulo com base nos campos do módulo. Escolha a forma que melhor se adapta ao seu caso de uso específico."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#css-classes",
          children: "Classes de CSS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#require-css-block",
          children: "Bloco require_css"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#inline-styles",
          children: "Estilos integrados"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Classes de CSS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para configurar um estilo predefinido para o módulo com a possibilidade de os editores escolherem entre opções, você pode adicionar um campo de módulo para definir classes em seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " que correspondem às classes CSS em seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, você pode ter um módulo de imagem e texto. Você quer que os criadores de conteúdo sejam capazes de posicionar a imagem à direita ou à esquerda do texto com base em um campo de escolha. Para fazer isso, você pode definir seus arquivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " da seguinte forma:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n\n<section class=\"img-text__wrapper img-text--{{ module.positioning }}\" aria-label=\"{{ module.heading }}\">\n  {# module.position is a choice field with two values \"img-left\" or \"img-right\". This dictates the order they appear on desktop. Controlled by CSS #}\n  <div class=\"img-text__img\">\n      <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\">\n  </div>\n  <div class=\"img-text__text\">\n      <h2>\n        {% inline_text field=\"heading\" value=\"{{ module.heading }}\" %}\n      </h2>\n      {% inline_rich_text field=\"text\" value=\"{{ module.text }}\" %}\n  </div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "/* module.css */\n\n/* CSS that makes the image show adjacent to the text, \nand positioned based on the positioning field.*/\n\n/* The media query ensures that on mobile the image \nwill always appear above the text for \nvisual consistency. */\n\n@media (min-width: 768px) {\n  .img-text__wrapper {\n    display: flex;\n    align-items: row;\n  }\n  .img-text__img,\n  .img-text__text {\n    flex: 1;\n    padding: 10px;\n  }\n  .img-text--img-right {\n    flex-direction: row-reverse;\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bloco require_css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando as classes não são ideais e você precisa fornecer aos criadores de conteúdo controle direto sobre propriedades específicas, as tags de estilo com blocos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " são a melhor opção."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para fornecer aos criadores de conteúdo controle direto sobre propriedades específicas sem usar classes, você pode adicionar estilo ao arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " dentro das tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "required_css"
      }), ". Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n  <style>\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  </style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "o module.html"
      }), " pode renderizar HubL, você pode usar os valores de campo do módulo como variáveis CSS. Quando um criador de conteúdo atualiza o campo no editor de páginas, o CSS é atualizado de acordo. Esses blocos movem as tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " para o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " da sua página dentro da instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode definir o CSS abrangido apenas à instância do módulo, envolvendo o CSS com as tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope_css"
      }), ". Por exemplo, você poderia atualizar o código do módulo acima da seguinte forma:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n<style>\n  {% scope_css %}\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  {% end_scope_css %}\n</style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar estilos integrados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando as classes não são ideais e você precisa dar aos criadores de conteúdo controle granular apenas sobre algumas propriedades, pode adicionar diretamente os valores a um atributo de estilo no HTML."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Module.html #}\n<div style=\"background: rgba({{ module.bg_color.color|convert_rgb }},{{ module.bg_color.opacity/100 }});\">\n  {% inline_rich_text field=\"richtext\" value=\"{{ module.richtext }}\" %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você tiver muitas propriedades e o código se tornar difícil de ler, considere mudar para o método de bloco ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Importar arquivos CSS específicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " é uma função do HubL que pode ser adicionada ao module.html para informar ao HubSpot que um determinado módulo ou modelo requer um determinado arquivo CSS para ser exibido. Uma tag de link apontando para o arquivo css é adicionada ao ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " da página dentro de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A função ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " só carregará esse arquivo CSS uma vez, independentemente do número de vezes que esse mesmo arquivo seja exigido pelos módulos e modelos em uma determinada página. Isso o torna ideal para situações em que os estilos são compartilhados entre os módulos, mas não faz sentido adicionar o CSS diretamente às folhas de estilo principais utilizadas em cada página do seu site."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " e os arquivos CSS associados fazem a mesma coisa, mas o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " pode ser executado de forma condicional com base nos valores do campo. Isso evita o carregamento de códigos desnecessários."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{ require_css(get_asset_url(\"/modules/shared_layout_styles.css\")) }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript (module.js)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " para adicionar JavaScript a um módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Assim como o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), ", o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " não suporta HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Scripting baseado em valores de campo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem algumas formas de criar módulos em que o JavaScript atua de forma diferente com base nos valores de campo. Compreender que método utilizar e quando pode trazer benefícios de desempenho para todas as páginas em que o módulo é utilizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, você tem um módulo de imagem personalizado e quer fornecer aos criadores de conteúdo a capacidade de fazer a imagem abrir em uma lightbox. Os criadores de conteúdo só querem isso em imagens específicas, e não em todas as instâncias do módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atributos de dados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os atributos de dados são atributos personalizados padrão HTML 5 que os desenvolvedores adicionam aos elementos. Todos os elementos suportam tanto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "class=\"yourClassName\""
      }), " quanto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-your-attribute=\"yourValue\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html-->\n<div class=\"img-module img-module__wrapper\" data-lightbox=\"{{ module.is_lightbox_enabled }}\" data-caption=\"above\">\n  <!-- module.is_lightbox_enabled is a boolean field, module.caption_position is a choice field. -->\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode usar atributos de dados para passar os valores de campo das instâncias do seu módulo a serem tratadas pelo arquivo module.js."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para usar os valores no arquivo module.js, você precisará executar em loop todas as instâncias do módulo. Adicionar um nome de classe específico do módulo ao wrapper mais externo do seu módulo lhe dará um alvo para usar, para que você possa executar em loop cada uma das instâncias do seu módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// module.js\n\nlet imgModules = document.getElementsByClassName('img-module');\nArray.from(imgModules).forEach(function (element) {\n  // loop through each of the instances of the module\n  // set data attributes to variables to make it easy to work with\n  let isLightboxEnabled = element.dataset.lightbox;\n  let captionStyle = element.dataset.caption;\n  if (isLightboxEnabled) {\n    element.addEventListener('click', function () {\n      showLightbox(captionStyle); // Execute your code for the action you want to take, you can pass your data attributes into functions from libraries.\n    });\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os atributos de dados permitirão recuperar os valores de campo para cada instância do módulo no module.js."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bloco require_js"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em situações avançadas, talvez ao usar uma biblioteca de modelos JavaScript ou um framework reativo como Vue.js ou React.js, você pode preferir emitir apenas os dados, enquanto o framework trata da renderização."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neste caso, use uma tag de script entre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " para fornecer variáveis que você pode acessar do script de modelagem."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% require_js %}\n<script>\n  let myArray = [\n    {%- for item in module.repeating_text_field -%}\"{{ item }}\",{%- endfor -%}\n  ];\n</script>\n{% end_require_js %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta técnica pode ser útil para fornecer aplicações avançadas com um conjunto inicial de dados do qual se pode renderizar. Isso elimina uma chamada inicial por JavaScript para recuperar os dados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " é uma função do HubL que informa ao HubSpot que um determinado módulo ou modelo requer um determinado arquivo JavaScript para ser carregado corretamente. A função tem dois parâmetros: o caminho para o arquivo e o local onde o arquivo deve ser adicionado (\"head\" ou \"footer\")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em um módulo, o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " somente pode ser adicionado ao módulo.html. O arquivo JavaScript referido na instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " somente será carregado uma vez por página, independentemente do número de vezes que seja requerido pelos módulos e modelos dentro da página. Isso reduz o número de solicitações HTTP e evita a duplicação de código."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algumas situações em que isso se torna útil:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você tem vários módulos ou modelos que requerem o mesmo JavaScript, você pode usar o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " para compartilhar esse script entre os módulos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você estiver trabalhando com um bundler JavaScript, como um webpack, pode ser mais fácil enviar os arquivos js para um local específico. Usando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", você pode associar o JavaScript ao módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " e os arquivos javascript associados fazem a mesma coisa, mas o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " pode ser executado de forma condicional com base nos valores do campo. Isso evita que códigos desnecessários sejam carregados. Você também tem a opção de carregar o JavaScript no cabeçalho, caso você precise disso."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Como o JavaScript pode causar bloqueio de renderização, o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " coloca o JavaScript no \"footer\" por padrão. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Saiba mais sobre como optimizar o desempenho."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informações relacionadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Otimize seu site do CMS Hub para velocidade"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Campos de módulo"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}