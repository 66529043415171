"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 170712321798;
const slug = exports.slug = 'guides/api/conversations/create-custom-channels';
const title = exports.title = 'Custom channels (BETA)';
const name = exports.name = 'vNext Docs DP - Custom channels (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to define custom channels in the conversations inbox and help desk (BETA).';
const position = exports.position = 1;
const badge = exports.badge = 'BETA';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-public-app",
  "label": "Create a public app",
  "parent": null
}, {
  "depth": 0,
  "id": "register-your-custom-channel-in-hubspot",
  "label": "Register your custom channel in HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "channel-schema",
  "label": "Channel schema",
  "parent": "register-your-custom-channel-in-hubspot"
}, {
  "depth": 1,
  "id": "channel-capabilities",
  "label": "Channel capabilities",
  "parent": "register-your-custom-channel-in-hubspot"
}, {
  "depth": 1,
  "id": "delivery-identifiers",
  "label": "Delivery identifiers",
  "parent": "register-your-custom-channel-in-hubspot"
}, {
  "depth": 0,
  "id": "connect-channel-accounts-to-hubspot",
  "label": "Connect channel accounts to HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "channel-account-connection-for-specific-accounts",
  "label": "Channel account connection for specific accounts",
  "parent": "connect-channel-accounts-to-hubspot"
}, {
  "depth": 1,
  "id": "creating-a-connection-setup-flow-for-your-custom-channel",
  "label": "Creating a connection setup flow for your custom channel",
  "parent": "connect-channel-accounts-to-hubspot"
}, {
  "depth": 0,
  "id": "publish-received-messages-to-hubspot",
  "label": "Publish received messages to HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "incoming-message-schema",
  "label": "Incoming message schema",
  "parent": "publish-received-messages-to-hubspot"
}, {
  "depth": 1,
  "id": "supported-message-attachment-types",
  "label": "Supported message attachment types",
  "parent": "publish-received-messages-to-hubspot"
}, {
  "depth": 2,
  "id": "file-attachments",
  "label": "File attachments",
  "parent": "publish-received-messages-to-hubspot"
}, {
  "depth": 2,
  "id": "quick-reply-attachments",
  "label": "Quick reply attachments",
  "parent": "publish-received-messages-to-hubspot"
}, {
  "depth": 2,
  "id": "unsupported-content-attachments",
  "label": "Unsupported content attachments",
  "parent": "publish-received-messages-to-hubspot"
}, {
  "depth": 0,
  "id": "handling-outgoing-messages-sent-from-hubspot",
  "label": "Handling outgoing messages sent from HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "handling-updates-to-channel-accounts",
  "label": "Handling updates to channel accounts",
  "parent": null
}, {
  "depth": 1,
  "id": "channel-account-updated-events",
  "label": "Channel account updated events",
  "parent": "handling-updates-to-channel-accounts"
}, {
  "depth": 1,
  "id": "channel-account-purged-events",
  "label": "Channel account purged events",
  "parent": "handling-updates-to-channel-accounts"
}, {
  "depth": 0,
  "id": "archive-a-custom-channel",
  "label": "Archive a custom channel",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Custom channels (BETA)"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " account, you can create custom channels to build a bridge between an external message service and HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/set-up-the-conversations-inbox",
        children: "inbox"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/help-desk/overview-of-the-help-desk-workspace",
        children: "help desk"
      }), " features. You can then publish your custom channel app in the HubSpot App Marketplace for other HubSpot admins to install and use in their own accounts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a public app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To get started, you'll need to create a public app that you'll use to register your custom channel with HubSpot, then handle incoming and outgoing messages to an inbox."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can follow the instructions in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/apps/public-apps/overview",
        children: "this article"
      }), " to create your public app. When configuring the scopes for your app on the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Auth"
      }), " tab, ensure that your app requests the following scopes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conversations.custom_channels.read"
        }), ": this scope allows you to access to read messages and threads that your custom channel has already published to HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conversations.custom_channels.write"
        }), ": this scope allows you to publish messages to HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conversations.read"
        }), ": this scope allows you to retrieve a list of conversation inboxes in an account using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/conversations/inbox-and-messages#inboxes",
          children: "Conversations BETA API"
        }), ", so that you can connect channel accounts to specific inboxes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've created your app, take note of the following app details that you'll use to identify your app when making requests to the Custom Channels API endpoints."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App ID"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Client ID"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Client secret"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Register your custom channel in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After creating your app, you can create your custom channel in HubSpot, using your HubSpot Developer API Key, and your App ID of the app you just created."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To register your channel, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/conversations/v3/custom-channels?hapikey={YOUR_DEVELOPER_API_KEY}&appId={appId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the body of your request, provide a channel schema that includes an object that details the channel capabilities."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the following request body provides an example channel schema with a set of basic channel capabilities:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for registering a custom channel\n{\n  \"name\": \"My new custom channel\",\n  \"webhookUrl\": \"https://example.com/handle-new-outgoing-message-notification\",\n  \"capabilities\": {\n    \"deliveryIdentifierTypes\": [],\n    \"richText\": [\"HYPERLINK\", \"TEXT_ALIGNMENT\", \"BLOCKQUOTE\"],\n    \"allowConversationStart\": false,\n    \"allowMultipleRecipients\": false,\n    \"allowInlineImages\": false,\n    \"allowOutgoingMessages\": false,\n    \"outgoingAttachmentTypes\": [\"FILE\"]\n    \"allowedFileAttachmentMimeTypes\": [\"image/png\"],\n    \"maxFileAttachmentCount\": 1,\n    \"maxFileAttachmentSizeBytes\": 1500000,\n    \"maxTotalFileAttachmentSizeBytes\": 1500000\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Channel schema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The table below outlines each of the parameters required for the channel schema in the request body."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of your channel, which will appear to users who install your app."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhookUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A valid URL where HubSpot will send webhook notifications of outgoing messages that should be sent using your channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "capabilities"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object that specifies that the messaging content and recipient capabilities of your channel. Consult the table below for all supported fields for this object."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountConnectionRedirectUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "If you're [creating a connection setup flow for your custom channel](#creating-a-connection-setup flow-for-your-custom-channel), this is the URL that HubSpot will point to when users click the option to install your app in the inbox or help desk connection setup in HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Channel capabilities"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The table below outlines all available fields you can specify for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "capabilities"
      }), " field of your channel."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifierTypes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional array that specifies the identifier types your channel supports for its senders and recipients. Consult the delivery identifier types table below for more info."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "richText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An optional array of supported rich text elements presented as options in HubSpot's message composer. Available values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"BLOCKQUOTE\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"BOLD\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"FONT_SIZE\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"FONT_STYLE\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"HYPERLINK\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"ITALIC\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"LISTS\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TEXT_ALIGNMENT\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TEXT_HIGHLIGHT_COLOR\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TEXT_COLOR\""
            }), ", and/or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"UNDERLINE\""
            }), ". If left unspecified, no rich text elements will be supported."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowConversationStart"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether your channel allows HubSpot users to initiate conversations with outgoing messages."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowMultipleRecipients"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether your channel allows messages to be sent with multiple recipients."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowInlineImages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether your channel allows inline images to be included in the message body. If set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", HubSpot's message composer will present an ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Insert image"
            }), " option."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowOutgoingMessages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether your channel allows outgoing messages. You can set this field to true if you want your channel to be an \"intake-only\" message source."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "outgoingAttachmentTypes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The types of attachments your channel supports (valid values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"FILE\""
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"QUICK_REPLIES\""
            }), ")."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowedFileAttachmentMimeTypes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The file MIME types that your channel supports for file attachments, if any. This defaults to a fairly permissive list of common text, image, audio, video, and archive files."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"image/png\", \"text/plain\", ...]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxFileAttachmentCount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of file attachments permitted for a single message."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxFileAttachmentSizeBytes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum individual file size permitted for a single attachment on a message, in bytes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxTotalFileAttachmentSizeBytes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum cumulative file size permitted for all attachments combined for a single message, in bytes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delivery identifiers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "deliveryIdentifierTypes"
      }), " field specified within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "channelCapabilities"
      }), " of your channel specifies an array of delivery identifiers."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A delivery identifier is an object that includes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), ", that describes sender or recipient delivery details:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The format for an identifier of the sender or recipient. Much like a postal mail address, this identifier provides a well-defined format for sending or receiving messages to/from the individual contact. The following types are available:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "HS_EMAIL_ADDRESS"
                }), ": identifier value must have a valid email address."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "HS_PHONE_NUMBER"
                }), ": identifier value must be a valid phone number, based on the logic in libphonenumber."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CHANNEL_SPECIFIC_OPAQUE_ID"
                }), ": identifier value does not require any format validation to be performed, except to check it isn't blank. This option is intended for use in channels with their own concept of \"addresses\" (e.g., usernames, UUIDs, or chat handles)."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The actual value of the delivery identifier (e.g., \"", (0, _jsxRuntime.jsx)(_components.a, {
              href: "mailto:support@example.com",
              children: "support@example.com"
            }), "\" for an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HS_EMAIL_ADDRESS"
            }), ")."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you need to review or update your channel's configuration, you can send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to the same endpoint above using the same channel schema. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " requests also accept partial-update requests."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Connect channel accounts to HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After you've created your custom channel, the next step is to allow specific channel accounts to be connected to a user's HubSpot account, so that messages can be sent and received."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Channel account connection for specific accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're developing a custom channel for your own business, or building a specific integration on behalf of a client, you can connect channel accounts directly to a HubSpot account. You'll need to have direct administrative access to the account or have an app installed in an account that's authorized the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversations.read"
      }), " scope."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a HubSpot user installs your custom channel app in their account, a request will be sent to your custom channel's ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Redirect URL"
      }), ", which contains an authorization code that can be used to request an OAuth token for accessing that user's HubSpot account and its associated data. For more information on how OAuth works, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/apps/authentication/working-with-oauth",
        children: "this article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've obtained an OAuth access token using the authorization code, you can use this OAuth access token to send requests to HubSpot to connect specific channel accounts."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To connect a specific channel account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/conversations/v3/custom-channels/{channelId}/channel-accounts"
      }), ", where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "channelId"
      }), " is the channel ID that you've registered."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The request body below provides an example channel account schema for an inbox ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body to connect a specific channel account\n{\n  \"inboxId\": \"123\",\n  \"name\": \"My connected inbox\",\n  \"deliveryIdentifier\": \"jdoe@example.com\",\n  \"authorized\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The table below provides details for each of the possible fields of a channel account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inboxId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/use-the-conversations-inbox",
              children: "conversations inbox"
            }), " that you've connected your channel account to. You can retrieve a list of conversation inboxes with their associated IDs by using the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages#inboxes",
              children: "conversations beta API"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The friendly name that will appear to HubSpot users in their account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifier"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional delivery identifier that should be associated with this channel account (e.g., a username or email address that uniquely identifies this channel account within your integrated messaging service)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default this field is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", but if the account is no longer usable or accessible, you can update this to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " to disable this channel account for your custom channel."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Creating a connection setup flow for your custom channel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you're developing a custom channel integration that you want to distribute to multiple customers via the HubSpot marketplace, you can register an account connection flow that will appear within the in-app HubSpot inbox or help desk connection flow."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The screenshot below showcases how an example custom channel called ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "HuddleHub"
      }), " would appear in the help desk connection flow in HubSpot. Your app's name, description, and logo from your public app settings will appear as part of the custom channel option that users can click to connect."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/custom-channel-account-connection-api.png",
        alt: "custom-channel-account-connection-api"
      }), "Any account that installs your app can click the associated option to authorize their credentials with any third-party service you're integrating with (if applicable), then proceed to connect that account to HubSpot. When users click the associated option in HubSpot, a pop-up window will open and load a webpage that allows them to provide their credentials. You'll need to host this webpage and any associated backend services yourself."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The screenshot below provides a minimal example of what this dialog box looks like and includes some debugging information based on the query parameters that HubSpot includes in the URL. When designing your own page, you should optimize it for a 600px by 600px window, and provide a user-friendly method (e.g., a form) to provide any account details and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#delivery-identifiers",
        children: "delivery identifier information"
      }), " you'll need to connect their account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/custom-channel-account-connection-flow-huddle-hub-dialog.png",
        alt: "custom-channel-account-connection-flow-huddle-hub-dialog"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To register the behavior of your custom channel account connection with a HubSpot account that's installed your app, you'll make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/conversations/v3/custom-channels/{channelId}"
      }), ". In the body of your request, set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "channelAccountConnectionRedirectUrl"
      }), " property to the URL that you want HubSpot to navigate to when users click your custom channel in the connection flow."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once clicked, HubSpot will open a new window with the URL you specified, and append the following query parameters that you can use to authenticate with a third-party service, or for any other custom behavior you might need to wire up based on the user's HubSpot account, inbox, channel, or user information:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A shared object created in HubSpot's database that you'll use as an interim session token while the end user goes through the setup flow.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "Once they've provided all the parameters you'll need to connect their account, you'll use this token to make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PATCH"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/conversations/v3/custom-channels/{channelId}/channel-account-staging-tokens/{accountToken}"
            }), ", detailed in the section below this table."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of your custom channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inboxId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the help desk or inbox that the HubSpot admin wants to connect your channel to."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot account attempting to connect their account to your custom channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirectUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The HubSpot URL that you should send the user to when your flow and/or form submission is complete."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've collected the user's information in your page (e.g., using a form to collect each of the required parameters) and the user confirms their input by clicking a submission button, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/conversations/v3/custom-channels/{channelId}/channel-account-staging-tokens/{accountToken}"
      }), " and provide the following parameters in your request:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountToken"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["As detailed above, this is the same ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountToken"
            }), " provided as a query parameter in the URL of your webpage when the user initiated the connection in their HubSpot account. This should be provided as a path parameter in the URL of your request."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of your custom channel, provided as a path parameter in the URL of your request."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This is a request body parameter that will aact as a friendly name for the channel account that will appear in the inbox or help desk settings in the user's HubSpot account.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/successfully-connected-huddlehub-account.png",
              alt: "successfully-connected-huddlehub-account"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifier"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "DeliveryIdentifier"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Provided as a request body parameter, the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifier"
            }), " that describes the address of the connected account. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#delivery-identifiers",
              children: "Delivery Identifiers section above"
            }), " for more details on how a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifier"
            }), " is structured."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've made the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request and gotten a successful response, you can redirect the user back to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirectUrl"
      }), " detailed above and close the pop-up window."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The user will then see a success screen in their HubSpot account:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/custom-account-channel-connection-flow-api-doc.png",
        alt: "custom-account-channel-connection-flow-api-doc"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Publish received messages to HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a channel account that uses your custom channel receives a message in the external message service (e.g., a user gets a new direct message in their social account's inbox), you'll want to ensure that the message gets published to HubSpot, which will log it into the HubSpot CRM and the associated conversations inbox."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To publish a received message to HubSpot, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/conversations/v3/custom-channels/{channelId}/messages"
      }), ", where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "channelId"
      }), " should be the channel ID that you previously registered."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incoming message schema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The body of your request can include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The plaintext content of your message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "richText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional string that represents the HTML markup for the formatted text content of the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the channel account receiving the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integrationThreadId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional string for the unique integration thread ID to be used in threading messages together."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integrationIdempotencyId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An optional string you can use as a unique-per-message idempotency ID, to ensure that multiple requests to publish this message don't result in duplicate copies of it being published.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "The idempotency ID is only unique in the context of a channel account: the same channel account cannot have two messages with the same idempotency ID. However, two different channel accounts can have messages that have the same idempotency ID."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inReplyToId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional string you can use to reference the message ID of an existing message that this message is a direct reply to."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageDirection"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A required string that should be set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"INCOMING\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "senders"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of messages senders. Each sender should be provided as an object with two fields: a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliverIdentifier"
            }), ", detailed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#delivery-identifiers",
              children: "above"
            }), ", and a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", which is an optional string that serves as a friendly name for the sender."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recipients"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of intended recipients. Each recipient should be provided as an object with two fields: a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifier"
            }), ", detailed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#delivery-identifiers",
              children: "above"
            }), ", and a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", which is an optional string that serves as a friendly name for the recipient."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional timestamp, in ISO8601 format, that specifies when the message was received. If omitted, this value will default to the time you publish the message to HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attachments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional list of message attachments."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supported message attachment types"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Several message attachment types are currently available: files, quick replies, as well as a catch-all type for unsupported file attachments."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "File attachments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "File attachments can be uploaded using HubSpot's files API, and the resulting file ID can be used to reference the file contents when publishing incoming messages to HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Consult the table below for details on how to structure a message attachment referencing an uploaded file:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A required string that must be set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"FILE\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The HubSpot file ID for the uploaded file that the attachment will reference."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileUsageType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An enumeration used to render an appropriate preview of the file attachment. Available values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"STICKER\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"VOICE_RECORDING\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"IMAGE\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"AUDIO\""
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"OTHER\""
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Quick reply attachments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Some chat services, such as Facebook Messenger, present suggested options as \"quick replies\" to users, allowing them to select from a predefined list of response messages. HubSpot supports this functionality by providing the ability to include quick replies as an attachment to your message."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Consult the table below on how to structure a message attachment that includes a list of quick replies:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A required string that must be set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"QUICK_REPLIES\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quickReplies"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of one or more predefined responses. Each quick reply is an object that should include the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), ": a string that provides the text content of the reply, or the URL to visit when the option is clicked."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), ": an optional string that will appear instead of the raw ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), " when presenting the option to the user."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "valueType"
                }), ": whether to treat the option as a hyperlink (with the value as the URL and the label as text) or as plaintext (in which case the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), " is used as the option text)."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Unsupported content attachments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These attachments are used to represent attachment types included in the original message that HubSpot cannot recognize."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if a channel account on the messaging platform you're connecting to HubSpot via your channel receives a message that includes an attachment not listed in this article, and you want users viewing the message in their HubSpot account to know there was an attachment they might want to view directly on the messaging platform, you could use this attachment type when publishing the message to HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To specify an unsupported content attachment, the attachment should be specified as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example attachment schema for unsupported content\n{\n  \"type\": \"UNSUPPORTED_CONTENT\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Handling outgoing messages sent from HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To handle outgoing messages sent from HubSpot using your custom channel, HubSpot will send a payload as a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhookUrl"
      }), " that you specified when you first registered your custom channel."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The payload of the request that HubSpot sends will include a type of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OUTGOING_CHANNEL_MESSAGE_CREATED"
      }), ". The table below provides a full reference on all fields included in the body of the webhook event:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A string that specifies the webhook event type, which will be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"OUTGOING_CHANNEL_MESSAGE_CREATED\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The HubSpot portal ID for the account from which the message was sent."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The channel ID of your custom channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When the event occurred, represented as an ISO8601 timestamp."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object that provides details about the outgoing message. Each of the fields of this message are detailed in the message table below."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unique reference to a specific message over a given channel used for debugging."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelIntegrationThreadIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This array includes an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "integrationThreadId"
            }), " that was sent if you had an opaque delivery identifier. If you use delivery identifier based threading, HubSpot will generate an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "integrationThreadId"
            }), " on your behalf. You can supply this value when ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#publish-received-messages-to-hubspot",
              children: "publishing a message to HubSpot"
            }), " to ensure the message is added to an existing thread, or it will create a new thread if the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "integrationThreadId"
            }), " does not yet exist."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "message"
      }), " object in the webhook payload contains the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A unique message ID"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A string that's hard-coded to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"MESSAGE\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of your custom channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the channel account receiving the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversationsThreadId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the thread in the conversations inbox that includes this message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An ISO8601 timestamp representing the time when the user hit ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Send"
            }), " from within the HubSpot message composer."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A string representing the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/conversations/inbox-and-messages#get-actors",
              children: "actorId"
            }), " of the HubSpot user who sent the message."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "senders"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of messages senders. Each sender is provided as an object with three fields: an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/conversations/inbox-and-messages#get-actors",
              children: "actorId"
            }), " string, a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliverIdentifier"
            }), ", detailed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#delivery-identifiers",
              children: "above"
            }), ", and a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", which is a string that serves as a friendly name for the sender."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recipients"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of intended recipients. Each recipient is provided as an object with three fields: an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/api/conversations/inbox-and-messages#get-actors",
              children: "actorId"
            }), " string, a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deliveryIdentifier"
            }), ", detailed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#delivery-identifiers",
              children: "above"
            }), ", and a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", which is an optional string that serves as a friendly name for the recipient."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The plaintext content of your message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "richText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional string that represents the HTML markup for the formatted text content of the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "channelAccountId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"OUTGOING\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inReplyToId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional string you can use to reference the message ID of an existing message that this message is a direct reply to."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "truncationStatus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"NOT_TRUNCATED\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"SENT\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attachments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional list of message attachments."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Handling updates to channel accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Whenever a channel account is updated or deleted, HubSpot will send a payload as a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhookUrl"
      }), " that you specified when you first registered your custom channel. This can be useful for cases where you want to take an action such as notifying your users that their connection to HubSpot has been modified or deleted."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Channel account updated events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The payload of the request that HubSpot sends will include a type of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CHANNEL_ACCOUNT_UPDATED"
      }), ". The table below provides a full reference on all fields included in the body of the webhook event:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A string that specifies the webhook event type, which will be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CHANNEL_ACCOUNT_UPDATED\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The HubSpot portal ID for the account from which the message was sent."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The channel ID of your custom channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When the event occurred, represented as an ISO8601 timestamp."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the custom channel account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountDeliveryIdentifier"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The delivery account identifier that's associated with the channel account."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Channel account purged events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The payload of the request that HubSpot sends will include a type of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CHANNEL_ACCOUNT_PURGED"
      }), ". The table below provides a full reference on all fields included in the body of the webhook event:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A string that specifies the webhook event type, which will be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CHANNEL_ACCOUNT_PURGED\""
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The HubSpot portal ID for the account from which the message was sent."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The channel ID of your custom channel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When the event occurred, represented as an ISO8601 timestamp."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the custom channel account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channelAccountDeliveryIdentifier"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The delivery account identifier that was associated with the channel account."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Archive a custom channel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To archive a custom channel, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " call to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/conversations/v3/custom-channels/{channelId}"
      }), " using the ID of the channel you want to archive as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "channelId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If successfully archived, you'll receive a response of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No content"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}