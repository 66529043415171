"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358768;
const slug = exports.slug = 'guides/api/cms/hubdb';
const title = exports.title = 'CMS API | HubDB';
const name = exports.name = 'CMS API | HubDB';
const metaDescription = exports.metaDescription = 'The HubDB endpoints are used to get and manage data in your HubDB data tables.';
const position = exports.position = 4;
const toc = exports.toc = [{
  "depth": 0,
  "id": "rate-limits",
  "label": "Rate limits",
  "parent": null
}, {
  "depth": 0,
  "id": "draft-vs-live-tables",
  "label": "Draft vs live tables",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-hubdb-table",
  "label": "Create a HubDB table",
  "parent": null
}, {
  "depth": 1,
  "id": "add-table-columns",
  "label": "Add table columns",
  "parent": "create-a-hubdb-table"
}, {
  "depth": 1,
  "id": "add-table-rows",
  "label": "Add table rows",
  "parent": "create-a-hubdb-table"
}, {
  "depth": 1,
  "id": "import-rows-from-csv",
  "label": "Import rows from CSV",
  "parent": "create-a-hubdb-table"
}, {
  "depth": 1,
  "id": "date-formatting",
  "label": "Date formatting",
  "parent": "create-a-hubdb-table"
}, {
  "depth": 1,
  "id": "reset-options",
  "label": "Reset options",
  "parent": "create-a-hubdb-table"
}, {
  "depth": 0,
  "id": "retrieve-hubdb-data",
  "label": "Retrieve HubDB data",
  "parent": null
}, {
  "depth": 1,
  "id": "filter-returned-rows",
  "label": "Filter returned rows",
  "parent": "retrieve-hubdb-data"
}, {
  "depth": 1,
  "id": "sort-returned-rows",
  "label": "Sort returned rows",
  "parent": "retrieve-hubdb-data"
}, {
  "depth": 0,
  "id": "configuring-hubdb-tables-for-dynamic-pages",
  "label": "Configuring HubDB tables for dynamic pages",
  "parent": null
}, {
  "depth": 0,
  "id": "changes-in-v3",
  "label": "Changes in v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS API | HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDB is a relational data store that presents data as rows, columns, and cells in a table, much like a spreadsheet. HubDB tables can be added or modified ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview#creating-your-first-table",
        children: "within your HubSpot account"
      }), ", but you can also use the API endpoints documented here. For information on using data from HubDB tables on your website or in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "programmable emails"
      }), ", check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubSpot's CMS developer documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Similar to HubSpot website pages, HubDB tables support ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), " versions. This allows you to update data in the table, either for testing or to allow for a manual approval process, without affecting any live pages. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#draft-vs-live-tables",
        children: "drafted versus live tables"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a table is set to be ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "allowed for public access"
      }), ", you can access the published version of the table and rows without any authentication by specifying your HubSpot account ID via the query parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're migrating from v2 of the HubDB API, learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#changes-in-v3",
        children: "changes in the current (v3) API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " endpoints that support ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " also support ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), ", so you can access data in a table client-side using JavaScript and your account ID. Other methods and the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Get all tables"
        }), " endpoint require authentication and do not support ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rate limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB API requests have different rate limits, depending on the type of request:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Any ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " requests made that don't require authentication (including client-side JavaScript requests) are limited to 10 requests per second. These requests won't count towards the daily limit."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["All other requests ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/oauth-tokens",
          children: "using authentication"
        }), " follow the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api",
          children: "standard limits"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Draft vs live tables"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB tables have both draft and live versions and live versions can be published or unpublished. This will allow you to update data in the table, either for page previews or testing or to allow for a manual approval process, without affecting any live pages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this API, separate endpoints are designated for the draft and published versions of a table. For example, you can retrieve the published version of a table by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following endpoint:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["And to retrieve any content that has been drafted but not yet published, you would add ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/draft"
      }), " to the end of the URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Draft data can be reviewed and then pushed in HubSpot, or with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/push-live"
      }), " endpoint. The draft data can also be discarded via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/reset"
      }), " endpoint, allowing you to revert to the current live version of the data without disruption."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a HubDB table"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a HubDB table, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request body, specify the following required fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The internal name of the table. This name cannot be changed once the table is created. Names can only include lowercase letters, digits, and underscores and cannot begin with a number."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"my_table\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The label of the table that users see when editing the table in HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\":\"My table\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition, you can specify the following optional fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the table can be used for ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#configuring-hubdb-tables-for-dynamic-pages",
              children: "creating dynamic pages"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"useForPages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowPublicAPIAccess"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether the table can be read without authorization."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowPublicApiAccess\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowChildTables"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether child tables can be created for the table."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowChildTables\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableChildTablePages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "multilevel dynamic pages"
            }), " should be created using child tables."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"enableChildTablePages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The columns of the table. Learn more about column properties in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-table-columns",
              children: "Add table columns"
            }), " sections."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "See \"Add table columns\" section below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Without any columns added yet, your create request might look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"name\": \"test_table\",\n  \"label\": \"Test Table\",\n  \"useForPages\": false,\n  \"allowPublicApiAccess\": false,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"columns\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add table columns"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each column in a HubDB table can be defined with the following properties:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Required. The internal name of the column. Cannot be changed after the column is created."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"row_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Optional. The label for the column that users will see when editing the table in HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\": \"Row label\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The data type of the column. Must be one of the following:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TEXT"
                }), ": a text field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "RICHTEXT"
                }), ": a text field that supports basic HTML formatting. Not recommended for raw HTML, as it may impact whether the HTML is editable in HubSpot. Editing the code in HubSpot may also impact the way the code is rendered. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NUMBER"
                }), ": a number field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOOLEAN"
                }), ": represented as a checkbox in HubSpot. Use ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0"
                }), " for unchecked and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), " for checked."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATE"
                }), ": stores a specific date as a millisecond timestamp set to midnight UTC."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATETIME"
                }), ": stores a date and a time as a millisecond timestamp."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SELECT"
                }), ": the column can only be set to one of a set of options. See the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " field below for required properties."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MULTISELECT"
                }), ": the column can be set to one or more of a set of options. See the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " field below for required properties."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LOCATION"
                }), ": stores a latitude and longitude location."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IMAGE"
                }), ": stores the URL of an image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "VIDEO"
                }), ": stores the player ID of the video."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FOREIGN_ID"
                }), ": the column will reference a column from another HubDB table. In addition, you must define the other HubDB table with the following properties:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "foreignTableId: the ID of the other HubDB table. "
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "foreignColumnId: the ID of the column in the other HubDB table."
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CURRENCY"
                }), ": stores the number as a currency value."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ": stores a file from the file manager. You'll also need to include a ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "fileType"
                }), " field to specify whether the field can store all file types (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ") or only document types such as PDF (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOCUMENT"
                }), ")."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"type\": \"type\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of options for select and multiselect columns. Each option is defined with a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " along with a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), " equal to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option\": [{\"name\":\"Option 1\", \"type\":\"option\"}, {\"name\": \"Option 2\", \"type\": \"option\"}]"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the above fields, your request to create a new HubDB table might look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"label\": \"Test Table\",\n  \"name\": \"test_table\",\n  \"columns\": [\n    {\n      \"name\": \"text_column\",\n      \"label\": \"Text Column\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"number_column\",\n      \"label\": \"Number Column\",\n      \"archived\": false,\n      \"type\": \"NUMBER\"\n    },\n    {\n      \"name\": \"multiselect\",\n      \"label\": \"Multi Select Column\",\n      \"archived\": false,\n      \"type\": \"multiselect\",\n      \"options\": [\n        {\n          \"name\": \"Option 1\",\n          \"type\": \"option\"\n        },\n        {\n          \"name\": \"Option 2\",\n          \"type\": \"option\"\n        }\n      ]\n    }\n  ],\n  \"useForPages\": true,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"allowPublicApiAccess\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After creating a table, columns will be assigned IDs in ascending order. When updating existing columns, include the column's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " field in the input object."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add table rows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can add rows either manually through the API, or you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#import-rows-from-csv",
        children: "import rows from a CSV file"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add rows to a HubDB table, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For each table row, you can include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of key-value pairs with the column name and the value you want to add to it.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "If you don't want to set a specific value for a column, you can omit the column name from the list of key-value pairs."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"values\": { \"text_column\": \"sample text\", \"number_column\": 76}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For tables ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "enabled for dynamic pages"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " is the path suffix used for the page created for this row."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"path\": \"example_url_path\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For tables ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "enabled for dynamic pages"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " is the HTML title used for the page created for this row."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"Example Title\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When creating ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "multilevel dynamic pages"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " specifies the child table ID."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableId\": 123456"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the above fields, your request might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"values\": {\n    \"text_column\": \"sample text value\",\n    \"number_column\": 76,\n    \"rich_text_column\": \"<strong>This is a styled paragraph.</strong>\",\n    \"date_column\": 1591228800000,\n    \"date_time_column\": 1604450520000,\n    \"boolean_column\": 1,\n    \"select_column\": {\n      \"name\": \"option 1\",\n      \"type\": \"option\"\n    },\n    \"multiselect_column\": [\n      {\n        \"name\": \"Option 1\",\n        \"type\": \"option\"\n      },\n      {\n        \"name\": \"Option 2\",\n        \"type\": \"option\"\n      }\n    ],\n    \"url_column\": \"https://www.hubspot.com/marketing\",\n    \"video_column\": 3392210008,\n    \"image_column\": {\n      \"url\": \"https://f.hubspotusercontentqa00.net/hubfs/99992002/image3%20(1).jpg\",\n      \"width\": 1600,\n      \"height\": 900,\n      \"type\": \"image\"\n    },\n    \"foreign_id_column\": [\n      {\n        \"id\": \"4364402239\",\n        \"type\": \"foreignid\"\n      },\n      {\n        \"id\": \"4364402240\",\n        \"type\": \"foreignid\"\n      }\n    ]\n  },\n  \"path\": \"test_path\",\n  \"name\": \"test_title\",\n  \"childTableId\": \"1902373\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Import rows from CSV"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To import data into a HubDB table from a CSV file, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft/import"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The import endpoint takes a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "config"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " a set of JSON-formatted options for the import."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "file"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the CSV file that you want to import."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), ", include the following fields as a JSON string:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "skipRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The number of header rows that should be skipped over. This field defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), ", skipping the first row and treating it as a header. Set this to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " if all of the rows are valid data."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"skipRows\": 0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "separator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The column delimiter in the CSV file. Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\",\""
            }), " by default."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"separator\": \",\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The index of the column in the source file containing the row’s ID (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), ").If this column is specified, the import will update the existing rows in the table for the matching row IDs from the CSV file. This is optional and you can ignore this during the first time you import data into a table.See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Reset options"
            }), " section below more detailed information."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"idSourceColumn\": 1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resetTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", meaning that the table's rows will be updated without removing any existing rows. If set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the spreadsheet rows will overwrite table data, meaning that any rows in the table that aren't in the spreadsheet will be deleted.See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Reset options"
            }), " section below more detailed information."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"resetTable\": true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For tables ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "enabled for dynamic pages"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            }), " specifies the column in the CSV file that contains the row's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ". Column numbers are in ascending order, with the first column being ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"nameSourcecolumn\": 5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For tables ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "enabled for dynamic pages"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            }), " specifies the column in the CSV file that contains the row's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), ". Column numbers are in ascending order, with the first column being ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"pathSourcecolumn\": 6"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specifies the column in the CSV file that contains the row's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), ". Column numbers are in ascending order, with the first column being ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableSourcecolumn\": 3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of mappings for the columns in the source file to the columns in the destination HubDB table.Each mapping must have the following format: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"source\":1,\"target”:\"columnIdOrName\"}"
            }), " ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "source:"
                }), " the column index in the source file. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                }), " for the second column."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "target:"
                }), " the ID or name of the HubDB table column. You can get the ID or name of a column by ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#retrieve-hubdb-data",
                  children: "getting the details for the table"
                }), "."]
              })]
            }), "If your file has an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), " column, you shouldn't include it in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), ". Instead, include it as the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            }), " to update existing rows."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"columnMappings\": [{\"source\":1, \"target\": 2}, {\"source\": 2, \"target\": \"column_name\"}]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryKeyColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of a column in the target HubDB table that will be used for deduplication. The column's ID cannot be used for this field."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"primaryKeyColumn\": \"column_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "encoding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The file's encoding type. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utf-8"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ascii"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-2022-jp"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "windows-1252"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"encoding\": \"utf-8\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Only CSV is supported."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"format\": \"csv\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the above table, your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), " JSON might look like the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example config JSON\n{\n  \"skipRows\": 1,\n  \"separator\": \",\",\n  \"idSourceColumn\": 1,\n  \"resetTable\": false,\n  \"columnMappings\": [\n    {\n      \"target\": 1,\n      \"source\": 2\n    },\n    {\n      \"target\": 2,\n      \"source\": \"zip_code\"\n    }\n  ],\n  \"primaryKeyColumn\": \"name\",\n  \"encoding\": \"utf-8\",\n  \"format\": \"csv\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If using cURL, your command might look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -L -X POST 'https://api.hubspotqa.com/hubdb/api/v2/tables/xxxxxx/import?portalId=xxxxxxx' \\\n-H 'Content-Type: multipart/form-data' \\\n-F 'config=\"{\\\"skipRows\\\":1,\\\"format\\\":\\\"csv\\\",\\\"separator\\\":\\\",\\\",\\\"encoding\\\":\\\"iso-8859-1\\\",\\\"columnMappings\\\":[{\\\"target\\\":1,\\\"source\\\":7},{\\\"target\\\":3,\\\"source\\\":8}],\\\"idSourceColumn\\\":1,\\\"pathSourceColumn\\\":null,\\\"nameSourceColumn\\\":null,\\\"childTableSourceColumn\\\":null,\\\"resetTable\\\":true}\"' \\\n-F 'file=@\"/Users/xxxxxxxxxxxxx/Downloads/filename.csv\"'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Date formatting"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are several formats you can use when importing data into a date-type column."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Integers"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yyyy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yy"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These formats require the month to precede the day (i.e., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dd/mm/yy"
      }), " is not accepted). Integers can be separated by hyphens (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") or slashes (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Relaxed dates"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also import date formats that are less standardized than integer-based dates. For example:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "The 1st of March in the year 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Fri Mar 4 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "March 4th '22"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Relative dates"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will parse the following date formats relative to the current day:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "next Thursday"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "tomorrow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "3 days from now"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Reset options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When importing data from a CSV file into a HubDB table, you can set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "resetTable"
      }), " field to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " (default) to manage whether HubDB row data is overwritten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ":"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If the rows in the CSV file does not have a row ID column (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), " or row ID is specified as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", those rows will be inserted with the new row IDs generated."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If the row IDs in the CSV file already exists in the target table, the existing rows in the table will be updated with the new values from the input file."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If the table has rows but the input CSV file does not have those row IDs, those rows will be deleted from the target table."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If the row IDs in the input CSV file do not exist in the target table, those rows will be inserted with the new row IDs generated and the row IDs given in the input file will be ignored."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If the input CSV file does not contain the row ID column at all, all the rows will be deleted from the target table and the rows from the input file will be inserted with the new row IDs generated."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), " (default):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If the row IDs in the CSV file already exists in the target table, the existing rows in the table will be updated with the new values from the input file."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If the table has rows but the input CSV file does not have those row IDs, those rows will ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "not"
            }), " be deleted from the target table and those rows will remain unchanged."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If the row IDs in the input CSV file do not exist in the target table, those rows will be inserted with the new row IDs generated and the row IDs given in the input file will be ignored."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If the rows in the CSV file does not have a row ID column or row ID is specified as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", those rows will be inserted with the new row IDs generated."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve HubDB data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are multiple ways to retrieve HubDB data, depending on whether you're looking for table details or the rows of a table:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve table details from all published tables, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve table details from a specific published table, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables/{tableIdOrName}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all rows from a specific table, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a specific row from a table, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows/{rowId}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When retrieving row data, you can further filter and sort the results."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a table is set to be ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "allowed for public access"
      }), ", you can access the published version of the table and rows without any authentication by specifying your HubSpot account ID via the query parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Filter returned rows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When retrieving HubDB table data, you can apply filters as query parameters to receive specific data. Filter query parameters are constructed as follows: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnName__operator"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you have a number column named ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), ", you can filter results to only include rows where ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), " is greater than 10: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&bar__gt=10"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "All filters are ANDed together (OR filters are not currently supported)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When filtering, keep the following in mind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["When passing values for ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect"
          }), " columns, the values should be comma-separated (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect_column__contains=1,2"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), " filters, you can use relative dates in place of timestamps in order to specify a value relative to the current time. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "-3h"
          }), " would correspond to the timestamp 3 hours before now, whereas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "10s"
          }), " would correspond to 10 seconds in the future. Supported time units are ms (milliseconds), s (seconds), m (minutes), h (hours), d (days). Current time can be used by specifying a zero value: 0s"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For the purposes of these filters, the built in column ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_id"
          }), " is a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "number"
          }), " column, the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_created_at"
          }), " column is a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "datetime"
          }), ", and the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_path"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_name"
          }), " columns are ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          }), " columns."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn which operators can be applied to which column types:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq (or none)"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Equals"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All column types.This filter is applied if no operator is used. When used with multiselect columns, returns rows that exact match supplied values."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not equal to"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All column types."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contains"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Text, richtext, and multiselect.When used with multiselect columns, returns rows that contain all of the supplied values. This filter is ", (0, _jsxRuntime.jsx)("u", {
              children: "case sensitive"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number, date, and datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than or equal to"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number, date, and datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number, date, and datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than or equal to"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number, date, and datetime."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Null"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["All column types except boolean.This filter doesn't require a value (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__is_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not null"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["All column types except boolean.This filter doesn't require a value (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__not_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Like"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text and richtext."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not like"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text and richtext."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contains"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Text and richtext.This filter is ", (0, _jsxRuntime.jsx)("u", {
              children: "case insensitive"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Starts with"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text and richtext."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "In"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Number, select, and multiselect.Returns rows where the column includes at least one of the passed options. When there is no other ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            }), " in the query parameter, the results will be sorted in the order in which values are specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), " operator."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sort returned rows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When retrieving HubDB data, you can apply sorting as a query parameter to determine the order of the returned data. To sort data, add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort"
      }), " query parameter and specify the column name:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=columnName"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, data will be returned in the natural order of the specified column. You can reverse the sort by adding a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), " to the column name:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=-columnName"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can include this parameter multiple times to sort by multiple columns."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to sorting by a column, there are three functions that can be used:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "geo_distance(location_column_name, latitude, longitude):"
          }), " takes the name of a location column and coordinates, returns the rows ordered by how far away the values of the specified location column are from the provided coordinates."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "length(column_name):"
          }), " takes the name of a column, returns the rows ordered by the length of the column value (calculated as a string)"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "random():"
          }), " returns the rows in random order."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These functions also support reverse ordering. For example, the following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " sort returns items that are the farthest away first:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-geo_distance(location_column,42.37,-71.07)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuring HubDB tables for dynamic pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using HubSpot's CMS, you can use a HubDB table as a data source to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "generate dynamic pages"
      }), ". For example, you can create a table that contains a row for each member of your executive team, with columns containing information that you want to display on a page. After selecting that table as the dynamic data source for a page, that page will generate a listing page that displays all rows as summary items, along with separate pages for each row, similar to a blog listing page and blog post pages."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To enable a table to be selected as a data source in the content editor, you'll need to set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useForPage"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". You can optionally include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamicMetaTags"
      }), " to specify which columns to use for each page's metadata."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when specifying ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamicMetaTags"
        }), ", you'll need to ensure that the page is using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " HubL tags instead of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". Learn more in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb#metadata",
          children: "dynamic pages guide"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the code below would create a table that can be used for dynamic pages, and specifies the three columns to use for page metadata."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to create table\n{\n  \"name\": \"dynamic_page_table\",\n  \"label\": \"Dynamic page table\",\n  \"useForPages\": true,\n  \"columns\": [\n    {\n      \"name\": \"meta_description\",\n      \"label\": \"Meta description\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"featured_image\",\n      \"label\": \"Featured image\",\n      \"archived\": false,\n      \"type\": \"IMAGE\"\n    },\n    {\n      \"name\": \"canonical_url\",\n      \"label\": \"Canonical URL\",\n      \"archived\": false,\n      \"type\": \"URL\"\n    }\n  ],\n  \"dynamicMetaTags\": {\n    \"DESCRIPTION\": 1,\n    \"FEATURED_IMAGE_URL\": 2,\n    \"LINK_REL_CANONICAL_URL\": 3\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " to enable the table to be used as a data source for dynamic pages."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamicMetaTags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specifies the columns by ID to use for metadata on each dynamic page. Can contain:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DESCRIPTION"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FEATURED_IMAGE_URL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINK_REL_CANONICAL_URL"
                })
              })]
            }), "For any metadata fields not specified, pages will inherit the respective values from its parent page."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DESCRIPTION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The numeric ID of the column to use for each page's meta description."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FEATURED_IMAGE_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The numeric ID of the column to use for each page's featured image URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_REL_CANONICAL_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The numeric ID of the column to use for each page's canonical URL."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Changes in v3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tables should have both ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ". This name cannot be changed once the table is created. Names can only include lowercase letters, digits, and underscores and cannot begin with a number. Both ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " should be unique in the account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["API supports both table ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " in the URL paths."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " row endpoints return column ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " instead of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), " field. Also, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " row endpoints require column ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " instead of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), " field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Row update ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " endpoints now accept sparse updates, which means you can specify only the column values that you need to update (whereas you had to specify all the column values in the previous versions). When you update a column with a list of values such as multiselect, you need to specify the list of all the values. In order to delete the value for a column, you need to specify the column with the value as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " in the request."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Removed the endpoints to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "update"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "delete"
        }), " a row cell in favor of the row update ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " endpoints."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Import endpoint now supports an optional field ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "idSourceColumn"
        }), " along with existing fields in the JSON-formatted options. You can use this field to specify the column in the csv file which contains row ids. To import new rows along with the new values for existing rows, you can simply specify ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " as the row id for the new rows and the valid row ids for the existing columns. See more details in the Import section below. Also you can use column names or ids in the target field of the column mappings in the JSON-formatted options."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Clone endpoint requires a new name and new label."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}