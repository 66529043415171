import { na1 } from 'hubspot-url-utils/hublets';
import { registerQuery } from 'data-fetching-client';
// TODO: Authed client when available?
// import apiClient from 'hub-http/clients/apiClient';
import noAuthClient from 'hub-http/clients/noAuthApiClient';
import { hubletApi } from 'hub-http/middlewares/core';

// @ts-expect-error Untyped
import { SpecificationActionTypes } from 'developer-docs-shared-components/modules/Doc/actions';
const API_CATALOG_PUBLIC_BASE = '/api/api-catalog-public/';
export const FETCH_SPECIFICATION = registerQuery({
  fieldName: 'specification',
  args: ['dispatch', 'endpoint'],
  fetcher({
    dispatch,
    endpoint
  }) {
    console.log('Fetching:', endpoint);
    return noAuthClient.get(`${API_CATALOG_PUBLIC_BASE}${endpoint}`, {
      query: {
        swaggerVersion: 3
      },
      // API_CATALOG_PUBLIC_BASE is only deployed to the na1 hublet
      api: hubletApi('app', 'hubspot', na1)
    }).then(data => {
      dispatch({
        type: SpecificationActionTypes.SPECIFICATION_FETCH_SUCCEEDED,
        payload: {
          data
        }
      });
      return data;
    });
  }
});