"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817161640;
const slug = exports.slug = 'guides/apps/public-apps/overview';
const title = exports.title = 'Aplicativos públicos';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Creating and installing apps';
const metaDescription = exports.metaDescription = 'Saiba como criar e instalar um aplicativo no HubSpot. Os aplicativos são integrações que podem ser instaladas em contas de clientes por meio de um processo de conexão do OAuth.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-um-aplicativo-p%C3%BAblico",
  "label": "Criar um aplicativo público",
  "parent": null
}, {
  "depth": 1,
  "id": "configurar-escopos",
  "label": "Configurar escopos",
  "parent": "criar-um-aplicativo-p%C3%BAblico"
}, {
  "depth": 2,
  "id": "tipos-de-escopo",
  "label": "Tipos de escopo",
  "parent": "criar-um-aplicativo-p%C3%BAblico"
}, {
  "depth": 2,
  "id": "configurar-os-escopos-de-aplicativos-p%C3%BAblicos",
  "label": "Configurar os escopos de aplicativos públicos",
  "parent": "criar-um-aplicativo-p%C3%BAblico"
}, {
  "depth": 0,
  "id": "instalar-um-app",
  "label": "Instalar um app",
  "parent": null
}, {
  "depth": 1,
  "id": "autorize-o-aplicativo-com-uma-conta-de-cliente.",
  "label": "Autorize o aplicativo com uma conta de cliente.",
  "parent": "instalar-um-app"
}, {
  "depth": 1,
  "id": "gerar-tokens-iniciais-do-oauth",
  "label": "Gerar tokens iniciais do OAuth",
  "parent": "instalar-um-app"
}, {
  "depth": 0,
  "id": "gerenciar-aplicativos-p%C3%BAblicos-no-hubspot",
  "label": "Gerenciar aplicativos públicos no HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "find-an-app-s-id",
  "label": "Find an app's ID",
  "parent": "gerenciar-aplicativos-p%C3%BAblicos-no-hubspot"
}, {
  "depth": 1,
  "id": "monitorar-o-comportamento-do-aplicativo",
  "label": "Monitorar o comportamento do aplicativo",
  "parent": "gerenciar-aplicativos-p%C3%BAblicos-no-hubspot"
}, {
  "depth": 0,
  "id": "adicionar-um-dom%C3%ADnio-verificado",
  "label": "Adicionar um domínio verificado",
  "parent": null
}, {
  "depth": 1,
  "id": "adicionar-um-dom%C3%ADnio-verificado",
  "label": "Adicionar um domínio verificado",
  "parent": "adicionar-um-dom%C3%ADnio-verificado"
}, {
  "depth": 2,
  "id": "observa%C3%A7%C3%B5es-adicionais",
  "label": "Observações adicionais",
  "parent": "adicionar-um-dom%C3%ADnio-verificado"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      img: "img",
      h3: "h3",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Aplicativos públicos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na HubSpot, um aplicativo público é um tipo de integração que pode ser instalada nas contas de clientes ou listadas no Marketplace de aplicativos. Isso requer autenticação por ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". Assim que um usuário ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#installing-apps",
        children: "instalar"
      }), " o app em sua conta da HubSpot, você poderá fazer chamadas de API para essa conta usando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "token de acesso OAuth"
      }), ". O app também aparecerá nas configurações ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Apps conectados"
      }), " da conta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os aplicativos conectados também podem fazer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "a assinatura de alterações usando webhooks"
      }), " e criar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "eventos de linha do tempo personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, aprenda como:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-public-app",
          children: "Criar um aplicativo público"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#install-an-app",
          children: "Instalar um aplicativo público em uma conta"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#manage-public-apps-in-hubspot",
          children: "Gerenciar o aplicativo, inclusive o monitoramento do uso"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "Adicionar um domínio verificado ao aplicativo"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um aplicativo público"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar um app na HubSpot, você basicamente associa o app criado com uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "conta de desenvolvedor"
      }), ". Para começar a criar seu app da HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor de apps, navegue até ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Em seguida, preencha algumas informações básicas e configurações do app. Quando os usuários autenticarem o aplicativo na conta da HubSpot, eles verão o nome, a descrição, o logotipo e qualquer informação de contato de suporte que você fornecer nesta página."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": o nome do app será usado sempre que ele for exibido na HubSpot. Isso inclui a instalação do app, bem como o rodapé ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Fornecido por"
        }), " para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "cartões CRM"
        }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/timeline",
          children: "eventos de linha do tempo"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/new_app_setup.png?width=600&name=new_app_setup.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autenticação"
        }), " para visualizar o ID e o segredo do cliente, bem como os escopos atribuídos ao aplicativo. Você precisará dessas informações ao", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "iniciar uma conexão OAuth"
        }), " entre o aplicativo e o HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/app_auth_settings.png?width=600&name=app_auth_settings.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar escopos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os escopos determinam as permissões do seu aplicativo para acessar as fontes de dados ou ferramentas em uma conta que tenha instalado o aplicativo. Os escopos configurados aparecerão como os parâmetros de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " em um URL de instalação que você pode fornecer aos usuários."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tipos de escopo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Autenticação"
      }), ", estão disponíveis três tipos de escopos diferentes para você configurar. Você deve especificar os escopos do aplicativo necessários para a instalação, mas também pode especificar dois outros tipos de escopos: condicionalmente obrigatórios e opcionais."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Escopos obrigatórios:"
        }), " escopos que devem ser autorizados pelo usuário ", (0, _jsxRuntime.jsx)("u", {
          children: "e"
        }), " devem estar presentes no parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " no URL de instalação do aplicativo para uma instalação bem-sucedida."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Escopos condicionalmente obrigatórios:"
        }), " escopos que devem ser autorizados pelo usuário somente se estiverem presentes no parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " no URL de instalação do aplicativo para uma instalação bem-sucedida.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Este tipo de escopo permite ser flexível e fornecer um URL de instalação separado para funcionalidades ou escopos escalonados que só são obrigatórios quando os usuários ativam determinadas funcionalidades no seu aplicativo. Por exemplo, você pode oferecer dois URLs de instalação aos seus usuários: um URL de instalação pode incluir o escopo condicionalmente obrigatório no parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " para usuários com acesso a uma funcionalidade, enquanto outro URL de instalação omite esse escopo no parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " para usuários sem acesso."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se um escopo condicionalmente obrigatório estiver presente no URL de instalação do seu aplicativo e um usuário sem acesso à funcionalidade associada tentar instalar o aplicativo utilizando esse URL, a instalação falhará."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Escopos opcionais:"
        }), " escopos que ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " são obrigatórios para instalar o aplicativo com êxito. Estes escopos são especificados no parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " no URL de instalação do seu aplicativo. Por exemplo, se quiser que seu aplicativo possa obter dados de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "objetos personalizados"
        }), " (que só estão disponíveis para contas ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " da HubSpot), poderá adicionar o escopo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.read"
        }), " como um escopo opcional. Depois, se uma conta tiver acesso aos objetos personalizados, o escopo será autorizado. Caso contrário, ainda será possível instalar o aplicativo sem o escopo dos objetos personalizados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Configurar os escopos de aplicativos públicos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para personalizar as configurações do seu escopo e adicionar novos escopos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para configurar os escopos condicionalmente obrigatórios ou opcionais, clique para ativar a opção ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ativar configurações avançadas de escopo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab.png",
        alt: "turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observe:"
        }), " a partir de 21 de outubro de 2024, serão necessárias configurações avançadas de escopo para todos os aplicativos. Saiba mais no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/advanced-auth-and-scope-settings-for-public-apps",
          children: "Registro de alterações para desenvolvedores"
        }), " da HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Escopos"
        }), ", clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar novo escopo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No painel direito, utilize a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "barra de pesquisa"
        }), " para procurar um escopo e, em seguida, marque a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "caixa de seleção"
        }), " ao lado de qualquer escopo que pretenda que o usuário autorize. Se você ativou as configurações avançadas de escopo, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menu suspenso"
        }), " ao lado do escopo e selecione um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tipo de escopo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Atualizar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/add-new-scope-panel-in-public-app-setup.png",
        alt: "add-new-scope-panel-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revise os escopos configurados. Se você ativou as configurações avançadas de escopo, poderá mudar o tipo de escopo de qualquer escopo clicando no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menu suspenso"
        }), " ao lado do escopo. Você também pode clicar em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Excluir"
        }), " para remover um dos escopos do seu aplicativo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/review-new-scope-settings-in-public-app-setup.png",
        alt: "review-new-scope-settings-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quando tiver terminado de definir as configurações do aplicativo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar aplicativo"
        }), " no canto inferior esquerdo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o seu app criado, agora é possível realizar o processo de instalação."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " é recomendável ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "adicionar um domínio verificado"
        }), " ao aplicativo para incluir outro nível de confiança para os usuários que instalarem o aplicativo. Caso contrário, o aplicativo exibirá um banner informando que não foi verificado."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instalar um app"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " antes de instalar seu app, lembre-se do seguinte:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Um app não aparecerá na página ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Apps conectados"
          }), " da conta até os tokens de acesso iniciais e de atualização serem criados."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Um aplicativo só poderá ser instalado por usuários com acesso aos escopos obrigatórios ou condicionalmente obrigatórios de um aplicativo."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Não é possível instalar aplicativos em contas de desenvolvedor. Para testar o app, crie uma ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types",
            children: "conta de teste"
          }), " em sua conta de desenvolvedor do app e instale-a ali."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A instalação de um app pode ser dividida em duas etapas: autorização e geração de token."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Autorize o aplicativo com uma conta de cliente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para autorizar o aplicativo com uma conta da HubSpot, você precisará criar um URL de autorização. Para fazer isso, obtenha o ID de cliente do aplicativo e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "inicie o processo do OAuth"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Depois que o URL estiver pronto, abra-o no navegador para ver uma lista de todas as suas contas da HubSpot. É exatamente isso que os usuários verão quando você direcioná-los para esse URL."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "conta"
        }), " em que deseja instalar o app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/select_account-1.webp",
        alt: "select_account-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depois de escolher uma conta, você verá uma lista de escopos com base nos parâmetros ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&scope="
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&optional_scope="
        }), " definidos para o URL de autorização."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se você incluir ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " e a conta selecionada não tiver acesso a ele (como o escopo do conteúdo para uma conta somente do CRM), ele não será listado."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conceder acesso"
        }), " para autorizar a conexão."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/approve_scopes-1.webp",
        alt: "approve_scopes-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depois que o acesso for concedido, você será redirecionado de acordo com o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&redirect_uri="
        }), " no URL de autorização original, e um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?code="
        }), " será anexado ao URL. Use esse código na próxima etapa para gerar um token de acesso."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gerar tokens iniciais do OAuth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para gerar seus tokens de acesso iniciais e de atualização, você precisará do código do parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?code="
      }), " do URL de autorização, do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirect_url"
      }), " e do ID e do segredo do cliente. Você encontrará instruções detalhadas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de autorizar o aplicativo e gerar os tokens iniciais, a instalação estará concluída. Ele será listado em sua página ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/integrations-beta",
        children: "Apps conectados"
      }), " e você começará a receber solicitação de busca de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "webhook"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "cartões CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/connected_apps-1.webp",
        alt: "connected_apps-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gerenciar aplicativos públicos no HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Find an app's ID"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode encontrar o ID de um aplicativo público na sua conta de desenvolvedor de aplicativos, usando um dos métodos a seguir:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos"
        }), " na barra de navegação principal e visualize o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID do aplicativo"
        }), " listado abaixo do nome do seu aplicativo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id.png",
        alt: "find-app-id"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos"
        }), " na barra de navegação principal e clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do aplicativo. Na página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações básicas"
        }), ", clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autenticação"
        }), " e visualize o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID do aplicativo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id-auth-settings.png",
        alt: "find-app-id-auth-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Monitorar o comportamento do aplicativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot registra todas as solicitações feitas para um app conectado, incluindo solicitações recebidas usando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "token de acesso OAuth"
      }), " ou solicitações enviadas para webhooks ou cartões de CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para visualizar este registro de solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu da barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitoramento"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use as ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "guias"
        }), " para visualizar diferentes tipos de solicitações feitas para ou por meio do app. Ao visualizar esses registros, você pode clicar em uma solicitação individual para visualizar mais informações sobre ela, incluindo:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["para solicitações ", (0, _jsxRuntime.jsx)("u", {
              children: "bem-sucedidas"
            }), ", o método, o caminho e o horário da solicitação."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["para solicitações ", (0, _jsxRuntime.jsx)("u", {
              children: "malsucedidas"
            }), ", informações de erro adicionais, como cabeçalhos de resposta e corpo."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/request_details.webp",
        alt: "request_details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abaixo, saiba mais sobre cada guia da página ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Monitoramento"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Chamadas de API:"
        }), " a guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Chamadas de API"
        }), " mostrará todas as solicitações feitas no aplicativo usando um token de acesso de OAuth. Ele pode ser filtrado por método HTTP, código de resposta, período ou URL de solicitação."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks:"
        }), " a guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Webhooks"
        }), " mostra as solicitações do HubSpot para todas as suas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "assinaturas de webhook"
        }), " do aplicativo. Filtre por resposta (inclusive tempo limite e falhas de conexão), status (sucesso, nova tentativa ou falha), tipo de assinatura, período de tempo, tentativa, lote, evento ou ID da conta."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o ID da tentativa é uma combinação de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriptionId"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "eventId"
        }), ", e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "attemptNumber"
        }), " de uma solicitação específica."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Extensões do CRM:"
        }), " a guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Extensões do CRM"
        }), " mostra as solicitações para os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "Cartões de CRM"
        }), " do aplicativo. Filtre por tipo de objeto de extensão, tipo de objeto de CRM (contato, empresa, ticket ou negócio), erro ou tipo de aviso, período de tempo, ID da solicitação ou ID de registro de CRM (ou seja, um ID de contato específico)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configurações do aplicativo:"
        }), " a guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Configurações do aplicativo"
        }), " permite configurar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/create-an-app-settings-page",
          children: "página de configurações"
        }), " do aplicativo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em cada guia, se algum evento associado ocorreu nos últimos 30 dias (por exemplo, um gatilho de webhook ou uma chamada de API), você pode clicar em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exportar registros"
      }), " para exportar os dados de evento associados para um CSV:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Na caixa de diálogo, configure quantos dias de dados devem ser exportados (até 30 dias)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), ". Será enviada uma notificação por e-mail para o endereço de e-mail associado ao seu usuário nas suas configurações da HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar um domínio verificado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando os usuários do HubSpot instalam um aplicativo, eles consentem o acesso aos dados de suas contas ao desenvolvedor do aplicativo. A identidade e a reputação do desenvolvedor desempenham um papel importante na decisão de um usuário de continuar com a instalação. Para garantir o consentimento total do usuário ao instalar um aplicativo, a HubSpot exibirá uma mensagem na tela de instalação do aplicativo para indicar seu nível de verificação e a listagem no Marketplace de aplicativos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Se um aplicativo não tiver um domínio verificado, a HubSpot exibirá um banner na tela de instalação, informando que o aplicativo não foi verificado."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/not-verified.png",
            alt: "not-verified"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Se o aplicativo tiver um domínio verificado, mas não estiver ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "listado no Marketplace de aplicativos"
          }), ", o HubSpot exibirá o domínio verificado junto com um banner na tela de instalação, informando que o aplicativo não foi revisado ou aprovado pela HubSpot."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-not-listed.png",
        alt: "verified-not-listed"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Quando um aplicativo for listado no marketplace e aprovado pelo processo de revisão do aplicativo da HubSpot, o HubSpot não exibirá nenhum dos banners acima. Não é necessário verificar o domínio se o aplicativo tiver sido listado no Marketplace de aplicativos."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-and-listed.png",
            alt: "verified-and-listed"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar um domínio verificado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para adicionar um domínio verificado ao aplicativo, você precisará primeiro adicionar o domínio às configurações do aplicativo e, em seguida, um registro TXT às configurações de DNS do domínio:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor de aplicativos, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contato e suporte"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Domínio da empresa"
        }), ", insira seu domínio e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Salvar"
        }), ". Uma mensagem será exibida abaixo do ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Domínio da empresa"
        }), ", informando que o domínio ainda não foi verificado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verificar agora"
        }), " para iniciar o processo de verificação."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/domain-verification-for-app.png",
        alt: "domain-verification-for-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No painel direito, confirme se o domínio foi inserido corretamente e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Próximo"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Copie o valor do registro de TXT exigido, clicando em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar"
          }), " na coluna ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Valor"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verify-app-domain-copy-value.png",
            alt: "verify-app-domain-copy-value"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "No seu provedor de DNS, crie um registro de TXT com o valor copiado. Veja a seguir as instruções para alguns provedores de DNS comuns:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.godaddy.com/help/add-a-txt-record-19232",
              children: "GoDaddy"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://my.bluehost.com/hosting/help/559#add",
              children: "BlueHost"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.namecheap.com/support/knowledgebase/article.aspx/317/2237",
              children: "Namecheap"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare#h_60566325041543261564371",
              children: "Cloudflare"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.hover.com/hc/en-us/articles/217282457-How-to-Edit-DNS-records-A-AAAA-CNAME-MX-TXT-SRV-",
              children: "Hover"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.name.com/support/articles/115004972547-Adding-a-TXT-Record",
              children: "Nome"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.uniteddomains.com/hc/en-us/articles/115000887125-How-to-set-up-a-TXT-record-on-a-domain-name",
              children: "Domínios unidos"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Depois de atualizar as configurações de DNS, volte ao HubSpot e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Próximo"
          }), " no painel direito. Os registros de DNS podem levar até 48 horas para serem atualizados, portanto, o HubSpot pode não reconhecer a alteração imediatamente. Você pode voltar a essa tela a qualquer momento, selecionando novamente a opção ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Verificar agora"
          }), " na página de configurações ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informações da empresa"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Depois da verificação, será exibido um indicador de status de sucesso no campo ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Domínio da empresa"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Domain%20verified__export.png",
        alt: "verified__export do domínio"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Observações adicionais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para garantir a propriedade contínua do domínio, o HubSpot continuará a verificar se o registro TXT está presente regularmente. O aviso de instalação retornará se o registro TXT for removido ou modificado."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Atualmente, você pode ter apenas um domínio verificado por conta de desenvolvedor. Todos os aplicativos em uma conta compartilham o domínio verificado. O domínio na página de instalação será vinculado ao seu domínio raiz."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você excluir seu domínio verificado, todos os aplicativos da sua conta de desenvolvedor receberão o aviso de instalação novamente. Você pode verificar outro domínio, mas o processo levará algumas horas."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}