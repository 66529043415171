"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044591;
const slug = exports.slug = 'guides/cms/setup/github-integration';
const title = exports.title = 'Configurar la integración continua con un repositorio de GitHub mediante GitHub Actions';
const name = exports.name = 'LATAM (ES) Setting up continuous integration with a GitHub repository using GitHub Actions';
const metaDescription = exports.metaDescription = 'Configurar la integración continua con un repositorio de GitHub mediante GitHub Actions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "enviar-archivos-locales-a-github",
  "label": "Enviar archivos locales a GitHub",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-la-acci%C3%B3n-hubspot-cms-deploy-github-recomendado",
  "label": "Usar la acción HubSpot CMS Deploy GitHub (recomendado)",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-y-combinar-una-solicitud-de-extracci%C3%B3n-en-main",
  "label": "Crear y combinar una solicitud de extracción en main",
  "parent": null
}, {
  "depth": 0,
  "id": "bloquear-tu-material-en-el-administrador-de-dise%C3%B1o",
  "label": "Bloquear tu material en el administrador de diseño",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurar la integración continua con un repositorio de GitHub mediante GitHub Actions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como parte de tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "workflow de desarrollo"
      }), ", es posible que prefieras mantener tu código fuente de verdad en el control de versiones. Esto sería especialmente útil si trabajas como parte de un equipo de desarrollo para que puedas hacer seguimiento de los cambios y revertirlos rápidamente si es necesario."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/features/actions",
        children: "GitHub Actions"
      }), ", puedes configurar una integración continua con un repositorio de GitHub. Esta guía recorre el proceso de integración y asume que estás familiarizado con:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.github.com/en/get-started/using-git",
          children: "El uso de Git"
        }), " y GitHub"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La creación de sitios web usando la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLI de HubSpot"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, descubre cómo configurar la integración utilizando HubSpot CMS Deploy GitHub Action (recomendado) o manualmente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enviar archivos locales a GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de poder integrarte con GitHub, primero deberás recopilar tus archivos localmente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si tienes un material de CMS existente que reside en HubSpot, como un tema o un conjunto de plantillas, puedes obtenerlo ejecutando el comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cli#fetch",
          children: "fetch"
        }), " de la siguiente manera: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch<HubSpot_src> <local_dest>"
        }), ". De manera alternativa, puedes descargar todos los archivos en el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "sistema de archivos de desarrollador"
        }), " de la cuenta ejecutando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch /"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para crear un nuevo proyecto local, se recomienda comenzar con el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "boilerplate del tema de CMS"
        }), ". Si no has trabajado con el tema de CMS antes, echa un vistazo a la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "guía de inicio rápido"
        }), ". Si ya instalaste la CLI de HubSpot y configuraste tu entorno local, puedes crear un nuevo tema local desde el boilerplate ejecutando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs create website-theme<new-theme-name>"
        }), ". Debes subir tus archivos a HubSpot con el comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cli#upload",
          children: "hs upload"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con tu código disponible localmente, lo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.github.com/en/get-started/importing-your-projects-to-github/importing-source-code-to-github/adding-locally-hosted-code-to-github",
        children: "agregarás a un repositorio de GitHub"
      }), ". Después de agregar tus archivos a GitHub, continúa con el siguiente paso para instalar la GitHub Action prefabricada de HubSpot (recomendada) o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manually-configure-the-action",
        children: "configurar la Acción manualmente"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar la acción HubSpot CMS Deploy GitHub (recomendado)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agilizar el proceso, HubSpot creó una GitHub Action que puedes instalar en tu proyecto de GitHub para gestionar la implementación automática de los cambios de una rama a tu cuenta de producción de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "e0132707-395d-4617-bd9d-0b21c8b129d5",
      external: true,
      label: "Install automatic CMS deploy GitHub action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear y combinar una solicitud de extracción en main"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Con tus secretos, workflows y scripts en tu repositorio de GitHub, crea una solicitud de extracción y combínala en main."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de combinar la solicitud de extracción, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acciones"
        }), ". Deberías ver cómo se ejecuta tu acción de implementación, que luego implementará tu código en tu cuenta de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bloquear tu material en el administrador de diseño"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ahora que tu fuente de referencias reside en GitHub, debes bloquear tu material en HubSpot para evitar que se realicen modificaciones allí. Esto garantiza que los cambios solo lleguen a través de la acción de implementación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para bloquear materiales en el administrador de diseño:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivos y plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Herramientas de diseño"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Localiza la carpeta de tu activo en la barra lateral izquierda, luego ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "haz clic con el botón derecho"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bloquear carpeta"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-lock-folder.png",
        alt: "design-manager-lock-folder"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}