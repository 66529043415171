"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52099237782;
const slug = exports.slug = 'guides/api/cms/source-code';
const title = exports.title = 'CMS | ソースコードAPI';
const name = exports.name = 'APAC JAPAN (ja) | vNext Docs DP - Source Code API_JA | 202109';
const metaDescription = exports.metaDescription = 'HubSpotデベロッパー ファイル システム内で、ファイルを作成、取得、更新、または削除します。また、ファイルにエラーや警告がないかを検証できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%9F%BA%E6%9C%AC%E6%A9%9F%E8%83%BD",
  "label": "基本機能",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%92%B0%E5%A2%83%E3%81%A8%E3%83%91%E3%82%B9",
  "label": "環境とパス",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "ファイルをダウンロードする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%A8%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC%E3%81%AE%E3%83%A1%E3%82%BF%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "ファイルとフォルダーのメタデータを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "ファイルをアップロードする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E5%86%85%E5%AE%B9%E3%82%92%E6%A4%9C%E8%A8%BC%E3%81%99%E3%82%8B",
  "label": "ファイルの内容を検証する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "ファイルを削除する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%83%91%E3%83%83%E3%82%B1%E3%83%BC%E3%82%B8%E3%82%92%E5%B1%95%E9%96%8B%E3%81%99%E3%82%8B",
  "label": "ファイルパッケージを展開する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMSソースコード"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "基本機能"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMSソースコードAPIを使用すると、HubSpot", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/key-concepts#developer-file-system",
        children: "デベロッパー ファイル システム"
      }), "に格納されたファイルを操作できます。これらのファイルには、デザインマネージャーに表示されるテンプレート、モジュール、CSS、JSなどの全てのCMSアセットが含まれます。このAPIでは次の操作ができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "新しいファイルをHubSpotアカウントにアップロードするか、既存のファイル対する変更をアップロードします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CMSアセットをアカウントからダウンロードまたは削除します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "デザインマネージャー内の各ファイルまたは各フォルダーのメタデータを取得します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotのCMS上のファイルの内容（HubL構文を含む）を検証します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "zipファイルをファイルパッケージとしてアップロードし、アカウント内に展開します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このAPIでは、全てのCMSアセットをローカル環境で編集するとともに、作業の迅速化に役立つツールを作成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "環境とパス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ソースコードAPIエンドポイントでは、CMSデベロッパー ファイル システム内でのファイルの特定に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "environment"
      }), "パラメーターと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "path"
      }), "パラメーターを使用します。このパラメーターは通常、エンドポイントパス自体に、例えば", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), "のように指定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "environment"
      }), "パラメーターは、各ファイルの未公開バージョンまたは公開バージョンのいずれを操作するかを示します。未公開のバージョンの場合は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), "を使用します。公開中のバージョンの場合は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "published"
        }), "へのアップロードはデザインマネージャー上で［公開］ボタンを押すことに相当する点に注意してください。そのため、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "draft"
        }), "にある全ての内容が消去されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "path"
      }), "パラメーターは、CMSデベロッパー ファイル システムでのファイルの位置を示します。UNIXベースのオペレーティングシステムとは異なり、最上位レベルのアセットの前に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), "文字は付きません。新しいファイルをアップロードする際には、ファイルの作成先となる場所を指定する必要があります。既存のファイルを取得する、または既存のファイルに対する変更をアップロードする場合は、既存のファイルのパスに一致している必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのアセットタイプに、ローカルのファイル形式が使用されます。つまりアセットが、タイプによっては複数のファイルで構成されます。例えば、モジュールは実際には", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), "サフィックスが末尾に付くディレクトリーとして表されます。このため、モジュールのHTMLを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "foo.module/module.html"
      }), "のようなパスを使用する必要があります。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cms-cli",
        children: "ローカル開発のドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルをダウンロードする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウントからは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), "エンドポイントを使用してファイルをダウンロードできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このためには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "application/octet-stream"
      }), "コンテンツタイプを受け入れるHTTP GETリクエストを使用します。これにより、指定したパスの実際のバイナリーファイルの内容をダウンロードできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "例："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ファイルのダウンロード："
        }), " GET ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/published/content/my-file.js"
        }), " Accept: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/octet-stream"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "フォルダーの内容全体をダウンロードすることはできません。代わりに、フォルダーのメタデータを取得し（以下を参照）、子を個別に取得する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルとフォルダーのメタデータを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/metadata/{path}"
      }), "エンドポイントを使用することで、HubSpotアカウント上のファイルまたはフォルダーのメタデータ（パス、ファイル名、作成／更新時のタイムスタンプなど）を取得できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このためには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "application/json"
      }), "コンテンツタイプを受け入れるHTTP GETリクエストを使用します。これにより、そのファイルの関連メタデータを含むJSONオブジェクトを取得できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フォルダーのメタデータは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "folder: true"
      }), "プロパティーにより示されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "children"
      }), "配列は、フォルダー内のファイルとサブフォルダーの名前を示します。これらのファイル名をフォルダーツリーの走査に使用します。1つのフォルダーのメタデータを取得し、そのフォルダーの子と全てのサブフォルダーを再帰的に取得します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "例："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "フォルダーのメタデータを取得する："
        }), " GET ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/published/metadata/my-folder"
        }), " Accept: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルをアップロードする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウントには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), "エンドポイントを使用してローカルのファイルをアップロードできます。ファイルは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), "コンテンツタイプを指定したHTTP PUTリクエストによってアップロードする必要があります。バイナリー ファイル データを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "file"
      }), "という名前のフィールドとして含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "例："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "新規ファイルをアップロードする："
        }), " PUT ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/published/content/my-new-file.html"
        }), " Content-Type: multipart/form-dataフォームデータ：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{ file: [バイナリー ファイル データ] }"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "既存の下書きファイルを更新する："
        }), " PUT ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/draft/content/path/to/existing-file.html"
        }), " Content-Type: multipart/form-dataフォームデータ：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{ file: [バイナリー ファイル データ] }"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotでは現在、以下のCMSアセット ファイル タイプをサポートしています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "css"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "js"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "html"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "txt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "md"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "jpg"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "jpeg"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "png"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "gif"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "map"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "svg"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ttf"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "woff"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "woff2"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルの内容を検証する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルファイルの内容は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/validate/{path}"
      }), "エンドポイントを使用して検証できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは、テンプレート／モジュール、またはテーマまたはモジュールのJSON内のHubLの検証に使用できます。これにより、デザインマネージャーに表示される警告とエラーが示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["無効なファイルは直接発行しようとしても拒否される点に注意してください。", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "公開前にまずファイルを検証することをお勧めします。"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), "コンテンツタイプを指定したHTTP POSTリクエストによって送信する必要があります。バイナリー ファイル データを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "file"
      }), "という名前のフィールドとして含める必要があります。検証にエラーがあった場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), "レスポンスと関連するエラーのリストが返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "例："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "新規ファイルをアップロードする："
        }), " POST ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/published/validate/my-file.html"
        }), " Content-Type: multipart/form-dataフォームデータ：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{ file: \\[バイナリー ファイル データ\\] }"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウントからは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/{environment}/content/{path}"
      }), "エンドポイント経由でHTTP DELETEリクエストを使用してファイルを削除できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), "（公開）環境から削除すると、ファイルは完全に削除されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), "（下書き）環境から削除すると、未公開の変更が消去されるだけです。公開ファイルを削除すると、使用されていたファイルが公開中のコンテンツに即座に影響を与えます。このため、ファイルに対する全ての参照を削除してから、ファイルを削除してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "例："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ファイルを削除する："
        }), " DELETE ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/source-code/published/content/my-file.html"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルパッケージを展開する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウントには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/source-code/extract/{path}"
      }), "エンドポイント経由でHTTP POSTリクエストを使用してzipファイルを展開できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この", (0, _jsxRuntime.jsx)(_components.code, {
        children: "path"
      }), "は、アカウントにアップロード済みのzipファイルである必要があります。展開プロセスは非同期で、圧縮ファイルの数とサイズに応じて最大で1分程度かかることがあります。zipの内容は、zipファイルが置かれているのと同じフォルダーに展開されます。展開が正常に完了しても、元のzipファイルは自動削除されません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "例："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**zipファイルをアップロードする： **PUT ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/cms/v3/source-code/published/content/my-package.zip"
          }), " Content-Type: multipart/form-dataフォームデータ：", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{ file: \\[バイナリー ファイル データ\\] }"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "zipファイルを展開する："
          }), " POST ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/cms/v3/source-code/extract/my-package.zip"
          })]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}