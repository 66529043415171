"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831422;
const slug = exports.slug = 'guides/cms/content/memberships/overview';
const title = exports.title = 'Adhésion';
const name = exports.name = 'EMEA FR Memberships';
const metaDescription = exports.metaDescription = "Le contrôle d'accès est une fonctionnalité qui permet d'exiger des visiteurs qu'ils possèdent un compte pour accéder à du contenu.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "flux-d-utilisateurs",
  "label": "Flux d'utilisateurs",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-hubl-de-contr%C3%B4le-d-acc%C3%A8s",
  "label": "Variables HubL de contrôle d'accès",
  "parent": null
}, {
  "depth": 0,
  "id": "fonctions-hubl-d-objet-crm",
  "label": "Fonctions HubL d'objet CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "s-inscrire%2C-se-connecter-et-se-d%C3%A9connecter",
  "label": "S'inscrire, se connecter et se déconnecter",
  "parent": null
}, {
  "depth": 0,
  "id": "mod%C3%A8les-de-contr%C3%B4le-d-acc%C3%A8s",
  "label": "Modèles de contrôle d'accès",
  "parent": null
}, {
  "depth": 0,
  "id": "journaux-d-audit-du-contr%C3%B4le-d-acc%C3%A8s",
  "label": "Journaux d'audit du contrôle d'accès",
  "parent": null
}, {
  "depth": 0,
  "id": "authentification-unique-pour-les-adh%C3%A9sions",
  "label": "Authentification unique pour les adhésions",
  "parent": null
}, {
  "depth": 1,
  "id": "connexion-via-les-r%C3%A9seaux-sociaux",
  "label": "Connexion via les réseaux sociaux",
  "parent": "authentification-unique-pour-les-adh%C3%A9sions"
}, {
  "depth": 0,
  "id": "articles-et-ressources-relatifs-au-contr%C3%B4le-d-acc%C3%A8s",
  "label": "Articles et ressources relatifs au contrôle d'accès",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Contrôles d'accès"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le contrôle d'accès est une fonctionnalité qui permet d'exiger des visiteurs qu'ils possèdent un compte pour accéder à du contenu. Ce système de compte utilise le CMS Hub et les listes du CRM ainsi que la possibilité pour un visiteur de créer un mot de passe pour son compte. Les équipes marketing peuvent facilement ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-pages-editor/control-audience-access-to-pages",
        children: "créer des pages sur leurs sites auxquels seuls les contacts inscrits sur des listes précises du CRM pourront accéder"
      }), ". Vous pouvez également restreindre l'accès ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-knowledge-base",
        children: "aux articles de votre base de connaissances"
      }), " et à vos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-blog",
        children: "articles de blog"
      }), " grâce aux contrôles d'accès."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Flux d'utilisateurs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque les contacts sont autorisés à accéder au contenu, ce qui peut se produire lorsqu'ils rejoignent des listes ou lors d'une affectation manuelle, ils reçoivent un e-mail pour s'inscrire à votre site web, où ils définissent un mot de passe pour accéder au contenu auquel ils ont l'autorisation d'accéder."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Prenons l'exemple d'une salle de sport qui souhaite permettre aux visiteurs de s'inscrire à des cours et de visualiser les cours auxquels ils se sont inscrits. Lorsqu'un visiteur s'inscrit à un cours, la soumission de formulaire crée un contact dans le CRM de HubSpot et ce contact est ajouté à une liste basée sur la soumission de formulaire, qui est utilisée pour accorder l'accès à une page « Mes événements »."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/signup.gif",
        alt: "Voici un exemple d'inscription à la salle de sport."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le visiteur reçoit un e-mail d'inscription qui lui permet de créer un mot de passe pour son compte de membre."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/register%20account.png",
        alt: "Formulaire d'inscription"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Désormais, lorsqu'il se connecte à son compte, l'utilisateur peut se connecter à la page privée « Mes événements » à l'aide de l'adresse e-mail et du mot de passe définis. Étant donné que le visiteur est connecté, le développeur qui a créé le contenu privé peut afficher des données sur le contact connecté à l'aide des données du CRM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/my_events.gif",
        alt: "Un visiteur utilise son compte pour se connecter et consulter les cours auxquels il s'est inscrit."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables HubL de contrôle d'accès"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour certaines entreprises, il peut être judicieux d'afficher différents contenus selon qu'un utilisateur est connecté ou non. Les développeurs peuvent utiliser certaines variables HubL pour vérifier si un contact est actuellement connecté sur un site web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.is_logged_in"
        })
      }), " indique si le visiteur actuel est connecté au site web via des adhésions. Elle peut être utilisée dans une condition ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " pour rendre conditionnellement certains contenus, vous permettant de personnaliser l'expérience de votre visiteur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n    You're signed in!\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez afficher un contenu différent sur la même page en fonction de l'adhésion à la liste, vous pouvez vérifier les adhésions à la liste des contacts connectés à l'aide de la variable HubL", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.list_memberships"
        })
      }), ", qui renvoie un dictionnaire d'ID de listes dont le contact connecté est membre."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour personnaliser le contenu sans utiliser le contrôle d'accès, vous pouvez utiliser la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables#general-variables",
          children: "variable de contact"
        }), " si un visiteur a soumis un formulaire sur votre site web."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonctions HubL d'objet CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En plus du contenu général affiché conditionnellement sur une page, il est possible de récupérer des informations sur les objets de votre compte HubSpot tels que des contacts, des entreprises, des transactions et des produits via les fonctions suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-associations",
          children: "Associations de CRM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-object",
          children: "Objet du CRM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-objects",
          children: "Objets du CRM"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour des raisons de sécurité, seuls les objets de produit et d'événement marketing peuvent être récupérés sur une page publique ; pour extraire des informations sur d'autres types d'objets, une page doit être soumise au contrôle d'accès."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n  {% set membership_contact = crm_object('contact', request.contact.contact_vid, 'firstname,lastname') %}\n  Welcome back, {{ membership_contact.firstname }} {{ membership_contact.lastname }}\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "S'inscrire, se connecter et se déconnecter"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'un contact est autorisé à accéder à n'importe quel contenu de votre site web via des adhésions, il recevra un e-mail pour s'inscrire sur votre site web où il pourra définir un mot de passe pour accéder au contenu qu'il est autorisé à consulter. Dans le cas où vous avez besoin de renvoyer à un contact un lien pour s'inscrire, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-pages-editor/control-audience-access-to-pages#resend-the-registration-email-to-a-specific-contact",
        children: "lui renvoyer l'e-mail d'inscription"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les chemins d'URL pour la connexion/déconnexion des utilisateurs sont cohérents pour tous les sites de CMS Hub avec la fonctionnalité de contrôle d'accès."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/login"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/logout"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'un visiteur se connecte à son site web, un cookie est envoyé vers son navigateur, ce qui lui permet de naviguer sur votre site web et d'accéder aux pages auxquelles il a accès par le biais d'abonnements sans avoir à se reconnecter. Si un visiteur se déconnecte ou ne s'est jamais connecté à votre site web depuis son navigateur, il sera invité à se connecter avant de pouvoir consulter le contenu."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modèles de contrôle d'accès"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les sites avec des contrôles d'accès ont quelques pages spéciales qui sont indispensables à la fonctionnalité de contrôle d'accès. Celles-ci sont dictées par des modèles système spéciaux. Ces modèles système sont modifiables, ce qui vous permet de contrôler l'apparence des différentes étapes du contrôle d'accès. Pour définir les modèles que vous utilisez, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Paramètres >"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contenu privé"
        })
      }), " et choisissez l'onglet ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/templates",
        children: "Modèles"
      }), ". Pour créer un modèle à définir dans ces paramètres, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketing > Fichiers et modèles > Outils de conception"
      }), ", puis dans l'angle supérieur gauche, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fichier > Nouveau fichier > HTML et HUBL"
      }), ", puis sélectionnez le type de modèle approprié dans la liste déroulante."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour obtenir une liste complète des modèles de contrôle d'accès, reportez-vous à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#membership",
        children: "la section sur le contrôle d'accès de la documentation relative aux modèles de contrôle d'accès"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Seuls les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "modèles HTML et HubL"
        }), " peuvent être des modèles de contrôle d'accès."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Journaux d'audit du contrôle d'accès"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: "Paramètres > Contenu privé"
      }), ", vous pouvez consulter un journal d'audit des interactions des visiteurs avec le contenu soumis au contrôle d'accès. Cela vous permet d'identifier les visiteurs qui consultent du contenu privé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-02-22%20at%2012.24.54%20PM.png",
        alt: "Journal d'audit de visionnage de contenu privé"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentification unique pour les adhésions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également gérer les autorisations d'accès et les besoins d'authentification de toutes vos entreprises dans un seul système avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso",
        children: "l'authentification unique (SSO) pour le contrôle d'accès."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Connexion via les réseaux sociaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez permettre aux utilisateurs de votre liste de se connecter en utilisant Google ou Facebook au lieu de saisir leur adresse e-mail et leur mot de passe. Le fournisseur d'accès aux réseaux sociaux envoie l'adresse e-mail associée au compte de réseau social connecté. Cette adresse e-mail est ensuite utilisée pour vérifier si ce contact se trouve dans une liste de contacts disposant d'un accès au contenu. Cette fonctionnalité ne nécessite pas la configuration de paramètres d'authentification unique."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devez définir une page « Inscription privée requise » avec une liste de contacts. En outre, votre modèle de connexion doit disposer du module ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "membership_social_logins"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/social",
        children: "Ajouter une connexion via un réseau social à vos pages d'abonnement"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Articles et ressources relatifs au contrôle d'accès"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-memberships",
          children: "HubSpot Essentials pour les développeurs : premiers pas avec le contrôle d'accès"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/creating-a-header-nav-that-adapts-to-if-the-contact-is-logged-in",
          children: "Créer des menus qui s'adaptent selon que l'utilisateur est connecté ou non"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}