"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 33073926377;
const slug = exports.slug = 'guides/apps/api-usage/breaking-change-definition';
const title = exports.title = 'Breaking Changes on the HubSpot Platform';
const name = exports.name = 'Breaking Change Definition';
const metaDescription = exports.metaDescription = 'What changes qualify as breaking vs non-breaking. Use this to help guide your app development ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "rest-apis",
  "label": "REST APIs",
  "parent": null
}, {
  "depth": 0,
  "id": "hubl",
  "label": "HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "embedded-content",
  "label": "Embedded content",
  "parent": null
}, {
  "depth": 0,
  "id": "javascript-sdk",
  "label": "JavaScript SDK",
  "parent": null
}, {
  "depth": 0,
  "id": "snowflake-data-share",
  "label": "Snowflake Data Share",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Breaking changes on the HubSpot platform"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When developing an integration on the HubSpot platform, you'll need to be aware of the types of changes that HubSpot might make to its platform or data within. Below, learn about what HubSpot considers a breaking or non-breaking change, and use these lists to inform decisions you'll make when working with HubSpot's APIs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If a change is listed as a breaking change, we aim to provide you with 90 days notice if we do decide to make that change."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this notice period applies to developer products released to general availability. For beta and developer preview products, we’ll continue to update those regularly as we learn more about them. In certain situations, such as those related to security or privacy, we may shorten that 90 day timeframe."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example of correctly handling non-breaking changes, you can safely write (in pseudo code here) ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if err.code === 500 then retry"
      }), " but you should not write ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if err.message === \"Uh oh, something went wrong\" then retry"
      }), ". By asking yourself the question \"if HubSpot makes a change listed as non-breaking, will this negatively impact the user experience?\", you can write code that will be easier to maintain and create a more reliable app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "REST APIs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about what changes are breaking for incoming and outgoing RESTful APIs (e.g., contacts API and webhooks)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing an endpoint"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing a field from the response (a “field” in this case is a JSON key/value pair)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing an option from a field in the request - If a request field could previously accept two String values, and we remove the ability to accept one of those, that would be a breaking change"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a new required field, header, or parameter in the request"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Renaming a field"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing the data type of a field"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing field lengths past industry standard data limits (int, long, etc.)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing error response code or category"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing auth types"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Restricting scope"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Reduce API limits"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Making validation more stringent"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing the default sort order"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Significantly increase the size of a response (10x)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Non-breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing the error message"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a new option to a response (e.g., adding a new possible return value to a string field)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a new optional input parameter"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Property labels changing - Labels can be changed by HubSpot, application providers, and even customers. Integrations should not rely on property labels for their functionality."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn about what changes are considered breaking for HubL (e.g., the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows"
      }), " function or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape"
      }), " filter)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing a filter, function, variable or tag"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a new required argument"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing the order of an argument"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Change the data type of a field in a module/theme field JSON"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Reduce the number of times a function can be invoked on a page"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Non-breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a new optional argument"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a new function, filter, variable or tag"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Embedded content"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about what changes are considered breaking for embedded content (e.g., forms and CTAs)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding or removing class names"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Changing the timing or execution of custom form events (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "onFormSubmit"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing the timing or structure of global form events"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Non-breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Add an optional setting for customizing the behavior of the embed"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript SDK"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about what changes are considered breaking for JavaScript SDK (e.g., the calling SDK)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing an operation"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Requiring a new argument to a function or field in an object that is passed to the function"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing order of required parameters"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing data type returned"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Non-breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a new function"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Snowflake Data Share"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Occasionally, there will be changes to the schema that describes the data share. Any change that makes querying a share fail due to changing an existing schema object is considered a breaking change."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, renaming a table is a breaking change for the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/query-hubspot-data-in-snowflake",
        children: "Snowflake Data Share integration"
      }), " because queries referencing the former name of the table will fail after the rename. In this case, HubSpot will add a new table with the same data to the share so that you can begin using the new name. The old table will continue to exist until the end of the 90 day notice period."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about what changes are considered breaking for the Snowflake Data Share."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing or renaming the database schema"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing or renaming a table or view"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Removing or renaming a column"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Changing a column's datatype"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Column nullability"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Non-breaking changes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If a change is listed as a non-breaking change, it will be added to the schema with no notice given. For example, adding a new column to the schema."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This means that queries accessing shares should be as specific as possible when referencing schema objects. For example, you should always qualify tables by specifying the database schema they belong to. It also means that you shouldn’t use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SELECT *"
      }), " clause in your queries, because that might break when a new column is added."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following are examples of non-breaking changes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding to the database schema"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a table or view"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding a column"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Changes to objects and their properties (e.g., the data within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objects"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "object_properties"
        }), " tables and views)"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}