"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310872;
const slug = exports.slug = 'guides/cms/content/performance/overview';
const title = exports.title = 'CDN、セキュリティー、パフォーマンス';
const name = exports.name = 'APAC JAPAN (ja) | [new] CDN, Security, Performance_JA | 202108';
const metaDescription = exports.metaDescription = 'HubSpotのCMSでは、セキュリティー、信頼性、パフォーマンス、メンテナンスの機能を提供するとともに、世界各地に分散された高速なCDNが使用されています。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/developer-bg-2.jpg';
const featuredImageAltText = exports.featuredImageAltText = 'CDN, Security, Performance';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E9%85%8D%E4%BF%A1%E3%83%8D%E3%83%83%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%EF%BC%88cdn%EF%BC%89",
  "label": "コンテンツ配信ネットワーク（CDN）",
  "parent": null
}, {
  "depth": 0,
  "id": "secure-socket-layer%EF%BC%88ssl%EF%BC%89",
  "label": "Secure Socket Layer（SSL）",
  "parent": null
}, {
  "depth": 0,
  "id": "http%2F2",
  "label": "HTTP/2",
  "parent": null
}, {
  "depth": 0,
  "id": "ipv6",
  "label": "IPv6",
  "parent": null
}, {
  "depth": 0,
  "id": "javascript%E3%81%AE%E8%BB%BD%E9%87%8F%E5%8C%96%EF%BC%88%E3%83%9F%E3%83%8B%E3%83%95%E3%82%A3%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%EF%BC%89",
  "label": "JavaScriptの軽量化（ミニフィケーション）",
  "parent": null
}, {
  "depth": 0,
  "id": "css%E3%81%AE%E8%BB%BD%E9%87%8F%E5%8C%96%E3%81%A8%E7%B5%90%E5%90%88",
  "label": "CSSの軽量化と結合",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6%E3%83%BC%E3%81%A8%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%81%AE%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5",
  "label": "ブラウザーとサーバーのキャッシュ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%81%AE%E6%9B%B8%E3%81%8D%E6%8F%9B%E3%81%88",
  "label": "ドメインの書き換え",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E5%9C%A7%E7%B8%AE",
  "label": "テキスト圧縮",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%94%BB%E5%83%8F%E3%81%AE%E5%9C%A7%E7%B8%AE%EF%BC%8F%E6%9C%80%E9%81%A9%E5%8C%96%E3%81%A8%E8%87%AA%E5%8B%95%E3%82%B5%E3%82%A4%E3%82%BA%E5%A4%89%E6%9B%B4",
  "label": "画像の圧縮／最適化と自動サイズ変更",
  "parent": null
}, {
  "depth": 0,
  "id": "amp%EF%BC%88accelerated-mobile-page%EF%BC%89",
  "label": "AMP（Accelerated Mobile Page）",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CDN、セキュリティー、パフォーマンス"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSは、高機能で最適化されたコンテンツ マネジメント システムの1つです。HubSpotのCMSはSaaS（Software as a Service）ソリューションなので、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/security",
        children: "セキュリティー"
      }), "や", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/reliability",
        children: "信頼性"
      }), "の確保、メンテナンスなどは当社が行います。お客さまはコードの記述や優れたユーザーエクスペリエンスの実現に集中できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["さまざまなパフォーマンスの強化もHubSpotが行いますが、ご自分でできる領域もあります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "HubSpotのCMSのパフォーマンスを最適化する方法はこちら。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンテンツ配信ネットワーク（CDN）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSでは世界各地に分散されたコンテンツ配信ネットワークを採用しているため、閲覧者の場所にかかわらず、ページが高速に読み込まれます。CDNを利用したメディアやページのホスティングには、追加の設定やアカウント取得は必要ありません。分散処理とキャッシュ無効化はシステムによって自動的に行われ、ウェブ アプリケーション ファイアウォールと組み込みのセキュリティーによってオンライン攻撃から安全に保護されるので、お客さまは優れたサイトの構築作業に集中できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secure Socket Layer（SSL）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSまたはMarketing Hubのアカウントに接続された全てのドメインには、SSLが無料で組み込まれ、自動的にプロビジョニングされます。各ドメインには固有のSAN証明書がプロビジョニングされます。設定可能なオプションとして、特定のTLSバージョンの無効化、HTTP経由のリクエストのHTTPSへのリダイレクト、今後のリクエストをHTTPS経由にするためのHSTSヘッダーの提供などが用意されています。必要に応じて、追加の月額料金でカスタムSSL証明書をホスティングできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTTP/2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでホスティングされるウェブサイトでは、全てのSSLトラフィックがHTTP/2を使って配信されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://http2.github.io/",
        children: "HTTP/2"
      }), "は、現在の通信方法に代わる新しいHTTPプロトコルです。これは、基礎からのプロトコル刷新ではありません。HTTPメソッド、ステータスコード、セマンティックスは共通で、HTTP/1.xと同じAPIを使ってプロトコルを（必要に応じた簡単な情報の付加程度で）表すことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パフォーマンスの改善を重視したHTTP/2では、特に体感速度や、ネットワークとサーバーのリソース使用量が改善されます。その主な目的は、ブラウザーからウェブサイトまでの接続を1つにすることです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "IPv6"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot上にホスティングされる全てのウェブサイトにはIPv6アドレスが含まれているので、IPv6経由のネイティブアクセスが可能です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.google.com/intl/en/ipv6/index.html",
        children: "IPv6"
      }), "はインターネットプロトコルの最新バージョンで、利用可能なアドレスの数が実質的に無制限（340兆の1兆倍の1兆倍）に拡張されます。インターネットではIPv4アドレスが枯渇しています。IPv6への移行によって、さらに多くのデバイスがインターネットに接続可能になるため、インターネットの持続的な拡大と、革新的なサービスの開発につながります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScriptの軽量化（ミニフィケーション）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのデザインマネージャーでは、JavaScriptファイルから不要なスペース、改行、コメントを自動的に削除することで、構文解析および配信の高速化を支援しています。なお、ファイルマネージャーにアップロードされたJavaScriptファイルは自動的に軽量化されません。したがって、ウェブサイトに掲載するファイルについては軽量化してからアップロードする必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "注：JavaScriptファイルは更新のたびに、バックグラウンドで自動的に軽量化されます。このため、JavaScriptファイルの.min.jsバージョンが公開ページに配信されるまでには少し時間がかかります。この間は非軽量化バージョンが配信されるので、サイトの訪問者は最新バージョンのファイルを閲覧できます。構文エラーがある場合は、JavaScriptファイルを軽量化できないことがあります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSSの軽量化と結合"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは自動的に軽量化が行われます（不要なスペースと改行が削除されます）。複数のCSSファイルを1つのファイルに結合するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "HubL includesを使用"
      }), "します。これにより、HTTPリクエスト数が最小限に抑えられ、ウェブサイトでのCSSの配信と解析のスピードが最大限に高まります。なお、ファイルマネージャーにアップロードされたJavaScriptファイルは自動的に軽量化されません。したがって、ウェブサイトに掲載するファイルについては軽量化してからアップロードする必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "注：CSSファイルの更新のたびに、ページ上のCSSファイルを再軽量化する必要があります。このため、更新後のCSSファイルが公開ページに配信されるまでには少し時間がかかります。構文エラーがある場合は、CSSファイルを軽量化できないことがあります。外部のCSSファイルを更新しても、結合されたCSSファイルは更新されません。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブラウザーとサーバーのキャッシュ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotではページやファイルのキャッシュをサーバーとブラウザーの両方で自動的に保持することで、ページ内の全てのデータを配信する速度を最大限に引き上げることができます。ページが修正されたりページの依存関係（テンプレートやモジュールなど）が変更されたりした場合は、該当するページのサーバーキャッシュが自動的に期限切れになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ドメインの書き換え"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブサイトにドメインを使用するたびに、追加のDNSルックアップと接続が発生します。ウェブサイトの読み込みは、使用するドメインの数が少ないほど速くなります。HTTP/2では1つの接続を通じて複数のファイルを同時に読み込めるので、複数ドメインにわたるアセット（コンテンツ）の分散に関する古いガイドラインは当てはまらなくなりました。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["可能な場合は、デベロッパー ファイル システムのファイル、CSS、JS、画像など、CMSのページによって参照されるアセットのURLが、現在のページのドメインに合わせて自動的に書き換えられます。例えば", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/",
        children: "www.hubspot.jp"
      }), "上に配信されるページから", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg",
        children: "http://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg"
      }), "という画像を参照している場合、URLが自動的に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/hubfs/HubSpot_Logos/HSLogo_gray.svg",
        children: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg"
      }), "に更新されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テキスト圧縮"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HTML、CSS、JSなどの全てのテキストベースのファイルは、ブラウザーに配信される前に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/brotli",
        children: "brotli"
      }), "を使って圧縮されます。brotliによる圧縮はGZIPよりも効果があります。Brotli圧縮への対応がクライアントから示されない場合は、gzip圧縮が適用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "軽量化によりブラウザー側のCSSファイルとJSファイルの構文解析がスピードアップし、圧縮によりファイルのブラウザーへの配信が高速になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "画像の圧縮／最適化と自動サイズ変更"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "画像をファイルマネージャーにアップロードすると、画像は自動的に最適化されます。具体的には、JPEGとPNGのメタデータ（画像サイズを大きくするEXIFなどのデータ）が除去されます。また（GIFファイルを除く）全ての画像は、視覚的な劣化を伴わない方法で再圧縮されます。さらに、JPEGよりも小さいPNGを利用できる場合は、エンコードを変えて画像が配信されることもあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**画像は、元の解像度に関係なく72 dpiで再保存されます。**当初印刷用に作成された300 dpiのファイルをアップロードした場合は、ウェブ標準の72 dpiに変換されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["**WebP形式をサポートするブラウザー向けとして、WebP形式に画像を拡張しています。**WebPバージョンの方が画像のファイルサイズが小さくなる場合は、この形式で画像が配信されます。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "この変換はサーバー側で行われ、URL内のファイル拡張子は変更されません。"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), "としてアップロードされた画像は、URLでは引き続き", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), "と表示されますが、WebPとして配信されます。この画像のリンクは、WebPへの対応状況にかかわらず、全てのユーザーの環境で機能します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubSpot上にホスティングされた画像をCMSコンテンツに配置すると、自動的にサイズが変更されるので"
      }), "、ブラウザー側での処理に必要とされるリソースの消費が回避されます。これを実現するために、高さと幅の属性を持つ画像の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " URLに高さと幅のクエリーパラメーターが付加されます。注：画像のサイズ変更は1分以内で完了します。画像のサイズが変更される前にページがリクエストされた場合はサイズ変更前の画像が配信されます。ブラウザーには、読み込む画像の解像度について複数のオプションが提示されるので、ディスプレイが標準解像度でも高解像度でも画像が鮮明に表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "画像の最適化／圧縮、および画像の自動サイズ変更共に、HubSpot上にホスティングされる全てのページとファイルについて既定で有効になっています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/img_opt.png",
        alt: "img要素のスクリーンショット。さまざまなサイズ変更URLを含むsrcsetが自動的に追加されています。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["画像urlに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "quality=high"
        }), "クエリーパラメーターが含まれるjpgファイルは、圧縮されません。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMS上で構築を行う開発者が、画像にHubSpotコンテキストを追加して、画像のサイズ変更をさらに制御することもできます。この場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#resize-image-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url()"
        })
      }), "関数を使用します。これにより開発者はモジュール内に制限を設けて、制作担当者が過度に大きな画像をページやEメールに表示しないように制御できます。この関数は、背景画像など、HTML内の高さ／幅属性によって画像サイズが決まらない状況にも適しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "AMP（Accelerated Mobile Page）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["AMP（Accelerated Mobile Page）は、ほぼ瞬時にコンテンツが読み込まれるモバイル特化型のページ形式です。HubSpot上にホスティングされるブログ記事では、スイッチを切り替えることでAMPを有効にできます。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "HubSpot上でAMPを使用する方法"
      }), "を参照してください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}