"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694137;
const slug = exports.slug = 'guides/cms/content/modules/quickstart';
const title = exports.title = 'Erste Schritte mit Modulen';
const name = exports.name = 'Erste Schritte mit Modulen';
const metaDescription = exports.metaDescription = 'Erstellen Sie eine Komponente, die für alle Ihre Seiten und Vorlagen wiederverwendbar ist, ein so genanntes benutzerdefiniertes Modul. Sie lernen etwas über Felder und deren Verwendung in HubL.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Module%20Editor-1-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Erste Schritte mit Modulen';
const toc = exports.toc = [{
  "depth": 0,
  "id": "bevor-sie-loslegen",
  "label": "Bevor Sie loslegen",
  "parent": null
}, {
  "depth": 0,
  "id": "lokale-entwicklungsumgebung-einrichten",
  "label": "Lokale Entwicklungsumgebung einrichten",
  "parent": null
}, {
  "depth": 0,
  "id": "die-cms-boilerplate-zu-ihrem-account-hinzuf%C3%BCgen",
  "label": "Die CMS-Boilerplate zu Ihrem Account hinzufügen",
  "parent": null
}, {
  "depth": 0,
  "id": "ein-modul-in-hubspot-erstellen",
  "label": "Ein Modul in HubSpot erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "felder-zum-modul-hinzuf%C3%BCgen",
  "label": "Felder zum Modul hinzufügen",
  "parent": "ein-modul-in-hubspot-erstellen"
}, {
  "depth": 2,
  "id": "das-textfeld-f%C3%BCr-den-kundennamen-hinzuf%C3%BCgen",
  "label": "Das Textfeld für den Kundennamen hinzufügen",
  "parent": "ein-modul-in-hubspot-erstellen"
}, {
  "depth": 2,
  "id": "das-bildfeld-f%C3%BCr-das-kundenprofilbild-hinzuf%C3%BCgen",
  "label": "Das Bildfeld für das Kundenprofilbild hinzufügen",
  "parent": "ein-modul-in-hubspot-erstellen"
}, {
  "depth": 2,
  "id": "das-rich-text-feld-f%C3%BCr-sallys-testimonial-hinzuf%C3%BCgen",
  "label": "Das Rich-Text-Feld für Sallys Testimonial hinzufügen",
  "parent": "ein-modul-in-hubspot-erstellen"
}, {
  "depth": 1,
  "id": "hubl-hinzuf%C3%BCgen%2C-um-felddaten-anzuzeigen",
  "label": "HubL hinzufügen, um Felddaten anzuzeigen",
  "parent": "ein-modul-in-hubspot-erstellen"
}, {
  "depth": 0,
  "id": "das-modul-lokal-anzeigen-und-%C3%A4ndern",
  "label": "Das Modul lokal anzeigen und ändern",
  "parent": null
}, {
  "depth": 1,
  "id": "den-modulordner-in-ihrer-lokalen-umgebung-%C3%B6ffnen",
  "label": "Den Modulordner in Ihrer lokalen Umgebung öffnen",
  "parent": "das-modul-lokal-anzeigen-und-%C3%A4ndern"
}, {
  "depth": 1,
  "id": "die-fields.json-datei-des-moduls-anzeigen",
  "label": "Die fields.json-Datei des Moduls anzeigen",
  "parent": "das-modul-lokal-anzeigen-und-%C3%A4ndern"
}, {
  "depth": 1,
  "id": "die-module.html-datei-des-moduls-anzeigen",
  "label": "Die module.html-Datei des Moduls anzeigen",
  "parent": "das-modul-lokal-anzeigen-und-%C3%A4ndern"
}, {
  "depth": 1,
  "id": "die-module.css-datei-des-moduls-anzeigen",
  "label": "Die module.css-Datei des Moduls anzeigen",
  "parent": "das-modul-lokal-anzeigen-und-%C3%A4ndern"
}, {
  "depth": 0,
  "id": "lokale-%C3%A4nderungen-f%C3%BCr-ihren-hubspot-account-%C3%BCbernehmen",
  "label": "Lokale Änderungen für Ihren HubSpot-Account übernehmen",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-lokalen-%C3%A4nderungen-in-hubspot-in-einer-vorschau-anzeigen",
  "label": "Ihre lokalen Änderungen in HubSpot in einer Vorschau anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "das-modul-zu-einer-vorlage-hinzuf%C3%BCgen",
  "label": "Das Modul zu einer Vorlage hinzufügen",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-%C3%A4nderungen-in-hubspot-in-einer-vorschau-anzeigen",
  "label": "Ihre Änderungen in HubSpot in einer Vorschau anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "das-modul-lokal-in-der-vorlage-anpassen",
  "label": "Das Modul lokal in der Vorlage anpassen",
  "parent": null
}, {
  "depth": 0,
  "id": "zwei-weitere-testimonial-module-zur-vorlage-hinzuf%C3%BCgen",
  "label": "Zwei weitere Testimonial-Module zur Vorlage hinzufügen",
  "parent": null
}, {
  "depth": 0,
  "id": "den-designnamen-%C3%A4ndern",
  "label": "Den Designnamen ändern",
  "parent": null
}, {
  "depth": 0,
  "id": "n%C3%A4chste-schritte",
  "label": "Nächste Schritte",
  "parent": null
}, {
  "depth": 0,
  "id": "sonstige-tutorials",
  "label": "Sonstige Tutorials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      pre: "pre",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erste Schritte mit Modulen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Tutorial lernen Sie anhand des CMS Hub-Boilerplate-Designs, wie Sie ein Modul erstellen und wie Sie es in Vorlagen und Seiten als Teil eines Designs verwenden können. Am Ende des Tutorials haben Sie ein Kundentestimonial-Modul erstellt, das ein Textfeld, ein Bildfeld und ein Rich-Text-Feld enthält."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn Sie zum ersten Mal mit CMS Hub entwickeln, empfehlen wir:"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Schneller Einstieg in die CMS Hub-Entwicklung"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie ein Modul für die Verwendung in einem Design entwickeln möchten, das Sie in HubSpots Marketplace für Vorlagen listen möchten, sehen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/module-requirements",
          children: "Modulanforderungen für den HubSpot-Marketplace für Vorlagen"
        }), " an."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bevor Sie loslegen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie mit diesem Tutorial loslegen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Richten Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-cms-developer-sandbox",
          children: "CMS Hub-Entwickler-Sandbox"
        }), " ein. Sie können stattdessen Ihren bestehenden Account verwenden, aber in der Sandbox können Sie entwickeln, ohne dass sich dies auf die Elemente in Ihrem Haupt-Account auswirkt. Installieren Sie- ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), ", das die Tools zur lokalen Entwicklung von HubSpot aktiviert. Unterstützt wird Version 10 oder höher."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können sich auch schon eine Vorschau der fertigen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
        children: "Projektdateien"
      }), " anschauen. Da sich der Code des CMS-Boilerplate-Designs im Laufe der Zeit ändern kann, sind nur die wichtigsten Dateien enthalten, die Entwickler während des Tutorials erstellen oder bearbeiten müssen. Diese Dateien umfassen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial.module:"
        }), " der Ordner mit den Dateien, aus denen das benutzerdefinierte Modul besteht, das Sie im Rahmen des Tutorials erstellen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "homepage.html:"
        }), " die Vorlage, die Sie bearbeiten, um das benutzerdefinierte Modul einzuschließen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "images"
        }), ": der Ordner mit den Profilbildern, die Sie im Modul verwenden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lokale Entwicklungsumgebung einrichten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Tutorial entwickeln Sie das Modul lokal. Um mit HubSpot über die Befehlszeilenschnittstelle zu interagieren, müssen Sie das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "CLI von HubSpot"
      }), " installieren und es für den Zugriff auf Ihren Account konfigurieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie im Terminal den folgenden Befehl aus, um das CLI global zu installieren. Um das CLI stattdessen nur in Ihrem aktuellen Verzeichnis zu installieren, führen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm install -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Authentifizieren Sie im Verzeichnis, in dem Sie Ihre Designdateien speichern, Ihren HubSpot-Account, damit Sie über das CLI damit interagieren können."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs init\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Drücken Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eingabetaste"
        }), ", um die Seite mit dem persönlichen Zugriffsschlüssel in Ihrem Browser zu öffnen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Account"
        }), " aus, in dem Sie bereitstellen möchten, und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mit diesem Account fortfahren"
        }), ". Sie werden dann zur Seite mit dem persönlichen Zugriffsschlüssel des Accounts weitergeleitet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie neben ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Persönlicher Zugriffsschlüssel"
        }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anzeigen"
        }), ", um Ihren Schlüssel zu sehen. Klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kopieren"
        }), ", um ihn in Ihre Zwischenablage zu kopieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie den kopierten Schlüssel im Terminal ein und drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eingabetaste"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie einen eindeutigen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " für den Account ein. Dieser Name wird nur von Ihnen gesehen und verwendet, wenn Sie Befehle ausführen. Drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eingabetaste"
        }), ", um die Tools zur lokalen Entwicklung mit Ihrem Account zu verknüpfen:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie sehen dann eine Erfolgsmeldung, die bestätigt, dass die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "-Datei erstellt wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die CMS-Boilerplate zu Ihrem Account hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie den folgenden Befehl aus, um ein neues Design mit dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), " zu erstellen. Daraufhin wird ein Designordner mit dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my-theme"
        }), " in Ihrem Arbeitsverzeichnis erstellt, der die Boilerplate-Elemente enthält."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create website-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Laden Sie die Dateien in Ihren Account hoch."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload <src> <destination>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Pfad zu lokalen Dateien, relativ zu Ihrem aktuellen Arbeitsverzeichnis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Zielpfad in Ihrem HubSpot-Account. Dies kann ein neuer Ordner sein."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zum Beispiel lädt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme my-new-theme"
      }), " den Ordner ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-theme"
      }), " von Ihrem Rechner in den Ordner ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-new-theme"
      }), " in HubSpot hoch."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Standardmäßig lädt HubSpot in den Standard-Account in Ihrer ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config,yml"
        }), "-Datei hoch. Sie können jedoch auch einen Account angeben, indem Sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--account=<accountNameOrId>"
        }), "-Flag im Befehl einschließen. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload --portal=mainProd"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Weitere Informationen finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CLI-Befehlsreferenz"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Modul in HubSpot erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit der CMS-Boilerplate in Ihrer lokalen Umgebung erstellen Sie jetzt ein neues Modul für das Design."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Für die Zwecke dieses Tutorials erstellen Sie das Modul in HubSpot und übernehmen es dann mithilfe des CLI in das Design. Sie können jedoch auch Module lokal mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#create-new-files",
          children: "hs create module"
        }), "-Befehl von Grund auf neu erstellen."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Melden Sie sich bei Ihrem HubSpot-Account an und gehen Sie dann zum Design-Manager, indem Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing > Dateien und Vorlagen > Design-Manager"
          }), " navigieren."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Öffnen Sie in der linken Seitenleiste des Design-Managers den soeben hochgeladenen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Designordner"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Öffnen Sie im Designordner den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ordner für Module"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie oben links auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Datei > Neue Datei"
          }), ", um ein neues Modul in diesem Ordner zu erstellen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie im Dialogfeld auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Dropdown-Menü"
          }), ", wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Modul"
          }), " aus und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Weiter"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/new-module-dropdown-menu0.png",
            alt: "new-module-dropdown-menu0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Aktivieren Sie das Kontrollkästchen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seiten"
          }), ", um das Modul für Website-Seiten und Landingpages verfügbar zu machen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie dem Modul den Namen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Testimonial"
          }), " und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Erstellen"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Felder zum Modul hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie als Nächstes dem Modul drei Felder hinzu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein Textfeld zum Speichern des Namens des Kunden, der das Testimonial abgibt."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein Bildfeld, in dem das Profilbild des Kunden gespeichert wird."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein Rich-Text-Feld, in dem das Testimonial des Kunden gespeichert wird."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Das Textfeld für den Kundennamen hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie in der rechten Seitenleiste des Modul-Editors auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Feld hinzufügen"
          }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Text"
          }), " aus."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-add-field.png",
            alt: "testimonial-module-add-field"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie in der rechten Seitenleiste oben rechts auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Bleistift-Symbol"
          }), ", um das Feld zu benennen. Geben Sie für dieses Tutorial den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Kundennamen"
          }), " ein. Der Name der HubL-Variable ändert sich dann in ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "customer_name"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Legen Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Standardtext"
          }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sally"
          }), " fest."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Text-Field-3.png",
        alt: "Beispiel Textfeld"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in der rechten Seitenleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Breadcrumb"
        }), "-Symbol, um zum Hauptmenü des Moduls zurückzukehren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-breadcrumb-icon0.png",
        alt: "module-breadcrumb-icon0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Das Bildfeld für das Kundenprofilbild hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie auf die gleiche Weise ein weiteres Feld hinzu, wobei Sie diesmal den Feldtyp ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bild"
        }), " auswählen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie dem Bildfeld das Label ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Profilbild"
        }), " und legen Sie dann den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen der HubL-Variablen"
        }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "profile_pic"
        }), " fest."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie unter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Standardbild"
        }), " das Profilbild aus, das für Sally im Ordner ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bilder"
        }), " der fertigen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
          children: "Projektdateien"
        }), " bereitgestellt wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Legen Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ALT-Text"
        }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sallys Profilbild"
        }), " fest."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/getting-started-with-modules-profile-pic.png",
        alt: "getting-started-with-modules-profile-pic"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Das Rich-Text-Feld für Sallys Testimonial hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie auf die gleiche Weise ein weiteres Feld hinzu, wobei Sie dieses Mal den Feldtyp ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rich-Text"
        }), " auswählen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Versehen Sie das Rich-Text-Feld mit dem Label ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Feld ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Standard-Rich-Text"
        }), " und geben Sie „Ich habe mit diesem Unternehmen nur gute Erfahrungen gemacht“ ein."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Rich-Text-Field-5--.png",
        alt: "Beispiel Rich-Text-Feld"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bislang haben Sie Daten in mehrere Modulfelder eingegeben. Zu diesem Zeitpunkt enthält das Modul jedoch keinerlei HTML, um diese Daten zu rendern. Im Modul-Editor wird dies durch den leeren Zustand des Abschnitts ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " widergespiegelt.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-html-empty0.png",
        alt: "module-html-empty0"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie als Nächstes HubL zu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " hinzu, um die Felddaten anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubL hinzufügen, um Felddaten anzuzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Daten aus den zuvor erstellten Feldern anzuzeigen, fügen Sie folgenden HubL zum Bereich ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " hinzu:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{module.customer_name}}\n<img src={{module.profile_pic.src}} alt=\"{{module.profile_pic.alt}}\">\n{{module.testimonial}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die obigen HubL-Token verwenden Punktnotation, um Daten aus jedem Feld aufzurufen. Da wir Daten aus Modulfeldern abrufen möchten, beginnt jedes Token in diesem Fall mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", gefolgt vom HubL-Variablennamen des Feldes. Sie können Punktnotation verwenden, um auf bestimmte Eigenschaften eines Feldes zuzugreifen, die Sie in den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "profile_pic"
      }), "-Token in Zeile 3 oben sehen können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn der HubL zu Ihrem Modul hinzugefügt wurde, klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau"
        }), ", um zu sehen, wie das Modul bisher aussieht."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-previewer.png",
        alt: "design-manager-previewer"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie dann oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Änderungen veröffentlichen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie das Modul in HubSpot erstellt und veröffentlicht haben, verwenden Sie nun das CLI, um das Modul in Ihre lokale Umgebung zu holen, damit Sie seinen Content anzeigen und weitere Änderungen vornehmen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Das Modul lokal anzeigen und ändern"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um das Modul lokal anzuzeigen, müssen Sie es zunächst in Ihr lokales Design holen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Führen Sie im Terminal den folgenden Befehl aus: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs fetch <hs_src> <destination>"
          }), ":"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<hs_src>"
              }), " steht für den Dateipfad des Moduls in HubSpot. Um den Dateipfad abzurufen, klicken Sie mit der rechten Maustaste auf das Modul in der linken Seitenleiste im Design-Manager und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Pfad kopieren"
              }), " aus."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-copy-path0.png",
                alt: "design-manager-copy-path0"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "<destination>"
              }), " steht für den Pfad zu dem lokalen Verzeichnis, in dem sich Ihr Design befindet. Wird dies nicht angegeben, verwendet der Befehl das aktuelle Arbeitsverzeichnis."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie sich beispielsweise bereits im Arbeitsverzeichnis befinden, kann Ihr FETCH-Befehl wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch my-theme/modules/testimonial.module\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Den Modulordner in Ihrer lokalen Umgebung öffnen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gehen Sie in Ihrem bevorzugten Code-Editor zu dem Modulordner, den Sie gerade von Ihrem HubSpot-Account geholt haben. In Ihrem Modulordner finden Sie fünf verschiedene Dateien:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein JSON-Objekt, das die Felder Ihres Moduls enthält."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta.json"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein JSON-Objekt, das Meta-Informationen zu Ihrem Modul enthält."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.css"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die CSS-Datei, die Ihr Modul formatiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Den HTML- und HubL-Code für Ihr Modul."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module.js"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das JavaScript für Ihr Modul."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ausführlichere Informationen finden Sie in unserer Dokumentation zur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Dateistruktur des Moduls"
      }), ", insbesondere zu den Dateien ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), ". In diesem Tutorial konzentrieren wir uns auf die Dateien ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " und wie sie vom Modul-Editor im Design-Manager generiert, heruntergeladen und hochgeladen werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die fields.json-Datei des Moduls anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Öffnen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei des Moduls. Abgesehen von einigen der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Nummern, dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "-Attribut des Bildfeldes und möglicherweise der Reihenfolge der Felder sollte die Datei ein JSON-Objekt ähnlich dem folgenden enthalten:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//fields.json\n\n[\n  {\n    \"label\": \"Customer Name\",\n    \"name\": \"customer_name\",\n    \"id\": \"2a025cd5-7357-007f-ae2f-25ace762588e\",\n    \"type\": \"text\",\n    \"required\": true,\n    \"locked\": false,\n    \"validation_regex\": \"\",\n    \"allow_new_line\": false,\n    \"show_emoji_picker\": false,\n    \"default\": \"Sally\"\n  },\n  {\n    \"label\": \"Profile Picture\",\n    \"name\": \"profile_pic\",\n    \"id\": \"7877fb84-eb8a-d2c7-d939-77e6e9557d8f\",\n    \"type\": \"image\",\n    \"required\": false,\n    \"locked\": false,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"default\": {\n      \"src\": \"https://cdn2.hubspotqa.net/hubfs/101140939/profile-pic-sally.svg\",\n      \"alt\": \"Sally Profile Picture\",\n      \"width\": 100,\n      \"height\": 100,\n      \"max_width\": 100,\n      \"max_height\": 100\n    }\n  },\n  {\n    \"label\": \"Testimonial\",\n    \"name\": \"testimonial\",\n    \"id\": \"c5524ece-1ab5-f92d-a5de-e2bf53199dfa\",\n    \"type\": \"richtext\",\n    \"required\": false,\n    \"locked\": false,\n    \"default\": \"<p>I’ve had nothing but wonderful experiences with this company.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Werte für die folgenden Felder entsprechen den Werten, die Sie in Schritt 3 hinzugefügt haben:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": der Name des Feldes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ": das Label des Feldes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "default"
        }), ": der Standardwert des Feldes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die module.html-Datei des Moduls anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "-Datei sollte den HubL- und HTML-Code enthalten, den Sie zuvor im HubL + HTML-Modul-Editor geschrieben haben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um diesen Code interessanter zu gestalten und bereit für CSS-Styling zu machen, kopieren Sie den folgenden Code und fügen ihn in die Datei ein:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"testimonial\">\n  <h1 class=\"testimonial__header\"> {{ module.customer_name }} </h1>\n  <img class=\"testimonial__picture\" src={{ module.profile_pic.src }} alt={{ module.profile_pic.alt }}>\n  {{ module.testimonial }}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn Sie Ihren HTML wie oben beschrieben schreiben, verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/bem-101/",
          children: "BEM-Klassenstruktur"
        }), " in Übereinstimmung mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/STYLEGUIDE.md#css-code-formatting",
          children: "Styleguide"
        }), " des CMS Hub-Boilerplate-Designs."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die module.css-Datei des Moduls anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "-Datei sollte zu diesem Zeitpunkt leer sein. Kopieren Sie den folgenden Code und fügen Sie ihn in die Datei ein, um Stile hinzuzufügen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial {\n  text-align: center;\n}\n\n.testimonial__header {\n  font-weight: bold;\n}\n\n.testimonial__picture {\n  display: block;\n  margin: auto;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie den Code hinzugefügt haben, speichern Sie die Datei."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lokale Änderungen für Ihren HubSpot-Account übernehmen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Übernehmen Sie Ihre lokalen Änderungen nach dem Speichern wieder in Ihren HubSpot-Account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Gehen Sie zu Ihrem Terminal und stellen Sie sicher, dass Sie sich im richtigen Verzeichnis befinden."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie den watch-Befehl aus, um Änderungen beim Speichern an HubSpot zu übertragen: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <destination>"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch my-theme my-theme\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre lokalen Änderungen in HubSpot in einer Vorschau anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateien und Vorlagen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design-Manager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu dem von Ihnen erstellten ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design"
        }), ", öffnen Sie dann den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modulordner"
        }), " und wählen Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testimonial"
        }), "-Modul aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn das Modul geöffnet ist, sollten Sie Ihre Änderungen in den Bereichen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.css"
        }), " sehen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau"
        }), ". Daraufhin öffnet sich eine neue Registerkarte, auf der die Modulvorschau angezeigt wird."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Module-Preview-7.png",
        alt: "Beispiel Modulvorschau"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fassen wir den bisherigen Verlauf des Tutorials zusammen. Sie haben:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ein Modul in HubSpot erstellt."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "dieses Modul in Ihre lokale Umgebung geholt."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "mithilfe Ihrer Tools zur lokalen Entwicklung Änderungen am HubL + HTML und CSS vorgenommen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im nächsten Teil des Tutorials erfahren Sie, wie Sie das Modul in einer Vorlage verwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Das Modul zu einer Vorlage hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Teil des Tutorials arbeiten Sie hauptsächlich in den Ordnern ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modules"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), " innerhalb Ihrer lokalen Designdateien.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/theme-folder-structure-getting-started-with-modules0.png",
        alt: "theme-folder-structure-getting-started-with-modules0"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gemäß ihrer einfachsten Definition sind Module bearbeitbare Bereiche von HubSpot-Vorlagen und -Seiten. In HubSpot können Sie über den Design-Manager Module in Vorlagen einfügen, aber hier verwenden wir dann HubL, um das Modul zur Vorlage in Ihrer lokalen Umgebung hinzuzufügen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Öffnen Sie in Ihrem Code-Editor den ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "templates"
          }), "-Ordner und dann die ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "-Datei."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in der ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "-Datei zum letzten ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), ", der etwa in Zeile 28 beginnt. Diesem Abschnitt fügen Sie Ihr neues Modul hinzu."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/home-html-add-testimonial-module-to-section0.png",
            alt: "home-html-add-testimonial-module-to-section0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Kopieren Sie innerhalb dieses ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_section"
          }), " und über den anderen ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_modules"
          }), " das folgende HubL-Modul-Tag und fügen Sie es ein:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path= “../modules/Testimonial.module”,\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieses HubL-Tag referenziert Ihr neues Modul anhand seines relativen Dateipfades. Damit das Modul gleichmäßig zu den anderen beiden Modulen im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " passt, weist es auch einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), "-Wert für das Modul zu:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das CMS von HubSpot verwendet ein ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview#columns",
          children: "12-Spalten-Rastersystem."
        }), " Um dieses Modul gleichmäßig mit den anderen beiden zu platzieren, müssen Sie daher jedes Modul im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " aktualisieren, damit es eine Breite von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), " hat."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dann hat das erste ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " in der Gruppe (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), ") einen Versatz von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), ", um es zuerst zu positionieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das zweite ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linked_image"
        }), ") hat einen Versatz von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4"
        }), ", um es als zweites zu positionieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das dritte ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "rich_text"
        }), ") hat einen Versatz von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "8"
        }), ", um es als drittes zu positionieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), "-Wert von jedem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " festgelegt haben, sieht Ihr Code wie folgt aus:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n    background_color=\"#f8fafc\",\n    vertical_alignment=\"MIDDLE\"\n  %}\n\n    {% dnd_module\n      path= “../modules/Testimonial.module”,\n      offset=0,\n      width=4\n    %}\n    {% end_dnd_module %}\n\n    {% dnd_module\n      path=\"@hubspot/linked_image\",\n      img={\n        \"alt\": \"Stock placeholder image with grayscale geometrical mountain landscape\",\n        \"loading\": \"lazy\",\n        \"max_height\": 451,\n        \"max_width\": 605,\n        \"size_type\": \"auto_custom_max\",\n        \"src\": get_asset_url(\"../images/grayscale-mountain.png\")\n      },\n      offset=4,\n      width=4\n    %}\n    {% end_dnd_module %}\n    {% dnd_module\n      path=\"@hubspot/rich_text\",\n      html=\"<h2>Provide more details here.</h2><p>Use text and images to tell your company’s story. Explain what makes your product or service extraordinary.</p>\"\n      offset=8,\n      width=4\n    %}\n    {% end_dnd_module %}\n  {% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Beim Hinzufügen eines Moduls zu einem Drag-&-Drop-Bereich ist für das Modul-Tag ", (0, _jsxRuntime.jsx)("u", {
          children: "kein"
        }), " eindeutiger Name erforderlich. Wenn Sie jedoch ein Modul zu einer HTML-Datei außerhalb von Drag-&-Drop-Bereichen hinzufügen, müssen Sie dem Modul einen eindeutigen Namen zuweisen. Sie würden auch eine etwas andere Syntax verwenden, zum Beispiel:"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% module \"testimonial_one\" path=\"../modules/Testimonial.module\" %}"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Verwendung von Modulen in Vorlagen"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre Änderungen in HubSpot in einer Vorschau anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), "-Befehl nicht ausgeführt haben lassen, um Ihre Arbeit automatisch nachzuverfolgen, führen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch <src> <dest>"
        }), " aus. Stellen Sie sicher, dass dieser Befehl weiter ausgeführt wird, während Sie die nächsten Schritte abschließen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Öffnen Sie in Ihrem HubSpot-Account den Design-Manager (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateien und Vorlagen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design-Manager"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie in der linken Seitenleiste des Design-Managers die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "home.html"
        }), "-Datei aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Live-Vorschau mit Anzeigeoptionen"
        }), " aus, um die Vorschau der Vorlage in einem neuen Fenster zu öffnen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Live-Preview-10.png",
        alt: "Beispiel Live-Vorschau"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Öffnen Sie in der neuen Registerkarte die Vorlagenvorschau, die Ihr neu hinzugefügtes Testimonial-Modul enthalten sollte."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-module-added-to-theme.png",
        alt: "testimonial-module-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Das Modul lokal in der Vorlage anpassen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So können Sie die Startseitenvorlage interessanter gestalten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie zurück zu Ihrem Code-Editor und öffnen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "home.html"
        }), "-Datei."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fügen Sie dem Tag des Testimonial-Moduls die folgenden Parameter hinzu:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n testimonial = \"Wow, what a product! I can't wait to recommend this to all my family and friends!\",\n offset=0,\n width=4\n%}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die obigen Parameter setzen die Standardwerte außer Kraft, die Sie den drei Feldern ursprünglich zugewiesen haben. Jeder Parameter verwendet den HubL-Variablennamen, den Sie zuvor den einzelnen Feldern zugewiesen haben:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "customer_name"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " dieser Parameter übergibt den Namen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Mary"
        }), " an das Feld für den Kundennamen. Dabei wird der Originalwert ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sally"
        }), " überschrieben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "profile_pic"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " dieser Parameter ist ein Objekt, das zwei Eigenschaften enthält:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "src"
            }), "-Eigenschaft verwendet die HubL-Funktion ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "get_asset_url"
            }), ", um die URL für das neue Profilbild abzurufen. Beachten Sie, dass der Dateipfad des Bildes relativ zu Ihrem Arbeitsverzeichnis ist. Sie müssen dieses Bild zunächst dem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "-Ordner in Ihren lokalen Designdateien hinzufügen. Sie finden das Bild im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "-Ordner der abgeschlossenen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea/images",
              children: "Projektdateien"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), "-Eigenschaft weist den ALT-Text des Bildes zu."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "testimonial"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " dieser Parameter übergibt neuen Text in das Testimonial-Feld."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alternativ können Sie für das Rich-Text-Feld auch die HubL-Block-Syntax verwenden, um einen großen HTML- oder Textblock zu schreiben:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n path='../modules/Testimonial.module',\n customer_name = \"Mary\",\n profile_pic = {\n  src: \"{{\n   get_asset_url('../images/profile-pic-mary.svg') }}\",\n   alt: \"Mary Profile Picture\"\n   },\n offset=0,\n width=4\n%}\n {% module_attribute \"testimonial\" %}\n   Wow, what a product! I can't wait to recommend this to all my family and friends!\n {% end_module_attribute %}\n{% end_dnd_module %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "Block-Syntax von Modulen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "-Befehl in Ihrem Terminal ausgeführt haben lassen, werden Ihre Änderungen, durch Speichern an HubSpot gesendet. Sie können dann zum Design-Manager zurückkehren, um eine Vorschau der Vorlage anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Mary-Module-in-Template-12.png",
        alt: "Mary-Modul in Vorlage"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zwei weitere Testimonial-Module zur Vorlage hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Schritt fügen Sie der Vorlage zwei weitere Testimonial-Module hinzu, indem Sie die gleichen Schritte wie zuvor ausführen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie zu Ihrem Code-Editor und öffnen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "home.html"
          }), "-Datei."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Fügen Sie unter dem zuvor hinzugefügten Testimonial-Modul eine weitere Instanz des Moduls hinzu, indem Sie den Code des Moduls kopieren und einfügen. Geben Sie im neuen Testimonial-Modul die folgenden Details an, indem Sie die gleichen Schritte wie oben ausführen:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Der Name des Kunden ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ollies Testimonial lautet wie folgt: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "I can't believe that I ever conducted business without the use of this product!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Für das Bild von Ollie fügen Sie den relativen Dateipfad für die Datei im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "-Ordner hinzu. Sie können das Bild selbst aus den fertigen ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-modules-getting-started/tree/3b9159334f8c6204c7d2d34d8945d71b6ba402ea",
              children: "Projektdateien"
            }), " abrufen. Geben Sie dann Ollies Bild das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), "-Attribut ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Ollie Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Um den Abstand beizubehalten, legen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " fest."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Fügen Sie unter dem zweiten Testimonial-Modul ein drittes mit den folgenden Angaben hinzu:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Der Name des Kunden ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Erins Testimonial lautet: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "My business has nearly tripled since I began to use this product! Don't wait, start now!"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Für das Bild von Erin fügen Sie den relativen Dateipfad für die Datei im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "images"
            }), "-Ordner hinzu. Geben Sie dann Erins Bild das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), "-Attribut ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Erin Profile Picture"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Um den Abstand beizubehalten, legen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "8"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " fest."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Speichern Sie Ihre Änderungen."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "-Befehl in Ihrem Terminal ausgeführt haben lassen, können Sie zum Design-Manager zurückkehren, um eine Vorschau Ihrer Änderungen an der Vorlage anzuzeigen. Die Vorschau der Vorlage sollte nun alle drei Testimonial-Module enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/testimonial-modules-added-to-theme.png",
        alt: "testimonial-modules-added-to-theme"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bereiten Sie als letzten Schritt das Design für die Verwendung auf einer Live-Seite vor, indem Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "-Datei bearbeiten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Den Designnamen ändern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie den Standardnamen des Designs ändern möchten, können Sie dies tun, indem Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " Felder und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " Datei konfigurieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example theme.json\n\n{\n  \"name\": \"my_first_theme\",\n  \"label\": \"My first theme\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie diesen Code hinzugefügt haben, speichern Sie Ihre Änderungen, damit der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "-Befehl Ihre Änderungen an HubSpot sendet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nächste Schritte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie nun ein benutzerdefiniertes Modul erstellt, es der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "home.html"
      }), "-Vorlage hinzugefügt und optional den Designnamen angepasst haben, können Sie eine Live-Seite aus dem Design erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einem ähnlichen Tutorial folgen möchten, das sich auf Designs konzentriert, sehen Sie sich das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/getting-started",
        children: "Tutorial „Erste Schritte mit Designs“"
      }), " an. Andernfalls erfahren Sie in der Wissensdatenbank von HubSpot mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
        children: "Erstellen und Anpassen von Seiten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie mehr über Module erfahren möchten, sehen Sie sich die folgenden Modulanleitungen an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Konfigurieren eines Moduls"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Verwenden von Modulen in Vorlagen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Standardmodule"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sonstige Tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Erste Schritte mit Designs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "Erste Schritte mit serverlosen Funktionen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Erstellen eines effizienten Entwickler-Workflows"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Barrierefreiheit"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/js-frameworks",
          children: "Verwenden von JavaScript-Frameworks in CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Verwenden von Webkomponenten in benutzerdefinierten Modulen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-build-a-code-block-web-component",
          children: "Erstellen einer Code-Block-Webkomponente"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}