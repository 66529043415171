"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const slug = exports.slug = 'reference/cms/overview';
const title = exports.title = 'Resumen de referencia del CMS';
const metaDescription = exports.metaDescription = 'Documentos de referencia para desarrolladores del sistema de gestión de contenido (CMS) de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "gu%C3%ADas-de-desarrollo-en-el-cms",
  "label": "Guías de desarrollo en el CMS",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de referencia del CMS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta sección incluye documentos de referencia para desarrolladores del sistema de gestión de contenido (CMS) de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Proporciona acceso rápido a documentos de referencia relacionados con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "módulos"
      }), ", el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), "lenguaje de plantillas, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "funciones sin servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guías de desarrollo en el CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver un resumen del desarrollo en CMS Hub, consulta la sección ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "Guías de desarrollo en el CMS"
      }), ". Estas incluyen una visión general de las características y herramientas del CMS, así como una guía de inicio rápido."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;