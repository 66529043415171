"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611094;
const slug = exports.slug = 'guides/cms/tools/design-manager';
const title = exports.title = 'Design Manager';
const name = exports.name = '[new] Design Manager';
const metaDescription = exports.metaDescription = 'The Design Manager is an web-based integrated development environment that can optionally be used to build templates, modules, CSS and JavaScript.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/module%20editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'The Design Manager';
const toc = exports.toc = [{
  "depth": 0,
  "id": "design-manager-settings",
  "label": "Design Manager Settings",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Design Manager"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Design Manager is a web-based integrated development environment that can optionally be used to build ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "templates"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", CSS and JavaScript. The Design Manager can be found by navigating to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Files and Templates > Design Tools"
        })
      }), " in the top navigation bar."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you prefer to develop locally using your preferred code editor, workflows and technologies you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Regardless of whether you develop locally or utilize the Design Manager for building out assets, the entire developer file system of your account will be represented and editable in the Design Manager."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/designmanager%203-20-2020.png",
        alt: "Screenshot of HubSpot Design Manager"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Some developers or teams choose to use a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "workflow where a mix of the Design Manager and local development"
      }), " are used. You can utilize the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch"
        })
      }), " command of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), " to pull Design Manager edits down to your local environment. Alternatively, you can prevent editing and have your entire ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "codebase continuously integrated with source control"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Check out this ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "Knowledge Base article"
      }), " for a comprehensive overview of the Design Manager and its various components."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Design Manager Settings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The Design Manager has basic IDE settings you can configure to make the experience fit you and your workflows. A settings button appears adjacent to the help button in the bottom bar and can be used to configure the settings."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "You can configure settings for:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Editor theme"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Font"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tab size"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Indent unit"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Soft tabs"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Smart Indent"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Indent on formatting input"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Indent with tabs"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Line wrapping"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Auto close brackets"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Match brackets"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Match tags"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "ESLint"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["and more, see ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://knowledge.hubspot.com/cos-general/customize-design-manager-settings",
                children: "this Knowledge Base article"
              }), " for a breakdown of the settings for more information."]
            }), "\n"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["If these features don't satisfy your needs, we highly recommend trying our ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli",
              children: "local development tools"
            }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/creating-an-efficient-development-workflow",
              children: "workflow"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Design%20Manger%20Settings-1.png",
            alt: "Screenshot of design manager settings screen"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}