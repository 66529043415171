"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 182007332219;
const slug = exports.slug = 'guides/apps/marketplace/understand-app-categories';
const title = exports.title = 'App Marketplace | Understand app categories';
const name = exports.name = 'vNext Docs DP - Understand app categories';
const metaDescription = exports.metaDescription = "When submitting an app for listing on HubSpot's App Marketplace, understand app categories before selecting the category for your app.";
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Understand app categories"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "listing your app"
      }), " in the HubSpot App Marketplace, you can select up to ", (0, _jsxRuntime.jsx)("u", {
        children: "two"
      }), " categories for your app. Use the categories to define the functionality and purpose of your app."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you must be a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "super admin"
        }), " to update and submit an app listing."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Collaboration:"
        }), " these apps facilitate communication between team members by offering a convenient, informal space to directly message one another, talk as a group, and share relevant content. This category includes whiteboard apps, screen-sharing apps, virtual workspaces, and visual collaboration platforms."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ETL:"
        }), " Extract, transform, and load (ETL) apps are used to transfer data between databases or for external use.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "These apps are used for data replication, after which the data will be stored in database management systems and data warehouses. These solutions also help with data extraction for analytics."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "This category also includes Reverse ETL apps. These apps sync data from a data warehouse to several business applications. Pull data from data warehouses or any other central data repository and send this data to third-party integrations, API integrations, and tools, pre-built connectors."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Messaging Network:"
        }), " these apps serve as an internal messaging system for businesses via a text-based messaging application. Messaging network apps facilitate one-on-one, direct messaging as well as messaging within predefined groups and teams."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Partner Management:"
        }), " partner relationship management (PRM) apps, also known as partner management, give businesses tools to track sales partners and affiliates. PRM solutions offer a private portal for each partner to access documents, campaign materials, market development funds (MDF), opportunities, and deals."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Project Management:"
        }), " use these apps to plan projects, assign tasks, and organize teams. They give you real-time status updates so you can make quick decisions and control projects for any size or team. Generally, you can also track how much time each person or team spends working on various projects to improve efficiency at an organizational level."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content Management System:"
        }), " these apps help users simplify creating, editing, and publishing digital content. This category includes WordPress and apps used to build and manage content on a website, blog, or platform."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "iPaaS"
        }), ": Integration platform as a service (iPaaS) apps offer a centralized console to manage, govern, and integrate cloud-based applications. These tools work by connecting cloud applications and services and controlling integration flows."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Form and pop-up Builder"
        }), ": these apps enable non-technical users to create and deploy pop-up messages on a website to encourage users to do something, such as sign up for a newsletter.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Pop-ups can appear as modal windows or overlays displayed on top of an existing webpage. Pop-up builder software also lets users make pop-ups more personal. They can target specific groups or send pop-up messages based on what users do."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["This category also includes ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "form builder"
            }), " apps, which are used to create, personalize, and deploy forms on a website."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM:"
        }), " these apps are used to efficiently organize, monitor, and support data about existing and prospective customers. The software centralizes data from various sources to create records. The software has a repository of a complete customer database, which stakeholders use to manage long-term customer contracts and relationships."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sensitive Data:"
        }), " these apps are used to locate sensitive data—such as personally identifiable information (PII), protected health information (PHI), payment card industry (PCI) data, intellectual property (IP), and other important business data—stored across multiple company systems, including databases and applications, as well as on user endpoints."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMS:"
        }), " SMS apps, also known as business text messaging apps, enable companies to plan and implement marketing campaigns that target mobile devices via SMS (Short Message Service)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Spreadsheets:"
        }), " these apps organize, catalog, and keep your data in easy-to-understand charts and graphs. They also organize data that can be shared for real-time collaboration, undergo further analysis, or turned into visual representations"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Accounting:"
        }), " these apps are designed to manage financial transactions, expenses, income, and cash flows within an organization. They automate account payables and receivables, journal entries, ledgers, and financial statements for accounting and finance, HR, payroll processing, annuity, investment, and budget forecasting processes within different organizations."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ecommerce:"
        }), " these apps provide comprehensive software that allows organizations to manage all operations related to online sales of products or services"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Payments:"
        }), " these apps are used to process multiple types of business-to-business (B2B) payments. Companies use these apps to manage payments received from business customers and made to suppliers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Subscription Management:"
        }), " these apps track all activities related to the sale of subscription-based products."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Event Management:"
        }), " these apps are used to make event planning easier. Apps in this category manage all aspects of an event from beginning to end. This can include creating an event website, collecting registrations, and more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Social Networks:"
        }), " these apps allow individuals and companies to connect with one another to communicate and share data, often in a public forum."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Account-based marketing:"
        }), " these apps help marketing and sales departments work together by identifying good target accounts before putting in place a marketing plan that is specific to each account. These apps automate and reduce the process of identifying prospects and dedicating the right resources to nurturing the most promising accounts."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Session Replays:"
        }), " these apps record and playback a user's session when they use a website or mobile app. This helps them understand how the user uses the app better. They also show how visitors use a company's website or mobile app. This shows any problems, errors, or confusing moments they may have."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Advertising:"
        }), " these apps allow companies to buy, manage, and place display advertisements on websites, including banner, overlay, and rich media ads."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Social Media Management:"
        }), " these apps provide the functionality to administer social media accounts, schedule posts, suggest content, and boost posts"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Video:"
        }), " these apps are used to edit and modify video content. This includes converting formats, editing files, adding effects, and more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CPQ:"
        }), " Configure, price, quote (CPQ) apps automate the quoting and proposal process, starting with the moment a customer supplies their needs in a company’s offering and ending with sending a detailed quote to the customer or prospect."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conversation Intelligence:"
        }), " these apps record, transcribe, and analyze sales calls. CI apps can be used to take notes on key conversations with potential buyers, identify risky or noncompliant topics of conversation, coach new sales representatives on best practices, and more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Proposals:"
        }), " these apps streamline and automate the proposal and request for proposal (RFP) process for sales operations. Use these apps to quickly generate documents in multiple file formats, share documents through multiple channels, and track the impact of RFP and proposal documents on ‌sales success"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales Compensation:"
        }), " these apps automate the accounting and administration of commissions and incentive plans based on customizable rules such as employee role, tenure, or sale type. The software also provide salespeople with a detailed look into past earnings and forecasted revenue."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales Enablement:"
        }), " these apps store marketing materials and sales content. They give sales representatives timely, productive, and useful materials during all parts of the selling process. Sales enablement apps also ensure that any sales representative can find the appropriate content, submit it to prospects, and track prospect engagement within that content."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Competitive Intelligence:"
        }), " these apps enable businesses to capture, analyze, and take action on their competitive landscape. These apps can provide insight into advertising strategy, pricing changes, product additions, and more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-Signature:"
        }), " these apps let users sign on documents shared online. This eliminates the need for physical documents to record signatures. Use e-signature apps to protect documents, like sales contracts or employment paperwork."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lead Scoring and Routing:"
        }), " these apps are used by companies to determine the potential of each business opportunity.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "By using this type of software, companies can create scales and benchmarks to rank prospects against. These apps can help teams focus on those lead opportunities that are most likely to convert into sales."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Lead routing apps automatically qualify and distribute leads to the right sales rep or team. They use routing rules based on criteria like a rep's territory or experience."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales Intelligence:"
        }), " these apps use companies’ internal and external data to increase sales and improve sales processes. Sales intelligence apps improve the quality and quantity of sales leads by using B2B contact databases to find new opportunities and salespeople with the information they need, including contact information, job titles, and firmographics. Some apps also offer buying signals or additional insights, such as recent funding, transfer of companies, and more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Outbound calling apps:"
        }), " use these apps to call leads directly, record the call, and prospect data. These apps usually have features that let you click to call quickly. Some apps can record calls for training purposes and sort prospects based on the probability of a successful sale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Inbound calling apps:"
        }), " use these apps to attribute incoming phone calls to their respective sources by generating unique, local, and toll-free numbers for advertisements, website locations, and more. These apps often go beyond basic tracking by offering advanced call recording, monitoring, routing, and interactive voice response systems to qualify leads and provide more granular reporting."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Customer success:"
        }), " these apps track customer behavior, preferences, and usage patterns, to allow agents to coordinate their success planning with greater accuracy and prevent the likelihood of churn. Some customer success app also uses detailed analysis of past behavior to create a \"health score\" to predict a customer's future satisfaction."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Digital platform Adoption:"
        }), " A digital adoption (DAP) app is a software layer integrated on top of another software application or website to guide users through tasks and functions. Digital adoption platforms aim to help new users quickly learn how to interact with a website or application or assist returning users in learning newly added functionality."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ERP:"
        }), " Enterprise resource planning (ERP) apps manage, control, and organize daily business operations and workflows. Employees in production, manufacturing, accounting, finance, HR, and supply chain use ERP systems to support process automation, control input data, and optimize business operations to save resources."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Experience Management:"
        }), " these apps consolidate feedback from a specific, targeted audience and deliver actionable insights and follow-up steps to close the loop. Specifically, these apps deploy analytics dashboards for feedback data that is viewable by stakeholders across an organization."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Field Service:"
        }), " Field service management (FSM) apps help companies manage field-based workers by optimizing their positioning, availability, and skills as labor resources. These apps are primarily used by companies that provide on-site service and technical expertise such as equipment maintenance, delivery, and more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Learning Management System:"
        }), " these apps help companies organize, track, and manage efforts to train employees, customers, and other external partners. LMS apps are used to manage individualized training programs for onboarding, development, and compliance training purposes. Companies use a LMS to assign courses to employees or external end users, then track learners’ progress as they complete course lessons and assessments. These courses can be created using built-in tools in the LMS or a separate course authoring software."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Live Chat:"
        }), " companies use these apps to communicate with their website visitors in real time via chat windows. Customer service representatives can utilize live chat apps to provide support to users who have questions regarding products or website navigation. Some other features include reporting and analytics, interactive chat notifications, and conversation archiving."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workforce Management:"
        }), " organizations use these apps to plan, manage, and track employee work, including labor requirements, employee schedules, and paid time off (PTO). These apps are also used to to forecast labor demand, create and assign employee schedules, track attendance, and report on workforce efficiency."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales Engagement:"
        }), " these apps streamline the sales process, combining their sales and marketing efforts to create personalized and automated sales journeys; these can include emails, calls, social posts, meetings, and text messages."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Email:"
        }), " these apps send electronic mail from one user to another. This category also includes email client apps that manage a user’s email account or accounts through a desktop application. Email clients work similarly to webmail email managers provided by email apps available via browser, but are instead accessible through a downloaded program."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Direct Mail Automation:"
        }), " these apps automate the process of sending electronic or physical letters, packages, and gifts. Marketers often use direct mail automation to track and target their campaigns."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workflow Automation:"
        }), " these apps are used to automate workflows to route tasks and information between people and systems based on predefined rules and triggers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Automation:"
        }), " these apps use software to automate repetitive marketing tasks, which can increase efficiency and free up time for other projects."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SEO:"
        }), " Search engine optimization (SEO) apps, or organic search marketing software, are designed to provide information on how websites are ranked in search engines. These tools provide valuable insights for optimizing content and improving rankings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Scheduling:"
        }), " There are 2 types of scheduling apps:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Online appointment scheduling:"
            }), " these apps provide customers with a portal to book an appointment online and enables businesses to track and manage those appointments."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Business scheduling :"
            }), " these apps allows users to automatically sync multiple calendars to find shared availability without exposing individual calendars and compromising privacy."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Data Migration:"
        }), " these apps are used to move user data from one system to another."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Data Quality and Backup:"
        }), " these apps analyze sets of information and identify incorrect, incomplete, or improperly formatted data. After profiling data concerns, data quality apps cleanse or correct that data based on guidelines. Data backup apps create copies of important data and files to prevent data loss by corruption or infection by malware. This category also includes other kinds of data apps such as data enrichment apps, address verification apps, and more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Product Analytics:"
        }), " these apps provide companies visibility into user behavior by tracking and analyzing their interactions with a product."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales Analytics:"
        }), " these apps report on CRM data to reveal sales insights and forecast future performance. Sales teams and managers use sales analytics to gain visibility into sales activities; locate high or under-performing salespeople, products, or communications; and forecast future sales numbers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Analytics:"
        }), " these apps encompass tools and processes which enable an organization to manage, evaluate, and control its marketing efforts by measuring marketing performance."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connector:"
        }), " these apps allow users to connect their HubSpot portal to different platforms and services."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ticketing:"
        }), " these apps help companies manage their incoming customer support tickets."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Surveys:"
        }), " these apps allow users to create online surveys, quizzes, polls, and other web forms."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Help Desk:"
        }), " these apps are used to organize, manage, and respond to service-related requests from internal and external sources. Customer inquiries are typically submitted via multiple channels, including email, phone, or social media."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Knowledge Base:"
        }), " these apps store and organize information about businesses and their products, services, and processes in a central repository accessible by the rest of the organization."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fundraising and Non Profit:"
        }), " fundraising apps are used by nonprofit organizations to manage funding processes. Its main goal is to attract and retain donors, and to ensure their loyalty and continuous financing. Non profit apps are designed to help nonprofit and charity organizations meet the specific business needs of not for profit operations."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applicant Tracking Systems:"
        }), " these apps are used by recruiters, HR teams, and hiring managers use to source, screen, and manage job applicants for open positions.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Companies use these apps to create and distribute job postings, parse resumes for relevant information, schedule interviews, and source candidate information, cover letters, and references."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "This category also includes recruiting apps, which facilitate the hiring and onboarding of new talent through tools that create internal and external candidate pools, produce and distribute job postings, and include applicant tracking software (ATS), onboarding, and analytics."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Consent Management:"
        }), " companies use these apps to legally document and manage a user’s consent choices before collecting, sharing, or selling user data from online sources such as websites and apps that use cookies, embedded videos, and other tracking technologies."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}