"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921781;
const slug = exports.slug = 'guides/api/crm/search';
const title = exports.title = 'API de CRM  |  Buscar';
const name = exports.name = 'vNext Docs DP - Buscar';
const metaDescription = exports.metaDescription = 'Los puntos  de terminación de búsqueda de CRM hacen que la obtención de datos sea más eficiente al permitir a los desarrolladores filtrar, ordenar y buscar en cualquier tipo de objeto de CRM.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "realizar-una-solicitud-de-b%C3%BAsqueda",
  "label": "Realizar una solicitud de búsqueda",
  "parent": null
}, {
  "depth": 0,
  "id": "objetos-e-interacciones-del-crm-en-los-que-se-pueden-realizar-b%C3%BAsquedas",
  "label": "Objetos e interacciones del CRM en los que se pueden realizar búsquedas",
  "parent": null
}, {
  "depth": 1,
  "id": "objetos",
  "label": "Objetos",
  "parent": "objetos-e-interacciones-del-crm-en-los-que-se-pueden-realizar-b%C3%BAsquedas"
}, {
  "depth": 1,
  "id": "interacciones",
  "label": "Interacciones",
  "parent": "objetos-e-interacciones-del-crm-en-los-que-se-pueden-realizar-b%C3%BAsquedas"
}, {
  "depth": 0,
  "id": "buscar-propiedades-predeterminadas-que-se-pueden-buscar",
  "label": "Buscar propiedades predeterminadas que se pueden buscar",
  "parent": null
}, {
  "depth": 0,
  "id": "filtrar-resultados-de-b%C3%BAsqueda",
  "label": "Filtrar resultados de búsqueda",
  "parent": null
}, {
  "depth": 0,
  "id": "buscar-en-asociaciones",
  "label": "Buscar en asociaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "ordenar-resultados-de-b%C3%BAsqueda",
  "label": "Ordenar resultados de búsqueda",
  "parent": null
}, {
  "depth": 0,
  "id": "paginaci%C3%B3n-de-resultados",
  "label": "Paginación de resultados",
  "parent": null
}, {
  "depth": 0,
  "id": "limitaciones",
  "label": "Limitaciones",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Buscar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza los puntos de terminación de búsqueda de CRM para filtrar, clasificar y buscar\nobjetos, registros y compromisos en tu CRM. Por ejemplo, usa los puntos de terminación\npara obtener una lista de contactos en tu cuenta o una lista de todos los negocios\nabiertos. Para usar estos puntos de terminación desde una aplicación, el alcance\ndel CRM es necesario. Consulta esta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "lista de alcances disponibles"
      }), "\npara saber qué alcances detallados del CRM se pueden usar para lograr tu meta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Realizar una solicitud de búsqueda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para buscar en tu CRM, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " en el punto de terminación de búsqueda del objeto. Los puntos de terminación de búsqueda de CRM se construyen utilizando el siguiente formato: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), ". En el cuerpo de la solicitud, incluirás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#filter-search-results",
        children: "filtros"
      }), " para limitar tu búsqueda por valores de propiedad del CRM. Por ejemplo, el siguiente fragmento de código recuperaría una lista de todos los contactos que tienen una dirección de correo electrónico específica de la empresa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"CONTAINS_TOKEN\",\n          \"value\": \"*@hubspot.com\"\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada objeto que busques incluirá un conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#crm-objects",
        children: "propiedades predeterminadas"
      }), " que se devolverán. Por ejemplo, una búsqueda de contactos devolverá ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdate"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastmodifieddate"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), ". Para devolver un conjunto específico de propiedades, incluye una matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " en el cuerpo de la solicitud."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/companies/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"annualrevenue\",\n          \"operator\": \"GT\",\n          \"value\": \"10000000\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"annualrevenue\", \"name\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " incluir una matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), " anulará las propiedades devueltas por opción predeterminada, por lo que tendrás que especificar aquellas en la matriz si deseas devolverlas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos e interacciones del CRM en los que se pueden realizar búsquedas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objetos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las tablas a continuación contienen los puntos de terminación de búsqueda de objetos, los objetos a los que se refieren y las propiedades que se devuelven de forma predeterminada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Buscar punto de terminación"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedades devueltas predeterminadas"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/carts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carros"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contactos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Negocios"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "closedate,``pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deal_split/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divisiones de negocios"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/discounts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Descuentos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envíos de comentarios"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/fees/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tarifas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/invoices/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Facturas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/leads/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Leads"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/line_items/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Elementos de pedido"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/orders/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pedidos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/commerce_payments/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pagos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Productos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cotizaciones"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_expiration_date"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_status"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/subscriptions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Suscripciones (Commerce)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/taxes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Impuestos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_priority"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Interacciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La siguiente tabla contiene los puntos de terminación de búsqueda de interacciones, las interacciones a las que se refieren y las propiedades que se devuelven de forma predeterminada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Buscar punto de terminación"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Interacción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedades devueltas predeterminadas"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Llamadas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Correos electrónicos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reuniones"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Notas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tareas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Buscar propiedades predeterminadas que se pueden buscar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Busca todas las propiedades de texto predeterminadas en los registros del objeto especificado para encontrar todos los registros que tienen un valor que contiene la cadena especificada. Por opción predeterminada, los resultados se devolverán en el orden de creación de los objetos (más antiguo primero), pero puedes anular esto con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#sorting",
        children: "ordenar"
      }), ". Por ejemplo, la siguiente solicitud busca todos los contactos con un valor de propiedad de texto predeterminado que contiene la letra ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"query\": \"x\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestran las propiedades que se buscan por opción predeterminada a través del método anterior:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Buscar punto de terminación"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propiedades de búsqueda predeterminadas"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Llamadas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_body_preview"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "website"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contactos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_additional_emails"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fax"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mobilephone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_marketable_until_renewal"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/{objectType}/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos personalizados"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hasta 20 propiedades seleccionadas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Negocios"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealtype"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Correos electrónicos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envíos de comentarios"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_submission_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_content"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reuniones"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Notas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Productos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cotizaciones"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_firstname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_lastname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_proposal_slug"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_company_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_email"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_quote_number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tareas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_body"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_subject"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtrar resultados de búsqueda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa filtros en el cuerpo de la solicitud para limitar los resultados solo a los registros con valores de propiedad coincidentes. Por ejemplo, la siguiente solicitud busca todos los contactos con el nombre ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Alice."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": al filtrar resultados de búsqueda por llamadas, conversaciones, correos electrónicos, reuniones, notas o tareas, la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview_html"
        }), " no es compatible. En el caso de los correos electrónicos, las propiedades ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_email_html"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview"
        }), " tampoco son compatibles."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n            \"propertyName\": \"firstname\",\n            \"operator\": \"EQ\",\n            \"value\": \"Alice\"\n          }\n        ]\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir varios criterios de filtro, puedes agrupar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), "dentro de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        })
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para aplicar la lógica ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "AND"
        }), ", incluye una lista separada por comas de condiciones dentro de un conjunto de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para aplicar la lógica ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "OR"
        }), ", incluye múltiples ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " dentro de un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroup"
        }), ". Puedes incluir un máximo de cinco ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " con un máximo de 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " en cada grupo, con un máximo de 25 filtros en total. Si has incluido demasiados grupos o filtros, recibirás una respuesta de error ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "VALIDATION_ERROR"
        }), ". Por ejemplo, la siguiente solicitud busca contactos con el nombre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Alice"
        }), " Y un apellido que no sea ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Smith"
        }), ", O contactos que no tengan un valor para la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"firstname\",\n          \"operator\": \"EQ\",\n          \"value\": \"Alice\"\n        },\n        {\n          \"propertyName\": \"lastname\",\n          \"operator\": \"NEQ\",\n          \"value\": \"Smith\"\n        }\n      ]\n    },\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"NOT_HAS_PROPERTY\"\n        }\n      ]\n    }\n  ]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes usar operadores en filtros para especificar qué registros deben devolverse. Los valores de los filtros no distinguen entre mayúsculas y minúsculas, con las dos excepciones siguientes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Al filtrar por una propiedad de enumeración, la búsqueda distingue entre mayúsculas y minúsculas para todos los operadores de filtro."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al filtrar por una propiedad de cadena utilizando los operadores ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IN"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "NOT_IN"
        }), ", los valores buscados deben estar en minúsculas. A continuación se muestran los operadores de filtro disponibles:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que el valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que o igual al valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mayor que el valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mayor que o igual al valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Igual al valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NEQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No es igual al valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dentro del rango especificado. En tu solicitud, usa pares clave-valor para establecer ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "highValue"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            }), ". Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#between-operator",
              children: "el siguiente ejemplo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Incluido dentro de la lista especificada. Búsquedas por coincidencia exacta. En la solicitud, incluye los valores de la lista en una matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), ". Al buscar una propiedad de cadena con este operador, los valores deben estar en minúsculas. Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#in-operator",
              children: "el siguiente ejemplo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["No incluido dentro de la lista especificada En la solicitud, incluye los valores de la lista en una matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), ". Al buscar una propiedad de cadena con este operador, los valores deben estar en minúsculas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tiene un valor para la propiedad especificada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No tiene un valor para la propiedad especificada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contiene una ficha. En tu solicitud, puedes usar comodines (*) para realizar una búsqueda parcial. Por ejemplo, usa el valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "*@hubspot.com"
            }), " para recuperar contactos con una dirección de correo electrónico de HubSpot."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No contiene una ficha."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, puedes usar el operador ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BETWEEN"
      }), " para buscar todas las tareas que se modificaron por última vez dentro de un intervalo de tiempo específico:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tasks/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n   \"filterGroups\":[{\n      \"filters\":[\n        {\n          \"propertyName\":\"hs_lastmodifieddate\",\n          \"operator\":\"BETWEEN\",\n          \"highValue\": \"1642672800000\",\n          \"value\":\"1579514400000\"\n        }\n      ]\n    }]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para otro ejemplo, puedes utilizar el operador ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IN"
      }), " para buscar empresas que hayan especificado valores seleccionados en una propiedad desplegable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/companies/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n           \"propertyName\":\"enumeration_property\",\n           \"operator\":\"IN\",\n          \"values\": [\"value_1\", \"value_2\"]\n        }\n        ]\n      }\n    ],\n   \"properties\": [\"annualrevenue\", \"enumeration_property\", \"name\"]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Buscar en asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Busca registros que estén asociados con otros registros específicos mediante la pseudopropiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations.{objectType}"
      }), ". Por ejemplo, la siguiente solicitud busca todos los tickets asociados con un contacto que tiene el ID de contacto de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tickets/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filters\": [\n      {\n        \"propertyName\": \"associations.contact\",\n        \"operator\": \"EQ\",\n        \"value\": \"123\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " la opción de buscar en asociaciones de objetos personalizados no es compatible actualmente a través de los puntos de terminación de búsqueda. Para encontrar asociaciones de objetos personalizados, puedes usar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de asociaciones"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ordenar resultados de búsqueda"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usa una regla de ordenamiento en el cuerpo de la solicitud para listar los resultados en orden ascendente o descendente. Solo se puede aplicar una regla de ordenamiento a cualquier búsqueda. Por ejemplo, la siguiente solicitud ordena los contactos devueltos con la mayoría de los contactos creados recientemente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"sorts\": [\n      {\n        \"propertyName\": \"createdate\",\n        \"direction\": \"DESCENDING\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paginación de resultados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por opción predeterminada, los puntos de terminación de búsqueda devolverán páginas de 10 registros al mismo tiempo. Esto puede cambiarse configurando el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "limit"
      }), " en el cuerpo de la solicitud. El número máximo de objetos admitidos por página es 100. Por ejemplo, la solicitud siguiente devolvería páginas que contienen 20 resultados cada una."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"limit\": 20\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para acceder a la siguiente página de resultados, debes pasar un parámetro ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        })
      }), " proporcionado en la propiedad ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), " de la respuesta anterior. Si no se proporciona la propiedad ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), ", no hay resultados adicionales que mostrar. Debes formatear el valor en el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "after"
      }), " como un entero. Por ejemplo, la siguiente solicitud devolvería la siguiente página de resultados:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"after\": \"20\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es posible que los objetos del CRM recién creados o actualizados tarden unos minutos en aparecer en los resultados de búsqueda."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los objetos de CRM archivados no aparecerán en ningún resultado de búsqueda."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los puntos de terminación tienen una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "tasa limitada"
        }), " de cuatro solicitudes por segundo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Una consulta puede contener un máximo de 3.000 caracteres. Si el cuerpo de tu solicitud supera los 3.000 caracteres, se devolverá un error 400."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los puntos de terminación de búsqueda están limitados a 10.000 resultados totales para cualquier consulta dada. Tratar de obtener más de 10.000 generará un error 400."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#filter-search-results",
          children: "filtrar"
        }), ", puedes incluir un máximo de cinco ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " con hasta 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " en cada grupo para un máximo de 25 filtros en total."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cuando buscas números de teléfono, HubSpot usa propiedades calculadas especiales para estandarizar el formato. Todas estas propiedades comienzan con ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_searchable_calculated_*"
        }), ". Como parte de esta estandarización, HubSpot solo utiliza el código de área y el número local. Debes abstenerte de incluir el código de país en tus criterios de búsqueda o filtro."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}