"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044566;
const slug = exports.slug = 'guides/cms/content/multi-language-content';
const title = exports.title = 'Mehrsprachige Inhalte';
const name = exports.name = 'EMEA DACH Multi-language';
const metaDescription = exports.metaDescription = 'Entwickler, die Websites in HubSpot erstellen, können problemlos eine mehrsprachige Website unterstützen. Je nach Art Ihres Unternehmens kann ein gewisser Entwicklungsaufwand erforderlich sein.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "standardm%C3%A4%C3%9Fige-funktionen-von-hubspot-f%C3%BCr-mehrsprachigkeit",
  "label": "Standardmäßige Funktionen von HubSpot für Mehrsprachigkeit",
  "parent": null
}, {
  "depth": 1,
  "id": "was-hubspot-nicht-tut",
  "label": "Was HubSpot nicht tut",
  "parent": "standardm%C3%A4%C3%9Fige-funktionen-von-hubspot-f%C3%BCr-mehrsprachigkeit"
}, {
  "depth": 0,
  "id": "sprachvariablen-festlegen",
  "label": "Sprachvariablen festlegen",
  "parent": null
}, {
  "depth": 0,
  "id": "seitenbearbeitbare-module-verwenden",
  "label": "Seitenbearbeitbare Module verwenden",
  "parent": null
}, {
  "depth": 0,
  "id": "feld%C3%BCbersetzungen-in-benutzerdefinierten-modulen-und-designs-ber%C3%BCcksichtigen",
  "label": "Feldübersetzungen in benutzerdefinierten Modulen und Designs berücksichtigen",
  "parent": null
}, {
  "depth": 1,
  "id": "lokale-entwicklung",
  "label": "Lokale Entwicklung",
  "parent": "feld%C3%BCbersetzungen-in-benutzerdefinierten-modulen-und-designs-ber%C3%BCcksichtigen"
}, {
  "depth": 1,
  "id": "design-manager",
  "label": "Design-Manager",
  "parent": "feld%C3%BCbersetzungen-in-benutzerdefinierten-modulen-und-designs-ber%C3%BCcksichtigen"
}, {
  "depth": 0,
  "id": "systemseiten-%C3%BCbersetzen",
  "label": "Systemseiten übersetzen",
  "parent": null
}, {
  "depth": 0,
  "id": "einen-sprachumschalter-ber%C3%BCcksichtigen",
  "label": "Einen Sprachumschalter berücksichtigen",
  "parent": null
}, {
  "depth": 0,
  "id": "implementieren-einer-suchfunktion-auf-mehrsprachigen-websites",
  "label": "Implementieren einer Suchfunktion auf mehrsprachigen Websites",
  "parent": null
}, {
  "depth": 0,
  "id": "globale-teilvorlagen-und-module-verwenden",
  "label": "Globale Teilvorlagen und Module verwenden",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      h3: "h3",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Mehrsprachige Inhalte"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jedes Unternehmen, das regional oder mit einer Kundenbasis, die mehrere Sprachen spricht, Geschäfte tätigt, muss in der Lage sein, mit seiner Zielgruppe in dessen Sprache in Kontakt zu treten. Mit dem CMS von HubSpot können Benutzer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-general/how-to-manage-multi-language-content-with-hubspots-cos",
        children: "mehrsprachige Varianten ihrer Inhalte erstellen"
      }), ", die es dem Endbenutzer ermöglichen, die Inhalte in der Sprache anzuzeigen, die sie am besten beherrschen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot legt eine Anzahl von Aspekten einer mehrsprachigen Website automatisch für Sie fest. Entwickler sollten jedoch ebenfalls durch eine Reihe von Schritten sicherstellen, dass ihre Website mehrsprachig ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standardmäßige Funktionen von HubSpot für Mehrsprachigkeit"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn eine mehrsprachige Variante für eine Seite in HubSpot erstellt wird, wird automatisch Folgendes durchgeführt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Es wird ein neuer Eintrag in der XML-Sitemap mit dem Namen und der URL der übersetzten Seite erstellt."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Es wird die Sprache des Inhalts im Seiten-", (0, _jsxRuntime.jsx)(_components.code, {
            children: "<head>"
          }), " für Vorlagen angegeben, die mit Drag-&-Drop-Funktionen erstellt wurden."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Es werden andere Seiten innerhalb der mehrsprachigen Inhaltsgruppe gemäß dem entsprechenden standardisierten Format identifiziert, das die anderen Seiten als Alternativen markiert, um doppelte Inhaltsfehler zu vermeiden, und es wird außerdem der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
            children: "ISO 639-1"
          }), "-Code identifiziert, der mit der/den Sprachübersetzung/en verknüpft ist:"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "<link rel=\"alternate\" hreflang=\"[**_lang_code_**](https://support.google.com/webmasters/answer/189077#language-codes)\" href=\"_url_of_page_\" />"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Schreiben Sie Links auf Sprachseiten neu, um zu intrasprachlichen Versionen der verlinkten Seite zu gelangen, damit die Besucher in der Sprache bleiben und Sie nicht jeden einzelnen Link bei jeder Seitenübersetzung aktualisieren müssen. Für ein bestimmtes Element können Sie dieses Neuschreiben deaktivieren, indem Sie dem Element die Klasse „hs-skip-lang-url-rewrite“ hinzufügen."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Was HubSpot nicht tut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit CMS Hub erledigt HubSpot Folgendes nicht automatisch:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "den Inhalt der Seite für Sie übersetzen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Benutzer basierend auf ihrem GeoIP zu einer mehrsprachigen Variante weiterleiten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ein Sprachumschaltmodul in Ihrem Header oder auf Ihrer Website berücksichtigen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "die Sprache einer Seite für Code-Dateien angeben."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "das Inhaltsrichtungsattribut für Übersetzungen mit einer Sprache festlegen, die von rechts nach links liest, im Gegensatz zu von links nach rechts für Code-Dateien."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sprachvariablen festlegen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da Code-Dateien nicht automatisch Sprachdeklarationen oder Sprachrichtungsattribute für Inhalte enthalten, muss dies für Code-Vorlagen manuell eingerichtet werden. Sprachvariablen können in HTML festgelegt oder über HubL ausgefüllt werden, wie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L2",
        children: "CMS Boilerplate-Vorlage"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie diese Eigenschaften mithilfe von HubL festlegen, können diese Daten dynamisch im HTML-Format einer Seite basierend auf der Sprache, die für die Seite in CMS Hub festgelegt ist, angezeigt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seitenbearbeitbare Module verwenden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um sicherzustellen, dass Inhalte in jeder Instanz der Verwendung einer Vorlage lokalisiert werden können, verwenden Sie nach Möglichkeit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "benutzerdefinierte Module"
      }), " anstelle von fest kodierten HTML-Inhalten. Das Erstellen von Modulen, die auf Seitenebene bearbeitet werden können, ermöglicht es Content-Autoren, den spezifischen Inhalt festzulegen, der auf jeder Seite angezeigt werden soll, ohne den Vorlagencode anpassen zu müssen. Außerdem können so individuelle Inhalte auf Seiten verwendet werden, die eine Vorlage teilen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feldübersetzungen in benutzerdefinierten Modulen und Designs berücksichtigen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um Ihr globales Team zu unterstützen, können Sie Übersetzungen von Modulen veröffentlichen, die Sie in HubSpot erstellt haben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie den Content des Moduls übersetzt und in den Sprachen Ihrer Teammitglieder veröffentlicht haben, sehen Benutzer die Feldbezeichnungen für dieses Modul in ihrer Account-Standardsprache. Der Inhalt in einem übersetzten Modul wird nicht automatisch übersetzt. Dies muss von Ihnen durchgeführt werden. Sie können Übersetzungen Ihres Moduls in jeder unterstützten Sprache erstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Übersetzungen mithilfe der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#local-module-file-structure",
        children: "Tools für lokale Entwicklung"
      }), " oder über den Design-Manager festlegen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Lokale Entwicklung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Übersetzungen mithilfe von Tools für lokale Entwicklung festzulegen, kann jeder Modulordner und jeder Designordner einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_locales"
      }), "-Ordner mit Unterordnern für Sprachgebietsschemata enthalten, die jeweils eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "messages.json"
      }), "-Datei mit Modulfeldübersetzungen enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/local%20module%20translations.png",
        alt: "Screenshot für die lokale Bearbeitung von Modulübersetzungen in VS-Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Design-Manager"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Feldübersetzungen über den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Design-Manager"
      }), " festzulegen, gehen Sie beim Anzeigen des Moduls zur Option „Übersetzungen hinzufügen“ auf der rechten Seite des Bildschirms. Wählen Sie im Dropdown-Menü die Sprachen aus, in denen Ihr Team arbeitet. Von dort aus können Sie jede Sprache auswählen und die Bezeichnungskonventionen für jedes Feld in jeder Sprache angeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/field_translations.gif",
        alt: "Screenshot von Feldübersetzungen im Design-Manager"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Designfeldübersetzungen haben keine Schnittstelle im Design-Manager und müssen über die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".json"
        }), "-Dateien bearbeitet werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Systemseiten übersetzen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Übersetzungen für Systemseiten festzulegen, einschließlich Seiten zum Zurücksetzen des Passworts und für E-Mail-Abo-Einstellungen, können Sie Modul- und HubL-Tag-Felder mit Ihrem übersetzten Inhalt anpassen. Erfahren Sie mehr über die verfügbaren Felder für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "Module"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#system-page-tags",
        children: "HubL-Tags für Systemseiten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Sprachumschalter berücksichtigen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Damit Endbenutzer zwischen verfügbaren Übersetzungen wechseln können, sollte Ihrer Website ein Sprachumschaltmodul hinzugefügt werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/09ad0b2c3bbc8400e64c5617268cb0504392e8e5/src/templates/partials/header.html#L26-L47",
        children: "Beispiel für die Implementierung eines Sprachumschalters"
      }), " finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "CMS Hub Boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Header navigation row one #}\n      <div class=\"header__row-1\">\n        {% if content.translated_content.values()|selectattr('published')|length || is_listing_view && group.translations %}\n          <div class=\"header__language-switcher header--element\">\n            <div class=\"header__language-switcher--label\">\n              {% module 'language-switcher' path='@hubspot/language_switcher',\n                label='Language switcher',\n                display_mode='localized'\n              %}\n              <div class=\"header__language-switcher--label-current\"> {{ locale_name(locale) }}</div>\n            </div>\n          </div>\n        {% endif %}\n        <div class=\"header__search header--element\">\n          {% module 'site_search' path='@hubspot/search_input',\n            label='Search',\n            field_label='Search',\n            placeholder=''\n          %}\n        </div>\n      </div>\n      {# End header navigation row one #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementieren einer Suchfunktion auf mehrsprachigen Websites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "Inhaltssuche"
      }), " unterstützt die Abfrage von Inhalten in den verschiedenen Sprachen auf Ihrer Website. Der Sprachfilter-Parameter kann beim Aufruf von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "/contentsearch/v2/search"
      }), " verwendet werden, um nur bestimmte Sprachen zurückzugeben. So können Sie Suchfunktionen für jede Sprache auf Ihrer Website erstellen oder Besucher über mehrere Sprachen auf Ihrer Website suchen lassen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Globale Teilvorlagen und Module verwenden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie Modulfelder, damit Text in Headern, Footern und Seitenleisten bearbeitet werden kann. Platzieren Sie diese Module in globale Teilvorlagen. Content-Autoren profitieren nicht nur von der einfachen Bearbeitung, globale Teilvorlagen unterstützen außerdem", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-general/how-to-manage-multi-language-content-with-hubspots-cos#edit-global-content-in-a-multi-language-page",
        children: "das Konfigurieren ihrer Einstellungen für die einzelnen Sprachen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/edit-multilanguage-global-content.png",
        alt: "Screenshot des Seiten-Editors mit teilweiser Darstellung des Headers"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}