"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76426887833;
const slug = exports.slug = 'guides/apps/private-apps/overview';
const title = exports.title = 'Private Apps';
const name = exports.name = 'EMEA DACH (DE) Private Apps';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie private Apps in HubSpot erstellen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-private-app-erstellen",
  "label": "Eine private App erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "api-aufrufe-mit-dem-zugriffstoken-ihrer-app-durchf%C3%BChren",
  "label": "API-Aufrufe mit dem Zugriffstoken Ihrer App durchführen",
  "parent": null
}, {
  "depth": 0,
  "id": "informationen-zu-zugriffstoken-f%C3%BCr-private-apps-anzeigen",
  "label": "Informationen zu Zugriffstoken für private Apps anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "das-zugriffstoken-rotieren",
  "label": "Das Zugriffstoken rotieren",
  "parent": null
}, {
  "depth": 0,
  "id": "api-aufrufprotokolle-anzeigen",
  "label": "API-Aufrufprotokolle anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "limits-f%C3%BCr-private-apps",
  "label": "Limits für private Apps",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-private-app-l%C3%B6schen",
  "label": "Eine private App löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe von privaten Apps können Sie die APIs von HubSpot nutzen, um auf bestimmte Daten Ihres HubSpot-Accounts zuzugreifen. Sie können autorisieren, was jede private App in Ihrem Account anfordern oder ändern darf. Dadurch wird ein Zugriffstoken generiert, das für Ihre App einzigartig ist."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für den Zugriff auf private Apps in Ihrem HubSpot-Account müssen Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "Super-Admin"
      }), " sein."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Private Apps unterstützen benutzerdefinierte Chronikeinträge nicht. Webhooks werden ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "in privaten Apps unterstützt"
        }), ", aber Abonnements können nicht programmgesteuert über eine API bearbeitet werden und müssen stattdessen in den Einstellungen Ihrer privaten App bearbeitet werden."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn Sie vorhaben, eine App mit benutzerdefinierten Chronikeinträgen zu erstellen, sollten Sie stattdessen eine öffentliche App erstellen. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "Unterschiede zwischen privaten und öffentlichen Apps"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine private App erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahnrad-Symbol"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrationen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private App erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Konfigurieren Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Grundlegende Informationen"
        }), " die Details Ihrer App:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Namen"
            }), " Ihrer App ein."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Bewegen Sie den Mauszeiger über das Platzhalterlogo und klicken Sie auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Symbol zum Hochladen"
            }), ", um ein quadratisches Bild hochzuladen, das als Logo für Ihre App dienen soll."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie eine ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            }), " für Ihre App ein."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bereiche"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aktivieren Sie das Kontrollkästchen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lesen"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Schreiben"
        }), " für jeden Bereich, auf den Ihre private App zugreifen können soll. Sie können auch über die Suchleiste ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Bereich suchen"
        }), " nach einem bestimmten Bereich suchen. Eine vollständige Liste der verfügbaren Bereiche finden Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/other-resources/scopes",
          children: "diesem Referenzartikel"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ist Ihre App fertig konfiguriert, klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App erstellen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/updated-read-write-scope-config-private-apps.png",
        alt: "updated-read-write-scope-config-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Überprüfen Sie im Dialogfeld die Informationen zum Zugriffstoken Ihrer App und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mit dem Erstellen fortfahren"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald Ihre App erstellt ist, können Sie mithilfe des Zugriffstokens der App API-Aufrufe durchführen. Wenn Sie die Informationen zu Ihrer App bearbeiten oder ihre Bereiche ändern müssen, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Details bearbeiten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/edit-details-of-private-app.png",
        alt: "edit-details-of-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API-Aufrufe mit dem Zugriffstoken Ihrer App durchführen"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Private Apps verlieren den Zugriff auf Bereiche, wenn Ihr HubSpot-Account herabgestuft wird, und verlieren den Zugriff auf Funktionen. Wenn Ihr Account beispielsweise keinen Zugriff auf HubDB hat, hat Ihre private App keinen Zugriff auf den HubDB-Bereich."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Zugriffstoken privater Apps werden aktualisiert, um die verfügbaren Bereiche in Ihrem HubSpot-Account und Ihre Konfiguration der privaten App widerzuspiegeln. Die Token-Zeichenfolge selbst ändert sich jedoch nicht."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um API-Aufrufe durchzuführen, gehen Sie zur Detailseite Ihrer App."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf der ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Zugriffstoken"
        }), "-Karte auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Token anzeigen"
        }), ", um Ihr Zugriffstoken offenzulegen. Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kopieren"
        }), ", um das Token in Ihre Zwischenablage zu kopieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
        alt: "show-private-app-access-token-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie können dann das Zugriffstoken einfügen, um es Ihren Entwicklern zur Verfügung zu stellen, oder es selbst bei der Entwicklung Ihrer App verwenden. Legen Sie beim Aufrufen eines HubSpot-API-Endpunkts den Wert des Feldes ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Autorisierung"
        }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bearer [YOUR_TOKEN]"
        }), ". Wenn Sie zum Beispiel die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Kontakte-API"
        }), " mit Node.js und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), " aufrufen, würde die Anfrage wie folgt aussehen:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Zugriffstoken von privaten Apps werden auf Basis von OAuth implementiert, sodass Sie auch authentifizierte Aufrufe mit Ihrem Zugriffstoken unter Verwendung einer der HubSpot-Client-Bibliotheken durchführen können. Wenn Sie zum Beispiel die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/hubspot-api-nodejs",
          children: "Node.js-Client-Bibliothek"
        }), " verwenden, können Sie einen OAuth-Client instanziieren, indem Sie das Zugriffstoken Ihrer App übergeben:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informationen zu Zugriffstoken für private Apps anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Informationen zum Zugriffstoken einer privaten App anzuzeigen, z. B. die Hub-ID und mit dem Token verbundene Bereiche, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v2/private-apps/get/access-token-info"
      }), " durch. Geben Sie im Text der Anfrage Ihr Zugriffstoken an:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request response body\n{\n  \"tokenKey\": {{accessToken}}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Antwort enthält Informationen zum Benutzer, der das Token erstellt hat, die Hub-ID des Accounts, die ID der privaten App und die mit dem Token verbundenen Bereiche."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response body\n{\n  \"userId\": 123456,\n  \"hubId\": 1020304,\n  \"appId\": 2011410,\n  \"scopes\": [\"oauth\", \"crm.schemas.companies.write\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Das Zugriffstoken rotieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihr Zugriffstoken verloren gegangen ist oder anderweitig kompromittiert wurde, können Sie das Token rotieren. Es wird ein neues Zugriffstoken erstellt und das ursprüngliche Zugriffstoken verfällt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf die Seite mit den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Einstellungen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrationen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " Ihrer privaten App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie neben Ihrem Zugriffstoken auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rotieren"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn Ihr Token kompromittiert wurde und Sie den Zugriff sofort widerrufen möchten, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Rotieren und jetzt verfallen lassen"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Auch wenn keine unmittelbare Bedrohung für Ihr Token besteht, wird empfohlen, es alle sechs Monate zu rotieren. Wenn Sie bereit sind, ein regelmäßiges Rotieren Ihres Tokens zu initiieren, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Rotieren und später verfallen lassen"
            }), ", wodurch das Token in 7 Tagen abläuft.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Wenn Ihre App schon früher umgestellt werden kann, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Jetzt verfallen lassen"
                }), "."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Wenn Sie entscheiden, dass Sie mehr Zeit benötigen, können Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Rotieren abbrechen"
                }), " klicken, wodurch das Verfallen des ursprünglichen Tokens aufgehoben wird und das neue Zugriffstoken widerrufen wird."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/rotate-private-app-access-token.png",
        alt: "rotate-private-app-access-token"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot sendet auch E-Mail-Benachrichtigungen an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "Super-Admins"
      }), " mit Erinnerungen an den Status der Zugriffstoken-Rotation sowie andere damit verbundene Warnungen. Super-Admins in Ihrem HubSpot-Account erhalten Benachrichtigungen für die folgenden Ereignisse und Erinnerungen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein Super-Admin hat eine Rotation initiiert (entweder sofort oder in 7 Tagen)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein Super-Admin hat eine ausstehende Rotation abgebrochen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ein Super-Admin hat sich dafür entschieden, ein Zugriffstoken sofort verfallen zu lassen, indem er auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Jetzt verfallen lassen"
        }), " geklickt hat, anstatt 7 Tage auf das Verfallen des Tokens zu warten."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das Zugriffstoken der App läuft in 24 Stunden ab."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das Zugriffstoken der App wurde rotiert und ist nach 7 Tagen abgelaufen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie Ihr Zugriffstoken seit über 180 Tagen nicht mehr rotiert haben, erhalten Super-Admins auch eine Erinnerungs-E-Mail, um das Zugriffstoken Ihrer App zu rotieren."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API-Aufrufprotokolle anzeigen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So überprüfen Sie die API-Aufrufe, die Ihre App in den letzten 30 Tagen durchgeführt hat:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf der Detailseite Ihrer App auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Protokolle"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Überprüfen und filtern Sie die API-Aufrufe Ihrer privaten App:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Klicken Sie auf die Dropdown-Menüs ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Methode"
            }), " und ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Antwort"
            }), ", um Ihre historischen API-Aufrufe nach Anfragemethode oder Antwortcode zu filtern."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Klicken Sie auf das Dropdown-Menü für das ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Startdatum"
            }), " oder das ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enddatum"
            }), ", um Ihre Aufrufprotokolle auf einen bestimmten Zeitraum einzugrenzen."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sie können auch über das Suchfeld ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Suche nach Anfrage-URL"
            }), " nach bestimmten Aufrufen suchen."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/private-app-logs-tab-updated.png",
        alt: "private-app-logs-tab-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um die API-Aufrufprotokolle zu exportieren, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Protokolle exportieren (CSV)"
        }), ". Geben Sie dann einen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Datumsbereich"
        }), " (bis zu den letzten 30 Tagen) an und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportieren"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie im Pop-up-Feld den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Datumsbereich"
        }), " der API-Aufrufe aus, die Sie exportieren möchten, und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportieren"
        }), ". Sie erhalten eine E-Mail mit einem Download-Link, sobald der Export zum Download bereitsteht."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits für private Apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können bis zu 20 private Apps in Ihrem HubSpot-Account erstellen. Jede private App unterliegt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "den Richtlinien zur API-Nutzung von HubSpot"
      }), ". Die Anzahl der Aufrufe, die Ihre private App durchführen kann, hängt von Ihrem Account-Abonnement ab und davon, ob Sie das API-Add-on erworben haben:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Produktstufe"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Pro 10 Sekunden"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Pro Tag"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private Apps"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Jede Version)Kostenlose Version und Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100/private App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250.000/Account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Jede Version)Pro und Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150/private App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500.000/Account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private Apps mit API-Add-on"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Jede Version)Kostenlose Version, Starter, Professional und Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200/private App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1.000.000/Account"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie sowohl eine Starter- als auch eine Professional-Version haben, gelten für Ihren Account Limits für die höhere Stufe (Professional)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "\\-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/account-info/v3/api-usage/daily/private-apps"
      }), " durchführen, um die tägliche API-Nutzung für alle privaten Apps in Ihrem HubSpot-Account zu überprüfen. Erfahren Sie mehr über die Verwendung der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "Account-Informationen-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine private App löschen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie eine Ihrer privaten Apps löschen, wird ihr Zugriffstoken dauerhaft widerrufen und Sie können sie nicht mehr für API-Aufrufe verwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So löschen Sie eine App:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahnrad-Symbol"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " Ihrer privaten App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben auf der Seite auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentifizierung"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie unten auf der Seite auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "[Name Ihrer App] löschen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Dialogfeld den Namen Ihrer App ein, um deren Löschung zu bestätigen, und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Löschen"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}