"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'reference/api/cms/hubdb/v2';
const title = exports.title = 'HubDB API - V2';
const name = exports.name = 'HubDB API - V2';
const metaDescription = exports.metaDescription = 'HubDB API - V2';
const orderedTitles = exports.orderedTitles = ['Get all tables', 'Get details for a specific table', 'Get table rows', 'Create a new Table', 'Clone a Table', 'Add a new row to a table', 'Import a CSV to the draft version of a table', 'Update a table', 'Update a row', 'Update a specific cell', 'Publish the draft data for a table', 'Revert the draft data for a table', 'Delete a row', 'Delete a cell from a row', 'Delete a table'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET', 'GET', 'GET', 'POST', 'POST', 'POST', 'POST', 'PUT', 'PUT', 'PUT', 'PUT', 'PUT', 'DELETE', 'DELETE', 'DELETE'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h4: "h4",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointRightColumn,
      EndpointExample,
      EndpointParameter
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(Alert, {
      type: "danger",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " as of January 31, 2024, the HubDB v2 API has been sunsetted.\nThe associated endpoints will not be fully turned off until a future date in\n2024, but if you're using this API, please migrate to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/cms/hubdb",
          children: "HubDB v3\nAPI"
        }), ", which includes many performance, usability, and\nAPI consistency improvements over the previous two versions. Learn more about\n", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-sunset-hubdb-v1-api-hubdb-v2-api-and-hubdb-javascript-client",
          children: "this sunset on the HubSpot Developer\nChangelog"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get all tables"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/hubdb/api/v2/tables"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get all of the HubDB tables for the specified portal. This will return the details for each table, including the column definitions."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Example URL: ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/hubdb/api/v2/tables",
            children: "https://api.hubapi.com/hubdb/api/v2/tables"
          })]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Note: Unlike other GET endpoints, getting all tables will require authentication."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {})]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzCgpFeGFtcGxlIFJlc3BvbnNlOgp7CiAgIm9iamVjdHMiOiBbCiAgICB7CiAgICAgICJpZCI6IDMwMDA4MSwKICAgICAgIm5hbWUiOiAiRXhhbXBsZSBUYWJsZSAxIiwKICAgICAgImNyZWF0ZWRBdCI6IDE0Nzk0Nzk5NzcyODYsCiAgICAgICJwdWJsaXNoZWRBdCI6IDE0Nzk0ODAwMDU2MDYsCiAgICAgICJ1cGRhdGVkQXQiOiAxNDc5NDgwMDA1NzgxLAogICAgICAiY29sdW1ucyI6IFsKICAgICAgICB7CiAgICAgICAgICAibmFtZSI6ICJOYW1lIiwKICAgICAgICAgICJsYWJlbCI6IG51bGwsCiAgICAgICAgICAiaWQiOiAxLAogICAgICAgICAgInR5cGUiOiAiVEVYVCIKICAgICAgICB9LAogICAgICAgIHsKICAgICAgICAgICJuYW1lIjogImRhdGVjb2wiLAogICAgICAgICAgImxhYmVsIjogImRhdGVjb2wiLAogICAgICAgICAgImlkIjogMiwKICAgICAgICAgICJ0eXBlIjogIkRBVEUiCiAgICAgICAgfQogICAgICBdLAogICAgICAiZGVsZXRlZCI6IGZhbHNlLAogICAgICAidXNlRm9yUGFnZXMiOiBmYWxzZSwKICAgICAgInJvd0NvdW50IjogMCwKICAgICAgImNyZWF0ZWRCeSI6IG51bGwsCiAgICAgICJ1cGRhdGVkQnkiOiBudWxsLAogICAgICAiY29sdW1uQ291bnQiOiAyCiAgICB9LAogICAgewogICAgICAiaWQiOiAzMDAwODgsCiAgICAgICJuYW1lIjogIkV4YW1wbGUgVGFibGUgMiIsCiAgICAgICJjcmVhdGVkQXQiOiAxNDc5NzQ3Nzg5NTU1LAogICAgICAicHVibGlzaGVkQXQiOiBudWxsLAogICAgICAidXBkYXRlZEF0IjogMCwKICAgICAgImNvbHVtbnMiOiBbCiAgICAgICAgewogICAgICAgICAgIm5hbWUiOiAiTmFtZSIsCiAgICAgICAgICAibGFiZWwiOiBudWxsLAogICAgICAgICAgImlkIjogMSwKICAgICAgICAgICJ0eXBlIjogIlRFWFQiCiAgICAgICAgfQogICAgICBdLAogICAgICAiZGVsZXRlZCI6IGZhbHNlLAogICAgICAidXNlRm9yUGFnZXMiOiBmYWxzZSwKICAgICAgInJvd0NvdW50IjogMCwKICAgICAgImNyZWF0ZWRCeSI6IG51bGwsCiAgICAgICJ1cGRhdGVkQnkiOiBudWxsLAogICAgICAiY29sdW1uQ291bnQiOiAxCiAgICB9CiAgXSwKICAidG90YWwiOiAyLAogICJsaW1pdCI6IDAsCiAgIm9mZnNldCI6IDAsCiAgIm1lc3NhZ2UiOiBudWxsLAogICJ0b3RhbENvdW50IjogMgp9"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get details for a specific table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/hubdb/api/v2/tables/:tableId"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get the details for a specific HubDB table. This will include the definitions for the columns in the table, as well as the number of rows in the table."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can get the draft version of the table by including ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/draft"
          }), " at the end of the request path. See the example for details. ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note"
          }), ": You must include ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/oauth/tokens",
            children: "authentication"
          }), " when requesting the draft version."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Example URL: ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/hubdb/api/v2/tables/300081?portalId=62515",
            children: "https://api.hubapi.com/hubdb/api/v2/tables/300081?portalId=62515"
          })]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "portalId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The Hub ID that the table belongs to."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table that you're looking for."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "draft",
            required: false,
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/draft"
              }), " at the end of the request path to get the draft details of the table."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MT9wb3J0YWxJZD02MjUxNQoKRXhhbXBsZSBHRVQgVVJMIGZvciB0aGUgZHJhZnQgZGV0YWlsczoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MS9kcmFmdAoKRXhhbXBsZSBSZXNwb25zZToKewogICJpZCI6IDMwMDA4MSwKICAibmFtZSI6ICJFeGFtcGxlIFRhYmxlIDEiLAogICJjcmVhdGVkQXQiOiAxNDc5NDc5OTc3Mjg2LAogICJwdWJsaXNoZWRBdCI6IDE0Nzk0ODAwMDU2MDYsCiAgInVwZGF0ZWRBdCI6IDE0Nzk0ODAwMDU3ODEsCiAgImNvbHVtbnMiOiBbCiAgICB7CiAgICAgICJuYW1lIjogIk5hbWUiLAogICAgICAibGFiZWwiOiBudWxsLAogICAgICAiaWQiOiAxLAogICAgICAidHlwZSI6ICJURVhUIgogICAgfSwKICAgIHsKICAgICAgIm5hbWUiOiAiZGF0ZWNvbCIsCiAgICAgICJsYWJlbCI6ICJkYXRlY29sIiwKICAgICAgImlkIjogMiwKICAgICAgInR5cGUiOiAiREFURSIKICAgIH0KICBdLAogICJkZWxldGVkIjogZmFsc2UsCiAgInVzZUZvclBhZ2VzIjogZmFsc2UsCiAgInJvd0NvdW50IjogMCwKICAiY3JlYXRlZEJ5IjogbnVsbCwKICAidXBkYXRlZEJ5IjogbnVsbCwKICAiY29sdW1uQ291bnQiOiAyCn0="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get table rows"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/hubdb/api/v2/tables/:tableId/rows"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "This endpoint is used to get the rows for a specific HubDB table. The results can be filtered and ordered using the options detailed below."
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Draft versions"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can get the draft version of the table by including ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/draft"
          }), " at the end of the request path. See the example for details. ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note"
          }), ": You must include ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/oauth/tokens",
            children: "authentication"
          }), " when requesting the draft version."]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Filtering"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In addition to the below parameters, you can filter the results by specific data. Filters are applied as query parameters, by adding the column name, followed by two underscores (_), and then the operator. For example, if you have a number column named 'bar', you can filter the results to only include rows where the 'bar' column is greater than 10 using this parameter: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "&bar__gt=10"
          }), ". Any number of filters can be included as query parameters in the request URL. All filters are ANDed together. ORing filters is not currently supported."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Operators can only be applied to specific column types. When passing values for MULTISELECT columns, the ids or names should be separated by commas (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "multiselect_column__contains=1,2"
          }), ") For the purposes of these filters, the built in column hs_id is a NUMBER column, the hs_created_at column is a DATETIME, and the hs_path column is a TEXT column."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For DATETIME filters, you can use relative dates in place of timestamps in order to specify a value relative to the current time. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "-3h"
          }), " would correspond to the timestamp 3 hours before now, whereas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "10s"
          }), " would correspond to 10 seconds in the future. Supported timeunits are ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "ms"
          }), " (milliseconds), ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "s"
          }), " (seconds), ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "m"
          }), " (minutes), ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "h"
          }), " (hours), ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "d"
          }), " (days). Current time can be used by specifying a zero value: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "0s"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "Filter name"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Operator"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Works with"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Equals"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "eq (or none)"
              }), (0, _jsxRuntime.jsxs)(_components.td, {
                children: ["All column typesThis filter is applied if no operator is used", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "&example_column=value"
                }), "When used with MULTISELECT columns, returns rows that exactly match supplied values"]
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Not equal"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "ne"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "All column types"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Contains"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "contains"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "TEXT, RICHTEXT, MULTISELECTWhen used with MULTISELECT columns, returns rows that contain all of the supplied values."
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Less than"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "lt"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "NUMBER, DATE, DATETIME"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Less than or equal"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "lte"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "NUMBER, DATE, DATETIME"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Greater than"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "gt"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "NUMBER, DATE, DATETIME"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Greater than or equal"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "gte"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "NUMBER, DATE, DATETIME"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Null"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "is_null"
              }), (0, _jsxRuntime.jsxs)(_components.td, {
                children: ["All column types, except BOOLEANThis filter does not require a value (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "&example_column__is_null="
                }), ")"]
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Not null"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "not_null"
              }), (0, _jsxRuntime.jsxs)(_components.td, {
                children: ["All column types, except BOOLEANThis filter does not require a value (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "&example_column__not_null="
                }), ")"]
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Like"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "like"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "TEXT, RICHTEXT"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Not like"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "not_like"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "TEXT, RICHTEXT"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Contains (case insensitive)"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "icontains"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "TEXT, RICHTEXT"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "Starts with"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "startswith"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "TEXT, RICHTEXT"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "In"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "in"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "NUMBER, SELECT, MULTISELECTReturns rows where the column includes at least one of the passed options. When there is no other Order By in the query parameter, the results will be sorted in the order in which values are specified in the 'in' operator."
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "portalId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The Hub ID that the table belongs to."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table that you're looking for."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "draft",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/draft"
              }), " at the end of the request path to get the draft details of the table."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "limit",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The maximum number of rows returned in the response. Defaults to 1000."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "offset",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Used to page through the results if the number of rows in the table is higher than the limit parameter."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "orderBy",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Return the rows in the natural order of the specified column. You can reverse the sort by adding a - to the column name: ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "orderBy=-bar"
              }), ". You can include this parameter multiple times to sort by multiple columns.In addition to ordering by a column, there are three functions that can be used:", (0, _jsxRuntime.jsxs)("ul", {
                children: [(0, _jsxRuntime.jsxs)("li", {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "geo_distance(location_column_name, latitude, longitude)"
                  }), " Takes the name of a location column and coordinates, returns the rows ordered by how far away the value of the specified location column are from the provided coordinates."]
                }), (0, _jsxRuntime.jsxs)("li", {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "length(column_name)"
                  }), "T akes the name of a column, returns the rows ordered by the length of the column value (calculated as a string)"]
                }), (0, _jsxRuntime.jsxs)("li", {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "random()"
                  }), "Returns the rows in random order."]
                })]
              }), "These functions also support reverse ordering:", (0, _jsxRuntime.jsx)(_components.code, {
                children: "orderBy=-geo_distance(location_column,42.37,-71.07)"
              }), " returns the items that are the farthest away first."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTCB3aXRob3V0IGFueSBmaWx0ZXJzOgpodHRwczovL2FwaS5odWJhcGkuY29tL2h1YmRiL2FwaS92Mi90YWJsZXMvMzAwMDgxL3Jvd3M/cG9ydGFsSWQ9NjI1MTUKCkV4YW1wbGUgR0VUIFVSTCB0byBnZXQgdGhlIGRyYWZ0IHZlcnNpb24gb2YgdGhlIHJvd3M6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vaHViZGIvYXBpL3YyL3RhYmxlcy8zMDAwODEvcm93cy9kcmFmdAoKVGhpcyBleGFtcGxlIGZpbHRlcnMgYnkgYSBOVU1CRVIgY29sdW1uIG5hbWVkIGBudW1iZXJfY29sdW1uJwphbmQgc29ydHMgdGhlIHJvd3MgYnkgYSBkYXRlIGZpZWxkIG5hbWVkICdhX2RhdGUnLCB3aXRoIHRoZSBsYXRlc3QgZGF0ZXMgZmlyc3Q6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vaHViZGIvYXBpL3YyL3RhYmxlcy8zMDAwODEvcm93cz9wb3J0YWxJZD02MjUxNSZudW1iZXJfY29sdW1uX19sdD0xMCZvcmRlckJ5PS1hX2RhdGUKCkV4YW1wbGUgUmVzcG9uc2U6CnsKICAib2JqZWN0cyI6IFsKICAgIHsKICAgICAgImlkIjogNDY5MDQyMzQ5NywKICAgICAgImNyZWF0ZWRBdCI6IDE0ODM2NDk1MDc2MTUsCiAgICAgICJwYXRoIjogbnVsbCwKICAgICAgIm5hbWUiOiBudWxsLAogICAgICAidmFsdWVzIjogewogICAgICAgICIzIjogMSwKICAgICAgICAiNCI6ICJDb2x1bW4gVGV4dCIsCiAgICAgICAgIjUiOiAxNDg5MDE3NjAwMDAwLAogICAgICAgICI2IjogMTQ1MjU2MjIwMDAwMCwKICAgICAgICAiNyI6ICI8cD48c3Ryb25nPjxlbT48dT5UaGlzIGhhcyBtdWx0aXBsZSBzdHlsZXMuPC91PjwvZW0+PC9zdHJvbmc+PC9wPiIKICAgICAgfQogICAgfSwKICAgIHsKICAgICAgImlkIjogNDY4MjAxNTgyMywKICAgICAgImNyZWF0ZWRBdCI6IDE0ODM1NjQ3Mzg2ODAsCiAgICAgICJwYXRoIjogbnVsbCwKICAgICAgIm5hbWUiOiBudWxsLAogICAgICAidmFsdWVzIjogewogICAgICAgICIzIjogNywKICAgICAgICAiNCI6ICJFeGFtcGxlIFRleHQiLAogICAgICAgICI1IjogMTQ4NDI2NTYwMDAwMCwKICAgICAgICAiNiI6IDE0ODUwNTc2MDAwMDAsCiAgICAgICAgIjciOiAiPHA+VGhpcyBpcyBzb21lIHRleHQuPC9wPiIKICAgICAgfQogICAgfQogIF0sCiAgInRvdGFsIjogMiwKICAibGltaXQiOiAxMDAwLAogICJvZmZzZXQiOiAwLAogICJtZXNzYWdlIjogbnVsbCwKICAidG90YWxDb3VudCI6IDIKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Create a new Table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/hubdb/api/v2/tables"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Create a new HubDB table."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "name",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The name of the table."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "useForPages",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Set this to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), " to use the page for ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "http://designers.hubspot.com/docs/tutorials/how-to-build-dynamic-pages-with-hubdb",
                children: "dynamic pages"
              }), "."]
            })
          }), (0, _jsxRuntime.jsxs)(EndpointParameter, {
            schemaGroup: "Body",
            name: "columns",
            children: [(0, _jsxRuntime.jsx)(_components.p, {
              children: "A list of column definitions that will be used for the table."
            }), (0, _jsxRuntime.jsx)(_components.p, {
              children: "Each column should include the following fields."
            }), (0, _jsxRuntime.jsxs)(_components.table, {
              children: [(0, _jsxRuntime.jsx)(_components.thead, {
                children: (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.th, {
                    children: "Field"
                  }), (0, _jsxRuntime.jsx)(_components.th, {
                    children: "How to use"
                  }), (0, _jsxRuntime.jsx)(_components.th, {
                    children: "Description"
                  })]
                })
              }), (0, _jsxRuntime.jsxs)(_components.tbody, {
                children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "Name"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"name\":"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "The internal name of the column."
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "Label"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"label\":"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "Optional - The label for the column. This will be displayed when editing the table in the HubSpot app."
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "Type"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"type\":"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["The data type of the column. must be one of", (0, _jsxRuntime.jsxs)("ul", {
                      children: [(0, _jsxRuntime.jsx)("li", {
                        children: "TEXT - a simple text field"
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "RICHTEXT - a text field that supports basic HTML"
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "NUMBER - a number field"
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "BOOLEAN - represented as a checkbox in HubSpot, uses 0 for unchecked and 1 for checked."
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "DATE - Stores a specific date as a millisecond timestamp set to midnight UTC for the specified date."
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "DATETIME - Stores a date and a time as a millisecond timestamp."
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "SELECT - The column can only be set to one of a set of options."
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "MULTISELECT - The column can be set to one or more of a set of options."
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "URL - Stores a URL."
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "LOCATION - Stores a lattitude and longitude location."
                      }), (0, _jsxRuntime.jsx)("li", {
                        children: "IMAGE - Stores the URL of an image."
                      })]
                    })]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "Options"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"options\":"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["If the column is the SELECT type, this will be used as the options for the column. Each option must include a name, and a type of \"option\": ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "[{\"name\":\"a\", \"type\":\"option\"}, {\"name\":\"b\", \"type\":\"option\"}]"
                    })]
                  })]
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "publishedAt",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Set this to the current time to publish the table, making it available for use."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUE9TVCBVUkw6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vaHViZGIvYXBpL3YyL3RhYmxlcwoKRXhhbXBsZSBQT1NUIEJvZHk6CgpDcmVhdGUgYSBuZXcgdGFibGUgd2l0aCBhIHNpbmdsZSBURVhUIGNvbHVtbjoKewogICJuYW1lIjogIlRlc3QgVGFibGUiLAogICJjb2x1bW5zIjogWwogICAgewogICAgICAibmFtZSI6ICJOYW1lIiwKICAgICAgInR5cGUiOiAiVEVYVCIKICAgIH0KICBdCn0KCkNyZWF0ZSBhIHRhYmxlIHdpdGggYSBURVhUIGNvbHVtbiBhbmQgYSBTRUxFQ1QgY29sdW1uOgp7CiAgIm5hbWUiOiAiTmV3IFRhYmxlIiwKICAiY29sdW1ucyI6IFsKICAgIHsKICAgICAgIm5hbWUiOiAiTmFtZSIsCiAgICAgICJ0eXBlIjogIlRFWFQiCiAgICB9LAogICAgewogICAgICAibmFtZSI6ICJjaG9pY2VzIiwKICAgICAgInR5cGUiOiAiU0VMRUNUIiwKICAgICAgIm9wdGlvbnMiOiBbCiAgICAgICAgewogICAgICAgICAgIm5hbWUiOiAib25lIiwKICAgICAgICAgICJ0eXBlIjogIm9wdGlvbiIKICAgICAgICB9LAogICAgICAgIHsKICAgICAgICAgICJuYW1lIjogInR3byIsCiAgICAgICAgICAidHlwZSI6ICJvcHRpb24iCiAgICAgICAgfQogICAgICBdCiAgICB9CiAgXQp9CgpFeGFtcGxlIHJlc3BvbnNlIGJvZHk6CnsKICAiaWQiOiA2ODAwMTksCiAgIm5hbWUiOiAiTmV3IFRhYmxlIiwKICAiY3JlYXRlZEF0IjogMTUyMzQ3MTI3ODk2MiwKICAicHVibGlzaGVkQXQiOiBudWxsLAogICJ1cGRhdGVkQXQiOiAwLAogICJjb2x1bW5zIjogWwogICAgewogICAgICAibmFtZSI6ICJOYW1lIiwKICAgICAgImxhYmVsIjogbnVsbCwKICAgICAgImlkIjogMSwKICAgICAgImRlbGV0ZWQiOiBmYWxzZSwKICAgICAgInR5cGUiOiAiVEVYVCIKICAgIH0sCiAgICB7CiAgICAgICJuYW1lIjogImNob2ljZXMiLAogICAgICAibGFiZWwiOiBudWxsLAogICAgICAiaWQiOiAyLAogICAgICAiZGVsZXRlZCI6IGZhbHNlLAogICAgICAib3B0aW9ucyI6IFsKICAgICAgICB7CiAgICAgICAgICAiaWQiOiAxLAogICAgICAgICAgIm5hbWUiOiAib25lIiwKICAgICAgICAgICJ0eXBlIjogIm9wdGlvbiIKICAgICAgICB9LAogICAgICAgIHsKICAgICAgICAgICJpZCI6IDIsCiAgICAgICAgICAibmFtZSI6ICJ0d28iLAogICAgICAgICAgInR5cGUiOiAib3B0aW9uIgogICAgICAgIH0KICAgICAgXSwKICAgICAgInR5cGUiOiAiU0VMRUNUIiwKICAgICAgIm9wdGlvbkNvdW50IjogMgogICAgfQogIF0sCiAgImxhYmVsIjogIk5ldyBUYWJsZSIsCiAgImNvc09iamVjdFR5cGUiOiAiSFVCREJfVEFCTEUiLAogICJ1cGRhdGVkIjogMCwKICAiZGVsZXRlZCI6IGZhbHNlLAogICJyb3dDb3VudCI6IDAsCiAgInVzZUZvclBhZ2VzIjogZmFsc2UsCiAgImNvbHVtbkNvdW50IjogMgp9"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Clone a Table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/hubdb/api/v2/tables/:tableId/clone"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Clone an existing HubDB table."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table to be cloned."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "draft",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/draft"
              }), " at the end of the request path to update the draft version of the table."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "newName",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The name of the new table. If omitted, an empty JSON object must still be passed in the request body."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUE9TVCBVUkw6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vaHViZGIvYXBpL3YyL3RhYmxlcy82NzMxMTAvY2xvbmUKCkV4YW1wbGUgUE9TVCBCb2R5IHRvIGNyZWF0ZSBhIGNsb25lIHdpdGggdGhlIHNhbWUgbmFtZToKe30KCkV4YW1wbGUgUE9TVCBib2R5IHRvIGNyZWF0ZSBhIGNsb25lIHdpdGggYSBuZXcgbmFtZToKewogICJuZXdOYW1lIjogIlRlc3QgVGFibGUgKENsb25lZCkiCn0KCkV4YW1wbGUgcmVzcG9uc2UgYm9keToKewogICJpZCI6IDY3MzExMSwKICAibmFtZSI6ICJUZXN0IFRhYmxlIChDbG9uZWQpIiwKICAiY3JlYXRlZEF0IjogMTUwNDEyMDYxMjc0NSwKICAicHVibGlzaGVkQXQiOiBudWxsLAogICJ1cGRhdGVkQXQiOiAwLAogICJjb2x1bW5zIjogWwogICAgewogICAgICAibmFtZSI6ICJOYW1lIiwKICAgICAgImxhYmVsIjogbnVsbCwKICAgICAgImlkIjogMSwKICAgICAgInR5cGUiOiAiVEVYVCIKICAgIH0KICBdLAogICJjb3NPYmplY3RUeXBlIjogIkhVQkRCX1RBQkxFIiwKICAiZGVsZXRlZCI6IGZhbHNlLAogICJsYWJlbCI6ICJUZXN0IFRhYmxlIChDbG9uZWQpIiwKICAidXBkYXRlZCI6IDAsCiAgInJvd0NvdW50IjogMCwKICAidXNlRm9yUGFnZXMiOiBmYWxzZSwKICAiY29sdW1uQ291bnQiOiAxCn0K"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Add a new row to a table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/hubdb/api/v2/tables/:tableId/rows"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Add a new row to a HubDB table."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["New rows will be added to the draft table. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/publish"
          }), " endpoint to push all changes live."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table you want to add the row to."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "path",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If the table is enabled for ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "http://designers.hubspot.com/docs/tutorials/how-to-build-dynamic-pages-with-hubdb",
                children: "dynamic pages"
              }), ", this is the path suffix used for the page for this row. ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Lowercase characters only."
              })]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "name",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If the table is enabled for ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "http://designers.hubspot.com/docs/tutorials/how-to-build-dynamic-pages-with-hubdb",
                children: "dynamic pages"
              }), ", this is the HTML title used for the page for this row."]
            })
          }), (0, _jsxRuntime.jsxs)(EndpointParameter, {
            schemaGroup: "Body",
            name: "values",
            children: [(0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A list of values for the cells in the row. Each entry requires a column id that you would get when pulling the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#get-details-for-a-specific-table",
                children: "the details for the table"
              }), ", and the value being set for that column. For example, when setting a field with id 2 and type TEXT to use the value 'test':", (0, _jsxRuntime.jsx)(_components.code, {
                children: "\"2\": \"test\""
              }), "See below for more details for the values used by specific column types."]
            }), (0, _jsxRuntime.jsx)(_components.p, {
              children: "Below you'll find details for the format of the data used for the different column types. Each Example assumes you're setting a field with the column id of '2'"
            }), (0, _jsxRuntime.jsxs)(_components.table, {
              children: [(0, _jsxRuntime.jsx)(_components.thead, {
                children: (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.th, {
                    children: "Column Type"
                  }), (0, _jsxRuntime.jsx)(_components.th, {
                    children: "Value type"
                  }), (0, _jsxRuntime.jsx)(_components.th, {
                    children: "Example"
                  })]
                })
              }), (0, _jsxRuntime.jsxs)(_components.tbody, {
                children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "TEXT"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "A simple string"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"2\": \"Example Text\""
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "RICHTEXT"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "A string that supports HTML"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["\"2\": \"", (0, _jsxRuntime.jsx)("p", {
                      children: "This is some text."
                    }), "\""]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "NUMBER"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "A number, supports decimal values"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"2\": 7.1354"
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "BOOLEAN"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "0 for false or 1 for true"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"2\": 1"
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "DATE"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "A Unix formatted timestamp in milliseconds. Must be set to midnight UTC time for the specified date. For example, the value of 02 Jan 2017 is 1483315200000."
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"2\": 1483315200000"
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "DATETIME"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "A Unix formatted timestamp in milliseconds. The example would be for 06 Jan 2017 05:00:00 UTC"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "\"2\": 1483678800000"
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "SELECT"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "An object representing one of a set of options set in the column definition. The name and id of the value will correspond to the column settings."
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["\"2\": ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "{ \"id\": 4, \"name\": \"d\", \"type\": \"option\" }"
                    })]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "URL"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "A string representing a URL"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["\"2\" : ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "\"https://integrate.hubspot.com/\""
                    })]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "LOCATION"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "An object representing latitude and longitude coordinates."
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["\"2\": ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "{ \"lat\": 41.836826, \"long\": -88.461914, \"type\": \"location\" }"
                    })]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "IMAGE"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "An object including a URL of an image."
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["\"2\": ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "{ \"url\": \"http://domain.com/image.jpg\", \"type\": \"image\" }"
                    })]
                  })]
                })]
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUE9TVCBVUkw6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vaHViZGIvYXBpL3YyL3RhYmxlcy8zMDAwODEvcm93cwoKRXhhbXBsZSByZXF1ZXN0czoKCkNyZWF0ZSBhIG5ldyBlbXB0eSByb3c6Cnt9CgpDcmVhdGUgYSByb3cgc2V0dGluZyBjb2x1bW4gaWQgJzMnIHdpdGggdHlwZSBOVU1CRVIsIGFuZCBjb2x1bW4gaWQgJzQnIHdpdGggdHlwZSBURVhUOgp7CiAgInZhbHVlcyI6IHsKICAgICIzIjogNywKICAgICI0IjogIk5ldyBSb3cgVGV4dCIKICB9Cn0KCkNyZWF0ZSBhIHJvdyBpbiBhIHRhYmxlIGJlaW5nIHVzZWQgZm9yCmR5bmFtaWMgcGFnZXMsIHRoYXQgd291bGQgYXBwZWFyIGF0IHRoZSBwYXRoCnN1ZmZpeCBvZiAnL3JvdzcnIHdpdGggdGhlIHBhZ2UgdGl0bGUgb2YgJ1BhZ2UgNyc6CnsKICAicGF0aCI6ICJyb3c3IiwKICAibmFtZSI6ICJQYWdlIDciLAogICJ2YWx1ZXMiOiB7CiAgICAiMyI6IDcsCiAgICAiNCI6ICJOZXcgUm93IFRleHQiCiAgfQp9CgpFeGFtcGxlIFJlc3BvbnNlOgp7CiAgImlkIjogNDY4NTQ1NjM0MywKICAiY3JlYXRlZEF0IjogMTQ4MzczOTg0NzIwMiwKICAicGF0aCI6ICJyb3c3IiwKICAibmFtZSI6ICJQYWdlIDciLAogICJ2YWx1ZXMiOiB7CiAgICAiMyI6IDcsCiAgICAiNCI6ICJOZXcgUm93IFRleHQiCiAgfQp9"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Import a CSV to the draft version of a table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/hubdb/api/v2/tables/:tableId/import"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Import the contents of a CSV file into the draft version of an existing HubDB table."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Importing a CSV will always update the draft version of the table. You will need to publish the draft via API or via the publish tools in HubSpot before the import would affect the live, public version of the table."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "This endpoint takes a multipart POST request. The first part will be a set of JSON formatted options for the import. The second part will be the CSV file you want to import."
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Reset Options:"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["We have two options while importing the data into the table: ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Add Rows"
          }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Reset"
          }), "."]
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["With ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "reset table"
              }), " option (can be specified as ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "\"resetTable\":true"
              }), " in the JSON-formatted options)"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the rows in the csv file does not have a row id column(hs_id) or row id is specified as 0, those rows will be inserted with the new row ids generated."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the row ids in the csv file already exists in the target table, the existing rows in the table will be updated with the new values from the input file."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the table has rows but the input csv file does not have those row ids, those rows will be deleted from the target table."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the row ids in the input csv file do not exist in the target table, those rows will be inserted with the new row ids generated and the row ids given in the input file will be ignored."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the input csv file does not contain the row id column at all, all the rows will be deleted from the target table and the rows from the input file will be inserted with the new row ids generated."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["With ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "add rows"
              }), " option (can be specified as ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "\"resetTable\":false"
              }), " in the  JSON-formatted options)"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the row ids in the csv file already exists in the target table, the existing rows in the table will be updated with the new values from the input file."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["if the table has rows but the input csv file does not have those row ids, those rows will ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "not"
              }), " be deleted from the target table and those rows will remain unchanged."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the row ids in the input csv file do not exist in the target table, those rows will be inserted with the new row ids generated and the row ids given in the input file will be ignored."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "if the rows in the csv file does not have a row id column or row id is specified as 0, those rows will be inserted with the new row ids generated."
            }), "\n"]
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table you want to import the data into."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "resetTable",
            required: true,
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "false"
              }), ". If false, the rows will be inserted / updated without removing anything from the table. If ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), ", the rows from csv file will be inserted / updated from the table and rows that exists in the table but not in the csv file will be deleted. See the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Reset options section below more detailed information"
              }), "."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "skipRows",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The number of header rows in the CSV file that should be skipped over. Defaults to 1 (skiping the first row in the CSV and treating it as a header row), set this to 0 if all of the rows in the CSV are data rows you want imported."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "format",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Must be \"csv\", as only CSV files are currently supported."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "columnMappings",
            required: true,
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A list of mappings for the columns in the CSV to the columns in the HubDB table.Each mapping will have the following format:", (0, _jsxRuntime.jsx)(_components.code, {
                children: "{\"source\":1,\"target”:2}"
              }), "\"source\" refers the column in the CSV, starting with 1.\"target\" refers to the ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "id"
              }), " of the table column. You can get the IDs for the columns by ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#get-details-for-a-specific-table",
                children: "getting the details for the table"
              }), "."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "primaryKeyColumn",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "Name"
              }), " of a column to deduplicate against existing rows in the database. You can get the names for the columns by ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#get-details-for-a-specific-table",
                children: "getting the details for the table"
              }), "."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "nameSourceColumn",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The number of the column in the CS file (starting with 1) that should be imported into the ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "name"
              }), " column."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "pathSourceColumn",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The number of the column in the CSV (starting with 1) that should be imported into the ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "path"
              }), " column."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "publishedAtSourceColumn",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The index of the column in the source file containing the row’s ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "ID(hs_id)"
              }), ". If this column is specified, the import process updates the existing rows in the table for the matching row ids from csv. This is optional and you can ignore this during the first time you insert data into a table. See the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Reset options section below more detailed information."
              })]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUE9TVCBVUkw6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vaHViZGIvYXBpL3YxL3RhYmxlcy8xMjM0NTYvaW1wb3J0CgpBbnkgSlNPTiwgaW5jbHVkaW5nIHRoZSBleGFtcGxlIGJlbG93LCBtdXN0IGJlIHBsYWNlZCBpbiB0aGUgY29uZmlnIGZpZWxkIG9mIHRoZSByZXF1ZXN0LiAKCkV4YW1wbGUgSlNPTiBjb25maWcgZGF0YToKVGhpcyBleGFtcGxlIGNvbHVtbk1hcHBpbmdzIG1hcHMgdGhlIGZpcnN0IGNvbHVtbiBvZiB0aGUgQ1NWIHRvIHRoZSBIdWJEQiBjb2x1bW4gd2l0aCBpZCAyLCBhbmQgdGhlIHNlY29uZCBjb2x1bW4gaW4gdGhlIENTViB0byB0aGUgSHViREIgY29sdW1uIHdpdGggaWQgMzoKewogICJyZXNldFRhYmxlIjogZmFsc2UsCiAgInNraXBSb3dzIjogMSwKICAiZm9ybWF0IjogImNzdiIsCiAgImNvbHVtbk1hcHBpbmdzIjogWwogICAgewogICAgICAic291cmNlIjogMSwKICAgICAgInRhcmdldCI6IDIKICAgIH0sCiAgICB7CiAgICAgICJzb3VyY2UiOiAyLAogICAgICAidGFyZ2V0IjogMwogICAgfQogIF0KfQoKRXhhbXBsZSBKU09OIGNvbmZpZyBkYXRhIHdoZW4gdXNpbmcgYGlkU291cmNlQ29sdW1uYDoKewogICAgInNraXBSb3dzIjoxLAogICAgImZvcm1hdCI6ImNzdiIsCiAgICAic2VwYXJhdG9yIjoiLCIsCiAgICAiY29sdW1uTWFwcGluZ3MiOlsKICAgICAgICB7CiAgICAgICAgICAgICJ0YXJnZXQiOjEsCiAgICAgICAgICAgICJzb3VyY2UiOjYKICAgICAgICB9LAogICAgICAgIHsKICAgICAgICAgICAgInRhcmdldCI6MiwKICAgICAgICAgICAgInNvdXJjZSI6NwogICAgICAgIH0KICAgIF0sCiAgICAiaWRTb3VyY2VDb2x1bW4iOjEsCiAgICAicGF0aFNvdXJjZUNvbHVtbiI6MiwKICAgICJuYW1lU291cmNlQ29sdW1uIjo0LAogICAgImNoaWxkVGFibGVTb3VyY2VDb2x1bW4iOjUsCiAgICAicmVzZXRUYWJsZSI6dHJ1ZQp9CgoKUmV0dXJucyBhIDQwNCByZXNwb25zZSBpZiB0aGUgdGFibGUgb3IgaW1wb3J0IGRhdGEgaXMgbm90IGZvdW5kLgoKUmV0dXJucyBhIDQwMCBpZiBhIG5vbi1jc3YgZmlsZSBpcyBzZW50CgpPdGhlcndpc2UsIHJldHVybnMgYSAyMDAgd2l0aCB0aGUgZm9sbG93aW5nIGRhdGE6CnsKICAiZXJyb3JzIjogW10sCiAgLy8gQSBsaXN0IG9mIGFueSBlcnJvcnMgZW5jb3VudGVyZWQgZHVyaW5nIHRoZSBpbXBvcnQKICAicm93c0ltcG9ydGVkIjogMSwKICAvLyBUaGUgbnVtYmVyIG9mIHJvd3MgaW1wb3J0ZWQgdG8gdGhlIHRhYmxlCiAgImR1cGxpY2F0ZVJvd3MiOiAwCiAgLy8gVGhlIG51bWJlciBvZiBkdXBsaWNhdGUgcm93cyBmb3VuZCwgaWYgdXNpbmcgcHJpbWFyeUtleUNvbHVtbgp9"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Update a table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/hubdb/api/v2/tables/:tableId"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Update an existing HubDB table. You can use this endpoint to add or remove a column, or to publish a table that's ready to be used."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Table updates will modify the draft table. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/publish"
          }), " endpoint to push all changes live."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "draft",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/draft"
              }), " at the end of the request path to update the draft version of the table."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "name",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Renames the table"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            type: "boolean",
            name: "useForPages",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Set this to true to enable the table for ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "http://designers.hubspot.com/docs/tutorials/how-to-build-dynamic-pages-with-hubdb",
                children: "dynamic pages"
              }), "."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "publishedAt",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Set this to the current (or a past) time to publish a table that's ready to be used"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "columns",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Updates the columns in the table. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#create-a-new-table",
                children: "creating a table"
              }), " for the column definitions.", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "IMPORTANT:"
              }), " Data in this field ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "overwrites"
              }), " the existing columns. If you want to add a new column, you'll need to include the existing columns and the new column in the request."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzM2MDEyMwoKRXhhbXBsZSByZXF1ZXN0czoKClVwZGF0ZSB0aGUgbmFtZSBvZiB0aGUgdGFibGU6CnsibmFtZSI6IlVwZGF0ZWQgVGFibGUifQoKUHVibGlzaCBhIHRhYmxlOgp7InB1Ymxpc2hlZEF0IjoxNDgzMjQ2ODAwMDAwfQoKRW5hYmxlIHRoZSB0YWJsZSBmb3IgdXNlIGluIGR5bmFtaWMgcGFnZXM6CnsidXNlRm9yUGFnZXMiOiB0cnVlfQoKVXBkYXRlIHRoZSB0YWJsZSB0byBpbmNsdWRlIGFuIGV4aXN0aW5nIFRFWFQgY29sdW1uIG5hbWVkICdOYW1lJyBhbmQgaWQgMSwgYW5kIGFkZCBhIG5ldyBOVU1CRVIgY29sdW1uIG5hbWVkICdudW1iZXInCklNUE9SVEFOVDogWW91IG11c3QgaW5jbHVkZSB0aGUgJ2lkJyBmb3IgYW55IGV4aXN0aW5nIGNvbHVtbnMgdG8gcHJldmVudCBhIG5ldyBjb2x1bW4Kd2l0aCB0aGUgc2FtZSBuYW1lIGFuZCB0eXBlIGZyb20gcmVwbGFjaW5nIHRoZSBleGlzdGluZyBjb2x1bW4uCk5ldyBjb2x1bW4gSURzIGFyZSBhc3NpZ25lZCBhdXRvbWF0aWNhbGx5Ogp7CiAgImNvbHVtbnMiOiBbCiAgICB7CiAgICAgICJuYW1lIjogIk5hbWUiLAogICAgICAibGFiZWwiOiBudWxsLAogICAgICAiaWQiOiAxLAogICAgICAidHlwZSI6ICJURVhUIgogICAgfSwKICAgIHsKICAgICAgImxhYmVsIjogIm51bWJlciIsCiAgICAgICJuYW1lIjogIm51bWJlciIsCiAgICAgICJ0eXBlIjogIk5VTUJFUiIKICAgIH0KICBdCn0KCkV4YW1wbGUgcmVzcG9uc2U6CnsKICAiaWQiOiAzNjAxMjMsCiAgIm5hbWUiOiAiVXBkYXRlZCBUYWJsZSIsCiAgInVzZUZvclBhZ2VzIjogZmFsc2UsCiAgImNyZWF0ZWRBdCI6IDE0ODM3MjYyNTc5NzAsCiAgInB1Ymxpc2hlZEF0IjogbnVsbCwKICAidXBkYXRlZEF0IjogMTQ4MzczNTA1MDAxMSwKICAiY29sdW1ucyI6IFsKICAgIHsKICAgICAgIm5hbWUiOiAiTmFtZSIsCiAgICAgICJsYWJlbCI6IG51bGwsCiAgICAgICJpZCI6IDEsCiAgICAgICJ0eXBlIjogIlRFWFQiCiAgICB9LAogICAgewogICAgICAibmFtZSI6ICJudW1iZXIiLAogICAgICAibGFiZWwiOiAibnVtYmVyIiwKICAgICAgImlkIjogNCwKICAgICAgInR5cGUiOiAiTlVNQkVSIgogICAgfQogIF0sCiAgImRlbGV0ZWQiOiBmYWxzZSwKICAicm93Q291bnQiOiAwLAogICJjcmVhdGVkQnkiOiBudWxsLAogICJ1cGRhdGVkQnkiOiBudWxsLAogICJjb2x1bW5Db3VudCI6IDQKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Update a row"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/hubdb/api/v2/tables/:tableId/rows/:rowId"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Update a specific row in a HubDB table."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Row changes will be written to the draft table. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/publish"
          }), " endpoint to push all changes live."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table the row belongs to"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "rowId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the row you want to update."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "path",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If the table is enabled for ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "http://designers.hubspot.com/docs/tutorials/how-to-build-dynamic-pages-with-hubdb",
                children: "dynamic pages"
              }), ", this is the path suffix used for the page for this row. ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Must be lowercase."
              })]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "name",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If the table is enabled for ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "http://designers.hubspot.com/docs/tutorials/how-to-build-dynamic-pages-with-hubdb",
                children: "dynamic pages"
              }), ", this is the HTML title used for the page for this row."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "values",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Example: ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "\"values\": [ {list of cell values} ]"
              }), ".   A list of values for the cells in the row. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#add-a-new-row-to-a-table",
                children: "adding a row"
              }), " for more details on the format of the different column types. ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Important:"
              }), " The columns included in the request will ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "overwrite"
              }), " the existing columns. Any columns not included will be ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "removed"
              }), " from the row. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#update-a-specific-cell",
                children: "updating a cell"
              }), " to update a specific cell in a row instead of the entire row."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MS9yb3dzLzQ2OTA0MjM2MjIKCkV4YW1wbGUgcmVxdWVzdCBkYXRhOgpVcGRhdGUgdGhlIHJvdyB0byBoYXZlIHRoZSBzaW5nbGUgY29sdW1uIHdpdGggaWQgJzQnIG9mIHR5cGUgVEVYVDoKewogICJ2YWx1ZXMiOiB7CiAgICAiNCI6ICJ1cGRhdGVkIgogIH0KfQoKRXhhbXBsZSBSZXNwb25zZToKewogICJpZCI6IDQ2OTA0MjM2MjIsCiAgImNyZWF0ZWRBdCI6IDE0ODM3NDQ0MDMyMTMsCiAgInBhdGgiOiBudWxsLAogICJuYW1lIjogbnVsbCwKICAidmFsdWVzIjogewogICAgIjQiOiAidXBkYXRlZCIKICB9Cn0="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Update a specific cell"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/hubdb/api/v2/tables/:tableId/rows/:rowId/cells/:cellId"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Update a specific cell in the specified row. This will let you update a single cell without affecting the other cells in that row."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["All updates will be written to the draft table. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/publish"
          }), " endpoint to push all changes live."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table the row belongs to"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "rowId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the row the cell belongs to"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "cellId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the column that you want to update"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Body",
            name: "value",
            required: true,
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The value you want to use for the cell. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#add-a-new-row-to-a-table",
                children: "adding a new row"
              }), " for details on the data used for each column type."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "draft",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/draft"
              }), " at the end of the request path to update the draft version of the table."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MS9yb3dzLzQ2ODU0NTYzNDMvY2VsbHMvNAoKRXhhbXBsZSByZXF1ZXN0IGRhdGE6ClVwZGF0ZSBhIFRFWFQgY2VsbDoKeyJ2YWx1ZSIgOiAiVXBkYXRlZCBSb3cgVGV4dCJ9CgpVcGRhdGUgYSBMT0NBVElPTiBjZWxsOgp7InZhbHVlIjp7InR5cGUiOiJsb2NhdGlvbiIsImxhdCI6NDIuMzYwNSwibG9uZyI6LTcxLjA1OTZ9fQoKVXBkYXRlIGEgQk9PTEVBTiAoY2hlY2tib3gpIGNlbGwgdG8gZmFsc2UgKHVuY2hlY2tlZCk6CnsidmFsdWUiOjB9CgoKRXhhbXBsZSBSZXNwb25zZToKeyJ2YWx1ZSI6IlVwZGF0ZWQgQ2VsbCBEYXRhIn0="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Publish the draft data for a table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/hubdb/api/v2/tables/:tableId/publish"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Publish the current draft version of the table. This will immediately push the data for any stored draft version to the live version of the table, meaning that any website pages using data from the table would be updated."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table you want to publish."
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MS9wdWJsaXNoCgpEb2VzIG5vdCByZXF1aXJlIGEgUFVUIGJvZHkuCgpFeGFtcGxlIHJlc3BvbnNlOgp7CiAgImlkIjogMzAwMDgxLAogICJuYW1lIjogIkV4YW1wbGUgVGFibGUgMSIsCiAgImNyZWF0ZWRBdCI6IDE0Nzk0Nzk5NzcyODYsCiAgInB1Ymxpc2hlZEF0IjogMTQ3OTQ4MDAwNTYwNiwKICAidXBkYXRlZEF0IjogMTQ3OTQ4MDAwNTc4MSwKICAiY29sdW1ucyI6IFsKICAgIHsKICAgICAgIm5hbWUiOiAiTmFtZSIsCiAgICAgICJsYWJlbCI6IG51bGwsCiAgICAgICJpZCI6IDEsCiAgICAgICJ0eXBlIjogIlRFWFQiCiAgICB9LAogICAgewogICAgICAibmFtZSI6ICJkYXRlY29sIiwKICAgICAgImxhYmVsIjogImRhdGVjb2wiLAogICAgICAiaWQiOiAyLAogICAgICAidHlwZSI6ICJEQVRFIgogICAgfQogIF0sCiAgImRlbGV0ZWQiOiBmYWxzZSwKICAidXNlRm9yUGFnZXMiOiBmYWxzZSwKICAicm93Q291bnQiOiAwLAogICJjcmVhdGVkQnkiOiBudWxsLAogICJ1cGRhdGVkQnkiOiBudWxsLAogICJjb2x1bW5Db3VudCI6IDIKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Revert the draft data for a table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/hubdb/api/v2/tables/:tableId/revert"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Reverts the draft data for a table to the last published version of the table. This will discard any currently saved draft data."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "THe ID of the table you want to revert the data for."
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MS9yZXZlcnQKCkRvZXMgbm90IHJlcXVpcmUgYSBQVVQgYm9keS4KCkV4YW1wbGUgcmVzcG9uc2U6CnsKICAiaWQiOiAzMDAwODEsCiAgIm5hbWUiOiAiRXhhbXBsZSBUYWJsZSAxIiwKICAiY3JlYXRlZEF0IjogMTQ3OTQ3OTk3NzI4NiwKICAicHVibGlzaGVkQXQiOiAxNDc5NDgwMDA1NjA2LAogICJ1cGRhdGVkQXQiOiAxNDc5NDgwMDA1NzgxLAogICJjb2x1bW5zIjogWwogICAgewogICAgICAibmFtZSI6ICJOYW1lIiwKICAgICAgImxhYmVsIjogbnVsbCwKICAgICAgImlkIjogMSwKICAgICAgInR5cGUiOiAiVEVYVCIKICAgIH0sCiAgICB7CiAgICAgICJuYW1lIjogImRhdGVjb2wiLAogICAgICAibGFiZWwiOiAiZGF0ZWNvbCIsCiAgICAgICJpZCI6IDIsCiAgICAgICJ0eXBlIjogIkRBVEUiCiAgICB9CiAgXSwKICAiZGVsZXRlZCI6IGZhbHNlLAogICJ1c2VGb3JQYWdlcyI6IGZhbHNlLAogICJyb3dDb3VudCI6IDAsCiAgImNyZWF0ZWRCeSI6IG51bGwsCiAgInVwZGF0ZWRCeSI6IG51bGwsCiAgImNvbHVtbkNvdW50IjogMgp9"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete a row"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "DELETE",
          path: "/hubdb/api/v2/tables/:tableId/rows/:rowId"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Remove a row from a specified HubDB table."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Rows will only be deleted from the draft table. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/publish"
          }), " endpoint to push all changes live."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table the row belongs to"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "rowId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the row you want to delete"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "draft",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/draft"
              }), " at the end of the request path to delete the row from the draft version."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgREVMRVRFIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MS9yb3dzLzQ2OTA0MjM2MTcKClJldHVybnMgYSAyMDQgTm8gQ29udGVudCByZXNwb25zZSBvbiBzdWNjZXNzLg=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete a cell from a row"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "DELETE",
          path: "/hubdb/api/v2/tables/:tableId/rows/:rowId/cells/:cellId"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Remove a cell from a row."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["All updates will be written to the draft table. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/publish"
          }), " endpoint to push all changes live."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "This action cannot be undone after the table is republished with the changes."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "hubdb"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table the row belongs to"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "rowId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the row the cell belongs to"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "cellId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the column that you want to delete"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Query",
            name: "draft",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/draft"
              }), " at the end of the request path to delete the cell in the draft version of the table."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgREVMRVRFIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzMwMDA4MS9yb3dzLzQ2ODU0NTYzNDMvY2VsbHMvNwoKUmV0dXJucyBhIDIwNCBObyBDb250ZW50IHJlc3BvbnNlIG9uIHN1Y2Nlc3Mu"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete a table"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "DELETE",
          path: "/hubdb/api/v2/tables/:tableId"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/cms/hubdb",
            children: "There is a new version of this API available."
          })
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Delete an existing HubDB table."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "This will delete all data in the table, and cannot be undone."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "tableId",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The ID of the table to delete"
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgREVMRVRFIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9odWJkYi9hcGkvdjIvdGFibGVzLzM2MDExOAoKUmV0dXJucyBhIDIwNCBObyBDb250ZW50IHJlc3BvbnNlIG9uIHN1Y2Nlc3M="
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}