"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const slug = exports.slug = 'reference/cms/overview';
const title = exports.title = 'Aperçu de la référence CMS';
const metaDescription = exports.metaDescription = 'Documentation de référence pour le système de gestion de contenu (CMS) HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "guides-de-d%C3%A9veloppement-cms",
  "label": "Guides de développement CMS",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Aperçu de la référence CMS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette section comprend des documents de référence pour les développeurs sur le système de gestion de contenu (CMS) HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il permet un accès rapide aux documents de référence liés aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "modules"
      }), ", au langage de modélisation ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), ", aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "champs"
      }), " et aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "fonctions sans serveur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guides de développement CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour un aperçu du développement sur CMS Hub, consultez la section ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "Guides de développement CMS"
      }), ". Cela inclut un aperçu des fonctionnalités et des outils du CMS, ainsi qu'un guide de démarrage rapide."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;