"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710042;
const slug = exports.slug = 'guides/cms/setup/creating-an-efficient-development-workflow';
const title = exports.title = 'Optimize your HubSpot development workflow';
const name = exports.name = 'Optimize your HubSpot development workflow';
const metaDescription = exports.metaDescription = 'Creating an efficient development workflow when building websites on the HubSpot CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "building-with-portability-in-mind",
  "label": "Building with portability in mind",
  "parent": null
}, {
  "depth": 0,
  "id": "setting-up-your-development-environment",
  "label": "Setting up your development environment",
  "parent": null
}, {
  "depth": 1,
  "id": "setting-up-your-code-editor",
  "label": "Setting up your code editor",
  "parent": "setting-up-your-development-environment"
}, {
  "depth": 2,
  "id": "vs-code",
  "label": "VS Code",
  "parent": "setting-up-your-development-environment"
}, {
  "depth": 2,
  "id": "other-code-editors-and-ides",
  "label": "Other code editors and IDEs",
  "parent": "setting-up-your-development-environment"
}, {
  "depth": 0,
  "id": "testing",
  "label": "Testing",
  "parent": null
}, {
  "depth": 2,
  "id": "editor",
  "label": "Editor",
  "parent": "testing"
}, {
  "depth": 2,
  "id": "module-preview",
  "label": "Module Preview",
  "parent": "testing"
}, {
  "depth": 2,
  "id": "debugging",
  "label": "Debugging",
  "parent": "testing"
}, {
  "depth": 2,
  "id": "sandboxes",
  "label": "Sandboxes",
  "parent": "testing"
}, {
  "depth": 0,
  "id": "deploying",
  "label": "Deploying",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Optimize your HubSpot development workflow"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Setting up an efficient developer workflow will help you work more effectively when building websites on the HubSpot CMS. Depending on the nature of your web development team, or the nature of a specific project, your workflow may differ."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, a single developer building out a new site in a new HubSpot CMS account needs to worry less about testing and collaboration. On the other hand, a team of developers working on a larger website will need a clearer dev and staging process, a deployment workflow, and code living in source control in order to work efficiently."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This guide is designed to walk you through setting up an efficient developer workflow, which you can adapt to fit your needs."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This guide assumes you build websites using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), ", follow the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "getting started with local development"
      }), " tutorial to get set up. This guide also assumes you've gone through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart#quick_start",
        children: "quick start guide to developing on the HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Building with portability in mind"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before we begin setting up our developer workflow, it is important to recognize portability as a key concept in having an efficient developer workflow. The portability of your project ensures it is easy to move between environments with little friction and explanation, making it easy to test and stage changes before taking them live."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "CMS Theme Boilerplate"
      }), " is an example project that is portable, utilizing features like relative file paths, and true file format for all assets in the project using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), ", which allows it to live in source control and work in any HubSpot account. This project is a great starting or reference point for developers working on a new project. All of the HubSpot default Themes are built using this boilerplate, and can also be used as a portable and effective starting point."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Setting up your development environment"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For your individual development environment, each developer on your team should create a free ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS Developer Sandbox account"
      }), ". These accounts never expire and have all of the functionality of paid HubSpot CMS accounts (except being able to connect custom domains)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The CMS CLI makes it easy to interact with multiple HubSpot CMS accounts. Create a new ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "configuration entry"
      }), " for your CMS Developer Sandbox account. Set the name of the entry for your sandbox to be along the lines of “DEV” or “SANDBOX” so it is clear this account is a development environment. Additionally, set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "defaultPortal"
      }), " to be your sandbox account, so when you run commands using the CMS CLI, it will automatically interact with your sandbox, and reduce accidental production deploys. At this point, your configuration file will look something like this:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: DEV\nportals:\n  - name: PROD\n    portalId: 123\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n  - name: DEV\n    portalId: 456\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now, when running commands in the CMS CLI, like ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload"
        })
      }), ", if you do not specify a portal, the files will be uploaded to your “DEV” account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Setting up your code editor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use your preferred code editor when building on HubSpot, whether you prefer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#vs-code",
        children: "VS Code"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-code-editors-and-ides",
        children: "other code editors and IDEs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "VS Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A significant amount of developers building on HubSpot use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/",
        children: "Visual Studio Code"
      }), ". That inspired the HubSpot VS Code Extension. The extension adds handy intellisense snippets, HubL code completion, HubL syntax highlighting HubL Linting. The project is ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode",
        children: "open source"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/blob/master/CONTRIBUTING.md",
        children: "contributions are welcome"
      }), ". If you have feedback, please ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/issues",
        children: "file an issue on the repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "c0ab15c7-27f7-4f4d-a884-5468e852577a",
      external: true,
      label: "Get VS Code Extension"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/vs%20code%20extension%20-%20hubl%20variable%20suggestion.gif",
        alt: "vs code extension animated screen capture showing hubl variable suggestion"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Other code editors and IDEs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While there is an official VS Code extension, there is no reason you can't use a different preferred editor. HubL is HubSpot's private fork of Jinjava, which is based on Jinja. Because of the similarities in syntax, Jinja syntax highlighting extensions tend to work well. Extensions and add-on tooling vary by editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Testing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are two main methods for testing changes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testing with watch/upload:"
        }), " When working in your development environment, it is safe to use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#watch",
          children: "watch"
        }), " command to automatically upload changes when you save files in your text editor to rapidly develop. If you use the Design Manager “Live preview with display options” tool for a template, as you save changes, you will automatically see them reflected in the rendered output of the template preview. To view the live preview of a template, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview > Live Preview"
        }), " with display options within the template editor of the Design Manager."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testing locally:"
        }), " to preview your changes locally without uploading to the account, you can run the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs theme preview"
        }), " command in the theme's root directory. This command will run a local proxy server at ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://hslocal.net:3000/",
          children: "https://hslocal.net:3000/"
        }), " which you can then use to preview the theme's templates and modules. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#locally-preview-theme",
          children: "hs theme preview command"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Editor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Another critical piece of the development phase is testing your changes in the content creation tools. If you are building modules, or templates designed to be manipulated in the content editor, create pages in your development environment to ensure the content editing experience is as you intend it to be. Drag modules around into odd configurations and enter dummy content to make sure marketers can not “break” your modules when building pages. Using the content editors will help illustrate what guardrails you want to build into your templates and modules. Currently, it is not possible to move content, such as pages or blog posts, between HubSpot accounts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Module Preview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When in the module editor within the Design Manager, select the “Preview” button. This will open up a preview editor for how the module and its fields will behave in the content editors. This allows you to test the fields, groups, and repeaters in your module with dummy content in a safe environment."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/module-preview.gif",
        alt: "module preview"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Debugging"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Knowing how to debug and troubleshoot issues with your website is critical in the ongoing health and success of your website. Familiarize yourself with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting",
        children: "debugging techniques when developing on the HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sandboxes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As noted above in the section about setting up your development environment, you can create free ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS Developer Sandbox"
      }), " accounts to use for testing and as a safe development environment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deploying"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you have tested your changes and are ready to take them live, it is time to deploy your changes to your production portal. Based on your local configuration, you will need to run the CMS CLI command with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--portal"
      }), " argument to interact with your production account, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme/src my-theme --portal=PROD"
      }), ". When uploading files to your production account, pay attention if there were any errors to diagnose, and make sure to briefly browse your live website to make sure there were not any unintended consequences of the deploy."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you work as part of a web development team, it is recommended to have your entire production codebase source of truth in version control, and to deploy to your product portal when changes are merged in master. This way, your team of developers can use your favorite version control system to collaborate, track changes and easily roll-back changes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To learn more about setting up continuous integration with git repositories, follow this guide on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "utilizing GitHub actions to deploy to your production account when changes are merged into master"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}