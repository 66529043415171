"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'reference/api/crm/objects/companies/v1';
const title = exports.title = 'Companies API - V1';
const name = exports.name = 'Companies API - V1';
const metaDescription = exports.metaDescription = 'Companies API - V1';
const orderedTitles = exports.orderedTitles = ['Update a group of companies'];
const orderedHttpMethods = exports.orderedHttpMethods = ['POST'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(EndpointCard, {
    children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
      children: [(0, _jsxRuntime.jsx)(_components.h4, {
        children: "Update a group of companies"
      }), (0, _jsxRuntime.jsx)(Endpoint, {
        method: "POST",
        path: "/companies/v1/batch-async/update"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Update a group of existing company records by their ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "companyId"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This endpoint takes a list of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), "s and that correspond to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "companyId"
        }), " of the company you want to update, and a list of properties to update for that company."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Note:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "This endpoint can only be used to update existing company records based on their companyId. It cannot create new records"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Up to 100 companies can be updated in a single request. There is no limit to the number of properties that can be updated per company."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "If there are any errors in the request, the entire request will fail and no updates will be applied to any records."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The updates are processed asynchronously, so it may take several minutes to see the updates in HubSpot if you're sending a large number of updates."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
        scope: "crm.objects.companies.write"
      }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
        children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "objectId",
          required: true,
          type: "Body",
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "The companyId of the company that you want to update."
          })
        }), (0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "properties",
          required: true,
          type: "Body",
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "A list of properties that you want to update. Each property must include the name of the property to be updated, and the value of the property to update to."
          })
        })]
      })]
    }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
      children: [(0, _jsxRuntime.jsx)(EndpointExample, {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: "CkV4YW1wbGUgUE9TVCBVUkw6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vY29tcGFuaWVzL3YxL2JhdGNoLWFzeW5jL3VwZGF0ZQoKRXhhbXBsZSBQT1NUIGJvZHk6ClsKICB7CiAgICAib2JqZWN0SWQiOiAxNzQzMzAyNiwKICAgICJwcm9wZXJ0aWVzIjogWwogICAgICB7CiAgICAgICAgIm5hbWUiOiAibmFtZSIsCiAgICAgICAgInZhbHVlIjogIlVwZGF0ZWQgQ29tcGFueSBOYW1lIgogICAgICB9CiAgICBdCiAgfSwKICB7CiAgICAib2JqZWN0SWQiOiAxNjk2MjcwNSwKICAgICJwcm9wZXJ0aWVzIjogWwogICAgICB7CiAgICAgICAgIm5hbWUiOiAibmFtZSIsCiAgICAgICAgInZhbHVlIjogIkJpb3RlY2ggQ29tcGFueSBOYW1lIgogICAgICB9LAogICAgICB7CiAgICAgICAgIm5hbWUiOiAiaW5kdXN0cnkiLAogICAgICAgICJ2YWx1ZSI6ICJCaW90ZWNobm9sb2d5IgogICAgICB9CiAgICBdCiAgfQpdCgpFeGFtcGxlIHJlc3BvbnNlOgpSZXR1cm5zIGEgMjAyIEFjY2VwdGVkIHJlc3BvbnNlIG9uIHN1Y2Nlc3Mu"
        })
      }), (0, _jsxRuntime.jsx)(_components.h3, {
        children: "Response details"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Returns a 202 Accepted response on success. Any issues with the JSON will result in a 4xx response with details about the specific issue in the body of the response."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}