"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620920;
const slug = exports.slug = 'guides/apps/marketplace/app-marketplace-listing-requirements';
const title = exports.title = "Marketplace des applications | Conditions relatives aux listings d'applications";
const name = exports.name = 'EMEA (FR) App Listing Requirements';
const metaDescription = exports.metaDescription = "Les partenaires d'application peuvent désormais consulter depuis un espace unique les directives et exigences de HubSpot pour le listing de leurs applications sur le marketplace des applications. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "conditions-requises",
  "label": "Conditions requises",
  "parent": null
}, {
  "depth": 1,
  "id": "conditions-relatives-aux-marques",
  "label": "Conditions relatives aux marques",
  "parent": "conditions-requises"
}, {
  "depth": 1,
  "id": "conditions-relatives-aux-listings",
  "label": "Conditions relatives aux listings",
  "parent": "conditions-requises"
}, {
  "depth": 0,
  "id": "analyse%2C-retour-et-approbation",
  "label": "Analyse, retour et approbation",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9compenses-pour-les-partenaires-d-applications-list%C3%A9es",
  "label": "Récompenses pour les partenaires d'applications listées",
  "parent": null
}, {
  "depth": 0,
  "id": "ressources-associ%C3%A9es",
  "label": "Ressources associées",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Conditions relatives aux listings sur le marketplace des applications"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les soumissions de listings d'applications sont étudiées manuellement par l'équipe qualité de l'écosystème HubSpot et seront rejetées si elles ne respectent pas les conditions décrites ci-dessous. Une fois que votre application répond à ces exigences, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "créer votre listing d'application"
      }), " à partir de votre compte de développeur d'applications en accédant à ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketplace des applications > Listings > Créer un listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conditions requises"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID d'application HubSpot unique :"
          }), " votre application doit autoriser les demandes d'API avec l'ID d'application HubSpot public (et l'", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "ID de client OAuth"
          }), ") associé à votre listing d'application."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un listing ne doit pas rediriger vers une autre application publique ou privée."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Votre application publique répertoriée ne doit pas nécessiter le fonctionnement d'une autre application publique ou privée."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Votre application publique listée doit être unique. Si vous avez déjà publié une application et que vous souhaitez la remplacer, vous devez mettre à jour l'application existante au lieu d'en publier une nouvelle."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ne créez pas plusieurs applications qui répondent au même cas d'utilisation. Les applications ayant des fonctionnalités similaires et utilisant les mêmes API doivent être consolidées en une seule application."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "OAuth :"
          }), " votre application doit utiliser OAuth comme méthode d'autorisation unique. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "utilisation d'OAuth"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Installations :"
          }), " votre application doit avoir au moins trois ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/certification-requirements#:~:text=Active%20installs%20are%20the%20number%20of%20unique%20HubSpot%20production%20accounts%2C%20unaffiliated%20with%20your%20organization%2C%20showing%20successful%20app%20activity%20within%20the%20past%2030%20days.",
            children: "installations actives uniques"
          }), ". Autrement, vous ne pourrez pas soumettre votre listing d'application."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Périmètres d'accès :"
          }), " vous devez uniquement demander les périmètres d'accès nécessaires pour votre application. ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth#scopes",
            children: "Vérifiez vos périmètres d'accès"
          }), " et assurez-vous que vous ne demandez pas d'accès inutile (cette pratique permet de meilleurs taux de conversion)."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "paramètres de domaine avancés"
            }), " de votre application doivent être activés. Tous les domaines requis, conditionnellement requis et facultatifs doivent être sélectionnés pour éviter les erreurs. Ces paramètres se trouvent dans le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#app-developer-accounts",
              children: "compte développeur"
            }), " qui gère votre application."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Conditions générales"
          }), " : vous devez consulter et accepter les conditions générales du ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/app-program-agreement",
            children: "contrat relatif au Programme Partenaires d'applications de HubSpot"
          }), ". HubSpot, vous ainsi que nos clients communs sont ainsi protégés. Vous ne pourrez pas soumettre votre listing d'application sans réaliser cette étape."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Secteurs d'activité restreints :"
          }), " votre application ne doit pas s'adapter ou fournir des fonctionnalités qui serviraient exclusivement les clients de l'un des ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/acceptable-use#Restricted-Industries",
            children: "secteurs d'activité restreints"
          }), " de HubSpot."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conditions relatives aux marques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Votre application et sa documentation associée (documentation, pages de destination, etc.) doivent répondre aux ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/branding-guidelines",
          children: "Directives de branding de HubSpot"
        }), ". Par exemple, mettez en majuscule la lettre « S » dans « HubSpot » chaque fois que vous faites référence à HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Votre application et sa documentation associée (documentation, pages de destination, etc.) ne doivent pas être contraires aux ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "Conditions générales d'utilisation des marques commerciales de HubSpot"
        }), ". Par exemple, ne combinez pas le nom de HubSpot (y compris « Hub » et « HubSpot ») avec le nom ou le logo de votre application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conditions relatives aux listings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez rempli ces conditions, vous pouvez soumettre votre listing d'application. Lorsque vous soumettez votre listing d'application, vous devez renseigner correctement toutes les informations. Ces champs sont particulièrement importants et si vous ne répondez pas à ces exigences, votre listing ne sera défini qu'en mode Brouillon :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le contenu de votre listing doit être spécifique à l'intégration, contrairement aux informations générales sur le produit. Il doit contenir des informations sur la valeur ajoutée pour les clients avec le téléchargement et l'utilisation de cette intégration. Quelques bons exemples : ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/calling/aircall",
          children: "Aircall"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/sales-enablement/cloudfiles",
          children: "CloudFiles"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/partner-relationship-management/reveal-191193",
          children: "Reveal"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous devez fournir un lien vers une documentation de configuration publique (sans connexion ni paywall) spécifique à votre intégration HubSpot.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Votre guide de configuration ne peut pas simplement être votre page d'accueil ou une base de connaissances générale."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Il doit plutôt présenter les différentes étapes pour installer et configurer l'intégration."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["À titre d'exemple, consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://orgcharthub.com/guides/setup",
              children: "guide d'installation d'OrgChartHub"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Incluez une URL de bouton d'installation pertinente qui redirige les clients vers une page où ils peuvent facilement connecter votre application à HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les URL des ressources de support de votre application (site web de support, forum de la communauté HubSpot, étude de cas) doivent être en ligne, à jour et accessibles publiquement."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les URL des Conditions d'utilisation et de la Politique de confidentialité de votre application doivent être en ligne et à jour."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tous les champs d'URL ont une limite de 250 caractères."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "données partagées"
        }), ", qui permettent aux utilisateurs de savoir comment les informations circuleront entre votre application et HubSpot, doivent être précises, à jour et présenter les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements#:~:text=Webhooks%20API.-,Scopes,-%3A%20You%20must",
          children: "périmètres d'accès"
        }), " requis par votre application.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Tous les objets sélectionnés dans vos périmètres d'accès OAuth doivent être documentés dans le tableau ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Données partagées"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si votre application demande à la fois des périmètres d'accès de lecture et de rédaction d'objet, la synchronisation des données doit être annoncée comme bidirectionnelle pour ces objets spécifiques."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Votre listing de marketplace des applications doit contenir des informations claires et précises sur les tarifs.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Vous devez proposer au moins un plan tarifaire pertinent pour votre intégration HubSpot, qui doit correspondre aux informations publiées sur votre site web."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les plans tarifaires gratuits ne doivent être utilisés que pour les modèles libres ou freemium."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous devez inclure au moins une méthode de contact du support."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Suivez les directives indiquées ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/testing-credentials",
          children: "ici"
        }), " pour fournir des informations d'identification de test pour votre listing d'application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Analyse, retour et approbation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez soumis votre listing, l'équipe qualité de l'écosystème HubSpot procédera à une première analyse dans les 10 jours ouvrables. Si l'une des informations fournies est incorrecte, trompeuse ou incomplète, nous vous en informerons. L'ensemble du processus d'examen et de feedback sur l'application ne devrait pas prendre plus de 60 jours à partir du moment où le feedback est partagé. Comme indiqué dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/app-program-agreement",
        children: "Conditions générales du marketplace des applications"
      }), ", HubSpot se réserve le droit de retirer ou de refuser la publication de votre listing d'application à tout moment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récompenses pour les partenaires d'applications listées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Listing dédié dans la marketplace HubSpot des applications"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Accès prioritaire au support développeur via un alias de support dédié"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ressources communautaires pour les développeurs, y compris des webinars, des forums et bien plus encore"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sélection de ressources marketing, y compris des modèles de RP et des guides de lancement"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Parrainage, stands et tickets remisés pour des événements INBOUND"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Logiciel remisé via le programme de capital-amorçage HubSpot pour start-ups"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Newsletters mensuelles avec des nouveautés marketing, des sorties de produit et bien plus encore"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ressources associées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/listing-your-app",
          children: "Comment lister votre application"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/certification-requirements",
          children: "Conditions relatives à la certification des applications"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "Documentation de référence pour les API"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/overview",
          children: "Comment utiliser les API de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
          children: "Forum de la communauté des développeurs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/contact-our-partnerships-team",
          children: "Contacter l'équipe Partenaire d'application"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}