"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125371;
const slug = exports.slug = 'reference/cms/modules/export-to-template-context';
const title = exports.title = 'Parameter „export_to_template_context“';
const name = exports.name = 'EMEA DACH (DE) export_to_template_context';
const metaDescription = exports.metaDescription = '„export_to_template_context“ ist ein Parameter, der die Parameter eines Moduls für die Vorlagenumgebung verfügbar macht, ohne dass das Modul tatsächlich gerendert wird. Dieser Parameter kann mit allen HubL-Modul-Tags verwendet werden. Das widget_data-Tag wird verwendet, um diese Parameter abzurufen, sie in Variablen zu speichern und/oder sie in die Logik Ihrer Vorlage einzubinden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "verwendung-in-benutzerdefinierten-modulen",
  "label": "Verwendung in benutzerdefinierten Modulen",
  "parent": null
}, {
  "depth": 0,
  "id": "vom-benutzer-ausw%C3%A4hlbare-hintergrundbilder",
  "label": "Vom Benutzer auswählbare Hintergrundbilder",
  "parent": null
}, {
  "depth": 0,
  "id": "auswahlfeld-zum-rendern-von-vordefiniertem-markup",
  "label": "Auswahlfeld zum Rendern von vordefiniertem Markup",
  "parent": null
}, {
  "depth": 0,
  "id": "abrufen-von-parametern-aus-bereits-in-der-vorlage-gerenderten-modulen",
  "label": "Abrufen von Parametern aus bereits in der Vorlage gerenderten Modulen",
  "parent": null
}, {
  "depth": 0,
  "id": "drucken-von-hubl-modulinformationen-im-blog-listing",
  "label": "Drucken von HubL-Modulinformationen im Blog-Listing",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      pre: "pre",
      h2: "h2",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Parameter „export_to_template_context“"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " ist ein Parameter, der die Parameter eines HubL-Tags für die Vorlagenumgebung verfügbar macht, ohne dass das HubL-Tag tatsächlich gerendert wird. Dieser Parameter kann mit allen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "HubL-Tags"
      }), " verwendet werden. Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), "-Dictionary wird verwendet, um diese Parameter abzurufen, sie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "Variablen"
      }), " zu speichern und/oder sie in die Logik Ihrer Vorlage einzubinden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Indem Sie die Parameter eines HubL-Tags im Vorlagenkontext verfügbar machen, ohne dass das HubL-Tag tatsächlich gerendert wird, können Sie den Benutzern die Möglichkeit geben, im Content-Editor Entscheidungen zu treffen, die sich auf die Darstellung der Vorlage auswirken. Angenommen, Sie möchten einen bestimmten Code-Block nur dann darstellen, wenn der Benutzer einen Wert in ein Feld eingibt. Dies ist mit diesem Parameter möglich."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zuerst müssen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " zum HubL-Tag hinzufügen. Dann müssen Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data.module.parameter_you_want_to_retreive"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"job_title\" path=\"@hubspot/text\", label=\"Enter a Job Title\", value=\"Chief Morale Officer\", export_to_template_context=True %}\n{# Makes the parameters available to be stored and used in template logic #}\n\n{{ widget_data.job_title.body.value }}\n{# Prints the value of the HubL tag but can also be used in template logic #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden finden Sie einige Anwendungen dieses Konzepts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung in benutzerdefinierten Modulen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " wird in benutzerdefinierten Modulen nicht unterstützt, da es für diese keinen wirklichen Zweck erfüllt. Sie müssen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " nicht verwenden, um den Wert eines Moduls in einer Vorlage abzurufen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "Sie können bereits darauf zugreifen"
      }), ". Wenn Sie die Ausgabe des Moduls optisch ausblenden müssen, können Sie das Modul so erstellen, dass es nichts ausgibt, oder ein boolesches Feld einschließen, über das aktiviert oder deaktiviert wird, ob das Modul etwas ausgibt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vom Benutzer auswählbare Hintergrundbilder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Beispiel wird ein Bild-HubL-Tag erstellt, dann aber in den Kontext der Vorlage exportiert und nicht gerendert. Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "-Parameter wird mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), "-Tag abgerufen und als Quelle eines Hintergrundbildes in einem style-Tag wiedergegeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"background_image\" path=\"@hubspot/image\" label='Select a background image',\nsrc='http://cdn2.hubspot.net/hub/428357/file-2117441560-jpg/img/dev-bg-compressed.jpg',\nexport_to_template_context=True %}\n\n\n<!--Sample markup -->\n<div class=\"bg-image-section\" style=\"background:url('{{ widget_data.background_image.src }}'); background-size:cover; min-height: 500px;\">\n    <h1>Supercharge your app with HubSpot</h1>\n    <h3>Build powerful integrations for a global community of users</h3>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies ist zwar in Code-Vorlagen möglich, aber im Allgemeinen ist es besser, ein benutzerdefiniertes Modul zu erstellen, um den Benutzern im Seiten-Editor das beste Erlebnis zu bieten. HubL-Tags wie diese werden als einzelne Felder angezeigt, während Sie mehrere verwandte Felder haben können. Bei Verwendung eines benutzerdefinierten Moduls werden alle seine Felder im Seiten-Editor gruppiert angezeigt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Auswahlfeld zum Rendern von vordefiniertem Markup"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das folgende Beispiel verwendet den Parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " in Verbindung mit einem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#choice",
        children: "Auswahlmodul"
      }), ", um eine Bannernachricht auf einer Karriereseite zu ändern. Der Benutzer wählt über die Benutzeroberfläche eine Abteilung aus und die Überschrift ändert sich, ohne dass der Benutzer den Inhalt tatsächlich bearbeiten muss."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"department\" path=\"@hubspot/choice\", label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die gleiche Funktionalität kann mit einem Auswahlfeld innerhalb eines benutzerdefinierten Moduls reproduziert werden. Die Benutzeroberfläche des benutzerdefinierten Moduls macht die Auswahl von Optionen mit einem Wert und einem Label ziemlich einfach."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abrufen von Parametern aus bereits in der Vorlage gerenderten Modulen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen Parameter von einem Modul oder Tag abrufen möchten, das bereits auf einer Seite gerendert wird, kann das Modul in einem Dictionary mit dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgets"
      }), " aufgerufen werden. Der Parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " ist nicht erforderlich. Die Syntax lautet wie folgt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// HubL\n{{ content.widgets.name_of_module.body.parameter }}\n\n{{ content.widgets.my_text.body.value }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Die obige Methode unterstützt nicht das Abrufen von Werten aus Feldern in globalen Modulen, da ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content.widgets"
        }), " nicht auf globale Module zugreift."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da verschiedene Felder Daten in unterschiedlichen Formaten speichern, ist es oft praktisch, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "Entwicklerinfo"
      }), " zu nutzen, um zu sehen, wie Sie auf die spezifischen Daten zugreifen, die Sie anzeigen möchten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Drucken von HubL-Modulinformationen im Blog-Listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Blog-Vorlagen werden in der Regel für Blogs verwendet, können aber auch zum Erstellen verschiedener anderer Typen von Listings wiederverwertet werden. Dazu können Sie die oben beschriebenen Techniken anwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie möchten zum Beispiel ein Listing-Layout mit Pressemitteilungen erstellen, die Ihr Unternehmen erhalten hat. Dabei soll über das Listing aber nicht zu Beiträgen verlinkt werden, sondern zu einer anderen Seite."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können dieses Konzept unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/projects",
        children: "academy.hubspot.com/projects"
      }), " in Aktion sehen. Die Listing-Seite für Projekte ist eine Blog-Listing-Vorlage, aber jeder Beitrag verlinkt zu einer regulären HubSpot-Seite. Der Content-Autor gibt den Ziel-Link im Editor an."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Header des Codes des einzelnen Blog-Beitrags definieren Sie ein Textfeld. Wenn Sie nicht möchten, dass der Text im Beitrag dargestellt wird, verwenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"custom_blog_link\" path=\"@hubspot/text\", label=\"Link to external press item\", export_to_template_context=True %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieses Textfeld kann in jedem Blog-Beitrag bearbeitet werden. Als Nächstes müssen wir einen Link in unserem Listing definieren. Da die Widget-Daten jedoch nur im Kontext des Beitrags vorhanden sind, müssen wir eine andere Syntax verwenden, um die Widget-Daten abzurufen und den Link auszufüllen. In diesem Fall verwenden wir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.widgets.custom_blog_link.body.value"
      }), ". Während die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " für das Blog-Listing nicht verfügbar sind, wird der Wert dieses Feldes weiterhin im Kontext der Widgets der einzelnen Inhalte gespeichert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend sehen Sie eine einfache Blog-Listing-Schleife, die diesen benutzerdefinierten Link bei jedem Beitrag darstellt. Wenn Sie dieses Verfahren anwenden, sollten Sie sicherstellen, dass Sie das Unterverzeichnis, das automatisch für jeden Blog-Beitrag erstellt wird, zu Ihrer robots.txt-Datei hinzufügen, um zu verhindern, dass diese leeren Beiträge von Google und anderen Crawlern gecrawlt werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n<a href=\"{{ content.widgets.custom_blog_link.body.value }}\">\n    Click here to see this press feature!\n</a>\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}