"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 143007979024;
const slug = exports.slug = 'reference/api/crm/lists/list-definitions';
const title = exports.title = 'List filter definitions (BETA)';
const name = exports.name = 'vNext - List Filter Definitions';
const metaDescription = exports.metaDescription = 'Learn how to structure filters when using the Lists API. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "filtertype-options",
  "label": "FilterType options",
  "parent": null
}, {
  "depth": 1,
  "id": "ads_time",
  "label": "ADS_TIME",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "ads_search",
  "label": "ADS_SEARCH",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "cta",
  "label": "CTA",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "email_event",
  "label": "EMAIL_EVENT",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "email_subscription",
  "label": "EMAIL_SUBSCRIPTION",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "event",
  "label": "EVENT",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "form_submissions",
  "label": "FORM_SUBMISSIONS",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "form_submission_on_page",
  "label": "FORM_SUBMISSION_ON_PAGE",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "in_list",
  "label": "IN_LIST",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "integration_event",
  "label": "INTEGRATION_EVENT",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "page_view",
  "label": "PAGE_VIEW",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "privacy",
  "label": "PRIVACY",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "property",
  "label": "PROPERTY",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "survey_monkey",
  "label": "SURVEY_MONKEY",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "survey_monkey_value",
  "label": "SURVEY_MONKEY_VALUE",
  "parent": "filtertype-options"
}, {
  "depth": 1,
  "id": "webinar",
  "label": "WEBINAR",
  "parent": "filtertype-options"
}, {
  "depth": 0,
  "id": "property-operation-definitions",
  "label": "Property operation definitions",
  "parent": null
}, {
  "depth": 1,
  "id": "all_property",
  "label": "ALL_PROPERTY",
  "parent": "property-operation-definitions"
}, {
  "depth": 1,
  "id": "bool",
  "label": "BOOL",
  "parent": "property-operation-definitions"
}, {
  "depth": 1,
  "id": "enumeration",
  "label": "ENUMERATION",
  "parent": "property-operation-definitions"
}, {
  "depth": 1,
  "id": "multistring",
  "label": "MULTISTRING",
  "parent": "property-operation-definitions"
}, {
  "depth": 1,
  "id": "number",
  "label": "NUMBER",
  "parent": "property-operation-definitions"
}, {
  "depth": 1,
  "id": "string",
  "label": "STRING",
  "parent": "property-operation-definitions"
}, {
  "depth": 1,
  "id": "time_point",
  "label": "TIME_POINT",
  "parent": "property-operation-definitions"
}, {
  "depth": 2,
  "id": "property_referenced",
  "label": "PROPERTY_REFERENCED",
  "parent": "property-operation-definitions"
}, {
  "depth": 2,
  "id": "date",
  "label": "DATE",
  "parent": "property-operation-definitions"
}, {
  "depth": 2,
  "id": "indexed",
  "label": "INDEXED",
  "parent": "property-operation-definitions"
}, {
  "depth": 1,
  "id": "time_ranged",
  "label": "TIME_RANGED",
  "parent": "property-operation-definitions"
}, {
  "depth": 2,
  "id": "indexed",
  "label": "INDEXED",
  "parent": "property-operation-definitions"
}, {
  "depth": 2,
  "id": "date",
  "label": "DATE",
  "parent": "property-operation-definitions"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      ul: "ul",
      li: "li",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "List filter definitions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, review the definitions for the filter type, property operations, and coalescing refine by and pruning refine by operations."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "FilterType options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterType"
      }), " parameter is used to define the filter within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranch"
      }), ". There are 16 different types of filters."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ADS_TIME"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Evaluates whether a contact has seen any ads in the timeframe defined by the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pruningRefineBy"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"ADS_TIME\",\n  \"pruningRefineBy\": PruningRefineBy\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ADS_SEARCH"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has performed the ad interactions as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"ADS_SEARCH\",\n  \"entityType\": \"AD\",\n  \"searchTermType\": \"ID\",\n  \"searchTerms\": [\"hubspot\", \"hubspot developers\"],\n  \"adNetwork\": \"LINKEDIN\",\n  \"operator\": \"CONTAINS\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "entityType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of asset interacted with. Values include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "AD"
                }), ": a specific ad. Supports ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "ID"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "NAME"
                }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "AD_TYPE"
                }), " as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "searchTermTypes"
                }), ". "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ADGROUP"
                }), ": a specific ad group. Supports ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "ID"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "NAME"
                }), " as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "SearchTermTypes"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CAMPAIGN"
                }), ": a specific ad campaign. Supports ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "ID"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "NAME"
                }), " as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "SearchTermTypes"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "searchTermType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "searchTerms"
            }), " type. Values include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ID"
                }), " - supports ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_KNOWN"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_EQUAL_TO"
                }), " as operators. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NAME"
                }), " - supports all operators"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "AD_TYPE"
                }), " - supports ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_KNOWN"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_EQUAL_TO"
                }), " as operators."]
              })]
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD_TYPE"
            }), " cannot be used when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "entityType"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ADGROUP"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CAMPAIGN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "searchTerms"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Required, unless the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_KNOWN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "adNetwork"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ad network. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINKEDIN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ADWORDS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FACEBOOK"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ENDS_WITH"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "STARTS_WITH"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_KNOWN"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CTA"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not interacted with a specific CTA as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"CTA\",\n  \"ctaName\": \"b7988654-536d-4f7f-976b-8e9e2c7bb54\",\n  \"coalescingRefineBy\": CoalescingRefineBy, // Optional\n \"pruningRefineBy\": PruningRefineBy, // Optional\n  \"operator\": \"HAS_CLICKED_CTA\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_CLICKED_CTA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NOT_CLICKED_CTA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_OPENED_CTA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NOT_OPENED_CTA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_CLICKED_CTA_PLACEMENT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NOT_CLICKED_CTA_PLACEMENT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_OPENED_CTA_PLACEMENT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NOT_OPENED_CTA_PLACEMENT"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "EMAIL_EVENT"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not interacted with a marketing email as defined by the filters."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"EMAIL_EVENT\",\n  \"level\": \"EMAIL_API_CAMPAIGN\",\n  \"emailId\": \"79019645\",\n \"appId\": \"2285\",\n  \"operator\": \"OPENED\",\n \"clickUrl\": string, // Optional\n \"pruningRefineBy\": PruningRefineBy // Optional\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "level"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The email campaign level. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_API_CAMPAIGN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_API_CAMPAIGN_GROUP"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_CAMPAIGN"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_CLICKED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKED_SPAM"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPENED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPENED_BUT_LINK_NOT_CLICKED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPENED_BUT_NOT_REPLIED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "REPLIED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "UNSUBSCRIBED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BOUNCED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RECEIVED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RECEIVED_BUT_NOT_OPENED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT_BUT_LINK_NOT_CLICKED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT_BUT_NOT_RECEIVED"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "clickURL"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Only allowed when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_CLICKED"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pruningRefineBy"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Only supports ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ABSOLUTE_COMPARATIVE"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ABSOLUTE_RANGED"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "EMAIL_SUBSCRIPTION"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not interacted with a marketing email as defined by the filters."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"EMAIL_SUBSCRIPTION\",\n  \"subscriptionIds\": [\"4805877\"],\n  \"acceptedStatuses\": [\"OPT_IN\"],\n  \"subscriptionType\": \"SUBSCRIPTION\" // Optional\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "acceptedStatuses"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The email subscription status. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPT_IN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPT_OUT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_OPTED"
            }), "There can only be one ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "acceptedStatus"
            }), " per ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "filterType"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The subscription type."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "clickURL"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Only allowed when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_CLICKED"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pruningRefineBy"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Only supports ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ABSOLUTE_COMPARATIVE"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ABSOLUTE_RANGED"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "EVENT"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or does not have a specific event as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"EVENT\",\n  \"eventId\": \"123456\",\n  \"operator\": \"HAS_EVENT\",\n \"coalescingRefineBy\": CoalescingRefineBy, // Optional\n \"pruningRefineBy\": PruningRefineBy // Optional\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVENT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_EVENT"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "FORM_SUBMISSIONS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not filled out a specific form or any form as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"FORM_SUBMISSION\",\n  \"formId\": \"6f62a9c7-4200-4b50-9779-3db4d9e40eb2\", // Optional\n  \"coalescingRefineBy\": CoalescingRefineBy, // Optional\n  \"pruningRefineBy\": PruningRefineBy, // Optional\n  \"operator\": \"FILLED_OUT\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FILLED_OUT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_FILLED_OUT"
            }), "If ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_FILLED_OUT"
            }), " is selected, the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pruningRefineBy"
            }), " parameter must be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "FORM_SUBMISSION_ON_PAGE"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not filled out a specific or any form on a specific page as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"FORM_SUBMISSION_ON_PAGE\",\n  \"pageId\": \"397942088\",\n  \"formId\": \"f62a9c7-4200-4b50-9779-3db4d9e40eb\", // Optional\n  \"coalescingRefineBy\": CoalescingRefineBy, // Optional\n  \"pruningRefineBy\": PruningRefineBy, // Optional\n  \"operator\": \"FILLED_OUT\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FILLED_OUT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_FILLED_OUT"
            }), "If ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_FILLED_OUT"
            }), " is selected, the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pruningRefineBy"
            }), " parameter must be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "IN_LIST"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a record is or is not a member of a specific list, import, or workflow as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"IN_LIST\",\n  \"listId\": \"42\", // Optional\n  \"metadata\": [\n    {\n      \"id\": \"56\",\n      \"inListType\": \"WORKFLOWS_ENROLLMENT\"\n    }\n  ], // Optional\n  \"operator\": \"InListOperator\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inListType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The list type. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKFLOWS_ENROLLMENT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKFLOWS_ACTIVE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKFLOWS_GOAL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKFLOWS_COMPLETED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IMPORT"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_LIST"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_IN_LIST"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "INTEGRATION_EVENT"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Integration event filters can be used to filter specific contacts based on whether or not they have interacted with integration events that have properties as specified by the filter lines."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n   \"filterType\":\"INTEGRATION_EVENT\",\n   \"eventTypeId\":\"4009\",\n   \"filterlines\":[\n      {\n         \"property\" :string,\n  \"operation\": PropertyOperation\n      },\n   ]}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Refer to the property operations definitions section."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "PAGE_VIEW"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not viewed a specific page as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"PAGE_VIEW\",\n  \"pageUrl\":\"www.hubspot.com\" ,\n  \"enableTracking\": true, // Optional\n  \"operator\": \"HAS_PAGEVIEW_EQ\",\n  \"coalescingRefineBy\": CoalescingRefineBy, // Optional\n  \"pruningRefineBy\": PruningRefineBy // Optional\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableTracking"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Must be a boolean property operation. Values include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TRUE"
                }), " - can only be used if ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "operator"
                }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "HAS_PAGEVIEW_EQ"
                }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "NOT_HAS_PAGEVIEW_EQ"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FALSE"
                }), " "]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PAGEVIEW_EQ"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PAGEVIEW_CONTAINS"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PAGEVIEW_MATCHES_REGEX"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PAGEVIEW_EQ"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PAGEVIEW_CONTAINS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PAGEVIEW_MATCHES_REGEX<br />"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "PRIVACY"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact does or does not have privacy consent for a specific privacy type as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"PRIVACY\",\n  \"privacyName\": \"PRIVACY_CONSENT_APPROVE\",\n  \"operator\": \"PRIVACY_CONSENT_GRANTED\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "privacyName"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVACY_CONSENT_APPROVE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVACY_CONSENT_DECLINE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVACY_CONSENT_REVOKE"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVACY_CONSENT_GRANTED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVACY_CONSENT_NOT_GRANTED"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "PROPERTY"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Evaluates whether a record's property value satisfies the property filter operation as defined by the filter. Property filters require an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "operation"
      }), " object, which you can learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#property-operation-definitions",
        children: "below"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"PROPERTY\",\n  \"property\": \"name\",\n  \"operation\": PropertyOperation\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The property name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#property-operations-definitions",
              children: "property operations definitions"
            }), " section."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "SURVEY_MONKEY"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not responded to a specific Survey Monkey survey as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"SURVEY_MONKEY\",\n  \"operator\": \"HAS_RESPONDED_TO_SURVEY\",\n  \"surveyId\": \"305206406\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_RESPONDED_TO_SURVEY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NOT_RESPONDED_TO_SURVEY"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "SURVEY_MONKEY_VALUE"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not responded to a specific Survey Monkey survey’s question with a specified value as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"SURVEY_MONKEY_VALUE\",\n  \"operator\": \"HAS_ANSWERED_SURVEY_QUESTION_WITH_VALUE\",\n  \"surveyId\": \"305677\",\n  \"surveyAnswerRowId\": null, // Optional\n  \"surveyAnswerColId\": null, // Optional\n  \"valueComparison\": PropertyOperation,\n  \"surveyQuestion\": \"423564\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "valueComparison"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Refer to the property operations definitions section."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter's operator. Value is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_ANSWERED_SURVEY_QUESTION_WITH_VALUE"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "WEBINAR"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates whether a contact has or has not registered or attended any webinars or a specific webinar as defined by the filter."
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"filterType\": \"WEBINAR\",\n  \"operator\": \"HAS_WEBINAR_REGISTRATION\",\n  \"webinarId\": \"95909203370\" // Optional\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_WEBINAR_REGISTRATION"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_WEBINAR_REGISTRATION"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_WEBINAR_ATTENDANCE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_WEBINAR_ATTENDANCE"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Property operation definitions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When filtering for records with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROPERTY"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "INTEGRATION_EVENT"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SURVEY_MONKEY_VALUE"
      }), " filter type, you'll include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "operation"
      }), " object to define the parameters of the filter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each property operation definition has an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "operationType"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "operator"
      }), " field, and most operations use a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "values"
      }), " field to specify values to compare the property against. You can also include an optional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "includeObjectsWithNoValueSet"
      }), " parameter to include records with no property value set."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "operationType"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "operator"
        }), ": classify the type of operation and how it will operate against a property of value."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "includeObjectsWithNoValueSet"
        }), ": defines how the operation should treat records that do not have a value set for the defined property.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", a record without a value for the evaluated property will be ", (0, _jsxRuntime.jsx)("u", {
              children: "accepted"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " (default), a record without a value for the evaluated property will be ", (0, _jsxRuntime.jsx)("u", {
              children: "rejected"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ALL_PROPERTY"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For all properties, use this filter to determine whether a property value is known or is unknown as defined by the property operation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-anyproperty.png",
        alt: "list-filter-anyproperty"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"email\",\n      \"operation\": {\n        \"operationType\": \"ALL_PROPERTY\",\n        \"operator\": \"IS_KNOWN\"\n      }\n    }\n  ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_KNOWN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_UNKNOWN"
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "BOOL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#property-type-and-fieldtype-values",
        children: "boolean type properties"
      }), ", use this filter to determine whether a current (or historical) boolean property value is or is not (or has or has not) equalled a specific value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-BOOLEAN.png",
        alt: "list-filter-BOOLEAN"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"boolean_property\",\n      \"operation\": {\n        \"operationType\": \"BOOL\",\n        \"operator\": \"IS_EQUAL_TO\",\n        \"value\": true\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_NOT_EQUAL_TO"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_BEEN_EQUAL_TO"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_BEEN_EQUAL_TO"
            }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Calculated properties cannot use a historical ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "operator"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTEGRATION_EVENT"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterType"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UNIFIED_EVENTS"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterBranchType"
                }), " parameters only support ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_EQUAL_TO"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_NOT_EQUAL_TO"
                }), "."]
              })]
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ENUMERATION"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#property-type-and-fieldtype-values",
        children: "enumeration type properties"
      }), ", such as checkboxes and selects, you can use this operation type to filter based on the current or historical value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-enumeration.png",
        alt: "list-filter-enumeration"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"checkbox_property\",\n      \"operation\": {\n        \"operationType\": \"ENUMERATION\",\n        \"operator\": \"IS_ANY_OF\",\n        \"values\": [\"true\", \"false\"]\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_NONE_OF"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_ANY_OF"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_EXACTLY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_NOT_EXACTLY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS_ALL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOES_NOT_CONTAIN_ALL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_BEEN_ANY_OF"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_BEEN_ANY_OF"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_BEEN_EXACTLY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_BEEN_EXACTLY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_CONTAINED_ALL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_CONTAINED_ALL"
            }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Calculated properties cannot use a historical ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "operator"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTEGRATION_EVENT"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterType"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UNIFIED_EVENTS"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterBranchType"
                }), " parameters only support ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_ANY_OF"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_NONE_OF"
                }), "."]
              })]
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "MULTISTRING"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#property-type-and-fieldtype-values",
        children: "string properties"
      }), ", you can use this operation type to filter based on the current value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-multi-string.png",
        alt: "list-filter-multi-string"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"email\",\n      \"operation\": {\n        \"operationType\": \"MULTISTRING\",\n        \"operator\": \"CONTAINS\",\n        \"values\": [\"hubspot\", \"hotmail\"]\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_NOT_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOES_NOT_CONTAIN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "STARTS_WITH"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ENDS_WITH"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_BEEN_EQUAL_TO"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_BEEN_EQUAL_TO"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Calculated properties cannot have a historical ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "operator"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTEGRATION_EVENT"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterType"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UNIFIED_EVENTS"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterBranchType"
                }), " parameters only support ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_EQUAL_TO"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_NOT_EQUAL_TO"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTAINS"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOES_NOT_CONTAIN"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "STARTS_WITH"
                }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "ENDS_WITH"
                }), " parameters."]
              })]
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "NUMBER"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#property-type-and-fieldtype-values",
        children: "string properties"
      }), ", you can use this operation type to filter based on the current value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-number.png",
        alt: "list-filter-number"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"hs_analytics_average_page_views\",\n      \"operation\": {\n        \"operationType\": \"NUMBER\",\n        \"operator\": \"IS_GREATER_THAN\",\n        \"value\": \"5\"\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_NOT_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_GREATER_THAN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_GREATER_THAN_OR_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_LESS_THAN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_LESS_THAN_OR_EQUAL_TO"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_BEEN_EQUAL_TO"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_BEEN_EQUAL_TO"
            }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Calculated properties cannot have a historical ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "operator"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTEGRATION_EVENT"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterType"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UNIFIED_EVENTS"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterBranchType"
                }), " parameters only support ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_EQUAL_TO"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_NOT_EQUAL_TO"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_GREATER_THAN"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_GREATER_THAN_OR_EQUAL_TO"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_LESS_THAN"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_LESS_THAN_OR_EQUAL_TO"
                }), " operators. "]
              })]
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "STRING"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#property-type-and-fieldtype-values",
        children: "string properties"
      }), ", you can use this operation type to filter based on the current or historical value."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-string.png",
        alt: "list-filter-string"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"email\",\n      \"operation\": {\n        \"operationType\": \"STRING\",\n        \"operator\": \"HAS_EVER_CONTAINED\",\n        \"value\": \"hubspot\"\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_NOT_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOES_NOT_CONTAIN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "STARTS_WITH"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ENDS_WITH"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_BEEN_EQUAL_TO"
            }), ",", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_BEEN_EQUAL_TO"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_EVER_CONTAINED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_NEVER_CONTAINED"
            }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Calculated properties cannot have a historical ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "operator"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTEGRATION_EVENT"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterType"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UNIFIED_EVENTS"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "filterBranchType"
                }), " parameters only support ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_EQUAL_TO"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_NOT_EQUAL_TO"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTAINS"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOES_NOT_CONTAIN"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "STARTS_WITH"
                }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "ENDS_WITH"
                }), " parameters."]
              })]
            })]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "TIME_POINT"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#property-type-and-fieldtype-values",
        children: "date properties"
      }), ", you can use this operation type to filter based on if a property has been updated before or after a specific time. That time can be specified as a specific date or relative to the current day."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "timeType"
      }), " field to configure how the property will be compared against time:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#property-referenced",
          children: "PROPERTY_REFERENCED"
        }), ": compares the property to another date property."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#date",
          children: "DATE"
        }), ": compares the property to a specific date"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#indexed",
          children: "INDEXED"
        }), ": compares the property to a date relative to the current day."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inputs and validations differ on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "timeType"
      }), " field: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROPERTY_REFERENCED"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DATE"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "INDEXED"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "PROPERTY_REFERENCED"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compare the property update date against the value in another date property."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-date-reference.png",
        alt: "list-filter-date-reference"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"birthdate\",\n      \"operationType\": \"TIME_POINT\",\n      \"operation\": {\n        \"operator\": \"IS_AFTER\",\n        \"timePoint\": {\n          \"timeType\": \"PROPERTY_REFERENCED\",\n          \"timezoneSource\": \"CUSTOM\",\n          \"zoneId\": \"America/New_York\",\n          \"property\": \"hs_latest_open_lead_date\",\n          \"referenceType\": \"UPDATED_AT\"\n        },\n        \"endpointBehavior\": \"EXCLUSIVE\",\n        \"propertyParser\": \"UPDATED_AT\",\n        \"type\": \"TIME_POINT\"\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values inclue:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_AFTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_BEFORE"
            }), (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: "The property passed must be a valid datetime property."
              })
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PROPERTY_REFERENCED"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyParser"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VALUE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED AT"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "VALUE"
                }), " is selected, the comparison is the value of the property."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UPDATED_AT"
                }), " is selected, the updated time of the property is used for comparison."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpointBehavior"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXCLUSIVE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "zoneID"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The account's default time zone."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "DATE"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compare the property update date against a specific date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-date.png",
        alt: "list-filter-date"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"hs_lifecyclestage_customer_date\",\n      \"operation\": {\n        \"operationType\": \"TIME_POINT\",\n        \"operator\": \"IS_AFTER\",\n        \"endpointBehavior\":\"EXCLUSIVE\",\n        \"timePoint\": {\n         \"timeType\": \"DATE\",\n         \"timezoneSource\": \"CUSTOM\",\n         \"zoneId\":\"America/New_York\",\n         \"year\":\"2024\",\n         \"month\":\"1\",\n         \"day\":\"1\",\n         \"hour\":\"23\",\n         \"minute\":\"59\",\n         \"second\":\"59\",\n         \"millisecond\":\"999\"\n        }\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DATE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyParser"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VALUE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED AT"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "VALUE"
                }), " is selected, the comparison is the value of the property."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UPDATED_AT"
                }), " is selected, the updated time of the property is used for comparison."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpointBehavior"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXCLUSIVE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "zoneID"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The account's default time zone."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values inclue:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_AFTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_BEFORE"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "The date must be a valid date."
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["When the property is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_AFTER"
                }), ", these fields must be set:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“hour”: 23"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“minute”: 59"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“second”: 59"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“millisecond”: 999"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["When the property is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_BEFORE"
                }), ", these fields must be set:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“hour”: 00"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“minute”: 00"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“second”: 00"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“millisecond”: 000"
                    })
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "INDEXED"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compare the property update date against a date relative to the current day."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-date-indexed.png",
        alt: "list-filter-date-indexed"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"hs_lifecyclestage_customer_date\",\n      \"operation\": {\n        \"operationType\": \"TIME_POINT\",\n        \"operator\": \"IS_BEFORE\",\n        \"endpointBehavior\":\"EXCLUSIVE\",\n        \"timePoint\": {\n         \"timeType\": \"INDEXED\",\n         \"indexReference\":{\"referenceType\": \"TODAY\"},\n         \"offset\":{\"days\":-10},\n         \"timezoneSource\": \"CUSTOM\",\n         \"zoneId\":\"America/New_York\"\n        }\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INDEXED"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyParser"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VALUE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED AT"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "VALUE"
                }), " is selected, the comparison is the value of the property."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UPDATED_AT"
                }), " is selected, the updated time of the property is used for comparison."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpointBehavior"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXCLUSIVE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "zoneID"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The account's default time zone."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Offsets the comparative date in days relative to the current day."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values inclue:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_AFTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_BEFORE"
            }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["When the property is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_AFTER"
                }), ", offset must be set at < 0."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["When the property is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_BEFORE"
                }), ", offset must be set at >=0."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "TIME_RANGED"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#property-type-and-fieldtype-values",
        children: "date properties"
      }), ", you can use this filter to determine if a property has been updated between or outside of two specific times. Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "indexReference"
      }), " field, these times can be specified as a specific date (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DATE"
      }), ") or relative to the current day (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "INDEXED"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "INDEXED"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compares the property update date to a time range relative to the current day."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-date-range-index.png",
        alt: "list-filter-date-range-index"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n    {\n      \"filterType\": \"PROPERTY\",\n      \"property\": \"hs_lifecyclestage_customer_date\",\n      \"operation\": {\n        \"operator\": \"IS_NOT_BETWEEN\",\n        \"operationType\": \"TIME_RANGED\",\n        \"includeObjectsWithNoValueSet\": false,\n        \"lowerBoundEndpointBehavior\": \"INCLUSIVE\",\n        \"upperBoundEndpointBehavior\": \"INCLUSIVE\",\n        \"propertyParser\": \"UPDATED_AT\",\n        \"lowerBoundTimePoint\": {\n          \"timeType\": \"INDEXED\",\n          \"timezoneSource\": \"CUSTOM\",\n          \"zoneId\": \"America/New_York\",\n          \"indexReference\": {\n            \"referenceType\": \"TODAY\"\n          },\n          \"offset\": {\n            \"days\": -10\n          }\n        },\n        \"upperBoundTimePoint\": {\n          \"timeType\": \"INDEXED\",\n          \"timezoneSource\": \"CUSTOM\",\n          \"zoneId\": \"America/New_York\",\n          \"indexReference\": {\n            \"referenceType\": \"NOW\"\n          }\n        }\n      }\n    }\n  ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INDEXED"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyParser"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VALUE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED AT"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "VALUE"
                }), " is selected, the comparison is the value of the property."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UPDATED_AT"
                }), " is selected, the updated time of the property is used for comparison."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lowerBoundEndpointBehavior"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCLUSIVE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upperBoundEndpointBehavior"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCLUSIVE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "zoneID"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The account's default time zone."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values inclue:", (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_BETWEEN"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_NOT_BETWEEN"
                })]
              })
            }), "When the property is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_AFTER"
            }), ", offset must be set at <= 0.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsxs)("li", {
                children: ["When the property is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "IS_BEFORE"
                }), ", offset must be set at >=0."]
              })
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indexReference"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{“referenceType”:”NOW”}"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{“referenceType”:”TODAY”}"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "indexReference"
                }), " is equal to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "{“referenceType”:”NOW”}"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "offset"
                }), " must be >=0"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "indexReference"
                }), " is equal to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "{“referenceType”:”TODAY”}"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "offset"
                }), " must be <=0"]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Other validations:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyParser"
        }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "VALUE"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "operator"
        }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IS_BETWEEN"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "lowerBoundTimePoint.indexReference"
            }), " should be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{“referenceType”:”NOW”}"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "upperBoundTimePoint.indexReference"
            }), " should be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{“referenceType”:”TODAY”}"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For all other combinations of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyParser"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "operator"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "lowerBoundTimePoint.indexReference"
            }), " should be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{“referenceType”:”TODAY”}"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "upperBoundTimePoint.indexReference"
            }), " should be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{“referenceType”:”NOW”}"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "DATE"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compares the property to a specific time range."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-filter-date-range-date.png",
        alt: "list-filter-date-range-date"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "\"filters\": [\n          {\n            \"filterType\": \"PROPERTY\",\n            \"property\": \"hs_lifecyclestage_customer_date\",\n            \"operation\": {\n              \"operator\": \"IS_BETWEEN\",\n              \"type\": \"TIME_RANGED\",\n              \"operationType\": \"TIME_RANGED\",\n              \"includeObjectsWithNoValueSet\": false,\n              \"lowerBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"upperBoundEndpointBehavior\": \"INCLUSIVE\",\n              \"propertyParser\": \"VALUE\",\n              \"lowerBoundTimePoint\": {\n                \"timeType\": \"DATE\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"year\": 2024,\n                \"month\": 1,\n                \"day\": 17,\n                \"hour\": 0,\n                \"minute\": 0,\n                \"second\": 0,\n                \"millisecond\": 0\n              },\n              \"upperBoundTimePoint\": {\n                \"timeType\": \"DATE\",\n                \"timezoneSource\": \"CUSTOM\",\n                \"zoneId\": \"America/New_York\",\n                \"year\": 2024,\n                \"month\": 1,\n                \"day\": 27,\n                \"hour\": 23,\n                \"minute\": 59,\n                \"second\": 59,\n                \"millisecond\": 999\n              }\n            }\n          }\n        ]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Accepted Values"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DATE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyParser"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VALUE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED AT"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "VALUE"
                }), " is selected, the comparison is the value of the property."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "UPDATED_AT"
                }), " is selected, the updated time of the property is used for comparison."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lowerBoundEndpointBehavior"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCLUSIVE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upperBoundEndpointBehavior"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCLUSIVE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "zoneID"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The account's default time zone."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter operator. Values include:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_AFTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IS_BEFORE"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "The date must be a valid date."
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "lowerBoundTimePoint"
                }), " parameter, these fields must be set:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“hour”: 23"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“minute”: 59"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“second”: 59"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“millisecond”: 999"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "upperBoundTimePoint"
                }), ", these fields must be set:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“hour”: 00"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“minute”: 00"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“second”: 00"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "“millisecond”: 000"
                    })
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}