"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35877638219;
const slug = exports.slug = 'reference/cms/hubl/tags/dnd-areas';
const title = exports.title = 'ドラッグ＆ドロップエリアのHubLタグ';
const name = exports.name = 'APAC JAPAN (ja) | Dev. Page | [new] HubL Supported Tags | dnd_areas | 202211';
const metaDescription = exports.metaDescription = 'このHubLタグは、ドラッグ＆ドロップエリアの作成に使用します。開発者はこれらのタグを使用して、コンテンツエディター内でレイアウト、スタイル、コンテンツを直接変更できる、ページ内のセクションを作成できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "dnd_area",
  "label": "dnd_area",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_section",
  "label": "dnd_section",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_column",
  "label": "dnd_column",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_row",
  "label": "dnd_row",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_module",
  "label": "dnd_module",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%83%8C%E6%99%AF",
  "label": "背景",
  "parent": null
}, {
  "depth": 1,
  "id": "background_color",
  "label": "background_color",
  "parent": "%E8%83%8C%E6%99%AF"
}, {
  "depth": 1,
  "id": "background_linear_gradient",
  "label": "background_linear_gradient",
  "parent": "%E8%83%8C%E6%99%AF"
}, {
  "depth": 1,
  "id": "background_image",
  "label": "background_image",
  "parent": "%E8%83%8C%E6%99%AF"
}, {
  "depth": 0,
  "id": "dnd%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%81%8B%E3%82%89%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%B8%E3%81%AE%E5%A4%89%E6%8F%9B%E6%96%B9%E6%B3%95",
  "label": "dndのスタイルパラメーターからページへの変換方法",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ドラッグ＆ドロップエリアのHubLタグ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "を使用することで、開発者はコンテンツエディター内でレイアウト、スタイル、コンテンツを直接変更できる、ページ内のセクションとグローバルパーシャルを作成できます。ドラッグ＆ドロップエリアのセットアップ手順については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "ドラッグ＆ドロップエリアの作成チュートリアル"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリアは、12列のレスポンシブグリッドを基盤としています。ドラッグ＆ドロップタグでは、列と行を指定するクラス名を指定して、マークアップをレンダリングします。そのようなクラス名を対象とするスタイルシートを追加する必要があります。実装可能なレイアウトスタイルの例については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "HubSpot CMSボイラープレート"
      }), "（英語）を参照してください。スタイルシートをテンプレートに追加するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172854037902"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**現時点では、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/blog",
          children: "ブログ記事"
        }), "と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates#email",
          children: "Eメールテンプレート"
        }), "でドラッグ＆ドロップエリアを使用することはできません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas",
        children: "ドラッグ＆ドロップエリア"
      }), "は、ウェブページの一部の構造、デザイン、コンテンツを編集可能にするためのコンテナーです。ドラッグ＆ドロップエリアの既定のコンテンツは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "タグの本文として提供します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュール自体にドラッグ＆ドロップエリアを含めることはできません。モジュールへの追加コンテンツを統一するインターフェイスを制作担当者に提供するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/module-theme-fields#repeaters",
        children: "繰り返し可能なフィールドとグループ"
      }), "を代わりに使用してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "タグには、以下のパラメーターを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "class"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dnd_areaを囲むdivに追加するクラス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エディター上のサイドバーのラベルとして使用されます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "入力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_area \"unique_name\", class=\"main\" %}\n\n{% end_dnd_area %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"container-fluid main\">\n  <div class=\"row-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n    </div>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "タグには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "タグを含めることもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**制作担当者は、テンプレートに「", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "」タグが含まれているかどうかに応じて、ページのテンプレートを同じタイプの別のテンプレートと入れ替えることができます。"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["ビジュアル ドラッグ＆ドロップ レイアウト エディター上で作成したテンプレートは、「", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "」タグの有無に関係なく、他のドラッグ＆ドロップテンプレートまたはコードテンプレートと入れ替えることができます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["dnd_areaタグを含むコードテンプレートは、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "タグを含む他のコードテンプレートとのみ入れ替えることできます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "タグを含まないコードテンプレートは、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "タグを含まない他のコードテンプレートとのみ入れ替えることできます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_section %}"
        })
      }), "は、最上位の行で、{% dnd_area %}タグ内にネストする必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#section-templates",
        children: "セクションはテンプレートとして定義"
      }), "してから、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas/sections#section-context",
        children: "含める"
      }), "こともできるため、テンプレートの迅速な作成に最適です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "タグには、以下のパラメーターを指定することができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "背景色"
            }), "の指定をサポートするディクショナリー（辞書型）。文字列としても指定できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "背景画像"
            }), "の指定をサポートするディクショナリー。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "線形グラデーションの背景"
            }), "の指定をサポートするディクショナリー。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "セクションを全幅にするか、または内側のコンテナーの幅によって制限するかを指定するブール値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), "でのマージン値の指定に使用するディクショナリー。単位を指定しない場合、既定の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "が適用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー囲む要素でコンテンツの最大幅を設定するピクセル値"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), "でのパディング値の指定に使用するディクショナリー。単位を指定しない場合、既定の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "が適用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["子コンテンツの垂直方向の位置合わせ。使用可能なオプションには、以下のものがあります。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), "タグあたり1つのbackgroundパラメーターのみ使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "入力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_section\n  background_image={\n    \"backgroundPosition\": \"MIDDLE_CENTER\",\n    \"backgroundSize\": \"cover\",\n    \"imageUrl\": \"https://example.com/path/to/image.jpg\"\n  },\n  margin={\n    \"top\": 32,\n    \"bottom\": 32\n  },\n  padding={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\",\n    \"left\": \"1em\",\n    \"right\": \"1em\"\n  },\n  max_width=1200,\n  vertical_alignment=\"MIDDLE\"\n%}\n\n{% end_dnd_section %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 unique_name-row-0-background-image dnd-section unique_name-row-0-max-width-section-centering unique_name-row-0-margin unique_name-row-0-padding\"\n>\n  <div class=\"row-fluid \"></div>\n  <!--end row-->\n</div>\n<!--end row-wrapper -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "タグには、以下のタグも含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_column"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_column %}"
        })
      }), "は、垂直方向の構成要素であり、親の行で定義された1つ以上のレイアウト列を占めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このHubLタグは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "タグ内にネストする必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), "タグには、以下のパラメーターを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "背景色"
            }), "の指定をサポートするディクショナリー（辞書型）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "背景画像"
            }), "の指定をサポートするディクショナリー。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "線形グラデーションの背景"
            }), "の指定をサポートするディクショナリー。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), "でのマージン値の指定に使用するディクショナリー。単位を指定しない場合、既定の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "が適用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー囲む要素でコンテンツの最大幅を設定するピクセル値"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), "でのパディング値の指定に使用するディクショナリー。単位を指定しない場合、既定の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "が適用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["子コンテンツの垂直方向の位置合わせ。使用可能なオプションには、以下のものがあります。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "タグあたり1つのbackgroundパラメーターのみ使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "入力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_column\n  offset=0,\n  width=12,\n  background_color={\n    r: 255,\n    g: 0,\n    b: 0,\n    a: 1\n  },\n  margin={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\"\n  },\n%}\n\n{% end_dnd_column %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span12 widget-span widget-type-cell unique_name-column-1-margin unique_name-column-1-background-color unique_name-column-1-vertical-alignment dnd-column\"\n  style=\"\"\n  data-widget-type=\"cell\"\n  data-x=\"0\"\n  data-w=\"12\"\n></div>\n<!--end widget-span -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), "タグには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "を含めることもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_row"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/templates/drag-and-drop-areas#row",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_row %}"
        })
      }), "は横方向の構成要素であり、列とモジュールを配置できる、ネストされた12列レイアウトのグリッドを作成します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このHubLタグは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "タグ内にネストする必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "タグには、以下のパラメーターを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "背景色"
            }), "の指定をサポートするディクショナリー（辞書型）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "背景画像"
            }), "の指定をサポートするディクショナリー。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "線形グラデーションの背景"
            }), "の指定をサポートするディクショナリー。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), "でのマージン値の指定に使用するディクショナリー。単位を指定しない場合、既定の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "が適用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー囲む要素でコンテンツの最大幅を設定するピクセル値"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), "でのパディング値の指定に使用するディクショナリー。単位を指定しない場合、既定の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "が適用されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["子コンテンツの垂直方向の位置合わせ。使用可能なオプションには、以下のものがあります。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), "タグあたり1つのbackgroundパラメーターのみ使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "入力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_row\n  background_color={\n    r: 123,\n    g: 123,\n    b: 123,\n    a: 1.0\n  },\n  margin={\n    \"top\": 20,\n    \"bottom\": 200\n  },\n  padding={\n    \"top\": 20,\n    \"bottom\": 200,\n    \"left\": 20,\n    \"right\": 20\n  }\n%}\n\n{% end_dnd_row %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 main-row-0-padding main-row-0-background-color main-row-0-margin\"\n>\n  <div class=\"row-fluid \"></div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "dnd_rowには、以下のタグを含めることもできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#module",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_module %}"
        })
      }), "は、div内に囲まれた", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "であり、レイアウト、スタイル、コンテンツを追加できます。このモジュールを指定するには、そのパスを参照します。HubSpotの既定のモジュールを指定することも（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/"
      }), "名前空間を使用）、独自に作成したモジュールを、デザインマネージャーのファイルツリー内のパスで指定することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このHubLタグは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "タグ内にネストする必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "タグには、以下のパラメーターを指定することができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールへのパス"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["横方向の位置。次の値を使用できます。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LEFT"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CENTER"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RIGHT"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "12列グリッド上での位置を示す0からの値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "12列グリッドを占めるカラムの数。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "flexbox_positioning"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "非推奨"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["**推奨されません。使用しないでください。**代わりに、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            }), "を行やセクションの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            }), "を併せて使用します。", (0, _jsxRuntime.jsx)("br", {}), "モジュールのフレックスボックスの位置。垂直方向の位置、次に水平方向の位置を示す以下の文字列に対応しています。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["上記の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), "パラメーターのいずれかに一致するフィールド名がある古いモジュールを使用していますか？フィールドグループの場合と同様に、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates#passing-fields-that-have-dnd-associated-parameters",
          children: "fieldsパラメーターを使用して既定値を渡す"
        }), "ことができます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "入力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n  path=\"@hubspot/rich_text\",\n  offset=0,\n  width=8,\n%}\n  {% module_attribute \"html\" %}\n    <h1>Hello, world!</h1>\n  {% end_module_attribute %}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span8 widget-span widget-type-custom_widget\"\n  style=\"\"\n  data-widget-type=\"custom_widget\"\n  data-x=\"0\"\n  data-w=\"12\"\n>\n  <div\n    id=\"hs_cos_wrapper_main-module-1\"\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module\"\n    style=\"\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"module\"\n  >\n    <span\n      id=\"hs_cos_wrapper_module-1_\"\n      class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n      style=\"\"\n      data-hs-cos-general-type=\"widget\"\n      data-hs-cos-type=\"rich_text\"\n    >\n      <h1>Hello, world!</h1>\n    </span>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "背景"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["列、セクション、行のdnd要素に背景を設定するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-image",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_image"
        })
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-linear-gradient",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_linear_gradient"
        })
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-color",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_color"
        })
      }), "を使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_color"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["列、セクション、行のdndタグは、背景色をサポートしています。ドラッグ＆ドロップ要素の既定の背景色を設定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_color"
      }), "パラメーターを使用します。これは文字列のパラメーターであり、以下の例に示されている形式を含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section %}\n  // Hex Value (both 3 and 6 char length)\n  {% dnd_column background_color=\"#F7F7F7\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"#FFF\" %}\n  {% end_dnd_column %}\n// Both RGB and RGBA\n  {% dnd_column background_color=\"rgb(255,255,255)\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"rgba(0,0,0,.25)\" %}\n  {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_linear_gradient"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["列、セクション、行のdnd要素は、背景の線形グラデーションをサポートしています。既定のグラデーションを設定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_linear_gradient"
      }), "パラメーターを使用します。このパラメーターで想定される値はディクショナリーです。現時点でサポートされるのは色の開始点と終了点のみです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["グラデーションの方向を設定します。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to bottom"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to top"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to right"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["色を表す文字列の配列。現在、始点と終点を示す2つの値をサポートしています。値は、以下の形式の文字列として指定します。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGB"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGBA"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "8 char hex"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_linear_gradient={\n    \"direction\": \"to bottom\",\n    \"colors\": [\n      \"#1EB6C3\",\n      \"#2A2859\"\n    ]\n  }\n  %}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_image"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["列、セクション、および行のdnd要素は、背景画像をサポートしています。既定の背景画像を指定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_image"
      }), "パラメーターを使用します。このパラメーターで想定される値はディクショナリーです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "キー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundPosition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["画像の背景位置。最初に垂直方向、次に水平方向の位置を示す文字列をサポートしています。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundSize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["画像に使用されるCSS背景サイズのプロパティー。", (0, _jsxRuntime.jsx)("br", {}), "サポートされる値：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "cover"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "contain"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "imageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像への絶対URL。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_image = {\n      \"backgroundPosition\": \"MIDDLE_CENTER\",\n      \"backgroundSize\": \"cover\",\n      \"imageUrl\": \"https://www.example.com/bg-image.jpg\"\n    },\n%}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dndのスタイルパラメーターからページへの変換方法"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "#background",
        children: "背景"
      }), "、マージン、パディングなど、スタイルのパラメーターを使用した場合、クラス名はセクション、列、行、モジュールに基づいて自動的に割り出されます。割り当てたプロパティー値は、自動的に作成されたクラス名に追加され、その後生成されたCSSコードがページ内の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), "終了タグの前にある", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "タグに配置されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/responsive-breakpoints",
        children: "ブレークポイントごとに異なるドラッグ＆ドロップのスタイルを設定"
      }), "して、外観をレスポンシブにすることができます。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}