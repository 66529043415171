"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529627;
const slug = exports.slug = 'reference/cms/hubl/loops';
const title = exports.title = 'for-Schleifen';
const name = exports.name = 'EMEA DACH (de) For loops';
const metaDescription = exports.metaDescription = 'for-Schleifen können in HubL verwendet werden, damit Sequenzen von Objekten durchlaufen werden. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "schleifeneigenschaften",
  "label": "Schleifeneigenschaften",
  "parent": null
}, {
  "depth": 0,
  "id": "verschachtelte-schleifen",
  "label": "Verschachtelte Schleifen",
  "parent": null
}, {
  "depth": 0,
  "id": "cycle",
  "label": "cycle",
  "parent": null
}, {
  "depth": 0,
  "id": "variablen-innerhalb-von-schleifen",
  "label": "Variablen innerhalb von Schleifen",
  "parent": null
}, {
  "depth": 0,
  "id": "schl%C3%BCssel-werte-paare-in-schleifen",
  "label": "Schlüssel-Werte-Paare in Schleifen",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-bestimmte-anzahl-von-iterationen-durchf%C3%BChren",
  "label": "Eine bestimmte Anzahl von Iterationen durchführen",
  "parent": null
}, {
  "depth": 0,
  "id": "verwendung-von-hubl-tags-in-schleifen",
  "label": "Verwendung von HubL-Tags in Schleifen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "for-Schleifen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["for-Schleifen können in HubL verwendet werden, damit Sequenzen von Objekten durchlaufen werden. Sie werden am häufigsten bei der Darstellung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "Blog-Inhalten"
      }), " in einem Listenformat verwendet, können aber auch zum Sortieren anderer Sequenzvariablen verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["for-Schleifen beginnen mit einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), "-Anweisung und enden mit einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% endfor %}"
      }), "-Anweisung. Innerhalb der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), "-Anweisung wird ein einzelnes Sequenzelement genannt, gefolgt von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "in"
      }), " und dem Namen der Sequenz. Der Code zwischen den öffnenden und schließenden ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), "-Anweisungen wird bei jeder Iteration gedruckt und enthält im Allgemeinen die gedruckte Variable des einzelnen Sequenzelements. Im Folgenden finden Sie die grundlegende Syntax einer for-Schleife:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in items %}\n\t{{ item }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend ein einfaches Beispiel, das zeigt, wie eine Sequenz von Variablenwerten in eine Liste gedruckt wird."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set languages = [\"HTML\", \"CSS\", \"Javascript\", \"Python\", \"Ruby\", \"PHP\", \"Java\"] %}\n\n<h1>Languages</h1>;\n<ul>\n    {% for language in languages %}\n    <li>{{ language }}</li>\n    {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Languages</h1>\n<ul>\n  <li>HTML</li>\n  <li>CSS</li>\n  <li>Javascript</li>\n  <li>Python</li>\n  <li>Ruby</li>\n  <li>PHP</li>\n  <li>Java</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Schleifeneigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Durchlaufen einer Schleife können Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "bedingte Logik"
      }), " verwenden, um das Verhalten der Schleife zu definieren. Die variable Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), " speichert die aktuelle Anzahl der Iterationen der Schleife. Es gibt mehrere andere Eigenschaften von Schleifenvariablen, die die Iterationen auf unterschiedliche Weise zählen. Diese Eigenschaften werden im Folgenden beschrieben:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.cycle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Hilfsfunktion, um zwischen einer Liste von Sequenzen zu wechseln. Siehe die nachstehende Erklärung."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, wie tief in einer rekursiven Schleife das Rendering gerade ist. Beginnt bei Ebene 1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, wie tief in einer rekursiven Schleife das Rendering gerade ist. Beginnt bei Ebene 0"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.first"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Diese Variable wird als true ausgewertet, wenn es sich um die erste Iteration der Schleife handelt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die aktuelle Iteration der Schleife. Diese Variable beginnt mit der Zählung bei 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die aktuelle Iteration der Schleife. Diese Variable beginnt die Zählung bei 0."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.last"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Diese Variable wird als true ausgewertet, wenn es sich um die letzte Iteration der Schleife handelt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.length"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Anzahl der Elemente in der Sequenz"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Anzahl der Iterationen ab dem Ende der Schleife. Wird rückwärts gezählt bis 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Anzahl der Iterationen ab dem Ende der Schleife. Wird rückwärts gezählt bis 0."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden finden Sie einige Beispiele, in denen verschiedene Schleifenvariablen verwendet werden. Das folgende einfache Beispiel verwendet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), ", um eine Zählung durchzuführen, die bei jeder Iteration ausgegeben wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set loopy = [\"Content\", \"Social\", \"Contacts\", \"Reports\"] %}\n{% for app in loopy %}\n    {{ loop.index }}. {{app}} <br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1. Content <br />\n2. Social <br />\n3. Contacts <br />\n4. Reports <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das nächste Beispiel verwendet bedingte Logik, um zu prüfen, ob die Länge der Schleife durch bestimmte Zahlen teilbar (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "divisibleby"
      }), ") ist. Anschließend wird die Breite des Post-Item-Divs entsprechend angepasst. Das Beispiel verwendet die Standardschleife für Blog-Beiträge und geht davon aus, dass die Schleife 6 Beiträge enthält."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n{% if loop.length is divisibleby 4 %}\n<div style=\"width:25%\">Post content</div>\n\n{% elif loop.length is divisibleby 3 %}\n<div style=\"width:33.33332%\">Post content</div>\n\n{% else %}\n<div style=\"width:50%\">Post content</div>\n\n{% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verschachtelte Schleifen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Schleifen können auch mit Schleifen verschachtelt werden. Die untergeordnete for-Schleife wird bei jeder Iteration der übergeordneten for-Schleife ausgeführt. Im folgenden Beispiel wird eine Liste untergeordneter Elemente in einem verschachtelten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " von übergeordneten Elementen gedruckt."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set parents = [\"Parent item 1\", \"Parent item 2\", \"Parent item 3\"] %}\n{% set children = [\"Child item 1\", \"Child item 2\", \"Child item 3\"] %}\n<ul>\n{% for parent in parents %}\n<li>{{parent}}<ul>\n    {% for child in children %}\n    <li>{{child}}</li>\n    {% endfor %}\n    </ul>\n</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Parent item 1\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 2\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 3\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cycle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das cycle-Tag kann innerhalb einer for-Schleife verwendet werden, um eine Reihe von Zeichenkettenwerten zu durchlaufen und sie bei jeder Iteration zu drucken. Eine der praktischsten Anwendungen dieses Verfahrens ist die Anwendung alternierender Klassen auf Ihre Blog-Beiträge in einem Listing. Dieses Tag kann für mehr als zwei Werte verwendet werden und wiederholt den Zyklus, wenn es mehr Schleifenwiederholungen als Zykluswerte gibt. Im folgenden Beispiel wird eine Klasse von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "odd"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "even"
      }), " auf Beiträge in einem Listing angewendet (das Beispiel geht davon aus, dass es 5 Beiträge in der Schleife gibt)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bitte beachten Sie, dass ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "keine Leerzeichen"
      }), " zwischen den durch Kommata getrennten Werten der Zykluszeichenfolge stehen."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    <div class=\"post-item {% cycle \"odd\",\"even\" %}\">Blog post content</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variablen innerhalb von Schleifen"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Alle innerhalb von Schleifen definierten Variablen sind auf den Bereich dieser Schleife beschränkt und können nicht von außerhalb der Schleife aufgerufen werden."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Variablen, die außerhalb einer Schleife definiert sind, aus einer Schleife heraus aufrufen, aber nicht umgekehrt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Schlüssel-Werte-Paare in Schleifen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn das Dictionary von Informationen, das Sie in einer Schleife durchlaufen, über Schlüssel- und Wertepaare verfügt, hätte eine einfache for-Schleife nur Zugriff auf die Werte. Wenn Sie innerhalb der for-Schleife sowohl auf die Schlüssel als auch auf die Werte zugreifen möchten, würde die HubL so formatiert werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set dict_var = {\"name\": \"Cool Product\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{% for key, val in dict_var.items() %}\n    {{ key }}: {{ val }}<br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "name: Cool Product <br />\nprice: $20 <br />\nsize: XL <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine bestimmte Anzahl von Iterationen durchführen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Manchmal möchte man eine bestimmte Anzahl von Iterationen durchführen, was beim Generieren von HTML oder CSS nützlich sein kann. Dies können Sie mithilfe der range-Funktion tun."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for x in range(0,5) %}\n\t{{loop.index}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1 2 3 4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung von HubL-Tags in Schleifen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie der Seite ein Tag hinzufügen, weist HubSpot dem umschließenden HTML-Code automatisch eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " zu. Dieses Tag ist eindeutig pro name-Tag. In Situationen, in denen Sie ein Tag in einer for-Schleife verwenden müssen, ist die Festlegung eindeutiger Namen nicht sinnvoll. Fügen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unique_in_loop"
      }), "-Parameter zu Ihrem Tag hinzu, um eindeutige IDs zu generieren. Dieser Parameter fügt den Modulnamen an die Nummer der aktuellen SchleifeniIeration an, um sicherzustellen, dass er eindeutig ist. Eindeutige IDs werden nicht nur für gültigen HTML benötigt, sondern sind auch für die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "Barrierefreiheit"
      }), " wichtig."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in module.icon_field %}\n\t{% icon\n\t\tname=\"{{ item.name }}\",\n\t\tstyle=\"{{ item.type }}\",\n\t\tunicode=\"{{ item.unicode }}\",\n        unique_in_loop=True\n\t%}\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}