"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 85488833916;
const slug = exports.slug = 'guides/api/crm/exports';
const title = exports.title = 'API do CRM | Exportações';
const name = exports.name = 'API do CRM | Exportações';
const metaDescription = exports.metaDescription = 'Saiba como usar a API de exportações para exportar registros em exibições ou listas de sua conta da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "comece-uma-exporta%C3%A7%C3%A3o",
  "label": "Comece uma exportação",
  "parent": null
}, {
  "depth": 1,
  "id": "exportar-uma-exibi%C3%A7%C3%A3o",
  "label": "Exportar uma exibição",
  "parent": "comece-uma-exporta%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "exportar-uma-lista",
  "label": "Exportar uma lista",
  "parent": "comece-uma-exporta%C3%A7%C3%A3o"
}, {
  "depth": 0,
  "id": "recuperar-exporta%C3%A7%C3%B5es",
  "label": "Recuperar exportações",
  "parent": null
}, {
  "depth": 0,
  "id": "limites",
  "label": "Limites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exportações"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a API de exportações para exportar registros e dados de propriedades da sua conta da HubSpot, recuperar um URL para baixar um arquivo de exportação ou ver o status de uma exportação. No HubSpot, você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/export-contacts-companies-deals-or-tickets",
        children: "exportar registros"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-log-of-your-users-exports-in-your-account",
        children: "ver um registro das exportações anteriores em sua conta."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comece uma exportação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para iniciar uma exportação, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async"
      }), ". O corpo da solicitação deve especificar informações como o formato do arquivo, o objeto e as propriedades que você deseja exportar e o tipo de exportação que está sendo concluído (por exemplo, exportar uma exibição de objeto ou uma lista). Você também pode filtrar os dados da propriedade a serem exportados com base em operadores específicos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para as exportações de exibição e de lista, você pode incluir os seguintes campos em sua solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de exportação, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VIEW"
            }), " (exporta uma exibição de uma página de índice de objeto) ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LIST"
            }), " (exporta uma lista)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O formato do arquivo. As opções incluem: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLSX"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLS"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da exportação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O idioma do arquivo de exportação. As opções incluem: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EN"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ES"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FI"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "JA"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PT"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SV"
            }), ". Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-language-offerings",
              children: "idiomas compatíveis."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome ou o ID do objeto que você está exportando. Para objetos padrão, você pode usar o nome do objeto (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            }), "), mas para objetos personalizados, você deve usar o valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), ". Você pode recuperar esse valor fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associatedObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome ou o ID de um objeto associado para incluir na exportação. Se você incluir um objeto associado, a exportação conterá os IDs de registro associados desse objeto e o valor da propriedade de exibição principal dos registros (por exemplo, nome). Você pode exportar apenas um objeto associado por solicitação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista das propriedades que você deseja incluir na exportação."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exportar uma exibição"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver exportando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "exibição de página de índice"
      }), ", seu valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " deve ser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "VIEW"
      }), ", e você pode incluir o seguinte campo para filtrar e classificar os registros que está exportando:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publicCrmSearchRequest"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica quais dados devem ser exportados com base em determinados valores de propriedade e consultas de pesquisa. Você pode incluir o seguinte no objeto:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "filters"
            }), ": as propriedades e os valores de propriedade pelos quais filtrar os registros.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "sorts"
            }), ": a ordem de classificação dos valores de uma propriedade, seja crescente, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASC"
            }), ", ou descendente, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DES"
            }), ".", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "query"
            }), ": uma string para pesquisar os valores dos registros."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, para exportar uma exibição de contatos e registros de empresa associados, filtrados pela propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", sua solicitação poderia ser semelhante à seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"VIEW\",\n  \"exportName\": \"All contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"DE\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email, firstname, lastname\"],\n  \"associatedObjectType\": \"COMPANY\",\n  \"publicCrmSearchRequest\": {\n    \"filters\": [\n      {\n        \"value\": \"hello@test.com\",\n        \"propertyName\": \"email\",\n        \"operator\": \"EQ\"\n      }\n    ],\n    \"query\": \"hello\",\n    \"sorts\": [\n      {\n        \"propertyName\": \"email\",\n        \"order\": \"ASC\"\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exportar uma lista"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver exportando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists",
        children: "lista"
      }), ", seu valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), " deve ser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LIST"
      }), ", mas você também precisa especificar a lista que está exportando com o seguinte campo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/lists/lists-faq#ils-list",
              children: "ID de lista ILS"
            }), " da lista para exportar. Você pode encontrar o valor de ID da lista ILS através dos detalhes da lista em HubSpot. Navegue para ** Contatos** > ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Listas"
            }), ", passe o mouse sobre a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "lista"
            }), " na tabela, depois clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Detalhes"
            }), ". No painel direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Copiar"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "ID da lista"
            }), " ao lado do valor de ID da lista ILS. As listas de contatos têm dois valores de IDs diferentes, mas você ", (0, _jsxRuntime.jsx)("u", {
              children: "deve"
            }), " use o valor de ID da lista ILS em sua solicitação."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, para exportar uma lista com os e-mails dos contatos, sua solicitação pode ter a seguinte aparência:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"LIST\",\n  \"listId\": 1234567,\n  \"exportName\": \"Marketing email contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"EN\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar exportações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando você concluir uma exportação com êxito, o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " da exportação será retornado na resposta. Para recuperar uma exportação da sua conta da HubSpot, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async/tasks/{exportId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao recuperar as exportações, o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), " da exportação também será retornado. Os status possíveis incluem: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PENDING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSING"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CANCELED"
      }), ". Para as exportações com status ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), ", é retornado um URL que pode ser usado para fazer download do arquivo exportado. O URL de download expirará cinco minutos após a solicitação ser concluída. Depois de expirado, você pode executar outra solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para gerar um novo URL exclusivo."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": antes da expiração, o URL de download de uma exportação pode ser acessado sem qualquer autorização adicional. Para proteger seus dados, prossiga com cuidado ao compartilhar um URL ou ao integrar com o HubSpot por meio desta API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os limites a seguir se aplicam:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao definir filtros para sua exportação, você pode incluir no máximo três ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " com até três ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " em cada grupo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode realizar até trinta exportações em um período de 24 horas e uma exportação por vez. As exportações adicionais serão enfileiradas até que a exportação anterior seja concluída."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um arquivo CSV exportado será compactado automaticamente se o arquivo resultante for maior que 2 MB."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}