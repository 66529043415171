"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61045964892;
const slug = exports.slug = 'guides/api/crm/engagements/meetings';
const title = exports.title = 'Interacciones | Reuniones';
const name = exports.name = 'Interacciones | Reuniones';
const metaDescription = exports.metaDescription = 'Usa la API de interacción de reuniones para registrar y gestionar reuniones en registros del CRM. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-reuni%C3%B3n",
  "label": "Crear una reunión",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": "crear-una-reuni%C3%B3n"
}, {
  "depth": 1,
  "id": "asociaciones",
  "label": "Asociaciones",
  "parent": "crear-una-reuni%C3%B3n"
}, {
  "depth": 0,
  "id": "recuperar-reuniones",
  "label": "Recuperar reuniones",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-reuniones",
  "label": "Actualizar reuniones",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-reuniones-existentes-con-registros",
  "label": "Asociar reuniones existentes con registros",
  "parent": "actualizar-reuniones"
}, {
  "depth": 1,
  "id": "eliminar-una-asociaci%C3%B3n",
  "label": "Eliminar una asociación",
  "parent": "actualizar-reuniones"
}, {
  "depth": 0,
  "id": "anclar-una-reuni%C3%B3n-a-un-registro",
  "label": "Anclar una reunión a un registro",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-reuniones",
  "label": "Eliminar reuniones",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Reuniones"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa la API de interacción de reuniones para registrar y gestionar reuniones en registros del CRM. Puedes registrar las actividades de las reuniones ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "en HubSpot"
      }), " o a través de la API de reuniones. Puedes recuperar, actualizar o eliminar las interacciones de reuniones que se registran manualmente en un registro, se programan con la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/meetings-tool/use-meetings",
        children: "herramienta de reuniones"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/schedule-a-meeting-with-a-contact-in-a-record",
        children: "se programan mediante la integración de calendario de Google Calendar u Office 365"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, aprende los métodos básicos de gestión de reuniones a través de la API. Para ver todos los puntos de terminación disponibles y sus requisitos, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una reunión"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una interacción de reunión, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de la solicitud, agrega detalles de la reunión en el objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propiedades"
      }), ". También puedes agregar un objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "asociaciones"
      }), " para asociar tu nueva reunión con un registro existente (por ejemplo, contactos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto propiedades, puedes incluir los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Requerido. Este campo marca la fecha y hora en que ocurrió la reunión. Puedes usar una marca de tiempo Unix en milisegundos o en formato UTC. (VERSIÓN BETA) Cuando falta el valor de propiedad, el valor predeterminado será a", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El título de la reunión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID del propietario"
            }), " asociado con la reunión. Este campo determina el usuario que aparece como creador de la reunión en la cronología del registro."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La descripción de la reunión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_internal_meeting_notes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Las notas internas que realizas para tu equipo durante una reunión y que no están incluidas en la descripción de la reunión de asistentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_external_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL externa del evento del calendario. Por ejemplo, podría ser un enlace de Calendario de Google o un enlace de calendario de Microsoft Outlook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dónde se lleva a cabo la reunión. El valor podría ser una dirección física, una sala de conferencias, un enlace de videoconferencia o un número de teléfono. Esto aparece en la invitación de calendario del asistente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fecha y hora en que comienza la reunión. El valor de esta propiedad debe coincidir con el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_end_time"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fecha y hora en que termina la reunión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_outcome"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El resultado de la reunión. Los valores de resultado son programado, completado, reprogramado, sin asistencia y cancelado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de reunión. Las opciones se basan en los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "tipos de reuniones establecidos en tu cuenta de HubSpot."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los ID de los archivos adjuntos de la reunión. Los ID de varios archivos adjuntos están separados por un punto y coma."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear y asociar una reunión con registros existentes, incluye un objeto asociaciones en tu solicitud. Por ejemplo, para crear y asociar una reunión con contactos, tu solicitud puede ser similar a la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-23T01:02:44.872Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_meeting_title\": \"Intro meeting\",\n    \"hs_meeting_body\": \"The first meeting to discuss options\",\n    \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n    \"hs_meeting_external_url\": \"https://Zoom.com/0000\",\n    \"hs_meeting_location\": \"Remote\",\n    \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n    \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n    \"hs_meeting_outcome\": \"SCHEDULED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 102\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El objeto asociaciones debe incluir:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El registro que deseas asociar con la reunión, especificado por su valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " único."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación entre la reunión y el registro. Incluye la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " y el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Los ID de los tipos de asociación predeterminados se enumeran ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aquí"
            }), ", o puedes recuperar el valor de los tipos de asociación personalizados (es decir, etiquetas) a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre cómo crear reuniones por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar reuniones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes recuperar reuniones individualmente o en masa. Obtén más información sobre la recuperación por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar una reunión individual por su ID de reunión, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), ". También puedes incluir los siguientes parámetros en la URL de la solicitud:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de los objetos de los que deseas recuperar los ID de registro asociados."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar una lista de todas las reuniones, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/meetings"
      }), ". Puedes incluir los siguientes parámetros en la URL de la solicitud:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número máximo de resultados que se mostrarán por página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar reuniones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes actualizar las reuniones individualmente o por lotes. Para actualizar una reunión individual por su ID de reunión, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la solicitud, incluye las propiedades de reunión que deseas actualizar. Por ejemplo, el cuerpo de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/meetings/{meetingId}\n{\n  \"properties\": {\n     \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n     \"hubspot_owner_id\": \"11349275740\",\n     \"hs_meeting_title\": \"Intro meeting\",\n     \"hs_meeting_body\": \"The first meeting to discuss options\",\n     \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n     \"hs_meeting_external_url\":\n     \"https://Zoom.com/0000\",\n     \"hs_meeting_location\": \"Remote\",\n     \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n     \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n     \"hs_meeting_outcome\": \"SCHEDULED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignorará los valores de las propiedades de solo lectura e inexistentes. Para borrar un valor de propiedad, pasa una cadena vacía para la propiedad en el cuerpo de la solicitud."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la actualización por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar reuniones existentes con registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar una reunión con registros, como un contacto y sus empresas asociadas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". La URL de la solicitud contiene los siguientes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meetingId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la reunión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tipo de objeto con el que deseas asociar la reunión (por ejemplo, contacto o empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del registro con el que deseas asociar la reunión."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del tipo de asociación entre la reunión y el otro tipo de objeto. Puedes recuperar este valor a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, la URL de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/meetings/17612479134/associations/contact/104901/200"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una asociación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una asociación entre una reunión y un registro, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " en la misma URL que la anterior:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anclar una reunión a un registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "anclar una reunión"
      }), " a un registro para que permanezca en la parte superior de la cronología del registro. La reunión ya debe estar asociada con el registro antes de anclar, y solo puedes anclar una actividad por registro. Para anclar una reunión, incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la reunión en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " al crear o actualizar un registro a través de las API de objetos. Obtén más información sobre el uso de las API de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: ",contactos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negocios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar reuniones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes eliminar reuniones individualmente o por lotes, lo que agregará la reunión a la papelera de reciclaje en HubSpot. Más tarde podrás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurar la reunión desde la cronología del registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una reunión individual por su ID de reunión, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la eliminación por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}