"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358695;
const slug = exports.slug = 'guides/api/crm/understanding-the-crm';
const title = exports.title = 'CRM API | CRMについて';
const name = exports.name = 'vNext Docs DP - CRMについて';
const metaDescription = exports.metaDescription = 'HubSpotのCRMは、顧客の関係やデータを管理するためのシステムです。CRMのオブジェクトタイプ、プロパティー、関連付け、担当者、およびエンゲージメントについてご紹介します。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
  "label": "オブジェクト",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E9%96%93%E3%81%AE%E9%96%A2%E4%BF%82",
  "label": "オブジェクト間の関係",
  "parent": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%82%AF%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%83%BC%E3%81%A8%E6%B7%BB%E4%BB%98%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB",
  "label": "アクティビティーと添付ファイル",
  "parent": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%83%87%E3%83%BC%E3%82%BF%E5%90%8C%E6%9C%9F",
  "label": "データ同期",
  "parent": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97id",
  "label": "オブジェクトタイプID",
  "parent": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88"
}, {
  "depth": 0,
  "id": "%E4%B8%80%E6%8B%AC%E3%82%A2%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "一括アクション",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "プロパティー",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "既定のプロパティー",
  "parent": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "カスタムプロパティー",
  "parent": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E6%89%80%E6%9C%89%E8%80%85",
  "label": "レコード所有者",
  "parent": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC"
}, {
  "depth": 0,
  "id": "%E5%9B%BA%E6%9C%89id%E3%81%A8%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89id",
  "label": "固有IDとレコードID",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E5%9B%BA%E6%9C%89id",
  "label": "既定の固有ID",
  "parent": "%E5%9B%BA%E6%9C%89id%E3%81%A8%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89id"
}, {
  "depth": 1,
  "id": "%E7%8B%AC%E8%87%AA%E3%81%AE%E5%9B%BA%E6%9C%89id%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "独自の固有IDの作成",
  "parent": "%E5%9B%BA%E6%9C%89id%E3%81%A8%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89id"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CRMについて"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウントが基盤とするのは、ビジネス上の関係と プロセスを格納するCRM（顧客関係管理）と呼ばれるデータベースです。このデータを管理するために、\nHubSpotアカウントには関係やプロセスのタイプを表すオブジェクトが 含まれています。レコードと呼ばれるオブジェクトの個々のインスタンスは、\n各オブジェクトタイプに属する個々のエンティティーを表します（例えば、John Smithはコンタクトです）。各レコードにデータを保存するには、\nプロパティー（Eメールプロパティーなど）を使用します。 また、個々のエンティティー間の関係を表すために、レコードを互いに関連付けることができます\n（例えば、John SmithをSmith & Co.社に関連付けることができます）。さらにCRMレコードには、\nEメール、コール、ミーティングなどの関連付けられたエンゲージメント／アクティビティーによるインタラクションに関する\n情報を格納することもできます。以下で、CRMオブジェクト、レコード、プロパティーなどに関する\n詳細をご確認ください。HubSpot内からCRMデータベースを管理する方法の詳細については、\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/get-started/manage-your-crm-database",
        children: "HubSpotのナレッジベース"
      }), "をご覧ください"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "オブジェクト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM APIを使用すると、オブジェクト、レコード、アクティビティーにアクセスできます。以下のリストで、HubSpotで使用できるオブジェクトについて説明します。次のオブジェクトのそれぞれにHubSpot内に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "インデックスページ"
      }), "があり、全て相互に関連付けることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "コンタクト"
        }), "：個人に関する情報が格納されます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/contacts",
          children: "コンタクトのAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "会社"
        }), "：個々の企業や組織についての情報が格納されます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/companies",
          children: "会社のAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "取引"
        }), "：コンタクトまたは会社との関係における収益機会を表します。成約または失注になるまでの間、取引はパイプラインステージを通じて追跡されます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/deals",
          children: "取引のAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "チケット"
        }), "：顧客からのサポート依頼を表します。チケットはクローズされるまでの間、パイプラインステータスを通じて追跡されます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/tickets",
          children: "チケットのAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
          children: "コール"
        }), "：コンタクトとの通話に関する情報を格納しますが、エンゲージメントとして他のオブジェクトに関連付けることもできます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/calls",
          children: "コールのAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-quotes",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "見積もり"
          })
        }), "：潜在顧客と共有される価格情報を表します。見積もりは、コンタクト、会社、取引、および商品項目に関連付けることができます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/commerce/quotes",
          children: "見積もりのAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "カスタムオブジェクト"
        }), "（", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " のみ）：カスタムオブジェクトを作成すると、どのようなタイプのデータ（特に上記の標準オブジェクトに一致しないデータ）でも、HubSpotに格納できます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "カスタムオブジェクトのAPIエンドポイントを表示"
        }), "以下のオブジェクトにはHubSpot内にインデックスページがありませんが、他の特定のオブジェクトに関連付けることができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
          children: "アクティビティー／エンゲージメント"
        }), "（コール、Eメール、ミーティング、メモ、タスク、SMS、LinkedIn、WhatsApp、郵便）：レコードに関連付けられているインタラクションを表します。アクティビティーは、コンタクト、会社、取引、チケット、カスタムオブジェクトに関連付けることができます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/engagement-details",
          children: "エンゲージメントのAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
          children: "製品"
        }), "：販売する商品やサービスを表します。製品を他のCRMオブジェクトに関連付けることはできませんが、製品に基づいて商品項目を作成し、それらを取引や見積もりに関連付けることができます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/products",
          children: "製品のAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
          children: "商品項目"
        }), "：取引において販売する個々の製品とサービスを表します。商品項目は、製品ライブラリーにある既存の製品から作成したり、スタンドアロン商品項目として作成したりすることができます。スタンドアロン商品項目は製品ライブラリーには追加されません。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/line-items",
          children: "商品項目のAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "フィードバック送信"
          })
        }), "：フィードバックアンケートに送信された情報を保存します。フィードバック送信はコンタクトレコードに関連付けられます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/feedback-submissions",
          children: "フィードバック送信のAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events",
          children: "マーケティングイベント"
        }), "：マーケティング活動に関連するイベント、具体的には、接続された連携機能を使ったイベントなどを表します。コンタクトがマーケティングイベントに参加したか、登録したか、参加をキャンセルしたかを指定できます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/marketing/marketing-events",
          children: "マーケティングイベントのAPIエンドポイントを表示"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "請求書"
        }), "：売上に対して送信する請求書を表します。請求書には、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "コンタクト"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "会社"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "取引"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "商品項目"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "割引"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: "手数料"
          })
        }), "、および**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: "税金"
        }), "**を関連付けることができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "支払い"
        }), "：請求書、支払いリンク、見積もりを通じて購入者が行う支払いです。支払いには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "コンタクト"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "会社"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "取引"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "請求書"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "見積もり"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "商品項目"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "配信登録"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "割引"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: "手数料"
          })
        }), "、および**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: "税金"
        }), "を関連付けることができます。**"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "配信登録"
        }), "：支払いリンクと見積もりを通じて予定されている定期的な支払いです。配信登録には、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "コンタクト"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "会社"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "取引"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "見積もり"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "商品項目"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "支払い"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "割引"
        }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: "手数料"
          })
        }), "、および**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: "税金"
        }), "**を関連付けることができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-details",
          children: "ユーザー"
        }), "：HubSpotアカウントのユーザーを表します。ユーザーを他のCRMオブジェクトに関連付けることはできませんが、ユーザーAPIを介して取得および更新することはできます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "ユーザープロビジョニングAPI"
        }), "を使用して、ユーザーをアカウントに追加することもできます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "オブジェクト間の関係"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは、オブジェクトがどのように相互に関連しているかを示すために、レコードを関連付けることができます。例えば、複数のコンタクトを会社に関連付けて、会社および関連するコンタクトを取引に関連付けることができます。全てのHubSpotアカウントには、コンタクト、会社、取引、チケット、アクティビティーがあり、以下のモデルに示されているように、これらを相互に関連付けることができます。HubSpotアカウントへのアクセス権があれば、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-model-of-your-crm-object-and-activity-relationships",
        children: "データ モデル ツール"
      }), "に移動してアカウント固有のオブジェクト関係を確認できます。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/data-model-overview-updated.gif",
        alt: "data-model-overview-updated"
      }), "配信登録に応じて、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "関連付けラベル"
      }), "を使用してレコード間の特定の関係を記述できます。場合によってはアカウントに、他の標準オブジェクトに関連付けることができる追加のカスタムオブジェクトがあります。製品や商品項目などの他のオブジェクトは、特定のオブジェクトにのみ関連付けることができます。これらの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-objects",
        children: "追加オブジェクトとその関連付け"
      }), "について詳細をご確認ください。また、オブジェクト間の関係と、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPIエンドポイント"
      }), "による関連付けの管理についても詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アクティビティーと添付ファイル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アクティビティーとも呼ばれる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "エンゲージメント"
      }), "は、レコードとのインタラクションからのデータを格納します。例えば、プロスペクトに電話する場合は、コンタクトレコードに通話内容を記録し、関連付けられた会社レコードにそれを関連付けることもできます。有効なアクティビティーとしては、メモ、タスク、ミーティング、Eメール、コール、郵便、SMS、LinkedInメッセージ、WhatsAppメッセージなどがあります。関連ファイルをトラッキングするために、レコードの添付ファイルを格納することもできます。これらは通常、エンゲージメントに関連します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "エンゲージメントAPI"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details"
      }), "について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "データ同期"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクトデータを同期するために、エンゲージメントデータを同期する必要はありません。オブジェクトは複数のエンゲージメントに関連付けることができるため、同期を実行する前に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "API制限"
      }), "を念頭に置いておくことも重要です。ただし、完全移行に先立って連携を行う場合は、プロパティーではなくエンゲージメントの同期が必要になる場合もあります。この場合、2つのシステム間でエンゲージメントを同期すると、全てのユーザーが、必要なデータを移行期間中にも利用できるようになります。例えば、HubSpotを採用している事業開発部門が、別のCRMを使用しているインサイドセールス担当と共同で取引を扱う場合、両方の部門が成約に必要な情報を共有できるようにエンゲージメントを同期する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "オブジェクトタイプID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のAPIを使用する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), "フィールドを使用する必要があります。各オブジェクトまたはアクティビティーのID値は次のとおりです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "コンタクト："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "会社："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-2"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "取引："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-3"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "チケット："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-5"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**カスタムオブジェクト：**カスタムオブジェクトのID値を確認するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/schemas"
        }), "に送信します。値は", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2-3453932"
        }), "のような形式になります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "コール："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-48"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eメール："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-49"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ミーティング："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-47"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "メモ："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-46"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "タスク："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-27"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "製品："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-7"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "請求書："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-52"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "商品項目："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-8"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "支払い："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-101"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "見積もり："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-14"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "配信登録："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-69"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "コミュニケーション機能"
        }), "（SMS、LinkedInメッセージ、WhatsAppメッセージ）：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-18"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "郵便："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-116"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "マーケティングイベント："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-54"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "フィードバック送信："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-19"
        }), "数値のID値はいつでも使用できますが、コンタクト、会社、取引、チケット、メモではオブジェクトの名前を使用できる場合もあります。以下に例を示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/imports",
          children: "インポートAPI"
        }), "でインポートを開始する際は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), "で、ファイル内のデータがどのオブジェクトに属するかを指定します。コンタクトのデータをインポートするには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), "の値として", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        }), "を指定できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "関連付けAPI"
        }), "を使用する際は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), "の値でオブジェクトと、関連付けの方向を指定します。コンタクトと会社との関連付けタイプを確認するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストURLとして、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/contact/company/labels"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/0-1/0-2/labels"
        }), "を指定できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "一括アクション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["各オブジェクトには、1つのリクエストで複数のオブジェクトレコードの作成、読み取り、更新、およびアーカイブを実行するための一括APIエンドポイントが用意されています。一括APIエンドポイントには、1回の呼び出しにつきレコード100個までという上限があります。例えば次のリクエストでは、IDで識別された2つのコンタクトレコードが一括で更新されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"inputs\": [\n    {\n      \"id\": \"609051\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    },\n    {\n      \"id\": \"569751\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一括作成アクションでは、どのレコードが正常に作成され、どのレコードが正常に作成されなかったかを示すマルチステータスエラーを有効にすることができます。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/other-resources/error-handling#multi-status-errors",
        children: "マルチステータスエラー処理の設定"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコードに関する情報は、プロパティーと呼ばれるフィールドに格納された後、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "グループ"
      }), "に分類されます。HubSpotには、各オブジェクトに既定のプロパティーのセットがあります。各オブジェクトの既定のプロパティーに加えて、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "カスタムプロパティーを作成する"
      }), "ことによってカスタムデータを保存できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既定のプロパティー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRMオブジェクトは、主な「タイプ」**と一連の「プロパティー」**によって定義されます。タイプごとに標準のプロパティーがあり、名前と値のペアでタイプが表されます。オブジェクトごとの既定のプロパティーをご覧ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "コンタクト"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "会社"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "取引"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "チケット"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: "フィードバック送信"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties",
          children: "請求書"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "支払い"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "配信登録"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムプロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクトに特殊な情報を格納するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "カスタムプロパティー"
      }), "を作成します。カスタムプロパティーの管理は、CRMオブジェクトプロパティーのAPIエンドポイントを使用して行うことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "レコード所有者"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotユーザーをレコードの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "所有者"
      }), "として割り当てることができます。オブジェクトへのアクセス権を付与されたHubSpotユーザーを所有者を割り当てることができます。また、専用の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/how-can-i-create-a-custom-owner-property",
        children: "カスタムプロパティー"
      }), "を作成することで、1つのオブジェクトに複数の所有者を割り当てることもできます。所有者の作成はHubSpot上でのみ行えますが、IDやメールアドレスなどの詳細情報の取得には", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "所有者APIエンドポイント"
      }), "を使用できます。このデータは、HubSpot上での操作やプロパティー変更API呼び出しにより、CRMレコードに割り当てることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "固有IDとレコードID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["固有IDは、データベース内のあるレコードを、別のレコードと（仮にID以外の情報が同一であっても）区別するための値です。例えば、銀行のデータベースの中には、名前が同じ2人のJohn Smithさんのレコードが存在する場合も考えられます。誤ったJohn Smithさんに送金されることがないように、各レコードには固有のIDとして番号が割り当てられます。HubSpotでは、この固有IDを使用して、データを正しいレコードに送信し、重複の削除を管理することができます。HubSpotで重複の削除がどのように処理されるかについて詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/deduplication-of-contacts-companies-deals-tickets",
        children: "ナレッジベース記事"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既定の固有ID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは、レコードが作成されると、固有IDが自動生成され、文字列として扱われます。このようなIDは、特定のオブジェクトタイプ内では一意になりますが、コンタクトと会社のIDが同じになる場合はあります。コンタクトと会社の場合、コンタクトのEメール（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "）や会社のドメイン名（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), "）など、他にも固有のIDがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "独自の固有IDの作成"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotによって生成されたレコードID（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "）は、主に高度な連携を実現する目的で利用できます。ただし、データに一意の値を持つ他のプロパティーが必要な場合や、レコードIDが使用できない場合や、アプリの統合ロジックが複雑になる場合があります。このような場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "プロパティーAPIを使用してカスタムの固有IDプロパティーを作成"
      }), "できます。カスタムの固有IDプロパティーを作成したら、API呼び出しで、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "、コンタクトの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "、会社の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), "を使用する場合と同じ方法でそのカスタムのIDプロパティーを使用して特定のレコードを識別し、更新できます。例えば、カスタムの固有IDプロパティーの値に基づいて取引を取得する場合のリクエストURLは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), "のようになります。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}