"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 120374846289;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/custom-email-template';
const title = exports.title = 'Specify drag and drop areas in a custom email template (BETA)';
const name = exports.name = 'Specify drag and drop areas in a custom email template (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to specify drag and drop areas in a custom email template (BETA).';
const position = exports.position = 2;
const badge = exports.badge = 'BETA';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-create-a-new-html-template",
  "label": "1. Create a new HTML template",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-add-hubl-tags-to-template-head",
  "label": "2. Add HubL tags to template head",
  "parent": null
}, {
  "depth": 1,
  "id": "required-tag",
  "label": "Required tag",
  "parent": "2.-add-hubl-tags-to-template-head"
}, {
  "depth": 1,
  "id": "optional-tags",
  "label": "Optional tags",
  "parent": "2.-add-hubl-tags-to-template-head"
}, {
  "depth": 0,
  "id": "3.-create-a-drag-and-drop-area",
  "label": "3. Create a drag and drop area",
  "parent": null
}, {
  "depth": 1,
  "id": "create-an-empty-drag-and-drop-area",
  "label": "Create an empty drag and drop area",
  "parent": "3.-create-a-drag-and-drop-area"
}, {
  "depth": 1,
  "id": "create-a-drag-and-drop-area-with-empty-columns",
  "label": "Create a drag and drop area with empty columns",
  "parent": "3.-create-a-drag-and-drop-area"
}, {
  "depth": 0,
  "id": "4.-add-a-drag-and-drop-area-with-modules",
  "label": "4. Add a drag and drop area with modules",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-further-customize-and-style-your-drag-and-drop-template",
  "label": "5. Further customize and style your drag and drop template",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Specify a drag and drop area in a custom email template (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This functionality is in beta and requires your account to be ungated. By participating in this beta you agree to HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developer-terms",
          children: "Developer Terms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "Developer Beta Terms"
        }), ". Please note that this functionality is currently under development and is subject to change based on testing and feedback."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'null']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag and drop areas"
      }), " allow developers to create sections of custom email templates that support layout, stylistic and content changes directly within the email editor. This allows developers to create fewer email templates with global structure, that support content creators making a multitude of pages with various purposes and layouts."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " custom email templates can only contain one drag and drop area."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Create a new HTML template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Create a new html template that will include the HubL and HTML code which will make up your drag and drop section:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, create a new file by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "File"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New file"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "What would you like to build today?"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTML + HubL"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Template type"
        }), " dropdown menu then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Email"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for the template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update the template's location, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Change"
        }), " under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "File location"
        }), ", then select a new folder where the template will be created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Add HubL tags to template head"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Review the following required and optional tags you can include in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " section of your email template for styling and compatibility purposes:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Required tag"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ dnd_area_stylesheet }}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This tag will provide the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adds associated media queries"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fixes known styling issues in Outlook"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Resets margins and paddings"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Enables anti-aliasing"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adds some CSS"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Optional tags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To ensure that your template renders consistently across all major email clients, you can also include the following optional HubL tags to your template:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ email_header_includes }}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This tag will inject CSS into your template to help render styling consistently, fix common styling issues, and add metadata to the email's HTML. When parsing this HubL tag, the following content will be added within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " of the email's HTML:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prevent iOS 11 from automatically scaling emails"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Informs browsers and email clients how you expect them to interpret different characters."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This tag enables CSS3 and media queries on Windows Phone 7.5, informs Internet Explorer to render content in the highest mode possible, and allows the browser to choose which version of Internet Explorer that the email should be rendered with."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sets the viewable area to the width of the device screen, which helps make your email responsive."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ reset_css_stylesheet }}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Including this tag will fix a number of styling issues in Outlook, and will also reset your email's margins and paddings."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ outlook_background_snippet }}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This tag will set the background image or color in Outlook, providing that you've specified an associated background CSS rule."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create a drag and drop area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ dnd_area_stylesheet }}"
      }), " HubL tag and any other optional tags to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " of your template, you can configure a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " in the template body."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create an empty drag and drop area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " is the container that makes a portion of the web page editable in terms of its structure, design, and content. The body of a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " tag supplies the default content for the drag-and-drop area."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The drag-and-drop area can be enclosed within either a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " or a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<table>"
      }), ". One constraint of the drag-and-drop area is that the minimum width is set to 624 pixels, and this value cannot be overridden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This tag on its own will generate a drop zone for content creators to drag modules into within the email editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following code would specify an empty drag and drop area:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main\" %}\n\t<!-- generates an empty drag and drop area drop-section -->\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a drag and drop area with empty columns"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also customize your drag and drop area to specify default sections and columns by using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " fields."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the following HubL code would specify a drag and drop area with 3 columns:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main\" %}\n  {% dnd_section padding={'top': 25, 'bottom': '20} %}\n    {% dnd_column width=4 %}\n    {% end_dnd_column %}\n    {% dnd_column width=4 %}\n    {% end_dnd_column %}\n    {% dnd_column width=4 %}\n    {% end_dnd_column %}\n  {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), " HubL tag is ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " currently supported in email templates."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Add a drag and drop area with modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To pre-populate a section with content, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " tag to include a module by referencing its path. The module must be added within a section and column to pre-populate the drag-and-drop area with content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the example below, a default HubSpot module is referenced, but you can also include modules you've built, by specifying their path within your Design Tools file tree."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To specify a default value for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_attribute"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main\", full_width=False %}\n\t{% dnd_section padding={\n\t            'top':'25',\n\t            'bottom':'20'\n\t            }, full_width=False %}\n\t  {% dnd_column width=6 %}\n\t    {% dnd_module path='@hubspot/image_email', img={\n\t                    'alt':'NavyLogo',\n\t                    'height':38,\n\t                    'src':'email_dnd_template_images/NavyLogo.png',\n\t                    'width':120\n\t                    }, alignment='center', hs_enable_module_padding=True, hs_wrapper_css={\n\t                    'padding-bottom':'10px',\n\t                    'padding-left':'20px',\n\t                    'padding-right':'20px',\n\t                    'padding-top':'10px'\n\t                    } %}\n\t    {% end_dnd_module %}\n\t  {% end_dnd_column %}\n\t  {% dnd_column width=6 %}\n\t  {% end_dnd_column %}\n\t{% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Further customize and style your drag and drop template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each drag and drop HubL tag (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " etc.) includes different parameters that you can use to provide default styling and specify other behavior, such as the label that will appear in the email editor sidebar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To learn more about the parameters available for each tag, check out the links below for each tag:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-area",
          children: "dnd_area"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-section",
          children: "dnd_section"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-column",
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-module",
          children: "dnd_module"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}