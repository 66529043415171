"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'api/crm/contacts-timeline-embed';
const title = exports.title = 'Contacts Timeline Embed API';
const name = exports.name = 'Contacts Timeline Embed API';
const metaDescription = exports.metaDescription = 'Contacts Timeline Embed API';
const orderedTitles = exports.orderedTitles = ['Contact Timeline Embed'];
const orderedHttpMethods = exports.orderedHttpMethods = [];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      img: "img",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample
    } = _components;
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(EndpointCard, {
    children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
      children: [(0, _jsxRuntime.jsx)(_components.h4, {
        children: "Contact Timeline Embed"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "The Contact Timeline Embed is a custom timeline view that enables integrators to insert the HubSpot contact timeline into an external system. Integrators can use the URL to prompt a log in to HubSpot (if the users haven't already) and surface a custom, embeddable view of the HubSpot timeline. The custom view includes the contact's HubSpot score and allows full timeline filtering. For Marketing Professional/Enterprise portals, the embed also provides the option to enroll contacts in HubSpot workflows."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://cdn2.hubspot.net/hubfs/428357/2018-09-07_1252.png",
          alt: "Contact Embed"
        })
      }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
        children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "Portal ID",
          type: "Path",
          required: "true",
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "The Hub ID of the portal in question.\nUsed in the request URL (see example)."
          })
        }), (0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "contactId",
          type: "Path",
          required: "true",
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vid"
            }), " of the contact record you're looking to embed."]
          })
        })]
      })]
    }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
      children: (0, _jsxRuntime.jsx)(EndpointExample, {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: "CkV4YW1wbGUgaWZyYW1lOgp7JSBzZXQgZXhhbXBsZV9pZnJhbWUgPSAiPGlmcmFtZSBzcmM9XCJodHRwczovL2FwcC5odWJzcG90LmNvbS9jb250YWN0LXRpbWVsaW5lLWVtYmVkLzYyNTE1L2xvZ2luP2lkPTkyNDgzMjRcIj48L2lmcmFtZT4iICV9Cnt7ZXhhbXBsZV9pZnJhbWV8ZXNjYXBlfX0="
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}