"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021628;
const slug = exports.slug = 'guides/apps/authentication/working-with-oauth';
const title = exports.title = 'Trabajar con OAuth';
const name = exports.name = 'vNext Docs DP - Trabajar con OAuth';
const metaDescription = exports.metaDescription = 'OAuth es un medio seguro de autentificación para tu aplicación. Utiliza tokens de autorización en lugar de una contraseña para conectar tu aplicación a una cuenta de usuario. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "iniciando-una-integraci%C3%B3n-con-oauth-2.0",
  "label": "Iniciando una integración con OAuth 2.0",
  "parent": null
}, {
  "depth": 0,
  "id": "par%C3%A1metros-de-consulta",
  "label": "Parámetros de consulta",
  "parent": null
}, {
  "depth": 0,
  "id": "configurar-alcances",
  "label": "Configurar alcances",
  "parent": null
}, {
  "depth": 0,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h5: "h5",
      h2: "h2",
      em: "em",
      img: "img",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Trabajar con OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuth es un medio seguro de autentificación que usa tokens de autorización en lugar de una contraseña para conectar tu aplicación a una cuenta de usuario. Iniciar el acceso de OAuth es el primer paso para permitir a los usuarios ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#install-an-app",
        children: "instalar tu aplicación "
      }), " en sus cuentas de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "cualquier aplicación diseñada para ser instalada por varias cuentas de HubSpot o que se anuncie en el Mercado de aplicaciones debe usar OAuth."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["los usuarios que instalen aplicaciones en su cuenta de HubSpot deben ser ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
            children: "superadministradores"
          }), " o tener permisos de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: "acceso al mercado de aplicaciones"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Recursos recomendados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "Guía de inicio de OAuth"
        }), " te ayudará a usar una aplicación de ejemplo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Este ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/academy/tracks/71/593/2967",
          children: "Tutorial de HubSpot Academy"
        }), " ofrece una introducción rápida en el uso de OAuth con HubSpot, incluyendo un desglose del flujo de HubSpot-OAuth y cómo actualizar un token de acceso."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Iniciando una integración con OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para iniciar una integración con OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En primer lugar, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "crea una aplicación"
        }), " en una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers/",
          children: "cuenta de desarrollador de HubSpot"
        }), ". Después de crear la aplicación, podrás encontrar el ID de cliente de la aplicación y el secreto del cliente en la página de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "autentificación"
        }), " de la configuración de tu aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/MyHubSpotApp.png",
        alt: "MyHubSpotApp"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Usa el ID de cliente y el secreto del cliente, junto con los ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-parameters",
            children: "parámetros de consulta"
          }), " y los ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#scopes",
            children: "alcances"
          }), " que se describen a continuación, para crear la URL de autorización."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Envía a los usuarios que deseen instalar tu aplicación a la URL de autorización, donde se les presentará una pantalla que les permitirá seleccionar su cuenta y conceder acceso a tu integración. Puedes establecer que la URL de autorización sea para una cuenta de HubSpot específica agregando el ID de cuenta entre ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/oauth/"
          }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/authorize"
          }), ", como se muestra a continuación. Después de conceder acceso, se redireccionarán a tu aplicación mediante una", (0, _jsxRuntime.jsx)(_components.code, {
            children: "redirect_url"
          }), ", que tendrá un parámetro de consulta de código adjunto. Usarás ese código y el secreto del cliente para obtener un ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/oauth/tokens",
            children: "access_token y refresh_token"
          }), " de HubSpot."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Ejemplos de URL de autorización"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Cualquier cuenta:"
                }), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Cuenta específica (ID 123456):"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/123456/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Ejemplo de URL de redireccionamiento"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/?code=xxxx"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Ejemplo de error:"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/?error=error_code&error_description=Human%20readable%20description%20of%20the%20error"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Usa el ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " para autentificar las llamadas a la API realizadas para esa cuenta de HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Una vez que expire el ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), ", use el ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "refresh_token"
          }), " para generar un nuevo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["tu aplicación no aparecerá como ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Aplicación conectada"
          }), " en la cuenta de un usuario a menos que generes el token de actualización y el token de acceso inicial."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["los tokens de acceso reflejan los alcances solicitados desde la aplicación y ", (0, _jsxRuntime.jsx)("u", {
            children: "no "
          }), " reflejan los permisos o limitaciones de lo que un usuario puede hacer en su cuenta de HubSpot. Por ejemplo, si un usuario tiene permisos para ver solo los contactos de su propiedad, pero autoriza una solicitud para el alcance ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.objects.contacts.read"
          }), ", el token de acceso resultante puede ver todos los contactos de la cuenta y no solo los que son propiedad del usuario autorizante."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parámetros de consulta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los siguientes parámetros son obligatorios al crear una URL de autorización para tu aplicación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parámetro"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descripción"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Cómo se usa"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de cliente"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id=x"
            }), "Usado en la URL"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtén esto desde la página de configuración de autentificación de la aplicación (como se describió anteriormente)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Redireccionar URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri=x"
            }), "La URL a la que serán redireccionados los visitantes al recibir acceso a tu aplicación."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["También designarás esto en la página de configuración de autentificación de tu aplicación.", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nota:"
            }), " Por razones de seguridad, esta URL ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "debe"
            }), " usar https en producción. (Cuando pruebes usando localhost, puede usarse http). También ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "debes"
            }), " usar un dominio, ya que las direcciones IP no son compatibles."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Alcance"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un conjunto de permisos separados por espacios al que tu aplicación necesita tener acceso. Cualquier alcance que hayas marcado en la configuración de autentificación de tu aplicación se considerará como obligatorio y tendrás que incluirlo en este parámetro, o la página de autentificación mostrará un error. Además, los usuarios recibirán un error si intentan instalar tu aplicación en una cuenta que no tiene acceso a un alcance incluido. Ve la tabla de alcances a continuación para conocer más detalles sobre a qué puntos de terminación pueden tener acceso alcances específicos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los siguientes parámetros son opcionales:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parámetro"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Cómo usar"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descripción"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Alcances opcionales"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&optional_scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un conjunto de permisos opcionales separados por un espacio para tu aplicación. Los alcances opcionales se eliminarán automáticamente de la solicitud de autorización si el usuario selecciona una cuenta de HubSpot que no tiene acceso a esa herramienta (como solicitar el alcance en las redes sociales en un portal de CRM). Si estás usando alcances opcionales, deberás verificar el token de acceso o el token de actualización para ver cuáles se otorgaron. Ver la tabla a continuación para obtener más información sobre los alcances."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Estado"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "&state=y"
            }), "Si este parámetro está incluido en la URL de autorización, el valor se incluirá en un parámetro de consulta de estado cuando el usuario sea dirigido a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_url"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de cadena que se puede usar para mantener el estado del usuario cuando se redirecciona de regreso a tu aplicación."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar alcances"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuth requiere que configures alcances, o permisos, para tu aplicación. Cada alcance proporciona acceso a un conjunto de puntos de terminación de la API de HubSpot y permite a los usuarios otorgar a tu aplicación acceso a herramientas específicas en su cuenta de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/granular-scopes-selection.png",
        alt: "granular-scopes-selection"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El acceso a las API o puntos de terminación específicos depende del nivel de cuenta de HubSpot. Puedes encontrar una lista completa de alcances y puntos de terminación disponibles en la siguiente tabla. Si tu aplicación puede funcionar con varios tipos de cuentas de HubSpot, puedes usar el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " para incluir los alcances con los que trabajas. De esta manera, los clientes que usan las cuentas de CRM aún pueden autorizar tu aplicación, incluso si no pueden acceder a todos sus alcances. Tu aplicación debe verificar y manejar cualquier alcance para el que no esté autorizada."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Alcance"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descripción"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Proporciona acceso a"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nivel de cuenta obligatorio"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los integradores pueden enumerar dominios de CMS en la cuenta de un cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API del CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los integradores pueden crear, actualizar y eliminar dominios personalizados de CMS."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API del CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los integradores pueden ver todas las funciones sin servidor de CMS, cualquier secreto relacionado y los resultados de la ejecución de funciones."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API del CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Enterprise"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los integradores pueden escribir funciones y secretos sin servidor de CMS."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API del CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), "Enterprise"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles acerca de los artículos de conocimiento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Otorga acceso a artículos de conocimiento actualizados."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.publish"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Otorga acceso para actualizar y publicar artículos de conocimiento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve la configuración general y de la base de conocimientos de la plantilla, como el dominio o la URL raíz."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Otorga acceso para actualizar la configuración general y de la base de conocimientos de la plantilla. Esto incluye acceso de escritura a artículos de conocimiento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.performance.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los integradores pueden ver los datos de rendimiento de CMS para todos tus sitios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API del CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles de listas de contactos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de lista"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, elimina o haz cambios en listas de contactos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de listas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y otros detalles acerca de las empresas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de empresas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y crea, elimina o haz cambios en las empresas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de empresas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y otros detalles acerca de los contactos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de contactos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y crea, elimina o haz cambios en los contactos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de contactos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles acerca de los objetos personalizados en el CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de objetos personalizados"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cualquier ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crear, eliminar o hacer cambios a los objetos personalizados en el CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de objetos personalizados"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cualquier ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y otros detalles acerca de los negocios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de negocios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y crea, elimina o haz cambios en los negocios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de negocios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.feedback_submission.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles sobre los envíos a cualquiera de tus encuestas de feedback."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de encuesta de feedback"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.goals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver todos los tipos de metas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de metas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), ", o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y otros detalles acerca de los elementos de pedido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de elementos de pedido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, elimina o haz cambios a elementos de pedido."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de elementos de pedido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles sobre eventos de marketing."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de eventos de marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crear, eliminar o hacer cambios a los eventos de marketing."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de eventos de marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.owners.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles de usuarios asignados a un registro del CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de propietarios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve propiedades y otros detalles acerca de las cotizaciones y plantillas de cotizaciones."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de cotizaciones"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, elimina o haz cambios a cotizaciones."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de cotizaciones"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles de configuración de propiedades de las empresas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de propiedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, elimina o haz cambios en la configuración de propiedades de las empresas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de propiedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles de configuración de propiedades de los contactos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de propiedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, elimina o haz cambios en la configuración de propiedades de los contactos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de propiedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles acerca de las definiciones de los objetos personalizados en el HubSpot CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de objetos personalizados"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cualquier ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles de configuración de propiedades de los negocios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de propiedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, elimina o haz cambios en la configuración de propiedades de los negocios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de propiedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles acerca de elementos de pedido."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de elementos de pedido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles acerca de las cotizaciones y plantillas de cotizaciones."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de cotizaciones"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.billing.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Realiza cambios en la configuración de facturación de tu cuenta. Esto incluye la gestión y asignación de licencias de pago para los usuarios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de configuración"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lee los tipos de cambio existentes junto con la moneda actual de la empresa asociada con tu portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de información de la cuenta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, actualiza y elimina los tipos de cambio junto con la actualización de la moneda de la empresa asociada a tu portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de información de la cuenta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles sobre los usuarios de la cuenta y sus permisos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de aprovisionamiento de usuarios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gestiona los usuarios y los permisos de usuario en tu cuenta de HubSpot. Esto incluye la creación de nuevos usuarios, la asignación de permisos y roles, y la eliminación de usuarios existentes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de aprovisionamiento de usuarios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.teams.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles sobre los equipos de la cuenta."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de aprovisionamiento de usuarios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.team.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Asigna usuarios a los equipos en tu cuenta de HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de aprovisionamiento de usuarios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account-info.security.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Incluye acceso a los registros de actividad de la cuenta y otra información de seguridad de la cuenta."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de actividad de la cuenta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accounting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto permite que HubSpot y la integración de contabilidad compartan detalles de facturación, productos y contactos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de extensión de contabilidad"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Agrega formularios a las páginas del contacto que realizan acciones personalizadas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de extensiones del CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "analytics.behavioral_events.send"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye el acceso al envío de eventos de comportamiento personalizados"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de analíticas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye workflows."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de automatización (puntos de terminación de workflows)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "behavioral_events.event_definitions.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crea, lee, actualiza o elimina eventos de comportamiento. Esto incluye propiedades de eventos de comportamiento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de analíticas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business_units.view.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver datos de la unidad de negocio, incluida la información del logotipo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de unidades de negocio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Complemento de unidades de negocio"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business-intelligence"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye puntos finales que se ubican sobre las fuentes y el correo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de analíticas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_query.execute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Consulta datos de tu cuenta de HubSpot utilizando el punto de terminación de API de GraphQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Punto de terminación de API de GraphQL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_schema.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Realizar consultas de introspección a través de clientes de aplicaciones GraphQL como GraphiQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQL y otros clientes GraphQL de terceros"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Pro"]
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ver detalles de las preferencias de suscripción de tus contactos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de preferencias de suscripción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Suscríbete/cancela la suscripción de contactos a tus tipos de suscripción. No suscribirá a los contactos que se hayan dado de baja."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de preferencias de suscripción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Suscríbete/cancela la suscripción de contactos a tus tipos de suscripción. No suscribirá a los contactos que se hayan dado de baja."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de preferencias de suscripción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye sitios, landing pages, correo electrónico, blog y campañas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de CMS y puntos de terminación de calendario, correo electrónico y eventos de correo electrónico"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise,"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles sobre las cadenas en la bandeja de entrada de conversaciones."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bandeja de entrada de conversaciones y API de mensajes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.visitor_identification.tokens.create"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtén tokens de identificación para los visitantes del sitio web autentificados que interactúen con el widget de chat de HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de identificación de visitantes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cualquier ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envía mensajes en conversaciones. Crear y actualizar hilos de mensajes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bandeja de entrada de conversaciones y API de mensajes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.export"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exporta registros desde tu CRM para todos los tipos de datos de CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de importaciones de CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.import"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Te permite importar registros en tu CRM. Esto incluye crear nuevos registros o modificar alguno de tus registros existentes para todos los tipos de datos de CRM (contactos, empresas, negocios, tickets, etc.). No incluye archivar ni eliminar ningún dato."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de importaciones de CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ctas.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite el acceso de lectura para las CTA."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["No hay disponibilidad de publicAPI ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter, Pro o Enterprise"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye acceso a las características de comercio electrónico."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de productos y elementos de pedido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "external_integrations.forms.access"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Incluye la capacidad de cambiar el nombre, eliminar y clonar formularios existentes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de formularios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye el acceso al administrador de archivos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de los archivos (Administrador de archivos) y asignaciones de archivos (plantillas de CMS, módulos y diseño)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ve detalles o descarga archivos de usuarios, archivos adjuntos y archivos del sistema de todas las herramientas de HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de los archivos ( Archivo Mánager ) y asignaciones de archivos (plantillas de CMS, módulos y diseño)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye acceso a los puntos de terminación de los formularios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de formularios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms-uploaded-files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Descargar archivos enviados a través de un formulario."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtén un archivo cargado a través del punto de terminación de envío de formularios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye el acceso a HubDB."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de HubDB"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), ", o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " con el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/website",
              children: "complemento de sitios web"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integration-sync"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto expone la API de sincronización, que permite la sincronización de la mayoría de los objetos del CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API del Ecommerce Bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Otorga acceso a eventos y objetos desde el centro multimedia."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de Centro multimedia"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Otorga acceso para crear y actualizar eventos y objetos desde el centro multimedia."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de Centro multimedia"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oauth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El alcance básico requerido para OAuth. Este alcance se agrega por opción predeterminada a todas las aplicaciones."
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sales-email-read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Otorga acceso para leer todos los detalles de los correos electrónicos individuales enviados a los contactos."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Puntos de terminación de interacciones ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nota:"
            }), " Este alcance es obligatorio para obtener el contenido de las interacciones por correo electrónico. Ver el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/engagement-details#email-content",
              children: "Resumen de interacciones"
            }), " para más detalles."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye la bandeja de entrada de las redes sociales."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de redes sociales"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye el acceso a los tickets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de tickets"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Otorga acceso a la administración de eventos personalizados en los registros de HubSpot CRM. Esto incluye crear o actualizar registros."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de eventos de cronología"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cualquier cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transactional-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esto incluye correos electrónicos transaccionales y los puntos de terminación de los correos electrónicos transaccionales."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Puntos de terminación de correos electrónicos transaccionales"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            }), " Pro o Enterprise con ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/email/transactional-email",
              children: "Complemento de correo electrónico transaccional"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Métodos de autentificación en HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "Guía de inicio de OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/oauth/tokens",
        children: "Tokens de gestión"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}