"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42366481070;
const slug = exports.slug = 'guides/api/cms/content-audit';
const title = exports.title = 'CMSコンテンツオーディットAPI｜HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (ja) | vNext Docs DP - CMS Content Audit API_JA | 202104';
const metaDescription = exports.metaDescription = 'CMSコンテンツオーディット（証跡）APIを使用すると、コンテンツオブジェクトに関する変更をタイプ、期間、またはHubSpotユーザーIDを基準に絞り込んだり、並べ替えたりできるため、更新状況を常に把握できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cms%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88",
  "label": "CMSイベントタイプのリスト",
  "parent": null
}, {
  "depth": 0,
  "id": "cms%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88",
  "label": "CMSオブジェクトタイプのリスト",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "CMSコンテンツオーディット"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMSコンテンツオーディット（証跡）APIを使用すると、コンテンツオブジェクトに関する変更をタイプ、期間、またはHubSpotユーザーIDを基準に絞り込んだり、並べ替えたりできます。このエンドポイントを使用できるのは、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "CMS Hub Enterprise"
      }), "をご契約中のアカウントのみです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**使用例：**ページのリストに最後に変更を加えたユーザーを確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMSイベントタイプのリスト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "イベントタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CREATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクトが作成されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DELETED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクトが削除または接続解除されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクトが公開されました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UNPUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクトが非公開にされました。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクトが更新されました。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMSオブジェクトタイプのリスト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "オブジェクトタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント設定内のブログ設定に加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログに関連付けられているブログ記事。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTENT_SETTINGS"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウント設定内のウェブサイト設定に加えられた変更。変更された値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta"
            }), " JSON配列として示されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CTA"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/marketing-tools/topics#ctas",
              children: "CTA（Calls-to-Action）"
            }), "に加えられた変更。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOMAIN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント設定内の［ドメインとURL］設定で接続されているドメインに加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "EメールエディターでEメールに加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FILE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/cos-general/organize-edit-and-delete-files",
              children: "ファイルツール"
            }), "でファイルに加えられた変更。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GLOBAL_MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/cos-general/can-i-make-changes-to-a-global-module-in-only-one-template",
              children: "グローバルモジュール"
            }), "に加えられた変更。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBDB_TABLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDBテーブルに加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント設定内のナレッジベース設定に加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツエディター上でナレッジベース記事に加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツエディター上でランディングページに加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview",
              children: "モジュール"
            }), "に加えられた変更。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SERVERLESS_FUNCTION"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
              children: "サーバーレス関数"
            }), "に加えられた変更。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TEMPLATE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "テンプレート"
            }), "に加えられた変更。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "THEME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/website-pages/edit-your-global-theme-settings",
              children: "テーマ設定"
            }), "に加えられた変更と", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/website-pages/edit-your-global-theme-settings",
              children: "テーマ"
            }), "が作成されたときの変更。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "URL_MAPPING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント設定内の［URLリダイレクト］設定でURLリダイレクトに加えられた変更。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBSITE_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツエディター上でウェブサイトページに加えられた変更。"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}