"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529617;
const slug = exports.slug = 'reference/cms/serverless-functions';
const title = exports.title = 'Referenz für serverlose Funktionen';
const name = exports.name = 'EMEA DACH (DE) Serverless Reference';
const metaDescription = exports.metaDescription = 'Referenzinformationen für Ihre serverless.json-Datei, Funktionsdateien, Endpunkte, CLI-Befehle und die Verwaltung von Paketen – alles an einem Ort.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": null
}, {
  "depth": 1,
  "id": "endpunkte",
  "label": "Endpunkte",
  "parent": "serverless.json"
}, {
  "depth": 0,
  "id": "funktionsdatei",
  "label": "Funktionsdatei",
  "parent": null
}, {
  "depth": 1,
  "id": "kontextobjekt",
  "label": "Kontextobjekt",
  "parent": "funktionsdatei"
}, {
  "depth": 1,
  "id": "header",
  "label": "Header",
  "parent": "funktionsdatei"
}, {
  "depth": 2,
  "id": "weiterleitung-durch-senden-eines-headers",
  "label": "Weiterleitung durch Senden eines Headers",
  "parent": "funktionsdatei"
}, {
  "depth": 1,
  "id": "cookies-von-ihrem-endpunkt-aus-setzen",
  "label": "Cookies von Ihrem Endpunkt aus setzen",
  "parent": "funktionsdatei"
}, {
  "depth": 1,
  "id": "mehrere-werte-f%C3%BCr-einen-einzigen-header-festlegen",
  "label": "Mehrere Werte für einen einzigen Header festlegen",
  "parent": "funktionsdatei"
}, {
  "depth": 0,
  "id": "geheimnisse",
  "label": "Geheimnisse",
  "parent": null
}, {
  "depth": 0,
  "id": "verwendung-von-serverlosen-funktionen-mit-dem-form-element",
  "label": "Verwendung von serverlosen Funktionen mit dem form-Element",
  "parent": null
}, {
  "depth": 0,
  "id": "cors",
  "label": "CORS",
  "parent": null
}, {
  "depth": 1,
  "id": "get-anfragen",
  "label": "GET-Anfragen",
  "parent": "cors"
}, {
  "depth": 0,
  "id": "vorinstallierte-pakete",
  "label": "Vorinstallierte Pakete",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen",
  "label": "Beschränkungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      h4: "h4",
      em: "em",
      ul: "ul",
      li: "li",
      br: "br",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Referenz für serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie eine serverlose Funktion als Teil eines ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "Entwicklerprojekts"
        }), " erstellen, besuchen Sie stattdessen die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "Dokumentation der serverlosen Funktion für Entwicklerprojekte"
        }), ". Die folgende Dokumentation dient zum Erstellen serverloser Funktionen außerhalb der Entwicklerprojektplattform."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Artikel erfahren Sie mehr über die Dateien, die sich in einem ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: ["serverlosen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), "-Ordner"]
      }), " befinden, und die CLI-Befehle, die Sie mit serverlosen Funktionen verwenden können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einen umfassenden Überblick über serverlose Funktionen finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "Übersicht über serverlose Funktionen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen zum Erstellen serverloser Funktionen mit Projekten für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "JavaScript gerenderte Module und Partials finden"
      }), " Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "Dokumentation zu Entwicklerprojekten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), "-Ordner"]
      }), " speichert die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Datei die Konfiguration der serverlosen Funktion. Dies ist eine erforderliche Datei und sie ordnet Ihre Funktionen ihren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#endpoints",
        children: "Endpunkten"
      }), " zu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// serverless.json\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"environment\": {\n    \"globalConfigKey\": \"some-value\"\n  },\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"events\": {\n      \"file\": \"function1.js\",\n      \"method\": \"GET\"\n    },\n    \"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"CONFIG_KEY\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleKeyName\", \"otherkeyname\"]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Schlüssel"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "runtime"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Laufzeitumgebung. Unterstützt die folgenden ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/en/about/",
              children: "Node.js"
            }), "-Versionen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Knoten 20 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs20.x"
                }), ") (empfohlen)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Knoten 18 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs18.x"
                }), ")"]
              })]
            }), "Beachten Sie, dass HubSpot Node 16 über den 12. Juli 2024 hinaus ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/changelog/deprecation-of-node-v16-in-all-serverless-functions",
              children: "nicht mehr unterstützt"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Schema-Version der serverlose Funktion von HubSpot. (Aktuelle Version 1.0)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "environment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Konfigurationsvariablen, die zur Laufzeit als ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
              children: "Umgebungsvariablen"
            }), " an die ausführende Funktion übergeben werden. Sie könnten dies verwenden, um eine Logik für die Verwendung einer Testversion einer API anstelle der echten Version auf der Grundlage einer Umgebungsvariablen hinzuzufügen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Array mit den Namen der Geheimnisse, die Ihre serverlose Funktion für die Authentifizierung verwendet. Speichern Sie Geheimniswerte nicht direkt in dieser Datei, sondern verweisen Sie nur auf die Geheimnisnamen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoints"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Endpunkte definieren die Pfade, die zugänglich gemacht werden, und ihre Zuordnung zu bestimmten JavaScript-Dateien in Ihrem Funktionsordner. Erfahren Sie unten mehr über Endpunkte."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Weisen Sie Ihren Geheimnissen und Umgebungsvariablen nicht denselben Namen zu. Andernfalls kommt es zu Konflikten bei der Rückgabe ihrer Werte in der Funktion."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Endpunkte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jeder Endpunkt kann seine eigenen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "Umgebungsvariablen"
      }), " und Geheimnisse haben. Außerhalb von Endpunkten angegebene Variablen sollten für Konfigurationseinstellungen verwendet werden, die für alle Funktionen und Endpunkte gelten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"configKey\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleAPIKeyName\",\"otherKeyName\"]\n    }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Endpunkte haben einige eindeutige Schlüssel."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Schlüssel"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge oder Array von Zeichenfolgen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
              children: "HTTP-Methode oder -Methoden"
            }), ", die der Endpunkt unterstützt. Die Standardeinstellung ist ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
              children: "GET"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pfad zur JavaScript-Funktionsdatei mit der Implementierung für den Endpunkt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverlose Funktionen werden über einen Pfad in der Domain Ihres HubSpot CMS-Accounts bereitgestellt. Dies gilt auch für standardmäßige ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-sites.com"
      }), "-Subdomains."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können diese Funktionen unter der folgenden URL aufrufen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://{domainName}/_hcms/api/{endpoint-name/path}?portalid={hubId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die einzelnen URL-Komponenten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "domainName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ihr Domain-Name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/_hcms/api/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der für serverlose Funktionen reservierte Pfad. Alle Endpunkte befinden sich innerhalb dieses Pfades."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoint-name/path"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Endpunktname oder -pfad, den Sie in der ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), "-Datei angegeben haben."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ihre Hub-ID. Wenn Sie dies in der Anfrage angeben, können Sie Ihre Funktionen in Modul- und Vorlagenvorschauen testen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Funktionsdatei"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neben der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Konfigurationsdatei enthält der Ordner ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "-Ordner auch eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), "-JavaScript-Datei, die die Funktion definiert. Sie können die Bibliothek der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/request/request#readme",
        children: "Anfragen"
      }), " auch verwenden, um HTTP-Anfragen an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "HubSpot-APIs"
      }), " und andere APIs durchzuführen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Require axios library, to make API requests.\nconst axios = require('axios');\n// Environment variables from your serverless.json\n// process.env.globalConfigKey\n\nexports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n\n  // context.params\n  // context.body\n  // context.accountId\n  // context.limits\n\n  // secrets created using the CLI are available in the environment variables.\n  // process.env.secretName\n\n  //sendResponse is what you will send back to services hitting your serverless function.\n  sendResponse({ body: { message: 'my response' }, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Kontextobjekt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Kontextobjekt enthält Kontextinformationen über die Ausführung der Funktion, die in den folgenden Parametern gespeichert sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die HubSpot-Account-ID, die die Funktion enthält."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wird ausgefüllt, wenn die Anfrage als ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " mit dem Inhaltstyp ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "application/json"
            }), " gesendet wird"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn die Anfrage von einem Kontakt mit einem gesetzten Cookie stammt, wird das Kontaktobjekt mit einer Reihe von grundlegenden Kontakteigenschaften mit den folgenden Informationen gefüllt.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": die Besucher-ID des Kontakts"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "isLoggedIn"
                }), ": Bei Verwendung von CMS-Zugriffsberechtigungen ist dies ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", wenn der Kontakt in der Domain angemeldet ist."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "listMemberships"
                }), ": ein Array von IDs für Kontaktlisten, bei denen dieser Kontakt Mitglied ist"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enthält die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#headers",
              children: "Header"
            }), ", die vom Client gesendet werden, der Ihren Endpunkt erreicht."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "params"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gefüllt mit Abfragezeichenfolgenwerten zusammen mit allen HTML-Formular-POST-Werten. Diese sind als Zuordnung mit Zeichenfolgen als Schlüssel und einem Array von Zeichenfolgen für jeden Wert strukturiert.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "context.params.yourvalue"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limits"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt zurück, wie nah Sie an den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview#know-your-limits",
              children: "Ratenbeschränkungen für serverlose Funktionen"
            }), ".", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "executionsRemaining"
                }), ": wie viele Ausführungen pro Minute noch übrig sind."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "timeRemaining"
                }), ": wie viel erlaubte Ausführungszeit noch übrig ist."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Header"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie die Header des Clients, der Ihren Endpunkt erreicht, in Erfahrung bringen müssen, können über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.headers"
      }), " auf diese zugreifen, ähnlich wie Sie über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.body"
      }), " auf Informationen zugreifen würden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden finden Sie einige der gängigen Header, die HubSpot bereitstellt. Eine vollständige Liste finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers",
        children: "HTTP-Header-Dokumentation von MDN"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Header"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Teilt mit, welche Inhaltstypen, ausgedrückt als ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types",
              children: "MIME-Typen"
            }), ", der Client versteht. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-encoding"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Teilt die Inhaltskodierung mit, die der Client versteht. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-encoding",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Teilt mit, welche menschliche Sprache und welches Gebietsschema bevorzugt wird. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-language",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cache-control"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enthält Richtlinien für die Zwischenspeicherung. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "connection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Teilt mit, ob die Netzwerkverbindung offen bleibt. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/connection",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cookie"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enthält Cookies, die der Client gesendet hat. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/cookie",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Übermittelt den Domain-Namen und die TCP-Portnummer eines überwachenden Servers. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/host",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true-client-ip"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["IP-Adresse des Endbenutzers. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/206776727-What-is-True-Client-IP-",
              children: "Siehe Cloudflare true-client-ip"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upgrade-insecure-requests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Teilt mit, dass die Clients eine verschlüsselte und authentifizierte Antwort wünschen. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Upgrade-Insecure-Requests",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "user-agent"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vom Hersteller definierte Zeichenfolge zur Identifizierung der Anwendung, des Betriebssystems, des Anwendungsherstellers und der Version. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent",
              children: "Siehe MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "x-forwarded-for"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifiziert die ursprüngliche IP-Adresse eines Clients durch einen Proxy oder Load Balancer. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For",
              children: "Siehe MDN"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Weiterleitung durch Senden eines Headers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine Weiterleitung von Ihrer serverlosen Funktion durchführen, indem Sie eine Antwort mit einem ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Standort"
      }), "-Header und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "301"
      }), "-Status-Code senden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "sendResponse({\n  statusCode: 301,\n  headers: {\n    Location: 'https://www.example.com',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cookies von Ihrem Endpunkt aus setzen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Über Ihre serverlose Funktion können Sie dem Client (Webbrowser) mitteilen, dass er ein Cookie setzen soll."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n    sendResponse({\n      body: { ... },\n      'Set-Cookie': 'myCookie1=12345; expires=...; Max-Age=...',\n      statusCode: 200\n    });\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mehrere Werte für einen einzigen Header festlegen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für Header, die mehrere Werte unterstützen, können Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multiValueHeaders"
      }), " verwenden, um die Werte zu übergeben. Zum Beispiel können Sie den Browser anweisen, mehrere Cookies zu setzen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  sendResponse({\n    body: { ... },\n    multiValueHeaders: {\n      'Set-Cookie': [\n        'myCookie1=12345; expires=...; Max-Age=...',\n        'myCookie2=56789; expires=...; Max-Age=...'\n       ]\n    },\n    statusCode: 200\n  });\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Geheimnisse"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine Anfrage der serverlosen Funktionen authentifizieren müssen, verwenden Sie Geheimnisse, um Werte wie API-Schlüssel oder private App-Zugriffstoken zu speichern. Mit dem CLI können Sie Ihrem HubSpot-Account Geheimnisse hinzufügen, um diese Werte zu speichern, auf die Sie später über Umgebungsvariablen (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "process.env.secretName"
      }), ") zugreifen können. Geheimnisse werden über die HubSpot-Befehlszeile mit den folgenden Befehlen verwaltet:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#list-secrets",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets list"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#add-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets add"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#remove-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets delete"
          })
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald Geheimnisse über das CLI hinzugefügt wurden, können sie Funktionen zur Verfügung gestellt werden, indem ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), "-Array mit dem Namen des Geheimnisses hinzugefügt wird. Auf diese Weise können Sie Ihren Funktionscode in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "Versionskontrolle speichern"
      }), " und Geheimnisse verwenden, ohne sie preiszugeben. Sie sollten jedoch ", (0, _jsxRuntime.jsx)("u", {
        children: "niemals"
      }), " den Wert Ihres Geheimnisses durch Konsolenprotokollierung oder als Antwort zurückgeben, da dies das Geheimnis in Protokollen oder auf Frontend-Seiten preisgibt, die Ihre serverlose Funktion aufrufen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Aufgrund der Zwischenspeicherung kann es etwa eine Minute dauern, bis aktualisierte geheime Werte angezeigt werden. Wenn Sie gerade ein Geheimnis aktualisiert haben, aber immer noch den alten Wert sehen, überprüfen Sie es nach etwa einer Minute erneut."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung von serverlosen Funktionen mit dem form-Element"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei der Übermittlung von serverlosen Funktionen verwenden Sie JavaScript, um die Formulareinsendung zu verarbeiten, und verwenden Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"contentType\" : \"application/json\""
      }), "-Header in Ihrer Anfrage. Verwenden Sie nicht das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "action"
      }), "-Attribut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<form>"
      }), "-Elemente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CORS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cross Origin Resource Sharing (CORS)"
        })
      }), " ist eine Sicherheitsfunktion des Browsers. Standardmäßig schränken Browser durch JavaScript initiierte herkunftsübergreifende Anfragen ein. Dadurch wird verhindert, dass bösartiger Code, der auf einer anderen Domain läuft, Ihre Website beeinträchtigt. Dies wird als Same-Origin-Policy bezeichnet. Da das Senden und Abrufen von Daten von anderen Servern manchmal notwendig ist, kann der externe Server HTTP-Header bereitstellen, die mitteilen, welche Herkunft die Informationen von einem Browser lesen darf."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie sollten keine CORS-Probleme haben, wenn Sie Ihre serverlose Funktion innerhalb Ihrer von HubSpot gehosteten Seiten aufrufen. Wenn dies doch der Fall ist, vergewissern Sie sich, dass Sie das richtige Protokoll verwenden."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erhalten Sie diesen CORS-Fehler?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Access to fetch at [Ihre Funktions-URL] from origin [Seite, die die Anfrage stellt] has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource. If an opaque response serves your needs, set the request's mode to 'no-cors' to fetch the resource with CORS disabled.\""
        })]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Geht Ihre Anfrage an einen anderen Ursprung als die aufrufende Seite?"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Wenn der Domain-Name anders ist, ja."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Bei Verwendung eines anderen Protokolls (http, https), ja."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn Sie ein anderes Protokoll verwenden, ändern Sie einfach das Protokoll, und das Problem ist behoben."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Sie können den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Access-Control-Allow-Origin-Header"
        }), " von HubSpot derzeit nicht ändern."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors",
          children: "Siehe MDN für weitere detaillierte CORS-Fehlerbehebung."
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "GET-Anfragen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["GET-Anfragen können je nach Client auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: "CORS-Anfragen"
      }), " stellen. Bei ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
        children: "GET-Anfragen"
      }), " wird nichts geschrieben, sondern nur Daten zurückgegeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorinstallierte Pakete"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serverlose HubSpot-Funktionen werden derzeit mit den folgenden Paketen vorinstalliert:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/@hubspot/api-client",
          children: "@hubspot/api-client"
        }), ": ^1.0.0-beta"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ": ^0.19.2"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        }), ": ^2.88.0"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/requests",
          children: "requests"
        }), ": ^0.2.2"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So nutzen Sie die neueste unterstützte Version eines vorinstallierten Pakets bzw. so nutzen Sie ein neu hinzugefügtes Paket:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Klonen oder kopieren Sie Ihre Funktionsdatei."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ändern Sie den Endpunkt Ihrer Funktion in der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "-Datei, um auf Ihre neue Funktionsdatei zu verweisen. Sie können die alte Version bedenkenlos löschen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Pakete außerhalb des vorinstallierten Paketsatzes einbinden möchten, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " verwenden, um Ihre Node-Module zu kombinieren und Ihre gebündelten Dateien als Funktionsdateien zu verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serverlose Funktionen sollen schnell sein und einen engen Fokus haben. Um schnelle Aufrufe und Antworten zu ermöglichen, unterliegen die serverlosen Funktionen von HubSpot folgenden Beschränkungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 Geheimnisse pro Account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 MB Speicher."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Nicht mehr als 100 Endpunkte pro HubSpot-Account"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie müssen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contentType"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " beim Aufrufen einer Funktion verwenden."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Protokolle der serverlose Funktionen werden 90 Tage lang gespeichert."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6 MB in einer AWS Lambda-Aufruf-Payload."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ausführungslimits"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Jede Funktion hat eine maximale Ausführungszeit von 10 Sekunden."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Jeder Account ist auf insgesamt 600 Ausführungssekunden pro Minute beschränkt."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das bedeutet, dass jedes dieser Szenarien eintreten kann:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "60 Funktionsausführungen, die jeweils 10 Sekunden in Anspruch nehmen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6.000 Funktionsausführungen, die 100 Millisekunden dauern."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Funktionen, die diese Limits überschreiten, lösen einen Fehler aus. Ausführungsanzahl und Zeitlimits geben eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "-Antwort zurück. Die Ausführungszeit jeder Funktion wird in den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "Protokollen der serverlosen Funktionen"
      }), " berücksichtigt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um diese Beschränkungen zu umgehen, werden dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: "Funktionskontext"
      }), " während der Ausführung automatisch Daten für Obergrenzen zur Verfügung gestellt. Sie können dies nutzen, um Ihre Anwendung so zu beeinflussen, dass sie innerhalb dieser Obergrenzen bleibt. Wenn Ihre Anwendung beispielsweise die Abfrage Ihres Endpunkts erfordert, können Sie mit Ihren Daten eine Variable zurückgeben, um die Häufigkeit der Abfrage zu beeinflussen. Auf diese Weise können Sie bei hohem Traffic die Abrufrate verlangsamen, um ein Überschreiten der Obergrenzen zu vermeiden, und sie dann bei geringem Traffic wieder erhöhen."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}