"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42761605800;
const slug = exports.slug = 'guides/apps/authentication/oauth-quickstart-guide';
const title = exports.title = 'Arbeiten mit OAuth | OAuth-Schnellanleitung';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - OAuth Quickstart';
const metaDescription = exports.metaDescription = 'Mithilfe unserer Schnellanleitung und einer Beispiel-App mit Node.js erfahren Sie, wie Sie Ihre App über OAuth mit HubSpot verknüpfen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "bevor-sie-loslegen",
  "label": "Bevor Sie loslegen",
  "parent": null
}, {
  "depth": 1,
  "id": "so-funktioniert-das-ganze",
  "label": "So funktioniert das Ganze",
  "parent": "bevor-sie-loslegen"
}, {
  "depth": 1,
  "id": "in-diesem-leitfaden",
  "label": "In diesem Leitfaden",
  "parent": "bevor-sie-loslegen"
}, {
  "depth": 0,
  "id": "schnellanleitungs-app",
  "label": "Schnellanleitungs-App",
  "parent": null
}, {
  "depth": 0,
  "id": "abrufen-von-oauth-2.0-token",
  "label": "Abrufen von OAuth 2.0-Token",
  "parent": null
}, {
  "depth": 1,
  "id": "schritt-1-autorisierungs-url-erstellen-und-den-benutzer-zum-oauth-2.0-server-von-hubspot-weiterleiten",
  "label": "Schritt 1: Autorisierungs-URL erstellen und den Benutzer zum OAuth 2.0-Server von HubSpot weiterleiten",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 3,
  "id": "beispiele",
  "label": "Beispiele",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 3,
  "id": "codierung-eines-zus%C3%A4tzlichen-status-der-weiterleitung-eines-benutzers",
  "label": "Codierung eines zusätzlichen Status der Weiterleitung eines Benutzers",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 1,
  "id": "schritt-2-hubspot-fordert-benutzer-zur-einwilligung-auf",
  "label": "Schritt 2: HubSpot fordert Benutzer zur Einwilligung auf",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 1,
  "id": "schritt-3-oauth-2.0-serverantwort-verarbeiten",
  "label": "Schritt 3: OAuth 2.0-Serverantwort verarbeiten",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 3,
  "id": "beispiel",
  "label": "Beispiel:",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 1,
  "id": "schritt-4-autorisierungscode-f%C3%BCr-token-austauschen",
  "label": "Schritt 4: Autorisierungscode für Token austauschen",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 3,
  "id": "beispiel",
  "label": "Beispiel:",
  "parent": "abrufen-von-oauth-2.0-token"
}, {
  "depth": 0,
  "id": "verwenden-von-oauth-2.0-token",
  "label": "Verwenden von OAuth 2.0-Token",
  "parent": null
}, {
  "depth": 3,
  "id": "beispiel",
  "label": "Beispiel:",
  "parent": "verwenden-von-oauth-2.0-token"
}, {
  "depth": 0,
  "id": "aktualisieren-von-oauth-2.0-token",
  "label": "Aktualisieren von OAuth 2.0-Token",
  "parent": null
}, {
  "depth": 3,
  "id": "beispiel",
  "label": "Beispiel:",
  "parent": "aktualisieren-von-oauth-2.0-token"
}, {
  "depth": 2,
  "id": "verwandte-dokumente",
  "label": "Verwandte Dokumente",
  "parent": "aktualisieren-von-oauth-2.0-token"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      hr: "hr",
      h3: "h3",
      ol: "ol",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h5: "h5",
      pre: "pre",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "OAuth-Schnellanleitung"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bevor Sie loslegen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie OAuth mit HubSpot verwenden können, müssen Sie über Folgendes verfügen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers",
          children: "Ein Entwickler-Account"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "Eine App"
        }), ", die mit Ihrem Entwickler-Account verknüpft ist"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ein HubSpot-Account*, um Ihre App zu installieren (Sie können einen vorhandenen Account verwenden oder einen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "Test-Account erstellen"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "*Sie müssen ein Super-Admin sein, um eine App in einem HubSpot-Account zu installieren"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "So funktioniert das Ganze"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot unterstützt den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.okta.com/blog/2018/04/10/oauth-authorization-code-grant-type",
        children: "OAuth 2.0 Grant-Type „Autorisierungscode“"
      }), ", der in vier grundlegende Schritten unterteilt werden kann:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ihre App öffnet ein Browser-Fenster, um den Benutzer an den HubSpot OAuth 2.0-Server weiterzuleiten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Benutzer prüft die angeforderten Berechtigungen und gewährt der App Zugriff."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Benutzer wird mit einem Autorisierungscode in der Abfragezeichenfolge zurück zur App geleitet."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die App sendet eine Anfrage an den OAuth 2.0-Server, um den Autorisierungscode gegen ein Zugriffstoken auszutauschen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "In diesem Leitfaden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#quick-start-app",
          children: "Schnellanleitungs-App"
        }), ": Eine Node.js-Demo-App, die sich beim OAuth 2.0-Server von HubSpot authentifiziert"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#getting_tokens",
          children: "Abrufen von OAuth 2.0-Token"
        }), " So autorisieren Sie Ihre App bei Benutzern"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#using_tokens",
          children: "Verwenden von OAuth 2.0-Token:"
        }), " So erstellen Sie Abfragen mit einem Token"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#refreshing_tokens",
          children: "Aktualisieren von OAuth 2.0-Token"
        }), ": So verwenden Sie das von HubSpot bereitgestellte Aktualisierungstoken"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Hinweis:"
      }), " Alle Code-Beispiele in diesem Leitfaden sind in JavaScript (Node.js) geschrieben"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Schnellanleitungs-App"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie zum ersten Mal die OAuth-Authentifizierung mit den APIs von HubSpot verwenden, sollten Sie sich unbedingt vorher mit der in Node.js geschriebenen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
        children: "Node.js OAuth 2.0 Quickstart-App"
      }), " beschäftigen. Diese Beispiel-App ermöglicht einen schnellen Einstieg in die Verwendung von OAuth 2.0, da alle unten in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "Abrufen von OAuth 2.0-Token"
      }), " beschriebenen Schritte erläutert werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "App auf Github abrufen"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abrufen von OAuth 2.0-Token"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schritt 1: Autorisierungs-URL erstellen und den Benutzer zum OAuth 2.0-Server von HubSpot weiterleiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen Benutzer an den OAuth 2.0-Server von HubSpot verweisen, wird im ersten Schritt die Autorisierungs-URL erstellt. Dadurch wird Ihre App identifiziert und es werden die Ressourcen (Bereiche) definiert, auf die diese im Namen des Benutzers Zugriff anfordert. Die Abfrageparameter, die Sie als Teil einer Autorisierungs-URL übergeben können, werden unten angezeigt. Weitere Informationen zu diesem Schritt finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Referenzdokumentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Erforderlich?"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ja"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Client-ID identifiziert Ihre App. Sie finden sie auf der Einstellungsseite Ihrer App."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ja"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth#scopes",
              children: "Bereiche"
            }), ", die Ihre Anwendung anfordert, getrennt durch URL-codierte Leerzeichen."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contacts%20social"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ja"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die URL, zu der der Benutzer weitergeleitet wird, nachdem er Ihre App für die angeforderten Bereiche autorisiert hat. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bei Produktionsanwendungen ist https erforderlich."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.beispiel.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optional_scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nein"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Bereiche, die für Ihre App optional sind und verworfen werden, wenn das ausgewählte HubSpot-Portal keinen Zugriff auf diese Produkte hat"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nein"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein eindeutiger Zeichenfolgenwert, der zur Aufrechterhaltung des Status des Benutzers verwendet werden kann, wenn er zurück zu Ihrer App weitergeleitet wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WeHH_yy2irpl8UYAvv-my"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie Ihre URL erstellt haben, starten Sie den OAuth 2.0-Prozess, indem Sie den Benutzer dorthin verweisen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Beispiele"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe einer serverseitigen Weiterleitung:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Build the auth URL\nconst authUrl =\n  'https://app.hubspot.com/oauth/authorize' +\n  `?client_id=${encodeURIComponent(CLIENT_ID)}` +\n  `&scope=${encodeURIComponent(SCOPES)}` +\n  `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;\n+`&state=${encodeURIComponent(STATE)}`;\n\n// Redirect the user\nreturn res.redirect(authUrl);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe eines HTML-Links:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a\n  href=\"https://app.hubspot.com/oauth/authorize?scope=contacts%20social&redirect_uri=https://www.example.com/auth-callback&client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&state=xxxxxxxx\"\n  >Install</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Codierung eines zusätzlichen Status der Weiterleitung eines Benutzers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einige Apps müssen den Benutzer möglicherweise an andere Orte weiterleiten. Beispielsweise kann eine App Benutzer auf verschiedene Subdomains ihrer Integration weiterleiten (z. B. benutzerA.integration.com und benutzerB.integration.com). Verwenden Sie dazu den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), "-Parameter, um weitere Informationen zum Benutzerstatus zu codieren:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1. Generieren und speichern Sie einen Nonce-Wert für den state-Parameter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2. Speichern Sie den Status des Benutzers in einem lokalen Datenspeicher mit dem Nonce-Wert als dessen Schlüssel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "3. Schließen Sie den Nonce-Wert als state-Parameter in der Autorisierungs-URL ein."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "4. Wenn sich der Benutzer authentifiziert und zu Ihrer Weiterleitungs-URL weitergeleitet wird, validieren Sie den state-Parameter und verwenden Sie ihn als Schlüssel, um den gespeicherten Benutzerstatus abzurufen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "5. Leiten Sie den Benutzer von dort aus nach Bedarf weiter (z. B. durch erneutes Weiterleiten zu einer benutzerspezifischen URL)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schritt 2: HubSpot fordert Benutzer zur Einwilligung auf"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot zeigt dem Benutzer ein Einwilligungsfenster an, in dem der Name Ihrer App und eine kurze Beschreibung der HubSpot API-Dienste angezeigt werden, für die eine Zugriffsberechtigung angefordert wird. Der Benutzer kann dann Zugriff auf Ihre App gewähren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/%5BExample%5D%20Install%20Screen.png",
        alt: "[Beispiel] Installationsbildschirm"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Hinweis:"
      }), " Der Benutzer, der die App installiert, muss Zugriff auf alle angeforderten Bereiche haben. Wenn er nicht die erforderlichen Zugriffsrechte besitzt, schlägt die Installation fehl und er wird zu einer Fehlerseite weitergeleitet. Wenn ein Benutzer diese Seite für einen Berechtigungsfehler sieht, muss er einen Super-Admin bitten, die App zu installieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anwendung funktioniert in dieser Phase nicht. Sobald der Zugriff gewährt ist, sendet der HubSpot OAuth 2.0-Server eine Anfrage an den in der Autorisierung der Autorisierungs-URL definierten Calback-URI."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schritt 3: OAuth 2.0-Serverantwort verarbeiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn der Benutzer die Aufforderung zur Einwilligung von Schritt 2 abgeschlossen hat, sendet der OAuth 2.0-Server eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an den in Ihrer Authentifizierungs-URL angegebenen Weiterleitungs-URI. Wenn keine Fehler auftreten und der Benutzer die Zugriffsanfrage akzeptiert, wird die Anfrage mit einem angehängten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), "-Abfrageparameter an den Weiterleitungs-URI zurückgegeben. Wenn der Benutzer keinen Zugriff gewährt, wird keine Anfrage gesendet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "app.get('/oauth-callback', async (req, res) => {\n  if (req.query.code) {\n    // Handle the received code\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schritt 4: Autorisierungscode für Token austauschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Ihre App einen Autorisierungscode aus dem OAuth 2.0-Server empfangen hat, kann sie den Code für ein Zugriffs- und Aktualisierungstoken tauschen, indem eine im URL-Format codierte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage mit den unten aufgeführten Werten an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " gesendet wird. Weitere Informationen zu diesem Schritt erhalten Sie in diesem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "Referenzdokument"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Muss ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            }), " sein"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Client-ID Ihrer App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Client-Geheimnis Ihrer App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Weiterleitungs-URI vom Autorisieren Ihrer App durch den Benutzer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.beispiel.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Autorisierungscode, der vom OAuth 2.0-Server empfangen wurde"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5771f587-2fe7-40e8-8784-042fb4bc2c31"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'authorization_code',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  code: req.query.code\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Text der Token-Antwort besteht aus JSON-Daten mit folgendem Format:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Hinweis:"
      }), " Das Zugriffstoken läuft nach der Anzahl der Sekunden ab, die im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), "-Feld der Antwort angegeben ist. Aktuell sind dies 30 Minuten. Weitere Informationen zum Abrufen eines neuen Zugriffstokens finden Sie unter Aktualisieren von OAuth 2.0-Token."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwenden von OAuth 2.0-Token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald der Autorisierungscode-Prozess abgeschlossen ist, ist Ihre App autorisiert, Anfragen im Namen des Benutzers vorzunehmen. Stellen Sie dazu das Token als ein Bearer-Token im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), "-HTTP-Header bereit. Spezifische Details dazu finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "Referenzdokument"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "request.get(\n  'https://api.hubapi.com/contacts/v1/lists/all/contacts/all?count=1',\n  {\n    headers: {\n      Authorization: `Bearer ${ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Zugriffstoken spiegeln die von der App angeforderten Bereiche wider. Sie spiegeln nicht die Berechtigungen oder Einschränkungen dessen wider, was ein Benutzer in seinem HubSpot-Account tun kann. Wenn beispielsweise ein Benutzer die Berechtigung hat, nur eigene Kontakte anzuzeigen, aber eine Anfrage für den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), "-Bereich autorisiert, kann das resultierende Zugriffstoken alle Kontakte im Account anzeigen und nicht nur die, die dem autorisierenden Benutzer gehören."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aktualisieren von OAuth 2.0-Token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuth-Zugriffstoken laufen in regelmäßigen Abständen ab. So ist sichergestellt, dass Angreifer bei einem kompromittierten Token nur für eine kurze Zeit Zugriff haben. Die Gültigkeitsdauer des Tokens in Sekunden wird im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), "-Feld angegeben, wenn ein Autorisierungscode gegen ein Zugriffstoken getauscht wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ihre App kann das empfangene Aktualisierungstoken gegen ein neues Zugriffstoken tauschen, indem eine im URL-Format codierte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage mit den unten aufgeführten Werten an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " gesendet wird. Weitere Informationen zu diesem Schritt finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "Referenzdokumentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Muss ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            }), " sein"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Client-ID Ihrer App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Client-Geheimnis Ihrer App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Weiterleitungs-URI vom Autorisieren Ihrer App durch den Benutzer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.beispiel.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Aktualisierungstoken, dass bei der Autorisierung Ihrer App durch den Benutzer empfangen wurde"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b9443019-30fe-4df1-a67e-3d75cbd0f726"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'refresh_token',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  refresh_token: REFRESH_TOKEN\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Text der Token-Antwort besteht aus JSON-Daten mit folgendem Format:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Sample response\n{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das neue Zugriffstoken kann dann verwendet werden, um Aufrufe im Namen des Benutzers vorzunehmen. Wenn das neue Token abläuft, können Sie anhand der gleichen Schritte ein neues abrufen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Authentifizierungsmethoden in HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Arbeiten mit OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "Verwalten von Token"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}