"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358695;
const slug = exports.slug = 'guides/api/crm/understanding-the-crm';
const title = exports.title = 'API do CRM | Noções básicas sobre CRM';
const name = exports.name = 'vNext DP de documentos - Noções básicas sobre o CRM';
const metaDescription = exports.metaDescription = 'O CRM da HubSpot é um sistema para gerenciar relacionamentos e dados do cliente. Saiba sobre tipos de objeto de CRM, propriedades, associações, proprietários e envolvimentos. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "objetos",
  "label": "Objetos",
  "parent": null
}, {
  "depth": 1,
  "id": "relacionamentos-do-objeto",
  "label": "Relacionamentos do objeto",
  "parent": "objetos"
}, {
  "depth": 1,
  "id": "atividades-e-anexos",
  "label": "Atividades e anexos",
  "parent": "objetos"
}, {
  "depth": 1,
  "id": "sincroniza%C3%A7%C3%A3o-de-dados",
  "label": "Sincronização de dados",
  "parent": "objetos"
}, {
  "depth": 1,
  "id": "ids-do-tipo-de-objeto",
  "label": "IDs do tipo de objeto",
  "parent": "objetos"
}, {
  "depth": 0,
  "id": "a%C3%A7%C3%B5es-em-lote",
  "label": "Ações em lote",
  "parent": null
}, {
  "depth": 0,
  "id": "propriedades",
  "label": "Propriedades",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades-padr%C3%A3o",
  "label": "Propriedades padrão",
  "parent": "propriedades"
}, {
  "depth": 1,
  "id": "propriedades-personalizadas",
  "label": "Propriedades personalizadas",
  "parent": "propriedades"
}, {
  "depth": 1,
  "id": "propriet%C3%A1rios-de-registros",
  "label": "Proprietários de registros",
  "parent": "propriedades"
}, {
  "depth": 0,
  "id": "identificadores-exclusivos-e-ids-de-registro",
  "label": "Identificadores exclusivos e IDs de registro",
  "parent": null
}, {
  "depth": 1,
  "id": "identificadores-exclusivos-padr%C3%A3o",
  "label": "Identificadores exclusivos padrão",
  "parent": "identificadores-exclusivos-e-ids-de-registro"
}, {
  "depth": 1,
  "id": "criando-seus-pr%C3%B3prios-identificadores-exclusivos",
  "label": "Criando seus próprios identificadores exclusivos",
  "parent": "identificadores-exclusivos-e-ids-de-registro"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    em: "em",
    h3: "h3",
    img: "img",
    code: "code",
    pre: "pre"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Noções básicas do CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A base da sua conta da HubSpot é um banco de dados de seus relacionamentos comerciais e processos, chamados de CRM (Customer Relationship Management, gerenciamento do relacionamento com o cliente). Para gerenciar esses dados, as contas da HubSpot incluem objetos, que representam tipos de relacionamentos ou processos. Instâncias individuais de objetos, registros de chamadas, representam as entidades individuais sob cada tipo de objeto (por exemplo, John Smith é um contato). Para armazenar dados em cada registro, você usará propriedades (por exemplo, a propriedade de e-mail) e, para representar as relações entre entidades individuais, você poderá associar registros entre si (por exemplo, associar John Smith a uma empresa Smith & Co.)a Smith & Co.) Além disso, os registros do CRM também podem armazenar informações sobre interações por meio de engajamentos/atividades associados, como e-mails, chamadas e reuniões. Abaixo, saiba mais sobre objetos do CRM, registros, propriedades e funcionalidades adicionais. Para saber mais sobre como gerenciar seu banco de dados de CRM no HubSpot, confira a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/get-started/manage-your-crm-database",
        children: "Central de conhecimento da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A API do CRM fornece acesso a objetos, registros e atividades. A lista abaixo explica os objetos disponíveis no HubSpot. Cada um dos seguintes objetos possui uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "página de índice"
      }), " no HubSpot e todos podem ser associados uns aos outros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Contatos"
        }), ": armazenam informações sobre uma única pessoa. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/contacts",
          children: "Exibir pontos de extremidade de contatos"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Empresas"
        }), ": armazenam informações sobre uma empresa ou organização específica. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/companies",
          children: "Exibir pontos de extremidade de empresas"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Negócios"
        }), ": representam oportunidades de receita com um contato ou uma empresa. Eles são rastreados por meio das fases do pipeline, o que resulta na concretização ou na perda do negócio. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/deals",
          children: "Exibir pontos de extremidade de negócios"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Tickets"
        }), ": representam solicitações de ajuda ou suporte feitas por clientes. Eles são rastreados por meio do status do pipeline, culminando no fechamento do ticket. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/tickets",
          children: "Exibir pontos de extremidade de tickets"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
          children: "Chamadas"
        }), ": armazena informações sobre chamadas com contatos, mas também podem ser associadas a outros objetos como um engajamento. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/calls",
          children: "Exibir pontos de extremidade de chamadas"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-quotes",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Orçamentos"
          })
        }), ": representam as informações de preços compartilhadas com potenciais compradores. Os orçamentos podem ser associados a contatos, empresas, negócios e itens de linha. Veja ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/commerce/quotes",
          children: "pontos de extremidade de orçamentos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "Objetos Personalizados"
        }), " (somente ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "): crie um objeto personalizado para armazenar qualquer tipo de dados no HubSpot, especialmente dados que não se encaixam nos objetos padrão listados acima. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "Exibir pontos de extremidade de objetos personalizados"
        }), "Os seguintes objetos não têm páginas de índice dentro do HubSpot, mas podem ser associados a alguns outros objetos:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
          children: "Atividades/engajamentos"
        }), " (chamadas, e-mails, reuniões, observações, tarefas, SMS, LinkedIn, WhatsApp, correio): representam interações associadas aos seus registros. Você pode associar atividades a contatos, empresas, negócios, tickets e objetos personalizados. Exibir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/engagement-details",
          children: "pontos de extremidade de engajamento."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
          children: "Produtos"
        }), ": representam mercadorias ou serviços para venda. Os produtos não podem ser associados a outros objetos do CRM, mas você pode criar itens de linha com base em produtos e associá-los a negócios e orçamentos. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/products",
          children: "Exibir pontos de extremidade de produtos"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
          children: "Itens de linha"
        }), ": representam produtos e serviços individuais vendidos em um negócio. Os itens de linha podem ser criados de produtos existentes na sua biblioteca de produtos ou como itens de linha independentes. Esses itens de linha independentes não serão adicionados à biblioteca de produtos. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/line-items",
          children: "Exibir os pontos de extremidade dos itens de linha"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Envios de feedback"
          })
        }), ": armazenam as informações enviadas em uma pesquisa de feedback. Os envios de feedback são associados aos registros de contatos. Exibir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/feedback-submissions",
          children: "pontos de extremidade de envio de feedback"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events",
          children: "Eventos de marketing"
        }), ": representam eventos relacionados aos seus esforços de marketing, incluindo especificamente eventos de integrações conectadas. Você pode especificar se um contato compareceu, se registrou ou cancelou a participação em um evento de marketing. Exibir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/marketing/marketing-events",
          children: "pontos de extremidade de eventos de marketing"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "Faturas"
        }), ": representam as faturas que você envia para as vendas realizadas. As faturas podem ser associadas a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "contatos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "empresas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "negócios"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "itens de linha"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "descontos"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " impostos"
          })
        }), " e**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " taxas"
        }), "**."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "Pagamentos"
        }), ": os pagamentos feitos pelos compradores por meio de faturas, links de pagamento e orçamentos. Os pagamentos podem ser associados a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: " contatos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "empresas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "negócios"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "faturas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "orçamentos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "itens de linha"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "assinaturas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "descontos"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " impostos"
          })
        }), " e**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " taxas"
        }), ".**"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "Assinaturas"
        }), ": pagamentos recorrentes agendados por meio de links de pagamento e orçamentos. As faturas pode ser associadas a", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: " contatos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "empresas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "negócios"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "orçamentos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "itens de linha"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "pagamentos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "descontos"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " impostos"
          })
        }), " e**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " taxas"
        }), "**."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-details",
          children: "Usuários"
        }), ": representam os usuários na sua conta da HubSpot. Os usuários não podem ser associados a outros objetos de CRM, mas podem ser recuperados e atualizados por meio da API de usuários. Você também pode adicionar usuários a uma conta com a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "API de provisionamento de usuários"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Relacionamentos do objeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No HubSpot, é possível mostrar como os objetos estão relacionados entre si por meio de registros associados. Por exemplo, você pode associar vários contatos a uma empresa e, em seguida, associar a empresa e os respectivos contatos a um negócio. Todas as contas da HubSpot têm contatos, empresas, negócios, tickets e atividades, que podem ser associados entre si, conforme o modelo abaixo. Se você tiver acesso a uma conta da HubSpot, poderá revisar as relações entre objetos exclusivos da sua conta, acessando", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-model-of-your-crm-object-and-activity-relationships",
        children: " a ferramenta de modelo de dados."
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/data-model-overview-updated.gif",
        alt: "data-model-overview-updated"
      }), "Dependendo da sua assinatura, você pode descrever a relação específica entre os registros usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "rótulos de associação"
      }), ", e sua conta pode ter objetos personalizados adicionais, que podem ser associados a outros objetos padrão. Outros objetos, como produtos e itens de linha, só podem ser associados a certos objetos. Saiba mais sobre estes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-objects",
        children: "objetos adicionais e suas associações"
      }), ".Saiba mais sobre relações de objeto e gerenciamento de associações usando os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "pontos de extremidade de associações"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atividades e anexos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Os engajamentos"
      }), ", também chamados de atividades, armazenam dados de interações com registros. Por exemplo, se você fizer uma chamada para um prospect, poderá registrar uma chamada no registro do contato e também associar a chamada à empresa associada. Atividades possíveis incluem observações, tarefas, reuniões, e-mails, chamadas, correio postal, SMS, mensagens do LinkedIn e mensagens do WhatsApp. Você também pode armazenar anexos em registros para monitorar os arquivos pertinentes. Geralmente, estão relacionados a engajamentos. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "APIs de engajamentos."
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sincronização de dados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A sincronização dos dados de envolvimento não é obrigatória para sincronizar dados do objeto. Como é possível associar um objeto a vários engajamentos, também é importante considerar os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "limites de API"
      }), " antes da sincronização. No entanto, talvez você queira sincronizar engajamentos em vez de propriedades quando uma integração for precursora de uma migração completa. Nesse caso, a sincronização de engajamentos nos dois sistemas garantirá que todos os usuários tenham os dados necessários durante a transição. Por exemplo, quando uma equipe de desenvolvimento de negócios que trabalha no HubSpot lida com um representante de vendas interno que trabalha em outro CRM, é importante sincronizar os envolvimentos para que as duas equipes tenham o contexto necessário ao fechamento de uma venda."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "IDs do tipo de objeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao usar determinadas APIs, você precisará usar o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), ". Abaixo estão os valores de ID para cada objeto ou atividade:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contatos"
        }), ":", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Empresas"
        }), ":", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-2"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Negócios"
        }), ":", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-3"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tickets:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-5"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objetos personalizados"
        }), ": para encontrar o valor de ID do seu objeto personalizado, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/schemas"
        }), ". O valor será semelhante a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2-3453932"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Chamadas:"
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-48"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-mails"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-49"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reuniões"
        }), ":", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-47"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observações"
        }), ":", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-46"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tarefas"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-27"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Produtos"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-7"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Faturas:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-52"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Itens de linha"
        }), ":", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-8"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Pagamentos: **", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-101"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Orçamentos: **", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-14"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Assinaturas:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-69"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Comunicações"
        }), " (SMS, mensagens do LinkedIn, WhatsApp): ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-18"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Correio postal"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-116"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eventos de marketing"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-54"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Envios de feedback"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-19"
        }), "embora você sempre possa usar o valor de ID numérico, em alguns casos, também poderá usar o nome do objeto para contatos, empresas, negócios, tickets ou observações. Por exemplo:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao iniciar uma importação com a", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/imports",
          children: " API de importações"
        }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " especifica a qual objeto pertencem os dados em seu arquivo. Para importar dados de contatos, seu valor para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao usar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de associações"
        }), ", os valores ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), " especificam os objetos e a direção da associação. Para exibir os tipos de associação de contatos para empresas, seu URL de solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/contact/company/labels"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/0-1/0-2/labels"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ações em lote"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada objeto fornece pontos de extremidade em lote que permitem criar, ler, atualizar e arquivar vários registros de objeto em uma única solicitação. Os pontos de extremidade em lote podem ter no máximo 100 registros por chamada. Por exemplo, a seguinte solicitação atualizaria em lote dois registros de contato identificados pelo respetivo ID.", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST``/crm/v3/objects/contacts/batch/update"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"inputs\": [\n    {\n      \"id\": \"609051\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    },\n    {\n      \"id\": \"569751\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ações de criação em lote, você pode habilitar erros de vários status que informam quais registros foram criados com êxito e quais não foram. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/other-resources/error-handling#multi-status-errors",
        children: "configurar a manipulação de erros de vários status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As informações sobre registros são armazenadas em campos chamados de propriedades, que são então organizados em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "grupos"
      }), ". O HubSpot fornece um conjunto de propriedades padrão para cada objeto. Além de cada propriedade padrão do objeto, você pode armazenar dados personalizados ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "criando propriedades personalizadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os objetos de CRM são definidos por um ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "tipo"
      }), " principal e um conjunto de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "propriedades"
      }), ". Cada tipo tem um conjunto exclusivo de propriedades padrão, representado por um mapeamento de pares nome-valor. Saiba mais sobre propriedades padrão para diferentes objetos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contatos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Empresas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Negócios"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: "Envios de feedback"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties",
          children: "Faturas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Assinaturas"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades personalizadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Crie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "propriedades personalizadas"
      }), " para armazenar informações especializadas de um objeto. É possível gerenciar propriedades personalizadas usando os pontos de extremidade de propriedades de objetos de CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Proprietários de registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atribuir usuários do HubSpot como", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: " proprietários"
      }), " de registros. Qualquer usuário do HubSpot com acesso a um objeto pode ser atribuído como proprietário. Além disso, é possível atribuir vários proprietários a um objeto criando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/how-can-i-create-a-custom-owner-property",
        children: "propriedade personalizada"
      }), " para esse propósito. Os proprietários só podem ser criados no HubSpot, mas você pode usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "ponto de extremidade de proprietários"
      }), " para obter os detalhes de identificação deles, inclusive IDs e endereços de e-mail. Esses dados podem ser atribuídos aos registros de CRM no HubSpot ou por meio de chamadas de API de alteração de propriedade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identificadores exclusivos e IDs de registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um identificador exclusivo é um valor que diferencia um registro em um banco de dados de outro, mesmo que eles tenham informações idênticas. Por exemplo, no banco de dados de um banco pode haver registros de duas pessoas com o nome John Smith. Para evitar o envio acidental de dinheiro para o John Smith errado, cada registro recebe um número como seu ID exclusivo. No HubSpot, você usará esses identificadores exclusivos para enviar seus dados para os registros corretos e realizar desduplicação. Saiba mais sobre como a HubSpot lida com a desduplicação na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/deduplication-of-contacts-companies-deals-tickets",
        children: "central de conhecimento"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Identificadores exclusivos padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando um registro é criado no HubSpot, um ID exclusivo é gerado automaticamente e deve ser tratado como uma string. Esses IDs são exclusivos apenas dentro do tipo de objeto. Então, pode haver um contato e uma empresa com o mesmo ID. Para contatos e empresas, há identificadores exclusivos adicionais, incluindo o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " de um contato e o nome de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " de uma empresa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criando seus próprios identificadores exclusivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em muitos casos, você pode usar o ID do registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), " gerado pelo HubSpot para direcionar a lógica de sua integração. No entanto, seus dados podem exigir outras propriedades com valores exclusivos ou pode haver momentos em que o ID do registro não pode ser usado ou complica a lógica de integração do seu aplicativo. Nesses casos, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "criar uma propriedade de identificador exclusivo personalizada por meio da API de Propriedades."
      }), "Depois de criar uma propriedade de ID exclusivo personalizada, você poderá usá-la em chamadas de API para identificar e atualizar registros específicos da mesma forma que usa ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " para contatos ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " para empresas. Por exemplo, para recuperar um negócio com base no seu valor para uma propriedade de ID exclusivo personalizada, o URL da solicitação poderá ser semelhante a:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;