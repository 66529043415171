"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694125;
const slug = exports.slug = 'guides/cms/content/themes/overview';
const title = exports.title = 'Resumen del tema';
const name = exports.name = 'Resumen del tema';
const metaDescription = exports.metaDescription = 'Construir temas de CMS de HubSpot que permitan a los desarrolladores construir sistemas de diseño para que los creadores de contenido trabajen dentro de ellos. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/themes.gif';
const featuredImageAltText = exports.featuredImageAltText = 'Temas de HubSpot';
const toc = exports.toc = [{
  "depth": 0,
  "id": "temas-como-un-paquete",
  "label": "Temas como un paquete",
  "parent": null
}, {
  "depth": 1,
  "id": "crear-p%C3%A1ginas",
  "label": "Crear páginas",
  "parent": "temas-como-un-paquete"
}, {
  "depth": 1,
  "id": "campos-tem%C3%A1ticos",
  "label": "Campos temáticos",
  "parent": "temas-como-un-paquete"
}, {
  "depth": 1,
  "id": "m%C3%B3dulos-de-tema",
  "label": "Módulos de tema",
  "parent": "temas-como-un-paquete"
}, {
  "depth": 0,
  "id": "estructura-de-los-archivos-del-tema",
  "label": "Estructura de los archivos del tema",
  "parent": null
}, {
  "depth": 1,
  "id": "theme.json",
  "label": "theme.json",
  "parent": "estructura-de-los-archivos-del-tema"
}, {
  "depth": 1,
  "id": "fields.json",
  "label": "fields.json",
  "parent": "estructura-de-los-archivos-del-tema"
}, {
  "depth": 2,
  "id": "utilizar-los-valores-de-los-campos-tem%C3%A1ticos",
  "label": "Utilizar los valores de los campos temáticos",
  "parent": "estructura-de-los-archivos-del-tema"
}, {
  "depth": 0,
  "id": "vista-preliminar-de-temas",
  "label": "Vista preliminar de temas",
  "parent": null
}, {
  "depth": 1,
  "id": "modo-de-prueba",
  "label": "Modo de prueba",
  "parent": "vista-preliminar-de-temas"
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h4: "h4",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de temas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un tema es una colección portátil y contenida de materiales para desarrolladores diseñada para permitir una experiencia de edición de contenido flexible. Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "construir temas localmente usando la CLI de HubSpot"
      }), " utilizando las herramientas, tecnologías y workflows que prefieras. Los temas y todos sus archivos también son portables entre entornos y cuentas. Para ver un video de cómo empezar a desarrollar temas, ve el video de HubSpot Academy a continuación:"]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172844605010"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas como un paquete"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los temas son un paquete que se extiende por toda la aplicación de HubSpot en varios lugares para permitir una experiencia eficiente de creación de contenido. Los desarrolladores pueden utilizar los temas para construir un sistema de diseño con el que los creadores de contenido puedan trabajar. Cualquier cantidad de flexibilidad, o barandillas pueden ser construidas en un tema para satisfacer las necesidades de tu negocio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear páginas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando los creadores de contenido comienzan a crear nuevas páginas, se les pide que empiecen por seleccionar el tema desde el que van a crear una página, seguido de la selección de la plantilla que van a utilizar dentro del tema."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20selection.gif",
        alt: "Selección de temas dentro de HubSpot"
      }), "La imagen de vista preliminar del tema, así como otras configuraciones de un tema, se definen en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#theme-json",
        children: "archivo theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Campos temáticos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los temas permiten a los desarrolladores crear un conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "campos temáticos"
      }), ", similares a los campos de los módulos, que permiten a los creadores de contenido ajustar varios mandos y diales diseñados por un desarrollador para permitir el control estilístico global de un sitio web sin tener que editar el CSS. Los desarrolladores ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#using-theme-field-values",
        children: "utilizan HubL para acceder a los valores de los campos temáticos"
      }), " en todo su CSS. Los creadores de contenido utilizan el Editor de temas para modificar los campos del tema, previsualizar esos cambios con las plantillas existentes dentro de un tema y publicar sus cambios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/themes.gif",
        alt: "temas"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los campos de los temas son dictados por el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "archivo fields.json de un tema"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al editar un tema en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#test-mode",
        children: "modo de prueba"
      }), ", también puedes copiar la configuración JSON del tema. Esto te permite pegar cualquier actualización en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " local del tema.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode.png",
        alt: "copy-theme-settings-test-mode"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos de tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los módulos de un tema deben estar diseñados específicamente para su uso dentro de las plantillas de ese tema. El editor de contenido destacará estos módulos temáticos, lo que hará que los creadores de contenido puedan agregar de forma rápida y sencilla módulos a las páginas que están construyendo y que están diseñados para funcionar bien en el contexto de esa página. Los módulos predeterminados y el resto de módulos de tu cuenta HubSpot seguirán estando disponibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20modules.png",
        alt: "Módulos temáticos dentro de un tema"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al desarrollar un tema, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/hide-modules-and-sections",
        children: "ocultar módulos y secciones del editor de páginas"
      }), " para crear una experiencia de creación de contenido más ágil."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estructura de los archivos del tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un tema es un único directorio de archivos. Puedes incluir archivos HTML, CSS y Javascript, módulos y archivos adicionales que pueden organizarse de cualquier manera dentro de los subdirectorios de la carpeta del tema principal. Se necesitan dos archivos JSON para construir un tema: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ". Estos archivos deben incluirse en la carpeta raíz del tema."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para comenzar con un ejemplo, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "plantilla de CMS de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hs-fs/hubfs/theme%20file%20structure.png?width=599&height=452&name=theme%20file%20structure.png",
        alt: "estructura de los archivos del tema"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En este momento los archivos .json sólo pueden ser creados y cargados a una cuenta de HubSpot a través de las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "herramientas de desarrollo local"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "theme.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " contiene la metainformación para tu directorio de temas, como la etiqueta legible de los temas, su captura de pantalla de vista preliminar y varias configuraciones de cómo debe comportarse el tema. Tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " tendrá un aspecto similar al siguiente:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La etiqueta legible del tema, utilizada en varios lugares en los que el tema se muestra en toda la aplicación HubSpot, como la pantalla de selección de plantillas y el editor de temas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "preview_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una ruta relativa a un archivo de plantilla en el tema que debería ser la plantilla predeterminada, utilizada cuando se previsualiza un tema en el editor de temas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshot_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una ruta relativa a un archivo de imagen que se utiliza para dar una instantánea del aspecto del tema en varios lugares donde se produce la selección del tema, como en la pantalla de selección de plantillas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enable_domain_stylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Activar o desactivar las hojas de estilo adjuntas a los dominios en la configuración del sitio web para que se incluyan en las plantillas del tema. El valor predeterminado es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número entero de la versión que admite ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "."
            }), " versiones."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto para proporcionar información sobre ti como proveedor del tema.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "El nombre del proveedor. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "La dirección de correo electrónico de soporte del proveedor. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "El sitio web del proveedor. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "documentation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enlace a la documentación del tema."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "example_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enlace de ejemplo del tema activo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "license"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://spdx.org/licenses/",
              children: "identificador SPDX"
            }), " válido o la ruta relativa a la licencia dentro del tema. Esta licencia dicta el uso y la modificación que permite el creador del tema. Útil cuando se envía al mercado."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Booleano que determina si un tema se muestra en la página del creador de contenido para su selección. El valor predeterminado es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " controla los campos disponibles y los grupos de campos en el editor de temas, incluidos los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "campos de estilo"
      }), ". Los campos que incluyas dependerán del control que quieras que tengan los creadores de contenido en el editor de páginas. El número de campos disponibles para los temas es más limitado que para los módulos, ya que los campos del tema son mejores para las opciones de estilo, mientras que el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "contenido global"
      }), " es mejor para el contenido del tema."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, en lugar de agregar un campo de texto al ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field.json"
      }), " del tema para el eslogan de tu sitio, deberías agregarlo como un módulo global para que los creadores de contenido puedan actualizar el eslogan desde el editor de la página en lugar del editor del tema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos que están disponibles para su uso en los temas son:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#boolean",
          children: "Boolean"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Border"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#choice",
          children: "Choice"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Color"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Font"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#image",
          children: "Image"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#number",
          children: "Number"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Spacing"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener una documentación completa sobre las posibles opciones de los campos temáticos, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "documentación sobre módulos y campos temáticos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Utilizar los valores de los campos temáticos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para acceder a los valores del campo, utiliza la notación de puntos y antepón la ruta al valor en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " con el tema. Puedes utilizar el valor de los campos temáticos en tus hojas de estilo utilizando una sintaxis como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ theme.path.to.value }}"
      }), ". Por ejemplo, el campo de la fuente que se describe a continuación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"type\": \"group\",\n    \"name\": \"typography\",\n    \"label\": \"Typography\",\n    \"children\": [\n      {\n        \"type\": \"font\",\n        \"name\": \"h1_font\",\n        \"label\": \"Heading 1\",\n        \"load_external_fonts\": true,\n        \"default\": {\n          \"color\": \"#000\",\n          \"font\": \"Merriweather\",\n          \"font_set\": \"GOOGLE\",\n          \"variant\": \"700\",\n          \"size\": \"48\"\n        }\n      }\n    ]\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se debe referenciar en el CSS con:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "h1 {\n font-size: {{ theme.typography.h1_font.size }}px;\n font-family: {{ theme.typography.h1_font.font }};\n color: {{ theme.typography.h1_font.color }};\n text-decoration: {{ theme.typography.h1_font.styles.text-decoration }};\n font-style: {{ theme.typography.h1_font.styles.font-style }};\n font-weight: {{ theme.typography.h1_font.styles.font-weight }};\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vista preliminar de temas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para los desarrolladores, a veces es necesario poder probar que los campos de tu tema funcionan correctamente, pero no quieren afectar a las páginas reales. Ahí es donde entra el modo de prueba de temas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modo de prueba"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["El modo de prueba te ofrece un entorno seguro para poder jugar con los campos de tu tema y asegurarte de que funcionan como espera. La interfaz tiene el mismo aspecto que la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings#edit-theme-settings",
              children: "vista preliminar/el editor de temas que pueden ver los creadores de contenido"
            }), ", pero puedes estar seguro de que no estás cambiando la configuración real de tu sitio web. Para protegerte contra actualizaciones accidentales de la configuración del tema, la publicación estará bloqueada en el modo de prueba. Puedes saber si estás en modo de prueba cuando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?testmode=true"
            }), " aparece en la barra de direcciones y un ícono de modo de prueba se muestra en el encabezado del editor de temas."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://f.hubspotusercontent00.net/hubfs/53/theme-test-mode.png",
            alt: "Modo de prueba/vista preliminar de temas"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay dos maneras de habilitar el modo de prueba:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Para activar el modo de prueba del administrador de diseño:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["En el administrador de diseño, selecciona tu ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "tema"
              }), " en el Finder."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["En la parte superior de la barra lateral izquierda, haz clic en el botón ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Vista previa"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode-design-manager%20(1).png",
                alt: "copy-theme-settings-test-mode-design-manager (1)"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Para activar el modo de prueba desde el editor de páginas:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["En el editor de páginas, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Diseño"
              }), " en la barra lateral izquierda y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Editar configuración del tema"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/page-editor-edit-theme.png",
                alt: "page-editor-edit-theme"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Agrega ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "?testmode=true"
              }), " a la URL, y luego presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Intro"
              }), ". Estarás en modo de prueba."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Otro método es abrir la configuración del tema desde el editor de la página. Luego, una vez dentro, agrega el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?testmode=true"
      }), " a la URL en la barra de direcciones."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Primeros pasos para usar temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Cómo agregar capacidades temáticas a los sitios existentes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "Plantilla de temas de HubSpot"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}