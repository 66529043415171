"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125432;
const slug = exports.slug = 'reference/cms/modules/export-to-template-context';
const title = exports.title = 'Paramètre export_to_template_context';
const name = exports.name = 'EMEA (FR) export_to_template_context';
const metaDescription = exports.metaDescription = "export_to_template_context est un paramètre qui met les paramètres d'un module à disposition de l'environnement du modèle sans restituer réellement le module. Ce paramètre peut être utilisé avec toutes les balises de module HubL. La balise widget_data est utilisée pour récupérer ces paramètres, les stocker dans des variables et/ou les intégrer dans la logique de votre modèle.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "utilisation-dans-des-modules-personnalis%C3%A9s",
  "label": "Utilisation dans des modules personnalisés",
  "parent": null
}, {
  "depth": 0,
  "id": "images-d-arri%C3%A8re-plan-s%C3%A9lectionnables-par-l-utilisateur",
  "label": "Images d'arrière-plan sélectionnables par l'utilisateur",
  "parent": null
}, {
  "depth": 0,
  "id": "champ-de-choix-pour-restituer-le-balisage-pr%C3%A9d%C3%A9fini",
  "label": "Champ de choix pour restituer le balisage prédéfini",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9ration-des-param%C3%A8tres-%C3%A0-partir-de-modules-d%C3%A9j%C3%A0-restitu%C3%A9s-sur-le-mod%C3%A8le",
  "label": "Récupération des paramètres à partir de modules déjà restitués sur le modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "impression-des-informations-du-module-hubl-sur-la-liste-des-blogs",
  "label": "Impression des informations du module HubL sur la liste des blogs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      pre: "pre",
      h2: "h2",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Paramètre export_to_template_context"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " est un paramètre qui met les paramètres d'une balise HubL à la disposition de l'environnement du modèle sans restituer réellement la balise HubL. Ce paramètre peut être utilisé avec toutes les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "balises HubL"
      }), ". Le dictionnaire ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " est utilisé pour récupérer ces paramètres, les stocker dans des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "variables"
      }), " et/ou les intégrer dans la logique de votre modèle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En rendant les paramètres d'une balise HubL disponibles dans l'environnement du modèle sans restituer réellement la balise HubL, vous pouvez permettre aux utilisateurs de prendre des décisions dans l'éditeur de contenu qui affectent la restitution du modèle. Par exemple, supposons que vous voulez restituer un certain bloc de code uniquement lorsque l'utilisateur donne une valeur à un champ. Cela devient possible avec ce paramètre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devez d'abord ajouter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " à la balise HubL. Vous devez alors utiliser un paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data.module.parameter_you_want_to_retreive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"job_title\" path=\"@hubspot/text\", label=\"Enter a Job Title\", value=\"Chief Morale Officer\", export_to_template_context=True %}\n{# Makes the parameters available to be stored and used in template logic #}\n\n{{ widget_data.job_title.body.value }}\n{# Prints the value of the HubL tag but can also be used in template logic #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous quelques applications de ce concept."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation dans des modules personnalisés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " n'est pas pris en charge dans les modules personnalisés, car il n'a pas d'utilité réelle pour eux. Vous n'avez pas besoin d'utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " pour obtenir la valeur d'un module dans un modèle, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "car vous pouvez déjà y accéder"
      }), ". Si vous devez masquer visuellement le résultat du module, vous pouvez créer le module pour qu'il ne produise rien ou inclure un champ booléen qui active ou désactive le rendu du module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Images d'arrière-plan sélectionnables par l'utilisateur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans cet exemple, une balise HubL d'image est créée, mais elle est ensuite exportée vers le contexte du modèle plutôt que d'être restituée. Le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " est récupéré avec la balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " et restitué comme source d'une image d'arrière-plan dans une balise de style."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"background_image\" path=\"@hubspot/image\" label='Select a background image',\nsrc='http://cdn2.hubspot.net/hub/428357/file-2117441560-jpg/img/dev-bg-compressed.jpg',\nexport_to_template_context=True %}\n\n\n<!--Sample markup -->\n<div class=\"bg-image-section\" style=\"background:url('{{ widget_data.background_image.src }}'); background-size:cover; min-height: 500px;\">\n    <h1>Supercharge your app with HubSpot</h1>\n    <h3>Build powerful integrations for a global community of users</h3>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bien qu'il soit possible de le faire dans des modèles codés, il est généralement préférable de créer un module personnalisé afin d'offrir aux utilisateurs de l'éditeur de page la meilleure expérience possible. Les balises HubL comme celle-ci apparaissent comme des champs individuels, alors que vous pouvez avoir plusieurs champs associés. En utilisant un module personnalisé, tous ses champs s'affichent groupés dans l'éditeur de page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Champ de choix pour restituer le balisage prédéfini"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'exemple suivant utilise le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " en association avec un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#choice",
        children: "module de choix"
      }), " pour modifier un message de bannière sur une page Carrières. L'utilisateur sélectionne un département via l'interface utilisateur et l'intitulé change sans que l'utilisateur ait à modifier le contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"department\" path=\"@hubspot/choice\", label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette même fonctionnalité peut être reproduite en utilisant un champ de choix dans un module personnalisé. L'interface utilisateur du module personnalisé facilite le choix d'options comportant à la fois une valeur et un libellé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupération des paramètres à partir de modules déjà restitués sur le modèle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez récupérer un paramètre d'un module ou d'une balise dont le rendu est déjà en cours sur une page, vous pouvez accéder au module dans un dictionnaire intitulé ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgets"
      }), ". Le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " n'est pas nécessaire. La syntaxe est la suivante :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// HubL\n{{ content.widgets.name_of_module.body.parameter }}\n\n{{ content.widgets.my_text.body.value }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " La méthode ci-dessus ne prend pas en charge la récupération de valeurs à partir de champs dans des modules globaux, car ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content.widgets"
        }), " n'accédera pas aux modules globaux."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Étant donné que les différents champs stockent les données dans des formats différents, il est souvent pratique d'utiliser les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "informations du développeur"
      }), " pour savoir comment accéder aux données spécifiques que vous souhaitez afficher."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Impression des informations du module HubL sur la liste des blogs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si les modèles de blog sont généralement utilisés pour les blogs, ils peuvent également être réutilisés pour créer divers autres types de listes. Vous pouvez utiliser les techniques décrites ci-dessus pour y parvenir."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, vous pouvez créer une liste de presse concernant votre entreprise, mais plutôt que de créer un lien vers les articles, vous souhaitez que la liste renvoie à une autre page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez voir ce concept en action sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/projects",
        children: "academy.hubspot.com/projects"
      }), ". La page de liste des projets est un modèle de liste de blog, mais chaque article est lié à une page HubSpot ordinaire. Le créateur de contenu spécifie le lien de destination dans l'éditeur."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'en-tête du code de l'article de blog individuel, vous devez définir un champ de texte. Si vous ne voulez pas que le texte soit restitué sur l'article, vous devez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"custom_blog_link\" path=\"@hubspot/text\", label=\"Link to external press item\", export_to_template_context=True %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce champ de texte est modifiable dans chaque article de blog. Ensuite, nous devons définir un lien dans notre liste. Toutefois, comme les données du widget n'existent que dans le contexte de l'article, nous devons utiliser une syntaxe différente pour récupérer les données du widget afin de renseigner le lien. Dans ce cas, nous utiliserons ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.widgets.custom_blog_link.body.value"
      }), ". Bien que le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " ne soit pas accessible à la liste de blog, la valeur de ce champ est toujours stockée dans le contexte des widgets du contenu individuel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une boucle basique de liste de blog qui restitue ce lien personnalisé avec chaque article est présentée ci-dessous. Si vous utilisez cette technique, veillez à ajouter le sous-répertoire créé automatiquement pour chaque article de blog à votre fichier robots.txt afin d'éviter que ces articles vides ne soient recherchés par Google et les autres robots d'exploration."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n<a href=\"{{ content.widgets.custom_blog_link.body.value }}\">\n    Click here to see this press feature!\n</a>\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}