"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710047;
const slug = exports.slug = 'reference/cms/hubl/overview';
const title = exports.title = 'HubL Syntax';
const name = exports.name = 'HubL syntax overview';
const metaDescription = exports.metaDescription = 'HubL is the jinja inspired templating language used for building templates and modules on the HubSpot CMS. If statements, for loops, includes, etc.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "types-of-delimiters",
  "label": "Types of delimiters",
  "parent": null
}, {
  "depth": 1,
  "id": "statements",
  "label": "Statements",
  "parent": "types-of-delimiters"
}, {
  "depth": 1,
  "id": "expressions",
  "label": "Expressions",
  "parent": "types-of-delimiters"
}, {
  "depth": 2,
  "id": "do-tag",
  "label": "Do tag",
  "parent": "types-of-delimiters"
}, {
  "depth": 1,
  "id": "comments",
  "label": "Comments",
  "parent": "types-of-delimiters"
}, {
  "depth": 0,
  "id": "modules",
  "label": "Modules",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-and-macros",
  "label": "Variables and macros",
  "parent": null
}, {
  "depth": 0,
  "id": "filters-and-functions",
  "label": "Filters and functions",
  "parent": null
}, {
  "depth": 0,
  "id": "if-statements",
  "label": "If statements",
  "parent": null
}, {
  "depth": 0,
  "id": "for-loops",
  "label": "For loops",
  "parent": null
}, {
  "depth": 0,
  "id": "other-hubl-features",
  "label": "Other HubL features",
  "parent": null
}, {
  "depth": 1,
  "id": "escaping-hubl-delimiters",
  "label": "Escaping HubL delimiters",
  "parent": "other-hubl-features"
}, {
  "depth": 1,
  "id": "including-files-in-files",
  "label": "Including files in files",
  "parent": "other-hubl-features"
}, {
  "depth": 1,
  "id": "blocks-and-extends",
  "label": "Blocks and extends",
  "parent": "other-hubl-features"
}, {
  "depth": 1,
  "id": "copy-section-hubl",
  "label": "Copy section HubL",
  "parent": "other-hubl-features"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubL syntax overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot’s CMS uses the HubSpot Markup Language, referred to as HubL (pronounced “Hubble”). HubL is HubSpot’s extension of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", a templating engine based on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jinja.palletsprojects.com/en/latest/",
        children: "Jinja"
      }), ". HubL uses a fair amount of markup that is unique to HubSpot and does not support all features of Jinja."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This article will take you through the basics of HubL's features and syntax."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types of delimiters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Similar to other commonly used templating languages such as PHP, HubL can be mixed into your HTML in coded templates files or HubL template modules. In order to differentiate, where your HubL starts and ends, you will need to learn a few key symbols that act as delimiters."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% %} - statement delimiters\n{{ }} - expression delimiters\n{# #} - comment delimiters\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Be mindful of nesting comments in your code, as it can result in the trailing comment tag to render as text."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Statements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL statements are used to create editable modules, define conditional template logic, set up for loops, define variables, and more. Statements are delimited by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%"
      }), ". They do not print anything to the page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Expressions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Expressions print values stored in the context of the template. Expressions are delimited by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{"
      }), ". For example, a variable must be defined as a statement, but then a HubL expression would be used to print the variable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Do tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The 'do' tag works exactly like the regular statements ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% ... %}"
      }), "; This can be used to modify lists and dictionaries."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note: When adding to arrays, you want to use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#append",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".append()"
        })
      }), " and when adding to objects, you want to use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update()"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "# Arrays\n{% set navigation = [\"Home\", \"About\"] %}\n{% do navigation.append(\"Contact Us\") %}\n{{navigation}}\n\n# Objects\n{% set book = {\"name\" : \"Rocking HubL\", \"author\" : \"John Smith\" }%}\n{% do book.update({\"ebook\" : \"yes\"}) %}\n{{book}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "# Arrays [Home, About, Contact Us] # Objects {name=Rocking HubL, author=John\nSmith, ebook=yes}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comments"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The final type of delimiter that you may encounter or decide to employ while developing with HubL, is a HubL comment. Comments are defined by a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{#"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modules"
      }), " are dynamic areas of a template that can be customized by the end user in the content editor. For example, if you were coding a template file from scratch, you would add modules to templates with HubL tags, to give your content creators the ability to edit areas of the page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Module tags are made up of the following components:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Type of module:"
        }), " specifies which module to render. Please refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "HubL Supported Tags"
        }), " page for a listing of the different types of modules available."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "A unique name for that module:"
        }), " gives the module a unique identity in the context of the template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Path:"
        }), " depending on the tag, defines the location of where the module is in the design manager. The path for HubSpot default modules should always start with @hubspot/ followed by the type of module. See the example below and the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "using modules in templates page"
        }), " for more."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Parameters:"
        }), " optionally specify additional module information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"unique_module_name\",\n  path=\"@hubspot/text\",\n  label=\"Single Text Line\",\n  value=\"This is a single text line\"\n%}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text widget-type-text\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"module\"\n  id=\"hs_cos_wrapper_text\"\n  style=\"\"\n>\n  <span\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"text\"\n    id=\"hs_cos_wrapper_text_\"\n    style=\"\"\n    >This is a single text line</span\n  >\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example above is a text module with the label and value parameters defined. The label defines the help text in the editor and value sets the default text for that module. See the sample gif below for how this looks inside of the editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/module-label-value-min.gif",
        alt: "module-label-value-min"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "learn more about using modules in templates, here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables and macros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL includes many ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "predefined HubSpot variables"
      }), " that print their helpful values from the app. In addition, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "define your own variables in a template"
      }), ". In the following example, a variable named ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryColor"
      }), " is defined in a statement and then printed with a HubL expression. This example mixes the HubL variable with CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set primaryColor = '#F7761F' %} {# defines variable and assigns HEX color #}\n\na {\n  color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL macros allow you to print multiple statements with a dynamic value. This technique proves useful when you find yourself writing the same basic code blocks over and over, but only need to change certain values. In the following example, a macro is used to print a CSS3 transition property that includes all the vendor prefixes. You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "learn more about macros, here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n  {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filters and functions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filters can be added to your HubL to transform or alter the value of a template variable. A simple example displayed below is formatting a date variable. Filters use a | (pipeline symbol) and are applied without spaces to a variable."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the example below, assume a blog post was published on the 29th of April. The publish date of the post is formatted with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetimeformat"
      }), " filter. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "You can view a full list of filters here."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date_local_time|datetimeformat(\"%B %e, %Y\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "April 29, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While filters affect how variables render, functions process value and account information and render that info. For example, a function can be used to get the total number of posts for a particular blog or to lighten/darken a color variable by a particular amount."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example would print the total number of blog posts from the designers.hubspot.com/blog. It uses a Blog ID (available in the URL of the blog dashboard) parameter to specify which blog to target. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "You can view a full list of functions here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "// blog_total_post_count\n{{ blog_total_post_count(359485112) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "If statements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "If statements"
      }), " allow you to use conditional logic to dictate how your template will render conditional logic in HubL statements for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), ". An if statement must begin with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " and end with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example below defines a choice module that lets the end user select a department from a dropdown. Depending on what the user selects in the editor, the template will generate a dynamic heading for a careers page. This example requires the use of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "export_to_template_context"
        })
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "For loops"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "For loops"
      }), " allow you to iterate over items stored in a sequence. They will most commonly be used with rendering blog content in a listing format. For loops begin with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), " statement and end with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endfor"
      }), " statement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the example below, an array of types of bears is stored as a variable called ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bears"
      }), ". A for loop is then used to iterate through the different types of bears printing a list item for each type."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bears = [\"panda bear\", \"polar bear\", \"black bear\", \"grizzly bear\", \"koala bear\"] %}\n\n<h1>Types of bears</h1>\n<ul>\n  {% for bear in bears %}\n  <li>{{ bear }}</li>\n  {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Types of bears</h1>\n<ul>\n  <li>panda bear</li>\n  <li>polar bear</li>\n  <li>black bear</li>\n  <li>grizzly bear</li>\n  <li>koala bear</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Other HubL features"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are a few other miscellaneous templating features that may be useful, as you develop with HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Escaping HubL delimiters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Many other languages share the same delimiters as HubL, which can create issues when working in coded files on the CMS. If you want to use a HubL delimiter for a different language, you need to wrap that code in:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: " {{\"Code you want to escape\"}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{\"Code you want to escape\"}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Including files in files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can include multiple ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".html"
      }), " files in one ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HubL template"
      }), " using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#standard-partials",
        children: "include tag"
      }), ". In order to create an HTML file that does not require the standard template variables, you must uncheck the option \"Make template available for new content.\" The syntax is displayed below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"custom/page/web_page_basic/my_footer.html\" %}\n{% include \"hubspot/styles/patches/recommended.css\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also compile multiple CSS files into a single CSS file with the same include tag. When you publish the parent file, the child file will be compiled into a single minified CSS file with the parent's code."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blocks and extends"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When creating complex templates, you can use create compartmentalized blocks that extend a parent template."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["First, you'll create a main template that includes the required ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), " variables. Within that template, you need to define a unique block using the following syntax where ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_sidebar"
      }), " is a unique name:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"custom/page/web_page_basic/my_template.html\" %}\n{% block my_sidebar %}\n<h3>Sidebar title</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, you can create a child HTML file that will populate that block. First, you must declare an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#blocks-and-extends",
        children: "extends statement"
      }), " that references the path to the parent. This block of code would be rendered in the parent template but maintained in another smaller and more manageable file. This technique is not for everyone but can be useful to stay organized when coding complex email or page templates. When using this technique, you should choose the child template, when creating content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Copy section HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the page editor, you can copy the HubL markup for a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: "drag and drop section"
      }), " to reuse the code as needed. This can be helpful when wanting to recreate a drag and drop section in a coded file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-section-hubl-menu.png",
        alt: "copy-section-hubl-menu"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "copying section HubL."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}