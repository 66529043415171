'use es6';

import { argumentType, httpFormContentTypes } from './constants.js';
import { extractModelNameFromRef, extractOASRequest, generateArgumentValue, extractApiNameFromRequest } from './helpers.js';
import { isNil, get } from './objutils';
const getValueFromFormdata = (formData, argName, argType) => {
  let path;
  const val = get(formData, argName);
  if (argType === 'array' && val && val.length === 1) {
    path = `${argName}[0]`;
  } else {
    path = argName;
  }
  return get(formData, path);
};
export default class OpenApiSpecificationParser {
  constructor(openApiSpecification) {
    this.openApiSpecification = openApiSpecification;
  }
  initAssignment(variableName, modelRef, formData) {
    const modelName = typeof modelRef === 'string' ? extractModelNameFromRef(modelRef) : extractModelNameFromRef(modelRef.$$ref);
    const model = this.openApiSpecification.components.schemas[modelName];
    this.imports.add(modelName);
    const assignment = {
      variable: variableName,
      model: modelName,
      args: []
    };
    for (const argumentName of Object.keys(model.properties)) {
      const argument = model.properties[argumentName];
      const argumentRef = get(argument, '$ref') || get(argument, 'items.$$ref');
      const argType = get(argument, 'type', argumentType.REF);
      let argumentValue = getValueFromFormdata(formData, argumentName, argType);
      if (argumentValue === undefined) {
        argumentValue = get(argument, 'example') || get(model, `example.${argumentName}`) || get(argument, 'enum[0]');
      }
      const isRequired = model.required && model.required.includes(argumentName);
      if (argumentRef || isRequired || argumentValue !== undefined) {
        const assigmentArg = Object.assign({
          key: argumentName,
          type: argType
        }, argType === argumentType.ARRAY && {
          items: argument.items
        });
        assignment.args.push(Object.assign({}, assigmentArg, {
          value: argumentValue
        }));
      }
    }
    this.assignments.push(assignment);
  }
  pushParameter(parameter, formData, sdkCall, skipUndefinedNotRequiredSdkCallArguments) {
    let value = formData[parameter.in][parameter.name];
    const defaultValue = get(parameter, 'schema.default');
    let type = get(parameter, 'schema.type');
    if (parameter.required || value || defaultValue !== undefined || !skipUndefinedNotRequiredSdkCallArguments) {
      if (!value) value = defaultValue;
      if (isNil(value) && parameter.required) {
        value = generateArgumentValue(parameter.name, type);
      }
      if (isNil(value)) {
        type = argumentType.UNDEFINED;
      }
      sdkCall.args.push({
        key: parameter.name,
        value,
        type,
        in: parameter.in
      });
    }
  }
  parse(operationId, formData, skipUndefinedNotRequiredSdkCallArguments = true) {
    const {
      sdkPath,
      request
    } = extractOASRequest(this.openApiSpecification, operationId);
    this.imports = new Set();
    this.assignments = [];
    const methodName = request.operationId.split('_').slice(-1).pop();
    const sdkCall = {
      path: sdkPath,
      apiName: `${extractApiNameFromRequest(request)}Api`,
      methodName,
      args: [],
      authTypes: request.security.map(item => {
        return Object.keys(item).toString();
      })
    };
    if (request.parameters) {
      for (const parameter of request.parameters) {
        if (parameter.in === 'path') {
          this.pushParameter(parameter, formData, sdkCall, skipUndefinedNotRequiredSdkCallArguments);
        }
      }
    }
    if (get(request, ['requestBody', 'content', 'application/json'])) {
      const requestBody = request.requestBody.content['application/json'].schema;
      let paramName;
      if (get(requestBody, '$$ref')) {
        paramName = extractModelNameFromRef(requestBody.$$ref);
        this.initAssignment(paramName, requestBody, formData.body);
        sdkCall.args.push({
          key: paramName,
          value: paramName,
          type: argumentType.REF
        });
      } else if (requestBody.type === 'array') {
        if (get(requestBody, 'items.$$ref')) {
          paramName = extractModelNameFromRef(requestBody.items.$$ref);
        }
        sdkCall.args.push({
          key: paramName,
          value: formData.body,
          type: argumentType.ARRAY,
          items: requestBody.items
        });
      }
    }
    if (request.parameters) {
      for (const parameter of request.parameters) {
        if (parameter.in !== 'path') {
          this.pushParameter(parameter, formData, sdkCall, skipUndefinedNotRequiredSdkCallArguments);
        }
      }
    }
    const properties = Object.assign({}, ...httpFormContentTypes.map(contentType => get(request, `requestBody.content["${contentType}"].schema.properties`, {})));
    Object.keys(properties).forEach(key => {
      const type = get(properties, `${key}.type`);
      sdkCall.args.push({
        key,
        value: generateArgumentValue(key, type),
        type,
        format: get(properties, `${key}.format`)
      });
    });
    return {
      sdkCall,
      assignments: this.assignments,
      imports: this.imports
    };
  }
}