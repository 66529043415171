"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107554;
const slug = exports.slug = 'guides/apps/authentication/validating-requests';
const title = exports.title = 'Webhooks | Validating Requests';
const name = exports.name = 'vNext Docs DP - Validating Requests from HubSpot';
const metaDescription = exports.metaDescription = 'An overview on validating requests originating from HubSpot to an integration. ';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "validate-requests-using-the-v1-request-signature",
  "label": "Validate requests using the v1 request signature",
  "parent": null
}, {
  "depth": 0,
  "id": "v1-request-signature-examples",
  "label": "v1 request signature examples:",
  "parent": null
}, {
  "depth": 0,
  "id": "validate-requests-using-the-v2-request-signature",
  "label": "Validate requests using the v2 request signature",
  "parent": null
}, {
  "depth": 1,
  "id": "example-for-a-get-request",
  "label": "Example for a GET request",
  "parent": "validate-requests-using-the-v2-request-signature"
}, {
  "depth": 1,
  "id": "example-for-a-post-request",
  "label": "Example for a POST request",
  "parent": "validate-requests-using-the-v2-request-signature"
}, {
  "depth": 0,
  "id": "validate-the-v3-request-signature",
  "label": "Validate the v3 request signature",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      h2: "h2",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Validating requests from HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To ensure that the requests that your integration is receiving from HubSpot are actually coming from HubSpot, several headers are populated in the request. You can use these headers, along with fields of the incoming request, to verify the signature of the request."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The method used to verify the signature depends on the version of the signature:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To validate a request using the latest version of the HubSpot signature, use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-V3"
        }), " header and follow the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests#validate-the-v3-request-signature",
          children: "associated instructions for validating the v3 version of the signature"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For backwards compatibility, requests from HubSpot also include older versions of the signature. To validate an older version of the signature, check the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-Version"
        }), " header, then follow the associated instructions below based on whether the version is ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v1"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v2"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the instructions below, learn how to derive a hash value from your app's client secret and the fields of an incoming request. Once you compute the hash value, compare it to the signature. If the two are equal, then the request has passed validation. Otherwise, the request may have been tampered with in transit or someone may be spoofing requests to your endpoint."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validate requests using the v1 request signature"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your app is subscribed to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks",
        children: "CRM object events via the webhooks API"
      }), ", requests from HubSpot will be sent with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " header set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v1"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header will be an SHA-256 hash built using the client secret of your app combined with details of the request."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To verify this version of the signature, perform the following steps:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a string that concatenates together the following: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (if present)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Create a SHA-256 hash of the resulting string."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Compare the hash value to the value of the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature"
        }), " header:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If they're equal then this request has passed validation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If these values do not match, then this request may have been tampered with in-transit or someone may be spoofing requests to your endpoint."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example for a request with a body:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Client secret : yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\n// Request body: [\n{\"eventId\":1,\"subscriptionId\":12345,\"\nportalId\":62515\",\noccurredAt\":1564113600000\",\nsubscriptionType\":\"contact.creation\",\n\"attemptNumber\":0,\n\"objectId\":123,\n\"changeSource\":\"CRM\",\n\"changeFlag\":\"NEW\",\n\"appId\":54321}\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v1 request signature examples:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n>>> import hashlib\n\n>>> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n>>> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> source_string = client_secret + request_body\n>>> source_string\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> hashlib.sha256(source_string).hexdigest()\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\nirb(main):003:0> require 'digest'\n=> true\nirb(main):004:0> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\"\nirb(main):005:0> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n=> \"[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):006:0> source_string = client_secret + request_body\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):007:0> Digest::SHA256.hexdigest source_string\n=> \"232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de\"\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n> const crypto = require('crypto')\nundefined\n> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n'[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> source_string = client_secret + request_body\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> hash = crypto.createHash('sha256').update(source_string).digest('hex')\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The resulting hash would be: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validate requests using the v2 request signature"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your app is handling data from a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "webhook action in a workflow"
      }), ", or if you're returning data for a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "custom CRM card"
      }), ", the request from HubSpot is sent with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " header set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v2"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header will be an SHA-256 hash built using the client secret of your app combined with details of the request."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To verify this signature, perform the following steps:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a string that concatenates together the following: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http method"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "URI"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (if present)"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Create a SHA-256 hash of the resulting string."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Compare the hash value to the signature.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If they're equal then this request has passed validation."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If these values do not match, then this request may have been tampered with in-transit or someone may be spoofing requests to your endpoint."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Notes:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The URI used to build the source string must exactly match the original request, including the protocol. If you're having trouble validating the signature, ensure that any query parameters are in the exact same order they were listed in the original request."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The source string should be UTF-8 encoded before calculating the SHA-256 hash."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example for a GET request"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request, you'd need your app's client secret and specific fields from the metadata of your request. These fields are listed below with placeholder values included:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client secret:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTTP method:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Request body: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"\""
          })]
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The resulting concatenated string would be: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyGEThttps://www.example.com/webhook_uri"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After calculating a SHA-256 hash of the concatenated string above, the resulting signature you'd expect to match to the one in the header would be: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eee2dddcc73c94d699f5e395f4b9d454a069a6855fbfa152e91e88823087200e"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example for a POST request"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request, you'd need your app's client secret, specific fields from the metadata of your request, and a string representation of the body of the request (e.g., using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "JSON.stringify(request.body)"
      }), " for a Node.js service). These fields are listed below with placeholder values included:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client secret:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTTP method:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Request body:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{\"example_field\":\"example_value\"}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The resulting concatenated string would be: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"example_value\"}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After calculating a SHA-256 hash of the concatenated string above, the resulting signature you'd expect to match to the one in the header would be:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "9569219f8ba981ffa6f6f16aa0f48637d35d728c7e4d93d0d52efaa512af7900"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After [SHA-ing] the signature, you could then compare the resulting expected signature to the one provided in the x-hubspot-signature header of the request:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Node.js code snippet below details how you could incorporate ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v2"
      }), " request validation for a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request if you were running an Express server to handle incoming requests. Keep in mind that the code block below is an example and omits certain dependencies you might need to run a fully-featured Express service. Confirm that you're running the latest stable and secure libraries when implementing request validation for your specific service."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Introduce any dependencies. Only several dependencies related to this example are included below:\nconst express = require('express');\nconst bodyParser = require('body-parser');\nconst crypto = require('crypto');\nconst app = express();\n\n// Add any custom handling or setup code for your Node.js service here.\napp.use(bodyParser.urlencoded({ extended: false }));\napp.use(bodyParser.json());\n\n// Example Node.js request validation code.\napp.get('/example-service', (request, response, next) => {\n  const { url, method, headers, hostname } = request;\n\n  const requestSignature = headers['x-hubspot-signature'];\n\n  // Compute expected signature\n  const uri = `https://${hostname}${url}`;\n  const encodedString = Buffer.from(\n    `${process.env.CLIENT_SECRET}${method}${uri}`,\n    'ascii'\n  ).toString('utf-8');\n  const expectedSignature = crypto\n    .createHash('sha256')\n    .update(encodedString)\n    .digest('hex');\n\n  console.log('Expected signature: %s', requestSignature);\n  console.log('Request signature: %s', expectedSignature);\n\n  // Add your custom handling to compare request signature to expected signature\n  if (requestSignature !== expectedSignature) {\n    console.log('Request of signature does NOT match!');\n    response.status(400).send('Bad request');\n  } else {\n    console.log('Request of signature matches!');\n    response.status(200).send();\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validate the v3 request signature"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " header will be an HMAC SHA-256 hash built using the client secret of your app combined with details of the request. It will also include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Request-Timestamp"
      }), " header."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When validating a request using the X-HubSpot-Signature-v3 header, you'll need to"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Reject the request if the timestamp is older than 5 minutes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the request URI, decode any of the URL-encoded characters listed in the table below. You do not need to decode the question mark that denotes the beginning of the query string."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Encoded value"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Decoded value"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ":"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "?"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%40"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "@"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%21"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%24"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "$"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%27"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%28"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "("
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%29"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ")"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2C"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ","
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3B"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ";"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a utf-8 encoded string that concatenates together the following: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestMethod"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestUri"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestBody"
        }), " + timestamp. The timestamp is provided by the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Request-Timestamp"
        }), " header."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Create an HMAC SHA-256 hash of the resulting string using the application secret as the secret for the HMAC SHA-256 function."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Base64 encode the result of the HMAC function."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Compare the hash value to the signature. If they're equal then this request has been verified as originating from HubSpot. It's recommended that you use constant-time string comparison to guard against timing attacks."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Node.js code snippet below details how you could incorporate v3 request validation for a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request if you were running an Express server to handle incoming requests. Keep in mind that the code block below is an example and omits certain dependencies you might need to run a fully-featured Express service. Confirm that you're running the latest stable and secure libraries when implementing request validation for your specific service."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Introduce any dependencies. Only several dependencies related to this example are included below:\nrequire('dotenv').config();\nconst express = require('express');\nconst bodyParser = require('body-parser');\nconst crypto = require('crypto');\nconst app = express();\nconst port = process.env.PORT || 4000;\n\napp.use(bodyParser.urlencoded({ extended: false}));\napp.use(bodyParser.json());\n\napp.post('/webhook-test', (request, response) => {\n  response.status(200).send('Received webhook subscription trigger');\n\n  const {\n    url,\n    method,\n    body,\n    headers,\n    hostname\n  } = request;\n\n  // Parse headers needed to validate signature\n  const signatureHeader = headers[\"x-hubspot-signature-v3\"]\n  const timestampHeader = headers[\"x-hubspot-request-timestamp\"];\n\n  // Validate timestamp\n  const MAX_ALLOWED_TIMESTAMP = 300000; // 5 minutes in milliseconds\n  const currentTime = Date.now();\n  if (currentTime - timestamp > MAX_ALLOWED_TIMESTAMP) {\n    console.log(\"Timestamp is invalid, reject request\");\n    // Add any rejection logic here\n  }\n\n  // Concatenate request method, URI, body, and header timestamp\n  const uri = `https://${hostname}${url}`;\n  const rawString = `${method}${uri}${JSON.stringify(body)}${timestamp}`;\n\n  // Create HMAC SHA-256 hash from resulting string above, then base64-encode it\n  const hashedString = crypto.createHmac(\"sha256\", process.env.CLIENT_SECRET).update(rawString).digest(\"base64\");\n\n  // Validate signature: compare computed signature vs. signature in header\n  if (crypto.timingSafeEqual(Buffer.from(hashedString), Buffer.from(signatureHeader)) {\n    console.log(\"Signature matches! Request is valid.\");\n    // Proceed with any request processing as needed.\n  } else {\n    console.log(\"Signature does not match: request is invalid\");\n    // Add any rejection logic here.\n  }\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}