"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 48816364594;
const slug = exports.slug = 'guides/cms/content/fields/brand-and-settings-inheritance';
const title = exports.title = 'Herança de configurações de marca';
const name = exports.name = 'Herança de configurações de marca';
const metaDescription = exports.metaDescription = 'Saiba como usar as configurações de marca, como logotipos e cores de marca, em um tema ou em arquivos HubL/HTML e CSS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vari%C3%A1veis-das-configura%C3%A7%C3%B5es-de-marca",
  "label": "Variáveis das configurações de marca",
  "parent": null
}, {
  "depth": 1,
  "id": "cores",
  "label": "Cores",
  "parent": "vari%C3%A1veis-das-configura%C3%A7%C3%B5es-de-marca"
}, {
  "depth": 1,
  "id": "logotipos",
  "label": "Logotipos",
  "parent": "vari%C3%A1veis-das-configura%C3%A7%C3%B5es-de-marca"
}, {
  "depth": 1,
  "id": "favicons",
  "label": "Favicons",
  "parent": "vari%C3%A1veis-das-configura%C3%A7%C3%B5es-de-marca"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Herança de configurações de marca"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/customize-branding-for-your-hubspot-content#customize-your-company-logo-and-colors",
        children: "configurações de marca"
      }), ", os usuários podem definir as cores da marca da empresa, logotipos e favicons para serem usados no conteúdo do HubSpot. Isso permite que você acesse as configurações da marca com tokens no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " de um tema e dentro de arquivos HTML/HubL e CSS. Você também pode acessar as cores da marca no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " de um módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de adicionar esses tokens em um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", os criadores de conteúdo podem editar seus valores no editor de configurações de tema. Ao adicionar esses tokens em um HTML, HubL ou CSS, os valores serão codificados e não poderão ser modificados no editor de páginas pelos criadores de conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre as variáveis de configuração de marca disponíveis, juntamente com exemplos de implementação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis das configurações de marca"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A seguir está uma lista de opções que podem ser acessadas da área de configurações da marca dentro do valor do objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), " ou em arquivos HTML/HubL e CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As cores da marca podem ser acessadas no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json-color",
        children: "arquivo fields.json"
      }), " de um tema ou módulo e nos arquivos HTML/HubL e CSS usando os seguintes tokens do HubL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Principal:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.primaryColor}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[0]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-primary.png",
                alt: "brand-colors-primary"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Secundário:"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{brand_settings.secondaryColor}}"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-secondary.png",
            alt: "brand-colors-secondary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Cores de destaque:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor1}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor2}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor3}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-accent.png",
                alt: "brand-colors-accent"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Cores adicionais:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[1]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[2]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[3]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-additional.png",
                alt: "brand-colors-additional"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para acessar diretamente o código hexadecimal de uma cor, inclua um filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hex"
      }), " no token. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryColor.hex}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir as cores das configurações da marca em um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " de tema ou módulo, use o seguinte formato:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example of using the primary color in within a theme's\n// field.json file\n{\n  \"name\": \"branding_color\",\n  \"label\": \"branding_color\",\n  \"type\": \"color\",\n  \"default\": {\n    \"color\": \"#26ff55\",\n    \"opacity\": 60\n  },\n  \"inherited_value\": {\n    \"property_value_paths\": {\n      \"color\": \"brand_settings.primaryColor\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Pode ser que as contas não tenham cores adicionais definidas nas configurações da marca. Se o seu código fizer referência a uma cor herdada que não existe nas configurações da marca, a seguinte lógica de fallback será usada:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "secondaryColor"
          }), " assumirá a primeira cor adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[1]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor1"
          }), " assumirá a primeira cor adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[2]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor2"
          }), " assumirá a primeira cor adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor3"
          }), " assumirá a primeira cor adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[4]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Cores adicionais (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ") voltará para o valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "default"
          }), ". Se não houver um conjunto de cores de propriedade padrão, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "primaryColor"
          }), " será usado."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logotipos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os logotipos da marca podem ser acessados nos arquivos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " e HTML/HubL e CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode usar os seguintes tokens para acessar o conjunto de logotipos principais nas configurações da marca:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.logos[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-logo0.png",
        alt: "brand-settings-logo0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os logotipos adicionais podem ser acessados usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.logos[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além disso, você pode acessar os seguintes atributos de logotipo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL do logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.link}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texto alternativo do logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.alt}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Altura do logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.height}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Largura do logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.width}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Link para a imagem do logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.src}}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Favicons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os favicons de marcas podem ser acessados somente nos arquivos HTML/HubL e CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode usar os seguintes tokens para acessar o conjunto de logotipos principais nas configurações da marca:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryFavicon}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.favicons[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-favicon0.png",
        alt: "brand-settings-favicon0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os favicons adicionais podem ser acessados usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.favicons[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode acessar o URL do logotipo diretamente incluindo um filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), ". Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryFavicon.src}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}