'use es6';

export const getHeader = (headers, header) => {
  // HTTP headers are case insensitive, so ignore case when finding a given header
  if (!headers) {
    return null;
  }
  const keys = Object.keys(headers);
  const key = keys.find(k => k.toLowerCase() === header.toLowerCase());
  return key ? headers[key] : null;
};