"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 129416541858;
const slug = exports.slug = 'guides/crm/private-apps/creating-private-apps';
const title = exports.title = 'Creating private apps with projects (BETA)';
const name = exports.name = 'Creating private apps with projects (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to create private apps using HubSpot developer projects.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-private-app-within-a-project",
  "label": "Create a private app within a project",
  "parent": null
}, {
  "depth": 0,
  "id": "add-a-serverless-function-to-the-app",
  "label": "Add a serverless function to the app",
  "parent": null
}, {
  "depth": 1,
  "id": "serverless.json",
  "label": "serverless.json",
  "parent": "add-a-serverless-function-to-the-app"
}, {
  "depth": 1,
  "id": "package.json",
  "label": "package.json",
  "parent": "add-a-serverless-function-to-the-app"
}, {
  "depth": 1,
  "id": "example-function.js",
  "label": "example-function.js",
  "parent": "add-a-serverless-function-to-the-app"
}, {
  "depth": 0,
  "id": "view-the-app-in-hubspot",
  "label": "View the app in HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "overview",
  "label": "Overview",
  "parent": "view-the-app-in-hubspot"
}, {
  "depth": 1,
  "id": "auth",
  "label": "Auth",
  "parent": "view-the-app-in-hubspot"
}, {
  "depth": 1,
  "id": "logs",
  "label": "Logs",
  "parent": "view-the-app-in-hubspot"
}, {
  "depth": 1,
  "id": "log-traces",
  "label": "Log Traces",
  "parent": "view-the-app-in-hubspot"
}, {
  "depth": 1,
  "id": "extensions",
  "label": "Extensions",
  "parent": "view-the-app-in-hubspot"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create private apps using projects (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When building with the developer projects framework, private apps enable you to authenticate data fetch requests using their private app access token. Private apps can only be installed into the account where they're defined, versus ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps",
        children: "public apps"
      }), " which are installed into multiple accounts through an OAuth flow."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your project's structure, a private app is stored in a folder that contains an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app.json"
      }), " configuration file, and will also contain extension and serverless function files needed by the app. A project can contain multiple apps, and each app can contain multiple UI extensions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this guide, learn how to create a private app in a project and view it in HubSpot. Then, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "create a UI extension within the app"
      }), " to customize the CRM UI, or build ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/build-modules-and-partials-with-react",
        children: "CMS React modules"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " building UI extensions for private apps in a standard HubSpot account requires an ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription. However, you can try out building private apps using projects in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "developer test accounts"
        }), " for free."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a private app within a project"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a private app in a project, you'll first need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "install the HubSpot CLI"
      }), ". To get started with HubSpot projects, you can also check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart",
        children: "quickstart guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can create up to 20 private apps in a HubSpot account, and each app is subject to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details#rate-limits",
          children: "HubSpot's API rate limits"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a private app within your project:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), " folder of your project directory, create a folder for your app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Within the app folder, create an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "app.json"
        }), " file. This file will contain your app definitions."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy the following code into your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "app.json"
        }), " file:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example app config file\n{\n  \"name\": \"Get started App with React\",\n  \"description\": \"This is an example of private app that shows a custom card on the Contact record tab built with React-based frontend. This card demonstrates simple handshake with HubSpot's serverless backend.\",\n  \"scopes\": [\"crm.objects.contacts.read\", \"crm.objects.contacts.write\"],\n  \"uid\": \"unique-app-name\",\n  \"public\": false,\n  \"extensions\": {\n    \"crm\": {\n      \"cards\": [\n        {\n          \"file\": \"extensions/example-card.json\"\n        }\n      ]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A unique name for the app."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The app's description."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scopes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The app's allowed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/scopes",
              children: "scopes"
            }), ". At least one scope is required."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "uid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The app's uniquely identifying name. This can be any string, but should meaningfully identify the app. HubSpot will identify the app by this ID so that you can change the app's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " locally or in HubSpot without removing historical or stateful data, such as logs."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "public"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " for private apps."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extensions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contains the extensions included in the app. For app cards, include a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm"
            }), " object, followed by a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cards"
            }), " array that contains a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), " field that references the card's JSON configuration file. If you have not yet defined your extension, you can leave this object empty."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add a serverless function to the app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Within the private app, you'll add a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions#create-a-serverless-function",
        children: "serverless function"
      }), " to serve as your UI extension's back end. Serverless function files are stored in a separate folder within the app folder."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the app directory, create a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " directory with the following files:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "serverless.json"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "package.json"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "example-function.js"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/Screenshot%202023-08-31%20at%204.23.46%20PM.png",
            alt: "Screenshot 2023-08-31 at 4.23.46 PM"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The example code below will get you started, but ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions#create-a-serverless-function",
        children: "check out the serverless functions guide"
      }), " to learn more about authenticating calls, managing secrets, debugging, and more."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "serverless.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example serverless config file\n\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"appFunctions\": {\n    \"myFunc\": {\n      \"file\": \"example-function.js\",\n      \"secrets\": []\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appFunctions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The object that contains definitions for each serverless function."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "myFunc"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The object containing the name of the serverless function ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), " along with any secrets needed for authenticating requests in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            }), " array.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "myFunc"
            }), " can be any value. You'll later reference this name when running the function in your React front end."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "package.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example serverless function package.json\n\n{\n  \"name\": \"demo.functions\",\n  \"version\": \"1.0.0\",\n  \"description\": \"\",\n  \"main\": \"index.js\",\n  \"scripts\": {\n    \"test\": \"echo \\\"Error: no test specified\\\" && exit 1\"\n  },\n  \"author\": \"\",\n  \"license\": \"ISC\",\n  \"dependencies\": {\n    \"@hubspot/api-client\": \"^7.0.1\",\n    \"axios\": \"^0.27.2\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "example-function.js"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example serverless function\n\nexports.main = (context = {}, callback) => {\n  const { text } = context.parameters;\n\n  const ret = `This is coming from a serverless function! You entered: ${text}`;\n  try {\n    callback(ret);\n  } catch (error) {\n    callback(error);\n  }\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your app defined and your serverless function configured, upload the project to HubSpot using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), " command."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On upload, the build will be triggered and HubSpot will validate your project files and provision any needed resources. By default, HubSpot will automatically deploy successful builds. Alternatively, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/link-a-github-repository-to-a-project",
        children: "link a GitHub repository to the project"
      }), " to automatically trigger a new build when you push a change to the project files in GitHub."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your app uploaded, learn how to view it in HubSpot below. Then, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "create a UI extension"
      }), " in the app to customize CRM records with various UI components."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View the app in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "Super admin"
      }), ", you can access and manage private apps in your HubSpot account, including viewing your apps, their build history, and the access token required to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "make API calls"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view a private app's details in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM Development"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the private app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You'll then be taken to the app's home page where you can view and manage its access token, view request logs and included extensions, and delete the app. Learn more about each tab below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Overview"
      }), " tab, review high-level information about the app's API calls and extension requests and responses."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/private-app-overview-tab.png",
        alt: "private-app-overview-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Auth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Auth"
      }), " tab, view authentication-related information, such as the private app's access token and scopes. You can also delete the app from this page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/private-app-auth-tab.png",
        alt: "private-app-auth-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Logs"
      }), " tab, view detailed information about the app's various calls and events. The tabs under the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Logs"
      }), " tab break down different types of events that occur while the app is running, and are helpful for debugging errors that your app might run into. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions#debug-a-serverless-function",
        children: "serverless function debugging"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "API calls:"
        }), " click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "API calls"
        }), " tab to view logs for API calls made by the app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Endpoint functions:"
        }), " click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Endpoint"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "functions"
        }), " tab to view logs for serverless endpoint functions included in the app. You can view further event details by clicking an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "event row"
        }), ". Log details will be displayed in a panel on the right, and will include a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "View log trace"
        }), " link that you can click to view the log track, along with a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Trade ID"
        }), " that you can copy for use on the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#log-traces",
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Log Traces"
          })
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Serverless functions:"
        }), " click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Serverless functions"
        }), " tab to view logs for serverless app functions included in the app. You can view further event details by clicking an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "event row"
        }), ". Log details will be displayed in a panel on the right, and will include a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "View log trace"
        }), " link that you can click to view the log track, along with a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Trade ID"
        }), " that you can copy for use on the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#log-traces",
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Log Traces"
          })
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Extensions:"
        }), " click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Extensions"
        }), " tab to view React top-level event logs, such as a UI extension successfully loading. Click an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "event row"
        }), " to view log details, including the user who initialized the request. Log details will also include a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "View log trace"
        }), " link that you can click to view the log trace, along with a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Trace ID"
        }), " that you can copy for use on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Log Traces"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Security:"
        }), " click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Security"
        }), " tab to view security-related events, such as private app access token viewing and rotating."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/in-app-logs-tab-private-app.png",
        alt: "in-app-logs-tab-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, say your app is running into an error when trying to retrieve company proximity information through a serverless function. You can click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Logs"
      }), " tab, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Serverless functions"
      }), " tab. Then, you can click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "errored request"
      }), " to view the log output in the right panel. In the panel, you can then click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "View log trace"
      }), " for more information."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/view-serverless-app-function-logs.gif",
        alt: "view-serverless-app-function-logs"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alternatively, you can click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copy Trace ID"
      }), " in the log output panel, then use the ID in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Log Traces"
      }), " tab."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/copy-trace-id-for-log-tracing.gif",
        alt: "copy-trace-id-for-log-tracing"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Log Traces"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Log Traces"
      }), " tab, view log traces by trace ID, which you can retrieve from log outputs on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#logs",
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Logs"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#logs",
        children: "tab"
      }), ". Log tracing enables you to trace front-end and back-end logs with a single ID, making it easier to debug issues happening in production."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also retrieve log IDs on CRM record pages where an extension has failed to load. This can be especially useful if your extension includes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#send-custom-log-messages-for-debugging",
        children: "custom log messages"
      }), ". ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/logger-debug-on-crm-record.png",
        alt: "logger-debug-on-crm-record"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " log tracing is not available for private apps built on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/platform-versioning",
          children: "platform version"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.1"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/private-app-log-traces-output.png",
        alt: "private-app-log-traces-output"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Extensions"
      }), " tab, view information about the extensions that are included in the app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/private-app-extensions-tab.png",
        alt: "private-app-extensions-tab"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}