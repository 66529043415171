"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611098;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/hubdb';
const title = exports.title = 'Construire des pages dynamiques avec HubDB';
const name = exports.name = 'Construire des pages dynamiques avec HubDB';
const metaDescription = exports.metaDescription = `Découvrez comment créer des pages de site Web dynamiques à l'aide des données HubDB.`;
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-cr%C3%A9er-un-tableau-hubdb",
  "label": "1. Créer un tableau HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-cr%C3%A9er-un-mod%C3%A8le",
  "label": "2. Créer un modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-cr%C3%A9er-la-page-dynamique",
  "label": "3. Créer la page dynamique",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-afficher-les-pages-en-ligne",
  "label": "4. Afficher les pages en ligne",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-ajouter-une-nouvelle-ligne-de-tableau",
  "label": "5. Ajouter une nouvelle ligne de tableau",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-afficher-les-donn%C3%A9es-de-la-page-dynamique",
  "label": "6. Afficher les données de la page dynamique",
  "parent": null
}, {
  "depth": 0,
  "id": "plus-de-didacticiels-sur-hubdb",
  "label": "Plus de didacticiels sur HubDB",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Construire des pages dynamiques avec HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "Les pages dynamiques"
      }), " sont des pages CMS dont le contenu provient d'une source de données structurée. En fonction de la façon dont vous configurez votre page dynamique, HubSpot extrait les données de la source sélectionnée et crée automatiquement un ensemble de pages. Cela inclut une page de listing qui affiche des résumés des données et des pages individuelles pour chaque entrée de source de données."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'aide d'un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "tableau HubDB"
      }), " comme source de données, vous pouvez créer une page dynamique qui génère ensuite une page pour chaque ligne du tableau. Chaque page dynamique comprend une URL unique optimisée pour le SEO, ainsi que des analytics spécifiques aux pages."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Vous pouvez créer jusqu'à 10 pages dynamiques par source de données. Pour les pages dynamiques HubDB, cela signifie que jusqu’à 10 pages dynamiques peuvent utiliser le même tableau HubDB. Le nombre de lignes du tableau (et donc de pages enfants) n’est pas pris en compte dans cette limite. En savoir plus sur les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
            children: "Limites techniques de HubDB"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les pages dynamiques HubDB sont limitées à 50 000 ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/content-search",
            children: "mises à jour de l’index de recherche de contenu"
          }), " par compte et par jour. Toute mise à jour de ces pages au-delà de cette limite n’apparaîtra pas dans la recherche de contenu ce jour-là."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce tutoriel explique comment créer un ensemble de pages dynamiques en utilisant HubDB comme source de données. Pour suivre ce tutoriel, vous aurez besoin de :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "**Content Hub **Pro"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Connaissances préalables concernant le HTML et les CSS"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Découvrez-en davantage sur la création de pages CMS basées sur les données dans le cours ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "CMS Data-Driven Content"
        }), " de HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Créer un tableau HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer un nouveau tableau HubDB :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à **Contenu **> ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un tableau"
        }), " dans l'angle supérieur droit."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, saisissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "libellé"
        }), " et le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " du tableau, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois le tableau créé, vous pouvez le paramétrer pour qu'il soit utilisé dans le contenu d'une page dynamique :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle supérieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gérer les paramètres"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le panneau de droite, cliquez pour activer l'option ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activer la création de pages dynamiques avec des données de ligne"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous pouvez éventuellement sélectionner la méta-description, l'image en vignette et l'URL canonique des pages dynamiques individuelles. Si vous laissez ces valeurs vides, chaque page héritera des valeurs respectives de sa page parent."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Remarque : ** pour qu'une page utilise les valeurs des colonnes de méta-description, d'image en et d'URL canonique, la page doit inclure les variables HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " suivantes plutôt que les variables ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " :"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.meta_description}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.featured_image_URL}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.canonical_url}}"
          })
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Par exemple, les modèles HubSpot tirent leur méta-description de la balise par défaut ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{content.meta_description}}"
        }), ". Vous devrez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{page_meta.meta_description}}"
        }), " à la place."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enregistrer"
        }), " pour enregistrer vos modifications."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-settings-sidebar-save.png",
        alt: "hubdb-table-settings-sidebar-save"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir mis à jour les paramètres de votre tableau, les colonnes ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Titre de la page"
      }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Chemin de la page"
      }), " seront ajoutées au tableau."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Titre de la page : **le nom de cette page tel que vu dans la balise de titre HTML."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Chemin de la page :"
        }), " le dernier segment de l'URL de la page dynamique créée par chaque ligne du tableau."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le tableau suivant est un exemple inspiré d'une page « À propos de nous » pour les membres d'une équipe de direction d'une entreprise. Ce tableau sera utilisé pour créer des pages dynamiques avec des chemins se terminant par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cfo-harlow"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ceo-jeff"
      }), ",", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cto-bristow"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pd-hugo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Titre de la page"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Chemin de page"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Rôle"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biographie"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Directeur financier Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cfo-harlow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Directeur financier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Il s’agit de Harlow, qui est généralement près de ses sous."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PDG Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceo-jeff"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "PDG"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff est le PDG, ce qui signifie qu’il dirige habituellement les choses ici."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cto-bristow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Voici notre directeur technique, Bristow, qui aime bricoler."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DCP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pd-hugo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CPD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo, notre directeur de la conception produit, aime concevoir des produits."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-hubdb-table.png",
        alt: "example-hubdb-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " bien que vous deviez saisir des chemins de page en minuscules, les URL résultantes sont insensibles à la casse. Dans l'exemple ci-dessus, lorsque l'on navigue vers ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/CEO-Jeff"
        }), ", on voit la même page que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/ceo-jeff"
        }), " au lieu d'une erreur 404."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous êtes prêt à utiliser les données de votre tableau pour commencer à créer vos pages, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Publier"
      }), " dans l'angle supérieur droit."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Créer un modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par la suite, vous créerez un modèle pour la page de listing et pour les pages de détail individuelles de chaque ligne, similaire à la façon dont les modèles d'articles peuvent servir aussi bien pour les pages de listing que pour les pages de détail des articles. Pour créer le modèle de page :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contenu"
        }), " >** Gestionnaire de conception**."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez au dossier dans lequel vous souhaitez créer le modèle. Pour créer un nouveau dossier, dans l'angle supérieur gauche, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichier"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nouveau dossier"
        }), ". Ensuite, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichier"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nouveau fichier"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, utilisez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menu déroulant"
        }), " pour sélectionner ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTML + HubL"
        }), " comme type de fichier."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivant"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/create-html-hubl-template.jpg",
        alt: "Créer un nouveau modèle HTML + HubL"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le champ ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nom du fichier"
        }), ", saisissez le nom du modèle."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sous ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Emplacement du fichier"
        }), ", vous pouvez modifier l'emplacement du modèle dans votre gestionnaire de conception en cliquant sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modifier"
        }), (0, _jsxRuntime.jsx)(_components.em, {
          children: "."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer"
        }), " pour créer le modèle."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une page dynamique est configurée pour utiliser ce modèle et que la fin de l'URL de la page correspond à la colonne de chemin, vous pouvez accéder aux variables ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_table_id"
      }), " du modèle. Par exemple, pour créer une page de profil de cadre, le code ci-dessous montre comment utiliser les champs de la ligne ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " pour afficher les informations du cadre :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), " : le ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "titre de la page"
        }), " associée pour la ligne HubDB."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " : le nom du cadre."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "role"
        }), " : le rôle du cadre."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ensuite, vous pouvez ajouter le traitement des cas où quelqu'un charge votre page dynamique (sans aucun chemin supplémentaire du tableau). Généralement, cela fait office de page de listing, pour répertorier les liens vers les pages des lignes de votre tableau HubDB. Remplacez votre code par :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n{% elif dynamic_page_hubdb_table_id %}\n    <ul>\n    {% for row in hubdb_table_rows(dynamic_page_hubdb_table_id) %}\n        <li><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></li>\n    {% endfor %}\n    </ul>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code à l'intérieur du bloc ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " itère sur toutes les lignes du tableau du cadre et affiche chaque entrée dans une liste, avec un lien vers leur chemin unique."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le gestionnaire de conception, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu"
        }), " pour prévisualiser le modèle. L'aperçu sera vide, car il s'appuie sur le contexte de la page pour définir les variables ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_row"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_table_id"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pour tester votre code au niveau du modèle, ajoutez le code temporaire suivant en haut de votre modèle, en vous assurant de le supprimer avant de le publier :"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set dynamic_page_hubdb_table_id = <YOUR_HUBDB_TABLE_ID> %}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Après avoir ajouté le code ci-dessus, votre modèle devrait maintenant afficher une liste d'hyperliens, en récupérant les données du tableau HubDB que vous avez créé."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-template-preview.png",
        alt: "hubdb-template-preview"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Après avoir prévisualisé le modèle, supprimez le code temporaire ci-dessus. Ensuite, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publier"
        }), " dans l'angle supérieur droit pour le rendre disponible pour la création de pages."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Créer la page dynamique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer une page dynamique à partir de votre modèle :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Avec votre nouveau modèle ouvert dans le gestionnaire de conception, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Actions"
          }), " en haut de l'outil de recherche, puis sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer une page"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-page-from-design-manager.png",
            alt: "create-page-from-design-manager"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la boîte de dialogue, sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Page de site web"
          }), ", puis saisissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom de la page"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer une page"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Paramètres"
          }), " en haut de l'éditeur de page."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le champ ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Titre de la page"
          }), ", saisissez un nom de page que vous pourrez utiliser ultérieurement pour rechercher des analytics de trafic."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le champ ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), ", saisissez une ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/executives"
          }), ". Cette URL sera l'URL de base de votre page dynamique."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Options avancées"
          }), " pour développer des paramètres supplémentaires."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Faites défiler la page jusqu'à la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Pages dynamiques"
          }), ", puis cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sources de données"
          }), ". Sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "tableau HubDB"
          }), " que vous avez créé."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/page%20editor%20hubdb%20dynamic%20.png",
        alt: "Options avancées dans les paramètres de page pour lier au tableau HubDB"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lorsque vous avez terminé, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publier"
        }), " dans l'angle supérieur droit. Vos pages sont maintenant prêtes à être consultées."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Afficher les pages en ligne"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez maintenant visiter votre nouvelle page dynamique et tous ses chemins, tels que définis par votre tableau HubDB."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Accédez à la page de listing dynamique à l'URL que vous avez définie dans l'éditeur de page. Ce tutoriel utilise ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " pour son URL de page dynamique. Dans ce cas, vous accéderez à : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.yourdomain.com/executives"
        }), ". À partir de la page de listing, cliquez sur les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "noms"
        }), " dans la liste à puces pour afficher la page de détails pour ce cadre."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/2022-11-28_15-55-47%20(1).gif",
        alt: "2022-11-28_15-55-47 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Ajouter une nouvelle ligne de tableau"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Maintenant que page dynamique charge les données HubDB, revenez au tableau et ajoutez une nouvelle ligne. Après avoir publié le tableau, vous verrez votre page en direct mise à jour dynamiquement avec les nouvelles données HubDB."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichiers et modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " du tableau que vous créez."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter une ligne"
        }), ", puis remplissez chaque colonne. Voici un échantillon de données."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Titre de la page"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Chemin de page"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Rôle"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biographie"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Directeur marketing Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cmo-hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes est notre passionné de chats."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publier"
        }), " dans l'angle supérieur droit."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans un autre onglet, revenez à la page de listing (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " dans cet exemple). Vous devriez maintenant voir le nouveau cadre apparaître sur la page de listing, et en cliquant sur son nom, vous verrez sa page de détails."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Afficher les données de la page dynamique"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que votre page dynamique compte des visites, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/analyze-performance-for-individual-pages-and-blog-posts",
        children: "analyser les performances de chaque page"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "afficher des données pour l'ensemble des pages dans l'outil Analytics de trafic"
      }), ". Même si les pages individuelles des cadres sont créées à partir de la même page dynamique, des données de trafic, telles que les vues de page, seront attribuées à chaque page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour consulter les données de visite de votre page dans HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Reporting"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Analytics marketing"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Analyse du trafic web"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Pages"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Affichez le tableau pour voir les données de trafic pour les pages parents et enfants individuelles. Les pages enfants seront désignées par des ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "icônes de flèche >"
          }), " pour montrer leur relation avec les pages parents."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-traffic-data-2.png",
            alt: "example-traffic-data-2"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tenez compte des informations suivantes si vous ne voyez pas les données de trafic que vous attendez :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous avez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/exclude-traffic-from-your-site-analytics",
          children: "exclu vos adresses IP dans les paramètres de rapports du compte"
        }), ", assurez-vous que vous accédez à vos pages hors de votre réseau pour que les vues de vos pages puissent être enregistrées."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["L'affichage", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/how-often-do-analytics-in-hubspot-update",
          children: " des nouvelles données de page dans HubSpot peut prendre jusqu'à 40 minutes"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plus de didacticiels sur HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Joindre des tableaux HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Comment créer une page d'équipe dynamique avec HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Comment ajouter des vidéos aux pages dynamiques"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Créer des pages dynamiques multi-niveaux via HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Créer une page avec une carte via HubDB"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}