"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158721871211;
const slug = exports.slug = 'getting-started/what-to-build';
const title = exports.title = 'Build-Inhalt';
const name = exports.name = 'Build-Inhalt (Entwicklerdokumente 2024)';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über die verschiedenen Möglichkeiten der Entwicklung mit HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "apis-verwenden-und-integrationen-erstellen",
  "label": "APIs verwenden und Integrationen erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "erste-schritte-bei-der-authentifizierung-von-aufrufen-mit-einer-privaten-app",
  "label": "Erste Schritte bei der Authentifizierung von Aufrufen mit einer privaten App",
  "parent": "apis-verwenden-und-integrationen-erstellen"
}, {
  "depth": 1,
  "id": "f%C3%BCr-den-app-marketplace-erstellen",
  "label": "Für den App Marketplace erstellen",
  "parent": "apis-verwenden-und-integrationen-erstellen"
}, {
  "depth": 0,
  "id": "die-hubspot-benutzeroberfl%C3%A4che-anpassen",
  "label": "Die HubSpot-Benutzeroberfläche anpassen",
  "parent": null
}, {
  "depth": 0,
  "id": "benutzerdefinierte-websites-erstellen",
  "label": "Benutzerdefinierte Websites erstellen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Build-Inhalt"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit HubSpot sind verschiedene Entwicklungswege möglich, je nach Ihren Zielen. Auf dieser Seite finden Sie einen umfassenden Überblick über die einzelnen Wege sowie die dafür benötigten Tools und Accounts. Dort finden Sie auch Links zu ausführlichen Dokumenten, die Ihnen weitere Informationen liefern, sowie zu relevanten Schnellstartanleitungen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#use-apis-and-build-integrations",
          children: "APIs verwenden und Integrationen erstellen"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#get-started-authenticating-calls-with-a-private-app",
              children: "Erste Schritte bei der Authentifizierung von Aufrufen mit einer privaten App"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#build-for-the-app-marketplace",
              children: "Für den App Marketplace erstellen"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#customize-the-hubspot-ui",
          children: "Die HubSpot-Benutzeroberfläche anpassen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-custom-websites",
          children: "Benutzerdefinierte Websites erstellen"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIs verwenden und Integrationen erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie die APIs von HubSpot, um benutzerdefinierte Lösungen zu erstellen, z. B. die Freigabe von Daten zwischen HubSpot und externen Systemen, die Verwendung von Webhooks, um auf Änderungen im Account zu überwachen, und die Erstellung benutzerdefinierter Objekte zum Speichern von spezifischen Daten für Ihr Unternehmen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiele für Anwendungsfälle:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verwenden Sie benutzerdefinierte Objekte, um die Art und Weise, wie das CRM Daten speichert, so anzupassen, dass es Ihr Unternehmen am besten darstellt."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Synchronisieren Sie Daten aus externen Systemen, um ein umfassenderes Bild der Go-to-Market-Aktivitäten zu erhalten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Erweitern Sie die Funktionen der CRM-Benutzeroberfläche, um sie optimal an Ihre Prozesse anzupassen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zwei Wege zum Erstellen von Apps"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Es gibt zwei ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "Typen von Apps"
        }), ", je nachdem, was Sie erstellen möchten. Wenn Sie mit APIs und Integrationen loslegen, empfiehlt es sich, mit einer privaten App zu beginnen, da diese schneller eingerichtet und für die Authentifizierung genutzt werden kann. Wenn Sie mit der Erstellung von Apps vertraut sind, möchten Sie vielleicht mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-for-the-app-marketplace",
          children: "öffentliche Apps"
        }), " erfahren, die in mehreren Accounts installiert werden können und Ihnen die Erstellung anderer Typen von Erweiterungen ermöglichen."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anwendungsbeispiel für private Apps:"
        }), " _„Ich möchte eine Anwendung für mein Unternehmen/Team erstellen.“"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Erste Schritte bei der Authentifizierung von Aufrufen mit einer privaten App"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die meisten API-Aufrufe erfordern eine Authentifizierung, um mit den Daten in Ihrem HubSpot-Account zu interagieren. Um mit Aufrufen an Ihrem Account zu beginnen, erstellen Sie eine private App und nutzen Sie deren Token für die Authentifizierung."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Es sind ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "Super-Admin"
        }), "-Berechtigungen erforderlich, um private Apps in Ihrem HubSpot-Account zu erstellen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So können Sie loslegen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "private App erstellen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
          children: "Durchführen von authentifizierten API-Aufrufe mit privaten Apps"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie können auch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/overview",
          children: "Client Bibliotheken"
        }), " verwenden, um API-Aufrufe durchzuführen"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Für den App Marketplace erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entwickeln Sie Integrationen für den App Marketplace von HubSpot mit einer öffentlichen App, damit HubSpot-Benutzer Ihre Integration in ihrem Account installieren können. Beim Erstellen von öffentlichen Apps für den App Marketplace müssen die Richtlinien von HubSpots App Marketplace eingehalten werden und es ist ein Account für App-Entwickler erforderlich."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Öffentliche Apps authentifizieren mit OAuth. Öffentliche Apps können in mehreren Accounts installiert und über den Marketplace verbreitet werden."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Beispiele für Anwendungsfälle für öffentliche Anwendungen:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_„Ich bin ein HubSpot-Partner und möchte eine wiederverwendbare App erstellen, die ich für meine Kunden anpassen kann.“"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_„Ich bin ein HubSpot-Partner, der eine App im Marketplace verfügbar machen möchte, um unsere Fähigkeiten so vielen HubSpot-Kunden wie möglich zu präsentieren.“"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So können Sie loslegen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-hubspot/developers",
            children: "Einen Account für App-Entwickler erstellen"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Schnellstart:"
          }), " ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "Eine öffentliche App erstellen"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/oauth-quickstart-guide",
            children: "Der OAuth-Schnellstartanleitung folgen"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
            children: "Die Anforderungen für den App Marketplace ansehen"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "Ihre App im App Marketplace einreichen"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die HubSpot-Benutzeroberfläche anpassen"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Das Erstellen von UI-Erweiterungen erfordert ein ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sales Hub"
          })
        }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Service Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "-Abonnement. Sie können jedoch in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "Entwickler-Test-Accounts"
        }), " kostenlos mit der Erstellung beginnen."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zusätzlich zu den UI-Elementen, die HubSpot für CRM-Datensätze bereitstellt, können Sie das CRM auch mit UI-Erweiterungen anpassen. Diese Erweiterungen werden lokal mit dem Tool für Entwicklerprojekte erstellt, das es Ihnen ermöglicht, HubSpot mithilfe des CLI zu erstellen und bereitzustellen. Das SDK für UI-Erweiterungen bietet eine Reihe von Methoden, Funktionalitäten, Tools und Komponenten, mit denen Sie Ihre Erweiterung anpassen können. Wenn Sie nicht sicher sind, wo Sie mit UI-Erweiterungen beginnen sollen, sehen Sie sich ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "die Beispielprojekte von HubSpot"
      }), " an."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Projekte ermöglichen es Ihnen, private Apps, UI-Erweiterungen und serverlose Funktionen mithilfe des HubSpot CLI lokal zu erstellen und bereitzustellen."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Beispiele für Anwendungsfälle für UI-Erweiterungen:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_„Ich möchte ein benutzerdefiniertes Formular zu Kontakt- und Unternehmensdatensätzen hinzufügen, das es unserem Kundensupport-Team ermöglicht, Jira-Tickets während eines Kundengesprächs zu erstellen.“"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_„Ich muss detaillierte Sales-Pipeline-Zusammenfassungen in den Datensätzen der Deals anzeigen, damit unsere verwaltenden Partner die benötigten Informationen auf einen Blick finden können.“"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So können Sie loslegen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie kein ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " oder ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Service Hub"
          }), " Enterprise"]
        }), "-Abonnement haben, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "erstellen Sie einen Entwickler-Test Account"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nehmen Sie an der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview#get-started",
          children: "Beta-Version des Entwicklerprojekts"
        }), " teil"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Schnellstart:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "Schnellstartanleitung für UI-Erweiterungen"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "SDK für UI-Erweiterungen"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Benutzerdefinierte Websites erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit der CMS-Software (Contentmanagement-System) von HubSpot können Sie leistungsstarke Websites und Landingpages erstellen, die sich an die Besucher Ihrer Website anpassen und auf sie zugeschnitten sind. Durch das Erstellen auf Grundlage des CMS ist jede Website-Besucherinteraktion direkt mit dem CRM des Accounts verbunden, demselben Tool, das die Teams von Vertrieb, Marketing, Services und RevOps nutzen, um ihre Arbeit effizienter zu erledigen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erstellen Sie HubSpot-Websites lokal in der von Ihnen bevorzugten Umgebung mit dem HubSpot CLI, wobei Sie Technologien wie diese verwenden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML, CSS und JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubL, die Jinjava-basierte Vorlagensprache von HubSpot"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "React, das für die Erstellung von JavaScript-gerenderten Modulen und globalen Teilvorlagen verwendet werden kann"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GraphQL, das Sie zur Abfrage von HubSpot-Daten nutzen können"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Und wenn Sie lieber GitHub für die Versionskontrolle verwenden, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "mithilfe von GitHub-Aktionen eine Integration einrichten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Das CMS ermöglicht es Ihnen, Websites lokal mit der HubSpot CLI zu erstellen. Sie haben Zugriff auf ein Array vielseitiger und anpassungsfähiger Tools, z. B. mehrsprachige Unterstützung, CTAs, Formulare, Zugriffsberechtigungen und mehr. HubSpot kümmert sich auch um die Bereiche Sicherheit, serverseitige Performance, CDN, Hosting und Betriebszeitgarantie für Sie."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Beispiele für Anwendungsfälle für das CMS:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_„Ich möchte Produkt-Listing-Seiten erstellen, die Informationen direkt aus dem im CRM verwalteten Bestand beziehen.“"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "_„Ich benötige eine Website mit einem öffentlichen Blog und einem Blog nur für Mitglieder.“"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Einen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-hubspot/cms-developers",
          children: "CMS-Sandbox-Account"
        }), " erstellen"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        }), " installieren"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
          children: "Visual Studio Code-Erweiterung von HubSpot"
        }), " installieren"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Schnellstart:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "CMS Hub-Schnellstartanleitung"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms",
          children: "CMS-Leitfäden"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms",
          children: "Referenzdokumente"
        }), " ansehen"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}