"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35219164725;
const slug = exports.slug = 'guides/cms/content/data-driven-content/emails-with-programmable-content';
const title = exports.title = 'Criar e-mails com conteúdo programável';
const name = exports.name = 'Criar e-mails com conteúdo programável';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limites-de-envio-de-e-mail",
  "label": "Limites de envio de e-mail",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-criar-um-m%C3%B3dulo-de-e-mail-program%C3%A1vel",
  "label": "1. Criar um módulo de e-mail programável",
  "parent": null
}, {
  "depth": 1,
  "id": "objetos-padr%C3%A3o",
  "label": "Objetos padrão",
  "parent": "1.-criar-um-m%C3%B3dulo-de-e-mail-program%C3%A1vel"
}, {
  "depth": 1,
  "id": "objetos-personalizados",
  "label": "Objetos personalizados",
  "parent": "1.-criar-um-m%C3%B3dulo-de-e-mail-program%C3%A1vel"
}, {
  "depth": 1,
  "id": "hubdb",
  "label": "HubDB",
  "parent": "1.-criar-um-m%C3%B3dulo-de-e-mail-program%C3%A1vel"
}, {
  "depth": 0,
  "id": "2.-adicionar-o-m%C3%B3dulo-a-um-e-mail",
  "label": "2. Adicionar o módulo a um e-mail",
  "parent": null
}, {
  "depth": 0,
  "id": "mais-tutoriais-focados-no-hubdb",
  "label": "Mais tutoriais focados no HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-academy",
  "label": "HubSpot Academy",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Criar e-mails com conteúdo programável"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Esse recurso está no momento em beta. Ao usar esta funcionalidade, você concorda com os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "termos de versão beta do desenvolvedor"
        }), ". Este guia se refere à funcionalidade disponível somente através dessa versão beta. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "Participe da versão beta em seus modelos ou módulos."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use conteúdo programável para personalizar os e-mails com dados da sua conta da HubSpot usando HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As fontes de dados que você pode usar em um e-mail programável dependem da sua assinatura do HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você tiver uma assinatura ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), ", poderá usar os dados de objetos padrão do CRM, como contatos, empresas e produtos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você tiver uma assinatura ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ", também poderá usar fontes estruturadas como tabelas do ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDB"
        }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "objetos personalizados"
        }), ". Esses dados podem ser filtrados com base nas propriedades de contato do destinatário."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, um site imobiliário poderia ter um formulário para os prospects preencherem com suas necessidades residenciais. O envio de formulário pode acionar um fluxo de trabalho que envia ao prospect um e-mail com imóveis nos quais ele pode estar interessado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de envio de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode incluir as funções HubL de CRM ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " em um e-mail programável, mas qualquer e-mail que inclua essas funções específicas estará sujeito aos limites abaixo, que se baseiam no número de destinatários para os quais você está enviando:"]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Total de destinatários"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Número máximo de funções HubL do CRM"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "500.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "250.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "165.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "125.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "100.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "5"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Enviar um e-mail que atenda ou exceda esses limites vai atrasar ou cancelar o envio do e-mail."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além dos limites descritos acima, esteja ciente das advertências adicionais listadas abaixo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você clonar um e-mail programável, ele não poderá ser enviado enquanto o original ainda estiver em processamento. Você deve aguardar pelo menos 1 hora entre cada envio de e-mail."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você não pode conduzir um teste A/B para um e-mail programável que inclua uma função HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Criar um módulo de e-mail programável"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar o módulo de e-mail para acessar seus dados do HubDB ou do objeto personalizado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta da HubSpot, vá em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Arquivos e Modelos"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ferramentas de design"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No canto superior esquerdo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Arquivo"
          }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Novo arquivo"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na caixa de diálogo, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Módulo"
          }), " para o tipo de arquivo e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Próximo"
          }), ". Em seguida, marque a caixa de seleção ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "E-mails"
          }), " e insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), " para o arquivo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Para ativar o e-mail programável para o módulo:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["No inspetor à direita, ative a opção ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Ativar módulo para versão beta do e-mail programável"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/programmable-email-switch0.png",
            alt: "programmable-email-switch0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Você também pode habilitar o e-mail programável para um modelo de e-mail codificado adicionando ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "isEnabledForEmailV3Rendering: true"
          }), " à parte superior do arquivo."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/enable-design-manager-template-for-programmable-email.png?width=510&name=enable-design-manager-template-for-programmable-email.png",
            alt: "enable-design-manager-template-for-programmable-email"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o módulo criado, você adicionará o código para acessar os dados do seu CRM. Os exemplos a seguir demonstram como consultar de diferentes fontes de dados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objetos padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode usar as funções HubL, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " para consultar dados de objetos padrão em sua conta, como contatos, empresas ou produtos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O código a seguir usa a função HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " para consultar os dados de um produto com ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2444498793"
      }), " e renderizar o nome, a descrição e o preço:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set product = crm_object(\"product\", 2444498793, \"name,description,price\") %}\n\n<div>\n  <p>\n    <span>Name: </span>\n    <span>{{ product.name }}</span>\n  </p>\n  <p>\n    <span>Description: </span>\n    <span>{{ product.description }}</span>\n  </p>\n  <p>\n    <span>Price: </span>\n    <span>{{ product.price }}</span>\n  </p>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objetos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você tiver uma conta do ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), ", poderá consultar os dados de um objeto personalizado criado na sua conta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O código abaixo recupera dados de um objeto personalizado chamado ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Propriedade"
      }), ", retornando valores (por exemplo, local, preço) armazenados nas propriedades do objeto personalizado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Observe que o exemplo abaixo usa o nome totalmente qualificado do objeto personalizado como primeiro argumento ao invocar a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_objects"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "função do HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O nome totalmente qualificado começa com o ID da conta da HubSpot (prefixado com ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "), seguido por um sublinhado e o nome em plural em letra minúscula do objeto personalizado (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você pode recuperar um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "API de esquema de objetos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = crm_objects(\"p2990812_properties\", \"\", \"listing_name,location, price, address, type\") %}\n\n{% for home in real_estate_listings.results %}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se o nome do seu objeto personalizado contiver hifens (por exemplo, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "My-Custom-Object"
        }), "), suas propriedades ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " poderão ser renderizadas em um e-mail programável. Você pode recriar o objeto personalizado com os hifens omitidos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-custom-objects",
          children: "diretamente em sua conta da HubSpot"
        }), ", ou pode usar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "API de objetos personalizados"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para filtrar os dados retornados para cada destinatário, você pode adicionar um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), " que filtrará os resultados pelas propriedades de contato do destinatário. Veja a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "lista completa de opções de filtro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&city=\"~contact.city\"&order=listing_name\" %}\n{% set real_estate_listings = crm_objects(\"p2990812_Property\", query, \"listing_name,location, price, address, type\") %}\n{% for home in real_estate_listings.results %}\n\n  ...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você tiver uma conta do ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", poderá usar os dados da tabela HubDB no e-mail."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O código a seguir usa a função HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows"
        })
      }), " para recuperar todos os dados da tabela. Isso listará todas as propriedades imobiliárias no e-mail, apresentando os detalhes de cada propriedade junto com sua imagem."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = hubdb_table_rows(1234567) %}\n\n{% for home in real_estate_listings%}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image.url }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para filtrar os dados retornados para cada destinatário, você pode adicionar um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), " que filtrará os resultados pelas propriedades de contato especificadas. Veja a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb#filter-returned-rows",
        children: "lista completa de opções de filtro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&persona=\"~contact.hs_persona.value~\"&order=listing_name\" %}\n\n{% for home in real_estate_listings %}\n\n\t...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No exemplo acima, a propriedade de contato ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Budget max"
        }), " é referenciada com ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.budget_max"
        }), ", enquanto ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Persona"
        }), " é referenciada com ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.hs_persona.value"
        }), ". Isso ocorre porque ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Persona"
        }), " é uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/property-field-types-in-hubspot",
          children: "propriedade de enumeração"
        }), ", que requer um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".value"
        }), " adicional para analisar o valor da propriedade, diferentemente de outros tipos de propriedade."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Adicionar o módulo a um e-mail"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o módulo publicado, você o adicionará ao corpo do e-mail de arrastar e soltar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-mail"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "email"
        }), " que você criou."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda, em ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Conteúdo"
        }), ", clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mais"
        }), ". Localize seu módulo de e-mail programável e arraste-o para o corpo do email."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/drag-and-drop-email-editor-more-modules.png",
        alt: "drag-and-drop-email-editor-more-modules"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você tiver configurado o módulo para filtrar dados por propriedades de contato específicas, a visualização do e-mail aparecerá em branco. Isso ocorre porque a ferramenta de e-mail não foi definida para visualizar o e-mail como um contato específico."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para visualizar a aparência do e-mail como um contato específico:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ações"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/email-actions-menu0.png",
        alt: "email-actions-menu0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na próxima tela, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar como um contato específico"
        }), ", depois selecione um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "contato"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/preview-email-as-specific-contact0.png",
        alt: "preview-email-as-specific-contact0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agora você deve ver apenas os dados relevantes para o destinatário, filtrados por suas propriedades de contato."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/preview%20window.png",
        alt: "Captura de tela da visualização do e-mail"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se um módulo ou modelo de e-mail contiver código personalizado com HubL não resolvido, um módulo de espaço reservado aparecerá em seu lugar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/placeholder-module-in-programmable-email.png",
        alt: "placeholder-module-in-programmable-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "É importante ter sempre dados de fallback para enviar caso não haja linhas do HubDB ou registros de objetos personalizados que atendam aos critérios definidos. Caso contrário, o destinatário poderá receber um e-mail em branco."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Esta versão beta pode causar problemas com modelos existentes. É importante testar todas as alterações minuciosamente antes de publicar e enviar um e-mail."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mais tutoriais focados no HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Criar páginas dinâmicas com o HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Como unir várias tabelas HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Como criar uma página dinâmica de membro da equipe com o HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Como criar páginas dinâmicas multiníveis usando o HubDB"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/other_data_sources_hubdb_and_custom_objects",
          children: "Usar objetos personalizados e HubDB no CMS Hub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}