"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611090;
const slug = exports.slug = 'guides/cms/overview';
const title = exports.title = 'HubSpot CMS overview';
const name = exports.name = 'HubSpot CMS overview';
const metaDescription = exports.metaDescription = 'High level overview of the HubSpot Content Hub for developers, showing you all of the key concepts like themes, templates, and modules, and how they fit together.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "getting-started",
  "label": "Getting started",
  "parent": null
}, {
  "depth": 0,
  "id": "building-for-content-creators",
  "label": "Building for content creators",
  "parent": null
}, {
  "depth": 0,
  "id": "types-of-content",
  "label": "Types of content",
  "parent": null
}, {
  "depth": 1,
  "id": "website-pages-and-landing-pages",
  "label": "Website pages and landing pages",
  "parent": "types-of-content"
}, {
  "depth": 1,
  "id": "blogs",
  "label": "Blogs",
  "parent": "types-of-content"
}, {
  "depth": 1,
  "id": "emails",
  "label": "Emails",
  "parent": "types-of-content"
}, {
  "depth": 0,
  "id": "working-with-data",
  "label": "Working with data",
  "parent": null
}, {
  "depth": 1,
  "id": "serverless-functions",
  "label": "Serverless functions",
  "parent": "working-with-data"
}, {
  "depth": 0,
  "id": "developer-file-system",
  "label": "Developer file system",
  "parent": null
}, {
  "depth": 0,
  "id": "themes%2C-templates%2C-modules%2C-and-fields",
  "label": "Themes, templates, modules, and fields",
  "parent": null
}, {
  "depth": 0,
  "id": "the-hubl-language",
  "label": "The HubL Language",
  "parent": null
}, {
  "depth": 0,
  "id": "logged-in-pages",
  "label": "Logged in pages",
  "parent": null
}, {
  "depth": 0,
  "id": "multi-language-support",
  "label": "Multi-language support",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      img: "img",
      h3: "h3",
      strong: "strong",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot CMS overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This section is designed to help you understand key aspects of HubSpot's ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "CMS"
      }), " and build great websites on it. To get the most out of this, a professional-level understanding of web development basics, including HTML, JavaScript, and CSS, is expected."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Getting started"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you're just getting started with developing on HubSpot's CMS, it's recommended to begin with the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a free ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#app-developer-accounts",
          children: "developer account"
        }), ", then create a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "test account"
        }), " within it. This will give you a testing environment to build out your CMS assets without impacting a standard HubSpot account. Because you can also build private apps in developer test accounts, along with building public apps in developer accounts, you'll have one home for both CMS and app development. Alternatively, you can create a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#cms-sandbox-accounts",
          children: "CMS developer sandbox account"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "CMS quickstart guide"
        }), " to walk through some basics, such as using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate",
          children: "CMS theme boilerplate"
        }), ", running commands using the HubSpot CLI, and the relationship between local development and content creation in HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Building for content creators"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot's CMS is designed to help businesses grow their web presence with an emphasis on enabling marketers to create and manage web content. The website's content, lead collection, and analytics are integrated with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/crm",
        children: "HubSpot CRM"
      }), ", making it easy to create personalized experiences for visitors and integrate those experiences with the rest of the business."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A well-crafted website should be developed in close collaboration with your content creators to understand their needs. To that end, it's recommended that you ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow#testing",
        children: "preview how the page building experience looks and feels for content creators"
      }), " while you build. This ensures they can work independently with the site as much as possible."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/making%20a%20page.gif",
        alt: "Animated showing user creating a drag-and-drop page"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot takes care of hosting and maintaining your pages, so you don’t have to worry about plugin management, updates, hosting, scaling, or security. The tradeoff is that the system puts a few more restrictions on what you can do compared to self-hosted CMS's. For example, you can’t alter or extend system fundamentals manually or via plugins, manipulate low-level rendering, or access and alter database content directly."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Developer-built content (e.g., ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "themes"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "templates"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", JavaScript, and CSS) is created in a developer file system, while page content (pages, blog posts) is laid out and built in a powerful block-based what you see is what you get (WYSIWYG) editor, and media files (content creator-built images, PDFs, etc.) are stored in a web app-based file manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a page is rendered, HubSpot routes the request to one of many servers based on domain, renders the page on our servers, and caches it to a content delivery network (CDN) if possible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types of content"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are many types of content that you create using HubSpot's CMS. The user interface for content creators is slightly different depending on content type, which has implications that you as a developer need to be aware of."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Website pages and landing pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Website and landing pages are built independent of one another, but all pages are based on templates. For content creators, the process of building a landing page or a website page is nearly identical. The distinction between them is that website pages are made to present information that’s part of your website and designed to be found organically, while a landing page is ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/marketing/landing-page-best-practices",
        children: "generally associated with a specific marketing offer or campaign"
      }), " (e.g., linked from a marketing email sent to a specific list of contacts)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the UI for marketers, the analytics and organization of these page types are also organized separately since landing pages often have specific conversion goals."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot blogs have two views—one for the listing page and one for the individual post page, then each blog post is populated into each of them. You can set a blog to share the same template for blog posts and listing pages, or have separate templates for the listing page and for blog posts. Blog posts must share the same template. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "blog template markup"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/manage-your-blog-template-and-settings",
        children: "how to create and manage blogs in HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Emails"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Emails can be built in a few ways in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Classic email:"
        }), " build email templates and modules in a similar way to website and landing pages. You can also build ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/email-template-markup",
          children: "coded email templates"
        }), " to have full control of the markup."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Drag and drop emails:"
        }), " build customizable ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
          children: "drag and drop"
        }), " email templates that enable content creators to build email layout and content using HubSpot's drag and drop interface."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Working with data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to creating page content through the in-app editors or hard-coding in templates, you can also use structured data sources to populate ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "dynamic page content"
      }), " with HubL. You can use the following data sources to populate pages:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
          children: "HubDB"
        }), ": store data in cells of HubDB tables."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
          children: "CRM records"
        }), ": store data in CRM records, such as contacts, companies, or custom objects."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Building dynamic pages using structured content means that you can create, edit, and remove website pages and page content by updating the data sources directly. Similar to a HubSpot blog, a set of dynamic pages will include a single listing page to display the instances of your data source, then a separate page for each individual instance. Using HubL, you can fully configure the data that the pages display."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you can create a HubDB table that stores a row of information for each member of a sales team. Using that HubDB table, HubSpot can then generate a listing page to display key details from each table row (such as a name and image for each sales rep), along with a separate page per sales rep to display more information (such as their bio and phone number). Should a sales rep later be promoted to a different team, you can delete their row from the HubDB table, and HubSpot will automatically delete their detail page and remove them from the listing page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless functions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to using CRM records and HubDB data to populate pages, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverless functions"
      }), " to write server-side code that interacts with HubSpot and third-party services through APIs. Serverless Functions are a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " feature."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Developer file system"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The core assets—templates, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "themes"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", as well as the JavaScript, CSS files, and images that support them—are created in a developer file system. You can view this file system either in the left panel of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "design manager"
      }), " or in folders synchronized locally using the local development tools. Within the file system, assets can refer to each other with absolute or relative paths."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " React-based assets, such as some HubSpot default modules and custom CMS React assets, will not appear in the design manager file system. These assets are intended to only be worked on in your local environment using the HubSpot CLI to fetch and upload."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Behind the scenes, these files are mapped to entries in a database. This is why access to the developer file system is through the HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " tools rather than direct SSH or FTP access, and some file system features you may expect, like permissions and symlinks, are not offered in the developer filesystem."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This differs from the approach of traditional CMS's, but means that broken references between file or syntax errors are caught at publish time rather than at runtime, providing you with extra insulation against accidental failures when live traffic is hitting a website."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Templates in the file system will be automatically detected and will be presented to content creators as they’re making new pages, so the structure of the file system is up to you. There’s no requirement that modules live in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/modules/"
      }), " folder or JavaScript lives in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/js/"
      }), " folder. However, it's recommended to organize your assets in a similar way to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "boilerplate example code for the CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " by default, HubSpot automatically minifies JavaScript and CSS included in the design manager to remove unnecessary spaces, line breaks, and comments. This also applies to JavaScript and CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "uploaded to the design manager through the CLI"
        }), ". This means that you should not add already minified code directly to the design manager."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-and-css-minification",
          children: "JavaScript and CSS minification"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Themes, templates, modules, and fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Themes"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "templates"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "fields"
      }), " are the objects you’ll work with most. Using these different objects effectively lets you give content creators the freedom to work and iterate on websites independently while staying inside style and layout guardrails you set."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Themes and modules contain fields, which are settings of specific data types, such as numbers, strings, rich text, and images. You can control how these are used in rendering these objects, as well as how they should be organized and appear in the ", (0, _jsxRuntime.jsx)("abbr", {
        title: "What You See Is What You Get",
        children: "WYSIWYG"
      }), " editor. Content creators can set values for fields in the WYSIWYG editor, which are applied to the theme or module at render time."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/cms/content/overview",
        children: "CMS building blocks overview"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "The HubL Language"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The main language that you'll use to build website assets on HubSpot's CMS is the HubSpot Markup Language or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), " (pronounced “Hubble”). HubL is HubSpot’s extension of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", a templating engine based on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://palletsprojects.com/p/jinja/",
        children: "Jinja"
      }), ". HubL uses a fair amount of markup that is unique to HubSpot and does not support all features of Jinja. It’s executed completely on the server-side when a page is rendered."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL has the features you’d expect of a simple templating language like ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "variables"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for loops"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if statements"
      }), ", but also supports more complex rendering ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macros"
      }), ", data fetching, and mapping with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "tags"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "functions"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filters"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you reach the limits of what's possible with HubL, HubSpot provides APIs for creating more customized solutions. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " accounts can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverless functions"
      }), ", enabling more sophisticated server side programming."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL language reference"
      }), " for more details on specific language features."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logged in pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "Membership"
      }), " feature of ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", you can require your CRM contacts to be logged in to view specific content of your site. Content behind membership pages can be highly personalized to the logged-in contact, and can even render Contacts, Companies, Deals and Products data from the CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multi-language support"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With HubSpot’s CMS, users can create ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "multi-language variations"
      }), " of their content. This will allow end-users to see content in the language with which they’re most comfortable. In addition, HubSpot provides tools to help developers ensure that the right language is available to the end-user."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}