"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279929;
const slug = exports.slug = 'guides/cms/content/content-staging';
const title = exports.title = 'Preparação de conteúdo';
const name = exports.name = 'PT BR Content Staging';
const metaDescription = exports.metaDescription = 'A preparação de conteúdo é um ambiente de desenvolvimento no aplicativo que permite reformular ou criar páginas preparadas antes de publicá-las no site de produção.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Preparação de conteúdo"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A preparação de conteúdo é um ambiente separado no aplicativo que permite atualizar ou criar páginas preparadas antes de publicá-las no site de produção."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A preparação de conteúdo pode ser usada durante um projeto de reformulação de site para organizar as páginas existentes usando um novo modelo. As análises associadas à página serão transferidas para a nova página no momento da publicação a partir do ambiente de preparação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como as reformulações geralmente envolvem a atualização de mais de uma página, você pode preparar todas as páginas de que precisa e publicá-las no site de uma só vez."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "Usar a preparação de conteúdo em uma reformulação de site"
      }), " para obter instruções detalhadas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter um tutorial completo sobre como usar a preparação de conteúdo, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "confira este artigo no Guia do usuário do site"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar e testar alterações em ativos como temas, modelos e módulos, recomendamos usar uma conta de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "sandbox do desenvolvedor"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Saiba mais sobre como criar um fluxo de trabalho de desenvolvimento eficiente."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}