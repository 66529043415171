"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937497;
const slug = exports.slug = 'guides/api/crm/objects/products';
const title = exports.title = 'CRM API | 製品';
const name = exports.name = 'vNext Docs DP - 製品';
const metaDescription = exports.metaDescription = 'HubSpotの製品は、販売する商品やサービスを表します。製品エンドポイントを使用することにより、製品データを管理したり同期したりできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E8%A3%BD%E5%93%81%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "製品を作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A3%BD%E5%93%81%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "製品を関連付ける",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%A6%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B%EF%BC%88%E8%A4%87%E6%95%B0%E3%81%AE%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%97%EF%BC%89",
  "label": "商品項目を作成して関連付ける（複数の呼び出し）",
  "parent": "%E8%A3%BD%E5%93%81%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%A6%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B%EF%BC%88%E5%8D%98%E4%B8%80%E3%81%AE%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%97%EF%BC%89",
  "label": "商品項目を作成して関連付ける（単一の呼び出し）",
  "parent": "%E8%A3%BD%E5%93%81%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "製品"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの製品は、販売する商品やサービスを表します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
        children: "製品ライブラリー"
      }), "を構築すると、効率的に製品を取引に追加したり、見積もりを作成したり、製品の実績についてレポートを作成したりできます。製品エンドポイントを使用すると、製品データを管理したり、HubSpotと他のシステムとの間で同期したりできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["製品は、会社、コンタクト、取引、チケット、商品項目、見積もりと同様にHubSpot CRMのオブジェクトです。オブジェクトのプロパティー、関連付け、関係性などの詳細は、HubSpotの「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRMオブジェクトについて"
      }), "」のガイドをご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**使用例：**セールス担当者が取引や見積もりなどに商品とサービスを簡単に追加できるように、製品APIを使用して製品カタログをHubSpotにインポートします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "製品を作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["製品を作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/products"
      }), "に送信します。リクエスト本文に、作成時に設定する製品プロパティーを含む", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "オブジェクトを含めます。後から同じエンドポイントに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを送信することで、製品のプロパティーを更新できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["利用可能な全ての製品プロパティーを表示するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "プロパティーAPI"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。製品プロパティーを取得する場合、リクエストURLは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/products"
      }), "になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to crm/v3/objects/products\n{\n  \"properties\": {\n    \"name\": \"Implementation Service\",\n    \"price\": \"6000.00\",\n    \"hs_sku\": \"123456\",\n    \"description\": \"Onboarding service for data product\",\n    \"hs_cost_of_goods_sold\": \"600.00\",\n    \"hs_recurring_billing_period\": \"P12M\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_recurring_billing_period"
        }), "の値は", (0, _jsxRuntime.jsx)(_components.code, {
          children: "P#M"
        }), "という形式（# は月数）であることに注意してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "製品を関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["製品自体を他のCRMオブジェクトに関連付けることはできません。ただし、製品の情報を取引または見積もりに関連付けるには、その製品に基づく", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "商品項目"
      }), "を作成できます。商品項目は製品の個々のインスタンスであり、製品とは別のオブジェクトであるため、製品自体を更新することなく、必要に応じて取引や見積もりで商品やサービスを調整できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、いずれか1つの製品が販売される取引をまとめるときには、まず製品から商品項目を作成し、それを取引に関連付けます。これを2つの別々の呼び出しで行うことも、商品項目を作成して関連付ける1つの呼び出しで行うこともできます。両方のオプションを以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**商品項目は、1つの単一親オブジェクトに属します。オブジェクトを関連付ける場合、商品項目はラインアイテムはオブジェクトごとに個別にする必要があります。例えば取引と見積もりを作成する場合は、取引用に1つの商品項目セットを作成し、見積もり用に別の商品項目セットを作成する必要があります。これは、オブジェクト間でCRMデータを合理化し、商品項目を変更する必要があるときに予期しないデータ損失が発生しないようにするのに役立ちます。例えば、見積もりを削除すると見積もりの商品項目が削除されます。それらの商品項目が取引に関連付けられている場合、取引の商品項目も削除されます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "商品項目を作成して関連付ける（複数の呼び出し）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["まず、IDが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567"
      }), "である製品に基づいて商品項目を作成します。利用可能な商品項目プロパティー全てのリストを取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "プロパティーAPI"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。商品項目のURLは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/line_items"
      }), "となります。既存の製品から商品項目を作成しているため、その製品から価格などのプロパティー値を継承します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レスポンスで返される商品項目IDを使用すると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "を使ってそれを取引に関連付けることができます。この例では、返された商品項目IDが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "7791176460"
      }), "であると仮定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["商品項目を既存の取引（ID：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "14795354663"
      }), "）に関連付けるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663"
      }), "に送信します。このリクエストでは、既定の関連付けタイプを使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "レスポンスで、次のような情報が返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663\n\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"14795354663\"\n      },\n      \"to\": {\n        \"id\": \"7791176460\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 19\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"7791176460\"\n      },\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 20\n      }\n    }\n  ],\n  \"startedAt\": \"2023-12-21T20:06:52.083Z\",\n  \"completedAt\": \"2023-12-21T20:06:52.192Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotでは、取引レコードの商品項目カードに「商品項目」が表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "商品項目を作成して関連付ける（単一の呼び出し）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["単一の呼び出しを使用して既存の製品から商品項目を作成し、取引に関連付けるには、商品項目の作成リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), "配列を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["商品項目を作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_item"
      }), "に送信します。リクエスト本文は次のようになります。商品項目と取引の関連付けの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), "は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "20"
      }), "であることに注意してください。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#association-type-id-values",
        children: "さまざまなタイプのCRMレコード間の関連タイプ"
      }), "について、詳しくご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "レスポンスで、新しい商品項目の詳細が返されます。HubSpotでは、取引レコードの商品項目カードに「商品項目」が表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}