"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125522;
const slug = exports.slug = 'reference/cms/modules/export-to-template-context';
const title = exports.title = 'export_to_template_context';
const name = exports.name = 'LATAM BR (PT) export_to_template_context';
const metaDescription = exports.metaDescription = 'export_to_template_context é um parâmetro que disponibiliza os parâmetros de um módulo para o ambiente de modelo sem realmente renderizar o módulo. Esse parâmetro pode ser usado em todas as tags HubL do módulo. A tag widget_data é usada para recuperar esses parâmetros, armazená-los em variáveis e/ou incorporá-los à lógica do seu modelo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "uso-em-m%C3%B3dulos-personalizados",
  "label": "Uso em módulos personalizados",
  "parent": null
}, {
  "depth": 0,
  "id": "imagens-de-fundo-selecion%C3%A1veis-pelo-usu%C3%A1rio",
  "label": "Imagens de fundo selecionáveis pelo usuário",
  "parent": null
}, {
  "depth": 0,
  "id": "campo-de-escolha-para-renderizar-marca%C3%A7%C3%A3o-predefinida",
  "label": "Campo de escolha para renderizar marcação predefinida",
  "parent": null
}, {
  "depth": 0,
  "id": "recupera%C3%A7%C3%A3o-dos-par%C3%A2metros-em-m%C3%B3dulos-j%C3%A1-renderizados-no-modelo",
  "label": "Recuperação dos parâmetros em módulos já renderizados no modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "impress%C3%A3o-das-informa%C3%A7%C3%B5es-do-m%C3%B3dulo-hubl-na-listagem-do-blog",
  "label": "Impressão das informações do módulo HubL na listagem do blog",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      pre: "pre",
      h2: "h2",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "export_to_template_context"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " é um parâmetro que disponibiliza os parâmetros de uma tag HubL para o ambiente de modelo sem realmente renderizar a tag HubL. Esse parâmetro pode ser usado com todas as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "tags HubL"
      }), ". O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " dict é usado para recuperar esses parâmetros, armazená-los em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "variáveis"
      }), " e/ou incorporá-los à lógica do seu modelo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao disponibilizar os parâmetros de uma tag HubL no contexto do modelo sem renderizá-la, você pode permitir que os usuários tomem decisões no editor de conteúdo que afetem a forma como o modelo é renderizado. Por exemplo, digamos que você queira renderizar apenas um determinado bloco de código quando o usuário atribuir um valor a um campo. Isso se torna possível com esse parâmetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Primeiro, você deve adicionar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " à tag HubL. Em seguida, você deve usar um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data.module.parameter_you_want_to_retreive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"job_title\" path=\"@hubspot/text\", label=\"Enter a Job Title\", value=\"Chief Morale Officer\", export_to_template_context=True %}\n{# Makes the parameters available to be stored and used in template logic #}\n\n{{ widget_data.job_title.body.value }}\n{# Prints the value of the HubL tag but can also be used in template logic #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo algumas aplicações deste conceito."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso em módulos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " não é suportado em módulos personalizados, pois não serve para eles em nenhum propósito real. Você não precisa usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " para obter o valor de um módulo dentro de um modelo, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "basta acessá-lo"
      }), ". Se você precisar ocultar visualmente a saída do módulo, poderá criar o módulo para que não haja saída ou incluir um campo booleano que habilite ou desabilite a renderização de algo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Imagens de fundo selecionáveis pelo usuário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neste exemplo, uma tag HubL de imagem é criada, mas depois exportada para o contexto do modelo, em vez de renderizada. O parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " é recuperado com a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " e renderizado como a fonte de uma imagem de fundo em uma tag de estilo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"background_image\" path=\"@hubspot/image\" label='Select a background image',\nsrc='http://cdn2.hubspot.net/hub/428357/file-2117441560-jpg/img/dev-bg-compressed.jpg',\nexport_to_template_context=True %}\n\n\n<!--Sample markup -->\n<div class=\"bg-image-section\" style=\"background:url('{{ widget_data.background_image.src }}'); background-size:cover; min-height: 500px;\">\n    <h1>Supercharge your app with HubSpot</h1>\n    <h3>Build powerful integrations for a global community of users</h3>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Embora isso seja possível em modelos codificados, é recomendado criar um módulo personalizado para oferecer a melhor experiência aos usuários no editor de páginas. Tags HubL como essa são exibidas como campos individuais, embora você possa ter vários campos relacionados. Em um módulo personalizado todos os seus campos são exibidos de modo agrupado no editor de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campo de escolha para renderizar marcação predefinida"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O exemplo a seguir usa o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " com um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#choice",
        children: "módulo de escolha"
      }), " para alterar uma mensagem de banner em uma página de carreiras. O usuário seleciona um departamento por meio da IU e o cabeçalho é alterado sem que ele precise editar o conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"department\" path=\"@hubspot/choice\", label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Essa mesma funcionalidade pode ser reproduzida usando um campo de escolha dentro de um módulo personalizado. A IU do módulo personalizado realmente torna as opções de escolha com um valor e um rótulo muito fáceis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperação dos parâmetros em módulos já renderizados no modelo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você quiser recuperar um parâmetro de um módulo ou tag renderizado em uma página, o módulo poderá ser acessado dentro de um dict chamado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgets"
      }), ". O parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " não é obrigatório. A sintaxe é a seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// HubL\n{{ content.widgets.name_of_module.body.parameter }}\n\n{{ content.widgets.my_text.body.value }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o método acima não é compatível com a recuperação de valores de campos em módulos globais, já que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content.widgets"
        }), " não acessará os módulos globais."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como campos diferentes armazenam dados em formatos diferentes, muitas vezes é útil utilizar as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "Informações do desenvolvedor"
      }), " para ver como acessar os dados específicos que deseja exibir."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Impressão das informações do módulo HubL na listagem do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Embora os modelos de blog sejam geralmente usados para blogs, eles também podem ser reaproveitados para criar vários outros tipos de listagens. Você pode usar as técnicas descritas acima para fazer isso."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, talvez você queira criar um layout de listagem de imprensa que sua empresa recebeu, mas em vez de vincular a posts, prefere que a listagem seja vinculada a outra página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ver esse conceito em ação em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/projects",
        children: "academy.hubspot.com/projects"
      }), ". A página de listagem de projetos é um modelo de listagem de blog, mas cada post vincula a uma página regular da HubSpot. O criador de conteúdo especifica o link de destino no editor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No cabeçalho do código do post do blog individual, você definiria um campo de texto. Se você não quiser que o texto seja renderizado no post, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"custom_blog_link\" path=\"@hubspot/text\", label=\"Link to external press item\", export_to_template_context=True %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esse campo de texto é editável em cada post do blog. Em seguida, é preciso definir um link na listagem. No entanto, como o widget_data só existe no contexto do post, é necessário usar uma sintaxe diferente para buscar os dados do widget para preencher o link. Nesse caso, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.widgets.custom_blog_link.body.value"
      }), ". Embora o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " não esteja disponível para a listagem do blog, o valor desse campo ainda é armazenado no contexto dos widgets do conteúdo individual."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo um loop básico de listagem de blog que renderiza esse link personalizado com cada post. Se estiver usando essa técnica, convém adicionar o subdiretório criado automaticamente para cada post do blog ao seu arquivo robots.txt para evitar que esses posts vazios sejam rastreados pelo Google e outros rastreadores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n<a href=\"{{ content.widgets.custom_blog_link.body.value }}\">\n    Click here to see this press feature!\n</a>\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}