"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611096;
const slug = exports.slug = 'reference/cms/modules/using-modules-in-templates';
const title = exports.title = 'テンプレートでモジュールを使用する';
const name = exports.name = '[new]テンプレートでモジュールを使用する';
const metaDescription = exports.metaDescription = 'モジュールとは、HubSpotページまたはEメールの編集可能な領域です。ドラッグ＆ドロップテンプレートビルダーを使用してテンプレートレイアウトにモジュールを追加することや、HubLを使用してモジュールを作成することができます。HubLモジュールは、コード テンプレート ファイル、HubLテンプレートモジュール、またはブログ コンテンツ モジュールで定義できます。以下では、全てのモジュールとそのパラメーターについて説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%9F%BA%E6%9C%AC%E7%9A%84%E3%81%AA%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E6%A7%8B%E6%96%87",
  "label": "基本的なモジュール構文",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%81%AB%E3%83%87%E3%82%A3%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%8A%E3%83%AA%E3%83%BC%E3%82%92%E6%B8%A1%E3%81%99",
  "label": "モジュールパラメーターにディクショナリーを渡す",
  "parent": null
}, {
  "depth": 1,
  "id": "dnd%E9%96%A2%E9%80%A3%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E6%B8%A1%E3%81%99",
  "label": "dnd関連パラメーターを使用するフィールドを渡す",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%81%AB%E3%83%87%E3%82%A3%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%8A%E3%83%AA%E3%83%BC%E3%82%92%E6%B8%A1%E3%81%99"
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "フィールドにテンプレートレベルの既定値を設定する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%8D%E3%82%B9%E3%83%88%E3%81%95%E3%82%8C%E3%81%9F%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "ネストされたフィールドグループの既定値を設定する",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E7%B9%B0%E3%82%8A%E8%BF%94%E3%81%97%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "繰り返しフィールドの既定値を設定する",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E7%B9%B0%E3%82%8A%E8%BF%94%E3%81%97%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "繰り返しフィールドグループの既定値を設定する",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "スタイルフィールドの既定値を設定する",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AE%E6%97%A2%E5%AE%9A%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%83%83%E3%82%AF%E6%A7%8B%E6%96%87",
  "label": "ブロック構文",
  "parent": null
}, {
  "depth": 0,
  "id": "content_attribute",
  "label": "content_attribute",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%85%A8%E3%81%A6%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%A7%E4%BD%BF%E7%94%A8%E5%8F%AF%E8%83%BD%E3%81%AA%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC",
  "label": "全てのモジュールで使用可能なパラメーター",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%83%99%E3%83%BC%E3%82%B9%E3%81%AE%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC",
  "label": "フィールドベースのパラメーター",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      img: "img",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "テンプレートでのモジュールの使用"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールは、テンプレートに直接追加したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "およびフレキシブルカラムを使用して、ページに個別に追加したりできます。モジュールがテンプレートに追加されると、そのモジュールは既定でその場所に表示されます。ドラッグ＆ドロップエリアやフレキシブルカラムにあるモジュールは、移動や削除が可能で、その他のモジュールもその周辺に追加できます。これがモジュールインスタンスです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールが定義されたら、必要に応じて", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "content.widgets ディクショナリー"
      }), "経由でテンプレートレベルのフィールド値を取得できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "基本的なモジュール構文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLタグは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% %}"
      }), "で区切られます。このタグで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "、一意の名前、モジュールのデザイン マネージャー パスを指定する必要があります。モジュールには、その他の設定のパラメーターを含めることもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**モジュール名：**テンプレートのコンテキスト内で一意に識別する名前をモジュールに付けます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "モジュールのタイプの後に、名前を引用符で囲んで指定する必要があり、名前ではスペースやダッシュの代わりに下線記号（アンダースコア）を使用する必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "この名前は、ページ／Eメールエディター内で設定されたコンテンツと、該当するHubLモジュールタグとをマッチングするのに使用されます。例えば、テンプレートの2か所のエリアに同名のHubLモジュールタグを記述した場合、ユーザーがエディターで編集するモジュールは1つだけですが、そのモジュールに対する変更は両方のエリアに適用されます。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**パス：**タグに応じて、デザインマネージャーでのモジュールの場所を定義します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            }), "は現在のドライブのルートを意味します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "./"
            }), "は現在のディレクトリーを意味します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "../"
            }), "は現在のディレクトリーの親を意味します。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "HubSpotの既定のモジュール"
        }), "のパスは常に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/"
        }), "で始まり、その後にモジュールのタイプが続きます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**パラメーター：**モジュールインスタンスに関する追加的な設定として、その動作とレンダリング方法を指定します。モジュールフィールドのテンプレートレベルの既定値が含まれます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "パラメーターは、カンマで区切ったキーと値のペアです。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["パラメーターには、文字列、ブール型、整数、列挙、JSONリストオブジェクトなどのさまざまな型があります。文字列パラメーターでは値を一重引用符または二重引用符で囲む必要がありますが、ブール型パラメーターでは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), "の値を引用符で囲む必要はありません。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/using-modules-in-templates#parameters-available-for-all-modules",
              children: "すべてのモジュールで利用可能なパラメーター"
            }), "についての詳細をご確認ください。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["注：モジュールのフィールド設定と比較して、エディター内でフィールド値が検証されることはありません。例えば、最小値が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "に設定された数値フィールドがモジュール内にある場合、このパラメーターに", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), "を渡しても、値が無効だという警告は表示されません。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Basic syntax #}\n{% module \"unique_module_name\" path=\"@hubspot/module_type\",\n  parameterString='String parameter value',\n  parameterBoolean=True\n%}\n\n{# Sample of a default HubSpot text module #}\n{% module \"job_title\" path=\"@hubspot/text\",\n  label=\"Job Title\",\n  value=\"Chief Morale Officer\"\n%}\n\n{# Sample of a custom module #}\n{% module \"faqs\" path=\"/myWebsite/modules/faq_module\",\n  label=\"Custom FAQ Module\"\n  faq_group_title=\"Commonly Asked Questions\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールパラメーターにディクショナリーを渡す"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ディクショナリー（辞書型）を必要とするフィールドが使用されたモジュールには、ディクショナリーを他のパラメーターと同様に渡すことができます。分かりやすくしたり、値を再利用したりする場合は、ディクショナリーを変数に設定し、変数をパラメーターに渡すことができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_buttons\",\n  path=\"@hubspot/social_sharing\",\n  email={\n    \"default\": true,\n    \"enabled\": false,\n    \"img_src\": \"https://...\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "dnd関連パラメーターを使用するフィールドを渡す"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "などの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "ドラッグ＆ドロップタグ"
      }), "には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), "などの既定のパラメーターのセットがあります。デザインマネージャーでは、このような予約済みパラメーターのいずれかを使用するフィールドを新規に作成できませんが、ドラッグ＆ドロップタグが導入される前に作成されたモジュールは予約済みパラメーターを既に使用している場合があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これを修正するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), "パラメーターを使用できます。フィールドデータをグループに渡すのと同じようにして、フィールド名を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), "オブジェクトでキーとして渡すことができます。値には、フィールドタイプに必要な形式との一貫性が必要です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main_content\"%}\n     {% dnd_section %}\n        {% dnd_column %}\n          {% dnd_row %}\n            {% dnd_module path=\"@hubspot/divider\",\n               fields={width: \"90\"}\n            %}\n            {% end_dnd_module %}\n        {% end_dnd_row %}\n      {%end_dnd_column%}\n    {% end_dnd_section %}\n  {% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フィールドにテンプレートレベルの既定値を設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "タグにパラメーターを含めると、テンプレートレベルでモジュールフィールドの既定値を設定できます。以下では、ネストされたフィールドグループ、繰り返しフィールド、繰り返しフィールドグループ、およびスタイルフィールドに既定のフィールド値を設定する方法について説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ネストされたフィールドグループの既定値を設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下は、他のネストされたフィールドグループを含むカスタム", (0, _jsxRuntime.jsx)(_components.code, {
        children: "style"
      }), "フィールドグループを持つカスタムドラッグ＆ドロップモジュールの例です。テンプレートレベルの構成と、この同じグループ化がデザインマネージャーにどのように表示されるかを比較します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n path=\"/Nested_Module.module\"\n style={\n  \"group\":{\n  \"section\":{\n   \"color_field\":{\n   \"color\" : \"#000\",\n   \"opacity\" : 100\n    }\n   }\n  }\n }\n%}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/multi-level%20field%20nesting%20.png",
            alt: "複数階層フィールドのネスト "
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "繰り返しフィールドの既定値を設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["配列をフィールドのパラメーターに渡すことにより、繰り返しフィールドにテンプレートレベルの既定値を設定できます。配列の項目は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#field-based-parameters",
        children: "フィールドタイプ"
      }), "に基づいて想定される形式にする必要があります。以下に例を示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "単純なテキストフィールドでは文字列のみが想定されます"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["画像の繰り返しフィールドでは画像フィールドオブジェクトが想定されます。これは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-based-parameters",
          children: "他の全てのフィールドタイプ"
        }), "にも該当します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/recipe_card.module',\n  ingredients=[\"Eggs\",\"Ham\",\"Cheese\"]\n%}\n\n{% module \"my_repeater_module\" path=\"/img_repeater_module\", label=\"img_repeater_module\",\nimage=[\n  {\n    \"src\" : \"https://cdn2.hubspot.net/hubfs/428357/Developer%20Site/assets/logo/Developers-LOGO.svg\",\n    \"alt\" : \"HubSpot Developers\",\n    \"width\" : 254,\n    \"height\" : 31\n  },{\n    \"src\" : \"https://www.hubspot.com/hs-fs/hub/53/file-733888614-jpg/assets/hubspot.com/about/management/dharmesh-home.jpg\",\n    \"alt\" : \"Dharmesh\",\n    \"width\" : 394,\n    \"height\" : 394\n  }\n]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "繰り返しフィールドグループの既定値を設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "スライドショーモジュールやよくある質問モジュールなどに使用される、繰り返しフィールドグループを含むモジュールでは、グループにテンプレートレベルの既定値を設定できます。そのためには、フィールドグループのパラメーターにオブジェクトの配列を渡します。オブジェクトのキーと値のペアは、フィールド名とその値になります。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/slideshow.module',\n  slides=[\n    {\n      \"caption\":\"Cute dog looking up\",\n      \"image_url\":\"http://example.com/image.jpg\",\n    },\n    {\n      \"caption\":\"Cuter cat not looking amused\",\n      \"image_url\":\"http://example.com/image2.jpg\",\n    }\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "スタイルフィールドの既定値を設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "styles"
      }), "パラメーターを使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "スタイルフィールド"
      }), "の既定値を明示的に設定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは他のグループの場合と同様に機能します。パラメーターはグループの名前です。このパラメーターに、設定する全てのフィールドと共にオブジェクトを渡します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n    path=\"./path/to/module\",\n    styles={\n      \"background_color\":{\n          \"color\":\"#123\",\n          \"opacity\":50\n       }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブロック構文"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ほとんどのモジュールには既定のコンテンツを制御するパラメーターがありますがその際、モジュールの既定のコンテンツへの大きなコードブロックの追加が必要になることがあります。たとえば、リッチテキストまたはHTMLモジュールの既定のコンテンツとして大きなHTMLブロックを含めることがあります。valueパラメーターにこのコードを記述する代わりに、HubLブロック構文を使用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module_block module \"my_rich_text_module\" path=\"/My Rich Text Field Module\",\n  label=\"My Rich Text Field Module\"\n%}\n    {% module_attribute \"rich_text_field_variable\" %}\n       <div>My HTML block</div>\n    {% end_module_attribute %}\n{% end_module_block %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "module_block"
        }), "構文が導入される前は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), "が使用されていました。同じパターンに従いますが、開始タグは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_attribute"
        }), "でした。終了タグは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_attribute"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_block"
        }), "でした。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), "構文は非推奨になりましたが、古いコードを更新する必要はありません。"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), "（非推奨）の直後に続くパラメーターは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "パラメーターです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのほぼ全てのドキュメントでは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "を使用しています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "HubSpotのV2モジュール"
      }), "は、開発者が作成できるものと同様の通常のモジュールです。したがって、別の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "を使用する必要はなくなりました。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), "は非推奨になったため、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), "を使用してください。別の開発担当者からウェブサイトを引き継いだ場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "を使用した古いコードが含まれている場合があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), "や", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), "などのHubSpot V1モジュール名がサポートされます。追加のパラメーターはHubLの先頭行に追加できます。2行目で、ブロックのコンテンツが適用されるパラメーターを定義します。例えば、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), "モジュールではこれはhtmlパラメーターです。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), "モジュールでは、これはvalueパラメーターです（この2つの例については以下を参照してください）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# widget_block is deprecated, use module_block instead. This example is only to\nexplain what type_of_module was used for, for those with legacy code. #}\n{% widget_block rich_text \"my_rich_text_module\" overrideable=True, label='My rich-text module'  %}\n        {% widget_attribute \"html\" %}\n            <h2>New Module</h2>\n            <p>Add content here.</p>\n        {% end_widget_attribute %}\n{% end_widget_block %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力例",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<span\n  id=\"hs_cos_wrapper_my_rich_text_module\"\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n  style=\"\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"rich_text\"\n>\n  <h2>New Module</h2>\n  <p>Add content here.</p>\n</span>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "content_attribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["標準構文とブロック構文の他に、事前定義のコンテンツ変数の既定コンテンツとして大きなブロックを指定することがあります。この最も一般的な例として、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: "content.email_body"
      }), "変数があります。この変数では、コンテンツエディターで変更可能な、標準のEメール本文が出力されます。これは標準のHubLモジュールではないので、既定のコンテンツのブロックを指定するには", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "content_attribute"
      }), "タグを使用します。Eメール本文変数に既定のコンテンツ コード ブロックを格納する例を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% content_attribute \"email_body\" %}\n        <p>Hi {{ contact.firstname }},</p>\n        <p>Describe what you have to offer the customer. Why should they read? What did you promise them in the subject line? Tell them something cool. Make them laugh. Make them cry. Well, maybe don't do that...</p>\n        <p>Use a list to:</p>\n        <ul>\n            <li>Explain the value of your offer</li>\n            <li>Remind the reader what they’ll get out of taking action</li>\n            <li>Show off your skill with bullet points</li>\n            <li>Make your content easy to scan</li>\n        </ul>\n        <p><a href=\"http://hubspot.com\">LINK TO A LANDING PAGE ON YOUR SITE</a> (This is the really important part.)</p>\n        <p>Now wrap it all up with a pithy little reminder of how much you love them.</p>\n        <p>Aw. You silver-tongued devil, you.</p>\n        <p>Sincerely,</p>\n        <p>Your name</p>\n{% end_content_attribute %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "全てのモジュールで使用可能なパラメーター"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一部の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "特殊なパラメーター"
      }), "は使えるモジュールが限られますが、すべてのモジュールでサポートされるパラメーターを以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツエディターに表示されるモジュールの名前。また、このパラメーターを使ってユーザーに追加の指示を与えることもできます。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールをコンテンツエディターで編集できるかどうかを制御します。これはデザインマネージャーの__「コンテンツエディターでの編集を禁止する」設定に相当します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "no_wrapper"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "に設定すると、モジュールのコンテンツを囲むラッパーマークアップが削除されます。ページ上では、モジュールは特殊なクラスの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<div>"
            }), "で常にラップされます。このラッパーマークアップでは、プレビューペインでモジュールがクリックされた際の該当モジュールへのエディターのスクロールを実現します。ラッパーの削除が必要になることがあります。例えば、テキストモジュールを使ってアンカータグの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), "属性のリンク先を指定する場合などです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュールラッパーにクラスを追加します。複数のクラスを追加するには、クラスをスペースで区切って指定します。次に例を示します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes='full-width panel'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "export_to_template_context"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "に設定すると、HTML がレンダリングされる代わりに、このウィジェットのパラメーターがテンプレートコンテキストで使用可能になります。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/export-to-template-context",
              children: "このパラメーターとwidget_dataタグの使い方をご確認ください。"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unique_in_loop"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュールがループ内で定義されている場合、モジュール名をloop.indexと共に付加します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "に設定すると、ループの反復のたびに別のバージョンのモジュールが出力されます。モジュール名をloop.indexと共に付加します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのモジュールタイプとそのパラメーターのリストを確認するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "ここをクリックしてください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フィールドベースのパラメーター"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、使用できるフィールドベースのモジュールパラメーターについて説明します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "例"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "キー"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数（ブログID）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true／false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option_1\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "色"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"color\" : \"#ffffff\",  \"opacity\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["color ", (0, _jsxRuntime.jsx)("br", {}), "6文字の16進数形式 ", (0, _jsxRuntime.jsx)("br", {}), "不透明度", (0, _jsxRuntime.jsx)("br", {}), "0 - 100の範囲の整数", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTA"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列（CTA ID）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"fb9c0055-6beb-489d-8dda-3e1222458750\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "日付"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "タイムスタンプ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "日時"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "タイムスタンプ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールアドレス"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列（Eメールアドレス文字列）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"develop@hubspot.com\", \"design@hubspot.com\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ファイル"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列（ファイルのURL）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://cdn2.hubspot.net/hubfs/file.pdf\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "フォローアップEメール"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数（フォローアップEメールID）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "フォント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"size\" : 12,  \"size_unit\" : \"px\",  \"color\" : \"#000\",  \"styles\" :{    \"text-decoration\" : \"underline\"  },  \"font\" : \"Alegreya\",  \"fallback\" : \"serif\",  \"variant\" : \"regular\",  \"font_set\" : \"GOOGLE\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["size ", (0, _jsxRuntime.jsx)("br", {}), "単位タイプなしのフォントサイズ ", (0, _jsxRuntime.jsx)("br", {}), "size_unit ", (0, _jsxRuntime.jsx)("br", {}), "フォントサイズ単位文字列", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"px\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"pt\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"em\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"rem\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"%\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ex\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ch\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "color ", (0, _jsxRuntime.jsx)("br", {}), "16進カラーコード文字列 ", (0, _jsxRuntime.jsx)("br", {}), "styles ", (0, _jsxRuntime.jsx)("br", {}), "サポートされるプロパティー\"font-weight\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"bold\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"italic\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"none\" / \"underline\" ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "フォーム"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"form_id\" : \"9aa2e5f3-a46d-4774-897e-0bc37478521c\",  \"response_type\" : \"redirect\",  \"redirect_url\" : \"http://www.hubspot.com\",  \"redirect_id\" : null,  \"form_type\" : \"HUBSPOT\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["form_id ", (0, _jsxRuntime.jsx)("br", {}), "フォームのID。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/forms/find-your-form-guid",
              children: "フォームのIDを取得する方法。"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "response_type ", (0, _jsxRuntime.jsx)("br", {}), "\"redirect\" / \"inline\" ", (0, _jsxRuntime.jsx)("br", {}), "message ", (0, _jsxRuntime.jsx)("br", {}), "response_type \"inline\"を使用している場合に表示されるメッセージ。htmlをサポートする文字列。", (0, _jsxRuntime.jsx)("br", {}), "redirect_url ", (0, _jsxRuntime.jsx)("br", {}), "文字列。ウェブページへの絶対URL ", (0, _jsxRuntime.jsx)("br", {}), "redirect_id ", (0, _jsxRuntime.jsx)("br", {}), "リダイレクト先のページ／投稿ID", (0, _jsxRuntime.jsx)("br", {}), "form_type ", (0, _jsxRuntime.jsx)("br", {}), "\"HUBSPOT\" / \"TICKET_FORM\" ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDBテーブル"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数（HubDBテーブルのID）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "アイコン"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"name\" : \"align-center\",  \"unicode\" : \"f037\",  \"type\" : \"SOLID\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["name ", (0, _jsxRuntime.jsx)("br", {}), "アイコンの名前 ", (0, _jsxRuntime.jsx)("br", {}), "unicode ", (0, _jsxRuntime.jsx)("br", {}), "アイコンのフォントのUnicodeシンボル ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), "シンボルのスタイル。\"SOLID\" / \"REGULAR\" ", (0, _jsxRuntime.jsx)("br", {}), "パラメーターを適切に設定するには、アイコンフィールドを設定し、その方法で値を表示することをお勧めします。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "画像"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"src\" : \"https://cdn2.hubspot.net/hubfs/image.jpeg\",  \"alt\" : \"an_image\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["src ", (0, _jsxRuntime.jsx)("br", {}), "画像URL ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "スクリーンリーダーや検索エンジンで使用される画像代替テキスト ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "画像を表示する幅 ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "画像を表示する高さ ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "リンク"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"url\" : {     \"type\" : \"EXTERNAL\",    \"href\" : \"www.hubspot.com\",    \"content_id\" : null   },  \"open_in_new_tab\" : false,  \"no_follow\" : false }"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["url ", (0, _jsxRuntime.jsx)("br", {}), "URL data.typeを格納するオブジェクト ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "HubSpot以外の非EメールURLの場合は\"EXTERNAL\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "ページ、ブログ記事、ランディングページの場合は\"CONTENT\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "ファイルマネージャーにアップロードされるファイルの場合は\"FILE\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Eメールアドレスの場合は\"EMAIL_ADDRESS\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "ブログのリスティングページの場合は\"BLOG\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "リンク先URL。", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {}), "open_in_new_tab ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\"（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), "を追加するかどうかを決定する） ", (0, _jsxRuntime.jsx)("br", {}), "no_follow ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\"（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"nofollow\""
            }), "を使用するかどうかを決定する）", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ロゴ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"override_inherited_src\" : true,  \"src\" : \"https://cdn2.hubspot.net/hubfs/logo.png\",  \"alt\" : \"best_logo_ever\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["override_inherited_src ", (0, _jsxRuntime.jsx)("br", {}), "true/false（ポータル既定値をオーバーライド） ", (0, _jsxRuntime.jsx)("br", {}), "src ", (0, _jsxRuntime.jsx)("br", {}), "画像URL ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "スクリーンリーダーや検索エンジンで使用される画像代替テキスト。", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "画像を表示する幅 ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "画像を表示する高さ ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ミーティング"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列（ミーティングリンク）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://app.hubspot.com/meetings/developers-r-kewl\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "メニュー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数（メニューID）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ページ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数（ページID）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列（HTMLを含めることが可能）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"# Hello, world! <RelatedApiLink />\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforceキャンペーン"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列（SalesforceキャンペーンID）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"7016A0000005S0tQAE\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "シンプルメニュー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メニュー項目オブジェクトの配列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[   {     \"isPublished\" : true,    \"pageLinkId\" : 123456789,    \"pageLinkName\" : \"My page\",    \"isDeleted\" : false,    \"categoryId\" : 1,    \"subCategory\" : \"site_page\",    \"contentType\" : \"site_page\",    \"state\" : \"PUBLISHED_OR_SCHEDULED\",    \"linkLabel\" : \"This is a page\",    \"linkUrl\" : null,    \"linkParams\" : null,    \"linkTarget\" : null,    \"type\" : \"PAGE_LINK\",    \"children\" : [ ]  } ]"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["isPublished ", (0, _jsxRuntime.jsx)("br", {}), "true/false（メニュー項目のページが公開されるか）", (0, _jsxRuntime.jsx)("br", {}), "pageLinkId ", (0, _jsxRuntime.jsx)("br", {}), "CMSでのページID ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkName ", (0, _jsxRuntime.jsx)("br", {}), "CMSでのページの実際の名前 ", (0, _jsxRuntime.jsx)("br", {}), "isDeleted ", (0, _jsxRuntime.jsx)("br", {}), "true/false ", (0, _jsxRuntime.jsx)("br", {}), "categoryId ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "1 - サイトページ"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "3 - ブログ記事"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "subCategory ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "normal_blog_post"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "contentType ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "state ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "DRAFT"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "DRAFT_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_OR_SCHEDULED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "SCHEDULED"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "linkLabel ", (0, _jsxRuntime.jsx)("br", {}), "ユーザーが読んでクリックするテキスト ", (0, _jsxRuntime.jsx)("br", {}), "linkUrl ", (0, _jsxRuntime.jsx)("br", {}), "ユーザーがクリックしたときに移動先となる実際のURL ", (0, _jsxRuntime.jsx)("br", {}), "linkParams ", (0, _jsxRuntime.jsx)("br", {}), "\\# リンクまたは?クエリーパラメーター ", (0, _jsxRuntime.jsx)("br", {}), "linkTarget ", (0, _jsxRuntime.jsx)("br", {}), "「新規タブで開く」が有効になっている場合は\"_blank\"、そうでなければ\"null\" ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK_WITH_PARAMS\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"NO_LINK\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "children ", (0, _jsxRuntime.jsx)("br", {}), "メニュー項目オブジェクトの配列（個々のメニュー項目と同じ） ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "タグ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数（タグIDまたはスラッグ：IDが推奨される）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"it's like any other string\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"type\" : \"CONTENT\",  \"href\" : null,  \"content_id\" : 123456789}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\"（HubSpot以外の非EメールURLの場合）"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\"（ページ、ブログ記事、ランディングページの場合）"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\"（ファイルマネージャーにアップロードされるファイルの場合）"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\"（Eメールアドレスの場合）"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\"（ブログのリスティングページの場合）"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "文字列：リンクの先URL。", (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}