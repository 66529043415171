import { useEffect, useState } from 'react';
import { getDefaultNode } from 'UIComponents/utils/RootNode';
const bodyElement = getDefaultNode();
/**
 * Use for immersive experiences to prevent scrolling, such as modals or
 * open mobile menus.
 */
export const useFullscreen = (initialFullscreen = false) => {
  const [fullscreen, setFullscreen] = useState(initialFullscreen);
  useEffect(() => {
    setFullscreen(initialFullscreen);
  }, [initialFullscreen]);
  useEffect(() => {
    if (fullscreen) {
      bodyElement.style.height = '100vh';
      bodyElement.style.overflowY = 'hidden';
    } else {
      bodyElement.style.height = '';
      bodyElement.style.overflowY = '';
    }
  }, [fullscreen]);
  return [fullscreen, setFullscreen];
};