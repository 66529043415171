"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358695;
const slug = exports.slug = 'guides/api/crm/understanding-the-crm';
const title = exports.title = 'API de CRM | Comprender el CRM';
const name = exports.name = 'vNext DP - Comprender el CRM';
const metaDescription = exports.metaDescription = 'El CRM de HubSpot es un sistema para administrar las relaciones y los datos con los clientes. Descubre los tipos de objetos de CRM, las propiedades, las asociaciones, los propietarios y las interacciones. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "objetos",
  "label": "Objetos",
  "parent": null
}, {
  "depth": 1,
  "id": "relaciones-entre-objetos",
  "label": "Relaciones entre objetos",
  "parent": "objetos"
}, {
  "depth": 1,
  "id": "actividades-y-archivos-adjuntos",
  "label": "Actividades y archivos adjuntos",
  "parent": "objetos"
}, {
  "depth": 1,
  "id": "sincronizaci%C3%B3n-de-datos",
  "label": "Sincronización de datos",
  "parent": "objetos"
}, {
  "depth": 1,
  "id": "id-de-tipos-de-objeto",
  "label": "ID de tipos de objeto",
  "parent": "objetos"
}, {
  "depth": 0,
  "id": "acciones-por-lotes",
  "label": "Acciones por lotes",
  "parent": null
}, {
  "depth": 0,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades-predeterminadas",
  "label": "Propiedades predeterminadas",
  "parent": "propiedades"
}, {
  "depth": 1,
  "id": "propiedades-personalizadas",
  "label": "Propiedades personalizadas",
  "parent": "propiedades"
}, {
  "depth": 1,
  "id": "propietarios-de-registros",
  "label": "Propietarios de registros",
  "parent": "propiedades"
}, {
  "depth": 0,
  "id": "identificadores-%C3%BAnicos-e-id-de-objetos",
  "label": "Identificadores únicos e ID de objetos",
  "parent": null
}, {
  "depth": 1,
  "id": "identificadores-%C3%BAnicos-predeterminados",
  "label": "Identificadores únicos predeterminados",
  "parent": "identificadores-%C3%BAnicos-e-id-de-objetos"
}, {
  "depth": 1,
  "id": "creaci%C3%B3n-de-tus-propios-identificadores-%C3%BAnicos",
  "label": "Creación de tus propios identificadores únicos",
  "parent": "identificadores-%C3%BAnicos-e-id-de-objetos"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Comprender el CRM"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tu cuenta de HubSpot se fundamenta en una base de datos que almacena toda la información\nsobre tus relaciones y procesos comerciales, lo que se conoce como CRM (Customer\nRelationship Management). Para administrar estos datos, las cuentas de HubSpot incluyen\nobjetos, que representan tipos de relaciones o procesos. Las instancias individuales\nde objetos, llamadas registros, representan las entidades individuales bajo cada\ntipo de objeto (por ejemplo, John Smith es un contacto). Para almacenar datos en\ncada registro, usarás propiedades (por ejemplo, la propiedad del correo electrónico),\ny para representar las relaciones entre entidades individuales, puedes asociar registros\nentre sí (por ejemplo, asociar a John Smith con una empresa Smith & Co.) Además,\nlos registros del CRM pueden almacenar también información sobre las interacciones\na través de interacciones/actividades asociadas, como correos electrónicos, llamadas\ny reuniones. A continuación, aprende sobre objetos, registros, propiedades y funciones\nadicionales. Para obtener más información sobre la gestión de tu base de datos de\nCRM desde dentro de HubSpot, echa un vistazo a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/get-started/manage-your-crm-database",
        children: "Base de conocimientos de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La API de CRM ofrece acceso a objetos, registros y actividades. La siguiente lista explica los objetos disponibles en HubSpot. Los siguientes objetos tienen una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "página de índice"
      }), " dentro de HubSpot y pueden asociarse entre sí:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Contactos"
        }), ": almacenan información sobre una persona individual. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/contacts",
          children: "Ver puntos de terminación de contactos"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Empresas"
        }), ": almacenan información sobre una empresa u organización individual. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/companies",
          children: "Ver puntos de terminación de empresas"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Negocios"
        }), ": representan oportunidades de ingresos con un contacto o empresa. Se les hace seguimiento a través de las etapas de la pipeline, lo que da como resultado que el negocio se gane o se pierda. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/deals",
          children: "Ver puntos de terminación de negocios"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Tickets"
        }), ": representan solicitudes de ayuda o asistencia técnica de los clientes. Se les hace seguimiento a través de los estados de la pipeline, lo que da como resultado que el ticket se cierre. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/tickets",
          children: "Ver puntos de terminación de tickets"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
          children: "Llamadas"
        }), ": almacena información sobre llamadas con contactos, pero también puede asociarse con otros objetos como una interacción. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/calls",
          children: "Ver puntos de terminación de llamadas"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-quotes",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Cotizaciones"
          })
        }), ": representan información de precios compartida con compradores potenciales. Las cotizaciones se pueden asociar con contactos, empresas, negocios y líneas de pedido. Ver ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/commerce/quotes",
          children: "puntos de terminación de cotizaciones"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "Objetos personalizados"
        }), " (solo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "): crea un objeto personalizado para almacenar cualquier tipo de datos en HubSpot, en especial los datos que no cumplen con los objetos estándar enumerados anteriormente. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "Ver puntos de terminación de objetos personalizados"
        }), "Los siguientes objetos no tienen páginas de índice dentro de HubSpot, pero pueden asociarse con otros objetos:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
          children: "Actividades/Interacciones"
        }), " (llamadas, correos electrónicos, reuniones, notas, tareas, SMS, LinkedIn, WhatsApp, correo postal): representan las interacciones asociadas con tus registros, también llamadas actividades. Puedes asociar actividades con contactos, empresas, negocios, tickets y objetos personalizados Ver ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/engagement-details",
          children: "puntos de terminación de interacciones"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
          children: "Productos"
        }), ": representan bienes o servicios para la venta. Los productos no se pueden asociar con otros objetos de CRM, pero puedes crear elementos de pedido basados en productos y asociarlos con negocios y cotizaciones. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/products",
          children: "Ver puntos de terminación de productos"
        }), " Los - ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
          children: "elementos de pedido"
        }), ": representan productos y servicios individuales vendidos en un negocio. Los elementos de pedido se pueden crear a partir de productos existentes en tu biblioteca de productos, o se pueden crear como elementos de pedido independientes. Los elementos de pedido independientes no se agregarán a la biblioteca de productos. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/line-items",
          children: "Ver puntos de terminación de elementos de pedido"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Envíos de comentarios"
          })
        }), ": almacena la información enviada a una encuesta de comentarios. Los envíos de comentarios están asociados con registros de contactos. Ver ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/feedback-submissions",
          children: "puntos de terminación de envío de comentarios"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events",
          children: "Eventos de marketing"
        }), ": representan eventos relacionados con tus esfuerzos de marketing, incluyendo específicamente eventos de integraciones conectadas. Puedes especificar si un contacto asistió, se registró o canceló su asistencia a un evento de marketing. Ver ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/marketing/marketing-events",
          children: "puntos de terminación de eventos de marketing"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "Facturas"
        }), ": representan las facturas que envías por ventas realizadas. Las facturas se pueden asociar con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "contactos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "empresas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "negocios"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "elementos de pedido"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "descuentos"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " tarifas"
          })
        }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/taxes",
            children: "impuestos"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "Pagos"
        }), ": los pagos realizados por los compradores a través de facturas, enlaces de pago y cotizaciones. Los pagos pueden asociarse con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: " contactos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "empresas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "negocios"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "facturas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "cotizaciones"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "elementos de pedido"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "suscripciones"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "descuentos"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " tarifas"
          })
        }), " e**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " impuestos"
        }), ".**"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "Suscripciones"
        }), ": pagos recurrentes programados a través de enlaces de pago y presupuestos. Las facturas se pueden asociar con", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: " contactos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "empresas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "negocios"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "cotizaciones"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "elementos de pedido"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "pagos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "descuentos"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " tarifas"
          })
        }), " e**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " impuestos"
        }), "**."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-details",
          children: "Usuarios"
        }), ": representa a los usuarios en tu cuenta de HubSpot. Los usuarios no se pueden asociar con otros objetos de CRM, pero se pueden recuperar y actualizar a través de la API de usuarios. También puedes agregar usuarios a una cuenta con la API de aprovisionamiento de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "usuarios"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Relaciones entre objetos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En HubSpot, puedes mostrar cómo se relacionan los objetos entre sí mediante registros asociados. Por ejemplo, puedes asociar múltiples contactos con una empresa y luego asociar la empresa y los contactos relevantes con un acuerdo. Todas las cuentas de HubSpot tienen contactos, empresas, negocios, tickets y actividades, que pueden asociarse entre sí, como se muestra en el siguiente modelo. Si tienes acceso a una cuenta de HubSpot, puedes hacer una reseña de las relaciones de objetos únicas de tu cuenta navegando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-model-of-your-crm-object-and-activity-relationships",
        children: " la herramienta del modelo de datos."
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/data-model-overview-updated.gif",
        alt: "data-model-overview-updated"
      }), "Según tu suscripción, puedes describir la relación específica entre los registros usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "etiquetas de asociación"
      }), ", y tu cuenta puede tener objetos personalizados adicionales, que pueden asociarse con los otros objetos estándar. Otros objetos, como productos y elementos de pedido, solo pueden asociarse con ciertos objetos. Más información sobre estos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-objects",
        children: "objetos adicionales y sus asociaciones"
      }), ". Más información sobre las relaciones de objetos y la gestión de asociaciones utilizando los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "puntos de terminación de las asociaciones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Actividades y archivos adjuntos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "interacciones"
      }), ", también llamadas actividades, almacenan datos de interacciones con registros. Por ejemplo, si llamas a un prospecto, puedes registrar una llamada en el registro de contactos y también asociar la llamada con su empresa asociada. Las actividades posibles incluyen notas, tareas, reuniones, correos electrónicos, llamadas, correo postal, SMS, mensajes de LinkedIn y mensajes de WhatsApp. También puedes almacenar archivos adjuntos en los registros para hacer seguimiento de los archivos relevantes. Estos a menudo están relacionados con las interacciones. Más información sobre las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API de interacciones."
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sincronización de datos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No se requiere sincronización de datos de interacción para sincronizar datos de objetos. Debido a que un objeto puede asociarse con numerosas interacciones, también es importante tener en cuenta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "los límites de API"
      }), " antes de sincronizar. Sin embargo, es posible que desees sincronizar las interacciones en lugar de las propiedades cuando una integración es un precursor de una migración completa. En este caso, la sincronización de las interacciones en ambos sistemas garantizará que todos los usuarios tengan los datos que necesitan durante la transición. Por ejemplo, cuando un equipo de desarrollo de empresas trabaja en HubSpot, envía negocios a un representante de ventas interno que trabaja en otro CRM, deberías sincronizar las interacciones de manera que ambos equipos tengan el contexto que necesitan para cerrar una venta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ID de tipos de objeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando utilices ciertas API, deberás usar el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), ". A continuación, aparecen los valores de ID para cada objeto o actividad:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contactos"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Empresas"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-2"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Negocios"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-3"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tickets:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-5"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objetos personalizados"
        }), ": para encontrar el valor de ID para tu objeto personalizado, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/schemas"
        }), ". El valor se verá similar a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2-3453932"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Llamadas"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-48"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Correos"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-49"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reuniones"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-47"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Notas"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-46"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tareas"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-27"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Productos"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-7"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Facturas"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-52"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Elementos de pedido"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-8"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Pagos:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-101"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cotizaciones:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-14"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suscripciones:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-69"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Comunicaciones"
        }), " (SMS, LinkedIn, mensajes de WhatsApp): ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-18"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Correo postal"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-116"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eventos de marketing"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-54"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Envíos de feedback"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-19"
        }), "Si bien siempre puedes usar el valor de ID numérico, en algunos casos, también puedes usar el nombre del objeto para contactos, empresas, negocios, tickets o notas. Por ejemplo:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al iniciar una importación con la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/imports",
          children: "API de importaciones"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " especifica a qué objeto pertenecen los datos del archivo. Para importar datos de contactos, el valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), "podría ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cuando se utiliza la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de asociaciones"
        }), ", los valores ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), " especifican los objetos y la dirección de la asociación. Para ver los tipos de asociación para los contactos con las empresas, la URL de la solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " podría ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/contact/company/labels"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/0-1/0-2/labels"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Acciones por lotes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada objeto proporciona puntos finales por lote que te permiten crear, leer, actualizar y archivar varios registros de objetos en una sola solicitud. Los puntos de terminación por lote tienen un límite de 100 registros por llamada. Por ejemplo, la siguiente solicitud actualizaría por lotes dos registros de contacto identificados por su ID.", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"inputs\": [\n    {\n      \"id\": \"609051\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    },\n    {\n      \"id\": \"569751\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el caso de las acciones de creación por lotes, puedes habilitar errores de varios estados que te indiquen qué registros se crearon correctamente y cuáles no. Obtén más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/other-resources/error-handling#multi-status-errors",
        children: "configuración del manejo de errores de varios estados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La información sobre los registros se almacena en campos llamados propiedades, que luego se organizan en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "grupos"
      }), ". HubSpot proporciona un conjunto de propiedades predeterminadas para cada objeto. Además de las propiedades predeterminadas de cada objeto, puedes almacenar datos personalizados ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "creando propiedades personalizadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades predeterminadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los objetos de CRM se definen por un ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "tipo"
      }), " principal y un conjunto de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "propiedades"
      }), ". Cada tipo tiene un conjunto único de propiedades estándar, representado por un mapa de pares nombre-valor. Más información sobre las propiedades predeterminadas para diferentes objetos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contactos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Empresas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Negocios"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: "Envíos de comentarios"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties",
          children: "Facturas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Pagos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Suscripciones"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades personalizadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Crea ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "propiedades personalizadas"
      }), " para almacenar información específica para un objeto. Las propiedades personalizadas pueden administrarse a través de los puntos finales de las propiedades de CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propietarios de registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes asignar usuarios de HubSpot como", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: " propietarios"
      }), " de registros. Se puede asignar como propietario a cualquier usuario de HubSpot con acceso a un objeto y se pueden asignar varios propietarios a un objeto creando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/how-can-i-create-a-custom-owner-property",
        children: "propiedad personalizada"
      }), " para este propósito. Los propietarios solo pueden crearse en HubSpot, pero puedes usar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "punto final de los propietarios"
      }), " para obtener sus datos de identificación, incluidos los ID y las direcciones de correo electrónico. Estos datos pueden asignarse a registros de CRM en HubSpot o a llamadas de API de cambio de propiedad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identificadores únicos e ID de objetos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un identificador único es un valor que diferencia un registro en una base de datos de otro, incluso si tiene información idéntica. Por ejemplo, una base de datos de un banco podría tener registros para dos personas llamadas John Smith. Para evitar enviar dinero accidentalmente al John Smith equivocado, a cada registro se le asigna un número como su único ID. En HubSpot, usarás estos identificadores únicos para enviar tus datos a los registros correctos y administrar la desduplicación. Más información sobre las formas en que HubSpot maneja la desduplicación en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/deduplication-of-contacts-companies-deals-tickets",
        children: "Base de conocimientos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Identificadores únicos predeterminados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se crea un registro en HubSpot, se genera automáticamente un ID único que debe ser tratado como una cadena de texto. Estos ID solo son únicos dentro del tipo de objeto, por lo que puede haber un contacto y una empresa con el mismo ID. Para los contactos y las empresas, hay identificadores únicos adicionales, incluyendo el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " de un contacto y el nombre de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " de una empresa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Creación de tus propios identificadores únicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En muchos casos, puedes usar el ID de registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") generado por HubSpot para impulsar la lógica de tu integración. Sin embargo, es posible que tus datos requieran otras propiedades con valores únicos, o puede haber ocasiones en que el ID de registro no se pueda usar o complique la lógica de integración de tu aplicación. En estos casos, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "crear una propiedad de identificador único personalizada mediante la API de propiedades. "
      }), "Una vez que hayas creado una propiedad de ID único personalizado, puedes usarla en llamadas a la API para identificar y actualizar registros específicos de la misma manera que puedes usar", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " para contactos o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " para empresas. Por ejemplo, para recuperar un negocio basado en su valor para un propiedad de ID único personalizado, la URL de solicitud podría verse así: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: "https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}