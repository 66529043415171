"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866608851;
const slug = exports.slug = 'reference/api/overview';
const title = exports.title = 'API de HubSpot | Primeros pasos';
const name = exports.name = 'vNext Docs DP - Primeros pasos';
const metaDescription = exports.metaDescription = 'La API de HubSpot permite a los desarrolladores crear aplicaciones e integraciones personalizadas. Comienza leyendo nuestro resumen de API y crea una cuenta de desarrollador.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "configuraci%C3%B3n",
  "label": "Configuración",
  "parent": null
}, {
  "depth": 1,
  "id": "biblioteca-de-clientes",
  "label": "Biblioteca de clientes",
  "parent": "configuraci%C3%B3n"
}, {
  "depth": 0,
  "id": "documentaci%C3%B3n-de-la-api",
  "label": "Documentación de la API",
  "parent": null
}, {
  "depth": 1,
  "id": "documentos-de-referencia",
  "label": "Documentos de referencia",
  "parent": "documentaci%C3%B3n-de-la-api"
}, {
  "depth": 1,
  "id": "gu%C3%ADas-de-integraci%C3%B3n",
  "label": "Guías de integración",
  "parent": "documentaci%C3%B3n-de-la-api"
}, {
  "depth": 0,
  "id": "anunciar-la-aplicaci%C3%B3n",
  "label": "Anunciar la aplicación",
  "parent": null
}, {
  "depth": 0,
  "id": "asistencia-t%C3%A9cnica-y-recursos-de-la-comunidad",
  "label": "Asistencia técnica y recursos de la comunidad",
  "parent": null
}, {
  "depth": 0,
  "id": "creaci%C3%B3n-del-cms",
  "label": "Creación del CMS",
  "parent": null
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "creaci%C3%B3n-del-cms"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Table
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Documentación de referencia de la API de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La plataforma de desarrolladores de HubSpot es una parte central de nuestra misión para empoderar a las organizaciones para que puedan ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/grow-better",
        children: "crecer mejor"
      }), ". Las API están diseñadas para habilitar equipos de cualquier forma o tamaño para crear integraciones sólidas que los ayuden a personalizar y obtener el mayor valor de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todas las API de HubSpot se ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/Representational_state_transfer",
        children: "crean usando las convenciones de REST"
      }), " y están diseñadas para tener una estructura de URLs predecible. Utilizan muchas características HTTP estándar, incluidos los métodos (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), ") y los códigos de error de respuesta. Todas las llamadas a la API de HubSpot se realizan en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com"
      }), " y todas las respuestas devuelven JSON estándar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existen varias maneras de crear integraciones con HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para crear una integración interna para una cuenta individual de HubSpot (por ejemplo, quieres crear una aplicación que pueda acceder y editar solo partes autorizadas de tu cuenta para compartir o integrar con otras partes de tu organización), crea una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "aplicación privada"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si quieres crear una aplicación pública que se pueda instalar en varias cuentas de HubSpot, debes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
          children: "crear una cuenta de desarrollador"
        }), ". Existen varias razones para esto: una cuenta de desarrollador es donde creas aplicaciones de HubSpot, cada una autenticada con OAuth y con un conjunto de características y permisos configurables. También puedes usar tu cuenta de desarrollador para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "crear cuentas de prueba"
        }), ", monitorear el estado y el rendimiento de la aplicación o publicar aplicaciones en el Mercado de aplicaciones de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre los diferentes tipos de aplicaciones y tipos de cuenta en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#account-relationships",
        children: "este artículo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Biblioteca de clientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las bibliotecas de clientes están diseñadas para interactuar con las API de HubSpot con menos interferencias. Están escritas en distintos lenguajes y ayudan a cerrar la brecha entre la aplicación y las API de HubSpot. Eliminan la necesidad de conocer la URL y el método HTTP exactos que se deben usar para cada llamada de API entre otras cosas, de manera que puedas tener más tiempo para concentrarte en realizar tu aplicación. Más información sobre nuestras bibliotecas de clientes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/overview",
        children: "aquí"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Table, {
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("th", {
            children: "Lenguaje"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "Enlace de paquete"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "Código fuente"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_nodejs-512_339733.png",
              alt: "Node.Js"
            }), "Node.Js"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://www.npmjs.com/package/@hubspot/api-client",
              children: "npm install @hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-nodejs",
              children: "hubspot-api-nodejs"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/new-php-logo.png",
              alt: "PHP"
            }), "PHP"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://packagist.org/packages/hubspot/api-client",
              children: "composer require hubspot/api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/hubspot-api-php",
              children: "hubspot-api-php"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/ruby.png",
              alt: "Ruby"
            }), "Ruby"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://rubygems.org/gems/hubspot-api-client",
              children: "gem install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-ruby"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsxs)("td", {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            },
            children: [(0, _jsxRuntime.jsx)("img", {
              style: {
                maxWidth: '60px'
              },
              src: "https://f.hubspotusercontent00.net/hubfs/53/iconfinder_267_Python_logo_4375050.png",
              alt: "Python"
            }), "Python"]
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("a", {
              href: "https://pypi.org/project/hubspot-api-client/",
              children: "pip install hubspot-api-client"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "%5Bhubspot-api-nodejs%5D(https://github.com/HubSpot/hubspot-api-nodejs)",
              children: "hubspot-api-python"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Documentación de la API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La documentación de la API de HubSpot se divide en dos secciones: documentos de referencia y guías de integración."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Documentos de referencia"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos los documentos de referencia de la API incluyen una sección de resumen y una sección de puntos finales. El resumen de la API incluye un resumen breve de su funcionalidad, casos de uso y consideraciones especiales para crear una integración. La sección de puntos finales contiene cada punto final, sus parámetros y ejemplos en varios lenguajes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas configurado los valores de configuración de autorizaciones de la aplicación en tu cuenta de desarrollador, puedes usar Postman o realizar llamadas de prueba directamente desde una página de referencia de punto final."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guías de integración"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres aprender los aspectos básicos de la plataforma de HubSpot o ver un ejemplo antes de realizar la primera llamada a la API, puedes encontrar aplicaciones de muestra y tutoriales, además de información detallada sobre las cuentas de desarrollador, trabajo con OAuth, límites de tasa de API y más en nuestra sección ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "Guías de integración"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Relacionado:"
      }), " Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "métodos de autentificación"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anunciar la aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando estés listo para compartir tu aplicación con el mundo y convertirte en partner de aplicaciones, lee nuestros requisitos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "anuncio"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "certificación"
      }), ". Después de eso, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "crear"
      }), " y administrar tu anuncio en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Mercado de aplicaciones"
      }), " directamente desde tu cuenta de desarrollador."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Asistencia técnica y recursos de la comunidad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén respuestas a tus preguntas, establece contactos y comparte tus opiniones al unirse a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "foros de la comunidad de desarrolladores"
      }), " de HubSpot y a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/developer-slack",
        children: "comunidad de Slack"
      }), ". Estos foros son un excelente lugar para que tu voz sea escuchada, los comentarios de la comunidad son muy importantes para nosotros y para enriquecer nuestros esfuerzos continuos para mejorar la experiencia del desarrollador de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes mantenerte actualizado con nuevas características, anuncios y cambios importantes suscribiendo al blog ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Changelog"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Creación del CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["¿Quieres crear un sitio web, blog, página de destino, aplicación sencilla o un correo electrónico? Consulta nuestros ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "documentos de desarrollador de CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/overview",
        children: "Cómo usar las API de HubSpot"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}