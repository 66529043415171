"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529643;
const slug = exports.slug = 'reference/cms/hubl/loops';
const title = exports.title = 'Bucles for';
const name = exports.name = 'EMEA ES For loops';
const metaDescription = exports.metaDescription = 'Los bucles for se pueden utilizar en HubL para iterar a través de secuencias de objetos. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "propiedades-del-bucle",
  "label": "Propiedades del bucle",
  "parent": null
}, {
  "depth": 0,
  "id": "bucles-anidados",
  "label": "Bucles anidados",
  "parent": null
}, {
  "depth": 0,
  "id": "cycle",
  "label": "cycle",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-dentro-de-los-bucles",
  "label": "Variables dentro de los bucles",
  "parent": null
}, {
  "depth": 0,
  "id": "pares-clave-y-valor-en-los-bucles",
  "label": "Pares clave y valor en los bucles",
  "parent": null
}, {
  "depth": 0,
  "id": "iterar-un-n%C3%BAmero-determinado-de-veces",
  "label": "Iterar un número determinado de veces",
  "parent": null
}, {
  "depth": 0,
  "id": "uso-de-las-etiquetas-hubl-en-los-bucles",
  "label": "Uso de las etiquetas HubL en los bucles",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Bucles for"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los bucles for se pueden utilizar en HubL para iterar a través de secuencias de objetos. Lo más habitual es que se utilicen con la renderización del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "contenido del blog"
      }), " en formato de listado, pero también pueden utilizarse para ordenar otras variables de secuencia."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los bucles for comienzan con una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), " y terminan con una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% endfor %}"
      }), ". Dentro de la sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), " se nombra un solo elemento de la secuencia seguido de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "in"
      }), " y luego el nombre de la secuencia. El código entre las sentencias ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), " de apertura y cierre se imprime con cada iteración, y generalmente incluye la variable impresa del elemento individual de la secuencia. A continuación se muestra la sintaxis básica de un bucle for:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in items %}\n\t{{ item }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se presenta un ejemplo básico que muestra cómo imprimir una secuencia de valores de variables en una lista."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL y HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set languages = [\"HTML\", \"CSS\", \"Javascript\", \"Python\", \"Ruby\", \"PHP\", \"Java\"] %}\n\n<h1>Languages</h1>;\n<ul>\n    {% for language in languages %}\n    <li>{{ language }}</li>\n    {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Languages</h1>\n<ul>\n  <li>HTML</li>\n  <li>CSS</li>\n  <li>Javascript</li>\n  <li>Python</li>\n  <li>Ruby</li>\n  <li>PHP</li>\n  <li>Java</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propiedades del bucle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A medida que un bucle itera, puedes utilizar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "lógica condicional"
      }), " para definir el comportamiento del bucle. La propiedad variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), " mantiene una cuenta del número actual de las iteraciones del bucle. Hay otras propiedades de las variables de bucle que cuentan las iteraciones de diferentes maneras. Estas propiedades se describen a continuación:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.cycle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una función de ayuda para recorrer una lista de secuencias. Consulta la explicación más abajo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indica la profundidad de un bucle recursivo en la que se encuentra la renderización. Comienza en el nivel 1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indica la profundidad de un bucle recursivo en la que se encuentra la renderización. Comienza en el nivel 0"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.first"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esta variable se evalúa como verdadera, si es la primera iteración del bucle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La iteración actual del bucle. Esta variable empieza a contar en 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La iteración actual del bucle. Esta variable empieza a contar en 0."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.last"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esta variable se evalúa como verdadera, si es la última iteración del bucle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.length"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de elementos en la secuencia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de iteraciones desde el final del bucle. Cuenta regresiva hasta 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de iteraciones desde el final del bucle. Cuenta regresiva hasta 0."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestran algunos ejemplos que utilizan diferentes variables de bucle. El siguiente ejemplo básico utiliza ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), " para mantener una cuenta que se imprime con cada iteración."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL y HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set loopy = [\"Content\", \"Social\", \"Contacts\", \"Reports\"] %}\n{% for app in loopy %}\n    {{ loop.index }}. {{app}} <br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1. Content <br />\n2. Social <br />\n3. Contacts <br />\n4. Reports <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente ejemplo utiliza la lógica condicional para comprobar si la longitud del bucle es ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "divisibleby"
      }), " ciertos números. A continuación, se renderiza la anchura del div de post-item en consecuencia. El ejemplo utiliza el bucle estándar de post de blog y asume que hay 6 posts en el bucle."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL y HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n{% if loop.length is divisibleby 4 %}\n<div style=\"width:25%\">Post content</div>\n\n{% elif loop.length is divisibleby 3 %}\n<div style=\"width:33.33332%\">Post content</div>\n\n{% else %}\n<div style=\"width:50%\">Post content</div>\n\n{% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bucles anidados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los bucles también pueden anidarse con bucles. El bucle for secundario se ejecutará con cada iteración del bucle for principal. En el siguiente ejemplo, se imprime una lista de elementos secundarios en un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " anidado dentro de un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " de elementos principales."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL y HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set parents = [\"Parent item 1\", \"Parent item 2\", \"Parent item 3\"] %}\n{% set children = [\"Child item 1\", \"Child item 2\", \"Child item 3\"] %}\n<ul>\n{% for parent in parents %}\n<li>{{parent}}<ul>\n    {% for child in children %}\n    <li>{{child}}</li>\n    {% endfor %}\n    </ul>\n</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Parent item 1\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 2\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 3\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cycle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La etiqueta cycle puede utilizarse dentro de un bucle for para recorrer una serie de valores de cadena e imprimirlos en cada iteración. Una de las aplicaciones más prácticas de esta técnica es aplicar la alternancia de clases a los posts de tu blog en un listado. Esta etiqueta puede utilizarse en más de dos valores y repetirá el ciclo si hay más iteraciones del bucle que valores del ciclo. En el ejemplo siguiente, se aplica una clase ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "odd"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "even"
      }), " a los posts de un listado (el ejemplo supone que hay 5 posts en el bucle)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ten en cuenta que ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "no hay espacios"
      }), " entre los valores de la cadena de ciclos separados por comas."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL y HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    <div class=\"post-item {% cycle \"odd\",\"even\" %}\">Blog post content</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables dentro de los bucles"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Cualquier variable definida dentro de los bucles está limitada al ámbito de ese bucle y no puede ser llamada desde fuera del mismo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes llamar a las variables definidas fuera de un bucle, desde dentro de un bucle, pero no a la inversa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pares clave y valor en los bucles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si el diccionario de información que está recorriendo tiene pares clave y valor, un simple bucle for solo tendría acceso a los valores. Si deseas tener acceso tanto a las claves como a los valores dentro del bucle for, el HubL se formatearía así:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set dict_var = {\"name\": \"Cool Product\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{% for key, val in dict_var.items() %}\n    {{ key }}: {{ val }}<br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salidas",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "name: Cool Product <br />\nprice: $20 <br />\nsize: XL <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Iterar un número determinado de veces"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A veces se quiere iterar un número determinado de veces, esto puede ser útil para generar HTML o CSS. Puedes hacerlo con la función de rango."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL y HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for x in range(0,5) %}\n\t{{loop.index}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Salida renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1 2 3 4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso de las etiquetas HubL en los bucles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando agregas una etiqueta a la página HubSpot asignas automáticamente un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " al HTML que la envuelve. Esta etiqueta es única por su \"nombre\". En situaciones en las que se necesita utilizar una etiqueta en un bucle for, establecer nombres únicos no es práctico. Agrega el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unique_in_loop"
      }), " a tu etiqueta para generar identificadores únicos. Este parámetro agrega el nombre del módulo con el número de iteración del bucle actual, asegurando que es único. Los identificadores únicos no solo son necesarios para un HTML válido, sino que son importantes para la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "accesibilidad"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in module.icon_field %}\n\t{% icon\n\t\tname=\"{{ item.name }}\",\n\t\tstyle=\"{{ item.type }}\",\n\t\tunicode=\"{{ item.unicode }}\",\n        unique_in_loop=True\n\t%}\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}