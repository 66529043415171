"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611105;
const slug = exports.slug = 'reference/cms/hubl/filters';
const title = exports.title = 'Filtros HubL';
const name = exports.name = 'Filtros HubL';
const metaDescription = exports.metaDescription = 'Lista de filtros do HubL e exemplos para desenvolvedores do HubSpot CMS.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "abs",
  "label": "abs",
  "parent": null
}, {
  "depth": 0,
  "id": "add",
  "label": "add",
  "parent": null
}, {
  "depth": 0,
  "id": "attr",
  "label": "attr",
  "parent": null
}, {
  "depth": 0,
  "id": "batch",
  "label": "batch",
  "parent": null
}, {
  "depth": 0,
  "id": "between_times",
  "label": "between_times",
  "parent": null
}, {
  "depth": 0,
  "id": "bool",
  "label": "bool",
  "parent": null
}, {
  "depth": 0,
  "id": "capitalize",
  "label": "capitalize",
  "parent": null
}, {
  "depth": 0,
  "id": "center",
  "label": "center",
  "parent": null
}, {
  "depth": 0,
  "id": "convert_rgb",
  "label": "convert_rgb",
  "parent": null
}, {
  "depth": 0,
  "id": "cut",
  "label": "cut",
  "parent": null
}, {
  "depth": 0,
  "id": "datetimeformat-descontinuado",
  "label": "datetimeformat (descontinuado)",
  "parent": null
}, {
  "depth": 0,
  "id": "default",
  "label": "default",
  "parent": null
}, {
  "depth": 0,
  "id": "dictsort",
  "label": "dictsort",
  "parent": null
}, {
  "depth": 0,
  "id": "difference",
  "label": "difference",
  "parent": null
}, {
  "depth": 0,
  "id": "divide",
  "label": "divide",
  "parent": null
}, {
  "depth": 0,
  "id": "divisible",
  "label": "divisible",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_html",
  "label": "escape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_attr",
  "label": "escape_attr",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_jinjava",
  "label": "escape_jinjava",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_js",
  "label": "escape_js",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_url",
  "label": "escape_url",
  "parent": null
}, {
  "depth": 0,
  "id": "escapejson",
  "label": "escapejson",
  "parent": null
}, {
  "depth": 0,
  "id": "filesizeformat",
  "label": "filesizeformat",
  "parent": null
}, {
  "depth": 0,
  "id": "first",
  "label": "first",
  "parent": null
}, {
  "depth": 0,
  "id": "float",
  "label": "float",
  "parent": null
}, {
  "depth": 0,
  "id": "forceescape",
  "label": "forceescape",
  "parent": null
}, {
  "depth": 0,
  "id": "format",
  "label": "format",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency-descontinuado",
  "label": "format_currency (descontinuado)",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency_value",
  "label": "format_currency_value",
  "parent": null
}, {
  "depth": 0,
  "id": "format_date",
  "label": "format_date",
  "parent": null
}, {
  "depth": 0,
  "id": "format_datetime",
  "label": "format_datetime",
  "parent": null
}, {
  "depth": 0,
  "id": "format_time",
  "label": "format_time",
  "parent": null
}, {
  "depth": 0,
  "id": "fromjson",
  "label": "fromjson",
  "parent": null
}, {
  "depth": 0,
  "id": "geo_distance",
  "label": "geo_distance",
  "parent": null
}, {
  "depth": 0,
  "id": "groupby",
  "label": "groupby",
  "parent": null
}, {
  "depth": 0,
  "id": "indent",
  "label": "indent",
  "parent": null
}, {
  "depth": 0,
  "id": "int",
  "label": "int",
  "parent": null
}, {
  "depth": 0,
  "id": "intersect",
  "label": "intersect",
  "parent": null
}, {
  "depth": 0,
  "id": "ipaddr",
  "label": "ipaddr",
  "parent": null
}, {
  "depth": 0,
  "id": "join",
  "label": "join",
  "parent": null
}, {
  "depth": 0,
  "id": "last",
  "label": "last",
  "parent": null
}, {
  "depth": 0,
  "id": "length",
  "label": "length",
  "parent": null
}, {
  "depth": 0,
  "id": "list",
  "label": "list",
  "parent": null
}, {
  "depth": 0,
  "id": "log",
  "label": "log",
  "parent": null
}, {
  "depth": 0,
  "id": "lower",
  "label": "lower",
  "parent": null
}, {
  "depth": 0,
  "id": "map",
  "label": "map",
  "parent": null
}, {
  "depth": 0,
  "id": "md5",
  "label": "md5",
  "parent": null
}, {
  "depth": 0,
  "id": "minus_time",
  "label": "minus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "multiply",
  "label": "multiply",
  "parent": null
}, {
  "depth": 0,
  "id": "plus_time",
  "label": "plus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "pprint",
  "label": "pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "random",
  "label": "random",
  "parent": null
}, {
  "depth": 0,
  "id": "regex_replace",
  "label": "regex_replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reject",
  "label": "reject",
  "parent": null
}, {
  "depth": 0,
  "id": "rejectattr",
  "label": "rejectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "render",
  "label": "render",
  "parent": null
}, {
  "depth": 0,
  "id": "replace",
  "label": "replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reverse",
  "label": "reverse",
  "parent": null
}, {
  "depth": 0,
  "id": "root",
  "label": "root",
  "parent": null
}, {
  "depth": 0,
  "id": "round",
  "label": "round",
  "parent": null
}, {
  "depth": 0,
  "id": "safe",
  "label": "safe",
  "parent": null
}, {
  "depth": 0,
  "id": "sanitize_html",
  "label": "sanitize_html",
  "parent": null
}, {
  "depth": 0,
  "id": "select",
  "label": "select",
  "parent": null
}, {
  "depth": 0,
  "id": "selectattr",
  "label": "selectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "shuffle",
  "label": "shuffle",
  "parent": null
}, {
  "depth": 0,
  "id": "slice",
  "label": "slice",
  "parent": null
}, {
  "depth": 0,
  "id": "sort",
  "label": "sort",
  "parent": null
}, {
  "depth": 0,
  "id": "split",
  "label": "split",
  "parent": null
}, {
  "depth": 0,
  "id": "string",
  "label": "string",
  "parent": null
}, {
  "depth": 0,
  "id": "striptags",
  "label": "striptags",
  "parent": null
}, {
  "depth": 0,
  "id": "strtotime",
  "label": "strtotime",
  "parent": null
}, {
  "depth": 0,
  "id": "sum",
  "label": "sum",
  "parent": null
}, {
  "depth": 0,
  "id": "symmetric_difference",
  "label": "symmetric_difference",
  "parent": null
}, {
  "depth": 0,
  "id": "title",
  "label": "title",
  "parent": null
}, {
  "depth": 0,
  "id": "tojson",
  "label": "tojson",
  "parent": null
}, {
  "depth": 0,
  "id": "trim",
  "label": "trim",
  "parent": null
}, {
  "depth": 0,
  "id": "truncate",
  "label": "truncate",
  "parent": null
}, {
  "depth": 0,
  "id": "truncatehtml",
  "label": "truncatehtml",
  "parent": null
}, {
  "depth": 0,
  "id": "unescape_html",
  "label": "unescape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "union",
  "label": "union",
  "parent": null
}, {
  "depth": 0,
  "id": "unique",
  "label": "unique",
  "parent": null
}, {
  "depth": 0,
  "id": "unixtimestamp",
  "label": "unixtimestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "upper",
  "label": "upper",
  "parent": null
}, {
  "depth": 0,
  "id": "urlencode",
  "label": "urlencode",
  "parent": null
}, {
  "depth": 0,
  "id": "urldecode",
  "label": "urldecode",
  "parent": null
}, {
  "depth": 0,
  "id": "urlize",
  "label": "urlize",
  "parent": null
}, {
  "depth": 0,
  "id": "wordcount",
  "label": "wordcount",
  "parent": null
}, {
  "depth": 0,
  "id": "wordwrap",
  "label": "wordwrap",
  "parent": null
}, {
  "depth": 0,
  "id": "xmlattr",
  "label": "xmlattr",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      strong: "strong",
      a: "a",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Filtros HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os filtros afetam a saída final do HubL. Eles podem ser aplicados a várias instruções e expressões do HubL para alterar a marcação do modelo emitido pelo servidor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A sintaxe básica de um filtro é ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername"
      }), ". O filtro é adicionado diretamente após a instrução ou a expressão, dentro dos seus delimitadores. Alguns filtros têm parâmetros adicionais que podem ser adicionados entre parênteses. A sintaxe básica de um filtro com uma string, um número e um parâmetro booleano é: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername(\"stringParameter\", 10, true)"
      }), ". Observe que os parâmetros das strings devem ser escritos entre aspas. Observe também que os filtros do HubL têm alias que podem ser usados da mesma forma que o filtro principal."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O artigo a seguir contém todos os filtros do HubL suportados."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você pode aplicar os filtros do HubL a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/personalize-your-content",
          children: "tokens de personalização"
        }), ", como tokens de contato e da empresa, nas páginas do CMS e do blog do HubSpot, mas ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " nos e-mails."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "abs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula o valor absoluto de um número. Você pode usar essa Filtrar para garantir que um número seja positivo."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_number = -53 %}\n{{ my_number|abs }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "add"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Adiciona um valor numérico a outro valor numérico. Este filtro funciona da mesma forma que o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#math",
        children: "operador +"
      }), ". O parâmetro entre parênteses é o adendo que você está combinando com o seu valor numérico inicial."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 40 %}\n{{ my_num|add(13) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "attr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renderiza o atributo de um dicionário. Este filtro é o equivalente a imprimir uma variável que existe dentro de um dicionário, tal como absolute_url."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica qual o atributo imprimir"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content|attr(\"absolute_url\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "https://developers.hubspot.com/docs/cms/hubl/filters\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "batch"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um filtro de lote agrupa itens dentro de uma sequência."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "linecount"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de itens a incluir no lote"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "fill_with"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica o que incluir para preencher qualquer item em falta"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No exemplo abaixo, há uma variável contendo uma sequência de tipos de frutas. O filtro de lote é aplicado a um loop que é iterado pela sequência. O loop agrupado é executado três vezes para imprimir três tipos de fruta por linha, antes de o loop externo ser executado novamente. Observe na saída final que existem apenas cinco tipos de fruta: o item final é substituído por &nbsp (o segundo parâmetro)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set rows = [\"apples\", \"oranges\", \"pears\", \"grapes\", \"blueberries\"] %}\n\n<table>\n{% for row in rows|batch(3, \" \") %}\n   <tr>\n    {% for column in row %}\n        <td>{{ column }}</td>\n    {% endfor %}\n    </tr>\n{% endfor %}\n</table>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<table>\n  <tbody>\n    <tr>\n      <td>apples</td>\n      <td>oranges</td>\n      <td>pears</td>\n    </tr>\n    <tr>\n      <td>grapes</td>\n      <td>blueberries</td>\n      <td></td>\n    </tr>\n  </tbody>\n</table>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "between_times"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula o tempo entre dois objetos de data e hora na unidade de tempo especificada."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você deve usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "somente"
        }), " com variáveis que retornam uma data. A partir de 30 de setembro de 2024, este filtro não retornará mais a data atual quando um valor nulo for passado. Após essa data, um valor nulo no filtro retornará 30 de setembro de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O objeto de data/hora final"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["As unidades de tempo válidas são ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set begin = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{% set end = \"2018-07-20T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ begin|between_times(end, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "6\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "bool"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte um valor de string de texto em booleano."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if \"true\"|bool == true %}hello world{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "hello world\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "capitalize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Transforma em maiúscula a primeira letra de um valor de variável. O primeiro caractere será maiúsculo, todas as outras letras serão minúsculas. As palavras subsequentes separadas por espaços ou hifens não terão a primeira letra em maiúscula."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sentence = \"the first letter of a sentence should always be capitalized.\" %}\n{{ sentence|capitalize }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The first letter of a sentence should always be capitalized.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "center"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O filtro center utiliza espaços em branco para centralizar o texto dentro de um determinado comprimento de campo. Este filtro não é recomendado ou útil, pois o compilador HTML da HubSpot removerá automaticamente os espaços em branco; no entanto, ele está incluído aqui por uma questão de abrangência."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica o comprimento dos espaços em branco para centralizar o texto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo mostra um filtro center aplicado a uma variável em uma tag prévia, para que os espaços em branco não sejam removidos."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to center\" %}\nbefore{{ var|center(80) }}after\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<pre>\nbefore                                string to center                                after\n</pre>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "convert_rgb"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte um valor HEX para uma string RGB. É útil se você precisar converter variáveis de cor para RGB para usá-las com uma declaração CSS RGBA. No exemplo abaixo, o valor definido por um módulo de cor é convertido para um valor RGB e usado em uma declaração CSS RGBA."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_color = \"#FFFFFF\" %}\n{{ my_color|convert_rgb }}\n\n{% color \"my_color\" color=\"#000000\", export_to_template_context=True %}\n<div style=\"background: rgba({{ widget_data.my_color.color|convert_rgb }}, .5)\"></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "255, 255, 255\n\n<div style=\"background: rgba(0, 0, 0, .5)\"></div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remove uma string de um valor. Este filtro pode ser usado para combinar e cortar uma parte específica de uma string. O parâmetro especifica a parte da string que deve ser removida. O exemplo abaixo remove o espaço e a palavra \"world\" do valor da variável original."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "characters_to_cut"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A parte da string que deve ser removida."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_string = \"Hello world.\" %}\n{{ my_string|cut(\" world\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hello.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "datetimeformat (descontinuado)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": este filtro foi ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated#datetimeformat",
          children: "descontinuado"
        }), ". Em vez disso, você deve usar o filtro ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-datetime",
          children: "format_datetime"
        }), ", que usa uma sintaxe mais padronizada."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "default"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se o valor não estiver definido, retornará o primeiro parâmetro; caso contrário, o valor da variável será impresso. Se você quiser usar o padrão com variáveis que avaliam como falso, precisará definir o segundo parâmetro como ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "verdadeiro"
      }), ". O primeiro exemplo abaixo imprimiria a mensagem se a variável não estivesse definida. O segundo exemplo aplicou o filtro a uma string vazia, que não é indefinida, mas imprime uma mensagem devido ao segundo parâmetro."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default_variable"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor a retornar se a variável não estiver definida. Se a variável estiver definida, o valor da variável será retornado em seu lugar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_value"
            }), " se a variável for uma string vazia"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ my_variable|default(\"my_variable is not defined\") }}\n{{ \"\"|default(\"the string was empty\", true) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "my_variable is not defined the string was empty\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dictsort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Classifica um dicionário e produz pares (chave, valor). Os dicionários não são classificados por padrão, mas você pode imprimir um dicionário classificado por chave ou valor. O primeiro parâmetro é um booleano para determinar se a classificação diferencia ou não maiúsculas de minúsculas. O segundo parâmetro determina se o dicionário deve ser classificado por chave ou por valor. O exemplo abaixo imprime um dicionário de contatos classificado, com todos os detalhes conhecidos sobre o contato."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Determina se a classificação diferencia maiúsculas de minúsculas"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sort_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determina se deve ser classificado por ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "key"
            }), " ou por ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for item in contact|dictsort(false, \"value\") %}\n    {{item}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A sorted contact dictionary\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro retorna a diferença de dois conjuntos ou listas. A lista retornada pelo filtro contém todos os elementos únicos que estão na primeira lista, mas não na segunda."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A segunda lista a ser comparada para encontrar as diferenças em relação à lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divide"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divide o valor atual por um divisor. O parâmetro passado é o divisor. Este filtro é uma alternativa ao operador /."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número pelo qual dividir a variável."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numerator = 106 %}\n{{ numerator|divide(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divisible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma alternativa ao teste de divisibilidade por expressão; o filtro divisible avaliará se o valor pode ser dividido pelo número dado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número a usar ao avaliar se o valor pode ser dividido."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set num = 10 %}\n{% if num|divisible(2) %}\nThe number is divisble by 2\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The number is divisible by 2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Limpa o conteúdo de uma entrada HTML. Aceita uma string e converte os caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ">"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " em sequências seguras para HTML. Use esse filtro para variáveis HubL usadas em HTML, mas que não devem permitir nenhum HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|escape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_attr"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Limpa o conteúdo de uma entrada de atributo de HTML. Aceita uma string e converte os caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " em sequências seguras para HTML. Use esse filtro para variáveis HubL que estão sendo adicionadas aos atributos HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Observe que, ao limpar valores de atributos que aceitam URLs, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "href"
      }), ", você deve usar o filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_url"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> markup is printed as text\" %}\n<img src=\"test.com/imageurl\" alt=\"{{escape_string|escape_attr}}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<img src=\"test.com/imageurl\" alt=\"This <br> markup is printed as text\" />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_jinjava"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Converte os caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}"
      }), " em strings para sequências seguras para Jinjava. Use este filtro se você precisar exibir texto que possa conter tais caracteres em Jinjava."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"{{This markup is printed as text}}\" %}\n{{ escape_string|escape_jinjava }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{This markup is printed as text}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Limpa as strings, incluindo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), ", para que possam ser inseridas em segurança em uma declaração de variável JavaScript. Use esse filtro para variáveis HubL usadas dentro de elementos de script HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"\\tThey said 'This string can safely be inserted into JavaScript.'\" %}\n{{ escape_string|escape_js }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "\\tThey said \\x27This string can safely be inserted into JavaScript.\\x27\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_url"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Limpa o conteúdo de uma entrada de URL, impondo protocolos especificados e removendo caracteres inválidos e perigosos, além de codificar entidades HTML. Retorna em branco se um URL for válido. Use esse filtro para variáveis HubL usadas em atributos HTML que devem ser URLs válidos."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"https://www.google.com<\" %}\n <a href=\"{{ escape_string|escape_url }}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"\"></a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escapejson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Limpa as strings para que possam ser usadas como valores JSON."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<script>alert('oh no!')</script>\" %}\n{% require_js position=\"head\" %}\n<script data-search_input-config=\"config_{{ name }}\" type=\"application/json\">\n{\n  \"autosuggest_results_message\": \"{{ escape_string|escapejson }}\"\n}\n</script>\n{% end_require_js %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<script\n  data-search_input-config=\"config_widget_1234567\"\n  type=\"application/json\"\n>\n  {\n    \"autosuggest_results_message\": \"<script>alert('oh no!')<\\/script>\"\n  }\n</script>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "filesizeformat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formate o valor como um arquivo de tamanho \"legível por humanos\" (ou seja, 13 kB, 4,1 MB, 102 Bytes etc.). Prefixos decimais (Mega, Giga etc.); são usados por padrão; se o parâmetro estiver definido como True, serão usados prefixos binários (Mebi, Gibi)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se definido como verdadeiro, são usados prefixos binários, como Mebi e Gibi."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bytes = 100000 %}\n{{ bytes|filesizeformat }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "100.0 KB\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "first"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorna o primeiro item de uma sequência."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ my_sequence|first }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte o valor em um número de ponto flutuante. Se a conversão não funcionar, ela retornará 0,0. Você pode substituir este padrão usando o primeiro parâmetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro a retornar se a conversão não funcionar."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|float + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "forceescape"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Força a limpeza do HTML. No ambiente HubSpot, não há um caso de uso para escape duplo; isso geralmente se comporta da mesma forma que o filtro de escape."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|forceescape }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aplique a formatação de strings Python a um objeto. %s podem ser substituídos por outra variável."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"Hi %s %s\"|format(contact.firstname, contact.lastname) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hi Brian Halligan\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency (descontinuado)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": este filtro foi ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated",
          children: "descontinuado"
        }), ". Em vez disso, use o filtro ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-currency-value",
          children: "format_currency_value"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency_value"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formata um determinado número como uma moeda com base na moeda padrão do portal e no local passado como parâmetro. Substitui o filtro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#format-currency",
        children: "format_currency descontinuado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 100 | format_currency_value(locale='en-GB', currency='EUR', maxDecimalDigits=6, minDecimalDigits=1) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "€100.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.oracle.com/java/technologies/javase/jdk8-jre8-suported-locales.html",
              children: "A tag da linguagem local Java"
            }), ". O padrão é locale.Format da página: ISO639LanguageCodeInLowercase-ISO3166CountryCodeInUppercase"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_4217",
              children: "código alfabético ISO 4217"
            }), " da moeda; o padrão é a moeda padrão dos portais. Códigos numéricos não são aceitos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "minDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número mínimo de dígitos decimais a serem usados. O padrão é o número padrão de dígitos decimais da moeda."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "maxDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número máximo de dígitos decimais a serem usados. O padrão é o número padrão de dígitos decimais da moeda."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_date"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formata o componente de data de um objeto de data."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você deve usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "somente"
        }), " com variáveis que retornam uma data. A partir de 30 de setembro de 2024, este filtro não retornará mais a data atual quando um valor nulo for passado. Após essa data, um valor nulo no filtro retornará 30 de setembro de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O formato a ser usado. Pode ser um dos seguintes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["um padrão personalizado seguindo o ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "LDML Unicode "
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O fuso horário da data de saída no ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "formato IANA TZDB"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A localidade a ser usada para formatos com reconhecimento de localidade."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_date('long') }}\n{{ content.publish_date | format_date('yyyyy.MMMM.dd') }}\n{{ content.publish_date | format_date('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "November 28, 2022 02022.November.28 28.11.2022\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_datetime"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formata os componentes de data e hora de um objeto de data. Este filtro substitui o filtro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#datetimeformat-nbsp-",
        children: "datetimeformat"
      }), " descontinuado. Por padrão, retorna uma data e hora no fuso horário UTC -00:00."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você deve usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "somente"
        }), " com variáveis que retornam uma data. A partir de 30 de setembro de 2024, este filtro não retornará mais a data atual quando um valor nulo for passado. Após essa data, um valor nulo no filtro retornará 30 de setembro de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O formato a ser usado. Pode ser um dos seguintes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["um padrão personalizado seguindo o ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "LDML Unicode"
                })]
              })]
            }), "Ao usar ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "long"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "full"
            }), ", o timestamp incluirá um Z para denotar tempo UTC com deslocamento zero (ou seja, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2:23:00 PM Z"
            }), "). Para remover o designador Z, especifique um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O fuso horário da data de saída no ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "formato IANA TZDB"
            }), ". Por padrão, retorna a hora UTC."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A localidade a ser usada para formatos com reconhecimento de localidade."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_datetime('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "12/31/69 7:00 PM\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formata o componente de hora de um objeto de data."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você deve usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "somente"
        }), " com variáveis que retornam uma data. A partir de 30 de setembro de 2024, este filtro não retornará mais a data atual quando um valor nulo for passado. Após essa data, um valor nulo no filtro retornará 30 de setembro de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O formato a ser usado. Pode ser um dos seguintes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["um padrão personalizado seguindo o ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "LDML Unicode "
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O fuso horário da data de saída no ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "formato IANA TZDB"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A localidade a ser usada para formatos com reconhecimento de localidade."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.updated | format_time('long') }}\n{{ content.updated | format_time('hh:mm a') }}\n{{ content.updated | format_time('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "3:25:06 PM Z 03:25 PM 10:25:44\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "fromjson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte uma string JSON em um objeto."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj =\"{ \"name\":\"Brian\",\"role\":\"Owner\" }\" %}\n{{ obj|fromjson }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{role=Owner, name=Brian}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "geo_distance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula a distância elipsoidal 2D entre dois pontos na Terra."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- in the example below\nthe HubDB Location =\n42.3667, -71.1060 (Cambridge, MA) |\nChicago, IL = 37.3435, -122.0344 -->\n{{ row.location | geo_distance(37.3435, -122.0344, \"mi\") }} MI\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "861.1655563461395 MI\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "groupby"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O filtro groupby agrupa uma sequência de objetos por um atributo comum. O parâmetro define o atributo comum pelo qual deve-se fazer o agrupamento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O atributo pelo qual agrupar."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<ul>\n{% for group in contents|groupby(\"blog_post_author\") %}\n    <li>{{ group.grouper }}\n      <ul>\n        {% for content in group.list %}\n          <li>{{ content.name }}</li>\n        {% endfor %}\n      </ul>\n    </li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Blog author 1\n    <ul>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 2\n    <ul>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 3\n    <ul>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "indent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O filtro indent usa espaços em branco para recuar o texto dentro de um determinado comprimento de campo. Este filtro não é recomendado ou útil, pois o compilador HTML da HubSpot removerá automaticamente os espaços em branco; no entanto, ele está incluído aqui por uma questão de abrangência. O exemplo abaixo mostra um filtro center aplicado a uma variável em uma tag prévia, para que os espaços em branco não sejam removidos. O primeiro parâmetro controla a quantidade de espaços em branco e o segundo booleano alterna se a primeira linha deve ser recuada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A quantidade de espaços em branco a ser aplicada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor booleano sobre se a primeira linha deve ser recuada."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to indent\" %}\n{{ var|indent(2, true) }}\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "string to indent\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "int"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte o valor em um número inteiro. Se a conversão não funcionar, ela retornará zero. Você pode substituir este padrão usando o primeiro parâmetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro a retornar se a conversão não funcionar."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|int + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "intersect"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro retorna a intersecção de dois conjuntos ou listas. A lista retornada pelo filtro contém todos os elementos únicos que estão contidos em ambas as listas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A segunda lista a ser comparada ao buscar a intersecção com a lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|intersect([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[2, 3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ipaddr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avalia como true se o valor é um endereço IPv4 ou IPv6 válido."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set ip = \"1.0.0.1\" %}\n{% if ip|ipaddr %}\n    The string is a valid IP address\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The string is a valid IP address\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "join"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorna uma string que é a concatenação das strings na sequência. O separador entre os elementos é uma string vazia por padrão. Você pode defini-la com o parâmetro opcional. O segundo parâmetro pode ser usado para especificar um atributo para unir."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "delimiter"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O delimitador que será usado para concatenar as strings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Variável HubL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Atributo de valor para unir um objeto."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_list = [1, 2, 3] %}\n{% set sep = \"---\" %}\n{{ my_list|join }}\n{{ my_list|join(\"|\") }}\n{{ my_list|join(sep) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "123 1|2|3 1---2---3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "last"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorna o último item de uma sequência."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{% my_sequence|last %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "length"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorna o número de itens de uma sequência ou mapeamento."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set services = [\"Web design\", \"SEO\", \"Inbound Marketing\", \"PPC\"] %}\n{{ services|length }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "list"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte valores numéricos em uma lista. Caso seja uma string, a lista retornada será uma lista de caracteres. Para adicionar strings a uma sequência, basta adicioná-la às variáveis da string nos delimitadores da sequência [ ]."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set one = 1 %}\n{% set two = 2 %}\n{% set three = 3 %}\n{% set four = [\"four\"] %}\n{% set list_num = one|list + two|list + three|list + four|list %}\n{{ list_num }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1,2,3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "log"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula o logaritmo natural de um número."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "base"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calcule o logaritmo na nona base."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 10|log }}\n{{ 65536|log(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2.302585092994046 16.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte as letras de um valor para todas minúsculas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ text \"text\" value=\"Text to MAKE Lowercase\", export_to_template_context=True }}\n{{ widget_data.text.value|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "text to make lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "map"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aplica um filtro em uma sequência de objetos ou procura um atributo. É útil ao lidar com listas de objetos, quando você só está interessado em um determinado valor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Geralmente, é usado para mapear um atributo. Por exemplo, se você quiser usar a lógica condicional para verificar se um valor está presente em um determinado atributo de um dicionário. Como alternativa, você pode deixá-lo invocar um filtro, passando o nome do filtro e os argumentos depois."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Atributo a retornar na sequência de objetos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Filtro a aplicar à sequência de objetos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set seq = [\"item1\", \"item2\", \"item3\"] %}\n{{ seq|map(\"upper\") }}\n{{ content|map(\"currentState\")}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[ITEM1, ITEM2, ITEM3] DRAFT\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "md5"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Calcula o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/MD5",
        children: "hash md5"
      }), " do objeto em questão"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.absolute_url|md5 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "923adb4ce05a4c6342c04c80be88d15e\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "minus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Subtrai uma quantidade de tempo de um objeto de data/hora."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor a subtrair."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["As unidades de tempo válidas são ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|minus_time(2, \"months\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-05-14 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "multiply"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Multiplica um valor por um número. Funciona da mesma forma que o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "operador *"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set n = 20 %}\n{{ n|multiply(3) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "60\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "plus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adiciona uma quantidade de tempo a um objeto de data/hora."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor a adicionar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["As unidades de tempo válidas são ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|plus_time(5, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-07-19 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "pprint"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Imprime uma variável. Imprime o tipo de variável e outras informações úteis para a depuração."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set this_var =\"Variable that I want to debug\" %}\n{{ this_var|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: Variable that I want to debug)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "random"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorna um item aleatório da sequência."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " ao usar este filtro, a página será ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "pré-renderizada"
        }), " periodicamente, em vez de sempre que o conteúdo da página for atualizado. Isso significa que o conteúdo filtrado ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " será atualizado em cada recarga de página."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Isso pode não ser um problema para certos tipos de conteúdo, como exibir uma lista aleatória de posts de blog. No entanto, se você precisar que o conteúdo seja alterado aleatoriamente em cada carregamento de página, use o JavaScript para randomizar o conteúdo do lado do cliente."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|random %}\n<div class=\"post-item\">Post item markup</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Random post</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "regex_replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Procura um padrão regex e o substitui por uma sequência de caracteres. O primeiro argumento é um padrão regex no estilo RE2; o segundo é a string de substituição."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Informações sobre a sintaxe regex RE2 podem ser encontradas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/re2/wiki/Syntax",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"contact-us-2\"|regex_replace(\"[^a-zA-Z]\", \"\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "contactus\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reject"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Filtra uma sequência de objetos aplicando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "teste de expressão"
      }), " ao objeto e rejeitando aqueles que passaram com sucesso no teste."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O teste de expressão a aplicar ao objeto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|reject(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[13, 3, 5, 17]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "rejectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtra uma sequência de objetos aplicando um teste a um atributo de um objeto e rejeitando aqueles que passaram com sucesso no teste."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica o atributo a selecionar. Você pode acessar os atributos aninhados usando a notação de pontos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A expressão para testar"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor a testar."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|rejectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n{% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n            <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n            <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n            </a>\n    </div>\n{% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "render"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renderiza rapidamente as strings que contêm HubL para que a saída possa ser passada para outros filtros."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ personalization_token(\"contact.lastname\", \"default value\")|render|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "mclaren\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Substitui todas as instâncias de uma substring por uma nova."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "old"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A substring que deve ser substituída."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "new"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A string de substituição."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se fornecido, apenas as ocorrências de primeira contagem são substituídas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if topic %}\n<h3>Posts about {{ page_meta.html_title|replace(\"Blog | \", \"\") }}</h3>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Posts about topic name</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reverse"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Reverte o objeto ou retorna um iterador que itera sobre ele ao contrário. Para reverter uma lista, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#reverse",
        children: ".reverse()"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] %}\n{% for num in nums|reverse %}\n{{ num }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "10 9 8 7 6 5 4 3 2 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "root"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcula a raiz quadrada de um valor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "nth_root"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calcule a enésima raiz de um número."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 16|root }}\n{{ 625|root(4) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "round"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Arredonde o número para uma determinada precisão."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "precision"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica a precisão do arredondamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "rounding_method"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["As opções incluem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), ", arredonda para cima ou para baixo (padrão); ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceil"
            }), ", sempre arredonda para cima; ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "floor"
            }), ", sempre arredonda para baixo. Se você não especificar um método, será usado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 52.5|round }}\n{{ 52.5|round(0, \"floor\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53 52\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "safe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Marque o valor como seguro, o que significa que, em um ambiente com escape automático habilitado, esta variável não sofrerá escape."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.post_list_content|safe }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>HTML post content that is not escaped.</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sanitize_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Limpa o conteúdo de uma entrada HTML para a saída de conteúdo de rich text. Aceita uma string e, em seguida, remove as tags HTML que não são permitidas. Use esse filtro para variáveis HubL usadas em HTML que devem permitir HTML seguro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao usar esse filtro, você pode incluir os seguintes parâmetros para permitir tipos específicos de tags HTML: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORMATTING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BLOCKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STYLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LINKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TABLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IMAGES"
      }), ". Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html(IMAGES)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html"
      }), " incluirá todos os parâmetros no filtro."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode incluir um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STRIP"
      }), " para remover ", (0, _jsxRuntime.jsx)("u", {
        children: "todo"
      }), " o HTML. Todo o conteúdo também é executado por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " para evitar interpretações aninhadas."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> <div>markup is <img src='test.com/image'> <span>printed</span> as text.</div>\" %}\n{{ escape_string|sanitize_html(\"IMAGES\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This  markup is <img src=\"test.com/image\"> printed as text.</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "select"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtra uma sequência de objetos, aplicando um teste ao objeto e selecionando apenas aqueles que passaram com sucesso no teste."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O teste de expressão a aplicar ao objeto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|select(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[10, 12, 22]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "selectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtra uma sequência de objetos aplicando um teste a um atributo de um objeto e selecionando apenas aqueles que passaram com sucesso no teste."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica o atributo a selecionar. Você pode acessar os atributos aninhados usando a notação de pontos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A expressão para testar"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor a testar."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|selectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n  {% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n       <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n         <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n       </a>\n    </div>\n  {% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">\n  <div class=\"hs-featured-image-wrapper\">\n    <a\n      href=\"http://blog.hubspot.com/marketing/how-to-get-a-job\"\n      title=\"\"\n      class=\"hs-featured-image-link\"\n    >\n      <img\n        src=\"//cdn2.hubspot.net/hub/53/hubfs/00-Blog-Related_Images/landing-a-job-featured-image.png?t=1431452322770&width=761\"\n        class=\"hs-featured-image\"\n      />\n    </a>\n  </div>\n  Post with featured image\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "shuffle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Randomiza a ordem de iteração por uma sequência. O exemplo abaixo randomiza um loop padrão do blog."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " ao usar este filtro, a página será ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "pré-renderizada"
        }), " periodicamente, em vez de sempre que o conteúdo da página for atualizado. Isso significa que o conteúdo filtrado ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " será atualizado em cada recarga de página."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Isso pode não ser um problema para certos tipos de conteúdo, como exibir uma lista aleatória de posts de blog. No entanto, se você precisar que o conteúdo seja alterado aleatoriamente em cada carregamento de página, use o JavaScript para randomizar o conteúdo do lado do cliente."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|shuffle %}\n<div class=\"post-item\">Markup of each post</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Markup of each post 5</div>\n<div class=\"post-item\">Markup of each post 3</div>\n<div class=\"post-item\">Markup of each post 1</div>\n<div class=\"post-item\">Markup of each post 2</div>\n<div class=\"post-item\">Markup of each post 4</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "slice"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divide um iterador e retorna uma lista de listas que contêm esses itens. O primeiro parâmetro especifica quantos itens serão divididos, e o segundo parâmetro especifica os caracteres para preencher as divisões vazias."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "slices"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A quantidade de itens que serão divididos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "filler"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica os caracteres para preencher as divisões vazias."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set items = [\"laptops\", \"tablets\", \"smartphones\", \"smart watches\", \"TVs\"] %}\n<div class=\"columwrapper\">\n  {% for column in items|slice(3,\" \") %}\n    <ul class=\"column-{{ loop.index }}\">\n    {% for item in column %}\n      <li>{{ item }}</li>\n    {% endfor %}\n    </ul>\n  {% endfor %}\n</div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"columwrapper\">\n  <ul class=\"column-1\">\n    <li>laptops</li>\n    <li>tablets</li>\n    <li>smartphones</li>\n  </ul>\n\n  <ul class=\"column-2\">\n    <li>smart watches</li>\n    <li>TVs</li>\n    <li></li>\n  </ul>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Classifica um iterável. Este filtro requer todos os parâmetros para classificar por um atributo no HubSpot. O primeiro parâmetro é um booleano para inverter a ordem de classificação. O segundo parâmetro determina se a classificação diferencia ou não maiúsculas de minúsculas. E o parâmetro final especifica por qual atributo classificar. No exemplo abaixo, os posts de um blog foram renderizados e classificados em ordem alfabética por nome."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "reverse"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O valor booleano para inverter a ordem de classificação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O valor booleano que determina se a classificação diferencia maiúsculas de minúsculas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O atributo pelo qual classificar. Omita ao classificar uma lista."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_posts = blog_recent_posts(\"default\", limit=5) %}\n\n{% for item in my_posts|sort(False, False, \"name\") %}\n{{ item.name }}<br>\n\n\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A post<br />\nB post<br />\nC post<br />\nD post<br />\nE post<br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "split"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divide a string de entrada em uma lista em um determinado separador. O primeiro parâmetro especifica o separador pelo qual a variável deve ser dividida. O segundo parâmetro determina quantas vezes a variável deve ser dividida. Qualquer item restante permanece agrupado. No exemplo abaixo, uma string de nomes é dividida no \";\" para os quatro primeiros nomes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_to_split_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica o separador pelo qual a variável deve ser dividida."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_splits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Determina quantas vezes a variável deve ser dividida. Qualquer item restante permanece agrupado."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_to_split = \"Stephen; David; Cait; Nancy; Mike; Joe; Niall; Tim; Amanda\" %}\n{% set names = string_to_split|split(\";\", 4) %}\n<ul>\n{% for name in names %}\n  <li>{{ name }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Stephen</li>\n  <li>David</li>\n  <li>Cait</li>\n  <li>Nancy; Mike; Joe; Niall; Tim; Amanda</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte um tipo de variável diferente em uma string. No exemplo abaixo, um número inteiro é convertido em uma string (pprint é usado para confirmar a mudança no tipo de variável)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set number_to_string = 45 %}\n{{ number_to_string|string|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: 45)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "striptags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remove as tags SGML/XML e substitua o espaço em branco adjacente por um espaço. Este filtro pode ser usado para remover quaisquer tags HTML de uma variável."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_html = \"<div><strong>Some text</strong></div>\" %}\n{{ some_html|striptags }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "strtotime"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte uma string e um formato de data/hora em um objeto de data/hora."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "datetimeFormat"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://docs.oracle.com/javase/7/docs/api/java/text/SimpleDateFormat.html",
              children: "Padrões de data e hora."
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\")|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1531558890000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sum"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Soma valores numéricos em uma sequência. O primeiro parâmetro pode especificar um atributo opcional e o segundo parâmetro define o valor a retornar se não houver nada na variável a somar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Atributo a somar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "return_if_nothing"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor a retornar se não houver nada na variável a somar."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sum_this = [1, 2, 3, 4, 5] %}\n{{ sum_this|sum }}\nTotal: {{ items|sum(attribute=\"price:\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "15 Total: 20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "symmetric_difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro retorna a diferença simétrica entre dois conjuntos ou listas. A lista retornada pelo filtro contém todos os elementos únicos que estão na primeira lista, mas não na segunda, ou que estão na segunda lista, mas não na primeira"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A segunda lista a ser comparada para encontrar a diferença simétrica com a lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|symmetric_difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "title"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Retorna uma versão em titlecase do valor. Ou seja, as palavras começarão com letras maiúsculas e todos os caracteres restantes estarão em letra minúscula."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_title\" label=\"Enter a title\", value=\"My title should be titlecase\", export_to_template_context=True %}\n{{ widget_data.my_title.value|title }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "My Title Should Be Titlecase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "tojson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escreve um objeto como uma string JSON."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n  {{ content.blog_post_author|tojson }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada do JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 1234567,\n  \"id\": 12312253109,\n  \"created\": 1566413741989,\n  \"updated\": 1566414012799,\n  \"deletedAt\": 0,\n  \"fullName\": \"Sample User\",\n  \"email\": \"sampleUser@example.com\",\n  \"userId\": null,\n  \"username\": null,\n  \"slug\": \"sample-user\",\n  \"jsonBody\": {\n    \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n    \"bio\": \"Sample Bio\",\n    \"facebook\": \"\",\n    \"linkedin\": \"\",\n    \"twitter\": \"\",\n    \"website\": \"https://www.hubspot.com\"\n  },\n  \"bio\": \"Sample Bio\",\n  \"facebook\": \"\",\n  \"linkedin\": \"\",\n  \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n  \"gravatarUrl\": \"https://app.hubspot.com/settings/avatar/108bb5ac667ded34796271437dfe8d58\",\n  \"twitterUsername\": \"\",\n  \"hasSocialProfiles\": false,\n  \"website\": \"https://www.hubspot.com\",\n  \"twitter\": \"\",\n  \"displayName\": \"Sample User\"\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "trim"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remove espaços em branco antes e depois. O HubSpot já remove os espaços em branco da marcação, mas este filtro está documentado por uma questão de abrangência."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \" remove whitespace \" }}\n{{ \" remove whitespace \"|trim }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "remove whitespace remove whitespace\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Corta o texto após um certo número de caracteres. O padrão é 255. Observe que os caracteres HTML estão incluídos nesta contagem. O comprimento é especificado pelo primeiro parâmetro, que é 255 por padrão. Se o segundo parâmetro for verdadeiro, o filtro cortará o texto no comprimento. Caso contrário, descartará a última palavra. Se o texto foi, de fato, truncado, um sinal de elipse (\"...\") será adicionado. Se você quiser um sinal de elipse diferente de \"...\", poderá especificá-lo usando o terceiro parâmetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de caracteres para truncar o texto. O valor padrão é 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor booleano. Se for verdade, o filtro diminuirá o comprimento do texto. Se for falso, descartará a última palavra."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Substitua os caracteres padrão '...' após o truncamento."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"I only want to show the first sentence. Not the second.\"|truncate(40) }}\n{{ \"I only want to show the first sentence. Not the second.\"|truncate(35, True, \"..........\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "I only want to show the first sentence. I only want to show the first\nsente..........\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncatehtml"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Trunca uma determinada string, respeitando a marcação html (ou seja, fecha adequadamente todas as tags aninhadas). Isso evita que uma tag permaneça aberta após o truncamento. Os caracteres HTML não são contabilizados no total de caracteres. Este filtro tem um parâmetro de comprimento e um parâmetro de símbolo de truncagem. Há um terceiro parâmetro booleano que especifica se as palavras serão quebradas em comprimento. Este parâmetro é falso por padrão, a fim de preservar o comprimento das palavras. Se utilizar apenas um dos parâmetros opcionais, utilize argumentos de palavras-chave, tais como truncatehtml(70, breakwords = false)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de caracteres para truncar o texto. O valor padrão é 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Substitua os caracteres padrão '...' após o truncamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valor booleano. Se for verdade, o filtro diminuirá o comprimento do texto. Se for falso, descartará a última palavra."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_text = \"<p>I want to truncate this text without breaking my HTML<p>\" %}\n{{ html_text|truncatehtml(28, \"...\" , false) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>I want to truncate this..</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unescape_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte texto com entidades codificadas em HTML para seus equivalentes em Unicode."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"me & you\" %}\n{{ escape_string|unescape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "me & you\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "union"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro retorna a união de dois conjuntos ou listas. A lista retornada pelo filtro contém todos os elementos únicos que estão em ambas as listas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A segunda lista a unir com a lista original."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|union([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 2, 3, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro extrai um conjunto único de uma sequência ou dicionário de objetos. Ao filtrar um dicionário, como uma lista de posts retornados por uma função, você pode especificar qual atributo é usado para deduplicar itens no dicionário."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attr"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica o atributo que deve ser utilizado ao filtrar um valor do dicionário"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"one\", \"one\", \"two\", \"three\" ] %}\n{{ my_sequence|unique }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[one, two, three]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unixtimestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este filtro converte um objeto datetime em um carimbo de data/hora unix."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você deve usar este filtro ", (0, _jsxRuntime.jsx)("u", {
          children: "somente"
        }), " com variáveis que retornam uma data. A partir de 30 de setembro de 2024, este filtro não retornará mais a data atual quando um valor nulo for passado. Após essa data, um valor nulo no filtro retornará 30 de setembro de 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2017-01-30 17:11:44 1485814304000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte as letras de um valor para todas maiúsculas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"text\" value=\"text to make uppercase\", export_to_template_context=True %}\n{{ widget_data.text.value|upper }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "TEXT TO MAKE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlencode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escapes e URL codificam uma string usando a formatação UTF-8. Aceita dicionários e strings regulares, bem como iteráveis em pares."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"encode\" value=\"Escape & URL encode this string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.encode.value|urlencode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape+%26+URL+encode+this+string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urldecode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Decodifica as strings de URL codificadas para a URL original. Aceita dicionários e strings regulares, bem como iteráveis em pares."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"decode\" value=\"Escape+%26+URL+decode+this+string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.decode.value|urldecode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape & URL encode this string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converte URLs de texto simples em links clicáveis. Se você passar um número inteiro adicional para o filtro, ele encurtará as urls para esse número. O segundo parâmetro é um booleano que determina se o link é rel=\"no follow\". O parâmetro final permite especificar se o link será aberto em uma nova aba."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "shorten_text"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número inteiro que encurtará as urls para o número desejado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "no_follow"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O valor booleano para indicar se o link é rel=\"no follow\"."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Especifica se o link abrirá em uma nova aba."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"http://hubspot.com/\"|urlize }}\n{{ \"http://hubspot.com/\"|urlize(10,true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",false,target=\"_blank\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"//hubspot.com/\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://...</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" target=\"_blank\">http://hubspot.com/</a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordcount"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Conta o número de palavras de uma string."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Se a string contém HTML, use o filtro striptags para obter uma contagem precisa."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{%  set count_words = \"Count the number of words in this variable\"  %}\n{{ count_words|wordcount }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "8\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordwrap"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Insere uma quebra nas palavras após um determinado número de caracteres. Funciona melhor em um <pre> porque o HubSpot remove os espaços em branco por padrão."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de caracteres após o qual quebrar o conteúdo."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set wrap_text = \"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum non sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet dui. Ut laoreet ultricies quam at fermentum.\" %}\n{{ wrap_text|wordwrap(10) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum\nnon sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet\ndui. Ut laoreet ultricies quam at fermentum.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "xmlattr"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cria uma string de atributos HTML/XML, com base nos itens de um dicionário. Todos os valores que não são \"nenhum\"", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "nem \"indefinidos\" são ignorados automaticamente. Se o filtro retornar algo, adiciona automaticamente um espaço na frente do item, a menos que o primeiro parâmetro seja falso."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "autospace"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O valor booleano que preencherá automaticamente um espaço na frente do item, a menos que definido como falso."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_attributes = {\"class\": \"bold\", \"id\": \"sidebar\"} %}\n<div {{ html_attributes|xmlattr }}></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"bold\" id=\"sidebar\"></div>\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}