import { FETCH_ALL_SPECS_FAILED, FETCH_ALL_SPECS_STARTED, FETCH_ALL_SPECS_SUCCEEDED } from '../actions/ActionTypes';
// @ts-expect-error Untyped
import RequestStatus from 'developer-docs-shared-components/lib/models/RequestStatus';
import produce from 'immer';
export const DEFAULT_ALL_SPECS_STATE = {
  status: RequestStatus.uninitialized,
  specs: {}
};
const allSpecsState = (state = DEFAULT_ALL_SPECS_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_ALL_SPECS_STARTED:
        draft.status = RequestStatus.pending;
        break;
      case FETCH_ALL_SPECS_SUCCEEDED:
        {
          draft.status = RequestStatus.succeeded;
          const {
            results
          } = action.payload.data;
          const groupedSpecs = results.reduce((acc, spec) => {
            if (!acc[spec.group]) {
              acc[spec.group] = {};
            }
            if (acc[spec.group][spec.name]) {
              throw new Error(`Duplicate spec found: ${spec.group}/${spec.name}`);
            }
            acc[spec.group][spec.name] = spec;
            return acc;
          }, {});
          draft.specs = groupedSpecs;
          break;
        }
      case FETCH_ALL_SPECS_FAILED:
        draft.status = new RequestStatus({
          failed: true,
          errorDetails: action.payload.error
        });
        break;
      default:
        break;
    }
  });
};
export default allSpecsState;