"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160715944591;
const slug = exports.slug = 'guides/api/crm/commerce/subscriptions';
const title = exports.title = 'Subscriptions API';
const name = exports.name = 'Subscriptions API';
const metaDescription = exports.metaDescription = 'Learn about using HubSpot APIs to retrieve information about subscriptions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "requirements",
  "label": "Requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-subscriptions",
  "label": "Retrieve subscriptions",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "retrieve-subscriptions"
}, {
  "depth": 1,
  "id": "search-for-subscriptions-by-properties",
  "label": "Search for subscriptions by properties",
  "parent": "retrieve-subscriptions"
}, {
  "depth": 0,
  "id": "associations",
  "label": "Associations",
  "parent": null
}, {
  "depth": 1,
  "id": "retrieving-a-subscription-with-associated-line-items",
  "label": "Retrieving a subscription with associated line items",
  "parent": "associations"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Subscriptions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the subscriptions API to fetch information about an account's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments",
        children: "commerce subscriptions"
      }), ". This is a read-only API, so it cannot be used for creating new or managing existing subscriptions. If you're looking to manage marketing email subscriptions instead, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/subscriptions-preferences",
        children: "subscription preferences API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, use this API to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#search-for-subscriptions-by-properties",
        children: "fetch all currently active subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To use this API, the account must be set up to collect payments through either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/set-up-payments",
        children: "HubSpot payments"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "Stripe payment processing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve subscriptions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the information you need, there are a few ways to retrieve subscriptions:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all subscriptions, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/subscriptions"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a specific subscription, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the above URL and specify a subscription ID. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/subscriptions/41112146008"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve subscriptions that meet a specific set of criteria, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the search endpoint and include filters in the request body. See an example of using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#search-for-invoices-by-filter-criteria",
          children: "search endpoint below"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a few default properties, including the create date, last modified date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"41112146008\",\n  \"properties\": {\n    \"hs_createdate\": \"2023-03-08T14:54:17.333Z\",\n    \"hs_lastmodifieddate\": \"2024-03-01T22:33:09.011Z\",\n    \"hs_object_id\": \"44446244097\"\n  },\n  \"createdAt\": \"2023-03-08T14:54:17.333Z\",\n  \"updatedAt\": \"2024-03-01T22:33:09.011Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To return specific properties, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter in the request URL along with comma-separated property names. For example, making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL would result in the response below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/subscriptions?properties=hs_status,hs_last_payment_amount"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"41112146008\",\n  \"properties\": {\n    \"hs_createdate\": \"2022-09-02T15:03:40.828Z\",\n    \"hs_last_payment_amount\": \"200.00\",\n    \"hs_lastmodifieddate\": \"2024-02-27T15:03:53.620Z\",\n    \"hs_object_id\": \"41112146008\",\n    \"hs_status\": \"active\"\n  },\n  \"createdAt\": \"2022-09-02T15:03:40.828Z\",\n  \"updatedAt\": \"2024-02-27T15:03:53.620Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available subscription properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/subscriptions"
      }), ". Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are some common subscription properties that you may want to query."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Label in UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#subscription-properties:~:text=the%20status%20of%20the%20subscription",
              children: "Status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The current status of the subscription. Values include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "active"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "past_due"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "canceled"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "expired"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "scheduled"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_recurring_billing_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#subscription-properties:~:text=for%20the%20subscription.-,Start%20date%3A,-the%20date%20the",
              children: "Start date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The date that the subscription is scheduled to start."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_last_payment_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#subscription-properties:~:text=is%20updated%20automatically.-,Last%20payment%20amount,-%3A%C2%A0the%20amount",
              children: "Last payment amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The amount that was charged during the most recent billing period."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_next_payment_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#subscription-properties:~:text=of%20the%20subscription.-,Next%20payment%20amount,-%3A%20the%20amount",
              children: "Next payment amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The amount that will be charged at the start of the next billing period."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_next_payment_due_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#subscription-properties:~:text=Next%20payment%20due%20date",
              children: "Next payment due date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The date that the next payment is due."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Search for subscriptions by properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the search endpoint to retrieve subscriptions that meet a specific set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search#filter-search-results",
        children: "filter criteria"
      }), ". This will be a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request that includes your filter criteria in the request body."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to search for all currently active subscriptions, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/subscriptions/search"
      }), " with the following request body:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example search request body\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"hs_status\",\n          \"value\": \"active\",\n          \"operator\": \"EQ\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"hs_status\", \"hs_last_payment_amount\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " array specifies the search criteria, while the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " array specifies which properties to return."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While you cannot set associations using this API, you can retrieve association information by making a GET request to the following URL:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/subscriptions/{hs_object_id}/associations/{associatedObjectName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Associated objects can include ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "contacts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies",
        children: "companies"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals",
        children: "deals"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "quotes"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "line items"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/payments",
        children: "payments"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/discounts",
        children: "discounts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/fees",
          children: "fees"
        })
      }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: "taxes"
        })
      }), ". These associations are typically set by the payment link or quote associated with the initial subscription payment. To manage these associations, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments#edit-a-subscription",
        children: "update the subscription in HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example of how you might use this API combined with another API to get a specific set of association information."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " line items belong to one single parent object. For example, if retrieving line items from a subscription, the line item ID’s will be different to those on a deal, or a quote."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieving a subscription with associated line items"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a subscription and the line items associated with it, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/subscription/{hs_object_id}/associations/line_items"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will return the IDs of the currently associated line items, along with meta information about the association type."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"1459694380\",\n      \"type\": \"subscription_to_line_item\"\n    },\n    {\n      \"id\": \"1459694381\",\n      \"type\": \"subscription_to_line_item\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then use the returned IDs to request more information about the line items through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "line items API"
      }), ". For example, you could batch retrieve line items by ID with the following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_items/batch/read"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"inputs\": [{ \"id\": \"1459694380\" }, { \"id\": \"1459694381\" }],\n  \"properties\": [\"name\", \"amount\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response would be formatted as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"id\": \"1459694381\",\n      \"properties\": {\n        \"amount\": \"100.00\",\n        \"createdate\": \"2023-11-08T18:23:06.361Z\",\n        \"hs_lastmodifieddate\": \"2023-11-08T18:23:06.361Z\",\n        \"hs_object_id\": \"1459694381\",\n        \"name\": \"Recurring line item 2\"\n      },\n      \"createdAt\": \"2023-11-08T18:23:06.361Z\",\n      \"updatedAt\": \"2023-11-08T18:23:06.361Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"1459694380\",\n      \"properties\": {\n        \"amount\": \"100.00\",\n        \"createdate\": \"2023-11-08T18:23:06.361Z\",\n        \"hs_lastmodifieddate\": \"2023-11-08T18:23:06.361Z\",\n        \"hs_object_id\": \"1459694380\",\n        \"name\": \"Recurring line item 1\"\n      },\n      \"createdAt\": \"2023-11-08T18:23:06.361Z\",\n      \"updatedAt\": \"2023-11-08T18:23:06.361Z\",\n      \"archived\": false\n    }\n  ],\n  \"startedAt\": \"2024-03-14T15:43:53.179Z\",\n  \"completedAt\": \"2024-03-14T15:43:53.186Z\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}