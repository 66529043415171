"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817127332;
const slug = exports.slug = 'guides/api/app-management/oauth/tokens';
const title = exports.title = 'OAuth | Gerenciando tokens';
const name = exports.name = 'EMEA PT (pt-BR) vNext Docs DP - OAuth Tokens';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "obter-tokens-de-acesso-e-de-atualiza%C3%A7%C3%A3o-do-oauth-2.0",
  "label": "Obter tokens de acesso e de atualização do OAuth 2.0:",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenha-informa%C3%A7%C3%B5es-de-um-token-de-acesso-ou-de-atualiza%C3%A7%C3%A3o-do-oauth-2.0",
  "label": "Obtenha informações de um token de acesso ou de atualização do OAuth 2.0:",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-o-token-de-atualiza%C3%A7%C3%A3o-do-oauth-2.0",
  "label": "Excluir o token de atualização do OAuth 2.0:",
  "parent": null
}, {
  "depth": 0,
  "id": "usando-tokens-de-acesso-de-oauth-2.0",
  "label": "Usando tokens de acesso de OAuth 2.0:",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      strong: "strong",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 10,
        children: [(0, _jsxRuntime.jsx)(_components.h1, {
          children: "Gerenciando tokens"
        }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obter tokens de acesso e de atualização do OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois que um usuário autorizar seu aplicativo, você receberá um código, que deverá ser utilizado para obter um token de acesso e um token de atualização. O token de acesso será usado para autenticar solicitações feitas pelo aplicativo. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Os tokens de acesso expiram após seis horas"
      }), ". Então, quando o primeiro token de acesso expirar, você poderá usar o endpoint do token de atualização para obter um novo token de acesso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para gerar um novo token de acesso, use um token de atualização obtido anteriormente. Os tokens de acesso expiram após seis horas. Se precisar de acesso off-line aos dados da HubSpot, armazene o token de atualização obtido quando você inicia a integração do OAuth e utilize-o para gerar um novo token de acesso depois que o token inicial expira"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observação"
      }), ": O tamanho dos tokens de acesso da HubSpot variarão à medida que alterarmos as informações que são codificadas. Recomendamos que os tokens tenham até 300 caracteres para acomodar quaisquer alterações."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenha informações de um token de acesso ou de atualização do OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Obtenha os metadados de um token de acesso ou de atualização. Ele pode ser usado para obter o endereço de e-mail do usuário do HubSpot para o qual o token foi criado, bem como o ID do Hub a que o token está associado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir o token de atualização do OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Exclui um token de atualização. Você pode usá-lo para excluir o token de atualização caso um usuário desinstale o aplicativo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observação:"
      }), " Isso excluirá apenas o token de atualização. Os tokens de acesso gerados com o token de atualização não serão afetados. Além disso, isso não desinstalará um aplicativo de uma conta da HubSpot nem impedirá a sincronização de dados entre uma conta e um aplicativo conectado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usando tokens de acesso de OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "// Authorization: Bearer {token}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "curl --request GET \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--url 'https://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false' \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--header 'authorization: Bearer CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4'"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{contacts: .....}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neste exemplo, o token de acesso é:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observações:"
      }), " Se estiver usando tokens de acesso OAuth 2.0, você ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "não"
      }), " deverá incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapikey="
      }), " no URL de solicitação. O cabeçalho de autorização é usado em substituição a esse parâmetro de consulta."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}