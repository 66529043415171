"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611080;
const slug = exports.slug = 'guides/cms/tools/personal-access-key';
const title = exports.title = 'Personal CMS Access Keys';
const name = exports.name = '[New] Personal CMS Access Keys';
const metaDescription = exports.metaDescription = 'Personal CMS access keys are a form of authentication intended to be used by developers building on HubSpot. They tied to a specific user in an account.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      code: "code",
      img: "img",
      a: "a",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Personal Access Key"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Personal access keys are the recommended way of authenticating with local development tools."
      }), " Personal access keys work in a similar fashion to API Keys but are tied to a specific user in an account. Personal access keys only work with local development tools."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Personal access keys compared to other Auth methods"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The advantage of personal access keys over implementations like API keys is that API keys effectively have super admin permissions. Personal access keys are limited to the permissions that the individual user in the portal has. If the user has Super Admin, they see no difference in their functionality, but the advantage is that if say an individual developer needs to be removed from an account, the act of disabling their user on the account will disable their local development capabilities."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because personal access keys are tied to the individual user in an account we are able to display more useful information, for example, if a developer changes or uploads a file using the local development tools while using a personal access key, we can attribute the change in-app to that user. This makes it easier to work with teams and understand who did what."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Personal access keys are tied to the individual user in the specific HubSpot account, and not the user directly. What this means is that using the local development tools you will need to generate a new personal access key for each account you wish to use the development tools with. This provides a layer of security for accounts, as a malicious actor obtaining your access key would then only be able to affect the individual portals and as that individual user."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Similarity to OAuth2"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Behind the scenes, personal access keys actually act like OAuth2. When you generate a personal access key, you choose the permissions you want this key to have. You may only have 1 access key per user per HubSpot account. Once you've generated your access key, an app will be connected to your HubSpot account called \"HubSpot Local Development Tools\". This first-party HubSpot app facilitates authentication for the local development tools when using a personal access key. Disconnecting this app will delete any access key you previously generated, instantly making it so your local development tools will no longer be able to connect through those access keys. You will need to generate a new key and update your ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), " file."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/personalcmsaccesskey.png",
            alt: "personalcmsaccesskey"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Protect your credentials"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Guard your personal access keys as if they are your account password, share them with no-one. They enable whoever has them to authenticate as if they are you and take any action you personally can take."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using personal access keys with the local development tools"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Personal access keys were built to be used with local development tools."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Get started with the local development tools"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://app.hubspot.com/l/personal-access-key",
        children: "View your personal CMS access key."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When used for auth in the local development tools, your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " file will resemble this:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: production\nportals:\n  - name: production\n    portalId: <portalId>\n    authType: personalaccesskey\n    personalAccessKey: >-\n      CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}