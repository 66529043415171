"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501378073;
const slug = exports.slug = 'guides/api/conversations/working-with-chatbots/code-snippets';
const title = exports.title = 'Conversations | Exécuter des blocs de code dans des bots';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Running Code Snippets in bots';
const metaDescription = exports.metaDescription = "Découvrez des instructions pour l'exécution de vos propres blocs de code dans une action de bot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "limites",
  "label": "Limites",
  "parent": null
}, {
  "depth": 0,
  "id": "biblioth%C3%A8ques-disponibles",
  "label": "Bibliothèques disponibles",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h2: "h2"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exécuter des blocs de code dans des bots"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/articles/kcs_article/conversations/create-a-bot",
        children: "création ou de la modification d'un bot"
      }), ", vous pouvez ajouter un bloc de code en cliquant sur le bouton + pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/articles/kcs_article/conversations/a-guide-to-bot-actions",
        children: "ajouter une action,"
      }), " comme vous le feriez habituellement. Dans le panneau de sélection de l'action, cliquez sur Exécuter un bloc de code.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/run-a-code-snippet-2.png?width=322&name=run-a-code-snippet-2.png",
        alt: "run-a-code-snippet-2"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensuite, donnez un nom interne à votre action. Dans le panneau de modification de code, vous verrez le modèle par défaut pour Node.js 10.x. Les informations de l'objet d'événement et des formats de réponses possibles sont détaillées ci-dessous.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/run-a-code-snippet-editor.png?width=388&name=run-a-code-snippet-editor.png",
        alt: "run-a-code-snippet-editor"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le code sera déclenché lorsque l'action enregistrée sera atteinte dans une conversation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe trois éléments principaux à prendre en compte lors de l'utilisation de blocs de code :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main()"
        }), " est appelée lorsque l'action de bloc de code est exécutée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["L'argument ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event"
        }), " est un objet contenant des informations pour le visiteur et la session de chat."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "callback()"
        }), " est utilisée pour renvoyer des données au bot et à l'utilisateur. Elle doit être appelée dans la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "event"
      }), " contiendra les données suivantes :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example payload\n{\n  \"userMessage\": {\n  // Details for the last message sent to your bot\n    \"message\": \"100-500\",\n    // The last message received by your bot, sent by the visitor\n    \"quickReply\": {\n    // If the visitor selected any quick reply options, this will be a list of the selected options.\n    // Will be 'null' if no options were selected.\n      \"quickReplies\":[\n      // A list of quick reply options selected by the visitor\n         {\n            \"value\":\"100-500\",\n            \"label\":\"100-500\"\n         }\n      ],\n  },\n  \"session\": {\n    \"vid\": 12345,\n    // The contact VID of the visitor, if known.\n    \"properties\": {\n    // A list of properties collected by the bot in the current session.\n      \"CONTACT\": {\n        \"firstname\": {\n          \"value\": \"John\",\n          \"syncedAt\": 1534362540592\n        },\n        \"email\": {\n          \"value\": \"testing@domain.com\",\n          \"syncedAt\": 1534362541764\n        },\n        \"lastname\": {\n          \"value\": \"Smith\",\n          \"syncedAt\": 1534362540592\n        }\n      }\n    },\n  \"customState\":{myCustomCounter: 1, myCustomString:\"someString\"}\n  // Only present if it customState was passed in from a previous callback payload\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback()"
      }), " est utilisée pour renvoyer des données au bot. L'argument doit être un objet avec les données suivantes :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//sample payload\n{\n  \"botMessage\": \"Thanks for checking out our website!\",\n  // This is the message your bot will display to the visitor.\n  \"quickReplies\": [{ value:'option',\n  // Passed to the bot as the response on click\n   label:'Option' // Gets displayed as the button label\n    }],\n  // the quickReplies object is optional\n  \"nextModuleNickname\": \"SuggestAwesomeProduct\",\n  // The nickname of the next module the bot should execute. If undefined, the bot will follow the default configured behavior\n  \"responseExpected\": false\n  // If true, the bot will display the returned botMessage, wait for a response, then execute this code snippet again with that new response.\n  \"customState\":{myCustomCounter: 1, myCustomString:\"someString\"}\n  // Optional field to pass along to the next step.\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les extraits de code dans les bots doivent se terminer dans un délai de 20 secondes et n'utiliser que 128 Mo de mémoire. Le dépassement de l'une de ces limites entraînera une erreur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bibliothèques disponibles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Plusieurs bibliothèques Node.js courantes peuvent être utilisées dans le bloc de code."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/async",
          children: "async"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/lodash",
          children: "lodash"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/mongoose",
          children: "mongoose"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/mysql",
          children: "mysql"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/redis",
          children: "redis"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/aws-sdk",
          children: "aws-sdk"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les bibliothèques peuvent être chargées à l'aide de la fonction normale ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require()"
      }), " en haut de votre code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const request = require('request');\n\nexports.main = (event, callback) => {\n  request('http://time.jsontest.com/', function (error, response, body) {\n    const responseJson = {\n      botMessage: 'The current time in GMT is ' + JSON.parse(body).time,\n      responseExpected: false,\n    };\n\n    callback(responseJson);\n  });\n};\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}