"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32924700882;
const slug = exports.slug = 'guides/apps/marketplace/measuring-app-performance';
const title = exports.title = 'Measuring app performance';
const name = exports.name = 'Measure app performance';
const metaDescription = exports.metaDescription = "Learn more about measuring your app's performance through the app listing page details view.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "review-your-app-s-analytics",
  "label": "Review your app's analytics",
  "parent": null
}, {
  "depth": 1,
  "id": "marketplace-analytics",
  "label": "Marketplace Analytics",
  "parent": "review-your-app-s-analytics"
}, {
  "depth": 0,
  "id": "customer-feedback",
  "label": "Customer Feedback",
  "parent": null
}, {
  "depth": 0,
  "id": "key-resources",
  "label": "Key Resources",
  "parent": null
}, {
  "depth": 0,
  "id": "measure-engagement-with-utm-parameters",
  "label": "Measure engagement with UTM parameters",
  "parent": null
}, {
  "depth": 2,
  "id": "related-docs",
  "label": "Related docs",
  "parent": "measure-engagement-with-utm-parameters"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Measure app performance"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can view performance metrics for any app listed in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "App Marketplace"
      }), " in your developer account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Review your app's analytics"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "app developer account"
        }), ", navigate ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Listings"
        }), ". This will bring you to a dashboard with all your App Marketplace listings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Marketplace Analytics"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Installs"
      }), " section, review detailed metrics on your app's performance. To analyze data over a specific period, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Frequency"
      }), " dropdown menu at the top of the page and select a time frame."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can analyze the following data:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App installs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App uninstalls"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App installs vs uninstalls"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Free vs Paid installs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Free vs Paid uninstalls"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Install by Country"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Installs by Hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/marketplace-app-analytics.png",
        alt: "marketplace-app-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Listing analytics"
      }), " section, review metrics for the app's listing page. To analyze data over a specific period, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Frequency"
      }), " dropdown menu at the top of the page and select a time frame."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can analyze the following data:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pageviews"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pageview Source"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CTA usage"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Free vs Paid Pageviews"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pageviews by Country"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pageviews by Hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-analytics.png",
        alt: "listing-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Customer Feedback"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all reviews customers have submitted for your app, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Customer Feedback"
      }), " tab."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/customer-feedback-tab.png",
        alt: "customer-feedback-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ratings & Reviews"
      }), " section, you can filter the reviews by the number of stars given, the customer's industry, and the customer's company size."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To reply to a review:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reply"
        }), " on a review."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the pop-up box, enter your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "reply"
        }), " to the customer, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Send"
        }), ". This reply will be publicly visible on your app listing."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To edit notifications for reviews received:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), " dropdown menu and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit notifications."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the Notifications page, search for ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listing New Reviews"
        }), " to get notified when a customer leaves a review."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To send your review link to a customer:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), " dropdown menu and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy review link"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Email or send this link to your customer for them to leave a review."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Uninstall Survey Responses"
      }), " section, review all survey responses."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To export the responses, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Export"
        }), " and select the file format."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Private Feedback"
      }), " section, review any private feedback responses to your team."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Key Resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Key Resources"
      }), " section, review the following resources:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The App Partner Program Benefits guide that lists all the benefits you get as a partner with a listed app on the Hubspot App Marketplace."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A feedback survey that you can fill out to give feedback about the app partner experience."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your App Partner Manager's contact information."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Measure engagement with UTM parameters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to the above metrics, you can further measure engagement by including UTM parameters in the URLs on your app listing page. This enables you to view how much traffic is coming to your website from your listing page, using HubSpot, Google Analytics, or other analytics platforms of your choosing."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It's recommended to add UTM parameters to the following URLs included on your listing page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Supporting content on your App Marketplace listing page, such as your company website, supporting documentation, case study, and privacy policy."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The OAuth install URL that customers use when installing the app."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you could add the following string of UTM parameters to the end of your documentation URL:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "?utm_campaign=appmarketplacelisting&utm_medium=referral&utm_source=hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " it's recommended to use UTM parameters that are consistent with other UTM tracking you or your marketing team may be using. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/customers/understanding-basics-utm-parameters",
          children: "basics of UTM parameters"
        }), " and how to create UTM tracking URLs with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/how-do-i-create-a-tracking-url",
          children: "HubSpot"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/what-are-utm-tracking-codes-ht",
          children: "Google Analytics"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add UTM parameters to the OAuth install URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your app developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the app to edit its details."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Basic info"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auth"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Redirect URLs"
        }), " section, update your redirect URL to contain your UTM parameters. This will update the app's install URL after saving, so you'll need to be sure you've updated any user-facing links to use the new URL."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/update-redirect-url.png",
        alt: "update-redirect-url"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the install button URL field has a limit of 250 characters. If your UTM parameters result in exceeding that limit, you may need to use a url shortener, such as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://bitly.com/",
          children: "Bitly"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add UTM parameters to the app's supporting content:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your app developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App Marketplace"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketplace Listings"
          }), " table, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Listing info"
          }), " tab, update the URL in the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Install button URL"
          }), " field with your UTM parameters."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/install-button-url.png",
            alt: "install-button-url"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Support info"
          }), " tab."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Contact info"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Support resources"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Terms of Service and Privacy Policy"
          }), ", sections, update the URLs with your UTM parameters."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/support-resources-section.png",
            alt: "support-resources-section"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Once you've updated your URLs, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Submit for review"
          }), " in the top right corner."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once reviewed and approved, the URLs on your app's listing page will be updated with your UTM parameters. You can then use analytics tools, such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "HubSpot"
      }), " or Google Analytics, to view traffic coming from your URLs as categorized by your UTM parameters."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " because your app listing can be found through Google and other search engines, it's also important to ensure your listing is SEO-friendly. One recommended strategy to improve your SEO is through backlinks. Whether you're writing website content, sending out email newsletters, or drafting social media messages, consider adding links to your listing page, along with relevant information about your integration. You can further expand your reach through strategies like ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/guest-blogging",
          children: "guest blogging"
        }), " to improve SEO authority."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/backlink-strategies",
          children: "creative ways to earn backlinks"
        }), ", and check out ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/link-building-tutorial",
          children: "HubSpot Academy's free lesson on link building"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Related docs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "App certification requirements"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App listing requirements"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}