"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492355369;
const slug = exports.slug = 'guides/cms/setup/creating-an-efficient-development-workflow';
const title = exports.title = 'Cómo optimizar el workflow de desarrollo de HubSpot.';
const name = exports.name = 'LATAM (ES) Development Workflow';
const metaDescription = exports.metaDescription = 'Crear un workflow de desarrollo eficiente al construir sitios web en el CMS de HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "construir-pensando-en-la-portabilidad",
  "label": "Construir pensando en la portabilidad",
  "parent": null
}, {
  "depth": 0,
  "id": "configurar-el-entorno-de-desarrollo",
  "label": "Configurar el entorno de desarrollo",
  "parent": null
}, {
  "depth": 1,
  "id": "configurar-tu-editor-de-c%C3%B3digo",
  "label": "Configurar tu editor de código",
  "parent": "configurar-el-entorno-de-desarrollo"
}, {
  "depth": 2,
  "id": "vs-code",
  "label": "VS Code",
  "parent": "configurar-el-entorno-de-desarrollo"
}, {
  "depth": 2,
  "id": "otros-editores-de-c%C3%B3digo-e-ide",
  "label": "Otros editores de código e IDE",
  "parent": "configurar-el-entorno-de-desarrollo"
}, {
  "depth": 0,
  "id": "pruebas",
  "label": "Pruebas",
  "parent": null
}, {
  "depth": 2,
  "id": "editor",
  "label": "Editor",
  "parent": "pruebas"
}, {
  "depth": 2,
  "id": "vista-preliminar-del-m%C3%B3dulo",
  "label": "Vista preliminar del módulo",
  "parent": "pruebas"
}, {
  "depth": 2,
  "id": "depuraci%C3%B3n",
  "label": "Depuración",
  "parent": "pruebas"
}, {
  "depth": 2,
  "id": "entornos-de-pruebas",
  "label": "Entornos de pruebas",
  "parent": "pruebas"
}, {
  "depth": 0,
  "id": "desplegando",
  "label": "Desplegando",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Cómo optimizar el workflow de desarrollo de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Configurar un workflow eficiente para los desarrolladores te ayudará a trabajar más eficazmente cuando construyas sitios web en el CMS de HubSpot. Dependiendo de la naturaleza de tu equipo de desarrollo web, o de la naturaleza de un proyecto específico, tu workflow puede variar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, un único desarrollador que construye un nuevo sitio en una nueva cuenta CMS de HubSpot debe preocuparse menos por las pruebas y la colaboración. Por otro lado, un equipo de desarrolladores trabajando en un sitio web más grande necesitará un proceso de desarrollo y puesta en escena más claro, un workflow de implementación, y un código que viva en el control de fuentes para poder trabajar de forma eficiente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta guía está diseñada para guiarte en la configuración de un workflow eficiente para el desarrollador, que puedes adaptar a tus necesidades."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta guía asume que construyes sitios web utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI de CMS"
      }), ", sigue el tutorial de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "inicio con el desarrollo local"
      }), " para configurarlo. Esta guía también asume que has pasado por la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart#quick_start",
        children: "guía de inicio rápido para desarrollar en el CMS de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Construir pensando en la portabilidad"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de empezar a configurar nuestro workflow de desarrollador, es importante reconocer la portabilidad como un concepto clave para tener un workflow de desarrollador eficiente. La portabilidad de tu proyecto garantiza que sea fácil pasar de un entorno a otro sin apenas fricciones ni explicaciones, lo que facilita las pruebas y la puesta en escena de los cambios antes de ponerlos en marcha."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "plantilla de temas de CMS"
      }), " es un proyecto de ejemplo que es portátil, utilizando características como rutas de archivo relativas, y el verdadero formato de archivo para todos los materiales en el proyecto utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI de CMS"
      }), ", que le permite vivir en el control de la fuente y trabajar en cualquier cuenta de HubSpot. Este proyecto es un gran punto de partida o de referencia para los desarrolladores que trabajan en un nuevo proyecto. Todos los Temas predeterminados de HubSpot están construidos utilizando esta plantilla, y también pueden ser utilizados como un punto de partida portátil y eficaz."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar el entorno de desarrollo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para tu entorno de desarrollo individual, cada desarrollador de tu equipo debería crear una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "cuenta gratuita de CMS Developer Sandbox"
      }), ". Estas cuentas nunca vencen y tienen todas las funcionalidades de las cuentas de pago de HubSpot CMS (excepto poder conectar dominios personalizados)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La CLI de CMS facilita la interacción con múltiples cuentas de HubSpot CMS. Crea una nueva ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "entrada de configuración"
      }), " para tu cuenta de CMS Developer Sandbox. Establece el nombre de la entrada para tu entorno de pruebas para que sea del tipo \"DEV\" o \"SANDBOX\" para que quede claro que esta cuenta es un entorno de desarrollo. Adicionalmente, configura el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "defaultPortal"
      }), " para que sea tu cuenta de entorno de pruebas, de modo que cuando ejecutes comandos usando la CLI de CMS, interactuará automáticamente con tu entorno de pruebas, y reducirá los despliegues de producción accidentales. En este punto, tu archivo de configuración tendrá un aspecto similar al siguiente:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: DEV\nportals:\n  - name: PROD\n    portalId: 123\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n  - name: DEV\n    portalId: 456\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora, al ejecutar comandos en la CLI del CMS, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload"
        })
      }), " si no especificas un portal, los archivos se cargarán a tu cuenta \"DEV\"."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar tu editor de código"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes usar tu editor de código preferido cuando construyas en HubSpot, ya sea que prefieras ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#vs-code",
        children: "VS Code"
      }), ", u ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-code-editors-and-ides",
        children: "otros editores de código e IDE"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "VS Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una cantidad significativa de desarrolladores que construyen en HubSpot utilizan ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/",
        children: "Visual Studio Code"
      }), ". Eso inspiró la extensión VS Code de HubSpot. La extensión agrega fragmentos útiles de intellisense, finalización de código HubL, resaltado de sintaxis HubL Linting. El proyecto es de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode",
        children: "código abierto"
      }), " y las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/blob/master/CONTRIBUTING.md",
        children: "contribuciones son bienvenidas"
      }), ". Si tienes algún comentario, por favor, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/issues",
        children: "introduce una incidencia en el repositorio"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "c0ab15c7-27f7-4f4d-a884-5468e852577a",
      external: true,
      label: "Get VS Code Extension"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/vs%20code%20extension%20-%20hubl%20variable%20suggestion.gif",
        alt: "extensión de vs code captura de pantalla animada mostrando la sugerencia de variable hubl"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Otros editores de código e IDE"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si bien existe una extensión oficial de VS Code, no hay razón para que no puedas usar un editor diferente que prefieras. HubL es la bifurcación privada de HubSpot de Jinjava, que se basa en Jinja. Debido a las similitudes en la sintaxis, las extensiones de resaltado de sintaxis de Jinja tienden a funcionar bien. Las extensiones y las herramientas complementarias varían según el editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pruebas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existen dos métodos principales para probar los cambios:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Prueba con watch/upload:"
        }), " cuando trabajes en tu entorno de desarrollo, es seguro utilizar el comando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#watch",
          children: "watch"
        }), " para cargar automáticamente los cambios cuando guardes los archivos en tu editor de texto para desarrollar rápidamente. Si utilizas la herramienta \"Vista preliminar en directo con opciones de visualización\" del Administrador de diseño para una plantilla, a medida que guardes los cambios, los verás reflejados automáticamente en la salida renderizada de la vista previa de la plantilla. Para ver la vista preliminar en directo de una plantilla, selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar > Vista preliminar en directo"
        }), " con opciones de visualización dentro del editor de plantillas del Administrador de diseño."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Probar localmente:"
        }), " para obtener una vista preliminar de tus cambios localmente sin cargarlos en la cuenta, puedes ejecutar el comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs theme preview"
        }), " en el directorio raíz del tema. Este comando ejecutará un servidor proxy local en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://hslocal.net:3000/",
          children: "https://hslocal.net:3000/"
        }), " que luego puedes usar para obtener una vista preliminar de las plantillas y módulos del tema. Más información sobre el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#locally-preview-theme",
          children: "comando de vista previa del tema hs"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Editor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Otra pieza fundamental de la fase de desarrollo es probar los cambios en las herramientas de creación de contenidos. Si estás construyendo módulos, o plantillas diseñadas para ser manipuladas en el editor de contenidos, crea páginas en tu entorno de desarrollo para asegurarte de que la experiencia de edición de contenidos es como pretendes. Arrastra los módulos en configuraciones extrañas e introduce contenido ficticio para asegurarse de que los vendedores no puedan \"romper\" tus módulos cuando construyan páginas. El uso de los editores de contenido te ayudará a ilustrar las barreras de seguridad que deseas construir en tus plantillas y módulos. Actualmente, no es posible mover contenido, como páginas o publicaciones de blog, entre cuentas de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Vista preliminar del módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando estés en el editor de módulos dentro del Administrador de diseño, selecciona el botón \"Vista preliminar\". Esto abrirá un editor de vista preliminar de cómo se comportará el módulo y sus campos en los editores de contenido. Esto te permite probar los campos, grupos y repetidores de tu módulo con contenido ficticio en un entorno seguro."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/module-preview.gif",
        alt: "Vista preliminar del módulo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Depuración"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saber cómo depurar y solucionar los problemas de tu sitio web es fundamental para la salud y el éxito continuos de tu sitio web. Familiarízate con las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting",
        children: "técnicas de depuración cuando desarrolles en el CMS de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Entornos de pruebas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como se indicó anteriormente en la sección sobre la configuración de tu entorno de desarrollo, puedes crear cuentas gratuitas de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS Developer Sandbox"
      }), " para utilizarlas como pruebas y como entorno de desarrollo seguro."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Desplegando"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayas probado tus cambios y estén listos para ponerlos en marcha, es el momento de desplegar los cambios en tu portal de producción. Basado en tu configuración local, necesitarás ejecutar el comando CLI de CMS con el argumento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--portal"
      }), " para interactuar con tu cuenta de producción, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme/src my-theme --portal=PROD"
      }), ". Cuando cargas los archivos a tu cuenta de producción, presta atención si hubo algún error que diagnosticar, y asegúrate de navegar brevemente por tu sitio web en directo para asegurarte de que no hubo ninguna consecuencia involuntaria del despliegue."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si trabajas como parte de un equipo de desarrollo web, se recomienda tener toda la fuente de la verdad de la base de código de producción en el control de versiones e implementar en el portal de tu producto cuando los cambios se fusionan en el maestro. De esta manera, tu equipo de desarrolladores puede utilizar tu sistema de control de versiones favorito para colaborar, hacer seguimiento y revertir fácilmente los cambios."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener más información sobre cómo configurar la integración continua con los repositorios de git, sigue esta guía sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "utilizar acciones de GitHub para implementar en tu cuenta de producción cuando los cambios se fusionan en master"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}