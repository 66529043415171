'use es6';

/**
 * CodeMirror config options. Here we're only exposing typical use options.
 * Others must be set using the codemirror instance, which can be had via a ref.
 *
 * @see {@link http://codemirror.net/5/doc/manual.html#config}
 */
import PropTypes from 'prop-types';
import ReactUtilsPropTypes from 'react-utils/CustomPropTypes';
import CodeMirror from 'codemirror';
import { createFontFamily } from 'react-codemirror/util/Text';
import { CODE_FONT_FAMILY } from 'HubStyleTokens/misc';
import { pick } from './miniUnderscore.js';

/**
 * Custom proptype validator for `mode`
 */
const validateModeProp = (props, propName) => {
  const value = props[propName];
  if (typeof value === 'undefined' || value in CodeMirror.modes || value in CodeMirror.mimeModes) {
    return null;
  }
  return new Error(`Invalid mode '${propName}' supplied to CodeMirror'. \
Must be of 'CodeMirror.modes' ${JSON.stringify(Object.keys(CodeMirror.modes), null, 2)} or \
a 'CodeMirror.mimeModes' ${JSON.stringify(Object.keys(CodeMirror.mimeModes), null, 2)}`);
};
const propTypes = Object.freeze({
  indentUnit: ReactUtilsPropTypes.uint32,
  lineNumbers: PropTypes.bool,
  lineWrapping: PropTypes.bool,
  readOnly: PropTypes.oneOf([true, false, 'nocursor']),
  tabSize: ReactUtilsPropTypes.uint32,
  autofocus: PropTypes.bool,
  viewportMargin: PropTypes.number,
  fixedGutter: PropTypes.bool,
  keyMap: PropTypes.string,
  extraKeys: PropTypes.object,
  showCursorWhenSelecting: PropTypes.bool,
  scrollbarStyle: PropTypes.string,
  smartIndent: PropTypes.bool,
  indentWithTabs: PropTypes.bool,
  electricChars: PropTypes.bool,
  /**
   * Available modes will depend on those loaded from
   * codemirror repackage, this project, or any custom defined.
   */
  mode: validateModeProp,
  /**
   * Available themes will depend on CSS themes loaded
   * from codemirror repackage or the spacesword themes
   * defined in this project.
   */
  theme: PropTypes.string,
  /**
   * From Addons
   */
  matchBrackets: PropTypes.bool,
  matchTags: PropTypes.bool,
  autoCloseBrackets: PropTypes.bool,
  autoCloseTags: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    whenClosing: PropTypes.bool,
    whenOpening: PropTypes.bool,
    dontCloseTags: PropTypes.arrayOf(PropTypes.string),
    indentTags: PropTypes.arrayOf(PropTypes.string)

    // TODO: available in CodeMirror v5.47.0:
    // emptyTags: PropTypes.arrayOf(PropTypes.string),
    // dontIndentOnAutoClose: PropTypes.bool,
    // dontIndentOnSlash: PropTypes.bool,
  })]),
  showTrailingSpace: PropTypes.bool,
  rulers: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.arrayOf(PropTypes.object)]),
  continueComments: PropTypes.bool,
  /**
   * Style props set on CodeMirror wrapping element.
   */
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string
});
const propKeys = Object.keys(propTypes);
const defaultProps = Object.freeze(Object.assign({}, pick(CodeMirror.defaults, propKeys), {
  /*
   * Addons
   */
  autoCloseBrackets: CodeMirror.defaults.autoCloseBrackets || false,
  autoCloseTags: CodeMirror.defaults.autoCloseTags || false,
  matchBrackets: CodeMirror.defaults.matchBrackets || false,
  matchTags: CodeMirror.defaults.matchTags || false,
  showTrailingSpace: CodeMirror.defaults.showTrailingSpace || false,
  rulers: CodeMirror.defaults.rulers || false,
  continueComments: CodeMirror.defaults.continueComments || false,
  /*
   * HS defaults
   */
  lineNumbers: true,
  showCursorWhenSelecting: true,
  fontFamily: CODE_FONT_FAMILY,
  fontSize: '12px'
}));

/**
 * Configurable style values should be set on the wrapper el.
 * For example, CodeMirror themes do not specify font size/family
 * so they can be configured dynamically in this way.
 *
 * @param {Object} codemirror
 * @param {Object} props
 */
function setWrapperElementStyles(codemirror, props) {
  const {
    style
  } = codemirror.getWrapperElement();
  style.fontFamily = createFontFamily(props.fontFamily);
  style.fontSize = props.fontSize || defaultProps.fontSize;
}

/**
 * From the passed props, picks only the props managed by the OptionManager.
 *
 * @param  {Object} props
 * @return {Object}
 */
function getOptionsFromProps(props) {
  return propKeys.reduce((coll, key) => {
    if (key in props) {
      coll[key] = props[key];
    }
    return coll;
  }, {});
}

/**
 * From the passed props, sets only the props managed by the OptionManager.
 *
 * @param {Object} codemirror
 * @param {Object} props
 */
function setOptionsFromProps(codemirror, props) {
  codemirror.operation(() => {
    propKeys.forEach(option => {
      if (codemirror.getOption(option) !== props[option]) {
        codemirror.setOption(option, props[option]);
      }
    });
    setWrapperElementStyles(codemirror, props);
  });
}
export default Object.freeze({
  propTypes,
  defaultProps,
  getOptionsFromProps,
  setOptionsFromProps,
  setWrapperElementStyles
});