"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 113052305538;
const slug = exports.slug = 'guides/cms/content/data-driven-content/graphql/use-graphql-data-in-your-website-pages';
const title = exports.title = 'ウェブサイトページでGraphQLクエリーからのデータを使用する';
const name = exports.name = 'APAC JAPAN (ja) | Dev. Page | Use data from a GraphQL query in your website pages';
const metaDescription = exports.metaDescription = 'ウェブサイトページでGraphQLクエリーのデータを使用する方法について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%87%E3%83%BC%E3%82%BF%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%82%92%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AB%E3%83%90%E3%82%A4%E3%83%B3%E3%83%89%E3%81%99%E3%82%8B",
  "label": "データクエリーをテンプレートにバインドする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%83%BC%E3%82%BF%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%82%92%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AB%E3%83%90%E3%82%A4%E3%83%B3%E3%83%89%E3%81%99%E3%82%8B",
  "label": "データクエリーをモジュールにバインドする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8B%95%E7%9A%84%E3%82%B3%E3%83%B3%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%82%92%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%81%AB%E6%B8%A1%E3%81%99",
  "label": "動的コンテキストをクエリーに渡す",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "クエリーデータを使用して動的ページを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%81%A8%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "動的ページのクエリーとモジュールを作成する",
  "parent": "%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%81%A8%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "リストページのクエリーとモジュールを作成する",
  "parent": "%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "ウェブサイトページを作成する",
  "parent": "%E3%82%AF%E3%82%A8%E3%83%AA%E3%83%BC%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E6%83%85%E5%A0%B1",
  "label": "関連情報",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ウェブサイトページでGraphQLクエリーからのデータを使用する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://graphql.org/",
        children: "GraphQL"
      }), "（英語）を使用すると、HubSpotのCRMおよびHubDBデータにアクセスするデータクエリーを作成して、データを基にパーソナライズした体験をウェブサイトの訪問者に提供できます。GraphQLの仕組みとクエリーの作成方法について詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/graphql/query-hubspot-data-using-graphql",
        children: "GraphQLを使ったHubSpotのデータ照会"
      }), "（英語）をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "GraphQLの仕組みを十分に理解し、必要となるデータを指定するクエリーを作成している場合は、テーマでそのクエリーを使用することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "拡張子が.graphqlのファイルにクエリーを保存します。GraphQLクエリーファイルを整理し、相対パスで参照しやすくするため、テーマのルートにある別のディレクトリーにGraphQLクエリーファイルを保持することをお勧めします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cli#upload",
          children: "HubSpot CLIツールを使用"
        }), "するか、HubSpotアカウントで", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/design-manager/a-quick-tour-of-the-design-manager#creating-new-templates-and-files",
          children: "デザインマネージャーを使用"
        }), "して、クエリーファイルを含むテーマをアップロードします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "GraphQLファイルをアップロードしたら、それらをモジュールまたはテンプレートにバインドできます。これにより、クエリーのデータをモジュールまたはテンプレートのレンダリングコンテキストで使用できるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "データクエリーをテンプレートにバインドする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コード内のデータを参照できるようにGraphQLクエリーをテンプレートにバインドするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dataQueryPath"
      }), "テンプレートアノテーションを追加し、関連付けられているGraphQLクエリーファイルのパスを指定します。パスにファイルの拡張子「.graphql」を含める必要はありません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、ページ上でクエリーの結果をレンダリングするテンプレートにクエリーファイルをバインドする場合、テンプレートのコードの先頭には次のコードが含まれます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: true\n  label: Contact profile page - GraphQL\n  dataQueryPath: ../data-queries/contact_info\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["その後、HubLの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data_query"
      }), "変数から、テンプレートとモジュールコードのデータクエリーへのレスポンスを参照できます。以下の例では、利用可能なロールを格納するHubL変数を定義します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set contactData = data_query.data.CRM.contact %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "データクエリーをモジュールにバインドする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーを使用して、GraphQLクエリーをモジュールにバインドできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、［マーケティング］＞［ファイルとテンプレート］＞［デザインツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファインダーの最上部で、［ファイル］ドロップダウンメニューをクリックし、［新規ファイル］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ダイアログボックスで、［今日は何を作成しますか？］ドロップダウンメニューをクリックし、［GraphQL］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［次へ］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ファイル名"
        }), "を入力し、GraphQLファイルの位置を確認します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "クエリーをコピーしてエディターに貼り付け、［変更を公開］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "クエリーで使用するモジュールに移動します。［リンク済みファイル］で［GraphQLファイル］ドロップダウンメニューをクリックし、クエリーを選択します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/bind-graphql-query-to-module-in-design-manager.png",
        alt: "bind-graphql-query-to-module-in-design-manager"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["その後、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data_query"
      }), "変数を使用して、モジュールコードでクエリーのレスポンスを参照できます。以下の例では、コンタクトのコレクションの照会データを格納するHubL変数を定義します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set contactCollectionData = module.data_query.data.CRM.contact_collection %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルで開発している場合は、モジュールのmeta.jsonファイルに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ata_query_path"
      }), "パラメーターを含めて、GraphQLクエリーをモジュールに直接バインドすることをお勧めします。相対パスでクエリーを参照しやすくするため、テーマのルートにある「modules」ディレクトリー内でモジュールを定義するすることをお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot CLIツールを使用して、モジュールのディレクトリーを", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cli#fetch",
          children: "fetch（取得）"
        }), "します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "data_query_path"
        }), "パラメーターを追加し、meta.jsonファイルの位置を基準としたGraphQLクエリーファイルのパスを指定します。例えば、クエリーファイルがテーマのルートにある「data-queries」ディレクトリーにある場合、meta.jsonファイルには以下のコードが含まれます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"data_query_path\": \"../../data-queries/contactsQuery\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "更新したmeta.jsonファイルをHubSpotアカウントにアップロードします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "動的コンテキストをクエリーに渡す"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のリクエストに対して動的なコンテキストがクエリーに必要な場合（例えば、ページの1つにアクセスしているコンタクトのデータなど）、GraphQLクエリーに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "HubL変数"
      }), "を渡すことができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["最初に、クエリーの先頭にある単行コメントで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "#"
        }), "文字を使用して変数を定義します。GraphQLの変数名には規則として、接頭辞に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "$"
        }), "文字を付加します。例えば、コンタクトのIDの変数を定義するには、次のようになります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "# $contact_id = \"{{ request.contact.contact_vid || ''}}\""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["変数とその型をクエリー定義のパラメーターとして含めます。変数を「non-nullable」としたい場合は、型の後に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!"
        }), "を追加します。上記の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "$contact_id"
        }), "変数をクエリーに渡して特定のコンタクトのコンタクト情報を表示する場合、クエリー定義は次のコードで始まります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "query contact_info($contact_id: String!)"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次に変数を参照し、クエリー内のいずれかのフィルターにその変数を引数として渡すことができます。以下のクエリーは、上記の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "$contact_id"
        }), "変数を使用して、ページにアクセスしているコンタクトの姓、名、Eメールを返します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Contact info page\"\n# description: \"Show a contact's name and email\"\n# $contact_id: \"{{request.contact.contact_vid }}\"\nquery contact_info($contact_id: String!) {\n  CRM {\n    contact(uniqueIdentifier: \"id\", uniqueIdentifierValue: $contact_id) {\n      _metadata {\n        id\n      }\n      firstname\n      lastname\n      email\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubL変数の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.query_dict"
        }), "を使用して、URLクエリー文字列パラメーターを引数としてGraphQLクエリーに渡すこともできます。例えば、ページURLに「/contacts?location=Boston&department=marketing」が含まれており、GraphQLクエリーでURLクエリーパラメーターを使用してコンタクトを所在地と部署に基づいて絞り込む場合、以下のGraphQLクエリーを使用できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Contact listing page with filters\"\n# description: \"Filter contacts by location and department\"\n# $location: \"{{ request.query_dict.location  || ''}}\"\n# $department: \"{{ request.query_dict.department || ''}\"\nquery contact_listing_page($location: String!, department: $String) {\n  CRM {\n    contact_collection(filter: {location__eq: $location, department_eq: $department) {\n      items {\n        _metadata {\n         id\n        }\n        firstname\n        lastname\n        email\n      }\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "クエリーデータを使用して動的ページを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRMデータの特定のプロパティーに基づいてページを動的に生成するには、GraphQLクエリーでディレクティブを使用します。各動的ページは、クエリーでアノテーションを付けたディレクティブからメタデータを取得します。各ディレクティブに定義する値は、検索結果ですぐに特定できるように、そのページのコンテンツを一意に示す値にしてください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このメタデータには次のディレクティブが含まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "動的ページのディレクティブ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_canonical_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのcanonical URL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのキービジュアル。このビジュアルは、ページが共有されると表示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのメタディスクリプション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのタイトル。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRMデータを照会する場合は、これらのディレクティブに加えて、クエリーで一意の識別子として使用するカスタムプロパティーを選択する必要があります。このプロパティーは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hasUniqueValue\": true"
      }), "を使用して構成する必要があります。既存のプロパティーはこのパラメーターを含めるように更新できないため、まだ新しいプロパティーを作成していない場合には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "APIを使用して新しいプロパティーを作成する"
      }), "必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "動的ページのクエリーとモジュールを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "動的ページで使用できるクエリーを作成するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["クエリーの先頭に、クエリーの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "description"
        }), "を単行コメントとして含めます。次に、ページの動的スラッグに識別子を関連付けるために別の単行コメントを追加します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["HubL変数", (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path_param_dict"
            }), "により提供される", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_slug"
            }), "フィールドに、識別子の名前を設定します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["例えば、コンタクトのプロファイルページを動的に作成するクエリーを作成し、各コンタクトのプロファイルページのスラッグとして", (0, _jsxRuntime.jsx)(_components.code, {
              children: "profile_full_name"
            }), "という名前のカスタムプロパティーを使用する場合、クエリー定義の上に以下のコメントを含めます。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "#$profile_full_name: {{ request.path_param_dict.dynamic_slug }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["データソースに一致するIDフィールドをクエリーに含めます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "CRM"
            }), "データを照会する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            }), "フィールドを含めます。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBDB"
            }), "テーブルのデータを照会する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), "フィールドを含めます。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["上の表の対応するメタデータディレクティブを使用してクエリーのフィールドにアノテーションを付け、各ディレクティブの前に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@"
        }), "文字を付けます。例えば、コンタクト プロフィール ページのタイトル、メタディスクリプション、キービジュアルを設定するには、次の手順に従います。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Contact profile\"\n# description: \"Contact profile query for showing details for a single contact\"\n# $profile_full_name: {{ request.path_param_dict.dynamic_slug }}\nquery contact_profile_page($profile_full_name: String!) {\n  CRM {\n    contact(\n      uniqueIdentifier: \"profile_full_name\"\n      uniqueIdentifierValue: $profile_full_name\n    ) {\n      hs_object_id\n      email\n      profile_full_name @web_page_title\n      profile_description @web_page_meta_description\n      profile_picture_url @web_page_featured_image\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["拡張子が.graphqlのファイルにクエリーを保存します。モジュールを設計する際には、HubL変数の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "data_query"
        }), "を使用してクエリーのデータにアクセスできます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リストページのクエリーとモジュールを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["用途によっては、動的ページのルートURLでオブジェクトのレコードのリストを表示することがあります。上記の例では、「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://website.com/profiles%E3%80%8D%E3%81%AB%E7%A7%BB%E5%8B%95%E3%81%97%E3%81%9F%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E8%A8%AA%E5%95%8F%E8%80%85%E3%81%AB%E5%AF%BE%E3%81%97%E3%80%81%E5%85%A8%E3%81%A6%E3%81%AE%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%83%97%E3%83%AD%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88%E3%81%8C%E8%A1%A8%E7%A4%BA%E3%81%95%E3%82%8C%E3%81%BE%E3%81%99%E3%80%82%E3%83%AA%E3%82%B9%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%E3%80%81%E6%AC%A1%E3%81%AE%E6%89%8B%E9%A0%86%E3%81%AB%E5%BE%93%E3%81%84%E3%81%BE%E3%81%99%E3%80%82",
        children: "https://website.com/profiles」に移動したウェブサイト訪問者に対し、全てのコンタクトプロファイルのリストが表示されます。リストページを設定するには、次の手順に従います。"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["リストページに必要な関連オブジェクトレコードのコレクションを取得するため、別のGraphQLクエリーを作成します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "クエリーの先頭に、ラベル、説明、およびクエリーに渡す必要のあるその他の変数を単行コメントとして追加します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "必要なフィルターをコレクションフィールドの引数として含めます。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Profile listing\"\n# description: \"Contact profile listing query for showing all profiles with a \"hubspot.com\" email address\"\nquery contact_listing {\n  CRM {\n    contact_collection(filter: { email__contains: \"hubspot.com\" }) {\n      email\n      profile_full_name\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "リストページのクエリーを.graphql拡張子を使用して保存し、上記の手順に従ってクエリーをリスト ページ モジュールにバインドします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ウェブサイトページを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "クエリーを作成し、関連モジュールにバインドしたら、リストページと詳細ページでモジュールを使用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["リストページを作成するには、次の手順に従います。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "新しいページを作成し、ドラッグ&ドロップエリアまたはフレキシブルカラムを含むページテンプレートを選択します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ページエディターで［設定］タブをクリックします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ページタイトル"
            }), "を入力します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["［ページURL］に、動的ページで取得するコレクションに対応する", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "コンテンツスラッグ"
            }), "を入力します。上記の例に従うと、コンテンツスラッグは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/profiles"
            }), "になります。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["リストページの", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "メタディスクリプション"
            }), "を入力し、［コンテンツ］タブをクリックしてエディターに戻ります。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "左側のサイドバーの［追加］タブで、リスト ページ モジュールを見つけてページエディター内にドラッグします。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ページのデザインが完了したら、右上の［公開］をクリックして公開します。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次に、詳細ページを設定します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/jp/website-pages/create-and-customize-pages",
              children: "新しいページを作成し"
            }), "、ドラッグ&ドロップエリアまたはフレキシブルカラムを含むページテンプレートを選択します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ページエディターで［設定］タブをクリックします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ページ**"
            }), "タイトル**を入力します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["［ページURL］の", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "鉛筆アイコン"
            }), "をクリックして、ページのURLを編集します。リストページを表示する場所をURLに設定します。上記の例ではリストページは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/profiles"
            }), "にあります。このため、ページの完全なコンテンツスラッグは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/profiles/[:dynamic-slug]"
            }), "になります。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［詳細オプション］をクリックし、［動的ページ］セクションまでスクロールします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["［動的ページ］の下の［データソース］ドロップダウンメニューをクリックし、GraphQLクエリーの先頭に含まれているラベルに一致する", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "ラベル"
            }), "を選択します。"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ページ上部にある［コンテンツ］タブをクリックして、エディターに戻ります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "左側のサイドバーの［追加］タブで、詳細モジュールを見つけてページエディター内にドラッグします。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "準備ができたら、右上にある［公開］をクリックしてページを公開します。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連情報"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["GraphQLクエリーを作成、テスト、調整する方法について詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/graphql/query-hubspot-data-using-graphql",
        children: "GraphQLを使ったHubSpotのデータ照会"
      }), "（英語）をご参照ください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}