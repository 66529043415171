"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 37833970855;
const slug = exports.slug = 'guides/api/cms/media-bridge';
const title = exports.title = 'CMS-API | Media Bridge';
const name = exports.name = 'vNext Docs DP - Media Bridge';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "die-media-bridge-api-verwenden",
  "label": "Die Media-Bridge-API verwenden",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-medienobjektdefinitionen-erstellen-und-anpassen",
  "label": "Ihre Medienobjektdefinitionen erstellen und anpassen",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-media-bridge-app-mit-dem-account-eines-hubspot-benutzers-verkn%C3%BCpfen",
  "label": "Ihre Media-Bridge-App mit dem Account eines HubSpot-Benutzers verknüpfen",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-medienobjekte-erstellen",
  "label": "Ihre Medienobjekte erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "cms-module-zum-einbetten-von-medien-erstellen",
  "label": "CMS-Module zum Einbetten von Medien erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eine-oembed-domain-einrichten",
  "label": "Eine oEmbed-Domain einrichten",
  "parent": "cms-module-zum-einbetten-von-medien-erstellen"
}, {
  "depth": 0,
  "id": "ein-benutzerdefiniertes-modul-erstellen",
  "label": "Ein benutzerdefiniertes Modul erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-medien-events-senden",
  "label": "Ihre Medien-Events senden",
  "parent": null
}, {
  "depth": 1,
  "id": "ein-event-mit-einem-kontaktdatensatz-verkn%C3%BCpfen",
  "label": "Ein Event mit einem Kontaktdatensatz verknüpfen",
  "parent": "ihre-medien-events-senden"
}, {
  "depth": 1,
  "id": "ein-events-mit-einem-medium-verkn%C3%BCpfen",
  "label": "Ein Events mit einem Medium verknüpfen",
  "parent": "ihre-medien-events-senden"
}, {
  "depth": 1,
  "id": "ein-event-mit-einer-seite-verkn%C3%BCpfen",
  "label": "Ein Event mit einer Seite verknüpfen",
  "parent": "ihre-medien-events-senden"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Media-Bridge-API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Media-Bridge-API ermöglicht es Integratoren, Medienobjekte wie Video- und Audiodateien sowie Mediennutzungsdaten nach HubSpot zu übertragen. Außerdem werden mit ihr die folgenden Funktionen im HubSpot-Account des Benutzers erstellt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Module zum Einbetten von Medienobjekten in die Drag-&-Drop-Editoren von HubSpot für Seiten und E-Mails."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CRM-Chronik-Events, die zeigen, wann Interessenten oder Kunden mit Videos, Audio und anderen Medientypen interagiert haben."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Segmentierte Listen für zielgerichtete und personalisierte Erlebnisse."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Workflows zur Automatisierung von Interaktionen, basierend auf Mediennutzungs-Events."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Berichte zur Messung der Auswirkungen von Mediaelementen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Media Bridge verwendet sowohl ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "benutzerdefinierte Objekte"
      }), " als auch „Vereinheitlichte Events“, das Event-Tracking-System von HubSpot. Dies bedeutet, dass Sie sowohl mithilfe der Media-Bridge-API als auch der API für benutzerdefinierte Objekte Ihre Integration erstellen können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Media-Bridge-API verwenden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie benötigen einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "HubSpot-Entwickler-Account"
      }), ", um Ihre Media-Bridge-App zu registrieren und Ihre ersten Medienobjektdefinitionen einzurichten, bevor Sie Ihre App mit dem Account eines HubSpot-Benutzers verknüpfen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre Medienobjektdefinitionen erstellen und anpassen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Medienobjekt zu definieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/object-definitions"
      }), " durch. Sie verwenden den ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaTypes"
      }), "-Parameter, um das Objekt zu definieren: ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "DOCUMENT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "OTHER"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihre Medienobjekte definiert haben, erstellen und ändern Sie die Medienobjekteigenschaften, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/schemas/{objectType}"
      }), " und eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/properties/{objectType}"
      }), " durchführen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle API-Aufrufe enthalten Ihre Entwickler-Account-ID als ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "portalID"
      }), "-Abfrageparameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre Media-Bridge-App mit dem Account eines HubSpot-Benutzers verknüpfen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um Ihre Media-Bridge-App mit dem Account eines HubSpot-Benutzers zu verknüpfen, müssen Sie für diese eine App-Definition im Account Ihres HubSpot-Entwicklers erstellen. App-Definitionen umfassen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Details wie das Logo und den Text, das bzw. der dem HubSpot-Benutzer angezeigt werden soll, wenn Ihre Integration versucht, eine erste Verknüpfung zu dessen Account zu erstellen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bereiche, die Ihre Integration im HubSpot-Account des Benutzers benötigt."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So verknüpfen Sie Ihre Media-Bridge-App mit dem Account eines HubSpot-Benutzers:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "Anwendungsdefinition"
        }), " in Ihrem Entwickler-Account für die Media-Bridge-App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie beim Definieren Ihrer Anwendung die folgenden Bereiche hinzu:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuth"
        }), "-Authentifizierung, wenn Sie Aufrufe Ihrer App authentifizieren. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/intro-to-auth",
          children: "Authentifizierungsmethoden"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So überprüfen Sie, ob die App korrekt im Portal eines Kunden installiert ist:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Besuchen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/media-bridge-demo/{HubID}"
        }), " und ersetzen Sie dabei ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{HubID}"
        }), " durch die Account-ID."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App"
        }), " und wählen Sie Ihre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Media-Bridge-App"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In der App können Sie die von der App unterstützten Medientypen anzeigen und Beispielmedien erstellen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sobald die Media-Bridge-App im Kundenportal installiert wurde, können Sie:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-your-media-objects",
          children: "Medienobjekte erstellen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-cms-modules-to-embed-media",
          children: "CMS-Module zum Einbetten Ihrer Medienobjekte erstellen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#send-your-media-events",
          children: "Medien-Events senden"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre Medienobjekte erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihre Medienobjektdefinitionen erstellt und Ihre Media-Bridge-App im Account eines Benutzers installiert haben, können Sie das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth-Token"
      }), " verwenden, um Medienobjekte im Account zu erstellen und zu ändern. Da Medienobjekte benutzerdefinierte Objekte sind, verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects#tab-2",
        children: "API-Endpunkte für benutzerdefinierte Objekte"
      }), ", um sie zu erstellen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/media-bridge/v1/{appId}/settings/object-definitions/{mediaType}"
        }), " durch, um den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType"
        }), " zu finden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{objectType}"
        }), " durch, um das Medienobjekt im Account des Benutzers zu erstellen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Medienobjekt stellt ein einbettbares Content-Element in einem Drittanbietersystem dar. Sobald ein Medienobjekt zur Media Bridge hinzugefügt wurde, kann es in CMS-Inhalte von HubSpot eingebettet und mit Medien-Events verknüpft werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), "-und ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), "-Medienobjekte werden in den folgenden Tabellen alle Standard- und erforderlichen Eigenschaften aufgelistet (* bedeutet erforderlich):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine ID, die verwendet wird, um das spezifische Medium im Media Bridge-System von HubSpot zu identifizieren. Dies wird von HubSpot automatisch generiert und kann von Entwicklern nicht festgelegt werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Dauer der Medienwiedergabe in Millisekunden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine URL, die eine gültige oEmbed-Antwort zurückgeben muss, die der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed-Spezifikation"
            }), " folgt Erfordert den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), "-Typ mit einem iframe in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL der RAW-Mediendatei. Dies kann in Zukunft verwendet werden, um die Einbettung in Social Media zu unterstützen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL eines Bilds, das als Thumbnail zum Einbetten des Mediums in Inhalte verwendet wird. Die ideale Größe für dieses Thumbnail ist 640 x 480 Pixel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL eines Bilds, das das Medium darstellt. Dieses Bild sollte die gleichen Maße wie das Originalmedium haben und kann an Stellen verwendet werden, an denen ein Bildplatzhalter benötigt wird (z. B. wenn das Medium in eine E-Mail eingefügt wird)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Mediums im System des Drittanbieters. Dies gibt Integratoren die Möglichkeit, Medien aus der Media Bridge abzurufen, basierend auf der gleichen ID, die sie in ihrem eigenen System verwenden. (Dies ist der API-Endpunkt, der diese Zuordnung nutzt)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein vom Anbieter bereitgestellter Pfad zum Objekt, der den Speicherort des Objekts im Ordnersystem des Drittanbieters darstellen soll (falls vorhanden). HubSpot versucht, diese Verzeichnisstruktur bei der Anzeige dieser Objekte für den Benutzer darzustellen, verschachtelt jedoch möglicherweise die Objekte und Ordner jedes Anbieters in einem nach dem Anbieter benannten Ordner der obersten Ebene."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des Mediums. Dies wird innerhalb der HubSpot-Benutzeroberfläche an Orten wie der Medienauswahl angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL, die es einem Benutzer ermöglicht, das Medium im System des Medienanbieters anzuzeigen oder mit ihm zu interagieren. Dies wird in der HubSpot-Benutzeroberfläche verwendet, um Benutzern die Möglichkeit zu geben, das Medium zu identifizieren, ohne sich nur auf den Titel zu verlassen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), "-Medienobjekte werden in den folgenden Tabellen alle Standard- und erforderlichen Eigenschaften aufgelistet (* bedeutet erforderlich):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine ID, die verwendet wird, um das spezifische Medium im Media Bridge-System von HubSpot zu identifizieren. Dies wird von HubSpot automatisch generiert und kann von Entwicklern nicht festgelegt werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine URL, die eine gültige oEmbed-Antwort zurückgeben muss, die der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed-Spezifikation"
            }), " folgt Erfordert den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), "-Typ mit einem iframe in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL der RAW-Mediendatei. Dies kann in Zukunft verwendet werden, um die Einbettung in Social Media zu unterstützen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL zu einem Bild, das als Thumbnail zum Einbetten des Mediums in Inhalt an Orten wie der Medienauswahl verwendet wird. Die ideale Größe für dieses Thumbnail ist 640 x 480 Pixel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL eines Bilds, das das Medium darstellt. Dieses Bild sollte die gleichen Maße wie das Originalmedium haben und kann an Stellen verwendet werden, an denen ein Bildplatzhalter benötigt wird (z. B. wenn das Medium in eine E-Mail eingefügt wird)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Mediums im System des Drittanbieters. Dies gibt Integratoren die Möglichkeit, Medien aus der Media Bridge abzurufen, basierend auf der gleichen ID, die sie in ihrem eigenen System verwenden. (Dies ist der API-Endpunkt, der diese Zuordnung nutzt)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein vom Anbieter bereitgestellter Pfad zum Objekt, der den Speicherort des Objekts im Ordnersystem des Drittanbieters darstellen soll (falls vorhanden). HubSpot versucht, diese Verzeichnisstruktur bei der Anzeige dieser Objekte für den Benutzer darzustellen, verschachtelt jedoch möglicherweise die Objekte und Ordner jedes Anbieters in einem nach dem Anbieter benannten Ordner der obersten Ebene."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des Mediums. Dies wird innerhalb der HubSpot-Benutzeroberfläche an Orten wie der Medienauswahl angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine URL, die es einem Benutzer ermöglicht, das Medium im System des Medienanbieters anzuzeigen oder mit ihm zu interagieren. Dies wird in der HubSpot-Benutzeroberfläche verwendet, um Benutzern die Möglichkeit zu geben, das Medium zu identifizieren, ohne sich nur auf den Titel zu verlassen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS-Module zum Einbetten von Medien erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jeder Media-Bridge-App-Anbieter ist dafür verantwortlich, ein eigenes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modul"
      }), " zu erstellen, um seine Medien im CMS-System von HubSpot zu rendern."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn eine Media-Bridge-App in einem HubSpot-Account installiert ist, verfügt das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#embed",
        children: "Einbettungsfeld"
      }), " im Modul über einen zusätzlichen Quellentyp für ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Medienintegration"
      }), ". Dies ermöglicht es dem Benutzer, Medien von der installierten App auszuwählen, die auf seiner Webseite-Seite eingebettet werden sollen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem der Benutzer ein einzubettendes Medium ausgewählt hat, sind die ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_url"
      }), " und ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_response"
      }), " des Mediums in HubL verfügbar, um mühelos Player zu rendern. Zusätzlich werden die ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "id"
      }), " und der ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "media_type"
      }), " von ausgewählten Medien gespeichert, um das Abfragen des zugrunde liegenden CRM-Objekts über die ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crm_objects"
      }), "-Hubl-Funktion zu ermöglichen. Dies kann verwendet werden, um einige oder alle Eigenschaften abzurufen, die Teil eines Medienobjekts sind."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Beispiel für die Verwendung dercrm_objects-HubL-Funktion mit einem Medienobjekt, bei dem die IDs ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "459"
      }), " und ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "922"
      }), " sind:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set objects = crm_objects(\"a123_Videos\", [459,922]) %} {{ objects }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["So rufen Sie ein bestimmtes Bild mit demselben Objekt ab: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set object = crm_object(\"a123_Images\", 459) %} {{ object }}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Apps können den Objekttyp (im Beispiel „a123_Videos“) abrufen, indem sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/{appId}/settings/object-definitions/{mediaType}"
      }), " durchführen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entwickler sollten die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/source-code",
        children: "CMS-Quellcode-API"
      }), "-Endpunkte verwenden, um ihren benutzerdefinierten Modulcode in die Accounts von Kunden zu übertragen, sobald Kunden sich über oAuth verbunden haben. Ist der Modulcode dann in den Account des Kunden übertragen, kann er automatisch das Modul des Entwicklers in seinem Inhalt verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine oEmbed-Domain einrichten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#oembed",
        children: "oEmbed"
      }), "-HubL-Funktion zu verwenden, muss die Domain, die zum Abrufen der oEmbed-Antwort verwendet wird, registriert werden, indem eine Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/oembed-domains"
      }), " durchgeführt wird. Die folgenden Parameter müssen enthalten sein:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Schema:"
          }), " Das URL-Muster für die Medien-URLs. Dieses URL-Muster wird verwendet, um die URL, die an die oEmbed-HubL-Funktion übergeben wird, Ihrer oEmbed-API zuzuordnen. Platzhalterwerte werden mit ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "*"
          }), " (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "www.domain.com/*"
          }), ") unterstützt."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL:"
          }), " die URL Ihrer oEmbed-API. Die Medien-URL wird über einen ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "URL"
          }), "-Parameter an diese URL übergeben."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Discovery (Boolesch):"
          }), " bestimmt, ob Ihre oEmbed-API die ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://oembed.com/#section4",
            children: "Discovery"
          }), "-Funktion von oEmbed unterstützt oder nicht."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Standardmäßig werden die registrierten oEmbed-Domains allen Kunden zur Verfügung gestellt, die Ihre App installiert haben. Wenn Sie benutzerdefinierte Domains haben, die für jeden Kunden einzigartig sind, können Sie beim Einrichten der oEmbed-Domain angeben, in welchem Account eine oEmbed-Domain verwendet werden darf, indem Sie einen portalId-Wert in die API-Anfrage übergeben. Dadurch wird sichergestellt, dass nur der angegebene HubSpot-Account diese oEmbed-Domain verwenden kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein benutzerdefiniertes Modul erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So erstellen Sie ein benutzerdefiniertes Modul:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateien und Vorlagen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design-Manager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben links auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Datei"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Neue Datei"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im Dialogfeld auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Was möchten Sie erstellen?"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modul"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Weiter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aktivieren Sie das Kontrollkästchen neben jedem Inhaltstyp, in dem Ihr Modul verwendet wird: ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Seiten"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Blogbeiträge"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Blog-Listings"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "E-Mails"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Angebote"
        }), ". In E-Mail-Vorlagen verwendete Module dürfen kein CSS oder JavaScript enthalten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie aus, ob dieses Modul ein ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "lokales Modul"
        }), " oder ein ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "globales Modul"
        }), " sein soll. Wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/design-manager-user-guide-v2/how-to-use-global-content-across-multiple-templates",
          children: "globales Modul"
        }), " erstellen, wird durch Bearbeiten des Inhalts dieses Moduls jeder Ort aktualisiert, an dem das Modul verwendet wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateinamen"
        }), " für Ihr Modul ein und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Felder"
        }), " auf der rechten Seite auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Feld hinzufügen"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Einbettung"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/_cARgVZZLUD-nsJUIaG8XMUdSm1GRLr4Gfw96HeZ6SyXFG0t6yiiPKt5ToSe7nq8arSnG569_wg5X2XsD0XEtmEGiX9sbQxH7deUIJKGshXamME6vXrLN2oVSAt-cmrkTN2nggDsdkChDnEOxKrw5mo",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wählen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Unterstützte Quellentypen"
          }), " die Option ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Medienintegration"
          }), " aus."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Standard-Einbettungscontent"
          }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Von [Media-Bridge-App] auswählen"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wählen Sie im rechten Bereich das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Medium"
          }), " aus, das Sie in das Modul einbetten möchten."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Legen Sie gegebenenfalls Editor-Optionen, Feldanzeigebedingungen und Repeater-Optionen fest."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie unter ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "HubL-Variablenname"
          }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Kopieren"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Snippet kopieren"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Fügen Sie das Snippet in den ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "module.html"
          }), "-Abschnitt ein."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Um eine Vorschau des Moduls auf Ihrer Seite anzuzeigen, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Vorschau"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie im linken Abschnitt auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Von [Media-Bridge-App] auswählen"
          }), " und wählen Sie dann das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Medium"
          }), " aus, das Sie in der Vorschau anzeigen möchten."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre Medien-Events senden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Medien-Event ist ein Event, das in Bezug zu einem Medienobjekt stattfindet, z. B. ein Wiedergabe-Event. Sobald ein Medien-Event an die Media-Bridge-App gesendet wurde, kann es in Berichten und in Chronik-CRM-Karten verwendet werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt drei Typen von Medien-Events:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Wiedergabe-Event:"
          }), " gibt an, dass ein Benutzer mit der Wiedergabe eines Mediums beginnt."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Quartil-Event:"
          }), " gibt an, wann ein Benutzer vierteljährliche Meilensteine (0 %, 25 %, 50 %, 75 %, 100 %) in dem Medium erreicht hat, das er wiedergibt."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Aufmerksamkeitsspanne-Event"
          }), ": gibt an, wenn ein Benutzer ein Medium vollständig genutzt hat oder wenn der Benutzer seine Sitzung beendet hat."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Events können gesendet werden, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played-percent"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/attention-span respectively"
      }), " durchführen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Damit Medien-Events in der Kontaktchronik des Benutzers in HubSpot angezeigt werden, muss für jede Sitzung ein ", (0, _jsxRuntime.jsx)("u", {
        children: "Wiedergabe"
      }), "-Event an die Media-Bridge-App gesendet werden. Events aus einer einzelnen Sitzung werden in der Chronik der Kontaktaktivitäten auf einer Karte angezeigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Events mit den v2-Event-Endpunkten gesendet werden, werden sie im Gegensatz zu über die v1-Endpunkte gesendeten asynchron verarbeitet. Daher empfehlen wir Folgendes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die v1-Version der Endpunkte sollte für alle Tests verwendet werden, da eine fehlerhafte Anfrage sofort einen Fehler verursacht."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die v2-Version der Endpunkte sollte in der Produktion verwendet werden, da ihr asynchroner Charakter hilft, Verzögerungen im Client zu vermeiden, während das Event in die Media Bridge geschrieben wird. Events werden außerdem im Falle eines vorübergehenden Ausfalls des Servers in der Media Bridge behalten und erneut versucht."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein Event mit einem Kontaktdatensatz verknüpfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Medien-Event mit einem Kontaktdatensatz zu verknüpfen, müssen Sie entweder die ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " oder ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), " angeben. Wird nur ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), " angegeben, wird es in eine ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " umgewandelt. Wenn beide in der Anfrage angegeben sind, wird die ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " als zentrale Quelle verwendet. Mit diesem Parameter kann die Media-Bridge-App eine Zuordnung zwischen dem Kontaktdatensatz und dem Event erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald ein Medien-Event mit einem Kontaktdatensatz verknüpft wurde, kann das Event in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-reports-with-the-custom-report-builder",
        children: "objektübergreifenden Berichten"
      }), " verwendet werden. Dies ermöglicht es Kunden, ihre Medien-Events mit Kontaktaufzeichnungen sowie zugeordneten Unternehmen und Deals zu verknüpfen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein Events mit einem Medium verknüpfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Medien-Event einem Medium zuzuordnen, muss entweder der ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), "- oder der ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "externalID"
      }), "-Parameter in der Anfrage berücksichtigt werden. Wird beides angegeben, wird die ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " als zentrale Quelle verwendet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein Event mit einer Seite verknüpfen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um ein Medien-Event einer HubSpot-Seite zuzuordnen, müssen die folgenden Parameter in der Anfrage enthalten sein:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wird die Seite in CMS Hub gehostet, muss die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageId"
        }), " angegeben werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wird die Seite ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " in CMS Hub gehostet, müssen der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageName"
        }), " und die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageUrl"
        }), " berücksichtigt werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In der folgenden Tabelle sind unterstützte Eigenschaften für die drei Medien-Events aufgeführt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Event-Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Mediums, auf das sich dieses Event bezieht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des Mediums im System des Drittanbieters. Dies gibt Entwicklern die Möglichkeit, anhand der gleichen ID, die sie in ihrem eigenen System verwenden, auf Medien in der Media Bridge zu verweisen. Dies kann anstelle der ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " in Events verwendet werden. Werden sowohl eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            }), " als auch ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " angegeben, wird die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " verwendet und die externalId ignoriert.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sessionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine eindeutige ID, um eine Ansichtssitzung darzustellen. Dies kann für verschiedene Anbieter verschiedene Dinge bedeuten, und HubSpot lässt Anbieter entscheiden, was eine Sitzung für sie bedeutet. Dies wird verwendet, um Events zu gruppieren, die in derselben Sitzung stattgefunden haben. Es wird erwartet, dass dies durch das System des Drittanbieters generiert wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Events"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des Kontakts im System von HubSpot, der das Medium genutzt hat. Diese kann mithilfe von", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/objects/contacts/v1#get-a-contact-by-its-user-token",
              children: "HubSpots API für das Abrufen von Kontakten anhand des Benutzertokens"
            }), " abgerufen werden. Die API unterstützt auch die Bereitstellung eines Benutzertokens und wandelt dieses automatisch in eine Kontakt-ID um."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactUtk"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Benutzertoken (usertoken, utk), das identifiziert, welcher Kontakt die Medien genutzt hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Content-ID der Seite, auf der ein Event aufgetreten ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name oder Titel der Seite, auf der ein Event aufgetreten ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL der Seite, auf der ein Event aufgetreten ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alle Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Zeitstempel, zu dem dieses Event aufgetreten ist, in Millisekunden seit der Epoche."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString / attentionSpanMap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufmerksamkeitsspanne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dies sind die Rohdaten, die die genauesten Daten zu Zeitspannen des Mediums liefern und wie oft jede Zeitspanne vom Benutzer genutzt wurde. Beispiel: Betrachten Sie ein 10-Sekunden-Video, bei dem jede Sekunde eine Spanne ist. Wenn ein Besucher die ersten 5 Sekunden des Videos ansieht, dann das Video neu startet und die ersten 2 Sekunden erneut ansieht, würde der resultierende ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString"
            }), " so aussehen: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "“0=2;1=2;2=1;3=1;4=1;5=0;6=0;7=0;8=0;9=0;”"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufmerksamkeitsspanne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Prozentsatz des Mediums, den der Benutzer konsumiert hat. Anbieter können dies unterschiedlich berechnen, je nachdem, wie sie wiederholte Ansichten des gleichen Teils des Mediums berücksichtigen. Aus diesem Grund versucht die API nicht, totalPercentWatched anhand der Informationen zur Aufmerksamkeitsspanne für das Event zu validieren. Wenn dieser Wert fehlt, berechnet HubSpot ihn von der Aufmerksamkeitsspanne wie folgt: (Anzahl der Spannen mit einem Wert von 1 oder mehr)/(Gesamtzahl der Spannen)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalSecondsPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufmerksamkeitsspanne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Sekunden, die ein Benutzer mit dem Konsum des Mediums verbracht hat. Die Media Bridge berechnet dies als ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            }), "*", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaDuration"
            }), ". Wenn ein Anbieter möchte, dass dies anders berechnet wird, kann er den vorab berechneten Wert angeben, wenn er das Event erstellt"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "playedPercent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quartil-Event"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Quartil-Prozent-Wert (0, 25, 50, 75, 100) dafür, wie viel vom Medium bislang konsumiert wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "iframeUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wiedergabe-Event"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine URL, die verwendet werden, um Daten aus einem externen System mithilfe eines iFrame anzuzeigen. Wenn berücksichtigt, zeigt das Event in der Kontaktchronik einen Link an, der ein modales Dialogfenster öffnet, das bei Anklicken den iFrame-Inhalt anzeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Medientyp, zu dem das Event gehört (zum Beispiel VIDEO oder AUDIO). Dies ermöglicht es uns, das Event den richtigen Objekten zuzuordnen, wenn ein einzelner Anbieter mehrere Medientypen unterstützt.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}