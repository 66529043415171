"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280489390;
const slug = exports.slug = 'guides/apps/marketplace/applying-for-app-certification';
const title = exports.title = 'Candidatando-se à certificação do aplicativo';
const name = exports.name = 'LATAM BR (PT) Applying for app certification';
const metaDescription = exports.metaDescription = 'A certificação do aplicativo permite que os usuários saibam que seu aplicativo foi revisado e aprovado pela HubSpot, construindo confiança e garantindo aos usuários em potencial a qualidade do aplicativo.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h3: "h3",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Candidatar-se à certificação do aplicativo"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "O que é a certificação do aplicativo?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A certificação do aplicativo envolve a revisão e confirmação pela equipe de Qualidade do Ecossistema da HubSpot de que o aplicativo listado atende a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "esses requisitos"
      }), " de segurança, privacidade, confiabilidade, desempenho, usabilidade, acessibilidade e valor. Depois de aprovado, sua página de listagem de aplicativos mostrará um emblema de “Aplicativo certificado pela HubSpot”."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Por que isso é importante?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um emblema certificado na sua listagem de aplicativo permite que clientes atuais e potenciais saibam que a equipe de Qualidade do Ecossistema da HubSpot analisou e aprovou seu aplicativo. Essa é uma maneira definitiva de simbolizar a qualidade e conquistar a confiança dos usuários do aplicativo. Saiba mais sobre como conquistar a confiança do cliente por meio da certificação de aplicativos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-customer-trust-through-certification",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Como a certificação funciona?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Qualquer parceiro de aplicativos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "elegível"
      }), " pode se candidatar à certificação usando sua conta de desenvolvedor de aplicativo. A equipe de Qualidade do Ecossistema da HubSpot analisará seu envio e entrará em contato para fornecer feedback ou confirmar a certificação do aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Meu aplicativo é elegível para certificação?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Revise nossos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "requisitos de certificação"
      }), " para verificar se seu aplicativo é elegível para certificação. Você não poderá se candidatar, a menos que sua aplicação tenha pelo menos 60 instalações ativas e a quantidade necessária de tráfego de API. As instalações ativas são o número de contas de produção exclusivas da HubSpot, não afiliadas à sua organização, que mostram a atividade bem-sucedida do aplicativo nos últimos 30 dias."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Como faço para me candidatar à certificação?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Somente é possível enviar um aplicativo por vez para certificação. Se você enviar mais do que um aplicativo para certificação ao mesmo tempo, eles serão rejeitados com base na ordem de envio. Quando seu aplicativo for certificado, você poderá enviar outro para certificação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para se cadastrar para a certificação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/account/why-can-t-i-log-into-hubspot",
            children: "Faça login"
          }), " em sua conta de desenvolvedor e navegue até ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace de aplicativos > Listagens"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Arraste o cursor do mouse à direita do aplicativo que deseja certificar e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Certificar aplicativo"
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/undefined-Jun-28-2021-07-51-13-13-PM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Insira a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " do vídeo de demonstração. Revise os requisitos necessários para o vídeo de demonstração do aplicativo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-demo",
          children: "abaixo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Forneça credenciais de teste para seu aplicativo para que a equipe de Qualidade do Ecossistema da HubSpot possa avaliar a funcionalidade dele."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-certification.png",
        alt: "app-certification"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar inscrição para a certificação"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requisitos para o vídeo de demonstração do aplicativo"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os vídeos de demonstração ajudam a equipe do Qualidade do Ecossistema da HubSpot a testar seu aplicativo. A equipe ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " analisará seu aplicativo a menos que você envie um vídeo de demonstração que atenda a todos os requisitos. Vídeos promocionais de vendas e marketing serão rejeitados. A HubSpot não compartilhará nem publicará seus vídeos de demonstração."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O vídeo de demonstração precisa atender aos seguintes requisitos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ter pelo menos três minutos de duração."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Incluir descrições em áudio (de preferência) ou escritas."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluir descrições do propósito do aplicativo e casos de uso comuns.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Por exemplo, \"O Acme App ajuda os representantes de vendas e integração a coordenar os CRMs. Negócios fechados em qualquer um dos CRMs compatíveis com a Acme podem gerar automaticamente tickets e tarefas de integração no HubSpot."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Demonstrar e descrever como os novos usuários devem:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Instalar seu aplicativo.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Por exemplo, “ Na lista do Acme App no Marketplace de aplicativos da HubSpot, clique em Instalar aplicativo, selecione o CRM, insira as credenciais, clique em Concluído, selecione sua conta da HubSpot, analise os escopos solicitados e clique em Conectar aplicativo."
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Instalar ou configurar seu aplicativo após a instalação.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Por exemplo, Depois que o aplicativo for instalado, selecione um pipeline de tickets navegando para Configurações > Integrações > Aplicativos conectados > Aplicativo Acme > Pipeline de tickets. Em seguida, configure até 10 tarefas padrão na seção 'Modelos de Tarefa'. Quando estiver tudo pronto para ativar a sincronização, ative 'Sincronização de Ticket'.\""
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Use os recursos principais do aplicativo para permitir casos de uso comuns.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Por exemplo, \"Para cada negócio fechado no CRM conectado, o recurso de sincronização de tíquetes criará um registro de tíquete no HubSpot com todos os contatos associados e suas tarefas configuradas. Isso permite que a sua equipe de integração se conecte imediatamente com o novo cliente.\""
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Interaja com seu aplicativo dentro de sua conta da HubSpot para permitir casos de uso comuns (se aplicável).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Por exemplo, \"Para criar tickets de integração a partir de ofertas da HubSpot, use a ação de fluxo de trabalho personalizado \"Criar Ticket da Acme\" em fluxos de trabalho baseados em ofertas. Essas ações permitem mais personalização do que as configurações do aplicativo para outros CRMs.\""
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Desconecte seu aplicativo da conta da HubSpot."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Desinstale o aplicativo da conta do HubSpot, descrevendo como isso afeta os dados e as contas do HubSpot dos usuários."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dica:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.loom.com/",
          children: "Loom"
        }), " é uma ferramenta gratuita que você pode usar para gravar um vídeo de demonstração."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Por que o CTA de certificação não está aparecendo para meu aplicativo?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O botão “Certificar aplicativo” aparecerá apenas se seu aplicativo estiver qualificado para aplicação. Revise nossos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "requisitos de certificação"
      }), " ou entre em contato com seu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-partner-manager",
        children: "gerente de parceiros de app"
      }), " se tiver alguma dúvida sobre elegibilidade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Como os usuários saberão que meu aplicativo é certificado?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma vez certificada, sua listagem do Marketplace de aplicativos mostrará um emblema de “Aplicativo certificado pela HubSpot”."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/dw7gvVRNzsrmtUwOYBsLVhpAEniaAZ5Z-_G70J-Bzs596yGR_ZzLXJyw821l7FVCV53XmKoI1VVEFxX9YKAJxtI-4NjwvCJadqfWyLG8IyFOWROZ6k8Nx-1307mp6cE0T8p980-N",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando um cliente passar o ponteiro do mouse sobre o emblema, ele verá informações adicionais sobre como os aplicativos são certificados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Encontre o seu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gerente de parceiros de apps"
        }), " e as respetivas informações de e-mail fazendo login na sua conta de desenvolvedor e navegando até ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de apps"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listagens"
        }), ". Passe o cursor do mouse sobre o app, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mais"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar detalhes da listagem"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Requisitos de certificação de aplicativos"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Requisitos de uma listagem de apps"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}