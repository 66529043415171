"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529636;
const slug = exports.slug = 'reference/cms/serverless-functions';
const title = exports.title = 'Referencia sin servidor';
const name = exports.name = 'LATAM (ES) Serverless Reference';
const metaDescription = exports.metaDescription = 'Información de referencia para tu serverless.json, archivos de función, puntos de terminación, comandos CLI y gestión de paquetes, todo en un solo lugar.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": null
}, {
  "depth": 1,
  "id": "puntos-de-terminaci%C3%B3n",
  "label": "Puntos de terminación",
  "parent": "serverless.json"
}, {
  "depth": 0,
  "id": "archivo-de-funciones",
  "label": "Archivo de funciones",
  "parent": null
}, {
  "depth": 1,
  "id": "objeto-de-contexto",
  "label": "Objeto de contexto",
  "parent": "archivo-de-funciones"
}, {
  "depth": 1,
  "id": "encabezados",
  "label": "Encabezados",
  "parent": "archivo-de-funciones"
}, {
  "depth": 2,
  "id": "redirigir-enviando-una-encabezado",
  "label": "Redirigir enviando una encabezado",
  "parent": "archivo-de-funciones"
}, {
  "depth": 1,
  "id": "establecer-las-cookies-desde-tu-punto-de-terminaci%C3%B3n",
  "label": "Establecer las cookies desde tu punto de terminación",
  "parent": "archivo-de-funciones"
}, {
  "depth": 1,
  "id": "establecer-varios-valores-para-un-solo-encabezado",
  "label": "Establecer varios valores para un solo encabezado",
  "parent": "archivo-de-funciones"
}, {
  "depth": 0,
  "id": "secretos",
  "label": "Secretos",
  "parent": null
}, {
  "depth": 0,
  "id": "uso-de-funciones-sin-servidor-con-el-elemento-de-formulario",
  "label": "Uso de funciones sin servidor con el elemento de formulario",
  "parent": null
}, {
  "depth": 0,
  "id": "cors",
  "label": "CORS",
  "parent": null
}, {
  "depth": 1,
  "id": "solicitudes-get",
  "label": "Solicitudes Get",
  "parent": "cors"
}, {
  "depth": 0,
  "id": "paquetes-precargados",
  "label": "Paquetes precargados",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites",
  "label": "Límites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      h4: "h4",
      em: "em",
      ul: "ul",
      li: "li",
      br: "br",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Referencia de funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si estás creando una función sin servidor como parte de un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "proyecto de desarrollador"
        }), ", en su lugar visita la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "documentación de la función sin servidor del proyecto de desarrollador"
        }), ". La siguiente documentación es para crear funciones sin servidor fuera de la plataforma del proyecto de desarrollador."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En este artículo, aprende sobre los archivos que se encuentran dentro de una ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: ["carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), " sin servidor"]
      }), " y los comandos CLI que puedes utilizar con las funciones sin servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener un resumen general de las funciones sin servidor, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "descripción general de las funciones sin servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener más información sobre la creación de funciones sin servidor con proyectos para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "módulos renderizados y parciales de JavaScript"
      }), ", consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "documentación de proyectos de desarrollador"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la carpeta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        })
      }), ", el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " almacena la configuración de la función sin servidor. Este es un archivo obligatorio y asigna tus funciones a sus ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#endpoints",
        children: "puntos de terminación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// serverless.json\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"environment\": {\n    \"globalConfigKey\": \"some-value\"\n  },\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"events\": {\n      \"file\": \"function1.js\",\n      \"method\": \"GET\"\n    },\n    \"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"CONFIG_KEY\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleKeyName\", \"otherkeyname\"]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Clave"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "runtime"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El entorno de ejecución. Admite las siguientes versiones de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/en/about/",
              children: "Node.js"
            }), ":", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Nodo 20 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs20.x"
                }), ") (recomendado)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Nodo 18 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs18.x"
                }), ")"]
              })]
            }), "Ten en cuenta que HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/changelog/deprecation-of-node-v16-in-all-serverless-functions",
              children: "ya no admitirá el Nodo 16"
            }), " más allá del 12 de julio de 2024."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Versión del esquema de funciones sin servidor de HubSpot. (Versión actual 1.0)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "environment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Variables de configuración pasadas a la función ejecutante como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
              children: "variables de entorno"
            }), " en tiempo de ejecución. Puedes usar esto para agregar una lógica para usar una versión de prueba de una API en lugar de la real basada en una variable de entorno."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una matriz que contiene los nombres de los secretos que tu función sin servidor utilizará para la autenticación. No almacenes secretos directamente en este archivo, solo haz referencia a los nombres de los secretos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoints"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los puntos de terminación definen las rutas que se exponen y tu asignación a archivos JavaScript específicos, dentro de tu carpeta de funciones. Obtén más información sobre puntos de terminación a continuación."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " no asignes el mismo nombre a tus secretos y variables de entorno. Si lo haces, se producirán conflictos al devolver tus valores en la función."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Puntos de terminación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada punto de terminación puede tener sus propias ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "variables de entorno"
      }), " y secretos. Las variables especificadas fuera de los puntos de terminación deben utilizarse para los ajustes de configuración que se aplican a todas las funciones y puntos de terminación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"configKey\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleAPIKeyName\",\"otherKeyName\"]\n    }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los puntos de terminación tienen un par de claves únicas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Clave"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena o matriz de cadenas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
              children: "Método o métodos HTTP"
            }), " que admite el punto de terminación. El valor predeterminado es ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
              children: "GET"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ruta al archivo de la función JavaScript con la implementación para el punto de terminación."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones sin servidor se exponen a través de una ruta en el dominio de tu cuenta de HubSpot CMS. Esto incluye los subdominios predeterminados ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-sites.com"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes acceder a estas funciones en la siguiente URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://{domainName}/_hcms/api/{endpoint-name/path}?portalid={hubId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, conoce cada uno de los componentes de la URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "domainName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tu nombre de dominio."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/_hcms/api/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La ruta reservada para las funciones sin servidor. Todos los puntos de terminación existen dentro de esta ruta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoint-name/path"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El nombre del punto de terminación o la ruta que especificaste en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tu ID de Hub Proporcionar esto en la solicitud te permitirá probar tus funciones dentro de las vistas previas de módulos y plantillas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Archivo de funciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además del archivo de configuración ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), ", la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " también contendrá un archivo JavaScript ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " que define la función. También puedes aprovechar la biblioteca de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/request/request#readme",
        children: "solicitudes"
      }), " para realizar solicitudes HTTP a las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "API de HubSpot"
      }), " y otras API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Require axios library, to make API requests.\nconst axios = require('axios');\n// Environment variables from your serverless.json\n// process.env.globalConfigKey\n\nexports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n\n  // context.params\n  // context.body\n  // context.accountId\n  // context.limits\n\n  // secrets created using the CLI are available in the environment variables.\n  // process.env.secretName\n\n  //sendResponse is what you will send back to services hitting your serverless function.\n  sendResponse({ body: { message: 'my response' }, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objeto de contexto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El objeto de contexto contiene información contextual sobre la ejecución de la función, almacenada en los siguientes parámetros."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la cuenta de HubSpot que contiene la función."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lleno si la solicitud se envía como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " con un tipo de contenido ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "application/json"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si la solicitud proviene de un contacto con cookies, el objeto contacto estará lleno con un conjunto de propiedades básicas del contacto junto con la siguiente información:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": El ID de visitante del contacto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "isLoggedIn"
                }), ": cuando se utiliza CMS Memberships, esto será ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " si el contacto está registrado en el dominio"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "listMemberships"
                }), ": una matriz de ID de listas de contactos de las que este contacto es miembro"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contiene los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#headers",
              children: "títulos"
            }), " enviados por el cliente que llega a tu punto de terminación."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "params"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Rellenado con valores de cadena de consulta junto con cualquier valor HTML Form-POSTed. Se estructuran como un mapa con cadenas como claves y una matriz de cadenas para cada valor.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "context.params.yourvalue"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limits"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Devuelve lo cerca que estás de alcanzar los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview#know-your-limits",
              children: "límites de velocidad de las funciones sin servidor"
            }), ".", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "executionsRemaining"
                }), ": cuántas ejecuciones por minuto quedan."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "timeRemaining"
                }), ": cuánto tiempo de ejecución permitido queda."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Encabezados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si necesitas conocer los encabezados del cliente que está llegando a tu punto de terminación, puedes acceder a ellos a través de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.headers"
      }), ", similar a cómo accederías a la información a través de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.body"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, revisa algunos de los encabezados comunes que proporciona HubSpot. Para obtener una lista completa, consulta la documentación de encabezados HTTP de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers",
        children: "MDN"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "encabezado"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Comunica qué tipos de contenido, expresados como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types",
              children: "tipos MIME"
            }), ", entiende el cliente. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-encoding"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Comunica la codificación del contenido que el cliente entiende. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-encoding",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Comunica qué idioma humano y localización se prefiere. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-language",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cache-control"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contiene directivas para el almacenamiento en caché. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "connection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Comunica si la conexión de red permanece abierta. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/connection",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cookie"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contiene las cookies enviadas por el cliente. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/cookie",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Comunica el nombre de dominio y el número de puerto TCP de un servidor de escucha. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/host",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true-client-ip"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dirección IP del usuario final. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/206776727-What-is-True-Client-IP-",
              children: "Consulta Cloudflare true-client-ip"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upgrade-insecure-requests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Comunica la preferencia del cliente por una respuesta cifrada y autentificada. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Upgrade-Insecure-Requests",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "user-agent"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cadena definida por el proveedor que identifica la aplicación, el sistema operativo, el proveedor de la aplicación y la versión. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent",
              children: "Consulta MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "x-forwarded-for"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifica la dirección IP de origen de un cliente a través de un proxy o equilibrador de carga. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For",
              children: "Consulta MDN"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Redirigir enviando una encabezado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes realizar una redirección desde tu función sin servidor enviando una respuesta con un encabezado ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "location"
      }), " y un statusCode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "301"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "sendResponse({\n  statusCode: 301,\n  headers: {\n    Location: 'https://www.example.com',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer las cookies desde tu punto de terminación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Desde tu función sin servidor puedes decirle al cliente (navegador web) que establezca una cookie."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n    sendResponse({\n      body: { ... },\n      'Set-Cookie': 'myCookie1=12345; expires=...; Max-Age=...',\n      statusCode: 200\n    });\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer varios valores para un solo encabezado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el caso de los encabezados que admiten múltiples valores, puedes utilizar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multiValueHeaders"
      }), ", para pasar los valores. Por ejemplo: puedes decirle al navegador que establezca varias cookies."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  sendResponse({\n    body: { ... },\n    multiValueHeaders: {\n      'Set-Cookie': [\n        'myCookie1=12345; expires=...; Max-Age=...',\n        'myCookie2=56789; expires=...; Max-Age=...'\n       ]\n    },\n    statusCode: 200\n  });\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secretos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando necesites autenticar una solicitud de función sin servidor, usarás secretos para almacenar valores como claves de API o tokens de acceso a aplicaciones privadas. Con la CLI, puedes agregar secretos a tu cuenta de HubSpot para almacenar esos valores, a los que luego podrás acceder a través de variables de entorno (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "process.env.secretName"
      }), "). Los secretos se gestionan a través de la CLI de HubSpot utilizando los siguientes comandos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#list-secrets",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets list"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#add-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets add"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#remove-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets delete"
          })
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez agregados a través de la CLI, los secretos pueden ponerse a disposición de las funciones mediante la inclusión de una matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secretos"
      }), " que contenga el nombre del secreto. Esto le permite almacenar tu código de función en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "control de versiones"
      }), " y usar secretos sin exponerlos. Sin embargo, ", (0, _jsxRuntime.jsx)("u", {
        children: "nunca debes"
      }), " devolver el valor de tu secreto a través del registro de la consola o como respuesta, ya que esto expondrá el secreto en los registros o en las páginas del front-end que llaman a tu función sin servidor."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debido al almacenamiento en caché, puede tardar aproximadamente un minuto en ver los valores secretos actualizados. Si acabas de actualizar un secreto pero todavía estás viendo el valor anterior, vuelve a comprobarlo después de aproximadamente un minuto."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso de funciones sin servidor con el elemento de formulario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al enviar funciones sin servidor, utiliza javascript para manejar el envío del formulario y utiliza el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"contentType\" : \"application/json\""
      }), " en tu solicitud. No utilices el atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "action"
      }), " de los elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<form>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CORS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intercambio de recursos de origen cruzado (CORS)"
        })
      }), " es una función de seguridad del navegador. Por opción predeterminada, los navegadores restringen las solicitudes de origen cruzado iniciadas por javascript. Esto evita que el código malicioso que se ejecuta en un dominio diferente, afecte a tu sitio. Esto se denomina política del mismo origen. Dado que el envío y la recuperación de datos de otros servidores es a veces una necesidad, el servidor externo, puede suministrar encabezados HTTP que comunican qué orígenes están autorizados a leer la información de un navegador."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No deberías tener problemas de CORS al llamar a tu función sin servidor dentro de tus páginas alojadas en HubSpot. Si lo haces, verifica que estás utilizando el protocolo correcto."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "¿Recibes este error CORS?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"El acceso a la búsqueda en [tu url de función] desde el origen [solicitud de creación de página] ha sido bloqueado por la política de CORS: la respuesta a la solicitud de prefiltro no pasa la verificación de control de acceso: no hay encabezado 'Access-Control-Allow-Origin' presente en el recurso solicitado. Si una respuesta opaca sirve a tus necesidades, establece el modo de la solicitud como 'no-cors' para obtener el recurso con CORS desactivado\""
        })]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "¿Tu solicitud se dirige a un origen diferente al del sitio que la llama?"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Si el nombre de dominio es diferente, sí."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Si utilizas un protocolo diferente (http, https), sí."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si utilizas un protocolo diferente, simplemente cambia el protocolo para que coincida y eso lo arreglará."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En este momento no se puede modificar el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Access-Control-Allow-Origin"
        }), " de HubSpot."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors",
          children: "Consulta el MDN para obtener más detalles sobre la solución de errores de CORS."
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Solicitudes Get"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las solicitudes Get pueden hacer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: "solicitudes CORS"
      }), " dependiendo del cliente. No hagas que las peticiones ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
        children: "GET"
      }), " escriban nada, solo que devuelvan datos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paquetes precargados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las funciones sin servidor de HubSpot vienen actualmente precargadas con los siguientes paquetes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/@hubspot/api-client",
          children: "@hubspot/api-client"
        }), ": ^1.0.0-beta"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ": ^0.19.2"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        }), ": ^2.88.0"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/requests",
          children: "requests"
        }), ": ^0.2.2"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para utilizar la última versión compatible de un paquete precargado, o para utilizar un paquete recién agregado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Clona o copia tu archivo de funciones."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cambia el punto de terminación de tu función en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " para que apunte a tu nuevo archivo de función. Puedes eliminar la versión antigua con seguridad."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres incluir paquetes fuera del conjunto de paquetes precargados, puedes usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " para combinar tus módulos de node y hacer que tus archivos agrupados sean tus archivos de función."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las funciones sin servidor están previstas para ser rápidas y tener un enfoque limitado. Para permitir llamadas y respuestas rápidas, las funciones sin servidor de HubSpot tienen los siguientes límites:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 secretos por cuenta."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 MB de memoria."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No más de 100 puntos de terminación por cuenta de HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Debes usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contentType"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " cuando se llama a una función."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los registros de las funciones sin servidor se almacenan durante 90 días."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6MB en una carga útil de invocación de AWS Lambda."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Límites de ejecución"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada función tiene un tiempo máximo de ejecución de 10 segundos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cada cuenta está limitada a un total de 600 segundos de ejecución por minuto."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto significa que cualquiera de estos escenarios puede ocurrir:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "60 ejecuciones de funciones que tardan 10 segundos cada una en completarse."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6000 ejecuciones de funciones que tardan 100 milisegundos en completarse."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones que superen esos límites arrojarán un error. El recuento de ejecuciones y los límites de tiempo devolverán una respuesta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), ". El tiempo de ejecución de cada función se incluye en los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "registros de funciones sin servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ayudar a evitar estos límites, los datos de los límites se proporcionan automáticamente al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: "contexto de la función"
      }), " durante la ejecución. Puedes utilizarlo para influir en tu solicitud para mantenerse dentro de esos límites. Por ejemplo, si tu aplicación requiere sondear tu punto de terminación, entonces puedes devolver con tus datos una variable para influir en la frecuencia del sondeo. De este modo, cuando el tráfico es elevado, se puede reducir el ritmo de sondeo para evitar que se alcancen los límites, y luego volver a aumentarlo cuando el tráfico sea escaso."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}