"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093036;
const slug = exports.slug = 'reference/cms/hubl/deprecated';
const title = exports.title = 'Veraltete HubL-unterstützte Funktionen';
const name = exports.name = 'EMEA DACH (DE) Deprecated HubL Supported Functions';
const metaDescription = exports.metaDescription = 'Alle HubL-Funktionen, die veraltet sind, und die Alternativen, die Sie stattdessen verwenden können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "veraltete-filter",
  "label": "Veraltete Filter",
  "parent": null
}, {
  "depth": 1,
  "id": "datetimeformat",
  "label": "datetimeformat",
  "parent": "veraltete-filter"
}, {
  "depth": 1,
  "id": "format_currency",
  "label": "format_currency",
  "parent": "veraltete-filter"
}, {
  "depth": 0,
  "id": "deprecated-functions",
  "label": "Deprecated functions",
  "parent": null
}, {
  "depth": 1,
  "id": "blog_post_by_id",
  "label": "blog_post_by_id",
  "parent": "deprecated-functions"
}, {
  "depth": 1,
  "id": "blog_topics",
  "label": "blog_topics",
  "parent": "deprecated-functions"
}, {
  "depth": 1,
  "id": "blog_recent_topic_posts",
  "label": "blog_recent_topic_posts",
  "parent": "deprecated-functions"
}, {
  "depth": 1,
  "id": "datetimeformat",
  "label": "datetimeformat",
  "parent": "deprecated-functions"
}, {
  "depth": 1,
  "id": "get_public_template_url",
  "label": "get_public_template_url",
  "parent": "deprecated-functions"
}, {
  "depth": 1,
  "id": "include_css",
  "label": "include_CSS",
  "parent": "deprecated-functions"
}, {
  "depth": 1,
  "id": "include_javascript",
  "label": "include_javascript",
  "parent": "deprecated-functions"
}, {
  "depth": 1,
  "id": "page_by_id",
  "label": "page_by_id",
  "parent": "deprecated-functions"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Veraltete HubL-Filter und -Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden finden Sie eine Liste von HubL-Filtern und -Funktionen, die veraltet sind. Obwohl diese Filter und Funktionen immer noch wie beabsichtigt funktionieren, wurden sie durch neuere ersetzt, die effektiver und optimiert sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für alle neuen und zukünftigen Projekte empfehlen wir, anstelle der veralteten HubL-Funktionen unsere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "aktuellen HubL-Funktionen"
      }), " zu verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Veraltete Filter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Filter sind veraltet:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#datetimeformat",
          children: "datetimeformat"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-currency",
          children: "format_currency"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "datetimeformat"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This function has been replaced by ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/filters#format-datetime",
          children: "format_datetime"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.updated|datetimeformat(\"%B %e, %Y\") }}\n{{ content.publish_date|datetimeformat(\"%B %e, %Y %l %p\") }}\n{{ content.publish_date|datetimeformat(\"%B %e, %Y %l %p\", \"America/Los_Angeles\") }}\n{{ content.publish_date|datetimeformat(\"%B %e, %Y %l %p\", \"America/Los_Angeles\", \"es-US\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "October 17, 2020 October 1, 2020 4 PM October 1, 2020 9 AM octubre 1, 2020 9\na.m.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "format_currency"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This function has been replaced by ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/filters#format-currency-value",
          children: "format_currency_value"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set price = 100 %}\n{{ price|format_currency(\"en-US\") }}\n{{ price|format_currency(\"fr-FR\") }}\n{{ price|format_currency(\"jp-JP\", \"JPY\", true) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "$100<br />\n100 $<br />\n￥ 100\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deprecated functions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Funktionen sind veraltet:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#blog-post-by-id",
          children: "blog_post_by_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#blog-topics",
          children: "blog_topics"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#blog-recent-topic-posts",
          children: "blog_recent_topic_posts"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#datetimeformat-nbsp-",
          children: "datetimeformat"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#get-public-template-url",
          children: "get_public_template_url"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#include-css",
          children: "include_css"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#include-javascript",
          children: "include_javascript"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#page-by-id",
          children: "page_by_id"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "blog_post_by_id"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#content-by-id",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "content_by_id()"
          })
        }), " ersetzt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_post = blog_post_by_id(4715624297) %}\n<ul>\n    <li>\n        <a href=\"{{ my_post.absolute_url }}\">{{my_post.title}}</a>\n    </li>\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    <a\n      href=\"//www.hubspot.com/blog/articles/kcs_article/email/how-do-i-create-default-values-for-my-email-personalization-tokens\"\n      >How do I create default values for my email or smart content\n      personalization tokens?</a\n    >\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "blog_topics"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#blog-tags",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "blog_tags()"
          })
        }), " umbenannt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ blog_topics(\"default\", 250) }}\n\n{% set my_tags = blog_topics(\"default\", 250) %}\n<ul>\n{% for item in my_tags %}\n  <li><a href=\"{{ blog_tag_url(group.id, item.slug) }}\">{{ item }}</a></li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[Insider]\n\n<ul>\n  <li><a href=\"https://www.ajlaporte.dev/blog/tag/insider\">Insider</a></li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "blog_recent_topic_posts"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#blog-recent-tag-posts",
          children: "blog_recent_tag_posts()"
        }), " umbenannt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{{ blog_recent_topic_posts(\"default\", \"culture\", 5) }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "datetimeformat"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#format-datetime",
          children: "format_datetime()"
        }), " ersetzt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ datetimeformat(content.publish_date_local_time, \"%B %e, %Y\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "February 27, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "get_public_template_url"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#get-asset-url",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "get_assets_url()"
          })
        }), " ersetzt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ get_public_template_url(\"custom/page/Designers_2015/designer-doc-2105.js\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "//cdn2.hubspot.net/hub/327485/hub_generated/style_manager/1431479563436/custom/page/Designers_2015/designer-doc-2105.min.html\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "include_CSS"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-css",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "require_css()"
          })
        }), " ersetzt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ include_css(\"custom/page/Designers_2015/designers-doc-2015.css\")  }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<link\n  rel=\"stylesheet\"\n  href=\"//cdn2.hubspot.net/hub/327485/hub_generated/style_manager/1431477077901/custom/page/Designers_2015/designers-doc-2015.min.css\"\n/>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "include_javascript"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "require_js()"
          })
        }), " ersetzt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ include_javascript(\"custom/page/Designers_2015/designer-doc-2105.js\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<script\n  type=\"text/javascript\"\n  src=\"//cdn2.hubspot.net/hub/327485/hub_generated/style_manager/1431479563436/custom/page/Designers_2015/designer-doc-2105.min.js\"\n></script>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "page_by_id"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Funktion wurde durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#content-by-id",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "content_by_id()"
          })
        }), " ersetzt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_page = page_by_id(4715624297) %}\n<ul>\n    <li>\n        <a href=\"{{ my_page.absolute_url }}\">{{ my_page.title }}</a>\n    </li>\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    <a\n      href=\"//www.hubspot.com/email/how-do-i-create-default-values-for-my-email-personalization-tokens\"\n      >How do I create default values for my email or smart content\n      personalization tokens?</a\n    >\n  </li>\n</ul>\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}