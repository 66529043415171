"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 44206971922;
const slug = exports.slug = 'getting-started/slack/code-of-conduct';
const title = exports.title = 'HubSpot開発者Slackコミュニティーの行動規範';
const name = exports.name = '開発者Slackの行動規範';
const metaDescription = exports.metaDescription = 'HubSpotの開発者Slackは、HubSpotプラットフォームを中心とした開発者間のサポートとコラボレーションのために、HubSpotの最も熱心な開発者が集まる専用のコミュニティーです。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = '開発者Slackでのコラボレーション';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%BA%8F%E6%96%87",
  "label": "序文",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%9C%AC%E8%A1%8C%E5%8B%95%E8%A6%8F%E7%AF%84%E3%81%AE%E6%9B%B4%E6%96%B0",
  "label": "本行動規範の更新",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%AB%E5%AF%BE%E3%81%99%E3%82%8B%E6%9C%9F%E5%BE%85",
  "label": "HubSpotサポートに対する期待",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%9C%9F%E5%BE%85%E3%81%95%E3%82%8C%E3%82%8B%E8%A1%8C%E5%8B%95",
  "label": "期待される行動",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%AC%99%E8%99%9A%E3%81%95%E3%81%8C%E3%81%82%E3%82%8B",
  "label": "謙虚さがある",
  "parent": "%E6%9C%9F%E5%BE%85%E3%81%95%E3%82%8C%E3%82%8B%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E5%85%B1%E6%84%9F%E5%8A%9B%E3%81%8C%E3%81%82%E3%82%8B",
  "label": "共感力がある",
  "parent": "%E6%9C%9F%E5%BE%85%E3%81%95%E3%82%8C%E3%82%8B%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E9%81%A9%E5%BF%9C%E5%8A%9B%E3%81%8C%E3%81%82%E3%82%8B",
  "label": "適応力がある",
  "parent": "%E6%9C%9F%E5%BE%85%E3%81%95%E3%82%8C%E3%82%8B%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E5%8D%93%E8%B6%8A%E6%80%A7%E3%81%8C%E3%81%82%E3%82%8B",
  "label": "卓越性がある",
  "parent": "%E6%9C%9F%E5%BE%85%E3%81%95%E3%82%8C%E3%82%8B%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E9%80%8F%E6%98%8E%E6%80%A7%E3%81%8C%E3%81%82%E3%82%8B",
  "label": "透明性がある",
  "parent": "%E6%9C%9F%E5%BE%85%E3%81%95%E3%82%8C%E3%82%8B%E8%A1%8C%E5%8B%95"
}, {
  "depth": 0,
  "id": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95",
  "label": "許されない行動",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%84%85%E3%81%97",
  "label": "脅し",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E5%80%8B%E4%BA%BA%E6%94%BB%E6%92%83",
  "label": "個人攻撃",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "hubspot%E3%81%AE%E5%BE%93%E6%A5%AD%E5%93%A1%E3%81%AB%E3%81%AA%E3%82%8A%E3%81%99%E3%81%BE%E3%81%99",
  "label": "HubSpotの従業員になりすます",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E8%AA%B9%E8%AC%97%E4%B8%AD%E5%82%B7%E7%9A%84%2F%E6%9C%89%E5%AE%B3%E3%81%AA%E8%A8%80%E8%91%89%E9%81%A3%E3%81%84%E3%81%A8%E3%83%8F%E3%83%A9%E3%82%B9%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "誹謗中傷的/有害な言葉遣いとハラスメント",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E6%9C%9B%E3%81%BE%E3%81%97%E3%81%8F%E3%81%AA%E3%81%84%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%B8%E3%81%AE%E5%8B%A7%E8%AA%98",
  "label": "望ましくないサービスへの勧誘",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E9%9F%B3%E5%A3%B0%2F%E3%83%93%E3%83%87%E3%82%AA%E9%80%9A%E8%A9%B1%E3%81%AE%E4%B8%8D%E6%AD%A3%E5%88%A9%E7%94%A8",
  "label": "音声/ビデオ通話の不正利用",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E8%B3%AA%E5%95%8F%E3%80%81%E5%9B%9E%E7%AD%94%E3%80%81%E3%82%B3%E3%83%9F%E3%83%A5%E3%83%8B%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8Bai%E3%81%AE%E4%BD%BF%E7%94%A8%E3%81%AE%E9%9D%9E%E9%96%8B%E7%A4%BA",
  "label": "質問、回答、コミュニケーションにおけるAIの使用の非開示",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E3%83%81%E3%83%A3%E3%83%8D%E3%83%AB%E3%81%A8%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AB%E5%AF%BE%E3%81%99%E3%82%8B%E3%82%B9%E3%83%91%E3%83%A0%E3%81%AE%E9%80%81%E4%BF%A1",
  "label": "チャネルとユーザーに対するスパムの送信",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 1,
  "id": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95%E3%82%92%E4%BF%83%E3%81%99",
  "label": "許されない行動を促す",
  "parent": "%E8%A8%B1%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E8%A1%8C%E5%8B%95"
}, {
  "depth": 0,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%81%AE%E3%83%81%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B3%E3%83%9F%E3%83%A5%E3%83%8B%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%ABhubspot-%E9%96%8B%E7%99%BA%E8%80%85slack%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "非公開のチームのコミュニケーションにHubSpot 開発者Slackを使用する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9F%B3%E5%A3%B0%2F%E3%83%93%E3%83%87%E3%82%AA%E9%80%9A%E8%A9%B1%E3%81%A8%E7%94%BB%E9%9D%A2%E5%85%B1%E6%9C%89",
  "label": "音声/ビデオ通話と画面共有",
  "parent": null
}, {
  "depth": 0,
  "id": "slack%E3%80%8C%E3%82%A2%E3%83%97%E3%83%AA%E3%80%8D%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "Slack「アプリ」の使用",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%88%E3%83%94%E3%83%83%E3%82%AF%E3%82%92%E3%81%BE%E3%81%A8%E3%82%81%E3%82%8B%EF%BC%88%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89%E5%BD%A2%E5%BC%8F%E3%81%AE%E3%83%A1%E3%83%83%E3%82%BB%E3%83%BC%E3%82%B8%EF%BC%89",
  "label": "トピックをまとめる（スレッド形式のメッセージ）",
  "parent": null
}, {
  "depth": 0,
  "id": "gif%E3%81%A8%E7%B5%B5%E6%96%87%E5%AD%97",
  "label": "GIFと絵文字",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88%E3%81%A8%E9%96%8B%E7%99%BA%E8%80%85slack%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AB%E5%AF%BE%E3%81%99%E3%82%8B%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88%E3%81%AE%E5%AE%9F%E6%96%BD",
  "label": "アンケートと開発者Slackユーザーに対するアンケートの実施",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A1%8C%E5%8B%95%E8%A6%8F%E7%AF%84%E9%81%95%E5%8F%8D%E3%81%AE%E5%A0%B1%E5%91%8A",
  "label": "行動規範違反の報告",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "開発者Slackの行動規範"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "序文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの開発者Slackは、HubSpotプラットフォームを中心とした開発者間のサポートとコラボレーションのために、HubSpotの開発者が集まる専用のコミュニティーです。開発者Slackを使用することにより、以下の開発者Slackの行動規範（「行動規範」）、該当する場合は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "HubSpots利用規定"
      }), "、および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://slack.com/acceptable-use-policy",
        children: "Slackの利用規定"
      }), "に従うことに同意したものとみなされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "本行動規範の更新"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは本行動規範を随時更新する権利を保有します。更新された場合、開発者Slackの#releases-and-updatesチャネルで変更を通知するように尽力しますが、この行動規範は一般的な状況を想定して作成されており、発生する可能性のある全ての状況を予測することはできないため、コミュニティーに事前に通知することなく更新を行い、ここに明示的に記載されていない可能性のある違反に対して措置を講じる必要がある場合があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは開発者Slackを随時ダウングレードまたは無効にする権利を保有しますが、コミュニティーに対して十分な事前通知を行うことに尽力します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotサポートに対する期待"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者SlackはHubSpotの公式サポートチャネルではありません。このSlackには、可能な場合に喜んでサポートを提供するHubSpotの従業員が参加している可能性がありますが、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubSpotの従業員へのメンションやメッセージで直接質問することはご遠慮ください"
      }), "。HubSpotからの直接のサポートを必要とする質問がある場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://help.hubspot.com/",
        children: "help.hubspot.com"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/",
        children: "コミュニティーフォーラム"
      }), "にアクセスしてください。HubSpotは、コミュニティーの改善のためにこれらのガイドラインを実施、解釈、および拡張する権利を保有します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "期待される行動"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotには何ページにもわたるポリシーや手順書はありません。代わりに「Use Good Judgement（自分で適切な判断を下す）」という簡潔なポリシーとHEARTというコアバリューがあります。コミュニティーが最適な判断を下し、HubSpotの次のコアバリューを共有することを奨励しています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "謙虚さがある"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "完璧なものはありません。自分自身を認識し、常に敬意を持って接しましょう。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "共感力がある"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "誰かのコメントを理解するだけでなく、全ての人に思いやりと敬意を持って行動してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "適応力がある"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "好奇心を失わず、常に変化できるようにしておきます。生涯にわたり学び続けましょう。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "卓越性がある"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "思慮深いBill S. Preston Esquireの言葉を借りれば「互いにエクセレントであれ」です。知識を共有して、素晴らしい存在となることを恐れないでください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "透明性がある"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "他者だけでなく、自分自身にもオープンで正直になりましょう。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの価値観の詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/34234/the-hubspot-culture-code-creating-a-company-we-love.aspx",
        children: "HubSpotのカルチャーコード"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "許されない行動"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次の行動は許されない行動とみなされ、Slackワークスペースの誰であってもこのような行動は容認されません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "脅し"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "他のユーザーに対する脅しは容認されません。これには、ユーザーに対する脅しの奨励、または他者の個人特定情報の投稿人物を特定する情報の投稿/投稿の脅迫（「晒し行為」）が含まれます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "個人攻撃"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "双方の当事者の間で意見の相違が生じる可能性は常にあります。苛立たしい状況になる可能性がありますが、誰かに対する個人攻撃になってはなりません。意見、個人的信念、アイデア、提案が原因で他者を攻撃することは容認されません。常にプロフェッショナルで礼儀正しく対応してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotの従業員になりすます"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotの従業員（HubSpotに雇用されていること）を偽るユーザーは、Slackワークスペースへの参加を禁止されます。これは厳格に適用されるルールです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "誹謗中傷的/有害な言葉遣いとハラスメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ユーザーに対する有害な言葉遣い："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "背景"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "家族"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "性自認または性表現"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "性的指向"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "人種/民族性"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "身体的特徴（体の大きさ、形、傷跡など）"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "年齢"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "母国語"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "能力"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "出身国"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "所在地"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "宗教"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "支持政党"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "その他の個人属性"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記に関する有害な言葉遣いは受け入れられません。常に最適な判断を下してください"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "望ましくないサービスへの勧誘"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ユーザーから具体的に要求されない限り、サービス提供に関するメッセージをユーザーに直接送信することは容認されません。これには、雇用機会やフリーランスプロジェクトに関するダイレクトメッセージも含まれます。Slackワークスペース内には、このようなメッセージを投稿できる#jobsチャネルと、誇りに思っているHubSpotでの実績をコミュニティーに対して紹介する#showcaseチャネルが用意されています。この2つのチャネルは、あらゆる形態の勧誘や自己宣伝が許容される唯一のチャネルです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "音声/ビデオ通話の不正利用"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slackでは、プラットフォーム内で音声/ビデオ通話を使用できます。この機能を不正利用し、ユーザーの許可なく直接電話をかけることは容認されません。誤って操作したり、誤ってボタンをクリックしたりすることがあります。このような場合は、プロフェッショナルな態度で、ユーザーに謝罪のメッセージを送信してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "質問、回答、コミュニケーションにおけるAIの使用の非開示"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者Slackで使用されるコンテンツを生成するためにAIツールを使用する場合は常に、そのようなAIツールを使用することを明確に開示する必要があります。また、AIツールに提供する情報に注意し、HubSpotの機密情報を共有しないようにする必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "チャネルとユーザーに対するスパムの送信"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者Slackには、プラットフォームの特定の機能と多くのユーザーに合わせてカスタマイズされた多くのチャネルがあります。メッセージを投稿する際は、そのメッセージの投稿に適したチャネルを使用してください。露出を増やす目的で、複数のチャネルにメッセージをコピーして貼り付けることは容認されません。同じチャネル内に同じメッセージや同様のメッセージを頻繁に投稿すること、および/またはユーザーに対して直接スパムを送信することは容認されません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "許されない行動を促す"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の許されない行動に参加するようにユーザーを促すことは容認されません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非公開のチームのコミュニケーションにHubSpot 開発者Slackを使用する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot 開発者Slackに参加することで、より広範なHubSpot Developer Communityに貢献することを確約することになります。（この行動規範に従い）どのようなコミュニケーショントピックも抑制されることはありませんが、ダイレクトメッセージやその他の手段を利用して非公開で会社のビジネスを行う目的で開発者Slackを使用しないでください。そのような行為が発見された場合には、HubSpotは開発者Slackからあなたとあなたのチームを削除する権利を保有します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "音声/ビデオ通話と画面共有"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slackではユーザーが音声/ビデオ通話を使用でき、また画面を共有することができます。これらの機能を使用する際には、他者に配慮し、敬意を払ってください。画面を共有するときは適切な判断を下してください。機密情報や個人情報が含まれている他のウィンドウがコンピューターで開いている可能性があることに注意してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Slack「アプリ」の使用"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slackでは、Slackの機能を拡張するためにアプリケーションをワークスペースに追加できます。開発者Slackワークスペースにユーザーが独自にアプリをインストールすることはできません。ワークスペースへのアプリのインストールは常にHubSpotによって行われます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "トピックをまとめる（スレッド形式のメッセージ）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slackの優れた機能の1つは、スレッド形式のコメント機能です。コミュニケーションをまとめて簡単に検索/スキャンできるようにするために、この機能を使用することをお勧めします。メインチャネルをすっきりと整理された状態に保ち、コミュニケーションのトピックを1か所にまとめるのに役立ちます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "GIFと絵文字"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "チャネルやスレッド形式のコメントでGIFを使用する場合は、GIFのコンテンツ/コンテキストに注意してください。同様に、Slackでは絵文字を使ってコメントに関する感情を表現できます。他者とコミュニケーションをとる際は、使用する絵文字に注意してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アンケートと開発者Slackユーザーに対するアンケートの実施"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者Slackワークスペースは、ウェブでの開発やHubSpotでの開発に関するコミュニケーションに使用することを目的としています。アンケートの作成が許可されており、ワークスペースには誰でも使用できるPolly Slackアプリケーションが用意されています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pollyを使用する要件は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アンケートは、ウェブでの開発（CSSやJSライブラリーなど）またはHubSpotでの開発に関連している必要があります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "\"［Show Responses］は常にオンにしておく必要があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者Slackで個人にアンケートを実施する際には、次のことは許可されていません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ユーザーのデモグラフィック情報、医療情報、またはその他の個人情報を含む非公開情報の要求。*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サードパーティーのサイトでホストされているアンケート（Googleフォーム、SurveyMonkeyフォーム、個人のウェブサイトなど）へのリンク。*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ユーザーにアンケートへの入力を求めるダイレクトメッセージの送信。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "* = HubSpotの従業員は、HubSpot製品の改善のためにHubSpot開発者Slackでアンケートを実施する権利を保持します。HubSpotの従業員は、HubSpotの公式アンケートが別の場所で実施される場合には、そのアンケートがHubSpotの公式アンケートであることを明確にするよう努めます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "行動規範違反の報告"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "本行動規範の違反、またはHubSpot Developer Communityであなたや他の方を不快にさせた可能性がある事柄を報告する場合には、当社の管理者に直接連絡できます。現職の全ての管理者は、#generalチャネルのピン留めされた投稿に表示されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["違反を匿名で報告することを希望される場合、または管理者にDMを送信しないことを希望される場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://form.asana.com/",
        children: "このフォームに記入"
      }), "していただければ、当社に速やかに通知されます。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}