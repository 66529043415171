"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831400;
const slug = exports.slug = 'reference/cms/hubl/tags/related-blog-posts';
const title = exports.title = 'Crear un listado de publicaciones de blog relacionadas con la etiqueta HubL de entradas relacionadas de blog.';
const name = exports.name = 'EMEA ES Creating a related blog post listing with the blog related posts HubL tag';
const metaDescription = exports.metaDescription = 'La etiqueta HubL related_blog_posts se puede usar para crear publicaciones de blog dinámicas y relacionadas basadas en una variedad de parámetros. Permite generar listados de publicaciones en todos los blogs, con la capacidad de filtrar por etiquetas, autores, rutas de publicación y fechas de publicación.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "par%C3%A1metros",
  "label": "Parámetros",
  "parent": null
}, {
  "depth": 0,
  "id": "ejemplos-de-usos-de-la-etiqueta-related_blog_posts-hubl",
  "label": "Ejemplos de usos de la etiqueta Related_blog_posts HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "mostrar-publicaciones-de-un-autor-espec%C3%ADfico-en-varios-blogs",
  "label": "Mostrar publicaciones de un autor específico en varios blogs",
  "parent": "ejemplos-de-usos-de-la-etiqueta-related_blog_posts-hubl"
}, {
  "depth": 1,
  "id": "mostrar-publicaciones-con-la-etiqueta-%22habilitaci%C3%B3n-de-ventas%22%2C-restringida-a-un-marco-de-tiempo-de-fecha-de-publicaci%C3%B3n-espec%C3%ADfico",
  "label": "Mostrar publicaciones con la etiqueta \"habilitación de ventas\", restringida a un marco de tiempo de fecha de publicación específico",
  "parent": "ejemplos-de-usos-de-la-etiqueta-related_blog_posts-hubl"
}, {
  "depth": 1,
  "id": "mostrar-entradas-usando-una-devoluci%C3%B3n-de-llamada-js-para-controlar-la-salida-html",
  "label": "Mostrar entradas usando una devolución de llamada JS para controlar la salida HTML",
  "parent": "ejemplos-de-usos-de-la-etiqueta-related_blog_posts-hubl"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Crear un listado de publicaciones de blog relacionados con la etiqueta HubL de entradas relacionadas de blog."
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), " se puede usar para crear publicaciones de blog dinámicas y relacionadas basadas en una variedad de parámetros. Permite generar listados de publicaciones en todos los blogs, con la capacidad de filtrar por etiquetas, autores, rutas de publicación y fechas de publicación. Los desarrolladores también pueden especificar la salida HTML del módulo utilizando una macro. Esta etiqueta de HubL se puede usar tanto en publicaciones de blog como en páginas. Este tutorial recorrerá los parámetros y las opciones de uso para la etiqueta HubL de las publicaciones relacionadas con el blog."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Ten en cuenta que la etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " no genera un módulo editable a nivel de entrada/página, sino que está configurado en su totalidad con HubL."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parámetros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La lista de publicaciones se genera a partir de una puntuación de relevancia basada en una comparación de los valores de los parámetros establecidos con las publicaciones que coinciden con estos parámetros o relacionadas con la publicación en la que aparece la etiqueta HubL. Ninguno de los parámetros son requeridos, sin embargo, especificar parámetros te permitirá controlar aún más qué publicaciones se devuelven. Para los parámetros separados por comas, cuantos más valores establezcas, más diverso será el listado devuelto. El parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), " te permite especificar una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macro"
      }), " para generar la salida HTML del módulo. Para obtener una lista completa de parámetros y un ejemplo de salida HTML predeterminada, consulta la especificación ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número máximo de publicaciones de blog en la lista."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "'default' or blog id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los ID de un blog del cual incluir publicaciones."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La(s) etiqueta(s) que debe(n) utilizarse para determinar si un post es relevante (separadas por comas). Si un post de blog tiene una de estas etiquetas o una similar, la relevancia del post aumenta, mejorando su clasificación en el listado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_authors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los nombres de los autores de los que se incluirán las publicaciones (separados por comas)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El(los) identificador(es) de un post de blog que se utilizará(n) cuando se busquen publicaciones de blog relevantes para la lista (separados por comas). Este parámetro solo debe usarse cuando el widget aparece en páginas, ya que en las publicaciones del blog, por opción predeterminada, será el post en la que aparece el widget."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_formatter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El nombre de una macro personalizada para renderizar las publicaciones de blog devueltos. A la macro se le pasan tres parámetros que son el objeto de post de blog a formatear, el recuento en la iteración de publicaciones de blog, y el recuento total de posts de blog en los resultados. Si no se especifica o se establece como “default”, se utilizará el formateador incorporado para dar formato a cada post.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nota: se recomienda usar el parámetro de devolución de llamada a continuación en lugar del parámetro ‘post_formatter’, ya que el HTML de la etiqueta se renderizará más rápidamente, disminuyendo los tiempos de carga de la página."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callback"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de una función javascript para renderizar los posts de blog devueltos. A la función se le pasa una matriz de objetos de posts de blog para formatear. Si no se especifican los parámetros callback o post_formatter, la etiqueta generará HTML en un formato predeterminado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path_prefixes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rutas de URL o subdirectorios desde los que incluir los posts (separadas por comas). Si un post de blog tiene un prefijo similar en tu ruta, la relevancia del post aumenta, mejorando su clasificación en el listado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite filtrar las entradas publicadas después de una fecha/hora."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/Time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite filtrar las entradas publicadas antes de una fecha/hora."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El(los) ID de los posts de un blog que deben aparecer siempre en el listado devuelto, a pesar de todos los demás valores de parámetros y filtros (separados por comas)."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Recomendamos encarecidamente usar el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "callback"
        }), " en lugar del parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "post_formatter"
        }), " para garantizar tiempos de carga de página más rápidos."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Ten en cuenta que si la etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " se está utilizando en una publicación, no se debe especificar el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post_ids"
        }), ", ya que en las publicaciones del blog, por defecto será la publicación en la que aparece el widget."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ejemplos de usos de la etiqueta Related_blog_posts HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, encontrarás algunos ejemplos de formas de usar esta etiqueta para mejorar tu sitio web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mostrar publicaciones de un autor específico en varios blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En este ejemplo, generamos una lista de publicaciones escritas por uno de los tres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_authors"
      }), " especificados en dos blogs diferentes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_ids=\"3241539189,3261083894\", limit=6, blog_authors=\"John Smith,Joe Smith,Frank Smith\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mostrar publicaciones con la etiqueta \"habilitación de ventas\", restringida a un marco de tiempo de fecha de publicación específico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En este ejemplo, generamos una lista de 10 publicaciones relacionadas con una publicación de blog específica, con la etiqueta \"habilitación de ventas\" y restringida a un marco de tiempo de fecha de publicación específico. Este ejemplo especifica el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post_ids"
      }), ", por lo que se usaría en una página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_post_ids=\"3267910554\", limit=10, tags=\"sales enablement\", start_date=\"2018-02-05\", end_date=\"2018-06-10\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mostrar entradas usando una devolución de llamada JS para controlar la salida HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En este ejemplo, generamos una lista de 5 publicaciones utilizando el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback"
      }), " para controlar la salida HTML de la publicación. (En lugar del parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), " usando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macro"
      }), ".)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts limit=5, callback=\"blog_post_formatter\" %}\n\n<script>\n  var blog_post_formatter = function(blogposts) {\n\n    var formatted = \"<div>\";\n    for (var i = 0; i < blogposts.length; i++) {\n      var blogpost = blogposts[i];\n      formatted += '<div class=\"related-blog-item\">';\n      formatted += `<span>Related Post ${i + 1}/${blogposts.length}</span><br>`;\n      formatted += `<a class=\"related-blog-title\" href=\"${blogpost.url}\"><span>${blogpost.name}</span></a>`;\n      formatted += `<div class=\"hs-related-blog-byline\">by <span class=\"related-blog-author\">${blogpost.blogAuthor.fullName}</span><span class=\"related-blog-posted-at\"> posted on </span><span class=\"related-blog-date\">${new Date(blogpost.publishDate).toLocaleDateString()}</span></div>`;\n      formatted += `<p class=\"related-blog-post-summary\">${blogpost.postSummary}<a href=\"${blogpost.url}\">Read more</a></p>`;\n      formatted += '<div class=\"related-blog-tags\">';\n      if (blogpost.tagList.length > 0) {\n        formatted += `Tags: ${blogpost.tagList.map(tag => tag.label).join(\", \")}`;\n      }\n      formatted += '</div>';\n\n      if (blogpost.featuredImage) {\n        formatted += `<img src=\"${blogpost.featuredImage}\" alt=\"${blogpost.featuredImageAltText}\">`;\n      }\n      formatted += '</div>';\n    }\n    formatted += '</div>';\n    return formatted;\n  }\n</script>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}