"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694125;
const slug = exports.slug = 'guides/cms/content/themes/overview';
const title = exports.title = 'テーマの概要';
const name = exports.name = 'テーマの概要';
const metaDescription = exports.metaDescription = '制作担当者向け作業環境としてのデザインシステムを開発者が構築するためのHubSpot CMSテーマの作成について紹介します。 ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/themes.gif';
const featuredImageAltText = exports.featuredImageAltText = 'HubSpotテーマ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%91%E3%83%83%E3%82%B1%E3%83%BC%E3%82%B8%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "パッケージとしてのテーマ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "ページの作成",
  "parent": "%E3%83%91%E3%83%83%E3%82%B1%E3%83%BC%E3%82%B8%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "テーマフィールド",
  "parent": "%E3%83%91%E3%83%83%E3%82%B1%E3%83%BC%E3%82%B8%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "テーマモジュール",
  "parent": "%E3%83%91%E3%83%83%E3%82%B1%E3%83%BC%E3%82%B8%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E6%A7%8B%E9%80%A0",
  "label": "テーマファイルの構造",
  "parent": null
}, {
  "depth": 1,
  "id": "theme.json",
  "label": "theme.json",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E6%A7%8B%E9%80%A0"
}, {
  "depth": 1,
  "id": "fields.json",
  "label": "fields.json",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E6%A7%8B%E9%80%A0"
}, {
  "depth": 2,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E5%80%A4%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "テーマのフィールド値の使用",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E6%A7%8B%E9%80%A0"
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC",
  "label": "テーマのプレビュー",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%82%B9%E3%83%88%E3%83%A2%E3%83%BC%E3%83%89",
  "label": "テストモード",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%83%AA%E3%82%BD%E3%83%BC%E3%82%B9",
  "label": "関連リソース",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h4: "h4",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "テーマの概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマは、移植可能な形式に統合されたさまざまな開発アセットであり、柔軟なコンテンツ編集環境の提供に役立ちます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "HubSpot CLIを使用してローカル環境でテーマを作成"
      }), "する際には、使い慣れたツール、テクノロジー、ワークフローを使用できます。テーマと、テーマ内の全てのファイルも、環境間およびアカウント間で移植可能です。テーマの開発方法に関する動画については、以下のHubSpotアカデミーの動画をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172844605010"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "パッケージとしてのテーマ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマはパッケージであり、HubSpotアプリ全体に適用されるので、効率的なコンテンツ作成が可能になります。開発者はテーマを使用して、制作担当者向けのデザインシステムを構築できます。柔軟なテーマに、一定の基準を組み込んでビジネスニーズに合わせることもできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ページの作成"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "制作担当者が新しいページの構築を始めるとき、ページ作成の出発点にするテーマを選択し、そのテーマ内のテンプレートを選択して開始するよう指示されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20selection.gif",
        alt: "HubSpot内でのテーマの選択"
      }), "テーマのプレビュー画像などのテーマ設定は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#theme-json",
        children: "theme.jsonファイル"
      }), "に設定されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーマフィールド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者はテーマを使ってモジュールフィールドに類似した", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "テーマフィールド"
      }), "のセットを作成できます。これにより制作担当者は、開発者がデザインしたさまざまなノブやダイヤルを調整し、CSSを編集せずにウェブサイト全体のスタイルを制御できます。開発者はCSS全体の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#using-theme-field-values",
        children: "テーマフィールドの値にHubLを使用してアクセス"
      }), "します。制作担当者はテーマエディターを使用して、テーマフィールドの値を変更し、テーマに含まれる既存のテンプレートに対する変更をプレビューしてから、その変更を公開します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/themes.gif",
        alt: "テーマ"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマフィールドは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "テーマのfields.jsonファイル"
      }), "で決まります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#test-mode",
        children: "テストモード"
      }), "でテーマを編集するときには、テーマの設定JSONをコピーすることもできます。こうすると、更新内容をテーマのローカル", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルに貼り付けることができます。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode.png",
        alt: "copy-theme-settings-test-mode"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーマモジュール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマ内のモジュールは、テーマ内のテンプレート内で使用するように設計する必要があります。テーマモジュールはコンテンツエディター上で強調表示されるので、制作担当者は作成しているページにそのページのコンテキストで正しく機能するようにデザインされたモジュールを素早く簡単に追加できます。既定のモジュールおよび残りのモジュールは引き続きHubSpotアカウントで利用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20modules.png",
        alt: "テーマ内のテーマモジュール"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマを開発するときに、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/hide-modules-and-sections",
        children: "ページエディターでモジュールとセクションを非表示"
      }), "にできます。こうすると、より合理的なコンテンツ作成エクスペリエンスが実現します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマファイルの構造"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマはファイルを含む1つのディレクトリーです。親テーマフォルダーのサブディレクトリー内にはHTML、CSS、JavaScriptファイル、モジュールなどのファイルを含めることができ、任意の方法で整理できます。テーマを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "の2つのJSONファイルが必要です。これらのファイルをルート テーマ フォルダーに含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サンプルから始めるには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "HubSpot CMSボイラーテンプレート"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hs-fs/hubfs/theme%20file%20structure.png?width=599&height=452&name=theme%20file%20structure.png",
        alt: "テーマファイルの構造"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["現時点では、.jsonファイルの作成とHubSpotアカウントへのアップロードは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "ローカル開発ツール"
        }), "を通じてのみ行えます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "theme.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "ファイルには、テーマの読み取り可能なラベル、プレビュースクリーンショット、テーマの動作のためのさまざまな設定など、テーマディレクトリーのメタ情報が含まれます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "ファイルの例を次に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーマの読み取り可能なラベル。テンプレート選択画面やテーマエディターなど、HubSpotアプリ全体にわたってテーマが表示されるさまざまな箇所で使用されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "preview_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーマ内のテンプレートファイル（既定のテンプレート）への相対パス。これはテーマエディターでテーマをプレビューする際に使用されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshot_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像ファイルへの相対パス。この画像は、テーマが選択されるさまざまな箇所（テンプレート選択画面など）で、テーマの外観を示すスナップショットの提供に使用されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enable_domain_stylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ウェブサイト設定でドメインに添付されたスタイルシートをテーマ内のテンプレートに含めるかどうかを指定します。既定値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "."
            }), "に対応した整数のバージョン番号。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["テーマプロバイダーとしての開発者自身に関する情報を提示するオブジェクト。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "プロバイダーの名前。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "プロバイダーのサポート担当のEメールアドレス。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "プロバイダーのウェブサイト。", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "documentation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーマドキュメントのリンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "example_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーマの実例リンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "license"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["有効な", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://spdx.org/licenses/",
              children: "SPDX ID"
            }), "またはテーマ内のライセンスの相対パス。このライセンスは、テーマの作成者に対してどのような使用と変更を許可するかを規定します。マーケットプレイスに提出する場合に便利です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["制作担当者のページにテーマが選択対象として表示されるかどうかを指定するブール値。既定値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "です。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルは、テーマエディターで利用可能なフィールドとフィールドグループ（", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "スタイルフィールド"
      }), "を含む）を制御します。含めるフィールドは、ページエディターでの制作担当者による制御をどの程度可能にするかに応じて異なります。テーマ内で利用可能なフィールドの数は、モジュールと比べて限られています。これは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "グローバルコンテンツ"
      }), "がテーマのコンテンツに適している一方、テーマフィールドはスタイルオプションとして最適なためです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、テーマの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field.json"
      }), "にサイトのタグラインのテキストフィールドを追加する代わりに、それをグローバルモジュールとして追加します。これにより、制作担当者はテーマエディターではなくページエディター内でタグラインを更新できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマ内で使用できるフィールドを次に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#boolean",
          children: "ブール値"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "境界線"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#choice",
          children: "選択"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "カラー"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "フォント"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#image",
          children: "画像"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#number",
          children: "数値"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "間隔"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマフィールドで使用できるオプションに関する包括的なドキュメントについては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "モジュールとテーマフィールドのドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "テーマのフィールド値の使用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フィールド値にアクセスするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "の値へのパスにthemeを使用したドット表記とプレフィックスを使用します。スタイルシートでテーマフィールド値を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ theme.path.to.value }}"
      }), "のような構文で使用できます。例えば、以下のフォントフィールドで説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"type\": \"group\",\n    \"name\": \"typography\",\n    \"label\": \"Typography\",\n    \"children\": [\n      {\n        \"type\": \"font\",\n        \"name\": \"h1_font\",\n        \"label\": \"Heading 1\",\n        \"load_external_fonts\": true,\n        \"default\": {\n          \"color\": \"#000\",\n          \"font\": \"Merriweather\",\n          \"font_set\": \"GOOGLE\",\n          \"variant\": \"700\",\n          \"size\": \"48\"\n        }\n      }\n    ]\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これを次のようなCSSで参照します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "h1 {\n font-size: {{ theme.typography.h1_font.size }}px;\n font-family: {{ theme.typography.h1_font.font }};\n color: {{ theme.typography.h1_font.color }};\n text-decoration: {{ theme.typography.h1_font.styles.text-decoration }};\n font-style: {{ theme.typography.h1_font.styles.font-style }};\n font-weight: {{ theme.typography.h1_font.styles.font-weight }};\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマのプレビュー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者は、テーマフィールドの正常な動作をテストする必要が生じる場合がありますが、実際のページに影響を与えないようにもする必要があります。ここでテーマ テスト モードを使います。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テストモード"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["テストモードを使用するとテーマのフィールドを操作できる安全な環境が提供され、期待どおりに機能することを確かめることができます。インターフェイスは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings#edit-theme-settings",
              children: "制作担当者が表示できるテーマプレビュー／エディター"
            }), "とまったく同様の表示ですが、実際のウェブサイトの設定は変更されないので安心です。誤ってテーマ設定が更新されないように保護されるため、テストモードでは公開がブロックされています。テストモードになっているかどうかはアドレスバーに", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?testmode=true"
            }), "と表示されることで分かります。また、テーマエディターのヘッダーにはテスト モード アイコンが表示されます。"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://f.hubspotusercontent00.net/hubfs/53/theme-test-mode.png",
            alt: "テーマテスト／プレビューモード"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テストモードを有効にするには、次の2つの方法があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "デザインマネージャーからテストモードを有効にするには："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["デザインマネージャーのファインダーで、該当する", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "テーマ"
              }), "を選択します。"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "左側のサイドバーの上部にある［プレビュー］ボタンをクリックします。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode-design-manager%20(1).png",
                alt: "copy-theme-settings-test-mode-design-manager (1)"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ページエディターからテストモードを有効にするには："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "ページエディターで、左側のサイドバーの［デザイン］タブをクリックして、［テーマ設定を編集］をクリックします。"
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/page-editor-edit-theme.png",
                alt: "page-editor-edit-theme"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["URLに", (0, _jsxRuntime.jsx)(_components.code, {
                children: "?testmode=true"
              }), "を追加して、", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Enter"
              }), "キーを押します。その後、テストモードになります。"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページエディター内でテーマ設定を開く方法もあります。開いたら次に、クエリーパラメーター", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?testmode=true"
      }), "をアドレスバーのURLに追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連リソース"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "テーマを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "テーマ機能を既存のサイトに追加する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "HubSpotテーマボイラープレート"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}