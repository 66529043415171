"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492890236;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/overview';
const title = exports.title = "Vue d'ensemble des fonctions sans serveur";
const name = exports.name = 'EMEA (FR) Serverless Overview';
const metaDescription = exports.metaDescription = "Les fonctions sans serveur sont écrites en JavaScript et utilisent l'environnement d'exécution NodeJS. Utilisez-les pour ajouter des fonctionnalités nouvelles et avancées aux sites Web CMS HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "exemples",
  "label": "Exemples",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-d-utilisation",
  "label": "Limites d'utilisation",
  "parent": null
}, {
  "depth": 1,
  "id": "limites-d-ex%C3%A9cution",
  "label": "Limites d'exécution",
  "parent": "limites-d-utilisation"
}, {
  "depth": 1,
  "id": "fractionner-les-d%C3%A9pendances",
  "label": "Fractionner les dépendances",
  "parent": "limites-d-utilisation"
}, {
  "depth": 0,
  "id": "acc%C3%A8s-aux-fonctions-sans-serveur-en-cours",
  "label": "Accès aux fonctions sans serveur en cours",
  "parent": null
}, {
  "depth": 1,
  "id": "r%C3%A9pertoires-de-fonctions-sans-serveur",
  "label": "Répertoires de fonctions sans serveur",
  "parent": "acc%C3%A8s-aux-fonctions-sans-serveur-en-cours"
}, {
  "depth": 1,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": "acc%C3%A8s-aux-fonctions-sans-serveur-en-cours"
}, {
  "depth": 1,
  "id": "function.js",
  "label": "Function.js",
  "parent": "acc%C3%A8s-aux-fonctions-sans-serveur-en-cours"
}, {
  "depth": 0,
  "id": "secrets",
  "label": "Secrets",
  "parent": null
}, {
  "depth": 0,
  "id": "affichage-des-journaux-des-fonctions-sans-serveur",
  "label": "Affichage des journaux des fonctions sans serveur",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " si vous créez une fonction sans serveur dans le cadre d'un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "projet de développement"
        }), ", consultez plutôt la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "documentation relative aux fonctions sans serveur dans les projets de développement"
        }), ". La documentation ci-dessous concerne la création de fonctions sans serveur en dehors de la plate-forme de projets de développement."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions sans serveur permettent d'écrire un code côté serveur qui interagit avec HubSpot et des services tiers via des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "API"
      }), ". Les API exigeant une authentification ne sont pas sécurisées pour l'interface d'un site web, car vos identifiants seraient exposés. Les fonctions sans serveur peuvent agir comme intermédiaires en vous permettant de conserver la confidentialité de vos identifiants."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avec les fonctions sans serveur, vous n'avez pas besoin de programmer et de gérer de nouveaux serveurs. Les fonctions sans serveur exigent moins de ressources et sont donc plus faciles à faire évoluer au fil de la croissance d'une entreprise."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez tester les fonctions sans serveur en utilisant un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "compte CMS sandbox développeur"
      }), ". Pour créer votre première fonction sans serveur, consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
        children: "guide de démarrage des fonctions sans serveur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemples"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "La liste des choses pour lesquelles vous pouvez utiliser les fonctions sans serveur HubSpot ne dépend que de votre imagination. Vous pouvez les utiliser pour :"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Collecter des données et les stocker dans HubDB ou CRM HubSpot"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Les calculateurs de données complexes"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "L'affichage dynamique de données provenant d'autres systèmes"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/cms/overview",
                children: "Les systèmes d'inscription à des événements"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Les soumissions de formulaires qui envoient des données à d'autres systèmes"
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172853952400"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "À l'aide de l'exemple de système d'inscription à des événements, vous pourrez utiliser des fonctions sans serveur pour gérer les inscriptions et mettre à jour le nombre de places disponibles pour un événement. Le flux fonctionnerait comme suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le visiteur du site Web accède à la page d'inscription à votre événement, qui indique qu'il y a de la place pour 15 personnes supplémentaires. Le visiteur remplit un formulaire personnalisé pour s'inscrire à l'événement et l'envoie."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cette soumission est configurée pour envoyer une requête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yourwebsite.com/_hcms/api/event/participants"
        }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event/participants"
        }), " est votre fonction sans serveur."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Votre fonction sans serveur reçoit les données soumises par l'utilisateur et effectue quelques actions avant de renvoyer une réponse au navigateur :"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envoie les données du champ du formulaire à l'API du formulaire de soumission HubSpot pour intégrer les informations de cette soumission de formulaire au CRM HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilise l'API HubDB pour soustraire 1 du nombre de participants à cet événement, qui est stocké dans HubDB."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Renvoie une réponse au navigateur Web."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "JavaScript reçoit la réponse de la fonction sans serveur, un message de confirmation à l'utilisateur final s'affiche sur la page, et ajuste le compte du nombre de créneaux restants pour les participants."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions sans serveur de HubSpot sont écrites en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        children: "JavaScript"
      }), " et utilisent l'environnement d'exécution ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/about/",
        children: "NodeJS"
      }), ". Les fonctions sans serveur de HubSpot sont destinées à être utilisées pour ajouter des fonctionnalités à votre site HubSpot, comme la prise en charge des soumissions de formulaires avancés et l'extraction de données à partir d'autres API. Il ne s'agit pas d'une plateforme informatique générique où vous pourriez exécuter du code non lié à HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'utilisation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les fonctions sans serveur sont destinées à être rapides avec un objectif restreint. Cette rapidité leur permet de s'allier parfaitement à l'interface des sites Web et applications, pour un appel et une réponse rapides. Par souci de performance, les fonctions sans serveur de HubSpot sont limitées à :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 secrets par compte."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128 Mo de mémoire."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pas plus de 100 points de terminaison par compte HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le contentType ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " lors de l'appel d'une fonction."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6 Mo par charge utile d'invocation, ce que vous pourriez rencontrer en essayant de charger un fichier avec une fonction sans serveur, par exemple."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "4 Ko pour la quantité de données qui peuvent être enregistrées. Lorsque vous atteignez cette limite, il est recommandé de vous enregistrer après chaque action individuelle, plutôt qu'après le résultat final."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limites d'exécution"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Chaque fonction a un temps d'exécution maximum de 10 secondes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Chaque compte est limité à 600 secondes d'exécution totale par minute."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela signifie que l'une ou l'autre de ces situations peut se produire en une minute :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Jusqu'à 60 exécutions de fonctions qui prennent chacune 10 secondes à être terminées."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Jusqu'à 6 000 exécutions de fonctions qui prennent 100 millisecondes à être terminées."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions qui dépassent ces limites de temps envoient un message d'erreur. Le nombre d'exécutions et les limites de temps renverront une réponse ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), ". Le temps d'exécution de chaque fonction est inclus dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#viewing-serverless-function-logs",
        children: "journaux des fonctions sans serveur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fractionner les dépendances"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fonctions sans serveur ne prennent pas en charge le fractionnement JavaScript entre plusieurs fichiers lors du déploiement. Au lieu de cela, votre fonction sans serveur doit inclure un fichier JavaScript pour exécuter la fonction. Si vous créez une fonction sans serveur avec plusieurs fichiers JavaScript, vous devez plutôt copier le code partagé dans le seul fichier JavaScript ou utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " pour regrouper votre code. Découvrez-en davantage sur l'utilisation de webpack en tant que solution sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/Import-packages-in-serverless-functions/m-p/346620",
        children: "communauté HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Accès aux fonctions sans serveur en cours"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, les fonctions sans serveur sont stockées dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "système de fichiers du développeur"
      }), ", visible dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/a-quick-tour-of-the-design-manager",
        children: "gestionnaire de conception"
      }), ". Vous pouvez accéder à vos fonctions sans serveur et les modifier localement via la CLI."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour inclure des fonctions sans serveur dans un projet de développement, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "documentation relative aux éléments constitutifs de JavaScript"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Répertoires de fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Les fonctions sans serveur de HubSpot se trouvent dans un répertoire des fonctions. Ce dossier peut porter n'importe quel nom, mais doit contenir le suffixe ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".functions"
            }), ". Les fichiers stockés dans ce dossier ne sont pas accessibles publiquement."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Dans le dossier de fonctions, incluez votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " ainsi qu'un fichier ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#function-js",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: ".js"
              })
            }), " qui contient vos fonctions. Vous pouvez envisager d'ajouter un fichier ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#readme-md",
              children: "LISEZMOI"
            }), " pour indiquer à quoi servent les fonctions, comment elles fonctionnent et si vous devez suivre un processus de création pour en être l'auteur."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-03%20at%208.24.31%20AM.png",
            alt: "Dossier .functions sans serveur"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour éviter les modifications accidentelles à partir du gestionnaire de conception, vous pouvez verrouiller votre dossier. Pour verrouiller un dossier, rendez-vous sur le gestionnaire de conception, puis faites un clic droit avec votre souris sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "dossier"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verrouiller le dossier"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " les fonctions sans serveur incluses dans les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "projets de développement"
        }), " ont été mises à jour à partir de la version ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.2 de la plateforme"
        }), ", comprenant un nouveau schéma ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), ". Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/platform-versioning",
          children: "gestion des versions de la plateforme de projets"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })
      }), " est le fichier de configuration de la fonction sans serveur qui spécifie l'environnement d'exécution et toutes les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "variables d'environnement"
      }), " que vous prévoyez d'utiliser dans vos fonctions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce fichier gère également le routage de vos points de terminaison. Vous spécifiez les chemins d'accès aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#endpoints",
        children: "points de terminaison"
      }), " que vous souhaitez associer à vos fichiers ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#function-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "function.js"
        })
      }), " fichiers. Pour voir un exemple de ce à quoi doit ressembler votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), ", consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: "guide de référence des fonctions sans serveur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Function.js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Votre fonction sans serveur peut porter n'importe quel nom, à condition qu'il s'agisse d'un ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: ["fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".js"
        })]
      }), ". Pour que votre fonction sans serveur fonctionne, elle doit être associée à un point de terminaison ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/serverless-functions#serverless-json",
        children: ["défini dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })]
      }), ". Afin de résoudre les problèmes, il est recommandé de nommer le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".js"
      }), " de la même manière que le nom de votre point de terminaison dans votre fichier de configuration ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secrets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de l'authentification d'un appel effectué par une fonction sans serveur, vous devez utiliser des secrets pour stocker les clés API, les jetons d'accès aux applications privées et d'autres informations d'authentification, par sécurité. Cela permettra l'authentification sans exposer votre clé ou jeton d'accès."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer et gérer des secrets, vous pouvez utiliser les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#serverless-function-commands",
        children: "commandes de la CLI HubSpot"
      }), ", telles que :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add"
        }), " pour créer un nouveau secret."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets list"
        }), " pour afficher vos secrets actuellement disponibles par nom."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets update"
        }), " pour mettre à jour un secret existant."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois ajoutés par le biais de la CLI, ils peuvent être disponibles pour des fonctions en incluant un tableau de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), " contenant le nom du secret. Cela vous permet de stocker votre code de fonction dans le système de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "contrôle de version"
      }), " et d'utiliser des secrets sans les diffuser. Cependant, vous ne devez ", (0, _jsxRuntime.jsx)("u", {
        children: "jamais"
      }), " renvoyer la valeur de votre secret par le biais du journal de la console ou comme réponse, sous peine de diffuser le secret dans les journaux ou dans les interfaces qui exécutent votre fonction sans serveur."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " en raison de la mise en cache, il peut s'écouler environ une minute pour que les valeurs secrètes se mettent à jour. Si vous venez de mettre à jour un secret mais que vous voyez toujours l'ancienne valeur, vérifiez à nouveau au bout d'une minute environ."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Affichage des journaux des fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour faciliter le dépannage de vos fonctions sans serveur, l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "interface CLI"
      }), " dispose d'une ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: ["commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })]
      }), " qui vous permet d'afficher les journaux de votre fonction. Outre les réponses aux invocations de fonctions individuelles, l'heure d'exécution et le temps d'exécution, toute instruction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), " apparaîtra également dans les journaux des fonctions. N'utilisez pas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), " pour les secrets comme les clés d'API."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}