"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29913023567;
const slug = exports.slug = 'reference/cms/modules/default-modules';
const title = exports.title = 'Standard-Webmodule';
const name = exports.name = 'Standard-Webmodule';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über die Standardmodule, die HubSpot für das Erstellen von Website Seiten-, Blogbeitrags- und Blog-Listing-Vorlagen bereitstellt. Diese Module sind getrennt von den Standard-E-Mail-Modulen, die für E-Mail-Vorlagen gedacht sind.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "blogkommentare",
  "label": "Blogkommentare",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-e-mail-abonnement",
  "label": "Blog-E-Mail-Abonnement",
  "parent": null
}, {
  "depth": 0,
  "id": "blogbeitr%C3%A4ge",
  "label": "Blogbeiträge",
  "parent": null
}, {
  "depth": 0,
  "id": "blogbeitragsfilter",
  "label": "Blogbeitragsfilter",
  "parent": null
}, {
  "depth": 0,
  "id": "listing-des-blogbeitrags",
  "label": "Listing des Blogbeitrags",
  "parent": null
}, {
  "depth": 0,
  "id": "button",
  "label": "Button",
  "parent": null
}, {
  "depth": 0,
  "id": "call-to-action",
  "label": "Call-to-Action",
  "parent": null
}, {
  "depth": 0,
  "id": "daten-l%C3%B6schen",
  "label": "Daten löschen",
  "parent": null
}, {
  "depth": 0,
  "id": "abgrenzung",
  "label": "Abgrenzung",
  "parent": null
}, {
  "depth": 0,
  "id": "daten-herunterladen",
  "label": "Daten herunterladen",
  "parent": null
}, {
  "depth": 0,
  "id": "header",
  "label": "Header",
  "parent": null
}, {
  "depth": 0,
  "id": "horizontaler-abstandhalter",
  "label": "Horizontaler Abstandhalter",
  "parent": null
}, {
  "depth": 0,
  "id": "symbol",
  "label": "Symbol",
  "parent": null
}, {
  "depth": 0,
  "id": "bild",
  "label": "Bild",
  "parent": null
}, {
  "depth": 0,
  "id": "bildraster",
  "label": "Bildraster",
  "parent": null
}, {
  "depth": 0,
  "id": "bildergalerie",
  "label": "Bildergalerie",
  "parent": null
}, {
  "depth": 0,
  "id": "galerie-mit-bild-schiebereglern",
  "label": "Galerie mit Bild-Schiebereglern",
  "parent": null
}, {
  "depth": 0,
  "id": "sprachumschaltung",
  "label": "Sprachumschaltung",
  "parent": null
}, {
  "depth": 0,
  "id": "logo",
  "label": "Logo",
  "parent": null
}, {
  "depth": 0,
  "id": "logo-raster",
  "label": "Logo-Raster",
  "parent": null
}, {
  "depth": 0,
  "id": "meetings",
  "label": "Meetings",
  "parent": null
}, {
  "depth": 0,
  "id": "mitgliedschaftsanmeldungen-%C3%BCber-social-media",
  "label": "Mitgliedschaftsanmeldungen über Social Media",
  "parent": null
}, {
  "depth": 0,
  "id": "men%C3%BC",
  "label": "Menü",
  "parent": null
}, {
  "depth": 0,
  "id": "fu%C3%9Fzeile",
  "label": "Fußzeile",
  "parent": null
}, {
  "depth": 0,
  "id": "passwortaufforderung",
  "label": "Passwortaufforderung",
  "parent": null
}, {
  "depth": 0,
  "id": "zahlungen",
  "label": "Zahlungen",
  "parent": null
}, {
  "depth": 0,
  "id": "produkt",
  "label": "Produkt",
  "parent": null
}, {
  "depth": 0,
  "id": "angebots-download",
  "label": "Angebots-Download",
  "parent": null
}, {
  "depth": 0,
  "id": "angebotszahlung",
  "label": "Angebotszahlung",
  "parent": null
}, {
  "depth": 0,
  "id": "angebotssignatur",
  "label": "Angebotssignatur",
  "parent": null
}, {
  "depth": 0,
  "id": "artikel",
  "label": "Artikel",
  "parent": null
}, {
  "depth": 0,
  "id": "rich-text",
  "label": "Rich-Text",
  "parent": null
}, {
  "depth": 0,
  "id": "rss-listing",
  "label": "RSS-Listing",
  "parent": null
}, {
  "depth": 0,
  "id": "website-sucheingabe",
  "label": "Website-Sucheingabe",
  "parent": null
}, {
  "depth": 0,
  "id": "suchergebnisse",
  "label": "Suchergebnisse",
  "parent": null
}, {
  "depth": 0,
  "id": "bereichs%C3%BCberschrift",
  "label": "Bereichsüberschrift",
  "parent": null
}, {
  "depth": 0,
  "id": "einfaches-men%C3%BC",
  "label": "Einfaches Menü",
  "parent": null
}, {
  "depth": 0,
  "id": "folgen-in-social-media",
  "label": "Folgen in Social Media",
  "parent": null
}, {
  "depth": 0,
  "id": "social-media-sharing",
  "label": "Social-Media-Sharing",
  "parent": null
}, {
  "depth": 0,
  "id": "karte-mit-registerkarten",
  "label": "Karte mit Registerkarten",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-zeile-text",
  "label": "Eine Zeile Text",
  "parent": null
}, {
  "depth": 0,
  "id": "videoeinbettung-landingpage",
  "label": "Videoeinbettung (Landingpage)",
  "parent": null
}, {
  "depth": 0,
  "id": "whatsapp-link",
  "label": "WhatsApp-Link",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Standard-Webmodule"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die Standardmodule, die HubSpot für das Erstellen von Website Seiten-, Blogbeitrags- und Blog-Listing-Vorlagen bereitstellt. Sie finden auch Standardmodule, die zur Erstellung von Angebotsvorlagen verwendet werden können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei der lokalen Entwicklung können Sie ein bestimmtes Standardmodul mithilfe des Modulpfads ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch-files",
        children: "abrufen"
      }), " (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/linked_image.module"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um den Code eines Standardmoduls anzuzeigen, können Sie das Modul im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), "-Ordner des Design-Managers anzeigen und kopieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-default-modules.png",
        alt: "design-manager-default-modules"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Standard-Webmodule sind getrennt von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules",
          children: "Standard-E-Mail-Modulen"
        }), ", die für E-Mail-Vorlagen vorgesehen sind. Wenn Ihre E-Mail-Vorlagen eines der folgenden Standard-Webmodule enthalten, sollten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/default-email-modules",
          children: "es durch das entsprechende E-Mail-spezifische Modul ersetzen"
        }), ":"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "cta"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "linked_image"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "logo"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_filter"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_listing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "section_header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "social_sharing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blogkommentare"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_comments\" path=\"@hubspot/blog_comments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog-E-Mail-Abonnement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_subscribe\" path=\"@hubspot/blog_subscribe\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Blog, der für das Modul verwendet werden soll."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Titel für das Modul (umhüllt von einem h3-Tag)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Subscribe Here!\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "response_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachricht, die beim Einsenden des Formulars angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Thanks for subscribing!"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blogbeiträge"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie diese Modul zu Blog Listing-Seiten hinzu, um Blogbeitragsvorschauen mit dem Titel, Feature-Bild, Autor, Veröffentlichungsdatum und mehr von jedem Beitrag anzuzeigen, und zwar mit einer klickbaren Schaltfläche, die zum Beitrag navigiert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieses Standardmodul wurde mit React erstellt und Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react/tree/main/default-react-modules/src/components/modules/BlogPosts-V0",
        children: "seinen Quellcode bei GitHub anzeigen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Auf dieses Modul kann nicht über den Design-Manager zugegriffen werden. Die Modul kann mit HubL in Code-Vorlagen referenziert und im ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/blog/edit-a-blog-listing-page",
            children: "Editor für Blog Listing-Seiten"
          }), " hinzugefügt werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Dieses Modul ersetzt das vorherige ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "blog_listing"
          }), "-Modul, das veraltet war."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_posts\"\n  path=\"@hubspot/blog_posts\"\n  layout=\"grid\",\n  columns=4,\n  displayForEachListItem=[\n   \"image\",\n   \"title\",\n   \"authorName\",\n   \"tags\",\n   \"publishDate\",\n   \"description\",\n   \"button\"\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Inhalt Layout für jede Blogbeitragsvorschau.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "grid"
                }), " (Standard): richtet Beiträge in einem einfachen Raster aus."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "singleColumn"
                }), ": richtet Beiträge in einer einzigen Spalte mit Feature-Bildern in einer eigenen Zeile über dem Rest des Beitrag-Contents aus."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "sideBySide"
                }), ": richtet Beiträge in einer Spalte mit Feature-Bildern aus, die horizontal am Beitragsinhalt ausgerichtet sind."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bei Verwendung des ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), "-Layouts wird die Anzahl der Beiträge pro Zeile angegeben. Kann ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), " sein."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alternateImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sideBySide"
            }), "-Layout verwenden, legen Sie dies auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " fest, um das Feature-Bild abwechselnd auf der rechten und linken Seite der Beitragsvorschau auszurichten."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fullImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), "- oder das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "singleColumn"
            }), "-Layout verwenden, legen Sie dieses Feld auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " fest, um das Feature-Bild zum Hintergrund der Beitragsvorschau zu machen."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "displayForEachListItem"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Inhalt, der in jeder Blogbeitragsvorschau enthalten sein soll. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image"
                }), ": das Feature-Bild des Beitrags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": der Titel des Beitrags", (0, _jsxRuntime.jsx)("li", {}), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorImage"
                }), ": das Bild des Autors des Beitrags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorName"
                }), ": der Name des Autors des Beitrags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tags"
                }), ": die Blog-Tags des Beitrags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "publishDate"
                }), ": das Veröffentlichungsdatum des Beitrags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": die Metabeschreibung des Beitrags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button"
                }), ": die Schaltfläche „Mehr lesen“, die zum Blogbeitrag verlinkt."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ 'image', 'title', 'authorImage', 'authorName', 'tags', 'publishDate', 'description', 'button' ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "buttonText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Text, der auf der Schaltfläche „Mehr lesen“ angezeigt wird, falls enthalten."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Read more"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blogbeitragsfilter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_filter\" path=\"@hubspot/post_filter\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wählen Sie einen anzuzeigenden Blog aus. In der Standardeinstellung wird der aktuelle Blog verwendet, wenn er in einer Blog-Vorlage verwendet wird, oder der primäre Blog, wenn er an einer anderen Stelle verwendet wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Typ der Filterung der anzuzeigenden Links. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "tag"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "month"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "author"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tag"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "order_by"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sortierung für die Werte von Filter-Links. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "post_count"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "name"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_count"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine H3-Überschrift."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Posts by Tag\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl der anzuzeigenden Filterlinks. Lassen Sie diese Angabe leer, um alle anzuzeigen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "expand_link_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Text, der angezeigt werden soll, wenn mehr als der anzuzeigende ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            }), "-Wert verfügbar ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"See all\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listing des Blogbeitrags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Eine neue Version dieses Moduls wurde Ende März 2024 veröffentlicht. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-module-versioning#post-listing",
          children: "neue Standard-Beitrags-Listing-Modul"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_listing\" path=\"@hubspot/post_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wählen Sie einen anzuzeigenden Blog aus. In der Standardeinstellung wird der aktuelle Blog verwendet, wenn er in einer Blog-Vorlage verwendet wird, oder der primäre Blog, wenn er an einer anderen Stelle verwendet wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listing_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ des Listings für Ihre Beiträge. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "recent"
                }), ": das letzte."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_all_time"
                }), ": am beliebtesten insgesamt."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_year"
                }), ": am beliebtesten im vergangenen Jahr."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_six_months"
                }), ": am beliebtesten in den letzten sechs Monaten."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_month"
                }), ": am beliebtesten im letzten Monat."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine H3-Überschrift."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Recent Posts\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maximale Anzahl an anzuzeigenden Beiträgen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Button"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"button\" path=\"@hubspot/button\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL, zu der die Schaltfläche weiterleitet."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"url\": { \"type\": \"EXTERNAL\", \"href\": \"www.hubspot.com\", \"content_id\": null }, \"open_in_new_tab\": false, \"no_follow\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der auf der Schaltfläche angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Add a button link here\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_default_style"
                }), " (Boolesch)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_font"
                }), " (Schriftart)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_color"
                }), " (Farbe)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_hover_color"
                }), " (Farbe)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_hover_color"
                }), " (Farbe)"]
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_default_style\": false, \"button_font\": { \"color\": \"#FFFFFF\", \"size_unit\": \"px\" }, \"button_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"text_hover_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"button_hover_color\": { \"color\": \"#CCCCCC\", \"opacity\": 100 } }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Call-to-Action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"cta\" path=\"@hubspot/cta\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "guid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GUID des CTA."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Daten löschen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können dieses Modul zu Ihrer Seite mit den Abonnementeinstellungen hinzufügen, um zuzulassen, dass Kontakte eine Löschung ihrer Daten per E-Mail verlangen können. Diese Funktion ist gemäß bestimmten Datenschutzgesetzen erforderlich. Sobald ein Kontakt die Löschung seiner Daten beantragt, hat er 30 Minuten Zeit, dies in einer automatisch versendeten E-Mail zu bestätigen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Benutzer mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "Super-Admin"
      }), "-Berechtigungen erhalten zu diesen Anfragen eine Benachrichtigung per E-Mail. Erfahren Sie, wie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#download-data",
        children: "Sie Kontakten erlauben, einen Download ihrer Daten anzufordern"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"delete_data\" path=\"@hubspot/delete_data\", label=\"delete_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nachricht, die über der Schaltfläche angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Delete Data  Permanently delete your personal data stored by {{ site_settings.company_name}}. Personal data is information that can be used to identify you and doesn't include anonymized data.  You'll get a follow-up email where you'll need to verify your request.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Schaltflächentext."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data deletion"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Feldgruppe für Erfolgs- und Fehlerbenachrichtigungen. Einschließlich der folgenden Feldgruppen:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": Rich-Text-Inhalt der Erfolgsbenachrichtigung."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": Rich-Text-Inhalt der Fehlerbenachrichtigung."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": das Symbol zum Schließen der Warnung."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abgrenzung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf Seiten unterstützt. Es gibt eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-module-versioning#divider",
        children: "neue Version dieses Moduls"
      }), " für Accounts, die nach dem 25. August 2022 erstellt wurden. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/divider-module-v1",
        children: "Erfahren Sie mehr über diese Änderung"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"divider\" path=\"@hubspot/divider\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pixel-(px)-Höhe der Trennlinie."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Breite der Trennlinie als Prozentsatz (%)."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Farbe"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Farbe (Hex-Wert) und Deckkraft (Zahl) der Trennlinie."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"color\": \"#000000\", \"opacity\": 100 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Typ der Linie. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "solid"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dotted"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dashed"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Horizontale Ausrichtung der Trennlinie. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "right"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "center"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blendet die obere und untere Umrandung der Trennlinie ein/aus."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pixel-Wert (px) für die obere und untere Umrandung der Trennlinie. Option verfügbar, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Daten herunterladen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können dieses Modul zu Ihrer Seite mit den Abonnementeinstellungen hinzufügen, damit Kontakte eine Kopie ihrer Daten anfordern können. Diese Funktion ist gemäß bestimmten Datenschutzgesetzen erforderlich. Benutzer mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "Super-Admin"
      }), "-Berechtigungen erhalten zu diesen Anfragen eine Benachrichtigung per E-Mail. Erfahren Sie, wie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#delete-data",
        children: "Sie Kontakten erlauben, die Löschung ihrer Daten anzufordern"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download_data\" path=\"@hubspot/download_data\", label=\"download_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nachricht, die über der Schaltfläche angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Download Data  Download your personal data stored by {{ site_settings.company_name}}.  Personal data is information that can be used to identify you and doesn't include anonymized data.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Schaltflächentext."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Feldgruppe für Erfolgs- und Fehlerbenachrichtigungen. Einschließlich der folgenden Feldgruppen:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": Rich-Text-Inhalt der Erfolgsbenachrichtigung."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": Rich-Text-Inhalt der Fehlerbenachrichtigung"]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": das Symbol zum Schließen der Warnung."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formular##"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "H3-Überschrift"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "form"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Formularobjekt, einschließlich:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "form_id"
                }), ": ID für das zu verwendende Formular"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), ": was der Besucher nach dem Einsenden des Formulars sieht:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "inline"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "redirect"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "message"
                }), ": Inline-Nachricht, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "inline"
                }), " festgelegt ist"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_id"
                }), ": ID der Seite, zu der weitergeleitet werden soll, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                }), " festgelegt ist."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_url"
                }), ": URL, zu der weitergeleitet werden soll, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                }), " festgelegt ist"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"form_id\": \"\", \"response_type\": \"redirect\", \"message\": \"Thanks for submitting the form.\", \"redirect_id\": null, \"redirect_url\": \"http://www.google.com\" }"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-Mail, an die anstelle der Standard-E-Mail eine Formularbenachrichtigung gesendet wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_override_email_addresses"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-Mail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Kommagetrennte Liste von E-Mails, an die gesendet werden soll, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Senden einer Follow-up-E-Mail ist aktiviert."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_email_for_live_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Follow-up-E-Mail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID der Follow-up-E-Mail. Verfügbar, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sfdc_campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforce-Kampagne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wenn die Salesforce-Integration aktiv ist, die Kampagnen-ID."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Header"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"header\" path=\"@hubspot/header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text für die Überschrift."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wählen Sie eine Überschriftenebene aus. Auswahlmöglichkeiten umfassen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " bis ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Horizontaler Abstandhalter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blog-Listings, Blogbeiträgen und E-Mails unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"horizontal_spacer\" path=\"@hubspot/horizontal_spacer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Symbol"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt. Symbole können aus den Symbolsätzen von Font Awesome 5.0.10 und 5.14.0 übernommen werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"icon\" path=\"@hubspot/icon\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des Symbols."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"hubspot\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "purpose"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Barrierefreiheitsoption, die den Zweck des Symbols für Bildschirmleseprogramme kategorisiert. Verfügbare Werte sind:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "decorative:"
                }), " wird vom Bildschirmleseprogramm ignoriert."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "semantic:"
                }), " wird vom Bildschirmleseprogramm gelesen."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"decorative\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Barrierefreiheitsoption, die dem Symbol einen Titel zuweist."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ des Symbols. Kann einer der folgenden Werte sein: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "regular"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "light"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thin"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duotone"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"solid\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unicode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ZeichenfolgeDer Unicode-Wert des Symbols"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "f3b2"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bild"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"linked_image\" path=\"@hubspot/linked_image\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bild"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bildobjekt, das Folgendes enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": Bild-URL"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": ALT-Text für Bild"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": Lazy-Loading-Optionen umfassen:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "disabled"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "lazy"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": px-Wert"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": px-Wert"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"src\": \"https://static.hubspot.com/final/img/content/email-template-images/placeholder_200x200.png\", \"alt\": \"placeholder_200x200\", \"loading\": \"disabled\", \"width\": 200, \"height\": 200 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Optionaler Link für das Bild."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Öffnet Link in neuer Registerkarte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bildraster"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"image_grid\" path=\"@hubspot/image_grid\", label=\"image_grid.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Informationen für jede Folie enthält. Objekt enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": Bild-URL."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL, auf die die Folie verweisen soll"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hover_messages"
                }), ": Text, der das Bild überlagert, wenn Sie den Mauszeiger darüber bewegen."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Anzeigemodus der Bildergalerie. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": Standard-Schieberegler."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": Thumbnail-Navigator."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": Lightbox-Galerie."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Anzahl der Zeilen in der Lightbox-Galerie, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ermöglicht das Durchlaufen der Folien in einer Schleife mit „Weiter“/“Zurück“, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wechselt automatisch zur nächsten Folie, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zeitspanne (Sekunden) zwischen dem Wechsel zur nächsten Folie, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zeigt Navigationsschaltflächen an, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt die Höhe der Folien fest, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": feste Höhe."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": variable Höhe."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Folienübergangsstile, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": Folienübergang."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": Übergang mit Ausblenden."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Position der Folienbeschriftungen, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": hält Bildunterschriften immer unter dem Bild."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": legt Bildbeschriftungen über die Bilder."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bildergalerie"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"gallery\" path=\"@hubspot/gallery\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Informationen für jede Folie enthält. Objekt enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": Bild-URL.", (0, _jsxRuntime.jsx)("li", {}), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_caption"
                }), ": boolescher Wert zur Anzeige der Folienbeschriftung.", (0, _jsxRuntime.jsx)("li", {}), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": Rich-Text für Beschriftung."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL, auf die die Folie verlinken soll."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_in_new_tab"
                }), ": boolescher Wert zum Weiterleiten, wenn der Link in einer neuen Registerkarte geöffnet werden soll."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Anzeigemodus der Bildergalerie. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": Standard-Schieberegler."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": Thumbnail-Navigator."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": Lightbox-Galerie."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Anzahl der Zeilen in der Lightbox-Galerie, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ermöglicht das Durchlaufen der Folien in einer Schleife mit „Weiter“/“Zurück“, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wechselt automatisch zur nächsten Folie, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zeitspanne (Sekunden) zwischen dem Wechsel zur nächsten Folie, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zeigt Navigationsschaltflächen an, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt die Höhe der Folien fest, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": feste Höhe."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": variable Höhe."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Folienübergangsstile, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": Folienübergang."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": Übergang mit Ausblenden."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Position der Folienbeschriftungen, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": hält Bildunterschriften immer unter dem Bild."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": legt Bildbeschriftungen über die Bilder."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Galerie mit Bild-Schiebereglern"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_name\"\n    path=\"@hubspot/image_slider_gallery\",\n    slides=[\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>1</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        },\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>2</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        }\n    ]\n    slideshow_settings={\n        \"slides\": {\n        \"per_page\": 1,\n        \"sizing\": \"natural\",\n        \"aspect_ratio\": \"16/9\",\n        \"show_captions\": true,\n        \"caption_position\": \"below\"\n        },\n        \"movement\": {\n        \"transition\": \"slide\",\n        \"loop_slides\": false,\n        \"auto_advance\": false,\n        \"auto_advance_speed_seconds\": 5\n        },\n        \"navigation\": {\n        \"show_main_arrows\": true,\n        \"show_thumbnails\": false,\n        \"show_dots\": false\n        }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Feldgruppe mit den folgenden Feldern:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": Bild-URL."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": Rich-Text für Bildbeschriftung."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL, zu der die Folie verlinkt."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slideshow_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Feldgruppe für die Einstellungen des Bildschiebereglers. Enthält die folgenden Feldgruppen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slides"
                }), ": Folieneinstellungen, einschließlich:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "per_page"
                    }), ": Anzahl der Folien pro Seite."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "sizing"
                    }), ": Bildgröße"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "aspect_ratio"
                    }), ": Seitenverhältnis von Bildern.", (0, _jsxRuntime.jsx)("li", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "show_captions"
                    }), ": ein boolescher Wert"]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "movement"
                }), ": Einstellungen für das Gleitverhalten von Bildern."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "navigation"
                }), ": Einstellungen für die Bildnavigation."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Standardtextelemente des Moduls, einschließlich:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_caption"
                }), ": Bildbeschriftung."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_image_alt_text"
                }), ": Bildtext."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_aria_label"
                }), ": Standard-Aria-Label des Moduls."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "arial_label_thumbnails"
                }), ": Standard-Aria-Thumbnail des Moduls."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_nav_aria_label"
                }), ": Standard-Aria-Label der Modulnavigation."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "prev"
                }), ": vorheriger Folientext."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "next"
                }), ": Weiter mit Folientext."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "first"
                }), ": „Zu ersten Folie“-Text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slideX"
                }), ": „Zu X Folie“-Text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pageX"
                }), ": „Zu X Seite“-Text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "play"
                }), ": „Automatische Wiedergabe starten“-Text.", (0, _jsxRuntime.jsx)("li", {
                  children: (0, _jsxRuntime.jsx)(_components.code, {
                    children: "pause"
                  })
                }), ": „Automatische Wiedergabe anhalten“-Text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "carousel"
                }), ": Karusselltext.", (0, _jsxRuntime.jsx)("li", {}), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "select"
                }), ": Text zum Auswählen einer anzuzeigenden Folie."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": Folientext."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slidelabel"
                }), ": Folienlabel."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sprachumschaltung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Auf Seiten unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"language_switcher\" path=\"@hubspot/language_switcher\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Sprache des Textes in der Sprachumschaltung. Die Optionen umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pagelang"
                }), ": die Namen der Sprachen in der Sprache der Seite angezeigt werden, auf der sich die Sprachauswahl befindet."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "localized"
                }), ": die Namen jeder Sprache in ihrer eigenen Sprache angezeigt werden."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hybrid"
                }), ": eine Kombination aus beidem."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "localized"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo\" path=\"@hubspot/logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bild"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bildobjekt, das Folgendes enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_inherited_src"
                }), ": überschreibt das Standardlogo aus den Einstellungen"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": Bild-URL"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": ALT-Text für Logo"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_inherited_src\": false, \"src\": null, \"alt\": null }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Optionaler Link für das Logo. Wenn keine URL angegeben wird, verlinkt Ihr Logo zu dem ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Logo URL"
            }), ", das in Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors#edit-your-logo",
              children: "Markeneinstellungen"
            }), " festgelegt ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_tab"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Öffnet Link in neuer Registerkarte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blendet den Unternehmensnamen aus, wenn ein Bild nicht ausgewählt ist."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wählt eine Überschriftenebene aus, wenn kein Bild ausgewählt ist und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " ist. Die Auswahlmöglichkeiten umfassen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " bis ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logo-Raster"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein anpassbares Raster aus Containern zur einheitlichen Anzeige von Logos. Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo grid\"\n  path=\"@hubspot/logo_grid\"\n  group_logos=[\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company2_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo2.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"alt\": \"lorem-logo\",\n        \"height\": 40,\n        \"loading\": \"lazy\",\n        \"max_height\": 40,\n        \"max_width\": 175,\n        \"src\": \"https://www.example.com/Logos/color/logo3.png\",\n        \"width\": 175\n      }\n    }\n  ],\n  styles={\n    \"group_logo\": {\n      \"group_background\": {\n        \"aspect_ratio\": \"1/1\",\n        \"background_color\": {\n          \"color\": \"#8E7CC3\",\n          \"opacity\": 100\n        }\n      },\n      \"group_spacing\": {\n        \"padding\": {\n          \"padding\": {\n            \"bottom\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"left\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"right\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"top\": {\n              \"units\": \"px\",\n              \"value\": 75\n            }\n          }\n        }\n      },\n      \"max_logo_height\": 85\n    },\n    \"group_logo_grid\": {\n      \"column_count\": 3,\n      \"grid_gap\": 54\n    }\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Array, das ein Objekt für jedes Logo im Raster enthält."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "logo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["In ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            }), ", ein Objekt für jedes Logo im Raster. Jedes Logo kann die folgenden Parameter enthalten:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": der Dateipfad des Logos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": das ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/performance/lazy-loading",
                  children: "Lazy Loading"
                }), "-Verhalten des Logos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": der ALT-Text"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": die Höhe des Logos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": die Breite des Logos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_height"
                }), ": die maximale Höhe des Logos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_width"
                }), ": die maximale Breite des Logos."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "styles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Array, das die Stilfelder enthält, die sich auf das Rasterlayout, die Logocontainer und die Logo-Bilder auswirken. Dieses Array enthält die folgenden Stilgruppen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo"
                }), ": Stile, die sich auf die Logocontainer und Logo-Bilder auswirken. Enthält Folgendes:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_background"
                    }), ": Stile, die das Seitenverhältnis und die Hintergrundfarbe der Rastercontainer festlegen. Die Seitenverhältnisse umfassen: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "1/1"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "4/3"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "16/9"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_spacing"
                    }), ": Stile, die die innere Auffüllung des Logocontainers festlegen. Bei breiteren Logos kann ein höherer Auffüllungswert die Logobreite verringern."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "max_logo_height"
                    }), ": die maximale Höhe jedes Logos."]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo_grid"
                }), ": Stile, die das Rasterlayout festlegen, einschließlich:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "column_count"
                    }), ": die Anzahl der Spalten im Raster."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "grid_gap"
                    }), ": der Abstand zwischen den Rasterelementen."]
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Meetings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"meetings\" path=\"@hubspot/meetings\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meeting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Meeting"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wählen Sie einen Meetinglink aus."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mitgliedschaftsanmeldungen über Social Media"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieses Modul bietet die Möglichkeit für Google- und Facebook-Anmeldung bei Websites mit zugriffsbeschränkten Inhalten. Der Benutzer muss sich mit einem Konto anmelden, das mit der E-Mail-Adresse für den Kontakt im CRM verknüpft ist. Sie können auswählen, welche Social-Media-Anmeldungen aktiviert werden sollen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Wird auf Anmeldeseiten für die Mitgliedschaft unterstützt."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\",\n        clientid=\"\"\n        appid=\"\"\n        facebook_enabled=true\n        google_enabled=true\n        %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Facebook setzt voraus, dass Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/register",
          children: "Facebook-Entwicklerkonto"
        }), " haben und eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/create-an-app",
          children: "Facebook-App"
        }), " mit grundlegenden Einstellungen erstellt haben. Sobald Sie dies getan haben, übergeben Sie Ihre App-ID an das Modul."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Google erfordert ein Google-Konto und die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/identity/sign-in/web/sign-in#create_authorization_credentials",
          children: "Erstellung von Autorisierungsanmeldeinformationen"
        }), ". Sobald dies erfolgt ist, übergeben Sie die Client-ID Ihrer App an das Modul."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ihre Facebook-App-ID."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Aktiviert die Schaltfläche für die Facebook-Anmeldung. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            }), " ist erforderlich**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ihre Google-Client-ID."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Aktiviert die Schaltfläche für die Google-Anmeldung. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            }), " ist erforderlich**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menü"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Möchten Sie Ihr eigenes benutzerdefiniertes Menü erstellen? Probieren Sie unsere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "menu()-Funktion"
      }), " aus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"menu\" path=\"@hubspot/menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menü"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID des Menüs."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "root_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Erweiterter Menütyp. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "site_root"
                }), ": Es werden immer Top-Level-Seiten im Menü angezeigt."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "top_parent"
                }), ": Es werden Seiten im Menü abhängig vom angezeigten Abschnitt angezeigt."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "parent"
                }), ": Es werden Seiten im Menü abhängig von der angezeigten Seite angezeigt."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "breadcrumb"
                }), ": Breadcrumb-Pfadmenü (verwendet horizontalen Flow)."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_root"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_levels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt die Anzahl der untergeordneten Menüebenen an, auf die erweitert werden kann. Die Auswahlmöglichkeiten umfassen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " bis ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ausrichtung des Menüs. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flyouts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aktiviert die Funktion für untergeordnete Menüelemente."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fußzeile"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"page_footer\" path=\"@hubspot/page_footer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Passwortaufforderung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"password_prompt\" path=\"@hubspot/password_prompt\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submit_button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der auf der Einsende-Schaltfläche angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Submit\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "password_placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Platzhaltertext für das Passwortfeld."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Password\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bad_password_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nachricht zum Anzeigen, wenn ein Passwort falsch eingegeben wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Sorry, please try again. \""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zahlungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payments\" path=\"@hubspot/payments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um festzulegen, dass das Modul einen bestimmten Zahlungslink verwendet, geben Sie die ID des Zahlungslinks ein. Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#link#find-a-payment-link-s-id",
              children: "finden die ID"
            }), " in der URL, während Sie den Zahlungslink bearbeiten."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt fest, ob das Zahlungsformular in einer neuen Registerkarte oder in einer Überlagerung geöffnet werden soll. Verfügbare Werte sind:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "new_tab"
                }), ": öffnet das Zahlungsformular in einer neuen Registerkarte."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "overlay"
                }), ": öffnet das Zahlungsformular in einem gleitenden Overlay."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"new_tab\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Legt den Text für die Checkout-Schaltfläche fest."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Checkout\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, ob die Schaltfläche einen Zahlungslink von HubSpot oder einen externen Link verwendet. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "payment_link"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"payment_link\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_link"
            }), " festgelegt ist, wird mit dieser Option das Ziel des externen Links festgelegt. Folgende Linktypen werden unterstützt:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Produkt"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zeigt ein Produkt aus der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "Produktbibliothek"
      }), " des Accounts an. Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/product\",\n  product={\n    \"id\" : 2124070179\n  },\n  group_button={\n    \"button_text\" : \"Buy\",\n    \"override_product_button\" : true,\n    \"button_override\" : {\n      \"no_follow\" : false,\n      \"open_in_new_tab\" : false,\n      \"sponsored\" : false,\n      \"url\" : {\n        \"href\" : \"https://www.test.com\",\n        \"type\" : \"EXTERNAL\"\n      }\n  },\n  group_description={\n    \"description_override\" : \"Monthly gym membership with access to shared locker facilities.\",\n    \"override_product_description\" : true\n  },\n  group_name={\n    \"heading_level\" : \"h3\",\n    \"name_override\" : \"Gym membership\",\n    \"override_product_name\" : true\n  },\n  group_image={\n    \"image_override\" : {\n      \"alt\" : \"360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS\",\n      \"height\" : 360,\n      \"loading\" : \"lazy\",\n      \"src\" : \"https://2272014.fs1.hubspotusercontent-na1.net/hubfs/2272014/360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS.jpg\",\n      \"width\" : 640\n    },\n    \"override_product_image\" : true\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produkt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das anzuzeigende Produkt, angegeben durch die Produkt-ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig enthält das Modul eine Schaltfläche, die den Benutzer zur festgelegten URL des Produkts führt. Um das Ziel der Schaltfläche anzupassen, fügen Sie diese optionale Feldgruppe zusammen mit den folgenden Feldern hinzu:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_text"
                }), ": eine Zeichenfolge, die den Text der Schaltfläche festlegt."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_button"
                }), ": Legen Sie dies auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " fest, um die Standardeinstellungen für Schaltflächen-Links zu überschreiben. Fügen Sie dann ein ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), "-Objekt hinzu. Erfahren Sie unten mehr über ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["In der ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            }), "-Feldgruppe legt dies das URL-Verhalten der Schaltfläche fest, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "override_product_button"
            }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " festgelegt ist. Enthält die folgenden Felder:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "no_follow"
                }), ": boolesches Feld für das ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://ahrefs.com/blog/nofollow-links",
                  children: "no_follow"
                }), "-Verhalten des Links."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_link_in_new_tab"
                }), ": boolesches Feld für das Öffnungsverhalten des Links."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), ": ein Objekt, das das Ziel der Schaltfläche angibt."]
              })]
            }), "Im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), "-Feld können Sie den Typ des Ziels über das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), "-Feld festlegen, das die folgenden Inhaltstypen unterstützt:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                }), ": eine Standard-URL. Nehmen Sie die URL in ein ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "-Feld auf."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                }), ": eine HubSpot-Seite. Nehmen Sie die Seiten-ID in ein ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content_id"
                }), "-Feld auf."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "PAYMENT"
                }), ": ein Zahlungslink. Nehmen Sie den Zahlungslink in ein ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "-Feld auf."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EMAIL_ADDRESS"
                }), ": eine E-Mail-Adresse. Nehmen e die Adresse in ein ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "-Feld auf."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig zeigt das Modul den Produktnamen oben im Modul als h3 an. Um den Namen anzupassen, fügen Sie diese optionale Feldgruppe zusammen mit den folgenden Feldern hinzu:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "heading_level"
                }), ": die Größe der Überschrift. Kann ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h1"
                }), " - ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h6"
                }), " sein."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_name"
                }), ": auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " festlegen, um Text anstelle des Produktnamens anzugeben."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "name_override"
                }), ": die Zeichenfolge, die oben im Modul angezeigt werden soll."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig zeigt das Modul die festgelegte Beschreibung des Produkts an. Um die Beschreibung anzupassen, berücksichtigen Sie diese optionale Feldgruppe zusammen mit den folgenden Feldern:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_description"
                }), ": auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " festlegen, um die Produktbeschreibung anzupassen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description_override"
                }), ": eine Zeichenfolge, die Ihre neue Beschreibung enthält."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig zeigt das Modul das festgelegte Bild des Produkts an. Um dieses Bild anzupassen, fügen Sie diese optionale Feldergruppe mit den folgenden Feldern hinzu:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_image"
                }), ": auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " festlegen, um ein neues Bild anzugeben. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image_override"
                }), ": ein Objekt, das das neue Bild angibt, einschließlich der folgenden Eigenschaften:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "height"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "loading"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "width"
                    })
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Angebots-Download"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Angebotsvorlagen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download\" path=\"@hubspot/quote_download\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Text, der auf der Download-Schaltfläche angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "download_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fehlermeldung, die angezeigt wird, wenn der Download fehlschlägt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem downloading the quote. Please try again."
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Angebotszahlung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Angebotsvorlagen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payment\" path=\"@hubspot/quote_payment\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Überschrift, die über dem Zahlungsabschnitt einer Angebotsvorlage angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Überschrift, die für die Anzeige von ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            }), " verwendet wird. Die Werte umfassen: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h4"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Text für die Zahlungsschaltfläche."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pay now"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "needs_signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Schaltflächentext, wenn eine Unterschrift erforderlich ist."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment can't be made because the quote isn't fully signed."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nachricht, die angezeigt wird, wenn während des Checkouts ein Fehler auftritt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem setting up checkout. Please contact the person who sent you this quote."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_status_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nachricht, die angezeigt wird, wenn beim Zahlungsversuch ein Fehler auftritt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem loading the payment status for this quote. Please try refreshing the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "paid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Statusanzeige für eine erfolgreiche Zahlung."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Paid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_processing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Statusanzeige für die Zahlungsabwicklung."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment processing"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Angebotssignatur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Angebotsvorlagen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"signature\" path=\"@hubspot/quote_signature\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ein Angebot eine elektronische Unterschrift erfordert, sind die folgenden Felder innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "esignature"
      }), "-Objekts verfügbar:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pretext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erläuterungstext für die elektronische Unterschrift."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Before you sign this quote, an email must be sent to you to verify your identity. Find your profile below to request a verification email."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verify_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der auf der Verifizerungsschaltfläche angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verify to sign"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "failure"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hinweistext, der angezeigt wird, wenn die Unterschriftsinformationen nicht abgerufen werden können."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem retrieving the signature information. Please reload the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verification_sent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Statusanzeige, die angezeigt wird, wenn die Verifizierungsanfrage erfolgreich an den Unterzeichner des Angebots gesendet wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verification sent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der angezeigt wird, wenn das Angebot erfolgreich unterzeichnet wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn für ein Angebot eine schriftliche Unterschrift erforderlich ist, sind die folgenden Felder innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "print_signature"
      }), "-Objekts verfügbar:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der am Anfang des Unterschriftsabschnitts angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der neben dem Unterschriftsfeld angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der neben dem Datumsfeld angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "printed_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text, der neben dem Feld für den gedruckten Namen angezeigt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Printed name"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "countersignature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Objekt, das Inhalte für den Abschnitt mit der Gegenzeichnung enthält. Dieses Objekt kann die folgenden Felder enthalten:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "header"
                }), ": Text, der oben im Abschnitt für die Gegenzeichnung angezeigt wird."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "countersignature"
                }), ": Text, der neben dem Feld für die Gegenzeichnung angezeigt wird."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "date"
                }), ": Text, der neben dem Datums-Feld angezeigt wird."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "printed_name"
                }), ": Text, der neben dem Feld für den gedruckten Namen angezeigt wird."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Artikel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Angebotsvorlagen unterstützt. Dieses Modul enthält auch den Standardtext, der in benutzerdefinierten Angeboten verwendet wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"module_165350106057652\" path=\"@hubspot/line_items\", label=\"line_items.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Titel der Artikelspalte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Column description"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "use_additional_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt ein Kontrollkästchen an, um Benutzern die Auswahl zusätzlicher Artikelspalten von den Produkteigenschaften zu ermöglichen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "additional_product_properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Produkteigenschaft. Die Auswahlmöglichkeiten umfassen: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "price"
                }), ": Preis des Artikels. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "item_description"
                }), ": Beschreibung des Artikels. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "quantity"
                }), ": Anzahl der Artikel. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "amount"
                }), ": Gesamtkosten der Artikel. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hs_recurring_billing_start_date"
                }), ": Startdatum der Abrechnung für wiederkehrende Artikel. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "discount"
                }), ": Der Rabattbetrag gilt für den Artikel. "]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM-Objekteigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wählen Sie eine beliebige Produkteigenschaft aus, die als Spaltenüberschrift für den Artikel angezeigt werden soll."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rich-Text"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in allen Vorlagentypen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rich_text\" path=\"@hubspot/rich_text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HTML-Block."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ## Something Powerful  \\n  ### Tell The Reader More  \\n  The headline and subheader tells us what you're [offering](\\\"#\\\"), and the form header closes the deal. Over here you can explain why your offer is so great it's worth filling out a form for.  \\n  Remember:  \\n<ul>\\n<li>Bullets are great</li>\\n<li>For spelling out [benefits](\\\"#\\\") and</li>\\n<li>Turning visitors into leads.</li>\\n</ul>"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "RSS-Listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rss_listing\" path=\"@hubspot/rss_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine H3-Überschrift."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Test\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Typ des RSS-Feeds. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog"
                }), " - von HubSpot gehosteter Blog."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " - ein externer RSS-Feed."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["RSS-URL, die verwendet werden soll, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "external"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_group_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID des zu verwendenden Blogs, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "topic_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tag"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["(optional) ID des Tags, nach dem gefiltert werden soll, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_items"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maximale Anzahl an anzuzeigenden Beiträgen."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fügt das Feature-Bild ein."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt den Namen des Autors an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribution_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Text, der einem Beitrag einen Autor zuschreibt. Wird angezeigt, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"by\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt den Beitragszusammenfassungstext an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit_to_chars"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Beschränkt die Länge des Zusammenfassungstextes, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "200"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "click_through_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Text, der am Ende der Zusammenfassung eines Beitrags für den Clickthrough-Link angezeigt wird, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Read more\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt das Veröffentlichungsdatum an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Format für das Veröffentlichungsdatum, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ist. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                }), " (z. B.: 06/11/06 12:00 Uhr)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                }), " (z. B.: 6. Juni 2006 12:00:00 Uhr)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                }), " (z. B.: 6. Juni 2017 12:00:00 Uhr EDT)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MMMM d, yyyy 'at' h:mm a"
                }), " (z. B.: 6. Juni 2006 um 12:00 Uhr)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "h:mm a 'on' MMMM d, yyyy"
                }), " (z. B.: 12:00 Uhr am 6. Juni 2006)"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "short"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Text, der angibt, wann ein Beitrag veröffentlicht wurde, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ist."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"posted at\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Website-Sucheingabe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"./local-search_input\"\n  placeholder=\"Search\"\n  include_search_button=true\n  results={\n   \"use_custom_search_results_template\": \"true\",\n   \"path_id\": \"77977569400\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "field_label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text des Labels für die Sucheingabe"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Platzhaltertext für das Eingabefeld."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Search\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_search_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fügt eine Suchen-Schaltfläche ein."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Welche Inhaltstypen in die Suchergebnisse aufgenommen werden sollen. Objekt enthält die folgenden Schlüssel mit booleschen Werten:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "website_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "landing_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_posts"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "knowledge_articles"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"website_pages\": true, \"landing_pages\": false, \"blog_posts\": true, \"knowledge_articles\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "results"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Objekt, das Steuerelemente für die Verwendung einer benutzerdefinierten Seite für Suchergebnisse aktiviert. Umfasst die folgenden Eigenschaften:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "use_custom_search_results_template"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(boolesch):"
                }), " Bei Festlegung auf ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " können Benutzer eine benutzerdefinierte Suchergebnisseite auswählen. Der Standardwert ist ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "path_id"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(Zeichenfolge):"
                }), " die ID der Seite, die für Suchergebnisse verwendet wird. Die Seite, auf die verwiesen wird, muss das Suchergebnisse-Modul enthalten."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suchergebnisse"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird in Seiten, Blogbeiträgen und Blog-Listings unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_featured_images"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeigt Feature-Bilder für Artikel an."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bereichsüberschrift"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"section_header\" path=\"@hubspot/section_header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bereichs-Header-Content."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Überschriftenebene für den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            }), ". Die Auswahlmöglichkeiten umfassen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " bis ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subheader"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text für den Unterüberschriften-Absatz für den Abschnitt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A more subdued subheader\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einfaches Menü"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"simple_menu\" path=\"@hubspot/simple_menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "menu_tree"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Einfaches Menü"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "„Einfaches Menü“-Objekt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "orientation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ausrichtung des Menüs. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Folgen in Social Media"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie Links zu Ihren Social-Media-Profilen hinzu, indem Sie die Drag-&-Drop-Neuordnung im Content-Editor verwenden. Fügt automatisch Symbole basierend auf der Social-Media-URL ein, Symbole können jedoch überschrieben werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_follow\" path=\"@hubspot/social_follow\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Ziel-Link für die im Editor hinzugefügte Social-Media-Seite."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_window"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bei Festlegung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " wird der Link in derselben Browser-Registerkarte geöffnet."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " festlegen, um ein Podcast-Symbol anstelle des Standard- oder benutzerdefinierten Symbols anzuzeigen."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig wird der ALT-Text automatisch generiert. Wenn dies auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " festgelegt ist, können Sie den standardmäßigen ALT-Text mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria_label"
            }), "-Feldes mit einem benutzerdefinierten Wert überschreiben."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria-label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ist, legt dieses Feld das Symbol ALT-Text fest (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Follow us on Facebook"
            }), "). Dieser Text wird von Bildschirmleseprogrammen vorgelesen, um das Symbol für sehbehinderte Benutzer zu beschreiben."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig werden Symbole automatisch auf der Grundlage der Link-URL ausgefüllt. Wenn dies auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " festgelegt ist, können Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            }), "-Feld verwenden, um eines der anderen bereitgestellten Symbole auszuwählen (Font Awesome 6.4.2)."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " festgelegt ist und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            }), " ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", verwenden Sie dieses Feld, um ein benutzerdefiniertes Symbol aus dem angegebenen Satz anzugeben. Die verfügbaren Symbole stammen von Font Awesome 6.4.2."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Social-Media-Sharing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_sharing\" path=\"@hubspot/social_sharing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hinweis: Die Variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "social_link_url"
      }), " in der Standardspalte unten hat den gleichen Wert wie der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "link"
      }), "-Parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies ist der Ziel-Link, der zum leichteren Teilen in Social-Media-Netzwerken gekürzt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Folgendes enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolescher Wert zum Aktivieren von Social-Media-Element"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": benutzerdefinierte URL für die Social-Media-Sharer-URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.facebook.com/share.php?u={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "twitter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Folgendes enthält.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolescher Wert zum Aktivieren eines Social-Media-Elements"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": benutzerdefinierte URL für die Social-Media-Sharer-URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("code", {
              children: ["{ \"enabled\": false, \"custom_link_format\": \"", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://twitter.com/intent/tweet?original_referer=%5C%7B%5C%7B",
                children: "https://twitter.com/intent/tweet?original_referer=\\{\\{"
              }), " social_link_url }}&url={{ social_link_url }}&source=tweetbutton&text={{ social_page_title|urlencode }}\" }"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "linkedin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Folgendes enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolescher Wert zum Aktivieren von Social-Media-Element"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": benutzerdefinierte URL für die Social-Media-Sharer-URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.linkedin.com/shareArticle?mini=true&url={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pinterest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Folgendes enthält:", (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolescher Wert zum Aktivieren von Social-Media-Element", (0, _jsxRuntime.jsx)("li", {}), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": benutzerdefinierte URL für die Social-Media-Sharer-URL.", (0, _jsxRuntime.jsx)("li", {}), (0, _jsxRuntime.jsx)(_components.code, {
                  children: "pinterest_media"
                }), ": Bildobjekt einschließlich:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    }), ": Bild-URL."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    }), ": ALT-Text für das Bild."]
                  })]
                })]
              })
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://pinterest.com/pin/create/button/?url={{ social_link_url }}&media={{ pinterest_media }}\", \"pinterest_media\": { \"src\": \"\", \"alt\": null } }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Folgendes enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolescher Wert zum Aktivieren von Social-Media-Element"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": benutzerdefinierte URL für die Social-Media-Sharer-URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"mailto:?subject=Check out {{ social_link_url }} &body=Check out {{ social_link_url }}\" }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Karte mit Registerkarten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/tabbed_card\",\n  tabs=[\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 1.</p>\",\n    \"tab_label\" : \"Tab 1 label\"\n   },\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 2.</p>\",\n    \"tab_label\" : \"Tab 2 label\"\n   }\n  ],\n  fixed_height=false\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tabs"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feldgruppe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Feldgruppe, die das Registerkarten-Label und den Textinhalt jeder Registerkarte enthält. Enthält die folgenden Felder:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tab_label"
                }), ": das Label der Registerkarte."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "content"
                }), ": der Rich-Text-Inhalt der Registerkarte."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fixed_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Standardmäßig werden die Registerkarten auf eine feste Höhe festgelegt, damit die Containerhöhe beim Wechsel zwischen Registerkarten gleich bleibt. Sie können dies auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " festlegen, wenn die Höhe des Kartencontainers auf Registerkarten auf dem Inhalt der aktiven Registerkarte basieren soll."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Zeile Text"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"text\" path=\"@hubspot/text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fügen Sie Ihren Text zu diesem Parameter hinzu."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Video##"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird auf Seiten, in Blog-Listings und in Blogbeiträgen unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video\" path=\"@hubspot/video\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Typ des Videos. Zu den Optionen gehören:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "embed"
                }), ": Einbettungscode aus einer externen Quellen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hubspot_video"
                }), ": von HubSpot gehostetes Video."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Videoplayer"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Von HubSpot gehostetes Video. Wird verwendet, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Einbettung"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Unterstützt Einbettungstypen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "oembed"
                }), (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "video"
                    }), ": URL für Video."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "html"
                }), ": Einbettungscode für Video."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bild"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Überschreibt oEmbed-Thumbnail-Bild, wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            }), " gleich ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail_play_button_color"
            }), " enthält - Farbfeld."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"oembed_thumbnail_play_button_color\":\"#ffffff\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder_fields"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, das Folgendes enthält:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_title"
                }), ": Textfeld."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_description"
                }), ": Textfeld."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"placeholder_title\":\"No video selected\", \"placeholder_description\":\"Select a video type in the sidebar.\"}"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Videoeinbettung (Landingpage)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Auf Seiten unterstützt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video_embed_lp\" path=\"@hubspot/video_embed_lp\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL für Video. URLs von Vimeo und YouTube werden unterstützt."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://www.youtube.com/watch?v=X1Rr5BFO5rg\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt das Video auf volle Breite fest (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ") oder legt Breite und Höhe manuell fest (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ")."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maximale Breite (Pixel)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "800"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maximale Höhe (Pixel)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "450"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "WhatsApp-Link"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wird auf Seiten, in Blogbeiträgen und auf Blog-Listing-Seiten unterstützt. Es ist ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
        children: "verknüpfter WhatsApp-Kanal"
      }), " erforderlich."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"whatsapp_link\" path=\"@hubspot/whatsapp_link\", label=\"whatsapp_link\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wählen Sie aus den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
              children: "mit dem Account verknüpften WhatsApp-Kanälen"
            }), " aus."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optin_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/collect-consent-for-whatsapp-conversations#subscribe-a-contact-using-opt-in-keywords-in-a-whatsapp-thread",
              children: "Opt-in- und Opt-out-Text"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_display"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wählen Sie aus, ob die Schaltfläche Text, ein WhatsApp-Symbol oder beides anzeigt. Die Auswahlmöglichkeiten umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_and_icon"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_and_icon"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Position des Symbols auf der Schaltfläche. Die Auswahlmöglichkeiten umfassen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "right"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Text der Schaltfläche."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Chat on WhatsApp"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_icon_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Symboltext für Bildschirmleseprogramme."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WhatsApp Icon"
            })
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}