"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74397423399;
const slug = exports.slug = 'guides/crm/setup';
const title = exports.title = 'Developer projects setup guide (BETA)';
const name = exports.name = 'Developer projects setup guide (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to set up your local environment and a development sandbox to get started developing projects.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "install-node-and-the-cli",
  "label": "Install Node and the CLI",
  "parent": null
}, {
  "depth": 0,
  "id": "connect-your-hubspot-account",
  "label": "Connect your HubSpot account",
  "parent": null
}, {
  "depth": 0,
  "id": "create-and-use-development-sandboxes",
  "label": "Create and use development sandboxes",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-development-sandbox",
  "label": "Create a development sandbox",
  "parent": "create-and-use-development-sandboxes"
}, {
  "depth": 1,
  "id": "view-a-development-sandbox-in-hubspot",
  "label": "View a development sandbox in HubSpot",
  "parent": "create-and-use-development-sandboxes"
}, {
  "depth": 1,
  "id": "delete-a-development-sandbox",
  "label": "Delete a development sandbox",
  "parent": "create-and-use-development-sandboxes"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      em: "em",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Developer projects setup guide (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To develop HubSpot projects, you’ll first need to set up your local environment, including installing the HubSpot CLI and connecting it to your HubSpot account. You can also set up testing environments in your connected HubSpot account so that you can iterate in a siloed environment before deploying to production."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For private app development, you can set up ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-and-use-development-sandboxes",
        children: "development sandboxes"
      }), " in your production account. For public app development, you can set up ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#create-a-developer-test-account",
        children: "test accounts"
      }), " in your developer account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn how to connect your production account to the CLI then create a development sandbox within it. After setting up your local environment, you can proceed to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/create-a-project",
        children: "creating a project"
      }), ". To learn more about the CLI commands you'll be using, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/project-cli-commands",
        children: "project-specific CLI commands reference"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " for a complete guide to creating a UI extension, including these setup steps, check out the UI extensions quickstart guides:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/private-apps/quickstart",
            children: "UI extensions for private apps quickstart"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/public-apps/quickstart",
            children: "UI extensions for public apps quickstart"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Install Node and the CLI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To set up your local environment, you'll first need to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/download/package-manager",
          children: "Node.js"
        }), " which enables HubSpot’s local development tools. Versions 18 and higher are supported. It's recommended to use a package manager like ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://brew.sh/",
          children: "Homebrew"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/nvm-sh/nvm",
          children: "nvm"
        }), " to install Node."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install the HubSpot CLI globally by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli@latest"
        }), " in the terminal. This command will also update the CLI to the latest version if you’ve already installed it."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Connect your HubSpot account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the CLI installed, you can now connect it to the account you'll be uploading to. If you're developing a UI extension for a private app, you'll connect the CLI to the production account where the app will be installed. If you're developing a UI extension for a public app, you'll instead connect the CLI to the developer account that will host the app."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the terminal, navigate to the directory where you’ll be working using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cd"
        }), " command."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd path/to/working/directory\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you haven't connected an account to the CLI before, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs init"
        }), ". If you've connected an account to the CLI before but want to connect another account, you can run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs auth"
        }), " instead."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs init\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), " to open the personal access key page in your browser."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the browser, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "account"
        }), " you want to deploy to.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For private app development, select an existing ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "standard production"
            }), " HubSpot account or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "developer sandbox account"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For public app development, select your ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "developer account"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continue with this account"
        }), ". You’ll then be redirected to the personal access key page of the account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you haven’t generated a personal access key for the selected account yet, you’ll need to first generate the key by selecting the scopes you want to include for it.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For private app development, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Developer projects"
            }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Sandboxes"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Serverless functions"
            }), " checkboxes. Then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Generate personal access key"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For projects that include a public app, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Developer projects"
            }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Developer test accounts"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "File manager"
            }), " checkboxes. Then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Generate personal access key"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To copy/paste the access key, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Show"
        }), " to reveal the key, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " your account's personal access key must include the following minimum scopes to avoid errors during local development:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["For private app development, your production account personal access key must include the following scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.projects.write"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.app_functions.read"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.app_functions.write"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.sandboxes.read"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.sandboxes.write"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "sandboxes.read"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "sandboxes.write"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["For public app development, your developer account personal access key must include the following scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.projects.write"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.test_accounts.read"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "developer.test_accounts.write"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "files"
          }), "."]
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "When connecting the CLI to an existing sandbox account, you can ignore the sandbox-related scopes above. Personal access keys in sandbox accounts don't have access to those scopes because you cannot create a sandbox within a sandbox."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Paste the copied key into the terminal, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a unique name for the account, which can used when running CLI commands. Then, press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Set the account as your default by pressing ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The CLI will display a success message confirming that the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        })
      }), " file was created, or updated, and that your newly connected account was set as the default."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), " file stores your authorized accounts, and can be updated by running the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs auth"
          })
        }), " command. At any time, you can view all the currently connected accounts by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs accounts list"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When you run commands to interact with the account, such as building and deploying, HubSpot will use the account that’s set as the default in the file. When developing on multiple accounts, you can change the default account by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs accounts use accountName"
        }), ". You can also interact with a specific account by adding the following flag to the end of a command: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--account=accountName"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about developing private apps in sandbox accounts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create and use development sandboxes"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " development sandboxes are only available in accounts with a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Service Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After following the steps above to connect a production account to the CLI for private app development, you can create a development sandbox within it to setup a lightweight testing environment. This enables you to develop your apps and extensions in a siloed environment before deploying to a production account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before proceeding, review the following development sandbox limits:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A production account can have only two development sandboxes at a time."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CRM object definitions are synced from the production account to the development sandbox at the time of sandbox creation."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You cannot create a sandbox within another sandbox."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a development sandbox"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To set up a development sandbox account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Because development sandboxes are created within the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "defaultPortal"
          }), " in your ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hubspot.config.yml"
          }), " file, first confirm that your production account is connected and set as the default:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the terminal, run ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "hs accounts list"
              }), "."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "In your list of connected accounts, confirm that your production account is listed as the default account."
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hs-acounts-list-default.png",
                alt: "hs-acounts-list-default"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If your production account is not the default, run ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "hs accounts use"
              }), " and select your production account."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["After confirming your production account is the default, run ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs sandbox create"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You'll then be prompted to select a type of sandbox to create. Select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Development sandbox"
          }), ", then press ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "name"
          }), " for the sandbox account, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "All CRM object definitions will be copied from production to the development sandbox."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/import-and-export/import-objects",
            children: "import tool"
          }), " to import production object record data, or manually create sample data for testing."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The CLI will then begin the sandbox setup process. Once the sandbox is fully set up and synced, you'll see a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sandbox sync complete"
          }), " confirmation."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your development sandbox created, it will appear under the associated production account when running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs accounts list"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to set the development sandbox as your default account, run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs accounts use"
      }), ", then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sandbox"
      }), ". To deploy to your sandbox or production account, you can either run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs accounts use"
      }), " to set the default account, or manually select the account when uploading by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload --account=<name-of-account>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cli-connected-accounts-sandbox.png",
        alt: "cli-connected-accounts-sandbox"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Development sandboxes are designed to be early proof of concept environments. It is recommended to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#delete-a-development-sandbox",
        children: "delete"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-a-development-sandbox",
        children: "create"
      }), " a new Development Sandbox using the CLI. This ensures development sandboxes always have an exact mirror of the production account’s CRM object definitions when beginning new projects."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After setting up your development sandbox, learn how to quickly create a UI extension by jumping to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart#start-local-development",
        children: "section three of the UI extensions quickstart guide"
      }), ". Or, learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/create-a-project",
        children: "projects"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps",
        children: "private apps"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "UI extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/project-cli-commands#sandbox-commands",
          children: "CLI commands"
        }), " you can use to interact with both standard and development sandboxes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "View a development sandbox in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, all super admin users are synced to the development sandbox during creation. Super admins can give other users access by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/add-and-remove-users",
        children: "adding them as users to the development sandbox"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To access the development sandbox account in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM Development"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sandboxes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Development"
        }), " tab, where your new sandbox will be listed along with its name, create date, and the user who created it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To navigate to the sandbox account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "development sandbox name"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once a user has been granted access to a development sandbox, they can access it by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Profile picture"
      }), " in the top right of HubSpot, then clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Account selection menu"
      }), " and selecting the account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/portal-picker-hobbes.png",
        alt: "portal-picker-hobbes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete a development sandbox"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To delete a development sandbox using the CLI, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs sandbox delete"
        }), ", then follow the prompts."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To delete a development sandbox in HubSpot:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CRM Development"
            }), " in the main navigation bar."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar menu, select ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Sandboxes"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Development"
            }), " tab."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Hover over the development sandbox, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Delete"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/delete-development-sandbox.png",
        alt: "delete-development-sandbox"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}