"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817161651;
const slug = exports.slug = 'guides/api/app-management/oauth/tokens';
const title = exports.title = 'OAuth | Gérer les jetons';
const name = exports.name = 'EMEA FRANCE (fr) vNext Docs DP - OAuth Tokens_FR';
const metaDescription = exports.metaDescription = "Obtenez des jetons d'accès et de rafraîchissement OAuth garantissant votre capacité à effectuer des actions CRUD en toute sécurité avec les API de HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "obtenir-des-jetons-oauth-2.0-d%E2%80%99acc%C3%A8s-et-d-actualisation-",
  "label": "Obtenir des jetons OAuth 2.0 d’accès et d'actualisation :",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenir-des-informations-pour-les-jetons-oauth-2.0-d%E2%80%99acc%C3%A8s-et-d-actualisation-",
  "label": "Obtenir des informations pour les jetons OAuth 2.0 d’accès et d'actualisation :",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-le-jeton-d-actualisation-oauth-2.0-",
  "label": "Supprimer le jeton d'actualisation OAuth 2.0 :",
  "parent": null
}, {
  "depth": 0,
  "id": "utiliser-les-jetons-d-acc%C3%A8s-oauth-2.0-",
  "label": "Utiliser les jetons d'accès OAuth 2.0 :",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      strong: "strong",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 10,
        children: [(0, _jsxRuntime.jsx)(_components.h1, {
          children: "Gérer les jetons"
        }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir des jetons OAuth 2.0 d’accès et d'actualisation :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez le code que vous obtenez une fois qu'un utilisateur autorise votre application pour obtenir un jeton d'accès et un jeton d'actualisation. Le jeton d'accès sera utilisé pour authentifier les requêtes de votre application. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Les jetons d'accès expirent au bout de six heures"
      }), ". Ainsi, vous pouvez utiliser le point de terminaison du jeton d'actualisation pour obtenir un nouveau jeton d'accès lorsque le premier expire."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez un jeton d'actualisation précédemment obtenu pour générer un nouveau jeton d'accès. Les jetons d'accès expirent au bout de six heures. Si vous avez besoin d'un accès hors ligne aux données HubSpot, stockez le jeton d'actualisation que vous obtenez lors du lancement de votre intégration OAuth et utilisez-le pour générer un nouveau jeton d'accès après expiration du premier."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarque"
      }), " : La taile des jetons d'accès HubSpot va fluctuer au fur et à mesure que les informations codées changent. Nous recommandons de laisser jusqu'à 300 caractères pour prendre en compte tout changement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir des informations pour les jetons OAuth 2.0 d’accès et d'actualisation :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Obtenez les métadonnées concernant un jeton d’accès ou d'actualisation. Cela peut être utilisé pour obtenir l'adresse e-mail de l'utilisateur HubSpot pour lequel le jeton a été créé ainsi que le HubID auquel le jeton est associé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer le jeton d'actualisation OAuth 2.0 :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supprimez un jeton d'actualisation. Vous pouvez utiliser cela pour supprimer le jeton d'actualisation si un utilisateur désinstalle votre application."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarque :"
      }), " Cela ne supprimera que le jeton d'actualisation. Les jetons d'accès générés avec le jeton d'actualisation ne seront pas affectés. En outre, cela ne désinstallera pas une application d'un compte HubSpot et n'empêchera pas la synchronisation des données entre un compte et une application connectée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliser les jetons d'accès OAuth 2.0 :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "// Authorization: Bearer {token}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "curl --request GET \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--url 'https://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false' \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--header 'authorization: Bearer CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4'"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{contacts: .....}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans cet exemple, le jeton d'accès est :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarques :"
      }), " Si vous utilisez des jetons d'accès OAuth 2.0, vous ne devez ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "pas"
      }), " inclure ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapikey="
      }), " dans l'URL de la requête. L'en-tête d’autorisation est utilisé à la place de ce paramètre de demande."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}