"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42750989738;
const slug = exports.slug = 'guides/apps/overview';
const title = exports.title = 'Erstellen von Apps – Übersicht';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Developer tools and accounts';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über die EntwicklerTools und den Entwickler-Accounts von HubSpot. Verwenden Sie sie, um eine Integration zu erstellen, eine App im Marketplace zu listen oder einen Test-Account zu erstellen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "typen-von-apps",
  "label": "Typen von Apps",
  "parent": null
}, {
  "depth": 1,
  "id": "private-apps",
  "label": "Private Apps",
  "parent": "typen-von-apps"
}, {
  "depth": 2,
  "id": "private-apps-in-projekten-beta",
  "label": "Private Apps in Projekten (BETA)",
  "parent": "typen-von-apps"
}, {
  "depth": 1,
  "id": "%C3%B6ffentliche-apps",
  "label": "Öffentliche Apps",
  "parent": "typen-von-apps"
}, {
  "depth": 1,
  "id": "unterst%C3%BCtzte-erweiterungen-nach-app-typ",
  "label": "Unterstützte Erweiterungen nach App-Typ",
  "parent": "typen-von-apps"
}, {
  "depth": 0,
  "id": "account-typen-f%C3%BCr-die-app-entwicklung",
  "label": "Account-Typen für die App-Entwicklung",
  "parent": null
}, {
  "depth": 0,
  "id": "authentifizierung",
  "label": "Authentifizierung",
  "parent": null
}, {
  "depth": 1,
  "id": "api-schl%C3%BCssel-f%C3%BCr-entwickler-accounts",
  "label": "API-Schlüssel für Entwickler-Accounts",
  "parent": "authentifizierung"
}, {
  "depth": 0,
  "id": "delete-a-developer-account",
  "label": "Delete a developer account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      ul: "ul",
      li: "li",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erstellen von Apps – Übersicht"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit Apps können Sie API-Aufrufe für Ihren HubSpot-Account authentifizieren, Events mit Webhooks abonnieren und die HubSpot-UI erweitern, z. B. mit benutzerdefinierten Karten. HubSpot bietet je nach den Anforderungen Ihrer Integration verschiedene Typen von Apps und Erweiterungen an. Im Folgenden erfahren Sie mehr über die Typen von Apps und Erweiterungen, die Sie mit HubSpot erstellen können, wie Sie mit der Erstellung beginnen und wie Sie sie in HubSpot verwalten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Typen von Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Je nach Art der Integration, die Sie erstellen möchten, müssen Sie den richtigen App-Typ auswählen. Im Folgenden erfahren Sie mehr über die Typen von Apps, die Sie erstellen können, und die Funktionen, die sie unterstützen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für um Erstellen von Erweiterungen finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#supported-extensions-by-app-type",
        children: "in der folgenden Referenztabelle"
      }), " eine kurze Übersicht darüber, welche Erweiterungen mit welchen Typen von Apps erstellt werden können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Private Apps können für einen einzelnen HubSpot-Account erstellt werden und eignen sich am besten für einmalige Skripts oder Einzelfallerweiterungen. Sie können beispielsweise eine private App für Ihren HubSpot-Account erstellen, um:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein neues benutzerdefiniertes Objekt über die API zu erstellen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CRM-Datensätze von einer CSV-Datei zu importieren."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "API-Anfragen in benutzerdefinierten Automatisierungsaktionen und Chat-Bots zu authentifizieren."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "Webhook-Abonnements zu erstellen und zu bearbeiten"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Allgemeinen sind private Apps einfacher zu implementieren als öffentliche Apps. Private Apps authentifizieren sich über Zugriffstoken und können nicht im HubSpot App Marketplace gelistet werden. Erfahren Sie mehr darüber, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "wann Sie private Apps erstellen sollten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn diese Art von App Ihren Anforderungen entspricht, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "erstellen Sie eine private App in Ihrem HubSpot-Account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Private Apps in Projekten (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Private Apps, die in Projekten erstellt wurden, ermöglichen es Ihnen, UI-Erweiterungen in CRM-Datensätzen zu erstellen. Der Unterschied beim Erstellen einer privaten App mit dieser Methode besteht darin, dass Projekte nur das Erstellen von UI-Erweiterungen unterstützen, die private Apps außerhalb von Projekten nicht erstellen können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn diese Art von App Ihren Anforderungen entspricht, lesen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "Schnellanleitung zu Projekten"
      }), " durch, um mit der Erstellung einer privaten App und eines Projekts mithilfe des CLI zu beginnen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Öffentliche Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Öffentliche Apps können in mehreren Accounts installiert werden. Neben den Typen von Erweiterungen, die Sie mit einer privaten App erstellen können, unterstützen öffentliche Apps erweiterte Funktionen, z. B.:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Account-übergreifende Events mithilfe der Webhooks-API abonnieren."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Benutzerdefinierte Chronik-Events in CRM-Datensätzen mithilfe der Chronik-Events-API erstellen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Benutzerdefinierte App-Einstellungsseiten in HubSpot erstellen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Öffentliche Apps authentifizieren sich über OAuth und können im HubSpot App Marketplace gelistet werden. Erfahren Sie mehr darüber, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "wann Sie öffentliche Apps erstellen sollten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn dieser Ap-Typ Ihren Bedürfnissen entspricht, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "erstellen Sie eine öffentliche App in Ihrem App-Entwickler-Account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Unterstützte Erweiterungen nach App-Typ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "App-Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Unterstützte Erweiterungen"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/video-conferencing",
                  children: "Videokonferenz-Erweiterung"
                })
              })
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Öffentliche App"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/calling-sdk",
                  children: "Anruf-SDK"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "CRM-Karten"
                }), "*"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/timeline",
                  children: "Chronik-Events"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Videokonferenz-Erweiterung"
              })]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private App mit Projekten erstellt (BETA)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/crm/ui-extensions/create",
                  children: "UI-Erweiterungen"
                })
              })
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["* Die CRM-Karten, die Sie mit öffentlichen Apps erstellen können, unterscheiden sich von den benutzerdefinierten Karten, die Sie als ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "UI-Erweiterungen mit Projekten (BETA)"
        }), " erstellen können. UI-Erweiterungen bieten mehr erweiterte Funktionen und anpassbare Komponenten"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Account-Typen für die App-Entwicklung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "App-Entwickler- und Test-Accounts arbeiten zwar zusammen, dienen jedoch einem jeweils anderen Zweck."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App-Entwickler-Accounts dienen zum Erstellen und Listen von Apps im App Marketplace."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In Test-Accounts können Sie die APIs und Apps, die Sie erstellen, testen, ohne die Daten in einem echten HubSpot-Account zu beeinträchtigen. Dies ist getrennt von einem Sandbox-Account, den Sie innerhalb von Standard-HubSpot-Accounts erstellen können."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "Account-Typen von HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentifizierung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine benutzerdefinierte Integration mit einem einzelnen HubSpot-Account erstellen möchten, können Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private App"
      }), " erstellen und deren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "Zugriffstoken"
      }), " zum Authentifizieren von API-Aufrufen verwenden oder Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " mit einer öffentlichen App verwenden. Jede App, die für die Installation durch mehrere HubSpot-Accounts oder für ein Listing im App Marketplace entwickelt wurde, muss OAuth verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Seit dem 30. November 2022 sind HubSpot-API-Schlüssel veraltet und werden nicht mehr unterstützt. Die fortgesetzte Verwendung von HubSpot API-Schlüsseln stellt ein Sicherheitsrisiko für Ihren Account und Ihre Daten dar. Während dieser Phase bis zur endgültigen Einstellung kann HubSpot Ihren Schlüssel jederzeit deaktivieren."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Sie sollten sich stattdessen mit einem Zugriffstoken für private Apps oder über OAuth authentifizieren. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "diese Änderung"
        }), " und wie Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "API-Schlüssel-Integration migrieren"
        }), ", um stattdessen eine private App zu verwenden."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Webhooks abonnieren oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth für Ihre App"
      }), " einrichten möchten, sollten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "einen Entwickler-Account erstellen"
      }), ". Mit Entwickler-Accounts können Sie außerdem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "Ihre Apps im App Marketplace listen"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "einen Test-Account erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "API-Schlüssel für Entwickler-Accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Ihre App-Einstellungen über die API zu verwalten, können Sie einen Entwickler-API-Schlüssel verwenden. Dieser API-Schlüssel ist getrennt von Standard-API-Schlüsseln, die veraltet sind. App-Entwickler-Schlüssel können für das Verwalten von Abonnements für die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "Webhooks-API"
      }), " und das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "Erstellen oder Aktualisieren von Event-Typen für das Chronik-Events-Feature"
      }), " verwendet werden. Alle anderen API-Aufrufe müssen mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "Zugriffstoken einer privaten App"
      }), " oder OAuth vorgenommen werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So greifen Sie auf den API-Schlüssel Ihres App-Entwickler-Accounts zu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem App-Entwickler-Account in der oberen Navigationsleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot-API-Schlüssel abrufen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im Dialogfeld auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Schlüssel anzeigen"
        }), ". Der Schlüssel wird offengelegt, und Sie können dann neben dem Schlüssel auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kopieren"
        }), " klicken."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie können auch den vorherigen API-Schlüssel deaktivieren und einen neuen generieren, indem Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Schlüssel neu generieren"
        }), " klicken."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/show_dev_api_key.webp",
        alt: "show_dev_api_key"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können API-Entwickler-Accounts löschen, wenn sie keine Apps mit Installationen oder aktiven Marketplace-Listings enthalten. Wenn Ihr Account über Apps mit Installationen oder aktiven Marketplace-Listings verfügt und Sie Ihren Account löschen möchten, wenden Sie sich bitte an den Support."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie Ihren Account gelöscht haben, können Sie nicht mehr auf diesen Account zugreifen. Wenn Sie zwischen mehreren HubSpot-Accounts wechseln können, wird der gelöschte Account nicht mehr angezeigt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Klicken Sie in Ihrem HubSpot-API-Entwickler-Account oben rechts auf Ihren Account-Namen und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/account.jpeg",
        alt: "Account"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Account löschen"
      }), ". Wenn Ihr Account über Apps mit Installationen oder aktiven Marketplace-Listings verfügt, wird diese Schaltfläche deaktiviert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Account2.jpg",
        alt: "Account2"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Geben Sie im Dialogfeld Ihre Account-ID ein und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Entwickler-Account löschen"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Confirm2.jpg",
        alt: "Confirm2"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}