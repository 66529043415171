"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 171234735456;
const slug = exports.slug = 'reference/ui-components/standard-components/status-tag';
const title = exports.title = 'StatusTag | UI components';
const name = exports.name = 'StatusTag | UI components';
const metaDescription = exports.metaDescription = 'Learn about the StatusTag component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "StatusTag | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "StatusTag"
      }), " component renders a visual indicator to display the current status of an item. Status tags can be static or clickable for invoking functions with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onClick"
      }), " prop."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-component-statustag.png",
        alt: "ui-extension-component-statustag"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import {\n  Flex,\n  Heading,\n  StatusTag,\n  Text,\n  hubspot,\n} from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Flex direction=\"column\" gap=\"sm\">\n      <Heading>Account status</Heading>\n      <Flex direction=\"column\" gap=\"sm\">\n        <Text format={{ fontWeight: 'bold' }}>\n          Billing: <StatusTag variant=\"success\">Good standing</StatusTag>\n        </Text>\n        <Text format={{ fontWeight: 'bold' }}>\n          Outreach:{' '}\n          <StatusTag variant=\"warning\">> 2 weeks since last check-in</StatusTag>\n        </Text>\n        <Text format={{ fontWeight: 'bold' }}>\n          Support:{' '}\n          <StatusTag variant=\"danger\">1 escalated support ticket</StatusTag>\n        </Text>\n        <Text format={{ fontWeight: 'bold' }}>\n          Upgrades: <StatusTag>No upgrades</StatusTag>\n        </Text>\n        <Text format={{ fontWeight: 'bold' }}>\n          Referrals: <StatusTag variant=\"info\">1 recent referral</StatusTag>\n        </Text>\n      </Flex>\n    </Flex>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'default'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'info'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'danger'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'warning'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'success'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The color of the dot indicator. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants section"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hollow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the status tag's dot will be a ring instead of a filled-in circle."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "() => void;"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the status tag is clicked. It receives no arguments and its return value is ignored."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "showRemoveIcon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the status tag will include a small, clickable x icon to remove it. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onRemoveClick"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "() => void;"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the remove icon is clicked."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "variant"
        }), " prop, you can configure the indicator severity color:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'danger'"
            }), ": a red dot indicating a negative state, such as error or failure."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'default'"
            }), " (default): a grey dot indicating a neutral state."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'info'"
            }), ": a blue dot indicating a general or informative state."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'success'"
            }), ": a green dot indicating a positive state, such as confirming success or completion."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'warning'"
            }), ": a yellow dot indicating a cautionary state, for when something needs attention or is time-sensitive."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-component-statustag-variants.png",
        alt: "ui-extension-component-statustag-variants"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hollow"
        }), " prop, you can configure the dot to be a filled circle or a ring:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-component-statustag-hollow.png",
        alt: "ui-extension-component-statustag-hollow"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "showRemoveIcon"
        }), " prop, you can include a clickable icon to remove the status indicator."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-component-statustag-removeicon.png",
        alt: "ui-extension-component-statustag-removeicon"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'info'"
        }), " status tag to indicate that a customer is active."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'success'"
        }), " status tag to indicate that an item in a to-do list has been completed."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'warning'"
        }), " tag to indicate that a deal is expiring soon."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'danger'"
        }), " tag to indicate that an error happened when trying to sync a specific property in a table."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " make tag text concise and clear."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " ensure that tag variants are used consistently across the extension."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use tags in place of buttons or links."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " rely on color alone to communicate the tag's meaning. Ensure that tag text is clear and helpful."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/tag",
          children: "Tag"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/alert",
          children: "Alert"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/icon",
          children: "Icon"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/progress-bar",
          children: "ProgressBar"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}