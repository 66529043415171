"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694134;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/tutorial';
const title = exports.title = 'Erstellen eines Drag-&-Drop-Bereichs';
const name = exports.name = '[neu] Erste Schritte mit Drag-&-Drop-Bereichen';
const metaDescription = exports.metaDescription = 'Verwendung von Drag-&-Drop-Bereichen zur Unterstützung von Content-Autoren, damit diese schnell und einfach Layout, Stil und Inhalt ändern können';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-eine-neue-html-vorlage-erstellen",
  "label": "1. Eine neue HTML-Vorlage erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-einen-drag-%26-drop-bereich-erstellen",
  "label": "2. Einen Drag-&-Drop-Bereich erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-einen-abschnitt-mit-einem-modul-erstellen",
  "label": "3. Einen Abschnitt mit einem Modul erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-mehrere-module-einbeziehen",
  "label": "4. Mehrere Module einbeziehen",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-spalten-und-zeilen-einbeziehen",
  "label": "5. Spalten und Zeilen einbeziehen",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-generische-stile-f%C3%BCr-drag-%26-drop-komponenten-festlegen",
  "label": "6. Generische Stile für Drag-&-Drop-Komponenten festlegen",
  "parent": null
}, {
  "depth": 0,
  "id": "verwandte-tutorials",
  "label": "Verwandte Tutorials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erste Schritte mit Drag-&-Drop-Bereichen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Mit Drag-&-Drop-Bereichen"
      }), " können Entwickler Seitenabschnitte erstellen, die Layout-, Stil- und Inhaltsänderungen direkt in den Content-Editoren unterstützen. Dies ermöglicht es Entwicklern, einige Vorlagen mit globaler Struktur zu erstellen, die Content-Autoren bei der Erstellung einer Vielzahl von Seiten mit verschiedenen Zwecken und Layouts unterstützen, ohne dass sie jemals mit Code arbeiten müssen oder neue Vorlagen für kleine Layoutänderungen benötigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/a%20new%20approach.gif",
        alt: "Animation von Modulen, die auf eine Seite gezogen werden, wobei Spalten und Zeilen angepasst werden"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entwickler können leere Ablagezonen für Drag-&-Drop-Bereiche festlegen, in denen Content-Autoren ihren eigenen Seiteninhalt und ihr eigenes Layout erstellen. Alternativ können Entwickler Drag-&-Drop-Bereiche mit verschiedenen Modulen, Layouts, Stilen und Inhalten vorab füllen, die Content-Autoren als Ausgangspunkt für ihre Arbeit dienen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Tutorial lernen Sie, wie Sie einen einfachen Drag-&-Drop-Bereich einrichten. Weitere Ressourcen für Entwickler zu Drag-&-Drop-Bereichen finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
        children: "Boilerplate"
      }), " für Best Practices zur Implementierung sowie in der", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: " Referenzdokumentation für HubL-Tags für Drag-&-Drop-Bereiche"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Ein Content-Autor kann die Vorlage einer Seite gegen eine andere Vorlage desselben Typs austauschen, je nachdem, ob sie ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/tags/dnd-areas",
            children: "dnd_area"
          })
        }), "-Tags enthält."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Mit dem visuellen Layout-Editor erstellte Drag-&-Drop-Vorlagen können gegen andere Drag-&-Drop-Vorlagen oder Code-Vorlagen mit oder ohne ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags können nur gegen andere Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen ohne ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags können nur gegen andere Code-Vorlagen ohne ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Eine neue HTML-Vorlage erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erstellen Sie eine neue HTML-Vorlage, die den HubL- und HTML-Code enthält, aus dem Ihr Drag-&-Drop-Bereich dann besteht."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Drag-&-Drop-Bereiche basieren auf einem 12-spaltigen, responsiven Raster. Drag-&-Drop-Tags rendern Markup mit Klassennamen, die Spalten und Zeilen bezeichnen. Sie sind für das Hinzufügen eines Stylesheets verantwortlich, das auf diese Klassennamen abzielt. Ein Beispiel für Layout-Stile, die Sie implementieren können, finden Sie in: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
          children: "CMS-Theme-Boilerplate"
        }), ". Ihr Stylesheet kann mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-css",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ require_css() }}"
          })
        }), " zur Vorlage hinzugefügt werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Einen Drag-&-Drop-Bereich erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " ist der Container, der einen Teil der Webseite in Bezug auf Struktur, Design und Inhalt bearbeitbar macht. Der Text eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "-Tags liefert den Standardinhalt für den Drag-&-Drop-Bereich."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieses Tag allein erzeugt eine Ablagezone, in die Content-Autoren im Content-Editor Module ziehen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n\t<!-- generates an empty drag and drop area drop-section -->\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Einen Abschnitt mit einem Modul erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " ist eine Zeile der obersten Ebene und kann nur ein direktes untergeordnetes Element eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " sein. Abschnitte unterstützen eine Reihe von Parametern, die die Standardwerte für die stilistischen Steuerelemente bestimmen, die Content-Autoren für Abschnitte in den Content-Editoren haben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Legen Sie also ein Hintergrundbild fest, und legen Sie eine zentrierte vertikale Ausrichtung und eine maximale Breite von 1.000 Pixel für untergeordnete Inhalte fest. Eine vollständige Liste der unterstützten Parameter für die Drag-&-Drop-HubL-Tags finden Sie in der Referenzdokumentation für HubL-Tags für Drag-&-Drop-Bereiche."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um den Abschnitt mit Inhalt zu füllen, können wir das", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "-Tag verwenden, um ein Modul einzubinden, indem wir auf seinen Pfad verweisen. In diesem Beispiel verweisen wir auf ein standardmäßiges HubSpot-Modul, aber Sie können zusätzlich von Ihnen erstellte Module einbeziehen, indem Sie deren Pfad im Dateiverzeichnisbaum Ihres Design-Managers angeben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Standardwert für unser", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " festzulegen, können wir das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_attribute"
      }), "-Tag verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image = {\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n          },\n          max_width=1000,\n          vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text' %}\n            {% module_attribute \"html\"%}\n                This is your main headline.\n                Use this space to tell everyone about what you have to offer.\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jetzt können wir sehen, dass unser Drag-&-Drop-Bereich ein Modul enthält, das Content-Autoren im Content-Editor bearbeiten können. Wir können auch sehen, wie sich das Festlegen einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "max_width"
      }), " im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " auf unseren Inhalt auswirkt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_text.png",
        alt: "Screenshot des Seiten-Editors, bei dem die Modul-Symbolleiste angezeigt wird"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Mehrere Module einbeziehen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um mehr als ein Modul einzubinden, können wir mehrere ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "-Tags verwenden. Durch Festlegen der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), "-Parameter, die auf einem 12-Spalten-Raster beruhen, können wir ein Bildmodul neben unserem Rich-Text-Modul platzieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image={\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n        },\n        max_width=1000,\n        vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text', width=8, offset=0, label=\"Rich Text\" %}\n            {% module_attribute \"html\"%}\n            \t<h1>This is your main headline.</h1>\n            \t<p>Use this space to tell everyone about what you have to offer.</p>\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n        {% dnd_module path='@hubspot/linked_image',\n          width=4,\n          offset=8,\n          img={\n            \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n            \"alt\": \"Stock placeholder image\"\n          }\n        %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jetzt haben wir auch ein bearbeitbares Bildmodul sowie einen Ziehgriff, mit dem Content-Autoren die Breite und den Versatz der Module ändern können. Wir können auch sehen, wie durch Festlegen einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vertical_alignment"
      }), " im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " unser Inhalt vertikal zentriert wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_image.png",
        alt: "Screenshot des Seiten-Editors, der ein einem Abschnitt hinzugefügtes Bildmodul zeigt"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Spalten und Zeilen einbeziehen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um unseren Drag-&-Drop-Bereich komplexer zu gestalten, können wir Zeilen und Spalten mithilfe der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), "-Tags einbinden. Zeilen und Spalten verhalten sich ähnlich wie Abschnitte im Content-Editor, in dem Content-Autoren sie verschieben, klonen, löschen und formatieren können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n  {% dnd_section\n    background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n    },\n    max_width=1000,\n    vertical_alignment='MIDDLE'\n  %}\n    {% dnd_module path='@hubspot/linked_image',\n      width=6,\n      img={\n        \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n        \"alt\": \"Stock placeholder image\"\n      }\n    %}\n    {% end_dnd_module %}\n    {% dnd_column width=6, offset=6 %}\n      {% dnd_row\n        padding={\n            'bottom': 15\n        }\n      %}\n        {% dnd_module path='@hubspot/rich_text' %}\n          {% module_attribute \"html\"%}\n              <h1>This is your main headline.</h1>\n              <p>Use this space to tell everyone about what you have to offer.</p>\n          {% end_module_attribute %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n      {% dnd_row %}\n        {% dnd_module path='@hubspot/form' %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n    {% end_dnd_column %}\n  {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jetzt haben unsere Content-Autoren zusätzlich zu den Modulen und Abschnitten weitere stilistische und Layoutkontrolle über bestimmte Zeilen und Spalten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_area_full.png",
        alt: "Screenshot des Seiten-Editors mit einer zweispaltigen Zeile, einem Bildmodul links, einem Rich-Text-Modul und einem Formularmodul rechts."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Generische Stile für Drag-&-Drop-Komponenten festlegen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die verschiedenen Komponenten von Drag-&-Drop-Bereichen, Abschnitten, Spalten, Zeilen und Modulen haben alle Klassen, die mit CSS formatiert werden können. Die bearbeitbaren Stile und Optionen für diese Komponenten können mit CSS und nicht mit HubL festgelegt werden. Zum Beispiel kann die Standardauffüllung in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_sections"
      }), " mit CSS festgelegt werden:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".dnd-section {\n  padding: 80px 20px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die generischen CSS-Selektoren für die Komponenten von Drag-&-Drop-Bereichen sind ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-section"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-column"
      }), ",", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-row"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-module"
      }), ". Abgesehen von diesen Klassen mit dnd-Präfix basieren die tatsächlichen Rasterklassennamen im Markup auf Bootstrap-2-Namen. Das bedeutet nicht, dass Sie Bootstrap 2 mit Drag-&-Drop-Bereichen verwenden müssen. Wenn Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " zu Ihrer Seite hinzufügen, sind Sie für die Angabe der Stile verantwortlich, mit denen das Raster funktioniert. Ein Beispiel für Layout-Stile, die Sie implementieren können, finden Sie in: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/css/objects",
        children: "CMS-Theme-Boilerplate"
      }), ". Ihr Stylesheet kann mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), " zur Vorlage hinzugefügt werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Ressourcen für Entwickler zu Drag-&-Drop-Bereichen finden Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "der Boilerplate"
      }), " für Best Practices zur Implementierung sowie in der", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: " Referenzdokumentation für HubL-Tags für Drag-&-Drop-Bereiche"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwandte Tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Erste Schritte mit Designs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Erste Schritte mit benutzerdefinierten Modulen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Hinzufügen von Designfunktionen zu einer vorhandenen Website"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}