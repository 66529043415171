"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694135;
const slug = exports.slug = 'reference/cms/hubl/variables';
const title = exports.title = 'HubL変数';
const name = exports.name = 'HubL変数';
const metaDescription = exports.metaDescription = 'HubSpotのテンプレートでは、さまざまな事前定義変数をウェブサイトやEメールの要素のレンダリングに活用できます。このページでは、このような変数のリファレンスとしてリストを掲載しています。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%85%A8%E3%81%A6%E3%81%AE%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%A7%E5%88%A9%E7%94%A8%E5%8F%AF%E8%83%BD%E3%81%AA%E5%A4%89%E6%95%B0",
  "label": "全てのテンプレートで利用可能な変数",
  "parent": null
}, {
  "depth": 0,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "Eメールの変数",
  "parent": null
}, {
  "depth": 1,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E5%BF%85%E9%A0%88%E5%A4%89%E6%95%B0",
  "label": "Eメールテンプレートの必須変数",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E5%A4%89%E6%95%B0"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%83%A9%E3%83%BC%E3%81%A8%E3%83%95%E3%82%A9%E3%83%B3%E3%83%88%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "カラーとフォントの設定",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E5%A4%89%E6%95%B0"
}, {
  "depth": 1,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E5%86%85%E5%AE%B9",
  "label": "Eメールの内容",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E5%A4%89%E6%95%B0"
}, {
  "depth": 1,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "非公開コンテンツEメールの変数",
  "parent": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E5%A4%89%E6%95%B0"
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "ウェブサイトページの変数",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%BF%85%E9%A0%88%E3%81%AE%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E5%A4%89%E6%95%B0",
  "label": "必須のページテンプレート変数",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E5%A4%89%E6%95%B0"
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%A8%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "コンテンツとコンタクトの変数",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E5%A4%89%E6%95%B0"
}, {
  "depth": 0,
  "id": "http%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "HTTPリクエストの変数",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "ブログの変数",
  "parent": null
}, {
  "depth": 0,
  "id": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "CRMオブジェクトによる動的ページ",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "HubDBの変数",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC%E3%83%8E%E3%83%BC%E3%83%89%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "メニューノードの変数",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E5%86%85%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%BC%E3%81%8A%E3%82%88%E3%81%B3%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E5%A4%89%E6%95%B0",
  "label": "アプリ内エディターおよびプレビュー変数",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubL変数"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのテンプレートでは、さまざまな事前定義変数をウェブサイトやEメールの要素のレンダリングに活用できます。このページでは、このような変数のリファレンスとしてリストを掲載しています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "HubLテンプレート"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "モジュール"
      }), "に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "独自の変数を作成する方法の詳細をご覧ください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このページに記載されている変数のほとんどは任意指定ですが、テンプレートからEメールやページを作成する上で必須の変数もいくつかあります。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["以下の変数は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables-macros-syntax",
          children: "変数とマクロのページ"
        }), "に記載されているように、変数を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "}}"
        }), "区切り文字で囲むことで、個別に使用できます。これらの変数は、HubLテンプレート言語の他の部分（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/loops",
          children: "ループ"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/filters",
          children: "フィルター"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions",
          children: "関数"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "タグ"
        }), "など）でも使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "全てのテンプレートで利用可能な変数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のHubL変数をEメール、ページ、またはブログのテンプレートで使用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらの変数からの出力に関する追加情報を見るには、以下に示すように", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters#pprint",
        children: "pprintフィルター"
      }), "を使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "入力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set variable = content %}\n{{variable|pprint}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(ContentMeta: { \"canonicalUrl\" : \"https://www.website.com/url-slug\",\n\"deferDynamicValues\" : false, \"featuredImageAltText\" : \"\", \"featuredImageHeight\"\n: 0, \"featuredImageUrl\" : \"\", \"featuredImageWidth\" : 0, \"htmlTitle\" : \"\",\n\"linkRelCanonicalUrl\" : \"https://www.website.com/url-slug\", \"metaDescription\" :\n\"\", \"metaKeywords\" : \"\", \"name\" : \"\", \"unescapedHtmlTitle\" : \"\",\n\"unescapedMetaDescription\" : null })\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この変数は、登録済みコンタクトの会社パーソナライゼーションプロパティーを格納するディクショナリー（辞書型）です。このディクショナリーのプロパティーにアクセスするには、ピリオドとプロパティー名を追加します。例えば", (0, _jsxRuntime.jsx)(_components.code, {
              children: "account.name"
            }), "の場合、コンタクトの会社名が出力されます。この変数を使用すると、ページキャッシュが無効になります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "［ウェブサイト］>［ページ］>［ブランディング］>［ロゴのリンク］の会社のドメインを出力します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この変数は、登録済みコンタクトのパーソナライゼーションプロパティーを格納するディクショナリーです。このディクショナリーのプロパティーにアクセスするには、ピリオドとプロパティー名を追加します。例えば", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.firstname"
            }), "の場合、コンタクトの名（ファーストネーム）が出力されます。この変数を使用すると、ページキャッシュが無効になります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この変数は、特定のコンテンツ（Eメール、ページ、記事など）に関連するさまざまなプロパティーを格納するディクショナリーです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページ、記事、またはEメールのウェブ ページ バージョンのURL全体を出力します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.archived"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この変数は、ページまたはEメールがユーザーによってアーカイブ済みの場合にtrueと評価されます"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツ作成者のEメールアドレス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツ作成者の名と姓。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_username"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツ作成者のHubSpotユーザー名。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このページまたはEメールに関連付けられているマーケティングキャンペーンのGUID。この固有IDは、キャンペーンツール内の特定のキャンペーンのURLで確認できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このページ、この記事、またはこのEメールに関連付けられているマーケティングキャンペーンの名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.created"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "日時"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツが最初に作成された時刻を示す日時オブジェクト（UTC時刻）。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#datetimeformat",
              children: "datetimeフィルター"
            }), "を使用して、この変数の書式を設定できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページのメタディスクリプションを取得するときには、変数", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            }), "を使用することをお勧めします。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "記事、Eメール、またはページの名前。ページとEメールの場合は内部コンテンツ名が出力され、記事の場合は記事のタイトルが出力されます。ブログ記事の場合、表示される記事のタイトルです。その他のコンテンツタイプの場合、一般に内部名です。この変数にはラッパーが含まれているので、ブログ記事に組み込まれている場合にはユーザーインターフェイス上で編集できます。ラッパーなしでコンテンツ名を出力するには、page_meta.nameを使用します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "日時"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツが公開された時刻を表す日時オブジェクト（UTC時刻）。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#format-datetime",
              children: "format_datetimeフィルター"
            }), "を使用して、この変数の書式を設定できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/change-your-language-and-region-settings",
              children: "既定の設定"
            }), "で定義されたタイムゾーンを使用してコンテンツが公開された日時を表す文字列。またこの変数は、［設定］>［ウェブサイト］>［ブログ］>［日付形式］にある言語と日付形式の設定の影響も受けます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["デザインマネージャーでのテンプレートのファイルパス（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom/page/web_page_basic/my_template.html"
            }), "）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページ、記事、またはEメール（ウェブ ページ バージョン）のURLスラッグ。これは、ドメインの後に続く値です。例えば", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/about-us"
            }), "の中のスラッグは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "about-us"
            }), "です。URL全体を指定するには、代わりに", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            }), "を使用してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.updated"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "日時"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ユーザーが最後にコンテンツを更新した日時を表す日時オブジェクト（UTC時刻）。datetimeフィルターを使用して、この変数の書式を設定できます。最初の公開時の**", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date"
            }), "とは等しくありません。**公開後に記事が更新されたかどうかを検証するには、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#between-times",
              children: (0, _jsxRuntime.jsx)("code", {
                children: "|between_times"
              })
            }), "フィルターを使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページ、記事、またはEメールの固有IDを出力します。このIDはエディターのURLで確認できます。この変数はcontent.idのエイリアスとして使用できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "favicon_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ファビコンのソースURLを出力します。この画像は、［設定］>［ウェブサイト］>［ページ］>［ブランディング］で設定されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hub_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ご使用のHubSpotアカウントのポータルID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_analytics_tracking_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アナリティクス トラッキング コードが含まれます。このタグは必ずしも必要ではありません。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            }), "が既にトラッキングコードをレンダリングしているからです。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_dt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "日時"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["レポート設定で定義されているタイムゾーンでの現在時刻を表す日時オブジェクト。この変数を使用すると、現在の時間を返す必要があるため、ページキャッシュが無効になります。", (0, _jsxRuntime.jsx)(_components.em, {
              children: "ページのパフォーマンスが劣化する可能性があります。キャッシュ可能な方法で現在の日時を取得するには、代わりにJavaScriptを使用してください。"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_time_zone"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのレポート設定で指定されているタイムゾーン。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.canonical_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このページにアクセスするための正式なURL。通常はクエリー文字列パラメーターが含まれません。これを", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"canonical\""
            }), "タグに使用してください。HubSpotによりURLが自動的に正規化されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページのタイトル。この変数をHTMLテンプレートの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), "タグで使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページのメタディスクリプション。この変数をHTMLテンプレートの\"description\" ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<meta>"
            }), "タグで使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            }), "のエイリアス。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portal_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "hub_idのエイリアス"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リクエストされたコンタクトに関するデータを含むディクショナリー。この変数を使用すると、ページキャッシュが無効になります。Eメールテンプレートでは使用できません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "site_settingsディクショナリーには、カラーやフォントなどのさまざまな設定が含まれます（下記を参照）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "year"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在の年を出力します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eメールの変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールテンプレートで使用できるHubL変数を以下に示します。いくつかの変数は他のコンテンツ タイプでも使用可能です。例えば", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#color-and-font-settings",
        children: "カラーとフォントの設定変数"
      }), "を使用して、Eメールの色やフォントの設定を他の場所で参照することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメールテンプレートの必須変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), "に準拠するには、HubSpot経由で送信される全てのEメールに、特定の会社情報とオプトアウト情報を掲載する必要があります。この情報は以下の変数によって設定され、これらの変数を含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["その他の任意指定のEメール変数は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#email-variables",
        children: "このページのさらに下の部分"
      }), "に記載されています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（［設定］>［マーケティング］>［Eメール］>［設定］>［フッター］で設定されている）会社の市区町村を出力します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（［設定］>［マーケティング］>［Eメール］>［設定］>［フッター］で設定されている）会社の名前を出力します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（［設定］>［マーケティング］>［Eメール］>［設定］>［フッター］で設定されている）会社の都道府県を出力します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（［設定］>［マーケティング］>［Eメール］>［設定］>［フッター］で設定されている）会社の住所を出力します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者が配信設定を管理したりEメール配信を解除したりできるページのURLを出力します。<a>のhref属性で、この変数を使用してください。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カラーとフォントの設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings",
        children: "マーケティングEメールの設定"
      }), "には、Eメールに出力できる基本的な色とフォントの制御設定がいくつかあります。なお、これらの変数の一部はページテンプレートやブログテンプレートでも使用可能です（その場合は変数の前に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings."
      }), "を追加します）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["背景色を16進値で設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.background_color"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["本文の境界線の色を16進値で設定します。このオプションは、設定の［", (0, _jsxRuntime.jsx)(_components.em, {
              children: "境界線の色のオプション"
            }), "］ドロップダウンメニューで［Eメール境界線の色を手動で設定］を選択すると使用可能になります。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_border_color"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color_choice"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この変数の値は、設定の［境界線の色のオプション］ドロップダウンメニューで制御されます。値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_AUTOMATIC"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_MANUAL"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_NONE"
            }), "です。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_border_color_choice"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["本文の色を16進値で設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_color"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["基本アクセント色を16進値で設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_accent_color"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列挙"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["基本フォントを文字列として設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["基本フォント色を16進値で設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_color"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["基本フォントサイズを", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "値として設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_size"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["基本フォントサイズの数値（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "を除く）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["第2基本アクセント色を16進値として設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_accent_color"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列挙"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["第2基本フォントを文字列として設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["第2基本フォント色を16進値として設定します。ページ／ブログテンプレートでこの値を出力するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font_color"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["第2基本フォントサイズの数値（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "を除く）。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメールの内容"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.create_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eメールのウェブ ページ バージョンがある場合は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "になります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.email_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールの本文。この変数によってリッチ テキスト モジュールがレンダリングされます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.emailbody_plaintext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プレーンテキストのEメール本文を変更します（任意指定）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.from_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール送信者の名前"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.reply_to"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールの返信用アドレス"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールの件名"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "Eメール本文のパディング設定"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "Eメール本文の幅の設定"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_2"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "CAN-SPAMフッター設定"
            }), "の住所2を出力します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.office_location_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "CAN-SPAMフッター設定"
            }), "のオフィス所在地名を出力します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_confirmation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信設定確認ページのURLを出力します。このURLは送信時に動的に生成されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールに指定されているEメールタイプの名前を出力します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信停止ページにリンクされた\"unsubscribe\"（配信停止）という語を含むアンカータグを生成します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link_all"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信設定を管理するためのリンクではなく、全てのEメールコミュニケーションの配信を解除するリンクをレンダリングします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信停止リンクとヘルプテキストを含む配信停止セクションを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールのウェブページバージョンを表示するリンク（ヘルプテキスト付き）を生成します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールのウェブページバージョンを表示するリンクを生成します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "非公開コンテンツEメールの変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages#customize-your-membership-registration-page-and-email-templates",
        children: "非公開コンテンツEメールテンプレート"
      }), "に使用されるEメールテンプレートの内部でのみ利用可能な変数を、以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "非公開コンテンツ設定"
            }), "で設定されている会社名。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開コンテンツウェブサイトのドメイン。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_src"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "ブランドキット設定"
            }), "で設定されている、表示するロゴのURL。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_alt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "ブランドキット設定"
            }), "で設定されている、ロゴの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), "属性。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開コンテンツウェブサイトの登録ページへのリンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_saved_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "パスワード保存ページへのリンク。リンクは、訪問者がアクセスできるランダムな制限付きページに訪問者をリダイレクトします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_reset_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開コンテンツウェブサイトのパスワード リセット ページへのリンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_passwordless_auth_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "パスワードレスのメンバー ログイン ページへのリンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_verify_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "登録確認ページへのリンク。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_website_admin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "非公開コンテンツ設定"
            }), "で設定されているウェブサイト管理者の名前。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイトページの変数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サイトページ、ランディングページ、システムページ、ブログに使用できる変数を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "必須のページテンプレート変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コードファイルを編集可能なページまたはブログテンプレートとして公開するには、以下の変数を含める必要があります。これらの変数を含まないHTMLファイルを公開して別のテンプレート内で使用するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "テンプレートアノテーション"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isAvailableForNewContent: false"
      }), "を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#hubspot-tracking-code",
              children: "HubSpotトラッキングコード"
            }), "と、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://app.hubspot.com/l/settings/website/pages/all-domains/page-templates",
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "コンテンツ設定"
              })
            }), "のフッターHTMLに含まれるその他のコード、または特定のページのオプションをレンダリングします。このタグを本文終了タグの直前に挿入する必要があります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_header_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#included-cms-files",
              children: "jQuery、layout.css"
            }), "、添付されるスタイルシート、meta viewportタグ、Googleアナリティクスのトラッキングコード、その他のメタ情報、およびドメイン／テンプレート／ページレベルでheadタグに付加されるコードを追加します。この変数をHTMLテンプレートの<head>に追加する必要があります。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コンテンツとコンタクトの変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "builtin_body_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この変数は、テンプレートを使って作成されたコンテンツのマークアップ（コンテンツのタイプ、コンテンツ名など）を区別するのに役立つクラスを動的に出力します。これにより、さまざまなタイプのコンテンツや特定のページのスタイルを容易に設定できます。この変数は、コードテンプレートの本文タグのclass属性で使用します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.is_logged_in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この変数は、ウェブサイト上のアクセス管理付きコンテンツにリクエスト元コンタクトがログインしているかどうかを定義します（詳細については", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
              children: "オーディエンスアクセスの制御に関するドキュメント"
            }), "を参照）。この変数は、リクエスト元コンタクトがログイン済みの場合は値trueを返し、ログアウトしている場合はfalseを返します。コンタクトをURL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.yourdomain.com/_hcms/mem/logout"
            }), "に誘導することで、ログアウトしてもらうことができます。この変数を使用すると、ページキャッシュが無効になります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.list_memberships"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この変数は、コンタクトがメンバーとして含まれるリストを表すIDディクショナリーを返します。この変数を使用すると、ページキャッシュが無効になります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この変数は、ページの言語設定に関する情報のディクショナリーを返します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.languageTag }}"
            }), "はページの言語ID（「en」や「es」など）を返します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.textDirection.value }}"
            }), "はページの言語のテキストの方向（「rtl」または「ltr」）を返します。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTTPリクエストの変数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HTTPページリクエストに関する情報を出力する変数を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.cookies"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cookie名とCookie値のマッピングを含むディクショナリー。この変数を使用すると、ページキャッシュが無効になります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このページへのアクセスに使用されるドメイン"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.full_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このページへのアクセスに使用されるURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URLのパス要素"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path_and_query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URLのパスとクエリーの要素"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URLのクエリー文字列の要素。request.query_dictにより、自動的にクエリー文字列がキーと値のペアに分割されます。ほとんどの場合、未加工のクエリーに対してはこれを使用することを推奨します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query_dict"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "名前->値のディクショナリーに変換されたクエリー文字列。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.referrer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HTTPリファラー（現在のページにリンクされているページのURL）。この変数を使用すると、ページキャッシュが無効になります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.remote_ip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "訪問者のIPアドレス。この変数を使用すると、ページキャッシュが無効になります。_"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.scheme"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リクエストのプロトコル（httpまたはhttps）"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_engine"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このページを見つけるのに使用された検索エンジン（該当する場合）。google、aol、live、yahoo、images.googleなど。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_keyword"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このページを見つけるのに使用されたキーワードフレーズ（該当する場合）"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.headers"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "利用可能なリクエストヘッダーのディクショナリー。この変数を使用すると、各リクエストで個別のヘッダーを解釈する必要があるので、ページキャッシュが無効になります。ページのパフォーマンスが劣化する可能性があります。_"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログの変数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ブログテンプレートで使用可能な変数を以下に示します。記事リストでのみ使用できる変数と、ブログ記事でのみ使用できる変数があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この変数には、ブログ執筆者リストページのブログ執筆者情報が含まれます。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-blog-author-statement",
              children: "ブログ執筆者リストのマークアップを表示する"
            }), "条件付きロジックを作成する場合に使用できます。また、以下のプロパティーも含まれます：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.blog_post_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["この変数には、特定の記事の各ブログ記事執筆者の情報が含まれます。以下のプロパティーが含まれています。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.slug"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "グループのエイリアス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在のブログ記事のコメント数。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在のブログ記事のコメントのリスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "current_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ビュー内のブログ記事の現在のページを示す整数インデックス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログの公開時に選択されたキービジュアルのソースURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "キービジュアルの代替テキスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "last_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ビュー内のブログ記事の最終ページを示す整数インデックス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "next_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ビュー内のブログ記事の次のページを示す整数インデックス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この次のブログ記事のキービジュアルのURL（存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の記事のキービジュアルの代替テキスト（代替テキストが存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次のブログ記事の名前（存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次のブログ記事のURLスラッグ（存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ記事の本文。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストページ用に変更された、ブログ記事の本文コンテンツ。最終的な出力は、［設定］>［ウェブサイト］>［ブログ］の要約設定の影響を受けます。設定内のキービジュアルが有効になっている場合、この変数を指定すると、「続きを読む」セパレーターよりも上にある全ての画像が自動的に削除されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_summary_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リストテンプレート内で使用される投稿要約のキービジュアル。この変数は、［設定］>［ウェブサイト］>［ブログ］の設定の影響を受けます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ記事の要約（サマリー）。このコンテンツは、ブログエディターの「続きを読む」セパレーターにより決定されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "前のブログ記事のキービジュアルのURL（存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "前の記事のキービジュアルの代替テキスト（代替テキストが存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "前のブログ記事の名前（存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "前のブログ記事のURLスラッグ（存在する場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ記事が公開された日時を表す文字列。ブログの言語設定と日付形式に基づいて書式が設定されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リクエストされたページが、全てのブログ記事へのリンクを含む「全ての記事」ページであるかどうかを示すブール値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.topic_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["これを使用すると、ループ処理によってトピックリストのマークアップをレンダリングできます。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{% for topic in content.topic_list %}"
            }), " その中の項目には、プロパティー", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "slug"
            }), "が含まれています。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-is-listing-view-statement",
              children: "ブログ リスト ページ（is_listing_view）"
            }), "上にある、forループを使って反復処理されるブログ記事のシーケンスです。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（定期、トピック、執筆者などの）リスト内の記事の合計数。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_page_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1ページあたりの記事数に基づく、記事ページの合計数。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents_topics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "contents（記事のシーケンス）内の全てのブログトピックのリストを取得します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ全体に関連する変数を含むディクショナリー。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログのベースURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.allow_comments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コメントが許可されている場合はtrueと評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "［設定］>［ウェブサイト］>［ブログ］にあるブログのメタディスクリプション。特定のリストページのメタディスクリプションに使用されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログのヘッダー。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), "タグに表示される、このブログのタイトル。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログの固有ID。このIDは、特定のブログのブログダッシュボードのURLで確認できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ブログの言語に関する情報を含むディクショナリー。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ group.language.languageTag }}"
            }), "を条件文で使用すると、複数言語ブログの言語バリエーションごとに異なるコンテンツをレンダリングできます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.public_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レンダリングページの最上部に表示される、このブログのタイトル。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.show_summary_in_listing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "投稿一覧に要約を表示するかどうかを示す、［設定］>［ウェブサイト］>［ブログ］にあるブール値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このブログへのパス。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.use_featured_image_in_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "投稿要約にキービジュアルを表示するかどうかを示す、［設定］>［ウェブサイト］>［ブログ］にあるブール値。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "archive_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページがブログのアーカイブページである場合はtrueを返します。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/blog/archive/2020/02"
            }), "は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRMオブジェクトによる動的ページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
        children: "CRMオブジェクトの動的ページを作成"
      }), "するときに使用される変数を以下に示します。これらの変数は、CRMオブジェクトの動的ページでのみ使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページ リクエスト パスに一致する動的ページのCRMオブジェクト。リクエストがリストページを対象としている場合、この値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), "になります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object_type_fqn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["CRMオブジェクトの完全修飾名（FQN）。FQNは、オブジェクトに割り当てられた一意のIDであり、これにはポータルIDとオブジェクト名が含まれます。FQNを", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "/reference/cms/hubl/functions#crm-objects",
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "crm_objects"
              }), "関数"]
            }), "で使用することができます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBの変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), "で動的ページを作成するときに使用される変数を以下に示します。これらの変数は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
        children: "HubDB動的ページ"
      }), "でのみ使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "長整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページエディターの［詳細設定］タブで選択されているテーブルのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ページ リクエスト パスに一致する動的ページのHubDB行。リクエストがリストページを対象としている場合、この値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), "になります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "長整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB行の内部ID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB行の名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["HubDB行のパス。これを使用すると、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            }), "で指定されたテーブル内の1行にリクエストが解決されます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "長整数"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB行の子テーブルID。ネストしたテンプレートを作成するために使用できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_parent_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ディクショナリー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB行の親行。ネストしたテンプレートの子テーブルを使用する場合にのみ使用できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_route_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "整数"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["複数レベル動的テンプレート内のページの現在の深さ。値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), "から始まり、テーブル階層が1つ加わるごとに増えていきます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メニューノードの変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "HubLメニュー関数"
      }), "により返されるオブジェクトで使用できる変数を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのメニューラベル。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのID（HubSpot内の場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.contentGroupId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのブログID（HubSpotブログ記事の場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.parentNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["現在のノードの親ノード。親ノードの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "children"
            }), "プロパティーには、現在のノードが含まれます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.children"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "現在のノードの子ノードからなるリスト。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeBranch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ノードが現在のページの最上位分岐内にある場合はtrue。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ノードが現在のページである場合はtrue。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "最上位のノードから現在のノードまでのレベル数（深さ）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageTitle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツページの名前（HubSpot内の場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのパススラッグ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.linkTarget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ページのターゲットリンク。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリ内エディターおよびプレビュー変数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次の変数を使用すると、コンテンツがコンテンツエディターまたはプレビューアーで表示されているかどうかを確認できます。例えば、それらを使用して、ライブページでコードが実行されている間はエディターでコードが実行されないようにすることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_in_page_editor %}\nDisplay something different within the page editor.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_hs_app"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがHubSpotアプリ内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツが任意のコンテンツエディター内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_global_content_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがグローバル コンテンツ エディター内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがテーマエディター内に表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがページエディター内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがブログ記事エディター内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがEメールエディター内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツが任意のプレビューコンテキスト内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがテーマプレビューアー内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_template_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがテンプレートプレビューアー内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがページプレビューアー内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがブログ記事プレビューアー内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがEメールプレビューアー内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_module_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツがモジュールプレビューアー内で表示されている場合、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "を返します。"]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}