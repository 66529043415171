"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 70944947747;
const slug = exports.slug = 'guides/cms/content/data-driven-content/graphql/use-graphql-data-in-your-website-pages';
const title = exports.title = 'Use data from a GraphQL query in your website pages';
const name = exports.name = 'Use data from a GraphQL query in your website pages';
const metaDescription = exports.metaDescription = 'Learn how to use data from a GraphQL query in your website pages.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "bind-a-data-query-to-a-template",
  "label": "Bind a data query to a template",
  "parent": null
}, {
  "depth": 0,
  "id": "bind-a-data-query-to-a-module",
  "label": "Bind a data query to a module",
  "parent": null
}, {
  "depth": 0,
  "id": "pass-dynamic-context-into-a-query",
  "label": "Pass dynamic context into a query",
  "parent": null
}, {
  "depth": 0,
  "id": "create-dynamic-pages-using-query-data",
  "label": "Create dynamic pages using query data",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-query-and-module-for-your-dynamic-pages",
  "label": "Create a query and module for your dynamic pages",
  "parent": "create-dynamic-pages-using-query-data"
}, {
  "depth": 1,
  "id": "create-a-listing-page-query-and-module",
  "label": "Create a listing page query and module",
  "parent": "create-dynamic-pages-using-query-data"
}, {
  "depth": 1,
  "id": "create-website-pages",
  "label": "Create website pages",
  "parent": "create-dynamic-pages-using-query-data"
}, {
  "depth": 0,
  "id": "further-reading",
  "label": "Further reading",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      em: "em",
      pre: "pre",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Use data from a GraphQL query in your website pages"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://graphql.org/",
        children: "GraphQL"
      }), " allows you to create data queries that access your HubSpot CRM and HubDB data to create personalized and data-driven experiences for your website visitors. Learn more about how GraphQL works and how to create a query ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/graphql/query-hubspot-data-using-graphql",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can learn more about building data-based pages in HubSpot Academy's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "Data-Driven Content course"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you're familiar with how GraphQL works and you've written a query that specifies the data you'll need, you can use the query in your theme."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Save your query in a file with the .graphql extension. It's recommended you keep GraphQL query files in a separate directory at the root of your theme to keep them organized and make them easier to reference with a relative path."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Upload a theme that includes the query file ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#upload",
          children: "using the HubSpot CLI tools"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager#creating-new-templates-and-files",
          children: "through the design manager"
        }), " in your HubSpot account."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've uploaded your GraphQL files, you can bind them to a module or template, which will make the query's data available to the rendering context of the module or template."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bind a data query to a template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To bind a GraphQL query to a template so you can reference the data in your code, add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dataQueryPath"
      }), " template annotation and provide the path to the associated GraphQL query file. You don't need to include the file's ", (0, _jsxRuntime.jsx)(_components.em, {
        children: ".graphql"
      }), " extension in the path."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you want to bind a query file to a template that renders the query's results on the page, the top of your template's code would include the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: true\n  label: Contact profile page - GraphQL\n  dataQueryPath: ../data-queries/contact_info\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then reference the response to the data query in your template and module code from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data_query"
      }), " variable in HubL. The example below defines a HubL variable to store available roles:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set contactData = data_query.data.CRM.contact %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bind a data query to a module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can bind a GraphQL query to a module using the design manager."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design Tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["At the top of the finder, click the File dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New file"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "What would you like to build today?"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "GraphQL"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "file name"
        }), ", then confirm the location for your GraphQL file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy and paste your query into the editor, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish changes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to the module you want to use with your query. Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Linked files"
        }), ", click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "GraphQL file"
        }), " dropdown menu, then select your query."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/bind-graphql-query-to-module-in-design-manager.png",
        alt: "bind-graphql-query-to-module-in-design-manager"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then reference the response of your query in your module code using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data_query"
      }), " variable. The example below defines a HubL variable to store the queried data for a collection of contacts:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set contactCollectionData = module.data_query.data.CRM.contact_collection %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're developing locally, you may prefer to bind a GraphQL query directly to a module by including a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data_query_path"
      }), " parameter in your module's meta.json file. It's recommended you define your module within a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "modules"
      }), " directory at the root of your theme to make it easier to reference your query with a relative path."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the HubSpot CLI tools, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#fetch",
          children: "fetch"
        }), " the module's directory."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "data_query_path"
        }), " parameter and specify the path to the associated GraphQL query file, relative to the location of the meta.json file. For example, if the query file is in a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "data-queries"
        }), " directory at the root of your theme, your meta.json file would include the following:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"data_query_path\": \"../../data-queries/contactsQuery\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Upload the updated meta.json file to your HubSpot account."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pass dynamic context into a query"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your query requires context that is dynamic to a given request, such as data from a contact who's visiting one of your pages, you can pass ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "HubL variables"
      }), " in your GraphQL queries."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["First, define the variable in a single line comment at the top of your query using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "#"
        }), " character. The convention for GraphQL variable names is to prefix them with a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "$"
        }), " character. For example, to define a variable for the ID of a contact:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "# $contact_id = \"{{ request.contact.contact_vid || ''}}\""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include your variable and its type as a parameter in your query definition. If you want your variable to non-nullable, add an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!"
        }), " after its type. To pass the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "$contact_id"
        }), " variable above into a query to show contact info for a given contact, your query definition would begin with:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "query contact_info($contact_id: String!)"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can then reference your variable and provide it as an argument to any of the filters in your query. Using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "$contact_id"
        }), " variable from above, the query below would provide the first name, last name, and email of a contact visiting a page:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Contact info page\"\n# description: \"Show a contact's name and email\"\n# $contact_id: \"{{request.contact.contact_vid }}\"\nquery contact_info($contact_id: String!) {\n  CRM {\n    contact(uniqueIdentifier: \"id\", uniqueIdentifierValue: $contact_id) {\n      _metadata {\n        id\n      }\n      firstname\n      lastname\n      email\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can also pass URL query string parameters as arguments to your GraphQL query using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request.query_dict"
        }), " HubL variable. For example, if you have a page URL that contained ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "/contacts?location=Boston&department=marketing"
        }), ", and wanted to use the URL query parameters in your GraphQL query to filter contacts by location and department, you could use the following GraphQL query:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Contact listing page with filters\"\n# description: \"Filter contacts by location and department\"\n# $location: \"{{ request.query_dict.location  || ''}}\"\n# $department: \"{{ request.query_dict.department || ''}\"\nquery contact_listing_page($location: String!, department: $String) {\n  CRM {\n    contact_collection(filter: {location__eq: $location, department_eq: $department) {\n      items {\n        _metadata {\n         id\n        }\n        firstname\n        lastname\n        email\n      }\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create dynamic pages using query data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You use directives in your GraphQL queries to dynamically generate pages based on certain properties from your CRM data. Each dynamic page will pull its metadata from the directives you annotate in your query. The values you define for each directive should uniquely identify the content of the page to stand out in search results."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This metadata includes the following directives:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Dynamic page directive"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_canonical_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The canonical URL of the page"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The page's featured image, which will appear when the page is shared."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The page's meta description"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "web_page_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The page's title"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to these directives, you'll also need to choose a custom property to use as a unique identifier in your query if you're querying CRM data. This property must be configured with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hasUniqueValue\": true"
      }), ". Existing properties cannot be updated to have this parameter, so you'll need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "create a new property through the API"
      }), " if you don't already have one."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a query and module for your dynamic pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a query you can use with dynamic pages:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "description"
        }), " for your query as single line comments at the top of your query. Then add another single line comment for an identifier to associate with the dynamic slug of your page:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Set the name of your identifier to the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_slug"
            }), " field provided by the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path_param_dict"
            }), " HubL variable."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["For example, if you're creating a query to dynamically create profile pages for your contacts, and you want to use a custom property named ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "profile_full_name"
            }), " as the slug for each contact's profile page, the following comment would be included above the query definition:"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "#$profile_full_name: {{ request.path_param_dict.dynamic_slug }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include an ID field in your query that matches your data source:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you're querying ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CRM"
            }), " data, include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            }), " field."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you're querying data from a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBDB"
            }), " table, include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), " field."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Annotate the fields of your query with the corresponding metadata directive from the table above, prefacing each directive with the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@"
        }), " character. For example, to configure the title, meta description, and featured image for a contact profile page:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Contact profile\"\n# description: \"Contact profile query for showing details for a single contact\"\n# $profile_full_name: {{ request.path_param_dict.dynamic_slug }}\nquery contact_profile_page($profile_full_name: String!) {\n  CRM {\n    contact(\n      uniqueIdentifier: \"profile_full_name\"\n      uniqueIdentifierValue: $profile_full_name\n    ) {\n      hs_object_id\n      email\n      profile_full_name @web_page_title\n      profile_description @web_page_meta_description\n      profile_picture_url @web_page_featured_image\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Save your query in a file with the .graphql extension. As you design your module, you can access the data from your query using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "data_query"
        }), " HubL variable."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a listing page query and module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depending on your use case, you may want the dynamic page's root URL to display a listing of your object's records. In the example above, a website visitor who navigated to ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://website.com/profiles",
          children: "https://website.com/profiles"
        })
      }), " would be presented with a listing of all contact profiles. To set up a listing page:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create another GraphQL query to retrieve a collection of the associated object records you'll need for the listing page.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Add a label, description, and any other variables you need to pass to your query as single line comments at the top of your query."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Include any ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#query-arguments-and-filters",
              children: "filters"
            }), " you might need as arguments to your collection field."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-graphql",
        children: "# label: \"Profile listing\"\n# description: \"Contact profile listing query for showing all profiles with a \"hubspot.com\" email address\"\nquery contact_listing {\n  CRM {\n    contact_collection(filter: { email__contains: \"hubspot.com\" }) {\n      email\n      profile_full_name\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Save your listing page query with the .graphql extension, then follow the instructions above to bind your query to a listing page module."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create website pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've created your queries and bound them to their associated modules, you can use the modules in a listing page and a details page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create a listing page:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Create a new page, selecting a page template that has a drag and drop area or flexible column."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the page editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Settings"
            }), " tab."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "page title"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Page URL"
            }), ", enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "content slug"
            }), " that corresponds to the collection that you're retrieving for your dynamic pages. Following the example above, the content slug would be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/profiles"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "meta description"
            }), " for the listing page, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Content"
            }), " tab to return to the editor."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar, in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Add"
            }), " tab, search for your listing page module, then drag it into the page editor."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["After you're done designing your page, publish it by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Publish"
            }), " in the upper right."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Next, set up the details page:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
              children: "Create a new page"
            }), ", selecting a page template that has a drag and drop area or flexible column."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the page editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Settings"
            }), " tab."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "page"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "title"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Page URL"
            }), ", click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "pencil icon"
            }), " to edit the page's URL. Set the URL to where you want your listing page to appear. In the example above, the listing page will be at: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/profiles"
            }), ", so the full content slug for the page should be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/profiles/[:dynamic-slug]"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Advanced options"
            }), ", then scroll to the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Dynamic pages"
            }), " section."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Dynamic pages"
            }), ", click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Data source"
            }), " dropdown menu, then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "label"
            }), " that matches the one you included at the top of your GraphQL query."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Content"
            }), " tab at the top of the page to return to the editor."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar, in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Add"
            }), " tab, search for your details module and drag it into the page editor."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When you're ready, publish your page by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Publish"
            }), " in the upper right."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Further reading"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about how to construct, test, and refine your GraphQL query ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/graphql/query-hubspot-data-using-graphql",
        children: "here"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}