"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529777;
const slug = exports.slug = 'reference/cms/hubl/operators-and-expression-tests';
const title = exports.title = 'Testes de expressão e operadores';
const name = exports.name = '[new] Operators & Expression Tests';
const metaDescription = exports.metaDescription = 'Para expandir a lógica e a funcionalidade de seus modelos, o HubL permite vários operadores-chave e testes de expressão. Os operadores permitem executar funções matemáticas, fazer comparações, complicar a lógica do modelo e alterar a renderização da marcação. Além disso, este artigo contém uma lista abrangente de testes de expressão que podem ser usados no HubL.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "operadores",
  "label": "Operadores",
  "parent": null
}, {
  "depth": 1,
  "id": "matem%C3%A1tica",
  "label": "Matemática",
  "parent": "operadores"
}, {
  "depth": 1,
  "id": "compara%C3%A7%C3%A3o",
  "label": "Comparação",
  "parent": "operadores"
}, {
  "depth": 1,
  "id": "l%C3%B3gico",
  "label": "Lógico",
  "parent": "operadores"
}, {
  "depth": 1,
  "id": "outros-operadores-do-hubl",
  "label": "Outros operadores do HubL",
  "parent": "operadores"
}, {
  "depth": 0,
  "id": "testes-de-express%C3%A3o",
  "label": "Testes de expressão",
  "parent": null
}, {
  "depth": 1,
  "id": "booleano",
  "label": "booleano",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "containing",
  "label": "containing",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "containingall",
  "label": "containingall",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "defined",
  "label": "defined",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "divisibleby",
  "label": "divisibleby",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "equalto",
  "label": "equalto",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "even",
  "label": "even",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "float",
  "label": "float",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "integer",
  "label": "integer",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "iterable",
  "label": "iterable",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "lower",
  "label": "lower",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "mapeamento",
  "label": "Mapeamento",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "nenhum",
  "label": "NENHUM",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "n%C3%BAmero",
  "label": "número",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "odd",
  "label": "odd",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "sameas",
  "label": "sameas",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "sequ%C3%AAncia",
  "label": "sequência",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "string",
  "label": "string",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "string_containing",
  "label": "string_containing",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "string_startingwith",
  "label": "string_startingwith",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "truthy",
  "label": "truthy",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "undefined",
  "label": "undefined",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "upper",
  "label": "upper",
  "parent": "testes-de-express%C3%A3o"
}, {
  "depth": 1,
  "id": "within",
  "label": "within",
  "parent": "testes-de-express%C3%A3o"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Testes de expressão e operadores"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para expandir a lógica e a funcionalidade dos seus modelos, o HubL aceita vários operadores-chave e testes de expressão. Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#operators",
        children: "operadores"
      }), " permitem executar funções matemáticas, fazer comparações, complicar a lógica do modelo e alterar a renderização da marcação. Além disso, este artigo contém uma lista abrangente de testes de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "expressão"
      }), " que podem ser usados no HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Operadores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os operadores são símbolos que dizem ao compilador HubL para executar várias operações que resultam na saída de marcação final. A seção a seguir inclui uma lista de todos os operadores de HubL permitidos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Matemática"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os operadores matemáticos padrão podem ser usados para calcular valores no contexto de um modelo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Símbolo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "+"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Adiciona dois objetos juntos. Isso geralmente é usado para a adição de números. Se você estiver tentando concatenar strings de listas, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "~"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "-"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subtrai um número de outro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "-"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divide números"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "%"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Retorna o restante da divisão de números"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "//"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Divida dois números e retorne o resultado inteiro truncado. Exemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;%raw%&#125;&#123;&#123; 20 // 7 &#125;&#125;&#123;%endraw%&#125;"
            }), " é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "*"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Multiplica números"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "**"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eleve o operando esquerdo ao poder do operando direito"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num + my_number }}<br/>  <!-- 11 + 2 = 13 -->\n{{ my_num - my_number }}<br/>  <!-- 11 - 2 = 9 -->\n{{ my_num / my_number }}<br/>  <!-- 11 / 2 = 5.5 -->\n{{ my_num % my_number }}<br/>  <!-- 11 % 2 = 1 -->\n{{ my_num // my_number }}<br/> <!-- 11 // 2 = 5 -->\n{{ my_num * my_number }}<br/>  <!-- 11 * 2 = 22 -->\n{{ my_num ** my_number }}<br/> <!-- 11 ** 2 = 121 -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "13 9 5.5 1 5 22 121\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comparação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os operadores de comparação podem ser usados para avaliar os valores da lógica do modelo. Você pode ver alguns exemplos de operadores de comparação sendo usados em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "declarações if aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Símbolo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "encurtado"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "=="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Igual a Avalia como verdadeiro sedois objetos são iguais."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "!="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não é igual a. Avalia como verdadeiro se dois objetos não forem iguais."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maior que Avalia como verdadeiro se o lado esquerdo for maior que o lado direito."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maior ou igual a Avalia como verdadeiro se o lado esquerdo for maior ou igual ao lado direito."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que Avalia como verdadeiro se o lado esquerdo for inferior ao lado direito."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor ou igual a Avalia como verdadeiro se o lado esquerdo for inferior ou igual ao lado direito."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A versão abreviada dos operadores de comparação é utilizável em filtros que envolvem o teste de uma expressão como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|selectattr()"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num == my_number }}<br/>  <!-- false -->\n{{ my_num != my_number }}<br/>  <!-- true -->\n{{ my_num > my_number }}<br/>   <!-- true -->\n{{ my_num >= my_number }}<br/>  <!-- true -->\n{{ my_num < my_number }}<br/>   <!-- false -->\n{{ my_num <= my_number }}<br/>  <!-- false -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "false true true true false false\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Lógico"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os operadores lógicos permitem combinar várias expressões em instruções únicas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Símbolo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "e"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Retorna verdadeiro se o operando direito e esquerdo forem verdadeiros."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ou"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Retorna verdadeiro se o operando esquerdo ou direito for verdadeiro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "não"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nega uma declaração e é usado em conjunto com é. Veja os exemplos abaixo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "(expr)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Agrupa uma expressão para a ordem de operações. Por exemplo, (10 - 2) * variável."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "?:"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements",
              children: "operador ternário"
            }), " aceita 3 argumentos (expressão, condição verdadeira, condição falsa). Avalia uma expressão e retorna a condição correspondente."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Outros operadores do HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo outros operadores do HubL importantes que podem ser usados para executar várias tarefas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Símbolo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "no"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verifica se um valor está em uma sequência."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "está"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Executa um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
              children: "teste de expressão"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "|"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplica um filtro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "~"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concatena valores."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Testes de expressão"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os testes de expressão são várias condições booleanas que podem ser avaliadas por operadores lógicos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "booleano"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "booleana"
      }), " verifica se o objeto é booleano (em um sentido estrito, não em sua capacidade de avaliar para uma expressão verdadeira)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set isActive = false %}\n\n{% if isActive is boolean %}\nisActive is a boolean\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "isActive is a boolean\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containing"
      }), " verifica se uma variável de lista tem um valor nela."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containing 2 %}\n\tSet contains 2!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containingall"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "containingall"
      }), " verifica se uma variável de lista contém todos os valores de outra lista."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containingall [2, 3] %}\n\tSet contains 2 and 3!\n{% endif %}\n\n{% if numbers is containingall [2, 4] %}\n\tSet contains 2 and 4!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2 and 3!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "defined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "defined"
      }), " definida verifica se uma variável é definida dentro do contexto do modelo. Embora você possa usar esse teste de expressão, escrever uma instrução if sem operador será o padrão para verificar se a variável está definida ou não."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No exemplo abaixo, o parâmetro de cor de um módulo de cor é testado. Se o parâmetro de cor não contiver um valor, o modelo renderizará uma cor de fundo preta padrão. Se for definido, ele renderizará a cor de fundo definida pelo usuário."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% color \"my_color\" color=\"#930101\", export_to_template_context=True %}\n<style>\n{% if widget_data.my_color.color is defined %}\nbody{\n    background: {{ widget_data.my_color.color }};\n}\n{% else %}\nbody{\n    background: #000;\n}\n{% endif %}\n</style>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<style>\n  body {\n    background: #930101;\n  }\n</style>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "divisibleby"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão teste ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "divisibleby"
      }), " pode ser usado para testar se um objeto é divisível por outro número."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, abaixo, é criado um loop for que itera através de uma lista de tipos de animais. Cada tipo de animal é impresso em uma div, e cada 5ª div tem um estilo em linha diferente aplicado (largura:100%). Esse conceito pode ser aplicado a um blog em que uma marcação diferente é renderizada para um determinado padrão de posts. Para saber mais sobre loops e loop.index, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "confira este artigo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set animals = [\"lions\", \"tigers\", \"bears\", \"dogs\", \"sharks\"] %}\n{% for animal in animals %}\n    {% if loop.index is divisibleby 5 %}\n    <div style=\"width:100%\">{{animal}}</div>\n    {% else %}\n    <div style=\"width:25%\">{{animal}}</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%\">lions</div>\n<div style=\"width:25%\">tigers</div>\n<div style=\"width:25%\">bears</div>\n<div style=\"width:25%\">dogs</div>\n<div style=\"width:100%\">sharks</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "equalto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "equalto"
      }), " verifica se o valor de uma variável é igual a uma constante ou outra variável. Você também pode usar o operador == para fazer o mesmo teste."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No exemplo abaixo, a largura dos posts do blog é ajustada com base no número total de postagens no loop. O exemplo de saída pressupõe que havia 4 posts no blog."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    {% if loop.length is equalto 2 %}\n        <div style=\"width:50%;\">Post content</div>\n    {% elif loop.length is equalto 3 %}\n        <div style=\"width:33.333332%;\">Post content</div>\n    {% elif loop.length is equalto 4 %}\n        <div style=\"width:25%;\">Post content</div>\n    {% else %}\n        <div style=\"width:100%;>Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "even"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "even"
      }), " verifica se uma variável numérica é um número par."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo mostra um loop de listagem de blog simplificado, em que, se a iteração atual do loop for par, uma classe de post par será atribuída ao div do item do post. Caso contrário, uma classe de post ímpar é atribuída."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is even %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "float"
      }), " verifica se uma variável numérica é um número de ponto flutuante."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 1.20 %}\n{% if quantity is float %}\n  quantity is a floating point number\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is a floating-point number\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "integer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verifica se uma variável é um ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "número inteiro."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 120 %}\n{% if quantity is integer %}\n  quantity is an integer\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is an integer\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "iterable"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verifica se uma variável é ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterável"
      }), " e pode ser inserida em loop."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este exemplo verifica uma variável chamada \"jobs\" para ver se ela pode ser iterada. Como a variável contém uma lista de trabalhos, a instrução if seria avaliada como true e o loop seria executado. Se a variável contivesse um único valor, a instrução ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if"
      }), " imprimiria esse valor com marcação diferente. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "Saiba mais sobre loops for"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set jobs = [\"Accountant\", \"Developer\", \"Manager\", \"Marketing\", \"Support\"] %}\n\n{% if jobs is iterable %}\n<h3>Available positions</h3>\n<ul>\n{% for job in jobs %}\n    <li>{{ job }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Available position</h3>\n<div class=\"single-position\">{{ jobs }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Available positions</h3>\n<ul>\n  <li>Accountant</li>\n  <li>Developer</li>\n  <li>Manager</li>\n  <li>Marketing</li>\n  <li>Support</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "lower"
      }), " é avaliado como verdadeiro quando uma string está em letras minúsculas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O exemplo abaixo usa uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements#unless-statements",
        children: "instrução unless"
      }), " e um filtro inferior para garantir que uma sequência de texto inserida em um módulo de texto esteja sempre em minúsculas."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Lowercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is lower %}\n{{ widget_data.my_text.value|lower }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text that should be lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mapeamento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mapping"
      }), " verifica se um objeto é ou não um dict (dicionário)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo verifica se o objeto de contato é um dicionário, o que é verdadeiro neste caso."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if contact is mapping %}\nThis object is a dictionary.\n{% else %}\nThis object is not a dictionary.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This object is a dictionary.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "NENHUM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "none"
      }), " verifica se uma variável tem um valor nulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"user_email\" path=\"@hubspot/text\" label=\"Enter user email\", value=\"example@hubspot.com\", export_to_template_context=True %}\n{% unless widget_data.user_email.value is none %}\n{{ widget_data.user_email.value }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "example@hubspot.com\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "número"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "number"
      }), " verifica se o valor de uma variável é um número."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo verifica uma variável para ver se ela é ou não uma variável e, em caso afirmativo, converte-a em milhões."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = 40 %}\n{% if my_var is number %}\n{{ my_var * 1000000 }}\n{% else %}\nmy_var is not a number.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "40000000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "odd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "odd"
      }), "verifica se uma variável numérica é um número ímpar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo está o mesmo exemplo do teste de expressão inversa par descrito anteriormente."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is odd %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sameas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sameas"
      }), " verifica se duas variáveis têm o mesmo valor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo define duas variáveis e, em seguida, verifica se elas são ou não as mesmas."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set var_one = True %}\n{% set var_two = True %}\n{% if var_one is sameas var_two  %}\nThe variables values are the same.\n{% else %}\nThe variables values are different.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The variables values are the same.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sequência"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "sequence"
      }), " é similar ao ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), " , na medida em que verifica se uma variável é ou não uma sequência."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo verifica se uma variável é uma sequência e, em seguida, itera através dessa sequência de gêneros musicais."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set genres = [\"Pop\", \"Rock\", \"Disco\", \"Funk\", \"Folk\", \"Metal\", \"Jazz\", \"Country\", \"Hip-Hop\", \"Classical\", \"Soul\", \"Electronica\" ] %}\n{% if genres is sequence %}\n<h3>Favorite genres</h3>\n<ul>\n{% for genre in genres %}\n    <li>{{ genre }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Favorite genre:</h3>\n    <div class=\"single-genre\">{{ genres }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Pop</li>\n  <li>Rock</li>\n  <li>Disco</li>\n  <li>Funk</li>\n  <li>Folk</li>\n  <li>Metal</li>\n  <li>Jazz</li>\n  <li>Country</li>\n  <li>Hip-Hop</li>\n  <li>Classical</li>\n  <li>Soul</li>\n  <li>Electronica</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "string"
      }), " verifica se o valor armazenado em uma variável é texto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo verifica se uma variável é ou não uma cadeia de caracteres e, em caso afirmativo, aplica um filtro de título para alterar a capitalização."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = \"title of section\" %}\n{% if my_var is string %}\n{{ my_var|title }}\n{% else %}\nmy_var is not a string\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Title Of Section\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_containing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este teste verifica se uma string está contida dentro de outra string. Este teste de expressão é usado em conjunto com o operador \"is\"."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.domain is string_containing \".es\" %}\nMarkup that will only render on content hosted on .es domains\n{% elif content.domain is string_containing \".jp\" %}\nMarkup that will only render on content hosted on .jp domains\n{% else %}\nMarkup that will render on all other domains\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all other domains\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_startingwith"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este teste de expressão verifica se uma string começa com uma string específica. Ela é usada em conjunto com o operador \"is\"."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.slug is string_startingwith \"es/\" %}\nMarkup that will only render on content hosted in a /es/ subdirectory\n{% elif content.slug is string_startingwith \"jp/\" %}\nMarkup that will only render on content hosted in a /jp/ subdirectory\n{% else %}\nMarkup that will render on all subdirectories\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all subdirectories\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "truthy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "truthy"
      }), " verifica se uma expressão é avaliada como Verdadeira."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo usa um módulo de caixa de seleção booleana para exibir uma mensagem de alerta."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% boolean \"check_box\" label=\"Show alert\", value=True, export_to_template_context=True %}\n\n{% if widget_data.check_box.value is truthy %}\n<div class=\"alert\">Danger!</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída HTML renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"alert\">Danger!</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "undefined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "undefined"
      }), " verifica se uma variável está indefinida no contexto do modelo. Esse teste é diferente de none, pois undefined será verdadeiro quando a variável estiver presente, mas não tiver valor; enquanto nenhum será verdadeiro quando a variável tiver um valor nulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo verifica um modelo para a existência da variável \"my_var\"."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if my_var is undefined %}\nA variable named \"my_var\" does not exist on this template.\n{% else %}\n{{ my_var }}\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A variable named \"my_var\" does not exist on this template.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O teste", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "upper"
      }), " é avaliado como verdadeiro quando uma string é toda maiúscula. Veja abaixo um exemplo inverso do teste de expressão inferior acima."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Uppercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is upper %}\n{{ widget_data.my_text.value|upper }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "SOME TEXT THAT SHOULD BE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "within"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os testes de expressão ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "within"
      }), " verificam se uma variável está dentro de uma lista."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if 2 is within numbers %}\n\t2 is in the list!\n{% endif %}\n\n{% if 4 is within numbers %}\n\t4 is in the list!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída renderizada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2 is in the list!\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}