"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164591883422;
const slug = exports.slug = 'reference/ui-components/crm-data-components/overview';
const title = exports.title = 'CRM data components (BETA)';
const name = exports.name = 'CRM data components (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the CRM data component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "available-components",
  "label": "Available components",
  "parent": null
}, {
  "depth": 0,
  "id": "filtering-data",
  "label": "Filtering data",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CRM data components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRM data components can pull data directly from the currently displaying CRM record, including information about associated records and single object reports. These components can only be placed in the middle column of CRM records."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These components are imported from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { CrmAssociationPivot, CrmReport } from '@hubspot/ui-extensions/crm';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Available components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-association-pivot",
          children: "CrmAssociationPivot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-association-property-list",
          children: "CrmAssociationPropertyList"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-association-table",
          children: "CrmAssociationTable"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-data-highlight",
          children: "CrmDataHighlight"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-property-list",
          children: "CrmPropertyList"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-report",
          children: "CrmReport"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-stage-tracker",
          children: "CrmStageTracker"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-statistics",
          children: "CrmStatistics"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtering data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CrmAssociationPivot"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CrmAssociationTable"
      }), " components, you can filter the data to fetch only what's most relevant. Review the table below for available filtering options."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { CrmAssociationPivot, CrmReport } from '@hubspot/ui-extensions/crm';\n\nconst Extension = () => {\n  return (\n    <CrmAssociationPivot\n      objectTypeId=\"0-1\"\n      associationLabels={['CEO', 'Co-founder']}\n      maxAssociations={10}\n      preFilters={[\n        {\n          operator: 'NOT_IN',\n          property: 'dealstage',\n          values: ['closedwon'],\n        },\n      ]}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "operator"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "EQ"
            }), " |", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NEQ"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LT"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LTE"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GT"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GTE"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_IN"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PROPTERTY"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PROPERTY"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The filter's operator (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            }), "). Can be one of:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EQ"
                }), ": is equal to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NEQ"
                }), ": is not equal to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LT"
                }), ": is less than ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LTE"
                }), ": is less than or equal to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "GT"
                }), ": is greater than ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "GTE"
                }), ": is greater than or equal to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BETWEEN"
                }), ": is within the specified range between ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "value"
                }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "highValue"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IN"
                }), ": is included in the specified ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "values"
                }), " array. This operator is case-sensitive, so inputted values must be in lowercase. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NOT_IN"
                }), ": is not included in the specified ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "values"
                }), " array."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "HAS_PROPERTY"
                }), ": has a value for the specified property."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NOT_HAS_PROPERTY"
                }), ": does not have a value for the specified property."]
              })]
            }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/search#filter-operators",
              children: "filtering CRM searches"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The property to filter by."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String | number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The property value to filter by."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String | number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The property values to filter by when using an operator that requires an array, such as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "highValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String | number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The upper value to filter by when using an operator that requires a range, such as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            }), "."]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}