"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501958307;
const slug = exports.slug = 'reference/api/deprecated/overview';
const title = exports.title = 'APIs da HubSpot | APIs obsoletas';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Uma lista de APIs da HubSpot obsoletas';
const toc = exports.toc = [{
  "depth": 0,
  "id": "apis-encerradas",
  "label": "APIs encerradas",
  "parent": null
}, {
  "depth": 0,
  "id": "apis-obsoletas",
  "label": "APIs obsoletas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "APIs encerradas e obsoletas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As APIs listadas nas seções abaixo ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " receberão futuras atualizações de funcionalidade e, em alguns casos, serão totalmente desativadas e ficarão indisponíveis para uso nas suas integrações."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["É altamente recomendável que você se inscreva na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Changelog do Desenvolvedor da HubSpot"
      }), " para acompanhar as atualizações mais recentes, alterações de interrupção e outras alterações significativas na funcionalidade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIs encerradas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As seguintes APIs foram totalmente removidas e retornarão um erro ao fazer uma chamada aos pontos de extremidade associados."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Data da descontinuação"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Observações"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Desempenho do CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9 de abril de 2024"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para obter dados analíticos do site da Web, utilize a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/reporting",
              children: "API de análises"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ecommerce Bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28 de fevereiro de 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se você já criou uma integração que usa essas APIs, poderá seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "o guia de migração"
            }), " para alternar sua integração para usar aplicativos privados."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Extensão de contabilidade"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28 de fevereiro de 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se você já criou uma integração que usa essas APIs, poderá seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "este guia de migração"
            }), " para alternar sua integração para usar aplicativos privados."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Calendário de marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "31 de agosto de 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Você pode continuar a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/use-your-marketing-calendar",
              children: "utilizar o calendário de marketing no HubSpot"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIs obsoletas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os pontos de extremidade antigos listados abaixo ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " receberão uma atualização de versão. Esses endpoints são funcionais e estáveis, mas não serão atualizados além da versão atual. A HubSpot continuará a oferecer suporte para eles no futuro próximo e anunciará qualquer alteração futura com um aviso amplo no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Registro de alterações para desenvolvedores"
      }), ".", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Observações"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Mídia social"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Inclui os seguintes pontos de extremidade:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
                  children: "Obter canais de publicação"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
                  children: "Obter mensagens de transmissão"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
                  children: "Obter uma mensagem de transmissão"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
                  children: "Criar uma mensagem de transmissão"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
                  children: "Cancelar uma mensagem de transmissão"
                })
              })]
            })]
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}