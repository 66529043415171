"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 153392289439;
const slug = exports.slug = 'reference/ui-components/standard-components/link';
const title = exports.title = 'Link';
const name = exports.name = 'Link | UI components';
const metaDescription = exports.metaDescription = 'Learn about the link component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Link | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Link"
      }), " component renders a clickable hyperlink. Use links to direct users to a web page, another part of the HubSpot app, or use them as buttons."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-link.png?width=700&height=232&name=design-guidelines-link.png",
        alt: "design-guidelines-link"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Link text:"
        }), " text that describes where the link leads."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "External link"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "icon"
        }), ": included when setting ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "external"
        }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". and indicates that the link will open in a new tab. Automatically included when the link leads to a page outside of the HubSpot app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Link } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Link\n      href={{\n        url: 'https://www.wikipedia.org',\n        external: true,\n      }}\n    >\n      Wikipedia\n    </Link>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), " Required"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the link's URL and open behavior. Contains the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), " (string): the URL that will open on click."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to open the URL in a new tab and display an external link icon. By default:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "Links to HubSpot app pages will open in the same tab and will not include an icon."
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "Links to non-HubSpot app pages will open in a new tab and include the icon."
                  })]
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'primary'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'light'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dark'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'destructive'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The color of the link. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants section"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "() => void"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that is invoked when the link is clicked. The function receives no arguments and its return value is ignored."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "preventDefault"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "event.preventDefault()"
            }), " will be invoked before the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            }), " function is called, preventing automatic navigation to the href URL."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overlay"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Include a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), " component in this object to open it as an overlay on click. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/crm/ui-extensions/sdk#open-an-overlay",
              children: "using overlays"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop, you can set the following styling:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "primary"
          }), ": the default blue (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#0091ae"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_4.png?width=206&height=37&name=design-guidelines-links_4.png",
            alt: "design-guidelines-links_4"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "light"
          }), ": a white link that turns to a lighter shade of blue on hover (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#7fd1de"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_3.png?width=206&height=34&name=design-guidelines-links_3.png",
            alt: "design-guidelines-links_3"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "dark"
          }), ": a darker shade of blue (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#33475b"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_2.png?width=206&height=38&name=design-guidelines-links_2.png",
            alt: "design-guidelines-links_2"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "destructive"
          }), ": a red link (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "#f2545b"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/design-guidelines-links_1.png?width=206&height=37&name=design-guidelines-links_1.png",
            alt: "design-guidelines-links_1"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the default ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary"
        }), " variant when you want to link to another page or contact record in HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "light"
        }), " variant when you want to include a link on a dark background."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dark"
        }), " variant to include a link in an alert."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "destructive"
        }), " variant when the link results in an action that can't be undone by the user, such as deleting contact property information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " space out links so that users can tell when they'll be navigation to a different place."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " make link text concise and contextual."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "destructive"
        }), " variant sparingly and only when the action can't be undone."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " always open links to pages outside of the HubSpot app in a new tab (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "external: true"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " crowd multiple links together."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dark"
        }), " variant outside of alerts."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/heading",
          children: "Heading"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/text",
          children: "Text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/image",
          children: "Image"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}