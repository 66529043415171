"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 127052887586;
const slug = exports.slug = 'guides/apps/marketplace/testing-credentials';
const title = exports.title = 'App Marketplace | Provide testing credentials for your app';
const name = exports.name = 'vNext Docs DP - Provide testing credentials for your app';
const metaDescription = exports.metaDescription = 'Follow these steps to provide testing credentials for your app when listing or certifying it on the App Marketplace. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "before-you-begin",
  "label": "Before you begin",
  "parent": null
}, {
  "depth": 0,
  "id": "provide-your-testing-credentials",
  "label": "Provide your testing credentials",
  "parent": null
}, {
  "depth": 0,
  "id": "frequently-asked-questions",
  "label": "Frequently Asked Questions",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ol: "ol",
      li: "li",
      ul: "ul",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Provide testing credentials for your app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Valid testing credentials must be provided to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "list"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "certify"
      }), " your app on the HubSpot App Marketplace. This is necessary for the HubSpot Product team to verify listing and certification requirements, and also recommend areas for improvement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Before you begin"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Review the following questions to find the appropriate instructions for providing testing credentials to the HubSpot Product team:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Does your app with HubSpot require an account for a platform/application/service other than HubSpot?", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "yes"
            }), ", (i.e. at least one other account is required), proceed to question 2."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "no"
            }), ", follow the steps listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#does-not-require-account",
              children: "here"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Are you able to give HubSpot Product team members access to ", (0, _jsxRuntime.jsx)("u", {
          children: "all"
        }), " platforms/applications/services required to use your app?", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "yes"
            }), ", (i.e. you are able to give HubSpot Product team members access to needed platforms/applications/services), follow the steps listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#able-to-invite-teams",
              children: "here"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "no"
            }), ", (i.e. you are ", (0, _jsxRuntime.jsx)("u", {
              children: "not able"
            }), " to give HubSpot Product team members access to at least one platform/application/service), follow the steps listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#unable-to-give-access",
              children: "here"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Provide your testing credentials"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["If your app ", (0, _jsxRuntime.jsx)("u", {
          children: "does not"
        }), " require a separate platform/application/service account:"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the first line, enter “No testing credentials are required to test this app.”"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Describe the steps to install the app, configure app settings, and perform common actions with the app. If these instructions are publicly documented, you may hyperlink to the relevant documentation."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/no-testing-credentials-required.png",
        alt: "no-testing-credentials-required"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["If your app requires a separate platform/application/service account and you ", (0, _jsxRuntime.jsx)("u", {
          children: "are able"
        }), " to provide the HubSpot Product team with access:"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the first line, list all platforms/applications/services required to fully use your app/integration."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For each required platform/application/service that you are able to give access to:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Invite ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "mailto:marketplace-tester@hubspot.com",
              children: "marketplace-tester@hubspot.com"
            }), " to all new or existing accounts. Ensure that these accounts have all features and permissions required to fully use your app with HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Include “Invite sent to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "mailto:marketplace-tester@hubspot.com",
              children: "marketplace-tester@hubspot.com"
            }), "” next to the platform you’ve added that user to. ", (0, _jsxRuntime.jsx)("u", {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Do not"
              })
            }), " ask the HubSpot Product team to create new accounts or sign up for free trials."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Describe the steps to install the app, configure app settings, and perform common actions with the app. If these instructions are publicly documented, you may hyperlink to the relevant documentation."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/able-to-invite-all.png",
        alt: "able-to-invite-all"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["If your app requires a separate platform/application/service account and you are ", (0, _jsxRuntime.jsx)("u", {
          children: "not able"
        }), " to provide the HubSpot Product team with access:"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the first line, list all platforms/applications/services required to fully use your app/integration."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For each required platform/application/service that you are able to give access to:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Invite ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "mailto:marketplace-tester@hubspot.com",
              children: "marketplace-tester@hubspot.com"
            }), " to all new or existing accounts. Ensure that these accounts have all features and permissions required to fully use your app with HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Include “Invite sent to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "mailto:marketplace-tester@hubspot.com",
              children: "marketplace-tester@hubspot.com"
            }), "” next to the platform you’ve added that user to. ", (0, _jsxRuntime.jsx)("u", {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Do not"
              })
            }), " ask the HubSpot Product team to create new accounts or sign up for free trials."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For each platform/application/service that you are ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " able to give access to:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Include an explanation on why you're unable to give the HubSpot Product team access."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Describe the steps to install the app, configure app settings, and perform common actions with the app. If these instructions are publicly documented, you may hyperlink to the relevant documentation."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Record a demo video that shows the app/integration performing actions with all required platforms, applications, or services. The demo video ", (0, _jsxRuntime.jsx)("u", {
          children: "must"
        }), " meet the requirements listed ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/applying-for-app-certification#app-demo",
          children: "here"
        }), " (even if you are not applying for app certification)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/unable-to-send-invite.png",
        alt: "unable-to-send-invite"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Frequently Asked Questions"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Should I connect my app to an existing HubSpot account?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "You may connect your app to a non-product HubSpot account. However, the HubSpot Product team reserves the right to disconnect your account and connect theirs for testing."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Where will these testing credentials be stored?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Credentials will be stored in a HubSpot-build admin tool and an enterprise-grade privileged access management (PAM) solution which is approved and managed by HubSpot's Security team."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Should I deactivate these testing credentials after my app has been listed or certified?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Please do not deactivate the testing credentials. If this is not possible, please notify the HubSpot Product team when the listing or certification review is complete."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "If I provide testing credentials for a listing review and apply for certification in the future, must I provide credentials again? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Yes, you will be required to provide testing credentials for your app if you apply for certification. You may reuse the same account/user or invite ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "mailto:marketplace-tester@hubspot.com",
          children: "marketplace-tester@hubspot.com"
        }), " if all features and permissions to fully use your HubSpot app remain available."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}