'use es6';

// Replacement functions for Underscore sourced from Underscore v.1.10.2,
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
// (1c3f1c5ef28e61cbd4abf505ff52738a5c75d716), and
// https://github.com/lodash/lodash (86a852fe763935bb64c12589df5391fd7d3bb14d)
export function compose(...args1) {
  const args = args1;
  const start = args.length - 1;
  return function (...args2) {
    let i = start;
    let res = args[start].apply(this, args2);
    while (i--) res = args[i].call(this, res);
    return res;
  };
}
function isObject(obj) {
  const type = typeof obj;
  return type === 'function' || type === 'object' && !!obj;
}
function allKeys(obj) {
  const _keys = [];
  if (!isObject(obj)) return [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      _keys.push(key);
    }
  }
  // NB Underscore has IE<9 support, removed here because it brings with it
  // a lot of other code.
  return _keys;
}
function createAssigner(keysFunc, defaults) {
  return function (obj, ...args) {
    const length = args.length;
    if (defaults) obj = Object(obj);
    if (length < 1 || obj == null) return obj;
    for (let index = 0; index < length; index++) {
      const source = args[index];
      const _keys = keysFunc(source);
      const l = _keys.length;
      for (let i = 0; i < l; i++) {
        const key = _keys[i];
        if (!defaults || obj[key] === void 0) obj[key] = source[key];
      }
    }
    return obj;
  };
}
export const defaults = createAssigner(allKeys, true);
export function isBoolean(obj) {
  return obj === true || obj === false || Object.prototype.toString.call(obj) === '[object Boolean]';
}

// Helper function for `eq`. Number.isNan() is unavailable in IE11.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
function isNaN(input) {
  /*eslint-disable */
  if (Number.isNaN) {
    return Number.isNaN(input);
  }
  return typeof input === 'number' && input !== input;
  /*eslint-disable */
}

// Internal recursive comparison function for `isEqual`.
function eq(a, b, aStack, bStack) {
  if (a === b) return a !== 0 || 1 / a === 1 / b;
  if (a == null || b == null) return false;
  if (isNaN(a)) return isNaN(b);
  const type = typeof a;
  if (type !== 'function' && type !== 'object' && typeof b !== 'object') return false;
  return deepEq(a, b, aStack, bStack);
}

// Internal recursive comparison function for `isEqual`.
function deepEq(a, b, aStack, bStack) {
  const className = Object.prototype.toString.call(a);
  if (className !== Object.prototype.toString.call(b)) return false;
  switch (className) {
    case '[object RegExp]':
    case '[object String]':
      return `${a}` === `${b}`;
    case '[object Number]':
      if (isNaN(a)) return isNaN(b);
      return +a === 0 ? 1 / +a === 1 / b : +a === +b;
    case '[object Date]':
    case '[object Boolean]':
      return +a === +b;
    case '[object Symbol]':
      return Symbol.prototype.valueOf.call(a) === Symbol.prototype.valueOf.call(b);
    default:
  }
  const areArrays = className === '[object Array]';
  if (!areArrays) {
    if (typeof a !== 'object' || typeof b !== 'object') return false;
    const aCtor = a.constructor;
    const bCtor = b.constructor;
    if (aCtor !== bCtor && !(isFunction(aCtor) && aCtor instanceof aCtor && isFunction(bCtor) && bCtor instanceof bCtor) && 'constructor' in a && 'constructor' in b) {
      return false;
    }
  }
  aStack = aStack || [];
  bStack = bStack || [];
  let length = aStack.length;
  while (length--) {
    if (aStack[length] === a) return bStack[length] === b;
  }
  aStack.push(a);
  bStack.push(b);
  if (areArrays) {
    length = a.length;
    if (length !== b.length) return false;
    while (length--) {
      if (!eq(a[length], b[length], aStack, bStack)) return false;
    }
  } else {
    const _keys = allKeys(a);
    let key;
    length = _keys.length;
    if (allKeys(b).length !== length) return false;
    while (length--) {
      key = _keys[length];
      if (!(_has(b, key) && eq(a[key], b[key], aStack, bStack))) return false;
    }
  }
  aStack.pop();
  bStack.pop();
  return true;
}

// Perform a deep comparison to check if two objects are equal.
export function isEqual(a, b) {
  return eq(a, b);
}
function _has(obj, path) {
  return obj != null && hasOwnProperty.call(obj, path);
}
export function omit(obj, ...keys) {
  return Object.keys(obj).filter(key => keys.indexOf(key) < 0).reduce((newObj, key) => Object.assign(newObj, {
    [key]: obj[key]
  }), {});
}
function before(n, func) {
  let r;
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }
  return function (...args) {
    if (--n > 0) {
      r = func.apply(this, args);
    }
    if (n <= 1) {
      func = undefined;
    }
    return r;
  };
}
export function once(func) {
  return before(2, func);
}
export function pick(obj, keys) {
  return Object.keys(obj).filter(key => keys.indexOf(key) >= 0).reduce((newObj, key) => Object.assign(newObj, {
    [key]: obj[key]
  }), {});
}
function tagTester(name) {
  return function (obj) {
    return Object.prototype.toString.call(obj) === `[object ${name}]`;
  };
}
const isArray = tagTester('Array');
const isFunction = tagTester('Function');
export function result(obj, path, fallback) {
  if (!isArray(path)) path = [path];
  const length = path.length;
  if (!length) {
    return isFunction(fallback) ? fallback.call(obj) : fallback;
  }
  for (let i = 0; i < length; i++) {
    let prop = obj == null ? void 0 : obj[path[i]];
    if (prop === void 0) {
      prop = fallback;
      i = length; // Ensure we don't continue iterating.
    }
    obj = isFunction(prop) ? prop.call(obj) : prop;
  }
  return obj;
}
let idCounter = 0;
export function uniqueId(prefix) {
  const id = `${++idCounter}`;
  return prefix ? prefix + id : id;
}