"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 36584931321;
const slug = exports.slug = 'guides/cms/content/performance/lazy-loading';
const title = exports.title = 'Lazy loading assets for performance';
const name = exports.name = 'Lazy loading assets for performance';
const metaDescription = exports.metaDescription = 'Lazy loading assets is the act of deferring the loading of the assets until the time that they are actually needed. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "lazy-loading-images",
  "label": "Lazy loading images",
  "parent": null
}, {
  "depth": 1,
  "id": "browser-compatibility",
  "label": "Browser Compatibility",
  "parent": "lazy-loading-images"
}, {
  "depth": 1,
  "id": "add-lazy-loading-to-image-fields-using-the-cli",
  "label": "Add lazy loading to Image fields using the CLI",
  "parent": "lazy-loading-images"
}, {
  "depth": 1,
  "id": "add-lazy-loading-to-image-and-logo-fields-in-hubspot",
  "label": "Add lazy loading to image and logo fields in HubSpot",
  "parent": "lazy-loading-images"
}, {
  "depth": 2,
  "id": "image-loading",
  "label": "Image loading",
  "parent": "lazy-loading-images"
}, {
  "depth": 2,
  "id": "available-loading-options",
  "label": "Available loading options",
  "parent": "lazy-loading-images"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      img: "img",
      h4: "h4",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Lazy loading assets for performance"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lazy loading assets allows you to defer the loading of the assets until the time when they are actually needed. On the web, this often means downloading the designated content only once the user has gotten sufficiently close to where in the HTML document the asset displays. This technique is one of many suggested for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "optimizing page performance"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lazy loading images"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Lazy loading options are available for the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#image",
          children: "image and logo fields"
        }), " in custom modules for use in HubL tags and also available in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cos-general/use-default-modules-in-your-template#image",
          children: "default image module."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When building custom modules, you have the option to enable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/browser-level-image-lazy-loading/",
        children: "browser built-in lazy loading"
      }), " on image fields. When enabled, you can choose whether to show or hide controls to the content editor enabling them to change the loading behavior in the page editor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Browser Compatibility"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lazy loading of images via the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), " attribute is supported by most of the popular Chromium-powered browsers (Chrome, Edge, Opera) and Firefox. To learn more about what browsers are supported you can visit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=loading-lazy-attr",
        children: "caniuse.com"
      }), ". Browsers that do not support the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), " attribute will simply ignore it without side-effects."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add lazy loading to Image fields using the CLI"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To enable lazy loading of images while building with the CMS CLI, add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "show_loading"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), " keys to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#image",
        children: "image"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#logo",
        children: "logo"
      }), " field in the module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json file\n{\n  \"id\": \"357bacfa-2bb8-e996-4589-f55e10d4f1d4\",\n  \"name\": \"image_field\",\n  \"label\": \"Image\",\n  \"required\": false,\n  \"locked\": false,\n  \"responsive\": true,\n  \"resizable\": true,\n  \"show_loading\": false,\n  \"type\": \"image\",\n  \"default\": {\n    \"size_type\": \"auto\",\n    \"src\": \"\",\n    \"alt\": null,\n    \"loading\": \"disabled\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_loading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controls showing/hiding lazy load controls to the content editor."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determines whether to use lazy loading. Options include: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"disabled\""
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"lazy\""
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"disabled\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then reference these variables in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " file using the following syntax:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set loadingAttr = module.image_field.loading != 'disabled' ? 'loading=\"{{ module.image_field.loading }}\"' : '' %}\n<img src=\"{{ module.image_field.src }}\" alt=\"{{ module.image_field.alt }}\" {{ loadingAttr }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add lazy loading to image and logo fields in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To enable lazy loading, add an image or logo field to your custom module, then navigate to the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Content options"
      }), " section in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/a-quick-tour-of-the-design-manager#inspector",
        children: "Inspector pane"
      }), ". Then use the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Image loading"
      }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Available loading options"
      }), " dropdown menus to configure the image loading behavior."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/lazy-loading/lazy-loading-image-controls-dm.png",
        alt: "Lazy loading controls in the Design Manager"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Image loading"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Image loading"
      }), " option will set the value of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), " attribute in the browser. Options for this include \"Default\" (default option) which is the default browser loading behavior for the asset. When enabling lazy loading, the image will load once the image reaches a certain distance from the viewport as defined in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/browser-level-image-lazy-loading/#distance-from-viewport-thresholds",
        children: "distance-from-viewport threshold"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Available loading options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Available loading options"
      }), " will determine if content editors will be able to see and set the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Image loading"
      }), " option while inside of the page, global, and theme content editor panes. Options for this include ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Do Not Show Controls"
      }), " (default) or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Show all controls"
      }), ". Below is a sample of what the page editor would look like with ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Show all controls"
      }), " selected:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/lazy-loading/lazy-loading-image-controls-page-editor.png",
        alt: "Lazy loading controls on the page editor"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then reference these variables in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " file using the following syntax:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set loadingAttr = module.image_field.loading != 'disabled' ? 'loading=\"{{ module.image_field.loading }}\"' : '' %}\n<img src=\"{{ module.image_field.src }}\" alt=\"{{ module.image_field.alt }}\" {{ loadingAttr }}>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}