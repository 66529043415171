'use es6';

export const EXPLORER_ENABLED = 'x-explorer-enabled';
export const SAMPLES_ENABLED = 'x-samples-enabled';
export const SAMPLES_LANGUAGES = 'x-samples-languages';
export const PROXY_ENABLED = 'x-proxy-enabled';
export const HEADERS = 'x-headers';
export const SEND_DEFAULTS = 'x-send-defaults';
export default {
  'x-explorer-enabled': true,
  'x-samples-enabled': true,
  'x-samples-languages': ['curl', 'node', 'php', 'ruby', 'python', 'csharp'],
  'x-proxy-enabled': true,
  'x-headers': undefined,
  'x-send-defaults': false
};