"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611098;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/hubdb';
const title = exports.title = 'Build dynamic pages using HubDB';
const name = exports.name = 'Build dynamic pages using HubDB';
const metaDescription = exports.metaDescription = 'Learn how to build dynamic website pages using HubDB data.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-create-a-hubdb-table",
  "label": "1. Create a HubDB table",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-create-a-template",
  "label": "2. Create a template",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-create-the-dynamic-page",
  "label": "3. Create the dynamic page",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-view-live-pages",
  "label": "4. View live pages",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-add-a-new-table-row",
  "label": "5. Add a new table row",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-view-dynamic-page-data",
  "label": "6. View dynamic page data",
  "parent": null
}, {
  "depth": 0,
  "id": "more-hubdb-focused-tutorials",
  "label": "More HubDB focused tutorials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Build dynamic pages using HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "Dynamic pages"
      }), " are CMS pages that get their content from a structured data source. Based on how you configure your dynamic page, HubSpot will pull data from the selected source and automatically create a set of pages. This includes a listing page that displays summaries of the data, and individual pages for each data source entry."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB table"
      }), " as a data source, you can create a dynamic page which then generates a page for each row in the table. Each dynamic page includes its own unique, SEO-friendly URL, and offers page-specific analytics."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["You can create up to 10 dynamic pages per data source. For HubDB dynamic pages, this means up to 10 dynamic pages can use the same HubDB table. The number of rows in the table (and therefore child pages) is not factored into this limit. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
            children: "HuDB technical limits"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubDB dynamic pages are limited to 50,000 ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/content-search",
            children: "content search"
          }), " index updates per account per day. Any updates to these pages beyond this limit will not be reflected in content search that day."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This tutorial walks through how to create a set of dynamic pages using HubDB as the data source. To follow this tutorial, you'll need:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          }), " Professional"]
        }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Some prior knowledge of HTML and CSS"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can learn more about building data-based CMS pages in HubSpot Academy's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "CMS Data-Driven Content course"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Create a HubDB table"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a new HubDB table:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create table"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, enter the table ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "label"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the table created, you can set it to be used for dynamic page content:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Manage settings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, click to toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enable creation of dynamic pages using row data"
        }), " switch on."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You can optionally select the meta description, featured image, and canonical URL of the individual dynamic pages. If you leave these values empty, each page will inherit the respective values from its parent page."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " for a page to use the values from the meta description, featured image, and canonical URL columns, the page must include the following ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " HubL variables rather than ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " variables:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.meta_description}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.featured_image_URL}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.canonical_url}}"
          })
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["For example, HubSpot templates pull in their meta description from the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{content.meta_description}}"
        }), " tag by default. You'll instead need to use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{page_meta.meta_description}}"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), " to save your changes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-settings-sidebar-save.png",
        alt: "hubdb-table-settings-sidebar-save"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you update the table settings, the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Page title"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Page path"
      }), " columns will be added to the table."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Page title:"
        }), " the name of this page as seen in the HTML title tag."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Page path:"
        }), " the last segment of the URL for the dynamic page created by each row in the table."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following table is an example modeled after an \"About us\" page for members of a company's executive team. This table will be used to create dynamic pages with paths ending in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cfo-harlow"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ceo-jeff"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cto-bristow"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pd-hugo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Page title"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Page path"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Role"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Bio"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cfo-harlow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This is Harlow, who is generally pennywise."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceo-jeff"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff is the CEO, which means he usually runs things around here."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cto-bristow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This is our CTO, Bristow, who likes to tinker."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Chief PD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pd-hugo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CPD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo, our Chief Product Designer, enjoys designing products."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-hubdb-table.png",
        alt: "example-hubdb-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " though you have to enter page paths as lowercase, the resulting URLs are case insensitive. In the example above, when someone navigates to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/CEO-Jeff"
        }), " they will see the same page as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/ceo-jeff"
        }), " instead of a 404 error."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you're ready to use the data from your table to build out your pages, click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Publish"
      }), " in the top right."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Create a template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Next, you'll create one template for both the listing page and the individual detail pages for each row, similar to how blog templates can be used for both listing and post detail pages. To create the page template:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design Manager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to the folder that you want to create the template in. To create a new folder, in the upper left click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "File"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New folder"
        }), ". Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "File"
        }), ", and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New file"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, use the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "dropdown menu"
        }), " to select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTML + HubL"
        }), " as the file type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/create-html-hubl-template.jpg",
        alt: "Creating a new HTML + HubL Template"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "File name"
        }), " field, enter the name of the template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "File location"
        }), ", you can change where the template is located in your design manager by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Change"
        }), (0, _jsxRuntime.jsx)(_components.em, {
          children: "."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), " to create the template."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a dynamic page is set to use this template and the end of the page URL matches the path column, you can access the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_table_id"
      }), " variables in the template. For example, for building an executive profile page, the code below demonstrates how you can use fields from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " to display an executive's info:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), ": the associated ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Page title"
        }), " for the HubDB row."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": the executive's name."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "role"
        }), ": the executive's role."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Next, you can add handling for the case in which someone loads your dynamic page without any additional paths from your table. Usually, this is used as a listing page, for listing links to the pages for the rows in your HubDB table. Replace your code with:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n{% elif dynamic_page_hubdb_table_id %}\n    <ul>\n    {% for row in hubdb_table_rows(dynamic_page_hubdb_table_id) %}\n        <li><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></li>\n    {% endfor %}\n    </ul>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The code inside the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " block iterates over all the rows in the executive's table and displays each entry in a list, with a link to their unique path."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the design manager, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview"
        }), " to preview the template. The preview will be blank, because it relies on the context of the page to set the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_row"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_table_id"
        }), " variables."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "To test your code at the template level, add the following temporary code to the top of your template, ensuring that you remove it before publishing:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set dynamic_page_hubdb_table_id = <YOUR_HUBDB_TABLE_ID> %}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "After adding the above code, your template should now render a list of hyperlinks, pulling data from the HubDB table you built."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-template-preview.png",
        alt: "hubdb-template-preview"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After previewing the template, remove the temporary code above. Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), " in the top right to make it available for creating pages."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create the dynamic page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a dynamic page from your template:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["With your new template open in the design manager, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Actions"
          }), " dropdown menu at the top of the finder, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create page"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-page-from-design-manager.png",
            alt: "create-page-from-design-manager"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Website page"
          }), ", then enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "page name"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create page"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["At the top of the page editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Settings"
          }), " tab."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Page title"
          }), " field, enter a page name, which you can use later to look up traffic analytics."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " field, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/executives"
          }), ". The URL will be the base URL for your dynamic page."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Advanced Options"
          }), " to expand additional settings."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Scroll down to the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Dynamic pages"
          }), " section, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Data sources"
          }), " dropdown menu. Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "HubDB table"
          }), " you created."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/page%20editor%20hubdb%20dynamic%20.png",
        alt: "Advanced options in page settings for linking to HubDB table"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When you’re finished, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), " in the upper right. Your pages are now ready to view."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. View live pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Now you can visit your new dynamic page and all of its paths, as defined by your HubDB table."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to the dynamic listing page at the URL you set in the page editor. This tutorial uses ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " for its dynamic page URL, so in that case you would navigate to: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.yourdomain.com/executives"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["From the listing page, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "names"
        }), " in the bulleted list to view the details page for that executive."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/2022-11-28_15-55-47%20(1).gif",
        alt: "2022-11-28_15-55-47 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Add a new table row"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your dynamic page loading HubDB data, navigate back to the table and add a new row. After publishing the table, you'll then see your live page dynamically update with the new HubDB data."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the table that you create."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add row"
        }), ", then fill out each column. Below is a sample set of data."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Page title"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Page path"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Role"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Bio"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cmo-hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes is our go-to cat enthusiast."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In another tab, navigate back to the listing page (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " in this example). You should now see the new executive appear on the listing page, and clicking their name will reveal their details page."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. View dynamic page data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once there are visits to your dynamic page, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/analyze-performance-for-individual-pages-and-blog-posts",
        children: "measure individual page performance"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "view all page data in the traffic analytics tool"
      }), ". Even though the individual executive pages are built from the same dynamic page, traffic data, such as page views, will be attributed to each page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view your page visit data in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Reporting"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing Analytics"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Web traffic analysis"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Pages"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["View the table to see traffic data for the individual parent and child pages. Child pages will be denoted with ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "> arrow icons"
          }), " to show their relation to parent pages."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-traffic-data-2.png",
            alt: "example-traffic-data-2"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Keep in mind the following if you're not seeing the traffic data you expect:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you've ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/exclude-traffic-from-your-site-analytics",
          children: "excluded your IP addresses in the account's report settings"
        }), ", ensure that you're accessing your pages from outside your network for your page views to register."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["It can take ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/how-often-do-analytics-in-hubspot-update",
          children: "up to 40 minutes"
        }), " for new page data to appear in HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "More HubDB focused tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "How to join multiple HubDB tables"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "How to build a dynamic team member page with HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "How to add videos to dynamic pages"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "How to build multilevel dynamic pages using HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Let's build a page with a map using HubDB"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}