"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 37833970855;
const slug = exports.slug = 'guides/api/cms/media-bridge';
const title = exports.title = 'CMS API | Media Bridge';
const name = exports.name = 'vNext Docs DP - Media Bridge';
const metaDescription = exports.metaDescription = '';
const position = exports.position = 5;
const toc = exports.toc = [{
  "depth": 0,
  "id": "use-the-media-bridge-api",
  "label": "Use the media bridge API",
  "parent": null
}, {
  "depth": 0,
  "id": "create-and-customize-your-media-object-definitions",
  "label": "Create and customize your media object definitions",
  "parent": null
}, {
  "depth": 0,
  "id": "connect-your-media-bridge-app-to-a-hubspot-user%E2%80%99s-account",
  "label": "Connect your media bridge app to a HubSpot user’s account",
  "parent": null
}, {
  "depth": 0,
  "id": "create-your-media-objects",
  "label": "Create your media objects",
  "parent": null
}, {
  "depth": 0,
  "id": "create-cms-modules-to-embed-media",
  "label": "Create CMS Modules to embed media",
  "parent": null
}, {
  "depth": 1,
  "id": "set-up-an-oembed-domain",
  "label": "Set up an oEmbed domain",
  "parent": "create-cms-modules-to-embed-media"
}, {
  "depth": 0,
  "id": "create-a-custom-module",
  "label": "Create a custom module",
  "parent": null
}, {
  "depth": 0,
  "id": "send-your-media-events",
  "label": "Send your media events",
  "parent": null
}, {
  "depth": 1,
  "id": "connect-an-event-to-a-contact-record",
  "label": "Connect an event to a contact record",
  "parent": "send-your-media-events"
}, {
  "depth": 1,
  "id": "connecting-an-event-to-a-piece-of-media",
  "label": "Connecting an event to a piece of media",
  "parent": "send-your-media-events"
}, {
  "depth": 1,
  "id": "connect-an-event-to-a-page",
  "label": "Connect an event to a page",
  "parent": "send-your-media-events"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Media Bridge API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The media bridge API allows integrators to push media objects such as video and audio files, and media consumption data into HubSpot. It also creates the following features in the user’s HubSpot account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules to embed media objects in HubSpot’s drag and drop editors for pages and emails."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CRM timeline events that show when prospects or customers have engaged with videos, audio, and other media types."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Segmented lists for targeted and personalized experiences."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Workflows to automate interactions based on media consumption events."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Reports to measure the impact of media assets."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The media bridge uses both ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom objects"
      }), " and unified events, HubSpot’s events tracking system. This means you can use both the media bridge API and the custom objects API to build your integration."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use the media bridge API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You need a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "HubSpot developer account"
      }), " to register your media bridge app and set up your initial media object definitions before connecting your app to a HubSpot user’s account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create and customize your media object definitions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To define a media object, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/object-definitions"
      }), ". You will use the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaTypes"
      }), " parameter to define the object: ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "DOCUMENT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "OTHER"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After defining your media objects, create and modify the media object properties by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/schemas/{objectType}"
      }), " and a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/properties/{objectType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any API calls made will include your developer account ID as the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "portalID"
      }), " query parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Connect your media bridge app to a HubSpot user’s account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To connect your media bridge app to a HubSpot user’s account, you must create an app definition in your HubSpot developer’s account for it. App definitions include:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Details such as the logo and text to be shown to the HubSpot user when your integration attempts to make an initial connection to their account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Scopes your integration needs in the user’s HubSpot account."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To connect your media bridge app to a HubSpot user's account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "application definition"
        }), " in your developer account for the media bridge app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include the following scopes when defining your application:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/oauth-tokens",
          children: "OAuth"
        }), " authentication when authenticating calls made by your app. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/intro-to-auth",
          children: "authentication methods"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To verify the app is installed correctly in a customer's portal:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Visit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/media-bridge-demo/{HubID}"
        }), ", replacing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{HubID}"
        }), " with the account ID."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App"
        }), " dropdown menu and select your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "media bridge app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the app, you can view the app’s supported media types and create example media."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once the media bridge app has been installed in a customer’s portal, you can:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-your-media-objects",
          children: "Create media objects"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-cms-modules-to-embed-media",
          children: "Create CMS Modules for embedding your media objects"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#send-your-media-events",
          children: "Send media events"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create your media objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After creating your media object definitions and installing your media bridge app in a user’s account, you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth token"
      }), " to create and modify media objects in the account. Since media objects are custom objects, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects#tab-2",
        children: "custom objects API endpoints"
      }), " to create them:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/media-bridge/v1/{appId}/settings/object-definitions/{mediaType}"
        }), " to find the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{objectType}"
        }), " to create the media object in the user’s account."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A media object represents a piece of embeddable content in a third-party system. Once a media object is added to the media bridge, it can be embedded in HubSpot’s CMS content and associated with media events."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), " media objects, the tables below list out all of the default and required properties (* denotes required):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An id used to identify the specific piece of media in HubSpot’s media bridge system. This is autogenerated by HubSpot and cannot be set by developers."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The duration of the media in milliseconds."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A URL that must return a valid oEmbed response that follows the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed spec."
            }), " Requires ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " type with an iframe in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL of the raw media file. This may be used in the future to help support social embedding."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL of an image used as the thumbnail for embedding the media into content. The ideal size for this thumbnail is 640x480 pixels."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL of an image representing the media. This image should be the same dimensions as the original media, and may be used in places where an image placeholder is needed (for example, when the media is inserted in an email)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The id of the media in the third party’s system. This gives integrators the ability to fetch media from the media bridge based on the same id that they use in their own system. (This is the API endpoint that leverages this mapping)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A provider-supplied path to the object, intended to represent the object’s location in the third party’s folder system (if any). HubSpot will attempt to represent this directory structure when displaying these objects to the user, but may nest each provider’s objects and folders within a top-level folder named after the provider."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the media. This will be shown inside of the HubSpot UI in places such as the media picker."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL that allows a user to view or interact with the media in the media provider’s system. This is used in the HubSpot UI to give users the ability to identify the media without relying on just the title."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), " media objects, the tables below list out all of the default and required properties (* denotes required):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An id used to identify the specific piece of media in HubSpot’s media bridge system. This is autogenerated by HubSpot, and cannot be set by developers."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A URL that must return a valid oEmbed response that follows the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed spec."
            }), " Requires ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " type with an iframe in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL of the raw media file. This may be used in the future to help support social embedding."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL to an image that will be used as the thumbnail for embedding the media into content in places such as the media picker. The ideal size for this thumbnail is 640x480 pixels."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL of an image representing the media. This image should be the same dimensions as the original media, and may be used in places where an image placeholder is needed (for example, when the media is inserted in an email)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The id of the media in the third party’s system. This gives integrators the ability to fetch media from the media bridge based on the same id that they use in their own system. (This is the api endpoint that leverages this mapping)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A provider-supplied path to the object, intended to represent the object’s location in the third party’s folder system (if any). HubSpot will attempt to represent this directory structure when displaying these objects to the user, but may nest each provider’s objects and folders within a top-level folder named after the provider."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the media. This will be shown inside of the HubSpot UI in places such as the media picker."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL that allows a user to view or interact with the media in the media provider’s system. This is used in the HubSpot UI to give users the ability to identify the media without relying on just the title."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create CMS Modules to embed media"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each media bridge app provider is responsible for creating their own ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "module"
      }), " to render their media in the HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a media bridge app is installed in a HubSpot account, the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#embed",
        children: "Embed field"
      }), " on the module has an additional ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Media integration"
      }), " source type. This allows the user to select media from the installed app to be embedded on their website page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After the user selects a piece of media to be embedded, the media’s ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_url"
      }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_response"
      }), " are available in HubL to easily render players. Additionally, the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "id"
      }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "media_type"
      }), " of selected media are stored to enable querying for the underlying CRM object via the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crm_objects"
      }), " HubL function. This can be used to fetch any or all of the properties that are part of a media object."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["An example use of the crm_objects HubL function with a media object where the ids are ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "459"
      }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "922"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set objects = crm_objects(\"a123_Videos\", [459,922]) %} {{ objects }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fetch a specific image with the same object: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set object = crm_object(\"a123_Images\", 459) %} {{ object }}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Apps can fetch the object type (“a123_Videos” in the example) by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/{appId}/settings/object-definitions/{mediaType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Developers should use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/source-code",
        children: "CMS Source Code API"
      }), " endpoints to push their custom module code into customers' accounts once customers have connected via oAuth. Once the module code is pushed into the customer’s account, they will automatically be able to start using the developer’s module in their content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set up an oEmbed domain"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#oembed",
        children: "oEmbed"
      }), " HubL function, the domain being used to fetch the oEmbed response must be registered by making a request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/oembed-domains"
      }), ". The following parameters must be included:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Scheme:"
          }), " the URL pattern for the media URLs. This URL pattern is used to match the URL passed into the oEmbed HubL function to your oEmbed API. Wildcard values are supported using ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "*"
          }), " (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "www.domain.com/*"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL:"
          }), " the URL of your oEmbed API. The media URL is passed to this URL via a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "URL"
          }), " parameter."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Discovery (Boolean):"
          }), " determines whether or not your oEmbed API supports oEmbed’s ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://oembed.com/#section4",
            children: "Discovery"
          }), " feature."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By default, the oEmbed domains registered will be made available to all customers who have installed your app. If you have custom domains that are unique to each customer, you can specify which account an oEmbed domain should be allowed to be used in by passing a portalId value into the API request when setting up the oEmbed domain. This will ensure that only the specified HubSpot account can use that oEmbed domain."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a custom module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a custom module:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design Tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the upper left, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "File"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New file"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "What would you like to build today?"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Module"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the checkbox next to each type of content where your module will be used: ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "pages"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "blog posts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "blog listings"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "emails"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "quotes"
        }), ". Modules used in email templates cannot include CSS or JavaScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select whether this module will be a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "local module"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "global module"
        }), ". If you create a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/design-manager-user-guide-v2/how-to-use-global-content-across-multiple-templates",
          children: "global module"
        }), ", editing this module's content will update every location where the module is used."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "file name"
        }), " for your module, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Fields"
        }), " section on the right, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add field"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Embed"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/_cARgVZZLUD-nsJUIaG8XMUdSm1GRLr4Gfw96HeZ6SyXFG0t6yiiPKt5ToSe7nq8arSnG569_wg5X2XsD0XEtmEGiX9sbQxH7deUIJKGshXamME6vXrLN2oVSAt-cmrkTN2nggDsdkChDnEOxKrw5mo",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Supported source types"
          }), " section, select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Media integration"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Default embed content"
          }), " section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Select from [media bridge app]"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right panel, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "media"
          }), " you want to embed in the module."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Set any of the editor options, field display conditions, and field repeater options."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "HubL variable name"
          }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copy"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copy snippet"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Paste the snippet into the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "module.html"
          }), " section."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["To preview how the module will look on your page, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Preview"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Select from [media bridge app]"
          }), ", then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "media"
          }), " you want to preview."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Send your media events"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A media event is an event that happens in relation to a media object, like a play event. Once a media event is sent to the media bridge app, it can be used in reports and in timeline CRM cards."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are three types of media events:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Played event:"
          }), " represents when a user begins playing a piece of media."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Quartile event:"
          }), " represents when a user has reached quarterly milestones (0%, 25%, 50%, 75%, 100%) in the piece of media they’re viewing."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Attention span event"
          }), ": represents when a user has fully consumed a piece of media, or once the user has completed their session."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Events can be sent by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played-percent"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/attention-span respectively"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For media events to be displayed on the user’s contact timeline in HubSpot, a ", (0, _jsxRuntime.jsx)("u", {
        children: "played"
      }), " event must be sent to the media bridge app for every session. Events from a single session will be shown in one card on the contact activity timeline."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When events are sent using the v2 event endpoints, they are processed asynchronously, unlike those sent via the v1 endpoints. As such, we recommend the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The v1 version of the endpoints should be used for any testing as an erroneous request will immediately error out."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The v2 version of the endpoints should be used in production as its asynchronous nature will help prevent delays in the client while the event is being written to the media bridge. Events are also retained and retried in case of a temporary failure in the media bridge’s server."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Connect an event to a contact record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To connect a media event to a contact record, you must provide either the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " or a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), ". If only a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), " is provided, it will be converted into a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), ". If both are provided in the request, the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " will be used as the source of truth. This parameter allows the media bridge app to create an association between the contact record and the event."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once a media event has been connected to a contact record, the event can be used in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-reports-with-the-custom-report-builder",
        children: "cross-object reports"
      }), ". This allows customers to tie their media events to contact records, as well as associated companies and deals."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Connecting an event to a piece of media"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate a media event to a piece of media, either the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "externalID"
      }), " parameters must be included in the request. If both are provided, the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " will be used as the source of truth."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Connect an event to a page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To associate a media event to a HubSpot page, the following parameters must be included in the request:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If the page is hosted on the HubSpot CMS, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageId"
        }), " must be provided."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If the page is ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " hosted on the HubSpot CMS, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageName"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageUrl"
        }), " must be included."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The table below outlines supported properties for the three media events:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Event Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The id of the media that this event is related to."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The id of the media in the third party’s system. This gives developers the ability to refer to media in the media bridge based on the same id that they use in their own system. This can be used instead of the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " in events. If both an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " are provided, the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " will be used and the externalId will be ignored.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sessionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A unique identifier to represent a viewing session. This can mean different things to different providers, and HubSpot is letting providers decide what a session means to them. This will be used to group events that happened in the same session together. This is expected to be generated by the third party’s system."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the contact in HubSpot’s system that consumed the media. This can be fetched using ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/objects/contacts/v1#get-a-contact-by-its-user-token",
              children: "HubSpot's Get contact by usertoken (utk) API"
            }), ". The API also supports supplying a usertoken, and will handle converting this into a contact ID automatically."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactUtk"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The usertoken (utk) that identifies which contact consumed the media."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The content Id of the page that an event happened on."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name or title of the page that an event happened on."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL of the page that an event happened on."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "All Events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The timestamp at which this event occurred, in milliseconds since the epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString / attentionSpanMap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attention Span"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["This is the raw data which provides the most granular data about spans of the media, and how many times each span was consumed by the user. Example: consider a 10 second video where each second is a span. If a visitor watches the first 5 seconds of the video, then restarts the video and watches the first 2 seconds again, the resulting ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString"
            }), " would be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "“0=2;1=2;2=1;3=1;4=1;5=0;6=0;7=0;8=0;9=0;”"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attention Span"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The percent of the media that the user consumed. Providers may calculate this differently depending on how they consider repeated views of the same portion of media. For this reason, the API will not attempt to validate totalPercentWatched against the attention span information for the event. If it is missing, HubSpot will calculate this from the attention span map as follows: (number of spans with a value of 1 or more)/(Total number of spans)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalSecondsPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attention Span"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The seconds that a user spent consuming the media. The media bridge calculates this as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            }), "*", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaDuration"
            }), ". If a provider would like this to be calculated differently, they can provide the pre-calculated value when they create the event"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "playedPercent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quartile Event"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A quartile percent value (0, 25, 50, 75, 100) for how much of the media has been consumed so far."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "iframeUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Played Event"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL that can be used to display data from an external system using an iFrame. When included, the event on the contact timeline will display a link that will open a modal window displaying the iFrame contents when clicked."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The media type that the event belongs to (for example, VIDEO or AUDIO) This allows us to properly assign the event to the correct objects when a single provider has support for multiple media types.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}