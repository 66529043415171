"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377948;
const slug = exports.slug = 'guides/api/analytics-and-events/event-analytics';
const title = exports.title = 'Events-API | Event-Analytics';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Web Analytics';
const metaDescription = exports.metaDescription = 'Der Event-Analytics-Endpunkt ermöglicht es Ihnen, Events zu finden und zu filtern, die einem CRM-Objekt beliebigen Typs zugeordnet sind. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "einzelne-event-abschl%C3%BCsse-abfragen",
  "label": "Einzelne Event-Abschlüsse abfragen",
  "parent": null
}, {
  "depth": 1,
  "id": "nach-objekt-id-ausw%C3%A4hlen",
  "label": "Nach Objekt-ID auswählen",
  "parent": "einzelne-event-abschl%C3%BCsse-abfragen"
}, {
  "depth": 1,
  "id": "nach-objekteigenschaft-ausw%C3%A4hlen",
  "label": "Nach Objekteigenschaft auswählen",
  "parent": "einzelne-event-abschl%C3%BCsse-abfragen"
}, {
  "depth": 0,
  "id": "abfragen-von-und-filtern-nach-event-typen",
  "label": "Abfragen von und Filtern nach Event-Typen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Event-Analytics"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die Event-Analytics API, um mit CRM-Datensätzen aller Typen verknüpfte Events abzurufen (nur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "). Dazu gehören Standard-Events, z. B. Aufrufe von Website-Seiten und E-Mail-Öffnungen sowie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "benutzerdefinierte Events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie beispielsweise diese API, um eine Chronik der Interaktionen zu sehen, die ein Kontakt mit Ihren Inhalten hatte. Sie können diese Chronik dann nutzen, um einen Datensatz für benutzerdefinierte Analytics zu erstellen oder eine Kontaktchronik in einer externen Anwendung anzuzeigen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einzelne Event-Abschlüsse abfragen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese API gibt Events für jeweils einen CRM-Datensatz zurück. Sie können den Datensatz auswählen, indem Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " angeben und entweder den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), "-Abfrageparameter hinzufügen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nach Objekt-ID auswählen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Datensatz anhand seiner ID anzugeben, fügen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "-Abfrageparameter hinzu. Um beispielsweise einen Kontaktdatensatz mit der ID ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "2832"
      }), " anzugeben, müssen Sie die folgende ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage vornehmen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectId=224834"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nach Objekteigenschaft auswählen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Datensatz anhand einer eindeutigen Eigenschaft anstelle der Kontakt-ID anzugeben, fügen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), "-Parameter hinzu. Verweisen Sie auf die Eigenschaft, indem Sie den Eigenschaftsnamen und den Wert im folgendem Format einschließen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty.{propname}={propvalue}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um beispielsweise einen Kontakt anhand seiner E-Mail-Adresse anzugeben, müssen Sie die folgende ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage vornehmen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectProperty.email=user12@dev.com"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abfragen von und Filtern nach Event-Typen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beim Abfragen der Events, die einem bestimmten CRM-Objekt zugeordnet sind, umfasst die Antwort alle Event-Typen, einschließlich benutzerdefinierter verhaltensorientierter Events."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um nur Event-Abschlüsse zurückzugeben für einen bestimmten Event-Typ, können Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), "-Parameter gefolgt vom Event-Namen einschließen. Um eine Liste aller verfügbaren Event-Typen abzurufen, können Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/event-types"
      }), " durchführen. Die Antwort gibt alle Event-Typen in Ihrem Account zurück. Sie können dann einen dieser Event-Namen als Abfrageparameter in einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage den Endpunkt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId=224834"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}