"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61045964892;
const slug = exports.slug = 'guides/api/crm/engagements/meetings';
const title = exports.title = 'Engajamentos | Reuniões';
const name = exports.name = 'Engajamentos | Reuniões';
const metaDescription = exports.metaDescription = 'Use a API de engajamento de reuniões para registrar e gerenciar reuniões nos registros do CRM. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-uma-reuni%C3%A3o",
  "label": "Criar uma reunião",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades",
  "label": "Propriedades",
  "parent": "criar-uma-reuni%C3%A3o"
}, {
  "depth": 1,
  "id": "associa%C3%A7%C3%B5es",
  "label": "Associações",
  "parent": "criar-uma-reuni%C3%A3o"
}, {
  "depth": 0,
  "id": "recuperar-reuni%C3%B5es",
  "label": "Recuperar reuniões",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-reuni%C3%B5es",
  "label": "Atualizar reuniões",
  "parent": null
}, {
  "depth": 1,
  "id": "associar-reuni%C3%B5es-existentes-a-registros",
  "label": "Associar reuniões existentes a registros",
  "parent": "atualizar-reuni%C3%B5es"
}, {
  "depth": 1,
  "id": "remover-uma-associa%C3%A7%C3%A3o",
  "label": "Remover uma associação",
  "parent": "atualizar-reuni%C3%B5es"
}, {
  "depth": 0,
  "id": "fixar-uma-reuni%C3%A3o-em-um-registro",
  "label": "Fixar uma reunião em um registro",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-reuni%C3%B5es",
  "label": "Excluir reuniões",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Reuniões"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a API de engajamento de reuniões para registrar e gerenciar reuniões nos registros do CRM. Você pode registrar atividades de reunião ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "no HubSpot"
      }), " ou por meio da API de reuniões. Você pode recuperar, atualizar ou excluir engajamentos de reunião que são incluídos manualmente em um registro, agendados usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/meetings-tool/use-meetings",
        children: "ferramenta de reuniões"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/schedule-a-meeting-with-a-contact-in-a-record",
        children: "agendados usando a integração de calendário do Google Agenda ou do Office 365"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abaixo, aprenda os métodos básicos de gerenciamento de reuniões por meio da API. Para visualizar todos os pontos de extremidade disponíveis e seus requisitos, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar uma reunião"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um engajamento de reunião, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo da solicitação, adicione detalhes da reunião em um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propriedades"
      }), ". Você também pode adicionar um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associações"
      }), " para associar sua nova reunião a um registro existente (por exemplo, contatos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No objeto de propriedades, você pode incluir os seguintes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Obrigatório. Este campo marca a data e a hora em que a reunião ocorreu. Você pode usar um carimbo de data e hora Unix em milissegundos ou no formato UTC.(BETA) Quando o valor da propriedade estiver ausente, será usado o valor padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O título da reunião."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID do proprietário"
            }), " associado à reunião. Este campo determina o usuário listado como o criador da reunião na linha do tempo do registro."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A descrição da reunião."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_internal_meeting_notes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "As observações internas que você faz para sua equipe durante uma reunião que não estão incluídas na descrição da reunião do participante."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_external_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL externo do evento de calendário. Por exemplo, pode ser um link de calendário do Google ou do Microsoft Outlook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Onde a reunião acontecerá. O valor pode ser um endereço físico, uma sala de conferências, um link de videoconferência ou um número de telefone. Isso aparece no convite no calendário do participante."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A data e hora de início da reunião. O valor dessa propriedade deve corresponder ao valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_end_time"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A data e hora de término da reunião."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_outcome"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O resultado da reunião. Os valores de resultado são programado, concluído, reprogramado, não compareceu e cancelado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de reunião. As opções se baseiam nos ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "tipos de reunião definidos na sua conta da HubSpot."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os IDs dos anexos da reunião. Vários IDs de anexo são separados por ponto e vírgula."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar e associar uma reunião a registros existentes, inclua um objeto de associações na solicitação. Por exemplo, para criar e associar uma reunião a contatos, a solicitação pode ser semelhante à seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-23T01:02:44.872Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_meeting_title\": \"Intro meeting\",\n    \"hs_meeting_body\": \"The first meeting to discuss options\",\n    \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n    \"hs_meeting_external_url\": \"https://Zoom.com/0000\",\n    \"hs_meeting_location\": \"Remote\",\n    \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n    \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n    \"hs_meeting_outcome\": \"SCHEDULED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 102\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O objeto de associações deve incluir:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O registro que deseja associar à reunião, especificado por seu valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " exclusivo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de associação entre a reunião e o registro. Inclua ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Os IDs de tipo de associação padrão são listados ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aqui"
            }), ", ou você pode recuperar o valor de tipos de associação personalizados (ou seja, rótulos) por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre a criação de reuniões em lote clicando na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar reuniões"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode recuperar reuniões individualmente ou em massa. Saiba mais sobre a recuperação em massa clicando na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar uma reunião individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), ". Você também pode incluir os seguintes parâmetros no URL da solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas dos objetos dos quais você deseja recuperar os IDs de registro associados."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar uma lista de todas as reuniões, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/meetings"
      }), ". Você pode incluir os seguintes parâmetros na URL da solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número máximo de resultados a serem exibidos por página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar reuniões"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atualizar reuniões individualmente ou em massa. Para atualizar uma reunião individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo da solicitação, inclua as propriedades de reunião que deseja atualizar. Por exemplo, o corpo da sua solicitação pode ser parecido com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/meetings/{meetingId}\n{\n  \"properties\": {\n     \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n     \"hubspot_owner_id\": \"11349275740\",\n     \"hs_meeting_title\": \"Intro meeting\",\n     \"hs_meeting_body\": \"The first meeting to discuss options\",\n     \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n     \"hs_meeting_external_url\":\n     \"https://Zoom.com/0000\",\n     \"hs_meeting_location\": \"Remote\",\n     \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n     \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n     \"hs_meeting_outcome\": \"SCHEDULED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot ignorará valores para propriedades somente leitura e inexistentes. Para limpar um valor de propriedade, passe uma string vazia para a propriedade no corpo da solicitação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a atualização em massa clicando na guia** Pontos de extremidade** na parte superior deste artigo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar reuniões existentes a registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar uma reunião a registros, como um contato e suas empresas associadas, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". A URL da solicitação contém os campos a seguir:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meetingId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do proprietário da reunião."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O tipo de objeto ao qual você deseja associar a reunião (por exemplo, contato ou empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do registro ao qual você deseja associar a reunião."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do tipo de associação entre a reunião e o outro tipo de objeto. Você pode recuperar esse valor por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, a URL da sua solicitação pode ser parecida com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/meetings/17612479134/associations/contact/104901/200"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remover uma associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para remover uma associação entre uma chamada e um registro, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para o mesmo URL acima:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fixar uma reunião em um registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "fixar uma reunião"
      }), " em um registro para que permaneça no topo da linha do tempo do registro. A chamada já deve estar associada ao registro antes da fixação e você só pode fixar uma atividade por registo. Para fixar uma reunião, inclua o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " da reunião no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " ao criar ou atualizar um registro por meio das APIs de objeto. Saiba mais sobre como usar as APIs de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contatos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negócios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir reuniões"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode excluir reuniões individualmente ou em massa, o que adicionará a reunião à lixeira no HubSpot. Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurar posteriormente a reunião da linha do tempo do registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir uma reunião individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a exclusão em massa clicando na guia** Pontos de extremidade** na parte superior deste artigo."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}