"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694148;
const slug = exports.slug = 'guides/cms/content/website-settings';
const title = exports.title = 'Configurações de website';
const name = exports.name = '[novo] Configurações do site';
const metaDescription = exports.metaDescription = 'As configurações do site são um local central onde várias configurações de conteúdo globais e em nível de sistema podem ser definidas para blog, navegação, páginas e temas do seu site.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg';
const featuredImageAltText = exports.featuredImageAltText = 'Área de configurações do site';
const toc = exports.toc = [{
  "depth": 0,
  "id": "configura%C3%A7%C3%B5es-do-blog",
  "label": "Configurações do blog",
  "parent": null
}, {
  "depth": 1,
  "id": "guia-geral",
  "label": "Guia Geral",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "nome-do-blog",
  "label": "Nome do blog",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "cabe%C3%A7alho-do-blog",
  "label": "Cabeçalho do blog",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "t%C3%ADtulo-da-p%C3%A1gina",
  "label": "Título da página",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "metadescri%C3%A7%C3%A3o",
  "label": "Metadescrição",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "url-raiz-do-blog",
  "label": "URL raiz do blog",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "controlar-o-acesso-do-p%C3%BAblico",
  "label": "Controlar o acesso do público",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 1,
  "id": "guia-modelos",
  "label": "Guia Modelos",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "modelo-atual",
  "label": "Modelo atual",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "modelos-para-p%C3%A1ginas-de-listagem-opcional",
  "label": "Modelos para páginas de listagem (opcional)",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "n%C3%BAmero-de-posts-por-p%C3%A1gina-de-listagem",
  "label": "Número de posts por página de listagem",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "html-do-cabe%C3%A7alho",
  "label": "HTML do cabeçalho",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "html-de-rodap%C3%A9",
  "label": "HTML de rodapé",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 1,
  "id": "guia-assinaturas",
  "label": "Guia Assinaturas",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "e-mails-de-notifica%C3%A7%C3%A3o-de-assinante-de-blog",
  "label": "E-mails de notificação de assinante de blog",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "feed-rss",
  "label": "Feed RSS",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 1,
  "id": "guia-formatos-de-data",
  "label": "Guia Formatos de data",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "idioma-das-datas",
  "label": "Idioma das datas",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "formato-da-data-de-publica%C3%A7%C3%A3o",
  "label": "Formato da data de publicação",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "formato-de-posts-por-m%C3%AAs",
  "label": "Formato de posts por mês",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 1,
  "id": "guia-coment%C3%A1rios",
  "label": "Guia Comentários",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "ativar-ou-desativar-os-coment%C3%A1rios-do-blog",
  "label": "Ativar ou desativar os comentários do blog",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "notifica%C3%A7%C3%B5es-de-coment%C3%A1rios-do-blog",
  "label": "Notificações de comentários do blog",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 1,
  "id": "guia-compartilhamento-social",
  "label": "Guia Compartilhamento social",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "conta-padr%C3%A3o-do-twitter",
  "label": "Conta padrão do Twitter",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "bot%C3%B5es-de-compartilhamento-social",
  "label": "Botões de compartilhamento social",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 1,
  "id": "guia-google-amp",
  "label": "Guia Google AMP",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "ativar-ou-desativar-o-google-amp",
  "label": "Ativar ou desativar o Google AMP",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 2,
  "id": "configura%C3%A7%C3%B5es-espec%C3%ADficas-de-amp-para-um-logotipo%2C-formata%C3%A7%C3%A3o-do-cabe%C3%A7alho",
  "label": "Configurações específicas de AMP para um logotipo, formatação do cabeçalho",
  "parent": "configura%C3%A7%C3%B5es-do-blog"
}, {
  "depth": 0,
  "id": "configura%C3%A7%C3%B5es-de-navega%C3%A7%C3%A3o",
  "label": "Configurações de navegação",
  "parent": null
}, {
  "depth": 0,
  "id": "configura%C3%A7%C3%B5es-de-p%C3%A1gina",
  "label": "Configurações de página",
  "parent": null
}, {
  "depth": 1,
  "id": "guia-modelos",
  "label": "Guia Modelos",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "html-do-cabe%C3%A7alho-do-site",
  "label": "HTML do cabeçalho do site",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "html-do-rodap%C3%A9-do-site",
  "label": "HTML do rodapé do site",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "jquery",
  "label": "jQuery",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "guia-branding",
  "label": "Guia Branding",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "favicon",
  "label": "Favicon",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "logotipo-texto-alternativo%2C-dimens%C3%B5es%2C-link",
  "label": "Logotipo (texto alternativo, dimensões, link)",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "guia-personaliza%C3%A7%C3%A3o",
  "label": "Guia Personalização",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "padr%C3%B5es-de-contato-e-empresa",
  "label": "Padrões de Contato e Empresa",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "guia-integra%C3%A7%C3%B5es",
  "label": "Guia Integrações",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "integra%C3%A7%C3%B5es-js",
  "label": "Integrações JS",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "guia-seo-e-rastreadores",
  "label": "Guia SEO e rastreadores",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "urls-can%C3%B4nicas",
  "label": "URLs canônicas",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "dom%C3%ADnio-de-hospedagem-de-arquivos-padr%C3%A3o",
  "label": "Domínio de hospedagem de arquivos padrão",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "robots.txt",
  "label": "Robots.txt",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "guia-p%C3%A1ginas-do-sistema",
  "label": "Guia Páginas do sistema",
  "parent": "configura%C3%A7%C3%B5es-de-p%C3%A1gina"
}, {
  "depth": 0,
  "id": "p%C3%A1ginas-de-erro-404-e-500",
  "label": "Páginas de erro 404 e 500",
  "parent": null
}, {
  "depth": 0,
  "id": "p%C3%A1gina-de-solicita%C3%A7%C3%A3o-de-senha",
  "label": "Página de solicitação de senha",
  "parent": null
}, {
  "depth": 2,
  "id": "url-e-p%C3%A1gina-de-resultados-de-pesquisa",
  "label": "URL e página de resultados de pesquisa",
  "parent": "p%C3%A1gina-de-solicita%C3%A7%C3%A3o-de-senha"
}, {
  "depth": 0,
  "id": "configura%C3%A7%C3%B5es-de-tema",
  "label": "Configurações de tema",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      h4: "h4",
      code: "code",
      em: "em",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurações do site"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As configurações do site são um local central onde várias configurações de conteúdo globais e em nível de sistema podem ser definidas para blog, navegação, páginas e temas do seu site. Navegue até ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/",
        children: "Configurações > Site"
      }), " e escolha a área de conteúdo para a qual deseja acessar as Configurações de conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurações do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nesta área, você controlará as configurações de conteúdo do(s) seu(s) blog(s). Se você tiver vários blogs, poderá alternar entre eles usando o menu suspenso no título \"Selecione um blog para modificar\"."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg",
        alt: "Tela de configurações do blog"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Geral"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Nome do blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As variáveis HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ content.blog }}"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.public_title }}"
      }), " renderizarão o nome definido aqui."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Cabeçalho do blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A variável HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.header }}"
      }), " renderizará o cabeçalho definido aqui."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Título da página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A variável HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.html_title }}"
      }), " renderizará o título definido aqui."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Metadescrição"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A variável HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.description }}"
      }), " renderizará a descrição definida aqui. Esta meta descrição será usada em páginas de listagem de blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL raiz do blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A URL raiz do blog precederá os slugs individuais dos posts de blog. A variável HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.absolute_url }}"
      }), " renderizará o URL definida aqui."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Controlar o acesso do público"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode controlar o acesso do público a todo o blog por meio desta configuração. Mais informações sobre isso ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Modelos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modelo atual"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este é o modelo usado para todos os posts em um blog específico. O mesmo modelo também será usado em páginas de listagem de blog por padrão. O conteúdo variável das páginas de listagem versus posts pode ser escrito no módulo de Conteúdo de posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modelos para páginas de listagem (opcional)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta configuração permite usar um modelo diferente para páginas de listagem de blog que não seja o modelo usado para posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Número de posts por página de listagem"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso determina o número de posts que aparecem em uma página de listagem de blog por padrão."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML do cabeçalho"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Qualquer código adicionado ao HTML do cabeçalho da listagem do blog será adicionado a todas as páginas de listagem por meio da variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), ". Qualquer código adicionado ao HTML do cabeçalho do post do blog será adicionado a todos os posts de blog por meio da variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML de rodapé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Qualquer código adicionado ao HTML do rodapé da listagem do blog será adicionado a todas as páginas de listagem por meio da variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), ". Qualquer código adicionado ao HTML do rodapé do post do blog será adicionado a todos os posts de blog por meio da variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Assinaturas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "E-mails de notificação de assinante de blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os e-mails de notificação instantânea, diária, semanal e mensal do blog podem ser ativados e editados por meio dessa configuração. Esses e-mails são enviados automaticamente quando novos posts de blog são publicados no período de tempo especificado. Leia mais ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-blog/set-up-your-blog-subscription-features-in-hubspot",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feed RSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O número de posts no feed RSS do blog pode ser determinado por essa configuração. Há um limite máximo de 50 posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Formatos de data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Idioma das datas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Essa configuração determina o idioma dos meses e dias que aparecem nas datas dos blogs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Formato da data de publicação"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O formato definido aqui determina a ordem e o padrão de datas de publicação nos blogs. Usando o idioma de marcação de dados do local, é possível especificar um formato de data personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Formato de posts por mês"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O formato definido aqui determina a ordem e o padrão dos posts por mês. Usando o idioma de marcação de dados do local, é possível especificar um formato de data personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Comentários"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ativar ou desativar os comentários do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É possível ativar ou desativar os comentários do blog por meio desta configuração. Os comentários podem exigir moderação ou ter um prazo definido, após o qual eles são fechados automaticamente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Notificações de comentários do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os comentários do blog podem acionar notificações de e-mail para os usuários especificados nesta configuração."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Compartilhamento social"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Conta padrão do Twitter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A conta do Twitter especificada aqui será usada para cartões do Twitter quando o conteúdo for compartilhado no Twitter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Botões de compartilhamento social"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os botões de compartilhamento social para Twitter, LinkedIn e Facebook podem ser adicionados automaticamente aos posts do blog por meio dessa configuração."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Google AMP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O AMP (Accelerated Mobile Pages) carrega seu conteúdo instantaneamente. Leia mais ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "aqui"
      }), ". Para carregar o conteúdo com rapidez, é necessário ter uma experiência de página simplificada. O controle de estilo do conteúdo AMP é limitado por esse motivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ativar ou desativar o Google AMP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As páginas formatadas para Google AMP podem ser habilitadas por meio desta configuração. Configurações de logotipo, fonte e cor no AMP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Configurações específicas de AMP para um logotipo, formatação do cabeçalho"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para fornecer conteúdo AMP, é necessário ter um estilo simplificado. Determine estilos específicos do AMP para logotipo, formatação de cabeçalho, fontes e cores por meio dessas configurações."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurações de navegação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode gerenciar seus links e rótulos de menu nesta área. Você pode alternar entre os menus que deseja configurar, escolhendo o menu suspenso e selecionando o menu desejado. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/set-up-your-site-s-navigation-menus",
        children: "Saiba mais sobre como configurar os menus de navegação dos seus sites aqui."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/navigation-settings-screen-4.jpg",
        alt: "Tela de configurações de navegação"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurações de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As configurações são divididas por domínios e os valores padrão podem ser definidos para todos os domínios. As configurações “", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Padrão para todos os domains"
      }), "”” serão exibidas ao navegar para Configurações de páginas. Há um botão no canto superior da tela para exibir e modificar as configurações de subdomínios específicos. As configurações aplicadas a subdomínios específicos substituirão o padrão para todas as configurações de domínios."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Somente usuários com a permissão de marketing “", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "Editar configurações do site”"
      }), " podem acessar e editar as configurações de conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/page-settings-screen-4.jpg",
        alt: "Tela de configurações de página"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Modelos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML do cabeçalho do site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Qualquer código adicionado ao campo HTML do cabeçalho do site nas Configurações de páginas será incluído na variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML do rodapé do site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Qualquer código adicionado ao campo HTML do rodapé do site nas Configurações de páginas será incluído na variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), ". Normalmente, este é um bom lugar para adicionar códigos de rastreamento e outros scripts que não são essenciais para o funcionamento ou a boa aparência do site. Dessa forma, nenhum dos seus modelos ou páginas será afetado negativamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode modificar a versão jQuery que é carregada em sua página nas Configurações de páginas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode optar por carregar o jQuery do rodapé usando esta configuração. Anexar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " ao URL da sua página permitirá testar essa alteração e garantir que ela não afeta negativamente a aparência do site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A opção para desativar o jQuery também está localizada nas Configurações de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Branding"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O conjunto de imagens de logotipo para cada domínio aqui será usado automaticamente no módulo “Logotipo” padrão."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Favicon"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Logotipo (texto alternativo, dimensões, link)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A URL de origem da imagem do favicon pode ser extraído do dicionário ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings"
      }), " e usado em seus arquivos codificados:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if site_settings.favicon_src %}<link rel=\"icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Personalização"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Padrões de Contato e Empresa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Estes são os valores padrão usados para os tokens de personalização nas páginas quando o visitante é desconhecido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Integrações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Integrações JS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Habilite as Integrações JS (como Google Analytics ou AdRoll) para todos os domínios ou selecione os domínios aqui. Se estiver usando outros scripts de rastreamento ou o Google Tag Manager em vez do Google Analytics, esse código deve ser adicionado ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#site-footer-html",
        children: "HTML do rodapé do site"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia SEO e rastreadores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URLs canônicas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Selecione aqui sua configuração de canonicalização padrão para páginas e posts individuais, bem como para páginas de listagem."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Domínio de hospedagem de arquivos padrão"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Controla o domínio em que os ativos do gerenciador de arquivos parecem estar hospedados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Robots.txt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modifique o arquivo robots.txt para cada domínio aqui."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Páginas do sistema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para várias páginas do sistema, você pode selecionar os modelos nas Configurações de páginas. Não existe nenhum editor de página para essas páginas, apenas modelos no Gerenciador de design que são criados com o tipo de modelo \"Sistema\". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Observação"
        })
      }), (0, _jsxRuntime.jsx)(_components.em, {
        children: ": as preferências de e-mail e os modelos de assinatura estão localizados nas Configurações de e-mail e não nas Configurações de páginas."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Páginas de erro 404 e 500"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essas são as páginas que são retornadas para os códigos de status 404 ou 500. Ao criar um novo modelo, selecione o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#error-pages",
        children: "tipo de modelo \"Página de erro\""
      }), " no Gerenciador de design para disponibilizar um modelo para essas páginas do sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Página de solicitação de senha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta é a página que é retornada para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "páginas protegidas por senha"
      }), " ao solicitar que um visitante insira uma senha. Ao criar um novo modelo, selecione o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#password-prompt",
        children: "tipo de modelo \"Página de solicitação de senha\""
      }), " no Gerenciador de design para disponibilizar um modelo para esse tipo de página do sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL e página de resultados de pesquisa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta é a página que lista os resultados de pesquisa para a entrada de consultas no módulo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-do-i-set-up-a-results-page-for-my-search-field-in-hubspot",
        children: "Pesquisa do site"
      }), " da HubSpot. Leia mais sobre como personalizar sua pesquisa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "aqui"
      }), ". Ao criar um novo modelo, selecione o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#search-results-page",
        children: "tipo de modelo \"Página de resultados de pesquisa\""
      }), " no Gerenciador de design para criar um modelo para esse tipo de página do sistema. Você também pode determinar a URL da página de resultados de pesquisa nas Configurações de páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurações de tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aqui, você pode encontrar todos os temas adicionados ao site. Você pode entrar no editor de temas clicando em um dos temas disponíveis no site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/theme-settings-screen-4.jpg",
        alt: "Tela de configurações do tema"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}