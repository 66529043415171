"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611083;
const slug = exports.slug = 'reference/cms/hubl/if-statements';
const title = exports.title = 'If statements';
const name = exports.name = '[new] if statements';
const metaDescription = exports.metaDescription = "A practical guide to HubSpot's HubL if statements for CMS developers.";
const position = exports.position = 9;
const toc = exports.toc = [{
  "depth": 0,
  "id": "basic-if-statement-syntax",
  "label": "Basic if statement syntax",
  "parent": null
}, {
  "depth": 0,
  "id": "using-elif-and-else",
  "label": "Using elif and else",
  "parent": null
}, {
  "depth": 0,
  "id": "unless-statements",
  "label": "Unless statements",
  "parent": null
}, {
  "depth": 0,
  "id": "ifchanged",
  "label": "ifchanged",
  "parent": null
}, {
  "depth": 0,
  "id": "inline-if-statements",
  "label": "Inline if statements",
  "parent": null
}, {
  "depth": 0,
  "id": "ternary-operators",
  "label": "Ternary operators",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      code: "code",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "If Statements"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can include conditional logic in your modules and templates by using HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#basic-if-statement-syntax",
        children: "if statements"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#unless-statements",
        children: "unless statements"
      }), ". If statements often contain HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "supported operators"
      }), " and can be used to execute ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "expression tests"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you're using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#personalization-token",
          children: "personalization tokens"
        }), " within a conditional statement of your email module, you must ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
          children: "enable programmable email for the module"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Information passed via the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/transactional-emails#single-send-api",
          children: "v3"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/single-send-api",
          children: "v4"
        }), " single send APIs will not function within ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if"
        }), " statements, as the templates compile before the information populates."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Basic if statement syntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL uses if statements to help define the logic of a template. The syntax of HubL if statements is very similar to conditional logic in Python. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statements are wrapped in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "statement delimiters"
      }), ", starting with an opening ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statement and ending with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below provides the basic syntax of an if statement, where \"condition\" would be replaced with the boolean rule that you were going to evaluate as being true of false."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if condition %}\n\tIf the condition is true print this to template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you have seen the basic syntax, let's look at a few actual examples of basic if statements. The next examples below show if statements that check to see whether or not a HubL module with the name ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " and whether a variable named ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " are present on a template. Notice that without any operators, the if statement will evaluate whether or not the module is defined in the context of the template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_module\" path=\"@hubspot/rich_text\", label=\"My rich text module\", html=\"Default module text\" export_to_template_context=true %}\n\n{% if widget_data.my_module %}\n\tA module named \"my_module\" is defined in this template.\n{% endif %}\n\n\n{% set my_variable = \"A string value for my variable\" %}\n{% if my_variable %}\n\tThe variable named my_variable is defined in this template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Notice that when evaluating the HubL module, the module name is left in quotes within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statement and while testing the variable no quotes are used around the variable name. In both examples above, the module and the variable exist in the template, so the statements evaluate to print the markup. Please note that these examples are only testing whether the module and variable are defined, not whether or not they have a value."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now let's look at an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statement that evaluates whether a module has a value, instead of evaluating whether it exists on the template. To do this, we need to use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "export_to_template_context"
      }), " parameter. In the example below, if the text module is valued in the content editor, the markup would print. If the module's text field were cleared, no markup would render. If you are working within custom modules, there is a simplified ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.widget_name"
      }), " syntax outlined in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "example here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"product_names\" path=\"@hubspot/text\", label=\"Enter the product names that you would like to render the coupon ad for\", value=\"all of our products\", export_to_template_context=True %}\n\n{% if widget_data.product_names.value %}\n<div class=\"coupon-ad\">\n<h3>For a limited time, get 50% off {{ widget_data.product_names.value}}! </h3>\n</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"coupon-ad\">\n  <h3>For a limited time get 50% off all of our products!</h3>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using elif and else"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statements can be made more sophisticated with additional conditional statements or with a rule that executes when the condition or conditions are false. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " statements allow you to add additional conditions to your logic that will be evaluated after the previous condition. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "else"
        })
      }), " statements define a rule that executes when all other conditions are false. You can have an unlimited number of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " statements within a single if statement, but only one ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " statement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is the basic syntax example of if statement that uses the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "<= operator"
      }), " to check the value of a variable. In this example, the template would print: \"Variable named number is less than or equal to 6.\""]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set number = 5 %}\n\n{% if number <= 2 %}\n\tVariable named number is less than or equal to 2.\n{% elif number <= 4 %}\n\tVariable named number is less than or equal to 4.\n{% elif number <= 6 %}\n\tVariable named number is less than or equal to 6.\n{% else %}\n\tVariable named number is greater than 6.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is one more example that uses a choice module to render different headings for a careers page, based on the department chosen by the user. The example uses the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "== operator"
      }), ", to check for certain predefined values in the choice module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unless statements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " statements are conditionals just like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statements, but they work on the inverse logic. They will render and compile the code between the opening and closing tags, unless the single boolean condition evaluates to true. Unless statements begin with an ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "unless"
        })
      }), " and end with an ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endunless"
        }), "."]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " statements support ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " but not ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example that prints an \"Under construction\" header, unless the rich text field is valued. If the rich text field has content, then that content will display."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_page_content\" path=\"@hubspot/rich_text\", label=\"Enter your page content\", html=\"\" export_to_template_context=true %}\n\n{{ widget_data.my_page_content.html }}\n\n{% unless widget_data.my_page_content.html %}\n<h1>This page is under construction.</h1>\n<h3>Come back soon!</h3>\n{% endunless %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ifchanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to if and unless statements, HubL supports ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ifchanged"
      }), " statements. These statements can be used to only render markup when a variable has changed since a prior invocation of this tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inline if statements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL supports inline ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statements. These can be used to write conditional logic in a concise manner with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "operators and expression tests"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set color = \"Blue\" if is_blue is truthy else \"Red\" %}     // color == \"blue\"\n\n{{ \"Blue\" if is_blue is truthy else \"Red\" }}     // \"Blue\"\n\n{% set dl = true %}\n<a href=\"http://example.com/some.pdf\" {{\"download\" if dl }} >Download PDF</a>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ternary operators"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["It is also possible to use ternary operators to quickly write conditional logic with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#logical",
        children: "operators and expression tests"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// If the variable is_blue is true, output \"blue\", otherwise output\"red\"\n{{ is_blue is truthy ? \"blue\" : \"red\" }}\n\n// Set the variable is_red to false if is_blue is true, otherwise set to true\n{% set is_red = is_blue is truthy ? false : true %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}