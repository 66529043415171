"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61303889553;
const slug = exports.slug = 'guides/api/crm/engagements/email';
const title = exports.title = 'Interaktionen | E-Mail';
const name = exports.name = 'Interaktionen | E-Mail';
const metaDescription = exports.metaDescription = 'Verwenden Sie die E-Mail-Interaktionen-API, um E-Mails in CRM-Datensätzen zu protokollieren und zu verwalten. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-e-mail-erstellen",
  "label": "Eine E-Mail erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "eine-e-mail-erstellen"
}, {
  "depth": 1,
  "id": "schreibgesch%C3%BCtzte-eigenschaften",
  "label": "Schreibgeschützte Eigenschaften",
  "parent": "eine-e-mail-erstellen"
}, {
  "depth": 1,
  "id": "e-mail-header-festlegen",
  "label": "E-Mail-Header festlegen",
  "parent": "eine-e-mail-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "eine-e-mail-erstellen"
}, {
  "depth": 0,
  "id": "e-mails-abrufen",
  "label": "E-Mails abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "e-mails-aktualisieren",
  "label": "E-Mails aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "vorhandene-e-mails-zu-datens%C3%A4tzen-zuordnen",
  "label": "Vorhandene E-Mails zu Datensätzen zuordnen",
  "parent": "e-mails-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "e-mails-aktualisieren"
}, {
  "depth": 0,
  "id": "eine-e-mail-an-einen-datensatz-anpinnen",
  "label": "Eine E-Mail an einen Datensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "e-mails-l%C3%B6schen",
  "label": "E-Mails löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "E-Mail"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die E-Mail-Interaktionen-API, um E-Mails in CRM-Datensätzen zu protokollieren und zu verwalten. Sie können E-Mail-Aktivitäten entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "in HubSpot"
      }), " oder über die E-Mails-API protokollieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden erfahren Sie, wie Sie E-Mails über die API verwalten können. Um alle verfügbaren Endpunkte und deren Anforderungen anzuzeigen, klicken Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine E-Mail erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine E-Mail-Interaktion zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie im Anfragetext E-Mail-Details in einem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), "-Objekt hinzu. Sie können auch ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), "-Objekt hinzufügen, um Ihre neue E-Mail einem vorhandenen Datensatz (z. B. Kontakten, Unternehmen) zuzuordnen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im properties-Objekt können Sie die folgenden Felder einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Erforderlich. Dieses Feld markiert den Zeitpunkt der Erstellung der E-Mail und bestimmt, wo Sie die E-Mail in der Datensatzchronik finden. Sie können entweder einen Unix-Zeitstempel im Millisekunden- oder UTC-Format verwenden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID des zuständigen Mitarbeiters"
            }), ", der der E-Mail zugeordnet ist. Dieses Feld bestimmt den Benutzer, der in der Datensatzchronik als E-Mail-Ersteller aufgeführt ist."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Richtung, in der die E-Mail gesendet wurde Mögliche Werte sind:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            }), ": Die E-Mail wurde vom CRM aus gesendet oder mit der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "BCC-Adresse"
            }), " gesendet und im CRM protokolliert.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INCOMING_EMAIL"
            }), ": Die E-Mail war eine Antwort auf eine protokollierte ausgehende E-Mail. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FORWARDED_EMAIL"
            }), ": Die E-Mail wurde ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/settings/log-email-in-your-crm-with-the-bcc-or-forwarding-address",
              children: "an das CRM weitergeleitet"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Text einer E-Mail, wenn sie von einem CRM-Datensatz gesendet wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Sendestatus der E-Mail. Der Wert kann ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BOUNCED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SCHEDULED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENDING"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), " sein."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Betreffzeile der protokollierten E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Text der E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die IDs der Anhänge der E-Mail. Mehrere Anhang-IDs sind durch ein Semikolon getrennt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Header der E-Mail. Der Wert für diese Eigenschaft wird automatisch in bestimmte schreibgeschützte E-Mail-Eigenschaften übernommen. Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/email#set-email-headers",
              children: "E-Mail-Header festlegen"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das batchweise Erstellen von E-Mail-Interaktionen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schreibgeschützte Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt auch einige schreibgeschützte E-Mail-Eigenschaften, die automatisch von HubSpot ausgefüllt werden. Die Eigenschaften in der folgenden Tabelle werden alle automatisch vom ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), "-Wert übernommen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse des Absenders der E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Vorname des E-Mail-Absenders."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_from_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Nachname des E-Mail-Absenders."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adressen der Empfänger der E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Vornamen der Empfänger der E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_to_lastname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nachnamen der Empfänger der E-Mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie einen E-Mail-Header abrufen, stellen Sie möglicherweise fest, dass sowohl für ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), " als auch ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " Werte vorhanden sind. Diese sind oft identisch, aber da ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), " identifiziert, durch was eine E-Mail tatsächlich gesendet wurde, gibt es Szenarien, in denen die Werte abweichen können. Wird beispielsweise eine E-Mail von einem E-Mail-Alias gesendet, bezieht sich der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "From"
        }), "-Wert auf die tatsächliche E-Mail-Adresse des Benutzers und der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Sender"
        }), "-Wert auf den E-Mail-Alias."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-Mail-Header festlegen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da die schreibgeschützten Eigenschaften automatisch mit Headern ausgefüllt werden, sollten Sie die E-Mail-Header manuell festlegen. Um den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_email_headers"
      }), "-Wert festzulegen, können Sie eine mit JSON-Escape-Zeichen versehene Zeichenfolge mit den folgenden Daten verwenden:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example data\n{\n  \"from\": {\n    \"email\": \"from@domain.com\",\n    \"firstName\": \"FromFirst\",\n    \"lastName\": \"FromLast\"\n  },\n  \"to\": [\n    {\n      \"email\": \"ToFirst ToLast<to@test.com>\",\n      \"firstName\": \"ToFirst\",\n      \"lastName\": \"ToLast\"\n    }\n  ],\n  \"cc\": [],\n  \"bcc\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage zum Erstellen einer E-Mail kann beispielsweise so aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"47550177\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for youremail\",\n    \"hs_email_headers\": \"{\\\"from\\\":{\\\"email\\\":\\\"from@domain.com\\\",\\\"firstName\\\":\\\"FromFirst\\\",\\\"lastName\\\":\\\"FromLast\\\"},\\\"sender\\\":{\\\"email\\\":\\\"sender@domain.com\\\",\\\"firstName\\\":\\\"SenderFirst\\\",\\\"lastName\\\":\\\"SenderLast\\\"},\\\"to\\\":[{\\\"email\\\":\\\"ToFirst+ToLast<to@test.com>\\\",\\\"firstName\\\":\\\"ToFirst\\\",\\\"lastName\\\":\\\"ToLast\\\"}],\\\"cc\\\":[],\\\"bcc\\\":[]}\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um eine E-Mail zu erstellen und bestehenden Datensätzen zuzuordnen, schließen Sie ein associations-Objekt in Ihre Anfrage ein. Um beispielsweise eine E-Mail zu erstellen und sie einem Deal und einem Kontakt zuzuordnen, könnte Ihr Anfragetext wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 601\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 210\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 602\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 198\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im associations-Objekt sollten Sie Folgendes einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz, den Sie der E-Mail zuordnen möchten, angegeben durch ihren eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen E-Mail und Datensatz. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "E-Mails abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können E-Mails einzeln oder mehrere gleichzeitig (batchweise) abrufen. Erfahren Sie mehr über das batchweise Abrufen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine einzelne E-Mail anhand ihrer E-Mail-ID abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), " durch. Sie können auch die folgenden Parameter in der Anfrage-URL berücksichtigen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die zurückgegeben werden sollen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekttypen, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Liste aller E-Mails anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/emails"
      }), " durch. Sie können die folgenden Parameter in der Anfrage-URL berücksichtigen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die maximale Anzahl an Ergebnissen, die pro Seite angezeigt werden können."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die zurückgegeben werden sollen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "E-Mails aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können E-Mails einzeln oder mehrere gleichzeitig (batchweise) aktualisieren. Um eine einzelne E-Mail anhand ihrer E-Mail-ID zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie im Anfragetext die E-Mail-Eigenschaften ein, die Sie aktualisieren möchten. Ihr Anfragetext kann beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_email_direction\": \"EMAIL\",\n    \"hs_email_status\": \"SENT\",\n    \"hs_email_subject\": \"Let's talk tomorrow\",\n    \"hs_email_text\": \"Thanks for your interest let's find a time to connect!\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignoriert Werte für schreibgeschützte und nicht vorhandene Eigenschaften. Um einen Eigenschaftswert zu löschen, übergeben Sie eine leere Zeichenfolge für die Eigenschaft im Anfragetext."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über batchweise Aktualisierungen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorhandene E-Mails zu Datensätzen zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine E-Mail Datensätzen zuzuordnen, z. B. einem Kontakt und seinen zugeordneten Unternehmen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch. Die Anfrage-URL enthält die folgenden Felder:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emailId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID der E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Typ des Objekts, dem Sie die E-Mail zuordnen möchten (z. B. Kontakt oder Unternehmen)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Datensatzes, dem Sie die E-Mail zuordnen möchten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine eindeutige ID, die den Zuordnungstyp zwischen der E-Mail und dem anderen Objekt angibt. Die ID kann numerisch oder in Snake-Case (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_to_contact"
            }), ") dargestellt werden. Sie können den Wert über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage-URL könnte beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/emails/17691787884/associations/contact/104901/198"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Zuordnung zwischen einer E-Mail und einem Datensatz zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an die gleiche URL wie oben durch:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine E-Mail an einen Datensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "eine E-Mail an einen Datensatz anpinnen"
      }), ", sodass sie oben in der Chronik des Datensatzes bleibt. Die E-Mail muss bereits vor dem Anpinnen dem Datensatz zugeordnet sein, und Sie können nur eine Aktivität pro Datensatz anpinnen. Um eine E-Mail anzupinnen, berücksichtigen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " der E-Mail im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld, wenn Sie einen Datensatz über die Objekt-APIs erstellen oder aktualisieren. Erfahren Sie mehr über das Verwenden der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "Unternehmen"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "Kontakte"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "Deals"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "Tickets"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "Benutzerdefinierte Objekte"
      }), "-APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "E-Mails löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine E-Mail löschen, wird sie vollständig gelöscht und kann ", (0, _jsxRuntime.jsx)("u", {
        children: "nicht"
      }), " wiederhergestellt werden. Sie können E-Mails einzeln oder mehrere gleichzeitig (batchweise) löschen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine einzelne E-Mail anhand ihrer E-Mail-ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/emails/{emailId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über batchweise Löschungen, indem Sie oben in diesem Artikel auf die Registerkarte** Endpunkte** klicken."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}