"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76633369011;
const slug = exports.slug = 'guides/cms/marketplace/theme-requirements';
const title = exports.title = 'Designanforderungen für den HubSpot-Marketplace für Vorlagen';
const name = exports.name = 'HubSpot-Marketplace für Vorlagen | Designanforderungen (2022)';
const metaDescription = exports.metaDescription = 'Erfahren Sie mehr über die Anforderungen, die ein Design erfüllen muss, um beim HubSpot-Marketplace für Vorlagen eingereicht zu werden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "designlimits",
  "label": "Designlimits",
  "parent": null
}, {
  "depth": 0,
  "id": "allgemeine-anforderungen-an-designs",
  "label": "Allgemeine Anforderungen an Designs",
  "parent": null
}, {
  "depth": 1,
  "id": "struktur-der-designdatei",
  "label": "Struktur der Designdatei",
  "parent": "allgemeine-anforderungen-an-designs"
}, {
  "depth": 1,
  "id": "relative-lokale-dateipfade-f%C3%BCr-vorlagen",
  "label": "Relative lokale Dateipfade für Vorlagen",
  "parent": "allgemeine-anforderungen-an-designs"
}, {
  "depth": 0,
  "id": "design-performance",
  "label": "Design-Performance",
  "parent": null
}, {
  "depth": 1,
  "id": "vorschau-urls-f%C3%BCr-designs",
  "label": "Vorschau-URLs für Designs",
  "parent": "design-performance"
}, {
  "depth": 0,
  "id": "verwenden-von-jquery",
  "label": "Verwenden von jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "designkonfiguration-theme.json",
  "label": "Designkonfiguration (theme.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "designeinstellungen-fields.json",
  "label": "Designeinstellungen (fields.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "vorlagen-css%2C-abschnitte%2C-seitenvorlagen-usw.",
  "label": "Vorlagen (CSS, Abschnitte, Seitenvorlagen usw.)",
  "parent": null
}, {
  "depth": 1,
  "id": "abschnitte",
  "label": "Abschnitte",
  "parent": "vorlagen-css%2C-abschnitte%2C-seitenvorlagen-usw."
}, {
  "depth": 1,
  "id": "seitenvorlagen",
  "label": "Seitenvorlagen",
  "parent": "vorlagen-css%2C-abschnitte%2C-seitenvorlagen-usw."
}, {
  "depth": 1,
  "id": "benennung-der-seitenvorlage",
  "label": "Benennung der Seitenvorlage",
  "parent": "vorlagen-css%2C-abschnitte%2C-seitenvorlagen-usw."
}, {
  "depth": 0,
  "id": "module",
  "label": "Module",
  "parent": null
}, {
  "depth": 0,
  "id": "globaler-content",
  "label": "Globaler Content",
  "parent": null
}, {
  "depth": 1,
  "id": "globale-teilvorlagen",
  "label": "Globale Teilvorlagen",
  "parent": "globaler-content"
}, {
  "depth": 0,
  "id": "mehrsprachige-unterst%C3%BCtzung",
  "label": "Mehrsprachige Unterstützung",
  "parent": null
}, {
  "depth": 0,
  "id": "mobile-und-responsive-elemente",
  "label": "Mobile und responsive Elemente",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      Tabs,
      Tab,
      IFrame
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Designanforderungen für den HubSpot-Marketplace für Vorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über die Anforderungen, die ein Design erfüllen müssen, wenn es beim HubSpot-Marketplace für Vorlagen eingereicht wird."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie gerade erst mit der Erstellung Ihres Designs beginnen, empfehlen wir Ihnen dringend, unsere kostenlose CMS Theme Boilerplate zu verwenden, die Sie aus unserem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Git-Repository"
      }), " herunterladen oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.youtube.com/watch",
        children: "in die Design-Manager-Benutzeroberfläche Ihres Accounts importieren können"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüfen Sie außerdem die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "Anforderungen"
      }), " an Vorlagen-Listing-Seiten, wenn Sie Ihre Vorlage beim HubSpot-Marketplace für Vorlagen einreichen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designlimits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie die folgenden Obergrenzen pro Design:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Kostenlose ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub_"
          }), "-Accounts können keine Websitesuche, CTA-Funktionen oder native HubSpot-Videos verwenden. Erfahren Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
            children: "Übersicht der Produkte und Dienstleistungen von HubSpot"
          }), " mehr darüber, was in ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS"
            })
          }), "-Produktabonnements enthalten ist."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Designs dürfen nicht mehr enthalten als:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 Vorlagen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 Module"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 Abschnitte"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Designs dürfen Folgendes ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nicht"
          }), " enthalten:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "E-Mail-Vorlagen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubDB-Funktionen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Serverlose Funktionen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "CRM-Objektfelder"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/drag-and-drop-templates#flexible-columns",
              children: "Flexible Spalten in Vorlagen"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Module vs. Abschnitte"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Abschnitte sind hilfreich, da Content-Autoren sie nur in Ablagezonen mit voller Breite auf der Seite ablegen können, was Ihnen als Entwickler hilft, ein großartiges fertiges Produkt zu gewährleisten."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Insbesondere Abschnitte mit festem Layout, in denen der Content-Autor keine Elemente innerhalb des Abschnitts verschieben kann, sind ein großartiges Tool, um kreative Formatierungen und Layouts bereitzustellen, die sonst mit dem Drag-&-Drop-Editor nicht bewerkstelligen lassen."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Abschnitte bieten auch zusätzliche Vorteile für die Benutzerfreundlichkeit des Content-Autors, da er einzelne Module innerhalb des Abschnitts auswählen kann, sodass seine Modulformulare kürzer und gezielter auf das zu bearbeitende Element ausgerichtet sind."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Allgemeine Anforderungen an Designs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Alle eingereichten Designs müssen unterscheidbar und original sein. Zum Beispiel zählt ein und dasselbe Design mit anderen Texten oder Platzhalterinhalten beispielsweise nicht als eigenständiges Design. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/template-policies#template-marketplace-compliance",
          children: "Erfahren Sie mehr über die Compliance-Anforderungen des HubSpot-Marketplace für Vorlagen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ein Design muss mit HTML- und HubL-Vorlagen und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "-Tags erstellt werden."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Designs müssen einem 12-Spalten-Rastersystem entsprechen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Struktur der Designdatei"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle Designs sollten eine ordnungsgemäße Ordnerstruktur enthalten und unter einem „übergeordneten“ Ordner gruppiert werden, der Ihr Produkt-Listing beschreiben sollte. Wenn Sie beispielsweise ein Design mit dem Namen „Super-Toll“ für den Marketplace erstellen, sollte Ihre Struktur ähnlich dem Bild unten aussehen. Erfahren Sie mehr über die Struktur von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-file-structure",
        children: "Designdateien"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/template-name.png",
          alt: "template-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/file-structure.png",
          alt: "file-structure"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Relative lokale Dateipfade für Vorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie sich auf Designelemente beziehen, ", (0, _jsxRuntime.jsx)("u", {
        children: "müssen"
      }), " Sie relative lokale Dateipfade verwenden. Der beste Weg, diese hinzuzufügen, ist die Verwendung der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "get_asset_url-Funktion"
      }), ", die die öffentliche URL eines Elements, einer Datei oder einer Vorlage zurückgibt. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "diese Funktion auch generieren"
      }), ", indem Sie entweder mit der rechten Maustaste auf eine ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Datei"
      }), " klicken und ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Öffentliche URL kopieren"
      }), " auswählen oder indem Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Aktionen"
      }), " klicken und dann ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Öffentliche URL kopieren"
      }), " auswählen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Stylesheet, auf das durch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "get_asset_url"
      }), " verwiesen wird, muss beispielsweise wie folgt formatiert sein:"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{require_css(get_asset_url('../../css/main.css')) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "//cdn2.hubspot.net/hub/1234567/hub_generated/template_assets/1565970767575/custom/styles/style.min.css\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Überprüfen Sie im folgenden Video die Unterschiede in der Dateistruktur in Ihrem Entwickler-Account im Vergleich zu Dateien, die einem Marketplace-Kunden bereitgestellt werden:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SSOdhqOciuk?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Design-Performance"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei Google Lighthouse muss ein Design Scores ", (0, _jsxRuntime.jsx)("u", {
        children: "über"
      }), " den folgenden Schwellenwerten aufweisen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desktop-Barrierefreiheit:"
        }), " 65"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desktop-Best Practices:"
        }), " 80"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desktop-Performance:"
        }), " 70"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mobil-Performance:"
        }), " 40"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#evaluate-themes-and-templates-for-seo-and-accessibility",
        children: "wie Sie mithilfe des CLI einen Google Lighthouse-Bericht für Ihr Design erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Designdateien sollten minimiert werden können."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alle Bilddateien sollten weniger als 1 MB groß sein."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Alle Bild-Tags sollten ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alt"
        }), "-Attribut haben (ein Wert von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " ist zulässig)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Alle Bild-Tags sollten über ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "loading"
        }), "-Attribut verfügen (ein Wert von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " ist zulässig)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorschau-URLs für Designs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie müssen Ihren eigenen Domain-Namen verwenden, wenn Sie Vorschau-URLs erstellen. Sie können nicht die von HubSpot bereitgestellte Domain verwenden, die folgende URL-Struktur hat: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[AccountID].hs-sites.com"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es muss eine Live-Website verwendet werden, das Image der Demo-Website kann nicht verwendet werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sollte Ihre Live-Demo zu irgendeinem Zeitpunkt unzugänglich werden, behält sich HubSpot das Recht vor, Ihr Design mit einer Benachrichtigung an den Anbieter zu löschen/zu entfernen, bis die Live-Demo wieder zugänglich wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwenden von jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["jQuery ist im HubSpot-Account eines Kunden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/include-jquery-across-your-hubspot-pages",
        children: "nicht standardmäßig aktiviert"
      }), ". Wenn Ihr Design auf jQuery basiert, muss eine Version von jQuery enthalten sein, um sicherzustellen, dass das Design wie erwartet funktioniert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie beispielsweise ein Modul hinzufügen, das jQuery erfordert, der Rest der Website jedoch nicht, müssen Sie den folgenden Code verwenden, um jQuery zu laden:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# this checks if the \"Include jQuery\" option in Settings > CMS > Pages is checked #}\n{% if not site_settings.include_jquery %}\n  {{ require_js(\"../jquery-3.4.1.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designkonfiguration (theme.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "-Datei muss die folgenden Parameter enthalten:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bitte überprüfen Sie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "-Datei und stellen Sie Folgendes sicher:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Name des Labels stimmt mit dem Namen in deinem Design-Listing überein."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie die kostenlose CMS-Theme Boilerplate von HubSpot verwenden, dürfen keine Boilerplate-Werte vorhanden sein. Dazu gehören Autoreninformationen, URL der Dokumentation, Beispiel-URL usw."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die URL der Dokumentation wird aufgelöst und enthält eine Dokumentation zur Verwendung Ihres Designs."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Vorschaupfad ist eine gültige Datei in Ihrem Design."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Screenshot-Pfad ist eine gültige Datei und hängt mit deinem Design zusammen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die Beispiel-URL wird aufgelöst und führt zu einer Demo Ihres Designs. Verwenden Sie für die Beispiel-URL nicht die Subdomains ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-json",
        children: "theme.json-Parameter"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designeinstellungen (fields.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei steuert die verfügbaren Felder und Feldgruppen im Design-Editor, einschließlich der Stilfelder. Welche Felder Sie berücksichtigen, hängt davon ab, wie viel Kontrolle die Content-Autoren über den Seiten-Editor haben sollen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), "-Datei muss mindestens drei Farbfelder enthalten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um Kompatibilität zwischen Designs und unabhängigen Modulen zu gewährleisten, müssen Designs den folgenden Standardkriterien für die Benennung von Farb- und Schriftartfeldern folgen: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), "und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), ". Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/template-policies#module-compatibility-with-themes",
          children: "Kompatibilität von Modul und Design"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Designfelder keine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primary_color"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secondary_color"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "heading_font"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body_font"
      }), "-Felder haben, kann das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), "-Feld verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über diese ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#fields-json",
        children: "fields.json-Parameter"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/fields.json",
        children: "sehen Sie sich ein Beispiel einer fields.json-Datei"
      }), " in der HubSpot CMS Boilerplate an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für Designeinstellungen gilt außerdem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie dürfen keine Konflikte mit Ihren Editor-Stilen oder über ein Modul festgelegten Stilen verursachen. Verwenden Sie zum Beispiel nicht ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!important"
        }), " in Ihrem CSS-Stylesheet, da es Endbenutzern ein Überschreiben erschwert und einen Konflikt verursachen würde."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es müssen aussagekräftige Label für jede Einstellung verwendet werden, damit Content-Autoren wissen, was sie aktualisieren."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie müssen für alle Vorlagen in einem Design gelten, es sei denn, es gibt einen bestimmten Anwendungsfall für zusätzliche Stile. Beispielsweise müssen Änderungen am Stil und der Größe von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), "-Überschriften in Designeinstellungen für alle ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), "-Tags im Design gelten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Design ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "muss"
      }), " mindestens die folgenden Designfelder enthalten:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Typografiefelder:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Felder für Haupttext-Schriftart (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "-Tags)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Schriftartfelder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " bis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h6"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hyperlinkfarbe (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "a"
        }), "-Tags), einschließlich Stile für das Darüberbewegen des Mauszeigers"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Formularfelder:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formulartitelhintergrund"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formulartiteltext, einschließlich mindestens Schriftartfarbstile"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hintergrundfarbe für Formular"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formularrahmenfarbe"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Farbe für Formularlabel"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rahmenfarbe für Formularfeld"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formular-Button - Dazu gehören Einstellungen für Button-Text, Hintergrundfarbe und Stile für das Darüberbewegen des Mauszeigers."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zusätzlich gilt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Felder innerhalb Ihres Designs müssen ggf. logisch gruppiert werden. Beispielsweise sollten mehrere mit Typografie verknüpfte Felder unter einer Gruppe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Typography"
        }), " gruppiert werden."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Designfelder sollten separate Farb- und Schriftartsteuerelemente für Schaltflächen und Formulare sowie separate Farb-, Logo- und Schriftartsteuerelemente für Header und Footer haben."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ein Teil der Farb- und Logofelder des Designs muss von den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/brand-and-settings-inheritance",
          children: "Markeneinstellungen"
        }), " des Accounts erben:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Mindestens zwei Farbfelder müssen Farben aus den Markeneinstellungen des Accounts erben. Zusätzliche Farbfelder können standardmäßig auf andere Farben festgelegt werden, einschließlich Schwarz und Weiß."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn Module in einem Design Logos verwenden, muss mindestens ein Logofeld von den Markeneinstellungen des Accounts erben. Wenn Sie ein Bildfeld zum Rendern eines Logos verwenden, muss das Bildfeld nicht von den Markeneinstellungen erben."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Wie Markenfarben die Ästhetik Ihres Designs beeinflussen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/voyJupcZYeQ?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorlagen (CSS, Abschnitte, Seitenvorlagen usw.)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abschnitte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie müssen gegebenenfalls ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
          children: "Abschnitte"
        }), " verwenden. Es ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "müssen"
        }), " mindestens fünf Abschnitte in einem Design enthalten sein."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abschnitte müssen eindeutige und funktionierende Screenshots enthalten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abschnitte und Module sollten nicht redundant sein."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seitenvorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Design ", (0, _jsxRuntime.jsx)("u", {
        children: "muss"
      }), " mindestens die folgenden Vorlagentypen enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Eine Website-Seitenvorlage oder eine Landingpage-Vorlage."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Bei der Aufnahme von mehrseitigen Vorlagen muss jede Vorlage einen anderen Zweck haben. Zum Beispiel eine Startseite, eine ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "„Über uns“-Seite"
            }), ", eine Landingpage in voller Breite und eine Landingpage mit einer rechten Seitenleiste."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Es wird empfohlen, mindestens acht Seitenvorlagen in ein Design aufzunehmen."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Separate Vorlagen für Blog-Listings und Blog-Beiträge."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Blog-Listing-Vorlage"
            }), ": Die Seite, auf der alle Blog-Beiträge in einem Listing-Format angezeigt werden (bekannt als Blogroll). Der Vorlagentitel muss widerspiegeln, dass er für die Listing-Seite bestimmt ist."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Blog-Beitragsvorlage"
            }), ": Die Blog-Beitragsdetailseite, die einzelne Blog-Beiträge anzeigt. Der Vorlagentitel muss widerspiegeln, dass er für den Blogbeitrag bestimmt ist."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Darüber hinaus müssen Felder für Blog-Kommentare und Blog-Autoren so formatiert werden, dass sie dem Design entsprechen."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Systemseitenvorlagen:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "404-Fehler-Vorlage"
            }), ": Wird angezeigt, wenn Besucher eine Seite aufrufen, die nicht vorhanden ist."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "500-Fehler-Vorlage"
            }), ": Wird angezeigt, wenn auf der Website ein interner Fehler auftritt."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vorlage mit Aufforderung zur Passworteingabe"
            }), ": Wird angezeigt, wenn eine Seite passwortgeschützt ist."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Abonnementvorlage"
            }), ": Eine Seite für die Abonnementeinstellungen, auf der E-Mail-Empfänger die Arten von E-Mails verwalten können, die sie abonniert haben."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vorlage für Abo-Update"
            }), ": Eine Bestätigungsseite, die angezeigt wird, wenn ein E-Mail-Empfänger seine E-Mail-Abo-Einstellungen aktualisiert."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vorlage für Back-up Abo-Kündigung"
            }), ": Die Seite, die für E-Mail-Empfänger angezeigt wird, die E-Mails abbestellen möchten, wenn HubSpot deren E-Mail-Adressen nicht bestimmen kann."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Suchergebnisvorlage"
            }), ": Zeigt Suchergebnisse an, die bei der Verwendung der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/content-search",
              children: "Website-Suche"
            }), " zurückgegeben werden. Nur für kostenpflichtige ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS Hub_"
            }), "-Accounts verfügbar."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benennung der Seitenvorlage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie Vorlagen mit ähnlichen Namen haben, fügen Sie beschreibende Wörter hinzu, die den Unterschied zwischen ihnen angeben."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Achten Sie auf eine konsistente Groß- und Kleinschreibung, entfernen Sie Bindestriche und vermeiden Sie die Verwendung von Kurzschrift (z. B. buchstabieren Sie den Hintergrund, anstatt bg zu verwenden)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ihr Unternehmensname oder Designname muss nicht im Vorlagennamen enthalten sein."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/module-requirements",
        children: "hier"
      }), " mehr über die Anforderungen an Designnmodule und einzelne Module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Globaler Content"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Globale Teilvorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Globale Teilvorlagen"
      }), " sind eine Art von Vorlage, die mit HTML und HubL erstellt wurde und auf Ihrer gesamten Website wiederverwendet werden kann. Die gebräuchlichste Art von globalen Teilvorlagen sind Website-Header, Seitenleisten und Website-Footer. Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#creating-a-global-content-partial-template-using-local-development-tools",
        children: "globale Teilvorlagen"
      }), " erstellen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Designs ", (0, _jsxRuntime.jsx)("u", {
          children: "müssen"
        }), " globale Teilvorlagen enthalten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Globale Teilvorlagen ", (0, _jsxRuntime.jsx)("u", {
          children: "müssen"
        }), " nutzbare ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag-&-Drop-Bereiche"
        }), " enthalten. Zum Beispiel können Sie den Drag-&-Drop-Bereich nicht mit einer \"hide\"-Klasse ausblenden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie ", (0, _jsxRuntime.jsx)("u", {
          children: "müssen"
        }), " verwendbare Drag-&-Drop-Bereiche in Kopf- und Fußzeilen integrieren."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bei Menüs, die global auf einer Website verwendet werden, müssen Benutzer außerdem auch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/menus-and-navigation",
          children: "ein HubSpot-Navigationsmenü auswählen können"
        }), ", das sie in ihren Account-Einstellungen erstellt haben."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Vermeiden Sie es, globale Module in globale Teilvorlagen aufzunehmen, da dies zu einem negativen Endbenutzererlebnis führen kann."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mehrsprachige Unterstützung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Designs ", (0, _jsxRuntime.jsx)("u", {
        children: "müssen"
      }), " in der Lage sein, mehrere Sprachversionen zu unterstützen, und sollten die Sprachen angeben, die sie unterstützen. Dies kann durch das Hinzufügen des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#language-switcher",
        children: "Sprachumschaltmoduls"
      }), " in einem globalen Header erreicht werden, das es den Kunden ermöglicht, die Sprachoptionen leicht zu finden und die gewünschte Sprache auszuwählen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie ", (0, _jsxRuntime.jsx)("u", {
          children: "dürfen nur"
        }), " jeweils eine Sprache gleichzeitig anzeigen. Vermeiden Sie beispielsweise, sowohl Englisch als auch Spanisch gleichzeitig in der Benutzeroberfläche zu haben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vermeiden Sie die Verwendung von fest kodiertem Text. Anstatt zum Beispiel den Text einer Blog-Listing-Schaltfläche als ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Mehr lesen"
        }), " fest zu kodieren, legen Sie den Text innerhalb eines Feldes so fest, dass der Endbenutzer den Text aktualisieren kann, ohne in den Code einsteigen zu müssen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mobile und responsive Elemente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Designs sollten in der Lage sein, ihren Inhalt an das Gerät anzupassen, auf dem sie angezeigt werden. Sie sollten außerdem ein gutes Nutzererlebnis über verschiedene Geräte hinweg bieten. Dies beinhaltet Folgendes, ist aber nicht darauf beschränkt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hauptnavigation"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Schieberegler und Registerkarten"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Große Bilder"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vermeiden von horizontalem Scrollen (es sei denn, dies ist beabsichtigt)"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}