"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611090;
const slug = exports.slug = 'guides/cms/overview';
const title = exports.title = 'Überblick über CMS Hub';
const name = exports.name = 'Überblick über CMS Hub';
const metaDescription = exports.metaDescription = 'Ein umfassender Überblick über HubSpot Content Hub für Entwickler, der Ihnen alle wichtigen Konzepte wie Designs, Vorlagen und Module und deren Zusammenspiel zeigt.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "content-enablement",
  "label": "Content Enablement",
  "parent": null
}, {
  "depth": 0,
  "id": "typen-von-inhalten",
  "label": "Typen von Inhalten",
  "parent": null
}, {
  "depth": 1,
  "id": "website-seiten-und-landingpages",
  "label": "Website-Seiten und Landingpages",
  "parent": "typen-von-inhalten"
}, {
  "depth": 1,
  "id": "blogs",
  "label": "Blogs",
  "parent": "typen-von-inhalten"
}, {
  "depth": 1,
  "id": "e-mails",
  "label": "E-Mails",
  "parent": "typen-von-inhalten"
}, {
  "depth": 0,
  "id": "strukturierte-inhalte",
  "label": "Strukturierte Inhalte",
  "parent": null
}, {
  "depth": 0,
  "id": "entwickler-dateisystem",
  "label": "Entwickler-Dateisystem",
  "parent": null
}, {
  "depth": 0,
  "id": "designs%2C-vorlagen%2C-module-und-felder",
  "label": "Designs, Vorlagen, Module und Felder",
  "parent": null
}, {
  "depth": 1,
  "id": "designs",
  "label": "Designs",
  "parent": "designs%2C-vorlagen%2C-module-und-felder"
}, {
  "depth": 1,
  "id": "vorlagen",
  "label": "Vorlagen",
  "parent": "designs%2C-vorlagen%2C-module-und-felder"
}, {
  "depth": 1,
  "id": "module",
  "label": "Module",
  "parent": "designs%2C-vorlagen%2C-module-und-felder"
}, {
  "depth": 1,
  "id": "felder",
  "label": "Felder",
  "parent": "designs%2C-vorlagen%2C-module-und-felder"
}, {
  "depth": 0,
  "id": "die-hubl-sprache",
  "label": "Die HubL-Sprache",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      li: "li",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Überblick über CMS Hub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieser Abschnitt soll Ihnen helfen, die wichtigsten Aspekte von HubSpots ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS"
        })
      }), " zu verstehen und großartige Websites auf dessen Grundlage zu entwickeln. Für ein optimales Erlebnis wird ein professionelles Verständnis der Grundlagen der Webentwicklung, einschließlich HTML, JavaScript und CSS, erwartet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie neu beim Entwickeln im CMS sind, sehen Sie sich zuerst das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Schnellstart-Tutorial"
      }), " an und konsultieren Sie dann bei Bedarf diese Übersicht."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Content Enablement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub wurde entwickelt, um Unternehmen dabei zu helfen, ihre Web-Präsenz zu erweitern. Dabei soll vor allem Marketern das Erstellen und Verwalten von Inhalten ermöglicht und erleichtert werden. Die Inhalte, Lead-Erfassung und Analytics der Website sind in das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/crm",
        children: "CRM von HubSpot"
      }), " integriert, sodass Marketer auf einfache Weise personalisierte Erlebnisse für Besucher erstellen und diese Erlebnisse in das übrige Unternehmen integrieren können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Als HubSpot-CMS-Entwickler erstellen Sie nicht nur Inhaltselemente wie Designs, Vorlagen und Module, Sie erstellen sie auch so, dass Content-Autoren sie nach Bedarf mit Inhalten anpassen können. Dementsprechend ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "müssen Sie"
        })
      }), " die Bedürfnisse von Entwicklern über ein System von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Designs"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modulen"
      }), " sowie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag-&-Drop-Bereichen"
      }), " mit den Anforderungen von Content-Autoren in Einklang bringt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/code-no-code-graphic.png",
        alt: "Vorlage und Module sind die Schnittstelle zwischen Entwicklern und Marketern"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine gut konzipierte Website sollte in enger Zusammenarbeit mit Ihren Content-Autoren entwickelt werden, um deren Bedürfnisse zu verstehen, und erfordert dann weniger laufenden Support und Wartung durch einen Entwickler. Zu diesem Zweck wird empfohlen, sich beim Erstellen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow#testing",
        children: "in einer Vorschau anzusehen, wie das Seitenerstellungserlebnis für Content-Autoren aussieht"
      }), ". Dadurch wird sichergestellt, dass sie so weit wie möglich unabhängig mit der Website arbeiten können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/making%20a%20page.gif",
        alt: "Animierte Darstellung der Erstellung einer Drag-&-Drop-Seite durch den Benutzer"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot übernimmt das Hosting und die Wartung Ihrer Seiten, damit sie sich nicht um Plug-in-Verwaltung, Updates, Hosting, Skalierung oder Sicherheit kümmern müssen. Der Nachteil dabei ist, dass das System im Vergleich zu selbst gehosteten CMS-Systemen die Möglichkeiten ein wenig mehr einschränkt. So können Sie beispielsweise keine Systemgrundlagen manuell oder über Plugins ändern oder erweitern, das Low-Level-Rendering manipulieren oder direkt auf Datenbankinhalte zugreifen und diese ändern."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Von Entwicklern erstellte Inhalte (z. B. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Designs"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Vorlagen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), ", JavaScript und CSS) werden in einem Dateisystem für Entwickler erstellt, während Seiteninhalte (Seiten, Blogbeiträge) in einem leistungsstarken blockbasierten WYSIWYG-Editor (What You See Is What You Get) gestaltet und erstellt werden und Mediendateien (von Content-Autoren erstellte Bilder, PDFs usw.) in einem auf einer Web-App basierenden Datei-Manager gespeichert werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn eine Seite gerendert wird, leitet HubSpot die Anfrage an einen von vielen Servern auf der Grundlage der Domain weiter, rendert die Seite auf unseren Servern und lspeichert sie, wenn möglich, in einem Content Delivery Network (CDN) zwischen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Typen von Inhalten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt viele Typen von Inhalten, die Sie mit dem CMS von HubSpot erstellen. Die Benutzeroberfläche für Content-Autoren unterscheidet sich je nach Inhaltstyp geringfügig. Dies hat Auswirkungen, die Sie als Entwickler kennen müssen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Website-Seiten und Landingpages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Website-Seiten und Landingpages werden unabhängig voneinander erstellt, aber alle Seiten basieren auf Vorlagen. Für Content-Autoren ist der Prozess des Erstellens einer Landingpage oder einer Website-Seite nahezu identisch. Der Unterschied besteht darin, dass Website-Seiten dazu dienen, Informationen zu präsentieren, die Teil Ihrer Website sind und organisch gefunden werden sollen, während eine Landingpage ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/marketing/landing-page-best-practices",
        children: "in der Regel mit einem bestimmten Marketingangebot oder einer Kampagne verknüpft ist"
      }), " (z. B. mit einer Marketing-E-Mail, die an eine bestimmte Liste von Kontakten gesendet wird)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In der Benutzeroberfläche für Marketer sind die Analytics und die Organisation dieser Seitentypen ebenfalls separat organisiert, da Landingpages oft spezifische Konversionsziele haben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-Blogs haben zwei Ansichten – eine für die Listing-Seite und eine für die Seite mit den einzelnen Beiträgen – und jeder Blogbeitrag wird in jede dieser Ansichten eingefügt. Sie können für einen Blog festlegen, dass er die gleiche Vorlage für Blogbeiträge und Listing-Seiten teilt, oder über separate Vorlagen für die Listing-Seite und für Blogbeiträge verfügen. Blogbeiträge müssen die gleiche Vorlage teilen. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "Blog-Vorlagen-Markup"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/manage-your-blog-template-and-settings",
        children: "wie Sie Blogs in HubSpot erstellen und verwalten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-Mails"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "E-Mails können in HubSpot auf unterschiedliche Art und Weise erstellt werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Klassische E-Mail:"
        }), " Erstellen Sie E-Mail-Vorlagen und -Module ähnlich wie Websites und Landingpages. Sie können auch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/email-template-markup",
          children: "kodierte E-Mail-Vorlagen erstellen,"
        }), " um die vollständige Kontrolle über das Markup zu haben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Drag-&-Drop-E-Mails:"
        }), " Erstellen Sie anpassbare ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
          children: "Drag-&-Drop"
        }), "-E-Mail-Vorlagen, mit denen Content-Autoren mithilfe der Drag-&-Drop-Oberfläche von HubSpot E-Mail-Layouts und -Inhalte erstellen können."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Strukturierte Inhalte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neben dem Erstellen von Seiteninhalten über die In-App-Editoren oder der Hartcodierung in Vorlagen können Sie auch strukturierte Datenquellen verwenden, um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "dynamische Seiteninhalte"
      }), " mit HubL zu füllen. Sie können die folgenden Datenquellen verwenden, um Seiten zu füllen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
          children: "HubDB"
        }), ": Speichern Sie Daten in Zellen von HubDB-Tabellen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
          children: "CRM-Datensätze"
        }), ": Speichern Sie Daten in CRM-Datensätzen, z. B. Kontakte, Unternehmen oder benutzerdefinierte Objekte."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dynamische Seiten mit strukturierten Inhalten zu erstellen bedeutet, dass Sie Webseiten und Seiteninhalte erstellen, bearbeiten und entfernen können, indem Sie die Datenquellen direkt aktualisieren. Ähnlich wie bei einem HubSpot-Blog umfasst ein Satz dynamischer Seiten eine einzelne Listing-Seite, um die Instanzen Ihrer Datenquelle anzuzeigen, und dann eine separate Seite für jede einzelne Instanz. Mit HubL können Sie die Daten, die die Seiten anzeigen, vollständig konfigurieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beispielsweise können Sie eine HubDB-Tabelle erstellen, in der eine Reihe mit Informationen für jedes Mitglied eines Vertriebsteams gespeichert ist. Mithilfe dieser HubDB-Tabelle kann HubSpot dann eine Listing-Seite generieren, um wichtige Details aus jeder Tabellenzeile anzuzeigen (z. B. einen Namen und ein Bild für die einzelnen Vertriebsmitarbeiter), zusammen mit einer separaten Seite pro Vertriebsmitarbeiter, um weitere Informationen anzuzeigen (z. B. Biografie und Telefonnummer). Sollte ein Vertriebsmitarbeiter später zu einem anderen Team befördert werden, können Sie seine Zeile aus der HubDB-Tabelle löschen. HubSpot löscht daraufhin automatisch seine Detailseite und entfernt sie von der Listing-Seite."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entwickler-Dateisystem"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Kernelemente – Vorlagen, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Designs"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), " sowie die JavaScript- und CSS-Dateien sowie die Bilder, die sie unterstützen – werden in einem Dateisystem für Entwickler erstellt. Sie können dieses Dateisystem entweder im linken Fenster des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Design-Managers"
      }), " oder in Ordnern anzeigen, die mit den Tools für die lokale Entwicklung synchronisiert wurden. Innerhalb des Dateisystems können die Elemente mit absoluten oder relativen Pfaden aufeinander verweisen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hinter den Kulissen werden diese Dateien Einträgen in einer Datenbank zugeordnet. Aus diesem Grund erfolgt der Zugriff auf das Dateisystem für Entwickler über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot-CLI-Tools"
      }), " und nicht über einen direkten SSH- oder FTP-Zugang, und einige Dateisystemfunktionen, die Sie vielleicht erwarten, z. B.erechtigungen und Symlinks, werden im Dateisystem für Entwickler nicht angeboten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies unterscheidet sich von der Vorgehensweise eines herkömmlichen CMS-Systems, bedeutet aber, dass fehlerhafte Verweise zwischen Dateien oder Syntaxfehler zum Zeitpunkt der Veröffentlichung und nicht erst zur Laufzeit erkannt werden, was Sie zusätzlich vor unbeabsichtigten Fehlern bei späterem Live-Traffic auf der Website schützt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vorlagen im Dateisystem werden automatisch erkannt und Content-Autoren präsentiert, wenn sie neue Seiten erstellen, sodass die Struktur des Dateisystems Ihnen überlassen bleibt. Es ist nicht erforderlich, dass Module in einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/modules/"
      }), "-Ordner oder JavaScript in einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/js/"
      }), "-Ordner gespeichert werden. Es wird jedoch empfohlen, Ihre Inhaltselemente in ähnlicher Weise zu organisieren, wie wir es im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Boilerplate-Beispielcode für das CMS"
      }), " getan haben."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " In der Standardeinstellung minifiziert HubSpot automatisch JavaScript und CSS, die im Design-Manager enthalten sind, um unnötige Leerzeichen, Zeilenumbrüche und Kommentare zu entfernen. Dies gilt auch für JavaScript und CSS, die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "über das CLI in den Design-Manager hochgeladen werden"
        }), ". Dies bedeutet, dass Sie nicht bereits minimierten Code direkt zum Design-Manager hinzufügen sollten."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-and-css-minification",
          children: "JavaScript und CSS-Minifizierung"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designs, Vorlagen, Module und Felder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Designs"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Vorlagen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Felder"
      }), " sind die Objekte, mit denen Sie am häufigsten arbeiten werden. Durch den effektiven Einsatz dieser verschiedenen Objekte können Sie Content-Autoren die Freiheit geben, selbstständig an Websites zu arbeiten und diese zu überarbeiten, ohne die von Ihnen festgelegten Stil- und Layoutvorgaben zu verletzen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Designs und Module enthalten Felder, die Einstellungen für bestimmte Datentypen sind, z. B. Zahlen, Zeichenfolgen, Rich-Text und Bilder. Sie können steuern, wie diese beim Rendern dieser Objekte verwendet werden und wie sie organisiert und im ", (0, _jsxRuntime.jsx)("abbr", {
        title: "What You See Is What You Get",
        children: "WYSIWYG"
      }), "-Editor angezeigt werden sollen. Content-Autoren können im WYSIWYG-Editor Werte für Felder festlegen, die zum Zeitpunkt des Renderns auf das Design oder Modul angewendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Designs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Designs"
      }), " sind die Top-Level-Objekte, die das Erscheinungsbild einer Website definieren und eine benutzerfreundliche Umgebung zum Bearbeiten von Inhalten schaffen. Zu diesen Elementen können Vorlagen, Module, CSS-Dateien, JavaScript-Dateien, Bilder und vieles mehr gehören."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mithilfe von Designs können Sie eine Reihe von Felder erstellen, mit denen Content-Autoren eine globale stilistische Kontrolle über eine Website erhalten, ohne CSS bearbeiten zu müssen. Sie können in CSS festlegen, wo diese Steuerelemente angewendet werden, Steuerelemente so anordnen, dass sie von anderen erben, und verwalten, wie sie im Design-Editor für Marketer dargestellt und organisiert werden. Content-Autoren verwenden den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-fields",
        children: "Design-Editor"
      }), ", um Designfelder zu ändern, eine Vorschau dieser Änderungen anhand der vorhandenen Vorlagen innerhalb eines Designs anzuzeigen und ihre Änderungen zu veröffentlichen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Designfelder können entweder global für eine Website festgelegt oder auf Seitenebene überschrieben werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Theme%20Editor.png",
        alt: "Design-Editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Vorlagen"
      }), " definieren das Basis-Markup und den Stil einer Reihe von Seiten, die eine Vorlage verwenden. Sie können HubL, HTML und Links zu JavaScript- und CSS-Dateien enthalten und können auf die Verwendung mit bestimmten Inhaltstypen beschränkt werden. Sie haben volle Flexibilität bei der Gestaltung und dem Stil, den Sie in eine Vorlage einfügen können, aber Sie sollten sich an einige bewährte Methoden halten und einige wichtige Funktionen von ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " nutzen, um sicherzustellen, dass die Marketer die Seiten einfach bearbeiten können. Einige Best Practices umfassen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview",
          children: "Vorlagen"
        }), " als Teil eines Designs und Verwendung von CSS auf Designebene, einschließlich Designfeldern, um den Großteil der Formatierung innerhalb einer Vorlage zu übernehmen. Dies erleichtert es Content-Autoren, globale und lokale Stiländerungen auf konsistente Weise vorzunehmen, ohne dass sie sich mit dem Bearbeiten von CSS befassen müssen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Modulen"
        }), " für die meisten Komponenten auf Ihrer Seite, wodurch sie neu angeordnet und auf der gesamten Website wiederverwendet werden können. Im Folgenden finden Sie weitere Informationen zu Modulen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "Drag-&-Drop-Bereichen"
        }), ", wo immer dies möglich ist, für zentrale Seiteninhalte, insbesondere auf internen Seiten. Mit Drag-&-Drop-Bereichen können Sie ein Standardlayout für die Module festlegen, aus denen eine Seite besteht, aber Marketern die Flexibilität geben, Layout und Stil unabhängig zu bearbeiten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
          children: "globalen Teilvorlagen"
        }), " für gemeinsame Inhalte wie Header und Footer, die auf der gesamten Website einheitlich aussehen sollen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vorlagen können entweder mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), " oder mit einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "Drag-&-Drop"
      }), "-Oberfläche im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Design-Manager"
      }), " erstellt werden. Es wird jedoch empfohlen, kodierte Vorlagen zu verwenden, da sie mehr Kontrolle und Funktionalität bieten als Drag-&-Drop-Vorlagen, z. B. die Unterstützung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag-&-Drop-Bereichen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Homepage%20Template%20html.png",
        alt: "VS-Code mit geöffneter HTML- und HubL-Vorlage"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Module"
      }), " sind wiederverwendbare Komponenten, die Sie in Vorlagen und auf Seiten platzieren können. Sie enthalten Steuerelemente für Marketer und enthalten HubL/HTML-Markup, CSS und JavaScript, mit denen sich zusammen wiederverwendbare und editierbare Komponenten einer Website erstellen lassen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Steuerelemente für ein Modul werden in Feldern definiert. Bei der Entwicklung eines guten Moduls muss daher sowohl das Erscheinungsbild auf einer Seite als auch die Bearbeitungsmöglichkeiten für Content-Editoren berücksichtigt werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "_HubSpot bietet einen Satz von _"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "Standardmodulen"
      }), " wie Header, Rich-Text, Bilder, Schaltflächen und CTAs, die Sie als grundlegende Komponenten verwenden. Sie werden wahrscheinlich auch Elemente entwickeln wollen, die interessantere Layouts haben können, die in Ihr Design und Ihre Vorlagen passen. Einige gängige Beispiele für Module, die Sie erstellen möchten, sind Accordions, Schieberegler und Registerkarten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Module%20form%20in%20editor.png",
        alt: "Modulformular im Editor"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können sich ein Modul als ein Objekt und Module auf Seiten als Instanzen dieses Objekts vorstellen. Das bedeutet, dass Updates am ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), ", CSS oder JavaScript eines Moduls auf alle Instanzen dieses Moduls innerhalb von Seiten oder Vorlagen in einem bestimmten Portal angewendet werden. Module sind außerdem portalübergreifend übertragbar, unabhängig davon, ob Sie das in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Design-Manager"
      }), " integrierte Kopiertool verwenden, sie im Marketplace verkaufen oder den Code direkt mit Tools für die lokale Entwicklung austauschen. Das bedeutet, dass Sie eine Designlösung einmal implementieren und auf allen Seiten und Portalen verwenden können. Wenn Sie sie dann aktualisieren müssen, können diese Änderungen auf allen Seiten übernommen werden, ohne dass Sie mehrere Seiten oder Vorlagen bearbeiten müssen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Module können auch in Designs eingebunden werden. So können Sie mithilfe von Designfeldern das Erscheinungsbild von Modulen beeinflussen und sicherstellen, dass sie im Seiten-Editor prominent angezeigt werden, damit Content-Autoren einfachen Zugriff auf Module haben, die gut zu den von Ihnen erstellten Designs passen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modulübersicht"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Felder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Felder"
      }), " sind die Steuerelemente, mit denen Content-Autoren die Parameter anpassen können, die an Ihre Designs und Module übergeben werden. Die Felder sind typisiert, einschließlich einfacher Typen wie boolesch, Text, URL, Auswahl und Datei, aber auch komplexere Felder wie Schriftart mit Formatierung sowie HubSpot-spezifische Felder wie Links zu anderen Inhalten oder Formularen im HubSpot-System."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Felder können auch innerhalb von Repeatern platziert werden, die ein Array an das Modul übergeben – ein Beispiel hierfür könnte ein Bildkarussell sein, bei dem Sie eine Reihe von Bildern mit zugehörigem `alt`-Text übergeben möchten. Anstatt eine Reihe von Bild- und Textfeldern zu erstellen, können Sie jeweils ein Feld erstellen und in eine Wiederholungsgruppe einfügen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Felder eines Moduls werden entweder im Design-Manager oder mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#the-user-interface-for-editing",
        children: "dieser Syntax in einer fields.json-Datei"
      }), " angegeben. Die Felder für ein Thema müssen in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei im Stammverzeichnis des Designs angegeben werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die HubL-Sprache"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Hauptsprache, mit der Sie Website-Elemente im CMS von HubSpot erstellen, ist die HubSpot Markup Language oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), " (ausgesprochen „Hubble“). HubL ist die HubSpot-Erweiterung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", einer auf ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://palletsprojects.com/p/jinja/",
        children: "Jinja"
      }), " basierenden Template-Engine. HubL verwendet eine ganze Reihe von Markups, die nur in HubSpot vorkommen, und unterstützt nicht alle Funktionen von Jinja. Es wird vollständig serverseitig ausgeführt, wenn eine Seite gerendert wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL verfügt über die Funktionen, die man von einer einfachen Vorlagensprache erwartet, wie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "Variablen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for-Schleifen"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if-Anweisungen"
      }), ", unterstützt aber auch komplexere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "Rendering-Makros"
      }), ", Datenabrufe und das Zuordnen mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "Tags"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "Funktionen"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "Filtern"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie an die Grenzen dessen stoßen, was mit HubL möglich ist, bietet HubSpot APIs für die Erstellung individuellerer Lösungen. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "-Accounts können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverlose Funktionen"
      }), " nutzen, die eine anspruchsvollere serverseitige Programmierung ermöglichen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL-Sprachreferenz"
      }), " weitere Einzelheiten zu bestimmten Sprachfunktionen nachlesen."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}