"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'reference/api/files/v3-legacy';
const title = exports.title = 'Files API - V3 Legacy';
const name = exports.name = 'Files API - V3 Legacy';
const metaDescription = exports.metaDescription = 'Files API - V3 Legacy';
const orderedTitles = exports.orderedTitles = ['Check the status of a file access update task', 'Get a signed URL for a private file', 'Upload a new file', 'Upload a replacement file', 'Update the access of a group of files in file manager'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET', 'GET', 'POST', 'POST', 'POST'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      a: "a",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Check the status of a file access update task"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/filemanager/api/v3/files/set-access/:taskId/status"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Check the status of a batch file access update task. You'll need the ID of a previously started batch update; see ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#update-the-access-of-a-group-of-files-in-file-manager",
            children: "the documentation"
          }), " for more details."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "The response will include the status of the update."
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "Status"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Description"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "ENQUEUED"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "The task has been added to the queue and is waiting to be processed"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "STARTED"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "The task has been started and is currently being processed."
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "COMPLETED"
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "The task has completed successfully."
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: "PARTIAL_FAILURE"
              }), (0, _jsxRuntime.jsxs)(_components.td, {
                children: ["The task has finished, but some files may not have updated successfully.An error report can be retrieved from the URL in the the additional field ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "ErrorTranscriptUrl"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "files"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "taskId",
            required: true,
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The ID of a previously started batch update task. This ID will be in the response when using the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#update-the-access-of-a-group-of-files-in-file-manager",
                children: "endpoint documented here"
              }), "."]
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9maWxlbWFuYWdlci9hcGkvdjMvZmlsZXMvc2V0LWFjY2Vzcy81ZjA2YzY5Mi0zYjAwLTMyYmQtODcwMS05NTllZTU0MTZkYWUvc3RhdHVzCgpFeGFtcGxlIHJlc3BvbnNlOgp7CiAgInN0YXR1cyI6ICJDT01QTEVURUQiLCAKICAidGFza0lkIjogImZpbGVtYW5hZ2VyLWJ1bGstc2V0LWFjY2Vzc2liaWxpdHktNjI1MTUtNWYwNmM2OTItM2IwMC0zMmJkLTg3MDEtOTU5ZWU1NDE2ZGFlIgp9CgpJZiB0aGVyZSBpcyBhIGZhaWx1cmUsIHRoZSByZXNwb25zZSB3aWxsIGluY2x1ZGUgYSBsaW5rIHRvIHRoZSBlcnJvciByZXBvcnQ6CnsKICAidGFza0lkIjogImZpbGVtYW5hZ2VyLWJ1bGstc2V0LWFjY2Vzc2liaWxpdHktNjI1MTUtMDAxYjQxNDUtZjRhMC0zNmJkLTgyYmEtOWY3YjA1ZjBlZDJjIiwKICAic3RhdHVzIjogIlBBUlRJQUxfRkFJTFVSRSIsCiAgImVycm9yVHJhbnNjcmlwdFVybCI6ICJodHRwczovL2FwaS5odWJzcG90LmNvbS9maWxlbWFuYWdlci9hcGkvdjIvZmlsZXMvNDI3NTA2NDQwMy9zaWduZWQtdXJsLXJlZGlyZWN0P3BvcnRhbElkPTYyNTE1Igp9"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get a signed URL for a private file"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/filemanager/api/v3/files/:fileId/signed-url"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get the details for a private file. The details will include a signed URL that can be used to retrieve the contents of the file."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Private files cannot be access using their URL alone. The signed URL returned by this endpoint can be used to get temporary access to the file contents."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Notes:"
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When using OAuth for your authentication, files that are both private and hidden cannot be accessed without the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            }), " scope. See the details below."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["The signed URL will only be valid for a few hours, after which the URL will result in an error. At this point, you would need to request a new signed URL to access the file again. The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "expiresAt"
            }), " field in the response is a UNIX-formatted timestamps in milliseconds that represents the time that the URL will expire."]
          }), "\n"]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "files"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "file_id",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for a particular file"
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTDoKaHR0cHM6Ly9hcGkuaHVic3BvdC5jb20vZmlsZW1hbmFnZXIvYXBpL3YzL2ZpbGVzLzM1MDQ5ODY0NjM3L3NpZ25lZC11cmwKCkV4YW1wbGUgcmVzcG9uc2U6CnsKICAiZXhwaXJlc0F0IjogMTYwMDM2MDYwNTY5NSwKICAidXJsIjogImh0dHBzOi8vY2RucC5odWJzcG90Lm5ldC9odWJmcy82MjUxNS9kb2NzL3Rlc3RfZmlsZS50eHQ/RXhwaXJlcz0xNjAwMzYwNjA1JmFwbTtTaWduYXR1cmU9Z3pTQTkwOFVoa1N3SFFLbmFpdml2N2MtTW5ISWRMRkVOdU1EZ1RXMH5SdlNjSld4NkpMR3ZSSExITFc5b1R4fkh5Sk5DSHlRd0UyfjVTZ2lGQmVpTDVqTmx0bzM4U25Da1VEOX5pRDRYUW1iMno0RVVIMXdJOXUxRFlaNjY2fnFjYzZYWkhZeVBsREpGemFxT3dkekRVckhtOXdpeWg5em4xNHNnbkl0dWZpQlp4T0dGVWxEckxTMHNtODVIUG9UMXFlaWdBbkNDNH5iTW1OVFJvcmNlbXlia3Y3SmVrYUQyeGVNSkdqNUdUaVdLRDA2aTZiVX5lUXZMNUVyNVEzZmFQaWhLT0pxV01JbHF2cWtkcmpZQlhiQ1FxRHlSY3BKNGxWYWFOTHpCY0R0S3JDZjhKN0dUWXd5SXdscGRMVHRFSHQxT3BjeGp5TUtDVWxYTGprNVZBX18mYW1wO0tleS1QYWlyLUlkPUFQS0FKRE5JQ09LQU5QSFZDU0JRIiwKICAibmFtZSI6ICJ0ZXN0X2ZpbGUiLAogICJleHRlbnNpb24iOiAidHh0IiwKICAidHlwZSI6ICJET0NVTUVOVCIsCiAgInNpemUiOiAzNQp9"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(EndpointCard, {
      children: (0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Upload a new file"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/filemanager/api/v3/files/upload"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Upload a new file to the file manager. This endpoint uses multi-part form encoding, rather than JSON."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On success, the response will include the metadata for the file. See ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/files/v2#get-file-metadata",
            children: "this page"
          }), " for an example."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "files"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fileName",
            schemaGroup: "Body",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Optional field. Desired name of the file. If not provided the name will be inferred from the file data."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "charsetHunch",
            schemaGroup: "Body",
            type: "file",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The multi-part form encoded file to upload."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "folderId",
            schemaGroup: "Body",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Partially optional field. One of folderPath or folderId must be specified. Path of the folder the file should be uploaded into. Uploading files into the root path “/” is highly discouraged."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "folderPath",
            schemaGroup: "Body",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Partially optional field. One of folderId or folderPath must be specified. Id of the folder the file should be uploaded into."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "file",
            schemaGroup: "Body",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Optional field. The character set of the provided file."
            })
          }), (0, _jsxRuntime.jsxs)(EndpointParameter, {
            name: "options",
            schemaGroup: "Body",
            type: "jsonString",
            required: true,
            children: [(0, _jsxRuntime.jsx)(_components.p, {
              children: "JSON string representation of the options object. See below."
            }), (0, _jsxRuntime.jsxs)(_components.table, {
              children: [(0, _jsxRuntime.jsx)(_components.thead, {
                children: (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.th, {
                    children: "Field name"
                  }), (0, _jsxRuntime.jsx)(_components.th, {
                    children: "Description"
                  })]
                })
              }), (0, _jsxRuntime.jsxs)(_components.tbody, {
                children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "access(enum)"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["Required field. Options are:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "PUBLIC_INDEXABLE"
                    }), (0, _jsxRuntime.jsx)("br", {}), "The file will be publicly accessible by anyone who has the file url. Search engines will be able to index the file.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "PUBLIC_NOT_INDEXABLE "
                    }), (0, _jsxRuntime.jsx)("br", {}), "The file will be publicly accessible by anyone who has the file url. The ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "https://developers.google.com/search/reference/robots_meta_tag#xrobotstag",
                      children: (0, _jsxRuntime.jsx)(_components.code, {
                        children: "X-Robots-Tag: noindex"
                      })
                    }), " header will be sent whenever the file is retreived, instructing search engines not to index the file.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "PRIVATE"
                    }), (0, _jsxRuntime.jsx)("br", {}), "The file will be private and requires a signed url to access. Search engines will NOT be able to index the file."]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "ttl(string)"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["Time to live. This is an optional field. If specified, the file will be ", (0, _jsxRuntime.jsx)("u", {
                      children: "permanently"
                    }), " deleted after the specified amount of time. After deletion, the file cannot be recovered or restored.The formats represent periods. For example “P3M” means a period of 3 months. The file will be deleted after 3 months. The limit for ttl is 1 year."]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "overwrite(boolean)"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["If true this file with replace any existing files with the name name, folder, and type.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "Note:"
                    }), " If this is set to false, and the uploaded file matches the filename of an existing file in the same folder, the file will be uploaded with a number incremented filename (e.g. example_file.txt will become example_file-1.txt)"]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "duplicateValidationStrategy(enum)"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["Optional field. Options are:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "REJECT"
                    }), (0, _jsxRuntime.jsx)("br", {}), "If a duplicate file is found, the upload request is rejected.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "RETURN_EXISTING"
                    }), (0, _jsxRuntime.jsx)("br", {}), "If a duplicate file is found, the upload is cancelled and the existing file is returned instead.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "NONE"
                    }), (0, _jsxRuntime.jsx)("br", {}), "No duplicate file validation is performed.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "Defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "NONE"
                    }), " if not specified"]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "duplicateValidationScope(enum)"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["Optional field. Options are:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "ENTIRE_PORTAL"
                    }), (0, _jsxRuntime.jsx)("br", {}), "Checks all files for a duplicate.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "EXACT_FOLDER"
                    }), "Checks only the provided folder for a duplicate.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "Defaults to ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "EXACT_FOLDER"
                    }), " if not specified"]
                  })]
                })]
              })]
            })]
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(EndpointCard, {
      children: (0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Upload a replacement file"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/filemanager/api/v3/files/:file_id/replace"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Replace a specific file in file manager, specified by the file ID."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "files"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            schemaGroup: "Path",
            name: "file_id",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for a particular file"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "file",
            schemaGroup: "Body",
            type: "file",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The multi-part form encoded file to upload."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "charSetHunch",
            schemaGroup: "Body",
            type: "string",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The character set of the provided file."
            })
          }), (0, _jsxRuntime.jsxs)(EndpointParameter, {
            name: "options",
            schemaGroup: "Body",
            type: "jsonString",
            required: true,
            children: [(0, _jsxRuntime.jsx)(_components.p, {
              children: "JSON string representation of the options object. See below."
            }), (0, _jsxRuntime.jsxs)(_components.table, {
              children: [(0, _jsxRuntime.jsx)(_components.thead, {
                children: (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.th, {
                    children: "Field name"
                  }), (0, _jsxRuntime.jsx)(_components.th, {
                    children: "Description"
                  })]
                })
              }), (0, _jsxRuntime.jsxs)(_components.tbody, {
                children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "access(enum)"
                  }), (0, _jsxRuntime.jsxs)(_components.td, {
                    children: ["Required field. Options are:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "PUBLIC_INDEXABLE"
                    }), (0, _jsxRuntime.jsx)("br", {}), "The file will be publicly accessible by anyone who has the file url. Search engines will be able to index the file.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "PUBLIC_NOT_INDEXABLE"
                    }), (0, _jsxRuntime.jsx)("br", {}), "The file will be publicly accessible by anyone who has the file url. The ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "https://developers.google.com/search/reference/robots_meta_tag#xrobotstag",
                      children: (0, _jsxRuntime.jsx)(_components.code, {
                        children: "X-Robots-Tag: noindex"
                      })
                    }), " header will be sent whenever the file is retreived, instructing search engines not to index the file.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
                      children: "PRIVATE"
                    }), (0, _jsxRuntime.jsx)("br", {}), "The file will be private and requires a signed url to access. Search engines will NOT be able to index the file."]
                  })]
                }), (0, _jsxRuntime.jsxs)(_components.tr, {
                  children: [(0, _jsxRuntime.jsx)(_components.td, {
                    children: "ttl(string)"
                  }), (0, _jsxRuntime.jsx)(_components.td, {
                    children: "Time to live. Optional field. If specified the file will be deleted after the specified amount of time. The formats are Periods. For example “P3M” means a period of 3 monts. The file will be deleted after 3 months. The ttl cannot be more than 1 year."
                  })]
                })]
              })]
            })]
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Update the access of a group of files in file manager"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/filemanager/api/v3/files/set-access/batch"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Updates the access settings for a set of files."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "The files can be specified by individual file IDs, or by folder ID. When a folder ID is used, all of the files in the folder and subfolder will have their access setting updated."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The updates are processed asynchronously. The response will include a taskId that can be used to to check the status of the task. See ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#check-the-status-of-a-file-access-update-task",
            children: "this documentation for details"
          }), " on checking the task status."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note:"
          }), " This endpoint will not update the settings for folders, only the individual files contained in those folders and any subfolders. Future files added to these folders will not be affected."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note:"
          }), " at least one of folderIds or fileIds body parameters must be included."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "files"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "access",
            schemaGroup: "Body",
            required: true,
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The access setting that you want to use for the files.\nMust be one of:", (0, _jsxRuntime.jsxs)("ul", {
                children: [(0, _jsxRuntime.jsx)("li", {
                  children: "PUBLIC_INDEXABLE - The file will be publicly accessible by anyone who has the file url. Search engines will be able to index the file."
                }), (0, _jsxRuntime.jsx)("li", {
                  children: "PUBLIC_NOT_INDEXABLE - The file will be publicly accessible by anyone who has the file url. Search engines will NOT be able to index the file."
                }), (0, _jsxRuntime.jsx)("li", {
                  children: "PRIVATE - The file will be private and requires a signed url to access. Search engines will NOT be able to index the file."
                })]
              })]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "folderIds",
            schemaGroup: "Body",
            type: "list",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A list of IDs corresponding to folders in the file manager. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/reference/api/files/v2/#list-the-folder-metadata",
                children: "this document"
              }), " for getting the folder details."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fileIds",
            schemaGroup: "Body",
            type: "list",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A list of IDs corresponding to files in file manager. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/reference/api/files/v2/#get-file-metadata",
                children: "this document"
              }), " for getting file details."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUE9TVCBVUkw6Cmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vZmlsZW1hbmFnZXIvYXBpL3YzL2ZpbGVzL3NldC1hY2Nlc3MvYmF0Y2gKCkV4YW1wbGUgUE9TVCBib2R5LApjaGFuZ2luZyB0aGUgYWNjZXNzIHNldHRpbmcgZm9yIGFsbCBvZiB0aGUgZmlsZXMgaW4gdHdvIGZvbGRlcnM6CnsKICAiZm9sZGVySWRzIjogWzEyLDQ5XSwKICAiZmlsZUlkcyI6IFtdLAogICJhY2Nlc3MiOiAiUFVCTElDX0lOREVYQUJMRSIKfQoKRXhhbXBsZSByZXNwb25zZToKewogICJwb3J0YWxJZCI6IDYyNTE1LCAKICAibWVzc2FnZSI6ICJTZXQtQWNjZXNzaWJpbGl0eSB0YXNrIHN1Y2Nlc3NmdWxseSBlbnF1ZXVlZCIsIAogICJ1c2VySWQiOiAyNzM2MSwgCiAgInRhc2tJZCI6ICI1ZjA2YzY5Mi0zYjAwLTMyYmQtODcwMS05NTllZTU0MTZkYWUiCn0KClVzZSB0aGUgdGFza0lkIGluIHRoaXMgcmVzcG9uc2UgdG8KY2hlY2sgdGhlIHN0YXR1cyBvZiB0aGUgZW5xdWV1ZWQgdGFzay4="
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}