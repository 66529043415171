"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 175041037980;
const slug = exports.slug = 'guides/crm/public-apps/migrate-a-public-app-to-projects';
const title = exports.title = 'Migrate a public app to the developer projects framework (BETA)';
const name = exports.name = 'Migrate a public app to the developer projects framework (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to migrate an existing public app onto the HubSpot developer projects framework. This will enable your app to include app cards built with the UI extensions SDK.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "migrate-to-the-projects-framework",
  "label": "Migrate to the projects framework",
  "parent": null
}, {
  "depth": 0,
  "id": "add-new-app-cards",
  "label": "Add new app cards",
  "parent": null
}, {
  "depth": 1,
  "id": "enable-local-development",
  "label": "Enable local development",
  "parent": "add-new-app-cards"
}, {
  "depth": 0,
  "id": "development-workflows",
  "label": "Development workflows",
  "parent": null
}, {
  "depth": 1,
  "id": "workflow-for-unlisted-apps",
  "label": "Workflow for unlisted apps",
  "parent": "development-workflows"
}, {
  "depth": 1,
  "id": "workflow-for-app-marketplace-apps",
  "label": "Workflow for App Marketplace apps",
  "parent": "development-workflows"
}, {
  "depth": 2,
  "id": "1.-set-test-account-feature-flag",
  "label": "1. Set test account feature flag",
  "parent": "development-workflows"
}, {
  "depth": 2,
  "id": "2.-begin-local-development",
  "label": "2. Begin local development",
  "parent": "development-workflows"
}, {
  "depth": 2,
  "id": "3.-continue-testing-and-submit-for-review",
  "label": "3. Continue testing and submit for review",
  "parent": "development-workflows"
}, {
  "depth": 0,
  "id": "rolling-out-updates-to-the-app-marketplace",
  "label": "Rolling out updates to the App Marketplace",
  "parent": null
}, {
  "depth": 1,
  "id": "add-card-update-guidance",
  "label": "Add card update guidance",
  "parent": "rolling-out-updates-to-the-app-marketplace"
}, {
  "depth": 1,
  "id": "gradually-roll-out-your-app-cards",
  "label": "Gradually roll out your app cards",
  "parent": "rolling-out-updates-to-the-app-marketplace"
}, {
  "depth": 1,
  "id": "roll-out-to-new-installs",
  "label": "Roll out to new installs",
  "parent": "rolling-out-updates-to-the-app-marketplace"
}, {
  "depth": 1,
  "id": "roll-out-to-a-subset-of-accounts",
  "label": "Roll out to a subset of accounts",
  "parent": "rolling-out-updates-to-the-app-marketplace"
}, {
  "depth": 1,
  "id": "roll-out-to-all-accounts-simultaneously",
  "label": "Roll out to all accounts simultaneously",
  "parent": "rolling-out-updates-to-the-app-marketplace"
}, {
  "depth": 0,
  "id": "feature-flag-api-reference",
  "label": "Feature flag API reference",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      em: "em",
      a: "a",
      h1: "h1",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      pre: "pre",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The features described in this guide are in Early Access beta, separate from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM development tools to build UI extensions with React as frontend"
        }), " beta for private app UI extension development."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/build-app-cards",
          children: "Request access to the UI extensions for public apps beta"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate a public app to the developer projects framework (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the CLI, you'll migrate an existing production public app to the projects framework. App features that are supported by projects, such as auth configuration and webhooks, will be included automatically in the migration. Features not supported by projects, such as custom workflow actions and timeline events, can be configured through the app settings UI or API as before."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This guide assumes that you meet the following criteria:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "You have an existing public app that has not yet been converted to the developer projects framework."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You are ready to migrate your public app to the developer projects framework, enabling the creation of UI extensions for your app. If you want to start with a dry run of the migration, you can instead make a copy of your app in a project by running ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs project clone-app"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You have ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/setup",
            children: "set up your local environment for project development"
          }), " and set your developer account as the default in the CLI."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["To view your connected accounts, run ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "hs accounts list"
              }), " in the terminal. The terminal will list all currently connected accounts, along with the default."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/cli-default-account.png",
                alt: "cli-default-account"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If your developer account is connected but not the default, run ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "hs accounts use <accountName>"
              }), "."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If you haven't connected your developer account to the CLI yet, revisit the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/crm/setup",
                children: "setup guide"
              }), "."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrate to the projects framework"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To begin migration, you'll switch your public app to be configured using projects. This process will preserve the original auth credentials, and all other existing app features, App Marketplace listings, and app installs. No changes are required in your app backend, and customers will not experience any interruptions in service."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure you've installed the latest version (v6.1.0 or greater) of the HubSpot CLI by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i -g @hubspot/cli@latest"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli@latest\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With your developer account connected to the CLI and set as the default, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project migrate-app"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project migrate-app\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "public app"
        }), " that you'd like to migrate. This will create a new project containing a single public app component that represents the current state of your app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/cli-migrate-app-selection%20(1).png",
        alt: "cli-migrate-app-selection (1)"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "local path"
        }), " for your project, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". The terminal will then display a message to outline the migration process and confirm your intention to convert the app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/hs-project-migrate-cli-confirm.png",
        alt: "hs-project-migrate-cli-confirm"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), " to confirm that you're ready to proceed with migration."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The migration process will then begin, and the terminal will display the migration status. The migration includes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Creating the project in your app developer account."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Converting the project-supported features of the app to source code files, which you can use int he future to update feature configuration."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Building and deploying the new project (Build #1), which completes the association between the public app and its project. This will preserve the original auth credentials, all app features, App Marketplace listing, and installs."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Downloading the new project source files to the specified local directory."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/build-succeeded-png.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With build #1 succeeded, you'll now have captured your original app's configuration state. As you continue to build your app and UI extensions, you can always safely revert to this state by redeploying build #1 by running the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project deploy --buildId=1"
      }), " command."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before you can begin UI extension development and run a local development server, deploy the successful build by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project deploy"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project deploy\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " after migration, features defined in the project source code will no longer be editable through HubSpot’s app management UI or developer APIs. You’ll instead need to manage those locally through the project using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), ". Other features, such as custom workflow actions or timeline events, can continue to be managed in the developer account app settings UI as before."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the app successfully migrated, you can now update the public app with app cards."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add new app cards"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After the migration is complete, you can add ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "UI extensions"
      }), " to it to customize the HubSpot UI with app cards, along with any other required app feature definition changes from your local development project to the new project-based public app. For guidance around building UI extensions, you can:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/quickstart",
          children: "public app quickstart guide"
        }), " to see HubSpot's boilerplate project template, which includes a simple app card."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Check out ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sample-extensions/overview",
          children: "HubSpot's sample UI extensions"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the utilities available in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "UI extensions SDK"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Browse the available ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "UI components"
        }), " for building the UI of your cards."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Enable local development"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While developing a UI extension, you can start a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/local-development",
        children: "local development server"
      }), " to see your changes in the browser in real-time without needing to upload. To enable local development:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install dependencies by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i"
        }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "src/app/extensions"
        }), " directory."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create#adding-cards-to-the-ui",
          children: "Add the card to the UI"
        }), " location through the account's settings. For apps that are currently listed on the App Marketplace, you'll need to complete this step after ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#set-test-account-feature-flag",
          children: "setting the test account feature flag"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Development workflows"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your development workflow for building out your app will depend on whether it's currently listed in the App Marketplace:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Migrated apps that are ", (0, _jsxRuntime.jsx)("u", {
          children: "listed in the App Marketplace"
        }), " will automatically be set with a feature flag to prevent your deployed changes from rolling out to active installs. You will need to selectively enable accounts for development and testing using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/app-management/feature-flags",
          children: "feature flag API"
        }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#workflow-for-app-marketplace-apps",
          children: "development workflow for listed apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Migrated apps that are ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " listed in the App Marketplace will not be set with feature flags by default, so your deployed changes will be available in the accounts where the app is currently installed. However, you can enable feature flagging for your app with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/app-management/feature-flags",
          children: "feature flag API"
        }), " if you would like to selectively limit access during the development phase. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#workflow-for-unlisted-apps",
          children: "development workflow for unlisted apps"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Workflow for unlisted apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your app is not listed in the App Marketplace, you can begin developing the new app card functionality without needing to set any feature flags on your account. If you do want to selectively control which accounts can access your app's new cards, you can use the feature flags API to control each account's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flagState"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-local-development",
          children: "Enable local development"
        }), ", then start the local development server by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project dev"
        }), " in the terminal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Continue building out your UI extension. The local development server will pick up any changes saved in the React extension file. If you make any changes to non-React files, such as the .json config files, you'll need to end the server and run the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), " command."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When you're satisfied with your changes, upload and build the project by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Deploy all updates by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project deploy"
        }), ", or through the project management UI in HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project deploy\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Alternatively, you can run both upload and deploy commands at the same time by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload && hs project deploy"
        }), ". You can also ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/create-a-project#view-the-project-in-hubspot",
          children: "enable automatic deploy on successful build"
        }), " in the project's settings."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Workflow for App Marketplace apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your app is listed in the App Marketplace, your migrated app will automatically have a feature flag enabled that controls access to app cards in the accounts where the app is installed. You'll need to use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/feature-flags",
        children: "feature flag API"
      }), " to selectively enable accounts to use the new app cards. By default, app cards for listed public apps are restricted to a maximum of ", (0, _jsxRuntime.jsx)("u", {
        children: "five"
      }), " accounts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "1. Set test account feature flag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To start developing new app cards without impacting production accounts, first ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#developer-test-accounts",
        children: "create a test account"
      }), " within your developer account if you haven't done so yet. Then, using your developer account's developer API key, set your test account's feature flag to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ON"
      }), " by making a request to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/feature-flags",
        children: "feature flag API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XPUT -s --json '{\"flagState\": \"ON\"}' 'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards/portals/{portalId}?hapikey={developerApiKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your test account feature flag configured, you'll be able to view new app cards in HubSpot as you develop your UI extensions."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "2. Begin local development"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-local-development",
          children: "Enable local development"
        }), ", then start the local development server by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project dev"
        }), " in the terminal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select your developer test account, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ". The terminal will update with a message displaying the status of the development server. You can press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "q"
        }), " at any time to end the server."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you receive an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Unable to find extensions files"
        }), " error when attempting to start the local development server, ensure that you've added the ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/crm/public-apps/creating-public-apps#app-configuration",
          children: ["extensions definition in your ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "public-app.json"
          }), " file"]
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "When you're satisfied with your updates, upload your changes to your developer account."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When the build succeeds, deploy your changes by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project deploy"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project deploy\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Alternatively, you can run both upload and deploy commands at the same time by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload && hs project deploy"
        }), ". You can also ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/create-a-project#view-the-project-in-hubspot",
          children: "enable automatic deploy on successful build"
        }), " in the project's settings."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "3. Continue testing and submit for review"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can continue testing your app and its new cards in additional accounts by using the feature flag API to set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flagState"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ON"
      }), ", as shown in the first step above. You can enable this flag for up to five accounts, including beta customers."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've sufficiently tested your updated app, you'll need to submit it for review by emailing details to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:app-card-review@hubspot.com",
        children: "app-card-review@hubspot.com"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If at any point you want to start over, you can always safely revert the app by redeploying build #1 by running the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project deploy --buildId=1"
        }), " command."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rolling out updates to the App Marketplace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After receiving approval for unlimited distribution from the App Card marketplace review process, your app will be ready for distribution. Before proceeding with app distribution, it's recommended to consider the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If your app previously included a classic CRM card in the sidebar, consider ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-card-update-guidance",
          children: "adding card update guidance"
        }), " so that users can seamlessly add your new cards and remove the old ones."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["It’s recommended to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#gradually-roll-out-your-app-cards",
          children: "gradually roll out your app card updates"
        }), " to catch any issues before distributing to the full install base. You can also ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#roll-out-to-all-accounts-simultaneously",
          children: "roll out to all installed accounts"
        }), " at the same time."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add card update guidance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To help users migrate to your new cards, HubSpot includes a default update state that you can apply to your classic cards. This update includes messaging to indicate that the card has available updates."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For account admins, a link to the app's settings page will be included. Non-admin users will see similar messaging, but will be guided to contact their account admin to assist with setup."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Admin view"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Non-admin view"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://knowledge.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/old-crm-card-upgrade-banner.png?width=383&height=539&name=old-crm-card-upgrade-banner.png",
              alt: "super-admin-card-migration-view"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://knowledge.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/non-admin-card-upgrade-banner.png?width=371&height=521&name=non-admin-card-upgrade-banner.png",
              alt: "non-super-admin-card-migration-view"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add this state to your classic cards, include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"showMigrationMessage\": \"true\""
      }), " in the card's JSON response. This should be included at the top-level of the response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "response.status(200).send({\"showMigrationMessage\": \"true\", \"results\": [crmCardDetails]});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alternatively, you can build your own custom update state by manually updating the card's JSON response directly, or even using the feature flag API for conditional responses."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the default update state enabled for your classic cards, their content will be replaced, and you can walk through what the end-user experience will be for super admins who want to upgrade to your new cards:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "In the test account, navigate to a CRM record that contains your classic card."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the CRM record, locate the card, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Set up now"
          }), " link to navigate to the app settings page."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/set-up-card-now.png",
            alt: "app-card-update-card"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["The app settings page will display all available cards. Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "link"
          }), " provided for each card to navigate to the customization page for that object."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-settings-page.png",
        alt: "app-settings-page"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Users can then proceed to customize their record pages as needed. They can find your new cards within the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Apps"
        }), " section of the customization sidebar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/customize-ui-card-added.png",
        alt: "customize-ui-card-added"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["After adding the new card, the old card can be removed by locating it in the view editor then clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Remove"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/remove-card-from-sidebar.png",
            alt: "remove-card-from-sidebar"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the card updating user experience on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/install-and-manage-app-cards",
        children: "HubSpot's Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gradually roll out your app cards"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/feature-flags",
        children: "feature flag API"
      }), ", you can gradually roll out your app in two ways:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#roll-out-to-new-installs",
          children: "Roll out app cards to new installs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#roll-out-to-a-subset-of-accounts",
          children: "Roll out app cards to a subset of accounts"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Roll out to new installs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To roll out your app cards to new installs only, you'll use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/feature-flags",
        children: "feature flag API"
      }), " to turn off the feature flag for all existing installs. Then, you'll turn on the default flag state so that new installs have the flag enabled by default."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For the first request, you'll set the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "flagState"
        }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OFF"
        }), " for all accounts that currently have the app installed. You'll need to gather the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "portalId"
        }), " for all existing installed accounts, then make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards/portals/batch/upsert?hapikey={developerAPIKey}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XPOST -s --json\n\"{\n  \"portalStates\": [\n    {\n      \"portalId\": 1234,\n      \"flagState\": \"OFF\"\n    },\n    {\n      \"portalId\": 4567,\n      \"flagState\": \"OFF\"\n    },\n    {\n      \"portalId\": 78910,\n      \"flagState\": \"OFF\"\n    }\n  ]\n}\" 'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards/portals/batch/upsert?hapikey={developerAPIKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With existing app installs set to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OFF"
        }), ", you can now set the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultState"
        }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ON"
        }), ". To do so, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XPUT -s --json\n\"{\n  \"defaultState\": \"ON\"\n}\"\n'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Once you feel confident in new customer adoption, you can begin to remove customer ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "portalIds"
        }), " that you switched to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OFF"
        }), " state by making a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards/portals/batch/delete?hapikey={developerAPIKey}"
        }), " with the account IDs you want to remove from the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OFF"
        }), " list."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XPOST -s --json\n\"{\n  \"portalIds\": [\n    1234,\n    4567,\n    78910\n  ]\n}\"\n'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards/portals/batch/delete?hapikey={developerAPIKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To check which accounts still have their flag set to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OFF"
        }), ", you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/flags/hs-release-app-cards/portals/?hapikey={developerAPIKey}"
        }), ". Learn more in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/app-management/feature-flags",
          children: "feature flag API reference documentation"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When all previously added accounts have been deleted using the above endpoint, you''ll have successfully completed the rollout and finished migration. At this point, you can safely delete your feature flag by making a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XDELETE -s\n'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Roll out to a subset of accounts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alternatively, rather than starting with new installs, you can selectively enable your app cards for a subset of accounts that have your app installed. To do so, you'll need to have a list of the account IDs for all existing public app installs. With that list, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards/portals/batch/upsert?hapikey={developerAPIKey}"
      }), " and set their ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flagState"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ON"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XPOST -s --json\n\"{\n  \"portalStates\": [\n    {\n      \"portalId\": 1234,\n      \"flagState\": \"ON\"\n    },\n    {\n      \"portalId\": 4567,\n      \"flagState\": \"ON\"\n    },\n    {\n      \"portalId\": 78910,\n      \"flagState\": \"ON\"\n    }\n  ]\n}\"\n'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards/portals/batch/upsert?hapikey={developerAPIKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can continue making this request for subsets of accounts until all accounts have been migrated. Then, you can delete your feature flag by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XDELETE -s\n'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Roll out to all accounts simultaneously"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To release your app cards to all installed accounts simultaneously, delete the app's feature flag by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -XDELETE -s\n'https://api.hubapi.com/feature-flags/v3/{appId}/flags/hs-release-app-cards?hapikey={developerAPIKey}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feature flag API reference"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the feature flag API to control availability of your app cards in customer accounts. All endpoints are under the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/feature-flags/v3/{appId}"
      }), " root path. The API currently supports a single App Flag: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-release-app-cards"
      }), ". Attempts to specify other App Flags will receive an error."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["View the full ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/feature-flags",
        children: "feature flag API reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "To streamline making requests to the feature flags API, check out the following HubSpot-provided resources:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/hubspot/hubspot-public-api-workspace/folder/26126890-0f7c11b0-0f48-44cf-9ca1-1ab8dc72dbeb",
            children: "Postman collection"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/uie-beta-public-app-samples/tree/main/feature-flag-scripts",
            children: "Collection of pre-written shell scripts"
          })
        }), "\n"]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}