"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49802411384;
const slug = exports.slug = 'guides/cms/marketplace/listing-templates';
const title = exports.title = 'List and update templates in the HubSpot Template Marketplace';
const name = exports.name = 'List and update templates in the HubSpot Template Marketplace';
const metaDescription = exports.metaDescription = 'Once you’ve created a Template Marketplace provider account, you can use it to create template listings and then submit them for approval. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-template-listing",
  "label": "Create a template listing",
  "parent": null
}, {
  "depth": 0,
  "id": "update-a-template-listing",
  "label": "Update a template listing",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      img: "img",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "List and update templates in the HubSpot Template Marketplace"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you’ve created a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "Template Marketplace provider account"
      }), ", you can use it to create template listings and submit them for approval to be listed on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "HubSpot Template Marketplace"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can only have ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "one"
        }), " new submission of each type (module or template) in review at a time. A submission is considered “new” if it’s not currently published or approved."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before listing your template on the Template Marketplace, fill out your Provider profile:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your Template Marketplace provider account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Template Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider profile"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Company Info"
        }), " section, fill out all the fields. These fields will appear on your template listings. You will not be able to edit your company name once it's been chosen and will need to contact HubSpot Support to change it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contact Info"
        }), " section, fill out all the fields for both the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Main contact"
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Developer contact"
        }), " sections. This information will only be used if HubSpot needs to contact you about a listing and ", (0, _jsxRuntime.jsx)("u", {
          children: "will not"
        }), " appear on any listing pages."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Info"
        }), " section, add your supported payment methods. You can connect either PayPal or Stripe as a payment gateway. If your payment information causes the payment gateway to stop working, your templates will be temporarily delisted to avoid a negative customer impact."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a template listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create and submit a theme or module listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your Template Marketplace provider account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Template Marketplace"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create listing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select either ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create theme listing"
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create module listing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the information on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Provider Information"
        }), " page, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review your payment information on the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Information"
        }), " page, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme or module"
        }), " you want to list, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save and continue"
        }), ". You'll then be brought to the listing editor"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/theme-listing-marketplace.png",
        alt: "theme-listing-marketplace"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The listing editor is broken up into five tabs: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Listing Info"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Theme/Module Details"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Category"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Support"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Review"
      }), ". All information is required unless otherwise indicated. Review all listing page requirements ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/marketplace-guidelines/general-requirements",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Review"
        }), " tab, validate your template’s code and preview your listing. If everything looks good, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Submit for review."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " do not change the template folder name once submitted. The template listing is directly linked to this folder upon submission. You will not be able to resubmit or update the template after changing the folder name."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update a template listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After creating a template listing, you can update it in your Template Marketplace provider account. If you've made changes to a template, you'll need to revalidate it before those changes are pushed out. If you do not revalidate, only the content on the Marketplace listing page will be updated."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To update a template listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your Template Marketplace provider account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Template Marketplace"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the listing you want to update. You'll be redirected to the listing editor."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Make any needed changes to the listing information, template details, categories, and support options, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Review"
          }), " tab to review your changes."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Review"
          }), " tab, check whether your template's code has changed since its last validation:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you've made no changes to the template, HubSpot will display a confirmation message in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Theme source code"
            }), " tile or ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Module source code"
            }), " tile showing that your validation is up to date."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-validation-up-to-date.png",
            alt: "asset-marketplace-validation-up-to-date"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you've made changes to the template, HubSpot will display an alert in the source code tiles. To send these changes to the Marketplace, you'll first need to click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Run validation"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-run-validation.png",
            alt: "asset-marketplace-run-validation"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "5."
          }), " On the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Submit for review"
          }), "."]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}