"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710044;
const slug = exports.slug = 'guides/cms/content/performance/overview';
const title = exports.title = 'CDN, security, and performance overview';
const name = exports.name = 'CDN, security, and performance overview';
const metaDescription = exports.metaDescription = 'The HubSpot CMS offers security, reliability, performance, and maintenance tools plus a global and fast CDN';
const toc = exports.toc = [{
  "depth": 0,
  "id": "content-delivery-network-cdn",
  "label": "Content Delivery Network (CDN)",
  "parent": null
}, {
  "depth": 0,
  "id": "secure-socket-layer-ssl",
  "label": "Secure Socket Layer (SSL)",
  "parent": null
}, {
  "depth": 0,
  "id": "http%2F2",
  "label": "HTTP/2",
  "parent": null
}, {
  "depth": 0,
  "id": "ipv6",
  "label": "IPv6",
  "parent": null
}, {
  "depth": 0,
  "id": "javascript-and-css-minification",
  "label": "JavaScript and CSS minification",
  "parent": null
}, {
  "depth": 0,
  "id": "domain-rewriting",
  "label": "Domain rewriting",
  "parent": null
}, {
  "depth": 0,
  "id": "text-compression",
  "label": "Text compression",
  "parent": null
}, {
  "depth": 0,
  "id": "image-compression%2C-optimization%2C-and-automatic-resizing",
  "label": "Image compression, optimization, and automatic resizing",
  "parent": null
}, {
  "depth": 0,
  "id": "accelerated-mobile-pages-amp",
  "label": "Accelerated Mobile Pages (AMP)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CDN, security, and performance overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot's CMS includes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/security",
        children: "security"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/reliability",
        children: "reliability"
      }), ", and performance solutions so that you can focus on writing code and creating delightful user experiences. Below, learn more about HubSpot's content delivery network, security features, and performance enhacements."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to the features listed in this article, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "further optimize performance on HubSpot CMS."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Content Delivery Network (CDN)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's CMS content is powered by a globally distributed Content Delivery Network that ensures faster page load times regardless of where your visitors are. No configuration, setup, or additional accounts are required to take advantage of the CDN for hosting media or pages, as HubSpot automatically handles distribution and cache validation. The CDN also features a web application firewall and built-in security to provide peace of mind against online attacks."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/prerendering",
        children: "content prerendering service"
      }), " also takes advantage of the CDN by storing local copies of prerendered pages across the globe. This enables users worldwide to load your pages faster based on their location. When a page or any dependency of a page, such as a template or module, changes, HubSpot automatically expire the server caches for that page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secure Socket Layer (SSL)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["SSL is included and automatically provisioned for free on all ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/domains-and-urls/connect-a-domain-to-hubspot",
        children: "connected domains."
      }), " Each domain is provisioned its own SAN certificate, with configurable options such as disabling support for select versions of TLS, redirecting requests made over HTTP to HTTPS, and serving the HSTS header so future requests are made over HTTPS. Per request, custom SSL certificates can be hosted with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "custom SSL add-on"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTTP/2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All SSL traffic on HubSpot hosted websites is served using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://http2.github.io/",
        children: "HTTP/2"
      }), ". HTTP/2 is a replacement for how HTTP is expressed “on the wire.” It is not a ground-up rewrite of the protocol; HTTP methods, status codes, and semantics are the same, and it's possible to use the same APIs as HTTP/1.x (possibly with some small additions) to represent the protocol."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The focus of the protocol is on performance; specifically, end-user perceived latency, network and server resource usage. One major goal is to allow the use of a single connection from browsers to a website."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "IPv6"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All HubSpot hosted websites include IPv6 addresses so they can be natively accessed over ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.google.com/intl/en/ipv6/index.html",
        children: "IPv6"
      }), ". IPv6 is the most recent version of the Internet Protocol and expands the number of available addresses to a virtually limitless amount–340 trillion trillion trillion addresses. Because the internet is running out of IPv4 addresses, transition to IPv6 enables the internet to continue to grow and enables new, innovative services to be developed because more devices can connect to it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript and CSS minification"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When writing JavaScript and CSS for HubSpot pages, you can expect the following minification behavior:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot automatically minifies JavaScript and CSS included in the design manager to remove unnecessary spaces, line breaks, and comments. This also applies to JavaScript and CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "uploaded to the design manager through the CLI"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " because HubSpot automatically minifies JavaScript and CSS in the design manager, you should ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " add minified code directly to the design manager."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To include minified code, you should instead upload the file to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/file-manager",
          children: "file manager"
        }), ", then attach the file through the design manager. To link a minified file to a module locally, you can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
          children: "update the module's meta.json to include css_assets or js_assets"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpot does not minify JavaScript or CSS files that are uploaded to the file manager or referenced via external links. You should ensure these files are minified before upload."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Every time you update a JavaScript or CSS file in the design manager or through local upload, HubSpot will automatically re-minify it. This can result in a short delay before you see the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".min.js"
        }), " version of your file being served on live pages. During that period, HubSpot will serve the unminified version to ensure site visitors still get the latest version of your files."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Syntax errors can prevent HubSpot from being able to minify a file."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to minification, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "use HubL includes to combine multiple CSS files into one file"
      }), " to further increase performance."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Domain rewriting"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each additional domain used on your website incurs an additional DNS lookup and connection. The fewer domains you use, the faster your website will load. HTTP/2 supports loading multiple files simultaneously over the same connection, so the old guidelines for “sharding” your assets amongst multiple domains no longer apply."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The URLs of assets referenced in CMS pages such as developer file system files, CSS, JS, and images are automatically rewritten to match the domain of the current page when possible. So if you reference an image at ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg",
        children: "http://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg"
      }), " on a page served on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/",
        children: "www.hubspot.com"
      }), ", the URL will automatically update to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg",
        children: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Text compression"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Text-based files such as HTML, CSS, and JS are all compressed using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/brotli",
        children: "brotli"
      }), " before they are served to browsers. Compression with brotli is even more significant than GZIP. If the client does not indicate that Brotli compression is supported, then gzip compression will be applied."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While minification speeds up the parse time of CSS and JS files in the browser, compression gets those files to the browser faster."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Image compression, optimization, and automatic resizing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When you upload an image to the file manager, images are automatically optimized. Specifically, JPEGs and PNGs are stripped of their metadata (such as EXIF data). All images except for GIF files are recompressed to be visually lossless. Additionally, HubSpot may serve images in a different encoding if it can be represented as a smaller PNG than a JPEG."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Images are re-saved at the web-standard 72dpi regardless of their original resolution. For example, if you upload a file at 300dpi, originally created for print, it will be converted to 72dpi."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot automatically serves images in a WebP format for browsers that support it when the WebP format is a smaller file size than the original image. This conversion happens server-side and does not change the file extension in the URL. To ensure that links to the image work for all visitors regardless of WebP support, image URLs maintain their original format, meaning an image uploaded as a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), ", will still show as a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " in the URL but will be served as a WebP."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-hosted images in CMS content are also automatically resized by appending height and/or width query parameters to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " URL for any images that have a height or width attribute. If a page is requested before an image is resized, the non-resized image will be served for that request. Browsers are given multiple options for the image resolution to load, ensuring that images look crisp on standard and high-resolution displays."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/img_opt.png",
        alt: "Screenshot of img element with srcset automatically added with different resize URLs"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["For individual jpg files if the image url has the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "quality=high"
        }), " query parameter, the image will not be compressed."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition, you can provide HubSpot with additional context to images to further control image resizing. This is done through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#resize-image-url",
        children: "resize_image_url()"
      }), " function, which prevents content creators from displaying oversized images on pages and emails. The function can also be useful when an image's size is not dictated by height and width attributes within the HTML, such as a background image."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Accelerated Mobile Pages (AMP)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["AMP, or Accelerated Mobile Pages, is a mobile-specific page format that loads content nearly instantaneously, and can be enabled for HubSpot blog posts in your account settings. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "using Accelerated Mobile Pages (AMP) in HubSpot"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}