"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358694;
const slug = exports.slug = 'guides/apps/marketplace/listing-your-app';
const title = exports.title = 'Marketplace des applications# Lister votre application';
const name = exports.name = 'vNext Docs DP - Lister votre application';
const metaDescription = exports.metaDescription = 'Suivez ces étapes pour soumettre une application pour la liste du Marketplace des applications de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9ez-et-soumettez-une-liste-d-applications",
  "label": "Créez et soumettez une liste d'applications",
  "parent": null
}, {
  "depth": 0,
  "id": "informations-sur-le-listing",
  "label": "Informations sur le listing",
  "parent": null
}, {
  "depth": 0,
  "id": "d%C3%A9tails-de-l-application",
  "label": "Détails de l'application",
  "parent": null
}, {
  "depth": 0,
  "id": "tarification",
  "label": "Tarification",
  "parent": null
}, {
  "depth": 0,
  "id": "fonctionnalit%C3%A9s-de-l-application",
  "label": "Fonctionnalités de l'application",
  "parent": null
}, {
  "depth": 0,
  "id": "informations-sur-le-support",
  "label": "Informations sur le support",
  "parent": null
}, {
  "depth": 0,
  "id": "v%C3%A9rifier-les-informations-et-soumettre-votre-listing",
  "label": "Vérifier les informations et soumettre votre listing",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-et-mettre-%C3%A0-jour-un-listing-localis%C3%A9-pour-un-listing-d-application-existant",
  "label": "Créer et mettre à jour un listing localisé pour un listing d'application existant",
  "parent": null
}, {
  "depth": 0,
  "id": "modifier-un-listing-d-application-en-ligne",
  "label": "Modifier un listing d'application en ligne",
  "parent": null
}, {
  "depth": 0,
  "id": "annuler-la-publication-d-un-listing-d-application-en-ligne",
  "label": "Annuler la publication d'un listing d'application en ligne",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Lister votre application"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez créé une application dans votre compte de développeur qui répond aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "conditions relatives aux listings sur le marketplace des applications"
      }), ", vous pouvez soumettre un listing pour l'ajouter au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace des applications de HubSpot"
      }), ". L'équipe de qualité de l'écosystème HubSpot analysera votre demande et vous fera savoir par e-mail si l'application a été approuvée ou rejetée."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Remarque : **vous devez être un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "super administrateur"
        }), " pour mettre à jour et soumettre une liste d'applications."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans cet article :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Créez et soumettez une liste d'applications"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-update-a-localized-listing-for-an-existing-app-listing",
          children: "Créez et mettez à jour un listing localisé pour un listing d'application existant"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Ajoutez des points de contact pour le Programme Partenaires d'applications"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#edit-localized-listing",
          children: "Modifiez un listing d'application en ligne"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unpublish-a-live-app-listing",
          children: "Annulez la publication d'un listing d'application en ligne"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créez et soumettez une liste d'applications"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Remarque : **avant de soumettre un listing d'application, consultez la page ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "Conditions relatives aux listings d'applications"
        }), " pour savoir comment élaborer votre listing."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "compte de développeur d'application"
        }), ", accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace des applications"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer une liste"
        }), " dans l'angle supérieur droit. Si ce bouton est grisé, des listes ont déjà été créées pour toutes vos applications existantes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez l'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "application"
        }), " pour laquelle vous souhaitez créer un listing et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivant"
        }), ". Les applications qui sont déjà répertoriées sur le Marketplace des applications n'apparaîtront pas ici."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur l'écran suivant, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sélectionner les langues dans lesquelles votre application est disponible"
        }), " et sélectionnez les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "langues"
        }), " dans lesquelles votre logiciel d'application est proposé. Le marketplace des applications est disponible en huit langues : anglais, espagnol, français, allemand, portugais, japonais, néerlandais et italien. Vous pouvez créer des listes d'applications dans autant de ces langues que vous le souhaitez, tant que le logiciel de votre application est disponible dans ces dernières."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sélectionner la langue de listing principale pour [nom de l'application]"
        }), " et sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "langue par défaut"
        }), " que les utilisateurs verront lorsqu'ils navigueront sur le marketplace des applications."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivant"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L’assistant de listing d’applications dispose de cinq onglets d’informations à remplir."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informations sur le listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informations sur les listes"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informations sur l’application"
          }), ", ajoutez le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Nom public de votre application"
          }), ", le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Nom de votre entreprise"
          }), ", le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Slogan"
          }), " et l'", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL du bouton d'installation"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "icône d'application"
          }), ", chargez une icône de 800 par 800 pixels pour votre application. Celle-ci apparaîtra dans le Marketplace des applications et les comptes des utilisateurs connectés. Les meilleures pratiques pour les icônes sont les suivantes :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "À faire"
            }), " : utiliser un fichier JPG, JPEG ou PNG, remplir tout l'espace (800 par 800 pixels) - votre image doit toucher au moins deux bords, et utiliser une image haute résolution, non pixellisée."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "À ne pas faire"
            }), " : inclure du texte dans votre icône, utiliser un mot-symbole ou laisser un espace blanc supplémentaire autour de votre icône."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/dev-doc-logo.png",
        alt: "dev-doc-logo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Catégoriser votre application"
        }), ", sélectionnez une ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "catégorie"
        }), " pour votre application et tous les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "termes de recherche"
        }), " qui peuvent être utilisés pour la trouver sur le Marketplace des applications."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Détails de l'application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l’onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Détails de l’application"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Vidéo de démonstration"
        }), ", chargez une ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "vidéo"
        }), " pour montrer le fonctionnement de votre application. Reportez-vous à la page ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
          children: "Comment créer une excellente vidéo de démonstration d'application"
        }), " pour connaître les bonnes pratiques ainsi que des exemples de création d'une vidéo de démonstration."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Captures d’écran"
        }), ", ajoutez des ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "images"
        }), " et du ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "texte alternatif"
        }), " montrant le fonctionnement de votre application. Vous pouvez ajouter jusqu’à huit images.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Cliquez sur Ajouter des ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "données partagées"
            }), ". Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Données partagées"
            }), ", indiquez aux utilisateurs comment les données circuleront entre votre application et HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Dans le panneau de droite, sélectionnez quel objet de l'application se synchronise avec quel objet de HubSpot, et la direction de la synchronisation. Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Vue d'ensemble de l'application"
            }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "décrivez"
            }), " brièvement comment votre application peut aider les utilisateurs à atteindre leurs objectifs."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour ajouter une autre synchronisation d'objets, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Ajouter un autre objet"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/shared-data-app-listing.png",
        alt: "shared-data-app-listing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Caractéristiques de HubSpot avec lesquelles votre application fonctionne"
        }), ", ajoutez jusqu'à 10 outils et caractéristiques HubSpot avec lesquels votre application fonctionne."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Autres outils avec lesquels votre logiciel s'intègre"
        }), ", sélectionnez jusqu'à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "six autres outils ou applications"
        }), " avec lesquels votre logiciel s'intègre."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Langues dans lesquelles votre application est disponible"
        }), ", sélectionnez toutes les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "langues"
        }), " disponibles pour votre application. Vous ne pouvez créer des listes Marketplace des applications supplémentaires que dans ces langues."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tarification"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l’onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Tarification"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section supérieure, sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "devise"
          }), " dans laquelle vous souhaitez lister l'application. Vous pouvez choisir parmi plus de 100 devises."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Vous pouvez également définir des ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "plans tarifaires"
          }), " pour votre application en ajoutant le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "modèle tarifaire"
          }), ", le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom de l’abonnement"
          }), ", le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "slogan"
          }), ", les ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "détails de la tarification"
          }), " et la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "liste des fonctionnalités"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["En fonction du modèle tarifaire choisi, vous devrez peut-être ajouter d'autres informations, comme la fréquence de paiement, les frais uniques ou les tarifs mensuels. Survolez ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "l'icône d'information"
              }), " avec la souris pour en savoir plus sur chaque modèle tarifaire."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/pricing-model-app-listing.png",
                alt: "pricing-model-app-listing"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Vous pouvez ajouter jusqu'à cinq plans tarifaires. Pour ajouter des plans tarifaires supplémentaires, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Ajouter un autre abonnement."
              })]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Lien vers le plans tarifaires de votre logiciel"
          }), ", saisissez l’élément ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " où les utilisateurs peuvent trouver plus d’informations sur vos plans tarifaires."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Plans tarifaires de l'agence"
          }), ", saisissez l'élément ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " où les utilisateurs peuvent se renseigner sur la tarification des services de partenariat ou de conseil."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonctionnalités de l'application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l’onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Fonctionnalités de l’application"
      }), ", ajoutez des fonctionnalités et guidez les clients sur la façon de les utiliser. Il n’y a pas de limite au nombre de fonctionnalités d'application pouvant être créées pour votre application."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter une fonctionnalité"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur la page ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Détails sur la fonctionnalité"
        }), " :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Saisissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nom de votre fonctionnalité"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sélectionnez tous les ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "groupes de domaines"
            }), " dont un client a besoin pour disposer de cette fonctionnalité. Les groupes de domaines sont utilisés pour déterminer si le compte HubSpot du client est compatible avec les fonctionnalités de l’application."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Saisir une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sélectionner une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "image"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sélectionnez l’emplacement où vous souhaitez afficher la fonctionnalité : ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Sur le listing du marketplace de votre application"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "En tant que carte de découverte de fonctionnalité"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez afficher jusqu’à cinq fonctionnalités d’application dans le listing du marketplace des applications et jusqu’à 10 fonctionnalités d’application en tant que cartes de découverte."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous choisissez d’afficher la fonctionnalité en tant que carte de découverte de fonctionnalité :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez un bouton principal :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Lien vers une fonctionnalité"
            }), " : sélectionnez la fonctionnalité HubSpot à laquelle le bouton doit être lié."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Créer une fonctionnalité"
            }), " : saisissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Texte du bouton"
            }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "l'URL du bouton"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Aucun bouton principal"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez un guide pratique pour intégrer vos clients :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Créer un guide à partir de zéro"
            }), " : saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "titre"
            }), ", une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), " et une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "image ou une vidéo"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["**Lien externe vers le guide : **saisissez une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "URL du guide"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Guide vidéo uniquement :"
            }), " chargez une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "vidéo"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour ajouter un autre guide, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter une autre section"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/feature-discovery-card.png",
        alt: "feature-discovery-card"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informations sur le support"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informations sur le support"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations de contact"
        }), ", ajoutez une méthode de contact du support pour les utilisateurs qui ont des questions sur l'utilisation de votre application. Ajoutez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "l'e-mail de votre support"
        }), ", le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "site Web de votre entreprise"
        }), " et les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "langues"
        }), " dans lesquelles le service client est proposé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Ressources support"
        }), ", incluez des ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "liens"
        }), " vers la documentation d'installation de votre application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section _Conditions d'utilisation et Politique de confidentialité _, ajoutez des ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "liens"
        }), " vers votre documentation sur la confidentialité."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vérifier les informations et soumettre votre listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informations d'analyse"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Instructions pour l'évaluation de l'application"
        }), ", saisissez toutes les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "instructions pour l'évaluation de l'application"
        }), " dont l'équipe qualité de l'écosystème HubSpot a besoin pour tester et évaluer votre application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Points de contact du Programme Partenaires d'applications"
        }), ", indiquez les coordonnées de toutes les personnes avec lesquelles il faut communiquer au sujet du listing d'application et du développeur de l'application. Vous devez inclure le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "rôle"
        }), " de la personne, son ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "prénom et son nom"
        }), ", son ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "adresse e-mail"
        }), " et le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "pays"
        }), " où elle est basée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour ajouter un autre point de contact, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter un autre point de contact"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour soumettre le listing pour analyse, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Soumettre pour analyse"
      }), " dans l'angle supérieur droit. Si le bouton ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Soumettre pour analyse"
      }), " est grisé, vérifiez que vous avez renseigné tous les champs requis et que vous disposez des autorisations de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "super administrateur"
      }), ". Si vous avez omis des champs obligatoires, un nombre dans le titre de l'onglet indiquant le nombre de champs omis s'affichera. Cliquez sur chaque onglet pour saisir les informations manquantes, puis revenez à l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informations d'analyse"
      }), " et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Soumettre pour analyse"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features.png",
        alt: "app-features"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer et mettre à jour un listing localisé pour un listing d'application existant"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous devrez définir une langue principale sur votre listing d'application existant et avoir déjà publié le listing principal dans le marketplace de HubSpot afin de créer des listings dans d'autres langues."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte de développeur d'application, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace des applications"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si vous avez déjà une application listée sur le Marketplace, une bannière jaune au-dessus de l'application listée vous demande de définir la langue principale de votre liste. Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Définir maintenant"
          }), ". Vous devrez définir la langue principale de la liste de l'application avant de pouvoir créer de nouvelles listes de langues."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la fenêtre de dialogue, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sélectionner les langues dans lesquelles votre application est disponible"
          }), " et sélectionnez les ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "langues"
          }), " dans lesquelles votre logiciel d'application est disponible."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sélectionner la langue de listing principale pour [nom de l'application]"
          }), " et sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "langue par défaut"
          }), " que les utilisateurs verront lorsqu'ils navigueront sur le marketplace des applications."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enregistrer"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez défini une langue principale, vous pourrez ajouter un nouveau listing localisé :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Survolez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "listing d'application"
        }), " et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Plus"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un listing dans une autre langue"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Langue pour ce listing"
        }), " et sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "langue"
        }), " dans laquelle vous souhaitez créer ce listing."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Suivez les étapes pour créer et soumettre une liste dans la langue sélectionnée."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Toutes les parties de votre listing localisé doivent être dans la même langue, y compris les captures d'écran de produits et les vidéos de démonstration."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "La mise à jour de votre listing principal ne met pas automatiquement à jour vos listings localisés. Chacun doit être mis à jour indépendamment."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modifier un listing d'application en ligne"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois qu'une liste d'applications est en ligne sur le Marketplace des applications, vous pouvez la modifier :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace des applications > Listes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Survolez la liste que vous souhaitez modifier et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Plus"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modifier"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Effectuez vos modifications puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Soumettre pour analyse"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Annuler la publication d'un listing d'application en ligne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace des applications"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passez le curseur de la souris sur le listing dont vous souhaitez annuler la publication et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Plus"
        }), " >", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Annuler la publication du listing en direct"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, saisissez la raison de l'annulation de la publication de l'application, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Soumettre une demande d'annulation de la publication"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les demandes d'annulation de publication sont traitées par l'équipe du marketplace de HubSpot dans les 10 jours ouvrables suivant leur soumission."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}