"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279873;
const slug = exports.slug = 'guides/cms/content/content-staging';
const title = exports.title = 'Content-Staging';
const name = exports.name = 'EMEA DE Content Staging';
const metaDescription = exports.metaDescription = 'Entwicklung-Staging ist eine Entwicklungsumgebung innerhalb der App, die es Ihnen ermöglicht, Staging-Seiten neu zu gestalten oder zu erstellen, bevor Sie sie auf Ihrer Produktionswebsite veröffentlichen.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Content-Staging"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Content-Staging ist eine separate Content-Umgebung innerhalb der App, die es Ihnen ermöglicht, in einer Staging-Umgebung befindliche Seiten zu aktualisieren oder zu erstellen, bevor Sie sie auf Ihrer Produktionswebsite veröffentlichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Content-Staging kann während eines Website-Neugestaltungsprojekts verwendet werden, um vorhandene Seiten mithilfe einer neuen Vorlage in einer Staging-Umgebung bereitzustellen. Analytics, die an die Seite gebunden sind, werden bei der Veröffentlichung aus der Staging-Umgebung auf die neue Seite übertragen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da bei Neugestaltungen in der Regel mehrere Seiten aktualisiert werden, können Sie alle Seiten, die Sie benötigen, in einer Staging-Umgebung bereitstellen und alle gleichzeitig auf der Live-Website veröffentlichen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Detaillierte Anweisungen finden Sie unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "Website mit Content-Staging neu gestalten und neu veröffentlichen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine vollständige Anleitung zum Einsatz von Content-Staging ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "finden Sie in diesem Artikel in der Website-Benutzeranleitung"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Inhaltselemente wie Designs, Vorlagen, Modulen zu entwickeln und Änderungen daran zu testen, empfehlen wir die Verwendung eines ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "Entwickler-Sandbox-Accounts"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Erfahren Sie mehr über das Erstellen eines effizienten Entwicklungsworkflows"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}