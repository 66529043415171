"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611084;
const slug = exports.slug = 'guides/cms/storage/file-manager';
const title = exports.title = 'File Manager';
const name = exports.name = '[new] File Manager';
const metaDescription = exports.metaDescription = 'Using the HubSpot File Manager to host images on the HubSpot CMS content delivery network, and allow content creators to easily add files to content. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "when-to-use-the-file-manager",
  "label": "When to use the File Manager",
  "parent": null
}, {
  "depth": 0,
  "id": "uploading-files-to-the-file-manager",
  "label": "Uploading files to the File Manager",
  "parent": null
}, {
  "depth": 0,
  "id": "using-file-manager-files",
  "label": "Using File Manager files",
  "parent": null
}, {
  "depth": 0,
  "id": "optimizations",
  "label": "Optimizations",
  "parent": null
}, {
  "depth": 0,
  "id": "serving-html-and-js-files-from-the-file-manager",
  "label": "Serving HTML and JS files from the file manager",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "File Manager"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to the developer file system, HubSpot's file manager can be used to store and serve files. Files in the file manager are served over HubSpot's global content delivery network (CDN)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, all files uploaded to the file manager are publicly accessible and may be indexed in search engines. After uploading your files, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/organize-edit-and-delete-files#edit-your-file-s-visibility-settings",
        children: "manage your file's visibility settings"
      }), " to prevent files from being indexed or accessed."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The File Manager can be found at ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/files/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > File and Templates > Files"
        })
      }), " in the top navigation menu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/image-png-May-24-2023-05-49-31-9738-AM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "When to use the File Manager"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Generally, the file manager should be used for files intended to be utilized in file pickers throughout HubSpot. For example, when selecting an image in an image or rich text module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before uploading files for use with the file manager:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Certain ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/files/supported-file-types#files-tool",
          children: "file size and type limits"
        }), " will apply. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content#before-you-get-started",
          children: "what to consider before uploading files"
        }), " to the file manager."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Files uploaded to the file manager cannot be edited within the HubSpot app, other than minor image file editing."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you intend to edit text-based files, they must be stored in the design manager."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Text-based files uploaded to the file manager will not be minified or modified in any way. To take advantage of HubSpot’s ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-minification",
          children: "JavaScript minification"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CSS minification and combination"
        }), ", store these files in the design manager."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uploading files to the File Manager"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Files can be uploaded to the file manager via the following options:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To upload files directly in HubSpot, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content",
          children: "upload files to the file manager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To upload files through the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI,"
        }), " use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload filemanager"
        }), " command."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To upload files using an API, learn more about ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/api/library/files/v3#post-%2Ffiles%2Fv3%2Ffiles",
          children: ["HubSpot's ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Upload a new file"
          }), " API"]
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After uploading your files to the file manager, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/organize-edit-and-delete-files",
        children: "organize and manage your files and file details"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using File Manager files"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Files uploaded to the file manager can be accessed via the following options:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Files uploaded to the file manager are accessible in the various file pickers throughout HubSpot and HubSpot's CMS, such as in rich text or image modules on pages."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Files uploaded to the file manager can be accessed through a direct download link. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/files/provide-a-direct-download-link-to-a-file-hosted-on-the-files-tool",
          children: "retrieve a file's direct download link"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Optimizations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["File Manager files are automatically ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#browser-and-server-caching",
        children: "cached"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#image-compression-optimization-and-automatic-image-resizing",
        children: "compressed and resized to be served efficiently"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#domain-rewriting",
        children: "accessible across all of your hosted domains to reduce cross-origin requests"
      }), ". Learn more about the HubSpot CMS ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "CDN, Security, and Performance"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Serving HTML and JS files from the file manager"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HTML and JavaScript files uploaded to the File Manager and served using a default HubSpot domain to serve files (i.e. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "f.hubspotusercontentXX.net"
      }), "), use Content Type: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text/plain"
      }), " . This means web browsers will not render and evaluate the code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If a user goes directly to an HTML file there it will display the HTML code itself to the user. To avoid this, you must serve these files from one of your connected domains instead of a HubSpot default domain."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}