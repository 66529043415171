"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 169555076495;
const slug = exports.slug = 'reference/ui-components/standard-components/modal';
const title = exports.title = 'Modal';
const name = exports.name = 'Modal | UI components (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the Modal component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "opening-and-closing-modals",
  "label": "Opening and closing modals",
  "parent": null
}, {
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      ul: "ul",
      li: "li",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Modal | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Modal"
      }), " component to render a pop-up overlay containing other components. Like the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), " component, you'll include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Modal"
      }), " component in an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "overlay"
      }), " prop within a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/loading-button",
        children: "LoadingButton"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/link",
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/tag",
        children: "Tag"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/image",
        children: "Image"
      }), " component."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see an example of using overlays, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#overlay-example",
        children: "HubSpot's Overlay example project"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To format the modal, you'll use the following subcomponents:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ModalBody"
        }), " (required): contains the main content of the modal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ModalFooter"
        }), ": an optional component to format the footer section of the modal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/2024-06-24_14-11-51%20(1).gif",
        alt: "2024-06-24_14-11-51 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import React from 'react';\nimport {\n  Button,\n  Modal,\n  ModalBody,\n  Text,\n  hubspot,\n} from '@hubspot/ui-extensions';\n\nhubspot.extend(() => <Extension />);\n\nconst Extension = () => {\n  return (\n    <>\n      <Button\n        overlay={\n          <Modal id=\"default-modal\" title=\"Example modal\" width=\"md\">\n            <ModalBody>\n              <Text>Welcome to my modal. Thanks for stopping by!</Text>\n              <Text>Close the modal by clicking the X in the top right.</Text>\n            </ModalBody>\n          </Modal>\n        }\n      >\n        Open modal\n      </Button>\n    </>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " Required"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The unique identifier for the modal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'sm'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'md'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'large'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'lg'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The width of the modal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of the modal, displayed at in the modal's top bar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'default'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'danger'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of modal. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants section"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria-label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The modal's accessibility label."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onOpen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "() => void"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the modal has finished opening."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClose"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "() => void"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the modal has finished closing."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Opening and closing modals"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot handles opening the modal when the user clicks the parent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/loading-button",
        children: "LoadingButton"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/link",
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/tag",
        children: "Tag"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/image",
        children: "Image"
      }), " component. A close button will also be included in the top right of the modal."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-modal-default-close.png",
        alt: "uie-components-modal-default-close"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition, you can add a close mechanism to a modal using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LoadingButton"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Tag"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Image"
      }), " with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onClick"
      }), " event that triggers the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "closeOverlay"
      }), " action. To use this action, you'll need to include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#registering-the-extension",
        children: "actions argument"
      }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend()"
      }), " as seen in the example code below."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#open-an-overlay",
        children: "opening and closing overlays"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import {\n  Button,\n  Modal,\n  ModalBody,\n  ModalFooter,\n  Text,\n  hubspot,\n} from '@hubspot/ui-extensions';\n\nhubspot.extend(({ actions }) => <OverlayExampleCard actions={actions} />);\n\nconst OverlayExampleCard = ({ actions }) => {\n  return (\n    <>\n      <Button\n        overlay={\n          <Modal id=\"default-modal\" title=\"Example modal\" width=\"md\">\n            <ModalBody>\n              <Text>Welcome to my modal. Thanks for stopping by!</Text>\n              <Text>\n                Close the modal by clicking the X in the top right, or using the\n                button below\n              </Text>\n            </ModalBody>\n            <ModalFooter>\n              <Button onClick={() => actions.closeOverlay('default-modal')}>\n                Close modal\n              </Button>\n            </ModalFooter>\n          </Modal>\n        }\n      >\n        Open modal\n      </Button>\n    </>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "You can only have one modal open at a time. Opening a modal while another is already open will cause the first one to close."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), " can be opened from a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Panel"
          }), ", but a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Panel"
          }), " cannot be opened from a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop to configure the style of modal."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, the modal will include a blue colored top bar where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "title"
      }), " displays."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-modal-variants-default.png",
        alt: "uie-components-modal-variants-default"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To configure the modal as a warning with a red colored top bar, set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'danger'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-modal-variants-danger.png",
        alt: "uie-components-modal-variants-danger"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use the default variant to prompt a user to enter a new set of customer details for the current contact record."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use the danger variant to confirm when a user wants to delete a deal record."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "DO:"
      }), " use this type of overlay for short messages and confirmations. If you want a more lightweight way to communicate a message to the user, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/alert",
        children: "Alert component"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "DO:"
      }), " use the danger variant to confirm destructive actions that cannot be undone. The text should clearly communicate what is being deleted, and the confirmation button should be explicit about the action. For example, never use the word \"Okay\" to confirm the deletion of an item. Instead, use \"Delete\"."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#open-an-overlay",
        children: "overlays"
      }), ", including when to use a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Modal"
      }), " or a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/panel",
          children: "Panel"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/loading-button",
          children: "LoadingButton"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "Display iframe modals using the UI extensions SDK"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}