/**
 * HubSpot's CodeMirror theme names. Upstream styles are included in the Sass
 * bundle. The light theme (hubspot-canvas-light) is unused so has been
 * excluded.
 *
 * @see https://git.hubteam.com/HubSpot/developer-docs-ui/blob/6216fe50bd63d15eca27516a1eac07062067b2c2/developer-docs-ui/static/sass/project.sass#L4
 */
export const CodeMirrorThemeNames = {
  /** The dark default theme */
  DARK: 'hubspot-canvas-dark',
  /** The light theme */
  LIGHT: 'hubspot-canvas-light'
};

/**
 * Type union of CodeMirror theme keys.
 */

/**
 * The `className`-based selector for the rendered CodeMirror component.
 *
 * @see https://sourcegraph.hubteam.com/git.hubteam.com/HubSpot/react-codemirror@17a5cc5b69ff04688db5d20bb0eeaf652ba14e62/-/blob/docs/README.md#import-style-sheets
 */
export const CodeMirrorClassNames = {
  /**
   * The default dark `className`-based selector.
   */
  DARK: `.cm-s-${CodeMirrorThemeNames.DARK}`,
  /**
   * The light `className`-based selector.
   */
  LIGHT: `.cm-s-${CodeMirrorThemeNames.LIGHT}`
};