"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358700;
const slug = exports.slug = 'guides/apps/overview';
const title = exports.title = 'Building apps overview';
const name = exports.name = 'Building apps overview';
const metaDescription = exports.metaDescription = "Learn about HubSpot's developer tools and developer accounts. Use them to build an integration, list an app on the Marketplace, or create a test account.";
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "types-of-apps",
  "label": "Types of apps",
  "parent": null
}, {
  "depth": 1,
  "id": "private-apps",
  "label": "Private apps",
  "parent": "types-of-apps"
}, {
  "depth": 2,
  "id": "private-apps-in-projects-beta",
  "label": "Private apps in projects (BETA)",
  "parent": "types-of-apps"
}, {
  "depth": 1,
  "id": "public-apps",
  "label": "Public apps",
  "parent": "types-of-apps"
}, {
  "depth": 2,
  "id": "public-apps-in-projects-beta",
  "label": "Public apps in projects (BETA)",
  "parent": "types-of-apps"
}, {
  "depth": 1,
  "id": "supported-extensions-by-app-type",
  "label": "Supported extensions by app type",
  "parent": "types-of-apps"
}, {
  "depth": 0,
  "id": "types-of-accounts-for-app-development",
  "label": "Types of accounts for app development",
  "parent": null
}, {
  "depth": 0,
  "id": "authentication",
  "label": "Authentication",
  "parent": null
}, {
  "depth": 1,
  "id": "developer-api-keys",
  "label": "Developer API keys",
  "parent": "authentication"
}, {
  "depth": 0,
  "id": "delete-a-developer-account",
  "label": "Delete a developer account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      ul: "ul",
      li: "li",
      h4: "h4",
      em: "em",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Building apps overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Apps enable you to authenticate API calls to your HubSpot account, subscribe to events with webhooks, and extend the HubSpot UI, such as with custom cards. HubSpot offers several types of apps and extensions depending on your integration's needs. Below, learn more about the types of apps and extensions you can build with HubSpot, how to get started building them, and how to manage them in HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types of apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the type of integration you want to build, you’ll need to choose the right type of app. Below, learn more about the types of apps that you can build and the functionalities that they support."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For building extensions, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#supported-extensions-by-app-type",
        children: "view the reference table below"
      }), " for a quick overview of which extensions can be built with which types of apps."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Private apps can be created for a single HubSpot account, and are best suited for one-off scripts or single-purpose extensions. In general, private apps are simpler to implement than public apps. Private apps authenticate with access tokens and cannot be listed on the HubSpot App Marketplace."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you might build a private app for your HubSpot account to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Create a new custom object through the API."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Import CRM records from a CSV file."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Authenticate API requests in custom automation actions and chat bots."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "Create and edit webhook subscriptions"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "when to build private apps"
      }), ". If this type of app fits your needs, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "get started creating a private app in your HubSpot account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Private apps in projects (BETA)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Private apps built using the developer projects framework enable you to create UI extensions for CRM records and help desk preview panels. You'll need a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sales Hub"
        })
      }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription to build a UI extension in for a private app in a standard HubSpot account. However, you can try these tools out for free in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#developer-test-accounts",
        children: "developer test account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get started, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/overview#get-started-with-private-apps",
        children: "enroll your account into the beta"
      }), ", then follow the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart",
        children: "quickstart guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Public apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Public apps can be installed in multiple accounts. In addition to the types of extensions you can build with a private app, public apps support advanced functionality, such as:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Subscribing to account-wide events using the webhooks API."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Creating custom timeline events on CRM records using the timeline events API."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Creating custom app settings pages in HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Public apps authenticate with OAuth and can be listed on the HubSpot App Marketplace. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "when to build public apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If this type of app fits your needs, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "get started creating a public app in your app developer account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Public apps in projects (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Public apps built using the developer projects framework enable you to create UI extensions for CRM records and help desk preview panels. You can also include other extensions in a public app created this way, but you'll need to manage those extensions in HubSpot and through the API as before."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're enrolled in the beta, get started by following the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/quickstart",
        children: "quickstart guide"
      }), ", or learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/migrate-a-public-app-to-projects",
        children: "migrate an existing public app to the projects framework"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supported extensions by app type"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "App type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Supported extensions"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private app"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/ui-extensions/overview",
              children: "UI extensions"
            }), " are supported in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/private-apps/creating-private-apps",
              children: "private apps created with projects (BETA)"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Public app"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/calling-sdk",
                  children: "Calling SDK"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "Classic CRM cards"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/timeline",
                  children: "Timeline events"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/video-conferencing",
                  children: "Video conference extension"
                })
              })]
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/ui-extensions/overview",
              children: "UI extensions"
            }), " are supported in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/public-apps/creating-public-apps",
              children: "public apps created with projects (BETA)"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["* Classic CRM cards are different from the app cards you can create as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "UI extensions with projects (BETA)"
        }), ". UI extensions offer more advanced functionality and customizable components."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types of accounts for app development"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While app developer and test accounts work together, they each serve a distinct purpose."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App developer accounts are intended for building and listing apps on the App Marketplace."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Developer test accounts, which can be created in developer accounts, are intended for testing APIs and apps you’re building without impacting data in a real HubSpot account."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sales Hub"
          })
        }), " or ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Service Hub"
          }), " Enterprise"]
        }), " subscription, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#development-sandbox-accounts-beta-",
          children: "development sandboxes"
        }), " in a standard HubSpot account to quickly iterate without impacting the account's data or assets."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "HubSpot's account types"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentication"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to build a custom integration with a single HubSpot account, you can create a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app"
      }), " and use its ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "access token"
      }), " to authenticate API calls, or you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " with a public app. Any app designed for installation by multiple HubSpot accounts or listing on the App Marketplace must use OAuth."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " as of November 30, 2022, HubSpot API Keys have been deprecated and are no longer supported. Continued use of HubSpot API Keys is a security risk to your account and data."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You should instead authenticate using a private app access token or OAuth. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "this change"
        }), " and how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrate an API key integration"
        }), " to use a private app instead."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to subscribe to webhooks or set up ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth for your app"
      }), ", you should ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "create a developer account"
      }), ". With developer accounts, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "list your apps"
      }), " on the App Marketplace or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "create a test account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Developer API keys"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To manage your app's settings through the API, you can use a developer API key. This API key is separate from standard API keys, which have been deprecated. Developer API keys can be used for managing subscriptions for the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "Webhooks API"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "creating or updating event types for the timeline events feature"
      }), ". All other API calls need to be made using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "private app access token"
      }), " or OAuth."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To access your app developer account API key:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your app developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Apps"
          }), " in the top navigation bar."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Get HubSpot API key"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-api-key.png",
            alt: "developer-account-api-key"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Show key"
          }), ". The key will be revealed, and you can then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copy"
          }), " next to the key."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can also deactivate the previous API key and generate a new one by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Regenerate key"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/show_dev_api_key.webp",
        alt: "show_dev_api_key"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can delete app developer accounts if they don’t contain apps with installations or active marketplace listings. If your account has apps with installations or active marketplace listings and you’d like to delete your account, please reach out to support for assistance."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you delete your account, you will no longer be able to access that account. If you can switch between multiple HubSpot accounts, the deleted account will no longer appear."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your HubSpot API developer account, click your account name in the top right corner, then click on ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Account."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/account.jpeg",
        alt: "account"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Delete account."
      }), " If your account has any apps with installations or active marketplace listings this button will be disabled."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Account2.jpg",
        alt: "Account2"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the dialog box, enter your account ID then click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Delete developer account"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Confirm2.jpg",
        alt: "Confirm2"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}