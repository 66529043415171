"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921797;
const slug = exports.slug = 'guides/api/crm/extensions/timeline';
const title = exports.title = 'CRM API | Timeline events';
const name = exports.name = 'CRM | Timeline events';
const metaDescription = exports.metaDescription = 'Overview and walkthrough of the Timeline API.';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-an-event-template",
  "label": "Create an event template",
  "parent": null
}, {
  "depth": 1,
  "id": "create-event-templates-through-the-api",
  "label": "Create event templates through the API",
  "parent": "create-an-event-template"
}, {
  "depth": 1,
  "id": "create-event-templates-in-hubspot",
  "label": "Create event templates in HubSpot",
  "parent": "create-an-event-template"
}, {
  "depth": 1,
  "id": "define-event-tokens",
  "label": "Define event tokens",
  "parent": "create-an-event-template"
}, {
  "depth": 2,
  "id": "create-event-tokens-through-the-api",
  "label": "Create event tokens through the API",
  "parent": "create-an-event-template"
}, {
  "depth": 1,
  "id": "define-header-and-detail-templates",
  "label": "Define header and detail templates",
  "parent": "create-an-event-template"
}, {
  "depth": 2,
  "id": "define-header-and-detail-templates-through-the-api",
  "label": "Define header and detail templates through the API",
  "parent": "create-an-event-template"
}, {
  "depth": 1,
  "id": "define-all-aspects-of-an-event-template-in-a-single-call",
  "label": "Define all aspects of an event template in a single call",
  "parent": "create-an-event-template"
}, {
  "depth": 0,
  "id": "create-an-event",
  "label": "Create an event",
  "parent": null
}, {
  "depth": 1,
  "id": "set-the-event-timestamp",
  "label": "Set the event timestamp",
  "parent": "create-an-event"
}, {
  "depth": 1,
  "id": "associate-an-event-with-a-crm-object",
  "label": "Associate an event with a CRM object",
  "parent": "create-an-event"
}, {
  "depth": 1,
  "id": "timeline-extensions",
  "label": "Timeline extensions",
  "parent": "create-an-event"
}, {
  "depth": 1,
  "id": "stamp-event-data-onto-crm-object-properties",
  "label": "Stamp event data onto CRM object properties",
  "parent": "create-an-event"
}, {
  "depth": 1,
  "id": "understand-",
  "label": "Understand ",
  "parent": "create-an-event"
}, {
  "depth": 1,
  "id": "set-up-a-custom-icon",
  "label": "Set up a custom icon",
  "parent": "create-an-event"
}, {
  "depth": 2,
  "id": "related-docs",
  "label": "Related docs",
  "parent": "create-an-event"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      em: "em",
      strong: "strong",
      h4: "h4",
      ul: "ul",
      li: "li",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Timeline Events"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The CRM extensions allow information from other systems to appear on HubSpot contact, company, or deal objects. The timeline events endpoints allow you to do this by creating custom timeline events. If you'd prefer your data to be editable by users but none of the default CRM objects fit your needs, you can consider using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom objects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_api/event_expanded-2.png",
        alt: "event_expanded-2"
      }), "For example, you want to better segment your contacts based on their interactions with your company and content. To do this, you need more information about them. Your app can create custom events (contacts who registered but did not attend a recent webinar, which variant of a sign-up flow a contact completed, etc.) that give you more context about the interactions contacts have with your company."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an event template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before you can start creating events, you must create an event template. Event templates describe actions your app will add to the timeline of a contact, company, or deal object in HubSpot. Examples of these actions include viewing a video, registering for a webinar, or filling out a survey. A single app can create up to 750 event templates."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Event templates are created for contacts by default, but they can be created for companies or deals using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " field. See creating a timeline event template for more details."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each event template has its own set of tokens and templates. You can use events created for contacts as criteria when creating new contact lists or workflows, such as: 'Create a list of all contacts with a Video Like where the video name contains XYZ,' where your event template is named \"Video Like\" and has an event token named \"video name.\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create event templates through the API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For this example, we'll create a new 'Example Webinar Registration' event template. For authentication, use the developer API key found in your app developer account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST\n-H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Example Webinar Registration\",\n  \"objectType\": \"contacts\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>''\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Be sure to replace ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<appId>>"
      }), " with your own App ID, which can be found on both the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "My Apps"
      }), " and app details pages in your developer account. You'll also need to replace ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<developerHapikey>>"
      }), " with your own developer API key, which you can find by navigating to ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Apps"
      }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Get HubSpot API key"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The properties ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "headerTemplate"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "detailTemplate"
      }), " could also be provided here. (See \"Defining Templates\" below.)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request will return the full, saved event template definition. Be sure to note the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " property in this response. This is the event template ID, which you'll need to make any updates to this event template or tokens in the future."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can see all event templates defined for an app via this GET command, which will also return the event template IDs:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create event templates in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to using the API to create and manage timeline event templates, you can also manage event templates in your HubSpot developer account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your app settings, navigate to Timeline events and use the \"Create event type\" button to create a new event template for this app. If you've created any event templates before, you'll see them here as well."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(3)-Jul-23-2020-10-02-24-50-PM.png",
        alt: "image (3)-Jul-23-2020-10-02-24-50-PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You'll start with a draft of your new event template. Once you've set the object type and the detail and header templates for the event, click \"Create.\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(4)-Jul-23-2020-10-02-24-66-PM.png",
        alt: "image (4)-Jul-23-2020-10-02-24-66-PM"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating or editing your event template, set any tokens you want to use with it in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Data"
      }), " tab."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/data-tab-1.png",
        alt: "data-tab-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you delete a template, once it's deleted, existing events using that template will be permanently removed from accounts with your app connected. You'll no longer be able to create new events of this type, but you'll still see legacy event data in lists and reports. It may take several hours to see these changes reflected in HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Define event tokens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've defined an event template, you'll likely want to define its tokens as well. Event template tokens allow you to attach custom data to events that can be displayed in the timeline, and for contacts, can be used for list segmentation and automation. You can create up to 500 tokens per timeline event template."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " company and deal events ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " be used in list segmentation or automation."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Create event tokens through the API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the event template ID created in Step 1, we'll add some tokens to identify the webinar our contacts registered for."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarName\",\n  \"label\": \"Webinar Name\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarId\",\n  \"label\": \"Webinar Id\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarType\",\n  \"label\": \"Webinar Type\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"value\": \"regular\",\n      \"label\": \"Regular\"\n    },\n    {\n      \"value\": \"ama\",\n      \"label\": \"Ask me anything\"\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Similarly, a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " will return all tokens defined on an event template:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET -H \"Content-Type: application/json\" 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The supported token types include:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), " — One of a set of options. See the webinarType example above."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "date"
        }), " — All dates must be in milliseconds in Unix time."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Note"
        }), ": Event tokens cannot be named log or lookup. These tokens are reserved as helpers by Handlebars.js, the library used to render in-app events. For more information, check out the Handlebars.js docs ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://handlebarsjs.com/builtin_helpers.html",
          children: "here."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Define header and detail templates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Header and detail templates define how to display a timeline event. You can specify ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://daringfireball.net/projects/markdown/syntax",
        children: "Markdown"
      }), " documents with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://handlebarsjs.com/",
        children: "Handlebars"
      }), " templates. The header template should be a one-line description of the event; and the details template is the drill-down view of the event (examples below)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The event tokens are passed as data to the templates. Using our example, you can reference the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " token in the template by using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{webinarName}}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " of an event (discussed below in \"Understanding extraData\") can only be referenced in the details template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Define header and detail templates through the API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Header and detail templates can be defined on the event template via the event template endpoints. For example, we can add templates to our 'Example Webinar Registration' by modifying that with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"id\": \"<<eventTemplateId>>\",\n  \"name\": \"Example Name Change\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note the use of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#formatDate"
      }), " directive—it's something we've defined to allow for user-friendly date formatting."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once an event is created for a contact using this (see \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#creating-an-event",
        children: "Creating an event"
      }), "\" below), here's what will show up in that contact's timeline:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Clicking on \"Show details\" renders the details template:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_expanded.png?width=640&name=event_expanded.png",
        alt: "event_expanded.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To set the icon that is displayed next to the events, see \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline#customicon",
        children: "Setting up a custom icon\""
      }), " below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The 'Example App Name' text above is the name of the app. In the CRM timeline, events can be filtered by app."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Define all aspects of an event template in a single call"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you’ve seen each aspect of an event template is progressively defined, you can define it all in one ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " call."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Another Webinar Registration\",\n  \"objectType\": \"contacts\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\",\n  \"tokens\": [\n    {\n      \"name\": \"webinarName\",\n      \"label\": \"Webinar Name\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarId\",\n      \"label\": \"Webinar Id\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarType\",\n      \"label\": \"Webinar Type\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"value\": \"regular\",\n          \"label\": \"Regular\"\n        },\n        {\n          \"value\": \"ama\",\n          \"label\": \"Ask me anything\"\n        }\n      ]\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an event"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that an event template is set up with tokens and templates, we're ready to create events for our customers' contacts, companies, deals, and tickets. The examples below assume we're working with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contacts"
      }), " event template created above. If the event template above is not set up to have the tokens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarId"
      }), ", then you will get an error when trying to create the event. Here's an example ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " for creating an event:"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": developer API keys and private app access tokens ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " be used as authentication when creating events. To create an event, the associated HubSpot account needs to grant access to your app via ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), ". Once you receive an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/oauth-tokens",
          children: "OAuth access token,"
        }), " you can use it to add events to the account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This generates an event on ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a.test.contact@email.com"
      }), "'s timeline (assuming the templates in 'Defining Templates' above):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set the event timestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The timestamp of the event determines where the event will appear in the object's timeline. By default, the event timestamp is when the POST command is sent. You can customize the event time by providing it in the request body in a timestamp property:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"timestamp\": \"2020-03-18T15:30:32Z\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This is preferred if you know the exact time an action occurred. In this example, if we have the timestamp for this webinar registration, we should provide it in this POST."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Timestamps can be in milliseconds epoch time or in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/ISO_8601",
        children: "ISO 8601"
      }), " format."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associate an event with a CRM object"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In order to create an event, you must be able to associate the event with a contact, company, or deal in the customer's account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the examples above, the objectType was set to contact, and we used email to associate the event with a contact. Email addresses must be unique for contacts in HubSpot, so if there's an existing contact with the provided email, that contact will be updated. If there isn't an existing contact, a new contact will be created. By default, this new contact will only have the email contact property provided. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#stamp-event-data-onto-crm-object-properties",
        children: "stamping event data onto contact properties"
      }), " to add additional data to contact properties."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you are working with known contacts, you can also use the contact ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " to associate the event. In those cases, you would use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " in the request JSON. You must include the vid of an existing contact, as you will not be able to create new contacts using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". This example uses the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " instead of email:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"29851\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also associate an event with a contact by usertoken, or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ". The usertoken is used by the HubSpot tracking code to track visitors, and is stored in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspotutk"
      }), " cookie. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " parameter to associate an event with a contact by usertoken. Note: It is not possible to associate events with anonymous visitors using the usertoken, so if the event is associated using only the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ", and the provided usertoken is not already associated with a contact, no new contact would be created and the event would not be visible in HubSpot. However, the event would appear in the timeline if a new contact was associated with the usertoken through another means (usually through a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "form submission including the hutk"
      }), ", or through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code",
        children: "identify method of the Tracking Code API"
      }), "). For this reason, we recommend including the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " in addition to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " to make sure that the event gets associated with a new or existing contact."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're working with an event template for contacts, it's possible to include multiple identification parameters with the event, so any combination of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " parameters may be included. If multiple parameters are included, the objectId (vid) will have the highest priority when determining which contact to associate with the event, followed by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ", with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " being the lowest priority. This means that you can update the email address of an existing object by including a new email address in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " parameter with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " of a known object in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". This example uses the email address and usertoken together:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"utk\": \"89b5afb740d41f4cd6651ac5237edf09\"\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to working with contacts, it's also possible to create event templates for companies and deals. For those event templates, you must use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " to associate the event with the company or deal. For companies, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " must be set to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " of the company you want to associate the event with, and for deals you would set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealId"
      }), " of the deal object."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the example below, assuming the event template was set to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPANY"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), ", this event would be associate with the company object with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " 528253914:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"3001\",\n  \"tokens\": {\n    \"dealProperty\": \"Custom property for deal\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Timeline extensions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The timeline extensions feature can be used to display data from an external system using an iFrame. When included, the event will display a link that will open a modal window displaying the iFrame contents when clicked. The details for the iFrame are set in the timelineIFrame field, which is an object containing the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "linkLabel"
        }), " - The text used to display the link that will display the IFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "headerLabel"
        }), " - The label of the modal window that displays the IFrame contents."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), " - The URI of the IFrame contents."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "width"
        }), " - The width of the modal window."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "height"
        }), " - The height of the modal window."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, using this data for an event:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"timelineIFrame\": {\n    \"linkLabel\":\"View external data\",\n    \"headerLabel\":\"Example iframe\",\n    \"url\":\"https://www.example.com\",\n    \"width\":800,\n    \"height\":300\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Would create this event, including the \"View external data\" link:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/external_data_link.png?width=640&name=external_data_link.png",
        alt: "external_data_link.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Clicking that link would open a modal window displaying the page set in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "url"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/iframe_modal.png?width=640&name=iframe_modal.png",
        alt: "iframe_modal.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Stamp event data onto CRM object properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In many cases, you'll want to modify the properties for the contacts, companies, or deals to which you're adding events. This often happens in cases where adding the event will actually create a contact—you'll likely want to update the first and last name properties on the contact so that you don't just create a contact with only an email address and an event."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can stamp data onto the associated object from an event by mapping your custom event tokens to contact, company, or deal properties."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consider this ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " command for updating a custom event template, note the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), " field:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"label\" : \"Updated Webinar Name\",\n  \"objectPropertyName\": \"zz_webinar_name\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens/<<tokenName>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This uses ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), " to map this custom event token to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " Object ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " property. This means that when we create a new event that specifies a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " token, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " property of the associated ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " will also be set. You can set these to custom or predefined HubSpot properties."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, let's say we already created a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyName"
      }), " token referencing a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), " custom property on the contact. Then creating an event like this causes the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " properties to be set on the contact with the email address ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:a.test.contact@email.com",
        children: "a.test.contact@email.com"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/set_property.png?width=1024&name=set_property.png",
        alt: "set_property.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Note: If an event token is stamped to a custom property, and that custom property is not present for a HubSpot account, then the value will still be set for the event, but it will be ignored for the corresponding object."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      children: ["Understand ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You may need to add detailed data to an event that doesn't fit the simple token-value structure used by the event template tokens. You may need to add a list or some hierarchical breakdown to an integration event. This is where ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " comes in."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can add an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " attribute to an event’s JSON body. The value of this ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " can be any valid JSON. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"extraData\": {\n    \"pollData\": [\n      {\n        \"question\": \"How excited are you for this webinar?\",\n        \"answer\":\"Quite!\"\n      },\n      {\n        \"question\": \"How frequently do you use our product?\",\n        \"answer\":\"Daily\"\n      }\n    ],\n    \"coWorkers\": [\n      {\n        \"name\": \"Joe Coworker\",\n        \"email\":\"joe.coworker@testco.com\"\n      },\n      {\n        \"name\": \"Jane Coworker\",\n        \"email\":\"jane.coworker@testco.com\"\n      }\n    ]\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["An example of using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " in a details template:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "//\nRegistration occurred at {{#formatDate timestamp}}{{/formatDate}}\n\n#### Poll Questions\n{{#each extraData.pollData}}\n  **{{question}}**: {{answer}}\n{{/each}}\n\n#### Co-Workers\n{{#each extraData.coWorkers}}\n  * {{name}}\n{{/each}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Which will result in a timeline event that looks like this:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/extra_data.png?width=640&name=extra_data.png",
        alt: "extra_data.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note: The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " attribute can only be referenced in the details template for an event. It can't be used in either the header template or in list segmentation."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set up a custom icon"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add visual appeal to your timeline items, you'll want to add a custom icon."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This image file for this icon should:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Have roughly square dimensions"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Have a transparent background"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Have the content in the center of the icon"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Be able to size down to 30x30 pixels"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Have a file size of 5MB or less"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To set the icon used for timeline events, navigate to Timeline events. Click on the placeholder image or the existing icon to set or update it."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_assets.png",
        alt: "timeline_assets"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you set the icon(s), they will be shown next to all of the timeline events associated with this application:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/timeline_icon.png?width=640&name=timeline_icon.png",
        alt: "timeline_icon.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Related docs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "CRM cards"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}