"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45278701403;
const slug = exports.slug = 'guides/api/crm/objects/line-items';
const title = exports.title = 'CRM-API | Artikel';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Line Items';
const metaDescription = exports.metaDescription = 'Wenn ein Produkt einem Deal zugeordnet wird, wird es zu einem Artikel. Die Artikel-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zu synchronisieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "einen-artikel-erstellen",
  "label": "Einen Artikel erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "einen-artikel-abrufen",
  "label": "Einen Artikel abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "einen-artikel-aktualisieren",
  "label": "Einen Artikel aktualisieren",
  "parent": null
}, {
  "depth": 0,
  "id": "einen-artikel-l%C3%B6schen",
  "label": "Einen Artikel löschen",
  "parent": null
}, {
  "depth": 0,
  "id": "artikeleigenschaften",
  "label": "Artikeleigenschaften",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Artikel"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot sind Artikel einzelne Instanzen von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "Produkten"
      }), ". Wenn ein Produkt einem Deal zugeordnet wird, wird es zu einem Artikel. Sie können Artikel erstellen, die eindeutig für ein einzelnes Angebot sind. Diese werden jedoch nicht zu Ihrer Produktbibliothek hinzugefügt. Die Artikel-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zwischen HubSpot und anderen Systemen zu synchronisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Anwendungsfall als Beispiel:"
      }), " Wenn Sie eine Reihe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "Angeboten"
      }), " für Vertriebsmitarbeiter erstellen, die an potenzielle Käufer gesendet werden sollen, können Sie diese API verwenden, um eigenständige Artikel pro Angebot sowie Artikel zu erstellen, die an bestehende Produkte angehängt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Artikel erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen eigenständigen Artikel zu erstellen, nehmen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_item"
      }), " vor. Geben Sie im Beitragstext die Details des Artikels an, z. B. Name, Menge und Preis."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Artikel basierend auf einem vorhandenen Produkt zu erstellen (erstellt über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "Produkte-API"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/products/how-do-i-use-products",
        children: "in HubSpot"
      }), "), fügen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_product_id"
      }), " in den Beitragstext ein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können den Artikel auch mit Deals, Angeboten, Rechnungen, Zahlungslinks oder Abonnements verknüpfen, indem Sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), "-Array in den Beitragstext einfügen. Zum Beispiel würde der Beitragstext unten ein Artikel namens „New standalone line item“ (Neuer eigenständiger Artikel) erstellen, der einem Deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "12345"
      }), ") zugeordnet ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"price\": 10,\n    \"quantity\": 1,\n    \"name\": \"New standalone line item\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 12345\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Artikel gehören zu einem einzelnen übergeordneten Objekt. Wenn Objekte zugeordnet werden, sollten Artikel für jedes Objekt individuell sein. Wenn Sie beispielsweise einen Deal und ein Angebot erstellen, sollten Sie einen Satz von Artikeln für den Deal und einen anderen Satz für das Angebot erstellen. Dies trägt dazu bei, CRM-Daten über Objekte hinweg zu optimieren und unerwarteten Datenverlust zu verhindern, wenn Artikel geändert werden müssen (z. B. werden durch das Löschen eines Angebots die Artikel des Angebots gelöscht, und wenn diese Artikel mit einem Deal verknüpft sind, werden auch die Deal-Artikel gelöscht)."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Der im Feld ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Eigenschaften"
          }), " angegebene ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Preis"
          }), " ", (0, _jsxRuntime.jsx)("u", {
            children: "darf nicht negativ sein"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Die ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Laufzeiteigenschaft"
          }), " des Artikels (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_recurring_billing_period"
          }), ") akzeptiert die ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.oracle.com/javase/8/docs/api/java/time/Period.html#:~:text=exceeds%20an%20int-,parse,-public%20static%C2%A0",
            children: "ISO-8601-Zeitraumformate"
          }), " PnYnMnD und PnW."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Artikel abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Artikel einzeln oder mehrere gleichzeitig abrufen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ein bestimmtes Artikel abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items/{lineItemId}"
        }), " durch, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lineItemId"
        }), " die ID des Artikels ist."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um alle Artikel abzurufen, führden Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In die Anfrage-URL können Sie die folgenden Parameter einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn eine der angegebenen Eigenschaften in dem/den angefragten Objekt(en) nicht vorhanden ist, wird sie ignoriert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die zusammen mit ihrem Verlauf an vorherigen Werten zurückgegeben werden sollen. Wenn eine der angegebenen Eigenschaften in dem/den angefragten Objekt(en) nicht vorhanden ist, wird sie ignoriert."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Artikel aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Artikel zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), " durch, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " die ID des Artikels ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Schließen Sie im Text des Beitrags die Eigenschaftswerte ein, die Sie aktualisieren möchten. Sie können ", (0, _jsxRuntime.jsx)("u", {
        children: "keine"
      }), " Zuordnungen über dieses Verfahren aktualisieren. Stattdessen müssen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihr Anfragetext kann beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/line_item/{lineItemId}\n{\n  \"properties\": {\n    \"price\": 25,\n    \"quantity\": 3,\n    \"name\": \"Updated line item\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Artikel löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Artikel zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), " durch, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " die ID des Artikels ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Artikeleigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Ihre Artikeldaten verwalten, möchten Sie möglicherweise einige der allgemeinen Eigenschaften in der folgenden Tabelle verwenden. Um alle Artikeleigenschaften abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/line_item"
      }), " durch. Erfahren Sie mehr über die Verwendung der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Eigenschaften-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaftsname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Label in UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des Artikels."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ausführliche Beschreibung des Produkts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bestandseinheit"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eindeutige Produkt-ID"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Startdatum der Abrechnung"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Startdatum eines festen Abrechnungszeitraums"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abrechnungsenddatum"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enddatum eines festen Abrechnungszeitraums"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recurringbillingfrequency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abrechnungsfrequenz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wie oft ein Artikel mit wiederkehrender Abrechnung abgerechnet wird. Es informiert über die Preiskalkulation für Deals und Angebote. Artikel mit einmaliger Abrechnung sind nicht enthalten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity,"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wie viele Einheiten eines Produkts sind in diesem Artikel enthalten"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Einzelpreis"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Kosten des Produkts"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nettopreis"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Gesamtkosten des Artikels (d. h. die Menge multipliziert mit dem Einheitenpreis)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Währung"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Währungscode für den Artikel"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}