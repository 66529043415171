import enviro from 'enviro';
import memoizeOne from 'react-utils/memoizeOne';

/**
 * Temporary enviro workaround for IPB issue.
 * @see https://issues.hubspotcentral.com/browse/DGS-254
 */
export default memoizeOne(() => {
  if (enviro.isQa() && !enviro.deployed()) {
    return 'local';
  } else if (window.location.hostname.includes('wthubspot') || enviro.isQa() && enviro.deployed()) {
    return 'qa';
  }
  return 'prod';
});