"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358696;
const slug = exports.slug = 'guides/api/crm/associations/associations-v3';
const title = exports.title = 'CRM API | Associations';
const name = exports.name = 'vNext Docs DP - Associations';
const metaDescription = exports.metaDescription = 'The CRM Associations endpoints are used to manage associations between tickets, products, line items, and their related contacts, companies, and deals.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "association-types",
  "label": "Association types",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-association-types",
  "label": "Retrieve association types",
  "parent": null
}, {
  "depth": 0,
  "id": "create-associations",
  "label": "Create associations",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-associations",
  "label": "Retrieve associations",
  "parent": null
}, {
  "depth": 0,
  "id": "remove-associations",
  "label": "Remove associations",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      em: "em",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Associations v3"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details",
          children: "new version of the Associations API"
        }), " has additional functionality, including creating and managing association labels. Refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "v4 Associations API article"
        }), " for more information."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Associations represent the relationships between objects and activities in the HubSpot CRM. You can use the v3 associations endpoints to create, retrieve, or remove associations in bulk."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about objects, records, properties, and associations APIs in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM"
      }), " guide. For more general information about objects and records in HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "learn how to manage your CRM database"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts-user-guide",
        children: "."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Association types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Associations are defined by object and direction. Association types are unidirectional, which means you'll need to use a different definition depending on the starting object type. Each endpoint requires a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{fromObjectType}"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{toObjectType}"
      }), " that tell the direction of the association."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To view all the defined association types from contacts to companies, you'd make a request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/associations/contacts/companies/types"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To see all tickets associated with a contact, you'd make a request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/associations/Contacts/Tickets/batch/read"
        }), " and identify the contact in the request body by its ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), ". In this example, Contacts is the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "fromObjectType"
        }), ", and Tickets is the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "toObjectType"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Association types can include unlabeled associations (e.g., contact-to-company), default labeled associations (e.g., contact-to-primary company), and custom labeled associations (e.g., ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Decision maker"
      }), " contact-to-company)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve association types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all the defined association types between objects, including default associations and custom association labels, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/types"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each type will have a returned numerical ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " value and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " that can be used to reference the association type in other requests. For default associations, the numerical ID will be the same for all accounts, but for custom association labels, the ID will be unique to your account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your response would look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example response GET /crm/v3/associations/contacts/companies/types\n{\n  \"results\": [\n    {\n      \"id\": \"136\",\n      \"name\": \"franchise_owner_franchise_location\"\n    },\n    {\n      \"id\": \"26\",\n      \"name\": \"manager\"\n    },\n    {\n      \"id\": \"1\",\n      \"name\": \"contact_to_company\"\n    },\n    {\n      \"id\": \"279\",\n      \"name\": \"contact_to_company_unlabeled\"\n    },\n    {\n      \"id\": \"32\",\n      \"name\": \"contractor\"\n    },\n    {\n      \"id\": \"37\",\n      \"name\": \"chef\"\n    },\n    {\n      \"id\": \"142\",\n      \"name\": \"toy_tester\"\n    },\n    {\n      \"id\": \"30\",\n      \"name\": \"decision_maker\"\n    },\n    {\n      \"id\": \"28\",\n      \"name\": \"billing_contact\"\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["While you can reference custom association types (i.e. labels) with the v3 Associations API, you cannot use the API to create or edit new labels. Learn how to create, update, and delete labels in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "v4 Associations API article"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "associate records"
      }), ", make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/batch/create"
      }), ". In your request, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values for the records you want to associate, as well as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), " of the association."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to associate contacts to companies, your request URL would be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/Contacts/Companies/batch/create"
      }), ", and your request would look similar to the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"inputs\": [\n    {\n      \"from\": {\n        \"id\": \"53628\"\n      },\n      \"to\": {\n        \"id\": \"12726\"\n      },\n      \"type\": \"contact_to_company\"\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve associated records, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/batch/read"
      }), ". In your request, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values of the records whose associations you want to view. This will be for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{fromObjectType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to retrieve deals associated with companies, your request URL would be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/Companies/Deals/batch/read"
      }), " and the request body would look like the following, with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values for the companies whose deal associations you want to view:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request POST /crm/v3/associations/Companies/Deals/batch/read\n{\n  \"inputs\": [\n    {\n      \"id\": \"5790939450\"\n    },\n    {\n      \"id\": \"6108662573\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your response, you'll receive the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values of all associated records. For the above example, your response would include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values for all associated deals and the association ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), ". The response would look similar to the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response POST /crm/v3/associations/Companies/Deals/batch/read\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"5790939450\"\n      },\n      \"to\": [\n        {\n          \"id\": \"1467822235\",\n          \"type\": \"company_to_deal\"\n        },\n        {\n          \"id\": \"7213991219\",\n          \"type\": \"company_to_deal\"\n        },\n        {\n          \"id\": \"9993513636\",\n          \"type\": \"company_to_deal\"\n        },\n        {\n          \"id\": \"18731599139\",\n          \"type\": \"company_to_deal\"\n        },\n        {\n          \"id\": \"21678228008\",\n          \"type\": \"company_to_deal\"\n        }\n      ]\n    },\n    {\n      \"from\": {\n        \"id\": \"6108662573\"\n      },\n      \"to\": [\n        {\n          \"id\": \"22901690010\",\n          \"type\": \"company_to_deal\"\n        }\n      ]\n    }\n  ],\n  \"startedAt\": \"2024-10-21T16:40:47.810Z\",\n  \"completedAt\": \"2024-10-21T16:40:47.833Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": when retrieving records associated with companies (i.e. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/associations/{fromObjectType}/companies/batch/read"
        }), "), only the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/records/associate-records#primary-company-association",
          children: "primary associated company"
        }), " will be returned. To view all associated companies, use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "V4 associations API."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Remove associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove associations between records, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/batch/archive"
      }), ". In the request body, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values for the from record and the to record, as well as their association type."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to remove the association between a company and a deal, your request would look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request POST crm/v3/associations/companies/deals/batch/archive\n{\n  \"inputs\": [\n    {\n      \"from\": {\n        \"id\": \"5790939450\"\n      },\n      \"to\": {\n        \"id\": \"21678228008\"\n      },\n      \"type\": \"company_to_deal\"\n    }\n  ]\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}