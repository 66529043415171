"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52098077563;
const slug = exports.slug = 'guides/cms/content/provide-a-good-editor-experience';
const title = exports.title = 'ページエディターで快適な作業環境を提供する方法';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] How to provide a good experience in the page editor_JA | 202109';
const metaDescription = exports.metaDescription = 'HubSpotのCMSエディターにCSS/JSが適切に表示されるようにする方法';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%BC%E5%86%85%E3%81%A7%E3%81%AE%E3%83%86%E3%82%B9%E3%83%88",
  "label": "エディター内でのテスト",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%85%B7%E4%BD%93%E7%9A%84%E3%81%AA%E6%8C%87%E5%AE%9A",
  "label": "具体的な指定",
  "parent": null
}, {
  "depth": 0,
  "id": "!important%E3%82%BF%E3%82%B0%E3%81%AE%E4%BD%BF%E7%94%A8%E3%82%92%E9%81%BF%E3%81%91%E3%82%8B",
  "label": "!importantタグの使用を避ける",
  "parent": null
}, {
  "depth": 1,
  "id": "!important%E3%81%AE%E4%BD%BF%E7%94%A8%E3%82%92%E5%9B%9E%E9%81%BF%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95",
  "label": "!importantの使用を回避する方法",
  "parent": "!important%E3%82%BF%E3%82%B0%E3%81%AE%E4%BD%BF%E7%94%A8%E3%82%92%E9%81%BF%E3%81%91%E3%82%8B"
}, {
  "depth": 0,
  "id": ".hs-inline-edit%E3%81%A8window.hsineditor%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": ".hs-inline-editとwindow.hsInEditorを使用する",
  "parent": null
}, {
  "depth": 1,
  "id": ".hs-inline-edit%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95",
  "label": ".hs-inline-editを使用する方法",
  "parent": ".hs-inline-edit%E3%81%A8window.hsineditor%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "window.hsineditor%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95",
  "label": "window.hsInEditorを使用する方法",
  "parent": ".hs-inline-edit%E3%81%A8window.hsineditor%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      a: "a",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ページエディターで快適な作業環境を提供する方法"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページエディターでは制作担当者がインライン編集を行って、表示される状態を確認しながら作業できるWYSIWYG環境でコンテンツを作成および編集できるようになっています。エディターには、モジュールとテンプレートのコードと、HubSpotアプリのCSS/JSの両方を含むウェブページのプレビューを示すiframeが組み込まれています。ただしテンプレートまたはモジュールのCSS/JSが原因で、エディターに問題が発生することがあります。この記事では、こうした問題を回避するためのベストプラクティスについて説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "エディター内でのテスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発したアセットを配信する前に、HubSpotのコンテンツエディター内でテストすることが重要です。エディター内でのテストは、エディターと競合するスタイル設定の特定にも、スムーズな作業環境の実現にも効果的です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、エディター内で実行できるテストの例を示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［スタイル］ドロップダウンメニューのオプション（フォント、テキストサイズ）とツールバーのオプション（位置合わせ、色）を使用して、テキストの書式を設定する"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "リッチ テキスト ツールバーのオプション（埋め込み、リンク、画像、パーソナライズトークン）をクリックして挿入する"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "インラインエディター内でリッチテキスト要素のプラグイン（埋め込み、リンク、画像、パーソナライズトークン）をクリックし、UIが正常に動作して期待どおりに表示されることを確かめる"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "具体的な指定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "主に、カスタムのJavaScriptまたはCSSが具体的ではない場合に、コンテンツエディターに問題が発生する可能性があります。コードの記述方法によって異なる形で、カスタムコードがエディターを干渉するリスクが現れます。一例として、次の関数を見てください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "$('body').click(function (event) {\n  $('.blog-listing-wrapper .post-listing .post-item')\n    .siblings()\n    .find('.right')\n    .removeClass('social-active');\n\n  event.stopPropagation();\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この関数は、body要素がクリックされると実行されます。このコードでは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "event.stopPropagation()"
      }), "が呼び出されるため、イベントが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), "に到達しません。このような要素に関連付けられているイベントリスナーがある場合に、そのイベントリスナーのコードは実行されません。上記のコードの問題は、要素がクリックされるたびにclickハンドラーが実行され、インラインエディターに問題が発生することです。Reactによってwindow要素にclickハンドラーが追加されることが原因です。全てのクリックを捕捉するのではなく、必要な場合にだけclickハンドラーを追加する方が適切です。例えば、ユーザーによるサイドメニューを開くためのボタンクリックの時点でclickハンドラーを追加し、クリックが発生したらハンドラーを削除します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), "のような汎用セレクターを使用する場合も、CSSに問題が発生することがあります。可能な場合は、ウェブページの特定箇所に限定したセレクター（", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-form label"
      }), "など）を使用することが望まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "!importantタグの使用を避ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "!important"
      }), "は、スタイルルールを他のものより優先させるために使用するタグです。ウェブコンテンツの制作時に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), "や", (0, _jsxRuntime.jsx)(_components.code, {
        children: "input[type=\"text\"]"
      }), "のような単純な要素セレクターで!importantを使用するのは適切ではありませんが、選択したスタイルルールよりも具体的に変更する場合に使用されることが少なくありません。!importantを使用すると、選択したルールが優先されるため、そのルールが具体的ではない場合でも所定のアセットに適用されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["代わりに、セレクターを具体的に指定することにより、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "!important"
      }), "を使用しなくても済むようにしてください。これにより、必要な要素以外にわたって適用される広範なルールを設定することなく、スタイルの設定対象にする要素を特定できます。また、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/tools/boilerplate-css",
        children: "ボイラープレートCSSファイル"
      }), "を参照して、CSSの混在回避に向けてセレクターの活用方法を理解することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "!importantの使用を回避する方法"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えばフォーム内の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<label>"
      }), "要素の全てを白にする目的で、CSS内で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<label>"
      }), "要素に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "!important"
      }), "が使用されることがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "label,\nlegend {\n  color: white !important;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["しかし特定の要素をスタイルルールのセレクターとして使用するのは広範なため、その意図に反してコンテンツエディター内の全ての", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<label>"
      }), "タグが白になってしまいます。このスタイルルールは、HubSpotのリンク挿入プラグインに採用されている", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<label>"
      }), "要素を除外しないことから、プラグイン上のフィールドのラベルが欠落しているような表示になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のスタイルルールのように具体的なセレクターを使用することで、白を追加するフォームモジュールのラベルにだけ白を追加でき、コンテンツエディター内の他のラベルには影響を与えません。フォームのスタイルを設定する際に使用できるセレクターについて理解を深めていただけるように、開発者向けの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cos/hubspot-form-markup#styling-forms",
        children: "資料"
      }), "を掲載しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".hs-form label,\n.hs-form .hs-form-field > label,\n.hs-form .hs-field-desc {\n  color: white;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: ".hs-inline-editとwindow.hsInEditorを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのコンテンツエディターでは、コンテンツのプレビューの読み込みにiframeを使用しています。このiframeの", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-inline-edit"
      }), "は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<html>"
      }), "に追加されるクラスです。また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsInEditor"
      }), "は「true」と定義されます。この2つの項目は、インラインエディターの初期化時に、コンテンツがHubSpotコンテンツエディター内にあるかどうかを開発者が確かめる方法として利用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-inline-edit"
      }), "を使用することで、エディターによるウェブページの読み込み時に適用しないCSSを記述して、CSSの混在を回避できます。インライン編集に影響する問題がJavaScriptにある場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsInEditor"
      }), "変数を使用して、エディター内で実行されると問題になる部分的なコードを無効にすることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: ".hs-inline-editを使用する方法"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エディター内でのみルールを適用するセレクターの前に", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-inline-edit"
      }), "を追加します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、次のCSSでは疑似セレクター", (0, _jsxRuntime.jsx)(_components.code, {
        children: ":not()"
      }), "を利用しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ":not(.hs-inline-edit) label {\n  color: white;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "別の方法として、HubSpot固有のクラスを利用することもできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、ページ内に固定ヘッダーがあり、エディター内のリッチ テキスト ツールバーが固定ヘッダーの下に隠れる場合、ウェブサイトのUIレイヤーを最上位のz-indexに配置していることが考えられます。通常、この配置は（どうしても必要な場合を除いて）ベストプラクティスではありません。これは、エディター内では特に問題になります。コンテンツがインラインコントロールの上にレンダリングされる可能性があるためです。最善の結果を得るために、ウェブサイトのUIレイヤーを[X] z-indexの下に配置するようにしてください。それには、セレクター", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-inline-edit"
      }), "の後に固定ヘッダーのセレクター（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "header"
      }), "）を続けて、z-indexの値を2147483599にします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".hs-inline-edit .header {\n  z-index: 2147483599;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "window.hsInEditorを使用する方法"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エディターの読み込み後にJavaScript関数が起動されるようにするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsInEditor"
      }), "を使用して何らかの処理を行うかどうかの判定条件を含めると役立ちます。以下のように、jQueryドキュメントのreadyハンドラーを利用することを検討してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "jQuery(document).ready(function ($) {\n  if (window.hsInEditor) {\n    return;\n  }\n  // so other stuff\n  $('.some_widget').fancyThing();\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "jQueryドキュメントのreadyの部分は、貴社のHubSpotサイトがjQueryを使用している場合にのみ機能します。"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}