"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817161636;
const slug = exports.slug = 'guides/apps/marketplace/certification-requirements';
const title = exports.title = 'Marketplace de aplicativos | Requisitos para certificação de aplicativos';
const name = exports.name = 'LATAM BR (PT) App Certification Requirements';
const metaDescription = exports.metaDescription = 'Veja aqui o que os Parceiros de aplicativos devem fazer para que o aplicativo seja certificado no Marketplace de aplicativos da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vis%C3%A3o-geral",
  "label": "Visão geral",
  "parent": null
}, {
  "depth": 0,
  "id": "benef%C3%ADcios-da-certifica%C3%A7%C3%A3o-de-ganhos",
  "label": "Benefícios da certificação de ganhos",
  "parent": null
}, {
  "depth": 0,
  "id": "crit%C3%A9rios-de-revis%C3%A3o",
  "label": "Critérios de revisão",
  "parent": null
}, {
  "depth": 1,
  "id": "seguran%C3%A7a-e-privacidade",
  "label": "Segurança e privacidade",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 2,
  "id": "exemplo-de-feedback",
  "label": "Exemplo de feedback",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 1,
  "id": "confiabilidade-e-desempenho",
  "label": "Confiabilidade e desempenho",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 2,
  "id": "exemplos-de-feedback",
  "label": "Exemplos de feedback",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 1,
  "id": "usabilidade-e-acessibilidade-%7C-app",
  "label": "Usabilidade e acessibilidade | App",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 2,
  "id": "exemplo-de-feedback",
  "label": "Exemplo de feedback",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 1,
  "id": "acessibilidade-e-usabilidade-%7C-listagem-do-marketplace-de-apps",
  "label": "Acessibilidade e usabilidade | Listagem do marketplace de apps",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 2,
  "id": "exemplos-de-feedback",
  "label": "Exemplos de feedback",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 1,
  "id": "usabilidade-e-acessibilidade-%7C-documenta%C3%A7%C3%A3o-de-suporte",
  "label": "Usabilidade e acessibilidade | Documentação de suporte",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 2,
  "id": "exemplo-de-feedback",
  "label": "Exemplo de feedback",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 1,
  "id": "valor",
  "label": "Valor",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 2,
  "id": "exemplo-de-feedback",
  "label": "Exemplo de feedback",
  "parent": "crit%C3%A9rios-de-revis%C3%A3o"
}, {
  "depth": 0,
  "id": "o-processo-de-revis%C3%A3o-de-certifica%C3%A7%C3%A3o-e-recertifica%C3%A7%C3%A3o-do-app",
  "label": "O processo de revisão de certificação e recertificação do app",
  "parent": null
}, {
  "depth": 0,
  "id": "perguntas-frequentes",
  "label": "Perguntas frequentes",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      h3: "h3",
      code: "code",
      h4: "h4",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Quote,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Quote) _missingMdxReference("Quote", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Obtendo certificação no Marketplace de aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A certificação do aplicativo envolve a análise e confirmação pela equipe de Qualidade do ecossistema da HubSpot de que o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "aplicativo listado"
      }), " atende aos requisitos abaixo para segurança, privacidade, confiabilidade, desempenho, usabilidade, acessibilidade e valor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os apps certificados destacam-se no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace do app"
      }), " com uma reputação de qualidade e confiabilidade. O aplicativo também ganhará ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#certification-benefits",
        children: "benefícios especiais"
      }), " e receberá feedback construtivo da equipe de Qualidade do ecossistema da HubSpot durante a revisão da certificação do aplicativo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Esses requisitos estão sujeitos a alterações, pois a HubSpot está continuamente fazendo melhorias no Marketplace de aplicativos da HubSpot e no Ecossistema. A HubSpot pode rejeitar uma solicitação de certificação de aplicativo, a seu critério, se ele não atender aos padrões definidos."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["A HubSpot ", (0, _jsxRuntime.jsx)("u", {
            children: "não"
          }), " analisará seu aplicativo, a menos que você envie um vídeo de demonstração conforme as instruções ao ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/applying-for-app-certification",
            children: "solicitar a certificação do aplicativo"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Somente é possível enviar um aplicativo por vez para certificação. Se você enviar mais do que um aplicativo para certificação ao mesmo tempo, eles serão rejeitados com base na ordem de envio."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visão geral"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo uma visão geral dos requisitos de certificação do aplicativo. Para mais detalhes, consulte a seção ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#review-criteria",
        children: "Critérios de revisão"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Deve estar associado a um ID simples do aplicativo HubSpot."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O seu aplicativo público listado deve ser único. Se você já tiver listado um aplicativo e quiser substituí-lo, deverá atualizar o aplicativo existente em vez de listar um novo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Não crie vários aplicativos que podem ser usados para resolver um mesmo caso de uso. Os aplicativos com funcionalidades semelhantes e que utilizam as mesmas APIs devem ser consolidadas em um único aplicativo."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Deve usar a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "autenticação OAuth"
          }), " e todos os escopos necessários."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Deve estar associado a um ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "domínio verificado"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Os ativos públicos associados ao seu aplicativo devem seguir as práticas recomendadas de segurança."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#security-privacy",
        children: "lista detalhada de requisitos de segurança e privacidade"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A atividade do aplicativo é definida por solicitações autenticadas por OAuth para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "APIs"
        }), " da HubSpot e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests",
          children: "solicitações assinadas"
        }), " de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "assinaturas de webhook"
        }), " e extensões da HubSpot (por exemplo, solicitações de busca de dados do ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "cartão de CRM"
        }), ")."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As instalações ativas são o número de contas de produção exclusivas da HubSpot, não afiliadas à sua organização, que mostram a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "atividade do aplicativo"
        }), " bem-sucedida nos últimos 30 dias."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Benefícios da certificação de ganhos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além dos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners/app",
        children: "benefícios de anunciar seu app"
      }), ", os apps certificados:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um selo \"Aplicativo certificado pela HubSpot\" exibido na sua listagem no Marketplace de aplicativos."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Têm uma maior visibilidade no marketplace de app da HubSpot:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "São incluídos no filtro de pesquisa \"Aplicativo certificado pela HubSpot\"."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "São elegíveis para inclusão nas coleções com curadoria do Marketplace de aplicativos."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Acesso ao selo \"Aplicativo certificado pela HubSpot\" e as imagens nas redes sociais para compartilhar a conquista da certificação do aplicativo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Consideração favorável nas iniciativas de parceria e amplificação da HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Critérios de revisão"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para obter a certificação, seu app deve demonstrar qualidade por meio de medidas quantitativas e descritores qualitativos de segurança, privacidade, confiabilidade, desempenho, usabilidade, acessibilidade e valor. Os requisitos abaixo são organizados por essas categorias e incluem exemplos de feedback construtivo que você pode receber."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Segurança e privacidade"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Deve estar associado a um ID simples do aplicativo HubSpot. Seu aplicativo deve autorizar as solicitações de API com o ID público do aplicativo HubSpot (e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "ID do cliente OAuth"
        }), ") associado à sua lista de aplicativos.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Uma lista não deve ser redirecionada para um aplicativo público ou privado diferente."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O seu aplicativo público listado não deve exigir o funcionamento de outro aplicativo público ou privado."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Deve ser autenticado pelo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "fluxo de código de autorização OAuth"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Não é permitido solicitar aos usuários que copiem e colem códigos ou tokens OAuth. Os usuários somente devem ser solicitados a conceder acesso"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Os apps devem solicitar, gerenciar e atualizar tokens de acesso sem o envolvimento do usuário"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use todos os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "escopos"
        }), " solicitados para instalação (ou seja, nos parâmetros ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " obrigatórios).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ter as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "configurações avançadas de escopo"
            }), " ativadas e selecionar todos os escopos obrigatórios, condicionalmente obrigatórios e opcionais que o aplicativo exige para instalação."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Os escopos estranhos devem ser removidos."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se determinados escopos se aplicarem apenas a um subconjunto da base de usuários do aplicativo, eles deverão ser incluídos como escopos condicionalmente obrigatórios ou opcionais."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Esteja associado a um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "domínio verificado"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seus ativos públicos serão avaliados quanto às práticas recomendadas de segurança relacionadas a softwares desatualizados e a várias vulnerabilidades e descobertas de servidores da Web."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemplo de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app currently requires four scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "timeline"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". According to our logs, however, it only made requests to the CRM Contacts and Timeline Events APIs in the last 30 days. Since the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " scopes are not required for either of these functions, please remove them as required from the app’s settings to minimize the permissions users must accept."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Confiabilidade e desempenho"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Deve estar em situação regular, ou seja:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Em conformidade com todos os termos aplicáveis."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Não ter a certificação rejeitada nos últimos seis meses."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Não ter escalonamentos de suporte não resolvidos com clientes mútuos."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Deve usar versões estáveis e públicas das APIs e extensões da HubSpot."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Recomenda-se o uso das versões públicas mais recentes."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "As APIs de visualização não documentadas, beta e de desenvolvedor são consideradas instáveis e não devem ser usadas no seu aplicativo de produção."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Observação: se o aplicativo usar APIs que não são estáveis ou públicas, informe isso durante a revisão da certificação ou à equipe do Gerente de parceiros de aplicativos para discutir as opções."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Mantenha um volume razoável de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "atividades"
          }), " nas contas de clientes da HubSpot não afiliadas à sua organização."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["O app deve seguir as ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/api-usage/usage-details",
            children: "diretrizes de uso da API"
          }), " e as práticas recomendadas, incluindo:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Respeito aos limites de taxa (ou seja, 100 solicitações de entrada a cada 10 segundos por conta conectada da HubSpot)."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Atualização de tokens de acesso do OAuth antes da expiração."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Armazenamento de dados em cache para chamadas repetidas quando possível."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O uso de APIs em lote e assinaturas de webhook para reduzir o volume de solicitações quando possível."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O uso de APIs para criar propriedades, fluxos de trabalho e ações de fluxo de trabalho personalizadas, em vez de exigir a ação do usuário."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Seu app deve manter uma taxa de sucesso média acima de ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "95%"
          }), " em todas as ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "atividades"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "As solicitações que resultam em respostas de erro são imputadas na taxa de sucesso."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Alguns erros inevitáveis ou esperados podem ser excluídos ao calcular as taxas de sucesso em todas as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/marketplace/certification-requirements#activity",
              children: "atividades"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "O aplicativo pode ter uma extensão de navegador para fornecer funcionalidades complementares e valor aos clientes:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "As extensões de navegador não devem ser criadas especificamente para a UI do HubSpot ou como uma solução alternativa para as APIs do HubSpot."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["As extensões de navegador não devem injetar capacidades ou componentes na UI do HubSpot.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Extensões de UI oficialmente compatíveis (por exemplo, ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "cartões CRM"
                }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/automation/custom-workflow-actions",
                  children: "ações de fluxo de trabalho personalizado"
                }), ") fornecem experiências de usuário mais consistentes para os clientes"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O aplicativo estará sujeito a uma avaliação de segurança adicional se incluir uma extensão de navegador."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemplos de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s API success rate falls below the 95% threshold required for certification. Our logs show a 83% success rate in the last 30 days. The vast majority of these requests returned ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), " burst rate limit errors. To reduce this error rate, we recommend throttling requests to 100 requests per 10 seconds for each account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating errors around trying to update contacts using an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undefined"
        }), " email address, which will not work with this endpoint. Your app should skip these requests if a record does not have an email address."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is making requests with expired OAuth tokens and receiving ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors before refreshing the token. To minimize these errors, we recommend that your app keep track of when tokens expire or refresh tokens before making requests. If you start seeing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors for 100% of requests and are unable to refresh the access token, consider the app uninstalled and stop making requests for the account until a user re-authenticates your app."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors from trying to use the Contact Lists API with Marketing Hub Free accounts, which do not have access to contact lists. If your app repeatedly gets ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors for missing the proper scopes, it should stop making calls to retrieve lists from that account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s webhook subscriptions frequently fail with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "500"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "503"
        }), " errors. Make sure that your server can handle the volume of requests (currently limited to 150 per second) so that customer data is not lost."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is pulling many contacts one at a time instead of pulling them in batches. We recommend using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "batch endpoints"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usabilidade e acessibilidade | App"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Seu aplicativo deve estar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "listado"
        }), " no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps",
          children: "Marketplace de aplicativos"
        }), " da HubSpot por pelo menos seis meses."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O aplicativo deve apresentar as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/ten-usability-heuristics/",
          children: "práticas recomendadas de usabilidade"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A instalação e o uso não devem causar confusão ou frustração para os clientes mútuos ou afetar negativamente a experiência do usuário do HubSpot."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemplo de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app currently requires users to manually configure workflow webhook actions to send text messages. Consider creating custom workflow actions via the app which are flexible enough to accommodate many use cases."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Acessibilidade e usabilidade | Listagem do marketplace de apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A listagem do marketplace de apps deve:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Descrever com precisão a funcionalidade atual do aplicativo.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se a funcionalidade mudar com base no produto ou no nível de assinatura do usuário, seja para a HubSpot ou para a sua solução, as diferenças devem ser esclarecidas."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Conter informações claras e precisas sobre preços, incluindo um link para sua página de preços atual.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Se vários pacotes oferecerem suporte ao seu aplicativo, a listagem do Marketplace de aplicativos deverá incluir, no mínimo, a opção menos custosa."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use dados de espaço reservado ou oculte dados para não exibir informações de identificação pessoal (PII)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluir:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Recursos visuais informativos e atualizados, que podem incluir capturas de tela ou um vídeo. Consulte a página ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
              children: "Como fazer um ótimo vídeo de demonstração de aplicativo"
            }), " para obter as melhores práticas e exemplos de como criar um vídeo de demonstração."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Um \"URL de documentação de configuração\" atualizado que contém um subdomínio e leva diretamente à página que hospeda a documentação do app. Esse link não deve levar à sua página inicial."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Não inclui:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Quaisquer dados ou estatísticas, a menos que um estudo de caso seja fornecido como um recurso."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemplos de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your App Marketplace listing includes few specific details about your app’s functionality. Please enhance the listing with screenshots which depict app functionality and include more thorough descriptions of common use cases and in-app behavior."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot customers are used to a “try before you buy” experience when purchasing our products and services. For this reason, we recommend your app provide a free trial or freemium sign-up experience. Some app partners who do not have pricing pages or free trials have created “HubSpot plans,” offering mutual customers transparent pricing, touchless sign-up, and other benefits."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usabilidade e acessibilidade | Documentação de suporte"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A documentação de suporte do app deve:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Estar em um URL ativo e acessível publicamente (ou seja, que não exija acesso pago ou login) e atenda a padrões atuais de acessibilidade, privacidade e GDPR."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Esteja atualizado e seja consistente com a versão atual do aplicativo."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Descrever claramente:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A funcionalidade do seu aplicativo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Como instalar seu aplicativo e conectar uma conta da HubSpot com capturas de tela de cada etapa, incluindo a tela de aprovação do escopo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Como configurar o aplicativo depois que ele estiver instalado."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Como usar o aplicativo, incluindo interações manuais e automatizadas."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Como desconectar o HubSpot do aplicativo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Como desinstalar seu aplicativo de uma conta da HubSpot."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Como a desconexão e a desinstalação podem afetar os dados e as contas da HubSpot dos usuários."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluir imagens. Todas as imagens que contêm capturas de tela da UI da HubSpot devem estar atualizadas e ser consistentes com nosso ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://canvas.hubspot.com/",
          children: "sistema de design"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Vídeos também são recomendados, mas não obrigatórios. Os vídeos devem ser atualizados regularmente e refletir a versão atual do aplicativo."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemplo de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The setup guide for your app includes a screenshot depicting the scopes your app requires for installation. This screenshot does not show the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "business-intelligence"
        }), " scope, which is selected in your app’s settings. Please update the screenshot so that it reflects the current required scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Valor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "As análises ativas de contagem, retenção e Marketplace de aplicativos da HubSpot da instalação são avaliadas como indicadores do valor que os clientes mútuos encontram no seu aplicativo."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["O aplicativo deve ter, no mínimo, 60 instalações ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#activity",
              children: "ativas"
            }), " e exclusivas para se qualificar e manter a certificação. As contas com instalações não devem ser afiliadas à sua organização. As contas de teste também serão excluídas.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Se o aplicativo tiver menos de 60 instalações ativas, você deverá cancelar a solicitação de certificação."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Se o aplicativo tiver menos do que as três instalações ativas necessárias para ser listado, ele poderá ser removido do Marketplace de aplicativos."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "A lista de aplicativos deve ter respostas da sua equipe para quaisquer comentários negativos do aplicativo."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemplo de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app has not maintained at least 60 active installs over the trailing six month period. As such, its certified status will be removed. You may re-apply for certification in six months."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "O processo de revisão de certificação e recertificação do app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A equipe de Qualidade do ecossistema da HubSpot responde às ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "solicitações de certificação de aplicativos"
      }), " em até ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "10 dias úteis"
      }), ". Todo o processo de análise e feedback do aplicativo não deve demorar mais de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "60 dias"
      }), " a partir do momento em que o feedback é compartilhado. Revise os critérios listados ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/testing-credentials",
        children: "aqui"
      }), " para fornecer credenciais de teste ao aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se o seu app atender a todos os requisitos, ele ganhará o status de certificado e um emblema \"App certificado pela HubSpot\" será exibido aos clientes e prospects no marketplace de apps. Seu app também aparecerá quando os usuários selecionarem o filtro \"App certificado pela HubSpot\"."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se a revisão do app não for concluída com sucesso, você poderá repetir a aplicação após seis meses."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de ser certificado por um ano, a equipe de Qualidade do ecossistema da HubSpot analisará seu aplicado para garantir ele ainda atende aos rigorosos requisitos de certificação. Se o aplicativo não atender mais a esses padrões, a equipe colaborará com você por até sessenta dias para resolver problemas."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se, a qualquer momento, seu aplicativo deixar de atender aos requisitos de certificação listados acima, a HubSpot pode iniciar imediatamente um processo de recertificação, mesmo se o aplicativo tiver sido certificado por menos de um ano. Conforme estabelecido no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/app-program-agreement",
          children: "Acordo do Programa de Parceiros de Aplicativos"
        }), ", a HubSpot também se reserva o direito de cancelar a publicação do seu aplicativo a qualquer momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Perguntas frequentes"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Are there any costs associated with app certification?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "No, we do not charge you a fee to list or certify your apps in the App Marketplace, nor a fee for installs generated through the App Marketplace. There is no revenue sharing. We are here to support you to make your app of higher quality."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Will I be notified when my app will be eligible again for certification if it was previously rejected?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No. At this time we do not have notifications enabled to notify you if and when you will be eligible to re-apply at this time. Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best resource to contact and ask if you are eligible before applying."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How should I promote my certified app to my customers?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Feel free to use the press release template on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/resources",
          children: "this page"
        }), " to share the news that your app has earned certification."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you plan to post on social media, be sure to tag HubSpot — we love to celebrate alongside our app partners!"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I enjoy experimenting with the newest beta APIs. Is app certification right for me?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We recommend you reach out to your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " to see if app certification is right for your app."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Our goal is to ensure your app is well built for our mutual customers and limits breaking changes, which requires your app uses the latest stable APIs. We also love seeing and supporting entrepreneurs, early adopters, and developers who are eager to experiment with the newest beta APIs."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I'd like my app to be featured on the App Marketplace. Is this what app certification is for?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The benefits of being featured in collections and for customers to easily filter for a certified app within the App Marketplace are continuing to evolve. We’d like to learn more about how you would find being featured the most helpful (e.g. App Marketplace, HubSpot community, HubSpot curated newsletters or other forms)."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best contact to discuss potential future benefits and start this conversation."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Why and how will my public assets be assessed for security best practices?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "With the average customer using more than five integrations, it’s imperative apps are monitored and held to privacy, security, and quality standards over time. Any public assets will be assessed using information already provided during a listing process and findings will be analyzed using a non-invasive method."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "My app was certified over a year ago. What do I have to do to keep my certification status and what does it mean to get recertified?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The HubSpot Ecosystem Quality team will reach out if your app is out of compliance or due for annual recertification with next steps."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We encourage you to monitor your app’s performance, certification requirements, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "Developer Changelog"
        }), ", and any additional HubSpot resources related to any changes in technology used and how your app could stay up to date."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Encontre o seu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gerente de parceiros de apps"
        }), " e as respetivas informações de e-mail fazendo login na sua conta de desenvolvedor e navegando até ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de apps"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listagens"
        }), ". Passe o cursor do mouse sobre o app, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mais"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar detalhes da listagem"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "Como solicitar a certificação do app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "Como listar seu app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Requisitos de uma listagem de apps"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
        children: "Fórum da comunidade para desenvolvedores"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/contact-our-partnerships-team",
        children: "Entre em contato com a equipe de Parceiros do aplicativo"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}