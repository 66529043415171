"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492355257;
const slug = exports.slug = 'guides/cms/content/templates/overview';
const title = exports.title = 'Resumen de plantillas';
const name = exports.name = 'EMEA ES (es) Templates';
const metaDescription = exports.metaDescription = 'Las plantillas son envoltorios de páginas o correos electrónicos reutilizables que generalmente colocan módulos y parciales en un diseño. Los creadores de contenido seleccionan una plantilla para utilizarla antes de crear una página o un correo electrónico.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-plantilla",
  "label": "Crear una plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "ver-vista-preliminar-de-la-plantilla",
  "label": "Ver vista preliminar de la plantilla",
  "parent": null
}, {
  "depth": 1,
  "id": "vista-previa-con-el-administrador-de-dise%C3%B1o",
  "label": "Vista previa con el administrador de diseño",
  "parent": "ver-vista-preliminar-de-la-plantilla"
}, {
  "depth": 1,
  "id": "vista-preliminar-con-una-nueva-p%C3%A1gina",
  "label": "Vista preliminar con una nueva página",
  "parent": "ver-vista-preliminar-de-la-plantilla"
}, {
  "depth": 0,
  "id": "tipos-de-plantilla",
  "label": "Tipos de plantilla",
  "parent": null
}, {
  "depth": 1,
  "id": "p%C3%A1gina",
  "label": "Página",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "correo-electr%C3%B3nico",
  "label": "Correo electrónico",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "parcial",
  "label": "Parcial",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "parcial-global",
  "label": "Parcial global",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "blog",
  "label": "Blog",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "lista-de-blog",
  "label": "Lista de blog",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "publicaci%C3%B3n-de-blog",
  "label": "Publicación de blog",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "plantilla-combinada-de-publicaciones-de-blog-y-anuncios",
  "label": "Plantilla combinada de publicaciones de blog y anuncios",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "p%C3%A1ginas-del-sistema",
  "label": "Páginas del sistema",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "p%C3%A1ginas-de-error",
  "label": "Páginas de error",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "preferencias-de-suscripci%C3%B3n-al-correo-electr%C3%B3nico",
  "label": "Preferencias de suscripción al correo electrónico",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "cancelaci%C3%B3n-de-suscripci%C3%B3n-de-copia-de-seguridad-de-correo-electr%C3%B3nico",
  "label": "Cancelación de suscripción de copia de seguridad de correo electrónico",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "confirmaci%C3%B3n-de-cancelaci%C3%B3n-de-suscripci%C3%B3n-a-correo-electr%C3%B3nico",
  "label": "Confirmación de cancelación de suscripción a correo electrónico",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "solicitud-de-contrase%C3%B1a",
  "label": "Solicitud de contraseña",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "p%C3%A1gina-de-resultados-de-b%C3%BAsqueda",
  "label": "Página de resultados de búsqueda",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 1,
  "id": "afiliaci%C3%B3n",
  "label": "Afiliación",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "inicio-de-sesi%C3%B3n-de-membres%C3%ADa",
  "label": "Inicio de sesión de membresía",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "registro-de-membres%C3%ADa",
  "label": "Registro de membresía",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "restablecimiento-de-contrase%C3%B1a-de-membres%C3%ADa",
  "label": "Restablecimiento de contraseña de membresía",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 2,
  "id": "solicitud-de-restablecimiento-de-membres%C3%ADa",
  "label": "Solicitud de restablecimiento de membresía",
  "parent": "tipos-de-plantilla"
}, {
  "depth": 0,
  "id": "archivos-cms-incluidos",
  "label": "Archivos CMS incluidos",
  "parent": null
}, {
  "depth": 1,
  "id": "jquery",
  "label": "jQuery",
  "parent": "archivos-cms-incluidos"
}, {
  "depth": 1,
  "id": "layout.css",
  "label": "layout.css",
  "parent": "archivos-cms-incluidos"
}, {
  "depth": 1,
  "id": "c%C3%B3digo-de-seguimiento-de-hubspot",
  "label": "Código de seguimiento de HubSpot",
  "parent": "archivos-cms-incluidos"
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de plantillas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas definen el diseño de tus páginas, correos electrónicos y temas de HubSpot. Una plantilla se compone de módulos y parciales, y puede hacer referencia a otros materiales como hojas de estilo y archivos JavaScript. Las plantillas pueden ser creadas usando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "CLI de HubSpot"
      }), " o en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "el administrador de diseño de HubSpot"
      }), ". Para los creadores de contenido, la plantilla es lo primero que seleccionarán al crear una página o un correo electrónico."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aprende a crear una plantilla, los diferentes tipos de plantillas y lo que se incluye en ellas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una plantilla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes crear una plantilla tanto ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "en HubSpot"
      }), " como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "utilizando la CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para crear una plantilla utilizando la CLI, ejecuta el siguiente comando:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <name> [dest]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de la plantilla"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La ruta del directorio local en el que deseas crear la plantilla. Si no se incluye, la plantilla se creará en el directorio en el que te encuentres."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilizando las teclas de flecha, desplázate hasta el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#template-types",
          children: "tipo de plantilla"
        }), " que quieras crear y luego pulsa ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "intro"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La plantilla se creará entonces localmente. Cuando quieras que la plantilla esté disponible para su uso en tu cuenta de HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#upload",
        children: "sube"
      }), " la plantilla a tu cuenta. También puedes utilizar el comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#watch",
        children: "watch"
      }), " para cargar automáticamente todos los archivos nuevos y las ediciones de los archivos existentes en el directorio de trabajo actual y los directorios secundarios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ver vista preliminar de la plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de actualizar una plantilla, puedes obtener una vista previa para asegurarte de que se vea y actúe como esperas. Hay algunas formas en que HubSpot puede obtener una vista previa de una plantilla, como:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista previa de una plantilla en el administrador de diseño:"
        }), " mejor para verificaciones visuales rápidas o cuando se necesita una vista previa de una publicación/anuncio/plantilla combinada del blog."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear un nuevo activo a partir de una plantilla:"
        }), " lo mejor para probar el editor de arrastrar y soltar y la experiencia de creación de contenido."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vista previa con el administrador de diseño"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vista previa de las plantillas usando el administrador de diseño puede ser especialmente útil para verificaciones visuales rápidas. El generador de vista previa de plantillas también te permite configurar opciones de presentación, como las dimensiones de viewport."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para obtener una vista previa de una plantilla en el administrador de diseño:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivos y plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Herramientas de diseño"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Con el explorador de archivos de la barra lateral izquierda, haz clic en la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "plantilla"
        }), " de la que deseas una vista previa."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vista preliminar en directo con opciones de presentación"
            }), " para obtener una vista preliminar de la plantilla con opciones para probar la capacidad de respuesta y la configuración del dominio, como las hojas de estilo. Esta opción muestra la página dentro de un iframe. Esto también le permite seleccionar entre blogs y la publicación del blog o la vista de listado para las plantillas de blog."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vista previa sin opciones de visualización"
            }), " para obtener una vista preliminar de la plantilla sin opciones adicionales."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/template-preview-options.png",
        alt: "template-preview-options"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vista preliminar con una nueva página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al cambiar las áreas de arrastrar y soltar, los valores de los parámetros predeterminados en los módulos u otros cambios significativos, es mejor ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/website-pages/create-and-customize-pages",
        children: "crear una página web"
      }), ", un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/blog/create-a-new-blog",
        children: "blog"
      }), ", una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/blog/create-and-publish-blog-posts",
        children: "publicación de blog"
      }), " o un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
        children: "correo electrónico"
      }), " utilizando la plantilla. A continuación, puedes probar diferentes valores de los campos de los módulos y probar cómo será tu plantilla en el mundo real y optimizarla para obtener la mejor experiencia del creador de contenido. Puedes publicar estos materiales o dejarlos en borrador para fines de prueba."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además, puedes usar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-general/redesign-and-relaunch-your-site-with-content-staging#new",
        children: "preparación de contenido"
      }), " o una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "cuenta de entorno de pruebas de desarrollador"
      }), " para crear y ver activos sin afectar una cuenta de producción."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las plantillas pueden utilizarse para diferentes tipos de contenido, como páginas web y publicaciones de blog. En las plantillas codificadas, se designa el tipo de plantilla agregando una anotación en la parte superior del archivo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, conoce los diferentes tipos de plantillas y las anotaciones que puedes utilizar para designar cada tipo."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " un creador de contenido puede cambiar la plantilla de una página por otra del mismo tipo, dependiendo de si tiene etiquetas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas construidas con el editor de diseño visual de arrastrar y soltar pueden cambiarse por otras plantillas de arrastrar y soltar o plantillas codificadas con o sin etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " ."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " solo pueden cambiarse por otras plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas ", (0, _jsxRuntime.jsx)("u", {
            children: "sin"
          }), " etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " solo pueden cambiarse por otras plantillas codificadas sin etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de página son el tipo de plantilla más abierto. Pueden servir como cualquier tipo de página web o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/7177/what-is-a-landing-page-and-why-should-you-care.aspx",
        children: "página de destino"
      }), ". Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#layout-css",
        children: "diseños"
      }), " de página no están precargados con ningún componente. Las plantillas de páginas codificadas vienen precargadas con un marcador escaso que incluye las etiquetas HubL sugeridas para la metainformación, el título y los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#included-cms-files",
        children: "includes"
      }), " de encabezado y pie de página necesarios. Algunos ejemplos de páginas que suelen utilizar una plantilla de página son, entre otros, los siguientes"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
          children: "Página de inicio"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/about.html",
          children: "Acerca de nosotros"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/contact.html",
          children: "Contacto"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Correo electrónico"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de correo electrónico son utilizadas por la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/marketing/email",
        children: "herramienta de correo electrónico"
      }), ". Tienen el conjunto de requisitos más estricto, ya que tienen que ser vistos en muchos clientes de correo electrónico diferentes y ajustarse a las mejores prácticas para garantizar una correcta capacidad de entrega. Tanto las plantillas de correo electrónico de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), " como las del administrador de diseño de arrastrar y soltar vienen preconfiguradas con componentes básicos en el momento de la creación. Las plantillas con este ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " solo son visibles para la selección de plantillas al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/email/create-and-send-marketing-emails",
        children: "crear un correo electrónico"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para cumplir con la normativa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), " , las plantillas de correo electrónico tienen un conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/variables#required-email-template-variables",
        children: "variables obligatorias"
      }), " que deben incluirse."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de correo electrónico también tienen una funcionalidad incorporada a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/email-template-markup#hs-inline-css-and-data-hse-inline-css",
        children: "inline-css"
      }), " agregada a los elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " con un nombre de clase especial o atributo de datos. Inlining CSS en los correos electrónicos es un método utilizado para obtener una mejor compatibilidad con los clientes de correo electrónico. Afortunadamente, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://litmus.com/blog/do-email-marketers-and-designers-still-need-to-inline-css",
        children: "la mayoría de los clientes de correo electrónico más populares admiten ahora el css incrustado"
      }), ", que sin embargo no es representativo de sus destinatarios específicos. Usa el buen juicio para hacer lo que es correcto para tus destinatarios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/email-template-markup",
        children: "Más información sobre la creación de plantillas de correo electrónico."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parcial"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page isAvailableForNewContent: false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las plantillas parciales son archivos que pueden incluirse en otros archivos codificados. A diferencia de los parciales globales, los parciales se pueden editar individualmente sin afectar otras instancias del parcial."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/html-hubl-templates#partials",
        children: "parciales"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parcial global"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: global_partial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los parciales globales son un tipo de plantilla creada con HTML y HubL que se puede reutilizar en todo el sitio web. Los tipos de parciales más comunes son los encabezados, las barras laterales y los pies de página. La actualización de un parcial global actualizará todas las instancias del parcial global."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/html-hubl-templates#global-partials",
        children: "parciales globales"
      }), " y el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "contenido global"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-blog/cos-blog-how-do-i-create-a-cos-blog",
        children: "crear un blog"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "las plantillas de blog"
      }), " tienen una estructura similar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#page-templates",
        children: "las plantillas de página"
      }), "estándar. La diferencia crucial es que pueden seleccionarse en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "configuración de contenido"
      }), " como plantillas de blog, mientras que las plantillas de página no pueden. Las plantillas creadas con ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), ", o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "no aparecen"
      }), " cuando un usuario está creando una página web, en la pantalla de selección de plantillas. Las plantillas de blog tienen en realidad dos formas, las páginas de listado del blog y las páginas de detalle de las publicaciones del blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Lista de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_listing\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "plantilla del listado del blog"
      }), " es la plantilla que los usuarios verán cuando naveguen a la URL del blog. Normalmente esta plantilla se utiliza para listar resúmenes, títulos e imágenes destacadas de todas las publicaciones del blog, además suele mostrar la paginación para llegar a las publicaciones más antiguas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Publicación de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_post\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La plantilla de la publicación del blog, es la plantilla que los usuarios verán cuando vean una publicación individual en el blog. Estas plantillas suelen mostrar el contenido completo de la publicación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Plantilla combinada de publicaciones de blog y anuncios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una sola plantilla de blog puede encargarse del diseño de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/building-blocks/templates/blog-template-markup#if-is-listing-view-statement",
        children: "páginas de anuncios y de las páginas detalladas"
      }), ", pero lo más habitual es que se separen en plantillas distintas. Las plantillas combinadas se mostrarán en la configuración del blog como seleccionables tanto para las opciones de los anuncios como para las publicaciones de blog. Si estás creando una plantilla que está destinada a ser utilizada solo para posts o anuncios, debes utilizar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para simplificar el desarrollo y la experiencia del creador de contenido, se recomienda utilizar por separado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_listing"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateTypes"
        }), " en lugar de las plantillas combinadas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Páginas del sistema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de las páginas del sistema están marcadas internamente para su propósito específico. En la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "configuración de contenido"
      }), " de tu cuenta, puedes seleccionar estas plantillas para su propósito específico en la pestaña del sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " el único tipo de plantilla de página del sistema que se puede crear a través de la CLI es la plantilla de página de resultados de búsqueda."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Páginas de error"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las páginas de error pueden establecerse en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Configuración de contenido"
      }), " como páginas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/404.html",
        children: "404"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/500.html",
        children: "500"
      }), ". Ambas plantillas utilizan el mismo templateType. Las plantillas creadas con este templateType no aparecen cuando un usuario está creando una página web, en la pantalla de selección de plantillas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: error_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Preferencias de suscripción al correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La página de preferencias de suscripción a correos Enumera todos los tipos de suscripción disponibles que un usuario puede aceptar o cancelar. Requerido para contener la etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_subscriptions \"email_subscriptions\"  %}"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscription-preferences.html",
        children: "Consulta la plantilla de preferencias de suscripción en el cms-theme-boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscription_preferences_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Cancelación de suscripción de copia de seguridad de correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una plantilla del sistema para páginas de cancelación de suscripción a correos electrónicos. Requerido para contener la etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_simple_subscription \"email_simple_subscription\" %}"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/backup-unsubscribe.html",
        children: "Consulta la plantilla de cancelación de suscripción de la copia de seguridad del correo electrónico en el cms-theme-boilerplate."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_backup_unsubscribe_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Confirmación de cancelación de suscripción a correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una plantilla del sistema para páginas de preferencias de confirmación de cancelación de suscripción a correos electrónicos Aquí es donde se envía a los usuarios cuando van a la URL generada por la variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/variables#email-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ unsubscribe_link_all }}"
        })
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscriptions-confirmation.html",
        children: "Consulta la plantilla de confirmación de suscripción en el cms-theme-boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscriptions_confirmation_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Solicitud de contraseña"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de solicitud de contraseña proporcionan una página de marca que los creadores de contenido pueden mostrar para requerir una contraseña antes de que un visitante pueda ver el contenido real de la página. Las plantillas de solicitud de contraseña se configuran a través de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Configuración de contenido"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "Cómo hacer que una página en HubSpot esté protegida por contraseña"
      }), ". Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/password-prompt.html",
        children: "solicitud de la página protegida por contraseña"
      }), " en la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: password_prompt_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Página de resultados de búsqueda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una plantilla del sistema para la funcionalidad de listado de búsqueda del sitio CMS integrada. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "plantilla de la página de resultados de búsqueda"
      }), " en la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: search_results_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Afiliación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las cuentas de HubSpot con la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "funcionalidad de membresías"
      }), " (", (0, _jsxRuntime.jsx)(_components.em, {
        children: "solo"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "CMS Hub"
      }), " Enterprise) pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-pages-editor/control-audience-access-to-pages",
        children: "crear páginas en tus sitios a las que solo pueden acceder los usuarios que forman parte de listas específicas en el CRM"
      }), ". Esto permite a los visitantes del sitio tener cuentas con credenciales de acceso. Estas plantillas te permiten controlar la apariencia de estas páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Solo las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "plantillas HTML + HubL"
        }), " pueden ser plantillas de afiliación."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Inicio de sesión de membresía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta es la página de inicio de sesión que se muestra cuando un usuario intenta acceder a un contenido cuyo acceso está controlado a través de la funcionalidad de membresías. Comúnmente contiene el módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_login \"member_login\" %}"
      }), ". Consulta el ejemplo de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-login.html",
        children: "plantilla de inicio de sesión de los miembros"
      }), " en la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_login_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Registro de membresía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta es la página de registro de usuarios que permite crear una cuenta para ver el contenido al que pueden acceder los usuarios de esta lista. Comúnmente contiene la etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_register \"member_register\" %}"
      }), ". Consulta el ejemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-register.html",
        children: "plantilla de registro de miembros"
      }), " en la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_register_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Restablecimiento de contraseña de membresía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta es la página de restablecimiento de la contraseña. Los usuarios proporcionan su nueva contraseña en esta página. Comúnmente contiene la etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset \"password_reset\" %}"
      }), ". Consulta el ejemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password.html",
        children: "plantilla de restablecimiento de la contraseña de membresía"
      }), " en la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Solicitud de restablecimiento de membresía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta es la página de solicitud de restablecimiento de contraseña. Mostrar un formulario para solicitar un correo electrónico de restablecimiento de contraseña. Comúnmente contiene la etiqueta HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset_request \"password_reset_request\" %}"
      }), ". Consulta el ejemplo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password-request.html",
        children: "plantilla de solicitud de restablecimiento de la contraseña de afiliación"
      }), " en la plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_request_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Archivos CMS incluidos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay ciertos archivos JavaScript y CSS que se adjuntan a las plantillas del CMS. Algunos archivos se incluyen automáticamente y no se pueden eliminar, mientras que otros se pueden incluir opcionalmente. Para saber más sobre el orden en que las hojas de estilo se adjuntan al contenido del CMS, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-general/create-edit-and-attach-css-files-to-style-your-site",
        children: "consulta este artículo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jquery.com/",
        children: "jQuery"
      }), " se incluye opcionalmente en la etiqueta head de las plantillas de HubSpot. Si se incluye, se renderiza como parte de la variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " HubL."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ajustes > Sitio web > Páginas"
      }), " puedes cambiar la versión de jQuery a 1.11.x, a la versión 1.7.1,o desactivarla por completo. También puedes optar por incluir un script de migración de jQuery para la compatibilidad con navegadores antiguos. Puedes mover jQuery al pie de página para mejorar el rendimiento de la página, pero mover jQuery puede romper JavaScript dependiendo de él. Se recomienda probar esto antes de moverlo agregando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " al final de las URL de la página de tu sitio web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si bien jQuery se incluyó históricamente por opción predeterminada, actualmente CMS Hub no requiere jQuery. La mayoría de las funcionalidades de jQuery ahora tienen equivalentes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://youmightnotneedjquery.com/",
        children: "modernos de javascript de vainilla"
      }), ", y se recomienda usarlos en su lugar. Si necesitas usar jQuery, te recomendamos que deshabilites la versión predeterminada en la configuración y uses la última versión cargada arriba de la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para probar si la eliminación de jQuery en tu sitio romperá algo, agrega ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsNoJQuery=true"
      }), " al final de la URL mientras ves varias páginas de tu sitio, especialmente aquellas con mucha interactividad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "layout.css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anteriormente conocido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "required_base.css"
      }), ", este archivo es responsable de diseñar la cuadrícula receptiva de HubSpot. Este archivo se incluye automáticamente en cualquier plantilla de arrastrar y soltar, pero ", (0, _jsxRuntime.jsx)("u", {
        children: "no"
      }), " se incluye por opción predeterminada en las plantillas codificadas personalizadas. Para los desarrolladores que utilicen las etiquetas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), " en plantillas HTML + HubL codificadas, no es necesario cargar el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "layout.css"
      }), ", pero se ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "incluye una versión del mismo en la plantilla del tema CMS"
      }), " para facilitar su puesta en marcha rápidamente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de la rejilla responsiva CSS, el archivo incluye algunas clases que pueden utilizarse para mostrar y ocultar elementos en diferentes ventanas. Para obtener más información, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/Designers_Docs_2015/layout.css",
        children: "ve el archivo directamente"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Código de seguimiento de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/account/how-does-hubspot-track-visitors",
        children: "código de seguimiento de HubSpot"
      }), " siempre se agrega automáticamente a cualquier plantilla de HubSpot, excluyendo las plantillas de correo electrónico, con la variable de HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "standard_footer_includes"
        })
      }), ". El código de seguimiento carga un archivo JavaScript de análisis llamado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "your_HubID.js"
      }), " (ejemplo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "158015.js"
      }), "). Este código de seguimiento está directamente integrado con la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/account/how-do-i-turn-on-gdpr-functionality-in-my-hubspot-account",
        children: "funcionalidad GDPR de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Áreas de arrastrar y soltar"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "Plantillas de secciones"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Cómo usar componentes web en plantillas y módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "Crear encabezados, pies de página y más utilizando contenido global"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}