"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710048;
const slug = exports.slug = 'guides/cms/setup/getting-started-with-local-development';
const title = exports.title = 'Erste Schritte mit lokaler Entwicklung';
const name = exports.name = 'Erste Schritte mit lokaler Entwicklung';
const metaDescription = exports.metaDescription = 'Erste Schritte mit lokaler Entwicklung in CMS Hub . ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/local_development.png';
const featuredImageAltText = exports.featuredImageAltText = 'Erste Schritte mit lokaler Entwicklung';
const toc = exports.toc = [{
  "depth": 0,
  "id": "abh%C3%A4ngigkeiten-installieren",
  "label": "Abhängigkeiten installieren",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-ein-arbeitsverzeichnis-erstellen",
  "label": "1. Ein Arbeitsverzeichnis erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-die-tools-f%C3%BCr-lokale-entwicklung-konfigurieren",
  "label": "2. Die Tools für lokale Entwicklung konfigurieren",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-eine-ressource-erstellen%2C-das-sie-in-hubspot-abrufen-k%C3%B6nnen",
  "label": "3. Eine Ressource erstellen, das Sie in HubSpot abrufen können",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-das-modul-in-ihrer-lokalen-umgebung-abrufen",
  "label": "4. Das Modul in Ihrer lokalen Umgebung abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-%C3%A4nderungen-vornehmen-und-hochladen",
  "label": "5. Änderungen vornehmen und hochladen",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-mithilfe-eines-watch-befehls-%C3%A4nderungen-automatisch-hochladen",
  "label": "6. Mithilfe eines watch-Befehls Änderungen automatisch hochladen",
  "parent": null
}, {
  "depth": 0,
  "id": "n%C3%A4chste-schritte",
  "label": "Nächste Schritte",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      ol: "ol",
      strong: "strong",
      br: "br",
      em: "em",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erste Schritte mit lokaler Entwicklung"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot CLI"
      }), " (Command Line Interface) verknüpft Ihre lokale Umgebung mit HubSpot, was bedeutet, dass Sie lokale Kopien Ihrer HubSpot-Web-Ressourcen haben. Auf diese Weise können Sie bei der Entwicklung im CMS von HubSpot die Versionskontrolle, Ihren bevorzugten Texteditor und verschiedene Webentwicklungstechnologien verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Leitfaden ist für die Benutzer geeignet, die bereits mit dem CMS vertraut sind, aber die Arbeit mit dem CLI erlernen möchten. Wenn Sie völlig neu in der CMS Hub-Entwicklung sind, empfehlen wir Ihnen, die Schnellanleitung zu befolgen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Schneller Einstieg in die CMS Hub-Entwicklung"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Tutorial erfahren Sie, wie Sie:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die CLI installieren und mit Ihrem HubSpot-Account verknüpfen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein Modul von Ihrem HubSpot-Account abrufen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das Modul lokal aktualisieren und dann Ihre Änderungen hochladen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), "-Befehls weiter gespeicherte Änderungen hochladen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Befehle und lokale Dateiformate finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "Referenz zu den Tools für lokale Entwicklung"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abhängigkeiten installieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um lokal auf HubSpot zu entwickeln, müssen Sie Folgendes erledigen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Installieren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), ", eine JavaScript-Laufzeitumgebung, die die lokalen Tools ermöglicht. Es wird Version 16 oder höher von Node unterstützt, wir empfehlen jedoch die Long-Term-Support-Version (LTS)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli"
        }), " in Ihrer Befehlszeile aus, um das HubSpot CLI global zu installieren. Um die Tools stattdessen nur in Ihrem aktuellen Verzeichnis zu installieren, führen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Sie auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://classic.yarnpkg.com/en/docs/install",
        children: "Yarn"
      }), " verwenden, wenn Sie dies bevorzugen. Wenn Sie Yarn verwenden, werden die Befehle mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yarn"
      }), "-Präfix ausgeführt."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erhalten Sie einen EACCES-Fehler bei der Installation?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Siehe ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.npmjs.com/resolving-eacces-permissions-errors-when-installing-packages-globally",
          children: "Beheben von EACCESS-Berechtigungsfehlern bei der globalen Installation von Paketen"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Ein Arbeitsverzeichnis erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erstellen Sie einen Ordner für die Arbeit, die Sie unten erledigen werden. Benennen Sie für die Zwecke dieses Tutorials den Ordner ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local-dev-tutorial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können dies lokal tun, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mkdir local-dev-tutorial"
      }), " in der Befehlszeile ausführen, wodurch das Verzeichnis erstellt wird. Führen Sie dann ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd local-dev-tutorial"
      }), " aus, um zu diesem Verzeichnis zu navigieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Die Tools für lokale Entwicklung konfigurieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Führen Sie als Nächstes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " aus, um die Tools mit Ihrem HubSpot-Account zu verknüpfen. Dieser Befehl führt Sie durch die Schritte unten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Um das CLI mit einem HubSpot-Account zu verknüpfen, müssen Sie den persönlichen Zugriffsschlüssel des Accounts kopieren. Wenn Sie dazu aufgefordert werden, drücken Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Eingabetaste"
          }), ", um hubspot.com mit der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/l/personal-access-key",
            children: "Seite mit dem persönlichen Zugriffsschlüssel des Accounts"
          }), " zu öffnen. Wenn Sie mehrere Accounts haben, werden Sie im Browser aufgefordert, zuerst einen Account auszuwählen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Auf der Seite mit dem persönlichen Zugriffsschlüssel können Sie einen neuen persönlichen Zugriffsschlüssel generieren oder den vorhandenen Schlüsselwert kopieren, falls dieser bereits vorhanden ist."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn Sie einen Schlüssel zum ersten Mal erstellen, wählen Sie aus, auf welche Bereiche der Schlüssel Zugriff hat. Sie müssen mindestens die ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Design-Manager"
            }), "-Berechtigung auswählen, um mit den Design-Tools des Accounts zu interagieren."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Nachdem Sie die Berechtigungen des Schlüssels ausgewählt haben, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Persönlichen Zugriffsschlüssel generieren"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sobald ein Schlüssel generiert wurde, kopieren Sie seinen Wert, indem Sie zunächst auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Anzeigen"
          }), " unter dem Schlüssel und dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Kopieren"
          }), " klicken."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Fügen Sie den Schlüssel in die Befehlszeile ein und drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Eingabetaste"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Geben Sie als Nächstes einen Namen für den Account ein. Dieser Name ist nur für Sie sichtbar und wird nur von Ihnen verwendet, wenn Sie Befehle ausführen. Sie könnten z. B. „sandbox“ verwenden, wenn Sie eine Sandbox für Entwickler nutzen, oder „unternehmen.com“, wenn Sie einen Standard-Account verwenden. Dieser Name darf keine Leerzeichen enthalten."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Drücken Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Eingabetaste"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach dem Abschluss des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "init"
      }), "-Ablaufs sehen Sie eine Erfolgsmeldung, die bestätigt, dass eine Konfigurationsdatei, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: " hubspot.config.yml"
      }), ", in Ihrem aktuellen Verzeichnis erstellt wurde."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " sieht etwa so aus:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: mainProd\nportals:\n  - name: mainProd\n    portalId: 123456\n    defaultMode: publish\n    authType: personalaccesskey\n    auth:\n      tokenInfo:\n        accessToken: >-\n          {accessTokenValue}\n\n\n        expiresAt: '2023-06-27T19:45:58.557Z'\n    personalAccessKey: >-\n      {personalAccessKeyValue}\n\n\n    sandboxAccountType: null\n    parentAccountId: null\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultPortal"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Account, mit dem standardmäßig interagiert wird, wenn CLI-Befehle ausgeführt werden. Um mit einem authentifizierten Account zu interagieren, der nicht als Standard-Account festgelegt ist, können Sie dem Befehl ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account="
            }), "-Flag hinzufügen, gefolgt vom Namen oder der ID des Accounts. Zum Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=12345"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=mainProd"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Unter ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "portals"
            }), " finden Sie für jeden verknüpften Account einen Eintrag. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " gibt den Vornamen für den Account an. Sie können diesen Namen verwenden, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli#set-default-account",
              children: "wenn Sie einen neuen Standard festlegen"
            }), " oder einen Account mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account"
            }), "-Flag angeben."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Account-ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultMode"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt beim Hochladen auf den Account den Standardstatus für das Hochladen von Content fest. Kann entweder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "draft"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish"
            }), " sein."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "authType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Form der Authentifizierung, die zur Authentifizierung des Accounts verwendet wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sandboxAccountType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt, wenn es sich bei dem Account um einen Sandbox-Account handelt, die ID des übergeordneten Produktions-Accounts an."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "parentAccountId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "parentAccountId"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), "-Datei unterstützt mehrere Accounts. Um weitere Accounts zu authentifizieren, führen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs auth"
          })
        }), " aus und folgen Sie den Anweisungen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Eine Ressource erstellen, das Sie in HubSpot abrufen können"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für die Zwecke dieses Tutorials erstellen Sie zunächst ein neues Element in HubSpot, damit Sie es mithilfe des CLI in Ihrer lokalen Umgebung abrufen können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Dateien und Vorlagen"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Design-Manager"
          }), ". Dadurch wird der Design-Manager geöffnet, in dem Sie auf die Dateien zugreifen können, die Sie über das CLI hochladen. Diese Struktur von Dateien und Ordnern wird auch als ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/overview#developer-file-system",
            children: "Entwickler-Dateisystem"
          }), " bezeichnet."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wählen Sie in der linken Seitenleiste des Design-Managers den Ordner ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "@hubspot"
          }), " aus, um die Standardressourcen von HubSpot anzuzeigen, z. B. Designs und Module."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-hubspot-folder.png",
            alt: "design-manager-hubspot-folder"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Scrollen Sie in der linken Seitenleiste nach unten und klicken Sie mit der rechten Maustaste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Symbol"
          }), "-Modul, und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Modul klonen"
          }), " aus. Das Modul wird in den Stamm des Entwickler-Dateisystems geklont und Ihre neue Modulkopie wird auf der rechten Seite geöffnet."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/default-icon-module-cloned.png",
            alt: "default-icon-module-cloned"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie oben in der linken Seitenleiste auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Aktionen"
          }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Pfad kopieren"
          }), " aus. Dadurch wird der relative Pfad zum Modul in das Entwickler-Dateisystem kopiert, den Sie im nächsten Schritt verwenden, um das Modul lokal abzurufen."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-actions-copy-path.png",
            alt: "module-actions-copy-path"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Das Modul in Ihrer lokalen Umgebung abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn das Modul geklont ist, verwenden Sie jetzt den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        })
      }), "-Befehl, um es in Ihre lokale Umgebung zu holen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Führen Sie im Terminal ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch '/icon copy.module'"
      }), " aus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch '/icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Modul wird als Verzeichnis heruntergeladen, das fünf Dateien enthält:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "fields.json"
          }), ": enthält den Code für die verschiedenen Felder des Moduls. In diesem Fall umfasst dies das Symbolfeld, zwei Felder für Barrierefreiheitoptionen und eine Reihe von Stilfeldern. Sie sehen diese Felder in der rechten Seitenleiste des Modul-Editors in HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), ": enthält die grundlegenden Informationen des Moduls, z. B. sein Label, seine ID und die Arten von Vorlagen, in denen es verwendet werden kann. Diese Informationen werden in der rechten Seitenleiste des Modul-Editors angezeigt."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.css"
          }), ": enthält das CSS des Moduls, das Sie auch im CSS-Fenster des Modul-Editors in HubSpot sehen können."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.html"
          }), ": enthält den HTML-Code des Moduls, den Sie auch im „HubL + HTML“-Bereich des Modul-Editors in HubSpot sehen können."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.js"
          }), ": enthält das JavaScript des Moduls, das Sie auch im JS-Fenster des Modul-Editors in HubSpot sehen können."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-directory-local.png",
            alt: "module-directory-local"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aktualisieren Sie als Nächstes die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "-Datei des Moduls, laden sie dann in Ihren Account hoch und sehen sich die Änderung in HubSpot an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Änderungen vornehmen und hochladen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nehmen Sie zunächst eine Änderung am Modul vor:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Öffnen Sie in Ihrem bevorzugten Code-Editor die ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), "-Datei des Moduls."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-open.png",
            alt: "module-meta-json-file-open"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Aktualisieren Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "label"
          }), "-Feld von ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"Icon\""
          }), " zu ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"CMS tutorial module\""
          }), ". Speichern Sie anschließend die Datei."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-label-updated.png",
            alt: "module-meta-json-file-label-updated"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bevor wir den nächsten Befehl, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ", ausführen, lassen Sie uns den Befehl und die dazu erforderlichen Argumente aufschlüsseln. Dieser Befehl benötigt zwei Argumente: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload <src> <dest>"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), ": der relative Pfad des Quellenordners, den Sie aus Ihrer lokalen Umgebung hochladen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dest"
        }), ": der Pfad des Zielverzeichnisses in HubSpot, lokal zum Stamm des Entwickler-Dateisystems. Sie können ein neues Verzeichnis im Account erstellen, indem Sie den Verzeichnisnamen angeben, z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload <src> /new-directory"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Da Sie in das Stammverzeichnis des Dateisystems des Entwicklers hochladen, führen Sie daher also den folgenden Befehl aus:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs upload 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nachdem das CLI bestätigt hat, dass das Modul erfolgreich hochgeladen wurde, laden Sie den Design-Manager neu, um Ihre Änderung in HubSpot anzuzeigen. Sie sollten nun sehen, dass das ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Label"
        }), "-Feld Ihren neuen Wert anzeigt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-label-updated.png",
        alt: "module-label-updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Mithilfe eines watch-Befehls Änderungen automatisch hochladen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie nun den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), "-Befehl verwendet haben, um einen einmaligen Upload Ihrer lokalen Dateien auszuführen, verwenden Sie nun den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "-Befehl, um gespeicherte Änderungen kontinuierlich hochzuladen. Dieser Befehl verwendet dieselben Argumente wie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), ", sodass Sie dieselben ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<src>"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dest>"
      }), "-Argumente wie oben angeben können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch 'icon copy.module' 'icon copy.module'"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs watch 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei jetzt ausgeführtem watch-Befehl werden gespeicherte Änderungen automatisch in den HubSpot-Account hochgeladen. Nehmen Sie zur Demonstration die folgende lokale Änderung am Modul vor:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aktualisieren Sie in der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "meta.json"
        }), "-Datei das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "host_template_types"
        }), "-Feld, um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_LISTING\""
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_POST\""
        }), " zu entfernen, damit das Modul nur für Seiten verfügbar ist: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"host_template_types\"``:[\"PAGE\"]"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Speichern Sie anschließend die Datei. Dadurch sollte das CLI aufgefordert werden, die Datei automatisch nach HubSpot hochzuladen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Laden Sie nach dem Hochladen der Datei den Design-Manager in HubSpot neu, um Ihre Änderung anzuzeigen. Der Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Vorlagentypen"
        }), " der rechten Seitenleiste sollte jetzt nur noch den Typ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Seite"
        }), " enthalten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-editor-template-type.png",
        alt: "module-editor-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um den watch-Befehl zu beenden, drücken Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Control + C"
        }), ". Beachten Sie unbedingt, dass Sie keine anderen Befehle im selben Terminalfenster ausführen können, in dem der watch-Befehl ausgeführt wird Um andere Befehle während der Ausführung eines watch-Befehls auszuführen, sollten Sie stattdessen ein anderes Terminalfenster öffnen und Ihre Befehle dort ausführen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nächste Schritte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie sich nun angesehen haben, wie Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetch"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "-Befehle nutzen, sollten Sie sich den vollständigen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "Referenzleitfaden zu CLI-Befehlen"
      }), " ansehen, um zu erfahren, was Sie sonst noch mit dem CLI tun können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es wird auch empfohlen, sich die folgenden Tutorials anzusehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Erstellen eines effizienten Entwicklungsworkflows"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Eine kontinuierliche Integration mit GitHub einrichten"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Erste Schritte mit benutzerdefinierten Modulen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Erste Schritte mit Designs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Erste Schritte mit Drag-&-Drop-Bereichen"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}