"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377977;
const slug = exports.slug = 'guides/api/conversations/working-with-chatbots/webhooks';
const title = exports.title = 'Conversaciones | Webhooks en bots';
const name = exports.name = 'vNext Docs DP - Working with webhooks from bots_ES';
const metaDescription = exports.metaDescription = 'Detalles de las solicitudes utilizadas por los pasos de webhook para chatbots.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h5: "h5",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Trabajar con webhooks desde bots"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/articles/kcs_article/conversations/create-a-bot",
        children: "crear o editar un bot"
      }), ", puedes agregar un webhook haciendo clic en «+» para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/articles/kcs_article/conversations/a-guide-to-bot-actions",
        children: "agregar una acción"
      }), " como normalmente lo harías. En el panel de selección de acción, haz clic en «Activar un webhook»", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/KB%20Team/trigger-a-webhook.png?width=334&name=trigger-a-webhook.png",
        alt: "trigger-a-webhook"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, asígnale un apodo a tu acción e introduce la URL del punto final para el webhook. Si tu webhook enviará datos HubSpot en respuesta a la solicitud, revisa la casilla «Esperar los comentarios del webhook». (Lee más sobre esto a continuación) Guarda tu acción. ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hub/2832391/hubfs/Settings/Website/Blog/trigger-a-webhook-editor.png?width=469&name=trigger-a-webhook-editor.png",
        alt: "trigger-a-webhook-editor"
      }), "Cuando se alcanzó esta acción en una conversación, HubSpot enviará una carga JSON a la ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "URL de webhook"
      }), " que hayas definido. La carga contendrá información relevante para la sesión de chat, incluidas las respuestas de los visitantes a cualquier pregunta, su ID de contacto e información sobre el bot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ejemplo de carga de solicitud:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//sample payload\n{\n  \"userMessage\": {\n  // Details for the last message sent to your bot\n    \"message\": \"100-500\",\n    // The last message received by your bot, sent by the visitor\n    \"quickReply\": {\n    // If the visitor selected any quick reply options, this will be a list of the selected options.\n    // Will be 'null' if no options were selected.\n      \"quickReplies\":[\n      // A list of quick reply options selected by the visitor\n         {\n            \"value\":\"100-500\",\n            \"label\":\"100-500\"\n         }\n      ],\n  },\n  \"session\": {\n    \"vid\": 12345,\n    // The contact VID of the visitor, if known.\n    \"properties\": {\n    // A list of properties collected by the bot in the current session.\n      \"CONTACT\": {\n        \"firstname\": {\n          \"value\": \"John\",\n          \"syncedAt\": 1534362540592\n        },\n        \"email\": {\n          \"value\": \"testing@domain.com\",\n          \"syncedAt\": 1534362541764\n        },\n        \"lastname\": {\n          \"value\": \"Smith\",\n          \"syncedAt\": 1534362540592\n        }\n      }\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los usuarios avanzados también tienen la opción de incluir JSON en la respuesta de tu webhook. Al hacerlo, puedes afectar el flujo de conversación o enviar un mensaje personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ejemplo de carga de respuesta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//sample payload\n{\n  \"botMessage\": null,\n  // This is the message your bot will display to the visitor.\n  \"nextModuleNickname\": \"PromptForCollectUserInput\",\n  // If defined, this will be the next module your bot will go to. If undefined, the default configured behavior will be observed.\n  \"responseExpected\": false\n  // If true, the webhook will be triggered again with the visitor's next reply. If false, the default configured behavior will be observed.\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}