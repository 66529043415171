"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35219164725;
const slug = exports.slug = 'guides/cms/content/data-driven-content/emails-with-programmable-content';
const title = exports.title = 'Crear correos electrónicos con contenido programable';
const name = exports.name = 'Crear correos electrónicos con contenido programable';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "l%C3%ADmites-de-env%C3%ADo-de-correo-electr%C3%B3nico",
  "label": "Límites de envío de correo electrónico",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-crear-un-m%C3%B3dulo-de-correo-electr%C3%B3nico-programable",
  "label": "1. Crear un módulo de correo electrónico programable",
  "parent": null
}, {
  "depth": 1,
  "id": "objetos-est%C3%A1ndar",
  "label": "Objetos estándar",
  "parent": "1.-crear-un-m%C3%B3dulo-de-correo-electr%C3%B3nico-programable"
}, {
  "depth": 1,
  "id": "objetos-personalizados",
  "label": "Objetos personalizados",
  "parent": "1.-crear-un-m%C3%B3dulo-de-correo-electr%C3%B3nico-programable"
}, {
  "depth": 1,
  "id": "hubdb",
  "label": "HubDB",
  "parent": "1.-crear-un-m%C3%B3dulo-de-correo-electr%C3%B3nico-programable"
}, {
  "depth": 0,
  "id": "2.-agregar-el-m%C3%B3dulo-a-un-correo-electr%C3%B3nico",
  "label": "2. Agregar el módulo a un correo electrónico",
  "parent": null
}, {
  "depth": 0,
  "id": "m%C3%A1s-tutoriales-enfocados-en-hubdb",
  "label": "Más tutoriales enfocados en HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-academy",
  "label": "HubSpot Academy",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Crear correos electrónicos con contenido programable"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Esta característica actualmente está en versión beta. Al usar esta funcionalidad, aceptas los términos de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "versión beta para desarrolladores"
        }), ". Esta guía hace referencia a las funciones disponibles solo a través de esa versión beta. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "Suscríbete a la versión beta en tus plantillas o módulos."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uso de contenido programable para personalizar correos electrónicos con datos de tu cuenta de HubSpot usando HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las fuentes de datos que puedes usar en un correo electrónico programable dependen de tu suscripción a HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si tienes una suscripción a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Pro"
        }), ", puedes utilizar datos de objetos estándar del CRM, como contactos, empresas y productos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si tienes una suscripción a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ", también puedes utilizar fuentes de datos estructuradas como tablas de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDB"
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "objetos personalizados"
        }), ". Estos datos se pueden filtrar en función de las propiedades de contacto del destinatario."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, en un sitio web inmobiliario, los prospectos podrían completar un formulario con sus necesidades de vivienda. El envío de formularios podría desencadenar un workflow que le envíe al prospecto un correo electrónico con casas en las que pueda estar interesado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites de envío de correo electrónico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes incluir las funciones de HubL del CRM ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " en un correo electrónico programable, pero cualquier correo que incluya estas funciones específicas está sujeto a los siguientes límites en función del número de destinatarios a los que se lo estés enviando:"]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Total de destinatarios"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Número máximo de funciones de HubL del CRM"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "500.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "250.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "165.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "125.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "100.000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "5"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El envío de un correo electrónico que cumple o excede uno de los límites anteriores retrasará o cancelará el envío de tu correo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además de los límites descritos anteriormente, ten en cuenta las advertencias adicionales que se indican a continuación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si clonas un correo electrónico programable, no se podrá enviar mientras el original aún esté en estado de procesamiento. Debes esperar al menos 1 hora entre cada envío de correo electrónico."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No puedes realizar una prueba A/B para un correo electrónico programable que incluya una función de HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Crear un módulo de correo electrónico programable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear el módulo de correo electrónico para acceder a tus datos de HubDB u objeto personalizado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de HubSpot, navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Archivos y plantillas"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Herramientas de diseño"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la parte superior izquierda, haz clic en ** Archivo ** y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Nuevo archivo"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el cuadro de diálogo, selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Módulo"
          }), " para el tipo de archivo y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Siguiente"
          }), ". Luego, selecciona la casilla de verificación ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Correos electrónicos"
          }), " e introduce un ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre"
          }), " para el archivo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Crear"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Para habilitar el correo electrónico programable para el módulo:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En el inspector de la derecha, activa el interruptor ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Activar módulo para correo electrónico programable beta"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/programmable-email-switch0.png",
            alt: "programmable-email-switch0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["También puedes habilitar el correo electrónico programable para una plantilla de correo electrónico codificada agregando ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "isEnabledForEmailV3Rendering: true"
          }), " en la parte superior del archivo."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/enable-design-manager-template-for-programmable-email.png?width=510&name=enable-design-manager-template-for-programmable-email.png",
            alt: "enable-design-manager-template-for-programmable-email"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con el módulo creado, agregarás entonces el código para acceder a los datos desde tu CRM. En los ejemplos siguientes se muestra cómo consultar desde diferentes fuentes de datos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objetos estándar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes utilizar las funciones de HubL como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " para consultar datos de objetos estándar en tu cuenta, como contactos, empresas o productos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente código utiliza la función de HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " para consultar los datos de un producto con un ID de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2444498793"
      }), " y representar el nombre, la descripción y el precio:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set product = crm_object(\"product\", 2444498793, \"name,description,price\") %}\n\n<div>\n  <p>\n    <span>Name: </span>\n    <span>{{ product.name }}</span>\n  </p>\n  <p>\n    <span>Description: </span>\n    <span>{{ product.description }}</span>\n  </p>\n  <p>\n    <span>Price: </span>\n    <span>{{ product.price }}</span>\n  </p>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objetos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes una cuenta de ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), ", puedes consultar datos de un objeto personalizado que hayas creado en tu cuenta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente código recupera datos de un objeto personalizado llamado _ Propiedad_, devolviendo valores (por ejemplo, ubicación, precio) almacenados en las propiedades del objeto personalizado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ten en cuenta que el siguiente ejemplo utiliza el nombre totalmente cualificado del objeto personalizado como primer argumento cuando se invoca la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "función de HubL"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_objects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El nombre totalmente cualificado comienza con el ID de la cuenta de HubSpot (precedido por ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "), seguido de un guion bajo y el nombre en plural en minúsculas del objeto personalizado (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Puedes recuperar un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " de un objeto haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "API del esquema de objetos del CRM"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = crm_objects(\"p2990812_properties\", \"\", \"listing_name,location, price, address, type\") %}\n\n{% for home in real_estate_listings.results %}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si el nombre de tu objeto personalizado contiene guiones (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Mi-Objeto-Personalizado"
        }), "), sus propiedades ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " se pueden mostrar en un correo electrónico programable. Puedes recrear el objeto personalizado con los guiones omitidos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-custom-objects",
          children: "directamente en tu cuenta de HubSpot"
        }), " o puedes usar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "API de objeto personalizado"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para filtrar los datos devueltos para cada destinatario, puedes agregar un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), ", el cual filtrará los resultados por las propiedades de contacto del destinatario. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "lista completa de opciones de filtros"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&city=\"~contact.city\"&order=listing_name\" %}\n{% set real_estate_listings = crm_objects(\"p2990812_Property\", query, \"listing_name,location, price, address, type\") %}\n{% for home in real_estate_listings.results %}\n\n  ...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes una cuenta de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", puedes usar datos de una tabla de HubDB en tu correo electrónico."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente código utiliza la función de HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows"
        })
      }), " para recuperar todos los datos de la tabla. Esto enumerará todas las propiedades inmobiliarias en el correo electrónico, mostrando los detalles de cada propiedad junto con su imagen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = hubdb_table_rows(1234567) %}\n\n{% for home in real_estate_listings%}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image.url }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para filtrar los datos devueltos para cada destinatario, puedes agregar un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), ", que filtrará los resultados por las propiedades de contacto especificadas. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb#filter-returned-rows",
        children: "lista completa de opciones de filtros"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&persona=\"~contact.hs_persona.value~\"&order=listing_name\" %}\n\n{% for home in real_estate_listings %}\n\n\t...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En el ejemplo anterior, se hace referencia a la propiedad de contacto ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Presupuesto máximo"
        }), " con ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.budget_max"
        }), ", mientras que a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Buyer persona"
        }), " se hace referencia con ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.hs_persona.value"
        }), ". Esto se debe a que ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Buyer persona"
        }), " es una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/property-field-types-in-hubspot",
          children: "propiedad de enumeración"
        }), ", que requiere un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".value"
        }), " adicional para analizar el valor de la propiedad, mientras que otros tipos de propiedades no lo hacen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Agregar el módulo a un correo electrónico"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con el módulo publicado, ahora lo agregarás al cuerpo del correo electrónico de arrastrar y soltar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tu cuenta de HubSpot, navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketing"
      }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Correo electrónico"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "correo electrónico"
        }), " que creaste."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda, en ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contenido"
        }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Más"
        }), ". Encuentra tu módulo de correo electrónico programable y luego arrástralo hasta el cuerpo del correo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/drag-and-drop-email-editor-more-modules.png",
        alt: "drag-and-drop-email-editor-more-modules"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si configuraste el módulo para filtrar datos por propiedades de contacto específicas, la vista previa del correo electrónico aparecerá en blanco. Esto se debe a que la herramienta de correo electrónico no se ha configurado para obtener una vista previa del correo por un contacto específico."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para obtener una vista previa de cómo será el correo electrónico para un contacto específico:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acciones"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista previa"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/email-actions-menu0.png",
        alt: "email-actions-menu0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la pantalla siguiente, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar como un contacto específico"
        }), " y luego selecciona un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "contacto"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/preview-email-as-specific-contact0.png",
        alt: "preview-email-as-specific-contact0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ahora deberías ver solo los datos relevantes para el destinatario, filtrados por sus propiedades de contacto."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/preview%20window.png",
        alt: "Captura de pantalla de la vista previa del correo electrónico "
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si una plantilla o módulo de correo electrónico contiene código personalizado con HubL no resuelto, aparecerá un módulo de marcador de posición en su lugar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/placeholder-module-in-programmable-email.png",
        alt: "placeholder-module-in-programmable-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Es importante tener siempre datos de reserva para enviar en el caso de que no haya filas de HubDB o registros de objetos personalizados que cumplan con los criterios que estableciste. De lo contrario, el destinatario podría recibir un correo electrónico en blanco."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Esta versión beta puede causar problemas con las plantillas existentes. Es importante probar los cambios exhaustivamente antes de publicar y enviar un correo electrónico en directo."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Más tutoriales enfocados en HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Desarrollo de páginas dinámicas con HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Cómo unir varias tablas de HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Cómo crear una página dinámica para miembros del equipo con HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Cómo crear páginas dinámicas multinivel usando HubDB"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/other_data_sources_hubdb_and_custom_objects",
          children: "Uso de HubDB y objetos personalizados en CMS Hub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}