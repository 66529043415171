"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501958309;
const slug = exports.slug = 'guides/api/marketing/subscriptions-preferences';
const title = exports.title = "API marketing | Préférences d'abonnement";
const name = exports.name = 'EMEA (FR) vNext Docs DP - Subscriptions Preferences';
const metaDescription = exports.metaDescription = " Les points de terminaison des préférences d'abonnement vous permettent de gérer les détails des abonnements pour les contacts dans un portail HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "obtenir-le-statut-d-abonnement-d-un-contact",
  "label": "Obtenir le statut d'abonnement d'un contact",
  "parent": null
}, {
  "depth": 0,
  "id": "abonner-un-contact",
  "label": "Abonner un contact",
  "parent": null
}, {
  "depth": 0,
  "id": "d%C3%A9sabonner-un-contact",
  "label": "Désabonner un contact",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenir-des-types-d-abonnement",
  "label": "Obtenir des types d'abonnement",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Préférences d'abonnement"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les types d'abonnements représentent la base juridique qui vous autorise à communiquer avec vos contacts par e-mail.Les contacts peuvent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/articles/kcs_article/email/how-to-send-contacts-an-email-with-an-opt-out-link-so-they-can-manage-their-preferences",
        children: "gérer leurs préférences relatives aux e-mails"
      }), " de façon à recevoir uniquement les communications qu'ils désirent."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " l'API des préférences d'abonnement ne prend actuellement ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " en charge la récupération ou la mise à jour des informations relatives à l'abonnement ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/fr/inbox/collect-consent-for-whatsapp-conversations",
          children: "WhatsApp"
        }), " d'un contact."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir le statut d'abonnement d'un contact"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le point de terminaison de statut d'abonnement de contact permet aux utilisateurs de récupérer les statuts d'abonnement pour une adresse e-mail dans un compte."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce point de terminaison est idéal lorsque vous disposez d'un centre de préférences ou d'une intégration externe et que vous devez connaître les statuts d'abonnement pour une adresse e-mail dans votre compte."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abonner un contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le point de terminaison d'abonnement vous permet d'abonner une adresse e-mail à un type d'abonnement donné dans un compte, mais ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ne vous permettra pas de réabonner des contacts qui se sont désabonnés."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de cas d'utilisation :"
      }), " Ce point de terminaison est idéal lorsque vous disposez d'une intégration ou d'un formulaire externe qui nécessite d'abonner des contacts à un type d'abonnement."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarque"
      }), " : Le point de terminaison d'abonnement doit être utilisé uniquement pour répondre aux demandes des contacts qui vous ont autorisé à les abonner. Veuillez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/contacts/how-do-subscription-preferences-and-types-work",
        children: "consulter les lois et réglementations en vigueur"
      }), " avant d'abonner un contact."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Désabonner un contact"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le point de terminaison de désabonnement vous permet de désabonner une adresse e-mail dans un compte de tout type d'abonnement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de cas d'utilisation :"
      }), " Ce point de terminaison est idéal lorsque vous disposez d'une intégration ou d'un formulaire externe qui nécessite de désabonner des contacts d'un type d'abonnement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir des types d'abonnement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le point de terminaison d'informations sur les abonnements permet aux utilisateurs de récupérer tous les types d'abonnement dans leur compte."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de cas d'utilisation :"
      }), " Ce point de terminaison est idéal si vous disposez d'un centre de préférences, d'une intégration ou d'un formulaire externe et que vous devez connaître les types d'abonnement qui existent dans votre compte afin que vous puissiez mettre à jour en conséquence les statuts d'abonnement pour vos contacts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {})]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}