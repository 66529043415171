"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817161744;
const slug = exports.slug = 'reference/api/analytics-and-events/tracking-code';
const title = exports.title = 'Visão geral da API de código de rastreamento';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Tracking code';
const metaDescription = exports.metaDescription = 'O endpoint de Análise da Web permite localizar e filtrar eventos associados a um objeto de CRM de qualquer tipo. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "identifica%C3%A7%C3%A3o-de-contatos",
  "label": "Identificação de contatos",
  "parent": null
}, {
  "depth": 0,
  "id": "identificar-um-visitante",
  "label": "Identificar um visitante",
  "parent": null
}, {
  "depth": 0,
  "id": "rastreamento-em-aplicativos-de-p%C3%A1gina-%C3%BAnica",
  "label": "Rastreamento em aplicativos de página única",
  "parent": null
}, {
  "depth": 1,
  "id": "definir-o-caminho-da-p%C3%A1gina",
  "label": "Definir o caminho da página",
  "parent": "rastreamento-em-aplicativos-de-p%C3%A1gina-%C3%BAnica"
}, {
  "depth": 1,
  "id": "rastrear-a-visualiza%C3%A7%C3%A3o-da-p%C3%A1gina",
  "label": "Rastrear a visualização da página",
  "parent": "rastreamento-em-aplicativos-de-p%C3%A1gina-%C3%BAnica"
}, {
  "depth": 0,
  "id": "pol%C3%ADtica-de-privacidade-no-rastreamento",
  "label": "Política de privacidade no rastreamento",
  "parent": null
}, {
  "depth": 0,
  "id": "obter-par%C3%A2metros-de-vincula%C3%A7%C3%A3o-entre-dom%C3%ADnios",
  "label": "Obter parâmetros de vinculação entre domínios",
  "parent": null
}, {
  "depth": 0,
  "id": "reaplicar-handlers-de-eventos-de-an%C3%A1lise",
  "label": "Reaplicar handlers de eventos de análise",
  "parent": null
}, {
  "depth": 0,
  "id": "rastreamento-de-eventos-comportamentais-personalizados-somente-marketing-hub-enterprise",
  "label": "Rastreamento de eventos comportamentais personalizados (somente Marketing Hub Enterprise)",
  "parent": null
}, {
  "depth": 1,
  "id": "trackcustombehavioralevent",
  "label": "trackCustomBehavioralEvent",
  "parent": "rastreamento-de-eventos-comportamentais-personalizados-somente-marketing-hub-enterprise"
}, {
  "depth": 1,
  "id": "personalize-o-c%C3%B3digo-de-rastreamento-para-enviar-dados-de-eventos-comportamentais-personalizados",
  "label": "Personalize o código de rastreamento para enviar dados de eventos comportamentais personalizados",
  "parent": "rastreamento-de-eventos-comportamentais-personalizados-somente-marketing-hub-enterprise"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral da API de código de rastreamento"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Esta página foi atualizada para usar novos eventos comportamentais personalizados. Para eventos personalizados antigos, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code/v1",
          children: "consulte a documentação antiga"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além de rastrear as visualizações de página, o código de rastreamento do HubSpot permite identificar visitantes, rastrear eventos e rastrear manualmente visualizações de página sem recarregar a página. A API do código de rastreamento permite criar eventos e rastrear dados de eventos dinamicamente no HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se o seu site usa o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "banner de consentimento de privacidade"
      }), ", saiba como gerenciar os cookies adicionados ao navegador de um visitante com a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "API de banner de cookies"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As chamadas de função são enviadas para o array ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), ". Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', { path string }])\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neste artigo, saiba como adicionar funções ao código de rastreamento para identificar visitantes, rastrear visualizações de página, enviar dados de eventos e muito mais."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identificação de contatos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A ferramenta de análise da HubSpot identifica os contatos usando dois segmentos de dados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O usertoken, que é armazenado no cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspotutk"
        }), " do navegador do visitante."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O endereço de e-mail do contato."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando o código de rastreamento do HubSpot rastrear a ação de um visitante, como uma exibição de página, ele associará automaticamente essa ação ao usertoken do visitante. Quando você usar a API de código de rastreamento para identificar um visitante por endereço de e-mail, o sistema de análise de dados vinculará esse e-mail ao usertoken, permitindo que a HubSpot atualize um registro de contato existente ou crie um novo. Os dados de análise associados ao usertoken, como exibições de página e fonte original, serão exibidos no registro do contato."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando você sabe a identidade de um visitante (por exemplo, endereço de e-mail), pode usar a função de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identify-a-visitor",
        children: "identificação"
      }), " para definir identidades no rastreador. Você pode enviar a identidade para o HubSpot fazendo uma chamada separada de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events-js-api",
        children: "trackCustomBehavioralEvent"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lembre-se do seguinte ao usar essa função:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Evite usar um e-mail de espaço reservado para visitantes desconhecidos, pois o HubSpot criará um registro de contato com esse e-mail, e o cookie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "usertoken"
        }), " do visitante será associado a esse registro. Assim, todos os visitantes desconhecidos serão associados ao seu contato de espaço reservado."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você definir uma identidade para um registro de contato e tiver propriedades exclusivas, removeremos todas as propriedades da identidade que violem a exclusividade."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identificar um visitante"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se sua conta foi criada antes de 8 de setembro de 2021 e está configurada para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/prevent-contact-properties-update-through-tracking-code-api",
          children: "permitir que propriedades de contato sejam atualizadas por meio do código de rastreamento"
        }), ", você também poderá incluir outras propriedades de contato a serem atualizadas com essa função. Essa funcionalidade está desativada para contas criadas após 8 de setembro de 2021."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"identify\", { {identity details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use esse endpoint para identificar os visitantes e os contatos do site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visitor:"
        }), " refere-se a qualquer visitante do site, independentemente de ser ou não um contato no HubSpot. O HubSpot não cria registros para os visitantes como o faz para os contatos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contact:"
        }), " refere-se a um visitante ou contato offline que tem um registro no HubSpot. Um contato pode ser identificado pelo seu endereço de e-mail exclusivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para identificar manualmente um visitante ou contato, você pode usar um endereço de e-mail ou um ID externo exclusivo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "email:"
        }), " identifique um visitante pelo endereço de e-mail quando você quiser atualizar um contato existente ou criar um novo. Se existir um contato com esse e-mail no HubSpot, o registro de contato será atualizado com os dados de análise. Se não existir nenhum contato com esse endereço de e-mail, um novo registro será criado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "id:"
        }), " um ID externo personalizado que identifica o visitante. A identificação de um visitante com este ID associará os dados de análise a esse visitante. No entanto, somente usar o ID não criará um contato no HubSpot. Os dados de análise somente podem ser associados a um contato existente por meio deste método quando:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "o visitante foi previamente identificado pelo ID e pelo e-mail."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "o visitante foi previamente identificado pelo ID e também possui um envio de formulário associado ao seu registro."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " esse ID externo somente poderá ser usado com o código de rastreamento do HubSpot. Esse ID não pode ser usado para obter ou atualizar registros usando qualquer outra ferramenta ou API do HubSpot. Se você souber o endereço de e-mail do visitante, é recomendado usá-lo como identificador exclusivo. Da mesma forma, você somente deve identificar um visitante por ID quando não souber o endereço de e-mail dele."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Se você já enviou dados de análise para o HubSpot usando apenas o ID do visitante, poderá incluir posteriormente o ID e um endereço de e-mail para associar os dados desse ID a um contato. O contato existente será atualizado ou criado se não houver nenhum contato no momento."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nThe below example gets the value of a query string parameter '?email='\nand uses that to identify the visitor\n*/\n\nfunction getParameterByName(name) {\n  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);\n  return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));\n}\n\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n  },\n]);\n\n/*\nThe below example sets the email,\nas well as a custom property favorite_color\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n    favorite_color: 'orange',\n  },\n]);\n\n/*\nThe below example sets the email and a custom external ID for the visitor. \nThis assumes that your site includes a variable named \n'user' that includes that user's ID in an 'id' \nproperty, and their email in an 'email' property.\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: user.email,\n    id: user.id,\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lembre-se das seguintes condições ao usar essa função:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Essa função armazena os dados no rastreador, mas os dados não são transmitidos de fato para o HubSpot com essa chamada. Os dados só serão transmitidos durante o rastreamento de uma exibição de página ou de um evento (com as funções ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#track-page-view",
          children: "trackPageView"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackEvent"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um contato somente pode ter um ID e/ou endereço de e-mail associado a ele. Se você tentar atribuir dois IDs a um e-mail, apenas o primeiro será associado ao endereço de e-mail."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para vincular os dados a um contato, inclua um endereço de e-mail."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se a sua conta foi criada antes de 8 de setembro de 2021 e está configurada para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "permitir que as propriedades de contato sejam atualizadas por meio do código de rastreamento"
        }), ", você também pode incluir outras propriedades de contato a serem atualizadas com esta função."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Esta função ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "não"
        }), " restaurará contatos excluídos anteriormente. Esses contatos devem ser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/restore-deleted-contacts-companies-deals-or-tickets",
          children: "restaurados no HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rastreamento em aplicativos de página única"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O código de rastreamento do HubSpot registrará automaticamente uma visualização de página quando o código for carregado pela primeira vez, mas você também pode rastrear manualmente visualizações de página em um aplicativo de página única sem recarregar o código de rastreamento. Você pode usar as funções ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-path",
        children: "setPath"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " para atualizar e rastrear a página atual. Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Set up the path for the initial page view -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/home']);\n</script>\n\n<!-- Load the HubSpot tracking code -->\n<!-- Start of HubSpot Embed Code -->\n  <script type=\"text/javascript\" id=\"hs-script-loader\" async defer src=\"//js.hs-scripts.com/{hubId}.js\">\n</script>\n<!-- End of HubSpot Embed Code -->\n\n<!-- Tracking subsequent page views -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/about-us']);\n  _hsq.push(['trackPageView']);\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir o caminho da página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsq.push(['setPath', { path string }])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Atualizar o caminho da página atual armazenado no rastreador. Esta função deve ser usada pelos aplicativos de página única para atualizar a página atual sempre que uma página for carregada. Após usar essa função para atualizar o caminho, você precisará chamar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "função trackPageView"
      }), " para rastrear a exibição da página atual."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os aplicativos de página única devem enviar uma chamada", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " antes que o código de rastreamento seja carregado para definir a URL que será rastreada para a primeira exibição de página. Consulte a seção de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "exibição da página de rastreamento"
      }), " abaixo para ver um exemplo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao chamar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), ", você incluirá o caminho da página atual. O caminho definido será tratado como relativo ao domínio atual que está sendo visualizado. O caminho sempre deve ser precedido de uma barra. Se o URL também contiver parâmetros, eles também deverão ser incluídos no caminho. Confira o código acima para ver exemplos."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Lembre-se das seguintes condições ao usar essa função:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Qualquer caminho definido usando a função ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " substituirá os dados no cabeçalho do referenciador. Se você chamar ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " uma vez, precisará usar ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " para atualizar o caminho de cada visualização que deseja rastrear."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Chamar ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " repetidamente substituirá o referenciador, o que pode afetar a fonte original do contato, dependendo de quando uma solicitação de rastreamento é feita."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Esta função só pode atualizar o caminho da URL. O domínio é definido automaticamente com base na URL da página que está sendo carregada. O caminho definido usando essa função sempre é tratado como relativo a esse domínio detectado."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// These examples assume that the domain of the site is\n// www.mydomain.com\n\n// Set the path to '/' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/\n\n// Set the path to '/contact-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/contact-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/contact-us\n\n// Set the path to '/blog/post?utm_campaign=my-campaign' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/blog/post?utm_campaign=my-campaign']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/blog/post?utm_campaign=my-campaign\n\n// Set the path to '/#/about-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/about-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/#/about-us\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rastrear a visualização da página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['trackPageView']);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rastreie a exibição de página para a página atual. Esta função é chamada automaticamente quando o código de rastreamento é carregado em uma página, mas você pode chamar esta função manualmente para rastrear visualizações subsequentes em um aplicativo de página única."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " chamar essa função manualmente antes ou durante o carregamento da página inicial pode fazer com que visualizações duplicadas sejam rastreadas."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta função não é compatível com argumentos. O título rastreado será o valor atual de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.title"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A URL rastreada baseia-se em um dos seguintes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O caminho definido usando a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "função setPath"
        }), ". Se o site tiver sido criado como um aplicativo de página única, esta função será o método preferido para definir o caminho rastreado. Confira a seção ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " acima para ver as isenções de responsabilidade sobre a função."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se a função ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " nunca tiver sido chamada, a URL rastreada será o cabeçalho do HTTP do referenciador na solicitação feita pelo navegador do visitante para os servidores de rastreamento da HubSpot. (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/API/History_API#Adding_and_modifying_history_entries",
          children: "A modificação do estado do histórico do navegador"
        }), " atualizará o valor usado para esse cabeçalho). O uso de cabeçalhos de referenciador não permitirá fragmentos de URL (qualquer elemento após # na URL), pois os fragmentos não são incluídos nesses cabeçalhos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// Track a new page using setPath:\n// Update the path stored in the tracker:\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/new-page']);\n// Track the page view for the new page\n_hsq.push(['trackPageView']);\n\n\n// Track a new page by updating the browser state:\n// Update the browser state, showing \"updated.html\" in the browser address bar\nvar stateObj = { foo: 'updated' };\nhistory.pushState(stateObj, \"updated page\", \"updated.html\");\n//Track the page view for the new page, '/updated.html'\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['trackPageView']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lembre-se das seguintes condições ao usar essa função:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Embora não seja possível impedir que essa função seja chamada automaticamente quando o código de rastreamento for carregado, você pode controlar a URL registrada para a página enviando uma chamada ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "_hsq"
        }), " antes de o código de rastreamento ser carregado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se o site for um aplicativo de página única, evite incluir a tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), " em sua página. Se sua página usar tags ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), ", você precisará usar a função ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " para atualizar o caminho das novas páginas, pois a URL canônica definida com base no link substituirá qualquer URL detectada se você estiver atualizando apenas o estado do histórico do navegador."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Política de privacidade no rastreamento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se o seu site tiver um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "banner de consentimento de privacidade,"
      }), " você poderá usar funções para verificar e gerenciar os cookies adicionados ao navegador do visitante. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "gerenciar os cookies de banner de consentimento de privacidade"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obter parâmetros de vinculação entre domínios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['addIdentityListener', function(hstc, hssc, hsfp) {}])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O código de rastreamento do HubSpot pode ser usado em vários sites com domínios separados. Como essa função, você pode obter os parâmetros de consulta obrigatórios para criar links que permitirão rastrear os visitantes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/set-up-your-site-domains#track-multiple-domains-with-cross-domain-linking",
        children: "nesses domínios separados"
      }), ". Esses parâmetros de consulta são usados pelo código de rastreamento do HubSpot para identificar um visitante entre os domínios, garantindo que os cookies separados para os domínios separados sejam mesclados para um único visitante rastreado. Você também pode usar parâmetros de consulta entre domínios em links que são adicionados dinamicamente à página depois que o código de rastreamento é carregado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os links entre domínios são necessários somente durante a vinculação a um domínio distinto (por exemplo, domain-one*.com* e ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "domain-two.com"
      }), ") que também está sendo rastreado para uma única conta da HubSpot. Você não precisa de parâmetros de link entre domínios ao rastrear visitas entre subdomínios (por exemplo, ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://www.domain-one.com",
          children: "www.domain-one.com"
        })
      }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "blog.domain-one.com"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Get the cross-domain query parameters and store them in a string,\n//so that they can be appended to links as needed.\n_hsq.push([\n  'addIdentityListener',\n  function (hstc, hssc, hsfp) {\n    // Add these query parameters to any links that point to a separate tracked domain\n    crossDomainTrackingParams =\n      '&__hstc=' + hstc + '&__hssc=' + hssc + '&__hsfp=' + hsfp;\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reaplicar handlers de eventos de análise"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['refreshPageHandlers'])"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta função reaplica quaisquer handlers de eventos de análise que estejam definidos nas configurações de análise da conta da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Isso incluirá a reaplicação de qualquer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events/create-clicked-element-events",
        children: "evento de elemento clicado"
      }), " que tenha sido configurado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode usar esta função para reaplicar automaticamente handlers de clique quando o conteúdo na página for atualizado, como atualizar uma seção de conteúdo ou exibir uma janela modal na página."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " essa funcionalidade é disparada automaticamente como parte da função ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "setPath"
          })
        }), ". Portanto, você só precisará usar essa função quando atualizar o conteúdo sem atualizar a URL da página rastreada."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Reapply event handlers after updating the page content\n_hsq.push(['refreshPageHandlers']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rastreamento de eventos comportamentais personalizados (somente Marketing Hub Enterprise)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando eventos comportamentais personalizados, você pode vincular conclusões de eventos a registros de contato e preencher propriedades de evento com metadados sobre o evento. Para recuperar eventos comportamentais personalizados, use a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "API de análises da web"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por meio da API, os eventos podem ser disparados usando seus nomes internos, que são atribuídos automaticamente quando você cria os eventos. Você pode encontrar o nome interno de um evento ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "no HubSpot"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/reporting#get-events",
        children: "usando a API de eventos"
      }), ". Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "encontrar o nome interno de um evento"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Imported%20sitepage%20images/custom-event-internal-name.png",
        alt: "custom-event-internal-name"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há três tipos de eventos que você pode criar no HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Eventos de elementos clicados"
        }), ": eventos vinculados a elementos clicáveis em uma página do site. Preencherá automaticamente um conjunto de propriedades de evento padrão da HubSpot por meio do código de rastreamento. Você pode personalizar ainda mais esses eventos com a função ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "Eventos de URL visitada"
        }), ": eventos vinculados a carregamentos de página em URLs específicas. Preencherá automaticamente um conjunto de propriedades de evento padrão da HubSpot por meio do código de rastreamento. Você pode personalizar ainda mais esses eventos com a função ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "Eventos comportamentais rastreados manualmente"
        }), ": eventos personalizados que são exclusivos da sua empresa, bem como eventos que podem não ser capturados automaticamente pelo HubSpot ou por uma integração. Envie dados manualmente para eventos do HubSpot por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "API HTTP"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para cada tipo de evento, o HubSpot inclui um conjunto de propriedades padrão que podem capturar certos metadados no momento da conclusão, incluindo parâmetros UTM ou metadados do dispositivo e do sistema operacional."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como essa função trabalha juntamente com o rastreamento de análises de dados do HubSpot, qualquer evento disparado por meio da API JavaScript será automaticamente associado ao cookie ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "hubspotutk"
      }), " do visitante. Nesse caso, o evento será ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identities-overview",
        children: "vinculado automaticamente ao contato associado a esse token do usuário"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "trackCustomBehavioralEvent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"trackCustomBehavioralEvent\", { {event details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use essa função para rastrear um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events-user-guide-v2/a-quick-tour-of-events",
        children: "evento"
      }), " usando o JavaScript e o código de rastreamento do HubSpot. Você pode usar eventos para rastrear atividades específicas realizadas pelos visitantes em seu site. Eventos rastreados podem aparecer nas linhas do tempo dos contatos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to fire a custom behavioral event using the name \"clicked Buy Now button\" when a visitor clicks an element with the 'buyNow' id.\n*/\n\ndocument.getElementById(\"buyNow\").onclick = function() {_hsq.push([\"trackCustomBehavioralEvent\", {\n   name: \"pe123456_course_registration\",\n   properties: {\n       course_id: \"Math101\"\n   }\n}]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Argumentos"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Como usar"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "name:\"internal_name\""
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O event_id ou o nome interno do evento que você criou no HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "property_name: \"property_value\""
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista de pares de chaves e valores, com um par de chave e valor por propriedade.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_name"
            }), " é o nome interno da propriedade de evento que você criou para o evento e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_value"
            }), " é o valor a ser adicionado à propriedade. Você também pode rastrear propriedades não definidas e retornar para criá-las após o rastreamento de eventos."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Personalize o código de rastreamento para enviar dados de eventos comportamentais personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por padrão, o HubSpot cria um conjunto de propriedades para cada evento que você criar. Para eventos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "elemento clicado"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "URL visitada"
      }), ", o HubSpot preencherá automaticamente algumas dessas propriedades com dados. Porém, você também poderá personalizar o código de rastreamento para enviar dados para as propriedades do evento."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Relatórios"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ferramentas de análise"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Personalizar eventos comportamentais"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nome"
        }), " do evento que você deseja rastrear."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Em ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Propriedades"
        }), ", copie o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome interno"
        }), " do evento."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-clicked-element-event-internal-name0.png",
        alt: "custom-clicked-element-event-internal-name0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Em seguida, na tabela de propriedades, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), " da propriedade de evento para qual você deseja enviar os dados."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No painel direito, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ícone de origem </>"
          }), " para visualizar o nome interno da propriedade. Você usará esse nome ao personalizar o código de rastreamento."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-behavioral-event-property-internal-name0.png",
            alt: "custom-behavioral-event-property-internal-name0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Agora que você tem os dados do evento e da propriedade de evento, clique no ícone ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Configurações"
          }), " para acessar as configurações da conta. Em seguida, no menu da barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Rastreamento e análises"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Código de rastreamento"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Personalizar javascript"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/tracking-code-customize-javascript0.png",
            alt: "tracking-code-customize-javascript0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Adicionar Javascript personalizado"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral direita, insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), " para o JavaScript personalizado e insira o JavaScript, incluindo a função ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#events-js-api",
            children: "trackCustomBehavioralEvent"
          }), ". O JavaScript será executado após o código de rastreamento ser carregado em uma página."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example usage\n_hsq.push(['trackCustomBehavioralEvent',{\n   name: '((behavioral_event_internal_name))”,\n   properties: { internal_property_name: property_value}\n}\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, se seu evento rastreia o registro em um curso quando um botão com o ID HTML ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "register_for_econ101"
      }), " é clicado, seu JavaScript pode ter a seguinte aparência: ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/customize-tracking-code0-1.png",
        alt: "customize-tracking-code0-1"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Salvar"
        }), " para salvar o JavaScript. O código de rastreamento será carregado com o JavaScript personalizado."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}