"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45939803996;
const slug = exports.slug = 'guides/cms/improve-older-sites/add-theme-features-to-existing-sites';
const title = exports.title = 'テーマ機能をHubSpotのCMS上の既存ウェブサイトに追加する方法';
const name = exports.name = 'APAC JAPAN (JA) | [NEW] Add theme capabilities to an existing HubSpot CMS website | 202106';
const metaDescription = exports.metaDescription = 'テーマ機能は、古いサイトにも追加する形で組み込むことが可能です。ウェブサイトの今後と、この機能によってどのようにウェブサイトを強化できるかを検討してください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E",
  "label": "テーマ",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-%E6%97%A2%E5%AD%98%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%921%E3%81%A4%E3%81%AE%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC%E3%81%AB%E9%85%8D%E7%BD%AE%E3%81%99%E3%82%8B",
  "label": "1. 既存のファイルを1つのフォルダーに配置する",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "2.-%E6%A0%BC%E7%B4%8D%E3%81%97%E3%81%9F%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC%E3%82%92%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "2. 格納したフォルダーをテーマにする",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 1,
  "id": "3.-%E3%83%86%E3%83%BC%E3%83%9E%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "3. テーマフィールドを追加する",
  "parent": "%E3%83%86%E3%83%BC%E3%83%9E"
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%AE%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%92html%E3%81%A8%E3%81%97%E3%81%A6%E8%A4%87%E8%A3%BD%E3%81%99%E3%82%8B",
  "label": "デザインマネージャーのドラッグ＆ドロップをHTMLとして複製する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2",
  "label": "ドラッグ＆ドロップエリア",
  "parent": null
}, {
  "depth": 2,
  "id": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E3%81%9F%E3%82%81%E3%81%AB%E3%80%81%E5%85%A8%E3%81%A6%E3%81%AE%E6%97%A2%E5%AD%98%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E5%A4%89%E6%8F%9B%E3%81%99%E3%82%8B%E6%84%8F%E5%91%B3%E3%81%AF%E3%81%82%E3%82%8A%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "ドラッグ＆ドロップエリアを使用するために、全ての既存ページを変換する意味はありますか？",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2"
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E5%A4%89%E6%8F%9B",
  "label": "既存のテンプレートの変換",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2"
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E7%A7%BB%E8%A1%8C%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95%E3%81%AF%EF%BC%9F",
  "label": "既存のページを移行する方法は？",
  "parent": "%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2"
}, {
  "depth": 0,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "グローバルパーシャル",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%BC%E3%83%89%E3%82%A2%E3%83%A9%E3%83%BC%E3%83%88",
  "label": "コードアラート",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      code: "code",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "テーマ機能をHubSpotのCMS上の既存ウェブサイトに追加する方法"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのメリットとして、更新し続ける必要がないことが挙げられます。CMS Hubでは常に最新バージョンが使用されます。CMS Hubの新機能は追加として導入されます。そのため、HubSpotのCMS上に構築した既存のウェブサイトのテンプレートやモジュールなどは、構築した当時と同様に機能します。しかも、CMSの内部は常に改善され、速度や編集のしやすさが向上しています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CMS Hubの機能の中には、開発者による追加が必要な機能もあります。このような機能の一部は、新しいウェブサイトの場合とは異なる方法で実装することも可能です。このチュートリアルでは、既存のウェブサイトにこれらの機能を追加する方法を説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "続ける前に"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotでの構築経験がある場合も、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "CMS Hubの主な概念"
        }), "を再確認してください。全ての要素を関連付けて理解できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI"
        }), "を使用する必要もあるため、インストールがまだの場合は", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "設定"
        }), "してください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらの機能は個別に追加して使用できます。必要な機能の見出しを見つけて、取り組んでみてください。このチュートリアルは順序に完全に従っていただく必要はありません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "テーマ"
      }), "は、テンプレート、モジュール、CSS、JSON、JSファイルのパッケージです。テーマがパッケージ化されていることは、コンテンツエディターの使いやすさの面で意味があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "制作担当者が新しいウェブサイトまたはランディングページを作成する際には、そのアカウントに含まれるテーマのグリッドが表示されます。使用するテーマを選択すると、テーマに含まれるテンプレートのみのグリッドが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマのテンプレートを使用してページを編集する場合、テーマに含まれているモジュールは、［モジュールを追加］パネル内で分かりやすく示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. 既存のファイルを1つのフォルダーに配置する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "同じフォルダーに格納されているテンプレート、モジュール、CSS、JSファイルがあれば、テーマにします。サブフォルダーに分けるかどうかは問題ではなく、それらのアセットが1つのフォルダー内にあることのみが重要です。すでにそのように格納されていれば問題ありません。まだの場合は、フォルダーを作成し、アセットをそのフォルダーに移動します。このフォルダーに、テーマに付ける名前を付けます。名前は自由に決められますが、サイトに反映する会社のブランドと、デザインの刷新または変更の年に基づいて付けることをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. 格納したフォルダーをテーマにする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマには2つのJSONファイルが必要で、実際にまずコンテンツが必要になるのはそのうちの1つのみです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Theme.json"
        }), " -テーマの設定が含まれます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fields.json"
        }), " -サイト全体にデザインの変更を反映させるために使用するフィールドが含まれます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JSONファイルは特殊なので、現時点ではデザインマネージャーでは作成できません。これらのファイルは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "経由でアップロードできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "「既存のファイルを1つのフォルダーに配置する」ステップで作成したフォルダーを、自分のコンピューターに取り込みます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コンピューター上のフォルダー内に、新しいファイルを作成して", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "という名前を付けます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/cms/content/themes/overview#theme-json",
          children: ["テーマドキュメントにサンプル", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.json"
          }), "ファイル"]
        }), "をコピーします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コード例を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "ファイルに貼り付けます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "\"label\""
        }), "の値を、制作担当者に表示するテーマの名前に変更します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "\"preview_path\""
        }), "をホームページテンプレートまたは最も頻繁に使用するテンプレートのいずれかのパスに変更します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ウェブサイトのホームページのスクリーンショットを取ります。画像に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.jpg"
        }), "という名前を付けます。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "ファイルと同じフォルダー内に画像を配置します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["theme.jsonの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"screenshot_path\""
        }), "を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.png"
        }), "画像のパスに変更します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルを保存します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "と同じフォルダー内に新しいファイルを作成し、fields.jsonという名前を付けます。このファイル内に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[]"
        }), "のみを入力し、ファイルを保存します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["デザインマネージャー上で変更内容を確認するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs uploadコマンド"
      }), "を実行します。これで基本的なテーマが作成されました。テーマに関連付けられて、テーマ内のモジュールとテンプレートが表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. テーマフィールドを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマフィールドは、制作担当者がテーマ全体のスタイルを変更できるように提供されるUIコントロールです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのテーマコントロールは、新しいウェブサイトの構築時に、制作担当者によるサイト全体のブランディング設定を可能にします。開発者はサイト開発の技術的な側面に集中できるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "既存のウェブサイトでは、テーマフィールドは不要な場合があります。ウェブサイトがすでにブランドに合わせて構築されている場合は実質、サイト全体の色やデザインのコントロールを追加する意味はありません。サイトのブランディングを大幅に変える場合は、過去に遡ってフィールドを追加するよりも、デザインを再度行うほうが適切と考えられます。最終的には、ウェブサイトに関わる担当者間で話し合って決断してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマにフィールドを追加するには、フィールドのJSONをfields.jsonファイルに追加します。フィールドの構造はモジュールフィールドと同様です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマフィールドの値を変更するためのアクセス権が必要なユーザーに、［グローバルコンテンツおよびテーマを編集］権限があることを確認します。この設定の編集権限が必要ないユーザーについては、この権限が有効になっていないことを確認します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "ユーザー権限のグローバルコンテンツとテーマ設定のスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デザインマネージャーのドラッグ＆ドロップをHTMLとして複製する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "グローバルパーシャル"
      }), "では、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "コードHTML＋HubL"
      }), "ファイルを使用する必要があります。これらの新しい機能を、古いドラッグ＆ドロップ デザイン マネージャー システムを使用して構築されている既存のウェブサイト上で使用する場合は、テンプレートをHTMLとして複製することが必要になる場合があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレートをHTML＋HubLテンプレートとして複製するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "デザインマネージャーを開き、ファインダーでテンプレートを見つけます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テンプレートを右クリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "表示されたコンテキストメニューで、［HTMLとして複製（Clone as HTML）］を選択します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これで、ドラッグ＆ドロップエリアとグローバルパーシャルを追加できるようになりました。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ドラッグ＆ドロップエリア"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "ドラッグ＆ドロップエリア"
      }), "をテンプレートに追加すると、制作担当者がモジュールをページ上で縦横に配置できるようになります。ドラッグ＆ドロップエリアは、制作担当者に追加のスタイルコントロールも提供します。ドラッグ＆ドロップエリアのテンプレートは多様なレイアウトの作成に利用できるので、開発者はサイトの作成と更新の技術面に集中できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ドラッグ＆ドロップエリアは新しい機能で、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "デザインマネージャーのドラッグ＆ドロップテンプレート"
        }), "とは異なります。また、デザインマネージャーのドラッグ＆ドロップテンプレートではサポートされません。ドラッグ＆ドロップ テンプレート ビルダーを使用して以前に作成したテンプレートにドラッグ＆ドロップエリアを追加するには、「デザインマネージャーのドラッグ＆ドロップをHTMLとして複製する」を参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ドラッグ＆ドロップエリアを使用するために、全ての既存ページを変換する意味はありますか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これは、ページがビジネス上の目的を満たしているかどうかによります。つまり、「使えるならそのまま使う」という考え方が当てはまります。ページのレイアウトを変更する必要がある場合は、ドラッグ＆ドロップエリアを使用するページに変換することが適切と考えられます。ページが目的を果たしていてレイアウトの変更が必要ない場合は、現状で問題ありません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のテンプレートの変換"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["古いテンプレートの複製を作成して、フレキシブルカラムをドラッグ＆ドロップエリアに変更するのは比較的簡単です。制作担当者の作業の幅も広がるため、お勧めです。制作担当者は新しいページで数多くのクリエイティブなコントロールを利用できるようになります。現在のテンプレートがデザインマネージャーのドラッグ＆ドロップテンプレートの場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#clone-design-manager-drag-and-drop-as-html",
        children: "デザインマネージャーのドラッグ＆ドロップをHTMLとして複製する"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["最も簡単な解決法は、HubLタグ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% widget_container \"my_unique_column_name\" %}"
          })
        }), "を使用しているフレキシブルカラムのインスタンスを見つけることです。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["必要に応じて、各インスタンスを", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% dnd_area \"my_unique_area_name\" %}"
          })
        }), "で置き換えます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["既定のモジュールを設定しない場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), "を空のままにします。この領域の既定のモジュールを継承するか、新しく便利な既定モジュールを設定する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), "内に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), "を追加し、その内部に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "を追加します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["古い", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_container"
        }), "内にある各モジュールタグについて、既定で表示するモジュールへのパスが指定された", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), "を含む、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), "を作成します。これで、フレキシブルカラムと同じ縦のレイアウトが作成されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のページを移行する方法は？"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["フレキシブルカラムで構築されたテンプレートを変更してドラッグ＆ドロップエリアを使用する場合は、理解しておく点があります。", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "フレキシブルカラムはドラッグ＆ドロップエリアと同じではありません。フレキシブルカラムのみを持つテンプレートをドラッグ＆ドロップエリアのみを持つテンプレートに交換することはできません"
        }), "。安全上、この交換は認められません。コンテンツはフレキシブルカラムからドラッグ＆ドロップエリアにマッピングされません。この理由を考えてみましょう。新しいテンプレートを作成してサイドバーとメインのコンテンツエリアを設定したとします。サイドバーはフレキシブルカラムで、メインコンテンツはドラッグ＆ドロップエリアです。交換ツールはフレキシブルカラム同士をマッピングしようとします。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページを変換してドラッグ＆ドロップエリアを使用する場合に最も安全な方法は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "コンテンツステージング"
      }), "を使用することです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/content/staging/domain",
          children: "コンテンツ ステージング ツールを開き"
        }), "、既存のページを見つけて、ステージングします。［空白ページをステージング］を選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ドラッグ＆ドロップエリアを使用する新しいテンプレートを選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "元のバージョンのページから情報をコピーして貼り付け、必要なレイアウトを作成します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "完了したら、ステージングに公開します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コンテンツ ステージング ツールの［公開］タブに進みます。ページを選択し、ステージングから公開します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "グローバルパーシャル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "グローバルパーシャル"
      }), "とは、ウェブサイト全体で再利用可能なHTMLおよびHubLのコンテンツ要素です。典型的なグローバルパーシャルは、ウェブサイトのヘッダー、サイドバー、フッターです。グローバルパーシャルは、制作担当者にはグローバル コンテンツ エディター上の選択可能な領域として示されます。グローバル コンテンツ エディターの外観はページエディターと同じですが、グローバルコンテンツの編集に使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "具体的には、ナビゲーションなどのコンテンツのモジュールを含むグローバルパーシャルとして、ヘッダーを実装してみてください。ナビゲーションへのリンクを追加する必要がある制作担当者は、ページエディターでヘッダーをクリックし、メニューモジュールをクリックして、メニューを更新します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "グローバルコンテンツを編集するためのアクセス権が必要なユーザーに、［グローバルコンテンツおよびテーマを編集］権限があることを確認します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "ユーザー権限のグローバルコンテンツとテーマ設定"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コードアラート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "コードアラート"
      }), "によって、開発者およびITマネージャーは、CMS Hub Enterpriseのアカウントのページやアセットのパフォーマンスと表示に影響する問題の概要を1か所で確認できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者は、新しい機能を活用するためにサイトを積極的に最適化する立場にあります。アカウントのコードアラートを確認し、問題がある場合は解決することをお勧めします。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}