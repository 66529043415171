"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611094;
const slug = exports.slug = 'guides/cms/tools/design-manager';
const title = exports.title = 'デザインマネージャー';
const name = exports.name = '[new]デザインマネージャー';
const metaDescription = exports.metaDescription = 'デザインマネージャーはウェブベースの統合開発環境です。デザインマネージャーを使用して、テンプレート、モジュール、CSS、JavaScriptを作成することもできます。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/module%20editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'デザインマネージャー';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "デザインマネージャーの設定",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "デザインマネージャー"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["デザインマネージャーはウェブベースの統合開発環境です。デザインマネージャーを使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "テンプレート"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "、CSS、JavaScriptを作成することもできます。デザインマネージャーは、上部ナビゲーションバーの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "［マーケティング］＞［ファイルとテンプレート］＞［デザインツール］"
        })
      }), "にあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["使い慣れたコードエディター、ワークフロー、テクノロジーを使ってローカル環境で開発することをご希望の場合は、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI"
        }), "を使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ローカルでの開発、またはデザインマネージャーを使ったアセット制作にかかわらず、デザインマネージャーではご使用のアカウントのデベロッパー ファイル システム全体を確認し、編集することが可能になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/designmanager%203-20-2020.png",
        alt: "HubSpotデザインマネージャーのスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "デザインマネージャーとローカル開発を組み合わせたワークフロー"
      }), "を採用する開発者やチームもあります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch"
        })
      }), "CMS CLI](/guides/cms/tools/local-development-cli)の[コマンドを使用すると、デザインマネージャーの編集内容をローカル環境に取得できます。または編集を防止し、全体的な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "コードベースとソース管理環境とのCI（継続的インテグレーション）"
      }), "を実現できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["デザインマネージャーとそのコンポーネントの概要については、この", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "ナレッジベースの記事"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デザインマネージャーの設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーには基本的なIDE設定があり、これを作業のフローに適した設定にすることができます。下部のバー内のヘルプボタンの横に表示される設定ボタンから、設定を行うことができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "次の設定が可能です。"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "エディターテーマ"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "フォント"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "タブサイズ"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "字下げの単位"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "ソフトタブ"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "スマートインデント"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "フォーマット入力時の字下げ"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "タブによる字下げ"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "行の折り返し"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "閉じかっこの自動付加"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "かっこの対応"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "タグの対応"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "ESLint"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["その他の設定について、詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://knowledge.hubspot.com/cos-general/customize-design-manager-settings",
                children: "このナレッジベースの記事"
              }), "を参照してください。"]
            }), "\n"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["これらの機能がニーズに合わない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli",
              children: "ローカル開発のツール"
            }), "と", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/creating-an-efficient-development-workflow",
              children: "ワークフロー"
            }), "をお試しください。"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Design%20Manger%20Settings-1.png",
            alt: "デザインマネージャーの設定画面のスクリーンショット"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}