"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164592946577;
const slug = exports.slug = 'reference/ui-components/overview';
const title = exports.title = "Composants des extensions d'interface utilisateur";
const name = exports.name = "Présentation des composants de l'interface utilisateur";
const metaDescription = exports.metaDescription = "Découvrez les composants que vous pouvez utiliser pour créer des extensions d'interface utilisateur.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "composants-standards",
  "label": "Composants standards",
  "parent": null
}, {
  "depth": 0,
  "id": "composants-de-donn%C3%A9es-crm",
  "label": "Composants de données CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "composants-d-action-crm",
  "label": "Composants d'action CRM",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Présentation des composants de l'interface utilisateur (BÊTA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create",
        children: "extension d'IU"
      }), ", vous pouvez inclure n'importe quel nombre de composants réutilisables fournis par HubSpot. Ces composants vont de simples champs de texte à des rapports d'objets CRM prêts à l'emploi, et chaque composant offre des options de personnalisation par le biais de propriétés."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les composants sont importés en tête de votre fichier d'extension ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tsx"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jsx"
      }), ". Selon le type de composant, vous devrez les importer à partir de l'un des deux répertoires du kit de développement logiciel."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#standard-components",
          children: "Les composants standards"
        }), " sont importés de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions'"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-data-components",
          children: "Les données CRM"
        }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#crm-action-components",
          children: "les composants d'action CRM"
        }), " sont importés de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "'@hubspot/ui-extensions/crm'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Alert, Text } from '@hubspot/ui-extensions';\nimport { CrmAssociationPivot, CrmActionLink } from '@hubspot/ui-extensions/crm';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Pour accéder aux derniers composants, assurez-vous que vous avez installé le dernier pack npm en lançant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm i @hubspot/ui-extensions"
        }), " dans le répertoire ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extensions"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Composants standards"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les composants standards sont des composants qui peuvent être utilisés pour les données internes et externes. Ces composants ne récupèrent pas les données eux-mêmes, mais sont plus souples dans leur mise en œuvre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ces composants sont importés de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'@hubspot/ui-extensions'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Composant"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/accordion",
              children: "Accordion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Section en accordéon qui peut contenir d'autres composants."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/alert",
              children: "Alert"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alertes pour indiquer les statuts et les résultats des actions, tels que les messages de réussite et d'erreur."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Composant utilisé pour la gestion de la mise en page. Peut être utilisé avec ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            }), ". Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "gestion de la mise en page des extensions"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boutons permettant aux utilisateurs d'effectuer des actions, telles que l'envoi ou la récupération de données."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button-row",
              children: "Button row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour la restitution de plusieurs boutons."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/checkbox",
              children: "Checkbox"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une case à cocher unique. Pour afficher plusieurs cases à cocher, utilisez plutôt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/date-input",
              children: "DateInput"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Champ permettant aux utilisateurs de sélectionner une date."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/description-list",
              children: "DescriptionList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche des paires de libellés et de valeurs personnalisées, de la même manière que les propriétés HubSpot apparaissent dans la barre latérale gauche des fiches d'informations CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/divider",
              children: "Divider"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ligne horizontale permettant de séparer les éléments d'une extension."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/dropdown",
              children: "Dropdown"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu déroulant permettant de sélectionner des valeurs, présenté sous forme de boutons ou d'hyperliens."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/empty-state",
              children: "EmptyState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Illustration libellée pour indiquer un composant sans contenu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/error-state",
              children: "ErrorState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Illustrations libellées pour indiquer les erreurs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/flex",
              children: "Flex"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enveloppe d'autres composants dans un ensemble vide ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "div"
            }), " défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display=flex"
            }), ". Utilisez ce composant et ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/box",
              children: "Box"
            }), " pour ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/manage-ui-extension-layout",
              children: "gérer la mise en page de l'extension"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Form"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Formulaire pour soumettre des données, qui peut contenir d'autres composants connexes tels que ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/heading",
              children: "Heading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rend un texte de grande taille pour les titres."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/icon",
              children: "Icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rend une icône visuelle dans d'autres composants."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une image, principalement utilisée pour ajouter des logos ou d'autres éléments d'identité visuelle de la marque, ou pour accentuer d'autres contenus de l'extension."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/input",
              children: "Input"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Champ de saisie de texte dans lequel les utilisateurs peuvent saisir des valeurs textuelles personnalisées. Principalement utilisé dans les composants du ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "formulaire"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lien hypertexte cliquable permettant d'accéder à des pages externes et des pages de l'application HubSpot, ou de déclencher des fonctions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/list",
              children: "List"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste ordonnée ou non d'éléments."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOUVEAU"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Similaire à un composant ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), " avec des options d'état de chargement supplémentaires."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-spinner",
              children: "LoadingSpinner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indicateur visuel indiquant que la carte est en cours de chargement ou de traitement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOUVEAU"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une superposition contextuelle contenant d'autres composants. Utile pour les messages courts et les boîtes de confirmation d'action. Peut être ouvert avec les composants ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un champ de sélection déroulant dans lequel les utilisateurs peuvent sélectionner plusieurs valeurs. Pour permettre la sélection d'une seule valeur, utilisez plutôt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            }), ". Principalement utilisé dans les composants du ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "formulaire"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Champ de saisie d'un nombre. Principalement utilisé dans les composants du ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "formulaire"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOUVEAU"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un panneau qui s'ouvre sur le côté droit de la page, contenant d'autres éléments. Peut être ouvert avec les composants ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Button"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/loading-button",
              children: "LoadingButton"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Link"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/image",
              children: "Image"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/progress-bar",
              children: "ProgressBar"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Représentation visuelle des données en mouvement vers une cible positive ou négative. Peut afficher des nombres et des pourcentages."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/radio-button",
              children: "RadioButton"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un bouton de case d'option. Si vous souhaitez inclure plus de deux cases d'option ou si vous créez un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "formulaire"
            }), ", il est recommandé d'utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            }), " à la place."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/select",
              children: "Select"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Champ de sélection déroulant dans lequel l'utilisateur peut sélectionner une seule valeur. Pour permettre la sélection de plusieurs valeurs, utilisez plutôt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/multi-select",
              children: "MultiSelect"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/statistics",
              children: "Statistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un aperçu visuel d'un ou plusieurs points de données. Comprend des valeurs numériques et des indicateurs de tendance (pourcentage croissant/décroissant)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/status-tag",
              children: "StatusTag"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "success",
              children: "NOUVEAU"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un indicateur visuel pour afficher l'état actuel d'un élément."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/step-indicator",
              children: "StepIndicator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indicateur visuel décrivant la progression d'un processus à plusieurs étapes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/stepper-input",
              children: "StepperInput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Semblable au composant ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/number-input",
              children: "NumberInput"
            }), ", mais ce champ permet aux utilisateurs d'augmenter ou de diminuer la valeur d'un montant défini."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/table",
              children: "Tableau"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : permet d'afficher les données en colonnes et en lignes. Les tableaux peuvent être paginés et triés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Libellés de couleur permettant de classer des informations ou d'autres éléments. Ils peuvent être statiques ou cliquables pour déclencher des fonctions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rend le texte avec des options de formatage."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tile",
              children: "Tile"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Conteneur rectangulaire et bordé permettant de créer des groupes de composants apparentés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text-area",
              children: "TextArea"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Semblable à ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/text",
              children: "Text"
            }), ", mais pour des textes plus longs. Comprend des accessoires permettant de configurer la taille des champs, le nombre maximal de caractères et la redimensionnabilité."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle",
              children: "Toggle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un interrupteur booléen qui peut être configuré avec des options de taille et de libellé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/toggle-group",
              children: "ToggleGroup"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste de cases à cocher ou de cases d'option sélectionnables."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Composants de données CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les composants de données CRM peuvent extraire des données directement de la fiche d'informations CRM en cours d'affichage, y compris des informations sur les fiches d'informations associées et les rapports d'objets uniques. Ces composants ne peuvent être placés que dans la colonne centrale des fiches d'informations CRM. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-data-components/overview",
        children: "composants de données CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ces composants sont importés de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Composant"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-pivot",
              children: "CrmAssociationPivot"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste des fiches d'informations associées à la fiche d'informations en cours d'affichage, organisées selon leur libellé d'association."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-property-list",
              children: "CrmAssociationPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste modifiable des propriétés CRM appartenant à une fiche d'informations associée à la fiche d'informations actuellement affichée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-stage-tracker",
              children: "CrmAssociationStageTracker"
            }), " NOUVEAU"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une barre de progression du cycle de vie ou du pipeline qui affiche les données des fiches d'informations associées. Peut inclure une liste de propriétés avec une possibilité de modification intraligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-association-table",
              children: "CrmAssociationTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau des fiches d'informations associées à la fiche d'informations actuellement affichée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-data-highlight",
              children: "CrmDataHighlight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste des propriétés CRM appartenant à la fiche d'informations actuellement affichée ou à une autre fiche d'informations spécifiée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-property-list",
              children: "CrmPropertyList"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste modifiable des propriétés CRM appartenant à la fiche d'informations actuellement affichée ou à une autre fiche d'informations spécifiée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-report",
              children: "CrmReport"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche un rapport d'objet unique existant. Inclut des options de filtrage ou peut afficher toutes les données de rapport sans filtre."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-stage-tracker",
              children: "CrmStageTracker"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Barre de progression des phases du cycle de vie ou du pipeline avec une liste de propriétés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/crm-statistics",
              children: "CrmStatistics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affichez des résumés des données calculées à partir des associations de la fiche d'informations en cours d'affichage. Par exemple, le chiffre d'affaires moyen des entreprises associées à un contact."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Composants d'action CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les composants d'action CRM fournissent un ensemble intégré d'actions liées à la gestion de la relation client, notamment l'ajout de notes aux fiches d'informations, l'ouverture d'une fenêtre de rédaction d'un e-mail personnalisé, la création de nouvelles fiches d'informations, etc. Chaque composant peut effectuer le même ensemble d'actions. Le choix du composant dépendra donc de vos besoins et de vos préférences. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview",
        children: "composants d'action CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les composants d'action CRM sont importés de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)("span", {
              style: {
                display: 'inline-block',
                width: '200px'
              },
              children: "Composant"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-button",
              children: "CrmActionButton"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un bouton qui peut exécuter un ensemble intégré d'actions CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-action-link",
              children: "CrmActionLink"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lien cliquable qui peut exécuter un ensemble intégré d'actions CRM."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/crm-card-actions",
              children: "CrmCardActions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Petits boutons autonomes ou boutons de menu déroulant pouvant contenir plusieurs actions CRM."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}