"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65495023168;
const slug = exports.slug = 'reference/api/deprecated/overview';
const title = exports.title = 'HubSpot-APIs | Veraltete APIs';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Eine Liste veralteter HubSpot-APIs';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eingestellte-apis",
  "label": "Eingestellte APIs",
  "parent": null
}, {
  "depth": 0,
  "id": "veraltete-apis",
  "label": "Veraltete APIs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Eingestellte und veraltete APIs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die in den folgenden Abschnitten aufgeführten APIs erhalten ", (0, _jsxRuntime.jsx)("u", {
        children: "nichtlänger"
      }), " zukünftige Updates der Funktionalität und werden in einigen Fällen vollständig eingestellt und für die Verwendung in Ihren Integrationen nicht mehr zur Verfügung gestellt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es wird dringend empfohlen, das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "HubSpot-Entwickler-Änderungsprotokoll"
      }), " zu abonnieren, um in Bezug auf die neuesten Updates, Breaking Changes und andere wichtige Änderungen an der Funktionalität auf dem neuesten Stand zu bleiben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eingestellte APIs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden APIs wurden vollständig entfernt und geben einen Fehler zurück, wenn ein Aufruf an ihre zugehörigen Endpunkte erfolgt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Datum der Einstellung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Notizen"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS-Performance"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9\\. April 2024"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Verwenden Sie für Website-Analysedaten die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/reporting",
              children: "Analytics-API"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "E-Commerce Bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28\\. Februar, 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn Sie zuvor eine Integration mit dieser API erstellt haben, können Sie mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "dieses Migrationsleitfadens"
            }), " Ihre Integration auf die Verwendung privater Apps umstellen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Erweiterung für Buchhaltungsprogramme"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28\\. Februar, 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Wenn Sie zuvor eine Integration mit dieser API erstellt haben, können Sie mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "dieses Migrationsleitfadens"
            }), " Ihre Integration auf die Verwendung privater Apps umstellen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketingkalender"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "31\\. August 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sie können weiterhin ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/use-your-marketing-calendar",
              children: "den Marketingkalender in HubSpot verwenden"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Veraltete APIs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die unten aufgeführten älteren Endpunkte erhalten ", (0, _jsxRuntime.jsx)("u", {
        children: "kein"
      }), " Versions-Update. Diese Endpunkte sind funktional und stabil, aber Sie werden nicht über ihre aktuelle Version hinaus aktualisiert. HubSpot unterstützt diese weiterhin auf absehbare Zeit und kündigt zukünftige Änderungen mit genügend Vorlaufzeit in seinem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Entwickler-Änderungsprotokoll"
      }), " an.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Notizen"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Social Media"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Umfasst die folgenden Endpunkte:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
                  children: "Veröffentlichungskanäle abrufen"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
                  children: "Broadcast-Nachrichten abrufen"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
                  children: "Eine Broadcast-Nachricht abrufen"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
                  children: "Eine Broadcast-Nachricht erstellen"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
                  children: "Eine Broadcast-Nachricht abbrechen"
                })
              })]
            })]
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}