import { combineReducers } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

// import authReducer from 'developer-docs-shared-components/modules/Auth/reducer';
// @ts-expect-error Untyped
import docReducer from 'developer-docs-shared-components/modules/Doc/reducers';
// @ts-expect-error Untyped
import tryItReducer from 'developer-docs-shared-components/modules/TryIt/reducer';
import { startApp } from '../actions/AppActions';
import auth from './auth';
import userState from './userState';
import searchState from './searchState';
import allSpecsState from './allSpecs';
export function useAppDispatch() {
  return useDispatch();
}
export function useAppSelector(selector) {
  return useSelector(selector);
}
export const rootReducer = combineReducers({
  auth,
  userState,
  searchState,
  // auth: authReducer,
  doc: docReducer,
  tryIt: tryItReducer,
  allSpecsState
});
export const initialState = rootReducer(undefined, startApp());