"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29913100011;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/overview';
const title = exports.title = 'Drag and Drop areas overview';
const name = exports.name = 'Drag and drop areas overview';
const metaDescription = exports.metaDescription = 'Drag and drop areas allow content creators to place modules, change layout, and some styling within the content editors. Developers create predefined layouts.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "the-content-creator-experience",
  "label": "The content creator experience",
  "parent": null
}, {
  "depth": 0,
  "id": "the-developer-experience",
  "label": "The developer experience",
  "parent": null
}, {
  "depth": 0,
  "id": "drag-and-drop-area-elements",
  "label": "Drag and drop area elements",
  "parent": null
}, {
  "depth": 1,
  "id": "sections",
  "label": "Sections",
  "parent": "drag-and-drop-area-elements"
}, {
  "depth": 1,
  "id": "columns",
  "label": "Columns",
  "parent": "drag-and-drop-area-elements"
}, {
  "depth": 1,
  "id": "rows",
  "label": "Rows",
  "parent": "drag-and-drop-area-elements"
}, {
  "depth": 1,
  "id": "modules",
  "label": "Modules",
  "parent": "drag-and-drop-area-elements"
}, {
  "depth": 0,
  "id": "html-structure-and-styling",
  "label": "HTML structure and styling",
  "parent": null
}, {
  "depth": 1,
  "id": "editor-and-attribute-styling",
  "label": "Editor and attribute styling",
  "parent": "html-structure-and-styling"
}, {
  "depth": 0,
  "id": "migrating-flexible-columns",
  "label": "Migrating flexible columns",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Drag and Drop areas overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Drag and drop areas enable you to create areas of pages and global partials where content creators can place modules, change layout, and add styling within the content editor. Using drag and drop areas, you can create fewer templates, as content creators are able to create layouts on their own."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn more about the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " experience and concepts. Once you're ready to build, see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "getting started with dnd_area"
      }), ", and the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area reference"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " drag and drop areas can't be used in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "blog post"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "email templates"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "The content creator experience"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a content creator creates a page using a template that has drag and drop areas, they first see the page with predefined modules in the layout that you've defined as the developer. This initial layout sets the precedent for how pages using this template might look. Using drag and drop areas, the content creator can then build the page, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Adding modules, sections, rows, and columns."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Resizing modules and updating their content and styling, such as adjusting alignment and adding backgrounds."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This gives content creators enough flexibility to make simple page changes without needing a developer for small tweaks."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " a content creator can swap a page's template for another template of the same type, depending on whether it has ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), " tags."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Templates built with the visual drag and drop layout editor can be swapped for other drag and drop templates or coded templates with or without ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Coded templates with ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " tags can only be swapped for other coded templates with ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Coded templates ", (0, _jsxRuntime.jsx)("u", {
            children: "without"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags can only be swapped for other coded templates without ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags."]
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "When the page's template is swapped, any existing content added to drag and drop areas of the first template will be retained."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/drag-and-drop.gif",
        alt: "The page editor experience for dnd_areas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "The developer experience"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Developing with drag and drop areas is similar to working with common CSS frameworks and their grids. First you'll lay out the page using containers, called ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: "sections"
      }), ", which contain rows. Inside of those rows are modules and columns. Learn more about these ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#drag-and-drop-area-elements",
        children: "elements"
      }), " below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While you could hard-code nearly everything into the template, the goal of developing with drag and drop areas is that you instead build the default page content which can later be edited by a content creator."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["View the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "HubSpot CMS Boilerplate templates"
      }), " to see ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " tags in use."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd%20coded.png",
        alt: "dnd_area coded in VS Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Drag and drop area elements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When building a page with drag and drop areas, you'll include the following elements:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-area",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dnd_area"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the highest-level drag and drop element which enables dragging and dropping modules in the content editor. You cannot nest drag and drop areas. For example, a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " cannot contain a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " tag."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-section",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dnd_section"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the outermost container in a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ". Can contain ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), ". You cannot nest a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " within other drag and drop elements."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-column",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dnd_column"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " can contain ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), ". Multiple columns within a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), " will align the row's contents horizontally."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-row",
          children: "dnd_row"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " can contain ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas#dnd-module",
          children: "dnd_module"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " a module wrapper where module layout, styles, and content can be added."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about each of these tags below. In addition, you may want to walk through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "Getting started with drag and drop areas guide"
      }), " for a hands-on approach."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Drag%20and%20Drop%20relationships.png",
        alt: "Drag and Drop relationships"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The diagram below further breaks down the hierarchy of the various drag and drop elements."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Example%20structure.png",
        alt: "Example structure"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For a video walkthrough of how to visualize drag and drop areas, check out the video below:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/RUs24n8DfzA",
      maxHeight: "315px",
      maxWidth: "560px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Continue reading to learn more about drag and drop sections, rows, columns, and modules."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sections"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sections are a special type of row which are created using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), " tag. They are the only drag and drop element that can be a direct descendant of a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". You can think of sections like an outer wrapping container. They can enable content to be full width or have a confined center max-width. Because sections wrap around columns and modules, it makes it easy to rearrange and implement large portions of content. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " tag does not render an HTML ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), " element."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is a screenshot of how a section appears in the page editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Section%20in%20page%20editor.png",
        alt: "Section in page editor"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " tag, you can also create ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#create-reusable-sections",
        children: "section templates"
      }), " which are pre-defined reusable sections that content creators can access in the page editor. For example, you could build the section shown in the screenshot above as a section template so that a content creator could quickly add it to pages as needed. Section templates have some unique capabilities, including being able to use them similar to a standard hubL template partial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Columns"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Columns are wrappers for rows and modules and can be placed inside of a row or section. Columns are created using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-column-code-dnd-column-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use multiple columns inside of a row to place rows and the modules they contain horizontally."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Columns are vertical regions that can contain rows. You can make columns of different sizes by changing their width. A row’s size is 12 \"columns\" wide, this refers to the CSS grid. The columns inside of a row can be any size smaller than 12 but cannot add up to more than 12."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When multiple rows are placed inside of a column the modules inside of those rows will appear vertically stacked. Since modules are columns themselves, a module cannot be a direct descendant of a column, they must be contained within a row."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20column%20in%20page%20editor.png",
        alt: "dnd_area column in page editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The appearance of a column in the page editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Rows are wrappers for columns. Rows are created in templates using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-row-code-dnd-row-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        })
      }), " tag. Since modules are columns you can place them directly inside of a row. This will cause the modules to appear horizontally adjacent to each other."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modules can be organized vertically by placing them inside of rows. If you want to place a module above another you would place that module inside a row. You would then add another module in a row above or below that first row."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/dnd_area%20row%20in%20page%20editor.png",
        alt: "dnd_area row in page editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The appearance of a row in the page editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modules"
      }), " are a fundamental part of the HubSpot CMS, acting as reusable building blocks that you use to piece together a site, and display content. When building a template you place modules inside of drag and drop rows and sections using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-module-code-dnd-module-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), " tag. Modules are also columns. Meaning if you place two module tags, or a module and a column directly next to each other, they will appear side-by-side horizontally."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No drag and drop elements can be placed within a module. Modules cannot be direct children of a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML structure and styling"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag and drop areas and their elements when rendered have class names for a 12 column grid based on bootstrap 2. To make it easy to get up and running, you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "_layout.css"
      }), " file from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/",
        children: "HubSpot CMS Boilerplate"
      }), ". This provides default styles for those class names."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You are not required to use this stylesheet and can provide your own styles instead. If you're building your site based off of the CMS Theme Boilerplate and want to use your own CSS, you will want to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/5abaf2a4c45a95dbed1d459f7f0f6407350752ac/src/templates/layouts/base.html#L8",
        children: "remove layout.css from being called in base.html"
      }), ". For your own CSS grid you will need to target those same grid class names, but the styling is up to you."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag and drop areas when rendered create divs with classes that are used by the page editor. Examples would be ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-span"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget-type-cell"
      }), ". You should not directly target these classes as they are used by page-editor and could change down the road."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Instead in your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-area-code-dnd-area-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        })
      }), " HubL add a class parameter with a class name you would like to use"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"container-fluid my-custom-class\">\n  <div class=\"row-fluid-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n      <!--end widget-span -->\n    </div>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Editor and attribute styling"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With drag and drop areas content creators can have some effect on styling of the page. For example they can set a section to have a background. Developers can pass default values for those settings through attributes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When the page is actually rendered, the styles that are generated based on those settings is added to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["At launch of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " those styles were loaded from ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        }), ". That was ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/drag-and-drop-area-styles-moved-from-footer-to-header",
          children: "changed recently"
        }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        }), " and is rolling out to all HubSpot accounts with the HubSpot CMS."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating flexible columns"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you are changing templates built with flexible columns to now use drag and drop areas, keep the following in mind about flexible columns."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Flexible columns are not the same as drag and drop areas, and you can't swap from a template that only has a flexible column to one that only has a drag and drop area. This limitation was put in place because the content would not map from the flexible column to the drag and drop area. To illustrate why this is, suppose you built your new template so you have a sidebar and a main content area. Your sidebar is a flexible column, your main content is a drag and drop area. The swapping tool would map the flexible column to the flexible column."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites#drag-and-drop-areas",
        children: "adding drag and drop areas to existing pages"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites#drag-and-drop-areas",
          children: "Adding drag and drop areas to existing websites"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Creating a drop area"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://youtu.be/RUs24n8DfzA",
          children: "Visualizing Drag and Drop Areas - YouTube"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}