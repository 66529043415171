'use es6';

import { argumentType } from '../../common/constants.js';
import { toSnakeCase } from '../../common/stringutils';
const PYTHON_RESERVED_KEYWORDS = ['from'];
export function formatArgumentKey(key) {
  let formattedKey = toSnakeCase(key);
  if (PYTHON_RESERVED_KEYWORDS.includes(formattedKey)) {
    formattedKey = `_${formattedKey}`;
  }
  return formattedKey;
}
export function formatArgumentValue({
  key,
  value,
  type
}) {
  let stringBoolean;
  switch (type) {
    case argumentType.OBJECT:
    case argumentType.REF:
      return typeof value === 'object' ? formatArgumentKey(key) : formatArgumentKey(value);
    case argumentType.STRING:
      if (typeof value === 'undefined') {
        return '';
      } else if (value.includes('\n')) {
        return `"""${value}"""`;
      }
      return `"${value}"`;
    case argumentType.ARRAY:
      return JSON.stringify([value].flat());
    case argumentType.BOOLEAN:
      stringBoolean = value.toString();
      return stringBoolean.charAt(0).toUpperCase() + stringBoolean.slice(1);
    default:
      return value;
  }
}