"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044627;
const slug = exports.slug = 'guides/cms/features';
const title = exports.title = 'Recursos do HubSpot CMS';
const name = exports.name = 'PT-BR CMS Features';
const metaDescription = exports.metaDescription = 'Os desenvolvedores e profissionais de marketing podem trabalhar com vários recursos poderosos do HubSpot CMS para construir seus sites e apoiá-los com o CRM de classe mundial da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sandbox-para-desenvolvedores-do-cms",
  "label": "Sandbox para desenvolvedores do CMS",
  "parent": null
}, {
  "depth": 0,
  "id": "pesquisa-de-conte%C3%BAdo",
  "label": "Pesquisa de conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "v%C3%A1rios-idiomas",
  "label": "Vários idiomas",
  "parent": null
}, {
  "depth": 0,
  "id": "gerenciador-de-arquivos",
  "label": "Gerenciador de arquivos",
  "parent": null
}, {
  "depth": 0,
  "id": "prepara%C3%A7%C3%A3o-de-conte%C3%BAdo",
  "label": "Preparação de conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb",
  "label": "HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "associa%C3%A7%C3%A3o",
  "label": "Associação",
  "parent": null
}, {
  "depth": 0,
  "id": "fun%C3%A7%C3%B5es-sem-servidor",
  "label": "Funções sem servidor",
  "parent": null
}, {
  "depth": 0,
  "id": "apis-da-hubspot",
  "label": "APIs da HubSpot",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      strong: "strong",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Recursos do CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os desenvolvedores e profissionais de marketing podem trabalhar com vários recursos poderosos do HubSpot CMS para construir seus sites e apoiá-los com o CRM de classe mundial da HubSpot. Organize seu site com segurança enquanto cria com o HubDB, fornecendo conteúdo específico direcionado ao usuário e certificando-se de que seu conteúdo seja apresentado em um idioma com o qual o leitor esteja confortável. Tudo dentro do HubSpot CMS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sandbox para desenvolvedores do CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Crie uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "Sandbox para desenvolvedores do CMS"
      }), " para obter acesso a todas as ferramentas e recursos de desenvolvedor do HubSpot. Use sua sandbox para saber como criar sites excelentes no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://br.hubspot.com/products/cms",
        children: "CMS Hub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pesquisa de conteúdo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Não há necessidade de usar uma ferramenta de pesquisa de terceiros. Usando o mecanismo de pesquisa nativo do HubSpot, a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "funcionalidade de pesquisa integrada"
      }), " do HubSpot CMS permitirá que seus visitantes encontrem o conteúdo que procuram."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vários idiomas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com o HubSpot CMS, os usuários podem criar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "variações do conteúdo em outros idiomas"
      }), ". Assim, os usuários finais poderão ver o conteúdo no idioma com o qual se sentem mais confortáveis. Além disso, o HubSpot fornece ferramentas para ajudar os desenvolvedores a garantir que o idioma certo esteja disponível para o usuário final."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gerenciador de arquivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/file-manager",
        children: "Gerenciador de arquivos do HubSpot"
      }), " pode ser usado para armazenar e servir arquivos em seu site. Os arquivos podem ser armazenados no Gerenciador de arquivos por meio da interface do usuário no aplicativo ou por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preparação de conteúdo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "preparação de conteúdo"
      }), " permite que os criadores de sites atualizem o design e o conteúdo das páginas, preparando as alterações. Essas alterações preparadas podem ser publicadas em seu site de produção. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "A preparação de conteúdo é um recurso do CMS Hub Professional."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), " é um repositório de dados relacionais, muito parecido com uma planilha. Você pode criar tabelas e manipular dados no HubDB por meio de uma interface de usuário ou usando a API do HubDB. Você pode usar as tags de marcação HubL da HubSpot para colocar os dados diretamente no CMS e gerar páginas de forma dinâmica com base nas linhas da tabela do HubDB. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "O HubDB é um recurso do CMS Hub Professional."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando o recurso ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "Associação"
      }), " do CMS Hub Enterprise, você pode exigir que seus contatos do CRM estejam conectados para visualizar conteúdo específico em seu site. O conteúdo por trás das páginas de associação pode ser altamente personalizado para o contato conectado, e pode até mesmo renderizar dados de Contatos, Empresas, Negócios e Produtos do CRM.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "A funcionalidade de Associações é um recurso do CMS Hub Enterprise."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "As funções sem servidor"
      }), " oferecem uma forma de escrever código do lado do servidor que interaja com a HubSpot e serviços de terceiros através de APIs. Use as funções sem servidor para adicionar recursos avançados ao seu site do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://br.hubspot.com/products/cms",
        children: "CMS Hub"
      }), ". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "As funções sem servidor são um recurso do CMS Hub Enterprise."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIs da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "APIs da HubSpot"
      }), " fornecem acesso autenticado e seguro aos dados do CMS e do CRM. As APIs permitem que você crie um aplicativo ou integração funcional de forma rápida e fácil. Todas as APIs da HubSpot estão organizadas em REST — se você já interagiu com uma API RESTful, muitos dos conceitos serão familiares para você."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}