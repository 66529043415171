"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279880;
const slug = exports.slug = 'guides/cms/content/performance/overview';
const title = exports.title = 'Descripción general de CDN, seguridad y rendimiento';
const name = exports.name = 'EMEA ES CDN, security, and performance overview';
const metaDescription = exports.metaDescription = 'El CMS de HubSpot ofrece herramientas de seguridad, fiabilidad, rendimiento y mantenimiento, además de una CDN global y rápida';
const toc = exports.toc = [{
  "depth": 0,
  "id": "red-de-distribuci%C3%B3n-de-contenido-cdn",
  "label": "Red de distribución de contenido (CDN)",
  "parent": null
}, {
  "depth": 0,
  "id": "capa-de-puertos-seguros-ssl",
  "label": "Capa de puertos seguros (SSL)",
  "parent": null
}, {
  "depth": 0,
  "id": "http%2F2",
  "label": "HTTP/2",
  "parent": null
}, {
  "depth": 0,
  "id": "ipv6",
  "label": "IPv6",
  "parent": null
}, {
  "depth": 0,
  "id": "javascript-y-la-minimizaci%C3%B3n-de-css",
  "label": "Javascript y la minimización de CSS",
  "parent": null
}, {
  "depth": 0,
  "id": "almacenamiento-en-cach%C3%A9-del-navegador-y-del-servidor",
  "label": "Almacenamiento en caché del navegador y del servidor",
  "parent": null
}, {
  "depth": 0,
  "id": "reescritura-de-dominios",
  "label": "Reescritura de dominios",
  "parent": null
}, {
  "depth": 0,
  "id": "compresi%C3%B3n-de-texto",
  "label": "Compresión de texto",
  "parent": null
}, {
  "depth": 0,
  "id": "compresi%C3%B3n%2Foptimizaci%C3%B3n-de-im%C3%A1genes-y-redimensionamiento-autom%C3%A1tico-de-im%C3%A1genes",
  "label": "Compresión/optimización de imágenes y redimensionamiento automático de imágenes",
  "parent": null
}, {
  "depth": 0,
  "id": "p%C3%A1ginas-m%C3%B3viles-aceleradas-amp",
  "label": "Páginas móviles aceleradas (AMP)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Descripción general de CDN, seguridad y rendimiento"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El CMS de HubSpot es uno de los sistemas de gestión de contenido más avanzados y optimizados del mercado. Dado que el CMS de HubSpot es una solución de Software como servicio (SaaS), manejamos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/security",
        children: "seguridad"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/reliability",
        children: "confiabilidad"
      }), ", mantenimiento y más, para que tu equipo pueda concentrarse en escribir código y crear experiencias de usuario atractivas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot hace numerosas mejoras de rendimiento para ti, pero todavía hay más que puedes hacer. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "Cómo optimizar el rendimiento en el CMS de HubSpot."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Red de distribución de contenido (CDN)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La red de distribución de contenido distribuida globalmente del CMS de HubSpot garantizará tiempos de carga de página ultrarrápidos independientemente de dónde se encuentren tus visitantes. No se requiere configuración o cuentas adicionales para aprovechar la CDN para alojar elementos multimedia o páginas. El sistema se encarga de la distribución y la invalidación de la caché para que puedas concentrarte en la creación de un gran sitio, mientras que un firewall de aplicaciones web y las medidas de seguridad integradas proporcionan tranquilidad contra los ataques en línea."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Capa de puertos seguros (SSL)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "SSL se incluye y aprovisiona automáticamente de forma gratuita en todos los dominios conectados en las cuentas de HubSpot CMS o Marketing Hub. Cada dominio cuenta con su propio certificado SAN, con opciones configurables, como desactivar el soporte para versiones seleccionadas de TLS, redirigir las solicitudes realizadas a través de HTTP a HTTPS y servir el encabezado HSTS para que futuras solicitudes se realicen a través de https. Por solicitud, los certificados SSL personalizados se pueden alojar por una tarifa mensual adicional."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTTP/2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todo el tráfico SSL de sitios web alojados en HubSpot se distribuye usando HTTP/2. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://http2.github.io/",
        children: "HTTP/2"
      }), " reemplaza la manera de expresarse del HTTP “en el cable”. No es una reescritura desde cero del protocolo; los métodos HTTP, los códigos de estado y la semántica son los mismos, y debería ser posible usar las mismas API que HTTP/1.x (posiblemente con algunas pequeñas adiciones) para representar el protocolo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El protocolo se centra en el rendimiento, particularmente en la latencia percibida por el usuario final y el uso de recursos del servidor y la red. Un objetivo principal es permitir el uso de una sola conexión de los navegadores a un sitio web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "IPv6"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos los sitios web alojados en HubSpot incluyen direcciones IPv6 para que se pueda acceder a ellos de forma nativa a través de IPv6. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.google.com/intl/en/ipv6/index.html",
        children: "IPv6"
      }), " es la versión más reciente del Protocolo de Internet y amplía el número de direcciones disponibles a una cantidad prácticamente ilimitada: 340 billones de billones de billones de direcciones. Internet se está quedando sin direcciones IPv4. La transición a IPv6 permite que Internet siga creciendo y permite que se desarrollen servicios nuevos e innovadores porque más dispositivos pueden conectarse a Internet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Javascript y la minimización de CSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al escribir páginas de JavaScript y CSS para HubSpot, puedes esperar el siguiente comportamiento de minimización:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot minimiza automáticamente JavaScript y CSS incluidos en el administrador de diseño para eliminar espacios innecesarios, saltos de línea y comentarios. Esto también se aplica a JavaScript y CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "cargados en el administrador de diseño a través de la CLI"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " dado que HubSpot minimiza automáticamente JavaScript y CSS en el administrador de diseño, ", (0, _jsxRuntime.jsx)("u", {
          children: "no debes"
        }), " agregar código minimizado directamente al administrador de diseño."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para incluir código minimizado, debes cargar el archivo en el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/file-manager",
          children: "administrador de archivos"
        }), " y luego adjuntar el archivo a través del administrador de diseño. Para vincular un archivo minimizado a un módulo localmente, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
          children: "actualizar el meta.json del módulo para incluir css_assets o js_assets"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpot no minimiza los archivos JavaScript o CSS que se cargan en el administrador de archivos o a los que se hace referencia a través de enlaces externos. Debes asegurarte de que estos archivos estén minimizados antes de cargarlos."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cada vez que actualices un archivo JavaScript o CSS en el administrador de diseño o a través de la carga local, HubSpot lo volverá a minimizar automáticamente. Esto puede provocar un breve retraso antes de que veas la versión ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".min.js"
        }), " de tu archivo que se está publicando en páginas en vivo. Durante ese período, HubSpot ofrecerá la versión sin minimizar para garantizar que los visitantes del sitio sigan recibiendo la última versión de tus archivos."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los errores de sintaxis pueden impedir que HubSpot pueda minimizar un archivo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de la minimización, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "usar HubL includes para combinar múltiples archivos CSS en un solo archivo"
      }), " para aumentar aún más el rendimiento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Almacenamiento en caché del navegador y del servidor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot almacena automáticamente en caché las páginas y los archivos tanto a nivel de servidor como de navegador para garantizar la entrega más rápida de todos los activos de página a los visitantes de tu sitio web. Cuando tu página o cualquier dependencia de tu página (como una plantilla o módulo) cambia, caducamos automáticamente las cachés de servidor para esa página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reescritura de dominios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada dominio adicional utilizado en tu sitio web incurre en una búsqueda y conexión DNS adicional. Cuantos menos dominios uses, más rápido se cargará tu sitio web. HTTP/2 admite la carga de varios archivos simultáneamente en la misma conexión, por lo que ya no se aplican las antiguas pautas para \"fragmentar\" tus activos entre múltiples dominios."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las URL de los activos a los que se hace referencia en las páginas de CMS, como los archivos del sistema de archivos del desarrollador, CSS, JS e imágenes, se vuelven a escribir automáticamente para que coincidan con el dominio de la página actual cuando sea posible. Por lo tanto, si haces referencia a una imagen en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg",
        children: "http://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg"
      }), " en una página publicada en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/",
        children: "www.hubspot.com"
      }), ", la URL se actualizará automáticamente a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg",
        children: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compresión de texto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los archivos basados en texto como HTML, CSS y JS se comprimen usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/brotli",
        children: "brotli"
      }), " antes de que se sirvan a los navegadores. La compresión con brotli es aún más significativa que GZIP. Si el cliente no indica que se admite la compresión de Brotli, se aplicará la compresión de gzip."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si bien la minimización acelera el tiempo de análisis de los archivos CSS y JS en el navegador, la compresión lleva esos archivos al navegador más rápido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compresión/optimización de imágenes y redimensionamiento automático de imágenes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando cargas una imagen al administrador de archivos, las imágenes se optimizan automáticamente. Específicamente, los JPEG y los PNG son despojados de sus metadatos (EXIF y otras cosas que hacen que las imágenes sean más grandes). Todas las imágenes (excepto los archivos GIF) también se vuelven a comprimir para que no tengan pérdidas visuales. Además, podemos servir imágenes en una codificación diferente si se puede representar como un PNG más pequeño que un JPEG."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Las imágenes se vuelven a guardar a 72dpi independientemente de su resolución original."
      }), " Si subes un archivo a 300dpi, creado originalmente para imprimir, se convertirá a 72dpi, que es el estándar para la web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Progresivamente mejoramos las imágenes para usar el formato WebP para los navegadores que lo admiten."
      }), " Las imágenes se mostrarán en formato WebP si el tamaño del archivo de esa versión de la imagen es menor. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Esta conversión se realiza del lado del servidor y no cambia la extensión del archivo en la URL"
      }), ". Una imagen cargada como un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), ", todavía se mostrará como un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " en la URL, pero se servirá como una WebP. Esto garantiza que los enlaces a esta imagen funcionen para todos, independientemente de la compatibilidad con WebP."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Las imágenes alojadas en HubSpot colocadas en el contenido de CMS serán redimensionadas automáticamente por HubSpot"
      }), ", evitando que el navegador gaste recursos para hacerlo. Para lograr esto, HubSpot agrega parámetros de consulta de altura y/o ancho a la URL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " de cualquier imagen que tenga un atributo de ancho o altura. Nota: el cambio de tamaño de la imagen tarda menos de un minuto, si se solicita una página antes de cambiar el tamaño de la imagen, la imagen no redimensionada se mostrará para esa solicitud. Los navegadores tienen varias opciones para que la resolución de la imagen se cargue, por lo que tus imágenes se verán nítidas en pantallas estándar y de alta resolución."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La optimización/compresión de imágenes y el redimensionamiento automático de imágenes están habilitados por opción predeterminada para todas las páginas y todos los archivos alojados en HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/img_opt.png",
        alt: "Captura de pantalla del elemento img con srcset agregado automáticamente con diferentes URL de tamaño"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para archivos jpg individuales si la url de la imagen tiene el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "quality=high"
        }), ", la imagen no se comprimirá."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los desarrolladores que se basan en el CMS de HubSpot también pueden proporcionarle contexto adicional a las imágenes para controlar aún más el cambio de tamaño de la imagen. Esto se hace a través de la función ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#resize-image-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url()"
        })
      }), ". Esto permite a los desarrolladores construir barandillas en los módulos para evitar que los creadores de contenido muestren imágenes de gran tamaño en páginas y correos electrónicos. La función también puede ser buena para situaciones en las que el tamaño de una imagen no está dictado por los atributos de altura y ancho dentro del HTML, como una imagen de fondo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Páginas móviles aceleradas (AMP)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["AMP, o páginas móviles aceleradas, es un formato de página específico para dispositivos móviles que carga contenido casi instantáneamente. AMP se puede habilitar en las publicaciones del blog alojado en HubSpot con solo tocar un interruptor. Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "Cómo usar páginas móviles aceleradas (AMP) en HubSpot"
      }), " para obtener más detalles."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}