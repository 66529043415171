"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 64443492104;
const slug = exports.slug = 'guides/crm/developer-projects/project-cli-commands';
const title = exports.title = 'Developer projects CLI reference (BETA)';
const name = exports.name = 'Developer projects CLI commands (BETA)';
const metaDescription = exports.metaDescription = 'Learn about project-specific CLI commands.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "update-the-cli",
  "label": "Update the CLI",
  "parent": null
}, {
  "depth": 0,
  "id": "view-all-commands",
  "label": "View all commands",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-new-project",
  "label": "Create a new project",
  "parent": null
}, {
  "depth": 0,
  "id": "upload-to-hubspot",
  "label": "Upload to HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "deploy-to-hubspot",
  "label": "Deploy to HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "migrate-a-public-app",
  "label": "Migrate a public app",
  "parent": null
}, {
  "depth": 0,
  "id": "clone-a-public-app",
  "label": "Clone a public app",
  "parent": null
}, {
  "depth": 0,
  "id": "start-a-local-development-server",
  "label": "Start a local development server",
  "parent": null
}, {
  "depth": 0,
  "id": "open-project-in-hubspot",
  "label": "Open project in HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "watch-for-changes",
  "label": "Watch for changes",
  "parent": null
}, {
  "depth": 0,
  "id": "view-logs",
  "label": "View logs",
  "parent": null
}, {
  "depth": 0,
  "id": "sandbox-commands",
  "label": "Sandbox commands",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-sandbox",
  "label": "Create a sandbox",
  "parent": "sandbox-commands"
}, {
  "depth": 1,
  "id": "delete-a-sandbox",
  "label": "Delete a sandbox",
  "parent": "sandbox-commands"
}, {
  "depth": 1,
  "id": "sync",
  "label": "Sync",
  "parent": "sandbox-commands"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Developer projects CLI commands (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot command line interface (CLI) connects your local development tools to HubSpot, allowing you to develop on HubSpot with version control, your favorite text editor, and various web development technologies."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn about the CLI commands available while you're developing with HubSpot projects. You can also refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "standard CLI commands"
      }), " reference for general commands such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs auth"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project --help"
        }), " at any time in your terminal to learn more about project-specific commands."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update the CLI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Update your CLI to the latest version."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli@latest\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View all commands"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "List all project-specific CLI commands."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project help\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To learn more about a specific command, enter the command followed by ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--help"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a new project"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Create a project in a specified directory. You'll be prompted to give the project a name, as well as confirm the local location. You'll then select whether to start the project from scratch or from a sample template."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A new folder will be created in the specified directory containing an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsproject.json"
      }), " file and an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " folder where you'll build out your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "project components"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've created a project, you can run other project commands inside your project directory and HubSpot will automatically recognize your project."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project create\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Upload to HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Upload the project to your HubSpot account and create a build. If the project hasn't been created in the account yet, you'll be asked whether you want to create it."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If the project is configured to auto-deploy, this command will automatically deploy after the build is successful. By default, new projects are set to auto-deploy."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can upload a project to a specific account in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " file by adding ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--account=accountName"
      }), " to the command. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload --account=main"
      }), ". This can be useful when switching between uploading to a sandbox account and then uploading to the main account. For example, your workflow might look like:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When developing your project in a sandbox, you upload changes with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload--account=sandbox"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Then when uploading the project to a main account, you upload the project with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload--account=main"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the same configuration when using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#watch-for-changes",
        children: "watch"
      }), " command."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deploy to HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Manually deploy the most recent build if the project is not set to auto-deploy."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project deploy\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrate a public app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Migrate a public app to the developer projects framework. Learn more in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/migrate-a-public-app-to-projects",
        children: "public app migration guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project migrate-app\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clone a public app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Create a copy of an existing public app that hasn't been migrated to the projects framework."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project clone-app\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can deploy any build by adding ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--buildId=buildID"
      }), ". For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project deploy --buildId=123"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Start a local development server"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Start a local development server to view extension changes in the browser without needing to refresh. With the server running, saving changes to your extension's front end files and serverless function files will cause the extension to automatically refresh. This does not include changes made to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".json"
      }), " config files, which need to be manually uploaded instead."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All CRM object definitions will be copied from production to development sandbox. You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/import-and-export/import-objects",
        children: "import tool"
      }), " to import production object record data, or manually create sample data for testing."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When a project has multiple extensions, you'll be prompted to select which extensions to run. You can run multiple extensions from the same app, but not multiple extensions across multiple apps."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project dev\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Open project in HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Opens the project in HubSpot where you can view the project's settings, build history, and more. By default, will attempt to open the project in the default account set in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), ". Specify an account by adding the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--account=accountName"
      }), " flag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project open\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Watch for changes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Watches the project directory and uploads to HubSpot upon saving, including deleting files. Each upload will result in a new build with a new build ID. A successful build will deploy automatically if the project’s ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project#builds-and-deploying",
        children: "auto-deploy setting"
      }), " is turned on."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project watch\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can further configure watch to send changes to a specific account with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "---account=accountName"
      }), ". For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project watch --account=main"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View logs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Get logs for a specific function within a project."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project logs\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Running this command will guide you through selecting the project, app, and serverless function to get logs for. However, you can also manually specify this information by including the following flags in the command:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Flag"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--project=projectName"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The name of the project as set in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hsproject.json"
            }), " file."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--app=appName"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The name of the app as set in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "app.json"
            }), " file."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--function=functionName"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For app functions, the name of the serverless function as set in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " file."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--endpoint"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "For endpoint functions, the public endpoint path."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sandbox commands"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Interact with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-standard-sandbox-account",
        children: "standard sandboxes"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup#create-and-use-development-sandboxes",
        children: "development sandboxes"
      }), " using the commands below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a sandbox"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Creates a new sandbox in a production account. When running this command, you can select whether you want to create a standard sandbox or a development sandbox."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If creating a standard sandbox, when running this command, all supported assets will be synced from production to the standard sandbox by default. You can choose to trigger a one-time sync of the last updated 5,000 contacts and, if applicable, up to 100 associated companies, deals, and tickets (for each associated object type)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A production account can have one standard sandbox and two development sandboxes at a time. Additional standard sandboxes can be purchased as an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#Addons",
        children: "add-on"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup#create-and-use-development-sandboxes",
        children: "development sandbox limits"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs sandbox create\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete a sandbox"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Deletes a sandbox connected to the production account. Follow the prompts to select the sandbox account to delete, then confirm the permanent deletion."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs sandbox delete\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sync"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sunset on September 10th, 2024. Use the HubSpot UI to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account-management/sync-automated-emails-forms-lists-and-workflows-to-a-standard-sandbox-account",
        children: "sync supported assets from production to standard sandbox"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs sandbox sync #sunset on September 10th, 2024\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}