"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76425848694;
const slug = exports.slug = 'guides/apps/private-apps/overview';
const title = exports.title = 'Applications privées';
const name = exports.name = 'EMEA (FR) Private Apps';
const metaDescription = exports.metaDescription = 'Découvrez comment créer des applications privées dans HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-une-application-priv%C3%A9e",
  "label": "Créer une application privée",
  "parent": null
}, {
  "depth": 0,
  "id": "passer-des-appels-d-api-avec-le-jeton-d-acc%C3%A8s-de-votre-application",
  "label": "Passer des appels d'API avec le jeton d'accès de votre application",
  "parent": null
}, {
  "depth": 0,
  "id": "afficher-des-informations-sur-le-jeton-d-acc%C3%A8s-de-l-application-priv%C3%A9e",
  "label": "Afficher des informations sur le jeton d'accès de l'application privée",
  "parent": null
}, {
  "depth": 0,
  "id": "actualiser-votre-jeton-d-acc%C3%A8s",
  "label": "Actualiser votre jeton d'accès",
  "parent": null
}, {
  "depth": 0,
  "id": "afficher-les-journaux-d-appels-d-api",
  "label": "Afficher les journaux d'appels d'API",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-d-utilisation-concernant-les-applications-priv%C3%A9es",
  "label": "Limites d'utilisation concernant les applications privées",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-une-application-priv%C3%A9e",
  "label": "Supprimer une application privée",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les applications privées vous permettent d'utiliser les API de HubSpot afin d'accéder à des données spécifiques depuis votre compte HubSpot. Vous pouvez autoriser ce que chaque application privée peut demander ou modifier dans votre compte, ce qui générera un jeton d'accès unique pour votre application."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devez être un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "super administrateur"
      }), " pour accéder aux applications privées dans votre compte HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les applications privées ne prennent pas en charge les événements de chronologie personnalisés. Les webhooks sont ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "pris en charge dans les applications privées"
        }), ", mais les abonnements ne peuvent pas être modifiés par programmation via une API, mais plutôt dans les paramètres de votre application privée."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si vous prévoyez de créer une application à l'aide d'événements de chronologie personnalisés, vous devrez créer une application publique à la place. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "différences entre applications privées et publiques"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer une application privée"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "l'icône Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intégrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications privées"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer une application privée"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informations de base"
        }), ", configurez les détails de votre application :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Saisissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "nom"
            }), " de votre application."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Passez le curseur de la souris sur le logo de variable et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "l'icône de téléchargement"
            }), " pour télécharger une image carrée qui servira de logo pour votre application."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Saisissez une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), " pour votre application."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Domaines"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez la case à cocher ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lecture"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Écriture"
        }), " pour chaque domaine auquel vous souhaitez que votre application privée puisse accéder. Vous pouvez également rechercher un périmètre spécifique en utilisant la barre de recherche ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Trouver un domaine"
        }), ". Vous pouvez consulter une liste complète des domaines disponibles dans ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/other-resources/scopes",
          children: "cet article de référence"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lorsque vous avez terminé de configurer votre application, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer une application"
        }), " dans l'angle supérieur droit."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/updated-read-write-scope-config-private-apps.png",
        alt: "updated-read-write-scope-config-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, vérifiez les informations sur le jeton d'accès de votre application, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continuer de créer"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois votre application créée, vous pouvez commencer à passer des appels d'API à l'aide du jeton d'accès de l'application. Si vous devez modifier les informations de votre application ou en modifier les domaines, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Modifier les détails"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/edit-details-of-private-app.png",
        alt: "edit-details-of-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Passer des appels d'API avec le jeton d'accès de votre application"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les applications privées perdront l'accès aux périmètres lorsque votre compte HubSpot sera déclassé et perdront l'accès aux fonctionnalités. Par exemple, si votre compte n'a pas accès à HubDB, votre application privée n'aura pas accès au périmètre HubDB."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Les jetons d'accès aux applications privées seront mis à jour pour refléter les périmètres disponibles dans votre compte HubSpot et ce que vous avez configuré pour l'application privée, mais la chaîne de jetons elle-même ne changera pas."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour passer des appels d'API, accédez à la page de détails de votre application."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur la carte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Jeton"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "d'accès"
        }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Afficher le jeton"
        }), " pour afficher votre jeton d'accès. Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copier"
        }), " pour copier le jeton dans le presse-papiers."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
        alt: "show-private-app-access-token-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous pouvez ensuite coller le jeton d'accès pour le fournir à vos développeurs ou l'utiliser dans le cadre du développement de votre application. Lorsque vous effectuez un appel vers l'un des points de terminaison de l'API HubSpot, définissez la valeur du champ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Autorisation"
        }), " sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bearer [VOTRE_JETON]"
        }), " Par exemple, si vous effectuez un appel vers l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "API Contacts"
        }), " via Node.js et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", la demande ressemblera à ceci :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les jetons d'accès des applications privées sont implémentés sur OAuth. Ainsi, vous pouvez également effectuer des appels authentifiés avec votre jeton d'accès à l'aide de l'une des bibliothèques clientes de HubSpot. Par exemple, si vous utilisez la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/hubspot-api-nodejs",
          children: "bibliothèque cliente Node.js"
        }), ", vous pouvez instancier un client OAuth en transmettant le jeton d'accès de votre application :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Afficher des informations sur le jeton d'accès de l'application privée"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher des informations sur le jeton d'accès d'une application privée, comme le HubID et les domaines associés, faites une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v2/private-apps/get/access-token-info"
      }), ". Dans le corps de la demande, incluez votre jeton d'accès :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request response body\n{\n  \"tokenKey\": {{accessToken}}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La réponse comprendra des informations sur l'utilisateur qui a créé le jeton, le HubID du compte, l'ID de l'application privée et les domaines associés au jeton."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response body\n{\n  \"userId\": 123456,\n  \"hubId\": 1020304,\n  \"appId\": 2011410,\n  \"scopes\": [\"oauth\", \"crm.schemas.companies.write\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualiser votre jeton d'accès"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre jeton d'accès est perdu ou corrompu, vous pouvez l'actualiser. Un nouveau jeton d'accès sera créé et le jeton d'accès initial expirera."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur la page ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intégrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications privées"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de votre application privée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À côté de votre jeton d'accès, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actualiser"
        }), " :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si votre jeton est corrompu et que vous souhaitez révoquer immédiatement l'accès, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Actualiser et expirer maintenant"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["S'il n'y a pas de menace imminente quant à votre jeton, il est toujours recommandé d'assurer la rotation de votre jeton tous les six mois. Si vous souhaitez lancer une rotation régulière de votre jeton, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Actualiser et expirer ultérieurement"
            }), ", ce qui déclenchera une expiration du jeton dans 7 jours.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Si votre application est prête à effectuer une transition plus tôt, vous pouvez cliquer sur ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Faire expirer maintenant"
                }), "."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Si vous avez besoin de plus de temps, vous pouvez cliquer sur ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Annuler la rotation"
                }), ", ce qui annulera l'expiration du jeton initial et révoquera le nouveau jeton d'accès."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/rotate-private-app-access-token.png",
        alt: "rotate-private-app-access-token"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot enverra également des notifications par e-mail aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "super administrateurs"
      }), " avec des rappels sur l'état de rotation des jetons d'accès ainsi que d'autres alertes connexes. Les super administrateurs de votre compte HubSpot recevront des notifications pour les événements et rappels suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un super administrateur a initié une rotation (soit immédiatement, soit planifiée pour 7 jours à partir de maintenant)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un super administrateur a annulé une rotation en attente."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un super administrateur a choisi d'expirer immédiatement un jeton d'accès en cliquant sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Expirer maintenant"
        }), " au lieu d'attendre 7 jours pour que le jeton expire."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le jeton d'accès de l'application va expirer dans 24 heures."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le jeton d'accès de l'application a fait l'objet d'une rotation et a expiré au bout de 7 jours."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous n'avez pas fait pivoter votre jeton d'accès depuis plus de 180 jours, les super administrateurs recevront également un e-mail de rappel pour faire pivoter le jeton d'accès de votre application."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Afficher les journaux d'appels d'API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour consulter les appels d'API que votre application a effectués au cours des 30 derniers jours :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur la page de détails de votre application, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Journaux"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Analysez et filtrez vos appels d'API pour votre application privée :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Cliquez sur les menus déroulants ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Méthode"
            }), " et ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Réponse"
            }), " pour filtrer vos appels d'API passés par méthode de demande ou par code de réponse."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Cliquez sur les menus déroulants ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Date de début"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Date de fin"
            }), " pour filtrer vos journaux d'appels selon une période spécifique."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Vous pouvez également rechercher des appels spécifiques par URL dans le champ ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Rechercher par l'URL de demande"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/private-app-logs-tab-updated.png",
        alt: "private-app-logs-tab-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour exporter les journaux d'appels de l'API, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exporter des journaux (CSV)"
        }), ". Spécifiez ensuite une ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "plage de dates"
        }), " (jusqu'à 30 jours auparavant) et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exporter"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte contextuelle, sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "plage de dates"
        }), " des appels d'API que vous souhaitez exporter et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exporter"
        }), ". Vous recevrez un e-mail contenant un lien de téléchargement lorsque le fichier sera prêt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'utilisation concernant les applications privées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez créer jusqu'à 20 applications privées dans votre compte HubSpot. Chaque application privée est soumise aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "directives sur l'utilisation des API HubSpot"
      }), ". Le nombre d'appels que votre application privée peut effectuer dépend de l'abonnement de votre compte et de l'achat du module complémentaire API :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Niveau du produit"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Toutes les 10 secondes"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Par jour"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Applications privées"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Tout abonnement Hub)Gratuit et Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100/application privée"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250 000/compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Tout abonnement Hub)Pro et Entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150/application privée"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500 000/compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Applications privées avec le module complémentaire API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Tout abonnement Hub)Gratuit, Starter, Pro et Entreprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200/application privée"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1 000 000/compte"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous disposez d'abonnements Starter et Pro, les limites pour le niveau le plus élevé (Pro) s'appliquent à votre compte."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez effectuer une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/account-info/v3/api-usage/daily/private-apps"
      }), " pour consulter l'utilisation quotidienne de l'API pour toutes les applications privées de votre compte HubSpot. Découvrez-en davantage sur l'utilisation de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "API d'informations de compte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer une application privée"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous supprimez une application privée, son jeton d'accès sera définitivement révoqué et vous ne pourrez plus l'utiliser pour effectuer des appels d'API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour supprimer une application :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "l'icône Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de votre application privée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En haut de la page, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentification"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Au bas de la page, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Supprimer [Nom de votre application]"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, saisissez le nom de votre application pour confirmer sa suppression, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Supprimer"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}