"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501958307;
const slug = exports.slug = 'reference/api/deprecated';
const title = exports.title = 'APIs da HubSpot | APIs obsoletas';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Uma lista de APIs da HubSpot obsoletas';
const toc = exports.toc = [{
  "depth": 0,
  "id": "apis-encerradas",
  "label": "APIs encerradas",
  "parent": null
}, {
  "depth": 0,
  "id": "apis-obsoletas",
  "label": "APIs obsoletas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      br: "br",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "APIs encerradas e obsoletas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As APIs listadas nas seções abaixo ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " receberão futuras atualizações de funcionalidade e, em alguns casos, serão totalmente desativadas e ficarão indisponíveis para uso nas suas integrações."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["É altamente recomendável que você se inscreva na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/changelog",
        children: "Changelog do Desenvolvedor da HubSpot"
      }), " para acompanhar as atualizações mais recentes, alterações de interrupção e outras alterações significativas na funcionalidade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIs encerradas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As seguintes APIs foram totalmente removidas e retornarão um erro ao fazer uma chamada aos pontos de extremidade associados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A partir de 20 de novembro de 2022, as chaves de API serão suspensas como método de autenticação. Saiba mais sobre essa alteração ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/changelog/upcoming-api-key-sunset",
          children: "neste anúncio de changelog"
        }), " e como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/api/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar uma integração de chave de API para usar um aplicativo privado"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A partir de 28 de fevereiro de 2023, as seguintes APIs não estarão mais disponíveis:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/marketing/ecomm-bridge",
              children: "APIs de ponte de comércio eletrônico"
            }), ": se você já criou uma integração usando essas APIs, pode seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "este guia de migração"
            }), " para alternar sua integração para usar aplicativos privados."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/crm/extensions/accounting",
              children: "APIs de extensão de contabilidade"
            }), ": se você criou anteriormente uma integração usando essas APIs, poderá seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/migrate-an-existing-account-extension-integration",
              children: "este guia de migração"
            }), " para alternar sua integração para usar aplicativos privados.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A partir de 31 de agosto de 2023, as APIs de calendário não estarão mais disponíveis. Isso inclui os seguintes endpoints:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Listar eventos de conteúdo"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Listar eventos sociais"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Listar eventos de tarefa de calendário"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Listar todos os eventos do calendário"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Criar tarefa de calendário"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Obter tarefa de calendário por ID"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Atualizar tarefa de calendário"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Excluir uma tarefa de calendário"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "APIs obsoletas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os pontos de extremidade antigos listados abaixo ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " receberão uma atualização de versão. Esses endpoints são funcionais e estáveis, mas não serão atualizados além da versão atual. A HubSpot continuará a oferecer suporte para eles no futuro próximo e anunciará qualquer alteração futura com um aviso amplo na nossa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/changelog",
        children: "Changelog do Desenvolvedor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "API de mídias sociais:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
              children: "Obter canais de publicação"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
              children: "Obter mensagens de transmissão"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
              children: "Obter uma mensagem de transmissão"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
              children: "Criar uma mensagem de transmissão"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
              children: "Cancelar uma mensagem de transmissão"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}