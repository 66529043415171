"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501378082;
const slug = exports.slug = 'guides/api/analytics-and-events/event-analytics';
const title = exports.title = "API d'événements | Analytics d'événement";
const name = exports.name = 'EMEA (FR) vNext Docs DP - Web Analytics';
const metaDescription = exports.metaDescription = "Le point de terminaison des analytics d'événement vous permet de trouver et de filtrer des événements associés à un objet de CRM de tout type. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "interroger-les-finalisations-d-%C3%A9v%C3%A9nements-individuels",
  "label": "Interroger les finalisations d'événements individuels",
  "parent": null
}, {
  "depth": 1,
  "id": "s%C3%A9lection-selon-l-id-d-objet",
  "label": "Sélection selon l'ID d'objet",
  "parent": "interroger-les-finalisations-d-%C3%A9v%C3%A9nements-individuels"
}, {
  "depth": 1,
  "id": "s%C3%A9lection-selon-une-propri%C3%A9t%C3%A9-d-objet",
  "label": "Sélection selon une propriété d'objet",
  "parent": "interroger-les-finalisations-d-%C3%A9v%C3%A9nements-individuels"
}, {
  "depth": 0,
  "id": "interrogation-et-filtrage-des-types-d-%C3%A9v%C3%A9nement",
  "label": "Interrogation et filtrage des types d'événement",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Analytics d'événement"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'API d'analytics d'événement pour rechercher des événements associés à tout type de fiche d'informations de CRM (", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), " uniquement). Cela inclut des événements standard, tels que les vues de page de site web et les ouvertures d'e-mails, ainsi que les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "événements personnalisés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, utilisez cette API pour afficher une chronologie des interactions d'un contact avec votre contenu. Vous pouvez ensuite utiliser cette chronologie pour créer un ensemble de données pour des analytics personnalisés ou présenter une chronologie de contact dans une application externe."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Interroger les finalisations d'événements individuels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette API renvoie des événements pour une fiche d'informations de CRM à la fois. Vous pouvez sélectionner la fiche d'informations en précisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " et en incluant le paramètre de demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sélection selon l'ID d'objet"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour spécifier une fiche d'informations selon son identifiant, ajoutez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Par exemple, pour spécifier une fiche d'informations de contact avec l'ID ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "2832"
      }), ", vous pouvez effectuer la demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " suivante :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectId=224834"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sélection selon une propriété d'objet"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour spécifier une fiche d'informations selon une propriété unique plutôt que par l'ID de contact, ajoutez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), ". Indiquez la propriété en incluant le nom de la propriété et la valeur du format suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty.{propname}={propvalue}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, pour spécifier un contact selon son adresse e-mail, vous pouvez effectuer la demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " suivante :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectProperty.email=user12@dev.com"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Interrogation et filtrage des types d'événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de la demande d'événements associés à un objet de CRM donné, la réponse comprendra tous les types d'événement, y compris les événements comportementaux personnalisés."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour renvoyer uniquement les événements terminés pour un type d'événement spécifique, vous pouvez inclure un paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), ", suivi du nom de l'événement. Pour obtenir une liste de tous les types d'événement disponibles, vous pouvez faire une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/event-types"
      }), ". La réponse renverra tous les types d'événement dans votre compte. Vous pourrez ensuite utiliser l'un de ces noms d'événement comme paramètre de requête dans une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " vers le point de terminaison ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId=224834"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}