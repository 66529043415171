"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280249499;
const slug = exports.slug = 'guides/api/app-management/webhooks/overview';
const title = exports.title = 'API Webhooks ';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Webhooks API';
const metaDescription = exports.metaDescription = "Présentation de l'API Webhooks, qui vous permet de vous abonner à des événements qui se produisent dans un compte HubSpot sur lequel est installée votre intégration.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "p%C3%A9rim%C3%A8tres-d-acc%C3%A8s",
  "label": "Périmètres d'accès",
  "parent": null
}, {
  "depth": 0,
  "id": "param%C3%A8tres-de-webhook",
  "label": "Paramètres de webhook",
  "parent": null
}, {
  "depth": 1,
  "id": "g%C3%A9rer-les-param%C3%A8tres-de-votre-compte-de-d%C3%A9veloppeur",
  "label": "Gérer les paramètres de votre compte de développeur",
  "parent": "param%C3%A8tres-de-webhook"
}, {
  "depth": 1,
  "id": "g%C3%A9rer-les-param%C3%A8tres-via-l-api",
  "label": "Gérer les paramètres via l'API",
  "parent": "param%C3%A8tres-de-webhook"
}, {
  "depth": 0,
  "id": "abonnements-de-webhook",
  "label": "Abonnements de webhook",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-des-abonnements-dans-votre-compte-de-d%C3%A9veloppeur",
  "label": "Créer des abonnements dans votre compte de développeur",
  "parent": "abonnements-de-webhook"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-des-abonnements-via-l-api",
  "label": "Créer des abonnements via l'API",
  "parent": "abonnements-de-webhook"
}, {
  "depth": 1,
  "id": "obtenir-des-abonnements",
  "label": "Obtenir des abonnements",
  "parent": "abonnements-de-webhook"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-un-nouvel-abonnement",
  "label": "Créer un nouvel abonnement",
  "parent": "abonnements-de-webhook"
}, {
  "depth": 1,
  "id": "mettre-%C3%A0-jour-un-abonnement",
  "label": "Mettre à jour un abonnement",
  "parent": "abonnements-de-webhook"
}, {
  "depth": 1,
  "id": "supprimer-un-abonnement",
  "label": "Supprimer un abonnement",
  "parent": "abonnements-de-webhook"
}, {
  "depth": 0,
  "id": "charges-utiles-de-webhook",
  "label": "Charges utiles de webhook",
  "parent": null
}, {
  "depth": 0,
  "id": "suppression-de-contacts-conform%C3%A9ment-%C3%A0-la-confidentialit%C3%A9",
  "label": "Suppression de contacts conformément à la confidentialité",
  "parent": null
}, {
  "depth": 0,
  "id": "s%C3%A9curit%C3%A9",
  "label": "Sécurité",
  "parent": null
}, {
  "depth": 0,
  "id": "nouvelles-tentatives",
  "label": "Nouvelles tentatives",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-d-utilisation",
  "label": "Limites d'utilisation",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Webhooks"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'API Webhooks vous permet de vous abonner à des événements qui se produisent dans un compte HubSpot sur lequel est installée votre intégration. Plutôt que de passer un appel d'API lorsqu'un événement se produit dans un compte connecté, HubSpot peut envoyer une demande HTTP à un point de terminaison que vous configurez. Vous pouvez configurer des événements abonnés dans les paramètres de votre application ou à l'aide des points de terminaison ci-dessous. Les webhooks peuvent être plus évolutifs que des sondages réguliers concernant des modifications, notamment pour les applications massivement installées."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'utilisation de l'API Webhooks nécessite les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous devez configurer une application HubSpot pour utiliser les webhooks en vous abonnant aux événements pour lesquels vous souhaitez recevoir des notifications et en précisant une URL pour l'envoi de celles-ci. Consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/integration-platform-api-requirements",
          children: "documentation relative aux prérequis"
        }), " pour en savoir plus sur la création d'une application."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous devez déployer un point de terminaison publiquement accessible et sécurisé (HTTPS) pour cette URL, capable de gérer les charges utiles pertinentes du webhook spécifiées dans cette documentation."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les webhooks sont configurés pour une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "application HubSpot"
      }), ", et non pour des comptes individuels. Tous les comptes qui installent votre application (par le biais du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "flux OAuth"
      }), ") seront abonnés à ses abonnements de webhook."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez vous abonner à des événements d'objet CRM, qui comprennent des contacts, des entreprises, des transactions, des tickets, des produits et des lignes de produit ainsi qu'à des événements de conversations."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
            children: "gérer les webhooks dans une application privée"
          }), ". Dans les applications privées, les paramètres de webhook ne peuvent être modifiés que dans les paramètres de l'application privée et ne peuvent pas être modifiés via l'API."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Pour vous abonner aux webhooks de conversations, vous devez accéder à la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/inbox-and-messages",
            children: "boîte de réception des conversations et aux API de messages"
          }), ", qui sont actuellement ", (0, _jsxRuntime.jsx)("u", {
            children: "en version bêta"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Périmètres d'accès"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour utiliser les webhooks afin de vous abonner à des événements CRM, votre application devra être configurée pour exiger le domaine associé qui correspond au type d'objet CRM auquel vous voulez vous abonner. Par exemple, si vous souhaitez vous abonner à des événements de contact, vous devez demander le domaine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm.objects.contacts.read"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous créez des abonnements dans les paramètres de l'interface utilisateur de votre application publique, vous serez invité à ajouter le domaine requis dans le panneau ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Créer de nouveaux abonnements webhook"
        }), " avant de terminer la création de votre abonnement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous créez un abonnement en faisant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " au point de terminaison ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/webhooks/v3/{appId}/subscriptions"
        }), ", la réponse inclura une erreur qui fournira le nom du domaine que vous devrez configurer dans les paramètres de l'interface utilisateur de votre application publique."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si votre application utilise déjà des webhooks, vous ne pourrez pas supprimer les domaines requis par les abonnements webhook actifs sans d'abord mettre en pause et supprimer les abonnements."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous pouvez consulter les domaines requis pour chaque type d'abonnement webhook dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#webhook-subscriptions",
          children: "tableau ci-dessous."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consultez la documentation OAuth pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0#scopes",
        children: "plus de détails sur les domaines"
      }), " et la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "configuration de l'URL d'autorisation"
      }), " pour votre application."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres de webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de configurer des abonnements de webhook, vous devez spécifier une URL à laquelle envoyer ces notifications. Suivez les instructions dans les sections ci-dessous pour savoir comment configurer entièrement les abonnements pour votre application."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Les paramètres de webhook peuvent être mis en cache jusqu'à cinq minutes. L'application des modifications de l'URL du webhook, des limites de simultanéité ou des paramètres d'abonnement peut prendre jusqu'à cinq minutes."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "HubSpot définit une limite de concomitance de 10 demandes lors de l'envoi de données d'événement d'abonnement associées à un compte qui a installé votre application. Cette limite de concomitance est le nombre maximum de demandes en cours que HubSpot tentera à la fois. Chaque demande peut contenir jusqu'à 100 événements."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gérer les paramètres de votre compte de développeur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez gérer vos paramètres d'URL et de limitation d'événement via la page de configuration de votre application dans votre compte de développeur :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte de développeur, accédez à votre tableau de bord ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Applications"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom"
          }), " de l'application pour laquelle vous souhaitez configurer des webhooks."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/app_id_list.png?width=600&name=app_id_list.png",
            alt: "app_id_list"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Webhooks"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le champ ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "URL cible"
          }), ", saisissez l'", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " vers laquelle HubSpot effectuera une demande POST pour le déclenchement des événements."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Utilisez le paramètre ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Limitation d'événement"
          }), " pour ajuster le nombre maximal d'événements que HubSpot essaiera d'envoyer."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_settings.png?width=600&name=webhook_settings.png",
        alt: "webhook_settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enregistrer"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gérer les paramètres via l'API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez utiliser les points de terminaison suivants et votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/how-do-i-get-my-hubspot-api-key",
        children: "clé d'API de développeur"
      }), " pour configurer de façon programmée les paramètres de webhook pour une application."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour afficher tous les paramètres de webhook actuellement configurés pour une application, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devrez inclure l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/how-do-i-find-the-app-id",
        children: "ID de l'application"
      }), " dans la demande, que vous trouverez sous le nom de l'application dans votre tableau de bord ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Applications"
      }), " ou dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "OAuth"
      }), " dans les paramètres de votre application."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les paramètres contiennent les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhookUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'URL à laquelle HubSpot enverra des notifications de webhook. Elle doit être sur un serveur HTTPS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La limite de concomitance pour l'URL du webhook. Cette valeur doit être un nombre supérieur à cinq."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour apporter des modifications à ces paramètres, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), " et incluez les champs suivants dans le corps de la demande :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'URL publique pour que HubSpot appelle l'endroit où les charges utiles de l'événement seront livrées."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "throttling"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configurez les détails de limitation du webhook dans cet objet. L'objet de limitation comprend les champs ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "et maxConcurrentRequests"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Échelle de temps pour ce paramètre. Peut être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SECONDLY"
            }), " (par seconde) ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ROLLING_MINUTE"
            }), " (par minute)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le nombre maximal de demandes HTTP que HubSpot tentera d'effectuer vers votre application dans une période donnée en fonction de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to https://api.hubapi.com/webhooks/v3/{appId}/settings\n\n{\n  \"throttling\": {\n    \"period\": \"SECONDLY\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"targetUrl\": \"https://www.example.com/hubspot/target\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abonnements de webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez configuré l'URL de votre webhook et les limitations d'événement, vous devez créer un ou plusieurs abonnements. Les abonnements de webhook indiquent à HubSpot les événements que votre application spécifique souhaite recevoir."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les abonnements s'appliquent à tous les clients qui ont installé votre intégration. Autrement dit, vous devez spécifier les abonnements dont vous avez besoin une seule fois. Une fois que vous avez activé un abonnement pour une application, il commencera automatiquement à obtenir les webhooks pour tous les clients qui ont installé votre application. Votre intégration commencera à recevoir les déclencheurs de webhook de tous les nouveaux clients."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour tous les abonnements webhook ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationChange"
      }), ", le webhook déclenchera deux événements pour les deux parties de l'association."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lors de l'association de deux fiches d'informations d'un contact, un abonnement à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " déclenchera deux événements, représentant le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 au contact 2"
        }), " et le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 2 au contact 1"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lorsque vous associez une fiche d'informations d'une entreprise, si vous avez deux abonnements webhook ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company.associationChange"
        }), ", vous recevrez deux événements. Ceux-ci représenteront le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 à l'entreprise 1"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "l'entreprise 1 au contact 1"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les types d'abonnement suivants sont pris en charge et peuvent être utilisés comme valeur pour le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " lors de la création d'abonnements via l'API :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'abonnement"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Domaine requis"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un contact est créé dans le compte d'un client."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un contact est supprimé dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un contact est fusionné avec un autre contact."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un contact a une association ajoutée ou supprimée entre lui-même et un autre objet de webhook pris en charge (contact, entreprise, transaction, ticket, ligne ou produit ou produit)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un contact est restauré à partir de la suppression."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Recevez une notification si un contact est supprimé ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "pour des raisons de confidentialité"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une propriété spécifique est modifiée pour un contact dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une entreprise est créée dans le compte d'un client."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une entreprise est supprimée dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une propriété spécifique est modifiée pour une entreprise dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une entreprise a une association ajoutée ou supprimée entre elle-même et un autre objet de webhook pris en charge (contact, entreprise, transaction, ticket, ligne ou produit ou produit)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une entreprise est restaurée à partir de la suppression."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une entreprise est fusionnée avec une autre."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une transaction est créée dans le compte d'un client."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une transaction est supprimée dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une transaction a une association ajoutée ou supprimée entre elle-même et un autre objet de webhook pris en charge (contact, entreprise, transaction, ticket, ligne ou produit ou produit)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une transaction est restaurée à partir de la suppression."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une transaction est fusionnée avec une autre."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une propriété spécifique est modifiée pour une transaction dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un ticket est créé dans le compte d'un client."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un ticket est supprimé dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une propriété spécifique est modifiée pour un ticket dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un ticket a une association ajoutée ou supprimée entre lui-même et un autre objet de webhook pris en charge (contact, entreprise, transaction, ticket, ligne ou produit ou produit)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un ticket est restauré à partir de la suppression."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un ticket est fusionné avec un autre."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un produit est créé dans le compte d'un client."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un produit est supprimé dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un produit est restauré à partir de la suppression."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un produit est fusionné avec un autre."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un produit spécifique est modifié pour un produit dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une ligne de produit est créée dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une ligne de produit est supprimée dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une ligne de produit a une association ajoutée ou supprimée entre elle-même et un autre objet de webhook pris en charge (contact, entreprise, transaction, ticket, ligne ou produit ou produit)."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une ligne de produit est restaurée à partir de la suppression."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une ligne de produit est fusionnée avec une autre."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si une propriété spécifique est modifiée pour une ligne de produit dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les types d'abonnement aux conversations suivants sont disponibles si vous utilisez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "API de boîte de réception de conversations et de messages"
      }), ", qui est actuellement ", (0, _jsxRuntime.jsx)("u", {
        children: "en version bêta"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'abonnement"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Domaine"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un nouveau fil de discussion est créé dans un compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un fil de discussion est archivé ou supprimé de façon réversible d'un compte."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un fil de discussion est définitivement supprimé dans un compte."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si la propriété d'un fil est modifiée."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Recevez une notification si un nouveau message sur un fil de discussion est reçu."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour les abonnements de modification de propriétés, vous devez spécifier la propriété pour laquelle vous souhaitez recevoir des notifications. Vous pouvez spécifier plusieurs abonnements de modification de propriétés. Si le compte d'un client ne dispose pas de la propriété que vous spécifiez dans un abonnement, vous ne recevrez aucun webhook de la part ce client pour cette propriété."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Certaines propriétés ne sont pas disponibles dans le cadre des abonnements de modifications de propriétés CRM. Ces propriétés sont :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "num_unique_conversion_events"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_lastmodifieddate"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous utilisez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "API de boîte de réception de conversations et de messages"
      }), ", qui est actuellement ", (0, _jsxRuntime.jsx)("u", {
        children: "en version bêta"
      }), ", les propriétés suivantes sont disponibles :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "assignedTo"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " le fil de conversation a été réattribué ou désattribué. Si le fil de conversation a été réattribué, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " sera un ID d'acteur dans la charge utile des webhooks. S'il est désattribué, cette valeur sera vide."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " le statut du fil de conversation a changé. Dans la charge utile des webhooks, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " sera ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OPEN"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CLOSED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "isArchived"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " le fil de conversation a été restauré. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " dans la charge utile des webhooks sera toujours ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "FALSE"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer des abonnements dans votre compte de développeur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez créer des abonnements de webhook dans votre compte de développeur HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur HubSpot, accédez au tableau bord ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " d'une application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un abonnement"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le panneau de droite, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quels types d'objets ?"
        }), " et sélectionnez les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objets"
        }), " pour lesquels vous souhaitez créer un abonnement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Écouter quels événements ?"
        }), " et sélectionnez les ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "types d'événements"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-contact-create-subscription.png",
        alt: "create-contact-create-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si vous créez un abonnement pour des événements de modification de propriété, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Quelles propriétés ?"
          }), " et sélectionnez les ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "propriétés"
          }), " à écouter."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_select_properties.png?width=450&name=webhook_select_properties.png",
            alt: ""
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "S'abonner"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'abonnement apparaîtra dans les paramètres des webhooks. Les nouveaux abonnements sont créés avec un statut En pause. Vous devrez activer l'abonnement pour l'envoi de webhooks :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Abonnements aux événements"
        }), ", passez le curseur sur le type d'objet et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Afficher les abonnements"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "case à cocher à"
        }), " côté de l'événement, puis dans l'en-tête du tableau, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activer"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/activate-subscription.png",
        alt: "activate-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer des abonnements via l'API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez créer des abonnements de façon programmée en utilisant les points de terminaison suivants. Vous devrez utiliser votre clé d'API de développeur lors des demandes à ces points de terminaison."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un abonnement peut inclure les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un nombre représentant l'ID unique d'un abonnement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'heure en millisecondes à laquelle cet abonnement a été créé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID d'utilisateur associé à l'utilisateur qui a créé l'abonnement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ce champ indique si l'abonnement est activé ou non et déclenche activement des notifications. La valeur peut être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'abonnement. Le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview#webhook-subscriptions",
              children: "tableau"
            }), " au début de cette section comprend les types d'abonnement disponibles."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de la propriété pour laquelle l'abonnement écoutera les modifications. Cette option n'est nécessaire que pour les types d'abonnement aux modifications de propriété."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtenir des abonnements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer la liste des abonnements, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La réponse sera un ensemble d'objets représentant vos abonnements. Chaque objet inclura des informations sur l'abonnement comme l'ID, la date de création, le type et, le cas échéant, une indication si l'abonnement est actuellement actif. Voici un exemple de réponse :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n[\n  {\n    \"id\": 25,\n    \"createdAt\": 1461704185000,\n    \"createdBy\": 529872,\n    \"eventType\": \"contact.propertyChange\",\n    \"propertyName\": \"lifecyclestage\",\n    \"active\": false\n  },\n  {\n    \"id\": 59,\n    \"createdAt\": 1462388498000,\n    \"createdBy\": 529872,\n    \"eventType\": \"company.creation\",\n    \"active\": false\n  },\n  {\n    \"id\": 108,\n    \"createdAt\": 1463423132000,\n    \"createdBy\": 529872,\n    \"eventType\": \"deal.creation\",\n    \"active\": true\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer un nouvel abonnement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un nouvel abonnement, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le corps de la demande, vous pouvez inclure les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le type d'abonnement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de la propriété pour laquelle l'abonnement écoutera les modifications. Cette option n'est nécessaire que pour les types d'abonnement aux modifications de propriété."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ce champ indique si l'abonnement est activé ou non et déclenche activement des notifications. La valeur peut être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous n'avez pas besoin d'inclure ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdAt"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdBy"
      }), ", car ces champs sont automatiquement définis."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, votre corps de demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n{\n  \"eventType\": \"company.propertyChange\",\n  \"propertyName\": \"companyname\",\n  \"active\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " doit être un type d'abonnement valide tel que défini dans la section ci-dessus et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyName"
      }), " doit être un nom de propriété valide. Si un client n'a aucune propriété définie correspondant à cette valeur, cet abonnement ne déclenchera aucune notification."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mettre à jour un abonnement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour activer ou mettre en pause un abonnement, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le corps de la demande, ajoutez les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ce champ indique si l'abonnement est activé ou non et déclenche activement des notifications. La valeur peut être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer un abonnement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer un abonnement, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Charges utiles de webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le point de terminaison pour l'URL cible que vous spécifiez dans les paramètres de webhook de votre application recevra des demandes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " contenant des données au format JSON de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour vérifier que les demandes que vous obtenez au point de terminaison de webhook proviennent bien de HubSpot, HubSpot renseigne un en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " avec une fonction de hachage SHA-256 composée du secret client de votre application et des détails de la demande. Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validation des signatures des demandes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez les tableaux ci-dessous pour afficher des détails sur les champs pouvant être contenus dans la charge utile."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID de l'objet qui a été créé, modifié ou supprimé. Il s'agit de l'ID du contact pour les contacts, de l'ID de l'entreprise pour les entreprises, de l'ID de transaction pour les transactions et de l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "ID de fil"
            }), " pour les conversations."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ce champ est uniquement envoyé pour les abonnements aux modifications de propriété. Il s'agit du nom de la propriété modifiée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ce champ est uniquement envoyé pour les abonnements aux modifications de propriété et représente la nouvelle valeur définie pour la propriété qui a déclenché la notification."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source de la modification. Cela peut être l'une des sources de modification qui apparaissent dans les historiques des propriétés de contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID unique de l'événement qui a déclenché cette notification. Cette valeur n'est pas forcément unique."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de l'abonnement qui a déclenché une notification sur l'événement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "ID de compte HubSpot"
            }), " du client où l'événement s'est produit."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de votre application Ce champ est utilisé dans le cas où plusieurs applications pointent vers la même URL de webhook."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lorsque cet événement s'est produit, un horodatage en millisecondes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le type d'événement concerné par cette notification. Consultez la liste des types d'abonnement pris en charge dans la section des abonnements aux webhooks."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Numéro de la tentative de notification de votre service concernant cet événement (à partir de 0). Si votre service expire ou renvoie une erreur comme indiqué dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Nouvelles tentatives"
            }), " ci-dessous, HubSpot tentera à nouveau d'envoyer la notification."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ce champ est uniquement envoyé lorsqu'un webhook est à l'écoute de nouveaux messages dans un fil. Il s'agit de l'ID du nouveau message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ce champ est uniquement envoyé lorsqu'un webhook est à l'écoute de nouveaux messages dans un fil. Il représente le type de message que vous envoyez. Cette valeur peut être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID du gagnant de la fusion, qui est la fiche d'informations qui reste après la fusion. Dans l'interface utilisateur de fusion HubSpot, il s'agit de la fiche d'informations sur la droite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mergedObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un tableau des ID qui représentent les fiches d'informations qui sont fusionnées dans le gagnant de la fusion. Dans l'interface utilisateur de fusion HubSpot, il s'agit de la fiche d'informations sur la gauche."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "newObjectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID de la fiche d'informations qui est créée à la suite de la fusion. Celui-ci est distinct de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            }), " car dans certains cas, une nouvelle fiche d'informations est créée à la suite de la fusion."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOfPropertiesMoved"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un entier représentant le nombre de propriétés transférées au cours de la fusion."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association, qui sera l'un des suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_CONTACT"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_COMPANY"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_LINE_ITEM"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TRANSACTION_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_DEAL"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_TICKET"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LIGNE_ITEM_TO_DEAL"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de la fiche d'informations à partir de laquelle la modification d'association a été effectuée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de la fiche d'informations secondaire dans l'événement d'association."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationRemoved"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un booléen qui représente les éléments suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " : le webhook a été déclenché par la suppression d'une association."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " : le webhook a été déclenché par la création d'une association."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isPrimaryAssociation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un booléen qui représente les éléments suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " : la fiche d'informations secondaire est l'association ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/crm-setup/associate-records#primary-company-information",
                  children: "principale"
                }), " de la fiche d'informations à partir de laquelle le changement d'association a été effectué."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " : la fiche d'informations n'est ", (0, _jsxRuntime.jsx)("u", {
                  children: "pas"
                }), " l'association principale de la fiche d'informations à partir de laquelle le changement d'association a été effectué. "]
              })]
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Remarque :"
            }), " La création d'une instance d'association principale entre deux fiches d'informations d'objets entraînera également la création de l'association non principale correspondante. Cela peut entraîner deux messages de webhook."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n[\n  {\n    \"objectId\": 1246965,\n    \"propertyName\": \"lifecyclestage\",\n    \"propertyValue\": \"subscriber\",\n    \"changeSource\": \"ACADEMY\",\n    \"eventId\": 3816279340,\n    \"subscriptionId\": 25,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.propertyChange\",\n    \"attemptNumber\": 0\n  },\n  {\n    \"objectId\": 1246978,\n    \"changeSource\": \"IMPORT\",\n    \"eventId\": 3816279480,\n    \"subscriptionId\": 22,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.creation\",\n    \"attemptNumber\": 0\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Comme indiqué ci-dessus, vous devez vous attendre à recevoir plusieurs objets dans une seule demande. La taille du lot peut varier, mais elle restera inférieure à 100 notifications. HubSpot enverra plusieurs notifications uniquement lorsqu'un grand nombre d'événements se produiront dans un délai court. Par exemple, si vous êtes abonné aux nouveaux contacts et qu'un client importe un grand nombre de contacts, HubSpot vous enverra les notifications pour ces contacts dans des lots et non une par demande."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot ne garantit pas que vous recevrez ces notifications dans l'ordre chronologique. Utilisez la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "occuredAt"
      }), " pour chaque notification pour déterminer quand est survenu l'événement qui a déclenché la notification."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ne garantit pas non plus que vous ne recevrez qu'une seule notification pour un événement. Bien que cela soit rare, il est possible que HubSpot vous envoie plusieurs fois la même notification."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suppression de contacts conformément à la confidentialité"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les utilisateurs HubSpot peuvent supprimer définitivement une fiche d'informations de contact pour répondre à la législation en matière de confidentialité. Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-do-i-perform-a-gdpr-delete-in-hubspot",
        children: "suppression conformément au RGPD"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez vous abonner au type d'abonnement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.privacyDeletion"
      }), " pour recevoir des notifications de webhook lorsqu'un utilisateur effectue une suppression de contact conformément à la confidentialité."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les notifications de suppression conformément à la confidentialité ont un comportement spécial :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un événement de suppression conformément à la confidentialité déclenche également l'événement de suppression du contact. Vous recevrez donc deux notifications si vous êtes abonné aux deux événements."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ces notifications ne seront pas nécessairement envoyées dans le bon ordre ou dans le même lot de messages. Vous devrez utiliser l'ID d'objet pour faire correspondre les différents messages."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sécurité"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour vérifier que les demandes que vous obtenez au point de terminaison de webhook proviennent bien de HubSpot, HubSpot dispose d'un en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " avec une fonction de hachage SHA-256 de concaténation de l'app-secret pour votre application et le corps de demande envoyé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour vérifier cette signature, concaténez le secret de votre application et le corps non analysé de la demande que vous traitez, et obtenez un hachage SHA-256 du résultat. Comparez le hachage résultant avec la valeur de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), ". Si ces valeurs sont identiques, cette demande provient de HubSpot ou la demande provenait d'une autre personne qui connait le secret de votre application (lequel doit rester secret)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si ces valeurs sont différentes, cette demande peut avoir été modifiée en transit ou quelqu'un peut usurper les notifications de webhook à votre point de terminaison."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validation des demandes de signature"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nouvelles tentatives"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre service rencontre des problèmes de traitement des notifications, HubSpot tentera d'envoyer à nouveau les notifications jusqu'à 10 fois."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot procèdera à de nouvelles tentatives dans les cas suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "La connexion a échoué :"
        }), " HubSpot ne parvient pas à ouvrir une connexion HTTP de l'URL de webhook fournie."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Expiration :"
        }), " votre service prend plus de cinq secondes pour renvoyer une réponse à un lot de notifications."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Codes d'erreur :"
        }), " votre service répond avec un code de statut HTTP (4xx ou 5xx)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les notifications seront renvoyées jusqu'à 10 fois. Ces tentatives seront réparties sur les 24 heures suivantes, avec des délais différents entre les demandes. Afin d'éviter la récupération au même moment d'un grand nombre d'échecs concomitants, les notifications individuelles feront l'objet d'une distribution au hasard."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'utilisation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les demandes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " que HubSpot envoie à votre service via vos abonnements de webhook ", (0, _jsxRuntime.jsx)("u", {
        children: "ne seront pas"
      }), " comptabilisées dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "limites de taux d'API de votre application"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez créer un maximum de 1 000 abonnements par application. Si vous tentez d'en créer plus, vous recevrez une mauvaise requête 400 avec le corps suivant :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"status\": \"error\",\n  \"message\": \"Couldn't create another subscription. You've reached the maximum number allowed per application (1000).\",\n  \"correlationId\": \"2c9beb86-387b-4ff6-96f7-dbb486c00a95\",\n  \"requestId\": \"919c4c84f66769e53b2c5713d192fca7\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}