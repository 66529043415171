"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611105;
const slug = exports.slug = 'reference/cms/hubl/filters';
const title = exports.title = 'Filtres HubL';
const name = exports.name = 'Filtres HubL';
const metaDescription = exports.metaDescription = 'Liste de filtres HubL et exemples pour les développeurs CMS Hub.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "abs",
  "label": "abs",
  "parent": null
}, {
  "depth": 0,
  "id": "add",
  "label": "add",
  "parent": null
}, {
  "depth": 0,
  "id": "attr",
  "label": "attr",
  "parent": null
}, {
  "depth": 0,
  "id": "batch",
  "label": "batch",
  "parent": null
}, {
  "depth": 0,
  "id": "between_times",
  "label": "between_times",
  "parent": null
}, {
  "depth": 0,
  "id": "bool",
  "label": "bool",
  "parent": null
}, {
  "depth": 0,
  "id": "capitalize",
  "label": "capitalize",
  "parent": null
}, {
  "depth": 0,
  "id": "center",
  "label": "center",
  "parent": null
}, {
  "depth": 0,
  "id": "convert_rgb",
  "label": "convert_rgb",
  "parent": null
}, {
  "depth": 0,
  "id": "cut",
  "label": "cut",
  "parent": null
}, {
  "depth": 0,
  "id": "datetimeformat-obsol%C3%A8te",
  "label": "datetimeformat (obsolète)",
  "parent": null
}, {
  "depth": 0,
  "id": "default",
  "label": "default",
  "parent": null
}, {
  "depth": 0,
  "id": "dictsort",
  "label": "dictsort",
  "parent": null
}, {
  "depth": 0,
  "id": "difference",
  "label": "difference",
  "parent": null
}, {
  "depth": 0,
  "id": "divide",
  "label": "divide",
  "parent": null
}, {
  "depth": 0,
  "id": "divisible",
  "label": "divisible",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_html",
  "label": "escape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_attr",
  "label": "escape_attr",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_jinjava",
  "label": "escape_jinjava",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_js",
  "label": "escape_js",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_url",
  "label": "escape_url",
  "parent": null
}, {
  "depth": 0,
  "id": "escapejson",
  "label": "escapejson",
  "parent": null
}, {
  "depth": 0,
  "id": "filesizeformat",
  "label": "filesizeformat",
  "parent": null
}, {
  "depth": 0,
  "id": "first",
  "label": "first",
  "parent": null
}, {
  "depth": 0,
  "id": "float",
  "label": "float",
  "parent": null
}, {
  "depth": 0,
  "id": "forceescape",
  "label": "forceescape",
  "parent": null
}, {
  "depth": 0,
  "id": "format",
  "label": "format",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency-obsol%C3%A8te",
  "label": "format_currency (obsolète)",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency_value",
  "label": "format_currency_value",
  "parent": null
}, {
  "depth": 0,
  "id": "format_date",
  "label": "format_date",
  "parent": null
}, {
  "depth": 0,
  "id": "format_datetime",
  "label": "format_datetime",
  "parent": null
}, {
  "depth": 0,
  "id": "format_time",
  "label": "format_time",
  "parent": null
}, {
  "depth": 0,
  "id": "fromjson",
  "label": "fromjson",
  "parent": null
}, {
  "depth": 0,
  "id": "geo_distance",
  "label": "geo_distance",
  "parent": null
}, {
  "depth": 0,
  "id": "groupby",
  "label": "groupby",
  "parent": null
}, {
  "depth": 0,
  "id": "indent",
  "label": "indent",
  "parent": null
}, {
  "depth": 0,
  "id": "int",
  "label": "int",
  "parent": null
}, {
  "depth": 0,
  "id": "intersect",
  "label": "intersect",
  "parent": null
}, {
  "depth": 0,
  "id": "ipaddr",
  "label": "ipaddr",
  "parent": null
}, {
  "depth": 0,
  "id": "join",
  "label": "join",
  "parent": null
}, {
  "depth": 0,
  "id": "last",
  "label": "last",
  "parent": null
}, {
  "depth": 0,
  "id": "length",
  "label": "length",
  "parent": null
}, {
  "depth": 0,
  "id": "list",
  "label": "list",
  "parent": null
}, {
  "depth": 0,
  "id": "log",
  "label": "log",
  "parent": null
}, {
  "depth": 0,
  "id": "lower",
  "label": "lower",
  "parent": null
}, {
  "depth": 0,
  "id": "map",
  "label": "map",
  "parent": null
}, {
  "depth": 0,
  "id": "md5",
  "label": "md5",
  "parent": null
}, {
  "depth": 0,
  "id": "minus_time",
  "label": "minus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "multiply",
  "label": "multiply",
  "parent": null
}, {
  "depth": 0,
  "id": "plus_time",
  "label": "plus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "pprint",
  "label": "pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "random",
  "label": "random",
  "parent": null
}, {
  "depth": 0,
  "id": "regex_replace",
  "label": "regex_replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reject",
  "label": "reject",
  "parent": null
}, {
  "depth": 0,
  "id": "rejectattr",
  "label": "rejectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "render",
  "label": "render",
  "parent": null
}, {
  "depth": 0,
  "id": "replace",
  "label": "replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reverse",
  "label": "reverse",
  "parent": null
}, {
  "depth": 0,
  "id": "root",
  "label": "root",
  "parent": null
}, {
  "depth": 0,
  "id": "round",
  "label": "round",
  "parent": null
}, {
  "depth": 0,
  "id": "safe",
  "label": "safe",
  "parent": null
}, {
  "depth": 0,
  "id": "sanitize_html",
  "label": "sanitize_html",
  "parent": null
}, {
  "depth": 0,
  "id": "select",
  "label": "select",
  "parent": null
}, {
  "depth": 0,
  "id": "selectattr",
  "label": "selectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "shuffle",
  "label": "shuffle",
  "parent": null
}, {
  "depth": 0,
  "id": "slice",
  "label": "slice",
  "parent": null
}, {
  "depth": 0,
  "id": "sort",
  "label": "sort",
  "parent": null
}, {
  "depth": 0,
  "id": "split",
  "label": "split",
  "parent": null
}, {
  "depth": 0,
  "id": "cha%C3%AEne",
  "label": "chaîne",
  "parent": null
}, {
  "depth": 0,
  "id": "striptags",
  "label": "striptags",
  "parent": null
}, {
  "depth": 0,
  "id": "strtotime",
  "label": "strtotime",
  "parent": null
}, {
  "depth": 0,
  "id": "sum",
  "label": "sum",
  "parent": null
}, {
  "depth": 0,
  "id": "symmetric_difference",
  "label": "symmetric_difference",
  "parent": null
}, {
  "depth": 0,
  "id": "title",
  "label": "title",
  "parent": null
}, {
  "depth": 0,
  "id": "tojson",
  "label": "tojson",
  "parent": null
}, {
  "depth": 0,
  "id": "trim",
  "label": "trim",
  "parent": null
}, {
  "depth": 0,
  "id": "truncate",
  "label": "truncate",
  "parent": null
}, {
  "depth": 0,
  "id": "truncatehtml",
  "label": "truncatehtml",
  "parent": null
}, {
  "depth": 0,
  "id": "unescape_html",
  "label": "unescape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "union",
  "label": "union",
  "parent": null
}, {
  "depth": 0,
  "id": "unique",
  "label": "unique",
  "parent": null
}, {
  "depth": 0,
  "id": "unixtimestamp",
  "label": "unixtimestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "upper",
  "label": "upper",
  "parent": null
}, {
  "depth": 0,
  "id": "urlencode",
  "label": "urlencode",
  "parent": null
}, {
  "depth": 0,
  "id": "urldecode",
  "label": "urldecode",
  "parent": null
}, {
  "depth": 0,
  "id": "urlize",
  "label": "urlize",
  "parent": null
}, {
  "depth": 0,
  "id": "wordcount",
  "label": "wordcount",
  "parent": null
}, {
  "depth": 0,
  "id": "wordwrap",
  "label": "wordwrap",
  "parent": null
}, {
  "depth": 0,
  "id": "xmlattr",
  "label": "xmlattr",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      strong: "strong",
      a: "a",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Filtres HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les filtres affectent le résultat final de votre HubL. Ils peuvent être appliqués à diverses annonces et expressions HubL pour modifier le balisage du modèle produit par le serveur."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La syntaxe de base d'un filtre est ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername"
      }), ". Le filtre est ajouté directement après l'annonce ou l'expression, à l'intérieur de ses séparateurs. Certains filtres ont des paramètres supplémentaires qui peuvent être ajoutés entre parenthèses. La syntaxe de base d'un filtre avec un paramètre de type chaîne, nombre et booléen est : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername(\"stringParameter\", 10, true)"
      }), ". Notez que les paramètres de type chaîne doivent être écrits entre guillemets. Notez également que les filtres HubL ont un alias qui peut être utilisé pour servir le même objectif que le filtre principal."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'article suivant contient tous les filtres HubL pris en charge."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Vous pouvez appliquer les filtres HubL aux ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/personalize-your-content",
          children: "jetons de personnalisation"
        }), ", tels que les jetons de contact et d'entreprise, sur les pages de CMS Hub et de blog, mais ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " dans les e-mails."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "abs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Obtient la valeur absolue d'un nombre. Vous pouvez utiliser ce filtre pour vérifier qu'un nombre est positif."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_number = -53 %}\n{{ my_number|abs }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "add"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ajoute une valeur numérique à une autre valeur numérique. Ce filtre fonctionne de la même manière que l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#math",
        children: "opérateur +"
      }), ". Le paramètre entre parenthèses est l'addendum que vous combinez avec votre valeur numérique initiale."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 40 %}\n{{ my_num|add(13) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "attr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rend l'attribut d'un dictionnaire. Ce filtre est l'équivalent de l'impression d'une variable qui existe dans un dictionnaire, comme content.absolute_url."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique l'attribut à imprimer"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content|attr(\"absolute_url\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "https://developers.hubspot.com/docs/cms/hubl/filters\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "batch"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un filtre de lot regroupe les éléments d'une séquence."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "linecount"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre d'éléments à inclure dans le lot"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "fill_with"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique ce qu'il faut inclure pour compléter les éléments manquants"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'exemple ci-dessous, il existe une variable contenant une séquence de types de fruits. Le filtre de lot est appliqué à une boucle qui parcourt la séquence. La boucle imbriquée s'exécute trois fois pour imprimer 3 types de fruits par ligne, avant que la boucle externe ne s'exécute à nouveau. Remarquez dans la sortie finale, puisqu'il n'y a que 5 types de fruits, le dernier élément est remplacé par un &nbsp (le deuxième paramètre)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set rows = [\"apples\", \"oranges\", \"pears\", \"grapes\", \"blueberries\"] %}\n\n<table>\n{% for row in rows|batch(3, \" \") %}\n   <tr>\n    {% for column in row %}\n        <td>{{ column }}</td>\n    {% endfor %}\n    </tr>\n{% endfor %}\n</table>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<table>\n  <tbody>\n    <tr>\n      <td>apples</td>\n      <td>oranges</td>\n      <td>pears</td>\n    </tr>\n    <tr>\n      <td>grapes</td>\n      <td>blueberries</td>\n      <td></td>\n    </tr>\n  </tbody>\n</table>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "between_times"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcule le temps entre deux objets datetime dans une unité de temps indiquée."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " vous devez ", (0, _jsxRuntime.jsx)("u", {
          children: "uniquement"
        }), " utiliser ce filtre avec les variables qui renvoient une date. À compter du 30 septembre 2024, ce filtre ne renverra plus la date du jour lorsqu'une valeur nulle est transmise. Après cette date, une valeur nulle dans le filtre renverra la date 30 septembre 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'objet datetime de fin"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les unités de temps valides sont ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set begin = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{% set end = \"2018-07-20T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ begin|between_times(end, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "6\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "bool"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit une valeur de chaîne de texte en un booléen."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if \"true\"|bool == true %}hello world{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "hello world\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "capitalize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mettez la première lettre d'une valeur variable en majuscule. Le premier caractère sera en majuscule, toutes les autres lettres seront en minuscules. Les mots suivants séparés par des espaces ou des traits d'union n'auront pas leur première lettre en majuscule."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sentence = \"the first letter of a sentence should always be capitalized.\" %}\n{{ sentence|capitalize }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The first letter of a sentence should always be capitalized.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "center"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le filtre centre utilise les espaces blancs pour centrer le texte dans une longueur de champ donnée. Ce filtre n'est pas recommandé et n'est pas particulièrement utile, car le compilateur HTML de HubSpot élimine automatiquement les espaces blancs ; cependant, il est inclus ici par souci d'exhaustivité."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Précise la longueur de l'espace blanc dans lequel le texte doit être centré."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'exemple ci-dessous montre un filtre center appliqué à une variable dans une pré-balise, de sorte que les espaces blancs ne sont pas supprimés."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to center\" %}\nbefore{{ var|center(80) }}after\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<pre>\nbefore                                string to center                                after\n</pre>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "convert_rgb"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit une valeur HEX en une chaîne RVB. C'est utile si vous devez convertir des variables de couleur en RVB pour les utiliser avec une déclaration CSS RVBA. Dans l'exemple ci-dessous, la valeur définie par un module de couleur est convertie en une valeur RVB et utilisée dans une déclaration CSS RVBA."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_color = \"#FFFFFF\" %}\n{{ my_color|convert_rgb }}\n\n{% color \"my_color\" color=\"#000000\", export_to_template_context=True %}\n<div style=\"background: rgba({{ widget_data.my_color.color|convert_rgb }}, .5)\"></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "255, 255, 255\n\n<div style=\"background: rgba(0, 0, 0, .5)\"></div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supprime une chaîne d'une valeur. Ce filtre peut être utilisé pour faire correspondre et couper une partie spécifique d'une chaîne. Le paramètre indique la partie de la chaîne qui doit être supprimée. L'exemple ci-dessous supprime l'espace et le mot world de la valeur originale de la variable."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "characters_to_cut"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La partie de la chaîne qui doit être supprimée."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_string = \"Hello world.\" %}\n{{ my_string|cut(\" world\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hello.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "datetimeformat (obsolète)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Ce filtre est ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated#datetimeformat",
          children: "obsolète"
        }), ". Vous devrez plutôt utiliser le filtre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-datetime",
          children: "format_datetime"
        }), ", qui utilise une syntaxe plus standardisée."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "default"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si la valeur est indéfinie, elle renverra le premier paramètre, sinon la valeur de la variable sera imprimée. Si vous voulez utiliser la valeur par défaut avec des variables qui s'évaluent comme false, vous devez définir le deuxième paramètre sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "true"
      }), ". Le premier exemple ci-dessous imprime le message si la variable n'est pas définie. Le deuxième exemple applique le filtre à une chaîne vide, qui n'est pas indéfinie, mais il imprime un message à cause du deuxième paramètre."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default_variable"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur à renvoyer si la variable est indéfinie. Si la variable est définie, la valeur de la variable sera renvoyée à la place."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Renvoie ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_value"
            }), " si la variable est une chaîne vide"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ my_variable|default(\"my_variable is not defined\") }}\n{{ \"\"|default(\"the string was empty\", true) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "my_variable is not defined the string was empty\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dictsort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Trie un dictionnaire et donne des paires (clé, valeur). Les dictionnaires ne sont pas triés par défaut, mais vous pouvez imprimer un dictionnaire, trié par clé ou par valeur. Le premier paramètre est un booléen qui détermine si le tri prend en compte la casse ou non. Le deuxième paramètre détermine si le dictionnaire doit être trié par clé ou par valeur. L'exemple ci-dessous imprime un dictionnaire de contacts trié, avec tous les détails connus sur le contact."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Détermine si le tri prend en compte la casse"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sort_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Détermine si le tri doit se faire par ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "key"
            }), " ou par ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for item in contact|dictsort(false, \"value\") %}\n    {{item}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A sorted contact dictionary\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce filtre renvoie la différence de deux ensembles ou listes. La liste renvoyée par le filtre contient tous les éléments uniques qui se trouvent dans la première liste, mais pas dans la seconde."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La deuxième liste à comparer pour trouver les différences par rapport à la liste originale."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divide"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divise la valeur actuelle par un diviseur. Le paramètre transmis est le diviseur. Ce filtre est une alternative à l'opérateur /."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre par lequel il faut diviser la variable."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numerator = 106 %}\n{{ numerator|divide(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divisible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une alternative au test d'expression divisibleby, le filtre divisible sera évalué comme true si la valeur est divisible par le nombre donné."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre à utiliser pour évaluer si la valeur est divisible."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set num = 10 %}\n{% if num|divisible(2) %}\nThe number is divisble by 2\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The number is divisible by 2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Échappe le contenu d'une entrée HTML. Accepte une chaîne et convertit les caractères ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ">"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " en séquences conformes à HTML. Utilisez ce filtre pour les variables HubL qui sont utilisées en HTML mais qui ne doivent autoriser aucun HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|escape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_attr"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Échappe le contenu d'une entrée d'attribut HTML. Accepte une chaîne et convertit les caractères ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " en séquences conformes à HTML. Utilisez ce filtre pour les variables HubL qui sont ajoutées aux attributs HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Notez que lorsque vous échappez des valeurs d'attributs qui acceptent les URL, telles que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "href"
      }), ", vous devez utiliser le filtre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_url"
      }), " à la place."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> markup is printed as text\" %}\n<img src=\"test.com/imageurl\" alt=\"{{escape_string|escape_attr}}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<img src=\"test.com/imageurl\" alt=\"This <br> markup is printed as text\" />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_jinjava"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Convertit les caractères ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}"
      }), " de la chaîne en séquences conformes à Jinjava. Utilisez ce filtre si vous devez afficher un texte susceptible de contenir de tels caractères dans Jinjava."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"{{This markup is printed as text}}\" %}\n{{ escape_string|escape_jinjava }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{This markup is printed as text}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Échappe les chaînes, notamment ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), ", afin qu'elles puissent être insérées en toute sécurité dans une déclaration de variable JavaScript. Utilisez ce filtre pour les variables HubL utilisées dans les éléments de script HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"\\tThey said 'This string can safely be inserted into JavaScript.'\" %}\n{{ escape_string|escape_js }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "\\tThey said \\x27This string can safely be inserted into JavaScript.\\x27\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_url"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Échappe le contenu d'une entrée d'URL, applique les protocoles spécifiés, supprime les caractères invalides et dangereux et code les entités HTML. Renvoie une valeur vierge si une URL est valide. Utilisez ce filtre pour les variables HubL qui sont utilisées dans les attributs HTML qui doivent être des URL valides."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"https://www.google.com<\" %}\n <a href=\"{{ escape_string|escape_url }}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"\"></a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escapejson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Échappe les chaînes de caractères afin qu'elles puissent être utilisées comme des valeurs JSON."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<script>alert('oh no!')</script>\" %}\n{% require_js position=\"head\" %}\n<script data-search_input-config=\"config_{{ name }}\" type=\"application/json\">\n{\n  \"autosuggest_results_message\": \"{{ escape_string|escapejson }}\"\n}\n</script>\n{% end_require_js %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<script\n  data-search_input-config=\"config_widget_1234567\"\n  type=\"application/json\"\n>\n  {\n    \"autosuggest_results_message\": \"<script>alert('oh no!')<\\/script>\"\n  }\n</script>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "filesizeformat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formate la valeur comme une taille de fichier « lisible par l'homme » (c'est-à-dire 13 kB, 4,1 Mo, 102 octets, etc.). Par défaut, les préfixes décimaux sont utilisés (Mega, Giga, etc.), si le paramètre est défini sur True, les préfixes binaires sont utilisés (Mebi, Gibi)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "S'il est défini sur true, des préfixes binaires sont utilisés tels que Mebi et Gibi."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bytes = 100000 %}\n{{ bytes|filesizeformat }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "100.0 KB\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "first"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renvoie le premier élément d'une séquence."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ my_sequence|first }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertir la valeur en un nombre à virgule flottante. Si la conversion ne fonctionne pas, elle renvoie 0.0. Vous pouvez remplacer cette valeur par défaut en utilisant le premier paramètre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre entier à renvoyer si la conversion ne fonctionne pas."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|float + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "forceescape"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Appliquer strictement l'échappement HTML. Dans l'environnement de HubSpot, il n'y a pas vraiment de cas d'utilisation pour l'échappement double, donc cette option se comporte généralement de la même manière que le filtre escape."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|forceescape }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Applique le formatage des chaînes Python à un objet. %s, peut être remplacé par une autre variable."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"Hi %s %s\"|format(contact.firstname, contact.lastname) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hi Brian Halligan\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency (obsolète)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Ce filtre est ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated",
          children: "obsolète"
        }), ". Utilisez plutôt le filtre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-currency-value",
          children: "format_currency_value"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency_value"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formate un nombre donné sous forme de devise en fonction de la devise par défaut du portail et du paramètre régional transmis sous forme de paramètre. Remplace le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#format-currency",
        children: "format_currency filtre obsolète"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 100 | format_currency_value(locale='en-GB', currency='EUR', maxDecimalDigits=6, minDecimalDigits=1) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "€100.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.oracle.com/java/technologies/javase/jdk8-jre8-suported-locales.html",
              children: "La balise du langage local Java"
            }), ". Les paramètres régionaux de la page constituent la valeur par défaut. Format : ISO639LanguageCodeInLowercase-ISO3166CountryCodeInUppercase"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_4217",
              children: "code alphabétique ISO 4217"
            }), " de la devise, la valeur par défaut est la devise par défaut du portail. Les codes numériques ne sont pas acceptés."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "minDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre minimum de décimales à utiliser. Par défaut, le nombre de décimales par défaut de la devise."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "maxDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre maximum de décimales à utiliser. Par défaut, le nombre de décimales par défaut de la devise."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_date"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formate le composant de date d'un objet de date."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " vous devez ", (0, _jsxRuntime.jsx)("u", {
          children: "uniquement"
        }), " utiliser ce filtre avec les variables qui renvoient une date. À compter du 30 septembre 2024, ce filtre ne renverra plus la date du jour lorsqu'une valeur nulle est transmise. Après cette date, une valeur nulle dans le filtre renverra la date 30 septembre 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le format à utiliser. Peut être l'un des éléments suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["un modèle personnalisé reposant sur ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le fuseau horaire de la date de sortie au format ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA TZDB"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les paramètres régionaux à utiliser pour les formats reposant sur des paramètres régionaux."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_date('long') }}\n{{ content.publish_date | format_date('yyyyy.MMMM.dd') }}\n{{ content.publish_date | format_date('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "November 28, 2022 02022.November.28 28.11.2022\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_datetime"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formate les composants de date et d'heure d'un objet de date. Ce filtre remplace le filtre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#datetimeformat-nbsp-",
        children: "datetimeformat"
      }), " obsolète. Par défaut, renvoie une date et une heure dans le fuseau horaire UTC-00:00."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " vous devez ", (0, _jsxRuntime.jsx)("u", {
          children: "uniquement"
        }), " utiliser ce filtre avec les variables qui renvoient une date. À compter du 30 septembre 2024, ce filtre ne renverra plus la date du jour lorsqu'une valeur nulle est transmise. Après cette date, une valeur nulle dans le filtre renverra la date 30 septembre 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le format à utiliser. Peut être l'un des éléments suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["un modèle personnalisé reposant sur ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            }), "En cas d'utilisation de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "long"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "full"
            }), ", l'horodatage inclura un Z pour indiquer le décalage d'origine du fuseau UTC (c.-à-d. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2:23:00 PM Z"
            }), "). Pour supprimer l'indicateur Z, spécifiez un attribut ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le fuseau horaire de la date de sortie au format ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA TZDB"
            }), ". Par défaut, renvoie l'heure UTC."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les paramètres régionaux à utiliser pour les formats reposant sur des paramètres régionaux."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_datetime('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "12/31/69 7:00 PM\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formate le composant d'heure d'un objet de date."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " vous devez ", (0, _jsxRuntime.jsx)("u", {
          children: "uniquement"
        }), " utiliser ce filtre avec les variables qui renvoient une date. À compter du 30 septembre 2024, ce filtre ne renverra plus la date du jour lorsqu'une valeur nulle est transmise. Après cette date, une valeur nulle dans le filtre renverra la date 30 septembre 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le format à utiliser. Peut être l'un des éléments suivants :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["un modèle personnalisé reposant sur ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le fuseau horaire de la date de sortie au format ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA TZDB"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les paramètres régionaux à utiliser pour les formats reposant sur des paramètres régionaux."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.updated | format_time('long') }}\n{{ content.updated | format_time('hh:mm a') }}\n{{ content.updated | format_time('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "3:25:06 PM Z 03:25 PM 10:25:44\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "fromjson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit une chaîne JSON en un objet."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj =\"{ \"name\":\"Brian\",\"role\":\"Owner\" }\" %}\n{{ obj|fromjson }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{role=Owner, name=Brian}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "geo_distance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcule la distance ellipsoïdale 2D entre deux points sur la Terre."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- in the example below\nthe HubDB Location =\n42.3667, -71.1060 (Cambridge, MA) |\nChicago, IL = 37.3435, -122.0344 -->\n{{ row.location | geo_distance(37.3435, -122.0344, \"mi\") }} MI\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "861.1655563461395 MI\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "groupby"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le filtre groupby regroupe une séquence d'objets selon un attribut commun. Le paramètre définit l'attribut commun pour le regroupement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'attribut à utiliser pour le regroupement."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<ul>\n{% for group in contents|groupby(\"blog_post_author\") %}\n    <li>{{ group.grouper }}\n      <ul>\n        {% for content in group.list %}\n          <li>{{ content.name }}</li>\n        {% endfor %}\n      </ul>\n    </li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Blog author 1\n    <ul>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 2\n    <ul>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 3\n    <ul>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "indent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le filtre indent utilise les espaces blancs pour indenter le texte dans une longueur de champ donnée. Ce filtre n'est pas recommandé et n'est pas particulièrement utile, car le compilateur HTML de HubSpot élimine automatiquement les espaces blancs ; cependant, il est inclus ici par souci d'exhaustivité. L'exemple ci-dessous montre un filtre center appliqué à une variable dans une pré-balise, de sorte que les espaces blancs ne sont pas supprimés. Le premier paramètre contrôle la quantité d'espaces blancs et le second booléen permet d'indenter ou non la première ligne."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La quantité d'espaces blancs à appliquer."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur booléenne indiquant si la première ligne doit être indentée."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to indent\" %}\n{{ var|indent(2, true) }}\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "string to indent\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "int"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertir la valeur en un nombre entier. Si la conversion ne fonctionne pas, elle renvoie 0. Vous pouvez remplacer cette valeur par défaut en utilisant le premier paramètre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre entier à renvoyer si la conversion ne fonctionne pas."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|int + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "intersect"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce filtre renvoie l'intersection de deux ensembles ou listes. La liste renvoyée par le filtre contient tous les éléments uniques qui sont contenus dans les deux listes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La deuxième liste à comparer pour trouver l'intersection de la liste avec la liste originale."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|intersect([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[2, 3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ipaddr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Évalue comme true si la valeur est une adresse IPv4 ou IPv6 valide."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set ip = \"1.0.0.1\" %}\n{% if ip|ipaddr %}\n    The string is a valid IP address\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The string is a valid IP address\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "join"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renvoie une chaîne qui est la concaténation des chaînes de la séquence. Le séparateur entre les éléments est une chaîne vide par défaut, vous pouvez le définir avec le paramètre facultatif. Le deuxième paramètre peut être utilisé pour indiquer un attribut à joindre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "delimiter"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le délimiteur à utiliser lors de la concaténation de chaînes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Variable HubL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribut de valeur à joindre à un objet."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_list = [1, 2, 3] %}\n{% set sep = \"---\" %}\n{{ my_list|join }}\n{{ my_list|join(\"|\") }}\n{{ my_list|join(sep) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "123 1|2|3 1---2---3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "last"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renvoie le dernier élément d'une séquence."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{% my_sequence|last %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "length"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renvoie le nombre d'éléments d'une séquence ou d'un mappage."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set services = [\"Web design\", \"SEO\", \"Inbound Marketing\", \"PPC\"] %}\n{{ services|length }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "list"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit les valeurs numériques en une liste. S'il s'agit d'une chaîne, la liste renvoyée sera une liste de caractères. Pour ajouter des chaînes à une séquence, il suffit de les ajouter aux variables de chaîne des délimiteurs de séquence [ ]."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set one = 1 %}\n{% set two = 2 %}\n{% set three = 3 %}\n{% set four = [\"four\"] %}\n{% set list_num = one|list + two|list + three|list + four|list %}\n{{ list_num }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1,2,3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "log"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcule le logarithme naturel d'un nombre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "base"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calculez le logarithme à la nième base."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 10|log }}\n{{ 65536|log(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2.302585092994046 16.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit une valeur en lettres minuscules."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ text \"text\" value=\"Text to MAKE Lowercase\", export_to_template_context=True }}\n{{ widget_data.text.value|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "text to make lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "map"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Applique un filtre sur une séquence d'objets ou recherche un attribut. C'est utile lorsque vous traitez des listes d'objets, mais que vous n'êtes réellement intéressé que par une certaine valeur de celle-ci."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'utilisation de base est le mappage d'un attribut. Par exemple, si vous voulez utiliser la logique conditionnelle pour vérifier si une valeur est présente dans un attribut particulier d'un dictionnaire. Vous pouvez également le laisser utiliser un filtre en transmettant le nom du filtre et les arguments après."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribut à renvoyer dans la séquence d'objets."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Filtre à appliquer à la séquence d'objets."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set seq = [\"item1\", \"item2\", \"item3\"] %}\n{{ seq|map(\"upper\") }}\n{{ content|map(\"currentState\")}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[ITEM1, ITEM2, ITEM3] DRAFT\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "md5"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Calcule le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/MD5",
        children: "hachage md5"
      }), " de l'objet donné"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.absolute_url|md5 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "923adb4ce05a4c6342c04c80be88d15e\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "minus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Soustrait un nombre d'heures à un objet datetime."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Montant à soustraire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les unités de temps valides sont ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|minus_time(2, \"months\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-05-14 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "multiply"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Multiplie une valeur par un nombre. Fonctionne de la même manière que l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "* opérateur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set n = 20 %}\n{{ n|multiply(3) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "60\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "plus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ajoute un nombre d'heures à un objet datetime."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Montant à ajouter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les unités de temps valides sont ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|plus_time(5, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-07-19 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "pprint"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Impression d'une variable avec mise en forme. Ceci imprime le type de variable et d'autres informations utiles pour le débogage."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set this_var =\"Variable that I want to debug\" %}\n{{ this_var|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: Variable that I want to debug)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "random"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renvoie un élément aléatoire de la séquence."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous utilisez ce filtre, la page sera ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "pré-rendue"
        }), " périodiquement et non pas chaque fois que le contenu de la page est mis à jour. Cela signifie que le contenu filtré ne sera ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " mis à jour à chaque rechargement de page."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Cela peut ne pas être un problème pour certains types de contenu, tels que l'affichage d'une liste aléatoire d'articles de blog. Cependant, si vous souhaitez que le contenu change aléatoirement à chaque chargement de page, vous devez plutôt utiliser JavaScript pour rendre aléatoire le contenu côté client."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|random %}\n<div class=\"post-item\">Post item markup</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Random post</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "regex_replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Recherche un motif regex et le remplace par une séquence de caractères. Le premier argument est un motif regex de style RE2, le second est la chaîne de remplacement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Des informations sur la syntaxe des regex RE2 sont disponibles ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/re2/wiki/Syntax",
        children: "ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"contact-us-2\"|regex_replace(\"[^a-zA-Z]\", \"\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "contactus\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reject"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Filtre une séquence d'objets en appliquant un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "test d'expression"
      }), " à l'objet et en rejetant ceux dont le test a réussi."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le test d'expression à appliquer à l'objet."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|reject(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[13, 3, 5, 17]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "rejectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtre une séquence d'objets en appliquant un test à l'attribut d'un objet et en rejetant ceux dont le test a réussi."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique l'attribut à sélectionner. Vous pouvez accéder aux attributs imbriqués en utilisant la notation par points."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'expression à tester"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur à tester."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|rejectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n{% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n            <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n            <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n            </a>\n    </div>\n{% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "render"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rend les chaînes contenant HubL au début afin que la sortie puisse être transmise dans d'autres filtres."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ personalization_token(\"contact.lastname\", \"default value\")|render|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "mclaren\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remplace toutes les instances d'une sous-chaîne par une nouvelle."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "old"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La sous-chaîne qui doit être remplacée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "new"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne de remplacement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si elle est fournie, seules les occurrences du premier décompte sont remplacées."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if topic %}\n<h3>Posts about {{ page_meta.html_title|replace(\"Blog | \", \"\") }}</h3>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Posts about topic name</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reverse"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inverse l'objet ou renvoie un itérateur qui parcours dans l'autre sens. Pour inverser une liste, utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#reverse",
        children: ".reverse()"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] %}\n{% for num in nums|reverse %}\n{{ num }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "10 9 8 7 6 5 4 3 2 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "root"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calcule la racine carrée d'une valeur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "nth_root"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calculez la nième racine d'un nombre."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 16|root }}\n{{ 625|root(4) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "round"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Arrondit le nombre à une précision donnée."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "precision"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique la précision de l'arrondi."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "rounding_method"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les options sont : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), " arrondit vers le haut ou vers le bas (par défaut) ; ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceil"
            }), " arrondit toujours vers le haut ; ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "floor"
            }), " arrondit toujours vers le bas.Si vous n'indiquez pas de méthode, la méthode ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), " est utilisée."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 52.5|round }}\n{{ 52.5|round(0, \"floor\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53 52\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "safe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Marquer la valeur comme sûre, ce qui signifie que dans un environnement où l'échappement automatique est activé, cette variable ne sera pas échappée."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.post_list_content|safe }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>HTML post content that is not escaped.</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sanitize_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nettoie le contenu d'une entrée HTML pour la sortie de contenu en texte enrichi. Accepte une chaîne, puis supprime les balises HTML qui ne sont pas autorisées. Utilisez ce filtre pour les variables HubL utilisées en HTML qui devraient permettre un HTML sécurisé."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous utilisez ce filtre, vous pouvez inclure les paramètres suivants pour autoriser des types spécifiques de balises HTML : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORMATTING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BLOCKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STYLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LINKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TABLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IMAGES"
      }), ". Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html(IMAGES)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'utilisation de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html"
      }), " inclura tous les paramètres dans le filtre."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également inclure un paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STRIP"
      }), " pour supprimer ", (0, _jsxRuntime.jsx)("u", {
        children: "tout"
      }), " le HTML. Tout le contenu est également exécuté via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " pour empêcher l'interprétation imbriquée."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> <div>markup is <img src='test.com/image'> <span>printed</span> as text.</div>\" %}\n{{ escape_string|sanitize_html(\"IMAGES\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This  markup is <img src=\"test.com/image\"> printed as text.</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "select"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtre une séquence d'objets en appliquant un test à l'objet et en ne sélectionnant que ceux dont le test a réussi."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le test d'expression à appliquer à l'objet."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|select(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[10, 12, 22]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "selectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtre une séquence d'objets en appliquant un test à un attribut d'un objet et en ne sélectionnant que ceux dont le test a réussi."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique l'attribut à sélectionner. Vous pouvez accéder aux attributs imbriqués en utilisant la notation par points."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'expression à tester"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur à tester."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|selectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n  {% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n       <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n         <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n       </a>\n    </div>\n  {% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">\n  <div class=\"hs-featured-image-wrapper\">\n    <a\n      href=\"http://blog.hubspot.com/marketing/how-to-get-a-job\"\n      title=\"\"\n      class=\"hs-featured-image-link\"\n    >\n      <img\n        src=\"//cdn2.hubspot.net/hub/53/hubfs/00-Blog-Related_Images/landing-a-job-featured-image.png?t=1431452322770&width=761\"\n        class=\"hs-featured-image\"\n      />\n    </a>\n  </div>\n  Post with featured image\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "shuffle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rend aléatoire l'ordre d'itération d'une séquence. L'exemple ci-dessous mélange une boucle de blog standard."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous utilisez ce filtre, la page sera ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "pré-rendue"
        }), " périodiquement et non pas chaque fois que le contenu de la page est mis à jour. Cela signifie que le contenu filtré ne sera ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " mis à jour à chaque rechargement de page."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Cela peut ne pas être un problème pour certains types de contenu, tels que l'affichage d'une liste aléatoire d'articles de blog. Cependant, si vous souhaitez que le contenu change aléatoirement à chaque chargement de page, vous devez plutôt utiliser JavaScript pour rendre aléatoire le contenu côté client."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|shuffle %}\n<div class=\"post-item\">Markup of each post</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Markup of each post 5</div>\n<div class=\"post-item\">Markup of each post 3</div>\n<div class=\"post-item\">Markup of each post 1</div>\n<div class=\"post-item\">Markup of each post 2</div>\n<div class=\"post-item\">Markup of each post 4</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "slice"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découpe un itérateur et renvoie une liste de listes contenant ces éléments. Le premier paramètre indique le nombre d'éléments qui seront découpés, et le second paramètre indique les caractères à utiliser pour remplir les tranches vides."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "slices"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Combien d'éléments seront découpés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "filler"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique les caractères à utiliser pour remplir les tranches vides."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set items = [\"laptops\", \"tablets\", \"smartphones\", \"smart watches\", \"TVs\"] %}\n<div class=\"columwrapper\">\n  {% for column in items|slice(3,\" \") %}\n    <ul class=\"column-{{ loop.index }}\">\n    {% for item in column %}\n      <li>{{ item }}</li>\n    {% endfor %}\n    </ul>\n  {% endfor %}\n</div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"columwrapper\">\n  <ul class=\"column-1\">\n    <li>laptops</li>\n    <li>tablets</li>\n    <li>smartphones</li>\n  </ul>\n\n  <ul class=\"column-2\">\n    <li>smart watches</li>\n    <li>TVs</li>\n    <li></li>\n  </ul>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Trie une table itérative. Ce filtre nécessite que tous les paramètres soient triés en utilisant un attribut dans HubSpot. Le premier paramètre est un booléen pour inverser l'ordre de tri. Le deuxième paramètre détermine si le tri prend en compte la casse. Et le dernier paramètre indique l'attribut à utiliser pour le tri. Dans l'exemple ci-dessous, les articles d'un blog sont rendus et classés par ordre alphabétique de leur nom."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "reverse"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur booléenne permettant d'inverser l'ordre de tri."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur booléenne qui détermine si le tri prend en compte la casse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribut à utiliser pour le tri. Omettre lors du tri d'une liste."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_posts = blog_recent_posts(\"default\", limit=5) %}\n\n{% for item in my_posts|sort(False, False, \"name\") %}\n{{ item.name }}<br>\n\n\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A post<br />\nB post<br />\nC post<br />\nD post<br />\nE post<br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "split"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divise la chaîne d'entrée en une liste selon le séparateur donné. Le premier paramètre indique le séparateur avec lequel la variable doit être divisée. Le deuxième paramètre détermine combien de fois la variable doit être divisée. Tous les autres éléments resteraient groupés. Dans l'exemple ci-dessous, une chaîne de noms est séparée au niveau du « ; » pour les 4 premiers noms."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_to_split_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique le séparateur avec lequel la variable doit être divisée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_splits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Détermine le nombre de fois que la variable doit être divisée. Tous les autres éléments resteraient groupés."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_to_split = \"Stephen; David; Cait; Nancy; Mike; Joe; Niall; Tim; Amanda\" %}\n{% set names = string_to_split|split(\";\", 4) %}\n<ul>\n{% for name in names %}\n  <li>{{ name }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Stephen</li>\n  <li>David</li>\n  <li>Cait</li>\n  <li>Nancy; Mike; Joe; Niall; Tim; Amanda</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "chaîne"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit un type de variable différent en une chaîne. Dans l'exemple ci-dessous, un entier est converti en chaîne (pprint est utilisé pour confirmer le changement de type de variable)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set number_to_string = 45 %}\n{{ number_to_string|string|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: 45)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "striptags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Efface les balises SGML/XML et remplace les espaces blancs adjacents par un espace. Ce filtre peut être utilisé pour supprimer toutes les balises HTML d'une variable."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_html = \"<div><strong>Some text</strong></div>\" %}\n{{ some_html|striptags }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "strtotime"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit une chaîne datetime et un format datetime en un objet datetime."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "datetimeFormat"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://docs.oracle.com/javase/7/docs/api/java/text/SimpleDateFormat.html",
              children: "Modèles de date et d'heure."
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\")|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1531558890000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sum"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ajoute des valeurs numériques dans une séquence. Le premier paramètre peut indiquer un attribut facultatif et le second paramètre définit une valeur à renvoyer s'il n'y a rien dans la variable à additionner."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribut à additionner."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "return_if_nothing"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur à renvoyer s'il n'y a rien dans la variable à additionner."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sum_this = [1, 2, 3, 4, 5] %}\n{{ sum_this|sum }}\nTotal: {{ items|sum(attribute=\"price:\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "15 Total: 20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "symmetric_difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce filtre renvoie la différence symétrique de deux ensembles ou listes. La liste renvoyée par le filtre contient tous les éléments uniques qui figurent dans la première liste, mais pas dans la seconde, ou qui figurent dans la seconde liste, mais pas dans la première."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La deuxième liste à comparer pour trouver la différence symétrique avec la liste originale."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|symmetric_difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "title"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renvoie une version titlecased de la valeur. C'est-à-dire que les mots commencent par des lettres majuscules, tous les autres caractères sont des minuscules."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_title\" label=\"Enter a title\", value=\"My title should be titlecase\", export_to_template_context=True %}\n{{ widget_data.my_title.value|title }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "My Title Should Be Titlecase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "tojson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Écrit un objet sous forme de chaîne JSON."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n  {{ content.blog_post_author|tojson }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie JSON rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 1234567,\n  \"id\": 12312253109,\n  \"created\": 1566413741989,\n  \"updated\": 1566414012799,\n  \"deletedAt\": 0,\n  \"fullName\": \"Sample User\",\n  \"email\": \"sampleUser@example.com\",\n  \"userId\": null,\n  \"username\": null,\n  \"slug\": \"sample-user\",\n  \"jsonBody\": {\n    \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n    \"bio\": \"Sample Bio\",\n    \"facebook\": \"\",\n    \"linkedin\": \"\",\n    \"twitter\": \"\",\n    \"website\": \"https://www.hubspot.com\"\n  },\n  \"bio\": \"Sample Bio\",\n  \"facebook\": \"\",\n  \"linkedin\": \"\",\n  \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n  \"gravatarUrl\": \"https://app.hubspot.com/settings/avatar/108bb5ac667ded34796271437dfe8d58\",\n  \"twitterUsername\": \"\",\n  \"hasSocialProfiles\": false,\n  \"website\": \"https://www.hubspot.com\",\n  \"twitter\": \"\",\n  \"displayName\": \"Sample User\"\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "trim"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supprime les espaces blancs de début et de fin. HubSpot supprime déjà les espaces blancs du balisage, mais ce filtre est documenté par souci d'exhaustivité."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \" remove whitespace \" }}\n{{ \" remove whitespace \"|trim }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "remove whitespace remove whitespace\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Coupe le texte après un certain nombre de caractères. La valeur par défaut est 255. Veuillez noter que les caractères HTML sont inclus dans ce décompte. La longueur est indiquée avec le premier paramètre qui a la valeur 255 par défaut. Si le deuxième paramètre est true, le filtre coupera le texte en longueur. Sinon, le dernier mot est rejeté. Si le texte a été tronqué, il ajoutera un signe d'ellipse (« ... »). Si vous souhaitez un autre signe d'ellipse que « ... », vous pouvez l'indiquer en utilisant le troisième paramètre."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre de caractères après lesquels le texte doit être tronqué. La valeur par défaut est 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur booléenne. Si sur true, le filtre coupe le texte en longueur. Si sur false, le dernier mot est supprimé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Remplace les caractères de fin par « ... » par défaut après la troncature."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"I only want to show the first sentence. Not the second.\"|truncate(40) }}\n{{ \"I only want to show the first sentence. Not the second.\"|truncate(35, True, \"..........\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "I only want to show the first sentence. I only want to show the first\nsente..........\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncatehtml"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tronque une chaîne donnée, en respectant le balisage html (c'est-à-dire qu'il fermera correctement toutes les balises imbriquées). Cela empêchera une balise de rester ouverte après la troncature. Les caractères HTML ne sont pas pris en compte dans le total des caractères. Ce filtre a un paramètre de longueur et un paramètre de symbole de troncature. Il existe un troisième paramètre booléen qui indique si les mots seront cassés en longueur. Ce paramètre est faux par défaut afin de préserver la longueur des mots. Si vous n'utilisez qu'un seul des paramètres facultatifs, utilisez des arguments de type mot-clé, comme truncatehtml(70, breakwords = false)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre de caractères après lesquels le texte doit être tronqué. La valeur par défaut est 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Remplace les caractères de fin par « ... » par défaut après la troncature."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur booléenne. Si sur true, le filtre coupe le texte en longueur. Si sur false, le dernier mot est supprimé."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_text = \"<p>I want to truncate this text without breaking my HTML<p>\" %}\n{{ html_text|truncatehtml(28, \"...\" , false) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>I want to truncate this..</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unescape_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit le texte avec des entités codées en HTML en leurs équivalents Unicode."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"me & you\" %}\n{{ escape_string|unescape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "me & you\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "union"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce filtre renvoie l'union de deux ensembles ou listes. La liste renvoyée par le filtre contient tous les éléments uniques qui se trouvent dans l'une ou l'autre des listes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La deuxième liste à joindre à la liste originale."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|union([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 2, 3, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce filtre extrait un ensemble unique d'une séquence ou d'un dictionnaire d'objets. Lors du filtrage d'un dictionnaire, tel qu'une liste d'articles renvoyée par une fonction, vous pouvez indiquer quel attribut est utilisé pour dédupliquer les éléments du dictionnaire."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attr"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique l'attribut qui doit être utilisé lors du filtrage d'une valeur dictionnaire"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"one\", \"one\", \"two\", \"three\" ] %}\n{{ my_sequence|unique }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[one, two, three]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unixtimestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce filtre convertit un objet datetime en un horodatage unix."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " vous devez ", (0, _jsxRuntime.jsx)("u", {
          children: "uniquement"
        }), " utiliser ce filtre avec les variables qui renvoient une date. À compter du 30 septembre 2024, ce filtre ne renverra plus la date du jour lorsqu'une valeur nulle est transmise. Après cette date, une valeur nulle dans le filtre renverra la date 30 septembre 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2017-01-30 17:11:44 1485814304000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit une valeur en lettres majuscules."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"text\" value=\"text to make uppercase\", export_to_template_context=True %}\n{{ widget_data.text.value|upper }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "TEXT TO MAKE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlencode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quitte et encode l'URL d'une chaîne utilisant le formatage UTF-8. Accepte à la fois les dictionnaires et les chaînes régulières ainsi que les itérables par paire."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"encode\" value=\"Escape & URL encode this string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.encode.value|urlencode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape+%26+URL+encode+this+string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urldecode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Décode les chaînes d'URL encodées vers l'URL d'origine. Accepte à la fois les dictionnaires et les chaînes régulières ainsi que les itérables par paire."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"decode\" value=\"Escape+%26+URL+decode+this+string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.decode.value|urldecode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape & URL encode this string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convertit les URL en texte brut en liens cliquables. Si vous transmettez au filtre un nombre entier supplémentaire, il raccourcira les URL à ce nombre. Le deuxième paramètre est un booléen qui indique si le lien est rel=\"no follow\". Le dernier paramètre vous permet de préciser si le lien s'ouvrira dans un nouvel onglet."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "shorten_text"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre entier qui raccourcira les URL au nombre désiré."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "no_follow"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur booléenne indiquant si le lien est rel=\"no follow\"."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique si le lien s'ouvrira dans un nouvel onglet."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"http://hubspot.com/\"|urlize }}\n{{ \"http://hubspot.com/\"|urlize(10,true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",false,target=\"_blank\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"//hubspot.com/\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://...</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" target=\"_blank\">http://hubspot.com/</a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordcount"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Compte le nombre de mots dans une chaîne."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si la chaîne contient du HTML, utilisez le filtre striptags pour obtenir un compte précis."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{%  set count_words = \"Count the number of words in this variable\"  %}\n{{ count_words|wordcount }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "8\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordwrap"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Provoque le retour à la ligne des mots après un nombre de caractères donné. Cela fonctionne mieux dans un <pre> car HubSpot supprime les espaces blancs par défaut."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre de caractères avec le retour à la ligne du contenu."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set wrap_text = \"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum non sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet dui. Ut laoreet ultricies quam at fermentum.\" %}\n{{ wrap_text|wordwrap(10) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum\nnon sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet\ndui. Ut laoreet ultricies quam at fermentum.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "xmlattr"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Crée une chaîne d'attributs HTML/XML, basée sur les éléments d'un dictionnaire. Toutes les valeurs qui ne sont ni nulles,", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "ni indéfinies sont automatiquement échappées. Il ajoute automatiquement un espace devant l'élément si le filtre renvoie quelque chose, sauf si le premier paramètre est false."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "autospace"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Valeur booléenne qui ajoutera automatiquement un espace devant l'élément, sauf si elle est définie sur false."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_attributes = {\"class\": \"bold\", \"id\": \"sidebar\"} %}\n<div {{ html_attributes|xmlattr }}></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"bold\" id=\"sidebar\"></div>\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}