"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29913023567;
const slug = exports.slug = 'reference/cms/modules/default-modules';
const title = exports.title = 'Default web modules';
const name = exports.name = 'Default web modules';
const metaDescription = exports.metaDescription = 'Learn about the default modules that HubSpot provides for creating website page, blog post, and blog listing templates. These modules are separate from default email modules, which are for email templates.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "blog-comments",
  "label": "Blog comments",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-email-subscription",
  "label": "Blog email subscription",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-posts",
  "label": "Blog posts",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-post-filter",
  "label": "Blog post filter",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-post-listing",
  "label": "Blog post listing",
  "parent": null
}, {
  "depth": 0,
  "id": "button",
  "label": "Button",
  "parent": null
}, {
  "depth": 0,
  "id": "call-to-action",
  "label": "Call-to-Action",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-data",
  "label": "Delete data",
  "parent": null
}, {
  "depth": 0,
  "id": "divider",
  "label": "Divider",
  "parent": null
}, {
  "depth": 0,
  "id": "download-data",
  "label": "Download data",
  "parent": null
}, {
  "depth": 0,
  "id": "form",
  "label": "Form",
  "parent": null
}, {
  "depth": 0,
  "id": "header",
  "label": "Header",
  "parent": null
}, {
  "depth": 0,
  "id": "horizontal-spacer",
  "label": "Horizontal spacer",
  "parent": null
}, {
  "depth": 0,
  "id": "icon",
  "label": "Icon",
  "parent": null
}, {
  "depth": 0,
  "id": "image",
  "label": "Image",
  "parent": null
}, {
  "depth": 0,
  "id": "image-grid",
  "label": "Image grid",
  "parent": null
}, {
  "depth": 0,
  "id": "image-gallery",
  "label": "Image gallery",
  "parent": null
}, {
  "depth": 0,
  "id": "image-slider-gallery",
  "label": "Image slider gallery",
  "parent": null
}, {
  "depth": 0,
  "id": "language-switcher",
  "label": "Language switcher",
  "parent": null
}, {
  "depth": 0,
  "id": "logo",
  "label": "Logo",
  "parent": null
}, {
  "depth": 0,
  "id": "logo-grid",
  "label": "Logo grid",
  "parent": null
}, {
  "depth": 0,
  "id": "meetings",
  "label": "Meetings",
  "parent": null
}, {
  "depth": 0,
  "id": "membership-social-logins",
  "label": "Membership social logins",
  "parent": null
}, {
  "depth": 0,
  "id": "menu",
  "label": "Menu",
  "parent": null
}, {
  "depth": 0,
  "id": "page-footer",
  "label": "Page footer",
  "parent": null
}, {
  "depth": 0,
  "id": "password-prompt",
  "label": "Password prompt",
  "parent": null
}, {
  "depth": 0,
  "id": "payments",
  "label": "Payments",
  "parent": null
}, {
  "depth": 0,
  "id": "product",
  "label": "Product",
  "parent": null
}, {
  "depth": 0,
  "id": "quote-download",
  "label": "Quote download",
  "parent": null
}, {
  "depth": 0,
  "id": "quote-payment",
  "label": "Quote payment",
  "parent": null
}, {
  "depth": 0,
  "id": "quote-signature",
  "label": "Quote signature",
  "parent": null
}, {
  "depth": 0,
  "id": "line-items",
  "label": "Line items",
  "parent": null
}, {
  "depth": 0,
  "id": "rich-text",
  "label": "Rich text",
  "parent": null
}, {
  "depth": 0,
  "id": "rss-listing",
  "label": "RSS listing",
  "parent": null
}, {
  "depth": 0,
  "id": "site-search-input",
  "label": "Site search input",
  "parent": null
}, {
  "depth": 0,
  "id": "search-results",
  "label": "Search results",
  "parent": null
}, {
  "depth": 0,
  "id": "section-header",
  "label": "Section header",
  "parent": null
}, {
  "depth": 0,
  "id": "simple-menu",
  "label": "Simple menu",
  "parent": null
}, {
  "depth": 0,
  "id": "social-follow",
  "label": "Social follow",
  "parent": null
}, {
  "depth": 0,
  "id": "social-sharing",
  "label": "Social sharing",
  "parent": null
}, {
  "depth": 0,
  "id": "tabbed-card",
  "label": "Tabbed card",
  "parent": null
}, {
  "depth": 0,
  "id": "one-line-of-text",
  "label": "One line of text",
  "parent": null
}, {
  "depth": 0,
  "id": "video",
  "label": "Video",
  "parent": null
}, {
  "depth": 0,
  "id": "video-embed-landing-page",
  "label": "Video embed (landing page)",
  "parent": null
}, {
  "depth": 0,
  "id": "whatsapp-link",
  "label": "WhatsApp link",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Default web modules"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about the default modules that HubSpot provides for building templates for website pages, blog posts, and blog listing pages. You'll also find default modules that can be used to build quote templates."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing locally, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch-files",
        children: "fetch"
      }), " a specific default module using the module path (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/linked_image.module"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view a default module's code, you can view and clone the module within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), " folder of the design manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-default-modules.png",
        alt: "design-manager-default-modules"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " default web modules are separate from ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules",
          children: "default email modules"
        }), ", which are for email templates. If your email templates include any of the following default web modules, you should ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/default-email-modules",
          children: "replace them with the corresponding email-specific module"
        }), ":"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "cta"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "linked_image"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "logo"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_filter"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_listing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "section_header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "social_sharing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog comments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in blog posts and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_comments\" path=\"@hubspot/blog_comments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog email subscription"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_subscribe\" path=\"@hubspot/blog_subscribe\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The blog to use for the module."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Title for the module (wrapped in a h3 tag)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Subscribe Here!\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "response_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message that is shown upon submitting the form."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Thanks for subscribing!"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog posts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Add this module to blog listing pages to display blog post previews containing each post's title, featured image, author, publish date, and more with a clickable button that navigates to the post."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This default module has built using React, and you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react/tree/main/default-react-modules/src/components/modules/BlogPosts-V0",
        children: "view its source code on GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["This module cannot be accessed from the design manager. The module can be referenced with HubL in coded templates and added within the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/blog/edit-a-blog-listing-page",
            children: "blog listing page editor"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["This module replaces the previous ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "blog_listing"
          }), " module, which has been deprecated."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_posts\"\n  path=\"@hubspot/blog_posts\"\n  layout=\"grid\",\n  columns=4,\n  displayForEachListItem=[\n   \"image\",\n   \"title\",\n   \"authorName\",\n   \"tags\",\n   \"publishDate\",\n   \"description\",\n   \"button\"\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The content layout for each blog post preview.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "grid"
                }), " (default): aligns posts in a basic grid."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "singleColumn"
                }), ": aligns posts in a single column. with featured images on their own row above the rest of the post content."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "sideBySide"
                }), ": aligns posts in a column with featured images aligned horizontally with the post content."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), " layout, the number of posts per row. Can be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alternateImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sideBySide"
            }), " layout, set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " to align the featured image on the right and left side of the post preview, alternating."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fullImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "singleColumn"
            }), " layouts, set this field to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " to make the featured image the background of the post preview."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "displayForEachListItem"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The content to include in each blog post preview. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image"
                }), ": the post's featured image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": the post's title."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorImage"
                }), ": the post author's image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorName"
                }), ": the post author's name."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tags"
                }), ": the post's blog tags."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "publishDate"
                }), ": the post's publish date."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": the post's meta description."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button"
                }), ": the read more button that links to the blog post."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ 'image', 'title', 'authorImage', 'authorName', 'tags', 'publishDate', 'description', 'button' ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "buttonText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text that displays on the read more button, if included."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Read more"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog post filter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_filter\" path=\"@hubspot/post_filter\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Select a blog to display. Default will use the current blog when used in a blog template or the primary blog when used elsewhere."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Type of filtering links to show. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "tag"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "month"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "author"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tag"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "order_by"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ordering for the values of filter links. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "post_count"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "name"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_count"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An H3 heading."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Posts by Tag\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number of filter links to show. Leave blank to show all."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "expand_link_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Text to display if more than the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            }), " value to display are available."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"See all\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog post listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " a new version of this module was release at the end of March 2024. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-module-versioning#post-listing",
          children: "new default post listing module"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_listing\" path=\"@hubspot/post_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Select a blog to display. Default will use the current blog when used in a blog template or the primary blog when used elsewhere."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listing_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of listing for your posts. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "recent"
                }), ": most recent."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_all_time"
                }), ": most popular of all time."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_year"
                }), ": most popular the past year."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_six_months"
                }), ": most popular the past six months."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_month"
                }), ": most popular the past month."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An H3 heading."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Recent Posts\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maximum number of posts to display."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Button"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"button\" path=\"@hubspot/button\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL that the button redirects to."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"url\": { \"type\": \"EXTERNAL\", \"href\": \"www.hubspot.com\", \"content_id\": null }, \"open_in_new_tab\": false, \"no_follow\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that will be displayed on the button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Add a button link here\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_default_style"
                }), " (Boolean)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_font"
                }), " (Font)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_color"
                }), " (Color)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_hover_color"
                }), " (Color)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_hover_color"
                }), " (Color)"]
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_default_style\": false, \"button_font\": { \"color\": \"#FFFFFF\", \"size_unit\": \"px\" }, \"button_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"text_hover_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"button_hover_color\": { \"color\": \"#CCCCCC\", \"opacity\": 100 } }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Call-to-Action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"cta\" path=\"@hubspot/cta\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "guid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Globally Unique Identifier of the CTA."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can add this module to your subscription preferences page to allow contacts to request that their data be deleted. This function is required under certain data privacy laws. Once a contact requests that their data be deleted, they have 30 minutes to confirm in an email that will automatically be sent."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Users with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "super admin permissions"
      }), " will receive a notification email about these requests. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#download-data",
        children: "allow contacts to request a download of their data"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"delete_data\" path=\"@hubspot/delete_data\", label=\"delete_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Message displayed above the button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Delete Data  Permanently delete your personal data stored by {{ site_settings.company_name}}. Personal data is information that can be used to identify you and doesn't include anonymized data.  You'll get a follow-up email where you'll need to verify your request.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Button text."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data deletion"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The success and fail alert field group. Including the following field groups:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": rich text content of success alert."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": rich text content of fail alert."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": the icon to close the alert."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Divider"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Supported in pages. There's a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-module-versioning#divider",
        children: "new version of this module"
      }), " available in accounts created after August 25th, 2022. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/divider-module-v1",
        children: "Learn more about this change"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"divider\" path=\"@hubspot/divider\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pixel (px) height of the divider line."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Percentage (%) width of the divider line."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Color"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Color (hex) and opacity (number) of the divider line."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"color\": \"#000000\", \"opacity\": 100 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Line type. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "solid"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dotted"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dashed"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Horizontal alignment of divider line. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "right"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "center"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Show/hide top and bottom margining on the divider."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pixel (px) value for the margining on top and bottom of divider line. Option available when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Download data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can add this module to your subscription preferences page to allow contacts to request a copy of their data. This function is required under certain data privacy laws. Users with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "super admin permissions"
      }), " will receive a notification email about these requests. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#delete-data",
        children: "allow contacts to request that their data be deleted"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download_data\" path=\"@hubspot/download_data\", label=\"download_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Message displayed above the button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Download Data  Download your personal data stored by {{ site_settings.company_name}}.  Personal data is information that can be used to identify you and doesn't include anonymized data.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Button text."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The success and fail alert field group. Including the following field groups:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": rich text content of success alert."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": rich text content of fail alert."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": the icon to close the alert."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Form"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "H3 heading"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "form"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Form object including:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "form_id"
                }), ": ID for form to use"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), ": what the visitor will see after submitting the form:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "inline"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "redirect"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "message"
                }), ": inline message if ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "inline"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_id"
                }), ": ID of page to be redirected to if ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_url"
                }), ": URL to be redirected to if ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"form_id\": \"\", \"response_type\": \"redirect\", \"message\": \"Thanks for submitting the form.\", \"redirect_id\": null, \"redirect_url\": \"http://www.google.com\" }"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Email to send form notification to instead of form defaults."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_override_email_addresses"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Email"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Comma-separated list of emails to send to when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enabled sending a follow up email."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_email_for_live_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Followupemail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID of the follow-up email. Available when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sfdc_campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforcecampaign"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When Salesforce integration is active, the campaign ID."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Header"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"header\" path=\"@hubspot/header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text for the heading."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Choose a heading level. Choice include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " through ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Horizontal spacer"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, blog posts, and emails."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"horizontal_spacer\" path=\"@hubspot/horizontal_spacer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Icon"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts. Icons can be pulled from the Font Awesome 5.0.10 and 5.14.0 icon sets."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"icon\" path=\"@hubspot/icon\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the icon."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"hubspot\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "purpose"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An accessibility option that categorizes the purpose of the icon for screen readers. Available values are:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "decorative:"
                }), " ignored by the screen reader."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "semantic:"
                }), " read by the screen reader."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"decorative\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An accessibility option that assigns the icon a title."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of icon. Can be one of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "regular"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "light"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thin"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duotone"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"solid\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unicode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The icon's unicode value."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "f3b2"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Image"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"linked_image\" path=\"@hubspot/linked_image\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Image"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Image object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": image url"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": alt text for image"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": lazy loading options include:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "disabled"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "lazy"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": px value"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": px value"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"src\": \"https://static.hubspot.com/final/img/content/email-template-images/placeholder_200x200.png\", \"alt\": \"placeholder_200x200\", \"loading\": \"disabled\", \"width\": 200, \"height\": 200 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Optional link for the image."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opens link in a new tab."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Image grid"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"image_grid\" path=\"@hubspot/image_grid\", label=\"image_grid.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing information for each slide. Object contains:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": image URL."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL where the slide should link."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hover_messages"
                }), ": text that overlays on the image on hover."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Display mode of the Image Gallery. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": standard slider."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": thumbnail navigator."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": lightbox gallery."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Number of rows in the Lightbox gallery when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enables looping through the slides with next/prev when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Automatically advances to the next slide when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Amount of time (seconds) between advancing to the next slide when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Show navigation buttons when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the height of the slides when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": fixed height."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": variable height."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Slide transition styles when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": slide transition."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": fade transition."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Position of the slide captions when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": always keep captions below the image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": superimpose captions on top of images."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Image gallery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"gallery\" path=\"@hubspot/gallery\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing information for each slide. Object contains:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": image URL."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_caption"
                }), ": boolean to show slide caption."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": rich text for caption."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL where the slide should link."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_in_new_tab"
                }), ": boolean to direct if the link should open in a new tab."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Display mode of the Image Gallery. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": standard slider."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": thumbnail navigator."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": lightbox gallery."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Number of rows in the Lightbox gallery when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enables looping through the slides with next/prev when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Automatically advances to the next slide when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Amount of time (seconds) between advancing to the next slide when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Show navigation buttons when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the height of the slides when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": fixed height."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": variable height."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Slide transition styles when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": slide transition."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": fade transition."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Position of the slide captions when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": always keep captions below the image."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": superimpose captions on top of images."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Image slider gallery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_name\"\n    path=\"@hubspot/image_slider_gallery\",\n    slides=[\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>1</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        },\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>2</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        }\n    ]\n    slideshow_settings={\n        \"slides\": {\n        \"per_page\": 1,\n        \"sizing\": \"natural\",\n        \"aspect_ratio\": \"16/9\",\n        \"show_captions\": true,\n        \"caption_position\": \"below\"\n        },\n        \"movement\": {\n        \"transition\": \"slide\",\n        \"loop_slides\": false,\n        \"auto_advance\": false,\n        \"auto_advance_speed_seconds\": 5\n        },\n        \"navigation\": {\n        \"show_main_arrows\": true,\n        \"show_thumbnails\": false,\n        \"show_dots\": false\n        }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A field group containing the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": image URL."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": rich text for image caption."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL that the slide links to."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slideshow_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The image slider settings field group. Includes the following field groups:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slides"
                }), ": slide settings, including:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "per_page"
                    }), ": number of slides per page."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "sizing"
                    }), ": image sizing."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "aspect_ratio"
                    }), ": image aspect ratio."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "show_captions"
                    }), ": a boolean"]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "movement"
                }), ": image sliding behavior settings."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "navigation"
                }), ": image navigation settings."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The module's default text elements, including:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_caption"
                }), ": image caption."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_image_alt_text"
                }), ": image text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_aria_label"
                }), ": the module's default aria label."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "arial_label_thumbnails"
                }), ": the module's default aria thumbnail."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_nav_aria_label"
                }), ": the module navigation's default aria label."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "prev"
                }), ": previous slide text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "next"
                }), ": next slide text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "first"
                }), ": go to first slide text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slideX"
                }), ": go to X slide text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pageX"
                }), ": go to X page text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "play"
                }), ": start autoplay text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pause"
                }), ": pause autoplay text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "carousel"
                }), ": carousel text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "select"
                }), ": text for selecting a slide to show."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": slide text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slidelabel"
                }), ": slide label."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Language switcher"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"language_switcher\" path=\"@hubspot/language_switcher\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The language of the text in the language switcher. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pagelang"
                }), ": the names of languages will display in the language of the page the switcher is on."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "localized"
                }), ": the name of each language will display in that language."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hybrid"
                }), ": a combination of the two."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "localized"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo\" path=\"@hubspot/logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Image"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Image object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_inherited_src"
                }), ": override the default logo from settings"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": image url"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": alt-text for logo"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_inherited_src\": false, \"src\": null, \"alt\": null }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Optional link for the logo. If no URL is specified, your logo will link to the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Logo URL"
            }), " set in your ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors#edit-your-logo",
              children: "brand settings"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_tab"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opens link in a new tab."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hide the company name when an image is not selected."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Choose a heading level when no image is selected and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ". Choices include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " through ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logo grid"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A customizable grid of containers to display logos uniformly. Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo grid\"\n  path=\"@hubspot/logo_grid\"\n  group_logos=[\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company2_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo2.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"alt\": \"lorem-logo\",\n        \"height\": 40,\n        \"loading\": \"lazy\",\n        \"max_height\": 40,\n        \"max_width\": 175,\n        \"src\": \"https://www.example.com/Logos/color/logo3.png\",\n        \"width\": 175\n      }\n    }\n  ],\n  styles={\n    \"group_logo\": {\n      \"group_background\": {\n        \"aspect_ratio\": \"1/1\",\n        \"background_color\": {\n          \"color\": \"#8E7CC3\",\n          \"opacity\": 100\n        }\n      },\n      \"group_spacing\": {\n        \"padding\": {\n          \"padding\": {\n            \"bottom\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"left\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"right\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"top\": {\n              \"units\": \"px\",\n              \"value\": 75\n            }\n          }\n        }\n      },\n      \"max_logo_height\": 85\n    },\n    \"group_logo_grid\": {\n      \"column_count\": 3,\n      \"grid_gap\": 54\n    }\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An array containing an object for each logo in the grid."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "logo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["In ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            }), ", an object for each logo in the grid. Each logo can include the following parameters:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": the file path of the logo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/performance/lazy-loading",
                  children: "lazy load"
                }), " behavior of the logo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": the logo's alt-text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": the logo's height."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": the logo's width."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_height"
                }), ": the logo's maximum height."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_width"
                }), ": the logo's maximum width."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "styles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing the style fields that affect the grid layout, logo containers, and logo images. This array contains the following style groups:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo"
                }), ": styles that affect the logo containers and logo images. Contains the following:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_background"
                    }), ": styles that set the aspect ratio and background color of the grid containers. Aspect ratios include: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "1/1"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "4/3"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "16/9"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_spacing"
                    }), ": styles that set the inner padding of the logo container. For wider logos, higher padding value may decrease logo width."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "max_logo_height"
                    }), ": the maximum height of each logo."]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo_grid"
                }), ": styles that set the grid layout, including:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "column_count"
                    }), ": the number of columns in the grid."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "grid_gap"
                    }), ": the spacing between grid items."]
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Meetings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"meetings\" path=\"@hubspot/meetings\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meeting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Meeting"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Select a meeting link."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Membership social logins"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This module provides Google and Facebook login capability to memberships sites. The user must sign-in with an account linked to the email for the contact in the CRM. You can choose which social logins to enable."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Supported in membership login pages."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\",\n        clientid=\"\"\n        appid=\"\"\n        facebook_enabled=true\n        google_enabled=true\n        %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Facebook requires having a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/register",
          children: "Facebook developer account"
        }), ", and a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/create-an-app",
          children: "facebook app created"
        }), ", with basic settings. Once you've done that your app id is what you pass to the module."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Google requires a Google account, and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/identity/sign-in/web/sign-in#create_authorization_credentials",
          children: "authorization credentials created"
        }), ", once you have that your app's client id is what you pass to the module."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your facebook app ID."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enable the button for Facebook login. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            }), " is required**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your Google client ID."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enable the button for Google login. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            }), " is required**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Looking to build your own custom menu? Try our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "menu() function"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"menu\" path=\"@hubspot/menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID of the menu."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "root_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Advanced menu type. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "site_root"
                }), ": always show top-level pages in menu."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "top_parent"
                }), ": show pages in menu that are related to section being viewed."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "parent"
                }), ": show pages in menu that are related to page being viewed."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "breadcrumb"
                }), ": breadcrumb style path menu (uses horizontal flow)."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_root"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_levels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determines the number of menu tree children that can be expanded in the menu. Choices include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " through ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Orientation of the menu. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flyouts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enabled hover over functionality for child menu items."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Page footer"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"page_footer\" path=\"@hubspot/page_footer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Password prompt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"password_prompt\" path=\"@hubspot/password_prompt\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submit_button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that appears on the submit button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Submit\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "password_placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Placeholder text for the password field."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Password\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bad_password_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Message to show when a password is entered incorrectly."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Sorry, please try again. \""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Payments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payments\" path=\"@hubspot/payments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["To set the module to use a specific payment link, include the ID of the payment link. You can ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#link#find-a-payment-link-s-id",
              children: "find the ID"
            }), " in the URL while editing the payment link."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set whether the payment form opens in a new tab or in an overlay. Available values are:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "new_tab"
                }), ": opens the payment form in a new tab."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "overlay"
                }), ": opens the payment form in a sliding overlay."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"new_tab\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sets the text of the checkout button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Checkout\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the button uses a HubSpot payment link or an external link. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "payment_link"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"payment_link\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_link"
            }), ", sets the destination of the external link. Supported link types include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Product"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Displays a product from the account's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "product library"
      }), ". Supported in pages, blog posts, and blog listings."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/product\",\n  product={\n    \"id\" : 2124070179\n  },\n  group_button={\n    \"button_text\" : \"Buy\",\n    \"override_product_button\" : true,\n    \"button_override\" : {\n      \"no_follow\" : false,\n      \"open_in_new_tab\" : false,\n      \"sponsored\" : false,\n      \"url\" : {\n        \"href\" : \"https://www.test.com\",\n        \"type\" : \"EXTERNAL\"\n      }\n  },\n  group_description={\n    \"description_override\" : \"Monthly gym membership with access to shared locker facilities.\",\n    \"override_product_description\" : true\n  },\n  group_name={\n    \"heading_level\" : \"h3\",\n    \"name_override\" : \"Gym membership\",\n    \"override_product_name\" : true\n  },\n  group_image={\n    \"image_override\" : {\n      \"alt\" : \"360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS\",\n      \"height\" : 360,\n      \"loading\" : \"lazy\",\n      \"src\" : \"https://2272014.fs1.hubspotusercontent-na1.net/hubfs/2272014/360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS.jpg\",\n      \"width\" : 640\n    },\n    \"override_product_image\" : true\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Product"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The product to display, specified by product ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, the module includes a button that directs users to the product's set URL. To customize the button destination, include this optional field group along with the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_text"
                }), ": a string that sets the button's text."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_button"
                }), ": set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to override the default button link settings. Then, include a ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), " object. Learn more about ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), " below."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            }), " field group, this sets the button's URL behavior when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "override_product_button"
            }), " is set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ".Includes the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "no_follow"
                }), ": boolean field for the link's ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://ahrefs.com/blog/nofollow-links",
                  children: "no_follow"
                }), " behavior."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_link_in_new_tab"
                }), ": boolean field for the link's open behavior."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), ": an object that specifies the button's destination."]
              })]
            }), "In the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), " field, you can set the type of destination through the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), " field, which supports the following content types:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                }), ": a standard URL. Include the URL in an ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), " field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                }), ": a HubSpot page. Include the page ID in a ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content_id"
                }), " field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "PAYMENT"
                }), ": a payment link. Include the payment link in an ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), " field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EMAIL_ADDRESS"
                }), ": an email address. Include the address in an ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), " field."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, the module will display the product name at the top of the module as an h3. To customize the name, include this optional field group along with the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "heading_level"
                }), ": the heading size. Can be ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h1"
                }), " - ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h6"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_name"
                }), ": set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to specify text rather than the product name."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "name_override"
                }), ": the string that you want to display at the top of the module."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, the module will display the product's set description. To customize the description, include this optional field group along with the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_description"
                }), ": set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to customize the product description."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description_override"
                }), ": a string containing your new description."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, the module will display the product's set image. To customize this image, include this optional field group with the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_image"
                }), ": set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to specify a new image. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image_override"
                }), ": an object that specifies the new image, including the following properties:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "height"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "loading"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "width"
                    })
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quote download"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in quote templates."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download\" path=\"@hubspot/quote_download\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text displayed on the download button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "download_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Error message displayed if the download fails."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem downloading the quote. Please try again."
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quote payment"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in quote templates."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payment\" path=\"@hubspot/quote_payment\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The heading displayed above the payment section of a quote template."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of heading used to display ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            }), ". Values include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h4"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The payment button text."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pay now"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "needs_signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Button text when a signature is required."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment can't be made because the quote isn't fully signed."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Message that displays when there's an error during checkout."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem setting up checkout. Please contact the person who sent you this quote."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_status_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Message that displays when there's an error when trying to make a payment."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem loading the payment status for this quote. Please try refreshing the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "paid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A successful payment status indicator."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Paid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_processing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A payment processing status indicator."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment processing"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quote signature"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in quote templates."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"signature\" path=\"@hubspot/quote_signature\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a quote requires an e-signature, the following fields are available within an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "esignature"
      }), " object:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pretext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-signature explanation text."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Before you sign this quote, an email must be sent to you to verify your identity. Find your profile below to request a verification email."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verify_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that displays on the verification button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verify to sign"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "failure"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Alert text that displays when the signature information can't be retrieved."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem retrieving the signature information. Please reload the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verification_sent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A status indicator that appears when the verification request has been successfully sent to the quote signer."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verification sent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that displays when the quote has been successfully signed."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a quote requires a printed signature, the following fields are available within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "print_signature"
      }), " object:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text displayed at the top of the signature section."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that displays next to the signature field."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that displays next to the date field."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "printed_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that displays next to the printed name field."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Printed name"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "countersignature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing content for the countersignature section. This object can contain the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "header"
                }), ": text that displays at the top of the countersignature section."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "countersignature"
                }), ": Text that displays next to the countersignature field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "date"
                }), ": text that displays next to the date field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "printed_name"
                }), ": text that displays next to the printed name field."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Line items"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in quote templates. This module also includes the default text used on custom quotes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"module_165350106057652\" path=\"@hubspot/line_items\", label=\"line_items.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The title of the line item column."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Column description"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "use_additional_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Display checkbox to allow users to select additional line item columns from product properties."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "additional_product_properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A product property. Choices include: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "price"
                }), ": price of line item. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "item_description"
                }), ": description of line item. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "quantity"
                }), ": number of line items. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "amount"
                }), ": total cost of line items. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hs_recurring_billing_start_date"
                }), ": billing start date for recurring line items. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "discount"
                }), ": discount amount applies to the line item. "]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRM object property"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Select any product property to appear as a line item column header."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rich text"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in all template types."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rich_text\" path=\"@hubspot/rich_text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HTML block."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ## Something Powerful  \\n  ### Tell The Reader More  \\n  The headline and subheader tells us what you're [offering](\\\"#\\\"), and the form header closes the deal. Over here you can explain why your offer is so great it's worth filling out a form for.  \\n  Remember:  \\n<ul>\\n<li>Bullets are great</li>\\n<li>For spelling out [benefits](\\\"#\\\") and</li>\\n<li>Turning visitors into leads.</li>\\n</ul>"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "RSS listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rss_listing\" path=\"@hubspot/rss_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An H3 heading."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Test\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Type of RSS feed. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog"
                }), " - HubSpot hosted blog."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " - An external RSS feed."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["RSS URL to use when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "external"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_group_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Id of the blog to use when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "topic_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tag"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["(optional) Id of the tag to filter by when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_items"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maximum number of posts to display."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Include the featured image."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Show the author name."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribution_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Text that attributes an author to a post. Displayed when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"by\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Show post summary text."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit_to_chars"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Limits the length of the summary text when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "200"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "click_through_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The text which will be displayed for the clickthrough link at the end of a post summary when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Read more\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Show publish date."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Format for the publish date when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ". Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                }), " (ex: 06/11/06 12:00PM)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                }), " (ex: Jun 6, 2006 12:00:00 pm)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                }), " (ex: June 6, 2017 12:00:00 pm EDT)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MMMM d, yyyy 'at' h:mm a"
                }), " (ex: June 6, 2006 at 12:00 pm)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "h:mm a 'on' MMMM d, yyyy"
                }), " (ex: 12:00 pm on June 6, 2006)"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "short"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The text that indicates when a post was published when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"posted at\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Site search input"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"./local-search_input\"\n  placeholder=\"Search\"\n  include_search_button=true\n  results={\n   \"use_custom_search_results_template\": \"true\",\n   \"path_id\": \"77977569400\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "field_label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Search input label text"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Placeholder text for the input field."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Search\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_search_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Include a search button."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Which content types to include in search results. Object contains the following keys with boolean values:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "website_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "landing_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_posts"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "knowledge_articles"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"website_pages\": true, \"landing_pages\": false, \"blog_posts\": true, \"knowledge_articles\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "results"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object that enables controls for using a custom search results page. Includes the following properties:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "use_custom_search_results_template"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(boolean):"
                }), " when set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", users can select a custom search results page. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "path_id"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(string):"
                }), " the ID of the page that will be used for search results. The referenced page must contain the search results module."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Search results"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog posts, and blog listings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_featured_images"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Display featured images for items."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Section header"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"section_header\" path=\"@hubspot/section_header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Section header content."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Heading level for the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            }), ". Choices include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " through ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subheader"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Subheading paragraph text for the section."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A more subdued subheader\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Simple menu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"simple_menu\" path=\"@hubspot/simple_menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "menu_tree"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Simple Menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Simple menu object."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "orientation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Orientation of the menu. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Social follow"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Add links to your social media profiles, with drag and drop reordering in the content editor. Automatically inserts icons based on the social URL, but icons can be overridden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_follow\" path=\"@hubspot/social_follow\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The destination link for the social page added in the editor."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_window"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", link will open in the same browser tab."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " to display a podcast icon instead of a default or custom icon."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, alt-text is automatically generated. When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", you can override the default alt-text with a custom value using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria_label"
            }), " field."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria-label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", this field sets the icon alt-text (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Follow us on Facebook"
            }), "). This is used by screen readers to describe the icon to users who are visually impaired."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, icons are auto-populated based on the link URL. When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            }), " field to select one of the other provided icons (Font Awesome 6.4.2)."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Icon"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", use this field to specify a custom icon from the provided set. Available icons are from Font Awesome 6.4.2."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Social sharing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_sharing\" path=\"@hubspot/social_sharing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note: The variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "social_link_url"
      }), " in the default column below is the same value as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "link"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This is the destination link that will be shortened for easier sharing on social networks."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolean to enable social item"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": custom URL for socials sharer URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.facebook.com/share.php?u={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "twitter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolean to enable social item"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": custom URL for socials sharer URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("code", {
              children: ["{ \"enabled\": false, \"custom_link_format\": \"", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://twitter.com/intent/tweet?original_referer=%5C%7B%5C%7B",
                children: "https://twitter.com/intent/tweet?original_referer=\\{\\{"
              }), " social_link_url }}&url={{ social_link_url }}&source=tweetbutton&text={{ social_page_title|urlencode }}\" }"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "linkedin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolean to enable social item"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": custom URL for socials sharer URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.linkedin.com/shareArticle?mini=true&url={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pinterest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolean to enable social item."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": custom URL for socials sharer URL."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pinterest_media"
                }), ": image object including:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    }), ": image URL."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    }), ": alt-text for the image."]
                  })]
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://pinterest.com/pin/create/button/?url={{ social_link_url }}&media={{ pinterest_media }}\", \"pinterest_media\": { \"src\": \"\", \"alt\": null } }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": boolean to enable social item"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": custom URL for socials sharer URL"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"mailto:?subject=Check out {{ social_link_url }} &body=Check out {{ social_link_url }}\" }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tabbed card"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/tabbed_card\",\n  tabs=[\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 1.</p>\",\n    \"tab_label\" : \"Tab 1 label\"\n   },\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 2.</p>\",\n    \"tab_label\" : \"Tab 2 label\"\n   }\n  ],\n  fixed_height=false\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tabs"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Field group"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A field group that contains the tab label and text content of each tab. Contains the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tab_label"
                }), ": the label of the tab."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "content"
                }), ": the tab's rich text content."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fixed_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["By default, the tabs will be set at a fixed height so that the container height stays the same when switching between tabs. You can set this to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " if you want the tabbed card container height to be based on the active tab's content."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "One line of text"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"text\" path=\"@hubspot/text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add your text to this parameter."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Video"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages, blog listings, and blog posts."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video\" path=\"@hubspot/video\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Type of video. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "embed"
                }), ": embed code from an external source."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hubspot_video"
                }), ": HubSpot hosted video."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Video Player"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["HubSpot hosted video. Used when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Embed"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Supports embed types:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "oembed"
                }), (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "video"
                    }), ": URL for video."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "html"
                }), ": embed code for video."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Image"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Override oembed thumbnail image when ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            }), " equals ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail_play_button_color"
            }), " - Color field."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"oembed_thumbnail_play_button_color\":\"#ffffff\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder_fields"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Object containing:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_title"
                }), ": text field."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_description"
                }), ": text field."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"placeholder_title\":\"No video selected\", \"placeholder_description\":\"Select a video type in the sidebar.\"}"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Video embed (landing page)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supported in pages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video_embed_lp\" path=\"@hubspot/video_embed_lp\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL for video. URLs from Vimeo and YouTube are supported."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://www.youtube.com/watch?v=X1Rr5BFO5rg\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the video to full width (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ") or manually set width and height (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ")."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Max width (px)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "800"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Max height (px)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "450"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "WhatsApp link"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Supported in pages, blog posts, and blog listing pages. Requires a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
        children: "connected WhatsApp channel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"whatsapp_link\" path=\"@hubspot/whatsapp_link\", label=\"whatsapp_link\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Select from the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
              children: "WhatsApp channels connected to the account"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optin_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/collect-consent-for-whatsapp-conversations#subscribe-a-contact-using-opt-in-keywords-in-a-whatsapp-thread",
              children: "opt-in and opt-out text"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_display"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Select whether the button displays text, a WhatsApp icon, or both. Choices include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_and_icon"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_and_icon"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Choice"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The position of the icon on the button. Choices include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "right"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The button's text."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Chat on WhatsApp"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_icon_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The icon text for screen readers."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WhatsApp Icon"
            })
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}