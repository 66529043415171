"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611088;
const slug = exports.slug = 'getting-started/slack/developer-slack';
const title = exports.title = 'Participe da comunidade de desenvolvedores da HubSpot no Slack';
const name = exports.name = 'Inscreva-se no Slack - Designers Desenvolvedores';
const metaDescription = exports.metaDescription = 'O Slack de Desenvolvedores da HubSpot é uma comunidade onde os desenvolvedores se reúnem para apoiar e colaborar uns com os outros na plataforma HubSpot. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Colaborar no Slack do desenvolvedor';
const toc = exports.toc = [{
  "depth": 0,
  "id": "para-quem-%C3%A9-a-comunidade-de-desenvolvedores-do-slack%3F",
  "label": "Para quem é a comunidade de desenvolvedores do Slack?",
  "parent": null
}, {
  "depth": 0,
  "id": "o-que-posso-esperar-ao-participar-da-comunidade%3F",
  "label": "O que posso esperar ao participar da comunidade?",
  "parent": null
}, {
  "depth": 0,
  "id": "junte-se-ao-nosso-slack-de-desenvolvedores-se-inscrevendo-abaixo",
  "label": "Junte-se ao nosso Slack de Desenvolvedores se inscrevendo abaixo",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Form
    } = _components;
  if (!Form) _missingMdxReference("Form", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Participe da comunidade de desenvolvedores da HubSpot no Slack"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O Slack de Desenvolvedores da HubSpot é uma comunidade onde os desenvolvedores se reúnem para apoiar e colaborar uns com os outros na plataforma HubSpot. Criada em 2016, a comunidade de desenvolvedores no Slack cresceu para incluir centenas de desenvolvedores ativos em todo o mundo, todos reunidos para compartilhar conhecimento e experiência."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Para quem é a comunidade de desenvolvedores do Slack?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Seja você um iniciante no desenvolvimento na plataforma HubSpot ou um especialista experiente, o Slack de Desenvolvedores é seu lugar ideal. Temos canais dedicados a vários aspectos da nossa plataforma, como APIs, Desenvolvimento de e-mails, Desenvolvimento local usando nossa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Modelos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Temas"
      }), " e muito mais."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "O que posso esperar ao participar da comunidade?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode esperar encontrar uma comunidade ativa de desenvolvedores que apoiam e ensinam uns aos outros. Esta é antes de tudo uma comunidade; quanto mais investir nela, mais lucrará com ela. A comunidade é global, mas a maioria dos participantes está localizada no hemisfério ocidental. Isso significa que se você mora no hemisfério oriental, pode não receber respostas rápidas. Não é que as pessoas estejam ignorando ou não vendo seus posts, provavelmente é porque estão dormindo. Para mudar isso, você pode ser uma das pessoas ativas no seu fuso horário e ajudar os outros. Assim, a comunidade crescerá e será mais útil para você. Na HubSpot, valorizamos muito essa comunidade e fazemos parte dela. Trate os outros com respeito; trate os HubSpotters com respeito. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/code-of-conduct",
        children: "Revise nosso Código de Conduta da Comunidade do Slack"
      }), ". Vamos crescer melhor juntos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Junte-se ao nosso Slack de Desenvolvedores se inscrevendo abaixo"
    }), "\n", ' ', "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "7fd756b1-d981-4b81-9caf-8a79a25bffb2",
      responseType: "inline",
      message: `<br/><button target="_blank" href="https://cta-redirect.hubspot.com/cta/redirect/53/18a2bcd4-227f-46b9-b7e4-52dba8955ae5">Enter the Slack community</button>`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}