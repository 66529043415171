"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 156182192007;
const slug = exports.slug = 'reference/ui-components/standard-components/button';
const title = exports.title = 'Button';
const name = exports.name = 'Button | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Button component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      a: "a",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Button | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Button"
      }), " component renders a single button. Use this component to enable users to perform actions, such as submitting a form, sending data to an external system, or deleting data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Button text is passed into the component like a standard HTML element, rather than through a prop."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/Button%20component%20variants.png",
        alt: "Button component variants"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Button text:"
        }), " the visible button text that describes the button's action."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Variant:"
        }), " the color of the button. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#variants",
          children: "available button variants below"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Button } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Button\n      onClick={() => {\n        console.log('Someone clicked the button!');\n      }}\n      href={{\n        url: 'https://wikipedia.org',\n        external: true,\n      }}\n      variant=\"primary\"\n      size=\"md\"\n      type=\"button\"\n    >\n      Click me!\n    </Button>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Prop"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Include this prop to open a URL on click. Contains the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), " (string): the URL that will open on click."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to open the URL in a new tab and display an external link icon. By default:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "Links to HubSpot app pages will open in the same tab and will not include an icon."
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "Links to non-HubSpot app pages will open in a new tab and include the icon."
                  })]
                })]
              })]
            }), "When a button includes both ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), " and an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            }), " action, both will be executed on button click."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "() => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the button is clicked. It receives no arguments and it's return value is ignored."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the button will render in a greyed-out state and cannot be clicked."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'primary'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'secondary'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'destructive'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'transparent'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the color of the button. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants section"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'button'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'reset'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'submit'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sets the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "role"
            }), " HTML attribute of the button."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "size"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'xs'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'extra-small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'sm'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'med'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), " (default)"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The size of the button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overlay"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Include a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), " component in this object to open it as an overlay on click. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/crm/ui-extensions/sdk#open-an-overlay",
              children: "using overlays"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop, you can set the color of the button."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Primary:"
          }), " a dark blue button for the most frequently used or most important action on an extension. Each extension should only have one primary button."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-guide-button-type-primary.png",
            alt: "design-guide-button-type-primary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Secondary:"
          }), " a grey button to provide alternative or non-primary actions. Each extension should include no more than two secondary buttons."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-guide-button-type-secondary.png",
            alt: "design-guide-button-type-secondary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Destructive:"
          }), " a red button for actions that delete, disconnect, or perform any action that the user can't undo. Button text should clearly communicate what is being deleted or disconnected. After a destructive button is clicked, the user should have to verify or confirm the action."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-guide-button-type-destructive.png",
            alt: "design-guide-button-type-destructive"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Transparent:"
          }), " a button with the background and border color removed, styled like a hyperlink."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-components-button-transparent-variant.png",
            alt: "uie-components-button-transparent-variant"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " HubSpot does not provide variant options for the orange buttons you’ll find across the app (both solid and outlined). Those color variants are reserved for the HubSpot product, which helps to maintain the hierarchy of available actions on a given page."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary"
        }), " button at the end of a form to submit data to another system."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary"
        }), " button next to a primary form submit button to reset form fields."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "destructive"
        }), " button to enable users to delete a contact's data from an external system."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Set a button to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "disabled"
        }), " when a contact doesn't qualify for a form submission due to missing criteria or other ineligibility."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " set button text that clearly communicates what action will occur when a user clicks it. Text should be unambiguous and concise (~2-4 words)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " use sentence-casing for button text (only the first word capitalized)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " minimize the number of buttons that appear on a page record across all extensions."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " always open links to pages outside of the HubSpot app in a new tab (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "external: true"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " include multiple primary buttons in a single extension."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use a destructive button unless the consequences are significant or irreversible."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button-row",
          children: "ButtonRow"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button-row"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/overview",
          children: "CRM action components"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/overview"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-button",
          children: "CrmActionButton"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/panel",
          children: "Panel"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}