import { isMultiSelect, isFilesArray } from './fieldTypes';

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function shouldDisplayLabelForSchema(schema, definitions = {}) {
  switch (schema.type) {
    case 'array':
      return isMultiSelect(schema, definitions) || isFilesArray(schema, definitions);
    case 'object':
      return false;
    default:
      return true;
  }
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function breakLabel(label) {
  return label.replace(/([A-Z])/g, '\u200B$1');
}