"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937504;
const slug = exports.slug = 'guides/api/crm/objects/companies';
const title = exports.title = 'CRM API | 会社レコード';
const name = exports.name = 'vNext Docs DP - 会社レコード';
const metaDescription = exports.metaDescription = '会社レコードには、企業に関するデータを格納します。会社エンドポイントを使用することにより、会社データを管理したり、HubSpotと他のシステムとの間で同期したりできます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "会社レコードを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "プロパティー",
  "parent": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91",
  "label": "関連付け",
  "parent": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "会社レコードを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "会社レコードを更新する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E4%BB%96%E3%81%AE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%A2%E3%82%AF%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "既存の会社レコードを他のレコードまたはアクティビティーに関連付ける",
  "parent": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "関連付けを削除する",
  "parent": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%A7%E3%82%A2%E3%82%AF%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E3%83%94%E3%83%B3%E7%95%99%E3%82%81%E3%81%99%E3%82%8B",
  "label": "会社レコードでアクティビティーをピン留めする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%BC%9A%E7%A4%BE%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "会社レコードを削除する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "会社レコード"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotの会社レコードには、自社とやり取りする組織に関する情報が保存されます。会社エンドポイントを使用することにより、会社レコードを作成および管理したり、HubSpotと他のシステムの間で会社データを同期したりできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクト、レコード、プロパティー、および関連付けAPIの詳細については、「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRMについて"
      }), "」のガイドをご確認ください。HubSpotのオブジェクトとレコードに関する一般的な情報については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "CRMデータベースを管理する方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "会社レコードを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しい会社レコードを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト内のpropertiesオブジェクトに会社データを含めます。また、associationsオブジェクトを追加して、新しい会社レコードを既存のレコード（コンタクト、取引など）やアクティビティー（ミーティング、メモなど）に関連付けることもできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["会社の詳細は、会社プロパティーに保存されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
        children: "HubSpotの既定の会社プロパティー"
      }), "がありますが、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "カスタムプロパティーを作成"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しい会社レコードを作成する際は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), "プロパティーのうち、", (0, _jsxRuntime.jsx)("u", {
        children: "少なくとも1つ"
      }), "をリクエストに含める必要があります。ドメイン名は会社レコードを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "一意に識別する主要なID"
      }), "であるため、HubSpotで会社が重複しないようにするために、常に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), "を含めることを推奨します。会社レコードが", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/add-multiple-domain-names-to-a-company-record",
        children: "複数のドメイン"
      }), "を所有している場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_additional_domains"
      }), "フィールドに各ドメインをセミコロンで区切って指定することにより、API経由でドメインを追加できます。例：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hs_additional_domains\" : \"domain.com; domain2.com; domain3.com\""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["利用可能な全てのプロパティーを確認するには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/companies"
      }), "に送信して、アカウントの会社プロパティーのリストを取得します。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "プロパティーAPI"
      }), "をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), "を含める場合、値はライフサイクルステージの内部名を参照する必要があります。既定のステージの内部名はテキスト値であり、ステージの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "ラベル"
        }), "（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), "や", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "など）を編集してもこれは変更されません。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "カスタムステージ"
        }), "の内部名は数値です。ステージの内部IDを見つけるには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "ライフサイクルステージ設定"
        }), "を確認するか、APIを使ってライフサイクル ステージ プロパティーを取得します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、新しい会社レコードを作成する場合、リクエストの内容は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付け"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しい会社レコードを作成する際に、その会社レコードをassociationsオブジェクトの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "既存のレコード"
      }), "や", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "アクティビティー"
      }), "に関連付けることもできます。例えば、新しい会社レコードに既存のコンタクトとEメールアドレスを関連付ける場合、リクエストの内容は次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 280\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 185\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "associationsオブジェクトには、次のものを含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["会社レコードを関連付けるレコードまたはアクティビティー。一意の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "値で指定します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["会社とレコード／アクティビティーの間の関連付けのタイプ。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "および", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), "を含めます。既定の関連付けタイプIDは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "このページ"
            }), "で確認できます。または、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "関連付けAPI"
            }), "を使用して、カスタム関連付けタイプ（ラベル）の値を取得することもできます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "会社レコードを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "会社レコードを個別に、または一括で取得できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["個々の会社レコードを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies/{companyId}"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全ての会社レコードのリストを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのエンドポイントに対し、リクエストのURLに次のクエリーパラメーターを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで返されるプロパティーのカンマ区切りリスト。リクエスト対象の会社レコードで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで返される現在および過去のプロパティーのカンマ区切りリスト。リクエスト対象の会社レコードで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けられているIDが取得されるオブジェクトのカンマ区切りリスト。存在しない関連付けを指定した場合、その関連付けはレスポンスで返されません。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "関連付けAPI"
            }), "をご参照ください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["レコードIDまたは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "一意のカスタム識別子プロパティー"
        }), "を指定して、特定の会社レコードを一括で取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/companies/batch/read"
        }), "に送信します。このバッチエンドポイントは関連付けを取得", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "関連付けAPI"
        }), "を使用してバッチが関連付けを読み取る方法について説明します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一括読み取りエンドポイントの場合は、任意指定の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターを使用し、カスタムの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "一意の識別子プロパティー"
      }), "を指定して、会社レコードを取得することもできます。既定で、リクエストの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値はレコードID（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "）を参照するため、レコードIDを使用して取得する場合は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターは不要です。一意のカスタム値プロパティーを使用して会社レコードを取得する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "パラメーターを含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、会社レコードを一括で取得する場合のリクエストは、次のいずれかのようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"idProperty\": \"uniquepropertyexample\",\n  \"inputs\": [\n    {\n      \"id\": \"abc\"\n    },\n    {\n      \"id\": \"def\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロパティーの現在の値と過去の値を含む会社を取得する場合のリクエストは、次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"name\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "会社レコードを更新する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "会社レコードを個別に、または一括で更新できます。既存の会社の場合、APIを介して会社レコードを更新するために使用可能な一意の値は、会社のレコードIDです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["会社IDを基準に個々の会社レコードを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), "に送信して、更新するデータを含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存の会社レコードを他のレコードまたはアクティビティーに関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["会社レコードを他のCRMレコードまたはアクティビティーに関連付けるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "値を取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "この既定値リスト"
        }), "を参照するか、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "に送信します。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコードを関連付ける方法について詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "ご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["会社レコードと他のレコードまたはアクティビティーの関連付けを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "のURLに送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "会社レコードでアクティビティーをピン留めする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "フィールドを含めると、APIを使用して会社レコードでアクティビティーをピン留めできます。フィールドに、ピン留めするアクティビティーの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "を含めます。これは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "エンゲージメントAPI"
      }), "を介して取得できます。レコードごとに1つのアクティビティーをピン留めすることができ、そのアクティビティーはピン留めする前にすでに会社レコードに関連付けられている必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "会社のピン留めされたアクティビティーを設定または更新するには、次のようにリクエストします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/companies/{companyId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "会社を作成し、既存のアクティビティーに関連付け、同じリクエストにアクティビティーをピン留めすることもできます。以下に例を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/companies\n{\n  \"properties\": {\n    \"domain\": \"example.com\",\n    \"name\": \"Example Company\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 189\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "会社レコードを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["会社レコードを個別に、または一括で削除できます。削除した会社レコードは、HubSpot内のごみ箱に追加されます。そのため、必要に応じて後で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "HubSpot内で会社レコードを復元"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["IDを基準に個々の会社レコードを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "会社レコードを一括で削除する方法については、この記事の上部にある［エンドポイント］__タブをクリックしてください。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}