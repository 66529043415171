"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694130;
const slug = exports.slug = 'guides/cms/content/themes/getting-started';
const title = exports.title = 'Erste Schritte mit Designs';
const name = exports.name = '[neu] Erste Schritte mit Designs';
const metaDescription = exports.metaDescription = 'Erste Schritte mit Designs in CMS Hub. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-ein-boilerplate-designprojekt-starten",
  "label": "1. Ein Boilerplate-Designprojekt starten",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-die-cms-boilerplate-auf-ihren-hubspot-account-hochladen",
  "label": "2. Die CMS Boilerplate auf Ihren HubSpot-Account hochladen",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-eine-seite-erstellen",
  "label": "3. Eine Seite erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-designfelder-bearbeiten",
  "label": "4. Designfelder bearbeiten",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-f%C3%BCr-lokale-%C3%A4nderungen-vorbereiten",
  "label": "5. Für lokale Änderungen vorbereiten",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-ein-designfeld-hinzuf%C3%BCgen",
  "label": "6. Ein Designfeld hinzufügen",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-in-ihrem-css-auf-das-feld-verweisen",
  "label": "7. In Ihrem CSS auf das Feld verweisen",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-%C3%A4nderungen-testen",
  "label": "8. Änderungen testen",
  "parent": null
}, {
  "depth": 0,
  "id": "n%C3%A4chste-schritte",
  "label": "Nächste Schritte",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erste Schritte mit Designs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Design ist ein übertragbares und eigenständiges Paket von Entwicklerelementen, die zusammen eine flexible Bearbeitung von Inhalten ermöglichen. Zu diesen Elementen können Vorlagen, Module, CSS-Dateien, JavaScript-Dateien, Bilder und vieles mehr gehören. Mithilfe von Designs können Entwickler eine Reihe von Designfeldern erstellen, ähnlich wie Modulfelder, die es Content-Autoren ermöglichen, globale Website-Stile zu steuern, ohne CSS bearbeiten zu müssen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können HubL verwenden, um im gesamten CSS des Designs auf die Werte von Designfeldern zuzugreifen. Content-Autoren können dann mithilfe des Design-Editors Designfelder bearbeiten, eine Vorschau dieser Änderungen im Vergleich zu den vorhandenen Vorlagen innerhalb eines Designs anzeigen und ihre Änderungen veröffentlichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/cms-themes-animation.gif",
        alt: "UI-Animation zum Bearbeiten von Designeinstellungen, die die Auswahl einer Farbe für Elemente eines Designs zeigt."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieses Dokument führt Sie durch die Erstellung Ihres ersten Designs auf Grundlage der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMS Boilerplate"
      }), ". Weitere Informationen zu Designs finden Sie in der", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: " Referenzdokumentation für Designs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn Sie zum ersten Mal mit CMS Hub entwickeln, sollten Sie sich diese Ressource ansehen:"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Schneller Einstieg in die CMS Hub-Entwicklung"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bevor Sie beginnen, müssen Sie das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        }), " installieren."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Ein Boilerplate-Designprojekt starten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Führen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create website-theme my-website-theme"
      }), " aus, um ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-website-theme"
      }), "-Verzeichnis zu erstellen, das mit Dateien von der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "CMS-Design-Boilerplate"
      }), " gefüllt wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Die CMS Boilerplate auf Ihren HubSpot-Account hochladen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Führen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-website-theme my-website-theme"
      }), " aus. Dadurch wird die Vorlage in den Design-Manager Ihres Accounts hochgeladen, und zwar in einen Ordner mit dem Namen ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "my-website-theme"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Eine Seite erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So erstellen Sie eine Seite mit dem hochgeladenen Design:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website-Seiten"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erstellen"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website-Seite"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie im Dialogfeld die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Domain"
        }), " aus, auf der die Seite veröffentlicht werden soll, und geben Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Seitennamen"
        }), " ein. Klicken Sie anschließend auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Seite erstellen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-page-from-dashboard.gif",
        alt: "create-page-from-dashboard"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Im Bildschirm für die Vorlagenauswahl werden Vorlagen von Ihrem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/use-themes#use-an-active-theme",
          children: "aktiven Design"
        }), " oben auf der Seite angezeigt.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn Sie kein aktives Design ausgewählt haben, bewegen Sie den Mauszeiger über die ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS-Design-Boilerplate"
            }), " und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Als aktives Design festlegen"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn Sie bereits ein aktives Design festgelegt haben, wählen Sie Ihr neues Design aus, indem Sie auf das Dropdown-Menü für die ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Designauswahl"
            }), " klicken und dann ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Design ändern"
            }), " auswählen. Bewegen Sie den Mauszeiger über die ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS-Design-Boilerplate"
            }), " und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Als aktives Design festlegen"
            }), ". Wählen Sie im nächsten Bildschirm eine ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vorlage"
            }), " aus."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/theme-selector.gif",
        alt: "theme-selector"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie werden dann zum Seiten-Editor weitergeleitet, in dem Sie die Felder des Designs bearbeiten können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Designfelder bearbeiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in der linken Seitenleiste des Seiten-Editors auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Designs"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Designs"
        }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Designeinstellungen bearbeiten"
        }), ". Hier können Sie Ihre bestehenden Designeinstellungen ändern. Wenn Sie Änderungen an den Designeinstellungen veröffentlichen, werden die Stile auf allen Seiten aktualisiert, die das aktualisierte Design verwenden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-theme-settings.gif",
        alt: "edit-theme-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Für lokale Änderungen vorbereiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Kehren Sie zu Ihrem Terminal zurück und führen Sie dann ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch my-website-theme my-website-theme"
      }), " aus. Dieser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Befehl"
      }), " überwacht Ihr lokales Verzeichnis und lädt die folgenden Änderungen automatisch in Ihren HubSpot-Account hoch, wenn die Datei gespeichert wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Ein Designfeld hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da wir nun auf lokale Änderungen überwachen, fügen Sie ein neues Designfeld hinzu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Öffnen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), "-Datei in Ihrem Editor. Diese Datei steuert die verfügbaren Felder in der Seitenleiste des Design-Editors. Wir fügen ein neues ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview#fields-json",
          children: "Feld"
        }), " hinzu, um die Footer-Höhe anzugeben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Suchen Sie am unteren Ende der Datei die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "footer"
        }), "-Gruppe."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Kopieren Sie den nachstehenden Code und fügen Sie den JSON in die Datei über dem ersten Element im untergeordneten Array für die footer-Gruppe ein."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n      {\n      \"id\" : \"\",\n      \"name\" : \"height\",\n      \"label\" : \"Footer height\",\n      \"required\" : false,\n      \"locked\" : false,\n      \"display\" : \"text\",\n      \"step\" : 1,\n      \"type\" : \"number\",\n      \"min\" : 10,\n      \"max\" : 900,\n      \"help_text\":\"This footer will expand in height to accomodate any content added to the footer. You are setting the minimum height in px\",\n      \"default\" : 100\n      },\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Speichern Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " und aktualisieren Sie die Design-Vorschau in HubSpot. Ihr neues Feld sollte in der linken Seitenleiste angezeigt werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. In Ihrem CSS auf das Feld verweisen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Öffnen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), "-Datei in Ihrem Editor. Suchen Sie dann den CSS-Selektor für ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), ". Jetzt fügen wir diesem Selektor ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "min-height"
        }), "-Element hinzu."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um auf einen Wert in einem Design zuzugreifen, verwenden Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme"
        }), "-Objekt. Sie müssen zum Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ theme.footer.height }}"
        }), " verwenden, um auf den in unserem Feld für die Höhe festgelegten Wert zuzugreifen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ersetzen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), "-Deklaration in theme-overrides.css durch Folgendes:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".footer {\n  background-color: {{ footer_bg_color }};\n  min-height: {{theme.footer.height}}px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Speichern Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), ", um sie in Ihren HubSpot-Account hochzuladen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Änderungen testen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Kehren Sie zum Design-Editor zurück und aktualisieren Sie die Seite, damit das neue Feld im Footer erscheint. Aktualisieren Sie den Wert für die Höhe, damit er sofort in der Vorschau angezeigt wird. Es könnte hilfreich sein, eine Hintergrundfarbe für den Footer festzulegen, damit Sie die Änderung leichter erkennen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nächste Schritte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie Ihr Design erstellt und aktualisiert haben, können Sie nun weitere Designfelder erstellen und diese für Ihre Projekte anpassen. Weitere Anpassungsmöglichkeiten finden Sie in der", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: " Übersicht über Designs"
      }), ". Während der Erstellung Ihres Designs kann es hilfreich sein, sich die Best Practices für das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "Optimieren von Websites in CMS Hub"
      }), " anzusehen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot hat mehrere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/default-themes",
        children: "Standarddesigns"
      }), ", die automatisch in CMS Hub enthalten sind. Diese Designs können Sie anzeigen, klonen und aktualisieren, um zu lernen, wie Sie ein Design in einem realen Szenario verwenden können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald Sie sich mit Designs vertraut gemacht haben, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "erfahren Sie hier, wie Sie Ihr erstes benutzerdefiniertes Modul erstellen"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}