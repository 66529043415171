"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021629;
const slug = exports.slug = 'guides/apps/api-usage/usage-details';
const title = exports.title = 'API de HubSpot | Pautas de Uso';
const name = exports.name = 'Pautas de uso de la API';
const metaDescription = exports.metaDescription = 'Las pautas de uso de la API de HubSpot cubren nuestra Política de uso aceptable, Términos de API, límites de tasa, autentificación y seguridad.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "autenticaci%C3%B3n-y-seguridad",
  "label": "Autenticación y seguridad",
  "parent": null
}, {
  "depth": 0,
  "id": "comprobaci%C3%B3n-del-uso-de-la-api",
  "label": "Comprobación del uso de la API",
  "parent": null
}, {
  "depth": 1,
  "id": "aplicaciones-privadas",
  "label": "Aplicaciones privadas",
  "parent": "comprobaci%C3%B3n-del-uso-de-la-api"
}, {
  "depth": 1,
  "id": "aplicaciones-p%C3%BAblicas-que-usan-oauth",
  "label": "Aplicaciones públicas que usan OAuth",
  "parent": "comprobaci%C3%B3n-del-uso-de-la-api"
}, {
  "depth": 0,
  "id": "l%C3%ADmites-de-tasa",
  "label": "Límites de tasa",
  "parent": null
}, {
  "depth": 2,
  "id": "aplicaciones-p%C3%BAblicas",
  "label": "Aplicaciones públicas",
  "parent": "l%C3%ADmites-de-tasa"
}, {
  "depth": 2,
  "id": "aplicaciones-privadas",
  "label": "Aplicaciones privadas",
  "parent": "l%C3%ADmites-de-tasa"
}, {
  "depth": 0,
  "id": "otros-l%C3%ADmites",
  "label": "Otros límites",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites-de-solicitudes",
  "label": "Límites de solicitudes",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites-de-servicio",
  "label": "Límites de servicio",
  "parent": null
}, {
  "depth": 0,
  "id": "respuestas-de-error",
  "label": "Respuestas de Error",
  "parent": null
}, {
  "depth": 1,
  "id": "usa-api-por-lotes-y-resultados-de-cach%C3%A9-cuando-sea-posible",
  "label": "Usa API por lotes y resultados de caché cuando sea posible",
  "parent": "respuestas-de-error"
}, {
  "depth": 1,
  "id": "usa-webhooks-para-obtener-datos-actualizados-de-hubspot",
  "label": "Usa webhooks para obtener datos actualizados de HubSpot",
  "parent": "respuestas-de-error"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      ul: "ul",
      li: "li",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Pautas de uso de la API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot monitoriza atentamente el uso de nuestras API públicas para garantizar una experiencia de calidad para cada usuario. Todos los desarrolladores de aplicaciones e integraciones deben cumplir con la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "Política de uso aceptable de HubSpot"
      }), " y los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/developer-terms",
        children: "Términos de la API"
      }), ". Mientras HubSpot se reserva el derecho de cambiar o eliminar las API a lo largo del tiempo, las actualizaciones siempre se proporcionarán por adelantado a través del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "registro de cambios para desarrolladores"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autenticación y seguridad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para tener una seguridad óptima, todas las aplicaciones deben usar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Protocolo OAuth"
      }), " de HubSpot directamente, o usar el token de acceso de tu aplicación si estás creando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicación privada"
      }), ". Las aplicaciones son responsables de almacenar datos en vivo (TTL) y actualizar los tokens de acceso de usuario de conformidad con este protocolo. Cuando se genere un token de acceso, incluirá un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " que indica cuánto tiempo puede usar para hacer llamadas a la API antes de actualizar. Las solicitudes", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Unauthorized (401)"
      }), " no son un indicador valido que un nuevo token de acceso debe ser devuelto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comprobación del uso de la API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicaciones privadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Para ver el uso de la API para una aplicación privada:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícono de configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integraciones"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones privadas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la aplicación privada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la página de detalles de la aplicación, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Registros"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revisa las llamadas a la API enumeradas en la tabla. También puedes usar la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "barra de búsqueda"
        }), ", los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "filtros"
        }), " y los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "selectores de fechas"
        }), " para refinar aún más las llamadas a la API que se muestran."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/Screenshot%202023-08-31%20at%205.28.03%20PM.png",
        alt: "Captura de pantalla 2023-08-31 a las 16.10.28"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Aprende más sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#view-api-call-logs",
          children: "comprobar el uso de la API en aplicaciones privadas"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicaciones públicas que usan OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ver el uso de la API para una aplicación pública mediante OAuth:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitorización"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usa las ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "pestañas"
        }), " para ver los diferentes tipos de solicitudes que se realizan desde o hacia la aplicación. Mientras ves estos registros, puedes hacer clic en una ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "solicitud individual"
        }), " para ver más información."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/6-request_details.png",
        alt: "6-request_details"
      }), "Aprende más sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#monitor-app-behavior",
        children: "monitorización del uso de la API para aplicaciones públicas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites de tasa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aplicaciones públicas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para las aplicaciones de OAuth, cada cuenta de HubSpot que instala su aplicación está limitada a 100 solicitudes cada 10 segundos. Esto excluye la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "API de búsqueda"
      }), ", como se indica en la sección ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-limits",
        children: "Otros límites"
      }), " a continuación. Los límites relacionados con el complemento de la API no se aplican."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aplicaciones privadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada aplicación privada está sujeta a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "pautas de uso de API de HubSpot"
      }), ". El número de llamadas que tu aplicación privada puede hacer se basa en la suscripción de tu cuenta y si compras el complemento para API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nivel de producto"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Por 10 segundos"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Al día"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicaciones privadas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(cualquier hub)Gratis y Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100 / aplicación privada"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250.000 / cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Cualquier Hub)Pro y Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150 / aplicación privada"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500.000 / cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicaciones privadas con complemento de API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(cualquier Hub)Free, Starter, Pro y Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200 / aplicación privada"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1.000.000 / cuenta"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Otros límites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes crear hasta 100 aplicaciones por cuenta de desarrollador."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes crear hasta 20 aplicaciones privadas por cuenta de HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes crear hasta 1.000 suscripciones de webhook por aplicación."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes crear hasta 25 configuraciones de extensión de CRM por aplicación."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes crear hasta 750 tipos de eventos de línea de tiempo por aplicación."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puedes crear hasta 500 propiedades por tipo de evento de línea de tiempo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites de solicitudes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ten en cuenta los siguientes límites generales al realizar solicitudes a los puntos de terminación de la API de HubSpot. Algunas API también pueden tener sus propios límites más específicos, que se enumeran en la página de referencia de la API en ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Límites"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/single-send-limits-example.png",
        alt: "single-send-limits-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los puntos de terminación de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "API de búsqueda"
        }), " tienen una tasa limitada de cuatro solicitudes por segundo por token de autenticación. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search#limitations",
          children: "los límites de búsqueda"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Las solicitudes de API que estén exentas de límites diarios o secundarios ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " se registrarán en HubSpot. Si quieres almacenar estas solicitudes exentas, tendrás que registrar estas solicitudes externamente."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las solicitudes por lotes a puntos de terminación de objetos del CRM están limitadas a 100 registros por solicitud. Por ejemplo, no puedes leer por lotes más de 100 contactos por solicitud."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites de servicio"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre nuestros límites de servicio y precios ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/pricing/service-limits",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Respuestas de Error"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier aplicación o integración que supere los límites de su tasa recibirá una respuesta de error ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " para todas las llamadas a la API subsiguientes. Las solicitudes que resultan en una respuesta de error no deberían exceder el 5% del total de solicitudes diarias. Si planeas anunciar tu aplicación en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Mercado de aplicaciones de HubSpot"
      }), ", debes estar bajo este límite del 5% para ser certificado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La respuesta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " tendrá el siguiente formato:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example\n{\n  \"status\": \"error\",\n  \"message\": \"You have reached your daily limit.\",\n  \"errorType\": \"RATE_LIMIT\",\n  \"correlationId\": \"c033cdaa-2c40-4a64-ae48-b4cec88dad24\",\n  \"policyName\": \"DAILY\",\n  \"requestId\": \"3d3e35b7-0dae-4b9f-a6e3-9c230cbcf8dd\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "message"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "policyName"
      }), " indicarán qué límite alcanzaste (ya sea diariamente o en segundo lugar)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El límite ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "diario"
      }), " se restablece a la medianoche basado en tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-set-your-time-zone-in-hubspot",
        children: "configuración de zona horaria"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La siguiente tabla detalla los títulos de límite de tarifa incluidos en la respuesta de cada solicitud de API a HubSpot, sujeto a las excepciones que se enumeran a continuación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Encabezado"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descripción"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El número de solicitudes de API permitidas por día. Ten en cuenta que este título no está incluido en la respuesta a las solicitudes de API autorizadas mediante ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El número de solicitudes de API que aún se permiten para el día actual. Ten en cuenta que este título no está incluido en la respuesta a las solicitudes de API autorizadas mediante ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La ventana de tiempo a la que se aplican los encabezados ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            }), ". Por ejemplo, un valor de 10000 sería una ventana de 10 segundos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El número de solicitudes permitidas en la ventana identificada en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), ".Por ejemplo, si este encabezado tuviera un valor de 100 y el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), " fuera 10000, el límite aplicado sería de 100 solicitudes por 10 segundos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El número de solicitudes de API que aún se permiten para la ventana especificada en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Los encabezados ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly"
          }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly-Remaining"
          }), " todavía están incluidos y aún tendrán datos precisos, pero el límite al que hacen referencia estos encabezados ya no se aplica y estos dos encabezados deben considerarse obsoletos."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las respuestas de los ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/search",
            children: "puntos de terminación de API de búsqueda"
          }), " ", (0, _jsxRuntime.jsx)("u", {
            children: "no"
          }), " incluirán ninguno de los encabezados de límite de velocidad enumerados anteriormente."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes comprobar el número de llamadas utilizadas durante el día actual usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "este punto de terminación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás llegando al límite de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TEN_SECONDLY_ROLLING"
      }), ", debes limitar las solicitudes que realiza tu aplicación para mantenerte por debajo de ese límite. Además de eliminar las solicitudes, o si estás en el límite diario, echa un vistazo a las sugerencias a continuación."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si encuentras que aún estás alcanzando los límites de llamadas después de analizar estas sugerencias, por favor pública en los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://integrate.hubspot.com/",
        children: "foros de desarrollador"
      }), " de HubSpot. Debes incluir tantos detalles como sea posible sobre las API que estás usando, cómo las estás usando y qué límite estás alcanzando."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usa API por lotes y resultados de caché cuando sea posible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu sitio o aplicación usa datos de HubSpot en cada una de las páginas de carga, esa información debe estar almacenada y cargarse desde esa memoria caché en lugar de ser solicitada desde las API de HubSpot cada vez. Si haces llamadas reiteradas para obtener la configuración de tu cuenta para un trabajo por lotes (como obtener las propiedades, los propietarios o la configuración de tu objeto para un formulario), esas configuraciones también deberían ser almacenadas en caché cuando sea posible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usa webhooks para obtener datos actualizados de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes una suscripción de HubSpot Marketing Enterprise, puedes usar acciones de webhook en workflows para que los datos de los registros de contactos se envíen a tu sistema. Los Webhooks pueden activarse como una acción en cualquier workflow, por lo que puedes usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows-user-guide-v2/how-to-choose-the-workflow-type-and-the-starting-condition-best-suited-for-your-goal",
        children: "condiciones de inicio"
      }), " de workflow como criterios para que los datos de contacto se envíen a tu sistema. Más detalles sobre cómo utilizar webhooks puedes encontrarlo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "aquí,"
      }), " y hay ejemplos de datos de webhooks ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "aquí"
      }), ". Las llamadas de webhook realizadas a través de workflows no cuentan en el límite de tasa de la API."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}