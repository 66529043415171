"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710045;
const slug = exports.slug = 'guides/cms/storage/hubdb/overview';
const title = exports.title = 'HubDB';
const name = exports.name = 'HubDB';
const metaDescription = exports.metaDescription = 'HubSpotのネイティブ データベース ツールであるHubDBを使用して、データに基づくコンテンツを作成しましょう。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/HubDB%20Image%20for%20Building%20Dynamic%20Pages%20with%20HubDB.png';
const featuredImageAltText = exports.featuredImageAltText = 'HubDB';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubdb%E3%82%A2%E3%83%BC%E3%82%AD%E3%83%86%E3%82%AF%E3%83%81%E3%83%A3%E3%83%BC",
  "label": "HubDBアーキテクチャー",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb%E3%81%AE%E6%8A%80%E8%A1%93%E7%9A%84%E3%81%AA%E5%88%B6%E9%99%90%E4%BA%8B%E9%A0%85",
  "label": "HubDBの技術的な制限事項",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "HubDBテーブルを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A4%87%E6%95%B0%E3%81%AEhubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E7%B5%90%E5%90%88%E3%81%99%E3%82%8B",
  "label": "複数のHubDBテーブルを結合する",
  "parent": null
}, {
  "depth": 1,
  "id": "1.%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%81%AB%E5%A4%96%E9%83%A8id%E5%88%97%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "1.メインテーブルに外部ID列を追加する",
  "parent": "%E8%A4%87%E6%95%B0%E3%81%AEhubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E7%B5%90%E5%90%88%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "2.-%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%81%AE%E8%A1%8C%E3%81%AB%E5%A4%96%E9%83%A8%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E8%A1%8C%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "2. テーブルの行に外部テーブル行を追加する",
  "parent": "%E8%A4%87%E6%95%B0%E3%81%AEhubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E7%B5%90%E5%90%88%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "3.-%E7%B5%90%E5%90%88%E3%81%95%E3%82%8C%E3%81%9Fhubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%99%E3%82%8B",
  "label": "3. 結合されたHubDBテーブルデータをレンダリングする",
  "parent": "%E8%A4%87%E6%95%B0%E3%81%AEhubdb%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%82%92%E7%B5%90%E5%90%88%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9",
  "label": "HubLを使用したHubDBデータへのアクセス",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%A1%8C%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "行の取得",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 2,
  "id": "%E8%A1%8C%E3%81%AE%E5%B1%9E%E6%80%A7",
  "label": "行の属性",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%83%A1%E3%82%BF%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "テーブルメタデータの取得",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%83%BC%E3%83%96%E3%83%AB%E3%81%AE%E5%B1%9E%E6%80%A7",
  "label": "テーブルの属性",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 1,
  "id": "%E5%88%97%E3%83%A1%E3%82%BF%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "列メタデータの取得",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 2,
  "id": "%E5%88%97%E3%81%AE%E5%B1%9E%E6%80%A7",
  "label": "列の属性",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 2,
  "id": "%E5%88%97%E3%81%AE%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
  "label": "列のメソッド",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 2,
  "id": "%E3%83%AA%E3%83%83%E3%83%81%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E5%88%97",
  "label": "リッチテキスト列",
  "parent": "hubl%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9Fhubdb%E3%83%87%E3%83%BC%E3%82%BF%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 0,
  "id": "hubdb%E3%81%AE%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB%E3%81%A8%E8%B3%87%E6%96%99",
  "label": "HubDBのチュートリアルと資料",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      em: "em",
      strong: "strong",
      img: "img",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h3: "h3",
      pre: "pre",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBは、データを行、列、セルに保存する、スプレッドシートによく似たテーブルを作成するために使用するツールです。HubDBテーブルの列、行、およびその他の設定は、必要に応じてカスタマイズできます。例えば、HubDBは次の目的で使用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "外部メカニズムからのフィードバックを保存し、後で取得できるようにする"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "動的CMSページを作成する"
        }), "ために使用可能な構造化データを保存する（", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " および ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " のみ）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "プログラム可能なEメール"
        }), "で使用するデータを保存する（", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " のみ）。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-example0.png",
        alt: "hubdb-table-example0"
      }), "HubDBテーブルには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "HubSpot内"
      }), "からアクセスすることも、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "HubDB API"
      }), "を介してアクセスすることもできます。そのためユースケースによって、テーブルからデータを取得するにはさまざまな方法があります。HubDBテーブルからデータを取得する具体的な方法としては、次が挙げられます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/cms/hubdb",
          children: "HubDB API"
        }), "を介して外部からデータのクエリーを実行する。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotのHubLマークアップタグを使用してデータを構造化データとしてCMSに取り込む。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubDB APIと", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "サーバーレス関数"
        }), "を使用して対話型のウェブアプリ環境を提供する。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
            children: "HubDBデータをページで使用する"
          }), "には、", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " が必要です。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/email/create-programmable-emails",
            children: "プログラム可能なEメール"
          }), "でHubDBデータを使用するには、", (0, _jsxRuntime.jsx)(_components.em, {
            children: "**Marketing Hub"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " が必要です。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["既存のテーブルを公開、編集、表示するには、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#website-tools",
            children: "HubDB権限"
          }), "が必要です。HubDBテーブルの設定を作成、複製、削除、編集するには、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: "「HubDBテーブル設定」権限"
          }), "が必要です。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBアーキテクチャー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBテーブルは、スプレッドシートと同様に、行、列、セルで構成されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**テーブル：**テーブル（表）は行と列の2次元配列です。テーブルは、作成時にグローバル固有IDが割り当てられます。これは、HubLまたはAPIを介してテーブルを指定する必要があるときに使用できます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**行：**行はテーブルの横方向のスライスです。スプレッドシートアプリケーションの場合、行は1から始まる番号で表されます。各テーブル行は、作成時にグローバル固有IDが割り当てられます。テーブルの各行には、既定でいくつかの列が含まれています。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "列"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この行に自動的に割り当てられた、数値のグローバル固有ID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この行が作成された時点のタイムスタンプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "動的ページで使用される場合、この文字列が、ページURLのパスを構成する最後のセグメントになります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "動的ページで使用される場合、ページのタイトル"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**HubDBのリッチ テキスト エリア列は65,000文字に制限されています。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/hubdb-rich-text-area-limited-to-65000-characters-and-a-new-meetings-module",
          children: "変更履歴の通知"
        }), "（英語）をご覧ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**列：**列はテーブルの縦方向のスライスです。各列にはタイプがあり、特定の種類のデータを格納するために使用されます。テーブルには必要な数の列を含めることができ、各列は作成時にグローバル固有IDが割り当てられます。列IDは値", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1"
        }), "から始まりますが、必ずしも連続する値ではなく、再利用することはできません。列タイプは次のとおりです。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テキスト"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "リッチテキスト"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "URL"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "画像"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Select"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "複数選択"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "日付"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "日時"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "数値"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "通貨"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "チェックボックス"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "場所（経度、緯度）"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "外部ID"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "動画"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**セル：**セルは行と列が交差する場所であり、値を格納します。セルの読み取りと更新は、セル単位で個別に、または行の一部として行うことができます。セルの値を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), "に設定するということは、セルの値を削除することに相当します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBの技術的な制限事項"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のHubDBの技術的制限に注意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アカウントの制限："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アカウント1件あたりのHubDBテーブル数：1,000件"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アカウント1件あたりのHubDB行数：100万行"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "テーブルの制限："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テーブル1件あたりの列数：250列"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubDBテーブル1件あたりの行数：10,000行"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テーブル名1件あたりの文字数：700文字"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テーブルラベル1件あたりの文字数：700文字"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "列の制限："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "リッチテキスト列1列あたりの文字数：65,000文字"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "各テキスト列の文字数：10,000文字"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "列名1件あたりの文字数：700文字"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ラベル1件あたりの文字数：700文字"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "列の説明1件あたりの文字数：300文字"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "列内の選択可能なオプション1件あたりの文字数：700文字"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ページの制限："
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["CMSページ1ページあたりの", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb_table_rows"
            }), " HubL関数の呼び出し：10回"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["同じHubDBテーブルを使用する", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "動的ページ"
            }), "：10ページ"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "動的ページが有効なHubDBではパスが必ず小文字で設定されるため、これらのページへのURLでは大文字と小文字が区別されません。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBテーブルを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDBテーブルを作成するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "HubSpotのUI"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#create-a-new-table",
        children: "HubDB API"
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全ての新しく作成されたテーブルのステータスは、下書きとして設定されます。テーブルは公開するまで、HubLまたはAPIによるデータ出力を目的として使用することができません。テーブルを作成する際に、パブリックAPIアクセスを許可するかどうか、テーブルのデータを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "動的ページの作成"
      }), "に使用するかどうかなどの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "設定を管理する"
      }), "こともできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "複数のHubDBテーブルを結合する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBテーブルは、外部ID列タイプを使用して結合できます。結合により、複数のテーブルからの組み合わせデータをレンダリングできます。これは、複数のデータストア間で一部のデータが共有される場合に役立ちます。1つの集中管理データテーブルでこの情報を管理でき、その他の複数のHubDBテーブル データ ストアからそのテーブルにアクセスできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "複数のHubDBテーブルを結合する方法は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1.メインテーブルに外部ID列を追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで、［マーケティング］＞［ファイルとテンプレート］＞［HubDB］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テーブル結合を追加する先のテーブルを見つけて、［アクション］ドロップダウンメニューをクリックし、［編集］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［編集］をクリックし、［列を追加］を選択します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しい列の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ラベル"
        }), "と", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "を入力します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［列のタイプ］ドロップダウンメニューをクリックし、［外部ID］を選択します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［テーブルを選択］ドロップダウンメニューをクリックし、現在のテーブルに結合する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "テーブル"
        }), "を選択します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［列を選択］ドロップダウンメニューをクリックし、選択した結合対象テーブルから、［外部ID］フィールドに表示する", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "列"
        }), "を選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［列を追加］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), "［列を選択］で選択した値は、どの列の値がHubDB UIの［外部ID］フィールドに表示されるかを指定するだけです。結合されたHubDBテーブルをレンダリングする際は、全てのテーブル列を使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id.png",
        alt: "hubdb_foreign_id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. テーブルの行に外部テーブル行を追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["［外部", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ID"
      }), "］列を選択したので、次はHubDBテーブルの各行に複数選択列フィールドを指定します。これにより、外部テーブルの行を選択できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "選択した［列を選択］フィールドは、この複数選択フィールドで使用されます。これにより、外部テーブルから選択する行を見つけやすくなります。以下の例では、"
      }), "［Expertise table join］フィールドの複数選択値は、外部HubDBテーブルの__［Name］列です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id_ui.png",
        alt: "hubdb_foreign_id_ui"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), "［外部 ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID"
        }), "］列の［列を選択］フィールドは安全に編集できます。編集しても、どの列の値がHubDB UIに表示されるかが更新されるだけです。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. 結合されたHubDBテーブルデータをレンダリングする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レンダリングでは、［列を選択］フィールドだけでなく、外部テーブル行の全てのデータにHubLを介してアクセスできます。HubDB外部行のデータにアクセスするには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "ネストしたforループ"
      }), "を使用して、個々の行に関連付けられた全ての外部行をループ処理します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(tableId, filterQuery) %}\n  the name for row {{ row.hs_id }} is {{ row.name }}\n  {% for foreign_row in row.foreign_table %}\n  \tthe name for foreign row {{ foreign_row.hs_id }} is {{ foreign_row.name }}\n  {% endfor %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubLを使用したHubDBデータへのアクセス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLを使用すると、ウェブサイトページで構造化コンテンツとして使用するHubDBデータを取得できます。この後、HubLを使用してテーブル、行、列のデータを取得する方法を詳しく説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**下書きされたHubDBテーブルデータはページエディターとライブプレビューに表示されますが、ライブページには、公開済みのHubDBコンテンツのみが表示されます。エディターに、テーブルデータが表示されているか、ライブページには表示されていないプレビューが表示されている場合は、そのテーブルデータの追加後にテーブルが公開されたことを確認します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "行の取得"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーブルの行のリストを取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: "hubdb_table_rows()"
      }), " HubL関数を使用します。テーブルには、テーブルのIDまたは名前でアクセスできます。HubSpotアカウント間でのコード移植に役立つ可能性があるため、HubDBテーブルは名前で参照することをお勧めします。新しいテーブルの作成時に変更不可能なテーブル名を設定します。この名前は、テーブルエディター内の［アクション］＞［設定を管理］を選択することでいつでも確認できます。テーブルのIDは、テーブルエディターのアドレスバー、またはHubDBテーブルダッシュボードの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), "列で確認できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Table%20Create.png",
        alt: "テーブル作成モーダルのスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows()"
      }), "を使用してデータを取得する例です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(<tableId or name>, <filterQuery>) %}\n  the value for row {{ row.hs_id }} is {{ row.<column name> }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**既定では、返される行の最大数は1,000です。それより多くの行を取得するには、関数クエリーで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), "を指定します。以下に例を示します。"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hudb_table_rows (12345, \"random()&limit=1500\")"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), "の構文は、HTTP APIと同じです。例えば", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows(123, \"employees__gt=10&orderBy=count\")"
      }), "の場合、［employees］列の値が10よりも大きい行が、［count］列に基づいて並べ替えられた状態で返されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), "のオプションパラメーターを網羅したリストは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "テーブル行の取得方法に関するページ"
      }), "（英語）でご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["異なる", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), "パラメーターを指定した複数の行クエリーを使用する代わりに、1つのクエリーを使用して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "selectattr()"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rejectattr()"
      }), "フィルターで次のように行を絞り込む方法が適切です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set all_cars = hubdb_table_rows(<tableId or name>) %}\n\n{% set cars_with_windows = all_cars|selectattr('windows') %}\n\n{% set teslas = all_cars|selectattr('make','equalto','tesla') %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1つの行を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_row()"
      }), " HubL関数を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set row = hubdb_table_row(<tableId or name>, <rowId>) %}\nthe value for {{ row.hs_id }} is {{ row.<column name> }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["組み込み列とカスタム列の名前では大文字と小文字は区別されません。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HS_ID"
      }), "は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_id"
      }), "と同じように機能します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "行の属性"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "属性"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この行のグローバル固有ID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "動的ページを使用する場合、この文字列は「ページのパス」列の値で、ページURLのパスを構成する最後のセグメントです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "動的ページを使用する場合、この文字列は行の「ページタイトル」列の値です。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "行が作成された時点のUnixタイムスタンプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "動的ページを使用する場合、これは行のデータを提供する別のテーブルのIDです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.column_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列名を使用してカスタム列の値を取得します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row[\"column name\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列名を使用してカスタム列の値を取得します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーブルメタデータの取得"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーブルの名前、列、最終更新日などのメタデータを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table()"
      }), "関数を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table(<tableId or name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テーブルの属性"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ここでは、前述のコードで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table()"
        }), "から代入された変数を例に、関連する属性を示します。実際にご使用の変数名とは異なる場合があります。利便性を考慮して、変数に属性値を代入することをお勧めします。", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["または、次の方法を使用できます。 ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table(<tableId>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "属性"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーブルのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーブルの名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.columns"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["列情報のリスト。この属性の情報に対して、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/loops",
              children: "forループ"
            }), "による反復処理を実行できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーブルが初めて作成された時点のタイムスタンプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.published_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このテーブルが公開された時点のタイムスタンプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.updated_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このテーブルの最終更新時点のタイムスタンプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.row_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テーブル内にある行の数。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "列メタデータの取得"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table_column(<tableId or name>, <columnId or column name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーブルの列に関する情報（ラベル、タイプ、オプションなど）を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_column()"
      }), "関数を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "列の属性"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ここでは、前述のコードで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_column()"
        }), "から代入された変数を例に、関連する属性を示します。実際にご使用の変数名とは異なる場合があります。利便性を考慮して、変数に属性値を代入することをお勧めします。", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["または、次の方法を使用できます。 ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table_column(<tableId>,<columnId or column name>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "属性"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列のID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列の名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列に使用するラベル。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この列のタイプ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["select列タイプの場合、これは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "optionId"
            }), "と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "情報のマップです。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.foreignIds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["foreignId列タイプの場合、これはforeignIdsのリストです（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "プロパティーと", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "プロパティーを含む）。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "列のメソッド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "メソッド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "getOptionByName(\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "select列タイプの場合、オプション名に基づいてオプションの情報を取得します。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "リッチテキスト列"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "richtext"
      }), "列タイプは、モジュールのリッチ テキスト フィールドと同じように機能します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "データはHTMLとして保存され、HubDB UIにテキスト編集インターフェイスが表示されます。ただし、HubSpotのUIを使用してHubDBテーブルを編集する場合、ソースコードを直接編集することはできません。これにより、技術者以外のユーザーが無効なHTMLを入力できないようにして、サイトの外観や機能に想定外の問題が発生する事態を防いでいます。埋め込みコードや追加のカスタムHTMLが必要な場合は、リッチ テキスト エディターの埋め込み機能を使用してカスタムコードを配置できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBのチュートリアルと資料"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "イベント登録アプリ"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "HubDBを使用して動的なチーム メンバー ページを作成する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "動的ページに動画を追加する方法"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "HubDBを使ってマルチレベルの動的ページを作成する方法"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}