"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 91311166391;
const slug = exports.slug = 'reference/api/analytics-and-events/cookie-banner/cookie-banner-api';
const title = exports.title = 'イベントAPI | Cookieバナー | HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (ja) | WEB | vNext Docs DP - Cookie Banner_JA| 202212';
const metaDescription = exports.metaDescription = 'Cookieバナーを使用すると、ユーザー設定に基づいてさまざまな分析スクリプトを有効または無効にできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cookie%E3%82%92%E5%89%8A%E9%99%A4",
  "label": "Cookieを削除",
  "parent": null
}, {
  "depth": 1,
  "id": "_hsp.push-%5B--revokecookieconsent--%5D%3B",
  "label": "_hsp.push ([' revokeCookieConsent ']);",
  "parent": "cookie%E3%82%92%E5%89%8A%E9%99%A4"
}, {
  "depth": 0,
  "id": "%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E4%B8%8D%E5%8F%AFcookie%E3%81%AE%E9%85%8D%E7%BD%AE",
  "label": "トラッキング不可Cookieの配置",
  "parent": null
}, {
  "depth": 1,
  "id": "_hsq.push-%5B--donottrack--%5D%3B",
  "label": "_hsq.push ([' DoNotTrack ']);",
  "parent": "%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E4%B8%8D%E5%8F%AFcookie%E3%81%AE%E9%85%8D%E7%BD%AE"
}, {
  "depth": 0,
  "id": "%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%90%8C%E6%84%8F%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "プライバシーに関する同意ステータスの取得",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC%E5%88%A5%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%84cookie",
  "label": "カテゴリー別ではないCookie",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E8%A8%AA%E5%95%8F%E8%80%85%E3%81%8C%E5%90%8C%E6%84%8F%E8%A8%AD%E5%AE%9A%E3%82%92%E7%AE%A1%E7%90%86%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%88%E3%81%86%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "ウェブサイトの訪問者が同意設定を管理できるようにする",
  "parent": null
}, {
  "depth": 0,
  "id": "ui%E3%81%AE%E4%BE%8B",
  "label": "UIの例",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%9C%E3%82%BF%E3%83%B3",
  "label": "ボタン",
  "parent": "ui%E3%81%AE%E4%BE%8B"
}, {
  "depth": 1,
  "id": "%E5%9B%BA%E5%AE%9A%E4%BD%8D%E7%BD%AE%E3%83%9C%E3%82%BF%E3%83%B3",
  "label": "固定位置ボタン",
  "parent": "ui%E3%81%AE%E4%BE%8B"
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%83%B3%E3%82%AF",
  "label": "リンク",
  "parent": "ui%E3%81%AE%E4%BE%8B"
}, {
  "depth": 0,
  "id": "%E9%81%8E%E5%8E%BB%E3%81%AE%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC",
  "label": "過去のカテゴリー",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      hr: "hr",
      h2: "h2",
      h3: "h3",
      code: "code",
      pre: "pre",
      br: "br",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CookieバナーAPIの概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["スーパー管理者と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/settings/hubspot-user-permissions-guide#%E8%A8%AD%E5%AE%9A",
        children: "［ウェブサイト設定］の編集権限"
      }), "を付与されたユーザーは、EUのCookie法規および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/data-privacy/gdpr",
        children: "一般データ保護規則（GDPR）"
      }), "を順守するために、訪問者のCookieトラッキングとプライバシー ポリシー バナーをカスタマイズすることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プライバシーポリシーの通知により、訪問者はHubSpotアカウントでのCookieによるトラッキングを許可するかどうかを選択できます。この機能はHubSpotページだけではなく、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/reports/install-the-hubspot-tracking-code",
        children: "HubSpotトラッキングコード"
      }), "がインストールされている全ての外部ページ上で動作します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/privacy-and-consent/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "Cookieのトラッキング設定やプライバシーポリシーの通知をカスタマイズしましょう。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、Cookieバナーを通じて訪問者のブラウザーに追加されたCookieを管理する方法について説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cookieを削除"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsp.push ([' revokeCookieConsent ']);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["GDPRに基づくHubSpotの同意バナーに組み込まれる、HubSpotトラッキングコードによって作成されたCookieを削除します。これには、訪問者のトラッキングに関連するHubSpot Cookieが含まれます。Cookieが削除されると、どの訪問者も新規の訪問者と見なされるため、次回のページ読み込み時には", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/privacy-and-consent/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "Cookie同意バナー"
      }), "が表示されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この機能を使用しても、HubSpot以外のバナーによって配置されたCookieは", (0, _jsxRuntime.jsx)("u", {
        children: "削除されません"
      }), "。削除の対象となる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ja/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser",
        children: "Cookieの一覧"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to remove the consent banner cookies\nwhen a visitor clicks an element with the 'removeCookies' id.\n*/\n\nvar _hsp = (window._hsp = window._hsp || []);\ndocument.getElementById('removeCookies').onclick = function () {\n  _hsp.push(['revokeCookieConsent']);\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "トラッキング不可Cookieの配置"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push ([' DoNotTrack ']);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者のブラウザーに__hs_do_not_trackのCookieを配置します。これにより、この訪問者に関するHubSpotトラッキングコードを使った情報送信は停止されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "{track: true}"
      }), "引数を含めてもう一度関数を呼び出すことにより、Cookieを削除できます。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['doNotTrack', {track: true}]);"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**この機能を使用すると、匿名化されたトラフィックやカスタムイベントのデータを含む、トラッキングコードによる全ての情報収集を停止します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to place the __hs_do_not_track cookie for\nthe visitor when they click an element with the 'doNotTrack' id.\n*/\n\ndocument.getElementById('doNotTrack').onclick = function () {\n  _hsq.push(['doNotTrack']);\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "プライバシーに関する同意ステータスの取得"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsp.push ([' addPrivacyConsentListener ', callbackFunction]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["現在の訪問者のプライバシーに関する同意ステータスを取得します。ユーザーによるきめ細かい制御を可能にするために、同意には3つのカテゴリーが提供されています。各カテゴリーに対する独自のキーは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "consent.categories"
      }), "オブジェクト内にあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "consent.categories.analytics"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "consent.categories.advertisement"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "consent.categories.functionality"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページの状態に応じて、callbackFunctionが呼び出されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["バナーが", (0, _jsxRuntime.jsx)("u", {
            children: "有効になっていない"
          }), "場合、または訪問者が以前にバナーを表示して［承諾］か［拒否］ボタンをクリックしたことがある場合："]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "バナーコードが既に読み込まれている場合、callbackFunctionはすぐに呼び出されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "トラッキングコードが読み込まれる前にcallbackFunctionが_hspに含まれると、トラッキングコードが読み込まれた後にこの関数が呼び出されます。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "同意バナーが有効になっている場合、訪問者が［承諾］か［拒否］ボタンをクリックするとcallbackFunctionが呼び出されます。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Log the analytics category consent status of the current visitor to the console\n\nvar _hsp = (window._hsp = window._hsp || []);\n\n// analytics\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(consent.categories.analytics);\n  },\n]);\n\n// advertisement\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(consent.categories.advertisement);\n  },\n]);\n\n// functionality\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(consent.categories.functionality);\n  },\n]);\n\n// or it can all be done in one call\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    console.log(`analytics: ${consent.categories.analytics}`);\n    console.log(`advertisement: ${consent.categories.advertisement}`);\n    console.log(`functionality: ${consent.categories.functionality}`);\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カテゴリー別ではないCookie"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "This is provided for backward compatibility with older scripts. For all new websites you should use the cookies by category method, giving more granular control over cookie activation."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsp.push ([' addPrivacyConsentListener ', callbackFunction]);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "現在の訪問者のプライバシーに関する同意ステータスの真偽を取得できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページの状態に応じて、callbackFunctionが呼び出されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["バナーが", (0, _jsxRuntime.jsx)("u", {
            children: "有効になっていない"
          }), "場合、または訪問者が以前にバナーを表示して［承諾］か［拒否］ボタンをクリックしたことがある場合："]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "バナーコードが既に読み込まれている場合、callbackFunctionはすぐに呼び出されます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "トラッキングコードが読み込まれる前にcallbackFunctionが_hspに含まれると、トラッキングコードが読み込まれた後にこの関数が呼び出されます。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "同意バナーが有効になっている場合、訪問者が［承諾］か［拒否］ボタンをクリックするとcallbackFunctionが呼び出されます。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Log the consent status of the current visitor to the console\n\nvar _hsp = (window._hsp = window._hsp || []);\n_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    if (consent.allowed) {\n      console.log('something');\n    }\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "CallbackFunction"
      }), "は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "consent"
      }), "オブジェクトを唯一の引数として受け入れます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "consent"
      }), "オブジェクトには単一の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "allowed"
      }), "プロパティーがあり、次の場合に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "となります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/ja/privacy-and-consent/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
          children: "プライバシーポリシーの同意バナー"
        }), "が有効になっていない、または通知専用モードで有効になっている場合。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "オプトインモードが有効になっていて、訪問者がバナーで［承諾］をクリックした場合。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "以前オプトインモードが有効になっていた際に、訪問者がバナーで［承諾］をクリックしたことがある場合。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "オプトインモードで同意バナーが有効になっているときに、訪問者が［拒否］ボタンをクリックしたか以前にクリックしたことがある場合、このプロパティーはfalseになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイトの訪問者が同意設定を管理できるようにする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "ShowBanner"
      }), "関数を呼び出してバナーを再表示し、ウェブサイトの訪問者が同意設定を変更できるようにします。例："]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "​​var _hsp = window._hsp = window._hsp || [];\n​​_hsp.push(['showBanner']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "showBanner"
      }), "の動作はポリシーによって異なり、オプトインポリシーとカテゴリー別のCookieポリシーでのみ使用可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オプトインポリシーの場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "showBanner"
      }), "を呼び出すと、次の動画（英語）のようにバナーが再び表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/39970836014",
      width: "800px",
      height: "450px"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カテゴリー別のCookieポリシーの場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "showBanner"
      }), "を呼び出すと、次の動画（英語）のように各カテゴリーを選択するためのモーダルが再び表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/39970836014",
      width: "800px",
      height: "450px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "UIの例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この機能によって、訪問者はウェブサイト上のボタンやリンクを使用して、バナーを再度開いて設定を変更できるようになります。コードを表示した以下の例もご参照ください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ボタン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このボタンはウェブサイトのフッターによく配置されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<button\n  type=\"button\"\n  id=\"hs_show_banner_button\"\n  onClick=\"(function(){\n        var _hsp = window._hsp = window._hsp || [];\n        _hsp.push(['showBanner']);\n      })()\"\n>\n  Cookie Settings\n</button>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hs_show_banner_button {\n  display: inline-block;\n  text-align: center;\n  height: 50px;\n  background-color: #425b76;\n  border: 1px solid #425b76;\n  border-radius: 3px;\n  padding: 10px 16px;\n  text-decoration: none;\n  color: #fff;\n  font-family: inherit;\n  font-size: inherit;\n  font-weight: normal;\n  line-height: inherit;\n  text-shadow: none;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/39970836014",
      width: "800px",
      height: "450px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "固定位置ボタン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このボタンは画面下部の固定位置に配置されます。ページに設置すると常時画面に表示されるため、訪問者が見逃すことはありません。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<button\n  id=\"hs-hud-cookie-settings\"\n  onClick=\"(function(){\n      var _hsp = window._hsp = window._hsp || [];\n      _hsp.push(['showBanner']);\n    })()\"\n>\n  Cookie Settings\n</button>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "button#hs-hud-cookie-settings {\n  position: fixed !important;\n  bottom: 0px;\n  right: 10px;\n  color: white;\n  background-color: #425b76;\n  padding: 5px;\n  border-top-right-radius: 5px;\n  border-top-left-radius: 5px;\n  border-width: 0;\n  appearance: none;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/39970836014",
      width: "800px",
      height: "450px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リンク"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "該当テキストをハイライト表示してリンクを挿入することもできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a\n  id=\"hs-cookie-settings-link\"\n  onClick=\"(function(){\n      var _hsp = window._hsp = window._hsp || [];\n      _hsp.push(['showBanner']);\n    })()\"\n>\n  Cookie Settings\n</a>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hs-cookie-settings-link {\n  cursor: pointer;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/39970836014",
      width: "800px",
      height: "450px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "過去のカテゴリー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["バナーに追加されたプライバシー同意リスナーが、同意の変更に準拠するためにリスナーに渡される同意オブジェクトは、カテゴリーごとに以前の同意状態を格納する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "previousCategories"
      }), "フィールドを含みます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "consent = {\n  allowed: true or false,\n  categories: {\n    necessary: true,\n    analytics: true or false,\n    advertisement: true or false,\n    functionality: true or false,\n  },\n  previousCategories: {\n    necessary: true,\n    analytics: true or false, # defaults to false\n    advertisement: true or false, # defaults to false\n    functionality: true or false, # defaults to false\n  },\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**previousCategoriesは、一度ぺージを閉じると失効します。ユーザーがページを再読み込みまたは再訪するたびに、previousCategoriesはデフォルトの状態にリセットされます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "同意リスナーの場合previousCategoriesを利用して、同意がいつ変更されたかを特定し、行動を適切に調整可能です。例えばトラッキングスクリプトの場合、同意リスナーを使用して同意を受け取ったときにCookieを使用し、同意が取り消されたときにそれらのCookieを削除する可能性があります。同意に関して変更がなくアクションが必要ない場合でも、previousCategoriesフィールドからその情報がリスナーに渡されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下は、previousCategoriesを使用する同意リスナーの例です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "_hsp.push([\n  'addPrivacyConsentListener',\n  function (consent) {\n    const prevConsent = consent.previousCategories;\n    const currConsent = consent.categories;\n    const hasConsent = currConsent.analytics && currConsent.advertisement;\n    const prevHadConsent = prevConsent.analytics && prevConsent.advertisement;\n    const hasChanged = hasConsent !== prevHadConsent;\n\n    if (hasChanged && hasConsent) {\n      // drop cookies, execute code etc\n    }\n\n    if (hasChanged && !hasConsent) {\n      // remove cookies, undo actions and resepect user privacy\n    }\n  },\n]);\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}