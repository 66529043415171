"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 33914880818;
const slug = exports.slug = 'guides/cms/content/memberships/sso';
const title = exports.title = 'SSO for Memberships';
const name = exports.name = '[Developers] SSO for Memberships';
const metaDescription = exports.metaDescription = 'Manage all of your businesses access permission and authentication needs in a single system with single sign-on (SSO) for memberships.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "initial-setup",
  "label": "Initial Setup",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-login-to-your-identity-provider.",
  "label": "1. Login to your identity provider.",
  "parent": "initial-setup"
}, {
  "depth": 1,
  "id": "2.-navigate-to-your-applications.",
  "label": "2. Navigate to your applications.",
  "parent": "initial-setup"
}, {
  "depth": 1,
  "id": "3.-create-a-new-saml-application-specifically-for-hubspot-content-access.",
  "label": "3. Create a new SAML application specifically for HubSpot content access.",
  "parent": "initial-setup"
}, {
  "depth": 1,
  "id": "4.-copy-the-identifier-or-issuer-url%2C-the-single-sign-on-url%2C-and-the-certificate-from-your-identity-provider%2C-and-paste-them-into-the-corresponding-fields-in-the-sso-setup-panel-in-hubspot.",
  "label": "4. Copy the identifier or issuer URL, the single-sign on URL, and the certificate from your identity provider, and paste them into the corresponding fields in the SSO setup panel in HubSpot.",
  "parent": "initial-setup"
}, {
  "depth": 1,
  "id": "5.-click-verify.",
  "label": "5. Click Verify.",
  "parent": "initial-setup"
}, {
  "depth": 0,
  "id": "sso-enablement-for-blogs",
  "label": "SSO Enablement for Blogs",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-navigate-to-settings-%3E-cms-%3E-blog-in-the-settings-ui.",
  "label": "1. Navigate to settings > CMS > Blog in the Settings UI.",
  "parent": "sso-enablement-for-blogs"
}, {
  "depth": 1,
  "id": "2.-select-a-blog-that-is-currently-hosted-on-an-sso-enabled-subdomain-from-the-%E2%80%9Cselect-a-blog-to-modify%E2%80%9D-list.",
  "label": "2. Select a blog that is currently hosted on an SSO enabled subdomain from the “select a blog to modify” list.",
  "parent": "sso-enablement-for-blogs"
}, {
  "depth": 1,
  "id": "3.-locate-the-control-audience-access-settings-at-the-bottom-of-your-blog%E2%80%99s-general-tab.",
  "label": "3. Locate the control audience access settings at the bottom of your blog’s general tab.",
  "parent": "sso-enablement-for-blogs"
}, {
  "depth": 0,
  "id": "sso-enablement-for-pages-%2F-landing-pages",
  "label": "SSO Enablement for Pages / Landing Pages",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-navigate-to-marketing-%3E-website-%3E-website-pages-or-landing-pages.",
  "label": "1. Navigate to marketing > website > website pages or landing pages.",
  "parent": "sso-enablement-for-pages-%2F-landing-pages"
}, {
  "depth": 1,
  "id": "2.-select-a-single-page-or-landing-page-on-an-sso-enabled-domain%2C-or-select-multiple-pages-or-landing-pages-on-an-sso-enabled-domain-using-the-checkbox-option-in-the-listing%E2%80%99s-area%2C-and-click-the-%E2%80%9Ccontrol-audience-access%E2%80%9D-option-at-the-top-of-the-table.",
  "label": "2. Select a single page or landing page on an SSO enabled domain, or select multiple pages or landing pages on an SSO enabled domain using the checkbox option in the listing’s area, and click the “control audience access” option at the top of the table.",
  "parent": "sso-enablement-for-pages-%2F-landing-pages"
}, {
  "depth": 0,
  "id": "sso-enablement-for-knowledge-articles",
  "label": "SSO Enablement for Knowledge Articles",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-navigate-to-settings-%3E-knowledge-base-%3E-articles",
  "label": "1. Navigate to settings > knowledge base > articles",
  "parent": "sso-enablement-for-knowledge-articles"
}, {
  "depth": 1,
  "id": "2.-select-a-single-article-on-an-sso-enabled-domain-or-select-multiple-articles-on-an-sso-enabled-domain-using-the-checkbox-option-in-the-listing%E2%80%99s-area%2C-and-click-the-%E2%80%9Ccontrol-audience-access%E2%80%9D-option-at-the-top-of-the-table.",
  "label": "2. Select a single article on an SSO enabled domain or select multiple articles on an SSO enabled domain using the checkbox option in the listing’s area, and click the “control audience access” option at the top of the table.",
  "parent": "sso-enablement-for-knowledge-articles"
}, {
  "depth": 0,
  "id": "control-audience-access-option-settings",
  "label": "Control Audience Access Option Settings",
  "parent": null
}, {
  "depth": 0,
  "id": "instructions-for-specific-identity-providers",
  "label": "Instructions for specific identity providers",
  "parent": null
}, {
  "depth": 1,
  "id": "okta",
  "label": "Okta",
  "parent": "instructions-for-specific-identity-providers"
}, {
  "depth": 1,
  "id": "onelogin",
  "label": "OneLogin",
  "parent": "instructions-for-specific-identity-providers"
}, {
  "depth": 0,
  "id": "frequently-asked-questions",
  "label": "Frequently Asked Questions",
  "parent": null
}, {
  "depth": 1,
  "id": "what-happens-to-my-content-if-i-disable-sso-for-a-domain%3F",
  "label": "What happens to my content if I disable SSO for a domain?",
  "parent": "frequently-asked-questions"
}, {
  "depth": 1,
  "id": "can-i-go-back-to-the-old-private---registration-required-option%3F-how",
  "label": "Can I go back to the old Private - registration required option? How",
  "parent": "frequently-asked-questions"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      img: "img",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "SSO for Memberships"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['service_hub-professional', 'service_hub-enterprise', 'cms-enterprise', 'cms-professional']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Manage all of your businesses access permission and authentication needs in a single system with single sign-on (SSO) for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "memberships"
      }), ". This system allows you to manage access to your company’s applications across your stack, giving your end-users a single username and password combo for all of the applications and content they should have access to."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this setup process must be done by an IT administrator with experience creating applications in your identity provider account. At this time, we only support SAML-based applications."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initial Setup"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Follow the steps below to begin setting up your SSO for memberships."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The navigation instructions and field names described below may differ across identity providers. You can find more specific instructions for setting up applications in commonly used identity providers below:"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["• ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#okta",
          children: "Okta"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["• ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#onelogin",
          children: "OneLogin"
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Login to your identity provider."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Navigate to your applications."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Create a new SAML application specifically for HubSpot content access."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To get the Audience URI and Sign on URL, ACS, Recipient, or Redirect values:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In your HubSpot account, click the settings icon settings in the main navigation bar."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the left sidebar menu, select Private Content."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Select a domain from the “choose a domain to edit” picklist to open the settings for that domain. Note* SSO must be enabled on a per-subdomain basis at this time."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/sso-memberships/sso-for-memberships-sub-domain-select.png",
        alt: "Select a domain from the dropdown"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Single sign-on (SSO)"
        }), " section, click Set up."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the right pane, click Copy next to the values as needed. If you are using Microsoft AD FS, click the Microsoft AD FS tab to copy the values needed."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Paste them into your identity provider account where required."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "4. Copy the identifier or issuer URL, the single-sign on URL, and the certificate from your identity provider, and paste them into the corresponding fields in the SSO setup panel in HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "5. Click Verify."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once verification is complete, a “single sign on is enabled” notification will appear at the top of the General & Templates tabs for that domain and all template and email settings options that are no longer managed through HubSpot (because they are now managed through your IaP) will be disabled."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SSO Enablement for Blogs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Navigate to settings > CMS > Blog in the Settings UI."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Select a blog that is currently hosted on an SSO enabled subdomain from the “select a blog to modify” list."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Locate the control audience access settings at the bottom of your blog’s general tab."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Visit the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso#control-audience-access-option-settings",
        children: "control audience access option settings section"
      }), " for more information on these choices."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SSO Enablement for Pages / Landing Pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Navigate to marketing > website > website pages or landing pages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Select a single page or landing page on an SSO enabled domain, or select multiple pages or landing pages on an SSO enabled domain using the checkbox option in the listing’s area, and click the “control audience access” option at the top of the table."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "These options are also available in the page and landing page editor > settings tab."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/sso-memberships/page-landing-page-editor.png",
        alt: "Page and landing page listing in app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Visit the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso#control-audience-access-option-settings",
        children: "control audience access option settings section"
      }), " for more information on these choices."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SSO Enablement for Knowledge Articles"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Note:"
        }), " Articles must be set at the article level at this time. We will address global SSO settings options for the knowledge base at a later time."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Navigate to settings > knowledge base > articles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Select a single article on an SSO enabled domain or select multiple articles on an SSO enabled domain using the checkbox option in the listing’s area, and click the “control audience access” option at the top of the table."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Note:"
      }), " These options are also available in the article editor > settings tab."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/sso-memberships/kb-article-editor.png",
        alt: "Knowledge base article listing in app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Visit the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso#control-audience-access-option-settings",
        children: "control audience access option settings section"
      }), " for more information on these choices."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Control Audience Access Option Settings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you would like all users in your IaP that have HubSpot as an assigned app to be able to see your private content, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "select the Private - Single sign on(SSO) required"
      }), " option."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you would like to segment users with the assigned HubSpot app in your IaP into smaller tiered groups, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "select the Private - Single sign on (SSO) required with list filtering"
      }), " option."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "This option requires users to be both a member of your IaP with the assigned app AND a member of a contact list within HubSpot in order to view pages. The benefit of this option is that it allows you to further refine access if your business operates on a tiered subscription model, for example members get access to different content materials depending on their bronze, gold, or platinum subscription levels."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Note:"
        }), " This is also the default option for content previously marked as “Private - registration required”. If you have content previously marked as \"Private - registration required\" and would like to transition fully to SSO, please verify that all contacts currently in those assigned lists are added to your IaP before switching over to unfiltered SSO management. Failure to do so will result in contacts losing access to that content."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instructions for specific identity providers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Okta"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you need administrative access in your Okta instance. This process is only accessible in the Classic UI in Okta."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Log in to Okta. Make sure you are in the administrative instance of your Okta developer account."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications"
        }), " in the top navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add application"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create new app."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Web"
        }), " as your platform and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "SAML 2.0"
        }), " as your Sign On method then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fill out the General Settings screen as desired then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the Configure SAML screen, copy and paste the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sign on URL"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Audience URI"
        }), " from HubSpot into Okta, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Next"
        }), " and finish the app creation process."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Assignments"
        }), " tab. Assign the new app to any users that will have access to private content, including yourself."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sign On"
        }), " tab. Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View setup instructions"
        }), " button."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy the Identity Provider ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Single Sign-On URL"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Identity Provider Issuer"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "X.509 Certificate"
        }), " from Okta into HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verify"
        }), ". You'll be prompted to log in with your Okta account to finish the configuration and save your settings."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "OneLogin"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you need administrative access in your OneLogin instance to create a new SAML 2.0 application in OneLogin, as required."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Log in to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "OneLogin"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add App"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Search for ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "SAML Test Connector (Advanced)"
        }), " and select the app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configuration"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy and paste the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Audience"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Recipient"
        }), " from HubSpot into the corresponding fields in OneLogin."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "SSO"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Issuer URL"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "SAML 2.0 Endpoint"
        }), " (Single Sign-on URL) into HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View Details"
        }), " under the X.509 Certificate then copy and paste the certificate into HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Users"
        }), " tab in the top left and add yourself and any other users that should have access to private content to the OneLogin application you created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verify"
        }), ". You'll be prompted to log in with your OneLogin account to finish the configuration and save your settings."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Frequently Asked Questions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "What happens to my content if I disable SSO for a domain?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you disable SSO for a domain that has published private content on it today, any Private - Single sign on (SSO) required pages will become fully public. Any content marked Private - Single sign on (SSO) required with list filtering will remain private and will be inaccessible to all users."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Can I go back to the old Private - registration required option? How"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To go back to simple registration through HubSpot, our recommendation is to first change all sensitive content over to Private - Single sign on (SSO) required with list filtering, then disable SSO for that domain, and then to change all private content over to Private - registration required. During this switch we recommend reviewing any lists or workflows used to populate lists for registration purposes to ensure things are correct before saving the changes."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}