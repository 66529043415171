"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158160967195;
const slug = exports.slug = 'reference/ui-components/standard-components/empty-state';
const title = exports.title = 'EmptyState | UI components';
const name = exports.name = 'EmptyState | UI components';
const metaDescription = exports.metaDescription = 'Learn about the EmptyState component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      a: "a",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "EmptyState | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EmptyState"
      }), " component sets the content that appears when the extension is in an empty state. Use this component when there's no content or data to help guide users."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-guidelines-empty-state-primary-button.png",
        alt: "design-guidelines-empty-state-primary-button"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Image:"
        }), " the default image that comes with the component."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Title:"
        }), " the title that describes why the component is in an empty state."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Additional text:"
        }), " an additional ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://developers.hubspot.com/docs/platform/ui-extension-components/text",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "Text"
          }), " component"]
        }), " to provide further guidance. This does not come with the component by default."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Additional button:"
        }), " an additional ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "Button"
          }), " component"]
        }), " to can help users take action. This does not come with the component by default."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { EmptyState, Text } from '@hubspot/ui-extensions';\n\n\nconst Extension = ({ data }) => {\n  if (!data || !data.length) {\n    return (\n      <EmptyState title=\"Nothing here yet\" layout=\"vertical\" reverseOrder={true}>\n        <Text>Go out there and get some leads!</Text>\n      </EmptyState>\n    )\n  }\n\n  return (\n      {data.map(...)}\n  );\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Prop"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flush"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", removes the default vertical margins for the component. By default, set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "imageWidth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The max-width for the image container. By default, set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "250"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'horizontal'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'vertical'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The layout direction of the content."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "reverseOrder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", swaps out the visual order of the text (primary) and image (secondary) content. This ensures that the primary content is presented first to screen readers. By default, set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text for the title header."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Display when it's the first use of a feature."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Show when the user is required to take action in order to populate the card with information."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " make empty states informative so that users understand what will appear when the extension is not empty."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " make empty states actionable. If relevant, explain the benefits of this area and how to add content or data."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " make empty states too long."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/alert",
          children: "Alert"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/error-state",
          children: "ErrorState"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/loading-spinner",
          children: "LoadingSpinner"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}