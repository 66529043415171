'use es6';

import generateSdkExample, { LANGUAGE_TYPE } from 'snippets-gen';
const LANGUAGES = {
  node: LANGUAGE_TYPE.JAVASCRIPT,
  php: LANGUAGE_TYPE.PHP,
  ruby: LANGUAGE_TYPE.RUBY,
  python: LANGUAGE_TYPE.PYTHON
};
export default ((har, lang, oas, operationId, additionalParams) => {
  const language = LANGUAGES[lang];
  return generateSdkExample(language, oas, operationId, har, additionalParams);
});