"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611094;
const slug = exports.slug = 'guides/cms/tools/design-manager';
const title = exports.title = 'Gestionnaire de conception';
const name = exports.name = '[nouveau] Gestionnaire de conception';
const metaDescription = exports.metaDescription = 'Le gestionnaire de conception est un environnement de développement intégré basé sur le web qui peut être utilisé de manière facultative pour créer des modèles, des modules, des CSS et du JavaScript.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/module%20editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'Le gestionnaire de conception';
const toc = exports.toc = [{
  "depth": 0,
  "id": "param%C3%A8tres-du-gestionnaire-de-conception",
  "label": "Paramètres du gestionnaire de conception",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Gestionnaire de conception"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le gestionnaire de conception est un environnement de développement intégré basé sur le web qui peut être utilisé de manière facultative pour créer des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modèles"
      }), ", des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), ", des CSS et du JavaScript. Le gestionnaire de conception est accessible via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Fichiers et modèles > Outils de conception"
        })
      }), " dans la barre de navigation supérieure."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si vous préférez développer localement en utilisant votre éditeur de code, vos workflows et vos technologies préférés, vous pouvez utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "ILC de CMS"
        }), " à la place."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Que vous développiez localement ou que vous utilisiez le gestionnaire de conception pour créer des ressources, l'ensemble du système de fichiers de développement de votre compte sera représenté et modifiable dans le gestionnaire de conception."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/designmanager%203-20-2020.png",
        alt: "Capture d'écran du gestionnaire de conception HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certains développeurs ou certaines équipes choisissent d'utiliser un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "workflow qui combine le gestionnaire de conception et le développement local"
      }), ". Vous pouvez utiliser la commande ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch"
        })
      }), " de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ILC de CMS"
      }), " pour transférer les modifications du gestionnaire de conception dans votre environnement local. Vous pouvez également empêcher la modification et faire en sorte que l'ensemble de votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "base de code soit continuellement intégré au contrôle de source"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consultez cet ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "article de la base de connaissances"
      }), " pour une présentation complète du gestionnaire de conception et de ses différents composants."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres du gestionnaire de conception"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le gestionnaire de conception dispose de paramètres IDE de base que vous pouvez configurer pour adapter l'expérience à vos besoins et à vos workflows. Un bouton de configuration apparaît à côté du bouton d'aide dans la barre inférieure et peut être utilisé pour configurer les paramètres."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Vous pouvez configurer les paramètres pour :"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Thème de l'éditeur"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Police"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Taille des tabulations"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Unité de retrait"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tabulations soft"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Retrait intelligent"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Retrait sur la mise en forme"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Retrait avec tabulations"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Retour automatique à la ligne"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Crochets à fermeture automatique"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Correspondance des crochets"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Correspondance des balises"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "ESLint"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["et bien plus encore, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://knowledge.hubspot.com/cos-general/customize-design-manager-settings",
                children: "cet article de la base de connaissances"
              }), " pour une description des paramètres pour plus d'informations."]
            }), "\n"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Si ces fonctionnalités ne répondent pas à vos besoins, nous vous recommandons vivement d'essayer nos ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli",
              children: "outils de développement local"
            }), " et notre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/creating-an-efficient-development-workflow",
              children: "workflow"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Design%20Manger%20Settings-1.png",
            alt: "Capture d'écran de l'écran des paramètres du gestionnaire de conception"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}