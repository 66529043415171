"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66491233096;
const slug = exports.slug = 'guides/cms/content/templates/overview';
const title = exports.title = 'Übersicht über Vorlagen';
const name = exports.name = 'EMEA DACH (de) Templates';
const metaDescription = exports.metaDescription = 'Vorlagen sind wiederverwendbare Seiten- oder E-Mail-Wrapper, die im Allgemeinen Module und Teilvorlagen in ein Layout platzieren. Content-Autoren wählen eine Vorlage aus, die sie bei der Erstellung einer Seite oder E-Mail verwenden.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-vorlage-erstellen",
  "label": "Eine Vorlage erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "vorschau-einer-vorlage-anzeigen",
  "label": "Vorschau einer Vorlage anzeigen",
  "parent": null
}, {
  "depth": 1,
  "id": "vorschau-mit-dem-design-manager",
  "label": "Vorschau mit dem Design-Manager",
  "parent": "vorschau-einer-vorlage-anzeigen"
}, {
  "depth": 1,
  "id": "vorschau-mit-einer-neuen-seite",
  "label": "Vorschau mit einer neuen Seite",
  "parent": "vorschau-einer-vorlage-anzeigen"
}, {
  "depth": 0,
  "id": "vorlagentypen",
  "label": "Vorlagentypen",
  "parent": null
}, {
  "depth": 1,
  "id": "seite",
  "label": "Seite",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "e-mail",
  "label": "E-Mail",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "teilvorlage",
  "label": "Teilvorlage",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "globale-teilvorlage",
  "label": "Globale Teilvorlage",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "blog",
  "label": "Blog",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "blog-listing",
  "label": "Blog-Listing",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "blog-beitrag",
  "label": "Blog-Beitrag",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "kombinierte-blog-beitrags--und-listing-vorlage",
  "label": "Kombinierte Blog-Beitrags- und Listing-Vorlage",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "systemseiten",
  "label": "Systemseiten",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "fehlerseiten",
  "label": "Fehlerseiten",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "e-mail-abo-einstellungen",
  "label": "E-Mail-Abo-Einstellungen",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "e-mail-back-up-abo-k%C3%BCndigung",
  "label": "E-Mail-Back-up Abo-Kündigung",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "best%C3%A4tigung-der-abmeldung-von-e-mail",
  "label": "Bestätigung der Abmeldung von E-Mail",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "passwortaufforderung",
  "label": "Passwortaufforderung",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "suchergebnisseite",
  "label": "Suchergebnisseite",
  "parent": "vorlagentypen"
}, {
  "depth": 1,
  "id": "mitgliedschaft",
  "label": "Mitgliedschaft",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "mitgliedschaftsanmeldung",
  "label": "Mitgliedschaftsanmeldung",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "registrierung-f%C3%BCr-mitgliedschaft",
  "label": "Registrierung für Mitgliedschaft",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "zur%C3%BCcksetzen-des-passworts-f%C3%BCr-die-mitgliedschaft",
  "label": "Zurücksetzen des Passworts für die Mitgliedschaft",
  "parent": "vorlagentypen"
}, {
  "depth": 2,
  "id": "anfrage-zum-zur%C3%BCcksetzen-der-mitgliedschaft",
  "label": "Anfrage zum Zurücksetzen der Mitgliedschaft",
  "parent": "vorlagentypen"
}, {
  "depth": 0,
  "id": "enthaltene-cms-dateien",
  "label": "Enthaltene CMS-Dateien",
  "parent": null
}, {
  "depth": 1,
  "id": "jquery",
  "label": "jQuery",
  "parent": "enthaltene-cms-dateien"
}, {
  "depth": 1,
  "id": "layout.css",
  "label": "layout.css",
  "parent": "enthaltene-cms-dateien"
}, {
  "depth": 1,
  "id": "hubspot-tracking-code",
  "label": "HubSpot-Tracking-Code",
  "parent": "enthaltene-cms-dateien"
}, {
  "depth": 0,
  "id": "weiterf%C3%BChrende-ressourcen",
  "label": "Weiterführende Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Übersicht über Vorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vorlagen definieren das Layout Ihrer HubSpot-Seiten, -E-Mails und -Designs. Eine Vorlage besteht aus Modulen und Teilvorlagen und kann auf andere Elemente wie Stylesheets und JavaScript-Dateien verweisen. Vorlagen können entweder über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "HubSpot CLI"
      }), " oder im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "Design-Manager von HubSpot"
      }), " erstellt werden. Für Content-Autoren ist die Vorlage das erste, das sie bei der Erstellung einer Seite oder E-Mail auswählen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie, wie Sie eine Vorlage erstellen, welche Arten von Vorlagen es gibt und was in Ihren Vorlagen enthalten ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Vorlage erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine Vorlage entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "in HubSpot"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "mithilfe des CLI"
      }), " erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um eine Vorlage mithilfe des CLI zu erstellen, führen Sie den folgenden Befehl aus:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <name> [dest]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der Vorlage"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Pfad des lokalen Verzeichnisses, in dem Sie die Vorlage erstellen möchten. Ist dies nicht der Fall, wird die Vorlage in dem Verzeichnis erstellt, in dem Sie sich gerade befinden."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigieren Sie mit den Pfeiltasten zu dem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#template-types",
          children: "Typ von Vorlage"
        }), ", den Sie erstellen möchten, und drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eingabetaste"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Vorlage wird dann lokal erstellt. Wenn Sie die Vorlage zur Verwendung in Ihrem HubSpot-Account bereitstellen möchten, müssen Sie die Vorlage in Ihren Account ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "hochladen"
      }), ". Sie können auch den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "watch"
      }), "-Befehl verwenden, um automatisch alle neuen Dateien und Änderungen an bestehenden Dateien im aktuellen Arbeitsverzeichnis und in den Unterverzeichnissen hochzuladen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorschau einer Vorlage anzeigen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie eine Vorlage aktualisiert haben, können Sie eine Vorschau anzeigen, um sicherzustellen, dass sie wie erwartet aussieht und sich verhält. Es gibt verschiedene Möglichkeiten in HubSpot, die Vorschau einer Vorlage anzuzeigen, z. B.:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau einer Vorlage im Design-Manager:"
        }), " Eignet sich am besten für schnelle visuelle Überprüfungen oder wenn Sie einen Blog-Beitrag/ein Listings/eine kombinierte Vorlage in einer Vorschau anzeigen müssen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erstellen eines neuen Elemente über eine Vorlage:"
        }), " Eignet sich am besten für das Testen des Drag-&-Drop-Editors und der Content-Erstellung."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorschau mit dem Design-Manager"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Vorschau von Vorlagen mithilfe des Design-Managers kann für schnelle visuelle Überprüfungen besonders hilfreich sein. Mit der Vorlagenvorschau können Sie auch Anzeigeoptionen konfigurieren, z. B. Viewport-Maße."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So zeigen Sie die Vorschau einer Vorlage im Design-Manager an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Marketing“"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Dateien und Vorlagen“"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Design-Tools“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im Dateiexplorer der linken Seitenleiste auf die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorlage"
        }), ", die Sie in der Vorschau anzeigen möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Vorschau“"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "„Live-Vorschau mit Anzeigeoptionen“"
            }), " aus, um eine Vorschau der Vorlage mit Optionen zum Testen der Responsivität und der Domain-Einstellungen wie Stylesheets anzuzeigen. Diese Option zeigt die Seite in einem iFrame an. Auf diese Weise können Sie auch zwischen Blogs und der Blog-Beitrags- oder Listing-Ansicht für Blog-Vorlagen wählen."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "„Vorschau ohne Anzeigeoptionen“"
            }), " aus, um eine Vorschau der Vorlage ohne zusätzliche Optionen anzuzeigen."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/template-preview-options.png",
        alt: "template-preview-options"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorschau mit einer neuen Seite"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Drag-&-Drop-Bereiche, Standardparameterwerte in Modulen oder andere wichtige Komponenten ändern, empfiehlt es sich, mithilfe der Vorlage eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/website-pages/create-and-customize-pages",
        children: "Website-Seite"
      }), ", einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/blog/create-a-new-blog",
        children: "Blog"
      }), ", einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/blog/create-and-publish-blog-posts",
        children: "Blog-Beitrag"
      }), " oder eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
        children: "E-Mail zu erstellen"
      }), ". Sie können dann verschiedene Werte für die Modulfelder ausprobieren und testen, wie Ihre Vorlage tatsächlich aussehen wird, und sie dann so optimieren, dass Content-Autoren ideal mit ihr arbeiten können. Sie können diese Elemente entweder veröffentlichen oder zu Testzwecken im Entwurfsmodus belassen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Darüber hinaus können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-general/redesign-and-relaunch-your-site-with-content-staging#new",
        children: "Content-Staging"
      }), " oder einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "Entwickler-Sandbox-Account"
      }), " verwenden, um Elemente zu erstellen und anzuzeigen, ohne einen Produktions-Account zu beeinträchtigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorlagentypen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vorlagen können für verschiedene Typen von Inhalten verwendet werden, z. B. für Website-Seiten und Blog-Beiträge. Bei Code-Vorlagen geben Sie den Typ der Vorlage an, indem Sie eine Annotation am Anfang der Datei hinzufügen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die verschiedenen Typen von Vorlagen und die Annotationen, die Sie zur Kennzeichnung der einzelnen Typen verwenden können."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten Sie:"
        }), " Ein Content-Autor kann die Vorlage einer Seite gegen eine andere Vorlage desselben Typs austauschen, je nachdem, ob sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "-Tags enthält."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Mit dem visuellen Drag-&-Drop-Layout-Editor erstellte Vorlagen können gegen andere Drag-&-Drop-Vorlagen oder Code-Vorlagen mit oder ohne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags können nur gegen andere Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen ", (0, _jsxRuntime.jsx)("u", {
            children: "ohne"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area-"
          }), "-Tags können nur gegen andere Code-Vorlagen ohne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seite"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Seitenvorlagen sind der offenste Vorlagentyp. Sie können als jede Art von Webseite oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/7177/what-is-a-landing-page-and-why-should-you-care.aspx",
        children: "Landingpage"
      }), " dienen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#layout-css",
        children: "Seitenlayouts"
      }), " werden nicht mit Komponenten vorab ausgefüllt. Code-Seitenvorlagen sind mit nur wenig Markup vorab ausgefüllt, darunter vorgeschlagene HubL-Tags für Meta-Informationen, Titel und erforderliche Header- und Footer-", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#included-cms-files",
        children: "Includes"
      }), ". Beispiele für Seiten, die typischerweise eine Seitenvorlage verwenden, sind unter anderem:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
          children: "Homepage"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/about.html",
          children: "Über uns"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/contact.html",
          children: "Kontakt"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-Mail"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["E-Mail-Vorlagen werden vom ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/marketing/email",
        children: "E-Mail-Tool"
      }), " verwendet. Sie unterliegen strengsten Anforderungen, da sie in vielen verschiedenen E-Mail-Clients angezeigt werden und den besten Praktiken entsprechen müssen, um eine ordnungsgemäße Zustellbarkeit zu gewährleisten. Die Drag-&-Drop-E-Mail-Vorlagen von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), " und auch des Design-Managers werden bei der Erstellung mit Basiskomponenten vorausgefüllt. Vorlagen mit diesem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " sind nur beim ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/email/create-and-send-marketing-emails",
        children: "Erstellen einer E-Mail"
      }), " als auswählbare Vorlage sichtbar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), "-konform zu sein, müssen E-Mail-Vorlagen über eine Reihe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-email-template-variables",
        children: "erforderlichen Variablen"
      }), " verfügen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["E-Mail-Vorlagen verfügen außerdem über eine integrierte Funktion für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/email-template-markup#hs-inline-css-and-data-hse-inline-css",
        children: "inline-css"
      }), ", das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "-Elementen mit einem speziellen Klassennamen oder Datenattribut hinzugefügt wurde. Das Einbinden von CSS in E-Mails ist eine Methode, um eine bessere Unterstützung über verschiedene E-Mail-Clients zu erreichen. Glücklicherweise ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://litmus.com/blog/do-email-marketers-and-designers-still-need-to-inline-css",
        children: "unterstützen die meisten gängigen E-Mail-Clients inzwischen eingebettetes CSS"
      }), ". Dies ist jedoch nicht repräsentativ für Ihre spezifischen Empfänger. Setzen Sie Ihr Urteilsvermögen ein, um das Richtige für Ihre Empfänger zu tun."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/email-template-markup",
        children: "Erfahren Sie mehr über das Erstellen von E-Mail-Vorlagen."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Teilvorlage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page isAvailableForNewContent: false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Teilvorlagen sind Vorlagendateien, die in andere Code-Dateien eingefügt werden können. Im Gegensatz zu globalen Teilvorlagen können Teilvorlagen einzeln bearbeitet werden, ohne dass sich dies auf andere Instanzen der Teilvorlage auswirkt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#partials",
        children: "Teilvorlagen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Globale Teilvorlage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: global_partial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Globale Teilvorlagen sind eine Art von Vorlage, die mit HTML & HubL erstellt wurde und auf Ihrer gesamten Website wiederverwendet werden kann. Die gebräuchlichsten Arten von globalen Teilverlagen sind Header, Seitenleisten- und Footer von Websites. Durch die Aktualisierung einer globalen Teilvorlage werden alle Instanzen der globalen Teilvorlage aktualisiert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
        children: "globale Teilvorlagen"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "globalen Content"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-blog/cos-blog-how-do-i-create-a-cos-blog",
        children: "Erstellung eines Blogs"
      }), " sind die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "Blog-Vorlagen"
      }), " ähnlich aufgebaut wie die Standard-", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#page-templates",
        children: "Seitenvorlagen"
      }), ". Der entscheidende Unterschied besteht darin, dass sie in den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Content-Einstellungen"
      }), " als Blog-Vorlagen ausgewählt werden können, Seitenvorlagen hingegen nicht. Vorlagen, die mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog"
      }), " erstellt wurden, werden im Vorlagenauswahlbildschirm ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "nicht angezeigt"
      }), ", wenn ein Benutzer eine Webseite erstellt. Blog-Vorlagen können grundsätzlich in zwei Formen auftreten, als Blog-Listing-Seiten und als Blog-Beitragsdetailseiten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blog-Listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_listing\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "Blog-Listing-Vorlage"
      }), " ist die Vorlage, die die Benutzer sehen, wenn sie zur URL des Blogs navigieren. Diese Vorlage wird in der Regel verwendet, um Zusammenfassungen, Titel und Feature-Bilder aller Beiträge im Blog aufzulisten, und zeigt außerdem eine Seitennummerierung an, um zu älteren Beiträgen zu gelangen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blog-Beitrag"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_post\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Blog-Beitragsvorlage ist die Vorlage, die Benutzer sehen, wenn sie einen einzelnen Beitrag im Blog anzeigen. Diese Vorlagen zeigen in der Regel den vollständigen Inhalt des Beitrags."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Kombinierte Blog-Beitrags- und Listing-Vorlage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine einzige Blog-Vorlage kann das Layout für die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog#if-is-listing-view-statement",
        children: "Listing- und Detailseiten"
      }), " übernehmen, aber in der Regel werden diese in separate Vorlagen aufgeteilt. Kombinierte Vorlagen werden in den Blog-Einstellungen als für beide Optionen, Listing und Blog-Beitrag, auswählbar angezeigt. Wenn Sie eine Vorlage erstellen, die nur für Beiträge oder Listings verwendet werden soll, sollten Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um die Entwicklung und die Erstellung von Inhalten zu vereinfachen, wird empfohlen, die separaten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateTypes"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_listing"
        }), " anstelle von kombinierten Vorlagen zu verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Systemseiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Systemseitenvorlagen werden intern für ihren spezifischen Zweck gekennzeichnet. In den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Content-Einstellungen"
      }), " Ihres Accounts können Sie diese Vorlagen für den angegebenen Zweck auf der System-Registerkarte auswählen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Der einzige Typ von Systemseitenvorlage, der über das CLI erstellt werden kann, ist die Vorlage für die Suchergebnisseite."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Fehlerseiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fehlerseiten können in den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Content-Einstellungen"
      }), " entweder als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/404.html",
        children: "404"
      }), "- oder als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/500.html",
        children: "500"
      }), "-Seiten festgelegt werden. Beide Vorlagen verwenden denselben templateType. Vorlagen, die mit diesem templateType erstellt wurden, werden nicht im Bildschirm für die Vorlagenauswahl angezeigt, wenn ein Benutzer eine Webseite erstellt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: error_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "E-Mail-Abo-Einstellungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Seite für E-Mail-Abo-Einstellungen Listet alle verfügbaren Abonnementtypen auf, für die sich ein Benutzer entscheiden kann. Erforderlich, um das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_subscriptions \"email_subscriptions\"  %}"
      }), "-HubL-Tag zu enthalten. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscription-preferences.html",
        children: "Siehe die Vorlage für die Abonnementeinstellungen in der CMS Theme Boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscription_preferences_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "E-Mail-Back-up Abo-Kündigung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine Systemvorlage für E-Mail-Kündigungsseiten. Erforderlich, um das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_simple_subscription \"email_simple_subscription\" %}"
      }), "-HubL-Tag zu enthalten. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/backup-unsubscribe.html",
        children: "Siehe die Vorlage für E-Mail-Back-up Abo-Kündigung in der CMS Theme Boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_backup_unsubscribe_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bestätigung der Abmeldung von E-Mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine Systemvorlage für Bestätigungsseiten zur E-Mail-Kündigung. Hierher werden Benutzer weitergeleitet, wenn sie zu der von der Variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ unsubscribe_link_all }}"
        })
      }), " generierten URL gehen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscriptions-confirmation.html",
        children: "Siehe die Vorlage für die Abonnementbestätigung in der CMS Theme Boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscriptions_confirmation_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Passwortaufforderung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vorlagen für Passwortaufforderungen bieten eine gebrandete Seite, die Content-Autoren anzeigen können, um ein Passwort zu verlangen, bevor ein Besucher den eigentlichen Inhalt der Seite anzeigen kann. Vorlagen für Passwortaufforderungen werden über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Content-Einstellungen"
      }), " festgelegt. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "In diesem Artikel"
      }), " erfahren Sie, wie Sie Seite in HubSpot mit einem Passwort schützen. Siehe die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/password-prompt.html",
        children: "Aufforderung für die passwortgeschützte Seite"
      }), " in der Boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: password_prompt_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Suchergebnisseite"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine Systemvorlage für die integrierte Suchfunktion der CMS-Website. Siehe die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "Vorlage für die Suchergebnisseite"
      }), " in der Boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: search_results_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mitgliedschaft"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-Accounts mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "Funktion für Mitgliedschaften"
      }), " (", (0, _jsxRuntime.jsx)(_components.em, {
        children: "nur"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "CMS Hub"
      }), " Enterprise) können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-pages-editor/control-audience-access-to-pages",
        children: "auf ihren Websites Seiten erstellen, auf die nur Benutzer zugreifen können, die nicht zu bestimmten Listen im CRM gehören"
      }), ". Dies ermöglicht es den Besuchern der Website, Accounts mit Anmeldedaten einzurichten. Diese Vorlagen geben Ihnen die Kontrolle über das Erscheinungsbild dieser Seiten."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Nur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL-Vorlagen"
        }), " können Mitgliedschaftsvorlagen sein."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Mitgliedschaftsanmeldung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies ist die Anmeldeseite, die angezeigt wird, wenn ein Benutzer versucht, auf Inhalte zuzugreifen, deren Zugriff durch die Mitgliedschaftsfunktion kontrolliert wird. Enthält in der Regel das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_login \"member_login\" %}"
      }), "-Modul. Siehe die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-login.html",
        children: "Beispielvorlage für die Mitgliedschaftsanmeldung"
      }), " in der Boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_login_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Registrierung für Mitgliedschaft"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies ist die Seite für die Benutzerregistrierung, auf der die Benutzer einen Account erstellen können, um die Inhalte anzuzeigen, auf die die Benutzer dieser Liste zugreifen können. Enthält in der Regel das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_register \"member_register\" %}"
      }), "-HubL-Tag. Siehe die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-register.html",
        children: "Beispielvorlage für die Registrierung für Mitgliedschaft"
      }), " in der Boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_register_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Zurücksetzen des Passworts für die Mitgliedschaft"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies ist die Seite zum Zurücksetzen des Passworts. Die Benutzer geben ihr neues Passwort auf dieser Seite ein. Enthält in der Regel das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset \"password_reset\" %}"
      }), "-HubL-Tag. Siehe die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password.html",
        children: "Beispielvorlage für das Zurücksetzen des Passworts für die Mitgliedschaft"
      }), " in der Boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Anfrage zum Zurücksetzen der Mitgliedschaft"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies ist die Seite für das Anfordern des Zurücksetzens des Passworts. Sie zeigt ein Formulars zum Anfordern einer E-Mail zum Zurücksetzen des Passworts. Enthält in der Regel das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset_request \"password_reset_request\" %}"
      }), "-HubL-Tag. Siehe die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password-request.html",
        children: "Beispielvorlage für das Anfordern des Zurücksetzens des Passworts für die Mitgliedschaft"
      }), " in der Boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_request_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enthaltene CMS-Dateien"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt bestimmte JavaScript- und CSS-Dateien, die mit CMS-Vorlagen verbunden sind. Einige Dateien sind automatisch enthalten und können nicht entfernt werden, während andere optional hinzugefügt werden können. Weitere Informationen zur Reihenfolge, in der Stylesheets an CMS-Inhalte angehängt werden, finden Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-general/create-edit-and-attach-css-files-to-style-your-site",
        children: "diesem Artikel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jquery.com/",
        children: "jQuery"
      }), " ist optional im head-Tag von HubSpot-Vorlagen enthalten. Wenn sie enthalten ist, wird sie als Teil der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), "-HubL-Variable gerendert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Unter ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Einstellungen“ > „Website“ > „Seiten“"
      }), " können Sie die jQuery-Version auf 1.11.x, Version 1.7.1 ändern oder sie ganz deaktivieren. Sie können auch ein jQuery-Migrationsskript für die Abwärtskompatibilität mit älteren Browsern einfügen. Sie können jQuery in den Footer verschieben, um die Seiten-Performance zu verbessern. Durch das Verschieben von jQuery kann allerdings JavaScript beschädigt werden, das darauf angewiesen ist. Es wird empfohlen, dies zu testen, bevor Sie jQuery verschieben, indem Sie am Ende Ihrer Website-Seiten-URLs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " hinzufügen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Während jQuery in der Vergangenheit standardmäßig enthalten war, erfordert CMS Hub derzeit nicht jQuery. Die meisten Funktionen von jQuery verfügen jetzt über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://youmightnotneedjquery.com/",
        children: "moderne Vanilla-JavaScript-Äquivalente"
      }), ", und es wird empfohlen, diese stattdessen zu verwenden. Wenn Sie jQuery verwenden müssen, empfehlen wir, die Standardversion in den Einstellungen zu deaktivieren und die neueste Version zu verwenden, die über das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), "-Tag geladen wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um zu testen, ob das Entfernen von jQuery auf Ihrer Website irgendetwas beschädigt, fügen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsNoJQuery=true"
      }), " am Ende der URL hinzu, während Sie Ihre Website anzeigen, insbesondere Seiten mit starker Interaktivität."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "layout.css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Datei, früher als ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "required_base.css"
      }), " bekannt, ist für die Formatierung des responsiven Rasters von HubSpot verantwortlich. Diese Datei wird automatisch in jede Drag- & -Drop-Vorlage aufgenommen, ist jedoch standardmäßig ", (0, _jsxRuntime.jsx)("u", {
        children: "nicht"
      }), " in Vorlagen auf Basis von benutzerdefiniertem Code enthalten. Wenn Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), "-Tags in HTML- und HubL-Code-Vorlagen verwenden, müssen die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "layout.css"
      }), "-Datei nicht laden, aber eine Version davon ist ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "in der CMS-Boilerplate enthalten"
      }), ", um den schnellen Einstieg zu erleichtern."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zusätzlich zum responsiven CSS-Raster enthält die Datei einige Klassen, die zum Ein- und Ausblenden von Elementen in verschiedenen Viewports verwendet werden können. Um mehr zu erfahren, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/Designers_Docs_2015/layout.css",
        children: "sehen Sie sich die Datei direkt an"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpot-Tracking-Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/account/how-does-hubspot-track-visitors",
        children: "HubSpot-Tracking-Code"
      }), " wird, ausgenommen E-Mail-Vorlagen, immer automatisch mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "standard_footer_includes"
        })
      }), "-HubL-Variable zu jeder HubSpot-Vorlage hinzugefügt. Der Tracking-Code lädt eine Analytics-JavaScript-Datei namens ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Ihre_HubID.js"
      }), " (Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "158015.js"
      }), "). Dieser Tracking-Code ist direkt in die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/account/how-do-i-turn-on-gdpr-functionality-in-my-hubspot-account",
        children: "GDPR-Funktionalität von HubSpot"
      }), " integriert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weiterführende Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag-&-Drop-Bereiche"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "Vorlagen für Abschnitte"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "So verwenden Sie Webkomponenten in Vorlagen und Modulen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "Erstellen Sie Header, Footer und mehr mit globalem Content"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}