"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611087;
const slug = exports.slug = 'guides/cms/content/templates/types/drag-and-drop-templates';
const title = exports.title = 'Drag-&-Drop-Vorlagen';
const name = exports.name = '[neu] Vorlagen - Drag-and-Drop';
const metaDescription = exports.metaDescription = 'Drag-&-Drop-Design-Manager-Vorlagen sind Vorlagen, die mit einem visuellen Designer erstellt wurden. Sie werden nicht für neue Vorlagen empfohlen, da Code-Vorlagen ein besseres Erlebnis bieten.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image2-3.png';
const featuredImageAltText = exports.featuredImageAltText = 'Design-Manager-Schnittstelle mit ausgewählten Modulen';
const toc = exports.toc = [{
  "depth": 0,
  "id": "drag-%26-drop-vorlagendesigner",
  "label": "Drag-&-Drop-Vorlagendesigner",
  "parent": null
}, {
  "depth": 1,
  "id": "module",
  "label": "Module",
  "parent": "drag-%26-drop-vorlagendesigner"
}, {
  "depth": 1,
  "id": "gruppen",
  "label": "Gruppen",
  "parent": "drag-%26-drop-vorlagendesigner"
}, {
  "depth": 1,
  "id": "globale-gruppen",
  "label": "Globale Gruppen",
  "parent": "drag-%26-drop-vorlagendesigner"
}, {
  "depth": 1,
  "id": "flexible-spalten",
  "label": "Flexible Spalten",
  "parent": "drag-%26-drop-vorlagendesigner"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      h2: "h2",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Drag-&-Drop-Vorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Drag-&-Drop-Vorlagen werden für neue Vorlagen nicht empfohlen:**Diese Vorlagen können nicht Teil eines Designs sein und unterstützen daher keine Designfunktionen wie Designfelder und Designmodule. Drag-&-Drop-Vorlagen werden in CMS Hub Starter NICHT unterstützt, verwenden Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ". Drag-&-Drop-Vorlagen unterstützen nicht einige der neueren Funktionen des CMS (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "Mitgliedschaften"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag-&-Drop-Bereiche"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "wiederverwendbare Abschnitte"
        }), ", GraphQL und viele andere Funktionen). Sie sind nicht erweiterbar und bieten Content-Autoren und Entwicklern keine so gute Umgebung wie Code-Vorlagen. Stattdessen empfehlen wir codierte ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL-Vorlagen"
        }), " mit ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag-&-Drop-Bereichen"
        }), ", die Entwicklern und Marketern ein besseres Erlebnis bieten. Für einen schnellen Einstieg in das CMS empfehlen wir Ihnen, sich die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate",
          children: "HubSpot Theme Boilerplate"
        }), " anzusehen, die mit codierten Vorlagen erstellt wurde."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'marketing_hub-professional', 'cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag-&-Drop-", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Vorlagen"
      }), " wurden für weniger technisch bewandte Benutzer entwickelt, um auf einfache Weise eine Website im HubSpot-CMS erstellen zu können. Drag-&-Drop-Vorlagen nutzen einen visuellen Vorlagendesigner, der HTML + HubL unter der Haube generiert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Damit der visuelle Designer funktioniert, wird die HTML-Ausgabe von HubSpot gesteuert und Sie haben keine direkte Kontrolle über die strukturellen Teile davon. Zusätzlich ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "wird eine layout.css-Datei auf die Seite geladen, die ein einfaches 12-Spalten-Gitter auf Basis von Bootstrap 2 ermöglicht"
      }), ". Dies macht alle Websites, die mit Drag-&-Drop-Vorlagen erstellt wurden, standardmäßig responsiv, sodass Inhaltszeilen vertikal gestapelt werden. Für eine anspruchsvollere und individuelle Optimierung für Mobilgeräte sollten Sie Ihre eigenen responsiven Stile hinzufügen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag-&-Drop-Vorlagen werden im Design-Manager erstellt und bei der Interaktion mit ihnen über die API oder die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Tools für lokale Entwicklung"
      }), " als JSON dargestellt. Aufgrund der Beschaffenheit von Drag-&-Drop-Vorlagen ist die einzige empfohlene Methode, sie zu bearbeiten, über die Design-Manager-Schnittstelle. **Wenn Sie lieber programmieren, Versionskontrolle verwenden oder einfach in der Lage sein möchten, Ihre bevorzugten Tools lokal zu bearbeiten, bieten HTML + HubL-codierte Vorlagen die beste Umgebung für Entwickler.**Die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), "-Funktionalität für HTML + HubL-Vorlagen ermöglicht auch Content-Autoren ein besseres Erlebnis als die Drag-&-Drop-Oberfläche des Design-Managers, da sie im visuellen Content-Editor verbleibt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Drag-&-Drop-Vorlagendesigner"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Drag-&-Drop-Vorlage zu erstellen, öffnen Sie den Design-Manager und erstellen Sie im Finder eine neue Datei, wählen Sie eine Vorlage sowie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "Typ der Vorlage"
      }), " aus, die Sie erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Drag-&-Drop-Vorlagen bestehen aus Bausteinen. Diese Bausteine, oder Blöcke, sind Module, Gruppen, globale Gruppen und flexible Spalten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts",
        children: "Erfahren Sie, wie Sie den Drag-&-Drop-Vorlagendesigner verwenden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module sind wiederverwendbare Komponenten, die bearbeitbare Teile einer Seite sind. Module bestehen aus einem HTML + HubL-Vorlagenfragment, CSS, JS und Feldern. Module können von Content-Autoren in flexiblen Spalten und dnd_areas platziert werden und sind ihr wichtigste Mittel, um die Inhalte ihrer Website zu verwalten. Sie können Module für eine beliebige Anzahl von Funktionen erstellen: Suche, Bildergalerien, Menüs, komplexe Marketinganimationen, Taschenrechner, Produktvergleiche – die Möglichkeiten sind Ihrer Vorstellungskraft überlassen und dem, was Sie für ein gutes Nutzererlebnis für Content-Autoren halten. Über die Felder steuert der Content-Editor die Ausgabe. Module sind nicht nur für Drag-&-Drop-Vorlagen ein wichtiges Element, sie sind ein sehr wichtiger Kernbaustein von CMS Hub. In Drag-&-Drop-Vorlagen können die Standardwerte für Modulfelder im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
        children: "Inspektor"
      }), " konfiguriert werden. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Erfahren Sie mehr über Module."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image2-3.png",
        alt: "Design-Manager-Schnittstelle mit hervorgehobenen Modulen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gruppen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Gruppen sind Wrapper für andere Gruppen und Module. Sie könnenüber CSS-Klassen und HTML-Wrapper verfügen. Gruppen manifestieren sich als umhüllender HTML mit Layout-Klassen, um die Platzierung und Dimensionierung der Gruppen zu erleichtern. Die Gruppen können auch einen internen Namen haben. Damit können Sie im Design-Manager ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#group-modules",
        children: "Module gruppieren"
      }), ", wodurch es einfacher wird, Teile einer Seite visuell zu identifizieren. Ein Beispiel hierfür könnten Seitenleisten oder Banner sein."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL"
        }), "-Dateien der empfohlene Pfad für neue Websites, Spalten, Abschnitte und Zeilen von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag-&-Drop-Bereichen"
        }), " sind, ersetzen Drag-&-Drop-Bereiche weitgehend die Zwecke von Gruppen."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Zusätzlich kann ein Entwickler Teilvorlagen und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "globale Teilvorlagen"
        }), " erstellen, die zusätzlich zu Drag-&-Drop-Bereichen Freeform-Code enthalten können."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Drag-&-Drop-Bereiche, Teilvorlagen und globale Teilvorlagen werden in Drag-&-Drop-Vorlagen nicht unterstützt."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image4-1.png",
        alt: "Design-Manager-Screenshot mit ausgewählter Seitenleistengruppe"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Globale Gruppen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Globale Gruppen sind Gruppen, deren Bearbeitung sich auf die gesamte Website und nicht nur auf die Seite, auf der sie sich befinden, auswirkt. Globale Gruppen können in mehreren Vorlagen vorhanden sein und werden am häufigsten für Website-Header und Footer verwendet. Globale Gruppen ähneln Teilvorlagen, sind aber auf die Struktur beschränkt, die durch Drag-&-Drop-Vorlagen erzwungen wird. Sie können bei Bedarf in HTML + HubL-Dateien eingebettet werden, in den meisten Fällen ist es allerdings sinnvoller, stattdessen eine globale Teilvorlage zu verwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-3.png",
        alt: "Design-Manager-Schnittstelle mit hervorgehobenen globalen Gruppen in Header und Footer"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Flexible Spalten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Flexible Spalten sind ein spezieller Typ von Gruppe. Sie können einen Standardsatz von Modulen enthalten, aber Content Editoren können Module darin hinzufügen, entfernen und verschieben. Flexible Spalten ermöglichen eine eindimensionale Neuanordnung von Modulen, vertikal nach oben und unten. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Im Gegensatz zu dnd_area-Tags"
      }), " **unterstützen flexible Spalten weder Abschnitte noch die Formatierungsmöglichkeiten von Modulen innerhalb dieser Abschnitte.**Flexible Spalten sind nicht nur für Drag-&-Drop-Vorlagen ein wichtiges Element, es gibt auch ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "HubL-Tag"
      }), ", das in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), "-Vorlagen verwendet werden kann. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#add-a-flexible-column",
        children: "Erfahren Sie, wie Sie eine flexible Spalte zu einer Drag-&-Drop-Vorlage hinzufügen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Es wird allgemein empfohlen, Drag-&-Drop-Bereiche zu verwenden, da sie meistens nützlicher sind und alle Funktionen bieten, die auch flexible Spalten bieten."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Es kann in einigen Fällen dennoch nützlich sein, eine flexible Spalte zu verwenden, z. B. für eine Seitenleiste. Für Hauptinhaltsbereiche sind Drag-&-Drop-Bereiche jedoch viel flexibler."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image3-2.png",
        alt: "Design-Manager-Screenshot mit ausgewählter Hauptinhaltsgruppe"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Warum sollten Sie flexible Spalten verwenden? Websites sind in der Regel nicht starr. Sie werden über lange Zeiträume hinweg erstellt und gepflegt. Eine Homepage zum Beispiel für ein Unternehmen kann vorgestellte Produkte präsentieren und sich häufig ändern, wenn sich die Marketinganforderungen des Unternehmens im Laufe der Zeit ändern. Dass Inhalte innerhalb einer flexiblen Spalte hinzugefügt/entfernt und von Content-Editoren geändert werden können, erleichtert Marketern die Arbeit und ermöglicht es Entwicklern, sich auf das zu konzentrieren, was ihnen Spaß macht, nämlich coole Sachen zu entwickeln, anstatt kleine Seitenanpassungen vorzunehmen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ebenso können Seiten auf einer Website unterschiedliche strukturelle Anforderungen haben. Flexible Spalten geben Marketern die Kontrolle, diese Seiten mit benutzerdefinierten Modulen zu erstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 8,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Benutzerdefinierten Code zu Ihren Drag-&-Drop-Vorlagen hinzufügen"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Sie können Ihren Drag-&-Drop-Vorlagen auf mehrere Arten benutzerdefinierten Code hinzuzufügen. Bei der primären Methode erfolgt dies über benutzerdefinierte Module. Manchmal müssen Sie jedoch möglicherweise Code hinzufügen, der um Module oder Gruppen gehüllt wird. Klicken Sie dazu auf das Modul oder die Gruppe, um sie im Inspektor anzuzeigen und das umhüllende HTML-Feld zu finden. Fügen Sie dort Ihren HTML hinzu."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Manchmal müssen Sie Code zum Header Ihres HTML oder Code direkt über dem ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), " hinzufügen. Stellen Sie bei geöffneter Vorlage sicher, dass Sie nichts ausgewählt haben. Der Inspektor zeigt die Felder für die Vorlage selbst an. Hier können Sie Stylesheets und JavaScript-Dateien verlinken und gleichzeitig zusätzlichen HTML im Header oder kurz vor dem ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), " -Tag hinzufügen."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Verwenden Sie dafür den ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
            children: "Inspektor"
          }), ". Der Inspektor verfügt über Felder auf Vorlagenebene für ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template##attach-stylesheets-and-javascript-files",
            children: "Stylesheets, JavaScript"
          }), ", ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template#customize-your-template-s-head-and-body-options",
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "<head>"
            }), "-Markup"]
          }), " usw."]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://designers.hubspot.com/hubfs/image5.png",
            alt: "Inspektor-Bereich für benutzerdefinierten Code des Design-Managers"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}