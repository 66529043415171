"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 75327506237;
const slug = exports.slug = 'reference/api/other-resources/error-handling';
const title = exports.title = 'Manipulação de erros';
const name = exports.name = 'Manipulação de erros';
const metaDescription = exports.metaDescription = 'Saiba como lidar com erros comuns de API ao desenvolver com as APIs da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "erros-de-v%C3%A1rios-status",
  "label": "Erros de vários status",
  "parent": null
}, {
  "depth": 0,
  "id": "tentativas",
  "label": "Tentativas",
  "parent": null
}, {
  "depth": 1,
  "id": "webhooks",
  "label": "Webhooks",
  "parent": "tentativas"
}, {
  "depth": 1,
  "id": "a%C3%A7%C3%B5es-de-fluxo-de-trabalho-de-c%C3%B3digo-personalizado",
  "label": "Ações de fluxo de trabalho de código personalizado",
  "parent": "tentativas"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      pre: "pre",
      h2: "h2",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Manipulação de erros"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A menos que especificado de outra forma, a maioria dos pontos de extremidade do HubSpot retornará uma resposta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " OK após o sucesso. Todos os pontos de extremidade que retornaram um código de status diferente especificarão a resposta retornada na sua documentação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além disso, o HubSpot tem várias respostas de erros comuns a várias APIs:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "207 Multi-Status"
        }), ": retornado quando há status diferentes (por exemplo, erros e êxitos), que ocorre quando você habilita ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#multi-status-errors",
          children: "tratamento de erros de vários status"
        }), " para o objeto API batch criar pontos de extremidade."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "401 Unauthorized"
        }), ": retornado quando a autenticação fornecida é inválida. Veja nossa", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: " Visão geral de autenticação "
        }), "para obter detalhes sobre as solicitações de API autenticadas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "403 Forbidden"
        }), ": retornado quando a autenticação fornecida não tem as permissões adequadas para acessar a URL específica. Como exemplo, um token de OAuth que só tem acesso de conteúdo receberá um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " ao acessar a API Negócios (que exige acesso de contatos). Se você confirmou que sua chave de API ou aplicativo privado tem as permissões necessárias, entre em contato com o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "suporte da HubSpot "
        }), "para obter assistência."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "429 Too many requests"
        }), ": retornando quando a conta ou o aplicativo está acima de seus", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: " limites de taxa"
        }), " de API. Encontre sugestões em trabalhar dentro desses limites", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/working-within-the-hubspot-api-rate-limits",
          children: " aqui"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "477 Migration in Progress"
        }), ": retornado quando uma conta da HubSpot está sendo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/eu-data-centre",
          children: "migrada entre locais de hospedagem de dados"
        }), ". O HubSpot retornará o cabeçalho de resposta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Retry-After",
          children: "Retry-After"
        }), ", que indica o número de segundos após os quais a solicitação será repetida (normalmente até 24 horas)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "502/504 timeouts"
        }), ": retornado quando os limites de processamento da HubSpot foram atendidos. Esses limites estão em vigor para impedir que um único cliente cause redução de desempenho. Essas respostas de tempo limite ocorrem ao fazer um grande número de solicitações ao longo de um período prolongado. Se você receber uma dessas respostas, você deve pausar suas solicitações por alguns segundos e tentar novamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "503 service temporarily unavailable"
        }), ": retornado quando o HubSpot está temporariamente indisponível. Se você receber essa resposta, deve pausar suas solicitações por alguns segundos e tentar novamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "521 web server is down"
        }), ": retornado quando o servidor do HubSpot está inativo. Isso deve ser um problema temporário. Se você receber essa resposta, deve pausar suas solicitações por alguns segundos e tentar novamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "522 connnection timed out"
        }), ": retornado quando a conexão entre o HubSpot e o aplicativo atingiu o tempo limite. Se você recebeu essa resposta, entre em contato com o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "suporte da HubSpot "
        }), "para obter assistência."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "523 origin is unreachable"
        }), ": retornada quando o HubSpot não consegue entrar em contato com seu aplicativo. Se você receber essa resposta, deve pausar suas solicitações por alguns segundos e tentar novamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "524 timeout"
        }), ": retornado quando uma resposta não é recebida dentro de 100 segundos. Isso pode ocorrer quando o servidor do HubSpot está sobrecarregado, como com uma consulta de dados grande. Se você receber essa resposta, deve pausar suas solicitações por alguns segundos e tentar novamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "525/526 SSL issues"
        }), ": retornado quando o certificado SSL é inválido ou o aperto de mão SSL falha. Se você recebeu essa resposta, entre em contato com o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "suporte da HubSpot "
        }), "para obter assistência."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além desses erros gerais, as respostas de erro do HubSpot são projetadas para compreensíveis aos usuários. A maioria dos pontos de extremidade não retorna códigos de erro, mas uma resposta de JSON formatada com detalhes sobre o erro. Mais detalhes para erros específicos de ponto de extremidade podem ser encontrados nas páginas de documentação para o final."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": os campos no exemplo de resposta abaixo devem ser tratados como opcionais em qualquer análise de erro. Os campos específicos incluídos podem variar entre APIs diferentes, portanto qualquer análise de erro deve possibilitar que os campos específicos estejam ausentes da resposta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Structure of an example error from HubSpot\n{\n  \"status\": \"error\",\n  \"message\": \"This will be a human readable message with details about the error.\",\n  \"errors\": [\n    {\n      \"message\": \"This will be a message with additional details about the error\",\n      \"in\": \"name\"\n    }\n  ],\n  \"category\": \"VALIDATION_ERROR\",\n  \"correlationId\": \"a43683b0-5717-4ceb-80b4-104d02915d8c\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erros de vários status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "APIs de objetos'"
      }), " criar pontos de extremidade, você pode habilitar respostas com vários status que incluam falhas parciais. Isso significa que a resposta mostrará quais registros foram criados e quais não foram. Para fazer isso, inclua um valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " para cada entrada em sua solicitação. O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " pode ser qualquer string exclusiva."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, uma solicitação para criar tickets pode ser parecida com a seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request to POST crm/v3/objects/tickets/batch/create\n{\n  \"inputs\": [\n    {\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9350\",\n        \"hs_pipeline_stage\": \"1\"\n      },\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9351\",\n        \"missing\": \"1\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Na resposta, os status são agrupados para que você possa ver quais criações foram bem-sucedidas e quais falharam. Para a solicitação acima, sua resposta será semelhante a:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"id\": \"1145814089\",\n      \"properties\": {\n        \"createdate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_helpdesk_sort_timestamp\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_last_message_from_visitor\": \"false\",\n        \"hs_lastmodifieddate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_object_id\": \"1145814089\",\n        \"hs_object_source\": \"API\",\n        \"hs_object_source_label\": \"INTERNAL_PROCESSING\",\n        \"hs_pipeline\": \"0\",\n        \"hs_pipeline_stage\": \"1\",\n        \"hs_ticket_id\": \"1145814089\"\n      },\n      \"createdAt\": \"2024-08-15T17:09:13.648Z\",\n      \"updatedAt\": \"2024-08-15T17:09:13.648Z\",\n      \"archived\": false\n    }\n  ],\n  \"numErrors\": 1,\n  \"errors\": [\n    {\n      \"status\": \"error\",\n      \"category\": \"VALIDATION_ERROR\",\n      \"message\": \"Property values were not valid: [{\\\"isValid\\\":false,\\\"message\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"error\\\":\\\"PROPERTY_DOESNT_EXIST\\\",\\\"name\\\":\\\"missing\\\",\\\"localizedErrorMessage\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"portalId\\\":891936587}]\",\n      \"context\": {\n        \"objectWriteTraceId\": [\"549b1c2a9351\"]\n      }\n    }\n  ],\n  \"startedAt\": \"2024-08-15T17:09:13.610Z\",\n  \"completedAt\": \"2024-08-15T17:09:13.910Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tentativas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se o aplicativo ou a integração fornecer um ponto de extremidadeque a HubSpot chamará, como assinaturas de webhook, todos os erros que o ponto de extremidade lançar farão com que a HubSpot repita a solicitação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhooks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se, em algum momento, o serviço enfrentar problemas ao lidar com notificações, o HubSpot fará 10 tentativas de reenviar as notificações com falha."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot tentará novamente nos seguintes casos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Falha na conexão:"
        }), " se o HubSpot não conseguir estabelecer uma conexão http com a URL do webhook fornecida."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tempo limite:"
        }), " se o serviço demorar mais de 5 segundos para enviar uma resposta a um lote de notificações"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Códigos de erro:"
        }), " seu serviço responde com qualquer código de status HTTP (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4xx"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5xx"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os fluxos de trabalho** não** serão repetidos depois de receber códigos de status de resposta da série 4XX. Uma exceção a esta regra são os erros de limite de taxa 429; os fluxos de trabalho serão repetidos automaticamente depois de receber uma resposta 429 e respeitarão o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), ", se estiver presente. Observe que o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), " em milissegundos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As notificações serão repetidas até 10 vezes. Essas tentativas serão divulgadas nas próximas 24 horas, com atrasos variáveis entre solicitações. Será aplicada uma randomização às notificações individuais para evitar que um grande número de falhas simultâneas sejam repetidas exatamente mesmo horário."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ações de fluxo de trabalho de código personalizado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver criando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions",
        children: "ação de código personalizado"
      }), " em um fluxo de trabalho e a chamada de API em sua ação falhar devido a um erro de limitação de taxa ou um erro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "5XX"
      }), " do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "axios"
      }), " ou do ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/api-client"
      }), ", a HubSpot tentará novamente executar sua ação por até três dias, começando um minuto após a falha. As falhas subsequentes serão tentadas novamente em intervalos crescentes, com uma lacuna máxima de oito horas entre as tentativas."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}