"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358688;
const slug = exports.slug = 'guides/api/marketing/forms';
const title = exports.title = 'API de marketing | Formularios ';
const name = exports.name = 'vNext Docs DP - Formularios';
const metaDescription = exports.metaDescription = 'Estos puntos de terminación se utilizan para crear y administrar formularios en tu cuenta de HubSpot.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Formularios"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa un formulario de HubSpot para recopilar información de leads sobre tus visitantes y contactos. Puedes usar los puntos de terminación descritos aquí para configurar nuevos formularios u obtener detalles de formularios que has creado anteriormente. Si buscas enviar datos de envío de formularios, puedes usar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "punto de terminación Enviar datos a un formulario"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El tipo de formulario indica su propósito y está establecido en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot"
      }), " por opción predeterminada. Puedes utilizar los siguientes valores para tu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "formType"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot"
        }), ": estos formularios ofrecen una variedad de tipos de campos y opciones de estilo, y se pueden usar incrustados en páginas de HubSpot o en páginas externas. Estos formularios se pueden crear y editar usando los puntos de terminación descritos aquí. También puedes crear estos formularios dentro de tu cuenta de HubSpot, aprende más sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-forms",
          children: "cómo crear formularios de HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "captured"
        }), ": estos formularios corresponden a formularios HTML en sitios web externos. Si la herramienta de formularios externos está habilitada y hay envíos al formulario en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
          children: "una página con seguimiento"
        }), ", el formulario se crea automáticamente en HubSpot. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/use-non-hubspot-forms",
          children: "cómo usar formularios externos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "flow"
        }), ": estos son formularios emergentes que se pueden usar en páginas de HubSpot o en páginas externas. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-pop-up-forms",
          children: "la herramienta de formularios emergentes de HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_comment"
        }), ": estos formularios se crean automáticamente para las páginas del blog de HubSpot con el fin de recopilar comentarios en las publicaciones del blog. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/blog/set-up-and-moderate-your-blog-comments",
          children: "configurar y moderar más los comentarios del blog"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}