"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501958328;
const slug = exports.slug = 'guides/api/marketing/emails/transactional-emails';
const title = exports.title = 'API de marketing | E-mails transacionais ';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Transactional Emails';
const metaDescription = exports.metaDescription = 'Uma visão geral do recurso E-mail Transacional no HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "m%C3%A9todos-para-envio-de-e-mail-transacional",
  "label": "Métodos para envio de e-mail transacional",
  "parent": null
}, {
  "depth": 0,
  "id": "api-smtp",
  "label": "API SMTP",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-um-novo-token-de-api-smtp",
  "label": "Criar um novo token de API SMTP",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "recuperar-tokens-de-smtp",
  "label": "Recuperar tokens de SMTP",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "listar-tokens-de-api-smtp-por-campanha",
  "label": "Listar tokens de API SMTP por campanha",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "consultar-um-token-api-smtp-%C3%BAnico",
  "label": "Consultar um token API SMTP único",
  "parent": "api-smtp"
}, {
  "depth": 3,
  "id": "detalhes-da-resposta",
  "label": "Detalhes da resposta",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "gerenciar-tokens-existentes",
  "label": "Gerenciar tokens existentes",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "redefinir-senha",
  "label": "Redefinir senha",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "excluir-um-token",
  "label": "Excluir um token",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "fa%C3%A7a-login-no-servidor-smtp-da-hubspot",
  "label": "Faça login no servidor SMTP da HubSpot",
  "parent": "api-smtp"
}, {
  "depth": 0,
  "id": "api-de-envio-%C3%BAnico",
  "label": "API de envio único",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-e-publicar-seu-modelo-de-e-mail",
  "label": "Criar e publicar seu modelo de e-mail",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 1,
  "id": "envie-seu-e-mail-usando-a-api-de-envio-%C3%BAnico",
  "label": "Envie seu e-mail usando a API de envio único",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 1,
  "id": "solicitar-propriedades",
  "label": "Solicitar propriedades",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 2,
  "id": "emailid",
  "label": "emailId",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 2,
  "id": "message",
  "label": "message",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 2,
  "id": "contactproperties",
  "label": "contactProperties",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 2,
  "id": "customproperties",
  "label": "customProperties",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 1,
  "id": "consultar-o-status-de-um-envio-de-e-mail",
  "label": "Consultar o status de um envio de e-mail",
  "parent": "api-de-envio-%C3%BAnico"
}, {
  "depth": 2,
  "id": "sendresult",
  "label": "sendResult",
  "parent": "api-de-envio-%C3%BAnico"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      code: "code",
      h4: "h4",
      h5: "h5",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "E-mail transacional"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se tiver o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/email/transactional-email",
        children: "complemento de e-mail transacional"
      }), ", você pode enviar e-mails por um endereço IP dedicado para recibos de comércio eletrônico, atualizações de conta, alterações de termos de serviço e outras transações de negócios essenciais."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["E-mails transacionais são para interações baseadas em relacionamento, ao contrário de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/emails/marketing-emails",
        children: "e-mails de marketing"
      }), ", que normalmente são usados para promover conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Métodos para envio de e-mail transacional"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há três maneiras de implementar o e-mail transacional:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
          children: "Configurar o e-mail transacional no aplicativo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#smtp-api",
          children: "API SMTP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#single-send-api",
          children: "API de envio único"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Método"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Visão geral"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemplo de caso de uso"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "E-mail transacional no aplicativo"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Crie e-mails transacionais com o editor de e-mail do HubSpot.Isso fornece os mesmos benefícios de e-mails padrão do HubSpot, como conteúdo inteligente, personalização e modelos.Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
              children: "como configurar e-mails transacionais no aplicativo"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envie um e-mail de atualização de política aos seus clientes com um link para uma nova página de política. Essa é uma atualização de serviço, não um e-mail de marketing, portanto você não precisa incluir links de assinatura (por exemplo, links de CAN-SPAM). Você não precisa usar propriedades ou informações personalizadas de sistemas externos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "API SMTP"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Envie e-mail transacional por meio do seu próprio site ou aplicativo enquanto controla o desempenho de e-mail e crie informações de contato no HubSpot. A capacidade opcional de criar informações de contato é baseada na criação de token de smtp.Saiba mais na seção ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#smtp-api",
              children: "API SMTP abaixo"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envie um e-mail de confirmação de assinatura de conta de um sistema de e-mail transacional separado, enquanto controla o desempenho de e-mail e criando contatos no HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "API de envio único"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma combinação de e-mail transacional no aplicativo e API SMTP.Crie e-mails transacionais usando o editor de e-mail do HubSpot e adicione tokens externos personalizados ao seu e-mail que você pode enviar à HubSpot por meio da API.Saiba mais em ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#single-send",
              children: "API de envio único abaixo"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envie um e-mail de recibo de compra ao seu cliente usando o HubSpot. O e-mail é disparado quando a compra é feita e passa valores personalizados de outro sistema (por exemplo, um item comprado e total de compra). Além disso, acompanhe o desempenho desse e-mail no HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " todos os contatos com CC em um e-mail transacional não serão monitorados e o e-mail não aparecerá na linha do tempo do registo de contato com CC."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O e-mail transacional enviado com a API SMTP é acionado automaticamente por critérios específicos, como fazer uma compra em um site de comércio eletrônico. Esta API integra-se com sistemas internos ou de terceiros para disparar o email e incorporar dados armazenados fora do HubSpot (por exemplo, informações de envio ou preço de compra). O e-mail é enviado do seu sistema, mas está conectado a códigos de rastreamento do HubSpot que permitem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
        children: "rastreamento de engajamento e medições"
      }), " completos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar um e-mail usando a API SMTP, você precisa usar um token de API SMTP para obter credenciais de login para o servidor SMTP da HubSpot. Depois de fazer login no servidor, você pode enviar o e-mail pelo SMTP. Se você não criou tokens de API SMTP, precisará ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-a-new-smtp-api-token",
        children: "gerar um novo token"
      }), " antes. Se já tiver criado tokens de API SMTP, veja os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-existing-smtp-api-tokens",
        children: "diferentes métodos para obter seus tokens pela API"
      }), ". Depois de obter o token, saiba sobre como fazer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "login no servidor SMTP da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os domínios que você usa como o ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endereço de remetente"
      }), " dos seus e-mails precisam estar conectados como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/domains-and-urls/connect-your-email-sending-domain",
        children: "domínio de envio de e-mail"
      }), " no HubSpot. Você encontrará um erro se enviar e-mails transacionais pela API SMTP usando um domínio não autorizado a enviar em nome da sua conta da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": todos os métodos na API SMTP exigem um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "token de OAuth"
        }), " para autenticação."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Se preferir, todos os métodos abaixo para criar, recuperar, excluir e redefinir senhas para tokens da API SMTP podem ser feitos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "em sua conta da HubSpot"
        }), ", em vez de na API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar um novo token de API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um novo token de API SMTP, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O corpo da solicitação precisa ser um objeto formatado em JSON com as seguintes propriedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica se um contato deve ser criado para destinatários de e-mail."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": um nome para a campanha associada ao token de API SMTP."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A resposta inclui um objeto de token SMTP, que contém:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": nome de usuário para fazer login no servidor SMTP da HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": endereço de e-mail do usuário que enviou a solicitação de criação do token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "password"
        }), ": a senha para fazer login no servidor SMTP da HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": identificador atribuído à campanha fornecida na solicitação de criação de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": data/hora gerada quando um token é criado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica se um contato deve ser criado para destinatários de e-mail."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": o nome da campanha associado ao token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com o token criado, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "fazer login no servidor SMTP da HubSpot"
      }), " usando os valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "senha"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A senha de um token só pode ser recuperada no momento da criação. Se você perder a senha ou quiser definir uma nova, será necessário ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manage-existing-tokens",
        children: "redefinir a senha do token"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os tokens da API SMTP gerados por meio da API pública expiram após 12 meses. Quando expirarem, eles serão excluídos automaticamente. Os tokens criados diretamente ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "na sua conta da HubSpot"
        }), " ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " expiram automaticamente."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Recuperar tokens de SMTP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo os métodos disponíveis para obter dados de token usando a API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Listar tokens de API SMTP por campanha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter uma lista de tokens por nome de campanha, ou um token único por ID da campanha, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também precisará incluir um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignName"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailCampaignId"
      }), " com a solicitação. Você pode encontrar todos os detalhes da solicitação na guia Endpoints no canto superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Detalhes da resposta"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A resposta contém ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging"
      }), " como campos de nível superior:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "results"
        }), ": uma coleção de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "SmtpApiTokenView"
        }), " contendo:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), ": nome de usuário para fazer login no servidor SMTP da HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            }), ": endereço de e-mail do usuário que enviou a solicitação de criação do token."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "emailCampaignId"
            }), ": identificador atribuído à campanha fornecida na solicitação de criação de token."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            }), ": data/hora gerada quando um token é criado."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createContact"
            }), ": indica se um contato deve ser criado para destinatários de e-mail."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "campaignName"
            }), ": o nome da campanha associado ao token."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "paging"
        }), ": contém um campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "next.after"
        }), " que pode ser usado para solicitar mais resultados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Consultar um token API SMTP único"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para consultar um token API SMTP único por ID, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Detalhes da resposta"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A resposta inclui ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", que contém:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": nome de usuário para fazer login no servidor SMTP da HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": endereço de e-mail do usuário que enviou a solicitação de criação do token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": identificador atribuído à campanha fornecida na solicitação de criação de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": data/hora gerada quando um token é criado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica se um contato deve ser criado para destinatários de e-mail."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": o nome da campanha associado ao token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gerenciar tokens existentes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de criar tokens, você pode redefinir uma senha ou excluir o token usando a API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Redefinir senha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar uma senha de token, faça uma solicitação POST para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}/password-reset"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A resposta inclui ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", que contém:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": nome de usuário para fazer login no servidor SMTP da HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), ": endereço de e-mail do usuário que enviou a solicitação de criação do token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), ": identificador atribuído à campanha fornecida na solicitação de criação de token."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), ": data/hora gerada quando um token é criado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), ": indica se um contato deve ser criado para destinatários de e-mail."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), ": o nome da campanha associado ao token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Excluir um token"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir um token API SMTP único, faça uma solicitação DELETE para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A resposta não inclui nenhum conteúdo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Faça login no servidor SMTP da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo os detalhes para fazer login no servidor SMTP da HubSpot, usando o nome de usuário (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") e a senha fornecida pelo seu token."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hostname SMTP:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se você não estiver com base na UE, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp.hubapi.com"
            }), " como nome do host."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se você estiver na UE, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp-eu1.hubapi.com"
            }), " como nome do host."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Porta SMTP:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Para STARTTLS, você pode usar a porta 25 ou 587."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Para TLS direto, use a porta 465."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nome do usuário SMTP:"
        }), " fornecido no campo ID"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Senha SMTP:"
        }), " fornecida no campo de senha"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API de envio único"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A API de envio único envia e-mails de modelo criados na ferramenta de e-mail do HubSpot usando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " formatada em JSON. Todos os e-mails enviados por essa API serão associados automaticamente aos registros de contatos com base no endereço de e-mail. Se não houver contato com um endereço de e-mail correspondente, um novo contato com esse e-mail será criado. Se quiser enviar e-mails sem criar contatos, use a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "API SMTP"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar e publicar seu modelo de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A API de envio único envia e-mails de modelo criados na ferramenta de e-mail do HubSpot usando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " formatada em JSON. Todos os e-mails enviados por essa API serão associados automaticamente aos registros de contatos com base no endereço de e-mail. Se não houver contato com um endereço de e-mail correspondente, um novo contato com esse e-mail será criado. Se quiser enviar e-mails sem criar contatos, use a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "API SMTP"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Primeiro, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
        children: "configure seu e-mail no HubSpot"
      }), ". Depois de criar o e-mail, você poderá definir os detalhes do destinatário, incluindo qualquer contato ou propriedades personalizadas configuradas no modelo de e-mail, no corpo da solicitação de API. Para fazer a solicitação da API, você precisará do ID do e-mail:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você deixar o e-mail em rascunho sem publicá-lo, poderá obter o ID do e-mail no URL quando estiver no editor de e-mail. O ID é o valor numérico que precede o caractere de barra final (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), ") no URL (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/email/{PORTAL_ID}/edit/{EMAIL_ID}/settings"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/email-id-for-drafted-single-send-api-email.png",
        alt: "email-id-for-drafted-single-send-api-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se publicar o e-mail, você poderá copiar o ID do e-mail a partir da página de detalhes do e-mail."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-15%20at%201.00.37%20PM.png",
        alt: "Captura de tela 15-04-2020 às 1.00.37 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o HubSpot não salva HTML/JSON enviado por meio desta API. Você pode revisar o modelo de e-mail a partir da linha do tempo do contato do destinatário, mas se quiser manter um registro do conteúdo do e-mail, recomendamos adicionar um Cco ao e-mail."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Envie seu e-mail usando a API de envio único"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar um email com a API de envio único, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/single-email/send"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A resposta contém os seguintes campo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": a data/hora da solicitação de envio."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": um identificador que pode ser usado para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-the-status-of-an-email-send",
            children: "consulta o status do envio"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": o status da solicitação de envio. Inclui ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDENTE"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSANDO"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELADO"
          }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CONCLUÍDO"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Solicitar propriedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O corpo da solicitação precisa ser um objeto formatado em JSON com as seguintes propriedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "message"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contactProperties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "emailId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailId"
      }), " contém o ID de conteúdo do e-mail transacional, que pode ser encontrado na ferramenta de e-mail do HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "message"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O campo de mensagem é um objeto JSON contendo tudo que você deseja substituir. No mínimo, você deve incluir o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "to"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Campos de objeto de mensagem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "to"
        }), ": o destinatário do email"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "from"
        }), ": do cabeçalho \"De\" do email. Você pode definir um nome com o seguinte formato: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"from\":\"Sender Name <sender@hubspot.com>\""
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), ": o ID de um envio específico. Apenas um e-mail com um determinado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), " será enviado por conta, então você pode incluir esse campo para evitar envios de e-mail duplicados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "replyTo"
        }), ": uma lista JSON de valores de cabeçalho \"Responder para\" para o email."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "cc"
        }), ": uma lista JSON de endereços de email a enviar como Cc."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "bcc"
        }), ": uma lista JSON de endereços de e-mail para enviar como Cco."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "contactProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactProperties"
      }), " é um mapa JSON dos valores de propriedade de contato. Cada valor de propriedade de contato contém um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "nome"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "valor"
      }), ". Cada propriedade será definida no registro do contato e será visível no modelo em:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hs-fs/hubfs/Knowledge_Base_Images/Contacts/Forms/form-field-options-1.png?width=550&name=form-field-options-1.png",
        alt: ""
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/name-token-in-template-for-transactional-email.png",
        alt: "name-token-in-template-for-transactional-email"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use essas propriedades quando quiser definir uma propriedade de contato enquanto estiver enviando o e-mail. Por exemplo, ao enviar um recibo, que você pode definir uma propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "last_paid_date"
      }), ", pois o envio do recibo terá informações sobre o último pagamento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"contactProperties\": {\n    \"last_paid_date\": \"2022-03-01\",\n    \"firstname\": \"jane\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "customProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " é um mapa JSON das propriedades do valor-chave. Essas propriedades geralmente estão relacionadas ao próprio e-mail, não ao contato que recebe o e-mail. Elas não aparecerão na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/create-a-web-version-of-your-marketing-email",
        children: "versão da página da Web do e-mail"
      }), " ou na exibição do e-mail na linha do tempo do contato. Essas propriedades também não são armazenadas no HubSpot e serão incluídas apenas no e-mail enviado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada chave no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " pode ser referenciada no modelo usando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "expressão HubL"
      }), " para campos contidos na variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "personalizada"
      }), " (por exemplo,", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ custom.NAME_OF_PROPERTY }}"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, se o modelo de e-mail fizer referência a duas propriedades, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "purchaseUrl"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "productName"
      }), ", você poderá fornecer os valores associados a elas com o seguinte corpo de solicitação:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"customProperties\": {\n    \"purchaseUrl\": \"https://example.com/link-to-product\",\n    \"productName\": \"vanilla\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode fazer referência a essas propriedades no modelo de e-mail:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Congrats on purchasing some of the best ice cream around.\n  </p>\n  <a href={{custom.purchaseUrl}}>{{custom.productName}}</a>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " só permite matrizes quando usado com um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "conteúdo de e-mail programável"
      }), ". No seu modelo de e-mail, você pode fazer referência aos itens definidos em seu campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " usando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "expressão HubL"
      }), " (por exemplo, usando um loop ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for"
      }), " para renderizar cada item em uma lista). Por exemplo, se o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " que você incluiu no corpo da solicitação foi estruturado como o seguinte trecho JSON abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643122,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"7\"\n  },\n  \"customProperties\": {\n    \"exampleArray\": [\n      {\"firstKey\": \"someValue\", \"secondKey\": \"anotherValue\"},\n      {\"firstKey\": \"value1\", \"secondKey\": \"value2\" }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em seguida, você pode fazer referência aos valores de cada item em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exampleArray"
      }), " com o código HubL a seguir:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Thanks for your recent purchase! Here are the details of the items you'll be receiving:\n  </p>\n  <ul>\n    {% for item in custom.exampleArray %}\n      <li>First key: {{ item.firstKey }}, Second key: {{item.secondKey}}</li>\n    {% endfor %}\n  </ul>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma vez enviado, o e-mail transacional tornaria o conteúdo do modelo de e-mail programável associado da seguinte forma:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hs-fs/hubfs/Knowledge_Base_Images/Contacts/Forms/form-field-options-1.png?width=550&name=form-field-options-1.png",
        alt: ""
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-transactional-email-with-customProperties-array.png",
        alt: "example-transactional-email-with-customProperties-array"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Consultar o status de um envio de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter o status do envio de e-mail, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/marketing/v3/email/send-statuses/{statusId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A resposta contém os seguintes campo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "sendResult"
          }), ": uma enumeração que representa o status de envio do e-mail. Os valores possíveis estão ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#sendresult-values",
            children: "listados abaixo"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": a data/hora da solicitação do envio."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "startedAt"
          }), ": a data/hora de início do processamento do envio."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "completedAt"
          }), ": a data/hora de conclusão do envio."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": um identificador que pode ser usado para consulta o status do envio."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": o status da solicitação de envio. Inclui ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDENTE"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSANDO"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELADO"
          }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CONCLUÍDO"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventId"
          }), ": se enviado, o ID e data/hora de criação do evento de envio."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendResult"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResult"
      }), " é uma enumeração que reflete o resultado de uma tentativa de envio de e-mail. Os valores possíveis são:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SENT"
        }), ": o e-mail foi enviado com sucesso."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "QUEUED"
        }), ": o e-mail foi colocado na fila e será enviado à medida que a fila é processada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PORTAL_SUSPENDED"
        }), ": devido a violações da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/acceptable-use",
          children: "Política de Utilização Responsável"
        }), ", o email do cliente do HubSpot foi suspenso."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_TO_ADDRESS"
        }), ": o endereço do destinatário é inválido. Esse erro também ocorrerá se você tentar enviar um e-mail com um dos seguintes prefixos baseados em função no endereço de e-mail: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "abuse"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "no-reply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noreply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "root"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "spam"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "security"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undisclosed-recipients"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "unsubscribe"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inoc"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "postmaster"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "privacy"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOCKED_DOMAIN"
        }), ": o domínio não pode receber e-mails do HubSpot neste momento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUSLY_BOUNCED"
        }), ": o destinatário foi rejeitado anteriormente e a lógica de envio resultou em nenhum envio."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUS_SPAM"
        }), ": o destinatário marcou um email semelhante como spam."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_FROM_ADDRESS"
        }), ": o endereço de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Remetente"
        }), " é inválido."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_CONTENT"
        }), ": o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " é inválido, ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " corresponde a um email que não foi configurado para envio único."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_TEMPLATE_PROPERTIES"
        }), ": há propriedades configuradas no modelo que não foram incluídas no ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        }), " enviado na solicitação."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}