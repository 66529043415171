"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044589;
const slug = exports.slug = 'guides/cms/tools/personal-access-key';
const title = exports.title = 'Clave de acceso personal de CMS';
const name = exports.name = 'EMEA ES Personal CMS Access Keys';
const metaDescription = exports.metaDescription = 'Las claves de acceso personal de CMS son una forma de autenticación destinada a ser utilizada por los desarrolladores que construyen en HubSpot. Están vinculados a un usuario específico en una cuenta.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      code: "code",
      img: "img",
      a: "a",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Clave de acceso personal"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Las claves de acceso personal son la forma recomendada de autenticarse con herramientas de desarrollo local."
      }), " Las claves de acceso personal funcionan de forma similar a las claves de API, pero están vinculadas a un usuario específico en una cuenta. Las claves de acceso personal solo funcionan con herramientas de desarrollo local."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Claves de acceso personal en comparación con otros métodos de autenticación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La ventaja de las claves de acceso personal sobre las implementaciones como las claves de API es que las claves de API tienen efectivamente permisos de superadministrador. Las claves de acceso personal se limitan a los permisos que tiene el usuario individual en el portal. Si el usuario tiene Super Admin, no ven ninguna diferencia en su funcionalidad, pero la ventaja es que si un desarrollador individual necesita ser eliminado de una cuenta, el acto de deshabilitar a su usuario en la cuenta deshabilitará sus capacidades de desarrollo local."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Debido a que las claves de acceso personal están vinculadas al usuario individual en una cuenta, podemos mostrar información más útil, por ejemplo, si un desarrollador cambia o carga un archivo usando las herramientas de desarrollo local mientras usa una clave de acceso personal, podemos atribuir el cambio en la aplicación a ese usuario. Esto hace que sea más fácil trabajar con equipos y entender quién hizo qué."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las claves de acceso personal están vinculadas al usuario individual en la cuenta específica de HubSpot y no al usuario directamente. Lo que esto significa es que usando las herramientas de desarrollo local necesitarás generar una nueva clave de acceso personal para cada cuenta con la que desees usar las herramientas de desarrollo. Esto proporciona una capa de seguridad para las cuentas, ya que un actor malicioso que obtiene tu clave de acceso solo podría afectar a los portales individuales y a ese usuario individual."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Parecido a OAuth2"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Entre bastidores, las claves de acceso personal en realidad actúan como OAuth2. Cuando generas una clave de acceso personal, eliges los permisos que deseas que esta clave tenga. Solo puedes tener 1 clave de acceso por usuario por cuenta de HubSpot. Una vez que hayas generado tu clave de acceso, se conectará una aplicación a tu cuenta de HubSpot llamada \"Herramientas de desarrollo local de HubSpot\". Esta aplicación HubSpot de origen facilita la autenticación de las herramientas de desarrollo local cuando se utiliza una clave de acceso personal. Si desconectas esta aplicación, se eliminará cualquier clave de acceso que hayas generado anteriormente, lo que hará que tus herramientas de desarrollo local ya no puedan conectarse a través de esas claves de acceso. Deberás generar una nueva clave y actualizar tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/personalcmsaccesskey.png",
            alt: "personalcmsaccesskey"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Proteger tus credenciales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Protege tus claves de acceso personal como si fueran la contraseña de tu cuenta, no las compartas con nadie. Permiten a quienquiera que las tenga autenticarse como si fueras tú y tomar cualquier acción que tú personalmente puedas tomar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Uso de claves de acceso personal con las herramientas de desarrollo local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las claves de acceso personal se construyeron para ser utilizadas con herramientas de desarrollo local."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Comenzar con las herramientas de desarrollo local"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://app.hubspot.com/l/personal-access-key",
        children: "Ve tu clave de acceso CMS personal."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se usa para auth en las herramientas de desarrollo local, tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " se asemejará a esto:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: production\nportals:\n  - name: production\n    portalId: <portalId>\n    authType: personalaccesskey\n    personalAccessKey: >-\n      CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}