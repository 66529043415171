"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 75327506237;
const slug = exports.slug = 'reference/api/other-resources/error-handling';
const title = exports.title = 'Fehlerbehandlung';
const name = exports.name = 'Fehlerbehandlung';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie häufige API-Fehler bei der Entwicklung mit HubSpot-APIs behandeln.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "multi-status-fehler",
  "label": "Multi-Status-Fehler",
  "parent": null
}, {
  "depth": 0,
  "id": "erneute-versuche",
  "label": "Erneute Versuche",
  "parent": null
}, {
  "depth": 1,
  "id": "webhooks",
  "label": "Webhooks",
  "parent": "erneute-versuche"
}, {
  "depth": 1,
  "id": "workflow-aktionen-mit-benutzerdefiniertem-code",
  "label": "Workflow-Aktionen mit benutzerdefiniertem Code",
  "parent": "erneute-versuche"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      pre: "pre",
      h2: "h2",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Behandeln von Fehlern"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sofern nicht anders angegeben, geben die meisten HubSpot-Endpunkte eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " OK-Antwort bei einem Erfolg zurück. Bei allen Endpunkten, die einen anderen Statuscode zurückgeben, wird die zurückgegebene Antwort in ihrer Dokumentation erläutert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Darüber hinaus hat HubSpot mehrere Fehlerantworten, die häufig bei mehreren APIs auftreten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "207 Multi-Status"
        }), ": wird zurückgegeben, wenn es unterschiedliche Status gibt (z. B. Fehler und Erfolge), was der Fall ist, wenn Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#multi-status-errors",
          children: "Behandlung von Multi-Status-Fehlern"
        }), " für die Batch-Erstellungsendpunkte der Objekt-API aktiviert haben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "401 Unauthorized"
        }), ": wird zurückgegeben, wenn die angegebene Authentifizierung ungültig ist. Weitere Informationen zur Authentifizierung von API-Anfragen finden Sie in unserer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "Übersicht über die Authentifizierung"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "403 Forbidden"
        }), ": wird zurückgegeben, wenn die Authentifizierung nicht die erforderlichen Berechtigungen hat, um auf die spezifische URL zuzugreifen. Ein OAuth-Token, das nur Content-Zugriff hat, würde eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), "-Meldung erhalten, wenn es auf die Deals-API zugreift (die Zugriff auf Kontakte erfordert). Wenn Sie bestätigt haben, dass Ihr API-Schlüssel oder Ihre private App über die erforderlichen Berechtigungen verfügt, wenden Sie sich bitte an den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpot-Support"
        }), ", um Hilfe zu erhalten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "429 Too many requests"
        }), ": wird zurückgegeben, wenn Ihr Account oder Ihre App über den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "API-Ratenbeschränkungen"
        }), " liegt. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/working-within-the-hubspot-api-rate-limits",
          children: "Hier"
        }), " finden Sie Vorschläge, wir Sie mit diesen Limits umgehen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "477 Migration in Progress"
        }), ": wird zurückgegeben, wenn ein HubSpot-Account gerade ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/eu-data-centre",
          children: "zwischen Datenhosting-Standorten migriert wird"
        }), ". HubSpot gibt einen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Retry-After",
          children: "Retry-After"
        }), "-Antwort-Header zurück, der angibt, wie viele Sekunden gewartet werden muss, bevor die Anfrage erneut versucht wird (in der Regel bis zu 24 Stunden)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "502/504 timeouts"
        }), ": wird zurückgegeben, wenn die Verarbeitungslimits von HubSpot eingehalten wurden. Diese Limits sollen verhindern, dass ein einzelner Client Leistungseinbußen verursacht. Diese Timeout-Antworten treten auf, wenn Sie eine große Anzahl von Anfragen über einen längeren Zeitraum vornehmen. Wenn Sie eine dieser Antworten erhalten, sollten Sie Ihre Anfragen für einige Sekunden pausieren und es dann erneut versuchen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "503 service temporarily unavailable"
        }), ": wird zurückgegeben, wenn HubSpot vorübergehend nicht verfügbar ist. Wenn Sie diese Antwort erhalten, sollten Sie Ihre Anfragen für einige Sekunden pausieren und es dann erneut versuchen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "521 web server is down"
        }), ": wird zurückgegeben, wenn der Server von HubSpot ausgefallen ist, sollte dies ein temporäres Problem sein. Wenn Sie diese Antwort erhalten, sollten Sie Ihre Anfragen für einige Sekunden pausieren und es dann erneut versuchen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "522 connnection timed out"
        }), ": wird zurückgegeben, wenn die Verbindung zwischen HubSpot und Ihrer Anwendung abgelaufen ist. Wenn Sie diese Antwort erhalten haben, wenden Sie sich bitte an den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpot-Support"
        }), ", um Hilfe zu erhalten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "523 origin is unreachable"
        }), ": wird zurückgegeben, wenn HubSpot Ihre Anwendung nicht erreichen kann. Wenn Sie diese Antwort erhalten, sollten Sie Ihre Anfragen für einige Sekunden pausieren und es dann erneut versuchen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "524 timeout"
        }), ": wird zurückgegeben, wenn eine Antwort nicht innerhalb von 100 Sekunden empfangen wird. Dies kann vorkommen, wenn der HubSpot-Server überlastet ist, z. B. bei einer großen Datenabfrage. Wenn Sie diese Antwort erhalten, sollten Sie Ihre Anfragen für einige Sekunden pausieren und es dann erneut versuchen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "525/526 SSL issues"
        }), ": wird zurückgegeben, wenn das SSL-Zertifikat ungültig ist oder der SSL-Handshake fehlschlägt. Wenn Sie diese Antwort erhalten haben, wenden Sie sich bitte an den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpot-Support"
        }), ", um Hilfe zu erhalten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neben diesen allgemeinen Fehlern sind die Antworten auf HubSpot-Fehler als menschenlesbare Inhalte gedacht. Die meisten Endpunkte geben keine Fehlercodes, sondern eine JSON-formatierte Antwort mit Details zum Fehler zurück. Weitere Details zu endpunktspezifischen Fehlern finden Sie auf den Dokumentationsseiten für den Endpunkt."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Die folgenden Felder in der Beispielantwort sollten bei jeder Fehleranalyse als optional behandelt werden. Die spezifischen enthaltenen Felder können zwischen verschiedenen APIs variieren, so dass bei der Fehleranalyse berücksichtigt werden sollte, dass bestimmte Felder in der Antwort fehlen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Structure of an example error from HubSpot\n{\n  \"status\": \"error\",\n  \"message\": \"This will be a human readable message with details about the error.\",\n  \"errors\": [\n    {\n      \"message\": \"This will be a message with additional details about the error\",\n      \"in\": \"name\"\n    }\n  ],\n  \"category\": \"VALIDATION_ERROR\",\n  \"correlationId\": \"a43683b0-5717-4ceb-80b4-104d02915d8c\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multi-Status-Fehler"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für die Batch-Erstellungsendpunkte der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Objekt-APIs'"
      }), " können Sie Multi-Status-Antworten aktivieren, die ein teilweises Fehlschlagen enthalten. Das bedeutet, dass in der Antwort angezeigt wird, welche Datensätze erstellt wurden und welche nicht. Fügen Sie dazu für jede Eingabe einen eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), "-Wert in Ihre Anfrage ein. Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " kann eine eindeutige Zeichenfolge sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine Anfrage zum Erstellen von Tickets könnte beispielsweise so aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request to POST crm/v3/objects/tickets/batch/create\n{\n  \"inputs\": [\n    {\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9350\",\n        \"hs_pipeline_stage\": \"1\"\n      },\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9351\",\n        \"missing\": \"1\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In der Antwort sind die Status gruppiert, damit Sie sehen können, welche Erstellvorgänge erfolgreich waren und welche fehlgeschlagen sind. Für die obige Anfrage würde Ihre Antwort wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"id\": \"1145814089\",\n      \"properties\": {\n        \"createdate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_helpdesk_sort_timestamp\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_last_message_from_visitor\": \"false\",\n        \"hs_lastmodifieddate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_object_id\": \"1145814089\",\n        \"hs_object_source\": \"API\",\n        \"hs_object_source_label\": \"INTERNAL_PROCESSING\",\n        \"hs_pipeline\": \"0\",\n        \"hs_pipeline_stage\": \"1\",\n        \"hs_ticket_id\": \"1145814089\"\n      },\n      \"createdAt\": \"2024-08-15T17:09:13.648Z\",\n      \"updatedAt\": \"2024-08-15T17:09:13.648Z\",\n      \"archived\": false\n    }\n  ],\n  \"numErrors\": 1,\n  \"errors\": [\n    {\n      \"status\": \"error\",\n      \"category\": \"VALIDATION_ERROR\",\n      \"message\": \"Property values were not valid: [{\\\"isValid\\\":false,\\\"message\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"error\\\":\\\"PROPERTY_DOESNT_EXIST\\\",\\\"name\\\":\\\"missing\\\",\\\"localizedErrorMessage\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"portalId\\\":891936587}]\",\n      \"context\": {\n        \"objectWriteTraceId\": [\"549b1c2a9351\"]\n      }\n    }\n  ],\n  \"startedAt\": \"2024-08-15T17:09:13.610Z\",\n  \"completedAt\": \"2024-08-15T17:09:13.910Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erneute Versuche"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihre App oder Ihre Integration einen Endpunkt bereitstellt, den HubSpot dann aufruft, z. B. Webhook-Abonnements, führen alle Fehler, die Ihr Endpunkt verursacht, dazu, dass HubSpot die Anfrage erneut versucht."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhooks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn bei Ihrem Service Probleme bei der Datenverarbeitung zu irgendeinem Zeitpunkt auftreten, versucht HubSpot, diese Benachrichtigungen bis zu 10 Mal erneut zu senden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot versucht es in den folgenden Fällen erneut:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verbindung fehlgeschlagen:"
        }), " HubSpot kann eine http-Verbindung mit der angegebenen Webhook-URL nicht öffnen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Timeout:"
        }), " Ihr Service benötigt länger als 5 Sekunden, um eine Antwort zurück an einen Batch an Benachrichtigungen zu senden"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fehlercodes:"
        }), " Ihr Service antwortet mit einem beliebigen HTTP-Statuscode (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4xx"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5xx"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Workflows werden nach Erhalt der Antwortstatuscodes der 4xx-Serie ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "nicht"
      }), " erneut versucht. Eine Ausnahme von dieser Regel sind 429-Ratenbeschränkungsfehler. Workflows werden nach Erhalt einer 429-Antwort automatisch wiederholt und respektieren den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), "-Header, falls vorhanden. Beachten Sie, dass der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), "-Wert in Millisekunden angegeben wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Benachrichtigungen werden bis zu 10 Mal erneut versucht. Diese erneuten Versuche werden mit wechselnden Verzögerungen zwischen den Anfragen über die nächsten 24 Stunden verteilt. Bei einzelnen Benachrichtigungen wird etwas Randomisierung angewendet, um zu verhindern, dass eine große Anzahl erneuter Versuche zum exakt gleichen Zeitpunkt gleichzeitig fehlschlägt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Workflow-Aktionen mit benutzerdefiniertem Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions",
        children: "benutzerdefinierte Code-Aktion"
      }), " in einem Workflow erstellen und ein API-Aufruf in Ihrer Aktion aufgrund eines Ratenbegrenzungsfehlers oder eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "5XX"
      }), "-Fehlers von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "axios"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/api-client"
      }), " fehlschlägt, versucht HubSpot eine Minute nach dem Fehler bis zu drei Tage lang erneut, Ihre Aktion auszuführen. Nachfolgende fehlgeschlagene Webhooks werden in größeren Intervallen mit einer maximalen Lücke von acht Stunden versucht, erneut auszuführen."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}