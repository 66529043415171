"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921797;
const slug = exports.slug = 'guides/api/crm/extensions/timeline';
const title = exports.title = 'CRM-API | Chronik-Events';
const name = exports.name = 'CRM | Chronik-Events';
const metaDescription = exports.metaDescription = 'Überblick über und Erläuterung der Chronik-API.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-event-vorlage-erstellen",
  "label": "Eine Event-Vorlage erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "event-vorlagen-%C3%BCber-die-api-erstellen",
  "label": "Event-Vorlagen über die API erstellen",
  "parent": "eine-event-vorlage-erstellen"
}, {
  "depth": 1,
  "id": "event-vorlagen-in-hubspot-erstellen",
  "label": "Event-Vorlagen in HubSpot erstellen",
  "parent": "eine-event-vorlage-erstellen"
}, {
  "depth": 1,
  "id": "event-token-definieren",
  "label": "Event-Token definieren",
  "parent": "eine-event-vorlage-erstellen"
}, {
  "depth": 2,
  "id": "event-token-%C3%BCber-die-api-erstellen",
  "label": "Event-Token über die API erstellen",
  "parent": "eine-event-vorlage-erstellen"
}, {
  "depth": 1,
  "id": "header--und-detail-vorlagen-definieren",
  "label": "Header- und Detail-Vorlagen definieren",
  "parent": "eine-event-vorlage-erstellen"
}, {
  "depth": 2,
  "id": "header--und-detail-vorlagen-%C3%BCber-die-api-definieren",
  "label": "Header- und Detail-Vorlagen über die API definieren",
  "parent": "eine-event-vorlage-erstellen"
}, {
  "depth": 1,
  "id": "alle-aspekte-einer-event-vorlage-in-einem-einzigen-aufruf-definieren",
  "label": "Alle Aspekte einer Event-Vorlage in einem einzigen Aufruf definieren",
  "parent": "eine-event-vorlage-erstellen"
}, {
  "depth": 0,
  "id": "ein-event-erstellen",
  "label": "Ein Event erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "den-events-zeitstempel-festlegen",
  "label": "Den Events-Zeitstempel festlegen",
  "parent": "ein-event-erstellen"
}, {
  "depth": 1,
  "id": "ein-event-zu-einem-crm-objekt-zuordnen",
  "label": "Ein Event zu einem CRM-Objekt zuordnen",
  "parent": "ein-event-erstellen"
}, {
  "depth": 1,
  "id": "chronikerweiterungen",
  "label": "Chronikerweiterungen",
  "parent": "ein-event-erstellen"
}, {
  "depth": 1,
  "id": "crm-objekteigenschaften-mit-event-daten-aktualisieren-stempeln",
  "label": "CRM-Objekteigenschaften mit Event-Daten aktualisieren (stempeln)",
  "parent": "ein-event-erstellen"
}, {
  "depth": 1,
  "id": "extradata",
  "label": "extraData",
  "parent": "ein-event-erstellen"
}, {
  "depth": 1,
  "id": "ein-benutzerdefiniertes-symbol-einrichten",
  "label": "Ein benutzerdefiniertes Symbol einrichten",
  "parent": "ein-event-erstellen"
}, {
  "depth": 2,
  "id": "verwandte-dokumente",
  "label": "Verwandte Dokumente",
  "parent": "ein-event-erstellen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      em: "em",
      strong: "strong",
      h4: "h4",
      ul: "ul",
      li: "li",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Chronik-Events"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die CRM-Erweiterungen ermöglichen es, dass Informationen aus anderen Systemen in HubSpot-Kontakt-, Unternehmens- oder Deal-Objekten angezeigt werden können. Die Chronik-Events-Endpunkte ermöglichen es Ihnen, benutzerdefinierte Chronik-Events zu erstellen. Wenn Sie möchten, dass Ihre Daten von Benutzern bearbeitet werden können, jedoch keines der Standard-CRM-Objekte Ihren Anforderungen entspricht, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "benutzerdefinierte Objekte"
      }), " in Betracht ziehen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_api/event_expanded-2.png",
        alt: "event_expanded-2"
      }), "Sie möchten z. B. Ihre Kontakte besser anhand ihrer Interaktionen mit Ihrem Unternehmen und Inhalt segmentieren. Dazu benötigen Sie mehr Informationen über sie. Ihre App kann benutzerdefinierte Events erstellen (Kontakte, die sich registriert, jedoch nicht an einem jüngsten Webinar teilgenommen haben; welche Variante eines Registrierungsprozesses einen Kontakt abgeschlossen hat, usw.), die Ihnen mehr Kontext über die Interaktionen von Kontakten mit Ihrem Unternehmen liefern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Event-Vorlage erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie Events erstellen können, müssen Sie eine Event-Vorlage erstellen. Event-Vorlagen beschreiben Aktionen, die Ihre App zur Chronik eines Kontakt-, Unternehmens- oder Deal-Objekts in HubSpot hinzufügt. Beispiele für diese Aktionen sind unter anderem das Aufrufen eines Videos, die Registrierung für ein Webinar oder das Ausfüllen einer Umfrage. Eine einzelne App kann bis zu 750 Event-Vorlagen erstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Events werden standardmäßig für Kontakte erstellt, sie können jedoch mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), "-Feldes auch für Unternehmen oder Deals erstellt werden. Im Abschnitt zur Erstellung einer Vorlage für Chronik-Events finden Sie weitere Informationen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jede Event-Vorlage verfügt über einen eigenen Satz an Token und Vorlagen. Sie können Events für Kontakte als Kriterien beim Erstellen neuer Kontaktlisten oder Workflows verwenden, z. B.: „Erstellen einer Liste aller Kontakte mit einem Video-Like, bei der der Videoname XYZ enthält“, wobei die Event-Vorlage „Video-Like“ heißt und über ein Event-Token mit dem Namen „Videoname“ verfügt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Event-Vorlagen über die API erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Beispiel erstellen wir eine neue Event-Vorlage vom Typ „Beispiel-Webinar-Registrierung“. Verwenden Sie zur Authentifizierung den Entwickler-API-Schlüssel in Ihrem App-Entwickler-Account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST\n-H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Example Webinar Registration\",\n  \"objectType\": \"contacts\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>''\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ersetzen Sie unbedingt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<appId>>"
      }), " mit Ihrer eigenen App-ID, die Sie sowohl unter ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Meine Apps"
      }), " als auch auf den App-Detailseiten in Ihrem Entwickler-Account finden können. Sie müssen auch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<developerHapikey>>"
      }), " mit Ihrem eigenen Entwickler-API-Schlüssel ersetzen, den Sie finden, indem Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Apps"
      }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubSpot-API-Schlüssel abrufen"
      }), " gehen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Eigenschaften ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "headerTemplate"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "detailTemplate"
      }), " könnten auch hier angegeben werden. (Siehe „Definieren von Vorlagen“ unten.)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage gibt die vollständige, gespeicherte Definition der Event-Vorlage zurück. Beachten Sie unbedingt die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Eigenschaft in dieser Antwort. Dies ist die Event-Vorlagen-ID, die Sie für alle Aktualisierungen dieser Event-Vorlage oder Token in der Zukunft benötigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können alle für eine App definierten Event-Vorlagen über diesen GET-Befehl anzeigen. Dabei werden auch die Event-Vorlagen-IDs zurückgegeben:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Event-Vorlagen in HubSpot erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neben dem Erstellen und Verwalten von Chronik-Event-Vorlagen über die API können Sie auch Event-Vorlagen in Ihrem HubSpot-Entwickler-Account verwalten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gehen Sie in Ihren App-Einstellungen zu „Chronik-Events“ und verwenden Sie die Schaltfläche „Event-Typ erstellen“, um eine neue Event-Vorlage für diese App zu erstellen. Wenn Sie bereits Event-Vorlagen erstellt haben, werden diese ebenfalls hier angezeigt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(3)-Jul-23-2020-10-02-24-50-PM.png",
        alt: "image (3)-Jul-23-2020-10-02-24-50-PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie beginnen mit einem Entwurf Ihrer neuen Event-Vorlage. Nachdem Sie den Objekttyp und die Detail- und Header-Vorlagen für das Event festgelegt haben, klicken Sie auf „Erstellen“."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(4)-Jul-23-2020-10-02-24-66-PM.png",
        alt: "image (4)-Jul-23-2020-10-02-24-66-PM"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Legen Sie beim Erstellen oder Bearbeiten Ihrer Event-Vorlage auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Daten"
      }), " alle Token fest, die Sie mit ihr verwenden möchten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/data-tab-1.png",
        alt: "data-tab-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**Bitte beachten: **Sobald eine Vorlage gelöscht wurde, werden vorhandene Events, die diese Vorlage verwenden, dauerhaft aus Accounts mit Ihrer verknüpften App entfernt. Sie können keine neuen Events mehr für diesen Typ erstellen, sehen aber weiterhin dennoch frühere Event-Daten in Listen und Berichten. Es kann mehrere Stunden dauern, bis diese Änderungen in HubSpot angezeigt werden."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Event-Token definieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie eine Event-Vorlage festgelegt haben, möchten Sie wahrscheinlich auch deren Token definieren. Token für Event-Vorlagen ermöglichen es Ihnen, benutzerdefinierte Daten an Events anzuhängen, die in der Chronik angezeigt und bei Kontakten für die Listensegmentierung und Automatisierung verwendet werden können. Sie können bis zu 500 Token pro Chronik-Event-Vorlage erstellen."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Bitte beachten: **Unternehmens- und Deal-Events können ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " in der Listensegmentierung oder Automatisierung verwendet werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Event-Token über die API erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mithilfe der in Schritt 1 erstellten Event-Vorlagen-ID fügen wir einige Token hinzu, um das Webinar zu identifizieren, für das sich unsere Kontakte registriert haben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarName\",\n  \"label\": \"Webinar Name\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarId\",\n  \"label\": \"Webinar Id\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarType\",\n  \"label\": \"Webinar Type\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"value\": \"regular\",\n      \"label\": \"Regular\"\n    },\n    {\n      \"value\": \"ama\",\n      \"label\": \"Ask me anything\"\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In ähnlicher Weise werden mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " alle Token zurückgegeben, die in einer Event-Vorlage definiert sind:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET -H \"Content-Type: application/json\" 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die unterstützten Token-Typen umfassen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), " – Eine aus einer Reihe von Optionen. Siehe webinarType-Beispiel oben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "date"
        }), " – Alle Datumsangaben müssen in Millisekunden in Unix-Zeit angegeben sein."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hinweis"
        }), ": Event-Token können nicht Protokoll (log) oder Suche (lookup) benannt werden. Diese Token sind als Helfer von Handlebars.js reserviert, der Bibliothek, die zur Anzeige von Events in Apps verwendet wird. Weitere Informationen finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://handlebarsjs.com/builtin_helpers.html",
          children: "hier"
        })]
      }), " in der Dokumentation von Handlebars.js."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Header- und Detail-Vorlagen definieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Header- und Detail-Vorlagen bestimmen, wie Sie ein Chronik-Event angezeigt werden soll. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://daringfireball.net/projects/markdown/syntax",
        children: "Markdown"
      }), "-Dokumente mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://handlebarsjs.com/",
        children: "Handlebars"
      }), "-Vorlagen angeben. Die Header-Vorlage sollte eine einzeilige Beschreibung des Events sein, und die Details-Vorlage ist die Drilldown-Ansicht des Events (Beispiele unten)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Event-Token werden als Daten an die Vorlagen übergeben. Anhand unseres Beispiels können Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), "-Token in der Vorlage mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{webinarName}}"
      }), " referenzieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " eines Events (erläutert unter „Grundlegendes zu extraData“) können nur in der Details-Vorlage referenziert werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Header- und Detail-Vorlagen über die API definieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Header- und Detail-Vorlagen können in der Event-Vorlage über die Event-Vorlagen-Endpunkte definiert werden. Wir können beispielsweise Vorlagen zu unserer „Beispiel-Webinar-Registrierung“ hinzufügen, indem dies mit einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " modifiziert wird:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"id\": \"<<eventTemplateId>>\",\n  \"name\": \"Example Name Change\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beachten Sie hier die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#formatDate"
      }), "-Direktive. Mit ihr möchten wir eine benutzerfreundliche Datumsformatierung ermöglichen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald ein Event für einen Kontakt mit ihrer Hilfe erstellt wird (siehe \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#creating-an-event",
        children: "Erstellen eines Events)"
      }), "\", wird Folgendes in der Chronik des Kontakts angezeigt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Klicken auf „Details anzeigen“ stellt die Details-Vorlage dar:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_expanded.png?width=640&name=event_expanded.png",
        alt: "event_expanded.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen dazu, wie Sie Symbol festlegen, das neben den Events angezeigt wird, finden Sie unten unter \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline#customicon",
        children: "Einrichten eines benutzerdefinierten Symbols\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Text „Beispiel-App-Name“ oben ist der Name der App. In der CRM-Chronik können Events nach App gefiltert werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Alle Aspekte einer Event-Vorlage in einem einzigen Aufruf definieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bisher haben Sie gesehen, wie die einzelnen Aspekte einer Event-Vorlage schrittweise definiert wurden. Sie können aber auch alles in einem einzigen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Aufruf definieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Another Webinar Registration\",\n  \"objectType\": \"contacts\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\",\n  \"tokens\": [\n    {\n      \"name\": \"webinarName\",\n      \"label\": \"Webinar Name\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarId\",\n      \"label\": \"Webinar Id\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarType\",\n      \"label\": \"Webinar Type\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"value\": \"regular\",\n          \"label\": \"Regular\"\n        },\n        {\n          \"value\": \"ama\",\n          \"label\": \"Ask me anything\"\n        }\n      ]\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Event erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem nun eine Event-Vorlage mit Token und Vorlagen eingerichtet ist, sind wir bereit, Events für die Kontakte, Unternehmen, Deals und Tickets unserer Kunden zu erstellen. In den folgenden Beispielen wird davon ausgegangen, dass mit der oben erstellten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contacts"
      }), "-Event-Vorlage gearbeitet wird. Wenn die Event-Vorlage oben nicht so eingerichtet ist, dass sie über die Token ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarId"
      }), " verfügt, erhalten Sie einen Fehler beim Versuch, das Event zu erstellen. Hier ein Beispiel-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " für das Erstellen eines Events:"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Entwickler-API-Schlüssel und Zugriffstoken von privaten Apps können ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " als Authentifizierung beim Erstellen von Ereignissen verwendet werden. Um ein Event zu erstellen, muss der zugehörige HubSpot-Account Zugriff über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), " auf Ihre App gewähren. Sobald Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "Zugriffstoken"
        }), " abrufen, können Sie es zum Hinzufügen von Events zum Account verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dadurch wird ein Event in der Chronik von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a.test.contact@email.com"
      }), " erstellt (ausgehend davon, dass die Vorlagen unter „Definieren von Vorlagen“ verwendet werden):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Den Events-Zeitstempel festlegen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Zeitstempel des Events bestimmt, wo das Event in der Chronik des Objekts angezeigt wird. Standardmäßig wird der Event-Zeitstempel angezeigt, wenn der POST-Befehl gesendet wird. Sie können die Event-Zeit anpassen, indem Sie sie im Anfragetext in einer Zeitstempel-Eigenschaft angeben:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"timestamp\": \"2020-03-18T15:30:32Z\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies wird bevorzugt, wenn Sie den exakten Zeitpunkt kennen, an dem eine Aktion stattgefunden hat. In diesem Beispiel gilt, wenn wir den Zeitstempel für diese Webinar-Registrierung haben, sollten wir ihn in diesem POST bereitstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zeitstempel können in Millisekunden-Epoche-Zeit oder im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/ISO_8601",
        children: "ISO 8601"
      }), "-Format vorliegen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein Event zu einem CRM-Objekt zuordnen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um ein Event erstellen zu können, müssen Sie das Event mit einem Kontakt, einem Unternehmen oder einem Deal im Account des Kunden verknüpfen können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In den obigen Beispielen wurde der objectType auf Kontakt festgelegt und wir haben E-Mail verwendet, um das Event mit einem Kontakt zu verknüpfen. E-Mail-Adressen müssen für Kontakte in HubSpot eindeutig sein. Wenn es einen vorhandenen Kontakt mit der angegebenen E-Mail gibt, wird dieser Kontakt aktualisiert. Wenn kein Kontakt vorhanden ist, wird ein neuer Kontakt erstellt. Standardmäßig verfügt dieser neue Kontakt nur über die angegebene E-Mail-Kontakteigenschaft. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#stamp-event-data-onto-crm-object-properties",
        children: "Aktualisieren (Stempeln) von Kontakteigenschaften mit Event-Daten"
      }), ", um zusätzliche Daten zu Kontakteigenschaften hinzuzufügen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie mit bekannten Kontakten arbeiten, können Sie auch die Kontakt-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " verwenden, um das Event zu verknüpfen. In diesen Fällen würden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " im Anfrage-JSON verwenden. Sie müssen die VID eines vorhandenen Kontakts einbeziehen, da Sie keine neuen Kontakte mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " erstellen können. In diesem Beispiel wird die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " anstelle von E-Mail verwendet:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"29851\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ein Event auch nach Benutzertoken, oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ", einem Kontakt zuordnen. Das Benutzertoken wird vom HubSpot-Tracking-Code zum Nachverfolgen von Besuchern verwendet und wird im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspotutk"
      }), "-Cookie gespeichert. Verwenden Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "-Parameter, um ein Event nach Benutzertoken einem Kontakt zuzuordnen. Hinweis: Es ist nicht möglich, Events mit anonymen Besuchern mithilfe des Benutzertokens zuzuordnen. Wenn das Event also nur mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "-Parameters zugeordnet wird und das angegebene Benutzerertoken nicht bereits einem Kontakt zugeordnet ist, würde kein neuer Kontakt erstellt und das Event wäre nicht in HubSpot sichtbar. Das Event wird jedoch in der Chronik angezeigt, wenn ein neuer Kontakt über andere Methoden mit dem Benutzerertoken verknüpft worden ist, (in der Regel über eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "Formulareinsendung, einschließlich des hutk"
      }), ", oder über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code",
        children: "Methode des Identifizierens der Tracking-Code-API"
      }), "). Aus diesem Grund empfehlen wir, die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " zusätzlich zum ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " zu berücksichtigen, um sicherzustellen, dass das Event einem neuen oder vorhandenen Kontakt zugeordnet wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie mit einer Event-Vorlage für Kontakte arbeiten, ist es möglich, mehrere Identifikationsparameters mit dem Event zu berücksichtigen, sodass jede Kombination der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), "-Parameter berücksichtigt werden kann. Wenn mehrere Parameter berücksichtigt werden, hat die objectId (vid) die höchste Priorität beim Bestimmen des Kontakts, der dem Event zugeordnet werden soll, gefolgt von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " mit der geringsten Priorität. Dies bedeutet, dass Sie die E-Mail-Adresse eines vorhandenen Objekts aktualisieren können, indem Sie eine neue E-Mail-Adresse im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "-Parameter mit der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " eines bekannten Objekts in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " berücksichtigen können. In diesem Beispiel werden die E-Mail-Adresse und das Benutzertoken zusammen verwendet:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"utk\": \"89b5afb740d41f4cd6651ac5237edf09\"\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neben dem Arbeiten mit Kontakten ist es auch möglich, Event-Vorlagen für Unternehmen und Deals zu erstellen. Für diese Event-Vorlagen müssen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " verwenden, um das Event dem Unternehmen oder Deal zuzuordnen. Für muss die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " auf die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " des Unternehmens festgelegt sein, dem das Event zugeordnet werden soll, und für müssten Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " auf die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealId"
      }), " des Deal festlegen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn im folgenden Beispiel davon ausgegangen wird, dass die Event-Vorlage auf den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPANY"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " festgelegt wurde, würde dieses Event mit dem Unternehmensobjekt mit der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " 528253914 zugeordnet:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"3001\",\n  \"tokens\": {\n    \"dealProperty\": \"Custom property for deal\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Chronikerweiterungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Chronik-Erweiterungen können verwendet werden, um Daten aus einem externen System mithilfe eines iFrame anzuzeigen. Wenn berücksichtigt, zeigt das Event einen Link an, der ein modales Dialogfenster öffnet, das bei Anklicken den iFrame-Inhalt anzeigt. Die Details für den iFrame werden im timelineIFrame-Feld festgelegt. Dies ist ein Objekt enthält, das die folgenden Felder enthält:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "linkLabel"
        }), " – Der Text, der zur Anzeige des Links, der den IFrame-Inhalt anzeigt, verwendet wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "headerLabel"
        }), " – Das Label des modalen Fensters, das die IFrame-Inhalte anzeigt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), " – Der URI des IFrame-Contents."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "width"
        }), " – Die Breite des modalen Fensters."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "height"
        }), " – Die Höhe des modalen Fensters."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei Verwendung beispielsweise dieser Daten für ein Event:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"timelineIFrame\": {\n    \"linkLabel\":\"View external data\",\n    \"headerLabel\":\"Example iframe\",\n    \"url\":\"https://www.example.com\",\n    \"width\":800,\n    \"height\":300\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Würde dieses Event erstellt werden, einschließlich des Links „Externe Daten anzeigen“:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/external_data_link.png?width=640&name=external_data_link.png",
        alt: "external_data_link.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Durch Klicken auf diesen Link würde ein modales Fenster geöffnet, das die in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "url"
      }), " festgelegte Seite anzeigt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/iframe_modal.png?width=640&name=iframe_modal.png",
        alt: "iframe_modal.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CRM-Objekteigenschaften mit Event-Daten aktualisieren (stempeln)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In vielen Fällen möchten Sie die Eigenschaften für die Kontakte, Unternehmen oder Deals ändern, für die Sie Events hinzufügen, ändern. Dies geschieht häufig in Fällen, in denen über das Event tatsächlich ein Kontakt erstellt wird – Sie möchten wahrscheinlich die Eigenschaften „Vorname“ und „Nachname“ im Kontakt aktualisieren, damit Sie nicht nur einen Kontakt mit einer E-Mail-Adresse und einem Event erstellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können das zugehörige Objekt mit Daten von einem Event aktualisieren (stempeln), indem Sie Ihre benutzerdefinierten Event-Token Kontakt-, Unternehmens- oder Deal-Eigenschaften zuordnen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ziehen Sie diesen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Befehl zum Aktualisieren einer benutzerdefinierten Event-Vorlage in Betracht. Beachten Sie dabei das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), "-Feld:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"label\" : \"Updated Webinar Name\",\n  \"objectPropertyName\": \"zz_webinar_name\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens/<<tokenName>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieser verwendet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), ", um dieses benutzerdefinierte Event-Token der Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), "-Objekt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " zuordnen. Das heißt, dass beim Erstellen eines neuen Events, das ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), "-Token angibt, auch die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), "-Eigenschaft des zugehörigen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " festgelegt wird. Sie können diese auf benutzerdefinierte oder vordefinierte HubSpot-Eigenschaften festlegen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Angenommen, wir haben beispielsweise bereits eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyName"
      }), "-Token erstellt, das eine benutzerdefinierte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), "-Eigenschaft im Kontakt referenziert. Dies führt dann dazu, dass bei Erstellen eines Event wie diesem die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), "-Eigenschaften im Kontakt mit der E-Mail-Adresse ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:a.test.contact@email.com",
        children: "a.test.contact@email.com"
      }), " festgelegt werden:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/set_property.png?width=1024&name=set_property.png",
        alt: "set_property.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hinweis: Wenn eine benutzerdefinierte Eigenschaft mit einem Event-Token aktualisiert (gestempelt) wird und diese benutzerdefinierte Eigenschaft für einen HubSpot-Account nicht vorhanden ist, wird der Wert weiterhin für das Event festgelegt, es wird jedoch für das entsprechende Objekt ignoriert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " verstehen"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie müssen möglicherweise detaillierte Daten zu einem Event hinzufügen, das nicht der einfachen Token-Wert-Struktur entspricht, die von den Event-Vorlagen-Token verwendet wird. Möglicherweise müssen Sie auch eine Liste oder eine gewisse hierarchische Aufschlüsselung zu einem Integrations-Event hinzufügen. Hier kommt nun ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " ins Spiel."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "-Attribut zum JSON-Text eines Events hinzufügen. Der Wert dieses ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "-Attributs kann beliebiger zulässiger JSON sein. Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"extraData\": {\n    \"pollData\": [\n      {\n        \"question\": \"How excited are you for this webinar?\",\n        \"answer\":\"Quite!\"\n      },\n      {\n        \"question\": \"How frequently do you use our product?\",\n        \"answer\":\"Daily\"\n      }\n    ],\n    \"coWorkers\": [\n      {\n        \"name\": \"Joe Coworker\",\n        \"email\":\"joe.coworker@testco.com\"\n      },\n      {\n        \"name\": \"Jane Coworker\",\n        \"email\":\"jane.coworker@testco.com\"\n      }\n    ]\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Beispiel für die Verwendung von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " in einer Details-Vorlage:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "//\nRegistration occurred at {{#formatDate timestamp}}{{/formatDate}}\n\n#### Poll Questions\n{{#each extraData.pollData}}\n  **{{question}}**: {{answer}}\n{{/each}}\n\n#### Co-Workers\n{{#each extraData.coWorkers}}\n  * {{name}}\n{{/each}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies führt zu einem Chronik-Event, das wie folgt aussieht:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/extra_data.png?width=640&name=extra_data.png",
        alt: "extra_data.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hinweis: Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), "-Attribut kann nur in der Details-Vorlage für ein Event referenziert werden. Es kann weder in der Kopfzeilen-Vorlage oder in der Listensegmentierung verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein benutzerdefiniertes Symbol einrichten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um Ihre Chronikelemente visuell ansprechender gestalten, sollten Sie ein benutzerdefiniertes Symbol hinzufügen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Bilddatei für dieses Symbol sollte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ungefähr quadratische Maße aufweisen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Einen durchsichtigen Hintergrund haben"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Den Inhalt in der Mitte des Symbols haben"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Auf bis zu 30 x 30 Pixel verkleinert werden können"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Über eine Dateigröße von höchstens 5 MB verfügen"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um das Symbol für Chronik Events festzulegen, navigieren Sie zu „Chronik Events“. Klicken Sie auf das Platzhalterbild oder das vorhandene Symbol, um es festzulegen oder zu aktualisieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_assets.png",
        alt: "timeline_assets"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie das oder die Symbole festgelegt haben, wird es bzw. werden sie neben allen mit dieser Anwendung verknüpften Chronik-Events angezeigt:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/timeline_icon.png?width=640&name=timeline_icon.png",
        alt: "timeline_icon.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Grundlegendes zum CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "CRM-Karten"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}