import { useContext, useEffect } from 'react';
import { TrackerContext } from '../utils/usage-tracker/TrackerContext';

/**
 * Recommended use of `any` in `window`'s case.
 * @see https://hubspot.slack.com/archives/C017FT4P2Q0/p1652891788973199?thread_ts=1652891566.469509&cid=C017FT4P2Q0
 */

/**
 * The className on the manage cookies link.
 * @see https://docs.google.com/document/d/1RglGxAPE7jJZn7NoajYWApUI3B9ciWBBrtBTIXrOSWk/edit
 */
export const MANAGE_COOKIES_CLASSNAME = 'hs-footer-cookie-settings';

/**
 * Checks that the `window._hsg` and `window._hsg.compliance` keys
 * have been set by the external cookies compliance script. These are
 * needed to call its methods in the SPA.
 */
const isValidComplianceFunction = key => !!window._hsg && !!window._hsg.compliance && typeof window._hsg.compliance[key] === 'function';

/**
 * Checks if the 'Manage Cookies' link is hidden. If it is hidden,
 * calls the `window` function that is set by the external GDPR
 * compliance script.
 * @see https://docs.google.com/document/d/1RglGxAPE7jJZn7NoajYWApUI3B9ciWBBrtBTIXrOSWk/edit
 */
export const renderManageCookiesLink = () => {
  // Since this function is used within descendants (e.g. Footer), always check that the window method exists before querying the DOM and calling `addSettingsLink`
  if (isValidComplianceFunction('addSettingsLink')) {
    const hiddenFooterCookieSettings = document.querySelector(`.${MANAGE_COOKIES_CLASSNAME}[hidden]`);
    if (hiddenFooterCookieSettings) {
      // Only call function when 'Manage cookies' link is hidden (i.e. has `hidden` attribute)
      window._hsg.compliance.addSettingsLink();
    }
  }
};

/**
 * Mounts the 'Manage Cookies' footer link and modal on SPA route
 * change. Should be included on every public-facing route. This
 * cannot be previewed locally — see documentation.
 *
 * @see https://docs.google.com/document/d/1RglGxAPE7jJZn7NoajYWApUI3B9ciWBBrtBTIXrOSWk/edit
 */
const GDPRCompliance = ({
  pathname
}) => {
  const {
    updateTrackerTypeIfChanged
  } = useContext(TrackerContext);
  useEffect(() => {
    if (isValidComplianceFunction('scanCookies')) {
      window._hsg.compliance.scanCookies();
    }
    updateTrackerTypeIfChanged();
    renderManageCookiesLink();
  }, [pathname, updateTrackerTypeIfChanged]);
  return null;
};
export default GDPRCompliance;