"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59619794102;
const slug = exports.slug = 'guides/api/crm/engagements/calls';
const title = exports.title = 'Interacciones | Llamadas';
const name = exports.name = 'Interacciones | Llamadas';
const metaDescription = exports.metaDescription = 'Utiliza la API de interacciones de llamadas para registrar y gestionar llamadas en registros del CRM. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-interacci%C3%B3n-de-llamada",
  "label": "Crear una interacción de llamada",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": "crear-una-interacci%C3%B3n-de-llamada"
}, {
  "depth": 1,
  "id": "asociaciones",
  "label": "Asociaciones",
  "parent": "crear-una-interacci%C3%B3n-de-llamada"
}, {
  "depth": 0,
  "id": "recuperar-llamadas",
  "label": "Recuperar llamadas",
  "parent": null
}, {
  "depth": 0,
  "id": "identificaci%C3%B3n-de-los-mensajes-de-voz-frente-a-las-llamadas-grabadas",
  "label": "Identificación de los mensajes de voz frente a las llamadas grabadas",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-llamadas",
  "label": "Actualizar llamadas",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-llamadas-existentes-con-registros",
  "label": "Asociar llamadas existentes con registros",
  "parent": "actualizar-llamadas"
}, {
  "depth": 1,
  "id": "eliminar-una-asociaci%C3%B3n",
  "label": "Eliminar una asociación",
  "parent": "actualizar-llamadas"
}, {
  "depth": 0,
  "id": "anclar-una-llamada-en-un-registro",
  "label": "Anclar una llamada en un registro",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-llamadas",
  "label": "Eliminar llamadas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Llamadas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la API de interacciones de llamadas para registrar y gestionar llamadas en registros del CRM y en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "página de índice de llamadas"
      }), ". Puedes registrar llamadas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "en HubSpot"
      }), " o a través de la API de llamadas. A continuación, aprenderás los métodos básicos de gestión de llamadas a través de la API. Para ver todos los puntos de terminación disponibles y sus requisitos, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una interacción de llamada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una interacción de llamada, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de la solicitud, agrega detalles de la llamada en el objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propiedades"
      }), ". También puedes agregar el objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "asociaciones"
      }), " para asociar tu nueva llamada con un registro existente (por ejemplo, contactos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra una lista de las propiedades de llamada predeterminadas de HubSpot que puedes incluir en el objeto de propiedades. También puedes crear propiedades personalizadas utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/properties",
        children: "API de propiedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Requerido. Este campo marca la hora de creación de la llamada y determina dónde se encuentra la llamada en la cronología del registro. Puedes usar una marca de tiempo Unix en milisegundos o en formato UTC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La descripción de la llamada, incluidas las notas que deseas agregar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del registro de HubSpot asociado con la llamada. Este será el destinatario de la llamada para llamadas ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), ", o el marcador de la llamada para llamadas ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_type_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del objeto al que pertenece el registro asociado de la llamada (por ejemplo, especifica si el registro es un contacto o una empresa). Este será el objeto del destinatario para las llamadas ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), ", o el objeto del marcador para las llamadas ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La dirección de la llamada desde la perspectiva del usuario de HubSpot. Si el usuario es el destinatario de la llamada, la dirección debe establecerse en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), ". Si el usuario inició la llamada, la dirección debe establecerse en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_disposition"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El resultado de la llamada. Para establecer la disposición de llamada, debes usar el valor interno de GUID. Si tu cuenta configuró", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/calling/create-custom-call-and-meeting-outcomes",
              children: "resultados de llamadas personalizadas"
            }), ", puedes encontrar sus GUID de disposición usando ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/engagements/engagement-details#get-call-engagement-dispositions",
              children: "esta API"
            }), ". Las etiquetas de resultados predeterminadas de HubSpot y sus valores internos son: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Ocupado: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "9d9162e7-6cf3-4944-bf63-4dff82258764"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Conectado: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "f240bbac-87c9-4f6e-bf70-924b57d47db7"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Dejó mensaje en directo: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "a4c4c377-d246-4b32-a13b-75a56a4cd0ff"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Dejó mensaje de voz: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "b2cf5968-551e-4856-9783-52b3da59a7d0"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["No hay respuesta: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "73a0d17f-1163-4015-bdd5-ec830791da20"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Número equivocado: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "17b47fee-58de-441e-a44c-c6300d46f273"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La duración de la llamada, en milisegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_from_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de teléfono desde el que se hizo la llamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_recording_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL que almacena la grabación de la llamada. Las URL a archivos .mp3 o .wav se pueden reproducir en registros del CRM. Solo se aceptarán HTTPS, URL seguras."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El estado de la llamada. Los estados son ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BUSY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALLING_CRM_USER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CANCELED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPLETED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONNECTING"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_PROGRESS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NO_ANSWER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUEUED"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RINGING"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El título de la llamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_source"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fuente de la llamada. Esto no es obligatorio, pero es necesario para aprovechar el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/extensions/calling-extensions/recordings-and-transcriptions#log-a-call-with-your-app-s-endpoint-using-the-engagements-api",
              children: "pipeline de grabación y transcripciones"
            }), ". Si la propiedad está establecida, debe establecerse en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS_PLATFORM"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_to_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de teléfono que recibió la llamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID del propietario"
            }), " asociado con la llamada. Este campo determina el usuario que aparece como creador de la llamada en la cronología del registro."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de llamada. Las opciones se basan en los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "tipos de llamadas establecidos en tu cuenta de HubSpot."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los ID de los archivos adjuntos de la llamada. Los ID de varios archivos adjuntos están separados por un punto y coma."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear y asociar una llamada con registros existentes, incluye el objeto asociaciones en tu solicitud. Por ejemplo, para crear una llamada y asociarla con un contacto y un ticket, el cuerpo de tu solicitud podría tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Support call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \"Resolved issue\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 500\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 194\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1234\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 220\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto asociaciones, debes incluir lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El registro que deseas asociar con la llamada, especificado por su valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " único."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación entre la llamada y el registro. Incluye ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Los ID de los tipos de asociación predeterminados se enumeran ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aquí"
            }), ", o puedes recuperar el valor de los tipos de asociación personalizados (es decir, etiquetas) a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre cómo crear llamadas por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar llamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes recuperar llamadas individualmente o en masa. Obtén más información sobre la recuperación por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar una llamada individual por su ID de llamada, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), ". Puedes incluir los siguientes parámetros en la URL de la solicitud:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de las ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "propiedades"
            }), " que se devolverán."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de los tipos de objetos para recuperar los ID asociados. No se devolverán en la respuesta las asociaciones especificadas que no existan. Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de asociaciones."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar una lista de todas las llamadas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), ". Puedes incluir los siguientes parámetros en la URL de la solicitud:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número máximo de resultados que se mostrarán por página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de las ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "propiedades"
            }), " que se devolverán."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando realices una solicitud exitosa, la respuesta incluirá la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callId"
      }), " que puedes usar para recuperar, actualizar y eliminar la llamada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identificación de los mensajes de voz frente a las llamadas grabadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para llamadas y mensajes de voz grabados, se almacena una grabación en la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_recording_url"
      }), ". Si tu cuenta tiene acceso a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/receive-calls-in-hubspot",
        children: "llamadas entrantes"
      }), ", para diferenciar entre las llamadas que se completaron y grabaron frente a las llamadas entrantes con un mensaje de voz, incluye las siguientes propiedades en tu solicitud: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si una llamada tiene un mensaje de voz, el valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "missed"
      }), ", y el valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". El valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " para una llamada entrante en la que no se dejó ningún mensaje de voz o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), " si la llamada tiene un estado distinto a perdido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar llamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes actualizar las llamadas individualmente o por lotes. Para actualizar una llamada individual por su ID de llamada, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la solicitud, incluye las propiedades de llamada que deseas actualizar:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/calls/{callID}\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Discovery call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \" Decision maker out, will call back tomorrow\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignorará los valores de las propiedades de solo lectura e inexistentes. Para borrar un valor de propiedad, pasa una cadena vacía para la propiedad en el cuerpo de la solicitud."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la actualización por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar llamadas existentes con registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar una llamada con registros, como un contacto y sus empresas asociadas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". La URL de la solicitud contiene los siguientes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la llamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tipo de objeto con el que deseas asociar la llamada (por ejemplo, contacto o empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del registro con el que deseas asociar la llamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un identificador único para indicar el tipo de asociación entre la llamada y el otro objeto. El ID se puede representar numéricamente o con palabras separadas por guion bajo (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "call_to_contact"
            }), "). Puedes recuperar el valor a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, la URL de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/calls/17591596434/associations/contact/104901/194"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una asociación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una asociación entre una llamada y un registro, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " en la misma URL que la anterior:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anclar una llamada en un registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "anclar una llamada"
      }), " en un registro para que permanezca en la parte superior de la línea de tiempo del registro. La llamada ya debe estar asociada con el registro antes de anclarla, y solo debes anclar una actividad por registro. Para anclar una llamada, incluye ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de las llamadas en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " al crear o actualizar un registro a través de las API de objetos. Obtén más información sobre el uso de las API de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas"
      }), ",", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contactos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negocios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar llamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes eliminar llamadas individualmente o por lotes, lo que agregará la llamada a la papelera de reciclaje en HubSpot. Más tarde podrás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurar la llamada desde la cronología del registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una llamada individual por su ID de llamada, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la eliminación por lotes haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}