"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102629641400;
const slug = exports.slug = 'guides/crm/overview';
const title = exports.title = 'UI customization overview (BETA)';
const name = exports.name = 'UI customization overview';
const metaDescription = exports.metaDescription = "Learn about the ways you can customize HubSpot's UI by creating UI extensions. UI extensions are developed locally using developer projects, and are powered by private or public apps.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "projects",
  "label": "Projects",
  "parent": null
}, {
  "depth": 0,
  "id": "apps",
  "label": "Apps",
  "parent": null
}, {
  "depth": 0,
  "id": "ui-extensions",
  "label": "UI extensions",
  "parent": null
}, {
  "depth": 0,
  "id": "development-sandboxes",
  "label": "Development sandboxes",
  "parent": null
}, {
  "depth": 0,
  "id": "github-integration",
  "label": "GitHub integration",
  "parent": null
}, {
  "depth": 0,
  "id": "get-started",
  "label": "Get started",
  "parent": null
}, {
  "depth": 1,
  "id": "get-started-with-private-apps",
  "label": "Get started with private apps",
  "parent": "get-started"
}, {
  "depth": 1,
  "id": "get-started-with-public-apps",
  "label": "Get started with public apps",
  "parent": "get-started"
}, {
  "depth": 0,
  "id": "more-resources",
  "label": "More resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h1: "h1",
      img: "img",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " previously, this page was titled \"CRM development tools overview.\" It has been updated to reflect the expanded toolset, as UI extensions can also be used to customize the help desk preview panel UI. All previous CRM development tools documentation has been expanded and moved to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/overview",
          children: "guides section"
        }), ". This page has been updated for posterity."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "UI customization overview (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Customize HubSpot's UI by building UI extensions, powered by public and private apps. UI extensions are developed locally using the developer projects framework, and enable you to use features like development sandboxes, version control, and integration with GitHub to streamline your process."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you can build an app card that retrieves data from an external source."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/custom-extension-example.png",
        alt: "custom-extension-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an overview of the tools you'll use during the UI extension development process, along with links to in-depth documentation to guide you along the way."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " there are a few ways to use HubSpot's UI customization tools, depending on your HubSpot subscription:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Building UI extensions for private apps in a standard HubSpot account requires an ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " subscription. However, a paid subscription is not required to create UI extensions for private apps in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#developer-test-accounts",
            children: "developer test accounts"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Public apps are built in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#app-developer-accounts",
            children: "developer accounts"
          }), ", which don't require a paid subscription. If you're enrolled in the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/build-app-cards",
            children: "UI extensions for public apps beta"
          }), ", learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/public-apps/overview",
            children: "building UI extensions for public apps"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Projects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Projects are the highest level container of UI extension development, as they contain not only UI extensions, but also the app that powers them, along with all other supporting assets. Projects also enable a build and deploy process on HubSpot's platform with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project"
      }), " CLI commands, which you'll use for version control as you build. Once deployed to an account, you can view and manage the project, app, and any included UI extensions in HubSpot. This includes viewing build history and monitoring API calls."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/create-a-project",
        children: "creating projects"
      }), ", and get started by following the UI extension quickstart guides for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart",
        children: "private apps"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/quickstart",
        children: "public apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Apps are the powerhouse of UI extensions, enabling you to authenticate requests for data fetching and more. You can build UI extensions for both public apps and private apps, depending on your use case. The main differences between which type of app to use include:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpot subscription:"
        }), " you'll need an ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription to build UI extensions for private apps in a standard HubSpot account. However, you can try out the toolset for free by opting a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#developer-test-accounts",
          children: "developer test account"
        }), " into the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#get-started",
          children: "beta"
        }), ". And because developer accounts are free, a paid subscription is not required for building with public apps."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the ability to build UI extensions for public apps is currently in Early Access beta, and enrollment is separate from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM development tools to build UI extensions with React as frontend"
        }), " beta for private apps."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/build-app-cards",
          children: "Request access to the UI extensions for public apps beta"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Distribution:"
        }), " private apps are more intended for use in individual accounts as needed, as they must be created on a per-account basis. Public apps are intended for use in multiple accounts and include an easy installation flow for the end-user. If you intend to list your app on the App Marketplace, you'll need to build a public app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentication:"
        }), " private apps fetch data using serverless functions, and HubSpot provides the back-end by authenticating requests with an access token. Public apps fetch data with the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.fetch()"
        }), " API and authenticate requests with OAuth, which require you to provide a custom back-end. Because OAuth tokens are short-lived, your integration will need to have the infrastructure to securely store, manage, and refresh tokens."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Supported features:"
        }), " while both app types support UI extensions, public apps enable you to include more types of extensions as features in your app, such as timeline events or using the calling SDK."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "differences between these types of apps"
      }), ", as well as how to create and configure both ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/quickstart",
        children: "public apps"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart",
        children: "private apps"
      }), " locally using projects."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "UI extensions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI extensions are custom-built, React-based UI elements that enrich HubSpot's interface. At this time, possible UI extensions include app cards for CRM records and help desk. For example, you can create a card that enables users to submit form data to an external database from any contact record, or a card that customer support reps can use to take action on open tickets in the help desk UI."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI extensions are built using projects and consist of a front-end and back-end:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "UI extension front-end:"
        }), " the user-facing part of the extension in the form of an app card consisting of ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "HubSpot-provided components"
        }), ". Along with displaying information, users can interact with components to perform a variety of actions. You'll build the front-end with either React or TypeScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "UI extension back-end:"
        }), " private apps fetch data through ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "serverless functions"
        }), ", which allows a UI extension to send and retrieve data to display in components, while the back-end is provided by HubSpot. Public apps instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/fetching-data",
          children: "hubspot.fetch API"
        }), ", which requires you to bring your own REST-based back end for authenticating and authorizing requests."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "UI extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the app cards you can build with projects are separate from ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/overview",
          children: "classic CRM cards"
        }), ", and they cannot be built interchangeably. If you've previously built a public app that includes a classic CRM card, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/migrate-a-public-app-to-projects",
          children: "migrate it to the projects framework"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Development sandboxes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For private app development, you can use development sandboxes to develop projects in a lightweight testing environment to ensure your project's components work as expected before deploying to a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "standard sandbox or production account"
      }), ". Development sandboxes are created through the CLI and can be accessed within production HubSpot accounts. Development sandboxes sync some, but not all, account assets on creation, and have additional limits compared to standard sandboxes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When building UI extensions for a public app, you'll instead use test accounts within your developer account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup",
        children: "setting up a development sandbox"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "GitHub integration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you prefer to use GitHub for version control, you can connect a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/link-a-github-repository-to-a-project",
        children: "GitHub repository to a project"
      }), " to automatically trigger project builds when you push a change to the connected repository. You can then use use GitHub tools and workflows to streamline development, whether you work alone or with a team."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get started"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get started with private apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/user-management/hubspot-user-permissions-guide#super-admin",
        children: "Super Admin"
      }), " in an ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " account, you can join this beta from the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Product Updates"
      }), " page in your HubSpot account:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, click your ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "account name"
          }), " in the top right corner, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Product updates"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "In beta"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the list of betas, search for or scroll to the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CRM development tools to build UI extensions with React as frontend"
          }), " beta, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Join beta"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/crm-development-tools-join-beta.png",
            alt: "crm-development-tools-join-beta"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After joining the beta, get started with any of the following options:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/quickstart",
          children: "quickstart guide"
        }), " to quickly build and deploy a working example app card."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Check out ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sample-extensions/overview",
          children: "HubSpot's sample extensions"
        }), " to see examples of what's possible."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Build your project from scratch by starting with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/create-a-project",
          children: "HubSpot projects guide"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get started with public apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ability to create UI extensions for public apps is in Early Access beta, which you can request access to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/build-app-cards",
        children: "here"
      }), ". Once you're enrolled in the beta, start with the following resources:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/overview",
          children: "UI extensions for public apps overview"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/quickstart",
          children: "UI extensions for public apps quickstart"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/migrate-a-public-app-to-projects",
          children: "Migrate an existing public app to the projects framework"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "More resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/CRM-Development-Tools-Beta/gh-p/crm-development-tools-beta",
          children: "CRM development tools on the HubSpot Community"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/crm-development-tools-for-hubspot-developers-beta",
          children: "CRM development tools on the HubSpot Developer Blog"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}