"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694131;
const slug = exports.slug = 'guides/cms/content/themes/hubspot-cms-boilerplate';
const title = exports.title = 'O HubSpot CMS Boilerplate';
const name = exports.name = '[novo]O HubSpot CMS Boilerplate';
const metaDescription = exports.metaDescription = 'O HubSpot CMS Boilerplate ilustra as práticas recomendadas e ajuda os desenvolvedores a criar rapidamente um site no HubSpot CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "por-que-os-desenvolvedores-devem-usar-o-hubspot-cms-boilerplate%3F",
  "label": "Por que os desenvolvedores devem usar o HubSpot CMS Boilerplate?",
  "parent": null
}, {
  "depth": 0,
  "id": "como-come%C3%A7ar-a-usar-o-hubspot-cms-boilerplate",
  "label": "Como começar a usar o HubSpot CMS Boilerplate",
  "parent": null
}, {
  "depth": 0,
  "id": "estrutura-do-hubspot-cms-boilerplate",
  "label": "Estrutura do HubSpot CMS Boilerplate",
  "parent": null
}, {
  "depth": 0,
  "id": "jquery",
  "label": "jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      strong: "strong",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "O HubSpot CMS Boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 9,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "O HubSpot CMS Boilerplate"
            }), " serve como um ponto de partida para ajudar os desenvolvedores a colocar um site no ar rapidamente no HubSpot CMS e ilustra as práticas recomendadas na hora de criar com a plataforma do HubSpot CMS. O boilerplate é um projeto open-source do GitHub onde todos podem sugerir mudanças e adaptar para seu próprio uso. Se você é novo no HubSpot CMS e quer começar um novo projeto baseado no boilerplate, siga o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/quickstart",
              children: "guia de início rápido para desenvolver no HubSpot CMS"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 3,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/subscription",
              children: "Watch"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/fork",
              children: "Fork"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Star"
            })]
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Por que os desenvolvedores devem usar o HubSpot CMS Boilerplate?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot CMS Boilerplate foi projetado e é mantido ativamente pela HubSpot. Ao criar o boilerplate, incorporamos as práticas recomendadas influenciadas pela forma como os desenvolvedores criam a melhor experiência de construção de sites, e então as aplicamos na construção de um site no HubSpot CMS. Isso fornece aos desenvolvedores um site limpo, com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "desempenho"
      }), " e pronto para ser modificado, o que poupa aos desenvolvedores uma quantidade significativa de tempo de desenvolvimento. O boilerplate também fornece CSS abrangente para os ativos relacionados ao HubSpot, tais como formulários, módulos de menu, classes de base e muito mais. Veja uma demonstração dinâmica do boilerplate em ação em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/",
        children: "https://boilerplate.hubspotcms.com/"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como começar a usar o HubSpot CMS Boilerplate"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para começar a usar a placa da caldeira no seu ambiente de desenvolvimento local, basta seguir o nosso ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Guia de introdução ao desenvolvimento de sites no HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estrutura do HubSpot CMS Boilerplate"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Os desenvolvedores podem trabalhar com o boilerplate usando as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "ferramentas de desenvolvimento local"
            }), " ou diretamente dentro do ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/get-started-with-the-design-manager",
              children: "Gerenciador de design"
            }), ". O boilerplate usa referências de caminhos relativos para todos os ativos, o que facilita a adaptação a qualquer projeto. Isso também permite que o boilerplate seja totalmente transferível entre as contas na plataforma HubSpot. Abaixo está uma captura de tela da estrutura de pastas dos ativos do boilerplate. O boilerplate separa os ativos em múltiplos diretórios para fácil identificação de onde eles residem."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844633478"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/boilerplate/boilerplate-structure-tree-view-4.png",
        alt: "Estrutura de pastas do HubSpot CMS Boilerplate"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A estrutura de modelo subjacente do HubSpot CMS Boilerplate gira em torno de um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "layout de base comum"
      }), ", localizado na pasta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "modelos > layouts"
      }), ", que é então a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% extends %}"
      }), ", e faz referência ao bloco ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% block body %}"
      }), " para seu conteúdo principal. Um exemplo de como a tag extend e os blocos são usados pode ser visto em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "qualquer um dos arquivos html dentro do diretório de modelos"
      }), ". Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#blocks-and-extends",
        children: "blocos e extensões"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este é um método comum de desenvolvimento em sistemas CMS em que você tem uma base (às vezes chamada de modelo principal) que contém todas as principais peças estruturais comuns de conteúdo do site. Estes costumam ser os itens que estão dentro do elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " no seu site, como propriedades meta comuns (por exemplo: Título e Metadescrição), links Favicon, links CSS e scripts de terceiros."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n <head>\n   <meta charset=\"utf-8\">\n   <title>{{ page_meta.html_title }}</title>\n   {% if site_settings.favicon_src %}<link rel=\"shortcut icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n   <meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n   {{ require_css(get_asset_url(\"../../css/layout.css\")) }}\n   {{ require_css(get_asset_url(\"../../css/main.css\")) }}\n   {{ require_css(\"https://fonts.googleapis.com/css?family=Merriweather:400,700|Lato:400,700&display=swap\") }}\n   {{ require_js(get_asset_url(\"../../js/main.js\")) }}\n   {{ standard_header_includes }}\n </head>\n <body>\n   <div class=\"body-wrapper {{ builtin_body_classes }}\">\n     {% block header %}\n       {% global_partial path=\"../partials/header.html\" %}\n     {% endblock header %}\n\n     {% block body %}\n       <!-- Nothing to see here -->\n     {% endblock body %}\n\n     {% global_partial path=\"../partials/footer.html\" %}\n   </div>\n   {{ standard_footer_includes }}\n </body>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro desse layout de base, há também chamadas para as nossas parciais globais de cabeçalho e rodapé. Isso nos permite manter o código dessas parciais em seus próprios arquivos separados para modularidade e, por serem parciais globais, elas podem então ser facilmente editadas pelos criadores de conteúdo usando nosso ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Editor de conteúdo global"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter mais detalhes sobre os ativos incluídos no boilerplate, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/wiki",
        children: "confira o wiki do boilerplate no GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "O boilerplate de temas da HubSpot não exige jQuery para funcionar"
      }), ". Para contas da HubSpot mais antigas, o jQuery é carregado por padrão. As novas contas da HubSpot têm o jQuery desativado por padrão."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tradicionalmente, os scripts da HubSpot exigiam jQuery para funcionar, por isso a configuração de todo o domínio estava lá para ajudar a garantir a compatibilidade. Os scripts da HubSpot não usam mais jQuery. porque ele não é necessário e há maneiras melhores de os desenvolvedores incluírem bibliotecas que também trabalham com controle de código fonte. É aconselhável desativar as configurações jQuery para sites novos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esteja ciente de que, se você desativar o jQuery em um domínio que já tenha um site - todas as landing pages ou páginas da web existentes poderão ser afetadas se dependerem de jQuery."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Se você deseja usar jQuery no seu site novo, é recomendado usar a versão mais recente do jQuery."
      }), " Há duas maneiras simples de fazer isso:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Faça o upload da última versão do jQuery no seu sistema de arquivos de desenvolvimento e use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " para carregar o jQuery onde e quando você precisar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use uma CDN de confiança, e use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " para carregar o jQuery onde e quando você precisar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Introdução aos temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Como otimizar o seu site do CMS Hub para desempenho"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Introdução à acessibilidade"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}