"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937503;
const slug = exports.slug = 'guides/api/crm/extensions/calling-sdk';
const title = exports.title = 'CRM-API | SDK für Calling-Erweiterungen';
const name = exports.name = 'vNext Docs DP - SDK für Calling-Erweiterungen';
const metaDescription = exports.metaDescription = 'Übersicht über das SDK für Calling-Erweiterungen';
const toc = exports.toc = [{
  "depth": 0,
  "id": "die-demo-anruf-app-ausf%C3%BChren",
  "label": "Die Demo-Anruf-App ausführen",
  "parent": null
}, {
  "depth": 1,
  "id": "die-demo-anruf-app-installieren",
  "label": "Die Demo-Anruf-App installieren",
  "parent": "die-demo-anruf-app-ausf%C3%BChren"
}, {
  "depth": 1,
  "id": "die-demo-anruf-app-von-hubspot-aus-starten",
  "label": "Die Demo-Anruf-App von HubSpot aus starten",
  "parent": "die-demo-anruf-app-ausf%C3%BChren"
}, {
  "depth": 0,
  "id": "das-sdk-f%C3%BCr-calling-erweiterungen-in-ihrer-anruf-app-installieren",
  "label": "Das SDK für Calling-Erweiterungen in Ihrer Anruf-App installieren",
  "parent": null
}, {
  "depth": 0,
  "id": "sdk-f%C3%BCr-calling-erweiterungen-verwenden",
  "label": "SDK für Calling-Erweiterungen verwenden",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-app-testen",
  "label": "Ihre App testen",
  "parent": null
}, {
  "depth": 1,
  "id": "mithilfe-des-anrufeinstellungen-endpunkts",
  "label": "Mithilfe des Anrufeinstellungen-Endpunkts",
  "parent": "ihre-app-testen"
}, {
  "depth": 1,
  "id": "ihre-erweiterungseinstellungen-mit-localstorage-%C3%BCberschreiben",
  "label": "Ihre Erweiterungseinstellungen mit localStorage überschreiben",
  "parent": "ihre-app-testen"
}, {
  "depth": 0,
  "id": "ihre-app-f%C3%BCr-die-produktion-vorbereiten",
  "label": "Ihre App für die Produktion vorbereiten",
  "parent": null
}, {
  "depth": 0,
  "id": "ihre-anruf-app-im-hubspot-marketplace-ver%C3%B6ffentlichen",
  "label": "Ihre Anruf-App im HubSpot Marketplace veröffentlichen",
  "parent": null
}, {
  "depth": 0,
  "id": "events",
  "label": "Events",
  "parent": null
}, {
  "depth": 1,
  "id": "senden-von-nachrichten-an-hubspot",
  "label": "Senden von Nachrichten an HubSpot",
  "parent": "events"
}, {
  "depth": 2,
  "id": "initialized",
  "label": "initialized",
  "parent": "events"
}, {
  "depth": 2,
  "id": "userloggedin",
  "label": "userLoggedIn",
  "parent": "events"
}, {
  "depth": 2,
  "id": "userloggedout",
  "label": "userLoggedOut",
  "parent": "events"
}, {
  "depth": 2,
  "id": "outgoingcall",
  "label": "outgoingCall",
  "parent": "events"
}, {
  "depth": 2,
  "id": "callanswered",
  "label": "callAnswered",
  "parent": "events"
}, {
  "depth": 2,
  "id": "callended",
  "label": "callEnded",
  "parent": "events"
}, {
  "depth": 2,
  "id": "callcompleted",
  "label": "callCompleted",
  "parent": "events"
}, {
  "depth": 2,
  "id": "senderror",
  "label": "sendError",
  "parent": "events"
}, {
  "depth": 2,
  "id": "resizewidget",
  "label": "resizeWidget",
  "parent": "events"
}, {
  "depth": 1,
  "id": "nachrichten-von-hubspot-empfangen",
  "label": "Nachrichten von HubSpot empfangen",
  "parent": "events"
}, {
  "depth": 2,
  "id": "onready",
  "label": "onReady",
  "parent": "events"
}, {
  "depth": 2,
  "id": "ondialnumber",
  "label": "onDialNumber",
  "parent": "events"
}, {
  "depth": 2,
  "id": "onengagementcreated",
  "label": "onEngagementCreated",
  "parent": "events"
}, {
  "depth": 2,
  "id": "oncreateengagementsucceeded",
  "label": "onCreateEngagementSucceeded",
  "parent": "events"
}, {
  "depth": 2,
  "id": "oncreateengagementfailed",
  "label": "onCreateEngagementFailed",
  "parent": "events"
}, {
  "depth": 2,
  "id": "onvisibilitychanged",
  "label": "onVisibilityChanged",
  "parent": "events"
}, {
  "depth": 2,
  "id": "defaulteventhandler",
  "label": "defaultEventHandler",
  "parent": "events"
}, {
  "depth": 0,
  "id": "anruf-sdk-%7C-h%C3%A4ufig-gestellte-fragen",
  "label": "Anruf-SDK | Häufig gestellte Fragen",
  "parent": null
}, {
  "depth": 0,
  "id": "wie-wird-die-benutzerauthentifizierung-gehandhabt%3F",
  "label": "Wie wird die Benutzerauthentifizierung gehandhabt?",
  "parent": null
}, {
  "depth": 0,
  "id": "werden-calling-erweiterungen-in-einem-cdn-gehostet%3F",
  "label": "Werden Calling-Erweiterungen in einem CDN gehostet?",
  "parent": null
}, {
  "depth": 0,
  "id": "wann-sollte-eine-interaktion-erstellt-und-wann-aktualisiert-werden%3F",
  "label": "Wann sollte eine Interaktion erstellt und wann aktualisiert werden?",
  "parent": null
}, {
  "depth": 0,
  "id": "welche-bereiche-sind-als-teil-der-integration-erforderlich%3F",
  "label": "Welche Bereiche sind als Teil der Integration erforderlich?",
  "parent": null
}, {
  "depth": 0,
  "id": "kann-diese-funktionalit%C3%A4t-zu-einer-bereits-bestehenden-anwendung-im-marketplace-hinzugef%C3%BCgt-werden-oder-muss-ich-eine-neue-app-erstellen%3F",
  "label": "Kann diese Funktionalität zu einer bereits bestehenden Anwendung im Marketplace hinzugefügt werden oder muss ich eine neue App erstellen?",
  "parent": null
}, {
  "depth": 0,
  "id": "kann-ich-meine-vorhandene-softphone-anwendung-in-das-sdk-integrieren%3F",
  "label": "Kann ich meine vorhandene Softphone-Anwendung in das SDK integrieren?",
  "parent": null
}, {
  "depth": 0,
  "id": "k%C3%B6nnen-benutzer-mehrere-integrationen-gleichzeitig-verwenden%3F",
  "label": "Können Benutzer mehrere Integrationen gleichzeitig verwenden?",
  "parent": null
}, {
  "depth": 0,
  "id": "k%C3%B6nnen-benutzer-kostenloser-versionen-app-integrationen-installieren%3F",
  "label": "Können Benutzer kostenloser Versionen App-Integrationen installieren?",
  "parent": null
}, {
  "depth": 0,
  "id": "wenn-ein-benutzer-meine-app-bereits-installiert-hat%2C-wird-die-integration-automatisch-angezeigt%3F",
  "label": "Wenn ein Benutzer meine App bereits installiert hat, wird die Integration automatisch angezeigt?",
  "parent": null
}, {
  "depth": 0,
  "id": "kann-jeder-benutzer-eine-app-installieren-oder-deinstallieren%3F",
  "label": "Kann jeder Benutzer eine App installieren oder deinstallieren?",
  "parent": null
}, {
  "depth": 0,
  "id": "kann-ich-eine-benutzerdefinierte-anrufeigenschaft-erstellen%3F",
  "label": "Kann ich eine benutzerdefinierte Anrufeigenschaft erstellen?",
  "parent": null
}, {
  "depth": 0,
  "id": "kann-ich-einen-anruf-von-einem-benutzerdefinierten-objekt-aus-t%C3%A4tigen%3F",
  "label": "Kann ich einen Anruf von einem benutzerdefinierten Objekt aus tätigen?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      ul: "ul",
      h3: "h3",
      code: "code",
      pre: "pre",
      img: "img",
      br: "br",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "SDK für Calling-Erweiterungen"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Unsere Anruf-App-Partner müssen Anrufinteraktionen nicht mehr manuell erstellen und aktualisieren; HubSpot erledigt dies für sie. Erfahren Sie dazu ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "hier"
        }), " mehr."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/calling-extensions-sdk",
        children: "SDK für Calling-Erweiterungen"
      }), " ermöglicht die Verwendung einer benutzerdefinierten Anrufoption für HubSpot-Benutzer direkt aus einem Datensatz im CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine Calling-Erweiterung besteht aus drei Hauptelementen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk",
          children: "SDK für Calling-Erweiterungen"
        }), ", ein JavaScript-SDK, das die Kommunikation zwischen Ihrer App und HubSpot ermöglicht."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anrufeinstellungen-Endpunkte"
        }), ", die verwendet werden, um die Anrufeinstellungen für Ihre App festzulegen. Jeder HubSpot-Account, der mit Ihrer App verknüpft wird, verwendet diese Einstellungen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "calling iframe"
        }), ", in dem Ihre App für HubSpot-Benutzer angezeigt wird und mit den Anrufeinstellungen konfiguriert wird."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen zum In-App-Calling-Feature finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool#call-from-your-phone",
        children: "in diesem Wissensdatenbankartikel"
      }), ". Sobald Ihre App mit Calling-Erweiterung mit HubSpot verknüpft ist, wird sie als Option in der Anrufumschaltung angezeigt, wenn ein Benutzer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
        children: "einen Anruf über HubSpot tätigt"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie über keine App verfügen, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#app-developer-accounts",
        children: "eine über Ihren HubSpot-Entwickler-Account erstellen"
      }), ". Wenn Sie noch keinen HubSpot-Entwickler-Account haben, können Sie sich ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "hier"
      }), " für einen Account registrieren."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Derzeit werden nur ausgehende Anrufe unterstützt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Demo-Anruf-App ausführen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können das SDK für Calling-Erweiterungen mit zwei verschiedenen Demo-Apps testen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-minimal-js",
          children: "demo-minimal-js "
        }), "bietet eine minimale Implementierung des SDK mit JavaScript, HTML und CSS. Zeigen Sie an, wie das SDK in", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/project-demo-v1/demos/demo-minimal-js/index.js",
          children: " index.js"
        }), " instanziiert wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-react-ts",
          children: "demo-react-ts "
        }), "bietet eine praktische Implementierung des SDK mit React, TypeScript und Styled Components, um als Blaupause für Ihre App zu fungieren. Zeigen Sie an, wie das SDK in  ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/master/demos/demo-react-ts/src/hooks/useCti.ts",
          children: "useCti.ts"
        }), " instanziiert wird."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Diese Demo-Apps sind keine voll funktionsfähigen Anruf-Apps und verwenden Scheindaten, um ein realistischeres Erlebnis zu bieten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die Demo-Anruf-App installieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können die Demo-Apps mit oder ohne Installation ausführen. So installieren Sie die Demo in Ihrer lokalen Umgebung:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Installieren Sie", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://nodejs.org/en",
            children: " Node.js "
          }), "in Ihrer Umgebung."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klonen Sie die ZIP-Datei dieses Repositorys, spalten Sie sie ab oder ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/calling-extensions-sdk/archive/refs/heads/master.zip",
            children: "laden Sie sie herunter"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Öffnen Sie Ihren Terminal und wechseln Sie zum Stammverzeichnis des Projekts."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Führen Sie einen der folgenden Befehle aus:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-minimal-js && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Für ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), ":"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-react-ts && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese wechseln zum gewünschten Demo-Verzeichnis, installieren die für das Projekt erforderlichen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), "-Abhängigkeiten mithilfe der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.npmjs.com/cli/v9",
        children: "npm CLI"
      }), " und starten die App."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Der Befehl ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm start"
        }), " söffnet automatisch eine neue Registerkarte in Ihrem Browser unter ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://localhost:9025/",
          children: "https://localhost:9025/"
        }), ", und Sie müssen möglicherweise eine Warnung vom Typ „Ihre Verbindung ist nicht sicher“ umgehen, um auf die Anwendung zugreifen zu können."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die Demo-Anruf-App von HubSpot aus starten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie zu Ihren Datensätzen:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Kontakte:"
            }), " Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Kontakte > Kontakte"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Unternehmen:"
            }), " Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Kontakte > Unternehmen"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Öffnen Sie die Entwicklerkonsole Ihres Browsers und führen Sie den folgenden Befehl aus:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wenn Sie die Installationsschritte abgeschlossen haben, für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-react-ts"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'local');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie die Installationsschritte übersprungen haben:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), ":"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app:js');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Für ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), ":"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Aktualisieren Sie die Seite und klicken Sie auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Telefon"
          }), "-Symbol in der linken Seitenleiste. Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Anruf von"
          }), " und wählen Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen"
          }), " der Demo-App aus Schritt 2 aus (z. B. Demo-App Local, Demo-App JS, Demo-App React)."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/call-app-in-record.png",
            alt: "call-app-in-record"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Anrufen"
          }), ", um zu sehen, wie sich die Demo-App über das SDK für Calling-Erweiterungen in HubSpot integrieren lässt. Sie können auch die Events anzeigen, die in der Entwicklerkonsole Ihres Browsers protokolliert werden."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/calling-sdk-in-app.png",
        alt: "calling-sdk-in-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Das SDK für Calling-Erweiterungen in Ihrer Anruf-App installieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["So fügen Sie das SDK für Calling-Erweiterungen als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), "-Abhängigkeit zu Ihrer Anruf-App hinzu:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Führen Sie für npm Folgendes aus:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i --save @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Führen Sie für yarn Folgendes aus:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "yarn add @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SDK für Calling-Erweiterungen verwenden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das SDK für Calling-Erweiterungen stellt eine einfache API für HubSpot und eine Anruf-App zum Austausch von Nachrichten bereit. Die Nachrichten werden über Methoden gesendet, die vom SDK bereitgestellt werden, und über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), " empfangen. Eine vollständige Liste der verfügbaren Events finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events",
        children: "Abschnitt „Events“"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden werden die Events beschrieben:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nummer wählen:"
        }), " HubSpot sendet das Nummer-wählen-Event."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ausgehender Anruf gestartet:"
        }), " Die App benachrichtigt HubSpot, wenn der Anruf gestartet wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Interaktion erstellen:"
        }), " HubSpot erstellt", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: " eine Anrufinteraktion "
        }), "mit minimalen Informationen, wenn dies von der App angefordert wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Interaktion erstellt:"
        }), " HubSpot hat eine Interaktion erstellt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Interaktions-ID an App gesendet:"
        }), " HubSpot sendet die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), " an die App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anruf beendet:"
        }), " Die App benachrichtigt, wenn der Anruf beendet ist."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anruf abgeschlossen:"
        }), " Die App benachrichtigt, wenn der Benutzer mit dem App-Nutzerlebnis fertig ist."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Interaktion aktualisieren:"
        }), " Die App ruft die Interaktion anhand der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), " ab und führt dann eine Zusammenführung durch und aktualisiert die Interaktion mit zusätzlichen Anrufdetails. Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls#update-calls",
          children: "Aktualisieren einer Anrufinteraktion über die API"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "über das SDK"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erstellen Sie zunächst eine Instanz des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CallingExtensions"
      }), "-Objekts. Sie können das Verhalten Ihrer Erweiterung definieren, indem Sie das Objekt einer Option angeben, wenn Sie Ihre Erweiterungeninstanz erstellen. Das Objekt dieser Option stellt ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), "-Feld bereit, in dem Sie das Verhalten Ihrer Erweiterung angeben können. Der folgende Code-Block veranschaulicht die verfügbaren Optionen und Event-Handler, die Sie definieren können:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import CallingExtensions from \"@hubspot/calling-extensions-sdk\";\n\nconst options = {\n  /** @property {boolean} debugMode - Whether to log various inbound/outbound debug messages to the console. If false, console.debug will be used instead of console.log */\n  debugMode: boolean,\n  // eventHandlers handle inbound messages\n  eventHandlers: {\n    onReady: () => {\n      /* HubSpot is ready to receive messages. */\n    },\n    onDialNumber: event => {\n      /* HubSpot sends a dial number from the contact */\n    },\n    onCreateEngagementSucceeded: event => {\n      /* HubSpot has created an engagement for this call. */\n    }\n    onEngagementCreatedFailed: event => {\n      /* HubSpot has failed to create an engagement for this call. */\n    }\n    onUpdateEngagementSucceeded: event => {\n      /* HubSpot has updated an engagement for this call. */\n    },\n    onUpdateEngagementFailed: event => {\n      /* HubSpot has failed to update an engagement for this call. */\n    }\n    onVisibilityChanged: event => {\n      /* Call widget's visibility is changed. */\n    }\n  }\n};\n\nconst extensions = new CallingExtensions(options);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre App testen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um den Calling-Erweiterungen-iFrame für Endbenutzer zu starten, benötigt HubSpot die folgenden iFrame-Parameter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n   name: string /* The name of your calling app to display to users. */,\n   url: string  /* The URL of your calling app, built with the Calling Extensions SDK */,\n   width: number /* The iFrame's width */,\n   height: number /* The iFrame's height */,\n   isReady: boolean /* Whether the widget is ready for production (defaults to true) */,\n   supportsCustomObjects : true /* Whether calls can be placed from a custom object */\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mithilfe des Anrufeinstellungen-Endpunkts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Senden Sie mit Ihrem API-Tool (z. B. Postman) die folgende Payload an die Einstellungen-API von HubSpot. Stellen Sie sicher, dass Sie die APP_ID Ihrer Anruf-App und Ihrer App ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), " abrufen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "isReady"
        }), "-Flag zeigt an, ob die App für die Produktion bereit ist. Dieses Flag sollte während des Testens auf false festlegt sein."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":false}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ihre Erweiterungseinstellungen mit localStorage überschreiben"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können jede Ihrer Erweiterungseinstellungen zu Testzwecken außer Kraft setzen. Öffnen Sie Ihre Browser-Entwicklerkonsole über eine HubSpot-Registerkarte, bearbeiten Sie die folgenden Einstellungen und führen Sie den Befehl aus:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const myExtensionSettings = {\n  isReady: true,\n  name: 'My app name',\n  url: 'My local/qa/prod URL',\n};\n\nlocalStorage.setItem(\n  'LocalSettings:Calling:CallingExtensions',\n  JSON.stringify(myExtensionSettings)\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre App für die Produktion vorbereiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie den POST-Endpunkt bereits beim Testen Ihrer App verwendet haben, können Sie den PATCH-Endpunkt verwenden, um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady"
      }), " in true zu ändern. Senden Sie ansonsten diese Payload mithilfe Ihres API-Tools (z. B. Postman) an die Einstellungen-API von HubSpot. Stellen Sie sicher, dass Sie die APP_ID Ihrer Anruf-App und Ihrer App ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), " abrufen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":true}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ihre Anruf-App im HubSpot Marketplace veröffentlichen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der letzte Schritt nach der Einrichtung Ihrer App besteht darin, Ihre Anruf-App im HubSpot-Marketplace zu listen. Weitere Details finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "hier"
      }), ". Sie können sie auch nicht im Marketplace auflisten, wenn diese Anwendung nur für den internen Gebrauch bestimmt ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Events"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verfügbare Anruf-Events:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Senden von Nachrichten an HubSpot", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#demo-minimal-js"
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "#yarn"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#initialized",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "initialized"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedIn",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedIn"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedOut"
              })
            }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut"
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#outgoingCall",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "outgoingCall"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callAnswered",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callAnswered"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callEnded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callEnded"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callCompleted"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#sendError",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "sendError"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#resizeWidget",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "resizeWidget"
              })
            }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Nachrichten von HubSpot empfangen:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onReady",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onReady"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onDial",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onDialNumber"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onEngagementCreated"
              })
            }), " (veraltet)"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onCreateEngagementSucceeded"
              })
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onUpdateEngagementFailed"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onVisibilityChanged"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#defaultEventHandler",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "defaultEventHandler"
              })
            })]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Senden von Nachrichten an HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), "-Objekt stellt die folgenden Event-Handler bereit, die Sie aufrufen können, um Nachrichten an HubSpot zu senden oder ein anderes zugeordnetes Verhalten anzugeben. Siehe folgende Beispiele."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "initialized"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Nachricht, die anzeigt, dass das Softphone für die Interaktion bereit ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const payload = {\n  isLoggedIn: boolean,\n};\n\nextensions.initialized(payload);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isLoggedIn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Überprüft, ob ein Benutzer angemeldet ist oder nicht."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedIn"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Nachricht, dass sich der Benutzer angemeldet hat."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// This message is only needed when user isn't logged in when initialized\nextensions.userLoggedIn();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedOut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Meldung, dass sich der Benutzer abgemeldet hat."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.userLoggedOut();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "outgoingCall"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Nachricht, um HubSpot zu informieren, dass ein ausgehender Anruf gestartet wurde."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string /** @deprecated Use toNumber instead **/,\n  callStartTime: number,\n  createEngagement: true,\n  toNumber: string,\n  fromNumber: string,\n};\nextensions.outgoingCall(callInfo);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callStartTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Startzeit des Aufrufs in Millisekunden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createEngagement"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ob HubSpot eine Interaktion für diesen Anruf erstellen soll oder nicht. Wenn true, antwortet HubSpot mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: "onCreateEngagementSucceeded"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: "onCreateEngagementFailed"
            }), ".", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Telefonnummer des Empfängers."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Nummer des Anrufers. Erforderlicher Parameter"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callAnswered"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Nachricht, um HubSpot zu informieren, dass ein ausgehender Anruf angenommen wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.callAnswered();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callEnded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Nachricht, um HubSpot zu informieren, dass der Anruf beendet ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call ended event, the user can navigate away, can close the call widget.\nextensions.callEnded({\n  engagementId: number,\n  callEndStatus: EndStatus,\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine von HubSpot erstellte Interaktion-ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callEndStatus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufzählung"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Status des Anrufs nach Beendigung des Anrufs. Verfügbare Status:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_COMPLETED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_FAILED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_CANCELED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_BUSY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_NO_ANSWER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_REJECTED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_MISSED"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callCompleted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sendet eine Nachricht, um HubSpot zu informieren, dass der Anruf abgeschlossen ist. Für Interaktionseigenschaften ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/calls",
        children: "ist HubSpot zuständig"
      }), ", d h., sie müssen nicht mehr manuell erstellt oder aktualisiert werden (siehe Hervorhebung)."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hideWidget"
        }), "-Eigenschaft wird ignoriert, wenn sich der Benutzer in einer Aufgabenwarteschlange mit dem Aufgabentyp ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Call"
        }), " befindet."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call completed event, HubSpot will\n//   1) insert the engagement into the timeline\n//   2) set the default associations on the engagement\n//   3) closes the widget unless `hideWidget` is set to false.\n//   4) update the engagement with any engagement properties\nconst data = {\n  engagementId: number,\n  hideWidget: boolean,\n  engagementProperties: { [key: string]: string },\n  externalCallId: number,\n}\nextensions.callCompleted(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine von HubSpot erstellte Interaktion-ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hideWidget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, ob das Widget ausgeblendet werden soll, wenn der Anruf beendet wird. Optionaler Parameter. Die Standardeinstellung ist true."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Melden Sie sich per Opt-in für HubSpot-Interaktionen an, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/calls#properties",
              children: "Eigenschaften hinzufügen"
            }), ". Bewirkt, dass HubSpot mit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementSucceeded",
              children: "onUpdateEngagementSucceeded"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementFailed",
              children: "onUpdateEngagementFailed"
            }), " antwortet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalCallId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Anruf-App hat eine Anruf-ID erstellt. Wird verwendet, um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/third-party-calling",
              children: "Anrufe im Helpdesk zu aktivieren"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendError"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Nachricht, um HubSpot zu informieren, dass in der Anruf-App ein Fehler aufgetreten ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the sendError event, HubSpot will display an alert popup to the user with the error message provided.\nconst data = {\n  message: string,\n};\nextensions.sendError(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fehlermeldung, die im Warnungs-Pop-up angezeigt wird."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "resizeWidget"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sendet eine Nachricht, um HubSpot darüber zu informieren, dass die Größe der Anruf-App geändert werden muss."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the resizeWidget event, HubSpot will use the provided height and width to resize the call widget.\nconst data = {\n  height: number,\n  width: number,\n};\nextensions.resizeWidget(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewünschte Höhe des Anruf-Widgets."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gewünschte Breite der Anruf-Widgets."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nachrichten von HubSpot empfangen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), "-Objekt stellt die folgenden Event-Handler bereit, die Sie aufrufen können, um Nachrichten in HubSpot zu empfangen oder um ein anderes zugeordnetes Verhalten anzugeben. Siehe folgende Beispiele."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onReady"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachricht, die angibt, dass HubSpot bereit ist, Nachrichten zu empfangen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example snippet for handling onReady event\nonReady() {\n    extensions.initialized(payload);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onDialNumber"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dieses Event wird ausgelöst, wenn ein Benutzer in HubSpot einen ausgehenden Anruf auslöst. Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onDialNumber"
      }), "-Event stellt eine Payload bereit, die alle relevanten Felder enthält, die dem Anruf zugeordnet sind. Diese Felder sind in der folgenden Tabelle aufgeführt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onDialNumber(data) {\n  const {\n    phoneNumber: string,\n    ownerId: number,\n    subjectId: number,\n    objectId: number,\n    objectType: CONTACT | COMPANY,\n    portalId: number,\n    countryCode: string,\n    calleeInfo {\n        calleeId: number,\n        calleeObjectTypeId: string,\n    },\n    startTimestamp: number,\n    toPhoneNumberSrc: string,\n   } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Eigenschaft | Typ | Beschreibung |\n| --- | --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "phoneNumber"
      }), " | Zeichenfolge | Die Telefonnummer der Person, die der HubSpot-Benutzer angerufen hat. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ownerId"
      }), " | Zeichenfolge | Nummer | Die ID des angemeldeten Benutzers (in HubSpot). |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subjectId"
      }), " | Zahl | Die ID des Betreffs. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " | Zahl | Objekttyp der Telefonnummer. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " | Zeichenfolge | Der Objekttyp, der der gewählten Telefonnummer zugeordnet ist (z. B. die Telefonnummer des Kontakts oder Unternehmens). Die möglichen Werte hier sind ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"CONTACT\""
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"COMPANY\""
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " | Zahl | Die ID des HubSpot-Portals. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "countryCode"
      }), " | Zeichenfolge | Die Landesvorwahl der Telefonnummer. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calleeInfo"
      }), " | Array | Informationen über den Angerufenen. Diese sollten Folgendes enthalten:", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeID: number"
          })
        }), (0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeObjectTypeId: string"
          })
        })]
      }), " |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "startTimestamp"
      }), " | Zahl | Zeitstempel des Beginns des Anrufs. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toPhoneNumberSrc"
      }), " | Zeichenfolge | : Der Name der Telefonnummern' -", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/create-and-edit-properties",
        children: "Eigenschaf in HubSpot"
      }), ". Bei der Eigenschaft kann es sich um einen Standardwert für eine Eigenschaft oder eine benutzerdefinierte Eigenschaft handeln. Ein Kontakt kann beispielsweise über drei Kontakttelefonnummern verfügen, von denen eine mit ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Büro"
      }), ", eine mit ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Privat"
      }), " und die andere mit ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Mobil"
      }), " gekennzeichnet ist. |"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onEngagementCreated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veraltet. Verwenden Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#onCreateEngagementSucceeded",
        children: "onCreateEngagementSucceeded"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/** @deprecated Use onCreateEngagementSucceeded instead **/\nonEngagementCreated(data) {\n    const {\n      engagementId: number,\n    } = data;\n      ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Eigenschaft"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine von HubSpot erstellte Interaktion-ID."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementSucceeded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot sendet eine Nachricht, um den Anruf-App-Partner zu informieren, dass die Aktualisierung der Interaktion erfolgreich war."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementSucceeded: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementFailed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot sendet eine Nachricht, um den Anruf-App-Partner zu benachrichtigen, dass die Erstellung der Interaktion fehlgeschlagen ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementFailed: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onVisibilityChanged"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachricht, die angibt, ob der Benutzer die Anruf-App minimiert oder ausgeblendet hat."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onVisibilityChanged(data) {\n    const { isMinimized, isHidden } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "defaultEventHandler"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Standard-Handler für Events."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "defaultEventHandler(event) {\n   console.info(\"Event received. Do you need to handle it?\", event);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anruf-SDK | Häufig gestellte Fragen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Wie wird die Benutzerauthentifizierung gehandhabt?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Anruf-App sollte die Authentifizierung handhaben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Werden Calling-Erweiterungen in einem CDN gehostet?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ja. Sie können das SDK für Calling-Erweiterungen über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.jsdelivr.com/",
        children: "jsDeliver"
      }), " installieren. Um beispielsweise calling-extensions-sdk @0.2.2 zu installieren, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js",
        children: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Wann sollte eine Interaktion erstellt und wann aktualisiert werden?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Benutzer kann einen Anruf innerhalb der HubSpot-Benutzeroberfläche und außerhalb der HubSpot-Benutzeroberfläche initiieren (z. B. Mobile-App, weitergeleitete Nummer usw.). Wenn ein Anruf von der HubSpot-Benutzeroberfläche aus initiiert wird, erstellt HubSpot eine Anrufinteraktion und sendet die Interaktion an die Anruf-App. Sobald der Anruf beendet ist, kann die Anruf-App diese Interaktion mit zusätzlichen Anrufdetails aktualisieren. Wenn ein Anruf außerhalb der HubSpot-Benutzeroberfläche initiiert wird, sollte die App die Anrufinteraktion erstellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Welche Bereiche sind als Teil der Integration erforderlich?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Kontakte-hinzufügen- und Chronik-Bereiche sind erforderlich. Diese Bereiche stellen sicher, dass Ihre Anwendung Zugriff auf Kontakte hat und Anrufinteraktionen im CRM erstellen und aktualisieren kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kann diese Funktionalität zu einer bereits bestehenden Anwendung im Marketplace hinzugefügt werden oder muss ich eine neue App erstellen?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie bereits über eine bestehende App verfügen, die den Anwendungsfall „Anrufe“ bedient, können Sie diese Funktion direkt zu Ihrer bestehenden App hinzufügen. Alle Kunden, die Ihre App bereits installiert haben, erhalten Zugriff auf diese neue Funktion, ohne die App erneut installieren zu müssen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kann ich meine vorhandene Softphone-Anwendung in das SDK integrieren?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ja, die Integration Ihrer vorhandenen Softphone-Anwendung sollte sehr einfach sein. Befolgen Sie einfach die Schritte in der obigen Dokumentation, um Ihre Anwendung einsatzbereit zu machen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Können Benutzer mehrere Integrationen gleichzeitig verwenden?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ja, Benutzer können mehrere Anrufintegrationen von Drittanbietern gleichzeitig verwenden. Sie können mit dem Provider-Switcher, der nach dem Klicken auf die Anruftaste angezeigt wird, nahtlos zwischen den Anbietern wechseln."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Können Benutzer kostenloser Versionen App-Integrationen installieren?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ja, alle Benutzer können die App installieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Wenn ein Benutzer meine App bereits installiert hat, wird die Integration automatisch angezeigt?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ja, wenn ein Benutzer Ihre App bereits installiert hat und Sie dieselbe App mit den Calling-Erweiterungen aktualisieren, wird die Integration automatisch angezeigt. Derzeit gibt es keine Möglichkeit für den Entwickler, die Anruf-App nur für eine Teilmenge von Kunden zu aktivieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kann jeder Benutzer eine App installieren oder deinstallieren?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nein, nur Benutzer mit den erforderlichen Berechtigungen können eine App installieren und deinstallieren. Erfahren Sie mehr darüber, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/edit-user-permissions",
        children: "die Berechtigungen eines Benutzers überprüfen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kann ich eine benutzerdefinierte Anrufeigenschaft erstellen?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ja, Sie können eine benutzerdefinierte Anrufeigenschaft mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Eigenschaften-API"
      }), " erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kann ich einen Anruf von einem benutzerdefinierten Objekt aus tätigen?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ja, Anrufintegrationen können Anrufe von benutzerdefinierten Objekten aus tätigen, solange sie nur das SDK zum Erstellen des Anrufs verwenden. Bei jeder Integration muss überprüft werden, ob sie nur das Anruf-SDK zum Erstellen von Anrufen und zum Benachrichtigen von HubSpot im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), "-Event verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Stellen Sie zunächst sicher, dass die Integration das Anruf-SDK verwendet, um Interaktionen im outgoingCall-Event zu erstellen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "outgoingCall({ createEngagement: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createEngagement"
      }), " true ist, erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-your-app-ready-for-production",
        children: "hier"
      }), ", wie Sie Ihre App-Informationen aktualisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hier ist das Beispiel für das gesamte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), "-Event:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string, // optional unless call is initiated by the widget\n  createEngagement: true // whether HubSpot should create an engagement for this call\n  callStartTime: number // optional unless call is initiated by the widget\n};\nextensions.outgoingCall(callInfo);\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}