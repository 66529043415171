/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

import I18n from 'I18n';

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getAvailableKey = (preferredKey, formData) => {
  let index = 0;
  let newKey = preferredKey;
  while (formData.hasOwnProperty(newKey)) {
    newKey = `${preferredKey}-${++index}`;
  }
  return newKey;
};

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getDefaultValue = type => {
  switch (type) {
    case 'string':
      return I18n.text('developer-docs-shared-components.params.body.additionalProperties.defaultStringValue');
    case 'array':
      return [];
    case 'boolean':
      return false;
    case 'null':
      return null;
    case 'number':
      return 0;
    case 'object':
      return {};
    default:
      return I18n.text('developer-docs-shared-components.params.body.additionalProperties.defaultStringValue');
  }
};