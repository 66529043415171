"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 142141704227;
const slug = exports.slug = 'guides/cms/content/modules/build-modules-and-partials-with-react';
const title = exports.title = 'Build CMS modules with React';
const name = exports.name = 'Build CMS modules with React';
const metaDescription = exports.metaDescription = 'Learn how to build modules using React.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "benefits-of-building-with-react-vs.-hubl",
  "label": "Benefits of building with React vs. HubL",
  "parent": null
}, {
  "depth": 0,
  "id": "building-and-deploying",
  "label": "Building and deploying",
  "parent": null
}, {
  "depth": 0,
  "id": "limitations",
  "label": "Limitations",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Build CMS modules with React"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Rather than using HubL to build modules, you can use JavaScript and React instead. Then, after creating a JavaScript module, it can be added to a page either by hardcoding or via the drag and drop editor in HubSpot. In addition to stitching server-rendered React components into the HTML generated by HubL, JavaScript modules and partials support client-side interactivity with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.patterns.dev/posts/islands-architecture",
        children: "islands"
      }), ". This enables you to have more precise control over where and when JavaScript is shipped and run in the browser."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Get started with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-react/",
        children: "CMS React documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Benefits of building with React vs. HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "At a high level, building with React comes with benefits including component composability, code reuse, broader community resources, and real access to JavaScript on the server at render time."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rendering React on the server means that there's less of a divide between your code that serves the initial page HTML and your interactive browser code. In contrast, creating complex and interactive pages with HubL can lead to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "An increase of client-side JavaScript that slows down the page until it's all been downloaded and executed."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Needing to replicate or maintain UI logic across HubL and JavaScript in order to have server HTML that is immediately visible and interactive."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["But by building with JavaScript, you can code complex interactive components that either share code or are directly rendered on the server. When paired with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.patterns.dev/posts/islands-architecture",
        children: "islands"
      }), " model, this can enable you to more easily code web experiences that have good Core Web Vital scores (LCP, FID, CLS) even as complexity increases."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Additionally, by building on top of JavaScript and the open source framework of React, you can use the wealth of tooling, third-party libraries via npm, example code, and more that are available in the broader ecosystem. For example, since JavaScript modules and partials are built on top of Vite, you'll get things like ESM, TypeScript, JSX, CSS modules, and tree-shaking out of the box."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Building and deploying"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS React is built on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/create-a-project",
        children: "developer projects framework"
      }), ", which uses a local build and deploy process. A project can be uploaded for build and deploy using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project upload"
      }), " command. This command can be run in the root of your project, or you can include a directory path in the command."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// In root directory\nhs project upload\n\n// Specified path\nhs project upload path/to/project\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The upload command will kick off the project build. By default, projects are configured to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/developer-projects/create-a-project#view-the-project-in-hubspot",
        children: "auto-deploy"
      }), " after successful build. You can turn this setting off in HubSpot after your first successful deploy. If you turn off auto-deploy, you can manually deploy your project after successful build by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs project deploy"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["For more information about ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project"
        }), " CLI commands, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/project-cli-commands",
          children: "documentation"
        }), " or run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project --help"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following features are not currently available for React-based modules:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Importing JavaScript modules into JavaScript partials."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Online code editing within the design manager."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Some HubL features, such as certain tags and filters, may not be readily available in JavaScript components."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}