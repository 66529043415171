"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38575989237;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-completions';
const title = exports.title = 'カスタムイベント完了を送信する';
const name = exports.name = 'カスタムイベント完了を送信する';
const metaDescription = exports.metaDescription = 'カスタムイベントを使用すると、JavaScriptまたはHTTP APIを介して高度なアクティビティーを追跡できます。イベントの詳細を取得するには、イベントAPIを使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E5%AE%9A%E7%BE%A9",
  "label": "イベントの定義",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B",
  "label": "イベントデータを送信する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "イベントデータを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "イベントプロパティー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%88%E3%83%AA%E3%83%93%E3%83%A5%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88",
  "label": "アトリビューションレポート",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "アセットタイプ",
  "parent": "%E3%82%A2%E3%83%88%E3%83%AA%E3%83%93%E3%83%A5%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%E3%82%BF%E3%82%A4%E3%83%88%E3%83%AB",
  "label": "アセットタイトル",
  "parent": "%E3%82%A2%E3%83%88%E3%83%AA%E3%83%93%E3%83%A5%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%B3%E3%82%BF%E3%83%A9%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%BD%E3%83%BC%E3%82%B9",
  "label": "インタラクションソース",
  "parent": "%E3%82%A2%E3%83%88%E3%83%AA%E3%83%93%E3%83%A5%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      em: "em",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "カスタムイベント完了を送信する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタムイベントは、イベントの詳細をイベントプロパティーに格納するアカウント定義のイベントです。HubSpotで作成できるカスタムイベントには、次の3つのタイプがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "クリックされた要素"
        }), "および", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "訪問されたURL"
        }), "イベントは、トラッキングコードによって自動的にデータが設定されるカスタムイベントです。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code#events-js-api",
          children: "trackCustomEvent"
        }), "関数を使用してトラッキングコードを更新することで、これらのイベントをさらにカスタマイズできます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
          children: "手動でトラッキングされるイベント"
        }), "とは、HubSpotや連携によって自動的には取得されない、貴社に固有のカスタムイベントです。このAPIを介して、これらのイベントに手動でデータを送信できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここでは、手動でトラッキングされるカスタムイベントを作成する方法、APIを介してイベントデータを送信する方法、取り込まれたイベントデータを使用する方法を説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントの定義"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベント完了データをHubSpotに送信するには、まず、イベントのメタデータ、CRMオブジェクト、関連付け、プロパティーなど、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "イベント自体を定義する"
      }), "必要があります。イベントを定義するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions",
        children: "カスタムイベント定義API"
      }), "を使用するか、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketing Hub"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " 配信登録をご利用の場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
        children: "HubSpotでイベントを作成"
      }), "することができます。イベントを作成するとHubSpotによって一連の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "既定のイベントプロパティー"
      }), "が追加され、ユーザーはそこにイベントデータを格納できます。イベントの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "追加プロパティーを作成する"
      }), "こともできます。イベントのプロパティーは、いつでも作成または編集できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントを設定したら、APIを介してデータを送信できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントデータを送信する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントデータをHubSpotに送信するには、リクエスト本文にイベントデータを指定して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/events/v3/send"
      }), "に送信します。イベントデータを送信する前に、以下の制限を確認してください。これらの制限を超えるとエラーが発生します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST to https://api.hubspot.com/events/v3/send\n{\n  \"eventName\": \"pe1234567_login_event\",\n  \"objectId\": \"608051\",\n  \"occurredAt\": \"2024-06-28T12:09:31Z\",\n  \"properties\": {\n    \"hs_city\": \"Cambridge\",\n    \"hs_country\": \"United States\",\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\",\n    \"hs_touchpoint_source\": \"DIRECT_TRAFFIC\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["イベントの内部名。これを確認するには、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "既存のイベント定義をクエリー"
            }), "するか、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/reports/create-custom-behavioral-events-with-the-code-wizard#find-internal-name",
              children: "HubSpotアプリ内で確認"
            }), "します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["イベントが関連付けられるCRMレコードのID。コンタクトの場合は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utk"
            }), "フィールドを使用して、Eメールアドレスまたは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code",
              children: "HubSpotユーザートークン"
            }), "でコンタクトを識別することもできます。その他の全てのオブジェクトタイプでは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), "が必要です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["HubSpotでは既定で、イベント完了タイムスタンプをリクエストが送信された時間に設定します。イベントの完了時刻を指定するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), "リクエスト本文の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            }), "フィールドにタイムスタンプを含めます（", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_8601",
              children: "ISO 8601形式"
            }), "）。これは、現実のイベントの完了をより正確に繁栄するために、イベントデータの日付をさかのぼって設定する場合に特に役立ちます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["データの送信先となるイベントプロパティー。これには、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
              children: "HubSpotの既定のイベントプロパティー"
            }), "や、イベントに定義した任意の", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
              children: "カスタムプロパティー"
            }), "を含めることができます。既定のイベントプロパティーのほとんどは文字列プロパティーですが、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "イベント定義をクエリー"
            }), "するか、HubSpotのイベントにナビゲートすることで、全てのイベントプロパティーを確認できます。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#event-properties",
              children: "イベントプロパティー"
            }), "の詳細については、以下をご確認ください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "次のいずれかの制限を超えると、リクエストは失敗します。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "プロパティーラベルと内部名は50文字に制限されています。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "URLとリファラーのプロパティーは最大1024文字まで、その他のプロパティーは最大256文字までです。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "各イベント完了には、最大で50個のプロパティーのデータを含めることができます。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "プロパティーの内部名はアルファベットで始まる必要があり、使用できるのは小文字のa～z、数字の0～9、アンダースコアのみです。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "小文字にした後の内部名が同じプロパティーは重複していると見なされ、完了時にはどちらか一方のプロパティーのみが使用されます。HubSpotでは、辞書の順序の昇順で並べ替え、最初の50個のプロパティーのうち、最後に表示されたプロパティーを保持します。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "アカウントごとの一意のイベント定義数の上限は500です。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "1か月あたりのイベント完了数の上限は3,000万です。"
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントデータを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "コンタクトのイベントデータを取得する"
      }), "には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId={CONTACT_ID}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記のリクエストには以下の要素が含まれています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**eventType：**イベントの内部名。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**objectType：**レコードのオブジェクトタイプ。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**objectId：**コンタクトのID。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントプロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントデータはプロパティーに格納されます（", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "既定のイベントプロパティー"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "カスタム定義プロパティー"
      }), "のいずれか）。イベントデータを送信するときに、イベントデータを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "オブジェクトに含めることでプロパティーを更新できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"properties\": {\n    \"property1\": \"string\",\n    \"property2\": \"string\",\n    \"property3\": \"string\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "送信する値は、イベントプロパティーのタイプによって異なります。既定のイベントプロパティーのほとんどは、単行テキスト（文字列）です。ただし、イベントごとに任意のタイプのカスタムプロパティーを作成できます。プロパティー値の書式を設定する際は、以下の表を確認してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティータイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "一連のオプションを表す文字列。複数の値を送信する場合は、値をセミコロンで区切ります。このタイプは、HubSpotでのドロップダウン選択、ラジオ選択、および複数チェックボックスのプロパティーに対応しています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エポックミリ秒またはISO8601形式のタイムスタンプ。このタイプは、HubSpotでの日付ピッカープロパティーに対応しています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プレーンテキスト文字列。文字数の上限は65,536文字です。このタイプは、HubSpotでの単行および複数行のテキストプロパティーに対応しています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "小数第1位までの数値。このタイプは、HubSpotでの数値および計算プロパティーに対応しています。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントの利用可能なプロパティーを表示するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで、［レポート］>［データ管理］>［カスタムイベント］にナビゲートします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["イベントの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［プロパティー］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロパティーテーブルで、プロパティーの名前の下に表示されているプロパティーのタイプを確認します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-event-properties-table.png",
        alt: "custom-event-properties-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アトリビューションレポート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "クリックされた要素"
      }), "イベントや", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "訪問されたURL"
      }), "イベントなどのJavaScriptイベントには、アトリビューションレポート用にアセットタイプとインタラクションデータが自動的に取り込まれます。手動でトラッキングされるイベントに同じデータを含めるには、イベントプロパティーを使用して、リクエスト本文に手動でデータを含める必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/analyze-custom-behavioral-events",
        children: "カスタムイベントの分析"
      }), "に関する詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、アセットタイプとインタラクションソースとして有効な値の説明とリクエストの例を記載します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アセットタイプ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のアセットタイプをカスタム行動イベントのリクエストに結び付けるには、リクエスト本文に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_content_type"
      }), "プロパティーを含めます。以下に例を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "プロパティーを使用することもできます。", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), "および", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "の両方が1つのリクエストに含まれている場合、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), "が", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "の値より優先されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ランディングページやブログ記事などのHubSpotの標準コンテンツタイプは、次の値で表すことができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "値"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "STANDARD_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ウェブサイトページでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ランディングページでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ記事でのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ナレッジベース記事でのインタラクション。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "その他全てのタイプのアセットには、次の値を使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "値"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Facebook広告やGoogle広告などの広告でのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コールでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT_IMPORT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトインポートを介したインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotコミュニケーションに関連するインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CUSTOM_BEHAVIORAL_EVENT_NAME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["カスタムイベントの内部名（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pe123456_manually_tracked_event"
            }), "）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメールでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "外部ページでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "連携を介したインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "マーケティングイベント"
            }), "でのインタラクション。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEDIA_BRIDGE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/cms/media-bridge",
              children: "メディアブリッジ"
            }), "を介したインタラクション。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ミーティングでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1対1のセールスEメールでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SEQUENCE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "シーケンスでのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ソーシャルメディア投稿でのインタラクション。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "上記のいずれのカテゴリーにも該当しないアセットでのインタラクション。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アセットタイトル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムイベントをアセットに結び付けるには、リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_asset_title"
      }), "プロパティーを追加し、アセットの名前を文字列の形式で指定します。以下に例を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_title\": \"Sweepstakes Sign Up\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "インタラクションソース"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタム行動イベントを特定のソースに結び付けるには、リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_touchpoint_source"
      }), "プロパティーを追加し、次のいずれかの値を設定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "値"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースがコミュニケーションであることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DIRECT_TRAFFIC"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースが直接トラフィックであることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_MARKETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースがマーケティングEメールであることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_CRM"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースがHubSpotのCRMであることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースが連携であることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["インタラクションソースが", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "マーケティングイベント"
            }), "であることを示します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OFFLINE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースがオフラインであることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ORGANIC_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースがオーガニック検索であることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER_CAMPAIGNS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースが未分類のキャンペーンからのものであることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースが有料検索広告であることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SOCIAL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースが有料ソーシャル広告であることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "REFERRALS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースがリファーラルであることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースが営業であることを示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_MEDIA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インタラクションソースが（有料ソーシャル広告ではない）ソーシャルメディアであることを示します。"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}