"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 44206971922;
const slug = exports.slug = 'getting-started/slack/code-of-conduct';
const title = exports.title = 'Der Verhaltenskodex für die HubSpot-Entwickler-Community bei Slack (Developer Slack)';
const name = exports.name = 'Verhaltenskodex für Developer Slack';
const metaDescription = exports.metaDescription = 'HubSpots „Developer Slack“-Community ist eine spezielle Community für die engagiertesten Entwickler von HubSpot, die sich im Namen des Supports und der Zusammenarbeit von Entwickler zu Entwickler rund um die CRM-Plattform zusammenfinden.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Zusammenarbeit im Developer-Slack';
const toc = exports.toc = [{
  "depth": 0,
  "id": "pr%C3%A4ambel",
  "label": "Präambel",
  "parent": null
}, {
  "depth": 0,
  "id": "aktualisierungen-dieses-verhaltenskodexes",
  "label": "Aktualisierungen dieses Verhaltenskodexes",
  "parent": null
}, {
  "depth": 0,
  "id": "erwartungen-bez%C3%BCglich-des-hubspot-supports",
  "label": "Erwartungen bezüglich des HubSpot-Supports",
  "parent": null
}, {
  "depth": 0,
  "id": "erwartetes-verhalten",
  "label": "Erwartetes Verhalten",
  "parent": null
}, {
  "depth": 1,
  "id": "seien-sie-dem%C3%BCtig",
  "label": "Seien Sie demütig",
  "parent": "erwartetes-verhalten"
}, {
  "depth": 1,
  "id": "seien-sie-einf%C3%BChlsam",
  "label": "Seien Sie einfühlsam",
  "parent": "erwartetes-verhalten"
}, {
  "depth": 1,
  "id": "seien-sie-anpassungsf%C3%A4hig",
  "label": "Seien Sie anpassungsfähig",
  "parent": "erwartetes-verhalten"
}, {
  "depth": 1,
  "id": "seien-sie-bemerkenswert",
  "label": "Seien Sie bemerkenswert",
  "parent": "erwartetes-verhalten"
}, {
  "depth": 1,
  "id": "seien-sie-transparent",
  "label": "Seien Sie transparent",
  "parent": "erwartetes-verhalten"
}, {
  "depth": 0,
  "id": "inakzeptables-verhalten",
  "label": "Inakzeptables Verhalten",
  "parent": null
}, {
  "depth": 1,
  "id": "androhung-von-gewalt",
  "label": "Androhung von Gewalt",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "pers%C3%B6nliche-angriffe",
  "label": "Persönliche Angriffe",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "falsche-identit%C3%A4t-als-hubspot-mitarbeiter-oder--vertreter",
  "label": "Falsche Identität als HubSpot-Mitarbeiter oder -Vertreter",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "herabw%C3%BCrdigende%2Fsch%C3%A4digende-sprache-und-bel%C3%A4stigung",
  "label": "Herabwürdigende/schädigende Sprache und Belästigung",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "unerw%C3%BCnschte-aufforderung-zur-inanspruchnahme-von-dienstleistungen",
  "label": "Unerwünschte Aufforderung zur Inanspruchnahme von Dienstleistungen",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "missbrauch-von-sprachanrufen%2Fvideoanrufen",
  "label": "Missbrauch von Sprachanrufen/Videoanrufen",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "nicht-offengelegte-verwendung-von-ki-in-fragen%2C-antworten-oder-konversationen",
  "label": "Nicht offengelegte Verwendung von KI in Fragen, Antworten oder Konversationen",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "channel--und-benutzer-spamming",
  "label": "Channel- und Benutzer-Spamming",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 1,
  "id": "beeinflussung-von-inakzeptablen-verhaltensweisen",
  "label": "Beeinflussung von inakzeptablen Verhaltensweisen",
  "parent": "inakzeptables-verhalten"
}, {
  "depth": 0,
  "id": "verwendung-der-hubspot-entwickler-community-developer-slack-f%C3%BCr-private-team-konversationen",
  "label": "Verwendung der HubSpot-Entwickler-Community (Developer Slack) für private Team-Konversationen",
  "parent": null
}, {
  "depth": 0,
  "id": "sprach-%2Fvideoanrufe-%2B-screen-sharing",
  "label": "Sprach-/Videoanrufe + Screen-Sharing",
  "parent": null
}, {
  "depth": 0,
  "id": "slack-%E2%80%9Eapps%E2%80%9C-nutzung",
  "label": "Slack-„Apps“-Nutzung",
  "parent": null
}, {
  "depth": 0,
  "id": "themen-zusammenhalten-nachrichten-in-threads",
  "label": "Themen zusammenhalten (Nachrichten in Threads)",
  "parent": null
}, {
  "depth": 0,
  "id": "gifs-und-emojis",
  "label": "Gifs und Emojis",
  "parent": null
}, {
  "depth": 0,
  "id": "umfragen-und-befragung-von-benutzern-aus-der-%E2%80%9Edeveloper-slack%E2%80%9C-community",
  "label": "Umfragen und Befragung von Benutzern aus der „Developer Slack“-Community",
  "parent": null
}, {
  "depth": 0,
  "id": "meldung-von-verst%C3%B6%C3%9Fen-gegen-den-verhaltenskodex",
  "label": "Meldung von Verstößen gegen den Verhaltenskodex",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Verhaltenskodex für Developer Slack"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Präambel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpots „Developer Slack“-Community ist eine spezielle Community für HubSpot-Entwickler, die sich im Namen des Supports und der Zusammenarbeit von Entwickler zu Entwickler rund um die CRM-Plattform zusammenfinden. Durch die Nutzung unserr „Developer Slack“-Community erklären Sie sich damit einverstanden, den folgenden Verhaltenskodex für Developer Slack („Verhaltenskodex“), ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "HubSpots Richtlinie zur akzeptablen Nutzung"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://slack.com/acceptable-use-policy",
        children: "Slacks Richtlinie über die zulässige Nutzung"
      }), " einzuhalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aktualisierungen dieses Verhaltenskodexes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot behält sich das Recht vor, diesen Verhaltenskodex jederzeit zu aktualisieren. Im Falle von Aktualisierungen informieren wir Benutzer über den #releases-and-updates-Channel in der „Developer Slack“-Community über diese Änderungen. Da dieser Verhaltenskodex jedoch in Erwartung gängiger Szenarien verfasst wurde und wir nicht alle möglichen Szenarien vorhersehen können, kann es sein, dass wir ihn aktualisieren und hier nicht explizit beschriebene Maßnahmen bei Verstößen ergreifen müssen, ohne die Community vorher zu informieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot behält sich das Recht vor, die „Developer Slack“-Community jederzeit herabzustufen oder zu deaktivieren, verpflichtet sich aber, dies mit ausreichender Vorankündigung für die Community zu tun."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erwartungen bezüglich des HubSpot-Supports"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die „Developer Slack“-Community ist kein offizieller Support-Kanal von HubSpot. Diese Slack-Community umfasst zwar HubSpot-Mitarbeiter, die gerne helfen, wenn sie können, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "aber bitte sehen Sie davon ab, HubSpot-Mitarbeiter mit direkten Fragen zu erwähnen oder zu benachrichtigen"
      }), ". Wenn Sie eine Frage haben, bei der Sie direkten Support von HubSpot benötigen, besuchen Sie bitte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://help.hubspot.com/",
        children: "help.hubspot.com"
      }), " oder die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/",
        children: "Community-Foren"
      }), ". HubSpot behält sich das Recht vor, diese Richtlinien zum Wohle der Community durchzusetzen, auszulegen und zu erweitern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erwartetes Verhalten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei HubSpot haben wir keine seitenlangen Richtlinien oder Verfahren. Wir setzen stattdessen auf zwei einfache Prinzipien: gutes Urteilsvermögen und Herz zeigen. Wir ermutigen unsere Communitys, ebenfalls ein gutes Urteilsvermögen an den Tag zu legen und die folgenden Grundwerte von HubSpot zu teilen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seien Sie demütig"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nichts ist perfekt. Seien Sie sich Ihrer selbst bewusst und seien Sie stets respektvoll."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seien Sie einfühlsam"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Haben Sie nicht nur Verständnis für die Kommentare anderer, sondern zeigen Sie auch Mitgefühl und Respekt für alle."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seien Sie anpassungsfähig"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Seien Sie neugierig, und seien Sie immer bereit, sich zu verändern. Verstehen Sie sich als lebenslang Lernenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seien Sie bemerkenswert"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um es mit den Worten des weisen Bill S. Preston, Esquire, zu sagen: „Seid großartig zueinander“. Geben Sie Ihr Wissen weiter und haben Sie keine Angst davor, herauszustechen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seien Sie transparent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Seien Sie offen und ehrlich zu anderen, vor allem aber sich selbst gegenüber."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mehr über unsere Werte erfahren Sie unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/34234/the-hubspot-culture-code-creating-a-company-we-love.aspx",
        children: "HubSpot's Culture Code"
      }), " (HubSpot-Kulturkodex)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inakzeptables Verhalten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Verhaltensweisen gelten als inakzeptabel und werden von keinem Benutzer im Slack-Workspace toleriert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Androhung von Gewalt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Androhung von Gewalt gegen andere Benutzer wird nicht hingenommen. Dazu gehört auch die Aufforderung zur Gewalt gegen einen oder mehrere Benutzer oder das Posten/Drohen, persönliche Informationen anderer Personen zu posten (bekannt als „Doxxing“)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Persönliche Angriffe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es wird immer wieder vorkommen, dass zwei Parteien unterschiedlicher Meinung sind. Das kann zwar frustrierend sein, sollte aber nie Anlass für einen persönlichen Angriff auf jemanden sein. Es ist nicht akzeptabel, Menschen aufgrund ihrer Meinungen, persönlichen Überzeugungen, Ideen oderVorschläge anzugreifen. Bitte verhalten Sie sich zu jeder Zeit professionell und respektvoll."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Falsche Identität als HubSpot-Mitarbeiter oder -Vertreter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alle Benutzer, die sich als HubSpot-Mitarbeiter (oder als Mitarbeiter von HubSpot) ausgeben, werden aus dem Slack-Workspace verbannt. Dies ist eine Null-Toleranz-Regel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Herabwürdigende/schädigende Sprache und Belästigung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verletzende Sprache gegenüber einem Benutzer bezüglich:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hintergrund"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Familie"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Geschlechtsidentität oder Ausdruck der Geschlechtlichkeit"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sexuelle Orientierung"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Race/Ethnische Zugehörigkeit"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Physische Merkmale (Körpergröße, Form, Narben usw.)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alter"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Muttersprache"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fähigkeiten"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Nationale Herkunft"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Standort"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Religion"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Politische Zugehörigkeit"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Andere persönliche Attribute"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ist nicht akzeptabel. Gehen Sie stets mit gutem Urteilsvermögen vor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Unerwünschte Aufforderung zur Inanspruchnahme von Dienstleistungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es ist inakzeptabel, Benutzern direkt Nachrichten zukommen zu lassen, in denen Sie Ihre Dienste anbieten, es sei denn, der Benutzer hat diese Informationen ausdrücklich angefordert. Dazu gehören auch direkte Nachrichten hinsichtlich Beschäftigungsmöglichkeiten oder freiberuflichen Projekten. Wir haben einen #jobs-Channel in unserem Slack-Workspace eingerichtet, in dem diese Art von Nachrichten gepostet werden können, sowie einen #showcase-Channel, in dem Sie die HubSpot-Arbeit, auf die Sie stolz sind, der Community vorstellen können. Dies sind die einzigen beiden Channels, in denen jede Form von Werbung oder Eigenwerbung zulässig ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Missbrauch von Sprachanrufen/Videoanrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack erlaubt den Rückgriff auf Sprach-/Videoaufrufe innerhalb seiner Plattform. Es ist inakzeptabel, diese Funktion zu missbrauchen, indem man Benutzer ohne deren Zustimmung direkt aufruft. Wir verstehen, dass manchmal Fehler passieren und Tasten aus Versehen angeklickt werden. Seien Sie in einem solchen Fall professionell und senden Sie dem Benutzer einfach eine Entschuldigung."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nicht offengelegte Verwendung von KI in Fragen, Antworten oder Konversationen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie ein KI-Tool nutzen, um Inhalte zu generieren, die in der „Developer Slack“-Community verwendet werden, müssen Sie die Nutzung solcher Tools in jedem einzelnen Fall deutlich offenlegen. Sie müssen auch auf die Informationen achten, die Sie einem KI-Tool zur Verfügung stellen, und dürfen keine vertraulichen oder sensiblen Informationen von HubSpot weitergeben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Channel- und Benutzer-Spamming"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die „Developer Slack“-Community hat viele Channels, die genau auf bestimmte Funktionen unserer Plattform und viele Benutzer ausgerichtet sind. Wenn Sie Ihre Nachricht posten, nutzen Sie bitte den entsprechenden Channel für das Veröffentlichen Ihrer Nachricht. Es ist inakzeptabel, Ihre Nachricht einfach über mehrere Channels zu kopieren/einzufügen, um die Sichtbarkeit zu erhöhen. Es ist inakzeptabel, dieselbe Nachricht oder eine ähnliche Nachricht häufig im selben Channel zu posten und/oder Benutzer direkt zu spammen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beeinflussung von inakzeptablen Verhaltensweisen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es ist inakzeptabel, einen oder mehrere Benutzer zur Teilnahme an einem der oben aufgeführten inakzeptablen Verhaltensweisen zu beeinflussen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung der HubSpot-Entwickler-Community (Developer Slack) für private Team-Konversationen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Indem Sie der der HubSpot-Entwickler-Community bei Slack beitreten, verpflichten Sie sich, zur breiteren HubSpot-Entwickler-Community beizutragen. Es werden keine Konversationsthemen abgelehnt (sofern sie diesen Verhaltenskodex befolgen), aber wir bitten Sie, die „Developer Slack“-Community nicht zu nutzen, um private Firmenangelegenheiten über Direct Messaging oder andere Mittel abzuwickeln. Wenn ein solches Verhalten entdeckt wird, behalten wir uns das Recht vor, Sie und Ihr Team aus der „Developer Slack“-Community zu entfernen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sprach-/Videoanrufe + Screen-Sharing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack bietet Benutzern die Möglichkeit, Sprach-/Videoanrufe mit der zusätzlichen Möglichkeit des Screen-Sharing (Bildschirmübertragung) zu nutzen. Seien Sie bei der Nutzung dieser Funktionen rücksichtsvoll und respektvoll gegenüber anderen. Seien Sie vorsichtig, wenn Sie Ihren Bildschirm freigeben, und achten Sie auf andere Fenster, die auf Ihrem Computer geöffnet sein könnten und sensible oder personenbezogene Informationen enthalten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Slack-„Apps“-Nutzung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack bietet die Möglichkeit, Anwendungen zum Workspace hinzuzufügen, um die Funktionalität von Slack zu erweitern. Es ist Benutzern nicht gestattet, eigene Apps im „Developer Slack“-Workspace zu installieren. Alle App-Installationen im Workspace werden von HubSpot übernommen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Themen zusammenhalten (Nachrichten in Threads)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine der großartigen Funktionen von Slack ist die Möglichkeit von Kommentar-Threads. Um Konversationen zusammenzuführen und leicht durchsuchbar zu machen, empfehlen wir die Verwendung dieser Funktion. Es hilft, die Haupt-Channels übersichtlich zu halten und die Themen von Konversationen an einem Ort zusammenzufassen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gifs und Emojis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei der Verwendung von Gifs in den Channels oder in den Kommentaren zu den Threads ist auf den Inhalt/Kontext des Gifs zu achten. Ebenso bietet Slack die Möglichkeit, Emojis zum Ausdruck von Emotionen in Bezug auf Kommentare einzusetzen. Bitte achten Sie auf die Emojis, die Sie verwenden, wenn Sie mit anderen kommunizieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Umfragen und Befragung von Benutzern aus der „Developer Slack“-Community"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der „Developer Slack“-Workspace ist für Konversationen rund um das Entwickeln im Web und das Entwickeln in HubSpot gedacht. Das Erstellen von Umfragen ist erlaubt, und wir stellen die Polly-Slack-Anwendung zur allgemeinen Nutzung in unserem Workspace zur Verfügung."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei der Nutzung von Polly gelten folgende Voraussetzungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die Umfrage muss sich auf das Entwickeln im Web (z. B. CSS- oder JS-Bibliotheken) oder auf das Entwickeln in HubSpot beziehen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "\"„Antworten anzeigen“ muss immer aktiviert bleiben."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Folgendes ist bei der Befragung von Personen aus der „Developer Slack-Community nicht erlaubt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das Erheben von privaten Informationen, einschließlich demografischer, medizinischer oder anderer personenbezogener Informationen von Benutzern.*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das Verlinken zu Umfragen, die auf Websites von Drittanbietern gehostet werden (z. B. Google-Formulare, Survey-Monkey-Formulare oder persönliche Websites).*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Direkte Nachrichten an die Benutzer mit der Aufforderung, an Umfragen teilzunehmen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "* = HubSpot-Mitarbeiter behalten sich das Recht vor, die „Developer Slack-Community im Dienste der Verbesserung des HubSpot-Produkts zu überwachen. HubSpot-Mitarbeiter bemühen sich, deutlich zu machen, dass es sich bei ihrer Umfrage um eine offizielle HubSpot-Umfrage handelt, wenn sie anderswo gehostet wird."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Meldung von Verstößen gegen den Verhaltenskodex"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie einen Verstoß gegen diesen Verhaltenskodex oder irgendetwas, das Ihnen oder anderen in der HubSpot-Entwickler-Community Unbehagen bereitet hat, melden möchten, können Sie sich direkt an einen unserer Admins wenden. Alle aktuellen Admins werden in einem angehefteten Post im Channel #general angezeigt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie es vorziehen, Ihren Verstoß anonym zu melden, oder es vorziehen, keinen DM an einen Admin zu senden, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://form.asana.com/",
        children: "dieses Formular"
      }), " ausfüllen und wir werden sofort benachrichtigt."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}