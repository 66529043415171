"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const slug = exports.slug = 'reference/cms/overview';
const title = exports.title = 'CMS Reference Overview';
const metaDescription = exports.metaDescription = 'Developer reference docs for the HubSpot Content Management System (CMS).';
const position = exports.position = 1;
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS Reference Overview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use the CMS reference docs to quickly find information you might need when building themes, modules, templates, and more."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While the CMS guides are intended to walk you through various features and functionalities, this section is geared towards providing definition lists and boilerplate code that you can use as needed."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The information you’ll find in this section assumes that you’re familiar with developing on the CMS. If you’re just getting started, it’s recommended to check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "CMS quickstart"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "CMS development guides"
      }), " section."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this section, find reference information for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "modules"
      }), ", the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), " templating language, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "fields"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "serverless functions"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;