"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694136;
const slug = exports.slug = 'guides/cms/content/forms';
const title = exports.title = 'HubSpot-Formulare';
const name = exports.name = 'HubSpot-Formulare';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie mithilfe von Modulen, HubL oder dem Einbettungscode Formulare zu Ihrer HubSpot-Website oder zu externen Seiten hinzufügen. Erfahren Sie dann, wie Sie sie mit Stilen formatieren können.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "das-standardformularmodul",
  "label": "Das Standardformularmodul",
  "parent": null
}, {
  "depth": 1,
  "id": "klonen-des-standardmoduls",
  "label": "Klonen des Standardmoduls",
  "parent": "das-standardformularmodul"
}, {
  "depth": 0,
  "id": "formularfelder-in-benutzerdefinierten-modulen",
  "label": "Formularfelder in benutzerdefinierten Modulen",
  "parent": null
}, {
  "depth": 1,
  "id": "formularoptionen-im-editor-einschr%C3%A4nken",
  "label": "Formularoptionen im Editor einschränken",
  "parent": "formularfelder-in-benutzerdefinierten-modulen"
}, {
  "depth": 0,
  "id": "verwendung-des-formular-einbettungscodes",
  "label": "Verwendung des Formular-Einbettungscodes",
  "parent": null
}, {
  "depth": 1,
  "id": "internationalisierung-hinzuf%C3%BCgen",
  "label": "Internationalisierung hinzufügen",
  "parent": "verwendung-des-formular-einbettungscodes"
}, {
  "depth": 0,
  "id": "formular-events",
  "label": "Formular-Events",
  "parent": null
}, {
  "depth": 0,
  "id": "formularformatierung",
  "label": "Formularformatierung",
  "parent": null
}, {
  "depth": 1,
  "id": "formatierung-von-formularen-%C3%BCber-das-standardformularmodul-oder-das-hubl-tag",
  "label": "Formatierung von Formularen über das Standardformularmodul oder das HubL-Tag",
  "parent": "formularformatierung"
}, {
  "depth": 1,
  "id": "formatierung-von-formularen-%C3%BCber-ein-benutzerdefiniertes-modul",
  "label": "Formatierung von Formularen über ein benutzerdefiniertes Modul",
  "parent": "formularformatierung"
}, {
  "depth": 1,
  "id": "formatierung-von-formularen%2C-die-%C3%BCber-den-formular-einbettungscode-hinzugef%C3%BCgt-werden",
  "label": "Formatierung von Formularen, die über den Formular-Einbettungscode hinzugefügt werden",
  "parent": "formularformatierung"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot-Formulare"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie HubSpot-Formulare, um Informationen von Website-Benutzern zu erfassen, auf die Sie dann in HubSpot zugreifen können. Sie können Links zu Formularen direkt mit Benutzern teilen, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "Formulardaten über die API übermitteln"
      }), " und sie mithilfe des CMS auf Ihren Website-Seiten einbetten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formulare sind ein zentraler Bestandteil von HubSpot und können in einem HubSpot-Account jeder Abonnementstufe erstellt werden. Formulare sind nicht nur für Kunden-Konversionen wichtig, sondern auch, weil Formulardaten in anderen HubSpot-Tools und -Elementen wie Smart-Content, Listen, Workflows, Content-Personalisierung und mehr verwendet werden können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms",
        children: "Erstellen eines HubSpot-Formulars"
      }), " können Sie es zu Ihren Vorlagen und Seiten hinzufügen. Je nach Anwendungsfall gibt es mehrere Möglichkeiten, ein Formular zu einer Vorlage hinzuzufügen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-default-form-module",
          children: "Mithilfe des Standardformularmoduls"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#form-fields-in-custom-modules",
          children: "Durch Hinzufügen eines Formularfelds zu einem benutzerdefinierten Modul"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-hubl-tag"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-embed-code",
          children: "Durch Einbetten mithilfe des Formular-Einbettungscodes"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Das Standardformularmodul"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre Vorlage über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Drag-&-Drop-Bereiche"
      }), " verfügt, können Content-Autoren das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "Standardformularmodul"
      }), " über den Seiten-Editor zu einer Seite hinzufügen und dann die Formularoptionen in der linken Seitenleiste konfigurieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Formularmodul direkt in eine Vorlage mit Drag-&-Drop-Bereichen zu codieren, referenzieren Sie es als ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"dnd_area\" class='body-container body-container__landing', label='Main section' %}\n {% dnd_section vertical_alignment='MIDDLE' %}\n  {% dnd_column width=6, offset=6 %}\n   {% dnd_row %}\n\n    <!-- Form module tag for use in templates -->\n    {% dnd_module path='@hubspot/form' %}\n    {% end_dnd_module %}\n\n   {% end_dnd_row %}\n  {% end_dnd_column %}\n {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Formularmodul zu einer Vorlage außerhalb eines Drag-&-Drop-Bereichs hinzuzufügen, referenzieren Sie es stattdessen als ein Standard-", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei beiden Implementierungen können Sie dem module-Tag Parameter hinzufügen, um Einstellungen wie das zu verwendende Formular und die Weiterleitungsoptionen festzulegen, wie im obigen Code-Beispiel gezeigt. Weitere Informationen zu den verfügbaren Parametern finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "Dokumentation zu Standardmodulen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Klonen des Standardmoduls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können das Standardmodul nicht nur verwenden, wie es ist, sondern es auch klonen, um es bearbeitbar zu machen, damit Sie es nach Bedarf anpassen können. Sie könnten beispielsweise das Standardformularmodul klonen, ein Farbfeld hinzufügen und dann den HTML-Code des Moduls in ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), "-Tag mit Formatierung einschließen, um die Farbe als Hintergrund hinzuzufügen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie im Design-Manager der linken Seitenleiste zum ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "@hubspot"
        }), "-Ordner, klicken Sie dann mit der rechten Maustaste auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "form.module"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modul klonen"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/clone-form-module.png",
        alt: "clone-form-module"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in der rechten Seitenleiste auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Feld hinzufügen"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Farbe"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<section>"
        }), "-Tag um den HTML-Content herum hinzu und fügen Sie dann Formatierung hinzu, um das Farbfeld zu referenzieren, z. B.:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section style=\"background:{{ module.color_field.color }}\">"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/default-form-module-clone-section.png",
        alt: "default-form-module-clone-section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formularfelder in benutzerdefinierten Modulen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein benutzerdefiniertes Modul erstellen, können Sie ein Formular berücksichtigen, indem Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#form",
        children: "Formularfeld"
      }), " hinzufügen und dazu das Code-Snippet des Felds zum HTML des Moduls hinzufügen. Sie können beispielsweise ein Anfrageformular für eine Beratung zu einem Modul hinzufügen, das ein Bild eines Produkts und ein beschreibendes Leistungsversprechen enthält."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So fügen Sie einem benutzerdefinierten Modul über den Design-Manager ein Formularfeld hinzu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in der rechten Seitenleiste des Modul-Editors auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Feld hinzufügen"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Formular"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-select-form-field.png",
        alt: "design-manager-select-form-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nachdem Sie das Feld hinzugefügt haben, bewegen Sie den Mauszeiger über das Feld in der rechten Seitenleiste, klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktionen"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Snippet kopieren"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-copy-snippet.png",
        alt: "module-field-copy-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fügen Sie das Snippet in das HTML-Feld des Moduls ein."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-paste-snippet.png",
        alt: "module-field-paste-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formularoptionen im Editor einschränken"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach dem Hinzufügen zu einer Seite haben Content-Autoren in der Regel die Kontrolle über viele Aspekte des Formulars, einschließlich des zu verwendenden Formulars und der Formularfelder selbst. Sie können jedoch die im Seiten-Editor vorgegebenen Steuerungsmöglichkeiten einschränken, indem Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei des Formularmoduls ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "lokal"
      }), " so ändern, dass sie die folgenden Felder enthält:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            }), "-Eigenschaft auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " fest, um alle Steuerelemente der Inline-Formularbearbeitung im Formularmodul auszublenden. Dazu gehören die Formularfelder, der Text der Einsenden-Schaltfläche, die Datenschutz- und Einwilligungsoptionen sowie CAPTCHA."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required_property_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Array, das angibt, welche Formulare basierend auf den Eigenschaftentypen der Formularfelder ausgewählt werden können. Zu den Werten gehören: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CONTACT\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"COMPANY\""
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TICKET\""
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie beispielsweise ein Modul entwickelt haben, das nur für Formulare verwendet werden sollte, mit denen Besucher die verschiedenen Serviceabteilungen Ihres Unternehmens kontaktieren können, können Sie Content-Autoren erlauben, nur Formulare auszuwählen, die Ticket-Eigenschaften verwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Form field\n{\n  \"id\": \"843b4f0f-0ed7-5b10-e86a-5cc8a0f11a0c\",\n  \"name\": \"form_field_1\",\n  \"display_width\": null,\n  \"label\": \"Form\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"form\",\n  \"disable_inline_form_editing\": true,\n  \"required_property_types\": [\"TICKET\"],\n  \"default\": {\n    \"response_type\": \"inline\",\n    \"message\": \"Thanks for submitting the form.\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwendung des Formular-Einbettungscodes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ein Modul- oder HubL-Tag nicht ideal ist oder wenn Sie ein Formular zu einer externen Seite hinzufügen möchten, können Sie es stattdessen mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code",
        children: "Formular-Einbettungscode"
      }), " einbetten. Sie können auch den Formular-Einbettungscode anpassen, um die Funktionalität der Formular zu erweitern. Alle Anpassungsoptionen für Formular-Einbettungscode und mehr finden Sie im Referenzleitfaden für Formulare."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Optionen zum Anpassen von Formularen sind nur für in HubSpot erstellte Formulare verfügbar, die ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#with-css-in-your-external-stylesheet-marketing-hub-professional-enterprise-or-legacy-marketing-hub-basic-only",
            children: "als unformatiertes HTML-Formular festgelegt"
          }), " wurden. Der HubSpot-Account muss über ein ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Content Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), "- oder ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "-Abonnement verfügen."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot-Formulare sollten nur mit der von HubSpot gehosteten JavaScript-Datei geladen werden. Das Erstellen einer Kopie des Formular-Einbettungscodes und Self-Hosting werden ", (0, _jsxRuntime.jsx)("u", {
            children: "nicht"
          }), " unterstützt. Verbesserungen von HubSpot in den Bereichen Sicherheit, Antispam, Barrierefreiheit und Performance werden nicht auf Ihre Formular übertragen, wenn Sie sich entscheiden, den Formular-Einbettungscode von HubSpot selbst zu hosten."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie ein Beispiel für einen Formular-Einbettungscode ohne Anpassung."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    region: 'na1',\n    portalId: '123456',\n    formId: 'df93f0c1-2919-44ef-9446-6a29f9a7f',\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl oder Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des HubSpot-Accounts, in dem das Formular erstellt wurde. Dies wird verwendet, um die Formulardefinition abzurufen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Formulars, die zum Abrufen der Formulardefinition verwendet wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "region"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
              children: "Region"
            }), " des Accounts, in dem das Formular erstellt wurde. Dies wird verwendet, um die Formulardefinition abzurufen. Mögliche Werte sind ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "na1"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eu1"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Internationalisierung hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Standardmäßig stellt HubSpot eine Reihe von Validierungsnachrichten in vielen verschiedenen Sprachen bereit, auf die Sie mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), "-Parameter zugreifen können. Sie können auch benutzerdefinierte Sprachen hinzufügen oder die Fehlermeldungen und die im Formular angezeigten Monate/Tage der Datumsauswahl mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translation"
      }), "-Parameter außer Kraft setzen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "Referenzdokumentation zu Formularen"
      }), " mehr über Fehlermeldungen bei der Internationalisierung und der Formularvalidierung."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      fieldLabels: {\n        email: 'Electronic mail',\n      },\n      required: \"Hey! That's required!\",\n      submitText: 'Custom Submit Button Text',\n      submissionErrors: {\n        MISSING_REQUIRED_FIELDS: 'Please fill in all required fields!',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formular-Events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formulare bieten zwei Möglichkeiten, Funktionen an Events zu binden: Callbacks im Formular-Einbettungscode von HubSpot und globale Formular-Events. Erfahren Sie mehr über die verfügbaren Formular-Einbettungscode-Callbacks und globale Formular-Events in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "Referenzdokumentation für Formulare"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example form embed code callback\nhbspt.forms.create({\n  portalId: '',\n  formId: '',\n  onBeforeFormSubmit: function ($form) {\n    // YOUR SCRIPT HERE\n  },\n});\n\n// Example listener\nwindow.addEventListener('message', (event) => {\n  if (\n    event.data.type === 'hsFormCallback' &&\n    event.data.eventName === 'onFormSubmitted'\n  ) {\n    someAnalyticsLib('formSubmitted');\n    console.log('Form Submitted! Event data: ${event.data}');\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formularformatierung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Während HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "globale Formularstile"
      }), " sowie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "formularspezifische Einstellungsebene"
      }), " anbietet, können Sie ein Formular auch gestalten, je nachdem, wie es zu Ihren CMS-Seiten hinzugefügt wird."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Alle in CMS Hub generierten Formulare (mit Ausnahme des Formular-Einbettungscodes) ignorieren alle Stile, die über die globalen Formulareinstellungen oder die individuellen Einstellungen des Formulars konfiguriert werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatierung von Formularen über das Standardformularmodul oder das HubL-Tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-Formulare, die zu HubSpot-Seiten hinzugefügt werden, können mithilfe des CSS Ihrer Website formatiert werden. HubSpot enthält eine Reihe unterschiedlicher Klassen und Attribute in Formularen, die generiert werden, bei denen Sie Stile anwenden können. Als Ausgangspunkt verweisen wir auf das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/elements/_forms.css",
        children: "Formular-CSS der HubSpot Boilerplate"
      }), ", das die besten Praktiken für das Formatieren von Formularen darstellt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatierung von Formularen über ein benutzerdefiniertes Modul"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formulare in benutzerdefinierten Modulen können durch CSS im CSS-Bereich des Moduls im Design-Manager formatiert werden. Indem Sie CSS auf das Modul beschränken, können Sie sicherstellen, dass immer, wenn das Modul einer Seite hinzugefügt wird, die Stile übernommen werden. Es wird empfohlen, dem Formular einen Wrapper hinzuzufügen und diesen dann als Top-Level-Selektor für das CSS zu verwenden. Dadurch wird verhindert, dass die Formatierung der Formulare Ihres benutzerdefinierten Moduls durch zusätzliche Stile im Hauptstylesheet Ihrer Website überschrieben wird. Nachfolgend finden Sie einen Screenshot des benutzerdefinierten Moduls von oben, das dem CSS-Fenster hinzugefügt wurde."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/form-module-css-pane-4.jpg",
        alt: "Benutzerdefiniertes Modul mit CSS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatierung von Formularen, die über den Formular-Einbettungscode hinzugefügt werden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie den Formular-Einbettungscode verwenden, können Sie das Formular mit den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "Einstellungen für globale Formularstile"
      }), " oder mit dem CSS Ihrer Website formatieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit den Einstellungen für globale Formularstile können Sie Standardeinstellungen für jedes Formular im Account konfigurieren. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "diese Stile auch für ein einzelnes Formular im Formular-Editor überschreiben"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/global-form-styling-options-4.png",
        alt: "Globale Formularstile"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "-Abonnement haben, können Sie beim Erstellen eines Formulars die Option ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Als unformatiertes HTML-Formular"
      }), " festlegen auswählen. Mit dieser Einstellung wird das Formular als HTML und nicht als Iframe gerendert, wodurch das Formatieren des eingebetteten Formulars mit CSS vereinfacht wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site",
        children: "Formatieren von eingebetteten Formularen in der Wissensdatenbank"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/set-as-raw-html-form-4.jpg",
        alt: "Einstellung zum Festlegen als unformatiertes HTML-Formular"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}