"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35877708582;
const slug = exports.slug = 'guides/cms/content/performance/speed';
const title = exports.title = 'CMS Hubで構築したウェブサイトの速度の最適化';
const name = exports.name = 'APAC JAPAN (ja) | Optimizing your CMS Hub site for performance_JA | 202011';
const metaDescription = exports.metaDescription = 'ウェブサイトの読み込み速度とレンダリング速度を向上させるためのヒントと戦略を紹介します。CMS Hubのテーマまたはサイトを高速化します。また、HubSpotによってどのような処理が行われるかを把握しておきます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%84%AA%E3%82%8C%E3%81%9F%E5%9F%BA%E7%9B%A4%E3%82%92%E5%87%BA%E7%99%BA%E7%82%B9%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "優れた基盤を出発点にする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF",
  "label": "ウェブサイトのパフォーマンスにおける一般的なボトルネック",
  "parent": null
}, {
  "depth": 1,
  "id": "%E7%94%BB%E5%83%8F",
  "label": "画像",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF"
}, {
  "depth": 2,
  "id": "%E5%8F%AF%E8%83%BD%E3%81%AA%E5%AF%BE%E7%AD%96",
  "label": "可能な対策",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF"
}, {
  "depth": 1,
  "id": "%E5%8B%95%E7%94%BB%E3%81%AE%E8%87%AA%E5%8B%95%E5%86%8D%E7%94%9F",
  "label": "動画の自動再生",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF"
}, {
  "depth": 2,
  "id": "%E5%8F%AF%E8%83%BD%E3%81%AA%E5%AF%BE%E7%AD%96",
  "label": "可能な対策",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF"
}, {
  "depth": 1,
  "id": "javascript",
  "label": "JavaScript",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF"
}, {
  "depth": 2,
  "id": "%E5%8F%AF%E8%83%BD%E3%81%AA%E5%AF%BE%E7%AD%96",
  "label": "可能な対策",
  "parent": "%E3%82%A6%E3%82%A7%E3%83%96%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AA%E3%83%9C%E3%83%88%E3%83%AB%E3%83%8D%E3%83%83%E3%82%AF"
}, {
  "depth": 0,
  "id": "%E6%8E%A8%E5%A5%A8%E3%83%84%E3%83%BC%E3%83%AB",
  "label": "推奨ツール",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%BC%E3%83%89%E3%82%A2%E3%83%A9%E3%83%BC%E3%83%88",
  "label": "コードアラート",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E3%81%AB%E3%82%88%E3%82%8B%E8%87%AA%E5%8B%95%E5%87%A6%E7%90%86",
  "label": "HubSpotによる自動処理",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E9%80%9F%E5%BA%A6%E5%90%91%E4%B8%8A%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E8%BF%BD%E5%8A%A0%E8%B3%87%E6%96%99",
  "label": "サイトの速度向上に関する追加資料",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%83%84%E3%83%BC%E3%83%AB",
  "label": "パフォーマンスツール",
  "parent": "%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E9%80%9F%E5%BA%A6%E5%90%91%E4%B8%8A%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E8%BF%BD%E5%8A%A0%E8%B3%87%E6%96%99"
}, {
  "depth": 2,
  "id": "%E7%94%BB%E5%83%8F%E3%81%AE%E6%9C%80%E9%81%A9%E5%8C%96",
  "label": "画像の最適化",
  "parent": "%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E9%80%9F%E5%BA%A6%E5%90%91%E4%B8%8A%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E8%BF%BD%E5%8A%A0%E8%B3%87%E6%96%99"
}, {
  "depth": 2,
  "id": "%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%83%86%E3%82%B9%E3%83%88",
  "label": "パフォーマンステスト",
  "parent": "%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AE%E9%80%9F%E5%BA%A6%E5%90%91%E4%B8%8A%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E8%BF%BD%E5%8A%A0%E8%B3%87%E6%96%99"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      ol: "ol",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS Hubで構築したウェブサイトのパフォーマンス最適化"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ユーザーエクスペリエンスは、コンテンツの品質、表示速度、セキュリティー、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "アクセシビリティー"
      }), "を支える要素の1つです。これらを最適化することは、通常、検索エンジン最適化（SEO）対策としても効果的です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "優れたパフォーマンスとは、エンドユーザーの利便性を高めることに他なりません。パフォーマンスを向上するには、ウェブサイト上のさまざまな課題を解消する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "優れた基盤を出発点にする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "後からパフォーマンス上の問題を修正するよりも、パフォーマンスを念頭に置いた優れた基盤を出発点としてサイトを構築するほうが簡単です。例えば、速度の遅い自動車を買ってスピードを速めるよりも、速い自動車をゼロから組み立てるほうが簡単です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "HubSpot CMSボイラープレート"
      }), "は、ベストプラクティスを採り入れてサイトを迅速に構築できるようにするために作成されています。ウェブサイトの評価での現在のスコアについて、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "GitHub README"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードを一切追加していない状態のボイラープレートから作成したウェブサイトでも、これだけのスコアを達成できます。つまり、ボイラープレートをベースに追加するコードだけに専念できるということです。"
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "bdf71810-13e6-494f-8dbd-f733d5451ad5",
      label: "Build a site based on boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ウェブサイトのパフォーマンスにおける一般的なボトルネック"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ほとんどのウェブパフォーマンスの最適化手法とベストプラクティスは、HubSpotに固有のものではありません。ほとんどのウェブサイトのパフォーマンスのボトルネックはHubSpotに固有のものではないためです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パフォーマンスの問題の大半は、レンダリングと読み込みの2つに分類されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "読み込みのパフォーマンス"
        }), "は、ウェブページに必要な全てのファイルをユーザーのブラウザーに転送する際の効率性を意味します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "レンダリングのパフォーマンス"
        }), "は、ブラウザーがダウンロードした全てのものを最終結果としてユーザーに表示する際の効率性を意味します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["読み込みのパフォーマンスを理解することはそれほど難しくありません。読み込みのパフォーマンスはファイルの数、ファイルのサイズ、ファイルの配信速度によって決まります。レンダリングのパフォーマンスは複雑です。カスケーディング スタイル シート（", (0, _jsxRuntime.jsx)("abbr", {
        children: "CSS"
      }), "）、JavaScript（", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), "）、画像、動画、訪問者が使用しているデバイス、使用されているウェブブラウザーのそれぞれが、重要な要素です。CSSはレンダリングの妨げとなります。CSSの不適切な記述が原因で、ページのレンダリング中に累積レイアウト変更（", (0, _jsxRuntime.jsx)("abbr", {
        children: "CLS"
      }), "）が発生することがあります。画像が原因でCLSが発生し、RAMを消費する可能性があります。動画プレイヤーが原因でCLSが発生し、一部のファイル形式は多くの処理を必要とすることがあります。JSではページの", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Glossary/DOM",
        children: ["Document Object Model（", (0, _jsxRuntime.jsx)("abbr", {
          children: "DOM"
        }), "）"]
      }), "と", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Glossary/CSSOM",
        children: ["CSSオブジェクトモデル（", (0, _jsxRuntime.jsx)("abbr", {
          children: "CSSOM"
        }), "）"]
      }), "を操作できますが、これによって前述の問題が発生します。また、JSによってリソースが大量に消費される可能性もあります。全ての訪問者に対して高速な操作性を提供するには、全ての要素のバランスを取り、ベストプラクティスに従う必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "画像"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブ上のほぼ全てのページでは、画像が至るところで使われています。通常、画像はページ上で最もサイズが大きいファイルです。画像の数が多かったり、画像のサイズが大きかったりすると、それだけページの読み込み時間が長くなります。GIFやwebpのアニメーションも、同じサイズのアニメーション化されていない画像と比べると、読み込み時間が長くなります。一部の画像形式は他の形式よりもパフォーマンスに優れています。また、状況によっても最適な画像形式は異なります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "可能な対策"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["パフォーマンス向上のためにできることのうち、最も重要なのは、ウェブ用として", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/compress-image",
          children: "画像を最適化"
        }), "することです。画像の最適化は、制作担当者と開発者の双方が共同で取り組むことが求められます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページあたりの画像数を減らします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/insiders/different-types-of-image-files",
          children: "それぞれの用途に応じた適切な画像形式を使用"
        }), "します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "可能な場合は常にSVG（Scalable Vector Graphics）を使用します。SVGは品質を落とすことなく無制限に拡大できます。アニメーションを作成するときは、SVGをインライン化するのが有効です。静止画では、SVMスプライトシートを作成するか、SVGをそのまま通常のimg要素または背景画像として扱うほうが、通常は良好なパフォーマンスが得られます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/lazy-loading",
          children: "画像の遅延読み込み"
        }), "を適切に制御します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), "要素に高さと幅のHTML属性を含めるようにします。こうすると、ブラウザーでレンダリングしやすくなり、HubSpotが自動的に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), "を追加することもできます。幅と高さを指定すると、HubSpotで最適化をサポートできるだけでなく、ブラウザーも", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/i18n/ja/cls/",
          children: "累積レイアウトシフト（CLS）"
        }), "に応じて自動的に最適化できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/ja-JP/docs/Web/CSS/aspect-ratio",
          children: "CSS aspect-ratio"
        }), "プロパティーを使用して、imgのサイズが変更される際のスペースを確保します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url"
          })
        }), "を使用して、画像が特定の解像度になるようにサイズ変更します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["背景画像の場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/optimize-css-background-images-with-media-queries/",
          children: "メディアクエリー"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url"
        }), "を組み合わせて、デバイスに最適なサイズで画像を配信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["大きなヒーローイメージを使用する場合は、", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "require_head"
          }), "タグ"]
        }), "の中で", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"preload\" as=\"image\" href=\"http://example.com/img_url.jpg\">"
        }), "を使用して、ヒーローイメージを事前に読み込むことができます。この方法は最小限に控えてください。多用すると、パフォーマンスが低下するためです。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "動画の自動再生"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "動画の背景と動画の自動再生を使用すると、ウェブサイトを際立たせることができます。ただし、それには犠牲も伴います。動画の背景はウェブサイトのヘッダーに使用されることがよくあります。動画を自動再生することは、ブラウザーが直ちに動画の読み込みを開始しなければならないことを意味します。これは接続が遅いか、携帯回線でデータを受信しているユーザーにとっては特に問題になることがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "可能な対策"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "動画の自動再生は回避してください。背景にアニメーションを表示する場合は、CSSアニメーションまたはJavaScriptアニメーションの使用を検討してください。自動再生動画を表示する場合："
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ニーズに応じて動画の解像度を許容できる程度まで抑え、動画に効果を適用して低解像度であることが目立たないようにします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "デバイスと接続に基づいて動画品質を調整します。YouTube、Vidyard、Vimeoなどの動画共有／ホスティングサービスを使用する方法が最適です。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モバイル上での自動再生を無効にして、代わりの静止画を表示します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["操作可能な要素をウェブサイトに追加するには、JavaScript（", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), "）が役立ちます。ただし通常は、大量のJSコードを読み込むとJSファイルのサイズが大きくなり、操作可能な要素をブラウザーが読み込むまでの時間が長くなります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), "にJSを読み込むことも問題になる可能性があります。既定では、JavaScriptは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/render-blocking-resources/",
        children: "レンダリング ブロック リソース"
      }), "として設定されているためです。また、JSは訪問者のデバイス上で実行されます。つまり、デバイスのリソースの制約を受けます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "可能な対策"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotのCMSが初めてリリースされた当時、既定で", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), "にjQueryが読み込まれていました。［設定］＞［ウェブサイト］＞［ページ］の順に進んで完全に削除するか、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/upgrade-jquery",
          children: "最新バージョンのjQueryにアップグレード"
        }), "することができます。このような設定を、構築に自身が関わっていなかった古いウェブサイト上で変更する際には、jQueryに依存して構築されていたり、ヘッダーでのjQueryの読み込みに基づいて構築されていたりすることがあるので注意が必要です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["レンダリングの妨げにならないように、JavaScriptが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "</body>"
        }), "の直前に読み込まれるようにします。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), "は、モジュールまたはテンプレート上で必要な場合に限ってJavaScriptを読み込むために、また、モジュールの複数のインスタンスによって誤ってJavaScriptが複数回読み込まれることをなくすために使用できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["JSをリファクタリングして効率化することを検討します。JSプラグインの数を減らして、セマンティックHTMLが役立つ場合はこれを使用します。例えばドロップダウンには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<details>"
        }), "および", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<summary>"
        }), "を使用します。モーダルには", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<dialog>"
        }), "を使用します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ごく一部の機能のために大規模なJSライブラリーを使用している場合は、簡素なJSで代用するか、可能な場合はライブラリーのサブセットを読み込むことを検討します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js"
        }), "を使用して、必要な場合に限定してJSを読み込みます。この場合、ページごとに1度だけ読み込まれます。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), "を使用する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "async"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "defer"
        }), "属性を使用してページパフォーマンスを改善します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["モジュールのJavaScriptが読み込まれる位置とタイミングを制御するには、モジュールのmeta.jsonファイル内で", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/modules/configuration",
          children: "js_render_options"
        }), "を使用します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["外部リソースを読み込む場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/preconnect-and-dns-prefetch/",
          children: "事前接続とDNSプリフェッチ"
        }), "を適切に使用して読み込み時間を短縮します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "使用するトラッキングスクリプトの数を制限します。トラッキングスクリプトは、情報を収集するために、ページでのユーザーによるあらゆる行動の把握を試みる性質があります。こうしたユーザー操作の分析には大量のコードが使用されます。トラッキングスクリプトごとにこのコードの量が増大します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "推奨ツール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotの推奨ツールは、特定のウェブサイトのパフォーマンスとSEOに関するフィードバックを得るための有用なツールです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/seo/view-seo-recommendations-in-hubspot",
        children: "推奨ツールの詳細をご確認ください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コードアラート"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コードアラートは、HubSpotウェブサイト内で特定された問題を集約した概要としての役割を果たす、CMS Hub Enterpriseの機能です。コードアラートで特定された問題を修正すると、ウェブサイトのパフォーマンス最適化に役立ちます。HubLの制限からCSSの問題まで、さまざまな領域の問題が特定されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "コードアラートの詳細を参照してください。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotによる自動処理"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パフォーマンスや速度に関するベストプラクティスのほとんどは、HubSpotに固有のものではありません。HubSpotのCMSでは、一般的なパフォーマンス問題が自動的に処理されます。次のような最適化があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CDNでの画像最適化と自動WebP変換"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "HTTP2"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "JavaScript"
        }), "とCSSのミニフィケーション"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "ブラウザーとサーバーのキャッシュ"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "プリレンダリング"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "ドメインの書き換え"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#text-compression",
          children: "Brotli圧縮（GZIP圧縮による代替付き）"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#accelerated-mobile-pages-amp-",
          children: "HubSpotブログ記事でのAMPのサポート"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムモジュールにCSSが組み込まれていると、HubSpotでは、モジュールがページで使用されている場合にのみmodule.cssが効率的に読み込まれます。また、ページで使用されているモジュールのインスタンス数に関係なく、module.cssが1回だけ読み込まれます。既定では、module.cssの読み込みは非同期ではありませんが、この動作はモジュールのmeta.jsonファイルに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options"
      }), "を含めることで変更できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サイトの速度向上に関する追加資料"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サイトの速度を最適化するにはさまざまな対処法があり、最適化手法の多くはさらに細分化できます。サイトの最適化に取り組むときは、HubSpotの充実した参考情報（英語）を参照してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/site-speed-and-performance-what-we-do-and-what-you-can-do",
          children: "サイトの速度とパフォーマンス：最適化のためにできることと、HubSpotによるサポートの仕組み"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-we-improved-page-speed-on-hubspot.com",
          children: "HubSpot.comでページの表示速度を向上させる方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/15-tips-to-speed-up-your-website",
          children: "ウェブサイトを高速化する15のヒント"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/how-to-reduce-your-websites-page-speed",
          children: "ウェブサイトページの読み込み時間を短縮するための5つの簡単な方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/google-page-speed",
          children: "Google Page Speedで100％を達成する8つのステップに関するガイド"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/website-optimization",
          children: "ウェブサイトの最適化 - HubSpotアカデミー"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/i18n/ja/learn/",
          children: "web.dev"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "HubSpot CMSを最適化する方法 - Jeff Bourter"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.smashingmagazine.com/2021/04/humble-img-element-core-web-vitals/",
          children: "控え目なimg要素とCore Web Vitals - Smash Magazine"
        }), "（英語）"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "パフォーマンスツール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パフォーマンスの向上やテストのためのツールは数多く提供されています。これらのツールを理解して活用するとパフォーマンスの最適化に役に立ちます。以下に、開発者コミュニティーで提供されている情報を参考として示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "画像の最適化"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "画像を最適化してからウェブにアップロードして配信すると、画面に収まらないような大きい画像を配信しないように配慮できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "よく使われる画像最適化ツール："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imageoptim.com/mac",
          children: "ImageOptim"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/photoshop.html",
          children: "Adobe Photoshop"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/illustrator.html",
          children: "Adobe Illustrator"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imagecompressor.com/",
          children: "Optimizilla"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "パフォーマンステスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "どのようなウェブサイトを構築する場合も、パフォーマンスのテストと最適化はウェブサイトの構築プロセスの一環として行う必要があります。ウェブサイトの速度テストに利用できるツールは多数あります。パフォーマンスを情報に基づいて改善できるように、ツールとその仕組みを理解することが重要です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "測定に特化したツールもあれば、パフォーマンスに点数を付けるツールもあります。ツールが実際にどのように機能するかを理解することが重要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "測定ツール"
      }), "は一般に、読み込み時間、スクリプトの実行時間、コンテンツの初回ペイントまでの時間、アセットをダウンロードするときのネットワーク時間をテストします。通常、これらのツールによる測定結果は、それぞれの指標に固有の時間を示します。ページは読み込みの度にがまったく同じとは限らないため、再テストすると、通常は測定値がわずかに異なります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "評価ツール"
      }), "は速度を測定するだけでなく、多くの場合はパーセンテージや文字で表した点数で、パフォーマンスを示します。こうした点数は、開発者やマーケティング担当者がパフォーマンスを向上させる動機付けとなるよう意図されています。パフォーマンスには考慮に入れなければならないさまざまな指標や観点があります。1つの指標だけに基づいて全体的なパフォーマンスを評価することはできません。また、指標によっては、パフォーマンスに関する認識にもさまざまな影響を与えます。こうしたツールで全体的なパフォーマンスを計算する際には、各種の指標に異なる重みが付けられています。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "測定指標の重み付け方法について、業界全体で採用されている標準はありません。"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://googlechrome.github.io/lighthouse/scorecalc/",
        children: "Google Page Speed"
      }), "のように、重み付けは時間の経過とともに変更される可能性があります。また、個々の指標で「良好」な状態を意味する最小値や最大値として業界全体で受け入れられている基準もありません。一部のツールは、テスト済みウェブサイトのパーセンタイルを基準にしています。つまり、ウェブサイトでのスコアは他のテスト済みウェブサイトでのスコアとの比較に基づいています。そのため、「良好／優秀」な速度の範囲に達することが次第に難しくなります。利用者にとっての利便性、訪問者の保持状況、およびROIに基づく調査などで良好なスコアのしきい値を決定するツールもあります。また、全てのツールにおいて後続のページの読み込みパフォーマンスが考慮されるとは限らないことを考慮してください。例えば、HubSpotモジュールシステムでは、各モジュールのCSSとJSが切り離されているため、このようなアセットはモジュールが実際にページに配置された場合にのみ読み込まれます。このため、複数の小さなCSSファイルが発生し、この状態がGoogle Page Speed Insightsによって警告されることがあります。実際には、次回のページ読み込みにおいて、次のページで繰り返されるモジュールのCSSまたはJSがキャッシュされていて、ダウンロードする必要がなくなっています。1つの大きなファイルをダウンロードするのではなく、これまでに表示されていないモジュールのファイル（数キロバイト程度）をダウンロードするだけで済みます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "点数を使用するツールに関しては、複数のツールを使用して、それぞれのツールで最高のスコアを獲得できるように努力する必要があります。ツールによって重み付けは異なるという点を理解してください。最適化の取り組みにより、あるツールではスコアが上がったとしても、別のツールではそうではないこともあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "よく使われるパフォーマンス テスト ツール："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://website.grader.com/",
          children: "Website Grader"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://gtmetrix.com/",
          children: "GTMetrix"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/speed/pagespeed/insights/",
          children: "Google Page Speed Insights"
        }), "およびその他の", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/speed-tools",
          children: "Google パフォーマンス最適化ツール"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.pingdom.com/",
          children: "Pingdom"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.webpagetest.org/",
          children: "WebPageTest"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "関連："
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/performance-scoring/",
          children: "Lighthouseのパフォーマンススコアリングの仕組み"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "ウェブサイト最適化のロードマップ（Core Web Vitals）| Mark Ryba"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}