"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 129892668929;
const slug = exports.slug = 'guides/api/crm/lists/overview';
const title = exports.title = 'Lists API';
const name = exports.name = 'Lists API';
const metaDescription = exports.metaDescription = 'Learn how to create and manage lists using the Lists API. ';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "list-processing-types",
  "label": "List processing types",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-list",
  "label": "Create a list",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-lists",
  "label": "Retrieve lists",
  "parent": null
}, {
  "depth": 0,
  "id": "search-for-a-list",
  "label": "Search for a list",
  "parent": null
}, {
  "depth": 0,
  "id": "update-lists",
  "label": "Update lists",
  "parent": null
}, {
  "depth": 1,
  "id": "update-a-list-filter-branch",
  "label": "Update a list filter branch",
  "parent": "update-lists"
}, {
  "depth": 0,
  "id": "delete-and-restore-a-list",
  "label": "Delete and restore a list",
  "parent": null
}, {
  "depth": 0,
  "id": "manage-list-membership",
  "label": "Manage list membership",
  "parent": null
}, {
  "depth": 1,
  "id": "add-records-to-an-existing-list",
  "label": "Add records to an existing list",
  "parent": "manage-list-membership"
}, {
  "depth": 1,
  "id": "view-records-in-an-existing-list",
  "label": "View records in an existing list",
  "parent": "manage-list-membership"
}, {
  "depth": 1,
  "id": "delete-records-from-an-existing-list",
  "label": "Delete records from an existing list",
  "parent": "manage-list-membership"
}, {
  "depth": 0,
  "id": "migrate-from-v1-to-v3-api-endpoints",
  "label": "Migrate from v1 to v3 API endpoints",
  "parent": null
}, {
  "depth": 1,
  "id": "get-static-lists",
  "label": "Get static lists",
  "parent": "migrate-from-v1-to-v3-api-endpoints"
}, {
  "depth": 1,
  "id": "get-dynamic-lists",
  "label": "Get dynamic lists",
  "parent": "migrate-from-v1-to-v3-api-endpoints"
}, {
  "depth": 1,
  "id": "get-a-batch-of-lists-by-list-id",
  "label": "Get a batch of lists by list ID",
  "parent": "migrate-from-v1-to-v3-api-endpoints"
}, {
  "depth": 1,
  "id": "get-recent-list-members-with-properties",
  "label": "Get recent list members with properties",
  "parent": "migrate-from-v1-to-v3-api-endpoints"
}, {
  "depth": 1,
  "id": "get-all%2Frecently-modified-records-with-properties",
  "label": "Get all/recently modified records with properties",
  "parent": "migrate-from-v1-to-v3-api-endpoints"
}, {
  "depth": 0,
  "id": "migrate-list-ids-from-v1-to-v3-endpoints",
  "label": "Migrate list IDs from v1 to v3 endpoints",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Lists"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lists are a collection of records of the same object type that can be used for record segmentation, filtering, and grouping to serve your business needs. You can create contact, company, deal, or custom object lists. The v3 Lists API allows you to create, edit, and fetch lists."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A list consists of a list definition and list memberships:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "List definition: stores essential information about the list."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "List memberships: mappings between the list and object record."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/lists/overview",
          children: "legacy v1 Lists API"
        }), "will be sunset starting May 30th, 2025. If you were previously using the v1 Lists API, review the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#migrate-from-v1-to-v3-api-endpoints",
          children: "guide below"
        }), " to transition to the v3 API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "List processing types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are three types of list processing types: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "MANUAL"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DYNAMIC"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SNAPSHOT"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "MANUAL"
      }), ": this processing type indicates that object records can only be added to or removed from the list via manual actions by the user or API call. There is no list processing or list membership management done in the background by HubSpot's systems. This type of list is helpful for when you need a set list of records that won't change unless manually updated."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "DYNAMIC"
      }), ": this processing type gives the possibility to specify ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/lists/lists-filters",
        children: "list filters"
      }), " to match records that will become list members. This type of list is processed in the background by HubSpot to ensure that the list only contains records that match the list's filters. Whenever a record changes, it is reevaluated against the list's filters and either added to or removed from it. This type of list is helpful for when you want to keep a running list that you expect to change over time."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "SNAPSHOT"
      }), ": ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/lists/lists-filters",
        children: "list filters"
      }), " are specified at the time of list creation. After initial processing is completed, records can only be added to or removed from the list by manual actions. This type of list is helpful for when you want to create a list of records based on specific criteria, but don't want that list to change automatically after initial processing."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, you must include the following parameters: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "processingType"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterBranch"
      }), " parameter is optional, and can be included to create branching logic for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DYNAMIC"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SNAPSHOT"
      }), " type lists. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/lists/lists-filters",
        children: "configuring list filters and branches"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once created, a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listID"
      }), " (the ILS list ID) will be generated. This ID is used for future updates and modifications."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"name\": \"My static list\",\n  \"objectTypeId\": \"0-1\",\n  \"processingType\": \"MANUAL\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve lists"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depending on your use case, there are multiple ways to retrieve lists:A list can be retrieved by using either the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/lists-faq",
        children: "ILS list ID"
      }), " or the name and object type for the list."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a list by name, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/lists/object-type-id/{objectTypeId}/name/{listName}"
        }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " is the ID that corresponds to the type of object stored by the list. See the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-ids",
          children: "full list of object type IDs"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual list by ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/lists/lists-faq",
          children: "ILS list ID"
        }), ", make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/lists/{listId}"
        }), ". Learn more about finding the ILS list ID below."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve multiple listsby ILS list ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/lists"
        }), " and include a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "listIds"
        }), " query parameter for each list. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?listIds=940&listIds=938"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When retrieving lists, you can include a query parameter of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "includeFilters=true"
      }), " to return list filter definitions in the response."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To find a list's ILS list ID, you can navigate to the lists tool in HubSpot, then hover over the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "list"
      }), " and click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Details"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists#view-and-edit-a-list",
        children: "viewing lists"
      }), ". You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#search-for-a-list",
        children: "search for lists"
      }), " by other criteria, then view the list's ID in the response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/list-find-ils-list-id.png",
        alt: "list-find-ils-list-id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Search for a list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can search for lists by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/search"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, you'll specify the criteria that you want to search by. For example, to search for lists that contain specific words in their name, you can include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), " field. Or, to search for lists of a specific processing type, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "processingTypes"
      }), " array with each of the processing types you want to search by."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to search for all static lists that contain \"HubSpot\" in the name, your request body would be as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"query\": \"HubSpot\",\n  \"processingTypes\": [\"MANUAL\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update lists"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update a list name, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/update-list-name"
      }), " with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listName"
      }), " query parameter. If the list with the provided ILS list ID exists, then its name will be updated to the provided ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listName"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listName"
      }), " must be unique amongst all other public lists in the portal."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also include a query parameter of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "includeFilters=true"
      }), " to return list filter definitions in the response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update a list filter branch"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DYNAMIC"
      }), " list's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/lists/lists-filters",
        children: "filter branches"
      }), ", make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/update-list-filters"
      }), ". In the request body, include the new filter branch definition. This"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/lists/lists-filters",
        children: "list filter"
      }), " branch can be updated by sending a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/update-list-filters"
      }), " with a request body containing the new filter branch definition. If the list with the provided ILS list ID exists, then its filter branch definition will be updated to the provided filter branch. Once the filter branch is updated, the list will begin processing its new memberships."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete and restore a list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once deleted, lists can be restored within 90 days of deletion by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listID}/restore"
      }), ". Lists deleted more than 90 days ago ", (0, _jsxRuntime.jsx)("u", {
        children: "cannot"
      }), " be restored."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage list membership"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view and manage records included in a list, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/memberships/"
      }), " endpoints below. List membership endpoints can only be used on ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "MANUAL"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SNAPSHOT"
      }), " list processing types. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DYNAMIC"
      }), " lists will add and remove records based on the filter criteria set."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add records to an existing list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add records to an existing list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/memberships/add"
      }), " with a list of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "recordID"
      }), "s in the request body."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add records from one list to another, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ​", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3​/lists/{listId}/memberships​/add-from​/{sourceListId}"
      }), ", where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sourceListId"
      }), " is the list you're retrieving the records from. You can move a limit of 100,000 records at a time."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "View records in an existing list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all records in an existing list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/memberships"
      }), ". This returns all members of a list ordered by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "recordId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delete records from an existing list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove all records from an existing list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/memberships"
      }), ". This will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " delete the list from your account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove specific records from an existing list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/memberships/remove"
      }), " with a list of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "recordID"
      }), "s in the request body."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrate from v1 to v3 API endpoints"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you were previously using any of the v1 list endpoints, you can migrate over to the equivalent endpoints detailed in the sections below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get static lists"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a static list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/search"
      }), " and include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SNAPSHOT"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "MANUAL"
      }), " within an array provided as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "processingTypes"
      }), " parameter in your request body."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body for POST request to /crm/v3/lists/search\n{\n  \"additionalProperties\": [\n    \"hs_is_public\",\n    \"hs_is_read_only\",\n    \"hs_is_limit_exempt\",\n    \"hs_all_team_ids\",\n    \"hs_folder_id\",\n    \"hs_folder_name\"\n  ],\n  \"offset\": 0,\n  \"processingTypes\": [\"MANUAL\", \"SNAPSHOT\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get dynamic lists"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a dynamic list, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/search"
      }), " and include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DYNAMIC"
      }), " within an array provided as the processingTypes parameter in your request body."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body for POST request to /crm/v3/lists/search\n{\n  \"additionalProperties\": [\n    \"hs_is_public\",\n    \"hs_is_read_only\",\n    \"hs_is_limit_exempt\",\n    \"hs_all_team_ids\",\n    \"hs_folder_id\",\n    \"hs_folder_name\"\n  ],\n  \"offset\": 0,\n  \"processingTypes\": [\"DYNAMIC\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get a batch of lists by list ID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a batch of lists by the listIds, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/search"
      }), ". In the request, include the desired list IDs in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listIds"
      }), " parameter and specify any additional properties. The response will not include any filter branches."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body for POST request to /crm/v3/lists/search\n{\n  \"additionalProperties\": [\n    \"hs_is_public\",\n    \"hs_is_read_only\",\n    \"hs_is_limit_exempt\",\n    \"hs_all_team_ids\",\n    \"hs_folder_id\",\n    \"hs_folder_name\"\n  ],\n  \"offset\": 0,\n  \"listIds\": [\"42\", \"51\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include filters in your response, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists"
      }), ". In the request, append the query parameters ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "includeListFilters=true"
      }), " and the desired list IDs as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listIds"
      }), " parameter. E.g. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists?includeFilters=true&listIds=42&listIds=51"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get recent list members with properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["First, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/{listId}/memberships/join-order"
      }), " to get the record IDs of the list members. Then, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), " for the specific ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), " and include the record IDs in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "values"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body for POST request to /crm/v3/objects/{object}/search\n\n{\n  \"properties\": [\n    \"firstname\",\n    \"lastname\",\n    \"email\",\n    \"hs_object_id\",\n    \"createdate\",\n    \"lastmodifieddate\",\n    \"hs_all_accessible_team_ids\"\n  ],\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"hs_object_id\",\n          \"operator\": \"IN\",\n          \"values\": [\"808431983\", \"802539655\", \"101\"]\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get all/recently modified records with properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the CRM search endpoint to search for records in your HubSpot account. To get all records, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), " with the object you want to search for."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"properties\": [\n    \"firstname\",\n    \"lastname\",\n    \"email\",\n    \"hs_object_id\",\n    \"createdate\",\n    \"lastmodifieddate\",\n    \"hs_all_accessible_team_ids\"\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request to /crm/v3/objects/deals/search\n{\n  \"properties\": [\"hs_object_id\", \"createdate\", \"dealstage\", \"lastmodifieddate\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get recently modified records, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), " and filter by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastmodifieddate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"properties\": [\n    \"firstname\",\n    \"lastname\",\n    \"email\",\n    \"hs_object_id\",\n    \"createdate\",\n    \"lastmodifieddate\"\n  ],\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"lastmodifieddate\",\n          \"operator\": \"GT\",\n          \"value\": \"2024-02-22\"\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrate list IDs from v1 to v3 endpoints"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Contact lists have two list IDs: a legacy list ID that corresponds with the legacy v1 lists API endpoint, and one that corresponds with the v3 lists API endpoint."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/lists/overview",
        children: "legacy v1 Lists API"
      }), "will be sunset starting May 30th, 2025, HubSpot API users must migrate their legacy v1 list IDs to the v3 list IDs. To do so, use the ID mapping endpoints to fetch the v3 list ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "listId"
      }), ") from one or more v1 list IDs (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "legacyListId"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the ID mapping endpoints will be sunset on May 30th, 2025."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fetch mappings one at a time, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/idmapping?legacyListId=<legacyListId>"
      }), " with the v1 list ID in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "legacyListID"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example response from a GET request to /crm/v3/lists/idmapping?legacyListId=64\n\n{\n  \"listId\": \"61\",\n  \"legacyListId\": \"64\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fetch multiple ID mappings in one batch, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/lists/idmapping"
      }), " and include the legacy list ID values within an array. The limit on this API is 10, 000 entries."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request to /crm/v3/lists/idmapping\n\n[\"64\", \"33\", \"22566\"]\n\n// Response\n\n{\n  \"missingLegacyListIds\": [\n    \"22566\"\n  ],\n  \"legacyListIdsToIdsMapping\": [\n    {\n      \"listId\": \"61\",\n      \"legacyListId\": \"64\"\n    },\n    {\n      \"listId\": \"38\",\n      \"legacyListId\": \"33\"\n    }\n  ]\n}\n"
          })
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}