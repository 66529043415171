"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694148;
const slug = exports.slug = 'guides/cms/content/website-settings';
const title = exports.title = 'Paramètres du site web';
const name = exports.name = '[nouveau] Paramètres du site web';
const metaDescription = exports.metaDescription = 'La section Paramètres du site web est un espace centralisé où divers paramètres de contenu global et systémiques peuvent être configurés pour le blog, la navigation, les pages et les thèmes de votre site web.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg';
const featuredImageAltText = exports.featuredImageAltText = 'Zone des paramètres du site web';
const toc = exports.toc = [{
  "depth": 0,
  "id": "param%C3%A8tres-du-blog",
  "label": "Paramètres du blog",
  "parent": null
}, {
  "depth": 1,
  "id": "onglet-g%C3%A9n%C3%A9ral",
  "label": "Onglet Général",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "nom-du-blog",
  "label": "Nom du blog",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "en-t%C3%AAte-de-blog",
  "label": "En-tête de blog",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "titre-de-la-page",
  "label": "Titre de la page",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "description-des-m%C3%A9tadonn%C3%A9es",
  "label": "Description des métadonnées",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "url-racine-du-blog",
  "label": "URL racine du blog",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "contr%C3%B4le-de-l-acc%C3%A8s-de-l-audience",
  "label": "Contrôle de l'accès de l'audience",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 1,
  "id": "onglet-mod%C3%A8les",
  "label": "Onglet Modèles",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "mod%C3%A8le-actuel",
  "label": "Modèle actuel",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "mod%C3%A8le-pour-les-pages-de-listing-facultatif",
  "label": "Modèle pour les pages de listing (facultatif)",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "nombre-d-articles-par-page-de-listing",
  "label": "Nombre d'articles par page de listing",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "html-d-en-t%C3%AAte",
  "label": "HTML d'en-tête",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "html-de-pied-de-page",
  "label": "HTML de pied de page",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 1,
  "id": "onglet-abonnements",
  "label": "Onglet Abonnements",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "e-mails-de-notification-d-abonn%C3%A9-au-blog",
  "label": "E-mails de notification d'abonné au blog",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "flux%C2%A0rss",
  "label": "Flux RSS",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 1,
  "id": "onglet-formats-de-date",
  "label": "Onglet Formats de date",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "langue-pour-les-dates",
  "label": "Langue pour les dates",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "format-de-la-date-de-publication",
  "label": "Format de la date de publication",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "format-des-articles-par-mois",
  "label": "Format des articles par mois",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 1,
  "id": "onglet-commentaires",
  "label": "Onglet Commentaires",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "activer-ou-d%C3%A9sactiver-les-commentaires-de-blog",
  "label": "Activer ou désactiver les commentaires de blog",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "notifications-des-commentaires-de-blog",
  "label": "Notifications des commentaires de blog",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 1,
  "id": "onglet-partage-sur-les-r%C3%A9seaux-sociaux",
  "label": "Onglet Partage sur les réseaux sociaux",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "compte-twitter-par-d%C3%A9faut",
  "label": "Compte Twitter par défaut",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "boutons-de-partage-sur-les-r%C3%A9seaux-sociaux",
  "label": "Boutons de partage sur les réseaux sociaux",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 1,
  "id": "onglet-amp-de-google",
  "label": "Onglet AMP de Google",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "activer-ou-d%C3%A9sactiver-le-format-amp-de-google",
  "label": "Activer ou désactiver le format AMP de Google",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 2,
  "id": "param%C3%A8tres-sp%C3%A9cifiques-aux-pages-amp-pour-un-logo%2C-un-formatage-de-l-en-t%C3%AAte",
  "label": "Paramètres spécifiques aux pages AMP pour un logo, un formatage de l'en-tête",
  "parent": "param%C3%A8tres-du-blog"
}, {
  "depth": 0,
  "id": "param%C3%A8tres-de-navigation",
  "label": "Paramètres de navigation",
  "parent": null
}, {
  "depth": 0,
  "id": "param%C3%A8tres-de-page",
  "label": "Paramètres de page",
  "parent": null
}, {
  "depth": 1,
  "id": "onglet-mod%C3%A8les",
  "label": "Onglet Modèles",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "html-d-en-t%C3%AAte-du-site",
  "label": "HTML d'en-tête du site",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "html-de-pied-de-page-du-site",
  "label": "HTML de pied de page du site",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "jquery",
  "label": "jQuery",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 1,
  "id": "onglet-branding",
  "label": "Onglet Branding",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "favicon",
  "label": "Favicon",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "logo-texte-alternatif%2C-dimensions%2C-lien",
  "label": "Logo (texte alternatif, dimensions, lien)",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 1,
  "id": "onglet-personnalisation",
  "label": "Onglet Personnalisation",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "valeurs-par-d%C3%A9faut-pour-les-contacts-et-entreprises",
  "label": "Valeurs par défaut pour les contacts et entreprises",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 1,
  "id": "onglet-int%C3%A9gration",
  "label": "Onglet Intégration",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "int%C3%A9grations-js",
  "label": "Intégrations JS",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 1,
  "id": "onglet-seo-et-robots-d-indexation",
  "label": "Onglet SEO et robots d'indexation",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "url-canonique",
  "label": "URL canonique",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "domaine-d-h%C3%A9bergement-de-fichier-par-d%C3%A9faut",
  "label": "Domaine d'hébergement de fichier par défaut",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 2,
  "id": "fichier%C2%A0robots.txt",
  "label": "Fichier robots.txt",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 1,
  "id": "onglet-pages-syst%C3%A8me",
  "label": "Onglet Pages système",
  "parent": "param%C3%A8tres-de-page"
}, {
  "depth": 0,
  "id": "pages-d-erreur%C2%A0404-et%C2%A0500",
  "label": "Pages d'erreur 404 et 500",
  "parent": null
}, {
  "depth": 0,
  "id": "page-d-invite-de-mot-de-passe",
  "label": "Page d'invite de mot de passe",
  "parent": null
}, {
  "depth": 2,
  "id": "url-et-page-de-r%C3%A9sultats-de-recherche",
  "label": "URL et page de résultats de recherche",
  "parent": "page-d-invite-de-mot-de-passe"
}, {
  "depth": 0,
  "id": "param%C3%A8tres-des-th%C3%A8mes",
  "label": "Paramètres des thèmes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      h4: "h4",
      code: "code",
      em: "em",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Paramètres du site web"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La section Paramètres du site web est un espace centralisé où divers paramètres de contenu global et systémiques peuvent être configurés pour le blog, la navigation, les pages et les thèmes de votre site web. Accédez à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/",
        children: "Paramètres > Site web"
      }), " et choisissez la zone de contenu pour laquelle vous souhaitez accéder à vos paramètres de contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres du blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans cette section, vous contrôlerez les paramètres de contenu pour votre ou vos blogs de site. Si vous avez plusieurs blogs, vous pouvez basculer entre ceux-ci à l'aide du menu déroulant sous l'en-tête « Sélectionner un blog à modifier »."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg",
        alt: "Écran des paramètres du blog"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Général"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Nom du blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les variables HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ content.blog }}"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.public_title }}"
      }), " afficheront le nom défini ici."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "En-tête de blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.header }}"
      }), " affichera l'en-tête défini ici."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Titre de la page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.html_title }}"
      }), " HubL affichera le titre défini ici."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Description des métadonnées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.description }}"
      }), " HubL affichera la description définie ici. Cette méta-description sera utilisée sur les pages de listing de blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL racine du blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'URL racine du blog précédera les slugs d'article de blog individuels. La variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.absolute_url }}"
      }), " HubL affichera l'URL définie ici."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Contrôle de l'accès de l'audience"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez contrôler l'accès de l'audience à un blog entier via ce paramètre. Cliquez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
        children: "ici"
      }), " pour obtenir davantage d'informations à ce sujet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Modèles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modèle actuel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "C'est le modèle utilisé pour tous les articles de blog dans un blog particulier. Le même modèle sera également utilisé par défaut pour les pages de listing de blog. Le contenu variable pour les pages de listing par rapport aux articles peut être rédigé dans le module Contenu de l'article."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modèle pour les pages de listing (facultatif)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce paramètre permet d'activer un autre modèle pour les pages de listing de blog, différent du modèle utilisé pour les articles de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Nombre d'articles par page de listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela détermine le nombre d'articles qui apparaissent sur une page de listing de blog par défaut."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML d'en-tête"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tout code ajouté au HTML d'en-tête du listing de blog sera ajouté à toutes les pages de listing via la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), ". Tout code ajouté au HTML d'en-tête d'article de blog sera ajouté à tous les articles de blog via la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML de pied de page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tout code ajouté au HTML de pied de page du listing de blog sera ajouté à toutes les pages de listing via la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), ". Tout code ajouté au HTML de pied de page d'article de blog sera ajouté à tous les articles de blog via la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Abonnements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "E-mails de notification d'abonné au blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les e-mails instantanés, quotidiens, hebdomadaires et mensuels de notification de blog peuvent être activés et modifiés via ce paramètre. Ces e-mails sont automatiquement envoyés si de nouveaux articles de blog sont publiés dans la période correspondante. Cliquez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-blog/set-up-your-blog-subscription-features-in-hubspot",
        children: "ici"
      }), " pour en savoir plus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Flux RSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le nombre d'articles dans le flux RSS du blog peut être déterminé via ce paramètre. Celui-ci ne peut pas dépasser 50 articles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Formats de date"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Langue pour les dates"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce paramètre détermine la langue des mois et des jours qui apparaissent dans les dates du blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Format de la date de publication"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le format défini ici détermine l'ordre et la structure des dates de publication dans les blogs. À l'aide du langage LDML, il est possible de spécifier un format de date personnalisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Format des articles par mois"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le format défini ici détermine l'ordre et la structure des articles par mois. À l'aide du langage LDML, il est possible de spécifier un format de date personnalisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Commentaires"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Activer ou désactiver les commentaires de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il est possible d'activer ou de désactiver les commentaires de blog via ce paramètre. Les commentaires peuvent nécessiter une modération ou être automatiquement fermés après un délai établi."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Notifications des commentaires de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les commentaires de blog peuvent déclencher des notifications par e-mail aux utilisateurs spécifiés via ce paramètre."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Partage sur les réseaux sociaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Compte Twitter par défaut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le compte Twitter spécifié ici sera utilisé pour les cartes Twitter lorsque le contenu est partagé sur Twitter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Boutons de partage sur les réseaux sociaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les boutons de partage sur Twitter, LinkedIn et Facebook peuvent être automatiquement ajoutés aux articles de blog via ce paramètre."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet AMP de Google"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les pages mobiles accélérées (AMP) chargent instantanément votre contenu. Cliquez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "ici"
      }), " pour en savoir plus. Afin de charger le contenu aussi rapidement, une expérience de page simplifiée est requise. C'est pourquoi le contenu AMP a un contrôle de style limité."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Activer ou désactiver le format AMP de Google"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les pages au format AMP de Google peuvent être activées via ce paramètre. Paramètres AMP du logo, de la police et de la couleur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Paramètres spécifiques aux pages AMP pour un logo, un formatage de l'en-tête"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Afin de fournir du contenu AMP, un style simplifié est nécessaire. Déterminez des styles spécifiques aux pages AMP pour le logo, le formatage d'en-tête, les polices et les couleurs à l'aide de ces paramètres."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres de navigation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez gérer les liens et les libellés de votre menu dans cette zone. Vous pouvez basculer entre les menus que vous souhaitez configurer en choisissant le menu déroulant et en sélectionnant le menu souhaité. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/set-up-your-site-s-navigation-menus",
        children: "Découvrez-en davantage sur la configuration des menus de navigation de vos sites ici."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/navigation-settings-screen-4.jpg",
        alt: "Écran des paramètres de navigation"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres de page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les paramètres sont répartis par domaine et les valeurs par défaut pour tous les domaines peuvent être définies. Les paramètres « ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Par défaut pour tous les paramètres domains"
      }), "” s'affichent lorsque vous accédez aux paramètres des pages. Un bouton figure en haut de l'écran pour afficher et modifier les paramètres pour des sous-domaines spécifiques. Les paramètres appliqués à des sous-domaines spécifiques remplaceront les paramètres par défaut de tous les domaines."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Seuls les utilisateurs disposant de l'autorisation marketing « ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "Modifier les paramètres du site"
      }), " » peuvent accéder aux paramètres de contenu et les modifier."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/page-settings-screen-4.jpg",
        alt: "Écran des paramètres de page"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Modèles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML d'en-tête du site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tout code ajouté dans le champ HTML d'en-tête du site dans les paramètres de page sera inclus dans la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML de pied de page du site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tout code ajouté dans le champ HTML du pied de page du site dans les paramètres de page sera inclus dans la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), ". Généralement, il s'agit d'un bon endroit pour ajouter des codes de suivi et d'autres scripts « non essentiels » au fonctionnement de votre site ou qui semblent corrects. Ainsi, cela n'aura pas d'impact négatif sur vos modèles ou vos pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez modifier le chargement de la version jQuery sur votre page via les paramètres de page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également choisir de charger jQuery à partir de votre pied de page via ce paramètre. L'ajout de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " à votre URL de page vous permettra de tester ce changement et de vous assurer qu'il n'a pas d'impact négatif sur l'apparence de votre site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'option de désactivation de jQuery se trouve également dans les paramètres de page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Branding"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'image du logo définie pour chaque domaine ici sera automatiquement utilisée dans le module « Logo » par défaut."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Favicon"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Logo (texte alternatif, dimensions, lien)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Votre URL source d'image favicon peut être extraite du dictionnaire ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings"
      }), " et utilisée dans vos fichiers codés :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if site_settings.favicon_src %}<link rel=\"icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Personnalisation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Valeurs par défaut pour les contacts et entreprises"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ces valeurs par défaut sont utilisées pour les jetons de personnalisation utilisés sur vos pages lorsque le visiteur est inconnu."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Intégration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Intégrations JS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Activez les intégrations JS (telles que Google Analytics ou AdRoll) pour tous les domaines ou sélectionnez des domaines ici. Si vous utilisez d'autres scripts de suivi ou Google Tag Manager à la place de Google Analytics, ce code doit être ajouté au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#site-footer-html",
        children: "HTML de pied de page du site"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet SEO et robots d'indexation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL canonique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sélectionnez votre paramètre de canonisation par défaut pour les pages individuelles et les articles ainsi que les pages de listing ici."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Domaine d'hébergement de fichier par défaut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela détermine le domaine où les éléments du gestionnaire de fichiers semblent être hébergés."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Fichier robots.txt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modifiez le fichier robots.txt pour chaque domaine ici."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Onglet Pages système"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour plusieurs pages système, vous pouvez sélectionner vos modèles dans les paramètres de page. Aucun éditeur de page n'existe pour ces pages, seuls les modèles du gestionnaire de conception qui sont créés avec le type de modèle « Système ». ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Remarque"
        })
      }), "_ : Les préférences d'e-mail et les modèles d'abonnement se trouvent dans les paramètres d'e-mail et non dans les paramètres de page._"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pages d'erreur 404 et 500"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce sont les pages qui sont renvoyées pour les codes de statut 404 ou 500. Lorsque vous créez un nouveau modèle, sélectionnez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#error-pages",
        children: "type de modèle « Page d'erreur »"
      }), " dans le gestionnaire de conception pour restituer un modèle disponible pour ces pages système."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Page d'invite de mot de passe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il s'agit de la page renvoyée pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "les pages protégées par mot de passe"
      }), " lorsqu'un visiteur est invité à saisir un mot de passe. Lorsque vous créez un nouveau modèle, sélectionnez le type de modèle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#password-prompt",
        children: "« Page d'invite de mot de passe »"
      }), " dans le gestionnaire de conception pour rendre un modèle disponible pour ce type de page système."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL et page de résultats de recherche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il s'agit de la page qui répertorie les résultats de recherche pour les requêtes saisies dans le module ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-do-i-set-up-a-results-page-for-my-search-field-in-hubspot",
        children: "Recherche sur le site web"
      }), " de HubSpot. Pour en savoir plus sur la personnalisation de votre recherche, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "cliquez ici"
      }), ". Lorsque vous créez un nouveau modèle, sélectionnez le type de modèle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#search-results-page",
        children: "« Page de résultats de recherche »"
      }), " dans le gestionnaire de conception pour créer un modèle pour ce type de page système. Vous pouvez également déterminer l'URL de votre page de résultats de recherche dans les paramètres de page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres des thèmes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ici, vous pouvez trouver tous les thèmes ajoutés à votre site. Vous pouvez accéder à l'éditeur de thèmes en cliquant sur l'un des thèmes disponibles sur votre site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/theme-settings-screen-4.jpg",
        alt: "Écran des paramètres de thème"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}