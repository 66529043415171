"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49334172328;
const slug = exports.slug = 'guides/cms/content/fields/best-practices';
const title = exports.title = 'Práticas recomendadas para campos de temas e módulos';
const name = exports.name = 'Práticas recomendadas para campos de temas e módulos';
const metaDescription = exports.metaDescription = 'A capacidade de criar e agrupar campos representa um problema para a experiência do usuário (UX). Recomendamos que você use esta página como um guia para fornecer uma experiência intuitiva aos criadores de conteúdo.';
const featuredImage = exports.featuredImage = 'https://www.hubspot.com/hubfs/options%20and%20style%20tabs.png';
const featuredImageAltText = exports.featuredImageAltText = 'Guia de campos de estilo do módulo no editor de páginas do CMS Hub';
const toc = exports.toc = [{
  "depth": 0,
  "id": "campos-de-estilo-vs.-campos-de-conte%C3%BAdo",
  "label": "Campos de estilo vs. campos de conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "organizar-campos",
  "label": "Organizar campos",
  "parent": null
}, {
  "depth": 1,
  "id": "exemplo",
  "label": "Exemplo",
  "parent": "organizar-campos"
}, {
  "depth": 0,
  "id": "campos-obrigat%C3%B3rios",
  "label": "Campos obrigatórios",
  "parent": null
}, {
  "depth": 0,
  "id": "tipografia",
  "label": "Tipografia",
  "parent": null
}, {
  "depth": 0,
  "id": "alternar-caixa-de-sele%C3%A7%C3%A3o-vs.-campos-booleanos",
  "label": "Alternar caixa de seleção vs. campos booleanos",
  "parent": null
}, {
  "depth": 0,
  "id": "artigos-relacionados",
  "label": "Artigos relacionados",
  "parent": null
}, {
  "depth": 2,
  "id": "tutoriais",
  "label": "Tutoriais",
  "parent": "artigos-relacionados"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      h2: "h2",
      a: "a",
      em: "em",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Práticas recomendadas para campos de módulos e temas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao incorporar campos ao seu módulo ou tema, há algumas práticas recomendadas a serem consideradas, incluindo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Agrupar campos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Organizar campos logicamente entre módulos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Proporcionar uma experiência consistente de estilo com campos de estilo"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neste artigo, conheça algumas práticas recomendadas para criar uma experiência eficiente e consistente de edição de módulos e campos do tema para os criadores de conteúdo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos de estilo vs. campos de conteúdo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode adicionar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "campos de estilo"
      }), " a módulos e temas. Eles permitem que o criador de conteúdo ajuste a aparência do módulo, como bordas ou imagens de plano de fundo atrás do texto. Os campos de estilo não devem transmitir significado ou ser necessários para entender o conteúdo da página. Por exemplo, se você tiver um texto e um módulo de imagem e a imagem for necessária para compreender o conteúdo do texto, a imagem deverá ser um campo de conteúdo em vez de um campo de estilo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando adicionado a um módulo, as opções de estilo aparecerão na guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estilos"
      }), " do editor de páginas. Quando adicionadas a um tema, as opções de estilo aparecerão na barra lateral esquerda do editor de temas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/style-field-module-editor0.png",
        alt: "style-field-module-editor0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Tenha a acessibilidade em mente ao decidir entre usar uma imagem ou um campo de imagem de plano de fundo. Se o criador do conteúdo tiver a capacidade de adicionar texto alternativo, use um campo de imagem em vez de um campo de imagem de plano de fundo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Organizar campos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A maneira como você organiza os campos desempenha um papel significativo na capacidade do criador do conteúdo de fazer alterações rapidamente. Veja a seguir as práticas recomendadas para a organização de campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Agrupar campos de estilo com base no componente que eles controlam, em vez do elemento estilístico."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Deixe apenas os campos mais importantes e de nível mais elevado fora dos grupos."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Prefira criar um grupo de componentes em vez de grupos não aninhados. Se você precisar adicionar funcionalidade ao módulo posteriormente, não poderá mover os módulos para um grupo sem atualizar manualmente todas as instâncias do módulo nas páginas."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Classificar grupos de campos na ordem em que os componentes aparecem com base no idioma principal da maioria dos criadores de conteúdo que manterão o site. Exemplo: o inglês é lido da esquerda para a direita, de cima para baixo. Se os usuários que mantêm o site normalmente leem da direita para a esquerda em seu idioma, você deve fornecê-lo nessa ordem. Em caso de dúvida, baseie isso no idioma principal do conteúdo do seu site."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#field-groups",
        children: "grupos de campos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja a seguir um exemplo de módulo de cartão."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/card%20and%20style%20settings.png",
        alt: "Um layout de cartão típico contendo um ícone, texto e um botão. As opções de estilo são mostradas agrupadas à direita nas categorias Ícone, Botão e Cartão."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O painel de estilos agrupa os campos do módulo em três grupos com base nos componentes do cartão que podem ter o estilo definido."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ícone"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Botão"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cartão"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao visualizar o cartão, o ícone é visto primeiro, seguido do texto e do botão. O grupo Ícone e suas opções de estilo são exibidos antes do grupo Botão e de suas opções de estilo. Portanto, o campo de cor para o ícone seria encontrado no grupo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ícone"
      }), ", enquanto o campo de cor para a cor de fundo do botão seria encontrado dentro do grupo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Botão"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos obrigatórios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Campos obrigatórios são campos que o criador do conteúdo deve definir para exibir o módulo e publicar a página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Somente exija que os campos tenham um valor se eles quebrarem o módulo caso não tenham um valor."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você precisa ter um campo obrigatório, forneça um valor padrão, se possível."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, você está criando um módulo de carrossel de imagens que permite configurar quantos slides serão exibidos ao mesmo tempo. Um valor mínimo de 1 é necessário e, sem um valor, não é possível saber como exibir o carrossel de imagens. Essa é uma situação em que pode fazer sentido exigir um valor, mas definir um padrão de um ou dois."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipografia"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como os campos de rich text oferecem mais controle tipográfico, é recomendado usar campos de rich text em vez de uma combinação de campo de texto e campo de fonte quando possível."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pode haver casos em que você precisa fornecer estilos tipográficos que se aplicam a várias partes do conteúdo em um mesmo módulo, como um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#text",
        children: "campo de texto"
      }), " destinado a cabeçalhos. Nesse caso, talvez você possa tornar o trabalho do criador do conteúdo mais eficiente fornecendo campos de estilo para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#font",
        children: "fonte"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#color",
        children: "cor"
      }), " no campo de texto."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao incluir campos de rich text, é recomendado permitir que os controles tipográficos do campo substituam os campos de estilo adicionados manualmente. Se um campo de estilo controlar um campo de rich text, talvez seja útil definir o texto de ajuda para garantir que o criador do conteúdo esteja ciente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alternar caixa de seleção vs. campos booleanos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao incluir um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#boolean",
        children: "campo booleano"
      }), ", você pode configurá-lo para ser exibido como um botão ou caixa de seleção."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Defina o booleano para alternar quando o campo controla uma opção principal de design ou de layout. Por exemplo, converter o layout de um cartão de uma orientação vertical para horizontal."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Defina o booleano como uma caixa de seleção quando a alteração for menos drástica. Por exemplo, ocultar ou mostrar uma data de publicação para um post de blog em um módulo de posts de blog em destaque."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Artigos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields",
          children: "Tipos de campos de tema e módulo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Visão geral dos campos de módulo e tema"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Visão geral dos módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview",
          children: "Visão geral dos temas"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tutoriais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Introdução aos módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Introdução aos temas"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}