"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 68732366656;
const slug = exports.slug = 'guides/api/settings/account-information-api';
const title = exports.title = 'Account Information API';
const name = exports.name = 'vNext Docs DP - Account Information';
const metaDescription = exports.metaDescription = "HubSpot's account information API endpoints provide information about a given HubSpot account. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "check-daily-api-usage-and-limits-for-a-private-app",
  "label": "Check daily API usage and limits for a private app",
  "parent": null
}, {
  "depth": 0,
  "id": "get-account-details-for-a-hubspot-account",
  "label": "Get account details for a HubSpot account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Account information"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following endpoints provide information about a given HubSpot account, including the account settings, and the daily API usage and limits."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Check daily API usage and limits for a private app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use this endpoint to see how many API calls a private app has made for the current day, and the API usage limits for that account. The current day is measured from midnight to midnight based on the connected account's time zone settings. Read more about HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "API usage guidelines"
      }), " and the API ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/apisbytier",
        children: "request limits"
      }), " for each HubSpot subscription type."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get account details for a HubSpot account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use this endpoint to find the following information for a given HubSpot account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The account's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
          children: "portal ID"
        }), " (also called the Hub ID)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/change-your-language-and-region-settings",
          children: "time zone set"
        }), " in the account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/add-and-edit-your-account-currencies",
          children: "company currency and additional currencies"
        }), " added to the account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
          children: "data hosting location"
        }), " for the account."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}