"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 44206971922;
const slug = exports.slug = 'getting-started/slack/code-of-conduct';
const title = exports.title = 'Code de conduite de la communauté Slack pour les développeurs HubSpot';
const name = exports.name = 'Code de conduite de Slack pour les développeurs';
const metaDescription = exports.metaDescription = 'Slack pour les développeurs est une communauté HubSpot dédiée aux développeurs les plus engagés de HubSpot qui permet de se rassembler dans le but de soutenir les développeurs et de collaborer autour de la plateforme HubSpot.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Collaborer dans Slack pour les développeurs';
const toc = exports.toc = [{
  "depth": 0,
  "id": "pr%C3%A9ambule",
  "label": "Préambule",
  "parent": null
}, {
  "depth": 0,
  "id": "mises-%C3%A0-jour-du-pr%C3%A9sent-code-de-conduite",
  "label": "Mises à jour du présent Code de conduite",
  "parent": null
}, {
  "depth": 0,
  "id": "attentes-en-mati%C3%A8re-d-assistance-hubspot",
  "label": "Attentes en matière d'assistance HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "comportement-attendu",
  "label": "Comportement attendu",
  "parent": null
}, {
  "depth": 1,
  "id": "faire-preuve-d-humilit%C3%A9",
  "label": "Faire preuve d'humilité",
  "parent": "comportement-attendu"
}, {
  "depth": 1,
  "id": "faire-preuve-d-empathie",
  "label": "Faire preuve d'empathie",
  "parent": "comportement-attendu"
}, {
  "depth": 1,
  "id": "s-adapter",
  "label": "S'adapter",
  "parent": "comportement-attendu"
}, {
  "depth": 1,
  "id": "%C3%AAtre-remarquable",
  "label": "Être remarquable",
  "parent": "comportement-attendu"
}, {
  "depth": 1,
  "id": "faire-preuve-de-transparence",
  "label": "Faire preuve de transparence",
  "parent": "comportement-attendu"
}, {
  "depth": 0,
  "id": "comportement-inacceptable",
  "label": "Comportement inacceptable",
  "parent": null
}, {
  "depth": 1,
  "id": "menaces-de-violence",
  "label": "Menaces de violence",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "attaques-personnelles",
  "label": "Attaques personnelles",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "usurpation-d-identit%C3%A9-d-un-employ%C3%A9-de-hubspot",
  "label": "Usurpation d'identité d'un employé de HubSpot",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "langage-d%C3%A9rogatoire%2Fpr%C3%A9judiciable-et-harc%C3%A8lement",
  "label": "Langage dérogatoire/préjudiciable et harcèlement",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "sollicitation-non-d%C3%A9sir%C3%A9e-de-services",
  "label": "Sollicitation non désirée de services",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "appels-vocaux%2Fvid%C3%A9o-abusifs",
  "label": "Appels vocaux/vidéo abusifs",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "utilisation-non-d%C3%A9clar%C3%A9e-de-l-ia-dans-les-questions%2C-les-r%C3%A9ponses-ou-les-conversations",
  "label": "Utilisation non déclarée de l'IA dans les questions, les réponses ou les conversations",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "contenu-ind%C3%A9sirable-sur-les-canaux-et-pour-les-utilisateurs",
  "label": "Contenu indésirable sur les canaux et pour les utilisateurs",
  "parent": "comportement-inacceptable"
}, {
  "depth": 1,
  "id": "incitation-%C3%A0-un-comportement-inacceptable",
  "label": "Incitation à un comportement inacceptable",
  "parent": "comportement-inacceptable"
}, {
  "depth": 0,
  "id": "utilisation-de-slack-pour-les-d%C3%A9veloppeurs-pour-les-conversations-priv%C3%A9es-de-l-%C3%A9quipe",
  "label": "Utilisation de Slack pour les développeurs pour les conversations privées de l'équipe",
  "parent": null
}, {
  "depth": 0,
  "id": "appels-vocaux%2Fvid%C3%A9o-et-partage-d-%C3%A9cran",
  "label": "Appels vocaux/vidéo et partage d'écran",
  "parent": null
}, {
  "depth": 0,
  "id": "utilisation-des-applications-slack",
  "label": "Utilisation des applications Slack",
  "parent": null
}, {
  "depth": 0,
  "id": "regrouper-les-sujets-messages-group%C3%A9s",
  "label": "Regrouper les sujets (messages groupés)",
  "parent": null
}, {
  "depth": 0,
  "id": "gif-et-%C3%A9mojis",
  "label": "GIF et émojis",
  "parent": null
}, {
  "depth": 0,
  "id": "sondages-et-enqu%C3%AAtes-aupr%C3%A8s-des-utilisateurs-de-slack-pour-les-d%C3%A9veloppeurs",
  "label": "Sondages et enquêtes auprès des utilisateurs de Slack pour les développeurs",
  "parent": null
}, {
  "depth": 0,
  "id": "signalement-des-violations-du-code-de-conduite",
  "label": "Signalement des violations du Code de conduite",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Code de conduite de Slack pour les développeurs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Préambule"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Slack pour les développeurs est une communauté HubSpot dédiée aux développeurs de HubSpot qui permet de se rassembler dans le but de soutenir les développeurs et de collaborer autour de la plateforme HubSpot. En utilisant Slack pour les développeurs, vous acceptez de respecter le Code de conduite de Slack pour les développeurs (ci-après le « Code de conduite »), la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "Politique d'utilisation acceptable de HubSpot"
      }), " le cas échéant, et la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://slack.com/acceptable-use-policy",
        children: "Politique d'utilisation acceptable de Slack"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mises à jour du présent Code de conduite"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se réserve le droit de mettre à jour ce Code de conduite à tout moment. Lorsque des mises à jour ont lieu, nous nous engageons à communiquer ces changements via le canal #releases-and-updates sur Slack pour les développeurs. Toutefois, ce Code de conduite étant rédigé en anticipant des scénarios courants, et ne pouvant pas anticiper tous les scénarios possibles qui pourraient se produire, nous pouvons avoir besoin de le mettre à jour et de prendre des mesures sur des violations qui peuvent ne pas être explicitement décrites ici, sans notification préalable à la communauté."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se réserve le droit de déclasser ou de désactiver Slack pour les développeurs à tout moment, mais s'engage à le faire en prévenant la communauté suffisamment à l'avance."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Attentes en matière d'assistance HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Slack pour les développeurs n'est pas un canal d'assistance officiel de HubSpot. Bien qu'il puisse contenir des employés de HubSpot qui seront heureux de vous aider dès qu'ils le pourront, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "veuillez ne pas mentionner ni envoyer des messages aux employés de HubSpot pour des questions directes"
      }), ". Si vous avez une question pour laquelle vous avez besoin d'une assistance directe de la part de HubSpot, veuillez consulter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://help.hubspot.com/",
        children: "help.hubspot.com"
      }), " ou les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/",
        children: "forums de la communauté"
      }), ". HubSpot se réserve le droit d'appliquer, d'interpréter et d'étendre ces dispositions pour l'amélioration de la communauté."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportement attendu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Chez HubSpot, nous n'avons pas de pages de politiques ou de procédures, mais une politique qui tient en une phrase : « Utilisez votre bon sens. » Nous encourageons nos communautés à faire preuve de discernement et à partager les valeurs fondamentales suivantes de HubSpot :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Faire preuve d'humilité"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rien n'est parfait. Soyez conscient de vous-même et soyez toujours respectueux."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Faire preuve d'empathie"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ne vous contentez pas d'être compréhensif à l'égard des commentaires de quelqu'un, agissez également avec compassion et respect pour tous."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "S'adapter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Soyez curieux et toujours prêt à changer. Apprenez constamment."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Être remarquable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour reprendre les mots du sage Bill S. Preston, Esquire, « Soyez excellents les uns envers les autres ». Partagez vos connaissances et n'ayez pas peur d'être génial."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Faire preuve de transparence"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Soyez ouvert et honnête avec les autres, et surtout avec vous-même."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur nos valeurs, veuillez consulter le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/34234/the-hubspot-culture-code-creating-a-company-we-love.aspx",
        children: "Code culturel de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportement inacceptable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les comportements suivants sont considérés comme inacceptables et ne seront pas tolérés par les utilisateurs de l'espace de travail Slack."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Menaces de violence"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les menaces de violence à l'encontre d'un autre utilisateur ne sont pas acceptables. Cela inclut également l'incitation à la violence à l'égard d'un ou de plusieurs utilisateurs ou la (menace de) publication d'informations d'identification personnelle d'autres personnes (également appelée « doxxing »)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Attaques personnelles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il y aura toujours des moments où deux parties auront une divergence d'opinion. Bien que cela puisse être frustrant, cela ne doit jamais se transformer en une attaque personnelle contre quelqu'un. Il est inacceptable d'attaquer des personnes pour leurs opinions, leurs croyances personnelles, leurs idées ou leurs propositions. Veuillez toujours faire preuve de professionnalisme et de respect."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usurpation d'identité d'un employé de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tout utilisateur usurpant l'identité d'un employé de HubSpot sera banni de l'espace de travail Slack. Aucune tolérance ne sera accordée."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Langage dérogatoire/préjudiciable et harcèlement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Toute injure à l'égard d'un utilisateur sur :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Milieu social"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Famille"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Identité ou expression de genre"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Orientation sexuelle"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Origine ethnique"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Traits physiques (taille, poids, cicatrices, etc.)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Âge"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Langue maternelle"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Capacités"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Nationalité"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Localisation"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Religion"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Affiliation politique"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Autres caractéristiques personnelles"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "est jugée inacceptable. Faites toujours preuve de bon sens."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sollicitation non désirée de services"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il est inacceptable d'envoyer un message aux utilisateurs en leur proposant directement vos services, à moins que l'utilisateur n'ait spécifiquement demandé cette information. Cela inclut également les messages directs concernant des offres d'emploi ou des projets en freelance. Nous avons créé un canal #jobs à l'intérieur de notre espace de travail Slack, où ce type de messages peut être publié, ainsi qu'un canal #showcase pour mettre en avant tout travail HubSpot dont vous êtes fier auprès de la communauté. Ce sont les deux seuls canaux où toute forme de sollicitation ou d'autopromotion est autorisée."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Appels vocaux/vidéo abusifs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack permet l'utilisation d'appels vocaux/vidéo au sein de sa plateforme. Il est inacceptable d'abuser de cette fonction en appelant directement les utilisateurs sans leur autorisation. Nous comprenons que des erreurs se produisent parfois et que des boutons soient cliqués par inadvertance. Si cela se produit, faites preuve de professionnalisme et envoyez simplement un message d'excuse à l'utilisateur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utilisation non déclarée de l'IA dans les questions, les réponses ou les conversations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous avez l'intention d'utiliser un outil d'IA pour générer du contenu qui sera utilisé sur Slack pour les développeurs, vous devez clairement déclarer l'utilisation de ces outils à chaque fois que vous les utilisez. Vous devez également faire attention aux informations que vous fournissez à un outil d'IA et ne pas partager d'informations confidentielles ou sensibles de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Contenu indésirable sur les canaux et pour les utilisateurs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack pour les développeurs comporte de nombreux canaux adaptés à certaines fonctionnalités de notre plateforme et à de nombreux utilisateurs. Lorsque vous publiez votre message, veuillez utiliser le canal approprié. Il est inacceptable de simplement copier/coller votre message sur plusieurs canaux pour une meilleure visibilité. Il est inacceptable de publier fréquemment le même message ou un message similaire dans le même canal et/ou de spammer directement les utilisateurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incitation à un comportement inacceptable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il est inacceptable d'inciter un ou plusieurs utilisateurs à adopter l'un des comportements inacceptables énumérés ci-dessus."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation de Slack pour les développeurs pour les conversations privées de l'équipe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En rejoignant Slack pour les développeurs, vous vous engagez à contribuer à la communauté des développeurs HubSpot. Aucun sujet de conversation n'est déconseillé (s'il respecte le présent Code de conduite), mais nous vous demandons de ne pas utiliser Slack pour les développeurs pour mener des activités professionnelles privées par le biais de la messagerie directe ou d'autres moyens. Si un tel comportement est constaté, nous nous réservons le droit de vous retirer, vous et votre équipe, de Slack pour les développeurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Appels vocaux/vidéo et partage d'écran"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack permet aux utilisateurs d'utiliser les appels vocaux/vidéo avec la possibilité supplémentaire de partager l'écran. Lorsque vous utilisez ces fonctions, soyez attentifs et respectueux des autres. Faites preuve de discernement lorsque vous partagez votre écran et soyez attentif aux autres fenêtres qui peuvent être ouvertes sur votre ordinateur et qui contiennent des informations sensibles ou personnelles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation des applications Slack"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack permet d'ajouter des applications à l'espace de travail afin d'étendre les fonctionnalités de Slack. Nous n'autorisons pas les utilisateurs à installer eux-mêmes des applications dans l'espace de travail Slack pour les développeurs. Toutes les installations d'applications dans l'espace de travail seront effectuées par HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Regrouper les sujets (messages groupés)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'une des fonctionnalités les plus intéressantes de Slack est la possibilité de créer des fils de discussion. Nous vous recommandons d'utiliser cette fonction pour faciliter les conversations et les rendre facilement consultables. Cela permet de garder les canaux principaux propres et de regrouper les sujets de conversation en un seul endroit."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "GIF et émojis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous utilisez des GIF à l'intérieur de canaux ou fils de commentaires, faites attention au contenu/contexte du GIF. De même, Slack offre la possibilité d'utiliser des émojis pour exprimer des émotions dans les commentaires. Faites attention aux émojis que vous utilisez lorsque vous communiquez avec d'autres personnes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sondages et enquêtes auprès des utilisateurs de Slack pour les développeurs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'espace de travail Slack pour les développeurs est destiné à être utilisé pour des conversations sur le développement sur le web et le développement sur HubSpot. La création d'enquêtes est autorisée et nous proposons l'application Polly Slack dans notre espace de travail afin que tout le monde puisse l'utiliser."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de l'utilisation de Polly, nous demandons ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le sondage doit être lié au développement sur le web (comme les bibliothèques CSS ou JS) ou au développement sur HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "\"La case Afficher les réponses doit toujours rester cochée."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les éléments suivants ne sont pas autorisés lorsque vous interrogez des personnes sur Slack pour les développeurs :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sollicitation d'informations privées, y compris des informations démographiques, médicales ou d'autres informations personnelles concernant les utilisateurs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Lien vers des enquêtes hébergées sur des sites tiers (tels que des formulaires Google, des formulaires Survey Monkey ou des sites web personnels)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envoi de messages directs aux utilisateurs pour leur demander de répondre à des enquêtes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "* = Les employés HubSpot conservent le droit d'effectuer des enquêtes dans Slack pour les développeurs dans le cadre de l'amélioration du produit HubSpot. Les employés HubSpot s'efforceront d'indiquer clairement que leur enquête est une enquête officielle de HubSpot si elle est hébergée ailleurs."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Signalement des violations du Code de conduite"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour signaler une violation du présent Code de conduite ou tout autre fait qui vous aurait mis ou aurait mis d'autres personnes mal à l'aise au sein de la communauté des développeurs de HubSpot, vous pouvez vous adresser directement à l'un de nos administrateurs. Tous les administrateurs actuels seront inclus dans un message épinglé dans le canal #general."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous préférez signaler votre violation de manière anonyme ou ne pas envoyer de message à un administrateur, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://form.asana.com/",
        children: "remplir ce formulaire"
      }), " et nous en serons immédiatement informés."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}