"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611089;
const slug = exports.slug = 'reference/cms/serverless-functions';
const title = exports.title = 'Serverless Reference';
const name = exports.name = 'Serverless Reference';
const metaDescription = exports.metaDescription = 'Reference information for your serverless.json, function files, endpoints, CLI commands, and managing packages all in one place.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "serverless.json",
  "label": "Serverless.json",
  "parent": null
}, {
  "depth": 1,
  "id": "endpoints",
  "label": "Endpoints",
  "parent": "serverless.json"
}, {
  "depth": 0,
  "id": "function-file",
  "label": "Function file",
  "parent": null
}, {
  "depth": 1,
  "id": "context-object",
  "label": "Context object",
  "parent": "function-file"
}, {
  "depth": 1,
  "id": "headers",
  "label": "Headers",
  "parent": "function-file"
}, {
  "depth": 2,
  "id": "redirect-by-sending-a-header",
  "label": "Redirect by sending a header",
  "parent": "function-file"
}, {
  "depth": 1,
  "id": "set-cookies-from-your-endpoint",
  "label": "Set cookies from your endpoint",
  "parent": "function-file"
}, {
  "depth": 1,
  "id": "set-multiple-values-for-a-single-header",
  "label": "Set multiple values for a single header",
  "parent": "function-file"
}, {
  "depth": 0,
  "id": "secrets",
  "label": "Secrets",
  "parent": null
}, {
  "depth": 0,
  "id": "using-serverless-functions-with-the-form-element",
  "label": "Using serverless functions with the form element",
  "parent": null
}, {
  "depth": 0,
  "id": "cors",
  "label": "CORS",
  "parent": null
}, {
  "depth": 1,
  "id": "get-requests",
  "label": "Get requests",
  "parent": "cors"
}, {
  "depth": 0,
  "id": "preloaded-packages",
  "label": "Preloaded packages",
  "parent": null
}, {
  "depth": 0,
  "id": "limits",
  "label": "Limits",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      h4: "h4",
      em: "em",
      ul: "ul",
      li: "li",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Serverless functions reference"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you're building a serverless function as a part of a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "developer project"
        }), ", visit the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "developer projects serverless function documentation"
        }), " instead. The documentation below is for building serverless functions outside of the developer project platform."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this article, learn about the files found inside of a ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: ["serverless ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), " folder"]
      }), " and the CLI commands you can use with serverless functions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For a high-level overview of serverless functions, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverless functions overview"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For more information about building serverless functions with projects for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "JavaScript rendered modules and partials"
      }), ", see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "developer projects documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), " folder"]
      }), ", the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " file stores the serverless function configuration. This is a required file, and maps your functions to their ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#endpoints",
        children: "endpoints"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// serverless.json\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"environment\": {\n    \"globalConfigKey\": \"some-value\"\n  },\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"events\": {\n      \"file\": \"function1.js\",\n      \"method\": \"GET\"\n    },\n    \"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"CONFIG_KEY\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleKeyName\", \"otherkeyname\"]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "runtime"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The runtime environment. Supports the following ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/en/about/",
              children: "Node.js"
            }), " versions:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Node 20 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs20.x"
                }), ") (recommended)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Node 18 (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs18.x"
                }), ")"]
              })]
            }), "Note that HubSpot will ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/changelog/deprecation-of-node-v16-in-all-serverless-functions",
              children: "no longer support Node 16"
            }), " beyond July 12, 2024."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot serverless function schema version. (Current version 1.0)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "environment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuration variables passed to the executing function as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
              children: "environment variables"
            }), " at runtime. You might use this to add logic for using a testing version of an API instead of the real thing based on an environment variable."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An array containing the names of the secrets your serverless function will use for authentication. Do not store secret values directly in this file, only reference secret names."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoints"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Endpoints define the paths that are exposed and their mapping to specific JavaScript files, within your functions folder. Learn more about endpoints below."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " do not assign the same name to your secrets and environment variables. Doing so will result in conflicts when returning their values in the function."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Endpoints"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each endpoint can have its own ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "environment variables"
      }), " and secrets. Variables specified outside of endpoints should be used for configuration settings that apply to all functions and endpoints."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"configKey\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleAPIKeyName\",\"otherKeyName\"]\n    }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Endpoints have a couple unique keys."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String or array of strings"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
              children: "HTTP method or methods"
            }), " that the endpoint supports. Defaults to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
              children: "GET"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Path to JavaScript function file with the implementation for the endpoint."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverless functions are exposed through a path at your HubSpot CMS account’s domain. This includes default ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-sites.com"
      }), " sub-domains."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can access these functions at the following URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://{domainName}/_hcms/api/{endpoint-name/path}?portalid={hubId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about each URL component:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "domainName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your domain name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/_hcms/api/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The path reserved for serverless functions. All endpoints exist inside this path."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoint-name/path"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The endpoint name or path that you specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), " file."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your Hub ID. Providing this in the request will enable you to test your functions within module and template previews."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Function file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " configuration file, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " folder will also contain a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " JavaScript file that defines the function. You can also leverage the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/request/request#readme",
        children: "request"
      }), " library to make HTTP request to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "HubSpot APIs"
      }), " and other APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Require axios library, to make API requests.\nconst axios = require('axios');\n// Environment variables from your serverless.json\n// process.env.globalConfigKey\n\nexports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n\n  // context.params\n  // context.body\n  // context.accountId\n  // context.limits\n\n  // secrets created using the CLI are available in the environment variables.\n  // process.env.secretName\n\n  //sendResponse is what you will send back to services hitting your serverless function.\n  sendResponse({ body: { message: 'my response' }, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Context object"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The context object contains contextual information about the function's execution, stored in the following parameters."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The HubSpot account ID containing the function."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Populated if the request is sent as a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " with a content type of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "application/json"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["If the request is from a cookied contact, the contact object will be populated with a set of basic contact properties along with the following information:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": The contact’s visitor ID."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "isLoggedIn"
                }), ": when using CMS Memberships, this will be ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " if the contact is logged in to the domain."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "listMemberships"
                }), ": an array of contact list IDs that this contact is a member of."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contains the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#headers",
              children: "headers"
            }), " sent from the client hitting your endpoint."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "params"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Populated with query string values along with any HTML Form-POSTed values. These are structured as a map with strings as keys and an array of strings for each value.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "context.params.yourvalue"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limits"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Returns how close you are to hitting the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview#know-your-limits",
              children: "serverless function rate limits"
            }), ".", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "executionsRemaining"
                }), ": how many executions per minute are remaining."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "timeRemaining"
                }), ": how much allowed execution time is remaining."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Headers"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you need to know the headers of the client that's hitting your endpoint, you can access them through ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.headers"
      }), ", similar to how you would access information through ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.body"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, review some of the common headers that HubSpot provides. For a full list, see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers",
        children: "MDN's HTTP headers documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "header"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communicates which content types expressed as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types",
              children: "MIME types"
            }), ", the client understands. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-encoding"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communicates the content encoding the client understands. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-encoding",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communicates which human language and locale is preferred. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-language",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cache-control"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Holds directives for caching. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "connection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communicates whether the network connection stays open. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/connection",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cookie"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contains cookies by sent the client. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/cookie",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communicates the domain name and TCP port number of a listening server. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/host",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true-client-ip"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["IP address of the end-user. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/206776727-What-is-True-Client-IP-",
              children: "See Cloudflare true-client-ip"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upgrade-insecure-requests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Communicates the clients preference for an encrypted and authenticated response. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Upgrade-Insecure-Requests",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "user-agent"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vendor defined string identifying the application, operating system, application vendor, and version. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent",
              children: "See MDN"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "x-forwarded-for"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Identifies the originating IP address of a client through a proxy or load balancer. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For",
              children: "See MDN"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Redirect by sending a header"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can perform a redirect from your serverless function by sending a response with a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "location"
      }), " header and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "301"
      }), " statusCode."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "sendResponse({\n  statusCode: 301,\n  headers: {\n    Location: 'https://www.example.com',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set cookies from your endpoint"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "From your serverless function you can tell the client (web browser) to set a cookie."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n    sendResponse({\n      body: { ... },\n      'Set-Cookie': 'myCookie1=12345; expires=...; Max-Age=...',\n      statusCode: 200\n    });\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set multiple values for a single header"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For headers that support multiple values, you can use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multiValueHeaders"
      }), ", to pass the values. For example: you can tell the browser to set multiple cookies."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  sendResponse({\n    body: { ... },\n    multiValueHeaders: {\n      'Set-Cookie': [\n        'myCookie1=12345; expires=...; Max-Age=...',\n        'myCookie2=56789; expires=...; Max-Age=...'\n       ]\n    },\n    statusCode: 200\n  });\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secrets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you need to authenticate a serverless function request, you'll use secrets to store values such as API keys or private app access tokens. Using the CLI, you can add secrets to your HubSpot account to store those values, which you can later access through environment variables (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "process.env.secretName"
      }), "). Secrets are managed through the HubSpot CLI using the following commands:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#list-secrets",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets list"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#add-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets add"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#remove-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets delete"
          })
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once added through the CLI, secrets can be made available to functions by including a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), " array containing the name of the secret. This enables you to store your function code in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "version control"
      }), " and use secrets without exposing them. However, you should ", (0, _jsxRuntime.jsx)("u", {
        children: "never"
      }), " return your secret's value through console logging or as a response, as this will expose the secret in logs or in front-end pages that call your serverless function."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " due to caching, it can take about one minute to see updated secret values. If you've just updated a secret but are still seeing the old value, check again after about a minute."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using serverless functions with the form element"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When submitting serverless functions use javascript to handle the form submission, and use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"contentType\" : \"application/json\""
      }), " header in your request. Do not use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<form>"
      }), " elements ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "action"
      }), " attribute."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CORS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cross Origin Resource Sharing (CORS)"
        })
      }), " is a browser security feature. By default browsers restrict cross-origin requests initiated by javascript. This prevents malicious code running on a different domain, from affecting your site. This is called the same-origin policy. Because sending and retrieving data from other servers is sometimes a necessity, the external server, can supply HTTP headers that communicate which origins are permitted to read the information from a browser."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You should not run into CORS issues calling your serverless function within your HubSpot hosted pages. If you do, verify you are using the correct protocol."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Getting this CORS error?"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "\"Access to fetch at [your function url] from origin [page making request] has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource. If an opaque response serves your needs, set the request's mode to 'no-cors' to fetch the resource with CORS disabled.\""
        })]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Is your request to a different origin than the site calling it?"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "If the domain name is different, yes."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "If using a different protocol(http, https), yes."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If using a different protocol, simply change the protocol to match and that will fix it."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can't modify HubSpot's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Access-Control-Allow-Origin"
        }), " header at this time."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors",
          children: "See MDN for further detailed CORS error troubleshooting."
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get requests"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Get requests may be able to make ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS",
        children: "CORS requests"
      }), " depending on the client. Do not make ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET",
        children: "GET"
      }), " requests write anything, just return data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preloaded packages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot serverless functions currently come preloaded with the following packages:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/@hubspot/api-client",
          children: "@hubspot/api-client"
        }), ": ^1.0.0-beta"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ": ^0.19.2"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        }), ": ^2.88.0"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/requests",
          children: "requests"
        }), ": ^0.2.2"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To use the latest supported version of a preloaded package, or to use a newly added package:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Clone or copy your function file."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Change your function's endpoint in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " file to point to your new function file. You can safely delete the old version."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to include packages outside of the preloaded package set, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), " to combine your node modules and have your bundled files be your function files."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serverless functions are intended to be fast and have a narrow focus. To enable quick calls and responses, HubSpot serverless functions have the following limits:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "50 secrets per account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128MB of memory."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No more than 100 endpoints per HubSpot account."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You must use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contentType"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), " when calling a function."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Serverless function logs are stored for 90 days."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6MB on an AWS Lambda invocation payload."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Execution limits"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Each function has a maximum of 10 seconds of execution time."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Each account is limited to 600 total execution seconds per minute."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This means either of these scenarios can happen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "60 function executions that take 10 seconds each to complete."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "6,000 function executions that take 100 milliseconds to complete."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Functions that exceed those limits will throw an error. Execution count and time limits will return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " response. The execution time of each function is included in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "serverless function logs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To assist in avoiding these limits, limit data is provided automatically to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: "function context"
      }), " during execution. You can use that to influence your application to stay within those limits. For example, if your application requires polling your endpoint, then you can return with your data a variable to influence the frequency of the polling. That way when traffic is high you can slow the rate of polling avoiding hitting limits, then ramp it back up when traffic is low."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}