"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 174650168411;
const slug = exports.slug = 'reference/ui-components/standard-components/loading-button';
const title = exports.title = 'LoadingButton | UI components';
const name = exports.name = 'LoadingButton | UI components';
const metaDescription = exports.metaDescription = 'Learn about the LoadingButton component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "opening-overlays",
  "label": "Opening overlays",
  "parent": null
}, {
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Table,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "LoadingButton | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LoadingButton"
      }), " component renders a button with loading state options. It includes the same props as the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button component"
      }), " with a few additional props for managing loading state."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-loadingbutton-example.gif",
        alt: "ui-extensions-loadingbutton-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import React from 'react';\nimport { useState } from 'react';\nimport { Flex, Heading, LoadingButton, hubspot } from '@hubspot/ui-extensions';\n\nhubspot.extend(({ actions }) => <Extension actions={actions} />);\n\nfunction fakeFetchContactName() {\n  return new Promise((resolve) => {\n    setTimeout(() => {\n      resolve({ firstname: 'Tom', lastname: 'Bombadil' });\n    }, 1000);\n  });\n}\n\nfunction Extension() {\n  const [isFetching, setIsFetching] = useState(false);\n  const [contactName, setContactName] = useState('');\n\n  async function handleClick() {\n    setIsFetching(true);\n    const { firstname, lastname } = await fakeFetchContactName();\n\n    setContactName(`${firstname} ${lastname}`);\n    setIsFetching(false);\n  }\n\n  return (\n    <Flex direction=\"column\" gap=\"md\" align=\"start\">\n      <LoadingButton loading={isFetching} onClick={handleClick}>\n        Fetch Name\n      </LoadingButton>\n      {!isFetching && contactName !== '' && <Heading>{contactName}</Heading>}\n    </Flex>\n  );\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Table, {
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("th", {
            children: "Prop"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "Type"
          }), "\n", (0, _jsxRuntime.jsx)("th", {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "String"
          }), (0, _jsxRuntime.jsxs)("td", {
            children: [(0, _jsxRuntime.jsx)(_components.p, {
              children: "Include this prop to open a URL on click. Contains the following fields:"
            }), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), " (string): the URL that will open on click."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsxs)(_components.p, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "external"
                  }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                    children: "true"
                  }), " to open the URL in a new tab and\ndisplay an external link icon. By default:"]
                }), (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.p, {
                      children: "Links to HubSpot app pages will open in the same tab and will\nnot include an icon."
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.p, {
                      children: "Links to non-HubSpot app pages will open in a new tab and\ninclude the icon."
                    })
                  })]
                })]
              })]
            }), (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["When a button includes both ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "href"
              }), " and an ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "onClick"
              }), " action, both will be\nexecuted on button click."]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "() => void"
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "A function that will be invoked when the button is clicked. It receives\nno arguments and it's return value is ignored."
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disabled"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), ", the button will render in a greyed-out state and\ncannot be clicked."]
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), "\n", (0, _jsxRuntime.jsxs)("td", {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'primary'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'secondary'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'destructive'"
            })]
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Sets the color of the button. See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "#variants",
                children: "variants section"
              }), " for more\ninformation."]
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), "\n", (0, _jsxRuntime.jsxs)("td", {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'button'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'reset'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'submit'"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)("td", {
            children: ["Sets the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "role"
            }), " HTML attribute of the button."]
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "size"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "'xs'"
              }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "'extra-small'"
              }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "'sm'"
              }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "'small'"
              }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "'med'"
              }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "'medium'"
              }), "\n(default)"]
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: "The size of the button."
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loading"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Set to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), " to display the loading indicator and disable the button.\nDefault is ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "false"
              }), "."]
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resultIconName"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "String"
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Set to an ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/reference/ui-components/standard-components/icon",
                children: "icon name"
              }), "\nto display an icon after loading. By default, will display a check mark."]
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overlay"
            })
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "Object"
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Include a\n", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/modal",
                children: "Modal"
              }), "\nor\n", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/panel",
                children: "Panel"
              }), "\ncomponent in this object to open it as an overlay on click. Learn more\nabout ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/beta-docs/guides/crm/ui-extensions/sdk#open-an-overlay",
                children: "using\noverlays"
              }), "."]
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Opening overlays"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Like the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/link",
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/tag",
        children: "Tag"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/image",
        children: "Image"
      }), " components, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LoadingButton"
      }), " component can open an overlay. In addition, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "overlayOptions"
      }), " prop to trigger the overlay either on click or when the loading has finished."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import React from 'react';\nimport { useState } from 'react';\nimport {\n  Flex,\n  Heading,\n  LoadingButton,\n  Panel,\n  PanelBody,\n  PanelSection,\n  hubspot,\n} from '@hubspot/ui-extensions';\n\nhubspot.extend(({ actions }) => <Extension actions={actions} />);\n\nfunction fakeFetchContactName() {\n  return new Promise((resolve) => {\n    setTimeout(() => {\n      resolve({ firstname: 'Tom', lastname: 'Bombadil' });\n    }, 1000);\n  });\n}\n\nfunction Extension() {\n  const [isFetching, setIsFetching] = useState(false);\n  const [contactName, setContactName] = useState('');\n\n  async function handleClick() {\n    setIsFetching(true);\n    const { firstname, lastname } = await fakeFetchContactName();\n\n    setContactName(`${firstname} ${lastname}`);\n    setIsFetching(false);\n  }\n\n  return (\n    <Flex direction=\"column\" gap=\"md\" align=\"start\">\n      <LoadingButton\n        loading={isFetching}\n        onClick={handleClick}\n        overlayOptions={{ openBehavior: 'onLoadingFinish' }}\n        overlay={\n          <Panel title={'Contact name '} id=\"my-panel\">\n            <PanelBody>\n              <PanelSection>\n                {contactName !== '' && <Heading>{contactName}</Heading>}\n              </PanelSection>\n            </PanelBody>\n          </Panel>\n        }\n      >\n        Fetch name (overlay)\n      </LoadingButton>\n    </Flex>\n  );\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " prop, you can set the color of the button."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Primary:"
          }), " a dark blue button for the most frequently used or most important action on an extension. Each extension should only have one primary button."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-components-loadingbuttons_2.png",
            alt: "ui-extension-components-loadingbuttons_2"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Secondary (default):"
          }), " a grey button to provide alternative or non-primary actions. Each extension should include no more than two secondary buttons."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-components-loadingbuttons_1.png",
            alt: "ui-extension-components-loadingbuttons_1"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Destructive:"
          }), " a red button for actions that delete, disconnect, or perform any action that the user can't undo. Button text should clearly communicate what is being deleted or disconnected. After a destructive button is clicked, the user should have to verify or confirm the action."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-components-loadingbuttons_3.png",
            alt: "ui-extension-components-loadingbuttons_3"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " HubSpot does not provide variant options for the orange buttons you’ll find across the app (both solid and outlined). Those color variants are reserved for the HubSpot product, which helps to maintain the hierarchy of available actions on a given page."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary"
        }), " button at the end of a form to submit data to another system."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary"
        }), " button next to a primary form submit button to reset form fields."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "destructive"
        }), " button to enable users to delete a contact's data from an external system."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Set a button to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "disabled"
        }), " when a contact doesn't qualify for a form submission due to missing criteria or other ineligibility."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " set button text that clearly communicates what action will occur when a user clicks it. Text should be unambiguous and concise (~2-4 words)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " use sentence-casing for button text (only the first word capitalized)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " minimize the number of buttons that appear on a page record across all extensions."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " always open links to pages outside of the HubSpot app in a new tab (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "external: true"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " include multiple primary buttons in a single extension."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON'T:"
        }), " use a destructive button unless the consequences are significant or irreversible."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button-row",
          children: "ButtonRow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/overview"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-button",
          children: "CrmActionButton"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/panel",
          children: "Panel"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}