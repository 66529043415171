"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358755;
const slug = exports.slug = 'guides/api/crm/objects/tickets';
const title = exports.title = 'API de CRM | Tickets';
const name = exports.name = 'vNext Docs DP - Tickets';
const metaDescription = exports.metaDescription = 'Un ticket representa una solicitud de asistencia técnica a un cliente. Los puntos finales de los tickets te permiten administrar estos datos y sincronizarlos entre HubSpot y otros sistemas. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crea-tickets-de-asistencia-t%C3%A9cnica",
  "label": "Crea tickets de asistencia técnica",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": "crea-tickets-de-asistencia-t%C3%A9cnica"
}, {
  "depth": 1,
  "id": "asociaciones",
  "label": "Asociaciones",
  "parent": "crea-tickets-de-asistencia-t%C3%A9cnica"
}, {
  "depth": 0,
  "id": "recuperar-tickets",
  "label": "Recuperar tickets",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-tickets",
  "label": "Actualizar tickets",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-tickets-existentes-con-registros-o-actividades",
  "label": "Asociar tickets existentes con registros o actividades",
  "parent": "actualizar-tickets"
}, {
  "depth": 1,
  "id": "eliminar-una-asociaci%C3%B3n",
  "label": "Eliminar una asociación",
  "parent": "actualizar-tickets"
}, {
  "depth": 0,
  "id": "anclar-una-actividad-en-un-registro-de-ticket",
  "label": "Anclar una actividad en un registro de ticket",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-tickets",
  "label": "Eliminar tickets",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En HubSpot, los tickets representan solicitudes de ayuda de los clientes. A los tickets se les hace seguimiento a través de tu proceso de asistencia técnica en los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
        children: "estados de la pipeline"
      }), " hasta que se cierren. Los puntos de terminación de los tickets te permiten crear y administrar registros de ticket, así como sincronizar los datos de los registros de ticket entre HubSpot y otros sistemas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre las API de objetos, registros, propiedades y asociaciones en la guía ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprender el CRM"
      }), ". Para obtener más información general sobre objetos y registros de HubSpot,", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: " aprende a administrar la base de datos del CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crea tickets de asistencia técnica"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear nuevos tickets, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En tu solicitud, incluye los datos del ticket en el objeto propiedades. También puedes agregar el objeto asociaciones para asociar el nuevo ticket con registros existentes (por ejemplo, contactos, empresas) o actividades (por ejemplo, reuniones, notas)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los detalles del ticket se almacenan en las propiedades de ticket. Hay ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
        children: "propiedades de ticket de HubSpot predeterminadas"
      }), ", pero también puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "crear propiedades personalizadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear un nuevo ticket, debes incluir las siguientes propiedades en tu solicitud: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subject"
      }), " (el nombre del ticket), ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pipeline_stage"
      }), " (el estado del ticket) y si tienes varias pipelines, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pipeline"
      }), ". Si no se especifica una pipeline, se utilizará la pipeline predeterminada."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver todas las propiedades disponibles, puedes recuperar una lista de las propiedades de tickets de tu cuenta haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/tickets"
      }), ". Más información sobre la", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: " API de propiedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": debes usar el ID interno de un estado de ticket o pipeline al crear un ticket a través de la API. El ID interno es un número, que también se devolverá cuando recuperes tickets a través de la API. Puedes encontrar el estado de un ticket o el ID interno de la pipeline en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
          children: "configuración de tu pipeline de tickets."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para crear un nuevo ticket, tu solicitud puede ser similar a la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al crear un nuevo ticket, también puedes asociar el ticket con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "registros"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "actividades existentes"
      }), " incluyendo un objeto asociaciones. Por ejemplo, para asociar un nuevo ticket con un registro de contacto y de empresa existentes, tu solicitud se vería de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 16\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 26\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto asociaciones, debes incluir lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El registro o actividad que deseas asociar con el ticket, especificado por su valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " único."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación entre el ticket y el registro/actividad. Incluye ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Los ID de los tipos de asociación predeterminados se enumeran ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aquí"
            }), ", o puedes recuperar el valor de los tipos de asociación personalizados (es decir, etiquetas) a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar tickets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes recuperar tickets individualmente o en lotes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar un ticket individual, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/{ticketId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para solicitar una lista de todos los tickets, envía una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En estos puntos de terminación, puedes incluir los siguientes parámetros de consulta en la URL de la solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán en la respuesta. Si el un ticket solicitado no tiene un valor para una propiedad, no aparecerá en la respuesta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades actuales e históricas que se devolverán en la respuesta. Si el un ticket solicitado no tiene un valor para una propiedad, no aparecerá en la respuesta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de los objetos de los cuales recuperar los ID asociados. No se devolverán en la respuesta las asociaciones especificadas que no existan. Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de asociaciones."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar un lote de tickets específicos por ID de registro o una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propiedad de identificador único personalizado"
        }), ", haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/tickets/batch/read"
        }), ". El punto de terminación por lote ", (0, _jsxRuntime.jsx)("u", {
          children: "no puede"
        }), " recuperar asociaciones. Obtener más información sobre cómo leer asociaciones por lotes con la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de asociaciones"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para el punto de terminación de lectura por lotes, también puedes utilizar el parámetro opcional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " para recuperar tickets mediante una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propiedad de identificador único"
      }), " personalizado. De forma predeterminada, los valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la solicitud se refieren al ID de registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), por lo que no se requiere el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " al recuperar por ID de registro. Para utilizar una propiedad de valor único personalizada para recuperar tickets, debes incluir el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para recuperar un lote de tickets, tu solicitud podría tener uno de los siguientes aspectos:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"subject\", \"hs_pipeline_stage\", \"hs_pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"4444888856\"\n    },\n    {\n      \"id\": \"666699988\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"subject\", \"hs_pipeline_stage\", \"hs_pipeline\"],\n  \"idProperty\": \"uniquepropertyexample\",\n  \"inputs\": [\n    {\n      \"id\": \"abc\"\n    },\n    {\n      \"id\": \"def\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para recuperar tickets con valores actuales e históricos para una propiedad, tu solicitud podría ser la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"hs_pipeline_stage\"],\n  \"inputs\": [\n    {\n      \"id\": \"4444888856\"\n    },\n    {\n      \"id\": \"666699988\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar tickets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes actualizar tickets individualmente o por lotes. Para los tickets existentes, el ID de registro es un valor único predeterminado que puedes usar para actualizar el ticket a través de la API, pero también puedes identificar y actualizar tickets utilizando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propiedades de identificador único personalizadas."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para actualizar un ticket individual por su ID de registro, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/{ticketId}"
        }), " e incluye los datos que deseas actualizar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para actualizar varios tickets, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/tickets/batch/update"
        }), ". En el cuerpo de la solicitud, incluye una matriz con los identificadores de los tickets y las propiedades que deseas actualizar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar tickets existentes con registros o actividades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar un ticket con otros registros del CRM o una actividad, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para recuperar el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "esta lista"
        }), " de valores predeterminados o haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una asociación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una asociación entre un ticket y un registro o actividad, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a la siguiente URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anclar una actividad en un registro de ticket"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes anclar una actividad en un registro de tickets a través de la API incluyendo el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " en tu solicitud. En el campo, incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la actividad a anclar, que se puede recuperar a través de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API de interacciones"
      }), ". Puedes anclar una actividad por registro, y la actividad ya debe estar asociada con el ticket antes de anclarla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para establecer o actualizar la actividad anclada de un ticket, tu solicitud podría ser la siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/tickets/{ticketId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También puedes crear un ticket, asociarlo a una actividad existente y anclar la actividad en la misma solicitud. Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/tickets\n{\n  \"properties\": {\n    \"hs_pipeline\": \"0\",\n    \"hs_pipeline_stage\": \"1\",\n    \"hs_ticket_priority\": \"HIGH\",\n    \"subject\": \"troubleshoot report\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 227\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar tickets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes eliminar tickets individualmente o por lotes, lo que agregará el ticket a la papelera de reciclaje en HubSpot. Más tarde puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restaurar el ticket dentro de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar un ticket individual por su ID, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/tickets/{ticketId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la eliminación de tickets por lotes en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}