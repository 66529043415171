"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42763670679;
const slug = exports.slug = 'reference/api/analytics-and-events/tracking-code';
const title = exports.title = 'Übersicht über die Tracking-Code-API';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Tracking code';
const metaDescription = exports.metaDescription = 'Der Web-Analytics-Endpunkt ermöglicht es Ihnen, Events zu finden und zu filtern, die einem CRM-Objekt beliebigen Typs zugeordnet sind. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "identifizieren-von-kontakten",
  "label": "Identifizieren von Kontakten",
  "parent": null
}, {
  "depth": 0,
  "id": "einen-besucher-identifizieren",
  "label": "Einen Besucher identifizieren",
  "parent": null
}, {
  "depth": 0,
  "id": "tracking-in-single-page-webanwendungen",
  "label": "Tracking in Single-Page-Webanwendungen:",
  "parent": null
}, {
  "depth": 1,
  "id": "seitenpfad-festlegen",
  "label": "Seitenpfad festlegen",
  "parent": "tracking-in-single-page-webanwendungen"
}, {
  "depth": 1,
  "id": "seitenaufruf-nachverfolgen",
  "label": "Seitenaufruf nachverfolgen",
  "parent": "tracking-in-single-page-webanwendungen"
}, {
  "depth": 0,
  "id": "datenschutzrichtlinie-beim-tracking",
  "label": "Datenschutzrichtlinie beim Tracking",
  "parent": null
}, {
  "depth": 0,
  "id": "domain%C3%BCbergreifende-verlinkungsparameter-abrufen",
  "label": "Domainübergreifende Verlinkungsparameter abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "analytics-event-handler-erneut-anwenden",
  "label": "Analytics-Event-Handler erneut anwenden:",
  "parent": null
}, {
  "depth": 0,
  "id": "benutzerdefinierte-verhaltensorientierte-events-nachverfolgen-nur-marketing-hub-enterprise",
  "label": "Benutzerdefinierte verhaltensorientierte Events nachverfolgen (nur Marketing Hub Enterprise)",
  "parent": null
}, {
  "depth": 1,
  "id": "trackcustombehavioralevent",
  "label": "trackCustomBehavioralEvent",
  "parent": "benutzerdefinierte-verhaltensorientierte-events-nachverfolgen-nur-marketing-hub-enterprise"
}, {
  "depth": 1,
  "id": "tracking-code-anpassen%2C-um-daten-von-benutzerdefinierten-verhaltensorientierten-events-zu-senden",
  "label": "Tracking-Code anpassen, um Daten von benutzerdefinierten verhaltensorientierten Events zu senden",
  "parent": "benutzerdefinierte-verhaltensorientierte-events-nachverfolgen-nur-marketing-hub-enterprise"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Übersicht über die Tracking-Code-API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Diese Seite wurde für das Arbeiten mit neuen benutzerdefinierten verhaltensorientierten Events aktualisiert. Weitere Informationen zu benutzerdefinierten verhaltensorientierten Events ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code/v1",
          children: "finden Sie in der Legacy-Dokumentation"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zusätzlich zur Nachverfolgung von Seitenaufrufen können Sie mithilfe des Tracking-Codes von HubSpot Besucher identifizieren, Events nachverfolgen und manuell Seitenaufrufe nachverfolgen (ohne die Seite neu zu laden). Die Tracking-Code-API ermöglicht es Ihnen, dynamisch Events zu erstellen und Event-Daten in HubSpot nachzuverfolgen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre Website das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "Banner zur Datenschutzeinwilligung verwendet"
      }), ", erfahren Sie in diesem Artikel, wie Sie die Cookies verwalten können, die dem Browser eines Besuchers mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "Cookie-Banner-API"
      }), " hinzugefügt werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Funktionsaufrufe werden in das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), "-Array eingefügt. Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', { path string }])\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Artikel erfahren Sie, wie Sie Funktionen zum Tracking-Code hinzufügen können, um Besucher zu identifizieren, Seitenaufrufe nachzuverfolgen, Event-Daten zu senden und vieles mehr."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identifizieren von Kontakten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das HubSpot-Aanalytics-Tool identifiziert Kontakte mithilfe von zwei Datenelementen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Das Benutzertoken, das im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspotutk"
        }), "-Browser-Cookie des Besuchers gespeichert wird."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die E-Mail-Adresse des Kontakts."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn der HubSpot-Tracking-Code die Aktion eines Besuchers (z. B. ein Seitenaufruf) nachverfolgt, ordnet er diese Aktion automatisch dem Benutzertoken des Besucher zu. Wenn Sie die Tracking-Code-API verwenden, um einen Besucher anhand der E-Mail-Adresse zu identifizieren, verknüpft das Analytics-System diese E-Mail-Adresse mit dem Benutzertoken und ermöglicht HubSpot so das Aktualisieren eines vorhandenen Kontaktdatensatzes oder das Erstellen eines neuen. Analytics-Daten, die dem Benutzertoken zugeordnet sind, wie Seitenaufrufe und ursprüngliche Quelle, werden dann im Kontaktdatensatz angezeigt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie die Identität eines Besuchers kennen (z. B. die E-Mail-Adresse), können Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identify-a-visitor",
        children: "identify"
      }), "-Funktion verwenden, um Identitäten im Tracker festzulegen. Sie können dann die Identität an HubSpot senden, indem Sie einen separaten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events-js-api",
        children: "trackCustomBehavioralEvent"
      }), "-Aufruf vornehmen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie Folgendes, wenn Sie diese Funktion verwenden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vermeiden Sie das Verwenden einer Platzhalter-E-Mail-Adresse für unbekannte Besucher, da HubSpot dann einen Kontaktdatensatz mit dieser Platzhalter-E-Mail-Adresse erstellt und das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "usertoken"
        }), "-Cookie des Besuchers diesem Datensatz zugeordnet wird. Dies führt dazu, dass alle unbekannten Besucher mit Ihrem Platzhalterkontakt verknüpft werden."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie einem Kontaktdatensatz eine Identität zuweisen und über eindeutige Eigenschaften verfügen, werden alle Eigenschaften aus der Identität entfernt, die gegen die Eindeutigkeit verstoßen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einen Besucher identifizieren"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Ihr Account vor dem 8. September 2021 erstellt wurde und so eingerichtet ist, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/prevent-contact-properties-update-through-tracking-code-api",
          children: "dass Kontakteigenschaften über den Tracking-Code aktualisiert werden können"
        }), ", können Sie auch andere Kontakteigenschaften hinzufügen, die mit dieser Funktion aktualisiert werden sollen. Bei Accounts, die nach dem 8. September 2021 erstellt wurden, ist diese Funktion standardmäßig deaktiviert. Sie können zulassen, dass Kontakte über den Tracking-Code aktualisiert werden, indem Sie die Option ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "Aktualisierung der Kontakteigenschaften durch nachverfolgte Events zulassen"
        }), " aktivieren."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"identify\", { {identity details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie diesen Endpunkt, um Website-Besucher und Kontakte zu identifizieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Besucher:"
        }), " bezieht sich auf jeden Website-Besucher, unabhängig davon, ob er ein HubSpot-Kontakt ist. HubSpot erstellt keine Datensätze für Besucher, wie es bei Kontakten der Fall ist."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontakt:"
        }), " bezieht sich auf einen Besucher oder Offline-Kontakt, der einen Datensatz in HubSpot hat. Ein Kontakt kann anhand seiner eindeutigen E-Mail-Adresse identifiziert werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um einen Besucher oder Kontakt manuell zu identifizieren, können Sie entweder eine E-Mail-Adresse oder eine eindeutige externe ID verwenden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-Mail:"
        }), " Identifizieren Sie einen Besucher anhand der E-Mail-Adresse, wenn Sie einen vorhandenen Kontakt aktualisieren oder einen neuen erstellen möchten. Wenn ein Kontakt mit dieser E-Mail in HubSpot vorhanden ist, wird der Kontaktdatensatz mit den Analytics-Daten aktualisiert. Wenn an dieser E-Mail-Adresse kein Kontakt vorhanden ist, wird ein neuer Datensatz erstellt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ID:"
        }), " eine benutzerdefinierte externe ID, die den Besucher identifiziert. Wenn Sie einen Besucher mit dieser ID identifizieren, werden diesem Besucher Analytics-Daten zugeordnet. Wenn Sie jedoch nur die ID verwenden, wird kein Kontakt in HubSpot erstellt. Analytics-Daten können nur dann einem vorhandenen Kontakt über diese Methode zugeordnet werden, wenn:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "der Besucher zuvor sowohl per ID als auch per E-Mail identifiziert wurde."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "der Besucher zuvor anhand der ID identifiziert wurde und auch eine Formulareinsendung hat, die seinem Datensatz zugeordnet ist."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Diese externe ID kann nur mit dem HubSpot-Tracking-Code verwendet werden. Diese ID kann nicht verwendet werden, um Datensätze über andere HubSpot-Tools oder -APIs abzurufen oder zu aktualisieren. Wenn Sie die E-Mail-Adresse des Besuchers kennen, wird empfohlen, diese als eindeutige ID zu verwenden. Ebenso sollten Sie einen Besucher nur mit einer ID identifizieren, wenn Sie seine E-Mail-Adresse nicht kennen."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn Sie zuvor Analytics-Daten nur mit der Besucher-ID an HubSpot gesendet haben, können Sie später sowohl die ID als auch eine E-Mail-Adresse angeben, um die Daten aus dieser ID einem Kontakt zuzuordnen. Der bestehende Kontakt wird dann aktualisiert oder erstellt, wenn derzeit kein Kontakt vorhanden ist."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nThe below example gets the value of a query string parameter '?email='\nand uses that to identify the visitor\n*/\n\nfunction getParameterByName(name) {\n  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);\n  return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));\n}\n\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n  },\n]);\n\n/*\nThe below example sets the email,\nas well as a custom property favorite_color\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n    favorite_color: 'orange',\n  },\n]);\n\n/*\nThe below example sets the email and a custom external ID for the visitor. \nThis assumes that your site includes a variable named \n'user' that includes that user's ID in an 'id' \nproperty, and their email in an 'email' property.\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: user.email,\n    id: user.id,\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie diese Funktion verwenden, beachten Sie Folgendes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dieser Funktionsaufruf speichert die Daten im Tracker, aber die Daten werden mit diesem Aufruf nicht wirklich an HubSpot weitergegeben. Die Daten werden nur beim Nachverfolgen eines Seitenaufrufs oder eines Events (mit einer beiden Funktionen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#track-page-view",
          children: "trackPageView"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackEvent"
        }), ") weitergegeben."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Einem Kontakt kann nur eine ID und/oder E-Mail-Adresse zugeordnet sein. Wenn Sie versuchen, einer E-Mail zwei IDs zuzuweisen, wird nur die erste ID der E-Mail-Adresse zugeordnet."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie müssen eine E-Mail-Adresse angeben, um die Daten mit einem Kontakt zu verknüpfen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Ihr Account vor dem 8. September 2021 erstellt wurde und so eingerichtet ist, dass ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "Kontakteigenschaften über den Tracking-Code aktualisiert werden können"
        }), ", können Sie auch andere Kontakteigenschaften hinzufügen, die mit dieser Funktion aktualisiert werden sollen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mit dieser Funktion werden zuvor gelöschte Kontakte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "nicht"
        }), " wiederhergestellt. Diese Kontakte müssen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/articles/kcs_article/contacts/restore-deleted-contacts-companies-deals-or-tickets",
          children: "in HubSpot wiederhergestellt werden"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tracking in Single-Page-Webanwendungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der HubSpot-Tracking-Code zeichnet automatisch einen Seitenaufruf auf, wenn der Code zuerst geladen wird. Sie können jedoch auch Seitenaufrufe in einer Single-Page-Webanwendung manuell nachverfolgen, ohne den Tracking-Code neu zu laden. Sie können die Funktionen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-path",
        children: "setPath"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view"
      }), "trackPageView verwenden, um die aktuelle Seite zu aktualisieren und nachzuverfolgen: Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Set up the path for the initial page view -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/home']);\n</script>\n\n<!-- Load the HubSpot tracking code -->\n<!-- Start of HubSpot Embed Code -->\n  <script type=\"text/javascript\" id=\"hs-script-loader\" async defer src=\"//js.hs-scripts.com/{hubId}.js\">\n</script>\n<!-- End of HubSpot Embed Code -->\n\n<!-- Tracking subsequent page views -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/about-us']);\n  _hsq.push(['trackPageView']);\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seitenpfad festlegen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsq.push(['setPath', { path string }])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aktualisieren Sie den im Tracker gespeicherten Pfad der aktuellen Seite. Diese Funktion sollte von Single-Page-Webanwendungen verwendet werden, um bei jedem Laden einer Seite die aktuelle Seite zu aktualisieren. Nachdem Sie diese Funktion zum Aktualisieren des Pfads verwendet haben, müssen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), "-Funktion aufrufen, um den Aufruf der aktuellen Seite nachzuverfolgen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Single-Page-Webanwendungen sollten einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), "-Aufruf in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " einfügen, bevor der Tracking-Code geladen wird, um die URL festzulegen, die für den ersten Seitenaufruf nachverfolgt werden soll. Ein Beispiel dazu finden Sie im Abschnitt zum ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "Nachverfolgen eines Seitenaufrufs"
      }), " unten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Aufrufen von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), " schließen Sie den Pfad der aktuellen Seite ein. Der festgelegte Pfad wird als relativ zur aktuell angezeigten Domain behandelt. Der Pfad sollte immer mit einem Schrägstrich beginnen. Wenn Ihre URL auch Parameter enthält, müssen diese ebenfalls in den Pfad aufgenommen werden. Sehen Sie sich den obigen Code für Beispiele an."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn Sie diese Funktion verwenden, beachten Sie Folgendes:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Jeder mithilfe der ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), "-Funktion festgelegte Pfad überschreibt die Daten im Referrer-Header. Wenn Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), "ein Mal aufrufen, müssen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " verwenden, um den Pfad für jeden Aufruf zu aktualisieren, den Sie nachverfolgen möchten."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Durch ein wiederholtes Aufrufen von ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " wird der Referrer überschrieben, was sich auf die ursprüngliche Quelle eines Kontakts auswirken kann, je nachdem, wann eine Tracking-Anfrage durchgeführt wird."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Diese Funktion kann nur den Pfad der URL aktualisieren. Die Domain wird automatisch basierend auf der URL der Seite, die geladen wird, festgelegt und der Pfad, der mithilfe dieser Funktion festgelegt wird, wird immer als relativ zu dieser erkannten Domain behandelt."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// These examples assume that the domain of the site is\n// www.mydomain.com\n\n// Set the path to '/' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/\n\n// Set the path to '/contact-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/contact-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/contact-us\n\n// Set the path to '/blog/post?utm_campaign=my-campaign' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/blog/post?utm_campaign=my-campaign']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/blog/post?utm_campaign=my-campaign\n\n// Set the path to '/#/about-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/about-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/#/about-us\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seitenaufruf nachverfolgen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['trackPageView']);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verfolgen Sie die Seitenansicht für die aktuelle Seite nach. Diese Funktion wird automatisch aufgerufen, wenn der Tracking-Code auf einer Seite geladen wird, Sie können diese Funktion jedoch manuell aufrufen, um nachfolgende Ansichten in einer Single-Page-Webanwendung nachzuverfolgen."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Ein manuelles Aufrufen diese Funktion vor oder während des ersten Ladens der Seite kann zu duplizierten Ansichten führen, die nachverfolgt werden."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Funktion unterstützt keine Argumente. Der nachverfolgte Seitentitel ist dann der aktuelle Wert von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.title"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die URL, die nachverfolgt wird, basiert auf einer der folgenden Optionen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dem mithilfe der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath-Funktion"
        }), " festgelegte Pfad. Wenn Ihre Website als Single-Page-Webanwendung erstellt wurde, ist diese Funktion die bevorzugte Methode, um den nachverfolgten Pfad festzulegen. Beachten Sie die Anmerkungen zu dieser Funktion im obigen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), "-Abschnitt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " nie aufgerufen wurde, ist die nachverfolgte URL dann der Referrer-HTTP-Header in der Anfrage, die vom Browser des Besuchers an die Tracking-Server von HubSpot übermittelt wird (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/API/History_API#Adding_and_modifying_history_entries",
          children: "ein Ändern des Browser-Verlaufszustands"
        }), " würde den für diesen Header verwendeten Wert ändern). Beim Referrer-Header werden URL-Fragmente (alles nach dem # in der URL) nicht unterstützt, da Fragmente nicht im Referrer-Header berücksichtigt werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// Track a new page using setPath:\n// Update the path stored in the tracker:\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/new-page']);\n// Track the page view for the new page\n_hsq.push(['trackPageView']);\n\n\n// Track a new page by updating the browser state:\n// Update the browser state, showing \"updated.html\" in the browser address bar\nvar stateObj = { foo: 'updated' };\nhistory.pushState(stateObj, \"updated page\", \"updated.html\");\n//Track the page view for the new page, '/updated.html'\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['trackPageView']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie die folgenden Bedingungen, wenn Sie diese Funktion verwenden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Obwohl Sie nicht verhindern können, dass diese Funktion automatisch aufgerufen wird, wenn der Tracking-Code geladen wird, können Sie die für die Seite aufgezeichnete URL steuern, indem Sie einen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), "-Aufruf in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "_hsq"
        }), " einfügen, bevor der Tracking-Code geladen wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Ihre Website eine Single-Page-Webanwendung ist, sollten Sie kein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), "-Tag in Ihre Seite aufnehmen. Wenn Ihre Seite ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), "-Tags verwendet, müssen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), "-Funktion verwenden, um den Pfad Ihrer neuen Seiten zu aktualisieren, da die kanonische URL vom Link-Tag jede erkannte URL überschreibt, wenn Sie nur den Browser-Verlaufszustand aktualisieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Datenschutzrichtlinie beim Tracking"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre Website über ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "Banner zur Datenschutzeinwilligung"
      }), " verfügt, können Sie Funktionen verwenden, um Cookies zu überprüfen und zu verwalten, die im Browser des Besuchers platziert werden. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "das Verwalten von Cookies von Bannern zur Datenschutzeinwilligung"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Domainübergreifende Verlinkungsparameter abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['addIdentityListener', function(hstc, hssc, hsfp) {}])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der HubSpot-Tracking-Code kann über mehrere Websites hinweg mit separaten Domains verwendet werden. Diese Funktion ermöglicht es Ihnen, die Abfrageparameter abzurufen, die zum Erstellen von Links erforderlich sind, mit denen Sie Ihre Besucher ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/reports/set-up-your-site-domains#track-multiple-domains-with-cross-domain-linking",
        children: "über diese separaten Domains hinweg"
      }), " nachverfolgen können. Diese Abfrageparameter werden von dem HubSpot-Tracking-Code verwendet, um einen Besucher über Domains hinweg zu identifizieren, indem sichergestellt wird, dass die separaten Cookies für die separaten Domains zu einem einzigen nachverfolgten Besucher zusammengeführt werden. Sie können auch domainübergreifende Abfrageparameter in Links verwenden, die dynamisch nach dem Laden des Tracking-Code zur Seite hinzugefügt werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Domainübergreifende Links sind nur beim Verlinken mit einer bestimmten Domain (z. B. ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "domain-eins.com"
      }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "domain-zwei.com"
      }), ") erforderlich, die außerdem auch für einen einzelnen HubSpot-Account nachverfolgt wird. Sie benötigen keine domainübergreifenden Link-Parameter, wenn Sie Besuche zwischen Subdomains nachverfolgen (z. B. ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://www.domain-eins.com",
          children: "www.domain-eins.com"
        })
      }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "blog.domain-eins.com"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Get the cross-domain query parameters and store them in a string,\n//so that they can be appended to links as needed.\n_hsq.push([\n  'addIdentityListener',\n  function (hstc, hssc, hsfp) {\n    // Add these query parameters to any links that point to a separate tracked domain\n    crossDomainTrackingParams =\n      '&__hstc=' + hstc + '&__hssc=' + hssc + '&__hsfp=' + hsfp;\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Analytics-Event-Handler erneut anwenden:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['refreshPageHandler'])"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Funktion wendet alle Analytics-Event-Handler erneut an, die in den Analytics-Einstellungen für den HubSpot-Account eingerichtet sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies bedeutet auch, dass alle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events/create-clicked-element-events",
        children: "Events vom Typ „Angeklicktes Element“"
      }), " erneut angewendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können diese Funktion verwenden, um automatisch Klick-Handler erneut anzuwenden, wenn Inhalte auf der Seite aktualisiert werden, z. B. die Aktualisierung eines Abschnitts mit Inhalten oder das Anzeigen eines modalen Fensters auf der Seite."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Diese Funktion wird automatisch als Teil der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "setPath"
          })
        }), "-Funktion ausgelöst, sodass Sie diese Funktion nur verwenden müssen, wenn Sie die Inhalte aktualisieren, ohne die nachverfolgte URL der Seite zu aktualisieren."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Reapply event handlers after updating the page content\n_hsq.push(['refreshPageHandlers']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Benutzerdefinierte verhaltensorientierte Events nachverfolgen (nur Marketing Hub Enterprise)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit benutzerdefinierten verhaltensorientierten Events können Sie Event-Abschlüsse an Kontaktdatensätze binden und Event-Eigenschaften mit Metadaten über das Event füllen. Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "Web-Analytics-API"
      }), ", um benutzerdefinierte verhaltensorientierte Events abzurufen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Über die API können Events mithilfe des internen Namens des Events ausgelöst werden, der beim Erstellen des Events automatisch zugewiesen wird. Sie können den internen Namen eines Events entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "in HubSpot"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/reporting#get-events",
        children: "mithilfe der Events-API"
      }), " finden. Erfahren Sie, wie Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "den internen Namen für ein Event finden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Imported%20sitepage%20images/custom-event-internal-name.png",
        alt: "custom-event-internal-name"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt drei Typen von Events, die Sie in HubSpot erstellen können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/analytics-tools/create-clicked-element-events",
          children: "Events vom Typ „Angeklicktes Element“"
        }), ": Events, die mit anklickbaren Elementen auf einer Website-Seite verknüpft sind. Füllt automatisch eine Reihe von Standard-HubSpot-Event-Eigenschaften über den Tracking-Code aus. Sie können diese Events mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "-Funktion weiter anpassen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/analytics-tools/create-visited-url-events",
          children: "Events vom Typ „Aufgerufene URL“"
        }), ": Events, die an Seitenladevorgänge bei bestimmten URLs gebunden sind. Füllt automatisch eine Reihe von Standard-HubSpot-Event-Eigenschaften über den Tracking-Code aus. Sie können diese Events mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "-Funktion weiter anpassen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "Manuell nachverfolgte verhaltensorientierte Events"
        }), ": Benutzerdefinierte Events, die einzigartig für Ihr Unternehmen sind, sowie Events, die möglicherweise nicht automatisch von HubSpot oder einer Integration erfasst werden. Daten werden manuell über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "HTTP-API"
        }), " an HubSpot-Events gesendet."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für jeden Event-Typ enthält HubSpot eine Reihe von Standardeigenschaften, die bestimmte Metadaten zum Zeitpunkt des Abschlusses erfassen können, einschließlich UTM-Parameter oder Geräte- und Betriebssystemmetadaten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da diese Funktion zusammen mit dem Analytics-Tracking von HubSpot arbeitet, werden alle über die JavaScript-API ausgelösten Events automatisch mit dem ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "hubspotutk"
      }), "-Cookie des Besuchers verknüpft, sodass das Event automatisch an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identities-overview",
        children: "dem diesem „Benutzertoken“ zugeordneten Kontakt gebunden wird"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "trackCustomBehavioralEvent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"trackCustomBehavioralEvent\", { {event details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie diese Funktion, um ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/events-user-guide-v2/a-quick-tour-of-events",
        children: "Event"
      }), " mithilfe von JavaScript und dem Tracking-Code von HubSpot nachzuverfolgen. Sie können Events verwenden, um bestimmte Aktivitäten nachzuverfolgen, die von Besuchern auf Ihrer Website abgeschlossen wurden. Nachverfolgte Events können in den Chroniken von Kontakten auftauchen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to fire a custom behavioral event using the name \"clicked Buy Now button\" when a visitor clicks an element with the 'buyNow' id.\n*/\n\ndocument.getElementById(\"buyNow\").onclick = function() {_hsq.push([\"trackCustomBehavioralEvent\", {\n   name: \"pe123456_course_registration\",\n   properties: {\n       course_id: \"Math101\"\n   }\n}]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Argumente"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Anwendung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "name:\"internal_name\""
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die event_id oder der interne Name des Events, das Sie in HubSpot erstellt haben."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "property_name: \"property_value\""
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Liste von Schlüssel-Wert-Paaren mit einem Schlüssel-Wert-Paar pro Eigenschaft.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_name"
            }), " ist der interne Name der Event-Eigenschaft, die Sie für das Event erstellt haben, und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_value"
            }), " ist der Wert, der der Eigenschaft hinzugefügt werden soll. Sie können auch nicht definierte Eigenschaften nachverfolgen und zurückgehen, um sie nach dem Event-Tracking zu erstellen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tracking-Code anpassen, um Daten von benutzerdefinierten verhaltensorientierten Events zu senden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Standardmäßig erstellt HubSpot eine Reihe von Eigenschaften für jedes von Ihnen erstellte Event. Für Events vom Typ ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/analytics-tools/create-clicked-element-events",
        children: "Angeklicktes Element"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/analytics-tools/create-visited-url-events",
        children: "Aufgerufene URL"
      }), " füllt HubSpot einige dieser Eigenschaften automatisch mit Daten aus. Sie können aber auch Ihren Tracking-Code anpassen, um Daten an die Eigenschaften des Events zu senden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Berichte"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Analytics-Tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Benutzerdefinierte verhaltensorientierte Events"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " des Events, das Sie nachverfolgen möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Kopieren Sie unter ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Eigenschaften"
        }), " den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internen Namen"
        }), " des Events."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-clicked-element-event-internal-name0.png",
        alt: "custom-clicked-element-event-internal-name0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie dann in der Eigenschaftentabelle auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen"
          }), " der Event-Eigenschaft, an die Sie Daten senden möchten."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie im rechten Bereich auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "</> Quellensymbol"
          }), ", um den internen Namen der Eigenschaft anzuzeigen. Sie verwenden diesen Namen, wenn Sie den Tracking-Code anpassen."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-behavioral-event-property-internal-name0.png",
            alt: "custom-behavioral-event-property-intern-name0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nun, da Sie Ihre Event- und Event-Eigenschaftsdaten haben, klicken Sie auf das Symbol für ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Einstellungen"
          }), ", um zu Ihren Account-Einstellungen zu wechseln. Gehen Sie dann im linken Seitenmenü zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Tracking & Analytics"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Tracking-Code"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "JavaScript anpassen"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/tracking-code-customize-javascript0.png",
            alt: "tracking-code-customize-javascript0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Benutzerdefiniertes JavaScript hinzufügen"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie in der rechten Seitenleiste einen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen"
          }), " für Ihr benutzerdefiniertes JavaScript ein und geben Sie dann das JavaScript ein, einschließlich der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#events-js-api",
            children: "trackCustomBehavioralEvent"
          }), "-Funktion. Dieses JavaScript wird ausgeführt, nachdem der Tracking-Code für eine Seite geladen wurde."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example usage\n_hsq.push(['trackCustomBehavioralEvent',{\n   name: '((behavioral_event_internal_name))”,\n   properties: { internal_property_name: property_value}\n}\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihr Event beispielsweise eine Kursregistrierung nachverfolgt, wenn eine Schaltfläche mit der HTML-ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "register_for_econ101"
      }), " angeklickt wird, kann Ihr JavaScript wie folgt aussehen:", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/customize-tracking-code0-1.png",
        alt: "customize-tracking-code0-1"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie anschließend auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Speichern"
        }), ", um Ihr JavaScript zu speichern. Ihr Tracking-Code wird nun mit Ihrem benutzerdefinierten JavaScript geladen."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}