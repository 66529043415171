"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 162205500172;
const slug = exports.slug = 'guides/api/crm/commerce/orders';
const title = exports.title = 'CRM API | Orders';
const name = exports.name = 'CRM API | Orders';
const metaDescription = exports.metaDescription = "Learn about using HubSpot's APIs to create and manage ecommerce order data.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-orders",
  "label": "Create orders",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-orders"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-orders"
}, {
  "depth": 0,
  "id": "retrieve-orders",
  "label": "Retrieve orders",
  "parent": null
}, {
  "depth": 1,
  "id": "search-for-orders-by-properties",
  "label": "Search for orders by properties",
  "parent": "retrieve-orders"
}, {
  "depth": 1,
  "id": "retrieve-an-order-with-associations",
  "label": "Retrieve an order with associations",
  "parent": "retrieve-orders"
}, {
  "depth": 0,
  "id": "update-orders",
  "label": "Update orders",
  "parent": null
}, {
  "depth": 0,
  "id": "order-properties",
  "label": "Order properties",
  "parent": null
}, {
  "depth": 1,
  "id": "pipelines-and-stages",
  "label": "Pipelines and stages",
  "parent": "order-properties"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      h3: "h3",
      a: "a",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Orders"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use the orders API to create and manage data related to ecommerce purchases in HubSpot. This can be especially useful for keeping HubSpot data synced with external ecommerce platforms, such as Shopify and NetSuite."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, when a buyer adds a set of products to their cart and makes a purchase, store that purchase as an individual order. You can then update that order with tracking information once the shipping label has been printed. Because this information is stored in a property, you can reference it in emails that you send to notify customers that their package is on the way."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create orders"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create an order, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/order"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, you can include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " objects to set property values and associate the order with other CRM objects (e.g., contacts and line items). Learn more about order properties and associations below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Order details are stored in order properties. HubSpot provides a set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#order-properties",
        children: "default order properties"
      }), ", but you can also create your own custom properties using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include properties when creating an order, add them as fields in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object in the request body. For example, the request body below would create an order with some basic order and shipping details based on the information provided by the buyer at checkout."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request body\n{\n  \"properties\": {\n    \"hs_order_name\": \"Camping supplies\",\n    \"hs_currency_code\": \"USD\",\n    \"hs_source_store\": \"REI - Portland\",\n    \"hs_fulfillment_status\": \"Packing\",\n    \"hs_shipping_address_city\": \"Portland\",\n    \"hs_shipping_address_state\": \"Maine\",\n    \"hs_shipping_address_street\": \"123 Fake Street\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include the information you provided during creation along with a few other default properties."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"54805205097\",\n  \"properties\": {\n    \"hs_created_by_user_id\": \"959199\",\n    \"hs_createdate\": \"2024-03-27T18:04:11.823Z\",\n    \"hs_currency_code\": \"USD\",\n    \"hs_exchange_rate\": \"1.0\",\n    \"hs_fulfillment_status\": \"Packing\",\n    \"hs_lastmodifieddate\": \"2024-03-27T18:04:11.823Z\",\n    \"hs_object_id\": \"54805205097\",\n    \"hs_object_source\": \"CRM_UI\",\n    \"hs_object_source_id\": \"userId:959199\",\n    \"hs_object_source_label\": \"CRM_UI\",\n    \"hs_object_source_user_id\": \"959199\",\n    \"hs_order_name\": \"Camping supplies\",\n    \"hs_shipping_address_city\": \"Portland\",\n    \"hs_shipping_address_state\": \"Maine\",\n    \"hs_shipping_address_street\": \"123 Fake Street\",\n    \"hs_source_store\": \"REI - Portland\",\n    \"hs_updated_by_user_id\": \"959199\"\n  },\n  \"createdAt\": \"2024-03-27T18:04:11.823Z\",\n  \"updatedAt\": \"2024-03-27T18:04:11.823Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can associate the order with other HubSpot CRM objects at creation by including an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " array. You can also use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API"
      }), " to update existing orders after creation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " array, include an object for each associated record using the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fields"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the record that you want to associate the order with."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A unique identifier to indicate the association type between the order and the other object. Below are the CRM objects that you can associate orders with, along with their ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ":", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/carts",
                  children: "Carts"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "593"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/contacts",
                  children: "Contacts"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "507"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/companies",
                  children: "Companies"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "509"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/deals",
                  children: "Deals"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "512"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/discounts",
                  children: "Discounts"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "519"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/payments/create-and-use-payment-discount-codes",
                  children: "Discount codes"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "521"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/invoices",
                  children: "Invoices"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "518"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/line-items",
                  children: "Line items"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "513"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/payments",
                  children: "Payments"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "523"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/commerce/quotes",
                  children: "Quotes"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "730"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://developers.hubspot.com/docs/api/crm/subscriptions",
                  children: "Subscriptions"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "516"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/engagements/tasks",
                  children: "Tasks"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "726"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/objects/tickets",
                  children: "Tickets"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "525"
                })]
              })]
            }), "To see a list of all association types, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "associations API documentation"
            }), ". Or, you can retrieve each value by making a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request body below would create an order that's associated with a specific contact and two line items. Properties are also included below the associations for setting initial order information."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 507\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1243313490\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 513\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1243557166\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 513\n        }\n      ]\n    }\n  ],\n  \"properties\": {\n    \"hs_order_name\": \"Associated order\",\n    \"hs_currency_code\": \"USD\",\n    \"hs_source_store\": \"REI - Portland\",\n    \"hs_fulfillment_status\": \"Packing\",\n    \"hs_shipping_address_city\": \"Portland\",\n    \"hs_shipping_address_state\": \"Maine\",\n    \"hs_shipping_address_street\": \"123 Fake Street\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve orders"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the information you need, there are a few ways to retrieve orders:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all orders, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/order"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a specific order, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the above URL and specify an order ID. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/order/44446244097"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve orders that meet a specific set of criteria, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the search endpoint and include filters in the request body. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search#make-a-search-request",
          children: "searching the CRM"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a few default properties, including the create date, last modified date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"54767113310\",\n      \"properties\": {\n        \"hs_createdate\": \"2024-03-26T20:02:34.935Z\",\n        \"hs_lastmodifieddate\": \"2024-03-26T20:02:48.278Z\",\n        \"hs_object_id\": \"54767113310\"\n      },\n      \"createdAt\": \"2024-03-26T20:02:34.935Z\",\n      \"updatedAt\": \"2024-03-26T20:02:48.278Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"54804869149\",\n      \"properties\": {\n        \"hs_createdate\": \"2024-03-27T17:39:16.122Z\",\n        \"hs_lastmodifieddate\": \"2024-03-27T17:39:16.122Z\",\n        \"hs_object_id\": \"54804869149\"\n      },\n      \"createdAt\": \"2024-03-27T17:39:16.122Z\",\n      \"updatedAt\": \"2024-03-27T17:39:16.122Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To return specific properties, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter in the request URL along with comma-separated property names. For example, making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL would result in the response below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/order?properties=hs_order_name,hs_source_store"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"54767113310\",\n  \"properties\": {\n    \"hs_createdate\": \"2024-03-26T20:02:34.935Z\",\n    \"hs_lastmodifieddate\": \"2024-03-27T18:50:07.678Z\",\n    \"hs_object_id\": \"54767113310\",\n    \"hs_order_name\": \"Test API order 2\",\n    \"hs_source_store\": \"REI - Portland\"\n  },\n  \"createdAt\": \"2024-03-26T20:02:34.935Z\",\n  \"updatedAt\": \"2024-03-27T18:50:07.678Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available order properties, you can query the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), " by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/order"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#order-properties",
        children: "order properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Search for orders by properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the search endpoint to retrieve orders that meet a specific set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search#filter-search-results",
        children: "filter criteria"
      }), ". This will be a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request that includes your filter criteria in the request body."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to search for all orders placed at a specific store, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/order/search"
      }), " with the following request body:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example search request body\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"hs_source_store\",\n          \"value\": \"REI - Portland\",\n          \"operator\": \"EQ\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"hs_order_name\", \"hs_source_store\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve an order with associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve an order along with its associations, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/order/{orderId}/associations/{objectName}"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to retrieve an order and its associated contacts, you would use the following URL:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/order/{orderId}/associations/contact"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will return the IDs of the currently associated contacts, along with meta information about the association type."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"301\",\n      \"type\": \"order_to_contact\"\n    },\n    {\n      \"id\": \"1196316844\",\n      \"type\": \"order_to_contact\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You could then use the returned IDs to request more information about the contacts through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "contacts API"
      }), ". For example, you could retrieve the contact using its ID by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"301\",\n  \"properties\": {\n    \"createdate\": \"2022-09-27T13:13:31.004Z\",\n    \"email\": \"tom.bombadil@oldforest.com\",\n    \"firstname\": \"Tom\",\n    \"hs_object_id\": \"301\",\n    \"lastmodifieddate\": \"2023-11- 07T17:14:00.841Z\",\n    \"lastname\": \"Bombadil\"\n  },\n  \"createdAt\": \"2022-09-27T13:13:31.004Z\",\n  \"updatedAt\": \"2023-11-07T17:14:00.841Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " array specifies the search criteria, while the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " array specifies which properties to return."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update orders"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update an order, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/order/{orderId}"
      }), ". In the request body, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object containing the properties that you want to update."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to update an order with the shipping tracking number, you could send the following request body:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_shipping_tracking_number\": \"123098521091\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a set of default properties along with the property that you just set."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"54767113310\",\n  \"properties\": {\n    \"hs_created_by_user_id\": \"959199\",\n    \"hs_createdate\": \"2024-03-26T20:02:34.935Z\",\n    \"hs_lastmodifieddate\": \"2024-03-27T20:03:05.890Z\",\n    \"hs_object_id\": \"54767113310\",\n    \"hs_shipping_tracking_number\": \"123098521091\",\n    \"hs_updated_by_user_id\": \"959199\"\n  },\n  \"createdAt\": \"2024-03-26T20:02:34.935Z\",\n  \"updatedAt\": \"2024-03-27T20:03:05.890Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update the associations for an existing order, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/order/{orderId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". You can also use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["See the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#associations",
          children: "associations section"
        }), " for ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " values for order-to-object associations. You can also make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To see all a list of all values, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
          children: "associations API documentation"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to associate an existing order with an existing payment, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to the following URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/order/{orderId}/associations/commerce_payments/{paymentId}/523"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response will return a set of default properties along with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " object containing information about the association that you set."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"54767113310\",\n  \"properties\": {\n    \"hs_createdate\": \"2024-03-26T20:02:34.935Z\",\n    \"hs_lastmodifieddate\": \"2024-03-27T20:03:05.890Z\",\n    \"hs_object_id\": \"54767113310\"\n  },\n  \"createdAt\": \"2024-03-26T20:02:34.935Z\",\n  \"updatedAt\": \"2024-03-27T20:03:05.890Z\",\n  \"archived\": false,\n  \"associations\": {\n    \"payments\": {\n      \"results\": [\n        {\n          \"id\": \"50927296322\",\n          \"type\": \"order_to_commerce_payment\"\n        }\n      ]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association from an existing order, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the following URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/order/{orderId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to remove an associated payment from an order, your request URL would be the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/order/{orderId}/associations/commerce_payments/{paymentId}/523"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Order properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When managing your order data, you may want to use some of the common properties in the table below. To get all order properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/order"
      }), ". Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Label in UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_order_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the order."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_currency_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Currency Code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The currency that the order was placed in."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_source_store"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Source Store"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The store that that the order came from."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_fulfillment_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fulfillment Status"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The current fulfillment / shipping status of the order."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_shipping_status_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shipping Status URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL for tracking the shipment status."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_shipping_tracking_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shipping Tracking Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The tracking number for shipping."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_shipping_address_street"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shipping Street"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The street address for shipping."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_shipping_address_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shipping City"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The city in the shipping address."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_shipping_address_postal_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Shipping ZIP/Postal Code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The zip code of the shipping address."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pipeline"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The pipeline that the order is in. Pipelines contain stages for tracking the order's progress. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#pipelines-and-stages",
              children: "pipelines and stages"
            }), " below."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Stage"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The order's progress within its current pipeline. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#pipelines-and-stages",
              children: "pipelines and stages"
            }), " below."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pipelines and stages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To track an order's progress, you can create pipelines with defined stages for each step of the fulfillment process. For example, you could create a pipeline for online orders with stages for when the order has been opened, paid, processed, shipped, cancelled, and refunded."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/crm/v3/pipelines/%7BobjectType%7D",
        children: "pipelines API"
      }), ", you can create an order pipeline by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/pipelines/order"
      }), ". In the request body, you'll include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " for the pipeline, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "displayOrder"
      }), " for the display in HubSpot, and a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "stages"
      }), " array with objects for each stage."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"label\": \"Online orders\",\n  \"displayOrder\": 0,\n  \"stages\": [\n    {\n      \"label\": \"Open\",\n      \"displayOrder\": 0,\n      \"metadata\": {\n        \"state\": \"OPEN\"\n      }\n    },\n    {\n      \"label\": \"Paid\",\n      \"displayOrder\": 1,\n      \"metadata\": {\n        \"state\": \"OPEN\"\n      }\n    },\n    {\n      \"label\": \"Processed\",\n      \"displayOrder\": 2,\n      \"metadata\": {\n        \"state\": \"OPEN\"\n      }\n    },\n    {\n      \"label\": \"Shipped\",\n      \"displayOrder\": 3,\n      \"metadata\": {\n        \"state\": \"CLOSED\"\n      }\n    },\n    {\n      \"label\": \"Cancelled\",\n      \"displayOrder\": 4,\n      \"metadata\": {\n        \"state\": \"CLOSED\"\n      }\n    },\n    {\n      \"label\": \"Refunded\",\n      \"displayOrder\": 5,\n      \"metadata\": {\n        \"state\": \"CLOSED\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The pipeline's label as it should appear in HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "displayOrder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The order for displaying the pipeline in HubSpot. If two pipelines have a matching ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "displayOrder"
            }), ", they will be sorted alphabetically by label."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "stages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing the pipeline stages. Each stage is an object containing the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), ": the stage's label as it should appear in HubSpot."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "displayOrder"
                }), ": the order in which the stage will appear in HubSpot."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "metadata"
                }), ": configures whether the stage is in progress (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "OPEN"
                }), ") or complete (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CLOSED"
                }), ") using the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "state"
                }), " field."]
              })]
            })]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}