"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 176532371363;
const slug = exports.slug = 'reference/ui-components/crm-data-components/crm-association-stage-tracker';
const title = exports.title = 'CrmAssociationStageTracker';
const name = exports.name = 'CrmAssociationStageTracker | UI components (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the CrmAssociationStageTracker component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CrmAssociationStageTracker | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CrmAssociationStageTracker"
      }), " component renders a lifecycle or pipeline stage progress bar and a list of properties."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use this component to show stage progress for records associated with the currently displaying record. Each component instance can fetch data from one type of object (contacts, companies, deals, tickets, or custom objects). You can specify association labels to only display data from associated CRM records with that assigned label. You can also edit the property values inline."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/crmAssociatedStageTracker-example.gif",
        alt: "crmAssociatedStageTracker-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { CrmAssociationStageTracker } from '@hubspot/ui-extensions/crm';\n\nconst Extension = () => {\n  return (\n    <CrmAssociationStageTracker\n      objectTypeId=\"0-3\"\n      properties={['dealname', 'closed_won_reason', 'hs_acv', 'hs_arr']}\n      showProperties={true}\n      associationLabels={['Decision maker']}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), " Required"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The numeric ID of the type of associated object to display data from (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-1"
            }), " for contacts). See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/understanding-the-crm#object-type-id",
              children: "complete list"
            }), " of object IDs."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The properties of the associated records to display, up to four."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "showProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether to display the properties below the progress indicator. When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", properties will not display."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationLabels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "If provided, will only request a list of associated records with the specified label (case sensitive)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filters"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["If provided, the component will request a list of associated records that match the specified criteria. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/overview#filtering-data",
              children: "filtering in CRM data components"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["If provided, overrides the default sorting rules used in the search query. In the array, you'll include an object for the column you want to sort by, which specifies:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "columnName"
                }), ": the column to sort by."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "direction"
                }), ": the direction to sort by. Can be either ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), " (ascending) or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "-1"
                }), " (descending). By default, order is ascending."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-property-list",
          children: "CrmPropertyList"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-data-highlight",
          children: "CrmDataHighlight"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-report",
          children: "CrmReport"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-stage-tracker",
          children: "CrmStageTracker"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}