"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611088;
const slug = exports.slug = 'getting-started/slack/developer-slack';
const title = exports.title = 'Únete a la comunidad Developer Slack de HubSpot';
const name = exports.name = 'Registro en Slack - Diseñadores Desarrolladores';
const metaDescription = exports.metaDescription = 'El Developer Slack de HubSpot es una comunidad dedicada a que los desarrolladores de HubSpot se reúnan para recibir asistencia de desarrollador a desarrollador y colaborar en torno a la plataforma HubSpot. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Colabora en Developer Slack';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C2%BFpara-qui%C3%A9n-es-la-comunidad-developer-slack%3F",
  "label": "¿Para quién es la comunidad Developer Slack?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFqu%C3%A9-puedo-esperar-una-vez-que-me-una-a-la-comunidad%3F",
  "label": "¿Qué puedo esperar una vez que me una a la comunidad?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%BAnete-a-nuestro-developer-slack-registr%C3%A1ndote-a-continuaci%C3%B3n",
  "label": "Únete a nuestro Developer Slack registrándote a continuación",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Form
    } = _components;
  if (!Form) _missingMdxReference("Form", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Únete a la comunidad Developer Slack de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El Developer Slack de HubSpot es una comunidad dedicada a que los desarrolladores de HubSpot se reúnan para recibir asistencia de desarrollador a desarrollador y colaborar en torno a la plataforma HubSpot. Iniciada en 2016, la comunidad de desarrolladores de Slack ha crecido hasta contar con cientos de desarrolladores activos de todo el mundo reunidos para compartir sus conocimientos y experiencia."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Para quién es la comunidad Developer Slack?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tanto si estás empezando a desarrollar en la plataforma HubSpot como si eres un experto, el Developer Slack es para ti. Tenemos canales dedicados a múltiples aspectos de nuestra plataforma, desde API, desarrollo de correo electrónico, desarrollo local utilizando nuestra CLI ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli"
      }), " , ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Plantillas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Temas"
      }), " y mucho más."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Qué puedo esperar una vez que me una a la comunidad?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes esperar encontrar una comunidad activa de desarrolladores que se apoyan y enseñan mutuamente. Esto es ante todo una comunidad, cuanto más pongas en ella más sacarás de ella. Esta comunidad es mundial, pero la mayoría de sus miembros se encuentran en el hemisferio occidental, lo que significa que si vives en el hemisferio oriental es posible que no veas respuestas rápidas. No es que nadie ignore o no vea tus publicaciones, probablemente es que la gente está durmiendo. La forma de cambiar eso es ser una de las personas de tu zona horaria que está activa y ayuda a los demás. Así la comunidad crecerá y te será más útil. En HubSpot nos preocupamos profundamente por esta comunidad y formamos parte de ella. Trata a los demás con respeto, trata a los HubSpotters con respeto. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/code-of-conduct",
        children: "Revisa nuestro código de conducta de la Comunidad Slack"
      }), ". Crezcamos mejor juntos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Únete a nuestro Developer Slack registrándote a continuación"
    }), "\n", ' ', "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "7fd756b1-d981-4b81-9caf-8a79a25bffb2",
      responseType: "inline",
      message: `<br/><button target="_blank" href="https://cta-redirect.hubspot.com/cta/redirect/53/18a2bcd4-227f-46b9-b7e4-52dba8955ae5">Enter the Slack community</button>`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}