"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42748432498;
const slug = exports.slug = 'guides/apps/marketplace/certification-requirements';
const title = exports.title = 'App Marketplace | Voraussetzungen für die App-Zertifizierung';
const name = exports.name = 'EMEA DACH (DE) App Certification Requirements';
const metaDescription = exports.metaDescription = 'In diesem Artikel erfahren Sie, was App-Partner für ein Listing ihrer App-Zertifizierung im App Marketplace von HubSpot benötigen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C3%BCberblick",
  "label": "Überblick",
  "parent": null
}, {
  "depth": 0,
  "id": "vorteile-einer-zertifizierung",
  "label": "Vorteile einer Zertifizierung",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%BCberpr%C3%BCfungskriterien",
  "label": "Überprüfungskriterien",
  "parent": null
}, {
  "depth": 1,
  "id": "sicherheit-und-datenschutz",
  "label": "Sicherheit und Datenschutz",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 2,
  "id": "feedback-beispiel",
  "label": "Feedback-Beispiel",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 1,
  "id": "zuverl%C3%A4ssigkeit-und-leistung",
  "label": "Zuverlässigkeit und Leistung",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 2,
  "id": "feedback-beispiele",
  "label": "Feedback-Beispiele",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 1,
  "id": "benutzerfreundlichkeit-und-barrierefreiheit-%7C-app",
  "label": "Benutzerfreundlichkeit und Barrierefreiheit | App",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 2,
  "id": "feedback-beispiel",
  "label": "Feedback-Beispiel",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 1,
  "id": "benutzerfreundlichkeit-und-barrierefreiheit-%7C-app-marketplace-listing",
  "label": "Benutzerfreundlichkeit und Barrierefreiheit | App Marketplace-Listing",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 2,
  "id": "feedback-beispiele",
  "label": "Feedback-Beispiele",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 1,
  "id": "benutzerfreundlichkeit-und-barrierefreiheit-%7C-begleitende-dokumentation",
  "label": "Benutzerfreundlichkeit und Barrierefreiheit | Begleitende Dokumentation",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 2,
  "id": "feedback-beispiel",
  "label": "Feedback-Beispiel",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 1,
  "id": "wert",
  "label": "Wert",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 2,
  "id": "feedback-beispiel",
  "label": "Feedback-Beispiel",
  "parent": "%C3%BCberpr%C3%BCfungskriterien"
}, {
  "depth": 0,
  "id": "der-%C3%BCberpr%C3%BCfungsprozess-zur-zertifizierung-und-neuzertifizierung-einer-app",
  "label": "Der Überprüfungsprozess zur Zertifizierung und Neuzertifizierung einer App",
  "parent": null
}, {
  "depth": 0,
  "id": "h%C3%A4ufig-gestellte-fragen",
  "label": "Häufig gestellte Fragen",
  "parent": null
}, {
  "depth": 0,
  "id": "weiterf%C3%BChrende-ressourcen",
  "label": "Weiterführende Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      h3: "h3",
      code: "code",
      h4: "h4",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Quote,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Quote) _missingMdxReference("Quote", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Zertifizierung im App Marketplace"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die App-Zertifizierung beinhaltet, dass das HubSpot Ecosystem Quality-Team überprüft und bestätigt, dass Ihre auf", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "gelistete App"
      }), " die folgenden Anforderungen an Sicherheit, Datenschutz, Zuverlässigkeit, Leistung, Benutzerfreundlichkeit, Barrierefreiheit und Wert erfüllt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zertifizierte Apps zeichnen sich im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/de/marketplace/apps",
        children: "App Marketplace"
      }), " durch einen guten Ruf für Qualität und Vertrauenswürdigkeit aus. Außerdem erhält Ihre App ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#certification-benefits",
        children: "besondere Vorteile"
      }), " und konstruktives Feedback vom HubSpot Ecosystem Quality-Team während der Überprüfung der App-Zertifizierung."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Diese Anforderungen können sich ändern, da HubSpot seinen App Marketplace und sein Ökosystem kontinuierlich verbessert. HubSpot kann eine App-Zertifizierungsanfrage nach eigenem Ermessen ablehnen, wenn sie nicht den festgelegten Standards entspricht."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot überprüft Ihre App ", (0, _jsxRuntime.jsx)("u", {
            children: "nicht"
          }), ", es sei denn, Sie reichen ein Demo-Video ein, wie beim ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/applying-for-app-certification",
            children: "Beantragen einer App-Zertifizierung"
          }), " angewiesen."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Sie können jeweils nur eine App zur Zertifizierung einreichen. Wenn Sie mehr als eine App gleichzeitig zur Zertifizierung einreichen, werden diese basierend auf der Reihenfolge der Einreichung abgelehnt."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Überblick"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie eine Übersicht über die Anforderungen an die App-Zertifizierung. Weitere Informationen hierzu finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#review-criteria",
        children: "Abschnitt mit den Überprüfungskriterien"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre App muss Folgendes erfüllen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Sie muss mit einer einzigen HubSpot-App-ID verknüpft sein."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ihre gelistete öffentliche App muss eindeutig sein. Wenn bereits eine App von Ihnen gelistet ist und Sie diese ersetzen möchten, sollten Sie die bestehende App aktualisieren, anstatt eine neue zu listen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Erstellen Sie nicht mehrere Apps, die für denselben Anwendungsfall eine Lösung bieten. Apps mit ähnlicher Funktionalität und Verwendung der gleichen APIs sollten in einer einzigen App konsolidiert werden."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sie muss ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "OAuth-Authentifizierung"
          }), " sowie alle erforderlichen Bereiche verwenden."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sie muss mit einer ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "verifizierten Domain"
          }), " verknüpft sein."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Öffentliche Inhaltselemente, die mit Ihrer App verknüpft sind, müssen die bewährten Sicherheitsmethoden einhalten."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sehen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#security-privacy",
        children: "ausführliche Liste der Sicherheits- und Datenschutzanforderungen"
      }), " an."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["App-Aktivitäten werden durch OAuth-authentifizierte Anfragen an HubSpot-", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "APIs"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests",
          children: "signierte Anfragen"
        }), " von HubSpot-", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "Webhook-Abonnements"
        }), " und Erweiterungen (z. B. Anfragen zum Abrufen der Daten von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRM-Karten"
        }), ") definiert."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Aktive Installationen sind die Anzahl der einzigartigen HubSpot-Produktions-Accounts, die nicht mit Ihrer Organisation verbunden sind und die erfolgreiche ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App-Aktivitäten"
        }), " in den letzten 30 Tagen zeigen."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorteile einer Zertifizierung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zusätzlich zu den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners/app",
        children: "Vorteilen eines Listings Ihrer App"
      }), " profitieren zertifizierte Apps von Folgendem:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein „Von HubSpot zertifizierte App“-Abzeichen, das in ihrem App Marketplace-Listing präsentiert wird"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bessere Sichtbarkeit im HubSpot App Marketplace", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Aufnahme in den „Von HubSpot zertifizierte App“-Suchfilter."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Berechtigung für die Aufnahme in kuratierte App Marketplace-Sammlungen."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Zugriff auf das „Von HubSpot zertifizierte App“-Abzeichen und Social-Media-Bilder, um die Zertifizierungsleistung der App zu teilen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Günstige Berücksichtigung in den Partnerschafts- und Verstärkungsinitiativen von HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Überprüfungskriterien"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um eine Zertifizierung zu erhalten, muss Ihre App Qualität nachweisen, indem sie quantitative Kennzahlen und qualitative Deskriptoren für Sicherheit, Datenschutz, Zuverlässigkeit, Leistung, Benutzerfreundlichkeit, Barrierefreiheit und Wert erfüllt. Die folgenden Anforderungen sind nach diesen Kategorien gegliedert und enthalten Beispiele für konstruktives Feedback, das Sie erhalten können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sicherheit und Datenschutz"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre App muss Folgendes erfüllen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie muss mit einer einzigen HubSpot-App-ID verknüpft sein. Ihre App muss API-Anfragen mit der öffentlichen HubSpot App-ID (und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth-Client-ID"
        }), "), die mit Ihrem App-Listing verknüpft ist, autorisieren.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ein Listing darf nicht zu einer anderen öffentlichen oder privaten App weiterleiten."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ihre gelistete öffentliche App darf keine andere öffentliche oder private App benötigen, um zu funktionieren."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie muss durch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "den OAuth-Prozess mit Autorisierungscode"
        }), " authentifiziert sein", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Benutzer aufzufordern, OAuth-Codes oder -Token zu kopieren und einzufügen, ist untersagt. Benutzer sollten nur aufgefordert werden, Zugriff zu gewähren."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Apps müssen Zugriffstoken ohne Beteiligung des Benutzers anfordern, verwalten und aktualisieren"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie verwendet alle ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "Bereiche"
        }), ", die sie für die Installation anfordert (d. h. sowohl im erforderlichen", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), "-Parameter als auch im ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), "-Parameter).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "Erweiterte Bereichseinstellungen"
            }), " sind aktiviert und alle erforderlichen, bedingt erforderlichen und optionalen Bereiche, die die App für die Installation anfordert, sind ausgewählt."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Irrelevante Bereiche müssen entfernt werden."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wenn bestimmte Bereiche nur für eine Teilmenge der Benutzerbasis Ihrer App gelten, sollten sie als bedingt erforderliche oder optionale Bereiche enthalten sein."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie muss mit einer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "verifizierten Domain"
        }), " verknüpft sein."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ihre öffentlichen Inhaltselemente werden auf bewährte Sicherheitspraktiken im Zusammenhang mit veralteter Software und verschiedenen Schwachstellen und Erkenntnissen im Webserver überprüft."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback-Beispiel"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app currently requires four scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "timeline"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". According to our logs, however, it only made requests to the CRM Contacts and Timeline Events APIs in the last 30 days. Since the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " scopes are not required for either of these functions, please remove them as required from the app’s settings to minimize the permissions users must accept."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuverlässigkeit und Leistung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre App muss Folgendes erfüllen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Sie muss in folgender Hinsicht einwandfrei sein:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Sie muss allen geltenden Bedingungen entsprechen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Sie wurde in den letzten sechs Monaten nicht zur Zertifizierung abgelehnt."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Sie hat keine ungelösten Support-Eskalationen mit gemeinsamen Kunden."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Sie verwendet stabile, öffentliche Versionen der APIs und Erweiterungen von HubSpot."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Die Verwendung der neuesten öffentlichen Versionen wird empfohlen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Nicht dokumentierte, Beta- und Entwicklervorschau-APIs gelten als instabil und dürfen nicht in Ihrer Produktions-App verwendet werden."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Hinweis: Wenn Ihre App APIs verwendet, die nicht stabil oder öffentlich sind, geben Sie dies bitte während der Zertifizierungsprüfung bekannt oder wenden Sie sich an das App-Partner-Manager-Team, um Optionen zu besprechen."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sie halten ein angemessenes Volumen an ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "Aktivitäten"
          }), " von HubSpot-Kunden-Accounts aufrecht, die nicht mit Ihrem Unternehmen verbunden sind."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ihre App muss die ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/api-usage/usage-details",
            children: "Richtlinien zur API-Nutzung"
          }), " sowie bewährte Methoden einhalten, einschließlich:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Einhaltung von Ratenlimits (d. h. 100 eingehende Anfragen alle 10 Sekunden pro verknüpftem HubSpot-Account)."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Aktualisieren von OAuth-Zugriffstoken vor deren Ablauf."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Zwischenspeichern von Daten für wiederholte Anrufe, sofern möglich."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Verwendung von Batch-APIs und Webhook-Abonnements, um das Anforderungsvolumen zu reduzieren, wenn möglich."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Verwenden von APIs zum Erstellen von Eigenschaften, Workflows und benutzerdefinierten Workflow-Aktionen, anstatt Benutzeraktionen zu verlangen."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ihre App muss bei allen ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "Aktivitäten"
          }), " durchgängig eine durchschnittliche Erfolgsquote von über ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "95 %"
          }), " aufweisen"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Anfragen, die zu Fehlerantworten führen, werden auf diese Erfolgsquote angerechnet."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Einige unvermeidbare oder erwartete Fehler können ausgeschlossen werden, wenn Erfolgsquoten über alle ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/marketplace/certification-requirements#activity",
              children: "Aktivitäten"
            }), " hinweg berechnet werden."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ihre App verfügt möglicherweise über eine Browsererweiterung, um Kunden zusätzliche Funktionen und einen Mehrwert zu bieten:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Browsererweiterungen dürfen nicht speziell für die HubSpot-Benutzeroberfläche oder als Workaround für die HubSpot-APIs erstellt werden."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Browsererweiterungen dürfen keine Funktionen oder Komponenten in die Benutzeroberfläche von HubSpot einfügen.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Offiziell unterstützte UI-Erweiterungen (z. B. ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "CRM-Karten"
                }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/automation/custom-workflow-actions",
                  children: "benutzerdefinierte Workflow-Aktionen"
                }), ") bieten Kunden ein konsistenteres Nutzererlebnis"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ihre App wird einer zusätzlichen Sicherheitsbewertung unterzogen, wenn sie eine Browsererweiterung enthält."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback-Beispiele"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s API success rate falls below the 95% threshold required for certification. Our logs show a 83% success rate in the last 30 days. The vast majority of these requests returned ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), " burst rate limit errors. To reduce this error rate, we recommend throttling requests to 100 requests per 10 seconds for each account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating errors around trying to update contacts using an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undefined"
        }), " email address, which will not work with this endpoint. Your app should skip these requests if a record does not have an email address."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is making requests with expired OAuth tokens and receiving ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors before refreshing the token. To minimize these errors, we recommend that your app keep track of when tokens expire or refresh tokens before making requests. If you start seeing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors for 100% of requests and are unable to refresh the access token, consider the app uninstalled and stop making requests for the account until a user re-authenticates your app."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors from trying to use the Contact Lists API with Marketing Hub Free accounts, which do not have access to contact lists. If your app repeatedly gets ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors for missing the proper scopes, it should stop making calls to retrieve lists from that account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s webhook subscriptions frequently fail with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "500"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "503"
        }), " errors. Make sure that your server can handle the volume of requests (currently limited to 150 per second) so that customer data is not lost."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is pulling many contacts one at a time instead of pulling them in batches. We recommend using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "batch endpoints"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benutzerfreundlichkeit und Barrierefreiheit | App"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ihre App muss mindestens sechs Monate im HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/de/marketplace/apps",
          children: "App Marketplace"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "gelistet"
        }), " sein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ihre App muss die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/ten-usability-heuristics/",
          children: "bewährten Methoden in Bezug auf Benutzerfreundlichkeit"
        }), " befolgen.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Die Installation und die Verwendung sollten nicht zu Irritationen und Frustration der gemeinsamen Kunden führen oder sich anderweitig negativ auf das grundlegende HubSpot-Nutzererlebnis auswirken."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback-Beispiel"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app currently requires users to manually configure workflow webhook actions to send text messages. Consider creating custom workflow actions via the app which are flexible enough to accommodate many use cases."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benutzerfreundlichkeit und Barrierefreiheit | App Marketplace-Listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihr App Marketplace-Listing muss Folgendes erfüllen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es muss die aktuelle Funktionalität Ihrer App genau beschreiben.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ändert sich die Funktionalität basierend auf der Produkt- oder Abonnementebene eines Benutzers, entweder für HubSpot oder Ihre Lösung, müssen die Unterschiede eindeutig benannt werden."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sie enthält klare und genaue Preisinformationen, einschließlich eines Links zu Ihrer aktuellen Preisseite.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Wenn mehrere Pakete Ihre App unterstützen, muss das App Marketplace-Listing mindestens die kostengünstigste Option enthalten."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verwenden Sie Platzhalterdaten oder blenden Sie Daten aus, um keine personenbezogenen Daten (PII) anzuzeigen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es enthält:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Informative und aktuelle visuelle Hilfsmittel, die Screenshots oder ein Video umfassen können. Auf der Seite ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
              children: "How to Make a Great App Demo Video"
            }), " (So machen Sie ein großartiges App-Demo-Video) finden Sie Best Practices und Beispiele zum Erstellen eines Demo-Videos."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Eine aktuelle „URL zur Setup-Dokumentation“, die eine Subdomain enthält und direkt zu der Seite führt, auf der die Dokumentation für Ihre App gehostet wird. Dieser Link darf nicht zu Ihrer Homepage führen."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es enthält nicht:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Daten oder Statistiken, es sei denn, es wird eine Fallstudie als Ressource angegeben."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback-Beispiele"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your App Marketplace listing includes few specific details about your app’s functionality. Please enhance the listing with screenshots which depict app functionality and include more thorough descriptions of common use cases and in-app behavior."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot customers are used to a “try before you buy” experience when purchasing our products and services. For this reason, we recommend your app provide a free trial or freemium sign-up experience. Some app partners who do not have pricing pages or free trials have created “HubSpot plans,” offering mutual customers transparent pricing, touchless sign-up, and other benefits."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benutzerfreundlichkeit und Barrierefreiheit | Begleitende Dokumentation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die begleitende Dokumentation für Ihre App muss Folgendes erfüllen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie muss auf einer öffentlich zugänglichen Live-URL (d. h., es gibt keine Paywall und es ist keine Anmeldung erforderlich) gehostet werden und die aktuellen Standards in Bezug auf Barrierefreiheit, Datenschutz und DSGVO einhalten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie muss stets auf dem Laufenden sein und sich auf die aktuelle Version Ihrer App beziehen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie muss Folgendes eindeutig beschreiben:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Was die App leistet."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wie Ihre App installiert wird und ein HubSpot-Account verknüpft wird, mit Screenshots von jedem Schritt, einschließlich des Bildschirms für Bereichsgenehmigungen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wie Ihre App nach der Installation konfiguriert wird."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wie Ihre App verwendet wird, einschließlich manueller und automatisierter Interaktionen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wie HubSpots Verknüpfung von Ihrer App getrennt wird."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wie Ihre App von einem HubSpot-Account deinstalliert wird."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Wie sich das Entfernen und Deinstallieren auf die HubSpot-Accounts und Daten von Benutzern auswirkt."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie umfasst Bilder. Alle Bilder, die Screenshots der Benutzeroberfläche von HubSpot enthalten, sollten aktuell sein und in Einklang mit unserem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://canvas.hubspot.com/",
          children: "Design-System"
        }), " stehen.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Videos werden ebenfalls empfohlen, sind jedoch nicht zwingend. Videos sollten regelmäßig aktualisiert werden und die aktuelle Version Ihrer App widerspiegeln."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback-Beispiel"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The setup guide for your app includes a screenshot depicting the scopes your app requires for installation. This screenshot does not show the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "business-intelligence"
        }), " scope, which is selected in your app’s settings. Please update the screenshot so that it reflects the current required scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Wert"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Die aktive Anzahl der Installationen, die Kundenbindung und die Bewertungen im HubSpot App Marketplace werden als Indikatoren für den Wert bewertet, den Ihre App für gemeinsame Kunden darstellt."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ihre App muss über mindestens 60 ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#activity",
              children: "aktive"
            }), ", einmalige Installationen verfügen, um sich für die Zertifizierung zu qualifizieren und diese zu behalten. Die Accounts mit Installationen müssen nicht mit Ihrem Unternehmen verbunden sein. Test-Accounts werden ebenfalls ausgeschlossen.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Wenn Ihre App über weniger als 60 aktive Installationen verfügt, werden Sie aufgefordert, die Zertifizierungsanfrage zu stornieren."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Wenn Ihre App weniger als die drei aktiven Installationen aufweist, die gelistet werden müssen, wird Ihre App möglicherweise vom App Marketplace entfernt."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ihr App-Listing muss Antworten von Ihrem Team für jegliche negative Bewertungen Ihrer App haben."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback-Beispiel"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app has not maintained at least 60 active installs over the trailing six month period. As such, its certified status will be removed. You may re-apply for certification in six months."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Der Überprüfungsprozess zur Zertifizierung und Neuzertifizierung einer App"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das HubSpot Ecosystem Quality-Team antwortet innerhalb von ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "10 Werktagen"
      }), " auf ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "Anfragen zur App-Zertifizierung"
      }), ". Der gesamte Überprüfungs- und Feedback-Prozess der App sollte nicht länger als ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "60 Tage"
      }), " ab dem Übermitteln des Feedbacks dauern. Überprüfen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/testing-credentials",
        children: "hier aufgeführten Kriterien"
      }), " für die Bereitstellung von Testanmeldeinformationen für Ihre App."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihre App alle Anforderungen erfüllt, erhält sie den zertifizierten Status und Kunden und Interessenten wird im App Marketplace ein „Von HubSpot zertifizierte App“-Abzeichen angezeigt. Außerdem wird Ihre App angezeigt, wenn Benutzer den „Von HubSpot zertifizierte App“-Filter auswählen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sollte Ihre App die Bewertung nicht erfolgreich abschließen können Sie sich nach sechs Monaten erneut bewerben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nach einjähriger Zertifizierung kann das HubSpot Ecosystem Quality-Team Ihre App überprüfen, um sicherzustellen, dass sie weiterhin die strengen Zertifizierungsanforderungen erfüllt. Wenn Ihre App diese Standards nicht mehr erfüllt, arbeitet das Team bis zu sechzig Tage lang mit Ihnen zusammen, um Bedenken auszuräumen."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Ihre App die oben aufgeführten Zertifizierungsanforderungen zu irgendeinem Zeitpunkt nicht mehr erfüllt, leitet HubSpot möglicherweise sofort einen Neuzertifizierungsprozess ein, auch wenn Ihre App noch kein volles Jahr zertifiziert ist. Wie in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/app-program-agreement",
          children: "Vereinbarung für das App-Partnerprogramm"
        }), " angegeben, behält sich HubSpot auch das Recht vor, die Veröffentlichung Ihrer App jederzeit rückgängig zu machen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Häufig gestellte Fragen"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Are there any costs associated with app certification?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "No, we do not charge you a fee to list or certify your apps in the App Marketplace, nor a fee for installs generated through the App Marketplace. There is no revenue sharing. We are here to support you to make your app of higher quality."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Will I be notified when my app will be eligible again for certification if it was previously rejected?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No. At this time we do not have notifications enabled to notify you if and when you will be eligible to re-apply at this time. Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best resource to contact and ask if you are eligible before applying."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How should I promote my certified app to my customers?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Feel free to use the press release template on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/resources",
          children: "this page"
        }), " to share the news that your app has earned certification."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you plan to post on social media, be sure to tag HubSpot — we love to celebrate alongside our app partners!"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I enjoy experimenting with the newest beta APIs. Is app certification right for me?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We recommend you reach out to your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " to see if app certification is right for your app."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Our goal is to ensure your app is well built for our mutual customers and limits breaking changes, which requires your app uses the latest stable APIs. We also love seeing and supporting entrepreneurs, early adopters, and developers who are eager to experiment with the newest beta APIs."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I'd like my app to be featured on the App Marketplace. Is this what app certification is for?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The benefits of being featured in collections and for customers to easily filter for a certified app within the App Marketplace are continuing to evolve. We’d like to learn more about how you would find being featured the most helpful (e.g. App Marketplace, HubSpot community, HubSpot curated newsletters or other forms)."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best contact to discuss potential future benefits and start this conversation."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Why and how will my public assets be assessed for security best practices?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "With the average customer using more than five integrations, it’s imperative apps are monitored and held to privacy, security, and quality standards over time. Any public assets will be assessed using information already provided during a listing process and findings will be analyzed using a non-invasive method."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "My app was certified over a year ago. What do I have to do to keep my certification status and what does it mean to get recertified?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The HubSpot Ecosystem Quality team will reach out if your app is out of compliance or due for annual recertification with next steps."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We encourage you to monitor your app’s performance, certification requirements, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "Developer Changelog"
        }), ", and any additional HubSpot resources related to any changes in technology used and how your app could stay up to date."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Finden Sie Ihren ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App-Partner-Manager"
        }), " und seine E-Mail-Informationen, indem Sie sich bei Ihrem Entwickler-Account anmelden und zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), " navigieren. Bewegen Sie den Mauszeiger über Ihre App und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mehr"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listing-Details anzeigen"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weiterführende Ressourcen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "So beantragen Sie eine App-Zertifizierung"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "So listen Sie Ihre App"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App-Listing-Voraussetzungen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
        children: "Entwickler-Community-Forum"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/contact-our-partnerships-team",
        children: "App-Partnerteam kontaktieren"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}