"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611095;
const slug = exports.slug = 'guides/cms/content/content-staging';
const title = exports.title = 'Content Staging';
const name = exports.name = '[new] Content Staging';
const metaDescription = exports.metaDescription = 'Content Staging is an in-app development environment that allows you to redesign or create staged pages, before publishing them to your production site.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Content Staging"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Content Staging is an in-app separate content environment that allows you to update or create staged pages before publishing them to your production site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Content staging can be used during a website redesign project to stage existing pages using a new template. Analytics tied to the page will carry over to the new page on publish from staging."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Since redesigns usually involve more than one page being updated, you can stage all of the pages you need to and publish all of them to the live site at once."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["See ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "Using content staging for a website redesign"
      }), " for detailed instructions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For a full tutorial on how to use Content Staging, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "check out this article from the Website User Guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For building and testing changes to assets like themes, templates, modules, we recommend using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "developer sandbox account"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Learn more about building an efficient development workflow."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}