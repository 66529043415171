"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400676;
const slug = exports.slug = 'reference/cms/hubl/tags/dnd-areas';
const title = exports.title = 'Tags do HubL para áreas de arrastar e soltar';
const name = exports.name = 'PT-BR HubL Supported Tags | dnd_areas';
const metaDescription = exports.metaDescription = 'As tags do HubL usadas para criar áreas de arrastar e soltar que permitem aos desenvolvedores criar seções de páginas que possibilitam fazer mudanças de layout, estilo e conteúdo diretamente nos editores de conteúdo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "dnd_area",
  "label": "dnd_area",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_section",
  "label": "dnd_section",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_column",
  "label": "dnd_column",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_row",
  "label": "dnd_row",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_module",
  "label": "dnd_module",
  "parent": null
}, {
  "depth": 0,
  "id": "plano-de-fundo",
  "label": "Plano de fundo",
  "parent": null
}, {
  "depth": 1,
  "id": "background_color",
  "label": "background_color",
  "parent": "plano-de-fundo"
}, {
  "depth": 1,
  "id": "background_linear_gradient",
  "label": "background_linear_gradient",
  "parent": "plano-de-fundo"
}, {
  "depth": 1,
  "id": "background_image",
  "label": "background_image",
  "parent": "plano-de-fundo"
}, {
  "depth": 0,
  "id": "como-os-par%C3%A2metros-de-estilo-dnd-s%C3%A3o-traduzidos-na-p%C3%A1gina",
  "label": "Como os parâmetros de estilo dnd são traduzidos na página",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tags do HubL para áreas de arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastar e soltar"
      }), " permitem aos desenvolvedores criar seções de páginas e partials globais que possibilitam fazer mudanças de layout, estilo e conteúdo diretamente nos editores de conteúdo. Consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "tutorial sobre como criar uma área de arrastar e soltar"
      }), " para ver uma introdução à criação de áreas de arrastar e soltar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As áreas de arrastar e soltar se baseiam em uma grade de 12 colunas responsivas. As tags de arrastar e soltar renderizam marcações com nomes de classe designando as colunas e as linhas. Você precisará adicionar uma folha de estilos para direcionar esses nomes de classe.Um exemplo de estilos de layout que você pode implementar pode ser encontrado no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "HubSpot CMS Boilerplate"
      }), ". Sua folha de estilos pode ser adicionada ao modelo usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172854037902"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " as áreas de arrastar e soltar não podem ser usadas em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "posts de blog"
        }), " e em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "modelos de e-mail"
        }), " no momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "área de arrastar e soltar"
      }), " é um container que torna uma parte da página web editável em termos de estrutura, design e conteúdo. O corpo de uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " fornece o conteúdo padrão para a área de arrastar e soltar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos em si não podem conter áreas de arrastar e soltar. Para fornecer aos criadores de conteúdo uma interface para adicionar conteúdo uniforme dentro de um módulo, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#repeaters",
        children: "campos e grupos repetíveis"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " pode conter os seguintes parâmetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "class"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma classe adicionada à div que envolve uma dnd_area"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Usado no editor para rotular a área na barra lateral."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_area \"unique_name\", class=\"main\" %}\n\n{% end_dnd_area %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"container-fluid main\">\n  <div class=\"row-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n    </div>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " também podem conter tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " um criador de conteúdo pode trocar o modelo de uma página por outro do mesmo tipo, dependendo se tem tags ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos criados com o editor visual de layout de arrastar e soltar podem ser trocados por outros modelos de arrastar e soltar ou codificados com ou sem as tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados ", (0, _jsxRuntime.jsx)("u", {
            children: "sem"
          }), " tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados sem tags ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_section %}"
        })
      }), " é uma linha de nível superior e deve ser aninhada dentro de uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "As seções também podem ser definidas como um modelo"
      }), " e depois ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-context",
        children: "incluídas"
      }), " em uma ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", tornando-as ideais para a construção rápida de um modelo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " pode conter os seguintes parâmetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de uma ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "cor de fundo"
            }), ". Também pode ser fornecido como uma string."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de uma ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "imagem de fundo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "fundo de gradiente linear"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um booleano que determina se a seção deve ter largura total ou ser restringida por um container interno."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que permite especificar valores de margem em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Quando nenhuma unidade de medida é fornecida, é aplicado o padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de pixel que define a largura máxima do conteúdo em um dicionário de encapsulamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que permite especificar valores de deslocamento em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Quando nenhuma unidade de medida é fornecida, é aplicado o padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alinhamento vertical do conteúdo secundário. As opções disponíveis incluem:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você somente pode usar um parâmetro de plano de fundo por tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_section\n  background_image={\n    \"backgroundPosition\": \"MIDDLE_CENTER\",\n    \"backgroundSize\": \"cover\",\n    \"imageUrl\": \"https://example.com/path/to/image.jpg\"\n  },\n  margin={\n    \"top\": 32,\n    \"bottom\": 32\n  },\n  padding={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\",\n    \"left\": \"1em\",\n    \"right\": \"1em\"\n  },\n  max_width=1200,\n  vertical_alignment=\"MIDDLE\"\n%}\n\n{% end_dnd_section %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 unique_name-row-0-background-image dnd-section unique_name-row-0-max-width-section-centering unique_name-row-0-margin unique_name-row-0-padding\"\n>\n  <div class=\"row-fluid \"></div>\n  <!--end row-->\n</div>\n<!--end row-wrapper -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " também podem conter as seguintes tags:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_column"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_column %}"
        })
      }), " é um componente estrutural vertical que ocupa uma ou mais colunas de layout definidas pela linha principal."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta tag do HubL deve ser aninhada dentro de uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " pode conter os seguintes parâmetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de uma ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "cor de fundo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de uma ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "imagem de fundo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "fundo de gradiente linear"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que permite especificar valores de margem em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Quando nenhuma unidade de medida é fornecida, é aplicado o padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de pixel que define a largura máxima do conteúdo em um dicionário de encapsulamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que permite especificar valores de deslocamento em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Quando nenhuma unidade de medida é fornecida, é aplicado o padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alinhamento vertical do conteúdo secundário. As opções disponíveis incluem:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você somente pode usar um parâmetro de plano de fundo por tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_column\n  offset=0,\n  width=12,\n  background_color={\n    r: 255,\n    g: 0,\n    b: 0,\n    a: 1\n  },\n  margin={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\"\n  },\n%}\n\n{% end_dnd_column %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span12 widget-span widget-type-cell unique_name-column-1-margin unique_name-column-1-background-color unique_name-column-1-vertical-alignment dnd-column\"\n  style=\"\"\n  data-widget-type=\"cell\"\n  data-x=\"0\"\n  data-w=\"12\"\n></div>\n<!--end widget-span -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " também pode conter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_row"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#row",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_row %}"
        })
      }), " é um componente estrutural horizontal que cria um grid com 12 colunas, no qual podem ser colocados colunas e módulos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta tag do HubL deve ser aninhada dentro de uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " pode incluir os seguintes parâmetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de uma ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "cor de fundo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de uma ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "imagem de fundo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que suporta a especificação de um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "fundo de gradiente linear"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que permite especificar valores de margem em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Quando nenhuma unidade de medida é fornecida, é aplicado o padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de pixel que define a largura máxima do conteúdo em um dicionário de encapsulamento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário que permite especificar valores de deslocamento em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". Quando nenhuma unidade de medida é fornecida, é aplicado o padrão ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alinhamento vertical do conteúdo secundário. As opções disponíveis incluem:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você somente pode usar um parâmetro de plano de fundo por tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_row\n  background_color={\n    r: 123,\n    g: 123,\n    b: 123,\n    a: 1.0\n  },\n  margin={\n    \"top\": 20,\n    \"bottom\": 200\n  },\n  padding={\n    \"top\": 20,\n    \"bottom\": 200,\n    \"left\": 20,\n    \"right\": 20\n  }\n%}\n\n{% end_dnd_row %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 main-row-0-padding main-row-0-background-color main-row-0-margin\"\n>\n  <div class=\"row-fluid \"></div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma dnd_row também pode conter as seguintes tags:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#module",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_module %}"
        })
      }), " é um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulo"
      }), " envolto por uma div em que layout, estilos e conteúdo podem ser adicionados. O módulo é especificado referenciando o caminho, que pode ser um módulo padrão do HubSpot (usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/"
      }), "namespace), ou módulos que você construiu, especificando o caminho dentro da árvore de arquivos do gerenciador de design."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta tag do HubL deve ser aninhada dentro de uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " pode conter os seguintes parâmetros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O caminho de um módulo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Posicionamento horizontal, permite:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LEFT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CENTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RIGHT"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O deslocamento em relação a zero na grede de 12 colunas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de colunas que ocupam a grade de 12 colunas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "flexbox_positioning"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Descontinuado"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descontinuado; não utilizar."
            }), " Em vez disso, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            }), " em conjunto com o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            }), " da linha ou seção.", (0, _jsxRuntime.jsx)("br", {}), "Valor da posição Flexbox do módulo. Oferece suporte a uma string que indica a posição vertical seguida da horizontal:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um dos seus módulos antigos tem um nome de campo que corresponde a um dos parâmetros ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " acima? Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates#passing-fields-that-have-dnd-associated-parameters",
          children: "passar os valores padrão utilizando um parâmetro de campos"
        }), ", tal como faria com um grupo de campos."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n  path=\"@hubspot/rich_text\",\n  offset=0,\n  width=8,\n%}\n  {% module_attribute \"html\" %}\n    <h1>Hello, world!</h1>\n  {% end_module_attribute %}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span8 widget-span widget-type-custom_widget\"\n  style=\"\"\n  data-widget-type=\"custom_widget\"\n  data-x=\"0\"\n  data-w=\"12\"\n>\n  <div\n    id=\"hs_cos_wrapper_main-module-1\"\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module\"\n    style=\"\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"module\"\n  >\n    <span\n      id=\"hs_cos_wrapper_module-1_\"\n      class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n      style=\"\"\n      data-hs-cos-general-type=\"widget\"\n      data-hs-cos-type=\"rich_text\"\n    >\n      <h1>Hello, world!</h1>\n    </span>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plano de fundo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existem algumas formas de definir planos de fundo nos elementos dnd de coluna, seção e linha: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-image",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_image"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-linear-gradient",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_linear_gradient"
        })
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-color",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_color"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_color"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As tags dnd de coluna, seção e linha oferecem suporta a cores de fundo. Você pode definir a cor de fundo padrão para um elemento de arrastar e soltar usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_color"
      }), ". Esse parâmetro é um parâmetro baseado em string e pode incluir os formatos descritos no exemplo abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section %}\n  // Hex Value (both 3 and 6 char length)\n  {% dnd_column background_color=\"#F7F7F7\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"#FFF\" %}\n  {% end_dnd_column %}\n// Both RGB and RGBA\n  {% dnd_column background_color=\"rgb(255,255,255)\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"rgba(0,0,0,.25)\" %}\n  {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_linear_gradient"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os elementos dnd de coluna, seção e linha oferecem suporte a gradientes lineares de fundo. Você pode definir um gradiente padrão usando o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_linear_gradient"
      }), ". O parâmetro espera um dicionário. No momento, ele oferece suporte apenas a duas paradas de cor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A direção do gradiente.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to bottom"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to top"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to right"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Matriz de strings de cor. No momento, oferece suporte a dois valores: o início e o fim. Os valores são fornecidos como strings e os seguintes formatos são suportados:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGB"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGBA"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "8 char hex"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_linear_gradient={\n    \"direction\": \"to bottom\",\n    \"colors\": [\n      \"#1EB6C3\",\n      \"#2A2859\"\n    ]\n  }\n  %}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_image"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os elementos dnd de coluna, seção e linha oferecem suporte a imagens de fundo. Você pode fornecer uma imagem de fundo padrão usando o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_image"
      }), " que espera um dicionário."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Legenda"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundPosition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A posição da imagem no plano de fundo. Oferece suporte a uma string que indica a posição vertical seguida pela horizontal.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundSize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A propriedade CSS de tamanho de fundo utilizada para a imagem.", (0, _jsxRuntime.jsx)("br", {}), "Os valores permitidos são:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "cover"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "contain"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "imageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL absoluta da imagem."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_image = {\n      \"backgroundPosition\": \"MIDDLE_CENTER\",\n      \"backgroundSize\": \"cover\",\n      \"imageUrl\": \"https://www.example.com/bg-image.jpg\"\n    },\n%}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Como os parâmetros de estilo dnd são traduzidos na página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando você usa parâmetros baseados em estilo, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background",
        children: "planos de fundos"
      }), ", margens ou deslocamento, os nomes das classes são automaticamente computados para suas seções, colunas, linhas e módulos. Os valores de propriedade que você atribuiu são adicionados aos nomes de classe criados automaticamente e o código CSS resultante é colocado antes da tag de fechamento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), " na página dentro de uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/responsive-breakpoints",
        children: "estilos de arrastar e soltar também podem ser diferentes em diferentes pontos de quebra"
      }), " para oferecer um visual responsivo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}