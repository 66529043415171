"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 163897240188;
const slug = exports.slug = 'reference/ui-components/standard-components/box';
const title = exports.title = 'Box';
const name = exports.name = 'Box | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Box component for configuring UI extension layout.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 1,
  "id": "using-numbers-in-flex",
  "label": "Using numbers in flex",
  "parent": "usage-examples"
}, {
  "depth": 1,
  "id": "using-alignself",
  "label": "Using alignSelf",
  "parent": "usage-examples"
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Box | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " component renders an empty ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "div"
      }), " container for fine tuning the spacing of components. Commonly used with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/flex",
        children: "Flex"
      }), " component."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see an example of how ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " can be used for layout, check out HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#managing-layouts-flex-and-box",
        children: "Manage layouts: Flex and Box sample project"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-box.png?width=521&height=226&name=ui-extrensions-layout-box.png",
        alt: "ui-extrensions-layout-box"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Flex, Box, Tile } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Flex direction={'row'} justify={'start'} gap={'small'}>\n      <Box flex={1}>\n        <Tile>flex = 1</Tile>\n      </Box>\n      <Box flex={2}>\n        <Tile>flex = 2</Tile>\n      </Box>\n      <Box flex={3}>\n        <Tile>flex = 3</Tile>\n      </Box>\n      <Box flex={4}>\n        <Tile>flex = 4</Tile>\n      </Box>\n    </Flex>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignSelf"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'start'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'center'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'end'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'baseline'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'stretch'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Distributes a child component along the cross-axis using the available free space. Use this prop for nested ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Box"
            }), " components to align them differently from other child components in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Flex"
            }), " group."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flex"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'initial'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'auto'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'none'"
            }), " | Number"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Distributes components based on the available empty space around them. Options are as follows:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "initial"
                }), ": The item is sized according to its width and height properties. It shrinks to its minimum size to fit the container, but does not grow to absorb any extra free space in the flex container. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                }), ": The item is sized according to its width and height properties, but grows to absorb any extra free space in the flex container, and shrinks to its minimum size to fit the container."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "none"
                }), ": The item is sized according to its width and height properties. It is fully inflexible: it neither shrinks nor grows in relation to the flex container."]
              }), (0, _jsxRuntime.jsx)("li", {
                children: "number: tells a component to fill all available space, shared evenly amongst other components with the same parent. The larger the flex given, the higher the ratio of space a component will take compared to its siblings."
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You only need to use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " as a wrapper for components that you want to adjust. For example, if you wrap one component in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flex"
      }), " value, only that one component will have its width adjusted based on the available empty space."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extension-components-box-flex.png?width=523&height=112&name=ui-extension-components-box-flex.png",
        alt: "ui-extension-components-box-flex"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'start'} gap={'small'}>\n  <Box flex={1}>\n    <Tile>Tile 1</Tile>\n  </Box>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using numbers in flex"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flex"
      }), " prop in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " component to assign any extra spacing to components using either a default value (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "auto"
      }), ") or a specific number. When using a number, the components will be distributed based on the ratio of their assigned numbers. This also means that, when assigning a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flex"
      }), " value for only one ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), ", you can use any number, because any number by itself will result in all available space being assigned to that ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the four tiles below take up an increasing amount of space based on their ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flex"
      }), " values."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-box.png?width=522&height=226&name=ui-extrensions-layout-box.png",
        alt: "ui-extrensions-layout-box"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'start'} gap={'small'}>\n  <Box flex={1}>\n    <Tile>flex = 1</Tile>\n  </Box>\n  <Box flex={2}>\n    <Tile>flex = 2</Tile>\n  </Box>\n  <Box flex={3}>\n    <Tile>flex = 3</Tile>\n  </Box>\n  <Box flex={4}>\n    <Tile>flex = 4</Tile>\n  </Box>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using alignSelf"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alignSelf"
      }), " prop to override alignment rules for individual ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " components."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extensions-layout-box-alignself.png?width=525&height=310&name=ui-extensions-layout-box-alignself.png",
        alt: "ui-extensions-layout-box-alignself"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'column'} gap={'small'} align={'start'}>\n  <Box alignSelf={'end'}>\n    <Tile>Top right</Tile>\n  </Box>\n  <Box alignSelf={'center'}>\n    <Tile>Middle</Tile>\n  </Box>\n  <Tile>Bottom left</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/flex",
          children: "Flex"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/divider",
          children: "Divider"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/tile",
          children: "Tile"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}