"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093120;
const slug = exports.slug = 'guides/cms/content/multi-language-content';
const title = exports.title = 'Contenido en varios idiomas';
const name = exports.name = 'EMEA ES Multi-language';
const metaDescription = exports.metaDescription = 'Los desarrolladores que construyen sitios web en HubSpot pueden admitir fácilmente un sitio web en varios idiomas. Es posible que se requiera un poco de esfuerzo de desarrollo según tu negocio único.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "funcionalidades-multiling%C3%BCes-predeterminadas-de-hubspot",
  "label": "Funcionalidades multilingües predeterminadas de HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "lo-que-no-hace-hubspot",
  "label": "Lo que no hace HubSpot",
  "parent": "funcionalidades-multiling%C3%BCes-predeterminadas-de-hubspot"
}, {
  "depth": 0,
  "id": "establecer-variables-de-idioma",
  "label": "Establecer variables de idioma",
  "parent": null
}, {
  "depth": 0,
  "id": "utilizar-m%C3%B3dulos-editables-por-p%C3%A1gina",
  "label": "Utilizar módulos editables por página",
  "parent": null
}, {
  "depth": 0,
  "id": "incluir-traducciones-de-campo-en-m%C3%B3dulos-y-temas-personalizados",
  "label": "Incluir traducciones de campo en módulos y temas personalizados",
  "parent": null
}, {
  "depth": 1,
  "id": "desarrollo-local",
  "label": "Desarrollo local",
  "parent": "incluir-traducciones-de-campo-en-m%C3%B3dulos-y-temas-personalizados"
}, {
  "depth": 1,
  "id": "administrador-de-dise%C3%B1o",
  "label": "Administrador de diseño",
  "parent": "incluir-traducciones-de-campo-en-m%C3%B3dulos-y-temas-personalizados"
}, {
  "depth": 0,
  "id": "traducir-p%C3%A1ginas-del-sistema",
  "label": "Traducir páginas del sistema",
  "parent": null
}, {
  "depth": 0,
  "id": "incluir-un-conmutador-de-idioma",
  "label": "Incluir un conmutador de idioma",
  "parent": null
}, {
  "depth": 0,
  "id": "implementaci%C3%B3n-de-la-b%C3%BAsqueda-en-tu-sitio-web",
  "label": "Implementación de la búsqueda en tu sitio web",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-parciales-globales-y-m%C3%B3dulos",
  "label": "Usar parciales globales y módulos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      h3: "h3",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Contenido en varios idiomas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier empresa que haga negocios entre regiones o con una base de clientes que hable varios idiomas debe poder conectarse con su audiencia en el idioma de la audiencia. Con CMS de HubSpot, los usuarios pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-general/how-to-manage-multi-language-content-with-hubspots-cos",
        children: "crear variantes en varios idiomas"
      }), " de su contenido que permiten al usuario final ver el contenido en el idioma con el que se sienten más cómodos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot establece un número de facetas de un sitio web multilingüe para ti automáticamente, pero también hay un número de pasos que los desarrolladores deben tomar para asegurarse de que tu sitio web está listo para varios idiomas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Funcionalidades multilingües predeterminadas de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada vez que se crea una variante en varios idiomas para una página en HubSpot, automáticamente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Creamos una nueva entrada en el mapa del sitio XML que indique el nombre y la URL de la página traducida."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Especificamos el idioma del contenido dentro de la página ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "<head>"
          }), " para las plantillas creadas con la funcionalidad de arrastrar y soltar."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Identificamos otras páginas dentro del grupo de contenido multilingüe siguiendo el formato estandarizado apropiado, que marca las otras páginas como alternativas para evitar errores de contenido duplicado y también identifica el código ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
            children: "ISO 639-1"
          }), " asociado con la(s) traducción(es) del idioma:"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "<link rel=\"alternate\" hreflang=\"[**_lang_code_**](https://support.google.com/webmasters/answer/189077#language-codes)\" href=\"_url_of_page_\" />"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Vuelve a escribir enlaces en las páginas de idiomas para navegar a las versiones intra-idioma de la página enlazada para ayudar a los visitantes a mantenerse en el idioma y evitar la necesidad de que tengas que actualizar cada enlace en cada traducción de página. Para un elemento dado, puedes desactivar esta reescritura agregando la clase \"hs-skip-lang-url-rewrite\" al elemento."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Lo que no hace HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con el CMS de HubSpot, HubSpot no hace automáticamente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "traducir el contenido de la página para ti."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "dirigir a los usuarios a una variante en varios idiomas basada en su GeoIP."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "incluir un módulo de conmutador de idioma dentro de tu encabezado o sitio web."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "especificar el idioma de una página para los archivos codificados."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "establecer el atributo direccional de contenido para las traducciones utilizando un idioma que se lee de derecha a izquierda en lugar de de izquierda a derecha para los archivos codificados."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Establecer variables de idioma"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debido a que los archivos codificados no incluyen automáticamente declaraciones de idioma o atributos direccionales de idioma de contenido, esto debe configurarse manualmente para plantillas codificadas. Las variables de idioma se pueden configurar en HTML o rellenar a través de HubL, como en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L2",
        children: "plantilla Boilerplate de CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Configurar estas propiedades usando HubL permitirá que estos datos se rellenen dinámicamente dentro del HTML de una página en función del idioma establecido para la página dentro del CMS de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilizar módulos editables por página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para garantizar que el contenido se pueda localizar en cada instancia del uso de una plantilla, aprovecha los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos personalizados"
      }), " en lugar de contenido HTML codificado siempre que sea posible. La creación de módulos que se pueden editar a nivel de página permitirá a los creadores de contenido establecer el contenido específico que debe aparecer en cada página sin tener que ajustar el código de la plantilla. También permite que se utilice contenido único en todas las páginas que comparten una plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Incluir traducciones de campo en módulos y temas personalizados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para respaldar tu equipo global, puedes publicar traducciones de los módulos que creaste en HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de traducir y publicar el contenido en el módulo en los idiomas de los miembros de tu equipo, los usuarios verán las etiquetas de campo para ese módulo en el idioma predeterminado de su cuenta. El contenido de un módulo traducido no se traducirá automáticamente; deberás hacer esto. Puedes crear traducciones de tu módulo en cualquier idioma compatible."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes establecer traducciones usando las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#local-module-file-structure",
        children: "herramientas de desarrollo local"
      }), " o a través del Administrador de diseño."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Desarrollo local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para configurar las traducciones usando herramientas de desarrollo local, cada carpeta de módulo y cada carpeta de tema pueden contener una carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_locales"
      }), ", con subcarpetas de idioma local, cada una con un archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "messages.json"
      }), ", que contiene traducciones de campo de módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/local%20module%20translations.png",
        alt: "Captura de pantalla de las traducciones del módulo de edición local en VS Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Administrador de diseño"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para configurar las traducciones de campo a través del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Administrador de diseño"
      }), ", al ver el módulo, navega a la opción \"Agregar traducciones\" en el lado derecho de la pantalla. Selecciona los idiomas en los que trabaja tu equipo desde el menú desplegable. Desde allí, puedes seleccionar cada idioma y especificar las convenciones de etiquetado para cada campo en cada idioma."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/field_translations.gif",
        alt: "Captura de pantalla de traducciones de campo en el Administrador de diseño"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Las traducciones de campos de tema no tienen una interfaz en el Administrador de diseño y deben editarse a través de los archivos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".json"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Traducir páginas del sistema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para configurar las traducciones de las páginas del sistema, incluidas las páginas de restablecimiento de contraseñas y suscripción de correo electrónico, puedes personalizar los campos de etiquetas de módulo y HubL con el contenido traducido. Más información sobre los campos disponibles para los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "módulos"
      }), " y las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#system-page-tags",
        children: "etiquetas HubL de la página del sistema"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Incluir un conmutador de idioma"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para permitir a los usuarios finales alternar entre las traducciones disponibles, es recomendable que se agregue un módulo de conmutador de idioma a tu sitio web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/09ad0b2c3bbc8400e64c5617268cb0504392e8e5/src/templates/partials/header.html#L26-L47",
        children: "ejemplo de cómo implementar un conmutador de idioma se"
      }), " puede encontrar en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "Boilerplate de temas de CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Header navigation row one #}\n      <div class=\"header__row-1\">\n        {% if content.translated_content.values()|selectattr('published')|length || is_listing_view && group.translations %}\n          <div class=\"header__language-switcher header--element\">\n            <div class=\"header__language-switcher--label\">\n              {% module 'language-switcher' path='@hubspot/language_switcher',\n                label='Language switcher',\n                display_mode='localized'\n              %}\n              <div class=\"header__language-switcher--label-current\"> {{ locale_name(locale) }}</div>\n            </div>\n          </div>\n        {% endif %}\n        <div class=\"header__search header--element\">\n          {% module 'site_search' path='@hubspot/search_input',\n            label='Search',\n            field_label='Search',\n            placeholder=''\n          %}\n        </div>\n      </div>\n      {# End header navigation row one #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementación de la búsqueda en tu sitio web"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "búsqueda de contenido"
      }), " admite la consulta de contenido en los distintos idiomas de tu sitio web. El parámetro de filtro de idioma se puede usar al pulsar", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "/contentsearch/v2/search"
      }), " para devolver solo los idiomas especificados, lo que te permite crear experiencias de búsqueda para cada idioma en tu sitio web o permitir que los visitantes busquen en varios idiomas en tu sitio web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar parciales globales y módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza los campos del módulo para hacer que el texto en encabezados, pies de página y barras laterales sea editable. Coloca estos módulos en parciales globales. Los creadores de contenido no solo se beneficiarán de la facilidad de edición, sino que los parciales globales ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-general/how-to-manage-multi-language-content-with-hubspots-cos#edit-global-content-in-a-multi-language-page",
        children: "admiten la configuración de sus ajustes para cada idioma"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/edit-multilanguage-global-content.png",
        alt: "Captura de pantalla del editor de páginas que muestra parciales del encabezado"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}