"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694136;
const slug = exports.slug = 'guides/cms/content/forms';
const title = exports.title = 'HubSpot forms';
const name = exports.name = 'HubSpot forms';
const metaDescription = exports.metaDescription = 'Learn how to add forms to your HubSpot website or external pages using modules, HubL, or the embed code. Then, learn about how to style them.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "the-default-form-module",
  "label": "The default form module",
  "parent": null
}, {
  "depth": 1,
  "id": "cloning-the-default-module",
  "label": "Cloning the default module",
  "parent": "the-default-form-module"
}, {
  "depth": 0,
  "id": "form-fields-in-custom-modules",
  "label": "Form fields in custom modules",
  "parent": null
}, {
  "depth": 1,
  "id": "limit-form-options-in-the-editor",
  "label": "Limit form options in the editor",
  "parent": "form-fields-in-custom-modules"
}, {
  "depth": 0,
  "id": "using-the-form-embed-code",
  "label": "Using the form embed code",
  "parent": null
}, {
  "depth": 1,
  "id": "add-internationalization",
  "label": "Add internationalization",
  "parent": "using-the-form-embed-code"
}, {
  "depth": 0,
  "id": "form-events",
  "label": "Form events",
  "parent": null
}, {
  "depth": 0,
  "id": "form-styling",
  "label": "Form styling",
  "parent": null
}, {
  "depth": 1,
  "id": "styling-forms-via-the-default-form-module-or-hubl-tag",
  "label": "Styling forms via the default form module or HubL tag",
  "parent": "form-styling"
}, {
  "depth": 1,
  "id": "styling-forms-via-a-custom-module",
  "label": "Styling forms via a custom module",
  "parent": "form-styling"
}, {
  "depth": 1,
  "id": "styling-forms-added-via-the-form-embed-code",
  "label": "Styling forms added via the form embed code",
  "parent": "form-styling"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot forms"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use HubSpot forms to capture information from website visitors, which you can then access throughout HubSpot. You can share links to forms directly with users, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "submit form data via the API"
      }), ", and embed them on your website pages using the CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Forms are a core part of the HubSpot and can be created in HubSpot accounts of any subscription level. Not only are forms important for customer conversion, but also because form data can be used in other HubSpot tools and assets, such as smart content, lists, workflows, content personalization, and more."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms",
        children: "creating a HubSpot form"
      }), ", you can add it to your templates and pages. There are a few ways to add a form to a template, depending on your use case:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-default-form-module",
          children: "Using the default form module"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#form-fields-in-custom-modules",
          children: "Adding a form field to a custom module"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-hubl-tag"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-embed-code",
          children: "Embedding using the form embed code"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "The default form module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your template has ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "drag and drop areas"
      }), ", content creators can add the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "default form module"
      }), " to a page from the page editor, then configure the form options in the left sidebar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To code a form module directly into a template with drag and drop areas, reference it as a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"dnd_area\" class='body-container body-container__landing', label='Main section' %}\n {% dnd_section vertical_alignment='MIDDLE' %}\n  {% dnd_column width=6, offset=6 %}\n   {% dnd_row %}\n\n    <!-- Form module tag for use in templates -->\n    {% dnd_module path='@hubspot/form' %}\n    {% end_dnd_module %}\n\n   {% end_dnd_row %}\n  {% end_dnd_column %}\n {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add a form module to a template outside of a drag and drop area, you'll instead reference it as a standard ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With either implementation, you can add parameters to the module tag to specify settings such as the form to use and redirect options, as shown in the code example above. See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "default modules documentation"
      }), " for more information on available parameters."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cloning the default module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to using the default module as-is, you can clone it to make it editable, enabling you to customize it as needed. For example, you could clone the default form module, add a color field, then wrap the module's HTML in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), " tag with styling to add the color as a background:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar design manager, navigate to the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "@hubspot"
        }), " folder, then right click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "form.module"
        }), " and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Clone module"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/clone-form-module.png",
        alt: "clone-form-module"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add field"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Color"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<section>"
        }), " tag around the HTML content, then include styling to reference the color field, such as:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section style=\"background:{{ module.color_field.color }}\">"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/default-form-module-clone-section.png",
        alt: "default-form-module-clone-section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Form fields in custom modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating a custom module, you can include a form in it by adding a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#form",
        children: "form field"
      }), ", along with adding the field's code snippet to the module HTML. For example, you may want to add a consultation request form to a module that contains an image of a product and a descriptive value proposition."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add a form field to a custom module from the design manager:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right sidebar of the module editor, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add field"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Form"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-select-form-field.png",
        alt: "design-manager-select-form-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After adding the field, hover over the field in the right sidebar, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), " and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy snippet"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-copy-snippet.png",
        alt: "module-field-copy-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Paste the snippet into the module's HTML field."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-paste-snippet.png",
        alt: "module-field-paste-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limit form options in the editor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once added to a page, content creators typically have control over many aspects of the form, including which form to use and the form fields themselves. However, you can limit the amount of control given in the page editor by modifying the form module’s ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "locally"
      }), " to include the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Set the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            }), " property to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "to hide all inline form editing controls in the form module. This includes the form fields, submit button text, data privacy and consent options, and CAPTCHA."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required_property_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array that specifies which forms can be selected based on the property types of the form fields. Values include: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CONTACT\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"COMPANY\""
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TICKET\""
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you’ve built out a module that should only be used for forms that enable visitors to contact your company’s various services departments, you could allow content creators to only be able to select forms that use ticket properties."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Form field\n{\n  \"id\": \"843b4f0f-0ed7-5b10-e86a-5cc8a0f11a0c\",\n  \"name\": \"form_field_1\",\n  \"display_width\": null,\n  \"label\": \"Form\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"form\",\n  \"disable_inline_form_editing\": true,\n  \"required_property_types\": [\"TICKET\"],\n  \"default\": {\n    \"response_type\": \"inline\",\n    \"message\": \"Thanks for submitting the form.\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using the form embed code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a module or HubL tag isn't ideal, or if you want to add a form to an external page, you can instead embed it using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code",
        children: "form embed code"
      }), ". You can also customize the form embed code to extend the form's functionality. View all form embed code customization options and more in the forms reference guide."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Form customization options are only available for forms created in HubSpot that have been ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#with-css-in-your-external-stylesheet-marketing-hub-professional-enterprise-or-legacy-marketing-hub-basic-only",
            children: "set as raw HTML"
          }), ". The HubSpot account must have a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Content Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " subscription."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot forms should only be loaded using the HubSpot-hosted JavaScript file. Making a copy of the form embed code and self-hosting it is ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " supported. Any improvements that HubSpot makes to improve security, anti-spam, accessibility, and performance will not propagate to your form if you decide to self-host the HubSpot form embed code."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is a sample of a form embed code without any customization."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    region: 'na1',\n    portalId: '123456',\n    formId: 'df93f0c1-2919-44ef-9446-6a29f9a7f',\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number or string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot account that the form was created in. This is used to retrieve the form definition."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The form's ID, which is used to retrieve the form definition."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "region"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
              children: "region"
            }), " of the account where the form was created. This is used to retrieve the form definition. Possible values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "na1"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eu1"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add internationalization"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot provides a number of validation messages in many different languages, which you can access using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), " parameter. You can also add custom languages or override the error messages and date picker months/days displayed on the form using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translation"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about internationalization and form validation error messages in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "forms reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      fieldLabels: {\n        email: 'Electronic mail',\n      },\n      required: \"Hey! That's required!\",\n      submitText: 'Custom Submit Button Text',\n      submissionErrors: {\n        MISSING_REQUIRED_FIELDS: 'Please fill in all required fields!',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Form events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Forms allow two ways to bind functionality onto events: callbacks in the HubSpot form embed code and global form events. Learn more about the available form embed code callbacks and global form events in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "forms reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example form embed code callback\nhbspt.forms.create({\n  portalId: '',\n  formId: '',\n  onBeforeFormSubmit: function ($form) {\n    // YOUR SCRIPT HERE\n  },\n});\n\n// Example listener\nwindow.addEventListener('message', (event) => {\n  if (\n    event.data.type === 'hsFormCallback' &&\n    event.data.eventName === 'onFormSubmitted'\n  ) {\n    someAnalyticsLib('formSubmitted');\n    console.log('Form Submitted! Event data: ${event.data}');\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Form styling"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While HubSpot offers ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "form styling from a global setting"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "form specific setting level"
      }), ", you can also style a form depending on how it's added to your CMS pages."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " all forms generated on the HubSpot CMS (excluding using the form embed code) will ignore any styling that is configured via the global form settings or the form's individual settings."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styling forms via the default form module or HubL tag"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot forms added to HubSpot pages can be styled using your website's CSS. HubSpot includes a number of different classes and attributes on forms that are generated where you can apply styling. As a starting point, refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/elements/_forms.css",
        children: "HubSpot Boilerplate's form CSS"
      }), ", which represents the best practices for how to style forms."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styling forms via a custom module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Forms inside custom modules can be styled by CSS in the module's CSS pane within the design manager. By keeping CSS scoped to the module, you can ensure that whenever the module is added to a page, the styling comes with it. It's recommended to add a wrapper to the form, then using that wrapper as the top-level selector for the CSS. This will prevent your custom module's form styling from being overwritten by additional styles in your websites main stylesheet. Below is a screenshot of the custom module from above with form styling added to the CSS Pane."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/form-module-css-pane-4.jpg",
        alt: "Custom Module with CSS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styling forms added via the form embed code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When using the form embed code, you can style the form using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "global form styling settings"
      }), " or using your website's CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the global form styling settings enables you to configure default settings for every form in the account. You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "override these styles on an individual form within the form editor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/global-form-styling-options-4.png",
        alt: "Global Form Styles"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription, you can select the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Set as raw HTML form"
      }), " option when creating a form. This setting makes the form render as HTML instead of an iframe, which makes it easier to style the embedded form with CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site",
        children: "styling embedded forms on the Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/set-as-raw-html-form-4.jpg",
        alt: "Set as raw HTML form setting"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}