"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76685378224;
const slug = exports.slug = 'guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app';
const title = exports.title = 'Migrar una integración de clave API a una aplicación privada';
const name = exports.name = 'LATAM (ES) Migrate an API key integration to a private app';
const metaDescription = exports.metaDescription = 'Más información sobre cómo migrar una integración de clave API a una aplicación privada.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "en-esta-gu%C3%ADa",
  "label": "En esta guía",
  "parent": null
}, {
  "depth": 0,
  "id": "crea-una-nueva-aplicaci%C3%B3n-privada",
  "label": "Crea una nueva aplicación privada",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-el-m%C3%A9todo-de-autorizaci%C3%B3n-de-las-solicitudes-de-api-de-tu-integraci%C3%B3n",
  "label": "Actualizar el método de autorización de las solicitudes de API de tu integración",
  "parent": null
}, {
  "depth": 0,
  "id": "verificar-las-solicitudes-y-monitorizar-los-registros",
  "label": "Verificar las solicitudes y monitorizar los registros",
  "parent": null
}, {
  "depth": 0,
  "id": "ejemplos-de-implementaci%C3%B3n",
  "label": "Ejemplos de implementación",
  "parent": null
}, {
  "depth": 1,
  "id": "funciones-sin-servidor",
  "label": "Funciones sin servidor",
  "parent": "ejemplos-de-implementaci%C3%B3n"
}, {
  "depth": 1,
  "id": "trabajos-de-una-sola-vez",
  "label": "Trabajos de una sola vez",
  "parent": "ejemplos-de-implementaci%C3%B3n"
}, {
  "depth": 1,
  "id": "crear-objetos-personalizados",
  "label": "Crear objetos personalizados",
  "parent": "ejemplos-de-implementaci%C3%B3n"
}, {
  "depth": 1,
  "id": "acciones-de-workflow-de-c%C3%B3digo-personalizado",
  "label": "Acciones de workflow de código personalizado",
  "parent": "ejemplos-de-implementaci%C3%B3n"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      em: "em",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an API key integration to a private app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Si ves un banner en tu cuenta sobre cómo desactivar tu clave de API:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Asegúrate de haber migrado todas las integraciones afectadas y, a continuación, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
              children: "desactiva la clave de API"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Para comprobar si la clave de API de la cuenta se ha utilizado en los últimos siete días, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/integrations/how-do-i-get-my-hubspot-api-key#view-your-api-key-call-log",
              children: "ver el historial de llamadas de tu clave de API"
            }), ". El registro de llamadas no mostrará ninguna solicitud realizada con la clave hace más de siete días."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Las aplicaciones que aparecen en la página ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "https://knowledge.hubspot.com/es/integrations/connect-apps-to-hubspot#:~:text=You%20can%20view%20all%20your,Connected%20Apps%20page.",
              children: [(0, _jsxRuntime.jsx)(_components.em, {
                children: "Aplicaciones conectadas"
              }), " de la configuración de tu cuenta"]
            }), " no necesitan migrarse, ya que se autentican con OAuth."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Las claves API para desarrolladores son independientes de las claves de API estándar de HubSpot y ", (0, _jsxRuntime.jsx)("u", {
              children: "no"
            }), " están obsoletas. Las claves de la API de desarrollador se utilizan para administrar la configuración relacionada con tus aplicaciones de HubSpot, incluidas las suscripciones a la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "API de webhooks"
            }), " y los tipos de eventos de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/timeline",
              children: "API de eventos de línea de tiempo"
            }), "."]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si has creado una integración interna que utiliza una clave de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/integrations/how-do-i-get-my-hubspot-api-key",
        children: "API de HubSpot"
      }), ", tu clave de API proporciona acceso de lectura y escritura a todos tus datos de CRM, lo que puede ser un riesgo de seguridad si tu clave de API está comprometida. Al migrar a una aplicación privada, puedes autorizar los alcances específicos que requiere tu integración, lo que genera un token de acceso que limita los datos que tu integración puede solicitar o cambiar en tu cuenta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sigue los pasos a continuación para migrar una integración de clave de API existente a una aplicación privada. Se recomienda usar primero un entorno de prueba, como una cuenta de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "prueba de desarrollador o una cuenta"
      }), " de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/account/set-up-a-hubspot-sandbox-account",
        children: "entorno de pruebas"
      }), ", antes de realizar cambios en la producción. Si tienes preguntas durante la migración de tu aplicación, visita la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "Comunidad de desarrolladores"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para obtener una explicación del video de la migración de una integración de clave de API a una aplicación privada, echa un vistazo al video de Desarrolladores de HubSpot a continuación:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/F3Qd9Hp4giM?start=29&feature=oembed",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "En esta guía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-new-private-app",
          children: "Crea una nueva aplicación privada"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#update-the-authorization-method-of-your-integration-s-api-requests",
          children: "Actualizar el método de autorización de la solicitud de API de tu integración"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#verify-requests-and-monitor-logs",
          children: "Verificar las solicitudes y monitorizar los registros"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#implementation-examples",
          children: "Ejemplos de implementación"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " las aplicaciones privadas no admiten webhooks ni ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/overview",
          children: "ciertos tipos de extensiones"
        }), ". Si tu integración existente utiliza cualquiera de estas características, deberías crear una aplicación pública usando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), " en su lugar."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crea una nueva aplicación privada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ícono de configuración"
          }), " en la barra de navegación principal."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Integraciones"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Aplicaciones privadas"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Crear página privada"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la ficha ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Información básica"
          }), ", configura los detalles de tu aplicación:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Introduce el ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nombre"
            }), " de tu aplicación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Coloca el cursor sobre el logotipo del parámetro de sustitución y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "cargar icono"
            }), " para cargar una imagen cuadrada que funcionará como logotipo de tu aplicación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Introduce una ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descripción"
            }), " para tu aplicación."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Alcances"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "A continuación, selecciona los alcances que deseas autorizar en función de las API que utiliza tu integración. Para averiguar qué alcances necesitará tu aplicación:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Compila una lista de API de HubSpot que utiliza tu integración existente."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para cada solicitud de API, navega a la documentación de desarrollador asociada (por ejemplo, la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "API de contactos"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Puntos de terminación"
            }), " y desplázate hasta el punto de terminación que utiliza tu integración."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Requisitos"
            }), ", busca los alcances necesarios para usar el extremo. Siempre que sea posible, deberías optar por los alcances enumerados en ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Alcances granulares"
            }), " en lugar de los que figuran en ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Alcances estándar"
            }), ". Si no se enumeran alcances granulares, usa los alcances estándar."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/locate-scope-in-endpoints-tab-for-private-app-migration.png",
            alt: "locate-scope-in-endpoints-tab-for-private-app-migration"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["De nuevo en la configuración de tu aplicación privada, selecciona las casillas de comprobación ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Leer"
            }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Escribir"
            }), " junto a los alcances coincidentes. También puedes buscar un alcance usando la barra de búsqueda ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Buscar un alcance"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-matching-scope-for-private-app.png",
            alt: "select-matching-scope-for-private-app"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cuando hayas terminado de seleccionar tus alcances, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Crear aplicación"
          }), " en la parte superior derecha. Siempre puedes hacer cambios en tu aplicación después de crearla."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el cuadro de diálogo, revisa la información sobre el token de acceso de la aplicación y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Continuar creando"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con tu aplicación privada creada, puedes comenzar a hacer solicitudes de API usando su token de acceso. En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Detalles"
      }), " de la página de configuración de tu aplicación privada, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Mostrar token"
      }), " debajo de tu token de acceso para revelarlo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-migration-guide.png",
        alt: "show-private-app-access-token-migration-guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar el método de autorización de las solicitudes de API de tu integración"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En lugar de usar un parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapiKey"
      }), " para realizar solicitudes de API, los tokens de acceso a aplicaciones privadas se incluyen en el encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " de su solicitud. Al realizar una solicitud, establece el valor del encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Bearer YOUR_ACCESS_TOKEN"
      }), ". A menos que se indique lo contrario, este método de autorización es compatible con todos los puntos de terminación de API públicos, incluidas las API heredadas que se enumeran en la documentación de desarrollador ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "heredada de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tu solicitud puede parecerse a lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$headers = [\n    'Content-Type: application/json',\n    'Authorization: Bearer ' . YOUR_ACCESS_TOKEN,\n];\n\n$curl = curl_init();\n\ncurl_setopt($curl, CURLOPT_HTTPHEADER, $headers);\ncurl_setopt($curl, CURLOPT_URL, 'https://api.hubapi.com/crm/v3/objects/contacts');\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n$contacts = curl_exec($curl);\ncurl_close($curl);\n\nvar_dump($contacts);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "require 'uri'\nrequire 'net/http'\nrequire 'openssl'\n\nurl = URI(\"https://api.hubapi.com/crm/v3/objects/contacts\")\n\nhttp = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nhttp.verify_mode = OpenSSL::SSL::VERIFY_NONE\n\nrequest = Net::HTTP::Get.new(url)\nrequest['content-type'] = 'application/json'\ntoken = 'YOUR_ACCESS_TOKEN'\nrequest['authorization'] = \"Bearer #{token}\"\n\nresponse = http.request(request)\nputs response.read_body\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "import requests\n\nurl = \"https://api.hubapi.com/crm/v3/objects/contacts\"\n\nheaders = {\n  'content-type': 'application/json',\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\nresponse = requests.request(\"GET\", url, headers=headers)\n\nprint(response.text)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los tokens de acceso privado se implementan en la parte superior de OAuth, de manera que también puedas hacer llamadas autenticadas con tu token de acceso utilizando una de las bibliotecas de clientes de HubSpot. Por ejemplo, si estás utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "biblioteca de cliente Node.js"
      }), ", puedes crear instancias de un cliente de OAuth al pasar el token de acceso de tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$hubSpot = \\HubSpot\\Factory::createWithAccessToken('access-token');\n$response = $hubSpot->crm()->contacts()->basicApi()->getPage();\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# Load the gem\nrequire 'hubspot-api-client'\n\n# Setup client\nclient = Hubspot::Client.new(access_token: 'YOUR_ACCESS_TOKEN')\n\n# Get contacts\ncontacts = client.crm.contacts.basic_api.get_page\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "from hubspot import HubSpot\n\napi_client = HubSpot(access_token='YOUR_ACCESS_TOKEN')\napi_client.crm.contacts.get_page()\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para completar la migración a tu aplicación privada, elimina todas las referencias a la clave de API de HubSpot de tu código y, en su lugar, utiliza el enfoque anterior para usar el token de acceso de tu aplicación privada. Dependiendo de la solicitud que estés haciendo, es posible que desees crear un secreto para almacenar tu token, en lugar de codificarlo en tus solicitudes. Usar un secreto evitará que tu token sea expuesto, como cuando usas un token en una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "función sin servidor"
      }), ". Para almacenar el token de acceso como secreto:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la terminal, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add secretName"
        }), ". Se recomienda asignar un nombre simple al secreto para que puedas hacer referencia fácilmente a él en el futuro."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pega el token de acceso en el terminal y luego presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intro"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Luego puedes acceder a tu secreto como una variable de entorno. Más información en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "ejemplo de funciones sin servidor a continuación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para confirmar que se han eliminado todas las referencias a tu clave de API, puedes comprobar el registro de llamadas en tu cuenta de HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "símbolo de configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integraciones > Clave de API"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revisa las solicitudes más recientes en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Registro de llamadas"
        }), " para confirmar que no se han realizado solicitudes recientes desde que se eliminaron todas las referencias anteriores para usar el token de acceso de la aplicación privada."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/check-api-key-call-log-after-migration.png",
        alt: "check-api-key-call-log-after-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si tienes una cuenta de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), " de pago con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/contacts/marketing-contacts",
        children: "contactos de marketing"
      }), ", y previamente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/integrations/manage-marketing-contacts-settings-for-your-integrations#set-contacts-created-by-api-key-apps-as-marketing-contacts",
        children: "estableciste contactos creados por integraciones usando tu clave de API como contactos de marketing"
      }), ", también debes hacer lo mismo con tu aplicación privada:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícono de configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integraciones > Contactos de marketing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Tus aplicaciones conectadas"
        }), ", usa la barra de búsqueda para localizar tu aplicación privada y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activar para sincronizar contactos como contactos de marketing"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/set-private-app-contacts-as-marketing-contacts.png",
        alt: "set-private-app-contacts-as-marketing-contacts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayas terminado de configurar tu aplicación privada y confirmado que todas las referencias a tu clave de API se han eliminado en tu código, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
        children: "desactivar la clave"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verificar las solicitudes y monitorizar los registros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas eliminado todas las referencias a la clave API de HubSpot en tu código y las hayas reemplazado con referencias al token de acceso de tu aplicación privada, no se requieren más cambios de código."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si has seguido los pasos anteriores en una cuenta de prueba de desarrollador o sandbox, repite el mismo proceso en tu cuenta de producción. Luego, monitorea los registros de llamadas a la API de la aplicación privada y confirma que ninguna de las solicitudes de la aplicación devuelve ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), " errores:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícono de configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integraciones"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones privadas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de tu aplicación privada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Registros"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cualquier solicitud de API no satisfactoria, que falle debido a un alcance faltante, aparecerá como un error ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), ". Si accedes a los registros de tiempo de ejecución de tu integración, la respuesta de la solicitud de API correspondiente debe incluir un mensaje de error con detalles sobre los alcances que faltan."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/403-error-after-private-app-migration.png",
        alt: "403-error-after-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si necesitas incluir un nuevo alcance para tu aplicación privada:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Detalles"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Editar detalles"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En la parte superior de la página, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Alcances"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "casilla de comprobación"
            }), " junto a cualquier alcance que falte y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Confirmar cambios"
            }), " en la parte superior derecha cuando hayas terminado."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-missing-scopes-private-app-migration.png",
        alt: "select-missing-scopes-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre cómo crear y administrar aplicaciones privadas, junto con sus límites, en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "guía de aplicaciones privadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ejemplos de implementación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, descubre más información sobre los usos comunes de las claves de API y cómo migrar a tokens de acceso a aplicaciones privadas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si usas una clave de API dentro de una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "función sin servidor"
      }), ", también puedes usar el token de acceso de la aplicación privada para la autenticación. Deberás asegurarte de que la aplicación privada tenga los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/api/working-with-oauth#scopes",
        children: "alcances"
      }), " que la función necesita para ejecutarse."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para autenticar una función sin servidor con un token de acceso privado a la aplicación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la tarjeta de ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "token_de _acceso"
          }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mostrar token"
          }), " para mostrar tu token de acceso. A continuación, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar"
          }), " para copiar el token al portapapeles."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
            alt: "show-private-app-access-token-1"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Con el token de acceso copiado, crea un nuevo secreto para almacenar el token:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En la terminal, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs secrets add secretName"
            }), ". Se recomienda asignar un nombre simple al secreto para que puedas hacer referencia fácilmente a él en el futuro."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pega el token de acceso en el terminal y luego presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Intro"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "serverless.json"
          }), " de tu función sin servidor, agrega el nombre secreto a la matriz ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "secrets"
          }), ":"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example serverless.json file\n\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"getPrompts\": {\n      \"method\": \"GET\",\n      \"file\": \"serverlessFunction.js\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el archivo JavaScript de la función sin servidor, establece el valor del encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Authorization"
        }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bearer secretName"
        }), ". Por ejemplo, si estás llamando a la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "API de contactos"
        }), " usando Node.js y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", la solicitud será similar a lo siguiente:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example serverless function\nconst axios = require('axios');\n\nexports.main = (context, sendResponse) => {\n  axios.get(`https://api.hubapi.com/crm/v3/objects/contacts`, {\n    headers: {\n      Authorization: `Bearer ${process.env.secretName}`,\n      'Content-Type': 'application/json',\n    },\n  });\n  sendResponse({ statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/api/private-apps#make-api-calls-with-your-app-s-access-token",
        children: "hacer llamadas API con el token de tu aplicación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Trabajos de una sola vez"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si usas una clave de API para ejecutar trabajos únicos, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "crear una propiedad"
      }), ", puedes crear una aplicación privada y usar su token de acceso para autenticar la llamada. Una vez que se crea una aplicación privada, puedes reutilizar su token de acceso para cualquier trabajo único, siempre y cuando la aplicación privada tenga los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "alcances"
      }), " adecuados. Puedes actualizar los alcances de una aplicación privada en cualquier momento desde la configuración de la aplicación privada en HubSpot. O bien, puedes eliminar la aplicación privada y crear una nueva específica para el trabajo que necesitas ejecutar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/private-app-edit-scopes.png",
        alt: "private-app-edit-scopes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear objetos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En lugar de usar una clave API para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "crear un objeto personalizado"
      }), ", puedes crear una aplicación privada y usar su token de acceso para autenticar la llamada, siempre y cuando la aplicación tenga los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/api/working-with-oauth#scopes",
        children: "alcances"
      }), " necesarios. Por ejemplo, al usar Postman para crear un objeto personalizado, establece el tipo de autorización en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Token del portador"
      }), " y luego ingresa el token en el campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Token"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-private-app-access-token-field.png",
        alt: "postman-private-app-access-token-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre cómo crear un objeto personalizado usando una aplicación privada en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-a-custom-object-using-private-apps",
        children: "blog de desarrolladores de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Acciones de workflow de código personalizado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás usando una clave API en una ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/api/workflows/custom-code-actions#create-a-custom-code-action",
        children: ["acción de workflow de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "código personalizado"
        })]
      }), ", puedes usar el token de acceso de la aplicación privada, siempre que la aplicación tenga los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/api/working-with-oauth#scopes",
        children: "alcances"
      }), " necesarios. Para realizar la actualización, abre la acción personalizada en el editor de workflows y, a continuación, realiza las siguientes actualizaciones:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Primero, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/working-with-chatbots/code-snippets",
            children: "agrega un nuevo secreto"
          }), " que contenga el token de acceso privado a la aplicación."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/workflow-action-add-secret.png",
            alt: "workflow-action-add-secret"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Luego ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/es/docs/api/workflows/custom-code-actions#secret",
            children: "actualiza el código de acción"
          }), " con el nuevo secreto."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({\n  accessToken: process.env.secretName,\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}