'use es6';

import { VariableIndexStorage } from './VariableIndexStorage.js';
import parse from './parse.js';
import { formatArgumentKey, formatVariableName, formatImportsPaths, formatObjectAssignments } from './formatters.js';
import { toCamelCase, toSnakeCase } from '../../common/stringutils';
const getAuthParam = ({
  authTypes,
  apiKey,
  oAuthToken
}) => {
  if (oAuthToken) {
    return `AccessToken('${oAuthToken}')`;
  } else if (apiKey) {
    if (authTypes.includes('developer_hapikey')) {
      return `DeveloperApiKey('${apiKey}')`;
    }
    return `ApiKey('${apiKey}')`;
  } else {
    if (authTypes.includes('oauth2') || authTypes.includes('oauth2_legacy') || authTypes.includes('private_apps') || authTypes.includes('private_apps_legacy')) {
      return `AccessToken('YOUR_ACCESS_TOKEN')`;
    } else if (authTypes.includes('developer_hapikey')) {
      return `DeveloperApiKey('YOUR_DEVELOPER_API_KEY')`;
    } else {
      return `ApiKey('YOUR_API_KEY')`;
    }
  }
};
export default function generate(openApiSpecification, operationId, formData, additionalParams) {
  const {
    sdkCall,
    imports,
    assignments
  } = parse(openApiSpecification, operationId, formData);
  const authParam = getAuthParam(Object.assign({}, sdkCall, additionalParams));
  const lines = [''];
  lines.push(`$client = Factory::createWith${authParam};`);
  lines.push('');
  const variableIndexStorage = new VariableIndexStorage();
  for (const assignment of assignments) {
    const objectAssignments = formatObjectAssignments(assignment, variableIndexStorage);
    let line = `${formatVariableName(assignment.variable)} = new ${assignment.model}([`;
    for (const objectAssignment of objectAssignments) {
      objectAssignment.imports.forEach(object => imports.add(object));
      lines.push(...objectAssignment.lines);
      line += `\n    '${formatArgumentKey(objectAssignment.name)}' => ${objectAssignment.getVariable()},`;
    }
    line += '\n]);';
    lines.push(line);
  }
  const assignmentsSdkCall = formatObjectAssignments(sdkCall, variableIndexStorage);
  for (const assignmentSdkCall of assignmentsSdkCall) {
    assignmentSdkCall.imports.forEach(object => imports.add(object));
    lines.push(...assignmentSdkCall.lines);
  }
  const sdkMethodPath = [...sdkCall.path, sdkCall.apiName, sdkCall.methodName];
  let sdkCallLine = `$apiResponse = $client->${sdkMethodPath.map(p => toCamelCase(p)).join('()->')}(`;
  sdkCallLine += assignmentsSdkCall.map(assignment => assignment.getVariable()).join(', ');
  sdkCallLine += ');';
  lines.push('try {');
  lines.push(`    ${sdkCallLine}`);
  lines.push('    var_dump($apiResponse);');
  lines.push('} catch (ApiException $e) {');
  lines.push(`    echo "Exception when calling ${toSnakeCase(sdkCall.apiName)}->${toSnakeCase(sdkCall.methodName)}: ", $e->getMessage();`);
  lines.push('}');
  const importLines = [];
  importLines.push(['<?php']);
  const importPaths = formatImportsPaths({
    imports,
    sdkCall
  });
  importLines.push(...importPaths.map(p => `use ${p.join('\\')};`));
  importLines.push('');
  return importLines.join('\n') + lines.join('\n');
}