"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310856;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions';
const title = exports.title = 'サーバーレス関数の使い方';
const name = exports.name = 'APAC JAPAN (JA) | Getting started with serverless functions | 202108';
const metaDescription = exports.metaDescription = 'HubSpotのCMS上で初めてのサーバーレス関数を作成し、レスポンスを受け取ります。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "1. プロジェクトフォルダーを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "2. 関数フォルダーを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%E8%A8%AD%E5%AE%9A%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "3. 設定ファイルを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "4. 関数ファイルを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-serverless.json%E5%86%85%E3%81%AB%E9%96%A2%E6%95%B0%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B",
  "label": "5. serverless.json内に関数を登録する",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-%E9%96%A2%E6%95%B0%E3%82%92%E3%83%86%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B",
  "label": "6. 関数をテストする",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-%E9%96%A2%E6%95%B0%E3%82%92%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%99%E5%9F%BA%E6%9C%AC%E7%9A%84%E3%81%AA%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "7. 関数を呼び出す基本的なテンプレートを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-test-function.html%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "8. test-function.htmlテンプレートを使用してページを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%81%AE%E3%83%87%E3%83%90%E3%83%83%E3%82%B0",
  "label": "サーバーレス関数のデバッグ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%93%E3%81%93%E3%81%BE%E3%81%A7%E3%81%AB%E5%AE%9F%E8%A1%8C%E3%81%97%E3%81%9F%E3%81%93%E3%81%A8",
  "label": "ここまでに実行したこと",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%AC%A1%E3%81%AE%E3%82%B9%E3%83%86%E3%83%83%E3%83%97",
  "label": "次のステップ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "サーバーレス関数の使い方"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "開発者プロジェクト"
        }), "の一部としてサーバーレス関数を作成する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "開発者プロジェクトのサーバーレス関数のドキュメント"
        }), "を参照してください。以下のドキュメントは、開発者プロジェクトプラットフォーム以外でサーバーレス関数を作成する場合に使用します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数を使用すると、HubSpotやサードパーティーのサービスを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "API"
      }), "経由で操作できるサーバーサイドコードを作成できます。認証が必要なAPIでは資格情報が表示されるため、ウェブサイトのフロントエンドでの使用には適していません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数は、認証を必要とするフロントエンドサービスとバックエンドサービスの間で仲介処理として機能します。サーバーレス関数を使用する場合、開発者が新たにサーバーを構築、管理する必要がありません。サーバーレス関数はオーバーヘッド（間接的な負荷）が小さいため、ビジネスの成長に合わせて拡大することも容易です。このチュートリアルを実施する前に、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions",
        children: "HubSpotのサーバーレス関数とその概要"
      }), "を参照しておいてください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "このチュートリアルでは、初めてサーバーレス関数を作成する際の流れを説明します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーレス関数フォルダーの作成から、設定フォルダーの設定、関数の作成、レスポンスの取得までを行います。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "このチュートリアルの前に必要な作業"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["CMS Hub Enterpriseアカウントまたは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/standalone-cms-developer",
          children: "CMS開発者用サンドボックスアカウント"
        }), "へのアクセス。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions",
          children: "サーバーレス関数の概念"
        }), "に関する、少なくとも基本的な理解。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サーバーレス関数との連携に必要な", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/guides/getting-started-with-local-development",
          children: "HubSpotローカル開発ツール"
        }), "の使用経験。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#install-or-upgrade",
          children: "HubSpotローカル開発ツールの最新バージョン"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cms-cli#auth",
          children: "自分のHubSpotアカウントによって認証されたローカル開発ツール"
        }), "。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. プロジェクトフォルダーを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "ファイルを開き、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultPortal"
        })
      }), "が", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "CMS開発者用サンドボックス"
      }), "アカウントか、CMS Hub Enterpriseのアカウントのいずれかに設定されていることを確かめます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["自分のコンピューターの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        })
      }), "ファイルが含まれるフォルダーの中に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), "**フォルダーを作成します。**このフォルダーに、関数自体とその関数を使用するテンプレートの両方を含む全てのファイルを格納します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ターミナルで、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://developers.hubspot.jp/docs/cms/developer-reference/local-development-cms-cli#watch",
        children: "watchコマンド"
      }), "を実行します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch serverless-tutorial serverless-tutorial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以降、このフォルダーで何らかの編集を行うと、デザインマネージャーにアップロードされます。現在このフォルダーには中身がないため、このコマンドは単に、Watcherの準備ができていて監視中であることを示すだけです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. 関数フォルダーを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), "フォルダーの内部に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "フォルダーを作成します。"
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "末尾が", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), "のカスタム モジュール フォルダーと同様に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "はフォルダーにサーバーレス関数が含まれることを示します。このフォルダーに格納されているファイルは公開されません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このフォルダーは現在空なので、実行中の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), "コマンドでは、デザインマネージャーの中にこのフォルダーがまだ作成されません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. 設定ファイルを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "フォルダーに**"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "という名前の新しいファイルを作成します。**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "は", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "フォルダー内の必須ファイルです。これはサーバーレス関数の設定ファイルとして機能します。ランタイム環境、サーバーレス関数のバージョン、利用可能なエンドポイントを定義します。このファイルの詳細な定義については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions/reference",
        children: "サーバーレスリファレンス"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["空のファイルを作成して保存した場合、空の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "ファイルはアップロードできないというエラーメッセージがターミナルに表示されます。これは無視しても問題ありません。以下のコードを追加して保存すれば、新たなアップロードが起動されて正常に完了するためです。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "以下のコードをserverless.jsonに貼り付けます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// place this in your serverless.json file, without this comment\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"endpoints\": {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ファイルを保存します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このファイルはコードエディター上に開いたままにします。後で再度使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), "を実行中なので、デザインマネージャーを再読み込みすると、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), "の各フォルダーと、新しい", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "ファイルが表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. 関数ファイルを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "my-first-function.functions**"
      }), "フォルダーに*", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "*congratulation.jsという名前の新しいファイルを作成します。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これが、タスクを処理する実行可能な関数ファイルの本体です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "以下のコードを貼り付けます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n  const functionResponse =\n    \"Congrats! You've just deployed a Serverless Function.\";\n\n  // sendResponse is a callback function you call to send your response.\n  sendResponse({ body: functionResponse, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このサーバーレス関数を実行すると、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats!You just deployed a Serverless Function.\""
      }), "（サーバーレス関数がデプロイされました）という文字列と、成功を示すステータスコード", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "が返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "実際には、APIを使用したり、非公開の計算を実行したりするケースが考えられます。こうした計算結果を返すか、APIトランザクションの成否に基づくシンプルなステータスコードを返すことになります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "まだ終了ではありません。この関数を実行する方法がまだありません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. serverless.json内に関数を登録する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["もう一度", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "ファイルを開きます。このファイル内の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endpoints"
      }), "オブジェクトを検索します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このオブジェクトを次のように変更します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// update the endpoints object in your serverless.json to reflect this object.\n\"endpoints\": {\n   \"congratulation\": {\n     \"method\": \"GET\",\n     \"file\": \"congratulation.js\"\n   }\n }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["endointsオブジェクトには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation"
      }), "オブジェクトが含まれています。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation"
      }), "が、今作成しているエンドポイントです。サーバーレス関数を呼び出すために使用するパスは、エンドポイントの名前によって定義されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数は、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubSpot CMSアカウントのドメインのパス"
      }), "を通して公開されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この関数には、次のようにアクセスできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/<endpoint-name/path>"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "今回作成したcongratulationエンドポイントの場合は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/congratulation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このため、エンドポイントには通常、関数ファイルの名前と同じように名前を付けることが適切です。両方に、その情報に対して実行される処理ではなく、処理の対象となる情報に基づいて名前を付けるようにしてください。その情報に対して実行する処理タイプを伝送する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Methods",
        children: "HTTPメソッド"
      }), "を使用してください。関数によってサポートするHTTPメソッドは、methodパラメーターを使用して定義します。関数によってサポートされるメソッドを示す単一の文字列か、文字列の配列です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. 関数をテストする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数に対する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストをテストする最も簡単な方法は、ブラウザーで直接エンドポイントのURLにアクセスすることです。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://your-domain.com/_hcms/api/congratulation",
        children: "https://your-domain.com/_hcms/api/congratulation"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "your-domain.comは、実際のHubSpotサイトのドメインで置き換えます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次のように表示されます：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats!You just deployed a serverless function\""
      }), "（サーバーレス関数がデプロイされました）。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "正常に完了しました。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このレスポンスがない場合は、チュートリアルの最初から各ステップを確かめるとともに、コードを確認してください。ステップ4または5の手順が正しく行われなかったことが考えられます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["複雑なリクエストの場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.postman.com/downloads/",
          children: "Postman"
        }), "などのツールを使用すると役に立ちます。Postmanを利用すると、APIのテストとデバッグが簡単になります。フロントエンド開発者にとっては、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://learning.postman.com/docs/postman/sending-api-requests/generate-code-snippets/",
          children: "コード生成"
        }), "が便利で、JavaScriptからの関数呼び出しの出発点として利用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. 関数を呼び出す基本的なテンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しいターミナルウィンドウを開き、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd"
      }), "を使用して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), "フォルダーに切り替えます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ターミナルで次のコマンドを実行します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template \"test-function\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これにより、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), "ファイルが作成されます。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "コードエディターでこのファイルを開きます。"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "</head>"
      }), "タグの上に**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<script></script>"
      }), "を追加します。**"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "以下のJavaScriptをコピーします。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var requestOptions = {\n  method: 'GET',\n  headers: {\n    'Content-Type': 'application/json',\n  },\n};\n\nfetch('https://www.example.com/_hcms/api/congratulation', requestOptions)\n  .then((response) => response.text())\n  .then((result) => console.log(result))\n  .catch((error) => console.log('error', error));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpotサーバーレス関数でサポートされるコンテンツタイプは、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), "のみです。これを省略すると、「サポートされていないメディアタイプ」のエラーになります。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["前の手順で作成したスクリプトタグ内に、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "コピーしたJavaScriptコードを貼り付けます"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "www.example.com"
        }), "を実際のアカウントのドメインに変更します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ファイルを保存します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. test-function.htmlテンプレートを使用してページを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["デザインマネージャーで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), "ファイルを見つけます（場合によっては再読み込みが必要）。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルを右クリックし、［ページを作成］を選択します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページに「Test Function」と名前を付けます。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ページを作成します"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "プレビューをクリックし、新しいウィンドウでプレビューします"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "上で右クリック"
      }), "してから、［検査］を選択することによって、ページの検査を実行します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "全ての手順を正しく行った場合は、正常にメッセージが表示されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Congratulations, you've called your serverless function from within a HubSpot CMS page."
      }), "（HubSpotのCMSページ内からサーバーレス関数が呼び出されました。）"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "このチュートリアルでは、テンプレート上でサーバーレス関数を呼び出していますが、サーバーレス関数は、CMS上のサイトでJavaScriptを追加できるどのような場所からでも呼び出すことが可能です。最も一般的な場所は、カスタムモジュール内です。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サーバーレス関数のデバッグ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この段階で、サーバーレス関数が正常に動作しているはずです。関数が複雑化すると、トラブルシューティングは困難になります。ブラウザー内のコンソールがフロントエンドでのJavaScriptのデバッグに役立つように、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hsログ"
        })
      }), "ではサーバーレス関数に関する同様のログを取得できます。このコマンドの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://developers.hubspot.jp/docs/cms/developer-reference/local-development-cms-cli#logs",
        children: "ローカル開発のリファレンス"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs logs <endpoint-name> --follow\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ここまでに実行したこと"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数フォルダーとserverless.jsonの設定ファイル、さらに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation.js"
      }), "という名前の関数ファイルを作成しました。GETを使用して、サーバーレス関数から正常な呼び出し完了のテキストを取得しました。JavaScriptを使用することにより、HubSpotのCMSからサーバーレス関数を呼び出しました。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["設定ファイル、関数ファイル、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "フォルダーの関係性を理解できたので、以降は便利なCLIを使用して、短時間で関数を作成できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create function <function name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この関数では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "フォルダー、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "ファイルおよび指定した名前の関数ファイルが作成されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "次のステップ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot CMSの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://developers.hubspot.jp/docs/cms/features/serverless-functions",
          children: "サーバーレス関数の概要"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サーバーレス関数のさまざまな部分に関する", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions/reference",
          children: "サーバーレスリファレンス"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/introduction-to-hubspot-apis",
          children: "HubSpot APIの概要に関するアカデミーコース（英語）"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "GitHubでのCI（継続的インテグレーション）"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}