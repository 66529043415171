"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529779;
const slug = exports.slug = 'guides/cms/improve-older-sites/add-theme-features-to-existing-sites';
const title = exports.title = 'Como adicionar recursos de tema a um site existente do HubSpot CMS';
const name = exports.name = 'LATAM BR (PT) [NEW] Add theme capabilities to an existing HubSpot CMS website';
const metaDescription = exports.metaDescription = 'Os recursos do tema podem ser adicionados de forma complementar a sites mais antigos. Pense no futuro do site e em como esses recursos podem melhorá-lo.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tema",
  "label": "Tema",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-colocar-os-arquivos-existentes-em-uma-pasta",
  "label": "1. Colocar os arquivos existentes em uma pasta",
  "parent": "tema"
}, {
  "depth": 1,
  "id": "2.-tornar-uma-pasta-um-tema",
  "label": "2. Tornar uma pasta um tema",
  "parent": "tema"
}, {
  "depth": 1,
  "id": "3.-adicionar-campos-de-tema",
  "label": "3. Adicionar campos de tema",
  "parent": "tema"
}, {
  "depth": 0,
  "id": "clonar-%C3%A1reas-de-arrastar-e-soltar-como-html-no-gerenciador-de-design",
  "label": "Clonar áreas de arrastar e soltar como HTML no gerenciador de design",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A1reas-de-arrastar-e-soltar",
  "label": "Áreas de arrastar e soltar",
  "parent": null
}, {
  "depth": 2,
  "id": "faz-sentido-converter-todas-as-p%C3%A1ginas-existentes-para-usar-%C3%A1reas-de-arrastar-e-soltar%3F",
  "label": "Faz sentido converter todas as páginas existentes para usar áreas de arrastar e soltar?",
  "parent": "%C3%A1reas-de-arrastar-e-soltar"
}, {
  "depth": 1,
  "id": "converter-modelos-existentes",
  "label": "Converter modelos existentes",
  "parent": "%C3%A1reas-de-arrastar-e-soltar"
}, {
  "depth": 1,
  "id": "como-fa%C3%A7o-para-migrar-p%C3%A1ginas-existentes%3F",
  "label": "Como faço para migrar páginas existentes?",
  "parent": "%C3%A1reas-de-arrastar-e-soltar"
}, {
  "depth": 0,
  "id": "parciais-globais",
  "label": "Parciais globais",
  "parent": null
}, {
  "depth": 0,
  "id": "alertas-codificados",
  "label": "Alertas codificados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      code: "code",
      img: "img",
      h4: "h4",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Como adicionar recursos de tema a um site existente do HubSpot CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um dos benefícios do HubSpot é que você não precisa atualizá-lo constantemente. O CMS Hub sempre usa a versão mais recente. Os novos recursos introduzidos com o CMS Hub são complementares. Portanto, os modelos, módulos etc. para os sites existentes do HubSpot CMS funcionam exatamente como foram criados para funcionar. Eles são ainda melhores, pois nos bastidores o CMS está sempre ficando melhor, mais rápido e mais fácil de editar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem alguns recursos específicos do CMS Hub que um desenvolvedor precisa adicionar. Você pode implementar alguns desses recursos de forma diferente do que faria para um site novo. Este tutorial vai orientá-lo pela adição desses recursos ao(s) seu(s) site(s) existente(s)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de continuar:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revise os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "principais conceitos do CMS Hub"
        }), ", mesmo que você já esteja acostumado a desenvolver no HubSpot. Você entenderá melhor como todas as peças se encaixam."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você também precisará usar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CLI do CMS"
        }), "; portanto, se ela não estiver instalada, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "configure-a primeiro"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode adicionar e usar esses recursos de forma independente. Comece pelo cabeçalho dos recursos específicos de que precisa. Você não precisa seguir este tutorial de forma completamente linear."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " do CMS Hub são um pacote de modelos, módulos, arquivos CSS, JSON e JS. Como os temas são um pacote, eles afetam toda a experiência do editor de conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao criar um novo site ou landing page, o criador de conteúdo recebe uma grade dos temas em sua conta. Selecione o tema que está usando e será mostrada uma grade com apenas os modelos desse tema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao editar uma página usando um modelo de um tema, os módulos desse tema receberão tratamento especial, destacando-os no painel Adicionar módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Colocar os arquivos existentes em uma pasta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se todos os seus modelos, módulos, arquivos CSS e JS estiverem na mesma pasta, eles serão considerados como parte de um tema. Não importa se eles estão organizados em subpastas; importa apenas que esses ativos estejam contidos em uma pasta. Se eles já estiverem armazenados dessa maneira, ótimo; caso contrário, crie uma pasta e mova seus ativos para ela. Dê a essa pasta o nome que deseja dar ao seu tema. O nome depende totalmente de você, mas talvez nomeá-lo com base na marca da empresa que o site reflete, juntamente com o ano da reformulação ou atualização."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Tornar uma pasta um tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os temas exigem dois arquivos JSON, e apenas um deles precisa de conteúdo real inicialmente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Theme.json"
        }), " - contém as configurações do seu tema."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fields.json"
        }), " - contém campos que podem ser usados para fazer alterações de design em todo o site."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os arquivos JSON são especiais e não podem ser criados no momento no gerenciador de design. Esses arquivos PODEM ser carregados por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Busque a pasta que você criou na etapa \"colocar os arquivos existentes em uma pasta\" no seu computador."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dentro da pasta no seu computador, crie um novo arquivo com o nome ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copie o ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/cms/content/themes/overview#theme-json",
          children: ["exemplo de arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.json"
          }), " no documento de temas"]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cole o código de exemplo no seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Altere o valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"label\""
        }), " para o nome do tema, como você deseja que os criadores de conteúdo o vejam."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Altere ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"preview_path\""
        }), " para o caminho do modelo da página inicial ou do modelo usado com mais frequência."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Faça uma captura de tela da página inicial do site. Nomeie a imagem como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.jpg"
        }), ". Coloque a imagem dentro da mesma pasta do seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Altere ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"screenshot_path\""
        }), " em theme.json para o caminho da sua imagem ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.png"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Salve o arquivo."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dentro da mesma pasta que seu ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), ", crie um novo arquivo com o nome fields.json. Dentro desse arquivo, digite apenas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[]"
        }), " e salve o arquivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver as alterações no gerenciador de design, execute o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ". Agora você tem um tema básico. Os módulos e modelos dentro desse tema serão exibidos associados ao tema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Adicionar campos de tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os campos de tema são controles que você pode fornecer a um criador de conteúdo para permitir que ele faça alterações de estilo em todo o tema."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Durante a criação inicial de um site, esses controles de tema permitem que os criadores de conteúdo se envolvam, ajudando a definir a marca em todo o site. As equipes podem achar que isso libera o desenvolvedor para se concentrar nos aspectos mais técnicos do desenvolvimento do site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para sites existentes, os campos de tema podem ser desnecessários. Afinal, se o site já foi criado de forma personalizada, provavelmente não há propósito em adicionar controles de cor e tipografia em todo o site. Se a marca de um site estiver passando por uma mudança significativa, pode ser mais apropriado criar um novo design do que adicionar campos retroativamente. Em última análise, esta é uma decisão que você deve tomar mutuamente com as outras partes interessadas envolvidas no site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para adicionar campos ao tema, adicione o JSON deles ao seu arquivo fields.json. Os campos seguem a mesma estrutura que os campos do módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Certifique-se de que os usuários que devem ter acesso para alterar os valores do campo do tema tenham a permissão \"Editar temas e conteúdo global\". Certifique-se de que os usuários que não devem editar essas configurações não tenham essa opção ativada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "Captura de tela das configurações de conteúdo global e temas nas permissões do usuário"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clonar áreas de arrastar e soltar como HTML no gerenciador de design"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As áreas de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "arrastar e soltar"
      }), " e as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "parciais globais"
      }), " exigem o uso de arquivos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL codificados"
      }), ". Se o seu site existente puder ser criado usando o antigo sistema de gerenciamento de design de arrastar e soltar e você quiser usar esses novos recursos, esses modelos podem precisar ser clonados como HTML."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para clonar esses modelos como modelos HTML + HubL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abra o gerenciador de design e encontre o modelo na busca."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Clique com o botão direito no modelo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No menu de contexto que aparece, escolha \"Clonar como HTML\""
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agora você está pronto para trabalhar na adição das áreas de arrastar e soltar e parciais globais."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Áreas de arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As áreas de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "arrastar e soltar"
      }), " podem ser adicionadas aos modelos para fornecer aos criadores de conteúdo uma maneira de incluir módulos horizontal e verticalmente em uma página. As áreas de arrastar e soltar também fornecem controle de estilo adicional para o criador de conteúdo. Como um modelo com uma área de arrastar e soltar pode ser usado para criar muitos layouts diferentes, isso libera o desenvolvedor para se concentrar nos aspectos técnicos da criação e atualização do site."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As áreas de arrastar e soltar são um novo recurso e não são idênticas aos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "modelos de arrastar e soltar do Gerenciador de design"
        }), ". Também não são suportadas nos modelos de arrastar e soltar do Gerenciador de design. Para adicioná-las a modelos criados anteriormente usando o construtor de modelos de arrastar e soltar, consulte clonar áreas de arrastar e soltar como HTML no gerenciador de design."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Faz sentido converter todas as páginas existentes para usar áreas de arrastar e soltar?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso depende se a página está atendendo aos seus objetivos de negócios. Em outras palavras, se algo está funcionando bem, não há necessidade de mexer. Se o layout da página precisar ser alterado, então sim, provavelmente é aconselhável converter a página para usar as áreas de arrastar e soltar. Se a página está cumprindo seu propósito e não precisa de alterações de layout, provavelmente está tudo certo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Converter modelos existentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["É relativamente fácil criar um clone de um modelo antigo e alterar a coluna flexível para uma área de arrastar e soltar. Essa é uma boa ideia, pois abre muitas possibilidades para os criadores de conteúdo. Isso dá aos criadores de conteúdo muito controle criativo para novas páginas. Se o seu modelo for um modelo de arrastar e soltar do gerenciador de design, veja ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#clone-design-manager-drag-and-drop-as-html",
        children: "clonar áreas de arrastar e soltar como HTML no gerenciador de design"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A solução mais fácil é encontrar as instâncias de colunas flexíveis, que usam a tag HubL ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% widget_container \"my_unique_column_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Substitua cada instância conforme necessário por ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% dnd_area \"my_unique_area_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se não quiser definir os módulos padrão, deixe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " em branco. Se você quiser transferir os módulos padrão para a região ou definir novos padrões úteis, dentro de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ", adicione uma seção ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_"
        }), " e, dentro dela, uma ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para cada tag de módulo dentro do ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_container"
        }), " antigo, você desejará criar uma ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), " contendo um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " com um caminho correspondente aos módulos que deseja exibir por padrão. Isso criará o mesmo layout vertical que a coluna flexível tinha."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Como faço para migrar páginas existentes?"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Se estiver alterando modelos construídos com colunas flexíveis para usar áreas de arrastar e soltar, há algumas coisas que você deve saber. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "As colunas flexíveis não são como as áreas de arrastar e soltar. Você não pode trocar um modelo que só tem uma coluna flexível por outro que só tem uma área de arrastar e soltar"
        }), ". Não permitimos isso por precaução. O conteúdo não seria mapeado da coluna flexível para a área de arrastar e soltar. Para ilustrar o porquê disso, suponha que você criou um novo modelo para exibir uma barra lateral e uma área de conteúdo principal. A barra lateral é uma coluna flexível; o conteúdo principal é uma área de arrastar e soltar. A ferramenta de troca mapearia a coluna flexível para a coluna flexível."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você quiser converter uma página para usar áreas de arrastar e soltar, a maneira mais segura de fazer isso é usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "preparação de conteúdo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/content/staging/domain",
          children: "Abra a ferramenta de preparação de conteúdo"
        }), ", encontre a página existente e teste-a. Escolha \"Preparar página em branco\"."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Selecione o novo modelo que usa a área de arrastar e soltar."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copie e cole as informações da versão original da página, criando o layout desejado."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Quando terminar, publique em preparação."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Na ferramenta de preparação de conteúdo, navegue até a guia \"Publicar\". Selecione a página e publique em preparação."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parciais globais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Parciais globais"
      }), " são partes de conteúdo HTML e HubL que podem ser reutilizadas em todo o site. Os tipos mais comuns de parciais globais são cabeçalhos de sites, barras laterais e rodapés. Para criadores de conteúdo, as parciais globais se manifestam no editor de conteúdo como regiões que eles podem selecionar para editar no editor de conteúdo global. O editor de conteúdo global se parece com o editor de página, mas é usado para editar conteúdo global."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ilustrar, você pode implementar o cabeçalho como uma parcial global com módulos de navegação e outros conteúdos. Para o criador de conteúdo, se precisar adicionar um link à navegação, ele pode clicar no cabeçalho no editor de páginas, clicar no módulo de menu e atualizar o menu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certifique-se de que os usuários que ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "devem"
      }), " ter acesso para editar conteúdo global tenham a permissão \"Editar temas e conteúdo global\"."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "configuração global de conteúdo e tema nas permissões do usuário"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alertas codificados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para contas no CMS Hub Enterprise, os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "Alertas de código"
      }), " fornecem um local centralizado para desenvolvedores e gerentes de TI terem uma visão geral dos problemas que afetam o desempenho e a renderização de páginas e ativos na conta."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Já que você está otimizando ativamente o site para aproveitar as novas funcionalidades. É uma ótima ideia revisar os alertas de código da sua conta e resolver os existentes."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}