"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921780;
const slug = exports.slug = 'guides/api/marketing/subscriptions-preferences';
const title = exports.title = 'Marketing API | Subscriptions Preferences';
const name = exports.name = 'vNext Docs DP - Subscriptions Preferences';
const metaDescription = exports.metaDescription = ' The subscription preferences endpoints allow you to manage subscriptions details for contacts in a HubSpot portal';
const toc = exports.toc = [{
  "depth": 0,
  "id": "get-contact-subscription-status",
  "label": "Get contact subscription status",
  "parent": null
}, {
  "depth": 0,
  "id": "subscribe-contact",
  "label": "Subscribe contact",
  "parent": null
}, {
  "depth": 0,
  "id": "unsubscribe-contact",
  "label": "Unsubscribe contact",
  "parent": null
}, {
  "depth": 0,
  "id": "get-subscription-types",
  "label": "Get subscription types",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Subscription preferences"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Subscriptions types represent the legal basis to communicate with your contacts through email. Contacts can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/email/how-to-send-contacts-an-email-with-an-opt-out-link-so-they-can-manage-their-preferences",
        children: "manage their email preferences"
      }), " so that they're only opted into the emails they want to receive."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the subscription preferences API does ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " currently support retrieving or updating ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/inbox/collect-consent-for-whatsapp-conversations",
          children: "WhatsApp"
        }), " subscription information for a contact."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get contact subscription status"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The contact subscription status endpoint allows users to retrieve the subscription statuses for an email address in an account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This endpoint is ideal for when you have an external preferences center or integration and need to know the subscription statuses for any given email address in your account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Subscribe contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The subscribe contact endpoint allows you to subscribe an email address to any given subscription type in an account, but ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "will not allow you to resubscribe contacts who have opted out."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " This endpoint is ideal for when you have an integration or external form that needs to opt contacts into a subscription type."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Note"
      }), ": The subscribe contact endpoint should only be used to honor requests from contacts who have given you permission to subscribe them. Please ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-do-subscription-preferences-and-types-work",
        children: "review applicable laws and regulations"
      }), " before subscribing a contact."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unsubscribe contact"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The unsubscribe contact endpoint allows allows you to unsubscribe an email address in an account from any given subscription type."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " This endpoint is ideal for when you have an integration or external form that needs to opt contacts out of a subscription type."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get subscription types"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The subscription info endpoint allows users to retrieve all subscription types in their account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " This endpoint is ideal for when you have an external preferences center, integration, or form and need to know which subscription types exist in your account so you can update the subscription statuses for your contacts accordingly."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {})]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}