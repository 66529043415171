import { SET_DOCS_VERSION, SET_HAS_INITIALIZED_DOCS_VERSION } from '../actions/ActionTypes';
export const DEFAULT_USER_STATE = {
  docsVersion: '2020-10',
  hasInitializedDocVersion: false
};
const userState = (state = DEFAULT_USER_STATE, action) => {
  switch (action.type) {
    case SET_DOCS_VERSION:
      return Object.assign({}, state, {
        docsVersion: action.value
      });
    case SET_HAS_INITIALIZED_DOCS_VERSION:
      return Object.assign({}, state, {
        hasInitializedDocVersion: action.value
      });
    default:
      return state;
  }
};
export default userState;