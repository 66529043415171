"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044625;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions';
const title = exports.title = 'Introdução às funções sem servidor';
const name = exports.name = 'LATAM BR (PT) Getting started with serverless functions';
const metaDescription = exports.metaDescription = 'Crie sua primeira função sem servidor no HubSpot CMS e obtenha uma resposta.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-criar-uma-pasta-de-projeto",
  "label": "1. Criar uma pasta de projeto",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-crie-uma-pasta-de-fun%C3%A7%C3%B5es",
  "label": "2. Crie uma pasta de funções",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-crie-um-arquivo-de-configura%C3%A7%C3%A3o",
  "label": "3. Crie um arquivo de configuração",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-crie-um-arquivo-de-fun%C3%A7%C3%A3o",
  "label": "4. Crie um arquivo de função",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-registre-sua-fun%C3%A7%C3%A3o-em-serverless.json",
  "label": "5. Registre sua função em serverless.json",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-teste-sua-fun%C3%A7%C3%A3o",
  "label": "6. Teste sua função",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-crie-um-modelo-b%C3%A1sico-chamando-sua-fun%C3%A7%C3%A3o",
  "label": "7. Crie um modelo básico chamando sua função",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-crie-uma-p%C3%A1gina-usando-o-modelo-test-function.html",
  "label": "8. Crie uma página usando o modelo test-function.html",
  "parent": null
}, {
  "depth": 0,
  "id": "depurar-sua-fun%C3%A7%C3%A3o-sem-servidor",
  "label": "Depurar sua função sem servidor",
  "parent": null
}, {
  "depth": 0,
  "id": "o-que-voc%C3%AA-fez%3F",
  "label": "O que você fez?",
  "parent": null
}, {
  "depth": 0,
  "id": "para-onde-ir-a-partir-daqui%3F",
  "label": "Para onde ir a partir daqui?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Introdução às funções sem servidor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " se estiver criando uma função sem servidor como parte de um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/platform/create-a-project-for-ui-extensions",
          children: "projeto de desenvolvedor"
        }), ", visite a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/platform/serverless-functions",
          children: "documentação para desenvolvedores sobre a função sem servidor em projetos"
        }), ". A documentação abaixo é destinada a criação de funções sem servidor fora da plataforma do projeto do desenvolvedor."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções sem servidor oferecem uma forma de escrever código do lado do servidor que interaja com a HubSpot e serviços de terceiros através de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "APIs"
      }), ". As APIs que necessitam de autenticação não são seguras para o front-end de um site, já que sias credenciais estariam expostas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Funções sem servidor podem atuar como um intermediário entre os serviços de front-end e back-end que exigem autenticação. Com as funções sem servidor, os desenvolvedores não precisam criar e gerenciar novos servidores. As funções sem servidor exigem menos pessoal e, por isso, são mais fáceis de ampliar conforme a empresa cresce. Temos uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "visão geral de alto nível do que são as funções sem servidor da HubSpot e como elas funcionam"
      }), "; recomendamos que você a leia antes de seguir este tutorial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Este tutorial o orientará pela criação da sua primeira função sem servidor."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você criará uma pasta de função sem servidor, definirá sua pasta de configuração, criará uma função e obterá uma resposta dela."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "O que você deve fazer antes de seguir este tutorial:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ter acesso a uma conta do CMS Hub Enterprise ou a uma conta de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/standalone-cms-developer",
          children: "Sandbox para desenvolvedores do CMS"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Certifique-se de ter pelo menos uma compreensão básica ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "do que é uma função sem servidor"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Familiarize-se com as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "ferramentas de desenvolvimento local da HubSpot"
        }), ", pois elas são necessárias para trabalhar com as funções sem servidor."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Certifique-se de ter a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cms-cli#install-or-upgrade",
          children: "versão mais recente das ferramentas de desenvolvimento local da HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você já deve ter as ferramentas de desenvolvimento local ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: "autenticadas com sua conta da HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Criar uma pasta de projeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " e certifique-se de que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultPortal"
        })
      }), " está definido para a sua conta de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "Sandbox para desenvolvedores do CMS"
      }), " ou para uma conta do CMS Hub Enterprise."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No computador, na pasta que contém o arquivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crie a pasta"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      }), " Esta pasta conterá todos os nossos arquivos, tanto as próprias funções quanto um modelo que usará a função."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No terminal, execute o comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "watch"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch serverless-tutorial serverless-tutorial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Assim, todas as edições nesta pasta resultarão em uploads no Gerenciador de design. Como a pasta ainda não tem conteúdo, este comando simplesmente indicará que o Watcher está pronto e em funcionamento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Crie uma pasta de funções"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro da pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crie a pasta"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Similares às pastas de módulos personalizados que terminam em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " serve para comunicar que a pasta contém funções sem servidor. Os arquivos armazenados nesta pasta não podem ser publicamente acessados."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como a pasta está vazia no momento, o comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " que você está executando ainda não criará a pasta no Gerenciador de design."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Crie um arquivo de configuração"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Crie um novo arquivo na pasta"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "e nomeie-o"
      }), " ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "."]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " é um arquivo obrigatório contido em uma pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ". Ele serve como um arquivo de configuração para funções sem servidor. Definindo o ambiente de tempo de execução, a versão da função sem servidor e os endpoints disponíveis. Para obter um resumo de tudo o que é definido neste arquivo, consulte nossa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "referência das funções sem servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Se você criou e salvou o arquivo vazio, receberá uma mensagem de erro no terminal informando que não é possível carregar um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), " vazio. Não há problema em ignorar porque você vai adicionar esse código e salvá-lo, o que acionará um novo upload que terá êxito."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cole o código abaixo em serverless.json:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// place this in your serverless.json file, without this comment\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"endpoints\": {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Salve o arquivo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mantenha este arquivo aberto no editor de código, voltaremos a ele depois."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como o comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " está em execução, se você atualizar o gerenciador de design, verá as pastas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), " e o novo arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Crie um arquivo de função"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Crie um novo arquivo na pasta"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "my-first-function.functions"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "e nomeie-o"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "congratulation.js"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este é o arquivo de função real, o arquivo que será executado para realizar a tarefa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cole o código abaixo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n  const functionResponse =\n    \"Congrats! You've just deployed a Serverless Function.\";\n\n  // sendResponse is a callback function you call to send your response.\n  sendResponse({ body: functionResponse, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta função sem servidor, quando executada, retorna a string ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Parabéns! Você acabou de implantar uma função sem servidor.\""
      }), " e o código de status ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), ", indicando sucesso."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Em um cenário real, é provável que você use APIs ou faça alguns cálculos que não deseja que se tornem públicos. Você retornaria o resultado desses cálculos ou um código de status simples com base no sucesso das transações da API."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você ainda não terminou; ainda não há maneira de executar esta função."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Registre sua função em serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abra o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " novamente. No arquivo, localize o objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"endpoints\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Atualize o objeto para ficar assim:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// update the endpoints object in your serverless.json to reflect this object.\n\"endpoints\": {\n   \"congratulation\": {\n     \"method\": \"GET\",\n     \"file\": \"congratulation.js\"\n   }\n }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O objeto endpoints contém um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), " é o endpoint que você está criando. O nome do endpoint é o que define o caminho que você usará para chamar a função sem servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As funções sem servidor são expostas por meio de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "um caminho no domínio da sua conta do HubSpot CMS."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Essas funções podem ser acessadas em:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/<endpoint-name/path>"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No caso do endpoint \"congratulation\" que você criou, ele será"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/congratulation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por isso, geralmente é uma boa ideia nomear o endpoint de forma semelhante ao nome do arquivo de função, e ambos devem ser nomeados com base nas informações contra as quais eles atuam, não na ação tomada contra essas informações. Você deve usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
        children: "método HTTP"
      }), " ou outros métodos para comunicar o tipo de ação que está tomando contra essas informações. O parâmetro \"method\" define o método HTTP ao qual a função dá suporte. Pode ser uma única string ou uma matriz de strings que denotam os métodos aos quais a função dá suporte."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Teste sua função"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A maneira mais simples de testar uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para sua função sem servidor é acessar a URL do endpoint diretamente no navegador.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://your-domain.com/_hcms/api/congratulation",
        children: "https://your-domain.com/_hcms/api/congratulation"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Substituindo your-domain.com pelo domínio do seu site da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você deve ver ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Parabéns! Você acabou de implantar uma função sem servidor\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sucesso! Você conseguiu."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.em, {
        children: "Se você não receber essa resposta, comece do início deste tutorial lendo cuidadosamente cada etapa e verificando o código. É provável que as instruções na etapa 4 ou 5 não tenham sido seguidas corretamente."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para solicitações mais complexas, é útil usar uma ferramenta como o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.postman.com/downloads/",
          children: "Postman."
        }), " O Postman facilita o teste e a depuração de APIs. Um recurso útil para desenvolvedores de front-end é a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://learning.postman.com/docs/postman/sending-api-requests/generate-code-snippets/",
          children: "geração de código"
        }), ", que pode criar um ponto de partida para a chamada javascript da sua função."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Crie um modelo básico chamando sua função"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em uma nova janela do terminal, navegue para a pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Execute o seguinte comando no terminal:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template \"test-function\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O comando cria um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), ". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Abra o arquivo no editor de código."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Acima da tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</head>"
      }), ", ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["adicione ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<script></script>"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copie o javascript abaixo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var requestOptions = {\n  method: 'GET',\n  headers: {\n    'Content-Type': 'application/json',\n  },\n};\n\nfetch('https://www.example.com/_hcms/api/congratulation', requestOptions)\n  .then((response) => response.text())\n  .then((result) => console.log(result))\n  .catch((error) => console.log('error', error));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As funções sem servidor do HubSpot oferecem suportam apenas ao tipo de conteúdo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), ". Deixá-lo de fora resultará em um erro de \"Tipo de mídia não suportada\"."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cole o código JavaScript copiado"
      }), " dentro da tag do script que você criou anteriormente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Altere ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "www.example.com"
        }), " para o domínio da sua conta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Salve o arquivo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Crie uma página usando o modelo test-function.html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No Gerenciador de design, localize o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), " (talvez seja necessário atualizar)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Clique com o botão direito no arquivo e escolha \"criar página\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nomeie sua página como \"Função de teste\". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Criar página"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Clique para visualizar em nova janela"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inspecione a página ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "clicando com o botão direito"
      }), " em qualquer lugar na página e ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "selecionando \"inspecionar\"."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você fez tudo corretamente, verá no console a mensagem de felicitações."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Parabéns, você chamou sua função sem servidor de uma página do HubSpot CMS."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Embora este tutorial faça você chamar a função sem servidor no nível do modelo, você pode chamá-la em qualquer lugar em que possa adicionar JavaScript em seu site hospedado no CMS. O lugar mais comum para fazer isso é dentro dos módulos personalizados."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Depurar sua função sem servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Até aqui, sua função sem servidor deve funcionar corretamente. Quanto mais complexa sua função fica, mais difícil é solucionar os problemas. Assim como o console no navegador é útil para depurar javascript no front-end, você pode obter logs semelhantes para suas funções sem servidor usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })
      }), ". Confira a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "referência de desenvolvimento local"
      }), " para obter mais informações sobre este comando."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs logs <endpoint-name> --follow\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "O que você fez?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você criou uma pasta de função sem servidor, com um arquivo de configuração serverless.json e um arquivo de função chamado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation.js"
      }), ". Você usou \"GET\" para obter o texto de felicitação da função sem servidor. Você usou javascript para fazer uma chamada para a função sem servidor a partir de uma página no HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você entende como o arquivo de configuração, o arquivo de função e a pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " se relacionam, a CLI tem um comando útil que você pode usar para criar suas funções mais rápido da próxima vez."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create function <function name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta função cria uma pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ", um arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " e um arquivo de função com os nomes fornecidos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Para onde ir a partir daqui?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "Visão geral de alto nível das funções sem servidor"
        }), " no HubSpot CMS."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/serverless-functions",
          children: "Referência das funções sem servidor"
        }), " especificando as partes de uma função sem servidor"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/introduction-to-hubspot-apis",
          children: "Introdução ao curso acadêmico de APIs da HubSpot."
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Integração contínua com o GitHub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}