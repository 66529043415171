import UIMedia from 'UIComponents/layout/UIMedia';
import UIMediaBody from 'UIComponents/layout/UIMediaBody';
import UIMediaLeft from 'UIComponents/layout/UIMediaLeft';
import UIMediaRight from 'UIComponents/layout/UIMediaRight';
import UITab from 'UIComponents/nav/UITab';
import UIButton from 'UIComponents/button/UIButton';
import Accordion from './components/Accordion';
import Alert from './components/Alert';
import Card from './components/Card';
import CardsRow from './components/CardsRow';
import Code from './components/Code';
import Column from './components/Column';
import LegacyContentCard from './components/legacy/ContentCard';
import LegacyContentCardsRow from './components/legacy/ContentCardsRow';
import LegacyDndModule from './components/legacy/DndModule';
import LegacyDndSection from './components/legacy/DndSection';
import CTA from './components/CTA';
import Endpoint from './components/Endpoint';
import EndpointCard from './components/EndpointCard';
import EndpointExample from './components/EndpointExample';
import EndpointLeftColumn from './components/EndpointLeftColumn';
import EndpointParameter from './components/EndpointParameter';
import EndpointParameters from './components/EndpointParameters';
import EndpointRequirements from './components/EndpointRequirements';
import EndpointRightColumn from './components/EndpointRightColumn';
import Image from './components/Image';
import EndpointJSONFields from './components/EndpointJSONFields';
import Link from './components/Link';
import MdxH1 from './components/MdxH1';
import MdxH2 from './components/MdxH2';
import MdxH3 from './components/MdxH3';
import MdxH4 from './components/MdxH4';
import MdxH5 from './components/MdxH5';
import MdxH6 from './components/MdxH6';
import PostmanButton from './components/PostmanButton';
import Pre from './components/Pre';
import ProductTier from './components/ProductTier/ProductTier';
import Quote from './components/Quote';
import RelatedApiLink from './components/RelatedApiLink';
import Row from './components/Row';
import Table from './components/Table';
import Tabs from './components/Tabs';
import Tag from './components/Tag';
import UIIcon from 'UIComponents/icon/UIIcon';
import UIIllustration from 'UIComponents/image/UIIllustration';
import HubspotVideoPlayer from './components/HubspotVideoPlayer';
import IFrame from './components/IFrame';
import Form from './components/Form';
import Video from './components/Video';
export const mdxComponents = {
  Accordion,
  h1: MdxH1,
  h2: MdxH2,
  h3: MdxH3,
  h4: MdxH4,
  h5: MdxH5,
  h6: MdxH6,
  img: Image,
  button: UIButton,
  table: Table,
  Table,
  a: Link,
  Alert,
  Card,
  CardsRow,
  code: Code,
  Column,
  ContentCard: LegacyContentCard,
  ContentCardsRow: LegacyContentCardsRow,
  CTA,
  DndModule: LegacyDndModule,
  DndSection: LegacyDndSection,
  Endpoint,
  EndpointCard,
  EndpointExample,
  EndpointLeftColumn,
  EndpointParameter,
  EndpointParameters,
  EndpointRequirements,
  EndpointRightColumn,
  Icon: UIIcon,
  Illustration: UIIllustration,
  EndpointJSONFields,
  Media: UIMedia,
  MediaBody: UIMediaBody,
  MediaLeft: UIMediaLeft,
  MediaRight: UIMediaRight,
  PostmanButton,
  pre: Pre,
  ProductTier,
  Quote,
  Row,
  RelatedApiLink,
  Tab: UITab,
  Tabs,
  Tag,
  Video,
  HubspotVideoPlayer,
  IFrame,
  Form
};