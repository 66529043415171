"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'reference/api/cms/blogs/blog-authors/v3-legacy';
const title = exports.title = 'Blog Authors API - V3 Legacy';
const name = exports.name = 'Blog Authors API - V3 Legacy';
const metaDescription = exports.metaDescription = 'Blog Authors API - V3 Legacy';
const orderedTitles = exports.orderedTitles = ['List blog authors', 'Search blog authors', 'Get blog author by ID', 'Create blog author', 'Update blog author', 'Delete Blog Author'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET', 'GET', 'GET', 'POST', 'PUT', 'DELETE'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      code: "code",
      a: "a",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample,
      EndpointJSONFields
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointJSONFields) _missingMdxReference("EndpointJSONFields", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "List blog authors"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/blogs/v3/blog-authors"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get the list of blog authors. Supports paging and filtering."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Some parameters support \"range\" filters via double-underscored param suffixes, like ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "__gt"
          }), ". If a parameter supports a filter type of 'gt', that means you can append a double-underscore and 'gt' to the parameter name to find all items greater than a specified value."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["For example, the following query will find all items with the 'updated' time greater than 1364424600000: ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://api.hubapi.com/blogs/v3/blog-authors?created__gt=1364424600000",
            children: "https://api.hubapi.com/blogs/v3/blog-authors?created__gt=1364424600000"
          })]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "id",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Search for authors by id. Supports exact value matching and the 'in' range filter (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "id__in=348109009,348109019"
              }), ")"]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fullName",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Search for authors by full name. Supports exact value matching, 'contains' searching, and 'icontains' case-insensitive searching (e.g.   name__icontains=author1,author2`)"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "slug",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Search for authors by slug. Supports exact value matching"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "created",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter results by creation date, in milliseconds since the epoch. Supports exact value matching and the following range filters: range, gt, gte, lt, lte"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "updated",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter results by last updated date, in milliseconds since the epoch. Supports exact value matching and the following range filters: range, gt, gte, lt, lte"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "email",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Search for authors by email. Supports exact value matching"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "limit",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The maximum number of items to return. Defaults to 20."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "offset",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The offset from the beginning of the result set from which to start returning results. Used for paging. Defaults to 0."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for all query parameters (including those above) and JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIEdFVCBVUkw6PC9oMz4KPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnM/aGFwaWtleT1kZW1vIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnM8L2E+CgpFeGFtcGxlIEdFVCByZXNwb25zZToKewogICJvYmplY3RzIjogWwogICAgewogICAgICAicG9ydGFsSWQiOiA2MjUxNSwKICAgICAgImlkIjogMjg0ODM5MjQzLAogICAgICAiY3JlYXRlZCI6IDEzNzc4NTY5ODMwMDAsCiAgICAgICJ1cGRhdGVkIjogMTM3Nzg1Njk4MzAwMCwKICAgICAgImRlbGV0ZWRBdCI6IDAsCiAgICAgICJmdWxsTmFtZSI6ICJIdWIgU3BvdCIsCiAgICAgICJlbWFpbCI6ICJ0ZXN0YXBpQGh1YnNwb3QuY29tIiwKICAgICAgInVzZXJJZCI6IDIxNTQ4MiwKICAgICAgInVzZXJuYW1lIjogInRlc3RhcGlAaHVic3BvdC5jb20iLAogICAgICAic2x1ZyI6ICJodWItc3BvdCIsCiAgICAgICJkaXNwbGF5TmFtZSI6ICJIdWIgU3BvdCIsCiAgICAgICJnb29nbGVQbHVzIjogIiIsCiAgICAgICJiaW8iOiAiIiwKICAgICAgIndlYnNpdGUiOiAiIiwKICAgICAgInR3aXR0ZXIiOiAiIiwKICAgICAgImxpbmtlZGluIjogIiIsCiAgICAgICJmYWNlYm9vayI6ICIiLAogICAgICAiYXZhdGFyIjogIiIsCiAgICAgICJncmF2YXRhclVybCI6ICJodHRwczovL2FwcC5odWJzcG90LmNvbS9zZXR0aW5ncy9hdmF0YXIvYmIyM2YzYWU5N2I5Njk4ZDEzMGMwMmQyODQxZTg2YTQiLAogICAgICAidHdpdHRlclVzZXJuYW1lIjogIiIsCiAgICAgICJoYXNTb2NpYWxQcm9maWxlcyI6IGZhbHNlCiAgICB9CiAgXSwKICAibWVzc2FnZSI6IG51bGwsCiAgInRvdGFsIjogMTEsCiAgImxpbWl0IjogMTAwMCwKICAib2Zmc2V0IjogMAp9Cg=="
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was deleted, in milliseconds since the epoch. Zero if the author was never deleted. Use a DELETE request to properly soft delete an author - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "fullName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The full name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "email"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The email address of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "userId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The userid of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "username"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The username of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the author, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "displayName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The display name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "bio"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's bio text"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "googlePlus"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Google Plus url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "website"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's website url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitter"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "linkedin"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's LinkedIn url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "facebook"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Facebook url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "avatar"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's avatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "gravatarUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's gravatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitterUsername"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter username, generated from their twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "hasSocialProfiles"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "boolean"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "true if at least one social network (facebook, twitter, googlePlus, likedin) has a url"
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Search blog authors"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/blogs/v3/blog-authors/search"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Search for blog authors by name, email, and url slug. Supports paging, ordering, and casing changes."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "order",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "the attribute to order the results by. Prepend a \"-\" to invert"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "limit",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The maximum number of items to return. Defaults to 20."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "offset",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The offset from the beginning of the result set from which to start returning results. Used for paging. Defaults to 0."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for all query parameters (including those above) and JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "q",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Search for authors whose names, emails, or url slugs contain the given string (q=X)."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "active",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter authors by whether or not they have published blog posts (true or false)"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "blog",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Filter authors by the blog they have published to (value is a blog ID)"
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIEdFVCBVUkw6PC9oMz4KPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnMvc2VhcmNoP3E9aHViLXNwb3QiIHRhcmdldD0iX2JsYW5rIj5odHRwczovL2FwaS5odWJhcGkuY29tL2Jsb2dzL3YzL2Jsb2ctYXV0aG9ycy9zZWFyY2gvP3E9aHViLXNwb3Q8L2E+CgpFeGFtcGxlIEdFVCByZXNwb25zZToKewogICJvYmplY3RzIjogWwogICAgewogICAgICAicG9ydGFsSWQiOiA2MjUxNSwKICAgICAgImlkIjogMjg0ODM5MjQzLAogICAgICAiY3JlYXRlZCI6IDEzNzc4NTY5ODMwMDAsCiAgICAgICJ1cGRhdGVkIjogMTM3Nzg1Njk4MzAwMCwKICAgICAgImRlbGV0ZWRBdCI6IDAsCiAgICAgICJmdWxsTmFtZSI6ICJIdWIgU3BvdCIsCiAgICAgICJlbWFpbCI6ICJ0ZXN0YXBpQGh1YnNwb3QuY29tIiwKICAgICAgInVzZXJJZCI6IDIxNTQ4MiwKICAgICAgInVzZXJuYW1lIjogInRlc3RhcGlAaHVic3BvdC5jb20iLAogICAgICAic2x1ZyI6ICJodWItc3BvdCIsCiAgICAgICJwdWJsaWNVcmwiOiAiaHR0cDovLzYyNTE1LmhzLXNpdGVzLmNvbS9ibG9nL2F1dGhvci9odWItc3BvdCIsCiAgICAgICJ0b3RhbFBvc3RzIjogNzMsCiAgICAgICJsaXZlUG9zdHMiOiA1LAogICAgICAiZGlzcGxheU5hbWUiOiAiSHViIFNwb3QiLAogICAgICAic3RhdHVzIjogImFjdGl2ZSIsCiAgICAgICJnb29nbGVQbHVzIjogIiIsCiAgICAgICJiaW8iOiAiIiwKICAgICAgIndlYnNpdGUiOiAiIiwKICAgICAgInR3aXR0ZXIiOiAiIiwKICAgICAgImxpbmtlZGluIjogIiIsCiAgICAgICJmYWNlYm9vayI6ICIiLAogICAgICAiYXZhdGFyIjogIiIsCiAgICAgICJncmF2YXRhclVybCI6ICJodHRwczovL2FwcC5odWJzcG90LmNvbS9zZXR0aW5ncy9hdmF0YXIvYmIyM2YzYWU5N2I5Njk4ZDEzMGMwMmQyODQxZTg2YTQiLAogICAgICAidHdpdHRlclVzZXJuYW1lIjogIiIsCiAgICAgICJoYXNTb2NpYWxQcm9maWxlcyI6IGZhbHNlCiAgICB9CiAgXSwKICAibWVzc2FnZSI6IG51bGwsCiAgInRvdGFsIjogMTEsCiAgImxpbWl0IjogMTAwMCwKICAib2Zmc2V0IjogMAp9Cg=="
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was deleted, in milliseconds since the epoch. Zero if the author was never deleted. Use a DELETE request to properly soft delete an author - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "fullName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The full name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "email"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The email address of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "userId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The userid of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "username"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The username of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the author, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "publicUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author url based on your primary blog"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "totalPosts"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The total count of posts attributed to this author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "livePosts"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The total count of published posts attributed to this author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "status"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "\"active\" if author has any published posts, \"inactive\" otherwise"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "displayName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The display name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "bio"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's bio text"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "googlePlus"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Google Plus url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "website"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's website url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitter"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "linkedin"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's LinkedIn url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "facebook"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Facebook url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "avatar"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's avatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "gravatarUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's gravatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitterUsername"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter username, generated from their twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "hasSocialProfiles"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "boolean"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "true if at least one social network (facebook, twitter, googlePlus, likedin) has a url"
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get blog author by ID"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/blogs/v3/blog-authors/:id"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get a specific blog author by ID."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "content"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "id",
            schemaGroup: "Path",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for a blog author."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for all query parameters (including those above) and JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIEdFVCBVUkw6PC9oMz4KPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnMvMjg0ODM5MjQzIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnMvMjg0ODM5MjQzPC9hPgoKRXhhbXBsZSBHRVQgcmVzcG9uc2U6CnsKICAgICJwb3J0YWxJZCI6IDYyNTE1LAogICAgImlkIjogMjg0ODM5MjQzLAogICAgImNyZWF0ZWQiOiAxMzc3ODU2OTgzMDAwLAogICAgInVwZGF0ZWQiOiAxMzc3ODU2OTgzMDAwLAogICAgImRlbGV0ZWRBdCI6IDAsCiAgICAiZnVsbE5hbWUiOiAiSHViIFNwb3QiLAogICAgImVtYWlsIjogInRlc3RhcGlAaHVic3BvdC5jb20iLAogICAgInVzZXJJZCI6IDIxNTQ4MiwKICAgICJ1c2VybmFtZSI6ICJ0ZXN0YXBpQGh1YnNwb3QuY29tIiwKICAgICJzbHVnIjogImh1Yi1zcG90IiwKICAgICJwdWJsaWNVcmwiOiAiaHR0cDovLzYyNTE1LmhzLXNpdGVzLmNvbS9ibG9nL2F1dGhvci9odWItc3BvdCIsCiAgICAiZGlzcGxheU5hbWUiOiAiSHViIFNwb3QiLAogICAgImdvb2dsZVBsdXMiOiAiIiwKICAgICJiaW8iOiAiIiwKICAgICJ3ZWJzaXRlIjogIiIsCiAgICAidHdpdHRlciI6ICIiLAogICAgImxpbmtlZGluIjogIiIsCiAgICAiZmFjZWJvb2siOiAiIiwKICAgICJhdmF0YXIiOiAiIiwKICAgICJncmF2YXRhclVybCI6ICJodHRwczovL2FwcC5odWJzcG90LmNvbS9zZXR0aW5ncy9hdmF0YXIvYmIyM2YzYWU5N2I5Njk4ZDEzMGMwMmQyODQxZTg2YTQiLAogICAgInR3aXR0ZXJVc2VybmFtZSI6ICIiLAogICAgImhhc1NvY2lhbFByb2ZpbGVzIjogZmFsc2UKfQo="
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was deleted, in milliseconds since the epoch. Zero if the author was never deleted. Use a DELETE request to properly soft delete an author - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "fullName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The full name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "email"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The email address of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "userId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The userid of the author, if associated with a hubspot account. This property will not be included if the author is not associated with a user."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "username"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The username of the author, if associated with a hubspot account. This property will not be included if the author is not associated with a user."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the author, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "displayName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The display name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "bio"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's bio text"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "googlePlus"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Google Plus url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "website"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's website url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitter"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "linkedin"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's LinkedIn url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "facebook"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Facebook url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "avatar"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's avatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "gravatarUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's gravatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitterUsername"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter username, generated from their twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "hasSocialProfiles"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "boolean"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "true if at least one social network (facebook, twitter, googlePlus, likedin) has a url"
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Create blog author"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/blogs/v3/blog-authors"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Create a Blog Author."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "content"
        }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for all query parameters (including those above) and JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "email",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The email address of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fullName",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The first and last name of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "userId",
            type: "Long",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The HubSpot user id of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "username",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The HubSpot username of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "bio",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "A short biographay of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "website",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Url linking to a blog author page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "twitter",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL or username of the twitter account associated with the author. This will be normalized into the twitter url for said user"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "linkedin",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's linkedin page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "facebook",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's facebook page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "googlePlus",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's Google+ page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "avatar",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's avatar, if supplying a custom one"
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CjxoMz5FeGFtcGxlIFBPU1QgcmVxdWVzdDo8L2gzPgo8YSBocmVmPSJodHRwczovL2FwaS5odWJhcGkuY29tL2Jsb2dzL3YzL2Jsb2ctYXV0aG9ycyIgdGFyZ2V0PSJfYmxhbmsiPmh0dHBzOi8vYXBpLmh1YmFwaS5jb20vYmxvZ3MvdjMvYmxvZy1hdXRob3JzPC9hPgoKRXhhbXBsZSBQT1NUIGJvZHk6CnsKICAgICJlbWFpbCI6ICJ0ZXN0YXBpQGh1YnNwb3QuY29tIiwKICAgICJmdWxsTmFtZSI6ICJIdWIgU3BvdCIKfQoKRXhhbXBsZSBQT1NUIHJlc3BvbnNlOgp7CiAgICAicG9ydGFsSWQiOiA2MjUxNSwKICAgICJpZCI6IDI4NDgzOTI0MywKICAgICJjcmVhdGVkIjogMTM3Nzg1Njk4MzAwMCwKICAgICJ1cGRhdGVkIjogMTM3Nzg1Njk4MzAwMCwKICAgICJkZWxldGVkQXQiOiAwLAogICAgImZ1bGxOYW1lIjogIkh1YiBTcG90IiwKICAgICJlbWFpbCI6ICJ0ZXN0YXBpQGh1YnNwb3QuY29tIiwKICAgICJ1c2VySWQiOiBudWxsLAogICAgInVzZXJuYW1lIjogInRlc3RhcGlAaHVic3BvdC5jb20iLAogICAgInNsdWciOiAiaHViLXNwb3QiLAogICAgInB1YmxpY1VybCI6ICJodHRwOi8vNjI1MTUuaHMtc2l0ZXMuY29tL2Jsb2cvYXV0aG9yL2h1Yi1zcG90IiwKICAgICJkaXNwbGF5TmFtZSI6ICJIdWIgU3BvdCIsCiAgICAiZ29vZ2xlUGx1cyI6ICIiLAogICAgImJpbyI6ICIiLAogICAgIndlYnNpdGUiOiAiIiwKICAgICJ0d2l0dGVyIjogIiIsCiAgICAibGlua2VkaW4iOiAiIiwKICAgICJmYWNlYm9vayI6ICIiLAogICAgImF2YXRhciI6ICIiLAogICAgImdyYXZhdGFyVXJsIjogImh0dHBzOi8vYXBwLmh1YnNwb3QuY29tL3NldHRpbmdzL2F2YXRhci9iYjIzZjNhZTk3Yjk2OThkMTMwYzAyZDI4NDFlODZhNCIsCiAgICAidHdpdHRlclVzZXJuYW1lIjogIiIsCiAgICAiaGFzU29jaWFsUHJvZmlsZXMiOiBmYWxzZQp9Cg=="
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was deleted, in milliseconds since the epoch. Zero if the author was never deleted. Use a DELETE request to properly soft delete an author - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "fullName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The full name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "email"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The email address of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "userId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The userid of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "username"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The username of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the author, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "publicUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author url based on your primary blog"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "displayName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The display name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "bio"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's bio text"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "googlePlus"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Google Plus url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "website"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's website url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitter"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "linkedin"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's LinkedIn url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "facebook"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Facebook url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "avatar"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's avatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "gravatarUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's gravatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitterUsername"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter username, generated from their twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "hasSocialProfiles"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "boolean"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "true if at least one social network (facebook, twitter, googlePlus, likedin) has a url"
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Update blog author"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "PUT",
          path: "/blogs/v3/blog-authors/:id"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Update a Blog Author. This action will replace any existing allowed fields specified. Any fields not included will not change from existing values."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "id",
            schemaGroup: "Path",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for a blog author."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "casing",
            schemaGroup: "Query",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Use the casing=snake parameter to change the API's casing for all query parameters (including those above) and JSON fields (below) to snake_case, rather than camelCase, which is the default. This option is provided for backwards-compatibility and ease of migration from Content v2 APIs, which used snake_case."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "avatar",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The email address of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "googlePlus",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The first and last name of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "facebook",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The HubSpot user id of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "linkedin",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The HubSpot username of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "twitter",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "A short biographay of the blog author"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "website",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Url linking to a blog author page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "bio",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL or username of the twitter account associated with the author. This will be normalized into the twitter url for said user"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "username",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's linkedin page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "userId",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's facebook page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "fullName",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's Google+ page"
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "email",
            type: "string",
            schemaGroup: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "URL to the blog author's avatar, if supplying a custom one"
            })
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(EndpointRightColumn, {
        children: [(0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgUFVUIFVSTDoKPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnMvMjg0ODM5MjQzIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnMvMjg0ODM5MjQzPC9hPgoKRXhhbXBsZSBQVVQgYm9keToKewogICJmdWxsTmFtZSI6ICJGb28gQmFyIgp9CgpFeGFtcGxlIFBVVCByZXNwb25zZToKewogICAgInBvcnRhbElkIjogNjI1MTUsCiAgICAiaWQiOiAyODQ4MzkyNDMsCiAgICAiY3JlYXRlZCI6IDEzNzc4NTY5ODMwMDAsCiAgICAidXBkYXRlZCI6IDEzNzc4NTY5ODMwMDAsCiAgICAiZGVsZXRlZEF0IjogMCwKICAgICJmdWxsTmFtZSI6ICJGb28gQmFyIiwKICAgICJlbWFpbCI6ICJ0ZXN0YXBpQGh1YnNwb3QuY29tIiwKICAgICJ1c2VySWQiOiAyMTU0ODIsCiAgICAidXNlcm5hbWUiOiAidGVzdGFwaUBodWJzcG90LmNvbSIsCiAgICAic2x1ZyI6ICJodWItc3BvdCIsCiAgICAicHVibGljVXJsIjogImh0dHA6Ly82MjUxNS5ocy1zaXRlcy5jb20vYmxvZy9hdXRob3IvaHViLXNwb3QiLAogICAgImRpc3BsYXlOYW1lIjogIkZvbyBCYXIiLAogICAgImdvb2dsZVBsdXMiOiAiIiwKICAgICJiaW8iOiAiIiwKICAgICJ3ZWJzaXRlIjogIiIsCiAgICAidHdpdHRlciI6ICIiLAogICAgImxpbmtlZGluIjogIiIsCiAgICAiZmFjZWJvb2siOiAiIiwKICAgICJhdmF0YXIiOiAiIiwKICAgICJncmF2YXRhclVybCI6ICJodHRwczovL2FwcC5odWJzcG90LmNvbS9zZXR0aW5ncy9hdmF0YXIvYmIyM2YzYWU5N2I5Njk4ZDEzMGMwMmQyODQxZTg2YTQiLAogICAgInR3aXR0ZXJVc2VybmFtZSI6ICIiLAogICAgImhhc1NvY2lhbFByb2ZpbGVzIjogZmFsc2UKfQoKPC9ibG9jaz4="
          })
        }), (0, _jsxRuntime.jsxs)(EndpointJSONFields, {
          children: [(0, _jsxRuntime.jsx)(_components.h3, {
            children: "JSON Fields returned in the response"
          }), (0, _jsxRuntime.jsxs)(_components.table, {
            children: [(0, _jsxRuntime.jsx)(_components.thead, {
              children: (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.th, {
                  children: "Field name"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Type"
                }), (0, _jsxRuntime.jsx)(_components.th, {
                  children: "Description"
                })]
              })
            }), (0, _jsxRuntime.jsxs)(_components.tbody, {
              children: [(0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "id"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The unique id of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "portalId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The hub id"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "created"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was first created, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "updated"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was last updated, in milliseconds since the epoch"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "deletedAt"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "When the author was deleted, in milliseconds since the epoch. Zero if the author was never deleted. Use a DELETE request to properly soft delete an author - do not set this value directly."
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "fullName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The full name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "email"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The email address of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "userId"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Long"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The userid of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "username"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The username of the author, if associated with a hubspot account"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "slug"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The URL-friendly version of the author, used in blog urls"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "publicUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author url based on your primary blog"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "displayName"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "The display name of the author"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "bio"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's bio text"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "googlePlus"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Google Plus url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "website"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's website url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitter"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "linkedin"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's LinkedIn url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "facebook"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's Facebook url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "avatar"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's avatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "gravatarUrl"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's gravatar url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "twitterUsername"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "string"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "Author's twitter username, generated from their twitter url"
                })]
              }), (0, _jsxRuntime.jsxs)(_components.tr, {
                children: [(0, _jsxRuntime.jsx)(_components.td, {
                  children: "hasSocialProfiles"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "boolean"
                }), (0, _jsxRuntime.jsx)(_components.td, {
                  children: "true if at least one social network (facebook, twitter, googlePlus, likedin) has a url"
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete Blog Author"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "DELETE",
          path: "/blogs/v3/blog-authors/:id"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Deletes a blog author."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
          scope: "content"
        }), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "id",
            schemaGroup: "Path",
            required: true,
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "Unique identifier for the blog author to delete"
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgREVMRVRFIFVSTDoKPGEgaHJlZj0iaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnMvMjg0ODM5MjQzIiB0YXJnZXQ9Il9ibGFuayI+aHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9ibG9ncy92My9ibG9nLWF1dGhvcnMvMjg0ODM5MjQzPC9hPjwvcD4KCkV4YW1wbGUgREVMRVRFIHJlc3BvbnNlOiAKSFRUUC8xLjEgMjA0IE5vIENvbnRlbnQK"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}