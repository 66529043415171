"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66487748936;
const slug = exports.slug = 'reference/cms/modules/files';
const title = exports.title = 'Kodieren von benutzerdefinierten Modulen';
const name = exports.name = 'EMEA DACH (de) module files';
const metaDescription = exports.metaDescription = 'Benutzerdefinierte Module sind wiederverwendbare Komponenten, die Sie auf Ihrer gesamten Website einsetzen können. Erstellen Sie sie nach bewährten Verfahren zur Verbesserung von Geschwindigkeit, Benutzerfreundlichkeit und Barrierefreiheit.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "html-%2B-hubl-module.html",
  "label": "HTML + HubL (module.html)",
  "parent": null
}, {
  "depth": 0,
  "id": "css-module.css",
  "label": "CSS (module.css)",
  "parent": null
}, {
  "depth": 1,
  "id": "styling-basierend-auf-modulfeldwerten",
  "label": "Styling basierend auf Modulfeldwerten",
  "parent": "css-module.css"
}, {
  "depth": 2,
  "id": "css-klassen",
  "label": "CSS-Klassen",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "require_css-block",
  "label": "require_css-Block",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "inline-stile-hinzuf%C3%BCgen",
  "label": "Inline-Stile hinzufügen",
  "parent": "css-module.css"
}, {
  "depth": 1,
  "id": "bestimmte-css-dateien-importieren",
  "label": "Bestimmte CSS-Dateien importieren",
  "parent": "css-module.css"
}, {
  "depth": 0,
  "id": "javascript-module.js",
  "label": "JavaScript (module.js)",
  "parent": null
}, {
  "depth": 1,
  "id": "skripting-auf-der-grundlage-von-feldwerten",
  "label": "Skripting auf der Grundlage von Feldwerten",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "datenattribute",
  "label": "Datenattribute",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "require_js-block",
  "label": "require_js-Block",
  "parent": "javascript-module.js"
}, {
  "depth": 1,
  "id": "require_js",
  "label": "require_js",
  "parent": "javascript-module.js"
}, {
  "depth": 0,
  "id": "zugeh%C3%B6rige-informationen",
  "label": "Zugehörige Informationen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      h2: "h2",
      pre: "pre",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Moduldateien"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei der Erstellung eines Moduls für Seiten, Blogs und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/quotes",
        children: "Angebote"
      }), " enthält das Modul drei Front-End-bezogene Dateien, die den Inhalt, das Styling und die Funktionalität des Moduls steuern:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.html"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.css"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "module.js"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "E-Mail-Module unterstützen nicht module.css und module.js. Das liegt daran, dass E-Mail-Clients kein JavaScript unterstützen und die Unterstützung für verknüpfte CSS-Dateien begrenzt ist."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Dateien werden immer auf der Seite gerendert, wenn sich eine Instanz des Moduls auf der Seite befindet."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn eine Seite mehrere Instanzen desselben Moduls enthält, lädt HubSpot ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " von diesem Modul nur einmal. Standardmäßig werden ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " nicht asynchron geladen. Sie können dies jedoch ändern, indem Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options und js_render_options"
      }), " in der meta.json des Moduls aufnehmen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module können im Design-Manager oder lokal über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "das HubSpot-CLI"
      }), " erstellt werden. Im Design-Manager werden die Moduldateien in einem Editor mit mehreren Fenstern angezeigt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module1.png",
        alt: "cms-dev-custom-module1"
      }), "Wenn ein Modul lokal angezeigt wird, befinden sich die Dateien in module-name.module-Ordnern."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/cms-dev-custom-module0.png",
        alt: "cms-dev-custom-module0"
      }), "Ob Sie zum Erstellen und Verwalten von Modulen den Design-Manager oder das CLI verwenden, hängt von den Präferenzen Ihres Teams ab. Empfehlungen finden Sie unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Erstellen eines effizienten Entwicklungsworkflows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTML + HubL (module.html)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die module.html-Datei ist für HTML und HubL gedacht. Im Allgemeinen bestimmt der Ort, an dem ein Modul im Seiten-Editor oder in der Vorlagendatei platziert ist, wo der Inhalt der Datei module.html gerendert wird."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Datei agiert wie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "HubL-Include"
      }), " an der Stelle auf der Seite, an der das Modul platziert ist. Die module.html-Datei ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#using-module-field-data-to-render-html",
        children: "kann über HubL auf die Feldwerte des Moduls zugreifen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CSS (module.css)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "-Datei, um CSS zu einem Modul hinzuzufügen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Allgemeinen unterstützt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " eine sehr begrenzte Teilmenge von HubL. Sie können jedoch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#module-asset-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_asset_url(\"mein_bild.png\")"
        })
      }), " für Bilder verwenden, die als modulverknüpfte Inhaltselemente hinzugefügt wurden. Dies ermöglicht die Verknüpfung von Elementen wie Bildern, die mit dem Modul selbst verpackt sind. Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".testimonial-module__wrapper {\n  background: url('{{ module_asset_url('bg-pattern.png') }}');\n  background-repeat: repeat;\n  min-height: 200px;\n  width: 100%;\n  display: block;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie, wie Sie das CSS eines Moduls so einrichten, dass es sich dynamisch auf der Grundlage der Felder des Moduls ändert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styling basierend auf Modulfeldwerten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt einige Möglichkeiten, wie Sie das Styling Ihres Moduls auf der Grundlage der Modulfelder beeinflussen können. Wählen Sie die Methode, die für Ihren speziellen Anwendungsfall am besten geeignet ist."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#css-classes",
          children: "CSS-Klassen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#require-css-block",
          children: "require_css-Block"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#inline-styles",
          children: "Inline-Stile"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "CSS-Klassen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein vordefiniertes Styling für das Modul einzurichten, bei dem die Editoren aus diesen Optionen auswählen können, können Sie ein Modulfeld hinzufügen, um in Ihrer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "-Datei Klassen festzulegen, die den CSS-Klassen in Ihrer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "-Datei entsprechen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können zum Beispiel ein Bild- und ein Textmodul haben. Sie möchten, dass Content-Autoren das Bild auf der Grundlage eines Auswahlfeldes rechts oder links vom Text positionieren können. Dazu könnten Sie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "-Dateien wie folgt festlegen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n\n<section class=\"img-text__wrapper img-text--{{ module.positioning }}\" aria-label=\"{{ module.heading }}\">\n  {# module.position is a choice field with two values \"img-left\" or \"img-right\". This dictates the order they appear on desktop. Controlled by CSS #}\n  <div class=\"img-text__img\">\n      <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\">\n  </div>\n  <div class=\"img-text__text\">\n      <h2>\n        {% inline_text field=\"heading\" value=\"{{ module.heading }}\" %}\n      </h2>\n      {% inline_rich_text field=\"text\" value=\"{{ module.text }}\" %}\n  </div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "/* module.css */\n\n/* CSS that makes the image show adjacent to the text, \nand positioned based on the positioning field.*/\n\n/* The media query ensures that on mobile the image \nwill always appear above the text for \nvisual consistency. */\n\n@media (min-width: 768px) {\n  .img-text__wrapper {\n    display: flex;\n    align-items: row;\n  }\n  .img-text__img,\n  .img-text__text {\n    flex: 1;\n    padding: 10px;\n  }\n  .img-text--img-right {\n    flex-direction: row-reverse;\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_css-Block"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Content-Autoren direkte Kontrolle über bestimmte Eigenschaften geben müssen und wenn Klassen nicht ideal sind, sollten Sie Stil-Tags mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "-Blöcken verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Content-Autoren die direkte Kontrolle über bestimmte Eigenschaften zu geben, ohne Klassen zu verwenden, können Sie stattdessen Styling zur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "-Datei innerhalb von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "-Tags hinzufügen. Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n  <style>\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  </style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " HubL rendern kann, können Sie die Werte der Modulfelder als CSS-Variablen verwenden. Wenn ein Content-Autor das Feld im Seiten-Editor aktualisiert, wird das CSS entsprechend angepasst. Diese Blöcke verschieben die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "-Tags in die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "`<head>` Ihrer Seite innerhalb der `standard_header_includes`-Anweisung."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "  Sie können das CSS auch so festlegen, dass dessen Bereich nur für die Modulinstanz gilt, indem Sie das CSS mit `scope_css`-Tags umschließen. Sie könnten zum Beispiel den obigen Modulcode wie folgt aktualisieren:  "
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"img__wrapper\">\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n\n{% require_css %}\n<style>\n  {% scope_css %}\n    img {\n    border-width:{{ module.border_width }}px;\n    border-color:rgba({{ module.border_color.color|convert_rgb}},{{ module.border_color.opacity/100 }});\n    border-style: solid;\n    }\n  {% end_scope_css %}\n</style>\n{% end_require_css %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Inline-Stile hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie Content-Autoren detaillierte Steuerungsmöglichkeiten über bestimmte Eigenschaften geben müssen und Klassen nicht ideal sind, können Sie die Werte direkt in ein Stilattribut im HTML-Code einfügen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Module.html #}\n<div style=\"background: rgba({{ module.bg_color.color|convert_rgb }},{{ module.bg_color.opacity/100 }});\">\n  {% inline_rich_text field=\"richtext\" value=\"{{ module.richtext }}\" %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie viele Eigenschaften haben und der Code schwer zu lesen ist, sollten Sie auf die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "-Blockmethode umsteigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bestimmte CSS-Dateien importieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " ist eine HubL-Funktion, die Sie zu module.html hinzufügen können und die HubSpot mitteilt, dass ein bestimmtes Modul oder eine Vorlage eine bestimmte CSS-Datei zum Anzeigen benötigt. Ein Link-Tag, das auf die CSS-Datei verweist, wird der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " der Seite innerhalb des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), " hinzugefügt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "-Funktion lädt diese CSS-Datei nur einmal, unabhängig davon, wie oft dieselbe Datei von Modulen und Vorlagen auf einer bestimmten Seite benötigt wird. Dies eignet sich hervorragend für Situationen, in denen Stile von mehreren Modulen gemeinsam genutzt werden. Das Hinzufügen von CSS direkt zu den Haupt-Stylesheets, die auf jeder Seite Ihrer Website verwendet werden, ist möglicherweise jedoch nicht sinnvoll."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " und verlinkte CSS-Dateien erfüllen denselben Zweck, aber ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " kann auf der Grundlage von Feldwerten bedingt verwendet werden. Dies verhindert das Laden von unnötigem Code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html -->\n{{ require_css(get_asset_url(\"/modules/shared_layout_styles.css\")) }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript (module.js)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "-Datei, um JavaScript zu einem Modul hinzuzufügen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "-Datei unterstützt auch die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "-Datei kein HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Skripting auf der Grundlage von Feldwerten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt einige Möglichkeiten, Module zu erstellen, bei denen das JavaScript je nach Feldwert unterschiedlich reagiert. Wenn Sie wissen, welche Methode wann zu verwenden ist, können Sie auf jeder Seite, auf der das Modul verwendet wird, Performance-Vorteile erzielen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie zum Beispiel ein benutzerdefiniertes Bildmodul haben, möchten Sie Content-Autoren die Möglichkeit geben, das Bild in einer Lightbox zu öffnen. Content-Autoren möchten dies nur für bestimmte Bilder und nicht für alle Instanzen des Moduls."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Datenattribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Datenattribute sind benutzerdefinierte HTML 5-Standardattribute, die Entwickler zu Elementen hinzufügen. So wie alle Elemente ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "class=\"IhrKlassename\""
      }), " unterstützen, unterstützen auch alle Elemente ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-your-attribute=\"IhrWert\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- module.html-->\n<div class=\"img-module img-module__wrapper\" data-lightbox=\"{{ module.is_lightbox_enabled }}\" data-caption=\"above\">\n  <!-- module.is_lightbox_enabled is a boolean field, module.caption_position is a choice field. -->\n  {% if module.image.src %}\n    {% set sizeAttrs = 'width=\"{{ module.image.width }}\" height=\"{{ module.image.height }}\"' %}\n    {% if module.image.size_type == 'auto' %}\n      {% set sizeAttrs = 'style=\"max-width: 100%; height: auto;\"' %}\n    {% elif module.image.size_type == 'auto_custom_max' %}\n      {% set sizeAttrs = 'width=\"100%\" height=\"auto\" style=\"max-width: {{ module.image.max_width }}px; max-height: {{ module.image.max_height }}px\"' %}\n    {% endif %}\n    <img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" {{ sizeAttrs }}>\n  {% endif %}\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Datenattribute verwenden, um die Feldwerte Ihrer Modulinstanzen zu übergeben, die von Ihrer module.js-Datei verarbeitet werden sollen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um die Werte in Ihrer module.js-Datei zu verwenden, müssen Sie alle Instanzen Ihres Moduls durchlaufen. Durch Hinzufügen eines modulspezifischen Klassennamens zum äußersten Wrapper-Element Ihres Moduls erhalten Sie ein Ziel, das Sie verwenden können, damit Sie alle Instanzen Ihres Moduls durchlaufen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// module.js\n\nlet imgModules = document.getElementsByClassName('img-module');\nArray.from(imgModules).forEach(function (element) {\n  // loop through each of the instances of the module\n  // set data attributes to variables to make it easy to work with\n  let isLightboxEnabled = element.dataset.lightbox;\n  let captionStyle = element.dataset.caption;\n  if (isLightboxEnabled) {\n    element.addEventListener('click', function () {\n      showLightbox(captionStyle); // Execute your code for the action you want to take, you can pass your data attributes into functions from libraries.\n    });\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Datenattribute ermöglichen es Ihnen, die Feldwerte für jede Modulinstanz in Ihrer module.js abzurufen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js-Block"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In anspruchsvollen Situationen, z. B. bei Verwendung einer JavaScript-Templating-Bibliothek oder eines reaktiven Frameworks wie Vue.js oder React.js, möchten Sie möglicherweise nur die Daten ausgeben, während das Framework das Rendering übernimmt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In diesem Fall verwenden Sie ein Skript-Tag, das von einem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), "-Block umschlossen ist, um Variablen bereitzustellen, auf die Sie von Ihrem Templating-Skript aus zugreifen können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% require_js %}\n<script>\n  let myArray = [\n    {%- for item in module.repeating_text_field -%}\"{{ item }}\",{%- endfor -%}\n  ];\n</script>\n{% end_require_js %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Technik kann nützlich sein, um fortgeschrittene Anwendungen mit einem ersten Datensatz zu versorgen, von dem aus dann gerendert werden kann. Dadurch entfällt ein anfänglicher JavaScript-Aufruf zum Abrufen von Daten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "require_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " ist eine HubL-Funktion, die HubSpot mitteilt, dass ein bestimmtes Modul oder eine Vorlage eine bestimmte JavaScript-Datei benötigt, um korrekt geladen zu werden. Die Funktion benötigt zwei Parameter: den Pfad zur Datei und den Ort, an dem die Datei hinzugefügt werden soll (\"head\" oder \"footer\")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In einem Modul kann ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " nur zur module.html hinzugefügt werden. Die JavaScript-Datei, auf die in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), "-Anweisung verwiesen wird, wird nur einmal pro Seite geladen, unabhängig davon, wie oft sie von Modulen und Vorlagen auf der Seite benötigt wird. Dadurch wird die Anzahl der HTTP-Anfragen reduziert und doppelter Code vermieden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt einige Situationen, in denen dies praktisch ist:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie mehrere Module oder Vorlagen haben, die dasselbe JavaScript benötigen, können Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " verwenden, um dieses Skript modulübergreifend zu nutzen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie mit einem JavaScript-Bundler wie Webpack arbeiten, ist es möglicherweise einfacher, Ihre js-Dateien an einem bestimmten Ort auszugeben. Mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " können Sie das JavaScript mit Ihrem Modul verknüpfen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " und verknüpfte JavaScript-Dateien erfüllen denselben Zweck, aber ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " kann auf der Grundlage von Feldwerten bedingt ausgeführt werden. Dadurch wird verhindert, dass unnötiger Code geladen wird. Außerdem haben Sie die Möglichkeit, JavaScript in die Kopfzeile zu laden, falls Sie dies benötigen."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Da JavaScript das Rendern blockiert, ist \"footer\" der Standardort, an den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " JavaScript platziert. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Erfahren Sie mehr über die Optimierung der Leistung."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zugehörige Informationen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Die Geschwindigkeit Ihrer CMS Hub-Website optimieren"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Module"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Modulfelder"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}